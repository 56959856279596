import * as React from 'react';
import { ListItemStore } from './ListItemStore';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { View } from '../../../ViewStack/View';

@observer
export class ListItem extends BaseComponent<ListItemStore<any>>
{
    renderLoader(store: ListItemStore<any>): any
    {
        return this.renderComponent(store);
    }

    renderComponent(store: ListItemStore<any>): JSX.Element
    {
        if (store.isHidden || !store.template)
        {
            return null;
        }
        else
        {
            return this.renderItem(store);
        }
    }

    renderItem(store: ListItemStore<any>)
    {
        return <View
            viewComponent={store.template}
        />;
    }
}
