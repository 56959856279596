import { observable } from 'mobx';

export default class Module<T>
{
    @observable.ref private readonly _ref?: Function;
    @observable.ref instance: T;
    @observable.shallow dependencyRefs: Function[];
    @observable qualifier: string;
    @observable isSatisfied: boolean;
    @observable isProxy: boolean;
    @observable.shallow proxyResolutions: Array<(value?: any | PromiseLike<any>) => void>;
    @observable.ref promise: Promise<T>;
    @observable.ref resolution: (value?: any | PromiseLike<any>) => void;

    constructor(instance: T,
                dependencyRefs: Function[] = [],
                qualifier?: string,
                ref?: Function & { prototype: T },
                isSatisfied: boolean = false,
                isProxy: boolean = false)
    {
        this.instance = instance;
        this.dependencyRefs = dependencyRefs;
        this.qualifier = qualifier;
        this._ref = ref;
        this.isSatisfied = isSatisfied;
        this.isProxy = isProxy;
        this.proxyResolutions = [];
    }

    get ref():Function
    {
        return this._ref === undefined ? this.instance.constructor : this._ref;
    }
}
