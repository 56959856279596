import * as React from 'react';
import { DataObjectBespokeEditor } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { observer } from 'mobx-react';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { EntityFieldPathEditorStore } from './EntityFieldPathEditorStore';
import Button from '../../../../../Generic/Button/Button';

@observer
export class EntityFieldPathEditor extends DataObjectBespokeEditor<EntityFieldPathEditorStore>
{
    renderComponent(store: DataObjectEditorStore<EntityFieldPathEditorStore>)
    {
        if (store.bespokeStore.buttonStore)
        {
            return <Button
                store={store.bespokeStore.buttonStore}
            />;
        }
        else
        {
            return null;
        }
    }
}
