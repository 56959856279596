import PortalDataSourceFileQuery from '../PortalDataSourceFileQuery';
import { downloadApiFile } from '../../../../@Service/ApiClient/Hooks/downloadApiFile';

export default function downloadPortalDataSourceFile(query: PortalDataSourceFileQuery)
{
    return downloadApiFile(
        '/portal/datasource/file',
        {
            query: JSON.stringify(query.toDescriptor()),
        }
    );
}
