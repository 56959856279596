import { SelectionOptionsValue } from '../Model/SelectionOptionsValue';
import md5 from 'md5';
import { setValueInStorage } from '../../../Setting/Api/useLocalSetting';

export function saveSelectionOptionsValueInStorageAndGetKey(
    selectionOptionsValue: SelectionOptionsValue
): string
{
    const selectionOptionsValueJson = {
        view: selectionOptionsValue?.view?.toDescriptor(true),
        filter: selectionOptionsValue?.filter?.toDescriptor()
    };

    const key = `SelectionOptions.${md5(JSON.stringify(selectionOptionsValueJson))}`;

    setValueInStorage(
        key,
        selectionOptionsValueJson,
        'session'
    );

    return key;
}