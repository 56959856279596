import * as React from 'react';
import { DataObjectBespokeEditor } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { observer } from 'mobx-react';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { DatePeriodType, DatePeriodValue } from './DatePeriodValue';
import StaticSelectbox from '../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { action } from 'mobx';
import { getDatePeriodTypeOptions } from './DatePeriodTypeOptions';
import { FormHandlerContext } from '../../../../../Generic/Form/FormHandlerContext';
import { DataObject } from '../../../Model/DataObject';
import { FormEventListener } from '../../../../../Generic/Form/FormEventListener';
import { FormEvent } from '../../../../../Generic/Form/FormEvent';
import { DataObjectSpecification } from '../../../Model/DataObjectSpecification';
import { DataDescriptor } from '../../../Model/DataDescriptor';
import { NumberType } from '../../Number/NumberType';
import { DataObjectEditor } from '../../../Editor/Value/Editor/DataObjectEditor';

@observer
export class DatePeriodEditor extends DataObjectBespokeEditor
{

    @action.bound
    onChangeType(type: DatePeriodType)
    {
        const value = this.store.dataObject;
        const period = value.getValue()?.period ?? 1;
        value.setValue({
            ...value.getValue(),
            period,
            type: type ?? 'Days',
        });
        this.store.handlerContext.perform(
            new FormEvent<DataObject>(
                FormEvent.Change,
                value
            )
        );
    }

    @action.bound
    onBlurType()
    {
        let value = this.store.dataObject;

        this.store.handlerContext.perform(
            new FormEvent<DataObject>(
                FormEvent.Blur,
                value
            )
        );
    }

    renderComponent(store: DataObjectEditorStore)
    {
        const value = store.dataObject.value as DatePeriodValue;

        let periodHandlerContext = new FormHandlerContext<DataObject>();
        periodHandlerContext.listen(new FormEventListener<DataObject>(
            null,
            event =>
                {
                    const value = store.dataObject;

                    if (event.data.getValue() == null)
                    {
                        value.setValue(undefined);
                    }
                    else
                    {
                        value.setValue(
                            Object.assign(
                                { type: 'Days' },
                                value.getValue(),
                                { period: event.data.getValue() ?? 1 }
                            )
                        );
                    }

                    const newEvent = new FormEvent<DataObject>(event.id, value, event.event);
                    store.handlerContext.perform(newEvent);
                }
            )
        );

        const dataObjectPeriod = new DataObject(new DataObjectSpecification(new NumberType(), {}), new DataDescriptor());

        if (store.dataObject.value)
        {
            dataObjectPeriod.setValue(store.dataObject.value.period);
        }

        const dataObjectPeriodStore = new DataObjectEditorStore({
            dataObject: dataObjectPeriod,
            label: store.label,
            isDisabled: store.isDisabled,
            handlerContext: periodHandlerContext,
        });

        const datePeriodTypeOptions = getDatePeriodTypeOptions()

        return <ViewGroup
            orientation="horizontal"
            spacing={5}
            alignment="center"
        >
            <ViewGroupItem
                ratio={1}
            >
                <DataObjectEditor
                    store={dataObjectPeriodStore}
                />
            </ViewGroupItem>
            <ViewGroupItem
                ratio={1}
            >
                <StaticSelectbox
                    options={datePeriodTypeOptions}
                    value={value?.type}
                    onChange={this.onChangeType}
                    onBlur={this.onBlurType}
                    disabled={store.isDisabled}
                />
            </ViewGroupItem>
        </ViewGroup>;
    }
}
