import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import { LabelPosition } from './EntityInputLayout';
import localizeText from '../../Localization/localizeText';

export default class LabelLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable labelPosition: LabelPosition;
    @observable.ref label: Layout;
    @observable.ref view: Layout;

    // ------------------------ Constructor -------------------------

    constructor(labelPosition: LabelPosition,
                label: Layout,
                view: Layout)
    {
        super();

        this.labelPosition = labelPosition;
        this.label = label;
        this.view = view;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.Label', 'Label met layout');
    }

    validate(): Validation[]
    {
        return [
            ...this.label.validate(),
            ...this.view.validate()
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.label.getDependencies(),
            ...this.view.getDependencies()
        ];
    }

    toDescriptor()
    {
        return {
            type: 'Label',
            labelPosition: this.labelPosition,
            label: this.label.toDescriptor(),
            view: this.view.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new LabelLayout(
            descriptor.labelPosition,
            await getLayoutFromDescriptor(
                descriptor.label,
                dependencyContext),
            await getLayoutFromDescriptor(
                descriptor.view,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
