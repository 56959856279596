import { DataObjectBespokeEditorStore } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { action, computed, observable, reaction } from 'mobx';
import { ComputationEditorStore as InternalComputationEditorStore } from '../../../../../Domain/Computation/ComputationEditorStore';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import { ComputationTypeStore } from '../../../../Computation/ComputationTypeStore';
import isEqual from '../../../../../../@Util/IsEqual/isEqual';

export class ComputationEditorStore extends DataObjectBespokeEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;

    // ------------------------- Properties -------------------------

    @observable doObserveValue: boolean;
    @observable initialSpecification: any;

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    entersUI(isMounted: boolean)
    {
        super.entersUI(isMounted);

        this.setInitialSpecification(
            this.computationTypeStore.toSpecification(
                this.baseStore.dataObject.value));

        this.registerDisposable(
            reaction(
                () => this.computationEditorStore && this.computationEditorStore.descriptor,
                () =>
                {
                    // Internally, transactional model cannot deal with observable arrays and maps, so we do the dirty check here
                    // Otherwise, when you load this store, the transactional model will get dirty instantly (see isEqual in transactional model)
                    if (!isEqual(this.baseStore.dataObject.value && this.computationTypeStore.toSpecification(this.baseStore.dataObject.value), this.computationEditorStore && this.computationEditorStore.descriptor))
                    {
                        this.baseStore.dataObject.setValue(
                            this.computationTypeStore.fromSpecification(
                                this.computationEditorStore && this.computationEditorStore.descriptor));
                    }
                }));
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    @computed
    get computationEditorStore(): InternalComputationEditorStore
    {
        if (this.baseStore.dataObject.context == null || this.initialSpecification == null)
        {
            return null;
        }
        else
        {
            return new InternalComputationEditorStore(
                this.computationTypeStore.getTypeById(this.initialSpecification.type),
                this.baseStore.dataObject.context,
                this.initialSpecification);
        }
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    setDoObserveValue(doObserve: boolean)
    {
        this.doObserveValue = doObserve;
    }

    @action.bound
    setInitialSpecification(specification: any)
    {
        if (specification == null)
        {
            specification =
                {
                    type: 'Composite'
                };
        }

        this.initialSpecification = specification;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
