import React from 'react';
import { observer } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import ExpansionGroup from '../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import ExpansionGroupLayout from '../../../../../@Api/Layout/Type/ExpansionGroupLayout';

export interface ExpansionGroupLayoutViewerProps extends LayoutViewerProps<ExpansionGroupLayout>
{

}

const ExpansionGroupLayoutViewer: React.FC<ExpansionGroupLayoutViewerProps> =
    props =>
    {
        return <ExpansionGroup>
            <LayoutViewer
                {...props}
                layout={props.layout.layout}
            />
        </ExpansionGroup>;
    };

export default observer(ExpansionGroupLayoutViewer);
