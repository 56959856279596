import * as React from 'react';
import { PredicateType } from '../PredicateType';
import { CompositePredicateEditorStore } from './CompositePredicateEditorStore';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { CompositePredicateEditor } from './CompositePredicateEditor';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { CompositePredicateSpecification } from './CompositePredicateSpecification';
import { PredicateContext } from '../../PredicateContext';
import { PredicateTypeStore } from '../../PredicateTypeStore';
import { PredicateEditorStore } from '../../PredicateEditorStore';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';
import { EntityContext } from '../../../Entity/@Model/EntityContext';
import { OldCompositePredicate } from './OldCompositePredicate';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';

export class CompositePredicateType extends PredicateType<CompositePredicateEditorStore, CompositePredicateSpecification, OldCompositePredicate>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('PredicateTypeStore') predicateTypeStore: PredicateTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return 'Composite';
    }

    name(): string
    {
        return this.localizationStore.translate('CompositePredicateType.Name'); // And / Or
    }

    allow(context: PredicateContext): boolean
    {
        return true;
    }

    isTerminal(): boolean
    {
        return false;
    }

    fromSpecification(specification: CompositePredicateSpecification): OldCompositePredicate
    {
        return new OldCompositePredicate(
            (LogicalOperator as any)[specification.logicalOperator],
            specification.predicates.map(
                childPredicate =>
                    this.predicateTypeStore.fromSpecification(childPredicate)));
    }

    toSpecification(predicate: OldCompositePredicate): CompositePredicateSpecification
    {
        return {
            type: this.id(),
            logicalOperator: LogicalOperator[predicate.logicalOperator],
            predicates:
                predicate.predicates.map(
                    childPredicate =>
                        this.predicateTypeStore.toSpecification(childPredicate))
        };
    }

    evaluate(context: PredicateContext,
             predicate: OldCompositePredicate): boolean
    {
        if (predicate.logicalOperator == null)
        {
            return true;
        }
        else
        {
            if (predicate.predicates.length === 0)
            {
                return predicate.logicalOperator === LogicalOperator.And;
            }
            else
            {
                for (let childPredicate of predicate.predicates)
                {
                    let result = childPredicate.evaluate(context);

                    switch (predicate.logicalOperator)
                    {
                        case LogicalOperator.And:
                            if (!result)
                            {
                                return false;
                            }

                            break;

                        case LogicalOperator.Or:
                            if (result)
                            {
                                return true;
                            }

                            break;
                    }
                }

                switch (predicate.logicalOperator)
                {
                    case LogicalOperator.And:
                        return true;

                    case LogicalOperator.Or:
                        return false;

                    default:
                        return false;
                }
            }
        }
    }

    editorStore(context: PredicateContext,
                specification: CompositePredicateSpecification): CompositePredicateEditorStore
    {
        return new CompositePredicateEditorStore(
            this as any,
            context,
            specification,
            (LogicalOperator as any)[specification.logicalOperator || 'And'],
            (specification.predicates || []).map(
                predicate => (
                    PredicateEditorStore.construct(
                        context,
                        predicate,
                        this.predicateTypeStore))));
    }

    editorView(): React.ComponentClass<BaseComponentProps<CompositePredicateEditorStore>>
    {
        return CompositePredicateEditor;
    }

    editorSpecification(store: CompositePredicateEditorStore): CompositePredicateSpecification
    {
        return {
            type: this.id(),
            logicalOperator: LogicalOperator[store.logicalOperator],
            predicates: store.predicateStores.map(
                predicateStore => predicateStore.type.editorSpecification(
                    predicateStore.editorStore))
        };
    }

    entityFieldPaths(specification: CompositePredicateSpecification,
                     context: EntityContext,
                     parameter?: string): EntityFieldPath[]
    {
        let paths: EntityFieldPath[] = [];

        if (specification.predicates)
        {
            specification.predicates.forEach(
                predicate =>
                {
                    let predicateType = this.predicateTypeStore.getTypeById(predicate.type);

                    if (predicateType)
                    {
                        paths.push(
                            ...predicateType.entityFieldPaths(
                                predicate,
                                context,
                                parameter));
                    }
                });
        }

        return paths;
    }

    // ----------------------- Private logic ------------------------
}
