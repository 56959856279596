import { observable } from 'mobx';
import { BespokeAttachment } from './BespokeAttachment';
import { BespokeEntityType } from '../BespokeEntityType';
import { BespokeActivity } from './Activity/BespokeActivity';
import { EntityTypeStore } from '../EntityTypeStore';
import { BespokeEvent } from './BespokeEvent';
import { BespokeNote } from './BespokeNote';
import { BespokeRelation } from './BespokeRelation';
import { BespokeRelationship } from './BespokeRelationship';
import { BespokeDataset } from './BespokeDataset';
import { BespokeDatasetCategory } from './BespokeDatasetCategory';
import { BespokeEntityTypeGroup } from './BespokeEntityTypeGroup';
import { BespokeLayout } from './BespokeLayout';
import { BespokeInvolved } from './BespokeInvolved';
import { BespokeTimeRegistration } from './BespokeTimeRegistration';
import { BespokeAddress } from './BespokeAddress';
import { BespokeEmailAddress } from './BespokeEmailAddress';
import { BespokeProductLine } from './BespokeProductLine';
import { BespokeExternalCost } from './BespokeExternalCost';
import { BespokePhoneNumber } from './BespokePhoneNumber';
import { BespokeDatastorePhase } from './Datastore/BespokeDatastorePhase';
import { BespokeMilestone } from './BespokeMilestone';
import { BespokeNotification } from './BespokeNotification';
import { BespokeDashboard } from './BespokeDashboard';
import { BespokeProduct } from './BespokeProduct';
import { BespokeCalendarItem } from './BespokeCalendarItem';
import { BespokeTeam } from './BespokeTeam';
import { BespokeTemplate } from './BespokeTemplate';
import { BespokePack } from './BespokePack';
import { BespokeEmailClick } from './BespokeEmailClick';
import { BespokePortalUser } from './BespokePortalUser';
import { BespokeCustomEntity } from './BespokeCustomEntity';
import { BespokeAutomation } from './BespokeAutomation';
import { BespokeMileageRegistration } from './BespokeMileageRegistration';
import { BespokeEmployeeAllocation } from './BespokeEmployeeAllocation';
import { BespokePipeline } from './BespokePipeline';
import { BespokeDatastore } from './Datastore/BespokeDatastore';
import { BespokeDatastoreAppointmentType } from './Datastore/BespokeDatastoreAppointmentType';
import { BespokeDatastoreTaskType } from './Datastore/BespokeDatastoreTaskType';
import { BespokePrice } from './BespokePrice';

export class BespokeEntity extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable attachment: BespokeAttachment;
    @observable relation: BespokeRelation;
    @observable relationship: BespokeRelationship;
    @observable activity: BespokeActivity;
    @observable customEntity: BespokeCustomEntity;
    @observable event: BespokeEvent;
    @observable note: BespokeNote;
    @observable notification: BespokeNotification;
    @observable layout: BespokeLayout;
    @observable dashboard: BespokeDashboard;
    @observable datastore: BespokeDatastore;
    @observable datastoreAppointmentType: BespokeDatastoreAppointmentType;
    @observable datastorePhase: BespokeDatastorePhase;
    @observable datastoreTaskType: BespokeDatastoreTaskType;
    @observable dataset: BespokeDataset;
    @observable datasetCategory: BespokeDatasetCategory;
    @observable entityTypeGroup: BespokeEntityTypeGroup;
    @observable involved: BespokeInvolved;
    @observable timeRegistration: BespokeTimeRegistration;
    @observable mileageRegistration: BespokeMileageRegistration;
    @observable employeeAllocation: BespokeEmployeeAllocation;
    @observable address: BespokeAddress;
    @observable emailAddress: BespokeEmailAddress;
    @observable phoneNumber: BespokePhoneNumber;
    @observable product: BespokeProduct;
    @observable productLine: BespokeProductLine;
    @observable price: BespokePrice;
    @observable milestone: BespokeMilestone;
    @observable externalCost: BespokeExternalCost;
    @observable calendarItem: BespokeCalendarItem;
    @observable team: BespokeTeam;
    @observable template: BespokeTemplate;
    @observable emailClick: BespokeEmailClick;
    @observable pack: BespokePack;
    @observable portalUser: BespokePortalUser;
    @observable automation: BespokeAutomation;
    @observable pipeline: BespokePipeline;

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Entity')
    {
        super(entityTypeStore, code);

        this.attachment = new BespokeAttachment(this.entityTypeStore);
        this.relation = new BespokeRelation(this.entityTypeStore);
        this.relationship = new BespokeRelationship(this.entityTypeStore);
        this.activity = new BespokeActivity(this.entityTypeStore);
        this.customEntity = new BespokeCustomEntity(this.entityTypeStore);
        this.event = new BespokeEvent(this.entityTypeStore);
        this.note = new BespokeNote(this.entityTypeStore);
        this.notification = new BespokeNotification(this.entityTypeStore);
        this.layout = new BespokeLayout(this.entityTypeStore);
        this.dashboard = new BespokeDashboard(this.entityTypeStore);
        this.datastore = new BespokeDatastore(this.entityTypeStore);
        this.datastoreAppointmentType = new BespokeDatastoreAppointmentType(this.entityTypeStore);
        this.datastorePhase = new BespokeDatastorePhase(this.entityTypeStore);
        this.datastoreTaskType = new BespokeDatastoreTaskType(this.entityTypeStore);
        this.dataset = new BespokeDataset(this.entityTypeStore);
        this.datasetCategory = new BespokeDatasetCategory(this.entityTypeStore);
        this.entityTypeGroup = new BespokeEntityTypeGroup(this.entityTypeStore);
        this.involved = new BespokeInvolved(this.entityTypeStore);
        this.timeRegistration = new BespokeTimeRegistration(this.entityTypeStore);
        this.mileageRegistration = new BespokeMileageRegistration(this.entityTypeStore);
        this.employeeAllocation = new BespokeEmployeeAllocation(this.entityTypeStore);
        this.address = new BespokeAddress(this.entityTypeStore);
        this.emailAddress = new BespokeEmailAddress(this.entityTypeStore);
        this.phoneNumber = new BespokePhoneNumber(this.entityTypeStore);
        this.product = new BespokeProduct(this.entityTypeStore);
        this.productLine = new BespokeProductLine(this.entityTypeStore);
        this.price = new BespokePrice(this.entityTypeStore);
        this.milestone = new BespokeMilestone(this.entityTypeStore);
        this.externalCost = new BespokeExternalCost(this.entityTypeStore);
        this.calendarItem = new BespokeCalendarItem(this.entityTypeStore);
        this.team = new BespokeTeam(this.entityTypeStore);
        this.template = new BespokeTemplate(this.entityTypeStore);
        this.emailClick = new BespokeEmailClick(this.entityTypeStore);
        this.pack = new BespokePack(this.entityTypeStore);
        this.portalUser = new BespokePortalUser(this.entityTypeStore);
        this.automation = new BespokeAutomation(this.entityTypeStore);
        this.pipeline = new BespokePipeline(this.entityTypeStore);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
