import { useEffect } from 'react';
import { PortalTheme } from '../Theme/PortalTheme';

export function usePortalThemeTitle(
    portalTheme?: PortalTheme
)
{
    useEffect(
        () =>
        {
            if (portalTheme)
            {
                const oldTitle = document.title;
                document.title = portalTheme.title;

                return () =>
                {
                    document.title = oldTitle;
                }
            }
        },
        [
            portalTheme,
        ]
    );
}