import { observable } from 'mobx';
import { BaseComponentProps } from '../../../../@Framework/Component/BaseComponent';
import { PageStore } from '../PageStore';
import { ComponentPage } from './ComponentPage';

export class ComponentPageStore extends PageStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable name: string;
    @observable.ref internalComponentClass: React.ComponentClass<BaseComponentProps<PageStore>>;
    @observable.ref internalComponentProps: any;
    @observable icon: string;

    // ------------------------ Constructor -------------------------

    constructor(name: string,
                componentClass: any,
                componentProps: any = {},
                icon?: string,
                hasPagePadding: boolean = true)
    {
        super(0);

        this.name = name;
        this.internalComponentClass = componentClass;
        this.internalComponentProps = componentProps;
        this.icon = icon;
        this.hasPagePadding = hasPagePadding;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    get componentClass(): React.ComponentClass<BaseComponentProps<PageStore>>
    {
        return ComponentPage;
    }

    // ----------------------- Private logic ------------------------
}
