import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { openEntity } from '../../../@Util/openEntity';
import styles from './Link.module.scss';
import ClickableContext from '../../Context/ClickableContext';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';

export interface LinkClasses
{
    root?: string;
    link?: string;
}

export interface LinkProps
{
    entity: Entity;
    classes?: LinkClasses;
    stopPropagation?: boolean;
}

const Link: React.FC<LinkProps> =
    props =>
    {
        const isEntireItemClickable = useContext(ClickableContext);
        const onClick =
            useCallback(
                (event: MouseEvent) =>
                {
                    if (props.stopPropagation)
                    {
                        event.stopPropagation();
                    }

                    openEntity(props.entity);
                },
                [
                    props.stopPropagation,
                    props.entity
                ]);
        const isOpenable =
            useComputed(
                () =>
                    props.entity.entityType.bespoke.isOpenable(props.entity),
                [
                    props.entity
                ]);

        const content: any = props.children || props.entity.name || '-';

        if (isOpenable
            && !isEntireItemClickable)
        {
            return <span
                className={
                    classNames(
                        styles.root,
                        props.classes && props.classes.root,
                        styles.link,
                        props.classes && props.classes.link)
                }
                onClick={onClick as any}
            >
                {content}
            </span>;
        }
        else
        {
            return <span
                className={
                    classNames(
                        styles.root,
                        props.classes && props.classes.root)
                }
            >
                {content}
            </span>;
        }
    };

export default observer(Link);
