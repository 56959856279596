import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import Segment from '../../Model/Segment';

export default function getResultName(segment: Segment,
                                      result: EntitySelectionAggregateResult,
                                      idx: number)
{
    const localizationStore = loadModuleDirectly(LocalizationStore);

    if (segment.isDate && segment.isDateSegmentedInCategories)
    {
        switch (idx)
        {
            case 0:
                return localizationStore.translate('DateRange.Past'); // Past

            case 1:
                return localizationStore.translate('DateRange.Today'); // Today

            case 2:
                return localizationStore.translate('DateRange.Tomorrow'); // Tomorrow

            case 3:
                return localizationStore.translate('DateRange.Later'); // Later

            default:
                return localizationStore.translate('Generic.NoValue'); // No value
        }
    }
    else if (result.groupEntity)
    {
        return result.groupEntity.name;
    }
    else if (result.groupValue)
    {
        if (result.groupValue.value instanceof EntityType)
        {
            return result.groupValue.value.namePlural;
        }
        else
        {
            return result.groupValue.toString();
        }
    }
    else
    {
        return localizationStore.translate('Generic.NoValue'); // No value
    }
}
