import isDomainOrganizationSpecific from './isDomainOrganizationSpecific';

export default function getOrganizationDomainName(emailAddress: string)
{
    if (emailAddress)
    {
        const domain = emailAddress.replace(/.*@/, '');

        if (isDomainOrganizationSpecific(domain))
        {
            return domain;
        }
        else
        {
            return undefined;
        }
    }
    else
    {
        return undefined;
    }
}
