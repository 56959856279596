import ParameterDictionary from '../Automation/Parameter/ParameterDictionary';
import { DeferredInitializer } from '../Automation/DeferredInitializer/DeferredInitializer';
import AutomationDependencyContext from '../Automation/AutomationDependencyContext';

export default class LayoutDependencyContext
{
    // ------------------------- Properties -------------------------

    parameterDictionary: ParameterDictionary;
    deferredInitializer?: DeferredInitializer;

    // ------------------------ Constructor -------------------------

    constructor(
        parameterDictionary: ParameterDictionary,
        deferredValueInitializer?: DeferredInitializer
    )
    {
        this.parameterDictionary = parameterDictionary;
        this.deferredInitializer = deferredValueInitializer;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    withParameterDictionary(parameterDictionary: ParameterDictionary)
    {
        return new LayoutDependencyContext(
            parameterDictionary,
            this.deferredInitializer
        );
    }

    static fromAutomationDependencyContext(
        dependencyContext: AutomationDependencyContext
    )
    {
        return new LayoutDependencyContext(
            dependencyContext.parameterDictionary,
            dependencyContext.deferredInitializer
        );
    }

    // ----------------------- Private logic ------------------------
}
