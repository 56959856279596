import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Value from '../../Value/Value';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import CollectionValue from '../../Value/CollectionValue';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import localizeText from '../../../Localization/localizeText';

export default class JoinedCollectionComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref lhs: Computation<any, any>;
    @observable.ref rhs: Computation<any, any>;

    // ------------------------ Constructor -------------------------


    constructor(
        lhs: Computation<any, any>,
        rhs: Computation<any, any>
    )
    {
        super();

        this.lhs = lhs;
        this.rhs = rhs;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return this.lhs.getType();
    }

    isAsync(): boolean
    {
        return this.lhs.isAsync()
            || this.rhs.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const lhsCollection = await safelyApplyFunction(this.lhs, context);

        if (lhsCollection instanceof CollectionValue)
        {
            const lhsElements = CollectionValue.getCollection(lhsCollection);
            const rhsCollection = await safelyApplyFunction(this.rhs, context);
            const rhsElements = CollectionValue.getCollection(rhsCollection);

            return new CollectionValue(
                [
                    ...lhsElements,
                    ...rhsElements,
                ],
                lhsCollection.elementType
            );
        }
        else
        {
            throw new Error(`Expected collection but got ${lhsCollection.getName()}`);
        }
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        const lhsCollection = safelySynchronousApplyFunction(this.lhs, context);

        if (lhsCollection instanceof CollectionValue)
        {
            const lhsElements = CollectionValue.getCollection(lhsCollection);
            const rhsCollection = safelySynchronousApplyFunction(this.rhs, context);
            const rhsElements = CollectionValue.getCollection(rhsCollection);

            return new CollectionValue(
                [
                    ...lhsElements,
                    ...rhsElements,
                ],
                lhsCollection.elementType
            );
        }
        else
        {
            throw new Error(`Expected collection but got ${lhsCollection.getName()}`);
        }
    }

    getName(): string
    {
        return localizeText(
            'JoinedCollection',
            'Samengevoegde collectie'
        );
    }

    validate(): Validation[]
    {
        return [
            ...this.lhs.validate(),
            ...this.rhs.validate(),
        ];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'JoinedCollection';
        descriptor.lhs = this.lhs.toDescriptor();
        descriptor.rhs = this.rhs.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.lhs.getDependencies(),
            ...this.rhs.getDependencies(),
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const lhs =
            await getComputationFromDescriptor(
                descriptor.lhs,
                dependencyContext
            );
        const rhs =
            await getComputationFromDescriptor(
                descriptor.rhs,
                dependencyContext
            );

        return new JoinedCollectionComputation(
            lhs,
            rhs
        );
    }

    // ----------------------- Private logic ------------------------
}
