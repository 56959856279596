import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../DataObject/Model/DataObject';
import Value from '../../../../../@Api/Automation/Value/Value';
import { AssistantEntityTypeValue } from '../../Model/Value/AssistantEntityTypeValue';
import { findAssistantEntityTypeOrThrow } from '../findAssistantEntityTypeOrThrow';

export function findAssistantEntityTypeValue(
    value: AssistantEntityTypeValue
): Value<any, any>
{
    const entityType = findAssistantEntityTypeOrThrow(value.value);

    return new PrimitiveValue(
        DataObject.constructFromTypeIdAndValue(
            'EntityType',
            entityType
        )
    );
}
