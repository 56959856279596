import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { PredicateEditorProps } from '../../PredicateEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationEditor from '../../../Computation/ComputationEditor';
import styles from './InstanceOfPredicateEditor.module.scss';
import InstanceOfPredicate from '../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/InstanceOfPredicate';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import ValueTypeEditor from '../../../Value/ValueTypeEditor';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface InstanceOfPredicateEditorProps extends PredicateEditorProps<InstanceOfPredicate>
{

}

const InstanceOfPredicateEditor: React.FC<InstanceOfPredicateEditorProps> =
    props =>
    {
        const { value, context } = props;

        const setValue =
            useCallback(
                (innerValue: Computation<any, any>) =>
                    runInAction(
                        () =>
                            value.value = innerValue),
                [
                    value
                ]);

        const setValueType =
            useCallback(
                (valueType: ValueType<any>) =>
                    runInAction(
                        () =>
                            value.valueType = valueType),
                [
                    value
                ]);

        const computationValueType =
            useComputed(
                () =>
                    value.value?.isValid()
                        ?
                            value.value?.getType()
                        :
                            undefined,
                [
                    value
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                <div
                    className={styles.side}
                >
                    <ComputationEditor
                        value={value.value}
                        onChange={setValue}
                        context={context}
                        constructorItemFilter={props.constructorItemFilter}
                        disallowRichText={props.disallowRichText}
                        disallowTextComputation={props.disallowTextComputation}
                        autoFocus={props.autoFocus}
                        disabled={props.disabled}
                    />
                </div>
            </ViewGroupItem>
            <ViewGroupItem>
                <LocalizedText
                    code="Generic.IsInstanceOf"
                    value="Is instantie van"
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <div
                    className={styles.side}
                >
                    <ValueTypeEditor
                        value={value.valueType}
                        onChange={setValueType}
                        parentType={computationValueType}
                        autoFocus={props.autoFocus}
                    />
                </div>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(InstanceOfPredicateEditor);
