import { DataObjectBespokeEditorStore } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { computed } from 'mobx';
import { EntityFieldPathPickerStore } from '../../../../Entity/Path/Field/EntityFieldPathPickerStore';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import { ButtonStore } from '../../../../../Generic/Button/ButtonStore';
import { ViewComponent } from '../../../../../Generic/ViewStack/Model/ViewComponent';
import Menu from '../../../../../Generic/Menu/Menu';
import { FormEvent } from '../../../../../Generic/Form/FormEvent';
import { DataObject } from '../../../Model/DataObject';
import { ModalDialogStore } from '../../../../../Generic/Dialog/Type/ModalDialogStore';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { EntityTypeStore } from '../../../../Entity/Type/EntityTypeStore';
import { FeedbackStore } from '../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';

export class EntityFieldPathEditorStore extends DataObjectBespokeEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('FeedbackStore') feedbackStore: FeedbackStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    @computed
    get buttonStore(): ButtonStore
    {
        if (this.baseStore.dataObject.context.entityContext)
        {
            return new ButtonStore(
            {
                label:
                    () =>
                        this.baseStore.dataObject.isEmpty
                            ?
                                '...'
                            :
                                (this.baseStore.dataObject.value as EntityFieldPath).getAdministrativeName(this.entityTypeStore, false),
                onClick:
                    () =>
                    {
                        const closeDialog =
                            this.feedbackStore.openDialog({
                                store:
                                    new ModalDialogStore({
                                        viewComponent:
                                            () =>
                                                new ViewComponent(
                                                    Menu as any,
                                                    new EntityFieldPathPickerStore(
                                                        this.baseStore.dataObject.context.entityContext,
                                                        path =>
                                                        {
                                                            this.baseStore.dataObject.setValue(path);
                                                            closeDialog();

                                                            this.baseStore.handlerContext.perform(
                                                                new FormEvent<DataObject>(
                                                                    FormEvent.Change,
                                                                    this.baseStore.dataObject));
                                                        })),
                                        buttons:
                                            () => [
                                                new ButtonStore({
                                                    label: () => this.localizationStore.translate('Generic.Cancel'), // Cancel
                                                    isRaised: false,
                                                    color: 'default',
                                                    onClick: () => closeDialog()
                                                }),
                                                new ButtonStore({
                                                    label: () => this.localizationStore.translate('Generic.Deleted'), // deleted
                                                    isRaised: false,
                                                    color: 'primary',
                                                    onClick:
                                                        () =>
                                                        {
                                                            this.baseStore.dataObject.setValue(undefined);
                                                            closeDialog();

                                                            this.baseStore.handlerContext.perform(
                                                                new FormEvent<DataObject>(
                                                                    FormEvent.Change,
                                                                    this.baseStore.dataObject));
                                                        }
                                                })
                                            ],
                                        hasContentInset: false
                                    })
                            });
                    }
            });
        }
        else
        {
            return null;
        }
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
