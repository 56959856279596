import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import useCampaignClickedCount from '../Hooks/useCampaignClickedCount';
import CampaignPercentageChart from './CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import useCampaignOpenedCount from '../Hooks/useCampaignOpenedCount';

export interface CampaignChartClickedProps
{
    size: number;
    campaign: Entity;
}

const CampaignChartClicked: React.FC<CampaignChartClickedProps> =
    props =>
    {
        const campaign = props.campaign;

        const openedCount = useCampaignOpenedCount(campaign);
        const clickedCount = useCampaignClickedCount(campaign);

        const allSet =
            useAllSet([
                openedCount,
                clickedCount
            ]);

        const formattedSelection =
            useMemo(
                () =>
                {
                    return `${clickedCount} / ${openedCount}`;
                },
                [
                    clickedCount,
                    openedCount
                ]
            );

        return <div>
            {
                allSet &&
                    <CampaignPercentageChart
                        value={clickedCount / openedCount * 100}
                        size={props.size}
                        label={localizeText('Campaign.Chart.Clicked', 'Geklikt')}
                        selection={formattedSelection}
                    />
            }
        </div>;
    };

export default observer(CampaignChartClicked);
