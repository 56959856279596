import { observable } from 'mobx';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import FieldMapping from '../FieldMapping';

export default class EntityFieldMapping extends FieldMapping
{
    // ------------------------- Properties -------------------------

    @observable entityId: string;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                targetFieldPath: EntityFieldPath,
                entityId: string)
    {
        super(id, targetFieldPath);

        this.entityId = entityId;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        const targetFieldPath = EntityFieldPath.construct(descriptor.targetFieldPath);

        return new EntityFieldMapping(
            descriptor.id,
            targetFieldPath,
            descriptor.entityId);
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Entity',
            entityId: this.entityId
        };
    }

    // ----------------------- Private logic ------------------------
}
