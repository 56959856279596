import { Mutex } from '../Mutex/Mutex';

export interface AsyncCache<ID, V>
{
    getOrLoad: (id: ID) => Promise<V>;
    insert: (id: ID, value: V) => void;
}

export function createAsyncCache<ID, V>(loader: (id: ID) => Promise<V>): AsyncCache<ID, V>
{
    const keyMutex = new Mutex();
    const cache = new Map<ID, V>();
    const loaderMutexById = new Map<ID, Mutex>();

    return {
        getOrLoad:
            async (id: ID) =>
            {
                const loaderMutex =
                    await keyMutex.dispatch(
                        () =>
                        {
                            const loaderMutex = loaderMutexById.get(id) || new Mutex();
                            loaderMutexById.set(id, loaderMutex);

                            return loaderMutex;
                        });

                return await loaderMutex.dispatch(
                    async () =>
                    {
                        const value = cache.get(id);

                        if (value === undefined)
                        {
                            const loadedValue = await loader(id);
                            cache.set(id, loadedValue);
                            return loadedValue;
                        }
                        else
                        {
                            return value;
                        }
                    });
            },
        insert:
            (id, value) =>
            {
                cache.set(id, value);
            }
    }
}
