import Computation from '../Computation';
import PrimitiveValueType from '../../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../../Value/PrimitiveValue';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../../../../../@Component/Domain/DataObject/DataObjectStore';
import { OldPredicate } from '../../../../../@Component/Domain/Predicate/Type/OldPredicate';
import FunctionContext from '../../FunctionContext';
import { DataObject } from '../../../../../@Component/Domain/DataObject/Model/DataObject';
import { ComputationSubstitution } from '../Util/ComputationSubstitution';
import Parameter from '../../../Parameter/Parameter';

export default abstract class Predicate extends Computation<PrimitiveValueType, PrimitiveValue>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): PrimitiveValueType
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('Boolean'));
    }

    async apply(context: FunctionContext)
    {
        return new PrimitiveValue(
            DataObject.constructFromTypeIdAndValue(
                'Boolean',
                await this.evaluate(context)));
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        return new PrimitiveValue(
            DataObject.constructFromTypeIdAndValue(
                'Boolean',
                this.synchronouslyEvaluate(context)));
    }

    abstract evaluate(context: FunctionContext): Promise<boolean>;

    abstract synchronouslyEvaluate(context: FunctionContext): boolean;

    abstract getTrueStatements(
        context: FunctionContext,
        parameter?: Parameter<any>
    ): Predicate[];

    abstract normalize(): Predicate;

    abstract substitute(substitution: ComputationSubstitution): Predicate;

    toDescriptor()
    {
        const descriptor = {
            ...super.toDescriptor(),
            class: 'Predicate'
        };

        this.augmentDescriptor(descriptor);

        return descriptor;
    }

    toOldPredicate(): OldPredicate
    {
        return undefined;
    }

    // ----------------------- Private logic ------------------------
}
