import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { RelatedEntityPath } from "../../Path/@Model/RelatedEntityPath";
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';

export class BespokeTeam extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Team')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    orderByField(entity?: Entity, pathFromRelatedEntity?: RelatedEntityPath): EntityFieldPath
    {
        return EntityPath.root(this.type)
            .field(this.types.Team.Field.LocalizedName);
    }

    // ----------------------- Private logic ------------------------
}
