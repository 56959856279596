import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch as MuiSwitch } from '@material-ui/core';
import { SwitchProps as MuiSwitchProps } from '@material-ui/core/Switch';
import { useButtonClickHandler } from '../../Button/Button';

export interface SwitchProps
{
    checked?: boolean;
    disabled?: boolean;
    onToggle: (isChecked: boolean, event: MouseEvent) => void;
    baseProps?: Partial<MuiSwitchProps>;
    large?: boolean;
}

const Switch: React.FC<SwitchProps> =
    props =>
    {
        const { onToggle } = props;

        const onClick =
           useCallback(
               (event: any) =>
                   onToggle(!props.checked, event),
               [
                   props.checked,
                   onToggle
               ]);

        const [ isLoading, setLoading ] = useState(false);
        const clickCallback = useButtonClickHandler(onClick, setLoading, true);

        return <MuiSwitch
            checked={props.checked}
            disabled={props.disabled || isLoading}
            color="primary"
            onClick={clickCallback}
            size={props.large ? 'medium' : 'small'}
            {...props.baseProps}
        />;
    };

Switch.defaultProps = {
    checked: false
};

export default observer(Switch);
