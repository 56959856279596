import { MathematicalOperator } from '../Model/MathematicalOperator';
import { LogicalOperator } from '../Model/LogicalOperator';
import { Comparator } from '../Model/Comparator';
import { Aggregate } from '../Model/Aggregate';
import localizeText from '../../../../@Api/Localization/localizeText';

export function getLogicalOperatorSymbol(operator: LogicalOperator)
{
    switch (operator)
    {
        case LogicalOperator.And:
            return 'en';

        case LogicalOperator.Or:
            return 'of';
    }

    return undefined;
}

export function getMathematicalOperatorSymbol(operator: MathematicalOperator)
{
    switch (operator)
    {
        case MathematicalOperator.Add:
            return '+';

        case MathematicalOperator.Subtract:
            return '−';

        case MathematicalOperator.Multiply:
            return '×';

        case MathematicalOperator.Divide:
            return '÷';
    }

    return undefined;
}

export function getComparatorSymbol(comparator: Comparator)
{
    switch (comparator)
    {
        case Comparator.Equals:
            return '=';

        case Comparator.NotEquals:
            return '≠';

        case Comparator.GreaterThan:
            return '>';

        case Comparator.GreaterThanOrEqual:
            return '≥';

        case Comparator.LessThan:
            return '<';

        case Comparator.LessThanOrEqual:
            return '≤';

        case Comparator.IsDefined:
            return localizeText('Comparator.IsDefined', 'heeft een waarde');

        case Comparator.IsNotDefined:
            return localizeText('Comparator.IsNotDefined', 'heeft géén waarde');

        case Comparator.StartsWith:
            return localizeText('Comparator.StartsWith', 'start met');

        case Comparator.EndsWith:
            return localizeText('Comparator.EndsWith', 'eindigt met');

        case Comparator.In:
            return localizeText('Comparator.In', 'zit in');

        case Comparator.Contains:
            return localizeText('Comparator.Contains', 'bevat');

        case Comparator.NotContains:
            return localizeText('Comparator.NotContains', 'bevat niet');
    }

    return undefined;
}

export function getAggregateSymbol(aggregate: Aggregate)
{
    switch (aggregate)
    {
        case Aggregate.Count:
            return localizeText('Aggregate.Count', 'aantal');

        case Aggregate.Sum:
            return localizeText('Aggregate.Sum', 'som');

        case Aggregate.Average:
            return localizeText('Aggregate.Average', 'gemiddelde');

        case Aggregate.Max:
            return localizeText('Aggregate.Max', 'maximum');

        case Aggregate.Min:
            return localizeText('Aggregate.Min', 'minimun');
    }

    return undefined;
}

export function getAggregates()
{
    return [
        Aggregate.Count,
        Aggregate.Sum,
        Aggregate.Average,
        Aggregate.Max,
        Aggregate.Min
    ];
}
