import { catchImport } from '../Import/catchImport';

let isSetup = false;

export function sanitize(html: string, enableJsLinkHandler?: boolean): Promise<string | undefined>
{
	if (typeof html !== 'string')
	{
		return Promise.resolve(undefined);
	}

	return import('sanitize-html')
		.then(
			sanitizeHtml =>
			{
				if (!isSetup)
				{
					// https://github.com/punkave/sanitize-html
					sanitizeHtml.defaults.allowedTags =
						sanitizeHtml.defaults.allowedTags
							.filter(
								tag =>
									tag !== 'iframe');

					isSetup = true;
				}

				let sanitized =
					sanitizeHtml.default(
						html,
						{
							allowedTags:
								[
									'h3',
									'h4',
									'h5',
									'h6',
									'blockquote',
									'p',
									'a',
									'ul',
									'ol',
									'nl',
									'li',
									'b',
									'i',
									'u',
									's',
									'strong',
									'em',
									'strike',
									'code',
									'hr',
									'br',
									'div',
									'table',
									'thead',
									'caption',
									'tbody',
									'tr',
									'th',
									'td',
									'pre',
									'span',
									'style'
								],
							allowedClasses:
								{
									'*':
										[
											'ql-size-small',
											'ql-size-large',
											'ql-size-huge',
											'ql-align-right',
											'ql-align-center',
											'computed'
										]
								},
							allowedAttributes:
								{
									'*':
										[
											'style',
										],
									'a':
										[
											'href',
											'target'
										]
								},
							allowedStyles:
								{
									'*':
										{
											'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
										}
								},
							allowedSchemes: [
								...sanitizeHtml.defaults.allowedSchemes,
								'tel'
							]
						});

				if (enableJsLinkHandler)
				{
					sanitized = sanitized.replace(
						'<a href=',
						'<a onclick="window.open(event.target.href, event.target.target); event.preventDefault();" href=');
				}

				return Promise.resolve(sanitized);
			})
		.catch(catchImport);
}

export function getTextFromHtml(value?: string)
{
	if (value)
	{
		// https://stackoverflow.com/a/48687568
		const d = document.createElement('div');
		d.innerHTML = value;
		const text = d.innerText;
		d.remove();

		return text;
	}
	else
	{
		return undefined;
	}
}
