import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import FavoriteCallbackContext from '../FavoriteCallbackContext';
import Segment from '../../Model/Segment';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';

export interface ButtonProps
{
    segment?: Segment;
    filter?: Predicate;
}

const Button: React.FC<ButtonProps> =
    props =>
    {
        const favoriteCallback = useContext(FavoriteCallbackContext);
        const favorite =
            useCallback(
                () =>
                    favoriteCallback(
                        props.segment,
                        props.filter),
                [
                    favoriteCallback,
                    props.segment,
                    props.filter
                ]);

        return <IconButton
            icon="add"
            onClick={favorite}
            tooltip="Toevoegen aan dashboard"
        />;
    };

export default observer(Button);
