import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import CollectionType from '../../Value/Type/CollectionType';
import Value from '../../Value/Value';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import CollectionValue from '../../Value/CollectionValue';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import localizeText from '../../../Localization/localizeText';

export default class FirstElementOfCollectionComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref collection: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(collection: Computation<any, any>)
    {
        super();

        this.collection = collection;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return (this.collection.getType() as CollectionType<any>).type;
    }

    isAsync(): boolean
    {
        return this.collection.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const collection = await safelyApplyFunction(this.collection, context);

        if (collection instanceof CollectionValue)
        {
            const elementsInCollection = CollectionValue.getCollection(collection);

            if (elementsInCollection.length > 0)
            {
                return elementsInCollection[0];
            }
            else
            {
                return undefined;
            }
        }
        else
        {
            throw new Error(`Expected collection but got ${collection.getName()}`);
        }
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        const collection = safelySynchronousApplyFunction(this.collection, context);

        if (collection instanceof CollectionValue)
        {
            const elementsInCollection = CollectionValue.getCollection(collection);

            if (elementsInCollection.length > 0)
            {
                return elementsInCollection[0];
            }
            else
            {
                return undefined;
            }
        }
        else
        {
            throw new Error(`Expected collection but got ${collection.getName()}`);
        }
    }

    getName(): string
    {
        return localizeText(
            'FirstElementOfCollection',
            'Eerste element'
        );
    }

    validate(): Validation[]
    {
        return this.collection.validate();
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'FirstElementOfCollection';
        descriptor.collection = this.collection.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.collection.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const collection = await getComputationFromDescriptor(descriptor.collection, dependencyContext);
        const collectionType = collection.getType();

        if (collectionType instanceof CollectionType)
        {
            return new FirstElementOfCollectionComputation(collection);
        }
        else
        {
            throw new Error(`Expected collection but got ${collectionType.getName()}`);
        }
    }

    // ----------------------- Private logic ------------------------
}
