import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { EntityEvent } from '../../Model/Implementation/EntityEvent';
import { EntityByDate } from '../../Model/Implementation/EntityByDate';
import { EntityEventsPage } from '../../../@Component/Domain/Entity/Timeline/Model/EntityEventsPage';

export class EntityEventController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    countEvents(
        entityTypeId: number,
        entityId: number,
        userId: number,
        ownerId: number,
        assigneeId: number,
        fromDate: number,
        toDate: number,
        includeHidden: boolean,
        isPinned: boolean,
        filters: any,
        onlyIncludeEventsWithoutUser: boolean | undefined,
        search: string
    ): Promise<number>
    {
            return new Promise<number>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/entity/events/count', Method.Post,
                    {
                        entity_type_id: entityTypeId,
                        entity_id: entityId,
                        user_id: userId,
                        owner_id: ownerId,
                        assignee_id: assigneeId,
                        from_date: fromDate,
                        to_date: toDate,
                        include_hidden: includeHidden,
                        is_pinned: isPinned,
                        filters: filters,
                        only_include_events_without_user: onlyIncludeEventsWithoutUser,
                        search: search
                    }))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    getEvents(
        entityTypeId: number,
        entityId: number,
        userId: number,
        ownerId: number,
        assigneeId: number,
        fromDate: number,
        toDate: number,
        includeHidden: boolean,
        isPinned: boolean,
        filters: any,
        onlyIncludeEventsWithoutUser: boolean | undefined,
        start: number,
        length: number,
        search: string,
        orderBy: string,
        isAscendingOrder: boolean
    ): Promise<EntityEventsPage>
    {
            return new Promise<EntityEventsPage>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/entity/events/page', Method.Post,
                    {
                        entity_type_id: entityTypeId,
                        entity_id: entityId,
                        user_id: userId,
                        owner_id: ownerId,
                        assignee_id: assigneeId,
                        from_date: fromDate,
                        to_date: toDate,
                        include_hidden: includeHidden,
                        is_pinned: isPinned,
                        filters: filters,
                        only_include_events_without_user: onlyIncludeEventsWithoutUser,
                        start: start,
                        length: length,
                        search: search,
                        order_by: orderBy,
                        is_ascending_order: isAscendingOrder
                    }))
                    .then(result =>
                    {
                        resolve(
                            new EntityEventsPage(
                                fromJson(result.events, EntityEvent as any) as EntityEvent[],
                                result.hasMore as boolean
                            )
                        );
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    getEntitiesByLatestActivity(
        entityTypeId: number,
        entityId: number,
        filters: any,
        onlyIncludeEventsWithoutUser: boolean | undefined,
        offset: number,
        limit: number
    ): Promise<EntityByDate[]>
    {
        return new Promise<EntityByDate[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/entity/events/entities-by-latest-activity', Method.Post,
                    {
                        entity_type_id: entityTypeId,
                        entity_id: entityId,
                        filters: filters,
                        only_include_events_without_user: onlyIncludeEventsWithoutUser,
                        offset: offset,
                        limit: limit
                    }))
                    .then(result =>
                    {
                        resolve((fromJson(result, EntityByDate as any) as EntityByDate[]));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };
}
