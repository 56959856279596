import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ActionEditorProps } from '../../ActionEditor';
import { default as CompositeActionModel } from '../../../../../../../../../../@Api/Automation/Function/Action/CompositeAction';
import ActionConstructor from '../../Constructor/ActionConstructor';
import { runInAction } from 'mobx';
import CompositeActionInvocation from '../../../../../../../../../../@Api/Automation/Function/Action/CompositeActionInvocation';
import uuid from '../../../../../../../../../../@Util/Id/uuid';
import CompositeActionInvocationEditor from './CompositeActionInvocationEditor';
import Action from '../../../../../../../../../../@Api/Automation/Function/Action/Action';

export interface CompositeActionEditorProps extends ActionEditorProps<CompositeActionModel>
{

}

const CompositeActionEditor: React.FC<CompositeActionEditorProps> =
    props =>
    {
        const onConstruct =
            useCallback(
                (action: Action<any, any>, afterIdx: number = 0) =>
                    runInAction(
                        () =>
                            props.action.invocations.splice(
                                afterIdx,
                                0,
                                new CompositeActionInvocation(
                                    uuid(),
                                    action))),
                [
                    props.action
                ]);

        const onConstructAfter =
            useCallback(
                (action: Action<any, any>) =>
                    onConstruct(action, props.action.invocations.length),
                [
                    onConstruct
                ]);

        const [ invocation, ...tail ] = props.action.invocations.slice();

        if (invocation)
        {
            return <CompositeActionInvocationEditor
                context={props.context}
                action={props.action}
                invocation={invocation}
                tail={tail}
                idx={0}
                onConstruct={onConstruct}
                returnValue={props.returnValue}
            />;
        }
        else
        {
            return <ActionConstructor
                onConstruct={onConstructAfter}
                mode={tail.length > 0 ? 'Line' : 'Button'}
                context={props.context}
            />;
        }
    };

export default observer(CompositeActionEditor);
