import { EntityPathNode } from '../EntityPathNode';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { EntityTypeStore } from '../../../Type/EntityTypeStore';
import { EntityPath } from '../EntityPath';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationship } from '../../../../../../@Api/Model/Implementation/EntityRelationship';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';

export class EntityPathStaticNode extends EntityPathNode
{
    // ------------------------- Properties -------------------------

    entityType: EntityType;

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType)
    {
        super();

        this.entityType = entityType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static construct(descriptor: any,
                     entityTypeStore: EntityTypeStore): EntityPathNode
    {
        const entityType = entityTypeStore.getTypeByCode(descriptor.staticEntityTypeCode);

        if (entityType)
        {
            return new EntityPathStaticNode(entityType);
        }
        else
        {
            throw new Error(`Static entity type with ID: ${descriptor.staticEntityTypeCode} not found`);
        }
    }

    id(): string
    {
        return `staticEntityType.${this.entityType.code}`;
    }

    getName(entityTypeStore: EntityTypeStore): string
    {
        return this.entityType.nameSingular as any;
    }

    code(): string
    {
        return `static->${this.entityType.code}`;
    }

    getEntityType(baseEntityType: EntityType): EntityType
    {
        return this.entityType;
    }

    joinNode(baseEntityPath: EntityPath): EntityPath
    {
        return baseEntityPath.addNode(this);
    }

    inverseNode(baseEntityType: EntityType): EntityPathNode
    {
        return null;
    }

    isVirtual()
    {
        return true;
    }

    traverseEntity(entity: Entity,
                   commitContext?: CommitContext,
                   onRelationship?: (relationship: EntityRelationship, isParent: boolean) => void): Entity[]
    {
        return [ entity ];
    }

    constructEntity(baseEntity: Entity,
                    forceCreation: boolean,
                    addRelationshipToBase: boolean,
                    entityType: EntityType,
                    commitContext?: CommitContext): Entity
    {
        return baseEntity;
    }

    descriptor(): any
    {
        return {
            staticEntityTypeCode: this.entityType.code
        };
    }

    // ----------------------- Private logic ------------------------
}
