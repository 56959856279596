import React from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import getTypes from '../../../../Type/Api/getTypes';
import { CommitContextImpl } from '../../../../../../../@Api/Entity/Commit/Context/CommitContextImpl';
import { constructEntityOfType } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import openDialog from '../../../../../../../@Service/Navigation/Page/Hooks/openDialog';
import Dialog from '../../../../../../../@Future/Component/Generic/Dialog/Dialog';
import styles from '../../../../Email/EntityEmailDialog.module.scss';
import DialogContent from '../../../../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import DialogTitle from '../../../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import getDatastoreByCode from '../../../../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import performAction from '../../../../../../../@Api/Entity/performAction';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import { getCommitFromEntity } from '../../../../../../../@Api/Entity/Commit/commitEntity';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import CancelButton from '../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import { getEmailAddressesByEntity } from '../../../../../../../@Api/Entity/Bespoke/EmailAddress/getEmailAddressesByEntity';
import { updateDigitalSigningSignOrder } from './updateDigitalSigningSignOrder';
import { ActivityDigitalSigningConstructor } from '../../../../Constructor/Type/Activity/DigitalSigning/ActivityDigitalSigningConstructor';

export default async function createSigningActivity(
    offer: Entity,
    parentOfOffer?: Entity,
    action?: string,
    dialogMessage?: string
)
{
    const types = getTypes();

    // Create entity
    const commitContext = new CommitContextImpl();
    const signingEntity =
        constructEntityOfType(
            types.Activity.DigitalSigning.Type,
            commitContext
        );

    getDatastoreByCode(
        types.Datastore.Phase.DigitalSigning.Type,
        "Draft"
    )
        .then(
            (draftEntity) =>
                updateRelationship(
                    signingEntity,
                    false,
                    types.Activity.DigitalSigning.RelationshipDefinition.Phase,
                    draftEntity,
                    commitContext
                )

        )

    // Link signing to offer
    updateRelationship(
        signingEntity,
        false,
        types.Activity.DigitalSigning.RelationshipDefinition.ActivityToSign,
        offer,
        commitContext
    );

    const ownerOfDocument = parentOfOffer;

    if (ownerOfDocument)
    {
        // Add signing entity to owner of offer (e.g. the sales opportunity)
        updateRelationship(
            ownerOfDocument,
            false,
            types.Activity.RelationshipDefinition.LinkedActivities,
            signingEntity,
            commitContext
        );
    }

    // Set subject
    setValueByFieldInEntity(
        signingEntity,
        types.Activity.Field.Subject,
        offer.getName(commitContext),
        commitContext
    );

    // Resolve recipient
    const relationship =
        offer.getRelatedEntityByDefinition(
            true,
            types.Relationship.RelationshipDefinition.Activities,
            commitContext
        );

    const contact =
        offer.getRelatedEntityByDefinition(
            true,
            types.Relationship.Person.Contact.RelationshipDefinition.Activities,
            commitContext
        );

    let signerRelationship = contact || relationship;

    if (signerRelationship)
    {
        const signerEntity =
            constructEntityOfType(
                types.DigitalSigner.Type,
                commitContext
            );

        const splitname = signerRelationship.name.split(' ');
        splitname.push(' ');

        getDatastoreByCode(types.Datastore.Phase.DigitalSigner.Type, 'Unsigned')
            .then(
                unsignedStateEntity =>
                {
                    updateRelationship(
                        signerEntity,
                        false,
                        types.DigitalSigner.RelationshipDefinition.Phase,
                        unsignedStateEntity,
                        commitContext
                    );
                }
            );

        signerEntity.setValueByField(
            types.DigitalSigner.Field.SignOrderIndex,
            1, // SignerId starts with 1
            false,
            false,
            commitContext
        );

        signerEntity.setValueByField(
            types.DigitalSigner.Field.FirstName,
            splitname[0],
            false,
            false,
            commitContext
        );

        signerEntity.setValueByField(
            types.DigitalSigner.Field.LastName,
            splitname.splice(1).join(' ').trim(),
            false,
            false,
            commitContext
        );

        const emailAddresses =
            getEmailAddressesByEntity(
                signerRelationship,
                commitContext
            );

        if (emailAddresses.length > 0)
        {
            signerEntity.setValueByField(
                types.DigitalSigner.Field.EmailAddress,
                emailAddresses[0].emailAddress,
                false,
                false,
                commitContext
            );
        }

        updateRelationship(
            signerEntity,
            false,
            types.DigitalSigner.RelationshipDefinition.Relationship,
            signerRelationship,
            commitContext
        );

        updateRelationship(
            signingEntity,
            false,
            types.Activity.DigitalSigning.RelationshipDefinition.Signers,
            signerEntity,
            commitContext
        );
    }

    openDialog(
        onClose =>
        <Dialog
            onClose={onClose}
            open={true}
            width="md"
            className={styles.root}
        >
            <DialogTitle>
                {
                    <LocalizedText
                        code="CreateValidSignPackage"
                        value="Creëer ValidSign package"
                    />
                }
            </DialogTitle>
            <DialogContent>
                <ActivityDigitalSigningConstructor
                    entity={signingEntity}
                    autoCommit={false}
                    commitContext={commitContext}
                    hideSave
                    allowNoAttachments
                    documentMessage={dialogMessage}
                    buttons={[
                        <CancelButton
                            onClick={onClose}
                        />
                    ]}
                    startSendingHandler={
                       async (signingActivity) => {

                            const commit = getCommitFromEntity(signingActivity, commitContext);
                            const feedbackStore = loadModuleDirectly(FeedbackStore);

                            updateDigitalSigningSignOrder(signingActivity, commitContext);

                            return performAction(
                                offer,
                                {
                                    code: action,
                                    parameters: {
                                        signingActivity: commit.descriptor
                                    },
                                    files: commit.files,
                                    commitId: commit.id
                                })
                                .then(
                                    () =>
                                    {
                                        feedbackStore.enqueueSnackbar(
                                            localizeText(
                                                'ActivitySigningStartSucceeded',
                                                'Ondertekenactiviteit is gestart'
                                            ),
                                            {variant: 'success'}
                                        );
                                        onClose();
                                        commitContext.dispose();
                                    }
                                ).catch(
                                    (error) =>
                                    {
                                        console.log(error.data.detail);
                                        feedbackStore.enqueueSnackbar(
                                            localizeText(
                                                'ActivitySigningStartFailed',
                                                'Starten ondertekenactiviteit is niet gelukt: ${message}',
                                                {
                                                    message: error.data.detail
                                                }
                                            ),
                                            {variant: 'error'}
                                        );
                                    }
                                );
                        }
                    }
                />

            </DialogContent>
        </Dialog>
    );
}