import React from 'react';

import styles from './EntitySummary.module.scss';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Item from './Item/Item';

export interface EntitySummaryProps
{
    entity: Entity;
    clickable?: boolean;
}

const EntitySummary: React.FC<EntitySummaryProps> =
    props =>
    {
        const summaryFields =
            useComputed(
                () =>
                    props.entity.entityType.bespoke
                        .getSummaryFields(props.entity)
                        .filter(
                            field =>
                                    field.title !== undefined),
                [
                    props
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={0}
            className={styles.root}
        >
            {
                summaryFields.map(
                    field =>
                        <ViewGroupItem
                            key={field.id}
                        >
                            <Item
                                field={field}
                                clickable={props.clickable}
                            />
                        </ViewGroupItem>)
            }
        </ViewGroup>;
    };

export default observer(EntitySummary);
