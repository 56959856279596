import probeBrowserLanguageCode from './probeBrowserLanguageCode';

export default function getBrowserLanguageCode()
{
    const url = new URL(document.location.href);

    if (url.searchParams.has('languageCode'))
    {
        return url.searchParams.get('languageCode');
    }
    else
    {
        return probeBrowserLanguageCode();
    }
}
