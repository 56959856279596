import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { WidgetProps } from '../Widget';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Typography } from '@material-ui/core';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import ProjectAllocationWidget from '../../Model/Widget/ProjectAllocation/ProjectAllocationWidget';
import useResults from '../../../Selection/Hooks/useResults';
import useTypes from '../../../Type/Api/useTypes';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ProjectAllocationItem from './ProjectAllocationItem';
import { removeValuesFromStorage } from '../../../../Setting/Api/useLocalSetting';

export interface ProjectAllocationProps extends WidgetProps<ProjectAllocationWidget>
{

}

const ProjectAllocation: React.FC<ProjectAllocationProps> =
    ({
        onDelete,
        widget,
     }) =>
    {
        const types = useTypes();
        const projects =
            useResults(
                types.Activity.Project.Type,
                (builder, rootPath) =>
                    builder
                        .join(
                            rootPath.joinTo(
                                types.Relationship.RelationshipDefinition.Activities,
                                true
                            )
                        )
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.Entity.Field.IsClosed),
                                    undefined,
                                    false
                                )
                        )
                        .orderBy(
                            rootPath
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.Activities,
                                    true
                                )
                                .field(types.Entity.Field.Name),
                            true
                        ),
                [
                    types,
                ]
            );
        const dividerGlue = useDividerGlue();

        const settingStorageKey =
            useMemo(
                () =>
                    `Widget.${widget.id}.ProjectAllocation`,
                [
                    widget.id
                ]
            );

        const onDeleteItem =
            useCallback(
                () =>
                {
                    removeValuesFromStorage(settingStorageKey);
                    if (onDelete)
                    {
                        onDelete();
                    }
                },
                [
                    onDelete,
                    settingStorageKey,
                ]
            );

        return <Card>
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={dividerGlue}
            >
                <ViewGroupItem>
                    <CardInset>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                            alignment="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <Typography
                                    variant="body1"
                                >
                                    <LocalizedText
                                        code="ProjectAllocation"
                                        value="Project bezetting"
                                    />
                                </Typography>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={5}
                                    alignment="center"
                                >
                                    <ViewGroupItem>
                                        <MenuButton>
                                            <Menu>
                                                <DeleteItem
                                                    onClick={onDeleteItem}
                                                />
                                            </Menu>
                                        </MenuButton>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <DragHandle
                                            horizontal
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </CardInset>
                </ViewGroupItem>
                {
                    projects?.map(
                        project =>
                            <ViewGroupItem
                                key={project.uuid}
                            >
                                <ProjectAllocationItem
                                    project={project}
                                    settingStorageKey={settingStorageKey}
                                />
                            </ViewGroupItem>
                    )
                }
            </ViewGroup>

        </Card>;
    };

export default observer(ProjectAllocation);
