import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { useComputed } from 'mobx-react-lite';

export default function useRootPath(path: EntityPath)
{
    return useComputed(
        () =>
            EntityPath.root(path.entityType),
        [
            path
        ]);
}