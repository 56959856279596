import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import LayoutDependencyContext from '../LayoutDependencyContext';
import { observable } from 'mobx';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import ParameterDictionary from '../../Automation/Parameter/ParameterDictionary';
import DynamicParameterAssignment from '../../Automation/Function/Dynamic/DynamicParameterAssignment';
import getPortalDataSourceSignatureById from '../../Portal/DataSource/Api/getPortalDataSourceSignatureById';
import PortalDataSourceSignature from '../../Portal/DataSource/PortalDataSourceSignature';
import Parameter from '../../Automation/Parameter/Parameter';
import DataSourceValueType from '../../Automation/Value/Type/DataSourceValueType';
import PortalDataSourceOrdering from '../../Portal/DataSource/PortalDataSourceOrdering';
import AutomationDependencyContext from '../../Automation/AutomationDependencyContext';
import localizeText from '../../Localization/localizeText';
import getDependenciesWithoutParameters from '../../Automation/Api/getDependenciesWithoutParameters';

export default class PortalListLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref dataSourceSignature: PortalDataSourceSignature;
    @observable.ref parameterAssignment: DynamicParameterAssignment;
    @observable.shallow orderings: PortalDataSourceOrdering[];
    @observable.ref itemParameter: Parameter<DataSourceValueType>;
    @observable.ref itemLayout: Layout;
    @observable.ref emptyLayout: Layout | undefined;
    @observable hasDivider: boolean;

    // ------------------------ Constructor -------------------------

    constructor(dataSourceSignature: PortalDataSourceSignature,
                parameterAssignment: DynamicParameterAssignment,
                orderings: PortalDataSourceOrdering[],
                itemParameter: Parameter<DataSourceValueType>,
                itemLayout: Layout,
                emptyLayout: Layout | undefined,
                hasDivider: boolean)
    {
        super();

        this.dataSourceSignature = dataSourceSignature;
        this.parameterAssignment = parameterAssignment;
        this.orderings = orderings;
        this.itemParameter = itemParameter;
        this.itemLayout = itemLayout;
        this.emptyLayout = emptyLayout;
        this.hasDivider = hasDivider;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.PortalListLayout', 'Portaal datalijst');
    }

    validate(): Validation[]
    {
        return this.parameterAssignment.validate();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.parameterAssignment.getDependencies(),
            ...getDependenciesWithoutParameters(
                this.orderings
                    .map(ordering => ordering.getDependencies())
                    .reduce((a, b) => a.concat(b), []),
                ...[
                    this.itemParameter,
                    ...this.dataSourceSignature.resultParameters.parameters
                ]
            ),
            ...(this.emptyLayout?.getDependencies() || [])
        ];
    }

    toDescriptor()
    {
        return {
            type: 'PortalList',
            id: this.itemParameter.id,
            dataSourceId: this.dataSourceSignature.id,
            parameterAssignment: this.parameterAssignment.toDescriptor(),
            orderings: this.orderings.map(ordering => ordering.toDescriptor()),
            itemLayout: this.itemLayout.toDescriptor(),
            emptyLayout: this.emptyLayout?.toDescriptor(),
            hasDivider: this.hasDivider
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const id = descriptor.id || descriptor.dataSourceId;
        const dataSourceId = descriptor.dataSourceId;
        const dataSourceSignature = await getPortalDataSourceSignatureById(dataSourceId);
        const parameterAssignment =
            await DynamicParameterAssignment.fromDescriptor(
                descriptor.parameterAssignment,
                dataSourceSignature.parameters,
                dependencyContext);
        const orderingDependencyContext = new AutomationDependencyContext(dataSourceSignature.resultParameters);
        const orderings =
            await Promise.all<PortalDataSourceOrdering>(
                (descriptor.orderings || []).map(
                    ordering =>
                        PortalDataSourceOrdering.fromDescriptor(
                            ordering,
                            orderingDependencyContext)));

        const itemParameter =
            PortalListLayout.getItemParameter(
                id,
                dataSourceSignature);
        const itemLayout =
            await getLayoutFromDescriptor(
                descriptor.itemLayout,
                dependencyContext.withParameterDictionary(
                    ParameterDictionary.union(
                        dependencyContext.parameterDictionary,
                        dataSourceSignature.resultParameters,
                        new ParameterDictionary([ itemParameter ])
                    )
                )
            );
        const emptyLayout =
            descriptor.emptyLayout
                ?
                    await getLayoutFromDescriptor(
                        descriptor.emptyLayout,
                        dependencyContext)
                :
                    undefined;

        return new PortalListLayout(
            dataSourceSignature,
            parameterAssignment,
            orderings,
            itemParameter,
            itemLayout,
            emptyLayout,
            descriptor.hasDivider);
    }

    static getItemParameter(id: string,
                            dataSourceSignature: PortalDataSourceSignature): Parameter<DataSourceValueType>
    {
        return new Parameter<DataSourceValueType>(
            id,
            new DataSourceValueType(
                dataSourceSignature.id,
                undefined,
                dataSourceSignature),
            true,
            `Item van ${dataSourceSignature.name}`);
    }

    // ----------------------- Private logic ------------------------
}
