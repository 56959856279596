import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import TypeMapping from '../../Model/TypeMapping';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import Key from './Key/Key';
import Header from '../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import ExpansionPanel from '../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import uuid from '../../../../../../@Util/Id/uuid';
import HoverCardMiddle from '../../../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import { runInAction } from 'mobx';
import { default as KeyModel } from '../../Model/Key';
import useTypes from '../../../Type/Api/useTypes';
import CreateExternalId from './CreateExternalId/CreateExternalId';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import isExternalIdKey from '../../Api/isExternalIdKey';
import ExternalIdKey from './ExternalIdKey/ExternalIdKey';
import Switch from '../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import RelateToOwnEnvironment from './RelateToOwnEnvironment/RelateToOwnEnvironment';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface KeysProps
{
    typeMapping: TypeMapping;
    entity: Entity;
    commitContext: CommitContext;
}

const Keys: React.FC<KeysProps> =
    props =>
    {
        const id = useMemo(() => uuid(), []);
        const addKey =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            props.typeMapping.keys.push(
                                new KeyModel(
                                    uuid(),
                                    []))),
                [
                    props.typeMapping
                ]);

        const dividerGlue = useDividerGlue();
        const types = useTypes();

        const externalIdKeys =
            useComputed(
                () =>
                    props.typeMapping.keys
                        .filter(
                            key =>
                                isExternalIdKey(key)),
                [
                    props.typeMapping
                ]);

        const nonExternalIdKeys =
            useComputed(
                () =>
                    props.typeMapping.keys
                        .filter(
                            key =>
                                !isExternalIdKey(key)),
                [
                    props.typeMapping
                ]);

        const toggleCreation =
            useCallback(
                () =>
                    runInAction(
                       () =>
                           props.typeMapping.isCreationDisabled = !props.typeMapping.isCreationDisabled),
                [
                    props.typeMapping
                ]);

        const toggleUpdate =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            props.typeMapping.isUpdateEnabled = !props.typeMapping.isUpdateEnabled),
                [
                    props.typeMapping
                ]);

        return <ExpansionPanel
            id={id}
            summary={
                <Header
                    title={
                        <LocalizedText
                            code="Import.DuplicateDetectionOf"
                            value="Duplicatendetectie van ${type} ${relationship} ${numberOfTypes}"
                            type={props.typeMapping.entityType.getName().toLowerCase()}
                            relationship={props.typeMapping.entityType.isA(types.Relationship.Type) ? localizeText('Import.RelationshipAddition', '(verhouding)') : ''}
                            numberOfTypes={props.typeMapping.keys.length > 0 ? `(${props.typeMapping.keys.length})` : ''}
                        />
                    }
                    inset
                />
            }
            expansion={
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    glue={dividerGlue}
                >
                    {
                        externalIdKeys.length === 0 &&
                            <ViewGroupItem>
                                <ExternalIdKey
                                    typeMapping={props.typeMapping}
                                    idx={0}
                                    entity={props.entity}
                                    commitContext={props.commitContext}
                                />
                            </ViewGroupItem>
                    }
                    {
                        externalIdKeys
                            .map(
                                (key, idx) =>
                                    <ViewGroupItem
                                        key={key.id}
                                    >
                                        <ExternalIdKey
                                            typeMapping={props.typeMapping}
                                            idx={idx}
                                            entity={props.entity}
                                            commitContext={props.commitContext}
                                            externalIdKey={key}
                                            last={idx === externalIdKeys.length - 1}
                                        />
                                    </ViewGroupItem>)
                    }
                    {
                        nonExternalIdKeys
                            .map(
                                (key, idx) =>
                                    <ViewGroupItem
                                        key={key.id}
                                    >
                                        <Key
                                            typeMapping={props.typeMapping}
                                            keyModel={key}
                                            idx={Math.max(1, externalIdKeys.length) + idx}
                                        />
                                    </ViewGroupItem>)
                    }
                    <ViewGroupItem>
                        <CardInset>
                            <ViewGroup
                                orientation="vertical"
                                spacing={5}
                            >
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                        alignment="center"
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <LocalizedText
                                                code="Import.BlockCreationOfType"
                                                value="Blokkeer het aanmaken van nieuwe ${type}"
                                                type={props.typeMapping.entityType.getName(true).toLowerCase()}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <Switch
                                                checked={props.typeMapping.isCreationDisabled}
                                                onToggle={toggleCreation}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                        alignment="center"
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <LocalizedText
                                                code="Import.AllowEditType"
                                                value="Sta het bijwerken van bestaande ${type} toe"
                                                type={props.typeMapping.entityType.getName(true).toLowerCase()}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <Switch
                                                checked={props.typeMapping.isUpdateEnabled}
                                                onToggle={toggleUpdate}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>
                            </ViewGroup>
                        </CardInset>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <CreateExternalId
                            typeMapping={props.typeMapping}
                            entity={props.entity}
                            commitContext={props.commitContext}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <RelateToOwnEnvironment
                            typeMapping={props.typeMapping}
                            entity={props.entity}
                            commitContext={props.commitContext}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <HoverCardMiddle
                            onClick={addKey}
                        >
                            {
                                props.typeMapping.keys.length === 0
                                ?
                                    <LocalizedText
                                        code="Import.SetUpDuplicateDetection"
                                        value="Stel duplicatendetectie in"
                                    />
                                :
                                    <LocalizedText
                                        code="Import.AddNewFieldCombination"
                                        value="+ Voeg een nieuwe veldencombinatie toe"
                                    />
                            }
                        </HoverCardMiddle>
                    </ViewGroupItem>
                </ViewGroup>
            }
        />;
    };

export default observer(Keys);
