import { EntityType } from '../../Model/Implementation/EntityType';
import { EntityField } from '../../Model/Implementation/EntityField';
import getNameFieldByType from './getNameFieldByType';
import FieldInput from '../../../@Component/Domain/Multiplayer/Model/Input/FieldInput';
import getTypes from '../../../@Component/Domain/Entity/Type/Api/getTypes';

export function getInheritedNameFieldWithFallback(type: EntityType): EntityField
{
    const input = getNameFieldByType(type);

    if (input instanceof FieldInput)
    {
        return input.field;
    }
    else
    {
        return type.inheritedNameField ?? getTypes().Entity.Field.Name;
    }
}
