import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { useState } from 'react';
import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import { default as AutomationModel } from '../../../../../../../@Api/Automation/Automation';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import getTypes from '../../../../Type/Api/getTypes';

export function useParameterDictionary(entity: Entity) : ParameterDictionary
{
    const [ parameterDictionary ] =
        useState(
            () =>
            {
                const types = getTypes();
                const parameterDictionary = new ParameterDictionary([]);

                parameterDictionary.addParameter(
                    new Parameter(
                        AutomationModel.EnvironmentParameterId,
                        new EntityValueType(types.Relation.Organization.Environment.Type),
                        true,
                        localizeText('Generic.Environment', 'Environment')
                    )
                );

                const isUser = entity.getObjectValueByField(types.Automation.Field.IsUser);

                if (isUser)
                {
                    parameterDictionary.addParameter(
                        new Parameter(
                            AutomationModel.UserParameterId,
                            new EntityValueType(types.Relationship.Person.Contact.Employee.Type),
                            true,
                            localizeText('Generic.User', 'Gebruiker')
                        )
                    );
                }

                return parameterDictionary;
            });

    return parameterDictionary;

}