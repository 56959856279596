import * as React from 'react';
import { EntityEventType } from '../Model/EntityEventType';
import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../../../DataObject/DataObjectStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { Typography } from '@material-ui/core';
import { EntityCreationMutation } from '../../../../../@Api/Model/Implementation/EntityCreationMutation';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';
import { EntityCreateStore } from './EventCreateStore';
import { DataObjectViewer } from '../../../DataObject/Viewer/DataObjectViewer';
import { DataObjectEditor } from '../../../DataObject/Editor/Value/Editor/DataObjectEditor';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import { EntityRelationshipUpdateMutation } from '../../../../../@Api/Model/Implementation/EntityRelationshipUpdateMutation';
import { BadgeStore } from '../../../../Generic/Badge/BadgeStore';
import { registerType } from '../../../../../@Util/Serialization/Serialization';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { Color } from '../../../DataObject/Type/Color/Color';
import getPhaseRelationshipDefinition from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/getPhaseRelationshipDefinition';

// For some reason, the serializer does not deserialize the entity relationship update mutation from
// JSON. We manually register this type.
registerType(
    EntityRelationshipUpdateMutation,
    'EntityRelationshipUpdateMutation');

export class EventRelationshipUpdate extends EntityEventType
{
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('CurrentUserStore') userStore: CurrentUserStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Entity.Relationship.Update';
    }

    parentId(): string
    {
        return null;
    }

    isEnabledByDefault(): boolean
    {
        return false;
    }

    avatarType(event: EntityRelationshipUpdateMutation)
    {
        if (event.toRelatedEntity)
        {
            return event.toRelatedEntity.entityType.getAvatarType(
                this.entityTypeStore,
                super.avatarType(event));
        }
        else
        {
            return event.entity.entityType.getAvatarType(
                this.entityTypeStore,
                super.avatarType(event));
        }
    }

    color(event: EntityRelationshipUpdateMutation): string
    {
        if (event && event.entity && !event.isParentRelationship && event.entityRelationshipDefinition)
        {
            // If the event is a change in the workflow state, and the workflow state is final, get the color from the workflow state
            if (event.entityRelationshipDefinition === getPhaseRelationshipDefinition(event.entity.entityType))
            {
                if (event.toRelatedEntity && event.toRelatedEntity.getObjectValueByField(this.entityTypeStore.bespoke.types.Datastore.Phase.Field.IsTerminal) === true)
                {
                    const value = event.toRelatedEntity.getObjectValueByField(this.entityTypeStore.bespoke.types.Datastore.Phase.Field.Color);

                    if (value)
                    {
                        const color = new Color(value.r, value.g, value.b, value.a);

                        return color.hex;
                    }
                }
            }
        }

        if (event.toRelatedEntity)
        {
            if (event.toRelatedEntity.entityType.isEmbeddedByInheritance()
                || event.entityRelationshipDefinition.isSingular(event.isParentRelationship))
            {
                return event.entity.entityType.getInheritedColor();
            }
            else
            {
                return event.toRelatedEntity.entityType.getInheritedColor();
            }
        }
        else
        {
            return event.entity.entityType.getInheritedColor();
        }
    }

    icon(event: EntityRelationshipUpdateMutation): string
    {
        if (event && event.entity && !event.isParentRelationship && event.entityRelationshipDefinition)
        {
            // If the event is a change in the workflow state, and the workflow state is not final, get the icon of the parent
            if (event.entityRelationshipDefinition === getPhaseRelationshipDefinition(event.entity.entityType))
            {
                if (event.toRelatedEntity && event.toRelatedEntity.getObjectValueByField(this.entityTypeStore.bespoke.types.Datastore.Phase.Field.IsTerminal) === true)
                {
                    return event.toRelatedEntity.getObjectValueByField(this.entityTypeStore.bespoke.types.Datastore.Phase.Field.Icon);
                }
                else
                {
                    return event.entity.entityType.getInheritedIcon();
                }
            }
        }

        if (this.isFilled(event))
        {
            return event.toRelatedEntity.entityType.getInheritedIcon();
        }
        else
        {
            return 'low_priority';
        }
    }

    isFilled(event: EntityRelationshipUpdateMutation): boolean
    {
        if (event)
        {
            // Check if this is a creation event
            return event.fromRelatedEntity == null && event.toRelatedEntity != null;
        }
        else
        {
            return false;
        }
    }

    name(event: EntityRelationshipUpdateMutation): string
    {
        return event.entityRelationshipDefinition.isPlural(event.isParentRelationship)
            ?
                event.toRelatedEntity
                    ?
                        event.toRelatedEntity.entityType.nameSingular
                    :
                        event.entityRelationshipDefinition.getEntityType(event.isParentRelationship).nameSingular
            :
                event.entityRelationshipDefinition.getName(
                    event.isParentRelationship,
                    event.entity.entityType,
                    this.entityTypeStore);
    }

    description(event: EntityRelationshipUpdateMutation): string
    {
        if (event.toRelatedEntity)
        {
            return this.localizationStore.translate(
                'Event.RelationshipUpdate.Description.Attach',
                event.toRelatedEntity.name); // {name} attached
        }
        else
        {
            return this.localizationStore.translate(
                'Event.RelationshipUpdate.Description.Detach',
                event.entityRelationshipDefinition.getName(
                    event.isParentRelationship,
                    event.entity.entityType,
                    this.entityTypeStore)); // {name} detached
        }
    }

    // view(event: EntityRelationshipUpdateMutation): ViewComponent
    // {
    //     const descriptionField =
    //         this.entity.entityType.getInheritedDescriptionField();
    //
    //     if (descriptionField)
    //     {
    //         return new ViewCOmpeont(DataObejctEditor, w DataObjectViewerStore(this.entity.getDataObjectValueByField(descriptionField)));
    //     }
    //     else if ()
    //     {
    //         return undefined;
    //     }
    //
    //
    // }

    store(event: EntityRelationshipUpdateMutation): EntityCreateStore
    {
        if (event.toRelatedEntity
            && (event.toRelatedEntity.entityType.isA(this.entityTypeStore.bespoke.types.Note.Type)
                || event.toRelatedEntity.entityType.isA(this.entityTypeStore.bespoke.types.Attachment.Type)))
        {
            return new EntityCreateStore(event.toRelatedEntity);
        }
        else
        {
            return null;
        }
    }

    content(event: EntityRelationshipUpdateMutation,
            store: EntityCreateStore): JSX.Element
    {
        let content: JSX.Element;

        if (store == null || store.isDeleted)
        {
            content =
                <Typography
                    variant="body2"
                >
                    {
                        event.toRelatedEntity
                            ?
                                event.toRelatedEntity.name
                            :
                                this.localizationStore.translate('Generic.Deleted') // deleted
                    }
                </Typography>;
        }
        else
        {
            if (store.viewerStore && !store.isInEditMode)
            {
                content = <DataObjectViewer store={store.viewerStore} />;
            }
            else if (store.editorStore && store.isInEditMode)
            {
                content = <DataObjectEditor store={store.editorStore} />;
            }
        }

        return content;
    }

    caption(event: EntityEvent): string
    {
        return this.localizationStore.translate('Event.RelationshipUpdate.Caption'); // changed
    }

    actions(event: EntityRelationshipUpdateMutation,
            store: EntityCreateStore,
            onDelete?: (event: EntityCreationMutation) => void): ButtonStore[]
    {
        if (event.toRelatedEntity != null)
        {
            if (event.toRelatedEntity.entityType.isA(this.entityTypeStore.bespoke.types.Note.Type))
            {
                return [
                    new ButtonStore({
                        onClick: store.setInEditMode,
                        icon: 'edit',
                        isVisible: () => !store.isInEditMode && event.user && event.user.id === this.userStore.currentUser.id
                    })];
            }
            else if (event.toRelatedEntity.entityType.isA(this.entityTypeStore.bespoke.types.Attachment.Type))
            {
                return [
                    new ButtonStore({
                        onClick: () =>
                        {
                            store.delete().then(result =>
                            {
                                if (onDelete)
                                {
                                    onDelete(event);
                                }
                            });
                        },
                        icon: 'delete',
                        isVisible: () => !store.isInEditMode && event.user && event.user.id === this.userStore.currentUser.id
                    })];
            }
            else
            {
                return [];
            }
        }
        else
        {
            return [];
        }
    }

    badges(event: EntityEvent): BadgeStore[]
    {
        return [];
    }

    allowExpansion(event: EntityRelationshipUpdateMutation): boolean
    {
        return false;
    }

    expandInSidebar(event: EntityRelationshipUpdateMutation): boolean
    {
        return false;
    }

    expansionComponent(event: EntityRelationshipUpdateMutation): React.ComponentClass<BaseComponentProps<BaseStore>>
    {
        return null;
    }

    expansionStore(event: EntityRelationshipUpdateMutation): BaseStore
    {
        return null;
    }

    typeNameSingular(event: EntityRelationshipUpdateMutation): string
    {
        return this.localizationStore.translate('Read.RelationshipUpdate.Singular.Name'); // ...changed
    }

    typeNamePlural(): string
    {
        return this.localizationStore.translate('Read.RelationshipUpdate.Plural.Name'); // ...changed
    }

    getOpenableEntity(event: EntityRelationshipUpdateMutation): Entity
    {
        if (event.toRelatedEntity
            && !event.toRelatedEntity.entityType.isEmbeddedByInheritance())
        {
            return event.toRelatedEntity;
        }
        else
        {
            return null;
        }
    }
}
