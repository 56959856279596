import Validation from '../../Automation/Validation/Validation';
import Dependency from '../../Automation/Parameter/Dependency';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import LayoutEventTrigger from './LayoutEventTrigger';

export default class LayoutEventTriggers
{
    // ------------------------- Properties -------------------------

    @observable.shallow triggers: LayoutEventTrigger[];

    // ------------------------ Constructor -------------------------

    constructor(triggers: LayoutEventTrigger[])
    {
        this.triggers = triggers;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return this.triggers.map(
            trigger =>
                trigger.validate())
            .reduce((a, b) => a.concat(b), []);
    }

    getDependencies(): Dependency[]
    {
        return this.triggers.map(
            trigger =>
                trigger.getDependencies())
            .reduce((a, b) => a.concat(b), []);
    }

    toDescriptor()
    {
        return this.triggers.map(
            trigger =>
                trigger.toDescriptor());
    }

    static async fromDescriptor(descriptor: any[],
                                dependencyContext: LayoutDependencyContext)
    {
        return new LayoutEventTriggers(
            await Promise.all(
                descriptor.map(
                    trigger =>
                        LayoutEventTrigger.fromDescriptor(
                            trigger,
                            dependencyContext))));
    }

    // ----------------------- Private logic ------------------------
}
