import { useMemo } from 'react';
import useApiClient from '../../../../../../../../@Service/ApiClient/Hooks/useApiClient';

export default function useVideoPreviewImage(videoUrl: string): string
{
    const apiClient = useApiClient();

    return useMemo(
        () =>
        {
            return apiClient.url('/public/video/thumbnail?url=' + btoa(videoUrl));
        },
        [
            apiClient,
            videoUrl
        ]);
}
