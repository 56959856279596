import React from 'react';
import { DialogActions as MuiDialogActions } from '@material-ui/core';

export interface DialogActionsProps
{

}
const DialogActions: React.FC<DialogActionsProps> =
    props =>
    {
        return <MuiDialogActions>
            {props.children}
        </MuiDialogActions>;
    };

export default DialogActions;
