import React, { useCallback, useContext, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Popper from '../../../../../../../@Future/Component/Generic/Popper/Popper';
import Item from '../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import styles from './PortalEnvironmentButton.module.scss';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ApiControllerContext from '../../../../../../../@Api/Controller/ApiControllerContext';
import { User } from '../../../../../../../@Api/Model/Implementation/User';
import AuthenticationManagerContext from '../../../../../../../@Service/Authentication/AuthenticationManagerContext';
import Icon from '../../../../../../../@Future/Component/Generic/Icon/Icon';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import useIsMobile from '../../../../../../../@Util/Responsiveness/useIsMobile';
import useDividerGlue from '../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import LocalizedText from '../../../../../../Domain/Localization/LocalizedText/LocalizedText';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import Avatar from '../../../../../../../@Future/Component/Generic/Avatar/Avatar';
import { runInAction } from 'mobx';
import PortalUserContext from '../../User/PortalUserContext';
import EnvironmentUserList from '../../../Organization/Header/EnvironmentButton/EnvironmentUserList/EnvironmentUserList';
import openDialog from '../../../../../../../@Service/Navigation/Page/Hooks/openDialog';
import { NoLicenseErrorDialog } from '../../../Organization/Header/EnvironmentButton/EnvironmentUserList/EnvironmentUser/NoLicenseErrorDialog';

export interface EnvironmentButtonClasses
{
    root?: string;
}

export interface EnvironmentButtonProps
{
    classes?: EnvironmentButtonClasses
}

const PortalEnvironmentButton: React.FC<EnvironmentButtonProps> =
    () =>
    {
        const apiControllerContext = useContext(ApiControllerContext);
        const authenticationManagerContext = useContext(AuthenticationManagerContext);
        const portalUser = useContext(PortalUserContext);

        const currentUser =
            useComputed(
                () =>
                    portalUser.get(),
                [
                    portalUser
                ]);
        const setCurrentUser =
            useCallback(
                (user: User) =>
                    runInAction(
                        () =>
                            portalUser.set(user)),
                [
                    portalUser
                ]);

        const [ users ] =
            useAsyncResult(
                () =>
                    apiControllerContext.accountUserController.getUsersInAccount(),
            [
                apiControllerContext
            ]);

        const [ isOpen, setOpen ] = useState(false);

        const userSelectCallback =
            useCallback(
                (_) =>
                {
                    setOpen(false);
                },
                []
            );

        const noLicenseErrorCallback =
            useCallback(
                (user: User) =>
                {
                    openDialog(
                        close =>
                            <NoLicenseErrorDialog
                                user={user}
                                onClose={close}
                            />
                    );
                },
                []
            );

        const signOutCallback =
            useCallback(
                () =>
                {
                    setOpen(false);
                    authenticationManagerContext.logout();
                },
                [
                    setOpen,
                    authenticationManagerContext
                ]);

        const isMobile = useIsMobile();
        const dividerGlue = useDividerGlue();

        return <Popper
               reference={
                   <div
                       onClick={() => setOpen(!isOpen)}
                       className={classNames(
                           styles.wrapper,
                           isOpen && styles.open
                       )}
                   >
                       <Avatar
                           icon="person"
                           size={30}
                           className={styles.avatarRoot}
                       />
                       <div className={styles.overlay} />
                       <Icon
                           color="white"
                           icon="keyboard_arrow_down"
                           className={styles.overlayIcon}
                       />
                   </div>
               }
               popper={
                   <Card>
                       <ViewGroup
                           orientation={isMobile ? 'vertical' : 'horizontal'}
                           spacing={isMobile ? 0 : 16}
                           glue={isMobile ? dividerGlue : undefined}
                       >
                           <ViewGroupItem
                               ratio={isMobile ? undefined : 1}
                           >
                               <EnvironmentUserList
                                   users={users}
                                   currentUser={currentUser}
                                   onSelect={userSelectCallback}
                                   onSwitched={setCurrentUser}
                                   onNoLicenseError={noLicenseErrorCallback}
                               >
                                   <Item
                                       name={
                                           <LocalizedText
                                               code="Generic.Signout"
                                               value="Uitloggen"
                                           />
                                       }
                                       onClick={signOutCallback}
                                   />
                               </EnvironmentUserList>
                           </ViewGroupItem>
                       </ViewGroup>
                   </Card>
               }
               open={isOpen}
               onClose={() => setOpen(false)}
        />;
    };

PortalEnvironmentButton.defaultProps =
{

};

export default observer(PortalEnvironmentButton);
