import React, { useCallback } from 'react';
import useDebouncedCallback from '../../../../../@Util/Debounce/useDebouncedCallback';
import Tooltip from '../../Tooltip/Tooltip';

export interface ColorPickerProps
{
    color?: string;
    updateWhenClose?: boolean;
    onChange?: (color: string) => void;
    tooltip?: React.ReactNode;
}

const ColorPicker: React.FC<ColorPickerProps> =
    props =>
    {
        const onChange =
            useCallback(
                (hex: string) =>
                {
                    if (props.onChange)
                    {
                        return props.onChange(hex);
                    }
                },
                [
                    props,
                ]);
        const debouncedOnChange =
            useDebouncedCallback(
                onChange,
                [onChange],
                300
            );

        return <Tooltip
            title={props.tooltip}
        >
            <input
                type="color"
                value={props.color}
                onChange={
                    event =>
                        debouncedOnChange(
                            event.target.value
                        )
                }
            />
        </Tooltip>;
    };

ColorPicker.defaultProps =
{
    updateWhenClose: false
};

export default ColorPicker;
