import { getOrCompute, PropType } from '../../../@Framework/Store/BaseStore';
import { ButtonStore } from '../Button/ButtonStore';
import { action, computed, observable } from 'mobx';
import { WindowProps, WindowStore } from '../Window/WindowStore';
import { Tab, TabBarStore } from '../Tab/TabBarStore';
import { ViewComponent } from '../ViewStack/Model/ViewComponent';

export interface SidebarPanelProps extends WindowProps
{
    tabs?: PropType<SidebarPanelStore, SidebarPanelProps, Tab[]>;
    headerView?: PropType<WindowStore, WindowProps, ViewComponent>;
    headerButtons?: PropType<WindowStore, WindowProps, ButtonStore[]>;
    hasHeaderInset?: PropType<SidebarPanelStore, SidebarPanelProps, boolean>;
    isLeft?: PropType<WindowStore, WindowProps, boolean>;
    backgroundColor?: PropType<WindowStore, WindowProps, string>;
    scrollable?: PropType<WindowStore, WindowProps, boolean>;
    background?: PropType<WindowStore, WindowProps, string>;
    bodyPadding?: PropType<WindowStore, WindowProps, number>;
    width?: PropType<WindowStore, WindowProps, number>;
    showInCard?: PropType<WindowStore, WindowProps, boolean>;
}

const defaultProps: SidebarPanelProps =
{
    isLeft: false,
    backgroundColor: 'white',
    scrollable: true,
    bodyPadding: 0,
    hasHeaderInset:
        store =>
            store.showHeader,
    width: 380,
    showInCard: true
};

export class SidebarPanelStore extends WindowStore<SidebarPanelProps>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable tabIndex: number = undefined;
    @observable tabBarStore: TabBarStore;
    @observable isHovering: boolean;

    // ------------------------ Constructor -------------------------

    constructor(props: SidebarPanelProps)
    {
        super(props, defaultProps);

        this.tabBarStore = new TabBarStore({
            tabs: getOrCompute(this, this.props.tabs)
        });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get headerView(): ViewComponent
    {
        return getOrCompute(this, this.props.headerView);
    }

    @computed
    get headerButtons(): ButtonStore[]
    {
        return getOrCompute(this, this.props.headerButtons);
    }

    @computed
    get background(): string
    {
        return getOrCompute(this, this.props.background);
    }

    @computed
    get isLeft(): boolean
    {
        return getOrCompute(this, this.props.isLeft);
    }

    @computed
    get showInCard(): boolean
    {
        return getOrCompute(this, this.props.showInCard);
    }

    @computed
    get bodyPadding(): number
    {
        return getOrCompute(this, this.props.bodyPadding);
    }

    @computed
    get backgroundColor(): string
    {
        return getOrCompute(this, this.props.backgroundColor);
    }

    @computed
    get scrollable(): boolean
    {
        return getOrCompute(this, this.props.scrollable);
    }

    @computed
    get width(): number
    {
        return getOrCompute(this, this.props.width);
    }

    @computed
    get hasHeaderInset(): boolean
    {
        return getOrCompute(this, this.props.hasHeaderInset);
    }

    @computed
    get showHeader(): boolean
    {
        return !!this.title ||
            !!this.subTitle ||
            (this.headerButtons && this.headerButtons.length > 0) ||
            this.tabBarStore.hasTabs;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get closeButtonStore(): ButtonStore
    {
        if (this.props.onClose)
        {
            return new ButtonStore(
            {
                onClick: this.closeHandler,
                icon: 'close',
                color: 'default'
            });
        }
        else
        {
            return null;
        }
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    startHovering()
    {
        this.isHovering = true;
    }

    @action.bound
    stopHovering()
    {
        this.isHovering = false;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
