import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import localizeText from '../../Localization/localizeText';
import EffectLayoutDependency from './EffectLayoutDependency';
import LayoutAction from '../Action/LayoutAction';
import getLayoutActionFromDescriptor from '../Action/Api/getLayoutActionFromDescriptor';

export default class EffectLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref action: LayoutAction;
    @observable.shallow dependencies: EffectLayoutDependency[];
    @observable spacing: number;
    @observable hasDivider: boolean;
    @observable isWrapped: boolean;

    // ------------------------ Constructor -------------------------

    constructor(
        action: LayoutAction,
        items: EffectLayoutDependency[]
    )
    {
        super();

        this.action = action;
        this.dependencies = items;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.Effect', 'Effect');
    }

    validate(): Validation[]
    {
        return [
            ...this.action.validate(),
            ...this.dependencies
                .map(
                    dependency =>
                        dependency.validate()
                )
                .reduce((a, b) => a.concat(b), [])
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.action.getDependencies(),
            ...this.dependencies
                .map(
                    dependency =>
                        dependency.getDependencies()
                )
                .reduce((a, b) => a.concat(b), [])
        ];
    }

    toDescriptor()
    {
        return {
            type: 'Effect',
            action: this.action.toDescriptor(),
            dependencies: this.dependencies.map(dependency => dependency.toDescriptor()),
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new EffectLayout(
            await getLayoutActionFromDescriptor(
                descriptor.action,
                dependencyContext
            ),
            await Promise.all(
                descriptor.dependencies
                    .map(
                        dependency =>
                            EffectLayoutDependency.fromDescriptor(
                                dependency,
                                dependencyContext
                            )
                    )
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
