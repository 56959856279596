import { computed, observable } from 'mobx';
import { Comparator } from '../../../DataObject/Model/Comparator';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { EntitySelectionBuilder } from './EntitySelectionBuilder';
import { ValueComparisonConstraintNode } from '../../../../../@Api/Model/Implementation/ValueComparisonConstraintNode';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { EntityComparisonConstraintNode } from '../../../../../@Api/Model/Implementation/EntityComparisonConstraintNode';
import { DataObjectRepresentation } from '../../../DataObject/Model/DataObjectRepresentation';
import { TextType } from '../../../DataObject/Type/Text/TextType';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { PredicateTypeStore } from '../../../Predicate/PredicateTypeStore';
import { PredicateContext } from '../../../Predicate/PredicateContext';
import { CompositeConstraintNode, LogicalOperator } from '../../../../../@Api/Model/Implementation/CompositeConstraintNode';
import { EntityContext } from '../../@Model/EntityContext';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { DataObjectStore } from '../../../DataObject/DataObjectStore';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { DataObjectType, EntityField } from '../../../../../@Api/Model/Implementation/EntityField';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { EntityConstraintBuilderFilterContext } from './Predicate/Model/EntityConstraintBuilderFilterContext';
import { EntityConstraintBuilderFilterOptions } from './Predicate/Model/EntityConstraintBuilderFilterOptions';
import { buildConstraintNode } from './Predicate/buildConstraintNode';
import { AggregateSelectionComparisonConstraintNode } from '../../../../../@Api/Model/Implementation/AggregateSelectionComparisonConstraintNode';
import { Selection } from '../../../../../@Api/Selection/Model/Selection';
import { Aggregate } from '../../../DataObject/Model/Aggregate';
import Value from '../../../../../@Api/Automation/Value/Value';
import { EntityNode } from '../../../../../@Api/Model/Implementation/EntityNode';

type ValueType = DataObject | any | undefined;

export class EntityConstraintBuilder
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('PredicateTypeStore') predicateTypeStore: PredicateTypeStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;

    // ------------------------- Properties -------------------------

    @observable selectionBuilder: EntitySelectionBuilder;
    @observable compositeNode: CompositeConstraintNode;

    // ------------------------ Constructor -------------------------

    constructor(selectionBuilder: EntitySelectionBuilder,
                compositeNode: CompositeConstraintNode)
    {
        this.selectionBuilder = selectionBuilder;
        this.compositeNode = compositeNode;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get entityContext(): EntityContext
    {
        return new EntityContext([], this.selectionBuilder.rootPath);
    }

    @computed
    get predicateContext(): PredicateContext
    {
        return {
            entityContext: this.entityContext
        };
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    and(callback: (constraintBuilder: EntityConstraintBuilder) => void): EntityConstraintBuilder
    {
        return this.composite(LogicalOperator.And, callback);
    }

    or(callback: (constraintBuilder: EntityConstraintBuilder) => void): EntityConstraintBuilder
    {
        return this.composite(LogicalOperator.Or, callback);
    }

    composite(logicalOperator: LogicalOperator,
              callback: (constraintBuilder: EntityConstraintBuilder) => void): EntityConstraintBuilder
    {
        if (this.compositeNode.logicalOperator === logicalOperator)
        {
            callback(this);
        }
        else
        {
            let compositeNode = new CompositeConstraintNode(
                logicalOperator,
                []);

            this.compositeNode.childNodes.push(compositeNode);

            callback(
                new EntityConstraintBuilder(
                    this.selectionBuilder,
                    compositeNode));
        }

        return this;
    }

    relatedToEntity(path: EntityPath,
                    relatedEntity: Entity): EntityConstraintBuilder
    {
        if (relatedEntity && relatedEntity.isNew())
        {
            // console.warn('related to new entity', path, relatedEntity);

            return this;
        }
        else
        {
            return this.relatedToEntityId(
                path,
                relatedEntity && relatedEntity.id);
        }
    }

    relatedToEntityId(path: EntityPath,
                      relatedEntityId?: number): EntityConstraintBuilder
    {
        return this.valueComparison(
            path.field(this.entityTypeStore.idField),
            undefined,
            relatedEntityId == null
                ?
                    Comparator.IsNotDefined
                :
                    Comparator.Equals,
            DataObject.constructFromTypeIdAndValue(
                'Number',
                relatedEntityId == null
                    ?
                        null
                    :
                        relatedEntityId,
                this.dataObjectStore));
    }

    notRelatedToEntity(path: EntityPath,
                       relatedEntity: Entity): EntityConstraintBuilder
    {
        if (relatedEntity && relatedEntity.isNew())
        {
            // console.warn('related to new entity', path, relatedEntity);

            return this;
        }

        return this.valueComparison(
            path.field(this.entityTypeStore.idField),
            undefined,
            relatedEntity == null
                ?
                    Comparator.IsDefined
                :
                    Comparator.NotEquals,
            DataObject.constructFromTypeIdAndValue(
                'Number',
                relatedEntity == null
                ?
                    null
                :
                    relatedEntity.id,
                this.dataObjectStore));
    }

    isOfType(path: EntityPath,
             relatedEntityType: EntityType,
             includeInherited: boolean = true,
             includeChildren: boolean = false): EntityConstraintBuilder
    {
        return this.or(
            or =>
                relatedEntityType.getAllTypes(includeInherited, includeChildren)
                    .map(
                        type =>
                            or.eq(
                                path.field(this.entityTypeStore.typeField),
                                undefined,
                                DataObject.constructFromTypeIdAndValue(
                                    'EntityType',
                                    type,
                                    this.dataObjectStore))));
    }

    isNotOfType(path: EntityPath,
                relatedEntityType: EntityType,
                includeInherited: boolean = true,
                includeChildren: boolean = false): EntityConstraintBuilder
    {
        return this.and(
            and =>
                relatedEntityType.getAllTypes(includeInherited, includeChildren)
                    .map(
                        type =>
                            and.neq(
                                path.field(this.entityTypeStore.typeField),
                                undefined,
                                DataObject.constructFromTypeIdAndValue(
                                    'EntityType',
                                    type,
                                    this.dataObjectStore))));
    }

    eq(activeFieldPath: EntityFieldPath,
       activeFieldRepresentation: DataObjectRepresentation,
       value: ValueType): EntityConstraintBuilder
    {
        return this.valueComparison(
            activeFieldPath,
            activeFieldRepresentation,
            Comparator.Equals,
            value);
    }

    neq(activeFieldPath: EntityFieldPath,
        activeFieldRepresentation: DataObjectRepresentation,
        value: ValueType): EntityConstraintBuilder
    {
        return this.valueComparison(
            activeFieldPath,
            activeFieldRepresentation,
            Comparator.NotEquals,
            value);
    }

    gt(activeFieldPath: EntityFieldPath,
       activeFieldRepresentation: DataObjectRepresentation,
       value: ValueType): EntityConstraintBuilder
    {
        return this.valueComparison(
            activeFieldPath,
            activeFieldRepresentation,
            Comparator.GreaterThan,
            value);
    }

    ge(activeFieldPath: EntityFieldPath,
       activeFieldRepresentation: DataObjectRepresentation,
       value: ValueType): EntityConstraintBuilder
    {
        return this.valueComparison(
            activeFieldPath,
            activeFieldRepresentation,
            Comparator.GreaterThanOrEqual,
            value);
    }

    lt(activeFieldPath: EntityFieldPath,
       activeFieldRepresentation: DataObjectRepresentation,
       value: ValueType): EntityConstraintBuilder
    {
        return this.valueComparison(
            activeFieldPath,
            activeFieldRepresentation,
            Comparator.LessThan,
            value);
    }

    le(activeFieldPath: EntityFieldPath,
       activeFieldRepresentation: DataObjectRepresentation,
       value: ValueType): EntityConstraintBuilder
    {
        return this.valueComparison(
            activeFieldPath,
            activeFieldRepresentation,
            Comparator.LessThanOrEqual,
            value);
    }

    in(activeFieldPath: EntityFieldPath,
       activeFieldRepresentation: DataObjectRepresentation,
       value: ValueType): EntityConstraintBuilder
    {
        return this.valueComparison(
            activeFieldPath,
            activeFieldRepresentation,
            Comparator.In,
            value);
    }

    contains(activeFieldPath: EntityFieldPath,
             activeFieldRepresentation: DataObjectRepresentation,
             value: ValueType): EntityConstraintBuilder
    {
        return this.valueComparison(
            activeFieldPath,
            activeFieldRepresentation,
            Comparator.Contains,
            value);
    }

    search(activeFieldPath: EntityFieldPath,
           activeFieldRepresentation: DataObjectRepresentation,
           query: string): EntityConstraintBuilder
    {
        const terms = this.getSearchTerms(query);

        if (terms.length === 0)
        {
            return this;
        }
        else if (terms.length === 1)
        {
            return this.contains(
                activeFieldPath,
                activeFieldRepresentation,
                terms[0]);
        }
        else
        {
            return this.and(
                ab =>
                    terms.forEach(
                        term =>
                            ab.contains(
                                activeFieldPath,
                                activeFieldRepresentation,
                                term)));
        }
    }

    getSearchTerms(query: string): string[]
    {
        if (query)
        {
            return query.split(/\s+/);
        }
        else
        {
            return [];
        }
    }

    overlapsWith(startDateFieldPath: EntityFieldPath,
                 endDateFieldPath: EntityFieldPath,
                 startDate: Date,
                 endDate: Date)
    {
        // check if two periods overlap: https://stackoverflow.com/a/325964
        return this.and(
            ab =>
                ab
                    .lt(
                        startDateFieldPath,
                        undefined,
                        endDate)
                    .ge(
                        endDateFieldPath,
                        undefined,
                        startDate));
    }

    overlapsWithNullableEnd(
        startDateFieldPath: EntityFieldPath,
        endDateFieldPath: EntityFieldPath,
        startDate: Date,
        endDate: Date
    )
    {
        // check if two periods overlap: https://stackoverflow.com/a/325964
        return this.and(
            ab =>
                ab
                    .lt(
                        startDateFieldPath,
                        undefined,
                        endDate)
                    .or(
                        ob =>
                            ob
                                .ge(
                                    endDateFieldPath,
                                    undefined,
                                    startDate)
                                .isNotDefined(endDateFieldPath)
                    )
        );
    }

    isDefined(activeFieldPath: EntityFieldPath,
              activeFieldRepresentation?: DataObjectRepresentation): EntityConstraintBuilder
    {
        return this.valueComparison(
            activeFieldPath,
            activeFieldRepresentation,
            Comparator.IsDefined,
            undefined);
    }

    isNotDefined(activeFieldPath: EntityFieldPath,
                 activeFieldRepresentation?: DataObjectRepresentation): EntityConstraintBuilder
    {
        return this.valueComparison(
            activeFieldPath,
            activeFieldRepresentation,
            Comparator.IsNotDefined,
            undefined);
    }

    valueComparison(activeFieldPath: EntityFieldPath,
                    activeFieldRepresentation: DataObjectRepresentation,
                    comparator: Comparator,
                    value: ValueType): EntityConstraintBuilder
    {
        let activeNode = this.selectionBuilder.joinAndGet(activeFieldPath.path);

        this.compositeNode.childNodes.push(
            new ValueComparisonConstraintNode(
                activeNode,
                activeFieldPath.field,
                (activeFieldRepresentation || { data: undefined }).data,
                comparator,
                this.resolveDataObjectFromValue(
                    activeFieldPath,
                    value)));

        return this;
    }

    propertyComparison(activeFieldPath: EntityFieldPath,
                       activeFieldRepresentation: DataObjectRepresentation,
                       comparator: Comparator,
                       passiveFieldPath: EntityFieldPath,
                       passiveFieldRepresentation: DataObjectRepresentation): EntityConstraintBuilder
    {
        const passiveNode = this.selectionBuilder.joinAndGet(passiveFieldPath.path);

        return this.propertyComparisonWithNode(
            activeFieldPath,
            activeFieldRepresentation,
            comparator,
            passiveNode,
            passiveFieldPath.field,
            passiveFieldRepresentation
        );
    }

    propertyComparisonWithNode(
        activeFieldPath: EntityFieldPath,
        activeFieldRepresentation: DataObjectRepresentation,
        comparator: Comparator,
        passiveNode: EntityNode,
        passiveField: EntityField,
        passiveFieldRepresentation: DataObjectRepresentation
    ): EntityConstraintBuilder
    {
        const activeNode = this.selectionBuilder.joinAndGet(activeFieldPath.path);

        this.compositeNode.childNodes.push(
            new EntityComparisonConstraintNode(
                activeNode,
                activeFieldPath.field,
                (activeFieldRepresentation || { data: undefined }).data,
                comparator,
                passiveNode,
                passiveField,
                (passiveFieldRepresentation || { data: undefined }).data));

        return this;
    }

    groupComparison(groupFieldPath: EntityFieldPath,
                    groupFieldRepresentation: DataObjectRepresentation,
                    groupValue: DataObject): EntityConstraintBuilder
    {
        let node = this.selectionBuilder.joinAndGet(groupFieldPath.path);

        this.compositeNode.childNodes.push(
            new ValueComparisonConstraintNode(
                node,
                groupFieldPath.field,
                (groupFieldRepresentation || { data: undefined }).data,
                groupValue && !groupValue.isEmpty
                    ?
                        (DataObjectType[groupFieldPath.field.type] !== groupValue.specification.type.id()
                            ?
                                Comparator.In
                            :
                                (groupValue.specification.type instanceof TextType
                                    ?
                                        Comparator.StartsWith
                                    :
                                        Comparator.Equals))
                        :
                        Comparator.IsNotDefined,
                groupValue));

        return this;
    }

    aggregateSelectionComparison(
        selection: Selection,
        aggregate: Aggregate,
        aggregateFieldPath: EntityFieldPath,
        comparator: Comparator,
        value: Value<any, any>
    )
    {
        this.compositeNode.childNodes.push(
            new AggregateSelectionComparisonConstraintNode(
                selection,
                aggregate,
                aggregateFieldPath,
                comparator,
                value
            )
        );

        return this;
    }

    filter(
        filter: Predicate,
        options: EntityConstraintBuilderFilterOptions
    )
    {
        const context = this.getFilterContext(options);
        const constraintNode =
            buildConstraintNode(
                filter,
                context
            );

        if (constraintNode)
        {
            this.compositeNode.childNodes.push(constraintNode);
        }

        return this;
    }

    private getFilterContext(
        options: EntityConstraintBuilderFilterOptions
    ): EntityConstraintBuilderFilterContext
    {
        return {
            builder: this,
            context:
                new FunctionContext(
                    new ParameterDictionary([])
                ),
            isInAnd: true,
            ...options
        };
    }

    // ------------------------ Public logic ------------------------

    private resolveDataObjectFromValue(fieldPath: EntityFieldPath,
                                       value: ValueType)
    {
        if (value instanceof DataObject)
        {
            return value;
        }
        else
        {
            if (fieldPath.isRelationship)
            {
                return DataObject.constructFromTypeIdAndValue(
                    'Entity',
                    value,
                    this.dataObjectStore);
            }
            else
            {
                return DataObject.constructFromValue(
                    fieldPath.field.dataObjectSpecification,
                    value);
            }
        }
    }

    // ----------------------- Private logic ------------------------
}
