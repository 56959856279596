import { BaseStore } from '../../../../../../@Framework/Store/BaseStore';
import { action, computed, observable, runInAction } from 'mobx';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import { CurrentUserStore } from '../../../../../Domain/User/CurrentUserStore';
import { EntityTypeStore } from '../../../../../Domain/Entity/Type/EntityTypeStore';
import { SearchBarStore } from '../../../../../Generic/SearchBar/SearchBarStore';
import { AuthenticationManager } from '../../../../../../@Service/Authentication/AuthenticationManager';
import { RouterStore } from '../../../../../../@Service/Router/RouterStore';
import { ViewComponent } from '../../../../../Generic/ViewStack/Model/ViewComponent';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { DataObjectStore } from '../../../../../Domain/DataObject/DataObjectStore';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import List from '../../../../../Generic/List/V2/List';
import { ViewGroupStore } from '../../../../../Generic/ViewGroup/ViewGroupStore';
import { ViewGroupItem } from '../../../../../Generic/ViewGroup/ViewGroupItem';
import { DrawerStore } from '../../../../../Generic/Drawer/DrawerStore';
import { openEntity } from '../../../../../Domain/Entity/@Util/openEntity';
import { ReactViewComponent } from '../../../../../Generic/ViewStack/Model/ReactViewComponent';
import RecentlyVisitedList from '../../../../../Domain/Entity/RecentlyVisitedList/RecentlyVisitedList';

export class HeaderStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;
    @injectWithQualifier('RouterStore') routerStore: RouterStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('AuthenticationManager') authenticationManager: AuthenticationManager;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('LeftDrawerStore') drawerStore: DrawerStore;

    // ------------------------- Properties -------------------------

    @observable isNotificationViewerVisible: boolean = false;
    @observable isMenuOpen: boolean = false;
    @observable isSearchBarOpen: boolean;

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    entersUI(isMounted: boolean)
    {
        super.entersUI(isMounted);

        this.registerDisposable(
            computed(
                () =>
                    this.routerStore.path)
                .observe(
                    () =>
                        runInAction(
                            () =>
                                this.isMenuOpen = false)));
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

     @computed
     get searchBarStore(): SearchBarStore<Entity[]>
     {
         return new SearchBarStore<Entity[]>({
             isLightColor: () => false,
             isAutoFocus: () => this.isSearchBarOpen,
             panelContent:
                new ViewComponent(
                    List as any,
                    new ViewGroupStore({
                        direction: 'vertical',
                        items:
                        [
                            new ViewGroupItem({
                                id: 'list',
                                spacing: 0,
                                view:
                                    new ReactViewComponent(
                                        RecentlyVisitedList,
                                        {
                                            onOpen:
                                                entity =>
                                                {
                                                    this.searchBarStore.deactivate();

                                                    return openEntity(entity);
                                                }
                                        }),
                                style:
                                {
                                    padding: 7
                                }
                            }),
                        ]
                    })),
             load:
                 () =>
                    Promise.resolve([])
         });
     }

     @computed
     get userAvatarEntity(): Entity
     {
         if (this.currentUserStore.accountEntity)
         {
             return this.currentUserStore.accountEntity;
         }
         else
         {
             return undefined;
         }
     }

    // -------------------------- Actions ---------------------------

    @action.bound
    toggleNotificationViewer()
    {
        this.isNotificationViewerVisible = !this.isNotificationViewerVisible;
    }

    @action.bound
    setNotificationVisiblity(isVisible: boolean)
    {
        this.isNotificationViewerVisible = isVisible;
    }

    @action.bound
    openMenu()
    {
        this.isMenuOpen = true;
    }

    @action.bound
    closeMenu()
    {
        this.isMenuOpen = false;
    }

    @action.bound
    openUser()
    {
        return openEntity(this.currentUserStore.employeeEntity);
    }

    @action.bound
    onLogoClick()
    {
        this.routerStore.route(`/`);
    }

    @action.bound
    toggleMenu()
    {
        this.isMenuOpen = !this.isMenuOpen;
    }

    @action.bound
    toggleSearchBar()
    {
        this.isSearchBarOpen = !this.isSearchBarOpen;

        if (!this.isSearchBarOpen)
        {
            this.searchBarStore.deactivate();
        }
    }

    @action.bound
    openInBrowser()
    {
        window.open(window.location.href);
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
