import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { default as InternalNote } from '../../../../Item/Content/Note/Note';
import { EntityEvent } from '../../../../../../../@Api/Model/Implementation/EntityEvent';
import equalsEntity from '../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import styles from './Note.module.scss';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';

export interface NoteProps
{
    entity: Entity;
    event?: EntityEvent;
    rootEntity?: Entity;
    nested?: boolean;
}

const Note: React.FC<NoteProps> =
    props =>
    {
        const isTopLevelEvent =
            useMemo(
                () =>
                    equalsEntity(
                        props.event && props.event.entity,
                        props.rootEntity),
                [
                    props.event,
                    props.rootEntity
                ]);

        const rootClassNames =
            useMemo(
                () =>
                    classNames(
                        styles.root,
                        props.nested && styles.nested),
                [
                    props.nested
                ]);

        return <div
            className={rootClassNames}
        >
            <CardInset>
                <InternalNote
                    entity={props.entity}
                    white={isTopLevelEvent}
                    hideCard
                    creationMutation={props.event}
                />
            </CardInset>
        </div>;
    };

export default observer(Note);
