import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import { useComputed } from 'mobx-react-lite';
import getResultPredicate from './getResultPredicate';
import Segment from '../../Model/Segment';
import Dataset from '../../Model/Dataset';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../@Api/Automation/Value/Type/EntityValueType';

export default function useResultPredicate(
    parameter: Parameter<EntityValueType>,
    segment: Segment,
    result?: EntitySelectionAggregateResult
)
{
    return useComputed(
        () =>
            getResultPredicate(
                parameter,
                segment,
                result
            ),
        [
            parameter,
            segment,
            result
        ]
    );
}
