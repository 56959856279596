import Predicate from './Predicate';
import { observable } from 'mobx';
import getPredicateFromDescriptor from '../../../Api/getPredicateFromDescriptor';
import Dependency from '../../../Parameter/Dependency';
import AutomationDependencyContext from '../../../AutomationDependencyContext';
import Validation from '../../../Validation/Validation';
import { OldNotPredicate } from '../../../../../@Component/Domain/Predicate/Type/Not/OldNotPredicate';
import FunctionContext from '../../FunctionContext';
import { ComputationSubstitution } from '../Util/ComputationSubstitution';
import Parameter from '../../../Parameter/Parameter';

export default class NotPredicate extends Predicate
{
    // ------------------------- Properties -------------------------

    @observable.ref predicate: Predicate;

    // ------------------------ Constructor -------------------------

    constructor(predicate: Predicate)
    {
        super();

        this.predicate = predicate;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isAsync(): boolean
    {
        return this.predicate.isAsync();
    }

    async evaluate(context: FunctionContext): Promise<boolean>
    {
        const isTrue = await this.predicate.evaluate(context);

        return !isTrue;
    }

    synchronouslyEvaluate(context: FunctionContext): boolean
    {
        const isTrue = this.predicate.synchronouslyEvaluate(context);

        return !isTrue;
    }

    validate(): Validation[]
    {
        return this.predicate.validate();
    }

    getName(): string
    {
        return 'niet ...';
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Not';
        descriptor.predicate = this.predicate.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.predicate.getDependencies();
    }

    getTrueStatements(
        context: FunctionContext,
        parameter?: Parameter<any>
    ): Predicate[]
    {
        return [];
    }

    normalize(): Predicate
    {
        return this;
    }

    substitute(substitution: ComputationSubstitution): Predicate
    {
        return new NotPredicate(
            this.predicate.substitute(substitution));
    }

    toOldPredicate()
    {
        return new OldNotPredicate(
            this.predicate?.toOldPredicate());
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new NotPredicate(
            await getPredicateFromDescriptor(
                descriptor.predicate,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
