import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import LayoutEditor, { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import { runInAction } from 'mobx';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import ParameterAssignment from '../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import Computation from '../../../../../../@Api/Automation/Function/Computation/Computation';
import CollectionLayout from '../../../../../../@Api/Layout/Type/CollectionLayout';

export interface CollectionLayoutEditorProps extends LayoutEditorProps<CollectionLayout>
{

}

const CollectionLayoutEditor: React.FC<CollectionLayoutEditorProps> =
    props =>
    {
        const { layout, parameterDictionary, parameterAssignment, commitContext } = props;

        const itemContext =
            useMemo(
                () =>
                    new FunctionContext(
                        parameterDictionary.getNewDictionaryWithParameter(layout.elementParameter),
                        parameterAssignment || new ParameterAssignment(),
                        commitContext
                    ),
                [
                    parameterDictionary,
                    layout.elementParameter,
                    parameterAssignment,
                    commitContext,
                ]);
        const setItemLayout =
            useCallback(
                (itemLayout: Computation<any, any>) =>
                    runInAction(
                        () =>
                            layout.itemLayout = itemLayout),
                [
                    layout
                ]);

        return <LayoutEditor
            parameterDictionary={itemContext.parameterDictionary}
            parameterAssignment={itemContext.parameterAssignment}
            layout={layout.itemLayout}
            onChange={setItemLayout}
        />;
    };

export default observer(CollectionLayoutEditor);
