import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { ActionEditorProps } from '../../ActionEditor';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { runInAction } from 'mobx';
import CardInset from '../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import EntityValueType from '../../../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationEditor from '../../../Computation/ComputationEditor';
import { EntityFieldPath } from '../../../../../../../Path/@Model/EntityFieldPath';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import GenerateNumberInEntityAction from '../../../../../../../../../../@Api/Automation/Function/Action/GenerateNumberInEntityAction';
import { SimpleFieldPathSelector } from '../../../../../../../Path/FieldPathEditor/Simple/Selector/SimpleFieldPathSelector';
import Link from '../../../../../../../../../../@Future/Component/Generic/Link/Link';

export interface GenerateNumberInEntityActionEditorProps extends ActionEditorProps<GenerateNumberInEntityAction>
{

}

const GenerateNumberInEntityActionEditor: React.FC<GenerateNumberInEntityActionEditorProps> =
    props =>
    {
        const setEntity =
            useCallback(
                (entity?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.action.entity = entity
                    ),
                [
                    props.action
                ]
            );
        const entityType =
            useComputed(
                () =>
                    props.action.entity?.getType(),
                [
                    props.action
                ]
            );
        const setFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                            props.action.fieldPath = fieldPath),
                [
                    props.action
                ]
            );

        return <CardInset
            top={false}
        >
            <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                <ViewGroupItem>
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.Record"
                                value="Record"
                            />
                        }
                        labelPosition="left"
                    >
                        <ComputationEditor
                            context={props.context}
                            value={props.action.entity}
                            onChange={setEntity}
                        />
                    </Input>
                </ViewGroupItem>
                {
                    entityType instanceof EntityValueType &&
                    <ViewGroupItem>
                        <Input
                            label={
                                <LocalizedText
                                    code="Generic.Field"
                                    value="Veld"
                                />
                            }
                            labelPosition="left"
                        >
                            <SimpleFieldPathSelector
                                entityType={entityType.type}
                                view={
                                    ({ open }) =>
                                        <Link
                                            onClick={open}
                                        >
                                            {props.action.fieldPath?.getName() ?? '...'}
                                        </Link>
                                }
                                onSelect={setFieldPath}
                            />
                        </Input>
                    </ViewGroupItem>
                }
            </ViewGroup>
        </CardInset>;
    };

export default observer(GenerateNumberInEntityActionEditor);
