import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { openEntity } from '../../../@Util/openEntity';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Icon from '../../../../../../@Future/Component/Generic/Icon/Icon';
import Link from '../../../../../../@Future/Component/Generic/Link/Link';

export interface HeaderLinkProps
{
    entity: Entity;
}

const HeaderLink: React.FC<HeaderLinkProps> =
    props =>
    {
        const onClick =
            useCallback(
                () =>
                    openEntity(props.entity),
                [
                    props.entity
                ]);

        return <Link
            onClick={onClick}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={5}
                alignment="center"
            >
                <ViewGroupItem>
                    <Icon
                        icon={props.entity.entityType.inheritedIcon}
                        size={17}
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    {props.entity.name}
                </ViewGroupItem>
            </ViewGroup>
        </Link>;
    };

export default observer(HeaderLink);
