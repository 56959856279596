import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import Switch from '../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import { Field } from '../../Model/Field';
import { runInAction } from 'mobx';

export interface FieldItemProps
{
    entityType: EntityType;
    field: Field;
}

const IsPluralEditor: React.FC<FieldItemProps> =
    props =>
    {
        const onChange =
            useCallback(
                (value: boolean) =>
                    runInAction(
                        () =>
                            props.field.isPlural = value),
                [
                    props.field
                ]);

        if (props.field.dataType instanceof EntityType)
        {
            return <Switch
                checked={props.field.isPlural}
                onToggle={onChange}
                disabled={!props.field.isEditable || props.field.fieldPath !== undefined}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(IsPluralEditor);
