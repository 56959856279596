import * as React from 'react';
import { ComponentContainerStore } from './ComponentContainerStore';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';

@observer
export class ComponentContainer extends BaseComponent<ComponentContainerStore<any>>
{
    renderComponent(store: ComponentContainerStore<any>)
    {
        return React.createElement(
            store.componentClass,
            {
                store: store.store
            });
    }
}
