import React, { useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityFieldPath } from '../../../@Model/EntityFieldPath';
import { EntityPath } from '../../../@Model/EntityPath';
import useTypes from '../../../../Type/Api/useTypes';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import EntityTypeContext from '../../../../Type/EntityTypeContext';
import useVisibleFieldGroups from '../../../../Fields/Api/useVisibleFieldGroups';
import useUncategorizedFieldGroup from '../../../../Fields/Api/useUncategorizedFieldGroup';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Group from '../Group/Group';
import TextLabel from '../../../../../../../@Future/Component/Generic/Label/Variant/Text/TextLabel';
import TabBar from '../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import Menu from '../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import styles from './Content.module.scss';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import { makeStyles } from '@material-ui/core';
import useVisibleFieldPathsForEntity from '../../../../Fields/Api/useVisibleFieldPathsForEntity';

export interface FieldPathBrowserProps
{
    path: EntityPath;
    onTraverse: (path: EntityPath) => void;
    onSelect: (fieldPath: EntityFieldPath) => void;
    noVirtualGroups?: boolean;
    depth?: number;
    filter?: (fieldPath: EntityFieldPath) => boolean;
}

const useStyles = makeStyles({
    fullHeight:
        {
            height: '100%'
        },
    content:
        {
            height: 'calc(100% - 50px)',
            overflowX: 'scroll'
        },
    scrollable:
        {
            overflowY: 'scroll'
        }
});

const Content: React.FC<FieldPathBrowserProps> =
    props =>
    {
        const classes = useStyles();
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);
        const entity =
            useMemo(
                () =>
                {
                    return new Entity(props.path.entityType)
                        .initialize(entityTypeStore);
                },
                [
                    props.path,
                    entityTypeStore
                ]);
        const additionalPaths =
            useComputed(
                () =>
                {
                    if (props.path.entityType.isA(types.Relationship.Person.Type))
                    {
                        return [
                            props.path
                                .joinTo(
                                    types.Relationship.Person.RelationshipDefinition.Person,
                                    false)
                        ];
                    }
                    else if (props.path.entityType.isA(types.Relationship.Organization.Type))
                    {
                        return [
                            props.path
                                .joinTo(
                                    types.Relationship.Organization.RelationshipDefinition.Organization,
                                    false)
                        ];
                    }
                    else if (props.path.entityType.isA(types.Activity.Type))
                    {
                        return [
                            props.path
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.Activities,
                                    true),
                            props.path
                                .joinTo(
                                    types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                    true)
                        ];
                    }
                    else
                    {
                        return [];
                    }
                },
                [
                    props.path,
                    types
                ]);
        const filteredAdditionalPaths =
            useComputed(
                () =>
                    additionalPaths.filter(
                        path =>
                            props.filter === undefined
                            || props.filter(props.path.join(path).field())
                    ),
                [
                    additionalPaths,
                    props.filter,
                ]
            );
        const visibleFieldPaths = useVisibleFieldPathsForEntity(entity, true);
        const [ groups, isLoadingGroups ] = useVisibleFieldGroups(entity, visibleFieldPaths, props.noVirtualGroups);
        const uncategorizedGroup = useUncategorizedFieldGroup(entity, visibleFieldPaths);
        const connectionPaths =
            useComputed(
                () =>
                    [ true, false ]
                        .map(
                            isParent =>
                                props.path.entityType.getInheritedRelationshipDefinitions(isParent)
                                    .map(
                                        relationshipDefinition =>
                                            props.path.joinTo(
                                                relationshipDefinition,
                                                isParent))
                                    .filter(
                                        path =>
                                            path.lastJoinNode !== undefined))
                        .reduce((a, b) => a.concat(b), [])
                        .filter(
                            path =>
                                props.filter === undefined
                                || props.filter(props.path.join(path).field())
                        ),
                [
                    props.path
                ]);
        const castPaths =
            useComputed(
                () =>
                    props.path.entityType
                        .childTypes
                        .map(
                            childType =>
                                props.path.castTo(childType)),
                [
                    props.path
                ]);
        const filteredCastPaths =
            useComputed(
                () =>
                    castPaths.filter(
                        path =>
                            props.filter === undefined
                            || props.filter(path.field())
                    ),
                [
                    props.path
                ]
            );
        const [ tab, setTab ] = useState(0);

        useEffect(
            () =>
            {
                setTab(0);
            },
            [
                props.path,
                setTab
            ]);

        return <ViewGroup
            orientation="vertical"
            spacing={0}
            className={classes.fullHeight}
        >
            <ViewGroupItem>
                <TabBar
                    value={tab}
                >
                    <Tab
                        value={0}
                        onClick={setTab}
                    >
                        {props.path.entityType.getName()}
                    </Tab>
                    <Tab
                        value={1}
                        onClick={setTab}
                    >
                        <LocalizedText
                            code="FieldPathEditor.EntityTypeLinks"
                            value="${entityType} koppelingen"
                            entityType={props.path.entityType.getName()}
                        />
                    </Tab>
                    {
                        filteredCastPaths.map(
                            (castPath, idx) =>
                                <Tab
                                    key={castPath.id}
                                    value={2 + idx}
                                    onClick={() => props.onTraverse(castPath)}
                                >
                                    {castPath.getName(undefined, false, false)}
                                </Tab>)
                    }
                    {
                        filteredAdditionalPaths.map(
                            (additionalPath, idx) =>
                                <Tab
                                    key={additionalPath.id}
                                    value={2 + filteredCastPaths.length + idx}
                                    onClick={() => props.onTraverse(additionalPath)}
                                >
                                    {additionalPath.getName(undefined, false, false)}
                                </Tab>)
                    }
                </TabBar>
            </ViewGroupItem>
            <ViewGroupItem
                ratio={1}
                className={classes.content}
            >
                {
                    tab === 0 &&
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                        noMinWidth
                        className={classes.fullHeight}
                    >
                        {
                            !isLoadingGroups
                            && !uncategorizedGroup
                            && groups.length === 0
                            && filteredAdditionalPaths.length === 0
                            &&
                                <ViewGroupItem>
                                    <CardInset>
                                        <Centered
                                            horizontal
                                        >
                                            <TextLabel>
                                                <LocalizedText
                                                    code="FieldPathEditor.NoFields"
                                                    value="Er zijn géén velden."
                                                />
                                            </TextLabel>
                                        </Centered>
                                    </CardInset>
                                </ViewGroupItem>
                        }
                        {
                            uncategorizedGroup &&
                                <ViewGroupItem>
                                    <Group
                                        path={props.path}
                                        onTraverse={props.onTraverse}
                                        onSelect={props.onSelect}
                                        entity={entity}
                                        group={uncategorizedGroup}
                                        depth={props.depth}
                                        filter={props.filter}
                                    />
                                </ViewGroupItem>
                        }
                        {
                            !isLoadingGroups
                            && groups.map(
                                group =>
                                    <ViewGroupItem
                                        key={group.id || group.customId}
                                    >
                                        <Group
                                            path={props.path}
                                            onTraverse={props.onTraverse}
                                            onSelect={props.onSelect}
                                            entity={entity}
                                            group={group}
                                            depth={props.depth}
                                            filter={props.filter}
                                        />
                                    </ViewGroupItem>)
                        }
                    </ViewGroup>
                }
                {
                    tab === 1 &&
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                        className={classes.fullHeight}
                    >
                        {
                            [ true, false ]
                                .map(
                                    isSingleton =>
                                        <ViewGroup
                                            orientation="vertical"
                                            spacing={0}
                                            className={classes.scrollable}
                                        >
                                            <ViewGroupItem

                                            >
                                                <CardInset>
                                                    <div
                                                        className={styles.header}
                                                    >
                                                        {isSingleton ? localizeText('FieldPathEditor.Singular', 'Enkelvoudig') : localizeText('FieldPathEditor.Plural', 'Meervoudig')}
                                                    </div>
                                                </CardInset>
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <Menu
                                                    hideGlue
                                                >
                                                    {
                                                        connectionPaths
                                                            .filter(
                                                                connectionPath =>
                                                                    connectionPath
                                                                        .lastJoinNode
                                                                        .relationshipDefinition
                                                                        .isPlural(connectionPath.lastJoinNode.isParent) !== isSingleton)
                                                            .map(
                                                                connectionPath =>
                                                                    <Item
                                                                        key={connectionPath.id}
                                                                        name={connectionPath.getName(undefined, false, false)}
                                                                        onClick={() => props.onTraverse(connectionPath)}
                                                                    />)
                                                    }
                                                </Menu>
                                            </ViewGroupItem>
                                        </ViewGroup>)
                        }
                    </ViewGroup>
                }
                {
                    tab === 2 &&
                    <Menu
                        hideGlue
                    >
                        {
                            filteredCastPaths.map(
                                castPath =>
                                    <Item
                                        key={castPath.id}
                                        name={castPath.getName(undefined, false, false)}
                                        onClick={() => props.onTraverse(castPath)}
                                    />
                            )
                        }
                    </Menu>
                }
            </ViewGroupItem>
        </ViewGroup>
    };

Content.defaultProps = {
    depth: 0
};

const ObservableFieldBrowser = observer(Content);

export default ObservableFieldBrowser;
