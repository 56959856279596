import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { default as GenericMenuButton } from '../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import MenuButtonOptions from './MenuButtonOptions';
import useSwitch from '../../../../../@Util/Switch/useSwitch';
import Drawer from '../../Timeline/Drawer/Drawer';

export interface MenuButtonProps
{
    entity: Entity;
    icon?: string;
    small?: boolean;
    onDelete?: () => void;
}

const MenuButton: React.FC<MenuButtonProps> =
    props =>
    {
        const [ isTimelineOpen, openTimeline, closeTimeline ] = useSwitch(false);

        return <>
            <GenericMenuButton
                icon={props.icon}
                small={props.small}
            >
                <MenuButtonOptions
                    {...props}
                    isTimelineOpen={isTimelineOpen}
                    openTimeline={openTimeline}
                    closeTimeline={closeTimeline}
                >
                    {props.children}
                </MenuButtonOptions>
            </GenericMenuButton>
            {
                isTimelineOpen &&
                    <Drawer
                        open={isTimelineOpen}
                        onClose={closeTimeline}
                        variant="temporary"
                        anchor="right"
                        entity={props.entity}
                    />
            }
        </>;
    };

export default observer(MenuButton);
