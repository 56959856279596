import { GlobalEnvironment } from '../../../@Global/GlobalEnvironment';
import * as Sentry from '@sentry/browser';
import isUserError from '../../../@Api/Error/isUserError';
import { consoleLog } from '../../../@Future/Util/Logging/consoleLog';

export function setupSentry()
{
    if (GlobalEnvironment.SENTRY_ENDPOINT && GlobalEnvironment.SENTRY_ENDPOINT !== '')
    {
        Sentry.init({
            dsn: GlobalEnvironment.SENTRY_ENDPOINT,

            beforeSend:
                (event: any, hint?: any) =>
                {
                    if (!isCaptured(hint))
                    {
                        markAsCaptured(hint);

                        if (hint?.originalException != null
                            && isUserError(hint?.originalException))
                        {
                            return undefined;
                        }
                        else
                        {
                            consoleLog('sending event to sentry', event, hint);

                            // console.error(hint?.originalException);

                            return event;
                        }
                    }
                }
        });
    }
}

const isErrorCapturedKey = 'isCaptured';

function isCaptured(hint?: any)
{
    if (hint?.originalException)
    {
        return (hint.originalException as any)[isErrorCapturedKey] === true;
    }
    else
    {
        return false;
    }
}

function markAsCaptured(hint?: any)
{
    if (hint?.originalException)
    {
        (hint.originalException as any)[isErrorCapturedKey] = true;
    }
}
