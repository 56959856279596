import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Row from '../Row/Row';
import { ListProps } from '../List';
import { useExpansion } from '../../../Selection/Api/useExpansion';
import getTypes from '../../../Type/Api/getTypes';
import { isColumnPlural } from '../../Api/isColumnPlural';

export interface NonGroupedListPageProps extends ListProps
{
    page: any;
    pageIdx: number;
    pageSize: number;
    baseSelectionType: any;
    changeSelection: any;
    onRowClick: any;
    lastOpenedEntityIdFromRoutingState: any;
    selectionMutations: any;
}

const NonGroupedListPage: React.FC<NonGroupedListPageProps> =
    props =>
    {
        const types = getTypes();
        const list = props.view.specification.list;

        // Expand plural child relations removed earlier from selection
        const pluralChildrenColumnPaths =
            useComputed(
                () =>
                    list.columns
                        .filter(
                            column => isColumnPlural(column)
                        )
                        .map(
                            column => column.fieldPath.path
                        ),
            [
                    list.columns
                ]
            );

        useExpansion(
            props.view.entityType,
            () => pluralChildrenColumnPaths,
            () => props.page.map(result => result.entity),
            [
                types,
                props.page,
                props.view.entityType,
                pluralChildrenColumnPaths
            ]
        );

        return <React.Fragment
            key={props.page[0].entity.uuid}
        >
        {
            props.page
                .map(
                    result =>
                        result.entity
                )
                .filter(
                    entity =>
                        props.showHiddenEntities ||
                        !entity.entityType.bespoke.isHiddenInSelectbox(entity)
                )
                .map(
                    (entity, idx) =>
                        <Row
                            key={entity.uuid}
                            view={props.view}
                            columns={list.columns}
                            entity={entity}
                            idx={props.pageIdx * props.pageSize + idx}
                            isInEditMode={props.isInEditMode}
                            sortable={props.sortable}
                            selectable={props.selectable}
                            selected={
                                (props.baseSelectionType === 'none' && props.selectionMutations.includes(entity.uuid))
                                || (props.baseSelectionType === 'all' && !props.selectionMutations.includes(entity.uuid))
                            }
                            highlighted={entity.uuid === props.lastOpenedEntityIdFromRoutingState}
                            onSelect={props.changeSelection}
                            disableSelection={props.disableSelection}
                            onClick={props.onRowClick}
                            isInactive={props.isInactive}
                        />
                )
        }
    </React.Fragment>;
};

export default observer(NonGroupedListPage);
