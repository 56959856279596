import Role, { Privilege, RoleType } from '../../Model/Role';
import { isEntityTypePermissionDisabled } from './EntityType/isEntityTypePermissionDisabled';
import { isFieldPermissionDisabled } from './Field/isFieldPermissionDisabled';
import { isRelationshipDefinitionPermissionDisabled } from './RelationshipDefinition/isRelationshipDefinitionPermissionDisabled';
import { EntityMetadataPermission } from '../../Model/EntityMetadataPermission';

export function isPermissionDisabled(
    rolesByType: Map<RoleType, Role>,
    role: Role,
    privilege: Privilege,
    metadataPermission: EntityMetadataPermission
): boolean
{
    switch (metadataPermission.type)
    {
        case 'EntityType':
            return isEntityTypePermissionDisabled(
                rolesByType,
                role,
                privilege,
                metadataPermission.entityType
            );
        case 'EntityField':
            return isFieldPermissionDisabled(
                rolesByType,
                role,
                privilege,
                metadataPermission.field
            )
        case 'RelationshipDefinition':
            return isRelationshipDefinitionPermissionDisabled(
                rolesByType,
                role,
                privilege,
                metadataPermission.relationship
            );
        default:
            throw new Error('Unsupported entity metadata permission type');
    }
}