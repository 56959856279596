import { useComputed } from 'mobx-react-lite';
import { default as ResourcePlannerModel } from '../Model/ResourcePlanner';
import ResourceSelection from '../Model/ResourceSelection';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import { Comparator } from '../../../DataObject/Model/Comparator';
import useTypes from '../../Type/Api/useTypes';
import { DataObject } from '../../../DataObject/Model/DataObject';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';

export default function useEmployeeBillability()
{
    const types= useTypes();

    return useComputed(
        () =>
        {
            const employeeParameter =
                new Parameter(
                    'Employee',
                    new EntityValueType(types.Relationship.Person.Contact.Employee.Type),
                    true,
                    types.Relationship.Person.Contact.Employee.Type.getName());

            const teamParameter =
                new Parameter(
                    'Team',
                    new EntityValueType(types.Team.Type),
                    false,
                    types.Team.Type.getName());

            const skillParameter =
                new Parameter(
                    'Skill',
                    new EntityValueType(types.Datastore.Skill.Type),
                    false,
                    types.Datastore.Skill.Type.getName());

            const timeRegistrationParameter =
                types.TimeRegistration.Type &&
                new Parameter(
                    'TimeRegistration',
                    new EntityValueType(types.TimeRegistration.Type),
                    true,
                    types.TimeRegistration.Type.getName());

            return new ResourcePlannerModel(
                employeeParameter,
                new ParameterDictionary([
                    teamParameter,
                    skillParameter,
                ]),
                new CompositePredicate(
                    LogicalOperator.And,
                    [
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .field(types.Relationship.Person.Contact.Employee.Field.IsSupport)),
                            Comparator.Equals,
                            new PrimitiveValue(
                                DataObject.constructFromTypeIdAndValue(
                                    'Boolean',
                                    false))),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .field(types.Relationship.Person.Contact.Employee.Field.WorkableHoursPerWeek)),
                            Comparator.GreaterThan,
                            new PrimitiveValue(
                                DataObject.constructFromTypeIdAndValue(
                                    'Number',
                                    0))),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .field(types.Relationship.Field.IsFormer)),
                            Comparator.Equals,
                            new PrimitiveValue(
                                DataObject.constructFromTypeIdAndValue(
                                    'Boolean',
                                    false))),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .joinTo(
                                        types.Team.RelationshipDefinition.Members,
                                        true)
                                    .field()),
                            Comparator.Equals,
                            teamParameter),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .joinTo(
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Skills,
                                        false)
                                    .field()),
                            Comparator.Equals,
                            skillParameter),
                    ]),
                [
                    timeRegistrationParameter &&
                    new ResourceSelection(
                        timeRegistrationParameter,
                        EntityPath.fromEntityType(types.TimeRegistration.Type)
                            .joinTo(
                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                true),
                        EntityPath.fromEntityType(types.TimeRegistration.Type)
                            .field(types.TimeRegistration.Field.StartDate),
                        EntityPath.fromEntityType(types.TimeRegistration.Type)
                            .field(types.TimeRegistration.Field.EndDate),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                timeRegistrationParameter,
                                EntityPath.fromEntityType(types.TimeRegistration.Type)
                                    .field(types.TimeRegistration.Field.IsBillable)),
                            Comparator.Equals,
                            new PrimitiveValue(
                                DataObject.constructFromTypeIdAndValue(
                                    'Boolean',
                                    true))),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        [
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.TimeRegistrations,
                                    true)
                                .field(),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.TimeRegistrations,
                                    true)
                                .field(),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .joinTo(
                                    types.TimeRegistration.RelationshipDefinition.Activity,
                                    false)
                                .field(),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                    true)
                                .field(),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .field(types.TimeRegistration.Field.IsBillable)
                        ]
                    )
                ].filter(selection => selection),
                undefined,
                undefined,
                'month',
                'quarter');
        },
        [
            types
        ]);
}
