import ValueType from './ValueType';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import { DataObjectType } from '../../../../@Component/Domain/DataObject/Model/DataObjectType';

export default class PrimitiveValueType extends ValueType<DataObjectType>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return `Primitive(${this.type.id()})`;
    }

    isA(type: ValueType<any>): boolean
    {
        if (type instanceof PrimitiveValueType)
        {
            const thisType = this.type;
            const otherType = type.type;

            return thisType instanceof otherType.constructor;
        }
        else
        {
            return false;
        }
    }

    getName(): string
    {
        return this.type.name();
    }

    toDescriptor()
    {
        return {
            type: 'Primitive',
            typeId: this.type.id()
        };
    }

    static fromDescriptor(descriptor: any)
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore).getTypeById(
                descriptor.typeId));
    }

    // ----------------------- Private logic ------------------------
}
