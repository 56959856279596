import Layout from '../../../Layout';
import { observable } from 'mobx';
import Validation from '../../../../Automation/Validation/Validation';
import Dependency from '../../../../Automation/Parameter/Dependency';
import Computation from '../../../../Automation/Function/Computation/Computation';
import Predicate from '../../../../Automation/Function/Computation/Predicate/Predicate';
import LayoutEventTriggers from '../../../Event/LayoutEventTriggers';

export default abstract class FormInputLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable formId: string;
    @observable parameterId: string;
    @observable.ref placeholder: Computation<any, any> | undefined;
    @observable.ref isDisabled: Predicate | undefined;
    @observable hasIntrinsicSize: boolean;
    @observable.ref eventTriggers: LayoutEventTriggers;

    // ------------------------ Constructor -------------------------

    constructor(formId: string,
                parameterId: string,
                placeholder: Computation<any, any> | undefined,
                isDisabled: Predicate | undefined,
                hasIntrinsicSize: boolean = false,
                eventTriggers: LayoutEventTriggers = new LayoutEventTriggers([]))
    {
        super();

        this.formId = formId;
        this.parameterId = parameterId;
        this.placeholder = placeholder;
        this.isDisabled = isDisabled;
        this.hasIntrinsicSize = hasIntrinsicSize;
        this.eventTriggers = eventTriggers;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return [
            ...(this.placeholder?.validate() || []),
            ...this.eventTriggers.validate()
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...(this.placeholder?.getDependencies() || []),
            ...this.eventTriggers.getDependencies()
        ];
    }

    toDescriptor()
    {
        const descriptor = {
            formId: this.formId,
            parameterId: this.parameterId,
            placeholder: this.placeholder?.toDescriptor(),
            isDisabled: this.isDisabled?.toDescriptor(),
            hasIntrinsicSize: this.hasIntrinsicSize,
            eventTriggers: this.eventTriggers.toDescriptor()
        };

        this._toDescriptor(descriptor);

        return descriptor;
    }

    abstract _toDescriptor(descriptor: any);

    // ----------------------- Private logic ------------------------
}
