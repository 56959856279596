import React from 'react';
import { observer } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import CenteredBodyLayout from '../../../../../@Api/Layout/Type/CenteredBodyLayout';
import CenteredBodyView from './View/CenteredBodyView';

export interface CenteredBodyLayoutViewerProps extends LayoutViewerProps<CenteredBodyLayout>
{

}

const CenteredBodyLayoutViewer: React.FC<CenteredBodyLayoutViewerProps> =
    props =>
    {
        return <CenteredBodyView
            layout={props.layout}
        >
            <LayoutViewer
                {...props}
                layout={props.layout.layout}
            />
        </CenteredBodyView>;
    };

export default observer(CenteredBodyLayoutViewer);
