import { DataObjectEditorType, DataObjectSpecificationType, DataObjectViewerType } from '../../../../Model/DataObjectType';
import { DataObject } from '../../../../Model/DataObject';
import { PercentageRangeView } from './PercentageRangeView';
import { PercentageRangeEditor } from './PercentageRangeEditor';
import { PercentageRangeSpecification } from './PercentageRangeSpecification';
import { NumberRangeValue } from '../../NumberRange/NumberRangeValue';
import { NumberRangeType } from '../../NumberRange/NumberRangeType';
import { DataObjectRepresentation } from '../../../../Model/DataObjectRepresentation';
import numbro from 'numbro';
import { DataObjectContext } from '../../../../Model/DataObjectContext';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export class PercentageRangeType extends NumberRangeType
{
    id(): string
    {
        return 'PercentageRange';
    }

    name()
    {
        return localizeText('DataObject.Type.PercentageRange', 'Percentagebereik');
    }

    view(): DataObjectViewerType
    {
        return PercentageRangeView;
    }

    editor(): DataObjectEditorType
    {
        return PercentageRangeEditor;
    }

    specification(): DataObjectSpecificationType
    {
        return PercentageRangeSpecification;
    }

    intervalTypeId(): string
    {
        return 'Percentage';
    }

    rangeTypeId(): string
    {
        return 'PercentageRange';
    }

    constructRange(from: DataObject,
                   interval: DataObject,
                   rangeToSet: DataObject): void
    {
        let range: NumberRangeValue =
        {
            from: from.getValue(),
            to: from.getValue() + interval.getValue()
        };

        rangeToSet.setValue(range);
    }

    getString(value: NumberRangeValue,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        return `${numbro(value.from).format()}% - ${numbro(value.to).format()}%`;
    }
}
