import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import DialogTitle from '../../../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import Dialog from '../../../../../../../@Future/Component/Generic/Dialog/Dialog';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import { DialogContent, Divider } from '@material-ui/core';
import InputGroup from '../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import useTypes from '../../../../Type/Api/useTypes';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import useRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import useRelatedEntities from '../../../../../../../@Api/Entity/Hooks/useRelatedEntities';
import RichText from '../../../../../../Generic/RichText/RichText';
import { renderConceptEmail, renderEmail } from './Api/renderEmail';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';

export interface EmailPreviewProps
{
    emailEntity: Entity
    onClose?: () => void;
    commitContext: CommitContext
}

export const EmailPreview: React.FC<EmailPreviewProps> = observer(
    ({
        emailEntity,
        onClose,
        commitContext
     }) =>
    {
        const types = useTypes();

        const subject =
            useEntityValue(
                emailEntity,
                types.Activity.Field.Subject,
                undefined,
                commitContext
            );

        const content =
            useEntityValue(
                emailEntity,
                types.Activity.Email.Field.Content,
                undefined,
                commitContext
            );

        const template =
            useRelatedEntity(
                emailEntity,
                types.Template.Email.RelationshipDefinition.Emails,
                true,
                commitContext
            )

        const fromRecipient =
            useRelatedEntity(
                emailEntity,
                types.Activity.Email.RelationshipDefinition.From,
                false,
                commitContext
            );

        const toRecipients =
            useRelatedEntities(
                emailEntity,
                types.Activity.Email.RelationshipDefinition.To,
                false,
                commitContext
            );

        const relationship =
            useRelatedEntity(
                emailEntity,
                types.Activity.RelationshipDefinition.Relationship,
                true,
                commitContext
            );

        const contact =
            useRelatedEntity(
                emailEntity,
                types.Activity.RelationshipDefinition.Contact,
                true,
                commitContext
            );

        const [doRender] =
            useAsyncResult(
                async () =>
                {
                    if (emailEntity.isNew())
                    {
                        return await renderConceptEmail(
                            subject,
                            content,
                            template,
                            relationship,
                            contact
                        );
                    }
                    else
                    {
                        return await renderEmail(emailEntity);
                    }
                },
                [
                    emailEntity,
                    commitContext,
                    template,
                    relationship,
                    contact
                ]);

        const addresseeDisplayName =
            useCallback(
            (addressee: Entity) =>
            {
                const name = addressee.getRelatedEntityByDefinition(
                    false,
                    types.Recipient.RelationshipDefinition.Addressee,
                    commitContext)?.name;

                const email = addressee.getObjectValueByField(
                    types.Recipient.Email.Field.EmailAddress,
                    commitContext);

                return `${name} <${email}>`;
            },
            [
                types,
                commitContext
            ]);

        const toRecipientString =
            useMemo(
                () =>
                {
                    return toRecipients
                        .map(r =>
                            addresseeDisplayName(r)
                        )
                        .join(', ');
                },
                [
                    addresseeDisplayName,
                    toRecipients
                ]
            );

        return <Dialog
            onClose={onClose}
            width="lg"
            open
        >
            <DialogTitle>
                <LocalizedText
                    code="EmailPreview.Title"
                    value="E-mail voorbeeld"
                />
            </DialogTitle>
            <DialogContent>
                <ViewGroup
                    orientation='vertical'
                    spacing={8}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation='vertical'
                            spacing={10}
                        >
                            <ViewGroupItem>
                                <InputGroup>
                                    <Input
                                        label={
                                            <LocalizedText
                                                code="Generic.From"
                                                value="Van"
                                            />
                                        }
                                        labelPosition="left"
                                    >
                                        { addresseeDisplayName(fromRecipient) }
                                    </Input>
                                    <Input
                                        label={
                                            <LocalizedText
                                                code="Generic.To"
                                                value="Naar"
                                            />
                                        }
                                        labelPosition="left"
                                    >
                                        { toRecipientString }
                                    </Input>
                                    <Input
                                        label={types.Activity.Field.Subject.getName()}
                                        labelPosition="left"
                                    >
                                        {subject}
                                    </Input>
                                </InputGroup>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <Divider/>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <RichText
                                    value={doRender}
                                    standalone
                                >
                                </RichText>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={0}
                        >
                            <ViewGroupItem
                                ratio={1}
                                alignment="right"
                            >
                                <RightAlignedButtonGroup>
                                    <PrimaryButton
                                        label="Sluiten"
                                        onClick={onClose}
                                    />
                                </RightAlignedButtonGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </DialogContent>
        </Dialog>;
    }
);