import { EntityConstraintBuilderFilterContext } from './Model/EntityConstraintBuilderFilterContext';
import { ConstraintNode } from '../../../../../../@Api/Model/Implementation/ConstraintNode';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import QueryComputation from '../../../../../../@Api/Automation/Function/Computation/QueryComputation';
import AggregateQuery from '../../../../../../@Api/Automation/Query/AggregateQuery';
import { AggregateSelectionComparisonConstraintNode } from '../../../../../../@Api/Model/Implementation/AggregateSelectionComparisonConstraintNode';
import { EntitySelectionBuilder } from '../EntitySelectionBuilder';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import getTypes from '../../../Type/Api/getTypes';
import { getRhsValue } from './Util/getRhsValue';

export function buildAggregateSelectionComparisonConstraintNode(
    filter: ComparisonPredicate,
    context: EntityConstraintBuilderFilterContext
): ConstraintNode
{
    const lhs = filter.lhs as QueryComputation;
    const query = lhs.query as AggregateQuery;
    const rhs = filter.rhs;
    const types = getTypes();

    return new AggregateSelectionComparisonConstraintNode(
        EntitySelectionBuilder.builder(
            query.entityType,
            (builder, rootPath) =>
                builder
                    .if(
                        () => query.filter !== undefined,
                        sb =>
                            sb.where(
                                cb =>
                                    cb.filter(
                                        query.filter,
                                        {
                                            context: context.context,
                                            parameter: query.parameter,
                                            parent: context,
                                        }
                                    )
                            )
                    )
        ).build(),
        query.aggregate,
        query.aggregateFieldPath ??
        EntityPath.root(lhs.query.entityType)
            .field(types.Entity.Field.Id),
        filter.comparator,
        getRhsValue(rhs, context)
    );
}
