import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../../Viewer/LayoutViewer';
import TableLayout from '../../../../../../@Api/Layout/Type/Table/TableLayout';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import { TableLayoutBodyViewer } from './TableLayoutBodyViewer';
import { Table, TableBody } from '@material-ui/core';

export interface TableLayoutViewerProps extends LayoutViewerProps<TableLayout>
{

}

const TableLayoutViewer: React.FC<TableLayoutViewerProps> =
    props =>
    {
        return <Table>
            <TableBody>
                <TableLayoutBodyViewer
                    {...props}
                    loader={
                        <TableRow>
                            <TableCell>
                                <Centered
                                    horizontal
                                >
                                    <Loader />
                                </Centered>
                            </TableCell>
                        </TableRow>
                    }
                />
            </TableBody>
        </Table>;
    };

export default observer(TableLayoutViewer);
