import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import getTypes from '../../../../../Type/Api/getTypes';
import useResults from '../../../../../Selection/Hooks/useResults';

export default function useProductDefaultPrices(
    product: Entity,
): Entity[]
{
    const types = getTypes();

    return useResults(
        types.Price.Type,
        (builder, rootPath) =>
            builder
                // price is linked to the product
                .where(
                    cb =>
                        cb.relatedToEntity(
                            rootPath
                                .joinTo(
                                    types.Price.RelationshipDefinition.Product,
                                    false
                                ),
                            product
                        )
                )
                // it's linked to a default priceList
                .where(
                    cb =>
                        cb.eq(
                            rootPath
                                .joinTo(
                                    types.PriceList.RelationshipDefinition.Prices,
                                    true
                                )
                                .field(types.PriceList.Field.IsDefaultForCurrency),
                            undefined,
                            true
                        )
                ),
        [
            types,
            product,
        ]
    );
}