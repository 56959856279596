import { observable } from 'mobx';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import FieldMapping from '../FieldMapping';

export default class SourceFieldMapping extends FieldMapping
{
    // ------------------------- Properties -------------------------

    @observable sourceFieldId: string;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                targetFieldPath: EntityFieldPath,
                sourceFieldId: string)
    {
        super(id, targetFieldPath);

        this.sourceFieldId = sourceFieldId;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new SourceFieldMapping(
            descriptor.id,
            EntityFieldPath.construct(descriptor.targetFieldPath),
            descriptor.sourceFieldId);
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'SourceField',
            sourceFieldId: this.sourceFieldId
        };
    }

    // ----------------------- Private logic ------------------------
}
