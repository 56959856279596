import { EntityPath } from '../../../../../Entity/Path/@Model/EntityPath';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../../Entity/Type/EntityTypeStore';

export default function getOwnFieldPaths(rootPath: EntityPath)
{
    const entityTypeStore = loadModuleDirectly(EntityTypeStore);
    const types = entityTypeStore.bespoke.types;

    return [
        ...rootPath.entityType
            .getInheritedFields()
            .filter(
                field =>
                    !types.Entity.Type.isA(field.entityType)
                    && !field.isComputedField)
            .map(
                field =>
                    rootPath.field(field)),
        ...[true, false]
            .map(
                isParent =>
                    rootPath.entityType
                        .getInheritedRelationshipDefinitions(isParent)
                        .filter(
                            relationshipDefinition =>
                                !types.Entity.Type.isA(relationshipDefinition.getEntityType(!isParent))
                                && !relationshipDefinition.isComputed(isParent))
                        .map(
                            relationshipDefinition =>
                                rootPath.joinTo(relationshipDefinition, isParent)
                                    .field()))
            .reduce((a, b) => a.concat(b), [])
    ];
}
