import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import CompositePredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import { PredicateEditorProps } from '../PredicateEditor';
import { LogicalOperator } from '../../../../../../../DataObject/Model/LogicalOperator';
import Menu from '../../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import MenuButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import useSwitch from '../../../../../../../../../@Util/Switch/useSwitch';
import ComparisonPredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import { Comparator } from '../../../../../../../DataObject/Model/Comparator';
import NotPredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/NotPredicate';
import Predicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import IconButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import InstanceOfPredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/InstanceOfPredicate';

export interface PredicateConstructorProps extends PredicateEditorProps
{
    onConstruct: (predicate: Predicate) => void;
}

const PredicateEditor: React.FC<PredicateConstructorProps> =
    props =>
    {
        const { onConstruct, comparisonConstructor, value } = props;
        const [ isMenuOpen, openMenu, closeMenu ] = useSwitch(false);

        const constructComparisonPredicate =
            useCallback(
                () =>
                {
                    onConstruct(
                        comparisonConstructor
                            ?
                                comparisonConstructor()
                            :
                                new ComparisonPredicate(
                                    undefined,
                                    Comparator.Equals,
                                    undefined));

                    closeMenu();
                },
                [
                    onConstruct,
                    comparisonConstructor,
                    closeMenu
                ]);

        const isCurrentPredicateCompositeAnd =
            useComputed(
                () =>
                    value instanceof CompositePredicate &&
                    value.operator === LogicalOperator.And,
                [
                    value
                ]);

        const constructCompositePredicate =
            useCallback(
                () =>
                {
                    onConstruct(
                        new CompositePredicate(
                            isCurrentPredicateCompositeAnd
                                ?
                                    LogicalOperator.Or
                                :
                                    LogicalOperator.And,
                            [
                                comparisonConstructor
                                    ?
                                        comparisonConstructor()
                                    :
                                        new ComparisonPredicate(
                                            undefined,
                                            Comparator.Equals,
                                            undefined)
                            ]));

                    closeMenu();
                },
                [
                    onConstruct,
                    isCurrentPredicateCompositeAnd,
                    comparisonConstructor,
                    closeMenu
                ]);

        const constructNotPredicate =
            useCallback(
                () =>
                {
                    onConstruct(
                        new NotPredicate(
                            comparisonConstructor
                                ?
                                    new CompositePredicate(
                                        LogicalOperator.And,
                                        [
                                            comparisonConstructor()
                                        ])
                                :
                                    new CompositePredicate(
                                        LogicalOperator.And,
                                        [
                                            new ComparisonPredicate(
                                                undefined,
                                                Comparator.Equals,
                                                undefined)
                                        ])));

                    closeMenu();
                },
                [
                    onConstruct,
                    comparisonConstructor,
                    closeMenu
                ]);

        const constructInstanceOfPredicate =
            useCallback(
                () =>
                {
                    onConstruct(
                        new InstanceOfPredicate(
                            undefined,
                            undefined));

                    closeMenu();
                },
                [
                    onConstruct,
                    closeMenu
                ]);

        if (props.value && !props.disabled)
        {
            return <MenuButton
                icon="add"
                tooltip={
                    <LocalizedText
                        code="PredicateEditor.AddFilter"
                        value="Filter toevoegen"
                    />
                }
                open={isMenuOpen}
                onOpen={openMenu}
                onClose={closeMenu}
            >
                <Menu>
                    <Item
                        name={
                            <LocalizedText
                                code="PredicateEditor.Comparison"
                                value="Vergelijking"
                            />
                        }
                        onClick={constructComparisonPredicate}
                    />
                    <Item
                        name={
                            isCurrentPredicateCompositeAnd
                                ?
                                    <LocalizedText
                                        code="PredicateEditor.LogicalOperatorOr"
                                        value="A óf B óf C..."
                                    />
                                :
                                    <LocalizedText
                                        code="PredicateEditor.LogicalOperatorAnd"
                                        value="A én B én C..."
                                    />

                        }
                        onClick={constructCompositePredicate}
                    />
                    <Item
                        name={
                            <LocalizedText
                                code="Generic.Not"
                                value="Niet"
                            />
                        }
                        onClick={constructNotPredicate}
                    />
                    <Item
                        name={
                            <LocalizedText
                                code="Generic.IsInstanceOf"
                                value="Is instantie van"
                            />
                        }
                        onClick={constructInstanceOfPredicate}
                    />
                </Menu>
            </MenuButton>;
        }
        else if (!props.disabled)
        {
            return <IconButton
                icon="add"
                tooltip={
                    <LocalizedText
                        code="PredicateEditor.AddFilter"
                        value="Filter toevoegen"
                    />
                }
                onClick={constructCompositePredicate}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(PredicateEditor);
