import { observable } from 'mobx';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import Predicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';

export class RelatedEntityWidget
{
    // ------------------------- Properties -------------------------

    @observable path: EntityPath;
    @observable title?: string;
    @observable titleIncludingCategories?: string;
    @observable predicate?: Predicate;
    @observable isExpandedByDefault: boolean;
    @observable isWithoutCard: boolean;
    @observable hasMoreMenu: boolean;

    // ------------------------ Constructor -------------------------

    constructor(path: EntityPath,
                title?: string,
                titleIncludingCategories?: string,
                predicate?: Predicate,
                isExpandedByDefault: boolean = false,
                isWithoutCard: boolean = false,
                hasMoreMenu?: boolean)
    {
        this.path = path;
        this.title = title;
        this.titleIncludingCategories = titleIncludingCategories;
        this.predicate = predicate;
        this.isExpandedByDefault = isExpandedByDefault;
        this.isWithoutCard = isWithoutCard;
        this.hasMoreMenu = hasMoreMenu;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
