import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import getConnectorActivationByCode from '../../../../../../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';
import React, { useCallback, useMemo } from 'react';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import performAction from '../../../../../../../../@Api/Entity/performAction';
import { loadModuleDirectly } from '../../../../../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../../../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import Divider from '../../../../../../../../@Future/Component/Generic/Divider/Divider';
import useIsConnectorActivationActivated from '../../../../../../Marketplace/Api/useIsConnectorActivationActivated';

export interface EInvoiceSendButtonsProps
{
    entity: Entity;
}

export const EInvoiceSendButtons: React.FC<EInvoiceSendButtonsProps> =
    observer(
        ({
            entity
         }) =>
        {
            const [ eInvoicingConnectorActivation, isLoading] =
                useAsyncResult(
                    () => getConnectorActivationByCode('EInvoicing'),
                    []
                );

            const isActivated = useIsConnectorActivationActivated(eInvoicingConnectorActivation);

            const canSendEInvoices =
                useMemo(
                    () =>
                        !isLoading && isActivated,
                    [
                        isLoading,
                        isActivated
                    ]
                );

            const onSendToEInvoicing =
                useCallback(
                    () =>
                        performAction(
                            entity,
                            {
                                code: 'EInvoicing.SendInvoice'
                            })
                            .then()
                            .catch(
                                (error) => {
                                    loadModuleDirectly(FeedbackStore)
                                        .enqueueSnackbar(
                                            error.data.message,
                                            {
                                                variant: 'error',
                                                autoHideDuration: 6000
                                            });
                                }
                            ),
                    [
                        entity
                    ]
                )

            const onGenerateEInvoice =
                useCallback(
                    () =>
                        performAction(
                            entity,
                            {
                                code: 'EInvoicing.GenerateInvoice'
                            })
                            .then()
                            .catch(
                                (error) => {
                                    loadModuleDirectly(FeedbackStore)
                                        .enqueueSnackbar(
                                            error.data.message,
                                            {
                                                variant: 'error',
                                                autoHideDuration: 6000
                                            }
                                        );
                                }
                            ),
                    [
                        entity
                    ]
                )

            return <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                {
                    canSendEInvoices &&
                    <ViewGroupItem>
                        <Menu>
                            <Item
                                name={
                                    <LocalizedText
                                        code="Activity.Invoice.SendEInvoicing"
                                        value="via E-Facturatie"
                                    />
                                }
                                onClick={onSendToEInvoicing}
                            />
                        </Menu>
                    </ViewGroupItem>
                }
                <Divider/>
                <ViewGroupItem>
                    <Menu>
                        <Item
                            name={
                                <LocalizedText
                                    code="Activity.Invoice.GenerateEInvoice"
                                    value="Genereer E-Factuur XML bestand"
                                />
                            }
                            onClick={onGenerateEInvoice}
                        />
                    </Menu>
                </ViewGroupItem>
            </ViewGroup>

        }
    );
