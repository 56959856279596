import Validation from '../../../Automation/Validation/Validation';
import Dependency from '../../../Automation/Parameter/Dependency';
import Value from '../../../Automation/Value/Value';
import LayoutAction from '../LayoutAction';
import LayoutDependencyContext from '../../LayoutDependencyContext';
import { observable } from 'mobx';
import ValueType from '../../../Automation/Value/Type/ValueType';
import localizeText from '../../../Localization/localizeText';
import DynamicParameterAssignment from '../../../Automation/Function/Dynamic/DynamicParameterAssignment';
import ActionSignature from '../../../Automation/ActionTemplate/Model/ActionSignature';
import uuid from '../../../../@Util/Id/uuid';
import EmptyValueType from '../../../Automation/Value/Type/EmptyValueType';
import invokeActionTemplate from '../../../Automation/ActionTemplate/Api/invokeActionTemplate';
import ActionInvocation from '../../../Automation/ActionTemplate/Model/ActionInvocation';
import getActionSignatureById from '../../../Automation/ActionTemplate/Api/getActionSignatureById';
import LayoutContext from '../../LayoutContext';

export default class ActionTemplateInvocationAction extends LayoutAction
{
    // ------------------------- Properties -------------------------

    @observable.ref actionSignature: ActionSignature;
    @observable.ref parameterAssignment: DynamicParameterAssignment;

    // ------------------------ Constructor -------------------------

    constructor(actionSignature: ActionSignature,
                parameterAssignment: DynamicParameterAssignment)
    {
        super();

        this.actionSignature = actionSignature;
        this.parameterAssignment = parameterAssignment;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async apply(context: LayoutContext): Promise<Value<any, any>>
    {
        const parameterAssignment = await this.parameterAssignment.apply(context);

        const result =
            await invokeActionTemplate(
                new ActionInvocation(
                    uuid(),
                    this.actionSignature,
                    parameterAssignment));

        return result.result;
    }

    getReturnType(): ValueType<any>
    {
        return this.actionSignature?.resultType || EmptyValueType.instance;
    }

    getName(): string
    {
        return localizeText(
            'LayoutAction.ActionTemplateInvocation.InvokeAction',
            'Actie uitvoeren...');
    }

    getDependencies(): Dependency[]
    {
        return this.parameterAssignment.getDependencies();
    }

    validate(): Validation[]
    {
        return this.parameterAssignment.validate();
    }

    toDescriptor()
    {
        return {
            type: 'ActionTemplateInvocation',
            actionId: this.actionSignature.id,
            parameterAssignment: this.parameterAssignment.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const actionId = descriptor.actionId;
        const actionSignature = await getActionSignatureById(actionId, dependencyContext);

        return new ActionTemplateInvocationAction(
            actionSignature,
            await DynamicParameterAssignment.fromDescriptor(
                descriptor.parameterAssignment,
                actionSignature.parameters,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
