import React from 'react';
import { DialogTitle as MuiDialogTitle } from '@material-ui/core';

export interface DialogTitleProps
{

}
const DialogTitle: React.FC<DialogTitleProps> =
    props =>
    {
        return <MuiDialogTitle>
            {props.children}
        </MuiDialogTitle>;
    };

export default DialogTitle;
