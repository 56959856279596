import { useCallback, useState } from 'react';
import useOnDragEnd from './useOnDragEnd';
import { DropResult } from 'react-beautiful-dnd';

export default function useDroppedItemId()
{
    const [ droppedItemId, setDroppedItemId ] = useState(undefined);

    const onEnd =
        useCallback(
            (result: DropResult) =>
                setDroppedItemId(result.draggableId),
            [
                setDroppedItemId
            ]);

    useOnDragEnd(onEnd);

    return droppedItemId;
}