import React from 'react';
import FormDesigner from '../../../../Entity/Form/Editor/FormEditor';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';

export interface FormEditorProps
{
    entityType: EntityType;
}

const FormEditor: React.FC<FormEditorProps> =
    props =>
    {
        return <FormDesigner
            entityType={props.entityType}
        />;
    };

export default observer(FormEditor);
