import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Selectbox from '../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import useTypes from '../../Type/Api/useTypes';
import View from '../Model/View';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import CurrentUserContext from '../../../User/CurrentUserContext';
import getEntityTypeViewsBuilder from '../Api/getEntityTypeViewsBuilder';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { makeStyles } from '@material-ui/styles';
import getSystemDefaultView from '../Api/getSystemDefaultView';
import { textSecondaryColor } from '../../../../../@Resource/Theme/Theme';
import { createStringComparator } from '../../../../Generic/List/V2/Comparator/StringComparator';
import { createBooleanComparator } from '../../../../Generic/List/V2/Comparator/BooleanComparator';
import { useDefaultView } from '../Api/useDefaultView';
import { getSearchTermsFromQuery } from '../../../../../@Util/Search/getSearchTermsFromQuery';
import { DataObject } from '../../../DataObject/Model/DataObject';

export interface ViewSelectorProps
{
    entityType: EntityType;
    view?: View;
    onChange: (view: View) => void;
    outlined?: boolean;
    addSystemDefaultView?: boolean;
}

const useStyles =
    makeStyles({
        select: {
            minWidth: '300px'
        },
        caption: {
            textAlign: 'right',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: textSecondaryColor,
            '&:selected': {
                color: 'white',
            }
        },
        selectBoxItem: {
            fontSize: '14px !important',
            padding: '4px'
        },
    });

export const ViewSelector: React.FC<ViewSelectorProps> = observer(
    (props) =>
    {
        const types = useTypes();
        const classes = useStyles();
        const currentUserStore = useContext(CurrentUserContext);
        const [ defaultView, isLoadingDefaultView ] = useDefaultView(props.entityType);

        const isSystemDefaultView =
            useComputed(
                () =>
                    !isLoadingDefaultView &&
                    defaultView?.entity === undefined,
                [
                    defaultView,
                    isLoadingDefaultView
                ]
            )

        const viewLoader =
            useCallback(
                (query: string) =>
                    EntitySelectionBuilder.builder(
                        types.View.Type,
                        (builder, rootPath) =>
                            getEntityTypeViewsBuilder(
                                props.entityType,
                                currentUserStore,
                                builder,
                                rootPath
                            )
                            .if(
                                () =>
                                    query !== undefined && query.length > 0,
                                sb =>
                                    sb.where(
                                        cb =>
                                        getSearchTermsFromQuery(query)
                                            .forEach(
                                                term =>
                                                    cb.contains(
                                                        rootPath.field(types.View.Field.LocalizedName),
                                                        undefined,
                                                        DataObject.constructFromTypeIdAndValue(
                                                            'Text',
                                                            term
                                                        )
                                                    )
                                            )
                                    )
                            )
                            .where(
                                sb =>
                                    sb.eq(
                                        rootPath.field(types.View.Field.IsDefault),
                                        undefined,
                                        false
                                    )
                            )
                    )
                    .select()
                    .then(
                        async results =>
                        {
                            const views =
                                await Promise.all(
                                    results
                                        .map(
                                            async result =>
                                            {
                                                try
                                                {
                                                    return await View.fromDescriptor(
                                                        result.entity.getObjectValueByField(types.View.Field.Specification),
                                                        result.entity
                                                    );
                                                }
                                                catch (e)
                                                {
                                                    console.error(`Error deserializing view with ID: ${result.entity.id}`, e);
                                                    return undefined;
                                                }
                                            }
                                        )
                                );

                            return views.filter(
                                view =>
                                    view !== undefined
                            );
                        }
                    )
                    .then(
                        nonDefaultViews =>
                        {
                            const resultingViews = [];

                            const isDefaultViewInQuery =
                                !isLoadingDefaultView &&
                                defaultView &&
                                query && getSearchTermsFromQuery(query)
                                    .some(
                                        term => defaultView.name.includes(term)
                                    );

                            if (!query || query.length === 0 || isDefaultViewInQuery)
                            {
                                resultingViews.push(defaultView);
                            }

                            if (!isSystemDefaultView && props.addSystemDefaultView)
                            {
                                resultingViews.push(
                                    getSystemDefaultView(props.entityType)
                                );
                            }

                            return Promise.resolve(
                                resultingViews.concat(nonDefaultViews)
                                    .sort(
                                        createStringComparator(
                                            view => view.name
                                        )
                                    )
                                    .sort(
                                        // set default view as the first
                                        createBooleanComparator(
                                            view =>
                                                !view.entity?.getObjectValueByField(types.View.Field.IsDefault)
                                        )
                                    )
                            );
                        }
                    )
                    .catch(
                        error =>
                        {
                            console.error(`Error retrieving views for entityType ${props.entityType.code}: ${error}`);

                            return Promise.reject(error);
                        }
                    ),
                [
                    types,
                    props.addSystemDefaultView,
                    props.entityType,
                    currentUserStore,
                    defaultView,
                    isSystemDefaultView,
                    isLoadingDefaultView
                ]
            );

        const formatter =
            useCallback(
                (view: View) =>
                {
                    const isStandard =
                        (defaultView && view.entity?.getObjectValueByField(types.View.Field.IsDefault)) ||
                        (!defaultView && view.entity === undefined)

                    const caption =
                        isStandard
                            ?  <div
                                className={classes.caption}
                               >
                                <LocalizedText
                                    code="ViewSelector.IsDefaultView"
                                    value="Standaard"
                                />
                              </div>
                            : undefined

                    return <ViewGroup
                            orientation="horizontal"
                            spacing={10}
                            className={classes.selectBoxItem}
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                               {view.name}
                            </ViewGroupItem>
                            {
                                caption &&
                                <ViewGroupItem>
                                    {caption}
                                </ViewGroupItem>
                            }
                        </ViewGroup>
                },
                [
                    classes,
                    types,
                    defaultView
                ]
            );

        const valueFormatter =
            useCallback(
                (view: View) =>
                    view.name,
                []
            );

        const idResolver =
            useCallback(
                (view: View) =>
                    view.id,
                []
            );

        return <div
            className={classes.select}
        >
            <Selectbox
                load={viewLoader}
                onChange={props.onChange}
                formatOption={formatter}
                formatValue={valueFormatter}
                idResolver={idResolver}
                value={props.view}
                outlined={props.outlined}
            />
        </div>;
    }
);

