import moment from 'moment';
import { DependencyList, useCallback, useMemo } from 'react';

export default function useTimeDurationFromPeriod(startDate: Date,
                                                  endDate: Date,
                                                  onChange: (newEndDate: Date) => void,
                                                  deps: DependencyList): [ number, number, (hours: number, minutes: number) => void]
{
    const hours =
        useMemo(
            () =>
                moment(endDate).diff(startDate, 'hours'),
            [
                endDate,
                startDate
            ]);
    const minutes =
        useMemo(
            () =>
                moment(endDate).subtract(hours, 'hours').diff(startDate, 'minutes'),
            [
                endDate,
                hours,
                startDate
            ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeCallback = useCallback(onChange, deps);
    const setHoursAndMinutes =
        useCallback(
            (hours: number, minutes: number) =>
            {
                onChangeCallback(
                    moment(startDate)
                        .add(hours, 'hours')
                        .add(minutes, 'minutes')
                        .toDate());
            },
            [
                onChangeCallback,
                startDate,
            ]);

    return [
        hours,
        minutes,
        setHoursAndMinutes
    ];
}
