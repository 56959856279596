import { EntityPath } from '../../Path/@Model/EntityPath';
import { RelationshipMutationFilter } from '../../../../../@Api/Model/Implementation/RelationshipMutationFilter';
import { FieldMutationFilter } from '../../../../../@Api/Model/Implementation/FieldMutationFilter';
import { useMemo } from 'react';

export default function useEventFilters(rootPath: EntityPath)
{
    return useMemo(
        () =>
        {
            return [
                new FieldMutationFilter(rootPath),
                new RelationshipMutationFilter(rootPath)
            ];
        },
        [
            rootPath,
        ]);
}
