import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';

export class BespokeDataset extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore, code: string = 'Dataset')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isConfigurationType(): boolean
    {
        return true;
    }

    isSortable(pathFromRelatedEntity: RelatedEntityPath): boolean
    {
        const lastJoinNode = pathFromRelatedEntity.path.lastJoinNode;

        if (lastJoinNode)
        {
            return !lastJoinNode.isParent &&
                lastJoinNode.relationshipDefinition === this.entityTypeStore.bespoke.types.Dataset.RelationshipDefinition.Categories;
        }
        else
        {
            return false;
        }
    }

    // ----------------------- Private logic ------------------------
}
