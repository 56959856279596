import Value from '../Value/Value';
import Function from '../Function/Function';
import EmptyValue from '../Value/EmptyValue';
import FunctionContext from '../Function/FunctionContext';

export default function safelySynchronousApplyFunction(
    fn: Function<any, any>,
    context: FunctionContext
): Value<any, any>
{
    if (fn === undefined)
    {
        return EmptyValue.instance;
    }

    const result = fn.synchronousApply(context);

    if (result === undefined)
    {
        return EmptyValue.instance;
    }
    else
    {
        return result;
    }
}
