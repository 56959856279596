import React from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Click from '../Click/Click';
import Inset from '../Inset/Inset';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useSpotlerCampaignSendCount from '../../../Viewer/Content/Activity/SpotlerCampaign/Hooks/useSpotlerCampaignSendCount';
import { SpotlerCampaignChartDelivered } from '../../../Viewer/Content/Activity/SpotlerCampaign/Charts/SpotlerCampaignChartDelivered';
import { SpotlerCampaignChartViewed } from '../../../Viewer/Content/Activity/SpotlerCampaign/Charts/SpotlerCampaignChartViewed';
import { observer } from 'mobx-react';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface SpotlerCampaignProps
{
    spotlerCampaign: Entity;
}

const useStyles = makeStyles({
    root: {
        cursor: 'pointer',
    },
    labelName: {
        fontWeight: 'bold'
    }
});

export const SpotlerCampaign: React.FC<SpotlerCampaignProps> =
    observer(
        (
            {
                spotlerCampaign
            }
        ) =>
        {
            const classes = useStyles();

            const sendCount = useSpotlerCampaignSendCount(spotlerCampaign);

            return <Click
                open
            >
                <Inset>
                    <ViewGroup
                        className={classes.root}
                        orientation="horizontal"
                        spacing={10}
                        alignment="center"
                    >
                        <ViewGroupItem>
                            <SpotlerCampaignChartDelivered
                                size={80}
                                spotlerCampaign={spotlerCampaign}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <SpotlerCampaignChartViewed
                                size={80}
                                spotlerCampaign={spotlerCampaign}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem
                            ratio={1}
                        >
                            <ViewGroup orientation="vertical" spacing={5}>
                                <ViewGroupItem>
                                    <span className={classes.labelName}>
                                        {spotlerCampaign.name}
                                    </span>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <LocalizedText
                                        code="Campaign.SendItemCount"
                                        value="${sendCount} verzonden items"
                                        sendCount={sendCount}
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </Inset>
            </Click>;
        }
    );