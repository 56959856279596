import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Currency } from '../../../../../@Api/Localization/Currency';
import { makeStyles } from '@material-ui/core';
import Selectbox from '../Selectbox/Selectbox';
import search from '../../../../../@Util/String/search';
import { createStringComparator } from '../../../../../@Component/Generic/List/V2/Comparator/StringComparator';
import LocalizedText from '../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';
import { textSecondaryColor } from '../../../../../@Resource/Theme/Theme';

const useStyles = makeStyles({
    currency: {
        padding: 6
    },
    currencyValue: {
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    },
    currencySymbol: {
        paddingLeft: '5px',
        color: textSecondaryColor,
    },
    currencyPrecision: {
        color: textSecondaryColor,
        fontSize: 'smaller'
    },
    currencyName: {
        color: textSecondaryColor,
        fontSize: 'small',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden'
    }
});

export interface CurrencySelectorProps
{
    currencies: Currency[];
    value: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    showName?: boolean;
    showPrecision?: boolean;
}

const CurrencySelector: React.FC<CurrencySelectorProps> =
    ({
         currencies,
         value,
         onChange,
         disabled,
         showName,
         showPrecision,
     }) =>
    {
        const classes = useStyles();

        const currency =
            useMemo(
                () =>
                    currencies
                        ?.find(currency => currency.code === value),
                [
                    currencies,
                    value,
                ]
            );

        const onChangeCurrency =
            useCallback(
                (currency: Currency) =>
                    onChange
                        ? onChange(currency.code)
                        : undefined,
                [
                    onChange,
                ]
            );

        const loadCurrencyOptions =
            useCallback(
                (query: string) =>
                    Promise.resolve(
                        currencies
                            ?.filter(
                                currency =>
                                    search(query, currency.name))
                            ?.sort(createStringComparator(currency => currency.name))
                    ),
                [
                    currencies
                ]
            );

        const formatCurrencySymbol =
            (_currency: Currency)  =>
                <ViewGroup
                    orientation="horizontal"
                    spacing={0}
                >
                    {
                        _currency.symbol && _currency.symbol !== _currency.code &&
                        <ViewGroupItem
                            className={classes.currencySymbol}
                        >
                            {`(${_currency.symbol})`}
                        </ViewGroupItem>
                    }
                </ViewGroup>;

        const formatCurrency =
            useCallback(
                (currency: Currency) =>
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={0}
                                >
                                    <ViewGroupItem
                                        className={classes.currencyValue}
                                    >
                                        {currency.code}
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        {formatCurrencySymbol(currency)}
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                            <ViewGroupItem
                                className={classes.currencyName}
                            >
                                {currency.name}
                            </ViewGroupItem>
                        </ViewGroup>,
                [
                    classes,
                    formatCurrencySymbol
                ]
            );

        const formatCurrencyOption =
            (_currency: Currency) =>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    className={classes.currency}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={4}
                        >
                            <ViewGroupItem>
                                {_currency.code}
                            </ViewGroupItem>
                            <ViewGroupItem
                                ratio={1}
                            >
                                {formatCurrencySymbol(_currency)}
                            </ViewGroupItem>
                            {
                                showPrecision && _currency.precision > 0 &&
                                <ViewGroupItem
                                    alignment="right"
                                    className={classes.currencyPrecision}
                                >
                                    <LocalizedText
                                        code="OrganizationSettings.Currency.Precision"
                                        value="Precisie ${precision}"
                                        precision={_currency.precision}
                                    />
                                </ViewGroupItem>
                            }
                        </ViewGroup>
                    </ViewGroupItem>
                    {
                        showName &&
                        <ViewGroupItem
                            className={classes.currencyName}
                        >


                            {_currency.name}

                        </ViewGroupItem>
                    }
                </ViewGroup>;

        if (currency && disabled)
        {
            return formatCurrency(currency);
        }
        else
        {
            return <Selectbox
                    load={loadCurrencyOptions}
                    idResolver={currency => currency.code}
                    value={currency}
                    onChange={onChangeCurrency}
                    formatOption={formatCurrencyOption}
                    formatValue={currency => formatCurrency(currency)}
                />
        }
    };

export default observer(CurrencySelector);