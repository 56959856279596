import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import React from 'react';
import { observer } from 'mobx-react';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Author from '../../../../Viewer/Content/Activity/RelatedActivityList/List/List/Item/Author/Author';
import { default as EntityInput } from '../../../../Input/Input';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import useTypes from '../../../../Type/Api/useTypes';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import AttachmentChip from '../../../../AttachmentList/Attachment/AttachmentChip';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export interface AttachmentDetailsProps
{
    attachment: Entity;
    commitContext?: CommitContext;
    canHaveVersions?: boolean;
}

export const AttachmentDetails: React.FC<AttachmentDetailsProps> =
    observer(
        ({
            attachment,
            commitContext,
            canHaveVersions
         }) =>
        {
            const types = useTypes();

            return <CardInset>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <Author
                            entity={attachment}
                            prefix={
                                localizeText(
                                    'Generic.VersionCreatedOn',
                                    'Versie gemaakt op'
                                )
                            }
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <EntityInput
                            entity={attachment}
                            field={types.Attachment.Field.Description}
                            labelPosition="left"
                            commitContext={commitContext}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Input
                             label={
                                 <LocalizedText
                                     code="Generic.FileName"
                                     value="Bestandsnaam"
                                 />
                             }
                             labelPosition="left"
                        >
                            <AttachmentChip
                                attachment={attachment}
                                readOnly
                            />
                        </Input>
                    </ViewGroupItem>
                    {
                        canHaveVersions &&
                        <ViewGroupItem>
                            <EntityInput
                                entity={attachment}
                                field={types.Attachment.Field.Version}
                                labelPosition="left"
                                disabled
                            />
                        </ViewGroupItem>
                    }
                </ViewGroup>

            </CardInset>

        }
)