import { EntityRelationshipDefinition } from '../../../Model/Implementation/EntityRelationshipDefinition';
import { EntityType } from '../../../Model/Implementation/EntityType';
import getRelationshipDefinitionFilterDescriptorFromSetting from './getRelationshipDefinitionFilterDescriptorFromSetting';
import getRelationshipDefinitionFilterDescriptorFromEntity from './getRelationshipDefinitionFilterDescriptorFromEntity';

export default function getInheritedRelationshipDefinitionFilterDescriptor(entityType: EntityType,
                                                                           relationshipDefinition: EntityRelationshipDefinition,
                                                                           isParent: boolean): any | undefined
{
    let currentType = entityType;

    while (currentType !== undefined)
    {
        const filterDescriptor =
            getRelationshipDefinitionFilterDescriptorFromSetting(
                currentType,
                relationshipDefinition,
                isParent);

        if (filterDescriptor)
        {
            return filterDescriptor;
        }
        else if (currentType === relationshipDefinition.getEntityType(!isParent))
        {
            break;
        }

        currentType = currentType.parentType;
    }

    return getRelationshipDefinitionFilterDescriptorFromEntity(relationshipDefinition, isParent);
}
