import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutActionEditorProps } from '../../LayoutActionEditor';
import { runInAction } from 'mobx';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationEditor from '../../../../Entity/Viewer/Content/Automation/Editor/Computation/ComputationEditor';
import Computation from '../../../../../../@Api/Automation/Function/Computation/Computation';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import DownloadFileAction from '../../../../../../@Api/Layout/Action/Type/DownloadFileAction';

export interface DownloadFileActionEditorProps extends LayoutActionEditorProps<DownloadFileAction>
{

}

const DownloadFileActionEditor: React.FC<DownloadFileActionEditorProps> =
    props =>
    {
        const setFile =
            useCallback(
                (file?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.action.file = file
                    ),
                [
                    props.action
                ]
            );

        return <CardInset
            top={false}
        >
            <Input
                label={
                    <LocalizedText
                        code="LayoutAction.DownloadFile"
                        value="Download bestand"
                    />
                }
                labelPosition="left"
            >
                <ComputationEditor
                    context={props.context}
                    value={props.action.file}
                    onChange={setFile}
                />
            </Input>
        </CardInset>;
    };

export default observer(DownloadFileActionEditor);
