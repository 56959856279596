import React from 'react';
import { observer } from 'mobx-react-lite';
import { EntityPath } from '../../@Model/EntityPath';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import BreadcrumbNode from './Node/BreadcrumbNode';

export interface PathBreadcrumbProps
{
    path: EntityPath;
    onSelect: (path: EntityPath) => void;
}

const PathBreadcrumb: React.FC<PathBreadcrumbProps> =
    props =>
    {
        return <ViewGroup
            orientation="horizontal"
            spacing={5}
            glue={() => "›"}
        >
            {
                props.path.nodes.map(
                    (node, idx) =>
                        <ViewGroupItem
                            key={node.id()}
                        >
                            <BreadcrumbNode
                                {...props}
                                idx={idx}
                            />
                        </ViewGroupItem>)
            }
        </ViewGroup>
    };

export default observer(PathBreadcrumb);
