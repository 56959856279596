import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { EntityContext } from '../../../../../Entity/@Model/EntityContext';
import { EntityPath } from '../../../../../Entity/Path/@Model/EntityPath';
import useSwitch from '../../../../../../../@Util/Switch/useSwitch';
import { EntityFieldPath } from '../../../../../Entity/Path/@Model/EntityFieldPath';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Popper from '../../../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import FieldPathSelector from '../../../../../Entity/Path/FieldPathEditor/FieldPathSelector';
import uuid from '../../../../../../../@Util/Id/uuid';
import View from '../../../../../Entity/View/Model/View';
import ColumnOrdering from '../../../../../Entity/View/Model/Column/ColumnOrdering';
import ViewColumnOrderingEditor from './ViewColumnOrderingEditor';
import Column from '../../../../../Entity/View/Model/Column/Column';
import AddIconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/AddIconButton/AddIconButton';
import CardHeader from '../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { textSecondaryColor } from '../../../../../../../@Resource/Theme/Theme';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles =
    makeStyles({
        olContainer: {
            maxWidth: 500
        }
    })

export interface ViewColumnOrderingsEditorProps
{
    view: View;
    column: Column;
}

const ViewColumnOrderingsEditor: React.FC<ViewColumnOrderingsEditorProps> =
    props =>
    {
        const { view, column } = props;
        const classes = useStyles();

        const fieldContext =
            useMemo(
                () =>
                    new EntityContext(
                        [],
                        EntityPath.fromEntityType(view.entityType)),
                [
                    view
                ]);

        const [ isFieldSelectorOpen, openFieldSelector, closeFieldSelector ] = useSwitch(false);
        const selectFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                        {
                            column.ordering.push(
                                new ColumnOrdering(
                                    uuid(),
                                    fieldPath,
                                    false));

                            closeFieldSelector();
                        }),
                [
                    column,
                    closeFieldSelector
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem>
                        <CardHeader>
                            <LocalizedText
                                code="Configuration.View.SortedBy"
                                value="Gesorteerd op"
                            />
                        </CardHeader>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Popper
                            reference={
                                <AddIconButton
                                    onClick={openFieldSelector}
                                    color={textSecondaryColor}
                                />
                            }
                            popper={
                                <Card>
                                    <FieldPathSelector
                                        context={fieldContext}
                                        onSelect={selectFieldPath}
                                    />
                                </Card>
                            }
                            open={isFieldSelectorOpen}
                            onClose={closeFieldSelector}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
            {
                column.ordering.length > 0 &&
                    <ViewGroupItem>
                        <div
                            className={classes.olContainer}
                        >
                            <ol>
                                {
                                    column.ordering.map(
                                        ordering =>
                                            <li
                                                key={ordering.id}
                                            >
                                                <ViewColumnOrderingEditor
                                                    {...props}
                                                    ordering={ordering}
                                                />
                                            </li>
                                    )
                                }
                            </ol>
                        </div>
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(ViewColumnOrderingsEditor);
