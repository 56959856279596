import Dataset from '../Model/Dataset';
import { useMemo } from 'react';
import useTypes from '../../Type/Api/useTypes';

export default function useIsAlteredDataset(dataset: Dataset)
{
    const types = useTypes();

    return useMemo(
        () =>
        {
            return dataset &&
                (dataset.entity?.name === 'Forecast' ||
                dataset.entity?.name === 'Forecast (gewogen)' ||
                dataset.entity?.name === 'Abonnementenstand' ||
                dataset.entity?.name === 'Gefactureerd' ||
                dataset.entity?.name === 'Abonnementen churn');
        },
        [
            dataset,
            types
        ]);
}
