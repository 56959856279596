import React from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../../Type/Api/useTypes';
import useWorkflowState from '../../../Api/useWorkflowState';
import useWorkflowStates from '../../../Api/useWorkflowStates';
import equalsEntity from '../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import StateButton from '../../StateButton/StateButton';

export interface SubscriptionProps
{
    entity: Entity;
}

const Subscription: React.FC<SubscriptionProps> =
    props =>
    {
        const types = useTypes();
        const states = useWorkflowStates(props.entity.entityType);
        const state = useWorkflowState(props.entity);
        const toStates =
            useComputed(
                () =>
                    states &&
                        states
                            .filter(
                                checkState =>
                                {
                                    const code = checkState.getObjectValueByField(types.Datastore.Field.Code);

                                    return code !== 'AwaitingActivation'
                                        && !equalsEntity(state, checkState)
                                }),
                [
                    states,
                    types,
                    state
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={10}
            alignment="center"
        >
            {
                toStates.map(
                    toState =>
                        <ViewGroupItem
                            key={toState.uuid}
                        >
                            <StateButton
                                entity={props.entity}
                                state={toState}
                            />
                        </ViewGroupItem>)
            }
        </ViewGroup>;
    };

export default observer(Subscription);
