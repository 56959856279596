import React, { useMemo, useRef } from 'react';
import { CircularProgress, IconButton as MuiIconButton } from '@material-ui/core';
import { BaseButtonProps, useButtonClickHandler } from '../../Button';
import styles from './IconButton.module.scss';
import Icon from '../../../Icon/Icon';
import { classNames } from '../../../../../Util/Class/classNames';
import Tooltip from '../../../Tooltip/Tooltip';
import useSafeState from '../../../../../Util/SafeState/useSafeState';
import Badge from '@material-ui/core/Badge';
import { lightTextColor } from '../../../../../../@Resource/Theme/Theme';

export interface IconButtonClasses
{
    root?: string;
    icon?: string;
}

export interface IconButtonProps extends BaseButtonProps
{
    icon: string;
    tooltip: React.ReactNode;
    outlined?: boolean;
    filled?: boolean;
    color?: string;
    disableShadow?: boolean;
    size?: number;
    iconSize?: number;
    iconOutlined?: boolean;
    small?: boolean;
    classes?: IconButtonClasses;
    loading?: boolean;
    badge?: React.ReactNode;
    badgeColor?: BadgeColor;
    badgeVariant?: BadgeVariant;
    borderSize?: number;
    square?: boolean;
}

export type BadgeColor = 'primary' | 'secondary' | 'error';
export type BadgeVariant = 'standard' | 'dot';

const IconButton: React.FC<IconButtonProps> =
    props =>
    {
        const ref = useRef();
        const [ _isLoading, setLoading ] = useSafeState(ref, false);
        const isLoading = _isLoading || props.loading;

        const clickCallback = useButtonClickHandler(props.onClick, setLoading);

        const color = props.disabled ? lightTextColor : props.color;

        const foregroundColor =
            props.filled
                ?
                    'white'
                :
                    props.disableShadow
                        ?
                            color
                        :
                            color
                                ?
                                    color
                                :
                                    'default';

        const size =
            useMemo(
                () =>
                {
                    if (props.size)
                    {
                        return props.size;
                    }
                    else
                    {
                        if (props.small)
                        {
                            return 21;
                        }
                        else
                        {
                            return 30;
                        }

                        // size={21}
                        // iconSize={16}
                    }
                },
                [
                    props.small,
                    props.size
                ]);

        const iconSize =
            useMemo(
                () =>
                {
                    if (props.iconSize)
                    {
                        return props.iconSize;
                    }
                    else
                    {
                        if (props.small)
                        {
                            return 17;
                        }
                        else
                        {
                            return (size + 2) / 2;
                        }
                    }
                },
                [
                    props.iconSize,
                    props.small,
                    size
                ]);

        const loaderSize =
            useMemo(
                () =>
                    size + 2,
                [
                    size
                ]);

        const button =
            <MuiIconButton
                ref={ref}
                onClick={clickCallback}
                disabled={isLoading || props.disabled}
                classes={{
                    root: classNames(styles.root, props.classes && props.classes.root)
                }}
                style={{
                    width: size,
                    height: size,
                    color: foregroundColor,
                    backgroundColor:
                        props.filled
                            ?
                                props.color
                            :
                                props.disableShadow
                                    ?
                                        'unset'
                                    :
                                        undefined,
                    border:
                        props.outlined
                            ?
                                `${props.borderSize || 2}px solid ${props.color || 'rgba(0, 0, 0, 0.54)'}`
                            :
                                undefined,
                    borderRadius:
                        props.square
                            ? 5
                            : undefined,
                }}
            >
                <Icon
                    className={
                        classNames(
                            styles.icon,
                            props.classes && props.classes.icon
                        )}
                    outlined={props.iconOutlined}
                    color={foregroundColor}
                    icon={props.icon}
                    size={iconSize}
                />
                {
                    isLoading &&
                        <CircularProgress
                            size={loaderSize}
                            classes={{
                                root: classNames(styles.loader, props.filled && styles.filled)
                            }}
                        />
                }
            </MuiIconButton>;

        return <Tooltip
            title={props.tooltip}
        >
            {
                props.badge
                    ?
                        <Badge
                            badgeContent={props.badge}
                            color={props.badgeColor}
                            variant={props.badgeVariant}
                        >
                            {button}
                        </Badge>
                    :
                        button
            }
        </Tooltip>;
    };

IconButton.defaultProps = {
    badgeColor: 'error'
};

export default IconButton;
