import { observable, runInAction } from 'mobx';
import Computation from '../../../../../../../../../@Api/Automation/Function/Computation/Computation';

const currentCopiedComputation = observable.box<Computation<any, any>>();

export function copyComputationToClipboard(computation: Computation<any, any>)
{
    runInAction(
        () =>
            currentCopiedComputation.set(computation));
}

export function getCopiedComputationFromClipboard()
{
    return currentCopiedComputation.get();
}

export function hasCopiedComputationInClipboard()
{
    return getCopiedComputationFromClipboard() !== undefined;
}
