import React from 'react';
import { observer } from 'mobx-react-lite';
import { IntercomProvider } from 'react-use-intercom';
import { IntercomDetails } from './IntercomDetails';
import { GlobalEnvironment } from '../../../@Global/GlobalEnvironment';

export interface IntercomManagerProps
{

}

export const IntercomManager: React.FC<IntercomManagerProps> = observer(
    () =>
    {
        const workspaceId = GlobalEnvironment.INTERCOM_WORKSPACE_ID;

        return <IntercomProvider
            appId={workspaceId}
            autoBoot
        >
            <IntercomDetails/>
        </IntercomProvider>
    }
)