import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { observable } from 'mobx';
import Segment from './Segment';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import getPredicateFromDescriptor from '../../../../../@Api/Automation/Api/getPredicateFromDescriptor';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import { PredicateTypeStore } from '../../../Predicate/PredicateTypeStore';
import getNewViewPredicateFromOldPredicate from '../../View/Api/getNewViewPredicateFromOldPredicate';
import getViewParameters from '../../View/Api/getViewParameters';
import List from '../../View/Model/Specification/List';
import Layout from '../../../../../@Api/Layout/Layout';
import getLayoutFromDescriptor from '../../../../../@Api/Layout/Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../@Api/Layout/LayoutDependencyContext';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import { ViewParams } from '../../View/Model/ViewParams';

export default class Dataset
{
    // ------------------------- Properties -------------------------

    @observable.ref entityType: EntityType;
    @observable.ref parameter: Parameter<EntityValueType>;
    @observable.ref parameters: ParameterDictionary;
    @observable.shallow segments: Segment[];
    @observable.ref filter?: Predicate;
    @observable.ref itemLayout?: Layout;
    @observable.ref list?: List;
    @observable.ref entity?: Entity;

    // ------------------------ Constructor -------------------------

    constructor(
        entityType: EntityType,
        parameter: Parameter<EntityValueType>,
        parameters: ParameterDictionary,
        segments: Segment[],
        filter?: Predicate,
        itemLayout?: Layout,
        list?: List,
        entity?: Entity
    )
    {
        this.entityType = entityType;
        this.parameter = parameter;
        this.parameters = parameters;
        this.segments = segments;
        this.filter = filter;
        this.itemLayout = itemLayout;
        this.list = list;
        this.entity = entity;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(descriptor: any,
                                entity?: Entity)
    {
        const entityType = loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.entityTypeId);
        const parameters = getViewParameters(entityType);
        let filter: Predicate;

        if (descriptor.filter)
        {
            if (descriptor.isFilterConverted)
            {
                filter =
                    await getPredicateFromDescriptor(
                        descriptor.filter,
                        new AutomationDependencyContext(parameters));
            }
            else
            {
                const oldFilter = loadModuleDirectly(PredicateTypeStore).fromSpecification(descriptor.filter);

                if (oldFilter)
                {
                    filter =
                        await getNewViewPredicateFromOldPredicate(
                            parameters,
                            oldFilter);
                }
            }
        }

        const list =
            descriptor.list
                ?
                    await List.fromDescriptor(descriptor.list, parameters)
                :
                    undefined;

        const segments =
            await Promise.all(
                (descriptor.segments as any[])
                    .map(
                        segment =>
                            Segment.fromDescriptor(segment)));

        const itemLayout =
            descriptor.itemLayout
                ?
                    await getLayoutFromDescriptor(
                        descriptor.itemLayout,
                        new LayoutDependencyContext(
                            parameters
                        )
                    )
                :
                    undefined;

        return new Dataset(
            entityType,
            parameters.getParameterById(ViewParams.Entity),
            parameters,
            segments,
            filter,
            itemLayout,
            list,
            entity);
    }

    toDescriptor()
    {
        return {
            entityTypeId: this.entityType.id,
            segments:
                this.segments.map(
                    segment =>
                        segment.toDescriptor()),
            filter: this.filter?.toDescriptor(),
            itemLayout: this.itemLayout?.toDescriptor(),
            list: this.list?.toDescriptor(false),
            isFilterConverted: true
        };
    }

    isValid(): boolean
    {
        return this.entityType !== undefined;
    }

    // ----------------------- Private logic ------------------------
}
