import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityValueMutation } from '../../../../../../../@Api/Model/Implementation/EntityValueMutation';
import EntityTypeContext from '../../../../Type/EntityTypeContext';
import Emphasis from '../Emphasis/Emphasis';
import Tooltip from '../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import useContainsHTML from '../../../../../../../@Util/String/useContainsHTML';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export interface ValueProps
{
    event: EntityValueMutation;
}

const Value: React.FC<ValueProps> =
    props =>
    {
        const entityTypeStore = useContext(EntityTypeContext);
        const oldValueContainsHtml = useContainsHTML(props.event.oldValue && props.event.oldValue.toString());
        const newValueContainsHtml = useContainsHTML(props.event.newValue && props.event.newValue.toString());

        const fieldName =
            useMemo(
                () =>
                    <Emphasis>{props.event.entityField.getName(props.event.entity.entityType, entityTypeStore).toLowerCase()}</Emphasis>,
                [
                    entityTypeStore,
                    props.event
                ]);

        return <Tooltip
            title={
                oldValueContainsHtml
                ? undefined
                : <>{localizeText('TimeLine.AuditTrail.OldValue', 'Waarde was')} {<Emphasis>{(props.event.oldValue && props.event.oldValue.toString()) || '-'}</Emphasis>}</>
            }
        >
            {
                newValueContainsHtml
                ? <>{fieldName} {localizeText('TimeLine.AuditTrail.Edited', 'aangepast')}</>
                : <>{fieldName} {localizeText('TimeLine.AuditTrail.EditedTo', 'aangepast naar')} <Emphasis>{(props.event.newValue && props.event.newValue.toString()) || '-'}</Emphasis></>
            }

        </Tooltip>;
    };

export default observer(Value);
