import React from 'react';
import { observer } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import BodyView from './View/BodyView';
import BodyLayout from '../../../../../@Api/Layout/Type/BodyLayout';

export interface BodyLayoutViewerProps extends LayoutViewerProps<BodyLayout>
{

}

const BodyLayoutViewer: React.FC<BodyLayoutViewerProps> =
    props =>
    {
        return <BodyView
            layout={props.layout}
        >
            <LayoutViewer
                {...props}
                layout={props.layout.layout}
            />
        </BodyView>;
    };

export default observer(BodyLayoutViewer);
