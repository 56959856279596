import CollaborationClient from '../CollaborationClient';
import { Delta, Selection, State } from './State';
import AwaitingWithBufferState from './AwaitingWithBufferState';
import StateSynchronized from './SynchronizedState';

export default class AwaitingConfirmState implements State
{
    outstanding: Delta;

    constructor(outstanding: Delta)
    {
        this.outstanding = outstanding;
    }

    applyFromClient(client: CollaborationClient,
                    delta: Delta): State
    {
        return new AwaitingWithBufferState(this.outstanding, delta);
    }

    applyFromServer(client: CollaborationClient,
                    delta: Delta): State
    {
        const newOutstanding = this.outstanding.transform(delta, false);
        client.applyDelta(newOutstanding);

        return new AwaitingConfirmState(newOutstanding);
    }

    serverAck(client: CollaborationClient): State
    {
        return StateSynchronized;
    }

    transformSelection(selection: Selection): Selection
    {
        return selection.transform(this.outstanding);
    }

    resend(client: CollaborationClient)
    {
        client.sendDelta(client.version, this.outstanding);
    }
}
