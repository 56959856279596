import { observable } from 'mobx';
import Input from './Input/Input';
import resolveInputFromDescriptor from './Input/Api/resolveInputFromDescriptor';

export default class Pointer
{
    // ------------------------- Properties -------------------------

    @observable entityId: number;
    @observable input: Input;
    @observable part?: string;
    @observable.ref value?: any;

    // ------------------------ Constructor -------------------------

    constructor(entityId: number,
                input: Input,
                part?: string)
    {
        this.entityId = entityId;
        this.input = input;
        this.part = part;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        const input = resolveInputFromDescriptor(descriptor.input);

        if (input)
        {
            return new Pointer(
                descriptor.entityId,
                input,
                descriptor.part);
        }
        else
        {
            return undefined;
        }
    }

    id()
    {
        return `${this.entityId}:${this.input.id()}${this.part ? `:${this.part}` : ''}`;
    }

    toDescriptor()
    {
        return {
            entityId: this.entityId,
            input: this.input.toDescriptor(),
            part: this.part
        };
    }

    // ----------------------- Private logic ------------------------
}
