import { observable } from 'mobx';
import ActionSignature from './ActionSignature';
import ParameterAssignment from '../../Parameter/ParameterAssignment';
import { FileReporter } from '../../../../@Component/Domain/DataObject/Model/DataDescriptor';

export default class ActionInvocation
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref signature: ActionSignature;
    @observable.ref parameterAssignment: ParameterAssignment;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                signature: ActionSignature,
                parameterAssignment: ParameterAssignment)
    {
        this.id = id;
        this.signature = signature;
        this.parameterAssignment = parameterAssignment;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor(onFile?: FileReporter)
    {
        return {
            id: this.id,
            actionId: this.signature.id,
            parameterAssignment: this.parameterAssignment.toDescriptor(onFile)
        };
    }

    // ----------------------- Private logic ------------------------
}
