import { Entity } from '../../../Model/Implementation/Entity';
import { CommitContext } from '../../Commit/Context/CommitContext';
import { constructEntityOfType } from '../../Commit/Context/Api/Compatibility/constructEntityOfType';
import getTypes from '../../../../@Component/Domain/Entity/Type/Api/getTypes';
import { setValueByFieldInEntity } from '../../Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { updateRelationship } from '../../Commit/Context/Api/Compatibility/updateRelationship';
import { getChildProductLineRelationshipDefinitionsToCopy } from './getChildProductLineRelationshipDefinitionsToCopy';

export default function copyProductLine(line: Entity,
                                        toEntity: Entity,
                                        commitContext?: CommitContext)
{
    const types = getTypes();
    const lineCopy = constructEntityOfType(types.ProductLine.Type, commitContext);

    const fieldsToCopy = [
        types.Entity.Field.SortIndex,
        ...types.ProductLine.Type.getInheritedFields()
            .filter(
                field =>
                    !field.isComputedField
                    && field !== types.ProductLine.Field.BilledUntil
                    && field !== types.ProductLine.Field.StartDate
                    && field !== types.ProductLine.Field.EndDate
                    && field !== types.ProductLine.Field.IsProcessableInParent)
    ];

    [
        types.Entity.Field.SortIndex,
        ...fieldsToCopy
    ].forEach(
        field =>
            setValueByFieldInEntity(
                lineCopy,
                field,
                line.getObjectValueByField(field),
                commitContext)
    );

    updateRelationship(
        lineCopy,
        true,
        types.Activity.RelationshipDefinition.ProductLines,
        toEntity,
        commitContext
    );

    const childRelationshipDefinitionsToCopy = getChildProductLineRelationshipDefinitionsToCopy();

    childRelationshipDefinitionsToCopy.forEach(
        relationshipDefinition =>
            updateRelationship(
                lineCopy,
                false,
                relationshipDefinition,
                line.getRelatedEntityByDefinition(
                    false,
                    relationshipDefinition)
            )
        );

    return lineCopy;
}
