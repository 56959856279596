import { getSpecifiedPermission } from '../../../Api/Permission/getSpecifiedPermission';
import { getInheritedPermission } from '../../../Api/Permission/getInheritedPermission';
import Role, { Privilege, RoleType } from '../../../Model/Role';
import { EntityMetadataPermission } from '../../../Model/EntityMetadataPermission';
import { isPermissionDisabled } from '../../../Api/Permission/isPermissionDisabled';

export default function isRightPrivilegeConditionable(
    rolesByType: Map<RoleType, Role>,
    role: Role,
    privilege: Privilege,
    metadataPermission: EntityMetadataPermission
)
{
    if (privilege === 'canRead' && metadataPermission.type !== 'EntityType')
    {
        // disable conditional read rights on fields
        return false;
    }
    else if (isPermissionDisabled(
        rolesByType,
        role,
        privilege,
        metadataPermission
    ))
    {
        return false;
    }
    else
    {
        const specifiedPermission = getSpecifiedPermission(
            rolesByType,
            role,
            privilege,
            metadataPermission
        );

        if (specifiedPermission === 'Granted')
        {
            return true;
        }
        else if (specifiedPermission !== 'Denied')
        {
            return getInheritedPermission(
                rolesByType,
                role,
                privilege,
                metadataPermission
            ) === 'Granted';
        }
        else
        {
            return false;
        }
    }
}
