import AutomationDependencyContext from '../../AutomationDependencyContext';
import Trigger from '../Trigger';
import getPredicateFromDescriptor from '../../Api/getPredicateFromDescriptor';
import ParameterDictionary from '../../Parameter/ParameterDictionary';

export default async function updateTriggerWithPredicateFromDescriptor(trigger: Trigger,
                                                                       descriptor: any,
                                                                       dependencyContext: AutomationDependencyContext,
                                                                       addParametersToContext: boolean)
{
    let predicateDependencyContext: AutomationDependencyContext;

    if (addParametersToContext)
    {
        predicateDependencyContext = dependencyContext;
    }
    else
    {
        predicateDependencyContext =
            new AutomationDependencyContext(
                new ParameterDictionary(
                    dependencyContext.parameterDictionary.parameters));
    }

    trigger.getParameters()
        .forEach(
            parameter =>
                predicateDependencyContext
                    .parameterDictionary
                    .addParameter(parameter));

    if (descriptor.predicate)
    {
        trigger.predicate =
            await getPredicateFromDescriptor(
                descriptor.predicate,
                predicateDependencyContext);
    }
}
