import Parameter from './Parameter';
import { EntityFieldPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';

export default class Dependency
{
    // ------------------------- Properties -------------------------

    parameter: Parameter<any>;
    fieldPath?: EntityFieldPath;

    // ------------------------ Constructor -------------------------

    constructor(
        parameter: Parameter<any>,
        fieldPath?: EntityFieldPath
    )
    {
        this.parameter = parameter;
        this.fieldPath = fieldPath;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
