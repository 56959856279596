import React from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../../../Type/Api/useTypes';
import ExpansionPanel from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { CampaignProps } from '../Campaign';
import Input from '../../../../../Input/Input';
import InputGroup from '../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface CampaignOptOutProps extends CampaignProps
{
    commitContext: CommitContext;
}

const CampaignOptOut: React.FC<CampaignOptOutProps> =
    ({
        entity,
        commitContext,
     }) =>
    {
        const types = useTypes();

        return <ExpansionPanel
            id="OptOut"
            summary={
                <Header
                    large
                    inset
                    title={
                        <LocalizedText
                            code="Campaign.OptOut"
                            value="Opt-out"
                        />
                    }
                />
            }
            expansion={
                <CardInset>
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        <ViewGroupItem>
                            <LocalizedText
                                code="Campaign.OptOutHelp1"
                                value="Optioneel kun je hier een opt-out e-mailcategorie selecteren of aanmaken. Dit geeft de gebruiker een extra keuze om alléén op dit onderwerp een opt-out toe te passen."
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <LocalizedText
                                code="Campaign.OptOutHelp2"
                                value="Indien een gebruiker een opt-out toepast op alle campagne e-mails óf op de geselecteerd e-mailing opt-out categorie, dan zal deze gebruiker automatisch in toekomstige campagnes niet meer in de selectie worden meegenomen."
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <InputGroup>
                                <Input
                                    labelPosition="left"
                                    entity={entity}
                                    field={types.Activity.Campaign.RelationshipDefinition.EmailingCategory}
                                    commitContext={commitContext}
                                />
                            </InputGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </CardInset>
            }
        />;
    };

export default observer(CampaignOptOut);
