import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Menu from '../../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import useAsyncResult from '../../../../../../../../../@Util/Async/useAsyncResult';
import { ActionConstructorProps, ActionType } from './ActionConstructor';
import DynamicFunctionRepository from '../../../../../../../../../@Api/Automation/Function/Dynamic/Repository/DynamicFunctionRepository';
import DynamicAction from '../../../../../../../../../@Api/Automation/Function/Action/DynamicAction';
import ParameterDictionary from '../../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import DynamicParameterAssignment from '../../../../../../../../../@Api/Automation/Function/Dynamic/DynamicParameterAssignment';
import { DynamicFunction } from '../../../../../../../../../@Api/Automation/Function/Dynamic/DynamicFunction';
import DynamicFunctionInvocation from '../../../../../../../../../@Api/Automation/Function/Dynamic/DynamicFunctionInvocation';

export interface DynamicActionConstructorProps extends ActionConstructorProps
{
    repository: DynamicFunctionRepository<DynamicFunction<any, any>>;
}

const ActionConstructor: React.FC<DynamicActionConstructorProps> =
    props =>
    {
        const [ dynamicActions, isLoading ] =
            useAsyncResult(
                () =>
                    props.repository.listFunctions('Action'),
                [
                    props.repository
                ]);

        const types =
            useMemo<ActionType[]>(
                () =>
                    isLoading || !dynamicActions
                        ?
                            []
                        :
                            dynamicActions.map(
                                action => ({
                                    id: action.getId(),
                                    name: action.getName(),
                                    construct:
                                        () =>
                                            props.onConstruct(
                                                new DynamicAction(
                                                    new DynamicFunctionInvocation(
                                                        new ParameterDictionary(action.getParameters()),
                                                        new DynamicParameterAssignment(),
                                                        props.repository,
                                                        'Action',
                                                        action)))
                                })),
                [
                    isLoading,
                    dynamicActions,
                    props.onConstruct,
                    props.repository
                ]);

        return <Menu>
            {
                types.map(
                    type =>
                        <Item
                            key={type.id}
                            name={type.name}
                            onClick={type.construct}
                        />)
            }
        </Menu>;
    };

export default observer(ActionConstructor);
