import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import MenuButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import useSwitch from '../../../../../../../@Util/Switch/useSwitch';
import Menu from '../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import TableDimensionSection from '../../../../../../../@Api/Layout/Type/Table/Model/TableDimensionSection';
import StaticTableDimensionSection from '../../../../../../../@Api/Layout/Type/Table/Model/StaticTableDimensionSection';
import uuid from '../../../../../../../@Util/Id/uuid';
import PrimitiveValue from '../../../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import CollectionTableDimensionSection from '../../../../../../../@Api/Layout/Type/Table/Model/CollectionTableDimensionSection';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import EmptyValue from '../../../../../../../@Api/Automation/Value/EmptyValue';

export interface TableLayoutDimensionSectionConstructorProps
{
    onConstruct: (section: TableDimensionSection) => void;
}

const TableLayoutDimensionSectionConstructor: React.FC<TableLayoutDimensionSectionConstructorProps> =
    props =>
    {
        const { onConstruct } = props;
        const [ isOpen, open, close ] = useSwitch(false);
        const addStaticSection =
            useCallback(
                () =>
                    onConstruct(
                        new StaticTableDimensionSection(
                            uuid(),
                            new PrimitiveValue(DataObject.constructFromTypeIdAndValue('Number', 1)),
                            undefined)),
                [
                    onConstruct
                ]);
        const addCollectionSection =
            useCallback(
                () =>
                    onConstruct(
                        new CollectionTableDimensionSection(
                            uuid(),
                            new PrimitiveValue(DataObject.constructFromTypeIdAndValue('Number', 1)),
                            undefined,
                            EmptyValue.instance,
                            undefined)),
                [
                    onConstruct
                ]);

        return <MenuButton
            open={isOpen}
            onOpen={open}
            onClose={close}
            icon="add"
            tooltip={
                <LocalizedText
                    code="Generic.Add"
                    value="Toevoegen"
                />
            }
        >
            <Menu>
                <Item
                    name="Statisch"
                    onClick={addStaticSection}
                />
                <Item
                    name="Collectie"
                    onClick={addCollectionSection}
                />
            </Menu>
        </MenuButton>;
    };

export default observer(TableLayoutDimensionSectionConstructor);
