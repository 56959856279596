import { action, computed, observable } from 'mobx';
import { RootContext } from './Context/RootContext';
import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { OrganizationEnvironmentStore } from './Environment/Organization/OrganizationEnvironmentStore';
import { PublicEnvironmentStore } from './Environment/Public/PublicEnvironmentStore';
import { ModuleManager } from '../../../@Util/DependencyInjection/index';
import { StoreState } from '../../../@Framework/Store/@Model/StoreState';
import { PortalEnvironmentStore } from './Environment/Portal/PortalEnvironmentStore';
import getBrowserLanguageCode from './Environment/Public/Registration/Api/getBrowserLanguageCode';
import { ErrorDialogTranslations } from '../ErrorDialogTranslations';

export class RootStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable moduleManager: ModuleManager;
    @observable context: RootContext;
    @observable publicEnvironmentStore: PublicEnvironmentStore;
    @observable portalEnvironmentStore: PortalEnvironmentStore;
    @observable organizationEnvironmentStore: OrganizationEnvironmentStore;
    @observable initializationError: any;
    @observable defaultLanguage: string;

    // ------------------------ Constructor -------------------------

    constructor(moduleManager: ModuleManager)
    {
        super();

        this.moduleManager = moduleManager;
        this.context = new RootContext(this.moduleManager);
        this.publicEnvironmentStore = new PublicEnvironmentStore(this.moduleManager);
        this.portalEnvironmentStore = new PortalEnvironmentStore(this.moduleManager);
        this.defaultLanguage = getBrowserLanguageCode();
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return this.context.initialize()
            .then(this.postInitialize)
            .catch(this.startError);
    }

    @action.bound
    postInitialize()
    {
        this.context.welcomeStore.onUpdate(
            () =>
                Promise.resolve(this.updateStores()));

        this.updateStores();
    }

    @action.bound
    startError(error: any)
    {
        this.initializationError = error;
    }

    @action.bound
    recoverFromError()
    {
        this.setState(StoreState.Loading);
        this.initializationError = undefined;

        return this.reinitializeStore();
    }

    @action.bound
    updateStores()
    {
        if (this.isAuthenticated)
        {
            this.organizationEnvironmentStore = new OrganizationEnvironmentStore(this.moduleManager);
        }
        else
        {
            this.organizationEnvironmentStore = undefined;
        }
    }

    // -------------------------- Computed --------------------------

    @computed
    get isAuthenticated(): boolean
    {
        return this.context.authenticationManager.isAuthenticated
            && this.context.welcomeStore.welcomePackage.user !== undefined;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get isInPortalEnvironment(): boolean
    {
        return this.isAuthenticated
            && this.context.welcomeStore.welcomePackage.user.portal !== undefined
            && this.currentRouteType === 'Portal';
    }

    @computed
    get isInOrganizationEnvironment(): boolean
    {
        if (this.organizationEnvironmentStore)
        {
            return this.currentRouteType === 'App';
        }
        else
        {
            return false;
        }
    }

    @computed
    get currentRouteType()
    {
        return this.context.router.findRouteByPathAndRouteTypes(
            this.context.router.path,
            [
                'Public',
                this.context.welcomeStore.welcomePackage.user.portal ? 'Portal' : 'App'
            ])?.type || 'Public';
    }

    get errorDialogTranslations(): any
    {
        if (this.defaultLanguage)
        {
            return ErrorDialogTranslations[this.defaultLanguage.toLowerCase()];
        }
        else
        {
            return ErrorDialogTranslations['en'];
        }
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
