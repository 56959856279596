import { observer } from 'mobx-react';
import * as React from 'react';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { EntityViewerStore } from './EntityViewerStore';
import { Card, CardContent, Hidden } from '@material-ui/core';
import { TabBar } from '../../../../Generic/Tab/TabBar';
import { viewInset } from '../../../../../@Resource/Theme/Theme';
import { View } from '../../../../Generic/ViewStack/View';
import Timeline from '../../Timeline/Timeline';
import Header from '../Shared/Header/Header';
import { RelatedEntityViewer } from './Related/Internal/RelatedEntityViewer';
import ViewerEntityContext from '../Context/ViewerEntity/ViewerEntityContext';

export const innerColumnSpacing = 30;

@observer
export class EntityViewer extends BaseComponent<EntityViewerStore>
{
    renderComponent(store: EntityViewerStore)
    {
        if (store.isCompact)
        {
            return this.renderCompactView(store);
        }
        else
        {
            return <ViewerEntityContext.Provider
                value={store.entity}
            >
                {/* Normal view */}
                <Hidden smDown>
                    {this.renderNormalView(store)}
                </Hidden>

                {/* Compact view */}
                <Hidden mdUp>
                    {this.renderCompactView(store)}
                </Hidden>
            </ViewerEntityContext.Provider>;
        }
    }

    renderNormalView(store: EntityViewerStore)
    {
        if (store.showNewTimeline)
        {
            return <>
                <div
                    style={{
                        display: 'flex',
                        height: '100%'
                    }}
                >
                    <div
                        style={{
                            width: '38.2%',
                            marginRight: innerColumnSpacing / 2,
                            paddingTop: viewInset,
                            paddingLeft: viewInset
                        }}
                    >
                        {
                            this.renderDetails(store, false)
                        }
                    </div>
                    <div
                        style={{
                            width: '61.8%',
                            marginLeft: innerColumnSpacing / 2,
                        }}
                    >
                        <Card
                            style={{
                                borderRadius: 0,
                                height: '100%',
                                borderTop: 0,
                                borderBottom: 0
                            }}
                        >
                            <div
                                style={{
                                    overflowY: 'scroll',
                                    height: '100%'
                                }}
                            >
                                <CardContent
                                    style={{
                                        paddingTop: viewInset
                                    }}
                                >
                                    {this.renderBody(store)}
                                </CardContent>
                            </div>
                        </Card>
                    </div>
                </div>
            </>;
        }
        else
        {
            return <>
                <div
                    style={{
                        display: 'flex',
                        paddingLeft: 16,
                        paddingRight: 16
                    }}
                >
                    <div
                        style={{
                            width: '30%',
                            marginRight: innerColumnSpacing / 2
                        }}
                    >
                        {
                            this.renderDetails(store, false)
                        }
                    </div>
                    <div
                        style={{
                            width: '40%',
                            marginLeft: innerColumnSpacing / 2,
                            marginRight: innerColumnSpacing / 2
                        }}
                    >
                        {this.renderBody(store)}
                    </div>
                    <div
                        style={{
                            width: '30%',
                            marginLeft: innerColumnSpacing / 2
                        }}
                    >
                        {this.renderRelated(store)}
                    </div>
                </div>
            </>;
        }
    }

    renderCompactView(store: EntityViewerStore)
    {
        return <>
            <Header
                showTitle
                entity={store.entity}
                onClose={store.onClose ? () => store.onClose(store.entity) : undefined}
                isInSidebar={store.isInSidebar}
            />
            <div
                style={{
                    marginTop: 5,
                    marginBottom: viewInset
                }}
            >
                <TabBar
                    store={store.compactViewTabBarStore}
                />
            </div>
            <div
                style={{
                    width: '100%'
                }}
            >
                {
                    store.tab === 0 &&
                        this.renderDetails(store, true)
                }
                {
                    store.tab === 1 &&
                        this.renderTimeline(store, true)
                }
            </div>
        </>;
    }

    renderBody(store: EntityViewerStore)
    {
        if (store.bodyComponent)
        {
            return this.renderBodyComponent(store);
        }
        else
        {
            return <>
                {
                    store.tabBarStore &&
                        <TabBar
                            store={store.tabBarStore}
                        />
                }
                {
                    store.tab === 1 &&
                        this.renderTimeline(store, false)
                }
            </>;
        }
    }

    renderBodyComponent(store: EntityViewerStore)
    {
        const bodyComponent =
            <View
                viewComponent={store.bodyComponent}
            />;

        if (store.doShowBodyComponentInCard)
        {
            return <Card>
                {bodyComponent}
            </Card>;
        }
        else
        {
            return bodyComponent;
        }
    }

    renderDetails(store: EntityViewerStore,
                  isCompact: boolean)
    {
        return <>
            {
                !isCompact &&
                    <div
                        style={{
                            marginBottom: viewInset
                        }}
                    >
                        <Header
                            showTitle
                            entity={store.entity}
                            onClose={store.onClose ? () => store.onClose(store.entity) : undefined}
                            isInSidebar={store.isInSidebar}
                        />
                    </div>
            }
        </>;
    }

    renderTimeline(store: EntityViewerStore,
                   isCompact: boolean)
    {
        return this.renderTimelineBody(store, isCompact);
    }

    renderTimelineBody(store: EntityViewerStore,
                       isCompact: boolean)
    {
        return <Timeline
            entity={store.entity}
        />;
    }

    renderRelated(store: EntityViewerStore)
    {
        return <RelatedEntityViewer
            store={store.relatedEntityViewerStore}
        />;
    }
}
