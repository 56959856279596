import { reference, type } from '../../../@Util/Serialization/Serialization';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityRelationshipDefinition } from './EntityRelationshipDefinition';
import { EntityType } from './EntityType';
import { observable } from 'mobx';
import { EntityEventFilter } from './EntityEventFilter';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { EntityEvent } from './EntityEvent';
import { EntityRelationshipMutation } from './EntityRelationshipMutation';
import { EntityRelationshipCreationMutation } from './EntityRelationshipCreationMutation';
import { EntityRelationshipUpdateMutation } from './EntityRelationshipUpdateMutation';
import { EntityRelationshipDeletionMutation } from './EntityRelationshipDeletionMutation';
import { Entity } from './Entity';
import localizeText from '../../Localization/localizeText';

@type('RelationshipMutationFilter')
export class RelationshipMutationFilter extends EntityEventFilter
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'EntityRelationshipDefinition') @observable entityRelationshipDefinition: EntityRelationshipDefinition;
    @observable isParent: boolean;
    @reference(undefined, 'EntityType') @observable castEntityType: EntityType;
    @observable isCreation: boolean;
    @observable isMutation: boolean;
    @observable isDeletion: boolean;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(
        entityPath: EntityPath,
        relationshipDefinition?: EntityRelationshipDefinition,
        isParent?: boolean,
        castEntityType?: EntityType,
        isCreation: boolean = true,
        isMutation: boolean = true,
        isDeletion: boolean = true)
    {
        super(entityPath);

        this.entityRelationshipDefinition = relationshipDefinition;
        this.isParent = isParent;
        this.castEntityType = castEntityType;
        this.isCreation = isCreation;
        this.isMutation = isMutation;
        this.isDeletion = isDeletion;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    appliesTo(event: EntityEvent,
              rootEntity?: Entity): boolean
    {
        return event instanceof EntityRelationshipMutation
            && (this.entityRelationshipDefinition === undefined || this.entityRelationshipDefinition === event.entityRelationshipDefinition)
            && (this.isParent === undefined || this.isParent === event.isParentRelationship)
            && (!this.castEntityType || !event.toRelatedEntity || event.toRelatedEntity.entityType.isA(this.castEntityType))
            && ((this.isCreation && event instanceof EntityRelationshipCreationMutation)
                || (this.isMutation && event instanceof EntityRelationshipUpdateMutation)
                || (this.isDeletion && event instanceof EntityRelationshipDeletionMutation))
            && super.appliesTo(event, rootEntity);
    }

    name(entityTypeStore: EntityTypeStore): string
    {
        if (this.castEntityType)
        {
            return this.castEntityType.getName(this.entityRelationshipDefinition.isPlural(this.isParent));
        }
        else
        {
            if (this.entityRelationshipDefinition)
            {
                return `${this.entityRelationshipDefinition.getName(this.isParent, this.entityPath.entityType, entityTypeStore)} ${this.entityRelationshipDefinition.isPlural(this.isParent) 
                    ? '' 
                    : localizeText('Modifications', 'wijzigingen')
                }`;
            }
            else
            {
                return localizeText('Additions', 'Toevoegingen');
            }
        }
    }

    descriptor(): any
    {
        return {
            type: 'RelationshipMutationFilter',
            entityPath: (this.entityPath as any).descriptor,
            relationshipDefinitionId: this.entityRelationshipDefinition ? this.entityRelationshipDefinition.id : undefined,
            isParent: this.isParent,
            castEntityTypeId: this.castEntityType ? this.castEntityType.id : undefined,
            isCreation: this.isCreation,
            isMutation: this.isMutation,
            isDeletion: this.isDeletion
        };
    }

    // ----------------------- Private logic ------------------------
}
