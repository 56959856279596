import React from 'react';
import { observer } from 'mobx-react-lite';
import { Table, TableCell, TableRow } from '@material-ui/core';
import useTotals from './Hooks/useTotals';
import useTypes from '../../../../../../Type/Api/useTypes';
import { useNewCommitContext } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { useCommittableEntity } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/useCommittableEntity';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import { makeStyles } from '@material-ui/styles';
import Input from '../../../../../../Input/Input';
import { Alignment } from '../../../../../../../DataObject/Model/DataObject';
import { TotalsProps } from './DesktopTotals';

const useStyles =
    makeStyles({
        total:
            {
                borderBottom: '0 !important',
                fontWeight: '500 !important' as any
            },
        noBorderBottom:
            {
                borderBottom: '0 !important'
            },
        symbolContainer:
            {
                position: 'relative'
            },
        symbol:
            {
                position: 'absolute',
                left: 'calc(50% - 12px)',
                top: -15,
                color: 'rgb(224, 224, 224)',
                fontSize: 12
            },
        tableWrapping:
            {
                '& td':{
                    whiteSpace: 'nowrap'
                }
            }
    });

export const MobileTotals: React.FC<TotalsProps> = observer(
    props =>
    {
        const types = useTypes();
        const classes = useStyles();
        const commitContext = useNewCommitContext(props.commitContext);
        const entity = useCommittableEntity(props.entity, commitContext);

        const totals = useTotals(
            props.lines,
            props.currency,
            entity,
            commitContext,
            props.discountHidden,
            props.onSelect
        );

        return <TableRow>
            <TableCell />
            <TableCell>
                <Table
                    className={classes.tableWrapping}
                >
                    {
                        totals.showDiscountSection &&
                        <TableRow>
                            <TableCell
                                align="right"
                                className={classes.total}
                                colSpan={2}
                            >
                                <LocalizedText
                                    code="Generic.Subtotal"
                                    value="Subtotaal"
                                />
                            </TableCell>
                            <TableCell
                                className={classes.total}
                            />
                            <TableCell
                                align="right"
                                className={classes.total}
                            >
                                {
                                    totals.subtotalAmount.toCurrencyString(props.currency)
                                }
                            </TableCell>
                        </TableRow>
                    }
                    {
                        totals.showDiscountSection &&
                        <TableRow>
                            <TableCell
                                align="right"
                            >
                                <LocalizedText
                                    code="Generic.Discount"
                                    value="Korting"
                                />
                            </TableCell>
                            <TableCell>
                                <Input
                                    entity={entity}
                                    field={types.Activity.Field.DiscountPercentage}
                                    labelPosition="none"
                                    alignment={Alignment.Right}
                                    disabled={props.disabled}
                                    doAutocommit={props.autoCommit}
                                    commitContext={commitContext}
                                />
                            </TableCell>
                            <TableCell
                                align="right"
                            >
                                {totals.discountAmount.toCurrencyString(props.currency)}
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    }
                    <TableRow>
                        <TableCell
                            align="right"
                            className={classes.total}
                            colSpan={2}
                        >
                            <LocalizedText
                                code="Generic.TotalExcludingVat"
                                value="Totaal excl. BTW"
                            />
                        </TableCell>
                        <TableCell
                            className={classes.total}
                        />
                        <TableCell
                            align="right"
                            className={classes.total}
                        >
                            {totals.totalExcludingVatAmount.toCurrencyString(props.currency)}
                        </TableCell>
                    </TableRow>
                    {
                        Array
                            .from(totals.amountByVatGroup.entries())
                            .map(
                                ([ vatPercentage, vatAmount ], idx) =>
                                    <TableRow
                                        key={vatPercentage || 'undefined'}
                                    >
                                        <TableCell
                                            align="right"
                                            className={idx < totals.amountByVatGroup.size - 1 ? classes.noBorderBottom : undefined}
                                        >
                                            {
                                                idx === 0
                                                    ?
                                                    <LocalizedText
                                                        code="Generic.VAT"
                                                        value="BTW"
                                                    />
                                                    :
                                                    ''
                                            }
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            className={idx < totals.amountByVatGroup.size - 1 ? classes.noBorderBottom : undefined}
                                        >
                                            {vatPercentage}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            className={idx < totals.amountByVatGroup.size - 1 ? classes.noBorderBottom : undefined}
                                        >
                                            {vatAmount.toCurrencyString(props.currency)}
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                            )
                    }
                    <TableRow>
                        <TableCell
                            align="right"
                            className={classes.total}
                            colSpan={2}
                        >
                            <LocalizedText
                                code="Generic.Total"
                                value="Totaal"
                            />
                        </TableCell>
                        <TableCell
                            className={classes.total}
                        />
                        <TableCell
                            align="right"
                            className={classes.total}
                        >
                            {totals.totalIncludingVatAmount.toCurrencyString(props.currency)}
                        </TableCell>
                    </TableRow>
                </Table>
            </TableCell>
        </TableRow>;
    }
);