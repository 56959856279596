import { ListItemStore } from '../Item/ListItemStore';
import { observable } from 'mobx';
import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { ListStore } from '../ListStore';

/**
 * <D> item metadata
 */
export class ListFragmentStore<D> extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable listStore: ListStore<D, unknown, ListItemStore<D>, unknown, unknown, unknown>;
    @observable itemStores = observable.array<ListItemStore<D>>();

    // ------------------------ Constructor -------------------------

    constructor(listStore: ListStore<D, unknown, ListItemStore<D>, unknown, unknown, unknown>,
                itemStores: Array<ListItemStore<D>>)
    {
        super();

        this.listStore = listStore;
        this.itemStores.replace(itemStores);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
