import { ButtonProps, ButtonStore } from '../../../Generic/Button/ButtonStore';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { EntitySelectionBuilder } from '../Selection/Builder/EntitySelectionBuilder';
import { DataObject } from '../../DataObject/Model/DataObject';
import localizeText from '../../../../@Api/Localization/localizeText';

export interface EntityReadCountButtonProps extends ButtonProps
{
    entity: PropType<EntityReadCountButtonStore, EntityReadCountButtonProps, Entity>;
}

const defaultProps: Partial<EntityReadCountButtonProps> =
{
};

export class EntityReadCountButtonStore extends ButtonStore<EntityReadCountButtonProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;

    // ------------------------- Properties -------------------------

    @observable count: DataObject;

    // ------------------------ Constructor -------------------------

    constructor(props: EntityReadCountButtonProps)
    {
        super({
            icon:
                () => 'remove_red_eye',
            // tooltip:
            //     () => `${this.count} keer bekeken`,
            label:
                () =>
                    localizeText(
                        'Generic.NumberOfViews',
                        '${numberOfViews} keer bekeken',
                        {
                            numberOfViews: this.count === undefined ? '...' : this.count.toString()
                        }),
            // badgeContent:
            //     () => this.count.toString(),
            isVisible:
                () => true, // this.count > 0,
            ...props,
        }, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        const path = this.entityTypeStore.bespoke.types.Event.Read.Type.path();

        return new EntitySelectionBuilder(path.entityType)
            .where(
                cb =>
                    cb.relatedToEntity(
                        path.joinTo(
                            this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Events,
                            true),
                        this.entity))
            .count()
            .then(this.setCount);
    }

    // -------------------------- Computed --------------------------

    @computed
    get entity(): Entity
    {
        return getOrCompute(this, this.props.entity);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    setCount(count: DataObject)
    {
        this.count = count;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
