import * as React from 'react';
import ContentLoader from 'react-content-loader';

export class ContentLoaderProductLines extends React.Component
{
    render()
    {
        return <div>
            <ContentLoader
                speed={2}
                width={470}
                height={90}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
            >
                <rect x="10" y="10" rx="3" ry="3" width="100" height="4" />
                <rect x="120" y="10" rx="3" ry="3" width="100" height="4" />
                <rect x="240" y="10" rx="3" ry="3" width="100" height="4" />
                <rect x="360" y="10" rx="3" ry="3" width="100" height="4" />

                <rect x="10" y="30" rx="3" ry="3" width="100" height="4" />
                <rect x="120" y="30" rx="3" ry="3" width="100" height="4" />
                <rect x="240" y="30" rx="3" ry="3" width="100" height="4" />
                <rect x="360" y="30" rx="3" ry="3" width="100" height="4" />

                <rect x="10" y="50" rx="3" ry="3" width="100" height="4" />
                <rect x="120" y="50" rx="3" ry="3" width="100" height="4" />
                <rect x="240" y="50" rx="3" ry="3" width="100" height="4" />
                <rect x="360" y="50" rx="3" ry="3" width="100" height="4" />

                <rect x="240" y="70" rx="3" ry="3" width="100" height="4" />
                <rect x="360" y="70" rx="3" ry="3" width="100" height="4" />

                {/*<rect x="240" y="90" rx="3" ry="3" width="100" height="4" />
                <rect x="360" y="90" rx="3" ry="3" width="100" height="4" />*/}
            </ContentLoader>
        </div>;
    }
}
