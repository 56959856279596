import AutomationDependencyContext from '../AutomationDependencyContext';
import { computed, observable } from 'mobx';
import { EntityType } from '../../Model/Implementation/EntityType';
import FieldMapping from './Field/FieldMapping';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import getFieldMappingFromDescriptor from './Api/getFieldMappingFromDescriptor';
import Dependency from '../Parameter/Dependency';
import Validation from '../Validation/Validation';
import { groupBy } from '../../../@Util/MapUtils/groupBy';
import Key from './Key/Key';
import Parameter from '../Parameter/Parameter';
import EntityValueType from '../Value/Type/EntityValueType';

export default class Mapping
{
    // ------------------------- Properties -------------------------

    static ParameterId = 'MappingEntity';

    @observable.ref entityType: EntityType;
    @observable.ref parameter: Parameter<EntityValueType>;
    @observable.shallow fieldMappings: FieldMapping<any>[];
    @observable.shallow keys: Key[];

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType,
                parameter: Parameter<EntityValueType>,
                fieldMappings: FieldMapping<any>[],
                keys: Key[])
    {
        this.entityType = entityType;
        this.parameter = parameter;
        this.fieldMappings = fieldMappings;
        this.keys = keys;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get fieldMappingsByFieldId()
    {
        return groupBy(
            this.fieldMappings,
            fieldMapping =>
                fieldMapping.field.id());
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return this.fieldMappings
            .map(
                fieldMapping =>
                    fieldMapping.validate())
            .reduce(
                (a, b) =>
                    a.concat(b),
                []);
    }

    isAsync(): boolean
    {
        return this.fieldMappings.some(
            fieldMapping =>
                fieldMapping.isAsync()
        )
        || this.keys.some(
            key =>
                key.isAsync()
        );
    }

    getDependencies(): Dependency[]
    {
        return this.fieldMappings
            .map(
                fieldMapping =>
                    fieldMapping.getDependencies())
            .reduce((a, b) => a.concat(b), []);
    }

    toDescriptor()
    {
        return {
            entityTypeId: this.entityType.id,
            fieldMappings:
                this.fieldMappings.map(
                    fieldMapping =>
                        fieldMapping.toDescriptor()),
            keys:
                this.keys.map(
                    key =>
                        key.toDescriptor())
        };
    }

    static buildParameter(entityType: EntityType)
    {
        return new Parameter<EntityValueType>(
            Mapping.ParameterId,
            new EntityValueType(entityType),
            true,
            entityType.getName());
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const entityType =
            loadModuleDirectly(EntityTypeStore)
                .getTypeById(descriptor.entityTypeId);

        const parameter = Mapping.buildParameter(entityType);

        return new Mapping(
            entityType,
            parameter,
            await Promise.all(
                descriptor.fieldMappings.map(
                    fieldMapping =>
                        getFieldMappingFromDescriptor(
                            fieldMapping,
                            dependencyContext))),
            await Promise.all(
                (descriptor.keys || []).map(
                    key =>
                        Key.fromDescriptor(
                            key,
                            dependencyContext,
                            parameter))));
    }

    // ----------------------- Private logic ------------------------
}
