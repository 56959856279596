import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import CompositeActionInvocation from '../../../../../../../../../../@Api/Automation/Function/Action/CompositeActionInvocation';
import Card from '../../../../../../../../../../@Future/Component/Generic/Card/Card';
import FunctionContext from '../../../../../../../../../../@Api/Automation/Function/FunctionContext';
import CardHeader from '../../../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import ActionEditor from '../../ActionEditor';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import CompositeAction from '../../../../../../../../../../@Api/Automation/Function/Action/CompositeAction';
import { IObservableArray, runInAction } from 'mobx';
import ParameterDictionary from '../../../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ErrorBoundary from '../../../../../../../../../Error/ErrorBoundary';
import ActionConstructor from '../../Constructor/ActionConstructor';
import Action from '../../../../../../../../../../@Api/Automation/Function/Action/Action';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import { loadModuleDirectly } from '../../../../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { FeedbackStore } from '../../../../../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import { copyActionToClipboard } from '../../Clipboard/ActionClipboard';
import localizeText from '../../../../../../../../../../@Api/Localization/localizeText';
import CompositeActionReturnValueEditor from './CompositeActionReturnValueEditor';

export interface InvocationProps
{
    context: FunctionContext;
    action: CompositeAction;
    invocation: CompositeActionInvocation;
    tail: CompositeActionInvocation[];
    idx: number;
    onConstruct: (action: Action<any, any>, afterIdx: number) => void;
    returnValue?: boolean;
}

const CompositeActionInvocationEditor: React.FC<InvocationProps> =
    props =>
    {
        const onDelete =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (props.action.invocations as IObservableArray).remove(props.invocation)),
                [
                    props.action,
                    props.invocation
                ]);

        const [ nextInvocation, ...nextTail ] = props.tail;
        const nextContext =
            useComputed(
                () =>
                {
                    const resultParameter = props.invocation.getResultParameter();

                    return new FunctionContext(
                        new ParameterDictionary([
                            ...Array.from(props.context.parameterDictionary.parameterById.values()),
                            ...resultParameter
                                ?
                                    [
                                        resultParameter
                                    ]
                                :
                                    []
                        ]));
                },
                [
                    props.context,
                    props.invocation
                ]);

        const onConstructAfter =
            useCallback(
                (action: Action<any, any>) =>
                    props.onConstruct(
                        action,
                        props.idx + 1),
                [
                    props.onConstruct,
                    props.idx
                ]);

        const isActionValid =
            useComputed(
                () =>
                    props.invocation.action.isValid(),
                [
                    props.invocation
                ]);
        const copyToClipboard =
            useCallback(
                () =>
                {
                    copyActionToClipboard(props.invocation.action);

                    loadModuleDirectly(FeedbackStore)
                        .enqueueSnackbar(
                            localizeText('Generic.CopiedToClipboard', 'Gekopieerd naar klembord'),
                            {
                                variant: 'success'
                            });
                },
                [
                    props.action
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            {
                props.idx === 0 &&
                    <ViewGroupItem>
                        <ActionConstructor
                            onConstruct={props.onConstruct}
                            mode="Line"
                            context={props.context}
                        />
                    </ViewGroupItem>
            }
            <ViewGroupItem>
                <Card>
                    <CardInset
                        bottom={false}
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                            alignment="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <CardHeader>
                                    {props.idx + 1}. {props.invocation.action.getName()}
                                </CardHeader>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <MenuButton>
                                    <Menu>
                                        {
                                            isActionValid &&
                                                <Item
                                                    name={
                                                        <LocalizedText
                                                            code="Generic.CopyToClipboard"
                                                            value="Kopieren naar klembord"
                                                        />
                                                    }
                                                    onClick={copyToClipboard}
                                                />
                                        }
                                        <Item
                                            name={
                                                <LocalizedText
                                                    code="Generic.Delete"
                                                />
                                            }
                                            onClick={onDelete}
                                        />
                                    </Menu>
                                </MenuButton>
                            </ViewGroupItem>
                        </ViewGroup>
                    </CardInset>
                    <ErrorBoundary>
                        <ActionEditor
                            context={props.context}
                            action={props.invocation.action}
                            returnValue={false}
                        />
                    </ErrorBoundary>
                </Card>
            </ViewGroupItem>
            <ViewGroupItem>
                <ActionConstructor
                    onConstruct={onConstructAfter}
                    mode={nextInvocation ? 'Line' : 'Button'}
                    context={props.context}
                />
            </ViewGroupItem>
            {
                nextInvocation &&
                <ViewGroupItem>
                    <CompositeActionInvocationEditor
                        context={nextContext}
                        action={props.action}
                        invocation={nextInvocation}
                        tail={nextTail}
                        idx={props.idx + 1}
                        onConstruct={props.onConstruct}
                        returnValue={props.returnValue}
                    />
                </ViewGroupItem>
            }
            {
                !nextInvocation && props.returnValue &&
                <ViewGroupItem>
                    <CompositeActionReturnValueEditor
                        context={nextContext}
                        action={props.action}
                    />
                </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(CompositeActionInvocationEditor);
