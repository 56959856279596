import React, { useCallback, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../../Type/Api/useTypes';
import { loadModuleDirectly } from '../../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { ApiClient } from '../../../../../../../../@Service/ApiClient/ApiClient';
import TabBar from '../../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import FileViewer from '../../../../../../../Generic/FileViewer/FileViewer';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import ActivityTemplateEditor from '../../../../../Item/Navigator/More/Template/ActivityTemplateEditor';
import useRelatedEntity from '../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import { useExpansion } from '../../../../../Selection/Api/useExpansion';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import useFileDownloadOptions from "../../../../../Item/Navigator/More/Hooks/useFileDownloadOptions";

export interface WorkOrderDocumentProps
{
    entity: Entity;
}

const WorkOrderDocument: React.FC<WorkOrderDocumentProps> =
    props =>
    {
        const types = useTypes();
        const [ pdfTab, setPdfTab ] = useState(0);

        const workOrderTemplate =
            useRelatedEntity(
                props.entity,
                types.Activity.WorkOrder.RelationshipDefinition.Template,
                false
            );

        const {
            isFinal,
            previewDocumentUrl,
            finalizedDocumentUrl,
            downloadOptions,
        } =
            useFileDownloadOptions(props.entity, `workorder`, workOrderTemplate);

        useExpansion(
            props.entity.entityType,
            () => [
                EntityPath.fromEntity(props.entity)
                    .joinTo(
                        types.Activity.WorkOrder.RelationshipDefinition.Template,
                        false),
            ],
            () => [
                props.entity
            ],
            [
                props.entity,
                types
            ]
        );

        const gotoPreview =
            useCallback(
                () =>
                    setPdfTab(0),
                [
                    setPdfTab
                ]);

        return <Card>
            {
                !isFinal &&
                    <TabBar
                        value={pdfTab}
                    >
                        <Tab
                            value={0}
                            onClick={setPdfTab}
                            fullWidth
                        >
                            <LocalizedText
                                code="Generic.Preview"
                                value="Voorbeeld"
                            />
                        </Tab>
                        <Tab
                            value={1}
                            onClick={setPdfTab}
                            fullWidth
                        >
                            <LocalizedText
                                code="Generic.Modify"
                                value="Aanpassen"
                            />
                        </Tab>
                    </TabBar>
            }
            {
                pdfTab === 0 &&
                (finalizedDocumentUrl
                    ?
                        <FileViewer
                            url={finalizedDocumentUrl}
                            type="pdf"
                        />
                    :
                        <FileViewer
                            url={previewDocumentUrl}
                            extraDownloadOptions={downloadOptions}
                            type="pdf"
                        />)
            }
            {
                pdfTab === 1 &&
                    <CardInset>
                        <ActivityTemplateEditor
                            entity={props.entity}
                            templateEntity={workOrderTemplate}
                            onPreview={gotoPreview}
                            templateType={types.Template.Document.WorkOrder.Type}
                            templateRelationshipDefinition={types.Activity.WorkOrder.RelationshipDefinition.Template}
                            templateContentField={types.Activity.WorkOrder.Field.Document}
                            templateFileField={types.Activity.WorkOrder.Field.TemplateFile}
                            templateManagerRoute="/configuration/work-orders"
                        />
                    </CardInset>
            }
        </Card>;
    };

export default observer(WorkOrderDocument);
