import { useCallback } from 'react';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import useFavoriteDashboardIds from './useFavoriteDashboardIds';

export default function useOnToggleFavoriteDashboard()
{
    const [ favoriteDashboardIds, setFavoriteDashboardIds ] = useFavoriteDashboardIds();

    return useCallback(
        (dashboard: Entity) =>
        {
            if (favoriteDashboardIds.indexOf(dashboard.uuid) >= 0)
            {
                setFavoriteDashboardIds(
                    favoriteDashboardIds
                        .filter(
                            id =>
                                dashboard.uuid !== id));
            }
            else
            {
                setFavoriteDashboardIds([
                    ...favoriteDashboardIds,
                    dashboard.uuid
                ]);
            }
        },
        [
            favoriteDashboardIds,
            setFavoriteDashboardIds
        ]);
}
