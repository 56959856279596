import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import ValueType from '../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import Computation from '../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import Value from '../../../../../../../../../@Api/Automation/Value/Value';
import { ComputationEditorProps } from '../ComputationEditor';
import Link from '../../../../../../../../../@Future/Component/Generic/Link/Link';
import useDialog from '../../../../../../../../../@Service/Navigation/Page/Hooks/useDialog';
import ComputationDialogContent from './ComputationDialogContent';
import PortalContext from '../../../../../../../../../@Api/Portal/Context/PortalContext';

export interface ComputationDialogEditorProps<T extends ValueType<any> = ValueType<any>, C extends Computation<T, Value<any, any>> = Computation<T, Value<any, any>>> extends ComputationEditorProps<T, C>
{

}

const ComputationDialogEditor: React.FC<ComputationDialogEditorProps> =
    props =>
    {
        const { value } = props;
        const portalContext = useContext(PortalContext);

        const [ open ] =
            useDialog(
                close =>
                    <PortalContext.Provider
                        value={portalContext}
                    >
                        <ComputationDialogContent
                            {...props}
                            onClose={close}
                        />
                    </PortalContext.Provider>,
                [
                    props,
                    portalContext
                ]);

        return <Link
            onClick={open}
            highlighted
        >
            {value?.getName() || '...'}
        </Link>;
    };

export default observer(ComputationDialogEditor);
