import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Item from '../../../Item/Item';

export interface PlainListProps
{
    entities: Entity[];
    inset?: boolean;
    editable?: boolean;
}

const PlainList: React.FC<PlainListProps> =
    props =>
    {
        return <ViewGroup
            orientation="vertical"
            spacing={8}
        >
            {
                props.entities.map(
                    entity =>
                        <ViewGroupItem
                            key={entity.uuid}
                        >
                            <Item
                                entity={entity}
                                editable={props.editable}
                                horizontalInset={props.inset}
                                hideFavoriteButton
                            />
                        </ViewGroupItem>)
            }
        </ViewGroup>;
    };

export default observer(PlainList);
