import { observable } from 'mobx';
import ParameterAssignment from '../../Automation/Parameter/ParameterAssignment';
import PortalDataSourceSignature from './PortalDataSourceSignature';
import PortalDataSourceQuery from './PortalDataSourceQuery';
import PortalDataSourceOrdering from './PortalDataSourceOrdering';
import Predicate from '../../Automation/Function/Computation/Predicate/Predicate';

export default class PortalDataSourceListQuery extends PortalDataSourceQuery
{
    // ------------------------- Properties -------------------------

    @observable.shallow orderings: PortalDataSourceOrdering[];
    @observable offset: number;
    @observable limit: number;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                dataSourceSignature: PortalDataSourceSignature,
                parameterAssignment: ParameterAssignment,
                filter: Predicate | undefined,
                orderings: PortalDataSourceOrdering[],
                offset: number,
                limit: number)
    {
        super(id, dataSourceSignature, parameterAssignment, filter);

        this.orderings = orderings;
        this.offset = offset;
        this.limit = limit;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'List',
            orderings: this.orderings.map(ordering => ordering.toDescriptor()),
            offset: this.offset,
            limit: this.limit
        };
    }

    // ----------------------- Private logic ------------------------
}
