import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './FileDropZone.module.scss';
import { classNames } from '../../../../Util/Class/classNames';
import { observer } from 'mobx-react-lite';
import LocalizedText from '../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';
import ViewGroup from '../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import Chip from '@material-ui/core/Chip';

export interface FileDropZoneProps
{
    files?: File[];
    onDrop: (files: File[]) => void;
    noClick?: boolean;
    noDrag?: boolean;
    disabled?: boolean;
    dragOverlay?: JSX.Element;
    noDragOverlay?: boolean;
    dragOverlayLabel?: React.ReactNode;
    dropContainerLabel?: string;
    classes?: DropZoneClasses;
}

export interface DropZoneClasses
{
    dropContainer?: string;
    dragOverlay?: string;
}

const FileDropZone: React.FC<FileDropZoneProps> =
    props =>
    {
        const { onDrop, files } = props;

        const {getRootProps, getInputProps, isDragActive} =
            useDropzone({
                noClick: props.noClick,
                noDrag: props.noDrag,
                noKeyboard: !!props.children,
                onDropAccepted<T extends File>(files: T[], event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement> | DragEvent | Event): void
                {
                    props.onDrop(files);
                }
            });

        const removeFile =
            useCallback(
                (file: File) =>
                    onDrop(
                        files.filter(
                            checkFile =>
                                checkFile !== file)),
                [
                    onDrop,
                    files
                ]);

        const deleteAll =
            useCallback(
                (event: React.MouseEvent) =>
                {
                    event.stopPropagation();
                    onDrop([]);
                },
                [
                    onDrop
                ]);

        let dragOverlay = null;

        if (isDragActive && !props.noDragOverlay)
        {
            dragOverlay =
                props.dragOverlay
                    ?
                        props.dragOverlay
                    :
                        <div
                            className={
                                classNames(
                                    styles.dragOverlay,
                                    props.classes && props.classes.dragOverlay)}
                        >
                            {props.dragOverlayLabel ? props.dragOverlayLabel : <LocalizedText code="Generic.DropHere" value="Drop hier" />}
                        </div>;
        }

        if (props.disabled)
        {
            return <>
                {props.children}
            </>;
        }
        else if (props.children)
        {
            return <div
                {...getRootProps({className: 'dropzone'})}
            >
                <div
                    className={styles.root}
                >
                    <input {...getInputProps()} />
                    {props.children}
                    {dragOverlay}
                </div>
            </div>;
        }
        else
        {
            return <div>
                <div
                    {...getRootProps({className: 'dropzone'})}
                >
                    <div
                        className={styles.root}
                    >
                        <input {...getInputProps()} />
                        <div
                            className={classNames(
                                styles.dropContainer,
                                props.classes && props.classes.dropContainer)}
                        >
                            <div>
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={15}
                                >
                                    <ViewGroupItem>
                                        {
                                            props.dropContainerLabel
                                                ?
                                                    props.dropContainerLabel
                                                :
                                                    <LocalizedText code="Generic.ClickOrDropYourFilesHere" value="Klik of drop je bestanden hier" />
                                        }
                                    </ViewGroupItem>
                                    {
                                        props.files.length > 0 &&
                                            <ViewGroupItem>
                                                <ViewGroup
                                                    orientation="horizontal"
                                                    spacing={15}
                                                    wrap
                                                >
                                                    {
                                                        props.files.map(
                                                            file =>
                                                                <ViewGroupItem
                                                                    key={file.name}
                                                                >
                                                                    <Chip
                                                                        label={file.name}
                                                                        onDelete={() => removeFile(file)}
                                                                    />
                                                                </ViewGroupItem>)
                                                    }
                                                    {
                                                        props.files.length > 1 &&
                                                            <ViewGroupItem>
                                                                <Chip
                                                                    label={
                                                                        <LocalizedText
                                                                            code="Generic.DeleteAll"
                                                                            value="Alles verwijderen"
                                                                        />
                                                                    }
                                                                    onClick={deleteAll}
                                                                />
                                                            </ViewGroupItem>
                                                    }
                                                </ViewGroup>
                                            </ViewGroupItem>
                                    }
                                </ViewGroup>
                            </div>
                            {dragOverlay}
                        </div>
                    </div>
                </div>
            </div>;
        }
    };

FileDropZone.defaultProps = {
    files: []
};

export default observer(FileDropZone);
