import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import CompositeLayout from '../../../../../../@Api/Layout/Type/CompositeLayout';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CompositeLayoutItemEditor from './Item/CompositeLayoutItemEditor';
import Layout from '../../../../../../@Api/Layout/Layout';
import CompositeLayoutItem from '../../../../../../@Api/Layout/Type/CompositeLayoutItem';
import { DroppableProvided } from 'react-beautiful-dnd';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import LayoutConstructor from '../../../Editor/Constructor/LayoutConstructor';

export interface CompositeLayoutEditorItemListProps extends LayoutEditorProps<CompositeLayout>
{
    provided: DroppableProvided;
    onAdd: (layout: Layout, afterItem: CompositeLayoutItem) => void;
    draggableType: string;
}

const CompositeLayoutEditor: React.FC<CompositeLayoutEditorItemListProps> =
    props =>
    {
        const dividerGlue = useDividerGlue();
        const onAddLast =
            useCallback(
                (layout: Layout) =>
                    props.onAdd(layout, props.layout.items[props.layout.items.length - 1]),
                [
                    props.onAdd,
                    props.layout
                ]);

        return <ViewGroup
            orientation={props.layout.orientation === 'Vertical' ? 'vertical' : 'horizontal'}
            spacing={props.layout.spacing}
        >
            <ViewGroupItem
                ratio={props.layout.orientation === 'Vertical' ? undefined : 1}
            >
                <ViewGroup
                    orientation={props.layout.orientation === 'Vertical' ? 'vertical' : 'horizontal'}
                    spacing={props.layout.spacing}
                    glue={props.layout.hasDivider && dividerGlue}
                >
                    {
                        props.layout.items.map(
                            (item, idx) =>
                                <ViewGroupItem
                                    key={item.id}
                                    ratio={item.ratio}
                                >
                                    <CompositeLayoutItemEditor
                                        {...props}
                                        item={item}
                                        idx={idx}
                                        onAdd={props.onAdd}
                                        draggableType={props.draggableType}
                                    />
                                </ViewGroupItem>)
                    }
                    {
                        props.layout.orientation === 'Horizontal' && props.layout.items.length > 0 &&
                            <ViewGroupItem>
                                <LayoutConstructor
                                    parameterDictionary={props.parameterDictionary}
                                    onConstruct={onAddLast}
                                    mode="VerticalButton"
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </ViewGroupItem>
            <ViewGroupItem>
                {props.provided.placeholder}
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(CompositeLayoutEditor);
