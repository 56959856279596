import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import AddressLookupLayout from '../../../../../@Api/Layout/Type/AddressLookupLayout';
import AddressLookupLayoutViewer from './AddressLookupLayoutViewer';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';

export interface AddressLookupLayoutEditorProps extends LayoutEditorProps<AddressLookupLayout>
{

}

const AddressLookupLayoutEditor: React.FC<AddressLookupLayoutEditorProps> =
    props =>
    {
        const parameterAssignment =
            useMemo(
                () =>
                    props.parameterAssignment ?? new ParameterAssignment(),
                [
                    props.parameterAssignment
                ]
            );

        return <AddressLookupLayoutViewer
            {...props}
            parameterAssignment={parameterAssignment}
        />;
    };

export default observer(AddressLookupLayoutEditor);
