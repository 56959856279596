import { observable } from 'mobx';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import TypeMapping from '../../TypeMapping';
import FieldMapping from '../FieldMapping';
import AutomationDependencyContext from '../../../../../../../@Api/Automation/AutomationDependencyContext';

export default class RelationshipFieldMapping extends FieldMapping
{
    // ------------------------- Properties -------------------------

    @observable typeMapping: TypeMapping;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                targetFieldPath: EntityFieldPath,
                typeMapping: TypeMapping)
    {
        super(id, targetFieldPath);

        this.typeMapping = typeMapping;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext,
    )
    {
        return new RelationshipFieldMapping(
            descriptor.id,
            EntityFieldPath.construct(descriptor.targetFieldPath),
            await TypeMapping.fromDescriptor(
                descriptor.typeMapping,
                dependencyContext
            )
        );
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Relationship',
            typeMapping: this.typeMapping.toDescriptor()
        };
    }

    getAllTypeMappings(): TypeMapping[]
    {
        return [
            ...super.getAllTypeMappings(),
            ...this.typeMapping.getAllTypeMappings()
        ];
    }

    getAllFieldMappings(): FieldMapping[]
    {
        return [
            ...super.getAllFieldMappings(),
            ...this.typeMapping.getAllFieldMappings()
        ];
    }

    // ----------------------- Private logic ------------------------
}
