import React from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import useTypes from '../../../Type/Api/useTypes';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import Input from '../../../Input/Input';
import { useNewCommitContext } from '../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import useSwitch from '../../../../../../@Util/Switch/useSwitch';
import RightAlignedButtonGroup from '../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import { primaryColor, textSecondaryColor } from '../../../../../../@Resource/Theme/Theme';
import NoteIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/NoteIconButton/NoteIconButton';
import useToggle from '../../../../../../@Util/Toggle/useToggle';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import { EmployeeAllocationTableEditorRowRepeatEditor } from './EmployeeAllocationTableEditorRowRepeatEditor';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import { default as EntityMenuButton } from '../../../Item/MenuButton/MenuButton';
import useRelatedEntity from '../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import { EmployeeAllocationSequenceEditor } from './EmployeeAllocationSequenceEditor';
import { InitializedEmployeeAllocationTableEditorProps } from '../InitializedEmployeeAllocationTableEditor';
import useTextEntityValue from '../../../../../../@Api/Entity/Hooks/useTextEntityValue';
import Dialog from '../../../../../../@Future/Component/Generic/Dialog/Dialog';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';

export interface ItemPlannerEmployeePlanTableRowProps extends Omit<InitializedEmployeeAllocationTableEditorProps, 'items'>
{
    item: Entity;
    commitContext?: CommitContext;
    autoCommit: boolean;
    autoFocus?: boolean;
    options?: React.ReactNode;
    selected?: boolean;
    onSelect?: (item: Entity, isSelected: boolean) => void;
}

export const EmployeeAllocationTableEditorRow: React.FC<ItemPlannerEmployeePlanTableRowProps> =
    observer(
        props =>
        {
            const {
                specification,
                item,
                commitContext: parentCommitContext,
                autoCommit,
                autoFocus,
                options,
                includeDate,
                selected,
                onSelect,
                disableSequenceControls,
            } = props;
            const types = useTypes();
            const commitContext = useNewCommitContext(parentCommitContext);
            const description = useEntityValue(item, types.EmployeeAllocation.Field.Description);
            const [ hasDescription, toggleDescription ] = useToggle(description !== undefined);
            const [ isRepeatEditorOpen, openRepeatEditor, closeRepeatEditor ] = useSwitch(false);
            const [ isSequenceEditorOpen, openSequenceEditor, closeSequenceEditor ] = useSwitch(false);
            const sequence =
                useRelatedEntity(
                    item,
                    types.EmployeeAllocationSequence.RelationshipDefinition.Allocations,
                    true,
                    commitContext
                );
            const weekDate =
                useTextEntityValue(
                    item,
                    types.EmployeeAllocation.Field.WeekDate,
                    commitContext
                );

            return <>
                <TableRow>
                    <TableCell
                        padding="checkbox"
                    >
                        {
                            onSelect &&
                            <Checkbox
                                checked={selected}
                                onClick={
                                    event =>
                                    {
                                        onSelect(
                                            item,
                                            !selected
                                        );
                                        event.stopPropagation();
                                    }
                                }
                                size="small"
                            />
                        }
                    </TableCell>
                    {
                        includeDate &&
                        <TableCell>
                            {weekDate}
                        </TableCell>
                    }
                    <TableCell>
                        <Input
                            entity={item}
                            field={types.Relationship.RelationshipDefinition.EmployeeAllocations}
                            parent
                            doAutocommit={autoCommit}
                            commitContext={commitContext}
                            labelPosition="none"
                        />
                    </TableCell>
                    <TableCell>
                        <Input
                            entity={item}
                            field={types.Activity.RelationshipDefinition.EmployeeAllocations}
                            parent
                            doAutocommit={autoCommit}
                            commitContext={commitContext}
                            labelPosition="none"
                        />
                    </TableCell>
                    {
                        (specification.employeeAllocationConstructorFields ?? [])
                            .map(
                                constructorField =>
                                    <TableCell
                                        key={constructorField.id}
                                    >
                                        <Input
                                            entity={item}
                                            field={constructorField}
                                            doAutocommit={autoCommit}
                                            commitContext={commitContext}
                                            labelPosition="none"
                                        />
                                    </TableCell>
                            )
                    }
                    <TableCell>
                        <Input
                            entity={item}
                            field={types.Relationship.Person.Contact.Employee.RelationshipDefinition.Allocations}
                            parent
                            doAutocommit={autoCommit}
                            commitContext={commitContext}
                            labelPosition="none"
                        />
                    </TableCell>
                    <TableCell>
                        <Input
                            entity={item}
                            field={types.EmployeeAllocation.Field.DurationInHours}
                            doAutocommit={autoCommit}
                            commitContext={commitContext}
                            labelPosition="none"
                            autoFocus={autoFocus}
                        />
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        <RightAlignedButtonGroup>
                            <NoteIconButton
                                onClick={toggleDescription}
                                color={hasDescription ? primaryColor : textSecondaryColor}
                            />
                            {
                                !disableSequenceControls
                                && !item.isNew()
                                &&
                                <MenuButton
                                    icon="replay"
                                    open={isRepeatEditorOpen}
                                    onOpen={openRepeatEditor}
                                    onClose={closeRepeatEditor}
                                >
                                    <CardInset>
                                        <EmployeeAllocationTableEditorRowRepeatEditor
                                            {...props}
                                            item={item}
                                            onClose={closeRepeatEditor}
                                        />
                                    </CardInset>
                                </MenuButton>
                            }
                            {
                                !disableSequenceControls
                                && sequence
                                &&
                                <IconButton
                                    icon="keyboard_double_arrow_right"
                                    onClick={openSequenceEditor}
                                    tooltip={types.EmployeeAllocationSequence.Type.getName()}
                                />
                            }
                            {options ?? <EntityMenuButton entity={item} />}
                        </RightAlignedButtonGroup>
                    </TableCell>
                </TableRow>
                {
                    hasDescription &&
                    <TableRow>
                        <TableCell
                            colSpan={5}
                        >
                            <Input
                                entity={item}
                                field={types.EmployeeAllocation.Field.Description}
                                doAutocommit={autoCommit}
                                commitContext={commitContext}
                                labelPosition="none"
                            />
                        </TableCell>
                    </TableRow>
                }
                {
                    isSequenceEditorOpen &&
                    <Dialog
                        open
                        onClose={closeSequenceEditor}
                        width="lg"
                    >
                        <EmployeeAllocationSequenceEditor
                            {...props}
                            sequence={sequence}
                            onClose={closeSequenceEditor}
                        />
                    </Dialog>
                }
            </>;
        }
    );
