import * as React from 'react';
import { BaseComponent } from '../../../../../../@Framework/Component/BaseComponent';
import { CompositeComputationOperationStore } from './CompositeComputationOperationStore';
import { observer } from 'mobx-react';
import { grey } from '@material-ui/core/colors';
import { Button, ClickAwayListener, Grow, Icon, IconButton, MenuItem, MenuList, Paper } from '@material-ui/core';
import { ComputationEditor } from '../../../ComputationEditor';
import { MathematicalOperator } from '../../../../DataObject/Model/MathematicalOperator';
import { getMathematicalOperatorSymbol } from '../../../../DataObject/Util/SymbolUtils';
import { PopperReference } from '../../../../../Generic/Popper/PopperReference';
import { PopperContainer } from '../../../../../Generic/Popper/PopperContainer';
import { PopperManager } from '../../../../../Generic/Popper/PopperManager';

@observer
export class CompositeComputationOperationEditor extends BaseComponent<CompositeComputationOperationStore>
{
    renderComponent(store: CompositeComputationOperationStore)
    {
        return <div
            style={{
                position: 'relative',
                margin: 10
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    backgroundColor: grey[500],
                    width: 35,
                    height: 1,
                    left: -35,
                    top: 25
                }}
            />
            <Paper
                style={{
                    display: 'inline-flex',
                    flex: '1 1 auto',
                    background: store.computationStore.type.isTerminal() ? 'white' : 'none',
                    borderRadius: store.computationStore.type.isTerminal() ? 10 : undefined,
                    paddingLeft: store.computationStore.type.isTerminal() ? (store.operator == null ? 15 : 5) : undefined
                }}
                elevation={store.computationStore.type.isTerminal() ? undefined : 0}
            >
                {
                    store.operator != null &&
                        <div style={{ paddingTop: 9 }}>
                            {this.renderOperator(store)}
                        </div>
                }
                <div style={{ flex: '1 1 auto', display: 'inline-flex', alignItems: 'center' }}>
                    <ComputationEditor store={store.computationStore} />
                </div>
                <div>
                    <IconButton onClick={store.delete}>
                        <Icon>remove_circle</Icon>
                    </IconButton>
                </div>
            </Paper>
        </div>;
    }

    renderOperator(store: CompositeComputationOperationStore)
    {
        return <PopperManager>
            <PopperReference>
                <Button
                    onClick={store.openOperatorMenu}
                    size="small"
                >
                    <span style={{ fontSize: '1.2rem' }}>
                        {
                            getMathematicalOperatorSymbol(store.operator)
                        }
                    </span>
                </Button>
            </PopperReference>
            <PopperContainer
                placement="bottom-start"
                eventsEnabled={store.isOperatorMenuOpen}
                style={{zIndex: 10}}
            >
                {
                    store.isOperatorMenuOpen &&
                        <ClickAwayListener onClickAway={store.closeOperatorMenu}>
                            <div>
                                <Grow in={true}>
                                    <Paper>
                                        <MenuList role="menu">
                                            {
                                                Object.keys(MathematicalOperator)
                                                    .map(k => MathematicalOperator[k as any])
                                                    .filter(k => isNaN(k as any))
                                                    .map(operator => (
                                                        <MenuItem
                                                            key={operator}
                                                            onClick={(e: any) =>
                                                            {
                                                                store.setOperator((MathematicalOperator as any)[operator]);
                                                                store.closeOperatorMenu();

                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            {getMathematicalOperatorSymbol((MathematicalOperator as any)[operator])}
                                                        </MenuItem>))
                                            }
                                        </MenuList>
                                    </Paper>
                                </Grow>
                            </div>
                        </ClickAwayListener>
                }
            </PopperContainer>
        </PopperManager>;
    }
}
