import React, { useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import styles from './TrackItemLayout.module.scss';
import { useDrag } from 'react-dnd';
import { TrackItemProps } from '../TrackItem';
import FunctionContext from '../../../../../../../../@Api/Automation/Function/FunctionContext';
import ParameterDictionary from '../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import EntityValue from '../../../../../../../../@Api/Automation/Value/EntityValue';
import { primaryColor } from '../../../../../../../../@Resource/Theme/Theme';

export interface TrackItemLayoutProps extends TrackItemProps
{

}

const TrackItemLayout: React.FC<TrackItemLayoutProps> =
    props =>
    {
        const [ { isResizing }, resizer, preview ] =
            useDrag({
                item: {
                    type: 'TrackItemResize',
                    item: props.item
                },
                collect:
                    monitor => ({
                        isResizing: !!monitor.isDragging()
                    })
            });
        const isHighlighted =
            useMemo(
                () =>
                {
                    if (props.item.item.selection.highlightFilter)
                    {
                        return props.item.item.selection.highlightFilter.synchronouslyEvaluate(
                            new FunctionContext(
                                ParameterDictionary.union(
                                    props.resourcePlanner.parameterDictionary,
                                    new ParameterDictionary([
                                        props.item.item.selection.parameter,
                                    ])
                                ),
                                props.parameterAssignment.getNewAssignmentWithParameter(
                                    props.item.item.selection.parameter,
                                    new EntityValue(props.item.item.entity)
                                )
                            )
                        );
                    }
                    else
                    {
                        return true;
                    }
                },
                [
                    props.resourcePlanner,
                    props.parameterAssignment,
                    props.item,
                ]
            );

        const contentStyle =
            useComputed(
                () => ({
                    backgroundColor: props.item.item.selection.color ?? props.item.item.entity.entityType.bespoke.getEntityColorForCalendar(props.item.item.entity) ?? primaryColor,
                    opacity: isResizing ? 0 : isHighlighted ? 1 : 0.3,
                }),
                [
                    props.item,
                    isResizing,
                    isHighlighted
                ]);

        const title =
            useComputed(
                () =>
                    props.item.item.entity.entityType.bespoke.getEventTitle(props.item.item.entity),
                [
                    props.item
                ]);

        return <>
            <div
                ref={preview}
                style={contentStyle}
            />
            <div
                className={styles.root}
                style={contentStyle}
            >
                {title}
                <div
                    ref={resizer}
                    className={styles.resizeHandle}
                />
            </div>
        </>;
    };

export default observer(TrackItemLayout);
