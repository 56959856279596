import { enumerated, reference } from '../../../@Util/Serialization/Serialization';
import { EntityNode } from './EntityNode';
import { EntityRelationshipDefinition } from './EntityRelationshipDefinition';
import { EntityType } from './EntityType';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { observable } from 'mobx';
import { EntityEvent } from './EntityEvent';
import { EntityRelationshipMutation } from './EntityRelationshipMutation';
import { ConstraintNode } from './ConstraintNode';

export enum JoinType { InnerJoin, LeftJoin }

export class JoinNode extends EntityNode
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'EntityNode') @observable.ref joinEntityNode: EntityNode;
    @observable isJoinEntityNodeParent: boolean;
    @reference(undefined, 'EntityRelationshipDefinition') @observable.ref entityRelationshipDefinition: EntityRelationshipDefinition;
    @observable @enumerated(JoinType, 'JoinType') joinType: JoinType;
    @observable.ref constraintNode?: ConstraintNode;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(
        alias: string,
        entityType: EntityType,
        joinEntityNode: EntityNode,
        isJoinEntityNodeParent: boolean,
        entityRelationshipDefinition: EntityRelationshipDefinition,
        joinType: JoinType,
        constraintNode?: ConstraintNode
    )
    {
        super(entityType, alias);

        this.joinEntityNode = joinEntityNode;
        this.isJoinEntityNodeParent = isJoinEntityNodeParent;
        this.entityRelationshipDefinition = entityRelationshipDefinition;
        this.joinType = joinType;
        this.constraintNode = constraintNode;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    equals(node: JoinNode): boolean
    {
        return super.equals(node)
            && (this.joinEntityNode && this.joinEntityNode.alias) === (node.joinEntityNode && node.joinEntityNode.alias)
            && this.isJoinEntityNodeParent === node.isJoinEntityNodeParent
            && this.entityRelationshipDefinition === node.entityRelationshipDefinition
            && this.joinType === node.joinType
            && (
                (this.constraintNode && node.constraintNode && this.constraintNode.equals(node.constraintNode))
                ||
                (!this.constraintNode && !node.constraintNode)
            );
    }

    isAffectedBy(event: EntityEvent): boolean
    {
        return event instanceof EntityRelationshipMutation
            && event.entityRelationshipDefinition === this.entityRelationshipDefinition
            && event.isParentRelationship === !this.isJoinEntityNodeParent;
    }

    entityPath(): EntityPath
    {
        return this.joinEntityNode
            .entityPath()
            .joinTo(
                this.entityRelationshipDefinition,
                !this.isJoinEntityNodeParent);
    }

    descriptor()
    {
        return {
            type: 'Join',
            alias: this.alias,
            entityTypeId: this.entityType.isStaticType() ? undefined : this.entityType.id,
            entityTypeCode: this.entityType.isStaticType() ? this.entityType.code : undefined,
            joinEntityNodeAlias: this.joinEntityNode.alias,
            isJoinEntityNodeParent: this.isJoinEntityNodeParent,
            entityRelationshipDefinitionId: this.entityRelationshipDefinition ? this.entityRelationshipDefinition.id : undefined,
            entityRelationshipDefinitionCode: this.entityRelationshipDefinition ? this.entityRelationshipDefinition.code : undefined,
            joinType: JoinType[this.joinType],
            isStatic: this.entityType.isStaticType(),
            constraintNode: this.constraintNode?.descriptor()
        };
    }

    // ----------------------- Private logic ------------------------
}
