import * as React from 'react';
import { DataObjectBespokeEditor } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { renderTextField } from '../TextEditor';
import { observer } from 'mobx-react';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { TextEditorStore } from '../TextEditorStore';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import resolveUrlFromUserInput from '../../../../../../@Util/Url/resolveUrlFromUserInput';
import openWindow from '../../../../../../@Util/Window/openWindow';

@observer
export class UrlEditor extends DataObjectBespokeEditor<TextEditorStore>
{
    renderComponent(store: DataObjectEditorStore<TextEditorStore>): any
    {
        return <ViewGroup
            orientation="horizontal"
            spacing={10}
            alignment="center"
        >
            <ViewGroupItem
                ratio={1}
            >
                {renderTextField(store, 'url')}
            </ViewGroupItem>
            {
                store.dataObject.value &&
                    <ViewGroupItem>
                        <IconButton
                            icon="language"
                            tooltip="Website openen"
                            onClick={
                                () =>
                                    openWindow(
                                        resolveUrlFromUserInput(
                                            store.dataObject.value
                                        ),
                                        '_blank'
                                    )
                            }
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    }
}
