import { useComputed } from 'mobx-react-lite';
import { useBlockCheckerNotifications } from './useBlockCheckerNotifications';
import { getContractNotificationFromFinance } from './getContractNotificationFromFinance';
import { useContext } from 'react';
import CurrentUserContext from '../../../../../../Domain/User/CurrentUserContext';

export function useHeaderNotifications()
{
    const currentUserStore = useContext(CurrentUserContext);
    const blockCheckerNotifications = useBlockCheckerNotifications();
    const contractFinanceNotifications = getContractNotificationFromFinance(currentUserStore.contractEntity);

    return useComputed(
        () => [
            ...blockCheckerNotifications,
            ...contractFinanceNotifications
        ].filter(
            notification =>
                notification?.message?.length !== 0
        ),
        [
            blockCheckerNotifications,
            contractFinanceNotifications
        ]
    );

}