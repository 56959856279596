import * as React from 'react';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { NotPredicateEditorStore } from './NotPredicateEditorStore';
import { observer } from 'mobx-react';
import { Button, ClickAwayListener, Grid, Grow, Icon, IconButton, MenuItem, MenuList, Paper, Typography } from '@material-ui/core';
import { PredicateEditor } from '../../PredicateEditor';
import { grey, yellow } from '@material-ui/core/colors';
import { blue } from '../../../../../@Resource/Theme/Blue';
import { PopperReference } from '../../../../Generic/Popper/PopperReference';
import { PopperContainer } from '../../../../Generic/Popper/PopperContainer';
import { PopperManager } from '../../../../Generic/Popper/PopperManager';

@observer
export class NotPredicateEditor extends BaseComponent<NotPredicateEditorStore>
{
    renderComponent(store: NotPredicateEditorStore)
    {
        return <Grid container spacing={0}>
            <Grid item xs={12}>
                <Paper
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: yellow[100],
                        borderRadius: 10,
                        paddingRight: 15
                    }}
                >
                    <Typography variant="body1" style={{ flex: '1 1 auto' }}>
                        {
                            store.localizationStore.translate('NotPredicateEditor.Label.TheConditionBelowIsNotValid') // The condition below is not valid
                        }
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', minHeight: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 50 }}>
                    <div
                        style={{
                            backgroundColor: grey[500],
                            width: 1,
                            height: '100%'
                        }}
                    />
                </div>
                <div style={{ flex: '1 1 auto' }}>
                    {
                        store.predicateStore &&
                            <div
                                style={{
                                    position: 'relative',
                                    margin: 10
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        backgroundColor: grey[500],
                                        width: 35,
                                        height: 1,
                                        left: -35,
                                        top: 25
                                    }}
                                />
                                <Paper
                                    style={{
                                        display: 'inline-flex',
                                        flex: '1 1 auto',
                                        background: store.predicateStore.type.isTerminal() ? 'white' : 'none',
                                        borderRadius: store.predicateStore.type.isTerminal() ? 10 : undefined,
                                        paddingLeft: store.predicateStore.type.isTerminal() ? 5 : undefined
                                    }}
                                    elevation={store.predicateStore.type.isTerminal() ? undefined : 0}
                                >
                                    <div style={{ flex: '1 1 auto', display: 'inline-flex', alignItems: 'center' }}>
                                        <PredicateEditor store={store.predicateStore} />
                                    </div>
                                    <div>
                                        <IconButton onClick={() => (store.deletePredicate(store.predicateStore))}>
                                            <Icon>remove_circle</Icon>
                                        </IconButton>
                                    </div>
                                </Paper>
                            </div>
                    }
                </div>
            </Grid>
            <Grid item xs={12}>
                <Paper
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        backgroundColor: blue[100],
                        borderRadius: 10
                    }}
                >
                    <PopperManager>
                        <PopperReference>
                            <Button
                                onClick={store.openAddMenu}
                            >
                                <Icon style={{ marginRight: 10 }}>
                                    add
                                </Icon>
                                {
                                    store.localizationStore.translate('Generic.Add') // Add
                                }
                            </Button>
                        </PopperReference>
                        <PopperContainer
                            placement="bottom-start"
                            eventsEnabled={store.isAddMenuOpen}
                            style={{zIndex: 10}}
                        >
                            {
                                store.isAddMenuOpen &&
                                    <ClickAwayListener onClickAway={store.closeAddMenu}>
                                        <div>
                                            <Grow in={true}>
                                                <Paper>
                                                    <MenuList role="menu">
                                                        {
                                                            store.predicateTypeStore.types
                                                                .filter(type => type.allow(store.context))
                                                                .map(type => (
                                                                    <MenuItem
                                                                        key={type.id()}
                                                                        onClick={(e: any) =>
                                                                        {
                                                                            store.addPredicate(type);
                                                                            store.closeAddMenu();

                                                                            e.stopPropagation();
                                                                        }}
                                                                    >
                                                                        {type.name()}
                                                                    </MenuItem>))
                                                        }
                                                    </MenuList>
                                                </Paper>
                                            </Grow>
                                        </div>
                                    </ClickAwayListener>
                            }
                        </PopperContainer>
                    </PopperManager>
                </Paper>
            </Grid>
        </Grid>;
    }
}
