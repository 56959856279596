import { ViewType } from '../Model/ViewType';
import { User } from '../../../../../@Api/Model/Implementation/User';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { getValueFromStorage } from '../../../Setting/Api/useLocalSetting';
import getTypes from '../../Type/Api/getTypes';

const DefaultViewTypeSwitchDate = new Date(2024, 0, 23);

export function getDefaultViewTypeForDashboard(
    user: User,
    dashboard: Entity | undefined
): ViewType
{
    if (!dashboard)
    {
        return 'List';
    }
    else
    {
        const types = getTypes();

        const hasEntityType =
            dashboard.hasRelationshipsByDefinition(
                true,
                types.EntityType.RelationshipDefinition.Dashboards
            );

        if (hasEntityType && DefaultViewTypeSwitchDate < new Date(user.creationDate))
        {
            return 'List';
        }
        else
        {
            return 'Dashboard'
        }
    }
}

export function getViewTypeForDashboard(
    user: User,
    dashboard: Entity | undefined
): ViewType
{
    const defaultViewType = getDefaultViewTypeForDashboard(user, dashboard);

    if (dashboard)
    {
        return getValueFromStorage(
            `UserId:${user.id}:DashboardView.${dashboard.uuid}.LastSelectedViewType`,
            defaultViewType
        );
    }
    else
    {
        return defaultViewType;
    }
}
