import React, { useMemo } from 'react';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import { EntityTreeBrowserProps } from '../EntityTreeBrowser';
import { default as ViewModel } from '../../View/Model/View';
import getViewParameters from '../../View/Api/getViewParameters';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../View/Model/ViewParams';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { Comparator } from '../../../DataObject/Model/Comparator';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import EmptyValue from '../../../../../@Api/Automation/Value/EmptyValue';
import Specification from '../../View/Model/Specification';
import { default as ListModel } from '../../View/Model/Specification/List';
import Column from '../../View/Model/Specification/Column';
import uuid from '../../../../../@Util/Id/uuid';
import List from '../../View/List/List';
import Ordering from '../../View/Model/Ordering';
import FilterContext from '../../Dataset/Segment/FilterContext/FilterContext';

export interface EntityTreeBrowserViewProps extends EntityTreeBrowserProps
{
    category?: Entity;
}

const EntityTreeBrowserView: React.FC<EntityTreeBrowserViewProps> =
    props =>
    {
        const { category, recordType, recordsRelationshipDefinition, recordNameField, onOpen, isInEditMode } = props;

        const view =
            useMemo(
                () =>
                {
                    const params = getViewParameters(recordType);
                    const nameColumn =
                        new Column(
                            uuid(),
                            EntityPath.fromEntityType(recordType)
                                .field(recordNameField));

                    return new ViewModel(
                        'List',
                        recordType.getName(true),
                        recordType,
                        params,
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                params.getParameterById(ViewParams.Entity),
                                EntityPath.fromEntityType(recordType)
                                    .joinTo(
                                        recordsRelationshipDefinition,
                                        true)
                                    .field()),
                            category ? Comparator.Equals : Comparator.IsNotDefined,
                            category
                                ?
                                    new EntityValue(category)
                                :
                                    EmptyValue.instance),
                        new Specification(
                            new ListModel(
                                [
                                    nameColumn
                                ],
                                [
                                    new Ordering(
                                        nameColumn,
                                        true)
                                ])));
                },
                [
                    recordType,
                    recordsRelationshipDefinition,
                    recordNameField,
                    category
                ]);

        return <FilterContext.Provider
            value={view.filter}
        >
            <List
                view={view}
                onClick={onOpen}
                readonly={!isInEditMode}
                isInactive={props.isInactive}
            />
        </FilterContext.Provider>;
    };

export default observer(EntityTreeBrowserView);
