import { EntitySelectionBuilder } from '../../../../../Selection/Builder/EntitySelectionBuilder';
import getTypes from '../../../../../Type/Api/getTypes';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import { DisposableValue } from '../../../../../../../../@Util/Disposable/DisposableValue';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';

export default async function getDefaultTemplate(
    templateType: EntityType,
    firstWhenNoTemplateFound: boolean = true
): Promise<DisposableValue<Entity | undefined>>
{
    const types = getTypes();
    const defaultsSelectionBulder =
        EntitySelectionBuilder.builder(
            templateType,
            (builder, rootPath) =>
                builder
                    .join(
                        rootPath
                            .castTo(types.Template.Email.Type)
                            .joinTo(
                                types.Template.Email.RelationshipDefinition.DefaultEmailSender,
                                false))
                    .where(
                        cb =>
                            cb.eq(
                                rootPath.field(types.Template.Field.IsDefault),
                                undefined,
                                true))
                    .limit(1)
        );
    const defaults = await defaultsSelectionBulder.select();

    if (defaults.length > 0)
    {
        return {
            value: defaults[0].entity,
            dispose:
                () =>
                    defaultsSelectionBulder.dispose(),
        };
    }
    else
    {
        if (firstWhenNoTemplateFound)
        {
            const nonDefaultsBuilder =
                new EntitySelectionBuilder(templateType)
                    .limit(1);
            const nonDefaults = await nonDefaultsBuilder.select();
            const dispose =
                () =>
                {
                    defaultsSelectionBulder.dispose();
                    nonDefaultsBuilder.dispose();
                };

            if (nonDefaults.length === 0)
            {
                return {
                    value: undefined,
                    dispose,
                };
            }
            else
            {
                return {
                    value: nonDefaults[0].entity,
                    dispose,
                };
            }
        }
        else
        {
            return {
                value: undefined,
                dispose:
                    () =>
                        defaultsSelectionBulder.dispose()
            };
        }
    }
}
