import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import EntityLink from '../../../Link/EntityLink';
import EntityNameLabel from '../../../../../../@Future/Component/Generic/Label/Variant/EntityName/EntityNameLabel';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import styles from './ViewableName.module.scss';
import HeaderContext from '../../Context/HeaderContext';
import ClickableContext from '../../Context/ClickableContext';
import Input from '../../../../Multiplayer/Model/Input/Input';

export interface ViewableNameProps
{
    entity: Entity;
    nameInput?: Input;
    link?: boolean;
}

const ViewableName: React.FC<ViewableNameProps> =
    props =>
    {
        const isEntireItemClickable = useContext(ClickableContext);
        const isLink = props.link && !isEntireItemClickable;
        const isHeader = useContext(HeaderContext);
        const nameInput = props.nameInput;

        const entityName =
            useComputed(
                () =>
                {
                    if (nameInput)
                    {
                        return nameInput.getValue(props.entity).toString();
                    }
                    else
                    {
                        return props.entity.name;
                    }
                },
                [
                    nameInput,
                    props.entity
                ]);

        const label =
            <EntityNameLabel
                dense
                noWrap
                className={
                    classNames(
                        styles.root,
                        isLink && styles.link,
                        isHeader && styles.header)}
            >
                {entityName}
            </EntityNameLabel>;

        if (isLink)
        {
            return <EntityLink
                entity={props.entity}
            >
                {label}
            </EntityLink>;
        }
        else
        {
            return label;
        }
    };

export default observer(ViewableName);
