import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../Value/PrimitiveValue';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import Value from '../../Value/Value';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import { TextType } from '../../../../@Component/Domain/DataObject/Type/Text/TextType';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import EmptyValue from '../../Value/EmptyValue';
import { isStringNumeric } from '../../../../@Util/String/isStringNumeric';
import localizeText from '../../../Localization/localizeText';

export default class NumberFromTextComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref value: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(value: Computation<any, any>)
    {
        super();

        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('Number'));
    }

    getName(): string
    {
        return localizeText(
            'NumberFromText',
            'Nummer uit tekst...'
        );
    }

    validate(): Validation[]
    {
        return this.value.validate();
    }

    isAsync(): boolean
    {
        return this.value.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const value = await safelyApplyFunction(this.value, context);

        return this.getNumberFromValue(value);
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        const value = safelySynchronousApplyFunction(this.value, context);

        return this.getNumberFromValue(value);
    }

    getNumberFromValue(value: Value<any, any>): Value<any, any>
    {
        if (value instanceof PrimitiveValue
            && value.value.type instanceof TextType)
        {
            const textValue: string = value.value.value;

            if (isStringNumeric(textValue))
            {
                const numberFromString = parseFloat(textValue);

                return new PrimitiveValue(
                    DataObject.constructFromTypeIdAndValue(
                        'Number',
                        numberFromString));
            }
            else
            {
                return EmptyValue.instance;
            }
        }
        else
        {
            return EmptyValue.instance;
        }
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'NumberFromText';
        descriptor.value = this.value.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.value.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new NumberFromTextComputation(
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
