import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import React from 'react';
import { observer } from 'mobx-react';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import equalsEntity from '../../../../../@Api/Entity/Bespoke/equalsEntity';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import { default as SummaryItem } from '../../Summary/Item/Item';
import { makeStyles } from '@material-ui/core';
import { useComputed } from 'mobx-react-lite';
import { recordHoverBackgroundColor } from '../../../../../@Resource/Theme/Theme';
import { getMapViewDivId } from '../Api/getMapViewDivId';

export interface MapViewItemProps
{
    entity: Entity;
    relationship?: Entity;
    selectedEntity: Entity;
    summaryField?: any;
    onClick?: (entity: Entity) => any;
}

const useStyles =
    makeStyles({
        name:
        {
            position: 'relative',
            fontWeight: 500
        },
        description:
        {
            fontsize: '13px',
            color: '$light-text-color',
        },
        caption:
        {
            color: '$light-text-color',
        },
        typeName:
        {
            fontSize: '11px',
            color: '#80868b',
            letterSpacing: '0.8px'
        },
        highlighted:
        {
            backgroundColor: recordHoverBackgroundColor,
        },
        clickable:
        {
            cursor: 'pointer'
        }
    });

export const MapViewItem: React.FC<MapViewItemProps> =
    observer (
        ({
            entity,
            relationship,
            selectedEntity,
            summaryField,
            onClick
        }) =>
        {
            const classes = useStyles();

            const name =
                useComputed(
                    () =>
                        (relationship || entity).getName(),
                [
                        relationship,
                        entity
                    ]
                );

            const description =
                useComputed(
                    () =>
                        name !== entity.getName()
                        ? entity.getName()
                        : undefined,

                    [
                        name,
                        entity
                    ]
                );

            return <div
                id={getMapViewDivId(entity)}
                className={equalsEntity(entity, selectedEntity) ? classes.highlighted : undefined}
            >
                <CardInset>
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                    >
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="vertical"
                                spacing={5}
                                onClick={() => {onClick(entity)}}
                                className={classes.clickable}
                            >
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <div
                                        className={classes.typeName}
                                    >
                                        {entity.entityType.getName()}
                                    </div>
                                </ViewGroupItem>
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <div
                                        className={classes.name}
                                    >
                                        {name}
                                    </div>
                                </ViewGroupItem>
                                {
                                    description &&
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        <div
                                            className={classes.description}
                                        >
                                            {description}
                                        </div>
                                    </ViewGroupItem>
                                }

                            </ViewGroup>
                        </ViewGroupItem>
                        {
                            summaryField && <ViewGroupItem>
                                <CardInset
                                    vertical={false}
                                >
                                    <SummaryItem
                                        field={summaryField}
                                        clickable
                                    />
                                </CardInset>
                            </ViewGroupItem>
                        }
                    </ViewGroup>
                </CardInset>
            </div>;
        }
)