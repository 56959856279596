import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useEntities from './Api/useEntities';
import DividedList from '../../../List/V2/Divided/DividedList';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import useHasDividedList from './Api/useHasDividedList';
import PlainList from '../../../List/V2/Plain/PlainList';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Constructor from './Constructor/Constructor';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import HighlightContext from '../../HighlightContext/HighlightedEntityContext';
import useTypes from '../../../Type/Api/useTypes';
import useIsAttachedEntityType from '../Api/useIsAttachedEntityType';
import FilterContext from '../../../Dataset/Segment/FilterContext/FilterContext';
import EntityTypeContext from '../../../Dataset/Segment/EntityTypeContext/EntityTypeContext';
import OverlineLabel from '../../../../../../@Future/Component/Generic/Label/Variant/Overline/OverlineLabel';
import HorizontalInsetContext from '../../Context/HorizontalInsetContext';
import styles from './List.module.scss';
import uuid from '../../../../../../@Util/Id/uuid';

export interface ListClasses
{

}

export interface ButtonProps
{
    entity: Entity;
    path: EntityPath;
    editable?: boolean;
    overline?: boolean;
    hideConstructor?: boolean;
    classes?: ListClasses;
}

const List: React.FC<ButtonProps> =
    props =>
    {
        const hasDividedList = useHasDividedList(props.path);
        const isAttachedType = useIsAttachedEntityType(props.path.entityType);

        const types = useTypes();

        const [ , entities ] =
            useEntities(
                props.entity,
                props.path);

        const showListOverline =
            useMemo(
                () =>
                {
                    if (props.overline)
                    {
                        return !props.path.entityType.isA(types.Note.Type) &&
                            !props.path.entityType.isA(types.Attachment.Type);
                    }
                    else
                    {
                        return false;
                    }
                },
                [
                    props.overline,
                    props.path,
                    types.Attachment.Type,
                    types.Note.Type
                ]);

        const hasInset = useContext(HorizontalInsetContext);
        const noHighlightedEntities = useMemo(() => [], []);
        const [ constructorRevisionId, setConstructorRevisionId ] = useState(() => uuid());

        const resetConstructor =
            useCallback(
                () =>
                    setConstructorRevisionId(uuid()),
                [
                    setConstructorRevisionId
                ]);

        if (entities)
        {
            let listContent;

            if (hasDividedList)
            {
                listContent =
                    <DividedList
                        entities={entities}
                        inset
                        editable={isAttachedType}
                        hideFavoriteButton
                    />;
            }
            else
            {
                listContent =
                    <PlainList
                        entities={entities}
                        inset={hasInset}
                        editable={isAttachedType}
                    />;
            }

            const content =
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={entities.length > 0 ? 10 : 0}
                        >
                            <ViewGroupItem
                                ratio={1}
                                className={entities.length > 0 ? styles.constructorWrapper : undefined}
                            >
                                {
                                    showListOverline &&
                                        <CardInset
                                            horizontal={hasInset}
                                            vertical={false}
                                        >
                                            <OverlineLabel>
                                                {
                                                    props.path.entityType === types.Event.Read.Type
                                                        ?
                                                            'Gelezen om'
                                                        :
                                                            props.path.entityType.getName(true)
                                                }
                                            </OverlineLabel>
                                        </CardInset>
                                }
                                {
                                    !props.hideConstructor &&
                                        <Constructor
                                            key={constructorRevisionId}
                                            entity={props.entity}
                                            path={props.path}
                                            inset={hasInset}
                                            onCancel={resetConstructor}
                                        />
                                }
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        {listContent}
                    </ViewGroupItem>
                </ViewGroup>;

            return <HighlightContext.Provider
                value={noHighlightedEntities}
            >
                <EntityTypeContext.Provider
                    value={props.path.entityType}
                >
                    <FilterContext.Provider
                        value={undefined}
                    >
                        {content}
                    </FilterContext.Provider>
                </EntityTypeContext.Provider>
            </HighlightContext.Provider>;
        }
        else
        {
            return <Centered
                horizontal
            >
                <Loader />
            </Centered>;
        }
    };

export default observer(List);
