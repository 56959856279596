import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';

export class BespokeExternalCost extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'ExternalCost')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    extendRelationshipSelection(entity: Entity,
                                relationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean,
                                builder: EntitySelectionBuilder,
                                commitContext?: CommitContext)
    {
        super.extendRelationshipSelection(
            entity,
            relationshipDefinition,
            isParent,
            builder,
            commitContext
        );

        const types = this.entityTypeStore.bespoke.types;

        if (relationshipDefinition === types.Milestone.RelationshipDefinition.ExternalCosts
            && isParent)
        {
            builder
                .where(
                    cb =>
                        cb.relatedToEntity(
                            builder.rootPath
                                .joinTo(
                                    types.Activity.Project.RelationshipDefinition.Milestones,
                                    true),
                            entity.getRelatedEntityByDefinition(
                                true,
                                types.Activity.Project.RelationshipDefinition.ExternalCosts,
                                commitContext
                            )
                        )
                );
        }
    }

    // ----------------------- Private logic ------------------------
}
