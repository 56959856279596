import { observable } from 'mobx';
import { AllPrivileges, Permission, Privilege } from './Role';
import Predicate from '../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import getPredicateFromDescriptor from '../../../../@Api/Automation/Api/getPredicateFromDescriptor';
import AutomationDependencyContext from '../../../../@Api/Automation/AutomationDependencyContext';
import ParameterDictionary from '../../../../@Api/Automation/Parameter/ParameterDictionary';

export default class Right
{
    // ------------------------- Properties -------------------------

    @observable.ref parameters: ParameterDictionary;

    @observable canRead: boolean | undefined;
    @observable canCreate: boolean | undefined;
    @observable canUpdate: boolean | undefined;
    @observable canDelete: boolean | undefined;
    @observable canExport: boolean | undefined;

    @observable canReadCondition: Predicate | undefined;
    @observable canCreateCondition: Predicate | undefined;
    @observable canUpdateCondition: Predicate | undefined;
    @observable canDeleteCondition: Predicate | undefined;
    @observable canExportCondition: Predicate | undefined;



    // ------------------------ Constructor -------------------------

    constructor(
        parameters: ParameterDictionary,
        canRead: boolean | undefined = undefined,
        canCreate: boolean | undefined = undefined,
        canUpdate: boolean | undefined = undefined,
        canDelete: boolean | undefined = undefined,
        canExport: boolean | undefined = undefined,
        canReadCondition: Predicate | undefined = undefined,
        canCreateCondition: Predicate | undefined = undefined,
        canUpdateCondition: Predicate | undefined = undefined,
        canDeleteCondition: Predicate | undefined = undefined,
        canExportCondition: Predicate | undefined = undefined
    )
    {
        this.parameters = parameters;
        this.canRead = canRead;
        this.canCreate = canCreate;
        this.canUpdate = canUpdate;
        this.canDelete = canDelete;
        this.canExport = canExport;
        this.canReadCondition = canReadCondition;
        this.canCreateCondition = canCreateCondition;
        this.canUpdateCondition = canUpdateCondition;
        this.canDeleteCondition = canDeleteCondition;
        this.canExportCondition = canExportCondition;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getPermission(privilege: Privilege): Permission
    {
        const conditionSerializationKey = Right.getConditionSerializationKey(privilege);

        if (this[conditionSerializationKey])
        {
            return 'ConditionallyGranted';
        }
        else if (this[privilege] === true)
        {
            return 'Granted';
        }
        else if (this[privilege] === false)
        {
            return 'Denied';
        }
        else
        {
            return 'Inherited';
        }
    }

    getPredicate(privilege: Privilege): Predicate | undefined
    {
        const conditionSerializationKey = Right.getConditionSerializationKey(privilege);
        return this[conditionSerializationKey];
    }

    setPermission(privilege: Privilege,
                  permission: Permission,
                  condition?: Predicate)
    {
        const conditionSerializationKey = Right.getConditionSerializationKey(privilege);
        this[conditionSerializationKey] = undefined;

        switch (permission)
        {
            case 'Granted':
                this[privilege] = true;
                break;
            case 'ConditionallyGranted':
                if (condition)
                {
                    this[privilege] = true;
                    this[conditionSerializationKey] = condition;
                }
                else
                {
                    this[privilege] = undefined;
                }
                break;
            case 'Denied':
                this[privilege] = false;
                break;
            case 'Inherited':
                this[privilege] = undefined;
                break;
            default:
                break;
        }
    }

    toDescriptor()
    {
        const descriptor: any = {};

        AllPrivileges.forEach(
            privilege =>
            {
                descriptor[privilege] = this[privilege];
                if (this.getPermission(privilege) === 'ConditionallyGranted')
                {
                    const conditionSerializationKey = Right.getConditionSerializationKey(privilege);
                    descriptor[conditionSerializationKey] = this[conditionSerializationKey].toDescriptor();
                }
            });

        return descriptor;
    }

    static async fromDescriptor(
        descriptor: any,
        automationDependencyContext: AutomationDependencyContext
    )
    {
        const right = new Right(automationDependencyContext.parameterDictionary);

        for (const privilege of AllPrivileges)
        {
            const conditionSerializationKey = Right.getConditionSerializationKey(privilege);

            if (descriptor[conditionSerializationKey])
            {
                right[conditionSerializationKey] = await getPredicateFromDescriptor(
                    descriptor[conditionSerializationKey],
                    automationDependencyContext
                );
            }

            right[privilege] = descriptor[privilege];
        }

        return right;
    }

    static getConditionSerializationKey(privilege: Privilege)
    {
        return privilege + 'Condition';
    }

    async copy(automationDependencyContext: AutomationDependencyContext)
    {
       return Right.fromDescriptor(
            this.toDescriptor(),
            automationDependencyContext
       );
    }

    // ----------------------- Private logic ------------------------
}
