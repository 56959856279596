import { useMemo } from 'react';
import useTypes from '../../Type/Api/useTypes';
import Segment from '../../Dataset/Model/Segment';

export default function useIsWorkflowPipeline(segment: Segment)
{
    const types = useTypes();

    return useMemo(
        () =>
            segment.groupFieldPath
            && segment.groupFieldPath.relationshipDefinition
            && !segment.groupFieldPath.isParentRelationship
            && segment.groupFieldPath.relationshipDefinition.childEntityType.isA(types.Datastore.Phase.Type),
        [
            segment,
            types
        ]);
}
