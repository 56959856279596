import Validation from '../../../Automation/Validation/Validation';
import Dependency from '../../../Automation/Parameter/Dependency';
import Value from '../../../Automation/Value/Value';
import LayoutAction from '../LayoutAction';
import EmptyValue from '../../../Automation/Value/EmptyValue';
import LayoutDependencyContext from '../../LayoutDependencyContext';
import { observable } from 'mobx';
import EmptyValueType from '../../../Automation/Value/Type/EmptyValueType';
import ValueType from '../../../Automation/Value/Type/ValueType';
import CompositeActionInvocation from './CompositeActionInvocation';
import LayoutContext from '../../LayoutContext';

export default class CompositeAction extends LayoutAction
{
    // ------------------------- Properties -------------------------

    @observable.shallow invocations: CompositeActionInvocation[];

    // ------------------------ Constructor -------------------------


    constructor(invocations: CompositeActionInvocation[])
    {
        super();

        this.invocations = invocations;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async apply(context: LayoutContext): Promise<Value<any, any>>
    {
        let invocationContext = context;

        for (const invocation of this.invocations)
        {
            const result = await invocation.action.apply(invocationContext);

            invocationContext =
                new LayoutContext(
                    invocationContext
                        .parameterDictionary
                        .getNewDictionaryWithParameter(invocation.resultParameter),
                    invocationContext
                        .parameterAssignment
                        .getNewAssignmentWithParameter(
                            invocation.resultParameter,
                            result
                        ),
                    context.commitContext,
                    context.isInSafeMode,
                    context.formLayoutContexts
                );
        }

        return EmptyValue.instance;
    }

    getReturnType(): ValueType<any>
    {
        return EmptyValueType.instance;
    }

    getName(): string
    {
        return undefined;
    }

    getDependencies(): Dependency[]
    {
        return [];
    }

    validate(): Validation[]
    {
        return [];
    }

    toDescriptor()
    {
        return {
            type: 'Composite',
            invocations:
                this.invocations
                    .map(
                        invocation =>
                            invocation.toDescriptor())
        };
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: LayoutDependencyContext
    )
    {
        let invocationDependencyContext = dependencyContext;
        const invocations: CompositeActionInvocation[] = [];

        for (const invocationDescriptor of descriptor.invocations)
        {
            const invocation =
                await CompositeActionInvocation.fromDescriptor(
                    invocationDescriptor,
                    invocationDependencyContext
                );
            invocations.push(invocation);
            invocationDependencyContext =
                invocationDependencyContext.withParameterDictionary(
                    invocationDependencyContext.parameterDictionary
                        .getNewDictionaryWithParameter(invocation.resultParameter)
                );
        }

        return new CompositeAction(invocations);
    }

    // ----------------------- Private logic ------------------------
}
