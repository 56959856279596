import * as React from 'react';
import { DataObjectSpecificationEditorForm } from '../../../Editor/Specification/DataObjectSpecificationEditorForm';

export class EmailSpecification extends DataObjectSpecificationEditorForm
{
    render()
    {
        return <div />;
    }

    focus(): void
    {

    }

    blur(): void
    {

    }
}
