import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import Dependency from '../../Parameter/Dependency';
import EntityValueType from '../../Value/Type/EntityValueType';
import { EntityFieldPath } from '../../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import CollectionType from '../../Value/Type/CollectionType';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import Parameter from '../../Parameter/Parameter';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import { EntityFieldComputation } from '../../../../@Component/Domain/Computation/Type/Entity/Field/EntityFieldComputation';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import EntityValue from '../../Value/EntityValue';
import PrimitiveValue from '../../Value/PrimitiveValue';
import CollectionValue from '../../Value/CollectionValue';
import EmptyValue from '../../Value/EmptyValue';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import localizeText from '../../../Localization/localizeText';

export default class ValueFromEntityComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref entity: Computation<any, any>;
    @observable.ref fieldPath: EntityFieldPath;

    // ------------------------ Constructor -------------------------

    constructor(entity: Computation<any, any>,
                fieldPath: EntityFieldPath)
    {
        super();

        this.entity = entity;
        this.fieldPath = fieldPath;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        if (this.isCollection())
        {
            return new CollectionType(
                this.getSingularType());
        }
        else
        {
            return this.getSingularType();
        }
    }

    isAsync(): boolean
    {
        return this.entity.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const values = await this.getValues(context);

        return this.getValueFromValues(values);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        const values = this.synchronouslyGetValues(context);

        return this.getValueFromValues(values);
    }

    private getValueFromValues(values: Value<any, any>[])
    {
        if (this.isCollection())
        {
            return new CollectionValue(
                values,
                this.getType());
        }
        else
        {
            return values.find(() => true) || EmptyValue.instance;
        }
    }

    private async getValues(context: FunctionContext): Promise<Value<any, any>[]>
    {
        const value = await safelyApplyFunction(this.entity, context);

        return this.getValuesFromValue(
            context,
            value
        );
    }

    private synchronouslyGetValues(context: FunctionContext): Value<any, any>[]
    {
        const value = safelySynchronousApplyFunction(this.entity, context);

        return this.getValuesFromValue(
            context,
            value
        );
    }

    private getValuesFromValue(
        context: FunctionContext,
        value: Value<any, any>
    ): Value<any, any>[]
    {
        if (value instanceof EntityValue)
        {
            const entity = value.value;
            const relatedEntities =
                this.fieldPath.path.traverseEntity(
                    entity,
                    context.commitContext
                );

            if (this.fieldPath.isField)
            {
                return relatedEntities
                    .map(
                        relatedEntity =>
                            relatedEntity.getDataObjectValueByField(
                                this.fieldPath.field,
                                undefined,
                                context.commitContext
                            ))
                    .filter(
                        primitive =>
                            primitive != null && !primitive.isSemanticEmpty)
                    .map(
                        primitive =>
                            new PrimitiveValue(primitive));
            }
            else
            {
                return relatedEntities
                    .map(
                        relatedEntity =>
                            new EntityValue(relatedEntity));
            }
        }
        else
        {
            return [];
        }
    }

    getName(): string
    {
        return `${this.entity.getName()}: ${
            this.fieldPath?.getPathName() || localizeText('Computation.ChooseField', 'kies een veld')}`;
    }

    validate(): Validation[]
    {
        if (!(this.entity.getType() instanceof EntityValueType))
        {
            return [
                new Validation(
                    'Error',
                    localizeText('Computation.NoValidEntitySelected', 'Er is geen geldige entiteit geselecteerd.'))
            ];
        }
        else if (this.fieldPath)
        {
            return [];
        }
        else
        {
            return [
                new Validation(
                    'Error',
                    localizeText('Computation.NoFieldSelected', 'Je hebt geen veld geselecteerd.'))
            ];
        }
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'ValueFromEntity';
        descriptor.entity = this.entity.toDescriptor();
        descriptor.fieldPath = this.fieldPath?.descriptor;
    }

    getDependencies(): Dependency[]
    {
        if (this.entity instanceof Parameter
            && this.entity.getType() instanceof EntityValueType)
        {
            return [
                new Dependency(
                    this.entity,
                    this.fieldPath)
            ];
        }
        else
        {
            return [];
        }
    }

    private isCollection()
    {
        return this.fieldPath.path.isPlural;
    }

    private getSingularType(): ValueType<any>
    {
        if (this.fieldPath.isField)
        {
            return new PrimitiveValueType(this.fieldPath.field.dataObjectSpecification.type);
        }
        else
        {
            return new EntityValueType(this.fieldPath.path.entityType);
        }
    }

    toOldComputation(): any
    {
        if (this.entity instanceof Parameter)
        {
            return new EntityFieldComputation(
                this.fieldPath,
                this.entity.id === 'Entity'
                    ?
                        undefined
                    :
                        this.entity.id);
        }
        else
        {
            return undefined;
        }
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new ValueFromEntityComputation(
            await getComputationFromDescriptor(
                descriptor.entity,
                dependencyContext),
            EntityFieldPath.construct(descriptor.fieldPath));
    }

    // ----------------------- Private logic ------------------------
}
