import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import { runInAction } from 'mobx';
import PortalImageLayout from '../../../../../@Api/Layout/Type/PortalImageLayout';
import DimensionEditor from '../../Style/DimensionEditor/DimensionEditor';
import Dimension from '../../../../../@Api/Layout/Style/Dimension';

export interface PortalImageLayoutControlsProps extends LayoutEditorProps<PortalImageLayout>
{

}

const PortalImageLayoutControls: React.FC<PortalImageLayoutControlsProps> =
    props =>
    {
        const setWidth =
            useCallback(
                (width: Dimension) =>
                    runInAction(
                        () =>
                            props.layout.width = width),
                [
                    props.layout
                ]);

        const setHeight =
            useCallback(
                (height: Dimension) =>
                    runInAction(
                        () =>
                            props.layout.height = height),
                [
                    props.layout
                ]);

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.Width"
                            value="Breedte"
                        />
                    }
                >
                    <DimensionEditor
                        value={props.layout.width}
                        onChange={setWidth}
                    />
                </Input>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.Height"
                            value="Hoogte"
                        />
                    }
                >
                    <DimensionEditor
                        value={props.layout.height}
                        onChange={setHeight}
                    />
                </Input>
            </InputGroup>
        </CardInset>;
    };

export default observer(PortalImageLayoutControls);
