import React, { DragEvent as ReactDragEvent, DragEventHandler as ReactDragEventHandler } from 'react';
import styles from './FileDrop.module.scss';
import { classNames } from '../../../Util/Class/classNames';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import AttachmentDialog from '../../../../@Component/Domain/Entity/Bespoke/Attachment/Dialog/AttachmentDialog';
import OnFileDropDisableContext from './OnFileDropDisableContext';
import LocalizedText from '../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface IFileDropProps
{
  entity: Entity;
  disabled?: boolean;
}

export interface IFileDropState
{
  draggingOverFrame: boolean;
  draggingOverTarget: boolean;
  files: File[];
  disabledByProvider?: boolean;
}

class FileDrop extends React.PureComponent<IFileDropProps, IFileDropState>
{
    frameDragCounter: number;

    constructor(props:IFileDropProps)
    {
        super(props);
        this.frameDragCounter = 0;
        this.state = { draggingOverFrame: false, draggingOverTarget: false, files: undefined, disabledByProvider: false };
    }

    static eventHasFiles =
        (event:DragEvent | ReactDragEvent<HTMLElement>) =>
        {
            // In most browsers this is an array, but in IE11 it's an Object :(
            let hasFiles = false;
            if (event.dataTransfer)
            {
                const types = event.dataTransfer.types;
                for (const keyOrIndex in types)
                {
                    if (types[keyOrIndex] === 'Files')
                    {
                        hasFiles = true;
                        break;
                    }
                }
            }
            return hasFiles;
        };

    resetDragging =
        () =>
        {
            this.frameDragCounter = 0;
            this.setState({ draggingOverFrame: false, draggingOverTarget: false });
        };

    handleWindowDragOverOrDrop =
        (event:DragEvent) => event.preventDefault();

    handleFrameDrag =
        (event:DragEvent) =>
        {
            // Only allow dragging of files
            if (!FileDrop.eventHasFiles(event))
            {
                return;
            }

            // We are listening for events on the 'frame', so every time the user drags over any element in the frame's tree,
            // the event bubbles up to the frame. By keeping count of how many "dragenters" we get, we can tell if they are still
            // "draggingOverFrame" (b/c you get one "dragenter" initially, and one "dragenter"/one "dragleave" for every bubble)
            // This is far better than a "dragover" handler, which would be calling `setState` continuously.
            this.frameDragCounter += (event.type === 'dragenter' ? 1 : -1);

            if (this.frameDragCounter === 1)
            {
                this.setState({ draggingOverFrame: true });
                return;
            }

            if (this.frameDragCounter === 0)
            {
                this.setState({ draggingOverFrame: false });
                return;
            }
        };

    handleFrameDrop =
        () =>
        {
            if (!this.state.draggingOverTarget)
            {
                this.resetDragging();
            }
        };

    handleDragOver: ReactDragEventHandler<HTMLDivElement> =
        (event) =>
        {
            if (FileDrop.eventHasFiles(event))
            {
                this.setState({ draggingOverTarget: true });
            }
        };

    handleDragLeave: ReactDragEventHandler<HTMLDivElement> =
        () =>
        {
            this.setState({ draggingOverTarget: false });
        };

    handleDrop: ReactDragEventHandler<HTMLDivElement> =
        (event) =>
        {
            if (FileDrop.eventHasFiles(event))
            {
                let files: File[] = [];

                const fileList = (event.dataTransfer) ? event.dataTransfer.files : null;

                for (let index =0; index < fileList.length; index++)
                {
                    files.push(fileList[index]);
                }

                this.setState({ files: files });
            }
            this.resetDragging();
        };

    stopFrameListeners =
        () =>
        {
            if (window.document)
            {
                window.document.removeEventListener('dragenter', this.handleFrameDrag);
                window.document.removeEventListener('dragleave', this.handleFrameDrag);
                window.document.removeEventListener('drop', this.handleFrameDrop);
            }
        };

    startFrameListeners =
        () =>
        {
            if (window.document)
            {
                window.document.addEventListener('dragenter', this.handleFrameDrag);
                window.document.addEventListener('dragleave', this.handleFrameDrag);
                window.document.addEventListener('drop', this.handleFrameDrop);
            }
        };

    handleReset =
        () =>
        {
            this.setState({ files: undefined });
        };

    componentDidMount()
    {
        this.startFrameListeners();
        this.resetDragging();
        window.addEventListener('dragover', this.handleWindowDragOverOrDrop);
        window.addEventListener('drop', this.handleWindowDragOverOrDrop);
    }

    componentWillUnmount()
    {
        this.stopFrameListeners();
        window.removeEventListener('dragover', this.handleWindowDragOverOrDrop);
        window.removeEventListener('drop', this.handleWindowDragOverOrDrop);
    }

    disableByProvider =
        (disable: boolean) =>
        {
            this.setState({ disabledByProvider: disable });
        };

    render()
    {
        const { disabled, children, entity } = this.props;
        const { draggingOverFrame, files, disabledByProvider } = this.state;

        const dropFrameEnabled = !disabled && !disabledByProvider;

        return <OnFileDropDisableContext.Provider
            value={this.disableByProvider}
        >
                <div
                    className={dropFrameEnabled ? styles.fileDrop : undefined}
                    onDragOver={dropFrameEnabled ? this.handleDragOver : undefined}
                    onDragLeave={dropFrameEnabled ? this.handleDragLeave : undefined}
                    onDrop={dropFrameEnabled ? this.handleDrop : undefined}
                >
                    <div
                        className={
                            classNames(
                                styles.backdrop,
                                dropFrameEnabled && draggingOverFrame && styles.hovering
                            )
                        }
                    />

                    { dropFrameEnabled && draggingOverFrame && <div
                        className={
                            classNames(
                                styles.dropArea,
                                styles.dropAreaDragging
                            )
                        } >
                            <LocalizedText
                                code="Generic.DropYourFilesHere"
                                value="Drop hier uw bestanden"
                            />
                        </div>
                    }

                    {children}

                    {dropFrameEnabled && files && <AttachmentDialog
                        files={files}
                        entity={entity}
                        onReset={this.handleReset}
                    />}
                </div>
        </OnFileDropDisableContext.Provider>;
    }
}

export default FileDrop;
