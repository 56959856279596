import { observer } from 'mobx-react';
import React from 'react';
import { Entity } from '../../../../../../../../../../../../../../@Api/Model/Implementation/Entity';
import useIsMobile from '../../../../../../../../../../../../../../@Util/Responsiveness/useIsMobile';
import ViewGroupItem from '../../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import { ApsisCampaignChartDelivered } from '../../../../../../../../ApsisCampaign/Charts/ApsisCampaignChartDelivered';
import { ApsisCampaignChartSelection } from '../../../../../../../../ApsisCampaign/Charts/ApsisCampaignChartSelection';
import { ApsisCampaignChartClicked } from '../../../../../../../../ApsisCampaign/Charts/ApsisCampaignChartClicked';
import { ApsisCampaignChartViewed } from '../../../../../../../../ApsisCampaign/Charts/ApsisCampaignChartViewed';
import { ApsisCampaignChartUndeliverable } from '../../../../../../../../ApsisCampaign/Charts/ApsisCampaignChartUndeliverable';

export interface ApsisCampaignResultChartsProps
{
    apsisCampaign: Entity
}

export const ApsisCampaignResultCharts: React.FC<ApsisCampaignResultChartsProps> =
    observer(
        ({
             apsisCampaign
        }) =>
        {
            const isMobile = useIsMobile();

            return <ViewGroupItem>
                <ViewGroup
                    orientation={isMobile ? 'vertical' : 'horizontal'}
                    spacing={isMobile ? 30 : 30}
                    justification="center"
                    alignment="center"
                >
                    <ViewGroup
                        orientation="horizontal"
                        alignment="center"
                        spacing={30}
                    >
                        <ViewGroupItem
                            alignment="center"
                        >
                            <ApsisCampaignChartSelection
                                size={130}
                                apsisCampaign={apsisCampaign}
                            />
                        </ViewGroupItem>
                        <ViewGroup
                            orientation="vertical"
                            spacing={30}
                        >
                            <ViewGroupItem
                                alignment="center"
                            >
                                <ApsisCampaignChartDelivered
                                    size={130}
                                    apsisCampaign={apsisCampaign}
                                />
                            </ViewGroupItem>
                        </ViewGroup>

                    </ViewGroup>

                    <ViewGroup
                        orientation="horizontal"
                        spacing={30}
                        alignment="center"
                    >
                        <ViewGroupItem
                            alignment="center"
                        >
                            <ApsisCampaignChartViewed
                                size={130}
                                apsisCampaign={apsisCampaign}
                            />
                        </ViewGroupItem>
                        <ViewGroup
                            orientation="vertical"
                            spacing={30}
                        >
                            <ViewGroupItem
                                alignment="center"
                            >
                                <ApsisCampaignChartClicked
                                    size={130}
                                    apsisCampaign={apsisCampaign}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroup>
                    <ViewGroupItem
                        alignment="center"
                    >
                        <ApsisCampaignChartUndeliverable
                            size={130}
                            apsisCampaign={apsisCampaign}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
        }
    );
