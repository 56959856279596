import StateSynchronized from './State/SynchronizedState';
import { Delta, State } from './State/State';

export type SendCallback = (version: number, delta: Delta) => void;
export type ApplyCallback = (delta: Delta) => void;

export default class CollaborationClient
{
    version: number;
    state: State;
    onSend: SendCallback;
    onApply: ApplyCallback;

    constructor(version: number = 0,
                onSend: SendCallback,
                onApply: ApplyCallback)
    {
        this.version = version;
        this.state = StateSynchronized;
        this.onSend = onSend;
        this.onApply = onApply;
    }

    applyFromClient(delta: Delta)
    {
        this.state = this.state.applyFromClient(this, delta);
    }

    applyFromServer(delta: Delta)
    {
        this.version++;
        this.state = this.state.applyFromServer(this, delta);
    }

    serverAck()
    {
        this.version++;
        this.state = this.state.serverAck(this);
    }

    transformSelection(selection: any)
    {
        this.state.transformSelection(selection);
    }

    reconnect()
    {
        this.state.resend(this);
    }

    sendDelta(version: number,
              delta: Delta)
    {
        this.onSend(version, delta);
    }

    applyDelta(delta: Delta)
    {
        this.onApply(delta);
    }
}









