import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { dividerColor } from '../../../../../../../../../@Resource/Theme/Theme';

const useStyles = makeStyles({
    root: {
        border: `1px solid ${dividerColor}`,
        borderRadius: 15,
        minWidth: 100,
        paddingLeft: 15,
        minHeight: 32,
        display: 'flex',
        alignItems: 'center'
    },
    inner: {
        width: '100%',
        flex: '1 1 auto'
    }
});

export interface ComputationBoxProps
{

}

const ComputationBox: React.FC<ComputationBoxProps> =
    ({
        children
     }) =>
    {
        const classes = useStyles();

        return <div
            className={classes.root}
        >
            <div
                className={classes.inner}
            >
                {children}
            </div>
        </div>;
    };

export default ComputationBox;
