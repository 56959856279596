import React, { useCallback, useContext } from 'react';
import styles from './TeamSwitcher.module.scss';
import CurrentUserContext from '../../../../../../Domain/User/CurrentUserContext';
import { observer } from 'mobx-react-lite';
import Icon from '../../../../../../../@Future/Component/Generic/Icon/Icon';
import Popper from '../../../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import Menu from '../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import equalsEntity from '../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import useSwitch from '../../../../../../../@Util/Switch/useSwitch';
import AuthenticationManagerContext from '../../../../../../../@Service/Authentication/AuthenticationManagerContext';
import LocalizedText from '../../../../../../Domain/Localization/LocalizedText/LocalizedText';

export interface TeamSwitcherProps
{
    showInHeader?: boolean;
}

const TeamSwitcher: React.FC<TeamSwitcherProps> =
    ({
        showInHeader
    }) =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const [ isOpen, open, close ] = useSwitch(false);
        const authenticationManager = useContext(AuthenticationManagerContext);

        const selectTeam =
            useCallback(
                (team?: Entity) =>
                {
                    close();

                    authenticationManager.switchToTeam(team);
                },
                [
                    authenticationManager,
                    close
                ]);

        return <Popper
            reference={
                <div
                    className={styles.root}
                    onClick={open}
                    style={{
                        color:
                            showInHeader
                                ? '#fff'
                                : 'rgba(0, 0, 0, 0.87)'
                    }}
                >
                    <div
                        className={styles.activity}
                    >
                        {
                            currentUserStore.currentTeam?.name
                            || <LocalizedText
                                code="TeamSwitcher.NoTeamSelected"
                                value="Géén team geselecteerd"
                            />
                        }
                    </div>
                    <div
                        className={styles.icon}
                    >
                        <Icon
                            icon="expand_more"
                            size={20}
                        />
                    </div>
                </div>
            }
            popper={
                <Card>
                    <Menu
                        disableMaxHeight
                    >
                        {
                            (currentUserStore.limitedAccessTeams.length === 0
                                || currentUserStore.teams.length > currentUserStore.limitedAccessTeams.length
                                || currentUserStore.isAdministrator) &&
                                <Item
                                    name={
                                        <LocalizedText
                                            code="TeamSwitcher.NoTeam"
                                            value="Géén team"
                                        />
                                    }
                                    active={currentUserStore.currentTeam === undefined}
                                    onClick={() => selectTeam()}
                                />
                        }
                        {
                            currentUserStore.limitedAccessTeams.map(
                                team =>
                                    <Item
                                        key={team.uuid}
                                        name={team.name}
                                        active={equalsEntity(currentUserStore.currentTeam, team)}
                                        onClick={() => selectTeam(team)}
                                    />)
                        }
                    </Menu>
                </Card>
            }
            open={isOpen}
            onClose={close}
        />;

    };

export default observer(TeamSwitcher);
