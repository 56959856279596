import isUserError from './isUserError';
import captureException from '../../@Util/Exception/captureException';
import getErrorData from './getErrorData';
import localizeText from '../Localization/localizeText';

export default function getErrorMessage(error: any,
                                        fallbackMessage?: string)
{
    const errorData = getErrorData(error);

    if (isUserError(errorData))
    {
        return errorData.message || fallbackMessage;
    }
    else
    {
        captureException(error);

        return fallbackMessage
            || localizeText(
                'Error.FallbackMessage',
                'Oops! Er ging iets mis bij het uitvoeren van deze actie. Wij zijn ervan op de hoogte gebracht. Ververs de pagina of probeer het later nog eens.');
    }
}
