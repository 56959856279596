import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Entity/Type/Api/useTypes';
import { EntitySelectionBuilder } from '../../../../../Entity/Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../../../Entity/Path/@Model/EntityPath';
import useResults from '../../../../../Entity/Selection/Hooks/useResults';

export function useAutomationsParentEntities(
    automationsFilter: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void,
): Entity[]
{
    const types = useTypes();

    return useResults(
        types.EntityType.Type,
        (builder, rootPath) =>
            builder
                .where(
                    cb =>
                        cb.isDefined(
                            rootPath.joinTo(
                                types.EntityType.RelationshipDefinition.Automations,
                                false
                            )
                            .field()
                        )
                )
                .if(
                    () => true,
                    () =>
                        automationsFilter(
                            builder,
                            rootPath.joinTo(
                                types.EntityType.RelationshipDefinition.Automations,
                                false
                            )
                        )
                ),
        [
            automationsFilter,
            types,
        ]
    );
}