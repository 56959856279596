import React, { useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../../../../Type/Api/useTypes';
import useAggregateResult from '../../../../../../Selection/Hooks/useAggregateResult';
import { Aggregate } from '../../../../../../../DataObject/Model/Aggregate';
import Input from '../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { DataObject } from '../../../../../../../DataObject/Model/DataObject';
import { MathematicalOperator } from '../../../../../../../DataObject/Model/MathematicalOperator';
import Link from '../../../../../../../../../@Future/Component/Generic/Link/Link';
import styles from './Statistics.module.scss';
import { HighGranularityCompactRepresentation } from '../../../../../../../DataObject/Util/Representation/HighGranularityCompactRepresentation';
import useToggle from '../../../../../../../../../@Util/Toggle/useToggle';
import useIsMobile from '../../../../../../../../../@Util/Responsiveness/useIsMobile';
import Statistic from './Statistic/Statistic';
import { SelectionHint } from '../../../../../../../../../@Api/Model/Implementation/SelectionHint';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';

export interface StatisticsProps
{
    project: Entity;
    milestone?: Entity;
}

const Statistics: React.FC<StatisticsProps> =
    props =>
    {
        const types = useTypes();

        const [ ,setTab ] = useState(0);

        const totalInvoicedResult =
            useAggregateResult(
                types.Activity.Invoice.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.RelationshipDefinition.LinkedActivities,
                                        true),
                                    props.project))
                        .where(
                            cb =>
                                cb.neq(
                                    rootPath
                                        .joinTo(
                                            types.Activity.Invoice.RelationshipDefinition.Phase,
                                            false)
                                        .field(types.Datastore.Field.Code),
                                    undefined,
                                    'Concept'))
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.Activities,
                                                true),
                                            props.milestone)))
                        .aggregateOn(
                            rootPath.field(types.Activity.Field.TotalSalesExcludingVat),
                            undefined,
                            Aggregate.Sum)
                        .hint(SelectionHint.UseRealtimeAggregates),
                [
                    types,
                    props.project,
                    props.milestone
                ]);

        const totalInvoiced =
            useComputed(
                () =>
                {
                    if (totalInvoicedResult && !totalInvoicedResult.aggregates[0].isEmpty)
                    {
                        return totalInvoicedResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Currency', 0);
                    }
                },
                [
                    totalInvoicedResult
                ]);

        const totalInvoicedConceptResult =
            useAggregateResult(
                types.Activity.Invoice.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.RelationshipDefinition.LinkedActivities,
                                        true),
                                    props.project))
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath
                                        .joinTo(
                                            types.Activity.Invoice.RelationshipDefinition.Phase,
                                            false)
                                        .field(types.Datastore.Field.Code),
                                    undefined,
                                    'Concept'))
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.Activities,
                                                true),
                                            props.milestone)))
                        .aggregateOn(
                            rootPath.field(types.Activity.Field.TotalSalesExcludingVat),
                            undefined,
                            Aggregate.Sum)
                        .hint(SelectionHint.UseRealtimeAggregates),
                [
                    types,
                    props.project,
                    props.milestone
                ]);

        const totalInvoicedConcept =
            useComputed(
                () =>
                {
                    if (totalInvoicedConceptResult && !totalInvoicedConceptResult.aggregates[0].isEmpty)
                    {
                        return totalInvoicedConceptResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Currency', 0);
                    }
                },
                [
                    totalInvoicedConceptResult
                ]);

        const budget =
            useComputed(
                () =>
                {
                    if (props.milestone)
                    {
                        return props.milestone.getDataObjectValueByField(types.Milestone.Field.Budget);
                    }
                    else
                    {
                        return props.project.getDataObjectValueByField(types.Activity.Project.Field.Budget);
                    }
                },
                [
                    types,
                    props.project,
                    props.milestone
                ]);

        const forecast =
            useComputed(
                () =>
                    DataObject.compute(
                        budget,
                        totalInvoiced,
                        MathematicalOperator.Subtract),
                [
                    budget,
                    totalInvoiced
                ]);

        const totalInternalTimeCostResult =
            useAggregateResult(
                types.TimeRegistration.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.RelationshipDefinition.TimeRegistrations,
                                        true),
                                    props.project))
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.TimeRegistrations,
                                                true),
                                            props.milestone)))
                        .aggregateOn(
                            rootPath.field(types.TimeRegistration.Field.TotalCostPrice),
                            undefined,
                            Aggregate.Sum)
                        .hint(SelectionHint.UseRealtimeAggregates),
                [
                    types,
                    props.project,
                    props.milestone
                ]);

        const totalInternalTimeCost =
            useComputed(
                () =>
                {
                    if (totalInternalTimeCostResult && !totalInternalTimeCostResult.aggregates[0].isEmpty)
                    {
                        return totalInternalTimeCostResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Currency', 0);
                    }
                },
                [
                    totalInternalTimeCostResult
                ]);

        const totalExternalCostsResult =
            useAggregateResult(
                types.ExternalCost.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.Project.RelationshipDefinition.ExternalCosts,
                                        true),
                                    props.project))
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.ExternalCosts,
                                                true),
                                            props.milestone)))
                        .aggregateOn(
                            rootPath.field(types.ExternalCost.Field.Amount),
                            undefined,
                            Aggregate.Sum)
                        .hint(SelectionHint.UseRealtimeAggregates),
                [
                    types,
                    props.project,
                    props.milestone
                ]);

        const totalExternalCosts =
            useComputed(
                () =>
                {
                    if (totalExternalCostsResult && !totalExternalCostsResult.aggregates[0].isEmpty)
                    {
                        return totalExternalCostsResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Currency', 0);
                    }
                },
                [
                    totalExternalCostsResult
                ]);

        const totalPurchasePriceResult =
            useAggregateResult(
                types.ProductLine.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.RelationshipDefinition.ProductLines,
                                        true),
                                    props.project))
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.ProductLines,
                                                true),
                                            props.milestone)))
                        .aggregateOn(
                            rootPath.field(types.ProductLine.Field.TotalPurchasePrice),
                            undefined,
                            Aggregate.Sum)
                        .hint(SelectionHint.UseRealtimeAggregates),
                [
                    types,
                    props.project,
                    props.milestone
                ]);

        const totalPurchasePrice =
            useComputed(
                () =>
                {
                    if (totalPurchasePriceResult && !totalPurchasePriceResult.aggregates[0].isEmpty)
                    {
                        return totalPurchasePriceResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Currency', 0);
                    }
                },
                [
                    totalPurchasePriceResult
                ]);

        const timeSpentResult =
            useAggregateResult(
                types.TimeRegistration.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.RelationshipDefinition.TimeRegistrations,
                                        true),
                                    props.project))
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.TimeRegistrations,
                                                true),
                                            props.milestone)))
                        .aggregateOn(
                            rootPath.field(types.TimeRegistration.Field.DurationInHours),
                            undefined,
                            Aggregate.Sum)
                        .hint(SelectionHint.UseRealtimeAggregates),
                [
                    types,
                    props.project,
                    props.milestone
                ]);
        const timeSpent =
            useComputed(
                () =>
                {
                    if (timeSpentResult && !timeSpentResult.aggregates[0].isEmpty)
                    {
                        return timeSpentResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Number', 0);
                    }
                },
                [
                    timeSpentResult
                ]);

        const timePlannedResult =
            useAggregateResult(
                types.EmployeeAllocation.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.RelationshipDefinition.EmployeeAllocations,
                                        true),
                                    props.project))
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.EmployeeAllocations,
                                                true),
                                            props.milestone)))
                        .aggregateOn(
                            rootPath.field(types.EmployeeAllocation.Field.DurationInHours),
                            undefined,
                            Aggregate.Sum)
                        .hint(SelectionHint.UseRealtimeAggregates),
                [
                    types,
                    props.project,
                    props.milestone
                ]);
        const timePlanned =
            useComputed(
                () =>
                {
                    if (timePlannedResult && !timePlannedResult.aggregates[0].isEmpty)
                    {
                        return timePlannedResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Number', 0);
                    }
                },
                [
                    timePlannedResult
                ]);

        const hourBudget =
            useComputed(
                () =>
                {
                    let budget: DataObject;

                    if (props.milestone)
                    {
                        budget = props.milestone.getDataObjectValueByField(types.Milestone.Field.HourBudget);
                    }
                    else
                    {
                        budget = props.project.getDataObjectValueByField(types.Activity.Project.Field.HourBudget);
                    }

                    if (budget.isEmpty)
                    {
                        return DataObject.constructFromTypeIdAndValue('Number', 0);
                    }
                    else
                    {
                        return budget;
                    }
                },
                [
                    props.milestone,
                    props.project,
                    types
                ]);

        const totalCosts =
            useComputed(
                () =>
                    DataObject.compute(
                        totalInternalTimeCost,
                        DataObject.compute(
                            totalExternalCosts,
                            totalPurchasePrice,
                            MathematicalOperator.Add),
                        MathematicalOperator.Add),
                [
                    totalInternalTimeCost,
                    totalExternalCosts,
                    totalPurchasePrice
                ]);

        const totalSalesResult =
            useAggregateResult(
                types.ProductLine.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.RelationshipDefinition.ProductLines,
                                        true),
                                    props.project))
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.ProductLines,
                                                true),
                                            props.milestone)))
                        .aggregateOn(
                            rootPath.field(types.ProductLine.Field.Total),
                            undefined,
                            Aggregate.Sum)
                        .hint(SelectionHint.UseRealtimeAggregates),
                [
                    types,
                    props.project,
                    props.milestone
                ]);

        const totalSales =
            useComputed(
                () =>
                {
                    if (totalSalesResult && !totalSalesResult.aggregates[0].isEmpty)
                    {
                        return totalSalesResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Currency', 0);
                    }
                },
                [
                    totalSalesResult
                ]);

        const totalMaterialSalesResult =
            useAggregateResult(
                types.ProductLine.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.RelationshipDefinition.ProductLines,
                                        true),
                                    props.project))
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.ProductLines,
                                                true),
                                            props.milestone)))
                        .where(
                            cb =>
                                cb.neq(
                                    rootPath
                                        .joinTo(
                                            types.ProductLine.RelationshipDefinition.Product,
                                            false)
                                        .joinTo(
                                            types.Product.RelationshipDefinition.Unit,
                                            false)
                                        .field(types.Datastore.Field.Code),
                                    undefined,
                                    'Hour'
                                )
                        )
                        .aggregateOn(
                            rootPath.field(types.ProductLine.Field.Total),
                            undefined,
                            Aggregate.Sum)
                        .hint(SelectionHint.UseRealtimeAggregates),
                [
                    types,
                    props.project,
                    props.milestone
                ]);

        const totalMaterialSales =
            useComputed(
                () =>
                {
                    if (totalMaterialSalesResult && !totalMaterialSalesResult.aggregates[0].isEmpty)
                    {
                        return totalMaterialSalesResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Currency', 0);
                    }
                },
                [
                    totalMaterialSalesResult
                ]);

        const totalTimeSalesResult =
            useAggregateResult(
                types.TimeRegistration.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.RelationshipDefinition.TimeRegistrations,
                                        true),
                                    props.project))
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.TimeRegistrations,
                                                true),
                                            props.milestone)))
                        .aggregateOn(
                            rootPath.field(types.TimeRegistration.Field.TotalSalesPrice),
                            undefined,
                            Aggregate.Sum)
                        .hint(SelectionHint.UseRealtimeAggregates),
                [
                    types,
                    props.project,
                    props.milestone
                ]);

        const totalTimeSales =
            useComputed(
                () =>
                {
                    if (totalTimeSalesResult && !totalTimeSalesResult.aggregates[0].isEmpty)
                    {
                        return totalTimeSalesResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Currency', 0);
                    }
                },
                [
                    totalTimeSalesResult
                ]);

        const budgetUsed =
            useComputed(
                () =>
                    DataObject.compute(
                        DataObject.compute(
                            totalMaterialSales,
                            totalTimeSales,
                            MathematicalOperator.Add),
                        totalExternalCosts,
                        MathematicalOperator.Add),
                [
                    totalMaterialSales,
                    totalTimeSales,
                    totalExternalCosts
                ]);

        const profit =
            useComputed(
                () =>
                    DataObject.compute(
                        totalSales,
                        totalCosts,
                        MathematicalOperator.Subtract),
                [
                    totalSales,
                    totalCosts
                ]);

        const [ isBudgetExpanded, toggleBudgetExpansion ] = useToggle(false);
        const [ isInvoiceExpanded, toggleInvoiceExpansion ] = useToggle(false);
        const [ , toggleTimeExpansion ] = useToggle(false);
        const [ isProfitExpanded, toggleProfitExpansion ] = useToggle(false);
        const cardClasses =
            useMemo(
                () => ({
                    root: styles.card
                }),
                []);
        const isMobile = useIsMobile();
        const budgetUsedPercentage = budget.value > 0 ? Math.round(budgetUsed.value / budget.value * 100) : 0;
        const timeSpentPercentage = hourBudget.value > 0 ? Math.round(timeSpent.value / hourBudget.value * 100) : 0;
        const timePlannedPercentage = hourBudget.value > 0 ? Math.round(timePlanned.value / hourBudget.value * 100) : 0;

        return <ViewGroup
            orientation={isMobile ? 'vertical' : 'horizontal'}
            spacing={15}
            wrap
        >
            <ViewGroupItem>
                <div
                    className={styles.statistic}
                >
                    <Card
                        classes={cardClasses}
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                <Statistic
                                    label={
                                        <LocalizedText
                                            code="Projects.BudgetUsed"
                                            value="Budget verbruikt"
                                        />
                                    }
                                    value={budgetUsed.toString(HighGranularityCompactRepresentation)}
                                    total={budget.toString(HighGranularityCompactRepresentation)}
                                    percentage={budgetUsedPercentage}
                                    onClick={toggleBudgetExpansion}
                                    danger={budgetUsedPercentage > 100}
                                />
                            </ViewGroupItem>
                            {
                                isBudgetExpanded &&
                                    <ViewGroupItem>
                                        <div
                                            className={styles.expansion}
                                        >
                                            <ViewGroup
                                                orientation="vertical"
                                                spacing={5}
                                            >
                                                <ViewGroupItem>
                                                    <Input
                                                        label={
                                                            <LocalizedText
                                                                code="Projects.MaterialSales"
                                                                value="Verkoop materiaal"
                                                            />
                                                        }
                                                        labelPosition="left"
                                                        labelFullWidth
                                                        help={
                                                            <LocalizedText
                                                                code="Projects.MaterialSalesHelp"
                                                                value="De totale waarde van de te factureren/gefactureerde producten (uren niet meegeteld)"
                                                            />
                                                        }
                                                    >
                                                        <Link
                                                            onClick={() => setTab(3)}
                                                        >
                                                            {totalMaterialSales && totalMaterialSales.toString()}
                                                        </Link>
                                                    </Input>
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <Input
                                                        label={
                                                            <LocalizedText
                                                                code="Projects.TimeSales"
                                                                value="Verkoop uren"
                                                            />
                                                        }
                                                        labelPosition="left"
                                                        labelFullWidth
                                                        help={
                                                            <LocalizedText
                                                                code="Projects.TimeSalesHelp"
                                                                value="De totale verkoopwaarde van de verwerkte en de te factureren/gefactureerde uren"
                                                            />
                                                        }
                                                    >
                                                        <Link
                                                            onClick={() => setTab(1)}
                                                        >
                                                            {totalTimeSales && totalTimeSales.toString()}
                                                        </Link>
                                                    </Input>
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <Input
                                                        label={
                                                            <LocalizedText
                                                                code="Projects.ExternalCosts"
                                                                value="Externe kosten"
                                                            />
                                                        }
                                                        labelPosition="left"
                                                        labelFullWidth
                                                        help={
                                                            <LocalizedText
                                                                code="Projects.ExternalCostsHelp"
                                                                value="Totale waarde van de externe kosten"
                                                            />
                                                        }
                                                    >
                                                        <Link
                                                            onClick={() => setTab(2)}
                                                        >
                                                            {totalExternalCostsResult && totalExternalCosts.toString()}
                                                        </Link>
                                                    </Input>
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </div>
                                    </ViewGroupItem>
                            }
                        </ViewGroup>
                    </Card>
                </div>
            </ViewGroupItem>
            {
                types.Activity.Invoice.Type &&
                    <ViewGroupItem>
                        <div
                            className={styles.statistic}
                        >
                            <Card
                                classes={cardClasses}
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={0}
                                >
                                    <ViewGroupItem>
                                        <Statistic
                                            label={
                                                <LocalizedText
                                                    code="Projects.Invoiced"
                                                    value="Gefactureerd"
                                                />
                                            }
                                            value={totalInvoiced.toString(HighGranularityCompactRepresentation)}
                                            total={budget.toString(HighGranularityCompactRepresentation)}
                                            percentage={budget.value > 0 ? Math.round(totalInvoiced.value / budget.value * 100) : 0}
                                            onClick={toggleInvoiceExpansion}
                                        />
                                    </ViewGroupItem>
                                    {
                                        isInvoiceExpanded &&
                                            <ViewGroupItem>
                                                <div
                                                    className={styles.expansion}
                                                >
                                                    <ViewGroup
                                                        orientation="vertical"
                                                        spacing={5}
                                                    >
                                                        <ViewGroupItem>
                                                            <Input
                                                                label={
                                                                    <LocalizedText
                                                                        code="Projects.SentInvoices"
                                                                        value="Verstuurd"
                                                                    />
                                                                }
                                                                labelPosition="left"
                                                                labelFullWidth
                                                                help={
                                                                    <LocalizedText
                                                                        code="Projects.SentInvoicesHelp"
                                                                        value="Totale waarde van facturen die zijn verstuurd"
                                                                    />
                                                                }
                                                            >
                                                                <Link
                                                                    onClick={() => setTab(3)}
                                                                >
                                                                    {totalInvoicedResult && totalInvoiced.toString()}
                                                                </Link>
                                                            </Input>
                                                        </ViewGroupItem>
                                                        <ViewGroupItem>
                                                            <Input
                                                                label={
                                                                    <LocalizedText
                                                                        code="Projects.ConceptInvoices"
                                                                        value="Concept"
                                                                    />
                                                                }
                                                                labelPosition="left"
                                                                labelFullWidth
                                                                help={
                                                                    <LocalizedText
                                                                        code="Projects.ConceptInvoicesHelp"
                                                                        value="Totale waarde van facturen die in concept staan"
                                                                    />
                                                                }
                                                            >
                                                                <Link
                                                                    onClick={() => setTab(3)}
                                                                >
                                                                    {totalInvoicedConceptResult && totalInvoicedConcept.toString()}
                                                                </Link>
                                                            </Input>
                                                        </ViewGroupItem>
                                                        <ViewGroupItem>
                                                            <Input
                                                                label={
                                                                    <LocalizedText
                                                                        code="Projects.ForecastInvoices"
                                                                        value="Forecast"
                                                                    />
                                                                }
                                                                labelPosition="left"
                                                                labelFullWidth
                                                                help={
                                                                    <LocalizedText
                                                                        code="Projects.ForecastInvoicesHelp"
                                                                        value="Verwachtte te factureren bedrag: budget - totale waarde van verstuurde facturen"
                                                                    />
                                                                }
                                                            >
                                                                {totalInvoicedResult && forecast.toString()}
                                                            </Input>
                                                        </ViewGroupItem>
                                                    </ViewGroup>
                                                </div>
                                            </ViewGroupItem>
                                    }
                                </ViewGroup>
                            </Card>
                        </div>
                    </ViewGroupItem>
            }
            <ViewGroupItem>
                <div
                    className={styles.statistic}
                >
                    <Card
                        classes={cardClasses}
                    >
                        <Statistic
                            label={
                                <LocalizedText
                                    code="Projects.TimeSpent"
                                    value="Tijd gespendeerd"
                                />
                            }
                            value={
                                <LocalizedText
                                    code="Generic.DurationInHours"
                                    value="${duration} uur"
                                    duration={timeSpent.toString()}
                                />
                            }
                            total={
                                <LocalizedText
                                    code="Generic.DurationInHours"
                                    value="${duration} uur"
                                    duration={hourBudget.toString()}
                                />
                            }
                            percentage={timeSpentPercentage}
                            danger={timeSpentPercentage > 100}
                            onClick={toggleTimeExpansion}
                        />
                    </Card>
                </div>
            </ViewGroupItem>
            <ViewGroupItem>
                <div
                    className={styles.statistic}
                >
                    <Card
                        classes={cardClasses}
                    >
                        <Statistic
                            label={
                                <LocalizedText
                                    code="Generic.Occupation"
                                    value="Bezetting"
                                />
                            }
                            value={
                                <LocalizedText
                                    code="Generic.DurationInHours"
                                    value="${duration} uur"
                                    duration={timePlanned.toString()}
                                />
                            }
                            total={
                                <LocalizedText
                                    code="Generic.DurationInHours"
                                    value="${duration} uur"
                                    duration={hourBudget.toString()}
                                />
                            }
                            percentage={timePlannedPercentage}
                            danger={timePlannedPercentage > 100}
                            onClick={toggleTimeExpansion}
                        />
                    </Card>
                </div>
            </ViewGroupItem>
            <ViewGroupItem>
                <div
                    className={styles.statistic}
                >
                    <Card
                        classes={cardClasses}
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                <Statistic
                                    label={
                                        profit.value < 0
                                        ?
                                            <LocalizedText
                                                code="Projects.Loss"
                                                value="Verlies"
                                            />
                                        :
                                            <LocalizedText
                                                code="Projects.Profit"
                                                value="Winst"
                                            />
                                    }
                                    value={profit.toString(HighGranularityCompactRepresentation)}
                                    total={totalSales.toString(HighGranularityCompactRepresentation)}
                                    percentage={totalSales.value !== 0 ? Math.abs(Math.round(profit.value / totalSales.value * 100) ): 0}
                                    onClick={toggleProfitExpansion}
                                    danger={profit.value < 0}
                                />
                            </ViewGroupItem>
                            {
                                isProfitExpanded &&
                                    <ViewGroupItem>
                                        <div
                                            className={styles.expansion}
                                        >
                                            <ViewGroup
                                                orientation="vertical"
                                                spacing={5}
                                            >
                                                <ViewGroupItem>
                                                    <Input
                                                        label={
                                                            <LocalizedText
                                                                code="Project.Sales"
                                                                value="Verkoop"
                                                            />
                                                        }
                                                        labelPosition="left"
                                                        labelFullWidth
                                                    >
                                                        <Link
                                                            onClick={() => setTab(1)}
                                                        >
                                                            {totalSales && totalSales.toString()}
                                                        </Link>
                                                    </Input>
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <Input
                                                        label={
                                                            <LocalizedText
                                                                code="Projects.InternalHourCosts"
                                                                value="Interne uurkosten"
                                                            />
                                                        }
                                                        labelPosition="left"
                                                        labelFullWidth
                                                        help={
                                                            <LocalizedText
                                                                code="Projects.InternalHourCostsHelp"
                                                                value="Totale kosten van alle uren"
                                                            />
                                                        }
                                                    >
                                                        <Link
                                                            onClick={() => setTab(1)}
                                                        >
                                                            {totalInternalTimeCostResult && totalInternalTimeCost.toString()}
                                                        </Link>
                                                    </Input>
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <Input
                                                        label={
                                                            <LocalizedText
                                                                code="Projects.ExternalCosts"
                                                                value="Externe kosten"
                                                            />
                                                        }
                                                        labelPosition="left"
                                                        labelFullWidth
                                                        help={
                                                            <LocalizedText
                                                                code="Projects.ExternalCostsHelp"
                                                                value="Totale waarde van de externe kosten"
                                                            />
                                                        }
                                                    >
                                                        <Link
                                                            onClick={() => setTab(2)}
                                                        >
                                                            {totalExternalCostsResult && totalExternalCosts.toString()}
                                                        </Link>
                                                    </Input>
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <Input
                                                        label={
                                                            <LocalizedText
                                                                code="Projects.PurchaseCosts"
                                                                value="Inkoopkosten"
                                                            />
                                                        }
                                                        labelPosition="left"
                                                        labelFullWidth
                                                        help={
                                                            <LocalizedText
                                                                code="Projects.PurchaseCostsHelp"
                                                                value="Totale inkoopwaarde van alle facturatieregels"
                                                            />
                                                        }
                                                    >
                                                        <Link
                                                            onClick={() => setTab(3)}
                                                        >
                                                            {totalPurchasePriceResult && totalPurchasePrice.toString()}
                                                        </Link>
                                                    </Input>
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </div>
                                    </ViewGroupItem>
                            }
                        </ViewGroup>
                    </Card>
                </div>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(Statistics);
