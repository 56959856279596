import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import EntityInputLayout from '../../../../../@Api/Layout/Type/EntityInputLayout';
import FieldPathSelector from '../../../Entity/Path/FieldPathEditor/FieldPathSelector';
import MenuButton from '../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import { EntityContext } from '../../../Entity/@Model/EntityContext';
import { runInAction } from 'mobx';
import resolveInputFromFieldPath from '../../../Multiplayer/Model/Input/Api/resolveInputFromFieldPath';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';
import EntityInputLayoutViewer, { EntityInputLayoutViewerProps } from './EntityInputLayoutViewer';
import { primaryColor } from '../../../../../@Resource/Theme/Theme';
import { EntityPathJoinNode } from '../../../Entity/Path/@Model/Node/EntityPathJoinNode';

export interface EntityInputLayoutEditorProps extends LayoutEditorProps<EntityInputLayout>
{

}

const EntityInputLayoutEditor: React.FC<EntityInputLayoutEditorProps> =
    props =>
    {
        const context =
            useComputed(
                () =>
                    EntityContext.fromEntityType(props.layout.parameter.type.type),
                [
                    props.layout
                ]);

        const setFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                            props.layout.field = resolveInputFromFieldPath(fieldPath)),
                [
                    props.layout
                ]);

        const fieldPathFilter =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    fieldPath.path.nodes.length === 2
                        ? (fieldPath.path.nodes[1] instanceof EntityPathJoinNode
                            && !fieldPath.isField)
                        : fieldPath.path.nodes.length === 1,
                []
            );

        if (props.layout.field)
        {
            if (props.parameterAssignment)
            {
                return <EntityInputLayoutViewer
                    {...props as EntityInputLayoutViewerProps}
                    fallback={
                        <>
                            {props.layout.getName()}
                        </>
                    }
                />;
            }
            else
            {
                return <>{props.layout.getName()}</>;
            }
        }
        else
        {
            return <MenuButton
                icon="edit"
                tooltip="Veld selecteren"
                small
                fullWidth
                label={props.layout.field === undefined ? 'Selecteer een veld...' : undefined}
                color={primaryColor}
            >
                <FieldPathSelector
                    context={context}
                    onSelect={setFieldPath}
                    filter={fieldPathFilter}
                />
            </MenuButton>;
        }
    };

export default observer(EntityInputLayoutEditor);
