import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import AggregateValue from './AggregateValue';
import { observable } from 'mobx';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../Type/EntityTypeStore';
import ColumnOrdering from './ColumnOrdering';
import Layout from '../../../../../../@Api/Layout/Layout';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import getLayoutFromDescriptor from '../../../../../../@Api/Layout/Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../../@Api/Layout/LayoutDependencyContext';

export default class Column
{
    // ------------------------- Properties -------------------------

    @observable.ref groupFieldPath: EntityFieldPath;
    @observable aggregateValues: AggregateValue[];
    @observable.shallow ordering: ColumnOrdering[];
    @observable.ref itemLayout?: Layout;

    // ------------------------ Constructor -------------------------

    constructor(groupFieldPath: EntityFieldPath,
                aggregateValues: AggregateValue[],
                ordering: ColumnOrdering[] = [],
                itemLayout?: Layout)
    {
        this.groupFieldPath = groupFieldPath;
        this.aggregateValues = aggregateValues;
        this.ordering = ordering;
        this.itemLayout = itemLayout;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isValid(): boolean
    {
        return true;
    }

    static async fromDescriptor(
        descriptor: any,
        parameters: ParameterDictionary
    )
    {
        if (descriptor.groupFieldPath)
        {
            const fieldPath =
                EntityFieldPath.construct(
                    descriptor.groupFieldPath,
                    loadModuleDirectly(EntityTypeStore));

            if (fieldPath)
            {
                return new Column(
                    fieldPath,
                    descriptor.aggregateValues
                        .map(
                            aggregateValue =>
                                AggregateValue.fromDescriptor(
                                    aggregateValue))
                        .filter(
                            aggregateValue =>
                                aggregateValue.isValid),
                    (descriptor.ordering || []).map(
                        ordering =>
                            ColumnOrdering.fromDescriptor(ordering)),
                    descriptor.itemLayout
                        ?
                            await getLayoutFromDescriptor(
                                descriptor.itemLayout,
                                new LayoutDependencyContext(
                                    parameters
                                )
                            )
                        :
                            undefined
                    );
            }
            else
            {
                return undefined;
            }
        }
        else
        {
            return undefined;
        }
    }

    toDescriptor()
    {
        return {
            groupFieldPath: this.groupFieldPath?.descriptor,
            aggregateValues:
                this.aggregateValues.map(
                    aggregateValue =>
                        aggregateValue.toDescriptor()),
            ordering: this.ordering.map(ordering => ordering.toDescriptor()),
            itemLayout: this.itemLayout?.toDescriptor(),
        };
    }

    // ----------------------- Private logic ------------------------
}
