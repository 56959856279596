import { Setting } from '../../Settings/Setting';
import { Entity } from '../../Model/Implementation/Entity';
import getSetting from '../../../@Component/Domain/Setting/Api/getSetting';
import { SettingSource } from '../../../@Component/Domain/Setting/SettingStore';
import setSetting from '../../../@Component/Domain/Setting/Api/setSetting';

export async function setFieldGroupExpandedPredicate(fieldGroup: Entity,
                                                     predicate: any)
{
    await setFieldGroupExpandedPredicateSettingValue(
        fieldGroup,
        Setting.Metadata.ExpandedFieldGroupPredicate,
        predicate);
}

async function setFieldGroupExpandedPredicateSettingValue(fieldGroup: Entity,
                                                         code: string,
                                                         value: any)
{
    const settingValue = getSetting<any>(SettingSource.Organization, code);
    const newSettingValue = {
        ...settingValue
    };
    newSettingValue[fieldGroup.uuid] = value;

    await setSetting(
        SettingSource.Organization,
        code,
        newSettingValue
    );
}