import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Action, { ActionEditorProps } from '../../ActionEditor';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ForEachAction from '../../../../../../../../../../@Api/Automation/Function/Action/ForEachAction';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationEditor from '../../../Computation/ComputationEditor';
import CollectionType from '../../../../../../../../../../@Api/Automation/Value/Type/CollectionType';
import CollectionValue from '../../../../../../../../../../@Api/Automation/Value/CollectionValue';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import FunctionContext from '../../../../../../../../../../@Api/Automation/Function/FunctionContext';
import ParameterDictionary from '../../../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import CardInset from '../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import EmptyValueType from '../../../../../../../../../../@Api/Automation/Value/Type/EmptyValueType';

export interface ForEachActionEditorProps extends ActionEditorProps<ForEachAction>
{

}

const ForEachActionEditor: React.FC<ForEachActionEditorProps> =
    props =>
    {
        const collectionType =
            useMemo(
                () =>
                    new CollectionType(
                        EmptyValueType.instance
                    ),
                []);

        const setCollection =
            useCallback(
                (collection: Computation<CollectionType<any>, CollectionValue<any>>) =>
                    runInAction(
                        () =>
                            props.action.collection = collection),
                [
                    props.action
                ]);

        const childContext =
            useComputed(
                () =>
                {
                    const elementParameter = props.action.getElementParameter();

                    return new FunctionContext(
                        new ParameterDictionary([
                            ...Array.from(props.context.parameterDictionary.parameterById.values()),
                            ...elementParameter
                                ?
                                    [
                                        elementParameter
                                    ]
                                :
                                    []
                        ]),
                        undefined,
                        props.context.commitContext
                    );
                },
                [
                    props.context,
                    props.action
                ]);

        return <CardInset
            top={false}
        >
            <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.Collection"
                                value="Collectie"
                            />
                        }
                        labelPosition="left"
                    >
                        <ComputationEditor
                            context={props.context}
                            type={collectionType}
                            value={props.action.collection}
                            onChange={setCollection}
                        />
                    </Input>
                </ViewGroupItem>
                {
                    props.action.collection &&
                        <ViewGroupItem>
                            <Action
                                context={childContext}
                                action={props.action.action}
                            />
                        </ViewGroupItem>
                }
            </ViewGroup>
        </CardInset>;
    };

export default observer(ForEachActionEditor);
