import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import EntityContext from '../../Context/EntityContext';
import HeaderContext from '../../Context/HeaderContext';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { openEntity } from '../../../@Util/openEntity';
import ClickableContext from '../../Context/ClickableContext';
import useIsContextExpanded from '../../Navigator/Api/useIsContextExpanded';
import useSetContextExpansion from '../../Navigator/Api/useSetContextExpansion';
import useContextExpansionPath from '../../Navigator/Api/useContextExpansionPath';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import OnOpenContext from '../../Context/OnOpenContext';
import Region from './Region/Region';

export interface ClickProps
{
    open?: boolean;
    expand?: boolean;
    target?: Entity;
    allowExpansionHighlight?: boolean;
}

const Click: React.FC<ClickProps> =
    props =>
    {
        const contextEntity = useContext(EntityContext);
        const entity = props.target ? props.target : contextEntity;
        const isHeader = useContext(HeaderContext);
        const rootPath =
            useMemo(
                () =>
                    entity &&
                        EntityPath.fromEntity(entity),
                [
                    entity
                ]);

        const isContextExpanded = useIsContextExpanded();
        const setContextExpansion = useSetContextExpansion();
        const expansionPath = useContextExpansionPath();
        const onOpen = useContext(OnOpenContext);
        const onClick =
            useCallback(
                () =>
                {
                    if (props.open && entity)
                    {
                        if (onOpen)
                        {
                            onOpen(entity);
                        }
                        else
                        {
                            openEntity(entity);
                        }
                    }
                    else if (props.expand)
                    {
                        if (expansionPath && expansionPath.id === rootPath.id)
                        {
                            setContextExpansion(undefined);
                        }
                        else
                        {
                            setContextExpansion(rootPath);
                        }
                    }
                },
                [
                    entity,
                    props.open,
                    props.expand,
                    setContextExpansion,
                    expansionPath,
                    rootPath,
                    onOpen
                ]);

        const showExpansionHighlight =
            useMemo(
                () =>
                    props.allowExpansionHighlight && isContextExpanded && !isHeader,
                [
                    props.allowExpansionHighlight,
                    isContextExpanded,
                    isHeader
                ]);

        const isClickable =
            useMemo(
                () =>
                    !isHeader && (props.open || props.expand),
                [
                    isHeader,
                    props.open,
                    props.expand
                ]);

        return <ClickableContext.Provider
            value={isClickable}
        >
            <Region
                clickable={isClickable}
                highlighted={showExpansionHighlight}
                onClick={onClick}
            >
                {props.children}
            </Region>
        </ClickableContext.Provider>;
    };

export default observer(Click);
