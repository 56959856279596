import { BaseStore, getOrCompute, PropType } from '../../../../../@Framework/Store/BaseStore';
import { SelectboxStore } from '../SelectboxStore';
import { AvatarStore } from '../../../Avatar/AvatarStore';
import { computed, observable } from 'mobx';
import { TextStore } from '../../../Text/TextStore';

export type OnSelectboxItemSelect<D> = (store: SelectboxStore<D>, item: SelectboxItemStore<D>) => void;

export interface SelectboxItemProps<D>
{
    data: D;
    textStore?: PropType<SelectboxItemStore<D>, SelectboxItemProps<D>, TextStore>;
    avatarStore?: PropType<SelectboxItemStore<D>, SelectboxItemProps<D>, AvatarStore>;
    isValueRepresentation?: PropType<SelectboxItemStore<D>, SelectboxItemProps<D>, boolean>;
}

const defaultProps =
{
};

export class SelectboxItemStore<D> extends BaseStore<SelectboxItemProps<D>>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable data: D;

    // ------------------------ Constructor -------------------------

    constructor(props: SelectboxItemProps<D>)
    {
        super(props, defaultProps);

        this.data = this.props.data;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get textStore()
    {

        return getOrCompute(this, this.props.textStore);
    }

    @computed
    get avatarStore()
    {
        return getOrCompute(this, this.props.avatarStore);
    }

    @computed
    get isValueRepresentation(): boolean
    {
        return getOrCompute(this, this.props.isValueRepresentation);
    }

    // --------------------------- Stores ---------------------------

    @computed
    get derivedAvatarStore()
    {
        return this.avatarStore
            ?
                new AvatarStore(
                {
                    ...this.avatarStore.props,
                    size: 30
                })
            :
                undefined;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
