import * as React from 'react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { DragAndDropStore } from '../DragAndDropStore';
import { Drawer, Toolbar } from '@material-ui/core';
import { DragAndDropBucket } from './Bucket/DragAndDropBucket';
import { observer } from 'mobx-react';

@observer
export class DragAndDropBar extends BaseComponent<DragAndDropStore>
{
    renderComponent(store: DragAndDropStore)
    {
        // return <MuiThemeProvider theme={InverseTheme}>
        return <Drawer variant="persistent" anchor="bottom" open={store.activeBucketStores.length > 0}>
            <Toolbar disableGutters>
                {
                    store.activeBucketStores.map(
                        (bucketStore, idx) => (
                            <DragAndDropBucket key={idx} store={bucketStore} />
                        ))
                }
            </Toolbar>
        </Drawer>;
    }
}
