import * as React from 'react';
import { DataObjectBespokeEditor } from '../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { FormControlLabel } from '@material-ui/core';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { observer } from 'mobx-react';
import { ColorPicker } from '../../../../Generic/ColorPicker/ColorPicker';
import { ColorEditorStore } from './ColorEditorStore';

@observer
export class ColorEditor extends DataObjectBespokeEditor<ColorEditorStore>
{
    renderComponent(store: DataObjectEditorStore<ColorEditorStore>)
    {
        return <FormControlLabel
            label={store.label || ''}
            style={{
                width: '100%'
            }}
            control={<ColorPicker store={store.bespokeStore.colorPickerStore} />}
        />;
    }
}
