import React, { useCallback, useEffect, useState } from 'react';
import useApiClient from '../../../../../../../@Service/ApiClient/Hooks/useApiClient';
import { ApiRequest, Method } from '../../../../../../../@Service/ApiClient/Model/ApiRequest';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import TableBody from '@material-ui/core/TableBody';
import { WebhookEvent } from './Model/WebhookEvent';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import { WebhookEventRow } from './EventRow/WebhookEventRow';
import HoverCardBottom from '../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';

export interface WebhookEventsPageProps
{
    webhookId: string;
}

const pageSize = 50;

export const WebhookEventsTable: React.FC<WebhookEventsPageProps> =
    ({
        webhookId
     }) =>
    {
        const [ page, setPage ] = useState(0);
        const apiClient = useApiClient();
        const [ hasMore, setHasMore ] = useState(false);
        const [ isLoading, setLoading ] = useState(false);
        const [ events, setEvents ] = useState<WebhookEvent[]>([]);
        const loadPage =
            useCallback(
                async (page: number) =>
                {
                    setLoading(true);
                    const loadedEvents =
                        await apiClient
                            .request(
                                new ApiRequest<any>(
                                    `/v1/webhooks/${webhookId}/events`,
                                    Method.Get,
                                    {
                                        offset: page * pageSize,
                                        limit: pageSize,
                                    })
                            );

                    setEvents(
                        events => [
                            ...events,
                            ...loadedEvents
                        ]
                    );
                    setHasMore(loadedEvents.length === pageSize);
                    setLoading(false);
                    setPage(page);
                },
                [
                    apiClient,
                    setLoading,
                    setEvents,
                    setHasMore,
                    setPage,
                ]);

        useEffect(
            () =>
            {
                loadPage(0);
            },
            [
                loadPage
            ]);

        const onSave =
            useCallback(
                (event: WebhookEvent) =>
                    setEvents(
                        events =>
                            events.map(
                                checkEvent =>
                                    checkEvent.id === event.id
                                        ? event
                                        : checkEvent
                            )
                    ),
                [
                    setEvents
                ]);

        return <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <LocalizedText
                                code="Generic.Date"
                                value="Datum"
                            />
                        </TableCell>
                        <TableCell>
                            <LocalizedText
                                code="Generic.Url"
                                value="URL"
                            />
                        </TableCell>
                        <TableCell>
                            <LocalizedText
                                code="Generic.Status"
                                value="Status"
                            />
                        </TableCell>
                        <TableCell>
                            <LocalizedText
                                code="Generic.NumberOfAttempts"
                                value="Aantal pogingen"
                            />
                        </TableCell>
                        <TableCell>
                            <LocalizedText
                                code="Generic.LastAttempt"
                                value="Laatste poging"
                            />
                        </TableCell>
                        <TableCell>
                            <LocalizedText
                                code="Generic.NextAttempt"
                                value="Volgende poging"
                            />
                        </TableCell>
                        <TableCell>
                            <LocalizedText
                                code="Generic.DeletionDate"
                                value="Verwijderdatum"
                            />
                        </TableCell>
                        <TableCell
                            align="right"
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isLoading &&
                            <TableRow>
                                <TableCell
                                    colSpan={7}
                                >
                                    <Centered
                                        horizontal
                                    >
                                        <Loader />
                                    </Centered>
                                </TableCell>
                            </TableRow>
                    }
                    {
                        events.map(
                            event =>
                                <WebhookEventRow
                                    key={event.id}
                                    event={event}
                                    onSave={onSave}
                                />
                        )
                    }
                </TableBody>
            </Table>
            {
                hasMore &&
                    <HoverCardBottom
                        onClick={() => loadPage(page + 1)}
                    >
                        <LocalizedText
                            code="Generic.LoadMore"
                            value="Meer laden"
                        />
                    </HoverCardBottom>
            }
        </>;
    };
