import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { RouterStore } from '../../../../../@Service/Router/RouterStore';

export class BespokeDashboard extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore, code: string = 'Dashboard')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    onOpen(entity: Entity, pathFromRelatedEntity?: RelatedEntityPath): Promise<boolean>
    {
        return loadModuleDirectly(RouterStore)
            .route(`/dashboard/${entity.id}`)
            .then(
                () =>
                    Promise.resolve(false));
    }

    // ----------------------- Private logic ------------------------
}
