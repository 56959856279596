interface YearSelection {
    id: string;
    value: number;
    label: string;
}

export function getCalendarYearSelection(
    currentYear: number,
    numberOfPreviousYearsToShow: number,
    numberOfNextYearsToShow: number
)
{
    const yearSelection: YearSelection[] = [] ;
    const startYear = currentYear - numberOfPreviousYearsToShow
    const numberOfYearsToShower = numberOfNextYearsToShow + numberOfPreviousYearsToShow + 1
    for (let i = 0; i < numberOfYearsToShower; i++)
    {
        const year = startYear + i;

        yearSelection.push(
            {
                id: `${i}`,
                value: year,
                label: `${year}`
            }
        );
    }
    return yearSelection;
}
