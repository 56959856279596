import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import HighlightedEntityContext from '../../HighlightContext/HighlightedEntityContext';
import { groupBy } from '../../../../../../@Util/MapUtils/groupBy';
import HighlightedEntityType from './HighlightedEntityType/HighlightedEntityType';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';

export interface HighlightContainerProps
{
    paths: EntityPath[];
}

const HighlightContainer: React.FC<HighlightContainerProps> =
    props =>
    {
        const highlightedEntities = useContext(HighlightedEntityContext);

        const highlightedEntitiesByType =
            useMemo(
                () =>
                    groupBy(
                        highlightedEntities,
                        entity =>
                            entity.entityType),
                [
                    highlightedEntities
                ]);

        const types =
            useMemo(
                () =>
                    Array.from(highlightedEntitiesByType.keys()),
                [
                    highlightedEntitiesByType
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={5}
            glue={useDividerGlue()}
        >
            {
                types.map(
                    type =>
                        <HighlightedEntityType
                            key={type.id}
                            paths={props.paths}
                            entityType={type}
                            entities={highlightedEntitiesByType.get(type)}
                        />)
            }
        </ViewGroup>;
    };

export default observer(HighlightContainer);
