import { blue } from '../../@Resource/Theme/Blue';
import { brown, deepOrange, green, grey, indigo, lime, orange, pink, purple, teal } from '@material-ui/core/colors';
import { Color } from '@material-ui/core';
import { RGBA } from '../../@Component/Generic/ColorPicker/ColorPickerStore';

const colors: Color[] =
[
    blue, orange, green, brown, purple, indigo, teal, deepOrange, grey, lime, pink
];

export class ColorUtils
{
    static color(idx: number): Color
    {
        return colors[idx % colors.length];
    }

    static colorOfString(value: string): Color
    {
        return ColorUtils.color(value.length > 0 ? Number(value.charCodeAt(0)) : 0);
    }

    static hexToRGB(hex: string, alpha?: number): string
    {
        if (hex)
        {
            let r = parseInt(hex.slice(1, 3), 16),
                g = parseInt(hex.slice(3, 5), 16),
                b = parseInt(hex.slice(5, 7), 16);

            if (alpha != null)
            {
                return `rgba(${r},${g},${b},${alpha})`;
            }
            else
            {
                return `rgb(${r},${g},${b})`;
            }
        }
        else
        {
            return undefined;
        }
    }

    static hexToRgba(hex: string,
                     alpha: number = 1): RGBA
    {
        hex = hex.replace('#', '');

        return {
            r: parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16),
            g: parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16),
            b: parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16),
            a: alpha
        };
    }

    static rgbaToHex(orig: string)
    {
        let rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+)/i);

        return (rgb && rgb.length === 4) ? '#' +
        ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2) : orig;
    }
}
