import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { default as WidgetModel } from '../Model/Widget';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../Type/Api/useTypes';
import uuid from '../../../../../@Util/Id/uuid';
import AgendaWidget from '../Model/Widget/Agenda/AgendaWidget';
import Agenda from '../Widget/Agenda/Agenda';
import Calendar from '../Widget/Calendar/Calendar';
import CalendarWidget from '../Model/Widget/Calendar/CalendarWidget';
import useDividerGlue from '../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ExpansionPanel from '../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import ExpansionGroup from '../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import Header from '../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import TypeBrowser from './TypeBrowser/TypeBrowser';
import WebsiteWidget from '../Model/Widget/Website/WebsiteWidget';
import Website from '../Widget/Website/Website';
import FileWidget from '../Model/Widget/File/FileWidget';
import File from '../Widget/File/File';
import WidgetAdder from './Widget/WidgetAdder';
import EmployeePlannerWidget from '../Model/Widget/EmployeePlanner/EmployeePlannerWidget';
import EmployeePlanner from '../Widget/EmployeePlanner/EmployeePlanner';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import CustomWidgetBrowser from './CustomWidgetBrowser/CustomWidgetBrowser';
import EmployeeAllocationWidget from '../Model/Widget/EmployeeAllocation/EmployeeAllocationWidget';
import EmployeeAllocation from '../Widget/EmployeeAllocation/EmployeeAllocation';
import { FullscreenAgendaWidgetModel } from '../Model/Widget/FullscreenAgenda/FullscreenAgendaWidgetModel';
import { FullscreenAgendaWidget } from '../Widget/FullScreenAgenda/FullscreenAgendaWidget';

export interface WidgetBrowserProps
{
    onAdd: (widget: WidgetModel) => void;
    onClose: () => void;
}

const WidgetBrowser: React.FC<WidgetBrowserProps> =
    props =>
    {
        const types = useTypes();
        const onDelete = useCallback(() => {}, []);
        const agendaWidget = useMemo(() => new AgendaWidget(uuid()), []);
        const fullScreenAgendaWidget = useMemo(() => new FullscreenAgendaWidgetModel(uuid()), []);
        const calendarWidget = useMemo(() => new CalendarWidget(uuid()), []);
        const websiteWidget = useMemo(() => new WebsiteWidget(uuid()), []);
        const fileWidget = useMemo(() => new FileWidget(uuid()), []);
        const dividerGlue = useDividerGlue();
        const rootTypes =
            useMemo(
                () => [
                    types.Relation.Type,
                    types.Relationship.Organization.Type,
                    types.Relationship.Person.Type,
                    types.Activity.Type,
                    types.TimeRegistration.Type,
                    types.MileageRegistration.Type,
                    types.CustomEntity.Type,
                    types.Product.Type,
                    types.ProductLine.Type,
                    types.Involved.Type,
                    types.EventParticipation.Type,
                    types.Milestone.Type,
                    types.Label.Type,
                    types.EmailClick.Type,
                    types.Attachment.Type,
                    types.Form.Type,
                    types.Note.Type,
                    types.ArchivingEvent.Type,
                    types.PriceList.Type,
                    types.CourseLesson.Type,
                    types.Datastore.Type,
                    types.LeexiCall.Type
                ].filter(type => type !== undefined),
                [
                    types
                ]);
        const employeePlannerWidget =
            useMemo(
                () =>
                    new EmployeePlannerWidget(uuid()),
                []);
        const employeeAllocationWidget =
            useMemo(
                () =>
                    new EmployeeAllocationWidget(uuid()),
                []);

        return <ExpansionGroup>
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={dividerGlue}
            >
                {
                    rootTypes
                        .map(
                            type =>
                                <ViewGroupItem
                                    key={type.id}
                                >
                                    <ExpansionPanel
                                        id={`Type.${type.id}`}
                                        summary={
                                            <Header
                                                title={type.getName(true)}
                                                large
                                                inset
                                            />
                                        }
                                        expansion={
                                            <CardInset
                                                vertical={false}
                                            >
                                                <TypeBrowser
                                                    entityType={type}
                                                    onAdd={props.onAdd}
                                                    onClose={props.onClose}
                                                />
                                            </CardInset>
                                        }
                                    />
                                </ViewGroupItem>)
                }
                <ViewGroupItem>
                    <ExpansionPanel
                        id="AgendaAndCalendar"
                        summary={
                            <Header
                                title={
                                    <LocalizedText
                                        code="Dashboard.AgendaAndCalendar"
                                        value="Agenda & kalender"
                                    />
                                }
                                large
                                inset
                            />
                        }
                        expansion={
                            <ViewGroup
                                orientation="vertical"
                                spacing={0}
                                glue={dividerGlue}
                            >
                                <ViewGroupItem>
                                    <WidgetAdder
                                        name={
                                            <LocalizedText
                                                code="Generic.Agenda"
                                                value="Agenda"
                                            />
                                        }
                                        onAdd={() => props.onAdd(new AgendaWidget(uuid()))}
                                    >
                                        <Agenda
                                            widget={agendaWidget}
                                            onAdd={() => props.onAdd(new AgendaWidget(uuid()))}
                                            onDelete={onDelete}
                                        />
                                    </WidgetAdder>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <WidgetAdder
                                        name={
                                            <LocalizedText
                                                code="Generic.FullScreenAgenda"
                                                value="Agenda (volledig scherm)"
                                            />
                                        }
                                        onAdd={() => props.onAdd(new FullscreenAgendaWidgetModel(uuid()))}
                                    >
                                        <FullscreenAgendaWidget
                                            widget={fullScreenAgendaWidget}
                                            onAdd={() => props.onAdd(new FullscreenAgendaWidgetModel(uuid()))}
                                            onDelete={onDelete}
                                        />
                                    </WidgetAdder>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <WidgetAdder
                                        name={
                                            <LocalizedText
                                                code="Generic.Calendar"
                                                value="Kalender"
                                            />
                                        }
                                        onAdd={() => props.onAdd(new CalendarWidget(uuid()))}
                                    >
                                        <Calendar
                                            widget={calendarWidget}
                                            onAdd={() => props.onAdd(new CalendarWidget(uuid()))}
                                            onDelete={onDelete}
                                        />
                                    </WidgetAdder>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <WidgetAdder
                                        name={
                                            <LocalizedText
                                                code="ResourcePlanner.EmployeePlanning"
                                                value="Medewerker planning"
                                            />
                                        }
                                        onAdd={() => props.onAdd(new EmployeePlannerWidget(uuid()))}
                                    >
                                        <EmployeePlanner
                                            widget={employeePlannerWidget}
                                            onAdd={() => props.onAdd(employeePlannerWidget)}
                                            onDelete={onDelete}
                                        />
                                    </WidgetAdder>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <WidgetAdder
                                        name={
                                            <LocalizedText
                                                code="EmployeeAllocation"
                                                value="Medewerker bezetting"
                                            />
                                        }
                                        onAdd={() => props.onAdd(new EmployeeAllocationWidget(uuid()))}
                                    >
                                        <EmployeeAllocation
                                            widget={employeeAllocationWidget}
                                            onAdd={() => props.onAdd(employeeAllocationWidget)}
                                            onDelete={onDelete}
                                        />
                                    </WidgetAdder>
                                </ViewGroupItem>
                            </ViewGroup>
                        }
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <ExpansionPanel
                        id="CustomWidgets"
                        summary={
                            <Header
                                title={types.CustomWidget.Type.getName(true)}
                                large
                                inset
                            />
                        }
                        expansion={
                            <CardInset
                                vertical={false}
                            >
                                <CustomWidgetBrowser
                                    onAdd={props.onAdd}
                                />
                            </CardInset>
                        }
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <ExpansionPanel
                        id="Other"
                        summary={
                            <Header
                                title={
                                    <LocalizedText
                                        code="Generic.Other"
                                        value="Overig"
                                    />
                                }
                                large
                                inset
                            />
                        }
                        expansion={
                            <ViewGroup
                                orientation="vertical"
                                spacing={0}
                                glue={dividerGlue}
                            >
                                <ViewGroupItem>
                                    <WidgetAdder
                                        name={
                                            <LocalizedText
                                                code="Generic.Website"
                                                value="Website"
                                            />
                                        }
                                        onAdd={() => props.onAdd(websiteWidget)}
                                    >
                                        <Website
                                            widget={websiteWidget}
                                            onAdd={() => props.onAdd(websiteWidget)}
                                            onDelete={onDelete}
                                        />
                                    </WidgetAdder>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <WidgetAdder
                                        name={
                                            <LocalizedText
                                                code="Generic.File"
                                                value="Bestand"
                                            />
                                        }
                                        onAdd={() => props.onAdd(fileWidget)}
                                    >
                                        <File
                                            widget={fileWidget}
                                            onAdd={() => props.onAdd(fileWidget)}
                                            onDelete={onDelete}
                                        />
                                    </WidgetAdder>
                                </ViewGroupItem>
                            </ViewGroup>
                        }
                    />
                </ViewGroupItem>
            </ViewGroup>
        </ExpansionGroup>;
    };

export default observer(WidgetBrowser);
