import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import FilteredCollectionComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/FilteredCollectionComputation';
import Predicate from '../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import uuid from '../../../../../../../../../../@Util/Id/uuid';
import FunctionContext from '../../../../../../../../../../@Api/Automation/Function/FunctionContext';
import PredicateEditor from '../../../Predicate/PredicateEditor';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationBox from '../../Box/ComputationBox';

export interface FilteredCollectionComputationEditorProps extends ComputationEditorProps<ValueType<any>, FilteredCollectionComputation>
{

}

const FilteredCollectionComputationEditor: React.FC<FilteredCollectionComputationEditorProps> =
    ({
        value,
        context,
     }) =>
    {
        const setCollection =
            useCallback(
                (collection?: Computation<any, any>) =>
                    runInAction(
                        () =>
                        {
                            value.collection = collection;
                            value.elementParameter =
                                FilteredCollectionComputation.getElementParameter(
                                    value.collection,
                                    value.elementParameter?.id || uuid()
                                );
                        }),
                [
                    value
                ]
            );
        const filterContext =
            useComputed(
                () =>
                    new FunctionContext(
                        context.parameterDictionary.getNewDictionaryWithParameter(value.elementParameter),
                        context.parameterAssignment,
                        context.commitContext
                    ),
                [
                    context,
                    value
                ]
            );
        const setFilter =
            useCallback(
                (filter?: Predicate) =>
                    runInAction(
                        () =>
                            value.filter = filter),
                [
                    value
                ]
            );

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <Input
                label="Collectie"
                labelPosition="left"
            >
                <ComputationBox>
                    <ComputationEditor
                        context={context}
                        value={value.collection}
                        onChange={setCollection}
                    />
                </ComputationBox>
            </Input>
            <Input
                label="Filter"
                labelPosition="left"
            >
                <ComputationBox>
                    <PredicateEditor
                        context={filterContext}
                        value={value.filter}
                        onChange={setFilter}
                    />
                </ComputationBox>
            </Input>
        </ViewGroup>;
    };

export default observer(FilteredCollectionComputationEditor);
