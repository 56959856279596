import RelationshipFieldMapping from '../Relationship/RelationshipFieldMapping';
import SourceFieldMapping from '../SourceField/SourceFieldMapping';
import ValueFieldMapping from '../Constant/ValueFieldMapping';
import TypeReferenceFieldMapping from '../Relationship/TypeReferenceFieldMapping';
import ValueToEntitySourceFieldMapping from '../ValueToEntity/ValueToEntitySourceFieldMapping';
import EntityFieldMapping from '../Constant/EntityFieldMapping';
import ValueToValueSourceFieldMapping from '../ValueToValue/ValueToValueSourceFieldMapping';
import ExtractFirstNameSourceFieldMapping from '../SourceField/ExtractFirstNameSourceFieldMapping';
import ExtractMiddleNameSourceFieldMapping from '../SourceField/ExtractMiddleNameSourceFieldMapping';
import ExtractLastNameSourceFieldMapping from '../SourceField/ExtractLastNameSourceFieldMapping';
import ExtractStreetNameSourceFieldMapping from '../SourceField/ExtractStreetNameSourceFieldMapping';
import ExtractHouseNumberSourceFieldMapping from '../SourceField/ExtractHouseNumberSourceFieldMapping';
import ExtractHouseNumberSuffixSourceFieldMapping from '../SourceField/ExtractHouseNumberSuffixSourceFieldMapping';
import DateSourceFieldMapping from '../SourceField/DateSourceFieldMapping';
import ComputationFieldMapping from '../Computation/ComputationFieldMapping';
import AutomationDependencyContext from '../../../../../../../@Api/Automation/AutomationDependencyContext';
import TextToHtmlSourceFieldMapping from '../SourceField/TextToHtmlSourceFieldMapping';
import FileFromBase64SourceFieldMapping from '../SourceField/FileFromBase64SourceFieldMapping';

export default async function resolveFieldMapping(
    descriptor: any,
    dependencyContext: AutomationDependencyContext,
)
{
    switch (descriptor.type)
    {
        case 'SourceField':
            return SourceFieldMapping.fromDescriptor(descriptor);

        case 'SourceField.Date':
            return DateSourceFieldMapping.fromDescriptor(descriptor);

        case 'SourceField.ExtractFirstName':
            return ExtractFirstNameSourceFieldMapping.fromDescriptor(descriptor);

        case 'SourceField.ExtractMiddleName':
            return ExtractMiddleNameSourceFieldMapping.fromDescriptor(descriptor);

        case 'SourceField.ExtractLastName':
            return ExtractLastNameSourceFieldMapping.fromDescriptor(descriptor);

        case 'SourceField.ExtractStreetName':
            return ExtractStreetNameSourceFieldMapping.fromDescriptor(descriptor);

        case 'SourceField.ExtractHouseNumber':
            return ExtractHouseNumberSourceFieldMapping.fromDescriptor(descriptor);

        case 'SourceField.ExtractHouseNumberSuffix':
            return ExtractHouseNumberSuffixSourceFieldMapping.fromDescriptor(descriptor);

        case 'SourceField.TextToHtml':
            return TextToHtmlSourceFieldMapping.fromDescriptor(descriptor);

        case 'SourceField.FileFromBase64':
            return FileFromBase64SourceFieldMapping.fromDescriptor(descriptor);

        case 'ValueToEntitySourceField':
            return ValueToEntitySourceFieldMapping.fromDescriptor(descriptor);

        case 'ValueToValueSourceField':
            return ValueToValueSourceFieldMapping.fromDescriptor(descriptor);

        case 'Relationship':
            return RelationshipFieldMapping.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'Entity':
            return EntityFieldMapping.fromDescriptor(descriptor);

        case 'Value':
            return ValueFieldMapping.fromDescriptor(descriptor);

        case 'TypeReference':
            return TypeReferenceFieldMapping.fromDescriptor(descriptor);

        case 'Computation':
            return ComputationFieldMapping.fromDescriptor(
                descriptor,
                dependencyContext
            );
    }
}
