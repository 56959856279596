import * as React from 'react';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { GlobalEnvironment } from '../../../../@Global/GlobalEnvironment';
import CspCompliantRichTextIframe from './CspCompliantRichTextIframe';
import NonCspCompliantRichTextIframe from './NonCspCompliantRichTextIframe';

export interface RichTextIframeProps
{
    value: string;
    allowScrolling?: boolean;
}

const RichTextIframe: React.FC<RichTextIframeProps> =
    props =>
    {
        const valueWithAHrefInNewWindow =
            useMemo(
                () =>
                    (props.value || '').replace(/<a/g, '<a target="_blank"'),
                [
                    props.value
                ]
            );

        if (GlobalEnvironment.HTML_VIEWER_ENDPOINT)
        {
            return <CspCompliantRichTextIframe
                {...props}
                value={valueWithAHrefInNewWindow}
            />;
        }
        else
        {
            return <NonCspCompliantRichTextIframe
                {...props}
                value={valueWithAHrefInNewWindow}
            />;
        }
    };

export default observer(RichTextIframe);

