import { EntityRelationshipDefinition } from '../../../Model/Implementation/EntityRelationshipDefinition';
import { EntityType } from '../../../Model/Implementation/EntityType';
import getRelationshipDefinitionFilterDescriptorFromSetting from './getRelationshipDefinitionFilterDescriptorFromSetting';
import getRelationshipDefinitionFilterDescriptorFromEntity from './getRelationshipDefinitionFilterDescriptorFromEntity';

export default function getRelationshipDefinitionFilterDescriptor(entityType: EntityType,
                                                                  relationshipDefinition: EntityRelationshipDefinition,
                                                                  isParent: boolean): any | undefined
{


    return getRelationshipDefinitionFilterDescriptorFromSetting(entityType, relationshipDefinition, isParent)
        || getRelationshipDefinitionFilterDescriptorFromEntity(relationshipDefinition, isParent);
}
