import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import ComputedImageLayoutViewer from './ComputedImageLayoutViewer';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import ComputedImageLayout from '../../../../../@Api/Layout/Type/ComputedImageLayout';
import styles from './ComputedImageLayoutEditor.module.scss';

export interface ComputedImageLayoutEditorProps extends LayoutEditorProps<ComputedImageLayout>
{

}

const ComputedImageLayoutEditor: React.FC<ComputedImageLayoutEditorProps> =
    props =>
    {
        const parameterAssignment =
            useMemo(
                () =>
                    props.parameterAssignment || new ParameterAssignment(),

                [
                    props.parameterAssignment
                ]);

        return <div
            className={styles.root}
        >
            <ComputedImageLayoutViewer
                {...props}
                parameterAssignment={parameterAssignment}
            />
        </div>;
    };

export default observer(ComputedImageLayoutEditor);
