import { BespokeEntityType, SummaryField } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { observable } from 'mobx';
import { BespokeRelationshipPerson } from './BespokeRelationshipPerson';
import { BespokeRelationshipOrganization } from './BespokeRelationshipOrganization';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { TextStore } from '../../../../Generic/Text/TextStore';
import { typographyEntityName } from '../../../../../@Resource/Theme/Typography';
import { textColor } from '../../../../../@Resource/Theme/Theme';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { DataObjectRepresentation } from '../../../DataObject/Model/DataObjectRepresentation';
import { catchImport } from '../../../../../@Util/Import/catchImport';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import { EntityField } from '../../../../../@Api/Model/Implementation/EntityField';
import equalsEntity from '../../../../../@Api/Entity/Bespoke/equalsEntity';
import { EmailType } from '../../../DataObject/Type/Text/Email/EmailType';
import { PhoneNumberType } from '../../../DataObject/Type/Text/PhoneNumber/PhoneNumberType';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import getSetting from '../../../Setting/Api/getSetting';
import { SettingSource } from '../../../Setting/SettingStore';
import { Setting } from '../../../../../@Api/Settings/Setting';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';

export class BespokeRelationship extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable person: BespokeRelationshipPerson;
    @observable organization: BespokeRelationshipOrganization;

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Relationship')
    {
        super(entityTypeStore, code);

        this.person = new BespokeRelationshipPerson(this.entityTypeStore);
        this.organization = new BespokeRelationshipOrganization(this.entityTypeStore);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    onConstruct(entity: Entity,
                commitContext?: CommitContext): void
    {
        super.onConstruct(entity, commitContext);

        const types = this.entityTypeStore.bespoke.types;

        this.updateParentRelationFromType(
            entity,
            entity.entityType,
            commitContext
        );

        // Set account manager to the current user by default
        if (entity.isNew()
            && !entity.hasRelationshipsByDefinition(
                false,
                types.Relationship.RelationshipDefinition.AccountManager,
                commitContext)
        )
        {
            updateRelationship(
                entity,
                false,
                types.Relationship.RelationshipDefinition.AccountManager,
                loadModuleDirectly(CurrentUserStore).employeeEntity,
                commitContext
            );
        }
    }

    onValueSet(entity: Entity,
               field: EntityField,
               value: any,
               commitContext?: CommitContext)
    {
        super.onValueSet(entity, field, value, commitContext);

        if (field === this.entityTypeStore.typeField)
        {
            this.updateParentRelationFromType(
                entity,
                value,
                commitContext
            );
        }
    }

    updateParentRelationFromType(entity: Entity,
                                 fromType: EntityType,
                                 commitContext?: CommitContext)
    {
        // In case of non-new entities, do not update the parent relation of the relationship.
        // This leads to issues in case of multiple relationship management (if changing a lead to customer for instance,
        // the parent organization gets reset the the own organization, when it was not under the own organization).
        if (!entity.isNew())
        {
            return;
        }

        const types = this.entityTypeStore.bespoke.types;
        const currentEnvironment = loadModuleDirectly(CurrentUserStore).environmentEntity;

        // In case the relationship is a contact
        if ((fromType.isA(types.Relationship.Person.Contact.Type)
            || types.Relationship.Person.Contact.Type.isA(fromType))
            && !fromType.isA(types.Relationship.Person.Contact.Employee.Type))
        {
            // If the parent relation is the current environment, then clear it
            if (equalsEntity(
                entity.getRelatedEntityByDefinition(true, types.Relation.RelationshipDefinition.Relationships, commitContext),
                currentEnvironment))
            {
                updateRelationship(
                    entity,
                    true,
                    types.Relation.RelationshipDefinition.Relationships,
                    undefined,
                    commitContext
                );
            }
        }
        else
        {
            // Otherwise, always set the parent relation to be the current environment (if it is not yet already)
            if (!equalsEntity(
                entity.getRelatedEntityByDefinition(true, types.Relation.RelationshipDefinition.Relationships, commitContext),
                currentEnvironment))
            {
                updateRelationship(
                    entity,
                    true,
                    types.Relation.RelationshipDefinition.Relationships,
                    currentEnvironment,
                    commitContext
                );
            }
        }
    }

    showListItemsAsEntityCard(pathFromRelatedEntity?: RelatedEntityPath)
    {
        return this.isRelationshipOnRelationViewer(pathFromRelatedEntity);
    }

    showAvatarInEntityCard(entity: Entity,
                           pathFromRelatedEntity?: RelatedEntityPath)
    {
        return !this.isRelationshipViewedFromChild(pathFromRelatedEntity);
    }

    getEntityCardTitle(entity: Entity,
                       pathFromRelatedEntity?: RelatedEntityPath): TextStore
    {
        const owner =
            entity
                .getRelatedEntityByDefinition(
                    true,
                    this.entityTypeStore.bespoke.types.Relation.RelationshipDefinition.Relationships);

        if (this.isRelationshipViewedFromChild(pathFromRelatedEntity) && owner)
        {
            const style =
                {
                    ...typographyEntityName,
                    fontSize: 14
                };

            return new TextStore({
                label:
                    () =>
                        `van %1`,
                isInline: true,
                innerStyle:
                    {
                        ...style,
                        fontWeight: 400
                    },
                childTextStores:
                [
                    new TextStore({
                        label:
                            () =>
                                owner.name,
                        innerStyle: style,
                        color: textColor,
                        onClick:
                            () =>
                                import('../../@Util/openEntity')
                                    .then(
                                        index =>
                                            index.openEntity(
                                                owner,
                                                pathFromRelatedEntity
                                                    ?
                                                        new RelatedEntityPath(
                                                            pathFromRelatedEntity.entity,
                                                            pathFromRelatedEntity.path
                                                                .joinTo(
                                                                    this.entityTypeStore.bespoke.types.Relation.RelationshipDefinition.Relationships,
                                                                    true))
                                                    :
                                                        undefined,
                                                entity))
                                    .catch(catchImport),
                        isInline: true
                    })
                ]
            });
        }
        else
        {
            return undefined;
        }
    }

    showDividerInCard(entity: Entity,
                      pathFromRelatedEntity?: RelatedEntityPath)
    {
        return this.isRelationshipViewedFromChild(pathFromRelatedEntity);
    }

    showTypeSwitcherInCard(entity: Entity,
                           pathFromRelatedEntity?: RelatedEntityPath)
    {
        return this.isRelationshipViewedFromChild(pathFromRelatedEntity);
    }

    getAddPathsInTimeline(entity: Entity, rootPath: EntityPath): RelatedEntityPath[]
    {
        const pathToActivities =
            rootPath.joinTo(
                this.entityTypeStore.bespoke.types.Relationship.RelationshipDefinition.Activities,
                false);

        return [
            new RelatedEntityPath(
                entity,
                pathToActivities.castTo(this.entityTypeStore.bespoke.types.Activity.Task.Type)),
            new RelatedEntityPath(
                entity,
                pathToActivities.castTo(this.entityTypeStore.bespoke.types.Activity.Email.Type)),
            new RelatedEntityPath(
                entity,
                pathToActivities.castTo(this.entityTypeStore.bespoke.types.Activity.Project.Type)),
            new RelatedEntityPath(
                entity,
                pathToActivities.castTo(this.entityTypeStore.bespoke.types.Activity.DigitalSigning.Type))
        ];
    }

    allowIconOrCharactersInAvatar(): boolean
    {
        return true;
    }

    getSummaryFields(entity: Entity): SummaryField[]
    {
        const summaryFields: SummaryField[] = [];

        const relation =
            entity.getRelatedEntityByDefinition(
                false,
                this.entityTypeStore.bespoke.types.Relationship.Organization.RelationshipDefinition.Organization)
                ||
            entity.getRelatedEntityByDefinition(
                false,
                this.entityTypeStore.bespoke.types.Relationship.Person.RelationshipDefinition.Person);

        const website =
            (relation
                && relation.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Organization.Type)
                && EntityPath.fromEntity(relation)
                    .field(this.entityTypeStore.bespoke.types.Relation.Organization.Field.Website)
                    .getDataObjectValue(relation)) || undefined;

        const websiteLink =
            website
                ?
                !website.isEmpty
                    ?
                    website.toString(new DataObjectRepresentation({ isCompact: true }))
                    :
                    undefined
                :
                undefined;

        // Resolve website
        if (websiteLink)
        {
            summaryFields.push({
                id: 'website',
                icon: 'language',
                link: websiteLink,
                title: websiteLink,
                tooltip: undefined,
                value: undefined
            });
        }

        // Resolve e-mail addresses
        const resolveEmailAddressFields =
            (entity: Entity) =>
            {
                entity.entityType.getInheritedFields()
                    .filter(
                        field =>
                            field.dataObjectSpecification.type instanceof EmailType
                            && entity.hasValueForField(field))
                    .forEach(
                        emailField =>
                        {
                            const emailAddress = entity.getObjectValueByField(emailField);

                            summaryFields.push({
                                id: `email.${emailField.id}`,
                                icon: 'mail',
                                link: `mailto:${emailAddress}`,
                                title: emailAddress,
                                tooltip: emailField.getName(entity.entityType),
                                value: entity.getValueByField(emailField)
                            });
                        })
            };

        resolveEmailAddressFields(entity);

        const isContact = entity.entityType.isA(this.types.Relationship.Person.Contact.Type);

        if (relation && !isContact)
        {
            resolveEmailAddressFields(relation);
        }

        // Resolve phone numbers
        const resolvePhoneNumberFields =
            (entity: Entity) =>
            {
                entity.entityType.getInheritedFields()
                    .filter(
                        field =>
                            field.dataObjectSpecification.type instanceof PhoneNumberType
                            && entity.hasValueForField(field))
                    .forEach(
                        phoneField =>
                        {
                            const phoneNumber = entity.getObjectValueByField(phoneField);

                            summaryFields.push({
                                id: `phone.${phoneField.id}`,
                                icon: 'phone',
                                link: `tel:${phoneNumber}`,
                                title: phoneNumber,
                                tooltip: undefined,
                                value: entity.getValueByField(phoneField)
                            });
                        })
            };

        resolvePhoneNumberFields(entity);

        if (relation && !isContact)
        {
            resolvePhoneNumberFields(relation);
        }

        // Resolve addresses
        if (relation && !isContact)
        {
            relation.entityType.getInheritedRelationshipDefinitions(false)
                .filter(
                    relationshipDefinition =>
                        relationshipDefinition.isSingular(false)
                        && relationshipDefinition.getEntityType(false).isA(this.types.Address.Type))
                .forEach(
                    addressRspDef =>
                    {
                        const address = relation.getRelatedEntityByDefinition(false, addressRspDef);

                        if (address)
                        {
                            const addressLine = this.entityTypeStore.bespoke.types.Address.Functions.getAddressLine(address);

                            summaryFields.push({
                                id: `location.${address.uuid}`,
                                icon: 'location_on',
                                link: `https://www.google.com/maps/place/${encodeURIComponent(addressLine)}`,
                                title: addressLine,
                                tooltip: addressRspDef.getName(false, entity.entityType),
                                value: undefined,
                                entity: address,
                            });
                        }
                    });
        }

        return summaryFields;
    }

    isOpenableFromSelectbox(): boolean
    {
        return true;
    }

    isFormerLink(entity: Entity): boolean
    {
        return entity.getObjectValueByField(this.types.Relationship.Field.IsFormer);
    }

    getUserFriendlyEntityType()
    {
        return this.entityTypeStore.bespoke.types.Relation.Type;
    }

    hasResourceAvatar(entity: Entity): boolean
    {
        return true;
    }

    isAssignableToTeam(): boolean
    {
        if (this.entityTypeStore.currentUserStore.currentTeam)
        {
            return !this.entityTypeStore.currentUserStore.currentTeam.getObjectValueByField(
                this.types.Team.Field.HasAccessToNonTeamRelationships
            );
        }
        else
        {
            return true;
        }
    }

    hideFieldPath(entity: Entity,
                  fieldPath: EntityFieldPath,
                  fromFieldPath?: EntityFieldPath,
                  isInConstructor?: boolean,
                  commitContext?: CommitContext): boolean
    {
        return super.hideFieldPath(entity, fieldPath, fromFieldPath, isInConstructor, commitContext)
            || (fieldPath.relationshipDefinition === this.types.Relationship.RelationshipDefinition.ChildAccounts
                && fieldPath.isParentRelationship
                && entity.getObjectValueByField(this.types.Relationship.Field.IsMasterAccount))
            || (fieldPath.field === this.types.Relationship.Field.IsMasterAccount
                && entity.hasRelationshipsByDefinition(true, this.types.Relationship.RelationshipDefinition.ChildAccounts))
            || (this.types.Relationship.RelationshipDefinition.PriceList
                && fieldPath.relationshipDefinition === this.types.Relationship.RelationshipDefinition.PriceList
                && !fieldPath.isParentRelationship
                && !getSetting<boolean>(SettingSource.Organization, Setting.Sales.IsPriceListInRelationshipEnabled));
    }

    extendRelationshipSelection(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        builder: EntitySelectionBuilder,
        commitContext?: CommitContext)
    {
        super.extendRelationshipSelection(
            entity,
            relationshipDefinition,
            isParent,
            builder,
            commitContext
        );

        const types = this.types;

        if (
            relationshipDefinition === types.Relationship.RelationshipDefinition.ChildAccounts
            && isParent
        )
        {
            builder
                .where(
                    cb =>
                        cb.eq(
                            builder.rootPath.field(types.Relationship.Field.IsMasterAccount),
                            undefined,
                            true
                        )
                );
        }
    }

    attachmentEntitySelectionBuilder(
        sb: EntitySelectionBuilder,
        entity: Entity,
        commitContext?: CommitContext
    ) : EntitySelectionBuilder
    {
        //Add Attachments => Activity => Relationship:Activity
        if (!entity.isNew())
        {
            return sb
                .where(
                ws =>
                    ws.or(
                        wo =>
                            wo.relatedToEntity(
                                EntityPath
                                    .fromEntityType(this.types.Attachment.Type)
                                    .joinTo(
                                        this.types.Entity.RelationshipDefinition.Attachments,
                                        true
                                    ),
                                entity
                            )
                            .relatedToEntity(
                                EntityPath
                                    .fromEntityType(this.types.Attachment.Type)
                                    .joinTo(
                                        this.types.Entity.RelationshipDefinition.Attachments,
                                        true
                                    )
                                    .joinTo(
                                        this.types.Relationship.RelationshipDefinition.Activities,
                                    true
                                    ),
                                entity
                            )
                    )
                );
        }
        else
        {
            return sb;
        }
    }

    // ----------------------- Private logic ------------------------

    private isRelationshipOnRelationViewer(pathFromRelatedEntity?: RelatedEntityPath)
    {
        return this.isRelationshipViewedFromParent(pathFromRelatedEntity)
            || this.isRelationshipViewedFromChild(pathFromRelatedEntity);
    }

    private isRelationshipViewedFromParent(pathFromRelatedEntity?: RelatedEntityPath)
    {
        return pathFromRelatedEntity
            && pathFromRelatedEntity.path.lastJoinNode
            && !pathFromRelatedEntity.path.lastJoinNode.isParent
            && pathFromRelatedEntity.path.lastJoinNode.relationshipDefinition === this.entityTypeStore.bespoke.types.Relation.RelationshipDefinition.Relationships;
    }

    private isRelationshipViewedFromChild(pathFromRelatedEntity?: RelatedEntityPath)
    {
        return pathFromRelatedEntity
            && pathFromRelatedEntity.path.lastJoinNode
            && pathFromRelatedEntity.path.lastJoinNode.isParent
            && (pathFromRelatedEntity.path.lastJoinNode.relationshipDefinition === this.entityTypeStore.bespoke.types.Relationship.Person.RelationshipDefinition.Person
                || pathFromRelatedEntity.path.lastJoinNode.relationshipDefinition === this.entityTypeStore.bespoke.types.Relationship.Organization.RelationshipDefinition.Organization);
    }
}
