import React from 'react';
import { observer } from 'mobx-react';
import { default as ColumnModel } from '../Model/Column';
import { Droppable } from 'react-beautiful-dnd';
import List from './List/List';
import styles from './Column.module.scss';

export interface ColumnProps
{
    column: ColumnModel;
    firstColumn?: boolean;
    lastColumn?: boolean;
    lastSection?: boolean;
    disabled?: boolean;
}

const Column: React.FC<ColumnProps> =
    props =>
    {
        return <Droppable
            droppableId={props.column.id}
            type="widget"
        >
            {(provided, snapshot) =>
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={styles.root}
                >
                    <List
                        column={props.column}
                        provided={provided}
                        firstColumn={props.firstColumn}
                        lastColumn={props.lastColumn}
                        lastSection={props.lastSection}
                        disabled={props.disabled}
                    />
                </div>}
        </Droppable>;
    };

export default observer(Column);
