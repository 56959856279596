import * as React from 'react';
import { PredicateType } from '../PredicateType';
import { NotPredicateEditorStore } from './NotPredicateEditorStore';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { NotPredicateEditor } from './NotPredicateEditor';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { NotPredicateSpecification } from './NotPredicateSpecification';
import { PredicateContext } from '../../PredicateContext';
import { PredicateTypeStore } from '../../PredicateTypeStore';
import { PredicateEditorStore } from '../../PredicateEditorStore';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';
import { EntityContext } from '../../../Entity/@Model/EntityContext';
import { OldNotPredicate } from './OldNotPredicate';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';

export class NotPredicateType extends PredicateType<NotPredicateEditorStore, NotPredicateSpecification, OldNotPredicate>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('PredicateTypeStore') predicateTypeStore: PredicateTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return 'Not';
    }

    name(): string
    {
        return this.localizationStore.translate('NotPredicateType.Name'); // Not
    }

    allow(context: PredicateContext): boolean
    {
        return true;
    }

    isTerminal(): boolean
    {
        return false;
    }

    fromSpecification(specification: NotPredicateSpecification): OldNotPredicate
    {
        return new OldNotPredicate(
            this.predicateTypeStore.fromSpecification(specification.predicate));
    }

    toSpecification(predicate: OldNotPredicate): NotPredicateSpecification
    {
        return {
            type: this.id(),
            predicate: this.predicateTypeStore.toSpecification(predicate.predicate)
        };
    }

    evaluate(context: PredicateContext,
             predicate: OldNotPredicate): boolean
    {
        if (predicate.predicate)
        {
            return !predicate.predicate.evaluate(context);
        }
        else
        {
            return false;
        }
    }

    editorStore(context: PredicateContext,
                specification: NotPredicateSpecification): NotPredicateEditorStore
    {
        return new NotPredicateEditorStore(
            this as any,
            context,
            specification,
            PredicateEditorStore.construct(
                context,
                specification.predicate,
                this.predicateTypeStore));
    }

    editorView(): React.ComponentClass<BaseComponentProps<NotPredicateEditorStore>>
    {
        return NotPredicateEditor;
    }

    editorSpecification(store: NotPredicateEditorStore): NotPredicateSpecification
    {
        return {
            type: this.id(),
            predicate: store.predicateStore?.type.editorSpecification(store.predicateStore?.editorStore)
        };
    }

    entityFieldPaths(specification: NotPredicateSpecification,
                     context: EntityContext,
                     parameter?: string): EntityFieldPath[]
    {
        let paths: EntityFieldPath[] = [];

        if (specification.predicate)
        {
            let predicateType = this.predicateTypeStore.getTypeById(specification.predicate.type);

            if (predicateType)
            {
                paths.push(
                    ...predicateType.entityFieldPaths(
                        specification.predicate,
                        context,
                        parameter));
            }
        }

        return paths;
    }

    // ----------------------- Private logic ------------------------
}
