import { enumerated, reference } from '../../../@Util/Serialization/Serialization';
import { ConstraintNode } from './ConstraintNode';
import { observable } from 'mobx';
import { Entity } from './Entity';
import { EntityEvent } from './EntityEvent';
import { EntityNode } from './EntityNode';

export enum LogicalOperator { And, Or }

export class CompositeConstraintNode extends ConstraintNode
{
    // ------------------- Persistent Properties --------------------

    @observable @enumerated(LogicalOperator, 'LogicalOperator') logicalOperator: LogicalOperator;
    @reference(undefined, 'ConstraintNode') @observable.shallow childNodes: ConstraintNode[];

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(logicalOperator: LogicalOperator, childNodes: ConstraintNode[] = [])
    {
        super(); // [GC] TODO implement super constructor

        this.logicalOperator = logicalOperator;
        this.childNodes = childNodes;

        return this;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getDependencies(): EntityNode[]
    {
        return [
            ...super.getDependencies(),
            ...this.childNodes.flatMap(
                childNode =>
                    childNode.getDependencies()
            ),
        ];
    }

    hashCode(): string
    {
        return `${super.hashCode()},${this.childNodes.map(node => node.hashCode()).join(',')}`;
    }

    equals(node: CompositeConstraintNode): boolean
    {
        return super.equals(node)
            && this.logicalOperator === node.logicalOperator
            && this.childNodes.length === node.childNodes.length
            && this.childNodes.every((childNode, idx) => childNode.equals(node.childNodes[idx]));
    }

    matches(entity: Entity): boolean
    {
        switch (this.logicalOperator)
        {
            case LogicalOperator.And:
                return this.childNodes.every(node => node.matches(entity));

            case LogicalOperator.Or:
                return this.childNodes.some(node => node.matches(entity));
        }

        return false;
    }

    isAffectedBy(event: EntityEvent): boolean
    {
        return this.childNodes
            .some(
                node =>
                    node.isAffectedBy(event));
    }

    addNodes(nodes: ConstraintNode[]): CompositeConstraintNode
    {
        nodes.forEach(
            node =>
               this.addNode(node));

        return this;
    }

    addNode(node: ConstraintNode): CompositeConstraintNode
    {
         this.childNodes.push(node);

         return this;
    }

    descriptor()
    {
        return {
            type: 'Composite',
            logicalOperator: LogicalOperator[this.logicalOperator],
            childNodes: this.childNodes.map(node => (node.descriptor()))
        };
    }

    // ----------------------- Private logic ------------------------
}
