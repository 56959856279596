import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import ComputationLayout from '../../../../../@Api/Layout/Type/ComputationLayout';
import ComputationEditor from '../../../Entity/Viewer/Content/Automation/Editor/Computation/ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';

export interface ComputationLayoutEditorProps extends LayoutEditorProps<ComputationLayout>
{

}

const ComputationLayoutEditor: React.FC<ComputationLayoutEditorProps> =
    props =>
    {
        const setComputation =
            useCallback(
                (computation: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.layout.computation = computation),
                [
                    props.layout
                ]);

        const context =
            useComputed(
                () =>
                    new FunctionContext(
                        props.parameterDictionary,
                        props.parameterAssignment,
                        props.commitContext
                    ),
                [
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        return <ComputationEditor
            onChange={setComputation}
            context={context}
            value={props.layout.computation}
            fixedType
        />;
    };

export default observer(ComputationLayoutEditor);
