import React, { MouseEventHandler, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { InputActionMeta, OptionsType } from 'react-select/src/types';
import { FormatOptionLabelMeta } from 'react-select/base';
import { dividerColor, primaryColor, primaryFontFamily, primaryFontSize, textColor } from '../../../../../@Resource/Theme/Theme';
import { grey } from '@material-ui/core/colors';
import styles from './Selectbox.module.scss';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { StylesConfig } from 'react-select/src/styles';
import { classNames } from '../../../../Util/Class/classNames';
import { observer } from 'mobx-react-lite';
import uuid from '../../../../../@Util/Id/uuid';
import closest from '../../../../../@Util/DOM/closest';
import useDebouncedCallback from '../../../../../@Util/Debounce/useDebouncedCallback';
import useHorizontalMenuAdjustment from './Api/useHorizontalMenuAdjustment';
import { useIsMounted } from '../../../../../@Util/Async/useIsMounted';
import Dialog from '../../Dialog/Dialog';
import { Box } from '@material-ui/core';
import { FocusField } from '../../FocusField/FocusField';
import useIsMobile from '../../../../../@Util/Responsiveness/useIsMobile';
import LocalizedText from '../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';
import PrimaryButton from '../../Button/Variant/PrimaryButton/PrimaryButton';
import { InputLabelContext } from '../Input/LabelContext/InputLabelContext';
import DialogTitle from '../../Dialog/Title/DialogTitle';

export type SelectionValue<D> = D | ReadonlyArray<D>;
export type OptionFormatter<D> = (data: D, metadata: OptionMetadata<D>) => React.ReactNode;
export type CreateFormatter<D> = (data: string, metadata: OptionMetadata<D>) => React.ReactNode;
export type OptionDisabledCallback<D> = (data: D, options: OptionsType<D>) => boolean | false;
export type OptionMetadata<D> = { context: 'menu' | 'value', search: string, selected: SelectionValue<D>}
export type IdResolver<D> = (data: D) => string;

export interface SelectboxProps<D>
{
    load: (query: string) => Promise<D[]>;
    onChange: (value: SelectionValue<D>) => any;
    onCreate?: (value: string) => void;
    formatOption: OptionFormatter<D>;
    formatValue?: OptionFormatter<D>;
    formatCreate?: CreateFormatter<D>;
    isOptionDisabled?: OptionDisabledCallback<D>;
    value?: SelectionValue<D>;
    idResolver: IdResolver<D>;
    multi?: boolean;
    clearable?: boolean;
    placeholder?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    disableUnderline?: boolean;
    alignRight?: boolean;
    outlined?: boolean;
    selectWidthBehavior?: string; // [DD]: unknown what this is for
    defaultInputValue?: string;
    inputValue?: string;
    onInputChange?: (input: string, action: InputActionMeta) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    open?: boolean;
    onOpen?: () => void;
    openKey?: string;
    onClose?: () => void;
}

function getRawOptionValue(option: any)
{
    if (typeof option === 'object')
    {
        const keys = Object.keys(option);

        if (keys.length === 2 && keys.includes('label') && keys.includes('value'))
        {
            return option.value;
        }
    }

    return option;
}

const Selectbox: React.FC<SelectboxProps<any>> =
    props =>
    {
        const { load, onOpen, onClose, onChange, onCreate, onInputChange, onFocus, onBlur, formatOption, isOptionDisabled, formatValue, formatCreate, openKey } = props;

        const isMounted = useIsMounted();
        const [ input, setInput ] = useState('');
        const ref = useRef();
        const [ menuRef, setMenuRef ] = useState<HTMLDivElement>();
        useHorizontalMenuAdjustment(menuRef);

        useEffect(
            () =>
            {
                if (props.defaultInputValue)
                {
                    setInput(props.defaultInputValue);
                }
            },
            [
                props.defaultInputValue,
                setInput
            ]);

        const [ isOpen, setOpen ] = useState(false);

        useEffect(
            () =>
            {
                if (props.open !== undefined)
                {
                    setOpen(props.open);
                }
            },
            [
                props.open,
                setOpen
            ]);

        const value =
            useMemo(
                () =>
                {
                    if (props.value === undefined)
                    {
                        return null;
                    }
                    else if (Array.isArray(props.value))
                    {
                        return props.value.map(
                            value =>
                                ({
                                    label: value,
                                    value: value
                                }));
                    }
                    else
                    {
                        return ({
                            label: props.value,
                            value: props.value
                        });
                    }
                },
                [
                    props.value
                ]);

        const onMenuOpen =
            useCallback(
                () =>
                {
                    setOpen(true);

                    if (onOpen)
                    {
                        onOpen();
                    }

                    setTimeout(
                        () =>
                        {
                            if (ref.current)
                            {
                                setMenuRef((ref.current as any).select?.menuListRef?.parentElement);
                            }
                        },
                        50);
                },
                [
                    setOpen,
                    onOpen,
                    ref,
                    setMenuRef
                ]);

        const onMenuClose =
            useCallback(
                () =>
                {
                    setOpen(false);

                    if (onClose)
                    {
                        onClose();
                    }

                    setMenuRef(undefined);
                },
                [
                    setOpen,
                    onClose,
                    setMenuRef
                ]);

        const onSelectionChange =
            useCallback(
                (value: any) =>
                {
                    if (value == null)
                    {
                        return onChange(props.multi ? [] : undefined);
                    }
                    else
                    {
                        if (props.multi)
                        {
                            return onChange(
                                value.map(
                                    value =>
                                    {
                                        if (typeof value === 'object'
                                            && value.label !== undefined)
                                        {
                                            return value.value;
                                        }
                                        else
                                        {
                                            return value;
                                        }
                                    }));
                        }
                        else
                        {
                            return onChange(value);
                        }
                    }
                },
                [
                    onChange,
                    props.multi
                ]);

        const formatOptionLabel =
            useCallback(
                (option: any, metadata: FormatOptionLabelMeta<any>) =>
                {
                    if ((option as any).__isNew__)
                    {
                        return formatCreate(
                            option.label,
                            {
                                context: metadata.context,
                                search: metadata.inputValue,
                                selected: metadata.selectValue
                            });
                    }
                    else
                    {
                        switch (metadata.context)
                        {
                            case 'menu':
                                const formattedOption =
                                    formatOption(
                                        option,
                                        {
                                            context: metadata.context,
                                            search: metadata.inputValue,
                                            selected: metadata.selectValue
                                        });

                                if (typeof formattedOption === 'string')
                                {
                                    return <div
                                        className={styles.option}
                                    >
                                        <div
                                            className={styles.stringOption}
                                        >
                                            {formattedOption}
                                        </div>
                                    </div>;
                                }
                                else
                                {
                                    return <div
                                        className={styles.option}
                                    >
                                        {formattedOption}
                                    </div>;
                                }

                            case 'value':
                                if (option.value === undefined)
                                {
                                    return '';
                                }

                                return (formatValue || formatOption)(
                                    option.value,
                                    {
                                        context: metadata.context,
                                        search: metadata.inputValue,
                                        selected: metadata.selectValue
                                    });
                        }


                    }

                    return undefined;
                },
                [
                    formatCreate,
                    formatOption,
                    formatValue
                ]);

        const hasValue =
            useMemo(
                () =>
                {
                    if (props.value)
                    {
                        if (Array.isArray(props.value))
                        {
                            return props.value.length > 0;
                        }
                        else
                        {
                            return true;
                        }
                    }
                    else
                    {
                        return false;
                    }
                },
                [
                    props.value
                ]);

        const styling =
            useMemo<StylesConfig>(
                () =>
                    props.outlined
                        ?
                            ({
                                control:
                                    (base: any, state: any) =>
                                        ({
                                            ...base,
                                            backgroundColor: 'transparent',
                                            borderColor: state.isFocused ? primaryColor : 'rgb(190,190,190)',
                                            '&:hover':
                                            {
                                                borderColor: primaryColor
                                            },
                                            borderWidth: props.outlined ? 1 : 0,
                                            boxShadow: state.isFocused ? `0 0 0 1px ${primaryColor}` : undefined
                                        }),
                                input:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            margin: 0,
                                            textAlign: props.alignRight ? 'right' : undefined,
                                            fontSize: primaryFontSize,
                                            fontFamily: primaryFontFamily
                                        }),
                                indicatorSeparator:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            display: 'none',
                                            margin: 0
                                        }),
                                dropdownIndicator:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            padding: '5px 8px'
                                        }),
                                placeholder:
                                    (base: any) => ({
                                        ...base,
                                        color: 'rgba(0,0,0,0.35)'
                                    }),
                                menu:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            width: 'auto',
                                            minWidth: '100%'
                                        }),
                                menuPortal:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            // higher than popper zIndex (see share with colleagues popper in dashboard)
                                            zIndex: 2
                                        }),
                                option:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            padding: '0 10px'
                                        }),
                                singleValue:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            color: 'inherit', // inherit color from parent component
                                            marginLeft: 15,
                                            textAlign: props.alignRight ? 'right' : undefined,
                                            fontFamily: primaryFontFamily,
                                            fontSize: primaryFontSize,
                                            position: props.selectWidthBehavior === 'full' ? 'absolute' : 'relative',
                                            alignSelf: props.selectWidthBehavior === 'full' ? undefined : 'center',
                                            overflow: props.selectWidthBehavior === 'full' ? undefined : 'none',
                                            top: '0%',
                                            transform: 'translateY(0%)',
                                            marginRight: 0,
                                            width: hasValue ? '100%' : undefined,
                                            maxWidth: '100%' // by default this is calc(100% - 8px) but this leads to unnecessary ellipsis
                                        }),
                                valueContainer:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            maxHeight: 'none',
                                            cursor: 'text',
                                            fontFamily: primaryFontFamily,
                                            fontSize: primaryFontSize,
                                            alignSelf: props.selectWidthBehavior === 'full' ? undefined : 'normal',
                                            justifyContent: props.alignRight ? 'flex-end' : undefined,
                                            padding: `0 ${props.alignRight ? 0 : 8}px 0px 0`,
                                            flexWrap: props.multi ? 'wrap' : undefined,
                                        })
                            })
                                :
                            ({
                                clearIndicator:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            padding: 0
                                        }),
                                container:
                                    (base: any) =>
                                        ({
                                            ...base
                                        }),
                                control:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            border: 0,
                                            backgroundColor: 'transparent',
                                            borderRadius: 0,
                                            boxShadow: 'none',
                                            minHeight: 0 // overwrite the default min height (which is 38px, too high!)
                                        }),
                                group:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            paddingTop: 3
                                        }),
                                groupHeading:
                                    () =>
                                        ({
                                            fontWeight: 500,
                                            fontSize: 17,
                                            color: textColor,
                                            padding: '0 10px',
                                            marginBottom: 5
                                        }),

                                indicatorsContainer:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            padding: 0,

                                            // to align three dot menu (in Details.tsx) with the chevron in the selectbox underneath them
                                            marginRight: 4,
                                            height: 15,
                                            marginTop: 2
                                        }),
                                indicatorSeparator:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            display: 'none',
                                            margin: 0
                                        }),
                                dropdownIndicator:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            color: grey[600],
                                            padding: 0,
                                            display: props.alignRight ? 'none' : undefined,
                                            marginTop: 3
                                        }),
                                input:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            textAlign: props.alignRight ? 'right' : undefined,
                                            margin: 0,
                                            paddingBottom: 0,
                                            paddingTop: 0,
                                            fontSize: primaryFontSize,
                                            fontFamily: primaryFontFamily,
                                        }),
                                loadingIndicator:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            display: 'none'
                                        }),
                                loadingMessage:
                                    (base: any) =>
                                        ({
                                            ...base
                                        }),
                                menu:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            width: 'auto',
                                            minWidth: '100%',
                                        }),
                                menuList:
                                    (base: any) =>
                                        ({
                                            ...base
                                        }),
                                menuPortal:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            // higher than popper zIndex (see share with colleagues popper in dashboard)
                                            zIndex: 2,
                                        }),
                                multiValue:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            borderRadius: 25,
                                            marginRight: 8,
                                            alignItems: 'center',
                                            backgroundColor: 'rgba(0,0,0,0.07)',
                                            padding: '0 0 0 3px'
                                        }),
                                multiValueLabel:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            padding: 0,
                                            fontSize: 14
                                        }),
                                multiValueRemove:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            borderRadius: 25,
                                            width: 25,
                                            height: 25,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            cursor: 'pointer',
                                            justifyContent: 'center',
                                        }),
                                noOptionsMessage:
                                    (base: any) =>
                                        ({
                                            ...base
                                        }),
                                option:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            padding: '0 10px'
                                        }),
                                placeholder:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            color: 'rgba(0,0,0,0.35)'
                                        }),
                                singleValue:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            color: 'inherit', // inherit color from parent component
                                            marginLeft: 0,
                                            textAlign: props.alignRight ? 'right' : undefined,
                                            fontFamily: primaryFontFamily,
                                            fontSize: primaryFontSize,
                                            position: props.selectWidthBehavior === 'full' ? 'absolute' : 'relative',
                                            alignSelf: props.selectWidthBehavior === 'full' ? undefined : 'center',
                                            overflow: props.selectWidthBehavior === 'full' ? undefined : 'none',
                                            top: '0%',
                                            transform: 'translateY(0%)',
                                            marginRight: 0,
                                            width: hasValue ? '100%' : undefined,
                                            maxWidth: '100%' // by default this is calc(100% - 8px) but this leads to unnecessary ellipsis
                                        }),
                                valueContainer:
                                    (base: any) =>
                                        ({
                                            ...base,
                                            maxHeight: 'none',
                                            cursor: 'text',
                                            fontFamily: primaryFontFamily,
                                            fontSize: primaryFontSize,
                                            alignSelf: props.selectWidthBehavior === 'full' ? undefined : 'normal',
                                            justifyContent: props.alignRight ? 'flex-end' : undefined,
                                            padding: `0 ${props.alignRight ? 0 : 8}px 0px 0`,
                                            flexWrap: props.multi ? 'wrap' : undefined
                                        })
                    }),
                [
                    props.outlined,
                    props.alignRight,
                    props.selectWidthBehavior,
                    props.multi,
                    hasValue
                ]);
        const stylingWithFixedMenu =
            useMemo<StylesConfig>(
                () => ({
                    ...styling,
                    menuPortal:
                        (base, props) =>
                            ({
                                ...styling.menuPortal?.(base, props),
                                position: 'inherit',
                                width: 'inherit',
                                height: 'inherit',
                                left: 'inherit',
                                top: 'inherit',
                            }),
                    menu:
                        (base, props) =>
                            ({
                                ...styling.menu?.(base, props),
                                position: 'inherit',
                                boxShadow: 'none',
                                border: `1px solid ${dividerColor}`,
                            }),
                    menuList:
                        (base, props) =>
                            ({
                                ...styling.menuList?.(base, props),
                                maxHeight: 'initial',
                                overflowY: 'initial',
                            })
                }),
                [styling]
            );

        const getOptionValue =
            useCallback(
                (value: any) =>
                    props.idResolver(
                        getRawOptionValue(value)),
                [
                    props
                ]);

        const onCreateCallback =
            useCallback(
                (value: string) =>
                {
                    props.onCreate(value);
                },
                [
                    props
                ]);

        const placeholder =
            useMemo(
                () =>
                    props.placeholder || '...',
                [
                    props.placeholder
                ]);

        const finalInput = props.inputValue || input;

        const [ isLoading, setLoading ] = useState(false);
        const [ options, setOptions ] = useState<any[]>([]);
        const _performLoad =
            useCallback(
                async (query: string, doOpenMenu: boolean = true) =>
                {
                    if (doOpenMenu)
                    {
                        onMenuOpen();
                    }

                    setLoading(true);

                    const newOptions = await load(query);

                    if (isMounted())
                    {
                        setOptions(newOptions);
                    }

                    setLoading(false);
                },
                [
                    onMenuOpen,
                    setLoading,
                    load,
                    setOptions,
                    isMounted
                ]);

        const performLoad =
            useDebouncedCallback(
                (query: string) =>
                    _performLoad(query),
                [
                    _performLoad
                ],
                300);

        const loadOnFocus =
            useCallback(
                async () =>
                {
                    await _performLoad(finalInput);
                },
                [
                    _performLoad,
                    finalInput
                ]);

        const setInputCallback =
            useCallback(
                (input: string, action: InputActionMeta) =>
                {
                    setInput(input);

                    if (onInputChange)
                    {
                        onInputChange(input, action);
                    }

                    if (action.action === 'input-change')
                    {
                        performLoad(input);
                    }
                },
                [
                    setInput,
                    performLoad,
                    onInputChange
                ]);

        const [ isFocused, setFocused ] = useState(false);

        const onFocusCallback =
            useCallback(
                () =>
                {
                    setFocused(true);

                    if (onFocus)
                    {
                        onFocus();
                    }

                    loadOnFocus();
                },
                [
                    setFocused,
                    onFocus,
                    loadOnFocus
                ]);

        const onBlurCallback =
            useCallback(
                () =>
                {
                    setFocused(false);

                    if (onBlur)
                    {
                        onBlur();
                    }

                    onMenuClose();
                },
                [
                    setFocused,
                    onBlur,
                    onMenuClose
                ]);

        useEffect(
            () =>
            {
                if (openKey)
                {
                    onFocusCallback();
                }
            },
            [
                openKey,
                onFocusCallback
            ]);

        const rootClassNameWithUnderline =
            useMemo(
                () =>
                    classNames(
                        styles.root,
                        'future', // to ensure that old selectbox css does not interfere
                        props.outlined && styles.outlined,
                        isFocused && styles.focused
                    ),
                [
                    props.outlined,
                    isFocused
                ]);
        const rootClassName =
            useMemo(
                () =>
                    classNames(
                        rootClassNameWithUnderline,
                        props.disableUnderline && styles.disableUnderline
                    ),
                [
                    rootClassNameWithUnderline,
                    props.disableUnderline,
                ]);

        // Inside a horizontally scrollable table, the selectbox menu will disappear beneath a scrollbar
        // E.g. when managing time registrations
        const divRef = useRef();
        const [ menuPortalNode, setMenuPortalNode ] = useState<any>();

        useEffect(
            () =>
            {
                if (divRef.current)
                {
                    // Do not set the menu portal node to the body in a dialog, because then the selectbox will appear
                    // beneath the dialog/drawer
                    const dialog = closest(divRef.current, '.MuiDialog-root') || closest(divRef.current, '.MuiDrawer-root');

                    if (!dialog)
                    {
                        setMenuPortalNode(document.querySelector('body'));
                    }
                }
            },
            [
                divRef,
                setMenuPortalNode
            ]);

        // Ensure that when the load function changes, the default input value or the open function changes (e.g. in e-mail add-in), the selectbox rerenders its options
        // See: https://github.com/JedWatson/react-select/issues/1581
        // Find out if there are performance-friendlier ways of doing this (e.g. with ref)
        const key =
            useMemo(
                () =>
                    uuid(),
                // eslint-disable-next-line react-hooks/exhaustive-deps
                [
                    load
                ]);

        const changeInput =
            useCallback(
                (newInput: string) =>
                {
                    setInput(newInput);
                    _performLoad(newInput, false)
                         .then();
                },
                [
                    setInput,
                    _performLoad
                ]);

        useEffect(
            () =>
            {
                if (ref.current
                    && props.defaultInputValue !== undefined)
                {
                    changeInput(props.defaultInputValue);
                }
            },
            [
                ref,
                props.defaultInputValue,
                changeInput
            ]);

        // If the default value switches from a defined value to undefined, then clear the input
        // E.g. when linking an external email
        const [ oldDefaultValue, setOldDefaultValue] = useState<string>(props.defaultInputValue);

        useEffect(
            () =>
            {
                if (ref.current
                    && oldDefaultValue !== undefined
                    && props.defaultInputValue === undefined)
                {
                    changeInput('');
                }
            },
            [
                ref,
                props.defaultInputValue,
                oldDefaultValue,
                changeInput
            ]);

        useEffect(
            () =>
            {
                setOldDefaultValue(props.defaultInputValue);
            },
            [
                props.defaultInputValue
            ]);

        useEffect(
            () =>
            {
                // https://github.com/JedWatson/react-select/issues/2986#issuecomment-579816630
                // Force state isLoading to false on initial render
                if (ref.current)
                {
                    (ref.current as any).state.isLoading = false;
                }
            },
            [
                ref
            ]);

        const emptyOptionFilter =
            useCallback(
                () => true,
                []);

        const [ isLastOpenWithTouch, setLastOpenWithTouch ] = useState(false);
        const [ isDialogOpen, setDialogOpen ] = useState(false);
        const onPointerUp =
            useCallback(
                (event: React.PointerEvent<HTMLElement>) =>
                {
                    event.stopPropagation();

                    const isTouch = event.pointerType === 'touch';
                    setLastOpenWithTouch(isTouch);

                    if (isTouch)
                    {
                        onMenuOpen();
                        setDialogOpen(true);
                        performLoad('');
                    }
                    else
                    {
                        setDialogOpen(false);
                    }
                },
                [onMenuOpen, performLoad]
            );
        const closeDialog =
            useCallback(
                () =>
                {
                    onMenuClose();
                    setDialogOpen(false);
                },
                [onMenuClose]
            );
        const [ menuPortalNodeInDialog, setMenuPortalNodeInDialog ] = useState<any>();
        const onDialogSelectionChange =
            useCallback(
                (value: any) =>
                {
                    const doClose = onSelectionChange(value);

                    if (doClose !== false
                        && !props.multi)
                    {
                        closeDialog();
                    }
                },
                [closeDialog, onSelectionChange, props.multi]
            );
        const isSmallScreen = useIsMobile();
        const label = useContext(InputLabelContext);
        const stopClickPropagation =
            useCallback<MouseEventHandler>(
                e =>
                    e.stopPropagation ? e.stopPropagation() : undefined,
                []
            );

        return <FocusField
            focused={isDialogOpen}
        >
            <div
                ref={divRef}
                onClick={stopClickPropagation}
                onPointerUp={onPointerUp}
            >
                {
                    isDialogOpen &&
                    <Dialog
                        open={isDialogOpen}
                        onClose={closeDialog}
                        width="md"
                        fullWidth
                        fullscreen={isSmallScreen}
                        hideCloseButton={label === undefined}
                        contentFullHeight
                    >
                        {
                            label &&
                            <DialogTitle>
                                {label}
                            </DialogTitle>
                        }
                        <Box
                            paddingTop={
                                label === undefined
                                    ? 3
                                    : 0
                            }
                            paddingX={3}
                            paddingBottom={1}
                        >
                            {
                                onCreate
                                    ? <CreatableSelect
                                        key={key}
                                        ref={ref}
                                        className={rootClassNameWithUnderline}
                                        options={options}
                                        isLoading={isLoading}
                                        filterOption={emptyOptionFilter}
                                        value={value}
                                        onChange={onDialogSelectionChange}
                                        onMenuOpen={onMenuOpen}
                                        onMenuClose={onMenuClose}
                                        menuIsOpen
                                        onInputChange={setInputCallback}
                                        inputValue={finalInput}
                                        formatOptionLabel={formatOptionLabel}
                                        getOptionValue={getOptionValue}
                                        isClearable={props.clearable}
                                        placeholder={placeholder}
                                        autoFocus={props.autoFocus}
                                        isMulti={props.multi}
                                        onCreateOption={onCreateCallback}
                                        isDisabled={props.disabled}
                                        styles={stylingWithFixedMenu}
                                        onFocus={onFocusCallback}
                                        onBlur={onBlurCallback}
                                        tabSelectsValue={false}
                                        menuPortalTarget={menuPortalNodeInDialog}
                                        menuPlacement="auto"
                                    />
                                    : <Select
                                        key={key}
                                        ref={ref}
                                        className={rootClassNameWithUnderline}
                                        options={options}
                                        isLoading={isLoading}
                                        filterOption={emptyOptionFilter}
                                        value={value}
                                        onChange={onDialogSelectionChange}
                                        onMenuOpen={onMenuOpen}
                                        onMenuClose={onMenuClose}
                                        menuIsOpen
                                        onInputChange={setInputCallback}
                                        inputValue={finalInput}
                                        formatOptionLabel={formatOptionLabel}
                                        isOptionDisabled={isOptionDisabled}
                                        getOptionValue={getOptionValue}
                                        isClearable={props.clearable}
                                        placeholder={placeholder}
                                        autoFocus={props.autoFocus}
                                        isMulti={props.multi}
                                        isDisabled={props.disabled}
                                        styles={stylingWithFixedMenu}
                                        onFocus={onFocusCallback}
                                        onBlur={onBlurCallback}
                                        tabSelectsValue={false}
                                        menuPortalTarget={menuPortalNodeInDialog}
                                        menuPlacement="auto"
                                    />
                            }
                        </Box>
                        <Box
                            paddingX={3}
                            flex={1}
                            style={{
                                overflowY: 'auto',
                                maxHeight:
                                    isSmallScreen
                                        ? undefined
                                        : 'calc(100vh - 250px)',
                            }}
                        >
                            <div
                                ref={setMenuPortalNodeInDialog}
                            />
                        </Box>
                        <Box
                            paddingTop={1}
                            paddingX={3}
                            paddingBottom={2}
                        >
                            <PrimaryButton
                                label={
                                    <LocalizedText
                                        code="Generic.Close"
                                        value="Close"
                                    />
                                }
                                onClick={closeDialog}
                                fullWidth
                            />
                        </Box>
                    </Dialog>
                }
                {
                    onCreate
                        ? <CreatableSelect
                            key={key}
                            ref={ref}
                            className={rootClassName}
                            options={options}
                            isLoading={isLoading}
                            filterOption={emptyOptionFilter}
                            value={value}
                            onChange={onSelectionChange}
                            onMenuOpen={onMenuOpen}
                            onMenuClose={onMenuClose}
                            menuIsOpen={isOpen && !isLastOpenWithTouch}
                            onInputChange={setInputCallback}
                            inputValue={finalInput}
                            formatOptionLabel={formatOptionLabel}
                            getOptionValue={getOptionValue}
                            isClearable={props.clearable}
                            placeholder={placeholder}
                            autoFocus={props.autoFocus}
                            isMulti={props.multi}
                            onCreateOption={onCreateCallback}
                            isDisabled={props.disabled}
                            styles={styling}
                            onFocus={onFocusCallback}
                            onBlur={onBlurCallback}
                            tabSelectsValue={false}
                            menuPortalTarget={menuPortalNode}
                            menuPlacement="auto"
                        />
                        : <Select
                            key={key}
                            ref={ref}
                            className={rootClassName}
                            options={options}
                            isLoading={isLoading}
                            filterOption={emptyOptionFilter}
                            value={value}
                            onChange={onSelectionChange}
                            onMenuOpen={onMenuOpen}
                            onMenuClose={onMenuClose}
                            menuIsOpen={isOpen && !isLastOpenWithTouch}
                            onInputChange={setInputCallback}
                            inputValue={finalInput}
                            formatOptionLabel={formatOptionLabel}
                            isOptionDisabled={isOptionDisabled}
                            getOptionValue={getOptionValue}
                            isClearable={props.clearable}
                            placeholder={placeholder}
                            autoFocus={props.autoFocus}
                            isMulti={props.multi}
                            isDisabled={props.disabled}
                            styles={styling}
                            onFocus={onFocusCallback}
                            onBlur={onBlurCallback}
                            tabSelectsValue={false}
                            menuPortalTarget={menuPortalNode}
                            menuPlacement="auto"
                        />
                }
            </div>
        </FocusField>;
    };

Selectbox.defaultProps = {
    disableUnderline: true,
    open: false
};

export default observer(Selectbox);
