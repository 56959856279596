import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import { runInAction } from 'mobx';
import BodyLayout from '../../../../../@Api/Layout/Type/BodyLayout';
import Color from '../../../../../@Api/Layout/Style/Color';
import ColorEditor from '../../../../../@Future/Component/Generic/Input/ColorEditor/ColorEditor';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';

export interface BodyLayoutControlsProps extends LayoutEditorProps<BodyLayout>
{

}

const BodyLayoutControls: React.FC<BodyLayoutControlsProps> =
    props =>
    {
        const setBackgroundColor =
            useCallback(
                (color: string) =>
                    runInAction(
                        () =>
                            props.layout.backgroundColor = color ? new Color(color) : undefined),
                [
                    props.layout
                ]);

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.BackgroundColor"
                            value="Achtergrondkleur"
                        />
                    }
                >
                    <ColorEditor
                        value={props.layout.backgroundColor?.value}
                        onChange={setBackgroundColor}
                    />
                </Input>
            </InputGroup>
        </CardInset>;
    };

export default observer(BodyLayoutControls);
