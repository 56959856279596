import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../../Type/Api/useTypes';
import ViewGroup from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import InputGroup from '../../../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import CurrencyEditor from '../../../../../../../../../../../@Future/Component/Generic/Input/NumberEditor/CurrencyEditor/CurrencyEditor';
import NumberEditor from '../../../../../../../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';
import RightAlignedButtonGroup from '../../../../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import { ProductLineSubscriptionEditorProps } from '../ProductLineSubscriptionEditor';
import DangerTextButton from '../../../../../../../../../../../@Future/Component/Generic/Button/Variant/DangerTextButton/DangerTextButton';
import StaticSelectbox from '../../../../../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import useAsyncResult from '../../../../../../../../../../../@Util/Async/useAsyncResult';
import performAction from '../../../../../../../../../../../@Api/Entity/performAction';
import DateEditor from '../../../../../../../../../../../@Future/Component/Generic/Input/DateEditor/DateEditor';
import Centered from '../../../../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../../../../@Future/Component/Generic/Loader/Loader';
import LocalizedText from '../../../../../../../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../../../../../../../@Api/Localization/localizeText';
import useTextEntityValue from '../../../../../../../../../../../@Api/Entity/Hooks/useTextEntityValue';
import useEntityValue from '../../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';

export interface ChangeSubscriptionLineInNextPeriodProps extends ProductLineSubscriptionEditorProps
{

}

interface Period
{
    from: Date;
    to: Date;
}

type CompensationMethod = 'Quantity' | 'Price';

const ChangeSubscriptionLineInNextPeriodEditor: React.FC<ChangeSubscriptionLineInNextPeriodProps> =
    props =>
    {
        const types = useTypes();

        const [ currentSubscriptionPeriod, isLoading ] =
            useAsyncResult<Period>(
                () =>
                    performAction(
                        props.entity.getRelatedEntityByDefinition(
                            true,
                            types.Activity.RelationshipDefinition.ProductLines),
                        {
                            code: 'Activity.Subscription.GetCurrentPeriod'
                        })
                        .then(
                            result =>
                                result.result && ({
                                    from: new Date(result.result.from),
                                    to: new Date(result.result.to)
                                })),
                [
                    props.entity,
                    types
                ]);

        const periods =
            useMemo(
                () => [
                    {
                        id: 'NextSubscriptionPeriod',
                        label: localizeText('ProductLine.Subscription.NextSubscriptionPeriod', 'Volgende abonnementsperiode'),
                        value: 'NextSubscriptionPeriod',
                        getStartDate:
                            (subscriptionPeriod: Period) =>
                                subscriptionPeriod.to,
                        getEndDate:
                            (subscriptionPeriod: Period) =>
                                undefined,
                    },
                    {
                        id: 'RemainingSubscriptionPeriod',
                        label: localizeText('ProductLine.Subscription.RemainingSubscriptionPeriod', 'Resterende abonnementsperiode'),
                        value: 'RemainingSubscriptionPeriod',
                        getStartDate:
                            (subscriptionPeriod: Period) =>
                                new Date(),
                        getEndDate:
                            (subscriptionPeriod: Period) =>
                                subscriptionPeriod.to,
                    },
                    {
                        id: 'FromStartOfSubscriptionPeriod',
                        label: localizeText('ProductLine.Subscription.FromStartOfSubscriptionPeriod', 'Vanaf start van abonnementsperiode'),
                        value: 'FromStartOfSubscriptionPeriod',
                        getStartDate:
                            (subscriptionPeriod: Period) =>
                                subscriptionPeriod.from,
                        getEndDate:
                            (subscriptionPeriod: Period) =>
                                undefined,
                    },
                    {
                        id: 'CurrentSubscriptionPeriod',
                        label: localizeText('ProductLine.Subscription.CurrentSubscriptionPeriod', 'Deze abonnementsperiode'),
                        value: 'CurrentSubscriptionPeriod',
                        getStartDate:
                            (subscriptionPeriod: Period) =>
                                subscriptionPeriod.from,
                        getEndDate:
                            (subscriptionPeriod: Period) =>
                                subscriptionPeriod.to,
                    },
                    {
                        id: 'SubscriptionPeriodFromNow',
                        label: localizeText('ProductLine.Subscription.SubscriptionPeriodFromNow', 'Vanaf nu'),
                        value: 'SubscriptionPeriodFromNow',
                        getStartDate:
                            (subscriptionPeriod: Period) =>
                                new Date(),
                        getEndDate:
                            (subscriptionPeriod: Period) =>
                                undefined,
                    },
                    {
                        id: 'CustomPeriod',
                        label: localizeText('ProductLine.Subscription.CustomPeriod', 'Aangepaste periode...'),
                        value: 'CustomPeriod'
                    }
                ],
                []);

        const [ period, setPeriod ] = useState<string>(periods[0].value);

        const compensationMethods =
            useMemo(
                () => [
                    {
                        id: 'Quantity',
                        label: localizeText('ProductLine.Subscription.ChangeQuantity', 'Aantal aanpassen'),
                        value: 'Quantity'
                    },
                    {
                        id: 'Price',
                        label: localizeText('ProductLine.Subscription.ChangePrice', 'Prijs aanpassen'),
                        value: 'Price'
                    }
                ],
                []);

        const [ compensationMethod, setCompensationMethod ] = useState<CompensationMethod>('Quantity');

        const [ startDate, setStartDate ] = useState<Date>();
        const [ endDate, setEndDate ] = useState<Date>();

        useEffect(
            () =>
            {
                if (currentSubscriptionPeriod)
                {
                    const periodOption = periods.find(checkPeriod => checkPeriod.value === period);

                    if (periodOption.getStartDate)
                    {
                        setStartDate(
                            periodOption.getStartDate(
                                currentSubscriptionPeriod));
                    }

                    if (periodOption.getEndDate)
                    {
                        setEndDate(
                            periodOption.getEndDate(
                                currentSubscriptionPeriod));
                    }
                }
            },
            [
                period,
                currentSubscriptionPeriod,
                setStartDate,
                setEndDate
            ]);

        const currentQuantityAsString = useTextEntityValue(props.entity, types.ProductLine.Field.Quantity);
        const [ newQuantity, setNewQuantity ] =
            useState<number>(
                () =>
                    props.entity.getObjectValueByField(types.ProductLine.Field.Quantity));

        const currency = useEntityValue<string>(props.entity, types.ProductLine.Field.Currency);
        const [ newPrice, setNewPrice ] =
            useState<number>(
                () =>
                    props.entity.getObjectValueByField(
                        currency
                            ? types.ProductLine.Field.PriceInCurrency
                            : types.ProductLine.Field.Price
                    )
            );

        const [ newDiscountPercentage, ] =
            useState(
                () =>
                    props.entity.getObjectValueByField(types.ProductLine.Field.DiscountPercentage));

        const saveChanges =
            useCallback(
                (startDate: Date,
                 endDate: Date | undefined,
                 compensationMethod: CompensationMethod,
                 newQuantity: number,
                 newPrice: number,
                 newDiscountPercentage: number) =>
                    performAction(
                        props.entity,
                        {
                            code: 'ProductLine.ChangeSubscription',
                            name: localizeText('ProductLine.Subscription.EditLine', 'Abonnementsregel aanpassen'),
                            parameters: {
                                startDate: startDate?.toISOString(),
                                endDate: endDate?.toISOString(),
                                compensationMethod: compensationMethod,
                                newQuantity: newQuantity,
                                newPrice: newPrice,
                                newDiscountPercentage: newDiscountPercentage,
                                currency: currency,
                            }
                        }),
                [
                    currency
                ]);

        const saveChangesFromForm =
            useCallback(
                () =>
                    saveChanges(
                        startDate,
                        endDate,
                        compensationMethod,
                        newQuantity,
                        newPrice,
                        newDiscountPercentage),
                [
                    startDate,
                    endDate,
                    compensationMethod,
                    newQuantity,
                    newPrice,
                    newDiscountPercentage
                ]);

        const stopSubscriptionLineInNextPeriod =
            useCallback(
                () =>
                    saveChanges(
                        currentSubscriptionPeriod.to,
                        undefined,
                        'Quantity',
                        0,
                        newPrice,
                        newDiscountPercentage),
                [
                    saveChanges,
                    currentSubscriptionPeriod,
                    newPrice,
                    newDiscountPercentage
                ]);

        if (isLoading)
        {
            return <Centered
                horizontal
            >
                <Loader />
            </Centered>;
        }
        else if (currentSubscriptionPeriod)
        {
            return <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <strong>
                        <LocalizedText
                            code="ProductLine.Subscription.Customize"
                            value="Aanpassen"
                        />
                    </strong>
                </ViewGroupItem>
                <ViewGroupItem>
                    <InputGroup>
                        <Input
                            label={
                                <LocalizedText
                                    code="Generic.Period"
                                    value="Periode"
                                />
                            }
                            labelPosition="left"
                        >
                            <StaticSelectbox
                                options={periods}
                                onChange={setPeriod}
                                value={period}
                                disableUnderline
                            />
                        </Input>
                        {
                            period === 'CustomPeriod' &&
                                <Input
                                    label={
                                        <LocalizedText
                                            code="Generic.StartDate"
                                            value="Begindatum"
                                        />
                                    }
                                    labelPosition="left"
                                >
                                    <DateEditor
                                        value={startDate}
                                        onChange={setStartDate}
                                        disableUnderline
                                    />
                                </Input>
                        }
                        {
                            period === 'CustomPeriod' &&
                                <Input
                                    label={
                                        <LocalizedText
                                            code="Generic.EndDate"
                                            value="Einddatum"
                                        />
                                    }
                                    labelPosition="left"
                                >
                                    <DateEditor
                                        value={endDate}
                                        onChange={setEndDate}
                                        disableUnderline
                                    />
                                </Input>
                        }
                        <Input
                            label={
                                <LocalizedText
                                    code="Generic.Method"
                                    value="Methode"
                                />
                            }
                            labelPosition="left"
                        >
                            <StaticSelectbox
                                options={compensationMethods}
                                onChange={setCompensationMethod}
                                value={compensationMethod}
                                disableUnderline
                            />
                        </Input>
                        {
                            compensationMethod === 'Quantity' &&
                                <Input
                                    label={
                                        <LocalizedText
                                            code="Products.Subscription.CurrentQuantity"
                                            value="Huidig aantal"
                                        />
                                    }
                                    labelPosition="left"
                                >
                                    {currentQuantityAsString}
                                </Input>
                        }
                        {
                            compensationMethod === 'Quantity' &&
                                <Input
                                    label={
                                        <LocalizedText
                                            code="Products.Subscription.NewQuantity"
                                            value="Nieuw aantal"
                                        />
                                    }
                                    labelPosition="left"
                                >
                                    <NumberEditor
                                        value={newQuantity}
                                        onChange={setNewQuantity}
                                        disableUnderline
                                    />
                                </Input>
                        }
                        {
                            compensationMethod === 'Price' &&
                                <Input
                                    label={
                                        <LocalizedText
                                            code="Products.Subscription.CurrentPrice"
                                            value="Huidige prijs"
                                        />
                                    }
                                    labelPosition="left"
                                >
                                    {
                                        props.entity
                                            .getDataObjectValueByField(
                                                currency
                                                    ? types.ProductLine.Field.PriceInCurrency
                                                    : types.ProductLine.Field.Price
                                            ).toCurrencyString(currency)
                                    }
                                </Input>
                        }
                        {
                            compensationMethod === 'Price' &&
                                <Input
                                    label={
                                        currency
                                        ? <LocalizedText
                                                code="Products.Subscription.NewPriceInCurrency"
                                                value="Nieuwe prijs (valuta)"
                                            />
                                        : <LocalizedText
                                            code="Products.Subscription.NewPrice"
                                            value="Nieuwe prijs"
                                        />
                                    }
                                    labelPosition="left"
                                >
                                    <CurrencyEditor
                                        value={newPrice}
                                        onChange={setNewPrice}
                                        disableUnderline
                                        currencyCode={currency}
                                    />
                                </Input>
                        }
                        {/*<Input*/}
                        {/*    label={*/}
                        {/*        <LocalizedText*/}
                        {/*            code="Products.Subscription.NewDiscount"*/}
                        {/*            value="Nieuwe korting"*/}
                        {/*        />*/}
                        {/*    }*/}
                        {/*    labelPosition="left"*/}
                        {/*>*/}
                        {/*    <PercentageEditor*/}
                        {/*        value={newDiscountPercentage}*/}
                        {/*        onChange={setNewDiscountPercentage}*/}
                        {/*        disableUnderline*/}
                        {/*    />*/}
                        {/*</Input>*/}
                    </InputGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <RightAlignedButtonGroup>
                        <DangerTextButton
                            onClick={stopSubscriptionLineInNextPeriod}
                            label={
                                <LocalizedText
                                    code="Products.Subscription.StopSubscriptionLineInNextPeriod"
                                    value="Stopzetten na deze abonnementsperiode"
                                />
                            }
                        />
                        <SaveButton
                            onClick={saveChangesFromForm}
                        />
                    </RightAlignedButtonGroup>
                </ViewGroupItem>
            </ViewGroup>;
        }
        else
        {
            return null;
        }
    };

export default observer(ChangeSubscriptionLineInNextPeriodEditor);