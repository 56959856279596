import ValueType from './ValueType';
import getValueTypeFromDescriptor from '../../Api/getValueTypeFromDescriptor';
import AutomationDependencyContext from '../../AutomationDependencyContext';

export default class MapType extends ValueType<any>
{
    // ------------------------- Properties -------------------------

    keyType: ValueType<any>;
    valueType: ValueType<any>;

    // ------------------------ Constructor -------------------------

    constructor(keyType: ValueType<any>,
                valueType: ValueType<any>)
    {
        super(undefined);

        this.keyType = keyType;
        this.valueType = valueType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return `Map(${this.keyType.id()},${this.valueType.id()})`;
    }

    isA(type: ValueType<any>): boolean
    {
        return type instanceof MapType
            && this.keyType.isA(type.keyType)
            && this.valueType.isA(type.valueType);
    }

    getName(): string
    {
        return `Map van ${this.keyType.getName()} naar ${this.valueType.getName()}`;
    }

    toDescriptor()
    {
        return {
            type: 'Map',
            keyType: this.keyType.toDescriptor(),
            valueType: this.valueType.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const [ keyType, valueType ] =
            await Promise.all([
                getValueTypeFromDescriptor(
                    descriptor.keyType,
                    dependencyContext),
                getValueTypeFromDescriptor(
                    descriptor.valueType,
                    dependencyContext)
            ]);

        return new MapType(
            keyType,
            valueType);
    }

    // ----------------------- Private logic ------------------------
}
