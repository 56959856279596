import { observable } from 'mobx';
import { Computation } from '../Computation';
import { CompositeComputationOperation } from './Operation/CompositeComputationOperation';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import MathematicalComputation from '../../../../../@Api/Automation/Function/Computation/MathematicalComputation';
import EmptyValue from '../../../../../@Api/Automation/Value/EmptyValue';

export class CompositeComputation extends Computation
{
    // ------------------------- Properties -------------------------

    @observable operations = observable.array<CompositeComputationOperation>();

    // ------------------------ Constructor -------------------------

    constructor(operations: CompositeComputationOperation[])
    {
        super('Composite');

        this.operations.replace(operations);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async initialize(): Promise<any>
    {
        return Promise.all(
            this.operations.map(
                operation =>
                    operation.computation?.initialize()));
    }

    async toNewComputation(context: AutomationDependencyContext)
    {
        if (this.operations.length === 0)
        {
            return EmptyValue.instance;
        }
        else
        {
            const firstOperation = this.operations.find(() => true);
            let computation = await firstOperation.computation.toNewComputation(context);

            for (let i = 1; i < this.operations.length; i++)
            {
                const operation = this.operations[i];
                const operationComputation = await operation.computation.toNewComputation(context);
                computation =
                    new MathematicalComputation(
                        computation,
                        operation.operator,
                        operationComputation
                    );
            }

            return computation;
        }
    }

    // ----------------------- Private logic ------------------------
}
