import { EntityPath } from '../../Path/@Model/EntityPath';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityCacheReferrer } from '../../../../Service/Entity/EntityCacheReferrer';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityCacheService } from '../../../../Service/Entity/EntityCacheService';
import { EntityPathJoinNode } from '../../Path/@Model/Node/EntityPathJoinNode';

export default function markPathsAsFetchedInEntities(
    paths: EntityPath[],
    entities: Entity[],
    cacheReferrer: EntityCacheReferrer,
    isPartiallyFetched: (node: EntityPathJoinNode) => boolean = () => false
)
{
    const entityCacheService = loadModuleDirectly(EntityCacheService);

    for (const entity of entities)
    {
        entityCacheService.cacheReferrerService.addReferrerToCacheInformationForEntity(
            entity,
            cacheReferrer
        );
    }

    for (const path of paths)
    {
        let currentEntities = entities;

        for (const node of path.nodes)
        {
            if (node instanceof EntityPathJoinNode)
            {
                for (const entity of currentEntities)
                {
                    for (const relationship of
                        entity.getRelationshipsByDefinition(
                            node.isParent,
                            node.relationshipDefinition,
                            null))
                    {
                        entityCacheService.cacheReferrerService.addReferrerToCacheInformationForRelationship(
                            relationship,
                            cacheReferrer
                        );
                    }
                }

                if (!isPartiallyFetched(node))
                {
                    for (const currentEntity of currentEntities)
                    {
                        entityCacheService.relationshipsCacheReferrerService.registerReferrer(
                            currentEntity,
                            node.relationshipDefinition,
                            node.isParent,
                            cacheReferrer
                        );
                    }
                }
            }

            currentEntities =
                currentEntities
                    .map(
                        currentEntity =>
                            node.traverseEntity(currentEntity, null))
                    .reduce((a, b) => a.concat(b), []);

            for (const entity of currentEntities)
            {
                entityCacheService.cacheReferrerService.addReferrerToCacheInformationForEntity(
                    entity,
                    cacheReferrer
                );
            }
        }
    }
}
