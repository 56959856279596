import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import FunctionContext from '../../../../../../../../@Api/Automation/Function/FunctionContext';
import ValueType from '../../../../../../../../@Api/Automation/Value/Type/ValueType';
import Computation from '../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ValueEditor, { ValueEditorProps } from '../Value/ValueEditor';
import Value from '../../../../../../../../@Api/Automation/Value/Value';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import ValueFromEntityComputation from '../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import ValueFromEntityComputationEditor, { ValueFromEntityComputationEditorProps } from './Type/ValueFromEntity/ValueFromEntityComputationEditor';
import Parameter from '../../../../../../../../@Api/Automation/Parameter/Parameter';
import ParameterViewer from '../Parameter/ParameterViewer';
import styles from './ComputationEditor.module.scss';
import Tooltip from '../../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import QueryComputation from '../../../../../../../../@Api/Automation/Function/Computation/QueryComputation';
import QueryComputationEditor, { QueryComputationEditorProps } from './Type/Query/QueryComputationEditor';
import TextComputation from '../../../../../../../../@Api/Automation/Function/Computation/TextComputation';
import TextComputationEditor, { TextComputationEditorProps } from './Type/Text/TextComputationEditor';
import PrimitiveValueType from '../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import ComputationConstructor, { ComputationConstructorItem } from './ComputationConstructor';
import DynamicComputation from '../../../../../../../../@Api/Automation/Function/Computation/DynamicComputation';
import DynamicComputationEditor, { DynamicComputationEditorProps } from './Type/Dynamic/DynamicComputationEditor';
import MathematicalComputation from '../../../../../../../../@Api/Automation/Function/Computation/MathematicalComputation';
import MathematicalComputationEditor, { MathematicalComputationEditorProps } from './Type/MathematicalComputation/MathematicalComputationEditor';
import { TextType } from '../../../../../../DataObject/Type/Text/TextType';
import FormattedDateComputation from '../../../../../../../../@Api/Automation/Function/Computation/FormattedDateComputation';
import FormattedDateComputationEditor, { FormattedDateComputationEditorProps } from './Type/FormattedDate/FormattedDateComputationEditor';
import ComputationOptions from './ComputationOptions';
import { RichTextType } from '../../../../../../DataObject/Type/RichText/RichTextType';
import LinkComputation from '../../../../../../../../@Api/Automation/Function/Computation/LinkComputation';
import LinkComputationEditor, { LinkComputationEditorProps } from './Type/Link/LinkComputationEditor';
import ConditionalComputation from '../../../../../../../../@Api/Automation/Function/Computation/ConditionalComputation';
import ConditionalComputationEditor, { ConditionalComputationEditorProps } from './Type/ConditionalComputation/ConditionalComputationEditor';
import FileAttachmentComputation from '../../../../../../../../@Api/Automation/Function/Computation/FileAttachmentComputation';
import FileAttachmentComputationEditor, { FileAttachmentComputationEditorProps } from './Type/FileAttachment/FileAttachmentComputationEditor';
import { FileType } from '../../../../../../DataObject/Type/File/FileType';
import StartOfDayComputation from '../../../../../../../../@Api/Automation/Function/Computation/StartOfDayComputation';
import StartOfDayComputationEditor, { StartOfDayComputationEditorProps } from './Type/StartOfDay/StartOfDayComputationEditor';
import RegExComputation from '../../../../../../../../@Api/Automation/Function/Computation/RegExComputation';
import RegExComputationEditor, { RegExComputationEditorProps } from './Type/RegEx/RegExComputationEditor';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import EntityFromDataSourceValueComputation from '../../../../../../../../@Api/Automation/Function/Computation/EntityFromDataSourceValueComputation';
import EntityFromDataSourceValueComputationEditor, { EntityFromDataSourceValueComputationEditorProps } from './Type/EntityFromDataSourceValue/EntityFromDataSourceValueComputationEditor';
import HtmlFromLayoutComputation from '../../../../../../../../@Api/Automation/Function/Computation/HtmlFromLayoutComputation';
import HtmlFromLayoutComputationEditor, { HtmlFromLayoutComputationEditorProps } from './Type/HtmlFromLayout/HtmlFromLayoutComputationEditor';
import LocalizedComputationEditor, { LocalizedComputationEditorProps } from './Type/LocalizedComputation/LocalizedComputationEditor';
import LocalizedComputation from '../../../../../../../../@Api/Automation/Function/Computation/LocalizedComputation';
import PdfComputation from '../../../../../../../../@Api/Automation/Function/Computation/PdfComputation';
import PdfComputationEditor, { PdfComputationEditorProps } from './Type/PdfComputation/PdfComputationEditor';
import MergedPdfComputation from '../../../../../../../../@Api/Automation/Function/Computation/MergedPdfComputation';
import MergedPdfComputationEditor, { MergedPdfComputationEditorProps } from './Type/MergedPdfComputation/MergedPdfComputationEditor';
import TruncatedDateComputation from '../../../../../../../../@Api/Automation/Function/Computation/TruncatedDateComputation';
import TruncatedDateComputationEditor, { TruncatedDateComputationEditorProps } from './Type/TruncatedDate/TruncatedDateComputationEditor';
import RangeCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/RangeCollectionComputation';
import RangeCollectionComputationEditor, { RangeCollectionComputationEditorProps } from './Type/RangeCollection/RangeCollectionComputationEditor';
import DataSourceValueByIdComputationEditor, { DataSourceValueByIdComputationEditorProps } from './Type/DataSourceValueById/DataSourceValueByIdComputationEditor';
import DataSourceValueByIdComputation from '../../../../../../../../@Api/Automation/Function/Computation/DataSourceValueByIdComputation';
import FilteredCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/FilteredCollectionComputation';
import FilteredCollectionComputationEditor, { FilteredCollectionComputationEditorProps } from './Type/FilteredCollection/FilteredCollectionComputationEditor';
import FirstElementOfCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/FirstElementOfCollectionComputation';
import FirstElementOfCollectionComputationEditor, { FirstElementOfCollectionComputationEditorProps } from './Type/FirstElementOfCollection/FirstElementOfCollectionComputationEditor';
import DataSourceListQueryComputation from '../../../../../../../../@Api/Automation/Function/Computation/DataSourceListQueryComputation';
import DataSourceListQueryComputationEditor, { DataSourceListQueryComputationEditorProps } from './Type/DataSourceListQuery/DataSourceListQueryComputationEditor';
import ValueFromDataSourceValueComputation from '../../../../../../../../@Api/Automation/Function/Computation/ValueFromDataSourceValueComputation';
import ValueFromDataSourceValueComputationEditor, { ValueFromDataSourceValueComputationEditorProps } from './Type/ValueFromDataSourceValue/ValueFromDataSourceValueComputationEditor';
import MapByComputation from '../../../../../../../../@Api/Automation/Function/Computation/MapByComputation';
import MapByComputationEditor, { MapByComputationEditorProps } from './Type/MapBy/MapByComputationEditor';
import ValueFromMapComputation from '../../../../../../../../@Api/Automation/Function/Computation/ValueFromMapComputation';
import ValueFromMapComputationEditor, { ValueFromMapComputationEditorProps } from './Type/ValueFromMap/ValueFromMapComputationEditor';
import MapWithValueComputation from '../../../../../../../../@Api/Automation/Function/Computation/MapWithValueComputation';
import MapWithValueComputationEditor, { MapWithValueComputationEditorProps } from './Type/MapWithValue/MapWithValueComputationEditor';
import LengthOfTextComputation from '../../../../../../../../@Api/Automation/Function/Computation/LengthOfTextComputation';
import LengthOfTextComputationEditor, { LengthOfTextComputationEditorProps } from './Type/LengthOfText/LengthOfTextComputationEditor';
import NumberFromTextComputation from '../../../../../../../../@Api/Automation/Function/Computation/NumberFromTextComputation';
import NumberFromTextComputationEditor, { NumberFromTextComputationEditorProps } from './Type/NumberFromText/NumberFromTextComputationEditor';
import SizeOfCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/SizeOfCollectionComputation';
import SizeOfCollectionComputationEditor, { SizeOfCollectionComputationEditorProps } from './Type/SizeOfCollection/SizeOfCollectionComputationEditor';
import IsDutchBsnValidComputation from '../../../../../../../../@Api/Automation/Function/Computation/IsDutchBsnValidComputation';
import IsDutchBsnValidComputationEditor, { IsDutchBsnValidComputationEditorProps } from './Type/IsDutchBsnValid/IsDutchBsnValidComputationEditor';
import CapitalizedTextComputation from '../../../../../../../../@Api/Automation/Function/Computation/CapitalizedTextComputation';
import CapitalizedTextComputationEditor, { CapitalizedTextComputationEditorProps } from './Type/CapitalizedText/CapitalizedTextComputationEditor';
import CurrentDateComputation from '../../../../../../../../@Api/Automation/Function/Computation/CurrentDateComputation';
import CurrentDateComputationEditor, { CurrentDateComputationEditorProps } from './Type/CurrentDate/CurrentDateComputationEditor';
import CurrentDateTimeComputation from '../../../../../../../../@Api/Automation/Function/Computation/CurrentDateTimeComputation';
import CurrentDateTimeComputationEditor, { CurrentDateTimeComputationEditorProps } from './Type/CurrentDateTime/CurrentDateTimeComputationEditor';
import EmptyValueType from '../../../../../../../../@Api/Automation/Value/Type/EmptyValueType';
import DateWithTimeComputation from '../../../../../../../../@Api/Automation/Function/Computation/DateWithTimeComputation';
import DateWithTimeComputationEditor, { DateWithTimeComputationEditorProps } from './Type/DateWithTime/DateWithTimeComputationEditor';
import PeriodBetweenDatesComputation from '../../../../../../../../@Api/Automation/Function/Computation/PeriodBetweenDatesComputation';
import PeriodBetweenDatesComputationEditor, { PeriodBetweenDatesComputationEditorProps } from './Type/PeriodBetweenDates/PeriodBetweenDatesComputationEditor';
import QueryFileExportComputation from '../../../../../../../../@Api/Automation/Function/Computation/QueryFileExportComputation';
import QueryFileExportComputationEditor, { QueryFileExportComputationEditorProps } from './Type/QueryFileExport/QueryFileExportComputationEditor';
import MappedCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/MappedCollectionComputation';
import MappedCollectionComputationEditor, { MappedCollectionComputationEditorProps } from './Type/MappedCollection/MappedCollectionComputationEditor';
import LowercaseTextComputation from '../../../../../../../../@Api/Automation/Function/Computation/LowercaseTextComputation';
import LowercaseTextComputationEditor, { LowercaseTextComputationEditorProps } from './Type/LowercaseText/LowercaseTextComputationEditor';
import RoundingComputationEditor, { RoundedNumberComputationEditorProps } from './Type/RoundedNumber/RoundedNumberComputationEditor';
import RoundedNumberComputation from '../../../../../../../../@Api/Automation/Function/Computation/RoundedNumberComputation';
import SingletonCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/SingletonCollectionComputation';
import SingletonCollectionComputationEditor, { SingletonCollectionComputationEditorProps } from './Type/SingletonCollection/SingletonCollectionComputationEditor';
import AggregateOfCollectionComputationEditor, { AggregateOfCollectionComputationEditorProps } from './Type/AggregateOfCollection/AggregateOfCollectionComputationEditor';
import AggregateOfCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/AggregateOfCollectionComputation';
import DataSourceAggregateQueryComputation from '../../../../../../../../@Api/Automation/Function/Computation/DataSourceAggregateQueryComputation';
import DataSourceAggregateQueryComputationEditor, { DataSourceAggregateQueryComputationEditorProps } from './Type/DataSourceAggregateQuery/DataSourceAggregateQueryComputationEditor';
import JoinedCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/JoinedCollectionComputation';
import JoinedCollectionComputationEditor, { JoinedCollectionComputationEditorProps } from './Type/JoinedCollection/JoinedCollectionComputationEditor';
import LocalizedTextComputation from '../../../../../../../../@Api/Automation/Function/Computation/LocalizedTextComputation';
import LocalizedTextComputationEditor, { LocalizedTextComputationEditorProps } from './Type/LocalizedTextComputation/LocalizedTextComputationEditor';
import EntityTypeFromEntityTypeEntityComputation from '../../../../../../../../@Api/Automation/Function/Computation/EntityTypeFromEntityTypeEntityComputation';
import EntityTypeFromEntityTypeEntityComputationEditor, { EntityTypeFromEntityTypeEntityComputationEditorProps } from './Type/EntityTypeFromEntityTypeEntity/EntityTypeFromEntityTypeEntityComputationEditor';
import NameFromFileComputation from '../../../../../../../../@Api/Automation/Function/Computation/NameFromFileComputation';
import NameFromFileComputationEditor, { NameFromFileComputationEditorProps } from './Type/NameFromFile/NameFromFileComputationEditor';
import UrlEncodedTextComputation from '../../../../../../../../@Api/Automation/Function/Computation/UrlEncodedTextComputation';
import UrlEncodedTextComputationEditor, { UrlEncodedTextComputationEditorProps } from './Type/URLEncodedText/URLEncodedTextComputationEditor';
import useSwitch from '../../../../../../../../@Util/Switch/useSwitch';
import AiPromptComputation from '../../../../../../../../@Api/Automation/Function/Computation/AiPromptComputation';
import AiPromptComputationEditor, { AiPromptComputationEditorProps } from './Type/AiPromptComputation/AiPromptComputationEditor';
import AiAudioTranscriptionComputation from '../../../../../../../../@Api/Automation/Function/Computation/AiAudioTranscriptionComputation';
import AiAudioTranscriptionComputationEditor, { AiAudioTranscriptionComputationEditorProps } from './Type/AiAudioTranscription/AiAudioTranscriptionComputationEditor';
import JoinWithDelimiterComputation from "../../../../../../../../@Api/Automation/Function/Computation/JoinWithDelimiterComputation";
import JoinWithDelimiterComputationEditor, { JoinWithDelimiterComputationEditorProps } from "./Type/JoinWithDelimiter/JoinWithDelimiterComputationEditor";

export interface ComputationEditorProps<T extends ValueType<any> = ValueType<any>, C extends Computation<T, Value<any, any>> = Computation<T, Value<any, any>>>
{
    context: FunctionContext;
    type?: T;
    value?: C;
    onChange: (computation?: C) => void;
    fixedType?: boolean;
    constructorItemFilter?: (item: ComputationConstructorItem) => boolean;
    disallowRichText?: boolean;
    disallowTextComputation?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
}

const ComputationEditor: React.FC<ComputationEditorProps> =
    props =>
    {
        const [ isConstructorOpen, openConstructor, closeConstructor ] = useSwitch(false);

        const editor =
            useMemo(
                () =>
                {
                    if (!props.value)
                    {
                        if (props.type instanceof PrimitiveValueType
                            && (props.type.type instanceof TextType || props.type.type instanceof RichTextType)
                            && !props.disallowRichText
                            && !props.disallowTextComputation)
                        {
                            return <TextComputationEditor
                                {...props as TextComputationEditorProps}
                            />;
                        }
                        else if (props.type instanceof PrimitiveValueType
                            && props.type.type instanceof FileType)
                        {
                            return <FileAttachmentComputationEditor
                                {...props as FileAttachmentComputationEditorProps}
                            />;
                        }
                        else
                        {
                            return <ValueEditor
                                {...props as ValueEditorProps}
                            />;
                        }
                    }
                    else if (props.value instanceof Value)
                    {
                        return <ValueEditor
                            {...props as ValueEditorProps}
                        />;
                    }
                    else if (props.value instanceof Parameter)
                    {
                        return <ParameterViewer
                            value={props.value}
                        />;
                    }
                    else if (props.value instanceof ValueFromEntityComputation)
                    {
                        return <ValueFromEntityComputationEditor
                            {...props as ValueFromEntityComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof MathematicalComputation)
                    {
                        return <MathematicalComputationEditor
                            {...props as MathematicalComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof ConditionalComputation)
                    {
                        return <ConditionalComputationEditor
                            {...props as ConditionalComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof LocalizedComputation)
                    {
                        return <LocalizedComputationEditor
                            {...props as LocalizedComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof LocalizedTextComputation)
                    {
                        return <LocalizedTextComputationEditor
                            {...props as LocalizedTextComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof TextComputation)
                    {
                        return <TextComputationEditor
                            {...props as TextComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof LinkComputation)
                    {
                        return <LinkComputationEditor
                            {...props as LinkComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof RangeCollectionComputation)
                    {
                        return <RangeCollectionComputationEditor
                            {...props as RangeCollectionComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof SingletonCollectionComputation)
                    {
                        return <SingletonCollectionComputationEditor
                            {...props as SingletonCollectionComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof LengthOfTextComputation)
                    {
                        return <LengthOfTextComputationEditor
                            {...props as LengthOfTextComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof NumberFromTextComputation)
                    {
                        return <NumberFromTextComputationEditor
                            {...props as NumberFromTextComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof CapitalizedTextComputation)
                    {
                        return <CapitalizedTextComputationEditor
                            {...props as CapitalizedTextComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof LowercaseTextComputation)
                    {
                        return <LowercaseTextComputationEditor
                            {...props as LowercaseTextComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof RegExComputation)
                    {
                        return <RegExComputationEditor
                            {...props as RegExComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof RoundedNumberComputation)
                    {
                        return <RoundingComputationEditor
                            {...props as RoundedNumberComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof FilteredCollectionComputation)
                    {
                        return <FilteredCollectionComputationEditor
                            {...props as FilteredCollectionComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof MappedCollectionComputation)
                    {
                        return <MappedCollectionComputationEditor
                            {...props as MappedCollectionComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof JoinedCollectionComputation)
                    {
                        return <JoinedCollectionComputationEditor
                            {...props as JoinedCollectionComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof FirstElementOfCollectionComputation)
                    {
                        return <FirstElementOfCollectionComputationEditor
                            {...props as FirstElementOfCollectionComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof SizeOfCollectionComputation)
                    {
                        return <SizeOfCollectionComputationEditor
                            {...props as SizeOfCollectionComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof JoinWithDelimiterComputation)
                    {
                        return <JoinWithDelimiterComputationEditor
                            {...props as JoinWithDelimiterComputationEditorProps}
                        />
                    }
                    else if (props.value instanceof AggregateOfCollectionComputation)
                    {
                        return <AggregateOfCollectionComputationEditor
                            {...props as AggregateOfCollectionComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof ValueFromMapComputation)
                    {
                        return <ValueFromMapComputationEditor
                            {...props as ValueFromMapComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof MapWithValueComputation)
                    {
                        return <MapWithValueComputationEditor
                            {...props as MapWithValueComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof MapByComputation)
                    {
                        return <MapByComputationEditor
                            {...props as MapByComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof StartOfDayComputation)
                    {
                        return <StartOfDayComputationEditor
                            {...props as StartOfDayComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof FormattedDateComputation)
                    {
                        return <FormattedDateComputationEditor
                            {...props as FormattedDateComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof TruncatedDateComputation)
                    {
                        return <TruncatedDateComputationEditor
                            {...props as TruncatedDateComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof DateWithTimeComputation)
                    {
                        return <DateWithTimeComputationEditor
                            {...props as DateWithTimeComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof CurrentDateComputation)
                    {
                        return <CurrentDateComputationEditor
                            {...props as CurrentDateComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof CurrentDateTimeComputation)
                    {
                        return <CurrentDateTimeComputationEditor
                            {...props as CurrentDateTimeComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof PeriodBetweenDatesComputation)
                    {
                        return <PeriodBetweenDatesComputationEditor
                            {...props as PeriodBetweenDatesComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof QueryComputation)
                    {
                        return <QueryComputationEditor
                            {...props as QueryComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof QueryFileExportComputation)
                    {
                        return <QueryFileExportComputationEditor
                            {...props as QueryFileExportComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof HtmlFromLayoutComputation)
                    {
                        return <HtmlFromLayoutComputationEditor
                            {...props as HtmlFromLayoutComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof PdfComputation)
                    {
                        return <PdfComputationEditor
                            {...props as PdfComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof MergedPdfComputation)
                    {
                        return <MergedPdfComputationEditor
                            {...props as MergedPdfComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof FileAttachmentComputation)
                    {
                        return <FileAttachmentComputationEditor
                            {...props as FileAttachmentComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof DataSourceValueByIdComputation)
                    {
                        return <DataSourceValueByIdComputationEditor
                            {...props as DataSourceValueByIdComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof DataSourceListQueryComputation)
                    {
                        return <DataSourceListQueryComputationEditor
                            {...props as DataSourceListQueryComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof DataSourceAggregateQueryComputation)
                    {
                        return <DataSourceAggregateQueryComputationEditor
                            {...props as DataSourceAggregateQueryComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof ValueFromDataSourceValueComputation)
                    {
                        return <ValueFromDataSourceValueComputationEditor
                            {...props as ValueFromDataSourceValueComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof EntityFromDataSourceValueComputation)
                    {
                        return <EntityFromDataSourceValueComputationEditor
                            {...props as EntityFromDataSourceValueComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof EntityTypeFromEntityTypeEntityComputation)
                    {
                        return <EntityTypeFromEntityTypeEntityComputationEditor
                            {...props as EntityTypeFromEntityTypeEntityComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof NameFromFileComputation)
                    {
                        return <NameFromFileComputationEditor
                            {...props as NameFromFileComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof IsDutchBsnValidComputation)
                    {
                        return <IsDutchBsnValidComputationEditor
                            {...props as IsDutchBsnValidComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof DynamicComputation)
                    {
                        return <DynamicComputationEditor
                            {...props as DynamicComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof UrlEncodedTextComputation)
                    {
                        return <UrlEncodedTextComputationEditor
                            {...props as UrlEncodedTextComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof AiPromptComputation)
                    {
                        return <AiPromptComputationEditor
                            {...props as AiPromptComputationEditorProps}
                        />;
                    }
                    else if (props.value instanceof AiAudioTranscriptionComputation)
                    {
                        return <AiAudioTranscriptionComputationEditor
                            {...props as AiAudioTranscriptionComputationEditorProps}
                        />;
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props
                ]);

        const valueType =
            useComputed(
                () =>
                {
                    if (props.value
                        && props.value.isValid())
                    {
                        return props.value.getType();
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.value
                ]);

        const isTypeCompatible =
            useComputed(
                () =>
                {
                    if (valueType !== undefined
                        && props.type !== undefined
                        && !valueType.isA(EmptyValueType.instance))
                    {
                        return valueType.isA(props.type);
                    }
                    else
                    {
                        return true;
                    }
                },
                [
                    valueType,
                    props.type
                ]);

        const onChange =
            useCallback(
                (computation) =>
                {
                    if (isConstructorOpen)
                    {
                        closeConstructor();
                    }
                    if (props.onChange)
                    {
                        props.onChange(computation)
                    }
                },
                [
                    isConstructorOpen,
                    closeConstructor,
                    props
                ]
            );

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem
                ratio={1}
            >
                {
                    isTypeCompatible
                        ?
                            editor
                        :
                            <Tooltip
                                title={
                                    <LocalizedText
                                        code="ComputationEditor.Incompatible"
                                        value="Types niet compatibel. Verwacht: ${typeName}, ingevuld: ${valueTypeName}"
                                        typeName={props.type.getName()}
                                        valueTypeName={valueType.getName()}
                                    />
                                }
                            >
                                <div
                                    className={styles.incompatibleTypes}
                                >
                                    {editor}
                                </div>
                            </Tooltip>
                }
            </ViewGroupItem>
            {
                !props.disabled &&
                <ViewGroupItem>
                    <MenuButton
                        onClick={openConstructor}
                        onClose={closeConstructor}
                        open={isConstructorOpen}
                        icon="edit"
                        tooltip={
                            <LocalizedText
                                code="Generic.EditValue"
                                value="Waarde wijzigen"
                            />
                        }
                    >
                        <ComputationConstructor
                            {...props}
                            onChange={onChange}
                        />
                    </MenuButton>
                </ViewGroupItem>
            }
            {
                !props.disabled &&
                <ViewGroupItem>
                    <ComputationOptions
                        {...props}
                    />
                </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(ComputationEditor);
