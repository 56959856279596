import { observable } from 'mobx';
import { OldPredicate } from '../OldPredicate';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';

export class RightPredicate extends OldPredicate
{
    // ------------------------- Properties -------------------------

    @observable rightType: string;
    @observable rightId: number;

    // ------------------------ Constructor -------------------------

    constructor(rightType: string,
                rightId: number)
    {
        super('Right');

        this.rightType = rightType;
        this.rightId = rightId;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getTrueStatements(): OldPredicate[]
    {
        return [ this ];
    }

    getLeafs(): OldPredicate[]
    {
        return [ this ];
    }

    async initialize(): Promise<any>
    {
        return Promise.resolve();
    }

    async toNewPredicate(context: AutomationDependencyContext)
    {
        return undefined;
    }

    // ----------------------- Private logic ------------------------
}
