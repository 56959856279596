import { useContext, useMemo } from 'react';
import CurrentUserContext from '../../../../../../Domain/User/CurrentUserContext';
import useTypes from '../../../../../../Domain/Entity/Type/Api/useTypes';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { useComputed } from 'mobx-react-lite';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import { HeaderNotification } from '../HeaderNotifications';
import useCurrentLicensePeriod from '../../../../../../Domain/License/Api/useCurrentLicensePeriod';
import moment from 'moment';
import { useLocalizer } from '../../../../../../../@Service/Localization/Api/useLocalizer';

export function useBlockCheckerNotifications() : HeaderNotification[]
{
    const currentUserStore = useContext(CurrentUserContext);
    const types = useTypes();
    const localizer = useLocalizer();

    const blockDate =
        useEntityValue<Date>(
            currentUserStore.contractEntity,
            types.LicenseContract.Field.BlockDate
        );
    const cooldownPeriodEndDate =
        useEntityValue<Date>(
            currentUserStore.contractEntity,
            types.LicenseContract.Field.CooldownPeriodEndDate
        );
    const now =
        useMemo(
            () =>
                new Date(),
            []
        );
    const isEnvironmentBlocked =
        useMemo(
            () =>
                (blockDate ? blockDate <= now : false)
                || (cooldownPeriodEndDate ? cooldownPeriodEndDate <= now : false),
            [
                now,
                blockDate,
                cooldownPeriodEndDate,
            ]
        );

    const [, periodEnd, isActivated ] = useCurrentLicensePeriod();
    const numberOfDaysLeftInPeriod =
        useComputed(
            () =>
            {
                return Math.round(
                    moment(periodEnd)
                        .diff(moment(), 'days', true));
            },
            [
                periodEnd
            ]);

    return useComputed(
        () =>
            {
                const notifications = [];

                if (blockDate && !isActivated && numberOfDaysLeftInPeriod <= 0)
                {
                    notifications.push({
                        message: localizeText('Trial.IsExpired', 'Je proefperiode is verlopen.')
                    });
                }

                if (blockDate && isActivated && numberOfDaysLeftInPeriod <= 0)
                {
                    notifications.push({
                        message: localizeText('Contract.IsExpired', 'Je contractperiode is verlopen.')
                    });
                }

                if (blockDate)
                {
                    notifications.push({
                        message:  localizeText(
                            'Contract.ThisEnvironmentWillBeBlocked',
                            'Deze omgeving zal op ${date} geblokkeerd worden.',
                            {
                                date: localizer.formatDateTime(blockDate)
                            }
                        )
                    });
                }

                return notifications;
            },
        [
            isEnvironmentBlocked,
            blockDate,
            isActivated,
            numberOfDaysLeftInPeriod,
        ]
    );
}
