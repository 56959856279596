import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';
import useTypes from '../../../Type/Api/useTypes';

export default function useIsFavoritable(entity: Entity)
{
    const types = useTypes();

    return useComputed(
        () =>
        {
            return false;

            // return entity.entityType.isA(types.Relationship.Type)
            //     || entity.entityType.isA(types.Activity.SalesOpportunity.Type)
            //     || entity.entityType.isA(types.Activity.Project.Type)
            //     || entity.entityType.isA(types.Activity.ProductIssue.Type)
            //     || entity.entityType.bespoke.isConfigurationType();
        },
        [
            entity,
            types
        ]);
}
