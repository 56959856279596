import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { DataObjectSpecification } from './DataObjectSpecification';

export type FileReporter = (fileId: string, file: File) => void;

export class DataDescriptor
{
    // ------------------ Members -----------------------------

    text: string;
    text1: string;
    text2: string;
    text3: string;
    text4: string;
    text5: string;
    text6: string;
    text7: string;
    text8: string;
    text9: string;
    text10: string;
    number1: number;
    number2: number;
    boolean1: boolean;
    boolean2: boolean;
    date1: Date;
    date2: Date;
    dateTime1: Date;
    dateTime2: Date;
    file1: File;
    complex: any;

    // -------------- Constructor -----------------------------

    static construct(descriptor: any, specification: DataObjectSpecification): DataDescriptor
    {
        let dataDescriptor = new DataDescriptor();

        if (typeof descriptor === 'boolean')
        {
            dataDescriptor.boolean1 = descriptor;
        }
        else if (typeof descriptor === 'string')
        {
            dataDescriptor.text = descriptor;
        }
        else if (typeof descriptor === 'number')
        {
            dataDescriptor.number1 = descriptor;
        }
        else if (typeof descriptor === 'object')
        {
            // Avoid Object.assign, because sometimes a TransactionalModel is passed as property
            dataDescriptor.text = descriptor.text;
            dataDescriptor.text1 = descriptor.text1;
            dataDescriptor.text2 = descriptor.text2;
            dataDescriptor.text3 = descriptor.text3;
            dataDescriptor.text4 = descriptor.text4;
            dataDescriptor.text5 = descriptor.text5;
            dataDescriptor.text6 = descriptor.text6;
            dataDescriptor.text7 = descriptor.text7;
            dataDescriptor.text8 = descriptor.text8;
            dataDescriptor.text9 = descriptor.text9;
            dataDescriptor.text10 = descriptor.text10;

            dataDescriptor.number1 = descriptor.number1;
            dataDescriptor.number2 = descriptor.number2;

            dataDescriptor.boolean1 = descriptor.boolean1;
            dataDescriptor.boolean2 = descriptor.boolean2;

            if (descriptor.date1 != null)
            {
                // Note that `new Date(data.text)` will return timestamp with midnight at UTC,
                // while `moment(...).toDate()` will return timestamp with midnight at browser timezone!
                dataDescriptor.date1 = moment(descriptor.date1, 'YYYY-MM-DD').toDate();
            }

            if (descriptor.date2 != null)
            {
                // Note that `new Date(data.text)` will return timestamp with midnight at UTC,
                // while `moment(...).toDate()` will return timestamp with midnight at browser timezone!
                dataDescriptor.date2 = moment(descriptor.date2, 'YYYY-MM-DD').toDate();
            }

            if (descriptor.dateTime1 != null)
            {
                dataDescriptor.dateTime1 = new Date(descriptor.dateTime1);
            }

            if (descriptor.dateTime2 != null)
            {
                dataDescriptor.dateTime2 = new Date(descriptor.dateTime2);
            }

            dataDescriptor.complex = descriptor.complex;
        }

        if (specification)
        {
            const value = specification.type.getUninitializedValueFromData(
                dataDescriptor,
                specification);

            if (value != null)
            {
                dataDescriptor =
                    specification.type.getDataFromValue(
                        value,
                        specification);
            }
        }

        return dataDescriptor;
    }

    clone(): DataDescriptor
    {
        return Object.assign(new DataDescriptor(), this);
    }

    descriptor(onFile?: FileReporter): any
    {
        // Avoid Object.assign, because sometimes this is a TransactionalModel
        let descriptor: any =
        {
            text: this.text,
            text1: this.text1,
            text2: this.text2,
            text3: this.text3,
            text4: this.text4,
            text5: this.text5,
            text6: this.text6,
            text7: this.text7,
            text8: this.text8,
            text9: this.text9,
            text10: this.text10,
            number1: this.number1,
            number2: this.number2,
            boolean1: this.boolean1,
            boolean2: this.boolean2,
            complex: this.complex
        };

        if (this.date1)
        {
            descriptor.date1 = moment(this.date1).format('YYYY-MM-DD');
        }

        if (this.date2)
        {
            descriptor.date2 = moment(this.date2).format('YYYY-MM-DD');
        }

        if (this.dateTime1)
        {
            descriptor.dateTime1 = this.dateTime1.getTime();
        }

        if (this.dateTime2)
        {
            descriptor.dateTime2 = this.dateTime2.getTime();
        }

        if (this.file1)
        {
            const fileId = uuid();

            descriptor.file1 = fileId;

            if (onFile)
            {
                onFile(fileId, this.file1);
            }
        }

        return descriptor;
    }
}
