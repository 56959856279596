import useExpansion from '../../../../../../@Future/Component/Generic/ExpansionPanel/Hook/useExpansion';
import { useCallback, useContext } from 'react';
import NavigatorContext from '../NavigatorContext/NavigatorContext';
import useUniqueItemId from '../../Api/useUniqueItemId';
import { EntityPath } from '../../../Path/@Model/EntityPath';

export default function useSetContextExpansion()
{
    const context = useContext(NavigatorContext);
    const id = useUniqueItemId();
    const [ , setGloballyExpanded ] = useExpansion(id);

    return useCallback(
        (path?: EntityPath) =>
        {
            setGloballyExpanded(path !== undefined);
            context.setSelectedPath(path);
        },
        [
            setGloballyExpanded,
            context
        ]);
}
