import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import SourceField from '../../SourceField';
import getSourceValues from '../../../Api/getSourceValues';
import uuid from '../../../../../../../@Util/Id/uuid';
import ValueToValueSourceFieldMapping from '../ValueToValue/ValueToValueSourceFieldMapping';
import ValueToValueMapping from '../ValueToValue/ValueToValueMapping';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import { EntityTypeType } from '../../../../../DataObject/Type/Api/EntityType/EntityTypeType';
import { DataObjectSpecification } from '../../../../../DataObject/Model/DataObjectSpecification';

export default async function resolveValueToValueSourceFieldMapping(imprt: Entity,
                                                                    targetFieldPath: EntityFieldPath,
                                                                    sourceField: SourceField,
                                                                    newSourceFieldMappingId: string)
{
    const sourceValues = await getSourceValues(imprt, sourceField);

    return new ValueToValueSourceFieldMapping(
        newSourceFieldMappingId,
        targetFieldPath,
        sourceField.id,
        sourceValues.map(
            sourceValue =>
                new ValueToValueMapping(
                    uuid(),
                    sourceValue.value,
                    sourceValue.name,
                    DataObject.constructFromValue(
                        targetFieldPath.field.dataObjectSpecification.type instanceof EntityTypeType
                            ?
                            new DataObjectSpecification(
                                targetFieldPath.field.dataObjectSpecification.type,
                                {
                                    ...targetFieldPath.field.dataObjectSpecification.data,
                                    rootEntityTypeId: targetFieldPath.entityType.id
                                })
                            :
                            targetFieldPath.field.dataObjectSpecification,
                        undefined))));
}
