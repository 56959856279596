import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { LanguageEntry } from '../../Model/Implementation/LanguageEntry';
import { EntityRelationshipDefinitionUpdateMutation } from '../../Model/Implementation/EntityRelationshipDefinitionUpdateMutation';
import { EntityRelationshipDefinitionDeletionMutation } from '../../Model/Implementation/EntityRelationshipDefinitionDeletionMutation';

export class EntityRelationshipDefinitionController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    deleteEntityRelationshipDefinition(id: number): Promise<EntityRelationshipDefinitionDeletionMutation>
    {
            return new Promise<EntityRelationshipDefinitionDeletionMutation>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('user/relationship/definition/delete', Method.Post, { id: id }))
                    .then(result =>
                    {
                        resolve(fromJson(result, EntityRelationshipDefinitionDeletionMutation));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    saveEntityRelationshipDefinition(
        id: number,
        code: string,
        parentEntityTypeId: number,
        childEntityTypeId: number,
        parentFreeFormField: number,
        childFreeFormField: number,
        type: string,
        cardinality: string,
        isDefaultForParent: boolean,
        isDefaultForChild: boolean,
        isMandatoryForParent: boolean,
        isMandatoryForChild: boolean,
        isVisibleInParent: boolean,
        isVisibleInChild: boolean,
        isVisibleAsTabInParent: boolean,
        isVisibleAsTabInChild: boolean,
        isManagedInParent: boolean,
        isManagedInChild: boolean,
        isInactiveCascadedToParent: boolean,
        isInactiveCascadedToChild: boolean,
        isLazyFromParent: boolean,
        isLazyFromChild: boolean,
        parentPredicate: any,
        childPredicate: any,
        parentDeletionAction: string,
        childDeletionAction: string,
        name: LanguageEntry,
        inverseName: LanguageEntry,
        color: string,
        icon: string,
        importanceLevel: string,
        defaultParentPath: any,
        defaultChildPath: any,
        parentComputation: any,
        childComputation: any,
        entity: any
    ): Promise<EntityRelationshipDefinitionUpdateMutation>
    {
        return new Promise<EntityRelationshipDefinitionUpdateMutation>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        '/entity/relationship/definition/save',
                        Method.Post,
                        {
                            id: id,
                            code: code,
                            parent_entity_type_id: parentEntityTypeId,
                            child_entity_type_id: childEntityTypeId,
                            parent_free_form_field: parentFreeFormField,
                            child_free_form_field: childFreeFormField,
                            type: type,
                            cardinality: cardinality,
                            is_default_for_parent: isDefaultForParent,
                            is_default_for_child: isDefaultForChild,
                            is_mandatory_for_parent: isMandatoryForParent,
                            is_mandatory_for_child: isMandatoryForChild,
                            is_visible_in_parent: isVisibleInParent,
                            is_visible_in_child: isVisibleInChild,
                            is_visible_as_tab_in_parent: isVisibleAsTabInParent,
                            is_visible_as_tab_in_child: isVisibleAsTabInChild,
                            is_managed_in_parent: isManagedInParent,
                            is_managed_in_child: isManagedInChild,
                            is_inactive_cascaded_to_parent: isInactiveCascadedToParent,
                            is_inactive_cascaded_to_child: isInactiveCascadedToChild,
                            is_lazy_from_parent: isLazyFromParent,
                            is_lazy_from_child: isLazyFromChild,
                            parent_predicate: parentPredicate,
                            child_predicate: childPredicate,
                            parent_deletion_action: parentDeletionAction,
                            child_deletion_action: childDeletionAction,
                            name: name,
                            inverse_name: inverseName,
                            color: color,
                            icon: icon,
                            importance_level: importanceLevel,
                            default_parent_path: defaultParentPath,
                            default_child_path: defaultChildPath,
                            parent_computation: parentComputation,
                            child_computation: childComputation,
                            entity: entity
                        }
                    )
                )
                .then(result =>
                {
                    resolve(fromJson(result, EntityRelationshipDefinitionUpdateMutation));
                })
                .catch(reason =>
                {
                    reject(reason);
                });
            });
    };
}
