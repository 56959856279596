import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import FunctionContext from '../FunctionContext';
import Value from '../../Value/Value';
import Layout from '../../../Layout/Layout';
import RenderOptions from '../../../Layout/Style/RenderOptions';
import EmptyValue from '../../Value/EmptyValue';
import getLayoutFromDescriptor from '../../../Layout/Api/getLayoutFromDescriptor';
import localizeText from '../../../Localization/localizeText';
import LayoutDependencyContext from '../../../Layout/LayoutDependencyContext';

export default class HtmlFromLayoutComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref layout: Layout;
    @observable.ref renderOptions: RenderOptions;

    // ------------------------ Constructor -------------------------

    constructor(layout: Layout,
                renderOptions: RenderOptions)
    {
        super();

        this.layout = layout;
        this.renderOptions = renderOptions;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('RichText'));
    }

    getName(): string
    {
        return  localizeText('Generic.Layout', 'Layout') + '...';
    }

    isAsync(): boolean
    {
        return false;
    }

    async apply(context: FunctionContext)
    {
        return this.synchronousApply(context);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        return EmptyValue.instance;
    }

    validate(): Validation[]
    {
        const validations: Validation[] = [];

        if (!this.layout)
        {
            validations.push(
                new Validation(
                    'Error',
                    'Geen layout ingevuld'));
        }

        return validations;
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'HtmlFromLayout';
        descriptor.layout = this.layout.toDescriptor();
        descriptor.renderOptions = this.renderOptions.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.layout.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new HtmlFromLayoutComputation(
            await getLayoutFromDescriptor(
                descriptor.layout,
                LayoutDependencyContext.fromAutomationDependencyContext(dependencyContext)
            ),
            RenderOptions.fromDescriptor(
                descriptor.renderOptions));
    }

    // ----------------------- Private logic ------------------------
}
