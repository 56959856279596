import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { ExpansionPanelStore } from './ExpansionPanelStore';
import { CardContent, Theme, withStyles } from '@material-ui/core';
import { backgroundColor } from '../../../@Resource/Theme/Theme';
import Button from '../Button/Button';

// noinspection TsLint
const styles =
    (theme: Theme) =>
        ({
            detailsRoot:
            {
                padding: 0, // if padding is 0, then the shadows get overwritten
            }
        });

@observer
class ExpansionPanelDetails extends BaseComponent<ExpansionPanelStore>
{
    renderComponent(store: ExpansionPanelStore)
    {
        return <div
            className={this.props.classes.detailsRoot}
            style={{
                backgroundColor:
                    store.isExpansionInGrey
                        ?
                            backgroundColor
                        :
                            undefined
            }}
        >
            <div
                style={{
                    width: '100%'
                }}
            >
                {
                    store.isExpanded && store.detailView &&
                        <div
                            style={{
                                padding: `${store.hasVerticalExpansionInset ? 10 : 0}px ${store.hasHorizontalExpansionInset ? 10 : 0}px`,
                                ...store.detailViewStyle
                            }}
                        >
                            {
                                store.wrapExpansionInCardContent &&
                                    <CardContent>
                                        {
                                            React.createElement(
                                                store.detailView.component,
                                                {
                                                    store: store.detailView.store
                                                })
                                        }
                                    </CardContent>
                            }
                            {
                                !store.wrapExpansionInCardContent &&
                                    React.createElement(
                                        store.detailView.component,
                                        {
                                            store: store.detailView.store
                                        })
                            }
                            {
                                store.detailButtonStores && store.detailButtonStores.length > 0 &&
                                    <CardContent
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row-reverse'
                                        }}
                                    >
                                        {
                                            store.detailButtonStores.map(
                                                (buttonStore, idx) =>
                                                    <Button
                                                        key={`detailButton.${idx}`}
                                                        store={buttonStore}
                                                    />
                                            )
                                        }
                                    </CardContent>
                            }
                        </div>
                }
            </div>
        </div>;
    }
}

export default withStyles(styles)(ExpansionPanelDetails as any);
