import { useCallback, useContext, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import LocalizerContext from '../../../../../@Service/Localization/LocalizerContext';

export default function useRelativeTime(fromDate: Moment)
{
    const localizer = useContext(LocalizerContext);

    const getInterval =
        useCallback(
            () =>
            {
                const secondsPassed = moment.duration(moment().diff(fromDate)).asSeconds();

                if (secondsPassed < 45)             // 0s - 44s     a few seconds ago
                {
                    return 20000;                   // interval: 20 seconds
                }
                else if (secondsPassed < 90)        // 45s - 89s    a minute ago
                {
                    return 30000;                   // interval: 30 seconds
                }
                else if (secondsPassed < 2640)      // 90s - 44m    2 minutes ago ... 44m ago
                {
                    return 60000;                   // interval: 1 minute
                }
                else if (secondsPassed < 5340)      // 45m - 89m    an hour ago
                {
                    return 1800000;                 // interval: 30 minutes
                }
                else if (secondsPassed < 75600)     // 90m - 21h    2 hours ago ... 21 hours ago
                {
                    return 3600000;                 // interval: 1 hour
                }
                else
                {
                    return 0;
                }
            },
            [
                fromDate
            ]);

    const getRelativeTime =
        useCallback(
            (fromDate: Moment) =>
            {
                if (fromDate > moment())
                {
                    return localizer.formatDateTimeFromNow(moment());
                }
                else
                {
                    return localizer.formatDateTimeFromNow(fromDate);
                }
            },
            [
                localizer
            ]);


    const [relativeTime, setRelativeTime] =
        useState<string>(
            getRelativeTime(
                fromDate));

    useEffect(
        () =>
        {
            const interval = getInterval();

            if (interval > 0)
            {
                const timerID =
                    setInterval(
                        () => setRelativeTime(
                            getRelativeTime(
                                fromDate)),
                        interval);

                return () =>
                {
                    clearInterval(timerID);
                };
            }
     });

    return relativeTime;
}
