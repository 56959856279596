import { DataObjectBespokeEditorStore } from '../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { computed, untracked } from 'mobx';
import { getRichtextEditorDefaultButtons } from '../../../../Generic/RichText/Editor/RichTextEditorStore';
import { ExpressionEditorStore } from '../../../Expression/Editor/ExpressionEditorStore';
import { ExpressionSpecification } from '../../../Expression/Model/ExpressionSpecification';
import { isTransactionalModel, observe } from '../../../../../@Util/TransactionalModelV2';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { FormEvent } from '../../../../Generic/Form/FormEvent';
import { FormHandlerProperties } from '../../../../Generic/Form/FormHandlerProperties';

export class TextEditorStore extends DataObjectBespokeEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    entersUI(isMounted: boolean)
    {
        super.entersUI(isMounted);

        // Whenever the value of the data object changes by a foreign change, then also change the expression editor

        if (isTransactionalModel(this.baseStore.dataObject))
        {
            this.registerDisposable(
                observe(
                    this.baseStore.dataObject,
                    'value',
                    change =>
                    {
                        if (this.expressionEditorStore != null && !this.expressionEditorStore.isFocused)
                        {
                            this.expressionEditorStore.setExpression(change.newValue as any);
                        }
                    }));
        }
    }

    // -------------------------- Computed --------------------------

    @computed
    get isRichText(): boolean
    {
        return this.baseStore.dataObject.specification.data.isRichText;
    }

    @computed
    get expressionSpecification(): ExpressionSpecification
    {
        if (this.baseStore.dataObject.value)
        {
            if (typeof this.baseStore.dataObject.value === 'string')
            {
                return {
                    expression: this.baseStore.dataObject.value,
                    computations: []
                };
            }
            else
            {
                return this.baseStore.dataObject.value;
            }
        }
        else
        {
            return null;
        }
    }

    @computed
    get formHandlers(): FormHandlerProperties<any>
    {
        let handlers = new FormHandlerProperties(this.baseStore.handlerContext, this.baseStore.dataObject);
        handlers.wrap(
            FormEvent.Change,
            event =>
            {
                let newValue = event.target.value;

                if (newValue && newValue.length > 0)
                {
                    // Disallow new lines
                    newValue = newValue.replace(/\r?\n|\r/g, '');

                    this.baseStore.dataObject.setValue(newValue);
                }
                else
                {
                    this.baseStore.dataObject.setValue(undefined);
                }
            });

        return handlers;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get expressionEditorStore(): ExpressionEditorStore
    {
        if (this.isRichText)
        {
            return new ExpressionEditorStore(
                this.baseStore.dataObject.context,
                // Untracked because otherwise the editor loses focus
                // If the value of the dataobject changes, this is manually synchronized in entersUI
                untracked(() => this.expressionSpecification ? this.expressionSpecification : undefined),
                this.baseStore.isLabelOnSide
                    ?
                        this.localizationStore.translate('Generic.DefaultPlaceholder')  // Fill in your text
                    :
                        undefined,
                [
                    [
                        ...getRichtextEditorDefaultButtons()
                    ]
                ],
                null,
                false,
                false,
                specification =>
                {
                    if (specification)
                    {
                        if (specification && specification.expression === '<p><br></p>')
                        {
                            specification = null;
                        }
                    }

                    this.baseStore.dataObject.setValue(specification);
                });
        }
        else
        {
            return null;
        }
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
