import Role, { Permission, Privilege, RoleType } from '../../../Model/Role';
import { EntityField } from '../../../../../../@Api/Model/Implementation/EntityField';

function getPermission(
    role: Role,
    privilege: Privilege,
    field: EntityField
): Permission
{
    return role.rightByField.get(field)?.getPermission(privilege)
        ?? 'Inherited';
}

export function getFieldSpecifiedPermission(
    rolesByType: Map<RoleType, Role>,
    role: Role,
    privilege: Privilege,
    field: EntityField
): Permission
{
    const permission =
        getPermission(
            role,
            privilege,
            field
        );

    if (permission === 'Inherited')
    {
        const defaultPermission =
            getPermission(
                rolesByType.get('UserDefaultRole'),
                privilege,
                field
            );

        if (defaultPermission === 'Inherited')
        {
            return getPermission(
                rolesByType.get('UserBaseRole'),
                privilege,
                field
            );
        }
        else
        {
            return defaultPermission;
        }
    }
    else
    {
        return permission;
    }
}