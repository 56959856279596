import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import Name from '../../Name/Name';
import Caption from '../../Caption/Caption';
import Click from '../Click/Click';
import Inset from '../Inset/Inset';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Buttons from '../../Navigator/Buttons/Buttons';
import Navigator from '../../Navigator/Navigator';
import Header from '../../../Viewer/Content/Activity/RelatedActivityList/List/List/Item/Header/Header';

export interface DefaultProps
{
    entity: Entity;
}

const Default: React.FC<DefaultProps> =
    props =>
    {
        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <Click
                    open
                    allowExpansionHighlight
                >
                    <Inset>
                        <Name
                            entity={props.entity}
                        />
                        <Caption
                            entity={props.entity}
                        />
                        <Buttons
                            entity={props.entity}
                        />
                    </Inset>
                </Click>
            </ViewGroupItem>
            <ViewGroupItem>
                <Navigator
                    entity={props.entity}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(Default);
