import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../../../Editor/LayoutEditor';
import TableLayout from '../../../../../../../../@Api/Layout/Type/Table/TableLayout';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import TableLayoutCellLocationEditor from '../../Cell/TableLayoutCellLocationEditor';
import TableDimensionSection from '../../../../../../../../@Api/Layout/Type/Table/Model/TableDimensionSection';
import { IObservableArray, runInAction } from 'mobx';
import TableLayoutDimensionSectionConstructor from '../../DimensionSection/TableLayoutDimensionSectionConstructor';
import { groupBy } from '../../../../../../../../@Util/MapUtils/groupBy';
import { ColorUtils } from '../../../../../../../../@Util/Color/ColorUtils';
import DeleteIconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ChildTable from '../../../../../../../../@Api/Layout/Type/Table/Model/ChildTable';
import TableRowSectionsEditor from '../TableRowSectionsEditor';

export interface TableRowSectionEditorProps extends LayoutEditorProps<TableLayout>
{
    rowIdx: number;
    rowSection: TableDimensionSection;
    childTable?: ChildTable;
}

export const TableRowSectionEditor: React.FC<TableRowSectionEditorProps> =
    observer(
        props =>
        {
            const {
                layout,
                rowSection,
                rowIdx,
                childTable,
            } = props;
            const addColumnSection =
                useCallback(
                    (section: TableDimensionSection) =>
                        runInAction(
                            () =>
                                layout.columnSections.push(section)),
                    [
                        layout
                    ]);
            const childTablesByRowSectionId =
                useComputed(
                    () =>
                        groupBy(
                            layout.childTables
                                .filter(
                                    childTable =>
                                        childTable.dimension === 'Row'
                                ),
                            childTable =>
                                childTable.parentSection.id
                        ),
                    [layout]
                );

            return <>
                <TableRow>
                    {
                        layout.columnSections.map(
                            (columnSection, columnIdx) =>
                                <TableCell
                                    key={columnSection.id}
                                >
                                    <TableLayoutCellLocationEditor
                                        {...props}
                                        rowSection={rowSection}
                                        rowSectionEditor={columnIdx === 0}
                                        columnSection={columnSection}
                                        columnSectionEditor={rowIdx === 0 && childTable === undefined}
                                        first={columnIdx === 0 ? rowIdx === 0 : columnIdx === 0}
                                        last={columnIdx === 0 ? rowIdx === layout.rowSections.length - 1 : columnIdx === layout.columnSections.length - 1}
                                        childTable={childTable}
                                    />
                                </TableCell>)
                    }
                    <TableCell
                        style={{
                            width: 0
                        }}
                    >
                        {
                            childTable === undefined
                            && rowIdx === 0
                            &&
                            <TableLayoutDimensionSectionConstructor
                                onConstruct={addColumnSection}
                            />
                        }
                    </TableCell>
                </TableRow>
                {
                    (childTablesByRowSectionId.get(rowSection.id) ?? [])
                        .map(
                            childTable =>
                                <React.Fragment
                                    key={childTable.id}
                                >
                                    <TableRow>
                                        <TableCell
                                            colSpan={layout.columnSections.length + 1}
                                            style={{
                                                backgroundColor: ColorUtils.colorOfString(childTable.id)[900],
                                                color: 'white',
                                            }}
                                        >
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={5}
                                                alignment="center"
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                >
                                                    Child table
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <DeleteIconButton
                                                        onClick={
                                                            () =>
                                                                runInAction(
                                                                    () =>
                                                                        (layout.childTables as IObservableArray)
                                                                            .remove(childTable)
                                                                )
                                                        }
                                                        color="white"
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </TableCell>
                                    </TableRow>
                                    <TableRowSectionsEditor
                                        {...props}
                                        childTable={childTable}
                                    />
                                    <TableRow>
                                        <TableCell
                                            colSpan={layout.columnSections.length + 1}
                                            style={{
                                                backgroundColor: ColorUtils.colorOfString(childTable.id)[900],
                                                height: 10,
                                            }}
                                            padding="none"
                                        />
                                    </TableRow>
                                </React.Fragment>
                        )
                }
            </>;
        }
    );