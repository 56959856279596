import useTypes from '../../Type/Api/useTypes';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import { default as ResourcePlannerModel } from '../Model/ResourcePlanner';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { Comparator } from '../../../DataObject/Model/Comparator';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../DataObject/Model/DataObject';
import ResourceSelection from '../Model/ResourceSelection';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';

export default function useProjectEmployeePlanner(project: Entity,
                                                  milestone?: Entity)
{
    const types = useTypes();

    return useComputed(
        () =>
        {
            const employeeParameter =
                new Parameter(
                    'Employee',
                    new EntityValueType(types.Relationship.Person.Contact.Employee.Type),
                    true,
                    types.Relationship.Person.Contact.Employee.Type.getName());

            const teamParameter =
                new Parameter(
                    'Team',
                    new EntityValueType(types.Team.Type),
                    false,
                    types.Team.Type.getName());

            const skillParameter =
                new Parameter(
                    'Skill',
                    new EntityValueType(types.Datastore.Skill.Type),
                    false,
                    types.Datastore.Skill.Type.getName());

            const functionParameter =
                new Parameter(
                    'Function',
                    new EntityValueType(types.Datastore.Function.Type),
                    false,
                    types.Datastore.Function.Type.getName());

            const workOrderParameter =
                types.Activity.WorkOrder.Type &&
                    new Parameter(
                        'WorkOrder',
                        new EntityValueType(types.Activity.WorkOrder.Type),
                        true,
                        types.Activity.WorkOrder.Type.getName());

            const appointmentParameter =
                new Parameter(
                    'Appointment',
                    new EntityValueType(types.Activity.Appointment.Type),
                    true,
                    types.Activity.Appointment.Type.getName());

            const taskParameter =
                new Parameter(
                    'Task',
                    new EntityValueType(types.Activity.Task.Type),
                    true,
                    types.Activity.Task.Type.getName());

            const timeRegistrationParameter =
                types.TimeRegistration.Type &&
                    new Parameter(
                        'TimeRegistration',
                        new EntityValueType(types.TimeRegistration.Type),
                        true,
                        types.TimeRegistration.Type.getName());

            const activityPredicateBuilder =
                (parameter: Parameter<EntityValueType>) =>
                    new CompositePredicate(
                        LogicalOperator.And,
                        [
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    parameter,
                                    parameter.type.type.path()
                                        .joinTo(
                                            types.Activity.RelationshipDefinition.LinkedActivities,
                                            true)
                                        .field()),
                                Comparator.Equals,
                                new EntityValue(project)),
                            ...milestone
                                ?
                                    [
                                        new ComparisonPredicate(
                                            new ValueFromEntityComputation(
                                                parameter,
                                                parameter.type.type.path()
                                                    .joinTo(
                                                        types.Milestone.RelationshipDefinition.Activities,
                                                        true)
                                                    .field()),
                                            Comparator.Equals,
                                            new EntityValue(milestone))
                                    ]
                                :
                                    []
                        ]);

            return new ResourcePlannerModel(
                employeeParameter,
                new ParameterDictionary([
                    teamParameter,
                    skillParameter,
                    functionParameter,
                ]),
                new CompositePredicate(
                    LogicalOperator.And,
                    [
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .field(types.Relationship.Person.Contact.Employee.Field.IsSupport)),
                            Comparator.Equals,
                            new PrimitiveValue(
                                DataObject.constructFromTypeIdAndValue(
                                    'Boolean',
                                    false))),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .field(types.Relationship.Field.IsFormer)),
                            Comparator.Equals,
                            new PrimitiveValue(
                                DataObject.constructFromTypeIdAndValue(
                                    'Boolean',
                                    false))),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .joinTo(
                                        types.Team.RelationshipDefinition.Members,
                                        true)
                                    .field()),
                            Comparator.Equals,
                            teamParameter),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .joinTo(
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Skills,
                                        false)
                                    .field()),
                            Comparator.Equals,
                            skillParameter
                        ),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .joinTo(
                                        types.Relationship.Person.Contact.RelationshipDefinition.Function,
                                        false)
                                    .field()),
                            Comparator.Equals,
                            functionParameter
                        ),
                    ]),
                [
                    workOrderParameter &&
                        new ResourceSelection(
                            workOrderParameter,
                            EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                .joinTo(
                                    types.Activity.WorkOrder.RelationshipDefinition.Employee,
                                    false),
                            EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                .field(types.Activity.WorkOrder.Field.StartDate),
                            EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                .field(types.Activity.WorkOrder.Field.EndDate),
                            undefined,
                            activityPredicateBuilder(workOrderParameter),
                            true,
                        ),
                    new ResourceSelection(
                        appointmentParameter,
                        EntityPath.fromEntityType(types.Activity.Appointment.Type)
                            .joinTo(
                                types.Activity.Appointment.RelationshipDefinition.Owner,
                                false),
                        EntityPath.fromEntityType(types.Activity.Appointment.Type)
                            .field(types.Activity.Appointment.Field.StartDate),
                        EntityPath.fromEntityType(types.Activity.Appointment.Type)
                            .field(types.Activity.Appointment.Field.EndDate),
                        undefined,
                        activityPredicateBuilder(appointmentParameter),
                        true,
                    ),
                    new ResourceSelection(
                        taskParameter,
                        EntityPath.fromEntityType(types.Activity.Task.Type)
                            .joinTo(
                                types.Activity.Task.RelationshipDefinition.Assignee,
                                false),
                        EntityPath.fromEntityType(types.Activity.Task.Type)
                            .field(types.Activity.Task.Field.StartDate),
                        EntityPath.fromEntityType(types.Activity.Task.Type)
                            .field(types.Activity.Task.Field.EndDate),
                        undefined,
                        new CompositePredicate(
                            LogicalOperator.And,
                            [
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        taskParameter,
                                        EntityPath.fromEntityType(types.Activity.Task.Type)
                                            .field(types.Activity.Task.Field.HasCalendarItem)),
                                    Comparator.Equals,
                                    new PrimitiveValue(
                                        DataObject.constructFromTypeIdAndValue(
                                            'Boolean',
                                            true))),
                                activityPredicateBuilder(taskParameter)
                            ]),
                        true,
                    ),
                    timeRegistrationParameter &&
                        new ResourceSelection(
                            timeRegistrationParameter,
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                    true),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .field(types.TimeRegistration.Field.StartDate),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .field(types.TimeRegistration.Field.EndDate),
                            undefined,
                            new CompositePredicate(
                                LogicalOperator.And,
                                [
                                    new ComparisonPredicate(
                                        new ValueFromEntityComputation(
                                            timeRegistrationParameter,
                                            timeRegistrationParameter.type.type.path()
                                                .joinTo(
                                                    types.Activity.RelationshipDefinition.TimeRegistrations,
                                                    true)
                                                .field()),
                                        Comparator.Equals,
                                        new EntityValue(project)),
                                    ...milestone
                                        ?
                                            [
                                                new ComparisonPredicate(
                                                    new ValueFromEntityComputation(
                                                        timeRegistrationParameter,
                                                        timeRegistrationParameter.type.type.path()
                                                            .joinTo(
                                                                types.Milestone.RelationshipDefinition.TimeRegistrations,
                                                                true)
                                                            .field()),
                                                    Comparator.Equals,
                                                    new EntityValue(milestone))
                                            ]
                                        :
                                            []
                                ]),
                            undefined,
                        )
                ].filter(selection => selection)
            );
        },
        [
            types
        ]);
}
