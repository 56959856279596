import React, { useCallback, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import useTypes from '../../../../../../Type/Api/useTypes';
import Centered from '../../../../../../../../../@Future/Component/Generic/Centered/Centered';
import HoverCardBottom from '../../../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import HoverCardMiddle from '../../../../../../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import performAction from '../../../../../../../../../@Api/Entity/performAction';
import localizeText from '../../../../../../../../../@Api/Localization/localizeText';
import useDividerGlue from '../../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import constructEntity from '../../../../../../../../../@Api/Entity/constructEntity';
import { openEntity } from '../../../../../../@Util/openEntity';
import Popper from '../../../../../../../../../@Future/Component/Generic/Popper/Popper';
import RightAlignedButtonGroup from '../../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import useSwitch from '../../../../../../../../../@Util/Switch/useSwitch';
import Selectbox from '../../../../../../Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../../../../../Selection/Builder/EntitySelectionBuilder';
import SelectButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/SelectButton/SelectButton';
import Input from '../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import useSetting from '../../../../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../../../../Setting/SettingStore';
import { Setting } from '../../../../../../../../../@Api/Settings/Setting';
import { constructEntityOfType } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { useNewCommitContext } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { updateRelationship } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export interface TimeSheetProcessingOptionsProps
{
    relationship: Entity;
    activity?: Entity;
    milestone?: Entity;
    selectedLines: Entity[];
    onChangeSelectedLines: (lines: Entity[]) => void;
}

const TimeSheetProcessingOptions: React.FC<TimeSheetProcessingOptionsProps> =
    props =>
    {
        const { relationship, activity, milestone, selectedLines, onChangeSelectedLines } = props;
        const types = useTypes();
        const isProjectActivity =
            useMemo(
                () =>
                    activity && activity.entityType === types.Activity.Project.Type,
                [
                    activity,
                    types,
                ]
            );

        const billableLines =
            useComputed(
                () =>
                    types.Activity.Invoice.Type
                        ?
                            selectedLines
                                .filter(
                                    line =>
                                        line.getObjectValueByField(types.TimeRegistration.Field.IsBillable))
                        :
                            [],
                [
                    types,
                    selectedLines,
                    types
                ]);

        const [ combineTimeRegistrationsWhenProcessing ] =
            useSetting<boolean>(
                SettingSource.Organization,
                Setting.Invoicing.CombineTimeRegistrationsWhenProcessing);

        const processLines =
            useCallback(
                (doBill: boolean, targetActivity: Entity | undefined = activity) =>
                    performAction(
                        relationship,
                        {
                            code: 'TimeRegistration.ProcessTimeRegistrations',
                            parameters: {
                                timeRegistrationIds: selectedLines.map(line => line.uuid),
                                doBill: doBill,
                                activityId: targetActivity?.uuid,
                                doCombineTimeRegistrations: combineTimeRegistrationsWhenProcessing
                            },
                            name: localizeText('TimeRegistration.Processed', 'Urenregistraties verwerkt')
                        })
                        .then(
                            () =>
                                onChangeSelectedLines([])),
                [
                    selectedLines,
                    types,
                    onChangeSelectedLines,
                    relationship,
                    activity,
                    combineTimeRegistrationsWhenProcessing
                ]);

        const processLinesWithoutBilling =
            useCallback(
                () =>
                    processLines(false),
                [
                    processLines
                ]);

        const processLinesAndBill =
            useCallback(
                () =>
                    processLines(true),
                [
                    processLines
                ]);

        const commitContext =
            useNewCommitContext(
                undefined,
                {
                    allowAutoCommit: false,
                }
            );
        const processLinesAndBillInNewInvoice =
            useCallback(
                () =>
                {
                    const invoice =
                        constructEntityOfType(
                            types.Activity.Invoice.Type,
                            commitContext
                        );

                    updateRelationship(
                        invoice,
                        true,
                        types.Relationship.RelationshipDefinition.Activities,
                        relationship,
                        commitContext
                    );

                    if (activity)
                    {
                        updateRelationship(
                            invoice,
                            true,
                            types.Activity.RelationshipDefinition.LinkedActivities,
                            activity,
                            commitContext
                        );
                    }

                    if (milestone)
                    {
                        updateRelationship(
                            invoice,
                            true,
                            types.Milestone.RelationshipDefinition.Activities,
                            milestone,
                            commitContext
                        );
                    }

                    return constructEntity(
                        types.Activity.Invoice.Type,
                        undefined,
                        invoice,
                        () =>
                            processLines(true, invoice)
                                .then(
                                    () =>
                                        openEntity(invoice)),
                        false,
                        undefined,
                        undefined,
                        undefined,
                        commitContext
                    );
                },
                [
                    types,
                    relationship,
                    activity,
                    processLines,
                    commitContext,
                ]);

        const [ isInvoiceSelectorOpen, openInvoiceSelector, closeInvoiceSelector ] = useSwitch(false);
        const [ selectedInvoice, setSelectedInvoice ] = useState<Entity | undefined>(undefined);
        const invoiceSelections =
            useMemo(
                () =>
                    types.Activity.Invoice.Type
                        ?
                            [
                                EntitySelectionBuilder.build(
                                    types.Activity.Invoice.Type,
                                    (builder, rootPath) =>
                                        builder
                                            .where(
                                                cb =>
                                                    cb.relatedToEntity(
                                                        rootPath.joinTo(
                                                            types.Relationship.RelationshipDefinition.Activities,
                                                            true),
                                                        relationship))
                                            .where(
                                                cb =>
                                                    cb.eq(
                                                        rootPath
                                                            .joinTo(
                                                                types.Activity.Invoice.RelationshipDefinition.Phase,
                                                                false)
                                                            .field(types.Datastore.Field.Code),
                                                        undefined,
                                                        types.Activity.Invoice.Phase.Concept)))
                            ]
                        :
                            [],
                [
                    types,
                    relationship
                ]);
        const processLinesAndBillInExistingInvoice =
            useCallback(
                () =>
                    processLines(true, selectedInvoice)
                        .then(
                            () =>
                                openEntity(selectedInvoice)),
                [
                    processLines,
                    selectedInvoice
                ]);

        const dividerGlue = useDividerGlue();

        return <ViewGroup
            orientation="vertical"
            spacing={0}
            glue={dividerGlue}
        >
            {
                billableLines.length === 0 &&
                    <ViewGroupItem>
                        <HoverCardBottom
                            onClick={processLinesWithoutBilling}
                            disabled={selectedLines.length === 0}
                        >
                            {
                                selectedLines.length === 1
                                    ?
                                        <LocalizedText
                                            code="TimeSheet.ProcessLines.Singular"
                                            value="Verwerk ${numberOfLines} regel"
                                            numberOfLines={selectedLines.length}
                                        />
                                    :
                                        <LocalizedText
                                            code="TimeSheet.ProcessLines.Plural"
                                            value="Verwerk ${numberOfLines} regels"
                                            numberOfLines={selectedLines.length}
                                        />
                            }
                        </HoverCardBottom>
                    </ViewGroupItem>
            }
            {
                billableLines.length > 0 &&
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                            glue={dividerGlue}
                        >
                            <ViewGroupItem>
                                <CardInset>
                                    <Centered
                                        horizontal
                                    >
                                        <b>
                                            {
                                                selectedLines.length === 1
                                                    ?
                                                        <LocalizedText
                                                            code="TimeSheet.ProcessLines.Singular"
                                                            value="Verwerk ${numberOfLines} regel"
                                                            numberOfLines={selectedLines.length}
                                                        />
                                                    :
                                                        <LocalizedText
                                                            code="TimeSheet.ProcessLines.Plural"
                                                            value="Verwerk ${numberOfLines} regels"
                                                            numberOfLines={selectedLines.length}
                                                        />
                                            }
                                            {
                                                billableLines.length > 0 &&
                                                    <>
                                                        , <LocalizedText
                                                            code="TimeSheet.OfLines"
                                                            value="waarvan ${numberOfLines} declarabel"
                                                            numberOfLines={billableLines.length}
                                                        />
                                                    </>
                                            }...
                                        </b>
                                    </Centered>
                                </CardInset>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <HoverCardMiddle
                                    onClick={processLinesWithoutBilling}
                                >
                                    <LocalizedText
                                        code="TimeSheet.ProcessLinesWithoutBilling"
                                        value="Zonder facturatie"
                                    />
                                </HoverCardMiddle>
                            </ViewGroupItem>
                            {
                                isProjectActivity &&
                                    <ViewGroupItem>
                                        <HoverCardMiddle
                                            onClick={processLinesAndBill}
                                        >
                                            <LocalizedText
                                                code="TimeSheet.ProcessLinesWithBilling"
                                                value="Naar te factureren"
                                            />
                                        </HoverCardMiddle>
                                    </ViewGroupItem>
                            }
                            <ViewGroupItem>
                                <HoverCardMiddle
                                    onClick={processLinesAndBillInNewInvoice}
                                >
                                    <LocalizedText
                                        code="TimeSheet.ProcessLinesToNewInvoice"
                                        value="Naar nieuwe factuur"
                                    />
                                </HoverCardMiddle>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <Popper
                                    reference={
                                        <HoverCardBottom
                                            onClick={openInvoiceSelector}
                                        >
                                            <LocalizedText
                                                code="TimeSheet.ProcessLinesToExistingInvoice"
                                                value="Naar bestaande conceptfactuur"
                                            />
                                        </HoverCardBottom>
                                    }
                                    popper={
                                        <Card
                                            inset
                                        >
                                            <ViewGroup
                                                orientation="vertical"
                                                spacing={15}
                                            >
                                                <ViewGroupItem>
                                                    <Input
                                                        label={types.Activity.Invoice.Type.getName()}
                                                        labelPosition="top"
                                                    >
                                                        <Selectbox
                                                            selections={invoiceSelections}
                                                            value={selectedInvoice}
                                                            onChange={setSelectedInvoice as any}
                                                            autoFocus
                                                        />
                                                    </Input>
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <RightAlignedButtonGroup>
                                                        <SelectButton
                                                            onClick={processLinesAndBillInExistingInvoice}
                                                        />
                                                    </RightAlignedButtonGroup>
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </Card>
                                    }
                                    open={isInvoiceSelectorOpen}
                                    onClose={closeInvoiceSelector}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(TimeSheetProcessingOptions);
