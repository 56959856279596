import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../../../../../@Component/Domain/Entity/Type/Api/useTypes';
import { EntitySelectionBuilder } from '../../../../../../../../../@Component/Domain/Entity/Selection/Builder/EntitySelectionBuilder';
import useAsyncResult from '../../../../../../../../../@Util/Async/useAsyncResult';
import getEntityByUuid from '../../../../../../../../../@Api/Entity/Bespoke/getEntityByUuid';
import Selectbox from '../../../../../../../../../@Component/Domain/Entity/Selectbox/Selectbox';
import { observer } from 'mobx-react-lite';
import TemplateContext from '../TemplateContext';
import { EntityPath } from '../../../../../../../../../@Component/Domain/Entity/Path/@Model/EntityPath';

export interface TemplateSelectorProps
{
    templateId: string;
    onChange: (template: Entity) => void;
}

const TemplateSelector: React.FC<TemplateSelectorProps> =
    props =>
    {
        const types = useTypes();
        const [ template, _setTemplate ] = useState<Entity>();

        const { onChange } = props;
        const setTemplate =
            useCallback(
                (template: Entity) =>
                {
                    _setTemplate(template);
                    onChange(template)
                },
                [
                    _setTemplate,
                    onChange
                ]);

        useAsyncResult(
            async () =>
            {
                if (props.templateId)
                {
                    setTemplate(
                        (await getEntityByUuid(
                            types.Template.Block.Type,
                            props.templateId)).value);
                }
            },
            [
                props.templateId
            ]);

        // Avoid loops
        const parentTemplate = useContext(TemplateContext);

        const selections =
            useMemo(
                () => [
                    // Only allow templates of the type
                    new EntitySelectionBuilder(types.Template.Block.Type)
                        // Avoid loops
                        .if(
                            () =>
                                parentTemplate !== undefined,
                            sb =>
                                sb
                                    .where(
                                        cb =>
                                            cb.notRelatedToEntity(
                                                sb.rootPath,
                                                parentTemplate))
                                    .where(
                                        cb =>
                                            cb.relatedToEntity(
                                                EntityPath.fromEntityType(types.Template.Block.Type)
                                                    .joinTo(
                                                        types.Template.Block.RelationshipDefinition.TemplateType,
                                                        false),
                                                parentTemplate.entityType.entity)))
                        .build()
                ],
                [
                    types,
                    parentTemplate
                ]);

        return <Selectbox
            value={template}
            onChange={setTemplate}
            selections={selections}
        />;
    };

export default observer(TemplateSelector);
