import Widget from '../../Widget';
import { observable, runInAction } from 'mobx';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../Type/EntityTypeStore';
import collectAndInitializeByEntityIds from '../../../Api/collectAndInitializeByEntityIds';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';

export default class EntityWidget extends Widget
{
    // ------------------------- Properties -------------------------

    @observable.ref entityType: EntityType;
    @observable entityId?: string;
    @observable.ref entity: Entity;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                entityType: EntityType,
                entityId?: string)
    {
        super(id);

        this.entityType = entityType;
        this.entityId = entityId;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async doInitialize(widgets: EntityWidget[])
    {
        return collectAndInitializeByEntityIds(
            widgets,
            widget => widget.entityId,
            async (widget, entity) =>
                runInAction(
                    () =>
                        widget.entity = entity
                ),
            loadModuleDirectly(EntityTypeStore).bespoke.types.Entity.Type);
    }

    static fromDescriptor(descriptor: any)
    {
        return new EntityWidget(
            descriptor.id,
            loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.entityTypeId),
            descriptor.entityId);
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Entity',
            entityTypeId: this.entityType?.id,
            entityId: this.entityId
        }
    }

    // ----------------------- Private logic ------------------------
}
