import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { PathBreadcrumbProps } from '../PathBreadcrumb';
import Link from '../../../../../../../@Future/Component/Generic/Link/Link';
import EntityTypeContext from '../../../../Type/EntityTypeContext';
import styles from './BreadcrumbNode.module.scss';

export interface BreadcrumbNodeProps extends PathBreadcrumbProps
{
    idx: number;
}

const BreadcrumbNode: React.FC<BreadcrumbNodeProps> =
    props =>
    {
        const entityTypeStore = useContext(EntityTypeContext);

        const onSelect =
            useCallback(
                () =>
                    props.onSelect(
                        props.path.pathAt(props.idx + 1)),
                [
                    props.onSelect,
                    props.path,
                    props.idx
                ]);

        return <div
            className={styles.root}
        >
            <Link
                onClick={onSelect}
                highlighted={props.idx < props.path.nodes.length - 1}
            >
                {props.path.nodes[props.idx].getName(entityTypeStore)}
            </Link>
        </div>;
    };

export default observer(BreadcrumbNode);
