import { CustomWidgetParams } from './CustomWidgetParams';
import ParameterDictionary from '../../Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../Automation/Parameter/ParameterAssignment';
import EntityValue from '../../Automation/Value/EntityValue';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/index';
import { CurrentUserStore } from '../../../@Component/Domain/User/CurrentUserStore';

export function getCustomWidgetParameterAssignment(
    parameters: ParameterDictionary
)
{
    return new ParameterAssignment()
        .setValue(
            parameters.getParameterById(CustomWidgetParams.User),
            new EntityValue(
                loadModuleDirectly(CurrentUserStore).employeeEntity
            )
        );
}
