import React, { useCallback, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import moment from 'moment';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import DatePicker from '../../../../../@Future/Component/Generic/Input/DatePicker/DatePicker';
import TimePicker from '../../../../../@Future/Component/Generic/Input/TimePicker/TimePicker';
import styles from './Editor.module.scss';
import TimeDurationPicker from '../../../../../@Future/Component/Generic/Input/TimeDurationPicker/TimeDurationPicker';
import Switch from '../../../../../@Future/Component/Generic/Input/Switch/Switch';
import { EntityField } from '../../../../../@Api/Model/Implementation/EntityField';
import CancelButton from '../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import SaveButton from '../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import { DateTimeType } from '../../../DataObject/Type/Date/DateTime/DateTimeType';
import RightAlignedButtonGroup from '../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import useIsMobile from '../../../../../@Util/Responsiveness/useIsMobile';
import useEmployeePlanner from '../../ResourcePlanner/Type/useEmployeePlanner';
import TabBar from '../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import ResourcePlanner from '../../ResourcePlanner/ResourcePlanner';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import { EntityPath } from '../../Path/@Model/EntityPath';
import DayDurationPicker from '../../../../../@Future/Component/Generic/Input/DayDurationPicker/DayDurationPicker';
import DateRangePicker from '../../../../../@Future/Component/Generic/Input/DateRangePicker/DateRangePicker';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';

export type OnPlan =
    (startDateField?: EntityField,
     date?: Date,
     endDateField?: EntityField,
     endDate?: Date,
     employeePath?: EntityPath,
     employee?: Entity) => void;

export interface EntityPlannerProps
{
    entity: Entity;
    onClose: OnPlan;
    startDateField: EntityField;
    endDateField: EntityField;
    employeePath?: EntityPath;
    commitContext?: CommitContext;
}

export const Editor: React.FC<EntityPlannerProps> = observer(
    props =>
    {
        const [ tab, setTab ] = useState(0);
        const employeePlanner = useEmployeePlanner();

        const startDate =
            useMemo(
                () =>
                {
                    const date =
                        props.entity.getObjectValueByField<Date>(
                            props.startDateField,
                            props.commitContext
                        );

                    if (date)
                    {
                        return date;
                    }
                    else
                    {
                        return new Date();
                    }
                },
                [
                    props.entity,
                    props.startDateField,
                    props.commitContext,
                ]);

        const startHour =
            useMemo(
                () =>
                    startDate
                        ?
                            moment(startDate).get('hour')
                        :
                            12,
                [
                    startDate
                ]);

        const startMinute =
            useMemo(
                () =>
                    startDate
                        ?
                            moment(startDate).get('minute')
                        :
                            0,
                [
                    startDate
                ]);

        const hasTime =
            useComputed(
                () =>
                    props.startDateField.dataObjectSpecification.type instanceof DateTimeType,
                [
                    props.startDateField
                ]);

        const startEndDate =
            useMemo<Date>(
                () =>
                    props.entity.getObjectValueByField(
                        props.endDateField,
                        props.commitContext
                    ),
                [
                    props.entity,
                    props.endDateField,
                    props.commitContext,
                ]);

        const startDurationHours =
            useMemo(
                () =>
                    startDate && startEndDate
                        ?
                            moment(startEndDate).diff(startDate, 'hours')
                        :
                            1,
                [
                    startDate,
                    startEndDate
                ]);

        const startDurationMinutes =
            useMemo(
                () =>
                    startDate && startEndDate
                        ?
                            moment(startEndDate).subtract(startDurationHours, 'hours').diff(startDate, 'minutes')
                        :
                            0,
                [
                    startDate,
                    startEndDate,
                    startDurationHours
                ]);

        const startIsAllDay =
            useMemo(
                () =>
                    !hasTime ||
                    (
                        startDate.getHours() === 0 &&
                        startDate.getMinutes() === 0 &&
                        startEndDate.getHours() === 0 &&
                        startEndDate.getMinutes() === 0
                    ),
                [
                    hasTime,
                    startDate,
                    startEndDate
                ]);

        const [ date, setDate ] = useState(startDate);
        const [ hour, setHour ] = useState(startHour);
        const [ minute, setMinute ] = useState(startMinute);
        const [ durationHours, setDurationHours ] = useState(startDurationHours);
        const [ durationMinutes, setDurationMinutes ] = useState(startDurationMinutes);
        const [ dayDuration, setDayDuration ] = useState(() => Math.ceil(durationHours / 24));
        const endDate =
            useMemo(
                () =>
                    moment(date)
                    .add(durationHours, 'hours')
                    .add(durationMinutes, 'minutes')
                    .toDate(),
                [
                    date,
                    durationHours,
                    durationMinutes
                ]);

        const [ isAllDay, setAllDay ] = useState(startIsAllDay);

        const { onClose } = props;

        const onDateChange =
            useCallback(
                (date: Date) =>
                {
                    setDate(date);

                    if (!hasTime)
                    {
                        onClose(props.startDateField, date);
                    }
                },
                [
                    hasTime,
                    props.startDateField,
                    onClose
                ]);

        const onTimeChange =
            useCallback(
                (hour: number, minute: number) =>
                {
                    setHour(hour);
                    setMinute(minute);
                },
                [
                    setHour,
                    setMinute
                ]);

        const changeDayDuration =
            useCallback(
                (days: number) =>
                {
                    setDayDuration(days);
                    setDurationHours(24 * days);
                    setDurationMinutes(0);
                },
                [
                    setDayDuration,
                    setDurationHours,
                    setDurationMinutes
                ]
            )

        const saveCallback =
            useCallback(
                () =>
                {
                    if (date)
                    {
                        let planDate = moment(date).startOf('minute');
                        if (hasTime)
                        {
                            planDate.set('hour', hour);
                            planDate.set('minute', minute);
                        }

                        const newEndDate = planDate.clone();
                        newEndDate.add(durationHours, 'hours');
                        newEndDate.add(durationMinutes, 'minutes');

                        return onClose(
                            props.startDateField,
                            planDate.toDate(),
                            props.endDateField,
                            newEndDate.toDate());
                    }
                },
                [
                    props.startDateField,
                    props.endDateField,
                    date,
                    hasTime,
                    hour,
                    minute,
                    durationHours,
                    durationMinutes,
                    onClose
                ]);

        const cancelCallback =
            useCallback(
                () =>
                    onClose(),
                [
                    onClose
                ]);

        const toggleAllDay =
            useCallback(
                (isAllDay: boolean) =>
                {
                    setAllDay(isAllDay);

                    if (!isAllDay && hour === 0)
                    {
                        setHour(12);
                        setMinute(0);
                        setDurationHours(1);
                        setDurationMinutes(0);
                    }
                    else if (isAllDay)
                    {
                        setHour(0);
                        setMinute(0);
                        setDurationHours(24);
                        setDurationMinutes(0);
                    }
                },
                [
                    setAllDay,
                    hour,
                    setHour,
                    setMinute,
                    setDurationHours,
                    setDurationMinutes
                ]);

        const updateDuration =
            useCallback(
                (hours: number, minutes: number) =>
                {
                    setDurationHours(hours);
                    setDurationMinutes(minutes);
                },
                [
                    setDurationHours,
                    setDurationMinutes
                ]);

        const onDatesChange =
            useCallback(
                (startDate: Date, endDate: Date) =>
                {
                    if (!endDate)
                    {
                        endDate =
                            moment(startDate)
                                .add(1, 'day')
                                .toDate();
                    }

                    const diffInMins =
                        moment(endDate)
                            .diff(startDate, 'minutes');

                    setDayDuration(
                        moment(endDate)
                            .diff(startDate, 'days')
                    );

                    setDate(startDate);
                    updateDuration(
                        Math.floor(diffInMins / 60),
                        diffInMins % 60);
                },
                [
                    setDate,
                    setDayDuration,
                    updateDuration
                ]);

        const onPlan =
            useCallback(
                (employee, startDate, endDate) =>
                    props.onClose(
                        props.startDateField,
                        startDate,
                        props.endDateField,
                        endDate,
                        props.employeePath,
                        employee),
                [
                    props
                ]);

        const isMobile = useIsMobile();

        return <Card
            classes={{
                root: styles.cardRoot
            }}
        >
            <TabBar
                value={tab}
            >
                <Tab
                    value={0}
                    onClick={setTab}
                >
                    <LocalizedText
                        code="Generic.Period"
                        value="Periode"
                    />
                </Tab>
                <Tab
                    value={1}
                    onClick={setTab}
                >
                    <LocalizedText
                        code="Generic.Planner"
                        value="Planner"
                    />
                </Tab>
            </TabBar>
            {
                tab === 0 &&
                    <CardInset>
                        <ViewGroup
                            orientation="vertical"
                            spacing={10}
                        >
                            <ViewGroupItem>
                                {
                                    isAllDay
                                        ?
                                            <DateRangePicker
                                                startDate={date}
                                                endDate={endDate}
                                                onChange={onDatesChange}
                                            />
                                        :
                                            <DatePicker
                                                value={date}
                                                onChange={onDateChange}
                                            />
                                }
                            </ViewGroupItem>
                            {
                                date &&
                                    <ViewGroupItem>
                                        <ViewGroup
                                            orientation="vertical"
                                            spacing={5}
                                        >
                                            <ViewGroupItem>
                                                <ViewGroup
                                                    orientation={isMobile ? 'vertical' : 'horizontal'}
                                                    spacing={isMobile ? 5 : 0}
                                                    alignment={isMobile ? undefined : 'center'}
                                                >
                                                    <ViewGroupItem
                                                        ratio={isMobile ? undefined : 1}
                                                    >
                                                        <ViewGroup
                                                            orientation={isMobile ? 'vertical' : 'horizontal'}
                                                            spacing={isMobile ? 10 : 30}
                                                            alignment={isMobile ? undefined : 'center'}
                                                        >
                                                            {
                                                                !isAllDay &&
                                                                    <ViewGroupItem>
                                                                        <ViewGroup
                                                                            orientation="horizontal"
                                                                            spacing={10}
                                                                            alignment="center"
                                                                        >
                                                                            <ViewGroupItem
                                                                                ratio={isMobile ? undefined : 1}
                                                                            >
                                                                                <LocalizedText
                                                                                    code="Planner.At"
                                                                                    value="Om"
                                                                                    lowerCase
                                                                                />
                                                                            </ViewGroupItem>
                                                                            <ViewGroupItem>
                                                                                <TimePicker
                                                                                    hour={hour}
                                                                                    minute={minute}
                                                                                    onChange={onTimeChange}
                                                                                    classes={{root: styles.timePicker}}
                                                                                />
                                                                            </ViewGroupItem>
                                                                        </ViewGroup>
                                                                    </ViewGroupItem>
                                                            }
                                                            <ViewGroupItem>
                                                                <ViewGroup
                                                                    orientation="horizontal"
                                                                    spacing={10}
                                                                >
                                                                    <ViewGroupItem
                                                                        ratio={isMobile ? undefined : 1}
                                                                    >
                                                                        <LocalizedText
                                                                            code="Generic.Duration"
                                                                            value="Duratie"
                                                                            lowerCase
                                                                        />
                                                                    </ViewGroupItem>
                                                                    <ViewGroupItem>
                                                                        <div
                                                                            onClick={(event) => event.stopPropagation()}
                                                                        >
                                                                            {
                                                                                isAllDay
                                                                                    ?
                                                                                        <DayDurationPicker
                                                                                            value={dayDuration}
                                                                                            onChange={changeDayDuration}
                                                                                        />
                                                                                    :
                                                                                        <TimeDurationPicker
                                                                                            hours={durationHours}
                                                                                            minutes={durationMinutes}
                                                                                            onChange={updateDuration}
                                                                                        />
                                                                            }
                                                                        </div>
                                                                    </ViewGroupItem>
                                                                </ViewGroup>
                                                            </ViewGroupItem>
                                                        </ViewGroup>
                                                    </ViewGroupItem>
                                                    <ViewGroupItem
                                                        alignment={isMobile ? undefined : 'right'}
                                                    >
                                                        <ViewGroup
                                                            orientation="vertical"
                                                            spacing={10}
                                                        >
                                                            <ViewGroupItem>
                                                                <ViewGroup
                                                                    orientation="horizontal"
                                                                    spacing={5}
                                                                    alignment="center"
                                                                >
                                                                    <ViewGroupItem
                                                                        ratio={isMobile ? undefined : 1}
                                                                    />
                                                                    <ViewGroupItem>
                                                                        <LocalizedText
                                                                            code="Generic.AllDay"
                                                                            value="Hele dag"
                                                                            lowerCase
                                                                        />
                                                                    </ViewGroupItem>
                                                                    <ViewGroupItem>
                                                                        <Switch
                                                                            onToggle={toggleAllDay}
                                                                            checked={isAllDay}
                                                                        />
                                                                    </ViewGroupItem>
                                                                </ViewGroup>
                                                            </ViewGroupItem>
                                                        </ViewGroup>
                                                    </ViewGroupItem>
                                                </ViewGroup>
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <RightAlignedButtonGroup>
                                                    <CancelButton
                                                        onClick={cancelCallback}
                                                    />
                                                    <SaveButton
                                                        onClick={saveCallback}
                                                    />
                                                </RightAlignedButtonGroup>
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </ViewGroupItem>
                            }
                        </ViewGroup>
                    </CardInset>
            }
            {
                tab === 1 &&
                    <div
                        className={styles.resourcePlanner}
                    >
                        <ResourcePlanner
                            resourcePlanner={employeePlanner}
                            onPlan={onPlan}
                        />
                    </div>
            }
        </Card>;
    }
);