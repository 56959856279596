import React, { useCallback, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import { IObservableArray, runInAction } from 'mobx';
import InputGroup from '../../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TabBar from '../../../../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import MergedPdfComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/MergedPdfComputation';
import MergedPdfComputationPdf from '../../../../../../../../../../@Api/Automation/Function/Computation/MergedPdfComputationPdf';
import uuid from '../../../../../../../../../../@Util/Id/uuid';
import EmptyValue from '../../../../../../../../../../@Api/Automation/Value/EmptyValue';
import RightAlignedButtonGroup from '../../../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import DeleteButton from '../../../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';

export interface MergedPdfComputationEditorProps extends ComputationEditorProps<ValueType<any>, MergedPdfComputation>
{

}

const MergedPdfComputationEditor: React.FC<MergedPdfComputationEditorProps> =
    props =>
    {
        const setFileName =
            useCallback(
                (fileName: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.fileName = fileName),
                [
                    props.value
                ]);

        const [ tab, setTab ] = useState<string | 'Settings'>(props.value.pdfs.length > 0 ? props.value.pdfs[0].id : 'Settings');
        const pdf =
            useComputed(
                () =>
                    props.value.pdfs.find(pdf => pdf.id === tab),
                [
                    props.value,
                    tab
                ]);
        const setPdf =
            useCallback(
                (value: Computation<any, any>) =>
                    runInAction(
                        () =>
                            pdf.value = value),
                [
                    pdf
                ]);

        const createPdf =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            const id = uuid();

                            props.value.pdfs.push(
                                new MergedPdfComputationPdf(
                                    id,
                                    new EmptyValue()));

                            setTab(id);
                        }),
                [
                    props.value,
                    setTab
                ]);

        const deletePdf =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            (props.value.pdfs as IObservableArray).remove(pdf);
                            setTab(props.value.pdfs.length > 0 ? props.value.pdfs[0].id : 'Settings');
                        }),
                [
                    props.value,
                    pdf,
                    setTab
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <TabBar
                    value={tab}
                >
                    {
                        props.value.pdfs.map(
                            (pdf, idx) =>
                                <Tab
                                    key={pdf.id}
                                    onClick={setTab}
                                    value={pdf.id}
                                >
                                    PDF #{idx + 1}
                                </Tab>)
                    }
                    <Tab
                        onClick={createPdf}
                        value="+"
                    >
                        +
                    </Tab>
                    <Tab
                        onClick={setTab}
                        value="Settings"
                    >
                        <LocalizedText
                            code="Generic.Settings"
                            value="Instellingen"
                        />
                    </Tab>
                </TabBar>
            </ViewGroupItem>
            {
                pdf &&
                    <ViewGroupItem>
                        <ComputationEditor
                            context={props.context}
                            value={pdf.value}
                            onChange={setPdf}
                        />
                    </ViewGroupItem>
            }
            {
                pdf &&
                    <ViewGroupItem>
                        <RightAlignedButtonGroup>
                            <DeleteButton
                                onClick={deletePdf}
                            />
                        </RightAlignedButtonGroup>
                    </ViewGroupItem>
            }
            {
                !pdf &&
                    <ViewGroupItem>
                        <InputGroup>
                            <Input
                                labelPosition="left"
                                label={
                                    <LocalizedText
                                        code="Generic.FileName"
                                        value="Bestandsnaam"
                                    />
                                }
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={5}
                                    alignment="center"
                                >
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        <ComputationEditor
                                            context={props.context}
                                            value={props.value.fileName}
                                            onChange={setFileName}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        .pdf
                                    </ViewGroupItem>
                                </ViewGroup>
                            </Input>
                        </InputGroup>
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(MergedPdfComputationEditor);
