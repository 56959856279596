import { NumberSequenceDefinition } from './DefinitionBuilder';

export default function sanitizeNumberSequenceDefinition(definition: NumberSequenceDefinition): NumberSequenceDefinition
{
    return {
        components:
            definition.components.filter(
                component =>
                    !(
                        (!component.type || component.type === 'Text' || component.type === 'FieldPath') &&
                        (!component.data || component.data === '')))
    };
}
