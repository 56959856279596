import Parameter from '../../Parameter/Parameter';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Computation from '../Computation/Computation';
import ParameterDictionary from '../../Parameter/ParameterDictionary';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import { observable } from 'mobx';
import FunctionContext from '../FunctionContext';
import ParameterAssignment from '../../Parameter/ParameterAssignment';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import Dependency from '../../Parameter/Dependency';
import Validation from '../../Validation/Validation';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';

export default class DynamicParameterAssignment
{
    // ------------------------- Properties -------------------------

    @observable.shallow computationByParameter: Map<Parameter<any>, Computation<any, any>>;

    // ------------------------ Constructor -------------------------

    constructor(valueByParameter: Map<Parameter<any>, Computation<any, any>> = new Map())
    {
        this.computationByParameter = valueByParameter;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isAsync(): boolean
    {
        return Array.from(this.computationByParameter.values())
            .some(
                computation =>
                    computation.isAsync()
            );
    }

    async apply(context: FunctionContext)
    {
        const parameterAssignment = new ParameterAssignment();

        await Promise.all(
            Array.from(this.computationByParameter.entries())
                .map(
                    ([ parameter, computation ]) =>
                        safelyApplyFunction(computation, context)
                            .then(
                                result =>
                                    parameterAssignment.setValue(
                                        parameter,
                                        result))));

        return parameterAssignment;
    }

    synchronouslyApply(context: FunctionContext)
    {
        const parameterAssignment = new ParameterAssignment();

        Array.from(this.computationByParameter.entries())
            .map(
                ([ parameter, computation ]) =>
                    parameterAssignment.setValue(
                        parameter,
                        safelySynchronousApplyFunction(computation, context)));

        return parameterAssignment;
    }

    getComputation(parameter: Parameter<any>)
    {
        return this.computationByParameter.get(parameter);
    }

    setComputation(parameter: Parameter<any>,
                   computation: Computation<any, any>): this
    {
        this.computationByParameter.set(
            parameter,
            computation);

        return this;
    }

    getDependencies(): Dependency[]
    {
        return Array.from(this.computationByParameter.values())
            .map(
                computation =>
                    computation.getDependencies())
            .reduce((a, b) => a.concat(b), []);
    }

    validate(): Validation[]
    {
        return Array.from(this.computationByParameter.values())
            .map(
                computation =>
                    computation.validate())
            .reduce((a, b) => a.concat(b), []);
    }

    toDescriptor(): any[]
    {
        return Array.from(this.computationByParameter.entries())
            .map(
                ([ parameter, computation ]) => ({
                    parameterId: parameter.id,
                    computation: computation.toDescriptor()
                }));
    }

    getWithNewParameters(newParameters: ParameterDictionary)
    {
        const computationByParameterId =
            new Map(
                Array.from(this.computationByParameter)
                    .map(
                        ([ parameter, computation ]) => [
                            parameter.id,
                            computation
                        ]));

        const computationByNewParameter =
            new Map(
                newParameters
                    .parameters
                    .filter(
                        newParameter =>
                            computationByParameterId.has(newParameter.id))
                    .map(
                       newParameter => [
                           newParameter,
                           computationByParameterId.get(newParameter.id)
                       ]));

        return new DynamicParameterAssignment(computationByNewParameter);
    }

    static async fromDescriptor(descriptor: any[],
                                parameterDictionary: ParameterDictionary,
                                dependencyContext: AutomationDependencyContext)
    {
        const assignments =
            descriptor
                .filter(
                    assignment =>
                        parameterDictionary.hasParameterId(assignment.parameterId));

        const computations =
            await Promise.all(
                assignments.map(
                    assignment =>
                        getComputationFromDescriptor(
                            assignment.computation,
                            dependencyContext)));

        return new DynamicParameterAssignment(
            new Map(
                assignments
                    .map(
                        (assignment, idx) => [
                            parameterDictionary.getParameterById(assignment.parameterId),
                            computations[idx]
                        ])));
    }

    // ----------------------- Private logic ------------------------
}
