import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import styles from './InsetLayoutView.module.scss';
import InsetLayout from '../../../../../../@Api/Layout/Type/InsetLayout';

export interface InsetLayoutViewProps
{
    layout: InsetLayout;
}

const InsetLayoutView: React.FC<InsetLayoutViewProps> =
    props =>
    {
        const style =
            useComputed(
                () => ({
                    paddingTop: props.layout.top,
                    paddingRight: props.layout.right,
                    paddingBottom: props.layout.bottom,
                    paddingLeft: props.layout.left,
                    width: '100%'
                }),
                [
                    props.layout
                ]);

        return <div
            className={styles.root}
            style={style}
        >
            {props.children}
        </div>;
    };

export default observer(InsetLayoutView);
