import { DataObjectBespokeViewerStore } from '../../../Viewer/DataObjectBespokeViewerStore';
import { computed } from 'mobx';
import { LocalizedTextType } from '../LocalizedTextType';

export class TextViewerStore extends DataObjectBespokeViewerStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get translation()
    {
        return (this.baseStore.dataObject.specification.type as LocalizedTextType).getTranslation(this.baseStore.dataObject.value);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
