import Layout from '../../Layout';
import Dependency from '../../../Automation/Parameter/Dependency';
import Validation from '../../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../../LayoutDependencyContext';
import localizeText from '../../../Localization/localizeText';
import getDependenciesWithoutParameters from '../../../Automation/Api/getDependenciesWithoutParameters';
import PortalDataSourceSignature from '../../../Portal/DataSource/PortalDataSourceSignature';
import DynamicParameterAssignment from '../../../Automation/Function/Dynamic/DynamicParameterAssignment';
import { PortalTableColumn } from './Model/PortalTableColumn';
import { PortalTableOrdering } from './Model/PortalTableOrdering';
import LayoutEventTriggers from '../../Event/LayoutEventTriggers';
import Parameter from '../../../Automation/Parameter/Parameter';
import DataSourceValueType from '../../../Automation/Value/Type/DataSourceValueType';
import getPortalDataSourceSignatureById from '../../../Portal/DataSource/Api/getPortalDataSourceSignatureById';
import AutomationDependencyContext from '../../../Automation/AutomationDependencyContext';

export default class PortalTableLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref dataSourceSignature: PortalDataSourceSignature;
    @observable.ref parameterAssignment: DynamicParameterAssignment;
    @observable.ref rowParameter: Parameter<DataSourceValueType>;
    @observable.shallow columns: PortalTableColumn[];
    @observable.shallow orderings: PortalTableOrdering[];
    @observable.ref rowEventTriggers: LayoutEventTriggers;

    // ------------------------ Constructor -------------------------

    constructor(
        dataSourceSignature: PortalDataSourceSignature,
        parameterAssignment: DynamicParameterAssignment,
        rowParameter: Parameter<DataSourceValueType>,
        columns: PortalTableColumn[],
        orderings: PortalTableOrdering[],
        rowEventTriggers: LayoutEventTriggers
    )
    {
        super();

        this.dataSourceSignature = dataSourceSignature;
        this.parameterAssignment = parameterAssignment;
        this.rowParameter = rowParameter;
        this.columns = columns;
        this.orderings = orderings;
        this.rowEventTriggers = rowEventTriggers;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.PortalTable', 'Portaal tabel');
    }

    validate(): Validation[]
    {
        return [
            ...this.parameterAssignment.validate(),
            ...this.columns
                .map(column => column.validate())
                .reduce((a, b) => a.concat(b), []),
            ...this.rowEventTriggers.validate(),
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.parameterAssignment.getDependencies(),
            ...this.columns
                .map(column => column.getDependencies(this.rowParameter))
                .reduce((a, b) => a.concat(b), []),
            ...getDependenciesWithoutParameters(
                this.rowEventTriggers.getDependencies(),
                this.rowParameter
            ),
        ];
    }

    toDescriptor()
    {
        return {
            type: 'PortalTable',
            dataSourceId: this.dataSourceSignature.id,
            parameterAssignment: this.parameterAssignment.toDescriptor(),
            rowParameterId: this.rowParameter.id,
            columns:
                this.columns.map(
                    column =>
                        column.toDescriptor()
                ),
            orderings:
                this.orderings.map(
                    ordering =>
                        ordering.toDescriptor()
                ),
            rowEventTriggers: this.rowEventTriggers.toDescriptor(),
        };
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: LayoutDependencyContext
    )
    {
        const dataSourceId = descriptor.dataSourceId;
        const dataSourceSignature = await getPortalDataSourceSignatureById(dataSourceId);
        const parameterAssignment =
            await DynamicParameterAssignment.fromDescriptor(
                descriptor.parameterAssignment,
                dataSourceSignature.parameters,
                dependencyContext
            );
        const rowParameterId = descriptor.rowParameterId;
        const rowParameter =
            PortalTableLayout.buildRowParameter(
                rowParameterId,
                dataSourceSignature
            );
        const columns =
            await Promise.all<PortalTableColumn>(
                (descriptor.columns || []).map(
                    column =>
                        PortalTableColumn.fromDescriptor(
                            column,
                            dependencyContext,
                            rowParameter,
                            dataSourceSignature
                        )
                )
            );
        const orderings =
            await Promise.all<PortalTableOrdering>(
                (descriptor.orderings || []).map(
                    ordering =>
                        PortalTableOrdering.fromDescriptor(ordering)
                )
            );
        const rowEventTriggerDependencyContext =
            new AutomationDependencyContext(
                dependencyContext.parameterDictionary
                    .getNewDictionaryWithParameter(
                        rowParameter
                    )
            );
        const rowEventTriggers =
            await LayoutEventTriggers.fromDescriptor(
                descriptor.rowEventTriggers,
                LayoutDependencyContext.fromAutomationDependencyContext(
                    rowEventTriggerDependencyContext
                )
            );

        return new PortalTableLayout(
            dataSourceSignature,
            parameterAssignment,
            rowParameter,
            columns,
            orderings,
            rowEventTriggers,
        );
    }

    static buildRowParameter(
        id: string,
        dataSourceSignature: PortalDataSourceSignature
    )
    {
        return new Parameter<DataSourceValueType>(
            id,
            new DataSourceValueType(
                dataSourceSignature.id,
                undefined,
                dataSourceSignature
            ),
            true,
            dataSourceSignature.name
        );
    }

    // ----------------------- Private logic ------------------------
}
