import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import FunctionContext from '../../../../../../../../../../../@Api/Automation/Function/FunctionContext';
import PredicateEditor from '../../../../Predicate/PredicateEditor';
import ComparisonPredicate from '../../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import { Comparator } from '../../../../../../../../../DataObject/Model/Comparator';
import ValueFromEntityComputation from '../../../../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { QueryEditorProps } from './QueryEditor';
import Predicate from '../../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';

export interface QueryFilterEditorProps extends QueryEditorProps
{

}

const QueryFilterEditor: React.FC<QueryFilterEditorProps> =
    props =>
    {
        const { query, context } = props;

        const setFilter =
            useCallback(
                (filter: Predicate) =>
                    runInAction(
                        () =>
                            query.filter = filter),
                [
                    query
                ]);

        const filterContext =
            useComputed(
                () =>
                {
                    return new FunctionContext(
                        context.parameterDictionary.getNewDictionaryWithParameter(query.parameter),
                        undefined,
                        context.commitContext
                    );
                },
                [
                    context,
                    query
                ]);

        const comparisonConstructor =
            useCallback(
                () =>
                    new ComparisonPredicate(
                        new ValueFromEntityComputation(query.parameter, undefined),
                        Comparator.Equals,
                        undefined),
                [
                    query
                ]);

        return <PredicateEditor
            context={filterContext}
            value={props.query.filter}
            onChange={setFilter}
            comparisonConstructor={comparisonConstructor}
        />;
    };

export default observer(QueryFilterEditor);
