import { findAssistantFieldPath } from '../findAssistantFieldPath';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Action from '../../../../../@Api/Automation/Function/Action/Action';
import { AssistantUpdateEntityAction } from '../../Model/Automation/Action/AssistantUpdateEntityAction';
import UpdateEntityAction from '../../../../../@Api/Automation/Function/Action/UpdateEntityAction';
import { findAssistantComputation } from '../Computation/findAssistantComputation';

export function findAssistantUpdateEntityAction(
    action: AssistantUpdateEntityAction,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Action<any, any>
{
    const entity = parameters.getParameterById(action.entityVariable) as Parameter<any>;

    if (entity.type instanceof EntityValueType)
    {
        const entityType = entity.type.type;
        const fieldPath =
            findAssistantFieldPath(
                entityType.path(),
                action.field
            );
        const value =
            findAssistantComputation(
                action.value,
                parameters,
                rootParameter
            );

        return new UpdateEntityAction(
            entity,
            fieldPath,
            value
        );
    }
    else
    {
        throw new Error(`Expected entity value type, but got: ${entity.getType().id()} in ${JSON.stringify(action)}`);
    }
}
