import { useContext, useMemo } from 'react';
import Trigger from '../../../../../../Action/Api/Trigger';
import RouterContext from '../../../../../../../../@Service/Router/RouterContext';
import { LinkExternalEmailPageStore } from './LinkExternalEmailPageStore';
import ExternalEmail from '../Model/ExternalEmail';

export default function useLinkExternalEmailTrigger()
{
    const routerStore = useContext(RouterContext);

    return useMemo<Trigger<ExternalEmail>>(
        () =>
            ({
                type: 'Activity.Email.LinkExternalEmail',
                onEvent:
                    event =>
                    {
                        const pageStore = new LinkExternalEmailPageStore(event.data);

                        routerStore.route(
                            pageStore.routePath,
                            undefined,
                            undefined,
                            pageStore,
                            true);
                    }
            }),
        [
            routerStore
        ]);
}
