import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ParameterDictionary from '../../../../@Api/Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../../../@Api/Automation/Parameter/ParameterAssignment';
import LayoutEditor from '../Editor/LayoutEditor';
import LayoutViewer from '../Viewer/LayoutViewer';
import Layout from '../../../../@Api/Layout/Layout';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import RightAlignedButtonGroup from '../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import DangerTextButton from '../../../../@Future/Component/Generic/Button/Variant/DangerTextButton/DangerTextButton';
import CancelButton from '../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import SaveButton from '../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import CompositeLayout from '../../../../@Api/Layout/Type/CompositeLayout';
import HoverCardMiddle from '../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';

export interface ResettableLayoutEditorProps
{
    parameterDictionary: ParameterDictionary;
    parameterAssignment: ParameterAssignment;
    isInEditMode: boolean;
    onCloseEditMode: () => void;
    layout?: Layout;
    onChangeLayout: (layout?: Layout) => void;
    commitContext?: CommitContext;
}

export const ResettableLayoutEditor: React.FC<ResettableLayoutEditorProps> =
    observer(
        props =>
        {
            const parameterDictionary = props.parameterDictionary;
            const parameterAssignment = props.parameterAssignment;
            const [innerLayout, setInnerLayout] = useState<Layout | undefined>(props.layout);
            useEffect(
                () =>
                    setInnerLayout(props.layout),
                [props.layout]
            );
            const setLayout = props.onChangeLayout;
            const close =
                useCallback(
                    () =>
                    {
                        props.onCloseEditMode();
                    },
                    [
                        props.onCloseEditMode
                    ]);
            const clear =
                useCallback(
                    () =>
                    {
                        setLayout(undefined);

                        props.onCloseEditMode();
                    },
                    [
                        setLayout,
                        props.onCloseEditMode
                    ]);
            const save =
                useCallback(
                    () =>
                    {
                        setLayout(innerLayout);
                        props.onCloseEditMode();
                    },
                    [
                        innerLayout,
                        setLayout,
                        props.onCloseEditMode
                    ]);

            if (props.isInEditMode)
            {
                return <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    <ViewGroupItem>
                        {
                            innerLayout
                                ? <LayoutEditor
                                    layout={innerLayout}
                                    onChange={setInnerLayout}
                                    parameterDictionary={parameterDictionary}
                                    parameterAssignment={parameterAssignment}
                                    commitContext={props.commitContext}
                                />
                                : <HoverCardMiddle
                                    onClick={
                                        () =>
                                            setInnerLayout(
                                                new CompositeLayout(
                                                    'Vertical',
                                                    []
                                                )
                                            )
                                    }
                                >
                                    <LocalizedText
                                        code="SetLayout"
                                        value="Layout instellen"
                                    />
                                </HoverCardMiddle>
                        }
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <CardInset
                            top={false}
                        >
                            <RightAlignedButtonGroup>
                                {
                                    props.layout &&
                                    <DangerTextButton
                                        label={
                                            <LocalizedText
                                                code="Generic.ResetToFactorySettings"
                                                value="Terug naar fabrieksinstellingen"
                                            />
                                        }
                                        onClick={clear}
                                    />
                                }
                                <CancelButton
                                    onClick={close}
                                />
                                <SaveButton
                                    onClick={save}
                                />
                            </RightAlignedButtonGroup>
                        </CardInset>
                    </ViewGroupItem>
                </ViewGroup>;
            }
            else if (props.layout)
            {
                return <LayoutViewer
                    layout={props.layout}
                    parameterDictionary={parameterDictionary}
                    parameterAssignment={parameterAssignment}
                    commitContext={props.commitContext}
                />;
            }
            else
            {
                return null;
            }
        }
    );
