import { reference } from '../../../@Util/Serialization/Serialization';
import { ConstraintNode } from './ConstraintNode';
import { observable } from 'mobx';
import { Entity } from './Entity';
import { EntityEvent } from './EntityEvent';
import { EntityNode } from './EntityNode';

export class NotConstraintNode extends ConstraintNode
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'ConstraintNode') @observable.ref childNode: ConstraintNode;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(childNode: ConstraintNode)
    {
        super();

        this.childNode = childNode;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getDependencies(): EntityNode[]
    {
        return [
            ...super.getDependencies(),
            ...this.childNode.getDependencies(),
        ];
    }

    hashCode(): string
    {
        return `${super.hashCode()},${this.childNode.hashCode()}`;
    }

    equals(node: NotConstraintNode): boolean
    {
        return super.equals(node)
            && this.childNode.equals(node.childNode);
    }

    matches(entity: Entity): boolean
    {
        return !this.childNode.matches(entity);
    }

    isAffectedBy(event: EntityEvent): boolean
    {
        return this.childNode.isAffectedBy(event);
    }

    descriptor()
    {
        return {
            type: 'Not',
            childNode: this.childNode.descriptor()
        };
    }

    // ----------------------- Private logic ------------------------
}
