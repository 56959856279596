import AutomationDependencyContext from '../../../../@Api/Automation/AutomationDependencyContext';
import { ViewParams } from '../../Entity/View/Model/ViewParams';

export function getParameterInOldComputation(
    context: AutomationDependencyContext,
    entityContextParameter?: string
)
{
    // E.g. datasets have as parameter the letter 'T', while views has as parameter {ViewParams.Entity}
    if (entityContextParameter !== undefined
        && context.parameterDictionary.hasParameterId(entityContextParameter))
    {
        return context.parameterDictionary.getParameterById(entityContextParameter);
    }
    else
    {
        return context.parameterDictionary.getParameterById(ViewParams.Entity) as any;
    }
}