import { ListStore } from '../../../../../../Generic/List/V2/ListStore';
import { RelatedEntityGroup } from './RelatedEntityGroup';
import { ViewComponent } from '../../../../../../Generic/ViewStack/Model/ViewComponent';
import List from '../../../../../../Generic/List/V2/List';
import { TextStore } from '../../../../../../Generic/Text/TextStore';
import { ExpansionPanelListItemStore } from '../../../../../../Generic/List/V2/ExpansionPanel/ExpansionPanelListItemStore';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { ButtonStore } from '../../../../../../Generic/Button/ButtonStore';
import { RelatedEntityConstructorModalStore } from '../../../../Constructor/Related/Modal/RelatedEntityConstructorModelStore';
import { RelatedEntityConstructorStore } from '../../../../Constructor/Related/RelatedEntityConstructorStore';
import { computed, observable, runInAction } from 'mobx';
import { createEntityListNameComparator, EntityListStore } from '../../../../List/EntityListStore';
import { EntityViewerStore } from '../../EntityViewerStore';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import { EntityTypeStore } from '../../../../Type/EntityTypeStore';
import { DataObjectStore } from '../../../../../DataObject/DataObjectStore';
import { ExpansionPanelStore } from '../../../../../../Generic/ExpansionPanel/ExpansionPanelStore';
import { EntityPathJoinNode } from '../../../../Path/@Model/Node/EntityPathJoinNode';
import { EntityExpansionListStore } from '../ExpansionList/EntityExpansionListStore';
import ExpansionPanel from '../../../../../../Generic/ExpansionPanel/ExpansionPanel';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { LocalizationStore } from '../../../../../../../@Service/Localization/LocalizationStore';
import { ViewGroupStore } from '../../../../../../Generic/ViewGroup/ViewGroupStore';
import { ViewGroupItem } from '../../../../../../Generic/ViewGroup/ViewGroupItem';
import { CurrentUserStore } from '../../../../../User/CurrentUserStore';
import { createStringComparator } from '../../../../../../Generic/List/V2/Comparator/StringComparator';
import { RelatedEntityPath } from '../../../../Path/@Model/RelatedEntityPath';
import { injectWithQualifier, loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/index';
import { RouterStore } from '../../../../../../../@Service/Router/RouterStore';
import { FeedbackStore } from '../../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';

function getNameOfPath(path: EntityPath, entityTypeStore: EntityTypeStore): string
{
    return path.lastNode instanceof EntityPathJoinNode
        ?
            path.lastJoinNode.relationshipDefinition.getName(
                path.lastJoinNode.isParent,
                path.entityType,
                entityTypeStore)
        :
            path.entityType.namePlural;
}

function getCountOfPath(relativePath: RelatedEntityPath,
                        panelStore: ExpansionPanelStore,
                        listStore: RelatedEntityGroupListStore): number
{
    return panelStore.detailView.store.isInitialized
        ?
            ((panelStore.detailView.store as EntityExpansionListStore).query as Entity[]).length
        :
            listStore.pathCountMap.has(relativePath.id)
                ?
                    listStore.pathCountMap.get(relativePath.id)
                :
                    0;
}

export class RelatedEntityGroupListStore extends ViewGroupStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('RouterStore') routerStore: RouterStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;

    // ------------------------- Properties -------------------------

    @observable viewerStore: EntityViewerStore;
    @observable pathCountMap = observable.map<string, number>();
    @observable doReloadEvents: () => Promise<any>;
    @observable groups: RelatedEntityGroup[];
    @observable hideGroupsWhenEmpty: boolean;

    // ------------------------ Constructor -------------------------

    constructor(groups: RelatedEntityGroup[],
                viewerStore: EntityViewerStore,
                doReloadEvents: () => Promise<any>,
                hideGroupsWhenEmpty: boolean = false)
    {
        super({
            items: []
        });

        this.hideGroupsWhenEmpty = hideGroupsWhenEmpty;
        this.groups = groups;
        this.viewerStore = viewerStore;
        this.doReloadEvents = doReloadEvents;

        this.props.items =
            groups.map(
                group =>
                    this.getViewGroupItem(
                        group,
                        viewerStore,
                        doReloadEvents));
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return this.reloadPathCountMap()
            .then(() => super.initialize());
    }

    // -------------------------- Computed --------------------------

    @computed
    get count(): number
    {
        let count = 0;

        this.groups.forEach(
            group =>
                count += this.getGroupCount(group));

        return count;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getButtonStores(store: ExpansionPanelStore,
                    relativeAddPath?: RelatedEntityPath,
                    ignoreSourceRelationshipDefinitionDuringAdding: boolean = false,
                    group?: RelatedEntityGroup): ButtonStore[]
    {
        if (relativeAddPath && relativeAddPath.entity)
        {
            return [
                new ButtonStore(
                    {
                        icon: 'add',
                        tooltip: () => this.localizationStore.translate('Generic.Add'), // Add
                        color: 'default',
                        isVisible: () => this.currentUserStore.rightProfile.canUpdate(relativeAddPath.entity),
                        onClick:
                            () =>
                            {
                                const feedbackStore = loadModuleDirectly(FeedbackStore);
                                const closeDialog =
                                    feedbackStore.openDialog({
                                        store:
                                            new RelatedEntityConstructorModalStore(
                                                new RelatedEntityConstructorStore(
                                                    relativeAddPath.entity,
                                                    relativeAddPath.path,
                                                    undefined,
                                                    undefined,
                                                    ignoreSourceRelationshipDefinitionDuringAdding),
                                                entity =>
                                                {
                                                    store.expand();

                                                    if (group)
                                                    {
                                                        group.addEntities([ entity ]);
                                                    }

                                                    return this.doReloadEvents();
                                                },
                                                () => closeDialog())
                                    });
                            }
                    })
            ];
        }
        else
        {
            return [];
        }
    }

    // ----------------------- Private logic ------------------------

    private reloadPathCountMap(): Promise<any>
    {
        return Promise.all(
            this.groups.map(
                group =>
                    group.relativePaths
                        .map(
                            path =>
                                this.getSelectionBuilderByPath(path)
                                    .count()
                                    .then(count =>
                                    {
                                        runInAction(
                                            () =>
                                                this.pathCountMap.set(path.id, count.value));

                                        return Promise.resolve();
                                    }))));
    }

    private getSelectionBuilderByPath(path: RelatedEntityPath): EntitySelectionBuilder
    {
        return new EntitySelectionBuilder(path.path.entityType)
            .where(
                cb =>
                    cb.relatedToEntity(
                        path.path.reverse(),
                        path.entity));
    }

    private getViewGroupItem(group: RelatedEntityGroup,
                             viewerStore: EntityViewerStore,
                             doReloadEvents: () => Promise<any>): ViewGroupItem
    {
        const data =
            this.getViewGroupItemData(
                group,
                viewerStore,
                doReloadEvents);

        return new ViewGroupItem({
            spacing:
                () =>
                    group.showHeader
                        ?
                            12
                        :
                            0,
            style:
            {
                marginTop: '-1px'
            },
            id: 'view_group',
            view:
                new ViewComponent(
                    List as any,
                    new ViewGroupStore({
                        items:
                            [ data ]
                                .filter(
                                    item =>
                                        item !== undefined)
                    })),
            isHidden:
                () =>
                    this.hideGroupsWhenEmpty && this.getGroupCount(group) === 0
        });
    }

    private getGroupCount(group: RelatedEntityGroup): number
    {
        let count = group.entities.length;

        group.relativePaths.forEach(
            path =>
                count += (this.pathCountMap.get(path.id) || 0));

        group.childGroups.forEach(
            childGroup =>
                count += this.getGroupCount(childGroup));

        return count;
    }

    private getViewGroupItemData(group: RelatedEntityGroup,
                                 viewerStore: EntityViewerStore,
                                 doReloadEvents: () => Promise<any>): ViewGroupItem
    {
        if (group.childGroups.length > 0)
        {
            return new ViewGroupItem({
                id: 'related_entity',
                view:
                    new ViewComponent(
                        List as any,
                        new RelatedEntityGroupListStore(
                            group.childGroups,
                            viewerStore,
                            doReloadEvents,
                            this.hideGroupsWhenEmpty))
            });
        }
        else
        {
            if (group.paths.length > 0)
            {
                if (group.splitGroupOnPaths)
                {
                    return new ViewGroupItem({
                        id: 'entity_path',
                        view:
                            new ViewComponent(
                                List as any,
                                new ListStore<EntityPath, {}, ExpansionPanelListItemStore<EntityPath>>(
                                    {
                                        id: path => path.id,
                                        load: () => Promise.resolve(group.paths),
                                        comparator: createStringComparator(item => getNameOfPath(item.data, this.entityTypeStore)),
                                        construct:
                                            path =>
                                            {
                                                const expansionPanelListItemStore: ExpansionPanelListItemStore<EntityPath> =
                                                    new ExpansionPanelListItemStore<EntityPath>(
                                                    {
                                                        isHidden:
                                                            store =>
                                                                this.hideGroupsWhenEmpty &&
                                                                    getCountOfPath(
                                                                        new RelatedEntityPath(
                                                                            group.rootEntity,
                                                                            path),
                                                                        expansionPanelListItemStore.expansionPanelStore,
                                                                        this) === 0,
                                                        expansionPanel:
                                                            {
                                                                hasPopOut: true,
                                                                isSelectable: false,
                                                                summaryPrimaryText:
                                                                    new TextStore(
                                                                        {
                                                                            label:
                                                                                getNameOfPath(path, this.entityTypeStore),
                                                                            variant: 'expansionPanelHeader',
                                                                            color:
                                                                                () =>
                                                                                    expansionPanelListItemStore.expansionPanelStore.isExpanded
                                                                                        ?
                                                                                            'primary'
                                                                                        :
                                                                                            undefined
                                                                        }),
                                                                summarySecondaryText:
                                                                    store =>
                                                                        new TextStore(
                                                                            {
                                                                                label:
                                                                                    () =>
                                                                                        getCountOfPath(
                                                                                            new RelatedEntityPath(
                                                                                                group.rootEntity,
                                                                                                path),
                                                                                            store,
                                                                                            this).toString(),
                                                                                variant: 'body2'
                                                                            }),
                                                                summaryButtons:
                                                                    store =>
                                                                        this.getButtonStores(
                                                                            store,
                                                                            group.relativeAddPath
                                                                                ?
                                                                                    new RelatedEntityPath(
                                                                                        group.relativeAddPath.entity,
                                                                                        group.relativeAddPath.path.castTo(
                                                                                            path.entityType))
                                                                                :
                                                                                    new RelatedEntityPath(
                                                                                        group.rootEntity,
                                                                                        path),
                                                                            group.ignoreSourceRelationshipDefinitionDuringAdding,
                                                                            group),
                                                                detailView:
                                                                    new ViewComponent(
                                                                        List as any,
                                                                        new EntityExpansionListStore({
                                                                            viewerStore: viewerStore,
                                                                            paths: [ path ]
                                                                        })),
                                                                onClick:
                                                                    () =>
                                                                    {
                                                                        expansionPanelListItemStore.expansionPanelStore.setSelected(true);
                                                                        expansionPanelListItemStore.expansionPanelStore.toggleExpansion();

                                                                        return Promise.resolve();
                                                                    }
                                                            }
                                                    });

                                                return expansionPanelListItemStore;
                                            },
                                    }
                                ))
                    });
                }
                else
                {
                    const expansionPanelStore =
                        new ExpansionPanelStore(
                            {
                                isHidden:
                                    store =>
                                        this.hideGroupsWhenEmpty &&
                                            group.paths.reduce(
                                                (count, path) =>
                                                    count
                                                        +
                                                    path.traverseEntity(viewerStore.entity).length,
                                                0) === 0,
                                hasPopOut: true,
                                isSelectable: false,
                                summaryPrimaryText:
                                    new TextStore(
                                        {
                                            label: () => group.name,
                                            variant: 'expansionPanelHeader',
                                        }),
                                summarySecondaryText:
                                    store =>
                                        new TextStore(
                                            {
                                                label:
                                                    () =>
                                                        group.paths.reduce(
                                                            (count, path) =>
                                                                count
                                                                +
                                                                path.traverseEntity(viewerStore.entity).length,
                                                            0).toString(),
                                                variant: 'body2'
                                            }),
                                summaryButtons:
                                    store =>
                                        this.getButtonStores(
                                            store,
                                            group.relativeAddPath,
                                            group.ignoreSourceRelationshipDefinitionDuringAdding,
                                            group),
                                detailView:
                                    new ViewComponent(
                                        List as any,
                                        new EntityExpansionListStore({
                                            viewerStore: viewerStore,
                                            paths: group.paths
                                        })),
                                onClick:
                                    () =>
                                    {
                                        expansionPanelStore.setSelected(true);
                                        expansionPanelStore.toggleExpansion();

                                        return Promise.resolve();
                                    }
                            });
                }
            }
            else
            {
                return new ViewGroupItem({
                    id: 'expansion_panel',
                    view:
                        new ViewComponent(
                            ExpansionPanel as any,
                            new ExpansionPanelStore(
                                {
                                    hasPopOut: true,
                                    summaryPrimaryText:
                                        new TextStore(
                                            {
                                                label: group.name,
                                                variant: 'expansionPanelHeader',
                                            }),
                                    summarySecondaryText:
                                        store =>
                                            new TextStore(
                                                {
                                                    label:
                                                        () =>
                                                            store.detailView.store.isInitialized
                                                                ?
                                                                    (store.detailView.store as EntityListStore)
                                                                        .itemStores
                                                                        .map(itemStore => !itemStore.data.isDeleted)
                                                                        .length
                                                                        .toString()
                                                                :
                                                                    `${group.entities.map(entity => !entity.isDeleted).length}`,
                                                    variant: 'body2'
                                                }),
                                    summaryButtons:
                                        store =>
                                            this.getButtonStores(
                                                store,
                                                group.relativeAddPath,
                                                group.ignoreSourceRelationshipDefinitionDuringAdding,
                                                group),
                                    detailView:
                                        new ViewComponent(
                                            List as any,
                                            new EntityListStore({
                                                    selection:
                                                        () =>
                                                            group.entities,
                                                    isReactive: true,
                                                    summaryPrimaryTextCaption:
                                                        group.caption
                                                            ?
                                                                entity =>
                                                                    group.caption(entity)
                                                            :
                                                                undefined,
                                                    summaryButtons:
                                                        group.buttons
                                                            ?
                                                                entity =>
                                                                    group.buttons(entity)
                                                            :
                                                                () => [],
                                                    onVisit: viewerStore.openEntityViewerRight,
                                                    comparator: createEntityListNameComparator()
                                                }))
                                }))
                });
            }
        }
    }
}
