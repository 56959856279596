import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import FunctionContext from '../FunctionContext';
import Value from '../../Value/Value';
import EmptyValue from '../../Value/EmptyValue';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import ListQuery from '../../Query/ListQuery';
import QueryFileExportField from './QueryFileExportField';
import getQueryFromDescriptor from '../../Api/getQueryFromDescriptor';
import localizeText from '../../../Localization/localizeText';

export default class QueryFileExportComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref query: ListQuery;
    @observable.ref fileName: Computation<any, any>;
    @observable.shallow fields: QueryFileExportField[];

    // ------------------------ Constructor -------------------------

    constructor(
        query: ListQuery,
        fileName: Computation<any, any>,
        fields: QueryFileExportField[]
    )
    {
        super();

        this.query = query;
        this.fileName = fileName;
        this.fields = fields;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('File')
        );
    }

    getName(): string
    {
        return localizeText(
            'Computation.QueryFileExport',
            'Export query to file...'
        )
    }

    isAsync(): boolean
    {
        return false;
    }

    async apply(context: FunctionContext)
    {
        return this.synchronousApply(context);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        return EmptyValue.instance;
    }

    validate(): Validation[]
    {
        if (this.query)
        {
            return [
                ...this.query.validate(),
                ...this.fileName.validate(),
                ...this.fields
                    .map(
                        field =>
                            field.validate()
                    )
                    .reduce(
                        (a, b) => a.concat(b),
                        []
                    ),
            ];
        }
        else
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        'NoQueryFound',
                        'Geen query gevonden'
                    )
                )
            ];
        }
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'QueryFileExport';
        descriptor.query = this.query.toDescriptor();
        descriptor.fileName = this.fileName.toDescriptor();
        descriptor.fields = this.fields.map(field => field.toDescriptor());
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.query.getDependencies(),
            ...this.fileName.getDependencies(),
            ...this.fields
                .map(
                    field =>
                        field.getDependencies(this.query)
                )
                .reduce(
                    (a, b) => a.concat(b),
                    []
                ),
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const query =
            await getQueryFromDescriptor(
                descriptor.query,
                dependencyContext
            ) as ListQuery;
        const fileName =
            await getComputationFromDescriptor(
                descriptor.fileName,
                dependencyContext
            );
        const fields =
            await Promise.all(
                (descriptor.fields as any[])
                    .map(
                        field =>
                            QueryFileExportField.fromDescriptor(
                                field,
                                query,
                                dependencyContext
                            )
                    )
            );

        return new QueryFileExportComputation(
            query,
            fileName,
            fields
        );
    }

    // ----------------------- Private logic ------------------------
}
