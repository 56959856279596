import { Entity } from '../../../Model/Implementation/Entity';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import { EntityRelationshipDefinition } from '../../../Model/Implementation/EntityRelationshipDefinition';
import { EntityRelationship } from '../../../Model/Implementation/EntityRelationship';
import { EntityType } from '../../../Model/Implementation/EntityType';
import { EntityField } from '../../../Model/Implementation/EntityField';
import { action, computed, observable, runInAction } from 'mobx';
import { CommitOperation } from './Model/Operation/CommitOperation';
import { CreateEntityOperation } from './Model/Operation/CreateEntityOperation';
import { UpdateEntityValueOperation } from './Model/Operation/UpdateEntityValueOperation';
import { FileReporter } from '../../../../@Component/Domain/DataObject/Model/DataDescriptor';
import { CreateRelationshipOperation } from './Model/Operation/CreateRelationshipOperation';
import { UpdateRelationshipOperation } from './Model/Operation/UpdateRelationshipOperation';
import { DeleteRelationshipOperation } from './Model/Operation/DeleteRelationshipOperation';
import { CommitRequest } from './Model/CommitRequest';
import uuid from '../../../../@Util/Id/uuid';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { ApiClient } from '../../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../../@Service/ApiClient/Model/ApiRequest';
import { fromJsonAsArray } from '../../../../@Util/Serialization/Serialization';
import { EntityEvent } from '../../../Model/Implementation/EntityEvent';
import { CommitResult } from './Model/CommitResult';
import { CommitOptions, maybeShowSaveNotification } from '../commitEntity';
import { Mutex } from '../../../../@Util/Mutex/Mutex';
import { DeleteEntityOperation } from './Model/Operation/DeleteEntityOperation';
import equalsEntity from '../../Bespoke/equalsEntity';
import { EntityCreationMutation } from '../../../Model/Implementation/EntityCreationMutation';
import { EntityRelationshipCreationMutation } from '../../../Model/Implementation/EntityRelationshipCreationMutation';
import { CommitContextOptions } from './Model/CommitContextOptions';
import { Comparator } from '../../../../@Component/Domain/DataObject/Model/Comparator';
import { EntityCacheService } from '../../../../@Component/Service/Entity/EntityCacheService';
import { CommitMutationOptions } from './Model/CommitMutationOptions';
import { CommitLock } from './Model/CommitLock';
import { CommitContextWithMutationOptions } from './CommitContextWithMutationOptions';
import { CommitContext } from './CommitContext';

export class CommitContextImpl implements CommitContext
{
    // ------------------------- Properties -------------------------

    options: CommitContextOptions;
    commitMutex: Mutex;
    firstUpdatedEntity: Entity;
    writeAheadContext?: CommitContext;
    promisesBeforeCommit: Promise<any>[];
    @observable.shallow createdEntityByUuid: Map<string, Entity>;
    @observable.shallow deletedEntityByUuid: Map<string, Entity>;
    @observable.shallow updatedValueByKey: Map<string, DataObject>;
    @observable.shallow createdRelationshipByUuid: Map<string, EntityRelationship>;
    @observable.shallow updatedRelationshipByUuid: Map<string, EntityRelationship>;
    @observable.shallow deletedRelationshipByUuid: Map<string, EntityRelationship>;

    // ------------------------ Constructor -------------------------

    constructor(
        options: Partial<CommitContextOptions> = {}
    )
    {
        this.options = {
            allowAutoCommit: true,
            ...options,
        };
        this.commitMutex = new Mutex();
        this.createdEntityByUuid = observable.map(undefined, { deep: false });
        this.deletedEntityByUuid = observable.map(undefined, { deep: false });
        this.updatedValueByKey = observable.map(undefined, { deep: false });
        this.promisesBeforeCommit = [];
        this.createdRelationshipByUuid = observable.map(undefined, { deep: false });
        this.updatedRelationshipByUuid = observable.map(undefined, { deep: false });
        this.deletedRelationshipByUuid = observable.map(undefined, { deep: false });
    }

    // ----------------------- Initialization -----------------------

    // ------------------------ Public logic ------------------------

    public getOptions(): CommitContextOptions
    {
        return this.options;
    }

    public getFirstUpdatedEntity(): Entity | undefined
    {
        return this.firstUpdatedEntity;
    }

    public getEntity(
        uuid: string
    ): Entity | undefined
    {
        return this.writeAheadContext?.getEntity(uuid)
            ?? this.createdEntityByUuid.get(uuid)
            ?? this.parentContext?.getEntity(uuid);
    }

    @action
    public createEntity(
        entityType: EntityType,
        options?: CommitMutationOptions
    ): Entity
    {
        this.assertNotLocked(options);

        const entity =
            new Entity(entityType)
                .initialize(
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    false,
                    false,
                    false,
                    false
                );
        this.createdEntityByUuid.set(entity.uuid, entity);
        this.updateFirstUpdatedEntityIfNecessary(entity);
        entity.commitContext = this;

        if (this.shouldTriggerLocalAutomations(options))
        {
            this.triggerLocalAutomationsForEntityCreation(
                entity,
                options
            );
        }

        return entity;
    }

    private triggerLocalAutomationsForEntityCreation(
        entity: Entity,
        options?: CommitMutationOptions
    )
    {
        entity.entityType.bespoke.onConstruct(
            entity,
            this.withMutationOptions(options)
        );
    }

    @action
    public deleteEntity(
        entity: Entity,
        options?: CommitMutationOptions
    ): Entity
    {
        this.assertNotLocked(options);
        this.deleteRelationshipsForEntity(entity);
        this.deleteValuesForEntity(entity);

        if (this.createdEntityByUuid.has(entity.uuid))
        {
            this.createdEntityByUuid.delete(entity.uuid);
        }
        else
        {
            this.deletedEntityByUuid.set(entity.uuid, entity);
        }

        return entity;
    }

    private deleteRelationshipsForEntity(
        entity: Entity
    )
    {
        [ true, false ]
            .forEach(
                isParent =>
                    this.getCreatedOrUpdatedRelationshipsByEntity(
                        entity,
                        isParent
                    ).forEach(
                        relationship =>
                            this.deleteRelationship(relationship)
                    )
            );
    }

    private deleteValuesForEntity(
        entity: Entity
    )
    {
        Array.from(this.updatedValueByKey.keys())
            .filter(
                key =>
                    key.startsWith(`${entity.uuid}:`)
            )
            .forEach(
                key =>
                    this.updatedValueByKey.delete(key)
            );
    }

    public isEntityDirty(
        entity: Entity
    ): boolean
    {
        return this.writeAheadContext?.isEntityDirty(entity) === true
            || entity.isNew()
            || [ true, false ].some(
                isParent =>
                    this.getCreatedOrUpdatedRelationshipsByEntity(entity, isParent).length > 0
                    || this.getDeletedRelationshipsByEntity(entity, isParent).length > 0
            )
            || this.updatedFieldIdsByEntityId.has(entity.uuid);
    }

    public isEntityGraphDirty(
        entity: Entity,
        seenEntityIds: Set<string> = new Set()
    )
    {
        if (seenEntityIds.has(entity.uuid))
        {
            return false;
        }
        else
        {
            seenEntityIds.add(entity.uuid);

            return this.isEntityDirty(entity)
                || [ true, false].some(
                    isParent =>
                        this.getCreatedOrUpdatedRelationshipsByEntity(
                            entity,
                            isParent
                        ).some(
                            relationship =>
                                this.isEntityGraphDirty(
                                    relationship.getEntity(isParent),
                                    seenEntityIds
                                )
                        )
                );
        }
    }

    public hasValue(
        entity: Entity,
        field: EntityField
    ): boolean
    {
        return this.writeAheadContext?.hasValue(entity, field) === true
            ||
        this.updatedValueByKey.has(
            this.getValueKey(
                entity,
                field
            )
        )
            || this.parentContext?.hasValue(entity, field) === true;
    }

    public getValue(
        entity: Entity,
        field: EntityField
    ): DataObject | undefined
    {
        return this.writeAheadContext?.getValue(
            entity,
            field
        )
            ??
            this.updatedValueByKey.get(
                this.getValueKey(
                    entity,
                    field
                )
            )
            ??
            this.parentContext?.getValue(
                entity,
                field
            );
    }

    @action
    public setValue(
        entity: Entity,
        field: EntityField,
        value: DataObject,
        options?: CommitMutationOptions
    ): DataObject
    {
        if (entity.isDeleted)
        {
            return value;
        }

        const oldValue =
            entity.getDataObjectValueByField(
                field,
                undefined,
                undefined,
                true
            );
        const isValueEqualToOldValue =
            DataObject.compare(
                oldValue,
                value,
                Comparator.Equals
            );
        const valueKey = this.getValueKey(entity, field);

        if (isValueEqualToOldValue && !this.updatedValueByKey.has(valueKey))
        {
            return;
        }

        this.assertNotLocked(options);

        if (isValueEqualToOldValue)
        {
            this.updatedValueByKey.delete(valueKey);
        }
        else
        {
            this.updatedValueByKey.set(
                valueKey,
                value
            );

            this.updateFirstUpdatedEntityIfNecessary(entity);
        }

        if (this.shouldTriggerLocalAutomations(options))
        {
            this.triggerLocalAutomationsForValueUpdate(
                entity,
                field,
                value
            );
        }

        return value;
    }

    private triggerLocalAutomationsForValueUpdate(
        entity: Entity,
        field: EntityField,
        value: DataObject
    )
    {
        entity.entityType.bespoke.onValueSet(
            entity,
            field,
            value.value,
            this
        );
    }

    private getValueKey(
        entity: Entity,
        field: EntityField
    )
    {
        return `${entity.uuid}:${field.isStaticField() ? field.code : field.id}`;
    }

    public hasRelationship(
        relationship: EntityRelationship
    )
    {
        return this.writeAheadContext?.hasRelationship(relationship) === true
            || this.getRelationship(relationship.uuid) !== undefined
            || this.parentContext?.hasRelationship(relationship) === true;
    }

    public getRelationship(
        uuid: string
    ): EntityRelationship | undefined
    {
        return this.writeAheadContext?.getRelationship(uuid)
            ?? this.createdRelationshipByUuid.get(uuid)
            ?? this.updatedRelationshipByUuid.get(uuid)
            ?? this.parentContext?.getRelationship(uuid);
    }

    public getNewRelationships(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean
    )
    {
        const newRelationships =
            this.createdRelationshipsByRelatedDefinitionKey.get(
                this.getRelatedDefinitionKey(
                    entity,
                    isParent,
                    relationshipDefinition
                )
            ) ?? [];

        if (this.writeAheadContext || this.parentContext)
        {
            return [
                ...(this.parentContext?.getNewRelationships(
                    entity,
                    relationshipDefinition,
                    isParent
                ) ?? []),
                ...newRelationships,
                ...(this.writeAheadContext?.getNewRelationships(
                    entity,
                    relationshipDefinition,
                    isParent
                ) ?? []),
            ];
        }
        else
        {
            return newRelationships;
        }
    }

    public getCreatedOrUpdatedRelationshipsByEntity(
        entity: Entity,
        isParent: boolean
    ): EntityRelationship[]
    {
        return this.createdOrUpdatedRelationshipsByRelatedKey.get(
            this.getRelatedKey(
                entity,
                isParent
            )
        ) ?? [];
    }

    public getDeletedRelationshipsByEntity(
        entity: Entity,
        isParent: boolean
    ): EntityRelationship[]
    {
        return this.deletedRelationshipsByRelatedKey.get(
            this.getRelatedKey(
                entity,
                isParent
            )
        ) ?? [];
    }

    @action
    public createRelationship(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity: Entity,
        options?: CommitMutationOptions
    ): EntityRelationship
    {
        this.assertNotLocked(options);
        this.updateFirstUpdatedEntityIfNecessary(entity);

        const createdRelationship =
            this.createOrReinstateDeletedRelationship(
                entity,
                relationshipDefinition,
                isParent,
                relatedEntity
            );

        if (this.shouldTriggerLocalAutomations(options))
        {
            this.triggerLocalAutomationsForRelationship(
                createdRelationship,
                false,
                options
            );
        }

        return createdRelationship;
    }

    private createOrReinstateDeletedRelationship(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity: Entity
    ): EntityRelationship
    {
        const relationshipToReinstate =
            this.getDeletedSingularRelationshipIfPossible(
                entity,
                relationshipDefinition,
                isParent
            );

        if (relationshipToReinstate)
        {
            relationshipToReinstate.setEntity(
                isParent,
                relatedEntity
            );
            this.updatedRelationshipByUuid.set(
                relationshipToReinstate.uuid,
                relationshipToReinstate
            );
            this.deletedRelationshipByUuid.delete(relationshipToReinstate.uuid);

            return relationshipToReinstate;
        }
        else
        {
            const relationship =
                new EntityRelationship(
                    undefined,
                    isParent ? relatedEntity : entity,
                    isParent ? entity : relatedEntity,
                    relationshipDefinition
                ).initialize(
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    false
                );
            this.createdRelationshipByUuid.set(relationship.uuid, relationship);

            return relationship;
        }
    }

    private getDeletedSingularRelationshipIfPossible(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean
    ): EntityRelationship | undefined
    {
        if (relationshipDefinition.isSingular(isParent))
        {
            return Array.from(this.deletedRelationshipByUuid.values())
                .find(
                    relationship =>
                        equalsEntity(entity, relationship.getEntity(!isParent))
                        && relationship.definition === relationshipDefinition
                );
        }
        else
        {
            return undefined;
        }
    }

    @action
    public updateRelationship(
        relationship: EntityRelationship,
        isParent: boolean,
        relatedEntity: Entity,
        options?: CommitMutationOptions
    ): EntityRelationship
    {
        this.assertNotLocked(options);
        this.updateFirstUpdatedEntityIfNecessary(
            relationship.getEntity(!isParent)
        );

        const updatedRelationship =
            this.updateExistingOrCreateNewUpdatedRelationship(
                relationship,
                isParent,
                relatedEntity
            );

        if (this.shouldTriggerLocalAutomations(options))
        {
            this.triggerLocalAutomationsForRelationship(
                updatedRelationship,
                false,
                options
            );
        }

        return updatedRelationship;
    }

    private updateExistingOrCreateNewUpdatedRelationship(
        relationship: EntityRelationship,
        isParent: boolean,
        relatedEntity: Entity
    ): EntityRelationship
    {
        if (this.createdRelationshipByUuid.has(relationship.uuid))
        {
            const createdRelationship = this.createdRelationshipByUuid.get(relationship.uuid)!;

            createdRelationship.setEntity(
                isParent,
                relatedEntity
            );

            return createdRelationship;
        }
        else if (this.updatedRelationshipByUuid.has(relationship.uuid))
        {
            const updatedRelationship = this.updatedRelationshipByUuid.get(relationship.uuid)!;

            updatedRelationship.setEntity(
                isParent,
                relatedEntity
            );

            return updatedRelationship;
        }
        else
        {
            const updatedRelationship =
                new EntityRelationship(
                    relationship.id,
                    isParent ? relatedEntity : relationship.parentEntity,
                    isParent ? relationship.childEntity : relatedEntity,
                    relationship.definition
                );
            updatedRelationship.uuid = relationship.uuid;
            updatedRelationship.initialize(
                undefined,
                undefined,
                undefined,
                undefined,
                false
            );

            this.updatedRelationshipByUuid.set(relationship.uuid, updatedRelationship);

            return updatedRelationship;
        }
    }

    private triggerLocalAutomationsForRelationship(
        relationship: EntityRelationship,
        isDeletion: boolean,
        options?: CommitMutationOptions
    )
    {
        const promise = this.doTriggerLocalAutomationsForRelationship(relationship, isDeletion);

        this.promisesBeforeCommit.push(promise);

        promise.then(
            () =>
                this.promisesBeforeCommit.slice(
                    this.promisesBeforeCommit.indexOf(promise),
                    1
                )
        );
    }

    private async doTriggerLocalAutomationsForRelationship(
        relationship: EntityRelationship,
        isDeletion: boolean
    )
    {
        await Promise.all(
            [true, false].map(
                isParent =>
                    relationship.getEntity(!isParent)
                        .entityType
                        .bespoke
                        .onRelate(
                            relationship.getEntity(!isParent),
                            relationship.definition,
                            isParent,
                            isDeletion ? undefined : relationship.getEntity(isParent),
                            this
                        )
            )
        );
    }

    @action
    public deleteRelationship(
        relationship: EntityRelationship,
        options?: CommitMutationOptions
    ): EntityRelationship
    {
        this.assertNotLocked(options);
        this.updateFirstUpdatedEntityIfNecessary(
            relationship.getEntity(true)
        );

        if (this.createdRelationshipByUuid.has(relationship.uuid))
        {
            this.createdRelationshipByUuid.delete(relationship.uuid);
        }
        else
        {
            this.updatedRelationshipByUuid.delete(relationship.uuid);
            this.deletedRelationshipByUuid.set(relationship.uuid, relationship);
        }

        if (this.shouldTriggerLocalAutomations(options))
        {
            this.triggerLocalAutomationsForRelationship(
                relationship,
                true,
                options
            );
        }

        return relationship;
    }

    public isRelationshipDeleted(
        relationship: EntityRelationship
    ): boolean
    {
        return this.deletedRelationshipByUuid.has(relationship.uuid)
            || this.parentContext?.isRelationshipDeleted(relationship);
    }

    public isRelationshipDirty(
        relationship: EntityRelationship
    )
    {
        return this.createdRelationshipByUuid.has(relationship.uuid)
            || this.updatedRelationshipByUuid.has(relationship.uuid)
            || this.deletedRelationshipByUuid.has(relationship.uuid);
    }

    public isDirty(): boolean
    {
        return this.getOperations(() => {}).length > 0;
    }

    @action
    public clear()
    {
        [
            ...Array.from(this.createdEntityByUuid.values()),
            ...Array.from(this.deletedEntityByUuid.values()),
        ]
            .filter(
                entity =>
                    entity.commitContext === this
            )
            .forEach(
                entity =>
                    entity.commitContext = undefined
            );

        this.firstUpdatedEntity = undefined;
        this.createdEntityByUuid.clear();
        this.deletedEntityByUuid.clear();
        this.updatedValueByKey.clear();
        this.createdRelationshipByUuid.clear();
        this.updatedRelationshipByUuid.clear();
        this.deletedRelationshipByUuid.clear();
    }

    public dispose()
    {
        this.clear();
    }

    public commit(options: Partial<CommitOptions> = {}): Promise<CommitResult>
    {
        if (this.commitMutex.isInLock(options.lock?.id))
        {
            return this.internallyCommit(options);
        }
        else
        {
            return this.lock(
                () =>
                    this.internallyCommit(options) as any
            ) as any;
        }
    }

    private async internallyCommit(options: Partial<CommitOptions>): Promise<CommitResult>
    {
        await this.awaitPromisesBeforeCommit();

        const files = new Map<string, File>();
        const firstUpdatedEntity = this.firstUpdatedEntity;
        const request =
            this.getCommitRequest(
                options,
                (fileId, file) =>
                    files.set(
                        fileId,
                        file
                    )
            );

        if (this.shouldCommit(request))
        {
            loadModuleDirectly(EntityCacheService).registerCommit(request.id);

            if (request.operations.length === 0)
            {
                return {
                    id: request.id,
                    events: []
                };
            }
            else
            {
                const data = new FormData();
                data.append('request', JSON.stringify(request));

                files.forEach(
                    (file, fileId) =>
                        data.append(fileId, file, 'file')
                );

                const response =
                    await loadModuleDirectly(ApiClient)
                        .request(
                            new ApiRequest<any>(
                                '/commit',
                                Method.Post,
                                data,
                                {
                                    enctype: 'multipart/form-data',
                                },
                                true,
                                undefined,
                                undefined,
                                undefined,
                                true));

                if (response.ok)
                {
                    const result = response.data;
                    const events = fromJsonAsArray(response.data.events, EntityEvent);
                    result.events = events;

                    await this.processEvents(events);

                    if (firstUpdatedEntity)
                    {
                        maybeShowSaveNotification(firstUpdatedEntity, events);
                    }

                    runInAction(
                        () =>
                        {
                            this.updateNewEntityAndRelationshipIds(events);
                            this.clear()
                        }
                    );

                    return result;
                }
                else
                {
                    throw response;
                }
            }
        }
        else
        {
            return {
                id: request.id,
                events: []
            };
        }
    }

    private shouldCommit(request: CommitRequest): boolean
    {
        if (this.options.guard === undefined)
        {
            return true;
        }
        else
        {
            return request.operations.every(
                operation =>
                    this.options.guard(
                        operation,
                        this
                    )
            );
        }
    }

    private async processEvents(events: EntityEvent[])
    {
        const entityCacheService = loadModuleDirectly(EntityCacheService);
        const prematchResult = await entityCacheService.prematchEvents(events);
        await entityCacheService
            .postMatchEvents(
                events,
                prematchResult,
                new Map(this.createdEntityByUuid),
                new Map(this.createdRelationshipByUuid)
            );
    }

    private updateNewEntityAndRelationshipIds(events: EntityEvent[])
    {
        for (const event of events)
        {
            if (event instanceof EntityCreationMutation)
            {
                const entity = this.getEntity(event.entityUuid);

                if (entity)
                {
                    entity.id = event.entity.id;
                }
            }
            else if (event instanceof EntityRelationshipCreationMutation)
            {
                const relationship = this.getRelationship(event.entityRelationshipUuid);

                if (relationship)
                {
                    relationship.id = event.entityRelationship.id;
                }
            }
        }
    }

    public getCommitRequest(
        options: Partial<CommitOptions>,
        fileReporter: FileReporter,
        id: string = uuid()
    ): CommitRequest
    {
        return {
            id,
            operations: this.getOperations(fileReporter),
            ...options,
            isDebugMode: this.isDebugMode(options),
        };
    }

    private isDebugMode(options: Partial<CommitOptions>): boolean
    {
        return options?.isDebugMode
            ?? (localStorage?.getItem('isDebugMode') === 'true')
            ?? false;
    }

    private getOperations(fileReporter: FileReporter): CommitOperation[]
    {
        return [
            ...Array.from(this.createdEntityByUuid.values())
                .map(
                    entity => ({
                        type: 'CreateEntity',
                        entityId: entity.uuid,
                        entityTypeId: entity.entityType.id,
                    } as CreateEntityOperation)
                ),
            ...Array.from(this.updatedValueByKey.entries())
                .map(
                    ([ key, value]) => ({
                        type: 'UpdateEntityValue',
                        entityId: key.split(':')[0],
                        fieldId: key.split(':')[1],
                        value: value.data.descriptor(fileReporter),
                    } as UpdateEntityValueOperation)
                ),
            ...Array.from(this.createdRelationshipByUuid.values())
                .map(
                    relationship => ({
                        type: 'CreateRelationship',
                        relationshipId: relationship.uuid,
                        relationshipDefinitionId: relationship.definition.id,
                        parentEntityId: relationship.parentEntity.uuid,
                        childEntityId: relationship.childEntity.uuid,
                    } as CreateRelationshipOperation)
                ),
            ...Array.from(this.updatedRelationshipByUuid.values())
                .map(
                    relationship => ({
                        type: 'UpdateRelationship',
                        relationshipId: relationship.uuid,
                        parentEntityId: relationship.parentEntity.uuid,
                        childEntityId: relationship.childEntity.uuid,
                    } as UpdateRelationshipOperation)
                ),
            ...Array.from(this.deletedRelationshipByUuid.values())
                .map(
                    relationship => ({
                        type: 'DeleteRelationship',
                        relationshipId: relationship.uuid,
                    } as DeleteRelationshipOperation)
                ),
            ...Array.from(this.deletedEntityByUuid.values())
                .map(
                    entity => ({
                        type: 'DeleteEntity',
                        entityId: entity.uuid,
                    } as DeleteEntityOperation)
                ),
        ];
    }

    private updateFirstUpdatedEntityIfNecessary(
        entity: Entity
    )
    {
        if (!this.firstUpdatedEntity)
        {
            this.firstUpdatedEntity = entity;
        }
    }

    public isLocked(): boolean
    {
        return this.commitMutex.isLocked();
    }

    public awaitLock(): Promise<void>
    {
        return this.commitMutex.await();
    }

    public lock<T>(runnable: (lock: CommitLock) => Promise<T>)
    {
        return this.commitMutex.dispatch(
            async (lockId) =>
            {
                return runnable({
                    id: lockId,
                });
            }
        );
    }

    // ----------------------- Private logic ------------------------

    @computed
    get createdRelationshipsByRelatedDefinitionKey(): Map<string, EntityRelationship[]>
    {
        return this.groupRelationshipsByKey(
            (relationship, isParent) =>
                this.getRelatedDefinitionKey(
                    relationship.getEntity(!isParent),
                    isParent,
                    relationship.definition
                ),
            Array.from(this.createdRelationshipByUuid.values())
        );
    }

    @computed
    get createdOrUpdatedRelationshipsByRelatedKey(): Map<string, EntityRelationship[]>
    {
        return this.groupRelationshipsByKey(
            (relationship, isParent) =>
                this.getRelatedKey(
                    relationship.getEntity(!isParent),
                    isParent
                ),
            [
                ...Array.from(this.createdRelationshipByUuid.values()),
                ...Array.from(this.updatedRelationshipByUuid.values()),
            ]
        );
    }

    @computed
    get deletedRelationshipsByRelatedKey(): Map<string, EntityRelationship[]>
    {
        return this.groupRelationshipsByKey(
            (relationship, isParent) =>
                this.getRelatedKey(
                    relationship.getEntity(!isParent),
                    isParent
                ),
            Array.from(this.deletedRelationshipByUuid.values())
        );
    }

    private groupRelationshipsByKey(
        keyMapper: (relationship: EntityRelationship, isParent: boolean) => string,
        relationships: EntityRelationship[]
    ): Map<string, EntityRelationship[]>
    {
        const relationshipsByKey = new Map<string, EntityRelationship[]>();

        relationships.forEach(
            relationship =>
                [ true, false ].forEach(
                    isParent =>
                    {
                        const key = keyMapper(relationship, isParent);

                        if (relationshipsByKey.has(key))
                        {
                            relationshipsByKey.get(key).push(relationship);
                        }
                        else
                        {
                            relationshipsByKey.set(
                                key,
                                [ relationship ]
                            );
                        }
                    }
                )
        );

        return relationshipsByKey;
    }

    @computed
    get updatedFieldIdsByEntityId(): Map<string, Set<string>>
    {
        const updatedFieldIdsByEntityId = new Map<string, Set<string>>();

        Array.from(this.updatedValueByKey.keys())
            .forEach(
                (key) =>
                {
                    const [ entityId, fieldId ] = key.split(':');

                    if (updatedFieldIdsByEntityId.has(entityId))
                    {
                        updatedFieldIdsByEntityId.get(entityId).add(fieldId);
                    }
                    else
                    {
                        updatedFieldIdsByEntityId.set(
                            entityId,
                            new Set([ fieldId ])
                        );
                    }
                }
            );

        return updatedFieldIdsByEntityId;
    }

    private getRelatedKey(
        entity: Entity,
        isParent: boolean
    )
    {
        return `${entity.uuid}.${isParent ? 'p' : 'c'}`;
    }

    private getRelatedDefinitionKey(
        entity: Entity,
        isParent: boolean,
        relationshipDefinition: EntityRelationshipDefinition
    )
    {
        return `${this.getRelatedKey(entity, isParent)}.${relationshipDefinition.id}`;
    }

    private get parentContext()
    {
        return this.options.parentContext;
    }

    private shouldTriggerLocalAutomations(options?: CommitMutationOptions)
    {
        return !options?.disableLocalAutomations;
    }

    private assertNotLocked(options?: CommitMutationOptions)
    {
        if (this.commitMutex.isLocked(options?.lock?.id))
        {
            console.log('Commit context is locked');
            // throw new Error('Commit context is locked');
        }
    }

    private withMutationOptions(mutationOptions?: CommitMutationOptions)
    {
        return new CommitContextWithMutationOptions(
            this,
            mutationOptions
        );
    }

    private async awaitPromisesBeforeCommit()
    {
        await Promise.all(this.promisesBeforeCommit);
    }
}


