import React from 'react';
import { ViewGroupStore } from '../../../../../../Generic/ViewGroup/ViewGroupStore';
import { ViewGroupItem } from '../../../../../../Generic/ViewGroup/ViewGroupItem';
import { Comparator } from '../../../../../DataObject/Model/Comparator';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { computed, observable } from 'mobx';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { injectWithQualifier } from '../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../Type/EntityTypeStore';
import { PredicateTypeStore } from '../../../../../Predicate/PredicateTypeStore';
import { ComputationTypeStore } from '../../../../../Computation/ComputationTypeStore';
import { DataObjectStore } from '../../../../../DataObject/DataObjectStore';
import { EntityViewerStore } from '../../EntityViewerStore';
import { CurrentUserStore } from '../../../../../User/CurrentUserStore';
import { RelatedEntityWidget } from '../Model/RelatedEntityWidget';
import { ReactViewComponent } from '../../../../../../Generic/ViewStack/Model/ReactViewComponent';
import { default as DatasetView } from '../../../../Dataset/Dataset';
import { LogicalOperator } from '../../../../../DataObject/Model/LogicalOperator';
import Divider from '../../../../../../../@Future/Component/Generic/Divider/Divider';
import EntityConstructorButton from '../../../../Button/EntityConstructorButton/EntityConstructorButton';
import Dataset from '../../../../Dataset/Model/Dataset';
import Segment from '../../../../Dataset/Model/Segment';
import getViewParameters from '../../../../View/Api/getViewParameters';
import CompositePredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import ComparisonPredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../../../View/Model/ViewParams';
import EntityValue from '../../../../../../../@Api/Automation/Value/EntityValue';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export class RelatedEntityWidgetListStore extends ViewGroupStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('PredicateTypeStore') predicateTypeStore: PredicateTypeStore;
    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;

    // ------------------------- Properties -------------------------

    @observable entity: Entity;

    // ------------------------ Constructor -------------------------

    constructor(viewerStore: EntityViewerStore)
    {
        super({
            items:
                () =>
                    this.widgets
                        .map(
                            (widget, idx) =>
                                [
                                    new ViewGroupItem({
                                        id: widget.path.id,
                                        spacing: 0,
                                        view:
                                            () =>
                                            {
                                                const entityType = widget.path.entityType;
                                                const pathUntilLastJoinNode = widget.path.getPathUntilNode(widget.path.lastJoinNode);
                                                const constructFromEntity = pathUntilLastJoinNode.traverseEntity(this.entity).find(() => true);

                                                // let constructorButtonStore: EntityConstructorButtonStore;
                                                //
                                                // if (constructFromEntity)
                                                // {
                                                //     constructorButtonStore =
                                                //         new EntityConstructorButtonStore({
                                                //             rootEntity: constructFromEntity,
                                                //             pathFromRootEntity:
                                                //                 EntityPath.fromEntity(constructFromEntity)
                                                //                     .joinTo(
                                                //                         widget.path.lastJoinNode.relationshipDefinition,
                                                //                         widget.path.lastJoinNode.isParent)
                                                //                     .castTo(entityType),
                                                //             tooltip:
                                                //                 () =>
                                                //                     `${entityType.getName()} toevoegen`
                                                //         });
                                                // }

                                                const parameters = getViewParameters(widget.path.entityType);

                                                return new ReactViewComponent(
                                                    DatasetView,
                                                    {
                                                        id: `Related:${widget.path.code}`,
                                                        name:
                                                            widget.title
                                                            ||
                                                            widget.path.getName(this.entityTypeStore, false, false),
                                                        dataset:
                                                            new Dataset(
                                                                entityType,
                                                                parameters.getParameterById(ViewParams.Entity),
                                                                parameters,
                                                                widget.path.entityType.getAllInstantiableTypes()
                                                                    .length > 1
                                                                    ?
                                                                        [
                                                                            new Segment(
                                                                                '1',
                                                                                'List',
                                                                                EntityPath.root(widget.path.entityType).field(this.entityTypeStore.typeField),
                                                                                [])
                                                                        ]
                                                                    :
                                                                        [],
                                                                new CompositePredicate(
                                                                    LogicalOperator.And,
                                                                    [
                                                                        new ComparisonPredicate(
                                                                            new ValueFromEntityComputation(
                                                                                parameters.getParameterById(ViewParams.Entity),
                                                                                widget.path
                                                                                    .reverse()
                                                                                    .field()),
                                                                            Comparator.Equals,
                                                                            new EntityValue(this.entity)),
                                                                        ...widget.predicate
                                                                            ?
                                                                                [ widget.predicate ]
                                                                            :
                                                                                []
                                                                    ])),
                                                        hideCard: true,
                                                        hideConstructorButton: true,
                                                        headerAppendix:
                                                            constructFromEntity
                                                                ?
                                                                    React.createElement(
                                                                        EntityConstructorButton,
                                                                        {
                                                                            rootEntity: constructFromEntity,
                                                                            pathFromRootEntity:
                                                                                EntityPath.fromEntity(constructFromEntity)
                                                                                    .joinTo(
                                                                                        widget.path.lastJoinNode.relationshipDefinition,
                                                                                        widget.path.lastJoinNode.isParent)
                                                                                    .castTo(entityType),
                                                                        })
                                                                :
                                                                    undefined
                                                    });
                                            }
                                    }),
                                    new ViewGroupItem({
                                        id: `${widget.path.id}.Divider`,
                                        spacing: 0,
                                        view: new ReactViewComponent(Divider)
                                    })
                                ]).reduce((a, b) => a.concat(b), [])
        });

        this.entity = viewerStore.entity;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get rootPath(): EntityPath
    {
        return EntityPath.fromEntity(this.entity);
    }

    @computed
    get widgets(): RelatedEntityWidget[]
    {
        const widgets: RelatedEntityWidget[] = [];

        if (this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Activity.Type))
        {
            const pathToActivities =
                this.rootPath
                    .joinTo(
                        this.entityTypeStore.bespoke.types.Activity.RelationshipDefinition.LinkedActivities,
                        false);

            widgets.push(
                new RelatedEntityWidget(
                    pathToActivities,
                    localizeText('Configuration.Activities', 'Activiteiten'),
                    undefined,
                    undefined,
                    true,
                    true));
        }
        else if (this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Type))
        {
            widgets.push(
                new RelatedEntityWidget(
                    this.rootPath
                        .joinTo(
                            this.entityTypeStore.bespoke.types.Relation.RelationshipDefinition.Relationships,
                            false),
                    localizeText('Configuration.Relations', 'Relaties'),
                    undefined,
                    undefined,
                    true,
                    true));

            let pathToActivities: EntityPath;

            if (this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Person.Type))
            {
                const pathToRelationship =
                    this.rootPath
                        .joinTo(
                            this.entityTypeStore.bespoke.types.Relationship.Person.RelationshipDefinition.Person,
                            true);

                const relationships = pathToRelationship.traverseEntity(this.entity);

                if (relationships.length > 0)
                {
                    const hasContact = relationships.some(rsp => rsp.entityType.isA(this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Type));

                    if (hasContact)
                    {
                        pathToActivities =
                            pathToRelationship
                                .castTo(this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Type)
                                .joinTo(
                                    this.entityTypeStore.bespoke.types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                    false);
                    }
                    else
                    {
                        pathToActivities =
                            pathToRelationship
                                .joinTo(
                                    this.entityTypeStore.bespoke.types.Relationship.RelationshipDefinition.Activities,
                                    false);
                    }
                }
            }
            else if (this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Organization.Type))
            {
                pathToActivities =
                    this.rootPath
                        .joinTo(
                            this.entityTypeStore.bespoke.types.Relationship.Organization.RelationshipDefinition.Organization,
                            true)
                        .joinTo(
                            this.entityTypeStore.bespoke.types.Relationship.RelationshipDefinition.Activities,
                            false);
            }

            if (pathToActivities)
            {
                widgets.push(
                    new RelatedEntityWidget(
                        pathToActivities,
                        localizeText('Configuration.Activities', 'Activiteiten'),
                        undefined,
                        undefined,
                        true,
                        true));
            }
        }
        else
        {
            widgets.push(
                ...this.rootPath.entityType.getInheritedRelationshipDefinitions(false)
                        .filter(
                            relationshipDefinition =>
                                relationshipDefinition.isVisibleInDetails(true)
                                && relationshipDefinition.isPlural(false)
                                && this.currentUserStore.rightProfile.canReadSubtype(relationshipDefinition.getEntityType(false))
                                && relationshipDefinition !== this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Notes
                                && relationshipDefinition !== this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Attachments)
                        .map(
                            relationshipDefinition =>
                                new RelatedEntityWidget(
                                    this.rootPath
                                        .joinTo(
                                            relationshipDefinition,
                                            false))));
        }

        if (!this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relationship.Type))
        {
            widgets.push(
                new RelatedEntityWidget(
                    this.rootPath
                        .joinTo(
                            this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Attachments,
                            false),
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    false));
        }

        return widgets;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
