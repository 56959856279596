import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../../../@Framework/Component/BaseComponent';

import * as React from 'react';
import { ToolbarFontSizeStore } from './ToolbarFontSizeStore';
import Button from '../../../../Button/Button';

@observer
export class ToolbarFontSize extends BaseComponent<ToolbarFontSizeStore>
{

    renderComponent(store: ToolbarFontSizeStore)
    {
        return <div
            style={{
                marginLeft: 5,
                marginRight: 5,
                display: 'inline-block',
                borderRadius: 4,
                border: '1px solid',
                borderColor: '#3496db',
                // height: 27
            }}
        >
            <Button key={store.id} store={store.buttonStore} />
        </div>;
    }
}
