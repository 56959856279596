import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import Dependency from '../../Parameter/Dependency';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import localizeText from '../../../Localization/localizeText';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';

export default class AiAudioTranscriptionComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref audio: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(audio: Computation<any, any>)
    {
        super();

        this.audio = audio;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            DataObject.getTypeById('Text')
        );
    }

    isAsync(): boolean
    {
        return false;
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        throw new Error(`Unimplemented`);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        throw new Error(`Unimplemented`);
    }

    getName(): string
    {
        return `${localizeText('Computation.AiAudioTranscription', 'AI audio transcriptie')}: ${this.audio.getName()}`;
    }

    validate(): Validation[]
    {
        return [];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'AiAudioTranscription';
        descriptor.audio = this.audio.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.audio.getDependencies();
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext
    )
    {
        return new AiAudioTranscriptionComputation(
            await getComputationFromDescriptor(
                descriptor.audio,
                dependencyContext
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
