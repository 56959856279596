import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityFieldGroup } from '../../../Management/Application/EntityTypesEditor/EntityFieldGroup';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import getTranslation from '../../../../../@Service/Localization/Api/getTranslation';

export default function getAllFieldGroups(entityType: EntityType)
{
    const entityTypeStore = loadModuleDirectly(EntityTypeStore);
    const uncategorized = getTranslation('Generic.Uncategorized');

    return entityType
        .getInheritedFieldGroups(entityTypeStore)
        .concat([
            new EntityFieldGroup(
                entityType,
                undefined,
                () => uncategorized,
                'uncategorized'), // Uncategorized
        ]);
}
