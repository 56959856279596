import { observable } from 'mobx';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';

export default class ColumnOrdering
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref fieldPath: EntityFieldPath;
    @observable.ref isAscending: boolean;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                fieldPath: EntityFieldPath,
                isAscending: boolean)
    {
        this.id = id;
        this.fieldPath = fieldPath;
        this.isAscending = isAscending;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new ColumnOrdering(
            descriptor.id,
            EntityFieldPath.construct(descriptor.fieldPath),
            descriptor.isAscending);
    }

    toDescriptor()
    {
        return {
            id: this.id,
            fieldPath: this.fieldPath.descriptor,
            isAscending: this.isAscending
        };
    }

    // ----------------------- Private logic ------------------------
}
