import React from 'react';
import { observer } from 'mobx-react-lite';
import Predicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import CompositePredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import CompositePredicateEditor, { CompositePredicateEditorProps } from './Type/Composite/CompositePredicateEditor';
import ComparisonPredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ComparisonPredicateEditor, { ComparisonPredicateEditorProps } from './Type/Comparison/ComparisonPredicateEditor';
import DynamicPredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/DynamicPredicate';
import DynamicPredicateEditor, { DynamicPredicateEditorProps } from './Type/Dynamic/DynamicPredicateEditor';
import NotPredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/NotPredicate';
import NotPredicateEditor, { NotPredicateEditorProps } from './Type/Not/NotPredicateEditor';
import { PredicateEditorProps } from './PredicateEditor';
import InstanceOfPredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/InstanceOfPredicate';
import InstanceOfPredicateEditor, { InstanceOfPredicateEditorProps } from './Type/InstanceOf/InstanceOfPredicateEditor';

export interface PredicateEditorContentProps<T extends Predicate = Predicate> extends PredicateEditorProps<T>
{

}

const PredicateEditorContent: React.FC<PredicateEditorContentProps> =
    props =>
    {
        if (props.value instanceof CompositePredicate)
        {
            return <CompositePredicateEditor
                {...props as CompositePredicateEditorProps}
            />;
        }
        else if (props.value instanceof ComparisonPredicate)
        {
            return <ComparisonPredicateEditor
                {...props as ComparisonPredicateEditorProps}
            />;
        }
        else if (props.value instanceof NotPredicate)
        {
            return <NotPredicateEditor
                {...props as NotPredicateEditorProps}
            />;
        }
        else if (props.value instanceof InstanceOfPredicate)
        {
            return <InstanceOfPredicateEditor
                {...props as InstanceOfPredicateEditorProps}
            />;
        }
        else if (props.value instanceof DynamicPredicate)
        {
            return <DynamicPredicateEditor
                {...props as DynamicPredicateEditorProps}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(PredicateEditorContent);
