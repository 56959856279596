import * as React from 'react';
import { observer } from 'mobx-react';
import { ViewStack } from '../ViewStack/ViewStack';
import { DrawerStore } from './DrawerStore';
import { createStyles, Drawer as MuiDrawer, Hidden, Theme as MuiTheme } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { backgroundColor as bgColor } from '../../../@Resource/Theme/Theme';
import { OverflowYProperty } from 'csstype';
import { View } from '../ViewStack/View';
import { grey } from '@material-ui/core/colors';

const styles =
    (theme: MuiTheme) =>
        createStyles({
            paper:
            {
                overflowY: 'auto !important' as OverflowYProperty,
                backgroundColor: bgColor,
                // top: 60,
                // add this because the top offset will hide the content at the bottom 40px of the screen:
                // paddingBottom: 43
            }
        });

@observer
class Drawer extends ViewStack<DrawerStore>
{
    renderComponent(store: DrawerStore): JSX.Element
    {
        return store.width
            ?
                this.renderDrawerWithWidth(store, store.width)
            :
            <>
                <Hidden
                    only={['lg', 'md', 'sm', 'xs']}
                >
                    {this.renderDrawerWithWidth(store, '50%')}
                </Hidden>
                <Hidden
                    only={['xl', 'md', 'sm', 'xs']}
                >
                    {this.renderDrawerWithWidth(store, '60%')}
                </Hidden>
                <Hidden
                    only={['xl', 'lg', 'sm', 'xs']}
                >
                    {this.renderDrawerWithWidth(store, '70%')}
                </Hidden>
                <Hidden
                    only={['xl', 'lg', 'md', 'xs']}
                >
                    {this.renderDrawerWithWidth(store, '80%')}
                </Hidden>
                <Hidden
                    only={['xl', 'lg', 'md', 'sm']}
                >
                    {this.renderDrawerWithWidth(store, '100%')}
                </Hidden>
            </>;
    }

    renderDrawerWithWidth(store: DrawerStore,
                          width: string | number)
    {
        return <>
            <MuiDrawer
                open={store.hasViews}
                anchor={store.isLeft ? 'left' : 'right'}
                classes={{
                    paper: this.props.classes.paper
                }}
                PaperProps={{
                    style: {
                        top: 0, // store.top
                        bottom: 0,
                        height: 'auto',
                        backgroundAttachment: 'fixed',
                        backgroundImage: store.background ? `url(${store.background})` : undefined,
                        backgroundSize: 'cover',
                        width: `min(${typeof width === 'number' ? `${width}px` : width}, 100%)`
                    }
                }}
                onClose={() => store.clear()}
                disableEscapeKeyDown
            >
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        right: store.rightSideViewIsVisible && store.rightSideViewComponent ? store.rightSideViewWidth : 0
                    }}
                >
                    {super.renderComponent(store)}
                </div>
                {
                    store.rightSideViewIsVisible && store.rightSideViewComponent &&
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                width: store.rightSideViewWidth,
                                borderLeft: `1px solid ${grey[200]}`
                            }}
                        >
                            <View viewComponent={store.rightSideViewComponent}/>
                        </div>
                }
            </MuiDrawer>
        </>;
    }
}

const StyledDrawer = withStyles(styles)(Drawer as any);

export default StyledDrawer;
