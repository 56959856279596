import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { EntityEvent } from '../../Model/Implementation/EntityEvent';

export class DocumentEntityController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    finalizeDocument(documentId: number): Promise<EntityEvent[]>
    {
            return new Promise<EntityEvent[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/entity/bespoke/document/finalize', Method.Post, { document_id: documentId }))
                    .then(result =>
                    {
                        resolve((fromJson(result, EntityEvent) as any) as EntityEvent[]);
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
}
