import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Easing } from '../../../../@Component/Generic/Easing/easing';
import useAnimation from '../../../Util/Hook/useAnimation';

export interface CircularPercentageChartProps
{
    className?: string;
    size?: number;
    value: number;
    fromValue?: number;
    label?: (value: number) => string;
    subLabel?: (value: number) => ReactNode;
    customLabel?: (value: number) => ReactNode;
    animated?: boolean;
    animationDuration?: number;
    animationDelay?: number;
    color?: string;
    trailColor?: string;
    textColor?: string;
    onAnimationComplete?: () => void;
}

const CircularPercentageChart: React.FC<CircularPercentageChartProps> =
    props =>
    {
        const { onAnimationComplete } = props;

        const [ value, setValue ] = useState(props.value);
        const [ fromValue, setFromValue ] = useState(props.fromValue === undefined ? 0 : props.fromValue);
        const [ lastAnimationValue, setLastAnimationValue ] = useState(0);

        const { label, subLabel, customLabel } = props;

        const animationComplete =
            useCallback(
                () =>
                {
                    setFromValue(value);
                    setLastAnimationValue(value);

                    if (onAnimationComplete)
                    {
                        onAnimationComplete();
                    }
                },
                [
                    value,
                    setFromValue,
                    setLastAnimationValue,
                    onAnimationComplete
                ]);

        const animation =
            useAnimation(
                Easing.easeInOutQuad,
                props.animated ? props.animationDuration : 0,
                props.animationDelay,
                animationComplete,
                [
                    value,
                    fromValue,
                    animationComplete
                ]);

        const animatedValue =
            useMemo(
                () =>
                {
                    const v =
                        isNaN(value)
                            ?
                                0
                            :
                                value < 0
                                ?
                                    0
                                :
                                    value;

                    const f =
                        isNaN(fromValue)
                            ?
                                0
                            :
                            fromValue < 0
                                    ?
                                        0
                                    :
                                fromValue;

                    return (animation * (v - f)) + f;
                },
                // eslint-disable-next-line react-hooks/exhaustive-deps
                [
                    // Do not include fromValue and value,
                    // because only the animation should trigger this value
                    animation
                ]);

        useEffect(
            () =>
            {
                setLastAnimationValue(animatedValue);
            },
            [
                animatedValue,
                setLastAnimationValue
            ]);

        useEffect(
            () =>
            {
                // If the provided value changes, set the from value to the last animated value.
                // This is neccessary to provide stuttering if the props value changes while an
                // animation is in progress.
                if (!props.fromValue && lastAnimationValue > 0)
                {
                    setFromValue(lastAnimationValue);
                }

                setValue(props.value);
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [
                // Do not include lastAnimationValue because this should only trigger
                // when the props.value or props.fromValue change
                props.value,
                props.fromValue
            ]);

        const customLabelCallback =
            useCallback(
                (n) =>
                    customLabel
                        ?
                            customLabel(n)
                        :
                            label && subLabel
                            ?
                                <div>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            width: `${props.size * 0.75}px`,
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            color: props.textColor,
                                            fontSize: `${props.size / 120 * 1}em`
                                        }}
                                    >
                                        {subLabel(n)}
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            lineHeight: '1.1em',
                                            color: props.textColor,
                                            fontSize: `${props.size / 120 * 2}em`
                                        }}
                                    >
                                        {label(n)}
                                    </div>
                                </div>
                            :
                                undefined,
                [
                    props.size,
                    props.textColor,
                    customLabel,
                    label,
                    subLabel
                ]);

        return <div
            className={props.className}
            style={{
                width: props.size,
                height: props.size
            }}
        >
            {
                customLabelCallback &&
                    <CircularProgressbarWithChildren
                        value={animatedValue}
                        styles={
                            buildStyles({
                                pathTransition: 'none',
                                pathColor: props.color,
                                trailColor: props.trailColor,
                                textColor: props.textColor
                            })
                        }
                    >
                        {
                            customLabelCallback(animatedValue)
                        }
                    </CircularProgressbarWithChildren>
            }
            {
                !customLabelCallback &&
                    <CircularProgressbar
                        value={animatedValue}
                        text={props.label ? props.label(animatedValue) : `${Math.round(animatedValue)}%`}
                        styles={
                            buildStyles({
                                pathTransition: 'none',
                                pathColor: props.color,
                                trailColor: props.trailColor,
                                textColor: props.textColor
                            })
                        }
                    />
            }
        </div>;
    };

CircularPercentageChart.defaultProps =
{
    animated: true,
    animationDuration: 1000,
    animationDelay: 0,
    fromValue: 0,
    color: '#3497db'
};

export default observer(CircularPercentageChart);
