import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import Fields from '../../../Fields/Fields';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import PortalUserSettingsEditor from './UserSettings/PortalUserSettingsEditor';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import useTypes from '../../../Type/Api/useTypes';
import PrimaryButton from '../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import { useTwoFactorAuthenticationConfiguration } from '../../../../Security/Api/useTwoFactorAuthenticationConfiguration';
import performAction from '../../../../../../@Api/Entity/performAction';
import uuid from '../../../../../../@Util/Id/uuid';

export interface PortalUserProps
{
    entity: Entity;
    commitContext: CommitContext;
}

const PortalUser: React.FC<PortalUserProps> =
    ({
        entity,
        commitContext,
     }) =>
    {
        const types = useTypes();
        const [ refreshKey, setRefreshKey ] = useState(() => uuid());
        const user =
            useComputed(
                () => entity.user,
                [
                    entity
                ]
            );

        const hasTwoFactorAuthentication =
            useEntityValue(
                entity,
                types.PortalUser.Field.HasTwoFactorAuthentication,
                commitContext
            );

        useEffect(
            () =>
            {
                setRefreshKey(uuid());
            },
            [
                entity,
                commitContext
            ]
        );

        const [ twoFactorAuthenticationUserConfiguration, isLoadingUserConfiguration] =
            useTwoFactorAuthenticationConfiguration(
                user,
                refreshKey
            );

        const isTwoFactorAuthenticationEnabled =
            useMemo<boolean>(
                () =>
                    !isLoadingUserConfiguration && twoFactorAuthenticationUserConfiguration?.isEnabled,
                [
                    isLoadingUserConfiguration,
                    twoFactorAuthenticationUserConfiguration
                ]
            );

        const resetTwoFactorAuthentication =
            useCallback(
                () =>
                    performAction(
                        undefined,
                        {
                            code: 'Authentication.TwoFactor.Reset',
                            parameters: {
                                userId: user.id,
                            }
                        }
                    )
                    .then(),
                [
                    user
                ]
            );

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset
                                    bottom={false}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <TypeAndName
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                                <Fields
                                    entity={entity}
                                />
                            </Card>
                        </Grid>
                        {
                            hasTwoFactorAuthentication &&
                            <Grid
                                item
                                xs={12}
                            >
                                <Card>
                                    <CardInset>
                                        <CardHeader>
                                            <LocalizedText
                                                code="PortalUser.Settings.TwoFactorAuthentication"
                                                value="Twee factor authenticatie"
                                            />
                                        </CardHeader>
                                        <PrimaryButton
                                            label={
                                                <LocalizedText
                                                    code='TwoFactorAuthenticationGenerateKey'
                                                    value='Nieuwe sleutel genereren'
                                                />
                                            }
                                            onClick={resetTwoFactorAuthentication}
                                            disabled={!isTwoFactorAuthenticationEnabled}
                                        />
                                    </CardInset>
                                </Card>
                            </Grid>
                        }
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset>
                                    <CardHeader>
                                        <LocalizedText
                                            code="Generic.Settings"
                                            value="Instellingen"
                                        />
                                    </CardHeader>
                                    <PortalUserSettingsEditor
                                        portalUser={entity}
                                        doAutoCommit
                                        commitContext={commitContext}
                                    />
                                </CardInset>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(PortalUser);
