import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import uuid from '../../../../../../../../@Util/Id/uuid';
import PortalTableLayout from '../../../../../../../../@Api/Layout/Type/PortalTable/PortalTableLayout';
import { PortalTableColumn } from '../../../../../../../../@Api/Layout/Type/PortalTable/Model/PortalTableColumn';
import Parameter from '../../../../../../../../@Api/Automation/Parameter/Parameter';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import PortalTableColumnEditor from './Column/PortalTableColumnEditor';
import TextComputation from '../../../../../../../../@Api/Automation/Function/Computation/TextComputation';
import FunctionContext from '../../../../../../../../@Api/Automation/Function/FunctionContext';

export interface PortalTableColumnsEditorProps
{
    context: FunctionContext;
    layout: PortalTableLayout;
}

const PortalTableColumnsEditor: React.FC<PortalTableColumnsEditorProps> =
    ({
        context,
        layout,
     }) =>
    {
        const addColumn =
            useCallback(
                (resultParameter: Parameter<any>) =>
                    runInAction(
                        () =>
                            layout.columns = [
                                ...layout.columns,
                                new PortalTableColumn(
                                    uuid(),
                                    new TextComputation(
                                        resultParameter.name,
                                        false,
                                        []
                                    ),
                                    resultParameter,
                                    false,
                                    false,
                                    true,
                                    1
                                )
                            ]
                    ),
                [
                    layout,
                ]
            );
        const removeColumn =
            useCallback(
                (column: PortalTableColumn) =>
                    runInAction(
                        () =>
                        {
                            layout.orderings =
                                layout.orderings.filter(
                                    checkOrdering =>
                                        checkOrdering.columnId !== column.id
                                );
                            layout.columns =
                                layout.columns.filter(
                                    checkColumn =>
                                        checkColumn !== column
                                );
                        }
                    ),
                [
                    layout,
                ]
            );

        return <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <LocalizedText
                                code="Generic.Name"
                                value="Naam"
                            />
                        </TableCell>
                        <TableCell>
                            <LocalizedText
                                code="Generic.Value"
                                value="Waarde"
                            />
                        </TableCell>
                        <TableCell>
                            <LocalizedText
                                code="Generic.CustomLayout"
                                value="Aangepaste layout"
                            />
                        </TableCell>
                        <TableCell>
                            <LocalizedText
                                code="Generic.Searchable"
                                value="Zoekbaar"
                            />
                        </TableCell>
                        <TableCell>
                            <LocalizedText
                                code="Generic.Filterable"
                                value="Filterbaar"
                            />
                        </TableCell>
                        <TableCell>
                            <LocalizedText
                                code="Generic.Sortable"
                                value="Sorteerbaar"
                            />
                        </TableCell>
                        <TableCell>
                            <LocalizedText
                                code="Generic.Ratio"
                                value="Ratio"
                            />
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        layout.columns.map(
                            column =>
                                <PortalTableColumnEditor
                                    key={column.id}
                                    context={context}
                                    layout={layout}
                                    column={column}
                                    onDelete={
                                        () =>
                                            removeColumn(column)
                                    }
                                />
                        )
                    }
                    <TableRow>
                        <TableCell
                            colSpan={7}
                        >
                            <MenuButton
                                icon="add"
                                tooltip={
                                    <LocalizedText
                                        code="Generic.Add"
                                        value="Toevoegen"
                                    />
                                }
                                small
                            >
                                <Menu>
                                    {
                                        layout.dataSourceSignature.resultParameters.parameters.map(
                                            parameter =>
                                                <Item
                                                    key={parameter.id}
                                                    name={parameter.name}
                                                    onClick={
                                                        () =>
                                                            addColumn(parameter)
                                                    }
                                                />
                                        )
                                    }
                                </Menu>
                            </MenuButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>;
    };

export default observer(PortalTableColumnsEditor);
