import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ResourceSelection from '../Model/ResourceSelection';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Checkbox from '../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';

export interface PlanDialogProps
{
    resourceSelections: ResourceSelection[];
    activeSelections: ResourceSelection[];
    onChange: (selections: ResourceSelection[]) => void;
}

export const ResourceSelectionFilter: React.FC<PlanDialogProps> = observer (
    ({
        resourceSelections,
        activeSelections,
        onChange
     }) =>
    {
        const activeResourceSelections =
            useMemo(
                () =>
                    new Set(activeSelections),
                [
                    activeSelections
                ]);

        const uniqueResourceSelections =
            useMemo(
                () =>
                    new Set(resourceSelections),
                [
                    resourceSelections
                ]);

        const toggleResourceSelection =
            useCallback(
                (selection: ResourceSelection) =>
                {
                    const newSelections = new Set(activeResourceSelections);

                    if (activeResourceSelections.has(selection))
                    {
                        newSelections.delete(selection);
                    }
                    else
                    {
                        newSelections.add(selection);
                    }

                    return onChange(
                        resourceSelections.filter(
                            selection =>
                                newSelections.has(selection)));
                },
                [
                    activeResourceSelections,
                    resourceSelections,
                    onChange
                ]);

        return <MenuButton
            icon="filter_list"
            badge={activeResourceSelections.size !== uniqueResourceSelections.size ? 1 : undefined}
            badgeVariant="dot"
            tooltip={
                <LocalizedText
                    code="Generic.Filter"
                    value="Filter"
                />
            }
        >
            <CardInset>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    {
                        Array.from(uniqueResourceSelections).map(
                            selection =>
                                <ViewGroupItem
                                    key={selection.id}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                        alignment="center"
                                    >
                                        <ViewGroupItem>
                                            <Checkbox
                                                checked={activeResourceSelections.has(selection)}
                                                onToggle={() => toggleResourceSelection(selection)}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            {selection.entityType.getName(true)}
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>)
                    }
                </ViewGroup>
            </CardInset>
        </MenuButton>;
    }
);
