import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import Key from '../Model/Key';
import uuid from '../../../../../@Util/Id/uuid';
import { EntityPath } from '../../Path/@Model/EntityPath';
import KeyField from '../Model/KeyField';
import getTypes from '../../Type/Api/getTypes';

export default function getDefaultKeys(entityType: EntityType)
{
    const rootPath = EntityPath.fromEntityType(entityType);
    const types = getTypes();

    if (entityType.isA(types.Relationship.Person.Type))
    {
        return [
            new Key(
                uuid(),
                [
                    new KeyField(
                        uuid(),
                        rootPath
                            .joinTo(
                                types.Relation.RelationshipDefinition.Relationships,
                                true)
                            .field(),
                        true),
                    new KeyField(
                        uuid(),
                        rootPath
                            .joinTo(
                                types.Relationship.Person.RelationshipDefinition.Person,
                                false)
                            .field(),
                        true)
                ])
        ];
    }
    else if (entityType.isA(types.Relationship.Organization.Type))
    {
        return [
            new Key(
                uuid(),
                [
                    new KeyField(
                        uuid(),
                        rootPath
                            .joinTo(
                                types.Relation.RelationshipDefinition.Relationships,
                                true)
                            .field(),
                        true),
                    new KeyField(
                        uuid(),
                        rootPath
                            .joinTo(
                                types.Relationship.Organization.RelationshipDefinition.Organization,
                                false)
                            .field(),
                        true)
                ])
        ];
    }
    else if (entityType.isA(types.Relation.Person.Type))
    {
        // return [
        //     new Key(
        //         uuid(),
        //         [
        //             new KeyField(
        //                 uuid(),
        //                 rootPath.field(types.Relation.Person.Field.FirstName),
        //                 true),
        //             new KeyField(
        //                 uuid(),
        //                 rootPath.field(types.Relation.Person.Field.LastName),
        //                 true)
        //         ])
        // ];

        return [];
    }
    else if (entityType.isA(types.Relation.Organization.Type))
    {
        // return [
        //     new Key(
        //         uuid(),
        //         [
        //             new KeyField(
        //                 uuid(),
        //                 rootPath.field(types.Relation.Organization.Field.Name),
        //                 true)
        //         ]),
        //     new Key(
        //         uuid(),
        //         [
        //             new KeyField(
        //                 uuid(),
        //                 rootPath
        //                     .joinTo(
        //                         types.Relation.Organization.RelationshipDefinition.VisitingAddress,
        //                         false)
        //                     .field(types.Address.Field.PostalCode),
        //                 true),
        //             new KeyField(
        //                 uuid(),
        //                 rootPath
        //                     .joinTo(
        //                         types.Relation.Organization.RelationshipDefinition.VisitingAddress,
        //                         false)
        //                     .field(types.Address.Field.HouseNr),
        //                 true),
        //             new KeyField(
        //                 uuid(),
        //                 rootPath
        //                     .joinTo(
        //                         types.Relation.Organization.RelationshipDefinition.VisitingAddress,
        //                         false)
        //                     .field(types.Address.Field.HouseNrSuffix),
        //                 false)
        //         ])
        // ];

        return [];
    }
    else if (entityType.isA(types.Label.Type))
    {
        return [
            new Key(
                uuid(),
                [
                    new KeyField(
                        uuid(),
                        rootPath.field(types.Label.Field.Name),
                        true)
                ])
        ];
    }
    else
    {
        return [];
    }
}
