import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import FileDropZone from '../../../../../@Future/Component/Generic/Input/FileDropZone/FileDropZone';
import useTypes from '../../../Entity/Type/Api/useTypes';
import { runInAction } from 'mobx';
import ResourceImageLayoutViewer from './ResourceImageLayoutViewer';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import { FileValue } from '../../../DataObject/Type/File/FileValue';
import ResourceImageLayout from '../../../../../@Api/Layout/Type/ResourceImageLayout';
import constructEntity from '../../../../../@Api/Entity/constructEntity';
import { constructEntityOfType } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { setValueByFieldInEntity } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { CommitContextImpl } from '../../../../../@Api/Entity/Commit/Context/CommitContextImpl';

export interface ResourceImageLayoutEditorProps extends LayoutEditorProps<ResourceImageLayout>
{

}

const ResourceImageLayoutEditor: React.FC<ResourceImageLayoutEditorProps> =
    props =>
    {
        const types = useTypes();
        const onDrop =
            useCallback(
                (files: File[]) =>
                {
                    const commitContext = new CommitContextImpl({ allowAutoCommit: false });
                    const resource = constructEntityOfType(types.Resource.Type);
                    setValueByFieldInEntity(
                        resource,
                        types.Resource.Field.Name,
                        files[0].name,
                        commitContext
                    );
                    setValueByFieldInEntity(
                        resource,
                        types.Resource.Field.File,
                        FileValue.fromFile(files[0]),
                        commitContext
                    );

                    return constructEntity(
                        resource.entityType,
                        undefined,
                        resource,
                        savedResource =>
                            runInAction(
                                () =>
                                {
                                    props.layout.resourceToken = savedResource.getObjectValueByField(types.Resource.Field.Token);
                                    props.layout.resourceId = savedResource.uuid;
                                }),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        commitContext
                    );
                },
                [
                    types
                ]);

        const parameterAssignment =
            useMemo(
                () =>
                    props.parameterAssignment || new ParameterAssignment(),

                [
                    props.parameterAssignment
                ]);

        if (props.layout.resourceId)
        {
            return <ResourceImageLayoutViewer
                {...props}
                parameterAssignment={parameterAssignment}
            />;
        }
        else
        {
            return <FileDropZone
                onDrop={onDrop}
            />;
        }
    };

export default observer(ResourceImageLayoutEditor);
