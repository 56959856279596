import React from 'react';
import { observer } from 'mobx-react-lite';
import ParameterDictionary from '../../../../@Api/Automation/Parameter/ParameterDictionary';
import Layout from '../../../../@Api/Layout/Layout';
import ParameterAssignment from '../../../../@Api/Automation/Parameter/ParameterAssignment';
import LayoutEditorContent from './LayoutEditorContent';
import LayoutControlsRegion from './Controls/Region/LayoutControlsRegion';
import LayoutControlsCard from './Controls/Card/LayoutControlsCard';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';

export interface LayoutEditorProps<L extends Layout>
{
    layout: L;
    parameterDictionary: ParameterDictionary;
    parameterAssignment?: ParameterAssignment; // optional, as this might not be WYSWYG
    disableControls?: boolean;
    onChange?: (layout: L) => void;
    commitContext?: CommitContext;
}

const LayoutEditor: React.FC<LayoutEditorProps<Layout>> =
    props =>
    {
        const { onChange, ...contentProps } = props;
        const content =
            <LayoutEditorContent
                {...props}
                disableControls={false}
            />;

        if (props.disableControls)
        {
            return content;
        }
        else
        {
            return <LayoutControlsRegion
                {...contentProps}
                editor={content}
                controls={
                    <LayoutControlsCard
                        {...props}
                        disableControls={false}
                    />
                }
            />;
        }
    };

export default observer(LayoutEditor);
