import { type } from '../../../@Util/Serialization/Serialization';
import { EntityRelationshipMutation } from './EntityRelationshipMutation';

@type('EntityRelationshipUpdateMutation')
export class EntityRelationshipUpdateMutation extends EntityRelationshipMutation
{
    // ------------------- Persistent Properties --------------------
    
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
