import Period from '../Model/Period';
import { default as moment, Duration } from 'moment';

export default function getPeriodsInPeriod(period: Period,
                                           duration: Duration)
{
    let from = period.from;
    const periods: Period[] = [];

    while (from.isBefore(period.to))
    {
        const to = moment(from).add(duration);

        periods.push({
            from: from,
            to: to
        });

        from = to;
    }

    return periods;
}
