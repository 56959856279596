import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../Type/Api/useTypes';
import { useCallback } from 'react';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { constructEntityOfType } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { setValueByFieldInEntity } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import initializeDefaultRelationships from '../../Constructor/Api/initializeDefaultRelationships';

export default function useCreateTimeRegistration(commitContext: CommitContext)
{
    const types = useTypes();

    return useCallback(
        (employee: Entity,
         relationship: Entity,
         activity: Entity,
         parentActivity: Entity,
         startDate?: Date,
         endDate?: Date,
         lastTimeRegistration?: Entity) =>
        {
            const timeRegistration =
                constructEntityOfType(
                    types.TimeRegistration.Type,
                    commitContext
                );

            if (employee)
            {
                updateRelationship(
                    timeRegistration,
                    true,
                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                    employee,
                    commitContext
                );
            }

            if (relationship)
            {
                updateRelationship(
                    timeRegistration,
                    true,
                    types.Relationship.RelationshipDefinition.TimeRegistrations,
                    relationship,
                    commitContext
                );
            }

            if (!activity && !parentActivity && lastTimeRegistration)
            {
                activity =
                    lastTimeRegistration.getRelatedEntityByDefinition(
                        false,
                        types.TimeRegistration.RelationshipDefinition.Activity,
                        commitContext
                    );

                parentActivity =
                    lastTimeRegistration.getRelatedEntityByDefinition(
                        true,
                        types.Activity.RelationshipDefinition.TimeRegistrations,
                        commitContext
                    );
            }

            if (activity)
            {
                updateRelationship(
                    timeRegistration,
                    false,
                    types.TimeRegistration.RelationshipDefinition.Activity,
                    activity,
                    commitContext
                );
            }

            if (parentActivity)
            {
                updateRelationship(
                    timeRegistration,
                    true,
                    types.Activity.RelationshipDefinition.TimeRegistrations,
                    parentActivity,
                    commitContext
                );
            }

            setValueByFieldInEntity(
                timeRegistration,
                types.TimeRegistration.Field.StartDate,
                startDate ? startDate : new Date(),
                commitContext
            );

            if (endDate)
            {
                setValueByFieldInEntity(
                    timeRegistration,
                    types.TimeRegistration.Field.EndDate,
                    endDate,
                    commitContext
                );
            }

            initializeDefaultRelationships(timeRegistration)
                .then(
                    () =>
                    {
                        return timeRegistration
                    }
                );

            return timeRegistration;
        },
        [
            types,
            commitContext,
        ]);
}
