import React, { useCallback, useEffect, useRef, useState } from 'react';

export type ArrowStart = 'straight' | 'arrow-indent';
export type ArrowEnd = 'straight' | 'arrow' | 'round-indent' | 'round';

export interface ArrowProps
{
    color?: string;
    styles?: any;
    start?: ArrowStart;
    end?: ArrowEnd;
    stroke?: number;
    strokeColor?: string;
    renderInBackground?: boolean;
}

const Arrow: React.FC<ArrowProps> =
    props =>
    {
        const container = useRef(null);
        const [ parentWidth, setParentWidth ] = useState(0);
        const [ parentHeight, setParentHeight ] = useState(0);

        const handleResize =
            useCallback(
                () =>
                {
                    setTimeout(
                        () =>
                        {
                            const current = container.current;

                            if (current)
                            {
                                setParentWidth(current.clientWidth);
                                setParentHeight(current.clientHeight);
                            }
                        },
                        0);
                },
                [
                    setParentHeight,
                    setParentWidth,
                    container,
                    // container.current
                ]);

        // This effect should be triggered on all component updates to reset the width and height in the DOM tree.
        // Therefore, no empty is array is passed to the useEffect hook and the warning for that is supressed.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(() => handleResize());

        useEffect(
            () =>
            {
                window.addEventListener('resize', handleResize);

                return () =>
                {
                    window.removeEventListener('resize', handleResize);
                };
            },
            [
                handleResize
            ]);

        const stroke = props.stroke ? props.stroke : 0;
        const a = stroke > 0 ? stroke / 2 : 0;
        const width = parentWidth - stroke;
        const height = parentHeight - stroke;
        const depth = height / 2;
        const verticalCenter = height / 2;
        const start: ArrowStart = props.start ? props.start : 'straight';
        const end: ArrowEnd = props.end ? props.end : 'arrow';
        let template = undefined;

        if (start === 'straight')
        {
            if (end === 'straight')
            {
                template = `M${a},${a}h${width}v${height}H${a}V${a}z`;
            }
            else if (end === 'arrow')
            {
                template = `M${a},${a}h${width-depth}l${depth},${verticalCenter}L${width-depth},${height+a}H${a}V${a}z`;
            }
            else if (end === 'round-indent')
            {
                template = `M${a},${a}h${width}c${a},${a}-${verticalCenter/2},${verticalCenter/4}-${verticalCenter/2},${verticalCenter}S${width},${height},${width+a},${height+a}H${a}V${a}z`;
            }
            else if (end === 'round')
            {
                template = `M${a},${a}h${width}c${a},${a} ${verticalCenter/2},${verticalCenter/4} ${verticalCenter/2},${verticalCenter}S${width},${height},${width+a},${height+a}H${a}V${a}z`;
            }
        }
        else if (start === 'arrow-indent')
        {
            if (end === 'straight')
            {
                template = `M${a},${a}h${width}v${height}H${a}l${depth}-${verticalCenter}L${a},${a}z`;
            }
            else if (end === 'arrow')
            {
                template = `M${a},${a}h${width-depth}l${depth},${verticalCenter}L${width-depth},${height+a}H${a}l${depth}-${verticalCenter}L${a},${a}z`;
            }
            else if (end === 'round-indent')
            {
                template = `M${a},${a}h${width}c${a},${a}-${verticalCenter/2},${verticalCenter/4}-${verticalCenter/2},${verticalCenter}S${width},${height},${width+a},${height+a}H${a}l${depth}-${verticalCenter}L${a},${a}z`;
            }
            else if (end === 'round')
            {
                template = `M${a},${a}h${width-depth}c${a},${a} ${verticalCenter/2},${verticalCenter/4} ${verticalCenter/2},${verticalCenter}S${width-depth},${height},${width+a},${height+a}H${a}l${depth}-${verticalCenter}L${a},${a}z`;
            }
        }

        return <div
            ref={container}
            style={{
                position: props.renderInBackground ? 'absolute' : undefined,
                width: '100%',
                height: '100%'
            }}
        >
            {
                parentWidth && parentHeight &&
                    <svg
                        x={0}
                        y={0}
                        width={parentWidth}
                        height={parentHeight}
                    >
                        <path
                            fill={props.color}
                            d={template}
                            strokeWidth={props.stroke}
                            stroke={props.stroke && props.strokeColor}
                        />
                    </svg>
            }
        </div>;
    };

export default Arrow;
