import Period from '../Model/Period';
import { ResourceInterval } from '../Model/ResourceInterval';
import PeriodSpecification from '../Model/PeriodSpecification';
import { dayResolution, monthResolution, quarterResolution, twoWeekResolution, weekResolution, yearResolution } from '../Model/PeriodSpecifications';

export function getPeriodSpecification(period: Period, interval: ResourceInterval): PeriodSpecification
{
    if (period.to.diff(period.from, 'day') <= 2)
    {
        return dayResolution;
    }
    else if (period.to.diff(period.from, 'week') <= 2)
    {
        if (interval.id === 'week')
        {
            return weekResolution;
        }
        else
        {
            return twoWeekResolution;
        }
    }
    else if (period.to.diff(period.from, 'month') <= 2)
    {
        return monthResolution;
    }
    else if (period.to.diff(period.from, 'quarter') <= 2)
    {
        return quarterResolution;
    }
    else
    {
        return yearResolution;
    }
}
