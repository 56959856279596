import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import ClickLayout from '../../../../../@Api/Layout/Type/ClickLayout';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import LayoutActionEditor from '../../Action/LayoutActionEditor';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import { runInAction } from 'mobx';
import LayoutAction from '../../../../../@Api/Layout/Action/LayoutAction';

export interface ClickLayoutControlsProps extends LayoutEditorProps<ClickLayout>
{

}

const ClickLayoutControls: React.FC<ClickLayoutControlsProps> =
    props =>
    {
        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        props.parameterDictionary,
                        props.parameterAssignment,
                        props.commitContext
                    ),
                [
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        const setAction =
            useCallback(
                (action: LayoutAction) =>
                    runInAction(
                        () =>
                            props.layout.action = action),
                [
                    props.layout
                ]);

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <Input
                    labelPosition="top"
                    label={
                        <LocalizedText
                            code="Generic.Action"
                            value="Actie"
                        />
                    }
                >
                    <LayoutActionEditor
                        context={context}
                        action={props.layout.action}
                        onChange={setAction}
                    />
                </Input>
            </InputGroup>
        </CardInset>;
    };

export default observer(ClickLayoutControls);
