import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Action from '../../../../../@Api/Automation/Function/Action/Action';
import { findAssistantAction } from './findAssistantAction';
import { AssistantIfThenAction } from '../../Model/Automation/Action/AssistantIfThenAction';
import { findAssistantPredicate } from '../Predicate/findAssistantPredicate';
import ConditionalAction from '../../../../../@Api/Automation/Function/Action/ConditionalAction';
import ConditionalInAction from '../../../../../@Api/Automation/Function/Action/ConditionalInAction';
import { wrapAssistantActionInCompositeIfNeeded } from './wrapAssistantActionInCompositeIfNeeded';

export function findAssistantIfThenAction(
    action: AssistantIfThenAction,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Action<any, any>
{
    const when =
        findAssistantPredicate(
            action.if,
            parameters,
            rootParameter
        );
    const then =
        wrapAssistantActionInCompositeIfNeeded(
            findAssistantAction(
                action.then,
                parameters,
                rootParameter
            )
        );
    const otherwise =
        action.else
            ? wrapAssistantActionInCompositeIfNeeded(
                findAssistantAction(
                    action.else,
                    parameters,
                    rootParameter
                )
            )
            : undefined;

    return new ConditionalAction(
        [
            new ConditionalInAction(
                when,
                then
            )
        ],
        otherwise
    );
}
