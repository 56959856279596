import React from 'react';
import { observer } from 'mobx-react-lite';
import { EntitySelectionAggregateResult } from '../../../../Selection/Model/EntitySelectionAggregateResult';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useDividerGlue from '../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import Divider from '../../../../../../../@Future/Component/Generic/Divider/Divider';
import Item from './Item/Item';
import useChildSelection from '../Api/useChildSelection';
import ExpansionGroup from '../../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import useFilterAndSortAggregateResult from '../Chart/Api/useFilterAndSortAggregateResult';
import { default as SegmentModel } from '../../../Model/Segment';

export interface ExpansionProps
{
    entityType: EntityType;
    segment: SegmentModel;
    nextSegments: SegmentModel[];
    result: EntitySelectionAggregateResult;
}

const ExpansionSelector: React.FC<ExpansionProps> =
    props =>
    {
        const [ selectedChild, setSelectedChild ] =
            useChildSelection(
                props.segment,
                props.result);
        const glue = useDividerGlue();

        const sortedAggregateResults =
            useFilterAndSortAggregateResult(
                props.segment,
                props.result);

        return <ExpansionGroup>
            <Divider />
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={glue}
            >
                {
                    sortedAggregateResults.map(
                        (child, idx) =>
                            <ViewGroupItem
                                key={child.id}
                            >
                                <Item
                                    entityType={props.entityType}
                                    segment={props.segment}
                                    nextSegments={props.nextSegments}
                                    child={child}
                                    idx={idx}
                                    selected={selectedChild === child}
                                    onSelect={setSelectedChild}
                                />
                            </ViewGroupItem>)
                }
            </ViewGroup>
        </ExpansionGroup>;
    };

export default observer(ExpansionSelector);
