import FieldInput from '../FieldInput';
import RelationshipInput from '../RelationshipInput';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import Input from '../Input';

export default function resolveInputFromFieldPath(fieldPath: EntityFieldPath): Input
{
    if (fieldPath.field)
    {
        return new FieldInput(
            fieldPath.entityType,
            fieldPath.field);
    }
    else if (fieldPath.relationshipDefinition)
    {
        return new RelationshipInput(
            fieldPath.basePath.entityType,
            fieldPath.relationshipDefinition,
            fieldPath.isParentRelationship);
    }
    else
    {
        return undefined;
    }
}
