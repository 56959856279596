import React from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../../../../Type/Api/useTypes';
import Input from '../../../../../../../../../../Input/Input';
import Base from '../../Base/Base';
import { ContentProps } from '../../Content';
import useEntityValue from '../../../../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';

export interface SalesOpportunityProps extends ContentProps
{

}

const SalesOpportunity: React.FC<SalesOpportunityProps> =
    props =>
    {
        const types = useTypes();

        const currency =
            useEntityValue<string>(
                props.entity,
                types.Activity.Field.Currency);

        const fields = [
                <Input
                    key={0}
                    entity={props.entity}
                    field={types.Activity.SalesOpportunity.RelationshipDefinition.Phase}
                    labelPosition={props.labelPosition}
                    inline={props.inlineLabel}
                    commitContext={props.commitContext}
                />,
                <Input
                    key={1}
                    entity={props.entity}
                    field={
                        currency
                            ? types.Activity.Field.AmountInCurrency
                            : types.Activity.Field.Amount
                    }
                    labelPosition={props.labelPosition}
                    inline={props.inlineLabel}
                    commitContext={props.commitContext}
                />,
                <Input
                    key={2}
                    entity={props.entity}
                    field={types.Activity.SalesOpportunity.Field.Probability}
                    labelPosition={props.labelPosition}
                    inline={props.inlineLabel}
                    commitContext={props.commitContext}
                />
        ];

        return <Base
            {...props}
            open
        >
            {fields}
        </Base>;
    };

export default observer(SalesOpportunity);
