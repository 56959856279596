import getErrorData from './getErrorData';

export default function isUserError(error: any)
{
    const errorData = getErrorData(error);

    return errorData !== undefined
        && errorData.ok === false
        && errorData.message !== undefined
        && errorData.message.length > 0;
}
