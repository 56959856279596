import React, { useCallback, useEffect, useMemo } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import Centered from '../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../@Future/Component/Generic/Loader/Loader';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { groupBy } from '../../../../../../../../@Util/MapUtils/groupBy';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import equalsEntity from '../../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import HoverCardMiddle from '../../../../../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import ErrorBoundary from '../../../../../../../Error/ErrorBoundary';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import { useNewCommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { useCommittableEntity } from '../../../../../../../../@Api/Entity/Commit/Context/Api/useCommittableEntity';
import { CommitBuilder } from '../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { useExpansion } from '../../../../../Selection/Api/useExpansion';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import { EntitySelectionBuilder } from '../../../../../Selection/Builder/EntitySelectionBuilder';
import { Aggregate } from '../../../../../../DataObject/Model/Aggregate';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { ProductLinesTable } from '../ProductLinesTable/ProductLinesTable';
import { createDateIntervalComparator } from '../../../../../../../Generic/List/V2/Comparator/DateIntervalComparator';

export interface ProductLinesProps
{
    entity: Entity;
    onSelectProductLine?: (entity: Entity, selected: boolean) => void;
    onSelectAllProductLines?: (lines: Entity[], selected: boolean) => void;
    selectedProductLines?: Set<Entity>;
    milestone?: Entity;
    showMilestone?: boolean;
    billed?: boolean;
    addNewLineIfEmpty?: boolean;
    isStrikethrough?: (line: Entity) => boolean;
    disabled?: boolean;
    onFreshTransactionalEntity?: (entity: Entity) => void;
    showExpiredLines?: boolean;
    hideBilledNonRecurringLines?: boolean;
    commitContext?: CommitContext;
}

const ProductLines: React.FC<ProductLinesProps> =
    props =>
    {
        const types = useTypes();
        const commitContext =
            useNewCommitContext(
                undefined,
                {
                    guard:
                        (operation, commitContext) =>
                        {
                            // Do not commit yet if new product line has no product yet
                            if (operation.type === 'CreateEntity')
                            {
                                const entity = commitContext.getEntity(operation.entityId)!;

                                return !entity.entityType.isA(types.ProductLine.Type)
                                    || entity.hasRelationshipsByDefinition(
                                        false,
                                        types.ProductLine.RelationshipDefinition.Product,
                                        commitContext
                                    );
                            }
                            else
                            {
                                return true;
                            }
                        }
                },
                [
                    props.entity,
                    types,
                ]
            );
        const entity = useCommittableEntity(props.entity, commitContext);
        const currency = useEntityValue(props.entity, types.Activity.Field.Currency, undefined,props.commitContext);

        const pathToLines =
            useComputed(
                () =>
                    EntityPath.fromEntity(entity)
                        .joinTo(
                            types.Activity.RelationshipDefinition.ProductLines,
                            false),
                [
                    entity,
                    types
                ]);

        useEffect(
            () =>
            {
                if (props.onFreshTransactionalEntity)
                {
                    props.onFreshTransactionalEntity(entity);
                }
            },
            [
                props.onFreshTransactionalEntity,
                entity
            ]);

        const allLines =
            useComputed(
                () =>
                    pathToLines.traverseEntity(entity, commitContext)
                        .filter(
                            line =>
                                props.milestone
                                    ?
                                        equalsEntity(
                                            props.milestone,
                                            line.getRelatedEntityByDefinition(
                                                true,
                                                types.Milestone.RelationshipDefinition.ProductLines,
                                                commitContext
                                            )
                                        )
                                    :
                                        true),
                [
                    pathToLines,
                    entity,
                    props.milestone,
                    types,
                    commitContext,
                ]);

        const isDisabled = props.disabled;
        const addLineToEntity =
            useCallback(
                async (entity: Entity) =>
                {
                    if (!isDisabled)
                    {
                        const highestSortIndexSelectionBuilder =
                            EntitySelectionBuilder.builder(
                                types.ProductLine.Type,
                                (builder, rootPath) =>
                                    builder
                                        .where(
                                            cb =>
                                                cb.relatedToEntity(
                                                    pathToLines.reverse(),
                                                    entity
                                                )
                                        )
                                        .aggregateOn(
                                            rootPath.field(types.Entity.Field.SortIndex),
                                            undefined,
                                            Aggregate.Max
                                        )
                            );
                        const highestSortIndexResult = await highestSortIndexSelectionBuilder.selectAggregates();
                        const highestSortIndex = (highestSortIndexResult.aggregates[0].value ?? 0);
                        highestSortIndexSelectionBuilder.dispose();

                        return new CommitBuilder(commitContext)
                            .createEntity(
                                pathToLines.entityType,
                                newLine =>
                                    newLine
                                        .setObjectValue(
                                            types.ProductLine.Field.Currency,
                                            currency
                                        )
                                        .ifValid(
                                            () =>
                                                props.milestone !== undefined,
                                            () =>
                                                newLine.relateTo(
                                                    true,
                                                    types.Milestone.RelationshipDefinition.ProductLines,
                                                    props.milestone
                                                )
                                        )
                                        .setObjectValue(
                                            types.Entity.Field.SortIndex,
                                            highestSortIndex + 1
                                        )
                                        .setObjectValue(
                                            types.ProductLine.Field.Quantity,
                                            1
                                        )
                                        .relateTo(
                                            true,
                                            pathToLines.lastJoinNode.relationshipDefinition,
                                            entity
                                        )
                            );
                    }
                },
                [
                    types,
                    currency,
                    pathToLines,
                    isDisabled,
                    props.milestone,
                    commitContext,
                ]);

        const [ isLoading ] =
            useExpansion(
                entity.entityType,
                rootPath =>
                {
                    const pathToLines =
                        rootPath.joinTo(
                            types.Activity.RelationshipDefinition.ProductLines,
                            false);

                    return [
                        pathToLines,
                        pathToLines
                            .joinTo(
                                types.ProductLine.RelationshipDefinition.Product,
                                false),
                        pathToLines
                            .joinTo(
                                types.ProductLine.RelationshipDefinition.VatGroup,
                                false),
                        ...types.Milestone.Type
                            ?
                                [
                                    pathToLines
                                        .joinTo(
                                            types.Milestone.RelationshipDefinition.ProductLines,
                                            true)
                                ]
                            :
                                []
                    ];
                },
                () => [
                    entity
                ],
                [
                    entity,
                    types,
                ]
            );
        const addLineToCurrentEntity =
            useCallback(
                () =>
                    addLineToEntity(entity),
                [
                    addLineToEntity,
                    entity
                ]
            );
        const now =
            useMemo(
                () =>
                    new Date(),
                []
            );
        const filteredLines =
            useComputed(
                () =>
                    allLines
                        .filter(
                            line =>
                                !line.isDeleted
                                && (
                                    props.billed === undefined ||
                                    (line.getObjectValueByField(types.ProductLine.Field.IsBilled, commitContext) ?? false) === props.billed
                                )
                        )
                        .filter(
                            line =>
                            {
                                if (props.showExpiredLines)
                                {
                                    return true;
                                }
                                else
                                {
                                    const endDate = line.getObjectValueByField(types.ProductLine.Field.EndDate, commitContext);
                                    return endDate === undefined || now < endDate;
                                }
                            }
                        )
                        .filter(
                            line =>
                            {
                                if (props.hideBilledNonRecurringLines)
                                {
                                    const isNonRecurring =
                                        !line.hasValueForField(
                                            types.ProductLine.Field.RepeatInterval,
                                            commitContext
                                        );
                                    const isBilled =
                                        line.getObjectValueByField(
                                            types.ProductLine.Field.IsBilled,
                                            commitContext
                                        )
                                    return !(isNonRecurring && isBilled);
                                }
                                else
                                {
                                    return true;
                                }
                            }
                        )
                        .sort(
                            createDateIntervalComparator(
                                line =>
                                    line.getDataObjectValueByField(
                                        types.ProductLine.Field.RepeatInterval,
                                        undefined,
                                        commitContext
                                    )?.value
                            )
                        ),
                [
                    allLines,
                    props.billed,
                    types,
                    commitContext,
                    now,
                    props.showExpiredLines,
                    props.hideBilledNonRecurringLines,
                ]
            );
        const linesByInterval =
            useComputed(
                () =>
                {
                    const linesByInterval =
                        groupBy(
                            filteredLines,
                            line =>
                                line.getDataObjectValueByField(
                                    types.ProductLine.Field.RepeatInterval,
                                    undefined,
                                    commitContext
                                ).toString()
                        );

                    if (linesByInterval.size === 0)
                    {
                        linesByInterval.set(undefined, []);
                    }

                    return linesByInterval;
                },
                [
                    types,
                    filteredLines,
                    commitContext,
                ]
            );

        useAsyncResult(
            async () =>
            {
                if (props.addNewLineIfEmpty)
                {
                    if (entity.isNew())
                    {
                        if (pathToLines.traverseEntity(entity, commitContext).length === 0)
                        {
                            await addLineToEntity(entity);
                        }
                    }
                    else
                    {
                        const selectionBuilder =
                            EntitySelectionBuilder.builder(
                                types.ProductLine.Type,
                                builder =>
                                    builder.where(
                                        cb =>
                                            cb.relatedToEntity(
                                                pathToLines.reverse(),
                                                entity
                                            )
                                    )
                            );
                        const count = await selectionBuilder.count();

                        if (count.getValue() === 0)
                        {
                            await addLineToEntity(entity);
                        }

                        selectionBuilder.dispose();
                    }
                }
            },
            [
                props.addNewLineIfEmpty,
                entity,
                pathToLines,
                commitContext,
                types,
            ]
        );

        return <ErrorBoundary>
            <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                {
                    isLoading &&
                        <ViewGroupItem>
                            <CardInset>
                                <Centered
                                    horizontal
                                >
                                    <Loader />
                                </Centered>
                            </CardInset>
                        </ViewGroupItem>
                }
                {
                    Array.from(linesByInterval.keys())
                        .map(
                            interval =>
                                <ViewGroupItem
                                    key={interval || 'undefined'}
                                >
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={5}
                                    >
                                        {
                                            !(linesByInterval.size === 1 && !interval) &&
                                                <ViewGroupItem>
                                                    <CardInset
                                                        horizontal
                                                        vertical={false}
                                                    >
                                                        <strong>{interval || <LocalizedText
                                                            code="Generic.Once"
                                                            value="Eenmalig"
                                                        />}</strong>
                                                    </CardInset>
                                                </ViewGroupItem>
                                        }
                                        <ViewGroupItem>
                                            <ProductLinesTable
                                                entity={entity}
                                                lines={linesByInterval.get(interval)}
                                                onSelectProductLine={props.onSelectProductLine}
                                                onSelectAllProductLines={props.onSelectAllProductLines}
                                                selectedProductLines={props.selectedProductLines}
                                                disabled={isDisabled}
                                                showMilestone={props.showMilestone}
                                                isStrikethrough={props.isStrikethrough}
                                                discountHidden={linesByInterval.size > 1}
                                                loading={isLoading}
                                                commitContext={commitContext}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>
                        )
                }
            </ViewGroup>
            {
                isDisabled &&
                    <CardInset
                        top={false}
                        left={false}
                        right={false}
                    />
            }
            {
                !isDisabled &&
                    <HoverCardMiddle
                        onClick={addLineToCurrentEntity}
                    >
                        <LocalizedText
                            code="ProductLines.AddProductButton"
                            value="+ Product"
                        />
                    </HoverCardMiddle>
            }
        </ErrorBoundary>;
    };

export default observer(ProductLines);
