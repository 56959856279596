import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react';
import useApsisCampaignSendCount from '../Hooks/useApsisCampaignSendCount';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import React, { useMemo } from 'react';
import CampaignPercentageChart from '../../Campaign/Charts/CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';

export interface ApsisCampaignChartSelectionProps
{
    size: number;
    apsisCampaign: Entity;
}

export const ApsisCampaignChartSelection: React.FC<ApsisCampaignChartSelectionProps> =
    observer(
        ({
            size,
            apsisCampaign
        }) =>
        {
            const totalResultCount =
                useApsisCampaignSendCount(
                    apsisCampaign
                );

            const allSet =
                useAllSet(
                    [
                        totalResultCount
                    ]
                );

            const formattedSelection =
                useMemo(
                    () =>
                    {
                        return `${totalResultCount} / ${totalResultCount}`;
                    },
                    [
                        totalResultCount
                    ]
                );

            return <div>
                {
                    allSet &&
                    <CampaignPercentageChart
                        value={totalResultCount / totalResultCount * 100}
                        size={size}
                        label={localizeText('ApsisCampaign.Chart.Sent', 'Verzonden')}
                        selection={formattedSelection}
                    />
                }
            </div>;
        }
    )
