import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { EntitySelectionAggregateResult } from '../../../../../Selection/Model/EntitySelectionAggregateResult';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import useTranslation from '../../../../../../../../@Service/Localization/Api/useTranslation';
import { EntitySelectionResult } from '../../../../../Selection/Model/EntitySelectionResult';
import StatLabel from '../../../../../../../../@Future/Component/Generic/StatLabel/StatLabel';
import { DataObjectRepresentation } from '../../../../../../DataObject/Model/DataObjectRepresentation';
import Bucket from '../../../../../../../../@Future/Component/Generic/Bucket/Bucket';
import useResultCount from '../../../Api/useResultCount';
import useResultName from '../../../Api/useResultName';
import FavoriteCallbackContext from '../../../../Favorite/FavoriteCallbackContext';
import Button from '../../../../Favorite/Button/Button';
import useResultFilter from '../../../Api/useResultFilter';
import styles from './Item.module.scss';
import Segment from '../../../../Model/Segment';
import DatasetContext from '../../../../Context/DatasetContext';
import Tooltip from '../../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';

export interface ItemProps
{
    entityType: EntityType;
    segment: Segment;
    nextSegments: Segment[];
    child: EntitySelectionAggregateResult;
    idx: number;
    numberOfBuckets: number;
    selected?: boolean;
    onSelect: (result?: EntitySelectionResult) => void;
}

const Item: React.FC<ItemProps> =
    props =>
    {
        const { child, idx, numberOfBuckets, onSelect, selected } = props;
        const dataset = useContext(DatasetContext);
        const noValue = useTranslation('Generic.Uncategorized');

        const select =
            useCallback(
                () =>
                    onSelect(child),
                [
                    onSelect,
                    child
                ]);
        const deselect =
            useCallback(
                () =>
                    onSelect(),
                [
                    onSelect
                ]);

        const name = useResultName(props.segment, child, idx);
        const count = useResultCount(child);
        const favoriteCallback = useContext(FavoriteCallbackContext);
        const childFilter =
            useResultFilter(
                dataset,
                props.segment,
                child);
        const compactValue =
            useMemo(
                () =>
                    child.aggregates.length > 1
                        ? child.aggregates[1].toString(new DataObjectRepresentation({ isCompact: true }))
                        : (count ?? '-'),
                [
                    child,
                    count,
                ]
            );

        const fullValue =
            useMemo(
                () =>
                    child.aggregates.length > 1
                        ? child.aggregates[1].toString(new DataObjectRepresentation({ isCompact: false }))
                        : (count ?? '-'),
                [
                    child,
                    count,
                ]
            );

        return <Bucket
            key={child.id}
            bucketIdx={idx}
            numberOfBucketsInLine={4}
            numberOfBuckets={numberOfBuckets}
            selected={selected}
            onClick={selected ? deselect : select}
            color={props.entityType.getInheritedColor()}
            classes={{
                root: styles.root
            }}
        >
            <Tooltip
                title={fullValue !== compactValue ? fullValue : undefined}
            >
                <StatLabel
                    name={name || noValue}
                    value={compactValue}
                    countValue={child.aggregates.length > 1 ? count : undefined}
                    nameColor={selected && 'white'}
                    countColor={selected && 'white'}
                    valueColor={
                        selected
                            ?
                                'white'
                            :
                                props.entityType.getInheritedColor()}
                />
            </Tooltip>
            {
                favoriteCallback &&
                    <div
                        className={styles.favoriteButton}
                    >
                        <Button
                            segment={props.segment}
                            filter={childFilter}
                        />
                    </div>
            }
        </Bucket>;
    };

export default observer(Item);
