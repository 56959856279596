import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import AutomationDependencyContext from '../../Automation/AutomationDependencyContext';
import Predicate from '../../Automation/Function/Computation/Predicate/Predicate';
import getPredicateFromDescriptor from '../../Automation/Api/getPredicateFromDescriptor';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import localizeText from '../../Localization/localizeText';

export default class ConditionalLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref predicate: Predicate;
    @observable.ref layout: Layout;

    // ------------------------ Constructor -------------------------

    constructor(predicate: Predicate,
                layout: Layout)
    {
        super();

        this.predicate = predicate;
        this.layout = layout;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.Conditional', 'Conditionele layout');
    }

    validate(): Validation[]
    {
        return [
            ...this.predicate.validate(),
            ...this.layout.validate()
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.predicate.getDependencies(),
            ...this.layout.getDependencies()
        ];
    }

    toDescriptor()
    {
        return {
            type: 'Conditional',
            predicate: this.predicate.toDescriptor(),
            layout: this.layout.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new ConditionalLayout(
            await getPredicateFromDescriptor(
                descriptor.predicate,
                new AutomationDependencyContext(
                    dependencyContext.parameterDictionary,
                    dependencyContext.deferredInitializer
                )
            ),
            await getLayoutFromDescriptor(
                descriptor.layout,
                dependencyContext
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
