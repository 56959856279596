import { Entity } from '../../../../Model/Implementation/Entity';
import { DataObject } from '../../../../../@Component/Domain/DataObject/Model/DataObject';
import { EntityType } from '../../../../Model/Implementation/EntityType';
import { EntityField } from '../../../../Model/Implementation/EntityField';
import { EntityRelationshipDefinition } from '../../../../Model/Implementation/EntityRelationshipDefinition';
import { CommitContext } from '../CommitContext';
import { EntityScopedCommitBuilder } from './EntityScopedCommitBuilder';
import { updateRelationship } from '../Api/Compatibility/updateRelationship';
import { commitEntityWithContext } from '../Api/Compatibility/commitEntityWithContext';
import { EntityFieldPath } from '../../../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import { CommitOptions } from '../../commitEntity';
import { CommitBuilderResult } from './CommitBuilderResult';
import { CommitMutationOptions } from '../Model/CommitMutationOptions';
import { CommitContextImpl } from '../CommitContextImpl';

export class CommitBuilder
{
    // ------------------------- Properties -------------------------

    context: CommitContext;
    options: CommitMutationOptions;
    entityById: Map<string, Entity>;

    // ------------------------ Constructor -------------------------

    constructor(
        context: CommitContext = new CommitContextImpl(),
        options?: CommitMutationOptions
    )
    {
        this.context = context;
        this.options = options;
        this.entityById = new Map();
    }

    // ----------------------- Initialization -----------------------

    // ------------------------ Public logic ------------------------

    public createEntity(
        entityType: EntityType,
        builderConsumer: (builder: EntityScopedCommitBuilder) => void,
        id?: string
    ): CommitBuilder
    {
        const entity =
            this.context.createEntity(
                entityType,
                this.options
            );

        builderConsumer(
            new EntityScopedCommitBuilder(
                this,
                entity
            )
        );

        if (id !== undefined)
        {
            this.entityById.set(id, entity);
        }

        return this;
    }

    public deleteEntity(
        entity: Entity
    ): CommitBuilder
    {
        this.context.deleteEntity(
            entity,
            this.options
        );

        return this;
    }

    public setValueInEntity(
        entity: Entity,
        field: EntityField,
        value: DataObject
    ): CommitBuilder
    {
        this.context.setValue(
            entity,
            field,
            value
        );

        return this;
    }

    public setObjectValueInEntity(
        entity: Entity,
        field: EntityField,
        value?: any
    ): CommitBuilder
    {
        this.context.setValue(
            entity,
            field,
            DataObject.constructFromValue(
                field.dataObjectSpecification,
                value
            ),
            this.options
        );

        return this;
    }

    public setObjectValueInEntityByFieldPath(
        entity: Entity,
        fieldPath: EntityFieldPath,
        value?: Entity | any,
        fromEntity?: Entity
    ): CommitBuilder
    {
        fieldPath.setValue(
            entity,
            value,
            fromEntity,
            this.context,
            this.options
        );

        return this;
    }

    public relateEntityTo(
        entity: Entity,
        isParent: boolean,
        relationshipDefinition: EntityRelationshipDefinition,
        relatedEntity?: Entity,
        fromEntity?: Entity
    ): CommitBuilder
    {
        updateRelationship(
            entity,
            isParent,
            relationshipDefinition,
            relatedEntity,
            this.context,
            fromEntity,
            undefined,
            undefined,
            this.options
        );

        return this;
    }

    public async commit(options?: Partial<CommitOptions>): Promise<CommitBuilderResult>
    {
        const optionsWithLock = {
            ...options,
            lock: this.options?.lock,
        };

        if (this.context.getFirstUpdatedEntity())
        {
            await commitEntityWithContext(
                this.context.getFirstUpdatedEntity(),
                this.context,
                optionsWithLock
            );
        }
        else
        {
            await this.context.commit(optionsWithLock);
        }

        const result = new CommitBuilderResult(this.entityById);
        this.entityById = new Map();

        return result;
    }

    public ifValid(
        predicate: () => boolean,
        builderConsumer: (builder: CommitBuilder) => void
    ): CommitBuilder
    {
        if (predicate())
        {
            builderConsumer(this);
        }

        return this;
    }

    public getEntity(id: string): Entity | undefined
    {
        return this.entityById.get(id);
    }

    public getEntityOrThrow(id: string): Entity
    {
        const entity = this.getEntity(id);

        if (entity === undefined)
        {
            throw new Error(`Entity with ID: ${id} not found`);
        }
        else
        {
            return entity;
        }
    }

    public static async lock<T>(
        context: CommitContext,
        callback: (builder: CommitBuilder) => T,
        options?: CommitOptions
    )
    {
        let result: T;

        await context.lock(
            async lock =>
            {
                const builder =
                    new CommitBuilder(
                        context,
                        {
                            ...options,
                            lock,
                        }
                    );
                result = await callback(builder);
            }
        );

        return result;
    }

    // ----------------------- Private logic ------------------------
}
