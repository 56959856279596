import { useMemo } from 'react';

export default function useContainsHTML(text: string)
{
    return useMemo(
        () =>
            text
                ? /<\/?[a-z][\s\S]*>/i.test(text)
                : false,
        [
            text
        ]);
}
