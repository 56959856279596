import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import PrimitiveFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/PrimitiveFormInputLayout';
import PrimitiveFormInputLayoutViewer from './PrimitiveFormInputLayoutViewer';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import RightAlignedButtonGroup from '../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import TextEditor from '../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import DataObjectContext from '../../../../DataObject/DataObjectContext';
import search from '../../../../../../@Util/String/search';
import Selectbox from '../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import { DataObjectType as DataType } from '../../../../DataObject/Model/DataObjectType';
import { DataObjectType } from '../../../../../../@Api/Model/Implementation/EntityField';
import { runInAction } from 'mobx';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import uuid from '../../../../../../@Util/Id/uuid';
import PrimitiveValueType from '../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import FormContext from '../../Form/FormContext';
import ParameterAssignment from '../../../../../../@Api/Automation/Parameter/ParameterAssignment';

export interface PrimitiveFormInputLayoutEditorProps extends LayoutEditorProps<PrimitiveFormInputLayout>
{

}

const PrimitiveFormInputLayoutEditor: React.FC<PrimitiveFormInputLayoutEditorProps> =
    props =>
    {
        const form = useContext(FormContext);

        const parameter =
            useComputed<Parameter<PrimitiveValueType>>(
                () =>
                    props.layout.parameterId
                    && props.parameterDictionary.getParameterById(props.layout.parameterId),
                [
                    props.layout,
                    props.parameterDictionary
                ]);

        const [ name, setName ] = useState<string>();
        const [ dataType, setDataType ] = useState<DataType>();

        const dataObjectStore = useContext(DataObjectContext);
        const loader =
            useCallback(
                (query: string) =>
                    Promise.resolve<DataType[]>([
                        ...[
                            DataObjectType.Text,
                            DataObjectType.RichText,
                            DataObjectType.EmailAddress,
                            DataObjectType.TelephoneNumber,
                            DataObjectType.Url,
                            DataObjectType.Number,
                            DataObjectType.Percentage,
                            DataObjectType.Currency,
                            DataObjectType.Boolean,
                            DataObjectType.Date,
                            DataObjectType.DateTime,
                            DataObjectType.File
                        ].map(
                            type =>
                                dataObjectStore.getTypeById(DataObjectType[type]))
                            .filter(
                                type =>
                                    search(query, type.name()))
                    ]),
                [
                    dataObjectStore
                ]);

        const formatter =
            useCallback(
                (type: DataType) =>
                    type.name(),
                []);

        const save =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            if (dataType && name)
                            {
                                const parameter =
                                    new Parameter(
                                        uuid(),
                                        new PrimitiveValueType(dataType),
                                        false,
                                        name);

                                form.parameters.addParameter(parameter);

                                props.layout.parameterId = parameter.id;
                            }
                        }),
                [
                    name,
                    dataType,
                    form,
                    props.layout
                ]);

        const parameterAssignment =
            useMemo(
                () =>
                    props.parameterAssignment || new ParameterAssignment(),

                [
                    props.parameterAssignment
                ]);

        if (parameter)
        {
            return <PrimitiveFormInputLayoutViewer
                {...props}
                parameterAssignment={parameterAssignment}
            />;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <InputGroup>
                        <Input
                            labelPosition="top"
                            label={
                                <LocalizedText
                                    code="Generic.Name"
                                    value="Naam"
                                />
                            }
                        >
                            <TextEditor
                                value={name}
                                onChange={setName}
                            />
                        </Input>
                        <Input
                            labelPosition="top"
                            label={
                                <LocalizedText
                                    code="Generic.Type"
                                    value="Type"
                                />
                            }
                        >
                            <Selectbox
                                load={loader}
                                onChange={setDataType}
                                formatOption={formatter}
                                idResolver={formatter}
                                value={dataType}
                            />
                        </Input>
                    </InputGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <RightAlignedButtonGroup>
                        <SaveButton
                            onClick={save}
                        />
                    </RightAlignedButtonGroup>
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

export default observer(PrimitiveFormInputLayoutEditor);
