import React, { ChangeEvent, useCallback, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { TextField } from '@material-ui/core';
import useStartAdornment from '../Api/useStartAdornment';
import useEndAdornment from '../Api/useEndAdornment';
import styles from './TextEditor.module.scss';
import { InputProps as StandardInputProps } from '@material-ui/core/Input';
import { classNames } from '../../../../Util/Class/classNames';
import InputContentFit from '../InputContentFit/InputContentFit';

export interface TextEditorProps
{
    type?: 'text' | 'email' | 'password' | 'phone' | 'url'
    value?: string;
    onChange?: (value?: string) => void;
    onBlur?:  () => void;
    placeholder?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    maxLength?: number;
    inputProps?: StandardInputProps['inputProps'];
    className?: string;
    disableUnderline?: boolean;
    outlined?: boolean;
    multiline?: boolean;
    error?: boolean;
    fitContent?: boolean;
    inputStyle?: any;
    name?: string;
}

const TextEditor: React.FC<TextEditorProps> =
    props =>
    {
        const { onChange } = props;
        const onCheckedChange =
            useCallback(
                (value?: string) =>
                {
                    if (value !== undefined)
                    {
                        // Disallow new lines
                        value = value.replace(/\r?\n|\r/g, '');
                    }

                    if (onChange)
                    {
                        if (value === '')
                        {
                            onChange(undefined);
                        }
                        else
                        {
                            onChange(value);
                        }
                    }
                },
                [
                    onChange
                ]);

        const startAdornment = useStartAdornment(props.startAdornment);
        const endAdornment = useEndAdornment(props.endAdornment);

        const inputPropsWithoutUsedEvents =
            useMemo(
                () =>
                {
                    const { onChange, ...other } = props.inputProps;

                    return {
                        ...other,
                        maxLength: props.maxLength,
                        style: props.inputStyle
                    };
                },
                [
                    props.maxLength,
                    props.inputProps,
                    props.inputStyle
                ]);

        const inputProps =
            useMemo(
                () =>
                    ({
                        startAdornment: startAdornment,
                        endAdornment: endAdornment,
                        classes: {
                            root: styles.input,
                            underline: props.disableUnderline && styles.disableUnderline
                        }
                    }),
                [
                    startAdornment,
                    endAdornment,
                    props.disableUnderline
                ]);

        const onInputChange =
            useCallback(
                (event: ChangeEvent<HTMLInputElement>) =>
                {
                    onCheckedChange(event.target.value);

                    if (props.inputProps.onChange)
                    {
                        props.inputProps.onChange(event);
                    }
                },
                [
                    onCheckedChange,
                    props.inputProps
                ]);

        const classes =
            useMemo(
                () =>
                    ({
                        root: classNames(props.className, styles.root),

                    }),
                [
                    props.className
                ]);

        // Allow multiline, but we filter out new lines (see onCheckedChange). This way,
        // text will overflow but we will not allow manual newlines.
        // In case of non-text types, do not enable multiline
        const multiline =
            useMemo(
                () =>
                    // This comes with a performance penalty, so temporarily disabled
                    props.multiline,
                    // props.type === 'text',
                [
                    props.multiline
                    // props.type
                ]);

        const inputRef = useRef<any>();

        /* eslint-disable */
        // eslint disabled for 2 inputProps (one with capital letter)
        return <InputContentFit
            fitContent={props.fitContent}
            value={props.value}
            inputStyle={props.inputStyle}
        >
            <TextField
                inputRef={inputRef}
                type={props.type}
                value={props.value || ''}
                inputProps={inputPropsWithoutUsedEvents}
                InputProps={inputProps}
                placeholder={props.placeholder}
                disabled={props.disabled}
                autoFocus={props.autoFocus}
                onChange={onInputChange}
                onBlur={props.onBlur}
                classes={classes}
                multiline={multiline}
                fullWidth
                variant={props.outlined ? 'outlined' : undefined}
                error={props.error}
                name={props.name}
            />
        </InputContentFit>;
        /* eslint-enable */
    };

TextEditor.defaultProps = {
    type: 'text',
    inputProps: {}
};

export default observer(TextEditor);
