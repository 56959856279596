import React from 'react';
import styles from './StatLabel.module.scss';
import { classNames } from '../../../Util/Class/classNames';


export interface StatLabelClasses
{
    name?: string;
    value?: string;
    count?: string;
}

export interface StatLabelProps
{
    name: string;
    nameColor?: string;
    value: string;
    valueColor?: string;
    countValue?: string;
    countColor?: string;
    classes?: StatLabelClasses;
}

const StatLabel: React.FC<StatLabelProps> =
    props =>
    {
        return <div className={styles.root}>
            <div className={styles.column1}>
                <div
                    className={
                        classNames(
                            styles.name,
                            props.classes && props.classes.name)}
                    style={{
                        color: props.nameColor
                    }}
                >
                    {props.name}
                </div>
                <span
                    className={
                        classNames(
                            styles.value,
                            props.classes && props.classes.value)}
                    style={{
                        color: props.valueColor,
                    }}
                >
                    {props.value}
                </span>
            </div>
            <div
                className={
                    classNames(
                        styles.countValue,
                        props.classes && props.classes.count)}
                style={{
                    color: props.countColor
                }}
            >
                {props.countValue}
            </div>
        </div>;
    };

export default StatLabel;
