import { DragStart, DropResult } from 'react-beautiful-dnd';
import { action, computed, observable } from 'mobx';
import { Droppable } from './Model/DragAndDropListener';
import { DragAndDropAction } from './Model/DragAndDropAction';
import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { DragAndDropBucketStore } from './Bar/Bucket/DragAndDropBucketStore';
import { DragEndListener } from '../../Domain/DragAndDrop/Api/DragEndListener';
import { DragStartListener } from '../../Domain/DragAndDrop/Api/DragStartListener';

export class DragAndDropStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable dragStartListeners = observable.array<DragStartListener>();
    @observable dragEndListeners = observable.array<DragEndListener>();
    @observable droppables: Array<Droppable<any>>;
    @observable currentAction: DragAndDropAction<any>;

    // ------------------------ Constructor -------------------------

    constructor(sources: Array<Droppable<any>> = [])
    {
        super();

        this.droppables = sources;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get isVisible(): boolean
    {
        return this.bucketStores.length > 0;

        // return this.bucketStores.some(store => (store.isEnabled));
    }

    @computed
    get isDragging(): boolean
    {
        return this.currentAction != null;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get activeBucketStores(): Array<DragAndDropBucketStore<any>>
    {
        return this.bucketStores
            .filter(bucketStore => (bucketStore.isActive));
    }

    @computed
    get bucketStores(): Array<DragAndDropBucketStore<any>>
    {
        // if (this.currentAction)
        // {
        //     return this.currentAction.source.droppableBuckets();
        // }
        // else
        // {
        //     return [];
        // }

        let droppableBuckets: Array<DragAndDropBucketStore<any>> = [];
        let droppableBucketIds: string[] = [];

        this.droppables.forEach(
            droppable =>
            {
                droppable.droppableBuckets()
                    .forEach(bucket =>
                    {
                        if (droppableBucketIds.indexOf(bucket.id) < 0)
                        {
                            droppableBucketIds.push(bucket.id);
                            droppableBuckets.push(bucket);
                        }
                    });
            });

        return droppableBuckets;
    }

    // -------------------------- Actions ---------------------------

    @action
    listen(source: Droppable<any>)
    {
        this.droppables.push(source);
    }

    @action
    unlisten(source: Droppable<any>)
    {
        this.droppables.splice(
            this.droppables.indexOf(source),
            1);
    }

    @action.bound
    listenToDragStart(listener: DragStartListener)
    {
        this.dragStartListeners.push(listener);
    }

    @action.bound
    unlistenToDragStart(listener: DragStartListener)
    {
        this.dragStartListeners.remove(listener);
    }

    @action.bound
    listenToDragEnd(listener: DragEndListener)
    {
        this.dragEndListeners.push(listener);
    }

    @action.bound
    unlistenToDragEnd(listener: DragEndListener)
    {
        this.dragEndListeners.remove(listener);
    }

    @action.bound
    onBeforeDragStart(start: DragStart)
    {
        this.dragStartListeners.forEach(
            listener =>
                listener(start));

        let source = this.droppables.find(droppable => (droppable.droppableId() === start.source.droppableId));
        let sourceIndex = start.source.index;

        if (source)
        {
            let item = source.droppableItem(start.draggableId);

            this.startDragAction(
                new DragAndDropAction(
                    source,
                    sourceIndex,
                    item));
        }
    }

    @action.bound
    onDragStart(start: DragStart)
    {
        // let source = this.droppables.find(droppable => (droppable.droppableId() === start.source.droppableId));
        // let sourceIndex = start.source.index;
        //
        // if (source)
        // {
        //     let item = source.droppableItem(start.draggableId);
        //
        //     this.startDragAction(
        //         new DragAndDropAction(
        //             source,
        //             sourceIndex,
        //             item));
        // }
    }

    @action.bound
    onDragEnd(result: DropResult)
    {
        this.dragEndListeners.forEach(
            listener =>
                listener(result));

        this.endCurrentAction();

        if (!result.destination)
        {
            return;
        }

        let source = this.droppables.find(droppable => (droppable.droppableId() === result.source.droppableId));
        let sourceIdx = result.source.index;

        if (!source)
        {
            return;
        }

        let item = source.droppableItem(result.draggableId);

        let destination = this.droppables.find(droppable => (droppable.droppableId() === result.destination.droppableId));
        let destinationIdx = result.destination.index;

        // Sometimes the destination idx is -1, correct this to 0
        sourceIdx = Math.max(sourceIdx, 0);
        destinationIdx = Math.max(destinationIdx, 0);

        if (!destination)
        {
            return;
        }
        else
        {
            if (source === destination)
            {
                destination.droppableItemMoved(
                    item,
                    sourceIdx,
                    destinationIdx,
                    source,
                    () =>
                    {
                        destination.droppableItemMoved(
                            item,
                            destinationIdx,
                            sourceIdx,
                            source,
                            () =>
                            {
                                throw new Error('Cannot rollback a rollback.');
                            });
                    });
            }
            else
            {
                let rollback =
                    () =>
                    {
                        destination.droppableItemRemoved(
                            item,
                            destinationIdx,
                            destination,
                            Promise.resolve(),
                            () =>
                            {
                                throw new Error('Cannot rollback a rollback.');
                            });

                        source.droppableItemAdded(
                            item,
                            sourceIdx,
                            destination,
                            () =>
                            {
                                throw new Error('Cannot rollback a rollback.');
                            });
                    };

                let onAdd =
                    destination.droppableItemAdded(
                        item,
                        destinationIdx,
                        source,
                        rollback);

                source.droppableItemRemoved(
                    item,
                    sourceIdx,
                    destination,
                    onAdd,
                    rollback);
            }
        }
    }

    @action.bound
    startDragAction(action: DragAndDropAction<any>)
    {
        this.currentAction = action;
    }

    @action.bound
    onDropInBucket(onAdd: Promise<any> = Promise.resolve()): () => void
    {
        const currentAction = this.currentAction;

        if (currentAction)
        {
            this.endCurrentAction();

            let rollback =
                () =>
                {
                    currentAction.source.droppableItemAdded(
                        currentAction.item,
                        currentAction.sourceIndex,
                        currentAction.source,
                        () =>
                        {
                            throw new Error('Cannot rollback a rollback.');
                        });
                };

            currentAction.source.droppableItemRemoved(
                currentAction.item,
                currentAction.sourceIndex,
                null,
                onAdd,
                rollback);

            return rollback;
        }
        else
        {
            return null;
        }
    }

    @action
    endCurrentAction()
    {
        this.currentAction = null;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
