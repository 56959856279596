import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import ExtractFirstNameSourceFieldMapping from '../Model/FieldMapping/SourceField/ExtractFirstNameSourceFieldMapping';
import ExtractMiddleNameSourceFieldMapping from '../Model/FieldMapping/SourceField/ExtractMiddleNameSourceFieldMapping';
import ExtractLastNameSourceFieldMapping from '../Model/FieldMapping/SourceField/ExtractLastNameSourceFieldMapping';
import ExtractStreetNameSourceFieldMapping from '../Model/FieldMapping/SourceField/ExtractStreetNameSourceFieldMapping';
import ExtractHouseNumberSourceFieldMapping from '../Model/FieldMapping/SourceField/ExtractHouseNumberSourceFieldMapping';
import ExtractHouseNumberSuffixSourceFieldMapping from '../Model/FieldMapping/SourceField/ExtractHouseNumberSuffixSourceFieldMapping';

interface SpecificSourceFieldType
{
    id: string;
    type: string;
    label: string;
    constructor: any;
}

export default function getFieldSpecificSourceFieldMappingTypes(fieldPath: EntityFieldPath): SpecificSourceFieldType[]
{
    const types = loadModuleDirectly(EntityTypeStore).bespoke.types;

    if (fieldPath.field === types.Relation.Person.Field.FirstName)
    {
        return [
            {
                id: 'ExtractFirstName',
                type: 'SourceField.ExtractFirstName',
                label: 'Voornaam uit volledige naam halen',
                constructor: ExtractFirstNameSourceFieldMapping
            }
        ];
    }
    else if (fieldPath.field === types.Relation.Person.Field.MiddleName)
    {
        return [
            {
                id: 'ExtractMiddleName',
                type: 'SourceField.ExtractMiddleName',
                label: 'Tussenvoegsel uit volledige naam halen',
                constructor: ExtractMiddleNameSourceFieldMapping
            }
        ];
    }
    else if (fieldPath.field === types.Relation.Person.Field.LastName)
    {
        return [
            {
                id: 'ExtractLastName',
                type: 'SourceField.ExtractLastName',
                label: 'Achternaam uit volledige naam halen',
                constructor: ExtractLastNameSourceFieldMapping
            }
        ];
    }
    else if (fieldPath.field === types.Address.Field.Street)
    {
        return [
            {
                id: 'ExtractStreetName',
                type: 'SourceField.ExtractStreetName',
                label: 'Straatnaam uit adres halen',
                constructor: ExtractStreetNameSourceFieldMapping
            }
        ];
    }
    else if (fieldPath.field === types.Address.Field.HouseNr)
    {
        return [
            {
                id: 'ExtractHouseNumber',
                type: 'SourceField.ExtractHouseNumber',
                label: 'Huisnummer uit adres halen',
                constructor: ExtractHouseNumberSourceFieldMapping
            }
        ];
    }
    else if (fieldPath.field === types.Address.Field.HouseNrSuffix)
    {
        return [
            {
                id: 'ExtractHouseNumberSuffix',
                type: 'SourceField.ExtractHouseNumberSuffix',
                label: 'Toevoeging uit adres halen',
                constructor: ExtractHouseNumberSuffixSourceFieldMapping
            }
        ];
    }
    else
    {
        return [];
    }
}
