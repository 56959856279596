import React, { useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { default as ImportSummaryModel } from '../Model/ImportSummary';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import CircularPercentageChart from '../../../../../@Future/Component/Generic/Chart/CircularPercentageChart';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import useTypes from '../../Type/Api/useTypes';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Mapping from '../Model/Mapping';
import TabBar from '../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { Comparator } from '../../../DataObject/Model/Comparator';
import List from '../../View/List/List';
import getSystemDefaultView from '../../View/Api/getSystemDefaultView';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import EntityTypeContext from '../../Type/EntityTypeContext';
import isHiddenInImportResult from '../Api/isHiddenInImportResult';
import TypeMappingSummary from '../Model/TypeMappingSummary';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import IndicesLink from './IndicesLink/IndicesLink';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import getViewParameters from '../../View/Api/getViewParameters';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../View/Model/ViewParams';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import { LogViewer } from '../../../LogViewer/LogViewer';
import CurrentUserContext from '../../../User/CurrentUserContext';

export interface ImportSummaryProps
{
    importEntity: Entity;
    importPhase: string;
    mapping: Mapping;
    onSelect: (idx: number) => void;
    summary?: ImportSummaryModel;
}

const ImportSummary: React.FC<ImportSummaryProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);
        const currentUserStore = useContext(CurrentUserContext);

        const allTypeMappings =
            useComputed(
                () =>
                    props.mapping.getAllTypeMappings(),
                [
                    props.mapping
                ]);

        const result =
            useComputed(
                () =>
                    props.summary,
                [
                    props.summary
                ]);

        const [ selectedType, setSelectedType ] = useState<EntityType | number>(0);

        const viewParameters =
            useMemo(
                () =>
                    selectedType instanceof EntityType &&
                        getViewParameters(selectedType),
                [
                    selectedType
                ]);

        const view =
            useMemo(
                () =>
                    selectedType instanceof EntityType &&
                        getSystemDefaultView(
                            selectedType,
                            viewParameters,
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    viewParameters.getParameterById(ViewParams.Entity),
                                    EntityPath.fromEntityType(selectedType)
                                        .joinTo(
                                            types.Import.RelationshipDefinition.Entities,
                                            true)
                                        .field()),
                                Comparator.Equals,
                                new EntityValue(props.importEntity))),
                [
                    selectedType,
                    viewParameters,
                    types,
                    props.importEntity
                ]);

        const aggregatedSummariesByType =
            useMemo(
                () =>
                    (result ? allTypeMappings : [])
                        .filter(
                            typeMapping =>
                                result.summaryByTypeMappingId.has(typeMapping.id))
                        .map(
                            typeMapping =>
                                Array.from(result.summaryByTypeMappingId.get(typeMapping.id).summaryByTypeId?.entries() ?? [])
                                    .map(
                                        ([ typeIdAsStr, summary ]) => [
                                            entityTypeStore.getTypeById(parseInt(typeIdAsStr)),
                                            summary
                                        ] as [ EntityType, TypeMappingSummary ])
                                    .filter(
                                        ([ entityType ]) =>
                                            entityType &&
                                            !isHiddenInImportResult(entityType)))
                        .reduce((a, b) => a.concat(b), []),
                [
                    result,
                    allTypeMappings,
                    entityTypeStore
                ]);

        if (result)
        {
            return <Card>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <TabBar
                            value={selectedType}
                        >
                            <Tab
                                value={0}
                                onClick={setSelectedType}
                            >
                                <LocalizedText
                                    code="Import.Summary"
                                    value="Samenvatting"
                                />
                            </Tab>
                            {
                                (props.importPhase === 'Running' || props.importPhase === 'Finished' || props.importPhase === 'PartiallyFinished' || props.importPhase === 'RollingBack') &&
                                aggregatedSummariesByType
                                    .map(
                                        ([ entityType, summary ]) =>
                                            <Tab
                                                key={entityType.id}
                                                value={entityType}
                                                onClick={setSelectedType}
                                            >
                                                {entityType.getName(true)} ({summary.newCount})
                                            </Tab>)
                            }
                            {
                                currentUserStore.isSupport && props.importPhase !== 'Concept' &&
                                    <Tab
                                        value={-1}
                                        onClick={setSelectedType}
                                    >
                                        <LocalizedText
                                            code="Generic.Logs"
                                            value="Logs"
                                        />
                                    </Tab>
                            }
                        </TabBar>
                    </ViewGroupItem>
                    {
                        selectedType === 0 &&
                            <CardInset>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={30}
                                >
                                    <ViewGroupItem>
                                        <CardInset>
                                            <ViewGroup
                                                orientation="vertical"
                                                spacing={10}
                                            >
                                                <ViewGroupItem>
                                                    <Centered
                                                        horizontal
                                                    >
                                                        <CircularPercentageChart
                                                            value={((props.importEntity.getObjectValueByField(types.Import.Field.NumberOfProcessedRecords) || 0) / props.importEntity.getObjectValueByField(types.Import.Field.NumberOfRecords) * 100)}
                                                            size={130}
                                                            label={value => `${Math.round(value)}%`}
                                                            subLabel={
                                                                () =>
                                                                    <LocalizedText
                                                                        code="Import.Summary.Progress"
                                                                        value="Voortgang"
                                                                    />
                                                            }
                                                        />
                                                    </Centered>
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <ViewGroup
                                                        orientation="vertical"
                                                        spacing={5}
                                                    >
                                                        {
                                                            props.importEntity.hasValueForField(types.Import.Field.StartDate) &&
                                                                <ViewGroupItem>
                                                                    <Centered
                                                                        horizontal
                                                                    >
                                                                        <LocalizedText
                                                                            code="Import.Summary.StartedOn"
                                                                            value="Gestart op ${date}"
                                                                            date={props.importEntity.getDataObjectValueByField(types.Import.Field.StartDate).toString()}
                                                                        />
                                                                    </Centered>
                                                                </ViewGroupItem>
                                                        }
                                                        {
                                                            props.importEntity.hasValueForField(types.Import.Field.EndDate) &&
                                                                <ViewGroupItem>
                                                                    <Centered
                                                                        horizontal
                                                                    >
                                                                        <LocalizedText
                                                                            code="Import.Summary.EndedOn"
                                                                            value="Geëindigt op ${date}"
                                                                            date={props.importEntity.getDataObjectValueByField(types.Import.Field.EndDate).toString()}
                                                                        />
                                                                    </Centered>
                                                                </ViewGroupItem>
                                                        }
                                                        {
                                                            props.importEntity.hasValueForField(types.Import.Field.RollbackDate) &&
                                                                <ViewGroupItem>
                                                                    <Centered
                                                                        horizontal
                                                                    >
                                                                        <LocalizedText
                                                                            code="Import.Summary.RolledBackOn"
                                                                            value="Teruggedraaid op ${date}"
                                                                            date={props.importEntity.getDataObjectValueByField(types.Import.Field.RollbackDate).toString()}
                                                                        />
                                                                    </Centered>
                                                                </ViewGroupItem>
                                                        }
                                                        <ViewGroupItem>
                                                            <Centered
                                                                horizontal
                                                            >
                                                                {
                                                                    props.importPhase === 'Testing' || props.importPhase === 'Tested'
                                                                    ?
                                                                        <LocalizedText
                                                                            code="Import.Summary.ProcessedRecordsTested"
                                                                            value="${numberOfProcessedRecords} / ${numberOfRecords} regels getest."
                                                                            numberOfProcessedRecords={props.importEntity.getObjectValueByField(types.Import.Field.NumberOfProcessedRecords)}
                                                                            numberOfRecords={props.importEntity.getObjectValueByField(types.Import.Field.NumberOfRecords)}
                                                                        />
                                                                    :
                                                                        <LocalizedText
                                                                            code="Import.Summary.ProcessedRecordsImported"
                                                                            value="${numberOfProcessedRecords} / ${numberOfRecords} regels geïmporteerd."
                                                                            numberOfProcessedRecords={props.importEntity.getObjectValueByField(types.Import.Field.NumberOfProcessedRecords)}
                                                                            numberOfRecords={props.importEntity.getObjectValueByField(types.Import.Field.NumberOfRecords)}
                                                                        />
                                                                }

                                                            </Centered>
                                                        </ViewGroupItem>
                                                    </ViewGroup>
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </CardInset>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <LocalizedText
                                                            code="Import.Summary.Type"
                                                            value="Type"
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <LocalizedText
                                                            code="Import.Summary.NewCount"
                                                            value="Aantal nieuwe"
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <LocalizedText
                                                            code="Import.Summary.ExistingCount"
                                                            value="Aantal bestaande"
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <LocalizedText
                                                            code="Import.Summary.ErrorCount"
                                                            value="Aantal foutief"
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    aggregatedSummariesByType
                                                        .map(
                                                            ([ entityType, summary ]) =>
                                                                <TableRow
                                                                    key={entityType.id}
                                                                >
                                                                    <TableCell>
                                                                        {entityType.getName(true)}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <IndicesLink
                                                                            count={summary.newCount}
                                                                            indices={summary.newIndices}
                                                                            onSelect={props.onSelect}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <IndicesLink
                                                                            count={summary.existentCount}
                                                                            indices={summary.existentIndices}
                                                                            onSelect={props.onSelect}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <IndicesLink
                                                                            count={summary.errorCount}
                                                                            indices={summary.errorIndices}
                                                                            errorSourceInstanceIds={summary.errorSourceInstanceIds}
                                                                            onSelect={props.onSelect}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>)
                                                }
                                            </TableBody>
                                        </Table>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </CardInset>
                    }
                    {
                        view &&
                            <ViewGroupItem>
                                <List
                                    view={view}
                                />
                            </ViewGroupItem>
                    }
                    {
                        selectedType === -1 &&
                            <ViewGroupItem>
                                <LogViewer
                                    name={`imports/${props.importEntity.uuid}`}
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </Card>;
        }
        else
        {
            return null;
        }
    };

export default observer(ImportSummary);
