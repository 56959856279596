import { reference, registerType, type } from '../../../@Util/Serialization/Serialization';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { DataDescriptor } from '../../../@Component/Domain/DataObject/Model/DataDescriptor';
import { DataObject } from '../../../@Component/Domain/DataObject/Model/DataObject';
import { action, computed, observable } from 'mobx';
import { Entity } from './Entity';
import { EntityField } from './EntityField';
import { StaticEntityField } from './StaticEntityField';
import { registerBuilder } from '../../../@Util/TransactionalModelV2/Shared/TransactionalBuilder';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';

@type('EntityValue')
export class EntityValue
{
    // ------------------- Persistent Properties --------------------

    @observable id: number;
    @reference(undefined, 'Entity') @observable.ref entity: Entity;
    @reference(undefined, 'EntityField') @observable.ref field: EntityField;
    @observable isReference: boolean;
    @observable.ref value: any;

    // ------------------------- Properties -------------------------

    @observable.ref dataObject: DataObject;

    // ------------------------ Constructor -------------------------

    constructor(id?: number,
                entity?: Entity,
                field?: EntityField,
                dataObject?: DataObject)
    {
        this.id = id;
        this.entity = entity;
        this.field = field;
        this._setDataObject(dataObject);
    }

    // ----------------------- Initialization -----------------------

    initialize(entity: Entity,
               entityTypeStore: EntityTypeStore)
    {
        this.entity = entity;

        if (this.field == null)
        {
            // console.warn('Field is empty in ', entity.entityType.code);
        }
        else
        {
            this.field =
                entityTypeStore.getFieldByIdOrCode(
                    this.field.id,
                    this.field.code);

            this.initializeDataObject();
        }

        return this.field !== undefined;
    }

    initializeDataObject()
    {
        if (this.field)
        {
            const dataDescriptor =
                this.value != null
                    ?
                        DataDescriptor.construct(this.value, this.field.dataObjectSpecification)
                    :
                        new DataDescriptor();

            const entityTypeStore = loadModuleDirectly(EntityTypeStore);

            // If data object is not set yet (e.g. it is already set when .getReference is called on entity)
            if (this.dataObject == null)
            {
                this.setDataObject(
                    new DataObject(
                        this.field.dataObjectSpecification,
                        dataDescriptor,
                        {
                            entityContext: this.entity.entityContext,
                            getFileUrl: () => entityTypeStore.getFileUrl(this)
                        }));
            }

            if (this.field.defaultValueComputation)
            {
                if (this.dataObject.isEmpty)
                {
                    const defaultValue =
                        entityTypeStore.computationTypeStore
                            .computeFromSpecification(
                                {
                                    entityContext: this.entity.entityContext
                                },
                                this.field.defaultValueComputation);

                    if (defaultValue && !defaultValue.isEmpty)
                    {
                        this.dataObject.setValue(defaultValue.value);
                    }
                }
            }
        }
    }

    // -------------------------- Computed --------------------------

    // get dataObject(): DataObject
    // {
    //     if (this._dataObject)
    //     {
    //         return this._dataObject;
    //     }
    //     else
    //     {
    //         this.initializeDataObject();
    //
    //         return this._dataObject;
    //     }
    // }

    get isNew(): boolean
    {
        return this.id === undefined;
    }

    @computed
    get isEmpty()
    {
        return !this.dataObject || this.dataObject.isEmpty;
    }

    @computed
    get isValid(): boolean
    {
        if (this.field.isRequired)
        {
            if (this.dataObject.specification.type.hasSemanticValueWhenEmpty())
            {
                return true;
            }
            else
            {
                return !this.isEmpty && this.dataObject.isValid;
            }
        }
        else
        {
            return this.isEmpty || (this.dataObject && this.dataObject.isValid);
        }
    }

    // -------------------------- Actions ---------------------------

    @action
    setDataObject(dataObject: DataObject)
    {
        this._setDataObject(dataObject);
    }

    @action
    setValue(value: any)
    {
        this.entity.setManaged(true);
        this.dataObject.setValue(value);
    }

    // ------------------------ Public logic ------------------------

    isEditable(entityTypeStore: EntityTypeStore): boolean
    {
        if (this.field === entityTypeStore.typeField)
        {
            return this.entity.entityType.isSwitchableByInheritance()
                || !this.entity.entityType.isInstantiableByInheritance();
        }
        else
        {
            return this.field.computation == null
                && !this.field.isReadonly
                && (!this.field.isStaticField() || (this.field as StaticEntityField).isModifiable);
        }
    }

    descriptor(onFile?: (fileId: string, file: File) => void): any
    {
        return {
            id: this.id,
            fieldId: this.field.isStaticField() ? undefined : this.field.id,
            fieldCode: this.field.isStaticField() ? this.field.code : undefined,
            value: this.dataObject && this.dataObject.data ? this.dataObject.data.descriptor(onFile) : undefined
        };
    }

    // ----------------------- Private logic ------------------------

    _setDataObject(dataObject: DataObject)
    {
        if (dataObject)
        {
            this.dataObject = dataObject;
            (this.dataObject as any).originalEntityValue = this;
        }
        else
        {
            this.dataObject = undefined;
            this.value = undefined;
        }
    }
}

registerBuilder(EntityValue)
    .id(
        value =>
            `${value.entity.uuid}:${value.field.isStaticField() ? value.field.code : value.field.id}`
    )
    .includeAll()
    .deep('entity')
    .deep('dataObject');

registerType(DataDescriptor, 'DataDescriptor');
