import * as React from 'react';
import { PredicateType } from '../PredicateType';
import { ComparisonPredicateEditorStore } from './ComparisonPredicateEditorStore';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { ComparisonPredicateEditor } from './ComparisonPredicateEditor';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { ComparisonPredicateSpecification } from './ComparisonPredicateSpecification';
import { PredicateContext } from '../../PredicateContext';
import { ComputationTypeStore } from '../../../Computation/ComputationTypeStore';
import { Comparator } from '../../../DataObject/Model/Comparator';
import { ComputationEditorStore } from '../../../Computation/ComputationEditorStore';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';
import { EntityContext } from '../../../Entity/@Model/EntityContext';
import { OldComparisonPredicate } from './OldComparisonPredicate';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';

export class ComparisonPredicateType extends PredicateType<ComparisonPredicateEditorStore, ComparisonPredicateSpecification, OldComparisonPredicate>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return 'Comparison';
    }

    name(): string
    {
        return this.localizationStore.translate('ComparisonPredicateType.Name'); // Comparison
    }

    allow(context: PredicateContext): boolean
    {
        return true;
    }

    isTerminal(): boolean
    {
        return true;
    }

    fromSpecification(specification: ComparisonPredicateSpecification): OldComparisonPredicate
    {
        return new OldComparisonPredicate(
            specification.lhs
                ?
                    this.computationTypeStore.fromSpecification(specification.lhs)
                :
                    undefined,
            (Comparator as any)[specification.comparator],
            specification.rhs
                ?
                    this.computationTypeStore.fromSpecification(specification.rhs)
                :
                    undefined);
    }

    toSpecification(predicate: OldComparisonPredicate): ComparisonPredicateSpecification
    {
        return {
            type: this.id(),
            lhs:
                predicate.lhs
                    ?
                        this.computationTypeStore.toSpecification(predicate.lhs)
                    :
                        undefined,
            comparator:
                Comparator[predicate.comparator],
            rhs:
                predicate.rhs
                    ?
                        this.computationTypeStore.toSpecification(predicate.rhs)
                    :
                        undefined
        };
    }

    evaluate(context: PredicateContext,
             predicate: OldComparisonPredicate): boolean
    {
        let lhsResult: DataObject;
        let rhsResult: DataObject;

        if (predicate.lhs)
        {
            lhsResult =
                predicate.lhs.compute(context);
        }

        if (predicate.rhs)
        {
            rhsResult =
                predicate.rhs.compute(context);
        }

        return DataObject.compare(
            lhsResult,
            rhsResult,
            predicate.comparator);
    }

    editorStore(context: PredicateContext,
                specification: ComparisonPredicateSpecification): ComparisonPredicateEditorStore
    {
        return new ComparisonPredicateEditorStore(
            this as any,
            context,
            specification,
            specification.lhs ?
                ComputationEditorStore.construct(
                context,
                specification.lhs,
                this.computationTypeStore)
                : undefined,
            (Comparator as any)[specification.comparator || 'Equals'],
            specification.rhs ?
                ComputationEditorStore.construct(
                context,
                specification.rhs,
                this.computationTypeStore)
                : undefined);
    }

    editorView(): React.ComponentClass<BaseComponentProps<ComparisonPredicateEditorStore>>
    {
        return ComparisonPredicateEditor;
    }

    editorSpecification(store: ComparisonPredicateEditorStore): ComparisonPredicateSpecification
    {
        return {
            type: this.id(),
            lhs: store.lhs ? store.lhs.type.editorSpecification(store.lhs.editorStore) : undefined,
            comparator: Comparator[store.comparator],
            rhs: store.rhs ? store.rhs.type.editorSpecification(store.rhs.editorStore) : undefined
        };
    }

    entityFieldPaths(specification: ComparisonPredicateSpecification,
                     context: EntityContext,
                     parameter?: string): EntityFieldPath[]
    {
        let paths: EntityFieldPath[] = [];

        if (specification.lhs)
        {
            let lhsType = this.computationTypeStore.getTypeById(specification.lhs.type);

            if (lhsType)
            {
                paths.push(
                    ...lhsType.entityFieldPaths(
                        specification.lhs,
                        context,
                        parameter));
            }
        }

        if (specification.rhs)
        {
            let rhsType = this.computationTypeStore.getTypeById(specification.rhs.type);

            if (rhsType)
            {
                paths.push(
                    ...rhsType.entityFieldPaths(
                        specification.rhs,
                        context,
                        parameter));
            }
        }

        return paths;
    }

    // ----------------------- Private logic ------------------------
}
