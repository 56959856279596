import React, { useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid, makeStyles } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import Fields from '../../../Fields/Fields';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import TabBar from '../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import useTypes from '../../../Type/Api/useTypes';
import { ProductCurrencyPrices } from './Multicurrency/ProductCurrencyPrices';
import ProductPriceList from './ProductPriceList';
import { isMultiCurrency } from '../../../../Configuration/Page/Settings/OrganizationSettings/CurrencySettings/Api/isMultiCurrency';

const useStyles = makeStyles({
    tab: {
        paddingLeft: '0px',
    },
});

export interface ProductProps
{
    entity: Entity;
}

const Product: React.FC<ProductProps> =
    ({
        entity,
     }) =>
    {
        const classes = useStyles();
        const types = useTypes();
        const _isMultiCurrency = isMultiCurrency(true);
        const [ tab, setTab ] = useState(_isMultiCurrency ? 0 : 1);

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset
                                    bottom={false}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <TypeAndName
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                                <Fields
                                    entity={entity}
                                />
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={0}
                                >
                                    <ViewGroupItem>
                                        <ViewGroup
                                            orientation="horizontal"
                                            spacing={0}
                                        >
                                            <ViewGroupItem
                                                ratio={1}
                                            >
                                                <TabBar
                                                    value={tab}
                                                >
                                                    {
                                                        _isMultiCurrency &&
                                                        <Tab
                                                            value={0}
                                                            onClick={setTab}
                                                            className={classes.tab}
                                                        >
                                                            <LocalizedText
                                                                code="Product.SalesPrice.ForeignCurrencies"
                                                                value="Verkoopprijs in buitenlandse valuta's"
                                                            />
                                                        </Tab>
                                                    }
                                                    <Tab
                                                        value={1}
                                                        onClick={setTab}
                                                        className={classes.tab}
                                                    >
                                                        {types.PriceList.Type.getName(true)}
                                                    </Tab>
                                                </TabBar>
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <CardInset>
                                            {
                                                tab === 0 &&
                                                <ProductCurrencyPrices
                                                    product={entity}
                                                />
                                            }
                                            {
                                                tab === 1 &&
                                                <ProductPriceList
                                                    product={entity}
                                                />
                                            }
                                        </CardInset>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseLayout>
    };

export default observer(Product);
