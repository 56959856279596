import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { RouterStore } from '../../../../@Service/Router/RouterStore';

export default function openMarketplace(
    category?: string,
    connectorId?: string,
    isVirtual?: boolean
)
{
    const routerStore = loadModuleDirectly(RouterStore);
    const subUrl = getSubUrl(category, connectorId);

    if (isVirtual)
    {
        routerStore.routeVirtually(`/marketplace${subUrl}`)
    }
    else
    {
        routerStore.route(`/marketplace${subUrl}`);
    }
}

function getSubUrl(
    categoryCode?: string,
    connectorCode?: string
)
{
    if (categoryCode)
    {
        return connectorCode !== undefined
            ? `/${categoryCode}/${connectorCode}`
            : `/${categoryCode}`
    }
    else
    {
        return '';
    }
}