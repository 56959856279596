import React, { useCallback } from 'react';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { LabelButtonProps } from '../Label/LabelButton';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface SelectButtonProps extends Partial<LabelButtonProps>
{

}

const SelectButton: React.FC<SelectButtonProps> =
    props =>
    {
        const { onClick } = props;

        const onClickCallback =
            useCallback(
                () =>
                {
                    if (onClick)
                    {
                        return onClick();
                    }
                    else
                    {
                        return Promise.resolve();
                    }
                },
                [
                    onClick
                ]);

        return <PrimaryButton
            label={
                <LocalizedText
                    code="Generic.Select"
                />
            }
            onClick={onClickCallback}
            {...props}
        />;
    };

export default SelectButton;
