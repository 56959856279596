import React from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import Switch from '../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import { Field } from '../../Model/Field';
import { runInAction } from 'mobx';

export interface FieldItemProps
{
    entityType: EntityType;
    field: Field;
}

const IsVisibleDuringConstructionEditor: React.FC<FieldItemProps> =
    props =>
    {
        return <Switch
            checked={props.field.isVisibleDuringConstruction}
            onToggle={
                () =>
                    runInAction(
                        () =>
                            props.field.isVisibleDuringConstruction = !props.field.isVisibleDuringConstruction
                    )
            }
            disabled={!props.field.isEditable}
        />;
    };

export default observer(IsVisibleDuringConstructionEditor);
