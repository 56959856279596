import React, { useMemo } from 'react';
import { BlockEditProps } from '@wordpress/blocks';
import SampleColors from './SampleColors';
import { PanelColorSettings } from '@wordpress/block-editor';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export interface ButtonColorOptionsPanelProps
{
    props: BlockEditProps<{}>;
    open?: boolean;
    disableBorderColor?: boolean;
    disableFontColor?: boolean;
}

const ButtonColorOptionsPanel: React.FC<ButtonColorOptionsPanelProps> =
    ({
        props,
     }) =>
    {
        const { setAttributes } = props;
        const {
            color,
            background
        } = props.attributes as any;

        const colorSettings =
            useMemo(
                () =>
                    [
                        {
                            colors: SampleColors,
                            value: background,
                            label: localizeText('Gutenberg.ColorOptions.Background', 'Achtergrond'), // Background Color
                            onChange: background => setAttributes({ background: background }),
                        },
                        {
                            colors: SampleColors,
                            value: color,
                            label: localizeText('Gutenberg.ColorOptions.Font', 'Letters'), // Font Color
                            onChange: color => setAttributes({color: color}),
                        }
                    ],
                [
                    color,
                    background,
                    setAttributes
                ]
            );

        return <PanelColorSettings
            title={
                localizeText(
                    'Gutenberg.ColorOptions.Title',
                    'Kleuren'
                )
            }
            initialOpen={!!props.open}
            colorSettings={colorSettings}
        />;
    };

export default ButtonColorOptionsPanel;
