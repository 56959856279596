
export function replaceAll(
    source: string,
    regExString: string,
    replace: string
)
{
    return source
        .replace(
            new RegExp(regExString, 'g'),
            replace
        );
}