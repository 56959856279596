import { BaseStore } from '../../../../@Framework/Store/BaseStore';
import { action, computed, observable, toJS } from 'mobx';
import { ApiResponse } from '../../../../@Service/ApiClient/Model/ApiResponse';
import { ApiRequest, Method } from '../../../../@Service/ApiClient/Model/ApiRequest';
import { DialogStore } from '../../../Generic/Dialog/Type/DialogStore';
import { ViewComponent } from '../../../Generic/ViewStack/Model/ViewComponent';
import Text from '../../../Generic/Text/Text';
import { TextStore } from '../../../Generic/Text/TextStore';
import { ButtonStore } from '../../../Generic/Button/ButtonStore';
import { JsonEditor } from '../../../Generic/JsonEditor/JsonEditor';
import { JsonEditorStore } from '../../../Generic/JsonEditor/JsonEditorStore';
import { viewInset } from '../../../../@Resource/Theme/Theme';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { FeedbackStore } from '../Environment/Organization/Feedback/FeedbackStore';
import localizeText from '../../../../@Api/Localization/localizeText';

const copy = require('copy-to-clipboard');

export class ApiStateStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('FeedbackStore') feedbackStore: FeedbackStore;

    // ------------------------- Properties -------------------------

    @observable isAvailable: boolean;
    @observable errors = observable.array<ApiResponse<any>>();
    @observable dialogStores = observable.array<DialogStore>();

    // ------------------------ Constructor -------------------------

    constructor(isAvailable: boolean = true,
                errors: Array<ApiResponse<any>> = [])
    {
        super();

        this.isAvailable = isAvailable;
        this.errors.replace(errors);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get isOpen(): boolean
    {
        return !this.isAvailable || this.errors.length > 0;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action
    reportLive()
    {
        this.isAvailable = true;
    }

    @action
    reportDown(response: ApiResponse<any>)
    {
        if (response.status === 429)
        {
            this.isAvailable = false;

            this.feedbackStore.enqueueSnackbar(
                localizeText(
                    'TooManyRequests',
                    'Je hebt momenteel teveel aanvragen aan de server gedaan. Probeer het later nog eens.'
                ),
                {
                    key: 'TooManyRequests',
                    variant: 'error'
                }
            );
        }
        else
        {
            this.isAvailable = false;

            this.feedbackStore.enqueueSnackbar(
                localizeText(
                    'CannotReachServer',
                    'De server kan op dit moment niet bereikt worden. Controleer uw verbinding of probeer het later nog eens.'
                ),
                {
                    key: 'ServerError',
                    variant: 'error'
                }
            );
        }
    }

    @action
    reportError(apiRequest: ApiRequest<any>,
                apiResponse: ApiResponse<any>)
    {
        this.errors.push(apiResponse);

        const data =
            {
                request:
                {
                    resource: apiRequest.resource,
                    method: Method[apiRequest.method],
                    data: apiRequest.data,
                    headers: apiRequest.headers
                },
                response:
                {
                    ok: apiResponse.ok,
                    data: toJS(apiResponse.data),
                    error: apiResponse.error
                }
            };

        this.dialogStores.push(
            new DialogStore({
                title: 'Oops!',
                viewComponent:
                    new ViewComponent(
                        Text as any,
                        new TextStore({
                            label: localizeText('ServerError', 'Er is een fout opgetreden op onze server. %0'),
                            childTextStores:
                            [
                                new TextStore({
                                    style:
                                    {
                                        marginTop: viewInset
                                    },
                                    viewComponent:
                                        new ViewComponent(
                                            JsonEditor,
                                            new JsonEditorStore(data)),
                                })
                            ]
                        })),
                buttons:
                    () =>
                        [
                            new ButtonStore({
                                label: localizeText('Generic.CopyToClipboard', 'Kopieer naar klembord'),
                                color: 'primary',
                                onClick:
                                    () =>
                                        copy(JSON.stringify(data))
                            }),
                            ...this.getDialogButtons()
                        ]
            }));
    }

    @action
    clearErrors()
    {
        this.errors.clear();
    }

    @action.bound
    dismiss()
    {
        this.reportLive();
        this.clearErrors();
    }

    @action.bound
    popDialog()
    {
        this.dialogStores.pop();
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    private getDialogButtons(): ButtonStore[]
    {
        return [
            new ButtonStore({
                label: localizeText('MoveOn', 'Ga verder'),
                color: 'primary',
                isRaised: true,
                onClick:
                    () => this.popDialog()
            })
        ];
    }
}
