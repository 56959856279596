import AutomationDependencyContext from '../../AutomationDependencyContext';
import CronTrigger from '../CronTrigger';
import getMutationTriggerFromDescriptor from './getMutationTriggerFromDescriptor';
import Trigger from '../Trigger';
import updateTriggerWithPredicateFromDescriptor from './updateTriggerWithPredicateFromDescriptor';

export default async function getTriggerFromDescriptor(descriptor: any,
                                                       dependencyContext: AutomationDependencyContext)
{
    let trigger: Trigger;

    switch (descriptor.type)
    {
        case 'Creation':
        case 'Update':
        case 'Deletion':
        case 'Composite':
            trigger =
                await getMutationTriggerFromDescriptor(
                    descriptor,
                    dependencyContext);
                break;

        case 'Cron':
            trigger =
                await CronTrigger.fromDescriptor(
                    descriptor,
                    dependencyContext);
            break;
    }

    if (!trigger)
    {
        throw new Error();
    }

    await updateTriggerWithPredicateFromDescriptor(
        trigger,
        descriptor,
        dependencyContext,
        true);

    return trigger;
}
