import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../../Viewer/LayoutViewer';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import EmptyValue from '../../../../../../@Api/Automation/Value/EmptyValue';
import { runInAction } from 'mobx';
import Selectbox, { OptionMetadata, SelectionValue } from '../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import InsetLayout from '../../../../../../@Api/Layout/Type/InsetLayout';
import CollectionType from '../../../../../../@Api/Automation/Value/Type/CollectionType';
import Value from '../../../../../../@Api/Automation/Value/Value';
import CollectionValue from '../../../../../../@Api/Automation/Value/CollectionValue';
import EntitySelectionFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/EntitySelectionFormInputLayout';
import useFormInputLayoutPlaceholder from '../Shared/Api/useFormInputLayoutPlaceholder';

export interface EntitySelectionFormInputLayoutViewerProps extends LayoutViewerProps<EntitySelectionFormInputLayout>
{

}

const EntitySelectionFormInputLayoutViewer: React.FC<EntitySelectionFormInputLayoutViewerProps> =
    props =>
    {
        const { layout, parameterDictionary, parameterAssignment, commitContext } = props;

        const parameter =
            useComputed<Parameter<any>>(
                () =>
                    layout.parameterId
                        && parameterDictionary.getParameterById(layout.parameterId),
                [
                    layout,
                    parameterDictionary
                ]);

        const load =
            useCallback(
                async (searchQuery?: string) =>
                    CollectionValue.getCollection(
                        await layout.query.execute(
                            new FunctionContext(
                                parameterDictionary,
                                parameterAssignment,
                                commitContext
                            ),
                            searchQuery && searchQuery.length > 0
                                ? { query: searchQuery, fieldPaths: layout.searchFieldPaths }
                                : undefined)),
                [
                    layout,
                    parameterDictionary,
                    parameterAssignment,
                    commitContext
                ]);

        const optionLayoutWithInset =
            useComputed(
                () =>
                    new InsetLayout(
                        layout.optionLayout,
                        5,
                        5,
                        5,
                        5),
                [
                    layout
                ]);

        const optionParameterDictionary =
            useComputed(
                () =>
                    parameterDictionary.getNewDictionaryWithParameter(layout.query.parameter),
                [
                    parameterDictionary,
                    layout
                ]);

        const formatter =
            useCallback(
                (option: Value<any, any>, metadata: OptionMetadata<Value<any, any>>) =>
                    <LayoutViewer
                        parameterDictionary={optionParameterDictionary}
                        parameterAssignment={
                            parameterAssignment.getNewAssignmentWithParameter(
                                layout.query.parameter,
                                option)
                        }
                        layout={metadata.context === 'value' ? layout.optionLayout : optionLayoutWithInset}
                    />,
                [
                    optionParameterDictionary,
                    parameterAssignment,
                    layout,
                    optionLayoutWithInset
                ]);

        const idResolver =
            useCallback(
                (option: Value<any, any>) =>
                    option.getId(),
                []);

        const isMultiselect =
            useComputed(
                () =>
                    parameter.type instanceof CollectionType,
                [
                    parameter
                ]);

        const onChange =
            useCallback(
                (value: SelectionValue<Value<any, any>>) =>
                    runInAction(
                        () =>
                        {
                            let parameterValue: Value<any, any>;

                            if (isMultiselect && value)
                            {
                                const arrayValue = value as Value<any, any>[];

                                if (arrayValue.length === 0)
                                {
                                    parameterValue = EmptyValue.instance;
                                }
                                else
                                {
                                    parameterValue =
                                        new CollectionValue(
                                            arrayValue,
                                            (parameter.type as CollectionType<any>).type);
                                }
                            }
                            else
                            {
                                if (value)
                                {
                                    parameterValue = value as Value<any, any>;
                                }
                                else
                                {
                                    parameterValue = EmptyValue.instance;
                                }
                            }

                            parameterAssignment
                                .setValue(
                                    parameter,
                                    parameterValue);
                        }),
                [
                    isMultiselect,
                    parameterAssignment,
                    parameter
                ]);

        const value =
            useComputed(
                () =>
                {
                    const value = parameterAssignment.getValue(parameter);

                    if (value instanceof CollectionValue)
                    {
                        return value.value;
                    }
                    else
                    {
                        return value;
                    }
                },
                [
                    parameterAssignment,
                    parameter
                ]);

        const placeholder =
            useFormInputLayoutPlaceholder(
                layout,
                parameterDictionary,
                parameterAssignment,
                commitContext
            );

        const isDisabled =
            useComputed(
                () =>
                    layout.isDisabled?.synchronouslyEvaluate(
                        new FunctionContext(
                            parameterDictionary,
                            parameterAssignment,
                            commitContext
                        )
                    ),
                [
                    layout,
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                ]);

        return <Selectbox
            load={load}
            formatOption={formatter}
            idResolver={idResolver}
            onChange={onChange}
            value={value}
            multi={isMultiselect}
            disableUnderline={false}
            clearable
            placeholder={placeholder}
            disabled={isDisabled}
        />;
    };

export default observer(EntitySelectionFormInputLayoutViewer);
