import * as React from 'react';
import { DataObjectBespokeEditor } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { observer } from 'mobx-react';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { PredicateEditorStore } from './PredicateEditorStore';
import PredicatePicker from '../../../../Predicate/Picker/PredicatePicker';

@observer
export class PredicateEditor extends DataObjectBespokeEditor<PredicateEditorStore>
{
    renderComponent(store: DataObjectEditorStore<PredicateEditorStore>)
    {
        return <PredicatePicker
            store={store.bespokeStore.predicatePickerStore}
        />;
    }
}
