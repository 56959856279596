import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import { useComputed } from 'mobx-react-lite';
import getResultValue from './getResultValue';

export default function useResultValue(result: EntitySelectionAggregateResult)
{
    return useComputed(
        () =>
            getResultValue(result),
        [
            result
        ]);
}