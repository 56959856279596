export default function search(query: string, inString: string)
{
    if (inString)
    {
        if (query)
        {
            return inString.toLowerCase()
                .includes(query.toLowerCase());
        }
        else
        {
            return true;
        }
    }
    else
    {
        return false;
    }
}
