import React from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../../../Type/Api/useTypes';
import ExpansionPanel from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { CampaignProps } from '../Campaign';
import Input from '../../../../../Input/Input';
import InputGroup from '../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface CampaignSenderProps extends CampaignProps
{
    commitContext: CommitContext;
}

const CampaignSender: React.FC<CampaignSenderProps> =
    ({
        entity,
        commitContext,
     }) =>
    {
        const types = useTypes();

        return <ExpansionPanel
            id="Sender"
            summary={
                <Header
                    large
                    inset
                    title={
                        <LocalizedText
                            code="Campaign.Sender"
                            value="Afzender"
                        />
                    }
                />
            }
            expansion={
                <CardInset>
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        <ViewGroupItem>
                            <InputGroup>
                                <Input
                                    labelPosition="left"
                                    entity={entity}
                                    field={types.Activity.Campaign.Field.SenderName}
                                    commitContext={commitContext}
                                />
                                <Input
                                    labelPosition="left"
                                    entity={entity}
                                    field={types.Activity.Campaign.Field.SenderEmailAddress}
                                    commitContext={commitContext}
                                />
                                <Input
                                    labelPosition="left"
                                    entity={entity}
                                    field={types.Activity.Campaign.Field.UseAccountManagerAsSender}
                                    commitContext={commitContext}
                                />
                            </InputGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </CardInset>
            }
        />;
    };

export default observer(CampaignSender);
