import localizeText from '../../@Api/Localization/localizeText';

interface CronDay
{
    id: string;
    name: string;
}

export function getCronDays(): CronDay[]
{
    return [
        {
            id: 'MON',
            name: localizeText('Generic.Monday', 'Maandag')
        },
        {
            id: 'TUE',
            name: localizeText('Generic.Tuesday', 'Dinsdag')
        },
        {
            id: 'WED',
            name: localizeText('Generic.Wednesday', 'Woensdag')
        },
        {
            id: 'THU',
            name: localizeText('Generic.Thursday', 'Donderdag')
        },
        {
            id: 'FRI',
            name: localizeText('Generic.Friday', 'Vrijdag')
        },
        {
            id: 'SAT',
            name: localizeText('Generic.Saturday', 'Zaterdag')
        },
        {
            id: 'SUN',
            name: localizeText('Generic.Sunday', 'Zondag')
        }
    ];
}
