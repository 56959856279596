import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { Entity } from '../../Model/Implementation/Entity';
import { ClientLanguageHeader, ClientLanguageOverrideHeader } from '../../../@Service/Localization/LocalizationConstants';

export class EntitySelectionController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    aggregates(selection: any, aggregates: any): Promise<any>
    {
            return new Promise<any>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/user/entities/aggregates', Method.Post, { selection: selection, aggregates: aggregates }))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    select(selection: any, start: number, length: number, ordering: any, languageCode?: string): Promise<any>
    {
        const headers: any = {};

        if (languageCode !== undefined)
        {
            headers[ClientLanguageHeader] = languageCode;
            headers[ClientLanguageOverrideHeader] = true;
        }

        return new Promise<any>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        '/user/entities/select',
                        Method.Post,
                        {
                            selection: selection,
                            start: start,
                            length: length,
                            ordering: ordering,
                            isExtendedFormat: true,
                        },
                        headers))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    selectByPaths(ids: any, entityTypeId: number, paths: any): Promise<Entity[]>
    {
            return new Promise<Entity[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/user/entities/select-by-paths', Method.Post, { ids: ids, entity_type_id: entityTypeId, paths: paths }))
                    .then(result =>
                    {
                        resolve((fromJson(result, Entity as any) as Entity[]));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
}
