import { computed, observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValue from '../../Value/PrimitiveValue';
import FunctionContext from '../FunctionContext';
import { AiPromptComputationPart } from './AiPromptComputationPart';
import getValueTypeFromDescriptor from '../../Api/getValueTypeFromDescriptor';
import Value from '../../Value/Value';

export default class AiPromptComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.shallow parts: AiPromptComputationPart[];
    @observable.ref resultType: ValueType<any>;

    // ------------------------ Constructor -------------------------

    constructor(
        parts: AiPromptComputationPart[],
        resultType: ValueType<any>
    )
    {
        super();

        this.parts = parts;
        this.resultType = resultType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get computationById()
    {
        return new Map(
            this.parts.map(
                computation => [
                    computation.id,
                    computation
                ]));
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return this.resultType;
    }

    isAsync(): boolean
    {
        return this.parts.some(
            part =>
                part.isAsync()
        );
    }

    async apply(context: FunctionContext): Promise<PrimitiveValue>
    {
        throw new Error(`Unimplemented`);
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        throw new Error(`Unimplemented`);
    }

    getName(): string
    {
        return `AI prompt: ${this.parts.map(part => part.computation.getName()).join(', ').substring(0, 50)}...`;
    }

    validate(): Validation[]
    {
        return this.parts
            .map(
                part =>
                    part.validate()
            )
            .reduce((a, b) => a.concat(b), []);
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'AiPrompt';
        descriptor.parts =
            this.parts.map(
                part =>
                    part.toDescriptor()
            );
        descriptor.resultType = this.resultType.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.parts
            .map(
                part =>
                    part.getDependencies()
            )
            .reduce((a, b) => a.concat(b), []);
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext
    )
    {
        return new AiPromptComputation(
            await Promise.all(
                descriptor.parts.map(
                    part =>
                        AiPromptComputationPart.fromDescriptor(
                            part,
                            dependencyContext
                        )
                )
            ),
            await getValueTypeFromDescriptor(
                descriptor.resultType,
                dependencyContext
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
