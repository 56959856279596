import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../../Model/DataObjectType';
import { Comparator } from '../../../Model/Comparator';
import { Aggregate } from '../../../Model/Aggregate';
import { MathematicalOperator } from '../../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../../Model/DataObjectFunction';
import { DataObject } from '../../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../../@Util/DependencyInjection/index';
import { NumberRangeView } from './NumberRangeView';
import { NumberRangeEditor } from './NumberRangeEditor';
import { NumberRangeSpecification } from './NumberRangeSpecification';
import { NumberRangeValue } from './NumberRangeValue';
import { DataDescriptor } from '../../../Model/DataDescriptor';
import { ComparatorDescriptor } from '../../../Model/ComparatorDescriptor';
import { DataObjectRepresentation } from '../../../Model/DataObjectRepresentation';
import { DataObjectOperatorOverload } from '../../../Model/DataObjectOperatorOverload';
import { DataObjectContext } from '../../../Model/DataObjectContext';
import { DataObjectComparatorOverload } from '../../../Model/DataObjectComparatorOverload';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { toJS } from 'mobx';
import { DataObjectSpecification } from '../../../Model/DataObjectSpecification';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export class NumberRangeType implements DataObjectType<NumberRangeValue>
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'NumberRange';
    }

    name()
    {
        return localizeText('DataObject.Type.NumberRange', 'Nummerbereik');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [];
    }

    supportedAggregates(): Aggregate[]
    {
        return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return NumberRangeView;
    }

    editor(): DataObjectEditorType
    {
        return NumberRangeEditor;
    }

    specification(): DataObjectSpecificationType
    {
        return NumberRangeSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return null;
    }

    requiresInitialization(value: NumberRangeValue): boolean
    {
        return false;
    }

    getDataFromValue(value: NumberRangeValue, specification: DataObjectSpecification): DataDescriptor
    {
        let data = new DataDescriptor();
        data.number1 = value.from;
        data.number2 = value.to;

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): any
    {
        return {
            from: data.number1,
            to: data.number2
        };
    }

    valueId(value: NumberRangeValue): string
    {
        return JSON.stringify(toJS(value));
    }

    getString(value: NumberRangeValue,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        return `${value.from} - ${value.to}`;
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return false;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [];
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    isValid(): boolean
    {
        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return true;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }
}
