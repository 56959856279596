import { LogicalOperator } from '../../../../DataObject/Model/LogicalOperator';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import CompositePredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';

export default function combinePredicates(predicateA?: Predicate,
                                          predicateB?: Predicate)
{
    const predicates =
        [
            predicateA,
            predicateB
        ].filter(predicate => predicate !== undefined);

    if (predicates.length === 1)
    {
        return predicates[0];
    }
    else
    {
        return new CompositePredicate(
            LogicalOperator.And,
            predicates);
    }
}
