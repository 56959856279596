export function isPromise(object: any)
{
    // https://stackoverflow.com/a/38339199
    return Promise.resolve(object) === object;
}

export async function serialPromise<T>(generators: Array<() => Promise<T>>): Promise<T[]>
{
    const results: T[] = [];

    for (const generator of generators)
    {
        const result = await generator();

        results.push(result);
    }

    return results;
}

export function debounce<T>(inner: (...args: any[]) => T, ms = 0)
{
    let timer: any = null;
    let resolves: any[] = [];

    return function (...args: any[])
    {
        // Run the function after a certain amount of time
        clearTimeout(timer);

        timer = setTimeout(
            () =>
            {
                // Get the result of the inner function, then apply it to the resolve function of
                // each promise that has been created since the last time the inner function was run
                let result = inner(...args);
                resolves.forEach(r => r(result));
                resolves = [];
            },
            ms);

        return new Promise(r => resolves.push(r));
    };
}

export function delay(timeout: number)
{
    return new Promise(
        resolve =>
        {
            setTimeout(
                resolve,
                timeout);
        });
}
