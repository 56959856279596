export function groupBy<T, K>(array: T[],
                              key: (element: T) => K): Map<K, T[]>
{
    const map = new Map<K, T[]>();

    array.forEach(
        element =>
        {
            const id = key(element);

            if (map.has(id))
            {
                map.get(id).push(element);
            }
            else
            {
                map.set(id, [ element ]);
            }
        });

    return map;
}
