import AutomationDependencyContext from '../AutomationDependencyContext';
import { observable } from 'mobx';
import Predicate from '../Function/Computation/Predicate/Predicate';
import { EntityType } from '../../Model/Implementation/EntityType';
import Query from './Query';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import ValueType from '../Value/Type/ValueType';
import EntityValueType from '../Value/Type/EntityValueType';
import CollectionType from '../Value/Type/CollectionType';
import FunctionContext from '../Function/FunctionContext';
import { EntitySelectionBuilder } from '../../../@Component/Domain/Entity/Selection/Builder/EntitySelectionBuilder';
import EmptyValue from '../Value/EmptyValue';
import EntityValue from '../Value/EntityValue';
import CollectionValue from '../Value/CollectionValue';
import ListQueryOrdering from './ListQueryOrdering';
import ReactiveInterface from '../Value/ReactiveInterface';
import { QuerySearchParameters } from './QuerySearchParameters';
import Parameter from '../Parameter/Parameter';
import { getSubstitutedComputation } from '../Api/getSubstitutedComputation';
import ValueFromEntityComputation from '../Function/Computation/ValueFromEntityComputation';
import PagedCollectionValue from '../Value/PagedCollectionValue';
import { Entity } from '../../Model/Implementation/Entity';

export default class ListQuery extends Query
{
    // ------------------------- Properties -------------------------

    @observable.shallow joinPaths: EntityPath[];
    @observable.shallow orderings: ListQueryOrdering[];
    @observable offset: number;
    @observable limit: number;

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType,
                parameter: Parameter<EntityValueType>,
                filter: Predicate,
                joinPaths: EntityPath[],
                orderings: ListQueryOrdering[],
                offset: number,
                limit: number)
    {
        super(entityType, parameter, filter);

        this.joinPaths = joinPaths;
        this.orderings = orderings;
        this.offset = offset;
        this.limit = limit;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'List';
        descriptor.joinPaths = this.joinPaths.map(path => path.descriptor);
        descriptor.orderings = this.orderings.map(ordering => ordering.toDescriptor());
        descriptor.offset = this.offset;
        descriptor.limit = this.limit;
    }

    getType(): ValueType<any>
    {
        const elementType = new EntityValueType(this.entityType);

        if (this.limit <= 1)
        {
            return elementType;
        }
        else
        {
            return new CollectionType(elementType);
        }
    }

    async execute(
        context: FunctionContext,
        searchParameters?: QuerySearchParameters
    ): Promise<CollectionValue<Entity>>
    {
        const selectionBuilder =
            EntitySelectionBuilder.builder(
                this.entityType,
                builder =>
                    builder
                        .if(
                            () => this.filter !== undefined,
                            () =>
                                builder.where(
                                    cb =>
                                        cb.filter(
                                            this.filter,
                                            {
                                                context,
                                                parameter: this.parameter,
                                            }
                                        )
                                )
                        )
                        .if(
                            () => true,
                            () =>
                                this.joinPaths.forEach(
                                    joinPath =>
                                        builder.join(joinPath)))
                        .if(
                            () => searchParameters !== undefined,
                            () =>
                                this.augmentBuilderWithSearchParameters(
                                    builder,
                                    searchParameters))
                        .if(
                            () => this.offset !== undefined,
                            () => builder.offset(this.offset))
                        .if(
                            () => this.limit !== undefined,
                            () => builder.limit(this.limit))
            );
        const orderingDependencyContext =
            new AutomationDependencyContext(
                context.parameterDictionary
                    .getNewDictionaryWithParameter(this.parameter)
            );
        const substitutedOrderings =
            await Promise.all(
                this.orderings.map(
                    async ordering =>
                        new ListQueryOrdering(
                            ordering.id,
                            await getSubstitutedComputation(
                                ordering.expression,
                                orderingDependencyContext,
                                computation =>
                                {
                                    if (computation instanceof Parameter)
                                    {
                                        return selectionBuilder.selection.rootNode.parameter;
                                    }
                                    else if (computation instanceof ValueFromEntityComputation)
                                    {
                                        if (computation.fieldPath.isField)
                                        {
                                            const parameter =
                                                selectionBuilder
                                                    .joinAndGet(computation.fieldPath.path)
                                                    .parameter;

                                            return new ValueFromEntityComputation(
                                                parameter,
                                                EntityPath.fromEntityType(parameter.type.type)
                                                    .field(computation.fieldPath.field)
                                            );
                                        }
                                        else
                                        {
                                            const parameter =
                                                selectionBuilder
                                                    .joinAndGet(computation.fieldPath.basePath)
                                                    .parameter;

                                            return new ValueFromEntityComputation(
                                                parameter,
                                                EntityPath.fromEntityType(parameter.type.type)
                                                    .joinTo(
                                                        computation.fieldPath.relationshipDefinition,
                                                        computation.fieldPath.isParentRelationship
                                                    )
                                                    .field()
                                            );
                                        }
                                    }
                                    else
                                    {
                                        return computation;
                                    }
                                }
                            ),
                            ordering.direction
                        )
                )
            );
        substitutedOrderings.forEach(
            ordering =>
                selectionBuilder.orderByExpression(
                    ordering.expression,
                    ordering.direction === 'Ascending'
                )
        );
        const result = await selectionBuilder.selectExtendedResult();
        const results = result.records;
        const hasMore =
            this.limit === undefined
            && selectionBuilder.limitNumber !== undefined
            && selectionBuilder.limitNumber <= result.numberOfRecords;
        const loadMore =
            () =>
                new ListQuery(
                    this.entityType,
                    this.parameter,
                    this.filter,
                    this.joinPaths,
                    this.orderings,
                    (this.offset ?? 0) + selectionBuilder.limitNumber,
                    undefined
                ).execute(
                    context,
                    searchParameters
                );
        const reactiveInterface: ReactiveInterface = {
            getState(): string
            {
                return results.map(
                    result =>
                        result.entity.uuid).join(';');
            },
            getValue()
            {
                const entities = results.map(result => result.entity);

                if (this.limit <= 1)
                {
                    if (entities.length === 0)
                    {
                        // Create new empty value, instead of empty one because
                        // the reactive interface is set to it
                        return new EmptyValue();
                    }
                    else
                    {
                        return new EntityValue(entities[0]);
                    }
                }
                else
                {
                    return new PagedCollectionValue(
                        entities.map(
                            entity =>
                                new EntityValue(entity)),
                        new EntityValueType(this.entityType),
                        hasMore,
                        loadMore
                    );
                }
            }
        }

        const value = reactiveInterface.getValue();
        value.reactiveInterface = reactiveInterface;
        return value as CollectionValue<Entity>;
    }

    static async fromDescriptor(dependencyContext: AutomationDependencyContext,
                                descriptor: any)
    {
        const entityType = loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.entityTypeId);
        const parameter =
            Query.getResultParameter(
                entityType,
                descriptor.parameterId
            );
        const listQuery =
            new ListQuery(
                entityType,
                parameter,
                undefined,
                descriptor.joinPaths
                    ?
                        descriptor.joinPaths.map(
                            joinPath =>
                                EntityPath.construct(joinPath))
                    :
                        [],
                [],
                descriptor.offset,
                descriptor.limit);
        const orderingsDependencyContext =
            new AutomationDependencyContext(
                dependencyContext.parameterDictionary.getNewDictionaryWithParameter(
                    listQuery.parameter));
        const orderings =
            await Promise.all<ListQueryOrdering>(
                (descriptor.orderings || []).map(
                    ordering =>
                        ListQueryOrdering.fromDescriptor(
                            orderingsDependencyContext,
                            ordering)));
        listQuery.orderings = orderings;

        return listQuery;
    }

    // ----------------------- Private logic ------------------------
}
