import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { useContext, useMemo } from 'react';
import useTypes from '../../Type/Api/useTypes';
import CurrentUserContext from '../../../User/CurrentUserContext';
import useResults from '../../Selection/Hooks/useResults';

export default function useActiveTimeRegistration(): Entity
{
    const types = useTypes();
    const currentUserStore = useContext(CurrentUserContext);

    const activeTimeRegistrations =
        useResults(
            types.TimeRegistration.Type,
            (builder, rootPath) =>
                builder
                    .join(
                        rootPath
                            .joinTo(
                                types.TimeRegistration.RelationshipDefinition.Activity,
                                false))
                    .join(
                        rootPath
                            .joinTo(
                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                true))
                    .join(
                        rootPath
                            .joinTo(
                                types.Activity.RelationshipDefinition.TimeRegistrations,
                                true))
                    .where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath
                                    .joinTo(
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                        true),
                                currentUserStore.employeeEntity))
                    .where(
                        cb =>
                            cb.isDefined(rootPath.field(types.TimeRegistration.Field.StartDate)))
                    .where(
                        cb =>
                            cb.isNotDefined(rootPath.field(types.TimeRegistration.Field.EndDate))),
            [
                types,
                currentUserStore
            ]);

    return useMemo(
        () =>
            activeTimeRegistrations &&
                activeTimeRegistrations.find(() => true),
        [
            activeTimeRegistrations
        ]);
}
