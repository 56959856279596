import ValueType from '../../../../@Api/Automation/Value/Type/ValueType';
import PrimitiveValueType from '../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../DataObject/Model/DataObject';

export function findAssistantValueType(type: string): ValueType<any>
{
    return new PrimitiveValueType(
        DataObject.getTypeById(type)
    );
}
