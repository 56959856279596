import React from 'react';
import { observer } from 'mobx-react-lite';
import StaticTableDimensionSection from '../../../../../../../@Api/Layout/Type/Table/Model/StaticTableDimensionSection';
import { TableLayoutDimensionSectionEditorProps } from './TableLayoutDimensionSectionEditor';

export interface TableLayoutStaticDimensionSectionEditorProps extends TableLayoutDimensionSectionEditorProps
{
    section: StaticTableDimensionSection;
}

const TableLayoutStaticDimensionSectionEditor: React.FC<TableLayoutStaticDimensionSectionEditorProps> =
    () =>
    {
        return null;
    };

export default observer(TableLayoutStaticDimensionSectionEditor);
