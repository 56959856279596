import { reference, type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityEvent } from './EntityEvent';
import equalsEntity from '../../Entity/Bespoke/equalsEntity';
import { Entity } from './Entity';

@type('EntityEventFilter')
export class EntityEventFilter
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'EntityPath') @observable entityPath: EntityPath;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityPath: EntityPath)
    {
        this.entityPath = entityPath;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    name?(entityTypeStore: EntityTypeStore): string;

    descriptor?(): any;

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    appliesTo(event: EntityEvent,
              rootEntity?: Entity)
    {
        if (rootEntity)
        {
            return this.entityPath.reverse()
                .traverseEntity(event.entity)
                .some(
                    relatedEntity =>
                        equalsEntity(relatedEntity, rootEntity));
        }
        else
        {
            return true;
        }
    }

    // ----------------------- Private logic ------------------------
}
