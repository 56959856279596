import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Mapping from '../../../../../../../../../../../@Api/Automation/Mapping/Mapping';
import resolveInputFromFieldPath from '../../../../../../../../../Multiplayer/Model/Input/Api/resolveInputFromFieldPath';
import ViewGroup from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import InputGroup from '../../../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Card from '../../../../../../../../../../../@Future/Component/Generic/Card/Card';
import FieldPathSelector from '../../../../../../../../Path/FieldPathEditor/FieldPathSelector';
import Popper from '../../../../../../../../../../../@Future/Component/Generic/Popper/Popper';
import useSwitch from '../../../../../../../../../../../@Util/Switch/useSwitch';
import { EntityContext } from '../../../../../../../../@Model/EntityContext';
import { EntityPath } from '../../../../../../../../Path/@Model/EntityPath';
import { EntityFieldPath } from '../../../../../../../../Path/@Model/EntityFieldPath';
import CardInset from '../../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { EntityType } from '../../../../../../../../../../../@Api/Model/Implementation/EntityType';
import { runInAction } from 'mobx';
import ValueFieldMapping from '../../../../../../../../../../../@Api/Automation/Mapping/Field/ValueFieldMapping';
import FunctionContext from '../../../../../../../../../../../@Api/Automation/Function/FunctionContext';
import RelationshipInput from '../../../../../../../../../Multiplayer/Model/Input/RelationshipInput';
import FieldInput from '../../../../../../../../../Multiplayer/Model/Input/FieldInput';
import MappingFieldMapping from '../../../../../../../../../../../@Api/Automation/Mapping/Field/MappingFieldMapping';
import FieldsEditor from './FieldMapping/FieldsEditor';
import KeysEditor from './Key/KeysEditor';
import useDividerGlue from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import HoverCardMiddle from '../../../../../../../../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import LocalizedText from '../../../../../../../../../Localization/LocalizedText/LocalizedText';
import { isPrimitiveFieldRequired } from '../../../../../../../../../../../@Api/Metadata/Input/isPrimitiveFieldRequired';
import { isRelationshipDefinitionRequired } from '../../../../../../../../../../../@Api/Metadata/Input/isRelationshipDefinitionRequired';

export interface MappingEditorProps
{
    mapping: Mapping;
    context: FunctionContext;
    parentEntityType?: EntityType;
    parentFieldMapping?: MappingFieldMapping;
}

const MappingEditor: React.FC<MappingEditorProps> =
    props =>
    {
        const fieldContext =
            useMemo(
                () =>
                    new EntityContext(
                        [],
                        EntityPath.fromEntityType(props.mapping.entityType)),
                [
                    props.mapping.entityType
                ]);

        const [ isFieldSelectorOpen, openFieldSelector, closeFieldSelector ] = useSwitch(false);
        const selectFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                        {
                            props.mapping.fieldMappings
                                .push(
                                    new ValueFieldMapping(
                                        resolveInputFromFieldPath(fieldPath),
                                        undefined));

                            closeFieldSelector();
                        }),
                [
                    props.mapping,
                    closeFieldSelector
                ]);

        useEffect(
            () =>
            {
                runInAction(
                    () =>
                    {
                        [ true, false ]
                            .forEach(
                                isParent =>
                                    props.mapping.entityType
                                        .getInheritedRelationshipDefinitions(isParent)
                                        .filter(
                                            relationshipDefinition =>
                                                isRelationshipDefinitionRequired(
                                                    props.mapping.entityType,
                                                    isParent,
                                                    relationshipDefinition
                                                )
                                                && !relationshipDefinition.isComputed(isParent))
                                        .map(
                                            relationshipDefinition =>
                                                new RelationshipInput(
                                                    relationshipDefinition.getEntityType(!isParent),
                                                    relationshipDefinition,
                                                    isParent))
                                        .filter(
                                            field =>
                                                !props.mapping.fieldMappingsByFieldId.has(field.id())
                                                && (
                                                    !props.parentFieldMapping
                                                    || !(field.relationshipDefinition === props.parentFieldMapping.field.relationshipDefinition
                                                        && field.isParent !== props.parentFieldMapping.field.isParent)))
                                        .forEach(
                                            field =>
                                            {
                                                props.mapping.fieldMappings.push(
                                                    new ValueFieldMapping(
                                                        field,
                                                        undefined));
                                            }));

                        props.mapping.entityType
                            .getInheritedFields()
                            .filter(
                                field =>
                                    !field.isReadonly
                                    && !field.isComputedField
                                    && isPrimitiveFieldRequired(props.mapping.entityType, field)
                            )
                            .map(
                                field =>
                                    new FieldInput(
                                        field.entityType,
                                        field))
                            .filter(
                                field =>
                                    !props.mapping.fieldMappingsByFieldId.has(field.id()))
                            .forEach(
                                field =>
                                    props.mapping.fieldMappings.push(
                                        new ValueFieldMapping(
                                            field,
                                            undefined)));
                    });
            },
            [
                props.mapping
            ]);

        const dividerGlue = useDividerGlue();

        return <ViewGroup
            orientation="vertical"
            spacing={0}
            glue={dividerGlue}
        >
            <ViewGroupItem>
                <CardInset
                    horizontal
                    top={false}
                >
                    <InputGroup>
                        {
                            Array.from(props.mapping.fieldMappingsByFieldId.keys())
                                .map(
                                    fieldId =>
                                        <FieldsEditor
                                            key={fieldId}
                                            context={props.context}
                                            mapping={props.mapping}
                                            field={props.mapping.fieldMappingsByFieldId.get(fieldId)[0].field}
                                        />)
                        }
                    </InputGroup>
                </CardInset>
            </ViewGroupItem>
            <ViewGroupItem>
                <Popper
                    reference={
                        <HoverCardMiddle
                            onClick={openFieldSelector}
                        >
                            <LocalizedText
                                code="AutomationEditor.AddFieldButton"
                                value="+ Veld"
                            />
                        </HoverCardMiddle>
                    }
                    popper={
                        <Card>
                            <FieldPathSelector
                                context={fieldContext}
                                onSelect={selectFieldPath}
                            />
                        </Card>
                    }
                    open={isFieldSelectorOpen}
                    onClose={closeFieldSelector}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <KeysEditor
                    {...props}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(MappingEditor);
