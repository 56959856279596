import { observable } from 'mobx';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import { Computation } from '../../Computation';
import ValueFromEntityComputation from '../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import AutomationDependencyContext from '../../../../../../@Api/Automation/AutomationDependencyContext';
import { getParameterInOldComputation } from '../../../Api/getParameterInOldComputation';

export class EntityFieldComputation extends Computation
{
    // ------------------------- Properties -------------------------

    @observable entityContextParameter?: string;
    @observable entityFieldPath: EntityFieldPath;

    // ------------------------ Constructor -------------------------

    constructor(entityFieldPath: EntityFieldPath,
                entityContextParameter?: string)
    {
        super('EntityField');

        this.entityContextParameter = entityContextParameter;
        this.entityFieldPath = entityFieldPath;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------


    async initialize(): Promise<any>
    {
        return Promise.resolve();
    }

    async toNewComputation(context: AutomationDependencyContext)
    {
        const parameter =
            getParameterInOldComputation(
                context,
                this.entityContextParameter
            );

        return new ValueFromEntityComputation(
            parameter,
            this.entityFieldPath
        );
    }

    // ----------------------- Private logic ------------------------
}
