import Dependency from '../../../../Automation/Parameter/Dependency';
import Validation from '../../../../Automation/Validation/Validation';
import { observable } from 'mobx';
import Computation from '../../../../Automation/Function/Computation/Computation';
import Parameter from '../../../../Automation/Parameter/Parameter';
import TableDimensionInstance from './TableDimensionInstance';
import FunctionContext from '../../../../Automation/Function/FunctionContext';
import TableLayout from '../TableLayout';
import Value from '../../../../Automation/Value/Value';
import CollectionValue from '../../../../Automation/Value/CollectionValue';
import LayoutAction from '../../../Action/LayoutAction';
import safelyApplyFunction from '../../../../Automation/Api/safelyApplyFunction';
import PrimitiveValue from '../../../../Automation/Value/PrimitiveValue';

export default abstract class TableDimensionSection
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref size: Computation<any, any>;
    @observable.ref onClick?: LayoutAction;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                size: Computation<any, any>,
                onClick?: LayoutAction)
    {
        this.id = id;
        this.size = size;
        this.onClick = onClick;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract getParameters(): Parameter<any>[];

    abstract computeInstanceValues(context: FunctionContext,
                                   layout: TableLayout): Promise<CollectionValue<any>>;

    abstract computeInstances(context: FunctionContext,
                              layout: TableLayout,
                              collection: Value<any, any>[]): Promise<TableDimensionInstance[]>;

    async computeSize(context: FunctionContext)
    {
        return PrimitiveValue.getAsNumber(
            await safelyApplyFunction(this.size, context)
        ) ?? 1;
    }

    validate(): Validation[]
    {
        return [
            ...this.size.validate(),
            ...(this.onClick?.validate() ?? [])
        ];
    }

    abstract getDependencies(): Dependency[];

    toDescriptor()
    {
        return {
            id: this.id,
            size: this.size.toDescriptor(),
            onClick: this.onClick?.toDescriptor()
        };
    }

    // ----------------------- Private logic ------------------------
}
