import React from 'react';
import { observer } from 'mobx-react-lite';
import Default from '../../Default';
import { ContentProps } from '../../Content';
import CourseLessonCronPatternEditor from '../../../../Viewer/Content/Activity/Course/LessonCronPatternEditor/CourseLessonCronPatternEditor';

export interface ActivityCourseProps extends ContentProps
{

}

const ActivityCourse: React.FC<ActivityCourseProps> =
    props =>
    {
        return <Default
            {...props}
            entity={props.entity}
            appendix={
                <CourseLessonCronPatternEditor
                    entity={props.entity}
                    commitContext={props.commitContext}
                />
            }
        />;
    };

export default observer(ActivityCourse);
