import DynamicFunctionRepository from './DynamicFunctionRepository';

export default class DynamicFunctionRepositoryManager
{
    // ------------------------- Properties -------------------------

    repositories: DynamicFunctionRepository<any>[];
    repositoryById: Map<string, DynamicFunctionRepository<any>>;

    // ------------------------ Constructor -------------------------

    constructor(repositories: DynamicFunctionRepository<any>[])
    {
        this.repositories = repositories;
        this.repositoryById =
            new Map(
                repositories.map(
                    repo => [
                        repo.id,
                        repo
                    ]));
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getRepositoryById(id: string)
    {
        return this.repositoryById.get(id);
    }

    // ----------------------- Private logic ------------------------
}
