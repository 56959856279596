import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { LanguageEntry } from '../../Model/Implementation/LanguageEntry';
import { EntityTypeMutation } from '../../Model/Implementation/EntityTypeMutation';
import { EntityTypeDeletionMutation } from '../../Model/Implementation/EntityTypeDeletionMutation';

export class EntityTypeController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    deleteEntityType(id: number): Promise<EntityTypeDeletionMutation>
    {
            return new Promise<EntityTypeDeletionMutation>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/entity/type/delete', Method.Post, { id: id }))
                    .then(result =>
                    {
                        resolve(fromJson(result, EntityTypeDeletionMutation));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    saveEntityType(id: number, code: string, nameSingular: LanguageEntry, namePlural: LanguageEntry, parentTypeId: number, nameFieldId: number, avatarFieldId: number, compactNameFieldId: number, sortNameFieldId: number, descriptionFieldId: number, isInstantiable: boolean, isIdentity: boolean, isQueueable: boolean, isRoot: boolean, isEmbedded: boolean, isOwnable: boolean, isAssignable: boolean, isOrganizationIdentifiable: boolean, isUserIdentifiable: boolean, isInternalUserIdentifiable: boolean, isExternalUserIdentifiable: boolean, showInModuleSwitcher: boolean, hasPicture: boolean, hasFollowUp: boolean, hasDuplicatePrevention: boolean, form: any, color: string, icon: string, entity: any): Promise<EntityTypeMutation>
    {
            return new Promise<EntityTypeMutation>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/entity/type/save', Method.Post, { id: id, code: code, name_singular: nameSingular, name_plural: namePlural, parent_type_id: parentTypeId, name_field_id: nameFieldId, avatar_field_id: avatarFieldId, compact_name_field_id: compactNameFieldId, sort_name_field_id: sortNameFieldId, description_field_id: descriptionFieldId, is_instantiable: isInstantiable, is_identity: isIdentity, is_queueable: isQueueable, is_root: isRoot, is_embedded: isEmbedded, is_ownable: isOwnable, is_assignable: isAssignable, is_organization_identifiable: isOrganizationIdentifiable, is_user_identifiable: isUserIdentifiable, is_internal_user_identifiable: isInternalUserIdentifiable, is_external_user_identifiable: isExternalUserIdentifiable, show_in_module_switcher: showInModuleSwitcher, has_picture: hasPicture, has_follow_up: hasFollowUp, has_duplicate_prevention: hasDuplicatePrevention, form: form, color: color, icon: icon, entity: entity }))
                    .then(result =>
                    {
                        resolve(fromJson(result, EntityTypeMutation));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
}
