import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';
import { EntitySelectionBuilder } from '../../../Selection/Builder/EntitySelectionBuilder';
import Selectbox from '../../../Selectbox/Selectbox';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import useCount from '../../../Selection/Hooks/useCount';
import getDefaultPipelineName from '../../../../../../@Api/Entity/Bespoke/Datastore/Phase/getDefaultPipelineName';

export interface PipelineSelectorProps
{
    entityType: EntityType;
    value?: Entity;
    onChange: (entity?: Entity) => void;
}

const PipelineSelector: React.FC<PipelineSelectorProps> =
    props =>
    {
        const types = useTypes();

        const selections =
            useComputed(
                () => [
                    new EntitySelectionBuilder(types.Pipeline.Type)
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    EntityPath.fromEntityType(types.Pipeline.Type)
                                        .joinTo(
                                            types.EntityType.RelationshipDefinition.Pipelines,
                                            true),
                                    props.entityType.entity))
                        .selection
                ],
                [
                    types,
                    props.entityType
                ]);

        const pipelineCount =
            useCount(
                types.Pipeline.Type,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath
                                    .joinTo(
                                        types.EntityType.RelationshipDefinition.Pipelines,
                                        true),
                                props.entityType.entity)),
                [
                    types,
                    props.entityType
                ]);

        const defaultPipelineName =
            useComputed(
                () =>
                    getDefaultPipelineName(props.entityType),
                [
                    props.entityType
                ]);

        if (pipelineCount > 0)
        {
            return <Input
                labelPosition="left"
                label={types.Pipeline.Type.getName()}
                inline
            >
                <Selectbox
                    value={props.value}
                    onChange={props.onChange}
                    selections={selections}
                    hideCaptionInValue
                    emptyOption={defaultPipelineName}
                />
            </Input>;
        }
        else
        {
            return null;
        }
    };

export default observer(PipelineSelector);
