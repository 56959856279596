import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import Link from '../../../../../../../../../../@Future/Component/Generic/Link/Link';
import useDialog from '../../../../../../../../../../@Service/Navigation/Page/Hooks/useDialog';
import QueryComputationDialog from './QueryFileExportComputationDialog';
import ListQuery from '../../../../../../../../../../@Api/Automation/Query/ListQuery';
import QueryFileExportComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/QueryFileExportComputation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationBox from '../../Box/ComputationBox';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import { runInAction } from 'mobx';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import FunctionContext from '../../../../../../../../../../@Api/Automation/Function/FunctionContext';
import AddButton from '../../../../../../../../../../@Future/Component/Generic/Button/Variant/AddButton/AddButton';
import uuid from '../../../../../../../../../../@Util/Id/uuid';
import QueryFileExportField from '../../../../../../../../../../@Api/Automation/Function/Computation/QueryFileExportField';
import { DataObject } from '../../../../../../../../DataObject/Model/DataObject';
import EmptyValue from '../../../../../../../../../../@Api/Automation/Value/EmptyValue';
import { ViewSelector } from '../../../../../../../View/ViewSelector/ViewSelector';
import View from '../../../../../../../View/Model/View';
import TextComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/TextComputation';
import ValueFromEntityComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface QueryFileExportComputationEditorProps extends ComputationEditorProps<ValueType<any>, QueryFileExportComputation>
{

}

const QueryFileExportComputationEditor: React.FC<QueryFileExportComputationEditorProps> =
    props =>
    {
        const [ openDialog ] =
            useDialog(
                close =>
                    <QueryComputationDialog
                        {...props}
                        onClose={close}
                    />,
                [
                    props
                ]
            );
        const isSingular =
            useComputed(
                () =>
                    props.value.query
                    && props.value.query instanceof ListQuery
                    && props.value.query.limit <= 1,
                [
                    props.value
                ]
            );
        useEffect(
            () =>
            {
                if (!props.value.query)
                {
                    openDialog();
                }
            },
            [
                props.value.query,
                openDialog
            ]
        );
        const setFileName =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.fileName = value
                    ),
                [
                    props.value,
                ]
            );
        const contextWithItem =
            useComputed(
                () =>
                    props.value.query &&
                        new FunctionContext(
                            props.context.parameterDictionary.getNewDictionaryWithParameter(
                                props.value.query.parameter
                            ),
                            props.context.parameterAssignment
                        ),
                [
                    props.value,
                    props.context,
                ]
            );
        const textType =
            useMemo(
                () =>
                    new PrimitiveValueType(DataObject.getTypeById('Text')),
                []
            );
        const [ view, setView ] = useState<View | undefined>(undefined);
        useEffect(
            () =>
            {
                if (view)
                {
                    runInAction(
                        () =>
                        {
                            view.parameter.id = uuid();
                            props.value.fields = [];
                            props.value.query.filter = undefined;
                            props.value.query.orderings = [];
                            props.value.query.parameter = view.parameter;
                            props.value.query.filter = view.filter;

                            if (view.specification.list)
                            {
                                props.value.fields =
                                    view.specification.list.columns
                                        .map(
                                            column =>
                                                new QueryFileExportField(
                                                    uuid(),
                                                    new TextComputation(
                                                        column.fieldPath.getName(),
                                                        false,
                                                        []
                                                    ),
                                                    new ValueFromEntityComputation(
                                                        props.value.query.parameter,
                                                        column.fieldPath
                                                    )
                                                )
                                        );
                            }
                        }
                    );
                }
            },
            [
                view,
            ]
        );

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <Input
                label={
                    <LocalizedText
                        code="Generic.Query"
                        value="Query"
                    />
                }
                labelPosition="left"
            >
                <Link
                    onClick={openDialog}
                    highlighted
                >
                    {props.value.query?.entityType.getName(!isSingular) || '...'}
                </Link>
            </Input>
            {
                props.value.query &&
                <Input
                    label={
                        <>
                            <LocalizedText
                                code="Generic.UseView"
                                value="Weergave gebruiken"
                            />...
                        </>
                    }
                    labelPosition="left"
                >
                    <ViewSelector
                        entityType={props.value.query.entityType}
                        view={view}
                        onChange={setView}
                    />
                </Input>
            }
            {
                props.value.query &&
                <Input
                    label={
                        <LocalizedText
                            code="Generic.FileName"
                            value="Bestandsnaam"
                        />
                    }
                    labelPosition="left"
                >
                    <ComputationBox>
                        <ComputationEditor
                            context={props.context}
                            value={props.value.fileName}
                            onChange={setFileName}
                            type={textType}
                        />
                    </ComputationBox>
                </Input>
            }
            {
                props.value.query &&
                <Input
                    label="Fields"
                    labelPosition="left"
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <LocalizedText
                                        code="Generic.Name"
                                        value="Naam"
                                    />
                                </TableCell>
                                <TableCell>
                                    <LocalizedText
                                        code="Generic.Value"
                                        value="Waarde"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.value.fields.map(
                                    field =>
                                        <TableRow
                                            key={field.id}
                                        >
                                            <TableCell>
                                                <ComputationEditor
                                                    context={props.context}
                                                    value={field.name}
                                                    onChange={
                                                        value =>
                                                            runInAction(
                                                                () =>
                                                                    field.name = value ?? EmptyValue.instance
                                                            )
                                                    }
                                                    type={textType}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <ComputationEditor
                                                    context={contextWithItem}
                                                    value={field.value}
                                                    onChange={
                                                        value =>
                                                            runInAction(
                                                                () =>
                                                                    field.value = value ?? EmptyValue.instance
                                                            )
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                )
                            }
                            <TableRow>
                                <TableCell
                                    colSpan={2}
                                    align="center"
                                >
                                    <AddButton
                                        label={
                                            <LocalizedText
                                                code="Generic.Field"
                                                value="Veld"
                                            />
                                        }
                                        onClick={
                                            () =>
                                                runInAction(
                                                    () =>
                                                        props.value.fields.push(
                                                            new QueryFileExportField(
                                                                uuid(),
                                                                new TextComputation(
                                                                    '...',
                                                                    false,
                                                                    []
                                                                ),
                                                                EmptyValue.instance
                                                            )
                                                        )
                                                )
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Input>
            }
        </ViewGroup>;
    };

export default observer(QueryFileExportComputationEditor);
