import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import HeaderWithScrollBodyView from './View/HeaderWithScrollBodyView';
import HeaderWithScrollBodyLayout from '../../../../../@Api/Layout/Type/HeaderWithScrollBodyLayout';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import Layout from '../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';

export interface HeaderWithScrollBodyLayoutEditorProps extends LayoutEditorProps<HeaderWithScrollBodyLayout>
{

}

const HeaderWithScrollBodyLayoutEditor: React.FC<HeaderWithScrollBodyLayoutEditorProps> =
    props =>
    {
        const setHeader =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.header = layout),
                [
                    props.layout
                ]);

        const setBody =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.body = layout),
                [
                    props.layout
                ]);

        return <HeaderWithScrollBodyView
            header={
                <ChildLayoutEditor
                    {...props}
                    childLayout={props.layout.header}
                    onChange={setHeader}
                />
            }
            body={
                <ChildLayoutEditor
                    {...props}
                    childLayout={props.layout.body}
                    onChange={setBody}
                />
            }
        />;
    };

export default observer(HeaderWithScrollBodyLayoutEditor);
