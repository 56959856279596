import React from 'react';
import { observer } from 'mobx-react-lite';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import TypeSelector from './TypeSelector/TypeSelector';
import { Field } from '../Model/Field';
import Options from './Options/Options';
import styles from './FieldItem.module.scss';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import { TransactionalModel } from '../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { EntityFieldPath } from '../../../../../Entity/Path/@Model/EntityFieldPath';
import CardinalitySelector from './CardinalitySelector/CardinalitySelector';
import NameEditor from './NameEditor/NameEditor';
import InverseNameEditor from './InverseNameEditor/InverseNameEditor';
import CodeEditor from './CodeEditor/CodeEditor';
import PackEditor from './PackEditor/PackEditor';
import FilterEditor from './FilterEditor/FilterEditor';
import IsVisibleInDetailsEditor from './IsVisibleInDetailsEditor/IsVisibleInDetailsEditor';
import IsVisibleAsTabEditor from './IsVisibleAsTabEditor/IsVisibleAsTabEditor';
import IsVisibleDuringConstructionEditor from './IsVisibleDuringConstructionEditor/IsVisibleDuringConstructionEditor';

export interface FieldItemProps
{
    entityType: EntityType;
    field: TransactionalModel<Field>;
    onAddField: (fieldPath: EntityFieldPath) => void;
    onCancel: (field: Field) => void;
    showPackSelector: boolean;
}

const FieldItem: React.FC<FieldItemProps> =
    props =>
    {
        return <TableRow
            className={classNames(styles.root, props.field.isEditable && styles.editable)}
        >
            <TableCell>
                <TypeSelector
                    {...props}
                />
            </TableCell>
            <TableCell>
                <CardinalitySelector
                    {...props}
                />
            </TableCell>
            <TableCell>
                <NameEditor
                    {...props}
                />
            </TableCell>
            <TableCell>
                <InverseNameEditor
                    {...props}
                />
            </TableCell>
            <TableCell>
                <IsVisibleDuringConstructionEditor
                    {...props}
                />
            </TableCell>
            <TableCell>
                <IsVisibleInDetailsEditor
                    {...props}
                />
            </TableCell>
            <TableCell>
                <IsVisibleAsTabEditor
                    {...props}
                />
            </TableCell>
            <TableCell>
                <CodeEditor
                    {...props}
                />
            </TableCell>
            {
                props.showPackSelector &&
                    <TableCell>
                        <PackEditor
                            {...props}
                        />
                    </TableCell>
            }
            <TableCell>
                <FilterEditor
                    entityType={props.entityType}
                    fieldPath={props.field.fieldPath}
                />
            </TableCell>
            <TableCell>
                <Options
                    {...props}
                />
            </TableCell>
        </TableRow>;
    };

export default observer(FieldItem);
