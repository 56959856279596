import RouteMatcher from 'route-parser';
import { observable } from 'mobx';
import { PageStore } from '../../Navigation/Page/PageStore';

export type RouteType = 'Public' | 'App' | 'Portal';

export class Route
{
    // ------------------------- Properties -------------------------

    @observable path: string;
    @observable isExact: boolean;
    @observable type: RouteType;
    @observable instantiateStore: (parameters: any) => Promise<PageStore | undefined>;
    @observable matcher: RouteMatcher;

    // ------------------------ Constructor -------------------------

    constructor(path: string,
                isExact: boolean,
                type: RouteType,
                instantiateStore: (parameters: any) => Promise<PageStore | undefined>)
    {
        this.path = path;
        this.isExact = isExact;
        this.type = type;
        this.instantiateStore = instantiateStore;
        this.matcher = new RouteMatcher(this.path);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    matches(path: string): boolean
    {
        return this.matcher.match(path) !== false;
    }

    parameters(path: string): any
    {
        const params = this.matcher.match(path);

        return params === false ? {} : params;
    }

    // ----------------------- Private logic ------------------------
}
