import { DependencyList, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';

export default function useDebouncedCallback(
    callback: any,
    deps: DependencyList,
    delay: number
)
{
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memorizedCallback = useCallback(callback, deps);

    return useMemo(
        () =>
            debounce(memorizedCallback, delay),
        [
            memorizedCallback,
            delay
        ]
    );
}
