import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { default as DashboardModel } from './Model/Dashboard';
import Section from './Section/Section';
import { default as SectionModel } from './Model/Section';
import { default as ColumnModel } from './Model/Column';
import { DropResult } from 'react-beautiful-dnd';
import { runInAction } from 'mobx';
import useOnDragEnd from '../../DragAndDrop/Api/useOnDragEnd';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import styles from './Dashboard.module.scss';
import DashboardContext from './Context/DashboardContext';
import useMultiplayerViewingEntity from '../../Multiplayer/Api/useMultiplayerViewingEntity';
import Centered from '../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../@Future/Component/Generic/Loader/Loader';
import useAsyncResult from '../../../../@Util/Async/useAsyncResult';
import uuid from '../../../../@Util/Id/uuid';

export interface DashboardProps
{
    dashboard: DashboardModel;
    disabled?: boolean;
}

const Dashboard: React.FC<DashboardProps> =
    props =>
    {
        useMultiplayerViewingEntity(props.dashboard.entity);

        const onDrop =
            useCallback(
                (dropResult: DropResult) =>
                {
                    if (props.disabled)
                    {
                        return;
                    }

                    runInAction(
                        () =>
                        {
                            if (dropResult.destination)
                            {
                                const widget = props.dashboard.widgetById.get(dropResult.draggableId);
                                const sourceColumn = props.dashboard.columnById.get(dropResult.source.droppableId);
                                let targetColumn = props.dashboard.columnById.get(dropResult.destination.droppableId);

                                if (sourceColumn !== targetColumn || dropResult.source.index !== dropResult.destination.index)
                                {
                                    const targetSection = props.dashboard.sections
                                        .find(
                                            section =>
                                                section.columns.some(
                                                    column =>
                                                        column.id === (dropResult.destination.droppableId
                                                        )
                                                )
                                        );

                                    sourceColumn.widgets.splice(dropResult.source.index, 1);
                                    if (targetSection.isFullWidth)
                                    {
                                        // Create new section
                                        targetColumn = new ColumnModel(
                                            uuid(),
                                            [widget]
                                        );

                                        if (widget.isFullWidth())
                                        {
                                            props.dashboard.sections.unshift(
                                                new SectionModel(
                                                    uuid(),
                                                    [targetColumn]
                                                )
                                            );
                                        }
                                        else
                                        {
                                            props.dashboard.sections.unshift(
                                                new SectionModel(
                                                    uuid(),
                                                    [
                                                        targetColumn,
                                                        new ColumnModel(uuid(), []),
                                                        new ColumnModel(uuid(), []),
                                                    ]
                                                )
                                            );

                                        }
                                    }
                                    else
                                    {
                                        targetColumn.widgets.splice(
                                            dropResult.destination.index,
                                            0,
                                            widget
                                        );
                                    }

                                    // Clear empty sections
                                    props.dashboard.sections = props.dashboard.sections
                                        .filter(
                                            section =>
                                                section.columns.reduce(
                                                    (count, column) => count + column.widgets.length, 0) !== 0
                                        );

                                    if (props.dashboard.sections.length === 0)
                                    {
                                        props.dashboard.sections.unshift(
                                            new SectionModel(
                                                uuid(),
                                                [
                                                    new ColumnModel(
                                                        uuid(),
                                                        [])
                                                ]));
                                    }
                                }
                            }
                        });
                },
                [
                    props.dashboard,
                    props.disabled
                ]);

        useOnDragEnd(onDrop);

        const [ dispose ] =
            useAsyncResult(
                async () => ({
                    dispose: await props.dashboard.initialize()
                }),
                [
                    props.dashboard
                ]);

        useEffect(
            () =>
            {
                if (dispose)
                {
                    return () => dispose.dispose();
                }
            },
            [
                dispose
            ]);

        if (!props.dashboard.isInitialized)
        {
            return <Centered
                horizontal
                vertical
            >
                <Loader />
            </Centered>;
        }
        else
        {
            return <DashboardContext.Provider
                value={props.dashboard}
            >
                <div
                    className={styles.root}
                >
                    <div
                        className={styles.body}
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                            className={styles.sections}
                        >
                            {
                                props.dashboard.sections.map(
                                    (section, idx) =>
                                        <ViewGroupItem
                                            key={section.id}
                                            ratio={idx === props.dashboard.sections.length - 1 ? 1 : 0}
                                        >
                                            <Section
                                                section={section}
                                                lastSection={idx === props.dashboard.sections.length - 1}
                                                disabled={props.disabled}
                                            />
                                        </ViewGroupItem>)
                            }
                        </ViewGroup>
                    </div>
                </div>
            </DashboardContext.Provider>;
        }
    };

export default observer(Dashboard);
