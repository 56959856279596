import * as React from 'react';
import { DataObjectSpecificationEditorForm } from '../../../Editor/Specification/DataObjectSpecificationEditorForm';

export class DateIntervalSpecification extends DataObjectSpecificationEditorForm
{
    render()
    {
        return <div />;
    }
}
