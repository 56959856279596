import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Editor from '../../../../DataObject/Editor/Editor';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import useLocalPrimitiveSetting from '../../../../Setting/Api/useLocalPrimitiveSetting';
import { FilterOptionProps } from './FilterOption';

export interface PrimitiveFilterOptionProps extends FilterOptionProps
{

}

const PrimitiveFilterOption: React.FC<PrimitiveFilterOptionProps> =
    props =>
    {
        const dataObject =
            useComputed(
                () =>
                    props.filterOption.value as DataObject,
                [
                    props.filterOption.value
                ]);
        const [ , setSetting ] =
            useLocalPrimitiveSetting(
                props.filterOption.getFilterOptionSettingId(props.filterOptionId));

        if (dataObject)
        {
            return <Editor
                dataObject={dataObject}
                onChange={setSetting}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(PrimitiveFilterOption);
