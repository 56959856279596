import ComparisonPredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import { EntityConstraintBuilderFilterContext } from '../Model/EntityConstraintBuilderFilterContext';
import { EntityConstraintBuilderNodeAndField } from '../Model/EntityConstraintBuilderNodeAndField';
import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import ValueFromEntityComputation from '../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../../../../DataObject/Model/Comparator';
import EntityValue from '../../../../../../../@Api/Automation/Value/EntityValue';
import { isResultParameter } from './isResultParameter';
import { getEntityNodeAndFieldByParameterAndFieldPath } from './getEntityNodeAndFieldByParameterAndFieldPath';

export function getLhsNodeAndFieldAndAugmentContext(
    filter: ComparisonPredicate,
    context: EntityConstraintBuilderFilterContext
): EntityConstraintBuilderNodeAndField | undefined
{
    const lhs = filter.lhs;
    const rhs = filter.rhs;

    if (lhs instanceof Parameter
        && lhs.type instanceof EntityValueType
        && isResultParameter(lhs, context))
    {
        return getEntityNodeAndFieldByParameterAndFieldPath(
            lhs,
            EntityPath.fromEntityType(lhs.type.type).field(),
            context
        );
    }
    else if (lhs instanceof ValueFromEntityComputation
        && lhs.entity instanceof Parameter
        && lhs.entity.type instanceof EntityValueType
        && lhs.fieldPath
        && isResultParameter(lhs.entity, context))
    {
        const pathToJoin = lhs.fieldPath.path.rootedAt(context.builder.selectionBuilder.entityType);

        if (pathToJoin.isPlural
            && filter.comparator === Comparator.Contains
            && rhs instanceof EntityValue
            && context.isInAnd)
        {
            return getEntityNodeAndFieldByParameterAndFieldPath(
                lhs.entity,
                lhs.fieldPath,
                context,
                sb =>
                    `${pathToJoin.id}.${sb.selection.entityNodes.length}`
            );
        }
        else
        {
            return getEntityNodeAndFieldByParameterAndFieldPath(
                lhs.entity,
                lhs.fieldPath,
                context
            );
        }
    }
    else
    {
        return undefined;
    }
}
