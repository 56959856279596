import AutomationContext from '../AutomationContext';
import ParameterAssignment from '../Parameter/ParameterAssignment';
import Parameter from '../Parameter/Parameter';
import ParameterDictionary from '../Parameter/ParameterDictionary';
import { CommitContext } from '../../Entity/Commit/Context/CommitContext';

export default class FunctionContext extends AutomationContext
{
    // ------------------------- Properties -------------------------

    parameterAssignment: ParameterAssignment;
    commitContext: CommitContext;

    // ------------------------ Constructor -------------------------

    constructor(parameterDictionary: ParameterDictionary,
                parameterAssignment: ParameterAssignment = new ParameterAssignment(),
                commitContext?: CommitContext)
    {
        super(parameterDictionary);

        this.parameterAssignment = parameterAssignment;
        this.commitContext = commitContext;
    }


    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getObjectValue(parameter: Parameter<any>)
    {
        return this.parameterAssignment.getObjectValue(parameter);
    }

    clone()
    {
        return new FunctionContext(
            new ParameterDictionary(this.parameterDictionary.parameters),
            new ParameterAssignment(
                this.parameterAssignment.valueByParameter,
                this.parameterAssignment.parentAssignment),
            this.commitContext
        );
    }

    // ----------------------- Private logic ------------------------
}
