import React from 'react';
import { observer } from 'mobx-react-lite';
import { Draggable as DraggableDnd } from 'react-beautiful-dnd';
import useIsDropped from './Api/useIsDropped';
import styles from './Draggable.module.scss';
import { classNames } from '../../../../@Future/Util/Class/classNames';
import DragHandlePropsContext from '../Context/DragHandlePropsContext';
import DraggableStateContext from '../Context/DraggableStateContext';

export interface DraggableProps
{
    id: string;
    index: number;
    disabled?: boolean;
    handleOnContainer?: boolean;
    type?: string;
}

const Draggable: React.FC<DraggableProps> =
    (props) =>
    {
        const isDropped = useIsDropped(props.id);

        if (props.disabled)
        {
            return <>{props.children}</>;
        }
        else
        {
            return <DraggableDnd
                draggableId={props.id}
                index={props.index}
                // type={props.type}
            >
                {(provided, snapshot) =>
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...props.handleOnContainer ? provided.dragHandleProps : {}}
                        style={{ ...provided.draggableProps.style }}
                        className={classNames(snapshot.isDragging && styles.dragging, isDropped && styles.dropped)}
                    >
                        <DragHandlePropsContext.Provider
                            value={provided.dragHandleProps}
                        >
                            <DraggableStateContext.Provider
                                value={snapshot}
                            >
                                {props.children}
                            </DraggableStateContext.Provider>
                        </DragHandlePropsContext.Provider>
                    </div>}
            </DraggableDnd>;
        }
    };

export default observer(Draggable);
