import { observable } from 'mobx';
import Pointer from './Pointer';

export default class Connection
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable userId: number;
    @observable entityId: number;
    @observable viewingEntityId?: number;
    @observable focusPointer?: Pointer;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                userId: number,
                entityId: number,
                viewingEntityId: number,
                focusPointer: Pointer)
    {
        this.id = id;
        this.userId = userId;
        this.entityId = entityId;
        this.viewingEntityId = viewingEntityId;
        this.focusPointer = focusPointer;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new Connection(
            descriptor.id,
            descriptor.userId,
            descriptor.entityId,
            descriptor.viewingEntityId,
            descriptor.focusPointer
                ?
                    Pointer.fromDescriptor(descriptor.focusPointer)
                :
                    undefined);
    }

    toDescriptor()
    {
        return {
            id: this.id,
            userId: this.userId,
            entityId: this.entityId,
            viewingEntityId: this.viewingEntityId,
            focusPointer:
                this.focusPointer
                    ?
                        this.focusPointer.toDescriptor()
                    :
                        undefined
        };
    }

    // ----------------------- Private logic ------------------------
}
