import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';

export function isTimeRegistrationVisible(
    timeRegistration: Entity,
    filter?: (timeRegistration: Entity) => boolean
)
{
    return !timeRegistration.isDeleted
        && (filter === undefined ? true : filter(timeRegistration));
}
