import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import MultiplayerContext from '../Context/MultiplayerContext';
import styles from './PlayerList.module.scss';
import Player from './Player/Player';

export interface PlayerListProps
{
}

const PlayerList: React.FC<PlayerListProps> =
    () =>
    {
        const multiplayer = useContext(MultiplayerContext);

        const players =
            useComputed(
                () =>
                {
                    if (multiplayer && multiplayer.connection.viewingEntityId)
                    {
                        // Do not show duplicate user badges
                        const visitedUserIds = new Set<number>();

                        return multiplayer
                            .room
                            .connections
                            .filter(
                                connection =>
                                {
                                    if (visitedUserIds.has(connection.entityId))
                                    {
                                        return false;
                                    }
                                    else
                                    {
                                        if (connection.viewingEntityId === multiplayer.connection.viewingEntityId)
                                        {
                                            visitedUserIds.add(connection.entityId);

                                            return true;
                                        }
                                        else
                                        {
                                            return false;
                                        }
                                    }
                                });
                    }
                    else
                    {
                        return [];
                    }
                },
                [
                    multiplayer
                ]);

        if (players.length < 2)
        {
            return null;
        }
        else
        {
            return <div
                className={styles.root}
            >
                {
                    players.map(
                        player =>
                            <Player
                                key={player.id}
                                connection={player}
                            />)
                }
            </div>;
        }
    };

export default observer(PlayerList);
