import { EntityEvent } from '../../../../../../@Api/Model/Implementation/EntityEvent';
import { EntityValueMutation } from '../../../../../../@Api/Model/Implementation/EntityValueMutation';
import { EntityRelationshipMutation } from '../../../../../../@Api/Model/Implementation/EntityRelationshipMutation';

export default function isSystemEvent(event: EntityEvent)
{
    if (event instanceof EntityValueMutation)
    {
        return event.entityField.isComputedField;
    }
    else if (event instanceof EntityRelationshipMutation)
    {
        return event.entityRelationshipDefinition.isComputed(event.isParentRelationship);
    }
    else
    {
        return false;
    }
}