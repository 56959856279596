import React from 'react';
import ViewGroup, { ViewGroupProps } from '../../ViewGroup/ViewGroup';
import useIsMobile from '../../../../../@Util/Responsiveness/useIsMobile';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';

export interface ButtonGroupProps extends Partial<ViewGroupProps>
{
    noWrap?: boolean;
}

const ButtonGroup: React.FC<ButtonGroupProps> =
    props =>
    {
        const isMobile = useIsMobile();

        const buttons: any[] =
            React.Children.toArray(props.children)
                .filter(
                    child =>
                        child)
                .map(
                    (child: any) =>
                        React.cloneElement(
                            child,
                            {
                                ...child.props,
                                fullWidth: isMobile
                            }));

        return <ViewGroup
            {...props}
            orientation={isMobile ? 'vertical' : 'horizontal'}
            spacing={isMobile ? 10 : 15}
            wrap={!props.noWrap}
        >
            {
                buttons.map(
                    button =>
                        <ViewGroupItem
                            key={button.key}
                        >
                            {button}
                        </ViewGroupItem>)
            }
        </ViewGroup>;
    };

export default ButtonGroup;
