import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import PortalUserButtonLayout from '../../../../../@Api/Layout/Type/PortalUserButtonLayout';
import PortalEnvironmentButton from '../../../../App/Root/Environment/Portal/Header/PortalEnvironmentButton/PortalEnvironmentButton';

export interface PortalButtonLayoutViewerProps extends LayoutViewerProps<PortalUserButtonLayout>
{

}

const PortalUserButtonLayoutViewer: React.FC<PortalButtonLayoutViewerProps> =
    props =>
    {
        return <PortalEnvironmentButton />;
    };

export default observer(PortalUserButtonLayoutViewer);
