import { PredicateEditorStore } from '../../PredicateEditorStore';
import { action, computed, observable, reaction } from 'mobx';
import { PredicateContext } from '../../PredicateContext';
import { PredicateType } from '../PredicateType';
import { PredicateSpecification } from '../PredicateSpecification';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { ComputationEditorStore } from '../../../Computation/ComputationEditorStore';
import { Comparator } from '../../../DataObject/Model/Comparator';
import { ComputationType } from '../../../Computation/Type/ComputationType';
import { ComputationTypeStore } from '../../../Computation/ComputationTypeStore';

export class ComparisonPredicateEditorStore extends PredicateEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;

    // ------------------------- Properties -------------------------

    @observable lhs: ComputationEditorStore;
    @observable comparator: Comparator;
    @observable rhs: ComputationEditorStore;
    @observable isLhsAddMenuOpen: boolean;
    @observable isComparatorMenuOpen: boolean;
    @observable isRhsAddMenuOpen: boolean;

    // ------------------------ Constructor -------------------------

    constructor(type: PredicateType,
                context: PredicateContext,
                specification: PredicateSpecification,
                lhs: ComputationEditorStore,
                comparator: Comparator,
                rhs: ComputationEditorStore)
    {
        super(type, context, specification);

        this.lhs = lhs;
        this.comparator = comparator;
        this.rhs = rhs;
        this.isLhsAddMenuOpen = lhs == null;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get hasLhs(): boolean
    {
        return this.lhs != null;
    }

    @computed
    get hasRhs(): boolean
    {
        return this.rhs != null;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    openLhsAddMenu()
    {
        this.isLhsAddMenuOpen = true;
    }

    @action.bound
    closeLhsAddMenu()
    {
        this.isLhsAddMenuOpen = false;
    }

    @action.bound
    addLhsComputation(type: ComputationType)
    {
        this.lhs = ComputationEditorStore.construct(
            this.context,
            { type: type.id() },
            this.computationTypeStore);

        reaction(
            () => this.lhs && this.lhs.type.editorResultSpecification(this.lhs.editorStore),
            () =>
            {
                this.deleteRhsComputation();
            });
    }

    @action.bound
    deleteLhsComputation()
    {
        this.lhs = null;
        this.isLhsAddMenuOpen = true;
    }

    @action.bound
    openComparatorMenu()
    {
        this.isComparatorMenuOpen = true;
    }

    @action.bound
    closeComparatorMenu()
    {
        this.isComparatorMenuOpen = false;
    }

    @action.bound
    setComparator(comparator: Comparator)
    {
        this.comparator = comparator;
    }

    @action.bound
    openRhsAddMenu()
    {
        this.isRhsAddMenuOpen = true;
    }

    @action.bound
    closeRhsAddMenu()
    {
        this.isRhsAddMenuOpen = false;
    }

    @action.bound
    addRhsComputation(type: ComputationType)
    {
        this.rhs = ComputationEditorStore.construct(
            {
                resultSpecification: this.lhs.type.editorResultSpecification(this.lhs.editorStore),
                ...this.context
            },
            { type: type.id() },
            this.computationTypeStore);
    }

    @action.bound
    deleteRhsComputation(openRhsMenu: boolean = true)
    {
        this.rhs = null;
        this.isRhsAddMenuOpen = openRhsMenu;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
