import { type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';
import { EntityEventFilter } from './EntityEventFilter';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';

@type('EntityReadMutationFilter')
export class EntityReadMutationFilter extends EntityEventFilter
{
    // ------------------- Persistent Properties --------------------

    @observable isPublic: boolean;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityPath: EntityPath,
                isPublic: boolean = false)
    {
        super(entityPath);

        this.isPublic = isPublic;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    descriptor(): any
    {
        return {
            type: 'EntityReadMutationFilter',
            entityPath: (this.entityPath as any).descriptor,
            isPublic: this.isPublic
        };
    }

    // ----------------------- Private logic ------------------------
}
