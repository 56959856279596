import Widget from '../Widget';
import AgendaWidget from '../Widget/Agenda/AgendaWidget';
import CalendarWidget from '../Widget/Calendar/CalendarWidget';
import DatasetWidget from '../Widget/Dataset/DatasetWidget';
import NoteWidget from '../Widget/Note/NoteWidget';
import EntityWidget from '../Widget/Entity/EntityWidget';
import WebsiteWidget from '../Widget/Website/WebsiteWidget';
import FileWidget from '../Widget/File/FileWidget';
import ResourcePlannerWidget from '../Widget/ResourcePlanner/ResourcePlannerWidget';
import EmployeePlannerWidget from '../Widget/EmployeePlanner/EmployeePlannerWidget';
import ProjectPlannerWidget from '../Widget/ProjectPlanner/ProjectPlannerWidget';
import ProjectTimesheetWidget from '../Widget/ProjectTimesheet/ProjectTimesheetWidget';
import MyProjectsTimesheetWidget from '../Widget/MyProjectsTimesheet/MyProjectsTimesheetWidget';
import EmployeeTimesheetWidget from '../Widget/EmployeeTimesheet/EmployeeTimesheetWidget';
import EmployeeBillabilityWidget from '../Widget/EmployeeBillability/EmployeeBillabilityWidget';
import ViewsWidget from '../Widget/Views/ViewsWidget';
import CustomWidget from '../Widget/Custom/CustomWidget';
import EmployeeAllocationWidget from '../Widget/EmployeeAllocation/EmployeeAllocationWidget';
import ProjectAllocationWidget from '../Widget/ProjectAllocation/ProjectAllocationWidget';
import MyTimesheetWidget from '../Widget/MyTimesheet/MyTimesheetWidget';
import MyProjectHoursTimesheetWidget from '../Widget/MyProjectHoursTimesheet/MyProjectHoursTimesheetWidget';
import MyProjectAllocationWidget from '../Widget/MyProjectAllocation/MyProjectAllocationWidget';
import { MyProjectTimesheetByTimeRegistrationWidget } from '../Widget/MyProjectTimesheetByTimeRegistration/MyProjectTimesheetByTimeRegistrationWidget';
import { FullscreenAgendaWidgetModel } from '../Widget/FullscreenAgenda/FullscreenAgendaWidgetModel';

export default function fromDescriptor(descriptor: any): Widget
{
    switch (descriptor.type)
    {
        case 'Dataset':
            return DatasetWidget.fromDescriptor(descriptor);

        case 'Agenda':
            return AgendaWidget.fromDescriptor(descriptor);

        case 'FullscreenAgenda':
            return FullscreenAgendaWidgetModel.fromDescriptor(descriptor);

        case 'Calendar':
            return CalendarWidget.fromDescriptor(descriptor);

        case 'ResourcePlanner':
            return ResourcePlannerWidget.fromDescriptor(descriptor);

        case 'EmployeePlanner':
            return EmployeePlannerWidget.fromDescriptor(descriptor);

        case 'EmployeeTimesheet':
            return EmployeeTimesheetWidget.fromDescriptor(descriptor);

        case 'MyTimesheet':
            return MyTimesheetWidget.fromDescriptor(descriptor);

        case 'EmployeeBillability':
            return EmployeeBillabilityWidget.fromDescriptor(descriptor);

        case 'EmployeeAllocation':
            return EmployeeAllocationWidget.fromDescriptor(descriptor);

        case 'ProjectPlanner':
            return ProjectPlannerWidget.fromDescriptor(descriptor);

        case 'ProjectAllocation':
            return ProjectAllocationWidget.fromDescriptor(descriptor);

        case 'MyProjectAllocation':
            return MyProjectAllocationWidget.fromDescriptor(descriptor);

        case 'ProjectTimesheet':
            return ProjectTimesheetWidget.fromDescriptor(descriptor);

        case 'MyProjectTimesheet':
            return MyProjectsTimesheetWidget.fromDescriptor(descriptor);

        case 'MyProjectHoursTimesheet':
            return MyProjectHoursTimesheetWidget.fromDescriptor(descriptor);

        case 'MyProjectTimesheetByTimeRegistration':
            return MyProjectTimesheetByTimeRegistrationWidget.fromDescriptor(descriptor);

        case 'Note':
            return NoteWidget.fromDescriptor(descriptor);

        case 'File':
            return FileWidget.fromDescriptor(descriptor);

        case 'Website':
            return WebsiteWidget.fromDescriptor(descriptor);

        case 'Entity':
            return EntityWidget.fromDescriptor(descriptor);

        case 'Views':
            return ViewsWidget.fromDescriptor(descriptor);

        case 'Custom':
            return CustomWidget.fromDescriptor(descriptor);
    }

    return new NoteWidget(descriptor.id, '');
}
