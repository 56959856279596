import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import ClickLayout from '../../../../../@Api/Layout/Type/ClickLayout';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import Layout from '../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';

export interface ClickLayoutEditorProps extends LayoutEditorProps<ClickLayout>
{

}

const ClickLayoutEditor: React.FC<ClickLayoutEditorProps> =
    props =>
    {
        const setLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.layout = layout),
                [
                    props.layout
                ]);

        return <ChildLayoutEditor
            {...props}
            childLayout={props.layout.layout}
            onChange={setLayout}
        />;
    };

export default observer(ClickLayoutEditor);
