import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../../../../../@Future/Component/Generic/Menu/Item/Item';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import copy from 'copy-to-clipboard';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';

export interface IndexProps
{
    index: number;
    sourceInstanceId?: string;
    onSelect: (index: number) => void;
}

const Index: React.FC<IndexProps> =
    props =>
    {
        const open =
            useCallback(
                () =>
                    props.onSelect(props.index),
                [
                    props.onSelect,
                    props.index
                ]);

        const copyToClipboard =
            useCallback(
                () =>
                {
                    copy(props.sourceInstanceId);

                    loadModuleDirectly(FeedbackStore)
                        .enqueueSnackbar(
                            localizeText(
                                'Generic.CopiedToClipboard',
                                'Gekopieerd naar clipboard'));
                },
                [
                    props.sourceInstanceId
                ]);

        const name =
            useMemo(
                () =>
                    props.sourceInstanceId
                        ? localizeText('Import.Summary.SourceInstance', 'Entiteit #${id}', {id: props.sourceInstanceId})
                        : localizeText('Import.Summary.LineIndex', 'Regel #${index}', {index: props.index + 1 + ''}),
                [
                    props.sourceInstanceId,
                    props.index,
                ]);

        return <Item
            name={name}
            onClick={
                props.sourceInstanceId
                    ? copyToClipboard
                    : open
            }
        />;
    };

export default observer(Index);
