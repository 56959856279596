import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './TypeAndName.module.scss';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import Name from '../../../Item/Name/Name';
import TypeName from '../TypeName/TypeName';

export interface TypeAndNameProps
{
    entity: Entity;
    readonly?: boolean;
}

const TypeAndName: React.FC<TypeAndNameProps> =
    props =>
    {
        return <div
            className={styles.root}
        >
            <TypeName
                entity={props.entity}
            />
            <div
                className={styles.name}
            >
                <Name
                    entity={props.entity}
                    editable={!props.readonly}
                />
            </div>
        </div>;
    };

export default observer(TypeAndName);
