import { reference } from '../../../@Util/Serialization/Serialization';
import { EntityType } from './EntityType';
import { EntityNode } from './EntityNode';
import { EntityPathCastNode } from '../../../@Component/Domain/Entity/Path/@Model/Node/EntityPathCastNode';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { observable } from 'mobx';

export class CastNode extends EntityNode
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'EntityNode') @observable.ref castEntityNode: EntityNode;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType,
                alias: string,
                castEntityNode: EntityNode)
    {
        super(entityType, alias);

        this.castEntityNode = castEntityNode;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    equals(node: CastNode): boolean
    {
        return super.equals(node)
            && (this.castEntityNode && this.castEntityNode.alias) === (node.castEntityNode && node.castEntityNode.alias);
    }

    entityPath(): EntityPath
    {
        return this.castEntityNode.entityPath()
            .joinNode(
                new EntityPathCastNode(this.entityType));
    }

    descriptor(): any
    {
        return {
            type: 'Cast',
            alias: this.alias,
            entityTypeId: this.entityType.id,
            entityTypeCode: this.entityType.code,
            castEntityNodeAlias: this.castEntityNode.alias
        };
    }

    // ----------------------- Private logic ------------------------
}
