import TypeMapping from '../Model/TypeMapping';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import ValueFieldMapping from '../Model/FieldMapping/Constant/ValueFieldMapping';
import getEntityByUuid from '../../../../../@Api/Entity/Bespoke/getEntityByUuid';
import { DataObject } from '../../../DataObject/Model/DataObject';
import RelationshipFieldMapping from '../Model/FieldMapping/Relationship/RelationshipFieldMapping';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import EntityFieldMapping from '../Model/FieldMapping/Constant/EntityFieldMapping';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { constructEntityOfType } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { setValueByFieldInEntity } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';

export default function getEntityByTypeMapping(
    typeMapping: TypeMapping,
    entityByTypeMapping: Map<TypeMapping, Entity> = new Map(),
    commitContext: CommitContext
)
{
    if (entityByTypeMapping.has(typeMapping))
    {
        return entityByTypeMapping.get(typeMapping);
    }

    const entityTypeStore = loadModuleDirectly(EntityTypeStore);
    const currentUserStore = loadModuleDirectly(CurrentUserStore);

    const entity =
        constructEntityOfType(
            typeMapping.entityType,
            commitContext,
            {
                disableLocalAutomations: true,
            }
        );

    updateRelationship(
        entity,
        true,
        entityTypeStore.bespoke.types.Pack.RelationshipDefinition.Entities,
        currentUserStore.environmentPackEntity,
        commitContext
    );

    entityByTypeMapping.set(typeMapping, entity);

    typeMapping
        .fieldMappings
        .forEach(
            fieldMapping =>
            {
                if (fieldMapping instanceof ValueFieldMapping)
                {
                    if (fieldMapping.value)
                    {
                        setValueByFieldInEntity(
                            entity,
                            fieldMapping.targetFieldPath.field,
                            fieldMapping.value ? (fieldMapping.value as DataObject).value : undefined,
                            commitContext
                        );
                    }
                }
                else if (fieldMapping instanceof EntityFieldMapping)
                {
                    if (fieldMapping.entityId)
                    {
                        getEntityByUuid(fieldMapping.targetFieldPath.path.entityType, fieldMapping.entityId)
                            .then(
                                ({ value: relatedEntity }) =>
                                {
                                    updateRelationship(
                                        entity,
                                        fieldMapping.targetFieldPath.isParentRelationship,
                                        fieldMapping.targetFieldPath.relationshipDefinition,
                                        relatedEntity,
                                        commitContext
                                    );
                                });
                    }
                }
                else if (fieldMapping instanceof RelationshipFieldMapping)
                {
                    updateRelationship(
                        entity,
                        fieldMapping.targetFieldPath.isParentRelationship,
                        fieldMapping.targetFieldPath.relationshipDefinition,
                        getEntityByTypeMapping(
                            fieldMapping.typeMapping,
                            entityByTypeMapping,
                            commitContext
                        ),
                        commitContext
                    );
                }
            });

    return entity;
}
