import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import getLayoutFromDescriptor from '../../../../../../../../@Api/Layout/Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../../../../@Api/Layout/LayoutDependencyContext';
import ParameterDictionary from '../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import LayoutEditor from '../../../../../../Layout/Editor/LayoutEditor';
import ParameterAssignment from '../../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import EntityValue from '../../../../../../../../@Api/Automation/Value/EntityValue';
import CompositeLayout from '../../../../../../../../@Api/Layout/Type/CompositeLayout';
import LayoutViewer from '../../../../../../Layout/Viewer/LayoutViewer';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import RightAlignedButtonGroup from '../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import Centered from '../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../@Future/Component/Generic/Loader/Loader';
import CancelButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import useRelatedEntity from '../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import EntityTypeContext from '../../../../../Type/EntityTypeContext';
import PrimitiveValueType from '../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../../../DataObject/Model/DataObject';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import PrimitiveValue from '../../../../../../../../@Api/Automation/Value/PrimitiveValue';
import { CommitBuilder } from '../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import { default as EntityInput } from '../../../../../Input/Input';
import Switch from '../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';

export interface PageTabContentProps
{
    page: Entity;
    entity: Entity;
    isInEditMode: boolean;
    onChangeEditMode: (isInEditMode: boolean) => void;
    commitContext: CommitContext;
}

const PageTabContent: React.FC<PageTabContentProps> =
    props =>
    {
        const { page, entity, isInEditMode, onChangeEditMode, commitContext } = props;

        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);
        const currentUserStore = useContext(CurrentUserContext);

        const entityTypeEntity =
            useRelatedEntity(
                page,
                types.EntityType.RelationshipDefinition.Pages,
                true);
        const entityType =
            useComputed(
                () =>
                    entityTypeStore.getTypeByEntityId(entityTypeEntity.id),
                [
                    entityTypeStore,
                    entityTypeEntity
                ]);
        const layoutContext =
            useMemo(
                () =>
                    new LayoutDependencyContext(
                        new ParameterDictionary([
                            new Parameter(
                                'Entity',
                                new EntityValueType(entityType),
                                true,
                                entity.entityType.getName()
                            ),
                            new Parameter(
                                'User',
                                new EntityValueType(types.Relationship.Person.Contact.Employee.Type),
                                true,
                                localizeText('Generic.User', 'Gebruiker')
                            ),
                            new Parameter(
                                'Date',
                                new PrimitiveValueType(DataObject.getTypeById('DateTime')),
                                true,
                                localizeText('Generic.Date', 'Datum')
                            )
                        ]
                    )
                ),
                [
                    entity,
                    types,
                ]);
        const parameterAssignment =
            useMemo(
                () =>
                    new ParameterAssignment()
                        .setValue(
                            layoutContext.parameterDictionary.getParameterById('Entity'),
                            new EntityValue(entity)
                        )
                        .setValue(
                            layoutContext.parameterDictionary.getParameterById('User'),
                            new EntityValue(currentUserStore.employeeEntity)
                        )
                        .setValue(
                            layoutContext.parameterDictionary.getParameterById('Date'),
                            new PrimitiveValue(DataObject.constructFromTypeIdAndValue('DateTime', new Date()))
                        ),
                [
                    layoutContext,
                    entity
                ]);
        const [ titleLayout, isTitleLayoutLoading ] =
            useAsyncResult(
                async () =>
                {
                    if (page)
                    {
                        const specification = page.getObjectValueByField(types.Page.Field.TitleLayout);

                        if (specification)
                        {
                            return getLayoutFromDescriptor(
                                specification,
                                layoutContext);
                        }
                        else
                        {
                            return undefined;
                        }
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    page,
                    layoutContext
                ]);
        const [ layout, isLayoutLoading ] =
            useAsyncResult(
                async () =>
                {
                    if (page)
                    {
                        const specification = page.getObjectValueByField(types.Page.Field.Layout);

                        if (specification)
                        {
                            return getLayoutFromDescriptor(
                                specification,
                                layoutContext);
                        }
                        else
                        {
                            return new CompositeLayout(
                                'Vertical',
                                []);
                        }
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    page,
                    layoutContext
                ]);
        const [ titleLayoutToEdit, setTitleLayoutToEdit ] = useState(titleLayout);
        const [ layoutToEdit, setLayoutToEdit ] = useState(layout);

        useEffect(
            () =>
                setTitleLayoutToEdit(titleLayout),
            [
                setTitleLayoutToEdit,
                titleLayout
            ]);
        useEffect(
            () =>
                setLayoutToEdit(layout),
            [
                setLayoutToEdit,
                layout
            ]);

        const leaveEditMode =
            useCallback(
                () =>
                    onChangeEditMode(false),
                [
                    onChangeEditMode
                ]);
        const save =
            useCallback(
                () =>
                    new CommitBuilder()
                        .setObjectValueInEntity(
                            page,
                            types.Page.Field.TitleLayout,
                            titleLayoutToEdit?.toDescriptor()
                        )
                        .setObjectValueInEntity(
                            page,
                            types.Page.Field.Layout,
                            layoutToEdit?.toDescriptor()
                        )
                        .commit()
                        .then(
                            () =>
                                leaveEditMode()
                        ),
                [
                    page,
                    types,
                    titleLayoutToEdit,
                    layoutToEdit,
                    leaveEditMode
                ]);

        if (isTitleLayoutLoading || isLayoutLoading)
        {
            return <Centered
                horizontal
                vertical
            >
                <Loader />
            </Centered>;
        }
        else if (layout)
        {
            if (isInEditMode)
            {
                return <ViewGroup
                    orientation="vertical"
                    spacing={15}
                >
                    <ViewGroupItem>
                        <Card
                            inset
                        >
                            <Input
                                labelPosition="top"
                                label={
                                    <LocalizedText
                                        code="Generic.Title"
                                        value="Titel"
                                    />
                                }
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={15}
                                    alignment="center"
                                >
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        {
                                            titleLayoutToEdit
                                                ?
                                                    <LayoutEditor
                                                        layout={titleLayoutToEdit}
                                                        onChange={setTitleLayoutToEdit}
                                                        parameterDictionary={layoutContext.parameterDictionary}
                                                        parameterAssignment={parameterAssignment}
                                                        commitContext={commitContext}
                                                    />
                                                :
                                                    <EntityInput
                                                        entity={page}
                                                        field={types.Page.Field.Name}
                                                        labelPosition="none"
                                                        commitContext={commitContext}
                                                    />
                                        }
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <Input
                                            label={
                                                <LocalizedText
                                                    code="Generic.Detailed"
                                                    value="Gedetailleerd"
                                                />
                                            }
                                            labelPosition="right"
                                        >
                                            <Switch
                                                checked={titleLayoutToEdit !== undefined}
                                                onToggle={
                                                    isChecked =>
                                                        setTitleLayoutToEdit(
                                                            isChecked
                                                                ? new CompositeLayout('Vertical', [])
                                                                : undefined
                                                        )
                                                }
                                            />
                                        </Input>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </Input>
                        </Card>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <LayoutEditor
                            layout={layoutToEdit}
                            onChange={setLayoutToEdit}
                            parameterDictionary={layoutContext.parameterDictionary}
                            parameterAssignment={parameterAssignment}
                            commitContext={commitContext}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <RightAlignedButtonGroup>
                            <CancelButton
                                onClick={leaveEditMode}
                            />
                            <SaveButton
                                onClick={save}
                            />
                        </RightAlignedButtonGroup>
                    </ViewGroupItem>
                </ViewGroup>;
            }
            else
            {
                return <LayoutViewer
                    layout={layout}
                    parameterDictionary={layoutContext.parameterDictionary}
                    parameterAssignment={parameterAssignment}
                    commitContext={commitContext}
                />;
            }
        }
        else
        {
            return null;
        }
    };

export default observer(PageTabContent);
