import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import { AssistantVariableComputation } from '../../Model/Automation/Computation/AssistantVariableComputation';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';

export function findAssistantVariableComputation(
    computation: AssistantVariableComputation,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Computation<any, any>
{
    return parameters.getParameterById(computation.variable);
}
