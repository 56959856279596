import React from 'react';
import { LabelButtonProps } from '../Label/LabelButton';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

export interface AddButtonProps extends LabelButtonProps
{

}

const AddButton: React.FC<AddButtonProps> =
    props =>
    {
        return <PrimaryButton
            {...props}
            label={<span>+{props.label ? <>&nbsp;&nbsp;{props.label}</> : ''}</span>}
        />;

        // return <LabelButton
        //     {...props}
        //     label={<span>+&nbsp;&nbsp;{props.label}</span>}
        //     color={green[500]}
        //     filled
        // />;
    };

export default AddButton;
