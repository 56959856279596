import React, { useMemo } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { format } from 'date-fns';
import LabelButton from '../../../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(
    {
        hightlightRow:
        {
            background: '#f8f8f8',
            fontWeight: 'bold'
        },
        highlightDate:
        {
            fontWeight: 'bold',
            whiteSpace: 'nowrap'
        }
    }
);

export interface ConnectorLogRowLoadMoreProps
{
    date: Date;
    loadMore: () => void;
    isLoading: boolean;
}

export const LogEventsRowLoadMore: React.FC<ConnectorLogRowLoadMoreProps> =
    (
        {
            date,
            loadMore,
            isLoading
        }
    ) =>
    {
        const classes = useStyles();
        const friendlyDate = useMemo(
            () =>
                format(date, 'dd-MM-yyyy HH:mm:ss'),
            [
                date
            ]
        );

        return <TableRow
            className={ classes.hightlightRow }
        >
            <TableCell
                colSpan={4}
                align="center"
            >
                <LabelButton
                    label={`Load upto ${friendlyDate}`}
                    onClick={loadMore}
                    disabled={isLoading}
                    outlined
                    size="sm"
                />
            </TableCell>
        </TableRow>;
    }