import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../MenuButton/MenuButton';
import Inset from '../Inset/Inset';
import Avatar from '../Relationship/Avatar/Avatar';
import Header from '../Relationship/Header/Header';
import Caption from '../../Caption/Caption';
import ViewerEntityContext from '../../../Viewer/Context/ViewerEntity/ViewerEntityContext';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';
import Name from '../../Name/Name';
import OverlineLabel from '../../../../../../@Future/Component/Generic/Label/Variant/Overline/OverlineLabel';
import Fields from '../../../Fields/Fields';
import useToggle from '../../../../../../@Util/Toggle/useToggle';
import useRelatedEntity from '../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import { useInitializedEntityForViewer } from '../../../Viewer/Api/useInitializedEntityForViewer';
import ToggleFieldsIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/ToggleFieldsIconButton/ToggleFieldsIconButton';

export interface ProjectMemberProps
{
    entity: Entity;
}

const WorkOrderMember: React.FC<ProjectMemberProps> =
    props =>
    {
        const types = useTypes();
        const workOrder =
            useRelatedEntity(
                props.entity,
                types.Activity.WorkOrder.RelationshipDefinition.Members,
                true
            );
        const employee =
            useRelatedEntity(
                props.entity,
                types.WorkOrderMember.RelationshipDefinition.Employee,
                false
            );
        const calendarItemAttendee =
            useRelatedEntity(
                props.entity,
                types.WorkOrderMember.RelationshipDefinition.CalendarItemAttendee,
                false
            );
        const responseStatus =
            useRelatedEntity(
                calendarItemAttendee,
                types.CalendarItemAttendee.RelationshipDefinition.ResponseStatus,
                false
            );
        const viewerEntity = useContext(ViewerEntityContext);
        const isViewingFromRelationship =
            useComputed(
                () =>
                    equalsEntity(
                        employee,
                        viewerEntity
                    ),
                [
                    employee,
                    viewerEntity
                ]
            );

        useInitializedEntityForViewer(props.entity);

        const [ isFieldsOpen, toggleFieldsOpen ] = useToggle(false);

        if (isViewingFromRelationship && workOrder)
        {
            return <Inset>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                <Avatar
                                    entity={workOrder}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem
                                ratio={1}
                            >
                                <div>
                                    <OverlineLabel>
                                        {workOrder.entityType.getName()}
                                    </OverlineLabel>
                                </div>
                                <Name
                                    entity={workOrder}
                                    link
                                />
                                {
                                    responseStatus &&
                                        <Caption
                                            entity={props.entity}
                                        >
                                            {responseStatus.name}
                                        </Caption>
                                }
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ToggleFieldsIconButton
                                    toggleFieldsOpen={toggleFieldsOpen}
                                    isFieldsOpen={isFieldsOpen}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <MenuButton
                                    entity={props.entity}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    {
                        isFieldsOpen &&
                            <ViewGroupItem>
                                <Fields
                                    entity={props.entity}
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </Inset>;
        }
        else if (employee)
        {
            return <Inset>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                <Avatar
                                    entity={employee}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem
                                ratio={1}
                            >
                                <Header
                                    entity={employee}
                                    pathFromRelatedEntity={undefined}
                                />
                                {
                                    responseStatus &&
                                        <Caption
                                            entity={props.entity}
                                        >
                                            {responseStatus.name}
                                        </Caption>
                                }
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ToggleFieldsIconButton
                                    toggleFieldsOpen={toggleFieldsOpen}
                                    isFieldsOpen={isFieldsOpen}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <MenuButton
                                    entity={props.entity}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    {
                        isFieldsOpen &&
                            <ViewGroupItem>
                                <Fields
                                    entity={props.entity}
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </Inset>;
        }
        else
        {
            return <span>{props.entity.name}</span>;
        }
    };

export default observer(WorkOrderMember);
