import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import CampaignPercentageChart from './CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import useCampaignTotalEmailCount from '../Hooks/useCampaignTotalEmailCount';

export interface CampaignChartSelectionProps
{
    size: number;
    campaign: Entity;
}

export const CampaignChartSelection: React.FC<CampaignChartSelectionProps> =
    observer(
        ({
            size,
            campaign,
        }) =>
            {
                const totalEmailCount = useCampaignTotalEmailCount(campaign);

                const allSet =
                    useAllSet(
                        [
                            totalEmailCount
                        ]
                    );

                const formattedSelection =
                    useMemo(
                        () =>
                        {
                            return `${totalEmailCount}/${totalEmailCount}`;
                        },
                        [
                            totalEmailCount
                        ]
                    )

                return <div>
                    {
                        allSet &&
                        <CampaignPercentageChart
                            value={totalEmailCount / totalEmailCount * 100}
                            size={size}
                            label={localizeText('Campaign.Chart.Sent', 'Verzonden')}
                            selection={formattedSelection}
                        />
                    }
                </div>
            }
    );
