import { SettingSource } from '../../../@Component/Domain/Setting/SettingStore';
import Input from '../../../@Component/Domain/Multiplayer/Model/Input/Input';
import { Setting } from '../../Settings/Setting';
import getSetting from '../../../@Component/Domain/Setting/Api/getSetting';

export default function isHiddenField(field: Input): boolean
{
    const hiddenFields = getSetting(SettingSource.Organization, Setting.Metadata.HiddenFields);

    return !field.isRequired()
        && isHiddenFieldInSetting(hiddenFields, field);
}

function isHiddenFieldInSetting(
    hiddenFields: any,
    field: Input
): boolean
{
    const isHidden = hiddenFields[field.id()];

    if (isHidden !== undefined)
    {
        return isHidden;
    }
    else if (field.entityType.hasParent())
    {
        return isHiddenFieldInSetting(
            hiddenFields,
            field.fromType(field.entityType.parentType)
        );
    }
    else
    {
        return false;
    }
}