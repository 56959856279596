import * as React from 'react';
import { observer } from 'mobx-react';
import { AppBar, Dialog, Grid, Icon, IconButton, Theme, Toolbar, Typography } from '@material-ui/core';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { LightboxStore } from './LightboxStore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { Sidebar } from '../Sidebar/Sidebar';
import { ComponentContainer } from '../Container/ComponentContainer';

const styles = (theme: Theme) => (
    {
        dialogRoot:
        {
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
        },
        toolbarRoot:
        {
            minHeight: '32px',
            paddingLeft: '16px',
            paddingRight: '16px',
            display: 'flex'
        },
        dialogPaper:
        {
            boxShadow: 'none',
            backgroundColor: 'transparent',
            padding: '0',
            margin: '0px',
            height: '100%',
            width: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
            overflowY: 'scroll !important' as any
        }
    });

@observer
class Lightbox extends BaseComponent<LightboxStore>
{
    renderComponent(store: LightboxStore)
    {
        return <Dialog
          open={store.isVisible}
          onClose={() => store.setVisibility(false)}
          classes={{
              root: this.props.classes.dialogRoot,
              paper: this.props.classes.dialogPaper
          }}
        >
            <AppBar elevation={0} style={{ backgroundColor: 'transparent' }}>
                <Toolbar classes={{ root: this.props.classes.toolbarRoot }}>
                    <IconButton
                        color="secondary"
                        aria-label="Close"
                        onClick={() => store.setVisibility(false)}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <div style={{ flex: '1 1 auto', display: 'flex', alignItems: 'center' }}>
                        {
                            store.icon &&
                                <Icon style={{ color: 'white', marginRight: 10 }}>
                                    {store.icon}
                                </Icon>
                        }
                        <Typography variant="body2" style={{ color: 'white' }}>
                            {store.title}
                        </Typography>
                    </div>
                    <IconButton
                        color="secondary"
                        aria-label="Close"
                        onClick={() => store.setVisibility(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div
                onClick={() => store.setVisibility(false)}
                style={{
                    height: '100%',
                    display: 'flex'
                }}
            >
                <div
                    style={{
                        maxWidth: store.sidebarStore.hasViews ? 1500 : 1000,
                        zIndex: 10000,
                        margin: '10px auto',
                        width: '100%'
                    }}
                >
                    <Grid container style={{height: 'calc(100% - 50px)'}}>
                        <Grid item xs={store.sidebarStore.hasViews ? 8 : 12} style={{alignSelf: 'center'}}>
                            {
                                store.containerStore &&
                                    <ComponentContainer store={store.containerStore} />
                            }
                            {this.props.children}
                        </Grid>
                        {
                            store.sidebarStore.hasViews &&
                                <Grid item xs={4}>
                                    <Sidebar store={store.sidebarStore} />
                                </Grid>
                        }
                    </Grid>
                </div>
            </div>
        </Dialog>;
    }
}

export default withStyles(styles)(Lightbox as any);
