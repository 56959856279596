import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import useTypes from '../Type/Api/useTypes';
import Search from './CocSearch/CocSearch';
import useDialog from '../../../../@Service/Navigation/Page/Hooks/useDialog';
import PrimaryTextButton from '../../../../@Future/Component/Generic/Button/Variant/PrimaryTextButton/PrimaryTextButton';
import CurrentUserContext from '../../User/CurrentUserContext';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import updateEntityFromCoC from './updateEntityFromCoC';

export interface CocFetcherProps
{
    entity: Entity;
    relationship?: Entity;
    commitContext?: CommitContext;
}

const CocFetcher: React.FC<CocFetcherProps> =
    (
        {
            entity,
            relationship,
            commitContext
        }
    ) =>
    {
        const types = useTypes();
        const onSelect =
            useCallback(
                async (result: any) =>
                {
                    await updateEntityFromCoC(
                        result,
                        entity,
                        relationship,
                        commitContext);
                },
                [
                    entity,
                    relationship,
                    commitContext,
                ]);

        const [ openSearch ] =
            useDialog(
                close =>
                    <Search
                        entity={entity}
                        onClose={close}
                        onSelect={onSelect}
                        commitContext={commitContext}
                    />,
                [
                    entity,
                    onSelect
                ]);

        const currentUserStore = useContext(CurrentUserContext);
        const hasCoCPack =
            useComputed(
                () =>
                    currentUserStore.packs
                        .some(
                            pack =>
                                pack.getObjectValueByField(types.Pack.Field.Code) === 'CoC'),
                [
                    currentUserStore
                ]);

        if (hasCoCPack)
        {
            return <PrimaryTextButton
                label={
                    <LocalizedText
                        code="ChamberOfCommerce.SearchInCoC"
                        value="Zoeken in de Kamer van Koophandel"
                    />
                }
                onClick={openSearch}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(CocFetcher);
