import React, { useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import MultiplayerContext from '../Context/MultiplayerContext';
import RichtextEditor, { RichtextEditorProps } from '../../../../@Future/Component/Generic/Input/RichtextEditor/RichtextEditor';
import CollaborationClient from './CollaborationClient';
import { Delta } from './State/State';
import Quill from 'quill';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import getEntityById from '../../../../@Api/Entity/Bespoke/getEntityById';
import useTypes from '../../Entity/Type/Api/useTypes';
import { primaryColor } from '../../../../@Resource/Theme/Theme';

import QuillDelta from 'quill-delta';
import QuillCursors from 'quill-cursors';

Quill.register('modules/cursors', QuillCursors);

interface Cursor
{
    id: string;
    entity?: Entity;
    range: any;
    cursor?: any;
}

export interface CollaborationEditorProps extends Partial<RichtextEditorProps>
{
    id: string;
    initialValue?: string;
}

const CollaborationEditor: React.FC<CollaborationEditorProps> =
    props =>
    {
        const types = useTypes();
        const multiplayer = useContext(MultiplayerContext);
        const [ _quill, setQuill ] = useState<any>();
        const [ initialValue ]  = useState(props.initialValue);

        useEffect(
            () =>
            {
                if (_quill && multiplayer)
                {
                    const quill = _quill.getEditor();

                    const client =
                        new CollaborationClient(
                            0,
                            (version, delta) =>
                            {
                                multiplayer.trySendMessage(
                                    JSON.stringify({
                                        type: 'Collaboration.Apply',
                                        roomId: props.id,
                                        delta: delta,
                                        version: version
                                    }));
                            },
                            delta =>
                            {
                                quill.updateContents(delta, 'api');

                            });

                    const cursors = quill.getModule('cursors');
                    const cursorsByConnectionId = new Map<string, Cursor>();

                    const listener =
                        (event: MessageEvent) =>
                        {
                            const message = JSON.parse(event.data);

                            if (message.type === 'Collaboration.Initialize'
                                && message.roomId === props.id)
                            {
                                quill.setContents(new QuillDelta(message.document), 'api');
                                client.version = message.version;
                            }
                            else if (message.type === 'Collaboration.Apply'
                                && message.roomId === props.id)
                            {
                                for (const op of message.delta.ops)
                                {
                                    if (op.delete === 0)
                                    {
                                        delete op['delete'];
                                    }

                                    if (op.retain === 0)
                                    {
                                        delete op['retain'];
                                    }
                                }

                                message.delta.ops = message.delta.ops.filter(op => Object.keys(op).length > 0);

                                client.applyFromServer(new QuillDelta(message.delta));
                            }
                            else if (message.type === 'Collaboration.Acknowledge'
                                && message.roomId === props.id)
                            {
                                client.serverAck();
                            }
                            else if (message.type === 'Collaboration.MoveCursor'
                                && message.roomId === props.id)
                            {
                                if (!cursorsByConnectionId.get(message.connectionId))
                                {
                                    const cursorDescriptor: Cursor = {
                                        id: message.connectionId,
                                        range: message.range
                                    };

                                    cursorsByConnectionId.set(
                                        message.connectionId,
                                        cursorDescriptor);

                                    getEntityById(types.Relationship.Person.Contact.Employee.Type, message.entityId)
                                        .then(
                                            ({ value: entity }) =>
                                            {
                                                cursorDescriptor.entity = entity;

                                                cursorDescriptor.cursor =
                                                    cursors.createCursor(
                                                        message.connectionId,
                                                        entity.name,
                                                        primaryColor);

                                                cursors.moveCursor(message.connectionId, cursorDescriptor.range);

                                                cursors.toggleFlag(
                                                    message.connectionId,
                                                    true);
                                            });
                                }

                                cursorsByConnectionId.get(message.connectionId).range = message.range;
                                cursors.moveCursor(message.connectionId, message.range);
                            }
                        };

                    multiplayer.webSocket.addEventListener('message', listener);

                    const textChange =
                        (delta: Delta, oldDelta: Delta, source: any) =>
                        {
                            if (source === 'user')
                            {
                                client.applyFromClient(delta);
                            }
                        };

                    const selectionChange =
                        (range, oldRange, source) =>
                        {
                            if (source === 'user')
                            {
                                multiplayer.trySendMessage(
                                    JSON.stringify({
                                        type: 'Collaboration.MoveCursor',
                                        roomId: props.id,
                                        range: range
                                    }));
                            }
                        };

                    quill.on('text-change', textChange);
                    quill.on('selection-change', selectionChange);

                    multiplayer.trySendMessage(
                        JSON.stringify({
                            type: 'Collaboration.Join',
                            roomId: props.id,
                            document: quill.getContents()
                        }));

                    return () =>
                    {
                        multiplayer.trySendMessage(
                            JSON.stringify({
                                type: 'Collaboration.Leave',
                                roomId: props.id
                            }));

                        multiplayer.webSocket.removeEventListener('message', listener);
                        quill.off('text-change', textChange);
                        quill.off('selection-change', selectionChange);
                    }
                }
            },
            [
                props.id,
                multiplayer,
                _quill,
                initialValue,
                types
            ]);

        const modules =
            useMemo(
                () => ({
                    cursors: {
                        transformOnTextChange: true
                    }
                }),
                []);

        return <RichtextEditor
            editorRef={setQuill}
            modules={modules}
            defaultValue={initialValue}
            {...props}
        />;
    };

export default observer(CollaborationEditor);
