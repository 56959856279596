import { usePortalTheme } from './usePortalTheme';
import { useOrganizationAndPortalIdFromContext } from './useOrganizationAndPortalIdFromContext';
import { PortalTheme } from '../Theme/PortalTheme';

export function usePortalThemeFromContext(): [ PortalTheme | undefined, boolean ]
{
    const [ organizationAndPortalId, isLoadingOrganizationAndPortalIds ] = useOrganizationAndPortalIdFromContext();
    const organizationId = organizationAndPortalId?.organizationId;
    const portalId = organizationAndPortalId?.portalId;
    const [ portalTheme, isLoadingPortalTheme ] =
        usePortalTheme(
            organizationId,
            portalId
        );

    return [
        portalTheme,
        isLoadingPortalTheme || isLoadingOrganizationAndPortalIds
    ];
}