import React, { useCallback } from 'react';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import Link from '../../../../@Future/Component/Generic/Link/Link';
import { openEntity } from '../@Util/openEntity';
import { RelatedEntityPath } from '../Path/@Model/RelatedEntityPath';
import { useComputed } from 'mobx-react-lite';

export interface LinkProps
{
    entity: Entity;
    pathFromRelatedEntity?: RelatedEntityPath;
    stopPropagation?: boolean;
    highlighted?: boolean;
    tooltip?: React.ReactNode;
}

const EntityLink: React.FC<LinkProps> =
    props =>
    {

        const onClick =
            useCallback(
                (event: any) =>
                {
                    if (props.stopPropagation)
                    {
                        event.stopPropagation();
                    }

                    return openEntity(
                        props.entity,
                        props.pathFromRelatedEntity)
                },
                [
                    props.entity,
                    props.pathFromRelatedEntity,
                    props.stopPropagation
                ]);

        const showLink =
            useComputed(
                () =>
                    props.entity.entityType.bespoke.isOpenable(props.entity, props.pathFromRelatedEntity)
                    && props.entity.entityType.bespoke.isLinkable(props.entity),
                [
                    props.entity,
                    props.pathFromRelatedEntity
                ]);

        const isFormer =
            useComputed(
                () =>
                    props.entity.entityType.bespoke.isFormerLink(props.entity),
                [
                    props.entity
                ]
            )

        const content: any =
            props.children ||
            props.entity.name ||
            '-';

        if (showLink)
        {
            return <Link
                onClick={onClick}
                highlighted={props.highlighted}
                tooltip={props.tooltip}
                strikethrough={isFormer}
            >
                {content}
            </Link>;
        }
        else
        {
            return content;
        }
    };

export default EntityLink;
