import { BaseStore } from '../../../../../../@Framework/Store/BaseStore';
import { computed, observable } from 'mobx';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../../../Domain/Entity/Type/EntityTypeStore';
import { HeaderStore } from '../Header/HeaderStore';
import { SearchStore } from '../../../../../Domain/Search/SearchStore';
import { SidebarStore } from '../../../../../Generic/Sidebar/SidebarStore';
import { RouterStore } from '../../../../../../@Service/Router/RouterStore';

export class BodyStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('SidebarStore') sidebarStore: SidebarStore;
    @injectWithQualifier('RouterStore') routerStore: RouterStore;

    // ------------------------- Properties -------------------------

    @observable headerStore: HeaderStore;

    // ------------------------ Constructor -------------------------

    constructor(headerStore: HeaderStore)
    {
        super();

        this.headerStore = headerStore;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get showSearch(): boolean
    {
        return this.headerStore.searchBarStore.hasQuery
            && this.headerStore.searchBarStore.isActivated;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get searchStore(): SearchStore
    {
        if (this.headerStore.searchBarStore.hasDelayedQuery)
        {
            return new SearchStore(this.headerStore.searchBarStore);
        }
        else
        {
            return null;
        }
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
