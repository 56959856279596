import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { LocalizationStore } from '../LocalizationStore';

export default function getTranslation(code: string,
                                       ...args: string[])
{
    const localizationStore = loadModuleDirectly(LocalizationStore);

    return localizationStore.translate(code, ...args);
}
