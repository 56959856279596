import React, { useContext } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import styles from './Summary.module.scss';
import useIsShowEntitySummary from '../Api/useIsShowEntitySummary';
import { observer } from 'mobx-react-lite';
import EntitySummary from '../../../../Summary/EntitySummary';
import ClickableContext from '../../../Context/ClickableContext';

export interface SummaryProps
{
    entity: Entity;
}

const Summary: React.FC<SummaryProps> =
    props =>
    {
        const isClickable = useContext(ClickableContext);

        return <div
            className={styles.root}
        >
            {
                useIsShowEntitySummary(props.entity) &&
                    <EntitySummary
                        entity={props.entity}
                        clickable={!isClickable}
                    />
            }
        </div>;
    };

export default observer(Summary);
