import React, { useCallback, useState } from 'react';
import Popper from '../../../Popper/Popper';
import Card from '../../../Card/Card';
import IconButton, { BadgeColor, BadgeVariant } from '../Icon/IconButton';
import { primaryColor, textSecondaryColor } from '../../../../../../@Resource/Theme/Theme';
import LabelButton, { LabelButtonSize } from '../Label/LabelButton';
import { classNames } from '../../../../../Util/Class/classNames';
import styles from './MenuButton.module.scss';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface ExpansionButtonClasses
{
    root?: string;
}

export interface ExpansionButtonProps
{
    classes?: ExpansionButtonClasses;
    label?: React.ReactNode;
    icon?: string;
    small?: boolean;
    tooltip?: React.ReactNode;
    onClick?: () => void;
    open?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    color?: string;
    expansion?: (close: () => void) => React.ReactNode;
    fullWidth?: boolean;
    inline?: boolean;
    filled?: boolean;
    size?: LabelButtonSize;
    badge?: React.ReactNode;
    badgeColor?: BadgeColor;
    badgeVariant?: BadgeVariant;
    iconSize?: number;
}

const MenuButton: React.FC<ExpansionButtonProps> =
    props =>
    {
        const [ _isOpen, setOpen ] = useState(false);
        const isOpen = props.open === undefined ? _isOpen : props.open;
        const { onOpen, onClose } = props;
        const toggle =
            useCallback(
                () =>
                {
                    setOpen(!isOpen);

                    if (!isOpen)
                    {
                        if (onOpen)
                        {
                            onOpen();
                        }
                    }
                    else
                    {
                        if (onClose)
                        {
                            onClose();
                        }
                    }
                },
                [
                    setOpen,
                    isOpen,
                    onOpen,
                    onClose
                ]);

        const close =
            useCallback(
                () =>
                {
                    setOpen(false);

                    if (onClose)
                    {
                        onClose();
                    }
                },
                [
                    setOpen,
                    onClose
                ]);

        const preventPropagation =
            useCallback(
                (e) => e.stopPropagation(),
                []);

        const openMenuLocalizedText =
            <LocalizedText
                code="MenuButton.Open"
                value="Menu openen"
            />;

        const closeMenuLocalizedText =
            <LocalizedText
                code="MenuButton.Close"
                value="Menu sluiten"
            />;

        return <div
            onClick={preventPropagation}
            className={classNames(props.inline && styles.inline)}
        >
            <Popper
                reference={
                    props.label
                    ?
                        <LabelButton
                            label={props.label}
                            tooltip={props.tooltip || (isOpen ? closeMenuLocalizedText : openMenuLocalizedText)}
                            onClick={props.onClick || toggle}
                            color={props.color}
                            hoverColor={props.color}
                            fullWidth={props.fullWidth}
                            filled={props.filled}
                            icon={props.icon}
                            size={props.size}
                        />
                    :
                        <IconButton
                            small={props.small}
                            icon={props.icon || 'more_vert'}
                            tooltip={props.tooltip || (isOpen ? closeMenuLocalizedText : openMenuLocalizedText)}
                            onClick={props.onClick || toggle}
                            color={isOpen ? primaryColor : props.color ? props.color : textSecondaryColor}
                            badge={props.badge}
                            badgeColor={props.badgeColor}
                            badgeVariant={props.badgeVariant}
                            iconSize={props.iconSize}
                        />
                }
                popper={
                    <Card>
                        {props.expansion ? props.expansion(close) : props.children}
                    </Card>
                }
                open={isOpen}
                onClose={close}
                fullWidth={props.fullWidth}
                inline={props.inline}
            />
        </div>;
    };

export default MenuButton;
