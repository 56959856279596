import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import EntityInputLayout, { LabelPosition } from '../../../../../@Api/Layout/Type/EntityInputLayout';
import { runInAction } from 'mobx';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import StaticSelectbox, { StaticOption } from '../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import localizeText from '../../../../../@Api/Localization/localizeText';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import Switch from '../../../../../@Future/Component/Generic/Input/Switch/Switch';

export interface EntityInputLayoutControlsProps extends LayoutEditorProps<EntityInputLayout>
{

}

const EntityInputLayoutControls: React.FC<EntityInputLayoutControlsProps> =
    props =>
    {
        const labelPositionOptions =
            useMemo<StaticOption<LabelPosition>[]>(
                () => [
                    {
                        id: 'None',
                        label: localizeText('Generic.None', 'Geen'),
                        value: 'None'
                    },
                    {
                        id: 'Left',
                        label: localizeText('Generic.Left', 'Links'),
                        value: 'Left'
                    },
                    {
                        id: 'Top',
                        label: localizeText('Generic.Top', 'Boven'),
                        value: 'Top'
                    }
                ],
                []);

        const setLabelPosition =
            useCallback(
                (labelPosition: LabelPosition) =>
                    runInAction(
                        () =>
                            props.layout.labelPosition = labelPosition),
                [
                    props.layout
                ]);

        const setIntrinsicSize =
            useCallback(
                (hasIntrinsicSize: boolean) =>
                    runInAction(
                        () =>
                            props.layout.hasIntrinsicSize = hasIntrinsicSize),
                [
                    props.layout
                ]);

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.LabelPosition"
                            value="Label positie"
                        />
                    }
                >
                    <StaticSelectbox
                        options={labelPositionOptions}
                        value={props.layout.labelPosition}
                        onChange={setLabelPosition}
                    />
                </Input>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.hasIntrinsicSize"
                            value="Is net zo groot als inhoud"
                        />
                    }
                >
                    <Switch
                        checked={props.layout.hasIntrinsicSize}
                        onToggle={setIntrinsicSize}
                    />
                </Input>
            </InputGroup>
        </CardInset>;
    };

export default observer(EntityInputLayoutControls);
