import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import styles from './MileageSheet.module.scss';
import useTypes from '../../../../../Type/Api/useTypes';
import Centered from '../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../@Future/Component/Generic/Loader/Loader';
import { getModel } from '../../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import EntityTypeContext from '../../../../../Type/EntityTypeContext';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';
import Divider from '../../../../../../../../@Future/Component/Generic/Divider/Divider';
import HoverCardMiddle from '../../../../../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import TabBar from '../../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import useAggregateResult from '../../../../../Selection/Hooks/useAggregateResult';
import { Aggregate } from '../../../../../../DataObject/Model/Aggregate';
import { DataObject } from '../../../../../../DataObject/Model/DataObject';
import CloseButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/CloseButton/CloseButton';
import { TabLabel } from '../../../../../../../../@Future/Component/Generic/TabBar/Tab/TabLabel/TabLabel';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import usePaginatedSelection from '../../../../../View/Api/usePaginatedSelection';
import useResults from '../../../../../Selection/Hooks/useResults';
import MileageSheetProcessingOptions from './ProcessingOptions/MileageSheetProcessingOptions';
import useDividerGlue from '../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import MileageSheetTable, { StartDateSortDirection } from './Table/MileageSheetTable';
import useLocalSetting from '../../../../../../Setting/Api/useLocalSetting';
import { useNewCommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { constructEntityOfType } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { setValueByFieldInEntity } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import equalsEntity from '../../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import { getDistanceLabel } from './Api/getDistanceLabel';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { classNames } from '../../../../../../../../@Future/Util/Class/classNames';

export interface MileageSheetProps
{
    relationship: Entity;
    activity?: Entity;
    milestone?: Entity;
    timeRegistration?: Entity;
    onClose?: () => void;
    commitContext?: CommitContext;
    noScroll?: boolean;
}

const MileageSheet: React.FC<MileageSheetProps> =
    props =>
    {
        const { relationship, activity, milestone, timeRegistration, commitContext: parentCommitContext } = props;
        const types = useTypes();
        const commitContext = useNewCommitContext(parentCommitContext);

        const relationshipDefinition =
            useMemo(
                () =>
                {
                    if (timeRegistration)
                    {
                        return types.TimeRegistration.RelationshipDefinition.MileageRegistrations;
                    }
                    else if (milestone)
                    {
                        return types.Milestone.RelationshipDefinition.MileageRegistrations;
                    }
                    else if (activity)
                    {
                        return types.Activity.RelationshipDefinition.MileageRegistrations;
                    }
                    else if (relationship.entityType.isA(types.Relationship.Person.Contact.Employee.Type))
                    {
                        return types.Relationship.Person.Contact.Employee.RelationshipDefinition.MileageRegistrations;
                    }
                    else
                    {
                        return types.Relationship.RelationshipDefinition.MileageRegistrations;
                    }
                },
                [
                    types,
                    relationship,
                    activity,
                    milestone,
                    timeRegistration,
                ]);

        const entity =
            useMemo(
                () =>
                    timeRegistration || milestone || activity || relationship,
                [
                    timeRegistration,
                    relationship,
                    activity,
                    milestone
                ]);

        const entityTypeStore = useContext(EntityTypeContext);
        const currentUserStore = useContext(CurrentUserContext);

        const [ tab, setTab ] = useState(1);
        const [ constructingEntity, setConstructingEntity ] = useState<Entity | undefined>(undefined);

        const [ startDateSortDirection, setStartDateSortDirection ] =
            useLocalSetting<StartDateSortDirection>(
                'TimeSheet.StartDateSortDirection',
                'Ascending');

        const onAdd =
            useCallback(
                () =>
                {
                    const newEntity =
                        constructEntityOfType(
                            types.MileageRegistration.Type,
                            commitContext
                        );

                    updateRelationship(
                        newEntity,
                        true,
                        types.Relationship.RelationshipDefinition.MileageRegistrations,
                        relationship,
                        commitContext
                    );

                    if (activity)
                    {
                        updateRelationship(
                            newEntity,
                            true,
                            types.Activity.RelationshipDefinition.MileageRegistrations,
                            activity,
                            commitContext
                        );
                    }

                    if (milestone)
                    {
                        updateRelationship(
                            newEntity,
                            true,
                            types.Milestone.RelationshipDefinition.MileageRegistrations,
                            milestone,
                            commitContext
                        );
                    }

                    if (timeRegistration)
                    {
                        updateRelationship(
                            newEntity,
                            true,
                            types.TimeRegistration.RelationshipDefinition.MileageRegistrations,
                            timeRegistration,
                            commitContext
                        );
                    }

                    updateRelationship(
                        newEntity,
                        true,
                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.MileageRegistrations,
                        currentUserStore.employeeEntity,
                        commitContext
                    );

                    setValueByFieldInEntity(
                        newEntity,
                        types.MileageRegistration.Field.Date,
                        new Date(),
                        commitContext
                    );

                    setConstructingEntity(newEntity);
                },
                [
                    types,
                    entityTypeStore,
                    relationship,
                    activity,
                    milestone,
                    timeRegistration,
                    currentUserStore,
                    setConstructingEntity,
                    commitContext,
                ]);

        const [ pages, hasMore, _loadMore, isLoading ] =
            usePaginatedSelection(
                types.MileageRegistration.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        relationshipDefinition,
                                        true),
                                    entity))
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.MileageRegistration.Field.IsProcessed),
                                    undefined,
                                    tab === 2))
                        .if(
                            () =>
                                tab === 0,
                            () =>
                                builder.where(
                                    cb =>
                                        cb.isNotDefined(rootPath.field(types.MileageRegistration.Field.Date))))
                        .if(
                            () =>
                                tab !== 0,
                            () =>
                                builder.where(
                                    cb =>
                                        cb.isDefined(rootPath.field(types.MileageRegistration.Field.Date))))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.MileageRegistrations,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Activity.RelationshipDefinition.MileageRegistrations,
                                    true))
                        .if(
                            () =>
                                types.Milestone.RelationshipDefinition.MileageRegistrations !== undefined,
                            () =>
                                builder.join(
                                    rootPath
                                        .joinTo(
                                            types.Milestone.RelationshipDefinition.MileageRegistrations,
                                            true)))
                        .join(
                            rootPath
                                .joinTo(
                                    types.MileageRegistration.RelationshipDefinition.Type,
                                    false)
                                .joinTo(
                                    types.MileageRegistrationType.RelationshipDefinition.Product,
                                    false))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.MileageRegistrations,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.ProductLine.RelationshipDefinition.BilledMileageRegistrations,
                                    true)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.ProductLines,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.ProductLine.RelationshipDefinition.BilledMileageRegistrations,
                                    true)
                                .joinTo(
                                    types.ProductLine.RelationshipDefinition.InvoiceProductLine,
                                    false)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.ProductLines,
                                    true))
                        .orderBy(
                            rootPath.field(types.MileageRegistration.Field.Date),
                            startDateSortDirection === 'Ascending'),
                [
                    types,
                    tab,
                    entity,
                    relationshipDefinition,
                    startDateSortDirection,
                ]);
        const loadMore =
            useCallback(
                () =>
                {
                    setConstructingEntity(undefined);

                    return _loadMore();
                },
                [
                    setConstructingEntity,
                    _loadMore
                ]);

        const [ selectedLines, setSelectedLines ] = useState<Entity[]>([]);

        useEffect(
            () =>
            {
                setSelectedLines([]);
            },
            [
                relationship,
                activity,
                milestone,
                setSelectedLines
            ]);

        const activeMileageRegistrations =
            useResults(
                types.MileageRegistration.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        relationshipDefinition,
                                        true),
                                    entity))
                        .where(
                            cb =>
                                cb.isNotDefined(rootPath.field(types.MileageRegistration.Field.Date)))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.MileageRegistrations,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Activity.RelationshipDefinition.MileageRegistrations,
                                    true))
                        .if(
                            () =>
                                types.Milestone.RelationshipDefinition.MileageRegistrations !== undefined,
                            () =>
                                builder.join(
                                    rootPath
                                        .joinTo(
                                            types.Milestone.RelationshipDefinition.MileageRegistrations,
                                            true)))
                        .join(
                            rootPath
                                .joinTo(
                                    types.MileageRegistration.RelationshipDefinition.Type,
                                    false)
                                .joinTo(
                                    types.MileageRegistrationType.RelationshipDefinition.Product,
                                    false))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.MileageRegistrations,
                                    true))
                        .orderBy(
                            rootPath.field(types.MileageRegistration.Field.Date),
                            startDateSortDirection === 'Ascending'),
                [
                    types,
                    tab,
                    entity,
                    relationshipDefinition,
                    startDateSortDirection
                ])

        useEffect(
            () =>
            {
                if (activeMileageRegistrations?.length > 0)
                {
                    setTab(0);
                }
                else
                {
                    setTab(1);
                }
            },
            [
                activeMileageRegistrations,
                setTab
            ]);

        const activeDistanceLabel =
            useComputed(
                () =>
                {
                    return getDistanceLabel(0);
                },
                []);

        const unprocessedDistanceResult =
            useAggregateResult(
                types.MileageRegistration.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        relationshipDefinition,
                                        true),
                                    entity))
                        .where(
                            cb =>
                                cb.isDefined(
                                    rootPath.field(types.MileageRegistration.Field.Date)))
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.MileageRegistration.Field.IsProcessed),
                                    undefined,
                                    false))
                        .aggregateOn(
                            rootPath.field(types.MileageRegistration.Field.Distance),
                            undefined,
                            Aggregate.Sum),
                [
                    types,
                    relationshipDefinition,
                    entity
                ]);

        const unprocessedDistance =
            useComputed(
                () =>
                {
                    if (unprocessedDistanceResult && !unprocessedDistanceResult.aggregates[0].isEmpty)
                    {
                        return unprocessedDistanceResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Number', 0);
                    }
                },
                [
                    unprocessedDistanceResult
                ]);

        const unprocessedDistanceLabel =
            useComputed(
                () =>
                    getDistanceLabel(unprocessedDistance.value),
                [
                    unprocessedDistanceResult
                ]);

        const processedDistanceResult =
            useAggregateResult(
                types.MileageRegistration.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        relationshipDefinition,
                                        true),
                                    entity))
                        .where(
                            cb =>
                                cb.isDefined(
                                    rootPath.field(types.MileageRegistration.Field.Date)))
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.MileageRegistration.Field.IsProcessed),
                                    undefined,
                                    true))
                        .aggregateOn(
                            rootPath.field(types.MileageRegistration.Field.Distance),
                            undefined,
                            Aggregate.Sum),
                [
                    types,
                    relationshipDefinition,
                    entity
                ]);

        const processedDistance =
            useComputed(
                () =>
                {
                    if (processedDistanceResult && !processedDistanceResult.aggregates[0].isEmpty)
                    {
                        return processedDistanceResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Number', 0);
                    }
                },
                [
                    processedDistanceResult
                ]);

        const processedDistanceLabel =
            useComputed(
                () =>
                    getDistanceLabel(processedDistance.value),
                [
                    processedDistance
                ]);

        const showActivity =
            useMemo(
                () =>
                    activity === undefined,
                [
                    activity
                ]);

        const canHaveMilestone =
            useComputed(
                () =>
                    activity?.entityType.isA(types.Activity.Project.Type),
                [
                    activity,
                    types
                ]);

        const dividerGlue = useDividerGlue();
        const showBillingActivity =
            useMemo(
                () =>
                    tab === 2 && types.Activity.Invoice.Type !== undefined,
                [
                    tab,
                    types
                ]);

        const filter =
            useCallback(
                (mileageRegistration: Entity) =>
                {
                    if (props.milestone
                        && !equalsEntity(
                            mileageRegistration.getRelatedEntityByDefinition(
                                true,
                                types.Milestone.RelationshipDefinition.MileageRegistrations,
                                commitContext
                            ),
                            props.milestone
                        )
                    )
                    {
                        return false;
                    }

                    const isEnded = getModel(mileageRegistration).hasValueForField(types.MileageRegistration.Field.Date);

                    if (tab === 0)
                    {
                        return !isEnded;
                    }
                    else if (tab === 1)
                    {
                        return !getModel(mileageRegistration).getObjectValueByField(types.MileageRegistration.Field.IsProcessed)
                            && (mileageRegistration.isNew() || isEnded);
                    }
                    else
                    {
                        return getModel(mileageRegistration).getObjectValueByField(types.MileageRegistration.Field.IsProcessed)
                            && isEnded;
                    }
                },
                [
                    props.milestone,
                    types,
                    tab,
                    types,
                    commitContext,
                ]);

        if (isLoading && false)
        {
            return <Card
                inset
            >
                <Centered
                    horizontal
                >
                    <Loader />
                </Centered>
            </Card>
        }
        else
        {
            return <Card>
                <div
                    className={classNames(styles.header, props.noScroll && styles.noScroll)}
                >
                    <TabBar
                        value={tab}
                    >
                        {
                            activeMileageRegistrations?.length > 0 &&
                                <Tab
                                    value={0}
                                    onClick={setTab}
                                >
                                    <TabLabel
                                        label={
                                            <LocalizedText
                                                code="TimeSheet.Active"
                                                value="Lopend"
                                            />
                                        }
                                        secondary={activeDistanceLabel}
                                    />
                                </Tab>
                        }
                        <Tab
                            value={1}
                            onClick={setTab}
                        >
                            <TabLabel
                                label={
                                    <LocalizedText
                                        code="TimeSheet.ToBeProcessed"
                                        value="Te verwerken"
                                    />
                                }
                                secondary={unprocessedDistanceLabel}
                            />
                        </Tab>
                        <Tab
                            value={2}
                            onClick={setTab}
                        >
                            <TabLabel
                                label={
                                    <LocalizedText
                                        code="TimeSheet.Processed"
                                        value="Verwerkt"
                                    />
                                }
                                secondary={processedDistanceLabel}
                            />
                        </Tab>
                    </TabBar>
                    {
                        props.onClose &&
                            <div
                                className={styles.menu}
                            >
                                <CardInset>
                                    <CloseButton
                                        onClick={props.onClose}
                                    />
                                </CardInset>
                            </div>
                    }
                </div>
                <div
                    className={classNames(styles.tableContainer, props.noScroll && styles.noScroll)}
                >
                    <MileageSheetTable
                        allowSelectAll={tab === 1}
                        milestone={props.milestone}
                        showActivity={showActivity}
                        showBillingActivity={showBillingActivity}
                        canHaveMilestone={canHaveMilestone}
                        selectedLines={selectedLines}
                        onChangeSelectedLines={setSelectedLines}
                        constructingEntity={constructingEntity}
                        onChangeConstructingEntity={setConstructingEntity}
                        pages={pages}
                        hasMore={hasMore}
                        filter={filter}
                        startDateSortDirection={startDateSortDirection}
                        onChangeStartDateSortDirection={setStartDateSortDirection}
                        commitContext={commitContext}
                    />
                </div>
                {
                    isLoading &&
                        <CardInset>
                            <Centered
                                horizontal
                            >
                                <Loader />
                            </Centered>
                        </CardInset>
                }
                {
                    hasMore &&
                        <>
                            <HoverCardMiddle
                                onClick={loadMore}
                                disabled={isLoading}
                            >
                                <LocalizedText
                                    code="Generic.LoadMore"
                                    value="Meer laden"
                                />...
                            </HoverCardMiddle>
                            <Divider />
                        </>
                }
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    glue={dividerGlue}
                >
                    {
                        tab === 1 &&
                            <ViewGroupItem>
                                <HoverCardMiddle
                                    onClick={onAdd}
                                    disabled={constructingEntity?.isNew()}
                                >
                                    + {types.MileageRegistration.Type.getName()}
                                </HoverCardMiddle>
                            </ViewGroupItem>
                    }
                    {
                        (tab === 1 || tab === 2) &&
                            <ViewGroupItem>
                                <MileageSheetProcessingOptions
                                    relationship={relationship}
                                    activity={activity}
                                    milestone={milestone}
                                    selectedLines={selectedLines}
                                    onChangeSelectedLines={setSelectedLines}
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </Card>;
        }
    };

export default observer(MileageSheet);
