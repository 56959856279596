import { observable } from 'mobx';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import Value from '../../Value/Value';
import DynamicFunctionInvocation from '../Dynamic/DynamicFunctionInvocation';
import Computation from './Computation';

export default class DynamicComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref invocation: DynamicFunctionInvocation;

    // ------------------------ Constructor -------------------------

    constructor(invocation: DynamicFunctionInvocation)
    {
        super();

        this.invocation = invocation;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return this.invocation.getReturnType();
    }

    getName()
    {
        return this.invocation.getName();
    }

    validate(): Validation[]
    {
        return this.invocation.validate();
    }

    isAsync(): boolean
    {
        return true;
    }

    getDependencies(): Dependency[]
    {
        return this.invocation.getDependencies();
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Dynamic';
        descriptor.invocation = this.invocation.toDescriptor();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new DynamicComputation(
            await DynamicFunctionInvocation.fromDescriptor(
                descriptor,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
