import { useComputed } from 'mobx-react-lite';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import useTypes from '../../../Type/Api/useTypes';
import useIsAttachedEntityType from './useIsAttachedEntityType';
import { useContext } from 'react';
import ViewerEntityContext from '../../../Viewer/Context/ViewerEntity/ViewerEntityContext';

export default function useAttachedEntityPaths(entityType: EntityType)
{
    const types = useTypes();
    const isAttachedType = useIsAttachedEntityType(entityType);
    const viewerEntity = useContext(ViewerEntityContext);

    return useComputed(
        () =>
        {
            if (true)
                return [];

            // For now, if we are not inside a viewer, then we do not display any navigator buttons (to save performance)
            if (viewerEntity === undefined && !entityType.isA(types.Activity.Task.Type))
            {
                return [];
            }

            const rootPath = EntityPath.fromEntityType(entityType);
            const paths: EntityPath[] = [];

            if (!isAttachedType)
            {
                if (entityType.isA(types.Activity.Type))
                {
                    const linkedPath =
                        rootPath.joinTo(
                            types.Activity.RelationshipDefinition.LinkedActivities,
                            false);

                    if (!entityType.isA(types.Activity.Task.Type))
                    {
                        paths.push(
                            linkedPath.castTo(
                                types.Activity.Task.Type));
                    }

                    if (entityType.isA(types.Activity.SalesOpportunity.Type))
                    {
                        paths.push(
                            rootPath.joinTo(
                                types.Activity.RelationshipDefinition.ProductLines,
                                false));
                    }

                    if (!entityType.isA(types.Activity.Task.Type))
                    {
                        if (!entityType.isA(types.Activity.Offer.Type))
                        {
                            paths.push(
                                linkedPath.castTo(
                                    types.Activity.Offer.Type));
                        }

                        if (!entityType.isA(types.Activity.Email.Type))
                        {
                            paths.push(
                                linkedPath.castTo(
                                    types.Activity.Email.Type));
                        }
                    }
                }
                else if (entityType.isA(types.Relationship.Type))
                {
                    let activitiesPath;

                    if (entityType.isA(types.Relationship.Person.Contact.Type))
                    {
                        activitiesPath =
                            rootPath
                                .castTo(types.Relationship.Person.Contact.Type)
                                .joinTo(
                                    types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                    false);
                    }
                    else
                    {
                        activitiesPath =
                            rootPath
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.Activities,
                                    false);
                    }

                    paths.push(
                        activitiesPath.castTo(
                            types.Activity.Task.Type));

                    paths.push(
                        activitiesPath.castTo(
                            types.Activity.Offer.Type));

                    paths.push(
                        activitiesPath.castTo(
                            types.Activity.Email.Type));
                }
            }

            if (!entityType.isA(types.Note.Type)
                && !entityType.isA(types.Attachment.Type))
            {
                paths.push(
                    rootPath.joinTo(
                        types.Entity.RelationshipDefinition.Notes,
                        false));

                paths.push(
                    rootPath.joinTo(
                        types.Entity.RelationshipDefinition.Attachments,
                        false));
            }

            if (entityType.isA(types.Activity.Offer.Type)
                || entityType.isA(types.Activity.Email.Type))
            {
                paths.push(
                    rootPath
                        .joinTo(
                            types.Entity.RelationshipDefinition.Events,
                            false)
                        .castTo(types.Event.Read.Type));
            }

            if (entityType.isA(types.Attachment.Type))
            {
                paths.push(
                    rootPath
                        .joinTo(
                            types.Entity.RelationshipDefinition.Notes,
                            false)
                        .castTo(types.Note.Type));
            }

            return paths;
        },
        [
            entityType,
            types,
            viewerEntity
        ]);
}
