import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useMultiplayerViewingEntity from '../../Multiplayer/Api/useMultiplayerViewingEntity';
import { runInAction } from 'mobx';
import { saveReadEventForEntity } from './Api/saveReadEventForEntity';
import CurrentUserContext from '../../User/CurrentUserContext';
import EntityViewerContent from './EntityViewerContent';
import { useInitializedEntityForViewer } from './Api/useInitializedEntityForViewer';
import { useNewCommitContext } from '../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { useCommittableEntity } from '../../../../@Api/Entity/Commit/Context/Api/useCommittableEntity';

export type EntityViewerCommand = 'system';

export interface EntityViewerProps
{
    entity: Entity;
    inset?: boolean;
    compact?: boolean;
    openedEntity?: [Entity | undefined, Dispatch<SetStateAction<Entity | undefined>>];
    multiplayerView?: boolean;
    command?: EntityViewerCommand;
}

const EntityViewer: React.FC<EntityViewerProps> =
    ({
        entity,
        inset,
        compact,
        openedEntity,
        multiplayerView,
        command,
     }) =>
    {
        useMultiplayerViewingEntity(entity, multiplayerView);

        const commitContext = useNewCommitContext();
        const transactionalEntity = useCommittableEntity(entity, commitContext); // useFreshTransactionalEntity(entity);
        const currentUserStore = useContext(CurrentUserContext);

        useEffect(
            () =>
            {
                // Do not wait for read event to be saved
                if (!entity.isNew() && multiplayerView)
                {
                    saveReadEventForEntity(
                        entity,
                        currentUserStore.employeeEntity);
                }
            },
            [
                entity.uuid,
                currentUserStore
            ]);

        useEffect(
            () =>
                runInAction(
                    () =>
                    {
                        transactionalEntity.setManaged(true);
                        transactionalEntity.setDoAutoCommit(true);
                    }),
            [
                transactionalEntity
            ]);

        useInitializedEntityForViewer(transactionalEntity);

        return <EntityViewerContent
            entity={transactionalEntity}
            compact={compact}
            inset={inset}
            openedEntity={openedEntity}
            command={command}
            commitContext={commitContext}
        />;
    };

EntityViewer.defaultProps = {
    multiplayerView: false
};

export default observer(EntityViewer);
