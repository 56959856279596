import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import { useEffect, useMemo } from 'react';
import { useComputed } from 'mobx-react-lite';
import useLocalSetting from '../../../Setting/Api/useLocalSetting';
import useInitialValue from '../../../../../@Util/Initial/useInitialValue';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import { runInAction } from 'mobx';
import safelyApplyFunction from '../../../../../@Api/Automation/Api/safelyApplyFunction';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';

export function useLocalParameterAssignmentSetting(
    parameterDictionary?: ParameterDictionary,
    settingId?: string,
    providedParameterAssignment?: ParameterAssignment
): [ ParameterAssignment, ParameterAssignment, boolean ]
{
    const parameterAssignment =
        useMemo(
            () =>
                new ParameterAssignment(
                    new Map(providedParameterAssignment?.valueByParameter)
                ),
            [
                providedParameterAssignment
            ]
        );
    const computedParameterAssignment =
        useComputed(
            () =>
                parameterAssignment.cloneDeep(),
            [
                parameterAssignment
            ]
        );
    const [ savedParameterAssignmentDescriptor, setSavedParameterAssignment ] = useLocalSetting(settingId);
    const initialSavedParameterAssignmentDescriptor = useInitialValue(savedParameterAssignmentDescriptor);

    const [ , isLoadingInitialSavedParameterAssignment ] =
        useAsyncResult(
            async () =>
            {
                // Load values from memory
                if (settingId !== undefined
                    && parameterDictionary !== undefined
                    && initialSavedParameterAssignmentDescriptor)
                {
                    const savedParameterAssignment =
                        await ParameterAssignment.fromDescriptor(
                            new AutomationDependencyContext(
                                parameterDictionary
                            ),
                            initialSavedParameterAssignmentDescriptor
                        );

                    if (savedParameterAssignment)
                    {
                        runInAction(
                            () =>
                                savedParameterAssignment.valueByParameter
                                    .forEach(
                                        (value, parameter) =>
                                            parameterAssignment.setValue(
                                                parameter,
                                                value
                                            )
                                    )
                        );
                    }
                }

                // Initialize default values that have not yet been initialized
                for (const parameter of (parameterDictionary?.parameters ?? []))
                {
                    if (parameter.defaultValue !== undefined
                        && !parameterAssignment.hasValue(parameter))
                    {
                        const defaultValue =
                            await safelyApplyFunction(
                                parameter.defaultValue,
                                new FunctionContext(
                                    new ParameterDictionary([]),
                                    new ParameterAssignment()
                                )
                            );

                        runInAction(
                            () =>
                                parameterAssignment.setValue(
                                    parameter,
                                    defaultValue
                                )
                        );
                    }
                }

                return undefined;
            },
            [
                settingId,
                initialSavedParameterAssignmentDescriptor,
                parameterDictionary,
                parameterAssignment,
            ]
        );

    useEffect(
        () =>
        {
            if (settingId && computedParameterAssignment)
            {
                setSavedParameterAssignment(
                    computedParameterAssignment.toDescriptor()
                );
            }
        },
        [
            settingId,
            computedParameterAssignment,
            setSavedParameterAssignment,
        ]
    );

    return [
        parameterAssignment,
        computedParameterAssignment,
        isLoadingInitialSavedParameterAssignment,
    ];
}