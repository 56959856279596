import React from 'react';
import { observer } from 'mobx-react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import EntityLink from '../../../Link/EntityLink';
import Input from '../../../Input/Input';
import useTypes from '../../../Type/Api/useTypes';
import { useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { makeStyles } from '@material-ui/core';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';

export interface TimesheetResourceNameProps
{
    resource: Entity;
}

const useStyles = makeStyles({
    resourceName: {
        maxHeight: '33px',
    },
});

export const TimesheetResourceName: React.FC<TimesheetResourceNameProps> = observer(
    ({
        resource
     }) =>
    {
        const types= useTypes();
        const classes = useStyles();

        const resourceName =
            useComputed(
                () =>
                    resource.entityType.bespoke.getResourceTitle(resource),
                [
                    resource
                ]
            );

        const hasNote =
            resource.hasValueForField(
                types.TimeRegistration.Field.Description
            );
        
        return <ViewGroup
                orientation="vertical"
                spacing={5}
            >
            <ViewGroupItem
                className={classNames(classes.resourceName)}
            >
                <EntityLink
                    entity={resource}
                >
                    {
                        !hasNote &&
                        <div>{resourceName}</div>
                    }
                    {
                        hasNote &&
                        <Input
                            entity={resource}
                            field={types.TimeRegistration.Field.Description}
                            labelPosition="none"
                            disabled
                        />
                    }
                </EntityLink>
            </ViewGroupItem>
        </ViewGroup>
    }
);