import React, { useCallback, useContext, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Popper from '../../../../../../../@Future/Component/Generic/Popper/Popper';
import Avatar from '../../../../../../Domain/Entity/Avatar/Avatar';

import styles from './UserButton.module.scss';
import Menu from '../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import CurrentUserContext from '../../../../../../Domain/User/CurrentUserContext';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import AuthenticationManagerContext from '../../../../../../../@Service/Authentication/AuthenticationManagerContext';
import RouterContext from '../../../../../../../@Service/Router/RouterContext';
import Icon from '../../../../../../../@Future/Component/Generic/Icon/Icon';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import useSwitch from '../../../../../../../@Util/Switch/useSwitch';
import InviteEmployees from '../../../../../../Domain/License/InviteEmployees/InviteEmployees';
import LocalizedText from '../../../../../../Domain/Localization/LocalizedText/LocalizedText';

export interface UserButtonClasses
{
    root?: string;
}

export interface UserButtonProps
{
    classes?: UserButtonClasses
}

const UserButton: React.FC<UserButtonProps> =
    () =>
    {
        const routerContext = useContext(RouterContext);
        const currentUserStore = useContext(CurrentUserContext);
        const authenticationManagerContext = useContext(AuthenticationManagerContext);

        const entity =
            useComputed(
                () =>
                    currentUserStore.accountEntity,
                [
                    currentUserStore
                ]);

        const [ isOpen, setOpen ] = useState(false);

        const openUserSettingsCallback =
            useCallback(
                () =>
                {
                    setOpen(false);
                    routerContext.route('/user-settings');
                },
                [
                    setOpen,
                    routerContext
                ]);

        const openDevelopmentPageCallback =
            useCallback(
                () =>
                {
                    setOpen(false);
                    routerContext.route('/management/development');
                },
                [
                    setOpen,
                    routerContext
                ]);

        const signOutCallback =
            useCallback(
                () =>
                {
                    setOpen(false);
                    authenticationManagerContext.logout();
                },
                [
                    setOpen,
                    authenticationManagerContext
                ]);

        const [ isInviteColleaguesOpen, openInviteColleagues, closeInviteColleagues ] = useSwitch(false);

        return <>
            <Popper
               reference={
                   <div
                       onClick={() => setOpen(!isOpen)}
                       className={classNames(
                           styles.wrapper,
                           isOpen && styles.open
                       )}
                   >
                       <Avatar
                           entity={entity}
                           editable={false}
                           size={30}
                           classes={{
                               root: styles.avatarRoot
                           }}
                       />
                       <div className={styles.overlay} />
                       <Icon
                           color="white"
                           icon="keyboard_arrow_down"
                           className={styles.overlayIcon}
                       />
                   </div>
               }
               popper={
                   <Card>
                       <Menu
                           disableMaxHeight
                       >
                           <Item
                               key="personal"
                               name={
                                   <LocalizedText
                                       code="Settings.Personal"
                                       value="Persoonlijke instellingen"
                                   />
                               } // Personal settings
                               onClick={openUserSettingsCallback}
                           />
                           {
                               currentUserStore.isDeveloper &&
                                   <Item
                                       key="development"
                                       name={
                                           <LocalizedText
                                               code="Settings.Development"
                                               value="Development"
                                           />
                                       } // Development
                                       onClick={openDevelopmentPageCallback}
                                   />
                           }
                           {
                               currentUserStore.isAdministrator &&
                                   <Item
                                       key="invite_colleagues"
                                       name={
                                           <LocalizedText
                                               code="Settings.InviteColleagues"
                                               value="Nodig collega's uit"
                                           />
                                       }
                                       onClick={() =>
                                       {
                                           openInviteColleagues();
                                           setOpen(false);
                                       }}
                                   />
                           }
                           <Item
                               key="logout"
                               name={
                                   <LocalizedText
                                       code="Generic.Signout"
                                       value="Uitloggen"
                                   />
                               }
                               onClick={signOutCallback}
                           />
                       </Menu>
                   </Card>
               }
               open={isOpen}
               onClose={() => setOpen(false)}
            />
            {
                isInviteColleaguesOpen &&
                    <InviteEmployees
                        open={isInviteColleaguesOpen}
                        onClose={closeInviteColleagues}
                    />
            }
        </>;
    };

UserButton.defaultProps =
{

};

export default observer(UserButton);
