import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { observer, useComputed } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import TextComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/TextComputation';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import RichtextEditor from '../../../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/RichtextEditor';
import { IObservableArray, runInAction } from 'mobx';
import EditorFontSelector from '../../../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorFontSelector';
import EditorColorPicker from '../../../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorColorPicker';
import CollapsibleToolbar from '../../../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/CollapsibleToolbar';
import MenuButton from '../../../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import ComputationConstructor from '../../ComputationConstructor';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import uuid from '../../../../../../../../../../@Util/Id/uuid';
import { ComputationBlot } from './Blot/ComputationBlot';
import ComputationInTextEditor from './ComputationInTextEditor/ComputationInTextEditor';
import ComputationInText from '../../../../../../../../../../@Api/Automation/Function/Computation/ComputationInText';
import Card from '../../../../../../../../../../@Future/Component/Generic/Card/Card';
import useDelayedCallback from '../../../../../../../../../../@Future/Util/Hook/useDelayedCallback';
import { RichTextType } from '../../../../../../../../DataObject/Type/RichText/RichTextType';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useSwitch from '../../../../../../../../../../@Util/Switch/useSwitch';
import IconButton from '../../../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import ToolbarButtonWrapper from '../../../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/ToolbarButtonWrapper';
import LinkComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/LinkComputation';
import styles from './TextComputationEditor.module.scss';
import DefaultToolbarButtons from '../../../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/DefaultToolbarButtons';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import Popper from '../../../../../../../../../../@Future/Component/Generic/Popper/Popper';

const ReactQuill = require('react-quill');
const { Quill } = ReactQuill;

export interface TextComputationEditorProps extends ComputationEditorProps<PrimitiveValueType, TextComputation>
{

}

const TextComputationEditor: React.FC<TextComputationEditorProps> =
    props =>
    {
        const isRichText =
            useComputed(
                () =>
                    props.value
                        ?
                            props.value.isRichText
                        :
                            (props.type instanceof PrimitiveValueType && props.type.type instanceof RichTextType),
                [
                    props.value,
                    props.type
                ]);

        const setText =
            useCallback(
                (value?: string) =>
                    runInAction(
                        () =>
                        {
                            if (props.value)
                            {
                                props.value.text = value;
                            }
                            else
                            {
                                if (value)
                                {
                                    props.onChange(
                                        new TextComputation(
                                            value,
                                            isRichText,
                                            []));
                                }
                                else
                                {
                                    props.onChange(undefined);
                                }
                            }
                        }),
                [
                    props.value,
                    props.onChange,
                    isRichText
                ]);

        const [ quill, setQuill ] = useState<any>();
        const [ isBlotRegistered, setBlotRegistered ] =
            useState(
                () =>
                    Quill.imports[`formats/${ComputationBlot.blotName}`] === ComputationBlot
            );
        useEffect(
            () =>
            {
                Quill.register(ComputationBlot);
                setBlotRegistered(true);
            },
            [
                setBlotRegistered,
            ]);

        const [ openComputation, setOpenComputation ] = useState<ComputationInText>();
        const closeComputation =
            useCallback(
                () =>
                    setOpenComputation(undefined),
                [
                    setOpenComputation
                ]);
        const onChangeComputation =
            useCallback(
                (computation: Computation<any, any>) =>
                    runInAction(
                        () =>
                            openComputation.computation = computation),
                [
                    openComputation
                ]);

        const [ isConstructorOpen, openConstructor, closeConstructor ] = useSwitch(false);

        const addComputation =
            useCallback(
                (computation: Computation<any, any>) =>
                    runInAction(
                        () =>
                        {
                            const value = props.value || new TextComputation('', false, []);

                            const computationInText =
                                new ComputationInText(
                                    uuid(),
                                    computation);

                            value.computations.push(computationInText);

                            const quillEditor = quill.getEditor();

                            if (quillEditor)
                            {
                                const selection = quillEditor.getSelection() || { index: 0, length: 0 };

                                quillEditor.deleteText(
                                    selection.index,
                                    selection.length);

                                quillEditor.insertEmbed(
                                    selection.index,
                                    'Computation',
                                    computationInText.id,
                                    Quill.sources.USER);

                                quillEditor.setSelection(
                                    selection.index + 1,
                                    0);
                            }

                            setOpenComputation(computationInText);
                            closeConstructor();

                            if (!props.value)
                            {
                                props.onChange(value);
                            }
                        }),
                [
                    props.value,
                    quill,
                    setOpenComputation,
                    closeConstructor,
                    props.onChange
                ]);

        const addLink =
            useCallback(
                () =>
                    addComputation(
                        new LinkComputation(
                            undefined,
                            undefined)),
                [
                    addComputation
                ]);

        const [ elements, setElements ] = useState([]);

        useDelayedCallback(
            props.value?.text,
            useCallback(
                () =>
                {
                    if (quill)
                    {
                        const quillEditor = quill.getEditor();

                        if (quillEditor)
                        {
                            const computationElements = Array.from<Element>(quillEditor.container.getElementsByTagName('computation'));
                            const computationIds = new Set(computationElements.map(element => element.getAttribute('data-id')));

                            props.value?.computationById
                                .forEach(
                                    (computation, id) =>
                                    {
                                        if (!computationIds.has(id))
                                        {
                                            runInAction(
                                                () =>
                                                    (props.value.computations as IObservableArray).remove(computation));
                                        }
                                    });

                            setElements(computationElements);
                        }
                    }
                },
                [
                    quill,
                    setElements
                ]),
            300);

        const computationMenu =
            <MenuButton
                icon="add"
                inline
                tooltip={
                    <LocalizedText
                        code="ComputationEditor.AddVariableButtonTooltip"
                        value="Variabele waarde toevoegen..."
                    />
                }
                open={isConstructorOpen}
                onOpen={openConstructor}
                onClose={closeConstructor}
            >
                <ComputationConstructor
                    {...props}
                    disallowStaticValue
                    onChange={addComputation}
                />
            </MenuButton>;

        return <Popper
            open={openComputation !== undefined}
            reference={
                <>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                        alignment={isRichText ? undefined : 'center'}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            <div
                                className={styles.editor}
                            >
                                <RichtextEditor
                                    key={isBlotRegistered ? 'registered' : 'unregistered'}
                                    defaultValue={props.value?.text}
                                    onChange={setText}
                                    initialHeight={0}
                                    editorRef={setQuill}
                                    showToolbarOnFocus={props.value?.text !== undefined}
                                    autoFocus={props.autoFocus}
                                >
                                    {
                                        isRichText &&
                                            <CollapsibleToolbar>
                                                <DefaultToolbarButtons />

                                                <ToolbarButtonWrapper>
                                                    <IconButton
                                                        icon="link"
                                                        tooltip={
                                                            <LocalizedText
                                                                code="ComputationEditor.Link"
                                                                value="Link"
                                                            />
                                                        }
                                                        onClick={addLink}
                                                        small
                                                    />
                                                </ToolbarButtonWrapper>
                                                <EditorFontSelector />
                                                <EditorColorPicker
                                                    tooltip={
                                                        <LocalizedText
                                                            code="ComputationEditor.SelectColor"
                                                            value="Selecteer kleur"
                                                        />
                                                    }
                                                />
                                                {computationMenu}
                                            </CollapsibleToolbar>
                                    }
                                </RichtextEditor>
                            </div>
                        </ViewGroupItem>
                        {
                            !isRichText &&
                                <ViewGroupItem>
                                    {computationMenu}
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                    {
                        props.value &&
                            <ViewGroupItem>
                                {
                                    elements
                                        .filter(
                                            element =>
                                                props.value.computationById.has(element.getAttribute('data-id')))
                                        .map(
                                            element =>
                                                <React.Fragment
                                                    key={element.getAttribute('data-id')}
                                                >
                                                    {
                                                        ReactDOM.createPortal(
                                                            React.createElement(
                                                                ComputationInTextEditor,
                                                                {
                                                                    context: props.context,
                                                                    computationInText: props.value.computationById.get(element.getAttribute('data-id')),
                                                                    onOpen: setOpenComputation
                                                                }),
                                                            element)
                                                    }
                                                </React.Fragment>)
                                }
                            </ViewGroupItem>
                    }
                </>
            }
            popper={
                openComputation &&
                    <Card
                        inset
                    >
                        <ComputationEditor
                            context={props.context}
                            value={openComputation.computation}
                            onChange={onChangeComputation}
                        />
                    </Card>
            }
            onClose={closeComputation}
        />;
    };

export default observer(TextComputationEditor);
