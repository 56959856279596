import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { EntityFieldPath } from '../../Entity/Path/@Model/EntityFieldPath';
import { EntityPath } from '../../Entity/Path/@Model/EntityPath';
import { EntityRelationshipDefinition } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntityField } from '../../../../@Api/Model/Implementation/EntityField';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../Entity/Type/EntityTypeStore';
import getTypes from '../../Entity/Type/Api/getTypes';
import { findAssistantEntityType } from './findAssistantEntityTypeOrThrow';

export function findAssistantFieldPath(
    rootPath: EntityPath,
    path: string
): EntityFieldPath
{
    const [head, ...tail] = path.split('.');
    const relationshipField =
        findRelationshipField(
            rootPath,
            head
        );

    if (relationshipField)
    {
        const relationshipPath =
            rootPath.joinTo(
                relationshipField.relationshipDefinition,
                relationshipField.isParent
            );

        if (tail.length === 0)
        {
            return relationshipPath.field();
        }
        else
        {
            return findAssistantFieldPath(
                relationshipPath,
                tail.join('.')
            );
        }
    }
    else
    {
        const parentOrChildType =
            findParentOrChildType(
                rootPath.entityType,
                head
            );

        if (parentOrChildType)
        {
            const castPath = rootPath.castTo(parentOrChildType);

            if (tail.length === 0)
            {
                return castPath.field();
            }
            else
            {
                return findAssistantFieldPath(
                    castPath,
                    tail.join('.')
                );
            }
        }
        else
        {
            const field =
                findField(
                    rootPath.entityType,
                    head
                );

            if (field)
            {
                return rootPath.field(field);
            }
            else
            {
                throw new Error(`Could not find field: ${head} in type: ${rootPath.entityType.code} in path: ${path}`);
            }
        }
    }
}

function findRelationshipField(
    rootPath: EntityPath,
    field: string
): { isParent: boolean, relationshipDefinition: EntityRelationshipDefinition } | undefined
{
    return [ false, true ]
        .map(
            isParent =>
                rootPath.entityType.getInheritedRelationshipDefinitions(isParent)
                    .filter(
                        relationshipDefinition =>
                            relationshipDefinition.code !== 'Entity:Overrides'
                            && getRelationshipDefinitionCode(
                                relationshipDefinition,
                                isParent
                            ) === field
                    )
                    .map(
                        relationshipDefinition => ({
                            relationshipDefinition,
                            isParent,
                        })
                    )
                    .find(
                        () =>
                            true
                    )
        )
        .find(
            value =>
                value !== undefined
        );
}

function findParentOrChildType(
    entityType: EntityType,
    code: string
): EntityType | undefined
{
    const potentialChildType = findAssistantEntityType(code);

    if (potentialChildType !== undefined && potentialChildType.isCompatibleWith(entityType))
    {
        return potentialChildType;
    }
    else
    {
        return undefined;
    }
}

function findField(
    entityType: EntityType,
    code: string
): EntityField | undefined
{
    return entityType.getInheritedFields()
        .find(
            field =>
                getTextAfterLastDot(field.code) === code
        )
        ?? loadModuleDirectly(EntityTypeStore).staticType.getInheritedFields()
            .find(
                field =>
                    getTextAfterLastDot(field.code) === code
            )
        ?? (code === 'Type' || code === 'EntityType' ? getTypes().Entity.Field.Type : undefined)
        ?? (code === 'Id' ? getTypes().Entity.Field.Id : undefined)
        ?? (code === 'Name' ? getTypes().Entity.Field.Name : undefined);
}

function getRelationshipDefinitionCode(
    relationshipDefinition: EntityRelationshipDefinition,
    isParent: boolean
)
{
    const split = relationshipDefinition.code.split(':');

    if (split.length === 2)
    {
        if (isParent)
        {
            return getTextAfterLastDot(split[0]);
        }
        else
        {
            return getTextAfterLastDot(split[1]);
        }
    }
    else
    {
        return getTextAfterLastDot(relationshipDefinition.code);
    }
}

function getTextAfterLastDot(code: string): string
{
    const split = code.split('.');

    return split[split.length - 1];
}
