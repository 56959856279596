import { useCallback, useEffect, useState } from 'react';

export default function useWindowSize()
{
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeiht] = useState(window.innerHeight);

    const handleResize =
        useCallback(
            () =>
            {
                setWidth(window.innerWidth);
                setHeiht(window.innerHeight);
            },
            []);

    useEffect(
        () =>
        {
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        });

    return { width: width, height: height };
}
