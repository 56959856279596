import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import CompositeLayoutItem from '../../../../../../../../@Api/Layout/Type/CompositeLayoutItem';
import { CompositeLayoutEditorProps } from '../../CompositeLayoutEditor';
import { IObservableArray, runInAction } from 'mobx';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import CardHeader from '../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Layout from '../../../../../../../../@Api/Layout/Layout';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import InputGroup from '../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import NumberEditor from '../../../../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';
import LayoutControlsCard from '../../../../../Editor/Controls/Card/LayoutControlsCard';

export interface CompositeLayoutItemEditorProps extends CompositeLayoutEditorProps
{
    item: CompositeLayoutItem;
    onConstruct: (layout: Layout) => void;
}

const CompositeLayoutItemControls: React.FC<CompositeLayoutItemEditorProps> =
    props =>
    {
        const deleteItem =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (props.layout.items as IObservableArray).remove(props.item)),
                [
                    props.layout,
                    props.item
                ]);

        const setRatio =
            useCallback(
                (value: number | undefined) =>
                    runInAction(
                        () =>
                            props.item.ratio = value),
                [
                    props.item
                ]);

        return <LayoutControlsCard
            {...props}
            layout={props.item.layout}
            onDelete={deleteItem}
        >
            <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <CardInset
                        vertical={false}
                    >
                        <CardHeader>
                            <LocalizedText
                                code="Layout.SettingsOfElementInGroup"
                                value="Instellingen van element in groep"
                            />
                        </CardHeader>
                    </CardInset>
                </ViewGroupItem>
                <ViewGroupItem>
                    <CardInset
                        vertical={false}
                    >
                        <InputGroup>
                            <Input
                                labelPosition="left"
                                label={
                                    <LocalizedText
                                        code="Generic.Ratio"
                                        value="Ratio"
                                    />
                                }
                            >
                                <NumberEditor
                                    value={props.item.ratio}
                                    onChange={setRatio}
                                />
                            </Input>
                        </InputGroup>
                    </CardInset>
                </ViewGroupItem>
            </ViewGroup>
        </LayoutControlsCard>;
    };

export default observer(CompositeLayoutItemControls);
