import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import useTypes from '../../../../../Type/Api/useTypes';
import RelatedList from '../../../../Shared/RelatedList/RelatedList';

export interface ProjectMemberListProps
{
    entity: Entity;
}

const ProjectMemberList: React.FC<ProjectMemberListProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();
        const path =
            useComputed(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Activity.Project.RelationshipDefinition.Members,
                            false),
                [
                    props.entity,
                    currentUserStore,
                    types
                ]);
        const initializationPaths =
            useComputed(
                () => [
                    EntityPath
                        .fromEntityType(types.ProjectMember.Type)
                        .joinTo(
                            types.ProjectMember.RelationshipDefinition.Role,
                            false),
                    EntityPath
                        .fromEntityType(types.ProjectMember.Type)
                        .joinTo(
                            types.ProjectMember.RelationshipDefinition.Employee,
                            false)
                ],
                [
                    types
                ]);

        return <RelatedList
            entity={props.entity}
            path={path}
            initializationPaths={initializationPaths}
        />;
    };

export default observer(ProjectMemberList);
