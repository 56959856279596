import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import getMetadataSettingFlag from '../../../../../@Api/Metadata/getMetadataSettingFlag';
import { Setting } from '../../../../../@Api/Settings/Setting';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../Path/@Model/EntityPath';
import getTypes from '../../Type/Api/getTypes';
import getPipelineRelationshipDefinition from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/getPipelineRelationshipDefinition';
import initializeDefaultPhase from './initializeDefaultPhase';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export default async function initializeDefaultPipeline(
    entity: Entity,
    commitContext?: CommitContext
)
{
    const pipelineRelationshipDefinition = getPipelineRelationshipDefinition(entity.entityType);

    if (pipelineRelationshipDefinition
        && !getMetadataSettingFlag(entity.entityType, Setting.Metadata.HasDefaultPipeline))
    {
        const types = getTypes();

        return new EntitySelectionBuilder(types.Pipeline.Type)
            .where(
                cb =>
                    cb.relatedToEntity(
                        EntityPath.fromEntityType(types.Pipeline.Type)
                            .joinTo(
                                types.EntityType.RelationshipDefinition.Pipelines,
                                true),
                        entity.entityType.entity))
            .limit(1)
            .select()
            .then(
                results =>
                {
                    if (results.length > 0
                        // Entity might be updated by now
                        && !entity.hasRelationshipsByDefinition(
                            false,
                            pipelineRelationshipDefinition))
                    {
                        const pipeline = results[0].entity;

                        updateRelationship(
                            entity,
                            false,
                            pipelineRelationshipDefinition,
                            pipeline,
                            commitContext
                        );

                        return initializeDefaultPhase(
                            entity,
                            pipeline,
                            commitContext
                        );
                    }
                });
    }
    else
    {
        return initializeDefaultPhase(
            entity,
            undefined,
            commitContext
        );
    }
}
