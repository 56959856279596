import { EntityRelationship } from '../../../../../Model/Implementation/EntityRelationship';
import { CommitContext } from '../../CommitContext';

export function deleteRelationship(relationship: EntityRelationship,
                                   commitContext?: CommitContext)
{
    if (commitContext)
    {
        commitContext.deleteRelationship(relationship);
    }
    else
    {
        relationship.parentEntity.deleteRelationship(relationship, false);
    }
}
