import React, { useCallback, useMemo, useState } from "react";
import { Entity } from "../../../../../../../@Api/Model/Implementation/Entity";
import useApsisCampaignSendCount from "./Hooks/useApsisCampaignSendCount";
import useApsisCampaignDeliveredCount from "./Hooks/useApsisCampaignDeliverCount";
import useApsisCampaignViewedCount from "./Hooks/useApsisCampaignViewedCount";
import useApsisCampaignBouncedCount from "./Hooks/useApsisCampaignBouncedCount";
import useApsisCampaignClickedCount from "./Hooks/useApsisCampaignClickedCount";
import ViewGroup from "../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup";
import Card from "../../../../../../../@Future/Component/Generic/Card/Card";
import ViewGroupItem from "../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem";
import TabBar from "../../../../../../../@Future/Component/Generic/TabBar/TabBar";
import Tab from "../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab";
import LocalizedText from "../../../../../Localization/LocalizedText/LocalizedText";
import { ApsisDeliveredList } from "./Views/ApsisDeliveredList";
import { ApsisSendList } from "./Views/ApsisSendList";
import { ApsisEventList } from "./Views/ApsisEventList";
import { openEntity } from "../../../../@Util/openEntity";
import useTypes from "../../../../Type/Api/useTypes";
import ExpansionPanel from "../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel";
import Header from "../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header";
import CardInset from "../../../../../../../@Future/Component/Generic/Card/CardInset";
import { ApsisCampaignResultCharts } from "../RelatedActivityList/List/List/Item/Content/Activity/ApsisCampaignResult/Charts/ApsisCampaignResultCharts";
import useIsMobile from "../../../../../../../@Util/Responsiveness/useIsMobile";
import { observer } from "mobx-react";

export interface ApsisCampaignResultsProps
{
    apsisCampaign: Entity;
}

export const ApsisCampaignResults: React.FC<ApsisCampaignResultsProps> =
    observer(
        (
            {
                apsisCampaign
            }
        ) =>
        {
            const types = useTypes();
            const [ tab, setTab ] = useState(1);
            const isMobile = useIsMobile();

            const sendCount = useApsisCampaignSendCount(apsisCampaign);
            const deliveredCount = useApsisCampaignDeliveredCount(apsisCampaign);
            const viewCount = useApsisCampaignViewedCount(apsisCampaign);
            const clickedCount = useApsisCampaignClickedCount(apsisCampaign);
            const bouncedCount = useApsisCampaignBouncedCount(apsisCampaign);

            const onItemClicked = useCallback(
                entity =>
                {
                    if (entity.entityType.isA(types.Activity.ApsisCampaignResult.Type))
                    {
                        return openEntity(entity);
                    }
                    else if (entity.entityType.isA(types.ApsisCampaignEvent.Type))
                    {
                        return openEntity(
                            entity.getRelatedEntityByDefinition(
                                true,
                                types.Activity.ApsisCampaignResult.RelationshipDefinition.Events
                            )
                        );
                    }
                    return undefined;
                },
                [
                    types
                ]
            );

            const eventCode =
                useMemo(
                    () =>
                    {
                        switch(tab)
                        {
                            case 3:
                                return 'View';
                            case 4:
                                return 'Click';
                            case 5:
                                return 'Bounce';
                        }
                    },
                    [
                        tab
                    ]
                );

            return <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="vertical"
                        spacing={15}
                    >
                        <ViewGroupItem>
                            {
                                isMobile
                                    ?
                                        <Card>
                                            <ExpansionPanel
                                                id="apsisResultCharts"
                                                summary={
                                                    <Header
                                                        large
                                                        inset
                                                        title={
                                                            <LocalizedText
                                                                code="Campaign.Overview"
                                                                value="Overzicht"
                                                            />
                                                        }
                                                    />
                                                }
                                                expansion={
                                                    <CardInset>
                                                        <ApsisCampaignResultCharts
                                                            apsisCampaign={apsisCampaign}
                                                        />
                                                    </CardInset>
                                                }
                                            />
                                        </Card>
                                    :
                                        <Card
                                            inset
                                        >
                                            <ApsisCampaignResultCharts
                                                apsisCampaign={apsisCampaign}
                                            />
                                        </Card>
                            }
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Card>
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                <TabBar
                                    value={tab}
                                >
                                    <Tab
                                        value={1}
                                        onClick={setTab}
                                    >
                                        <LocalizedText
                                            code="Campaign.SendCount"
                                            value="Verzonden (${sendCount})"
                                            sendCount={sendCount}
                                        />
                                    </Tab>
                                    <Tab
                                        value={2}
                                        onClick={setTab}
                                    >
                                        <LocalizedText
                                            code="Campaign.DeliveredCount"
                                            value="Bezorgd (${deliveredCount})"
                                            deliveredCount={deliveredCount}
                                        />
                                    </Tab>
                                    <Tab
                                        value={3}
                                        onClick={setTab}
                                    >
                                        <LocalizedText
                                            code="Campaign.OpenedCount"
                                            value="Geopend (${openedCount})"
                                            openedCount={viewCount}
                                        />
                                    </Tab>
                                    <Tab
                                        value={4}
                                        onClick={setTab}
                                    >
                                        <LocalizedText
                                            code="Campaign.ClickedCount"
                                            value="Aangeklikt (${clickedCount})"
                                            clickedCount={clickedCount}
                                        />
                                    </Tab>
                                    <Tab
                                        value={5}
                                        onClick={setTab}
                                    >
                                        <LocalizedText
                                            code="Campaign.BouncedCount"
                                            value="Onbezorgbaar (${bouncedCount})"
                                            bouncedCount={bouncedCount}
                                        />
                                    </Tab>
                                </TabBar>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                {
                                    tab === 1 &&
                                        <ApsisSendList
                                            apsisCampaign={apsisCampaign}
                                            onItemClicked={onItemClicked}
                                        />
                                }
                                {
                                    tab === 2 &&
                                        <ApsisDeliveredList
                                            apsisCampaign={apsisCampaign}
                                            onItemClicked={onItemClicked}
                                        />
                                }
                                {
                                    tab > 2 &&
                                    <ApsisEventList
                                        apsisCampaign={apsisCampaign}
                                        eventCode={eventCode}
                                        onItemClicked={onItemClicked}
                                    />
                                }
                            </ViewGroupItem>
                        </ViewGroup>
                    </Card>
                </ViewGroupItem>
            </ViewGroup>;
        }
    );