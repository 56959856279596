import React from 'react';
import useTypes from '../../../../../../../../../../../Type/Api/useTypes';
import { Entity } from '../../../../../../../../../../../../../../@Api/Model/Implementation/Entity';
import localizeText from '../../../../../../../../../../../../../../@Api/Localization/localizeText';
import useApsisCampaignResultCount from '../Api/useApsisCampaignResultViewCount';
import { NumberChart } from './NumberChart';
import { observer } from 'mobx-react-lite';

export interface ApsisCampaignResultChartClickedProps
{
    size: number;
    apsisCampaignResult: Entity;
}

export const ApsisCampaignResultChartClicked: React.FC<ApsisCampaignResultChartClickedProps> =
    observer(
        (
            {
                apsisCampaignResult,
                size,
            }
        ) =>
        {
            const types = useTypes();
            const viewCount = useApsisCampaignResultCount(
                apsisCampaignResult,
                types.ApsisCampaignEvent.EventType.Click
            );

            return <NumberChart
                    value={viewCount}
                    size={size}
                    label={localizeText('ApsisCampaign.Chart.Clicked', 'Geklikt')}
                />;
        }
    );