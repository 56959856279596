import * as React from 'react';
import { dimmedColor, EntityEventType } from '../Model/EntityEventType';
import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { EntityReadEvent } from '../../../../../@Api/Model/Implementation/EntityReadEvent';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { Typography } from '@material-ui/core';
import { EntityCreationMutation } from '../../../../../@Api/Model/Implementation/EntityCreationMutation';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';
import { BadgeStore } from '../../../../Generic/Badge/BadgeStore';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { ImportanceLevel } from '../../../../../@Api/Model/Implementation/EntityField';

export class EventRead extends EntityEventType
{
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Entity.Read';
    }

    parentId(): string
    {
        return null;
    }

    isEnabledByDefault(): boolean
    {
        return false;
    }

    color(event: EntityEvent): string
    {
        return dimmedColor(event.entity.entityType);
    }

    icon(event: EntityEvent): string
    {
        return 'low_priority';
    }

    isFilled(event: EntityReadEvent): boolean
    {
        return false;
    }

    importanceLevel(event: EntityEvent): ImportanceLevel
    {
        return ImportanceLevel.Normal;
    }

    name(event: EntityReadEvent): string
    {
        return this.localizationStore.translate('Event.Read.ActionName', event.entity.entityType.nameSingular); // {type} viewed
    }

    description(event: EntityReadEvent): string
    {
        return undefined;
    }

    content(event: EntityEvent): JSX.Element
    {
        return <Typography variant="body2">
            {
                this.localizationStore.translate('Event.Read.Name', event.entity.entityType.nameSingular)}
        </Typography>;
    }

    caption(event: EntityEvent): string
    {
        return undefined;
    }

    actions(event: EntityReadEvent): ButtonStore[]
    {
        return [];
    }

    badges(event: EntityEvent): BadgeStore[]
    {
        return [];
    }

    allowExpansion(event: EntityReadEvent): boolean
    {
        return false;
    }

    expandInSidebar(event: EntityCreationMutation): boolean
    {
        return false;
    }

    expansionComponent(event: EntityReadEvent): React.ComponentClass<BaseComponentProps<BaseStore>>
    {
        return null;
    }

    expansionStore(event: EntityReadEvent): BaseStore
    {
        return null;
    }

    typeNameSingular(event: EntityReadEvent): string
    {
        return this.localizationStore.translate('Event.Read.Singular.Name'); // viewed
    }

    typeNamePlural(): string
    {
        return this.localizationStore.translate('Event.Read.Plural.Name'); // viewed
    }
}
