import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import CardLayout from '../../../../../@Api/Layout/Type/CardLayout';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import Layout from '../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';

export interface CardLayoutEditorProps extends LayoutEditorProps<CardLayout>
{

}

const CardLayoutEditor: React.FC<CardLayoutEditorProps> =
    props =>
    {
        const setLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.layout = layout),
                [
                    props.layout
                ]);

        const style =
            useComputed(
                () => ({
                    backgroundColor: props.layout.backgroundColor?.toCss()
                }),
                [
                    props.layout
                ]);

        return <Card
            style={style}
        >
            <ChildLayoutEditor
                {...props}
                childLayout={props.layout.layout}
                onChange={setLayout}
            />
        </Card>;
    };

export default observer(CardLayoutEditor);
