import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { isPrimitiveTypeSearchable } from '../../../../../@Api/Automation/Api/isPrimitiveTypeSearchable';

export function isFieldPathSearchable(fieldPath: EntityFieldPath)
{
    if (fieldPath.field)
    {
        return isPrimitiveTypeSearchable(fieldPath.field.dataObjectSpecification.type);
    }
    else
    {
        return false;
    }
}
