import Role, { Privilege } from '../../../Model/Role';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { resetEntityTypeFieldsAndRelationshipsPermissions } from './resetEntityTypeFieldsAndRelationshipsPermissions';

export function resetChildEntityTypePermissions(
    role: Role,
    privileges: Privilege[],
    entityType: EntityType
)
{
    entityType
        .childTypes
        .forEach(
            childType =>
            {
                const childRight = role.rightByType.get(childType);

                if (childRight)
                {
                    privileges
                        .forEach(
                            privilege =>
                                childRight.setPermission(privilege, 'Inherited')
                        );
                }

                resetEntityTypeFieldsAndRelationshipsPermissions(
                    role,
                    privileges,
                    entityType
                );

                resetChildEntityTypePermissions(
                    role,
                    privileges,
                    childType
                )

                if (childRight)
                {
                    role.rightByType.set(childType, childRight);
                }
            }
        );
}