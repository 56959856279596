import useTypes from '../../../Entity/Type/Api/useTypes';
import { useContext } from 'react';
import CurrentUserContext from '../../../User/CurrentUserContext';
import usePaginatedSelection, { Page } from '../../../Entity/View/Api/usePaginatedSelection';

export default function useNotifications(
    onlyRead?: boolean
): [Page[], boolean, ((nextPage?: number) => Promise<boolean>), boolean]
{
    const currentUserStore = useContext(CurrentUserContext);
    const types = useTypes();

    const [ pages, hasMore, loadMore, isLoading ] =
        usePaginatedSelection(
            types.Notification.Type,
            (builder, rootPath) =>
                builder
                    .if(
                        () => onlyRead !== undefined,
                        () =>
                            builder.where(
                                cb =>
                                    cb.eq(
                                        rootPath.field(types.Notification.Field.IsRead),
                                        undefined,
                                        onlyRead
                                    )
                            )
                    )
                    .where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath.joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.Notifications,
                                    true),
                                currentUserStore.employeeEntity))
                    .join(
                        rootPath.joinTo(
                            types.Entity.RelationshipDefinition.Notifications,
                            true))
                    .join(
                        rootPath.joinTo(
                            types.Notification.RelationshipDefinition.Initiator,
                            false))
                    .orderBy(
                        rootPath.field(types.Entity.Field.Id),
                        false)
            ,
            [
                types,
                currentUserStore,
                onlyRead
            ]);

    return [
            pages,
            hasMore,
            loadMore,
            isLoading
        ];
}
