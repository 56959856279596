import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PortalDataSourceSignature from '../../../Portal/DataSource/PortalDataSourceSignature';
import DynamicParameterAssignment from '../Dynamic/DynamicParameterAssignment';
import DataSourceValueType from '../../Value/Type/DataSourceValueType';
import getPortalDataSourceSignatureById from '../../../Portal/DataSource/Api/getPortalDataSourceSignatureById';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import queryPortalDataSource from '../../../Portal/DataSource/Api/queryPortalDataSource';
import PortalDataSourceIdsQuery from '../../../Portal/DataSource/PortalDataSourceIdsQuery';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import { TextType } from '../../../../@Component/Domain/DataObject/Type/Text/TextType';
import uuid from 'uuid';
import PortalDataSourceValue from '../../../Portal/DataSource/PortalDataSourceValue';
import DataSourceValue from '../../Value/DataSourceValue';
import EmptyValue from '../../Value/EmptyValue';
import localizeText from '../../../Localization/localizeText';
import PrimitiveValue from '../../Value/PrimitiveValue';

export default class DataSourceValueByIdComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref dataSourceSignature: PortalDataSourceSignature;
    @observable.ref parameterAssignment: DynamicParameterAssignment;
    @observable.ref valueId: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(dataSourceSignature: PortalDataSourceSignature,
                parameterAssignment: DynamicParameterAssignment,
                valueId: Computation<any, any>)
    {
        super();

        this.dataSourceSignature = dataSourceSignature;
        this.parameterAssignment = parameterAssignment;
        this.valueId = valueId;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new DataSourceValueType(
            this.dataSourceSignature.id,
            undefined,
            this.dataSourceSignature);
    }

    isAsync(): boolean
    {
        return true;
    }

    async apply(context: FunctionContext)
    {
        const [ id, parameterAssignment ] =
            await Promise.all([
                safelyApplyFunction(this.valueId, context),
                this.parameterAssignment.apply(context)
            ]);

        if (id instanceof PrimitiveValue
            && id.value.type instanceof TextType)
        {
            const queryResult =
                await queryPortalDataSource(
                    new PortalDataSourceIdsQuery(
                        uuid(),
                        this.dataSourceSignature,
                        parameterAssignment,
                        [
                            id.value.value
                        ]));

            if (queryResult.results.length > 0)
            {
                return new DataSourceValue(
                    new PortalDataSourceValue(
                        queryResult.results[0].id,
                        this.dataSourceSignature.id,
                        parameterAssignment,
                        queryResult.results[0].parameterAssignment));
            }
            else
            {
                return EmptyValue.instance;
            }
        }
        else
        {
            return EmptyValue.instance;
        }
    }

    getName(): string
    {
        return localizeText('Computation.DataSourceValueById', 'Databronwaarde met ID...');
    }

    validate(): Validation[]
    {
        if (!this.dataSourceSignature)
        {
            return [
                new Validation('Error', 'Geen databron geselecteerd')
            ];
        }
        if (!this.valueId)
        {
            return [
                new Validation('Error', 'Geen ID geselecteerd')
            ];
        }

        return [
            ...this.parameterAssignment.validate(),
            ...this.valueId.validate()
        ];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'DataSourceValueById';
        descriptor.dataSourceId = this.dataSourceSignature.id;
        descriptor.parameterAssignment = this.parameterAssignment.toDescriptor();
        descriptor.valueId = this.valueId.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.parameterAssignment.getDependencies(),
            ...this.valueId.getDependencies()
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const dataSourceSignature = await getPortalDataSourceSignatureById(descriptor.dataSourceId);
        const parameterAssignment =
            await DynamicParameterAssignment.fromDescriptor(
                descriptor.parameterAssignment,
                dataSourceSignature.parameters,
                dependencyContext);
        const valueId =
            await getComputationFromDescriptor(
                descriptor.valueId,
                dependencyContext);

        return new DataSourceValueByIdComputation(
            dataSourceSignature,
            parameterAssignment,
            valueId);
    }

    // ----------------------- Private logic ------------------------
}
