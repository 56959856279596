import { DateRangeValue } from '../DateRangeValue';
import { addDays, addMonths, addQuarters, addWeeks, addYears, startOfDay, startOfMonth, startOfQuarter, startOfWeek, startOfYear } from 'date-fns';

export default function getDatesFromRange(range?: DateRangeValue): [ Date, Date ]
{
    if (!range)
    {
        return [ undefined, undefined ];
    }

    let from: Date;
    let to: Date;
    let now = new Date();

    // Note that selections on period always uses "from >= date and date < to"
    // So one day should be added to the to date
    switch (range.type)
    {
        case 'DateRange':
            from = startOfDay(range.from);
            to = startOfDay(range.to);
            break;

        case 'DateTimeRange':
            from = range.from;
            to = range.to;
            break;

        case 'Month':
            if (range.from)
            {
                from = startOfMonth(range.from);
                to = startOfMonth(addMonths(range.from, 1));
            }
            else
            {
                from = startOfMonth(now);
                to = startOfMonth(addMonths(now, 1));
            }
            break;

        case 'Quarter':
            if (range.from)
            {
                from = startOfQuarter(range.from);
                to = startOfQuarter(addQuarters(range.from, 1));
            }
            else
            {
                from = startOfQuarter(now);
                to = startOfQuarter(addQuarters(now, 1));
            }
            break;

        case 'Year':
            if (range.from)
            {
                from = startOfYear(range.from);
                to = startOfYear(addYears(range.from, 1));
            }
            else
            {
                from = startOfYear(now);
                to = startOfYear(addYears(now, 1));
            }
            break;

        case 'Today':
            from = startOfDay(now);
            to = startOfDay(addDays(now, 1));
            break;

        case 'TodayOrEarlier':
            from = undefined;
            to = startOfDay(addDays(now, 1));
            break;

        case 'CurrentWeek':
            from = startOfWeek(now);
            to = startOfWeek(addWeeks(now, 1));
            break;

        case 'CurrentWeekOrEarlier':
            from = undefined;
            to = startOfWeek(addWeeks(now, 1));
            break;

        case 'LastWeek':
            from = startOfWeek(addWeeks(now, -1));
            to = startOfWeek(now);
            break;

        case 'NextWeek':
            from =  startOfWeek(addWeeks(now, 1));
            to =  startOfWeek(addWeeks(now, 2));
            break;

        case 'CurrentMonth':
            from = startOfMonth(now);
            to = startOfMonth(addMonths(now, 1));
            break;

        case 'LastMonth':
            from = startOfMonth(addMonths(now, -1));
            to = startOfMonth(now);
            break;

        case 'NextMonth':
            from = startOfMonth(addMonths(now, 1));
            to = startOfMonth(addMonths(now, 2));
            break;

        case 'CurrentQuarter':
            from = startOfQuarter(now);
            to = startOfQuarter(addQuarters(now, 1));
            break;

        case 'LastQuarter':
            from = startOfQuarter(addQuarters(now, -1));
            to = startOfQuarter(now);
            break;

        case 'NextQuarter':
            from = startOfQuarter(addQuarters(now, 1));
            to = startOfQuarter(addQuarters(now, 2));
            break;

        case 'CurrentYear':
            from = startOfYear(now);
            to = startOfYear(addYears(now, 1));
            break;

        case 'LastYear':
            from = startOfYear(addYears(now, -1));
            to = startOfYear(now);
            break;

        case 'NextYear':
            from = startOfYear(addYears(now, 1));
            to = startOfYear(addYears(now, 2));
            break;
    }
    
    return [
         isNaN(from?.getTime()) ? undefined : from,
         isNaN(to?.getTime()) ? undefined : to
    ];
}
