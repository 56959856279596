import * as React from 'react';
import { observer } from 'mobx-react';
import { RichTextEditorStore } from './RichTextEditorStore';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { RichTextToolbar } from '../Toolbar/RichTextToolbar';
import './Styles/styles.scss';
import { classNames } from '../../../../@Future/Util/Class/classNames';

const ReactQuill = require('react-quill');

@observer
export class RichTextEditor extends BaseComponent<RichTextEditorStore>
{
    renderComponent(store: RichTextEditorStore)
    {
        const editor =
            <ReactQuill
                style={{
                    width: '100%',
                    marginRight:
                        store.richTextToolbarStore &&
                        store.richTextToolbarStore.hasCollapsibleControls ? 20 : undefined
                }}
                ref={store.setQuillReference}
                value={
                    store.content ? store.content : ''
                }
                onChange={(newValue: any, delta: any, source: any) =>
                {
                    store.setRichTextContent(
                        newValue,
                        source !== 'user');
                }}
                onKeyDown={
                    store.onCtrlEnter
                    ?
                        e =>
                        {
                            // Capture the control + enter key combination
                            if (e.ctrlKey && e.keyCode === 13)
                            {
                                store.onCtrlEnter(store);
                            }
                        }
                    :
                        undefined}
                onChangeSelection={store.updateCursorPosition}
                modules={{
                    toolbar: [], // [{ indent: '-1'}, { indent: '+1' }] [[{ size: ['small', false, 'large', 'huge'] }, { color: [ '#ff0000', '#00ff00', '#0000ff'] }]],
                    clipboard: { matchVisual: false }
                }}
                formats={
                    store.allowTextStyling
                        ?
                        [
                            'header', 'font', 'color', 'size',
                            'bold', 'italic', 'underline', 'strike',
                            'align', 'list', 'bullet', 'indent',
                            'link', 'Computation'
                        ]
                        :
                        [
                            'Computation'
                        ]
                }
                placeholder={store.placeholder}
            />;

        return <div
            style={{
                position: 'relative',
                width: '100%'
            }}
            onClick={(e) => e.stopPropagation()}
            onFocus={store.focus}
            onBlur={store.delayedBlur}
        >
            <div
                className={
                    classNames(
                        'rich-textbox-editor',
                        store.props.classes && store.props.classes.editor)}
                style={{
                    width: '100%',
                    minHeight: `${store.initialHeight}px`,
                    display: 'flex',
                }}
            >
                {editor}
            </div>
            {
                store.richTextToolbarStore &&
                    <RichTextToolbar store={store.richTextToolbarStore} />
            }
        </div>;
    }
}
