import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import { default as TriggerModel } from '../../../../../../../../@Api/Automation/Trigger/Trigger';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardHeader from '../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import CreationTrigger from '../../../../../../../../@Api/Automation/Trigger/CreationTrigger';
import DeletionTrigger from '../../../../../../../../@Api/Automation/Trigger/DeletionTrigger';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import Divider from '../../../../../../../../@Future/Component/Generic/Divider/Divider';
import FieldPathSelector from '../../../../../Path/FieldPathEditor/FieldPathSelector';
import { EntityFieldPath } from '../../../../../Path/@Model/EntityFieldPath';
import UpdateTrigger from '../../../../../../../../@Api/Automation/Trigger/UpdateTrigger';
import resolveInputFromFieldPath from '../../../../../../Multiplayer/Model/Input/Api/resolveInputFromFieldPath';
import { EntityContext } from '../../../../../@Model/EntityContext';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import Popper from '../../../../../../../../@Future/Component/Generic/Popper/Popper';
import useSwitch from '../../../../../../../../@Util/Switch/useSwitch';
import PredicateEditor from '../Predicate/PredicateEditor';
import FunctionContext from '../../../../../../../../@Api/Automation/Function/FunctionContext';
import PrimaryTextButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryTextButton/PrimaryTextButton';
import { runInAction } from 'mobx';
import CompositePredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../../../DataObject/Model/LogicalOperator';
import ComparisonPredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import { Comparator } from '../../../../../../DataObject/Model/Comparator';
import CronTrigger from '../../../../../../../../@Api/Automation/Trigger/CronTrigger';
import CronEditor, { everyDayAt12Cron } from '../../../../../../../../@Future/Component/Generic/Input/CronEditor/CronEditor';
import MutationTrigger from '../../../../../../../../@Api/Automation/Trigger/MutationTrigger';
import CompositeTrigger from '../../../../../../../../@Api/Automation/Trigger/CompositeTrigger';
import CompositeTriggerEditor from './Composite/CompositeTriggerEditor';
import DeleteIconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import Predicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';

export interface TriggerProps
{
    entityType?: EntityType;
    trigger?: TriggerModel;
    onCreate?: (trigger: TriggerModel) => void;
    onDelete?: (trigger: TriggerModel) => void;
    context?: FunctionContext;
    idx?: number;
    onlyAllowMutationTriggers?: boolean;
}

const TriggerEditor: React.FC<TriggerProps> =
    props =>
    {
        const constructCreationTrigger =
            useCallback(
                () =>
                    props.onCreate &&
                        props.onCreate(
                            new CreationTrigger(
                                props.entityType,
                                undefined)),
                [
                    props.onCreate,
                    props.entityType
                ]);

        const constructDeletionTrigger =
            useCallback(
                () =>
                    props.onCreate(
                        new DeletionTrigger(
                            props.entityType,
                            undefined)),
                [
                    props.onCreate,
                    props.entityType
                ]);

        const constructCronTrigger =
            useCallback(
                () =>
                    props.onCreate(
                        new CronTrigger(
                            undefined,
                            everyDayAt12Cron)),
                [
                    props.onCreate,
                    props.entityType
                ]);

        const constructCompositeTrigger =
            useCallback(
                () =>
                    props.onCreate(
                        new CompositeTrigger(
                            props.entityType,
                            undefined,
                            LogicalOperator.Or,
                            [])),
                [
                    props.onCreate,
                    props.entityType
                ]);

        const constructUpdateTrigger =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    props.onCreate(
                        new UpdateTrigger(
                            props.entityType,
                            undefined,
                            resolveInputFromFieldPath(fieldPath))),
                [
                    props.onCreate,
                    props.entityType
                ]);

        const fieldContext =
            useMemo(
                () =>
                    new EntityContext(
                        [],
                        EntityPath.fromEntityType(props.entityType)),
                [
                    props.entityType
                ]);

        const createFilter =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (props.trigger as MutationTrigger).predicate =
                                new CompositePredicate(
                                    LogicalOperator.And,
                                    [
                                        new ComparisonPredicate(
                                            undefined,
                                            Comparator.Equals,
                                            undefined)
                                    ])),
                [
                    props.trigger
                ]);

        const [ isFieldSelectorOpen, openFieldSelector, closeFieldSelector ] = useSwitch(false);

        const setCron =
            useCallback(
                (cron: string) =>
                    runInAction(
                        () =>
                            (props.trigger as CronTrigger).cron = cron),
                [
                    props.trigger
                ]);

        const onDelete =
            useCallback(
                () =>
                    props.onDelete(props.trigger),
                [
                    props.onDelete
                ]);

        const setPredicate =
            useCallback(
                (predicate: Predicate) =>
                    runInAction(
                        () =>
                            props.trigger.predicate = predicate),
                [
                    props.trigger
                ]);

        if (props.trigger)
        {
            return <>
                <CardInset>
                    <CardHeader>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                {
                                    props.idx === undefined
                                    ?
                                        <LocalizedText
                                            code="Automation.Trigger"
                                            value="Trigger"
                                        />
                                    :
                                        `${props.idx + 1}. ${props.trigger.getName()}`
                                }
                            </ViewGroupItem>
                            {
                                props.onDelete &&
                                    <ViewGroupItem>
                                        <DeleteIconButton
                                            onClick={onDelete}
                                        />
                                    </ViewGroupItem>
                            }
                        </ViewGroup>
                    </CardHeader>
                    <ViewGroup
                        orientation="vertical"
                        spacing={15}
                    >
                        {
                            props.idx === undefined &&
                                <ViewGroupItem>
                                    {props.trigger.getName()}
                                </ViewGroupItem>
                        }
                        {
                            props.trigger.predicate &&
                                <ViewGroupItem>
                                    <PredicateEditor
                                        context={props.context}
                                        onChange={setPredicate}
                                        value={props.trigger.predicate}
                                    />
                                </ViewGroupItem>
                        }
                        {
                            !props.trigger.predicate &&
                                <ViewGroupItem>
                                    <PrimaryTextButton
                                        label={
                                            <LocalizedText
                                                code="Automation.AddFilter"
                                                value="Filter toevoegen"
                                            />
                                        }
                                        onClick={createFilter}
                                    />
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                </CardInset>
                {
                    props.trigger instanceof CronTrigger &&
                        <CardInset
                            top={false}
                            horizontal={false}
                        >
                            <CronEditor
                                value={props.trigger.cron}
                                onChange={setCron}
                            />
                        </CardInset>
                }
                {
                    props.trigger instanceof CompositeTrigger &&
                    props.context &&
                        <CompositeTriggerEditor
                            entityType={props.entityType}
                            context={props.context}
                            trigger={props.trigger}
                        />
                }
            </>
        }
        else
        {
            return <Popper
                reference={
                    <>
                        <CardInset>
                            <CardHeader>
                                <LocalizedText
                                    code="Automation.CreateTrigger"
                                    value="Nieuwe trigger toevoegen..."
                                />
                            </CardHeader>
                        </CardInset>
                        <Menu>
                            <Item
                                name={
                                    <LocalizedText
                                        code="Automation.AfterCreating"
                                        value="Na het aanmaken van een nieuwe ${entityTypeName}..."
                                        entityTypeName={props.entityType.getName().toLowerCase()}
                                    />
                                }
                                onClick={constructCreationTrigger}
                            />
                            <Item
                                name={
                                    <LocalizedText
                                        code="Automation.AfterMutating"
                                        value="Na het muteren van een veld in een ${entityTypeName}..."
                                        entityTypeName={props.entityType.getName().toLowerCase()}
                                    />
                                }
                                onClick={openFieldSelector}
                                active={isFieldSelectorOpen}
                            />
                            <Item
                                name={
                                    <LocalizedText
                                        code="Automation.AfterRemoving"
                                        value="Na het verwijderen van een ${entityTypeName}..."
                                        entityTypeName={props.entityType.getName().toLowerCase()}
                                    />
                                }
                                onClick={constructDeletionTrigger}
                            />
                            {
                                !props.onlyAllowMutationTriggers &&
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Automation.EachPeriod"
                                                value="Elke dag/week/maand/jaar..."
                                            />
                                        }
                                        onClick={constructCronTrigger}
                                    />
                            }
                            {
                                !props.onlyAllowMutationTriggers &&
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Automation.CombinationOfTriggers"
                                                value="Combinatie van triggers..."
                                            />
                                        }
                                        onClick={constructCompositeTrigger}
                                    />
                            }
                        </Menu>
                        {
                            isFieldSelectorOpen &&
                                <>
                                    <Divider />
                                </>
                        }
                    </>
                }
                popper={
                    <Card>
                        <FieldPathSelector
                            context={fieldContext}
                            onSelect={constructUpdateTrigger}
                        />
                    </Card>
                }
                open={isFieldSelectorOpen}
                onClose={closeFieldSelector}
            />;
        }
    };

export default observer(TriggerEditor);
