import React, { useCallback, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Dialog from '../../../../../../../../@Future/Component/Generic/Dialog/Dialog';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import DialogTitle from '../../../../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import DialogContent from '../../../../../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import DialogActions from '../../../../../../../../@Future/Component/Generic/Dialog/Actions/DialogActions';
import Input from '../../../../../Input/Input';
import SaveButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import InputGroup from '../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import { useNewCommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { commitEntityWithContext } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';

export interface RelationshipEditorDialogProps
{
    entity: Entity;
    saveCallback: (successful: boolean) => void;
    closeCallback: () => void;
}

const RelationshipEditorDialog: React.FC<RelationshipEditorDialogProps> =
    ({
        entity,
        saveCallback,
        closeCallback,
     }) =>
    {
        const types = useTypes();

        // Create transactional copy of the passed entity so that
        // we can commit only when the apply button is pressed.
        const commitContext = useNewCommitContext(undefined, { allowAutoCommit: false });

        const fieldPaths =
            useComputed(
                () => [
                    EntityPath.fromEntity(entity)
                        .joinTo(
                            types.Relationship.RelationshipDefinition.Activities,
                            true)
                        .field(),
                    EntityPath.fromEntity(entity)
                        .joinTo(
                            types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                            true)
                        .field()
                ].filter(
                    fieldPath =>
                        !entity.entityType.bespoke.hideFieldPath(entity, fieldPath, undefined, false, commitContext)),
                [
                    entity,
                    types,
                    commitContext
                ]);

        const [ isTouched, setTouched ] = useState(false);

        const save =
            useCallback(
                () =>
                {
                    setTouched(true);

                    return commitEntityWithContext(
                        entity,
                        commitContext
                    ).then(
                        () =>
                            saveCallback(true)
                    );
                },
                [
                    setTouched,
                    entity,
                    commitContext,
                    saveCallback,
                ]);

        return <Dialog
            open={true}
            onClose={closeCallback}
            width="sm"
        >
            <DialogTitle>
                {types.Relation.Type.getName(true)}
            </DialogTitle>
            <DialogContent>
                <InputGroup>
                    {
                        fieldPaths.map(
                            (fieldPath, idx) =>
                                <Input
                                    key={fieldPath.id}
                                    autoFocus={idx === 0}
                                    entity={entity}
                                    field={fieldPath}
                                    doAutocommit={false}
                                    underline="hover"
                                    touched={isTouched}
                                    labelPosition="left"
                                    commitContext={commitContext}
                                    useParentCommitContextForConstruction
                                />)
                    }
                </InputGroup>
            </DialogContent>
            <DialogActions>
                <SaveButton
                    onClick={save}
                />
            </DialogActions>
        </Dialog>
    };

export default observer(RelationshipEditorDialog);
