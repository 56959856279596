import React, { useMemo } from 'react';
import { PanelBody, PanelRow, SelectControl } from '@wordpress/components';
import { BlockEditProps } from '@wordpress/blocks';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';

export interface LabelOptionsPanelProps
{
    props: BlockEditProps<{}>;
    open?: boolean;
}

const LabelOptionsPanel: React.FC<LabelOptionsPanelProps> =
    props =>
    {
        const {
            labelPosition,
        } = props.props.attributes as any;

        const labelPositions =
            useMemo(
                () => [
                    {
                        label: 'Links',
                        value: 'left'
                    },
                    {
                        label: 'Boven',
                        value: 'top'
                    },
                    {
                        label: 'In het veld',
                        value: 'placeholder'
                    },
                    {
                        label: 'Géén',
                        value: 'none'
                    }
                ],
                []);

        return <PanelBody
            title={
                <LocalizedText
                    code="Generic.Label"
                    value="Label"
                />
            }
            initialOpen={!!props.open}
        >
            <PanelRow>
                <SelectControl
                    options={labelPositions}
                    value={labelPosition}
                    onChange={labelPosition => props.props.setAttributes({ labelPosition: labelPosition })}
                />
            </PanelRow>
        </PanelBody>;
    };

export default LabelOptionsPanel;
