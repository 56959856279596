export function isAllDay(start: Date, end: Date)
{
    if (start && end)
    {
        const diff = end.getTime() - start.getTime();

        return start
            && start.getHours() === 0
            && start.getMinutes() === 0
            && diff >= 1000 * 60 * 60 * 24 - 60 * 1000; // from 23:59:00 it is accounted for as 'all day'
    }
    else
    {
        return false;
    }
}
