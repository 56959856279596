import * as React from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Theme, withStyles } from '@material-ui/core';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { ListStore } from '../List/V2/ListStore';
import { Loader } from '../Loader/Loader';
import { View } from '../ViewStack/View';
import ListGroup from '../List/V2/ListGroup';
import Button from '../Button/Button';
import { ListItem } from '../List/V2/Item/ListItem';
import { ListItemStore } from '../List/V2/Item/ListItemStore';
import Text from '../Text/Text';

// noinspection TsLint
const styles =
    (theme: Theme) =>
        ({

        });

@observer
class GroupedMenuList extends BaseComponent<ListStore<any, any, any, any, any, any>>
{
    renderLoader(store: ListStore<any, any, any, any, any, any>)
    {
        return <>
            {this.renderComponent(store)}
            {
                !store.template && store.showLoader &&
                    <Loader />
            }
        </>;
    }

    renderComponent(store: ListStore<any, any, any, any, any, any>): JSX.Element
    {
        if (store.template)
        {
            return <View
                viewComponent={store.template}
            />;
        }
        else if ((store.visibleItemStores.length === 0 || store.isEmpty) && !store.isLoading && !store.createButton)
        {
            if (store.isEmpty && !store.showNoDataLabel && !store.isDraggable)
            {
                return null;
            }
            else
            {
                return <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 60,
                        padding: 24
                    }}
                >
                    <div
                        style={{
                            flex: '1 1 auto'
                        }}
                    >
                        {
                            store.isDraggable
                                ?
                                    <Droppable
                                        droppableId={store.droppableId()}
                                        type={store.droppableType()}
                                    >
                                        {
                                            (provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={{ minHeight: 40 }}
                                                >
                                                    {this.renderEmptyList(store)}
                                                    {provided.placeholder}
                                                </div>)
                                        }
                                    </Droppable>
                                :
                                    this.renderEmptyList(store)
                        }
                    </div>
                </div>;
            }
        }
        else if (store.sortedGroupIds.length > 0)
        {
            return <Card
                style={{
                    padding: `0 10px 10px 10px`,
                    whiteSpace: 'nowrap'
                }}
            >
                <Grid
                    style={{
                        display: '-webkit-inline-box'
                    }}
                    container
                >
                    {
                        store.groupStores
                            .map(
                                (groupStore, idx) =>
                                    <Grid
                                        item
                                        xs={store.groupColumnWidth}
                                        key={groupStore.id}
                                    >
                                        <ListGroup
                                            store={store}
                                            idx={idx}
                                            groupStore={groupStore}
                                        />
                                    </Grid>)
                    }
                </Grid>
            </Card>;
        }
        else
        {
            return <>
                {
                    store.createButton &&
                        <Button
                            key={store.createButton.uuid}
                            store={store.createButton}
                        />
                }
                {this.renderItemContainer(store)}
            </>;
        }
    }

    renderItemContainer(store: ListStore<any, any, any, any, any, any>)
    {
        if (store.isDraggable)
        {
            return <Droppable
                droppableId={store.droppableId()}
                type={store.droppableType()}
            >
                {
                    (provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                        >
                            {this.renderItems(store)}
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>;
        }
        else
        {
            return this.renderItems(store);
        }
    }

    renderItems(store: ListStore<any, any, any, any, any, any>)
    {

        if (store.isDraggable)
        {
            return store.sortedItemStores.map(
                (itemStore, idx) =>
                    <Draggable
                        key={`draggable-${itemStore.id}`}
                        draggableId={itemStore.id.toString()}
                        index={idx}
                    >
                        {(provided, snapshot) => (
                            <div
                                key={`itemcontainer-${itemStore.id}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                {
                                    this.renderItem(itemStore)
                                }
                            </div>)}
                    </Draggable>);
        }
        else
        {
            return store.sortedItemStores.map(
                itemStore =>
                    this.renderItem(itemStore));
        }
    }

    renderItem(itemStore: ListItemStore<any, any>)
    {
        return <ListItem
            key={itemStore.id === undefined ? itemStore.uuid : itemStore.id}
            store={itemStore}
        />;
    }

    renderEmptyList(store: ListStore<any, any, any, any, any, any>)
    {
        if (store.showNoDataLabel)
        {
            return <Text store={store.emptyTextStore} />;
        }
        else
        {
            return null;
        }
    }
}

export default withStyles(styles as any)(GroupedMenuList);
