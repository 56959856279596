import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { EntityExpansionBuilder } from '../../../Selection/Builder/EntityExpansionBuilder';
import useTypes from '../../../Type/Api/useTypes';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import EntityTypeContext from '../../../Type/EntityTypeContext';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import EntityValueType from '../../../../../../@Api/Automation/Value/Type/EntityValueType';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import getPredicateFromDescriptor from '../../../../../../@Api/Automation/Api/getPredicateFromDescriptor';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import useTextEntityValue from '../../../../../../@Api/Entity/Hooks/useTextEntityValue';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ButtonGroup from '../../../../../../@Future/Component/Generic/Button/ButtonGroup/ButtonGroup';
import SuccessButton from '../../../../../../@Future/Component/Generic/Button/Variant/SuccessButton/SuccessButton';
import performAction from '../../../../../../@Api/Entity/performAction';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import PredicateEditor from '../Automation/Editor/Predicate/PredicateEditor';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import { computed } from 'mobx';
import useCount from '../../../Selection/Hooks/useCount';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import CircularPercentageChart from '../../../../../../@Future/Component/Generic/Chart/CircularPercentageChart';
import useRelatedEntity from '../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import WarningButton from '../../../../../../@Future/Component/Generic/Button/Variant/WarningButton/WarningButton';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { LogViewer } from '../../../../LogViewer/LogViewer';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export interface BulkDeletionProps
{
    entity: Entity;
}

const BulkDeletion: React.FC<BulkDeletionProps> =
    ({
        entity,
     }) =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);
        const currentUserStore = useContext(CurrentUserContext);
        const [ filterContext, setFilterContext ] = useState<FunctionContext>();
        const [ entityType, setEntityType ] = useState<EntityType>();
        const [ parameter, setParameter ] = useState<Parameter<EntityValueType>>();
        const [ filter, setFilter ] = useState<Predicate>();
        const [ isInitialized ] =
            useAsyncResult(
                async () =>
                {
                    // Initialize dependencies
                    await new EntityExpansionBuilder(
                        entity.entityType,
                        [
                            entity
                        ],
                        [
                            EntityPath.fromEntity(entity)
                                .joinTo(
                                    types.EntityType.RelationshipDefinition.BulkDeletions,
                                    true),
                            EntityPath.fromEntity(entity)
                                .joinTo(
                                    types.BulkDeletion.RelationshipDefinition.Phase,
                                    false)
                        ])
                        .expand();

                    // Get entity type
                    const entityType =
                        entityTypeStore.getTypeByEntityId(
                            entity.getRelatedEntityByDefinition(
                                true,
                                types.EntityType.RelationshipDefinition.BulkDeletions).id);

                    setEntityType(entityType);

                    const parameter =
                        new Parameter(
                            'Entity',
                            new EntityValueType(entityType),
                            true,
                            entityType.getName());

                    setParameter(parameter);

                    const parameterDictionary =
                        new ParameterDictionary([
                            parameter
                        ]);

                    const filterContext = new FunctionContext(parameterDictionary);
                    setFilterContext(filterContext);

                    // Initialize filter
                    const filter =
                        entity.hasValueForField(types.BulkDeletion.Field.Filter)
                            ?
                                await getPredicateFromDescriptor(
                                    entity.getObjectValueByField(types.BulkDeletion.Field.Filter),
                                    filterContext)
                            :
                                undefined;

                    setFilter(filter);

                    return true;
                },
                [
                    types,
                    entity,
                    entityTypeStore,
                    setFilterContext,
                    setEntityType,
                    setParameter,
                    setFilter
                ]);

        const creationDate =
            useTextEntityValue(
                entity,
                types.Entity.Field.CreationDate);

        const startBulkDeletion =
            useCallback(
                () =>
                {
                    const safeWord = entityType.getName(true);

                    if (
                        window.prompt(
                            localizeText(
                                'BulkDeletionSafewordPrompt',
                                "Let op! Deze actie kan niet ongedaan gemaakt worden. Typ het woord '${safeWord}' om te actie te starten.",
                            {
                                        safeWord: safeWord
                                    }
                            )
                        ) === safeWord
                    )
                    {
                        return performAction(
                            entity,
                            {
                                code: 'BulkDeletion.Start'
                            });
                    }
                    else
                    {
                        window.alert(localizeText('BulkDeletionSafewordWrong', 'Het opgegeven veiligsheidswoord komt niet overeen.'));
                    }
                },
                [
                    entityType,
                    entity
                ]);

        const pauseBulkDeletion =
            useCallback(
                () =>
                    performAction(
                        entity,
                        {
                            code: 'BulkDeletion.Pause'
                        }),
                [
                    entity
                ]);

        useEffect(
            () =>
            {
                return computed(
                    () =>
                        filter?.toDescriptor())
                    .observe(
                        change =>
                            new CommitBuilder()
                                .setObjectValueInEntity(
                                    entity,
                                    types.BulkDeletion.Field.Filter,
                                    change.newValue
                                )
                                .commit()
                    )
            },
            [
                filter,
                entity,
                types,
            ]);

        const currentNumberOfRecords =
            useCount(
                entityType,
                (builder, rootPath) =>
                    builder
                        .if(
                            () =>
                                filter !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.filter(
                                            filter,
                                            {
                                                parameter,
                                            }
                                        )
                                )
                        ),
                [
                    filter,
                    parameter,
                ]);

        const cachedNumberOfRecords =
            useEntityValue(
                entity,
                types.BulkDeletion.Field.NumberOfRecords,
                currentNumberOfRecords);

        const numberOfRecords =
            useMemo(
                () =>
                {
                    if (cachedNumberOfRecords === undefined)
                    {
                        return currentNumberOfRecords;
                    }
                    else
                    {
                        return cachedNumberOfRecords;
                    }
                },
                [
                    cachedNumberOfRecords,
                    currentNumberOfRecords
                ]);

        const numberOfDeletedRecords =
            useEntityValue(
                entity,
                types.BulkDeletion.Field.NumberOfRecords,
                0);

        const phase =
            useRelatedEntity(
                entity,
                types.BulkDeletion.RelationshipDefinition.Phase,
                false);

        const phaseCode =
            useEntityValue(
                phase,
                types.Datastore.Field.Code);

        const isInProgress =
            useComputed(
                () =>
                    phaseCode === types.BulkDeletion.Phase.InProgress,
                [
                    phaseCode
                ]);

        const isPaused =
            useComputed(
                () =>
                    phaseCode === types.BulkDeletion.Phase.Paused,
                [
                    phaseCode
                ]);

        const isFinished =
            useComputed(
                () =>
                    phaseCode === types.BulkDeletion.Phase.Finished,
                [
                    phaseCode
                ]);

        const phaseName =
            useTextEntityValue(
                phase,
                types.Datastore.Field.LocalizedName);

        if (isInitialized)
        {
            return <BaseLayout>
                <ViewGroup
                    orientation="vertical"
                    spacing={15}
                >
                    <ViewGroupItem>
                        <Card
                            inset
                        >
                            <ViewGroup
                                orientation="vertical"
                                spacing={15}
                            >
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                        alignment="center"
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <CardHeader>
                                                <LocalizedText
                                                    code="BulkDeletion.OfTypeOnDate"
                                                    value="Bulkverwijdering van ${entityTypeName} on ${creationDate}"
                                                    entityTypeName={entityType.getName(true).toLowerCase()}
                                                    creationDate={creationDate}
                                                />
                                            </CardHeader>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <strong>Status:</strong> {phaseName}
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <ButtonGroup>
                                                <SuccessButton
                                                    onClick={startBulkDeletion}
                                                    label={
                                                        isPaused
                                                        ?
                                                            <LocalizedText
                                                                code="BulkDeletion.Continue"
                                                                value="Verwijderactie hervatten"
                                                            />
                                                        :
                                                            <LocalizedText
                                                                code="BulkDeletion.Start"
                                                                value="Verwijderactie starten"
                                                            />
                                                    }
                                                    loading={isInProgress}
                                                    disabled={isFinished}
                                                />
                                                {
                                                    isInProgress &&
                                                        <WarningButton
                                                            label={
                                                                <LocalizedText
                                                                    code="Generic.Pause"
                                                                    value="Pauzeren"
                                                                />
                                                            }
                                                            onClick={pauseBulkDeletion}
                                                        />
                                                }
                                            </ButtonGroup>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={15}
                                            >
                                                <ViewGroupItem>
                                                    <CircularPercentageChart
                                                        value={((numberOfDeletedRecords || 0) / numberOfRecords * 100)}
                                                        size={130}
                                                        label={value => `${Math.round(value)}%`}
                                                        subLabel={
                                                            () =>
                                                                <LocalizedText
                                                                    code="Generic.Progress"
                                                                    value="Voortgang"
                                                                />
                                                        }
                                                    />
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <ViewGroup
                                                        orientation="vertical"
                                                        spacing={5}
                                                    >
                                                        <ViewGroupItem>
                                                            <LocalizedText
                                                                code="BulkDeletion.NumberOfRecordsToRemove"
                                                                value="Aantal records te verwijderen: ${numberOfRecords}"
                                                                numberOfRecords={numberOfRecords}
                                                            />
                                                        </ViewGroupItem>
                                                        <ViewGroupItem>
                                                            <LocalizedText
                                                                code="BulkDeletion.NumberOfRecordsRemoved"
                                                                value="Aantal records verwijderd: ${numberOfDeletedRecords}"
                                                                numberOfDeletedRecords={numberOfDeletedRecords}
                                                            />
                                                        </ViewGroupItem>
                                                    </ViewGroup>
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>
                            </ViewGroup>
                        </Card>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Card
                            inset
                        >
                            <ViewGroup
                                orientation="vertical"
                                spacing={10}
                            >
                                <ViewGroupItem>
                                    <CardHeader>
                                        <LocalizedText
                                            code="Generic.Filter"
                                            value="Filter"
                                        />
                                    </CardHeader>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <PredicateEditor
                                        value={filter}
                                        onChange={setFilter}
                                        context={filterContext}
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </Card>
                    </ViewGroupItem>
                    {
                        currentUserStore.isSupport &&
                            <ViewGroupItem>
                                <Card>
                                    <LogViewer
                                        name={`bulkDeletions/${entity.uuid}`}
                                    />
                                </Card>
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </BaseLayout>;
        }
        else
        {
            return <Centered
                horizontal
                vertical
            >
                <Loader />
            </Centered>;
        }
    };

export default observer(BulkDeletion);
