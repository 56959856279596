import * as React from 'react';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { observer } from 'mobx-react';
import { DataObjectBespokeEditor } from '../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { ApiBaseEditorStore } from './ApiBaseEditorStore';
import ApiBaseSelector from './ApiBaseSelector';

@observer
export class ApiBaseEditor extends DataObjectBespokeEditor<ApiBaseEditorStore<any>>
{
    renderComponent(store: DataObjectEditorStore<ApiBaseEditorStore<any>>)
    {
        return <ApiBaseSelector
            store={store.bespokeStore}
        />;
    }
}
