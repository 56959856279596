import React from 'react';
import { classNames } from '../../../Util/Class/classNames';
import styles from './CardInset.module.scss';

export interface CardInsetClasses
{
    root?: string;
}

export interface CardInsetProps
{
    classes?: CardInsetClasses;
    horizontal?: boolean;
    vertical?: boolean;
    top?: boolean;
    bottom?: boolean;
    left?: boolean;
    right?: boolean;
}

const CardInset: React.FC<CardInsetProps> =
    props =>
    {
        const horizontal = props.horizontal === undefined || props.horizontal;
        const vertical = props.vertical === undefined || props.vertical;
        const top = vertical && (props.top === undefined || props.top);
        const bottom = vertical && (props.bottom === undefined || props.bottom);
        const left = horizontal && (props.left === undefined || props.left);
        const right = horizontal && (props.right === undefined || props.right);

        return <div
            className={
                classNames(
                    styles.root,
                    top && styles.top,
                    bottom && styles.bottom,
                    left && styles.left,
                    right && styles.right,
                    props.classes && props.classes.root)
            }
        >
            {props.children}
        </div>;
    };

export default CardInset;
