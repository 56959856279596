import { Id, ListItemStore } from './Item/ListItemStore';
import { observable } from 'mobx';

export class ListGroupStore<D, I extends ListItemStore<D>, G>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable id: Id;
    @observable value: G;
    @observable itemStores: I[];

    // ------------------------ Constructor -------------------------

    constructor(id: Id,
                value: G,
                itemStores: I[])
    {
        this.id = id;
        this.value = value;
        this.itemStores = itemStores;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
