import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ValueType from '../../../../../../../../@Api/Automation/Value/Type/ValueType';
import Computation from '../../../../../../../../@Api/Automation/Function/Computation/Computation';
import Value from '../../../../../../../../@Api/Automation/Value/Value';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import { ComputationEditorProps } from './ComputationEditor';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import PrimitiveValueType from '../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DateType } from '../../../../../../DataObject/Type/Date/DateType';
import { DateTimeType } from '../../../../../../DataObject/Type/Date/DateTime/DateTimeType';
import FormattedDateComputation from '../../../../../../../../@Api/Automation/Function/Computation/FormattedDateComputation';
import ConditionalComputation from '../../../../../../../../@Api/Automation/Function/Computation/ConditionalComputation';
import { runInAction } from 'mobx';
import ConditionalInComputation from '../../../../../../../../@Api/Automation/Function/Computation/ConditionalInComputation';
import CompositePredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../../../DataObject/Model/LogicalOperator';
import StartOfDayComputation from '../../../../../../../../@Api/Automation/Function/Computation/StartOfDayComputation';
import TruncatedDateComputation from '../../../../../../../../@Api/Automation/Function/Computation/TruncatedDateComputation';
import CollectionType from '../../../../../../../../@Api/Automation/Value/Type/CollectionType';
import FilteredCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/FilteredCollectionComputation';
import uuid from '../../../../../../../../@Util/Id/uuid';
import FirstElementOfCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/FirstElementOfCollectionComputation';
import EntityValueType from '../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import ValueFromEntityComputation from '../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import DataSourceValueType from '../../../../../../../../@Api/Automation/Value/Type/DataSourceValueType';
import ValueFromDataSourceValueComputation from '../../../../../../../../@Api/Automation/Function/Computation/ValueFromDataSourceValueComputation';
import MapByComputation from '../../../../../../../../@Api/Automation/Function/Computation/MapByComputation';
import MapType from '../../../../../../../../@Api/Automation/Value/Type/MapType';
import ValueFromMapComputation from '../../../../../../../../@Api/Automation/Function/Computation/ValueFromMapComputation';
import EntityFromDataSourceValueComputation from '../../../../../../../../@Api/Automation/Function/Computation/EntityFromDataSourceValueComputation';
import MapWithValueComputation from '../../../../../../../../@Api/Automation/Function/Computation/MapWithValueComputation';
import EmptyValue from '../../../../../../../../@Api/Automation/Value/EmptyValue';
import { TextType } from '../../../../../../DataObject/Type/Text/TextType';
import LengthOfTextComputation from '../../../../../../../../@Api/Automation/Function/Computation/LengthOfTextComputation';
import NumberFromTextComputation from '../../../../../../../../@Api/Automation/Function/Computation/NumberFromTextComputation';
import SizeOfCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/SizeOfCollectionComputation';
import { NumberType } from '../../../../../../DataObject/Type/Number/NumberType';
import IsDutchBsnValidComputation from '../../../../../../../../@Api/Automation/Function/Computation/IsDutchBsnValidComputation';
import CapitalizedTextComputation from '../../../../../../../../@Api/Automation/Function/Computation/CapitalizedTextComputation';
import DateWithTimeComputation from '../../../../../../../../@Api/Automation/Function/Computation/DateWithTimeComputation';
import MappedCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/MappedCollectionComputation';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import LowercaseTextComputation from '../../../../../../../../@Api/Automation/Function/Computation/LowercaseTextComputation';
import SingletonCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/SingletonCollectionComputation';
import AggregateOfCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/AggregateOfCollectionComputation';
import { Aggregate } from '../../../../../../DataObject/Model/Aggregate';
import JoinedCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/JoinedCollectionComputation';
import CollectionValue from '../../../../../../../../@Api/Automation/Value/CollectionValue';
import { useLocalizer } from '../../../../../../../../@Service/Localization/Api/useLocalizer';
import LocalizedComputation from '../../../../../../../../@Api/Automation/Function/Computation/LocalizedComputation';
import LocalizedComputationItem from '../../../../../../../../@Api/Automation/Function/Computation/LocalizedComputationItem';
import RoundedNumberComputation from '../../../../../../../../@Api/Automation/Function/Computation/RoundedNumberComputation';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import useTypes from '../../../../../Type/Api/useTypes';
import EntityTypeFromEntityTypeEntityComputation from '../../../../../../../../@Api/Automation/Function/Computation/EntityTypeFromEntityTypeEntityComputation';
import { FileType } from '../../../../../../DataObject/Type/File/FileType';
import NameFromFileComputation from '../../../../../../../../@Api/Automation/Function/Computation/NameFromFileComputation';
import TextComputation from '../../../../../../../../@Api/Automation/Function/Computation/TextComputation';
import TextComputationEditorOptions from './Type/Text/TextComputationEditorOptions';
import Divider from '../../../../../../../../@Future/Component/Generic/Divider/Divider';
import UrlEncodedTextComputation from '../../../../../../../../@Api/Automation/Function/Computation/UrlEncodedTextComputation';
import JoinWithDelimiterComputation from "../../../../../../../../@Api/Automation/Function/Computation/JoinWithDelimiterComputation";

export interface ComputationOptionsProps<T extends ValueType<any> = ValueType<any>, C extends Computation<T, Value<any, any>> = Computation<T, Value<any, any>>> extends ComputationEditorProps<T, C>
{

}

interface Option
{
    id: string;
    name: string;
    onClick: () => void;
}

const ComputationOptions: React.FC<ComputationOptionsProps> =
    props =>
    {
        const localizer = useLocalizer();
        const types = useTypes();
        const options =
            useComputed(
                () =>
                {
                    const options: Option[] = [];

                    if (props.value
                        && props.value.isValid())
                    {
                        const type = props.value.getType();

                        if (type instanceof PrimitiveValueType)
                        {
                            if (type.type instanceof DateType || type.type instanceof DateTimeType)
                            {
                                options.push({
                                    id: 'StartOfDay',
                                    name: localizeText('StartOfDay', 'Start van dag...'),
                                    onClick:
                                        () =>
                                            props.onChange(
                                                new StartOfDayComputation(props.value)
                                            )
                                });

                                options.push({
                                    id: 'FormattedDate',
                                    name: localizeText('FormattingDate', 'Datum formatteren...'),
                                    onClick:
                                        () =>
                                            props.onChange(
                                                new FormattedDateComputation(
                                                    'MM-dd',
                                                    props.value
                                                )
                                            )
                                });

                                options.push({
                                    id: 'TruncatedDate',
                                    name: localizeText('TruncateDate', 'Datum afkappen...'),
                                    onClick:
                                        () =>
                                            props.onChange(
                                                new TruncatedDateComputation(
                                                    'Day',
                                                    props.value
                                                )
                                            )
                                });

                                options.push({
                                    id: 'DateWithTime',
                                    name: localizeText('DateWithAdjustedTime', 'Datum met aangepaste tijd...'),
                                    onClick:
                                        () =>
                                            props.onChange(
                                                new DateWithTimeComputation(
                                                    props.value,
                                                    undefined
                                                )
                                            )
                                });
                            }
                            else if (type.type instanceof TextType)
                            {
                                options.push({
                                    id: 'LengthOfText',
                                    name: localizeText('LengthOfText', 'Lengte van tekst...'),
                                    onClick:
                                        () =>
                                            props.onChange(
                                                new LengthOfTextComputation(
                                                    props.value
                                                )
                                            )
                                });

                                options.push({
                                    id: 'NumberFromText',
                                    name: localizeText('NumberFromText','Nummer uit tekst...'),
                                    onClick:
                                        () =>
                                            props.onChange(
                                                new NumberFromTextComputation(
                                                    props.value
                                                )
                                            )
                                });

                                options.push({
                                    id: 'CapitalizedText',
                                    name: localizeText('CapitalizedText', 'Tekst met hoofdletter...'),
                                    onClick:
                                        () =>
                                            props.onChange(
                                                new CapitalizedTextComputation(
                                                    props.value
                                                )
                                            )
                                });

                                options.push({
                                    id: 'LowercaseText',
                                    name: `${localizeText('LowercaseText', 'Tekst met kleine letters')}...`,
                                    onClick:
                                        () =>
                                            props.onChange(
                                                new LowercaseTextComputation(
                                                    props.value
                                                )
                                            )
                                });
                            }
                            else if (type.type instanceof NumberType)
                            {
                                options.push({
                                    id: 'Round',
                                    name:
                                        localizeText(
                                            'RoundedNumberComputationName',
                                            'Afronden'
                                        ),
                                    onClick:
                                        () =>
                                            props.onChange(
                                                new RoundedNumberComputation(
                                                    0,
                                                    'HalfUp',
                                                    props.value
                                                )
                                            )
                                });

                                options.push({
                                    id: 'IsDutchBsnValid',
                                    name: localizeText('BSNNumberValidation', 'BSN nummer validatie...'),
                                    onClick:
                                        () =>
                                            props.onChange(
                                                new IsDutchBsnValidComputation(
                                                    props.value
                                                )
                                            )
                                });
                            }
                            else if (type.type instanceof FileType)
                            {
                                options.push({
                                    id: 'NameFromFile',
                                    name: localizeText('Computation.NameFromFile', 'Naam uit bestand'),
                                    onClick:
                                        () =>
                                            props.onChange(
                                                new NameFromFileComputation(props.value)
                                            )
                                });
                            }
                        }
                        else if (type instanceof EntityValueType)
                        {
                            options.push({
                                id: 'ValueFromEntity',
                                name: localizeText('FieldFromEntity', 'Veld uit entiteit...'),
                                onClick:
                                    () =>
                                        props.onChange(
                                            new ValueFromEntityComputation(
                                                props.value,
                                                undefined
                                            )
                                        )
                            });

                            if ((type.type as EntityType).isA(types.EntityType.Type))
                            {
                                options.push({
                                    id: 'EntityTypeFromEntityTypeEntity',
                                    name: localizeText('Computation.EntityTypeFromEntityTypeEntity', 'Entiteitstype uit entiteitstype entiteit'),
                                    onClick:
                                        () =>
                                            props.onChange(
                                                new EntityTypeFromEntityTypeEntityComputation(props.value)
                                            )
                                });
                            }
                        }
                        else if (type instanceof DataSourceValueType)
                        {
                            options.push({
                                id: 'ValueFromDataSourceValue',
                                name: localizeText('FieldFromDatasource', 'Veld uit databronwaarde...'),
                                onClick:
                                    () =>
                                        props.onChange(
                                            new ValueFromDataSourceValueComputation(
                                                props.value,
                                                undefined
                                            )
                                        )
                            });

                            options.push({
                                id: 'AsEntity',
                                name: localizeText('AsEntity', 'Als entiteit...'),
                                onClick:
                                    () =>
                                        props.onChange(
                                            new EntityFromDataSourceValueComputation(props.value)
                                        )
                            });
                        }
                        else if (type instanceof CollectionType)
                        {
                            options.push({
                                id: 'FilteredCollection',
                                name: localizeText('Filtering', 'Filteren...'),
                                onClick:
                                    () =>
                                        props.onChange(
                                            new FilteredCollectionComputation(
                                                props.value,
                                                FilteredCollectionComputation.getElementParameter(props.value, uuid()),
                                                undefined
                                            )
                                        )
                            });

                            options.push({
                                id: 'MappedCollection',
                                name: localizeText('MapCollection', 'Map...'),
                                onClick:
                                    () =>
                                    {
                                        const elementParameter =
                                            MappedCollectionComputation.getElementParameter(
                                                props.value,
                                                uuid()
                                            );
                                        props.onChange(
                                            new MappedCollectionComputation(
                                                props.value,
                                                elementParameter,
                                                EmptyValue.instance
                                            )
                                        );
                                    }
                            });

                            options.push({
                                id: 'JoinedCollection',
                                name: localizeText('JoinCollection', 'Voeg collectie samen met...'),
                                onClick:
                                    () =>
                                    {
                                        props.onChange(
                                            new JoinedCollectionComputation(
                                                props.value,
                                                new CollectionValue(
                                                    [],
                                                    (props.value as CollectionValue<any>).elementType
                                                )
                                            )
                                        );
                                    }
                            });

                            options.push({
                                id: 'FirstElementOfCollection',
                                name: localizeText('FirstElementOfCollection', 'Eerste element') + '...',
                                onClick:
                                    () =>
                                        props.onChange(
                                            new FirstElementOfCollectionComputation(props.value)
                                        )
                            });

                            options.push({
                                id: 'SizeOfCollection',
                                name: localizeText('SizeOfCollection','Grootte van collectie...'),
                                onClick:
                                    () =>
                                        props.onChange(
                                            new SizeOfCollectionComputation(
                                                props.value
                                            )
                                        )
                            });

                            options.push({
                                id: 'AggregateOfCollection',
                                name: localizeText('AggregateOfCollection','Aggregatie van collectie...'),
                                onClick:
                                    () =>
                                        props.onChange(
                                            new AggregateOfCollectionComputation(
                                                props.value,
                                                Aggregate.Sum
                                            )
                                        )
                            });

                            options.push({
                                id: 'MapBy',
                                name: localizeText('MapBy', 'Map by...'),
                                onClick:
                                    () =>
                                        props.onChange(
                                            new MapByComputation(
                                                props.value,
                                                FilteredCollectionComputation.getElementParameter(props.value, uuid()),
                                                undefined
                                            )
                                        )
                            });

                            options.push({
                                id: 'JoinWithDelimiter',
                                name: localizeText('JoinWithDelimiter', 'Samenvoegen met scheidingsteken'),
                                onClick:
                                    () =>
                                    {
                                        props.onChange(
                                            new JoinWithDelimiterComputation(
                                                "",
                                                props.value
                                            )
                                        );
                                    }
                            });
                        }
                        else if (type instanceof MapType)
                        {
                            options.push({
                                id: 'ValueFromMap',
                                name: localizeText('ValueFromMap', 'Waarde uit map...'),
                                onClick:
                                    () =>
                                        props.onChange(
                                            new ValueFromMapComputation(
                                                props.value,
                                                undefined
                                            )
                                        )
                            });

                            options.push({
                                id: 'MapWithValue',
                                name: localizeText('MapWithValue', 'Map met waarde...'),
                                onClick:
                                    () =>
                                        props.onChange(
                                            new MapWithValueComputation(
                                                props.value,
                                                undefined,
                                                EmptyValue.instance
                                            )
                                        )
                            });
                        }

                        if (props.value instanceof ConditionalComputation)
                        {
                            options.push({
                                id: 'AddConditional',
                                name: localizeText('AddConditional', 'Conditie toevoegen'),
                                onClick:
                                    () =>
                                        runInAction(
                                            () =>
                                                (props.value as ConditionalComputation)
                                                    .conditionals
                                                    .push(
                                                        new ConditionalInComputation(
                                                            new CompositePredicate(
                                                                LogicalOperator.And,
                                                                []),
                                                            undefined
                                                        )
                                                    )
                                        )
                            })
                        }
                    }

                    options.push({
                        id: 'SingletonCollection',
                        name: localizeText('SingletonCollection', 'Collectie uit element') + '...',
                        onClick:
                            () =>
                                props.onChange(
                                    new SingletonCollectionComputation(props.value)
                                )
                    });

                    options.push({
                        id: 'Localized',
                        name: localizeText('Localized', 'Gelokaliseerd') + '...',
                        onClick:
                            () =>
                                props.onChange(
                                    new LocalizedComputation([
                                            new LocalizedComputationItem(
                                                uuid(),
                                                localizer.languageCode,
                                                props.value
                                            )
                                        ]
                                    )
                                )
                    });

                    options.push({
                        id: 'UrlEncoded',
                        name: `${localizeText('UrlEncodedText', 'Url encoded tekst')}...`,
                        onClick:
                            () =>
                                props.onChange(
                                    new UrlEncodedTextComputation(
                                        props.value
                                    )
                                )
                    });

                    return options;
                },
                [
                    props.value,
                    props.onChange,
                    localizer,
                    types,
                ]);

        if (options.length > 0)
        {
            return <MenuButton>
                <Menu>
                    {
                        options.map(
                            option =>
                                <Item
                                    key={option.id}
                                    name={option.name}
                                    onClick={option.onClick}
                                />)
                    }
                </Menu>
                {
                    props.value instanceof TextComputation &&
                    <>
                        <Divider />
                        <TextComputationEditorOptions
                            {...props}
                            value={props.value}
                        />
                    </>
                }
            </MenuButton>;
        }
        else
        {
            return null;
        }
    };

export default observer(ComputationOptions);
