import Computation from '../../../../../../../@Api/Automation/Function/Computation/Computation';
import { EntityConstraintBuilderFilterContext } from '../Model/EntityConstraintBuilderFilterContext';
import { ViewParams } from '../../../../View/Model/ViewParams';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/index';
import { CurrentUserStore } from '../../../../../User/CurrentUserStore';
import EntityValue from '../../../../../../../@Api/Automation/Value/EntityValue';
import PrimitiveValue from '../../../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import FunctionContext from '../../../../../../../@Api/Automation/Function/FunctionContext';

export function getRhsValue(
    rhs: Computation<any, any>,
    context: EntityConstraintBuilderFilterContext
)
{
    if (rhs.isValid())
    {
        const rhsDependencies = rhs.getDependencies();
        const meDependency = rhsDependencies.find(dependency => dependency.parameter.id === ViewParams.Me);
        const currentDateTimeDependency = rhs.getDependencies().find(dependency => dependency.parameter.id === ViewParams.CurrentDateTime);
        let rhsParameterAssignment = context.context.parameterAssignment;

        if (meDependency)
        {
            const employeeEntity = loadModuleDirectly(CurrentUserStore).employeeEntity;

            if (employeeEntity)
            {
                rhsParameterAssignment =
                  rhsParameterAssignment.getNewAssignmentWithParameter(
                      meDependency.parameter,
                      new EntityValue(employeeEntity)
                  );
            }
        }

        if (currentDateTimeDependency)
        {
            rhsParameterAssignment =
                rhsParameterAssignment.getNewAssignmentWithParameter(
                    currentDateTimeDependency.parameter,
                    new PrimitiveValue(
                        DataObject.constructFromTypeIdAndValue(
                            'DateTime',
                            new Date()
                        )
                    )
                );
        }

        return rhs.synchronousApply(
            new FunctionContext(
                context.context.parameterDictionary,
                rhsParameterAssignment
            )
        );
    }
}
