import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import PortalContext from '../../../../../../../../../../@Api/Portal/Context/PortalContext';
import useTypes from '../../../../../../../Type/Api/useTypes';
import useEntityByUuid from '../../../../../../../../../../@Api/Entity/Bespoke/useEntityByUuid';
import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import getPortalDataSourceSignatureById from '../../../../../../../../../../@Api/Portal/DataSource/Api/getPortalDataSourceSignatureById';
import { runInAction } from 'mobx';
import DynamicParameterAssignment from '../../../../../../../../../../@Api/Automation/Function/Dynamic/DynamicParameterAssignment';
import PortalDataSourceSignature from '../../../../../../../../../../@Api/Portal/DataSource/PortalDataSourceSignature';
import ParameterDictionary from '../../../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { EntitySelectionBuilder } from '../../../../../../../Selection/Builder/EntitySelectionBuilder';
import useDialog from '../../../../../../../../../../@Service/Navigation/Page/Hooks/useDialog';
import Dialog from '../../../../../../../../../../@Future/Component/Generic/Dialog/Dialog';
import PortalDataSourceEditor from '../../../../../../../../Layout/Type/PortalList/Editor/DataSource/Editor/PortalDataSourceEditor';
import { createTransactionalModel } from '../../../../../../../../../../@Util/TransactionalModelV2/index';
import CardInset from '../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Selectbox from '../../../../../../../Selectbox/Selectbox';
import EditIconButton from '../../../../../../../../../../@Future/Component/Generic/Button/Variant/EditIconButton/EditIconButton';
import DynamicParameterAssignmentEditor from '../../../DynamicParameterAssignment/DynamicParameterAssignmentEditor';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import DataSourceListQueryComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/DataSourceListQueryComputation';
import PortalDataSourceOrderingsEditor from '../../../../../../../../Layout/Type/PortalList/Editor/DataSource/Orderings/PortalDataSourceOrderingsEditor';
import PortalDataSourceOrdering from '../../../../../../../../../../@Api/Portal/DataSource/PortalDataSourceOrdering';
import ComputationBox from '../../Box/ComputationBox';
import Predicate from '../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import PredicateEditor from '../../../Predicate/PredicateEditor';
import FunctionContext from '../../../../../../../../../../@Api/Automation/Function/FunctionContext';

export interface DataSourceListQueryComputationEditorProps extends ComputationEditorProps<ValueType<any>, DataSourceListQueryComputation>
{

}

const DataSourceListQueryComputationEditor: React.FC<DataSourceListQueryComputationEditorProps> =
    props =>
    {
        const { value, context } = props;
        const portalContext = useContext(PortalContext);

        const types = useTypes();
        const [ dataSource, isLoading ] = useEntityByUuid(types.PortalDataSource.Type, value.dataSourceSignature?.id);
        const setDataSource =
            useCallback(
                async (dataSource?: Entity) =>
                {
                    if (dataSource?.hasValueForField(types.PortalDataSource.Field.Specification))
                    {
                        const signature = await getPortalDataSourceSignatureById(dataSource.uuid, false);

                        runInAction(
                            () =>
                            {
                                value.parameterAssignment = value.parameterAssignment.getWithNewParameters(signature.parameters);
                                value.dataSourceSignature = signature;
                            });
                    }
                    else
                    {
                        runInAction(
                            () =>
                            {
                                value.parameterAssignment = new DynamicParameterAssignment();
                                value.dataSourceSignature =
                                    dataSource
                                        ?
                                            new PortalDataSourceSignature(
                                                dataSource.uuid,
                                                undefined,
                                                new ParameterDictionary([]),
                                                new ParameterDictionary([]))
                                        :
                                            undefined;
                            });
                    }
                },
                [
                    value,
                    types
                ]);
        const dataSourceSelection =
            useMemo(
                () => [
                    new EntitySelectionBuilder(types.PortalDataSource.Type)
                        .build()
                ],
                [
                    types
                ]);
        const dataSourceParameters =
            useComputed(
                () =>
                    value.dataSourceSignature?.parameters || new ParameterDictionary([]),
                [
                    value
                ]);
        const dataSourceParameterAssignment =
            useComputed(
                () =>
                    value.parameterAssignment || new DynamicParameterAssignment(),
                [
                    value
                ]);

        const [ openEditor ] =
            useDialog(
                close =>
                    <Dialog
                        open
                        onClose={close}
                        width="xl"
                    >
                        <PortalContext.Provider
                            value={portalContext}
                        >
                            <PortalDataSourceEditor
                                entity={dataSource}
                                onCancel={close}
                                onSave={
                                    async () =>
                                    {
                                        await setDataSource(dataSource);
                                        close();
                                    }
                                }
                            />
                        </PortalContext.Provider>
                    </Dialog>,
                [
                    portalContext,
                    dataSource,
                    setDataSource
                ]);

        const onConstruct =
            useCallback(
                (newDataSource: Entity) =>
                {
                    newDataSource.updateRelationship(
                        true,
                        types.Portal.RelationshipDefinition.DataSources,
                        createTransactionalModel(portalContext.portal));
                },
                [
                    types,
                    portalContext
                ]);

        const filterContext =
            useComputed(
                () =>
                    value.dataSourceSignature &&
                        new FunctionContext(
                            ParameterDictionary.union(
                                value.dataSourceSignature.resultParameters,
                                context.parameterDictionary),
                            context.parameterAssignment,
                            context.commitContext
                        ),
                [
                    value,
                    context
                ]);
        const setFilter =
            useCallback(
                (filter: Predicate) =>
                    runInAction(
                        () =>
                            value.filter = filter),
                [
                    value
                ]);

        const setOffset =
            useCallback(
                (offset: Computation<any, any>) =>
                    runInAction(
                        () =>
                            value.offset = offset),
                [
                    value
                ]);

        const setLimit =
            useCallback(
                (limit: Computation<any, any>) =>
                    runInAction(
                        () =>
                            value.limit = limit),
                [
                    value
                ]);

        const setOrderings =
            useCallback(
                (orderings: PortalDataSourceOrdering[]) =>
                    runInAction(
                        () =>
                            value.orderings = orderings),
                [
                    value
                ]);

        return <CardInset
            vertical={false}
            horizontal={false}
        >
            <Input
                label={
                    <LocalizedText
                        code="Generic.DataSource"
                        value="Databron"
                    />
                }
                labelPosition="top"
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <Selectbox
                            value={dataSource}
                            onChange={setDataSource}
                            selections={dataSourceSelection}
                            genericSelectboxProps={{
                                disabled: isLoading
                            }}
                            onConstruct={onConstruct}
                        />
                    </ViewGroupItem>
                    {
                        dataSource &&
                            <ViewGroupItem>
                                <EditIconButton
                                    onClick={openEditor}
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </Input>
            <DynamicParameterAssignmentEditor
                context={context}
                parameterDictionary={dataSourceParameters}
                parameterAssignment={dataSourceParameterAssignment}
            />
            {
                value.dataSourceSignature &&
                filterContext &&
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.Filter"
                                value="Filter"
                            />
                        }
                        labelPosition="top"
                    >
                        <ComputationBox>
                            <PredicateEditor
                                context={filterContext}
                                value={value.filter}
                                onChange={setFilter}
                            />
                        </ComputationBox>
                    </Input>
            }
            {
                value.dataSourceSignature &&
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.QueryOffset"
                                value="Index van eerst op te halen record"
                            />
                        }
                        labelPosition="top"
                    >
                        <ComputationBox>
                            <ComputationEditor
                                context={context}
                                value={value.offset}
                                onChange={setOffset}
                            />
                        </ComputationBox>
                    </Input>
            }
            {
                value.dataSourceSignature &&
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.QueryLimit"
                                value="Aantal op te halen records"
                            />
                        }
                        labelPosition="top"
                    >
                        <ComputationBox>
                            <ComputationEditor
                                context={context}
                                value={value.offset}
                                onChange={setLimit}
                            />
                        </ComputationBox>
                    </Input>
            }
            {
                value.dataSourceSignature &&
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.Ordering"
                                value="Sortering"
                            />
                        }
                        labelPosition="top"
                    >
                        <PortalDataSourceOrderingsEditor
                            value={value.orderings}
                            onChange={setOrderings}
                            dataSourceSignature={value.dataSourceSignature}
                        />
                    </Input>
            }
        </CardInset>;
    };

export default observer(DataSourceListQueryComputationEditor);
