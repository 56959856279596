import { Entity } from '../../../../../Model/Implementation/Entity';
import { CommitContext } from '../../CommitContext';
import { EntityField } from '../../../../../Model/Implementation/EntityField';

export function setValueByFieldInEntity(
    entity: Entity,
    field: EntityField,
    value: any,
    commitContext: CommitContext = entity.getCommitContext()
)
{
    entity.setValueByField(
        field,
        value,
        undefined,
        undefined,
        commitContext
    );
}
