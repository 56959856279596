import Dependency from '../../../../Automation/Parameter/Dependency';
import Validation from '../../../../Automation/Validation/Validation';
import LayoutDependencyContext from '../../../LayoutDependencyContext';
import FormInputLayout from './FormInputLayout';
import { observable } from 'mobx';
import Layout from '../../../Layout';
import getLayoutFromDescriptor from '../../../Api/getLayoutFromDescriptor';
import localizeText from '../../../../Localization/localizeText';
import getComputationFromDescriptor from '../../../../Automation/Api/getComputationFromDescriptor';
import Computation from '../../../../Automation/Function/Computation/Computation';
import Predicate from '../../../../Automation/Function/Computation/Predicate/Predicate';
import getPredicateFromDescriptor from '../../../../Automation/Api/getPredicateFromDescriptor';
import Query from '../../../../Automation/Query/Query';
import getQueryFromDescriptor from '../../../../Automation/Api/getQueryFromDescriptor';
import LayoutEventTriggers from '../../../Event/LayoutEventTriggers';
import { EntityFieldPath } from '../../../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';

export default class EntitySelectionFormInputLayout extends FormInputLayout
{
    // ------------------------- Properties -------------------------

    @observable.ref query: Query;
    @observable.ref optionLayout: Layout;
    @observable.shallow searchFieldPaths: EntityFieldPath[];

    // ------------------------ Constructor -------------------------

    constructor(formId: string,
                parameterId: string,
                placeholder: Computation<any, any> | undefined,
                isDisabled: Predicate | undefined,
                hasIntrinsicSize: boolean = false,
                eventTriggers: LayoutEventTriggers = undefined,
                query: Query,
                optionLayout: Layout,
                searchFieldPaths: EntityFieldPath[])
    {
        super(formId, parameterId, placeholder, isDisabled, hasIntrinsicSize, eventTriggers);

        this.query = query;
        this.optionLayout = optionLayout;
        this.searchFieldPaths = searchFieldPaths;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.SelectionFormInput', 'Input veld van formulier (selectbox)');
    }

    validate(): Validation[]
    {
        return [
            ...super.validate(),
            ...this.query.validate(),
            ...this.optionLayout.validate()
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...super.getDependencies(),
            ...this.query.getDependencies(),
            ...this.optionLayout.getDependencies()
                .filter(
                    dependency =>
                        dependency.parameter !== this.query.parameter)
        ];
    }

    _toDescriptor(descriptor: any)
    {
        Object.assign(
            descriptor,
            {
                type: 'EntitySelectionFormInput',
                query: this.query.toDescriptor(),
                optionLayout: this.optionLayout.toDescriptor(),
                searchFieldPaths:
                    this.searchFieldPaths.map(
                        searchFieldPath =>
                            searchFieldPath.descriptor)
            });
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const placeholder =
            descriptor.placeholder
                ?
                    await getComputationFromDescriptor(
                        descriptor.placeholder,
                        dependencyContext)
                :
                    undefined;
        const isDisabled =
            descriptor.isDisabled
                ?
                    await getPredicateFromDescriptor(
                        descriptor.isDisabled,
                        dependencyContext)
                :
                    undefined;
        const eventTriggers =
            await LayoutEventTriggers.fromDescriptor(
                descriptor.eventTriggers || [],
                dependencyContext);
        const query = await getQueryFromDescriptor(descriptor.query, dependencyContext);
        const optionLayout =
            await getLayoutFromDescriptor(
                descriptor.optionLayout,
                dependencyContext.withParameterDictionary(
                    dependencyContext.parameterDictionary.getNewDictionaryWithParameter(query.parameter)
                )
            );
        const searchFieldPaths =
            (descriptor.searchFieldPaths ?? [])
                .map(
                    descriptor =>
                        EntityFieldPath.construct(descriptor));

        return new EntitySelectionFormInputLayout(
            descriptor.formId,
            descriptor.parameterId,
            placeholder,
            isDisabled,
            descriptor.hasIntrinsicSize,
            eventTriggers,
            query,
            optionLayout,
            searchFieldPaths);
    }

    // ----------------------- Private logic ------------------------
}
