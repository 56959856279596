import { computed, observable } from 'mobx';
import { ChartDataSegmentation } from './ChartDataSegmentation';
import { DataObject } from '../../../Domain/DataObject/Model/DataObject';
import { DataObjectRepresentation } from '../../../Domain/DataObject/Model/DataObjectRepresentation';
import { LocalizationStore } from '../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import localizeText from '../../../../@Api/Localization/localizeText';

export class ChartDataPoint
{

    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable group: DataObject;
    @observable groupInterval: DataObject;
    @observable value: DataObject;
    @observable segmentations: ChartDataSegmentation[];

    // ------------------------ Constructor -------------------------

    constructor(group: DataObject,
                groupInterval: DataObject,
                value: DataObject,
                segmentations: ChartDataSegmentation[] = [])
    {
        this.group = group;
        this.groupInterval = groupInterval;
        this.value = value;
        this.segmentations = segmentations;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get isEmptyGroup()
    {
        return !this.group || this.group.isEmpty;
    }

    @computed
    get name(): string
    {
        if (this.isEmptyGroup)
        {
            return localizeText('Generic.NoValue', 'Géén waarde');
        }
        else
        {
            return this.group.toString(
                new DataObjectRepresentation(
                    {
                        isCompact: false,
                        granularity: 'low',
                        interval: this.groupInterval
                    }));
        }
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
