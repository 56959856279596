import React, { useMemo } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../Type/Api/useTypes';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import GenericPortalEmailLayoutEditor from '../EmailLayoutEditor/PortalEmailLayoutEditor';
import Parameter from '../../../../../../../../@Api/Automation/Parameter/Parameter';
import PrimitiveValueType from '../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../../../DataObject/Model/DataObject';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';

export interface PortalInvitationEmailLayoutEditorProps
{
    entity: Entity;
    commitContext?: CommitContext;
}

const PortalInvitationEmailLayoutEditor: React.FC<PortalInvitationEmailLayoutEditorProps> =
    ({
        entity,
        commitContext,
     }) =>
    {
        const types = useTypes();
        const parameters =
            useMemo(
                () => [
                    new Parameter<PrimitiveValueType>(
                        'InvitationLink',
                        new PrimitiveValueType(DataObject.getTypeById('Url')),
                        true,
                        localizeText('Portal.InvitationLink', 'Uitnodigingslink')
                    )
                ],
                []
            );

        return <GenericPortalEmailLayoutEditor
            entity={entity}
            subjectField={types.Portal.Field.InvitationEmailSubject}
            bodyField={types.Portal.Field.InvitationEmailBody}
            parameters={parameters}
            commitContext={commitContext}
        />;
    };

export default observer(PortalInvitationEmailLayoutEditor);
