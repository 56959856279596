import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Role, { RoleType } from '../Model/Role';
import useTypes from '../../Entity/Type/Api/useTypes';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { RoleFieldsEditor } from './FieldsEditor/RoleFieldsEditor';
import MasonryLayout from '../../../../@Future/Component/Generic/MasonryLayout/MasonryLayout';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import { FeaturesEditor } from './FeaturesEditor/FeaturesEditor';
import { ProjectFeaturesEditor } from './FeaturesEditor/ProjectFeaturesEditor';
import { RoleEntityTypeTree } from './TypeEditor/RoleEntityTypeTree';

export interface RoleProps
{
    rolesByType: Map<RoleType, Role>;
    role: Role;
    isReadOnly?: boolean;
}

export const RoleEditor: React.FC<RoleProps> =
    observer(
        ({
             role,
             isReadOnly,
             rolesByType,
         }) =>
        {
            const types = useTypes();
            const [ openEntityType, setOpenEntityType ] = useState<EntityType>();

            const rootTypes =
                useMemo(
                    () => [
                        types.Relationship.Person.Type,
                        types.Relationship.Organization.Type,
                        types.Activity.Type,
                        types.CustomEntity.Type,
                        types.Datastore.Type,
                        types.Template.Type
                    ],
                    [
                        types
                    ]);

            const relationFilter =
                useCallback(
                    (type: EntityType) =>
                        type === types.Relation.Person.Type
                        || type === types.Relation.Organization.Type,
                    [
                        types
                    ]);

            const configurationFilter =
                useCallback(
                    (type: EntityType) =>
                        type === types.View.Type
                        || type === types.Dataset.Type,
                    [
                        types
                    ]);

            const otherFilter =
                useCallback(
                    (type: EntityType) =>
                        type === types.Note.Type
                        || type === types.Attachment.Type
                        || type === types.ProductLine.Type
                        || type === types.Product.Type
                        || type === types.TimeRegistration.Type
                        || type === types.TimeRegistrationActivity.Type
                        || type === types.Label.Type
                        || type === types.Address.Type,
                    [
                        types
                    ]);

            if (openEntityType)
            {
                return <RoleFieldsEditor
                    role={role}
                    entityType={openEntityType}
                    onClose={setOpenEntityType as any}
                    isReadOnly={isReadOnly}
                    rolesByType={rolesByType}
                />;
            }
            else
            {
                return <MasonryLayout>
                    <FeaturesEditor
                        role={role}
                        isReadOnly={isReadOnly}
                    />
                    {
                        types.Activity.Project.Type &&
                            <ProjectFeaturesEditor
                                role={role}
                                isReadOnly={isReadOnly}
                            />
                    }
                   <RoleEntityTypeTree
                        role={role}
                        entityType={types.Relation.Type}
                        onOpen={setOpenEntityType}
                        includeNonInstantiableChildTypes
                        filter={relationFilter}
                        includeAll
                        isReadOnly={isReadOnly}
                        rolesByType={rolesByType}
                   />
                    <RoleEntityTypeTree
                        role={role}
                        entityType={types.Relationship.Type}
                        title={
                            <LocalizedText
                                code="Roles.RelationshipType"
                                value="Relatietype"
                            />
                        }
                        onOpen={setOpenEntityType}
                        includeNonInstantiableChildTypes
                        filter={relationFilter}
                        includeAll
                        isReadOnly={isReadOnly}
                        rolesByType={rolesByType}
                    />
                   {
                        rootTypes.map(
                            entityType =>
                                <RoleEntityTypeTree
                                    key={entityType.id}
                                    role={role}
                                    entityType={entityType}
                                    title={
                                        entityType === types.Relationship.Person.Type
                                        ?
                                            <LocalizedText
                                                code="Configuration.PersonTypes"
                                                value="Persoonstypen"
                                            />
                                        :
                                            entityType === types.Relationship.Organization.Type
                                            ?
                                                <LocalizedText
                                                    code="Configuration.OrganizationTypes"
                                                    value="Organisatietypen"
                                                />
                                            :
                                                undefined
                                    }
                                    onOpen={setOpenEntityType}
                                    includeNonInstantiableChildTypes
                                    includeAll
                                    isReadOnly={isReadOnly}
                                    rolesByType={rolesByType}
                                />)
                   }
                   <RoleEntityTypeTree
                        role={role}
                        entityType={types.Entity.Type}
                        hideRoot={true}
                        onOpen={setOpenEntityType}
                        filter={configurationFilter}
                        title={
                            <LocalizedText
                                code="Generic.Configuration"
                                value="Configuratie"
                            />
                        }
                        isReadOnly={isReadOnly}
                        rolesByType={rolesByType}
                   />
                    <RoleEntityTypeTree
                        role={role}
                        entityType={types.Entity.Type}
                        hideRoot={true}
                        onOpen={setOpenEntityType}
                        filter={otherFilter}
                        title={
                            <LocalizedText
                                code="Generic.Other"
                                value="Overig"
                            />
                        }
                        isReadOnly={isReadOnly}
                        rolesByType={rolesByType}
                    />
                </MasonryLayout>;
            }
        }
        );
