import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { Action, BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { createTransactionalModel } from '../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import constructEntity from '../../../../../@Api/Entity/constructEntity';
import { EntityActionResult } from '../../../../../@Api/Model/Implementation/EntityActionResult';
import localizeText from '../../../../../@Api/Localization/localizeText';
import hasAccessToWorkflowAutomations from '../../../../../@Api/Pack/hasAccessToWorkflowAutomations';
import { AuditTrailEntity } from '../../Timeline/Model/AuditTrailEntity';

export class BespokeAutomation extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore, code: string = 'Automation')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getActions(entity: Entity): Action[]
    {
        return [
            ...super.getActions(entity),
            ...hasAccessToWorkflowAutomations()
                ?
                    [
                        {
                            id: 'Automation.Duplicate',
                            code: 'Automation.Duplicate',
                            name: localizeText('Generic.Duplicate', 'Dupliceren'),
                            perform:
                                automation =>
                                {
                                    const newAutomation =
                                        createTransactionalModel(
                                            new Entity(this.types.Automation.Type)
                                                .initialize());

                                    [
                                        this.types.Automation.Field.Specification,
                                        this.types.Automation.Field.IsUser,
                                        this.types.Automation.Field.LocalizedName
                                    ].forEach(
                                        field =>
                                            newAutomation.setValueByField(
                                                field,
                                                automation.getObjectValueByField(field)));

                                    [
                                        this.types.EntityType.RelationshipDefinition.Automations
                                    ].forEach(
                                        relationshipDefinition =>
                                            newAutomation.updateRelationship(
                                                true,
                                                relationshipDefinition,
                                                createTransactionalModel(
                                                    automation.getRelatedEntityByDefinition(
                                                        true,
                                                        relationshipDefinition))));

                                    constructEntity(
                                        newAutomation.entityType,
                                        undefined,
                                        newAutomation);

                                    return Promise.resolve(new EntityActionResult());
                                }
                            }
                        ]
                    :
                        []
        ];
    }

    getAuditTrailEntities(entity: Entity, types): AuditTrailEntity[]
    {
        const activation =
            entity.getRelatedEntityByDefinition(
                false,
                types.Automation.RelationshipDefinition.Activations
            );

        return [
            new AuditTrailEntity(entity, entity.entityType.nameSingular),
            ...activation
                ? [ new AuditTrailEntity(activation, activation.entityType.nameSingular) ]
                : []
        ];
    }

    // ----------------------- Private logic ------------------------
}
