import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { observable } from 'mobx';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityEventController } from '../../../../../@Api/Controller/Directory/EntityEventController';
import { EntityEventFilter } from '../../../../../@Api/Model/Implementation/EntityEventFilter';
import { EntityEventTypeStore } from '../EntityEventTypeStore';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { EntityByDate } from '../../../../../@Api/Model/Implementation/EntityByDate';
import { EntityCacheReferrer } from '../../../../Service/Entity/EntityCacheReferrer';
import uuid from '../../../../../@Util/Id/uuid';
import { EntityCacheService } from '../../../../Service/Entity/EntityCacheService';
import { EntityEventsPage } from '../../Timeline/Model/EntityEventsPage';

const CacheReferrer: EntityCacheReferrer = 'EntityEventSelectionBuilder';

export class EntityEventSelectionBuilder
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityEventController') entityEventController: EntityEventController;
    @injectWithQualifier('EntityEventTypeStore') entityEventTypeStore: EntityEventTypeStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('EntityCacheService') entityCacheService: EntityCacheService;

    // ------------------------- Properties -------------------------

    entityType: EntityType;
    entity: Entity;
    fromDate: Date;
    toDate: Date;
    _offset: number;
    _limit: number;
    filters = observable.array<EntityEventFilter>();
    _onlyIncludeEventsWithoutUser: boolean | undefined;
    cacheReferrer: EntityCacheReferrer;

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType,
                entity: Entity)
    {
        this.entityType = entityType;
        this.entity = entity;
        this.cacheReferrer = `EventQuery.${uuid()}`;
        this._limit = 50;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    public static fromEntity(entity: Entity)
    {
        return new EntityEventSelectionBuilder(
            entity.entityType,
            entity);
    }

    public filter(...filters:  EntityEventFilter[]): this
    {
        this.filters.push(...filters);

        return this;
    }

    public onlyIncludeEventsWithoutUser(onlyIncludeEventsWithoutUser: boolean | undefined): this
    {
        this._onlyIncludeEventsWithoutUser = onlyIncludeEventsWithoutUser;

        return this;
    }

    public from(fromDate: Date): this
    {
        this.fromDate = fromDate;

        return this;
    }

    public to(toDate: Date): this
    {
        this.toDate = toDate;

        return this;
    }

    public offset(offset: number): this
    {
        this._offset = offset;

        return this;
    }

    public limit(limit: number): this
    {
        this._limit = limit;

        return this;
    }

    public select(): Promise<EntityEventsPage>
    {
        return this.entityEventController.getEvents(
            this.entityType.id,
            this.entity ? this.entity.id : undefined,
            undefined,
            undefined,
            undefined,
            this.fromDate && this.fromDate.getTime(),
            this.toDate && this.toDate.getTime(),
            undefined,
            undefined,
            this.filters.map(filter => filter.descriptor()),
            this._onlyIncludeEventsWithoutUser,
            this._offset,
            this._limit,
            undefined,
            'event.date',
            false)
            .then(
                result =>
                {
                    this.entityEventTypeStore.initializeEvents(
                        result.events,
                        undefined,
                        this.cacheReferrer);

                    return Promise.resolve(result);
                });
    }

    public count(): Promise<number>
    {
        return this.entityEventController.countEvents(
            this.entityType.id,
            this.entity ? this.entity.id : undefined,
            undefined,
            undefined,
            undefined,
            this.fromDate && this.fromDate.getTime(),
            this.toDate && this.toDate.getTime(),
            undefined,
            undefined,
            this.filters.map(filter => filter.descriptor()),
            this._onlyIncludeEventsWithoutUser,
            undefined);
    }

    public selectEntitiesByLatestActivity(): Promise<EntityByDate[]>
    {
        return this.entityEventController.getEntitiesByLatestActivity(
            this.entityType.id,
            this.entity ? this.entity.id : undefined,
            this.filters.map(filter => filter.descriptor()),
            this._onlyIncludeEventsWithoutUser,
            this._offset || 0,
            this._limit || 20)
            .then(
                entitiesByDate =>
                {
                    const entitiesToInitialize =
                        entitiesByDate.map(
                            entity =>
                                entity.entity);

                    // Initialize entities without merging with cache
                    entitiesToInitialize.forEach(
                        entity =>
                            entity.initialize(
                                this.entityTypeStore,
                                undefined,
                                undefined,
                                undefined,
                                false));

                    // Merge entities into cache in one go
                    // Simultaneously, build a map containing the cached entity instances such that they can be
                    // overwritten in the events
                    const initializedEntityById = new Map<number, Entity>();

                    this.entityCacheService
                        .mergeEntityNetworkForEntities(
                            entitiesToInitialize,
                            true,
                            CacheReferrer)
                        .forEach(
                            entity =>
                                initializedEntityById.set(
                                    entity.id,
                                    entity));

                    entitiesByDate.forEach(
                        entityByDate =>
                            entityByDate.entity = initializedEntityById.get(entityByDate.entity.id));

                    return Promise.resolve(entitiesByDate);
                });
    }

    dispose()
    {
        this.entityCacheService.disposeReferrer(this.cacheReferrer);
    }

    // ----------------------- Private logic ------------------------
}
