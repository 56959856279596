import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useDimensionInstances } from './Api/useDimensionInstances';
import HoverCard from '../../../../../../@Future/Component/Generic/Card/HoverCard/HoverCard';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { TableLayoutRowViewer } from './TableLayoutRowViewer';
import { groupBy } from '../../../../../../@Util/MapUtils/groupBy';
import ChildTable from '../../../../../../@Api/Layout/Type/Table/Model/ChildTable';
import { TableLayoutViewerProps } from './TableLayoutViewer';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    lastInChildTable: {
        borderBottomWidth: 10,
    }
});

export interface TableLayoutBodyViewerProps extends TableLayoutViewerProps
{
    loader: React.ReactNode;
    childTable?: ChildTable;
}

export const TableLayoutBodyViewer: React.FC<TableLayoutBodyViewerProps> =
    observer(
        props =>
        {
            const {
                parameterDictionary,
                parameterAssignment,
                commitContext,
                layout,
                childTable,
                loader,
            } = props;
            const classes = useStyles();
            const context =
                useMemo(
                    () =>
                        new FunctionContext(
                            parameterDictionary,
                            parameterAssignment,
                            commitContext
                        ),
                    [
                        parameterDictionary,
                        parameterAssignment,
                        commitContext,
                    ]
                );
            const {
                instances: rows,
                isLoading: isLoadingRows,
                hasMore: hasMoreRows,
                loadMore: loadMoreRows,
            } = useDimensionInstances(
                layout,
                childTable?.sections ?? layout.rowSections,
                context
            );
            const {
                instances: columns,
                isLoading: isLoadingColumns,
                hasMore: hasMoreColumns,
                loadMore: loadMoreColumns,
            } = useDimensionInstances(
                layout,
                layout.columnSections,
                context
            );
            const hasMore =
                useMemo(
                    () =>
                        hasMoreRows || hasMoreColumns,
                    [
                        hasMoreRows,
                        hasMoreColumns,
                    ]
                );
            const loadMore =
                useCallback(
                    async () =>
                    {
                        if (hasMoreColumns)
                        {
                            await loadMoreColumns();
                        }

                        if (hasMoreRows)
                        {
                            await loadMoreRows();
                        }
                    },
                    [
                        hasMoreColumns,
                        loadMoreColumns,
                        hasMoreRows,
                        loadMoreRows,
                    ]
                );
            const childTablesByRowSectionId =
                useComputed(
                    () =>
                        groupBy(
                            layout.childTables
                                .filter(
                                    childTable =>
                                        childTable.dimension === 'Row'
                                ),
                            childTable =>
                                childTable.parentSection.id
                        ),
                    [layout]
                );

            if (isLoadingRows || isLoadingColumns)
            {
                return <>{loader}</>;
            }
            else
            {
                const totalColumnSize = columns.map(column => column.size).reduce((a, b) => a + b, 0);
                const percentagePerColumnWidth = 100 / totalColumnSize;

                return <>
                    {
                        rows.map(
                            (row, idx) =>
                                <TableLayoutRowViewer
                                    key={row.id}
                                    {...props}
                                    row={row}
                                    columns={columns}
                                    context={context}
                                    percentagePerColumnWidth={percentagePerColumnWidth}
                                    childTablesByRowSectionId={childTablesByRowSectionId}
                                    childTable={childTable}
                                    last={idx === rows.length - 1 && !hasMore}
                                />
                        )
                    }
                    {
                        hasMore &&
                        <TableRow>
                            <TableCell
                                colSpan={columns.length}
                                className={childTable !== undefined && classes.lastInChildTable}
                            >
                                <HoverCard
                                    onClick={loadMore}
                                    disabled={isLoadingRows || isLoadingColumns}
                                >
                                    <LocalizedText
                                        code="Generic.LoadMore"
                                        value="Meer laden"
                                    />...
                                </HoverCard>
                            </TableCell>
                        </TableRow>
                    }
                </>;
            }
        }
    );