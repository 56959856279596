import { AssistantPredicate } from '../../Model/Predicate/AssistantPredicate';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { findAssistantCompositePredicate } from './findAssistantCompositePredicate';
import { findAssistantComparisonPredicate } from './findAssistantComparisonPredicate';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { findAssistantInstanceOfPredicate } from './findAssistantInstanceOfPredicate';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';

export function findAssistantPredicate(
    predicate: AssistantPredicate,
    parameters: ParameterDictionary,
    resultParameter: Parameter<EntityValueType>
): Predicate
{
    switch (predicate.type)
    {
        case 'Composite':
            return findAssistantCompositePredicate(
                predicate,
                parameters,
                resultParameter
            );

        case 'Comparison':
            return findAssistantComparisonPredicate(
                predicate,
                parameters,
                resultParameter
            );

        case 'InstanceOf':
            return findAssistantInstanceOfPredicate(
                predicate,
                resultParameter
            );

        default:
            throw new Error(`Could not resolve predicate: ${JSON.stringify(predicate)}`);
    }
}
