import React, { useMemo } from 'react';
import { Month } from '../../../Model/Util/Month';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import StaticSelectbox from '../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';

interface RecurrenceMonthEditorProps
{
    value: Month;
    onChange: (value: Month) => void;
    disabled?: boolean;
}

export const RecurrenceMonthEditor: React.FC<RecurrenceMonthEditorProps> =
    ({
        value,
        onChange,
        disabled,
     }) =>
    {
        const months =
            useMemo(
                () => [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                ],
                []
            );

        return <StaticSelectbox
            options={
                months.map(
                    month => ({
                        id: month,
                        value: month,
                        label: localizeText(`Generic.${month}`, month)
                    })
                )
            }
            onChange={onChange}
            value={value}
            disabled={disabled}
        />;
    };
