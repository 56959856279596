import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import ExpansionPanel from '../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import Expansion from './Expansion/Expansion';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import ExpansionGroup from '../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import { UnderlineMode } from '../../Input/UnderlineMode';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface RelationshipConstructorProps
{
    entity: Entity;
    relationshipDefinition: EntityRelationshipDefinition;
    isParent: boolean;
    inset?: boolean;
    autoCommit?: boolean;
    underline?: UnderlineMode;
    expanded?: boolean;
    disabled?: (fieldPath: EntityFieldPath) => boolean;
    commitContext?: CommitContext;
}

const RelationshipConstructor: React.FC<RelationshipConstructorProps> =
    props =>
    {
        return <ExpansionGroup
            disabled={props.expanded === undefined}
        >
            <ExpansionPanel
                id={props.relationshipDefinition.id}
                summary={
                    <Header
                        title={props.relationshipDefinition.getName(props.isParent, props.entity.entityType)}
                        inset={props.inset}
                    />
                }
                expansion={
                    <Expansion
                        {...props}
                    />
                }
                expanded={props.expanded}
            />
        </ExpansionGroup>;
    };

RelationshipConstructor.defaultProps = {
    inset: false
};

export default observer(RelationshipConstructor);
