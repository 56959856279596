import { EntityType } from '../../Model/Implementation/EntityType';
import getNameFieldOverrideByType from './getNameFieldOverrideByType';
import FieldInput from '../../../@Component/Domain/Multiplayer/Model/Input/FieldInput';
import Input from '../../../@Component/Domain/Multiplayer/Model/Input/Input';

export default function getNameFieldByType(type: EntityType): Input
{
    const input = getNameFieldOverrideByType(type);

    if (input)
    {
        return input;
    }
    else
    {
        const field = type.getInheritedNameField();

        if (field)
        {
            return new FieldInput(field.entityType, field);
        }
    }
}
