import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import { runInAction } from 'mobx';
import EntitySelectionFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/EntitySelectionFormInputLayout';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { EntityContext } from '../../../../Entity/@Model/EntityContext';
import { EntityPath } from '../../../../Entity/Path/@Model/EntityPath';
import useSwitch from '../../../../../../@Util/Switch/useSwitch';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import Popper from '../../../../../../@Future/Component/Generic/Popper/Popper';
import HoverCardBottom from '../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import FieldPathSelector from '../../../../Entity/Path/FieldPathEditor/FieldPathSelector';
import EntitySelectionFormInputLayoutSearchFieldPathEditor from './EntitySelectionFormInputLayoutSearchFieldPathEditor';

export interface EntitySelectionFormInputLayoutSearchFieldPathsEditorProps extends LayoutEditorProps<EntitySelectionFormInputLayout>
{

}

const EntitySelectionFormInputLayoutSearchFieldPathsEditor: React.FC<EntitySelectionFormInputLayoutSearchFieldPathsEditorProps> =
    props =>
    {
        const { layout } = props;

        const fieldContext =
            useMemo(
                () =>
                    new EntityContext(
                        [],
                        EntityPath.fromEntityType(layout.query.entityType)),
                [
                    layout.query
                ]);
        const [ isFieldSelectorOpen, openFieldSelector, closeFieldSelector ] = useSwitch(false);
        const selectFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                        {
                            layout.searchFieldPaths.push(fieldPath);

                            closeFieldSelector();
                        }),
                [
                    layout,
                    closeFieldSelector
                ]);

        return <Input
            labelPosition="top"
            label={
                <LocalizedText
                    code="Generic.SearchFields"
                    value="Zoekvelden"
                />
            }
        >
            <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                <ViewGroupItem>
                    <ol>
                        {
                            layout.searchFieldPaths.map(
                                fieldPath =>
                                    <li
                                        key={fieldPath.id}
                                    >
                                        <EntitySelectionFormInputLayoutSearchFieldPathEditor
                                            {...props}
                                            fieldPath={fieldPath}
                                        />
                                    </li>
                            )
                        }
                    </ol>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Divider />
                    <Popper
                        reference={
                            <HoverCardBottom
                                onClick={openFieldSelector}
                            >
                                Veld toevoegen
                            </HoverCardBottom>
                        }
                        popper={
                            <Card>
                                <FieldPathSelector
                                    context={fieldContext}
                                    onSelect={selectFieldPath}
                                />
                            </Card>
                        }
                        open={isFieldSelectorOpen}
                        onClose={closeFieldSelector}
                    />
                </ViewGroupItem>
            </ViewGroup>
        </Input>;
    };

export default observer(EntitySelectionFormInputLayoutSearchFieldPathsEditor);
