import React from 'react';
import { observer } from 'mobx-react-lite';
import CompositeLayoutItem from '../../../../../../../@Api/Layout/Type/CompositeLayoutItem';
import LayoutViewer from '../../../../Viewer/LayoutViewer';
import { CompositeLayoutViewerProps } from '../CompositeLayoutViewer';

export interface CompositeLayoutItemEditorProps extends CompositeLayoutViewerProps
{
    item: CompositeLayoutItem;
}

const CompositeLayoutItemViewer: React.FC<CompositeLayoutItemEditorProps> =
    props =>
    {
        const { item, parameterAssignment } = props;

        return <LayoutViewer
            {...props}
            parameterAssignment={item.parameterAssignment ?? parameterAssignment}
            layout={item.layout}
        />;
    };

export default observer(CompositeLayoutItemViewer);
