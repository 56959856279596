import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import getPhaseRelationshipDefinition from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/getPhaseRelationshipDefinition';
import getDefaultPhase from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/getDefaultPhase';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export default async function initializeDefaultPhase(
    entity: Entity,
    pipeline?: Entity,
    commitContext?: CommitContext
)
{
    const phaseRelationshipDefinition = getPhaseRelationshipDefinition(entity.entityType);

    if (phaseRelationshipDefinition
        && !entity.hasRelationshipsByDefinition(
            false,
            phaseRelationshipDefinition,
            commitContext
        ))
    {
        return getDefaultPhase(entity.entityType, pipeline)
            .then(
                phase =>
                {
                    updateRelationship(
                        entity,
                        false,
                        phaseRelationshipDefinition,
                        phase,
                        commitContext
                    );
                });
    }
}
