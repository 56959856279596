import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../Model/Implementation/Entity';
import { EntityField } from '../../Model/Implementation/EntityField';
import { CommitContext } from '../Commit/Context/CommitContext';

export default function useTextEntityValue(
    entity: Entity,
    field: EntityField,
    commitContext?: CommitContext
): string | undefined
{
    return useComputed(
        () =>
        {
            if (entity && field)
            {
                const value = entity.getDataObjectValueByField(field, undefined, commitContext);

                if (value === undefined)
                {
                    return undefined;
                }
                else
                {
                    return value.toString();
                }
            }
            else
            {
                return undefined;
            }
        },
        [
            entity,
            field,
            commitContext,
        ]);
}
