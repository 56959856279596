import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../Entity/Type/EntityTypeStore';
import { computed, observable } from 'mobx';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../../Entity/Path/@Model/EntityPath';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';

export class EntityFieldGroup
{
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;

    // ------------------------- Properties -------------------------

    @observable entity: Entity;
    @observable customName: () => string;
    @observable customId: string;
    @observable type: EntityType;

    // ------------------------ Constructor --------------------------

    constructor(type: EntityType, entity?: Entity, customName?: () => string, customId?: string)
    {
        this.entity = entity;
        this.customName = customName;
        this.customId = customId;
        this.type = type;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get id(): number
    {
        return this.entity
            ?
                this.entity.id
            :
                0;
    }

    @computed
    get name(): string
    {
        return this.entity
            ?
                this.entity.name
            :
                this.customName();
    }

    @computed
    get entityType(): EntityType
    {
       return this.type;
    }

    // @computed
    // get fieldPaths(): EntityFieldPath[]
    // {
    //     return this.entityTypeStore.getVisibleEntityFieldPaths(this.type)
    //         .filter(path => (path.isField && !path.field.isStaticField()) || !path.isField)
    //         .filter(path => path.isInFieldGroup(this, this.entityTypeStore));
    // }

    // Don't use the internal entityType to get the fields
    // A child entity could also add fields to this group
    fieldPaths(entityType: EntityType,
               includeStatic: boolean = false,
               includePluralRelationships: boolean = false): EntityFieldPath[]
    {
        const fieldPaths = this.entityTypeStore
            .getVisibleEntityFieldPaths(entityType, includePluralRelationships)
            .filter(path => path.isInFieldGroup(this, this.entityTypeStore));

        if (includeStatic)
        {
            return fieldPaths;
        }
        else
        {
            return fieldPaths
                .filter(path => (path.isField && !path.field.isStaticField()) || !path.isField);
        }
    }

    entityPaths(entityType: EntityType): EntityPath[]
    {
        return this.entityTypeStore
            .getVisibleEntityPaths(entityType)
            .filter(path => path.isInFieldGroup(this, this.entityTypeStore));
    }

}
