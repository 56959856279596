import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PortalDataSourceSignature from '../../../Portal/DataSource/PortalDataSourceSignature';
import DynamicParameterAssignment from '../Dynamic/DynamicParameterAssignment';
import DataSourceValueType from '../../Value/Type/DataSourceValueType';
import getPortalDataSourceSignatureById from '../../../Portal/DataSource/Api/getPortalDataSourceSignatureById';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import queryPortalDataSource from '../../../Portal/DataSource/Api/queryPortalDataSource';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import uuid from 'uuid';
import PortalDataSourceValue from '../../../Portal/DataSource/PortalDataSourceValue';
import DataSourceValue from '../../Value/DataSourceValue';
import localizeText from '../../../Localization/localizeText';
import PrimitiveValue from '../../Value/PrimitiveValue';
import PortalDataSourceOrdering from '../../../Portal/DataSource/PortalDataSourceOrdering';
import CollectionType from '../../Value/Type/CollectionType';
import PortalDataSourceListQuery from '../../../Portal/DataSource/PortalDataSourceListQuery';
import Predicate from './Predicate/Predicate';
import getPredicateFromDescriptor from '../../Api/getPredicateFromDescriptor';
import ParameterDictionary from '../../Parameter/ParameterDictionary';
import { getPartiallyComputedPredicate } from '../../Api/getPartiallyComputedPredicate';
import PagedCollectionValue from '../../Value/PagedCollectionValue';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';

const DefaultOffset = 0;
const DefaultLimit = 100;
const MaxLimit = 100;

export default class DataSourceListQueryComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref dataSourceSignature: PortalDataSourceSignature;
    @observable.ref parameterAssignment: DynamicParameterAssignment;
    @observable.ref filter?: Predicate;
    @observable.shallow orderings: PortalDataSourceOrdering[];
    @observable.ref offset: Computation<any, any>;
    @observable.ref limit: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(dataSourceSignature: PortalDataSourceSignature,
                parameterAssignment: DynamicParameterAssignment,
                filter: Predicate | undefined,
                orderings: PortalDataSourceOrdering[],
                offset: Computation<any, any>,
                limit: Computation<any, any>)
    {
        super();

        this.dataSourceSignature = dataSourceSignature;
        this.parameterAssignment = parameterAssignment;
        this.filter = filter;
        this.orderings = orderings;
        this.offset = offset;
        this.limit = limit;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new CollectionType(
            new DataSourceValueType(
                this.dataSourceSignature.id,
                undefined,
                this.dataSourceSignature));
    }

    isAsync(): boolean
    {
        return true;
    }

    async apply(context: FunctionContext)
    {
        const [ parameterAssignment, offset, limit ] =
            await Promise.all([
                this.parameterAssignment.apply(context),
                safelyApplyFunction(this.offset, context),
                safelyApplyFunction(this.limit, context)
            ]);
        const offsetAsNumber = PrimitiveValue.getAsNumber(offset) ?? DefaultOffset;
        const originalLimitAsNumber = PrimitiveValue.getAsNumber(limit);
        const limitAsNumber = Math.min(originalLimitAsNumber ?? DefaultLimit, MaxLimit);
        const substitutedFilter =
            this.filter
                ? await this.getSubstitutedFilter(this.filter, context)
                : undefined;
        const queryResult =
            await queryPortalDataSource(
                new PortalDataSourceListQuery(
                    uuid(),
                    this.dataSourceSignature,
                    parameterAssignment,
                    substitutedFilter,
                    this.orderings,
                    offsetAsNumber,
                    limitAsNumber
                )
            );
        const elements =
            queryResult.results.map(
                result =>
                    new DataSourceValue(
                        new PortalDataSourceValue(
                            result.id,
                            this.dataSourceSignature.id,
                            parameterAssignment,
                            result.parameterAssignment
                        )
                    )
            );
        const elementType =
            new DataSourceValueType(
                this.dataSourceSignature.id,
                undefined,
                this.dataSourceSignature
            );
        const hasMore =
            originalLimitAsNumber === undefined
            && elements.length >= limitAsNumber;

        return new PagedCollectionValue(
            elements,
            elementType,
            hasMore,
            () =>
                new DataSourceListQueryComputation(
                    this.dataSourceSignature,
                    this.parameterAssignment,
                    this.filter,
                    this.orderings,
                    new PrimitiveValue(
                        DataObject.constructFromTypeIdAndValue(
                            'Number',
                            offsetAsNumber + limitAsNumber
                        )
                    ),
                    new PrimitiveValue(
                        DataObject.constructFromTypeIdAndValue(
                            'Number',
                            limitAsNumber
                        )
                    )
                ).apply(context)
        );
    }

    async getSubstitutedFilter(filter: Predicate,
                               context: FunctionContext)
    {
        return getPartiallyComputedPredicate(
            filter,
            context,
            this.dataSourceSignature.resultParameters
        );
    }

    getName(): string
    {
        return localizeText('Computation.DataSourceListQuery', 'Databron query...');
    }

    validate(): Validation[]
    {
        if (!this.dataSourceSignature)
        {
            return [
                new Validation('Error', 'Geen databron geselecteerd')
            ];
        }

        return [
            ...this.parameterAssignment.validate(),
            ...(this.filter ? this.filter.validate() : []),
            ...this.orderings
                .map(ordering => ordering.validate())
                .reduce((a, b) => a.concat(b), []),
            ...this.offset.validate(),
            ...this.limit.validate()
        ];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'DataSourceListQuery';
        descriptor.dataSourceId = this.dataSourceSignature.id;
        descriptor.parameterAssignment = this.parameterAssignment.toDescriptor();
        descriptor.filter = this.filter?.toDescriptor();
        descriptor.orderings = this.orderings.map(ordering => ordering.toDescriptor());
        descriptor.offset = this.offset.toDescriptor();
        descriptor.limit = this.limit.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.parameterAssignment.getDependencies(),
            ...(this.filter ? this.filter.getDependencies() : []),
            ...this.orderings
                .map(
                    ordering =>
                        ordering.getDependencies())
                .reduce((a, b) => a.concat(b), [])
                .filter(
                    dependency =>
                        !this.dataSourceSignature.resultParameters.hasParameter(dependency.parameter)),
            ...this.offset.getDependencies(),
            ...this.limit.getDependencies()
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const dataSourceSignature = await getPortalDataSourceSignatureById(descriptor.dataSourceId);
        const parameterAssignment =
            await DynamicParameterAssignment.fromDescriptor(
                descriptor.parameterAssignment,
                dataSourceSignature.parameters,
                dependencyContext);
        const resultParametersDependencyContext =
            new AutomationDependencyContext(
                ParameterDictionary.union(
                    dataSourceSignature.resultParameters,
                    dependencyContext.parameterDictionary
                ));
        const filter =
            descriptor.filter
                ?
                    await getPredicateFromDescriptor(
                        descriptor.filter,
                        resultParametersDependencyContext)
                :
                    undefined;
        const orderings =
            await Promise.all<PortalDataSourceOrdering>(
                (descriptor.orderings || []).map(
                    ordering =>
                        PortalDataSourceOrdering.fromDescriptor(
                            ordering,
                            resultParametersDependencyContext)));
        const offset =
            await getComputationFromDescriptor(
                descriptor.offset,
                dependencyContext);
        const limit =
            await getComputationFromDescriptor(
                descriptor.limit,
                dependencyContext);

        return new DataSourceListQueryComputation(
            dataSourceSignature,
            parameterAssignment,
            filter,
            orderings,
            offset,
            limit);
    }

    // ----------------------- Private logic ------------------------
}
