import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { useContext, useEffect, useState } from 'react';
import MultiplayerContext from '../Context/MultiplayerContext';
import Input from '../Model/Input/Input';

export default function useMultiplayerInputFocus(entity?: Entity,
                                                 input?: Input,
                                                 isFocused?: boolean,
                                                 part?: string,
                                                 value?: any,
                                                 _hasBeenFocused: boolean = false)
{
    const multiplayer = useContext(MultiplayerContext);
    const [ hasBeenFocused, setBeenFocused ] = useState(_hasBeenFocused);

    useEffect(
        () =>
        {
            if (multiplayer && entity && input)
            {
                if (hasBeenFocused)
                {
                    if (isFocused && !entity.isNew())
                    {
                        multiplayer.trySendMessage(
                            JSON.stringify({
                                type: 'Entity.Focus',
                                focusPointer:
                                {
                                    entityId: entity.id,
                                    input: input.toDescriptor(),
                                    part: part
                                }
                            }));
                    }
                    else
                    {
                        multiplayer.trySendMessage(
                            JSON.stringify({
                                type: 'Entity.Blur',
                                focusPointer:
                                {
                                    entityId: entity.id,
                                    input: input.toDescriptor(),
                                    part: part
                                }
                            }));
                    }
                }
                else
                {
                    if (isFocused && !entity.isNew())
                    {
                        setBeenFocused(true);
                    }
                }
            }
        },
        [
            multiplayer,
            entity,
            input,
            isFocused,
            part,
            hasBeenFocused,
            setBeenFocused
        ]);

    useEffect(
        () =>
        {
            if (multiplayer && entity && input && !entity.isNew())
            {
                if (hasBeenFocused && isFocused)
                {
                    multiplayer.trySendMessage(
                        JSON.stringify({
                            type: 'Entity.Update',
                            entityId: entity.id,
                            input: input.toDescriptor(),
                            part: part,
                            value: value
                        }));
                }
            }
        },
        [
            multiplayer,
            entity,
            input,
            part,
            hasBeenFocused,
            value,
            isFocused
        ]);
}
