import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import LayoutDependencyContext from '../LayoutDependencyContext';
import { observable } from 'mobx';
import Dimension from '../Style/Dimension';
import localizeText from '../../Localization/localizeText';
import ImageLayout from './ImageLayout';
import Computation from '../../Automation/Function/Computation/Computation';
import getComputationFromDescriptor from '../../Automation/Api/getComputationFromDescriptor';

export default class ComputedImageLayout extends ImageLayout
{
    // ------------------------- Properties -------------------------

    @observable.ref image: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(image: Computation<any, any>,
                width: Dimension | undefined,
                height: Dimension | undefined)
    {
        super(width, height);

        this.image = image;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.ComputedImage', 'Afbeelding (dynamisch)');
    }

    validate(): Validation[]
    {
        return this.image.validate();
    }

    getDependencies(): Dependency[]
    {
        return this.image.getDependencies();
    }

    toDescriptor()
    {
        return {
            type: 'ComputedImage',
            image: this.image.toDescriptor(),
            width: this.width?.toDescriptor(),
            height: this.height?.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new ComputedImageLayout(
            await getComputationFromDescriptor(
                descriptor.image,
                dependencyContext),
            descriptor.width
                ?
                    Dimension.fromDescriptor(descriptor.width)
                :
                    undefined,
            descriptor.height
                ?
                    Dimension.fromDescriptor(descriptor.height)
                :
                    undefined);
    }

    // ----------------------- Private logic ------------------------
}
