import React, { useCallback, useState } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ActivityList from './Model/ActivityList';
import useMetadataSetting from '../../../../../../../@Api/Metadata/useMetadataSetting';
import { Setting } from '../../../../../../../@Api/Settings/Setting';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import DangerTextButton from '../../../../../../../@Future/Component/Generic/Button/Variant/DangerTextButton/DangerTextButton';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import CancelButton from '../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import SaveButton from '../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import ActivityListEditor from './Editor/ActivityListEditor';
import RelatedActivityList from './RelatedActivityList';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import uuid from '../../../../../../../@Util/Id/uuid';
import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import Predicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';

export interface RelatedActivityListRootProps
{
    list: ActivityList;
    entity: Entity;
    onConstruct?: (entity: Entity, path: EntityPath) => void;
    filter?: (parameter: Parameter<EntityValueType>, path: EntityPath) => Predicate | undefined;
    onCount?: (count: number) => void;
    isInEditMode: boolean;
    onChangeEditMode: (isInEditMode: boolean) => void;
    showCreationItem?: boolean;
}

const RelatedActivityListRoot: React.FC<RelatedActivityListRootProps> =
    props =>
    {
        const { list, isInEditMode, onChangeEditMode } = props;
        const [ listSpecification, setListSpecification ] =
            useMetadataSetting(
                list.entityType,
                Setting.Metadata.ActivityList,
                isInEditMode
            );
        const [ loadKey, setLoadKey ] = useState(() => uuid());
        const [ loadedList ] =
            useAsyncResult(
                async () =>
                {
                    if (listSpecification)
                    {
                        const listFromSettings = await ActivityList.fromDescriptor(listSpecification);
                        const existingItemIds =
                            new Set(
                                listFromSettings.items.map(
                                    setting =>
                                    setting.path.id
                                )
                            );

                        return new ActivityList(
                            listFromSettings.parameter,
                            [
                                ...listFromSettings.items,
                                // add missing entity types (which are absent in stored setting)
                                ...list.items
                                    .filter(
                                        item =>
                                            !existingItemIds.has(item.path.id)
                                    )
                                ]
                         );
                    }
                    else
                    {
                        return list;
                    }

                },
                [
                    loadKey,
                    listSpecification,
                    list
                ]);

        const close =
            useCallback(
                () =>
                    onChangeEditMode(false),
                [
                    onChangeEditMode
                ]);

        const clear =
            useCallback(
                async () =>
                {
                    await setListSpecification(undefined);
                    close();
                },
                [
                    setListSpecification,
                    close
                ]);

        const cancel =
            useCallback(
                async () =>
                {
                    setLoadKey(uuid());
                    close();
                },
                [
                    setLoadKey,
                    close
                ]);

        const save =
            useCallback(
                async () =>
                {
                    await setListSpecification(loadedList.toDescriptor());
                    close();
                },
                [
                    setListSpecification,
                    loadedList,
                    close
                ]);

        if (!loadedList)
        {
            return null;
        }
        else if (isInEditMode)
        {
            return <Card>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <ActivityListEditor
                            list={loadedList}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <CardInset>
                            <RightAlignedButtonGroup>
                                <DangerTextButton
                                    label={
                                        <LocalizedText
                                            code="Generic.ResetToFactorySettings"
                                            value="Terug naar fabrieksinstellingen"
                                        />
                                    }
                                    onClick={clear}
                                />
                                <CancelButton
                                    onClick={cancel}
                                />
                                <SaveButton
                                    onClick={save}
                                />
                            </RightAlignedButtonGroup>
                        </CardInset>
                    </ViewGroupItem>
                </ViewGroup>
            </Card>;
        }
        else
        {
            return <RelatedActivityList
                {...props}
                list={loadedList}
            />;
        }
    };

export default observer(RelatedActivityListRoot);
