import { BaseTypeStore } from '../../../@Framework/Store/BaseTypeStore';
import { PredicateType } from './Type/PredicateType';
import { PredicateContext } from './PredicateContext';
import { PredicateSpecification } from './Type/PredicateSpecification';
import { OldPredicate } from './Type/OldPredicate';

export class PredicateTypeStore extends BaseTypeStore<PredicateType>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    evaluate(context: PredicateContext,
             predicate: OldPredicate): boolean
    {
        return predicate.type.evaluate(context, predicate);
    }

    fromSpecification(specification: PredicateSpecification): OldPredicate
    {
        if (this.hasType(specification.type))
        {
            try
            {
                return this.getTypeById(specification.type)
                    .fromSpecification(specification);
            }
            catch (e)
            {
                // console.warn('error resolving predicate', e);

                return undefined;
            }
        }
        else
        {
            return null;
        }
    }

    toSpecification(predicate: OldPredicate): PredicateSpecification
    {
        return predicate.type.toSpecification(predicate);
    }

    // ----------------------- Private logic ------------------------
}
