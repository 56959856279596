import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { ResourceProps } from '../Resource';
import TrackItem from './Item/TrackItem';
import { noteColor } from '../../../../../../@Resource/Theme/Theme';
import { DragObjectWithType, useDrop } from 'react-dnd';
import { runInAction } from 'mobx';
import Item from '../../Model/Item';
import openDialog from '../../../../../../@Service/Navigation/Page/Hooks/openDialog';
import PlanDialog from '../../PlanDialog/PlanDialog';
import Period from '../../Model/Period';
import useDebouncedCallback from '../../../../../../@Util/Debounce/useDebouncedCallback';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';
import { default as TrackModel } from './Model/Track';
import { default as TrackItemModel } from './Model/TrackItem';
import Markup from './Markup/Markup';
import styles from './Track.module.scss';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export interface TrackProps extends ResourceProps
{
    track: TrackModel;
    trackItems: TrackItemModel[];
    intervalType: string;
    disableTimesheetDialog?: boolean;
}

function getOffset(event: React.MouseEvent,
                   toElement: HTMLDivElement): number
{
    const rect = toElement.getBoundingClientRect();

    return event.clientX - rect.left;
}

const Track: React.FC<TrackProps> =
    props =>
    {
        const [ dragItem, setDragItem ] = useState<TrackItemModel>();
        const updateDragPreview =
            useDebouncedCallback(
                (item: DragObjectWithType, diffX: number) =>
                {
                    if (item
                        && item.type === 'TrackItem')
                    {
                        const targetItem = (item as any).item as TrackItemModel;

                        runInAction(
                            () =>
                            {
                                const range =
                                    props.track.getMovedRangeByOffset(
                                        targetItem.range,
                                        diffX);

                                const period = range.getPeriod();

                                if (dragItem)
                                {
                                    dragItem.range = range;
                                    dragItem.item.period = period;
                                }
                                else
                                {
                                    setDragItem(
                                        new TrackItemModel(
                                            new Item(
                                                targetItem.item.selection,
                                                targetItem.item.entity,
                                                props.resource,
                                                period),
                                            range));
                                }
                            });
                    }
                    else if (item
                        && item.type === 'TrackItemResize')
                    {
                        const targetItem = (item as any).item as TrackItemModel;

                        runInAction(
                            () =>
                            {
                                const range =
                                    props.track.getResizedRangeByOffset(
                                        targetItem.range,
                                        diffX);
                                const period = range.getPeriod();

                                if (dragItem)
                                {
                                    dragItem.range = range;
                                    dragItem.item.period = period;
                                }
                                else
                                {
                                    setDragItem(
                                        new TrackItemModel(
                                            new Item(
                                                targetItem.item.selection,
                                                targetItem.item.entity,
                                                props.resource,
                                                period),
                                            range));
                                }
                            });
                    }
                },
                [
                    props.track,
                    props.resource,
                    dragItem,
                    setDragItem
                ],
                1);

        const [{ isOver }, drop] =
            useDrop({
                accept: [ 'TrackItem', 'TrackItemResize' ],
                drop:
                    (item, monitor) =>
                    {
                        if (item.type === 'TrackItem')
                        {
                            const diffX = monitor.getDifferenceFromInitialOffset().x;

                            runInAction(
                                () =>
                                {
                                    const targetItem = ((item as any).item as TrackItemModel);

                                    const range =
                                        props.track.getMovedRangeByOffset(
                                            targetItem.range,
                                            diffX);

                                    const period = range.getPeriod();
                                    const oldResource = targetItem.item.resource;
                                    const newResource = props.resource;

                                    targetItem.range = range;
                                    targetItem.item.period = period;
                                    targetItem.item.resource = props.resource;

                                    return new CommitBuilder()
                                        .setObjectValueInEntityByFieldPath(
                                            targetItem.item.entity,
                                            targetItem.item.selection.startDateFieldPath,
                                            period.from.toDate()
                                        )
                                        .setObjectValueInEntityByFieldPath(
                                            targetItem.item.entity,
                                            targetItem.item.selection.endDateFieldPath,
                                            period.to.toDate()
                                        )
                                        .setObjectValueInEntityByFieldPath(
                                            targetItem.item.entity,
                                            targetItem.item.selection.resourcePath.field(),
                                            newResource,
                                            oldResource
                                        )
                                        .commit();
                                });
                        }
                        else if (item.type === 'TrackItemResize')
                        {
                            const diffX = monitor.getDifferenceFromInitialOffset().x;

                            runInAction(
                                () =>
                                {
                                    const targetItem = ((item as any).item as TrackItemModel);

                                    const range =
                                        props.track.getResizedRangeByOffset(
                                            targetItem.range,
                                            diffX);

                                    const period = range.getPeriod();

                                    targetItem.range = range;
                                    targetItem.item.period = period;

                                    return new CommitBuilder()
                                        .setObjectValueInEntityByFieldPath(
                                            targetItem.item.entity,
                                            targetItem.item.selection.endDateFieldPath,
                                            period.to.toDate()
                                        )
                                        .commit();
                                });
                        }
                    },
                canDrop: () => true,
                collect: monitor => ({
                    isOver: !!monitor.isOver(),
                    canDrop: !!monitor.canDrop()
                }),
                hover:
                    (item, monitor) =>
                        updateDragPreview(
                            item,
                            monitor.getDifferenceFromInitialOffset().x)
            });

        useEffect(
            () =>
            {
                if (!isOver)
                {
                    setDragItem(undefined);
                }
            },
            [
                isOver,
                setDragItem
            ]);

        const style =
            useComputed(
                () => ({
                    display: 'grid',
                    gridTemplateColumns: props.track.cells.map(c => `${c.width}px`).join(' '),
                    position: 'relative',
                    backgroundColor: isOver && noteColor,
                    minHeight: 32
                } as CSSProperties),
                [
                    props.track,
                    isOver
                ]);

        const [ selectElement, setSelectElement ] = useState<HTMLDivElement>();
        const [ startOffset, setStartOffset ] = useState<number>();
        const [ endOffset, setEndOffset ] = useState<number>();
        const [ isSelecting, setSelecting ] = useState(false);

        const onStartSelect =
            useCallback(
                (event: React.MouseEvent) =>
                {
                    const selectionCell = props.track.getCellByOffset(getOffset(event, event.target as HTMLDivElement));
                    const startOffset = selectionCell.offset;

                    setSelectElement(event.target as HTMLDivElement);
                    setStartOffset(startOffset);
                    setSelecting(true);
                },
                [
                    props.track,
                    setStartOffset,
                    setSelecting,
                ]);

        const onMoveSelection =
            useCallback(
                (event: React.MouseEvent) =>
                {
                    if (isSelecting)
                    {
                        const selectionCell = props.track.getCellByOffset(getOffset(event, selectElement));
                        const selectionOffset = selectionCell.offset + selectionCell.width;

                        setEndOffset(selectionOffset);
                    }
                },
                [
                    props.track,
                    setEndOffset,
                    selectElement,
                    isSelecting
                ]);

        const onEndSelect =
            useCallback(
                (event: React.MouseEvent) =>
                {
                    if (isSelecting)
                    {
                        const selectionCell = props.track.getCellByOffset(getOffset(event, selectElement));
                        const endOffset = selectionCell.offset + selectionCell.width;

                        setEndOffset(endOffset);
                        setSelecting(false);
                    }
                },
                [
                    props.track,
                    setEndOffset,
                    selectElement,
                    isSelecting,
                    setSelecting
                ]);

        const clearSelection =
            useCallback(
                () =>
                {
                    setStartOffset(undefined);
                    setEndOffset(undefined);
                    setSelecting(false);
                    setDragItem(undefined);
                },
                [
                    setStartOffset,
                    setEndOffset,
                    setSelecting,
                    setDragItem
                ]);

        const period =
            useMemo<Period>(
                () =>
                {
                    if (startOffset && endOffset)
                    {
                        const min = Math.min(startOffset, endOffset);
                        const max = Math.max(startOffset, endOffset);

                        const startDate = props.track.getCellByOffset(min).cell.period.from;
                        const endDate = props.track.getCellByOffset(max).cell.period.from;

                        return {
                            from: startDate,
                            to: endDate
                        };
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    startOffset,
                    endOffset,
                    props.period,
                    props.specification.dataCellDuration
                ]);

        useEffect(
            () =>
            {
                if (!isSelecting && period)
                {
                    if (props.onPlan)
                    {
                        props.onPlan(
                            props.resource,
                            period.from.toDate(),
                            period.to.toDate());
                    }
                    else
                    {
                        openDialog(
                            close =>
                                <PlanDialog
                                    period={period}
                                    resource={props.resource}
                                    parameterAssignment={props.parameterAssignment}
                                    resourceSelections={props.resourcePlanner.resourceSelections}
                                    onClose={
                                        () =>
                                        {
                                            clearSelection();
                                            close();
                                        }
                                    }
                                />);
                    }
                }

            },
            [
                isSelecting,
                period,
                clearSelection,
                props.resource,
                props.resourcePlanner.resourceSelections,
                props.onPlan
            ]);

        const selectionStyle =
            useMemo(
                () =>
                    startOffset !== undefined
                    && endOffset !== undefined
                    && isSelecting
                    && ({
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: Math.min(startOffset, endOffset),
                        width: Math.max(startOffset, endOffset) - Math.min(startOffset, endOffset),
                        backgroundColor: 'rgba(253, 237, 173, 0.5)'
                    }) as CSSProperties,
                [
                    startOffset,
                    endOffset
                ]);

        const items =
            useComputed(
                () =>
                {
                    let items: TrackItemModel[];

                    if (dragItem && isOver)
                    {
                        items =
                            props.trackItems
                                .filter(
                                    item =>
                                        !equalsEntity(item.item.entity, dragItem.item.entity));
                        items.push(dragItem);
                    }
                    else
                    {
                        items = props.trackItems;
                    }

                    items.sort(
                        (a, b) =>
                            a.range.from.cell.idx - b.range.from.cell.idx);

                    return items;
                },
                [
                    props.trackItems,
                    dragItem,
                    isOver
                ]);

        const selectionByIdx =
            useComputed(
                () =>
                    new Map(
                        props.resourcePlanner.resourceSelections.map(
                            (selection, idx) => [
                                selection,
                                idx
                            ])),
                [
                    props.resourcePlanner
                ]);

        const rowByItem =
            useComputed(
                () =>
                {
                    const rowByItem = new Map<TrackItemModel, number>();
                    const openItems = new Set<TrackItemModel>();

                    items
                        .forEach(
                            item =>
                            {
                                // End items
                                Array.from(openItems)
                                    .filter(
                                        openItem =>
                                            openItem.range.to.cell.idx < item.range.from.cell.idx)
                                    .forEach(
                                        item =>
                                        {
                                            openItems.delete(item);
                                        });

                                const currentItems = Array.from(openItems);
                                currentItems.push(item);

                                currentItems
                                    .sort(
                                        (a, b) =>
                                            selectionByIdx.get(a.item.selection) - selectionByIdx.get(b.item.selection))
                                    .forEach(
                                        (openItem, idx) =>
                                        {
                                            rowByItem.set(
                                                openItem,
                                                idx);
                                        });

                                openItems.add(item);
                            });

                    return rowByItem;
                },
                [
                    items
                ]);

        return <div
            ref={drop}
            style={style}
            onMouseDown={props.timesheet ? undefined : onStartSelect}
            onMouseMove={props.timesheet ? undefined : onMoveSelection}
            onMouseUp={props.timesheet ? undefined : onEndSelect}
            onMouseLeave={props.timesheet ? undefined : clearSelection}
            className={styles.root}
        >
            <Markup
                {...props}
                trackItems={props.trackItems}
            />
            {
                !props.timesheet &&
                selectionStyle &&
                <div
                    style={selectionStyle}
                />
            }
            {
                !props.timesheet &&
                items
                    .filter(
                        item =>
                            !dragItem || !equalsEntity(item.item.entity, dragItem.item.entity))
                    .map(
                        item =>
                            <TrackItem
                                key={item.item.entity.uuid}
                                {...props}
                                item={item}
                                row={rowByItem.get(item)}
                            />)
            }
            {
                !props.timesheet && dragItem && isOver &&
                <TrackItem
                    {...props}
                    track={props.track}
                    item={dragItem}
                    row={rowByItem.get(dragItem)}
                    preview
                />
            }
        </div>;
    };

export default observer(Track);
