import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import HoverCard from '../../../../../@Future/Component/Generic/Card/HoverCard/HoverCard';
import Popper from '../../../../../@Future/Component/Generic/Popper/Popper';
import useSwitch from '../../../../../@Util/Switch/useSwitch';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { classNames } from '../../../../../@Future/Util/Class/classNames';
import Icon from '../../../../../@Future/Component/Generic/Icon/Icon';
import styles from './ActionConstructor.module.scss';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import LayoutAction from '../../../../../@Api/Layout/Action/LayoutAction';
import RouteAction from '../../../../../@Api/Layout/Action/Type/RouteAction';
import TextComputation from '../../../../../@Api/Automation/Function/Computation/TextComputation';
import localizeText from '../../../../../@Api/Localization/localizeText';
import PortalActionInvocationAction from '../../../../../@Api/Layout/Action/Type/PortalActionInvocationAction';
import DynamicParameterAssignment from '../../../../../@Api/Automation/Function/Dynamic/DynamicParameterAssignment';
import SetFormParameterAction from '../../../../../@Api/Layout/Action/Type/SetFormParameterAction';
import DataSourceValueType from '../../../../../@Api/Automation/Value/Type/DataSourceValueType';
import PrimitiveValueType from '../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { FileType } from '../../../DataObject/Type/File/FileType';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import PortalDataSourceFileDownloadAction from '../../../../../@Api/Layout/Action/Type/PortalDataSourceFileDownloadAction';
import PortalContext from '../../../../../@Api/Portal/Context/PortalContext';
import ActionTemplateInvocationAction from '../../../../../@Api/Layout/Action/Type/ActionTemplateInvocationAction';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import FormLayoutContexts from '../../Type/Form/FormLayoutContexts';
import DownloadFileAction from '../../../../../@Api/Layout/Action/Type/DownloadFileAction';
import EmptyValue from '../../../../../@Api/Automation/Value/EmptyValue';
import RefreshAction from '../../../../../@Api/Layout/Action/Type/RefreshAction';

export type ActionConstructorMode = 'Button' | 'Line'

export interface ActionConstructorProps
{
    context: FunctionContext,
    onConstruct: (action: LayoutAction, afterIdx?: number) => void;
    mode: ActionConstructorMode;
}

export interface ActionType
{
    id: string;
    name: string;
    construct: () => void;
}

const ActionConstructor: React.FC<ActionConstructorProps> =
    props =>
    {
        const portalContext = useContext(PortalContext);
        const formLayoutContexts = useContext(FormLayoutContexts);
        const [ isOpen, open, close ] = useSwitch(false);

        const onConstruct =
            useCallback(
                (action: LayoutAction) =>
                {
                    props.onConstruct(action);
                    close();
                },
                [
                    props.onConstruct,
                    close
                ]);

        const types =
            useMemo<ActionType[]>(
                () => [
                    {
                        id: 'Route',
                        name: localizeText('LayoutAction.Route.NavigateTo', 'Navigeren naar...'),
                        construct:
                            () =>
                                onConstruct(
                                    new RouteAction(
                                        new TextComputation(
                                            '/',
                                            false,
                                            [])))
                    },
                    {
                        id: 'Refresh',
                        name: localizeText('LayoutAction.Refresh', 'Verversen'),
                        construct:
                            () =>
                                onConstruct(
                                    new RefreshAction()
                                )
                    },
                    ...portalContext
                        ?
                            [
                                {
                                    id: 'PortalActionInvocation',
                                    name: localizeText('LayoutAction.PortalActionInvocation.InvokeAction', 'Actie uitvoeren...'),
                                    construct:
                                        () =>
                                            onConstruct(
                                                new PortalActionInvocationAction(
                                                    undefined,
                                                    new DynamicParameterAssignment()))
                                }
                            ]
                        :
                            [
                                {
                                    id: 'ActionTemplateInvocation',
                                    name: localizeText('LayoutAction.PortalActionInvocation.InvokeAction', 'Actie uitvoeren...'),
                                    construct:
                                        () =>
                                            onConstruct(
                                                new ActionTemplateInvocationAction(
                                                    undefined,
                                                    new DynamicParameterAssignment()))
                                },
                                {
                                    id: 'DownloadFile',
                                    name: localizeText('LayoutAction.DownloadFile', 'Bestand downloaden'),
                                    construct:
                                        () =>
                                            onConstruct(
                                                new DownloadFileAction(EmptyValue.instance)
                                            )
                                },
                            ],
                    ...formLayoutContexts
                        .reverse()
                        .map(
                            formLayoutContext =>
                                formLayoutContext.layout.parameters.parameters.map(
                                    parameter => ({
                                        id: `SetFormParameter.${parameter.id}`,
                                        name:
                                            localizeText(
                                                'LayoutAction.SetFormParameter.MutateParameter',
                                                '${parameter} muteren',
                                                {
                                                    parameter: parameter.name
                                                }),
                                        construct:
                                            () =>
                                                onConstruct(
                                                    new SetFormParameterAction(
                                                        formLayoutContext.layout.id,
                                                        parameter,
                                                        undefined))
                                    })))
                        .reduce((a, b) => a.concat(b), []),
                    ...portalContext
                        ?
                            props.context.parameterDictionary.parameters
                                .map(
                                    parameter => [
                                        parameter,
                                        parameter.type instanceof DataSourceValueType && parameter.type.dataSourceSignature !== undefined
                                            ?
                                                parameter.type.dataSourceSignature.resultParameters.parameters.filter(
                                                    field =>
                                                        field.type instanceof PrimitiveValueType
                                                            && field.type.type instanceof FileType)
                                            :
                                                []
                                    ] as [ Parameter<DataSourceValueType>, Parameter<any>[] ])
                                .filter(
                                    ([, fileFields ]) => fileFields.length > 0)
                                .map(
                                    ([ parameter, fileFields ]) =>
                                        fileFields.map(
                                            fileField => ({
                                                id: `PortalDataSourceDownloadAttachment.${parameter.id}`,
                                                name:
                                                    localizeText(
                                                        'LayoutAction.PortalDataSourceFileDownload.Download',
                                                        '${field} downloaden',
                                                        {
                                                            field: fileField.name
                                                        }),
                                                construct:
                                                    () =>
                                                        onConstruct(
                                                            new PortalDataSourceFileDownloadAction(
                                                                parameter.type.dataSourceSignature,
                                                                parameter,
                                                                fileField))
                                            })))
                                .reduce((a, b) => a.concat(b), [])
                        :
                            []
                ],
                [
                    onConstruct,
                    formLayoutContexts,
                    props.context
                ]);

        return <Popper
            reference={
                props.mode === 'Button'
                    ?
                        <HoverCard
                            onClick={open}
                        >
                            <LocalizedText
                                code="AutomationEditor.AddActionButton"
                                value="+ Actie"
                            />
                        </HoverCard>
                    :
                        <div
                            className={styles.container}
                        >
                            <div
                                className={
                                    classNames(
                                        styles.root,
                                        isOpen && styles.open,
                                        styles.vertical)}
                                onClick={open}
                            >
                                <div
                                    className={styles.line}
                                />
                                <div
                                    className={styles.icon}
                                >
                                    <Icon
                                        icon="add"
                                        size={11}
                                    />
                                </div>
                            </div>
                        </div>
            }
            popper={
                <Card>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={0}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            <Menu>
                                {
                                    types.map(
                                        type =>
                                            <Item
                                                key={type.id}
                                                name={type.name}
                                                onClick={type.construct}
                                            />)
                                }
                            </Menu>
                        </ViewGroupItem>
                    </ViewGroup>
                </Card>
            }
            open={isOpen}
            onClose={close}
        />;
    };

export default observer(ActionConstructor);
