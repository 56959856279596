import { BaseStore, getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { RichTextToolbarStore } from '../Toolbar/RichTextToolbarStore';
import { ToolbarIconCommandButtonStore } from '../Toolbar/Types/IconCommandButton/ToolbarIconCommandButtonStore';
import { ToolbarElementStore } from '../Toolbar/Types/ToolbarElementStore';
import { ToolbarFontSizeStore } from '../Toolbar/Types/FontSize/ToolbarFontSizeStore';
import { ToolbarColorPickerStore } from '../Toolbar/Types/ColorPicker/ToolbarColorPickerStore';
import Quill from 'quill';

export function getRichtextEditorDefaultButtons(): Array<ToolbarElementStore | ToolbarElementStore[]>
{
    return [
        [
            new ToolbarIconCommandButtonStore(1, 'format_bold', 'bold'),
            new ToolbarIconCommandButtonStore(2, 'format_italic', 'italic'),
            new ToolbarIconCommandButtonStore(3, 'format_underlined', 'underline'),
            new ToolbarIconCommandButtonStore(4, 'format_strikethrough', 'strike')
        ],
        [
            new ToolbarIconCommandButtonStore(5, 'format_list_numbered', 'list.ordered'),
            new ToolbarIconCommandButtonStore(6, 'format_list_bulleted', 'list.bullet')
        ],
        [
            new ToolbarIconCommandButtonStore(7, 'format_align_left', 'align.'),
            new ToolbarIconCommandButtonStore(8, 'format_align_center', 'align.center'),
            new ToolbarIconCommandButtonStore(9, 'format_align_right', 'align.right')
        ],
        /*[
            new ToolbarIconCommandButtonStore(10, 'format_indent_decrease', 'indent.-1'),
            new ToolbarIconCommandButtonStore(11, 'format_indent_increase', 'indent.+1')
        ],*/
        [
            new ToolbarIconCommandButtonStore(12, 'link', 'link'),
            new ToolbarFontSizeStore(13, 'size'),
            new ToolbarColorPickerStore(14, 'color')
        ]
    ];
}

export interface RichTextEditorClasses
{
    editor?: string;
    toolbar?: string;
}

export interface RichTextEditorProps
{
    white?: boolean;
    placeholder: PropType<RichTextEditorStore, RichTextEditorProps, string>;
    content: PropType<RichTextEditorStore, RichTextEditorProps, string>;
    controls: PropType<RichTextEditorStore, RichTextEditorProps, Array<Array<ToolbarElementStore | ToolbarElementStore[]>>>;
    initialHeight: PropType<RichTextEditorStore, RichTextEditorProps, number>;
    autoHideToolbarWhenEmpty: PropType<RichTextEditorStore, RichTextEditorProps, boolean>;
    showToolbarOnFocus: PropType<RichTextEditorStore, RichTextEditorProps, boolean>;
    allowTextStyling?: PropType<RichTextEditorStore, RichTextEditorProps, boolean>;
    hasLocalManagedContent?: PropType<RichTextEditorStore, RichTextEditorProps, boolean>;
    onChangeCallback?: (richText: string) => void;
    onFocus?: (store: RichTextEditorStore) => void;
    onBlur?: (store: RichTextEditorStore) => void;
    onCtrlEnter?: (store: RichTextEditorStore) => void;
    autoFocus?: PropType<RichTextEditorStore, RichTextEditorProps, boolean>;
    classes?: RichTextEditorClasses;
    showEditorInCard?: boolean;
}

const defaultProps: Partial<RichTextEditorProps> =
{
    white: false,
    initialHeight: 50,
    autoHideToolbarWhenEmpty: false,
    showToolbarOnFocus: false,
    allowTextStyling: true,
    hasLocalManagedContent: false,
    autoFocus: false
};

export class RichTextEditorStore extends BaseStore<RichTextEditorProps>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable isFocused: boolean;
    @observable quillEditorReference: any;
    @observable cursorPosition: number;
    @observable selectionLength: number;
    @observable blurTimer: any;
    @observable content: string;

    // ------------------------ Constructor -------------------------

    constructor(props: RichTextEditorProps)
    {
        super(props, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        if (this.providedContent)
        {
            this.setRichTextContent(this.providedContent, true);
        }

        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    @computed
    get classes(): RichTextEditorClasses
    {
        return getOrCompute(this, this.props.classes);
    }

    @computed
    get white(): boolean
    {
        return getOrCompute(this, this.props.white);
    }

    @computed
    get quillEditor(): Quill
    {
        if (this.quillEditorReference)
        {
            return this.quillEditorReference.getEditor();
        }

        return null;
    }

    @computed
    get autoFocus(): boolean
    {
        return getOrCompute(this, this.props.autoFocus);
    }

    @computed
    get contentLength(): number
    {
        if (this.content && this.quillEditor)
        {
            return this.quillEditor.getLength() - 1;
        }
        return -1;
    }

    @computed
    get initialHeight(): number
    {
        return getOrCompute(this, this.props.initialHeight);
    }

    @computed
    get providedContent(): string
    {
        return getOrCompute(this, this.props.content);
    }

    @computed
    get placeholder(): string
    {
        return getOrCompute(this, this.props.placeholder);
    }

    @computed
    get controls(): Array<Array<ToolbarElementStore | ToolbarElementStore[]>>
    {
        return getOrCompute(this, this.props.controls);
    }

    @computed
    get hideToolbarWhenEmpty(): boolean
    {
        return getOrCompute(this, this.props.autoHideToolbarWhenEmpty);
    }

    @computed
    get showToolbarOnFocus(): boolean
    {
        return getOrCompute(this, this.props.showToolbarOnFocus);
    }

    @computed
    get allowTextStyling(): boolean
    {
        return getOrCompute(this, this.props.allowTextStyling);
    }

    @computed
    get showEditorInCard(): boolean
    {
        return getOrCompute(this, this.props.showEditorInCard);
    }

    @computed
    get onChangeCallback()
    {
        return this.props.onChangeCallback;
    }

    @computed
    get onFocus()
    {
        return this.props.onFocus;
    }

    @computed
    get onBlur()
    {
        return this.props.onBlur;
    }

    @computed
    get onCtrlEnter()
    {
        return this.props.onCtrlEnter;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get richTextToolbarStore(): RichTextToolbarStore
    {
        if (this.quillEditor)
        {
            return new RichTextToolbarStore(
                this.quillEditor,
                this.controls,
                this.hideToolbarWhenEmpty
                    ?
                        () => this.showToolbarOnFocus ? this.isFocused || this.contentLength > 0 : this.contentLength > 0
                    :
                        () => this.showToolbarOnFocus ? this.isFocused : true,
                this.classes ? this.classes.toolbar : undefined);
        }
        else
        {
            return undefined;
        }
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    setQuillReference(editorRef: any)
    {
        this.quillEditorReference = editorRef;

        // Disable spellcheck
        /*
            if (this.quillEditor)
            {
                this.quillEditor.root.spellcheck = false;
            }
        */

        // Autofocus
        if (this.autoFocus && this.quillEditorReference)
        {
            this.quillEditorReference.focus();
        }
    }

    @action.bound
    setRichTextContent(content: string,
                       isSilent: boolean = true)
    {
        this.content = content;

        if (!isSilent && this.onChangeCallback)
        {
            this.onChangeCallback(this.content);
        }
    }

    @action.bound
    focus()
    {
        if (this.blurTimer)
        {
            clearTimeout(this.blurTimer);
            this.blurTimer = undefined;
        }

        this.isFocused = true;

        if (this.onFocus)
        {
            this.onFocus(this);
        }
    }

    @action.bound
    blur()
    {
        if (this.blurTimer)
        {
            clearTimeout(this.blurTimer);
            this.blurTimer = undefined;
        }

        this.isFocused = false;

        if (this.onBlur)
        {
            this.onBlur(this);
        }
    }

    @action.bound
    delayedBlur()
    {
        if (this.isFocused && !this.blurTimer)
        {
            this.blurTimer = setTimeout(this.blur, 500);
        }
    }

    @action.bound
    updateCursorPosition(isManualCall: boolean = false)
    {
        if (this.content && this.quillEditor)
        {
            const range = this.quillEditor.getSelection();

            if (range != null)
            {
                if (isManualCall || !(range.index === 0 && range.length === 0))
                {
                    this.cursorPosition = range.index;
                    this.selectionLength = range.length;
                }
            }
        }
        else
        {
            this.cursorPosition = 0;
            this.selectionLength = 0;
        }
    }

    @action.bound
    insertAtCursor(content: string)
    {
        if (this.quillEditor)
        {
            this.quillEditor.insertText(this.cursorPosition, content);
        }
    }

    @action.bound
    insertHtmlAtCursor(content: string)
    {
        if (this.quillEditor)
        {
            const placeholder = '_$~ph_';
            this.quillEditor.insertText(this.cursorPosition, placeholder);
            this.setRichTextContent(this.content.replace(placeholder, content));
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    private getPosition(): number
    {
        return this.content.length;
    }
}
