import { TextProps, TextStore } from '../../../Generic/Text/TextStore';
import { getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { ViewComponent } from '../../ViewStack/Model/ViewComponent';
import { computed } from 'mobx';
import Icon from '../../Icon/Icon';
import { IconStore } from '../../Icon/IconStore';
import { primaryColor } from '../../../../@Resource/Theme/Theme';
import localizeText from '../../../../@Api/Localization/localizeText';

export interface MoreTextProps extends TextProps
{
    isExpanded: PropType<MoreTextStore, TextProps, boolean>;
    toggleExpansion: () => void;
    moreLabel?: PropType<MoreTextStore, TextProps, string>;
}

const defaultProps: Partial<MoreTextProps> =
{
    moreLabel:
        store =>
            store.isExpanded
                ? localizeText('Generic.Less', `Minder`)
                : localizeText('Generic.More', `Meer`)
};

export class MoreTextStore extends TextStore<MoreTextProps>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: MoreTextProps)
    {
        super(
        {
            label: '%0 %1',
                isInline: false,
            style:
            {
                display: 'inline-flex',
                    marginTop: 4,
                position: 'relative',
                left: 10
            },
            childTextStores:
                textStore => [
                    new TextStore({
                        label: () => this.moreLabel,
                        onClick: () => Promise.resolve(props.toggleExpansion()),
                        size: 12,
                        isInline: true,
                        color: primaryColor,
                        hoverDecoration: undefined,
                        ...props
                    }),
                    new TextStore({
                        isInline: true,
                        viewComponent:
                            new ViewComponent(
                                Icon,
                                new IconStore({
                                    icon:
                                        () =>
                                            this.isExpanded
                                                ?
                                                    'keyboard_arrow_up'
                                                :
                                                    'keyboard_arrow_down',
                                    isInline: true,
                                    color: primaryColor,
                                    size: 16,
                                    weight: 100,
                                    onClick: () => props.toggleExpansion(),
                                    style: {
                                        paddingTop: 3,
                                        marginLeft: 2
                                    }
                                })),
                        style: {
                            visibility: textStore.isHovering ? 'visible' : 'hidden'
                        }
                    })
                ],
            ...props
        },
        defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get isExpanded(): boolean
    {
        return getOrCompute(this, this.props.isExpanded);
    }

    @computed
    get moreLabel(): string
    {
        return getOrCompute(this, this.props.moreLabel);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
