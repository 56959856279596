import React, { useContext } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../Type/Api/useTypes';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import EntityInput from '../../../../../Input/Input';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import TextEditor from '../../../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import { GlobalEnvironment } from '../../../../../../../../@Global/GlobalEnvironment';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';

export interface GeneralPortalWhiteLabelSettingsProps
{
    entity: Entity;
    commitContext?: CommitContext;
}

export const GeneralPortalWhiteLabelSettings: React.FC<GeneralPortalWhiteLabelSettingsProps> =
    observer(
        ({
             entity,
             commitContext,
         }) =>
        {
            const types = useTypes();
            const currentUserStore = useContext(CurrentUserContext);

            return <Card
                inset
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={15}
                >
                    <ViewGroupItem>
                        <Input
                            label={
                                <LocalizedText
                                    code="Generic.Link"
                                    value="Link"
                                />
                            }
                            labelPosition="left"
                        >
                            <TextEditor
                                value={`${GlobalEnvironment.APP_ENDPOINT}?organizationId=${currentUserStore.currentOrganization.uuid}&portalId=${entity.uuid}`}
                                disabled
                            />
                        </Input>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <EntityInput
                            entity={entity}
                            field={types.Portal.RelationshipDefinition.EmailSender}
                            labelPosition="left"
                            commitContext={commitContext}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <EntityInput
                            entity={entity}
                            field={types.Portal.Field.Logo}
                            labelPosition="left"
                            commitContext={commitContext}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <EntityInput
                            entity={entity}
                            field={types.Portal.Field.Favicon}
                            labelPosition="left"
                            commitContext={commitContext}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <EntityInput
                            entity={entity}
                            field={types.Portal.Field.Url}
                            labelPosition="left"
                            commitContext={commitContext}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <EntityInput
                            entity={entity}
                            field={types.Portal.Field.PrimaryColor}
                            labelPosition="left"
                            commitContext={commitContext}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </Card>;
        }
    );