import ParameterDictionary from '../../../Automation/Parameter/ParameterDictionary';
import Parameter from '../../../Automation/Parameter/Parameter';
import EntityValueType from '../../../Automation/Value/Type/EntityValueType';
import { EntityRelationshipDefinition } from '../../../Model/Implementation/EntityRelationshipDefinition';
import { BaseEntityParameterId, RelatedEntityParameterId } from './RelationshipDefinitionFilterConstants';

export default function getRelationshipDefinitionFilterParameterDictionary(relationshipDefinition: EntityRelationshipDefinition,
                                                                           isParent: boolean = false)
{
    return new ParameterDictionary([
        true,
        false
    ].map(
        isSideParent =>
            new Parameter(
                isSideParent === isParent ? RelatedEntityParameterId : BaseEntityParameterId,
                new EntityValueType(relationshipDefinition.getEntityType(isSideParent)),
                true,
                relationshipDefinition.getEntityType(isSideParent).getName())));
}
