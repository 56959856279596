import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityRelationshipMutation } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipMutation';
import Emphasis from '../Emphasis/Emphasis';
import getDisplayType from '../../Api/getDisplayType';
import EntityTypeContext from '../../../../Type/EntityTypeContext';
import Tooltip from '../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export interface RelationshipProps
{
    event: EntityRelationshipMutation;
}

const Relationship: React.FC<RelationshipProps> =
    props =>
    {
        const entityTypeStore = useContext(EntityTypeContext);

        if (props.event.entityRelationshipDefinition.isPlural(props.event.isParentRelationship))
        {
            if (props.event.toRelatedEntity)
            {
                return <>
                    <Emphasis>{getDisplayType(props.event.toRelatedEntity.entityType).getName().toLowerCase()}</Emphasis> {localizeText('TimeLine.AuditTrail.Added', 'toegevoegd')}
                </>;
            }
            else
            {
                return <>
                    <Emphasis>{props.event.entityRelationshipDefinition.getEntityType(props.event.isParentRelationship).getName()}</Emphasis> {localizeText('TimeLine.AuditTrail.Deleted', 'verwijderd')}
                </>;
            }
        }
        else
        {
            return <Tooltip
                title={
                    <>{localizeText('TimeLine.AuditTrail.OldValue', 'Waarde was')} {<Emphasis>{((props.event.fromRelatedEntity && props.event.fromRelatedEntity.name) || '-').toLowerCase()}</Emphasis>}</>
                }
            >
                <Emphasis>{props.event.entityRelationshipDefinition.getName(props.event.isParentRelationship, props.event.entity.entityType, entityTypeStore).toLowerCase()}</Emphasis> {localizeText('TimeLine.AuditTrail.EditedTo', 'aangepast naar')} <Emphasis>{((props.event.toRelatedEntity && props.event.toRelatedEntity.name) || '-').toLowerCase()}</Emphasis>
            </Tooltip>;
        }
    };

export default observer(Relationship);
