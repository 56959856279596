import Validation from '../../Automation/Validation/Validation';
import Dependency from '../../Automation/Parameter/Dependency';
import { observable } from 'mobx';
import LayoutAction from '../Action/LayoutAction';
import getLayoutActionFromDescriptor from '../Action/Api/getLayoutActionFromDescriptor';
import LayoutDependencyContext from '../LayoutDependencyContext';
import { LayoutEventType } from './LayoutEventType';

export default class LayoutEventTrigger
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable eventType: LayoutEventType;
    @observable.ref action: LayoutAction;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                eventType: LayoutEventType,
                action: LayoutAction)
    {
        this.id = id;
        this.eventType = eventType;
        this.action = action;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return this.action.validate();
    }

    getDependencies(): Dependency[]
    {
        return this.action.getDependencies();
    }

    toDescriptor()
    {
        return {
            id: this.id,
            eventType: this.eventType,
            action: this.action.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new LayoutEventTrigger(
            descriptor.id,
            descriptor.eventType,
            await getLayoutActionFromDescriptor(
                descriptor.action,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
