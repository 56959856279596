import { Selection } from '../../../../../@Api/Selection/Model/Selection';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntitySelectionAggregate } from './EntitySelectionAggregate';
import { EntitySelectionOrdering } from './EntitySelectionOrdering';
import hashCode from '../../../../../@Util/HashCode/hashCode';
import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';
import cached from '../../../../../@Util/Cached/cached';
import { EntityCacheInformation } from '../../../../Service/Entity/EntityCacheInformation';
import { isEventCoveringFieldPath } from '../Api/isEventCoveringFieldPath';
import { EntityQueryType } from './EntityQueryType';

export class EntityQuery
{
    // ------------------------- Properties -------------------------

    type: EntityQueryType;
    selection: Selection;
    offset: number;
    limit: number;
    ordering: EntitySelectionOrdering[];
    aggregates: EntitySelectionAggregate[];
    logName?: string;
    isCached: boolean;
    cacheInformation: EntityCacheInformation;

    // ------------------------ Constructor -------------------------

    constructor(
        type: EntityQueryType,
        selection: Selection,
        offset: number,
        limit: number,
        ordering: EntitySelectionOrdering[],
        aggregates: EntitySelectionAggregate[],
        logName: string,
        isCached: boolean
    )
    {
        this.type = type;
        this.selection = selection;
        this.offset = offset;
        this.limit = limit;
        this.ordering = ordering;
        this.aggregates = aggregates;
        this.logName = logName;
        this.isCached = isCached;
        this.cacheInformation = new EntityCacheInformation(new Set());
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    matches(entity: Entity)
    {
        try
        {
            return this.selection.matches(entity);
        }
        catch (e)
        {
            console.error(e);

            return false;
        }
    }

    isAffectedBy(event: EntityEvent)
    {
        try
        {
            return this.selection.isAffectedBy(event)
                || this.aggregates.some(
                    aggregate =>
                        isEventCoveringFieldPath(
                            event,
                            aggregate.fieldPath
                        )
                    );
        }
        catch (e)
        {
            console.error(e);

            return false;
        }
    }

    @cached
    get hashCodeWithoutOffset()
    {
        return hashCode(`${this.type},${this.selection.hashCode()},${this.ordering.length},${this.aggregates.length}`);
    }

    equals(query: EntityQuery)
    {
        return this.type === query.type
            && this.selection.equals(query.selection)
            && arrayEquals(this.ordering, query.ordering, isOrderingEqual)
            && arrayEquals(this.aggregates, query.aggregates, isAggregateEqual)
            && this.offset === query.offset
            && this.limit === query.limit;
    }

    // ----------------------- Private logic ------------------------
}

export function arrayEquals<T>(a: T[], b: T[], elementMatcher: (a: T, b: T) => boolean)
{
    if (a.length === b.length)
    {
        return a.every((elementA, idx) => elementMatcher(elementA, b[idx]));
    }
    else
    {
        return false;
    }
}

export function isOrderingEqual(a: EntitySelectionOrdering,
                                b: EntitySelectionOrdering)
{
    return JSON.stringify(a.expression.toDescriptor()) === JSON.stringify(b.expression.toDescriptor())
        && a.isAscending === b.isAscending;
}

export function isAggregateEqual(a: EntitySelectionAggregate,
                                 b: EntitySelectionAggregate)
{
    return a.fieldPath.id === b.fieldPath.id
        && JSON.stringify(a.representation) === JSON.stringify(b.representation)
        && a.aggregate === b.aggregate;
}
