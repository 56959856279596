import React, {useCallback} from 'react';
import {observer, useComputed} from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import {IObservableArray, runInAction} from 'mobx';
import PredicateEditor, {PredicateEditorProps} from '../../PredicateEditor';
import CompositePredicate
    from '../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import {LogicalOperator} from '../../../../../../../../DataObject/Model/LogicalOperator';
import IconButton from '../../../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import Predicate from '../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import styles from './CompositePredicateEditor.module.scss';
import {classNames} from '../../../../../../../../../../@Future/Util/Class/classNames';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import PredicateConstructor from '../../Constructor/PredicateConstructor';

export interface CompositePredicateEditorProps extends PredicateEditorProps<CompositePredicate>
{

}

const CompositePredicateEditor: React.FC<CompositePredicateEditorProps> =
    props =>
    {
        const { value, onChange, disabled } = props;

        const addPredicate =
            useCallback(
                (predicate: Predicate) =>
                    runInAction(
                        () =>
                            value.predicates.push(predicate)),
                [
                    value
                ]);

        const deletePredicate =
            useCallback(
                (predicate: Predicate) =>
                    runInAction(
                        () =>
                        {
                            (value.predicates as IObservableArray).remove(predicate);

                            if (value.predicates.length === 0 && onChange !== undefined)
                            {
                                return onChange(undefined);
                            }
                        }),
                [
                    value,
                    onChange
                ]);

        const joinWord =
            useComputed(
                () =>
                {
                    if (value.operator === LogicalOperator.And)
                    {
                        return <LocalizedText
                            code="PredicateEditor.JoinWord.And"
                            value="én"
                        />;
                    }
                    else
                    {
                        return <LocalizedText
                            code="PredicateEditor.JoinWord.Or"
                            value="óf"
                        />
                    }
                },
                [
                    value
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            {
                value.predicates.map(
                    (predicate, idx) =>
                        <ViewGroupItem
                            key={idx}
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                                alignment="center"
                            >
                                {
                                    idx > 0 &&
                                        <ViewGroupItem>
                                            {joinWord}
                                        </ViewGroupItem>
                                }
                                <ViewGroupItem>
                                    <div
                                        className={classNames(predicate instanceof CompositePredicate && styles.compositeComponent)}
                                    >
                                        <PredicateEditor
                                            {...props}
                                            onChange={
                                                newPredicate =>
                                                {
                                                    if (newPredicate === undefined)
                                                    {
                                                        deletePredicate(predicate);
                                                    }
                                                    else
                                                    {
                                                        runInAction(
                                                            () =>
                                                                value.predicates[idx] = newPredicate
                                                        );
                                                    }
                                                }
                                            }
                                            value={predicate}
                                        />
                                    </div>
                                </ViewGroupItem>
                                {
                                    !disabled &&
                                    <ViewGroupItem>
                                        <IconButton
                                            icon="delete"
                                            tooltip={
                                                <LocalizedText
                                                    code="Generic.Delete"
                                                />
                                            }
                                            onClick={
                                                () =>
                                                    deletePredicate(predicate)
                                            }
                                        />
                                    </ViewGroupItem>
                                }
                            </ViewGroup>
                        </ViewGroupItem>)
            }
            {
                !disabled &&
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                        alignment="center"
                    >
                        {
                            value.predicates.length > 0 &&
                                <ViewGroupItem>
                                    {joinWord}...
                                </ViewGroupItem>
                        }
                        <ViewGroupItem>
                            <PredicateConstructor
                                {...props}
                                onConstruct={addPredicate}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(CompositePredicateEditor);
