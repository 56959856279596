import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { WidgetProps } from '../Widget';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import styles from './Note.module.scss';
import CollapsibleToolbar from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/CollapsibleToolbar';
import EditorButton from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorCommandButton';
import EditorFontSelector from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorFontSelector';
import EditorColorPicker from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorColorPicker';
import { runInAction } from 'mobx';
import NoteWidget from '../../Model/Widget/Note/NoteWidget';
import useDelayedCallback from '../../../../../../@Future/Util/Hook/useDelayedCallback';
import useSwitch from '../../../../../../@Util/Switch/useSwitch';
import CollaborationEditor from '../../../../Multiplayer/CollaborationEditor/CollaborationEditor';
import RichText from '../../../../../Generic/RichText/RichText';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import DefaultToolbarButtons from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/DefaultToolbarButtons';

export interface NoteProps extends WidgetProps<NoteWidget>
{

}

const Note: React.FC<NoteProps> =
    props =>
    {
        const [ note, setNote ] = useState('');

        useEffect(
            () =>
            {
                setNote(props.widget.note || '');
            },
            [
                props.widget
            ]);

        const [ , focus, _blur ] = useSwitch(false);

        const saveNote =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            props.widget.note = note),
                [
                    note,
                    props.widget
                ]);

        const blur =
            useCallback(
                () =>
                {
                    _blur();
                    saveNote();
                },
                [
                    _blur,
                    saveNote
                ]);

        useDelayedCallback(
            note,
            useCallback(
                delayedNote =>
                    runInAction(
                        () =>
                            props.widget.note = delayedNote),
                [
                    props.widget
                ]),
            5000);

        return <Card
            classes={{
                root: styles.root
            }}
            inset
        >
            <div
                className={styles.menuButton}
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={5}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                    />
                    {
                        props.onAdd &&
                            <ViewGroupItem>
                                <IconButton
                                    icon="add"
                                    onClick={props.onAdd}
                                    tooltip="Toevoegen aan dashboard"
                                />
                            </ViewGroupItem>
                    }
                    <ViewGroupItem>
                        <DragHandle
                            horizontal
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <MenuButton>
                            <Menu>
                                <DeleteItem
                                    onClick={props.onDelete}
                                />
                            </Menu>
                        </MenuButton>
                    </ViewGroupItem>
                </ViewGroup>
            </div>
            {
                props.disabled
                    ?
                        <RichText
                            value={props.widget.note}
                        />
                    :
                        <CollaborationEditor
                            id={props.widget.id}
                            initialValue={props.widget.note}
                            placeholder={localizeText('Widget.Note.Placeholder', 'Voer hier je notitie in...')}
                            onChange={setNote as any}
                            onFocus={focus}
                            onBlur={blur}
                            allowTextStyling
                            showToolbarOnFocus
                        >
                            <CollapsibleToolbar>
                                <DefaultToolbarButtons />

                                <EditorButton icon="link" tooltip="Link" command="link"/>
                                <EditorFontSelector />
                                <EditorColorPicker tooltip="Selecteer kleur" />
                            </CollapsibleToolbar>
                        </CollaborationEditor>
            }
        </Card>;
    };

export default observer(Note);
