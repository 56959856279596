import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import getTypes from './getTypes';

export function extendContactRelationshipSelection(
    relationshipDefinition: EntityRelationshipDefinition,
    isParent: boolean,
    entity: Entity,
    builder: EntitySelectionBuilder,
    commitContext?: CommitContext
)
{
    const types = getTypes();

    builder
        .where(
            cb =>
                cb.relatedToEntity(
                    builder.rootPath
                        .joinTo(
                            types.Relation.RelationshipDefinition.Relationships,
                            true)
                        .castTo(types.Relation.Organization.Type)
                        .joinTo(
                            types.Relationship.Organization.RelationshipDefinition.Organization,
                            true),
                    entity.getRelatedEntityByDefinition(
                        isParent,
                        relationshipDefinition,
                        commitContext)
                )
        );
}