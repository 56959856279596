import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import OptionalRelatedEntityLayout from '../../../../../@Api/Layout/Type/OptionalRelatedEntityLayout';
import useOptionalRelatedEntity from './Api/useOptionalRelatedEntity';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';

export interface OptionalRelatedEntityLayoutViewerProps extends LayoutViewerProps<OptionalRelatedEntityLayout>
{

}

const OptionalRelatedEntityLayoutViewer: React.FC<OptionalRelatedEntityLayoutViewerProps> =
    props =>
    {
        const entity =
            useComputed(
                () =>
                    props.parameterAssignment?.getObjectValue(props.layout.parameter),
                [
                    props.parameterAssignment,
                    props.layout.parameter
                ]);

        const relatedEntityParameter =
            useComputed(
                () =>
                    props.layout.relatedEntityParameter,
                [
                    props.layout
                ]);

        const nextParameterDictionary =
            useComputed(
                () =>
                    relatedEntityParameter
                        ?
                        props.parameterDictionary.getNewDictionaryWithParameter(relatedEntityParameter)
                        :
                        props.parameterDictionary,
                [
                    relatedEntityParameter,
                    props.parameterDictionary
                ]);

        const optionalRelatedEntity =
            useOptionalRelatedEntity(
                entity,
                props.layout.field?.relationshipDefinition,
                props.layout.field?.isParent);

        const nextParameterAssignment =
            useComputed(
                () =>
                    props.parameterAssignment && relatedEntityParameter && optionalRelatedEntity
                        ?
                            props.parameterAssignment.getNewAssignmentWithParameter(
                                relatedEntityParameter,
                                new EntityValue(optionalRelatedEntity))
                        :
                            props.parameterAssignment,
                [
                    props.parameterAssignment,
                    relatedEntityParameter,
                    optionalRelatedEntity
                ]);

        if (props.layout.field)
        {
            return <LayoutViewer
                parameterDictionary={nextParameterDictionary}
                parameterAssignment={nextParameterAssignment}
                layout={props.layout.layout}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(OptionalRelatedEntityLayoutViewer);
