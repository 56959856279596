import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { EntityFieldPath } from '../Path/@Model/EntityFieldPath';
import Dialog from '../../../../@Future/Component/Generic/Dialog/Dialog';
import DialogTitle from '../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import DialogActions from '../../../../@Future/Component/Generic/Dialog/Actions/DialogActions';
import DialogContent from '../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import CancelButton from '../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import AuthenticationManagerContext from '../../../../@Service/Authentication/AuthenticationManagerContext';
import useTypes from '../Type/Api/useTypes';
import { EntityPath } from '../Path/@Model/EntityPath';
import Checkbox from '../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import Input from '../../../../@Future/Component/Generic/Input/Input/Input';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import PrimaryButton from '../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import AdministratorRightsRequired from '../../License/AdministratorRightsRequired/AdministratorRightsRequired';
import CurrentUserContext from '../../User/CurrentUserContext';
import Predicate from '../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import useToggle from '../../../../@Util/Toggle/useToggle';
import { Radio } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { classNames } from '../../../../@Future/Util/Class/classNames';
import { primaryColor } from '../../../../@Resource/Theme/Theme';

const useStyles = makeStyles({
  active: {
      color: `${primaryColor} !important`
  }
});

export interface ExportDialogProps
{
    entityType: EntityType;
    filter?: Predicate;
    fieldPaths?: EntityFieldPath[];
    close: () => void;
}

const ExportDialog: React.FC<ExportDialogProps> =
    props =>
    {
        const authenticationManager = useContext(AuthenticationManagerContext);
        const apiClient = authenticationManager.apiClient;
        const types = useTypes();
        const classes = useStyles();
        const { close } = props;

        const [ exportAsView, toggleExportAsView ] = useToggle(false);
        const [ includeAttachments, setIncludeAttachments ] = useState(false);
        const [ includeNotes, setIncludeNotes ] = useState(false);
        const [ includeExternalIds, setIncludeExternalIds ] = useState(false);
        const [ includeAllFields, setIncludeAllFields ] = useState(false);
        const [ includeIds, setIncludeIds ] = useState(false);

        useEffect(
            () =>
            {
                if (exportAsView)
                {
                    setIncludeAttachments(false);
                    setIncludeNotes(false);
                    setIncludeExternalIds(false);
                    setIncludeAllFields(false);
                    setIncludeIds(false);
                }
            },
            [
                exportAsView,
                setIncludeAttachments,
                setIncludeNotes,
                setIncludeExternalIds,
                setIncludeAllFields,
                setIncludeIds,
            ]
        );

        const exportFieldPaths =
            useMemo(
                () => [
                    ...props.fieldPaths,
                    includeNotes &&
                        EntityPath.fromEntityType(props.entityType)
                            .joinTo(
                                types.Entity.RelationshipDefinition.Notes,
                                false)
                            .field(types.Note.Field.Content),
                    includeAttachments &&
                        EntityPath.fromEntityType(props.entityType)
                            .joinTo(
                                types.Entity.RelationshipDefinition.Attachments,
                                false)
                            .field(types.Attachment.Field.File),
                    ...includeExternalIds
                        ?
                            [
                                types.ExternalId.Field.Service,
                                types.ExternalId.Field.Type,
                                types.ExternalId.Field.Id
                            ].map(
                                field =>
                                    EntityPath.fromEntityType(props.entityType)
                                        .joinTo(
                                            types.Entity.RelationshipDefinition.ExternalIds,
                                            false)
                                        .field(field))
                        :
                            []
                ].filter(item => item),
                [
                    props.fieldPaths,
                    props.entityType,
                    types,
                    includeAttachments,
                    includeNotes,
                    includeExternalIds
                ]);

        const download =
            useCallback(
                () =>
                {
                    return fetch(
                        apiClient.url('/entity/export'),
                        {
                            method: 'POST',
                            headers: {
                                ...apiClient.defaultHeaders,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                entityTypeId: props.entityType.id,
                                filter: props.filter?.toDescriptor(),
                                fieldPaths:
                                    exportFieldPaths.map(
                                        fieldPath =>
                                            fieldPath.descriptor),
                                includeAllFields: includeAllFields,
                                includeIds: includeIds,
                                exportAsView: exportAsView
                            })
                        })
                        .then(
                            result =>
                                result.blob())
                        .then(
                            blob =>
                            {
                                const file = window.URL.createObjectURL(blob);
                                window.location.assign(file);
                                close();
                            });
                },
                [
                    apiClient,
                    props.entityType,
                    props.filter,
                    exportFieldPaths,
                    includeAllFields,
                    includeIds,
                    exportAsView,
                    close
                ]);

        const currentUserStore = useContext(CurrentUserContext);
        const isExportingAllowed =
            useMemo(
                () =>
                    currentUserStore.isAdministrator
                    || (currentUserStore.rightProfile.role.isExportingEnabled()
                        && currentUserStore.rightProfile.role.isPermissionGrantedForType(props.entityType, 'canExport')),
                [
                    currentUserStore
                ]);

        return <Dialog
            open
        >
            <DialogTitle>
                <LocalizedText
                    code="ExportDialog.Title"
                    value="${typeName} exporteren"
                    typeName={props.entityType.namePlural}
                />
            </DialogTitle>
            <DialogContent>
                <AdministratorRightsRequired
                    disabled={isExportingAllowed}
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        <ViewGroupItem>
                            <Input
                                label={
                                    <LocalizedText
                                      code="ExportDialog.ViewType"
                                      value="Weergave (één bestand)"
                                    />
                                }
                                labelPosition="right"
                            >
                                <Radio
                                  checked={exportAsView}
                                  onClick={() => toggleExportAsView(true)}
                                  className={classes.active}
                                />
                            </Input>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Input
                                label={
                                <LocalizedText
                                    code="ExportDialog.ExtendedType"
                                    value="Uitgebreid"
                                  />
                                }
                                labelPosition="right"
                            >
                              <Radio
                                checked={!exportAsView}
                                onClick={() => toggleExportAsView(false)}
                                className={classes.active}
                              />
                            </Input>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Input
                                label={
                                    <LocalizedText
                                        code="ExportDialog.IncludeNotes"
                                        value="Inclusief notities"
                                    />
                                }
                                labelPosition="right"
                            >
                                <Checkbox
                                    checked={includeNotes}
                                    onToggle={setIncludeNotes}
                                    disabled={exportAsView}
                                    className={classNames(!exportAsView && classes.active)}
                                />
                            </Input>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Input
                                label={
                                    <LocalizedText
                                        code="ExportDialog.IncludeAttachments"
                                        value="Inclusief bijlagen"
                                    />
                                }
                                labelPosition="right"
                            >
                                <Checkbox
                                    checked={includeAttachments}
                                    onToggle={setIncludeAttachments}
                                    disabled={exportAsView}
                                    className={classNames(!exportAsView && classes.active)}
                                />
                            </Input>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Input
                                label={
                                    <LocalizedText
                                        code="ExportDialog.IncludeExternalIds"
                                        value="Inclusief externe IDs"
                                    />
                                }
                                labelPosition="right"
                            >
                                <Checkbox
                                    checked={includeExternalIds}
                                    onToggle={setIncludeExternalIds}
                                    disabled={exportAsView}
                                    className={classNames(!exportAsView && classes.active)}
                                />
                            </Input>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Input
                                label={
                                    <LocalizedText
                                        code="ExportDialog.IncludeIds"
                                        value="Inclusief Tribe IDs"
                                    />
                                }
                                labelPosition="right"
                            >
                                <Checkbox
                                    checked={includeIds}
                                    onToggle={setIncludeIds}
                                    disabled={exportAsView}
                                    className={classNames(!exportAsView && classes.active)}
                                />
                            </Input>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Input
                                label={
                                    <LocalizedText
                                        code="ExportDialog.IncludeAllFields"
                                        value="Alle velden exporteren"
                                    />
                                }
                                labelPosition="right"
                            >
                                <Checkbox
                                    checked={includeAllFields}
                                    onToggle={setIncludeAllFields}
                                    disabled={exportAsView}
                                    className={classNames(!exportAsView && classes.active)}
                                />
                            </Input>
                        </ViewGroupItem>
                    </ViewGroup>
                </AdministratorRightsRequired>
            </DialogContent>
            <DialogActions>
                <CancelButton
                    onClick={close}
                />
                {
                    isExportingAllowed &&
                        <PrimaryButton
                            label={
                                <LocalizedText
                                    code="Generic.Export"
                                    value="Exporteren"
                                />
                            }
                            onClick={download}
                        />
                }
            </DialogActions>
        </Dialog>;
    };

export default ExportDialog;
