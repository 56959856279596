import { action } from 'mobx';

export default class TransactionalCommitAdministration
{
    // ------------------------- Properties -------------------------

    seenObjects: Set<any>;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        this.seenObjects = new Set<any>();
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    @action
    seen(object: any)
    {
        this.seenObjects.add(object);
    }

    // ------------------------ Public logic ------------------------

    hasSeen(object: any): boolean
    {
        return this.seenObjects.has(object);
    }

    // ----------------------- Private logic ------------------------
}
