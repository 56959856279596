import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import HoverCardBottom from '../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import uuid from '../../../../../../@Util/Id/uuid';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import EffectLayoutDependencyEditor from './Dependency/EffectLayoutDependencyEditor';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import EffectLayoutDependency from '../../../../../../@Api/Layout/Type/EffectLayoutDependency';
import EmptyValue from '../../../../../../@Api/Automation/Value/EmptyValue';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';

export interface EffectLayoutDependenciesEditorProps
{
    value: EffectLayoutDependency[];
    onChange: (value: EffectLayoutDependency[]) => void;
    context: FunctionContext;
}

const EffectLayoutDependenciesEditor: React.FC<EffectLayoutDependenciesEditorProps> =
    ({
        value,
        onChange,
        context,
     }) =>
    {
        const createDependency =
            useCallback(
                () =>
                    onChange([
                        ...value,
                        new EffectLayoutDependency(
                            uuid(),
                            EmptyValue.instance
                        )
                    ]),
                [
                    onChange,
                    value,
                ]);
        const deleteDependency =
            useCallback(
                (dependencyToDelete: EffectLayoutDependency) =>
                    onChange(
                        value.filter(
                            dependency =>
                                dependency.id !== dependencyToDelete.id
                        )
                    ),
                [
                    onChange,
                    value,
                ]
            );
        const dividerGlue = useDividerGlue();

        return <Card>
            <CardInset
                bottom={false}
            >
                <CardHeader>
                    Dependencies
                </CardHeader>
            </CardInset>
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={dividerGlue}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="vertical"
                        spacing={15}
                        glue={dividerGlue}
                    >
                        {
                            value.map(
                                dependency =>
                                    <ViewGroupItem
                                        key={dependency.id}
                                    >
                                        <EffectLayoutDependencyEditor
                                            value={dependency}
                                            onDelete={deleteDependency}
                                            context={context}
                                        />
                                    </ViewGroupItem>
                            )
                        }
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <HoverCardBottom
                        onClick={createDependency}
                    >
                        + <LocalizedText code="Generic.Dependency" value="Dependency" />
                    </HoverCardBottom>
                </ViewGroupItem>
            </ViewGroup>
        </Card>;
    };

export default observer(EffectLayoutDependenciesEditor);
