import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { EntityExpansionBuilder } from '../../Selection/Builder/EntityExpansionBuilder';
import equalsEntity from '../../../../../@Api/Entity/Bespoke/equalsEntity';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export class BespokePortalUser extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'PortalUser')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    extendRelationshipSelection(entity: Entity,
                                relationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean,
                                builder: EntitySelectionBuilder,
                                commitContext?: CommitContext)
    {
        super.extendRelationshipSelection(
            entity,
            relationshipDefinition,
            isParent,
            builder,
            commitContext
        );

        if (relationshipDefinition === this.types.PortalUser.RelationshipDefinition.Contact
            && !isParent)
        {
            builder
                .where(
                    cb =>
                        cb.relatedToEntity(
                            builder.rootPath
                                .joinTo(
                                    this.types.Relation.RelationshipDefinition.Relationships,
                                    true)
                                .castTo(this.types.Relation.Organization.Type)
                                .joinTo(
                                    this.types.Relationship.Organization.RelationshipDefinition.Organization,
                                    true),
                            entity.getRelatedEntityByDefinition(
                                false,
                                this.types.PortalUser.RelationshipDefinition.Relationship,
                                commitContext
                            )
                        )
                );
        }
    }

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        // In case of an update of the relationship field, then update team and check if it is a contact and set the contact field
        if (relationshipDefinition === this.types.PortalUser.RelationshipDefinition.Relationship
            && !isParent)
        {
            // Check and update contact
            if (relatedEntity)
            {
                if (relatedEntity.entityType.isA(this.types.Relationship.Person.Contact.Type))
                {
                    const pathToOrgRelationship =
                        EntityPath.fromEntity(relatedEntity)
                            .joinTo(
                                this.types.Relation.RelationshipDefinition.Relationships,
                                true)
                            .castTo(this.types.Relation.Organization.Type)
                            .joinTo(
                                this.types.Relationship.Organization.RelationshipDefinition.Organization,
                                true);

                    await new EntityExpansionBuilder(
                        relatedEntity.entityType,
                        [
                            relatedEntity
                        ],
                        [
                            pathToOrgRelationship
                        ])
                        .expand()
                        .then(
                            () =>
                            {
                                const firstRelationship =
                                    pathToOrgRelationship.traverseEntity(relatedEntity, commitContext)
                                        .find(() => true);

                                if (firstRelationship)
                                {
                                    updateRelationship(
                                        entity,
                                        false,
                                        this.types.PortalUser.RelationshipDefinition.Relationship,
                                        firstRelationship,
                                        commitContext
                                    );
                                }
                                else
                                {
                                    updateRelationship(
                                        entity,
                                        false,
                                        this.types.PortalUser.RelationshipDefinition.Relationship,
                                        undefined,
                                        commitContext
                                    );
                                }

                                updateRelationship(
                                    entity,
                                    false,
                                    this.types.PortalUser.RelationshipDefinition.Contact,
                                    relatedEntity,
                                    commitContext
                                );
                            });
                }
                else if (relatedEntity.entityType.isA(this.types.Relationship.Organization.Type))
                {
                    const contact =
                        entity.getRelatedEntityByDefinition(
                            false,
                            this.types.PortalUser.RelationshipDefinition.Contact,
                            commitContext
                        );

                    if (!contact
                        || !equalsEntity(
                            contact.getRelatedEntityByDefinition(true, this.types.Relation.RelationshipDefinition.Relationships, commitContext),
                            relatedEntity.getRelatedEntityByDefinition(false, this.types.Relationship.Organization.RelationshipDefinition.Organization, commitContext)))
                    {
                        if (contact)
                        {
                            updateRelationship(
                                entity,
                                false,
                                this.types.PortalUser.RelationshipDefinition.Contact,
                                undefined,
                                commitContext
                            );
                        }

                        const pathToFirstContact =
                            EntityPath.fromEntity(relatedEntity)
                                .joinTo(
                                    this.types.Relationship.Organization.RelationshipDefinition.Organization,
                                    false)
                                .joinTo(
                                    this.types.Relation.RelationshipDefinition.Relationships,
                                    false)
                                .castTo(this.types.Relationship.Person.Contact.Type);

                        await new EntityExpansionBuilder(
                            relatedEntity.entityType,
                            [
                                relatedEntity
                            ],
                            [
                                pathToFirstContact
                            ])
                            .expand()
                            .then(
                                () =>
                                {
                                    // It might be that in the meantime the contact was programmatically set
                                    const contact =
                                        entity.getRelatedEntityByDefinition(
                                            false,
                                            this.types.PortalUser.RelationshipDefinition.Contact);

                                    if (!contact ||
                                        !equalsEntity(
                                            contact.getRelatedEntityByDefinition(true, this.types.Relation.RelationshipDefinition.Relationships, commitContext),
                                            relatedEntity.getRelatedEntityByDefinition(false, this.types.Relationship.Organization.RelationshipDefinition.Organization, commitContext)))
                                    {
                                        const firstContact =
                                            pathToFirstContact.traverseEntity(relatedEntity, commitContext)
                                                .find(() => true);

                                        if (firstContact)
                                        {
                                            updateRelationship(
                                                entity,
                                                false,
                                                this.types.PortalUser.RelationshipDefinition.Contact,
                                                firstContact,
                                                commitContext
                                            );
                                        }
                                    }
                                });
                    }
                }
            }
            else
            {
                updateRelationship(
                    entity,
                    false,
                    this.types.PortalUser.RelationshipDefinition.Contact,
                    undefined,
                    commitContext
                );
            }
        }
    }

    // ----------------------- Private logic ------------------------
}
