import { type } from '../../../@Util/Serialization/Serialization';
import { EntityEvent } from './EntityEvent';

@type('EntityActionEvent')
export class EntityActionEvent extends EntityEvent
{
    // ------------------- Persistent Properties --------------------

    actionCode: string;
    actionParameters: any;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
