import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../../../@Framework/Component/BaseComponent';
import { DataObjectEditorStore } from '../Editor/DataObjectEditorStore';
import Text from '../../../../../Generic/Text/Text';

@observer
export class DataObjectLabel extends BaseComponent<DataObjectEditorStore>
{
    renderComponent(store: DataObjectEditorStore)
    {
        return <div
            style={{
                flex: '1 1 auto'
            }}
        >
            <Text
                store={store.labelStore}
            />
        </div>;

        // return <Typography
        //     color={store.isFocused ? 'primary' : 'textSecondary'}
        //     style={{
        //         ...typographyUnderline,
        //         flex: '1 1 auto'
        //     }}
        // >
        //     {store.sideLabel || store.label}
        // </Typography>;
    }
}
