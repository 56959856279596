import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../Type/Api/useTypes';
import Default from '../Default';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import { ContentProps } from '../Content';
import Constructor from '../../Constructor';

export interface RelationProps extends ContentProps
{

}

const Relation: React.FC<RelationProps> =
    props =>
    {
        const types = useTypes();
        const toRelationDefinition =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Relation.Person.Type)
                        ?
                            types.Relationship.Person.RelationshipDefinition.Person
                        :
                            types.Relationship.Organization.RelationshipDefinition.Organization,
                [
                    props.entity,
                    types
                ]);
        const relationship =
            useComputed(
                () =>
                    props.entity.getRelatedEntityByDefinition(
                        true,
                        toRelationDefinition,
                        props.commitContext
                    ),
                [
                    props.entity,
                    toRelationDefinition,
                    props.commitContext,
                ]);

        const relationshipFieldPathFilter =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    !(fieldPath.relationshipDefinition === toRelationDefinition && !fieldPath.isParentRelationship),
                [
                    toRelationDefinition
                ]);

        const relationFieldPathFilter =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    !(fieldPath.relationshipDefinition === toRelationDefinition && fieldPath.isParentRelationship),
                [
                    toRelationDefinition
                ]);

        const showRelationship = relationship && relationship.isNew();

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            {
                !showRelationship &&
                    <ViewGroupItem>
                        Cannot find relationship
                    </ViewGroupItem>
            }
            {
                showRelationship &&
                    <ViewGroupItem>
                        <Constructor
                            entity={relationship}
                            filter={relationshipFieldPathFilter}
                            disableActions
                            autoCommit={props.autoCommit}
                            autoFocus={props.autoFocus}
                            commitContext={props.commitContext}
                        />
                    </ViewGroupItem>
            }
            <ViewGroupItem>
                <Default
                    {...props}
                    autoFocus={false}
                    entity={props.entity}
                    filter={relationFieldPathFilter}
                />
            </ViewGroupItem>
        </ViewGroup>
    };

export default observer(Relation);
