import React, { useMemo } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../Type/Api/useTypes';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import View from '../../../View/Model/View';
import Specification from '../../../View/Model/Specification';
import List from '../../../View/Model/Specification/List';
import Column from '../../../View/Model/Specification/Column';
import uuid from 'uuid';
import Ordering from '../../../View/Model/Ordering';
import { withValue } from '../../../../../../@Util/Functional/withValue';
import getViewParameters from '../../../View/Api/getViewParameters';
import TypeView from '../../../TypeView/TypeView';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../../View/Model/ViewParams';
import { Comparator } from '../../../../DataObject/Model/Comparator';
import EntityValue from '../../../../../../@Api/Automation/Value/EntityValue';
import CompositePredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../DataObject/Model/LogicalOperator';
import PrimitiveValue from '../../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../../DataObject/Model/DataObject';

export interface ProductPriceListProps
{
    product: Entity;
}

const ProductPriceList: React.FC<ProductPriceListProps> =
    ({
        product,
     }) =>
    {
        const types = useTypes();
        const parameters = getViewParameters(types.Price.Type);

        const rootPrice =
            useMemo(
                () =>
                    EntityPath.fromEntityType(types.Price.Type),
                [
                    types,
                ]
            );

        const pathPriceToPriceList =
            useMemo(
                () =>
                    rootPrice
                        .joinTo(
                            types.PriceList.RelationshipDefinition.Prices,
                            true
                        ),
                [
                    types,
                    rootPrice,
                ]
            );

        const pathPriceToProduct =
            useMemo(
                () =>
                    rootPrice
                        .joinTo(
                            types.Price.RelationshipDefinition.Product,
                            false
                        ),
                [
                    types,
                    rootPrice,
                ]
            );

        const filter =
            useMemo(
                () =>
                    new CompositePredicate(
                        LogicalOperator.And, [
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    parameters.getParameterById(ViewParams.Entity),
                                    pathPriceToProduct.field()
                                ),
                                Comparator.Equals,
                                new EntityValue(product)
                            ),
                            // hide priceList which are default for currencies
                            new CompositePredicate(
                                LogicalOperator.Or, [
                                    new ComparisonPredicate(
                                        new ValueFromEntityComputation(
                                            parameters.getParameterById(ViewParams.Entity),
                                            pathPriceToPriceList.field(types.PriceList.Field.IsDefaultForCurrency)
                                        ),
                                        Comparator.IsNotDefined,
                                        undefined
                                    ),
                                    new ComparisonPredicate(
                                        new ValueFromEntityComputation(
                                            parameters.getParameterById(ViewParams.Entity),
                                            pathPriceToPriceList.field(types.PriceList.Field.IsDefaultForCurrency)
                                        ),
                                        Comparator.Equals,
                                        new PrimitiveValue(
                                            DataObject.constructFromTypeIdAndValue(
                                                'Boolean',
                                                false
                                            )
                                        )
                                    )
                                ]
                            )
                        ]
                    ),
                [
                    parameters,
                    pathPriceToProduct,
                    product
                ]
            );

        const view =
            useMemo(
                () =>
                {
                    return new View(
                        'List',
                        types.Price.Type.getName(true),
                        types.Price.Type,
                        parameters,
                        filter,
                        new Specification(
                            withValue([
                                new Column(
                                    uuid(),
                                    pathPriceToPriceList.field(types.PriceList.Field.Name)
                                ),
                                new Column(
                                    uuid(),
                                    rootPrice.field(types.Price.Field.Price)
                                ),
                                new Column(
                                    uuid(),
                                    rootPrice.field(types.Price.Field.StartDate)
                                ),
                                new Column(
                                    uuid(),
                                    rootPrice.field(types.Price.Field.EndDate)
                                ),
                                new Column(
                                    uuid(),
                                    rootPrice.field(types.Price.Field.Quantity)
                                ),
                                new Column(
                                    uuid(),
                                    rootPrice.field(types.Price.Field.DiscountPercentage)
                                ),
                                new Column(
                                    uuid(),
                                    rootPrice.field(types.Price.Field.Currency)
                                ),
                            ],
                                columns =>
                                    new List(
                                        columns,
                                        [
                                            new Ordering(columns[0], true),
                                        ]
                                    )
                            )
                        )
                    )
                },
                [
                    types,
                    parameters,
                    rootPrice,
                    pathPriceToProduct,
                    pathPriceToPriceList
                ]
            );

        return <TypeView
            entityType={rootPrice.entityType}
            view={view}
            hideAddButton
        />;
    };

export default observer(ProductPriceList);
