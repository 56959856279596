import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import FormLayout from '../../../../../@Api/Layout/Type/Form/FormLayout';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import FormContext from './FormContext';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import Layout from '../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';
import FormLayoutContext from '../../../../../@Api/Layout/FormLayoutContext';
import FormLayoutContexts from './FormLayoutContexts';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';

export interface FormLayoutEditorProps extends LayoutEditorProps<FormLayout>
{

}

const FormLayoutEditor: React.FC<FormLayoutEditorProps> =
    props =>
    {
        const setLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.layout = layout),
                [
                    props.layout
                ]);

        const parameterDictionary =
            useComputed(
                () =>
                    ParameterDictionary.union(
                        props.parameterDictionary,
                        props.layout.parameters),
                [
                    props.parameterDictionary,
                    props.layout
                ]);

        const parentFormLayoutContexts = useContext(FormLayoutContexts);
        const formLayoutContexts =
            useMemo(
                () => [
                    ...parentFormLayoutContexts,
                    new FormLayoutContext(
                        props.layout,
                        new ParameterAssignment())
                ],
                [
                    parentFormLayoutContexts,
                    props.layout
                ]);

        return <FormLayoutContexts.Provider
            value={formLayoutContexts}
        >
            <FormContext.Provider
                value={props.layout}
            >
                <ChildLayoutEditor
                    {...props}
                    parameterDictionary={parameterDictionary}
                    childLayout={props.layout.layout}
                    onChange={setLayout}
                />
            </FormContext.Provider>
        </FormLayoutContexts.Provider>;
    };

export default observer(FormLayoutEditor);
