import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { default as EntityAvatar } from '../../../Avatar/Avatar';
import { default as GenericAvatar } from '../../../../../../@Future/Component/Generic/Avatar/Avatar';
import { User } from '../../../../../../@Api/Model/Implementation/User';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';

export interface AvatarProps
{
    entity: Entity;
    user?: User;
    rootEntity?: Entity;
}

const bigAvatarSize = 32;

const Avatar: React.FC<AvatarProps> =
    props =>
    {
        const avatar =
            useMemo(
                () =>
                {
                    const size = bigAvatarSize;

                    if (props.user
                        && props.user.entity)
                    {
                        return <EntityAvatar
                            entity={props.user.entity}
                            size={size}
                        />;
                    }
                    else
                    {
                        return <GenericAvatar
                            icon="person"
                            size={size}
                        />;
                    }
                },
                [
                    props.user
                ]);

        return avatar;
    };

export default observer(Avatar);
