import * as React from 'react';
import { observer } from 'mobx-react';
import { BodyStore } from './BodyStore';
import { BaseComponent } from '../../../../../../@Framework/Component/BaseComponent';
import { Router } from '../../../../../../@Service/Router/Router';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import styles from './Body.module.scss';
import { Search } from '../../../../../Domain/Search/Search';

@observer
export class Body extends BaseComponent<BodyStore>
{
    renderComponent(store: BodyStore)
    {
        return <>
            {
                store.searchStore &&
                    <div
                        className={classNames(styles.search, !store.showSearch && styles.hidden)}
                    >
                        <Search
                            store={store.searchStore}
                        />
                    </div>
            }
            <div
                className={classNames(styles.body, store.showSearch && styles.search)}
            >
                <Router
                    store={store.routerStore}
                />
            </div>
        </>;
    }
}
