import React, { useMemo } from 'react';
import { LogEvent } from '../../LogViewer/Model/LogEvent';
import { format } from 'date-fns';
import { LogEventsViewerLink } from './Link/LogEventsViewerLink';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { TableCell, TableRow } from '@material-ui/core';
import { LogLevelViewer } from '../../LogViewer/LevelViewer/LogLevelViewer';
import Icon from '../../../../@Future/Component/Generic/Icon/Icon';
import { makeStyles } from '@material-ui/styles';

export interface ConnectorLogRowProps
{
    event: LogEvent;
    showDebugMessage: boolean;
    isOldLogEntry: boolean;
}

const useStyles = makeStyles({
        dateCell: {
            whiteSpace: 'nowrap',
            verticalAlign: 'top'
        },
        levelCell: {
            verticalAlign: 'top',
            textAlign: 'center'
        },
        messageCell: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            overflowWrap: 'anywhere',
            verticalAlign: 'top'
        },
        debugMessage: {
            background: '#f9f9f9',
            padding: 0,
            border: '#ddd 1px solid',
            fontSize: 12,
            marginTop: 8
        },
        debugContent: {
            fontFamily: 'monospace',
            padding: 8
        }
    }
);

export const LogEventsRow: React.FC<ConnectorLogRowProps> =
    (
        {
            event,
            showDebugMessage,
            isOldLogEntry
        }
    ) =>
    {
        const classes = useStyles();

        const date =
            useMemo(
                () =>
                    format(
                        new Date(event.date),
                        'dd-MM-yyyy HH:mm:ss'
                    ),
                [
                    event,
                ]
            );

        const formattedMessage = useMemo(
            () =>
            {
                const entityRegex = /<entity id="(.*?)">(.*?)<\/entity>/igs;
                const syncRegex = /^<sync type="(in|out)"(.*)\/>/igs;
                const elements = [];

                if (event.userMessage?.length > 0)
                {
                    let userMessage = event.userMessage;
                    const syncRegexResult = syncRegex.exec(userMessage);
                    if (syncRegexResult != null)
                    {
                        if (syncRegexResult[1] === 'in')
                        {
                            elements.push(<Icon icon="keyboard_double_arrow_left" color="#ccc" size={22}/>);
                        }
                        else if (syncRegexResult[1] === 'out')
                        {
                            elements.push(<Icon icon="keyboard_double_arrow_right" color="#ccc" size={22}/>);
                        }
                        userMessage = userMessage.substr(syncRegexResult[0].length);
                    }

                    let regexResult;
                    let remaining = userMessage;
                    while ((regexResult = entityRegex.exec(userMessage)) != null)
                    {
                        const startPos = remaining.indexOf(regexResult[0]);

                        if (startPos > 0)
                        {
                            elements.push(
                                <span>{remaining.substr(0, startPos)}</span>
                            );

                            elements.push(
                                <LogEventsViewerLink
                                    uuid={regexResult[1]}
                                    name={regexResult[2]}
                                />
                            );

                            remaining =
                                remaining.substr(
                                    startPos + regexResult[0].length
                                );
                        }
                    }

                    elements.push(
                        <span>{remaining}</span>
                    );
                }
                return elements;
            },
            [
                event
            ]
        );

        return <TableRow>
            <TableCell
                className={classes.dateCell}
            >
                {date}
            </TableCell>
            <TableCell
                className={classes.levelCell}
            >
                <LogLevelViewer
                    level={event.level}
                />
            </TableCell>
            <TableCell
                width="70%"
                className={classes.messageCell}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem
                        key={event.id}
                    >
                        {
                            isOldLogEntry &&
                            <span>{event.message}</span>
                        }
                        {
                            !isOldLogEntry &&
                            <span
                                style={{
                                    fontWeight: showDebugMessage ? 'bold' : undefined
                                }}
                            >
                                {
                                    formattedMessage
                                }
                            </span>
                        }
                    </ViewGroupItem>
                    {
                        showDebugMessage && event.message &&
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="vertical"
                                spacing={0}
                                className={classes.debugMessage}
                            >
                                <ViewGroupItem
                                    className={classes.debugContent}
                                >
                                    {event.message}
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                    }
                </ViewGroup>
            </TableCell>
        </TableRow>
    };