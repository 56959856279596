import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '../../../../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import useTypes from '../../../../../../Type/Api/useTypes';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';

export interface HeaderProps
{
    lines: Entity[];
    onSelectProductLine?: (entity: Entity, selected: boolean) => void;
    onSelectAllProductLines?: (lines: Entity[], selected: boolean) => void;
    selectedProductLines?: Set<Entity>;
    hidePrices: boolean,
    showMilestone: boolean,
    currency: string
}

export const DesktopHeader: React.FC<HeaderProps> = observer(
    ({
         onSelectProductLine,
         onSelectAllProductLines,
         lines,
         selectedProductLines,
         hidePrices,
         showMilestone,
         currency
     }) =>
    {
        const types = useTypes();

        const selectAllProductLines =
            useCallback(
                (selected: boolean) =>
                    onSelectAllProductLines(lines, selected),
                [
                    lines,
                    onSelectAllProductLines
                ]);

        const isAllSelected =
            useMemo(
                () =>
                {
                    if (selectedProductLines)
                    {
                        return lines.every(line => selectedProductLines.has(line));
                    }
                    else
                    {
                        return false;
                    }
                },
                [
                    selectedProductLines,
                    lines
                ]);

        return <TableHead>
            <TableRow>
                <TableCell
                    {...{ width: '1%' }}
                >

                </TableCell>
                {
                    onSelectProductLine &&
                    <TableCell
                        {...{ width: '5%' }}
                    >
                        <Checkbox
                            onToggle={selectAllProductLines}
                            checked={isAllSelected}
                        />
                    </TableCell>
                }
                <TableCell
                    {...{ width: showMilestone ? '35%' : '40%' }}
                >
                    {types.ProductLine.RelationshipDefinition.Product.getName(false)}
                </TableCell>
                <TableCell
                    {...{ width: '10%' }}
                    align="right"
                >
                    {types.ProductLine.Field.Quantity.getName()}
                </TableCell>
                <TableCell
                    {...{width: '10%'}}
                    align="right"
                >
                    {
                        hidePrices
                            ? ''
                            : currency
                                ? types.ProductLine.Field.PriceInCurrency.getName()
                                : types.ProductLine.Field.Price.getName()
                    }
                </TableCell>
                <TableCell
                    {...{width: '10%'}}
                    align="right"
                >
                    {hidePrices ? '' : types.ProductLine.Field.PurchasePrice.getName()}
                </TableCell>
                <TableCell
                    {...{ width: '10%' }}
                    align="right"
                >
                    {hidePrices ? '' : types.ProductLine.RelationshipDefinition.VatGroup.getName(false)}
                </TableCell>
                <TableCell
                    {...{ width: '10%' }}
                    align="right"
                >
                    {
                        !hidePrices &&
                        <LocalizedText
                            code="Generic.Discount"
                            value="Korting"
                        />
                    }
                </TableCell>
                <TableCell
                    {...{ width: '10%' }}
                    align="right"
                >
                    {
                        !hidePrices &&
                        <LocalizedText
                            code="Generic.Total"
                            value="Totaal"
                        />
                    }
                </TableCell>
                {
                    showMilestone &&
                    <TableCell
                        {...{ width: '25%' }}
                    >
                        <LocalizedText
                            code="Generic.Milestone"
                            value="Milestone"
                        />
                    </TableCell>
                }
                <TableCell
                    {...{ width: '4%' }}
                >

                </TableCell>
            </TableRow>
        </TableHead>
    }
);