import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import useTypes from '../Type/Api/useTypes';
import { getModel, isTransactionalModel } from '../../../../@Util/TransactionalModelV2/index';
import EntityTypeContext from '../Type/EntityTypeContext';
import getInstantiableTypes from './Api/getInstantiableTypes';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { default as GenericInput } from '../../../../@Future/Component/Generic/Input/Input/Input';
import StaticSelectbox from '../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import Content from './Type/Content';
import CancelButton from '../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import PrimaryButton from '../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import { openEntity } from '../@Util/openEntity';
import LabelButton from '../../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import { dangerColor, primaryColor } from '../../../../@Resource/Theme/Theme';
import { consoleLog } from '../../../../@Future/Util/Logging/consoleLog';
import { EntityFieldPath } from '../Path/@Model/EntityFieldPath';
import RightAlignedButtonGroup from '../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import InputGroup from '../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import initializeDefaultRelationships from './Api/initializeDefaultRelationships';
import DeleteButton from '../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';
import deleteEntity from '../../../../@Api/Entity/deleteEntity';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import { UnderlineMode } from '../Input/UnderlineMode';
import { validateAndCommitEntityOrShowError } from '../../../../@Api/Entity/Commit/validateAndCommitEntityOrShowError';
import { useNewCommitContext } from '../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import sendAnalyticsLogging, { EventTypes } from '../../../../@Util/Analytics/sendAnalyticsLogging';

export interface ConstructorProps
{
    entityType?: EntityType;
    entity?: Entity;
    onClose?: () => void;
    onSave?: (entity: Entity) => void;
    openable?: boolean;
    onOpen?: (entity: Entity) => void;
    onDelete?: (entity: Entity) => void;
    touched?: boolean;
    filter?: (fieldPath: EntityFieldPath) => boolean;
    disableActions?: boolean;
    autoCommit?: boolean;
    autoFocus?: boolean;
    hideCloseButton?: boolean;
    underline?: UnderlineMode;
    fromFieldPath?: EntityFieldPath;
    required?: (fieldPath: EntityFieldPath) => boolean;
    disabled?: (fieldPath: EntityFieldPath) => boolean;
    commitContext?: CommitContext;
}

const Constructor: React.FC<ConstructorProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);

        const [ baseEntityType, setBaseEntityType ] = useState<EntityType>(props.entityType || props.entity.entityType);

        useEffect(
            () =>
            {
                setBaseEntityType(props.entityType || props.entity.entityType);
            },
            [
                props.entityType,
                props.entity
            ]);

        const _commitContext =
            useNewCommitContext(
                props.commitContext,
                {
                    allowAutoCommit: props.autoCommit ?? false,
                },
                [
                    props.autoCommit,
                ]
            );
        const commitContext = isTransactionalModel(props.entity) ? undefined : _commitContext;
        const entity =
            useMemo(
                () =>
                {
                    if (props.entity)
                    {
                        return props.entity;
                    }
                    else
                    {
                        return commitContext.createEntity(baseEntityType);

                        // const entity =
                        //     createTransactionalModel(
                        //         new Entity(baseEntityType)
                        //             .initialize(entityTypeStore));
                        //
                        // return entity;
                    }
                },
                [
                    props.entity,
                    baseEntityType,
                    commitContext,
                ]);

        const selectType =
            useCallback(
                (entityType: EntityType) =>
                {
                    entity.setEntityType(
                        entityType,
                        entityTypeStore,
                        commitContext
                    );
                    entity.initialize(entityTypeStore);
                },
                [
                    entity,
                    entityTypeStore,
                    commitContext,
                ]);

        const instantiableTypes =
            useComputed(
                () =>
                    getInstantiableTypes(baseEntityType, entity),
                [
                    baseEntityType,
                    entity
                ]);

        const instantiableTypeOptions =
            useMemo(
                () =>
                    instantiableTypes.map(
                        type => ({
                            id: type.id.toString(),
                            label: type.getName(),
                            value: type
                        })),
                [
                    instantiableTypes
                ]);

        const hasInstantiableTypeSelector =
            useComputed(
                () =>
                    (instantiableTypes.length > 1),
                        // (instantiableTypes.length === 1
                        //     && !entityType.isA(instantiableTypes[0])
                        //     // In case of Relationship.Person.Contact and Relationship.Person.Contact.Standard
                        //     && entityType.nameSingular !== instantiableTypes[0].nameSingular)),
                [
                    instantiableTypes,
                    baseEntityType
                ]);

        useEffect(
            () =>
            {
                if (entity.entityType.isInstantiableByInheritance())
                {
                    selectType(entity.entityType);
                }
                else if (instantiableTypes.length > 0)
                {
                    selectType(instantiableTypes[0]);
                }
            },
            [
                entity,
                selectType,
                instantiableTypes
            ]);

        const [ _isTouched, setTouched ] = useState(false);
        const isTouched =
            useMemo(
                () =>
                    _isTouched || props.touched,
                [
                    _isTouched,
                    props.touched
                ]);

        const isOpenable =
            useComputed(
                () =>
                    props.openable &&
                        entity.entityType.bespoke.isOpenable(entity),
                [
                    props.openable,
                    entity
                ]);

        const save =
            useCallback(
                async () =>
                {
                    setTouched(true);
                    const [ isCommitted, validationResult ] =
                        await validateAndCommitEntityOrShowError(
                            entity,
                            {
                                isForced: true,
                                context: commitContext,
                            });

                    if (isCommitted)
                    {
                        sendAnalyticsLogging(EventTypes.StartCreateRecord, null, entity)

                        if (props.onSave)
                        {
                            props.onSave(getModel(entity));
                        }

                        if (props.onClose)
                        {
                            props.onClose();
                        }
                    }
                    else
                    {
                        consoleLog('cannot save entity, entity is invalid', entity, validationResult, commitContext);
                    }
                },
                [
                    setTouched,
                    entity,
                    props.onSave,
                    props.onClose,
                    commitContext,
                ]);

        const saveAndOpen =
            useCallback(
                () =>
                {
                    return save()
                        .then(
                            () =>
                            {
                                if (!entity.isNew())
                                {
                                    if (props.onOpen)
                                    {
                                        return props.onOpen(entity);
                                    }
                                    else
                                    {
                                        return openEntity(entity);
                                    }
                                }
                            });
                },
                [
                    save,
                    props.onOpen,
                    entity
                ]);

        const doDelete =
            useCallback(
                () =>
                    deleteEntity(entity)
                        .then(
                            () =>
                                props.onDelete(entity))
                        .then(
                            () =>
                            {
                                if (props.onClose)
                                {
                                    props.onClose();
                                }
                            }),
                [
                    entity,
                    props.onDelete,
                    props.onClose
                ]);

        useEffect(
            () =>
            {
                initializeDefaultRelationships(
                    entity,
                    commitContext
                )
                .then();
            },
            [
                entity,
                commitContext,
            ]);

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <InputGroup>
                    {
                        hasInstantiableTypeSelector &&
                            <GenericInput
                                label={
                                    <span>
                                        <LocalizedText
                                            code="Generic.Type"
                                            value="Type"
                                        />*
                                    </span>
                                }
                                labelPosition="left"
                            >
                                <StaticSelectbox
                                    options={instantiableTypeOptions}
                                    onChange={selectType}
                                    value={entity.entityType}
                                    autoFocus
                                    open
                                />
                            </GenericInput>
                    }
                    <Content
                        {...props}
                        entity={entity}
                        touched={isTouched}
                        autoFocus={props.autoFocus && !hasInstantiableTypeSelector}
                        commitContext={commitContext}
                    />
                </InputGroup>
            </ViewGroupItem>
            {
                !entity.entityType.isA(types.Note.Type)
                    && !entity.entityType.isA(types.Attachment.Type)
                    && !entity.entityType.isA(types.Activity.Email.Type)
                    && !entity.entityType.isA(types.Relationship.Organization.Partner.Type)
                    && !props.disableActions
                    &&
                    <ViewGroupItem>
                        <RightAlignedButtonGroup>
                            {
                                !props.hideCloseButton && props.onClose &&
                                    <CancelButton
                                        key="Close"
                                        onClick={props.onClose}
                                    />
                            }
                            {
                                props.onDelete &&
                                    <DeleteButton
                                        onClick={doDelete}
                                        filled={false}
                                        hoverColor={dangerColor}
                                    />
                            }
                            <LabelButton
                                key="Save"
                                label={
                                    <LocalizedText
                                        code="Generic.Save"
                                        value="Opslaan"
                                    />
                                }
                                onClick={save}
                                filled={!isOpenable}
                                color={primaryColor}
                                hoverColor={primaryColor}
                            />
                            {
                                isOpenable &&
                                    <PrimaryButton
                                        key="SaveAndOpen"
                                        label={
                                            <LocalizedText
                                                code="Generic.SaveAndOpen"
                                                value="Opslaan en openen"
                                            />
                                        }
                                        filled
                                        onClick={saveAndOpen}
                                    />
                            }
                        </RightAlignedButtonGroup>
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

Constructor.defaultProps = {
    openable: true,
    filter: () => true,
    autoCommit: false,
    required: () => false,
    disabled: () => false
};

export default observer(Constructor);
