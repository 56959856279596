import Dataset from '../Model/Dataset';
import { useEffect } from 'react';
import useTypes from '../../Type/Api/useTypes';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { DateRangeValue } from '../../../DataObject/Type/Date/DateRange/DateRangeValue';
import { DateIntervalValue } from '../../../DataObject/Type/Date/DateInterval/DateIntervalValue';
import { runInAction } from 'mobx';
import { EntityPath } from '../../Path/@Model/EntityPath';
import uuid from '../../../../../@Util/Id/uuid';
import Segment from '../Model/Segment';
import { default as ChartModel } from '../../Chart/Model/Chart';
import DataSeries from '../../Chart/Model/DataSeries';
import AggregateValue from '../../View/Model/Column/AggregateValue';
import { Aggregate } from '../../../DataObject/Model/Aggregate';
import ComputedSeries from '../../Chart/Model/ComputedSeries';
import ComputedSeriesComponent from '../../Chart/Model/ComputedSeriesComponent';
import { MathematicalOperator } from '../../../DataObject/Model/MathematicalOperator';
import { Comparator } from '../../../DataObject/Model/Comparator';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';

export default function useAlteredDataset(dataset: Dataset)
{
    const types = useTypes();

    useEffect(
        () =>
        {
            const defaultDateRange =
                DataObject.constructFromTypeIdAndValue(
                    'DateRange',
                    {
                        type: 'CurrentYear',
                        // from: moment().startOf('year').endOf('month').toDate(),
                        // to: moment().startOf('year').add(1, 'year').toDate()
                    } as DateRangeValue);

            const defaultDateInterval =
                DataObject.constructFromTypeIdAndValue(
                    'DateInterval',
                    {
                        type: 'Monthly'
                    } as DateIntervalValue);

            if (dataset.entity?.name === 'Forecast'
                || dataset.entity?.name === 'Forecast (gewogen)')
            {
                const aggregateField =
                    dataset.entity?.name === 'Forecast (gewogen)'
                        ?
                            types.Activity.SalesOpportunity.Field.WeightedAmount
                                :
                            types.Activity.Field.Amount;

                runInAction(
                    () =>
                    {
                        const rootPath = EntityPath.fromEntityType(types.Activity.SalesOpportunity.Type);
                        const forcecastSeriesId = uuid();
                        const wonSeriesId = uuid();

                        dataset.segments = [
                            new Segment(
                                uuid(),
                                'Chart',
                                undefined,
                                undefined,
                                undefined,
                                new ChartModel(
                                    types.Activity.SalesOpportunity.Type,
                                    dataset.parameters,
                                    [
                                        new DataSeries(
                                            forcecastSeriesId,
                                            'Bar',
                                            'Niet gewonnen',
                                            false,
                                            true,
                                            rootPath.field(types.Activity.SalesOpportunity.Field.ExpectedOrderDate),
                                            new AggregateValue(
                                                rootPath.field(aggregateField),
                                                Aggregate.Sum),
                                            rootPath
                                                .joinTo(
                                                    types.Activity.SalesOpportunity.RelationshipDefinition.Phase,
                                                    false)
                                                .field(),
                                            undefined,
                                            new ComparisonPredicate(
                                                new ValueFromEntityComputation(
                                                    dataset.parameter,
                                                    rootPath
                                                        .joinTo(
                                                            types.Activity.SalesOpportunity.RelationshipDefinition.Phase,
                                                            false)
                                                        .field(types.Datastore.Phase.Field.IsTerminal)),
                                                Comparator.Equals,
                                                new PrimitiveValue(
                                                    DataObject.constructFromTypeIdAndValue(
                                                        'Boolean',
                                                        false)))),
                                        new DataSeries(
                                            wonSeriesId,
                                            'Bar',
                                            'Gewonnen',
                                            false,
                                            true,
                                            rootPath.field(types.Entity.Field.CloseDate),
                                            new AggregateValue(
                                                rootPath.field(aggregateField),
                                                Aggregate.Sum),
                                            rootPath
                                                .joinTo(
                                                    types.Activity.SalesOpportunity.RelationshipDefinition.Phase,
                                                    false)
                                                .field(),
                                            undefined,
                                            new ComparisonPredicate(
                                                new ValueFromEntityComputation(
                                                    dataset.parameter,
                                                    rootPath
                                                        .joinTo(
                                                            types.Activity.SalesOpportunity.RelationshipDefinition.Phase,
                                                            false)
                                                        .field(types.Datastore.Field.Code)),
                                                Comparator.Equals,
                                                new PrimitiveValue(
                                                    DataObject.constructFromTypeIdAndValue(
                                                        'Text',
                                                        'Won')))),
                                        new ComputedSeries(
                                            uuid(),
                                            'Bar',
                                            'Forecast',
                                            false,
                                            false,
                                            [
                                                new ComputedSeriesComponent(
                                                    uuid(),
                                                    MathematicalOperator.Add,
                                                    forcecastSeriesId),
                                                new ComputedSeriesComponent(
                                                    uuid(),
                                                    MathematicalOperator.Add,
                                                    wonSeriesId)
                                            ])
                                    ],
                                    undefined,
                                    defaultDateRange,
                                    defaultDateInterval))
                        ];
                    });
            }
            else if (dataset.entity?.name === 'Abonnementenstand')
            {
                runInAction(
                    () =>
                    {
                        const rootPath = EntityPath.fromEntityType(types.Activity.Subscription.Type);
                        const startSeriesId = uuid();
                        const endSeriesId = uuid();

                        dataset.segments = [
                            new Segment(
                                uuid(),
                                'Chart',
                                undefined,
                                undefined,
                                undefined,
                                new ChartModel(
                                    types.Activity.Subscription.Type,
                                    dataset.parameters,
                                    [
                                        new DataSeries(
                                            startSeriesId,
                                            'Bar',
                                            'Bedrag (+)',
                                            true,
                                            true,
                                            rootPath.field(types.Activity.Subscription.Field.StartDate),
                                            new AggregateValue(
                                                rootPath.field(types.Activity.Field.TotalRecurringSalesExcludingVat),
                                                Aggregate.Sum),
                                            undefined,
                                            rootPath.field(types.Activity.Subscription.Field.Interval)),
                                        new DataSeries(
                                            endSeriesId,
                                            'Bar',
                                            'Bedrag (-)',
                                            true,
                                            true,
                                            rootPath.field(types.Activity.Subscription.Field.EndDate),
                                            new AggregateValue(
                                                rootPath.field(types.Activity.Field.TotalRecurringSalesExcludingVat),
                                                Aggregate.Sum),
                                            undefined,
                                            rootPath.field(types.Activity.Subscription.Field.Interval)),
                                        new ComputedSeries(
                                            uuid(),
                                            'Bar',
                                            'Abonnementenstand',
                                            false,
                                            false,
                                            [
                                                new ComputedSeriesComponent(
                                                    uuid(),
                                                    MathematicalOperator.Add,
                                                    startSeriesId),
                                                new ComputedSeriesComponent(
                                                    uuid(),
                                                    MathematicalOperator.Subtract,
                                                    endSeriesId,
                                                    -1)
                                            ])
                                    ],
                                    dataset.filter,
                                    defaultDateRange,
                                    defaultDateInterval))
                        ];
                    });
            }
            else if (dataset.entity?.name === 'Gefactureerd')
            {
                runInAction(
                    () =>
                    {
                        const rootPath = EntityPath.fromEntityType(types.Activity.Invoice.Type);

                        dataset.segments = [
                            new Segment(
                                uuid(),
                                'Chart',
                                undefined,
                                undefined,
                                undefined,
                                new ChartModel(
                                    types.Activity.Invoice.Type,
                                    dataset.parameters,
                                    [
                                        new DataSeries(
                                            uuid(),
                                            'Bar',
                                            'Gefactureerd',
                                            false,
                                            false,
                                            rootPath.field(types.Activity.Invoice.Field.Date),
                                            new AggregateValue(
                                                rootPath.field(types.Activity.Field.Amount),
                                                Aggregate.Sum))
                                    ],
                                    dataset.filter,
                                    defaultDateRange,
                                    defaultDateInterval))
                        ];
                    });
            }
            else if (dataset.entity?.name === 'Abonnementen churn')
            {
                runInAction(
                    () =>
                    {
                        const rootPath = EntityPath.fromEntityType(types.Activity.Subscription.Type);
                        const cumulativePlusSeriesId = uuid();
                        const cumulativeMinusSeriesId = uuid();
                        const minusSeriesId = uuid();
                        const subscriptionStance = uuid();

                        dataset.segments = [
                            new Segment(
                                uuid(),
                                'Chart',
                                undefined,
                                undefined,
                                undefined,
                                new ChartModel(
                                    types.Activity.Subscription.Type,
                                    dataset.parameters,
                                    [
                                        new DataSeries(
                                            cumulativePlusSeriesId,
                                            'Bar',
                                            'Totaal bijgekomen abonnementen',
                                            true,
                                            true,
                                            rootPath.field(types.Activity.Subscription.Field.StartDate),
                                            new AggregateValue(
                                                rootPath.field(types.Entity.Field.Id),
                                                Aggregate.Count),
                                            undefined,
                                            rootPath.field(types.Activity.Subscription.Field.Interval)),
                                        new DataSeries(
                                            cumulativeMinusSeriesId,
                                            'Bar',
                                            'Totaal gestopte abonnementen',
                                            true,
                                            true,
                                            rootPath.field(types.Activity.Subscription.Field.EndDate),
                                            new AggregateValue(
                                                rootPath.field(types.Entity.Field.Id),
                                                Aggregate.Count),
                                            undefined,
                                            rootPath.field(types.Activity.Subscription.Field.Interval)),
                                        new DataSeries(
                                            minusSeriesId,
                                            'Bar',
                                            'Gestopte abonnementen',
                                            false,
                                            true,
                                            rootPath.field(types.Activity.Subscription.Field.EndDate),
                                            new AggregateValue(
                                                rootPath.field(types.Entity.Field.Id),
                                                Aggregate.Count),
                                            undefined,
                                            rootPath.field(types.Activity.Subscription.Field.Interval)),
                                        new ComputedSeries(
                                            subscriptionStance,
                                            'Bar',
                                            'Aantal actieve abonnementen',
                                            false,
                                            true,
                                            [
                                                new ComputedSeriesComponent(
                                                    uuid(),
                                                    MathematicalOperator.Add,
                                                    cumulativePlusSeriesId),
                                                new ComputedSeriesComponent(
                                                    uuid(),
                                                    MathematicalOperator.Subtract,
                                                    cumulativeMinusSeriesId)
                                            ]),
                                        new ComputedSeries(
                                            uuid(),
                                            'Bar',
                                            'Churn (%)',
                                            false,
                                            false,
                                            [
                                                new ComputedSeriesComponent(
                                                    uuid(),
                                                    MathematicalOperator.Add,
                                                    minusSeriesId),
                                                new ComputedSeriesComponent(
                                                    uuid(),
                                                    MathematicalOperator.Divide,
                                                    subscriptionStance,
                                                    -1),
                                                new ComputedSeriesComponent(
                                                    uuid(),
                                                    MathematicalOperator.Multiply,
                                                    undefined,
                                                    undefined,
                                                    DataObject.constructFromTypeIdAndValue(
                                                        'Number',
                                                        100))
                                            ])
                                    ],
                                    dataset.filter,
                                    defaultDateRange,
                                    defaultDateInterval))
                        ];
                    });
            }
            else
            {
                dataset.segments
                    .filter(
                        segment =>
                            segment.chart != null
                                && (segment.type === 'Line'
                                || segment.type === 'Tile')
                    )
                    .forEach(segment => segment.chart = undefined);

                dataset.segments
                    .filter(
                        segment =>
                            (segment.type === 'Bar' || segment.type === 'Line')
                                && !segment.chart
                                && (segment.groupFieldPath.field?.dataObjectSpecification.type.id() === 'Date'
                                || segment.groupFieldPath.field?.dataObjectSpecification.type.id() === 'DateTime')
                    )
                    .forEach(
                        segment =>
                        {
                            runInAction(
                                () =>
                                {
                                    let aggregateValues = segment.aggregateValues;

                                    if (segment.aggregateValues.length === 0)
                                    {
                                        aggregateValues = [
                                            new AggregateValue(
                                                segment.groupFieldPath,
                                                Aggregate.Count)
                                        ];
                                    }

                                    segment.chart =
                                        new ChartModel(
                                            dataset.entityType,
                                            dataset.parameters,
                                            aggregateValues.map(
                                                aggregateValue =>
                                                    new DataSeries(
                                                        uuid(),
                                                        segment.type === 'Bar'
                                                            ? 'Bar'
                                                            : 'Line',
                                                        dataset.entity?.name,
                                                        false,
                                                        false,
                                                        segment.groupFieldPath,
                                                        aggregateValue,
                                                        dataset.entity?.name === 'Forecast'
                                                            ? EntityPath.root(dataset.entityType)
                                                                    .joinTo(
                                                                        types.Activity.SalesOpportunity.RelationshipDefinition.Phase,
                                                                        false)
                                                                    .field()
                                                            : segment.secondaryGroupFieldPath
                                                    )
                                            ),
                                            dataset.filter,
                                            defaultDateRange,
                                            defaultDateInterval);
                                });
                        });
            }
        },
        [
            dataset,
            types
        ]);
}
