import React from 'react';
import CircularPercentageChart from '../../../../../../../../../../../../../../@Future/Component/Generic/Chart/CircularPercentageChart';

export interface NumberChartProps
{
    value: number;
    label?: string;
    size: number;
    color?: string;
}

export const NumberChart: React.FC<NumberChartProps> =
    (
        {
            value,
            label,
            size,
            color
        }
    ) =>
    {
        return <CircularPercentageChart
            size={size}
            value={value > 0 ? 100 : 0}
            color={color}
            label={() => `${value}x`}
            subLabel={() => label}
        />;
    };