import React, { useCallback, useContext, useEffect, useState } from 'react';
import RichtextEditorContext from '../RichtextEditorContext/RichtextEditorContext';
import Quill from 'quill';
import LabelButton from '../../../Button/Variant/Label/LabelButton';
import Popper from '../../../Popper/Popper';
import Card from '../../../Card/Card';
import Menu from '../../../Menu/Menu';
import Item from '../../../Menu/Item/Item';
import { primaryColor } from '../../../../../../@Resource/Theme/Theme';
import ToolbarButtonWrapper from './ToolbarButtonWrapper';
import localizeText from '../../../../../../@Api/Localization/localizeText';

const FontSizes =
[
    { key: 'font.small', value: 'small', name: () => localizeText('FontSize.Small', 'Klein') },
    { key: 'font.normal', value: false, name: () => localizeText('FontSize.Normal', 'Normaal') },
    { key: 'font.large', value: 'large', name: () => localizeText('FontSize.Large', 'Groot') },
    { key: 'font.huge', value: 'huge', name: () => localizeText('FontSize.Huge', 'Extra groot') }
];

export interface EditorFontSelectorProps
{
}

const EditorFontSelector: React.FC<EditorFontSelectorProps> =
    props =>
    {
        const context = useContext(RichtextEditorContext);
        const [buttonLabel, setButtonLabel] = useState(FontSizes.find(item => item.key === 'font.normal').name());
        const [isOpen, setOpen] = useState(false);

        const toggle =
            useCallback(
                () =>
                    setOpen(!isOpen),
                [
                    setOpen,
                    isOpen
                ]);

        const setFormat =
            useCallback(
                (name: string, value: any) =>
                {
                    if (context.updateFormat)
                    {
                        context.updateFormat("size", value);
                    }
                },
                [
                    context
                ]);

        useEffect(
            () =>
            {
                if (context.editor)
                {
                    // Hook to editor change to check if button needs 'active' update
                    context.editor.on(
                        (Quill as any).events.EDITOR_CHANGE,
                        (type: any, range: any) =>
                        {
                            if (type === (Quill as any).events.SELECTION_CHANGE)
                            {
                                const formats = range == null ? {} : context.editor.getFormat(range);
                                if (formats["size"])
                                {
                                    setButtonLabel(
                                        FontSizes.find(size => size.value === formats["size"]).name);
                                }
                                else
                                {
                                    setButtonLabel(
                                        FontSizes.find(size => size.value === false).name);
                                }
                            }
                        });
                }
            },
            [
                context.editor
            ]);

        return context.allowTextStyling &&
            <ToolbarButtonWrapper>
                <Popper
                    reference={
                        <LabelButton
                            label={buttonLabel}
                            color={primaryColor}
                            hoverColor={primaryColor}
                            onClick={toggle}
                        />
                    }
                    popper={
                        <Card
                            shadow
                        >
                            <Menu>
                                {
                                    FontSizes.map(
                                        size =>
                                            <Item
                                                key={size.key}
                                                name={size.name()}
                                                onClick={
                                                    () =>
                                                    {
                                                        setFormat(size.name(), size.value);
                                                        setOpen(false);
                                                    }}
                                            />)
                                }
                            </Menu>
                        </Card>
                    }
                    open={isOpen}
                    onClose={() => setOpen(false)}
                />
            </ToolbarButtonWrapper>;
    };

EditorFontSelector.defaultProps =
{

};

export default EditorFontSelector;
