import { EntityType } from '../../../../../Model/Implementation/EntityType';
import { Entity } from '../../../../../Model/Implementation/Entity';
import { CommitContext } from '../../CommitContext';
import { createTransactionalModel } from '../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { CommitMutationOptions } from '../../Model/CommitMutationOptions';

export function constructEntityOfType(
    entityType: EntityType,
    commitContext?: CommitContext,
    options?: CommitMutationOptions
): Entity
{
    if (commitContext)
    {
        return commitContext.createEntity(
            entityType,
            options
        );
    }
    else
    {
        return createTransactionalModel(
            new Entity(entityType)
                .initialize(
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    !options?.disableLocalAutomations
                )
        );
    }
}
