import { observable } from 'mobx';
import { commit, createTransactionalModel, getModel, isDirty, isTransactionalModel, TransactionalModel } from '..';
import TransactionalConfiguration from '../Shared/TransactionalConfiguration';
import { isContextIncluded, merge } from '../Shared/TransactionUtils';
import TransactionalContext from '../Shared/TransactionalContext';

/**
 * <T> type that is to be mutated
 * <R> pre commit result
 * <S> result from save API
 * <D> result from delete API
 */
export default abstract class TransactionalApi<T, R = void, S = T, D = any>
{
    // ------------------------- Properties -------------------------

    @observable configuration: TransactionalConfiguration;

    // ------------------------ Constructor -------------------------

    constructor(configuration: TransactionalConfiguration = new TransactionalConfiguration())
    {
        this.configuration = configuration;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    public createTransactional(model: T,
                               context?: TransactionalContext<T>): TransactionalModel<T>
    {
        return createTransactionalModel(
            model,
            this.configuration,
            context);
    }

    public commit(transactional: TransactionalModel<T>,
                  skipDirtyCheck: boolean = false): Promise<TransactionalModel<T>>
    {
        if (this.isValid(transactional))
        {
            if (skipDirtyCheck || this.isDirty(transactional))
            {
                return this.saveInApi(transactional)
                    .then(
                        model =>
                            this.postCommit(
                                transactional,
                                model))
                    .catch(reason => this.onError(reason));
            }
            else
            {
                return Promise.resolve(transactional);
            }
        }
        else
        {
            return Promise.reject('Attempting to commit invalid model.');
        }
    }

    public postCommit(transactional: TransactionalModel<T>,
                      resultFromApi: S): Promise<TransactionalModel<T>>
    {
        // It might be that an undefined model is returned because there are no changes,
        // see EntityApi.saveInApi - in that case, skip all post-save steps
        if (resultFromApi === undefined)
        {
            return Promise.resolve(transactional);
        }
        else
        {
            return this.preCommit(transactional, resultFromApi)
                .then(
                    preSaveResult =>
                        commit(transactional)
                            .then(
                                () =>
                                    this.postSave(
                                        transactional,
                                        resultFromApi,
                                        preSaveResult))
                            .then(() =>
                                Promise.resolve(transactional)));
        }
    }

    public initializeAndMergeModel(transactional: TransactionalModel<T>,
                                   model: T)
    {
        if (model === undefined)
        {
            return Promise.resolve();
        }
        else
        {
            return this.initialize(model)
                .then(() => Promise.resolve(model))
                .then(model =>
                    this.merge(
                        transactional,
                        model));
        }
    }

    public delete(transactional: TransactionalModel<T>): Promise<D>
    {
        return this.deleteInApi(transactional)
            .then(
                resultFromApi =>
                    this.preDeleteCommit(transactional, resultFromApi)
                        .then(
                            preDeleteResult =>
                                (isTransactionalModel(transactional)
                                    ?
                                    commit(transactional) as Promise<any>
                                    :
                                    Promise.resolve())
                                    .then(
                                        () =>
                                            this.postDelete(
                                                transactional,
                                                resultFromApi,
                                                preDeleteResult))))
            .catch(reason => this.onError(reason));
    }

    // ----------------------- Private logic ------------------------

    protected abstract initialize(model: T): Promise<any>;

    protected isValid(transactional: TransactionalModel<T>): boolean
    {
        return true;
    }

    protected isDirty(transactional: TransactionalModel<T>): boolean
    {
        return isDirty(transactional);
    }

    protected merge(transactional: TransactionalModel<T>,
                    source: T): Promise<any>
    {
        const context = new TransactionalContext(getModel(transactional));

        merge(
            getModel(transactional),
            source,
            (property, value) =>
                isContextIncluded(
                    context.joinTo(
                        property,
                        value)));

        return Promise.resolve();
    }

    protected preCommit(transactional: TransactionalModel<T>,
                        resultFromApi: S): Promise<R>
    {
        return Promise.resolve<any>(undefined);
    }

    protected postSave(transactional: TransactionalModel<T>,
                       resultFromApi: S,
                       result: R): Promise<any>
    {
        return Promise.resolve();
    }

    protected preDeleteCommit(transactional: TransactionalModel<T>,
                              resultFromApi: D): Promise<R>
    {
        return Promise.resolve<any>(undefined);
    }

    protected postDelete(transactional: TransactionalModel<T>,
                         resultFromApi: D,
                         preDeleteResult: R): Promise<any>
    {
        return Promise.resolve();
    }

    protected onError(reason: any)
    {
        return Promise.reject(reason);
    }

    protected abstract saveInApi(transactional: TransactionalModel<T>): Promise<S>;

    protected abstract deleteInApi(transactional: TransactionalModel<T>): Promise<D>;
}
