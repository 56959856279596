import Addressee from '../Model/Addressee';
import isEmailAddressValid from '../../../../../../../../@Util/EmailAddress/isEmailAddressValid';
import capitalize from 'lodash-es/capitalize';

const nonPersonalEmailAddresses = new Set([
    'info',
    'support',
    'noreply',
    'contact',
    'billing',
    'facturen',
    'help'
]);

export default function getPersonNameFromAddressee(addressee: Addressee): string
{
    if (addressee.name && !isEmailAddressValid(addressee.name))
    {
        return addressee.name;
    }
    else
    {
        const name = addressee.email.split('@')[0].split('.').map(component => capitalize(component)).join(' ');

        if (nonPersonalEmailAddresses.has(name))
        {
            return undefined;
        }
        else
        {
            return name;
        }
    }
}
