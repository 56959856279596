import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationBox from '../../Box/ComputationBox';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import SingletonCollectionComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/SingletonCollectionComputation';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface SingletonCollectionComputationEditorProps extends ComputationEditorProps<ValueType<any>, SingletonCollectionComputation>
{

}

const SingletonCollectionComputationEditor: React.FC<SingletonCollectionComputationEditorProps> =
    props =>
    {
        const setValue =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.value = value),
                [
                    props.value
                ]);

        return <Input
            label={
                <LocalizedText
                    code="SingletonCollection"
                    value="Collectie uit element"
                />
            }
            labelPosition="left"
        >
            <ComputationBox>
                <ComputationEditor
                    context={props.context}
                    value={props.value.value}
                    onChange={setValue}
                />
            </ComputationBox>
        </Input>;
    };

export default observer(SingletonCollectionComputationEditor);
