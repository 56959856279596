import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import LayoutActionEditor from '../../Action/LayoutActionEditor';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import { runInAction } from 'mobx';
import LayoutAction from '../../../../../@Api/Layout/Action/LayoutAction';
import LabelButtonLayout, { LabelButtonColor, LabelButtonVariant } from '../../../../../@Api/Layout/Type/LabelButtonLayout';
import StaticSelectbox from '../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import localizeText from '../../../../../@Api/Localization/localizeText';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import Layout from '../../../../../@Api/Layout/Layout';
import PredicateEditor from '../../../Entity/Viewer/Content/Automation/Editor/Predicate/PredicateEditor';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';

export interface LabelButtonLayoutControlsProps extends LayoutEditorProps<LabelButtonLayout>
{

}

const LabelButtonLayoutControls: React.FC<LabelButtonLayoutControlsProps> =
    props =>
    {
        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        props.parameterDictionary,
                        props.parameterAssignment,
                        props.commitContext
                    ),
                [
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        const setLabel =
            useCallback(
                (label: Layout) =>
                    runInAction(
                        () =>
                            props.layout.label = label),
                [
                    props.layout
                ]);

        const setAction =
            useCallback(
                (action: LayoutAction) =>
                    runInAction(
                        () =>
                            props.layout.action = action),
                [
                    props.layout
                ]);

        const variantOptions =
            useMemo(
                () => [
                    {
                        id: 'Contained',
                        value: 'Contained',
                        label: localizeText('Layout.LabelButton.Variant.Contained', 'Ingekleurd')
                    },
                    {
                        id: 'Text',
                        value: 'Text',
                        label: localizeText('Layout.LabelButton.Variant.Text', 'Enkel tekst')
                    }
                ],
                []);

        const setVariant =
            useCallback(
                (variant: LabelButtonVariant) =>
                    runInAction(
                        () =>
                            props.layout.variant = variant),
                [
                    props.layout
                ]);

        const colorOptions =
            useMemo(
                () => [
                    {
                        id: 'Primary',
                        value: 'Primary',
                        label: localizeText('Generic.Primary', 'Primair')
                    },
                    {
                        id: 'Secondary',
                        value: 'Secondary',
                        label: localizeText('Generic.Secondary', 'Secundair')
                    },
                    {
                        id: 'Success',
                        value: 'Success',
                        label: localizeText('Generic.Success', 'Succes')
                    },
                    {
                        id: 'Warning',
                        value: 'Warning',
                        label: localizeText('Generic.Warning', 'Waarschuwing')
                    },
                    {
                        id: 'Danger',
                        value: 'Danger',
                        label: localizeText('Generic.Danger', 'Gevaar')
                    }
                ],
                []);

        const setColor =
            useCallback(
                (color: LabelButtonColor) =>
                    runInAction(
                        () =>
                            props.layout.color = color),
                [
                    props.layout
                ]);

        const setDisabled =
            useCallback(
                (value: Predicate) =>
                    runInAction(
                        () =>
                            props.layout.isDisabled = value),
                [
                    props.layout
                ]);

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <Input
                    labelPosition="top"
                    label={
                        <LocalizedText
                            code="Generic.Label"
                            value="Label"
                        />
                    }
                >
                    <ChildLayoutEditor
                        {...props}
                        childLayout={props.layout.label}
                        onChange={setLabel}
                    />
                </Input>
                <Input
                    labelPosition="top"
                    label={
                        <LocalizedText
                            code="Generic.Action"
                            value="Actie"
                        />
                    }
                >
                    <LayoutActionEditor
                        context={context}
                        action={props.layout.action}
                        onChange={setAction}
                    />
                </Input>
                <Input
                    labelPosition="top"
                    label={
                        <LocalizedText
                            code="Generic.Variant"
                            value="Variant"
                        />
                    }
                >
                    <StaticSelectbox
                        options={variantOptions}
                        value={props.layout.variant}
                        onChange={setVariant}
                    />
                </Input>
                <Input
                    labelPosition="top"
                    label={
                        <LocalizedText
                            code="Generic.Color"
                            value="Kleur"
                        />
                    }
                >
                    <StaticSelectbox
                        options={colorOptions}
                        value={props.layout.color}
                        onChange={setColor}
                    />
                </Input>
                <Input
                    labelPosition="top"
                    label={
                        <LocalizedText
                            code="Generic.Disabled"
                            value="Gedeactiveerd"
                        />
                    }
                >
                    <PredicateEditor
                        value={props.layout.isDisabled}
                        onChange={setDisabled}
                        context={context}
                    />
                </Input>
            </InputGroup>
        </CardInset>;
    };

export default observer(LabelButtonLayoutControls);
