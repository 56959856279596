import React, { Fragment } from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import BlockOptionsPanel from '../../Settings/BlockOptionsPanel';
import ColorOptionsPanel from '../../Settings/ColorOptionsPanel';
import DimensionsOptionsPanel from '../../Settings/DimensionsOptionsPanel';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import GutenbergBlockType from '../../../Gutenberg/GutenbergBlockType';
import { GutenbergBlockTemplateId } from '../../../DocumentEditor';
import LinkOptionsPanel from '../../Settings/LinkOptionsPanel';
import TemplateSelector from './TemplateSelector/TemplateSelector';
import TemplateViewer from './TemplateViewer/TemplateViewer';
import StyledBlock from '../../StyledBlock/StyledBlock';
import Icon from '../../../../../Icon/Icon';

const GutenbergBlockTemplate: GutenbergBlockType =
{
    name: GutenbergBlockTemplateId,
    configuration:
        {
            title: {
                code: 'GutenbergBlock.Template',
                value: 'Deelsjabloon'
            },
            icon: <Icon icon="file_copy" />,
            category: 'common',

            attributes:
            {
                content:
                {
                    type: 'string',
                    source: 'html',
                    selector: 'img',
                    query: undefined,
                },
            },

            supports:
            {
                align: true
            },

            edit: props =>
            {
                const {
                    templateId
                } = props.attributes as any;

                const setTemplate =
                    (template: Entity) =>
                    {
                        props.setAttributes({ templateId: template.uuid });
                    };

                return (
                    <Fragment>
                        <InspectorControls>
                            <DimensionsOptionsPanel props={props} open />
                            <BlockOptionsPanel props={props} />
                            <ColorOptionsPanel props={props} />
                            <LinkOptionsPanel props={props} />
                        </InspectorControls>
                        <StyledBlock
                            block={props}
                        >
                            {
                                templateId
                                    ?
                                        <TemplateViewer
                                            templateId={templateId}
                                        />
                                    :
                                        <TemplateSelector
                                            templateId={templateId}
                                            onChange={setTemplate}
                                        />
                            }
                        </StyledBlock>
                    </Fragment>
                );
            },

            save: (props) =>
            {
                return <p>
                    {(props.attributes as any).content}
                </p>;
            }
        },

    view: block =>
    {
        return <div />;
    }
};

export default GutenbergBlockTemplate;
