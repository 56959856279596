import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../@Framework/Component/BaseComponent';
import { RouterStore } from './RouterStore';
import { Loader } from '../../@Component/Generic/Loader/Loader';
import ErrorBoundary from '../../@Component/Error/ErrorBoundary';
import Centered from '../../@Future/Component/Generic/Centered/Centered';
import CardHeader from '../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';

@observer
export class Router extends BaseComponent<RouterStore>
{
    renderLoader(store: RouterStore)
    {
        return <Loader />;
    }

    renderComponent(store: RouterStore)
    {
        if (store.currentRouteInstantiation)
        {
            const component =
                React.createElement(
                    store.currentRouteInstantiation.store.componentClass,
                    {
                        store: store.currentRouteInstantiation.store,
                        ...store.currentRouteInstantiation.store.componentProps
                    });

            return <ErrorBoundary
                centered
            >
                {component}
            </ErrorBoundary>;
        }
        else
        {
            return <Centered
                horizontal
                vertical
            >
                <CardHeader>
                    Pagina niet gevonden
                </CardHeader>
            </Centered>;
        }
    }
}
