import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../Value/PrimitiveValue';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Value from '../../Value/Value';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import CollectionValue from '../../Value/CollectionValue';
import localizeText from '../../../Localization/localizeText';
import { Aggregate } from '../../../../@Component/Domain/DataObject/Model/Aggregate';
import CollectionType from '../../Value/Type/CollectionType';
import EmptyValue from '../../Value/EmptyValue';
import MathematicalComputation from './MathematicalComputation';
import { MathematicalOperator } from '../../../../@Component/Domain/DataObject/Model/MathematicalOperator';
import ComparisonPredicate from './Predicate/ComparisonPredicate';
import { Comparator } from '../../../../@Component/Domain/DataObject/Model/Comparator';

export default class AggregateOfCollectionComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref collection: Computation<any, any>;
    @observable.ref aggregate: Aggregate;

    // ------------------------ Constructor -------------------------

    constructor(
        collection: Computation<any, any>,
        aggregate: Aggregate
    )
    {
        super();

        this.collection = collection;
        this.aggregate = aggregate;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        if (this.aggregate === Aggregate.Count)
        {
            return new PrimitiveValueType(
                DataObject.getTypeById('Number')
            );
        }
        else
        {
            return (this.collection.getType() as CollectionType<any>).type;
        }
    }

    getName(): string
    {
        return localizeText(
            'AggregateOfCollection',
            'Aggregatie van collectie...'
        );
    }

    validate(): Validation[]
    {
        return this.collection.validate();
    }

    isAsync(): boolean
    {
        return this.collection.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const collection =
            await safelyApplyFunction(
                this.collection,
                context
            );

        return this.getAggregateOfCollection(
            collection,
            context
        );
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        const collection =
            safelySynchronousApplyFunction(
                this.collection,
                context
            );

        return this.getAggregateOfCollection(
            collection,
            context
        );
    }

    getAggregateOfCollection(
        collection: Value<any, any>,
        context: FunctionContext
    ): Value<any, any>
    {
        if (collection instanceof CollectionValue)
        {
            const elementsInCollection = collection.value;

            switch (this.aggregate)
            {
                case Aggregate.Count:
                    return new PrimitiveValue(
                        DataObject.constructFromTypeIdAndValue(
                            'Number',
                            elementsInCollection.length
                        )
                    );

                case Aggregate.Sum:
                    if (elementsInCollection.length === 0)
                    {
                        return EmptyValue.instance;
                    }
                    else
                    {
                        let sum: Value<any, any> | undefined = undefined;

                        for (const element of elementsInCollection)
                        {
                            if (sum === undefined)
                            {
                                sum = element;
                            }
                            else
                            {
                                sum =
                                    safelySynchronousApplyFunction(
                                        new MathematicalComputation(
                                            sum,
                                            MathematicalOperator.Add,
                                            element
                                        ),
                                        context
                                    )
                            }
                        }

                        return sum!;
                    }

                case Aggregate.Min:
                    if (elementsInCollection.length === 0)
                    {
                        return EmptyValue.instance;
                    }
                    else
                    {
                        let min: Value<any, any> | undefined = undefined;

                        for (const element of elementsInCollection)
                        {
                            if (min === undefined)
                            {
                                min = element;
                            }
                            else
                            {
                                const isLower =
                                    new ComparisonPredicate(
                                        element,
                                        Comparator.LessThan,
                                        min
                                    ).evaluate(context);

                                if (isLower)
                                {
                                    min = element;
                                }
                            }
                        }

                        return min!;
                    }

                case Aggregate.Max:
                    if (elementsInCollection.length === 0)
                    {
                        return EmptyValue.instance;
                    }
                    else
                    {
                        let max: Value<any, any> | undefined = undefined;

                        for (const element of elementsInCollection)
                        {
                            if (max === undefined)
                            {
                                max = element;
                            }
                            else
                            {
                                const isHigher =
                                    new ComparisonPredicate(
                                        element,
                                        Comparator.GreaterThan,
                                        max
                                    ).evaluate(context);

                                if (isHigher)
                                {
                                    max = element;
                                }
                            }
                        }

                        return max!;
                    }

                default:
                    throw new Error(`Unimplemented aggregate: ${Aggregate[this.aggregate]}`);
            }
        }
        else
        {
            throw new Error(`Expected collection but got: ${JSON.stringify(collection.toDescriptor())}`);
        }
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'AggregateOfCollection';
        descriptor.collection = this.collection.toDescriptor();
        descriptor.aggregate = Aggregate[this.aggregate];
    }

    getDependencies(): Dependency[]
    {
        return this.collection.getDependencies();
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext
    )
    {
        const collection =
            await getComputationFromDescriptor(
                descriptor.collection,
                dependencyContext
            );
        const aggregate = Aggregate[descriptor.aggregate] as any as Aggregate;

        return new AggregateOfCollectionComputation(
            collection,
            aggregate
        );
    }

    // ----------------------- Private logic ------------------------
}
