import React, { useCallback } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../../Type/Api/useTypes';
import performAction from '../../../../../../../../@Api/Entity/performAction';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useWorkflowState from '../../../../../Workflow/Api/useWorkflowState';
import DangerButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/DangerButton/DangerButton';
import SuccessButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/SuccessButton/SuccessButton';
import getDatastoreByCode from '../../../../../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import { createTransactionalModel } from '../../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import Popper from '../../../../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import useSwitch from '../../../../../../../../@Util/Switch/useSwitch';
import styles from './SubscriptionActions.module.scss';
import SubscriptionActivationEditor from './ActivationEditor/SubscriptionActivationEditor';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';

export interface SubscriptionActionsProps
{
    entity: Entity;
}

const SubscriptionActions: React.FC<SubscriptionActionsProps> =
    props =>
    {
        const types = useTypes();

        const phase = useWorkflowState(props.entity);
        const isAwaitingActivation =
            useComputed(
                () =>
                    phase?.getObjectValueByField(types.Datastore.Field.Code) === types.Activity.Subscription.Phase.AwaitingActivation,
                [
                    phase,
                    types
                ]);
        const isActivated =
            useComputed(
                () =>
                    phase?.getObjectValueByField(types.Datastore.Field.Code) === types.Activity.Subscription.Phase.Activated,
                [
                    phase,
                    types
                ]);
        const isDeactivated =
            useComputed(
                () =>
                    phase?.getObjectValueByField(types.Datastore.Field.Code) === types.Activity.Subscription.Phase.Deactivated,
                [
                    phase,
                    types
                ]);

        const setPhase =
            useCallback(
                async (phaseCode: string) =>
                {
                    const phase =
                        await getDatastoreByCode(
                            types.Datastore.Phase.ActivitySubscription.Type,
                            phaseCode);

                    if (phase)
                    {
                        props.entity.updateRelationship(
                            false,
                            types.Activity.Subscription.RelationshipDefinition.Phase,
                            createTransactionalModel(phase));
                    }

                    return props.entity.checkAndDoCommit();
                },
                [
                    props.entity,
                    types
                ]);

        const deactivate =
            useCallback(
                () =>
                    setPhase(types.Activity.Subscription.Phase.Deactivated),
                [
                    setPhase,
                    types
                ]);

        const invoiceSubscription =
            useCallback(
                () =>
                    performAction(
                        props.entity,
                        {
                            code: 'Activity.Subscription.Invoice',
                            name: localizeText('SubscriptionActions.InvoiceSubscription', 'Factureer abonnement')
                        }),
                [
                    props.entity
                ]);

        const [ isActivationPopperOpen, openActivationPopper, closeActivationPopper ] = useSwitch(false);
        // const [ isDeactivationPopperOpen, openDeactivationPopper, closeDeactivationPopper ] = useSwitch(false);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            {
                isActivated &&
                    <ViewGroupItem>
                        <SuccessButton
                            icon="receipt"
                            label={
                                <LocalizedText
                                    code="SubscriptionActions.InvoiceSubscription"
                                    value="Factureer abonnement"
                                />
                            }
                            onClick={invoiceSubscription}
                        />
                    </ViewGroupItem>
            }
            {
                (isAwaitingActivation || isDeactivated) &&
                    <ViewGroupItem>
                        <Popper
                            reference={
                                <SuccessButton
                                    icon="play_arrow"
                                    label={
                                        <LocalizedText
                                            code="Generic.Activate"
                                            value="Activeren"
                                        />
                                    }
                                    onClick={openActivationPopper}
                                />
                            }
                            popper={
                                <Card
                                    inset
                                    classes={{
                                        root: styles.activationPopperRoot
                                    }}
                                >
                                    <SubscriptionActivationEditor
                                        entity={props.entity}
                                    />
                                </Card>
                            }
                            open={isActivationPopperOpen}
                            onClose={closeActivationPopper}
                        />
                    </ViewGroupItem>
            }
            {
                isActivated &&
                    <ViewGroupItem>
                        <DangerButton
                            icon="stop"
                            label={
                                <LocalizedText
                                    code="Generic.Deactivate"
                                    value="Deactiveren"
                                />
                            }
                            onClick={deactivate}
                        />
                        {/*<Popper*/}
                        {/*    reference={*/}
                        {/*        <DangerButton*/}
                        {/*            icon="stop"*/}
                        {/*            label="Opzeggen"*/}
                        {/*            onClick={openDeactivationPopper}*/}
                        {/*        />*/}
                        {/*    }*/}
                        {/*    popper={*/}
                        {/*        <Card*/}
                        {/*            inset*/}
                        {/*        >*/}
                        {/*            <ViewGroup*/}
                        {/*                orientation="vertical"*/}
                        {/*                spacing={15}*/}
                        {/*            >*/}
                        {/*                <ViewGroupItem>*/}
                        {/*                    <InputGroup>*/}
                        {/*                        <Input*/}
                        {/*                            entity={props.entity}*/}
                        {/*                            field={types.Activity.Subscription.Field.EndDate}*/}
                        {/*                            placeholder="Vandaag"*/}
                        {/*                        />*/}
                        {/*                    </InputGroup>*/}
                        {/*                </ViewGroupItem>*/}
                        {/*                <ViewGroupItem>*/}
                        {/*                    <RightAlignedButtonGroup>*/}
                        {/*                        <PrimaryButton*/}
                        {/*                            label="Opzeggen"*/}
                        {/*                            onClick={deactivate}*/}
                        {/*                        />*/}
                        {/*                    </RightAlignedButtonGroup>*/}
                        {/*                </ViewGroupItem>*/}
                        {/*            </ViewGroup>*/}
                        {/*        </Card>*/}
                        {/*    }*/}
                        {/*    open={isDeactivationPopperOpen}*/}
                        {/*    onClose={closeDeactivationPopper}*/}
                        {/*/>*/}
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(SubscriptionActions);
