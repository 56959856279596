import Dependency from '../../../../Automation/Parameter/Dependency';
import Validation from '../../../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../../../LayoutDependencyContext';
import Tab from './Tab';
import ValueType from '../../../../Automation/Value/Type/ValueType';
import TabSource from './TabSource';
import Computation from '../../../../Automation/Function/Computation/Computation';
import Layout from '../../../Layout';
import getComputationFromDescriptor from '../../../../Automation/Api/getComputationFromDescriptor';
import Parameter from '../../../../Automation/Parameter/Parameter';
import AutomationDependencyContext from '../../../../Automation/AutomationDependencyContext';

export default class DynamicTabSource extends TabSource
{
    // ------------------------- Properties -------------------------

    @observable.ref collection: Computation<any, any>;
    @observable.ref tabValue: Computation<any, any>;
    @observable.ref tabValueParameter: Parameter<any>;
    @observable.ref tabLayout: Layout;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                collection: Computation<any, any>,
                tabValue: Computation<any, any>,
                tabValueParameter: Parameter<any>,
                tabLayout: Layout)
    {
        super(id);

        this.collection = collection;
        this.tabValue = tabValue;
        this.tabValueParameter = tabValueParameter;
        this.tabLayout = tabLayout;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getTabs(): Tab[]
    {
        return [];
    }

    validate(): Validation[]
    {
        return [
            ...this.collection.validate(),
            ...this.tabValue.validate(),
            ...this.tabLayout.validate()
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.collection.getDependencies(),
            ...this.tabValue.getDependencies(),
            ...this.tabLayout.getDependencies()
                .filter(
                    dependency =>
                        dependency.parameter !== this.tabValueParameter)
        ];
    }

    toInternalDescriptor(descriptor: any)
    {
        descriptor.type = 'Dynamic';
        descriptor.collection = this.collection.toDescriptor();
        descriptor.tabValue = this.tabValue.toDescriptor();
        descriptor.tabLayout = this.tabLayout.toDescriptor();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext,
                                valueType: ValueType<any>)
    {
        const id = descriptor.id;
        const collection =
            await getComputationFromDescriptor(
                descriptor.collection,
                dependencyContext);
        const tabValue =
            await getComputationFromDescriptor(
                descriptor.tabValue,
                dependencyContext);
        const tabValueParameter =
            new Parameter(
                id,
                valueType,
                true,
                valueType.getName());
        const tabLayout =
            await getComputationFromDescriptor(
                descriptor.tabLayout,
                new AutomationDependencyContext(
                    dependencyContext
                        .parameterDictionary
                        .getNewDictionaryWithParameter(tabValueParameter)));

        return new DynamicTabSource(
            descriptor.id,
            collection,
            tabValue,
            tabValueParameter,
            tabLayout);
    }

    // ----------------------- Private logic ------------------------
}
