import { Entity } from '../../../../Model/Implementation/Entity';
import getPhaseRelationshipDefinition from './getPhaseRelationshipDefinition';
import { EntitySelectionBuilder } from '../../../../../@Component/Domain/Entity/Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { CommitContext } from '../../../Commit/Context/CommitContext';
import { updateRelationship } from '../../../Commit/Context/Api/Compatibility/updateRelationship';

export default function initializeInitialPhase(
    entity: Entity,
    doForce: boolean = false,
    commitContext?: CommitContext
)
{
    const types = loadModuleDirectly(EntityTypeStore).bespoke.types;
    const phaseRelationshipDefinition = getPhaseRelationshipDefinition(entity.entityType);

    if (phaseRelationshipDefinition)
    {
        return new EntitySelectionBuilder(phaseRelationshipDefinition.childEntityType)
            .where(
                cb =>
                    cb.eq(
                        EntityPath.fromEntityType(phaseRelationshipDefinition.childEntityType)
                            .field(types.Datastore.Field.IsDefault),
                        undefined,
                        true))
            .select()
            .then(
                results =>
                {
                    // If phase is not defined, then set it to the initial state (if there is any)
                    if (!entity.hasRelationshipsByDefinition(
                        false,
                        phaseRelationshipDefinition,
                        commitContext
                    )
                        || doForce)
                    {
                        const initialState =
                            results
                                .map(
                                    result =>
                                        result.entity)
                                .find(() => true);

                        if (initialState)
                        {
                            updateRelationship(
                                entity,
                                false,
                                phaseRelationshipDefinition,
                                initialState,
                                commitContext
                            );
                        }
                    }
                });
    }
    else
    {
        return Promise.resolve();
    }
}
