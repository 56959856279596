import { Entity } from '../../../Model/Implementation/Entity';
import { CommitContext } from '../../Commit/Context/CommitContext';
import getTypes from '../../../../@Component/Domain/Entity/Type/Api/getTypes';
import { EmailType } from '../../../../@Component/Domain/DataObject/Type/Text/Email/EmailType';
import { EntityEmailAddress } from './EntityEmailAddress';

export function getEmailAddressesByEntity(
    entity: Entity,
    commitContext?: CommitContext
): EntityEmailAddress[]
{
    const types = getTypes();

    const emailAddresses =
        entity.entityType.getInheritedFields()
            .filter(
                field =>
                    field.dataObjectSpecification.type instanceof EmailType
                    && entity.hasValueForField(field, commitContext))
            .map(
                field => ({
                    field: field,
                    emailAddress:
                        entity.getObjectValueByField(
                            field,
                            commitContext
                        )
                }));

    if (entity.entityType.isA(types.Relationship.Type))
    {
        const relationEmailAddresses =
            entity
                .getRelatedEntitiesByDefinition(
                    false,
                    entity.entityType.isA(types.Relationship.Organization.Type)
                        ? types.Relationship.Organization.RelationshipDefinition.Organization
                        : types.Relationship.Person.RelationshipDefinition.Person,
                    commitContext
                )
                .map(
                    relation =>
                        getEmailAddressesByEntity(
                            relation,
                            commitContext
                        )
                )
                .reduce((a, b) => a.concat(b), []);

        return [
            ...emailAddresses,
            ...relationEmailAddresses
        ];
    }
    else if (entity.entityType.isA(types.EmailSender.Type))
    {
        return [
            {
                field: types.EmailSender.Field.EmailAddress,
                emailAddress:
                    entity.getObjectValueByField(
                        types.EmailSender.Field.EmailAddress,
                        commitContext
                    )
            }
        ];
    }
    else
    {
        return emailAddresses;
    }
}