import React, { useCallback, useMemo } from 'react';
import Selectbox, { SelectboxProps } from '../Selectbox';
import search from '../../../../../../@Util/String/search';

export interface StaticOption<D>
{
    id: string;
    label: string;
    value: D;
    multi?: boolean;
}

export interface StaticSelectboxProps<D> extends Omit<SelectboxProps<D>, 'load' | 'formatOption' | 'idResolver'>
{
    options: StaticOption<D>[];
}

const StaticSelectbox: React.FC<StaticSelectboxProps<any>> =
    props =>
    {
        const optionByValue =
            useMemo(
                () =>
                    new Map(
                        props.options.map(
                            option => [
                                option.value,
                                option
                            ])),
                [
                    props.options
                ]);

        const load =
            useCallback(
                (query: string) =>
                    Promise.resolve(
                        props.options
                            .filter(
                                option =>
                                    search(query, option.label))
                            .map(
                                option =>
                                    option.value)),
                [
                    props.options
                ]);

        const idResolver =
            useCallback(
                (value: any) =>
                {
                    const option = optionByValue.get(value);

                    if (option === undefined)
                    {
                        return 'undefined';
                    }
                    else
                    {
                        return option.id;
                    }
                },
                [
                    optionByValue
                ]);

        const formatOption =
            useCallback(
                (value: any) =>
                {
                    const option = optionByValue.get(value);

                    if (option === undefined)
                    {
                        return '';
                    }
                    else
                    {
                        return option.label;
                    }
                },
                [
                    optionByValue
                ]);

        return <Selectbox
            load={load}
            idResolver={idResolver}
            formatOption={formatOption}
            {...props}
        />;
    };

export default StaticSelectbox;
