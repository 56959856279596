import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../Type/Api/useTypes';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { useCommittableEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/useCommittableEntity';
import MileageSheet from '../../../../Viewer/Content/Activity/MileageRegistration/MileageSheet/MileageSheet';
import useRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import { CardContent } from '@material-ui/core';

export interface TimeRegistrationMileageRegistrationsEditorProps
{
    entity: Entity;
    noScroll?: boolean;
    commitContext?: CommitContext;
}

const TimeRegistrationMileageRegistrationsEditor: React.FC<TimeRegistrationMileageRegistrationsEditorProps> =
    props =>
    {
        const commitContext = useNewCommitContext(props.commitContext);
        const entity = useCommittableEntity(props.entity, commitContext);
        const types = useTypes();
        const relationship =
            useRelatedEntity(
                entity,
                types.Relationship.RelationshipDefinition.TimeRegistrations,
                true,
                commitContext
            );
        const activity =
            useRelatedEntity(
                entity,
                types.Activity.RelationshipDefinition.TimeRegistrations,
                true,
                commitContext
            );
        const milestone =
            useRelatedEntity(
                entity,
                types.Milestone.RelationshipDefinition.TimeRegistrations,
                true,
                commitContext
            );

        return <CardContent>
            <MileageSheet
                relationship={relationship}
                activity={activity}
                milestone={milestone}
                timeRegistration={entity}
                commitContext={commitContext}
                noScroll={props.noScroll}
            />
        </CardContent>;
    };

export default observer(TimeRegistrationMileageRegistrationsEditor);
