import React from 'react';
import { CheckboxControl, PanelBody, PanelRow } from '@wordpress/components';
import { BlockEditProps } from '@wordpress/blocks';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface MileageRegistrationsOptionsProps
{
    props: BlockEditProps<{}>;
    open?: boolean;
}

const MileageRegistrationsOptions: React.FC<MileageRegistrationsOptionsProps> =
    props =>
    {
        const {
            hideEmployee,
            hideStartTime,
            showActivity
        } = props.props.attributes as any;

        return <PanelBody
            title={'Urenregistraties'}
            initialOpen={!!props.open}
        >
            <PanelRow>
                <CheckboxControl
                    name="hidePrices"
                    label={
                        <LocalizedText
                            code="Gutenberg.TimeRegistrationsOptions.HideEmployee"
                            value="Medewerker verbergen"
                        />
                    }
                    checked={hideEmployee}
                    onChange={hideEmployee => props.props.setAttributes({ hideEmployee: hideEmployee })}
                />
            </PanelRow>
            <PanelRow>
                <CheckboxControl
                    name="hideStartTime"
                    label={
                        <LocalizedText
                            code="Gutenberg.TimeRegistrationsOptions.HideStartTime"
                            value="Starttijd verbergen"
                        />
                    }
                    checked={hideStartTime}
                    onChange={hideStartTime => props.props.setAttributes({ hideStartTime: hideStartTime })}
                />
            </PanelRow>
            <PanelRow>
                <CheckboxControl
                    name="showActivity"
                    label={
                        <LocalizedText
                            code="Gutenberg.TimeRegistrationsOptions.ShowActivity"
                            value="Activiteit tonen"
                        />
                    }
                    checked={showActivity}
                    onChange={showActivity => props.props.setAttributes({ showActivity: showActivity })}
                />
            </PanelRow>
        </PanelBody>;
    };

export default MileageRegistrationsOptions;
