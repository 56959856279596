import * as React from 'react';
import { observer } from 'mobx-react';
import { ListItemStore } from './Item/ListItemStore';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { ListStore } from './ListStore';
import { View } from '../../ViewStack/View';
import { ListGroupStore } from './ListGroupStore';

export interface ListGroupProps
{
    idx: number;
    groupStore: ListGroupStore<any, any, any>;
}

@observer
export default class ListGroup<D, Q, I extends ListItemStore<D>> extends BaseComponent<ListStore<D, Q, I>, ListGroupProps>
{
    renderLoader(store: ListStore<D, Q, I>)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: ListStore<D, Q, I>): JSX.Element
    {
        return <View
            viewComponent={store.group.template(this.props.groupStore, this.props.idx, store.groupStores)}
        />;
    }
}
