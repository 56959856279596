import React from 'react';
import { default as MuiDivider } from '@material-ui/core/Divider';

export interface DividerProps
{
    className?: string;
}

const Divider: React.FC<DividerProps> =
    props =>
    {
        return <MuiDivider
            light
            classes={{
                root: props.className
            }}
        />;
    };

export default Divider;
