// https://spin.atomicobject.com/2018/09/10/javascript-concurrency/
import uuid from '../Id/uuid';

export class Mutex
{
    private lockId?: string;
    private mutex = Promise.resolve();

    lock(): PromiseLike<() => void>
    {
        let begin: (unlock: () => void) => void = unlock => {};

        this.mutex = this.mutex.then(() => {
            return new Promise(begin);
        });

        return new Promise(res => {
            begin = res;
        });
    }

    async dispatch<T>(fn: ((lockId: string) => T) | ((lockId: string) => PromiseLike<T>)): Promise<T>
    {
        const unlock = await this.lock();
        const oldLock = this.lockId;

        try
        {
            const lockId = uuid();
            this.lockId = lockId;
            return await Promise.resolve(fn(lockId));
        }
        finally
        {
            unlock();
            this.lockId = oldLock;
        }
    }

    async await(): Promise<void>
    {
        await this.mutex;
    }

    isLocked(lockId?: string): boolean
    {
        return this.lockId !== lockId;
    }

    isInLock(lockId?: string)
    {
        return lockId !== undefined && this.lockId === lockId;
    }
}
