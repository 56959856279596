import Widget from '../../Widget';
import { observable, runInAction } from 'mobx';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import collectAndInitializeByEntityIds from '../../../Api/collectAndInitializeByEntityIds';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../../Type/EntityTypeStore';

export default class ResourcePlannerWidget extends Widget
{
    // ------------------------- Properties -------------------------

    @observable resourcePlannerId: string;
    @observable.ref resourcePlanner: Entity;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                resourcePlannerId: string,
                resourcePlanner?: Entity)
    {
        super(id);

        this.resourcePlannerId = resourcePlannerId;
        this.resourcePlanner = resourcePlanner;

        if (resourcePlanner)
        {
            this.isInitialized = true;
        }
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async doInitialize(widgets: ResourcePlannerWidget[])
    {
        return collectAndInitializeByEntityIds(
            widgets,
            widget => widget.resourcePlannerId,
            async (widget, entity) =>
                runInAction(
                    () =>
                        widget.resourcePlanner = entity
                ),
            loadModuleDirectly(EntityTypeStore).bespoke.types.ResourcePlanner.Type);
    }

    static fromDescriptor(descriptor: any)
    {
        return new ResourcePlannerWidget(
            descriptor.id,
            descriptor.resourcePlannerId);
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'ResourcePlanner',
            resourcePlannerId: this.resourcePlannerId
        }
    }

    isFullWidth(): boolean
    {
        return true;
    }

    // ----------------------- Private logic ------------------------
}
