import { BaseTypeStore } from '../../../@Framework/Store/BaseTypeStore';
import { ComputationType } from './Type/ComputationType';
import { injectWithQualifier } from '../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../DataObject/DataObjectStore';
import { ComputationSpecification } from './Type/ComputationSpecification';
import { ComputationContext } from './ComputationContext';
import { DataObject } from '../DataObject/Model/DataObject';
import { Computation } from './Type/Computation';

export class ComputationTypeStore extends BaseTypeStore<ComputationType>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    computeFromSpecification(context: ComputationContext,
                             specification: ComputationSpecification): DataObject
    {
        if (this.hasType(specification.type))
        {
            return this.compute(
                context,
                this.fromSpecification(specification));
        }
        else
        {
            return null;
        }
    }

    compute(context: ComputationContext,
            computation: Computation): DataObject
    {
        return computation.type.compute(
            context,
            computation);
    }

    fromSpecification(specification: ComputationSpecification): Computation
    {
        if (this.hasType(specification.type))
        {
            return this.getTypeById(specification.type)
                .fromSpecification(specification);
        }
        else
        {
            return undefined;
        }
    }

    toSpecification(computation: Computation): ComputationSpecification
    {
        return computation.type.toSpecification(computation);
    }

    // ----------------------- Private logic ------------------------
}
