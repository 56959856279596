import { observable } from 'mobx';
import ActivityListItem from './ActivityListItem';
import { loadModuleDirectly } from '../../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../../Type/EntityTypeStore';
import Parameter from '../../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../../@Api/Automation/Value/Type/EntityValueType';

export default class ActivityList
{
    // ------------------------- Properties -------------------------

    @observable.ref parameter: Parameter<EntityValueType>;
    @observable.shallow items: ActivityListItem[];

    // ------------------------ Constructor -------------------------

    constructor(parameter: Parameter<EntityValueType>,
                items: ActivityListItem[])
    {
        this.parameter = parameter;
        this.items = items;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    get id()
    {
        return this.parameter.id;
    }

    get entityType()
    {
        return this.parameter.type.type;
    }

    static async fromDescriptor(descriptor: any)
    {
        const entityType = loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.entityTypeId);
        const parameter = new Parameter(descriptor.id, new EntityValueType(entityType), true, entityType.getName());
        const items =
            await Promise.all<ActivityListItem | undefined>(
                descriptor.items
                    .map(
                        item =>
                            ActivityListItem.fromDescriptor(item)
                                .catch(
                                    () =>
                                        Promise.resolve(undefined)
                                )
                    )
            );
        const validItems =
            items.filter(
                item =>
                    item !== undefined
            );

        return new ActivityList(
            parameter,
            validItems
        );
    }

    toDescriptor(): any
    {
        return {
            id: this.id,
            entityTypeId: this.entityType.id,
            items: this.items.map(item => item.toDescriptor())
        };
    }

    // ----------------------- Private logic ------------------------
}
