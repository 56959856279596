import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ButtonBase from '@material-ui/core/ButtonBase';
import styles from './Item.module.scss';
import Icon from '../../../../../@Future/Component/Generic/Icon/Icon';
import { veryLightTextColor } from '../../../../../@Resource/Theme/Theme';
import { green } from '@material-ui/core/colors';
import { classNames } from '../../../../../@Future/Util/Class/classNames';

export interface ItemProps
{
    name: string | ReactNode;
    checked?: boolean;
    disabled?: boolean;
    click?: () => (Promise<any> | any);
}

const Item: React.FC<ItemProps> =
    props =>
    {
        return <ButtonBase
            onClick={props.click}
            className={classNames(styles.root, props.checked && styles.checked)}
            disabled={props.disabled}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={5}
                alignment="center"
            >
                <ViewGroupItem>
                    <div
                        className={styles.icon}
                    >
                        <Icon
                            icon="checked"
                            size={18}
                            color={props.checked ? green[500] : veryLightTextColor}
                        />
                    </div>
                </ViewGroupItem>
                <ViewGroupItem
                    ratio={1}
                >
                    <span
                        className={styles.name}
                    >
                        {props.name}
                    </span>
                </ViewGroupItem>
            </ViewGroup>
        </ButtonBase>;
    };

export default observer(Item);
