import { observable } from 'mobx';
import Value from '../../Automation/Value/Value';
import AutomationDependencyContext from '../../Automation/AutomationDependencyContext';
import getValueFromDescriptor from '../../Automation/Api/getValueFromDescriptor';
import PortalActionInvocation from './PortalActionInvocation';
import PortalDataSourceQuery from '../DataSource/PortalDataSourceQuery';
import ActionInvocationResult from '../../Automation/ActionTemplate/Model/ActionInvocationResult';

export default class PortalActionInvocationResult extends ActionInvocationResult
{
    // ------------------------- Properties -------------------------

    @observable.shallow dirtyQueries: PortalDataSourceQuery[];

    // ------------------------ Constructor -------------------------

    constructor(invocationId: string,
                result: Value<any, any>,
                dirtyQueries: PortalDataSourceQuery[])
    {
        super(invocationId, result);

        this.dirtyQueries = dirtyQueries;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(descriptor: any,
                                invocation: PortalActionInvocation)
    {
        const invocationId = descriptor.invocationId;

        const result =
            await getValueFromDescriptor(
                descriptor.result,
                AutomationDependencyContext.GetEmptyContext());

        const openQueryById =
            new Map(
                invocation.openQueries.map(
                    query => [
                        query.id,
                        query
                    ]));

        const dirtyQueries =
            descriptor.dirtyQueryIds
                .filter(
                    queryId =>
                        openQueryById.has(queryId))
                .map(
                    queryId =>
                        openQueryById.get(queryId));

        return new PortalActionInvocationResult(
            invocationId,
            result,
            dirtyQueries);
    }

    // ----------------------- Private logic ------------------------
}
