import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import styles from './Item.module.scss';
import Icon from '../../../../../../../@Future/Component/Generic/Icon/Icon';
import { primaryColor } from '../../../../../../../@Resource/Theme/Theme';
import IconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import RouterContext from '../../../../../../../@Service/Router/RouterContext';
import { useComputed } from 'mobx-react-lite';

export interface NavigationProps
{
    icon?: string;
    name: React.ReactNode;
    route: string;
    onClose?: () => void;
    exact?: boolean;
}

const Item: React.FC<NavigationProps> =
    props =>
    {
        const routerStore = useContext(RouterContext);
        const selected =
            useComputed(
                () =>
                    props.exact
                        ?
                            routerStore.path === props.route
                        :
                            routerStore.path.startsWith(props.route),
                [
                    props.exact,
                    routerStore,
                    props.route
                ]);

        const route =
            useCallback(
                () =>
                    routerStore.route(props.route),
                [
                    routerStore,
                    props.route
                ]);

        return <div
            className={classNames(styles.root, selected && styles.selected)}
            onClick={route}
        >
            <div
                className={styles.content}
            >
                {
                    props.icon &&
                        <div
                            className={styles.icon}
                        >
                            <Icon
                                icon={props.icon}
                                size={18}
                            />
                        </div>
                }
                <div
                    className={styles.name}
                >
                    {props.name || '...'}
                </div>
            </div>
            {
                props.onClose &&
                    <div
                        className={styles.closeButton}
                    >
                        <IconButton
                            icon="close"
                            tooltip="Sluiten"
                            onClick={props.onClose}
                            iconSize={14}
                            color={primaryColor}
                            classes={{ root: styles.closeButton }}
                        />
                    </div>
            }
        </div>;
    };

export default observer(Item);
