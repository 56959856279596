import { useMemo } from 'react';
import useResizeObserver from 'use-resize-observer/polyfilled';

export default function useHorizontalMenuAdjustment(menu?: HTMLDivElement)
{
    const options =
        useMemo(
            () => ({
                ref: {
                    current: menu
                },
                onResize:
                    () =>
                        menu &&
                            calculateHorizontalAdjustment(menu)
            }),
            [
                menu
            ]);

    useResizeObserver(options);
}

function calculateHorizontalAdjustment(menu: HTMLDivElement)
{
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const rect = menu.getBoundingClientRect();
    const offset = Math.min(vw - rect.left - rect.width - 15, 0);

    menu.style.marginLeft = `${offset}px`;
}
