import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import { MathematicalOperator } from '../../../../@Component/Domain/DataObject/Model/MathematicalOperator';
import Dependency from '../../Parameter/Dependency';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import MathematicalComputation from './MathematicalComputation';
import ComparisonPredicate from './Predicate/ComparisonPredicate';
import { Comparator } from '../../../../@Component/Domain/DataObject/Model/Comparator';
import localizeText from '../../../Localization/localizeText';
import CollectionValue from '../../Value/CollectionValue';
import CollectionType from '../../Value/Type/CollectionType';

export default class RangeCollectionComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    private static MaxAllowedIterations = 10000;

    @observable.ref from: Computation<any, any>;
    @observable.ref to: Computation<any, any>;
    @observable.ref interval: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(from: Computation<any, any>,
                to: Computation<any, any>,
                interval: Computation<any, any>)
    {
        super();

        this.from = from;
        this.to = to;
        this.interval = interval;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new CollectionType(this.from.getType());
    }

    isAsync(): boolean
    {
        return this.from.isAsync()
            || this.to.isAsync()
            || this.interval.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const fromValue = await safelyApplyFunction(this.from, context);
        const toValue = await safelyApplyFunction(this.to, context);
        const intervalValue = await safelyApplyFunction(this.interval, context);

        return this.computeCollection(fromValue, toValue, intervalValue, context);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        const fromValue = safelySynchronousApplyFunction(this.from, context);
        const toValue = safelySynchronousApplyFunction(this.to, context);
        const intervalValue = safelySynchronousApplyFunction(this.interval, context);

        return this.computeCollection(fromValue, toValue, intervalValue, context);
    }

    private computeCollection(from: Value<any, any>,
                              to: Value<any, any>,
                              interval: Value<any, any>,
                              context: FunctionContext): Value<any, any>
    {
        const valueType = from.getType();
        const isIncreasing = this.isGreaterThan(to, from, context);
        const currentValues: Value<any, any>[] = [];
        let currentValue = from;
        let i = 0;

        while (this.isGreaterThan(to, currentValue, context) === isIncreasing)
        {
            if (currentValue.getType().isA(valueType))
            {
                currentValues.push(currentValue);
            }

            currentValue = this.add(currentValue, interval, context);
            i++;

            if (i > RangeCollectionComputation.MaxAllowedIterations)
            {
                throw new Error('Max allowed iterations reached');
            }
        }

        return new CollectionValue(
            currentValues,
            valueType);
    }

    private isGreaterThan(lhsValue: Value<any, any>,
                          rhsValue: Value<any, any>,
                          context: FunctionContext)
    {
        return new ComparisonPredicate(
            lhsValue,
            Comparator.GreaterThan,
            rhsValue).synchronouslyEvaluate(context);
    }

    private add(lhsValue: Value<any, any>,
                rhsValue: Value<any, any>,
                context: FunctionContext)
    {
        return safelySynchronousApplyFunction(
            new MathematicalComputation(
                lhsValue,
                MathematicalOperator.Add,
                rhsValue),
            context);
    }

    getName(): string
    {
        return localizeText('Computation.RangeCollection', 'Collectie uit range');
    }

    validate(): Validation[]
    {
        return [
            ...this.from.validate(),
            ...this.to.validate(),
            ...this.interval.validate()
        ];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'RangeCollection';
        descriptor.from = this.from.toDescriptor();
        descriptor.to = this.to.toDescriptor();
        descriptor.interval = this.interval.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.from.getDependencies(),
            ...this.to.getDependencies(),
            ...this.interval.getDependencies()
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const [ from, to, interval ] =
            await Promise.all([
                getComputationFromDescriptor(descriptor.from, dependencyContext),
                getComputationFromDescriptor(descriptor.to, dependencyContext),
                getComputationFromDescriptor(descriptor.interval, dependencyContext)
            ]);

        return new RangeCollectionComputation(from, to, interval);
    }

    // ----------------------- Private logic ------------------------
}
