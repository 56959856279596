import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ExpansionGroup from '../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import ExpansionPanel from '../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import ExpansionHeader from '../../../../@Future/Component/Generic/Label/Variant/ExpansionHeader/ExpansionHeader';
import Card from '../../../../@Future/Component/Generic/Card/Card';
import CurrentUserContext from '../../User/CurrentUserContext';
import useTypes from '../../Entity/Type/Api/useTypes';
import RouterContext from '../../../../@Service/Router/RouterContext';
import Item from './Item/Item';
import InviteEmployees from '../../License/InviteEmployees/InviteEmployees';
import styles from './Content.module.scss';
import { LinearProgress } from '@material-ui/core';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import PrimaryButton from '../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import useExpanded from '../../Entity/Dataset/Api/useExpanded';
import useSwitch from '../../../../@Util/Switch/useSwitch';
import useCount from '../../Entity/Selection/Hooks/useCount';
import useResult from '../../Entity/Selection/Hooks/useResult';
import { openEntity } from '../../Entity/@Util/openEntity';
import useDialog from '../../../../@Service/Navigation/Page/Hooks/useDialog';
import EntityPictureEditor from '../../Entity/Picture/Editor/EntityPictureEditor';
import useAllowedDomains from '../../Configuration/Page/DomainValidation/Hooks/useAllowedDomains';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import { CommitBuilder } from '../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import openMarketplace from '../../Marketplace/Api/openMarketplace';

export interface OnboardingManagerContentProps
{

}

const Content: React.FC<OnboardingManagerContentProps> =
    () =>
    {
        const [ isExpanded, toggleExpansion ] =
            useExpanded(
                'OnboardingManager',
                false);

        const currentUserStore = useContext(CurrentUserContext);
        const routerStore = useContext(RouterContext);
        const types = useTypes();

        const isAccountCreated =
            useComputed(
                () => true,
                []);

        const isEmailValidated =
            useComputed(
                () =>
                    currentUserStore.currentAccount.validationDate !== undefined,
                [
                    currentUserStore
                ]);

        const isLogoAdded =
            useComputed(
                () =>
                    currentUserStore.environmentEntity.hasValueForField(types.Relation.Field.Picture),
                [
                    currentUserStore
                ]);

        const emailAndAgendaConnectorActivationCount =
            useCount(
                types.ConnectorActivation.Type,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.eq(
                                rootPath
                                    .joinTo(
                                        types.Connector.RelationshipDefinition.Activations,
                                        true)
                                    .joinTo(
                                        types.ConnectorCategory.RelationshipDefinition.Connectors,
                                        true)
                                    .field(types.ConnectorCategory.Field.Code),
                                undefined,
                                'EmailAndAgenda')),
                [
                    types
                ]);

        const isEmailAndAgendaConnectorSetup =
            useMemo(
                () =>
                    emailAndAgendaConnectorActivationCount > 0,
                [
                    emailAndAgendaConnectorActivationCount
                ]);

        const financialConnectorActivationCount =
            useCount(
                types.ConnectorActivation.Type,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.eq(
                                rootPath
                                    .joinTo(
                                        types.Connector.RelationshipDefinition.Activations,
                                        true)
                                    .joinTo(
                                        types.ConnectorCategory.RelationshipDefinition.Connectors,
                                        true)
                                    .field(types.ConnectorCategory.Field.Code),
                                undefined,
                                'Financial')),
                [
                    types
                ]);

        const isFinancialConnectorSetup =
            useMemo(
                () =>
                    financialConnectorActivationCount > 0,
                [
                    financialConnectorActivationCount
                ]);

        const [ lastOrganizationImport ] =
            useResult(
                types.Import.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath
                                        .joinTo(
                                            types.EntityType.RelationshipDefinition.Imports,
                                            true),
                                    types.Relationship.Organization.Type.entity))
                        .orderBy(rootPath.field(types.Entity.Field.CreationDate), false),
                [
                    types
                ]);
        const hasImportedRelations =
            useComputed(
                () =>
                    lastOrganizationImport !== undefined,
                [
                    lastOrganizationImport
                ]);
        const openRelationImport =
            useCallback(
                () =>
                {
                    if (lastOrganizationImport)
                    {
                        return openEntity(lastOrganizationImport);
                    }
                    else
                    {
                        return routerStore.route(`/type/${types.Relationship.Organization.Type.id}/import`);
                    }
                },
                [
                    lastOrganizationImport,
                    routerStore
                ]);

        const [ openLogoDialog ] =
            useDialog(
                closeDialog =>
                    <EntityPictureEditor
                        onClose={closeDialog}
                        entity={currentUserStore.environmentEntity}
                        onSave={closeDialog}
                    />,
                [
                    currentUserStore,
                    currentUserStore.environmentEntity
                ]);

        const employeeCount = useCount(types.Relationship.Person.Contact.Employee.Type, () => {}, []);
        const hasInvitedColleagues =
            useMemo(
                () =>
                    employeeCount > 1,
                [
                    employeeCount
                ]);
        const [ isInviteColleaguesOpen, openInviteColleagues, closeInviteColleagues ] = useSwitch(false);

        const allowedDomains = useAllowedDomains();
        const hasAllowedDomains =
            useMemo(
                () =>
                    allowedDomains.length > 0,
                [
                    allowedDomains
                ]);

        const listOfBooleans = [
            isAccountCreated,
            isEmailValidated,
            isLogoAdded,
            isEmailAndAgendaConnectorSetup,
            isFinancialConnectorSetup,
            hasImportedRelations,
            hasInvitedColleagues,
            hasAllowedDomains
        ];

        const openDomainValidation =
            useCallback(
                () =>
                {
                    return routerStore.route(`/configuration/domain-validations`);
                },
                [
                    routerStore
                ]);

        const percentage =
            useComputed(
                () =>
                    Math.round(listOfBooleans.filter(b => b).length / listOfBooleans.length * 100),
                listOfBooleans);

        const onFinish =
            useCallback(
                () =>
                    new CommitBuilder()
                        .setObjectValueInEntity(
                            currentUserStore.environmentEntity,
                            types.Relation.Organization.Environment.Field.IsOnboarding,
                            false
                        )
                        .commit(),
                [
                    currentUserStore
                ]);

        return <div
            className={styles.root}
        >
            <Card>
                <ExpansionGroup>
                    <ExpansionPanel
                        id="OnboardingManager"
                        summary={
                            <Header
                                title={
                                    isExpanded
                                        ?
                                            <LocalizedText
                                                code="Onboarding.SetUpTribeCRM"
                                                value="Tribe CRM instellen"
                                            />
                                        :
                                            <LocalizedText
                                                code="Onboarding.ContinueSetUpTribeCRM"
                                                value="Doorgaan met Tribe CRM instellen"
                                            />
                                }
                                inset
                                large
                                classes={{
                                    root: styles.header,
                                    title: styles.title
                                }}
                            >
                                <ExpansionHeader>
                                    {/*<ViewGroup*/}
                                    {/*    orientation="horizontal"*/}
                                    {/*    spacing={buttonInset}*/}
                                    {/*    alignment="center"*/}
                                    {/*>*/}
                                    {/*    <ViewGroupItem>*/}
                                    {/*        <div*/}
                                    {/*            onClick={e => e.stopPropagation()}*/}
                                    {/*        >*/}
                                    {/*            <MenuButton>*/}
                                    {/*                {*/}
                                    {/*                    <div*/}
                                    {/*                        className={styles.deleteButtonMenu}*/}
                                    {/*                    >*/}
                                    {/*                        <MenuItem*/}
                                    {/*                            name="Sluiten"*/}
                                    {/*                            onClick={onDelete}*/}
                                    {/*                        />*/}
                                    {/*                    </div>*/}
                                    {/*                }*/}
                                    {/*            </MenuButton>*/}
                                    {/*        </div>*/}
                                    {/*    </ViewGroupItem>*/}
                                    {/*</ViewGroup>*/}
                                </ExpansionHeader>
                            </Header>
                        }
                        expansion={
                            <ViewGroup
                                orientation="vertical"
                                spacing={0}
                            >
                                <ViewGroupItem>
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Onboarding.RegisterAccount"
                                                value="Registreer je account"
                                            />
                                        }
                                        checked={isAccountCreated}
                                        disabled={isAccountCreated}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Onboarding.ConfirmEmailAddress"
                                                value="Bevestig je e-mail adres"
                                            />
                                        }
                                        checked={isEmailValidated}
                                        disabled
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Onboarding.AddLogo"
                                                value="Voeg je bedrijfslogo toe"
                                            />
                                        }
                                        checked={isLogoAdded}
                                        click={openLogoDialog}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Onboarding.ConfigureEmailAndAgenda"
                                                value="Stel je e-mail en agenda in"
                                            />
                                        }
                                        checked={isEmailAndAgendaConnectorSetup}
                                        click={() => openMarketplace('EmailAndAgenda')}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Onboarding.ConfigurateFinancialConnector"
                                                value="Stel je financiële koppeling in"
                                            />
                                        }
                                        checked={isFinancialConnectorSetup}
                                        click={() => openMarketplace('Financial')}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Onboarding.ImportRelations"
                                                value="Importeer je relaties"
                                            />
                                        }
                                        checked={hasImportedRelations}
                                        click={openRelationImport}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Onboarding.InviteColleagues"
                                                value="Nodig je collega's uit"
                                            />
                                        }
                                        checked={hasInvitedColleagues}
                                        click={openInviteColleagues}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Onboarding.ValidateEmailDomain"
                                                value="Valideer je e-maildomein"
                                            />
                                        }
                                        checked={hasAllowedDomains}
                                        click={openDomainValidation}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <CardInset>
                                        <ViewGroup
                                            orientation="horizontal"
                                            spacing={15}
                                            alignment="center"
                                        >
                                            <ViewGroupItem
                                                ratio={1}
                                            >
                                                <LinearProgress
                                                    value={percentage}
                                                    variant="determinate"
                                                />
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                {percentage}%
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </CardInset>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <CardInset>
                                        <ViewGroup
                                            orientation="horizontal"
                                            spacing={15}
                                            alignment="center"
                                        >
                                            <ViewGroupItem
                                                ratio={1}
                                            />
                                            <ViewGroupItem>
                                                <PrimaryButton
                                                    label={
                                                        <LocalizedText
                                                            code="Onboarding.Ready"
                                                            value="Ik ben klaar!"
                                                        />
                                                    }
                                                    onClick={onFinish}
                                                />
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </CardInset>
                                </ViewGroupItem>
                            </ViewGroup>
                        }
                        expanded={isExpanded}
                        onExpand={toggleExpansion}
                        onCollapse={toggleExpansion}
                    />
                </ExpansionGroup>
                {
                    isInviteColleaguesOpen &&
                        <InviteEmployees
                            open={isInviteColleaguesOpen}
                            onClose={closeInviteColleagues}
                        />
                }
            </Card>
        </div>;
    };

export default observer(Content);
