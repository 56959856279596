import { ReactElement } from 'react';
import uuid from '../../../../@Util/Id/uuid';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { FeedbackStore } from '../../../../@Component/App/Root/Environment/Organization/Feedback/FeedbackStore';

export default function openDialog(dialogConstructor: (closeDialog: () => void) => ReactElement)
{
    const id = uuid();
    const feedbackStore = loadModuleDirectly(FeedbackStore);

    const dialog =
        dialogConstructor(
            () =>
                feedbackStore.closeDialogById(id));

    feedbackStore.openDialog({
        id: id,
        content: dialog
    });
}
