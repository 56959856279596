import * as React from 'react';
import { BaseComponent } from '../../../../../../@Framework/Component/BaseComponent';
import { EntityFieldComputationEditorStore } from './EntityFieldComputationEditorStore';
import { observer } from 'mobx-react';
import { Button, Card, ClickAwayListener, Grow } from '@material-ui/core';
import { PopperReference } from '../../../../../Generic/Popper/PopperReference';
import { PopperContainer } from '../../../../../Generic/Popper/PopperContainer';
import { PopperManager } from '../../../../../Generic/Popper/PopperManager';
import FieldPathSelector from '../../../../Entity/Path/FieldPathEditor/FieldPathSelector';

@observer
export class EntityFieldComputationEditor extends BaseComponent<EntityFieldComputationEditorStore>
{
    renderComponent(store: EntityFieldComputationEditorStore)
    {
        return <PopperManager>
            <PopperReference>
                <Button
                    color="primary"
                    onClick={store.openPathEditor}
                >
                    {
                        store.entityFieldPath &&
                            store.entityFieldPath.getAdministrativeName(
                                store.entityTypeStore,
                                false)
                    }
                </Button>
            </PopperReference>
            <PopperContainer
                placement="bottom-start"
                eventsEnabled={store.isPathEditorOpen}
                style={{zIndex: 10}}
            >
                {
                    store.isPathEditorOpen &&
                        <ClickAwayListener onClickAway={store.closePathEditor}>
                            <Grow in={true}>
                                <Card>
                                    <FieldPathSelector
                                        context={store.context.entityContext}
                                        parameterId={store.entityContextParameter}
                                        onSelect={store.setPath}
                                    />
                                    {/*<Menu*/}
                                    {/*    store={store.entityFieldPathPickerStore}*/}
                                    {/*/>*/}
                                </Card>
                            </Grow>
                        </ClickAwayListener>
                }
            </PopperContainer>
        </PopperManager>;
    }
}
