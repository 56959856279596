import Predicate from '../../../Automation/Function/Computation/Predicate/Predicate';
import AutomationDependencyContext from '../../../Automation/AutomationDependencyContext';
import { EntityRelationshipDefinition } from '../../../Model/Implementation/EntityRelationshipDefinition';
import getRelationshipDefinitionFilterParameterDictionary from './getRelationshipDefinitionFilterParameterDictionary';
import getPredicateFromDescriptor from '../../../Automation/Api/getPredicateFromDescriptor';
import Parameter from '../../../Automation/Parameter/Parameter';
import EntityValueType from '../../../Automation/Value/Type/EntityValueType';
import { BaseEntityParameterId, RelatedEntityParameterId } from './RelationshipDefinitionFilterConstants';

export interface FilterWithContext
{
    filter: Predicate;
    baseParameter: Parameter<EntityValueType>;
    relatedParameter: Parameter<EntityValueType>;
    context: AutomationDependencyContext;
}

export default async function getRelationshipDefinitionFilterFromDescriptor(relationshipDefinition: EntityRelationshipDefinition,
                                                                            isParent: boolean,
                                                                            descriptor: any): Promise<FilterWithContext>
{
    const parameterDictionary =
        getRelationshipDefinitionFilterParameterDictionary(
            relationshipDefinition,
            isParent);
    const context = new AutomationDependencyContext(parameterDictionary);
    const baseParameter = parameterDictionary.getParameterById(BaseEntityParameterId);
    const relatedParameter = parameterDictionary.getParameterById(RelatedEntityParameterId);
    const filter =
        await getPredicateFromDescriptor(
            descriptor,
            context);

    return {
        filter,
        baseParameter,
        relatedParameter,
        context,
    };
}
