import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { FieldGroupItemProps } from '../FieldGroupItem';
import Switch from '../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import { isFieldGroupExpandedByDefault } from '../../../../../../../../@Api/Metadata/FieldGroup/isFieldGroupExpandedByDefault';
import { setFieldGroupExpandedByDefault } from '../../../../../../../../@Api/Metadata/FieldGroup/setFieldGroupExpandedByDefault';
import useSwitch from '../../../../../../../../@Util/Switch/useSwitch';
import Popper from '../../../../../../../../@Future/Component/Generic/Popper/Popper';
import IconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import { primaryColor, textSecondaryColor } from '../../../../../../../../@Resource/Theme/Theme';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import FieldGroupExpansionPredicateEditor from './FieldGroupExpansionPredicateEditor';
import { useFieldGroupExpandedPredicate } from '../../../../../../../../@Api/Metadata/FieldGroup/useFieldGroupExpandedPredicate';

export interface FieldGroupExpansionEditorProps extends FieldGroupItemProps
{

}

const FieldGroupExpansionEditor: React.FC<FieldGroupExpansionEditorProps> =
    props =>
    {
        const { entityType, fieldGroup } = props;
        const isExpanded =
            useComputed(
                () =>
                    isFieldGroupExpandedByDefault(
                        entityType,
                        fieldGroup,
                        false
                    ),
                [
                    entityType,
                    fieldGroup
                ]);
        const setExpanded =
            useCallback(
                async (isExpanded: boolean) =>
                    setFieldGroupExpandedByDefault(
                        entityType,
                        fieldGroup,
                        isExpanded),
                [
                    entityType,
                    fieldGroup
                ]);

        const fieldGroupSettingPredicate = useFieldGroupExpandedPredicate(fieldGroup);

        const [ isOpen, open, close ] = useSwitch(false);

        if (fieldGroup)
        {
            return <>
                {
                    fieldGroupSettingPredicate
                        ? null
                        : <Switch
                            checked={isExpanded}
                            onToggle={setExpanded}
                        />
                }
                <Popper
                    inline
                    reference={
                        <IconButton
                            icon="edit"
                            onClick={open}
                            tooltip={
                                <LocalizedText
                                    code="Generic.Edit"
                                />
                            }
                            color={fieldGroupSettingPredicate ? primaryColor : textSecondaryColor}
                        />
                    }
                    popper={
                        <Card
                            inset
                        >
                            <FieldGroupExpansionPredicateEditor
                                {...props}
                                predicate={fieldGroupSettingPredicate}
                                onClose={close}
                            />
                        </Card>
                    }
                    open={isOpen}
                    onClose={close}
                />
            </>;
        }
        else
        {
            return null;
        }
    };

export default observer(FieldGroupExpansionEditor);
