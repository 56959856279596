import React, { useState } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import TabBar from '../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import { Grid } from '@material-ui/core';
import PortalInvitationEmailLayoutEditor from './InvitationEmailLayoutEditor/PortalInvitationEmailLayoutEditor';
import PortalPasswordResetEmailLayoutEditor from './PasswordResetEmailLayoutEditor/PortalPasswordResetEmailLayoutEditor';
import { GeneralPortalWhiteLabelSettings } from './General/GeneralPortalWhiteLabelSettings';

export interface PortalWhiteLabelSettingsProps
{
    entity: Entity;
    commitContext?: CommitContext;
}

export const PortalWhiteLabelSettings: React.FC<PortalWhiteLabelSettingsProps> =
    observer(
        ({
             entity,
             commitContext,
         }) =>
        {
            const [ selectedTab, setSelectedTab ] = useState<string>('General');

            return <Grid
                container
            >
                <Grid
                    item
                    xs={12}
                >
                    <Card>
                        <TabBar
                            value={selectedTab}
                        >
                            <Tab
                                value="General"
                                onClick={setSelectedTab}
                            >
                                <LocalizedText
                                    code="Generic.General"
                                    value="Algemeen"
                                />
                            </Tab>
                            <Tab
                                value="InvitationEmailLayout"
                                onClick={setSelectedTab}
                            >
                                <LocalizedText
                                    code="Portal.InvitationEmailLayout"
                                    value="Uitnodiging"
                                />
                            </Tab>
                            <Tab
                                value="PasswordResetEmailLayout"
                                onClick={setSelectedTab}
                            >
                                <LocalizedText
                                    code="Portal.PasswordResetEmailLayout"
                                    value="Wachtwoord reset e-mail"
                                />
                            </Tab>
                        </TabBar>
                    </Card>
                </Grid>
                {
                    selectedTab === 'General' &&
                    <Grid
                        item
                        xs={12}
                    >
                        <GeneralPortalWhiteLabelSettings
                            entity={entity}
                            commitContext={commitContext}
                        />
                    </Grid>
                }
                {
                    selectedTab === 'InvitationEmailLayout' &&
                    <Grid
                        item
                        xs={12}
                    >
                        <PortalInvitationEmailLayoutEditor
                            entity={entity}
                            commitContext={commitContext}
                        />
                    </Grid>
                }
                {
                    selectedTab === 'PasswordResetEmailLayout' &&
                    <Grid
                        item
                        xs={12}
                    >
                        <PortalPasswordResetEmailLayoutEditor
                            entity={entity}
                            commitContext={commitContext}
                        />
                    </Grid>
                }
            </Grid>
        }
    );