import { EntityTypeStore } from '../../../../../Domain/Entity/Type/EntityTypeStore';
import { CurrentUserStore } from '../../../../../Domain/User/CurrentUserStore';
import { SettingStore } from '../../../../../Domain/Setting/SettingStore';
import { EntityEventTypeStore } from '../../../../../Domain/Entity/Event/EntityEventTypeStore';
import { injectWithQualifier, Module, ModuleContext, ModuleManager } from '../../../../../../@Util/DependencyInjection/index';
import { action, observable } from 'mobx';
import { SidebarStore } from '../../../../../Generic/Sidebar/SidebarStore';
import { RouterStore } from '../../../../../../@Service/Router/RouterStore';
import { AuthenticationManager } from '../../../../../../@Service/Authentication/AuthenticationManager';
import { DrawerStore } from '../../../../../Generic/Drawer/DrawerStore';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { WelcomeStore } from '../../../../../../@Service/Welcome/WelcomeStore';
import { ApiClient } from '../../../../../../@Service/ApiClient/ApiClient';
import DynamicFunctionRepositoryManager from '../../../../../../@Api/Automation/Function/Dynamic/Repository/DynamicFunctionRepositoryManager';
import ServerFunctionRepository from '../../../../../../@Api/Automation/Function/Dynamic/Repository/Server/ServerFunctionRepository';

export class OrganizationEnvironmentContext extends ModuleContext
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('RouterStore') routerStore: RouterStore;
    @injectWithQualifier('AuthenticationManager') authenticationManager: AuthenticationManager;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable.ref settingStore: SettingStore;
    @observable.ref entityTypeStore: EntityTypeStore;
    @observable.ref entityEventTypeStore: EntityEventTypeStore;
    @observable.ref currentUserStore: CurrentUserStore;
    @observable.ref apiClient: ApiClient;
    @observable.ref leftDrawerStore: DrawerStore;
    @observable.ref rightDrawerStore: DrawerStore;
    @observable.ref sidebarStore: SidebarStore;
    @observable.ref dynamicFunctionRepositoryManager: DynamicFunctionRepositoryManager;

    // ------------------------ Constructor -------------------------

    constructor(moduleManager: ModuleManager)
    {
        super(moduleManager);

        this.entityTypeStore = moduleManager.getInstance(EntityTypeStore);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    initializeModules()
    {
        return Promise.resolve();
    }

    // ------------------------ Public logic ------------------------

    protected defineModules() {
        // Setup self
        this.moduleManager.define(new Module(this, [], 'AppContext'));

        // Setup setting factory
        this.settingStore = new SettingStore();
        this.moduleManager.define(
            new Module(this.settingStore, [WelcomeStore], "SettingStore"),
            instance => instance.initializeStore()
        );

        // Setup entity event type store
        this.entityEventTypeStore = new EntityEventTypeStore();
        this.moduleManager.define(new Module(this.entityEventTypeStore, [], 'EntityEventTypeStore'));

        // Setup user factory
        this.currentUserStore = new CurrentUserStore();
        this.moduleManager.define(
            new Module(this.currentUserStore, [EntityTypeStore, SettingStore], 'CurrentUserStore'),
            instance => instance.initializeStore());

        // Setup drawer view store
        this.leftDrawerStore = new DrawerStore(true);
        this.rightDrawerStore = new DrawerStore(false);
        this.moduleManager.define(new Module(this.leftDrawerStore, [], 'LeftDrawerStore'));
        this.moduleManager.define(new Module(this.rightDrawerStore, [], 'RightDrawerStore'));

        // Setup sidebar view store
        this.sidebarStore = new SidebarStore();
        this.moduleManager.define(new Module(this.sidebarStore, [], 'SidebarStore'));

        // Setup dynamic action repository manager
        this.dynamicFunctionRepositoryManager =
            new DynamicFunctionRepositoryManager([
                new ServerFunctionRepository()
            ]);
        this.moduleManager.define(new Module(this.dynamicFunctionRepositoryManager, [], 'DynamicFunctionRepositoryManager'));
    }

    // ----------------------- Private logic ------------------------
}
