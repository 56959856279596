import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { default as InternalAgenda } from '../../../Agenda/Agenda';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import AgendaButton from '../../../Agenda/Button/AgendaButton';
import { WidgetProps } from '../Widget';
import AgendaWidget from '../../Model/Widget/Agenda/AgendaWidget';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import PlanButton from '../../../Agenda/PlanButton/PlanButton';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import { removeValuesFromStorage } from '../../../../Setting/Api/useLocalSetting';

export interface AgendaProps extends WidgetProps<AgendaWidget>
{

}

const Agenda: React.FC<AgendaProps> =
    props =>
    {
        const settingStorageKey =
            useMemo(
                () =>
                    `Widget.${props.widget.id}.Agenda`,
                [
                    props.widget.id
                ]
            );

        const onDelete =
            useCallback(
                () =>
                {
                    removeValuesFromStorage(settingStorageKey);
                    if (props.onDelete)
                    {
                        props.onDelete();
                    }
                },
                [
                    props.onDelete,
                    settingStorageKey,
                ]
            );

        return <Card>
            <DragHandle>
                <CardInset>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            <CardHeader>
                                <LocalizedText
                                    code="Generic.Agenda"
                                    value="Agenda"
                                />
                            </CardHeader>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <AgendaButton
                                settingStorageKey={settingStorageKey}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <PlanButton />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <MenuButton>
                                <Menu>
                                    <DeleteItem
                                        onClick={onDelete}
                                    />
                                </Menu>
                            </MenuButton>
                        </ViewGroupItem>
                    </ViewGroup>
                </CardInset>
            </DragHandle>
            <CardInset
                top={false}
            >
                <InternalAgenda
                    settingStorageKey={settingStorageKey}
                />
            </CardInset>
        </Card>;
    };

export default observer(Agenda);
