import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Parameter from '../../Parameter/Parameter';
import CollectionType from '../../Value/Type/CollectionType';
import Value from '../../Value/Value';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import CollectionValue from '../../Value/CollectionValue';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import getDependenciesWithoutParameters from '../../Api/getDependenciesWithoutParameters';
import localizeText from '../../../Localization/localizeText';

export default class MappedCollectionComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref collection: Computation<any, any>;
    @observable.ref elementParameter: Parameter<any>;
    @observable.ref expression: Computation<any, any>;

    // ------------------------ Constructor -------------------------


    constructor(
        collection: Computation<any, any>,
        elementParameter: Parameter<any>,
        expression: Computation<any, any>
    )
    {
        super();

        this.collection = collection;
        this.elementParameter = elementParameter;
        this.expression = expression;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new CollectionType(
            this.expression.getType()
        );
    }

    isAsync(): boolean
    {
        return this.collection.isAsync()
            || this.expression.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const collection = await safelyApplyFunction(this.collection, context);

        if (collection instanceof CollectionValue)
        {
            const elementsInCollection = CollectionValue.getCollection(collection);
            const expressionParameters =
                context.parameterDictionary.getNewDictionaryWithParameter(
                    this.elementParameter);
            const mappedElements =
                await Promise.all(
                    elementsInCollection.map(
                        element =>
                            safelyApplyFunction(
                                this.expression,
                                new FunctionContext(
                                    expressionParameters,
                                    context.parameterAssignment.getNewAssignmentWithParameter(
                                        this.elementParameter,
                                        element
                                    ),
                                    context.commitContext
                                )
                            )
                    )
                );

            return new CollectionValue(
                mappedElements,
                collection.elementType
            );
        }
        else
        {
            throw new Error(`Expected collection but got ${collection.getName()}`);
        }
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        const collection = safelySynchronousApplyFunction(this.collection, context);

        if (collection instanceof CollectionValue)
        {
            const elementsInCollection = CollectionValue.getCollection(collection);
            const expressionParameters =
                context.parameterDictionary.getNewDictionaryWithParameter(
                    this.elementParameter
                );

            return new CollectionValue(
                elementsInCollection.map(
                    (element) =>
                        safelySynchronousApplyFunction(
                            this.expression,
                            new FunctionContext(
                                expressionParameters,
                                context.parameterAssignment.getNewAssignmentWithParameter(
                                    this.elementParameter,
                                    element
                                ),
                                context.commitContext
                            )
                        )
                ),
                collection.elementType
            );
        }
        else
        {
            throw new Error(`Expected collection but got ${collection.getName()}`);
        }
    }

    getName(): string
    {
        return localizeText(
            'MappedCollection',
            'MappedCollection'
        );
    }

    validate(): Validation[]
    {
        return [
            ...this.collection.validate(),
            ...(this.expression?.validate() || [])
        ];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'MappedCollection';
        descriptor.collection = this.collection.toDescriptor();
        descriptor.elementParameterId = this.elementParameter.id;
        descriptor.expression = this.expression.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.collection.getDependencies(),
            ...getDependenciesWithoutParameters(
                this.expression?.getDependencies() ?? [],
                this.elementParameter
            )
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const collection = await getComputationFromDescriptor(descriptor.collection, dependencyContext);
        const collectionType = collection.getType();

        if (collectionType instanceof CollectionType)
        {
            const elementParameter =
                MappedCollectionComputation.getElementParameter(
                    collection,
                    descriptor.elementParameterId
                );
            const expression =
                await getComputationFromDescriptor(
                    descriptor.expression,
                    new AutomationDependencyContext(
                        dependencyContext.parameterDictionary.getNewDictionaryWithParameter(elementParameter)
                    )
                );

            return new MappedCollectionComputation(
                collection,
                elementParameter,
                expression
            );
        }
        else
        {
            throw new Error(`Expected collection but got ${collectionType.getName()}`);
        }
    }

    static getElementParameter(collection: Computation<any, any>,
                               id: string)
    {
        return new Parameter(
            id,
            (collection.getType() as CollectionType<any>).type,
            true,
            `Element uit collectie`
        );
    }

    // ----------------------- Private logic ------------------------
}
