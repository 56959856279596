import { BaseStore, CallbackType, getOrCompute, PropType } from '../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { ChangeEvent } from 'react';
import { ButtonStore } from '../Button/ButtonStore';
import { injectWithQualifier } from '../../../@Util/DependencyInjection/index';
import { LocalizationStore } from '../../../@Service/Localization/LocalizationStore';
import { ViewComponent } from '../ViewStack/Model/ViewComponent';
import Text from '../Text/Text';
import { TextStore } from '../Text/TextStore';
import { smallViewInset, viewInset } from '../../../@Resource/Theme/Theme';

export interface SearchBarProps<D>
{
    initialValue?: string;
    isExpandable?: PropType<SearchBarStore, SearchBarProps<D>, boolean>;
    isAutoFocus?: PropType<SearchBarStore, SearchBarProps<D>, boolean>;
    panelContent?: PropType<SearchBarStore, SearchBarProps<D>, ViewComponent>;
    isLightColor?: () => boolean;
    onUpdate?: (query: string) => void;
    onActivate?: CallbackType<SearchBarStore, SearchBarProps<D>, void>;
    load?: (query: string) => Promise<D>;
    showEmptyLabel?: boolean;
}

const defaultProps: Partial<SearchBarProps<any>> =
{
    isLightColor: () => false,
    initialValue: '',
    isExpandable: false,
    showEmptyLabel: false
};

export class SearchBarStore<D = any> extends BaseStore<SearchBarProps<D>>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable query: string = '';
    @observable isActivated: boolean = false;
    @observable delayedQuery: string = '';
    @observable searchTimeout: any;
    @observable isExpanded: boolean;
    @observable isLoadingSearch: boolean;
    @observable result: D;

    // ------------------------ Constructor -------------------------

    constructor(props: SearchBarProps<D>)
    {
        super(props, defaultProps);

        this.query = this.props.initialValue || '';
        this.isExpanded = this.isExpandable ? false : true;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get hasQuery(): boolean
    {
        return this.query.length > 0;
    }

    @computed
    get hasResult(): boolean
    {
        return !this.isEmpty;
    }

    @computed
    get hasDelayedQuery(): boolean
    {
        return this.delayedQuery.length > 0;
    }

    @computed
    get isLightColor(): boolean
    {
        return this.props.isLightColor();
    }

    @computed
    get isExpandable(): boolean
    {
        return getOrCompute(this, this.props.isExpandable);
    }

    @computed
    get isAutoFocus(): boolean
    {
        return getOrCompute(this, this.props.isAutoFocus);
    }

    @computed
    get placeholderText(): string
    {
        return `${this.localizationStore.translate('Generic.Search')}...`; // Search
    }

    @computed
    get panelContent(): ViewComponent
    {
        if (this.showEmptyLabel)
        {
            return new ViewComponent(
                Text as any,
                new TextStore({
                    label: () => `${this.localizationStore.translate('Search.Label.NoSearchResultsFound', this.query)}`,
                    style:
                    {
                        paddingTop: smallViewInset,
                        paddingLeft: viewInset,
                        paddingRight: viewInset,
                        paddingBottom: smallViewInset
                    }
                }));
        }
        else
        {
            if (this.hasDelayedQuery)
            {
                return undefined;
            }
            else
            {
                return getOrCompute(this, this.props.panelContent);
            }
        }
    }

    @computed
    get isEmpty(): boolean
    {
        return this.result === undefined
            || (this.result as any).length === 0;
    }

    @computed
    get showEmptyLabel(): boolean
    {
        return this.props.showEmptyLabel
            && this.hasDelayedQuery
            && !this.isLoadingSearch
            && this.isEmpty;
    }

    @computed
    get hasPanel(): boolean
    {
        return this.panelContent !== undefined;
    }

    @computed
    get isPanelVisible(): boolean
    {
        return (this.hasPanel
            && this.isActivated
            && !this.isLoadingSearch
            && this.isEmpty);
    }

    // --------------------------- Stores ---------------------------

    @computed
    get expandButtonStore(): ButtonStore
    {
        return new ButtonStore({
            icon: 'search',
            color:
                this.isLightColor
                    ?
                        undefined
                    :
                        '#ffffff33',
            isVisible:
                () => this.isExpandable && !this.isExpanded,
            onClick: this.showExpansion
        });
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    updateQuery(event: ChangeEvent<HTMLInputElement>)
    {
        this.query = event.target.value;
        this.setLoading(true);

        if (this.searchTimeout != null)
        {
            clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = setTimeout(() =>
        {
            this.setDelayedQuery(this.query.trim());
        }, 800);
    }

    @action.bound
    activate()
    {
        this.isActivated = true;

        if (this.props.onActivate)
        {
            this.props.onActivate(this);
        }
    }

    @action.bound
    deactivate()
    {
        this.isActivated = false;
    }

    @action.bound
    clickAway()
    {
        if (!this.hasQuery)
        {
            this.deactivate();
        }
    }

    @action.bound
    blur()
    {

    }

    @action
    setDelayedQuery(query: string)
    {
        this.delayedQuery = query;

        if (this.props.onUpdate)
        {
            this.props.onUpdate(query);
        }

        return this.triggerLoad(query);
    }

    @action.bound
    clear()
    {
        this.delayedQuery = '';
        this.query = '';

        this.setResult(undefined);

        if (this.isExpandable && this.isExpanded)
        {
            this.hideExpansion();
        }

        this.deactivate();
    }

    @action.bound
    showExpansion()
    {
        this.isExpanded = true;
    }

    @action.bound
    hideExpansion()
    {
        this.isExpanded = false;
    }

    @action.bound
    triggerLoad(query: string)
    {
        if (this.props.load)
        {
            if (query.length === 0)
            {
                this.setResult(undefined);
                this.setLoading(false);
            }
            else
            {
                return this.props.load(query)
                    .then(this.setResult)
                    .then(() => this.setLoading(false))
                    .catch(() => this.setLoading(false));
            }
        }
        else
        {
            this.setLoading(false);

            return Promise.resolve();
        }
    }

    @action.bound
    setLoading(isLoading: boolean)
    {
        this.isLoadingSearch = isLoading;
    }

    @action.bound
    setResult(result: D)
    {
        this.result = result;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
