import * as React from 'react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { observer } from 'mobx-react';
import { FileViewerLightboxStore } from './FileViewerLightboxStore';
import Lightbox from '../../Lightbox/Lightbox';
import FileViewer from '../FileViewer';
import { SupportedPreviewImageMimeTypes } from '../../../Domain/DataObject/Type/File/FileUtils';

@observer
export class FileViewerLightbox extends BaseComponent<FileViewerLightboxStore>
{
    renderComponent(store: FileViewerLightboxStore)
    {
        return <div>
            {
                (SupportedPreviewImageMimeTypes.has(store.mimeType?.toLowerCase())
                    || store.mimeType?.endsWith('pdf')) &&
                    <Lightbox
                        store={store.lightboxStore}
                    >
                        <FileViewer
                            url={store.fileUrl}
                            type={SupportedPreviewImageMimeTypes.has(store.mimeType?.toLowerCase()) ? 'image' : 'pdf'}
                        />
                    </Lightbox>
            }
        </div>;
    }
}
