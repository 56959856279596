import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import EntityTypeContext from '../../../../Entity/Type/EntityTypeContext';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import { runInAction } from 'mobx';
import { createTransactionalModel, getModel, TransactionalModel } from '../../../../../../@Util/TransactionalModelV2/index';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import Dataset from '../../../../Entity/Dataset/Model/Dataset';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { DatasetSelector } from '../../../../Entity/Dataset/DatasetSelector/DatasetSelector';
import useTransactionalEntity from '../../../../../../@Api/Entity/Bespoke/useTransactionalEntity';
import { default as EntityMenuButton } from '../../../../Entity/Item/MenuButton/MenuButton';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import deleteEntity from '../../../../../../@Api/Entity/deleteEntity';
import getEntityByUuid from '../../../../../../@Api/Entity/Bespoke/getEntityByUuid';
import DatasetInternalEditor from './DatasetInternalEditor';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import getViewParameters from '../../../../Entity/View/Api/getViewParameters';
import { ViewParams } from '../../../../Entity/View/Model/ViewParams';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import isMutable from '../../../../../../@Api/RightProfile/isMutable';

export interface DatasetEditorProps
{
    entityType: EntityType;
    datasetId?: string;
}

const DatasetEditor: React.FC<DatasetEditorProps> =
    props =>
    {
        const { entityType, datasetId } = props;
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);
        const entityTypeStore = useContext(EntityTypeContext);

        const [ datasetEntity, setDatasetEntity ] = useState<Entity | undefined>();
        const transactionalDatasetEntity = useTransactionalEntity(datasetEntity);

        const [ dataset ] =
            useAsyncResult<Dataset>(
                async () =>
                {
                    if (transactionalDatasetEntity)
                    {
                        const parameters = getViewParameters(entityType);
                        const specification = transactionalDatasetEntity.getObjectValueByField(types.Dataset.Field.Specification);

                        if (specification)
                        {
                            return Dataset.fromDescriptor(
                                specification,
                                transactionalDatasetEntity)
                                .catch(
                                    e =>
                                    {
                                        console.error('Error in dataset with ID: ' + transactionalDatasetEntity.id, e);

                                        return Promise.resolve(undefined);
                                    });
                        }
                        else
                        {
                            return new Dataset(
                                props.entityType,
                                parameters.getParameterById(ViewParams.Entity),
                                parameters,
                                [],
                                undefined,
                                undefined,
                                undefined,
                                transactionalDatasetEntity);
                        }
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    entityType,
                    transactionalDatasetEntity,
                    types
                ]);

        const saveDataset =
            useCallback(
                (datasetEntity: TransactionalModel<Entity>) =>
                {
                    if (datasetEntity.isNew())
                    {
                        datasetEntity.updateRelationship(
                            true,
                            types.EntityType.RelationshipDefinition.Datasets,
                            createTransactionalModel(dataset.entityType.entity));
                    }

                    datasetEntity.setValueByField(
                        types.Dataset.Field.Specification,
                        dataset.toDescriptor());

                    return datasetEntity.checkAndDoCommit(false, true)
                        .then(
                            () =>
                                runInAction(
                                    () =>
                                        dataset.entity = getModel(datasetEntity)));
                },
                [
                    types,
                    dataset
                ]);

        const saveAsNew =
            useCallback(
                () =>
                {
                    const newDataset =
                        createTransactionalModel(
                            new Entity(types.Dataset.Type)
                                .initialize(entityTypeStore));

                    newDataset.setValueByField(
                        types.Dataset.Field.LocalizedName,
                        transactionalDatasetEntity.getObjectValueByField(types.Dataset.Field.LocalizedName));

                    return saveDataset(newDataset);
                },
                [
                    types,
                    entityTypeStore,
                    saveDataset,
                    transactionalDatasetEntity
                ]);

        const save =
            useCallback(
                () =>
                {
                    if (transactionalDatasetEntity)
                    {
                        return saveDataset(transactionalDatasetEntity);
                    }
                    else
                    {
                        return saveAsNew();
                    }
                },
                [
                    transactionalDatasetEntity,
                    saveDataset,
                    saveAsNew
                ]);

        const onDelete =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            setDatasetEntity(undefined);
                        }),
                [
                    setDatasetEntity
                ]);

        const deleteDataset =
            useCallback(
                () =>
                {
                    return deleteEntity(transactionalDatasetEntity)
                        .then(
                            () =>
                                onDelete());
                },
                [
                    transactionalDatasetEntity,
                    onDelete
                ]);


        useEffect(
            () =>
            {
                if (datasetId)
                {
                    getEntityByUuid(
                        types.Dataset.Type,
                        datasetId
                    )
                    .then(
                        ({ value: dataset }) =>
                            setDatasetEntity(dataset)
                    );
                }
            },
            [
                datasetId,
                types,
                setDatasetEntity
            ]);

        const isDatasetPrivate =
            useMemo(
                () =>
                    datasetEntity
                    && datasetEntity.hasValueForField(types.Dataset.Field.IsPrivate)
                    && datasetEntity.getObjectValueByField<boolean>(types.Dataset.Field.IsPrivate),
                [
                    types,
                    datasetEntity
                ]);

        const isCurrentUserDatasetOwner =
            useMemo(
                () =>
                    datasetEntity
                    && datasetEntity.getRelatedEntitiesByDefinition(
                        false,
                        types.Dataset.RelationshipDefinition.Owners)
                        .some(
                            owner =>
                                owner.id === currentUserStore.employeeEntity.id
                        ),
                [
                    types,
                    datasetEntity,
                    currentUserStore.employeeEntity
                ]);

        const isEditable =
            useMemo(
                () =>
                    datasetEntity
                    && isMutable(datasetEntity)
                    && (!isDatasetPrivate || isCurrentUserDatasetOwner),
                [
                    datasetEntity,
                    isDatasetPrivate,
                    isCurrentUserDatasetOwner
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <CardInset>
                    <ViewGroup
                        orientation="vertical"
                        spacing={5}
                    >
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                            >
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <Input
                                        label={
                                            <LocalizedText
                                                code="Configuration.Dataset.SelectedDataset"
                                                value="Geselecteerde widget"
                                            />
                                        }
                                        labelPosition="left"
                                    >
                                        <DatasetSelector
                                            key={transactionalDatasetEntity === undefined ? 'undefined' : transactionalDatasetEntity.uuid}
                                            entityType={props.entityType}
                                            value={transactionalDatasetEntity}
                                            onChange={setDatasetEntity}
                                        />
                                    </Input>
                                </ViewGroupItem>
                                {
                                    transactionalDatasetEntity && isEditable &&
                                        <ViewGroupItem>
                                            <EntityMenuButton
                                                entity={transactionalDatasetEntity}
                                                onDelete={onDelete}
                                            />
                                        </ViewGroupItem>
                                }
                            </ViewGroup>
                        </ViewGroupItem>
                        {
                            dataset && transactionalDatasetEntity &&
                                <ViewGroupItem>
                                    <DatasetInternalEditor
                                        datasetEntity={transactionalDatasetEntity}
                                        dataset={dataset}
                                        onSave={save}
                                        onSaveAsNew={saveAsNew}
                                        onDelete={deleteDataset}
                                    />
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                </CardInset>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(DatasetEditor);
