import { EntityContext } from '../Entity/@Model/EntityContext';
import { ComputationTypeStore } from '../Computation/ComputationTypeStore';
import { ExpressionContext } from './ExpressionContext';
import { ExpressionSpecification } from './Model/ExpressionSpecification';
import * as cheerio from 'cheerio';
import { DataObject } from '../DataObject/Model/DataObject';
import { EntityFieldPath } from '../Entity/Path/@Model/EntityFieldPath';

export function express(computationTypeStore: ComputationTypeStore,
                        expressionContext: ExpressionContext,
                        expressionSpecification: ExpressionSpecification,
                        allowHtml: boolean = true): string
{
    let html = cheerio.load(expressionSpecification.expression, { decodeEntities: false });

    let computations = html('computation');

    while (computations.length > 0)
    {
        let computationElement = computations.first();
        let computationId = computationElement.attr('data-id');
        let computationValue: DataObject;
        let computationSpecification =
            expressionSpecification.computations.find(
                computation => computation.id === computationId);

        if (computationSpecification)
        {
            computationValue = computationTypeStore.computeFromSpecification(expressionContext, computationSpecification.computation);
        }

        let computationString = '';

        if (computationValue)
        {
            computationString =
                computationValue.toString()
                    .replace(/(?:\r\n|\r|\n)/g, '<br />');
        }

        computationElement.replaceWith(`<span class="computed">${computationString}</span>`);

        computations = html('computation');
    }

    let contents = html('body');

    if (allowHtml)
    {
        return contents.html();
    }
    else
    {
        return contents.text();
    }
}

export function getDependencies(computationTypeStore: ComputationTypeStore,
                                expressionSpecification: ExpressionSpecification,
                                context: EntityContext,
                                parameter?: string): EntityFieldPath[]
{
    return expressionSpecification.computations
        .map(
            namedComputation =>
            {
                return computationTypeStore.getTypeById(namedComputation.computation.type)
                    .entityFieldPaths(
                        namedComputation.computation,
                        context,
                        parameter);
            })
        .reduce((a, b) => a.concat(b), []);
}
