import { Currency } from '../../../../../../../../@Api/Localization/Currency';
import { EntitySelectionBuilder } from '../../../../../Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import getTypes from '../../../../../Type/Api/getTypes';

export default async function getDefaultPriceList(
    currency: Currency,
): Promise<Entity | undefined>
{
    const types = getTypes();

    return new EntitySelectionBuilder(
        types.PriceList.Type
    )
        .where(
            cb =>
                cb.eq(
                    EntityPath.fromEntityType(types.PriceList.Type)
                        .field(types.PriceList.Field.IsDefaultForCurrency),
                    undefined,
                    true
                )
        )
        .where(
            cb =>
                cb.eq(
                    EntityPath.fromEntityType(types.PriceList.Type)
                        .field(types.PriceList.Field.Currency),
                    undefined,
                    currency.code
                )
        )
        .where(
            cb =>
                cb.eq(
                    EntityPath.fromEntityType(types.PriceList.Type)
                        .field(types.PriceList.Field.Name),
                    undefined,
                    currency.code
                )
        )
        .select(0, 1)
        .then(
            results =>
                Promise.resolve(
                    results
                        .map(
                            result =>
                                result.entity
                        )
                        .find(() => true))
        )
        .catch(
            () =>
                Promise.resolve(undefined)
        );
    }