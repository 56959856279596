import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import getTypes from '../../Type/Api/getTypes';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { getSearchTermsFromQuery } from "../../../../../@Util/Search/getSearchTermsFromQuery";
import { DataObject } from "../../../DataObject/Model/DataObject";

export function augmentDatasetSelectionBuilderWithEntityTypeFilter(
    builder: EntitySelectionBuilder,
    entityType: EntityType,
    allowInheritedTypes: boolean
): EntitySelectionBuilder
{
    const types = getTypes();

    return builder
    .if(
        () => !allowInheritedTypes,
        sb =>
            sb.where(
                cb =>
                    cb.relatedToEntity(
                        builder.rootPath
                        .joinTo(
                            types.EntityType.RelationshipDefinition.Datasets,
                            true
                        ),
                        entityType.entity
                    )
            )
    )
    .if(
        () => allowInheritedTypes,
        sb =>
            sb.where(
                cb =>
                    cb.or(
                        ob =>
                            entityType.getInheritedTypes()
                            .forEach(
                                type =>
                                    ob.relatedToEntity(
                                        types.Dataset.Type.path()
                                        .joinTo(
                                            types.EntityType.RelationshipDefinition.Datasets,
                                            true),
                                        type.entity
                                    )
                            )
                    )
            )
    )
}

export function augmentDatasetSelectionBuilderWithEmployeeFilter(
    builder: EntitySelectionBuilder,
    employee: Entity,
    expandWithMembership: boolean
): EntitySelectionBuilder
{
    const types = getTypes();

    return builder
    .if(
        () => expandWithMembership,
        sb =>
            sb
            .join(
                builder.rootPath
                .joinTo(
                    types.Dataset.RelationshipDefinition.Owners,
                    false
                )
            )
            .join(
                builder.rootPath
                .joinTo(
                    types.Dataset.RelationshipDefinition.SharedWithEmployees,
                    false
                )
            )
            .join(
                builder.rootPath
                .joinTo(
                    types.Dataset.RelationshipDefinition.SharedWithTeams,
                    false
                )
                .joinTo(
                    types.Team.RelationshipDefinition.Members,
                    false
                )
            )
    )
    .where(
        cb =>
            cb.or(
                ob =>
                    ob
                    // the dataset is not private
                    .neq(
                        builder.rootPath.field(types.Dataset.Field.IsPrivate),
                        undefined,
                        true
                    )
                    // current user is owner of the dataset
                    .relatedToEntity(
                        builder.rootPath
                        .joinTo(
                            types.Dataset.RelationshipDefinition.Owners,
                            false),
                        employee
                    )
                    // the dataset is shared with current user
                    .relatedToEntity(
                        builder.rootPath
                        .joinTo(
                            types.Dataset.RelationshipDefinition.SharedWithEmployees,
                            false),
                        employee
                    )
                    // the dataset is shared with current user's team
                    .relatedToEntity(
                        builder.rootPath
                        .joinTo(
                            types.Dataset.RelationshipDefinition.SharedWithTeams,
                            false)
                        .joinTo(
                            types.Team.RelationshipDefinition.Members,
                            false),
                        employee
                    )
            )
    )
    .orderBy(
        builder.rootPath.field(types.Dataset.Field.LocalizedName),
        true
    );
}

export function augmentDatasetSelectionBuilderWithQueryFilter(
    builder: EntitySelectionBuilder,
    query: string
): EntitySelectionBuilder
{
    const types = getTypes();

    return builder
    .if(
        () =>
            query !== undefined && query.length > 0,
        sb =>
            sb.where(
                cb =>
                    getSearchTermsFromQuery(query)
                    .forEach(
                        term =>
                            cb.contains(
                                builder.rootPath.field(types.Dataset.Field.LocalizedName),
                                undefined,
                                DataObject.constructFromTypeIdAndValue(
                                    'Text',
                                    term
                                )
                            )
                    )
            )
    );
}