import React, { useCallback, useEffect, useMemo } from 'react';
import useTypes from '../../Entity/Type/Api/useTypes';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { default as LabelInput } from '../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import StaticSelectbox, { StaticOption } from '../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import useSetting from '../../Setting/Api/useSetting';
import { SettingSource } from '../../Setting/SettingStore';
import { Setting } from '../../../../@Api/Settings/Setting';
import { observer, useComputed } from 'mobx-react-lite';

export interface DefinitionBuilderRelationshipTypeProps
{
    entityType: EntityType;
}

export const DefinitionBuilderRelationshipType: React.FC<DefinitionBuilderRelationshipTypeProps> =
    observer(
        (
            props
        ) =>
        {
            const types = useTypes();

            const rootRelationship: EntityType =
                useMemo(
                    () =>
                        props.entityType.isA(types.Relation.Organization.Type)
                            ? types.Relationship.Organization.Type
                            : props.entityType.isA(types.Relation.Person.Type)
                                ? types.Relationship.Person.Type
                                : types.Relationship.Type,
                    [
                        props
                    ]
                )

            const defaultRelationshipIds = useMemo<number[]>(
                () =>
                    [
                        rootRelationship.isA(types.Relationship.Organization.Type)
                            ? types.Relationship.Organization.Commercial.Customer.Type.id
                            : rootRelationship.isA(types.Relationship.Person.Type)
                                ? types.Relationship.Person.Commercial.Customer.Type.id
                                : types.Relationship.Person.Commercial.Customer.Type.id, types.Relationship.Organization.Commercial.Customer.Type.id
                    ],
                [
                    rootRelationship
                ])

            const [filterOption, setFilterOption] = useSetting<number[]>(SettingSource.Organization, Setting.DebtorNumberGeneratorEntityTypeIds);

            const organisationTypesToExclude =
                useMemo(
                    () =>
                        [
                            types.Relationship.Organization.Supplier.Type,
                            types.Relationship.Organization.Identity.Type,
                            types.Relationship.Organization.Commercial.Lead.Type,
                            types.Relationship.Organization.Commercial.HotProspect.Type,
                            types.Relationship.Organization.Commercial.Prospect.Type,
                            types.Relationship.Organization.Commercial.Suspect.Type,
                        ],
                    [
                        types
                    ])

            const personTypesToExclude =
                useMemo(
                    () =>
                        [
                            types.Relationship.Person.Contact.Standard.Type,
                            types.Relationship.Person.Contact.Employee.Type,
                            types.Relationship.Person.Contact.Employee.Trainee.Type,
                            types.Relationship.Person.Commercial.Lead.Type,
                            types.Relationship.Person.Commercial.HotProspect.Type,
                            types.Relationship.Person.Commercial.Prospect.Type,
                            types.Relationship.Person.Commercial.Suspect.Type
                        ],
                    [
                        types
                    ])

            const typesToExclude =
                useMemo<EntityType[]>(
                    () =>
                        rootRelationship.isA(types.Relationship.Organization.Type)
                            ? organisationTypesToExclude
                            : rootRelationship.isA(types.Relationship.Person.Type)
                                ? personTypesToExclude
                                : organisationTypesToExclude.concat(personTypesToExclude),
                    [
                        types,
                        rootRelationship
                    ])

            const relationships =
                useMemo<StaticOption<any>[]>(
                    () =>
                    {
                        return rootRelationship?.getAllInstantiableTypes(true, true)
                            .filter(
                                type =>
                                    !typesToExclude.includes(type)
                            )
                            .map(
                                type =>
                                    ({
                                        id: type.code,
                                        label: type.nameSingular,
                                        value: type.id
                                    })
                            )
                    },
                    [
                        types,
                        props.entityType,
                        rootRelationship,
                        typesToExclude
                    ])

            const selectedOptions =
                useComputed(
                    () =>
                        filterOption.filter(
                            id =>
                                relationships.map(option => option.value).includes(id)
                        ),
                    [
                        filterOption,
                        relationships
                    ]);

            const preservedIds =
                useMemo(
                    () =>
                        filterOption.filter(
                            id =>
                                !relationships.map(option => option.value).includes(id)
                        ),
                    [
                        relationships,
                        filterOption
                    ]);

            const onSelectedOptions =
                useCallback(
                    (selectedOptions: number[]) =>
                    {
                        const arrayWithDuplicates = [
                            ...selectedOptions,
                            ...preservedIds
                        ]
                        const distinctArray = arrayWithDuplicates.filter(
                            (n, i) =>
                                arrayWithDuplicates.indexOf(n) === i);

                        setFilterOption(
                            distinctArray
                        ).finally();
                    },
                [
                    preservedIds,
                    setFilterOption
                ]
            )

            useEffect(
                () =>
                    {
                        if (selectedOptions == null || selectedOptions.length === 0)
                        {
                            onSelectedOptions(defaultRelationshipIds)
                        }
                    }, [
                        selectedOptions,
                        defaultRelationshipIds,
                        onSelectedOptions
                    ]);

            return <ViewGroupItem>
                <LabelInput
                    label={
                        <LocalizedText
                            code="DefinitionBuilder.RelationshipType"
                            value="Relatietype"
                        />
                    }
                    labelPosition="left"
                >
                    <StaticSelectbox
                        options={relationships}
                        value={selectedOptions}
                        onChange={onSelectedOptions}
                        multi
                        clearable
                    />
                </LabelInput>
            </ViewGroupItem>;

        }
    );