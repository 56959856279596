import React from 'react';
import { observer } from 'mobx-react-lite';
import BaseLayout from '../../../../Entity/Viewer/Shared/BaseLayout/BaseLayout';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import CenteredBodyLayout from '../../../../../../@Api/Layout/Type/CenteredBodyLayout';

export interface CenteredContentViewProps
{
    layout: CenteredBodyLayout;
}

const CenteredBodyView: React.FC<CenteredContentViewProps> =
    props =>
    {
        if (props.layout.width)
        {
            return <Centered
                horizontal
            >
                <div
                    style={{
                        width: props.layout.width.toCss()
                    }}
                >
                    {props.children}
                </div>
            </Centered>;
        }
        else
        {
            return <BaseLayout>
                {props.children}
            </BaseLayout>;
        }
    };

export default observer(CenteredBodyView);
