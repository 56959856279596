import { observable } from 'mobx';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import TypeMapping from './TypeMapping';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';

export default class Mapping
{
    // ------------------------- Properties -------------------------

    @observable typeMappings: TypeMapping[];
    @observable entity?: Entity;

    // ------------------------ Constructor -------------------------

    constructor(typeMappings: TypeMapping[],
                entity?: Entity)
    {
        this.typeMappings = typeMappings;
        this.entity = entity;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext,
        entity?: Entity
    )
    {
        return new Mapping(
            await Promise.all(
                descriptor.typeMappings
                    .map(
                        typeMapping =>
                            TypeMapping.fromDescriptor(
                                typeMapping,
                                dependencyContext
                            )
                    )
            ),
            entity
        );
    }

    toDescriptor()
    {
        return {
            typeMappings:
                this.typeMappings.map(
                    typeMapping =>
                        typeMapping.toDescriptor())
        };
    }

    getAllTypeMappings(): TypeMapping[]
    {
        return this.typeMappings
            .map(
                typeMapping =>
                    typeMapping.getAllTypeMappings())
            .reduce((a, b) => a.concat(b), []);
    }

    // ----------------------- Private logic ------------------------
}
