import React, { useCallback } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import LabelButton from '../../../../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import useTypes from '../../../Type/Api/useTypes';
import { colorToCss } from '../../../../DataObject/Type/Color/ColorType';
import usePhaseRelationshipDefinition from '../../../../../../@Api/Entity/Bespoke/Datastore/Phase/usePhaseRelationshipDefinition';
import localize from '../../../../../../@Api/Localization/localize';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { grey } from '@material-ui/core/colors';

export interface StateButtonProps
{
    entity: Entity;
    state: Entity;
}

const StateButton: React.FC<StateButtonProps> =
    props =>
    {
        const types = useTypes();
        const phaseRelationshipDefinition = usePhaseRelationshipDefinition(props.entity.entityType);
        const setState =
            useCallback(
                (state: Entity) =>
                    new CommitBuilder()
                        .relateEntityTo(
                            props.entity,
                            false,
                            phaseRelationshipDefinition,
                            state
                        )
                        .commit(),
                [
                    props.entity,
                    phaseRelationshipDefinition
                ]);

        return <LabelButton
            label={localize(props.state.getObjectValueByField(types.Datastore.Phase.Field.LocalizedActionName)) || localize(props.state.getObjectValueByField(types.Datastore.Field.LocalizedName))}
            onClick={() => setState(props.state)}
            filled
            size="sm"
            icon={props.state.getObjectValueByField(types.Datastore.Phase.Field.Icon)}
            color={colorToCss(props.state.getObjectValueByField(types.Datastore.Phase.Field.Color)) ?? grey[400]}
        />;
    };

export default observer(StateButton);
