import { observable } from 'mobx';
import ParameterAssignment from '../../Automation/Parameter/ParameterAssignment';

export default class PortalDataSourceValue
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref dataSourceId: string;
    @observable.ref dataSourceParameterAssignment: ParameterAssignment;
    @observable.ref valueParameterAssignment: ParameterAssignment;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                dataSourceId: string,
                dataSourceParameterAssignment: ParameterAssignment,
                valueParameterAssignment: ParameterAssignment)
    {
        this.id = id;
        this.dataSourceId = dataSourceId;
        this.dataSourceParameterAssignment = dataSourceParameterAssignment;
        this.valueParameterAssignment = valueParameterAssignment;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
