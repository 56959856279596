import { LocalizedTextValue } from './LocalizedTextValue';
import { loadModuleDirectly } from '../../@Util/DependencyInjection';
import { LocalizationStore } from '../../@Service/Localization/LocalizationStore';

export default function localize(value?: LocalizedTextValue)
{
    const localizationStore = loadModuleDirectly(LocalizationStore);

    if (value === undefined)
    {
        return undefined;
    }

    return value[localizationStore.languageCode.toLowerCase()]
        || value[localizationStore.defaultLanguage.toLowerCase()]
        || Object.keys(value)
            .filter(key => value[key] !== undefined)
            .map(key => value[key])
            .find(() => true);
}
