import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { DialogStore } from '../../../@Component/Generic/Dialog/Type/DialogStore';
import { SidebarPanelStore } from '../../../@Component/Generic/SidebarPanel/SidebarPanelStore';
import { SidebarStore } from '../../../@Component/Generic/Sidebar/SidebarStore';
import { ComponentClass, ReactElement } from 'react';
import { BaseComponentProps } from '../../../@Framework/Component/BaseComponent';
import { injectWithQualifier } from '../../../@Util/DependencyInjection/index';
import { SettingSource, SettingStore } from '../../../@Component/Domain/Setting/SettingStore';
import { DrawerStore } from '../../../@Component/Generic/Drawer/DrawerStore';
import { FeedbackStore } from '../../../@Component/App/Root/Environment/Organization/Feedback/FeedbackStore';

export abstract class PageStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('SettingStore') settingStore: SettingStore;
    @injectWithQualifier('FeedbackStore') feedbackStore: FeedbackStore;

    // ------------------------- Properties -------------------------

    @observable dialogStores = observable.array<DialogStore>();
    @observable dialogs = observable.map<string, ReactElement>();

    @observable leftSidebarStore: SidebarStore;
    @observable secondaryLeftSidebarStore: SidebarStore;
    @observable rightSidebarStore: SidebarStore;
    @observable childPageStore: PageStore;
    @observable expandedPageIsSliding: boolean;
    @observable renderChildPageAsSelf: boolean;
    @observable hasPagePadding: boolean = true;
    @observable isAnimatingFromLeft: boolean = false;
    @observable isAnimatingFromRight: boolean = false;
    @observable showLeftSidebar: boolean = true;
    @observable showRightSidebar: boolean = true;
    @observable fixedSidebarPanelStoresLeft = observable.array<SidebarPanelStore>();
    @observable fixedSidebarPanelStoresRight = observable.array<SidebarPanelStore>();
    @observable headerHeight: number = 0;

    @observable drawerStore: DrawerStore;

    // ------------------------ Constructor -------------------------

    constructor(headerHeight: number)
    {
        super();

        this.headerHeight = headerHeight;
        this.drawerStore = new DrawerStore(true);
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        this.showLeftSidebar = this.settingStore && this.settingStore.getValue(SettingSource.User, 'Databrowser.Sidebar.IsVisible').boolean1;

        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    @computed
    get hasDialogs(): boolean
    {
        return this.dialogStores.length > 0;
    }

    @computed
    get hasLeftSidebar(): boolean
    {
        return this.leftSidebarStore
            && this.leftSidebarStore.hasViews
            && this.showLeftSidebar;
    }

    @computed
    get leftSidebarPanelStore(): SidebarPanelStore
    {
        if (this.hasLeftSidebar && this.leftSidebarStore.currentView.store instanceof SidebarPanelStore)
        {
            return this.leftSidebarStore.currentView.store;
        }
        else
        {
            return null;
        }
    }

    @computed
    get hasSecondaryLeftSidebar(): boolean
    {
        return this.secondaryLeftSidebarStore && this.secondaryLeftSidebarStore.hasViews;
    }

    @computed
    get hasRightSidebar(): boolean
    {
        return this.rightSidebarStore
            && this.rightSidebarStore.hasViews
            && this.showRightSidebar;
    }

    @computed
    get rightSidebarPanelStore(): SidebarPanelStore
    {
        if (this.hasRightSidebar && this.rightSidebarStore.currentView.store instanceof SidebarPanelStore)
        {
            return this.rightSidebarStore.currentView.store;
        }
        else
        {
            return null;
        }
    }

    @computed
    get dialogComponents(): ReactElement[]
    {
        return Array.from(this.dialogs.values());
    }

    // --------------------------- Stores ---------------------------

    @computed
    get furthestChildStore(): PageStore
    {
        if (this.childPageStore)
        {
            return this.childPageStore.furthestChildStore;
        }
        else
        {
            return this;
        }
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    popUpperChildPage()
    {
        if (this.childPageStore && !this.childPageStore.childPageStore)
        {
            this.childPageStore = undefined;
        }
        else if (this.childPageStore)
        {
            this.childPageStore.popUpperChildPage();
        }
    }

    @action.bound
    setExpandedPageIsSliding(value: boolean)
    {
        this.expandedPageIsSliding = value;
    }

    @action.bound
    pushLeftSidebarPanel(sidebarPanelStore: SidebarPanelStore, isFixedToPage: boolean = false)
    {
        if (!this.leftSidebarStore)
        {
            this.leftSidebarStore = new SidebarStore();
        }

        if (isFixedToPage)
        {
            this.fixedSidebarPanelStoresLeft.push(sidebarPanelStore);
        }

        this.leftSidebarStore.pushSidebarPanel(sidebarPanelStore);
    }

    /*@action.bound
    pushSecondaryLeftSidebarPanel(sidebarPanelStore: SidebarPanelStore)
    {
        if (!this.secondaryLeftSidebarStore)
        {
            this.secondaryLeftSidebarStore = new SidebarStore();
        }

        this.secondaryLeftSidebarStore.pushSidebarPanel(sidebarPanelStore);
    }*/

    @action.bound
    pushRightSidebarPanel(sidebarPanelStore: SidebarPanelStore, isFixedToPage: boolean = false)
    {
        if (!this.rightSidebarStore)
        {
            this.rightSidebarStore = new SidebarStore();
        }

        if (isFixedToPage)
        {
            this.fixedSidebarPanelStoresRight.push(sidebarPanelStore);
        }

        this.rightSidebarStore.pushSidebarPanel(sidebarPanelStore);
    }

    @action.bound
    popLeftSidebarPanel(sideBarPabelStore: SidebarPanelStore)
    {
        if (this.leftSidebarStore)
        {
            this.leftSidebarStore.popSidebarPanel(sideBarPabelStore);
            this.fixedSidebarPanelStoresLeft.remove(sideBarPabelStore);
        }
    }

    @action.bound
    popRightSidebarPanel(sideBarPabelStore: SidebarPanelStore)
    {
        if (this.rightSidebarStore)
        {
            this.rightSidebarStore.popSidebarPanel(sideBarPabelStore);
            this.fixedSidebarPanelStoresRight.remove(sideBarPabelStore);
        }
    }

    @action.bound
    clearLeftSidebar(includeFixed: boolean = false)
    {
        if (this.leftSidebarStore)
        {
            if (includeFixed)
            {
                this.leftSidebarStore.clear();
                this.fixedSidebarPanelStoresLeft.clear();
            }
            else
            {
                this.leftSidebarStore.clear(
                    undefined,
                    view => this.isFixedLeft(view.store as SidebarPanelStore));
            }
        }
    }

    @action.bound
    clearSecondaryLeftSidebar()
    {
        if (this.leftSidebarStore)
        {
            this.leftSidebarStore.clear();
        }
    }

    @action.bound
    clearRightSidebar(includeFixed: boolean = false)
    {
        if (this.rightSidebarStore)
        {
            if (includeFixed)
            {
                this.rightSidebarStore.clear();
                this.fixedSidebarPanelStoresRight.clear();
            }
            else
            {
                this.rightSidebarStore.clear(
                    undefined,
                    view =>
                    {
                        return this.isFixedRight(view.store as SidebarPanelStore);
                    });
            }
        }
    }

    @action.bound
    pushPageFromLeft(pageStore: PageStore,
                     isReplacement: boolean = true,
                     onAnimationComplete?: () => void)
    {
        this.pushPageWithAnimation(
            pageStore,
            true,
            isReplacement,
            onAnimationComplete);
    }

    @action.bound
    pushPageFromRight(pageStore: PageStore,
                      isReplacement: boolean = true,
                      onAnimationComplete?: () => void)
    {
        this.pushPageWithAnimation(
            pageStore,
            false,
            isReplacement,
            onAnimationComplete);
    }

    @action.bound
    pushPageWithAnimation(pageStore: PageStore,
                          isFromLeft: boolean,
                          isReplacement: boolean = true,
                          onAnimationComplete?: () => void)
    {
        this.isAnimatingFromLeft = isFromLeft;
        this.isAnimatingFromRight = !isFromLeft;

        setTimeout(() =>
        {
            this.pushPage(pageStore, isReplacement);

            if (onAnimationComplete)
            {
                onAnimationComplete();
            }

            setTimeout(() =>
            {
                this.hideAnimationOverlay();
            },
            2000);
        },
        0);
    }

    @action.bound
    hideAnimationOverlay()
    {
        this.isAnimatingFromLeft = false;
        this.isAnimatingFromRight = false;
    }

    @action.bound
    pushPage(pageStore: PageStore, isReplacement: boolean = true)
    {
        this.childPageStore = pageStore;
        this.expandedPageIsSliding = true;
        this.renderChildPageAsSelf = isReplacement;
    }

    @action.bound
    popPage()
    {
        this.childPageStore = undefined;
    }

    @action.bound
    pushDialogStore(dialogStore: DialogStore)
    {
        this.feedbackStore.openDialog({
            store: dialogStore
        });
    }

    @action.bound
    closeDialogStore(dialogStore: DialogStore)
    {
        this.feedbackStore.dialogs
            .filter(
                dialog =>
                    dialog.store === dialogStore)
            .forEach(
                dialog =>
                    this.feedbackStore.closeDialog(dialog))
    }

    @action.bound
    closeAllDialogStores()
    {
        this.feedbackStore.dialogs
            .slice()
            .forEach(
                dialog =>
                    this.feedbackStore.closeDialog(dialog));
    }

    @action.bound
    pushDialog(key: string, dialog: ReactElement)
    {
        this.feedbackStore.openDialog({
            id: key,
            content: dialog
        });
    }

    @action.bound
    closeDialog(key: string)
    {
        this.feedbackStore.dialogs
            .filter(
                dialog =>
                    dialog.id === key)
            .forEach(
                dialog =>
                    this.feedbackStore.closeDialog(dialog));
    }

    @action.bound
    toggleLeftSidebar()
    {
        this.showLeftSidebar = !this.showLeftSidebar;
        this.settingStore.updateWithValue(SettingSource.User, 'Databrowser.Sidebar.IsVisible', { boolean1: this.showLeftSidebar } );
    }

    // ------------------------ Public logic ------------------------

    abstract get componentClass(): ComponentClass<BaseComponentProps<PageStore>>;

    get componentProps(): any
    {
        return undefined;
    }

    get routePath(): string
    {
        return undefined;
    }

    get name(): string
    {
        return undefined;
    }

    get icon(): string
    {
        return undefined;
    }

    get color(): string
    {
        return undefined;
    }

    get route(): string
    {
        return undefined;
    }

    get wallpaper(): string
    {
        return undefined;
    }

    // ----------------------- Private logic ------------------------

    isFixedLeft(sidebarPanelStore: SidebarPanelStore)
    {
        return this.fixedSidebarPanelStoresLeft.indexOf(sidebarPanelStore) > -1;
    }

    isFixedRight(sidebarPanelStore: SidebarPanelStore)
    {
        return this.fixedSidebarPanelStoresRight.indexOf(sidebarPanelStore) > -1;
    }
}
