import * as React from 'react';

export interface ChartLabelProps
{
    x?: number;
    y?: number;
    stroke?: string;
    payload?: any;
}

export class ChartLabel extends React.Component<ChartLabelProps, {}>
{
    render(): React.ReactNode
    {
        return <g transform={`translate(${this.props.x},${this.props.y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform="rotate(-35)"
            >
                {this.props.payload.value}
            </text>
        </g>;
    }
}
