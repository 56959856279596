import React, { useMemo } from 'react';
import { DayOfWeek } from '../../../Model/Util/DayOfWeek';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import Checkbox from '../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';

interface DayOfWeekEditorProps
{
    value?: DayOfWeek[];
    onChange: (value: DayOfWeek[]) => void;
}

export const DaysOfWeekEditor: React.FC<DayOfWeekEditorProps> =
    ({
        value,
        onChange,
     }) =>
    {
        const daysOfWeek =
            useMemo<DayOfWeek[]>(
                () => [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday'
                ],
                []
            );

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            wrap
        >
            {
                daysOfWeek.map(
                    dayOfWeek =>
                        <ViewGroupItem
                            key={dayOfWeek}
                        >
                            <Input
                                label={
                                    <LocalizedText
                                        code={`DayOfWeek.${dayOfWeek}`}
                                        value={dayOfWeek}
                                    />
                                }
                                labelPosition="right"
                            >
                                <Checkbox
                                    checked={value?.includes(dayOfWeek)}
                                    onToggle={
                                        isChecked =>
                                            onChange(
                                                isChecked
                                                    ? [ ...value!, dayOfWeek ]
                                                    : value!.filter(
                                                        existingDayOfWeek =>
                                                            existingDayOfWeek !== dayOfWeek
                                                    )
                                            )
                                    }
                                    disabled={value === undefined}
                                />
                            </Input>
                        </ViewGroupItem>
                )
            }
        </ViewGroup>;
    };
