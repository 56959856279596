import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { ViewParams } from '../Model/ViewParams';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../DataObject/Model/DataObject';

export function getViewParameterAssignment(
    parameters: ParameterDictionary,
    entity: Entity
)
{
    return new ParameterAssignment()
        .setValue(
            parameters.getParameterById(ViewParams.Entity),
            new EntityValue(entity)
        )
        .setValue(
            parameters.getParameterById(ViewParams.Me),
            new EntityValue(
                loadModuleDirectly(CurrentUserStore).employeeEntity
            )
        )
        .setValue(
            parameters.getParameterById(ViewParams.CurrentDateTime),
            new PrimitiveValue(
                DataObject.constructFromTypeIdAndValue(
                    'DateTime',
                    new Date()
                )
            )
        );
}
