import AutomationDependencyContext from '../AutomationDependencyContext';
import EmptyValue from '../Value/EmptyValue';
import CollectionValue from '../Value/CollectionValue';
import PrimitiveValue from '../Value/PrimitiveValue';
import EntityValue from '../Value/EntityValue';
import Value from '../Value/Value';
import DataSourceValue from '../Value/DataSourceValue';
import MapValue from '../Value/MapValue';

export default async function getValueFromDescriptor(
    descriptor: any,
    dependencyContext: AutomationDependencyContext
): Promise<Value<any, any>>
{
    switch (descriptor.type)
    {
        case 'Empty':
            return EmptyValue.fromDescriptor(descriptor);

        case 'Collection':
            return CollectionValue.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'Map':
            return MapValue.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'Primitive':
            return PrimitiveValue.fromDescriptor(descriptor);

        case 'Entity':
            return EntityValue.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'DataSource':
            return DataSourceValue.fromDescriptor(
                descriptor,
                dependencyContext
            );
    }
}
