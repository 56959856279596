import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';

export default function useAvatarSrc(entity: Entity)
{
    return useComputed(
        () =>
            entity && entity.getAvatarUrl(),
        [
            entity
        ]);
}
