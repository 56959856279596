import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import Card from '../../../../../../../../../../@Future/Component/Generic/Card/Card';
import styles from './Item.module.scss';
import Inset from '../../../../../../../Item/Content/Inset/Inset';
import useIsMobile from '../../../../../../../../../../@Util/Responsiveness/useIsMobile';
import { classNames } from '../../../../../../../../../../@Future/Util/Class/classNames';
import Icon from './Icon';
import Header from './Header/Header';
import Author from './Author/Author';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface CreationItemProps
{
    entity: Entity;
}

const CreationItem: React.FC<CreationItemProps> =
    props =>
    {
        const isCompact = useIsMobile();

        return <div
            className={classNames(styles.root, isCompact && styles.compact)}
        >
            <div
                className={styles.invertedLineOnLastItem}
            />
            {
                !isCompact &&
                    <Icon
                        icon="add"
                    />
            }
            <div
                className={styles.data}
            >
                <Card>
                    <div
                        className={styles.header}
                    >
                        <Inset
                            horizontal
                            vertical
                        >
                            <ViewGroup
                                orientation="vertical"
                                spacing={5}
                            >
                                <ViewGroupItem>
                                    <Header
                                        name={
                                            <LocalizedText
                                                code="RelatedActivities.EntityCreated"
                                                value="${name} aangemaakt"
                                                name={props.entity.entityType.getName()}
                                            />
                                        }
                                        entity={props.entity}
                                        includeAuthor={!isCompact}
                                    />
                                </ViewGroupItem>
                                {
                                    isCompact &&
                                        <ViewGroupItem>
                                            <Author
                                                entity={props.entity}
                                            />
                                        </ViewGroupItem>
                                }
                            </ViewGroup>
                        </Inset>
                    </div>
                </Card>
            </div>
        </div>;
    };

export default observer(CreationItem);
