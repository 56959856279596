import React from 'react';
import { RecurrencePatternEditorProps } from '../RecurrencePatternEditor';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import StaticSelectbox from '../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import localizeText from '../../../../../@Api/Localization/localizeText';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';

export const RecurrencePatternTypeEditor: React.FC<RecurrencePatternEditorProps> =
    ({
        value,
        onChange,
     }) =>
    {
        return <Input
            label={
                <LocalizedText
                    code="Generic.Repeat"
                    value="Herhaling"
                />
            }
            labelPosition="left"
        >
            <StaticSelectbox
                value={
                    value.type === 'RelativeMonthly'
                        ? 'Monthly'
                        : value.type === 'RelativeYearly'
                            ? 'Yearly'
                            : value.type
                }
                onChange={
                    newValue =>
                    {
                        if (newValue === 'Daily')
                        {
                            return onChange({
                                type: 'Daily',
                                startDate: value.startDate,
                                end: value.end,
                                modifiedOccurrences: value.modifiedOccurrences,
                                deletedOccurrences: value.deletedOccurrences,
                                interval: 1,
                            });
                        }
                        else if (newValue === 'Weekly')
                        {
                            return onChange({
                                type: 'Weekly',
                                startDate: value.startDate,
                                end: value.end,
                                modifiedOccurrences: value.modifiedOccurrences,
                                deletedOccurrences: value.deletedOccurrences,
                                interval: 1,
                                firstDayOfWeek: 'Monday',
                                daysOfWeek: [ 'Monday' ],
                            });
                        }
                        else if (newValue === 'Monthly')
                        {
                            return onChange({
                                type: 'Monthly',
                                startDate: value.startDate,
                                end: value.end,
                                modifiedOccurrences: value.modifiedOccurrences,
                                deletedOccurrences: value.deletedOccurrences,
                                interval: 1,
                                dayOfMonth: 1,
                            });
                        }
                        else if (newValue === 'Yearly')
                        {
                            return onChange({
                                type: 'Yearly',
                                startDate: value.startDate,
                                end: value.end,
                                modifiedOccurrences: value.modifiedOccurrences,
                                deletedOccurrences: value.deletedOccurrences,
                                interval: 1,
                                month: 'January',
                                dayOfMonth: 1,
                            });
                        }
                    }
                }
                options={[
                    {
                        id: 'Daily',
                        value: 'Daily',
                        label: localizeText('Generic.Daily', 'Dagelijks'),
                    },
                    {
                        id: 'Weekly',
                        value: 'Weekly',
                        label: localizeText('Generic.Weekly', 'Wekelijks'),
                    },
                    {
                        id: 'Monthly',
                        value: 'Monthly',
                        label: localizeText('Generic.Monthly', 'Maandelijks'),
                    },
                    {
                        id: 'Yearly',
                        value: 'Yearly',
                        label: localizeText('Generic.Yearly', 'Jaarlijks'),
                    },
                ]}
            />
        </Input>;
    };
