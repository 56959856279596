import Period from '../../../Model/Period';
import TrackCell from './TrackCell';
import Cell from './Cell';
import { Moment } from 'moment';
import TrackItem from './TrackItem';
import Item from '../../../Model/Item';
import TrackCellRange from './TrackCellRange';

export default class Track
{
    // ------------------------- Properties -------------------------

    period: Period;
    width: number;
    cells: TrackCell[];

    // ------------------------ Constructor -------------------------

    constructor(period: Period, width: number, cells: TrackCell[])
    {
        this.period = period;
        this.width = width;
        this.cells = cells;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getCellByIdx(idx: number): TrackCell
    {
        const normalizedIdx =
            Math.max(0,
                Math.min(
                    this.cells.length - 1,
                    idx));

        return this.cells[normalizedIdx];
    }

    getCellByOffset(offset: number): TrackCell
    {
        const normalizedOffset =
            Math.max(0,
                Math.min(
                    this.width,
                    offset));

        return this.cells.find(
            cell =>
                normalizedOffset >= cell.offset
                    && normalizedOffset < cell.offset + cell.width)
            || this.cells[this.cells.length - 1];
    }

    getCellByDate(date: Moment,
                  isEndInclusive: boolean = false)
    {
        const normalizedDate =
            date.isBefore(this.period.from)
                ?
                    this.period.from
                :
                    date.isAfter(this.period.to)
                        ?
                            this.period.to
                        :
                            date;

        return this.cells.find(
            cell =>
                normalizedDate.isSameOrAfter(cell.cell.period.from)
                && (isEndInclusive ? normalizedDate.isSameOrBefore(cell.cell.period.to) : normalizedDate.isBefore(cell.cell.period.to)))
            || this.cells[this.cells.length - 1];
    }

    getTrackItem(item: Item)
    {
        return new TrackItem(
            item,
            new TrackCellRange(
                this.getCellByDate(item.period.from, false),
                this.getCellByDate(item.period.to, true)));
    }

    getMovedRangeByOffset(range: TrackCellRange,
                          diffX: number)
    {
        const fromOffset = range.from.offset + diffX;
        const fromCell = this.getCellByOffset(fromOffset);

        const diffInIdx = fromCell.cell.idx - range.from.cell.idx;
        const toCell = this.getCellByIdx(range.to.cell.idx + diffInIdx);

        return new TrackCellRange(
            fromCell,
            toCell);
    }

    getResizedRangeByOffset(range: TrackCellRange,
                            diffX: number)
    {
        const toOffset = range.to.offset + diffX;
        let toCell = this.getCellByOffset(toOffset);

        if (toCell.cell.idx < range.from.cell.idx)
        {
            toCell = range.from;
        }

        return new TrackCellRange(
            range.from,
            toCell);
    }

    static buildTrack(period: Period,
                      width: number,
                      cells: Cell[],
                      activeToInActivePeriodRatio: number)
    {
        const numberOfActiveCells = cells.filter(c => c.isActive).length;
        const numberOfInactiveCells = cells.filter(c => !c.isActive).length;

        // Solve equation:
        // activeCellWidth = ratio * inactiveCellWidth
        // &&
        // numberOfActiveCells * activeCellWidth + numberOfInactiveCells * inactiveCellWidth = trackWidth
        // <=> (bi-implies)
        // activeCellWidth = trackWidth / (numberOfActiveCells + numberOfInactiveCells / ratio)
        const activeCellWidth = width / (numberOfActiveCells + numberOfInactiveCells / activeToInActivePeriodRatio);
        const inactiveCellWidth = activeCellWidth / activeToInActivePeriodRatio;

        let currentOffset = 0;

        return new Track(
            period,
            width,
            cells.map(
                cell =>
                {
                    let width = cell.isActive ? activeCellWidth : inactiveCellWidth;

                    const trackCell =
                        new TrackCell(
                            cell,
                            currentOffset,
                            width);

                    currentOffset += width;

                    return trackCell;
                }));
    }

    // ----------------------- Private logic ------------------------
}
