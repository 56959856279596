import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { lightTextColor, primaryColor, recordHoverBackgroundColor } from '../../../../../../../@Resource/Theme/Theme';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import useTypes from '../../../../../Entity/Type/Api/useTypes';
import useDebouncedCallback from '../../../../../../../@Util/Debounce/useDebouncedCallback';
import { StringHeaderFilter } from '../../../../../../../@Future/Component/Generic/Header/HeaderFilters/StringHeaderFilter';
import { Header, SortExpression } from '../../../../../../../@Future/Component/Generic/Header/Header';
import { EntityPath } from '../../../../Path/@Model/EntityPath';

const useStyles = makeStyles({
    header: {
        verticalAlign: 'top !important',
        "&:hover": {
            backgroundColor: recordHoverBackgroundColor,
            cursor: 'pointer'
        }
    },
    headerExtraInfo: {
        fontSize: 'small',
        color: lightTextColor
    },
    filter: {
        fontWeight: 'normal',
        color: primaryColor,
        width: 'fit-content',
    }
});

export type PortalUserFilterType = 'Username' | 'Contact' | 'Relationship';

export interface PortalUserListHeadProps
{
    sort: SortExpression;
    onSort: (sortExpression: SortExpression) => void;
    filters: Map<PortalUserFilterType, string>;
    onFilterChange: (type: PortalUserFilterType, filter: string) => void;
}

export const PortalUserListHead: React.FC<PortalUserListHeadProps> =
    observer(
        ({
            sort,
            onSort,
            filters,
            onFilterChange,
         }) =>
        {
            const types = useTypes();
            const classes = useStyles();

            const [ usernameFilter, setUsernameFilter ] = useState<string>(filters.get('Username'));
            const [ contactFilter, setContactFilter ] = useState<string>(filters.get('Contact'));
            const [ relationshipFilter, setRelationshipFilter ] = useState<string>(filters.get('Relationship'));

            const emailAddressFieldPath =
                useMemo(
                    () =>
                        EntityPath.root(types.PortalUser.Type)
                            .field(types.PortalUser.Field.EmailAddress),
                    [
                        types
                    ]);

            const hasTwoFactorAuthenticationFieldPath =
                useMemo(
                    () =>
                        EntityPath.root(types.PortalUser.Type)
                            .field(types.PortalUser.Field.HasTwoFactorAuthentication),
                    [
                        types
                    ]);

            const contactFieldPath =
                useMemo(
                    () =>
                        EntityPath.root(types.PortalUser.Type)
                            .joinTo(
                                types.PortalUser.RelationshipDefinition.Contact,
                                false)
                            .field(types.Entity.Field.Name),
                    [
                        types
                    ]);

            const relationshipFieldPath =
                useMemo(
                    () =>
                        EntityPath.root(types.PortalUser.Type)
                            .joinTo(
                                types.PortalUser.RelationshipDefinition.Relationship,
                                false)
                            .field(types.Entity.Field.Name),
                    [
                        types
                    ]);

            const debounceFilter =
                useDebouncedCallback(
                    (
                        type: PortalUserFilterType,
                        filter: string
                    ) =>
                    {
                        onFilterChange(type, filter);
                    },
                    [
                        onFilterChange,
                    ],
                    500
                );

            useEffect(
                () =>
                    debounceFilter('Username', usernameFilter),
                [
                    usernameFilter
                ]
            );
            useEffect(
                () =>
                    debounceFilter('Contact', contactFilter),
                [
                    contactFilter
                ]
            );
            useEffect(
                () =>
                    debounceFilter('Relationship', relationshipFilter),
                [
                    relationshipFilter
                ]
            );

            return <TableHead>
                <TableRow>
                    <TableCell
                        className={classes.header}
                        width={'20%'}
                    >
                        <StringHeaderFilter
                            fieldPath={emailAddressFieldPath}
                            filter={usernameFilter}
                            onChangeFilter={setUsernameFilter}
                            isDefaultSortColumn
                            sort={sort}
                            onSort={onSort}
                        />
                    </TableCell>
                    <TableCell
                        className={classes.header}
                        width={'10%'}
                    >
                        <Header
                            fieldPath={hasTwoFactorAuthenticationFieldPath}
                            sort={sort}
                            onSort={onSort}
                        />
                    </TableCell>
                    <TableCell
                        className={classes.header}
                        width={'20%'}
                    >
                        <StringHeaderFilter
                            fieldPath={contactFieldPath}
                            filter={contactFilter}
                            onChangeFilter={setContactFilter}
                            sort={sort}
                            onSort={onSort}
                            localizeResourceCode="Generic.Contact"
                            localizeResourceDefaultValue="Contact"
                        />
                    </TableCell>
                    <TableCell
                        className={classes.header}
                        width={'20%'}
                    >
                        <StringHeaderFilter
                            fieldPath={relationshipFieldPath}
                            filter={relationshipFilter}
                            onChangeFilter={setRelationshipFilter}
                            sort={sort}
                            onSort={onSort}
                            localizeResourceCode="Generic.Relation"
                            localizeResourceDefaultValue="Relatie"
                        />
                    </TableCell>
                    <TableCell
                        className={classes.header}
                        width={'15%'}
                    >
                        <LocalizedText
                            code="Generic.ActivationDate"
                            value="Activatie datum"
                        />
                    </TableCell>
                    <TableCell
                        className={classes.header}
                        width={'15%'}
                    >
                        <LocalizedText
                            code="Generic.LastLogin"
                            value="Laaste login"
                        />
                    </TableCell>
                </TableRow>
            </TableHead>;
        }
    );
