import { observable } from 'mobx';
import { EntityType } from '../../Model/Implementation/EntityType';
import Predicate from '../Function/Computation/Predicate/Predicate';
import Parameter from '../Parameter/Parameter';
import Dependency from '../Parameter/Dependency';
import EntityValueType from '../Value/Type/EntityValueType';
import getTypes from '../../../@Component/Domain/Entity/Type/Api/getTypes';
import Trigger from './Trigger';

export default abstract class MutationTrigger extends Trigger
{
    // ------------------------- Properties -------------------------

    static EntityParameterId = 'Entity';
    static InitiatorParameterId = 'Initiator';

    @observable.ref entityType: EntityType;

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType,
                predicate: Predicate)
    {
        super(predicate);

        this.entityType = entityType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract getParameters(): Parameter<any>[];

    getDependencies(): Dependency[]
    {
        if (this.predicate)
        {
            return [];
        }
        else
        {
            return this.predicate.getDependencies();
        }
    }

    getEntityParameter()
    {
        return new Parameter(
            MutationTrigger.EntityParameterId,
            new EntityValueType(this.entityType),
            true,
            this.entityType.getName());
    }

    getUserParameter()
    {
        return new Parameter(
            MutationTrigger.InitiatorParameterId,
            new EntityValueType(getTypes().Relationship.Person.Contact.Employee.Type),
            false,
            'Initiator');
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.entityTypeId = this.entityType.id;
    }

    abstract getName(): string;

    // ----------------------- Private logic ------------------------
}
