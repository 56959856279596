import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { EntityContext } from '../../../../Entity/@Model/EntityContext';
import styles from './DatasetEditor.module.scss';
import { IObservableArray, runInAction } from 'mobx';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import Dataset from '../../../../Entity/Dataset/Model/Dataset';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import FieldPathSelector from '../../../../Entity/Path/FieldPathEditor/FieldPathSelector';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import Segment from '../../../../Entity/Dataset/Model/Segment';
import uuid from '../../../../../../@Util/Id/uuid';
import SegmentEditor from './SegmentEditor/SegmentEditor';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import { default as DatasetView } from '../../../../Entity/Dataset/Dataset';
import { Grid } from '@material-ui/core';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import { default as EntityInput } from '../../../../Entity/Input/Input';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import FilterEditor from '../ResourcePlannersEditor/FilterEditor/FilterEditor';
import View from '../../../../Entity/View/Model/View';
import Specification from '../../../../Entity/View/Model/Specification';
import getSystemDefaultView from '../../../../Entity/View/Api/getSystemDefaultView';
import List from '../../../../Entity/View/List/List';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import AddButton from '../../../../../../@Future/Component/Generic/Button/Variant/AddButton/AddButton';
import DeleteIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import Layout from '../../../../../../@Api/Layout/Layout';
import ItemLayoutEditor from '../../../../Entity/View/ItemLayout/Editor/ItemLayoutEditor';
import DatasetEditorButtons from './DatasetEditorButtons';
import { usePackSelector } from '../../../Page/Packs/MySharedPackManager/Api/usePackSelector';
import { SharePackEditor } from '../../../Page/Packs/MySharedPackManager/SharePackEditor/SharePackEditor';

export interface DatasetInternalEditorProps
{
    datasetEntity: Entity;
    dataset: Dataset;
    onSave: () => {};
    onSaveAsNew: () => {};
    onDelete: () => {};
}

const DatasetInternalEditor: React.FC<DatasetInternalEditorProps> =
    props =>
    {
        const { datasetEntity, dataset } = props;
        const types = useTypes();

        const addSegment =
            useCallback(
                (groupFieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                            dataset.segments.push(
                                new Segment(
                                    uuid(),
                                    'List',
                                    groupFieldPath))),
                [
                    dataset
                ]);

        const filterParameter =
            useMemo(
                () =>
                    dataset?.parameter,
                [
                    dataset
                ]);
        const setFilter =
            useCallback(
                (filter?: Predicate) =>
                    runInAction(
                        () =>
                            dataset.filter = filter),
                [
                    dataset
                ]);

        const dividerGlue = useDividerGlue();

        const deleteSegment =
            useCallback(
                (segment: Segment) =>
                    runInAction(
                        () =>
                            (dataset.segments as IObservableArray).remove(segment)),
                [
                    dataset
                ]);

        const context =
            useComputed(
                () =>
                    dataset &&
                        EntityContext.fromEntityType(dataset.entityType),
                [
                    dataset
                ]);

        const setItemLayout =
            useCallback(
                (itemLayout?: Layout) =>
                    runInAction(
                        () =>
                            dataset.itemLayout = itemLayout
                    ),
                [
                    dataset
                ]
            );

        const view =
            useMemo(
                () =>
                    dataset.list &&
                        new View(
                            'List',
                            undefined,
                            dataset.entityType,
                            dataset.parameters,
                            dataset.filter,
                            new Specification(dataset?.list)),
                [
                    dataset.list
                ]);

        const createList =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            dataset.list =
                                getSystemDefaultView(
                                    dataset.entityType,
                                    dataset.parameters,
                                    dataset.filter)
                                    .specification
                                    .list),
                [
                    dataset
                ]);

        const deleteList =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            dataset.list = undefined),
                [
                    dataset
                ]);

        const [ isPackSelectorVisible, ownedShareAndEnvironmentPacks ] = usePackSelector(datasetEntity);

        return <Grid
            container
            spacing={2}
        >
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="vertical"
                            spacing={15}
                        >
                            <ViewGroupItem>
                                <InputGroup>
                                    <EntityInput
                                        labelPosition="left"
                                        entity={datasetEntity}
                                        field={types.Dataset.Field.LocalizedName}
                                        doAutocommit={false}
                                    />
                                </InputGroup>
                            </ViewGroupItem>
                            {
                                isPackSelectorVisible &&
                                <ViewGroupItem>
                                    <SharePackEditor
                                        entity={datasetEntity}
                                        ownedShareAndEnvironmentPacks={ownedShareAndEnvironmentPacks}
                                    />
                                </ViewGroupItem>
                            }
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={5}
                                >
                                    <ViewGroupItem>
                                        <CardHeader>
                                            <LocalizedText
                                                code="Generic.Filter"
                                                value="Filter"
                                            />
                                        </CardHeader>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <FilterEditor
                                            key={dataset.entity?.uuid}
                                            filterParameter={filterParameter}
                                            parameterDictionary={dataset.parameters}
                                            value={dataset.filter}
                                            onChange={setFilter}
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={5}
                                >
                                    <ViewGroupItem>
                                        <CardHeader>
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={5}
                                                alignment="center"
                                            >
                                                <ViewGroupItem>
                                                    <LocalizedText
                                                        code="Configuration.Dataset.Segmentation"
                                                        value="Segmentering"
                                                    />
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <MenuButton
                                                        icon="add"
                                                        tooltip={
                                                            <LocalizedText
                                                                code="Configuration.Dataset.AddSegmentationToField"
                                                                value="Voeg segmentering toe op veld"
                                                            />
                                                        }
                                                        small
                                                    >
                                                        <ViewGroup
                                                            orientation="vertical"
                                                            spacing={5}
                                                            glue={dividerGlue}
                                                        >
                                                            <ViewGroupItem>
                                                                <div
                                                                    className={styles.addSegmentLabel}
                                                                >
                                                                    <LocalizedText
                                                                        code="Configuration.Dataset.SelectSegmentationField"
                                                                        value="Selecteer een veld om op te segmenteren."
                                                                    />
                                                                </div>
                                                            </ViewGroupItem>
                                                            <ViewGroupItem>
                                                                <FieldPathSelector
                                                                    context={context}
                                                                    onSelect={addSegment}
                                                                />
                                                            </ViewGroupItem>
                                                        </ViewGroup>
                                                    </MenuButton>
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </CardHeader>
                                    </ViewGroupItem>
                                    {
                                        dataset.segments.map(
                                            (segment, idx) =>
                                                <ViewGroupItem
                                                    key={segment.id}
                                                >
                                                    <Divider />
                                                    <SegmentEditor
                                                        dataset={dataset}
                                                        segment={segment}
                                                        idx={idx}
                                                        onDelete={deleteSegment}
                                                    />
                                                </ViewGroupItem>)
                                    }
                                </ViewGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    <ViewGroupItem>
                        <CardHeader>
                            <LocalizedText
                                code="Generic.Example"
                                value="Voorbeeld"
                            />
                        </CardHeader>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <DatasetView
                            id={datasetEntity.uuid}
                            name={datasetEntity.name}
                            dataset={dataset}
                            disableExpansionMemory
                            expanded
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={15}
                >
                    <ViewGroupItem>
                        <ItemLayoutEditor
                            parameters={dataset.parameters}
                            value={dataset.itemLayout}
                            onChange={setItemLayout}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <CardHeader>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                                alignment="center"
                            >
                                <ViewGroupItem>
                                    <LocalizedText
                                        code="Configuration.Dataset.List"
                                        value="Gekoppelde weergave"
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <DeleteIconButton
                                        onClick={deleteList}
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </CardHeader>
                    </ViewGroupItem>
                    {
                        !dataset.list &&
                            <ViewGroupItem>
                                <AddButton
                                    label={
                                        <LocalizedText
                                            code="Generic.Create"
                                            value="Creëren"
                                        />
                                    }
                                    onClick={createList}
                                />
                            </ViewGroupItem>
                    }
                    <ViewGroupItem>
                        <DatasetEditorButtons
                            datasetEntity={datasetEntity}
                            dataset={dataset}
                            onSave={props.onSave}
                            onSaveAsNew={props.onSaveAsNew}
                            onDelete={props.onDelete}
                        />
                    </ViewGroupItem>
                    {
                        dataset.list &&
                            <ViewGroupItem>
                                <Card>
                                    <List
                                        view={view}
                                        isInEditMode
                                    />
                                </Card>
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </Grid>
        </Grid>;
    };

export default observer(DatasetInternalEditor);
