import React, { useContext } from 'react';
import Card from '../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { AssistantPrompt } from './Model/AssistantPrompt';
import { green } from '@material-ui/core/colors';
import { observer } from 'mobx-react';
import { Box, Typography } from '@material-ui/core';
import CurrentUserContext from '../User/CurrentUserContext';
import Avatar from '../Entity/Avatar/Avatar';
import { format } from 'date-fns';
import { AssistantPromptAnswer } from './AssistantPromptAnswer';

export interface AssistantPromptViewerProps
{
    prompt: AssistantPrompt;
    remainingPrompts: AssistantPrompt[];
    onClose: () => void;
}

const noQuestions = [];

export const AssistantPromptViewer: React.FC<AssistantPromptViewerProps> =
    observer(
        ({
             prompt,
             remainingPrompts,
             onClose,
         }) =>
        {
            const currentUserStore = useContext(CurrentUserContext);

            return <ViewGroup
                orientation="vertical"
                spacing={16}
            >
                <ViewGroupItem>
                    <div
                        style={{
                            marginBottom: 20,
                        }}
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={16}
                            alignment="center"
                        >
                            <ViewGroupItem>
                                <Avatar
                                    entity={currentUserStore.employeeEntity}
                                    editable={false}
                                    size={36}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <Card
                                        inset
                                        style={{
                                            backgroundColor: green[100],
                                        }}
                                    >
                                        {prompt.prompt}
                                    </Card>
                                    <Box
                                        style={{
                                            position: 'absolute',
                                            right: 0,
                                            bottom: -20,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            {format(prompt.date, 'HH:mm')}
                                        </Typography>
                                    </Box>
                                </div>
                            </ViewGroupItem>
                        </ViewGroup>
                    </div>
                </ViewGroupItem>
                <ViewGroupItem>
                    <AssistantPromptAnswer
                        prompt={prompt}
                        remainingPrompts={remainingPrompts}
                        onClose={onClose}
                        followUpQuestionAnswers={noQuestions}
                    />
                </ViewGroupItem>
            </ViewGroup>;
        }
    );
