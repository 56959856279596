import React from 'react';
import { RecurrencePatternEditorProps } from '../../../RecurrencePatternEditor';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { MonthlyRecurrencePattern } from '../../../../Model/RecurrencePattern';
import { RecurrenceIntervalEditor } from '../../../Util/Interval/RecurrenceIntervalEditor';
import { DayOfMonthEditor } from '../../../Util/DayOfMonth/DayOfMonthEditor';

export const MonthlyRecurrencePatternEditor: React.FC<RecurrencePatternEditorProps<MonthlyRecurrencePattern>> =
    ({
        value,
        onChange,
        disabled,
     }) =>
    {
        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                <LocalizedText
                    code="EveryDayOfMonth"
                    value="Elke maand op de"
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <DayOfMonthEditor
                    value={value?.dayOfMonth}
                    onChange={
                        dayOfMonth =>
                            onChange({
                                ...value,
                                dayOfMonth,
                            })
                    }
                    disabled={disabled}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                e
            </ViewGroupItem>
            <ViewGroupItem>
                <LocalizedText
                    code="Generic.OfEvery"
                    value="van elke"
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <RecurrenceIntervalEditor
                    value={value?.interval}
                    onChange={
                        interval =>
                            onChange({
                                ...value,
                                interval,
                            })
                    }
                    disabled={disabled}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <LocalizedText
                    code="Generic.Month(s)"
                    value="Maand(en)"
                />
            </ViewGroupItem>
        </ViewGroup>;
    };
