import React, { CSSProperties, useEffect } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityField } from '../../../../../@Api/Model/Implementation/EntityField';
import { DataObjectRepresentationProps } from '../../../DataObject/Model/DataObjectRepresentation';
import { runInAction } from 'mobx';
import { Alignment, DataObject } from '../../../DataObject/Model/DataObject';
import { FormHandlerContext } from '../../../../Generic/Form/FormHandlerContext';
import { FormEvent } from '../../../../Generic/Form/FormEvent';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import InternalValueEditor from './InternalValueEditor';

export interface ValueEditorProps
{
    entity: Entity;
    field: EntityField;
    commitContext?: CommitContext;
    doAutoCommit?: boolean;
    disableUnderline?: boolean;
    autoFocus?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    onChange?: (value: any | undefined) => void;
    required?: boolean;
    alignment?: Alignment;
    placeholder?: string;
    representation?: DataObjectRepresentationProps,
    isRequiredOverride?: boolean
    isInEditMode: boolean;
    hasUnderline?: boolean,
    isFocused?: boolean;
    inputStyle?: CSSProperties;
    onEnterPressed?: () => void,
    handlerContext?: FormHandlerContext<DataObject>,
    isCompact?: boolean,
    onKeyUp?: (event: FormEvent<DataObject>) => void;
    showCommitButtonOnTouch?: boolean;
}

const ValueEditor: React.FC<ValueEditorProps> =
    props =>
    {
        useEffect(
            () =>
            {
                runInAction(
                    () =>
                        props.entity.getValueByField(props.field, true)
                );
            },
            [
                props.entity,
                props.field,
            ]
        );

        const value =
            useComputed(
                () =>
                    props.entity.getValueByField(props.field, false, true),
                [
                    props.entity,
                    props.field,
                ]
            );

        if (value)
        {
            return <InternalValueEditor
                {...props}
                value={value}
            />;
        }
        else
        {
            return null;
        }
    };

ValueEditor.defaultProps = {
    isInEditMode: true
};

export default observer(ValueEditor);
