import Validation from '../../../Automation/Validation/Validation';
import Dependency from '../../../Automation/Parameter/Dependency';
import Value from '../../../Automation/Value/Value';
import LayoutAction from '../LayoutAction';
import LayoutDependencyContext from '../../LayoutDependencyContext';
import { observable } from 'mobx';
import Parameter from '../../../Automation/Parameter/Parameter';
import getLayoutActionFromDescriptor from '../Api/getLayoutActionFromDescriptor';
import LayoutContext from '../../LayoutContext';

export default class CompositeActionInvocation
{
    // ------------------------- Properties -------------------------

    @observable.ref resultParameter: Parameter<any>;
    @observable.ref action: LayoutAction;

    // ------------------------ Constructor -------------------------

    constructor(resultParameter: Parameter<any>, action: LayoutAction)
    {
        this.resultParameter = resultParameter;
        this.action = action;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async apply(context: LayoutContext): Promise<Value<any, any>>
    {
        return this.action.apply(context);
    }

    getDependencies(): Dependency[]
    {
        return this.action.getDependencies();
    }

    validate(): Validation[]
    {
        return this.action.validate();
    }

    toDescriptor()
    {
        return {
            id: this.resultParameter.id,
            action: this.action.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const id = descriptor.id;
        const action =
            await getLayoutActionFromDescriptor(
                descriptor.action,
                dependencyContext);
        const resultParameter =
            CompositeActionInvocation.getResultParameter(
                id,
                action);

        return new CompositeActionInvocation(
            resultParameter,
            action);
    }

    static getResultParameter(id: string,
                              action: LayoutAction): Parameter<any>
    {
        return new Parameter(
            id,
            action.getReturnType(),
            false,
            `Resultaat van actie ${action.getName()}`);
    }

    // ----------------------- Private logic ------------------------
}
