import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { ButtonStore } from '../ButtonStore';
import * as React from 'react';
import { Icon } from '@material-ui/core';
import { ToggleButton as MuiToggleButton, ToggleButtonGroup as MuiToggleButtonGroup } from '@material-ui/lab';

@observer
export class ToggleButton extends BaseComponent<ButtonStore>
{
    renderLoader(store: ButtonStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: ButtonStore)
    {
        return <MuiToggleButtonGroup
            value={
                store.isToggled
                    ?
                        ['selected']
                    :
                        []
            }
            onChange={store.handleToggle}
            style={store.style}
        >
            <MuiToggleButton value="selected">
                <Icon>{store.icon}</Icon>
            </MuiToggleButton>
        </MuiToggleButtonGroup>;
    }
}
