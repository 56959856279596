import { findAssistantOnCreateTrigger } from './findAssistantOnCreateTrigger';
import { findAssistantOnUpdateTrigger } from './findAssistantOnUpdateTrigger';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { findAssistantOnDeleteTrigger } from './findAssistantOnDeleteTrigger';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import { AssistantTrigger } from '../../Model/Automation/Trigger/AssistantTrigger';
import Trigger from '../../../../../@Api/Automation/Trigger/Trigger';
import { findAssistantCronTrigger } from './findAssistantCronTrigger';

export function findAssistantTrigger(
    trigger: AssistantTrigger,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Trigger
{
    switch (trigger.type)
    {
        case 'OnCreate':
            return findAssistantOnCreateTrigger(
                trigger,
                parameters,
                rootParameter
            );

        case 'OnUpdate':
            return findAssistantOnUpdateTrigger(
                trigger,
                parameters,
                rootParameter
            );

        case 'OnDelete':
            return findAssistantOnDeleteTrigger(
                trigger,
                parameters,
                rootParameter
            );

        case 'Cron':
            return findAssistantCronTrigger(
                trigger,
                parameters,
                rootParameter
            );

        default:
            throw new Error(`Could not resolve trigger: ${JSON.stringify(trigger)}`);
    }
}
