import React, { useCallback, useMemo } from 'react';
import useTypes from '../../Type/Api/useTypes';
import { SelectionOptionsProps } from '../SelectionOptions';
import MenuButton from '../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import useSwitch from '../../../../../@Util/Switch/useSwitch';
import Item from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import { EntityPath } from '../../Path/@Model/EntityPath';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import { createTransactionalModel } from '../../../../../@Util/TransactionalModelV2/index';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import constructEntity from '../../../../../@Api/Entity/constructEntity';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../@Api/Localization/localizeText';

export interface CampaignConstructorButtonProps extends SelectionOptionsProps
{

}

const CampaignConstructorButton: React.FC<CampaignConstructorButtonProps> =
    props =>
    {
        const types = useTypes();

        const allowedPathsFromSelection =
            useMemo(
                () =>
                {
                    const rootPath = EntityPath.fromEntityType(props.entityType);

                    if (props.entityType.isA(types.Activity.Type))
                    {
                        return [
                            rootPath
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.Activities,
                                    true)
                                .castTo(types.Relationship.Organization.Type),
                            rootPath
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.Activities,
                                    true)
                                .castTo(types.Relationship.Person.Type),
                            rootPath
                                .joinTo(
                                    types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                    true)
                        ];
                    }
                    else if (props.entityType.isA(types.Relationship.Type)
                        || props.entityType.isA(types.Relation.Type))
                    {
                        return [ rootPath ];
                    }
                    else
                    {
                        return [];
                    }
                },
                [
                    props.entityType,
                    types
                ]);

        const constructCampaign =
            useCallback(
                (pathFromSelection: EntityPath) =>
                {
                    const campaign =
                        createTransactionalModel(
                            new Entity(types.Activity.Campaign.Type)
                                .initialize());

                    campaign.setValueByField(
                        types.Activity.Field.Subject,
                        localizeText(
                            'CampaignConstructorButton.CampaignSubject',
                            'Mailing naar ${target}',
                            {
                                target: props.name.toLowerCase()
                            }));

                    campaign.setValueByField(
                        types.Activity.Campaign.Field.RootType,
                        pathFromSelection.entityType);

                    if (props.filter)
                    {

                        const descriptor = {
                            _isNew: true,
                            ...props.filter.toDescriptor()
                        }

                        campaign.setValueByField(
                            types.Activity.Campaign.Field.SelectionFilter,
                            descriptor
                        );
                    }

                    return constructEntity(
                        campaign.entityType,
                        undefined,
                        campaign);
                },
                [
                    types,
                    props.filter,
                    props.name
                ]);

        const [ isOpen, open, close ] = useSwitch(false);
        const click =
            useCallback(
                () =>
                {
                    if (allowedPathsFromSelection.length === 1)
                    {
                        constructCampaign(allowedPathsFromSelection[0]);
                    }
                    else
                    {
                        if (isOpen)
                        {
                            close();
                        }
                        else
                        {
                            open();
                        }
                    }
                },
                [
                    allowedPathsFromSelection,
                    constructCampaign,
                    isOpen,
                    close,
                    open
                ]);

        if (allowedPathsFromSelection.length === 0)
        {
            return null;
        }
        else
        {
            return <MenuButton
                open={isOpen}
                onOpen={open}
                onClose={close}
                onClick={click}
                icon="email"
                tooltip={
                    <LocalizedText
                        code="CampaignConstructorButton.CreateCampaign"
                        value="Mailing aanmaken"
                    />
                }
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    <ViewGroupItem>
                        <CardInset
                            bottom={false}
                        >
                            <strong>
                                <LocalizedText
                                    code="CampaignConstructorButton.TargetSelection"
                                    value="Mailing naar..."
                                />
                            </strong>
                        </CardInset>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Menu>
                            {
                                allowedPathsFromSelection.map(
                                    path =>
                                        <Item
                                            key={path.id}
                                            name={path.entityType.getName(true)}
                                            onClick={() => constructCampaign(path)}
                                        />)
                            }
                        </Menu>
                    </ViewGroupItem>
                </ViewGroup>
            </MenuButton>;
        }
    };

export default CampaignConstructorButton;
