import { useComputed } from 'mobx-react-lite';
import useNonEmptyFieldGroups from './useNonEmptyFieldGroups';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';

export default function useUncategorizedFieldGroup(
    entity: Entity,
    fieldPaths: EntityFieldPath[],
    forType?: EntityType
)
{
    const nonEmptyGroups =
        useNonEmptyFieldGroups(
            entity,
            fieldPaths,
            forType
        );

    return useComputed(
        () =>
        {
            return nonEmptyGroups
                .find(
                    group =>
                        !group.entity);
        },
        [
            nonEmptyGroups
        ]);
}
