import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { EntityActionResult } from '../../Model/Implementation/EntityActionResult';

export class EntityActionController
{
    apiClient: ApiClient;

    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };

    perform(code: string,
            parameters: any,
            entityTypeId: number,
            entityId: number,
            commitId: string,
            files?: Map<string, File>): Promise<EntityActionResult>
    {
        if (!files || files.size === 0)
        {
            return new Promise<EntityActionResult>(
                (resolve, reject) =>
                {
                    this.apiClient.request(new ApiRequest<any>('/entity/action/perform', Method.Post, { code: code, parameters: parameters, entity_type_id: entityTypeId, entity_id: entityId, commit_id: commitId }))
                        .then(result =>
                        {
                            resolve(result);
                        })
                        .catch(reason =>
                        {
                            reject(reason);
                        });
                });
        }
        else
        {
            let data = new FormData();

            data.append('code', code);

            if (parameters !== undefined)
            {
                data.append('parameters', JSON.stringify(parameters));
            }

            if (entityTypeId !== undefined)
            {
                data.append('entity_type_id', entityTypeId.toString());
            }

            if (entityId !== undefined)
            {
                data.append('entity_id', entityId.toString());
            }

            if (commitId !== undefined)
            {
                data.append('commit_id', commitId);
            }


            files.forEach(
                (file, fileId) =>
                {
                    data.append(fileId, file, 'file');
                });

            return this.apiClient
                .request(
                    new ApiRequest<any>(
                        '/entity/action/perform',
                        Method.Post,
                        data,
                        {
                            enctype: 'multipart/form-data',
                        },
                        true,
                        undefined,
                        undefined,
                        undefined,
                        true))
                .then(
                    result =>
                    {
                        if (result.ok)
                        {
                            return Promise.resolve(
                                fromJson(
                                    result.data,
                                    EntityActionResult));
                        }
                        else
                        {
                            return Promise.reject(result.error);
                        }
                    });
        }
    };
}
