import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import EntityTypeContext from '../../../../../Entity/Type/EntityTypeContext';
import { IObservableArray, runInAction } from 'mobx';
import Dataset from '../../../../../Entity/Dataset/Model/Dataset';
import Segment, { SegmentType } from '../../../../../Entity/Dataset/Model/Segment';
import IconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import MenuButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import FieldPathSelector from '../../../../../Entity/Path/FieldPathEditor/FieldPathSelector';
import { primaryColor, textSecondaryColor } from '../../../../../../../@Resource/Theme/Theme';
import { EntityFieldPath } from '../../../../../Entity/Path/@Model/EntityFieldPath';
import AggregateValueEditor from './AggregateValueEditor/AggregateValueEditor';
import AggregateValue from '../../../../../Entity/View/Model/Column/AggregateValue';
import { EntityContext } from '../../../../../Entity/@Model/EntityContext';
import FilterOption from '../../../../../Entity/Dataset/Model/FilterOption';
import uuid from '../../../../../../../@Util/Id/uuid';
import Switch from '../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import DeleteIconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export interface SegmentEditorProps
{
    dataset: Dataset;
    segment: Segment;
    idx: number;
    onDelete: (segment: Segment) => void;
}

interface SegmentTypeDescriptor
{
    type: SegmentType;
    name: string;
    icon: string;
}

const SegmentEditor: React.FC<SegmentEditorProps> =
    props =>
    {
        const entityTypeStore = useContext(EntityTypeContext);

        const types =
            useMemo<SegmentTypeDescriptor[]>(
                () => [
                    {
                        type: 'List',
                        name: localizeText('Generic.List', 'Lijst'),
                        icon: 'view_list'
                    },
                    {
                        type: 'Tile',
                        name: localizeText('Generic.Tiles', 'Tegels'),
                        icon: 'view_column'
                    },
                    {
                        type: 'Pie',
                        name: localizeText('Generic.PieChart', 'Cirkeldiagram'),
                        icon: 'pie_chart'
                    },
                    {
                        type: 'Bar',
                        name: localizeText('Generic.BarChart', 'Staafdiagram'),
                        icon: 'bar_chart'
                    },
                    {
                        type: 'Line',
                        name: localizeText('Generic.LineChart', 'Grafiek'),
                        icon: 'show_chart'
                    },
                    {
                        type: 'Funnel',
                        name: localizeText('Generic.Funnel', 'Funnel'),
                        icon: 'filter_list'
                    }
                ],
                []);

        const callbackByType =
            useMemo(
                () =>
                    new Map(
                        types.map(
                            type => [
                                type,
                                () =>
                                    runInAction(
                                        () =>
                                            props.segment.type = type.type)
                            ])),
                [
                    props.segment
                ]);

        const selectGroupFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                            props.segment.groupFieldPath = fieldPath),
                [
                    props.segment
                ]);

        const onDelete =
            useCallback(
                () =>
                    props.onDelete(props.segment),
                [
                    props.onDelete,
                    props.segment
                ]);

        const onChangeAggregateValue =
            useCallback(
                (aggregateValue?: AggregateValue) =>
                    runInAction(
                        () =>
                        {
                            if (aggregateValue)
                            {
                                props.segment.aggregateValues = [ aggregateValue ];
                            }
                            else
                            {
                                props.segment.aggregateValues = [];
                            }
                        }),
                [
                    props.segment
                ]);

        const context =
            useComputed(
                () =>
                    EntityContext.fromEntityType(props.dataset.entityType),
                [
                    props.dataset
                ]);

        const setSecondaryGroupFieldPath =
            useCallback(
                (groupFieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                            props.segment.secondaryGroupFieldPath = groupFieldPath),
                [
                    props.segment
                ]);

        const clearSecondaryGroupFieldPath =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            props.segment.secondaryGroupFieldPath = undefined),
                [
                    props.segment
                ]);

        const addFilterOption =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                        {
                            (props.segment.filterOptions as IObservableArray).push(
                                new FilterOption(
                                    uuid(),
                                    fieldPath,
                                    fieldPath.field?.dataObjectSpecification.type.rangeTypeId !== undefined
                                    && fieldPath.field?.dataObjectSpecification.type.rangeTypeId() !== undefined,
                                    false));
                        }),
                [
                    props.segment
                ]);

        const deleteFilterOption =
            useCallback(
                (filterOption: FilterOption) =>
                    runInAction(
                        () =>
                            (props.segment.filterOptions as IObservableArray).remove(filterOption)),
                [
                    props.segment
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            <ViewGroupItem>
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem>
                        {props.idx + 1}. <LocalizedText code="DatasetEditor.Segment.SegmentOn" value="Segmentering op" /> {props.segment.groupFieldPath?.getName(entityTypeStore) ? props.segment.groupFieldPath.getName(entityTypeStore).toLowerCase() : '...'}
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <MenuButton
                            key={props.segment.groupFieldPath?.id}
                            icon="edit"
                            tooltip={
                                <LocalizedText
                                    code="Generic.Edit"
                                    value="Wijzigen"
                                />
                            }
                            small
                        >
                            <FieldPathSelector
                                context={context}
                                onSelect={selectGroupFieldPath}
                            />
                        </MenuButton>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <DeleteIconButton
                            onClick={onDelete}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
            <ViewGroupItem>
                <Input
                    label={
                        <LocalizedText
                            code="DatasetEditor.Segment.ViewType"
                            value="Weergavetype"
                        />
                    }
                    labelPosition="left"
                >
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                    >
                        {
                            types.map(
                                type =>
                                    <ViewGroupItem
                                        key={type.type}
                                    >
                                        <IconButton
                                            icon={type.icon}
                                            tooltip={type.name}
                                            onClick={callbackByType.get(type)}
                                            color={props.segment.type === type.type ? primaryColor : textSecondaryColor}
                                        />
                                    </ViewGroupItem>)
                        }
                    </ViewGroup>
                </Input>
            </ViewGroupItem>
            <ViewGroupItem>
                <AggregateValueEditor
                    entityType={props.dataset.entityType}
                    aggregateValue={props.segment.aggregateValues.find(() => true)}
                    onChange={onChangeAggregateValue}
                />
            </ViewGroupItem>
            {
                props.segment.type === 'Bar' &&
                    <ViewGroupItem>
                        <Input
                            labelPosition="left"
                            label={
                                <LocalizedText
                                    code="DatasetEditor.Segment.SecondaryGrouping"
                                    value="Balken onderverdelen op"
                                />
                            }
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                                alignment="center"
                            >
                                <ViewGroupItem>
                                    {props.segment.secondaryGroupFieldPath?.getName()}
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <MenuButton
                                        key={props.segment.secondaryGroupFieldPath?.id}
                                        icon="edit"
                                        tooltip={
                                            <LocalizedText
                                                code="Generic.Edit"
                                                value="Wijzigen"
                                            />
                                        }
                                        small
                                    >
                                        <FieldPathSelector
                                            context={context}
                                            onSelect={setSecondaryGroupFieldPath}
                                        />
                                    </MenuButton>
                                </ViewGroupItem>
                                {
                                    props.segment.secondaryGroupFieldPath &&
                                        <ViewGroupItem>
                                            <DeleteIconButton
                                                icon="close"
                                                onClick={clearSecondaryGroupFieldPath}
                                            />
                                        </ViewGroupItem>
                                }
                            </ViewGroup>
                        </Input>
                    </ViewGroupItem>
            }
            <ViewGroupItem>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="DatasetEditor.Segment.FilterOptions"
                            value="Gebruiker laten filteren op velden"
                        />
                    }
                >
                    <ol>
                        {
                            props.segment.filterOptions.map(
                                filterOption =>
                                    <li
                                        key={filterOption.id}
                                    >
                                        <ViewGroup
                                            orientation="horizontal"
                                            spacing={15}
                                            alignment="center"
                                        >
                                            <ViewGroupItem>
                                                {filterOption.fieldPath.getName()}
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <DeleteIconButton
                                                    icon="close"
                                                    onClick={() => deleteFilterOption(filterOption)}
                                                />
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <Input
                                                    label={
                                                        <LocalizedText
                                                            code="DatasetEditor.Segment.FilterOption.HideLabel"
                                                            value="Label verbergen"
                                                        />
                                                    }
                                                    inline
                                                    labelPosition="left"
                                                >
                                                    <Switch
                                                        checked={filterOption.isLabelHidden}
                                                        onToggle={isChecked => runInAction(() => filterOption.isLabelHidden = isChecked)}
                                                    />
                                                </Input>
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </li>)
                        }
                        <li>
                            <MenuButton
                                key={props.segment.groupFieldPath?.id}
                                icon="add"
                                tooltip={
                                    <LocalizedText
                                        code="DatasetEditor.Segment.FilterOption.Add"
                                        value="Filtermogelijkheid toevoegen"
                                    />
                                }
                                small
                            >
                                <FieldPathSelector
                                    context={context}
                                    onSelect={addFilterOption}
                                />
                            </MenuButton>
                        </li>
                    </ol>
                </Input>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(SegmentEditor);
