import PortalDataSourceQueryResultSet from '../PortalDataSourceQueryResultSet';
import performAction from '../../../Entity/performAction';
import AutomationDependencyContext from '../../../Automation/AutomationDependencyContext';
import PortalDataSourceQuery from '../PortalDataSourceQuery';
import isInPortal from '../../Api/isInPortal';

export default async function queryPortalDataSource(query: PortalDataSourceQuery): Promise<PortalDataSourceQueryResultSet>
{
    if (isInPortal())
    {
        return queryDataSourceInPortal(query);
    }
    else
    {
        return Promise.resolve(new PortalDataSourceQueryResultSet([]));
    }
}

async function queryDataSourceInPortal(query: PortalDataSourceQuery): Promise<PortalDataSourceQueryResultSet>
{
    return performAction(
        undefined,
        {
            code: 'Portal.QueryPortalDataSource',
            parameters: {
                query: query.toDescriptor()
            }
        })
        .then(
            result =>
                PortalDataSourceQueryResultSet.fromDescriptor(
                    result.result,
                    new AutomationDependencyContext(
                        query.dataSourceSignature.resultParameters)));
}
