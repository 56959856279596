import Dependency from '../../../../Automation/Parameter/Dependency';
import Validation from '../../../../Automation/Validation/Validation';
import LayoutDependencyContext from '../../../LayoutDependencyContext';
import FormInputLayout from './FormInputLayout';
import { observable } from 'mobx';
import DynamicParameterAssignment from '../../../../Automation/Function/Dynamic/DynamicParameterAssignment';
import ParameterDictionary from '../../../../Automation/Parameter/ParameterDictionary';
import Layout from '../../../Layout';
import getLayoutFromDescriptor from '../../../Api/getLayoutFromDescriptor';
import getPortalDataSourceSignatureById from '../../../../Portal/DataSource/Api/getPortalDataSourceSignatureById';
import PortalDataSourceSignature from '../../../../Portal/DataSource/PortalDataSourceSignature';
import Parameter from '../../../../Automation/Parameter/Parameter';
import DataSourceValueType from '../../../../Automation/Value/Type/DataSourceValueType';
import localizeText from '../../../../Localization/localizeText';
import getComputationFromDescriptor from '../../../../Automation/Api/getComputationFromDescriptor';
import Computation from '../../../../Automation/Function/Computation/Computation';
import Predicate from '../../../../Automation/Function/Computation/Predicate/Predicate';
import getPredicateFromDescriptor from '../../../../Automation/Api/getPredicateFromDescriptor';
import LayoutEventTriggers from '../../../Event/LayoutEventTriggers';
import PortalDataSourceOrdering from '../../../../Portal/DataSource/PortalDataSourceOrdering';
import getDependenciesWithoutParameters from '../../../../Automation/Api/getDependenciesWithoutParameters';
import AutomationDependencyContext from '../../../../Automation/AutomationDependencyContext';

export default class SelectionFormInputLayout extends FormInputLayout
{
    // ------------------------- Properties -------------------------

    @observable.ref dataSourceSignature: PortalDataSourceSignature;
    @observable.ref parameterAssignment: DynamicParameterAssignment;
    @observable.ref filter?: Predicate;
    @observable.shallow orderings: PortalDataSourceOrdering[];
    @observable.ref optionParameter: Parameter<DataSourceValueType>;
    @observable.ref optionLayout: Layout;

    // ------------------------ Constructor -------------------------

    constructor(formId: string,
                parameterId: string,
                placeholder: Computation<any, any> | undefined,
                isDisabled: Predicate | undefined,
                hasIntrinsicSize: boolean = false,
                eventTriggers: LayoutEventTriggers = undefined,
                dataSourceSignature: PortalDataSourceSignature,
                parameterAssignment: DynamicParameterAssignment,
                filter: Predicate | undefined,
                orderings: PortalDataSourceOrdering[],
                optionParameter: Parameter<DataSourceValueType>,
                optionLayout: Layout)
    {
        super(formId, parameterId, placeholder, isDisabled, hasIntrinsicSize, eventTriggers);

        this.dataSourceSignature = dataSourceSignature;
        this.parameterAssignment = parameterAssignment;
        this.filter = filter;
        this.orderings = orderings;
        this.optionParameter = optionParameter;
        this.optionLayout = optionLayout;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.SelectionFormInput', 'Input veld van formulier (selectbox)');
    }

    validate(): Validation[]
    {
        return [
            ...super.validate(),
            ...this.parameterAssignment.validate()
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...super.getDependencies(),
            ...this.parameterAssignment.getDependencies(),
            ...getDependenciesWithoutParameters(
                [
                    ...(this.filter?.getDependencies() ?? []),
                    ...this.orderings
                        .map(ordering => ordering.getDependencies())
                        .reduce((a, b) => a.concat(b), []),
                    ...this.optionLayout.getDependencies(),
                ],
                ...[
                    this.optionParameter,
                    ...this.dataSourceSignature.resultParameters.parameters
                ]
            ),
        ];
    }

    _toDescriptor(descriptor: any)
    {
        Object.assign(
            descriptor,
            {
                type: 'SelectionFormInput',
                id: this.optionParameter.id,
                dataSourceId: this.dataSourceSignature.id,
                parameterAssignment: this.parameterAssignment.toDescriptor(),
                filter: this.filter?.toDescriptor(),
                orderings: this.orderings.map(ordering => ordering.toDescriptor()),
                optionLayout: this.optionLayout.toDescriptor()
            });
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const id = descriptor.id || descriptor.dataSourceId;
        const placeholder =
            descriptor.placeholder
                ?
                    await getComputationFromDescriptor(
                        descriptor.placeholder,
                        dependencyContext)
                :
                    undefined;
        const isDisabled =
            descriptor.isDisabled
                ?
                    await getPredicateFromDescriptor(
                        descriptor.isDisabled,
                        dependencyContext)
                :
                    undefined;
        const eventTriggers =
            await LayoutEventTriggers.fromDescriptor(
                descriptor.eventTriggers || [],
                dependencyContext);

        const dataSourceId = descriptor.dataSourceId;
        const dataSourceSignature = await getPortalDataSourceSignatureById(dataSourceId);
        const parameterAssignment =
            await DynamicParameterAssignment.fromDescriptor(
                descriptor.parameterAssignment,
                dataSourceSignature.parameters,
                dependencyContext);
        const filterContext =
            new AutomationDependencyContext(
                ParameterDictionary.union(
                    dataSourceSignature.resultParameters,
                    dependencyContext.parameterDictionary
                )
            );
        const filter =
            descriptor.filter
                ? await getPredicateFromDescriptor(
                    descriptor.filter,
                    filterContext
                )
                : undefined;
        const orderingDependencyContext = new AutomationDependencyContext(dataSourceSignature.resultParameters);
        const orderings =
            await Promise.all<PortalDataSourceOrdering>(
                (descriptor.orderings || []).map(
                    ordering =>
                        PortalDataSourceOrdering.fromDescriptor(
                            ordering,
                            orderingDependencyContext)));
        const optionParameter =
            SelectionFormInputLayout.getOptionParameter(
                id,
                dataSourceSignature
            );
        const optionLayout =
            await getLayoutFromDescriptor(
                descriptor.optionLayout,
                dependencyContext.withParameterDictionary(
                    ParameterDictionary.union(
                        dependencyContext.parameterDictionary,
                        dataSourceSignature.resultParameters,
                        new ParameterDictionary([ optionParameter ])
                    )
                )
            );

        return new SelectionFormInputLayout(
            descriptor.formId,
            descriptor.parameterId,
            placeholder,
            isDisabled,
            descriptor.hasIntrinsicSize,
            eventTriggers,
            dataSourceSignature,
            parameterAssignment,
            filter,
            orderings,
            optionParameter,
            optionLayout);
    }

    static getOptionParameter(id: string,
                              dataSourceSignature: PortalDataSourceSignature): Parameter<DataSourceValueType>
    {
        return new Parameter<DataSourceValueType>(
            id,
            new DataSourceValueType(
                dataSourceSignature.id,
                undefined,
                dataSourceSignature),
            true,
            `Optie van ${dataSourceSignature.name}`);
    }

    // ----------------------- Private logic ------------------------
}
