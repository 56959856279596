import { EntityFieldPath } from '../../../../../Entity/Path/@Model/EntityFieldPath';
import { observable } from 'mobx';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import { Cardinality } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { registerBuilder } from '../../../../../../../@Util/TransactionalModelV2/Shared/TransactionalBuilder';
import { LocalizedTextValue } from '../../../../../../../@Api/Localization/LocalizedTextValue';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';

export class Field
{
    @observable id: string;
    @observable.ref dataType: EntityType;
    @observable isParent: boolean;
    @observable cardinality: Cardinality;
    @observable name: LocalizedTextValue;
    @observable inverseName: LocalizedTextValue;
    @observable isEditable: boolean;
    @observable isVisibleDuringConstruction: boolean;
    @observable isVisibleInDetails: boolean;
    @observable isVisibleAsTab: boolean;
    @observable.ref pack: Entity;
    @observable.ref fieldPath?: EntityFieldPath;

    constructor(id: string,
                dataType: EntityType,
                isParent: boolean,
                cardinality: Cardinality,
                name: LocalizedTextValue,
                inverseName: LocalizedTextValue,
                isEditable: boolean,
                isVisibleDuringConstruction: boolean,
                isVisibleInDetails: boolean,
                isVisibleAsTab: boolean,
                pack: Entity,
                fieldPath?: EntityFieldPath)
    {
        this.id = id;
        this.dataType = dataType;
        this.isParent = isParent;
        this.cardinality = cardinality;
        this.name = name;
        this.inverseName = inverseName;
        this.isEditable = isEditable;
        this.isVisibleDuringConstruction = isVisibleDuringConstruction;
        this.isVisibleInDetails = isVisibleInDetails;
        this.isVisibleAsTab = isVisibleAsTab;
        this.pack = pack;
        this.fieldPath = fieldPath;
    }

    get isNew()
    {
        return this.fieldPath === undefined;
    }

    get isPlural()
    {
        if (this.isParent)
        {
            switch (this.cardinality)
            {
                case Cardinality.OneToOne:
                case Cardinality.OneToMany:
                    return false;

                case Cardinality.ManyToOne:
                    return true;

                case Cardinality.ManyToMany:
                    return true;
            }
            return false;
        }
        else
        {
            switch (this.cardinality)
            {
                case Cardinality.OneToOne:
                    return false;

                case Cardinality.OneToMany:
                    return true;

                case Cardinality.ManyToOne:
                    return false;

                case Cardinality.ManyToMany:
                    return true;
            }
            return false;
        }
    }
}

registerBuilder(Field).includeAll();
