import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import InvalidLayout from '../../../../../@Api/Layout/Type/InvalidLayout';
import InvalidLayoutView from './InvalidLayoutView';

export interface InvalidLayoutEditorProps extends LayoutEditorProps<InvalidLayout>
{

}

const InvalidLayoutEditor: React.FC<InvalidLayoutEditorProps> =
    ({
        layout,
     }) =>
    {
        return <InvalidLayoutView
            layout={layout}
            editMode
        />;
    };

export default observer(InvalidLayoutEditor);
