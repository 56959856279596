import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { PasswordPolicy } from '../../../@Component/Domain/Security/Model/PasswordPolicy';
import { PasswordPolicyValidationResult, PasswordPolicyValidationType } from '../../../@Component/Domain/Security/Model/PasswordPolicyValidationResult';

export class AccountPublicController
{
    registerNameChange(token: string, currentEmailAddress: string, newEmailAddress: string) {
        throw new Error("Method not implemented.");
    }
    apiClient: ApiClient;

    constructor(apiClient: ApiClient)
    {
        this.apiClient = apiClient;
    };

    shouldRegistrationTokenBeUserInvitation(token: string): Promise<boolean>
    {
        return new Promise<boolean>(
            (resolve, reject) =>
                this.apiClient
                    .request(
                        new ApiRequest<string>('/public/account/should-registration-token-be-user-invitation',
                            Method.Get,
                            {
                                token,
                            }
                        )
                    )
                    .then(
                        result =>
                            resolve(fromJson(result))
                    )
                    .catch(
                        reason =>
                            reject(reason)
                    )
        );
    }

    getUserInvitationUrlByRegistrationToken(token: string): Promise<string>
    {
        return new Promise<string>(
            (resolve, reject) =>
                this.apiClient
                    .request(
                        new ApiRequest<string>('/public/account/get-user-invitation-url-by-registration-token',
                            Method.Get,
                            {
                                token,
                            }
                        )
                    )
                    .then(
                        result =>
                            resolve(fromJson(result))
                    )
                    .catch(
                        reason =>
                            reject(reason)
                    )
        );
    }

    registerAccount(token: string,
                    emailAddress: string,
                    password: string,
                    firstName: string,
                    lastName: string,
                    languageCode: string): Promise<any>
    {
        return new Promise<string>(
            (resolve, reject) =>
            {
                this.apiClient
                    .request(
                        new ApiRequest<string>('/public/account/register',
                            Method.Post,
                            {
                                token: token,
                                email_address: emailAddress,
                                password: password,
                                first_name: firstName,
                                last_name: lastName,
                                language_code: languageCode
                        }))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

    requestPasswordReset(
        emailAddress: string,
        languageCode?: string,
        organizationId?: string,
        portalId?: string
    ): Promise<boolean>
    {
        return new Promise<boolean>(
            (resolve, reject) =>
            {
                this.apiClient
                    .request(
                        new ApiRequest<any>('/public/account/request-password-reset',
                            Method.Post,
                            {
                                email_address: emailAddress,
                                language_code: languageCode,
                                organization_id: organizationId,
                                portal_id: portalId,
                            }))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

    resetPassword(token: string,
                  emailAddress: string,
                  password: string): Promise<boolean>
    {
        return new Promise<boolean>(
            (resolve, reject) =>
            {
                this.apiClient
                    .request(
                        new ApiRequest<any>('/public/account/reset-password',
                            Method.Post,
                            {
                                token: token,
                                email_address: emailAddress,
                                password: password
                            }))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };
    resendValidationLink(emailAddress: string,
                         languageCode: string): Promise<boolean>
    {
        return new Promise<boolean>(
            (resolve, reject) =>
            {
                this.apiClient
                    .request(
                        new ApiRequest<any>('/public/account/resend-validation-link',
                            Method.Post,
                            {
                                email_address: emailAddress
                            }))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

    validatePasswordPolicy(
        token: string,
        emailAddress: string,
        password: string,
        validationType: PasswordPolicyValidationType,
        policy: PasswordPolicy
    ): Promise<PasswordPolicyValidationResult>
    {
        return new Promise<PasswordPolicyValidationResult>(
            (resolve, reject) =>
            {
                this.apiClient
                    .request(
                        new ApiRequest<PasswordPolicyValidationResult>(
                            '/public/account/validate-password-policy',
                            Method.Post,
                            {
                                token: token,
                                email_address: emailAddress,
                                password: password,
                                validation_type: validationType,
                                policy: JSON.stringify(policy),
                            })
                    )
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

    registerNewUsername(
        token: string,
        currentUsername: string,
        newUsername: string
    ): Promise<any>
    {
        return new Promise<string>(
            (resolve, reject) =>
            {
                this.apiClient
                    .request(
                        new ApiRequest<string>('/public/account/change-username',
                            Method.Post,
                            JSON.stringify({
                                token: token,
                                currentUsername: currentUsername,
                                newUsername: newUsername
                            }),
                            undefined,
                            true,
                            'application/json'
                        )
                    )
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };
}
