import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { runInAction } from 'mobx';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import styles from './ConditionalComputationEditor.module.scss';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import ConditionalComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/ConditionalComputation';
import ConditionalEditor from './Conditional/ConditionalEditor';
import Value from '../../../../../../../../../../@Api/Automation/Value/Value';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface ConditionalComputationEditorProps extends ComputationEditorProps<ValueType<any>, ConditionalComputation>
{

}

const ConditionalComputationEditor: React.FC<ConditionalComputationEditorProps> =
    props =>
    {
        const setOtherwise =
            useCallback(
                (value: Value<any, any>) =>
                    runInAction(
                        () =>
                        {
                            props.value.otherwise = value;
                        }),
                [
                    props.value
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            {
                props.value.conditionals.map(
                    conditional =>
                        <ViewGroupItem
                            key={conditional.id}
                        >
                            <ConditionalEditor
                                {...props}
                                conditional={conditional}
                            />
                        </ViewGroupItem>)
            }
            <ViewGroupItem>
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem>
                        <LocalizedText
                            code="Generic.Otherwise"
                            value="Anders"
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <div
                            className={styles.side}
                        >
                            <ComputationEditor
                                type={props.type}
                                value={props.value.otherwise}
                                onChange={setOtherwise}
                                context={props.context}
                            />
                        </div>
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(ConditionalComputationEditor);
