import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { AuthenticationEntryPoint } from '../Model/AuthenticationEntryPoint';

export function getAuthenticationEntryPoint(
    apiClient: ApiClient
): Promise<AuthenticationEntryPoint>
{
    return apiClient.request(
        new ApiRequest<AuthenticationEntryPoint>(
            '/authenticationEntryPoint',
            Method.Get
        )
    );
}