import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import { Comparator } from '../../../../DataObject/Model/Comparator';
import { LogicalOperator } from '../../../../DataObject/Model/LogicalOperator';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../Type/EntityTypeStore';
import Segment from '../../Model/Segment';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import PrimitiveValue from '../../../../../../@Api/Automation/Value/PrimitiveValue';
import EntityValue from '../../../../../../@Api/Automation/Value/EntityValue';
import CompositePredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import EmptyValue from '../../../../../../@Api/Automation/Value/EmptyValue';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../@Api/Automation/Value/Type/EntityValueType';

export default function getResultPredicate(
    parameter: Parameter<EntityValueType>,
    segment: Segment,
    result?: EntitySelectionAggregateResult
)
{
    const entityTypeStore = loadModuleDirectly(EntityTypeStore);

    if (result)
    {
        if (segment)
        {
            if (result.groupValue == null && result.groupEntity == null)
            {
                return new ComparisonPredicate(
                    new ValueFromEntityComputation(
                        parameter,
                        segment.groupFieldPath.field
                            ?
                                segment.groupFieldPath
                            :
                                segment.groupFieldPath.path.field(entityTypeStore.idField)),
                    Comparator.IsNotDefined,
                    EmptyValue.instance);
            }
            else if (result.groupValue != null)
            {
                return new ComparisonPredicate(
                    new ValueFromEntityComputation(
                        parameter,
                        segment.groupFieldPath),
                    segment.groupFieldPath?.field?.dataObjectSpecification.type.rangeTypeId &&
                    segment.groupFieldPath?.field?.dataObjectSpecification.type.rangeTypeId() === result.groupValue.specification.type.id()
                        ?
                            Comparator.In
                        :
                            Comparator.Equals,
                    new PrimitiveValue(result.groupValue));
            }
            else if (result.groupEntity != null)
            {
                return new ComparisonPredicate(
                    new ValueFromEntityComputation(
                        parameter,
                        segment.groupFieldPath.path.field()),
                    Comparator.Equals,
                    new EntityValue(result.groupEntity));
            }
            else
            {
                throw new Error('The impossible happened.');
            }
        }
        else
        {
            return new CompositePredicate(
                LogicalOperator.And,
                []);
        }
    }
    else
    {
        return undefined;
    }
}
