import ValueType from './ValueType';
import getValueTypeFromDescriptor from '../../Api/getValueTypeFromDescriptor';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import localizeText from '../../../Localization/localizeText';

export default class CollectionType<T> extends ValueType<ValueType<T>>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return `Collection(${this.type.id()})`;
    }

    isA(type: ValueType<any>): boolean
    {
        return type instanceof CollectionType;
    }

    getName(): string
    {
        return localizeText(
            'CollectionOf',
            'Collectie van ${name}',
            {
                name: this.type?.getName() || '...'
            }
        );
    }

    toDescriptor()
    {
        return {
            type: 'Collection',
            elementType: this.type.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new CollectionType<any>(
            await getValueTypeFromDescriptor(
                descriptor.elementType,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
