import Cell from './Cell';

export default class TrackCell
{
    // ------------------------- Properties -------------------------

    cell: Cell;
    offset: number;
    width: number;

    // ------------------------ Constructor -------------------------

    constructor(cell: Cell, offset: number, width: number)
    {
        this.cell = cell;
        this.offset = offset;
        this.width = width;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
