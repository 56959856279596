import React, { useCallback, useEffect, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import { SelectionCursor } from '../SelectionCursor';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import EntityViewer, { EntityViewerCommand } from '../../EntityViewer';
import SelectionCursorHandle from '../SelectionCursorHandle/SelectionCursorHandle';
import useLocalSetting from '../../../../Setting/Api/useLocalSetting';
import styles from './ViewerWithSelectionCursor.module.scss';
import SelectionCursorBrowser from '../SelectionCursorBrowser/SelectionCursorBrowser';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../../../../@Resource/Theme/Theme';
import IsBaseLayoutFullScreenOnXl from '../../Shared/BaseLayout/IsBaseLayoutFullScreenOnXl';

export interface ViewerWithSelectionCursorProps
{
    entity: Entity;
    command?: EntityViewerCommand;
    cursor?: SelectionCursor;
}

const ViewerWithSelectionCursor: React.FC<ViewerWithSelectionCursorProps> =
    props =>
    {
        const [ isOpen, setOpen ] = useLocalSetting('IsSelectionCursorOpen', false);
        const [ openedEntity, setOpenedEntity ] = useState(props.entity);

        useEffect(
            () =>
            {
                setOpenedEntity(props.entity);
            },
            [
                setOpenedEntity,
                props.entity
            ]);

        const close =
            useCallback(
                () =>
                    setOpen(false),
                [
                    setOpen
                ]);

        const viewer =
            <EntityViewer
                entity={openedEntity}
                inset
                multiplayerView
                command={props.command}
            />;

        const isHidden = useMediaQuery(theme.breakpoints.down('md'));

        if (isHidden || !props.cursor)
        {
            return viewer;
        }
        else
        {
            return <div>
                <ViewGroup
                    orientation="horizontal"
                    spacing={25}
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <IsBaseLayoutFullScreenOnXl.Provider
                            value={isOpen}
                        >
                            {viewer}
                        </IsBaseLayoutFullScreenOnXl.Provider>
                    </ViewGroupItem>
                    {
                        isOpen &&
                            <ViewGroupItem
                                className={styles.browser}
                            >
                                <div
                                    className={styles.innerBrowser}
                                >
                                    <SelectionCursorBrowser
                                        entity={props.entity}
                                        cursor={props.cursor}
                                        onClose={close}
                                        onOpen={setOpenedEntity}
                                        highlightedEntity={openedEntity}
                                    />
                                </div>
                            </ViewGroupItem>
                    }
                </ViewGroup>
                {
                    props.cursor &&
                        <SelectionCursorHandle
                            entity={props.entity}
                            cursor={props.cursor}
                            open={isOpen}
                            onToggle={setOpen}
                        />
                }
            </div>;
        }
    };

export default observer(ViewerWithSelectionCursor);
