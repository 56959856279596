import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import Trigger from '../../../../../@Api/Automation/Trigger/Trigger';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import DeletionTrigger from '../../../../../@Api/Automation/Trigger/DeletionTrigger';
import { AssistantOnDeleteTrigger } from '../../Model/Automation/Trigger/AssistantOnDeleteTrigger';

export function findAssistantOnDeleteTrigger(
    trigger: AssistantOnDeleteTrigger,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Trigger
{
    return new DeletionTrigger(
        rootParameter.type.type,
        undefined
    );
}
