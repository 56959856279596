import * as React from 'react';
import { BaseComponent } from '../../../../../../@Framework/Component/BaseComponent';
import { EntityAggregateComputationEditorStore } from './EntityAggregateComputationEditorStore';
import { observer } from 'mobx-react';
import { Button, Card, ClickAwayListener, Grid, Grow, Icon, IconButton, MenuItem, MenuList, Paper } from '@material-ui/core';
import { ComputationEditor } from '../../../ComputationEditor';
import { blue } from '../../../../../../@Resource/Theme/Blue';
import { grey, yellow } from '@material-ui/core/colors';
import { Aggregate } from '../../../../DataObject/Model/Aggregate';
import { getAggregateSymbol } from '../../../../DataObject/Util/SymbolUtils';
import { PopperReference } from '../../../../../Generic/Popper/PopperReference';
import { PopperContainer } from '../../../../../Generic/Popper/PopperContainer';
import Text from '../../../../../Generic/Text/Text';
import { PopperManager } from '../../../../../Generic/Popper/PopperManager';
import Menu from '../../../../../Generic/Menu/Menu';

@observer
export class EntityAggregateComputationEditor extends BaseComponent<EntityAggregateComputationEditorStore>
{
    renderComponent(store: EntityAggregateComputationEditorStore)
    {
        return <Grid container spacing={0}>
            <Grid item xs={12}>
                <Paper
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: yellow[100],
                        borderRadius: 10,
                        paddingRight: 15
                    }}
                >
                    <IconButton>
                        <Icon>filter_list</Icon>
                    </IconButton>
                    <div style={{ display: 'flex', flex: '1 1 auto', alignItems: 'center', marginRight: 50 }}>
                        <Text store={store.aggregateAllTextStore} />
                        {
                            this.renderEntityPath(store)
                        }
                    </div>
                    {
                        this.renderAggregate(store)
                    }
                </Paper>
            </Grid>
            {
                store.aggregate !== Aggregate.Count &&
                    [
                        <Grid key="computation" item xs={12} style={{ display: 'flex', minHeight: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 50 }}>
                                <div
                                    style={{
                                        backgroundColor: grey[500],
                                        width: 1,
                                        height: '100%'
                                    }}
                                />
                            </div>
                            <div style={{ flex: '1 1 auto' }}>
                                {
                                    store.computationStore &&
                                        <div
                                            style={{
                                                position: 'relative',
                                                margin: 10
                                            }}
                                        >
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    backgroundColor: grey[500],
                                                    width: 35,
                                                    height: 1,
                                                    left: -35,
                                                    top: 25
                                                }}
                                            />
                                            <Paper
                                                style={{
                                                    display: 'inline-flex',
                                                    flex: '1 1 auto',
                                                    background: store.computationStore.type.isTerminal() ? 'white' : 'none',
                                                    borderRadius: store.computationStore.type.isTerminal() ? 10 : undefined,
                                                    paddingLeft: store.computationStore.type.isTerminal() ? 5 : undefined
                                                }}
                                                elevation={store.computationStore.type.isTerminal() ? undefined : 0}
                                            >
                                                <div style={{ flex: '1 1 auto', display: 'inline-flex', alignItems: 'center' }}>
                                                   <ComputationEditor store={store.computationStore} />
                                                </div>
                                                <div>
                                                    <IconButton onClick={store.deleteComputation}>
                                                        <Icon>remove_circle</Icon>
                                                    </IconButton>
                                                </div>
                                            </Paper>
                                        </div>
                                }
                            </div>
                        </Grid>,
                        <Grid key="addComputation" item xs={12}>
                            <Paper
                                style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    backgroundColor: blue[100],
                                    borderRadius: 10
                                }}
                            >
                                <PopperManager>
                                    <PopperReference>
                                        <Button
                                            onClick={store.openAddMenu}
                                        >
                                            <Icon style={{ marginRight: 10 }}>
                                                edit
                                            </Icon>
                                            {
                                                store.localizationStore.translate('Computation.SpecifyCalculation') // Specify calculation
                                            }
                                        </Button>
                                    </PopperReference>
                                    <PopperContainer
                                        placement="bottom-start"
                                        eventsEnabled={store.isAddMenuOpen}
                                        style={{ zIndex: 10 }}
                                    >
                                        {
                                            store.isAddMenuOpen &&
                                                <ClickAwayListener onClickAway={store.closeAddMenu}>
                                                    <div>
                                                        <Grow in={true}>
                                                            <Paper>
                                                                <MenuList role="menu">
                                                                    {
                                                                        store.computationTypeStore.types
                                                                            .filter(type => type.allow(store.context))
                                                                            .map(type => (
                                                                                <MenuItem
                                                                                    key={type.id()}
                                                                                    onClick={(e: any) =>
                                                                                    {
                                                                                        store.setComputation(type);
                                                                                        store.closeAddMenu();

                                                                                        e.stopPropagation();
                                                                                    }}
                                                                                >
                                                                                    {type.name()}
                                                                                </MenuItem>))
                                                                    }
                                                                </MenuList>
                                                            </Paper>
                                                        </Grow>
                                                    </div>
                                                </ClickAwayListener>
                                        }
                                    </PopperContainer>
                                </PopperManager>
                            </Paper>
                        </Grid>
                    ]
            }
        </Grid>;
    }

    renderEntityPath(store: EntityAggregateComputationEditorStore)
    {
        return <PopperManager>
            <PopperReference>
                <Button
                    onClick={store.openEntityPathEditor}
                    size="small"
                >
                    {store.entityPath.getName(store.entityTypeStore, false)}
                </Button>
            </PopperReference>
            <PopperContainer
                placement="bottom-start"
                eventsEnabled={store.isAggregateMenuOpen}
                style={{ zIndex: 10 }}
            >
                {
                    store.isEntityPathEditorOpen &&
                        <ClickAwayListener onClickAway={store.closeEntityPathEditor}>
                            <div>
                                <Grow in={true}>
                                    <Card>
                                        <Menu
                                            store={store.entityPathPickerStore}
                                        />
                                    </Card>
                                </Grow>
                            </div>
                        </ClickAwayListener>
                }
            </PopperContainer>
        </PopperManager>;
    }

    renderAggregate(store: EntityAggregateComputationEditorStore)
    {
        return <PopperManager>
            <PopperReference>
                <Button
                    onClick={store.openAggregateMenu}
                    size="small"
                >
                    {getAggregateSymbol(store.aggregate)}
                </Button>
            </PopperReference>
            <PopperContainer
                placement="bottom-start"
                eventsEnabled={store.isAggregateMenuOpen}
                style={{ zIndex: 10 }}
            >
                {
                    store.isAggregateMenuOpen &&
                        <ClickAwayListener onClickAway={store.closeAggregateMenu}>
                            <div >
                                <Grow in={true}>
                                    <Paper>
                                        <MenuList role="menu">
                                            {
                                                Object.keys(Aggregate)
                                                    .map(k => Aggregate[k as any])
                                                    .filter(k => isNaN(k as any))
                                                    .map(aggregate => (
                                                        <MenuItem
                                                            key={aggregate}
                                                            onClick={(e: any) =>
                                                            {
                                                                store.setAggregate((Aggregate as any)[aggregate]);
                                                                store.closeAggregateMenu();

                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            {getAggregateSymbol((Aggregate as any)[aggregate])}
                                                        </MenuItem>))
                                            }
                                        </MenuList>
                                    </Paper>
                                </Grow>
                            </div>
                        </ClickAwayListener>
                }
            </PopperContainer>
        </PopperManager>;
    }
}
