import { observer } from 'mobx-react';
import InputGroup from '../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import TextEditor from '../../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import React, { useEffect, useState } from 'react';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import { MailboxConfiguration } from './MailboxConfig';
import Switch from '../../../../../../../@Future/Component/Generic/Input/Switch/Switch';

export interface MicrosoftMailboxDetailConfigProps
{
    configuration: any;
    onChange: (config: MailboxConfiguration, credentials: any) => void;
}

export const MicrosoftMailboxDetailConfig: React.FC<MicrosoftMailboxDetailConfigProps> = observer(
    ({
        configuration,
        onChange
     }) =>
    {

        const [ localCredentials, setLocalCredentials ] = useState<any>(undefined);
        const [ localConfiguration, setLocalConfiguration ] = useState<any>(configuration);

        useEffect(
            () =>
            {
                onChange(localConfiguration, localCredentials);
            },
            [
                localCredentials,
                localConfiguration,
                onChange
            ]
        );

        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            <ViewGroupItem>
                <InputGroup>
                    <Input
                        label={
                            <LocalizedText
                                code="Mailbox.Microsoft.Active"
                                value="Actief"
                            />
                        }
                        labelPosition="left"
                    >
                        <Switch
                            checked={configuration.isActive}
                            onToggle={
                                (value) =>
                                    setLocalConfiguration({...configuration, isActive: value})
                            }
                        />
                    </Input>
                </InputGroup>
            </ViewGroupItem>

            <ViewGroupItem>
                <InputGroup>
                    <Input
                        label={
                            <LocalizedText
                                code="Mailbox.Microsoft.TenantId"
                                value="Tenant id"
                            />
                        }
                        labelPosition="left"
                    >
                        <TextEditor
                            value={configuration.tenantId}
                            onChange={
                                (value) =>
                                    setLocalConfiguration({...configuration, tenantId: value})
                            }
                        />
                    </Input>
                </InputGroup>
            </ViewGroupItem>

            <ViewGroupItem>
                <InputGroup>
                    <Input
                        label={
                            <LocalizedText
                                code="Mailbox.Microsoft.ApplicationId"
                                value="Application id"
                            />
                        }
                        labelPosition="left"
                    >
                        <TextEditor
                            value={configuration.applicationId}
                            onChange={
                                (value) =>
                                    setLocalConfiguration({...configuration, applicationId: value})
                            }
                        />
                    </Input>
                </InputGroup>
            </ViewGroupItem>

            <ViewGroupItem>
                <InputGroup>
                    <Input
                        label={
                            <LocalizedText
                                code="Mailbox.Microsoft.Secret"
                                value="Secret"
                            />
                        }
                        labelPosition="left"
                    >
                        <TextEditor
                            type="password"
                            value={localCredentials?.secret}
                            onChange={
                                (value) =>
                                    value && value.length > 0
                                        ? setLocalCredentials({ secret: value })
                                        : setLocalCredentials(undefined)
                            }
                        />
                    </Input>
                </InputGroup>
            </ViewGroupItem>

            <ViewGroupItem>
                <InputGroup>
                    <Input
                        label={
                            <LocalizedText
                                code="Mailbox.Microsoft.EmailAddress"
                                value="Email adres"
                            />
                        }
                        labelPosition="left"
                    >
                        <TextEditor
                            type="email"
                            value={configuration.emailAddress}
                            onChange={
                                (value) =>
                                    setLocalConfiguration({...configuration, emailAddress: value})
                            }
                        />
                    </Input>
                </InputGroup>
            </ViewGroupItem>

        </ViewGroup>
   }
);