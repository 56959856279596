import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Default from '../Default';
import { ContentProps } from '../Content';
import PortalUserSettingsEditor from '../../../Viewer/Content/PortalUser/UserSettings/PortalUserSettingsEditor';
import Header from '../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import ExpansionPanel from '../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import uuid from '../../../../../../@Util/Id/uuid';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface PortalUserProps extends ContentProps
{

}

const PortalUser: React.FC<PortalUserProps> =
    props =>
    {
        const id = useMemo(() => uuid(), []);

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <Default
                    {...props}
                    entity={props.entity}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <ExpansionPanel
                    id={id}
                    summary={
                        <Header
                            title={
                                <LocalizedText
                                    code="Generic.Settings"
                                    value="Instellingen"
                                />
                            }
                        />
                    }
                    expansion={
                        <PortalUserSettingsEditor
                            portalUser={props.entity}
                            commitContext={props.commitContext}
                        />
                    }
                    expanded
                />
            </ViewGroupItem>
        </ViewGroup>
    };

export default observer(PortalUser);
