import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { RouterStore } from '../../../../@Service/Router/RouterStore';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';

export function openView(entityType: EntityType,
                         view: Entity)
{
    return loadModuleDirectly(RouterStore).route(`/type/${entityType.id}/view/${view.uuid}`);
}
