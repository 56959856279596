import React from 'react';
import styles from './DayDurationPicker.module.scss';
import ViewGroup from '../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import NumberEditor from '../NumberEditor/NumberEditor';
import { observer } from 'mobx-react-lite';
import LocalizedText from '../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface DayDurationPickerProps
{
    value?: number;
    onChange?: (days?: number) => void;
    autoFocus?: boolean;
}

const DayDurationPicker: React.FC<DayDurationPickerProps> =
    props =>
    {
        return <ViewGroup
            orientation="horizontal"
            alignment="center"
            spacing={20}
        >
            <ViewGroupItem>
                <div
                    className={styles.editor}
                >
                    <NumberEditor
                        value={props.value}
                        onChange={props.onChange}
                        min={0}
                        autoFocus={props.autoFocus}
                    />
                </div>
                <span
                    className={styles.label}
                >
                    {
                        props.value === 1
                            ?
                                <LocalizedText
                                    code="Generic.Day"
                                    value="Dag"
                                    lowerCase
                                />
                            :
                                <LocalizedText
                                    code="Generic.Days"
                                    value="Dagen"
                                    lowerCase
                                />
                    }
                </span>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(DayDurationPicker);
