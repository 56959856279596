import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { computed, observable } from 'mobx';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import FieldMapping from './FieldMapping/FieldMapping';
import Key from './Key';
import resolveFieldMapping from './FieldMapping/Api/resolveFieldMapping';
import getDefaultKeys from '../Api/getDefaultKeys';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';

export default class TypeMapping
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable entityType: EntityType;
    @observable fieldMappings: FieldMapping[];
    @observable keys: Key[];
    @observable isCreationDisabled: boolean;
    @observable isUpdateEnabled: boolean;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                entityType: EntityType,
                fieldMappings: FieldMapping[] = [],
                keys: Key[] = getDefaultKeys(entityType),
                isCreationDisabled: boolean = false,
                isUpdateEnabled: boolean = false)
    {
        this.id = id;
        this.entityType = entityType;
        this.fieldMappings = fieldMappings;
        this.keys = keys;
        this.isCreationDisabled = isCreationDisabled;
        this.isUpdateEnabled = isUpdateEnabled;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get fieldMappingByTargetFieldPathId()
    {
        return new Map(
            this.fieldMappings.map(
                fieldMapping => [
                    fieldMapping.targetFieldPath.id,
                    fieldMapping
                ]));
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext,
    )
    {
        return new TypeMapping(
            descriptor.id,
            loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.entityTypeId),
            await Promise.all(
                descriptor.fieldMappings
                    .map(
                        fieldMapping =>
                            resolveFieldMapping(
                                fieldMapping,
                                dependencyContext
                            )
                    )
                    .filter(
                        fieldMapping =>
                            fieldMapping !== undefined
                    )
            ),
            await Promise.all(
                descriptor.keys
                    .map(
                        key =>
                            Key.fromDescriptor(
                                key,
                                dependencyContext
                            )
                    )
            ),
            descriptor.isCreationDisabled,
            descriptor.isUpdateEnabled
        );
    }

    toDescriptor()
    {
        return {
            id: this.id,
            entityTypeId: this.entityType.id,
            fieldMappings:
                this.fieldMappings.map(
                    fieldMapping =>
                        fieldMapping.toDescriptor()),
            keys:
                this.keys.map(
                    key =>
                        key.toDescriptor()),
            isCreationDisabled: this.isCreationDisabled,
            isUpdateEnabled: this.isUpdateEnabled
        };
    }

    getAllTypeMappings(): TypeMapping[]
    {
        return [
            this,
            ...this.fieldMappings
                .map(
                    fieldMapping =>
                        fieldMapping.getAllTypeMappings())
                .reduce((a, b) => a.concat(b), [])
        ];
    }

    getAllFieldMappings(): FieldMapping[]
    {
        return this.fieldMappings
            .map(
                fieldMapping =>
                    fieldMapping.getAllFieldMappings())
            .reduce((a, b) => a.concat(b), []);
    }

    // ----------------------- Private logic ------------------------
}
