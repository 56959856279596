import { useContext } from 'react';
import useAsyncResult, { AsyncResult } from '../../../../../@Util/Async/useAsyncResult';
import CurrentUserContext from '../../../User/CurrentUserContext';
import performAction from '../../../../../@Api/Entity/performAction';
import { Currency } from '../../../../../@Api/Localization/Currency';

export function useContractCurrency(): AsyncResult<Currency>
{
    const currentUserStore = useContext(CurrentUserContext);

    return useAsyncResult<Currency>(
        () =>
            performAction(
                currentUserStore.contractEntity,
                {
                    code: 'LicenseContract.GetCurrency'
                })
                .then(
                    result =>
                        result.result
                ),
        [
            currentUserStore.contractEntity
        ]);
}
