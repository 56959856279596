import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ExternalEmail from '../Model/ExternalEmail';
import styles from './LinkExternalEmail.module.scss';
import CreateEmail from './CreateEmail/CreateEmail';
import PrimaryButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import useEmailsByExternalId from '../Api/useEmailsByExternalId';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardedList from '../../../../../List/V2/Carded/CardedList';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';

export interface LinkExternalEmailProps
{
    externalEmail: ExternalEmail;
}

export const emailType = 'Email';

const LinkExternalEmail: React.FC<LinkExternalEmailProps> =
    props =>
    {
        const linkedEmails =
            useEmailsByExternalId(
                props.externalEmail.service,
                emailType,
                props.externalEmail.id
            );
        const [isLinking, setLinking] = useState<boolean>();
        const showCreateEmail =
            useCallback(
                (show: boolean) =>
                    setLinking(show),
                []
            );

        if (linkedEmails && linkedEmails.length > 0 && !isLinking)
        {
            return <div
                className={styles.root}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={16}
                >
                    <ViewGroupItem>
                        {
                            linkedEmails &&
                                <CardedList
                                    entities={linkedEmails}
                                />
                        }
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <PrimaryButton
                            label={
                                <LocalizedText
                                    code="LinkExternalEmailAgain"
                                    value="Nogmaals koppelen"
                                />
                            }
                            onClick={() => showCreateEmail(true)}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </div>;
        }
        else
        {
            return <div
                className={styles.root}
            >
                <CreateEmail
                    externalEmail={props.externalEmail}
                    onMailLinked={() => showCreateEmail(false)}
                />
            </div>;
        }
    };

export default observer(LinkExternalEmail);
