import React from 'react';
import IconButton, { IconButtonProps } from '../Icon/IconButton';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface DownloadIconButtonProps extends Partial<IconButtonProps>
{

}

const DownloadIconButton: React.FC<DownloadIconButtonProps> =
    props =>
    {
        return <IconButton
            icon="cloud_download"
            onClick={props.onClick}
            tooltip={
                <LocalizedText
                    code="Generic.Download"
                    value="Downloaden"
                />
            }
            {...props}
        />;
    };

export default DownloadIconButton;
