import { DataObjectBespokeEditorStore } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { computed } from 'mobx';
import { EntityFieldPathPickerStore } from '../../../../Entity/Path/Field/EntityFieldPathPickerStore';
import { ButtonStore } from '../../../../../Generic/Button/ButtonStore';
import { ViewComponent } from '../../../../../Generic/ViewStack/Model/ViewComponent';
import Menu from '../../../../../Generic/Menu/Menu';
import { EntityPath } from '../../../../Entity/Path/@Model/EntityPath';
import { DataObject } from '../../../Model/DataObject';
import { FormEvent } from '../../../../../Generic/Form/FormEvent';
import { EntityTypeStore } from '../../../../Entity/Type/EntityTypeStore';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';

export class EntityPathEditorStore extends DataObjectBespokeEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    @computed
    get buttonStore(): ButtonStore
    {
        if (this.baseStore.dataObject.context.entityContext)
        {
            return new ButtonStore(
            {
                label:
                    () =>
                        this.baseStore.dataObject.isEmpty
                            ?
                                '...'
                            :
                                (this.baseStore.dataObject.value as EntityPath).getName(this.entityTypeStore, false),
                onClick:
                    store =>
                        store.openPopperView(
                            new ViewComponent(
                                Menu as any,
                                new EntityFieldPathPickerStore(
                                    this.baseStore.dataObject.context.entityContext,
                                    path =>
                                    {
                                        store.closePopperView();

                                        this.baseStore.dataObject.setValue(path.path);

                                        this.baseStore.handlerContext.perform(
                                            new FormEvent<DataObject>(
                                                FormEvent.Change,
                                                this.baseStore.dataObject));
                                    })))
            });
        }
        else
        {
            return null;
        }
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
