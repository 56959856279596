import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import { runInAction } from 'mobx';
import { Field } from '../../Model/Field';
import localize from '../../../../../../../../@Api/Localization/localize';
import LocalizedTextEditor from '../../../../../../../../@Future/Component/Generic/Input/LocalizedTextEditor/LocalizedTextEditor';

export interface FieldItemProps
{
    entityType: EntityType;
    field: Field;
}

const NameEditor: React.FC<FieldItemProps> =
    props =>
    {
        const setName =
            useCallback(
                (value: any) =>
                    runInAction(
                        () =>
                            props.field.name = value),
                [
                    props.field
                ]);

        const name =
            useComputed(
                () =>
                    localize(props.field.name),
                [
                    props.field
                ]);

        if (props.field.isEditable)
        {
            return <LocalizedTextEditor
                value={props.field.name}
                onChange={setName}
                disabled={!props.field.isEditable}
                autoFocus={props.field.fieldPath === undefined}
            />;
        }
        else
        {
            return <div>
                {name}
            </div>;
        }
    };

export default observer(NameEditor);
