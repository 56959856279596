import { EmployeeAllocationSpecification } from '../Model/EmployeeAllocationSpecification';
import getTypes from '../../Type/Api/getTypes';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import uuid from '../../../../../@Util/Id/uuid';
import { EntityPath } from '../../Path/@Model/EntityPath';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../../DataObject/Model/Comparator';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../DataObject/Model/DataObject';

export function getDefaultEmployeeAllocationSpecification(): EmployeeAllocationSpecification
{
    const types = getTypes();
    const resourceType = types.Relationship.Person.Contact.Employee.Type;
    const resourceToEmployeeAllocationPath =
        EntityPath.fromEntityType(resourceType)
            .joinTo(
                types.Relationship.Person.Contact.Employee.RelationshipDefinition.Allocations,
                false
            );
    const employeeAllocationType = types.EmployeeAllocation.Type;
    const resourceParameter =
        new Parameter<EntityValueType>(
            uuid(),
            new EntityValueType(resourceType),
            true,
            resourceType.getName()
        );
    const resourceTeamParameter =
        new Parameter(
            'Team',
            new EntityValueType(types.Team.Type),
            false,
            types.Team.Type.getName()
        );
    const resourceSkillParameter =
        new Parameter(
            'Skill',
            new EntityValueType(types.Datastore.Skill.Type),
            false,
            types.Datastore.Skill.Type.getName()
        );
    const resourceFunctionParameter =
        new Parameter(
            'Function',
            new EntityValueType(types.Datastore.Function.Type),
            false,
            types.Datastore.Function.Type.getName()
        );
    const resourceFilterParameters =
        new ParameterDictionary([
            resourceTeamParameter,
            resourceSkillParameter,
            resourceFunctionParameter
        ]);
    const falseValue =
        new PrimitiveValue(
            DataObject.constructFromTypeIdAndValue(
                'Boolean',
                false
            )
        );
    const resourceFilter =
        new CompositePredicate(
            LogicalOperator.And,
            [
                new ComparisonPredicate(
                    new ValueFromEntityComputation(
                        resourceParameter,
                        EntityPath.fromEntityType(resourceParameter.type.type)
                            .field(types.Relationship.Person.Contact.Employee.Field.IsSupport)),
                    Comparator.Equals,
                    falseValue
                ),
                new ComparisonPredicate(
                    new ValueFromEntityComputation(
                        resourceParameter,
                        EntityPath.fromEntityType(resourceParameter.type.type)
                            .field(types.Relationship.Field.IsFormer)),
                    Comparator.Equals,
                    falseValue
                ),
                new ComparisonPredicate(
                    new ValueFromEntityComputation(
                        resourceParameter,
                        EntityPath.fromEntityType(resourceParameter.type.type)
                            .joinTo(
                                types.Team.RelationshipDefinition.Members,
                                true)
                            .field()),
                    Comparator.Equals,
                    resourceTeamParameter
                ),
                new ComparisonPredicate(
                    new ValueFromEntityComputation(
                        resourceParameter,
                        EntityPath.fromEntityType(resourceParameter.type.type)
                            .joinTo(
                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.Skills,
                                false)
                            .field()),
                    Comparator.Equals,
                    resourceSkillParameter
                ),
                new ComparisonPredicate(
                    new ValueFromEntityComputation(
                        resourceParameter,
                        EntityPath.fromEntityType(resourceParameter.type.type)
                            .joinTo(
                                types.Relationship.Person.Contact.RelationshipDefinition.Function,
                                false)
                            .field()),
                    Comparator.Equals,
                    resourceFunctionParameter
                ),
            ]
        );
    const resourceNormFieldPath =
        EntityPath.fromEntityType(resourceType)
            .field(types.Relationship.Person.Contact.Employee.Field.WorkableHoursPerWeek);
    const employeeAllocationParameter =
        new Parameter<EntityValueType>(
            uuid(),
            new EntityValueType(employeeAllocationType),
            true,
            employeeAllocationType.getName()
        );

    return {
        resourceParameter,
        resourceToEmployeeAllocationPath,
        resourceFilterParameters,
        resourceFilter,
        resourceNormFieldPath,
        employeeAllocationParameter,
    };
}