export enum Comparator
{
    IsDefined = 0 ,
    IsNotDefined = 1,
    Equals = 2,
    NotEquals = 3,
    LessThan = 4,
    LessThanOrEqual = 5,
    GreaterThan = 6,
    GreaterThanOrEqual = 7,
    Contains = 8,
    NotContains = 12,
    In = 9,
    StartsWith = 10,
    EndsWith = 11
}
