import * as React from 'react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { PredicateEditorStore } from './PredicateEditorStore';
import { observer } from 'mobx-react';

@observer
export class PredicateEditor extends BaseComponent<PredicateEditorStore>
{
    renderComponent(store: PredicateEditorStore)
    {
        return React.createElement(
            store.type.editorView(),
            {
                store: store.editorStore
            });
    }
}
