import React, { useCallback, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import { IObservableArray, runInAction } from 'mobx';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import uuid from '../../../../../../../../../../@Util/Id/uuid';
import EmptyValue from '../../../../../../../../../../@Api/Automation/Value/EmptyValue';
import AiPromptComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/AiPromptComputation';
import { AiPromptComputationPart } from '../../../../../../../../../../@Api/Automation/Function/Computation/AiPromptComputationPart';
import ValueTypeEditor from '../../../Value/ValueTypeEditor';
import Chip from '@material-ui/core/Chip';
import ComputationDialogEditor from '../../Dialog/ComputationDialogEditor';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../../../../../DataObject/Model/DataObject';
import ComputationConstructor from '../../ComputationConstructor';
import MenuButton from '../../../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import useSwitch from '../../../../../../../../../../@Util/Switch/useSwitch';

export interface AiPromptComputationEditorProps extends ComputationEditorProps<ValueType<any>, AiPromptComputation>
{

}

const AiPromptComputationEditor: React.FC<AiPromptComputationEditorProps> =
    props =>
    {
        const setResultType =
            useCallback(
                (resultType: ValueType<any>) =>
                    runInAction(
                        () =>
                            props.value.resultType = resultType
                    ),
                [
                    props.value
                ]
            );
        const [ tab, setTab ] =
            useState<string | 'Settings'>(
                props.value.parts.length > 0
                    ? props.value.parts[0].id
                    : 'Settings'
            );
        const [prompt, ...attachments] = props.value.parts;
        const setPrompt =
            useCallback(
                (computation: Computation<any, any>) =>
                    runInAction(
                        () =>
                            prompt.computation = computation
                    ),
                [
                    prompt
                ]
            );
        const part =
            useComputed(
                () =>
                    props.value.parts.find(pdf => pdf.id === tab),
                [
                    props.value,
                    tab
                ]);
        const setPart =
            useCallback(
                (computation: Computation<any, any>) =>
                    runInAction(
                        () =>
                            part.computation = computation
                    ),
                [
                    part
                ]);
        const createPart =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            const id = uuid();

                            props.value.parts.push(
                                new AiPromptComputationPart(
                                    id,
                                    new EmptyValue()
                                )
                            );

                            setTab(id);
                        }),
                [
                    props.value,
                    setTab
                ]
            );
        const deletePart =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            (props.value.parts as IObservableArray).remove(part);
                            setTab(props.value.parts.length > 0 ? props.value.parts[0].id : 'Settings');
                        }
                    ),
                [
                    props.value,
                    part,
                    setTab
                ]
            );
        const fileType =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        DataObject.getTypeById('File')
                    ),
                []
            );
        const [ isConstructorOpen, openConstructor, closeConstructor ] = useSwitch(false);

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="AiPromptComputation.Prompt"
                            value="Prompt"
                        />
                    }
                >
                    <ComputationEditor
                        context={props.context}
                        value={prompt.computation}
                        onChange={setPrompt}
                    />
                </Input>
            </ViewGroupItem>
            <ViewGroupItem>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="AiPromptComputation.Images"
                            value="Afbeeldingen"
                        />
                    }
                >
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                    >
                        {
                            attachments.map(
                                attachment =>
                                    <ViewGroupItem
                                        key={attachment.id}
                                    >
                                        <Chip
                                            label={
                                                <ComputationDialogEditor
                                                    context={props.context}
                                                    value={attachment.computation}
                                                    onChange={
                                                        computation =>
                                                            runInAction(
                                                                () =>
                                                                    attachment.computation = computation
                                                            )
                                                    }
                                                    type={fileType}
                                                />
                                            }
                                            onDelete={
                                                () =>
                                                    runInAction(
                                                        () =>
                                                            (props.value.parts as IObservableArray).remove(attachment)
                                                    )
                                            }
                                        />
                                    </ViewGroupItem>
                            )
                        }
                        <ViewGroupItem>
                            <MenuButton
                                icon="add"
                                inline
                                tooltip={
                                    <LocalizedText
                                        code="ComputationEditor.AddVariableButtonTooltip"
                                        value="Variabele waarde toevoegen..."
                                    />
                                }
                                open={isConstructorOpen}
                                onOpen={openConstructor}
                                onClose={closeConstructor}
                            >
                                <ComputationConstructor
                                    context={props.context}
                                    type={fileType}
                                    onChange={
                                        computation =>
                                            runInAction(
                                                () =>
                                                    props.value.parts.push(
                                                        new AiPromptComputationPart(
                                                            uuid(),
                                                            computation
                                                        )
                                                    )
                                            )
                                    }
                                />
                            </MenuButton>
                        </ViewGroupItem>
                    </ViewGroup>
                </Input>
            </ViewGroupItem>
            <ViewGroupItem>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="AiPromptComputation.ResultType"
                            value="Resultaat"
                        />
                    }
                >
                    <ValueTypeEditor
                        value={props.value.resultType}
                        onChange={setResultType}
                    />
                </Input>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(AiPromptComputationEditor);
