import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import useApiClient from '../../../@Service/ApiClient/Hooks/useApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import Centered from '../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../@Future/Component/Generic/Loader/Loader';
import { LogQueryResult } from './Model/LogQueryResult';
import ViewGroup from '../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useToggle from '../../../@Util/Toggle/useToggle';
import IconButton from '../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { primaryColor, textSecondaryColor } from '../../../@Resource/Theme/Theme';
import { LogEventPage } from './Page/LogEventPage';
import { makeStyles } from '@material-ui/styles';
import { InfiniteScroller } from '../../Generic/InfiniteScroller/InfiniteScroller';
import moment from 'moment';
import { observer } from 'mobx-react';

const useStyles =
    makeStyles({
        table: {
            // maxHeight: '90vh',
            // overflowY: 'auto',
        },
    });

export interface LogViewerProps
{
    name: string;
    isNotLive?: boolean;
}

export const LogViewer: React.FC<LogViewerProps> =
    observer(
        ({
            name,
            isNotLive
         }) =>
        {
            const classes = useStyles();
            const apiClient = useApiClient();
            const [ isLive, toggleLiveness ] = useToggle(!isNotLive);
            const [ pages, setPages ] = useState<LogQueryResult[]>([]);
            const loadPage =
                useCallback(
                    async (
                        fromDate: Date,
                        toDate: Date,
                        isHistory: boolean = false,
                        page: number = 0
                    ) =>
                    {
                        const result =
                            await apiClient.request(
                                new ApiRequest<LogQueryResult>(
                                    '/logEvents',
                                    Method.Get,
                                    {
                                        name,
                                        fromDate: fromDate.toISOString(),
                                        toDate: toDate.toISOString(),
                                        page,
                                    }
                                )
                            );

                        setPages(
                            pages =>
                            {
                                const eventIds =
                                    new Set(
                                        pages
                                            .map(
                                                page =>
                                                    page.events.map(
                                                        event =>
                                                            event.id
                                                    )
                                            )
                                            .reduce(
                                                (a, b) =>
                                                    a.concat(b),
                                                []
                                            )
                                    );
                                const nonDuplicateEvents =
                                    result.events.filter(
                                        event =>
                                            !eventIds.has(event.id)
                                    );
                                result.events = nonDuplicateEvents;

                                if (nonDuplicateEvents.length > 0)
                                {
                                    return [
                                        ...isHistory ? [] : [ result ],
                                        ...pages,
                                        ...isHistory ? [ result ] : []
                                    ];
                                }
                                else
                                {
                                    return pages;
                                }
                            }
                        );

                        return result;
                    },
                    [
                        apiClient,
                        name,
                        setPages,
                    ]
                );
            useEffect(
                () =>
                {
                    if (isLive)
                    {
                        let fromDate = new Date();
                        const interval =
                            setInterval(
                                () =>
                                {
                                    const nextFromDate = new Date();
                                    loadPage(fromDate, new Date(), false)
                                        .then(
                                            () =>
                                                fromDate = nextFromDate
                                        );
                                },
                                5000
                            );

                        return () =>
                            clearInterval(interval);
                    }
                },
                [
                    loadPage,
                    isLive,
                ]
            );
            useEffect(
                () =>
                {
                    loadPage(moment().subtract(6, 'months').toDate(), new Date(), true).then();
                },
                [
                    loadPage,
                ]
            );
            const [ initialDate ] = useState<Date>(() => new Date());
            const [ hasMore, setMore ] = useState(true);
            const [ isLoading, setLoading ] = useState(false);
            const historyPageIdx = useRef(0);
            const loadMore =
                useCallback(
                    async () =>
                    {
                        setLoading(true);
                        historyPageIdx.current = historyPageIdx.current + 1;
                        const result =
                            await loadPage(
                                moment(initialDate).subtract(6, 'months').toDate(),
                                initialDate,
                                true,
                                historyPageIdx.current
                            );
                        setLoading(false);
                        setMore(result.events.length > 0);
                    },
                    [
                        setLoading,
                        historyPageIdx,
                        initialDate,
                        loadPage,
                        setMore,
                    ]
                );

            return <div
                className={classes.table}
            >
                <InfiniteScroller
                    loadMore={loadMore}
                    hasMore={hasMore}
                    maxHeight="90vh"
                    loading={isLoading}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width="15%"
                                >
                                    Date
                                </TableCell>
                                <TableCell
                                    width="15%"
                                >
                                    Level
                                </TableCell>
                                <TableCell
                                    width="70%"
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                        alignment="center"
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            Message
                                        </ViewGroupItem>
                                        {
                                            !isNotLive &&
                                            <ViewGroupItem>
                                                <IconButton
                                                    icon={isLive ? 'pause' : 'play_arrow'}
                                                    onClick={toggleLiveness}
                                                    color={isLive ? primaryColor : textSecondaryColor}
                                                    tooltip="Automatically reload"
                                                />
                                            </ViewGroupItem>
                                        }
                                    </ViewGroup>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                isLive &&
                                <TableRow>
                                    <TableCell
                                        colSpan={3}
                                    >
                                        <Centered
                                            horizontal
                                        >
                                            <ViewGroup
                                                orientation="horizontal"
                                                alignment="center"
                                                spacing={15}
                                            >
                                                <ViewGroupItem>
                                                    Waiting for new events...
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <Loader
                                                        size={15}
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </Centered>
                                    </TableCell>
                                </TableRow>
                            }
                            {
                                pages.map(
                                    (page, idx) =>
                                        <LogEventPage
                                            key={idx}
                                            page={page}
                                        />
                                )
                            }
                        </TableBody>
                    </Table>
                </InfiniteScroller>
            </div>;
        }
    );