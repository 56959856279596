import { BaseStore } from '../../@Framework/Store/BaseStore';
import { ApiClient } from '../../@Service/ApiClient/ApiClient';
import { Module, ModuleManager } from '../../@Util/DependencyInjection/index';
import { AccountPublicController } from './Directory/AccountPublicController';
import { AccountUserController } from './Directory/AccountUserController';
import { EntityBespokeEmailController } from './Directory/EntityBespokeEmailController';
import { OfferEntityPublicController } from './Directory/OfferEntityPublicController';
import { EntityController } from './Directory/EntityController';
import { EntityEventController } from './Directory/EntityEventController';
import { EntityFieldController } from './Directory/EntityFieldController';
import { ExternalPictureController } from './Directory/ExternalPictureController';
import { EntityRelationshipDefinitionController } from './Directory/EntityRelationshipDefinitionController';
import { EntitySelectionController } from './Directory/EntitySelectionController';
import { EntityTypeController } from './Directory/EntityTypeController';
import { OrganizationController } from './Directory/OrganizationController';
import { SettingController } from './Directory/SettingController';
import { StatusController } from './Directory/StatusController';
import { TokenController } from './Directory/TokenController';
import { TranslationController } from './Directory/TranslationController';
import { UserPublicController } from './Directory/UserPublicController';
import { WelcomeController } from './Directory/WelcomeController';
import { DocumentEntityController } from './Directory/DocumentEntityController';
import { EntityActionController } from './Directory/EntityActionController';
import { GoogleCalendarController } from './Directory/GoogleCalendarController';
import { MicrosoftExchangeConnectorController } from './Directory/MicrosoftExchangeConnectorController';
import { OrganizationRegistrationController } from './Directory/OrganizationRegistrationController';
import { LocalizationController } from './Directory/LocalizationController';
import { PayInvoicePublicController } from './Directory/PayInvoicePublicController';

export class ApiControllerStore extends BaseStore
{
    apiClient: ApiClient;
    moduleManager: ModuleManager;
    accountPublicController: AccountPublicController;
    accountUserController: AccountUserController;
    documentEntityController: DocumentEntityController;
    entityBespokeEmailController: EntityBespokeEmailController;
    offerEntityPublicController: OfferEntityPublicController;
    payInvoicePublicController: PayInvoicePublicController;
    entityController: EntityController;
    entityActionController: EntityActionController;
    entityEventController: EntityEventController;
    entityFieldController: EntityFieldController;
    entityPictureController: ExternalPictureController;
    entityRelationshipDefinitionController: EntityRelationshipDefinitionController;
    entitySelectionController: EntitySelectionController;
    entityTypeController: EntityTypeController;
    localizationController: LocalizationController;
    organizationController: OrganizationController;
    organizationRegistrationController: OrganizationRegistrationController;
    settingController: SettingController;
    statusController: StatusController;
    tokenController: TokenController;
    translationController: TranslationController;
    userPublicController: UserPublicController;
    welcomeController: WelcomeController;
    googleCalendarController: GoogleCalendarController;
    microsoftExchangeConnectorController: MicrosoftExchangeConnectorController;

    constructor(apiClient: ApiClient, moduleManager: ModuleManager)
    {
            super();
            this.apiClient = apiClient;
            this.moduleManager = moduleManager;
    };
    initialize(): Promise<any>
    {
            this.accountPublicController = new AccountPublicController(this.apiClient);
            this.accountUserController = new AccountUserController(this.apiClient);
            this.documentEntityController = new DocumentEntityController(this.apiClient);
            this.entityBespokeEmailController = new EntityBespokeEmailController(this.apiClient);
            this.offerEntityPublicController = new OfferEntityPublicController(this.apiClient);
            this.payInvoicePublicController = new PayInvoicePublicController(this.apiClient);
            this.entityController = new EntityController(this.apiClient);
            this.entityActionController = new EntityActionController(this.apiClient);
            this.entityEventController = new EntityEventController(this.apiClient);
            this.entityFieldController = new EntityFieldController(this.apiClient);
            this.entityPictureController = new ExternalPictureController(this.apiClient);
            this.entityRelationshipDefinitionController = new EntityRelationshipDefinitionController(this.apiClient);
            this.entitySelectionController = new EntitySelectionController(this.apiClient);
            this.entityTypeController = new EntityTypeController(this.apiClient);
            this.localizationController = new LocalizationController(this.apiClient);
            this.organizationController = new OrganizationController(this.apiClient);
            this.organizationRegistrationController = new OrganizationRegistrationController(this.apiClient);
            this.settingController = new SettingController(this.apiClient);
            this.statusController = new StatusController(this.apiClient);
            this.tokenController = new TokenController(this.apiClient);
            this.translationController = new TranslationController(this.apiClient);
            this.userPublicController = new UserPublicController(this.apiClient);
            this.welcomeController = new WelcomeController(this.apiClient);
            this.googleCalendarController = new GoogleCalendarController(this.apiClient);
            this.microsoftExchangeConnectorController = new MicrosoftExchangeConnectorController(this.apiClient);

            this.moduleManager.define(new Module(this.accountPublicController, [], 'AccountPublicController'));
            this.moduleManager.define(new Module(this.accountUserController, [], 'AccountUserController'));
            this.moduleManager.define(new Module(this.documentEntityController, [], 'DocumentEntityController'));
            this.moduleManager.define(new Module(this.entityBespokeEmailController, [], 'EntityBespokeEmailController'));
            this.moduleManager.define(new Module(this.offerEntityPublicController, [], 'OfferEntityPublicController'));
            this.moduleManager.define(new Module(this.payInvoicePublicController, [], 'PayInvoicePublicController'));
            this.moduleManager.define(new Module(this.entityController, [], 'EntityController'));
            this.moduleManager.define(new Module(this.entityActionController, [], 'EntityActionController'));
            this.moduleManager.define(new Module(this.entityEventController, [], 'EntityEventController'));
            this.moduleManager.define(new Module(this.entityFieldController, [], 'EntityFieldController'));
            this.moduleManager.define(new Module(this.entityPictureController, [], 'EntityPictureController'));
            this.moduleManager.define(new Module(this.entityRelationshipDefinitionController, [], 'EntityRelationshipDefinitionController'));
            this.moduleManager.define(new Module(this.entitySelectionController, [], 'EntitySelectionController'));
            this.moduleManager.define(new Module(this.entityTypeController, [], 'EntityTypeController'));
            this.moduleManager.define(new Module(this.localizationController, [], 'LocalizationController'));
            this.moduleManager.define(new Module(this.organizationController, [], 'OrganizationController'));
            this.moduleManager.define(new Module(this.organizationRegistrationController, [], 'OrganizationRegistrationController'));
            this.moduleManager.define(new Module(this.settingController, [], 'SettingController'));
            this.moduleManager.define(new Module(this.statusController, [], 'StatusController'));
            this.moduleManager.define(new Module(this.tokenController, [], 'TokenController'));
            this.moduleManager.define(new Module(this.translationController, [], 'TranslationController'));
            this.moduleManager.define(new Module(this.userPublicController, [], 'UserPublicController'));
            this.moduleManager.define(new Module(this.welcomeController, [], 'WelcomeController'));
            this.moduleManager.define(new Module(this.googleCalendarController, [], 'GoogleCalendarController'));
            this.moduleManager.define(new Module(this.microsoftExchangeConnectorController, [], 'MicrosoftExchangeConnectorController'));

            return Promise.resolve();
    };
}
