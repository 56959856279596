import React, { useCallback } from 'react';
import ViewGroup from '../../ViewGroup/ViewGroup';
import useIsMobile from '../../../../../@Util/Responsiveness/useIsMobile';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';

export interface InputGroupProps
{
    inline?: boolean;
    disabled?: boolean;
}

const InputGroup: React.FC<InputGroupProps> =
    props =>
    {
        const isMobile = useIsMobile();
        const isInline = props.inline;
        const inputs: any[] =
            React.Children.toArray(props.children)
                .filter(
                    child =>
                        child)
                .map(
                    (child: any) =>
                        React.cloneElement(
                            child,
                            {
                                ...child.props,
                                labelPosition:
                                    isInline
                                        ?
                                            'none'
                                        :
                                            child.props.labelPosition === 'left' && isMobile
                                                ?
                                                    'top'
                                                :
                                                    (child.props.labelPosition || 'left'),
                                inline: isInline,
                                disabled: child.props.disabled || props.disabled
                            }));

        const glue =
            useCallback(
                () =>
                    `·`,
                [

                ]);

        if (isInline)
        {
            return <ViewGroup
                orientation="horizontal"
                spacing={10}
                alignment="center"
                wrap
                glue={glue}
            >
                {
                    inputs.map(
                        input =>
                            <ViewGroupItem
                                key={input.key}
                            >
                                {input}
                            </ViewGroupItem>)
                }
            </ViewGroup>;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={isMobile ? 10 : 0}
            >
                {
                    inputs.map(
                        input =>
                            <ViewGroupItem
                                key={input.key}
                            >
                                {input}
                            </ViewGroupItem>)
                }
            </ViewGroup>;
        }
    };

export default InputGroup;
