import * as React from 'react';
import { DataObjectBespokeEditor } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { observer } from 'mobx-react';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { RichTextEditorStore } from './RichTextEditorStore';
import LocalizedTextEditor from '../../../../../../@Future/Component/Generic/Input/LocalizedTextEditor/LocalizedTextEditor';

@observer
export default class RichTextEditor extends DataObjectBespokeEditor<RichTextEditorStore>
{
    renderComponent(store: DataObjectEditorStore<RichTextEditorStore>): any
    {
        return <LocalizedTextEditor
            value={store.dataObject.value}
            placeholder={store.placeholder}
            inputProps={{...store.handlerProperties as any}}
            onChange={store.changeValue}
            autoFocus={store.isFocused}
        />;
    }
}
