import { action, IObservableArray, observable, runInAction } from 'mobx';
import { Entity } from '../../../@Api/Model/Implementation/Entity';
import { EntityRelationship } from '../../../@Api/Model/Implementation/EntityRelationship';
import { EntityEvent } from '../../../@Api/Model/Implementation/EntityEvent';
import { injectWithQualifier } from '../../../@Util/DependencyInjection/index';
import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { EntityTypeStore } from '../../Domain/Entity/Type/EntityTypeStore';
import { DataObject } from '../../Domain/DataObject/Model/DataObject';
import { DataDescriptor } from '../../Domain/DataObject/Model/DataDescriptor';
import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { Comparator } from '../../Domain/DataObject/Model/Comparator';
import { EntityMatchResult } from './EntityMatchResult';
import { PredicateTypeStore } from '../../Domain/Predicate/PredicateTypeStore';
import { ComputationTypeStore } from '../../Domain/Computation/ComputationTypeStore';
import { DataObjectStore } from '../../Domain/DataObject/DataObjectStore';
import { FileType } from '../../Domain/DataObject/Type/File/FileType';
import { consoleLog } from '../../../@Future/Util/Logging/consoleLog';
import { EntityCreationMutation } from '../../../@Api/Model/Implementation/EntityCreationMutation';
import { EntityRelationshipMutation } from '../../../@Api/Model/Implementation/EntityRelationshipMutation';
import { EntityRelationshipCreationMutation } from '../../../@Api/Model/Implementation/EntityRelationshipCreationMutation';
import { EntityRelationshipDeletionMutation } from '../../../@Api/Model/Implementation/EntityRelationshipDeletionMutation';
import { EntityValueMutation } from '../../../@Api/Model/Implementation/EntityValueMutation';
import { EntityType } from '../../../@Api/Model/Implementation/EntityType';
import { EntityDeletionMutation } from '../../../@Api/Model/Implementation/EntityDeletionMutation';
import { CurrentUserStore } from '../../Domain/User/CurrentUserStore';
import { EntityRelationshipUpdateMutation } from '../../../@Api/Model/Implementation/EntityRelationshipUpdateMutation';
import getEntitiesByIds from '../../../@Api/Entity/Bespoke/getEntitiesByIds';
import getEntityByUuid from '../../../@Api/Entity/Bespoke/getEntityByUuid';
import { Mutex } from '../../../@Util/Mutex/Mutex';
import { EntityQueryCacheService } from './EntityQueryCacheService';
import { EntityCacheReferrer } from './EntityCacheReferrer';
import { EntityGarbageCollectionService } from './EntityGarbageCollectionService';
import { EntityCacheReferrerService } from './EntityCacheReferrerService';
import { EntityRelationshipsCacheReferrerService } from './EntityRelationshipsCacheReferrerService';
import uuid from '../../../@Util/Id/uuid';

const UnknownCacheReferrer: EntityCacheReferrer = 'Unknown';
const EventCacheReferrer: EntityCacheReferrer = 'Event';

export type EventListener = (event: EntityEvent, isForeign: boolean) => void;

enum UpdateType
{
    Create, Update, Delete
}

export class EntityCacheService extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ApiClient') apiClient: ApiClient;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('PredicateTypeStore') predicateTypeStore: PredicateTypeStore;
    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;
    @injectWithQualifier('EntityQueryCacheService') entityQueryCacheService: EntityQueryCacheService;

    // ------------------------- Properties -------------------------

    @observable connectionId: string;
    entityById = new Map<number, Entity>();
    entityByUuid = new Map<string, Entity>();
    relationshipByUuid = new Map<string, EntityRelationship>();
    relationshipById = new Map<number, EntityRelationship>();
    processedEventIds = new Set<number>();
    @observable.ref updateSource: EventSource;
    @observable.shallow eventListeners = observable.array<EventListener>(undefined, { deep: false });
    mutex = new Mutex();
    eventsByCommitId = new Map<string, EntityEvent[]>();
    timeoutByCommitId = new Map<string, any>();
    registeredCommitIds = new Set<string>();
    cacheReferrerService: EntityCacheReferrerService;
    relationshipsCacheReferrerService: EntityRelationshipsCacheReferrerService;
    garbageCollectionService: EntityGarbageCollectionService;

    // ------------------------ Constructor -------------------------

    constructor(connectionId: string)
    {
        super();

        this.connectionId = connectionId;
        this.cacheReferrerService = new EntityCacheReferrerService();
        this.relationshipsCacheReferrerService = new EntityRelationshipsCacheReferrerService();
        this.garbageCollectionService =
            new EntityGarbageCollectionService(
                this,
                this.cacheReferrerService,
                this.relationshipsCacheReferrerService);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    registerEventListener(listener: EventListener)
    {
        this.eventListeners.push(listener);
    }

    @action.bound
    unregisterEventListener(listener: EventListener)
    {
        this.eventListeners.remove(listener);
    }

    @action.bound
    registerCommit(commitId: string)
    {
        this.registeredCommitIds.add(commitId);
    }

    hasCommit(commitId: string)
    {
        return this.registeredCommitIds.has(commitId);
    }

    @action.bound
    onUpdate(entity: Entity, type: UpdateType)
    {
        // this.resultByQuery.forEach(
        //     (result, query) =>
        //     {
        //         if (query.selection.matches(entity))
        //         {
        //             result.
        //         }
        //     });
    }

    @action.bound
    clear()
    {
        this.entityById.clear();
        this.entityByUuid.clear();
        this.relationshipByUuid.clear();
        this.relationshipById.clear();
        this.processedEventIds.clear();
        this.cacheReferrerService.clear();
        this.relationshipsCacheReferrerService.clear();
        this.entityQueryCacheService.clear();
        this.registeredCommitIds.clear();

        return this.reinitializeStore();
    }

    @action.bound
    mergeEntityNetwork(entity: Entity,
                       doMergeEditableValues: boolean = true,
                       referrer = UnknownCacheReferrer): Entity
    {
        return this.mergeEntityNetworkForEntities(
            [
                entity
            ],
            doMergeEditableValues,
            referrer)
            .find(() => true);
    }

    @action.bound
    mergeEntityNetworkForEntities(entities: Entity[],
                                  doMergeEditableValues: boolean = true,
                                  referrer = UnknownCacheReferrer): Entity[]
    {
        const entityByUuid = new Map<string, Entity>();
        const relationshipByUuid = new Map<string, EntityRelationship>();

        // Discover entities and relationships
        for (let entity of entities)
        {
            if (this.getEntity(entity.uuid) !== entity)
            {
                entity.discover(entityByUuid, relationshipByUuid, undefined, discoveredEntity => entity.isNew() ? // In case of discovery of a new entity, only discover related entities that are also new (discovering others is bad for performance
                    // and would no make any sense)
                    !discoveredEntity.isNew() : false);
            }
        }

        // Merge attributes of discovered entities to original entities
        entityByUuid
            .forEach(
                entity =>
                    this.mergeEntity(
                        entity,
                        doMergeEditableValues,
                        referrer));

        // Merge attributes of discovered relationships to cached relationships
        relationshipByUuid
            .forEach(
                relationship =>
                    this.mergeRelationship(
                        relationship,
                        referrer));

        return entities.map(entity => this.getEntity(entity.uuid));
    }

    @action.bound
    mergeEntity(entity: Entity,
                doMergeEditableValues: boolean = true,
                referrer: EntityCacheReferrer): Entity
    {
        // Insert or merge entity in cache
        if (this.hasEntity(entity.uuid))
        {
            this.cacheReferrerService.addReferrerToCacheInformationForEntity(entity, referrer);
            // this.mergeEntityWithSource(this.getEntity(entity.uuid), entity, doMergeEditableValues);
        }
        else
        {
            this.insertEntity(entity, referrer);
        }

        return this.getEntity(entity.uuid);
    }

    @action.bound
    mergeRelationship(relationship: EntityRelationship,
                      referrer: EntityCacheReferrer): EntityRelationship
    {
        // Insert or merge relationship in cache
        const originalCachedRelationship = this.getRelationship(relationship.uuid);
        const isExistentInCache = originalCachedRelationship !== undefined;
        const isOriginalCachedRelationshipNew = isExistentInCache && originalCachedRelationship.isNew();

        if (isExistentInCache)
        {
            this.cacheReferrerService.addReferrerToCacheInformationForRelationship(relationship, referrer);
            // this.mergeRelationshipWithSource(originalCachedRelationship, relationship);

            return originalCachedRelationship;
        }
        else
        {
            this.insertRelationship(relationship, referrer);
        }

        const cachedRelationship = this.getRelationship(relationship.uuid);

        // Relate relationships to existent entities
        if (!cachedRelationship.isDeleted)
        {
            [true, false]
                .forEach(
                    isParent =>
                    {
                        const fromRelatedEntity = this.getEntity(cachedRelationship.getEntity(isParent).uuid);
                        const toRelatedEntity = this.getEntity(relationship.getEntity(isParent).uuid);
                        const isDebug = false;

                        // If relationship is already related to the same entity, then we do not need to update anything
                        // Note that this saves a lot of performance for initializing new relationships (because the fromRelatedEntityIdx need not
                        // be resolved, which is a consuming task for thousands of relationships)
                        if (fromRelatedEntity === toRelatedEntity
                            && relationship.getEntity(isParent) === fromRelatedEntity)
                        {
                            return;
                        }
                        // We add/remove the relationship if it was not present in the cache or if the entity on the relationship has changed
                        else if (!isExistentInCache
                            // If we do not also remove and add the relationship when it was originally new, then
                            // the relationship does not get added to the original model
                            // Because of addition: Entity#addRelationship
                            || isOriginalCachedRelationshipNew
                            || fromRelatedEntity !== toRelatedEntity)
                        {
                            // const prefindCount = toRelatedEntity.getRelationships(!isParent).filter(rsp => rsp.uuid === cachedRelationship.uuid).length;

                            // Remove relationship from original related entity
                            const fromRelatedEntityIdx =
                                fromRelatedEntity
                                    ?
                                        fromRelatedEntity
                                            .getRelationships(!isParent)
                                            .findIndex(checkRelationship => checkRelationship.uuid === relationship.uuid)
                                    :
                                        -1;

                            // If the fromRelatedEntity is the same as the toRelatedEntity, and it was already contained by
                            // the fromRelatedEntity, then do nothing
                            if (fromRelatedEntity === toRelatedEntity && fromRelatedEntityIdx >= 0)
                            {
                                // Do nothing
                            }
                            else
                            {
                                if (fromRelatedEntity)
                                {
                                    const idx = fromRelatedEntityIdx;
                                    // fromRelatedEntity.getRelationships(!isParent)
                                    //     .findIndex(
                                    //         checkRelationship =>
                                    //             checkRelationship.uuid === relationship.uuid);

                                    if (isDebug)
                                    {
                                        consoleLog('removing relationship from', fromRelatedEntity.name, fromRelatedEntity.getRelationships(!isParent).length, '--', fromRelatedEntity, cachedRelationship, relationship, idx, isParent, fromRelatedEntity
                                            .getRelationships(!isParent)
                                            .map(r => r.uuid), relationship.uuid);
                                    }

                                    if (idx >= 0)
                                    {
                                        fromRelatedEntity
                                            .getRelationships(!isParent)
                                            .splice(idx, 1);
                                    }
                                }

                                toRelatedEntity
                                    .getRelationships(!isParent)
                                    .push(cachedRelationship);

                                if (isDebug)
                                {
                                    consoleLog('adding relationship to', toRelatedEntity.name, fromRelatedEntity.getRelationships(!isParent).length, '--', toRelatedEntity, cachedRelationship, relationship);
                                }
                            }
                        }

                        cachedRelationship.setEntity(isParent, toRelatedEntity);
                    });
        }

        return this.getRelationship(relationship.uuid);
    }

    @action.bound
    discardEntity(entity: Entity)
    {
        const entityByUuid = new Map<string, Entity>();
        const relationshipByUuid = new Map<string, EntityRelationship>();

        // Discover new entities and relationships
        entity.discover(entityByUuid, relationshipByUuid, undefined, () => !entity.isNew());
        entityByUuid.forEach(entity => this.entityByUuid.delete(entity.uuid));
        relationshipByUuid.forEach(relationship => this.discardRelationship(relationship));
        this.entityById.delete(entity.id);
        this.entityByUuid.delete(entity.uuid);
        this.cacheReferrerService.removeCacheInformationForEntity(entity);
        this.relationshipsCacheReferrerService.disposeEntity(entity);
    }

    @action.bound
    discardRelationship(relationship: EntityRelationship)
    {
        this.relationshipById.delete(relationship.id);
        this.relationshipByUuid.delete(relationship.uuid);
        this.cacheReferrerService.removeCacheInformationForRelationship(relationship);
        this.relationshipsCacheReferrerService.disposeRelationship(relationship);
    }

    @action.bound
    mergeEntityWithSource(toEntity: Entity, fromEntity: Entity, doMergeEditableValues: boolean = true)
    {
        if (toEntity !== fromEntity)
        {
            this.entityById.set(fromEntity.id, toEntity);

            toEntity.id = fromEntity.id;
            toEntity.setEntityType(fromEntity.entityType, this.entityTypeStore);
            toEntity.organization = fromEntity.organization;
            toEntity.sortIndex = fromEntity.sortIndex;
            toEntity.name = fromEntity.name;
            toEntity.compactName = fromEntity.compactName;
            toEntity.sortName = fromEntity.sortName;
            toEntity.description = fromEntity.description;
            toEntity.events = fromEntity.events;

            if (fromEntity.user)
            {
                toEntity.user = fromEntity.user;
            }

            fromEntity.values
                .filter(
                    fromValue =>
                        !fromValue.isEmpty || toEntity.hasValueForField(fromValue.field))
                .forEach(
                    fromValue =>
                    {
                        if (fromValue.field)
                        {
                            const toValue = toEntity.getValueByField(fromValue.field, true);

                            if (fromValue === toValue)
                            {
                                console.log('to and from are the same!!!');
                            }

                            if (toValue)
                            {
                                toValue.id = fromValue.id;
                                toValue.isReference = fromValue.isReference;

                                if (doMergeEditableValues
                                    || fromValue.dataObject.specification.type instanceof FileType
                                    || fromValue.field === this.entityTypeStore.bespoke.types.Activity.Task.Field.StartDate
                                    || fromValue.field === this.entityTypeStore.bespoke.types.Activity.Task.Field.EndDate
                                    || fromValue.field === this.entityTypeStore.bespoke.types.Entity.Field.CloseDate
                                    || fromValue.field === this.entityTypeStore.bespoke.types.Activity.Field.NumberOfPlannedActions
                                    || fromValue.field === this.entityTypeStore.bespoke.types.Activity.SalesOpportunity.Field.Probability
                                    || fromValue.field === this.entityTypeStore.bespoke.types.Activity.Field.NumberOfProductLines
                                    || fromValue.field === this.entityTypeStore.bespoke.types.Activity.Field.Amount
                                    || fromValue.field === this.entityTypeStore.bespoke.types.Activity.Field.AmountInCurrency
                                    || fromValue.field === this.entityTypeStore.bespoke.types.Activity.Field.TotalSalesExcludingVat
                                    || fromValue.field === this.entityTypeStore.bespoke.types.Activity.Field.TotalSalesExcludingVatInCurrency)
                                {
                                    if (fromValue.dataObject && DataObject.compare(fromValue.dataObject, toValue.dataObject, Comparator.NotEquals))
                                    {
                                        toValue.dataObject.setValue(fromValue.dataObject.value);

                                        toValue.dataObject.setInitialized(fromValue.dataObject.isInitialized);
                                    }
                                }
                            }
                        }
                        else
                        {
                            // console.warn('no field found for', fromValue);
                        }
                    });
        }
    }

    @action.bound
    insertEntity(entity: Entity,
                 referrer: EntityCacheReferrer)
    {
        this.entityByUuid.set(entity.uuid, entity);

        if (!entity.isNew())
        {
            this.entityById.set(entity.id, entity);
        }

        this.cacheReferrerService.insertCacheInformationForEntity(entity, referrer);
    }

    @action.bound
    removeEntity(entity: Entity)
    {
        this.entityByUuid.delete(entity.uuid);

        if (!entity.isNew())
        {
            this.entityById.delete(entity.id);
        }

        this.cacheReferrerService.removeCacheInformationForEntity(entity);
    }

    @action.bound
    insertRelationship(relationship: EntityRelationship,
                       referrer: EntityCacheReferrer = UnknownCacheReferrer)
    {
        this.relationshipByUuid.set(relationship.uuid, relationship);

        if (!relationship.isNew())
        {
            this.relationshipById.set(relationship.id, relationship);
        }

        this.cacheReferrerService.insertCacheInformationForRelationship(relationship, referrer);
    }

    @action.bound
    mergeRelationshipWithSource(toRelationship: EntityRelationship, fromRelationship: EntityRelationship)
    {
        if (toRelationship !== fromRelationship)
        {
            toRelationship.id = fromRelationship.id;
            toRelationship.definition = fromRelationship.definition;
        }
    }

    @action.bound
    removeRelationship(relationship: EntityRelationship)
    {
        this.relationshipByUuid.delete(relationship.uuid);

        if (!relationship.isNew())
        {
            this.relationshipById.delete(relationship.id);
        }

        this.cacheReferrerService.removeCacheInformationForRelationship(relationship);

        // The relationship may still be reused if it is used in deletedParentRelationships/deletedChildRelationships
        // See: Entity.updateRelationship
        // It should be issued a new ID, otherwise upon deleting a relationship and recommitting it,
        // the API will throw an exception that the relationship with this specified ID cannot be found
        relationship.id = undefined;
    }

    async processForeignEvent(event: EntityEvent)
    {
        if (this.timeoutByCommitId.has(event.commitId))
        {
            clearTimeout(this.timeoutByCommitId.get(event.commitId));
        }

        await this.mutex.dispatch(
            async () =>
            {
                // console.log('starting processing', event.id);
                const isInitialized = await this.initializeForeignEvent(event);

                if (isInitialized)
                {
                    if (!this.eventsByCommitId.has(event.commitId))
                    {
                        this.eventsByCommitId.set(event.commitId, []);
                    }

                    this.timeoutByCommitId.set(
                        event.commitId,
                        setTimeout(
                            () =>
                            {
                                this.processCommit(event.commitId);
                            },
                            2500));

                    this.eventsByCommitId.get(event.commitId).push(event);
                }

                if (event.isLastInCommit
                    && this.eventsByCommitId.has(event.commitId))
                {
                    await this.processCommit(event.commitId);
                }
            });
    }

    async processCommit(commitId: string)
    {
        const eventsToProcess = this.eventsByCommitId.get(commitId);

        this.cleanupCommit(commitId);

        if (eventsToProcess)
        {
            const prematchResult = await this.prematchEvents(eventsToProcess);

            return this.postMatchEvents(eventsToProcess, prematchResult);
        }
    }

    async initializeForeignEvent(event: EntityEvent): Promise<boolean>
    {
        let entity = this.getEntity(event.entityUuid);

        if (event.entity)
        {
            const entityType = this.entityTypeStore.getTypeById(event.entity.entityType.id);

            if (!entityType)
            {
                consoleLog('could not find type for foreign event', event);

                return false;
            }

            event.entity.entityType = entityType;
        }

        if (event instanceof EntityValueMutation)
        {
            const field = this.entityTypeStore.getFieldByIdOrCode(event.entityField.id, event.entityField.code);

            if (!field)
            {
                consoleLog('could not find field for foreign event', event);

                return false;
            }

            event.entityField = field;
        }
        else if (event instanceof EntityRelationshipMutation)
        {
            const relationshipDefinition = this.entityTypeStore.getRelationshipDefinitionById(event.entityRelationshipDefinition.id);

            if (!relationshipDefinition)
            {
                consoleLog('could not find relationship definition for foreign event', event);

                return false;
            }

            event.entityRelationshipDefinition = relationshipDefinition;
        }

        if (entity)
        {
            // Process relationship mutations only if the related entity is to be fetched (otherwise, do nothing)
            if (event instanceof EntityRelationshipMutation
                && this.entityQueryCacheService.getAffectedQueries(event).length === 0)
            {
                // console.log('not processing event [unfetched]', event.entityRelationshipDefinition.code, event.isParentRelationship, event);

                return false;
            }
        }
        else
        {
            const affectedQueries = this.entityQueryCacheService.getAffectedQueries(event);

            if (affectedQueries.length === 0)
            {
                // if (event instanceof EntityCreationMutation)
                // {
                //     console.log('not processing creation event [no affected queries]', event.entity.entityType.code, event, affectedQueries, Array.from(this.resultByQuery.keys()));
                // }
                // else
                // {
                //     console.log('not processing event [no affected queries]', event, affectedQueries);
                // }

                return false;
            }
        }

        if (!entity)
        {
            if (event instanceof EntityCreationMutation)
            {
                entity =
                    event.entity.initialize(
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        true,
                        true,
                        true,
                        false);
            }
            else if (event instanceof EntityDeletionMutation)
            {
                return false;
            }
            else
            {
                entity = (await getEntityByUuid(event.entity.entityType, event.entityUuid)).value;
            }

            if (!entity)
            {
                consoleLog('could not find entity for event', event);

                return false;
            }
        }

        // console.log('processing event', event, entity.entityType.code);

        event.entity = entity;

        if (event instanceof EntityRelationshipMutation)
        {
            let fromRelatedEntity: Entity;

            if (event.fromRelatedEntityUuid)
            {
                fromRelatedEntity = this.getEntity(event.fromRelatedEntityUuid);

                if (!fromRelatedEntity)
                {
                    fromRelatedEntity =
                        (await getEntityByUuid(
                            event.entityRelationshipDefinition.getEntityType(event.isParentRelationship),
                            event.fromRelatedEntityUuid)).value;
                }

                if (!fromRelatedEntity)
                {
                    return false;
                }
            }

            let toRelatedEntity: Entity;

            if (event.toRelatedEntityUuid)
            {
                toRelatedEntity = this.getEntity(event.toRelatedEntityUuid);

                if (!toRelatedEntity)
                {
                    toRelatedEntity =
                        (await getEntityByUuid(
                            event.entityRelationshipDefinition.getEntityType(event.isParentRelationship),
                            event.toRelatedEntityUuid)).value;
                }

                if (!toRelatedEntity)
                {
                    return false;
                }
            }

            runInAction(
                () =>
                {
                    event.fromRelatedEntity = fromRelatedEntity;
                    event.toRelatedEntity = toRelatedEntity;

                    let relationship: EntityRelationship;

                    if (event.entityRelationshipUuid
                        && event.entityRelationship)
                    {
                        relationship = this.getRelationship(event.entityRelationshipUuid);

                        if (!relationship)
                        {
                            relationship =
                                new EntityRelationship(
                                    event.entityRelationship.id,
                                    event.isParentRelationship ? toRelatedEntity : entity,
                                    event.isParentRelationship ? entity : toRelatedEntity,
                                    event.entityRelationshipDefinition);

                            relationship.uuid = event.entityRelationshipUuid;

                            this.insertRelationship(relationship, EventCacheReferrer);
                        }
                    }

                    event.entityRelationship = relationship;
                });
        }

        event.initialize(this.entityTypeStore, false);

        return true;
    }

    cleanupCommit(commitId: string)
    {
        this.eventsByCommitId.delete(commitId);

        const timeout = this.timeoutByCommitId.get(commitId);

        if (timeout !== undefined)
        {
            clearTimeout(timeout);

            this.timeoutByCommitId.delete(commitId);
        }
    }

    disposeReferrer(referrer: EntityCacheReferrer)
    {
        this.garbageCollectionService.disposeReferrer(referrer);
    }

    // ------------------------ Public logic ------------------------

    hasEntity(uuid: string)
    {
        return this.entityByUuid.has(uuid);
    }

    getEntity(uuid: string)
    {
        return this.entityByUuid.get(uuid);
    }

    getEntityById(id: number)
    {
        return this.entityById.get(id);
    }

    hasRelationship(uuid: string)
    {
        return this.relationshipByUuid.has(uuid);
    }

    getRelationship(uuid: string)
    {
        return this.relationshipByUuid.get(uuid);
    }

    getRelationshipById(id: number)
    {
        return this.relationshipById.get(id);
    }

    prematchEvents(events: EntityEvent[]): Promise<EntityMatchResult[]>
    {
        const newEntities = new Set<Entity>();
        const affectedEntities = new Set<Entity>();

        for (const event of events)
        {
            const entity = this.getEntity(event.entity.uuid);

            const fromEntity =
                (event instanceof EntityRelationshipCreationMutation || event instanceof EntityRelationshipUpdateMutation) && event.fromRelatedEntity
                    ?
                        this.getEntity(event.fromRelatedEntityUuid)
                    :
                        undefined;

            const toEntity =
                (event instanceof EntityRelationshipCreationMutation || event instanceof EntityRelationshipUpdateMutation) && event.toRelatedEntity
                    ?
                        this.getEntity(event.toRelatedEntityUuid)
                    :
                        undefined;

            if (entity)
            {
                affectedEntities.add(entity);

                if (event instanceof EntityCreationMutation)
                {
                    newEntities.add(entity);
                }
            }

            if (fromEntity)
            {
                affectedEntities.add(fromEntity);
            }

            if (toEntity)
            {
                affectedEntities.add(toEntity);
            }
        }

        return Promise.all(
            Array.from(affectedEntities)
                .map(
                    entity =>
                        this.prematch(
                            entity,
                            newEntities.has(entity))));
    }

    prematch(entity: Entity,
             isNew: boolean): Promise<EntityMatchResult>
    {
        if (isNew)
        {
            return Promise.resolve(new EntityMatchResult(entity, true, new Map()));
        }
        else
        {
            return Promise.resolve(this.entityQueryCacheService.matchQueries(entity));
        }
    }

    postMatchEvents(events: EntityEvent[],
                    result: EntityMatchResult[],
                    newOrDeletedEntitiesByUuid: Map<string, Entity> = new Map(),
                    newOrDeletedRelationshipsByUuid: Map<string, EntityRelationship> = new Map())
    {
        const entityIds = new Set(result.map(r => r.entity.uuid));

        events.forEach(
            event =>
            {
                if (event instanceof EntityDeletionMutation)
                {
                    const cachedEntity = this.getEntity(event.entityUuid);

                    if (cachedEntity)
                    {
                        newOrDeletedEntitiesByUuid.set(
                            cachedEntity.uuid,
                            cachedEntity);
                    }
                }
                else if (event instanceof EntityRelationshipDeletionMutation)
                {
                    const cachedRelationship = this.getRelationship(event.entityRelationshipUuid);

                    if (cachedRelationship)
                    {
                        newOrDeletedRelationshipsByUuid.set(
                            cachedRelationship.uuid,
                            cachedRelationship);
                    }
                }
            });

        return this.applyEvents(
            events,
            newOrDeletedEntitiesByUuid,
            newOrDeletedRelationshipsByUuid)
            .then(
                () =>
                {
                    // It might be that some events returned new entities (created on the API, but not yet known locally)
                    // So add these as 'new' results so they can be postmatched
                    events
                        .filter(
                            event =>
                                event instanceof EntityCreationMutation)
                        .map(
                            event =>
                                this.getEntity(event.entity.uuid))
                        .filter(
                            entity =>
                                entity !== undefined
                                    && !entityIds.has(entity.uuid))
                        .forEach(
                            entity =>
                            {
                                result.push(
                                    new EntityMatchResult(
                                        entity,
                                        true,
                                        new Map()));
                            });

                    return Promise.all(
                        result.map(
                            result =>
                                this.entityQueryCacheService.postmatch(
                                    result.entity,
                                    result)));
                });
    }

    @action.bound
    async applyEvents(events: EntityEvent[],
                      newOrDeletedEntitiesByUuid: Map<string, Entity> = new Map(),
                      newOrDeletedRelationshipsByUuid: Map<string, EntityRelationship> = new Map()): Promise<any>
    {
        // When fetching entities that belong to the events, entities may be returned by the API that are actually being created
        // E.g. when creating a sales opportunity, the getEntitiesByIds call may return the sales opportunity task (created by an automation
        // in the API) alongside with the sales opportunity itself.
        // This means that the newOrDeletedEntitiesByUuid will be ignored, because the entities from the cache will be used.
        // Thus, the newly created entities are added to the cache first to avoid them being ignored.
        const newEntities =
            Array.from(newOrDeletedEntitiesByUuid.values())
                .filter(
                    entity =>
                        entity.isNew()
                );
        const cacheReferrer = `ApplyEvents.${uuid()}`;
        this.mergeEntityNetworkForEntities(
            newEntities,
            undefined,
            cacheReferrer
        );

        const entityIdsToFetch =
            Array.from(
                new Set(
                    events
                        .filter(
                            event =>
                                !(event instanceof EntityDeletionMutation)
                        )
                        .filter(
                            event =>
                                !(
                                    this.getEntity(event.entityUuid || (event.entity && event.entity.uuid))
                                    || newOrDeletedEntitiesByUuid.get(event.entityUuid)
                                )
                        )
                        .filter(
                            event =>
                                event.entity && event.entity.id > 0
                        )
                        .map(
                            event =>
                                event.entity.id
                        )
                )
            );

        const { dispose } =
            await getEntitiesByIds(
                this.entityTypeStore.bespoke.types.Entity.Type,
                entityIdsToFetch
            );

        for (const event of events)
        {
            this.applyEvent(
                event,
                newOrDeletedEntitiesByUuid,
                newOrDeletedRelationshipsByUuid
            );
        }

        dispose();
        this.disposeReferrer(cacheReferrer);

        return Promise.resolve();
    }

    @action.bound
    applyEvent(event: EntityEvent,
               newOrDeletedEntitiesByUuid: Map<string, Entity>,
               newOrDeletedRelationshipsByUuid: Map<string, EntityRelationship>)
    {
        if (this.processedEventIds.has(event.id))
        {
            return;
        }

        // Deletion events have an id of 0 because they have no ID (they are also deleted alongside the entities)
        if (event.id > 0)
        {
            this.processedEventIds.add(event.id);
        }

        let isValidEvent = true;

        if (event.user
            && this.currentUserStore
            && event.user.id === this.currentUserStore.currentUser.id)
        {
            event.user = this.currentUserStore.currentUser;
        }

        if (event instanceof EntityCreationMutation)
        {
            const entity =
                this.getEntity(event.entityUuid)
                    ||
                newOrDeletedEntitiesByUuid.get(event.entityUuid)
                    ||
                event.entity;

            entity.id = event.entity.id;

            entity.initialize(
                this.entityTypeStore,
                undefined,
                undefined,
                false,
                false,
                undefined,
                undefined,
                false);

            this.insertEntity(entity, EventCacheReferrer);

            event.entity = entity;

            // console.log('creating entity', entity.entityType.code, 'event:', event);
        }
        else
        {
            const cachedEntity =
                this.getEntity(event.entityUuid || (event.entity && event.entity.uuid))
                    ||
                newOrDeletedEntitiesByUuid.get(event.entityUuid);

            if (cachedEntity)
            {
                event.entity = cachedEntity;

                if (event instanceof EntityRelationshipMutation)
                {
                    const isParent = event.isParentRelationship;
                    const relationshipDefinition = this.entityTypeStore.getRelationshipDefinitionById(event.entityRelationshipDefinition.id);

                    event.entityRelationshipDefinition = relationshipDefinition;

                    const fromRelatedEntity =
                        event.fromRelatedEntity
                            && this.getEntity(event.fromRelatedEntityUuid);

                    event.fromRelatedEntity = fromRelatedEntity;

                    const toRelatedEntity =
                        event.toRelatedEntityUuid
                            ?
                                this.getOrMergeEntity(
                                    event.toRelatedEntityUuid,
                                    event.toRelatedEntity,
                                    EventCacheReferrer
                                )
                            :
                                undefined;

                    event.toRelatedEntity = toRelatedEntity;

                    if (event instanceof EntityRelationshipCreationMutation)
                    {
                        // console.log('creating relationship', cachedEntity.entityType.code, relationshipDefinition.code, event);

                        if (toRelatedEntity)
                        {
                            const relationship =
                                this.getRelationship(event.entityRelationshipUuid)
                                    ||
                                newOrDeletedRelationshipsByUuid.get(event.entityRelationshipUuid)
                                    ||
                                // If the relationship cannot be found from the cache, then attempt
                                // to find it from the cached entity
                                cachedEntity.getRelationshipsByDefinition(
                                    isParent,
                                    relationshipDefinition)
                                    .find(
                                        relationship =>
                                            relationship.uuid === event.entityRelationshipUuid)
                                    ||
                                // In case of none of the above, then simply use the relationship from
                                // the event
                                event.entityRelationship;

                            if (event.entityRelationship)
                            {
                                relationship.id = event.entityRelationship.id;
                            }

                            relationship.definition = relationshipDefinition;
                            relationship.isInitialized = true;
                            relationship.setEntity(!isParent, cachedEntity);
                            relationship.setEntity(isParent, toRelatedEntity);

                            if (!cachedEntity.getRelationships(isParent).includes(relationship))
                            {
                                cachedEntity.getRelationships(isParent)
                                    .push(relationship);
                            }

                            if (!toRelatedEntity.getRelationships(!isParent).includes(relationship))
                            {
                                toRelatedEntity.getRelationships(!isParent)
                                    .push(relationship);
                            }

                            event.entityRelationshipDefinition = relationship.definition;
                            event.entityRelationship = relationship;

                            this.insertRelationship(relationship, EventCacheReferrer);
                        }
                    }
                    else
                    {
                        const cachedRelationship = this.getRelationship(event.entityRelationshipUuid);

                        if (cachedRelationship)
                        {
                            if (event instanceof EntityRelationshipDeletionMutation)
                            {
                                // If this is a one-sided deletion, we do not want to delete the relationship
                                // E.g. product line vat group (from the vat group side a change is a deletion)
                                if (event.isRelationshipDeleted)
                                {
                                    this.removeRelationship(cachedRelationship);

                                    // Now the relationship has been removed, any future events that are handled will not be found
                                    // in the cache
                                    // Thus, manually remove the relationships from both sides
                                    [
                                        true,
                                        false
                                    ].forEach(
                                        isParent =>
                                            (cachedRelationship.getEntity(isParent).getRelationships(!isParent) as IObservableArray)
                                                .remove(cachedRelationship)
                                    )
                                }
                            }
                            else
                            {
                                if (fromRelatedEntity)
                                {
                                    (fromRelatedEntity.getRelationships(!isParent) as IObservableArray)
                                        .remove(cachedRelationship);
                                }

                                if (toRelatedEntity && !toRelatedEntity.getRelationships(!isParent).includes(cachedRelationship))
                                {
                                    toRelatedEntity
                                        .getRelationships(!isParent)
                                        .push(cachedRelationship);
                                }

                                if (!cachedEntity.getRelationships(isParent).includes(cachedRelationship))
                                {
                                    cachedEntity
                                        .getRelationships(isParent)
                                        .push(cachedRelationship);
                                }

                                [ true, false ]
                                    .forEach(
                                        isParent =>
                                        {
                                            const relatedEntity =
                                                event.isParentRelationship === isParent
                                                    ? event.toRelatedEntity
                                                    : event.entity;

                                            if (relatedEntity)
                                            {
                                                cachedRelationship.setEntity(
                                                    isParent,
                                                    relatedEntity
                                                );
                                            }
                                        }
                                    );
                            }
                        }
                    }
                }
                else if (event instanceof EntityValueMutation)
                {
                    const field =
                        this.entityTypeStore.getFieldByIdOrCode(
                            event.entityField.id,
                            event.entityField.code);

                    if (field)
                    {
                        event.entityField = field;

                        // Resolve old value
                        const oldValue =
                            event.valueBeforeMutation
                                ?
                                    field.dataObjectSpecification.type
                                        .getUninitializedValueFromData(
                                            DataDescriptor.construct(
                                                event.valueBeforeMutation,
                                                field.dataObjectSpecification),
                                            field.dataObjectSpecification)
                                :
                                    undefined;

                        const oldDataObject =
                            DataObject.constructFromValue(
                                field.dataObjectSpecification,
                                oldValue);

                        if (!oldDataObject.isEmpty)
                        {
                            event.setDataObject(
                                false,
                                oldDataObject);
                        }

                        // Resolve new value
                        const newValue =
                            event.valueAfterMutation
                                ?
                                    field.dataObjectSpecification.type
                                        .getUninitializedValueFromData(
                                            DataDescriptor.construct(
                                                event.valueAfterMutation,
                                                field.dataObjectSpecification),
                                            field.dataObjectSpecification)
                                :
                                    undefined;

                        cachedEntity.setValueByField(
                            field,
                            newValue,
                            false,
                            true);

                        // console.log('setting value for field', cachedEntity, field, newValue);

                        const dataObject = cachedEntity.getDataObjectValueByField(field);

                        if (!dataObject.isEmpty)
                        {
                            event.setDataObject(
                                true,
                                // Clone data object because the original data object is mutated when changing the value
                                dataObject.clone());
                        }

                        if (event.entityValue && cachedEntity.hasValueForField(field))
                        {
                            const cachedValue = cachedEntity.getValueByField(field);

                            cachedValue.id = event.entityValue.id;
                            cachedValue.isReference = event.entityValue.isReference;
                        }

                        if (field === this.entityTypeStore.typeField
                            && newValue instanceof EntityType)
                        {
                            cachedEntity.entityType = newValue;
                        }
                        else if (field === this.entityTypeStore.sortIndexField)
                        {
                            cachedEntity.sortIndex = newValue;
                        }
                    }
                }
                else if (event instanceof EntityDeletionMutation)
                {
                    cachedEntity.isDeleted = true;

                    [ true, false ]
                        .forEach(
                            isParent =>
                                cachedEntity.getRelationships(isParent)
                                    .slice()
                                    .forEach(
                                        relationship =>
                                        {
                                            const relatedEntity = relationship.getEntity(isParent);

                                            if (relatedEntity)
                                            {
                                                (relatedEntity.getRelationships(!isParent) as IObservableArray)
                                                    .remove(relationship);
                                            }

                                            this.removeRelationship(relationship);
                                        }));

                    this.removeEntity(cachedEntity);
                }
            }
            else
            {
                isValidEvent = false;

                consoleLog(
                    'cannot find cached entity for event',
                    event,
                    this.entityTypeStore.getTypeById(event.entity.entityType.id)?.code,
                    (event instanceof EntityRelationshipMutation && this.entityTypeStore.getRelationshipDefinitionById(event.entityRelationshipDefinition.id)?.code),
                    (event instanceof EntityRelationshipMutation && event.toRelatedEntity && this.entityTypeStore.getTypeById(event.toRelatedEntity.entityType.id)?.code));
            }
        }

        if (isValidEvent)
        {
            for (const listener of this.eventListeners)
            {
                listener(event, event.connectionId !== this.connectionId);
            }
        }
    }

    private getOrMergeEntity(
        entityId: string,
        uninitializedEntity: Entity | undefined,
        referrer: EntityCacheReferrer
    )
    {
        const cachedEntity = this.getEntity(entityId);

        if (cachedEntity)
        {
            return cachedEntity;
        }
        else if (uninitializedEntity)
        {
            uninitializedEntity.initialize(
                undefined,
                undefined,
                undefined,
                undefined,
                false,
                false,
                false,
                false
            );

            return this.mergeEntity(
                uninitializedEntity,
                true,
                referrer
            );
        }
        else
        {
            return undefined;
        }
    }

    // ----------------------- Private logic ------------------------
}
