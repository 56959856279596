import * as React from 'react';
import { DataObjectBespokeViewer } from '../../Viewer/DataObjectBespokeViewer';
import { DataObjectViewerStore } from '../../Viewer/DataObjectViewerStore';
import { observer } from 'mobx-react';
import { ColorRGBA } from './ColorType';

@observer
export class ColorView extends DataObjectBespokeViewer
{
    renderComponent(store: DataObjectViewerStore)
    {
        let color: ColorRGBA = store.dataObject.getValue();

        let red = color && color.r !== undefined ? color.r : 0;
        let green = color && color.g !== undefined ? color.g : 0;
        let blue = color && color.b !== undefined ? color.b : 0;
        let alpha = color && color.a !== undefined ? color.a : 1;

        return <div
            style={{
                width: '100%',
                padding: 5,
                border: '1 px solid #f3f3f3',
                borderRadius: 4
            }}
        >
            <div
                style={{
                    backgroundColor: `rgba(${red}, ${green}, ${blue}, ${alpha})`,
                    width: '100%',
                    height: 10
                }}
            />
        </div>;
    }
}
