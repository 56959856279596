import React from 'react';
import styles from './Bucket.module.scss';
import { classNames } from '../../../Util/Class/classNames';

export interface BucketClasses
{
    root?: string;
}

export interface BucketProps
{
    bucketIdx: number;
    numberOfBucketsInLine: number;
    numberOfBuckets: number;
    width?: number | string;
    selected?: boolean;
    color?: string;
    borderColor?: string;
    onClick?: () => void;
    classes?: BucketClasses;
}

const Bucket: React.FC<BucketProps> =
    props =>
    {
        const isInFirstColumn = props.bucketIdx % Math.min(props.numberOfBucketsInLine, props.numberOfBuckets) === 0;
        const isInLastColumn = props.bucketIdx % Math.min(props.numberOfBucketsInLine, props.numberOfBuckets) === Math.min(props.numberOfBucketsInLine, props.numberOfBuckets) - 1;
        const isInFirstRow = props.bucketIdx < Math.min(props.numberOfBucketsInLine, props.numberOfBuckets);
        const isInLastRow = props.bucketIdx >= Math.floor((props.numberOfBuckets - 1) / props.numberOfBucketsInLine) * props.numberOfBucketsInLine;
        const hasBottomItem = props.bucketIdx + Math.min(props.numberOfBucketsInLine, props.numberOfBuckets) < props.numberOfBuckets;
        const hasRightItem =
            props.bucketIdx + 1 < props.numberOfBuckets
                &&
            props.bucketIdx % Math.min(props.numberOfBucketsInLine, props.numberOfBuckets) !== Math.min(props.numberOfBucketsInLine, props.numberOfBuckets) - 1;

        return <div
            className={classNames(
                styles.root,
                isInFirstRow && styles.fr,
                isInFirstColumn && styles.fc,
                isInLastRow && styles.lr,
                isInLastColumn && styles.lc,
                hasRightItem && styles.ri,
                hasBottomItem && styles.bi,
                props.selected && styles.selected,
                props.classes && props.classes.root
            )}
            style={{
                width:
                    props.width
                        ?
                            props.width
                        :
                            `calc(${100 / props.numberOfBucketsInLine}% - 2px)`,
                borderColor: props.borderColor,
                backgroundColor:
                    props.selected
                        ? props.color
                        : undefined
            }}
            onClick={props.onClick}
        >
            <div
                className={styles.content}
            >
                {props.children}
            </div>
        </div>;
    };

export default Bucket;
