import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { TableLayoutDimensionSectionEditorProps } from './TableLayoutDimensionSectionEditor';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import LayoutActionEditor from '../../../../Action/LayoutActionEditor';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LayoutContext from '../../../../../../../@Api/Layout/LayoutContext';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import FormLayoutContexts from '../../../Form/FormLayoutContexts';
import LayoutAction from '../../../../../../../@Api/Layout/Action/LayoutAction';
import { runInAction } from 'mobx';

export interface TableLayoutDimensionSectionOnClickEditorProps extends TableLayoutDimensionSectionEditorProps
{

}

const TableLayoutDimensionSectionOnClickEditor: React.FC<TableLayoutDimensionSectionOnClickEditorProps> =
    ({
        section,
        parameterDictionary,
        parameterAssignment,
        commitContext,
     }) =>
    {
        const formLayoutContexts = useContext(FormLayoutContexts);
        const context =
            useMemo(
                () =>
                    new LayoutContext(
                        ParameterDictionary.union(
                            parameterDictionary,
                            new ParameterDictionary(section.getParameters())
                        ),
                        parameterAssignment ?? new ParameterAssignment(),
                        commitContext,
                        true,
                        formLayoutContexts
                    ),
                [
                    parameterDictionary,
                    section,
                    parameterAssignment,
                    commitContext,
                    formLayoutContexts
                ]);
        const setAction =
            useCallback(
                (action: LayoutAction) =>
                    runInAction(
                        () =>
                            section.onClick = action),
                [
                    section
                ]);

        return <Input
            label={
                <LocalizedText
                    code="Generic.ClickAction"
                    value="Klikactie"
                />
            }
        >
            <LayoutActionEditor
                context={context}
                action={section.onClick}
                onChange={setAction}
            />
        </Input>;
    };

export default observer(TableLayoutDimensionSectionOnClickEditor);
