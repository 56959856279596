import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { ChartStore } from '../ChartStore';
import { Theme, withStyles } from '@material-ui/core';
import { Cell, Legend, LegendPayload, Pie, PieChart as RePieChart, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';
import { ColorUtils } from '../../../../@Util/Color/ColorUtils';
import Card from '../../../../@Future/Component/Generic/Card/Card';

const styles =
    (theme: Theme) =>
        ({

        });

@observer
class PieChart extends BaseComponent<ChartStore>
{
    renderComponent(store: ChartStore): JSX.Element
    {
        const chartData =
            store.data
                .map(
                    point =>
                        ({
                            name: point.name,
                            value: point.value.value,
                            dataObject: point.value,
                            percentage: store.sum && store.sum.value > 0 ? Math.round((point.value.value / store.sum.value) * 100) : undefined
                        }));

        const renderTooltip =
            (tooltipProps: TooltipProps) =>
            {
                const { payload } = tooltipProps;

                if (payload && payload.length > 0)
                {
                    const point = payload[0];

                    return <Card
                        inset
                    >
                        {point.name}: {point.payload.dataObject.toString()} ({point.payload.percentage}%)
                    </Card>;
                }
                else
                {
                    return null;
                }
            };

        const renderLegend =
            (value?: LegendPayload['value'], entry?: LegendPayload, i?: number) =>
                <span>{value} ({(entry as any).payload.percentage}%)</span>;

        return <ResponsiveContainer
            width="100%"
        >
            <RePieChart
                data={chartData}
                margin={{
                    left: 25,
                    bottom: 30
                }}

            >
                <Pie
                    nameKey="name"
                    dataKey="value"
                    data={chartData}
                    fill={store.color}
                    onClick={
                        (data: any) =>
                        {
                            const idx =
                                chartData.findIndex(
                                    element =>
                                        element === data.payload.payload);

                            if (idx >= 0)
                            {
                                store.toggleIndexSelection(idx);
                            }
                        }}
                >
                    {
                        Array.from(store.data.keys())
                            .map((entry, idx) =>
                                <Cell
                                    key={idx}
                                    cursor="pointer"
                                    fill={idx === store.selectedIndex ? ColorUtils.color(idx)[100] : ColorUtils.color(idx)[500]}
                                />)
                    }
                </Pie>
                <Tooltip
                    content={renderTooltip}
                />
                <Legend
                    formatter={renderLegend}
                />
            </RePieChart>
        </ResponsiveContainer>;
    }
}

export default withStyles(styles)(PieChart as any);
