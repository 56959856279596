import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import List from '../../../../../Model/Specification/List';
import Column from '../../../../../Model/Specification/Column';
import { runInAction } from 'mobx';
import Predicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import PredicateEditor from '../../../../../../Viewer/Content/Automation/Editor/Predicate/PredicateEditor';
import FunctionContext from '../../../../../../../../../@Api/Automation/Function/FunctionContext';
import { LogicalOperator } from '../../../../../../../DataObject/Model/LogicalOperator';
import View from '../../../../../Model/View';
import ComparisonPredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../../../../Model/ViewParams';
import { TextType } from '../../../../../../../DataObject/Type/Text/TextType';
import { Comparator } from '../../../../../../../DataObject/Model/Comparator';
import useTypes from '../../../../../../Type/Api/useTypes';
import InstanceOfPredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/InstanceOfPredicate';
import CompositePredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LocalizedTextType } from '../../../../../../../DataObject/Type/LocalizedText/LocalizedTextType';

export interface ColumnFilterEditorProps
{
    view: View;
    list: List;
    column: Column;
}

const ColumnFilterEditor: React.FC<ColumnFilterEditorProps> =
    props =>
    {
        const { view, column } = props;
        const types = useTypes();

        const setFilter =
            useCallback(
                (filter?: Predicate) =>
                    runInAction(
                        () =>
                            column.filter = filter as CompositePredicate),
                [
                    column
                ]);

        const context =
            useMemo(
                () =>
                    new FunctionContext(view.parameters),
                [
                    view
                ]);

        const constructComparisonPredicate =
            useCallback(
                () =>
                    column.fieldPath.field === types.Entity.Field.Type
                        ?
                            new InstanceOfPredicate(
                                new ValueFromEntityComputation(
                                    context.parameterDictionary.getParameterById(ViewParams.Entity),
                                    column.fieldPath.path.field()),
                                undefined)
                        :
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    context.parameterDictionary.getParameterById(ViewParams.Entity),
                                    column.fieldPath),
                                column.fieldPath.field?.dataObjectSpecification.type instanceof TextType
                                    || column.fieldPath.field?.dataObjectSpecification.type instanceof LocalizedTextType
                                    ?
                                        Comparator.Contains
                                    :
                                        Comparator.Equals,
                                undefined),
                [
                    column,
                    types,
                    context
                ]);

        useEffect(
            () =>
            {
                if (!column.filter)
                {
                    setFilter(
                        new CompositePredicate(
                            LogicalOperator.Or,
                            [
                                constructComparisonPredicate()
                            ]));
                }
            },
            [
                column,
                setFilter,
                constructComparisonPredicate
            ]);

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <PredicateEditor
                    context={context}
                    value={column.filter}
                    onChange={setFilter}
                    comparisonConstructor={constructComparisonPredicate}
                    autoFocus
                    disallowTextComputation
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(ColumnFilterEditor);
