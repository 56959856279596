import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { MenuStore } from './MenuStore';
import { Avatar, Divider, Icon, ListItem, ListItemText, Theme as MuiTheme } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import List from '../List/V2/List';
import GroupedMenuList from './GroupedMenuList';

const styles =
    (theme: MuiTheme) =>
        ({

        });

@observer
class Menu extends BaseComponent<MenuStore>
{
    renderComponent(store: MenuStore)
    {
        if (store.isVisible)
        {
            if (store.expandedItemStore)
            {
                return <>
                    {
                        !store.isExpandedInChild &&
                            <>
                                <ListItem
                                    button
                                    onClick={store.collapse}
                                >
                                    <Avatar
                                        style={{
                                            backgroundColor:
                                                store.expandedItemStore.avatar.color
                                        }}
                                    >
                                        <Icon
                                            style={{
                                                color: store.expandedItemStore.avatar.color
                                            }}
                                        >
                                            arrow_backward
                                        </Icon>
                                    </Avatar>
                                    <ListItemText
                                        primary={store.expandedItemStore.name}
                                        secondary={store.expandedItemStore.description}
                                    />
                                </ListItem>
                                <Divider />
                            </>
                    }
                    {
                        React.createElement(
                            store.expansionView.component,
                            {
                                store: store.expansionView.store
                            })
                    }
                </>;
            }
            else
            {
                if (store.maxHeight)
                {
                    return <div
                        style={{
                            maxHeight: store.maxHeight,
                            overflowY: 'scroll'
                        }}
                    >
                        {
                            store.isGrouped
                                ?
                                    <GroupedMenuList store={store.listStore} />
                                :
                                    <List store={store.listStore} />
                        }
                    </div>;
                }
                else
                {
                    return store.isGrouped
                        ?
                            <GroupedMenuList store={store.listStore} />
                        :
                            <List store={store.listStore} />;
                }
            }
        }
        else
        {
            return null;
        }
    }
}

export default withStyles(styles)(Menu as any);
