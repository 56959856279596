import React from 'react';
import { observer } from 'mobx-react-lite';
import { default as ViewModel } from '../Model/View';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { GroupedList } from './Grouped/GroupedList';
import NonGroupedList from './NonGrouped/NonGroupedList';

export type BaseListSelectionType = 'none' | 'all';

export interface ListSelection
{
    base: BaseListSelectionType;
    mutations: string[];
    size: number;
}

export interface ListProps
{
    view: ViewModel;
    isInEditMode?: boolean;
    hideCard?: boolean;
    sortable?: boolean;
    selectable?: boolean;
    onSelectionChange?: (selection: ListSelection) => void;
    defaultSelectionType?: BaseListSelectionType;
    selection?: ListSelection;
    readonly?: boolean;
    disableSelection?: (entity: Entity) => boolean;
    onClick?: (entity: Entity) => void;
    showHiddenEntities?: boolean;
    searchQuery?: string;
    saveViewState?: boolean;
    isInactive?: (entity: Entity) => boolean;
    disableInfiniteScroll?: boolean;
}

const List: React.FC<ListProps> =
    props =>
    {
        if (props.view.specification.list.groupings.length > 0)
        {
            return <GroupedList
                {...props}
                root
                idx={0}
                grouping={props.view.specification.list.groupings[0]}
                remainingGroups={props.view.specification.list.groupings.slice(1)}
            />;
        }
        else
        {
            return <NonGroupedList
                {...props}
                root
            />;
        }
    };

export default observer(List);
