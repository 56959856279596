import React, { useCallback, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { dividerColor, lightGrey } from '../../../../../../../@Resource/Theme/Theme';
import styles from './Markup.module.scss';
import { TrackProps } from '../Track';
import TrackItem from '../Model/TrackItem';
import TrackCell from '../Model/TrackCell';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import TimesheetDialog from '../../../TimeSheetDialog/TimesheetDialog';
import openDialog from '../../../../../../../@Service/Navigation/Page/Hooks/openDialog';
import getOverlappingPeriod from '../../../Api/getOverlappingPeriod';
import Period from '../../../Model/Period';

export interface MarkupProps extends TrackProps
{
    trackItems: TrackItem[];
}

const Markup: React.FC<MarkupProps> =
    props =>
    {
        const inactiveCells =
            useComputed(
                () =>
                    props.track.cells
                        .filter(
                            cell =>
                                !cell.cell.isActive),
                [
                    props.track
                ]);

        const primaryHeaderCells =
            useComputed(
                () =>
                    props.track.cells
                        .filter(
                            cell =>
                                cell.cell.isPrimaryHeader),
                [
                    props.track
                ]);

        const secondaryHeaderCells =
            useComputed(
                () =>
                {
                    const headerCells =
                        props.track.cells
                            .filter(
                                cell =>
                                    cell.cell.isSecondaryHeader);
                    let lastVisibleHeaderOffset = undefined;

                    return headerCells.map(
                        (headerCell, idx) =>
                        {
                            const isVisible =
                                lastVisibleHeaderOffset === undefined
                                || headerCell.offset - lastVisibleHeaderOffset >= 30;

                            if (isVisible)
                            {
                                lastVisibleHeaderOffset = headerCell.offset;
                            }

                            return {
                                cell: headerCell,
                                isLabelVisible: isVisible,
                                periodToNextSecondaryHeader: {
                                    from: headerCell.cell.period.from,
                                    to: idx + 1 < headerCells.length ? headerCells[idx + 1].cell.period.from : props.track.period.to
                                }
                            };
                        });
                },
                [
                    props.track
                ]);

        const durationMap =
            useComputed(
                () =>
                {
                    const durationMap = new Map<TrackCell, number>();

                    props.trackItems
                        .forEach(
                            item =>
                            {
                                for (let i = item.range.from.cell.idx; i <= item.range.to.cell.idx; i++)
                                {
                                    const cell = props.track.getCellByIdx(i);

                                    // Find nearest secondary header cell
                                    let targetCell = cell;

                                    for (let j = i; j >= 0; j--)
                                    {
                                        const possibleCell = props.track.getCellByIdx(j);

                                        if (possibleCell.cell.isSecondaryHeader)
                                        {
                                            targetCell = possibleCell;
                                            break;
                                        }
                                    }

                                    const overlappingPeriod =
                                        getOverlappingPeriod(
                                            cell.cell.period,
                                            item.item.period);

                                    if (overlappingPeriod)
                                    {
                                        durationMap.set(
                                            targetCell,
                                            (durationMap.get(targetCell) || 0)
                                            +
                                            overlappingPeriod.to
                                                .diff(overlappingPeriod.from, 'milliseconds'));
                                    }
                                }
                            });

                    return durationMap;
                },
                [
                    secondaryHeaderCells,
                    props.trackItems
                ]);

        const [ openedCell, setOpenedCell ] = useState<TrackCell>();

        const openTimesheetDialog =
            useCallback(
                (cell: TrackCell, period: Period) =>
                {
                    setOpenedCell(cell);

                    openDialog(
                        close =>
                            <TimesheetDialog
                                onClose={
                                    () =>
                                    {
                                        setOpenedCell(undefined);
                                        close();
                                    }}
                                period={period}
                                onSave={
                                    () =>
                                    {
                                        setOpenedCell(undefined);
                                        close();
                                    }}
                                resourceSelections={props.resourcePlanner.resourceSelections}
                                resource={props.resource}
                                parameterDictionary={props.resourcePlanner.parameterDictionary}
                                parameterAssignment={props.parameterAssignment}
                            />)
                },
                [
                    props.resourcePlanner,
                    props.resource,
                    props.parameterAssignment
                ]);

        // const openDialog =
        //     useDialog(
        //         close =>
        //             ,
        //         [
        //
        //         ]
        //     )

        return <>
            {
                inactiveCells.map(
                    cell =>
                        <div
                            key={cell.cell.idx}
                            style={{
                                position: 'absolute',
                                left: cell.offset,
                                width: cell.width,
                                top: 0,
                                bottom: 0,
                                backgroundColor: lightGrey,
                                pointerEvents: 'none'
                            }}
                        />)
            }
            {
                primaryHeaderCells.map(
                    (headerCell, idx) =>
                        <React.Fragment
                            key={idx}
                        >
                            {
                                props.first
                                && !props.disableHeader
                                &&
                                <div
                                    className={styles.primaryHeader}
                                    style={{
                                        left: headerCell.offset
                                    }}
                                >
                                    {headerCell.cell.period.from.format(props.specification.headerCellFormat)}
                                </div>
                            }
                            <div
                                style={{
                                    position: 'absolute',
                                    left: headerCell.offset,
                                    top: 0,
                                    bottom: 0,
                                    width: 1,
                                    backgroundColor: dividerColor,
                                    pointerEvents: 'none'
                                }}
                            />
                        </React.Fragment>)
            }
            {
                secondaryHeaderCells.map(
                    (headerCell, idx) =>
                        <React.Fragment
                            key={idx}
                        >
                            {
                                props.first
                                && !props.disableHeader
                                && headerCell.isLabelVisible
                                &&
                                <div
                                    className={styles.secondaryHeader}
                                    style={{
                                        left: headerCell.cell.offset
                                    }}
                                >
                                    {headerCell.cell.cell.period.from.format(props.specification.secondaryHeaderCellFormat)}
                                </div>
                            }
                            <div
                                style={{
                                    position: 'absolute',
                                    left: headerCell.cell.offset,
                                    top: 0,
                                    bottom: 0,
                                    width: 1,
                                    backgroundColor: dividerColor,
                                    pointerEvents: 'none'
                                }}
                            />
                            {
                                props.timesheet &&
                                <>
                                    <div
                                        className={classNames(styles.timesheetSelector, openedCell === headerCell.cell && styles.selected)}
                                        style={{
                                            position: 'absolute',
                                            left: headerCell.cell.offset + 1,
                                            width: idx < secondaryHeaderCells.length - 1 ? secondaryHeaderCells[idx + 1].cell.offset - headerCell.cell.offset - 1 : undefined,
                                            right: idx < secondaryHeaderCells.length - 1 ? undefined : 0,
                                            top: 0,
                                            bottom: 0,
                                            cursor: props.disableTimesheetDialog ? 'default' : 'pointer'
                                        }}
                                        onClick={
                                            () =>
                                            {
                                                if (!props.disableTimesheetDialog)
                                                {
                                                    openTimesheetDialog(headerCell.cell, headerCell.periodToNextSecondaryHeader)
                                                }
                                            }
                                        }
                                    >
                                        <div
                                            className={styles.label}
                                        >
                                            {
                                                durationMap.get(headerCell.cell) > 0 &&
                                                props.timesheetTotalFormatter(
                                                    durationMap.get(headerCell.cell),
                                                    headerCell.periodToNextSecondaryHeader,
                                                    props.resources,
                                                    props.intervalType,
                                                    props.resource)
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </React.Fragment>)
            }
        </>;
    };

export default observer(Markup);
