import React, { useCallback, useContext, useMemo } from 'react';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityFieldPath } from '../Path/@Model/EntityFieldPath';
import { default as GenericInput, InputProps as GenericInputProps } from '../../../../@Future/Component/Generic/Input/Input/Input';
import EntityTypeContext from '../Type/EntityTypeContext';
import { EntityRelationshipDefinition } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntityField } from '../../../../@Api/Model/Implementation/EntityField';
import { EntityPath } from '../Path/@Model/EntityPath';
import { Alignment } from '../../DataObject/Model/DataObject';
import FieldView from '../Field/View/FieldView';
import RelationshipEditor from '../Relationship/RelationshipEditor/RelationshipEditor';
import useSwitch from '../../../../@Util/Switch/useSwitch';
import { classNames } from '../../../../@Future/Util/Class/classNames';
import styles from './Input.module.scss';
import useTypes from '../Type/Api/useTypes';
import { SelectboxProps } from '../Selectbox/Selectbox';
import OverrideInputContext from './OverrideInputContext';
import { DataObjectRepresentationProps } from '../../DataObject/Model/DataObjectRepresentation';
import { default as InputModel } from '../../Multiplayer/Model/Input/Input';
import CurrentUserContext from '../../User/CurrentUserContext';
import ValueEditor from '../Value/Editor/ValueEditor';
import { UnderlineMode } from './UnderlineMode';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import resolveInputFromFieldPath from '../../Multiplayer/Model/Input/Api/resolveInputFromFieldPath';

export interface EntityInputProps extends Partial<GenericInputProps>
{
    entity: Entity;
    field: EntityFieldPath | EntityField | EntityRelationshipDefinition | InputModel;
    commitContext?: CommitContext;
    parent?: boolean;
    placeholder?: boolean | string;
    autoFocus?: boolean;
    disabled?: boolean;
    underline?: UnderlineMode;
    alignment?: Alignment;
    doAutocommit?: boolean;
    touched?: boolean;
    onConstruct?: (entity: Entity, commitContext: CommitContext) => void;
    useParentCommitContextForConstruction?: boolean;
    relationshipSelectboxProps?: Partial<SelectboxProps>;
    representation?: DataObjectRepresentationProps;
    required?: boolean;
    compact?: boolean;
    contentIfReadOnly?: React.ReactNode;
    onChange?: (value: any | undefined) => void;
    onBlur?: () => void;
    showCommitButtonOnTouch?: boolean;
}

const Input: React.FC<EntityInputProps> =
    props =>
    {
        const entityTypeStore = useContext(EntityTypeContext);
        const currentUserStore = useContext(CurrentUserContext);

        const {
            alignment,
            autoFocus,
            commitContext,
            compact,
            contentIfReadOnly,
            disabled,
            doAutocommit,
            entity,
            field,
            inline,
            labelPosition,
            onBlur,
            onChange,
            onConstruct,
            parent,
            placeholder,
            relationshipSelectboxProps,
            representation,
            required,
            touched,
            underline,
            useParentCommitContextForConstruction,
            showCommitButtonOnTouch,
        } = props;

        const fieldPath =
            useMemo(
                () =>
                {
                    if (field instanceof EntityFieldPath)
                    {
                        return field;
                    }
                    else if (field instanceof EntityField)
                    {
                        return EntityPath.fromEntity(entity).field(field);
                    }
                    else if (field instanceof EntityRelationshipDefinition)
                    {
                        return EntityPath.fromEntity(entity)
                            .joinTo(field, parent)
                            .field();
                    }
                    else if (field instanceof InputModel)
                    {
                        return field.toFieldPath()
                            .rootedAt(entity.entityType);
                    }
                },
                [
                    entity,
                    field,
                    parent
                ]);

        const isDisabled =
            useComputed(
                () =>
                    disabled
                        || entity.entityType.bespoke.isDisabled(entity, fieldPath)
                        || !currentUserStore.rightProfile.canUpdate(entity, commitContext)
                        || !(fieldPath.field
                            ?
                                currentUserStore.rightProfile.role.isPermissionGrantedForField(
                                    entity.entityType,
                                    fieldPath.field,
                                    'canUpdate'
                                )
                            :
                                currentUserStore.rightProfile.role.isPermissionGrantedForRelationshipTypeFromSide(
                                    entity.entityType,
                                    fieldPath.relationshipDefinition,
                                    fieldPath.isParentRelationship,
                                    'canUpdate'
                                )
                        ),
                [
                    currentUserStore.rightProfile,
                    disabled,
                    entity,
                    fieldPath
                ]);

        const label =
            useComputed(
                () =>
                    entity.entityType.bespoke.getLabelInInterface(
                        entity,
                        fieldPath),
                [
                    entity,
                    fieldPath
                ]);

        const [ _isTouched, setIsTouched ] = useSwitch(false);

        const onBlurToUse =
            useCallback(
                () =>
                {
                    if (onBlur)
                    {
                        onBlur();
                    }
                    setIsTouched();
                },
                [
                    onBlur,
                    setIsTouched
                ]
            )

        const isTouched =
            useMemo(
                () =>
                    touched || _isTouched,
                [
                    touched,
                    _isTouched
                ]);

        const placeholderToUse =
            useMemo(
                () =>
                    placeholder
                        ?
                            typeof placeholder === 'string'
                                ?
                                    placeholder
                                :
                                    fieldPath.getName(entityTypeStore)
                        :
                            '...',
                [
                    placeholder,
                    fieldPath,
                    entityTypeStore
                ]);

        const valueRepresentation =
            useMemo(
                () => ({
                        fitContent: inline,
                        ...representation
                    }),
                [
                    inline,
                    representation
                ]);

        const input =
            useMemo(
                () =>
                    resolveInputFromFieldPath(
                        fieldPath
                    ),
                [
                    fieldPath,
                ]
            );

        const isRequired =
            useComputed(
                () =>
                    required || input.isRequired(),
                [
                    required,
                    input,
                ]);

        const isEmpty =
            useComputed(
                () =>
                {
                    if (fieldPath.isField)
                    {
                        return fieldPath.getDataObject(entity).isEmpty;
                    }
                    else
                    {
                        return fieldPath.path.traverseEntity(entity).length === 0;
                    }
                },
                [
                    fieldPath,
                    entity
                ]);

        const types = useTypes();
        const labelPositionToUse =
            useComputed(
                () =>
                {
                    if (labelPosition === 'left'
                        || labelPosition === 'right')
                    {
                        if (fieldPath.isField)
                        {
                            if (fieldPath.field.dataObjectSpecification.type.hasLargeEditor &&
                                fieldPath.field.dataObjectSpecification.type.hasLargeEditor(fieldPath.field.dataObjectSpecification))
                            {
                                return 'top';
                            }
                        }
                    }

                    return labelPosition;
                },
                [
                    labelPosition,
                    fieldPath,
                    types
                ]);

        const overrideInputFunction = useContext(OverrideInputContext);
        const inputOverride =
            useMemo<React.ReactNode | undefined>(
                () =>
                {
                    if (overrideInputFunction)
                    {
                        return overrideInputFunction(entity, fieldPath);
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    overrideInputFunction,
                    entity,
                    fieldPath
                ]);

        if (inputOverride)
        {
            return <>{inputOverride}</>;
        }
        else
        {
            return <GenericInput
                label={
                    <span
                        className={
                            classNames(
                                styles.label,
                                isRequired && styles.required,
                                isEmpty && styles.empty,
                                isTouched && styles.touched)}
                    >
                        {label} {isRequired ? '*' : ''}
                    </span>}
                {...props}
                labelPosition={labelPositionToUse}
            >
                {
                    props.children
                        ||
                    (fieldPath.isComputed || isDisabled
                        ?
                            contentIfReadOnly ??
                            <FieldView
                                entity={entity}
                                field={fieldPath}
                                commitContext={commitContext}
                            />
                        :
                            fieldPath.isRelationship
                                ?
                                    <RelationshipEditor
                                        entity={entity}
                                        commitContext={commitContext}
                                        disableUnderline={underline === 'never' || underline === 'hover'}
                                        relationshipDefinition={fieldPath.relationshipDefinition}
                                        isParent={fieldPath.isParentRelationship}
                                        doAutoCommit={doAutocommit}
                                        autoFocus={autoFocus}
                                        onBlur={onBlurToUse}
                                        onChange={onChange}
                                        onConstruct={onConstruct}
                                        useParentCommitContextForConstruction={useParentCommitContextForConstruction}
                                        selectboxProps={relationshipSelectboxProps}
                                        required={required}
                                        compact={compact}
                                    />
                                :
                                    <ValueEditor
                                        entity={entity}
                                        field={fieldPath.field}
                                        commitContext={commitContext}
                                        isInEditMode
                                        hasUnderline={underline === 'always'}
                                        isFocused={autoFocus}
                                        alignment={alignment}
                                        onBlur={onBlurToUse}
                                        onChange={onChange}
                                        doAutoCommit={doAutocommit}
                                        placeholder={placeholderToUse}
                                        representation={valueRepresentation}
                                        isRequiredOverride={required}
                                        showCommitButtonOnTouch={showCommitButtonOnTouch}
                                    />)
                }
            </GenericInput>;
        }
    };

Input.defaultProps =
{
    doAutocommit: true,
    underline: 'hover',
    parent: false,
};

export default observer(Input);
