import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import { Field } from '../../Model/Field';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import SaveButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import { Cardinality, EntityRelationshipDefinition, ReferentialAction, Type } from '../../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import uuid from '../../../../../../../../@Util/Id/uuid';
import { LanguageEntry } from '../../../../../../../../@Api/Model/Implementation/LanguageEntry';
import LocalizerContext from '../../../../../../../../@Service/Localization/LocalizerContext';
import { DataObjectType, EntityField } from '../../../../../../../../@Api/Model/Implementation/EntityField';
import { EntityFieldApi } from '../../../../../../../../@Api/Api/EntityFieldApi';
import { commit, createTransactionalModel, getModel, isDirty, rollback } from '../../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { EntityRelationshipDefinitionApi } from '../../../../../../../../@Api/Api/EntityRelationshipDefinitionApi';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import { runInAction } from 'mobx';
import { EntityPath } from '../../../../../../Entity/Path/@Model/EntityPath';
import { EntityFieldPath } from '../../../../../../Entity/Path/@Model/EntityFieldPath';
import { EntityTypeApi } from '../../../../../../../../@Api/Api/EntityTypeApi';
import CancelButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import useTypes from '../../../../../../Entity/Type/Api/useTypes';
import NumberSequenceConfigurationButton from '../../../../../../NumberSequence/NumberSequenceConfigurationButton';
import isMutable from '../../../../../../../../@Api/RightProfile/isMutable';
import Input from '../../../../../../Multiplayer/Model/Input/Input';
import FieldInput from '../../../../../../Multiplayer/Model/Input/FieldInput';
import RelationshipInput from '../../../../../../Multiplayer/Model/Input/RelationshipInput';
import setNameFieldOverrideInType from '../../../../../../../../@Api/Metadata/Field/setNameFieldOverrideInType';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';

export interface FieldItemProps
{
    entityType: EntityType;
    field: Field;
    onAddField: (fieldPath: EntityFieldPath) => void;
    onSave: (field: Field) => void;
    onCancel: (field: Field) => void;
}

const Options: React.FC<FieldItemProps> =
    props =>
    {
        const types = useTypes();
        const localizerStore = useContext(LocalizerContext);
        const { onAddField } = props;

        const cancel =
            useCallback(
                () =>
                {
                    rollback(props.field);
                    props.onCancel(props.field);
                },
                [
                    props.field,
                    props.onCancel
                ]);

        const save =
            useCallback(
                () =>
                {
                    const saveNameField =
                        (type: EntityType,
                         input: Input,
                         isName: boolean) =>
                        {
                            if (input instanceof FieldInput)
                            {
                                if (isMutable(type.entity)
                                    && isMutable(input.field.entity))
                                {
                                    if ((isName && type.nameField !== input.field)
                                        || (!isName && type.nameField === input.field))
                                    {
                                        return setNameFieldOverrideInType(type, undefined)
                                            .then(
                                                () =>
                                                {
                                                    const transactionalType = createTransactionalModel(type);
                                                    transactionalType.nameField = isName ? input.field : undefined;

                                                    return new EntityTypeApi()
                                                        .commit(transactionalType);
                                                });
                                    }
                                }
                                else
                                {
                                    return setNameFieldOverrideInType(type, isName ? input : undefined);
                                }
                            }
                            else if (input instanceof RelationshipInput)
                            {
                                if (isMutable(type.entity)
                                    && isMutable(input.relationshipDefinition.entity))
                                {
                                    if (isName && type.nameField)
                                    {
                                        const transactionalType = createTransactionalModel(type);
                                        transactionalType.nameField = undefined;

                                        return new EntityTypeApi()
                                            .commit(transactionalType)
                                            .then(
                                                () =>
                                                    setNameFieldOverrideInType(type, isName ? input : undefined));
                                    }
                                    else
                                    {
                                        return setNameFieldOverrideInType(type, isName ? input : undefined);
                                    }
                                }
                                else
                                {
                                    return setNameFieldOverrideInType(type, isName ? input : undefined);
                                }
                            }

                            return Promise.resolve();
                        };


                    if (props.field.name
                        && props.field.dataType)
                    {
                        const isNew = props.field.fieldPath === undefined;

                        const nameLanguageEntry = new LanguageEntry();
                        // nameLanguageEntry.updateTranslation(
                        //     localizerStore.language,
                        //     props.field.name);

                        if (props.field.dataType instanceof EntityType)
                        {
                            const inverseNameLanguageEntry = new LanguageEntry();
                            // inverseNameLanguageEntry.updateTranslation(
                            //     localizerStore.language,
                            //     props.entityType.getName(true));

                            let relationshipDefinition: EntityRelationshipDefinition;
                            let entity: Entity;
                            const isParent =
                                props.field.fieldPath
                                    ?
                                        props.field.fieldPath.isParentRelationship
                                    :
                                        false;

                            if (props.field.fieldPath)
                            {
                                relationshipDefinition =
                                    createTransactionalModel(
                                        props.field.fieldPath.relationshipDefinition);

                                // relationshipDefinition.nameLanguageEntry
                                //     .updateTranslation(
                                //         localizerStore.language,
                                //         props.field.name);

                                relationshipDefinition.setMandatory(
                                    props.field.fieldPath.isParentRelationship,
                                    props.field.isRequired);

                                relationshipDefinition.setVisibleDuringConstruction(
                                    props.field.fieldPath.isParentRelationship,
                                    props.field.isVisibleDuringConstruction);

                                relationshipDefinition.setEntityType(
                                    isParent,
                                    props.field.dataType);

                                entity = relationshipDefinition.entity;
                            }
                            else
                            {
                                relationshipDefinition =
                                    createTransactionalModel(
                                        new EntityRelationshipDefinition(
                                            uuid(),
                                            props.entityType,
                                            props.field.dataType,
                                            Type.Reference,
                                            props.field.isPlural ? Cardinality.ManyToMany : Cardinality.ManyToOne,
                                            props.field.isRequired,
                                            false,
                                            true,
                                            false,
                                            true,
                                            false,
                                            false,
                                            false,
                                            undefined,
                                            undefined,
                                            ReferentialAction.None,
                                            ReferentialAction.None,
                                            nameLanguageEntry,
                                            inverseNameLanguageEntry));

                                relationshipDefinition.setVisibleDuringConstruction(
                                    false,
                                    props.field.isVisibleDuringConstruction);

                                entity =
                                    createTransactionalModel(
                                        new Entity(types.EntityRelationshipDefinition.Type)
                                            .initialize());

                                relationshipDefinition.entity = entity;

                                entity.setValueByField(
                                    types.EntityRelationshipDefinition.Field.LocalizedParentName,
                                    props.entityType.getName(true));
                            }

                            entity.setValueByField(
                                isParent
                                    ?
                                        types.EntityRelationshipDefinition.Field.LocalizedParentName
                                    :
                                        types.EntityRelationshipDefinition.Field.LocalizedChildName,
                                props.field.name);

                            entity.updateRelationship(
                                true,
                                types.Pack.RelationshipDefinition.Entities,
                                props.field.pack);

                            return new EntityRelationshipDefinitionApi()
                                .commit(relationshipDefinition, true)
                                .then(
                                    () =>
                                        runInAction(
                                            () =>
                                                props.field.fieldPath =
                                                    EntityPath.fromEntityType(props.entityType)
                                                        .joinTo(
                                                            getModel(relationshipDefinition),
                                                            props.field.fieldPath
                                                                ?
                                                                    props.field.fieldPath.isParentRelationship
                                                                :
                                                                    false)
                                                        .field()))
                                .then(
                                    () =>
                                    {
                                        return saveNameField(
                                            props.entityType,
                                            new RelationshipInput(
                                                relationshipDefinition.getEntityType(true),
                                                relationshipDefinition,
                                                false),
                                            props.field.isName) as any;
                                    })
                                .then(
                                    () =>
                                    {
                                        if (isNew)
                                        {
                                            onAddField(props.field.fieldPath);
                                        }

                                        return commit(props.field);
                                    })
                                .then(
                                    () =>
                                        props.onSave(props.field));
                        }
                        else
                        {
                            let field: EntityField;

                            if (props.field.fieldPath)
                            {
                                field = createTransactionalModel(props.field.fieldPath.field);

                                field.entity.setValueByField(
                                    types.EntityField.Field.LocalizedName,
                                    props.field.name);

                                // field.nameLanguageEntry
                                //     .updateTranslation(
                                //         localizerStore.language,
                                //         props.field.name);

                                field.isRequired = props.field.isRequired;
                                field.isDefining = props.field.isVisibleDuringConstruction;
                                field.type = DataObjectType[props.field.dataType.id()];
                            }
                            else
                            {
                                field =
                                    createTransactionalModel(
                                        new EntityField(
                                            undefined,
                                            props.entityType,
                                            uuid(),
                                            undefined,
                                            undefined,
                                            nameLanguageEntry,
                                            undefined,
                                            new LanguageEntry(),
                                            DataObjectType[props.field.dataType.id()],
                                            {},
                                            undefined,
                                            props.field.isVisibleDuringConstruction));

                                field.isRequired = props.field.isRequired;

                                const entity =
                                    createTransactionalModel(
                                        new Entity(types.EntityField.Type)
                                            .initialize());

                                field.entity = entity;

                                entity.setValueByField(
                                    types.EntityField.Field.LocalizedName,
                                    props.field.name);
                            }

                            field.entity.updateRelationship(
                                true,
                                types.Pack.RelationshipDefinition.Entities,
                                props.field.pack);

                            return new EntityFieldApi()
                                .commit(field, true)
                                .then(
                                    () =>
                                        runInAction(
                                            () =>
                                                props.field.fieldPath =
                                                    EntityPath.fromEntityType(props.entityType)
                                                        .field(getModel(field))))
                                .then(
                                    () =>
                                    {
                                        const field = props.field.fieldPath.field;
                                        const type = field.entityType;

                                        // Handle saving of name
                                        return saveNameField(
                                            type,
                                            new FieldInput(
                                                field.entityType,
                                                field),
                                            props.field.isName) as any;
                                    })
                                .then(
                                    () =>
                                    {
                                        if (isNew)
                                        {
                                            onAddField(props.field.fieldPath);
                                        }

                                        return commit(props.field);
                                    })
                                .then(
                                    () =>
                                        props.onSave(props.field));
                        }
                    }
                },
                [
                    props.entityType,
                    props.field,
                    localizerStore,
                    onAddField,
                    props.onSave
                ]);

        const deleteField =
            useCallback(
                () =>
                {
                    if (window.confirm(
                        localizeText(
                            'LosingDataWhenRemovingFieldWarning',
                            'Let op: als u dit veld verwijderd, wordt alle data die in dit veld ingevuld is over de gehele historie óók verwijderd.'
                        ))
                    )
                    {
                        if (props.field.fieldPath.isField)
                        {
                            return new EntityFieldApi()
                                .delete(createTransactionalModel(props.field.fieldPath.field));
                        }
                        else if (props.field.fieldPath.isRelationship)
                        {
                            return new EntityRelationshipDefinitionApi()
                                .delete(createTransactionalModel(props.field.fieldPath.relationshipDefinition));
                        }
                    }
                },
                [
                    props.field
                ]);

        const numberGenerator =
            useMemo(
                () =>
                    props.field?.fieldPath?.field === types.Activity.Field.Number
                        ||
                            props.field?.fieldPath?.field === types.Relation.Field.Number
                        ||
                            props.field?.fieldPath?.field === types.Relation.Field.DebtorNumber
                        ||
                            props.field?.fieldPath?.field === types.Relation.Field.CreditorNumber
                    ?
                        <ViewGroupItem>
                            <NumberSequenceConfigurationButton
                                entityType={props.entityType}
                                entityField={props.field.fieldPath.field}
                            />
                        </ViewGroupItem>
                    :
                        undefined,
                [
                    types,
                    props.field,
                    props.entityType
                ]);


        if (props.field.isEditable)
        {
            return <ViewGroup
                orientation="horizontal"
                spacing={10}
                alignment="center"
            >
                <ViewGroupItem
                    ratio={1}
                />
                {/*<ViewGroupItem>*/}
                {/*    <IconButton*/}
                {/*        icon="close"*/}
                {/*        onClick={props.onClose}*/}
                {/*        tooltip="Annuleren"*/}
                {/*    />*/}
                {/*</ViewGroupItem>*/}
                {
                    (isDirty(props.field) || !props.field.fieldPath) &&
                        <ViewGroupItem>
                            <CancelButton
                                onClick={cancel}
                            />
                        </ViewGroupItem>
                }
                {
                    (!props.field.fieldPath || isDirty(props.field)) &&
                        <ViewGroupItem>
                            <SaveButton
                                onClick={save}
                            />
                            {/*<IconButton*/}
                            {/*    icon="check"*/}
                            {/*    color={primaryColor}*/}
                            {/*    onClick={save}*/}
                            {/*    tooltip="Opslaan"*/}
                            {/*/>*/}
                        </ViewGroupItem>
                }
                {
                    props.field.fieldPath &&
                        <ViewGroupItem>
                            <MenuButton>
                                <Menu>
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Generic.Delete"
                                                value="Verwijderen"
                                            />
                                        }
                                        onClick={deleteField}
                                    >
                                        <LocalizedText
                                            code="Generic.Delete"
                                            value="Verwijderen"
                                        />
                                    </Item>
                                </Menu>
                            </MenuButton>
                        </ViewGroupItem>
                }
                {
                    numberGenerator
                }
            </ViewGroup>;
        }
        else if (numberGenerator)
        {
            return <ViewGroup
                orientation="horizontal"
                spacing={10}
            >
                {
                    numberGenerator
                }
            </ViewGroup>;
        }
        else
        {
            return null;
        }
    };

export default observer(Options);
