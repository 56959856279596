import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import CompositeLayout from '../../../../../../@Api/Layout/Type/CompositeLayout';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import LayoutConstructor from '../../../Editor/Constructor/LayoutConstructor';
import Layout from '../../../../../../@Api/Layout/Layout';
import { IObservableArray, runInAction } from 'mobx';
import CompositeLayoutItem from '../../../../../../@Api/Layout/Type/CompositeLayoutItem';
import uuid from '../../../../../../@Util/Id/uuid';
import { Droppable, DropResult } from 'react-beautiful-dnd';
import useOnDragEnd from '../../../../DragAndDrop/Api/useOnDragEnd';
import CompositeLayoutEditorItemList from './CompositeLayoutEditorItemList';
import CompositeLayoutDepth from '../Context/CompositeLayoutDepth';

export interface CompositeLayoutEditorProps extends LayoutEditorProps<CompositeLayout>
{

}

const CompositeLayoutEditor: React.FC<CompositeLayoutEditorProps> =
    props =>
    {
        const droppableId = useMemo(() => uuid(), []);

        const addLayout =
            useCallback(
                (layout: Layout, afterItem?: CompositeLayoutItem) =>
                    runInAction(
                        () =>
                            props.layout.items.splice(
                                afterItem === undefined
                                    ?
                                        props.layout.items.length
                                    :
                                        props.layout.items.indexOf(afterItem) + 1,
                                0,
                                new CompositeLayoutItem(
                                    uuid(),
                                    layout,
                                    props.layout.orientation === 'Horizontal' ? 1 : undefined))),
                [
                    props.layout
                ]);

        const onDrop =
            useCallback(
                (dropResult: DropResult) =>
                {
                    runInAction(
                        () =>
                        {
                            if (dropResult.destination)
                            {
                                const item = props.layout.items.find(item => item.id === dropResult.draggableId);

                                if (item)
                                {
                                    (props.layout.items as IObservableArray).remove(item);
                                    props.layout.items.splice(
                                        dropResult.destination.index,
                                        0,
                                        item);
                                }
                            }
                        });
                },
                [
                    props.layout
                ]);

        useOnDragEnd(onDrop);

        const depth = useContext(CompositeLayoutDepth);

        if (props.layout.items.length === 0)
        {
            return <LayoutConstructor
                parameterDictionary={props.parameterDictionary}
                onConstruct={addLayout}
                mode="Button"
            />;
        }
        else
        {
            return <CompositeLayoutDepth.Provider
                value={depth + 1}
            >
                <Droppable
                    droppableId={droppableId}
                    type={droppableId}
                >
                    {(provided, snapshot) =>
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            <CompositeLayoutEditorItemList
                                {...props}
                                provided={provided}
                                onAdd={addLayout}
                                draggableType={droppableId}
                            />
                        </div>}
                </Droppable>
            </CompositeLayoutDepth.Provider>;
        }
    };

export default observer(CompositeLayoutEditor);
