import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import { ApiClient } from '../../../../../@Service/ApiClient/ApiClient';
import ResourceImageLayout from '../../../../../@Api/Layout/Type/ResourceImageLayout';
import { useFetchedApiFile } from '../../../../../@Service/ApiClient/Hooks/useFetchedApiFile';

export interface ResourceImageLayoutViewerProps extends LayoutViewerProps<ResourceImageLayout>
{

}

const ResourceImageLayoutViewer: React.FC<ResourceImageLayoutViewerProps> =
    props =>
    {
        const url =
            useComputed(
                () =>
                    props.layout.resourceId
                        ?
                            loadModuleDirectly(ApiClient).url(`/public/resource/${props.layout.resourceId}?token=${props.layout.resourceToken}`)
                        :
                            undefined,
                [
                    props.layout
                ]);
        const [ initializedUrl ] = useFetchedApiFile(url);
        const style =
            useComputed(
                () =>
                    ({
                        width: props.layout.width?.toCss(),
                        height: props.layout.height?.toCss()
                    }),
                [
                    props.layout
                ]);

        if (initializedUrl)
        {
            return <img
                src={initializedUrl}
                style={style}
                alt=""
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(ResourceImageLayoutViewer);
