import { findAssistantValueFromEntityComputation } from './findAssistantValueFromEntityComputation';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import { AssistantComputation } from '../../Model/Automation/Computation/AssistantComputation';
import { findAssistantValue } from '../Value/findAssistantValue';
import { findAssistantVariableComputation } from './findAssistantVariableComputation';
import { findAssistantListQueryComputation } from './findAssistantListQueryComputation';
import { findAssistantMathComputation } from './findAssistantMathComputation';
import { findAssistantTemplatedTextComputation } from './findAssistantTemplatedTextComputation';
import { findAssistantAggregateQueryComputation } from './findAssistantAggregateQueryComputation';
import { findAssistantGenAiPromptComputation } from './findAssistantGenAiPromptComputation';
import { findAssistantGetFileNameComputation } from './findAssistantGetFileNameComputation';
import { findAssistantGenAiAudioTranscriptionComputation } from './findAssistantGenAiAudioTranscriptionComputation';

export function findAssistantComputation(
    computation: AssistantComputation,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Computation<any, any>
{
    switch (computation.type)
    {
        case 'Math':
            return findAssistantMathComputation(
                computation,
                parameters,
                rootParameter
            );

        case 'ReferenceToVariable':
            return findAssistantVariableComputation(
                computation,
                parameters,
                rootParameter
            );

        case 'ValueFromEntity':
            return findAssistantValueFromEntityComputation(
                computation,
                parameters,
                rootParameter
            );

        case 'ListQuery':
            return findAssistantListQueryComputation(
                computation,
                parameters,
                rootParameter
            );

        case 'AggregateQuery':
            return findAssistantAggregateQueryComputation(
                computation,
                parameters,
                rootParameter
            );

        case 'TemplatedText':
            return findAssistantTemplatedTextComputation(
                computation,
                parameters,
                rootParameter
            );

        case 'GenAiPrompt':
            return findAssistantGenAiPromptComputation(
                computation,
                parameters,
                rootParameter
            );

        case 'GenAiAudioTranscription':
            return findAssistantGenAiAudioTranscriptionComputation(
                computation,
                parameters,
                rootParameter
            );

        case 'GetFileName':
            return findAssistantGetFileNameComputation(
                computation,
                parameters,
                rootParameter
            );

        default:
            return findAssistantValue(
                computation,
                parameters
            );
    }
}
