import React, { useCallback, useState } from 'react';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { EntityRelationshipDefinition } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import { EntityField } from '../../../../@Api/Model/Implementation/EntityField';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import EntityTreeView from '../TreeView/EntityTreeView';
import EntityTreeBrowserView from './View/EntityTreeBrowserView';
import Card from '../../../../@Future/Component/Generic/Card/Card';
import getTypes from '../Type/Api/getTypes';

export interface EntityTreeBrowserProps
{
    categoryType: EntityType;
    childCategoriesRelationshipDefinition: EntityRelationshipDefinition;
    recordsRelationshipDefinition: EntityRelationshipDefinition;
    categoryNameField: EntityField;
    recordType: EntityType;
    recordNameField: EntityField;
    isInEditMode?: boolean;
    onOpen?: (entity: Entity) => void;
    isInactive?: (entity: Entity) => boolean;
}

const EntityTreeBrowser: React.FC<EntityTreeBrowserProps> =
    props =>
    {
        const [ selectedCategory, setSelectedCategory ] = useState<Entity | undefined>(undefined);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
        >
            <ViewGroupItem>
                <Card
                    inset
                >
                    <EntityTreeView
                        {...props}
                        selectedCategory={selectedCategory}
                        onSelectCategory={setSelectedCategory}
                    />
                </Card>
            </ViewGroupItem>
            <ViewGroupItem
                ratio={1}
            >
                <Card>
                    <EntityTreeBrowserView
                        {...props}
                        category={selectedCategory}
                    />
                </Card>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(EntityTreeBrowser);
