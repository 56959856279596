import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import useResults from '../../../../../../Selection/Hooks/useResults';
import Menu from '../../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import equalsEntity from '../../../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import CurrentUserContext from '../../../../../../../User/CurrentUserContext';
import RouterContext from '../../../../../../../../../@Service/Router/RouterContext';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardHeader from '../../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import { createGutenbergDocumentDefinition } from '../../../../../../../../../@Future/Component/Generic/Input/DocumentEditor/Gutenberg/GutenbergBlockEditor';
import Centered from '../../../../../../../../../@Future/Component/Generic/Centered/Centered';
import { ActivityTemplateConstructorProps } from '../ActivityTemplateConstructor';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import useRelatedEntity from '../../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import { setValueByFieldInEntity } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import { updateRelationship } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export interface ActivityTemplateSelectorProps extends ActivityTemplateConstructorProps
{

}

const ActivityTemplateSelector: React.FC<ActivityTemplateSelectorProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const routerStore = useContext(RouterContext);

        const templates =
            useResults(
                props.templateType,
                () => {},
                []);

        const selectedTemplate =
            useRelatedEntity(
                props.entity,
                props.templateRelationshipDefinition,
                false,
                props.commitContext
            );

        const clearTemplateFile =
            useCallback(
                (commit?: boolean) =>
                {
                    if (props.templateFileField)
                    {
                        setValueByFieldInEntity(
                            props.entity,
                            props.templateFileField,
                            undefined,
                            props.commitContext
                        );

                        if (commit)
                        {
                            return commitEntityWithContext(
                                props.entity,
                                props.commitContext
                            );
                        }
                    }
                },
                [
                    props.templateFileField,
                    props.entity,
                    props.commitContext,
                ]);

        const selectTemplate =
            useCallback(
                (template: Entity) =>
                {
                    clearTemplateFile();

                    updateRelationship(
                        props.entity,
                        false,
                        props.templateRelationshipDefinition,
                        template,
                        props.commitContext
                    );

                    return commitEntityWithContext(props.entity, props.commitContext)
                        .then(
                            () =>
                            {
                                if (props.onPreview)
                                {
                                    props.onPreview();
                                }
                            });
                },
                [
                    props.entity,
                    props.templateRelationshipDefinition,
                    clearTemplateFile,
                    props.onCancel,
                    props.commitContext,
                ]);

        const clearTemplate =
            useCallback(
                () =>
                    updateRelationship(
                        props.entity,
                        false,
                        props.templateRelationshipDefinition,
                        undefined,
                        props.commitContext
                    ),
                [
                    props.entity,
                    props.templateRelationshipDefinition,
                    props.commitContext,
                ]);

        const createBlankTemplate =
            useCallback(
                () =>
                {
                    clearTemplate();
                    clearTemplateFile();

                    setValueByFieldInEntity(
                        props.entity,
                        props.templateContentField,
                        createGutenbergDocumentDefinition(),
                        props.commitContext
                    );

                    return commitEntityWithContext(props.entity, props.commitContext)
                        .then(
                            () =>
                            {
                                if (props.onCancel)
                                {
                                    props.onCancel();
                                }
                            });
                },
                [
                    props.entity,
                    props.templateContentField,
                    props.onCancel,
                    props.commitContext,
                ]);

        const gotoTemplateManager =
            useCallback(
                () =>
                    routerStore.route(props.templateManagerRoute),
                [
                    routerStore
                ]);

        if (templates?.length === 0 && !currentUserStore.isAllowedInConfiguration)
        {
            return null;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <Centered
                        horizontal
                    >
                        <CardHeader>
                            <LocalizedText
                                code="TemplateSelector.SelectATemplate"
                                value="Kies een sjabloon"
                            />
                        </CardHeader>
                    </Centered>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Menu>
                        {
                            templates?.map(
                                template =>
                                    <Item
                                        key={template.uuid}
                                        name={template.name}
                                        onClick={
                                            () =>
                                                selectTemplate(template)
                                        }
                                        active={equalsEntity(selectedTemplate, template)}
                                        disabled={equalsEntity(selectedTemplate, template)}
                                    />)
                        }
                        <Item
                            name={
                                <LocalizedText
                                    code="TemplateSelector.EmptyTemplate"
                                    value="Blanco sjabloon"
                                />
                            }
                            onClick={createBlankTemplate}
                        />
                        {
                            currentUserStore.isAllowedInConfiguration &&
                                <Item
                                    name={
                                        <LocalizedText
                                            code="TemplateSelector.ManageTemplates"
                                            value="Sjablonen beheren..."
                                        />
                                    }
                                    onClick={gotoTemplateManager}
                                />
                        }
                    </Menu>
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

export default observer(ActivityTemplateSelector);
