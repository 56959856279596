import React, { useContext } from 'react';
import useAvatarSrc from '../Avatar/Api/useAvatarSrc';
import CurrentUserContext from '../../User/CurrentUserContext';
import { useFetchedApiFile } from '../../../../@Service/ApiClient/Hooks/useFetchedApiFile';

export type Unit = 'Pixel' | 'Percentage';

export interface OrganizationLogoProps
{
    width?: number;
    widthUnit?: Unit;
    height?: number;
    heightUnit?: Unit;
    maxWidth?: number;
    maxHeight?: number;
}

function getPixelOrPercentage(value?: number,
                              unit: Unit = 'Pixel')
{
    if (value === undefined)
    {
        return undefined;
    }
    else
    {
        if (unit === 'Percentage')
        {
            return `${value}%`;
        }
        else
        {
            return value;
        }
    }
}

const OrganizationLogo: React.FC<OrganizationLogoProps> =
    props =>
    {
        const currentUserContext = useContext(CurrentUserContext);
        const src = useAvatarSrc(currentUserContext && currentUserContext.environmentEntity);
        const [ initializedSrc ] = useFetchedApiFile(src);

        if (initializedSrc)
        {
            return <div
                style={{
                    textAlign: 'center'
                }}
            >
                <img
                    src={initializedSrc}
                    style={{
                        width: getPixelOrPercentage(props.width, props.widthUnit),
                        height: getPixelOrPercentage(props.height, props.heightUnit),
                        maxWidth: getPixelOrPercentage(props.maxWidth, props.widthUnit),
                        maxHeight: getPixelOrPercentage(props.maxHeight, props.heightUnit)
                    }}
                    alt="Logo"
                />
            </div>;
        }
        else
        {
            return <div>
                [ Logo ]
            </div>;
        }
    };

export default OrganizationLogo;
