import { EntityType } from '../Model/Implementation/EntityType';
import Predicate from '../Automation/Function/Computation/Predicate/Predicate';
import ParameterAssignment from '../Automation/Parameter/ParameterAssignment';
import { CommitContext } from './Commit/Context/CommitContext';
import { constructEntityOfType } from './Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateEntityFromFilter } from './updateEntityFromFilter';
import FunctionContext from '../Automation/Function/FunctionContext';

export default function constructEntityFromFilter(
    entityType: EntityType,
    filter: Predicate,
    parameterAssignment: ParameterAssignment,
    commitContext?: CommitContext
)
{
    const entity =
        constructEntityOfType(
            entityType,
            commitContext
        );

    if (filter)
    {
        updateEntityFromFilter(
            entity,
            filter,
            new FunctionContext(
                parameterAssignment.toParameterDictionary(),
                parameterAssignment,
                commitContext
            ),
            undefined,
            commitContext
        );
    }

    return entity;
}
