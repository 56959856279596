import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import LocalizerContext from '../../../../../../../@Service/Localization/LocalizerContext';
import { EntityValueUpdateMutation } from '../../../../../../../@Api/Model/Implementation/EntityValueUpdateMutation';
import { useComputed } from 'mobx-react-lite';
import Icon from '../../../../../../../@Future/Component/Generic/Icon/Icon';
import { makeStyles } from '@material-ui/styles';

interface AutomationDiffViewerHeaderProps
{
    event: EntityValueUpdateMutation;
}

const useStyles =
    makeStyles({
        headerText:
            {
                fontWeight: 700,
                fontSize: 17,
                textAlign: 'center'
            },
        header:
            {
                padding: "15px 15px 15px 15px"
            },
        subHeader:
        {
            fontWeight: 600,
            fontSize: 14,
            textAlign: 'center'
        }
    });

export const AutomationDiffViewerHeader: React.FC<AutomationDiffViewerHeaderProps> = observer(
    ({
        event
     }) =>
    {
        const localizer = useContext(LocalizerContext);
        const classes = useStyles();

        const userName =
            useComputed(
                () =>
                    event.user && event.user.entity
                        ? event.user.entity.getName()
                        : 'system',
                [
                    event
                ]
            );

        const eventDate =
            useMemo(
                () => new Date(event.date),
                [
                    event
                ]
            );

        return <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                        className={classes.header}
                    >
                        <ViewGroupItem>
                            <Icon
                                icon="compare_arrows"
                                size={'32px'}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem
                            className={classes.headerText}
                        >
                            <LocalizedText
                                code="AutomationDiffViewer.AutomationNameDateAndUser"
                                value="Wijziging van ${automation} op ${date} door ${user}"
                                automation={event.entity.name}
                                date={localizer.formatDate(eventDate) + ' [' + localizer.formatTime(eventDate) + ']'}
                                user={userName}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                    >
                        <ViewGroupItem
                            ratio={1}
                            className={classes.subHeader}
                        >
                            <LocalizedText
                                code="AutomationDiffViewer.ValueBeforeMutation"
                                value="Specificatie vóór wijziging"
                            />
                        </ViewGroupItem>

                        <ViewGroupItem
                            ratio={1}
                            className={classes.subHeader}
                        >
                            <LocalizedText
                                code="AutomationDiffViewer.ValueAfterMutation"
                                value="Specificatie na wijziging"
                            />
                        </ViewGroupItem>

                    </ViewGroup>
                </ViewGroupItem>
            </ViewGroup>
    }
);