import * as React from 'react';
import { Ref } from 'react';
import { FormHandlerContext } from '../../../../Generic/Form/FormHandlerContext';
import { DataObjectSpecification } from '../../Model/DataObjectSpecification';
import { DataObjectStore } from '../../DataObjectStore';

export interface DataObjectSpecificationEditorFormProperties
{
    dataObjectStore: DataObjectStore;

    ref: Ref<DataObjectSpecificationEditorForm>;

    specification: DataObjectSpecification;

    handlerContext: FormHandlerContext<DataObjectSpecification>;
}

export interface DataObjectSpecificationEditorFormState
{

}

export abstract class DataObjectSpecificationEditorForm extends React.Component<DataObjectSpecificationEditorFormProperties, DataObjectSpecificationEditorFormState>
{

}
