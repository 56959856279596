import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../Value/PrimitiveValue';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import Value from '../../Value/Value';
import moment from 'moment';

export type Truncation = 'Day' | 'Week' | 'FourWeeks' | 'Month' | 'Quarter' | 'HalfYear' | 'Year';

export default class TruncatedDateComputation extends Computation<PrimitiveValueType, PrimitiveValue>
{
    // ------------------------- Properties -------------------------

    @observable truncation: Truncation;
    @observable.ref date: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(truncation: Truncation,
                date: Computation<any, any>)
    {
        super();

        this.truncation = truncation;
        this.date = date;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('Date'));
    }

    isAsync(): boolean
    {
        return this.date.isAsync();
    }

    async apply(context: FunctionContext): Promise<PrimitiveValue>
    {
        const date = await safelyApplyFunction(this.date, context);

        return this.truncateDateValue(date);
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        const date = safelySynchronousApplyFunction(this.date, context);

        console.log(date);

        return this.truncateDateValue(date);
    }

    private truncateDateValue(value: Value<any, any>)
    {
        if (value instanceof PrimitiveValue
            && value.value.value instanceof Date)
        {
            return new PrimitiveValue(
                DataObject.constructFromTypeIdAndValue(
                    'Date',
                    this.truncateDate(value.value.value)));
        }
        else
        {
            return undefined;
        }
    }

    private truncateDate(date: Date)
    {
        const dateAsMoment = moment(date);

        if (this.truncation === 'Day')
        {
            return dateAsMoment.startOf('day').toDate();
        }
        else if (this.truncation === 'Week')
        {
            return dateAsMoment.startOf('week').toDate();
        }
        else if (this.truncation === 'FourWeeks')
        {
            const weekNumber = Math.floor(dateAsMoment.get('week') / 4);

            return dateAsMoment.startOf('year')
                .add(weekNumber * 4, 'weeks');
        }
        else if (this.truncation === 'Month')
        {
            return dateAsMoment.startOf('month').toDate();
        }
        else if (this.truncation === 'Quarter')
        {
            return dateAsMoment.startOf('quarter').toDate();
        }
        else if (this.truncation === 'HalfYear')
        {
            if (dateAsMoment.get('month') >= 6)
            {
                return dateAsMoment.startOf('year').add(6, 'months').toDate();
            }
            else
            {
                return dateAsMoment.startOf('year').toDate();
            }
        }
        else if (this.truncation === 'Year')
        {
            return dateAsMoment.startOf('year').toDate();
        }
        else
        {
            throw new Error(`Unknown truncation type: ${this.truncation}`);
        }
    }

    getName(): string
    {
        return 'Afgekapte datum...';
    }

    validate(): Validation[]
    {
        if (!this.date)
        {
            return [
                new Validation(
                    'Error',
                    'Geen datum ingesteld')
            ];
        }

        return this.date.validate();
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'TruncatedDate';
        descriptor.truncation = this.truncation;
        descriptor.date = this.date.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.date.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new TruncatedDateComputation(
            descriptor.truncation,
            await getComputationFromDescriptor(
                descriptor.date,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
