import Chip from '@material-ui/core/Chip';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BaseStore } from '../Store/BaseStore';
import { StoreState } from '../Store/@Model/StoreState';
import { Loader } from '../../@Component/Generic/Loader/Loader';
import { runInAction } from 'mobx';
import { v4 as uuid } from 'uuid';
import { StyledComponentProps } from '@material-ui/core/styles/withStyles';

export interface BaseComponentProps<T>
{
    store: T;
    style?: React.CSSProperties;
}

export abstract class BaseComponent<T extends BaseStore, P = {}> extends React.Component<StyledComponentProps & BaseComponentProps<T> & P, {}>
{
    uuid = uuid();
    store: T;
    isComponentMounted: boolean;

    abstract renderComponent?(store: T): any;

    render(): React.ReactNode
    {
        if (this.props.store == null)
        {
            throw new Error(`Creating component ${this.constructor.name} with an undefined or null store.`);
        }

        switch (this.props.store.state)
        {
            case StoreState.Loading:
            {
                return this.renderLoader(this.props.store);
            }

            case StoreState.Loaded:
            {
                return this.renderComponent(this.props.store);
            }

            case StoreState.Error:
            {
                throw new Error('error');
                // return this.renderComponent(this.props.store);
                // return this.renderErrorState(this.props.store);
            }

            default:
            {
                return this.renderError('Cannot render component! Is component decorated with @observer?');
            }
        }
    }

    // Do not remove, because super.componentDidMount() calls will fail. We want to be able
    // to implement this in the future, so we want super.componentDidMount() to be called at all implementing
    // locations
    componentDidMount()
    {
        runInAction(
            () =>
            {
                this.isComponentMounted = true;
                this.store.entersUI(true);

                let element = ReactDOM.findDOMNode(this) as any;

                if (element != null && element.setAttribute != null)
                {
                    element.setAttribute('data-component', Object.getPrototypeOf(this).constructor.name);
                    element.setAttribute('data-store', Object.getPrototypeOf(this.store).constructor.name);
                }
            });
    }

    componentDidUpdate()
    {

    }

    UNSAFE_componentWillMount()
    {
        this.setStore(this.props.store);
    }

    UNSAFE_componentWillReceiveProps(nextProps: StyledComponentProps & BaseComponentProps<T>)
    {
        // LD: Leave this command here
        // TODO: This condition should be enabled to avoid many unnessary invocations of the following code.
        // TODO: However, once enabled, sorting the product lines in a sales opputunity doesn't work anymore.
        // if (this.store !== nextProps.store)
        // {
            this.store.exitsUI(false);

            this.setStore(nextProps.store);

            this.store.entersUI(false);
        // }
    }

    componentWillUnmount()
    {
        this.store.exitsUI(true);
        this.isComponentMounted = false;
    }

    // componentDidCatch(error: Error, errorInfo: ErrorInfo)
    // {
    //     this.store.setError(`${error.name}: ${error.message} - ${errorInfo.componentStack}`);
    //     console.error(error, errorInfo);
    //
    //     function replaceErrors(key, value) {
    //         if (value instanceof Error) {
    //             var error = {};
    //
    //             Object.getOwnPropertyNames(value).forEach(function (key) {
    //                 error[key] = value[key];
    //             });
    //
    //             return error;
    //         }
    //
    //         return value;
    //     }
    //
    //     console.log(JSON.stringify(error, replaceErrors));
    // }

    setStore(store: T)
    {
        if (store == null)
        {
            console.error('Setting null store on component', this.constructor.name);
        }

        if (store !== this.store)
        {
            this.store = store;

            if (!store.isInitialized)
            {
                store.initializeStore()
                    .then(() =>
                    {
                        if (this.isComponentMounted)
                        {
                            // this.forceUpdate();
                        }
                    });
            }
        }
    }

    protected renderLoader(store: T): any
    {
        return <Loader />;
    }

    protected renderError(error: string)
    {
        return <Chip
             style={{background: 'green', color: 'white'}}
             label={
                 `
                 <${Object.getPrototypeOf(this).constructor.name}>
                 ${error}
                 `
             }
             onDelete={() => this.store.setState(StoreState.Loaded)}
        />;
    }
}
