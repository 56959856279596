import { ButtonProps, ButtonStore } from '../../../Generic/Button/ButtonStore';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { computed } from 'mobx';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { EntityReadCountButtonStore } from './EntityReadCountButtonStore';
import { DialogStore } from '../../../Generic/Dialog/Type/DialogStore';
import { ReactViewComponent } from '../../../Generic/ViewStack/Model/ReactViewComponent';
import ConvertActivityDialog from '../Bespoke/Activity/Convert/ConvertActivityDialog';
import localizeText from '../../../../@Api/Localization/localizeText';
import { FeedbackStore } from '../../../App/Root/Environment/Organization/Feedback/FeedbackStore';

export interface EntityActivityConvertButtonProps extends ButtonProps
{
    entity: PropType<EntityReadCountButtonStore, EntityActivityConvertButtonProps, Entity>;
}

const defaultProps: Partial<EntityActivityConvertButtonProps> =
{
};

export class EntityActivityConvertButtonStore extends ButtonStore<EntityActivityConvertButtonProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('FeedbackStore') feedbackStore: FeedbackStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: EntityActivityConvertButtonProps)
    {
        super({
            label:
                () =>
                {
                    return localizeText(
                        'Activity.ConvertTo',
                        'Converteren naar...');
                },
            isVisible:
                () => this.entity.entityType.isA(
                    this.entityTypeStore.bespoke.types.Activity.Email.Type),
            onClick:
                () =>
                {
                    const closeDialog =
                        this.feedbackStore.openDialog({
                            store:
                                new DialogStore(
                                    {
                                        isFullWidth: false,
                                        viewComponent:
                                            new ReactViewComponent(
                                                ConvertActivityDialog,
                                                {
                                                    entity: this.entity,
                                                    saveCallback:
                                                        success =>
                                                        {
                                                            if (success)
                                                            {
                                                                closeDialog();
                                                            }
                                                        },
                                                    closeCallback:
                                                        () =>
                                                            closeDialog()
                                                }
                                            )})
                        });
                },
            ...props,
        }, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    @computed
    get entity(): Entity
    {
        return getOrCompute(this, this.props.entity);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
