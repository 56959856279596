import { Dispatch, MutableRefObject, SetStateAction, useState } from 'react';

/**
 * This hook will return a state object that will only update its value
 * if the provided reference still exists in the DOM tree.
 *
 * @param ref
 * @param initialState
 */
export default function useSafeState<S>(
    ref: MutableRefObject<undefined>,
    initialState?: S | (() => S)): [S, Dispatch<SetStateAction<S>>]
{
    const [ state, setState ] = useState(initialState);

    return [
        state,
        value =>
        {
            setTimeout(
                () =>
                {
                    if (ref && ref.current)
                    {
                        setState(value);
                    }
                },
                0);
        }
    ];
}
