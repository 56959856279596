import React, { useCallback, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { RelationshipEditorProps } from '../RelationshipEditor';
import { EntitySelectionBuilder } from '../../../Selection/Builder/EntitySelectionBuilder';
import Selectbox from '../../../Selectbox/Selectbox';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import InputFocus from '../../../../Multiplayer/InputFocus/InputFocus';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import ParameterAssignment from '../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import EntityValue, { default as AutomationEntityValue } from '../../../../../../@Api/Automation/Value/EntityValue';
import getRelationshipDefinitionFilterFromDescriptor from '../../../../../../@Api/Metadata/RelationshipDefinition/Filter/getRelationshipDefinitionFilterFromDescriptor';
import getInheritedRelationshipDefinitionFilterDescriptor from '../../../../../../@Api/Metadata/RelationshipDefinition/Filter/getInheritedRelationshipDefinitionFilterDescriptor';
import { commitEntityWithContext } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import { updateRelationship } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { isRelationshipDefinitionRequired } from '../../../../../../@Api/Metadata/Input/isRelationshipDefinitionRequired';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import initializeDependencies from '../../../../../../@Api/Automation/Api/initializeDependencies';
import { useDisposableAsyncResult } from '../../../../../../@Util/Async/useDisposableAsyncResult';

export interface SingleRelationshipEditorProps extends RelationshipEditorProps
{
}

export const DefaultRelationshipEditor: React.FC<SingleRelationshipEditorProps> = observer(
    ({
        entity,
        relationshipDefinition,
        isParent,
        commitContext,
        doAutoCommit,
        required,
        onChange,
        onFocus,
        onConstruct,
        onBlur,
        disableUnderline,
        compact,
        useParentCommitContextForConstruction,
        selectboxProps,
        autoFocus
    }) =>
    {
        const filterDescriptor =
            useMemo(
                () =>
                    getInheritedRelationshipDefinitionFilterDescriptor(
                        entity.entityType,
                        relationshipDefinition,
                        isParent),
                [
                    entity,
                    relationshipDefinition,
                    isParent
                ]);
        const [ filterWithContext ] =
            useAsyncResult(
                () =>
                    getRelationshipDefinitionFilterFromDescriptor(
                        relationshipDefinition,
                        isParent,
                        filterDescriptor),
                [
                    relationshipDefinition,
                    isParent,
                    filterDescriptor
                ],
                filterDescriptor !== undefined
            );

        const baseFilterFunctionContext =
            useComputed(
                () =>
                    filterWithContext
                        ? new FunctionContext(
                            filterWithContext.context.parameterDictionary,
                            new ParameterAssignment()
                                .setValue(
                                    filterWithContext.baseParameter,
                                    new AutomationEntityValue(entity)
                                )
                        )
                        : undefined,
                [
                    filterWithContext,
                    entity
                ]
            );
        const dependenciesFromParameterAssignments =
            useComputed(
                () =>
                {
                    if (filterWithContext)
                    {
                        return Array.from(
                            baseFilterFunctionContext
                                .parameterAssignment
                                .valueByParameter
                                .entries()
                        )
                        .filter(
                            ([ _parameter, value ]) =>
                                value instanceof EntityValue
                        )
                        .map(
                            ([ parameter, value ]) =>
                                filterWithContext.filter.getDependencies()
                                .filter(
                                    dependency =>
                                        dependency.parameter === parameter
                                        && dependency.fieldPath
                                )
                                .map(
                                    fieldPath =>
                                        fieldPath.fieldPath!.path.traverseEntity(
                                            (value as EntityValue).value,
                                            commitContext
                                        )
                                )
                        );
                    }
                    else
                    {
                        return [];
                    }
                },
                [
                    filterWithContext,
                    baseFilterFunctionContext,
                    commitContext
                ]
            );

        useDisposableAsyncResult(
            async () =>
            {
                if (filterWithContext)
                {
                    const disposer =
                        await initializeDependencies(
                            filterWithContext.baseParameter,
                            [
                                new EntityValue(entity)
                            ],
                            filterWithContext.filter.getDependencies(),
                            commitContext
                        );

                    return {
                        value: undefined,
                        disposer: disposer
                    };
                }
                else
                {
                    return {
                        value: undefined,
                        disposer: () => {},
                    };
                }
            },
            [
                filterWithContext,
                entity,
                commitContext,
                dependenciesFromParameterAssignments,
            ]
        );

        const selections =
            useComputed(
                () => [
                    new EntitySelectionBuilder(relationshipDefinition.getEntityType(isParent))
                        .if(
                            () => true,
                            sb =>
                                sb.entityType.bespoke.getListDependencies()
                                    .forEach(
                                        path =>
                                            sb.join(path)
                                    )
                        )
                        .if(
                            () => true,
                            sb =>
                                entity.entityType.bespoke.extendRelationshipSelection(
                                    entity,
                                    relationshipDefinition,
                                    isParent,
                                    sb,
                                    commitContext
                                )
                        )
                        .if(
                            () => filterWithContext !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.filter(
                                            filterWithContext.filter,
                                            {
                                                context: baseFilterFunctionContext,
                                                parameter: filterWithContext.relatedParameter,
                                            }
                                        )
                                )
                        )
                        .build()
                ],
                [
                    entity,
                    relationshipDefinition,
                    isParent,
                    filterWithContext,
                    commitContext,
                    baseFilterFunctionContext
                ]);

        const isPlural =
            useComputed(
                () =>
                    entity.entityType.bespoke.isPluralInInterface(
                        entity,
                        relationshipDefinition,
                        isParent),
                [
                    entity,
                    relationshipDefinition,
                    isParent
                ]);

        const value =
            useComputed(
                () =>
                {
                    if (isPlural)
                    {
                        return entity.getRelatedEntitiesByDefinition(
                            isParent,
                            relationshipDefinition,
                            commitContext
                        );
                    }
                    else
                    {
                        return entity.getRelatedEntityByDefinition(
                            isParent,
                            relationshipDefinition,
                            commitContext
                        );
                    }
                },
                [
                    isPlural,
                    entity,
                    isParent,
                    relationshipDefinition,
                    commitContext,
                ]);

        const localOnChange =
            useCallback(
                (newValue: Entity | Entity[]) =>
                {
                    if (isPlural)
                    {
                        const newRelatedEntities = newValue as Entity[];
                        const newRelatedEntityUuids = new Set(newRelatedEntities.map(e => e.uuid));
                        const values =
                            entity.getRelatedEntitiesByDefinition(
                                isParent,
                                relationshipDefinition,
                                commitContext
                            );
                        const currentRelatedEntityUuids = new Set(values.map(e => e.uuid));

                        // Delete relationships that are no longer in the set
                        entity
                            .getRelationshipsByDefinition(
                                isParent,
                                relationshipDefinition,
                                commitContext
                            )
                            .filter(
                                relationship =>
                                    !newRelatedEntityUuids.has(
                                        relationship.getEntity(isParent).uuid))
                            .forEach(
                                relationship =>
                                    updateRelationship(
                                        entity,
                                        isParent,
                                        relationshipDefinition,
                                        undefined,
                                        commitContext,
                                        relationship.getEntity(isParent)));

                        // Add relationships
                        newRelatedEntities
                            .filter(
                                relatedEntity =>
                                    !currentRelatedEntityUuids.has(relatedEntity.uuid))
                            .forEach(
                                relatedEntity =>
                                    updateRelationship(
                                        entity,
                                        isParent,
                                        relationshipDefinition,
                                        relatedEntity,
                                        commitContext
                                    )
                            );
                    }
                    else
                    {
                        const relatedEntity = newValue as Entity;

                        updateRelationship(
                            entity,
                            isParent,
                            relationshipDefinition,
                            relatedEntity,
                            commitContext,
                            // isPlural might not be actually plural (only plural in interface)
                            // e.g. for parent linked activities
                            // To delete or update a plural relationship, we need to
                            // pass the 'fromEntity'
                            relationshipDefinition.isPlural(isParent)
                                ?
                                    entity.getRelatedEntityByDefinition(
                                        isParent,
                                        relationshipDefinition)
                                :
                                    undefined
                        );
                    }

                    if (doAutoCommit)
                    {
                        return commitEntityWithContext(
                            entity,
                            commitContext,
                            {
                                isForced: true,
                                isAutoCommit: true,
                            }
                        );
                    }

                    if (onChange)
                    {
                        onChange(newValue);
                    }
                },
                [
                    isPlural,
                    entity,
                    isParent,
                    relationshipDefinition,
                    doAutoCommit,
                    commitContext,
                    onChange,
                ]);

        const isRequired =
            useComputed(
                () =>
                    required ||
                    isRelationshipDefinitionRequired(entity.entityType, isParent, relationshipDefinition),
                [
                    required,
                    relationshipDefinition,
                    isParent,
                ]);

        const hideCaptionInValue =
            useComputed(
                () =>
                    entity.entityType.bespoke.hideCaptionInSelectboxValue(
                        entity,
                        relationshipDefinition,
                        isParent),
                [
                    entity,
                    relationshipDefinition,
                    isParent
                ]);

        const emptyOptionLabel =
            useComputed(
                () =>
                    entity.entityType.bespoke.getEmptyOptionLabel(
                        entity,
                        relationshipDefinition,
                        isParent),
                [
                    entity,
                    relationshipDefinition,
                    isParent
                ]);

        const [ isFocused, setFocused ] = useState(false);
        const localOnFocus =
            useCallback(
                () =>
                {
                    setFocused(true);

                    if (onFocus)
                    {
                        onFocus();
                    }
                },
                [
                    setFocused,
                    onFocus
                ]);
        const localOnBlur =
            useCallback(
                () =>
                {
                    setFocused(false);

                    if (onBlur)
                    {
                        onBlur();
                    }
                },
                [
                    setFocused,
                    onBlur
                ]);

        const localOnConstruct =
            useCallback(
                (newEntity: Entity, commitContext: CommitContext) =>
                {
                    if (onConstruct)
                    {
                        onConstruct(newEntity, commitContext);
                    }

                    newEntity.entityType.bespoke.onConstructFromRelationship(
                        newEntity,
                        entity,
                        relationshipDefinition,
                        isParent,
                        commitContext
                    );
                },
                [
                    entity,
                    onConstruct,
                    relationshipDefinition,
                    isParent
                ]);

        return <InputFocus
            entity={entity}
            field={relationshipDefinition}
            parent={isParent}
            focused={isFocused}
        >
            <Selectbox
                selections={selections}
                value={value}
                onChange={localOnChange}
                disableUnderline={disableUnderline}
                multi={isPlural}
                clearable={!isRequired && emptyOptionLabel === undefined}
                emptyOption={emptyOptionLabel}
                autoFocus={autoFocus}
                onFocus={localOnFocus}
                onBlur={localOnBlur}
                hideCaptionInValue={hideCaptionInValue}
                onConstruct={localOnConstruct}
                commitContext={commitContext}
                useParentCommitContextForConstruction={useParentCommitContextForConstruction}
                interceptConstruction={onConstruct !== undefined}
                compact={compact}
                {...selectboxProps}
            />
        </InputFocus>;
    }
);
