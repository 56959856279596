import React from 'react';
import LabelButton, { LabelButtonProps } from '../Label/LabelButton';
import { warningColor } from '../../../../../../@Resource/Theme/Theme';

export interface WarningButtonProps extends LabelButtonProps
{

}

const WarningButton: React.FC<WarningButtonProps> =
    props =>
    {
        return <LabelButton
            {...props}
        />;
    };

WarningButton.defaultProps = {
    color: warningColor,
    filled: true
};

export default WarningButton;
