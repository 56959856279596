import React from 'react';
import { observer } from 'mobx-react-lite';
import EntityLink from '../../../../Link/EntityLink';
import { EntityEvent } from '../../../../../../../@Api/Model/Implementation/EntityEvent';
import styles from './Mutator.module.scss';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface RelationshipProps
{
    event: EntityEvent;
}

const Mutator: React.FC<RelationshipProps> =
    props =>
    {
        if (props.event.user && props.event.user.entity)
        {
            return <span
                className={classNames(
                    styles.root,
                    styles.link
                )}
            >
                <EntityLink
                    entity={props.event.user.entity}
                >
                    {props.event.user.entity.name}
                </EntityLink>
            </span>;
        }
        else
        {
            return <span
                className={styles.root}
            >
                <LocalizedText
                    code="TimeLine.AuditTrail.ExternalPerson"
                    value="Een extern persoon"
                />
            </span>;
        }
    };

export default observer(Mutator);
