import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { useComputed } from 'mobx-react-lite';

export default function useSpotlerCampaignPhaseCode(spotlerCampaign: Entity, commitContext: CommitContext)
{
    const types = useTypes();

    const phase =
        useComputed(
            () =>
                spotlerCampaign
                    .getRelatedEntityByDefinition(
                        false,
                        types.Activity.SpotlerCampaign.RelationshipDefinition.Phase,
                        commitContext),
            [
                spotlerCampaign,
                types,
                commitContext
            ]
        );

    return useComputed(
        () =>
            phase?.getObjectValueByField(types.Datastore.Field.Code, commitContext),
        [
            phase,
            types,
            commitContext
        ]
    );
}