import { BaseStore, getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { computed } from 'mobx';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { InputStore } from '../../../Generic/Input/InputStore';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { EntityPath } from '../Path/@Model/EntityPath';
import { EntityFieldPath } from '../Path/@Model/EntityFieldPath';
import { ViewComponent } from '../../../Generic/ViewStack/Model/ViewComponent';
import { DataObjectStore } from '../../DataObject/DataObjectStore';
import { DataObjectViewerStore } from '../../DataObject/Viewer/DataObjectViewerStore';
import { DataObjectViewer } from '../../DataObject/Viewer/DataObjectViewer';
import { RelatedEntityGroupListStore } from '../Viewer/Deprecated/Related/Group/RelatedEntityGroupListStore';
import { RelatedEntityGroup } from '../Viewer/Deprecated/Related/Group/RelatedEntityGroup';
import { EntityViewerStore } from '../Viewer/Deprecated/EntityViewerStore';
import { DataObject } from '../../DataObject/Model/DataObject';
import List from '../../../Generic/List/V2/List';
import { ViewGroupStore } from '../../../Generic/ViewGroup/ViewGroupStore';
import { ViewGroupItem } from '../../../Generic/ViewGroup/ViewGroupItem';
import { Action } from '../Type/BespokeEntityType';
import { CurrentUserStore } from '../../User/CurrentUserStore';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import { CommitContextImpl } from '../../../../@Api/Entity/Commit/Context/CommitContextImpl';
import localizeText from '../../../../@Api/Localization/localizeText';

export interface EntitySystemFieldsProps
{
    id?: PropType<EntitySystemFieldsStore, EntitySystemFieldsProps, string>;
    entity: PropType<EntitySystemFieldsStore, EntitySystemFieldsProps, Entity>
}

const defaultProps: Partial<EntitySystemFieldsProps> =
{

};

export class EntitySystemFieldsStore extends BaseStore<EntitySystemFieldsProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;

    // ------------------------- Properties -------------------------

    commitContext: CommitContext;

    // ------------------------ Constructor -------------------------

    constructor(props: EntitySystemFieldsProps)
    {
        super(props, defaultProps);

        this.commitContext = new CommitContextImpl();
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get entity(): Entity
    {
        return getOrCompute(this, this.props.entity);
    }

    @computed
    get actions(): Action[]
    {
        return this.entity.entityType.bespoke.getActions(this.entity);
    }

    @computed
    get employee(): Entity
    {
        if (this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Person.Type))
        {
            return this.entity.getRelatedEntitiesByDefinition(
                true,
                this.entityTypeStore.bespoke.types.Relationship.Person.RelationshipDefinition.Person)
                .find(
                    entity =>
                        entity.entityType.isA(
                            this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Employee.Type));
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get paths(): EntityFieldPath[]
    {
        const entityRootPath = EntityPath.fromEntity(this.entity);

        return [
            entityRootPath.field(this.entityTypeStore.uuidField),
            entityRootPath.field(this.entityTypeStore.idField),
            entityRootPath.field(this.entityTypeStore.typeField),
            entityRootPath.field(this.entityTypeStore.sortIndexField),
            entityRootPath
                .joinTo(
                    this.entityTypeStore.bespoke.types.Pack.RelationshipDefinition.Entities,
                    true)
                .field(undefined),
        ];
    }

    @computed
    get relatedPaths(): EntityPath[]
    {
        return [
            EntityPath.fromEntity(this.entity)
                .joinTo(
                    this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.ExternalIds,
                    false),
            EntityPath.fromEntity(this.entity)
                .joinTo(
                    this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Notifications,
                    false),
            this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Organization.Environment.Type)
                && EntityPath.fromEntity(this.entity)
                    .joinTo(
                        this.entityTypeStore.bespoke.types.Relation.Organization.Environment.RelationshipDefinition.Packs,
                        false),
            this.currentUserStore.isDeveloper
                && this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Person.Account.Type)
                && EntityPath.fromEntity(this.entity)
                    .joinTo(
                        this.entityTypeStore.bespoke.types.Relation.Person.Account.RelationshipDefinition.Packs,
                        false)
        ].filter(path => path);
    }

    // --------------------------- Stores ---------------------------

    @computed
    get fields2ListStore(): ViewGroupStore
    {
        return new ViewGroupStore({
            items:
            [
                new ViewGroupItem({
                    id: 'List',
                    view:
                        new ViewComponent(
                            List,
                            this.genericRelatedListStore),
                    spacing: 0
                })
            ].filter(item => item !== undefined)
        });
    }

    @computed
    get accountEmailInputStore(): InputStore
    {
        return new InputStore({
            labelProps:
                {
                    label: localizeText('AccountEmail','Account email')
                },
            labelPosition: 'left',
            margin: '0 24px',
            view:
                new ViewComponent(
                    DataObjectViewer,
                    new DataObjectViewerStore(
                        DataObject.constructFromTypeAndValue(
                            this.dataObjectStore.getTypeById('EmailAddress'),
                            this.employee.user && this.employee.user.account
                                ?
                                    this.employee.user.account.username
                                :
                                    '')))
        });
    }

    @computed
    get genericRelatedListStore(): RelatedEntityGroupListStore
    {
        const rootGroup =
            new RelatedEntityGroup(
                'Root',
                undefined,
                false,
                [],
                this.entity,
                this.genericRelatedListStorePaths,
                []);

        return new RelatedEntityGroupListStore(
            [ rootGroup ],
            new EntityViewerStore(this.entity),
            () => Promise.resolve());
    }

    @computed
    get genericRelatedListStorePaths()
    {
        const relatedPaths: EntityPath[] = [];

        relatedPaths.push(
            EntityPath.fromEntity(this.entity)
                .joinTo(
                    this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.ExternalIds,
                    false));

        if (this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Organization.Environment.Type))
        {
            relatedPaths.push(
                EntityPath.fromEntity(this.entity)
                    .joinTo(
                        this.entityTypeStore.bespoke.types.Relation.Organization.Environment.RelationshipDefinition.Packs,
                        false));
        }

        if (this.currentUserStore.isDeveloper
            && this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Person.Account.Type))
        {
            relatedPaths.push(
                EntityPath.fromEntity(this.entity)
                    .joinTo(
                        this.entityTypeStore.bespoke.types.Relation.Person.Account.RelationshipDefinition.Packs,
                        false));
        }

        return relatedPaths;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
