import { type } from '../../../@Util/Serialization/Serialization';
import { EntityFieldMutation } from './EntityFieldMutation';

@type('EntityFieldDeletionMutation')
export class EntityFieldDeletionMutation extends EntityFieldMutation
{
    // ------------------- Persistent Properties --------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
