import ParameterAssignment from '../Parameter/ParameterAssignment';
import Dependency from '../Parameter/Dependency';
import initializeDependencies from './initializeDependencies';
import { groupBy } from '../../../@Util/MapUtils/groupBy';
import { Disposer } from '../../../@Util/Disposable/Disposer';

export async function initializeParameterAssignments(
    parameterAssignments: ParameterAssignment[],
    dependencies: Dependency[]
): Promise<Disposer>
{
    const dependenciesByParameter =
        groupBy(
            dependencies,
            dependency =>
                dependency.parameter
        );

    const disposers =
        await Promise.all(
            Array.from(dependenciesByParameter.entries())
                .map(
                    ([ parameter, dependencies ]) =>
                        initializeDependencies(
                            parameter,
                            parameterAssignments.map(
                                parameterAssignment =>
                                    parameterAssignment.getValue(parameter)
                            ),
                            dependencies
                        )
                )
        );

    return () =>
        disposers.forEach(
            disposer =>
                disposer()
        );
}
