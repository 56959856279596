import TrackCell from './TrackCell';
import Period from '../../../Model/Period';

export default class TrackCellRange
{
    // ------------------------- Properties -------------------------

    from: TrackCell;
    to: TrackCell;

    // ------------------------ Constructor -------------------------

    constructor(from: TrackCell, to: TrackCell)
    {
        this.from = from;
        this.to = to;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getPeriod(): Period
    {
        return {
            from: this.from.cell.period.from,
            to: this.to.cell.period.to
        };
    }

    // ----------------------- Private logic ------------------------
}
