import React from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../Type/Api/useTypes';
import Input from '../../../../../../../Input/Input';
import { Alignment } from '../../../../../../../../DataObject/Model/DataObject';
import { ProductLineEditorInterface } from '../Hooks/useProductLine';

export interface ProductLinePurchasePriceProps
{
    productLine: ProductLineEditorInterface;
    autoCommit: boolean;
    disabled: boolean;
    showLabel?: boolean;
    alignment?: Alignment;
}

export const ProductLinePurchasePrice: React.FC<ProductLinePurchasePriceProps> = observer(
    ({
         productLine,
         autoCommit,
         disabled,
         showLabel,
         alignment,
     }) =>
    {
        const types = useTypes();

        return <Input
            entity={productLine.productLine}
            field={types.ProductLine.Field.PurchasePrice}
            labelPosition={showLabel ? 'left' : 'none'}
            alignment={alignment}
            disabled={productLine.isPriceReadOnly || disabled}
            doAutocommit={autoCommit}
            commitContext={productLine.commitContext}
        />
    })