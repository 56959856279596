import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import Role, { RoleType } from '../../Model/Role';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import CardHeader from '../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { ItemEditor } from './Item/ItemEditor';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import FieldInput from '../../../Multiplayer/Model/Input/FieldInput';
import RelationshipInput from '../../../Multiplayer/Model/Input/RelationshipInput';
import { PrivilegeHeader } from '../RightEditor/PrivilegeHeader/PrivilegeHeader';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import styles from '../RoleEditor.module.scss';
import useTypes from '../../../Entity/Type/Api/useTypes';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { isRoleEntityTypeVisible } from '../../Api/useRoleEntityType';

export interface MemberProps
{
    rolesByType: Map<RoleType, Role>;
    role: Role;
    entityType: EntityType;
    onClose: () => void;
    isReadOnly: boolean;
}

export const RoleFieldsEditor: React.FC<MemberProps> =
    observer(
        ({
             role,
             entityType,
             onClose,
             isReadOnly,
             rolesByType,
         }) =>
        {
            const dividerGlue = useDividerGlue();
            const types = useTypes();

            const isEntityTypeVisible =
                useMemo(
                    () =>
                        isRoleEntityTypeVisible(role, entityType),
                    [
                        role,
                        entityType
                    ]
                );

            const customFilter =
                useCallback(
                    (isParent: boolean,
                     relationshipDefinition: EntityRelationshipDefinition) =>
                        isParent
                        || relationshipDefinition.type !== types.Relationship.Person.RelationshipDefinition.Person.type
                        || relationshipDefinition.type !== types.Relationship.Organization.RelationshipDefinition.Organization.type,
                    [
                        types
                    ]);

            const fields =
                useMemo(
                    () => [
                        ...entityType
                            .fields
                            .filter(
                                field =>
                                    !field.isComputedField
                                    && !field.isReadonly)
                            .map(
                                field =>
                                    new FieldInput(
                                        field.entityType,
                                        field)),
                        ...[ true, false ]
                            .map(
                                isParent =>
                                    entityType.getRelationshipDefinitions(isParent)
                                        .filter(
                                            relationshipType =>
                                                customFilter(isParent, relationshipType),
                                        )
                                        .map(
                                            relationshipType =>
                                                new RelationshipInput(
                                                    entityType,
                                                    relationshipType,
                                                    isParent)))
                            .reduce((a, b) => a.concat(b), [])
                    ],
                    [
                        entityType
                    ]);

            return <Card>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    glue={dividerGlue}
                >
                    <ViewGroupItem>
                        <CardInset>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                                alignment="end"
                                className={styles.header}
                            >
                                <ViewGroupItem>
                                    <IconButton
                                        icon="arrow_back"
                                        onClick={onClose}
                                        tooltip={
                                            <LocalizedText
                                                code="Generic.Return"
                                                value= "Ga terug"
                                            />
                                        }
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <CardHeader>
                                        {entityType.getName()}
                                    </CardHeader>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <PrivilegeHeader />
                                </ViewGroupItem>
                            </ViewGroup>
                        </CardInset>
                    </ViewGroupItem>
                    {
                        fields
                            .map(
                                field =>
                                    <ViewGroupItem
                                        key={field.id()}
                                    >
                                        <ItemEditor
                                            role={role}
                                            input={field}
                                            isReadOnly={isReadOnly || !isEntityTypeVisible}
                                            rolesByType={rolesByType}
                                        />
                                    </ViewGroupItem>)
                    }
                    {
                        fields.length === 0 &&
                            <ViewGroupItem>
                                <CardInset>
                                    <Centered
                                        horizontal
                                    >
                                        <LocalizedText
                                            code="RoleEntityTypeEditor.NoFieldsFound"
                                            value="Géén velden gevonden."
                                        />
                                    </Centered>
                                </CardInset>
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </Card>;
        }
    );
