import React, { useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import getLayoutFromDescriptor from '../../../../../../../../@Api/Layout/Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../../../../@Api/Layout/LayoutDependencyContext';
import ParameterDictionary from '../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import ParameterAssignment from '../../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import EntityValue from '../../../../../../../../@Api/Automation/Value/EntityValue';
import LayoutViewer from '../../../../../../Layout/Viewer/LayoutViewer';
import useRelatedEntity from '../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import EntityTypeContext from '../../../../../Type/EntityTypeContext';
import PrimitiveValueType from '../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../../../DataObject/Model/DataObject';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import PrimitiveValue from '../../../../../../../../@Api/Automation/Value/PrimitiveValue';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';

export interface PageTabTitleProps
{
    page: Entity;
    entity: Entity;
    commitContext: CommitContext;
}

const PageTabTitle: React.FC<PageTabTitleProps> =
    props =>
    {
        const { page, entity, commitContext } = props;

        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);

        const entityTypeEntity =
            useRelatedEntity(
                page,
                types.EntityType.RelationshipDefinition.Pages,
                true);
        const entityType =
            useComputed(
                () =>
                    entityTypeStore.getTypeByEntityId(entityTypeEntity.id),
                [
                    entityTypeStore,
                    entityTypeEntity
                ]
            );
        const layoutContext =
            useMemo(
                () =>
                    new LayoutDependencyContext(
                        new ParameterDictionary([
                            new Parameter(
                                'Entity',
                                new EntityValueType(entityType),
                                true,
                                entity.entityType.getName()
                            ),
                            new Parameter(
                                'Date',
                                new PrimitiveValueType(DataObject.getTypeById('DateTime')),
                                true,
                                localizeText('Generic.Date', 'Datum')
                            )
                        ])),
                [
                    entity
                ]
            );
        const parameterAssignment =
            useMemo(
                () =>
                    new ParameterAssignment()
                        .setValue(
                            layoutContext.parameterDictionary.getParameterById('Entity'),
                            new EntityValue(entity)
                        )
                        .setValue(
                            layoutContext.parameterDictionary.getParameterById('Date'),
                            new PrimitiveValue(DataObject.constructFromTypeIdAndValue('DateTime', new Date())
                            )
                        ),
                [
                    layoutContext,
                    entity
                ]
            );
        const titleLayoutSpecification = useEntityValue(page, types.Page.Field.TitleLayout);
        const [ titleLayout, isTitleLayoutLoading ] =
            useAsyncResult(
                async () =>
                {
                    if (titleLayoutSpecification)
                    {
                        return getLayoutFromDescriptor(
                            titleLayoutSpecification,
                            layoutContext
                        );
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    titleLayoutSpecification,
                    layoutContext,
                ]
            );

        if (isTitleLayoutLoading)
        {
            return null;
        }
        else if (titleLayout)
        {
            return <LayoutViewer
                layout={titleLayout}
                parameterDictionary={layoutContext.parameterDictionary}
                parameterAssignment={parameterAssignment}
                commitContext={commitContext}
            />;
        }
        else
        {
            return <>{page.name}</>;
        }
    };

export default observer(PageTabTitle);
