import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import useLocalSetting from './useLocalSetting';
import useEntityByUuid from '../../../../@Api/Entity/Bespoke/useEntityByUuid';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { useCallback } from 'react';

export default function useLocalEntitySetting(code: string,
                                              entityType?: EntityType,
                                              initialState?: Entity): [ Entity, (entity?: Entity) => void, boolean ]
{
    const [ entityId, setEntityId ] = useLocalSetting(code, initialState?.uuid);
    const [ entity, isLoading ] = useEntityByUuid(entityType, entityId);
    const setEntity =
        useCallback(
            (entity?: Entity) =>
            {
                setEntityId(entity?.uuid);
            },
            [
                setEntityId
            ]);

    return [
        entity,
        setEntity,
        isLoading
    ];
}
