import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import CurrentUserContext from '../../../../../User/CurrentUserContext';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import useTypes from '../../../../Type/Api/useTypes';
import RelatedList from '../../../Shared/RelatedList/RelatedList';

export interface InvolvedListProps
{
    entity: Entity;
}

const InvolvedList: React.FC<InvolvedListProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();
        const path =
            useComputed(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Activity.RelationshipDefinition.Involved,
                            false),
                [
                    props.entity,
                    currentUserStore,
                    types
                ]);
        const initializationPaths =
            useComputed(
                () => [
                    EntityPath
                        .fromEntityType(types.Involved.Type)
                        .joinTo(
                            types.Involved.RelationshipDefinition.Relationship,
                            false),
                    EntityPath
                        .fromEntityType(types.Involved.Type)
                        .joinTo(
                            types.Involved.RelationshipDefinition.Contact,
                            false),
                    EntityPath
                        .fromEntityType(types.Involved.Type)
                        .joinTo(
                            types.Involved.RelationshipDefinition.Role,
                            false)
                ],
                [
                    types
                ]);

        return <RelatedList
            entity={props.entity}
            path={path}
            initializationPaths={initializationPaths}
        />;
    };

export default observer(InvolvedList);
