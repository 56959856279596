import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../DataObject/Model/DataObject';
import Value from '../../../../../@Api/Automation/Value/Value';
import { AssistantTextValue } from '../../Model/Value/AssistantTextValue';

export function findAssistantTextValue(
    value: AssistantTextValue
): Value<any, any>
{
    return new PrimitiveValue(
        DataObject.constructFromTypeIdAndValue(
            'Text',
            value.value
        )
    );
}
