import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { Action, BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import localizeText from '../../../../../@Api/Localization/localizeText';
import { downloadApiFile } from '../../../../../@Service/ApiClient/Hooks/downloadApiFile';

export class BespokeAttachment extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore, code: string = 'Attachment')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isOpenable(entity: Entity,
               pathFromRelatedEntity?: RelatedEntityPath): boolean
    {
        return true;
    }

    onOpen(entity: Entity,
           pathFromRelatedEntity?: RelatedEntityPath): Promise<boolean>
    {
        const value = entity.getValueByField(this.entityTypeStore.bespoke.types.Attachment.Field.File);

        if (value)
        {
            const url = this.entityTypeStore.getFileUrl(value);

            return downloadApiFile(url)
                .then(
                    () =>
                        Promise.resolve(false)
                );
        }
        else
        {
            return Promise.resolve(false);
        }
    }

    getEntityToList(entity: Entity,
                    isRelated: boolean): Entity
    {
        if (isRelated)
        {
            return entity;
        }
        else
        {
            return entity.getRelatedEntityByDefinition(
                true,
                this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Attachments);
        }
    }

    getColor(entity: Entity): string
    {
        const owner =
            entity.getRelatedEntityByDefinition(
                true,
                this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Attachments);

        if (owner)
        {
            return owner.entityType.getInheritedColor();
        }
        else
        {
            return entity.entityType.getInheritedColor();
        }
    }

    getActions(entity: Entity): Action[]
    {
        return [
            ...super.getActions(entity),
            {
                id: 'Attachment.Email',
                code: 'Attachment.Email',
                name: localizeText('Attachment.Email', 'E-mail bijlage'),
                perform:
                    attachment =>
                    {
                        return this.entityTypeStore.entity.activity.email.openEmailComposerForAttachment(attachment);
                    }
            }
        ];
    }

    // ----------------------- Private logic ------------------------
}
