import React, { useCallback, useMemo } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import Fields from '../../../Fields/Fields';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import useTypes from '../../../Type/Api/useTypes';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import CompositeLayout from '../../../../../../@Api/Layout/Type/CompositeLayout';
import LayoutEditor from '../../../../Layout/Editor/LayoutEditor';
import SaveButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import Layout from '../../../../../../@Api/Layout/Layout';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import CustomWidgetSpecification from '../../../Dashboard/Model/Widget/Custom/Model/CustomWidgetSpecification';
import { runInAction } from 'mobx';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import Switch from '../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import getCustomWidgetParameters from '../../../../../../@Api/Dashboard/CustomWidget/getCustomWidgetParameters';
import LayoutDependencyContext from '../../../../../../@Api/Layout/LayoutDependencyContext';

export interface PageProps
{
    entity: Entity;
}

const CustomWidget: React.FC<PageProps> =
    ({
        entity,
     }) =>
    {
        const types = useTypes();
        const specificationDescriptor =
            useEntityValue<any>(
                entity,
                types.CustomWidget.Field.Specification
            );
        const parameterDictionary =
            useMemo(
                () =>
                    getCustomWidgetParameters(),
                []
            );
        const [ specification, isLoading ] =
            useAsyncResult(
                async () =>
                    specificationDescriptor
                        ?
                            await CustomWidgetSpecification.fromDescriptor(
                                specificationDescriptor,
                                new LayoutDependencyContext(parameterDictionary)
                            )
                        :
                            new CustomWidgetSpecification(
                                new CompositeLayout(
                                    'Vertical',
                                    []
                                ),
                                false
                            ),
                [
                    specificationDescriptor,
                    parameterDictionary,
                ]
            );
        const setFullWidth =
            useCallback(
                (isFullWidth: boolean) =>
                    runInAction(
                        () =>
                            specification.isFullWidth = isFullWidth
                    ),
                [
                    specification
                ]
            );
        const setLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            specification.layout = layout
                    ),
                [
                    specification
                ]
            );
        const saveSpecification =
            useCallback(
                () =>
                    new CommitBuilder()
                        .setObjectValueInEntity(
                            entity,
                            types.CustomWidget.Field.Specification,
                            specification.toDescriptor()
                        )
                        .commit(),
                [
                    entity,
                    types,
                    specification,
                ]);

        return <>
            <BaseLayout>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Card>
                                    <CardInset
                                        bottom={false}
                                    >
                                        <ViewGroup
                                            orientation="horizontal"
                                            spacing={15}
                                        >
                                            <ViewGroupItem
                                                ratio={1}
                                            >
                                                <TypeAndName
                                                    entity={entity}
                                                />
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <SaveButton
                                                    onClick={saveSpecification}
                                                />
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <MenuButton
                                                    entity={entity}
                                                />
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </CardInset>
                                    <Fields
                                        entity={entity}
                                    />
                                    {
                                        specification &&
                                            <CardInset>
                                                <Input
                                                    label={
                                                        <LocalizedText
                                                            code="CustomWidget.IsFullWidth"
                                                            value="Is volledige breedte"
                                                        />
                                                    }
                                                    labelPosition="left"
                                                >
                                                    <Switch
                                                        checked={specification.isFullWidth}
                                                        onToggle={setFullWidth}
                                                    />
                                                </Input>
                                            </CardInset>
                                    }
                                </Card>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                {
                                    isLoading &&
                                        <Centered
                                            horizontal
                                            vertical
                                        >
                                            <Loader />
                                        </Centered>
                                }
                                {
                                    specification &&
                                        <Card>
                                            <LayoutEditor
                                                layout={specification.layout}
                                                parameterDictionary={parameterDictionary}
                                                onChange={setLayout}
                                            />
                                        </Card>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </BaseLayout>
        </>;
    };

export default observer(CustomWidget);
