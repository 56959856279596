import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import Fields from '../../../Fields/Fields';
import Header from '../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import ExpansionPanel from '../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import OverrideInputContext, { InputOverrideRenderFunction } from '../../../Input/OverrideInputContext';
import TypeMapping from '../../Model/TypeMapping';
import ExpansionGroup from '../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import { TypeFieldInclusion } from '../../../Fields/Api/useVisibleFieldGroups';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import useTypes from '../../../Type/Api/useTypes';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export interface FieldMappingsProps
{
    typeMapping: TypeMapping;
    entity: Entity;
    commitContext: CommitContext;
    renderInputOverride: InputOverrideRenderFunction;
    forType?: EntityType;
    includeTypeField?: TypeFieldInclusion;
    advanced?: boolean;
}

const FieldMappings: React.FC<FieldMappingsProps> =
    props =>
    {
        const types = useTypes();
        const additionalFieldPaths =
            useMemo(
                () => [
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Relationship.Person.Contact.Employee.RelationshipDefinition.CreatedEntities,
                            true)
                        .field(),
                    EntityPath.fromEntity(props.entity)
                        .field(types.Entity.Field.CreationDate),
                ],
                [
                    props.entity,
                    types
                ]);

        if (props.forType)
        {
            return <>
                <Divider />
                <ExpansionGroup>
                    <ExpansionPanel
                        id={props.forType.id}
                        summary={
                            <Header
                                title={`${props.forType.getName()}-${localizeText('Import.SpecificFields', 'specifieke velden')}`}
                                inset
                            />
                        }
                        expansion={
                            <div>
                                <OverrideInputContext.Provider
                                    value={props.renderInputOverride}
                                >
                                    <Fields
                                        entity={props.entity}
                                        commitContext={props.commitContext}
                                        forType={props.forType}
                                        includeTypeField={props.includeTypeField}
                                        expanded
                                        ignoreHidden={props.advanced}
                                    />
                                </OverrideInputContext.Provider>
                            </div>
                        }
                        expanded
                    />
                </ExpansionGroup>
            </>;
        }
        else
        {
            return <>
                {
                    props.advanced &&
                        <CardInset
                            vertical={false}
                            horizontal
                        >
                            <InputGroup>
                                {
                                    additionalFieldPaths.map(
                                        fieldPath =>
                                            <React.Fragment
                                                key={fieldPath.id}
                                            >
                                                {props.renderInputOverride(props.entity, fieldPath)}
                                            </React.Fragment>)
                                }
                            </InputGroup>
                        </CardInset>
                }
                <OverrideInputContext.Provider
                    value={props.renderInputOverride}
                >
                    <Fields
                        entity={props.entity}
                        commitContext={props.commitContext}
                        includeTypeField={props.includeTypeField}
                        expanded
                        ignoreHidden={props.advanced}
                    />
                </OverrideInputContext.Provider>
            </>;
        }
    };

export default observer(FieldMappings);
