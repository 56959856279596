import { observable } from 'mobx';
import TableDimensionSection from './TableDimensionSection';
import ParameterDictionary from '../../../../Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../../../Automation/Parameter/ParameterAssignment';
import Layout from '../../../Layout';

export default class TableDimensionInstance
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref section: TableDimensionSection;
    @observable size: number;
    @observable.ref parameters: ParameterDictionary;
    @observable.ref parameterAssignment: ParameterAssignment;
    @observable.ref layout?: Layout;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                section: TableDimensionSection,
                size: number,
                parameters: ParameterDictionary,
                parameterAssignment: ParameterAssignment,
                layout?: Layout)
    {
        this.id = id;
        this.section = section;
        this.size = size;
        this.parameters = parameters;
        this.parameterAssignment = parameterAssignment;
        this.layout = layout;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
