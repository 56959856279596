import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../../../../Viewer/LayoutViewer';
import PortalTableLayout from '../../../../../../../../@Api/Layout/Type/PortalTable/PortalTableLayout';
import { TableRow } from '@material-ui/core';
import { PortalTableCell } from './Cell/PortalTableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { recordHoverBackgroundColor } from '../../../../../../../../@Resource/Theme/Theme';
import { getLayoutEventTriggerHtmlEvents } from '../../../../../../../../@Api/Layout/Event/Api/getLayoutEventTriggerHtmlEvents';
import LayoutContext from '../../../../../../../../@Api/Layout/LayoutContext';
import FormLayoutContexts from '../../../../Form/FormLayoutContexts';

const useStyles =
    makeStyles({
        clickable: {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: recordHoverBackgroundColor,
            },
        },
    });

export interface PortalTableRowProps extends LayoutViewerProps<PortalTableLayout>
{

}

export const PortalTableRow: React.FC<PortalTableRowProps> =
    observer(
        ({
            layout,
            parameterDictionary,
            parameterAssignment,
            commitContext,
            safeMode,
            ...otherProps
         }) =>
        {
            const classes = useStyles();
            const hasClickEvent =
                useMemo(
                    () =>
                        layout.rowEventTriggers.triggers
                            .some(
                                trigger =>
                                    trigger.eventType === 'Click'
                            ),
                    [
                        layout,
                    ]
                );
            const formLayoutContexts = useContext(FormLayoutContexts);
            const rowEvents =
                useMemo(
                    () =>
                        getLayoutEventTriggerHtmlEvents(
                            layout.rowEventTriggers,
                            new LayoutContext(
                                parameterDictionary,
                                parameterAssignment,
                                commitContext,
                                safeMode,
                                formLayoutContexts,
                            )
                        ),
                    [
                        layout,
                        parameterDictionary,
                        parameterAssignment,
                        commitContext,
                        safeMode,
                        formLayoutContexts,
                    ]
                );

            return <TableRow
                className={
                    hasClickEvent
                        ? classes.clickable
                        : undefined
                }
                {...rowEvents}
            >
                {
                    layout.columns.map(
                        column =>
                            <PortalTableCell
                                key={column.id}
                                {...otherProps}
                                parameterDictionary={parameterDictionary}
                                parameterAssignment={parameterAssignment}
                                commitContext={commitContext}
                                safeMode={safeMode}
                                layout={layout}
                                column={column}
                            />
                    )
                }
            </TableRow>;
        }
    );
