import React, { useCallback, useEffect } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../Type/Api/useTypes';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import { RecurrencePattern } from '../../../../RecurrencePattern/Model/RecurrencePattern';
import { setValueByFieldInEntity } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import Input from '../../../Input/Input';
import { RecurrencePatternEditor } from '../../../../RecurrencePattern/Editor/RecurrencePatternEditor';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import commitEntity from '../../../../../../@Api/Entity/Commit/commitEntity';
import { EntityField } from '../../../../../../@Api/Model/Implementation/EntityField';

export interface CalendarItemRecurrencePatternEditorProps
{
    entity: Entity;
    autoCommit?: boolean;
    commitContext?: CommitContext;
    inset?: boolean;
    startDateField: EntityField;
    isRecurringField: EntityField;
    recurrencePatternField: EntityField;
}

const EntityRecurrencePatternEditor: React.FC<CalendarItemRecurrencePatternEditorProps> =
    ({
        entity,
        autoCommit,
        commitContext,
        inset = false,
        startDateField,
        isRecurringField,
        recurrencePatternField,
     }) =>
    {
        const types = useTypes();
        const startDate =
            useEntityValue<Date>(
                entity,
                startDateField,
                undefined,
                commitContext
            );
        const isRecurring =
            useEntityValue<boolean>(
                entity,
                isRecurringField,
                undefined,
                commitContext
            );
        const recurrencePattern =
            useEntityValue<RecurrencePattern | undefined>(
                entity,
                recurrencePatternField,
                undefined,
                commitContext
            );
        useEffect(
            () =>
            {
                if (isRecurring && startDate && !recurrencePattern)
                {
                    setValueByFieldInEntity(
                        entity,
                        recurrencePatternField,
                        {
                            type: 'Daily',
                            startDate: startDate.toISOString(),
                            end: {
                                type: 'No',
                            },
                            interval: 1,
                            modifiedOccurrences: [],
                            deletedOccurrences: [],
                        },
                        commitContext
                    );
                }
                else if (!isRecurring && recurrencePattern)
                {
                    setValueByFieldInEntity(
                        entity,
                        recurrencePatternField,
                        undefined,
                        commitContext
                    );
                }
            },
            [
                entity,
                commitContext,
                types,
                isRecurring,
                startDate,
                recurrencePattern,
            ]
        );
        const setRecurrencePattern =
            useCallback(
                (value: RecurrencePattern) =>
                {
                    setValueByFieldInEntity(
                        entity,
                        recurrencePatternField,
                        value,
                        commitContext
                    );

                    if (autoCommit)
                    {
                        return commitEntity(
                            entity,
                            {
                                context: commitContext,
                            }
                        );
                    }
                },
                [
                    entity,
                    types,
                    commitContext,
                    autoCommit,
                ]
            );
        useEffect(
            () =>
            {
                if (recurrencePattern && startDate)
                {
                    const newRecurrencePattern = {
                        ...recurrencePattern,
                        startDate: startDate.toISOString(),
                    };

                    if (JSON.stringify(recurrencePattern) !== JSON.stringify(newRecurrencePattern))
                    {
                        setRecurrencePattern(newRecurrencePattern);
                    }
                }
            },
            [
                recurrencePattern,
                startDate,
                setRecurrencePattern,
            ]
        );

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            {
                startDate &&
                <ViewGroupItem>
                    <CardInset
                        horizontal={inset}
                        vertical={inset}
                    >
                        <Input
                            field={isRecurringField}
                            labelPosition="left"
                            doAutocommit={autoCommit}
                            commitContext={commitContext}
                            entity={entity}
                        />
                    </CardInset>
                </ViewGroupItem>
            }
            {
                entity.getObjectValueByField(
                    isRecurringField,
                    commitContext
                ) && recurrencePattern &&
                <ViewGroupItem>
                    <RecurrencePatternEditor
                        value={recurrencePattern}
                        onChange={setRecurrencePattern}
                        inset={inset}
                    />
                </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(EntityRecurrencePatternEditor);
