export default class Feature
{
    // ------------------------- Properties -------------------------

    id: string;

    // ------------------------ Constructor -------------------------

    constructor(id: string)
    {
        this.id = id;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor()
    {
        return {
            id: this.id
        };
    }

    static fromDescriptor(descriptor: any)
    {
        return new Feature(descriptor.id);
    }

    // ----------------------- Private logic ------------------------
}
