import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { BadgeStore } from './BadgeStore';
import { Badge as MuiBadge, Icon, Theme, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const styles =
    (theme: Theme) =>
        ({
            badge:
            {
                top: 1,
                right: -15,
                // The border color match the background color
                border: `2px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]}`
            },
            iconBadgeRoot:
            {
                display: 'flex',
                alignItems: 'center'
            },
            iconBadgeIcon:
            {
                color: grey[400]
            },
            iconBadgeLabel:
            {
                marginLeft: 10
            }
        });

@observer
class Badge extends BaseComponent<BadgeStore>
{
    renderLoader(store: BadgeStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: BadgeStore)
    {
        if (store.isVisible)
        {
            if (store.icon)
            {
                return <div
                    className={this.props.classes.iconBadgeRoot}
                >
                    <Icon
                        className={this.props.classes.iconBadgeIcon}
                    >
                        {store.icon}
                    </Icon>
                    <Typography
                        color="textSecondary"
                        className={this.props.classes.iconBadgeLabel}
                    >
                        {store.finalLabel}
                    </Typography>
                </div>;
            }
            else
            {
                return <MuiBadge
                    badgeContent={store.finalLabel}
                    color="primary"
                >
                    {this.props.children}
                </MuiBadge>;
            }
        }
        else
        {
            return null;
        }
    }
}

export default withStyles(styles)(Badge as any);
