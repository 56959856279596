import { CommitContext } from '../../CommitContext';
import { Entity } from '../../../../../Model/Implementation/Entity';

export function deleteEntity(
    entity: Entity,
    commitContext?: CommitContext
)
{
    if (commitContext)
    {
        commitContext.deleteEntity(entity);
    }
    else
    {
        entity.deleteEntity();
    }
}
