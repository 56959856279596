import * as React from 'react';
import { DataObjectBespokeEditor } from '../../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { FormEvent } from '../../../../../../Generic/Form/FormEvent';
import { DataObjectEditor } from '../../../../Editor/Value/Editor/DataObjectEditor';
import { DataObject } from '../../../../Model/DataObject';
import { DataObjectSpecification } from '../../../../Model/DataObjectSpecification';
import { DataDescriptor } from '../../../../Model/DataDescriptor';
import { FormHandlerContext } from '../../../../../../Generic/Form/FormHandlerContext';
import { FormEventListener } from '../../../../../../Generic/Form/FormEventListener';
import { PercentageType } from '../PercentageType';
import { DataObjectEditorStore } from '../../../../Editor/Value/Editor/DataObjectEditorStore';
import { observer } from 'mobx-react';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';

@observer
export class PercentageRangeEditor extends DataObjectBespokeEditor
{
    renderComponent(store: DataObjectEditorStore)
    {
        let fromHandlerContext = new FormHandlerContext<DataObject>();
        fromHandlerContext.listen(new FormEventListener<DataObject>(null, event =>
        {
            let value = store.dataObject;
            value.setValue(Object.assign({}, value.getValue(), { from: event.data.getValue() }));

            let newEvent = new FormEvent<DataObject>(event.id, value, event.event);
            store.handlerContext.perform(newEvent);
        }));

        let toHandlerContext = new FormHandlerContext<DataObject>();
        toHandlerContext.listen(new FormEventListener<DataObject>(null, event =>
        {
            let value = store.dataObject;
            value.setValue(Object.assign({}, value.getValue(), { to: event.data.getValue() }));

            let newEvent = new FormEvent<DataObject>(event.id, value, event.event);
            store.handlerContext.perform(newEvent);
        }));

        let dataObjectFrom = new DataObject(new DataObjectSpecification(new PercentageType(), {}), new DataDescriptor());

        if (store.dataObject.value)
        {
            dataObjectFrom.setValue(store.dataObject.value.from);
        }

        let dataObjectFromStore = new DataObjectEditorStore({
            dataObject: dataObjectFrom,
            label: store.label,
            isDisabled: store.isDisabled,
            handlerContext: fromHandlerContext
        });

        let dataObjectTo = new DataObject(new DataObjectSpecification(new PercentageType(), {}), new DataDescriptor());

        if (store.dataObject.value)
        {
            dataObjectTo.setValue(store.dataObject.value.to);
        }

        let dataObjectToStore = new DataObjectEditorStore({
            dataObject: dataObjectTo,
            label: ' ',
            isDisabled: store.isDisabled,
            handlerContext: toHandlerContext
        });

        return <ViewGroup
            orientation="horizontal"
            spacing={5}
            alignment="center"
        >
            <ViewGroupItem>
                <DataObjectEditor
                    store={dataObjectFromStore}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                -
            </ViewGroupItem>
            <ViewGroupItem>
                <DataObjectEditor
                    store={dataObjectToStore}
                />
            </ViewGroupItem>
        </ViewGroup>;
    }
}
