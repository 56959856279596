import { computed } from 'mobx';
import { SideEffectStore } from '../../../../../SideEffect/SideEffectStore';
import { EntityRelationshipMutation } from '../../../../../../../../@Api/Model/Implementation/EntityRelationshipMutation';
import { TextStore } from '../../../../../../../Generic/Text/TextStore';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';

export class CustomerUpgradeSideEffectStore extends SideEffectStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    @computed
    get relationship(): Entity
    {
        return this.entity.getRelatedEntityByDefinition(
            true,
            this.entityTypeStore.bespoke.types.Relationship.RelationshipDefinition.Activities);
    }

    @computed
    get commercialType(): EntityType
    {
        if (this.relationship.entityType.isA(this.entityTypeStore.bespoke.types.Relationship.Person.Type))
        {
            return this.entityTypeStore.bespoke.types.Relationship.Person.Commercial.Type;
        }
        else if (this.relationship.entityType.isA(this.entityTypeStore.bespoke.types.Relationship.Organization.Type))
        {
            return this.entityTypeStore.bespoke.types.Relationship.Organization.Commercial.Type;
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get customerType(): EntityType
    {
        if (this.relationship.entityType.isA(this.entityTypeStore.bespoke.types.Relationship.Person.Type))
        {
            return this.entityTypeStore.bespoke.types.Relationship.Person.Commercial.Customer.Type;
        }
        else if (this.relationship.entityType.isA(this.entityTypeStore.bespoke.types.Relationship.Organization.Type))
        {
            return this.entityTypeStore.bespoke.types.Relationship.Organization.Commercial.Customer.Type;
        }
        else
        {
            return undefined;
        }
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isActivated(): boolean
    {
        return this.events.find(
            event =>
                event instanceof EntityRelationshipMutation
                    && event.entityRelationshipDefinition === this.entityTypeStore.bespoke.types.Activity.SalesOpportunity.RelationshipDefinition.Phase
                    && !event.isParentRelationship
                    && event.toRelatedEntity
                    && event.toRelatedEntity.getObjectValueByField(this.entityTypeStore.bespoke.types.Datastore.Phase.Field.IsTerminal)
                    && event.toRelatedEntity.getObjectValueByField(this.entityTypeStore.bespoke.types.Datastore.Field.Code) === 'Won') !== undefined
            && this.relationship
            && this.relationship.entityType.isA(this.commercialType)
            && !this.relationship.entityType.isA(this.customerType);
    }

    description(): TextStore
    {
        return new TextStore({
            label:
                () =>
                    `- de status van ${this.relationship.name} wordt aangepast van ${this.relationship.entityType.getName()} naar ${this.customerType.getName()}`
        });
    }

    // ----------------------- Private logic ------------------------
}
