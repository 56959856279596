import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';

export default function useAllowCharactersOrIcon(entity: Entity)
{
    return useComputed(
        () =>
            entity.entityType.bespoke.allowIconOrCharactersInAvatar(),
        [
            entity
        ]);
}