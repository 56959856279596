import React, { useCallback, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityPath } from '../../../../../Entity/Path/@Model/EntityPath';
import ResourceSelection from '../../../../../Entity/ResourcePlanner/Model/ResourceSelection';
import InputGroup from '../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { EntityContext } from '../../../../../Entity/@Model/EntityContext';
import FieldPathSelector from '../../../../../Entity/Path/FieldPathEditor/FieldPathSelector';
import MenuButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import uuid from '../../../../../../../@Util/Id/uuid';
import { EntityFieldPath } from '../../../../../Entity/Path/@Model/EntityFieldPath';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import FilterEditor from '../FilterEditor/FilterEditor';
import Predicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import ResourcePlanner from '../../../../../Entity/ResourcePlanner/Model/ResourcePlanner';

export interface ResourceSelectionConstructorProps
{
    resourcePlanner: ResourcePlanner;
    resourcePath: EntityPath;
    onConstruct: (resourceSelection: ResourceSelection) => void;
}

const ResourceSelectionConstructor: React.FC<ResourceSelectionConstructorProps> =
    props =>
    {
        const context =
            useComputed(
                () =>
                    EntityContext.fromEntityType(props.resourcePath.entityType),
                [
                    props.resourcePath
                ]);

        const [ startDateFieldPath, setStartDateFieldPath ] = useState<EntityFieldPath>();
        const [ endDateFieldPath, setEndDateFieldPath ] = useState<EntityFieldPath>();
        const [ filter, setFilter ] = useState<Predicate>();
        const resourceSelectionParameter =
            useMemo(
                () =>
                    ResourceSelection.getResourceSelectionParameter(
                        uuid(),
                        props.resourcePath.entityType),
                [
                    props.resourcePath
                ]);

        const parameterDictionary =
            useComputed(
                () =>
                    props.resourcePlanner
                        .parameterDictionary
                        .getNewDictionaryWithParameter(resourceSelectionParameter),
                [
                    props.resourcePlanner,
                    resourceSelectionParameter
                ]);

        const save =
            useCallback(
                () =>
                    props.onConstruct(
                        new ResourceSelection(
                            resourceSelectionParameter,
                            props.resourcePath.reverse(),
                            startDateFieldPath,
                            endDateFieldPath,
                            filter
                        )
                    ),
                [
                    resourceSelectionParameter,
                    props.resourcePath,
                    startDateFieldPath,
                    endDateFieldPath,
                    filter,
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <ViewGroupItem>
                <InputGroup>
                    <Input
                        labelPosition="left"
                        label={
                            <LocalizedText
                                code="Generic.Type"
                                value="Type"
                            />
                        }
                    >
                        {props.resourcePath.entityType.getName()}
                    </Input>
                    <Input
                        labelPosition="left"
                        label={
                            <LocalizedText
                                code="Configuration.ResourcePlanner.ResourceSelection.StartDateField"
                                value="Veld van startdatum"
                            />
                        }
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                            alignment="center"
                        >
                            <ViewGroupItem>
                                {startDateFieldPath?.getName()}
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <MenuButton
                                    icon="edit"
                                    tooltip={
                                        <LocalizedText
                                            code="Generic.Edit"
                                            value="Wijzigen"
                                        />
                                    }
                                    small
                                >
                                    <FieldPathSelector
                                        key={startDateFieldPath?.id}
                                        context={context}
                                        onSelect={setStartDateFieldPath as any}
                                    />
                                </MenuButton>
                            </ViewGroupItem>
                        </ViewGroup>
                    </Input>
                    <Input
                        labelPosition="left"
                        label={
                            <LocalizedText
                                code="Configuration.ResourcePlanner.ResourceSelection.EndDateField"
                                value="Veld van einddatum"
                            />
                        }
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                            alignment="center"
                        >
                            <ViewGroupItem>
                                {endDateFieldPath?.getName()}
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <MenuButton
                                    icon="edit"
                                    tooltip={
                                        <LocalizedText
                                            code="Generic.Edit"
                                            value="Wijzigen"
                                        />
                                    }
                                    small
                                >
                                    <FieldPathSelector
                                        key={endDateFieldPath?.id}
                                        context={context}
                                        onSelect={setEndDateFieldPath as any}
                                    />
                                </MenuButton>
                            </ViewGroupItem>
                        </ViewGroup>
                    </Input>
                    <Input
                        labelPosition="left"
                        label={
                            <LocalizedText
                                code="Generic.Filter"
                                value="Filter"
                            />
                        }
                    >
                        <FilterEditor
                            parameterDictionary={parameterDictionary}
                            filterParameter={resourceSelectionParameter}
                            value={filter}
                            onChange={setFilter}
                        />
                    </Input>
                </InputGroup>
            </ViewGroupItem>
            <ViewGroupItem>
                <RightAlignedButtonGroup>
                    <SaveButton
                        onClick={save}
                        disabled={!startDateFieldPath || !endDateFieldPath}
                    />
                </RightAlignedButtonGroup>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(ResourceSelectionConstructor);
