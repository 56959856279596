import React, { useCallback, useMemo } from 'react';
import { Dialog as MuiDialog } from '@material-ui/core';

import styles from './Dialog.module.scss';
import { classNames } from '../../../Util/Class/classNames';
import CloseButton from '../Button/Variant/CloseButton/CloseButton';
import ErrorBoundary from '../../../../@Component/Error/ErrorBoundary';
import { TransitionProps } from '@material-ui/core/transitions';

export type DialogWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

interface DialogClasses
{
    paper?: string;
}

export interface DialogProps
{
    onClose?: () => void;
    open: boolean;
    width?: DialogWidth;
    className?: string;
    hideCloseButton?: boolean;
    classes?: DialogClasses;
    fullscreen?: boolean;
    TransitionProps?: TransitionProps;
    fullWidth?: boolean;
    contentFullHeight?: boolean;
}

const Dialog: React.FC<DialogProps> =
    props =>
    {
        const closeCallback =
            useCallback(
                () =>
                {
                    if (props.onClose)
                    {
                        props.onClose();
                    }
                },
                [
                    props
                ]);

        const isClosable =
            useMemo(
                () =>
                {
                    return props.onClose !== undefined;
                },
                [
                    props.onClose
                ]);

        return <MuiDialog
            fullScreen={props.fullscreen}
            open={props.open}
            onClose={closeCallback}
            fullWidth={props.fullWidth ?? true}
            maxWidth={props.width}
            disableBackdropClick={!isClosable}
            disableEscapeKeyDown={!isClosable}
            scroll="body"
            className={
                classNames(
                    styles.root,
                    props.className
                )
            }
            classes={props.classes}
            TransitionProps={props.TransitionProps}
        >
            <div
                className={
                    classNames(
                        styles.relativePosition,
                        props.contentFullHeight && styles.contentFullHeight
                    )
                }
            >
                {
                    isClosable && !props.hideCloseButton &&
                        <div
                            className={styles.closeButtonWrapper}
                        >
                            <CloseButton
                                onClick={closeCallback}
                            />
                        </div>
                }
                {
                    <ErrorBoundary>
                        {props.children}
                    </ErrorBoundary>
                }
            </div>
        </MuiDialog>;
    };

Dialog.defaultProps =
{
    open: false,
    width: 'md'
};

export default Dialog;
