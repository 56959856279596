import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Item.module.scss';
import { default as GenericAvatar } from '../../../../../../../../../../@Future/Component/Generic/Avatar/Avatar';
import { borderColor, lightTextColor } from '../../../../../../../../../../@Resource/Theme/Theme';

export interface IconProps
{
    icon: string;
}

const Icon: React.FC<IconProps> =
    props =>
    {
        return <div
            className={styles.icon}
        >
            <GenericAvatar
                icon={props.icon}
                // color="rgb(219, 219, 219)"
                color="white"
                size={38}
                iconSize={19}
                iconColor={lightTextColor}
                border
                borderColor={borderColor}
                borderWidth={1}
            />
        </div>;
    };

export default observer(Icon);
