import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import CampaignPercentageChart from '../../Campaign/Charts/CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import useSpotlerCampaignSendCount from '../Hooks/useSpotlerCampaignSendCount';
import useSpotlerCampaignDeliveredCount from '../Hooks/useSpotlerCampaignDeliveredCount';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

export interface SpotlerCampaignChartDeliveredProps
{
    size: number;
    spotlerCampaign: Entity;
    selectedStep?: Entity
}

export const SpotlerCampaignChartDelivered: React.FC<SpotlerCampaignChartDeliveredProps> =
    observer(
        (
            {
                spotlerCampaign,
                selectedStep,
                size,
            }
        ) =>
        {
            const totalResultCount =
                useSpotlerCampaignSendCount(
                    spotlerCampaign,
                    selectedStep
                );

            const sendCount =
                useSpotlerCampaignDeliveredCount(
                    spotlerCampaign,
                    selectedStep
                );

            const allSet =
                useAllSet([
                    totalResultCount,
                    sendCount
                ]);

            const formattedSelection =
                useMemo(
                    () =>
                    {
                        return `${sendCount} / ${totalResultCount}`;
                    },
                    [
                        sendCount,
                        totalResultCount
                    ]
                );

            return <div>
                {
                    allSet &&
                    <CampaignPercentageChart
                        value={sendCount / totalResultCount * 100}
                        size={size}
                        label={localizeText('Campaign.Chart.Delivered', 'Bezorgd')}
                        selection={formattedSelection}
                    />
                }
            </div>;
        }
    );