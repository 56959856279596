import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../Type/Api/useTypes';
import Click from '../Click/Click';
import Inset from '../Inset/Inset';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import Header from '../../../Viewer/Content/Activity/RelatedActivityList/List/List/Item/Header/Header';
import RichText from '../../../../../Generic/RichText/RichText';

export interface LeexiCompletionProps
{
    entity: Entity;
}

export const LeexiCompletion: React.FC<LeexiCompletionProps> = observer(
    ({
        entity
    }) =>
    {
        const types = useTypes();

        const promptTitle =
            useMemo(
                () =>
                    entity.getObjectValueByField(types.LeexiCallCompletion.Field.PromptTitle),
                [
                    entity,
                    types
                ]
            );

        return <Click
            open
        >
            <Inset>
                <Header
                    name={entity.entityType.getName()}
                    entity={entity}
                    includeAuthor
                />
                <div>
                    <RichText
                        value={promptTitle}
                    />
                </div>
            </Inset>
        </Click>;
    }
);