import React, { useCallback, useContext, useMemo } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../Type/Api/useTypes';
import DocumentEditor, {
    DocumentDefinition,
    EntityImageBlockId,
    GutenbergBlockExpressionId,
    GutenbergBlockHtmlId,
    GutenbergBlockImageId,
    GutenbergBlockPageBreakId,
    GutenbergBlockTemplateId,
    LayoutBlockId,
    MileageRegistrationsBlockId,
    OrganizationLogoBlockId,
    ProductLinesBlockId,
    TimeRegistrationsBlockId,
} from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/DocumentEditor';
import ExpressionEditorContext from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Expression/ExpressionEditorContext';
import { EntityField } from '../../../../../../@Api/Model/Implementation/EntityField';
import getExpressionContextByTemplateType from './Api/getExpressionContextByTemplateType';
import TemplateContext from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Type/Template/TemplateContext';
import useFetchedRelatedEntity from '../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity';
import EntityTypeContext from '../../../Type/EntityTypeContext';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import InteractiveLayoutContext from '../../../../Layout/InteractiveLayoutContext';

export interface TemplateProps
{
    entity: Entity;
    field: EntityField;
    commitContext?: CommitContext;
}

const DocumentTemplateField: React.FC<TemplateProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);

        const definition =
            useMemo(
                () =>
                    props.entity.getObjectValueByField(props.field),
                [
                    props.entity,
                    types
                ]);

        const onChange =
            useCallback(
                (definition: DocumentDefinition) =>
                {
                    setValueByFieldInEntity(
                        props.entity,
                        props.field,
                        definition,
                        props.commitContext
                    );

                    commitEntityWithContext(
                        props.entity,
                        props.commitContext,
                        {
                            isForced: true
                        }
                    );
                },
                [
                    props.entity,
                    types,
                    props.commitContext,
                ]);

        const [ blockTemplateType, isLoadingBlockTemplateType ] =
            useFetchedRelatedEntity(
                props.entity.entityType.isA(types.Template.Block.Type)
                    ?
                        props.entity
                    :
                        undefined,
                types.Template.Block.RelationshipDefinition.TemplateType,
                false,
                props.commitContext
            );

        const templateType =
            useComputed(
                () =>
                {
                    if (props.entity.entityType.isA(types.Template.Block.Type)
                        && blockTemplateType)
                    {
                        return entityTypeStore.getTypeByEntityId(blockTemplateType.id) || props.entity.entityType;
                    }
                    else
                    {
                        return props.entity.entityType;
                    }
                },
                [
                    blockTemplateType,
                    props.entity,
                    entityTypeStore
                ]);

        const isEmail =
            useMemo(
                () =>
                    templateType && templateType.isA(types.Template.Email.Type),
                [
                    templateType,
                    types
                ]);

        const [ expressionContext, isLoadingExpressionContext ] =
            useAsyncResult(
                () =>
                    getExpressionContextByTemplateType(
                        templateType,
                        props.field,
                        props.commitContext
                    ),
                [
                    templateType,
                    props.field,
                    props.commitContext,
                ]);

        const blockTypes =
            useMemo(
                () => [
                        GutenbergBlockExpressionId,
                        GutenbergBlockImageId,
                        GutenbergBlockTemplateId,
                        ProductLinesBlockId,
                        TimeRegistrationsBlockId,
                        MileageRegistrationsBlockId,
                        OrganizationLogoBlockId,
                        EntityImageBlockId,
                        GutenbergBlockHtmlId,
                        LayoutBlockId,
                        ...isEmail
                            ? []
                            : [ GutenbergBlockPageBreakId ]
                ],
                [
                    isEmail
                ]);


        if (isLoadingBlockTemplateType || isLoadingExpressionContext)
        {
            return null;
        }
        else
        {
            return <TemplateContext.Provider
                value={props.entity}
            >
                <ExpressionEditorContext.Provider
                    value={expressionContext}
                >
                    <InteractiveLayoutContext.Provider
                        value={false}
                    >
                        <DocumentEditor
                            key={props.entity.uuid}
                            type="gutenberg"
                            onChange={onChange}
                            definition={definition}
                            blockTypes={blockTypes}
                            documentLayoutControls={isEmail}
                        />
                    </InteractiveLayoutContext.Provider>
                </ExpressionEditorContext.Provider>
            </TemplateContext.Provider>;
        }
    };

export default observer(DocumentTemplateField);
