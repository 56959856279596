import * as React from 'react';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { ComparisonPredicateEditorStore } from './ComparisonPredicateEditorStore';
import { observer } from 'mobx-react';
import { Button, ClickAwayListener, Grow, Icon, IconButton, MenuItem, MenuList, Paper } from '@material-ui/core';
import { ComputationEditor } from '../../../Computation/ComputationEditor';
import { Comparator } from '../../../DataObject/Model/Comparator';
import { grey } from '@material-ui/core/colors';
import { getComparatorSymbol } from '../../../DataObject/Util/SymbolUtils';
import { PopperReference } from '../../../../Generic/Popper/PopperReference';
import { PopperContainer } from '../../../../Generic/Popper/PopperContainer';
import { PopperManager } from '../../../../Generic/Popper/PopperManager';

@observer
export class ComparisonPredicateEditor extends BaseComponent<ComparisonPredicateEditorStore>
{
    renderComponent(store: ComparisonPredicateEditorStore)
    {
        return <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
                {this.renderComputation(store, true)}
            </div>
            {
                store.hasLhs &&
                    <div>
                        {this.renderComparator(store)}
                    </div>
            }
            {
                store.hasLhs &&
                    <div>
                        {this.renderComputation(store, false)}
                    </div>
            }
        </div>;
    }

    renderComputation(store: ComparisonPredicateEditorStore,
                      isLhs: boolean)
    {
        let computation = isLhs ? store.lhs : store.rhs;
        let openAddMenu = isLhs ? store.openLhsAddMenu : store.openRhsAddMenu;
        let closeAddMenu = isLhs ? store.closeLhsAddMenu : store.closeRhsAddMenu;
        let isAddMenuOpen = isLhs ? store.isLhsAddMenuOpen : store.isRhsAddMenuOpen;
        let addComputation = isLhs ? store.addLhsComputation : store.addRhsComputation;
        let deleteComputation = isLhs ? store.deleteLhsComputation : store.deleteRhsComputation;

        if (computation)
        {
            if (computation.type.isTerminal())
            {
                return <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <ComputationEditor store={computation} />
                    </div>
                    <div>
                        <IconButton onClick={deleteComputation as any}>
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                </div>;
            }
            else
            {
                return <div style={{ margin: 15, backgroundColor: 'whitesmoke', borderRadius: 10 }}>
                    <div style={{ display: 'flex', minHeight: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 50 }}>
                            <div
                                style={{
                                    backgroundColor: grey[500],
                                    width: 1,
                                    height: '100%'
                                }}
                            />
                        </div>
                        <div style={{ flex: '1 1 auto' }}>
                            <div
                                style={{
                                    position: 'relative',
                                    margin: 10
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        backgroundColor: grey[500],
                                        width: 35,
                                        height: 1,
                                        left: -35,
                                        top: 25
                                    }}
                                />
                                <Paper
                                    style={{
                                        display: 'inline-flex',
                                        flex: '1 1 auto',
                                        background: 'none'
                                    }}
                                    elevation={0}
                                >
                                    <div style={{ flex: '1 1 auto', display: 'inline-flex', alignItems: 'center' }}>
                                       <ComputationEditor store={computation} />
                                    </div>
                                    <div>
                                        <IconButton onClick={deleteComputation as any}>
                                            <Icon>close</Icon>
                                        </IconButton>
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>;
            }
        }
        else
        {
            return <PopperManager>
                <PopperReference>
                    <Button
                        color={isAddMenuOpen ? 'primary' : undefined}
                        onClick={openAddMenu}
                    >
                        <Icon>edit</Icon>
                    </Button>
                </PopperReference>
                <PopperContainer
                    placement="bottom-start"
                    eventsEnabled={isAddMenuOpen}
                    style={{zIndex: 10}}
                >
                    {
                        isAddMenuOpen &&
                            <ClickAwayListener onClickAway={closeAddMenu}>
                                <div>
                                    <Grow in={true}>
                                        <Paper>
                                            <MenuList role="menu">
                                                {
                                                    store.computationTypeStore.types
                                                        .filter(type => type.allow(store.context) && (!isLhs || type.isResource()))
                                                        .map(type => (
                                                            <MenuItem
                                                                key={type.id()}
                                                                onClick={(e: any) =>
                                                                {
                                                                    addComputation(type);
                                                                    closeAddMenu();

                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                {type.name()}
                                                            </MenuItem>))
                                                }
                                            </MenuList>
                                        </Paper>
                                    </Grow>
                                </div>
                            </ClickAwayListener>
                    }
                </PopperContainer>
            </PopperManager>;
        }
    }

    renderComparator(store: ComparisonPredicateEditorStore)
    {
        return <PopperManager>
            <PopperReference>
                <Button
                    onClick={store.openComparatorMenu}
                >
                    {getComparatorSymbol(store.comparator)}
                </Button>
            </PopperReference>
            <PopperContainer
                placement="bottom-start"
                eventsEnabled={store.isComparatorMenuOpen}
                style={{zIndex: 10}}
            >
                {
                    store.isComparatorMenuOpen &&
                        <ClickAwayListener onClickAway={store.closeComparatorMenu}>
                            <div>
                                <Grow in={true}>
                                    <Paper>
                                        <MenuList role="menu">
                                            {
                                                Object.keys(Comparator)
                                                    .map(k => Comparator[k as any])
                                                    .filter(k => isNaN(k as any))
                                                    .map(comparator => (
                                                        <MenuItem
                                                            key={comparator}
                                                            onClick={(e: any) =>
                                                            {
                                                                store.setComparator((Comparator as any)[comparator]);
                                                                store.closeComparatorMenu();

                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            {getComparatorSymbol((Comparator as any)[comparator])}
                                                        </MenuItem>))
                                            }
                                        </MenuList>
                                    </Paper>
                                </Grow>
                            </div>
                        </ClickAwayListener>
                }
            </PopperContainer>
        </PopperManager>;
    }
}
