import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';

export default function useIsInClosedState(entity: Entity)
{
    return useComputed(
        () =>
            entity.entityType.bespoke.isInClosedState(entity),
        [
            entity
        ]);
}
