import * as Parchment from 'parchment';

const ReactQuill = require('react-quill');
const {Quill} = ReactQuill;

const EmbedBlock = Quill.import('blots/embed') as typeof Parchment.default.Embed;

export class ComputationBlot extends EmbedBlock
{
    static blotName = 'Computation';
    static tagName = 'computation';

    static create(value: any)
    {
        let node = super.create(value) as any; // HTMLElement;
        node.setAttribute('data-id', value);
        node.setAttribute('contenteditable', 'false');

        return node;
    }

    static value(domNode: any)
    {
        return domNode.dataset.id;
    }

    static formats(domNode: any)
    {
        return true;
    }
}
