import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import PortalRouterLayout from '../../../../../@Api/Layout/Type/PortalRouterLayout';
import PortalPage from '../../../../App/Root/Environment/Portal/Page/PortalPage';

export interface PortalRouterLayoutViewerProps extends LayoutViewerProps<PortalRouterLayout>
{

}

const PortalRouterLayoutViewer: React.FC<PortalRouterLayoutViewerProps> =
    props =>
    {
        return <PortalPage />;
    };

export default observer(PortalRouterLayoutViewer);
