import { DataObjectBespokeEditorStore } from '../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { action, computed, observable } from 'mobx';
import { ImageFinderStore } from '../../../../Generic/ImageFinder/ImageFinderStore';
import { FileUploaderStore } from '../../../../Generic/FileUploader/FileUploaderStore';
import { FileValue, FileValueType } from './FileValue';
import { ExternalPicture } from '../../../../../@Api/Model/Implementation/ExternalPicture';
import { FileUtils } from './FileUtils';
import { DataObject } from '../../Model/DataObject';
import { FormEvent } from '../../../../Generic/Form/FormEvent';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';
import localizeText from '../../../../../@Api/Localization/localizeText';

let assetRequire = require.context('../../../../../@Resource/Image/FileIcon/', true, /\.png$/);

export class FileEditorStore extends DataObjectBespokeEditorStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable tabIndex: number = 0;
    @observable showEdit: boolean = false;
    @observable isInEditMode: boolean = false;

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get showExpanded(): boolean
    {
        return this.showEdit || !this.fileValue || !this.name;
    }

    @computed
    get fileValue(): FileValue
    {
        return this.baseStore.dataObject.value;
    }

    @computed
    get avatar(): string
    {
        if (this.fileValue)
        {
            if (this.fileValue.file) // Uploaded file
            {
                if ((this.fileValue.file as any).preview)
                {
                    return FileUtils.getFileAvatar(
                        (this.fileValue.file as any).preview,
                        this.fileValue.file.type);
                }
                else
                {
                    return assetRequire('./default.png').default;
                }
            }
            else if (this.fileValue.url) // File from storage
            {
                return FileUtils.getFileAvatar(
                    this.baseStore.dataObject.context.getFileUrl(this.fileValue.url),
                    this.fileValue.mime);
            }
            else if (this.fileValue.originUrl)
            {
                return this.fileValue.originUrl;
            }
        }

        return undefined;
    }

    @computed
    get name(): string
    {
        if (this.fileValue)
        {
            if (this.fileValue.file)
            {
                return this.fileValue.file.name;
            }
            else if (this.fileValue.name)
            {
                return this.fileValue.name;
            }
        }

        return undefined;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get imageFinderStore(): ImageFinderStore
    {
        return new ImageFinderStore(
            '',
            this.onImageFinderSelected);
    }

    @computed
    get fileUploaderStore(): FileUploaderStore
    {
        return new FileUploaderStore(
            this.onUploaderSelected);
    }

    @computed
    get clearButtonStore(): ButtonStore
    {
        return new ButtonStore({
            icon: 'close',
            tooltip:
                () => localizeText('Generic.Delete', 'Verwijderen'),
            onClick:
                () => this.clear()
        });
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    setTabIndex(event: any, index: number)
    {
        this.tabIndex = index;
    }

    @action.bound
    toggleEdit()
    {
        this.showEdit = !this.showEdit;
    }

    @action.bound
    onImageFinderSelected(image: FileValue | ExternalPicture)
    {
        // if (image instanceof FileValue)
        // {
        //     this.setValue(image);
        // }
        // else if (image instanceof EntityPictureReference)
        // {
        //     this.setValue(
        //         new FileValue(
        //             FileValueType.Url,
        //             image.url.split('/').pop().split('#')[0].split('?')[0],
        //             image.description,
        //             undefined,
        //             image.url,
        //             undefined,
        //             undefined,
        //             undefined));
        // }

        this.setValue(
            new FileValue(
                FileValueType.Url,
                image instanceof ExternalPicture ? image.url.split('/').pop().split('#')[0].split('?')[0] : undefined,
                image instanceof ExternalPicture ? image.description : undefined,
                undefined,
                image instanceof ExternalPicture ? image.url : undefined,
                undefined,
                undefined,
                undefined));
    }

    @action.bound
    onUploaderSelected(files: File[])
    {
        this.setValue(
            FileValue.fromFile(files[0]));
        this.isInEditMode = false;
    }

    @action.bound
    setValue(fileValue: FileValue)
    {
        this.baseStore.dataObject.setValue(fileValue);

        this.baseStore.handlerContext.perform(
            new FormEvent<DataObject>(
                FormEvent.Change,
                this.baseStore.dataObject));
    }

    @action.bound
    clear()
    {
        this.setValue(undefined);
    }

    @action.bound
    toggleEditMode()
    {
        this.isInEditMode = !this.isInEditMode;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
