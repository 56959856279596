import { observable } from 'mobx';
import { DataObjectType } from '../../../DataObject/Model/DataObjectType';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../DataObject/DataObjectStore';

export default class SourceField
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable name: string;
    @observable type: DataObjectType;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                name: string,
                type: DataObjectType)
    {
        this.id = id;
        this.name = name;
        this.type = type;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new SourceField(
            descriptor.id,
            descriptor.name,
            loadModuleDirectly(DataObjectStore).getTypeById(descriptor.type));
    }

    toDescriptor()
    {
        return {
            id: this.id,
            name: this.name,
            type: this.type.id()
        };
    }

    // ----------------------- Private logic ------------------------
}
