import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { typographyEntityName, typographyEntityNameHeader } from '../../../../../../@Resource/Theme/Typography';
import HeaderContext from '../../Context/HeaderContext';
import { EntityField } from '../../../../../../@Api/Model/Implementation/EntityField';
import ValueEditor from '../../../Value/Editor/ValueEditor';
import { useNewCommitContext } from '../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';

export interface EditableNameProps
{
    entity: Entity;
    nameField: EntityField;
    fitContent?: boolean;
}

const EditableName: React.FC<EditableNameProps> =
    props =>
    {
        const commitContext = useNewCommitContext();
        const isHeader = useContext(HeaderContext);

        const inputStyle =
            useMemo(
                () => ({
                    ...(isHeader ? typographyEntityNameHeader : typographyEntityName),
                    // height: 25,
                    // color: isEditable && isClosed ? 'rgba(0,0,0,0.2)' : undefined
                }),
                [
                    isHeader
                ]);

        const representation =
            useMemo(
                () => ({
                    fitContent: props.fitContent,
                }),
                [
                    props.fitContent,
                ]);

        return <ValueEditor
            entity={props.entity}
            field={props.nameField}
            autoFocus={props.entity.isNew()}
            inputStyle={inputStyle}
            isInEditMode
            doAutoCommit={!props.entity.isNew()}
            representation={representation}
            commitContext={commitContext}
        />;
    };

export default observer(EditableName);
