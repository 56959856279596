import { observer } from 'mobx-react';
import { Page } from '../Page';
import * as React from 'react';
import { ComponentPageStore } from './ComponentPageStore';

@observer
export class ComponentPage extends Page<ComponentPageStore>
{
    renderBody(store: ComponentPageStore)
    {
        return React.createElement(
            store.internalComponentClass,
            store.internalComponentProps);
    }
}
