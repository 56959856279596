import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';

export class GoogleCalendarController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    listCalendars(connector: string): Promise<any[]>
    {
            return new Promise<any[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/connector/google/calendar/list', Method.Get, { connector: connector }))
                    .then(result =>
                    {
                        resolve(result as any[]);
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
}
