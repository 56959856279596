import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { Entity } from '../../Model/Implementation/Entity';
import { CommitContext } from '../Commit/Context/CommitContext';

export interface EntityValidationOptions
{
    isValidByEntityUuid: Map<string, boolean>;
    objectPath: any[];
    isDebug: boolean;
    isDeep: boolean;
    path: EntityPath;
    messages?: EntityValidationMessage[];
    commitContext?: CommitContext;
}

export interface EntityValidationMessage
{
    id: string;
    message: string;
}

export function getEntityValidationOptionsWithDefaults(entity: Entity,
                                                       options: Partial<EntityValidationOptions> = {},
                                                       defaultPath?: EntityPath): EntityValidationOptions
{
    if (options.isValidByEntityUuid === undefined)
    {
        options.isValidByEntityUuid = new Map();
    }

    if (options.objectPath === undefined)
    {
        options.objectPath = [ entity ];
    }

    if (options.isDebug === undefined)
    {
        options.isDebug = false;
    }

    if (options.isDeep === undefined)
    {
        options.isDeep = true;
    }

    if (options.path === undefined)
    {
        options.path = defaultPath || EntityPath.fromEntity(entity);
    }

    if (options.commitContext === undefined)
    {
        options.commitContext = entity.getCommitContext();
    }

    return options as EntityValidationOptions;
}
