import React, { useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import FormContext from './FormContext';
import FormLayout from '../../../../../@Api/Layout/Type/Form/FormLayout';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import { runInAction } from 'mobx';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';
import safelyApplyFunction from '../../../../../@Api/Automation/Api/safelyApplyFunction';
import FormLayoutContext from '../../../../../@Api/Layout/FormLayoutContext';
import FormLayoutContexts from './FormLayoutContexts';
import { useMemoized } from '../../../../../@Util/Memoized/useMemoized';

export interface FormLayoutViewerProps extends LayoutViewerProps<FormLayout>
{
    useParentParameterAssignment?: boolean;
}

const FormLayoutViewer: React.FC<FormLayoutViewerProps> =
    props =>
    {
        const { layout, parameterDictionary, parameterAssignment, commitContext, useParentParameterAssignment } = props;

        const formParameterDictionary =
            useComputed(
                () =>
                    ParameterDictionary.union(
                        parameterDictionary,
                        layout.parameters),
                [
                    parameterDictionary,
                    layout
                ]);
        const stableParentParameterAssignment =
            useMemoized(
                parameterAssignment,
                (a, b) =>
                    a.equals(b),
                []
            );
        const [formParameterAssignment] =
            useState(
                () =>
                    useParentParameterAssignment
                        ? parameterAssignment
                        : new ParameterAssignment(
                            new Map(),
                            parameterAssignment
                        )
            );
        useEffect(
            () =>
            {
                if (!useParentParameterAssignment)
                {
                    runInAction(
                        () =>
                            stableParentParameterAssignment.valueByParameter
                                .forEach(
                                    (value, parameter) =>
                                        formParameterAssignment.setValue(
                                            parameter,
                                            value
                                        )
                                )
                    );
                }
            },
            [
                useParentParameterAssignment,
                stableParentParameterAssignment,
                formParameterAssignment,
            ]
        );
        const [ defaultValueLoadingResult, isLoadingDefaultValues ] =
            useAsyncResult(
                async () =>
                {
                    const context =
                        new FunctionContext(
                            parameterDictionary,
                            stableParentParameterAssignment,
                            commitContext
                        );
                    const parametersToLoadDefaultsFor =
                        layout.parameters.parameters
                            .filter(
                                parameter =>
                                    parameter.defaultValue !== undefined
                                    && !formParameterAssignment.hasValue(parameter)
                            );
                    const defaultValues =
                        await Promise.all(
                            parametersToLoadDefaultsFor.map(
                                parameter =>
                                    safelyApplyFunction(
                                        parameter.defaultValue,
                                        context
                                    )
                            )
                        );

                    runInAction(
                        () =>
                        {
                            parametersToLoadDefaultsFor.forEach(
                                (parameter, idx) =>
                                {
                                    formParameterAssignment.setValue(
                                        parameter,
                                        defaultValues[idx]
                                    );
                                }
                            );
                        });

                    return true;
                },
                [
                    parameterDictionary,
                    stableParentParameterAssignment,
                    layout,
                    formParameterAssignment,
                    commitContext,
                ]
            );
        const parentFormLayoutContexts = useContext(FormLayoutContexts);
        const formLayoutContexts =
            useMemo(
                () => [
                    ...parentFormLayoutContexts,
                    new FormLayoutContext(
                        layout,
                        formParameterAssignment)
                ],
                [
                    parentFormLayoutContexts,
                    layout,
                    formParameterAssignment
                ]);

        if (isLoadingDefaultValues && defaultValueLoadingResult === undefined)
        {
            return null;
        }
        else
        {
            return <FormLayoutContexts.Provider
                value={formLayoutContexts}
            >
                <FormContext.Provider
                    value={layout}
                >
                    <LayoutViewer
                        {...props}
                        layout={layout.layout}
                        parameterDictionary={formParameterDictionary}
                        parameterAssignment={formParameterAssignment}
                    />
                </FormContext.Provider>
            </FormLayoutContexts.Provider>;
        }
    };

export default observer(FormLayoutViewer);
