import Predicate from './Predicate';
import { observable } from 'mobx';
import Dependency from '../../../Parameter/Dependency';
import Computation from '../Computation';
import { Comparator } from '../../../../../@Component/Domain/DataObject/Model/Comparator';
import getComputationFromDescriptor from '../../../Api/getComputationFromDescriptor';
import AutomationDependencyContext from '../../../AutomationDependencyContext';
import Validation from '../../../Validation/Validation';
import { OldComparisonPredicate } from '../../../../../@Component/Domain/Predicate/Type/Comparison/OldComparisonPredicate';
import safelyApplyFunction from '../../../Api/safelyApplyFunction';
import EmptyValue from '../../../Value/EmptyValue';
import FunctionContext from '../../FunctionContext';
import CollectionValue from '../../../Value/CollectionValue';
import PrimitiveValue from '../../../Value/PrimitiveValue';
import { DataObject } from '../../../../../@Component/Domain/DataObject/Model/DataObject';
import EntityValue from '../../../Value/EntityValue';
import Value from '../../../Value/Value';
import safelySynchronousApplyFunction from '../../../Api/safelySynchronousApplyFunction';
import { ComputationSubstitution } from '../Util/ComputationSubstitution';
import localizeText from '../../../../Localization/localizeText';
import Parameter from '../../../Parameter/Parameter';

export default class ComparisonPredicate extends Predicate
{
    // ------------------------- Properties -------------------------

    @observable.ref lhs: Computation<any, any>;
    @observable comparator: Comparator;
    @observable.ref rhs: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(lhs: Computation<any, any>,
                comparator: Comparator,
                rhs: Computation<any, any>)
    {
        super();

        this.lhs = lhs;
        this.comparator = comparator;
        this.rhs = rhs;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isAsync(): boolean
    {
        return this.lhs.isAsync()
            || (this.rhs?.isAsync() ?? false);
    }

    async evaluate(context: FunctionContext): Promise<boolean>
    {
        const lhsValue = await safelyApplyFunction(this.lhs, context);
        const rhsValue = await safelyApplyFunction(this.rhs, context);

        return this.compare(lhsValue, rhsValue);
    }

    synchronouslyEvaluate(context: FunctionContext): boolean
    {
        const lhsValue = safelySynchronousApplyFunction(this.lhs, context);
        const rhsValue = safelySynchronousApplyFunction(this.rhs, context);

        return this.compare(lhsValue, rhsValue);
    }

    private compare(lhsValue: Value<any, any>,
                    rhsValue: Value<any, any>)
    {
        if (this.comparator === Comparator.IsDefined)
        {
            return !(lhsValue instanceof EmptyValue);
        }
        else if (this.comparator === Comparator.IsNotDefined)
        {
            return lhsValue instanceof EmptyValue;
        }

        // Collection-Collection -> comparable
        // Collection-Primitive -> only supports Contains and NotContains, incomparable otherwise
        // Collection-Entity -> only supports Contains and NotContains, incomparable otherwise
        // Collection-Empty -> incomparable
        if (lhsValue instanceof CollectionValue)
        {
            if (this.comparator === Comparator.Contains)
            {
                return lhsValue.value.some(
                    element =>
                        element.equals(rhsValue)
                );
            }
            else if (this.comparator === Comparator.NotContains)
            {
                return !lhsValue.value.some(
                    element =>
                        element.equals(rhsValue)
                );
            }
            else if (rhsValue instanceof CollectionValue)
            {
                switch (this.comparator)
                {
                    case Comparator.Equals:
                        return lhsValue.equals(rhsValue);

                    case Comparator.NotEquals:
                        return !lhsValue.equals(rhsValue);
                }
            }
        }

        // Primitive-Primitive -> comparable
        // Primitive-Entity -> incomparable
        // Primitive-Empty -> comparable
        // Primitive-Collection -> only support IN, incomparable otherwise
        if (lhsValue instanceof PrimitiveValue)
        {
            if (rhsValue instanceof PrimitiveValue
                || rhsValue instanceof EmptyValue)
            {
                return DataObject.compare(
                    lhsValue.value,
                    rhsValue.value,
                    this.comparator);
            }
            else if (rhsValue instanceof CollectionValue
                && this.comparator === Comparator.In)
            {
                return rhsValue.value.some(
                    element =>
                        lhsValue.equals(element));
            }
        }

        // Entity-Primitive -> incomparable
        // Entity-Entity -> comparable
        // Entity-Empty -> comparable
        // Entity-Collection -> only support IN, incomparable otherwise
        if (lhsValue instanceof EntityValue)
        {
            if (rhsValue instanceof EntityValue
                || rhsValue instanceof EmptyValue)
            {
                switch (this.comparator)
                {
                    case Comparator.Equals:
                        return lhsValue.equals(rhsValue);

                    case Comparator.NotEquals:
                        return !lhsValue.equals(rhsValue);
                }
            }
            else if (rhsValue instanceof CollectionValue
                && this.comparator === Comparator.In)
            {
                return rhsValue.value.some(
                    element =>
                        lhsValue.equals(element));
            }
        }

        // Empty-Primitive -> comparable
        // Empty-Entity -> comparable
        // Empty-Empty -> comparable
        // Empty-Collection -> comparable
        if (lhsValue instanceof EmptyValue)
        {
            if (rhsValue instanceof PrimitiveValue)
            {
                return DataObject.compare(
                    undefined,
                    rhsValue.value,
                    this.comparator);
            }
            else if (rhsValue instanceof EntityValue)
            {
                switch (this.comparator)
                {
                    case Comparator.Equals:
                        return lhsValue.equals(rhsValue);

                    case Comparator.NotEquals:
                        return !lhsValue.equals(rhsValue);
                }
            }
            else if (rhsValue instanceof EmptyValue)
            {
                switch (this.comparator)
                {
                    case Comparator.Equals:
                        return lhsValue.equals(rhsValue);

                    default:
                        return false;
                }
            }
            else if (rhsValue instanceof CollectionValue)
            {
                switch (this.comparator)
                {
                    case Comparator.Equals:
                        return lhsValue.equals(rhsValue);

                    case Comparator.NotEquals:
                        return !lhsValue.equals(rhsValue);
                }
            }
        }

        throw new Error('incomparable');
    }

    getName(): string
    {
        return localizeText(
            'ComparisonPredicateName',
            'Vergelijking...'
        )
    }

    validate(): Validation[]
    {
        if (!this.lhs)
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        'ComparisonPredicate.InvalidComparison',
                        'Ongeldige vergelijking'
                    )
                )
            ];
        }

        return [
            ...this.lhs
                ?
                    this.lhs.validate()
                :
                    [],
            ...this.rhs
                ?
                    this.rhs.validate()
                :
                    []
        ];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Comparison';
        descriptor.lhs = this.lhs.toDescriptor();
        descriptor.comparator = Comparator[this.comparator];
        descriptor.rhs = this.rhs?.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [ this.lhs, this.rhs ]
            .filter(
                hs =>
                    hs)
            .map(
                hs =>
                    hs.getDependencies())
            .reduce((a, b) => a.concat(b), []);
    }

    toOldPredicate()
    {
        return new OldComparisonPredicate(
            this.lhs?.toOldComputation(),
            this.comparator,
            this.rhs?.toOldComputation());
    }

    getTrueStatements(
        context: FunctionContext,
        parameter?: Parameter<any>
    ): Predicate[]
    {
        return [ this ];
    }

    normalize(): Predicate
    {
        return this;
    }

    substitute(substitution: ComputationSubstitution): Predicate
    {
        return new ComparisonPredicate(
            this.lhs ? substitution(this.lhs) : this.lhs,
            this.comparator,
            this.rhs  ? substitution(this.rhs) : this.rhs);
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new ComparisonPredicate(
            await getComputationFromDescriptor(descriptor.lhs, dependencyContext),
            (Comparator as any)[descriptor.comparator],
            descriptor.rhs
                ?
                    await getComputationFromDescriptor(descriptor.rhs, dependencyContext)
                :
                    undefined);
    }

    // ----------------------- Private logic ------------------------
}
