import React, { useCallback } from 'react';
import { Tab as MuiTab, TabProps as MuiTabProps } from '@material-ui/core';
import useIsTabSelected from '../Api/useIsTabSelected';
import styles from './Tab.module.scss';
import { classNames } from '../../../../Util/Class/classNames';
import useValue from '../Api/useValue';
import TabSelectedContext from './Context/TabSelectedContext';

export interface TabProps
{
    value: any;
    onClick: (value: any) => void;
    className?: string;
    fullWidth?: boolean;
    selected?: boolean;
    disabled?: boolean;
    tabProps?: MuiTabProps;
}

const Tab: React.FC<TabProps> =
    props =>
    {
        const isSelected = useIsTabSelected(props.value) || props.selected;
        const { onClick, value } = props;
        const onClickCallback =
            useCallback(
                () =>
                    onClick(value),
                [
                    value,
                    onClick
                ]);
        const tabValue = useValue(value);

        return <MuiTab
            label={
                <TabSelectedContext.Provider
                    value={isSelected}
                >
                    {props.children}
                </TabSelectedContext.Provider>
            }
            onClick={onClickCallback}
            value={tabValue}
            selected={isSelected}
            textColor={isSelected ? 'primary' : undefined}
            className={classNames(styles.root, props.className)}
            disabled={props.disabled}
            {...props.fullWidth ? { fullWidth: true } : {}}
            {...props.tabProps}
        />;
    };

export default Tab;
