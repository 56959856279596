import { Entity } from '../../Model/Implementation/Entity';
import { EntityRelationshipDefinition } from '../../Model/Implementation/EntityRelationshipDefinition';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { consoleLog } from '../../../@Future/Util/Logging/consoleLog';
import { EntityValidationOptions, getEntityValidationOptionsWithDefaults } from './EntityValidationOptions';
import { EntityValidationResult } from './EntityValidationResult';
import RelationshipInput from '../../../@Component/Domain/Multiplayer/Model/Input/RelationshipInput';
import localizeText from '../../Localization/localizeText';
import { validateEntityWithOptions } from './validateEntity';
import { EntityRelationship } from '../../Model/Implementation/EntityRelationship';
import { isRelationshipDefinitionRequired } from '../../Metadata/Input/isRelationshipDefinitionRequired';

export default function validateRelationshipDefinitionInEntity(entity: Entity,
                                                               relationshipDefinition: EntityRelationshipDefinition,
                                                               isParent: boolean,
                                                               partialOptions?: Partial<EntityValidationOptions>): EntityValidationResult
{
    const options =
        getEntityValidationOptionsWithDefaults(
            entity,
            partialOptions,
            EntityPath
                .fromEntity(entity)
                .joinTo(
                    relationshipDefinition,
                    isParent));

    return validateRelationshipDefinitionInEntityWithOptions(
        entity,
        relationshipDefinition,
        isParent,
        options);
}

export function validateRelationshipDefinitionInEntityWithOptions(entity: Entity,
                                                                  relationshipDefinition: EntityRelationshipDefinition,
                                                                  isParent: boolean,
                                                                  options: EntityValidationOptions): EntityValidationResult
{
    const relationships = entity.getRelationshipsByDefinition(isParent, relationshipDefinition, options.commitContext);

    return validateExistingRelationships(entity, relationshipDefinition, isParent, relationships, options)
        || validateCardinality(entity, relationshipDefinition, isParent, relationships, options)
        || { isValid: true, messages: [] };
}

function validateExistingRelationships(entity: Entity,
                                       relationshipDefinition: EntityRelationshipDefinition,
                                       isParent: boolean,
                                       relationships: EntityRelationship[],
                                       options: EntityValidationOptions): EntityValidationResult | undefined
{
    for (const relationship of relationships)
    {
        if (!relationship.getEntity(isParent).entityType.isA(relationship.definition.getEntityType(isParent)))
        {
            if (options.isDebug)
            {
                consoleLog('malformed relationship from entity: invalid related entity type', entity, options.path.code, options.path, relationshipDefinition.code, isParent, relationshipDefinition, relationship);
            }

            return {
                isValid: false,
                messages: [
                    {
                        type: 'MalformedRelationship',
                        entity: entity,
                        relationship: relationship,
                        field:
                            new RelationshipInput(
                                entity.entityType,
                                relationshipDefinition,
                                isParent),
                        message:
                            localizeText(
                                'EntityValidation.MalformedRelationship',
                                'Er is een ongeldig record ingevuld in het veld ${fieldName} van het type ${currentTypeName} (verwacht: ${expectedTypeName})',
                                {
                                    fieldName: relationshipDefinition.getName(isParent),
                                    currentTypeName: relationship.getEntity(isParent).entityType.getName().toLowerCase(),
                                    expectedTypeName: relationshipDefinition.getEntityType(isParent).getName().toLowerCase()
                                })
                    }
                ]
            }
        }

        if (options.isDeep && entity.doCheckTransactionalRelationship(isParent, relationship))
        {
            const result =
                validateEntityWithOptions(
                    relationship.getEntity(isParent),
                    {
                        ...options,
                        objectPath: options.objectPath.concat([relationship])
                    });

            if (!result.isValid)
            {
                if (options.isDebug)
                {
                    consoleLog('debugging relationship from entity: invalid related entity', entity, options.path.code, options.path, relationshipDefinition.code, isParent, relationshipDefinition, relationship);
                }

                return result;
            }
        }
    }
}

function validateCardinality(entity: Entity,
                             relationshipDefinition: EntityRelationshipDefinition,
                             isParent: boolean,
                             relationships: EntityRelationship[],
                             options: EntityValidationOptions): EntityValidationResult | undefined
{
    const isMandatory = isRelationshipDefinitionRequired(entity.entityType, isParent, relationshipDefinition);
    const relationshipCount = relationships.length;
    const deletedCount = entity.getDeletedRelationshipsByDefinition(isParent, relationshipDefinition).length;

    if (entity.isNew())
    {
        // Relationship should be present if it is mandatory
        if (isMandatory)
        {
            if (relationshipCount > 0)
            {
                return undefined;
            }
            else
            {
                if (options.isDebug)
                {
                    consoleLog('debugging relationship from entity: mandatory relationship is not present', entity, options.path.code, options.path, entity.entityType.code, relationshipDefinition.code, isParent);
                }

                return getMissingRelationshipResult(
                    entity,
                    relationshipDefinition,
                    isParent);
            }
        }
        else
        {
            return undefined;
        }
    }
    else
    {
        // Relationship might not be present if mandatory, but might be deleted
        if (isMandatory)
        {
            if (deletedCount > 0)
            {
                if (options.isDebug && relationshipCount === 0)
                {
                    consoleLog('debugging relationship from entity: mandatory relationship is not present (deleted)', entity, options.path.code, options.path, relationshipDefinition.code, isParent, relationshipCount, deletedCount);
                }

                if (relationshipCount === 0)
                {
                    return getMissingRelationshipResult(
                        entity,
                        relationshipDefinition,
                        isParent);
                }
                else
                {
                    return undefined;
                }
            }
            else
            {
                return undefined;
            }
        }
        else
        {
            return undefined;
        }
    }
}

function getMissingRelationshipResult(entity: Entity,
                                      relationshipDefinition: EntityRelationshipDefinition,
                                      isParent: boolean): EntityValidationResult
{
    return {
        isValid: false,
        messages: [
            {
                type: 'MissingRelationship',
                entity: entity,
                field:
                    new RelationshipInput(
                        entity.entityType,
                        relationshipDefinition,
                        isParent),
                message:
                    localizeText(
                        'EntityValidation.MissingField',
                        '${fieldName} is verplicht',
                        {
                            fieldName: relationshipDefinition.getName(isParent)
                        })
            }
        ]
    };
}
