import { BespokeEntityType } from '../../../BespokeEntityType';
import { EntityTypeStore } from '../../../EntityTypeStore';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';

export class BespokeActivityCourseAttendance extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Activity.CourseAttendance')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    hideFieldPath(
        entity: Entity,
        fieldPath: EntityFieldPath,
        fromFieldPath?: EntityFieldPath,
        isInConstructor?: boolean
    ): boolean
    {
        return super.hideFieldPath(entity, fieldPath, fromFieldPath, isInConstructor)
            || fieldPath.field === this.types.Activity.Field.Subject;
    }

    // ----------------------- Private logic ------------------------
}
