import { useMemo } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationshipDefinition } from '../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { useComputed } from 'mobx-react-lite';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { createTransactionalModelIfCommitContextIsAbsent } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/createTransactionalModelIfCommitContextIsAbsent';
import { constructEntityOfType } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export default function useOptionalRelatedEntity(entity?: Entity,
                                                 relationshipDefinition?: EntityRelationshipDefinition,
                                                 isParent?: boolean,
                                                 commitContext?: CommitContext)
{
    const relatedEntity =
        useComputed(
            () =>
            {
                if (entity && relationshipDefinition && isParent !== undefined)
                {
                    return entity.getRelatedEntityByDefinition(
                        isParent,
                        relationshipDefinition);
                }
                else
                {
                    return undefined;
                }
            },
            [
                entity,
                relationshipDefinition,
                isParent
            ]);

    return useMemo(
        () =>
        {
            if (entity && relationshipDefinition && isParent !== undefined)
            {
                if (relatedEntity)
                {
                    return createTransactionalModelIfCommitContextIsAbsent(
                        relatedEntity,
                        commitContext
                    );
                }
                else
                {
                    const newEntity =
                        constructEntityOfType(
                            relationshipDefinition.getEntityType(isParent),
                            commitContext
                        );
                    updateRelationship(
                        newEntity,
                        !isParent,
                        relationshipDefinition,
                        entity,
                        commitContext,
                    );
                    return newEntity;
                }
            }
            else
            {
                return undefined;
            }
        },
        [
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext,
        ]);
}
