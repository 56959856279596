import { ViewState } from '../State/ViewState';
import { useRoutingState } from '../../../../../@Service/Router/Model/Api/useRoutingState';
import md5 from 'md5';
import { useComputed } from 'mobx-react-lite';
import { ViewStateKey } from '../State/ViewStateKey';

export function useViewRoutingState(
    key: ViewStateKey | undefined
): [ViewState | undefined, (viewState?: ViewState) => void]
{
    const viewKey =
        useComputed(
            () =>
                key === undefined
                    ? undefined
                    : getViewKeyAsString(key),
            [key]
        );
    const [viewState, setViewState] =
        useRoutingState<ViewState | undefined>(
            viewKey,
            {}
        );

    return [viewState, setViewState];
}

function getViewKeyAsString(key: ViewStateKey)
{
    const serializableKey = getSerializableViewKey(key);

    return `View.${md5(JSON.stringify(serializableKey))}`;
}

function getSerializableViewKey(key: ViewStateKey)
{
    return {
        entityTypeId: key.entityType.id,
        viewId: key.viewId,
        view:
            key.view === undefined
                ? undefined
                : key.view.toDescriptor(true),
        filter:
            key.filter === undefined
                ? undefined
                : {
                    name: key.filter.name,
                    predicate: key.filter.predicate?.toDescriptor()
                },
    };
}