import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { primaryColor } from '../../../../../../../@Resource/Theme/Theme';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import useTypes from '../../../../Type/Api/useTypes';
import { useCurrencies } from '../../../../../Configuration/Page/Settings/OrganizationSettings/CurrencySettings/Api/useCurrencies';
import { ProductCurrencyPrice } from './ProductCurrencyPrice';
import { useBaseCurrency } from '../../../../../Configuration/Page/Settings/OrganizationSettings/CurrencySettings/Api/useBaseCurrency';
import Popper from '../../../../../../../@Future/Component/Generic/Popper/Popper';
import IconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import Menu from '../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import useSwitch from '../../../../../../../@Util/Switch/useSwitch';
import { Currency } from '../../../../../../../@Api/Localization/Currency';
import { CommitBuilder } from '../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import getDefaultPriceList from './Api/getDefaultPriceList';
import getOrCreateDefaultPriceList from './Api/getOrCreateDefaultPriceList';
import useProductDefaultPrices from './Api/useProductDefaultPrices';

const useStyles = makeStyles({
    reference:
        {
            alignContent: 'center'
        }
});

export interface ProductCurrencyPricesProps
{
    product: Entity;
}

export const ProductCurrencyPrices: React.FC<ProductCurrencyPricesProps> =
    observer(
        ({
             product,
         }) =>
        {
            const classes = useStyles();
            const types = useTypes();

            const [ isPopupOpen, openPopup, closePopup ] = useSwitch(false);

            const currencies = useCurrencies();
            const baseCurrency = useBaseCurrency();
            const defaultPrices = useProductDefaultPrices(product);

            const newCurrencies =
                useMemo(
                    () =>
                        currencies
                            ?.filter(
                                currency =>
                                    currency.code !== baseCurrency?.code
                            )
                            ?.filter(
                                currency =>
                                    !defaultPrices
                                        ?.map
                                        (
                                            defaultPrice =>
                                                defaultPrice.getObjectValueByField(types.Price.Field.Currency)
                                        ).includes(currency.code)
                            ),
                    [
                        currencies,
                        baseCurrency,
                        defaultPrices,
                    ]
                );

            const onAdd =
                useCallback(
                     (currency: Currency) =>
                        getOrCreateDefaultPriceList(currency)
                            .then(
                                defaultPriceList =>
                                    new CommitBuilder()
                                        .createEntity(
                                            types.Price.Type,
                                            builder =>
                                                builder
                                                    .setObjectValue(
                                                        types.Price.Field.Currency,
                                                        currency.code
                                                    )
                                                    .setObjectValue(
                                                        types.Price.Field.Price,
                                                        currency.rate * product.getObjectValueByField(types.Product.Field.SalesPrice)
                                                    )
                                                    .relateTo(
                                                        false,
                                                        types.Price.RelationshipDefinition.Product,
                                                        product
                                                    )
                                                    .relateTo(
                                                        true,
                                                        types.PriceList.RelationshipDefinition.Prices,
                                                        defaultPriceList
                                                    ),
                                            'currencyPrice'
                                        )
                                        .commit()
                                        .then(
                                            () =>
                                                closePopup()
                                        )
                            ),
                    [
                        types,
                        product,
                        getDefaultPriceList,
                        closePopup
                    ]
                );

            const onDelete =
               useCallback(
                   (price: Entity) =>
                       new CommitBuilder()
                           .deleteEntity(
                               price
                           )
                           .commit(),
                   [

                   ]
               );

            return <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                {
                    defaultPrices
                        ?.map(
                            defaultPrice =>
                                <ViewGroupItem
                                    key={defaultPrice.id}
                                >
                                    <ProductCurrencyPrice
                                        price={defaultPrice}
                                        onDelete={onDelete}
                                    />
                                </ViewGroupItem>
                        )
                }
                {
                    newCurrencies?.length > 0 &&
                    <Popper
                        classes={{
                            reference: classes.reference
                        }}
                        reference=
                            {
                                <IconButton
                                    color={primaryColor}
                                    filled
                                    icon="add"
                                    onClick={openPopup}
                                    tooltip={
                                        <LocalizedText
                                            code="Generic.Add"
                                            value="Toevoegen"
                                        />
                                    }
                                />
                            }
                        popper=
                            {
                                <Card>
                                    <Menu>
                                        {
                                           newCurrencies
                                               ?.map(
                                                   currency =>
                                                       <Item
                                                           key={currency.code}
                                                           name={`${currency.name} (${currency.symbol})`}
                                                           onClick={() => onAdd(currency)}
                                                       />
                                               )
                                        }
                                    </Menu>
                                </Card>
                            }
                        open={isPopupOpen}
                        onClose={closePopup}
                    />
                }
            </ViewGroup>;
        });