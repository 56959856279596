import Dependency from '../../../../Automation/Parameter/Dependency';
import LayoutDependencyContext from '../../../LayoutDependencyContext';
import getComputationFromDescriptor from '../../../../Automation/Api/getComputationFromDescriptor';
import TableDimensionSection from './TableDimensionSection';
import Parameter from '../../../../Automation/Parameter/Parameter';
import FunctionContext from '../../../../Automation/Function/FunctionContext';
import TableDimensionInstance from './TableDimensionInstance';
import ParameterDictionary from '../../../../Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../../../Automation/Parameter/ParameterAssignment';
import TableLayout from '../TableLayout';
import CollectionValue from '../../../../Automation/Value/CollectionValue';
import EmptyValueType from '../../../../Automation/Value/Type/EmptyValueType';
import getLayoutActionFromDescriptor from '../../../Action/Api/getLayoutActionFromDescriptor';

export default class StaticTableDimensionSection extends TableDimensionSection
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getParameters(): Parameter<any>[]
    {
        return [];
    }

    async computeInstanceValues(context: FunctionContext, layout: TableLayout): Promise<CollectionValue<any>>
    {
        return new CollectionValue([], EmptyValueType.instance);
    }

    async computeInstances(context: FunctionContext,
                           layout: TableLayout): Promise<TableDimensionInstance[]>
    {
        return [
            new TableDimensionInstance(
                this.id,
                this,
                await this.computeSize(context),
                new ParameterDictionary(this.getParameters()),
                new ParameterAssignment())
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.size.getDependencies(),
            ...(this.onClick?.getDependencies() ?? [])
        ];
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Static'
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const id = descriptor.id;
        const size =
            await getComputationFromDescriptor(
                descriptor.size,
                dependencyContext);
        const onClick =
            descriptor.onClick
                ?
                    await getLayoutActionFromDescriptor(
                        descriptor.onClick,
                        dependencyContext)
                :
                    undefined;

        return new StaticTableDimensionSection(
            id,
            size,
            onClick);
    }

    // ----------------------- Private logic ------------------------
}
