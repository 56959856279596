import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { observable } from 'mobx';
import { BespokeRelationPerson } from './BespokeRelationPerson';
import { BespokeRelationOrganization } from './BespokeRelationOrganization';

export class BespokeRelation extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable person: BespokeRelationPerson;
    @observable organization: BespokeRelationOrganization;

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Relation')
    {
        super(entityTypeStore, code);

        this.person = new BespokeRelationPerson(this.entityTypeStore);
        this.organization = new BespokeRelationOrganization(this.entityTypeStore);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    allowIconOrCharactersInAvatar(): boolean
    {
        return true;
    }

    isOpenableFromSelectbox(): boolean
    {
        return true;
    }

    // ----------------------- Private logic ------------------------
}
