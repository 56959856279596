import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { DependencyList, useEffect, useMemo, useState } from 'react';
import { EntityExpansionBuilder } from '../Builder/EntityExpansionBuilder';
import { useIsMounted } from '../../../../../@Util/Async/useIsMounted';

export function useExpansion(
    type: EntityType | undefined,
    paths: (rootPath: EntityPath) => EntityPath[],
    entities: () => Entity[],
    deps: DependencyList
): [ boolean ]
{
    const isMounted = useIsMounted();
    const expansionBuilder =
        useMemo(
            () =>
                type &&
                new EntityExpansionBuilder(
                    type,
                    entities(),
                    paths(EntityPath.fromEntityType(type))
                ),
            [
                type,
                ...deps
            ]
        );
    const [ isLoading, setLoading ] = useState(true);

    useEffect(
        () =>
        {
            if (expansionBuilder)
            {
                setLoading(true);
                expansionBuilder.expand()
                    .then(
                        () =>
                        {
                            if (isMounted())
                            {
                                setLoading(false);
                            }
                        });

                return () =>
                    expansionBuilder.dispose();
            }
            else
            {
                setLoading(false);
            }
        },
        [
            setLoading,
            expansionBuilder,
            isMounted
        ]);

    return [ isLoading ];
}
