import * as React from 'react';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { CompositePredicateEditorStore } from './CompositePredicateEditorStore';
import { observer } from 'mobx-react';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import { Grid, Paper } from '@material-ui/core';
import { PredicateEditor } from '../../PredicateEditor';
import { grey } from '@material-ui/core/colors';
import { blue } from '../../../../../@Resource/Theme/Blue';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import MenuButton from '../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Item from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';

@observer
export class CompositePredicateEditor extends BaseComponent<CompositePredicateEditorStore>
{
    renderComponent(store: CompositePredicateEditorStore)
    {
        return <Grid container spacing={0}>
            <Grid item xs={12}>
                <Paper
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: 15,
                        paddingRight: 15
                    }}
                >
                    <ViewGroup
                        orientation="horizontal"
                        spacing={10}
                        alignment="center"
                    >
                        <ViewGroupItem>
                            <IconButton
                                icon="compare_arrows"
                                tooltip="Switchen tussen 'en' en 'of'"
                                onClick={store.toggleLogicalOperator}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem
                            ratio={1}
                        >
                            {
                                store.logicalOperator === LogicalOperator.And
                                    ?
                                        store.localizationStore.translate('CompositePredicateEditor.Label.AllConditionsBelowShouldBeValid') // All condidtions below should be valid
                                    :
                                        store.localizationStore.translate('CompositePredicateEditor.Label.AtLeastOneConditionShouldBeValid') // At least one condition should be valid
                            }
                        </ViewGroupItem>
                    </ViewGroup>
                </Paper>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', minHeight: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 50 }}>
                    <div
                        style={{
                            backgroundColor: grey[500],
                            width: 1,
                            height: '100%'
                        }}
                    />
                </div>
                <div style={{ flex: '1 1 auto' }}>
                    {
                        store.predicateStores.map(
                            predicateStore => (
                                <div
                                    key={predicateStore.uuid}
                                    style={{
                                        position: 'relative',
                                        margin: 10
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            backgroundColor: grey[500],
                                            width: 35,
                                            height: 1,
                                            left: -35,
                                            top: 25
                                        }}
                                    />
                                    <Paper
                                        style={{
                                            display: 'inline-flex',
                                            flex: '1 1 auto',
                                            background: predicateStore.type.isTerminal() ? 'white' : 'none',
                                            borderRadius: predicateStore.type.isTerminal() ? 10 : undefined,
                                            paddingLeft: predicateStore.type.isTerminal() ? 5 : undefined
                                        }}
                                        elevation={predicateStore.type.isTerminal() ? undefined : 0}
                                    >
                                        <div style={{ flex: '1 1 auto', display: 'inline-flex', alignItems: 'center' }}>
                                            <PredicateEditor store={predicateStore} />
                                        </div>
                                        <div>
                                            <IconButton
                                                icon="remove_circle"
                                                tooltip={
                                                    <LocalizedText
                                                        code="Generic.Delete"
                                                        value="Verwijderen"
                                                    />
                                                }
                                                onClick={() => store.deletePredicate(predicateStore)}
                                            />
                                        </div>
                                    </Paper>
                                </div>))
                    }
                </div>
            </Grid>
            <Grid item xs={12}>
                <Paper
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        backgroundColor: blue[100],
                        borderRadius: 15,
                        marginLeft: 9
                    }}
                >
                    <MenuButton
                        icon="add"
                        tooltip="Filter toevoegen"
                        key={store.predicateStores.length}
                    >
                        <Menu>
                            {
                                store.predicateTypeStore.types
                                    .filter(type => type.allow(store.context))
                                    .map(type =>
                                        <Item
                                            key={type.id()}
                                            name={type.name()}
                                            onClick={() =>
                                            {
                                                store.addPredicate(type);
                                                // store.closeAddMenu();
                                                // e.stopPropagation();
                                            }}
                                        />)
                            }
                        </Menu>
                    </MenuButton>
                </Paper>
            </Grid>
        </Grid>;
    }
}
