import React from 'react';
import IconButton, { IconButtonProps } from '../Icon/IconButton';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface SettingsIconButtonProps extends Partial<IconButtonProps>
{

}

const SettingsIconButton: React.FC<SettingsIconButtonProps> =
    props =>
    {
        return <IconButton
            icon="settings"
            onClick={props.onClick}
            tooltip={
                <LocalizedText
                    code="Generic.Settings"
                    value="Instellingen"
                />
            }
            {...props}
        />;
    };

export default SettingsIconButton;
