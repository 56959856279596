import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationBox from '../../Box/ComputationBox';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import NameFromFileComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/NameFromFileComputation';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../../../../../DataObject/Model/DataObject';

export interface NameFromFileComputationEditorProps extends ComputationEditorProps<ValueType<any>, NameFromFileComputation>
{

}

const NameFromFileComputationEditor: React.FC<NameFromFileComputationEditorProps> =
    props =>
    {
        const setValue =
            useCallback(
                (file?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.file = file
                    ),
                [
                    props.value
                ]
            );
        const type =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        DataObject.getTypeById('File')
                    ),
                []
            );

        return <Input
            label={
                <LocalizedText
                    code="Computation.NameFromFile"
                    value="Naam uit bestand"
                />
            }
            labelPosition="left"
        >
            <ComputationBox>
                <ComputationEditor
                    context={props.context}
                    value={props.value.file}
                    onChange={setValue}
                    type={type}
                />
            </ComputationBox>
        </Input>;
    };

export default observer(NameFromFileComputationEditor);
