import { DependencyList, useCallback, useEffect, useMemo } from 'react';
import { observable, runInAction } from 'mobx';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../Path/@Model/EntityPath';
import getPaginationResult, { Page, PaginationQuery } from '../Api/getPaginationResult';
import { useComputed } from 'mobx-react-lite';
import { EntitySelectionResult } from '../Model/EntitySelectionResult';
import { useIsMounted } from '../../../../../@Util/Async/useIsMounted';

export const DefaultPageSize = 50;

type Disposer = () => void;

interface PaginationQueryBuilder
{
    type: EntityType;
    callback: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void;
    processResults?: (results: EntitySelectionResult[]) => Promise<Disposer>;
    pageSize?: number;
}

export interface CombinedPaginationResultController
{
    results: PaginationResultController[];
    isLoading: boolean;
    hasMore: boolean;
    loadMore: () => Promise<any>;
}

export interface PaginationResultController
{
    pages: () => Page[];
    hasMore: () => boolean;
    loadMore: () => Promise<any>;
    isLoading: () => boolean;
    dispose: () => void;
}

export default function useCombinedPaginationResult(queryBuilders: PaginationQueryBuilder[],
                                                    deps: DependencyList): CombinedPaginationResultController
{
    const isMounted = useIsMounted();
    const queries =
        useMemo<PaginationQuery[]>(
            () =>
                queryBuilders.map(
                    queryBuilder =>
                    {
                        const { type, callback, pageSize = DefaultPageSize } = queryBuilder;

                        if (type)
                        {
                            const rootPath = EntityPath.fromEntityType(type);
                            const builder = EntitySelectionBuilder.fromPath(rootPath);
                            callback(builder, rootPath);
                            const selection = builder.build();

                            return {
                                type: type,
                                pageIdx: 0,
                                pageSize: pageSize,
                                selection: selection,
                                ordering: builder.ordering.slice(),
                                processResults: queryBuilder.processResults
                            };
                        }
                        else
                        {
                            return undefined;
                        }
                    }),
            deps);
    const results =
        useMemo<PaginationResultController[]>(
            () =>
                queries.map(
                    query =>
                    {
                        if (query)
                        {
                            const pageIdxValue = observable.box(0);
                            const isLoadingValue = observable.box(false);
                            const pagesValue = observable.box(observable.array<Page>());
                            const loadPage =
                                async (pageIdxToLoad: number) =>
                                {
                                    runInAction(
                                        () =>
                                        {
                                            isLoadingValue.set(true);
                                            pageIdxValue.set(pageIdxToLoad);
                                        });

                                    getPaginationResult({
                                        ...query,
                                        pageIdx: pageIdxToLoad
                                    }).then(
                                        page =>
                                            runInAction(
                                                () =>
                                                {
                                                    if (isMounted())
                                                    {
                                                        pagesValue.get().push(page);
                                                        isLoadingValue.set(false);
                                                    }
                                                }));
                                };
                            const loadMore =
                                () =>
                                    loadPage(pageIdxValue.get() + 1);
                            const hasMore =
                                () =>
                                {
                                    const pages = pagesValue.get();

                                    if (pages.length === 0)
                                    {
                                        return false;
                                    }
                                    else
                                    {
                                        return pages[pages.length - 1].numberOfRecords >= query.pageSize;
                                    }
                                };
                            const isLoading =
                                () =>
                                    isLoadingValue.get();
                            const pages =
                                () =>
                                    pagesValue.get();
                            const dispose =
                                () =>
                                    pagesValue.get().forEach(
                                        page =>
                                            page.dispose());

                            loadPage(0);

                            return {
                                pages,
                                hasMore,
                                loadMore,
                                isLoading,
                                dispose
                            };
                        }
                        else
                        {
                            return {
                                pages: () => [],
                                hasMore: () => false,
                                loadMore: () => Promise.resolve(),
                                isLoading: () => false,
                                dispose: () => {}
                            };
                        }
                    }),
            [
                queries,
                isMounted
            ]);
    const isLoading =
        useComputed(
            () =>
                results.some(controller => controller.isLoading()),
            [
                results
            ]);
    const hasMore =
        useComputed(
            () =>
                results.some(controller => controller.hasMore()),
            [
                results
            ]);
    const loadMore =
        useCallback(
            () =>
                Promise.all(
                    results
                        .map(
                            controller =>
                                controller.hasMore()
                                    ?
                                        controller.loadMore()
                                    :
                                        Promise.resolve())),
            [
                results
            ]);

    useEffect(
        () =>
            () =>
                results.forEach(
                    result =>
                        result.dispose()),
        [
            results
        ]);

    return {
        results,
        isLoading,
        hasMore,
        loadMore
    };
}
