import { useCallback, useContext, useState } from 'react';
import useOnDragStart from '../../../../../DragAndDrop/Api/useOnDragStart';
import useOnDragEnd from '../../../../../DragAndDrop/Api/useOnDragEnd';
import { DragStart } from 'react-beautiful-dnd';
import EntityTypeContext from '../../../../Type/EntityTypeContext';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';

export default function useDraggingEntity()
{
    const [ entity, setEntity ] = useState<Entity>(undefined);
    const entityTypeStore = useContext(EntityTypeContext);

    const onStart =
        useCallback(
            (start: DragStart) =>
            {
                const entity = entityTypeStore.entityCacheService.getEntity(start.draggableId);

                if (entity)
                {
                    setEntity(entity);
                }
            },
            [
                setEntity,
                entityTypeStore
            ]);

    const onEnd =
        useCallback(
            () =>
            {
                setEntity(undefined);
            },
            [
                setEntity
            ]);

    useOnDragStart(onStart);
    useOnDragEnd(onEnd);

    return entity;
}
