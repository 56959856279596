import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { add, differenceInDays, format, getMonth, getWeek, max, min, startOfMonth, startOfWeek } from 'date-fns';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import useTypes from '../Type/Api/useTypes';
import { grey } from '@material-ui/core/colors';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import { EmployeeAllocationEditorEmployeeRow } from './EmployeeRow/EmployeeAllocationEditorEmployeeRow';
import { itemPlannerWeekLength } from './EmployeeAllocationEditorConstants';
import { groupBy } from '../../../../@Util/MapUtils/groupBy';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import MenuButton from '../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import DatePicker from '../../../../@Future/Component/Generic/Input/DatePicker/DatePicker';
import useSwitch from '../../../../@Util/Switch/useSwitch';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { useLocalizer } from '../../../../@Service/Localization/Api/useLocalizer';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { createEntityComparator } from '../List/Comparator/EntityComparator';
import { EntityPath } from '../Path/@Model/EntityPath';
import { primaryColor, textSecondaryColor } from '../../../../@Resource/Theme/Theme';
import usePaginationResult from '../Selection/Hooks/usePaginationResult';
import Divider from '../../../../@Future/Component/Generic/Divider/Divider';
import HoverCardBottom from '../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import HoverCardMiddle from '../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import SortIconButton from '../../../../@Future/Component/Generic/Button/Variant/SortIconButton/SortIconButton';
import { EmployeeAllocationSpecification } from './Model/EmployeeAllocationSpecification';
import FunctionContext from '../../../../@Api/Automation/Function/FunctionContext';
import ParameterValueEditor from '../Viewer/Content/Automation/Editor/ParameterAssignment/Parameter/ParameterValueEditor';
import { useRoutingState } from '../../../../@Service/Router/Model/Api/useRoutingState';
import { useLocalParameterAssignmentSetting } from '../ResourcePlanner/Api/useLocalParameterAssignmentSetting';
import { NavigationButtons } from '../../../../@Future/Component/Generic/NavigationButtons/NavigationButtons';

export interface EmployeeAllocationEditorProps
{
    specification: EmployeeAllocationSpecification;
    title?: React.ReactNode;
    options?: React.ReactNode;
    minDate?: Date;
    maxDate?: Date;
    settingStorageKey?: string;
}

const resourceColumnWidth = 300;
const normColumnWidth = 100;
const totalColumnWidth = 120;

interface MonthPeriod
{
    startDate: Date;
    numberOfDays: number;
}

export const EmployeeAllocationEditor: React.FC<EmployeeAllocationEditorProps> =
    observer(
        ({
            specification,
            title,
            options,
            minDate,
            maxDate,
            settingStorageKey,
         }) =>
        {
            const { ref, width } = useResizeObserver<HTMLDivElement>();
            const numberOfWeeksToShow =
                useMemo(
                    () =>
                        width === undefined
                            ? 0
                            : Math.round(Math.max(300, (width ?? 0 - resourceColumnWidth - normColumnWidth - totalColumnWidth)) / 100),
                    [
                        width,
                    ]
                );
            const [ dateFromRoutingState, setDateInRoutingState ] =
                useRoutingState<Date>(
                    settingStorageKey
                        ? `${settingStorageKey}.Date`
                        : undefined,
                    max([
                        minDate ?? new Date(),
                        new Date()
                        ])
                );
            const [ startDate, setStartDate ] =
                useState(
                    () =>
                        startOfWeek(dateFromRoutingState, { weekStartsOn: 1 }),
                );
            useEffect(
                () =>
                    setStartDate(startOfWeek(dateFromRoutingState)),
                [
                    dateFromRoutingState,
                ]);
            const previous =
                useCallback(
                    () =>
                        setDateInRoutingState(add(startDate, { weeks: -2 })),
                    [
                        setDateInRoutingState,
                        startDate,
                    ]);
            const next =
                useCallback(
                    () =>
                        setDateInRoutingState(add(startDate, { weeks: 2 })),
                    [
                        setDateInRoutingState,
                        startDate,
                    ]);
            const endDate =
                useMemo(
                    () => add(startDate, { weeks: numberOfWeeksToShow }),
                    [
                        startDate,
                        numberOfWeeksToShow,
                    ]
                );
            const monthPeriods =
                useMemo(
                    () =>
                    {
                        const months: MonthPeriod[] = [];
                        let currentStartOfMonth = startOfMonth(startDate);
                        let currentMonth = getMonth(currentStartOfMonth);

                        do
                        {
                            const nextStartOfMonth = add(currentStartOfMonth, { months: 1 });
                            const nextMonth = getMonth(nextStartOfMonth);

                            if (nextMonth !== currentMonth)
                            {
                                const startOfMonthPeriod = max([ startDate, currentStartOfMonth ]);
                                const endOfMonthPeriod = min([ nextStartOfMonth, endDate ]);

                                months.push({
                                    startDate: startOfMonthPeriod,
                                    numberOfDays: differenceInDays(endOfMonthPeriod, startOfMonthPeriod),
                                });
                            }

                            currentStartOfMonth = nextStartOfMonth;
                            currentMonth = nextMonth;
                        }
                        while (currentStartOfMonth < endDate)

                        return months;
                    },
                    [
                        startDate,
                        endDate,
                    ]
                );
            const [
                resourceFilterParameterAssignment,
                computedResourceFilterParameterAssignment,
                isLoadingResourceFilterParameterAssignment,
            ] = useLocalParameterAssignmentSetting(
                specification.resourceFilterParameters,
                settingStorageKey === undefined
                    ? undefined
                    : `EmployeeAllocationEditor.ResourceFilterParameterAssignment.${settingStorageKey}`
            );
            const [
                employeeAllocationFilterParameterAssignment,
                computedEmployeeAllocationFilterParameterAssignment,
                isLoadingEmployeeAllocationFilterParameterAssignment,
            ] = useLocalParameterAssignmentSetting(
                specification.employeeAllocationFilterParameters,
                settingStorageKey === undefined
                    ? undefined
                    : `EmployeeAllocationEditor.EmployeeAllocationFilterParameterAssignment.${settingStorageKey}`
            );
            const types = useTypes();
            const [ isSortByTotal, setSortByTotal ] = useState(false);
            const [ isAscendingSort, setAscendingSort ] = useState(true);
            const {
                pages: resourcePages,
                loadMore: resourcesLoadMore,
                hasMore: resourcesHasMore,
                isLoading: resourcesIsLoading
            } =
                usePaginationResult(
                    {
                        type: specification.resourceParameter.type.type,
                        callback:
                            (builder, rootPath) =>
                                builder
                                    .if(
                                        () => specification.resourceFilter !== undefined,
                                        sb =>
                                            sb.where(
                                                cb =>
                                                    cb.filter(
                                                        specification.resourceFilter,
                                                        {
                                                            parameter: specification.resourceParameter,
                                                            context:
                                                                new FunctionContext(
                                                                    specification.resourceFilterParameters!,
                                                                    computedResourceFilterParameterAssignment,
                                                                )
                                                        }
                                                    )
                                            )
                                    )
                                    .if(
                                        () =>
                                            !isSortByTotal,
                                        sb =>
                                            sb.orderBy(
                                                rootPath.field(types.Entity.Field.Name),
                                                isAscendingSort
                                            )
                                    ),
                        isDisabled:
                            numberOfWeeksToShow === 0
                            || isLoadingResourceFilterParameterAssignment,
                    },
                    [
                        types,
                        specification.resourceFilter,
                        specification.resourceFilterParameters,
                        computedResourceFilterParameterAssignment,
                        isSortByTotal,
                        isAscendingSort,
                        numberOfWeeksToShow === 0,
                        isLoadingResourceFilterParameterAssignment,
                    ]
                );
            const resources =
                useComputed(
                    () =>
                        resourcePages
                            .map(
                                page =>
                                    page.results.map(
                                        result =>
                                            result.entity
                                    )
                            )
                            .reduce(
                                (a, b) => a.concat(b),
                                []
                            ),
                    [
                        resourcePages,
                    ]
                );

            const {
                pages: allocationPages,
                loadMore: allocationLoadMore,
                hasMore: allocationHasMore,
                isLoading: allocationIsLoading
            } =
                usePaginationResult({
                    type: resources ? types.EmployeeAllocation.Type : undefined,
                    isDisabled:
                        numberOfWeeksToShow === 0
                        || isLoadingEmployeeAllocationFilterParameterAssignment,
                    pageSize: 500,
                    callback:
                        (builder, rootPath) =>
                            builder
                                .where(
                                    cb =>
                                        cb.or(
                                            ob =>
                                                resources.forEach(
                                                    resource =>
                                                        ob.relatedToEntity(
                                                            specification.resourceToEmployeeAllocationPath.reverse(),
                                                            resource
                                                        )
                                                )
                                        )
                                )
                                .where(
                                    cb =>
                                        cb.ge(
                                            rootPath.field(types.EmployeeAllocation.Field.WeekDate),
                                            undefined,
                                            startDate
                                        )
                                )
                                .where(
                                    cb =>
                                        cb.lt(
                                            rootPath.field(types.EmployeeAllocation.Field.WeekDate),
                                            undefined,
                                            endDate
                                        )
                                )
                                .if(
                                    () => specification.employeeAllocationFilter !== undefined,
                                    sb =>
                                        sb.where(
                                            cb =>
                                                cb.filter(
                                                    specification.employeeAllocationFilter,
                                                    {
                                                        parameter: specification.employeeAllocationParameter,
                                                        context:
                                                            new FunctionContext(
                                                                specification.employeeAllocationFilterParameters!,
                                                                computedEmployeeAllocationFilterParameterAssignment,
                                                            )
                                                    }
                                                )
                                        )
                                ),
                    },
                [
                        types,
                        resources,
                        startDate,
                        endDate,
                        specification,
                        computedEmployeeAllocationFilterParameterAssignment,
                        numberOfWeeksToShow === 0,
                        isLoadingEmployeeAllocationFilterParameterAssignment,
                    ]
                );
            const employeeAllocations =
                useComputed(
                    () =>
                        allocationPages
                            .map(
                                page =>
                                    page.results.map(
                                        result =>
                                            result.entity
                                    )
                            )
                            .reduce(
                                (a, b) => a.concat(b),
                                []
                            ),
                    [
                        allocationPages,
                    ]
                );
            const employeeAllocationsByResource =
                useMemo(
                    () =>
                        groupBy(
                            employeeAllocations ?? [],
                            element =>
                                specification.resourceToEmployeeAllocationPath.reverse()
                                    .traverseEntity(element)
                                    .find(() => true)
                        ),
                    [
                        employeeAllocations,
                        specification,
                    ]
                );
            const totalByResource =
                useComputed(
                    () =>
                        new Map(
                            Array.from(employeeAllocationsByResource.entries())
                                .map(
                                    ([ employee, allocations ]) => [
                                        employee,
                                        (allocations ?? []).reduce(
                                            (a, b) =>
                                                a + (b.getObjectValueByField(types.EmployeeAllocation.Field.DurationInHours) ?? 0),
                                            0
                                        )
                                    ]
                                )
                        ),
                    [
                        employeeAllocationsByResource,
                        types,
                    ]
                );
            const sortedResources =
                useMemo(
                    () =>
                    {
                        if (resources)
                        {
                            if (isSortByTotal)
                            {
                                return resources
                                    .slice()
                                    .sort(
                                        (a, b) =>
                                            (totalByResource.get(isAscendingSort ? a : b) ?? 0) - (totalByResource.get(isAscendingSort ? b : a) ?? 0)
                                    );
                            }
                            else
                            {
                                return resources
                                    .slice()
                                    .sort(
                                        createEntityComparator(
                                            d => d,
                                            undefined,
                                            isAscendingSort ? 'Ascending' : 'Descending',
                                            EntityPath.fromEntityType(specification.resourceParameter.type.type)
                                                .field(types.Entity.Field.Name)
                                        )
                                    );
                            }
                        }
                        else
                        {
                            return undefined;
                        }
                    },
                    [
                        resources,
                        isSortByTotal,
                        isAscendingSort,
                        totalByResource,
                        specification,
                    ]
                );
            const [ isDatePickerOpen, openDatePicker, closeDatePicker ] = useSwitch(false);
            const localizer = useLocalizer();

            return <div
                ref={ref}
            >
                <CardInset
                    horizontal
                    vertical={false}
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                    >
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                                alignment="center"
                            >
                                <ViewGroupItem>
                                    <NavigationButtons
                                        onBackClicked={previous}
                                        onForwardClicked={next}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    {title}
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={5}
                                        alignment="center"
                                    >

                                        <MenuButton
                                            icon="today"
                                            tooltip={
                                                <LocalizedText
                                                    code="ResourcePlanner.SelectDate"
                                                    value="Datum opzoeken"
                                                />
                                            }
                                            expansion={
                                                close =>
                                                    <CardInset>
                                                        <DatePicker
                                                            onChange={
                                                                date => {
                                                                    setStartDate(startOfWeek(date, { weekStartsOn: 1 }));
                                                                    close();
                                                                }
                                                            }
                                                            value={startDate}
                                                        />
                                                    </CardInset>
                                            }
                                            onClick={openDatePicker}
                                            open={isDatePickerOpen}
                                            onClose={closeDatePicker}
                                        />
                                        {options}
                                    </ViewGroup>
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                        <ViewGroupItem
                            alignment="center"
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={0}
                                alignment="center"
                                justification="end"
                            >
                                {
                                    !isLoadingResourceFilterParameterAssignment &&
                                    (specification.resourceFilterParameters?.parameters ?? []).map(
                                        resourceFilterParameter =>
                                            <ViewGroupItem
                                                key={resourceFilterParameter.id}
                                            >
                                                <ParameterValueEditor
                                                    parameterAssignment={resourceFilterParameterAssignment}
                                                    parameter={resourceFilterParameter}
                                                    filter
                                                />
                                            </ViewGroupItem>
                                    )
                                }
                                {
                                    !isLoadingEmployeeAllocationFilterParameterAssignment &&
                                    (specification.employeeAllocationFilterParameters?.parameters ?? []).map(
                                        employeeAllocationFilterParameter =>
                                            <ViewGroupItem
                                                key={employeeAllocationFilterParameter.id}
                                            >
                                                <ParameterValueEditor
                                                    parameterAssignment={employeeAllocationFilterParameterAssignment}
                                                    parameter={employeeAllocationFilterParameter}
                                                    filter
                                                />
                                            </ViewGroupItem>
                                    )
                                }
                            </ViewGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </CardInset>

                {
                    allocationHasMore &&
                    <ViewGroupItem>
                        <Divider />
                        <HoverCardMiddle
                            onClick={allocationLoadMore}
                            disabled={allocationIsLoading}
                        >
                            <LocalizedText
                                code="Generic.NotAllResultsLoaded"
                                value="Niet alle resultaten zijn ingeladen. Klik hier om meer resultaten in te laden."
                            />
                        </HoverCardMiddle>
                        <Divider />
                    </ViewGroupItem>
                }

                <Table>
                    <TableHead>
                        <TableRow
                            style={{
                                height: 0,
                            }}
                        >
                            <TableCell
                                width={resourceColumnWidth}
                            />
                            {
                                specification.resourceNormFieldPath !== undefined &&
                                <TableCell
                                    width={normColumnWidth}
                                />
                            }
                            {
                                new Array(numberOfWeeksToShow * 7).fill(0).map(
                                    (_, idx) =>
                                        <TableCell
                                            key={idx}
                                            padding="none"
                                            style={{
                                                width: `calc((100% - ${resourceColumnWidth + normColumnWidth + totalColumnWidth}px) / ${numberOfWeeksToShow * 7})`
                                            }}
                                        />
                                )
                            }
                            <TableCell
                                width={totalColumnWidth}
                            />
                        </TableRow>
                        <TableRow>
                            <TableCell />
                            <TableCell />
                            {
                                monthPeriods.map(
                                    (period, idx) =>
                                        <TableCell
                                            key={idx}
                                            colSpan={period.numberOfDays}
                                            align="center"
                                            style={{
                                                borderLeft: `1px solid ${grey[100]}`
                                            }}
                                        >
                                            {format(period.startDate, 'LLLL', { locale: localizer.dateLocale })} '{format(period.startDate, 'yy', { locale: localizer.dateLocale })}
                                        </TableCell>
                                )
                            }
                            <TableCell
                                style={{
                                    borderLeft: `1px solid ${grey[100]}`
                                }}
                            />
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={10}
                                    alignment="center"
                                >
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        {specification.resourceParameter.name}
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <SortIconButton
                                            isAscendingSort={isAscendingSort}
                                            color={isSortByTotal ? textSecondaryColor : primaryColor}
                                            onClick={
                                                () =>
                                                {
                                                    setSortByTotal(false);
                                                    setAscendingSort(!isAscendingSort);
                                                }
                                            }
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </TableCell>
                            {
                                specification.resourceNormFieldPath !== undefined &&
                                <TableCell
                                    align="center"
                                    style={{
                                        borderLeft: `1px solid ${grey[100]}`
                                    }}
                                    title={specification.resourceNormFieldPath.getName()}
                                >
                                    <LocalizedText
                                        code="Generic.Norm"
                                        value="Norm"
                                    />
                                </TableCell>
                            }
                            {
                                new Array(numberOfWeeksToShow).fill(0).map(
                                    (_, idx) =>
                                    {
                                        const date = add(startDate, { weeks: idx });

                                        return <TableCell
                                            key={idx}
                                            align="center"
                                            title={format(date, 'PPP', { locale: localizer.dateLocale })}
                                            colSpan={itemPlannerWeekLength}
                                            style={{
                                                borderLeft: `1px solid ${grey[100]}`
                                            }}
                                        >
                                            {getWeek(date)}<br />
                                            <span
                                                style={{
                                                    color: grey[400],
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {format(date, 'dd', { locale: localizer.dateLocale })}/{format(date, 'MM', { locale: localizer.dateLocale })}
                                            </span>
                                        </TableCell>;
                                    }
                                )
                            }
                            <TableCell
                                align="center"
                                style={{
                                    borderLeft: `1px solid ${grey[100]}`
                                }}
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={10}
                                    alignment="center"
                                >
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        <LocalizedText
                                            code="Generic.Total"
                                            value="Totaal"
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <SortIconButton
                                            isAscendingSort={isAscendingSort}
                                            color={isSortByTotal ? primaryColor : textSecondaryColor}
                                            onClick={
                                                () =>
                                                {
                                                    setSortByTotal(true);
                                                    setAscendingSort(!isAscendingSort);
                                                }
                                            }
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            sortedResources?.map(
                                resource =>
                                    <EmployeeAllocationEditorEmployeeRow
                                        specification={specification}
                                        employeeAllocationParameterAssignment={employeeAllocationFilterParameterAssignment}
                                        key={resource.uuid}
                                        resource={resource}
                                        startDate={startDate}
                                        items={employeeAllocationsByResource.get(resource)}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        numberOfWeeksToShow={numberOfWeeksToShow}
                                        total={totalByResource.get(resource) ?? 0}
                                        settingStorageKey={settingStorageKey}
                                    />
                            )
                        }
                    </TableBody>
                </Table>
                {
                    resourcesHasMore &&
                    <>
                        <Divider />
                        <HoverCardBottom
                            onClick={resourcesLoadMore}
                            disabled={resourcesIsLoading}
                        >
                            <LocalizedText
                                code="Generic.LoadMore"
                                value="Meer laden"
                            />...
                        </HoverCardBottom>
                    </>
                }
            </div>;
        }
    );
