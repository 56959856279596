import { ConstraintNode } from './ConstraintNode';
import { observable } from 'mobx';
import { Entity } from './Entity';
import { EntityEvent } from './EntityEvent';

export class BooleanConstraintNode extends ConstraintNode
{
    // ------------------- Persistent Properties --------------------

    @observable isTrue: boolean;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(isTrue: boolean)
    {
        super();

        this.isTrue = isTrue;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    hashCode(): string
    {
        return `${super.hashCode()},${this.isTrue ? '1' : '0'}`;
    }

    equals(node: BooleanConstraintNode): boolean
    {
        return super.equals(node)
            && this.isTrue === node.isTrue;
    }

    matches(entity: Entity): boolean
    {
        return this.isTrue;
    }

    isAffectedBy(event: EntityEvent): boolean
    {
        return false;
    }

    descriptor()
    {
        return {
            type: 'Boolean',
            isTrue: this.isTrue,
        };
    }

    // ----------------------- Private logic ------------------------
}
