import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { DefaultRelationshipEditor } from './DefaultRelationshipEditor/DefaultRelationshipEditor';
import useTypes from '../../Type/Api/useTypes';
import ProductLineToProductEditor from './Bespoke/ProductLineToProductEditor/ProductLineToProductEditor';
import { SelectboxProps } from '../../Selectbox/Selectbox';
import EntityToPackEditor from './Bespoke/EntityToPackEditor/EntityToPackEditor';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import EntityToPipelineEditor from './Bespoke/EntityToPipelineEditor/EntityToPipelineEditor';

export interface RelationshipEditorProps
{
    entity: Entity;
    relationshipDefinition: EntityRelationshipDefinition;
    isParent: boolean;
    commitContext?: CommitContext;
    doAutoCommit?: boolean;
    disableUnderline?: boolean;
    autoFocus?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    onChange?: (value: Entity | Entity[] | undefined) => void;
    onConstruct?: (entity: Entity, commitContext: CommitContext) => void;
    useParentCommitContextForConstruction?: boolean;
    selectboxProps?: Partial<SelectboxProps>;
    required?: boolean;
    compact?: boolean;
}

const RelationshipEditor: React.FC<RelationshipEditorProps> =
    props =>
    {
        const types = useTypes();

        if (props.isParent && props.relationshipDefinition === types.Pack.RelationshipDefinition.Entities)
        {
            return <EntityToPackEditor
                {...props}
            />;
        }
        else if (!props.isParent && props.relationshipDefinition === types.ProductLine.RelationshipDefinition.Product)
        {
            return <ProductLineToProductEditor
                {...props}
            />;
        }
        else if (!props.isParent && props.relationshipDefinition.childComputation === types.Pipeline.Type)
        {
            return <EntityToPipelineEditor
                {...props}
            />;
        }
        else
        {
            return <DefaultRelationshipEditor
                {...props}
            />;
        }
    };

export default observer(RelationshipEditor);
