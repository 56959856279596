import * as React from 'react';
import { useCallback, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { Loader } from '../Loader/Loader';
import { makeStyles } from '@material-ui/core';

const useStyles =
    makeStyles({
        root: {
            overflow: 'auto',
        },
        loader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    });

export interface InfiniteScrollerProps
{
    maxHeight?: string | number;
    loadMore: () => void;
    hasMore: boolean;
    loading?: boolean;
}

export const InfiniteScroller: React.FC<InfiniteScrollerProps> =
    ({
         maxHeight,
         loadMore,
         hasMore,
         loading,
         children,
     }) =>
    {
        const classes = useStyles();
        const [ ref, setRef ] = useState<HTMLDivElement | undefined>(undefined);
        const scroll =
            useCallback(
                () =>
                {
                    if (hasMore && ref)
                    {
                        let reactNode = ReactDOM.findDOMNode(ref) as HTMLElement;

                        if (reactNode)
                        {
                            if (reactNode.scrollHeight - (reactNode.scrollTop + reactNode.clientHeight) < 250)
                            {
                                if (!loading)
                                {
                                    loadMore();
                                }
                            }
                        }
                    }
                },
                [
                    hasMore,
                    ref,
                    loading,
                    loadMore,
                ]
            );

        return <div
            className={classes.root}
            style={{
                maxHeight: maxHeight ?? 'calc(100vh - 300px)',
            }}
            ref={setRef}
            onScroll={scroll}
        >
            {children}
            {
                loading &&
                <div
                    className={classes.loader}
                >
                    <Loader
                        inline
                    />
                </div>
            }
        </div>;
    };