import { reference, type } from '../../../@Util/Serialization/Serialization';
import { EntityField } from './EntityField';
import { observable } from 'mobx';
import { EntityEventFilter } from './EntityEventFilter';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { EntityEvent } from './EntityEvent';
import { EntityValueMutation } from './EntityValueMutation';
import { EntityValueCreationMutation } from './EntityValueCreationMutation';
import { EntityValueDeletionMutation } from './EntityValueDeletionMutation';
import { EntityValueUpdateMutation } from './EntityValueUpdateMutation';
import { Entity } from './Entity';
import localizedText from '../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

@type('FieldMutationFilter')
export class FieldMutationFilter extends EntityEventFilter
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'EntityField') @observable entityField: EntityField;
    @observable isCreation: boolean;
    @observable isMutation: boolean;
    @observable isDeletion: boolean;
    @observable isComputed?: boolean;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityPath: EntityPath,
                entityField?: EntityField,
                isCreation: boolean = true,
                isMutation: boolean = true,
                isDeletion: boolean = true,
                isComputed?: boolean)
    {
        super(entityPath);

        this.entityField = entityField;
        this.isCreation = isCreation;
        this.isMutation = isMutation;
        this.isDeletion = isDeletion;
        this.isComputed = isComputed;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    appliesTo(event: EntityEvent,
              rootEntity?: Entity): boolean
    {
        return event instanceof EntityValueMutation
            && (this.entityField === undefined || this.entityField === event.entityField)
            && ((this.isCreation && event instanceof EntityValueCreationMutation)
            || (this.isMutation && event instanceof EntityValueUpdateMutation)
            || (this.isDeletion && event instanceof EntityValueDeletionMutation))
            && (this.isComputed === undefined || event.entityField.isComputedField === this.isComputed)
            && super.appliesTo(event, rootEntity);
    }

    name(entityTypeStore: EntityTypeStore): string
    {
        if (this.entityField)
        {
            return `${this.entityField.getName(this.entityPath.entityType, entityTypeStore)} `
                + localizedText('Modifications', 'wijzigingen');
        }
        else
        {
            if (this.isComputed)
            {
                return `${localizedText('SystemModifications', 'Systeemwijzigingen')}`;
            }
            else
            {
                return `${localizedText('FieldModifications', 'Veldwijzigingen')}`;
            }
        }
    }

    descriptor(): any
    {
        return {
            type: 'FieldMutationFilter',
            entityPath: (this.entityPath as any).descriptor,
            entityFieldId: this.entityField ? this.entityField.id : undefined,
            isCreation: this.isCreation,
            isMutation: this.isMutation,
            isDeletion: this.isDeletion,
            isComputed: this.isComputed
        };
    }

    // ----------------------- Private logic ------------------------
}
