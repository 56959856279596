import { observable } from 'mobx';
import EntityValueType from '../../Automation/Value/Type/EntityValueType';
import Parameter from '../../Automation/Parameter/Parameter';
import { EntityFieldPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import PrimitiveValueType from '../../Automation/Value/Type/PrimitiveValueType';

export default class PortalDataSourceField
{
    // ------------------------- Properties -------------------------

    @observable.ref parameter: Parameter<any>;
    @observable.ref fieldPath: EntityFieldPath;

    // ------------------------ Constructor -------------------------

    constructor(parameter: Parameter<any>,
                fieldPath: EntityFieldPath)
    {
        this.parameter = parameter;
        this.fieldPath = fieldPath;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor()
    {
        return {
            id: this.parameter.id,
            fieldPath: this.fieldPath.descriptor
        };
    }

    static async fromDescriptor(descriptor: any)
    {
        const id = descriptor.id;
        const fieldPath = EntityFieldPath.construct(descriptor.fieldPath);
        const parameter = PortalDataSourceField.getParameter(id, fieldPath);

        return new PortalDataSourceField(
            parameter,
            fieldPath);
    }

    static getParameter(id: string,
                        fieldPath: EntityFieldPath)
    {
        return new Parameter(
            id,
            fieldPath.isField
                ?
                    new PrimitiveValueType(fieldPath.field.dataObjectSpecification.type)
                :
                    new EntityValueType(fieldPath.path.entityType),
            false,
            fieldPath.getPathName(true));
            // `${fieldPath.path.rootEntityType.getName()}: ${fieldPath.getName()}`);
    }

    // ----------------------- Private logic ------------------------
}
