import { DataObjectBespokeViewerStore } from '../../Viewer/DataObjectBespokeViewerStore';
import { action, computed, observable } from 'mobx';
import { FileViewerLightboxStore } from '../../../../Generic/FileViewer/Lightbox/FileViewerLightboxStore';
import { FileValue } from './FileValue';
import { SupportedPreviewImageMimeTypes } from './FileUtils';
import { downloadApiFile } from '../../../../../@Service/ApiClient/Hooks/downloadApiFile';

export class FileViewStore extends DataObjectBespokeViewerStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable isOpen: boolean;

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get fileValue(): FileValue
    {
        return this.baseStore.dataObject.value;
    }

    @computed
    get fileViewerLightboxStore(): FileViewerLightboxStore
    {
        if (this.isOpen)
        {
            return new FileViewerLightboxStore(
                this.baseStore.dataObject.context.getFileUrl(this.fileValue.url),
                this.fileValue.name,
                this.fileValue.mime,
                this.closeLightbox);
        }

        return undefined;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    handleClick()
    {
        if (this.fileValue
            && SupportedPreviewImageMimeTypes.has(this.fileValue.mime.toLowerCase()))
        {
            this.showLightbox();
        }
        else
        {
            return downloadApiFile(
                this.baseStore.dataObject.context.getFileUrl(this.fileValue.url)
            );
        }
    }

    @action.bound
    showLightbox()
    {
        this.isOpen = true;
    }

    @action.bound
    closeLightbox()
    {
        this.isOpen = false;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
