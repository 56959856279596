import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';
import useWorkflowState from '../../Api/useWorkflowState';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';

export default function useSelectedPipelineStateIndex(entity: Entity,
                                                      states: Entity[])
{
    const workflowState = useWorkflowState(entity);

    return useComputed(
        () =>
            states.findIndex(
                state =>
                    equalsEntity(workflowState, state)),
        [
            states,
            workflowState
        ]);
}
