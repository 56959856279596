import React from 'react';
import { observer } from 'mobx-react-lite';
import { EntityEvent } from '../../../../../../@Api/Model/Implementation/EntityEvent';
import { EntityRelationshipMutation } from '../../../../../../@Api/Model/Implementation/EntityRelationshipMutation';
import Relationship from './Relationship/Relationship';
import { EntityValueMutation } from '../../../../../../@Api/Model/Implementation/EntityValueMutation';
import Value from './Value/Value';
import { EntityMutation } from '../../../../../../@Api/Model/Implementation/EntityMutation';
import Entity from './Entity/Entity';

export interface DescriptionProps
{
    event: EntityEvent;
    forceEntityDescription?: boolean;
}

const Description: React.FC<DescriptionProps> =
    props =>
    {
        if (props.forceEntityDescription)
        {
            return <Entity
                event={props.event}
            />;
        }
        else if (props.event instanceof EntityValueMutation)
        {
            return <Value
                event={props.event}
            />;
        }
        else if (props.event instanceof EntityRelationshipMutation)
        {
            return <Relationship
                event={props.event}
            />;
        }
        else if (props.event instanceof EntityMutation)
        {
            return <Entity
                event={props.event}
            />;
        }
    };

export default observer(Description);
