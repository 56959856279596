import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import FieldPathSelector from '../../../Path/FieldPathEditor/FieldPathSelector';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import ExpressionEditorContext from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Expression/ExpressionEditorContext';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import EntityTypeContext from '../../../Type/EntityTypeContext';
import useSwitch from '../../../../../../@Util/Switch/useSwitch';

export interface EntityImageProps
{
    parameterId?: string;
    fieldPath: EntityFieldPath,
    onSelect: (path: EntityFieldPath, parameter?: string) => void;
}

const EntityImage: React.FC<EntityImageProps> =
    props =>
    {
        const entityTypeStore = useContext(EntityTypeContext);
        const expressionContext = useContext(ExpressionEditorContext);
        const [ isOpen, open, close ] = useSwitch(false);

        const select =
            useCallback(
                (fieldPath: EntityFieldPath, parameter?: string) =>
                {
                    close();
                    props.onSelect(fieldPath, parameter);
                },
                [
                    close,
                    props.onSelect
                ]);

        return <div>
            <ViewGroup
                orientation="vertical"
                spacing={10}
                alignment="center"
                justification="center"
            >
                <ViewGroupItem>
                    <MenuButton
                        icon="image"
                        tooltip="Veld kiezen"
                        iconSize={50}
                        onClick={open}
                        onClose={close}
                        open={isOpen}
                    >
                        <FieldPathSelector
                            parameterId={props.parameterId}
                            value={props.fieldPath}
                            context={expressionContext.entityContext}
                            onSelect={select}
                        />
                    </MenuButton>
                </ViewGroupItem>
                {
                    props.fieldPath &&
                        <ViewGroupItem>
                            {
                                props.fieldPath.getName(entityTypeStore)
                            }
                        </ViewGroupItem>
                }
            </ViewGroup>
        </div>;
    };

export default observer(EntityImage);
