import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { DataObjectRepresentationProps } from '../../../DataObject/Model/DataObjectRepresentation';
import { EntityField } from '../../../../../@Api/Model/Implementation/EntityField';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';

export class BespokeAddress extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Address')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getRepresentation(
        entity: Entity,
        field: EntityField
    ): DataObjectRepresentationProps
    {
        if (field === this.types.Address.Field.HouseNr)
        {
            return {
                ...super.getRepresentation(entity, field),
                disableThousandSeparator: true
            };
        }
        else
        {
            return super.getRepresentation(entity, field);
        }
    }

    // ----------------------- Private logic ------------------------
}
