import React, { useCallback, useMemo } from 'react';
import { default as MuiAvatar } from '@material-ui/core/Avatar';
import Icon from '../Icon/Icon';
import { ColorUtils } from '../../../../@Util/Color/ColorUtils';
import { isUnloadableSrc, reportUnloadableSrc } from '../../../../@Api/Image/isImageSrcInvalid';
import { useComputed } from 'mobx-react-lite';

export interface AvatarProps
{
    src?: string;
    characters?: string;
    icon?: string;
    iconColor?: string;
    iconSize?: number;
    color?: string;
    outlined?: boolean;
    size?: number;
    className?: string;
    border?: boolean;
    borderColor?: string;
    borderWidth?: number;
}

const iconFactor = 17 / 30;

const Avatar: React.FC<AvatarProps> =
    props =>
    {
        const isSrcValid =
            useComputed(
                () =>
                    !isUnloadableSrc(props.src),
                [
                    props.src
                ]);

        const onSrcError =
            useCallback(
                () =>
                {
                    // Note that onSrcError is not always called by HTML when an image is not loadable (especially if the image is loaded multiple times on the same page),
                    // so we use a global repository
                    reportUnloadableSrc(props.src);
                },
                [
                    props.src
                ]);

        const src =
            useMemo(
                () =>
                {
                    if (isSrcValid)
                    {
                        return props.src;
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    isSrcValid,
                    props.src
                ]);

        const hasCharacters =
            useMemo(
                () =>
                    props.characters && props.characters.length > 0,
                [
                    props.characters
                ]);

        const color =
            useMemo(
                () =>
                    hasCharacters
                    ? ColorUtils.colorOfString(props.characters)['500']
                    : props.color,
                [
                    props.color,
                    props.characters,
                    hasCharacters
                ]);

        const avatarStyle =
            useMemo(
                () => ({
                    backgroundColor:
                        props.outlined || isSrcValid
                            ?
                                'transparent'
                            :
                                color,
                    border: props.border ? `${props.borderWidth || 1.5}px solid ${props.borderColor || color}` : undefined,
                    width: props.size,
                    height: props.size
                }),
                [
                    isSrcValid,
                    props.outlined,
                    props.border,
                    props.borderWidth,
                    props.borderColor,
                    props.size,
                    color
                ]);

        const imgProps =
            useMemo(
                () => ({
                    onError: onSrcError
                }),
                [
                    onSrcError
                ]);

        return <MuiAvatar
            src={src}
            imgProps={imgProps}
            style={avatarStyle}
            className={props.className}
        >
            {
                !src &&
                props.icon &&
                !hasCharacters &&
                    <Icon
                        icon={props.icon}
                        color={
                            props.outlined
                                ?
                                    color
                                :
                                    props.iconColor}
                        size={
                            props.iconSize
                                ?
                                    props.iconSize
                                :
                                    props.size
                                        ?
                                            props.size * iconFactor
                                        :
                                            undefined}
                        outlined={props.outlined}
                    >
                        {props.icon}
                    </Icon>
            }
            {
                !src &&
                    hasCharacters &&
                        <span
                            style={{
                                color:
                                    props.outlined
                                        ?
                                            color
                                        :
                                            undefined,
                                fontSize:
                                    props.size
                                        ?
                                            props.size / 1.8
                                        :
                                            undefined
                            }}
                        >
                            {props.characters}
                        </span>
            }
            {props.children}
        </MuiAvatar>;
    };

export default Avatar;
