import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';
import SaveButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import CancelButton from '../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import NoteTextEditor from './TextEditor/NoteTextEditor';
import makeStyles from '@material-ui/core/styles/makeStyles';

export interface NoteCreatorProps
{
    entity: Entity;
    showAddButton?: boolean;
    note?: string;
    white?: boolean;
    autoFocus?: boolean;
    noteSaved?: () => Promise<any>;
    onSave?: (entity: Entity) => void;
    onClose?: () => void;
    commitContext?: CommitContext;
}

const useStyles =
    makeStyles({
        buttons: {
            paddingTop: 16
        }
    })

const NoteEditor: React.FC<NoteCreatorProps> =
    props =>
    {
        const classes = useStyles();
        const types = useTypes();
        const note =
            useComputed(
                () =>
                    props.entity.getObjectValueByField(
                        types.Note.Field.Content,
                        props.commitContext
                    ),
                [
                    props.entity,
                    types,
                    props.commitContext,
                ]);
        const setNote =
            useCallback(
                (note: string) =>
                    setValueByFieldInEntity(
                        props.entity,
                        types.Note.Field.Content,
                        note,
                        props.commitContext
                    ),
                [
                    props.entity,
                    types,
                    props.commitContext,
                ]);
        const saveNote =
            useCallback(
                () =>
                {
                    return commitEntityWithContext(
                        props.entity,
                        props.commitContext,
                        {
                            isForced: true,
                        }
                    ).then(
                        () =>
                        {
                            if (props.onSave)
                            {
                                props.onSave(props.entity);
                            }

                            if (props.onClose)
                            {
                                props.onClose();
                            }
                        }
                    );
                },
                [
                    props.entity,
                    props.onSave,
                    props.onClose,
                    props.commitContext
                ]);
        const onKeyDownCallback =
            useCallback(
                (event) =>
                {
                    // Ctrl Enter pressed
                    if (event.ctrlKey && event.keyCode === 13)
                    {
                        saveNote()
                    }
                },
                [
                    saveNote
                ]);

        return <NoteTextEditor
            value={note ?? props.note}
            onChange={setNote}
            onKeyDown={onKeyDownCallback}
            white={props.white}
            autoFocus={props.autoFocus}
        >
            {
                props.showAddButton &&
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                    className={classes.buttons}
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        {props.children}
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <CancelButton
                            onClick={props.onClose}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <SaveButton
                            onClick={saveNote}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            }
        </NoteTextEditor>;
    };

NoteEditor.defaultProps =
{
    showAddButton: true,
    white: false,
    autoFocus: false,
    note: ''
};

export default observer(NoteEditor);
