import { BespokeEntityType } from '../../../BespokeEntityType';
import { EntityTypeStore } from '../../../EntityTypeStore';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationshipDefinition } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { updateRelationship } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { EntityExpansionBuilder } from '../../../../Selection/Builder/EntityExpansionBuilder';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { constructEntityOfType } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { FileValue } from '../../../../../DataObject/Type/File/FileValue';
import { fetchApiFile } from '../../../../../../../@Service/ApiClient/Hooks/fetchApiFile';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/index';
import { ApiClient } from '../../../../../../../@Service/ApiClient/ApiClient';

export class BespokeActivityCourse extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Activity.Course')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        // In case of linking a new course to a template, then: copy all template files (including files) into the course
        if (entity.isNew()
            && !isParent
            && relationshipDefinition === this.types.Activity.Course.RelationshipDefinition.CourseTemplate
            && relatedEntity)
        {
            ([
                {
                    from: this.types.CourseTemplate.Field.NumberOfLessons,
                    to: this.types.Activity.Course.Field.NumberOfLessons,
                },
                {
                    from: this.types.CourseTemplate.Field.LessonDuration,
                    to: this.types.Activity.Course.Field.LessonDuration,
                },
                {
                    from: this.types.CourseTemplate.Field.LessonCronPattern,
                    to: this.types.Activity.Course.Field.LessonCronPattern,
                },
                {
                    from: this.types.CourseTemplate.Field.Price,
                    to: this.types.Activity.Course.Field.Price,
                },
                {
                    from: this.types.CourseTemplate.Field.MaxNumberOfAttendees,
                    to: this.types.Activity.Course.Field.MaxNumberOfAttendees,
                },
            ])
                .forEach(
                    ({ from, to }) =>
                        setValueByFieldInEntity(
                            entity,
                            to,
                            relatedEntity.getObjectValueByField(
                                from,
                                commitContext
                            ),
                            commitContext
                        )
                );

            const relationshipsToCopy =  [
                {
                    from: this.types.CourseTemplate.RelationshipDefinition.CourseLocation,
                    to: this.types.Activity.Course.RelationshipDefinition.CourseLocation,
                },
                {
                    from: this.types.CourseTemplate.RelationshipDefinition.CourseInstructor,
                    to: this.types.Activity.Course.RelationshipDefinition.CourseInstructor,
                },
            ];

            const expansionBuilder =
                new EntityExpansionBuilder(
                    relatedEntity.entityType,
                    [ relatedEntity ],
                    [
                        ...relationshipsToCopy.map(
                            relationshipToCopy =>
                                relationshipToCopy.from
                        ),
                        this.types.CourseTemplate.RelationshipDefinition.CourseFiles
                    ].map(
                        relationshipDefinition =>
                            EntityPath.fromEntity(relatedEntity)
                                .joinTo(
                                    relationshipDefinition,
                                    false
                                )
                    )
                );
            await expansionBuilder.expand();

            relationshipsToCopy
                .forEach(
                    ({ from, to }) =>
                        updateRelationship(
                            entity,
                            false,
                            to,
                            relatedEntity.getRelatedEntityByDefinition(
                                false,
                                from,
                                commitContext
                            ),
                            commitContext
                        )
                );

            const courseFilesToCopy =
                relatedEntity.getRelatedEntitiesByDefinition(
                    false,
                    this.types.CourseTemplate.RelationshipDefinition.CourseFiles
                );

            for (const courseFile of courseFilesToCopy)
            {
                const copiedCourseFile =
                    constructEntityOfType(
                        courseFile.entityType,
                        commitContext
                    );
                setValueByFieldInEntity(
                    copiedCourseFile,
                    this.types.CourseFile.Field.Name,
                    courseFile.getObjectValueByField(this.types.CourseFile.Field.Name),
                    commitContext
                );

                const fileEntityValue = courseFile.getValueByField(this.types.CourseFile.Field.File);

                if (fileEntityValue !== undefined)
                {
                    const fileUrl = this.entityTypeStore.getFileUrl(fileEntityValue);
                    const { blob, dispose } =
                        await fetchApiFile(
                            loadModuleDirectly(ApiClient),
                            fileUrl
                        );

                    setValueByFieldInEntity(
                        copiedCourseFile,
                        this.types.CourseFile.Field.File,
                        FileValue.fromBlob(
                            blob,
                            (fileEntityValue.dataObject.value as FileValue).name
                        )
                    );

                    dispose();
                }

                updateRelationship(
                    copiedCourseFile,
                    true,
                    this.types.Activity.Course.RelationshipDefinition.CourseFiles,
                    entity,
                    commitContext
                );
            }

            expansionBuilder.dispose();
        }
    }

    // ----------------------- Private logic ------------------------
}
