import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import getPredicateFromDescriptor from '../../../../../../../../@Api/Automation/Api/getPredicateFromDescriptor';
import FunctionContext from '../../../../../../../../@Api/Automation/Function/FunctionContext';
import Centered from '../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../@Future/Component/Generic/Loader/Loader';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import PredicateEditor from '../../../../../../Entity/Viewer/Content/Automation/Editor/Predicate/PredicateEditor';
import CompositePredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../../../DataObject/Model/LogicalOperator';
import RightAlignedButtonGroup from '../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import ComparisonPredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../../../../../DataObject/Model/Comparator';
import CancelButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import DeleteButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';
import getFieldGroupPredicateParameterDictionary from '../../../../../../../../@Api/Metadata/FieldGroup/Predicate/getFieldGroupPredicateParameterDictionary';
import { EntityParameterId } from '../../../../../../../../@Api/Metadata/FieldGroup/Predicate/FieldGroupPredicateConstants';
import { FieldGroupPredicateButtonProps } from '../PredicateEditor/FieldGroupPredicateButton';
import { setFieldGroupExpandedPredicate } from '../../../../../../../../@Api/Metadata/FieldGroup/setFieldGroupExpandedPredicate';

export interface FieldGroupExpansionPredicateEditorProps extends FieldGroupPredicateButtonProps
{
    predicate: any;
    onClose: () => void;
}

const FieldGroupExpansionPredicateEditor: React.FC<FieldGroupExpansionPredicateEditorProps> =
    props =>
    {
        const { fieldGroup, onClose } = props;
        const predicateDescriptor = props.predicate;

        const predicateContext =
            useMemo(
                () =>
                    new FunctionContext(
                        getFieldGroupPredicateParameterDictionary(
                            fieldGroup)),
                [
                    fieldGroup
                ]);

        const [ predicate, isLoading ] =
            useAsyncResult(
                async () =>
                {
                    if (predicateDescriptor)
                    {
                        return getPredicateFromDescriptor(
                            predicateDescriptor,
                            predicateContext);
                    }
                    else
                    {
                        return new CompositePredicate(
                            LogicalOperator.And,
                            []);
                    }
                },
                [
                    predicateDescriptor,
                    predicateContext
                ]);

        const dummyOnChange =
            useCallback(
                () => {},
                []);

        const clear =
            useCallback(
                () =>
                {
                    setFieldGroupExpandedPredicate(fieldGroup, undefined)
                         .then(onClose);
                },
                [
                    fieldGroup,
                    onClose
                ]);

        const save =
            useCallback(
                () =>
                {
                    setFieldGroupExpandedPredicate(fieldGroup, predicate?.toDescriptor())
                         .then(onClose);
                },
                [
                    fieldGroup,
                    predicate,
                    onClose
                ]);

        const comparisonConstructor =
            useCallback(
                () =>
                    new ComparisonPredicate(
                        new ValueFromEntityComputation(
                            predicateContext.parameterDictionary.getParameterById(EntityParameterId),
                            undefined),
                        Comparator.Equals,
                        undefined),
                [
                    predicateContext
                ]);

        if (isLoading)
        {
            return <Centered
                horizontal
            >
                <Loader />
            </Centered>;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <PredicateEditor
                        value={predicate}
                        onChange={dummyOnChange}
                        context={predicateContext}
                        comparisonConstructor={comparisonConstructor}
                        comparisonLhsTypeFixed
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <RightAlignedButtonGroup>
                        <CancelButton
                            onClick={props.onClose}
                        />
                        <DeleteButton
                            onClick={clear}
                        />
                        <SaveButton
                            onClick={save}
                        />
                    </RightAlignedButtonGroup>
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

export default observer(FieldGroupExpansionPredicateEditor);
