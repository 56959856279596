import ParameterAssignment from '../Parameter/ParameterAssignment';
import Dependency from '../Parameter/Dependency';
import initializeDependencies from './initializeDependencies';
import { groupBy } from '../../../@Util/MapUtils/groupBy';
import { Disposer } from '../../../@Util/Disposable/Disposer';

export async function initializeParameterAssignment(
    parameterAssignment: ParameterAssignment,
    dependencies: Dependency[]
): Promise<Disposer>
{
    const dependenciesByParameter =
        groupBy(
            dependencies,
            dependency =>
                dependency.parameter
        );

    const disposers =
        await Promise.all(
            Array.from(parameterAssignment.valueByParameter.entries())
                .map(
                    ([ parameter, value ]) =>
                        initializeDependencies(
                            parameter,
                            [
                                value
                            ],
                            dependenciesByParameter.get(parameter) ?? []
                        )
                )
        );

    return () =>
        disposers.forEach(
            disposer =>
                disposer()
        );
}
