import Role, { Privilege, RoleType } from '../../../Model/Role';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { getEntityTypePermission } from './getEntityTypePermission';

export function isEntityTypePermissionDisabled(
    rolesByType: Map<RoleType, Role>,
    role: Role,
    privilege: Privilege,
    entityType: EntityType
): boolean
{
    if (privilege === 'canExport' && !role.isExportingEnabled())
    {
        return true;
    }

    const administratorBaseRole = rolesByType.get('AdministratorBaseRole');

    if (administratorBaseRole.rightByType.get(entityType)?.getPermission(privilege) === 'Denied')
    {
        return true;
    }

    switch (privilege)
    {
        case 'canUpdate':
        case 'canDelete':
        case 'canExport':
            return getEntityTypePermission(rolesByType, role, 'canRead', entityType) === 'Denied'
        default:
            return false;
    }
}