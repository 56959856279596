import React from 'react';
import { observer } from 'mobx-react-lite';
import useMarkAllAsRead from '../Api/useMarkAllAsRead';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import PrimaryTextButton from '../../../../../../@Future/Component/Generic/Button/Variant/PrimaryTextButton/PrimaryTextButton';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface MarkAllAsReadButtonButtonProps
{
    notifications: Entity[];
}

const MarkAllAsReadButton: React.FC<MarkAllAsReadButtonButtonProps> =
    props =>
    {
        const markAllAsRead = useMarkAllAsRead();

        return <PrimaryTextButton
            label={
                <LocalizedText
                    code="Notification.Drawer.MarkAllAsRead"
                    value="Markeer alles als gelezen"
                />
            }
            onClick={markAllAsRead}
        />;
    };

export default observer(MarkAllAsReadButton);
