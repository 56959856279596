import { Entity } from '../Model/Implementation/Entity';
import { commit, isTransactionalModel } from '../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { EntityEvent } from '../Model/Implementation/EntityEvent';
import { EntityMatchResult } from '../../@Component/Service/Entity/EntityMatchResult';
import { loadModuleDirectly } from '../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityController } from '../Controller/Directory/EntityController';
import { EntityCacheService } from '../../@Component/Service/Entity/EntityCacheService';
import localizeText from '../Localization/localizeText';
import { FeedbackStore } from '../../@Component/App/Root/Environment/Organization/Feedback/FeedbackStore';
import { handleCommitError, postCommitModel, synchronizeCommitFunction } from './Commit/commitEntity';
import uuid from '../../@Util/Id/uuid';
import { EntityDeletionMutation } from '../Model/Implementation/EntityDeletionMutation';
import { CommitContext } from './Commit/Context/CommitContext';
import { createTransactionalModelIfCommitContextIsAbsent } from './Commit/Context/Api/Compatibility/createTransactionalModelIfCommitContextIsAbsent';

export default function deleteEntity(entity: Entity,
                                     commitContext: CommitContext = entity.getCommitContext())
{
    return synchronouslyDeleteEntity(entity, commitContext);
}

async function synchronouslyDeleteEntity(entity: Entity,
                                         commitContext?: CommitContext)
{
    return synchronizeCommitFunction(
        entity,
        () =>
            performEntityDeletion(
                createTransactionalModelIfCommitContextIsAbsent(
                    entity,
                    commitContext
                ),
                commitContext
            )
    );
}

async function performEntityDeletion(entity: Entity,
                                     commitContext?: CommitContext)
{
    try
    {
        const events = await deleteInApi(entity, commitContext);
        const preCommitResult = await preCommitModel(entity, events);
        await commitModelIfNecessary(entity);
        maybeShowDeleteNotification(entity, events);
        await postCommitModel(
            {
                id: uuid(),
                descriptor: undefined,
                events: [ new EntityDeletionMutation(entity) ],
                files: new Map(),
            },
            events,
            preCommitResult);
    }
    catch (error)
    {
        return handleCommitError(entity, error);
    }
}

async function deleteInApi(entity: Entity,
                           commitContext?: CommitContext): Promise<EntityEvent[]>
{
    if (entity.isNew())
    {
        if (commitContext)
        {
            commitContext.deleteEntity(entity);
        }
        else
        {
            entity.deleteEntity();
        }

        return Promise.resolve([]);
    }
    else
    {
        if (commitContext)
        {
            commitContext.deleteEntity(entity);
            const result = await commitContext.commit();
            return result.events;
        }
        else
        {
            return loadModuleDirectly(EntityController).deleteEntity(entity.id);
        }
    }
}

async function preCommitModel(entity: Entity,
                              events: EntityEvent[]): Promise<EntityMatchResult[]>
{
    return loadModuleDirectly(EntityCacheService).prematchEvents(events);
}

async function commitModelIfNecessary(entity: Entity)
{
    if (isTransactionalModel(entity))
    {
        return commit(entity);
    }
}

function maybeShowDeleteNotification(entity: Entity,
                                   events: EntityEvent[])
{
    if (shouldShowDeleteNotification(entity, events))
    {
        showDeleteNotification(entity);
    }
}

function shouldShowDeleteNotification(entity: Entity,
                                      events: EntityEvent[])
{
    return events.length > 0
        && entity.entityType.bespoke.showDeleteNotification(entity);
}

function showDeleteNotification(entity: Entity)
{
    loadModuleDirectly(FeedbackStore)
        .enqueueSnackbar(
            localizeText(
                'Generic.ResourceDeleted',
                '${resource} verwijderd',
                {
                    resource: entity.entityType.getName()
                }),
            {
                variant: 'success',
                autoHideDuration: 1500
            });
}
