import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { ChartStore } from './ChartStore';
import { Theme, withStyles } from '@material-ui/core';
import BarChart from './Type/BarChart';
import PipelineChart from './Type/PipelineChart';
import LineChart from './Type/LineChart';
import PieChart from './Type/PieChart';
import FunnelChart from './Type/FunnelChart';

const styles =
    (theme: Theme) =>
        ({

        });

@observer
class Chart extends BaseComponent<ChartStore>
{
    renderComponent(store: ChartStore): JSX.Element
    {
        return this.renderChart(store);
    }

    renderChart(store: ChartStore): JSX.Element
    {
        switch (store.type)
        {
            case 'bar':
                return <BarChart
                    store={store}
                />;

            case 'pipeline':
                return <PipelineChart
                    store={store}
                />;

            case 'line':
                return <LineChart
                    store={store}
                />;

            case 'pie':
                return <PieChart
                    store={store}
                />;

            case 'funnel':
                return <FunnelChart
                    store={store}
                />;

            default:
                return null;
        }
    }
}

export default withStyles(styles)(Chart as any);
