import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import PortalSwitcherTabBarLayout from '../../../../../@Api/Layout/Type/PortalSwitcherTabBarLayout';
import TabBar from '../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import useTypes from '../../../Entity/Type/Api/useTypes';
import Card from '../../../../../@Future/Component/Generic/Card/Card';

export interface PortalSwitcherTabBarLayoutEditorProps extends LayoutEditorProps<PortalSwitcherTabBarLayout>
{

}

const PortalSwitcherTabBarLayoutEditor: React.FC<PortalSwitcherTabBarLayoutEditorProps> =
    props =>
    {
        const types = useTypes();
        const [ tab, setTab ] = useState(0);

        return <Card
            square
        >
            <TabBar
                value={tab}
            >
                <Tab
                    value={0}
                    onClick={setTab}
                >
                    {types.Portal.Type.getName()} 1
                </Tab>
                <Tab
                    value={1}
                    onClick={setTab}
                >
                    {types.Portal.Type.getName()} 2
                </Tab>
                <Tab
                    value={2}
                    onClick={setTab}
                >
                    {types.Portal.Type.getName()} 3
                </Tab>
            </TabBar>
        </Card>;
    };

export default observer(PortalSwitcherTabBarLayoutEditor);
