import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { ViewType } from './ViewType';
import Specification from './Specification';
import { computed, observable } from 'mobx';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { PredicateTypeStore } from '../../../Predicate/PredicateTypeStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import uuid from '../../../../../@Util/Id/uuid';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import getNewViewPredicateFromOldPredicate from '../Api/getNewViewPredicateFromOldPredicate';
import getViewParameters from '../Api/getViewParameters';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import getPredicateFromDescriptor from '../../../../../@Api/Automation/Api/getPredicateFromDescriptor';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import { ViewParams } from './ViewParams';

export default class View
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable type: ViewType;
    @observable _name: string;
    @observable.ref entityType: EntityType;
    @observable.ref parameters: ParameterDictionary;
    @observable.ref filter?: Predicate;
    @observable.ref specification: Specification;
    @observable.ref entity?: Entity;

    // ------------------------ Constructor -------------------------

    constructor(type: ViewType,
                name: string,
                entityType: EntityType,
                parameters: ParameterDictionary,
                filter: Predicate,
                specification: Specification,
                entity?: Entity)
    {
        this.id = uuid();
        this.type = type;
        this.name = name;
        this.entityType = entityType;
        this.parameters = parameters;
        this.filter = filter;
        this.specification = specification;
        this.entity = entity;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    @computed
    get name(): string
    {
        return this.entity?.name || this._name;
    }

    set name(value: string)
    {
        if (this.entity)
        {
            this.entity.setName(value);
        }
        else
        {
            this._name = value;
        }
    }

    isValid(): boolean
    {
        return (this.filter ? this.filter.isValid() : true)
            && this.specification.isValid();
    }

    get parameter(): Parameter<EntityValueType>
    {
        return this.parameters.getParameterById(ViewParams.Entity);
    }

    static async fromDescriptor(
        descriptor: any,
        entity?: Entity
    )
    {
        const entityType = loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.entityTypeId);
        const parameters = getViewParameters(entityType);
        let filter: Predicate;

        if (descriptor.filter)
        {
            if (descriptor.isFilterConverted)
            {
                filter =
                    await getPredicateFromDescriptor(
                        descriptor.filter,
                        new AutomationDependencyContext(parameters));
            }
            else
            {
                const oldFilter = loadModuleDirectly(PredicateTypeStore).fromSpecification(descriptor.filter);

                if (oldFilter)
                {
                    filter =
                        await getNewViewPredicateFromOldPredicate(
                            parameters,
                            oldFilter);
                }
            }
        }

        return new View(
            descriptor.type,
            descriptor.name,
            entityType,
            parameters,
            filter,
            await Specification.fromDescriptor(
                descriptor.specification,
                parameters
            ),
            entity);
    }

    toDescriptor(includeColumnFilters: boolean = false)
    {
        return {
            type: this.type,
            name: this.name,
            entityTypeId: this.entityType.id,
            filter: this.filter?.toDescriptor(),
            isFilterConverted: true,
            specification: this.specification.toDescriptor(includeColumnFilters)
        };
    }

    // ----------------------- Private logic ------------------------
}
