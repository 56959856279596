import React from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../Type/Api/useTypes';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MenuButton from '../../../../../../Item/MenuButton/MenuButton';
import styles from './Invoice.module.scss';
import FieldView from '../../../../../../Field/View/FieldView';
import useToggle from '../../../../../../../../../@Util/Toggle/useToggle';
import More from '../../../../../../Item/Navigator/More/More';
import { classNames } from '../../../../../../../../../@Future/Util/Class/classNames';
import useEntityValue from '../../../../../../../../../@Api/Entity/Hooks/useEntityValue';

export interface InvoiceProps
{
    entity: Entity;
}

const Invoice: React.FC<InvoiceProps> =
    ({
        entity,
     }) =>
    {
        const types = useTypes();
        const [ isOpen, toggleOpen ] = useToggle(false);

        const currency =
            useEntityValue<string>(
                entity,
                types.Activity.Field.Currency);

        return <>
            <TableRow
                className={classNames(styles.root, isOpen && styles.open)}
                onClick={() => toggleOpen()}
            >
                <TableCell>
                    <FieldView
                        entity={entity}
                        field={types.Activity.Field.Number}
                    />
                </TableCell>
                <TableCell>
                    <FieldView
                        entity={entity}
                        field={
                            currency
                                ? types.Activity.Field.TotalSalesExcludingVatInCurrency
                                : types.Activity.Field.TotalSalesExcludingVat
                        }
                    />
                </TableCell>
                <TableCell>
                    <FieldView
                        entity={entity}
                        field={types.Activity.Invoice.Field.PaymentTermInDays}
                    />
                </TableCell>
                <TableCell>
                    <FieldView
                        entity={entity}
                        field={types.Activity.Invoice.RelationshipDefinition.Phase}
                    />
                </TableCell>
                <TableCell>
                    <MenuButton
                        entity={entity}
                    />
                </TableCell>
            </TableRow>
            {
                isOpen &&
                    <TableRow>
                        <TableCell
                            colSpan={5}
                            padding="none"
                        >
                            <More
                                entity={entity}
                                disableInset
                            />
                        </TableCell>
                    </TableRow>
            }
        </>;
    };

export default observer(Invoice);
