import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import FileSpecificationContext from '../../../Context/FileSpecificationContext';
import StaticSelectbox from '../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export interface SourceFieldSelectorProps
{
    sourceFieldId: string;
    onChange: (sourceFieldId?: string) => void;
}

const SourceFieldSelector: React.FC<SourceFieldSelectorProps> =
    props =>
    {
        const fileSpecification = useContext(FileSpecificationContext);
        const sourceFieldOptions =
            useMemo(
                () =>
                    (fileSpecification ? fileSpecification.fields : [])
                        .map(
                            sourceField =>
                                ({
                                    id: `SourceField.${sourceField.id}`,
                                    label: localizeText(
                                        'Import.SourceFieldSelector.ColumnLabel',
                                        'Kolom: ${name}',
                                        {
                                            name: sourceField.name
                                        }),
                                    value: sourceField.id
                                })),
                [
                    fileSpecification
                ]);

        return <StaticSelectbox
            options={sourceFieldOptions}
            value={props.sourceFieldId}
            onChange={props.onChange}
        />;
    };

export default observer(SourceFieldSelector);
