import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';

export function isMileageRegistrationVisible(
    mileageRegistration: Entity,
    filter?: (mileageRegistration: Entity) => boolean
)
{
    return !mileageRegistration.isDeleted
        && (filter === undefined ? true : filter(mileageRegistration));
}
