import React from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import { SelectionCursor } from '../SelectionCursor';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import InternalList from '../../../Dataset/Segment/List/InternalList/InternalList';
import FilterContext from '../../../Dataset/Segment/FilterContext/FilterContext';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CloseIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/CloseIconButton/CloseIconButton';
import styles from './SelectionCursorBrowser.module.scss';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import Dataset from '../../../Dataset/Dataset';
import OnOpenEntityContext from '../../../Dataset/Context/OnOpenEntityContext';
import HighlightedEntityContext from '../../../Dataset/Context/HighlightedEntityContext';

export interface SelectionCursorHandleProps
{
    entity: Entity;
    cursor: SelectionCursor;
    onClose: () => void;
    onOpen: (entity: Entity) => void;
    highlightedEntity?: Entity;
}

const SelectionCursorBrowser: React.FC<SelectionCursorHandleProps> =
    props =>
    {
        const dividerGlue = useDividerGlue();

        if (props.cursor.dataset)
            return <Dataset
                id={props.cursor.datasetId}
                dataset={props.cursor.dataset}
                name={props.cursor.dataset.entity?.name}
                onClose={props.onClose}
                highlightedEntity={props.highlightedEntity}
                onOpenEntity={props.onOpen}
            />;

        return <Card
            classes={{
                root: styles.root
            }}
        >
            <ViewGroup
                orientation="vertical"
                spacing={0}
                className={styles.container}
                glue={dividerGlue}
            >
                <ViewGroupItem>
                    <CardInset>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                            alignment="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <CardHeader>
                                    {props.cursor.name}
                                </CardHeader>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <CloseIconButton
                                    onClick={props.onClose}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </CardInset>
                </ViewGroupItem>
                <ViewGroupItem
                    className={styles.list}
                    ratio={1}
                >
                    <FilterContext.Provider
                        value={props.cursor.filter}
                    >
                        <HighlightedEntityContext.Provider
                            value={props.highlightedEntity}
                        >
                            <OnOpenEntityContext.Provider
                                value={props.onOpen}
                            >
                                <InternalList
                                    entityType={props.cursor.entityType}
                                    disableCard
                                    disableTopDivider
                                />
                            </OnOpenEntityContext.Provider>
                        </HighlightedEntityContext.Provider>
                    </FilterContext.Provider>
                </ViewGroupItem>
            </ViewGroup>
        </Card>;
    };

export default observer(SelectionCursorBrowser);
