import React, { useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import useTypes from '../../Type/Api/useTypes';
import { grey } from '@material-ui/core/colors';
import EntityLink from '../../Link/EntityLink';
import { EmployeeAllocationEditorProps } from '../EmployeeAllocationEditor';
import { EmployeeAllocationEditorEmployeePlanCell } from './PlanCell/EmployeeAllocationEditorEmployeePlanCell';
import { add, getWeek } from 'date-fns';
import { groupBy } from '../../../../../@Util/MapUtils/groupBy';
import getDurationLabel from '../../../../../@Util/Date/getDurationLabel';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';

const useStyles =
    makeStyles({
        total: {
            fontWeight: 500,
        },
    });

export interface ItemPlannerEmployeeRowProps extends EmployeeAllocationEditorProps
{
    employeeAllocationParameterAssignment: ParameterAssignment;
    resource: Entity;
    startDate: Date;
    numberOfWeeksToShow: number;
    total: number;
    items?: Entity[];
}

export const EmployeeAllocationEditorEmployeeRow: React.FC<ItemPlannerEmployeeRowProps> =
    observer(
        ({
             specification,
             employeeAllocationParameterAssignment,
             resource,
             startDate,
             items,
             minDate,
             maxDate,
             numberOfWeeksToShow,
             total,
             settingStorageKey,
         }) =>
        {
            const classes = useStyles();
            const types = useTypes();
            const norm =
                useComputed<number>(
                    () =>
                        specification.resourceNormFieldPath
                            ? specification.resourceNormFieldPath.getObjectValue(resource)
                            : undefined,
                    [
                        specification,
                        resource,
                    ]
                );
            const itemsByWeek =
                useMemo(
                    () =>
                        groupBy(
                            items ?? [],
                            element =>
                                element.getObjectValueByField(types.EmployeeAllocation.Field.Week)
                        ),
                    [
                        items
                    ]
                );

            return <TableRow>
                <TableCell>
                    <EntityLink
                        entity={resource}
                    />
                </TableCell>
                {
                    specification.resourceNormFieldPath !== undefined &&
                    <TableCell
                        align="center"
                        style={{
                            borderLeft: `1px solid ${grey[100]}`
                        }}
                        title={types.Relationship.Person.Contact.Employee.Field.WorkableHoursPerWeek.getName()}
                    >
                        {norm !== undefined && getDurationLabel(norm * 1000 * 60 * 60)}
                    </TableCell>
                }
                {
                    new Array(numberOfWeeksToShow).fill(0).map(
                        (_, idx) =>
                        {
                            const cellStartDate = add(startDate, { weeks: idx });
                            const cellStartWeek = getWeek(cellStartDate);

                            return <EmployeeAllocationEditorEmployeePlanCell
                                key={cellStartWeek}
                                specification={specification}
                                employeeAllocationParameterAssignment={employeeAllocationParameterAssignment}
                                resource={resource}
                                minDate={minDate}
                                maxDate={maxDate}
                                startDate={cellStartDate}
                                norm={norm}
                                items={itemsByWeek.get(cellStartWeek)}
                                settingStorageKey={settingStorageKey}
                            />;
                        }
                    )
                }
                <TableCell
                    className={classes.total}
                    align="center"
                >
                    {getDurationLabel(total * 1000 * 60 * 60)}
                </TableCell>
            </TableRow>;
        }
    );
