import React, { useCallback, useEffect, useState } from 'react';
import { PanelRow } from '@wordpress/components';
import ViewGroup from '../../../../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../ViewGroup/ViewGroupItem';
import useTypes from '../../../../../../../../@Component/Domain/Entity/Type/Api/useTypes';
import { useComputed } from 'mobx-react-lite';
import { createStringComparator } from '../../../../../../../../@Component/Generic/List/V2/Comparator/StringComparator';
import StaticSelectbox from '../../../../Selectbox/Static/StaticSelectbox';
import Input from '../../../../Input/Input';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import { FormLinkFields } from './FormLinkFields';
import { FormLinkProperties } from './model/FormLinkProperties';

export interface FormLinkPanelProps
{
    formLinkProps: FormLinkProperties;
    onChange: (props: FormLinkProperties) => void;
}

const FormLinkPanel: React.FC<FormLinkPanelProps> =
    ({
        formLinkProps,
        onChange
     }) =>
    {
        const types = useTypes();
        const [ selectedForm, setSelectedForm ] = useState<EntityType>()

        const availableForms =
            useComputed(
                () =>
                {
                    return types.Form.Type.getAllTypes(false)
                        .filter(
                            type =>
                                type !== types.Form.Type
                        )
                        .map(
                            form => {
                                return {
                                    id: '' + form.id,
                                    label: form.getName(),
                                    value: form
                                }
                            }
                        )
                        .sort(
                            createStringComparator(
                                a =>
                                    a.label
                            )
                        )
                },
                [
                    types
                ]
            );

        useEffect(
            () => 
            {
                if (availableForms && formLinkProps?.formId)
                {
                    setSelectedForm(
                        availableForms
                            .map(
                                form => form.value
                            )
                            .find(
                                form =>
                                    form.entity.uuid === formLinkProps.formId
                            )
                    );
                }
                else
                {
                    setSelectedForm(undefined)
                }
            },
            [
                formLinkProps,
                availableForms,
                setSelectedForm
            ]
        );

        const onChangeSelectedForm =
            useCallback(
                (form: EntityType) =>
                {
                    setSelectedForm(form);
                    const newProps = {
                        formId: form?.entity.uuid,
                        fields: []
                    }
                    onChange(newProps);
                },
                [
                    onChange,
                    setSelectedForm
                ]
            )

        if (availableForms?.length > 0)
        {
            return <PanelRow>
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    <ViewGroupItem>
                        <Input
                            labelPosition="left"
                            label="Kies formulier"
                        >
                            <StaticSelectbox
                                options={availableForms}
                                onChange={onChangeSelectedForm}
                                value={selectedForm}
                                clearable
                            />
                        </Input>
                    </ViewGroupItem>
                    {
                        selectedForm &&
                        <ViewGroupItem>
                            <FormLinkFields
                                form={selectedForm}
                                formLinkProperties={formLinkProps}
                                onChangeProperties={onChange}
                            />
                        </ViewGroupItem>
                    }
                </ViewGroup>
            </PanelRow>
        }
        else
        {
            return null
        }
    };

export default FormLinkPanel;
