import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { extendContactRelationshipSelection } from '../Api/extendContactRelationshipSelection';
import { onRelationshipUpdateCheckAndSetContact } from '../Api/onRelationshipUpdateCheckAndSetContact';

export class BespokeInvolved extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Involved')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    extendRelationshipSelection(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        builder: EntitySelectionBuilder,
        commitContext?: CommitContext
    )
    {
        super.extendRelationshipSelection(
            entity,
            relationshipDefinition,
            isParent,
            builder,
            commitContext
        );

        const types = this.types;

        if (relationshipDefinition === types.Involved.RelationshipDefinition.Contact
            && !isParent)
        {
            extendContactRelationshipSelection(
                types.Involved.RelationshipDefinition.Relationship,
                false,
                entity,
                builder,
                commitContext
            );
        }
    }

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        const types = this.types;

        // In case of an update of the relationship field, then update team and check if it is a contact and set the contact field
        if (relationshipDefinition === types.Involved.RelationshipDefinition.Relationship
            && !isParent)
        {
            await onRelationshipUpdateCheckAndSetContact(
                false,
                types.Involved.RelationshipDefinition.Relationship,
                types.Involved.RelationshipDefinition.Contact,
                entity,
                relatedEntity,
                commitContext
            );
        }
    }

    allowIconOrCharactersInAvatar(): boolean
    {
        return true;
    }

    isOpenable(entity: Entity,
               pathFromRelatedEntity?: RelatedEntityPath): boolean
    {
        return false;
    }

    hideFieldPath(entity: Entity,
                  fieldPath: EntityFieldPath,
                  fromFieldPath?: EntityFieldPath,
                  isInConstructor?: boolean,
                  commitContext?: CommitContext): boolean
    {
        return super.hideFieldPath(entity, fieldPath, fromFieldPath, isInConstructor, commitContext)
            || (fieldPath.relationshipDefinition === this.types.Activity.RelationshipDefinition.Involved
                && fieldPath.isParentRelationship
                && entity.hasRelationshipsByDefinition(true, this.types.Activity.RelationshipDefinition.Involved));
    }

    // ----------------------- Private logic ------------------------
}
