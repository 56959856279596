import { observable } from 'mobx';
import FieldMapping from './FieldMapping';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import resolveInputFromDescriptor from '../../../../@Component/Domain/Multiplayer/Model/Input/Api/resolveInputFromDescriptor';
import Mapping from '../Mapping';
import RelationshipInput from '../../../../@Component/Domain/Multiplayer/Model/Input/RelationshipInput';
import Dependency from '../../Parameter/Dependency';
import Validation from '../../Validation/Validation';

export default class MappingFieldMapping extends FieldMapping<RelationshipInput>
{
    // ------------------------- Properties -------------------------

    @observable.ref mapping: Mapping;

    // ------------------------ Constructor -------------------------

    constructor(field: RelationshipInput,
                mapping: Mapping)
    {
        super(field);

        this.mapping = mapping;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return this.mapping.validate();
    }

    isAsync(): boolean
    {
        return this.mapping.isAsync();
    }

    getDependencies(): Dependency[]
    {
        return this.mapping.getDependencies();
    }

    augmentDescriptor(descriptor)
    {
        descriptor.type = 'Mapping';
        descriptor.mapping = this.mapping.toDescriptor();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new MappingFieldMapping(
            resolveInputFromDescriptor(descriptor.field) as RelationshipInput,
            await Mapping.fromDescriptor(
                descriptor.mapping,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
