import { EntityType } from '../../Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { CurrentUserStore } from '../../../@Component/Domain/User/CurrentUserStore';
import Input from '../../../@Component/Domain/Multiplayer/Model/Input/Input';

function _getNameFieldOverrideByType(type: EntityType,
                                    overrides: Map<EntityType, Input>): Input | undefined
{
    if (overrides.has(type))
    {
        return overrides.get(type);
    }
    else if (type.hasParent())
    {
        return _getNameFieldOverrideByType(type.parentType, overrides);
    }
    else
    {
        return undefined;
    }
}

export default function getNameFieldOverrideByType(type: EntityType): Input | undefined
{
    const overrides = loadModuleDirectly(CurrentUserStore)?.typeNameOverrides;

    if (overrides)
    {
        return _getNameFieldOverrideByType(type, overrides);
    }
    else
    {
        return undefined;
    }
}
