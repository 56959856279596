import React from 'react';
import styles from './Label.module.scss';
import { classNames } from '../../../Util/Class/classNames';

export function generateLabelWithClass(className: string,
                                       denseClass?: string): React.FC<LabelProps>
{
    return props =>
        <span
            className={
                classNames(
                    styles.root,
                    props.noWrap ? styles.nowrap : undefined,
                    props.dense ? styles.dense : undefined,
                    className,
                    props.dense && denseClass ? denseClass : undefined,
                    props.className)
            }
        >
            {props.children}
        </span>;
}

export interface LabelProps
{
    dense?: boolean;
    noWrap?: boolean;
    className?: string;
}
