/**
 * WordPress dependencies
 */
import {Button} from '@wordpress/components';
import {__experimentalLibrary as Library} from '@wordpress/block-editor';
import {close} from '@wordpress/icons';
import {__experimentalUseDialog as useDialog, useViewportMatch,} from '@wordpress/compose';

export default function InserterSidebar(props) {
	const { insertionPoint, onClose } = props;
	const showMostUsedBlocks = true;

	const isMobileViewport = useViewportMatch( 'medium', '<' );
	const [ inserterDialogRef, inserterDialogProps ] = useDialog( {
		onClose: () => onClose(),
	} );

	return (
		<div
			ref={ inserterDialogRef }
			{ ...inserterDialogProps }
			className="edit-post-editor__inserter-panel"
		>
			<div className="edit-post-editor__inserter-panel-header">
				<Button
					icon={ close }
					onClick={ () => onClose() }
				/>
			</div>
			<div className="edit-post-editor__inserter-panel-content">
				<Library
					showMostUsedBlocks={ showMostUsedBlocks }
					shouldFocusBlock={ isMobileViewport }
					rootClientId={ insertionPoint.rootClientId }
					__experimentalInsertionIndex={
						insertionPoint.insertionIndex
					}
					__experimentalFilterValue={ insertionPoint.filterValue }
				/>
			</div>
		</div>
	);
}
