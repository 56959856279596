import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { setValueByFieldInEntity } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import useTypes from '../../../../../Type/Api/useTypes';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import Dialog, { DialogProps } from '../../../../../../../../@Future/Component/Generic/Dialog/Dialog';
import DialogTitle from '../../../../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import DialogContent from '../../../../../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import PrimaryButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import CancelButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import { commitEntityWithContext } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import RightAlignedButtonGroup from '../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import { LocalizedTextValue } from '../../../../../../../../@Api/Localization/LocalizedTextValue';
import LocalizedTextEditor from '../../../../../../../../@Future/Component/Generic/Input/LocalizedTextEditor/LocalizedTextEditor';
import localize from '../../../../../../../../@Api/Localization/localize';
import { loadModuleDirectly } from '../../../../../../../../@Util/DependencyInjection/index';
import { LocalizationStore } from '../../../../../../../../@Service/Localization/LocalizationStore';


export interface ModifyProductDescriptionDialogProps extends DialogProps
{
    entity: any;
    commitContext: CommitContext;
}

const ModifyProductDescriptionDialog: React.FC<ModifyProductDescriptionDialogProps> =
    (
        props
    ) =>
    {
        const types = useTypes();
        const localizationStore = loadModuleDirectly(LocalizationStore);

        const [ localizedDescription, setLocalizedDescription ] =
            useState<LocalizedTextValue>(
                props.entity.hasValueForField(
                    types.ProductLine.Field.LocalizedDescription,
                    props.commitContext
                )
                    ? props.entity.getObjectValueByField(
                        types.ProductLine.Field.LocalizedDescription,
                        props.commitContext
                    )
                    : {
                        // backward compatibility for productLines created
                        // before PI202100133 - translatable product name
                        [localizationStore.languageCode.toLowerCase()]:
                            props.entity.getObjectValueByField(
                                types.ProductLine.Field.Description,
                                props.commitContext
                            )
                    }
            );

        const save =
            useCallback(
                async () =>
                {
                    const productLineDescription = localize(localizedDescription);

                    if (productLineDescription)
                    {
                        setValueByFieldInEntity(
                            props.entity,
                            types.ProductLine.Field.Description,
                            productLineDescription,
                            props.commitContext
                        );

                        setValueByFieldInEntity(
                            props.entity,
                            types.ProductLine.Field.LocalizedDescription,
                            localizedDescription,
                            props.commitContext
                        );

                        await commitEntityWithContext(
                            props.entity,
                            props.commitContext,
                            {
                                isForced: true,
                                isAutoCommit: true,
                            }
                        );

                        if (props.onClose)
                        {
                             props.onClose();
                        }

                    }
                },
                [
                    props.entity,
                    types,
                    props.commitContext,
                    props.onClose,
                    localizedDescription,
                ]
            );

        return <Dialog
            {...props}
            width="sm"
        >
            <DialogTitle>
                <LocalizedText
                    code='ModifyProductDescriptionTitle'
                    value='Product omschrijving'
                />
            </DialogTitle>
            <DialogContent>
                <ViewGroup
                    orientation="vertical"
                    spacing={15}
                >
                    <ViewGroupItem>
                        <LocalizedTextEditor
                            value={localizedDescription}
                            onChange={setLocalizedDescription}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <RightAlignedButtonGroup>
                            <CancelButton
                                onClick={props.onClose}
                            />
                            <PrimaryButton
                                label={
                                    <LocalizedText
                                        code="Generic.Save"
                                        value="Bewaar"
                                    />
                                }
                                onClick={save}
                                disabled={localize(localizedDescription) === undefined}
                            />
                        </RightAlignedButtonGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </DialogContent>
        </Dialog>;
    };

export default observer(ModifyProductDescriptionDialog);
