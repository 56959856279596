import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import LayoutViewer from '../../../../../Viewer/LayoutViewer';
import { TableCell } from '@material-ui/core';
import ComputationLayout from '../../../../../../../../@Api/Layout/Type/ComputationLayout';
import { PortalTableColumn } from '../../../../../../../../@Api/Layout/Type/PortalTable/Model/PortalTableColumn';
import { PortalTableHeaderProps } from '../PortalTableHeader';
import { PortalTableOrdering } from '../../../../../../../../@Api/Layout/Type/PortalTable/Model/PortalTableOrdering';
import uuid from '../../../../../../../../@Util/Id/uuid';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import SortIconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/SortIconButton/SortIconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { recordHoverBackgroundColor } from '../../../../../../../../@Resource/Theme/Theme';
import { PortalTableColumnFilterButton } from './FilterButton/PortalTableColumnFilterButton';
import { classNames } from '../../../../../../../../@Future/Util/Class/classNames';

const useStyles =
    makeStyles({
        root: {
            '& *': {
                fontWeight: 500,
            },
        },
        clickable: {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: recordHoverBackgroundColor,
            },
        },
        filterButton: {

        },
    });

export interface PortalTableHeaderColumnProps extends PortalTableHeaderProps
{
    column: PortalTableColumn;
    totalRatio: number;
}

export const PortalTableHeaderColumn: React.FC<PortalTableHeaderColumnProps> =
    observer(
        ({
            column,
            totalRatio,
            ordering,
            onOrder,
            onFilter,
            ...otherProps
         }) =>
        {
            const classes = useStyles();
            const nameLayout =
                useMemo(
                    () =>
                        new ComputationLayout(column.name),
                    [
                        column.name,
                    ]
                );

            return <TableCell
                className={
                    classNames(
                        classes.root,
                        column.isOrderable && classes.clickable
                    )
                }
                width={`${column.ratio / totalRatio * 100}%`}
                onClick={
                    column.isOrderable
                        ? () =>
                            onOrder(
                                ordering?.columnId !== column.id
                                || ordering.direction === 'Ascending'
                                ? new PortalTableOrdering(
                                    uuid(),
                                    column.id,
                                    ordering?.columnId === column.id
                                        ? 'Descending'
                                        : 'Ascending'
                                )
                                : undefined
                            )
                        : undefined
                }
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={5}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <LayoutViewer
                            {...otherProps}
                            layout={nameLayout}
                        />
                    </ViewGroupItem>
                    {
                        column.isFilterable &&
                        <ViewGroupItem
                            className={classes.filterButton}
                        >
                            <PortalTableColumnFilterButton
                                column={column}
                                onFilter={onFilter}
                            />
                        </ViewGroupItem>
                    }
                    {
                        column.isOrderable
                        && ordering?.columnId === column.id
                        &&
                        <ViewGroupItem >
                            <SortIconButton
                                isAscendingSort={ordering.direction === 'Ascending'}
                                onClick={() => {}}
                            />
                        </ViewGroupItem>
                    }
                </ViewGroup>
            </TableCell>;
        }
    );
