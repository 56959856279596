import React from 'react';
import { GutenbergCoreBlockTextId } from '../../../DocumentEditor';
import GutenbergBlockType from '../../../Gutenberg/GutenbergBlockType';
import StyledBlock from '../../StyledBlock/StyledBlock';
import RichText from '../../../../../../../../@Component/Generic/RichText/RichText';

const GutenbergCoreBlockText: GutenbergBlockType =
{
    name: GutenbergCoreBlockTextId,

    configuration:
    {
        attributes: {},
        category: null,
        title: {
            code: 'GutenbergBlock.Text',
            value: 'Tekst'
        },
    },

    view: block =>
    {
        return <StyledBlock
            block={block}
        >
            <RichText
                value={block.attributes.content}
            />
        </StyledBlock>;
    }
};

export default GutenbergCoreBlockText;
