import { computed, observable } from 'mobx';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import AggregateValue from '../../View/Model/Column/AggregateValue';
import { DateType } from '../../../DataObject/Type/Date/DateType';
import Chart from '../../Chart/Model/Chart';
import FilterOption from './FilterOption';

export type SegmentType = 'List' | 'Tile' | 'Pie' | 'Bar' | 'Line' | 'Funnel' | 'Chart';

export default class Segment
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable type: SegmentType;
    @observable.ref groupFieldPath: EntityFieldPath;
    @observable aggregateValues: AggregateValue[];
    @observable.shallow filterOptions: FilterOption[];
    @observable.ref chart: Chart;
    @observable.ref secondaryGroupFieldPath: EntityFieldPath;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                type: SegmentType,
                groupFieldPath: EntityFieldPath,
                aggregateValues: AggregateValue[] = [],
                filterOptions: FilterOption[] = [],
                chart?: Chart,
                secondaryGroupFieldPath?: EntityFieldPath)
    {
        this.id = id;
        this.type = type;
        this.groupFieldPath = groupFieldPath;
        this.aggregateValues = aggregateValues;
        this.filterOptions = filterOptions;
        this.chart = chart;
        this.secondaryGroupFieldPath = secondaryGroupFieldPath;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get isDate(): boolean
    {
        return this.groupFieldPath !== undefined
            && this.groupFieldPath.field !== undefined
            && this.groupFieldPath.field.dataObjectSpecification.type instanceof DateType;
    }

    @computed
    get isDateSegmentedInCategories()
    {
        return this.isDate &&
            (this.type === 'List'
                || this.type === 'Tile');
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(descriptor: any): Promise<Segment>
    {
        return new Segment(
            descriptor.id,
            descriptor.type,
            EntityFieldPath.construct(descriptor.groupFieldPath),
            descriptor.aggregateValues.map(
                aggregateValue =>
                    AggregateValue.fromDescriptor(aggregateValue)),
            (descriptor.filterOptions || []).map(
                filterOption =>
                    FilterOption.fromDescriptor(filterOption)),
            descriptor.chart
                ?
                    await Chart.fromDescriptor(descriptor.chart)
                :
                    undefined,
            descriptor.secondaryGroupFieldPath
                ?
                    EntityFieldPath.construct(descriptor.secondaryGroupFieldPath)
                :
                    undefined);
    }

    toDescriptor()
    {
        return {
            id: this.id,
            type: this.type,
            groupFieldPath: this.groupFieldPath.descriptor,
            aggregateValues:
                this.aggregateValues.map(
                    aggregateValue =>
                        aggregateValue.toDescriptor()),
            filterOptions:
                this.filterOptions.map(
                    filterOption =>
                        filterOption.toDescriptor()),
            // chart:
            //     this.chart
            //         ?
            //             this.chart.toDescriptor()
            //         :
            //             undefined,
            secondaryGroupFieldPath:
                this.secondaryGroupFieldPath
                    ?
                        this.secondaryGroupFieldPath.descriptor
                    :
                        undefined
        };
    }

    // ----------------------- Private logic ------------------------
}
