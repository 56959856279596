import AutomationDependencyContext from '../AutomationDependencyContext';
import Parameter from '../Parameter/Parameter';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { observable } from 'mobx';
import Input from '../../../@Component/Domain/Multiplayer/Model/Input/Input';
import { EntityType } from '../../Model/Implementation/EntityType';
import Predicate from '../Function/Computation/Predicate/Predicate';
import resolveInputFromDescriptor from '../../../@Component/Domain/Multiplayer/Model/Input/Api/resolveInputFromDescriptor';
import FieldInput from '../../../@Component/Domain/Multiplayer/Model/Input/FieldInput';
import PrimitiveValueType from '../Value/Type/PrimitiveValueType';
import RelationshipInput from '../../../@Component/Domain/Multiplayer/Model/Input/RelationshipInput';
import EntityValueType from '../Value/Type/EntityValueType';
import MutationTrigger from './MutationTrigger';
import localizeText from '../../Localization/localizeText';

export default class UpdateTrigger extends MutationTrigger
{
    // ------------------------- Properties -------------------------

    static FromValueParameterId = 'FromValue';
    static ToValueParameterId = 'ToValue';

    @observable.ref field: Input;

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType,
                predicate: Predicate,
                field: Input)
    {
        super(entityType, predicate);

        this.field = field;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getParameters(): Parameter<any>[]
    {
        const parameters: Parameter<any>[] = [
            this.getEntityParameter(),
            this.getUserParameter(),
            this.getDateParameter()
        ];

        if (this.field instanceof FieldInput)
        {
            const valueType = new PrimitiveValueType(this.field.field.dataObjectSpecification.type);

            parameters.push(
                new Parameter(
                    UpdateTrigger.FromValueParameterId,
                    valueType,
                    false,
                    localizeText(
                        'UpdateTriggerFromValueParameterName',
                        'Waarde van ${field} vóór mutatie',
                        {
                            field: this.field?.field?.getName()?.toLowerCase() ?? '[ ? ]',
                        })
                ),
                new Parameter(
                    UpdateTrigger.ToValueParameterId,
                    valueType,
                    false,
                    localizeText(
                        'UpdateTriggerToValueParameterName',
                        'Waarde van ${field} na mutatie',
                        {
                            field: this.field?.field?.getName()?.toLowerCase() ?? '[ ? ]',
                        })
                )
            );
        }
        else if (this.field instanceof RelationshipInput)
        {
            const valueType = new EntityValueType(this.field.relationshipDefinition.getEntityType(this.field.isParent));

            parameters.push(
                new Parameter(
                    UpdateTrigger.FromValueParameterId,
                    valueType,
                    false,
                    localizeText(
                        'UpdateTriggerFromValueParameterName',
                        'Waarde van ${field} vóór mutatie',
                        {
                            field: this.field?.relationshipDefinition?.getName(this.field?.isParent)?.toLowerCase() ?? '[ ? ]',
                        })
                ),
                new Parameter(
                    UpdateTrigger.ToValueParameterId,
                    valueType,
                    false,
                    localizeText(
                        'UpdateTriggerToValueParameterName',
                        'Waarde van ${field} na mutatie',
                        {
                            field: this.field?.relationshipDefinition?.getName(this.field?.isParent)?.toLowerCase() ?? '[ ? ]',
                        })
                ));
        }

        return parameters;
    }

    augmentDescriptor(descriptor: any)
    {
        super.augmentDescriptor(descriptor);

        descriptor.type = 'Update';
        descriptor.field = this.field.toDescriptor();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new UpdateTrigger(
            loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.entityTypeId),
            undefined,
            resolveInputFromDescriptor(descriptor.field));
    }

    getName(): string
    {
        return localizeText(
            'UpdateTriggerName',
            'Na het muteren van het veld ${field} van een ${type}',
            {
                field: this.field?.toFieldPath()?.getName()?.toLowerCase() ?? '[ ? ]',
                type: this.entityType?.getName()?.toLowerCase() ?? '[ ? ]',
            });
    }

    // ----------------------- Private logic ------------------------
}
