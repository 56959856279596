import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../../../@Framework/Component/BaseComponent';
import { HeaderStore } from './HeaderStore';
import { Card } from '@material-ui/core';
import { SearchBar } from '../../../../../Generic/SearchBar/SearchBar';
import { viewInset } from '../../../../../../@Resource/Theme/Theme';
import styles from './Header.module.scss';
import UserButton from './UserButton/UserButton';
import EnvironmentButton from './EnvironmentButton/EnvironmentButton';
import EntityConstructorButton from '../../../../../Domain/Entity/Button/EntityConstructorButton/EntityConstructorButton';
import Hidden from '@material-ui/core/Hidden';
import TrialStatus from '../../../../../Domain/License/TrialStatus/TrialStatus';
import RecordButton from '../../../../../Domain/Entity/Timetracker/RecordButton/RecordButton';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import DashboardSelector from '../../../../../Domain/Entity/Dashboard/DashboardSelector/DashboardSelector';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../@Future/Component/Generic/Menu/Item/Item';
import NotificationButton from '../../../../../Domain/Notification/Button/NotificationButton';
import Drawer from '../../../../../../@Future/Component/Generic/Drawer/Drawer';
import TeamSwitcher from './TeamSwitcher/TeamSwitcher';
import isInIframe from '../../../../../../@Future/Util/Iframe/IsInIframe';
import LocalizedText from '../../../../../Domain/Localization/LocalizedText/LocalizedText';
import Logo from '../../../../../../@Resource/Image/Logo/White.svg';
import openMarketplace from '../../../../../Domain/Marketplace/Api/openMarketplace';
import openTranslationCenter from '../../../../../Domain/Configuration/Page/TranslationCenter/Api/openTranslationCenter';
import { hasAccessToTranslationCenter } from '../../../../../Domain/Configuration/Page/TranslationCenter/Api/hasAccessToTranslationCenter';
import { VersionSwitcher } from '../../../../../Domain/VersionSwitcher/VersionSwitcher';

@observer
class Header extends BaseComponent<HeaderStore>
{
    renderComponent(store: HeaderStore): any
    {
        const isAllowedInTranslationCenter = hasAccessToTranslationCenter(store.currentUserStore);

        return <>
            <Card
                className={styles.headerCardWithShadow}
                square
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        paddingLeft: 10,
                        paddingRight: 15,
                    }}
                >
                    {/* Mobile */}
                    <Hidden
                        mdUp
                    >
                        {
                            store.isSearchBarOpen &&
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={5}
                                    alignment="center"
                                    className={styles.viewGroup}
                                >
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        <SearchBar
                                            store={store.searchBarStore}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <IconButton
                                            icon="close"
                                            color="white"
                                            onClick={store.toggleSearchBar}
                                            tooltip={
                                                <LocalizedText
                                                    code="Header.ToggleSearchBar"
                                                    value="Zoeken afsluiten"
                                                />
                                            }
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                        }
                        {
                            !store.isSearchBarOpen &&
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={10}
                                    alignment="center"
                                    className={styles.viewGroup}
                                >
                                    <ViewGroupItem>
                                        <IconButton
                                            icon="menu"
                                            color="white"
                                            onClick={store.toggleMenu}
                                            tooltip={
                                                <LocalizedText
                                                    code="Header.ToggleMenu"
                                                    value="Menu openen/sluiten"
                                                />
                                            }
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <img
                                            alt="Tribe"
                                            src={Logo}
                                            style={{
                                                cursor: 'pointer',
                                                height: 20
                                            }}
                                            onClick={store.onLogoClick}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem
                                        ratio={1}
                                    />
                                    {
                                        store.entityTypeStore.bespoke.types.TimeRegistration.Type &&
                                            <ViewGroupItem>
                                                <RecordButton />
                                            </ViewGroupItem>
                                    }
                                    <ViewGroupItem>
                                        <EntityConstructorButton
                                            iconColor="white"
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <NotificationButton />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <IconButton
                                            icon="search"
                                            color="white"
                                            onClick={store.toggleSearchBar}
                                            tooltip={
                                                <LocalizedText
                                                    code="Generic.Search"
                                                    value="Zoeken"
                                                />
                                            }
                                        />
                                    </ViewGroupItem>
                                    {
                                        isInIframe() &&
                                            <ViewGroupItem>
                                                <IconButton
                                                    icon="open_in_new"
                                                    color="white"
                                                    onClick={store.openInBrowser}
                                                    tooltip={
                                                        <LocalizedText
                                                            code="Header.OpenInBrowser"
                                                            value="In nieuw venster openen"
                                                        />
                                                    }
                                                />
                                            </ViewGroupItem>
                                    }
                                </ViewGroup>
                        }
                    </Hidden>
                    {/* Non-mobile */}
                    <Hidden
                        smDown
                    >
                        <div>
                            <img
                                alt="Tribe"
                                src={Logo}
                                style={{
                                    cursor: 'pointer',
                                    padding: '0 5px',
                                    marginLeft: viewInset,
                                    // width: 165,
                                    height: 28
                                }}
                                onClick={store.onLogoClick}
                            />
                        </div>
                        <VersionSwitcher/>
                        <Hidden
                            xsDown
                        >
                            <div
                                style={{
                                    width: '20%'
                                }}
                            >
                                <SearchBar
                                    store={store.searchBarStore}
                                />
                            </div>
                        </Hidden>
                        <div
                            style={{
                                flex: '1 1 auto',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            {
                                store.currentUserStore.isAllowedInConfiguration &&
                                    <div
                                        style={{
                                            marginRight: 15,
                                            alignSelf: 'center'
                                        }}
                                    >
                                        <TrialStatus />
                                    </div>
                            }
                            {
                                store.entityTypeStore.bespoke.types.TimeRegistration.Type &&
                                    <div
                                        style={{
                                            marginRight: 5,
                                            alignSelf: 'center'
                                        }}
                                    >
                                        <RecordButton />
                                    </div>
                            }
                            <div
                                style={{
                                    marginRight: 5,
                                    alignSelf: 'center'
                                }}
                            >
                                <EntityConstructorButton
                                    iconColor="white"
                                />
                            </div>
                            <div
                                style={{
                                    marginRight: 10,
                                    alignSelf: 'center'
                                }}
                            >
                                <NotificationButton />
                            </div>
                            {
                                isAllowedInTranslationCenter &&
                                <div
                                    style={{
                                        marginRight: 13,
                                        alignSelf: 'center'
                                    }}
                                >
                                    <IconButton
                                        icon="language"
                                        onClick={() => openTranslationCenter()}
                                        tooltip={
                                            <LocalizedText
                                                code="Configuration.TranslationCenter"
                                                value="Vertaalcentrum"
                                            />
                                        }
                                        iconSize={19}
                                        color="white"
                                    />
                                </div>
                            }
                            <div
                                style={{
                                    marginRight: 13,
                                    alignSelf: 'center'
                                }}
                            >
                                <IconButton
                                    icon="storefront"
                                    onClick={() => openMarketplace()}
                                    tooltip={
                                        <LocalizedText
                                            code="Header.OpenMarketplace"
                                            value="Marketplace openen"
                                        />
                                    }
                                    iconSize={19}
                                    color="white"
                                />
                            </div>
                            {
                                store.currentUserStore.limitedAccessTeams.length > 0 &&
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginRight: 15,
                                            alignSelf: 'center'
                                        }}
                                    >
                                        <TeamSwitcher
                                            showInHeader
                                        />
                                    </div>
                            }
                            <div
                                style={{
                                    display: 'flex',
                                    marginRight: 5,
                                    alignSelf: 'center'
                                }}
                            >
                                <UserButton />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    marginRight: 10,
                                    alignSelf: 'center'
                                }}
                            >
                                <EnvironmentButton />
                            </div>
                        </div>
                    </Hidden>
                </div>
            </Card>
            <Drawer
                open={store.isMenuOpen}
                onClose={store.toggleMenu}
                variant="temporary"
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <CardInset
                            bottom={false}
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={10}
                            >
                                <ViewGroupItem
                                    ratio={1}
                                />
                                <ViewGroupItem>
                                    <UserButton />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <EnvironmentButton />
                                </ViewGroupItem>
                            </ViewGroup>
                        </CardInset>
                    </ViewGroupItem>
                    {
                        store.currentUserStore.limitedAccessTeams.length > 0 &&
                        <ViewGroupItem>
                            <div
                                style={{
                                    display: 'block',
                                    margin: 10,
                                    borderRadius: 5,
                                    alignSelf: 'center',
                                    border: '#eee 1px solid'
                                }}
                            >
                                <TeamSwitcher />
                            </div>
                        </ViewGroupItem>
                    }
                    <ViewGroupItem>
                        <VersionSwitcher/>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <DashboardSelector
                            renderMenu
                            onOpen={store.closeMenu}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Divider />
                        <Menu>
                            <Item
                                icon="storefront"
                                name="Marketplace"
                                onClick={() => { store.closeMenu(); openMarketplace(); }}
                            />
                            {
                                store.currentUserStore.isAllowedInConfiguration &&
                                    <Item
                                        icon="settings"
                                        name={
                                            <LocalizedText
                                                code="Generic.Configuration"
                                                value="Configuratie"
                                            />
                                        }
                                        onClick={() => { store.closeMenu(); store.routerStore.route('/configuration'); }}
                                    />
                            }
                        </Menu>
                    </ViewGroupItem>
                    <ViewGroupItem
                        ratio={1}
                    />
                    {
                        store.currentUserStore.isAllowedInConfiguration &&
                            <ViewGroupItem>
                                <CardInset>
                                    <TrialStatus
                                        onStart={store.closeMenu}
                                    />
                                </CardInset>
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </Drawer>
        </>;
    }
}

export default Header;
