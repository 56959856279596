import { observable } from 'mobx';

export type DimensionUnit = 'Pixels' | 'Percentage';

export default class Dimension
{
    // ------------------------- Properties -------------------------

    @observable value: number;
    @observable unit: DimensionUnit;

    // ------------------------ Constructor -------------------------

    constructor(value: number, unit: DimensionUnit)
    {
        this.value = value;
        this.unit = unit;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toCss()
    {
        return `${this.value}${this.unit === 'Percentage' ? '%' : 'px'}`;
    }

    toDescriptor()
    {
        return {
            value: this.value,
            unit: this.unit
        };
    }

    static fromDescriptor(descriptor: any)
    {
        return new Dimension(
            descriptor.value,
            descriptor.unit);
    }

    // ----------------------- Private logic ------------------------
}
