import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import Dependency from '../../Parameter/Dependency';
import EntityValueType from '../../Value/Type/EntityValueType';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import PrimitiveValue from '../../Value/PrimitiveValue';
import EmptyValue from '../../Value/EmptyValue';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import localizeText from '../../../Localization/localizeText';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import getTypes from '../../../../@Component/Domain/Entity/Type/Api/getTypes';
import { FileType } from '../../../../@Component/Domain/DataObject/Type/File/FileType';
import { FileValue } from '../../../../@Component/Domain/DataObject/Type/File/FileValue';

export default class NameFromFileComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref file: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(entity: Computation<any, any>)
    {
        super();

        this.file = entity;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            DataObject.getTypeById('File')
        );
    }

    isAsync(): boolean
    {
        return this.file.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const value =
            await safelyApplyFunction(
                this.file,
                context
            );

        return this.getNameFromValue(value);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        const value =
            safelySynchronousApplyFunction(
                this.file,
                context
            );

        return this.getNameFromValue(value);
    }

    private getNameFromValue(value: Value<any, any>)
    {
        if (value instanceof PrimitiveValue
            && value.value.type instanceof FileType)
        {
            const fileValue: FileValue = value.value.getValue();

            if (fileValue.name)
            {
                return new PrimitiveValue(
                    DataObject.constructFromTypeIdAndValue(
                        'Text',
                        fileValue.name
                    )
                );
            }
            else
            {
                return EmptyValue.instance;
            }
        }
        else
        {
            throw new Error(`Expected file, but got: ${value.getId()}`);
        }
    }

    getName(): string
    {
        return `${localizeText('Computation.NameFromFile', 'Naam uit bestand')}: ${this.file.getName()}`;
    }

    validate(): Validation[]
    {
        const type = this.file.getType();

        if (!(type instanceof EntityValueType && type.type.isA(getTypes().EntityType.Type)))
        {
            return [
                new Validation(
                    'Error',
                    localizeText('Computation.NameFromFile.NoValidFileSelected', 'Er is geen geldig bestand geselecteerd.')
                )
            ];
        }
        else
        {
            return [];
        }
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'NameFromFile';
        descriptor.file = this.file.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.file.getDependencies();
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext
    )
    {
        return new NameFromFileComputation(
            await getComputationFromDescriptor(
                descriptor.file,
                dependencyContext
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
