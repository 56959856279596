import ValueType from './ValueType';

export default class EmptyValueType extends ValueType<void>
{
    // ------------------------- Properties -------------------------

    static instance = new EmptyValueType();

    // ------------------------ Constructor -------------------------

    constructor()
    {
        super(undefined);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return 'Empty';
    }

    isA(type: ValueType<any>): boolean
    {
        return type instanceof EmptyValueType;
    }

    getName(): string
    {
        return 'Leeg';
    }

    toDescriptor(): any
    {
        return {
            type: 'Empty'
        };
    }

    static fromDescriptor(descriptor: any): EmptyValueType
    {
        return EmptyValueType.instance;
    }

    // ----------------------- Private logic ------------------------
}
