import React from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../../../../Type/Api/useTypes';
import Input from '../../../../../../../../../../Input/Input';
import Base from '../../Base/Base';
import { ContentProps } from '../../Content';

export interface DocumentProps extends ContentProps
{

}

const Document: React.FC<DocumentProps> =
    props =>
    {
        const types = useTypes();

        const fields =
            [
                <Input
                    key={0}
                    entity={props.entity}
                    field={types.Activity.Document.RelationshipDefinition.Phase}
                    labelPosition={props.labelPosition}
                    inline={props.inlineLabel}
                    disabled
                    commitContext={props.commitContext}
                />
            ];

        return <Base
            {...props}
            open
        >
            {fields}
        </Base>;
    };

export default observer(Document);
