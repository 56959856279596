import * as React from 'react';
import { DataObjectBespokeEditor } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { observer } from 'mobx-react';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { ComputationEditorStore } from './ComputationEditorStore';
import { ComputationEditor as InternalComputationEditor } from '../../../../../Domain/Computation/ComputationEditor';

@observer
export class ComputationEditor extends DataObjectBespokeEditor<ComputationEditorStore>
{
    renderComponent(store: DataObjectEditorStore<ComputationEditorStore>)
    {
        if (store.bespokeStore.computationEditorStore)
        {
            return <InternalComputationEditor
                store={store.bespokeStore.computationEditorStore}
            />;
        }
        else
        {
            return null;
        }
    }
}
