import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { action, observable } from 'mobx';
import { injectWithQualifier } from '../../../@Util/DependencyInjection/index';
import { SidebarStore } from '../Sidebar/SidebarStore';
import { ComponentContainerStore } from '../Container/ComponentContainerStore';

export class LightboxStore<T extends BaseStore = BaseStore> extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('SidebarStore') sidebarStore: SidebarStore;

    // ------------------------- Properties -------------------------

    @observable icon: string;
    @observable title: string;
    @observable isVisible: boolean;
    @observable onClose: () => void;
    @observable containerStore: ComponentContainerStore<T>;

    // ------------------------ Constructor -------------------------

    constructor(icon: string,
                title: string,
                isVisible: boolean = false,
                onClose?: () => void,
                containerStore?: ComponentContainerStore<T>)
    {
        super();

        this.icon = icon;
        this.title = title;
        this.isVisible = isVisible;
        this.onClose = onClose;
        this.containerStore = containerStore;
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    setVisibility(isVisible: boolean)
    {
        this.isVisible = isVisible;

        if (!isVisible && this.onClose)
        {
            this.onClose();
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

}
