import React, { useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import styles from './AppBarLayoutView.module.scss';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import AppBarLayout from '../../../../../../@Api/Layout/Type/AppBarLayout';

export interface AppBarLayoutViewProps
{
    layout: AppBarLayout;
    editable?: boolean;
}

const AppBarLayoutView: React.FC<AppBarLayoutViewProps> =
    props =>
    {
        const classes =
            useMemo(
                () => ({
                    root: classNames(styles.root, props.editable && styles.editable)
                }),
                [
                    props.editable
                ]);

        const style =
            useComputed(
                () => ({
                    backgroundColor: `${props.layout.color.toCss()}`
                }),
                [
                    props.layout
                ]);

        return <Card
            classes={classes}
            style={style}
            square
        >
            {props.children}
        </Card>;
    };

export default observer(AppBarLayoutView);
