import * as React from 'react';
import { observer } from 'mobx-react';
import ViewGroupItem from '../ViewGroup/ViewGroupItem';
import PrimaryButton from '../Button/Variant/PrimaryButton/PrimaryButton';
import CardInset from '../Card/CardInset';
import { downloadApiFile } from '../../../../@Service/ApiClient/Hooks/downloadApiFile';
import { DownloadOption } from './model/DownloadOption';

interface PdfViewerMobileNextButtonProps
{
    options: DownloadOption[]
}

export const PdfViewerMobileDownloadButtons: React.FC<PdfViewerMobileNextButtonProps> = observer(
    ({
        options
     }) =>
    {
        return <div>
            {
                options
                .map(
                    option =>
                        <ViewGroupItem>
                            <CardInset
                                top={false}
                            >
                                <PrimaryButton
                                    label={option.label}
                                    onClick={() => downloadApiFile(option.url)}
                                    fullWidth
                                />
                            </CardInset>
                        </ViewGroupItem>
                )
            }
        </div>
    }
)
