import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import CompositeLayoutItem from './CompositeLayoutItem';
import localizeText from '../../Localization/localizeText';

export type Orientation = 'Vertical' | 'Horizontal';

export default class CompositeLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable orientation: Orientation;
    @observable.shallow items: CompositeLayoutItem[];
    @observable spacing: number;
    @observable hasDivider: boolean;
    @observable isWrapped: boolean;

    // ------------------------ Constructor -------------------------

    constructor(orientation: Orientation,
                items: CompositeLayoutItem[],
                spacing: number = 0,
                hasDivider: boolean = false,
                isWrapped: boolean = false)
    {
        super();

        this.orientation = orientation;
        this.items = items;
        this.spacing = spacing;
        this.hasDivider = hasDivider;
        this.isWrapped = isWrapped;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        switch (this.orientation)
        {
            case 'Vertical':
                return localizeText('Layout.Composite.Vertical', 'Verticale groep');

            case 'Horizontal':
                return localizeText('Layout.Composite.Horizontal', 'Horizontale groep');
        }
    }

    validate(): Validation[]
    {
        return this.items
            .map(
                layout =>
                    layout.validate())
            .reduce((a, b) => a.concat(b), []);
    }

    getDependencies(): Dependency[]
    {
        return this.items
            .map(
                layout =>
                    layout.getDependencies())
            .reduce((a, b) => a.concat(b), []);
    }

    toDescriptor()
    {
        return {
            type: 'Composite',
            orientation: this.orientation,
            items: this.items.map(layout => layout.toDescriptor()),
            spacing: this.spacing,
            hasDivider: this.hasDivider,
            isWrapped: this.isWrapped
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new CompositeLayout(
            descriptor.orientation,
            await Promise.all(
                descriptor.items
                    .map(
                        item =>
                            CompositeLayoutItem.fromDescriptor(
                                item,
                                dependencyContext))),
            descriptor.spacing,
            descriptor.hasDivider,
            descriptor.isWrapped);
    }

    // ----------------------- Private logic ------------------------
}
