import { observable } from 'mobx';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Action from './Action';
import EmptyValueType from '../../Value/Type/EmptyValueType';
import EmptyValue from '../../Value/EmptyValue';
import Computation from '../Computation/Computation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Validation from '../../Validation/Validation';
import localizeText from '../../../Localization/localizeText';

export default class ExceptionAction extends Action<EmptyValueType, EmptyValue>
{
    // ------------------------- Properties -------------------------

    @observable.ref message: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(message: Computation<any, any>)
    {
        super();

        this.message = message;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return EmptyValueType.instance;
    }

    getName()
    {
        return localizeText(
            'Action.Exception',
            'Foutmelding geven'
        );
    }

    validate(): Validation[]
    {
        return [];
    }

    isAsync(): boolean
    {
        return false;
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Exception';
        descriptor.message = this.message.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.message.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new ExceptionAction(
            await getComputationFromDescriptor(
                descriptor.message,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
