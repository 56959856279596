import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../ViewGroup/ViewGroup';
import DateEditor, { DateEditorProps } from '../DateEditor/DateEditor';
import moment from 'moment';
import TimePicker from '../TimePicker/TimePicker';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';

export interface DateTimeEditorProps extends DateEditorProps
{

}

const DateTimeEditor: React.FC<DateTimeEditorProps> =
    props =>
    {
        const { onChange } = props;

        const momentValue =
            useMemo(
                () =>
                {
                    if (props.value)
                    {
                        return moment(props.value);
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.value
                ]);

        const setDate =
            useCallback(
                (date: Date) =>
                {
                    onChange(date);
                },
                [
                    onChange
                ]);

        const setTime =
            useCallback(
                (hour: number, minute: number) =>
                {
                    const newDate = moment(momentValue);
                    newDate.set('hour', hour);
                    newDate.set('minute', minute);

                    onChange(newDate.toDate());
                },
                [
                    momentValue,
                    onChange
                ]);

        const hour =
            useMemo(
                () =>
                    momentValue?.get('hour'),
                [
                    momentValue
                ]);

        const minute =
            useMemo(
                () =>
                    momentValue?.get('minute'),
                [
                    momentValue
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={5}
            alignment="center"
        >
            <ViewGroupItem>
                <DateEditor
                    {...props}
                    onChange={setDate}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <TimePicker
                    {...props}
                    hour={hour}
                    minute={minute}
                    onChange={setTime}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(DateTimeEditor);
