import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import EntityValueType from '../../Automation/Value/Type/EntityValueType';
import Parameter from '../../Automation/Parameter/Parameter';
import Input from '../../../@Component/Domain/Multiplayer/Model/Input/Input';
import LayoutDependencyContext from '../LayoutDependencyContext';
import resolveInputFromDescriptor from '../../../@Component/Domain/Multiplayer/Model/Input/Api/resolveInputFromDescriptor';
import localizeText from '../../Localization/localizeText';

export type LabelPosition = 'None' | 'Left' | 'Top';

export default class EntityInputLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref parameter: Parameter<EntityValueType>;
    @observable.ref field: Input;
    @observable labelPosition: LabelPosition;
    @observable hasIntrinsicSize: boolean;

    // ------------------------ Constructor -------------------------

    constructor(parameter: Parameter<EntityValueType>,
                field: Input,
                labelPosition: LabelPosition = 'Left',
                hasIntrinsicSize = false)
    {
        super();

        this.parameter = parameter;
        this.field = field;
        this.labelPosition = labelPosition;
        this.hasIntrinsicSize = hasIntrinsicSize;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        const layoutName = localizeText('Layout.EntityInput', 'Input veld van ${parameter}', { parameter: this.parameter.getName() });

        if (this.field)
        {
            return `${layoutName}: ${this.field.getName()}`;
        }
        else
        {
            return layoutName;
        }
    }

    validate(): Validation[]
    {
        return [];
    }

    getDependencies(): Dependency[]
    {
        if (this.field)
        {
            return [
                new Dependency(
                    this.parameter,
                    this.field.toFieldPath())
            ];
        }
        else
        {
            return [];
        }
    }

    toDescriptor()
    {
        return {
            type: 'EntityInput',
            parameterId: this.parameter.id,
            field: this.field?.toDescriptor(),
            labelPosition: this.labelPosition,
            hasIntrinsicSize: this.hasIntrinsicSize
        };
    }

    static fromDescriptor(descriptor: any,
                          dependencyContext: LayoutDependencyContext)
    {
        return new EntityInputLayout(
            dependencyContext.parameterDictionary.getParameterById(descriptor.parameterId),
            descriptor.field ? resolveInputFromDescriptor(descriptor.field) : undefined,
            descriptor.labelPosition,
            descriptor.hasIntrinsicSize);
    }

    // ----------------------- Private logic ------------------------
}
