import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import TabBar from '../../../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import { EntityPath } from '../../../../../../Path/@Model/EntityPath';
import useTypes from '../../../../../../Type/Api/useTypes';
import styles from './PathSelector.module.scss';
import { EntitySelectionBuilder } from '../../../../../../Selection/Builder/EntitySelectionBuilder';
import { DataObject } from '../../../../../../../DataObject/Model/DataObject';
import { EntitySelectionResult } from '../../../../../../Selection/Model/EntitySelectionResult';
import { EntityByDate } from '../../../../../../../../../@Api/Model/Implementation/EntityByDate';
import { createNumberComparator } from '../../../../../../../../Generic/List/V2/Comparator/NumberComparator';
import useOpenedEntity from '../../../../../Context/OpenedEntity/useOpenedEntity';
import useOpenEntityCallback from '../../../../../Context/OpenedEntity/useOpenEntityCallback';
import getPhaseRelationshipDefinition from '../../../../../../../../../@Api/Entity/Bespoke/Datastore/Phase/getPhaseRelationshipDefinition';
import { classNames } from '../../../../../../../../../@Future/Util/Class/classNames';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Timeline from '../../../../../../Timeline/Timeline';
import { createInverseComparator } from '../../../../../../../../Generic/List/V2/Comparator/InverseComparator';
import { TabLabel } from '../../../../../../../../../@Future/Component/Generic/TabBar/Tab/TabLabel/TabLabel';
import { EntityPathCastNode } from '../../../../../../Path/@Model/Node/EntityPathCastNode';
import { Aggregate } from '../../../../../../../DataObject/Model/Aggregate';
import getPossiblePhaseRelationshipDefinitions from '../../../../../../../../../@Api/Entity/Bespoke/Datastore/Phase/getPossiblePhaseRelationshipDefinitions';
import { EntitySelectionAggregateResult } from '../../../../../../Selection/Model/EntitySelectionAggregateResult';
import { EntityExpansionBuilder } from '../../../../../../Selection/Builder/EntityExpansionBuilder';
import useToggle from '../../../../../../../../../@Util/Toggle/useToggle';
import IconButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { primaryColor, textSecondaryColor } from '../../../../../../../../../@Resource/Theme/Theme';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import { createStringComparator } from '../../../../../../../../Generic/List/V2/Comparator/StringComparator';
import Predicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import Parameter from '../../../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import useCombinedPaginationResult from '../../../../../../Selection/Hooks/useCombinedPaginationResult';
import HoverCard from '../../../../../../../../../@Future/Component/Generic/Card/HoverCard/HoverCard';
import { useIsMounted } from '../../../../../../../../../@Util/Async/useIsMounted';
import { Collapse } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PathSelectorExpansionButton from './ExpansionButton/PathSelectorExpansionButton';
import useAsyncResult from '../../../../../../../../../@Util/Async/useAsyncResult';
import { Setting as SettingCode, Setting } from '../../../../../../../../../@Api/Settings/Setting';
import getMetadataSettingValue from '../../../../../../../../../@Api/Metadata/getMetadataSettingValue';
import { EntityType } from '../../../../../../../../../@Api/Model/Implementation/EntityType';
import Layout from '../../../../../../../../../@Api/Layout/Layout';
import getLayoutFromDescriptor from '../../../../../../../../../@Api/Layout/Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../../../../../@Api/Layout/LayoutDependencyContext';
import { getEntityLayoutParameter } from '../../../../../../Layout/Api/getEntityLayoutParameter';
import ParameterDictionary from '../../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { getInitializationPathsForParameterFromLayout } from '../../../../../../../../../@Api/Layout/Api/getInitializationPathsForParameterFromLayout';
import ConditionalComputation from '../../../../../../../../../@Api/Automation/Function/Computation/ConditionalComputation';
import ValueFromEntityComputation from '../../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import ConditionalInComputation from '../../../../../../../../../@Api/Automation/Function/Computation/ConditionalInComputation';
import InstanceOfPredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/InstanceOfPredicate';
import { getRootNodeFromSelection } from '../../../../../../../../../@Api/Selection/Api/getRootNodeFromSelection';
import useSetting from '../../../../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../../../../Setting/SettingStore';
import useLocalSetting from '../../../../../../../Setting/Api/useLocalSetting';

export type RelatedActivitiesSegment = 'pinned' | 'open' | 'closed';

export interface PathSelectorProps
{
    entity: Entity;
    paths: EntityPath[];
    segment: RelatedActivitiesSegment;
    component: (paths: EntityPath[], entitiesByDate: EntityByDate[], isAll: boolean, areNotesOpenByDefault: boolean) => React.ReactNode;
    label: React.ReactNode;
    onTotalCount: (totalCount: number) => void;
    filter?: (parameter: Parameter<EntityValueType>, path: EntityPath) => Predicate | undefined;
    compact?: boolean;
}

const useStyles = makeStyles({
    chipButton:{
        width: 30
    }
});

interface LayoutInformation
{
    parameter: Parameter<EntityValueType>;
    layout: Layout;
}

const PathSelector: React.FC<PathSelectorProps> =
    props =>
    {
        const types = useTypes();
        const isMounted = useIsMounted();
        const classes = useStyles();

        const [ areNotesOpenByDefault, setNotesOpenByDefault ] =
            useLocalSetting<boolean>(
                `PathSelector.${props.segment}`,
                props.segment !== 'closed'
        );

        const toggleNotesOpenByDefault =
            useCallback(
                () =>
                {
                    setNotesOpenByDefault(!areNotesOpenByDefault);
                },
                [
                    areNotesOpenByDefault,
                    setNotesOpenByDefault
                ]);

        const [ isHeaderExpanded, toggleHeaderExpansion ] = useToggle(true);

        const [ tab, _setTab ] = useState('all');

        const filteredPaths =
            useComputed(
                () =>
                    props.segment === 'open'
                        ?
                            props.paths
                                .filter(
                                    path =>
                                        getPhaseRelationshipDefinition(path.entityType) !== undefined
                                            || path.entityType.isA(types.TimeRegistration.Type))
                        :
                            props.paths,
                [
                    props.segment,
                    props.paths
                ]);

        const openEntity = useOpenEntityCallback();
        const setTab =
            useCallback(
                (tab: any) =>
                {
                    openEntity(undefined);
                    _setTab(tab);
                },
                [
                    openEntity,
                    _setTab
                ]);

        const paths =
            useMemo(
                () =>
                    tab === 'all'
                        ?
                            filteredPaths
                        :
                            [
                                filteredPaths.find(p => p.id === tab)
                            ].filter(path => path !== undefined),
                [
                    tab,
                    filteredPaths
                ]);

        const [ layoutByEntityType, isLoadingLayouts ] =
            useAsyncResult(
                async () =>
                    new Map<EntityType, LayoutInformation | undefined>(
                        await Promise.all<[EntityType, LayoutInformation | undefined]>(
                            paths.map(
                                async path =>
                                {
                                    const descriptor: any =
                                        getMetadataSettingValue(
                                            path.entityType,
                                            Setting.Metadata.ActivityListItemLayout
                                        );

                                    if (descriptor)
                                    {
                                        const parameter = getEntityLayoutParameter(path.entityType);
                                        const layout =
                                            await getLayoutFromDescriptor(
                                                descriptor,
                                                new LayoutDependencyContext(
                                                    new ParameterDictionary([
                                                        parameter
                                                    ])
                                                )
                                            );

                                        return [
                                            path.entityType,
                                            {
                                                parameter,
                                                layout,
                                            }
                                        ] as [EntityType, LayoutInformation | undefined]
                                    }
                                    else
                                    {
                                        return [
                                            path.entityType,
                                            undefined,
                                        ] as [EntityType, LayoutInformation | undefined];
                                    }
                                }
                            )
                        )
                    ),
                [
                    paths
                ]
            );

        const [ isOpenRelatedActivityListAscendingSort ] =
            useSetting<boolean>(
                SettingSource.Organization,
                SettingCode.IsOpenRelatedActivityListAscendingSort
            );
        const sortOrderIsAscending =
            useMemo(
                () =>
                    props.segment !== 'closed'
                        ? isOpenRelatedActivityListAscendingSort
                        : false,
                [
                    props.segment,
                    isOpenRelatedActivityListAscendingSort,
                ]
            );
        const getSortExpressions =
            useCallback(
                (selectionBuilder: EntitySelectionBuilder) =>
                {
                    const rootNode = getRootNodeFromSelection(selectionBuilder.selection);
                    const rootPath = EntityPath.fromEntityType(selectionBuilder.entityType);

                    const dateSortExpressions = {
                        expression:
                            new ValueFromEntityComputation(
                                rootNode.parameter,
                                rootPath.field(
                                    rootPath.entityType.bespoke.getTimelineDateField(props.segment === 'open').field
                                )
                            ),
                        isAscending: sortOrderIsAscending,
                    };

                    if (tab === 'all')
                    {
                        const typeOfEntity =
                            new ValueFromEntityComputation(
                                selectionBuilder.getNodeByPath(rootPath).parameter,
                                rootPath.field(types.Entity.Field.Type)
                            );
                        const conditionalFields =
                            props.segment === 'open'
                                ? [
                                    types.Activity.Appointment.Type.bespoke.getTimelineDateField(true).field,
                                    types.Activity.Task.Type.bespoke.getTimelineDateField(true).field,
                                ]
                                : [
                                    types.Activity.Email.Type.bespoke.getTimelineDateField(false).field,
                                    types.Activity.Type.bespoke.getTimelineDateField(false).field,
                                ];
                        const conditionals =
                            conditionalFields
                                .map(
                                    field =>
                                    {
                                        const pathToParameter = rootPath.castTo(field.entityType);
                                        const node = selectionBuilder.joinAndGet(pathToParameter);

                                        return new ConditionalInComputation(
                                            new InstanceOfPredicate(
                                                typeOfEntity,
                                                new EntityValueType(field.entityType)
                                            ),
                                            new ValueFromEntityComputation(
                                                node.parameter,
                                                rootPath.field(field)
                                            ),
                                        )
                                    }
                                );

                        const otherwise =
                            new ValueFromEntityComputation(
                                getRootNodeFromSelection(selectionBuilder.selection).parameter,
                                EntityPath.fromEntityType(selectionBuilder.entityType)
                                    .field(types.EntityType.Type.bespoke.getTimelineDateField(props.segment === 'open').field)
                            );

                        return [
                            {
                                expression:
                                    new ConditionalComputation(
                                        conditionals,
                                        otherwise
                                    ),
                                isAscending: sortOrderIsAscending,
                            },
                            dateSortExpressions
                        ];
                    }
                    else
                    {
                        return [
                            dateSortExpressions
                        ];
                    }
                },
                [
                    props.segment,
                    sortOrderIsAscending,
                    tab,
                    types,
                ]
            );

        const getGeneralSelectionBuilder =
            useCallback(
                (path: EntityPath) =>
                {
                    const rootPath = EntityPath.fromEntityType(path.entityType);
                    const parameter = new Parameter('Entity', new EntityValueType(rootPath.entityType), true, undefined);
                    const filter = props.filter ? props.filter(parameter, path) : undefined;

                    const relationEntity =
                        props.segment !== 'open'
                        && props.entity.entityType.isA(types.Relationship.Type)
                        && (path.entityType.isA(types.Note.Type) || path.entityType.isA(types.Attachment.Type))
                            ? types.Relationship.Function.GetRelation(props.entity)
                            : undefined;

                    const selectionBuilder =
                        new EntitySelectionBuilder(path.entityType)
                            .if(
                                () => relationEntity === undefined,
                                sb =>
                                    sb.where(
                                        cb =>
                                            cb.relatedToEntity(
                                                path.reverse(),
                                                props.entity
                                            )
                                    )
                            )
                            .if(
                                () => relationEntity !== undefined,
                                sb =>
                                    sb.where(
                                        cb =>
                                            cb.or(
                                                ob =>
                                                    ob
                                                        .relatedToEntity(
                                                            path.reverse(),
                                                            props.entity
                                                        )
                                                        .relatedToEntity(
                                                            path.reverse(),
                                                            relationEntity
                                                        )
                                            )
                                    )
                            )
                            .if(
                                () => filter !== undefined,
                                sb =>
                                    sb.where(
                                        cb =>
                                            cb.filter(
                                                filter,
                                                {
                                                    parameter,
                                                }
                                            )
                                    )
                            )
                            .join(
                                rootPath
                                    .joinTo(
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.CreatedEntities,
                                        true
                                    )
                            )
                            .if(
                                () =>
                                    layoutByEntityType !== undefined &&
                                        layoutByEntityType.has(rootPath.entityType),
                                sb =>
                                {
                                    const information = layoutByEntityType.get(rootPath.entityType);

                                    if (information)
                                    {
                                        getInitializationPathsForParameterFromLayout(
                                            information.layout,
                                            information.parameter
                                        ).forEach(
                                            path =>
                                                sb.join(path)
                                        );
                                    }
                                }
                            );

                    if (props.segment === 'pinned')
                    {
                        selectionBuilder
                            .where(
                                sb =>
                                    sb.and(
                                    ab =>
                                        ab.isOfType(rootPath, types.Note.Type)
                                    )
                                    .eq(
                                        EntityPath.fromEntityType(path.entityType)
                                            .field(types.Note.Field.IsPinned),
                                        undefined,
                                        props.segment === 'pinned'
                                    )
                            )
                    }
                    else if (path.entityType.isA(types.TimeRegistration.Type))
                    {
                        selectionBuilder
                            .where(
                                cb =>
                                    cb.eq(
                                        EntityPath.fromEntityType(path.entityType)
                                            .field(types.TimeRegistration.Field.IsProcessed),
                                        undefined,
                                        props.segment === 'closed'
                                    )
                            );
                    }
                    else if (path.entityType.isA(types.Activity.Type))
                    {
                        selectionBuilder
                            .where(
                                cb =>
                                    cb.eq(
                                        rootPath.field(types.Entity.Field.IsClosed),
                                        undefined,
                                        props.segment === 'closed'
                                    )
                            )
                            .if(
                                () => types.Activity.Task.Type.isA(path.entityType),
                                sb =>
                                    sb
                                        .join(
                                            sb.rootPath
                                                .castTo(types.Activity.Task.Type)
                                                .joinTo(
                                                    types.Activity.Task.RelationshipDefinition.Type,
                                                    false
                                                )
                                        )
                                        .join(
                                            sb.rootPath
                                                .castTo(types.Activity.Task.Type)
                                                .joinTo(
                                                    types.Activity.Task.RelationshipDefinition.Assignee,
                                                    false
                                                )
                                        )
                            )
                            .if(
                                () => types.Activity.Appointment.Type.isA(path.entityType),
                                sb =>
                                    sb
                                        .join(
                                            sb.rootPath
                                                .castTo(types.Activity.Appointment.Type)
                                                .joinTo(
                                                    types.Activity.Appointment.RelationshipDefinition.Owner,
                                                    false
                                                )
                                        )
                            );
                    }

                    const sortSpecifications = getSortExpressions(selectionBuilder);

                    for (const sortSpecification of sortSpecifications)
                    {
                        selectionBuilder.orderByExpression(sortSpecification.expression, sortSpecification.isAscending);
                    }

                    return selectionBuilder;
                },
                [
                    props.entity,
                    props.segment,
                    props.filter,
                    types,
                    isLoadingLayouts,
                    layoutByEntityType,
                    getSortExpressions,
                ]);

        const processGeneralSelection =
            useCallback(
                async (path: EntityPath,
                 results: EntitySelectionResult[]) =>
                {
                    const rootPath = EntityPath.fromEntityType(path.entityType);
                    const fetchPaths: EntityPath[] = [];

                    fetchPaths.push(
                        ...path.entityType.bespoke.getListDependencies());

                    if (path.entityType.isA(types.Note.Type)
                        || path.entityType.isA(types.Attachment.Type))
                    {

                    }
                    else if (path.entityType.isA(types.TimeRegistration.Type))
                    {

                    }
                    else if (path.entityType.isA(types.Activity.Type))
                    {
                        const phaseRelationshipDefinitions = getPossiblePhaseRelationshipDefinitions(path.entityType);

                        if (types.Activity.Task.Type.isA(path.entityType))
                        {
                            fetchPaths.push(
                                rootPath
                                    .castTo(types.Activity.Task.Type)
                                    .joinTo(
                                        types.Activity.Task.RelationshipDefinition.Type,
                                        false),
                                rootPath
                                    .castTo(types.Activity.Task.Type)
                                    .joinTo(
                                        types.Activity.Task.RelationshipDefinition.Assignee,
                                        false));
                        }

                        if (types.Activity.Appointment.Type.isA(path.entityType))
                        {
                            fetchPaths.push(
                                rootPath
                                    .castTo(types.Activity.Appointment.Type)
                                    .joinTo(
                                        types.Activity.Appointment.RelationshipDefinition.Owner,
                                        false
                                    ),
                                rootPath
                                    .castTo(types.Activity.Appointment.Type)
                                    .joinTo(
                                        types.Activity.Appointment.RelationshipDefinition.Address,
                                        false
                                    )
                                    .joinTo(
                                        types.Address.RelationshipDefinition.Country,
                                        false
                                    )
                                );
                        }

                        fetchPaths.push(
                            ...phaseRelationshipDefinitions
                                .filter(
                                    phaseRelationshipDefinition =>
                                        phaseRelationshipDefinition.parentEntityType.isA(path.entityType))
                                .map(
                                    phaseRelationshipDefinition =>
                                        rootPath
                                            .castTo(phaseRelationshipDefinition.parentEntityType)
                                            .joinTo(
                                                phaseRelationshipDefinition,
                                                false)));

                        if (areNotesOpenByDefault)
                        {
                            fetchPaths.push(
                                rootPath
                                    .joinTo(
                                        types.Entity.RelationshipDefinition.Notes,
                                        false)
                                    .joinTo(
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.CreatedEntities,
                                        true));
                        }
                    }

                    const expansionBuilder =
                        new EntityExpansionBuilder(
                            path.entityType,
                            results.map(
                                result =>
                                    result.entity),
                            fetchPaths);

                    return expansionBuilder
                        .expand()
                        .then(
                            () =>
                                Promise.resolve(
                                    () =>
                                        expansionBuilder.dispose()
                                ));
                },
                [
                    props.entity,
                    types,
                    areNotesOpenByDefault
                ]);

        const [ condensedPaths, pathsByCondensedPathId ] =
            useComputed(
                () =>
                {
                    const condensedPathIds = new Set<string>();
                    const pathsByCondensedPathId = new Map<string, EntityPath[]>();
                    const condensedPaths: EntityPath[] = [];

                    filteredPaths
                        .forEach(
                            path =>
                            {
                                let condensedPath;

                                if (path.lastNode instanceof EntityPathCastNode)
                                {
                                    condensedPath = path.getPathUntilNode(path.lastNode);
                                }
                                else
                                {
                                    condensedPath = path;
                                }

                                if (!pathsByCondensedPathId.has(condensedPath.id))
                                {
                                    pathsByCondensedPathId.set(condensedPath.id, []);
                                }

                                pathsByCondensedPathId.get(condensedPath.id).push(path);

                                if (!condensedPathIds.has(condensedPath.id))
                                {
                                    condensedPathIds.add(condensedPath.id);
                                    condensedPaths.push(condensedPath);

                                }
                            });

                    return [ condensedPaths, pathsByCondensedPathId ];
                },
                [
                    filteredPaths
                ]);

        const [ countResults, setCountResults ] = useState<EntitySelectionAggregateResult[]>();
        const countByPath =
            useComputed(
                () =>
                {
                    const countMap = new Map<EntityPath, DataObject>();

                    if (countResults)
                    {
                        countResults.forEach(
                            (result, idx) =>
                            {
                                const condensedPath = condensedPaths[idx];

                                pathsByCondensedPathId.get(condensedPath.id)
                                    .forEach(
                                        path =>
                                        {
                                            countMap.set(
                                                path,
                                                DataObject.constructFromTypeIdAndValue(
                                                    'Number',
                                                    result
                                                        ?
                                                            result.children
                                                                .filter(
                                                                    child =>
                                                                        child.groupValue?.value
                                                                        && child.groupValue.value.isA(path.entityType))
                                                                .reduce((a, b) => a + b.aggregates[0].value as number, 0)
                                                        :
                                                            0));
                                        });
                            });
                    }

                    return countMap;
                },
                [
                    countResults,
                    condensedPaths,
                    pathsByCondensedPathId
                ]);

        useEffect(
            () =>
            {
                if (!isLoadingLayouts)
                {
                    Promise.all(
                        condensedPaths
                            .map(
                                path =>
                                {
                                    const selectionBuilder = getGeneralSelectionBuilder(path);

                                    if (selectionBuilder)
                                    {
                                        return selectionBuilder
                                            .groupBy(
                                                EntityPath.root(path.entityType)
                                                    .field(types.Entity.Field.Type))
                                                .aggregateOn(
                                                    EntityPath.root(path.entityType)
                                                        .field(types.Entity.Field.Id),
                                                    undefined,
                                                    Aggregate.Count)
                                                .selectAggregates();
                                    }
                                    else
                                    {
                                        return undefined;
                                    }
                                }
                            )
                            .filter(builder => builder)
                    )
                    .then(
                        results =>
                        {
                            if (isMounted())
                            {
                                setCountResults(results);
                            }
                        }
                    );
                }
            },
            [
                isLoadingLayouts,
                condensedPaths,
                getGeneralSelectionBuilder,
                types,
                setCountResults,
                isMounted
            ]);

        const totalCount =
            useComputed(
                () =>
                    Array.from(countByPath.values())
                        .map(
                            value => value.value)
                        .reduce((a, b) => a + b, 0)
                    // creation item when props.segment is closed
                    + (props.segment === 'closed' ? 1 : 0),
                [
                    countByPath,
                    props.segment
                ]);

        useEffect(
            () =>
            {
                if (countByPath.size > 0)
                {
                    props.onTotalCount(totalCount);
                }
            },
            [
                props.onTotalCount,
                totalCount,
                countByPath
            ]);

        const showAllTab =
            useComputed(
                () =>
                    props.segment === 'closed'
                    || Array.from(countByPath.values())
                        .filter(
                            count => count.value > 0).length > 1,
                [
                    props.segment,
                    countByPath
                ]);

        const showAuditTrailTab =
            useMemo(
                () =>
                    props.segment === 'closed',
                [
                    props.segment
                ]);

        const { results, isLoading, hasMore, loadMore } =
            useCombinedPaginationResult(
                isLoadingLayouts
                    ?
                        []
                    :
                        (tab === 'all' ? condensedPaths : paths)
                            .map(
                                path => ({
                                    // Check if count results exists, otherwise the selections are performed twice
                                    type: countResults ? path.entityType : undefined,
                                    callback:
                                        builder =>
                                        {
                                            const newBuilder = getGeneralSelectionBuilder(path);

                                            if (newBuilder)
                                            {
                                                builder.selection = newBuilder.selection;
                                                builder.ordering = newBuilder.ordering;
                                            }
                                        },
                                    processResults:
                                        results =>
                                            processGeneralSelection(path, results)
                                }
                                )
                            ),
                [
                    tab,
                    countResults,
                    condensedPaths,
                    paths,
                    getGeneralSelectionBuilder,
                    processGeneralSelection
                ]);

        const resultSets =
            useComputed(
                () =>
                    results.map(
                        result =>
                            result.pages()
                                .map(
                                    page =>
                                        page.results.slice())
                                .reduce((a, b) => a.concat(b), [])),
                [
                    results
                ]);

        const dateComparator =
            useMemo(
                () =>
                    sortOrderIsAscending
                        ? createNumberComparator<EntityByDate>(d => d.date)
                        : createInverseComparator(createNumberComparator<EntityByDate>(d => d.date)),
                [
                    sortOrderIsAscending,
                ]
            );
        const nameComparator =
            useMemo(
                () => createStringComparator<EntityByDate>(d => d.entity.name),
                []);
        const comparator =
            useCallback(
                (a: EntityByDate, b: EntityByDate) =>
                {
                    const result = dateComparator(a, b);

                    if (result === 0)
                    {
                        return nameComparator(a, b);
                    }
                    else
                    {
                        return result;
                    }
                },
                [
                    dateComparator,
                    nameComparator
                ]);
        const entitiesByDate =
            useComputed(
                () =>
                    resultSets
                        .map(
                            (set, ) =>
                                set
                                    .map(
                                        result =>
                                        {
                                            const entity = result.entity;

                                            return new EntityByDate(
                                                entity,
                                                (entity.getObjectValueByField(
                                                    entity.entityType.bespoke.getTimelineDateField(props.segment === 'open').field
                                                ) as Date)?.getTime()
                                            );
                                        }
                                    )
                        )
                        .reduce((a, b) => a.concat(b), [])
                        .sort(comparator),
                [
                    resultSets,
                    types,
                    comparator,
                    paths,
                    tab,
                    props.segment
                ]);

        const openedEntity = useOpenedEntity();

        const tabFieldPaths =
            useComputed(
                () =>
                    filteredPaths
                        .filter(
                            path =>
                                countByPath.has(path) && countByPath.get(path).value > 0
                        ),
                [
                    filteredPaths,
                    countByPath
                ]);

        // Initially set the right tab
        const singleTabPath =
            useComputed(
                () =>
                    tabFieldPaths
                        .find(
                            path =>
                                countByPath.has(path)
                                && countByPath.get(path).value === totalCount),
                [
                    tabFieldPaths,
                    countByPath,
                    totalCount
                ]);

        useEffect(
            () =>
            {
                if (singleTabPath
                    && props.segment === 'open')
                {
                    setTab(singleTabPath.id);
                }
            },
            [
                props.segment,
                singleTabPath,
                setTab
            ]);

        // Once a new entity is opened, then we might need to switch tabs
        useEffect(
            () =>
            {
                if (openedEntity
                    && tab !== 'all'
                    && tab !== 'AuditTrial'
                    && tabFieldPaths.find(p => p.id === tab)
                    && !openedEntity.entityType.isA(tabFieldPaths.find(p => p.id === tab).entityType))
                {
                    const path = tabFieldPaths.find(path => openedEntity.entityType.isA(path.entityType));

                    if (path)
                    {
                        setTab(path.id);
                    }
                }
            },
            [
                tabFieldPaths,
                tab,
                setTab,
                openedEntity
            ]);

        const numberOfVisibleTabs =
            useMemo(
                () =>
                {
                    let count = 0;

                    if (showAllTab)
                    {
                        count++;
                    }
                    count += tabFieldPaths.length;

                    if (showAuditTrailTab)
                    {
                        count++;
                    }

                    return count;
                },
                [
                    showAllTab,
                    tabFieldPaths,
                    showAuditTrailTab
                ]);

        const allTabCount =
            useMemo(
                () =>
                    totalCount,
                [
                    totalCount
                ]);

        // Do not show the open timeline if there are no records
        if (props.segment !== 'closed'
            && totalCount < 1
            && entitiesByDate.length === 0) // also check entitiesByDate, because the total count is based on the aggregates
        {
            return null;
        }

        return <div
            className={classNames(styles.root, props.compact && styles.compact)}
        >

            <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        alignment="center"
                        justification="center"
                        spacing={15}
                    >
                        <ViewGroupItem>
                            <PathSelectorExpansionButton
                                expanded={isHeaderExpanded}
                                onClick={toggleHeaderExpansion}
                                label={props.label}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem
                            className={classes.chipButton}
                        >
                            <span>
                                {
                                    !props.compact && isHeaderExpanded &&
                                            <IconButton
                                                icon="comment"
                                                color={
                                                    areNotesOpenByDefault
                                                        ?
                                                            primaryColor
                                                        :
                                                            textSecondaryColor
                                                }
                                                onClick={toggleNotesOpenByDefault}
                                                tooltip={
                                                    areNotesOpenByDefault
                                                    ?
                                                        <LocalizedText
                                                            code="Notes.HideAll"
                                                            value="Alle notities verbergen"
                                                        />
                                                    :
                                                        <LocalizedText
                                                            code="Notes.ShowAll"
                                                            value="Alle notities tonen"
                                                        />
                                                }
                                            />

                                }
                            </span>
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
                <Collapse in={isHeaderExpanded} timeout="auto" unmountOnExit>
                    <ViewGroupItem>
                    {
                        numberOfVisibleTabs > 1 &&
                            <div
                                className={styles.tabBar}
                            >
                                <TabBar
                                    value={tab === 'all' && !showAllTab ? undefined : tab}
                                    centered
                                    bordered={false}
                                >
                                    {
                                        showAllTab &&
                                            <Tab
                                                value="all"
                                                onClick={setTab}
                                            >
                                                <TabLabel
                                                    label={
                                                        <LocalizedText
                                                            code="Generic.All"
                                                            value="Alles"
                                                        />
                                                    }
                                                    secondary={allTabCount > 0 ? allTabCount : ''}
                                                />
                                            </Tab>
                                    }
                                    {
                                        tabFieldPaths
                                            .map(
                                                path =>
                                                    <Tab
                                                        key={path.id}
                                                        value={path.id}
                                                        onClick={setTab}
                                                    >
                                                        <TabLabel
                                                            label={path.entityType.getName(true)}
                                                            secondary={countByPath.get(path).toString()}
                                                        />
                                                    </Tab>)
                                    }
                                    {
                                        showAuditTrailTab &&
                                            <Tab
                                                value="AuditTrail"
                                                onClick={setTab}
                                            >
                                                <LocalizedText
                                                    code="Generic.AuditTrail"
                                                    value="Audit trail"
                                                />
                                            </Tab>
                                    }
                                </TabBar>
                            </div>
                    }
                    <div
                        className={styles.data}
                    >
                        {
                            tab === 'AuditTrail'
                                ?
                                    <Timeline
                                        entity={props.entity}
                                    />
                                :
                                    props.component(paths, entitiesByDate, tab === 'all', areNotesOpenByDefault)

                        }
                    </div>
                    </ViewGroupItem>
                    {
                        hasMore &&
                            <ViewGroupItem>
                                <div
                                    className={styles.loadMoreButton}
                                >
                                    <HoverCard
                                        onClick={loadMore}
                                        disabled={isLoading}
                                    >
                                        <LocalizedText
                                            code="Generic.LoadMore"
                                            value="Meer laden"
                                        />...
                                    </HoverCard>
                                </div>
                            </ViewGroupItem>
                    }
                </Collapse>
            </ViewGroup>

        </div>;
    };

export default observer(PathSelector);
