import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { useContext } from 'react';
import EntityTypeContext from '../../Type/EntityTypeContext';
import useAllFieldGroups from './useAllFieldGroups';
import { createStringComparator } from '../../../../Generic/List/V2/Comparator/StringComparator';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import equalsEntity from '../../../../../@Api/Entity/Bespoke/equalsEntity';
import getTypes from '../../Type/Api/getTypes';
import { useInheritedAddressRelationshipDefinitions } from './useInheritedAddressRelationshipDefinitions';

export default function useNonEmptyFieldGroups(
    entity: Entity,
    fieldPaths: EntityFieldPath[],
    forType?: EntityType
)
{
    const types= getTypes();
    const allFieldGroups = useAllFieldGroups(forType || entity.entityType);
    const entityTypeStore = useContext(EntityTypeContext);

    // Related addresses are added hardcoded in Expansion.tsx, gather them here also to determine if group should still be visible
    const inheritedAddressRelationshipDefinitions =
        useInheritedAddressRelationshipDefinitions(entity);

    const addressRelationshipFieldGroupEntities =
        useComputed(
            () =>
                inheritedAddressRelationshipDefinitions
                    .map(
                        relationshipDefinition =>
                            relationshipDefinition.entity.getRelatedEntityByDefinition(
                                true,
                                types.EntityFieldGroup.RelationshipDefinition.Relationships
                            )
                    ),
                [
                    types,
                    inheritedAddressRelationshipDefinitions
                ]
        );

    return useComputed(
        () =>
        {
            return allFieldGroups
                .filter(
                    fieldGroup =>
                        fieldPaths.some(
                            path =>
                                path.isInFieldGroup(fieldGroup, entityTypeStore) ||
                                addressRelationshipFieldGroupEntities.some(
                                    groupEntity =>
                                        equalsEntity(groupEntity, fieldGroup.entity)
                                )
                        )
                )
                .sort(createStringComparator(a => a.name));
        },
        [
            allFieldGroups,
            fieldPaths,
            entityTypeStore
        ]);
}
