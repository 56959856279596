import Widget from '../../Widget';
import { observable } from 'mobx';
import collectAndInitializeByEntityIds from '../../../Api/collectAndInitializeByEntityIds';
import CustomWidgetSpecification from './Model/CustomWidgetSpecification';
import getTypes from '../../../../Type/Api/getTypes';
import getCustomWidgetParameters from '../../../../../../../@Api/Dashboard/CustomWidget/getCustomWidgetParameters';
import LayoutDependencyContext from '../../../../../../../@Api/Layout/LayoutDependencyContext';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';

export default class CustomWidget extends Widget
{
    // ------------------------- Properties -------------------------

    @observable widgetId: string;
    @observable.ref parameters?: ParameterDictionary;
    @observable.ref specification?: CustomWidgetSpecification;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                widgetId: string,
                parameters?: ParameterDictionary,
                specification?: CustomWidgetSpecification)
    {
        super(id);

        this.widgetId = widgetId;
        this.parameters = parameters;
        this.specification = specification;

        if (specification)
        {
            this.isInitialized = true;
        }
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isFullWidth(): boolean
    {
        if (this.specification)
        {
            return this.specification.isFullWidth;
        }
        else
        {
            return false;
        }
    }

    async doInitialize(widgets: CustomWidget[])
    {
        const types = getTypes();
        const parameters = getCustomWidgetParameters();
        const dependencyContext = new LayoutDependencyContext(parameters);

        return collectAndInitializeByEntityIds(
            widgets,
            widget => widget.widgetId,
            async (widget, entity) =>
            {
                if (entity.hasValueForField(types.CustomWidget.Field.Specification))
                {
                    widget.parameters = parameters;
                    widget.specification =
                        await CustomWidgetSpecification.fromDescriptor(
                            entity.getObjectValueByField(types.CustomWidget.Field.Specification),
                            dependencyContext
                        );
                }
            },
            types.CustomWidget.Type);
    }

    static fromDescriptor(descriptor: any)
    {
        return new CustomWidget(
            descriptor.id,
            descriptor.widgetId
        );
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Custom',
            widgetId: this.widgetId
        }
    }

    // ----------------------- Private logic ------------------------
}
