import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import DragHandlePropsContext from '../Context/DragHandlePropsContext';
import Icon from '../../../../@Future/Component/Generic/Icon/Icon';
import styles from './DragHandle.module.scss';
import { veryLightTextColor } from '../../../../@Resource/Theme/Theme';
import { classNames } from '../../../../@Future/Util/Class/classNames';

export interface DragHandleProps extends Partial<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
{
    horizontal?: boolean;
    className?: string;
}

const DragHandle: React.FC<DragHandleProps> =
    props =>
    {
        const dragHandleProps = useContext(DragHandlePropsContext);
        const { horizontal, ...divProps } = props;

        return <div
            {...divProps}
            className={classNames(styles.root, props.className)}
            {...dragHandleProps}
        >
            {
                props.children
                    ?
                        props.children
                    :
                        <Icon
                            icon={props.horizontal ? 'drag_handle' : 'drag_indicator'}
                            color={veryLightTextColor}
                        />
            }
        </div>;
    };

export default observer(DragHandle);
