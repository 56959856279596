import { observable } from 'mobx';
import KeyField from './KeyField';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';

export default class Key
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable fields: KeyField[];

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                fields: KeyField[])
    {
        this.id = id;
        this.fields = fields;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext,
    )
    {
        return new Key(
            descriptor.id,
            await Promise.all(
                descriptor.fields.map(
                    field =>
                        KeyField.fromDescriptor(
                            field,
                            dependencyContext
                        )
                )
            )
        );
    }

    toDescriptor()
    {
        return {
            id: this.id,
            fields:
                this.fields.map(
                    fieldPath =>
                        fieldPath.toDescriptor())
        };
    }

    // ----------------------- Private logic ------------------------
}
