import React, { useCallback, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../../Viewer/LayoutViewer';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import PortalDataSourceListQuery from '../../../../../../@Api/Portal/DataSource/PortalDataSourceListQuery';
import uuid from '../../../../../../@Util/Id/uuid';
import { PortalTableLayoutViewerForQuery } from './ForQuery/PortalTableLayoutViewerForQuery';
import { PortalTableLayoutViewerPageSize } from './Constants/PortalTableLayoutViewerConstants';
import PortalTableLayout from '../../../../../../@Api/Layout/Type/PortalTable/PortalTableLayout';
import PortalDataSourceOrdering from '../../../../../../@Api/Portal/DataSource/PortalDataSourceOrdering';
import { mapBy } from '../../../../../../@Util/MapUtils/mapBy';
import { Table, TableBody } from '@material-ui/core';
import { PortalTableHeader } from './Header/PortalTableHeader';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import SearchBar from '../../../../../../@Future/Component/Generic/SearchBar/SearchBar';
import CompositePredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../DataObject/Model/LogicalOperator';
import { getSearchTermsFromQuery } from '../../../../../../@Util/Search/getSearchTermsFromQuery';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import { Comparator } from '../../../../DataObject/Model/Comparator';
import PrimitiveValue from '../../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import { PortalTableOrdering } from '../../../../../../@Api/Layout/Type/PortalTable/Model/PortalTableOrdering';
import { PortalTableColumn } from '../../../../../../@Api/Layout/Type/PortalTable/Model/PortalTableColumn';

export interface PortalTableLayoutViewerProps extends LayoutViewerProps<PortalTableLayout>
{

}

const PortalTableLayoutViewer: React.FC<PortalTableLayoutViewerProps> =
    props =>
    {
        const rowParameterDictionary =
            useComputed(
                () =>
                    ParameterDictionary.union(
                        props.parameterDictionary,
                        props.layout.dataSourceSignature.resultParameters,
                        new ParameterDictionary([ props.layout.rowParameter ])
                    ),
                [
                    props.parameterDictionary,
                    props.layout
                ]
            );
        const [ queryParameterAssignment ] =
            useAsyncResult(
                () =>
                    props.layout.parameterAssignment.apply(
                        new FunctionContext(
                            props.parameterDictionary,
                            props.parameterAssignment,
                            props.commitContext
                        )
                    ),
                [
                    props.layout,
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]
            );
        const columnById =
            useComputed(
                () =>
                    mapBy(
                        props.layout.columns,
                        column =>
                            column.id
                    ),
                [
                    props.layout
                ]
            );
        const [ currentOrdering, setCurrentOrdering ] =
            useState<PortalTableOrdering | undefined>(
                () =>
                    props.layout.orderings
                        .reverse()
                        .find(() => true)
            );
        const orderings =
            useMemo(
                () => [
                    ...props.layout.orderings.slice(0, -1),
                    ...currentOrdering === undefined
                        ? []
                        : [ currentOrdering ]
                ],
                [
                    props.layout.orderings,
                    currentOrdering,
                ]
            );
        const [ searchQuery, setSearchQuery ] = useState('');
        const [
            filterByColumn,
            setFilterByColumn
        ] = useState(
            () =>
                new Map<PortalTableColumn, string>()
        );
        const onFilter =
            useCallback(
                (column: PortalTableColumn, filterQuery: string | undefined) =>
                {
                    const newFilterByColumn = new Map(filterByColumn);

                    if (filterQuery === undefined)
                    {
                        newFilterByColumn.delete(column);
                    }
                    else
                    {
                        newFilterByColumn.set(
                            column,
                            filterQuery
                        );
                    }

                    setFilterByColumn(newFilterByColumn);
                },
                [
                    filterByColumn,
                ]
            );
        const query =
            useMemo(
                () =>
                {
                    if (queryParameterAssignment === undefined)
                    {
                        return undefined;
                    }
                    else
                    {
                        const searchColumns =
                            props.layout.columns
                                .filter(
                                    column =>
                                        column.isSearchable
                                );
                        const predicates = [
                            ...getPredicatesForColumnsAndSearchQuery(
                                searchColumns,
                                searchQuery
                            ),
                            ...Array.from(filterByColumn.entries())
                                .map(
                                    ([ column, filterQuery ]) =>
                                        getPredicatesForColumnsAndSearchQuery(
                                            [ column ],
                                            filterQuery
                                        )
                                )
                                .reduce(
                                    (a, b) =>
                                        a.concat(b),
                                    []
                                )
                        ];

                        return new PortalDataSourceListQuery(
                            uuid(),
                            props.layout.dataSourceSignature,
                            queryParameterAssignment,
                            predicates.length > 0
                                ? new CompositePredicate(
                                    LogicalOperator.And,
                                    predicates
                                )
                                : undefined,
                            orderings.map(
                                ordering =>
                                    new PortalDataSourceOrdering(
                                        ordering.id,
                                        columnById.get(ordering.columnId).expression,
                                        ordering.direction
                                    )
                            ),
                            0,
                            PortalTableLayoutViewerPageSize
                        );
                    }
                },
                [
                    props.layout,
                    queryParameterAssignment,
                    searchQuery,
                    filterByColumn,
                    orderings,
                ]
            );

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            {
                props.layout.columns.some(
                    column =>
                        column.isSearchable
                ) &&
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={0}
                    >
                        <ViewGroupItem
                            ratio={1}
                        />
                        <ViewGroupItem>
                            <SearchBar
                                onSearch={setSearchQuery}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
            }
            <ViewGroupItem>
                <div
                    style={{
                        overflowX: 'auto',
                    }}
                >
                    <Table>
                        <PortalTableHeader
                            {...props}
                            ordering={currentOrdering}
                            onOrder={setCurrentOrdering}
                            onFilter={onFilter}
                        />
                        <TableBody>
                            <PortalTableLayoutViewerForQuery
                                {...props}
                                rowParameterDictionary={rowParameterDictionary}
                                query={query}
                            />
                        </TableBody>
                    </Table>
                </div>
            </ViewGroupItem>
        </ViewGroup>;
    };

function getPredicatesForColumnsAndSearchQuery(
    columns: PortalTableColumn[],
    searchQuery: string
): CompositePredicate[]
{
    if (columns.length === 0 || searchQuery.length === 0)
    {
        return [];
    }
    else
    {
        return getSearchTermsFromQuery(searchQuery)
            .map(
                term =>
                    new CompositePredicate(
                        LogicalOperator.Or,
                        columns
                            .map(
                                column =>
                                    new ComparisonPredicate(
                                        column.expression,
                                        Comparator.Contains,
                                        new PrimitiveValue(
                                            DataObject.constructFromTypeIdAndValue(
                                                'Text',
                                                term
                                            )
                                        )
                                    )
                            )
                    )
            );
    }
}

export default observer(PortalTableLayoutViewer);
