import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../../Editor/LayoutEditor';
import InputGroup from '../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import NumberEditor from '../../../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';
import { runInAction } from 'mobx';
import CompositeLayout from '../../../../../../../@Api/Layout/Type/CompositeLayout';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import Switch from '../../../../../../../@Future/Component/Generic/Input/Switch/Switch';

export interface CompositeLayoutControlsProps extends LayoutEditorProps<CompositeLayout>
{

}

const CompositeLayoutControls: React.FC<CompositeLayoutControlsProps> =
    props =>
    {
        const setSpacing =
            useCallback(
                (value: number) =>
                    runInAction(
                        () =>
                            props.layout.spacing = value === undefined ? 0 : value),
                [
                    props.layout
                ]);

        const setHasDivider =
            useCallback(
                (value: boolean) =>
                    runInAction(
                        () =>
                            props.layout.hasDivider = value),
                [
                    props.layout
                ]);

        const setWrapped =
            useCallback(
                (value: boolean) =>
                    runInAction(
                        () =>
                            props.layout.isWrapped = value),
                [
                    props.layout
                ]);

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <Input
                    labelPosition="left"
                    label="Spacing"
                >
                    <NumberEditor
                        value={props.layout.spacing}
                        onChange={setSpacing}
                        min={0}
                    />
                </Input>
                <Input
                    labelPosition="left"
                    label="Scheidingsbalken"
                >
                    <Switch
                        checked={props.layout.hasDivider}
                        onToggle={setHasDivider}
                    />
                </Input>
                {
                    props.layout.orientation === 'Horizontal' &&
                        <Input
                            labelPosition="left"
                            label="Doorgaan op volgende regel bij te weinig ruimte"
                        >
                            <Switch
                                checked={props.layout.isWrapped}
                                onToggle={setWrapped}
                            />
                        </Input>
                }
                </InputGroup>
        </CardInset>;
    };

export default observer(CompositeLayoutControls);
