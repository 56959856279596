import { Entity } from '../../Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';

export default function useIsNew(entity: Entity)
{
    return useComputed(
        () =>
            entity.isNew(),
        [
            entity
        ]);
}