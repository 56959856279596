import React, { useCallback, useMemo, useState } from 'react';
import Dialog from '../../../../../@Future/Component/Generic/Dialog/Dialog';
import DialogTitle from '../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import DialogActions from '../../../../../@Future/Component/Generic/Dialog/Actions/DialogActions';
import constructEntity from '../../../../../@Api/Entity/constructEntity';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import { observer, useComputed } from 'mobx-react-lite';
import CancelButton from '../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import ResourceSelection from '../Model/ResourceSelection';
import Period from '../Model/Period';
import TabBar from '../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import constructEntityFromFilter from '../../../../../@Api/Entity/constructEntityFromFilter';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import PlanDialogSelector from './Selector/PlanDialogSelector';
import { CommitContextImpl } from '../../../../../@Api/Entity/Commit/Context/CommitContextImpl';
import isHiddenType from '../../../../../@Api/Metadata/EntityType/isHiddenType';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';

export interface PlanDialogProps
{
    resourceSelections: ResourceSelection[];
    period: Period;
    resource: Entity;
    parameterAssignment?: ParameterAssignment;
    onSave?: (entity: Entity) => void;
    onClose: () => void;
}

const PlanDialog: React.FC<PlanDialogProps> =
    props =>
    {
        const plannableResourceSelections =
            useComputed(
                () =>
                    props.resourceSelections.filter(
                        selection =>
                            selection.isPlannable &&
                            !isHiddenType(selection.entityType)),
                [
                    props.resourceSelections
                ]);

        const [ tab, setTab ] = useState(0);
        const create =
            useCallback(
                (resourceSelection: ResourceSelection) =>
                {
                    const commitContext = new CommitContextImpl({ allowAutoCommit: false });
                    const entity =
                        constructEntityFromFilter(
                            resourceSelection.entityType,
                            new CompositePredicate(
                                LogicalOperator.And,
                                [
                                    resourceSelection.filter,
                                    resourceSelection.highlightFilter
                                ].filter(
                                    filter =>
                                        filter !== undefined
                                )
                            ),
                            props.parameterAssignment,
                            commitContext
                        );

                    resourceSelection.startDateFieldPath
                        .setValue(
                            entity,
                            props.period.from.toDate(),
                            undefined,
                            commitContext
                        );

                    resourceSelection.endDateFieldPath
                        .setValue(
                            entity,
                            props.period.to.toDate(),
                            undefined,
                            commitContext
                        );

                    resourceSelection.resourcePath.field()
                        .setValue(
                            entity,
                            props.resource,
                            undefined,
                            commitContext
                        );

                    constructEntity(
                        entity.entityType,
                        undefined,
                        entity,
                        entity =>
                        {
                            if (props.onSave)
                            {
                                props.onSave(entity);
                            }

                            props.onClose();
                        },
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        commitContext
                    );
                },
                [
                    props.period,
                    props.resource,
                    props.parameterAssignment,
                    props.onSave,
                    props.onClose
                ]);

        const selectedResourceSelection =
            useMemo(
                () =>
                {
                    if (tab === 0)
                    {
                        return undefined;
                    }
                    else
                    {
                        return plannableResourceSelections[tab - 1];
                    }
                },
                [
                    tab
                ]);

        return <Dialog
            open
            width="lg"
            onClose={props.onClose}
        >
            <DialogTitle>
                <LocalizedText
                    code="ResourcePlanner.WhatToPlan"
                    value="Wat wil je inplannen?"
                />
            </DialogTitle>
            <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                {
                    plannableResourceSelections.length > 0 &&
                        <ViewGroupItem>
                            <TabBar
                                value={tab}
                            >
                                <Tab
                                    value={0}
                                    onClick={setTab}
                                >
                                    <LocalizedText
                                        code="Generic.New"
                                        value="Nieuw"
                                    />
                                </Tab>
                                {
                                    plannableResourceSelections
                                        .map(
                                            (resourceSelection, idx) =>
                                                <Tab
                                                    key={resourceSelection.id}
                                                    value={idx + 1}
                                                    onClick={setTab}
                                                >
                                                    {resourceSelection.entityType.getName(true)}
                                                </Tab>)
                                }
                            </TabBar>
                        </ViewGroupItem>
                }
                <ViewGroupItem>
                    {
                        tab === 0 &&
                            <Menu>
                                {
                                    plannableResourceSelections.map(
                                        resourceSelection =>
                                            <Item
                                                key={resourceSelection.id}
                                                icon={resourceSelection.entityType.getInheritedIcon()}
                                                name={resourceSelection.entityType.getName()}
                                                onClick={() => create(resourceSelection)}
                                            />)
                                }
                            </Menu>
                    }
                    {
                        tab > 0 &&
                            <PlanDialogSelector
                                {...props}
                                resourceSelection={selectedResourceSelection}
                            />
                    }
                </ViewGroupItem>
            </ViewGroup>
            <DialogActions>
                <CancelButton
                    onClick={props.onClose}
                />
            </DialogActions>
        </Dialog>;
    };

export default observer(PlanDialog);
