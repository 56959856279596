import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Selection } from '../../../../@Api/Selection/Model/Selection';
import { default as GenericSelectbox, SelectboxProps as GenericSelectboxProps, SelectionValue } from '../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../Selection/Builder/EntitySelectionBuilder';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import constructEntity from '../../../../@Api/Entity/constructEntity';
import { getModel } from '../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import EntityTypeContext from '../Type/EntityTypeContext';
import Caption from '../Item/Caption/Caption';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import styles from './Selectbox.module.scss';
import { InputActionMeta } from 'react-select/src/types';
import { classNames } from '../../../../@Future/Util/Class/classNames';
import { groupBy } from '../../../../@Util/MapUtils/groupBy';
import useTypes from '../Type/Api/useTypes';
import equalsEntity from '../../../../@Api/Entity/Bespoke/equalsEntity';
import getIllegalInstantiableTypes from '../Constructor/Api/getIllegalInstantiableTypes';
import uuid from '../../../../@Util/Id/uuid';
import { EntityFieldPath } from '../Path/@Model/EntityFieldPath';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { CurrentUserStore } from '../../User/CurrentUserStore';
import useIsMobile from '../../../../@Util/Responsiveness/useIsMobile';
import { DataObject } from '../../DataObject/Model/DataObject';
import Link from '../../../../@Future/Component/Generic/Link/Link';
import { openEntity } from '../@Util/openEntity';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import isHiddenType from '../../../../@Api/Metadata/EntityType/isHiddenType';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { constructEntityOfType } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { CommitContextImpl } from '../../../../@Api/Entity/Commit/Context/CommitContextImpl';
import { getInheritedNameFieldWithFallback } from '../../../../@Api/Metadata/Field/getInheritedNameFieldWithFallback';
import { makeStyles } from '@material-ui/styles';
import useToggle from '../../../../@Util/Toggle/useToggle';
import { primaryColor } from '../../../../@Resource/Theme/Theme';
import Popper from '../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../@Future/Component/Generic/Card/Card';
import { CardContent } from '@material-ui/core';
import { SelectboxAdvancedSearcher } from './AdvancedSearcher/SelectboxAdvancedSearcher';
import CardHeader from '../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import getSetting from '../../Setting/Api/getSetting';
import { SettingSource } from '../../Setting/SettingStore';
import { Setting } from '../../../../@Api/Settings/Setting';
import { getRootNodeFromSelection } from '../../../../@Api/Selection/Api/getRootNodeFromSelection';
import Icon from '../../../../@Future/Component/Generic/Icon/Icon';
import Tooltip from '../../../../@Future/Component/Generic/Tooltip/Tooltip';

export interface SelectboxProps
{
    selections: Selection[] | Entity[];
    value?: SelectionValue<Entity>;
    onChange: (value: SelectionValue<Entity>) => void;
    multi?: boolean;
    clearable?: boolean;
    disableUnderline?: boolean;
    inputValue?: string;
    onInputChange?: (input: string, action: InputActionMeta) => void;
    autoFocus?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    hideCaptionInValue?: boolean;
    disableConstruction?: boolean;
    onConstruct?: (entity: Entity, commitContext: CommitContext) => void;
    useParentCommitContextForConstruction?: boolean;
    commitContext?: CommitContext;
    interceptConstruction?: boolean;
    allowOpenAfterConstruction?: boolean;
    formatOptionValue?: (value: Entity) => React.ReactNode | undefined;
    formatSelectedValue?: (value: Entity) => React.ReactNode | undefined;
    formatCaption?: (entity: Entity) => React.ReactNode | undefined;
    disableRelationshipCreationOnConstruction?: boolean;
    disableRelationCreationOnConstruction?: boolean;
    genericSelectboxProps?: Partial<GenericSelectboxProps<Entity>>;
    searchFieldPaths?: EntityFieldPath[];
    emptyOption?: string;
    compact?: boolean;
    wrap?: boolean;
}

const emptyOptionValue = {
    isEmpty: true
};

const pageSize = 50;
const useStyles =
    makeStyles({
        root: {
            position: 'relative',
            '&:hover > $searchIconButton': {
                display: 'block',
            }
        },
        searchIcon:
        {
            paddingTop: 6,
            fontSize: 16,
            fontWeight: 'bold',
            color: '#99CBED',
            '&:hover':
            {
                color: '#6698BA',
                cursor: 'pointer'
            }
        },
        searchIconEnabled:
        {
            color: primaryColor
        },
        searchIconButton: {
            position: 'absolute',
            top: -5,
            display: 'none'
        },
        searchPositionWithClear:
        {
            right: 45,
        },
        searchPositionWithoutClear:
        {
            right: 25
        },
        selected:
        {
            display: 'block',
        }
    });

const Selectbox: React.FC<SelectboxProps> =
    props =>
    {
        const classes = useStyles();
        const types = useTypes();
        const hiddenDatastoreIds = getSetting<string[]>(SettingSource.Organization, Setting.Datastore.HiddenIds)

        const pageNumbersRef = useRef([]);
        const load =
            useCallback(
                (query: string) =>
                {
                    const pageNumbers = pageNumbersRef.current;
                    if (props.selections.length > 0)
                    {
                        if (props.selections[0] instanceof Selection)
                        {
                            const filledPageNumbers =
                                (props.selections as Selection[]).map(
                                    (_, idx) =>
                                        pageNumbers[idx] === undefined ? 0 : pageNumbers[idx]);
                            const selections = props.selections as Selection[];

                            return Promise.all(
                                selections.map(
                                    (selection, idx) =>
                                        Promise.all(
                                            new Array(filledPageNumbers[idx] + 1).fill(0)
                                                .map(
                                                    (_, pageIdx) =>
                                                        new EntitySelectionBuilder(
                                                            getRootNodeFromSelection(selection).entityType,
                                                            selection.clone()
                                                        )
                                                            .if(
                                                                () => query !== undefined && query.length > 0,
                                                                sb =>
                                                                    sb.where(
                                                                        cb =>
                                                                            cb.getSearchTerms(query)
                                                                                .forEach(
                                                                                    term =>
                                                                                        cb.or(
                                                                                            ob =>
                                                                                            {
                                                                                                const termValue =
                                                                                                    // Convert to text value, because the field that is compared to may not be of type text (e.g. localized text)
                                                                                                    DataObject.constructFromTypeIdAndValue(
                                                                                                        'Text',
                                                                                                        term);

                                                                                                ob.contains(
                                                                                                    sb.rootPath.field(
                                                                                                        getInheritedNameFieldWithFallback(
                                                                                                            sb.rootPath.rootEntityType
                                                                                                        )
                                                                                                    ),
                                                                                                    undefined,
                                                                                                    termValue);

                                                                                                if (props.searchFieldPaths !== undefined)
                                                                                                {
                                                                                                    props.searchFieldPaths.forEach(
                                                                                                        searchFieldPath =>
                                                                                                            ob.contains(
                                                                                                                searchFieldPath,
                                                                                                                undefined,
                                                                                                                termValue));
                                                                                                }
                                                                                            }))))

                                                            // Exclude hidden uuids for Datastores
                                                            .if(
                                                                () =>
                                                                    hiddenDatastoreIds
                                                                    && hiddenDatastoreIds.length > 0
                                                                    && getRootNodeFromSelection(selection).entityType.isA(types.Datastore.Type),
                                                                sb => {
                                                                    sb.where(
                                                                        cb => // Maybe use cb.in() ??
                                                                            hiddenDatastoreIds.forEach(
                                                                                hiddenUuid =>
                                                                                    cb.and(
                                                                                        ab =>
                                                                                            ab.neq(
                                                                                                sb.rootPath.field(types.Entity.Field.Uuid),
                                                                                                undefined,
                                                                                                hiddenUuid
                                                                                            )
                                                                                    )
                                                                            )
                                                                    )

                                                                }
                                                            )

                                                            .if(
                                                                () =>
                                                                    true,
                                                                sb =>
                                                                {
                                                                    const fieldPath = getRootNodeFromSelection(selection).entityType.bespoke.orderByField();

                                                                    if (fieldPath)
                                                                    {
                                                                        sb.orderBy(
                                                                            fieldPath,
                                                                            true);
                                                                    }
                                                                })
                                                            .select(pageIdx * pageSize, pageSize)))
                                            .then(
                                                allSelectionResults =>
                                                    Promise.resolve(
                                                        allSelectionResults
                                                            .map(results => results.slice())
                                                            .reduce((a, b) => a.concat(b), [])))))
                                .then(
                                    values =>
                                    {
                                        const queryTrimmed = query && query.length > 0 ? query.trim() : undefined;

                                        function getAddOptionByType(id: string,
                                                                    type: EntityType)
                                        {
                                            return {
                                                uuid: `${id}.${type.id}`,
                                                addType: type,
                                                query: queryTrimmed
                                            };
                                        }

                                        function getAllAddOptionsByType(id: string,
                                                                        type: EntityType)
                                        {
                                            if (types.Relationship.Type.isA(type))
                                            {
                                                return [
                                                    getAddOptionByType(id, types.Relationship.Person.Type),
                                                    getAddOptionByType(id, types.Relationship.Organization.Type)
                                                ];
                                            }
                                            else if (types.Relation.Type.isA(type))
                                            {
                                                return [
                                                    getAddOptionByType(id, types.Relation.Person.Type),
                                                    getAddOptionByType(id, types.Relation.Organization.Type)
                                                ];
                                            }
                                            else
                                            {
                                                if (getIllegalInstantiableTypes(type).has(type))
                                                {
                                                    return [];
                                                }
                                                else
                                                {
                                                    return [
                                                        getAddOptionByType(id, type)
                                                    ];
                                                }
                                            }
                                        }

                                        function getAllowedAddOptionsByType(id: string,
                                                                            type: EntityType)
                                        {
                                            const currentUserStore = loadModuleDirectly(CurrentUserStore);

                                            return getAllAddOptionsByType(id, type)
                                                .filter(
                                                    option =>
                                                        currentUserStore.rightProfile.canCreateType(option.addType) &&
                                                        !isHiddenType(option.addType));
                                        }

                                        function getAddOptions(idx: number)
                                        {
                                            if (props.disableConstruction)
                                            {
                                                return [];
                                            }
                                            else
                                            {
                                                const type = getRootNodeFromSelection(selections[idx]).entityType;

                                                return getAllowedAddOptionsByType(
                                                    `add.${idx}`,
                                                    type);
                                            }
                                        }

                                        function getLoadMoreOptions(idx?: number)
                                        {
                                            if (idx === undefined
                                                && values.length > 0
                                                && values[0].length >= (filledPageNumbers[0] + 1) * pageSize)
                                            {
                                                const newPageNumbers = filledPageNumbers.map(num => num + 1);

                                                return [
                                                    {
                                                        loadPage: newPageNumbers
                                                    }
                                                ];
                                            }
                                            else if (values[idx] !== undefined
                                                && values[idx].length > 0
                                                && values[idx].length >= (filledPageNumbers[idx] + 1) * pageSize)
                                            {
                                                const newPageNumbers = [
                                                    ...filledPageNumbers
                                                ];
                                                newPageNumbers[idx] = filledPageNumbers[idx] + 1;

                                                return [
                                                    {
                                                        loadPage: newPageNumbers
                                                    }
                                                ];
                                            }
                                            else
                                            {
                                                return [];
                                            }
                                        }

                                        const emptyOptions =
                                            props.emptyOption
                                                ?
                                                    [
                                                        emptyOptionValue
                                                    ]
                                                :
                                                    [];

                                        if (props.selections.length === 1)
                                        {
                                            if (getRootNodeFromSelection(selections[0]).entityType.isInstantiableByInheritance())
                                            {
                                                return Promise.resolve(
                                                    [
                                                        ...emptyOptions,
                                                        ...values
                                                            .map(
                                                                value =>
                                                                    value.slice())
                                                            .reduce((a, b) => a.concat(b), [])
                                                            .map(
                                                                result =>
                                                                    result.entity)
                                                            .filter(
                                                                entity =>
                                                                    !entity.entityType.bespoke.isHiddenInSelectbox(entity)),
                                                        ...getLoadMoreOptions(0),
                                                        ...getAddOptions(0)
                                                    ]) as any;
                                            }
                                            else
                                            {
                                                const entities =
                                                    values
                                                        .map(
                                                            value =>
                                                                value.slice())
                                                        .reduce((a, b) => a.concat(b), [])
                                                        .map(
                                                            result =>
                                                                result.entity)
                                                        .filter(
                                                            entity =>
                                                                !entity.entityType.bespoke.isHiddenInSelectbox(entity));

                                                if (entities.length === 0)
                                                {
                                                    return Promise.resolve([
                                                        ...emptyOptions,
                                                        ...getAddOptions(0)
                                                    ]);
                                                }
                                                else
                                                {
                                                    const entitiesByType =
                                                        groupBy(
                                                            entities,
                                                            entity => entity.entityType);

                                                    const otherAddOptions =
                                                        selections
                                                            .map(
                                                                (selection, idx) =>
                                                                    getAddOptions(idx))
                                                            .reduce((a, b) => a.concat(b), [])
                                                            .filter(
                                                                addOption =>
                                                                    !entitiesByType.has(addOption.addType))
                                                            .filter(
                                                                addOption =>
                                                                    !(addOption.addType.isA(types.Relationship.Person.Contact.Type)
                                                                        && entitiesByType.has(types.Relationship.Person.Contact.Standard.Type)));

                                                    return Promise.resolve(
                                                        [
                                                            ...emptyOptions,
                                                            ...Array.from(entitiesByType.entries())
                                                                .map(
                                                                    ([ type, entities ]) =>
                                                                        ({
                                                                            label: type.getName(true),
                                                                            options:
                                                                                [
                                                                                    ...entities,
                                                                                    ...getLoadMoreOptions(undefined),
                                                                                    ...getAllowedAddOptionsByType(
                                                                                        `add.type.${type.id}`,
                                                                                        type)
                                                                                ]
                                                                        })),
                                                            ...otherAddOptions.length > 0
                                                                ?
                                                                    [
                                                                        {
                                                                            label: 'Overig',
                                                                            options: otherAddOptions
                                                                        }
                                                                    ]
                                                                :
                                                                    []
                                                        ]);
                                                }


                                            }
                                        }
                                        else
                                        {
                                            return Promise.resolve(
                                                [
                                                    ...emptyOptions,
                                                    ...values
                                                        .map(
                                                            (value, idx) => ({
                                                                label: getRootNodeFromSelection(selections[idx]).entityType.getName(true),
                                                                options:
                                                                    [
                                                                        ...value.slice().map(
                                                                            result =>
                                                                                result.entity),
                                                                        ...getLoadMoreOptions(idx),
                                                                        ...getAddOptions(idx)
                                                                    ]
                                                            }))
                                                ]) as any;
                                        }
                                    })
                        }
                        else
                        {
                            const selections = props.selections as Entity[];

                            return Promise.resolve(selections) as any;
                        }
                    }
                    else
                    {
                        return Promise.resolve([]);
                    }
                },
                [
                    props.emptyOption,
                    props.selections,
                    props.searchFieldPaths,
                    props.disableConstruction,
                    hiddenDatastoreIds,
                    types,
                ]);

        const idResolver =
            useCallback(
                (option: any) =>
                    option.uuid,
                [

                ]);

        const isMobile = useIsMobile();

        const isCompact =
            useMemo(
                () => isMobile || (props.compact !== undefined && props.compact),
            [
                    isMobile,
                    props.compact
                ]);

        const formatOption =
            useCallback(
                (option: any, metadata: any, isValue: boolean = false) =>
                {
                    const searchIconReservedSpace =
                        isValue &&
                        !props.multi &&
                        <>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                        </>;

                    if (option === emptyOptionValue)
                    {
                        return <div
                            className={!isValue ? classNames(styles.option) : undefined}
                        >
                            {props.emptyOption || '-'}
                            {searchIconReservedSpace}
                        </div>;
                    }
                    else if (option.loadPage !== undefined)
                    {
                        return <div
                            className={!isValue ? classNames(styles.option, styles.secondary) : undefined}
                        >
                            <LocalizedText
                                code="Generic.LoadMore"
                                value="Meer laden"
                            />...
                        </div>;
                    }
                    else if (getModel(option) instanceof Entity)
                    {
                        const displayName =
                            isValue && props.formatSelectedValue
                                ? props.formatSelectedValue(option as Entity)
                                : props.formatOptionValue
                                    ? props.formatOptionValue(option as Entity)
                                    : option.name;

                        const nameWithOptionalLink =
                            isValue && (option as Entity).entityType.bespoke.isOpenableFromSelectbox() && !isMobile
                            ? <Link
                                onClick={() => openEntity(option)}
                              >
                                {displayName}
                              </Link>
                            : displayName;

                        const caption =
                            (!isValue || !props.hideCaptionInValue) &&
                                <Caption
                                    entity={option}
                                    classes={{
                                        root:
                                            classNames(
                                                styles.caption,
                                                !isValue && metadata.selected && metadata.selected.some(s => equalsEntity(s.value, option)) && styles.selected),
                                        link: {
                                            root: styles.link
                                        }
                                    }}
                                >
                                    {props.formatCaption && props.formatCaption(option)}
                                </Caption>;

                        const shouldRenderCaption =
                            (!isValue || !props.hideCaptionInValue);

                        const hasClearButton =
                            !props.multi && (props.clearable && props.value && !(props.multi && (props.value as any[]).length === 0));

                        return <div
                            className={!isValue ? styles.option : undefined}
                        >
                            {
                                isCompact &&
                                <div
                                    style={{display: 'grid'}}
                                >
                                    <div
                                        className={styles.name}
                                    >
                                        {nameWithOptionalLink}
                                        {searchIconReservedSpace}
                                    </div>
                                    <div
                                        className={styles.captionwrapper}
                                    >
                                        {caption}
                                    </div>
                                </div>
                            }
                            {
                                !isCompact &&
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={10}
                                    alignment="center"
                                >
                                    <ViewGroupItem
                                        className={
                                            classNames(
                                                props.wrap
                                                    ? styles.wrappedName
                                                    : styles.name,
                                                shouldRenderCaption
                                                    ? undefined
                                                    : styles.rightSpacing
                                            )}
                                    >
                                        {nameWithOptionalLink}
                                    </ViewGroupItem>
                                    {
                                        shouldRenderCaption &&
                                        <ViewGroupItem
                                            ratio={1}
                                            alignment="right"
                                            className={
                                                classNames(
                                                    styles.leftSpacing,
                                                    hasClearButton && styles.rightSpacing
                                                )
                                            }
                                        >
                                            {caption}
                                        </ViewGroupItem>
                                    }
                                </ViewGroup>
                            }
                        </div>;
                    }
                    else if (option.addType)
                    {
                        if (option.query)
                        {
                            return <div
                                className={classNames(styles.option, styles.secondary)}
                            >
                                <span>+ {option.addType.getName()}: <strong>{option.query}</strong></span>
                            </div>
                        }
                        else
                        {
                            return <div
                                // style={{
                                //     padding: '7px 0',
                                //     // fontWeight: 500,
                                //     color: textSecondaryColor
                                // }}
                                className={classNames(styles.option, styles.secondary)}
                            >
                                <span>+ {option.addType.getName()}</span>
                            </div>
                        }
                    }
                    else
                    {
                        return <>
                            {option.name}
                            {searchIconReservedSpace}
                        </>;
                    }
                },
                [
                    props.emptyOption,
                    props.hideCaptionInValue,
                    props.formatCaption,
                    props.formatSelectedValue,
                    isMobile
                ]);

        const formatValue =
            useCallback(
                (option: any, metadata: any) =>
                    formatOption(option, metadata, true),
                [
                    formatOption
                ]);

        const entityTypeStore = useContext(EntityTypeContext);

        const createEntityFromValue =
            useCallback(
                (value: any) =>
                {
                    const commitContext =
                        props.commitContext
                            ? props.useParentCommitContextForConstruction
                                ? props.commitContext
                                : new CommitContextImpl({
                                    allowAutoCommit: false,
                                })
                            : undefined;

                    function createEntityOfType(type: EntityType)
                    {
                        return constructEntityOfType(type, commitContext);
                    }

                    const entity = createEntityOfType(value.addType);

                    if (entity.entityType.isA(types.Relation.Type))
                    {
                        if (!props.disableRelationshipCreationOnConstruction)
                        {
                            if (entity.entityType.isA(types.Relation.Person.Type))
                            {
                                const personRsp = createEntityOfType(types.Relationship.Person.Type);

                                updateRelationship(
                                    entity,
                                    true,
                                    types.Relationship.Person.RelationshipDefinition.Person,
                                    personRsp,
                                    commitContext
                                );
                            }
                            else if (entity.entityType.isA(types.Relation.Organization.Type))
                            {
                                const organizationRsp = createEntityOfType(types.Relationship.Organization.Type);

                                updateRelationship(
                                    entity,
                                    true,
                                    types.Relationship.Organization.RelationshipDefinition.Organization,
                                    organizationRsp,
                                    commitContext
                                );
                            }
                        }

                        if (value.query)
                        {
                            entity.setName(value.query, commitContext);
                        }
                    }
                    else if (entity.entityType.isA(types.Relationship.Type))
                    {
                        if (!props.disableRelationCreationOnConstruction)
                        {
                            if (entity.entityType.isA(types.Relationship.Person.Type))
                            {
                                const person = createEntityOfType(types.Relation.Person.Type);

                                updateRelationship(
                                    entity,
                                    false,
                                    types.Relationship.Person.RelationshipDefinition.Person,
                                    person,
                                    commitContext
                                );

                                if (value.query)
                                {
                                    person.setName(value.query, commitContext);
                                }
                            }
                            else if (entity.entityType.isA(types.Relationship.Organization.Type))
                            {
                                const organization = createEntityOfType(types.Relation.Organization.Type);

                                updateRelationship(
                                    entity,
                                    false,
                                    types.Relationship.Organization.RelationshipDefinition.Organization,
                                    organization,
                                    commitContext
                                );

                                if (value.query)
                                {
                                    organization.setName(value.query, commitContext);
                                }
                            }
                        }
                    }
                    else
                    {
                        if (value.query)
                        {
                            entity.setName(value.query, commitContext);
                        }
                    }

                    if (props.onConstruct)
                    {
                        props.onConstruct(entity, commitContext);
                    }

                    if (!props.interceptConstruction)
                    {
                        constructEntity(
                            entity.entityType,
                            undefined,
                            entity,
                            entity =>
                            {
                                // Update the revision, otherwise the new option does not appear
                                // in the selectbox options
                                if (Array.isArray(props.value))
                                {
                                    props.onChange([
                                        ...props.value,
                                        entity
                                    ]);
                                }
                                else
                                {
                                    props.onChange(entity);
                                }
                            },
                            props.allowOpenAfterConstruction,
                            undefined,
                            undefined,
                            undefined,
                            commitContext
                        );
                    }
                },
                [
                    entityTypeStore,
                    props.onConstruct,
                    props.interceptConstruction,
                    props.commitContext,
                    props.allowOpenAfterConstruction,
                    props.value
                ]);

        const [ openKey, setOpenKey ] = useState<string | undefined>(undefined);
        const [ isAdvancedSearchOpen, toggleAdvancedSearchOpen ] = useToggle(false);
        const onChange =
            useCallback(
                (value: any) =>
                {
                    toggleAdvancedSearchOpen(false);

                    if (value)
                    {
                        if (value === emptyOptionValue)
                        {
                            props.onChange(undefined);
                        }
                        else if (value.loadPage
                            // Multiselect support:
                            || (Array.isArray(value) && value.filter(v => v.loadPage).length > 0))
                        {
                            const setPageNumbers =
                                (pageNumbers: number[]) =>
                                    pageNumbersRef.current = pageNumbers;

                            if (Array.isArray(value))
                            {
                                const v = value.filter(v => v.loadPage)[0];
                                setPageNumbers(v.loadPage);
                            }
                            else
                            {
                                setPageNumbers(value.loadPage);
                            }

                            setOpenKey(uuid());

                            return false;
                        }
                        else if (value !== emptyOptionValue)
                        {
                            if (Array.isArray(value))
                            {
                                const addValue = (value as any[]).find(v => v.addType instanceof EntityType);

                                if (addValue)
                                {
                                    createEntityFromValue(addValue);

                                    return false;
                                }
                                else
                                {
                                    props.onChange(value);
                                }
                            }
                            else if (value instanceof Entity)
                            {
                                props.onChange(value);
                            }
                            else
                            {
                                if (value.addType instanceof EntityType)
                                {
                                    createEntityFromValue(value);

                                    return false;
                                }
                            }
                        }
                        else
                        {
                            props.onChange(undefined);
                        }
                    }
                    else
                    {
                        props.onChange(undefined);
                    }
                },
                [
                    toggleAdvancedSearchOpen,
                    props.onChange,
                    props.genericSelectboxProps,
                    createEntityFromValue,
                ]);
        const onSelect =
            useCallback(
                (entity: Entity) =>
                {
                    if (props.multi)
                    {
                        onChange([ ...props.value as Entity[], entity ]);
                    }
                    else
                    {
                        onChange(entity);
                    }
                },
                [
                    props.multi,
                    props.value,
                ]);

        return <Popper
            reference={
                <div
                    className={classes.root}
                >
                    <GenericSelectbox
                        load={load}
                        onChange={onChange}
                        value={props.value === undefined ? (props.emptyOption ? emptyOptionValue : undefined) : props.value}
                        idResolver={idResolver}
                        formatOption={formatOption}
                        formatValue={formatValue}
                        multi={props.multi}
                        clearable={props.clearable}
                        disableUnderline={props.disableUnderline}
                        inputValue={props.inputValue}
                        onInputChange={props.onInputChange}
                        autoFocus={props.autoFocus}
                        onFocus={props.onFocus}
                        onBlur={props.onBlur}
                        openKey={openKey}
                        {...props.genericSelectboxProps}
                    />
                    <div
                        className={
                            classNames(
                                classes.searchIconButton,
                                (props.clearable && (props.value || props.emptyOption) && !(props.multi && (props.value as any[]).length === 0))
                                    ? classes.searchPositionWithClear
                                    : classes.searchPositionWithoutClear,
                                isAdvancedSearchOpen && classes.selected
                            )
                        }
                    >
                        <Tooltip
                            title={
                            <div>
                                <LocalizedText
                                    code="Generic.AdvancedSearch"
                                    value="Uitgebreid zoeken"
                                />
                            </div>
                            }
                        >
                            <span
                                onClick={() => toggleAdvancedSearchOpen()}
                            >
                                <Icon
                                    icon="search"
                                    size={16}
                                    className={
                                        classNames(
                                            classes.searchIcon,
                                            isAdvancedSearchOpen && classes.searchIconEnabled
                                        )
                                    }
                                />
                            </span>
                        </Tooltip>
                    </div>
                </div>
            }
            popper={
                <Card>
                    <CardContent>
                        <CardHeader>
                            <LocalizedText
                                code="Generic.AdvancedSearch"
                                value="Uitgebreid zoeken"
                            />
                        </CardHeader>
                    </CardContent>
                    <SelectboxAdvancedSearcher
                        selections={props.selections}
                        onSelect={onSelect}
                    />
                </Card>
            }
            onClose={toggleAdvancedSearchOpen}
            open={isAdvancedSearchOpen}
        />;
    };

Selectbox.defaultProps = {
    disableUnderline: true,
    allowOpenAfterConstruction: false,
    clearable: true,
};

export default observer(Selectbox);
