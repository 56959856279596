import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Button from '../Button/Button';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import useAttachedEntityPaths from '../Api/useAttachedEntityPaths';
import EditableContext from '../../Context/EditableContext';
import styles from './Buttons.module.scss';
import ExpansionButton from '../../../../../../@Future/Component/Generic/Button/Variant/Expansion/ExpansionButton';
import HeaderContext from '../../Context/HeaderContext';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import useIsContextExpanded from '../Api/useIsContextExpanded';
import useSetContextExpansion from '../Api/useSetContextExpansion';
import useContextExpansionPath from '../Api/useContextExpansionPath';

export interface ButtonsClasses
{

}

export interface ButtonsProps
{
    entity: Entity;
    showDetails?: boolean;
    onCount?: (path: EntityPath, count: number) => void;
    classes?: ButtonsClasses;
}

const Buttons: React.FC<ButtonsProps> =
    props =>
    {
        const isEditable = useContext(EditableContext);
        const paths = useAttachedEntityPaths(props.entity.entityType);
        const expansionPath = useContextExpansionPath();
        const clickCallback =
            useCallback(
                (e: React.MouseEvent<any>) =>
                {
                    e.stopPropagation();
                },
                []);
        const rootPath =
            useMemo(
                () =>
                    EntityPath.fromEntity(props.entity),
                [
                    props.entity
                ]);

        const isHeader = useContext(HeaderContext);
        const isContextExpanded = useIsContextExpanded();
        const setContextExpansion = useSetContextExpansion();
        const onButtonClick =
            useCallback(
                (path: EntityPath) =>
                {
                    if (expansionPath && expansionPath.id === path.id)
                    {
                        setContextExpansion(undefined);
                    }
                    else
                    {
                        setContextExpansion(path);
                    }
                },
                [
                    expansionPath,
                    setContextExpansion
                ]);

        return <div
            onClick={clickCallback}
            className={classNames(
                styles.root,
                isContextExpanded && styles.expanded)}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={5}
            >
                {
                    !isHeader &&
                        paths.map(
                            path =>
                                <ViewGroupItem
                                    key={path.id}
                                >
                                    <Button
                                        entity={props.entity}
                                        path={path}
                                        editable={isEditable}
                                        active={isContextExpanded && expansionPath && expansionPath.id === path.id}
                                        onClick={() => onButtonClick(path)}
                                        onCount={props.onCount}
                                    />
                                </ViewGroupItem>)
                }
                <ViewGroupItem
                    ratio={1}
                >
                    <span />
                </ViewGroupItem>
                {
                    (isHeader || props.showDetails) &&
                        <ViewGroupItem
                            className={styles.moreButton}
                        >
                            <ExpansionButton
                                expanded={expansionPath && expansionPath.id === rootPath.id}
                                onClick={() => onButtonClick(rootPath)}
                                classes={{
                                    chevron: styles.moreChevron
                                }}
                                chevronPosition="start"
                            />
                        </ViewGroupItem>
                }
            </ViewGroup>
        </div>;
    };

export default observer(Buttons);
