import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { Grid } from '@material-ui/core';
import styles from '../Automation.module.scss';
import CardHeader from '../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import ErrorBoundary from '../../../../../../Error/ErrorBoundary';
import AutomationEditor from '../Editor/AutomationEditor';
import EntityTypeContext from '../../../../Type/EntityTypeContext';
import getTypes from '../../../../Type/Api/getTypes';
import { default as AutomationModel } from '../../../../../../../@Api/Automation/Automation';
import { useComputed } from 'mobx-react-lite';
import hasAccessToWorkflowAutomations from '../../../../../../../@Api/Pack/hasAccessToWorkflowAutomations';
import { createTransactionalModel } from '../../../../../../../@Util/TransactionalModelV2/index';
import constructEntity from '../../../../../../../@Api/Entity/constructEntity';
import CurrentUserContext from '../../../../../User/CurrentUserContext';
import Validation from '../../../../../../../@Api/Automation/Validation/Validation';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';

export interface AutomationDesignTabProps
{
    entity: Entity;
    entityTypeEntity: Entity;
    automation: AutomationModel;
    setAutomation: (auto: AutomationModel) => void;
    validations: Validation[];
    parameterDictionary: ParameterDictionary;
    isEditable: boolean;
    isReadOnly: boolean;
    isLoading?: boolean;
}

export const AutomationDesignTab : React.FC<AutomationDesignTabProps> = observer(
    ({
        entity,
         entityTypeEntity,
        automation,
        setAutomation,
        validations,
        parameterDictionary,
        isEditable,
        isReadOnly,
        isLoading
    }) =>
    {
        const types = getTypes()
        const currentUserStore = useContext(CurrentUserContext);

        const isDuplicable =
            useComputed(
                () =>
                    !isEditable && hasAccessToWorkflowAutomations(),
                [
                    isEditable
                ]);

        const duplicateAutomation =
            useCallback(
                () =>
                {
                    const duplicatedAutomation =
                        createTransactionalModel(
                            new Entity(types.Automation.Type)
                                .initialize());

                    duplicatedAutomation.setValueByField(
                        types.Automation.Field.LocalizedName,
                        `${entity.name} (${currentUserStore.environmentEntity.name})`);

                    [
                        types.Automation.Field.Specification,
                        types.Automation.Field.IsUser
                    ].forEach(
                        field =>
                            duplicatedAutomation.setValueByField(
                                field,
                                entity.getObjectValueByField(field)));

                    duplicatedAutomation.updateRelationship(
                        true,
                        types.EntityType.RelationshipDefinition.Automations,
                        createTransactionalModel(
                            entity.getRelatedEntityByDefinition(
                                true,
                                types.EntityType.RelationshipDefinition.Automations)));

                    return constructEntity(
                        duplicatedAutomation.entityType,
                        undefined,
                        duplicatedAutomation);
                },
                [
                    entity,
                    types,
                    currentUserStore
                ]);

        const entityTypeStore = useContext(EntityTypeContext);
        const entityType =
            useMemo(
                () =>
                    entityTypeStore.getTypeByEntityId(entityTypeEntity?.id),
                [
                    entityTypeStore,
                    entityTypeEntity
                ]);

        return <ViewGroupItem>

            {
                validations.length > 0 &&
                <Grid
                    item
                    xs={12}
                >
                    <Card
                        inset
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={5}
                        >
                            <ViewGroupItem>
                                <strong>
                                    <LocalizedText
                                        code="Automation.AutomationContainsErrors"
                                        value="Deze automation bevat de volgende fouten:"
                                    />
                                </strong>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ol>
                                    {
                                        validations.map(
                                            validation =>
                                                <li
                                                    key={validation.id}
                                                >
                                                    {validation.message}
                                                </li>)
                                    }
                                </ol>
                            </ViewGroupItem>
                        </ViewGroup>
                    </Card>
                </Grid>
            }
            {
                isDuplicable &&
                <Grid
                    item
                    xs={12}
                >
                    <Card
                        inset
                        classes={{
                            root: styles.duplicateCard
                        }}
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={5}
                        >
                            <ViewGroupItem>
                                <CardHeader>
                                    <LocalizedText
                                        code="Automation.Edit"
                                        value="Automation aanpassen..."
                                    />
                                </CardHeader>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <LocalizedText
                                    code="Automation.OwnerOfAutomationHelperText"
                                    value="Je bent geen eigenaar van deze automation. Om deze aan te passen moet je de automation dupliceren."
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <RightAlignedButtonGroup>
                                    <PrimaryButton
                                        label={
                                            <LocalizedText
                                                code="Generic.Duplicate"
                                                value="Dupliceren"
                                            />
                                        }
                                        onClick={duplicateAutomation}
                                    />
                                </RightAlignedButtonGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </Card>
                </Grid>
            }
            <Grid
                item
                xs={12}
            >
                {
                    isLoading
                        ? <Centered
                            horizontal
                        >
                            <Loader />
                        </Centered>
                        : <ErrorBoundary>
                            <AutomationEditor
                                entityType={entityType}
                                automation={automation}
                                onCreate={setAutomation}
                                parameterDictionary={parameterDictionary}
                                entity={entity}
                                readOnly={isReadOnly || !isEditable}
                            />
                        </ErrorBoundary>
                }
            </Grid>
        </ViewGroupItem>
    }
)