import * as React from 'react';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { CompositeComputationEditorStore } from './CompositeComputationEditorStore';
import { observer } from 'mobx-react';
import { Button, ClickAwayListener, Grid, Grow, Icon, IconButton, MenuItem, MenuList, Paper } from '@material-ui/core';
import { blue } from '../../../../../@Resource/Theme/Blue';
import { grey, yellow } from '@material-ui/core/colors';
import { PopperReference } from '../../../../Generic/Popper/PopperReference';
import { PopperContainer } from '../../../../Generic/Popper/PopperContainer';
import { PopperManager } from '../../../../Generic/Popper/PopperManager';
import Text from '../../../../Generic/Text/Text';
import { CompositeComputationOperationEditor } from './Operation/CompositeComputationOperationEditor';

@observer
export class CompositeComputationEditor extends BaseComponent<CompositeComputationEditorStore>
{
    renderComponent(store: CompositeComputationEditorStore)
    {
        return <Grid container spacing={0}>
            <Grid item xs={12}>
                <Paper
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: yellow[100],
                        borderRadius: 10,
                        paddingRight: 15
                    }}
                >
                    <IconButton>
                        <Icon>computer</Icon>
                    </IconButton>
                    <Text store={store.calculationTextStore} />
                </Paper>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', minHeight: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 50 }}>
                    <div
                        style={{
                            backgroundColor: grey[500],
                            width: 1,
                            height: '100%'
                        }}
                    />
                </div>
                <div style={{ flex: '1 1 auto' }}>
                    {
                        store.operationStores.map(
                            operationStore => (
                                <div key={operationStore.uuid}>
                                    <CompositeComputationOperationEditor store={operationStore} />
                                </div>))
                    }
                </div>
            </Grid>
            <Grid item xs={12}>
                <Paper
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        backgroundColor: blue[100],
                        borderRadius: 10
                    }}
                >
                    <PopperManager>
                        <PopperReference>
                            <Button
                                onClick={store.openAddMenu}
                            >
                                <Icon style={{ marginRight: 10 }}>
                                    add
                                </Icon>
                                {
                                    store.localizationStore.translate('Computation.AddCalculation') // Add calculation
                                }
                            </Button>
                        </PopperReference>
                        <PopperContainer
                            placement="bottom-start"
                            eventsEnabled={store.isAddMenuOpen}
                            style={{ zIndex: 10 }}
                        >
                            {
                                store.isAddMenuOpen &&
                                    <ClickAwayListener onClickAway={store.closeAddMenu}>
                                        <div>
                                            <Grow in={true}>
                                                <Paper>
                                                    <MenuList role="menu">
                                                        {
                                                            store.computationTypeStore.types
                                                                .filter(type => type.allow(store.context))
                                                                .map(type => (
                                                                    <MenuItem
                                                                        key={type.id()}
                                                                        onClick={(e: any) =>
                                                                        {
                                                                            store.addOperation(type);
                                                                            store.closeAddMenu();

                                                                            e.stopPropagation();
                                                                        }}
                                                                    >
                                                                        {type.name()}
                                                                    </MenuItem>))
                                                        }
                                                    </MenuList>
                                                </Paper>
                                            </Grow>
                                        </div>
                                    </ClickAwayListener>
                            }
                        </PopperContainer>
                    </PopperManager>
                </Paper>
            </Grid>
        </Grid>;
    }
}
