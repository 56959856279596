import * as React from 'react';
import { AbstractSelectbox, SelectboxProps } from './AbstractSelectbox';
import { Grid, Typography } from '@material-ui/core';

// Does not work if we just use: import * as Select from 'react-select'; at runtime if we then return Select as selectboxClass.
const DefaultSelect = require('react-select');

export interface AdditionalSelectboxProps
{
    isLabelOnSide?: boolean;
    sideLabel?: string;
    doNotRenderInPortal?: boolean;
}

export class Selectbox extends AbstractSelectbox<SelectboxProps & AdditionalSelectboxProps>
{
    selectboxClass(): React.ComponentClass
    {
        return DefaultSelect.default;
    }

    render(): any
    {
        if (this.props.isLabelOnSide)
        {
            return <Grid container>
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        variant="body2"
                        color={this.state && this.state.isFocused ? 'primary' : 'secondary'}
                        style={{ flex: '1 1 auto' }}
                    >
                        {this.props.sideLabel}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                    style={{
                        position: 'relative'
                    }}
                >
                    {super.render()}
                </Grid>
            </Grid>;
        }
        else
        {
            return super.render();
        }
    }
}
