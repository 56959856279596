import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Action from '../../../../../@Api/Automation/Function/Action/Action';
import { findAssistantComputation } from '../Computation/findAssistantComputation';
import ForEachAction from '../../../../../@Api/Automation/Function/Action/ForEachAction';
import { AssistantForEachAction } from '../../Model/Automation/Action/AssistantForEachAction';
import CompositeAction from '../../../../../@Api/Automation/Function/Action/CompositeAction';
import { findAssistantAction } from './findAssistantAction';
import { wrapAssistantActionInCompositeIfNeeded } from './wrapAssistantActionInCompositeIfNeeded';

export function findAssistantForEachAction(
    action: AssistantForEachAction,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Action<any, any>
{
    const elementParameterId = action.elementVariableId;
    const collection =
        findAssistantComputation(
            action.collection,
            parameters,
            rootParameter
        );
    const forEachAction =
        new ForEachAction(
            elementParameterId,
            collection,
            new CompositeAction([])
        );
    const elementParameter = forEachAction.getElementParameter();
    const elementAction =
        wrapAssistantActionInCompositeIfNeeded(
            findAssistantAction(
                action.action,
                parameters.getNewDictionaryWithParameter(elementParameter),
                rootParameter
            )
        );

    return new ForEachAction(
        elementParameterId,
        collection,
        elementAction
    );
}
