import TableDimensionSection from '../../../../../../../@Api/Layout/Type/Table/Model/TableDimensionSection';
import { useCallback, useMemo } from 'react';
import { useComputed } from 'mobx-react-lite';
import safelySynchronousApplyFunction from '../../../../../../../@Api/Automation/Api/safelySynchronousApplyFunction';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import useReactiveValues from '../../../../../../../@Api/Automation/Api/useReactiveValues';
import FunctionContext from '../../../../../../../@Api/Automation/Function/FunctionContext';
import TableLayout from '../../../../../../../@Api/Layout/Type/Table/TableLayout';
import CollectionValue from '../../../../../../../@Api/Automation/Value/CollectionValue';
import TableDimensionInstance from '../../../../../../../@Api/Layout/Type/Table/Model/TableDimensionInstance';
import { useDelayedValue } from '../../../../../../../@Future/Util/Hook/useDelayedValue';
import { useMemoizedArray } from '../../../../../../../@Util/Array/useMemoizedArray';
import PagedCollectionValue from '../../../../../../../@Api/Automation/Value/PagedCollectionValue';

interface DimensionInstancesResult
{
    instances: TableDimensionInstance[] | undefined;
    isLoading: boolean;
    hasMore: boolean;
    loadMore: () => Promise<void>;
}

interface DimensionInstanceResult
{
    section: TableDimensionSection;
    collection: CollectionValue<any>;
    hasMore: boolean;
}

export function useDimensionInstances(
    layout: TableLayout,
    sections: TableDimensionSection[],
    context: FunctionContext
): DimensionInstancesResult
{
    const dependencies =
        useMemo(
            () =>
                Array.from(
                    new Set(
                        sections
                            .map(
                                rowSection =>
                                    rowSection.getDependencies())
                            .reduce((a, b) => a.concat(b), [])
                            .map(dependency => dependency.parameter)
                    )
                ),
            [
                sections
            ]
        );
    const dependencyValues =
        useComputed(
            () =>
                dependencies.map(
                    dependency =>
                        safelySynchronousApplyFunction(dependency, context)
                ),
            [
                dependencies,
                context
            ]
        );
    const delayedDependencyValues = useDelayedValue(dependencyValues);
    const memoizedDependencyValues =
        useMemoizedArray(
            delayedDependencyValues,
            (a, b) => a.equals(b),
            []
        );
    const [ values,, setValues ] =
        useAsyncResult(
            () =>
                computeDimensionInstanceValues(
                    context,
                    layout,
                    sections
                ),
            [
                context,
                layout,
                ...memoizedDependencyValues.slice()
            ]
        );
    const reactiveValues =
        useReactiveValues(
            () => values ?? [],
            [
                values
            ],
            value =>
                value.collection,
            (base, value) => ({
                ...base,
                collection: value as CollectionValue<any>,
            })
        );
    const [ instances, isLoadingInstances ] =
        useAsyncResult(
            () =>
                computeDimensionInstances(
                    context,
                    layout,
                    reactiveValues
                ),
            [
                context,
                layout,
                reactiveValues,
            ]
        );
    const memoizedInstances =
        useMemoizedArray(
            instances,
            (a, b) =>
                a.id === b.id
                && a.section === b.section
                && a.parameters === b.parameters
                && a.size === b.size
                && a.parameterAssignment.equals(b.parameterAssignment),
            []
        );
    const hasMore =
        useComputed(
            () =>
                reactiveValues.some(
                    reactiveValue =>
                        reactiveValue.hasMore
                ),
            [
                reactiveValues,
            ]
        );
    const loadMore =
        useCallback(
            async () =>
            {
                const newReactiveValues: DimensionInstanceResult[] = [];

                for (const reactiveValue of reactiveValues)
                {
                    if (reactiveValue.hasMore)
                    {
                        const newReactiveValue = {
                            ...reactiveValue,
                            hasMore: false
                        };
                        const addedReactiveValue =
                            await (reactiveValue.collection as PagedCollectionValue<any>)
                                .loadMore()
                                .then(
                                    moreCollection => ({
                                        section: reactiveValue.section,
                                        collection: moreCollection,
                                        hasMore:
                                            moreCollection instanceof PagedCollectionValue
                                            && moreCollection.hasMore,
                                    })
                                );
                        newReactiveValues.push(newReactiveValue);
                        newReactiveValues.push(addedReactiveValue);
                    }
                    else
                    {
                        newReactiveValues.push(reactiveValue);
                    }
                }

                setValues(newReactiveValues);
            },
            [
                reactiveValues,
                setValues,
            ]
        );

    return {
        instances: memoizedInstances,
        isLoading: isLoadingInstances,
        hasMore,
        loadMore,
    };
}

async function computeDimensionInstanceValues(
    context: FunctionContext,
    layout: TableLayout,
    sections: TableDimensionSection[]
): Promise<DimensionInstanceResult[]>
{
    return await Promise.all(
        sections.map(
            async section =>
            {
                const collection =
                    await section.computeInstanceValues(
                        context,
                        layout
                    );

                return {
                    section,
                    collection,
                    hasMore:
                        collection instanceof PagedCollectionValue
                        && collection.hasMore,
                };
            }
        )
    );
}

async function computeDimensionInstances(
    context: FunctionContext,
    layout: TableLayout,
    results: DimensionInstanceResult[]
)
{
    const allInstances =
        await Promise.all(
            results.map(
                (result) =>
                    result.section.computeInstances(
                        context,
                        layout,
                        CollectionValue.getCollection(result.collection)
                    )
            )
        );

    return allInstances.reduce(
        (a, b) =>
            a.concat(b),
        []
    );
}
