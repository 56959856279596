import React from 'react';

/**
 * WordPress dependencies
 */
import { ToolbarDropdownMenu } from '@wordpress/components';
import { __ } from '@wordpress/i18n';

/**
 * Internal dependencies
 */
import HeadingLevelIcon from './GutenbergCoreBlockHeadingLevelIcon';

const HEADING_LEVELS = [ 1, 2, 3, 4, 5, 6 ];

const POPOVER_PROPS = {
    className: 'block-library-heading-level-dropdown',
};

/** @typedef {import('@wordpress/element').WPComponent} WPComponent */

/**
 * HeadingLevelDropdown props.
 *
 * @typedef WPHeadingLevelDropdownProps
 *
 * @property {number}                 selectedLevel The chosen heading level.
 * @property {(newValue:number)=>any} onChange      Callback to run when
 *                                                  toolbar value is changed.
 */

/**
 * Dropdown for selecting a heading level (1 through 6).
 *
 * @param {WPHeadingLevelDropdownProps} props Component props.
 *
 * @return {WPComponent} The toolbar.
 */
export default function HeadingLevelDropdown( { selectedLevel, onChange } ) {
    return (
        <ToolbarDropdownMenu
            popoverProps={ POPOVER_PROPS }
            icon={ <HeadingLevelIcon level={ selectedLevel } /> }
            label={ __( 'Change heading level' ) }
            controls={ HEADING_LEVELS.map( ( targetLevel ) =>
                {
                    const isActive = targetLevel === selectedLevel;

                    return {
                        icon: (
                            <HeadingLevelIcon
                                level={ targetLevel }
                                isPressed={ isActive }
                            />
                        ),
                        label: `Heading ${targetLevel}`,
                        isActive,
                        onClick() {
                            onChange( targetLevel );
                        },
                    };
                }
            ) }
        />
    );
}
