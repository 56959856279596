import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { CustomTextVariants, TextColors, TextStore, TextWeights } from './TextStore';
import { Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import Button from '../Button/Button';
import { View } from '../ViewStack/View';
import { hoverFieldUnderline, hoverFieldUnderlineTransparent } from '../../../@Resource/Theme/Theme';

const styles =
    (theme: Theme) =>
        ({

        });

@observer
class Text extends BaseComponent<TextStore>
{
    renderLoader(store: TextStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: TextStore)
    {
        if (!store.isVisible)
        {
            return null;
        }

        return <div
            style={{
                display:
                    store.isInline
                        ?
                            'inline-block'
                        :
                            undefined,
                textAlign: store.alignment,
                borderBottom:
                    store.showFieldUnderline
                        ?
                            store.isHovering ? hoverFieldUnderline : hoverFieldUnderlineTransparent
                        :
                            undefined,
                padding:
                    store.showFieldUnderline
                        ?
                            '3px 0px'
                        :
                            undefined,
                ...store.style
            }}
        >
            {
                store.tooltip
                    ?
                        <Tooltip
                            title={store.tooltip}
                            enterDelay={500}
                        >
                            {
                                this.renderText(store)
                            }
                        </Tooltip>
                    :
                        this.renderText(store)
            }
        </div>;
    }


    renderText(store: TextStore)
    {
        return <Typography
                component={'span' as any}
                variant={
                    CustomTextVariants.has(store.variant)
                        ?
                            undefined
                        :
                            store.variant as any}
                onMouseOver={store.startHovering}
                onMouseLeave={store.stopHovering}
                align={store.alignment}
                style={{
                    display: store.style && store.style.display ? store.style.display : 'inline',
                    fontWeight: TextWeights.get(store.weight),
                    fontStyle:
                        store.isItalic
                            ?
                                'italic'
                            :
                                undefined,
                    textDecoration:
                        undefined
                        /*store.isLink && store.isHovering && store.hoverDecoration === 'link'
                            ?
                                'underline'
                            :
                                undefined*/,
                    textTransform:
                        store.isLowercase
                            ?
                                'lowercase'
                            :
                                store.isUppercase
                                    ?
                                        'uppercase'
                                    :
                                        undefined,
                    lineHeight:
                        store.lineHeight
                            ?
                                store.lineHeight
                            :
                                store.isDense
                                    ?
                                        1.1
                                    :
                                undefined,
                    cursor:
                        store.isLoading || !store.isLink
                            ?
                                undefined
                            :
                                store.showFieldUnderline && store.isHovering
                                    ?
                                        'text'
                                    :
                                        'pointer',
                    whiteSpace:
                        store.isNoWrap
                            ?
                                'nowrap'
                            :
                                undefined,
                    ...{
                        ...CustomTextVariants.get(store.variant),
                        color:
                            TextColors.has(store.color)
                                ?
                                    TextColors.get(store.color)
                                :
                                    store.color
                    },
                    ...store.size
                        ?
                            {
                                fontSize: store.size
                            }
                        :
                            {},
                    ...store.innerStyle,
                    ...store.style
                }}
                onClick={
                    store.isLoading || !store.isLink
                        ?
                            undefined
                        :
                            store.click}
            >
                {
                    store.view &&
                        <span
                            style={{
                                display: 'inline',
                            }}
                            onClick={store.attemptToStopPropagation}
                        >
                            <View viewComponent={store.view} />
                        </span>
                }

            {
                !store.view && store.template.chunks.map(
                    (chunk, idx) =>
                        <React.Fragment
                            key={idx}
                        >
                            {chunk}
                            {
                                idx < store.childTextStores.length &&
                                    store.childTextStores[idx] instanceof TextStore &&
                                    <Text
                                        store={store.childTextStores[idx]}
                                    />
                            }
                        </React.Fragment>)
            }

            {
                !store.view && store.buttons.length > 0 &&
                    <span
                        style={{
                            marginLeft: 4,
                            display: 'inline'
                        }}
                    >
                        {
                            store.buttons.map(
                                (buttonStore, idx) =>
                                    <Button
                                        key={idx}
                                        store={buttonStore}
                                    />)
                        }
                    </span>
            }
        </Typography>;
    }
}

export default withStyles(styles)(Text as any);
