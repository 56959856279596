import * as React from 'react';
import { dimmedColor, EntityEventType } from '../Model/EntityEventType';
import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';
import { EntityValueUpdateMutation } from '../../../../../@Api/Model/Implementation/EntityValueUpdateMutation';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../../../DataObject/DataObjectStore';
import { Typography } from '@material-ui/core';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { EntityCreationMutation } from '../../../../../@Api/Model/Implementation/EntityCreationMutation';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';
import { BadgeStore } from '../../../../Generic/Badge/BadgeStore';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';

export class EventValueUpdate extends EntityEventType
{
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Entity.Value.Update';
    }

    parentId(): string
    {
        return null;
    }

    isEnabledByDefault(): boolean
    {
        return false;
    }

    color(event: EntityEvent): string
    {
        return dimmedColor(event.entity.entityType);
    }

    icon(event: EntityEvent): string
    {
        return 'low_priority';
    }

    isFilled(event: EntityEvent): boolean
    {
        return false;
    }

    name(event: EntityValueUpdateMutation): string
    {
        return `${event.entityField.name}`;
    }

    description(event: EntityValueUpdateMutation): string
    {
        return this.getDataObject(event, true).toString();
    }

    content(event: EntityValueUpdateMutation): JSX.Element
    {
        return <Typography
            variant="body2"
        >
            {this.getDataObject(event, true).toString()}
        </Typography>;
    }

    caption(event: EntityEvent): string
    {
        return this.localizationStore.translate('Event.ValueUpdate.Caption'); // changed
    }

    actions(event: EntityValueUpdateMutation): ButtonStore[]
    {
        return [];
    }

    badges(event: EntityEvent): BadgeStore[]
    {
        return [];
    }

    allowExpansion(event: EntityValueUpdateMutation): boolean
    {
        return false;
    }

    expandInSidebar(event: EntityCreationMutation): boolean
    {
        return false;
    }

    expansionComponent(event: EntityValueUpdateMutation): React.ComponentClass<BaseComponentProps<BaseStore>>
    {
        return null;
    }

    expansionStore(event: EntityValueUpdateMutation): BaseStore
    {
        return null;
    }

    typeNameSingular(event: EntityValueUpdateMutation): string
    {
        return this.localizationStore.translate('Event.ValueUpdate.Singular.Name'); // field change
    }

    typeNamePlural(): string
    {
        return this.localizationStore.translate('Event.ValueUpdate.Plural.Name'); // field changes
    }

    getDataObject(event: EntityValueUpdateMutation,
                  isNew: boolean)
    {
        return event.getDataObject(isNew);
    }

    setDataObject(event: EntityValueUpdateMutation,
                  isNew: boolean,
                  dataObject: DataObject)
    {
        event.setDataObject(isNew, dataObject);
    }
}
