import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import { useContext, useEffect, useState } from 'react';
import { EntitySelectionBuilder } from '../../../Selection/Builder/EntitySelectionBuilder';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { Aggregate } from '../../../../DataObject/Model/Aggregate';
import EntityTypeContext from '../../../Type/EntityTypeContext';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import useDateValues from './Date/useDateValues';
import DataObjectContext from '../../../../DataObject/DataObjectContext';
import { runInAction } from 'mobx';
import AutoReloadContext from '../../Context/AutoReloadContext';
import { AutoReloadInterval } from '../../Context/AutoReloadConstants';
import Segment from '../../Model/Segment';
import { createEntityComparator } from '../../../List/Comparator/EntityComparator';
import { MathematicalOperator } from '../../../../DataObject/Model/MathematicalOperator';
import useIsWorkflowPipeline from '../../../View/Api/useIsWorkflowPipeline';
import useWorkflowStates from '../../../Workflow/Api/useWorkflowStates';
import { DateType } from '../../../../DataObject/Type/Date/DateType';
import { DateRangeType } from '../../../../DataObject/Type/Date/DateRange/DateRangeType';
import { DateIntervalValue } from '../../../../DataObject/Type/Date/DateInterval/DateIntervalValue';
import moment from 'moment';
import { DateRangeValue } from '../../../../DataObject/Type/Date/DateRange/DateRangeValue';
import PipelineContext from '../../Context/PipelineContext';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../@Api/Automation/Value/Type/EntityValueType';
import ParameterAssignment from '../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import EntityValue from '../../../../../../@Api/Automation/Value/EntityValue';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import { useIsMounted } from '../../../../../../@Util/Async/useIsMounted';

export default function useAugmentedResult(
    entityType: EntityType,
    segment: Segment,
    parameter: Parameter<EntityValueType>,
    result?: EntitySelectionAggregateResult,
    filter?: Predicate
)
{
    const [ augmentedResult, setAugmentedResult ] = useState(result);
    const entityTypeStore = useContext(EntityTypeContext);
    const dataObjectStore = useContext(DataObjectContext);
    const dateValues = useDateValues();
    const doAutoReload = useContext(AutoReloadContext);
    const pipeline = useContext(PipelineContext);
    const isMounted = useIsMounted();

    useEffect(
        () =>
        {
            function load()
            {
                if (segment.chart)
                {
                    return () => {};
                }

                return runInAction(
                    () =>
                    {
                        const groupFieldPath = segment.groupFieldPath;

                        if (!groupFieldPath)
                        {
                            return () => {};
                        }

                        const rootEntityType = groupFieldPath.path.rootEntityType;
                        const rootPath = EntityPath.root(rootEntityType);
                        const selectionBuildersToDiscard: EntitySelectionBuilder[] = [];

                        function getBaseSelectionBuilder()
                        {
                            const selectionBuilder =
                                new EntitySelectionBuilder(rootEntityType)
                                    .if(
                                        () => !rootEntityType.isA(entityType),
                                        sb =>
                                            sb.where(
                                                cb =>
                                                    cb.isOfType(
                                                        rootPath,
                                                        entityType)))
                                    .if(
                                        () =>
                                            filter !== undefined,
                                        builder =>
                                            builder.where(
                                                cb =>
                                                    cb.filter(
                                                        filter,
                                                        {
                                                            parameter: parameter,
                                                        }
                                                    )
                                            )
                                    )
                                    .aggregateOn(
                                        new EntityFieldPath(
                                            rootPath,
                                            entityTypeStore.idField),
                                        undefined,
                                        Aggregate.Count
                                    )
                                    .if(
                                        () => true,
                                        builder =>
                                            segment.aggregateValues
                                                .filter(
                                                    aggregateValue =>
                                                        aggregateValue.fieldPath)
                                                .forEach(
                                                    aggregateValue =>
                                                        builder.aggregateOn(
                                                            aggregateValue.fieldPath,
                                                            undefined,
                                                            aggregateValue.aggregate
                                                        )
                                                )
                                    );

                            selectionBuildersToDiscard.push(selectionBuilder);

                            return selectionBuilder;
                        }

                        if (segment.isDate && segment.isDateSegmentedInCategories)
                        {
                            Promise.all(
                                dateValues.map(
                                    dateValue =>
                                        getBaseSelectionBuilder()
                                            .if(
                                                () =>
                                                    segment.secondaryGroupFieldPath !== undefined,
                                                sb =>
                                                    sb.groupBy(segment.secondaryGroupFieldPath))
                                            .where(
                                                cb =>
                                                    cb.in(
                                                        groupFieldPath,
                                                        undefined,
                                                        dateValue))
                                            .where(
                                                cb =>
                                                    cb.isDefined(groupFieldPath))
                                            .selectAggregates(doAutoReload)))
                                .then(
                                    values =>
                                    {
                                        if (isMounted())
                                        {
                                            setAugmentedResult(
                                                new EntitySelectionAggregateResult(
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [
                                                        DataObject.constructFromTypeIdAndValue(
                                                            'Number',
                                                            values.map(value => value.aggregates[0].value).reduce((a, b) => a + b, 0),
                                                            dataObjectStore)
                                                    ],
                                                    groupFieldPath,
                                                    values.map(
                                                        (value, idx) =>
                                                        {
                                                            const newValue =
                                                                new EntitySelectionAggregateResult(
                                                                    value.groupEntity,
                                                                    idx >= dateValues.length
                                                                        ?
                                                                        values[idx].groupValue
                                                                        :
                                                                        dateValues[idx],
                                                                    value.groupInterval,
                                                                    value.aggregates,
                                                                    value.groupFieldPath,
                                                                    value.children);

                                                            (newValue as any).idx = idx;

                                                            return newValue;
                                                        })));
                                        }
                                    });
                        }
                        else
                        {
                            let interval: DataObject = undefined;
                            let range: DataObject = undefined;

                            if (groupFieldPath.field?.dataObjectSpecification.type instanceof DateType
                                || groupFieldPath.field?.dataObjectSpecification.type instanceof DateRangeType)
                            {
                                interval =
                                    DataObject.constructFromTypeIdAndValue(
                                        'DateInterval',
                                        {
                                            type: 'Monthly'
                                        } as DateIntervalValue);

                                range =
                                    DataObject.constructFromTypeIdAndValue(
                                        'DateRange',
                                        {
                                            from: moment().startOf('year').endOf('month').toDate(),
                                            to: moment().startOf('year').add(1, 'year').toDate()
                                        } as DateRangeValue);
                            }

                            getBaseSelectionBuilder()
                                .groupBy(groupFieldPath, interval ? { interval: interval, value: range, isFloored: true, isCeiled: true } : undefined)
                                .if(
                                    () =>
                                        segment.secondaryGroupFieldPath !== undefined,
                                    sb =>
                                        sb.groupBy(segment.secondaryGroupFieldPath))
                                .selectAggregates(doAutoReload)
                                .then(
                                    result =>
                                    {
                                        if (isMounted())
                                        {
                                            setAugmentedResult(result);
                                        }
                                    });
                        }

                        return () =>
                            selectionBuildersToDiscard.forEach(
                                selectionBuilder =>
                                    selectionBuilder.dispose());
                    });
            }

            const dispose = load();

            if (doAutoReload)
            {
                const interval =
                    setInterval(
                        () =>
                            load(),
                        AutoReloadInterval);

                return () =>
                {
                    clearInterval(interval);
                    dispose();
                };
            }

            return () =>
                dispose();
        },
        [
            entityType,
            parameter,
            segment,
            segment.groupFieldPath,
            segment.secondaryGroupFieldPath,
            segment.chart,
            segment.aggregateValues,
            segment.isDate,
            segment.isDateSegmentedInCategories,
            result,
            entityTypeStore,
            dataObjectStore,
            dateValues,
            filter,
            doAutoReload,
            isMounted
        ]);

    const isWorkflowPipeline = useIsWorkflowPipeline(segment);
    const workflowStates =
        useWorkflowStates(
            isWorkflowPipeline ? segment.groupFieldPath.relationshipDefinition.parentEntityType : undefined,
            undefined,
            pipeline);

    const [ resultWithOmittedPhases, setResultWithOmittedPhases ] = useState<EntitySelectionAggregateResult>();

    useEffect(
        () =>
        {
            if (augmentedResult)
            {
                if (isWorkflowPipeline && workflowStates)
                {
                    let children = augmentedResult.children;

                    const trueStatements =
                        filter
                            ? filter.getTrueStatements(
                                new FunctionContext(
                                    new ParameterDictionary([]),
                                    new ParameterAssignment()
                                )
                            )
                            : [];

                    const entitiesToEnrichResultWith =
                        workflowStates
                            .filter(
                                state =>
                                    trueStatements
                                        .filter(
                                            predicate =>
                                                predicate.isValid()
                                        )
                                        .every(
                                            predicate =>
                                            {
                                                if (predicate instanceof ComparisonPredicate
                                                    && predicate.lhs instanceof ValueFromEntityComputation
                                                    && predicate.lhs.fieldPath.path.entityType.isA(entityTypeStore.bespoke.types.Datastore.Phase.Type))
                                                {
                                                    const parameter =
                                                        new Parameter(
                                                            'p',
                                                            new EntityValueType(state.entityType),
                                                            true,
                                                            undefined);
                                                    const parameterDictionary = new ParameterDictionary([ parameter ]);
                                                    const parameterAssignment = new ParameterAssignment();
                                                    parameterAssignment.setValue(
                                                        parameter,
                                                        new EntityValue(state));
                                                    const context =
                                                        new FunctionContext(
                                                            parameterDictionary,
                                                            parameterAssignment);

                                                    return new ComparisonPredicate(
                                                        new ValueFromEntityComputation(
                                                            parameter,
                                                            EntityPath.fromEntity(state)
                                                                .field(predicate.lhs.fieldPath.field)),
                                                        predicate.comparator,
                                                        predicate.rhs)
                                                        .synchronouslyEvaluate(context);
                                                }
                                                else
                                                {
                                                    return true;
                                                }
                                            }));

                    // Disallow empty categories
                    children =
                        children
                            .filter(
                                value =>
                                    value.groupEntity != null);

                    if (entitiesToEnrichResultWith.length > 0)
                    {
                        const existentEntityIds = new Set<number>();

                        children
                            .filter(
                                child =>
                                    child.groupEntity)
                            .forEach(
                                child =>
                                    existentEntityIds.add(child.groupEntity.id));

                        entitiesToEnrichResultWith
                            .filter(
                                entity =>
                                    !existentEntityIds.has(entity.id))
                            .forEach(
                                entity =>
                                    children.push(
                                        new EntitySelectionAggregateResult(
                                            entity,
                                            undefined,
                                            undefined,
                                            [
                                                DataObject.constructFromTypeIdAndValue('Number', 0, entityTypeStore.dataObjectStore),
                                                ...segment.aggregateValues.map(
                                                    aggregateValue =>
                                                        aggregateValue.fieldPath.getNewDataObject(entityTypeStore.dataObjectStore, 0))
                                            ],
                                            children.length > 0
                                                ?
                                                children[0].groupFieldPath
                                                :
                                                undefined,
                                            [])));
                    }

                    if (segment
                        && segment.groupFieldPath
                        && segment.groupFieldPath.isRelationship)
                    {
                        const comparator =
                            createEntityComparator<EntitySelectionAggregateResult>(
                                result =>
                                    result.groupEntity);

                        children =
                            children.slice()
                                .sort(comparator);
                    }

                    // Add empty result if there is no empty child and count is not met
                    if (children.every(
                        child =>
                            !child.isEmpty))
                    {
                        const totalByAggregate = new Map<number, DataObject>();

                        augmentedResult.aggregates.forEach(
                            (aggregate, idx) =>
                                totalByAggregate.set(
                                    idx,
                                    aggregate));

                        children.forEach(
                            child =>
                                child.aggregates.forEach(
                                    (aggregate, idx) =>
                                        totalByAggregate.set(
                                            idx,
                                            DataObject.compute(
                                                totalByAggregate.get(idx),
                                                aggregate,
                                                MathematicalOperator.Subtract))));

                        if (totalByAggregate.get(0).value > 0)
                        {
                            children.unshift(
                                new EntitySelectionAggregateResult(
                                    undefined,
                                    undefined,
                                    undefined,
                                    augmentedResult.aggregates
                                        .map(
                                            (_, idx) =>
                                                totalByAggregate.get(idx)),
                                    segment.groupFieldPath,
                                    []));
                        }
                    }

                    // Remove children which are invisible
                    const visiblePhaseIds =
                        new Set(
                            entitiesToEnrichResultWith.map(
                                phase => phase.uuid));

                    children =
                        children.filter(
                            child =>
                                visiblePhaseIds.has(child.groupEntity?.uuid));

                    setResultWithOmittedPhases(
                        new EntitySelectionAggregateResult(
                            augmentedResult.groupEntity,
                            augmentedResult.groupValue,
                            augmentedResult.groupInterval,
                            augmentedResult.aggregates,
                            augmentedResult.groupFieldPath,
                            children));
                }
                else
                {
                    setResultWithOmittedPhases(augmentedResult);
                }
            }
        },
        [
            entityType,
            segment,
            filter,
            entityTypeStore,
            augmentedResult,
            isWorkflowPipeline,
            workflowStates,
            setResultWithOmittedPhases
        ]);

    return resultWithOmittedPhases;
}
