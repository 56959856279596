import React, { useContext, useMemo, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import Fields from '../../../Fields/Fields';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import ViewModel from '../../../View/Model/View';
import useTypes from '../../../Type/Api/useTypes';
import Specification from '../../../View/Model/Specification';
import ListModel from '../../../View/Model/Specification/List';
import Column from '../../../View/Model/Specification/Column';
import uuid from '../../../../../../@Util/Id/uuid';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import Ordering from '../../../View/Model/Ordering';
import List from '../../../View/List/List';
import { Comparator } from '../../../../DataObject/Model/Comparator';
import TabBar from '../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import { LogicalOperator } from '../../../../DataObject/Model/LogicalOperator';
import useCount from '../../../Selection/Hooks/useCount';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import CompositePredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import EntityValue from '../../../../../../@Api/Automation/Value/EntityValue';
import { ViewParams } from '../../../View/Model/ViewParams';
import EmptyValue from '../../../../../../@Api/Automation/Value/EmptyValue';
import { MailboxConfig } from './MailboxConfig/MailboxConfig';
import MailboxAutomations from './MailboxAutomations/MailboxAutomations';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import getViewParameters from '../../../View/Api/getViewParameters';
import { LogEventsViewer } from '../../../../LogEventsViewer/LogEventsViewer';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';

export interface MailboxProps
{
    entity: Entity;
}
const Mailbox: React.FC<MailboxProps> =
    ({
        entity,
     }) =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);
        const [ tab, setTab ] = useState(0);

        const noRelationshipCount =
            useCount(
                types.Activity.Email.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Mailbox.RelationshipDefinition.Emails,
                                        true),
                                    entity))
                        .where(
                            cb =>
                                cb.isNotDefined(
                                    rootPath
                                        .joinTo(
                                            types.Relationship.RelationshipDefinition.Activities,
                                            true)
                                        .field(types.Entity.Field.Id))),
                [
                    types,
                    entity
                ]);
        const withRelationshipCount =
            useCount(
                types.Activity.Email.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Mailbox.RelationshipDefinition.Emails,
                                        true),
                                    entity))
                        .where(
                            cb =>
                                cb.isDefined(
                                    rootPath
                                        .joinTo(
                                            types.Relationship.RelationshipDefinition.Activities,
                                            true)
                                        .field(types.Entity.Field.Id))),
                [
                    types,
                    entity
                ]);
        const view =
            useMemo(
                () =>
                {
                    const rootPath = EntityPath.fromEntityType(types.Activity.Email.Type);
                    const sendDateColumn =
                        new Column(
                            uuid(),
                            rootPath.field(types.Activity.Email.Field.SendDate));
                    const viewParameters = getViewParameters(types.Activity.Email.Type);

                    return new ViewModel(
                        'List',
                        types.Activity.Email.Type.getName(true),
                        types.Activity.Email.Type,
                        viewParameters,
                        new CompositePredicate(
                            LogicalOperator.And,
                            [
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        viewParameters.getParameterById(ViewParams.Entity),
                                        rootPath
                                            .joinTo(
                                                types.Mailbox.RelationshipDefinition.Emails,
                                                true)
                                            .field()),
                                    Comparator.Equals,
                                    new EntityValue(entity)),
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        viewParameters.getParameterById(ViewParams.Entity),
                                        rootPath
                                            .joinTo(
                                                types.Relationship.RelationshipDefinition.Activities,
                                                true)
                                            .field(types.Entity.Field.Id)),
                                    tab === 0 ? Comparator.IsNotDefined : Comparator.IsDefined,
                                    EmptyValue.instance)
                            ]),
                        new Specification(
                            new ListModel(
                                [
                                    sendDateColumn,
                                    new Column(
                                        uuid(),
                                        rootPath
                                            .joinTo(
                                                types.Activity.Email.RelationshipDefinition.From,
                                                false)
                                            .field(types.Recipient.Email.Field.EmailAddress)),
                                    new Column(
                                        uuid(),
                                        rootPath.field(types.Activity.Field.Subject))
                                ],
                                [
                                    new Ordering(
                                        sendDateColumn,
                                        false)
                                ])),
                        undefined);
                },
                [
                    entity,
                    types,
                    tab,
                ]);

        const configuration =
            useEntityValue<any>(
                entity,
                types.Mailbox.Field.Configuration
            );

        const allowLogging =
            useComputed(
                () =>
                    currentUserStore.isAdministrator &&
                    configuration?.type === 'Microsoft',
                [
                    configuration,
                    currentUserStore
                ]
            )

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset
                                    bottom={false}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <TypeAndName
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                                <Fields
                                    entity={entity}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    currentUserStore.isAdministrator &&
                        <MailboxConfig
                            entity={entity}
                        />
                }
                {
                    currentUserStore.isAdministrator &&
                    <Grid
                            item
                            xs={12}
                    >
                        <MailboxAutomations
                            entity={entity}
                        />
                    </Grid>
                }

                <Grid
                    item
                    xs={12}
                >
                    <Card>
                        <TabBar
                            value={tab}
                        >
                            <Tab
                                value={0}
                                onClick={setTab}
                            >
                                <LocalizedText
                                    code="Mailbox.Tabs.Unlinked"
                                    value="Ongekoppeld (${noRelationshipCount})"
                                    noRelationshipCount={noRelationshipCount}
                                />
                            </Tab>
                            <Tab
                                value={1}
                                onClick={setTab}
                            >
                                <LocalizedText
                                    code="Mailbox.Tabs.Linked"
                                    value="Gekoppeld (${withRelationshipCount})"
                                    withRelationshipCount={withRelationshipCount}
                                />
                            </Tab>
                            {
                                allowLogging &&
                                <Tab
                                    value={2}
                                    onClick={setTab}
                                >
                                    <LocalizedText
                                        code="Mailbox.Tabs.Logs"
                                        value="Logs"
                                    />
                                </Tab>
                            }
                        </TabBar>
                        {
                            (tab === 0 || tab === 1) &&
                            <List
                                view={view}
                            />
                        }
                        {
                            allowLogging && (tab === 2 ) &&
                            <CardInset>
                                <LogEventsViewer
                                    logName={`mailbox/${entity.uuid}`}
                               />
                            </CardInset>
                        }
                    </Card>
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(Mailbox);
