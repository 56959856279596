import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import useApiClient from '../../../@Service/ApiClient/Hooks/useApiClient';
import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ViewGroup from '../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { makeStyles } from '@material-ui/styles';
import Switch from '../../../@Future/Component/Generic/Input/Switch/Switch';
import Input from '../../../@Future/Component/Generic/Input/Input/Input';
import CurrentUserContext from '../User/CurrentUserContext';
import { LogEvent } from '../LogViewer/Model/LogEvent';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { LogQueryResult } from '../LogViewer/Model/LogQueryResult';
import DateEditor from '../../../@Future/Component/Generic/Input/DateEditor/DateEditor';
import TimePicker from '../../../@Future/Component/Generic/Input/TimePicker/TimePicker';
import { LogEventsRow } from './Row/LogEventsRow';
import moment from 'moment';
import { format } from 'date-fns';
import { LogEventsRowLoadMore } from './Row/LoadMore/LogEventsRowLoadMore';
import { LogLevel } from './Model/LogLevel';
import { LogEventsLevelSelector } from './LevelSelector/LogEventsLevelSelector';

export interface LogEventsViewerProps
{
    logName: string;
    useOldLogEntries?: boolean;
}

const useStyles = makeStyles({
        root: {
            width: '100%'
        },
        tableContainer: {
            maxHeight: 600,
            minHeight: 600,
            border: 'rgba(0, 0, 0, 0.08) 1px solid'
        },
        header: {
            backgroundColor: 'white',
            position: 'sticky',
            top: 0,
            zIndex: 1 // Mui sets sticky header to zIndex: 2, here we put it back to 1 otherwise our own Popup can't hover over it
        },
        loader: {
            height: 5,
            display: 'block !important'
        },
        hightlightRow:
            {
                background: '#f8f8f8',
                fontWeight: 'bold'
            },
    }
);

export interface CursorPosition
{
    start: Date;
    end: Date;
    page: number;
}

export const LogEventsViewer: React.FC<LogEventsViewerProps> =
    (
        {
            logName,
            useOldLogEntries
        }
    ) =>
    {
        const pageSize = 15;
        const classes = useStyles();
        const apiClient = useApiClient();
        const currentUserStore = useContext(CurrentUserContext);
        const [ levels, setLevels ] = useState<LogLevel[]>([ 'Info', 'Warning', 'Error' ]);
        const [ showDebugMessage, setShowDebugMessage ] = useState<boolean>(false);
        const [ distanceBottom, setDistanceBottom ] = useState(0);
        const tableRef = useRef(null);
        const initialDateRef = useRef(null);

        const [ initialDate, setInitialDate ] = useState<Date>(new Date());
        const [ focusOnInitialDate, setFocusOnInitialDate ] = useState<boolean>(true);
        const [ isLoading, setLoading ] = useState<boolean>(false);
        const [ futureEvents, setFutureEvents ] = useState<LogEvent[]>([]);
        const [ pastEvents, setPastEvents ] = useState<LogEvent[]>([]);
        const [ hasMoreFutureEvents, setHasMoreFutureEvents ] = useState<boolean>(false);
        const [ hasMorePastEvents, setHasMorePastEvents] = useState<boolean>(false);
        const [ futureCursor, setFutureCursor ] =
            useState<CursorPosition>(
                {
                    start: initialDate,
                    end: moment(initialDate).add(1, 'days').toDate(),
                    page: 0
                }
            );
        const hasPermissionToView =
            useMemo(
                () => currentUserStore.isSupport || currentUserStore.isAdministrator,
                [
                    currentUserStore
                ]
            );

        const updateLogLevels = useCallback(
            (newLevels: LogLevel[]) =>
            {
                setLevels(newLevels);
                setPastEvents([]);
                setFutureEvents([]);
            },
            [
                setPastEvents,
                setFutureEvents,
                setLevels
            ]
        );

        const [ pastCursor, setPastCursor ] =
            useState<CursorPosition>(
                {
                    start: moment(initialDate).subtract(1, 'days').toDate(),
                    end: initialDate,
                    page: 0
                }
            );
        const loadPast =
            useCallback(
                () =>
                {
                    if (hasMorePastEvents)
                    {
                        setPastCursor(
                            cursor =>
                                (
                                    {
                                        ...cursor,
                                        page: cursor.page + 1
                                    }
                                )
                        );
                    }
                    else
                    {
                        setPastCursor(
                            cursor =>
                                (
                                    {
                                        start: moment(cursor.start).subtract(1, 'days').toDate(),
                                        end: cursor.start,
                                        page: 0
                                    }
                                )
                        );
                    }
                },
                [
                    setPastCursor,
                    hasMorePastEvents
                ]
            );
        const loadFuture =
            useCallback(
                () =>
                {
                    if (hasMoreFutureEvents)
                    {
                        setFutureCursor(
                            cursor =>
                                (
                                    {
                                        start: cursor.start,
                                        end: cursor.end,
                                        page: cursor.page + 1
                                    }
                                )
                        );
                    }
                    else
                    {
                        setFutureCursor(
                            cursor =>
                            {
                                const newEnd = moment(cursor.end).add(1, 'days').toDate();
                                const currentTime = new Date();

                                return (
                                    {
                                        start: cursor.start,
                                        end:
                                            (newEnd > currentTime)
                                                ? currentTime
                                                : newEnd,
                                        page: 0
                                    }
                                );
                            }
                        );
                    }
                },
                [
                    setFutureCursor,
                    hasMoreFutureEvents
                ]
            );
        const updateCursors =
            useCallback(
                (date: Date, hours: number, minutes: number) =>
                {
                    date.setHours(hours);
                    date.setMinutes(minutes);

                    setInitialDate(date);
                    setFutureCursor(
                        {
                            start: date,
                            end: moment(date).add(1, 'days').toDate(),
                            page: 0
                        }
                    );
                    setPastCursor(
                        {
                            start: moment(date).subtract(1, 'days').toDate(),
                            end: date,
                            page: 0
                        }
                    );

                    setFocusOnInitialDate(true);
                    setPastEvents([]);
                    setFutureEvents([]);
                },
                [
                    setInitialDate,
                    setPastEvents,
                    setFutureEvents,
                    setFutureCursor,
                    setPastCursor,
                    setFocusOnInitialDate
                ]
            );
        const scrollListener = useCallback(
            async () =>
            {
                let bottom = tableRef.current.scrollHeight - tableRef.current.clientHeight;

                if (!distanceBottom)
                {
                    // calculate distanceBottom that works for you
                    setDistanceBottom(Math.round((bottom / 100) * 20))
                }

                if (tableRef.current.scrollTop > bottom - distanceBottom && !isLoading)
                {
                    loadFuture();
                }
                else if (tableRef.current.scrollTop < 40 && !isLoading)
                {
                    loadPast();
                }
            },
            [
                isLoading,
                tableRef,
                loadFuture,
                loadPast,
                distanceBottom,
                setDistanceBottom
            ]
        );

        // Load past
        useEffect(
            () =>
            {
                if (!hasPermissionToView)
                {
                    return;
                }

                setLoading(true);

                apiClient.request(
                    new ApiRequest<LogQueryResult>(
                        '/logEvents',
                        Method.Get,
                        {
                            name: logName,
                            fromDate: pastCursor.start.toISOString(),
                            toDate: pastCursor.end.toISOString(),
                            page: pastCursor.page,
                            pageSize: pageSize,
                            onlyUserMessages: !showDebugMessage,
                            levels: levels.join(','),
                            orderDescending: true
                        }
                    )
                ).then(
                    result =>
                    {
                        const scrollToBottom = tableRef.current.scrollHeight -tableRef.current.scrollTop;

                        setPastEvents(
                            events =>
                                [
                                    ...result.events.reverse().filter(
                                        event =>
                                            events.findIndex(
                                                e =>
                                                    e.id === event.id
                                            ) === -1,
                                    ),
                                    ...events
                                ]
                        );

                        setHasMorePastEvents(
                            result.events?.length === pageSize
                        );

                        tableRef.current.scrollTop = tableRef.current.scrollHeight - scrollToBottom;

                        if (focusOnInitialDate)
                        {
                            tableRef.current.scrollTo({top: initialDateRef.current.offsetTop - 600});
                            setFocusOnInitialDate(false);
                        }

                        setLoading(false);
                    }
                )
            },
            [
                hasPermissionToView,
                setLoading,
                apiClient,
                pastCursor,
                pageSize,
                showDebugMessage,
                levels,
                setPastEvents,
                setHasMorePastEvents,
                logName,
                focusOnInitialDate
            ]
        );

        // Load future
        useEffect(
            () =>
            {
                setLoading(true);

                if (!hasPermissionToView)
                {
                    return;
                }

                apiClient.request(
                    new ApiRequest<LogQueryResult>(
                        '/logEvents',
                        Method.Get,
                        {
                            name: logName,
                            fromDate: futureCursor.start.toISOString(),
                            toDate: futureCursor.end.toISOString(),
                            page: futureCursor.page,
                            pageSize: pageSize,
                            onlyUserMessages: !showDebugMessage,
                            levels: levels.join(','),
                        }
                    )
                ).then(
                    result =>
                    {
                        setFutureEvents(
                            events =>
                                [
                                    ...events,
                                    ...result.events.filter(
                                        event =>
                                            events.findIndex(
                                                e =>
                                                    e.id === event.id
                                            ) === -1,
                                    )
                                ]
                        );

                        setHasMoreFutureEvents(
                            result.events?.length === pageSize
                        );

                        setLoading(false);
                    }
                );
            },
            [
                hasPermissionToView,
                futureCursor,
                setLoading,
                apiClient,
                logName,
                pageSize,
                showDebugMessage,
                setFutureEvents,
                setHasMoreFutureEvents,
                levels,
                initialDateRef,
                focusOnInitialDate,
                setFocusOnInitialDate
            ]
        );

        useLayoutEffect(
            () =>
            {
                if (hasPermissionToView)
                {
                    const table = tableRef?.current;
                    if (table !== undefined)
                    {
                        table.addEventListener('scroll', scrollListener);
                    }

                    return () =>
                    {
                        table.removeEventListener('scroll', scrollListener);
                    }
                }
            },
            [
                hasPermissionToView,
                tableRef,
                scrollListener
            ]
        );


        if (hasPermissionToView)
        {
            return <div
                className={classes.root}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem
                        alignment="right"
                    >
                        <Input
                            label="Jump to: "
                            labelPosition="left"
                            inline
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={8}
                            >
                                <ViewGroupItem>
                                    <DateEditor
                                        disableUnderline
                                        value={initialDate}
                                        onChange=
                                            {
                                                selectedDate =>
                                                    updateCursors(selectedDate, initialDate.getHours(), initialDate.getMinutes())
                                            }
                                        fitContent
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem ratio={1}>
                                    <TimePicker
                                        hour={initialDate.getHours()}
                                        minute={initialDate.getMinutes()}
                                        onChange=
                                            {
                                                (hours, minutes) =>
                                                    updateCursors(initialDate, hours, minutes)
                                            }
                                        disableUnderline
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </Input>
                    </ViewGroupItem>
                    <ViewGroupItem
                        className={classes.loader}
                    >
                        {
                            isLoading &&
                            <LinearProgress />
                        }
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <TableContainer
                            className={classes.tableContainer}
                            ref={tableRef}
                        >
                            <Table
                                stickyHeader
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            width="2%"
                                            className={classes.header}
                                            size="medium"
                                        >
                                            Date
                                        </TableCell>
                                        <TableCell
                                            width="1%"
                                            className={classes.header}
                                            align="center"
                                        >
                                            <LogEventsLevelSelector
                                                value={levels}
                                                onChange={updateLogLevels}
                                            />
                                        </TableCell>
                                        <TableCell
                                            className={classes.header}
                                        >
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={0}
                                                alignment="center"
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                >
                                                    Message
                                                </ViewGroupItem>
                                                {
                                                    currentUserStore.isSupport && !useOldLogEntries &&
                                                    <ViewGroupItem>
                                                        <Switch
                                                            checked={showDebugMessage}
                                                            onToggle={
                                                                checked =>
                                                                    setShowDebugMessage(checked)
                                                            }
                                                        />
                                                    </ViewGroupItem>
                                                }
                                            </ViewGroup>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <LogEventsRowLoadMore
                                        date={pastCursor.start}
                                        loadMore={loadPast}
                                        isLoading={isLoading}
                                    />
                                    {
                                        pastEvents.map(
                                            event =>
                                                <LogEventsRow
                                                    key={event.id}
                                                    event={event}
                                                    showDebugMessage={showDebugMessage}
                                                    isOldLogEntry={useOldLogEntries}
                                                />
                                        )
                                    }
                                    <TableRow>
                                        <TableCell
                                            colSpan={4}
                                            className={ classes.hightlightRow }
                                            ref={initialDateRef}
                                        >
                                            {
                                                format(
                                                    new Date(initialDate),
                                                    'dd-MM-yyyy HH:mm:ss'
                                                )
                                            }
                                        </TableCell>
                                    </TableRow>
                                    {
                                        futureEvents.map(
                                            event =>
                                                <LogEventsRow
                                                    key={event.id}
                                                    event={event}
                                                    showDebugMessage={showDebugMessage}
                                                    isOldLogEntry={useOldLogEntries}
                                                />
                                        )
                                    }
                                    <LogEventsRowLoadMore
                                        date={futureCursor.end}
                                        loadMore={loadFuture}
                                        isLoading={isLoading}
                                    />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </ViewGroupItem>
                </ViewGroup>
            </div>;
        }
        else
        {
            return null;
        }
    };