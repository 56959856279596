import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import FieldItem from './FieldItem/FieldItem';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { EntityPath } from '../../../../Entity/Path/@Model/EntityPath';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import { Field } from './Model/Field';
import uuid from '../../../../../../@Util/Id/uuid';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import { createTransactionalModel, TransactionalModel } from '../../../../../../@Util/TransactionalModelV2/index';
import getOwnFieldPaths from './Api/getOwnFieldPaths';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import isConnection from './Api/isConnection';
import HoverCardBottom from '../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface TypeEditorProps
{
    entityType: EntityType;
    onAddField: (fieldPath: EntityFieldPath) => void;
}

const ConnectionsEditor: React.FC<TypeEditorProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);

        const rootPath =
            useComputed(
                () =>
                    EntityPath.fromEntityType(props.entityType),
                [
                    props.entityType
                ]);

        const fieldPaths =
            useComputed(
                () =>
                    getOwnFieldPaths(rootPath)
                        .filter(
                            fieldPath =>
                                isConnection(fieldPath)),
                [
                    rootPath
                ]);

        const [ fields, setFields ] = useState<TransactionalModel<Field>[]>([]);

        const loadFields =
            useCallback(
                () =>
                    fieldPaths
                        .map(
                            fieldPath =>
                            {
                                const ownedPacks = [
                                    ...currentUserStore.rightProfile.ownedPacks,
                                    currentUserStore.rightProfile.environmentPack
                                ];

                                const fieldPack =
                                    fieldPath.relationshipDefinition.entity.getRelatedEntityByDefinition(
                                        true,
                                        types.Pack.RelationshipDefinition.Entities);

                                return createTransactionalModel(
                                    new Field(
                                        fieldPath.id,
                                        fieldPath.path.entityType,
                                        fieldPath.isParentRelationship,
                                        fieldPath.relationshipDefinition.cardinality,
                                        fieldPath.relationshipDefinition.getNameDataObject(fieldPath.isParentRelationship).value,
                                        fieldPath.relationshipDefinition.getNameDataObject(!fieldPath.isParentRelationship).value,
                                        ownedPacks
                                            .some(
                                                pack =>
                                                    equalsEntity(
                                                        fieldPack,
                                                        pack)),
                                        fieldPath.relationshipDefinition.isVisibleDuringConstruction(fieldPath.isParentRelationship),
                                        fieldPath.relationshipDefinition.isVisibleInDetails(!fieldPath.isParentRelationship),
                                        fieldPath.relationshipDefinition.isVisibleAsTab(!fieldPath.isParentRelationship),
                                        fieldPack,
                                        fieldPath));
                            }),
                [
                    fieldPaths,
                    props.entityType,
                    types,
                    currentUserStore
                ]);

        useEffect(
            () =>
                setFields(loadFields()),
            [
                setFields,
                loadFields
            ]);

        const addField =
            useCallback(
                () =>
                {
                    setFields([
                        ...fields,
                        createTransactionalModel(
                            new Field(
                                uuid(),
                                types.Relationship.Organization.Type,
                                false,
                                undefined,
                                undefined,
                                undefined,
                                true,
                                true,
                                false,
                                false,
                                currentUserStore.rightProfile.ownedEnvironmentPackOrAnyOwnedPack
                            )
                        )
                    ]);
                },
                [
                    setFields,
                    fields,
                    types,
                    currentUserStore
                ]);

        const cancelField =
            useCallback(
                (field: Field) =>
                {
                    if (!field.fieldPath)
                    {
                        setFields(
                            fields.filter(
                                checkField =>
                                    checkField !== field));
                    }
                },
                [
                    fields,
                    setFields
                ]);

        const showPackSelector =
            useComputed(
                () =>
                    currentUserStore.rightProfile.ownedPacks.length > 1,
                [
                    currentUserStore
                ]);

        return <>
            {
                fields.length > 0 &&
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    {...{
                                        width: '15%'
                                    }}
                                >
                                    <LocalizedText
                                        code="Configuration.Connection.ConnectedTo"
                                        value="Gekoppeld aan"
                                    />
                                </TableCell>
                                <TableCell
                                    {...{
                                        width: '15%'
                                    }}
                                >
                                    <LocalizedText
                                        code="Configuration.Connection.EntityTypeHas"
                                        value="${entityTypeName} heeft..."
                                        entityTypeName={props.entityType.getName()}
                                    />
                                </TableCell>
                                <TableCell
                                    {...{
                                        width: '10%'
                                    }}
                                >
                                    <LocalizedText
                                        code="Configuration.Connection.Name"
                                        value="Naam"
                                    />
                                </TableCell>
                                <TableCell
                                    {...{
                                        width: '10%'
                                    }}
                                >
                                    <LocalizedText
                                        code="Configuration.Connection.NameFromConnectedEntity"
                                        value="Naam vanuit gekoppelde entiteit"
                                    />
                                </TableCell>
                                <TableCell
                                    {...{
                                        width: '5%'
                                    }}
                                >
                                    <LocalizedText
                                        code="Configuration.Connection.IsVisibleDuringConstruction"
                                        value="Zichtbaar tijdens aanmaken"
                                    />
                                </TableCell>
                                <TableCell
                                    {...{
                                        width: '5%'
                                    }}
                                >
                                    <LocalizedText
                                        code="Configuration.Connection.IsVisibleInDetails"
                                        value="Zichtbaar in details"
                                    />
                                </TableCell>
                                <TableCell
                                    {...{
                                        width: '5%'
                                    }}
                                >
                                    <LocalizedText
                                        code="Configuration.Connection.IsVisibleAsTab"
                                        value="Zichtbaar als tab"
                                    />
                                </TableCell>
                                <TableCell
                                    {...{
                                        width: '5%'
                                    }}
                                >
                                    <LocalizedText
                                        code="Configuration.Connection.FieldNameInAPI"
                                        value="Veldnaam in API"
                                    />
                                </TableCell>
                                {
                                    showPackSelector &&
                                        <TableCell
                                            {...{
                                                width: '10%'
                                            }}
                                        >
                                            <LocalizedText
                                                code="Configuration.Connection.Pack"
                                                value="Pack"
                                            />
                                        </TableCell>
                                }
                                <TableCell
                                    {...{
                                        width: '5%'
                                    }}
                                >
                                    <LocalizedText
                                        code="Configuration.Field.Filter"
                                        value="Filter"
                                    />
                                </TableCell>
                                <TableCell
                                    {...{
                                        width: '1%'
                                    }}
                                >

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                fields.map(
                                    field =>
                                        <FieldItem
                                            key={field.id}
                                            entityType={props.entityType}
                                            field={field}
                                            onAddField={props.onAddField}
                                            onCancel={cancelField}
                                            showPackSelector={showPackSelector}
                                        />)
                            }
                        </TableBody>
                    </Table>
            }
            {
                !fields.some(
                    field =>
                        !field.fieldPath) &&
                    <HoverCardBottom
                        onClick={addField}
                    >
                        <LocalizedText
                            code="Configuration.Connection.AddConnectionButton"
                            value="+ Koppeling toevoegen"
                        />
                    </HoverCardBottom>
            }
        </>;
    };

export default observer(ConnectionsEditor);
