import { observable } from 'mobx';

export default class Color
{
    // ------------------------- Properties -------------------------

    @observable value: string;

    // ------------------------ Constructor -------------------------

    constructor(value: string)
    {
        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toCss()
    {
        return this.value;
    }

    toDescriptor()
    {
        return this.value;
    }

    static fromDescriptor(descriptor: any)
    {
        return new Color(descriptor);
    }

    // ----------------------- Private logic ------------------------
}
