import { BespokeEntityType } from '../../BespokeEntityType';
import { EntityTypeStore } from '../../EntityTypeStore';
import { RelatedEntityPath } from '../../../Path/@Model/RelatedEntityPath';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { observable } from 'mobx';
import { BespokeDatastorePhaseActivitySubscription } from './BespokeDatastorePhaseActivitySubscription';

export class BespokeDatastorePhase extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable.ref activitySubscription: BespokeDatastorePhaseActivitySubscription;

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore, code: string = 'Datastore.Phase')
    {
        super(entityTypeStore, code);

        this.activitySubscription = new BespokeDatastorePhaseActivitySubscription(this.entityTypeStore);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    orderByField(entity?: Entity,
                 pathFromRelatedEntity?: RelatedEntityPath): EntityFieldPath
    {
        return EntityPath.fromEntityType(this.type).field(this.entityTypeStore.sortIndexField);
    }

    // ----------------------- Private logic ------------------------
}
