import { Action, BespokeEntityType } from '../../../BespokeEntityType';
import { EntityTypeStore } from '../../../EntityTypeStore';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationshipDefinition } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import copyProductLines from '../../../../../../../@Api/Entity/Bespoke/Activity/copyProductLines';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import { getRelationshipAndContactInitializationPathsInViewer } from '../../../Api/getRelationshipAndContactInitializationPathsInViewer';
import { extendContactRelationshipSelection } from '../../../Api/extendContactRelationshipSelection';
import { onRelationshipUpdateCheckAndSetContact } from '../../../Api/onRelationshipUpdateCheckAndSetContact';

export class BespokeActivitySubscription extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Activity.Subscription')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getInitializationPathsInViewer(rootPath: EntityPath): EntityPath[]
    {
        // If fetch the SendToAlternativeContact organization and contact
        const paths = super.getInitializationPathsInViewer(rootPath);

        const relationshipAndContactPaths =
            getRelationshipAndContactInitializationPathsInViewer(
                rootPath,
                this.types.Activity.Subscription.RelationshipDefinition.SendToAlternativeRelationship,
                this.types.Activity.Subscription.RelationshipDefinition.SendToAlternativeContact
            );

        paths.push(
            ...relationshipAndContactPaths
        );

        return paths;
    }

    extendRelationshipSelection(entity: Entity,
                                relationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean,
                                builder: EntitySelectionBuilder,
                                commitContext?: CommitContext)
    {
        super.extendRelationshipSelection(
            entity,
            relationshipDefinition,
            isParent,
            builder,
            commitContext
        );

        const types = this.types;

        if (relationshipDefinition === types.Activity.Subscription.RelationshipDefinition.SendToAlternativeContact
            && isParent)
        {
            extendContactRelationshipSelection(
                types.Activity.Subscription.RelationshipDefinition.SendToAlternativeRelationship,
                true,
                entity,
                builder,
                commitContext
            );
        }
    }

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        // In case of linking a new project to an activity, then:
        // - copy the subject
        if (entity.isNew()
            && isParent
            && relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities
            && relatedEntity)
        {
            // Copy product lines in case of a sales opportunity (repeated ones)
            if (relatedEntity.entityType.isA(this.types.Activity.SalesOpportunity.Type)
                && entity.getRelatedEntitiesByDefinition(false, this.types.Activity.RelationshipDefinition.ProductLines, commitContext).length === 0)
            {
                await copyProductLines(
                    relatedEntity,
                    entity,
                    undefined,
                    commitContext)
                    .then(
                        lines =>
                        {
                            if (lines.length > 0)
                            {
                                setValueByFieldInEntity(
                                    entity,
                                    this.types.Activity.Subscription.Field.Interval,
                                    lines.map(
                                        line =>
                                            line.getObjectValueByField(this.types.ProductLine.Field.RepeatInterval))
                                        .find(() => true),
                                    commitContext
                                );
                            }
                        });
            }

            // Copy fields
            [
                this.types.Activity.Field.Subject,
                this.types.Activity.Field.DiscountPercentage,
                this.types.Activity.Field.IsVatIncluded,
                this.types.Activity.Field.Currency,
            ].forEach(
                field =>
                    setValueByFieldInEntity(
                        entity,
                        field,
                        relatedEntity.getObjectValueByField(field),
                        commitContext
                    )
            );
        }
        // In case of an update of the SendToAlternativeRelationship field, then check if it is a contact and set the SendToAlternativeContact field
        else if (relationshipDefinition === this.types.Activity.Subscription.RelationshipDefinition.SendToAlternativeRelationship
            && isParent)
        {
            await onRelationshipUpdateCheckAndSetContact(
                true,
                this.types.Activity.Subscription.RelationshipDefinition.SendToAlternativeRelationship,
                this.types.Activity.Subscription.RelationshipDefinition.SendToAlternativeContact,
                entity,
                relatedEntity,
                commitContext
            );
        }
    }

    getActions(entity: Entity): Action[]
    {
        const phase = entity.getRelatedEntityByDefinition(false, this.types.Activity.Subscription.RelationshipDefinition.Phase);

        if (phase
            && phase.getObjectValueByField(this.types.Datastore.Field.Code) === 'Activated')
        {
            return [
                ...super.getActions(entity),
                {
                    id: 'Activity.Subscription.Invoice',
                    code: 'Activity.Subscription.Invoice',
                    name: localizeText('SubscriptionActions.InvoiceSubscription', 'Factureer abonnement')
                }
            ];
        }
        else
        {
            return super.getActions(entity);
        }
    }

    // ----------------------- Private logic ------------------------
}
