import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../Value/PrimitiveValue';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import localizeText from '../../../Localization/localizeText';

export default class RegExComputation extends Computation<PrimitiveValueType, PrimitiveValue>
{
    // ------------------------- Properties -------------------------

    @observable pattern: string;
    @observable.ref value: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(pattern: string,
                value: Computation<any, any>)
    {
        super();

        this.pattern = pattern;
        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('Text'));
    }

    getName(): string
    {
        return localizeText(
            'Regex',
            'Reguliere expressie'
        )
    }

    validate(): Validation[]
    {
        if (!this.pattern)
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        'RegExNoValidExpression',
                        'Geen geldig patroon ingesteld'
                    )
                )
            ];
        }

        if (!this.value)
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        'RegExNoValidValue',
                        'Geen geldige waarde ingesteld'
                    )
                    )
            ];
        }

        return [];
    }

    isAsync(): boolean
    {
        return this.value.isAsync();
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'RegEx';
        descriptor.pattern = this.pattern;
        descriptor.value = this.value.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.value.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new RegExComputation(
            descriptor.pattern,
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
