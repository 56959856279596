import { observable } from 'mobx';
import { reference, registerType, type } from '../../../../../@Util/Serialization/Serialization';
import TypeMappingSummary from './TypeMappingSummary';

@type('ImportSummary')
export default class ImportSummary
{
    // ------------------------- Properties -------------------------

    @reference(undefined, 'TypeMappingSummary', true) @observable.shallow summaryByTypeMappingId: Map<string, TypeMappingSummary>;

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}

registerType(ImportSummary, 'ImportSummary');
registerType(TypeMappingSummary, 'TypeMappingSummary');
