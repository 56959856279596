import { observable } from 'mobx';
import { ComputationType } from './ComputationType';
import { DataObject } from '../../DataObject/Model/DataObject';
import { ComputationContext } from '../ComputationContext';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { ComputationTypeStore } from '../ComputationTypeStore';
import NewComputation from '../../../../@Api/Automation/Function/Computation/Computation';
import AutomationDependencyContext from '../../../../@Api/Automation/AutomationDependencyContext';

export abstract class Computation
{
    // ------------------------- Properties -------------------------

    @observable type: ComputationType;

    // ------------------------ Constructor -------------------------

    constructor(typeId: string)
    {
        this.type = loadModuleDirectly(ComputationTypeStore).getTypeById(typeId);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    compute(context: ComputationContext): DataObject
    {
        return this.type.compute(context, this);
    }

    abstract initialize();

    abstract toNewComputation(context: AutomationDependencyContext): Promise<NewComputation<any, any>>

    // ----------------------- Private logic ------------------------
}
