import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { WidgetProps } from '../Widget';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import styles from './Views.module.scss';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import ExpansionPanel from '../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import { buttonInset } from '../../../../../../@Resource/Theme/Theme';
import ExpansionGroup from '../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import useExpanded from '../../../Dataset/Api/useExpanded';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import ViewsWidget from '../../Model/Widget/Views/ViewsWidget';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import useResults from '../../../Selection/Hooks/useResults';
import useTypes from '../../../Type/Api/useTypes';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import { openView } from '../../../@Util/openView';
import { openAllView } from '../../../@Util/openAllView';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import { createStringComparator } from '../../../../../Generic/List/V2/Comparator/StringComparator';
import getEntityTypeViewsBuilder from '../../../View/Api/getEntityTypeViewsBuilder';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import { createBooleanComparator } from '../../../../../Generic/List/V2/Comparator/BooleanComparator';
import { useDefaultView } from '../../../View/Api/useDefaultView';

export interface ViewsProps extends WidgetProps<ViewsWidget>
{

}

const Views: React.FC<ViewsProps> =
    props =>
    {
        // Show all available views (including all defaults) of entityType for currentUser and environment
        // - if no default view is defined in environment or other loaded pack then hardcoded systemdefault is added explicitly

        const types = useTypes();
        const [ isExpanded, toggleExpansion ] = useExpanded(`Widget.${props.widget.id}`);
        const currentUserStore = useContext(CurrentUserContext);

        const views =
            useResults(
                types.View.Type,
                (builder, rootPath) =>
                    getEntityTypeViewsBuilder(
                        props.widget.entityType,
                        currentUserStore,
                        builder,
                        rootPath
                    ),
                [
                    props.widget,
                    types
                ]);

        const dividerGlue = useDividerGlue();
        const openAll =
            useCallback(
                () =>
                    openAllView(props.widget.entityType),
                [
                    props.widget
                ]);

        const [ defaultView, isLoadingDefaultView ] = useDefaultView(props.widget.entityType);

        return <Card
            classes={{
                root: styles.root
            }}
        >
            <ExpansionGroup>
                <ExpansionPanel
                    id={`Widget.${props.widget.id}`}
                    summary={
                        <DragHandle>
                            <Header
                                title={
                                    <LocalizedText
                                        code="Widget.Views.TypeViews"
                                        value="${typeName} weergaves"
                                        typeName={props.widget.entityType?.getName()}
                                    />
                                }
                                large
                                inset
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={buttonInset}
                                    alignment="center"
                                >
                                    <ViewGroupItem>
                                        <MenuButton>
                                            <Menu>
                                                <DeleteItem
                                                    onClick={props.onDelete}
                                                />
                                            </Menu>
                                        </MenuButton>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </Header>
                        </DragHandle>
                    }
                    expansion={
                        <div
                            className={styles.listContainer}
                        >
                            <Card>
                                {
                                    (!views  || isLoadingDefaultView) &&
                                        <CardInset>
                                            <Centered
                                                horizontal
                                            >
                                                <Loader />
                                            </Centered>
                                        </CardInset>
                                }
                                {
                                    views &&
                                        <ViewGroup
                                            orientation="vertical"
                                            spacing={0}
                                            glue={dividerGlue}
                                        >
                                            {
                                                views
                                                    .sort(
                                                        createStringComparator(
                                                            view => view.name
                                                        )
                                                    )
                                                    .sort(
                                                        // set default view as the first
                                                        createBooleanComparator(
                                                            view => !view.getObjectValueByField(types.View.Field.IsDefault)
                                                        )
                                                    )
                                                    .map(
                                                        view =>
                                                            <ViewGroupItem
                                                                key={view.uuid}
                                                            >
                                                                <div
                                                                    className={styles.item}
                                                                    onClick={() => openView(props.widget.entityType, view)}
                                                                >
                                                                    {view.name}
                                                                </div>
                                                            </ViewGroupItem>
                                                    )
                                            }
                                        </ViewGroup>
                                }
                            </Card>
                        </div>
                    }
                    expanded={isExpanded}
                    onExpand={toggleExpansion}
                    onCollapse={toggleExpansion}
                />
            </ExpansionGroup>
        </Card>;
    };

export default observer(Views);
