import TransactionalApi from '../../@Util/TransactionalModelV2/Api/TransactionalApi';
import { injectWithQualifier } from '../../@Util/DependencyInjection/index';
import { getModel, TransactionalModel } from '../../@Util/TransactionalModelV2';
import { DataObjectType, EntityField, ImportanceLevel } from '../Model/Implementation/EntityField';
import { EntityFieldController } from '../Controller/Directory/EntityFieldController';
import { EntityTypeStore } from '../../@Component/Domain/Entity/Type/EntityTypeStore';
import { EntityFieldMutation } from '../Model/Implementation/EntityFieldMutation';
import { action } from 'mobx';
import { EntityFieldDeletionMutation } from '../Model/Implementation/EntityFieldDeletionMutation';
import { EntityCacheService } from '../../@Component/Service/Entity/EntityCacheService';
import { getCommitFromEntityAndRegisterIfValid } from '../Entity/Commit/commitEntity';

export class EntityFieldApi extends TransactionalApi<EntityField, void, EntityFieldMutation, EntityFieldDeletionMutation>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityFieldController') entityFieldController: EntityFieldController;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('EntityCacheService') entityCacheService: EntityCacheService;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    protected initialize(model: EntityField): Promise<any>
    {
        return Promise.resolve();
    }

    protected saveInApi(transactional: TransactionalModel<EntityField>): Promise<EntityFieldMutation>
    {
        const commit =
            transactional.entity
                ?
                    getCommitFromEntityAndRegisterIfValid(transactional.entity)
                :
                    undefined;

        return this.entityFieldController.saveEntityField(
            transactional.id,
            transactional.code,
            undefined,
            undefined,
            DataObjectType[transactional.type] as any,
            ImportanceLevel[transactional.importanceLevel] as any,
            transactional.dataObjectSpecification
                ?
                    transactional.dataObjectSpecification.data
                        ?
                            transactional.dataObjectSpecification.data
                        :
                            {}
                :
                    {},
            transactional.handlers,
            transactional.isRequired ? transactional.isRequired : false,
            transactional.isDefining,
            transactional.isDefault,
            transactional.isCompact,
            transactional.isReadonly,
            transactional.isHiddenFromTimeline,
            transactional.computation,
            transactional.defaultValueComputation,
            transactional.numberGenerator ? transactional.numberGenerator.id : undefined,
            transactional.entityType.id,
            commit?.descriptor);
    }

    @action.bound
    protected postSave(transactional: TransactionalModel<EntityField>,
                       resultFromApi: EntityFieldMutation,
                       result: void): Promise<any>
    {
        return this.entityTypeStore.applyMetadataMutation(
            resultFromApi,
            getModel(transactional));
    }

    protected deleteInApi(transactional: TransactionalModel<EntityField>): Promise<EntityFieldMutation>
    {
        return this.entityFieldController.deleteEntityField(transactional.id);
    }

    protected postDelete(transactional: TransactionalModel<EntityField>,
                         result: EntityFieldDeletionMutation): Promise<any>
    {
        return this.entityTypeStore.applyMetadataMutation(
            result,
            getModel(transactional));
    }
}
