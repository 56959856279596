import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import DeleteIconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { useCurrencies } from '../../../../../Configuration/Page/Settings/OrganizationSettings/CurrencySettings/Api/useCurrencies';
import CurrencyEditor from '../../../../../../../@Future/Component/Generic/Input/NumberEditor/CurrencyEditor/CurrencyEditor';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import useTypes from '../../../../Type/Api/useTypes';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import Tooltip from '../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import { CommitBuilder } from '../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export interface ProductCurrencyPriceProps
{
    price: Entity;
    onDelete: (price: Entity) => void;
}

export const ProductCurrencyPrice: React.FC<ProductCurrencyPriceProps> =
    observer(
        ({
             price,
         }) =>
        {
            const currencies = useCurrencies();
            const types = useTypes();
            const currencyCode = useEntityValue<string>(price, types.Price.Field.Currency);
            const priceValue = useEntityValue<number>(price, types.Price.Field.Price);
            const [ newPriceValue, setNewPriceValue ] = useState(priceValue);

            const currency =
                useMemo(
                    () =>
                        currencies
                            .find(
                                currency =>
                                    currency.code === currencyCode
                            ),
                    [
                        currencies,
                        currencyCode
                    ]);

            const onChange =
                useCallback(
                    (value?: number) =>
                        setNewPriceValue(value),
                    [
                        setNewPriceValue,
                    ]
                );
            const onBlur =
                useCallback(
                    () =>
                    {
                        if (newPriceValue !== priceValue)
                        {
                            new CommitBuilder()
                                .setObjectValueInEntity(
                                    price,
                                    types.Price.Field.Price,
                                    newPriceValue
                                )
                                .commit()
                                .finally()
                        }
                    },
                    [
                        types,
                        price,
                        newPriceValue,
                    ]
                );
            const onDelete =
                useCallback(
                    () =>
                        new CommitBuilder()
                            .deleteEntity(
                                price
                            )
                            .commit(),
                    [
                        price,
                    ]
                );

            return <ViewGroup
                    orientation="horizontal"
                    spacing={0}
            >
                <ViewGroupItem
                    ratio={1}
                >
                    <Input
                        label={
                            <Tooltip
                                title={localizeText(
                                    'Currency.ExchangeRate',
                                    'Wisselkoers: ${exchangeRate}',
                                    {
                                        exchangeRate: currency?.rate?.toString()
                                    })}
                            >
                                {currency?.name}
                            </Tooltip>
                        }
                        labelPosition="left"
                    >
                        <CurrencyEditor
                            value={priceValue}
                            onChange={onChange}
                            onBlur={onBlur}
                            currencyCode={currencyCode}
                            disableUnderline
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <DeleteIconButton
                        onClick={onDelete}
                    />
                </ViewGroupItem>
            </ViewGroup>;
        });