import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FileUploaderStore } from '../../../../../../../../@Component/Generic/FileUploader/FileUploaderStore';
import { FileUploader } from '../../../../../../../../@Component/Generic/FileUploader/FileUploader';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { FileValue } from '../../../../../../../../@Component/Domain/DataObject/Type/File/FileValue';
import useTypes from '../../../../../../../../@Component/Domain/Entity/Type/Api/useTypes';
import { EntitySelectionBuilder } from '../../../../../../../../@Component/Domain/Entity/Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../../../../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import styles from './ImageUploader.module.scss';
import { observer } from 'mobx-react-lite';
import { CommitBuilder } from '../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { useFetchedApiFile } from '../../../../../../../../@Service/ApiClient/Hooks/useFetchedApiFile';

export interface ImageUploaderProps
{
    width?: number;
    height?: number;
    widthPercentage?: boolean;
    heightPercentage?: boolean;
    resourceId: string;
    onResourceUploaded: (resource: Entity) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> =
    props =>
    {
        const types = useTypes();
        const [ resourceEntity, setResourceEntity ] = useState<Entity>();
        const { onResourceUploaded } = props;
        const uploadFiles =
            useCallback(
                files =>
                {
                    if (files && files.length > 0)
                    {
                        const file = files[0];

                        return new CommitBuilder()
                            .createEntity(
                                types.Resource.Type,
                                builder =>
                                    builder
                                        .setObjectValue(
                                            types.Resource.Field.File,
                                            FileValue.fromFile(file)
                                        )
                                        .setObjectValue(
                                            types.Resource.Field.Name,
                                            file.name
                                        ),
                                'resource'
                            )
                            .commit()
                            .then(
                                result =>
                                    result.withEntity(
                                        'resource',
                                        resource =>
                                        {
                                            setResourceEntity(resource);
                                            onResourceUploaded(resource);
                                        }
                                    )
                            );
                    }
                },
                [
                    setResourceEntity,
                    types,
                    onResourceUploaded
                ]);
        const uploaderStore =
            useMemo(
                () =>
                    new FileUploaderStore(uploadFiles),
                [
                    uploadFiles
                ]);
        const previewUrl =
            useMemo(
                () =>
                    resourceEntity
                    ?
                        resourceEntity.getDataObjectValueByField(types.Resource.Field.File)
                            .context.getFileUrl(
                                resourceEntity.getObjectValueByField(types.Resource.Field.File))
                    :
                        undefined,
                [
                    resourceEntity,
                    types
                ]);
        const [ initializedPreviewUrl ] = useFetchedApiFile(previewUrl);
        const previewWidth =
            useMemo(
                () =>
                    props.width > 0 ? `${props.width}${props.widthPercentage ? '%' : 'px'}` : undefined,
                [
                    props.width,
                    props.widthPercentage
                ]);
        const previewHeight =
            useMemo(
                () =>
                    props.height > 0 ? `${props.height}${props.heightPercentage ? '%' : 'px'}` : undefined,
                [
                    props.height,
                    props.heightPercentage
                ]);

        useEffect(
            () =>
            {
                if (!resourceEntity || !resourceEntity.id)
                {
                    const uuid = resourceEntity ? resourceEntity.uuid : props.resourceId;

                    if (uuid)
                    {
                        new EntitySelectionBuilder(types.Resource.Type)
                            .where(cb =>
                                cb.eq(
                                    EntityPath.root(types.Resource.Type).field(types.Entity.Field.Uuid),
                                    undefined,
                                    uuid))
                            .limit(1)
                            .select()
                            .then(
                                result =>
                                {
                                    setResourceEntity(
                                        result.length > 0 ? result[0].entity : undefined)
                                });
                    }
                }
            },
            [
                resourceEntity,
                props.resourceId,
                types,
                setResourceEntity
            ]);

        return <div
            className={styles.root}
        >
            <div>
                {
                    initializedPreviewUrl &&
                        <img
                            alt=""
                            src={initializedPreviewUrl}
                            style={{
                                width: previewWidth,
                                height: previewHeight,
                            }}
                        />
                }
            </div>
            {
                !previewUrl &&
                    <div
                        className={styles.fileUploader}
                    >
                        <FileUploader
                            store={uploaderStore}
                        />
                    </div>
            }
        </div>;
    };

export default observer(ImageUploader);
