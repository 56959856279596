import { ComputationType } from '../ComputationType';
import { CompositeComputationEditorStore } from './CompositeComputationEditorStore';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { CompositeComputationEditor } from './CompositeComputationEditor';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { CompositeComputationSpecification } from './CompositeComputationSpecification';
import { ComputationContext } from '../../ComputationContext';
import { ComputationTypeStore } from '../../ComputationTypeStore';
import { ComputationEditorStore } from '../../ComputationEditorStore';
import { DataObjectSpecification } from '../../../DataObject/Model/DataObjectSpecification';
import { CompositeComputationOperationStore } from './Operation/CompositeComputationOperationStore';
import { MathematicalOperator } from '../../../DataObject/Model/MathematicalOperator';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';
import { EntityContext } from '../../../Entity/@Model/EntityContext';
import { CompositeComputation } from './CompositeComputation';
import { CompositeComputationOperation } from './Operation/CompositeComputationOperation';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';

export class CompositeComputationType extends ComputationType<CompositeComputationEditorStore, CompositeComputationSpecification, CompositeComputation>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return 'Composite';
    }

    name(): string
    {
        return this.localizationStore.translate('Computation.Calculation'); // Calculation
    }

    allow(context: ComputationContext): boolean
    {
        return true;
    }

    isTerminal(): boolean
    {
        return false;
    }

    isResource(): boolean
    {
        return false;
    }

    fromSpecification(specification: CompositeComputationSpecification): CompositeComputation
    {
        return new CompositeComputation(
            specification.operations
                .filter(
                    operation =>
                        operation.computation != null)
                .map(
                    operation =>
                        new CompositeComputationOperation(
                            operation.operator
                                ?
                                    (MathematicalOperator as any)[operation.operator]
                                :
                                    undefined,
                            this.computationTypeStore.fromSpecification(operation.computation))));
    }

    toSpecification(computation: CompositeComputation): CompositeComputationSpecification
    {
        return {
            type: this.id(),
            operations:
                computation.operations.map(
                    operation =>
                        ({
                            operator:
                                operation.operator
                                    ?
                                        MathematicalOperator[operation.operator]
                                    :
                                        undefined,
                            computation:
                                this.computationTypeStore.toSpecification(operation.computation)
                        }))
        };
    }

    compute(context: ComputationContext,
            computation: CompositeComputation): DataObject
    {
        let result: DataObject = null;

        // console.groupCollapsed('composite computation', specification);

        computation.operations.forEach(
            (operation, idx) =>
            {
                let operationResult = operation.computation.compute(context);

                if (idx === 0)
                {
                    result = operationResult;

                }
                else
                {
                    result =
                        DataObject.compute(
                            result,
                            operationResult,
                            operation.operator);

                }
            });

        // console.groupEnd();

        return result;
    }

    description(context: ComputationContext,
                specification: CompositeComputationSpecification): string
    {
        return this.name();
    }

    editorStore(context: ComputationContext,
                specification: CompositeComputationSpecification): CompositeComputationEditorStore
    {
        return new CompositeComputationEditorStore(
            this as any,
            context,
            specification,
            (specification.operations || []).map(
                operation => (
                    new CompositeComputationOperationStore(
                        operation.operator ? (MathematicalOperator as any)[operation.operator || 'And'] : undefined,
                        ComputationEditorStore.construct(
                            context,
                            operation.computation,
                            this.computationTypeStore),
                        null))));
    }

    editorView(): React.ComponentClass<BaseComponentProps<CompositeComputationEditorStore>>
    {
        return CompositeComputationEditor;
    }

    editorSpecification(store: CompositeComputationEditorStore): CompositeComputationSpecification
    {
        return {
            type: this.id(),
            operations: store.operationStores.map(
                operationStore => ({
                    operator:
                        operationStore.operator == null
                            ?
                                undefined
                            :
                                MathematicalOperator[operationStore.operator],
                    computation: operationStore.computationStore
                        .type
                        .editorSpecification(operationStore.computationStore.editorStore)
                }))
        };
    }

    editorResultSpecification(store: CompositeComputationEditorStore): DataObjectSpecification
    {
        if (store.operationStores.length > 0)
        {
            return store.operationStores[store.operationStores.length - 1]
                .computationStore
                .type
                .editorResultSpecification(store.operationStores[store.operationStores.length - 1].computationStore);
        }
        else
        {
            return null;
        }
    }

    entityFieldPaths(specification: CompositeComputationSpecification,
                     context: EntityContext,
                     parameter?: string): EntityFieldPath[]
    {
        let paths: EntityFieldPath[] = [];

        if (specification.operations)
        {
            specification.operations.forEach(
                operation =>
                {
                    let computationType = this.computationTypeStore.getTypeById(operation.computation.type);

                    if (computationType)
                    {
                        paths.push(
                            ...computationType.entityFieldPaths(
                                operation.computation,
                                context,
                                parameter));
                    }
                });
        }

        return paths;
    }

    // ----------------------- Private logic ------------------------
}
