import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import Viewer from './Viewer/Viewer';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import styles from './Note.module.scss';
import { EntityEvent } from '../../../../../../@Api/Model/Implementation/EntityEvent';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import Editor from './Editor/Editor';
import { default as EntityAvatar } from '../../../Avatar/Avatar';
import Avatar from '../../../../../../@Future/Component/Generic/Avatar/Avatar';
import Inset from '../Inset/Inset';
import HideCreationDateContext from '../../Context/HideCreationDateContext';
import DisplayTimeContext from '../../Context/DisplayTimeContext';
import DisplayDateContext from '../../Context/DisplayDateContext';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import Click from '../Click/Click';
import OnOpenContext from '../../Context/OnOpenContext';
import useTypes from '../../../Type/Api/useTypes';
import Caption from '../../Caption/Caption';
import Link from '../../Caption/Link/Link';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import useRelatedEntity from '../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface NoteProps
{
    entity: Entity;
    white?: boolean;
    dateAppendix?: React.ReactNode;
    hideCard?: boolean;
    hideAvatar?: boolean;
    hideCreator?: boolean;
    hideCreationDate?: boolean;
    creationMutation?: EntityEvent;
}

const Note: React.FC<NoteProps> =
    props =>
    {
        const types = useTypes();
        const hideCreationDate = useContext(HideCreationDateContext) || props.hideCreationDate;
        const displayDate = useContext(DisplayDateContext);
        const displayTime = useContext(DisplayTimeContext);

        const currentUserStore = useContext(CurrentUserContext);

        const [ showEditor, setShowEditor ] = useState(false);

        const isEditorButtonVisible =
            useMemo(
                () => !props.entity.isNew(),
                [
                    props.entity
                ]);

        const editButtonCallback =
            useCallback(
                () => setShowEditor(true),
                [
                    setShowEditor
                ]);

        const hideEditor =
            useCallback(
                () => setShowEditor(false),
                [
                    setShowEditor
                ]);

        const creator =
            useRelatedEntity(
                props.entity,
                types.Relationship.Person.Contact.Employee.RelationshipDefinition.CreatedEntities,
                true);

        const entityAvatar =
            useMemo(
                () =>
                {
                    if (props.hideAvatar)
                    {
                        return undefined;
                    }
                    else
                    {
                        if (creator)
                        {
                            return <EntityAvatar
                                entity={creator}
                                editable={false}
                                size={25}
                            />;
                        }
                        else
                        {
                            return <Avatar
                                icon="person"
                                size={25}
                            />;
                        }
                    }
                },
                [
                    creator,
                    props.hideAvatar
                ]);

        const onOpen = useContext(OnOpenContext);
        const parentEntity =
            useComputed(
                () =>
                    props.entity.getRelatedEntityByDefinition(
                        true,
                        types.Entity.RelationshipDefinition.Notes),
                [
                    props.entity,
                    types
                ]);

        const dividerGlue = useDividerGlue();

        const note =
            <div
                className={styles.root}
            >
                <Inset>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                        className={styles.viewGroup}
                    >
                        {
                            entityAvatar &&
                                <ViewGroupItem
                                    className={styles.avatar}
                                >
                                    {entityAvatar}
                                </ViewGroupItem>
                        }
                        <ViewGroupItem
                            ratio={1}
                        >
                            <ViewGroup
                                orientation="vertical"
                                spacing={4}
                                glue={dividerGlue}
                            >
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={0}
                                        className={styles.relativePosition}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            {
                                                showEditor &&
                                                    <Editor
                                                        white={props.white}
                                                        entity={props.entity}
                                                        hideCard={props.hideCard}
                                                        onClose={hideEditor}
                                                    />
                                            }
                                            {
                                                !showEditor &&
                                                    <Viewer
                                                        entity={props.entity}
                                                        creator={creator}
                                                        white={props.white}
                                                        dateAppendix={props.dateAppendix}
                                                        displayTime={displayTime}
                                                        displayDate={displayDate}
                                                        hideCard={props.hideCard}
                                                        hideCreationDate={hideCreationDate}
                                                        hideCreator={props.hideCreator}
                                                    />
                                            }
                                            {
                                                creator !== undefined && equalsEntity(creator, currentUserStore.employeeEntity) && !showEditor &&
                                                    <div
                                                        className={classNames(
                                                            styles.btnEdit,
                                                            props.white && styles.white,
                                                            props.hideCard && styles.hideCard
                                                        )}
                                                    >
                                                        {
                                                            isEditorButtonVisible &&
                                                                <IconButton
                                                                    icon="edit"
                                                                    tooltip={
                                                                        <LocalizedText
                                                                            code="Generic.Edit"
                                                                            value="Wijzigen"
                                                                        />
                                                                    }
                                                                    onClick={editButtonCallback}
                                                                />
                                                        }
                                                    </div>
                                            }
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>
                                {
                                    onOpen && parentEntity &&
                                        <ViewGroupItem>
                                            <Caption
                                                entity={props.entity}
                                            >
                                                {parentEntity.entityType.getName()} <Link entity={parentEntity} />&nbsp;
                                                <Caption
                                                    entity={parentEntity}
                                                    classes={{
                                                        root: styles.caption
                                                    }}
                                                />
                                            </Caption>
                                        </ViewGroupItem>
                                }
                            </ViewGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </Inset>
            </div>;

        if (onOpen)
        {
            return <Click
                open
            >
                {note}
            </Click>;
        }
        else
        {
            return note;
        }
    };

export default observer(Note);
