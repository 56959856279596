import { observable } from 'mobx';
import ParameterAssignment from '../../Automation/Parameter/ParameterAssignment';
import PortalDataSourceSignature from './PortalDataSourceSignature';
import PortalDataSourceQuery from './PortalDataSourceQuery';

export default class PortalDataSourceIdsQuery extends PortalDataSourceQuery
{
    // ------------------------- Properties -------------------------

    @observable.shallow ids: string[];

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                dataSourceSignature: PortalDataSourceSignature,
                parameterAssignment: ParameterAssignment,
                ids: string[])
    {
        super(id, dataSourceSignature, parameterAssignment, undefined);

        this.ids = ids;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Ids',
            ids: this.ids
        };
    }

    // ----------------------- Private logic ------------------------
}
