import * as React from 'react';
import ErrorDialog from './ErrorDialog';
import captureException from '../../@Util/Exception/captureException';

export interface ErrorBoundaryProps
{
    centered?: boolean;
    renderError?: (error?: Error, errorInfo?: any, retry?: () => void) => React.ReactNode;
}

interface ErrorBoundaryState
{
    error?: Error;
    errorInfo?: any;
}

// Taken from: https://reactjs.org/docs/error-boundaries.html
export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState>
{
    constructor(props: ErrorBoundaryProps)
    {
        super(props);

        this.state = {};
    }

    static getDerivedStateFromError(error)
    {
        // Update state so the next render will show the fallback UI.
        return { error: error };
    }

    componentDidCatch(error, errorInfo)
    {
        captureException(error);

        this.setState({
            error: error,
            errorInfo: errorInfo
        });

        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
    }

    render()
    {
        if (this.state.error)
        {
            if (this.props.renderError)
            {
                return this.props.renderError(this.state.error, this.state.errorInfo, () => this.setState({ error: undefined, errorInfo: undefined }));
            }
            else
            {
                return <ErrorDialog
                    error={this.state.error}
                    errorInfo={this.state.errorInfo}
                    onClose={() => this.setState({ error: undefined, errorInfo: undefined })}
                    centered={this.props.centered}
                />;
            }
        }

        return this.props.children;
    }
}
