import { BaseStore } from '../../../../../../@Framework/Store/BaseStore';
import { action, observable } from 'mobx';
import { MathematicalOperator } from '../../../../DataObject/Model/MathematicalOperator';
import { ComputationEditorStore } from '../../../ComputationEditorStore';

export class CompositeComputationOperationStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable operator: MathematicalOperator;
    @observable computationStore: ComputationEditorStore;
    @observable isOperatorMenuOpen: boolean;
    @observable onDelete: (store: CompositeComputationOperationStore) => void;

    // ------------------------ Constructor -------------------------

    constructor(operator: MathematicalOperator,
                computationStore: ComputationEditorStore,
                onDelete: (store: CompositeComputationOperationStore) => void)
    {
        super();

        this.operator = operator;
        this.computationStore = computationStore;
        this.onDelete = onDelete;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    delete()
    {
        this.onDelete(this);
    }

    @action.bound
    openOperatorMenu()
    {
        this.isOperatorMenuOpen = true;
    }

    @action.bound
    closeOperatorMenu()
    {
        this.isOperatorMenuOpen = false;
    }

    @action.bound
    setOperator(operator: MathematicalOperator)
    {
        this.operator = operator;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
