import React, { useCallback, useState } from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import { EntityPath } from '../../../../../../Path/@Model/EntityPath';
import ProductLines from '../../ProductLines/ProductLines';
import Card from '../../../../../../../../../@Future/Component/Generic/Card/Card';
import HoverCardBottom from '../../../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import { createTransactionalModel } from '../../../../../../../../../@Util/TransactionalModelV2/index';
import copyProductLine from '../../../../../../../../../@Api/Entity/Bespoke/Activity/copyProductLine';
import ExpansionPanel from '../../../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { openEntity } from '../../../../../../@Util/openEntity';
import IconButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import useEntityValue from '../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import useTypes from '../../../../../../Type/Api/useTypes';
import ExpansionGroup from '../../../../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';

export interface ChildActivityProductEditorProps
{
    parentActivity: Entity;
    activity: Entity;
    path: EntityPath;
    onLoad: (isLoading: boolean) => void;
}

const ChildActivityProductEditor: React.FC<ChildActivityProductEditorProps> =
    props =>
    {
        const types = useTypes();

        const [ selectedProductLines, setSelectedProductLines ] = useState<Set<Entity>>(() => new Set());
        const onSelectProductLine =
            useCallback(
                (entity: Entity, selected: boolean) =>
                {
                    const newLines = new Set(selectedProductLines);

                    if (selected)
                    {
                        newLines.add(entity);
                    }
                    else
                    {

                        newLines.delete(entity);
                    }

                    setSelectedProductLines(newLines);
                },
                [
                    selectedProductLines,
                    setSelectedProductLines
                ]);

        const addLinesToParentActivity =
            useCallback(
                () =>
                {
                    props.onLoad(true);

                    const parentActivity = createTransactionalModel(props.parentActivity);

                    selectedProductLines.forEach(
                        line =>
                        {
                            const lineCopy =
                                copyProductLine(
                                    line,
                                    parentActivity);

                            lineCopy.setValueByField(
                                types.ProductLine.Field.IsBilled,
                                false);
                        });

                    return parentActivity.checkAndDoCommit()
                        .then(
                            () =>
                                Promise.all(
                                    Array.from(selectedProductLines)
                                        .map(
                                            line =>
                                            {
                                                const transactionalLine = createTransactionalModel(line);

                                                transactionalLine.setValueByField(
                                                    types.ProductLine.Field.IsProcessableInParent,
                                                    true);

                                                return transactionalLine.checkAndDoCommit();
                                            })))
                        .then(
                            () =>
                                setSelectedProductLines(new Set()))
                        .then(
                            () =>
                                props.onLoad(false));
                },
                [
                    props.onLoad,
                    props.parentActivity,
                    selectedProductLines,
                    setSelectedProductLines
                ]);

        const openActivity =
            useCallback(
                () =>
                    openEntity(props.activity),
                [
                    props.activity
                ]);

        const currency =
            useEntityValue<string>(
                props.activity,
                types.Activity.Field.Currency);

        const numberOfProductLines =
            useEntityValue(
                props.activity,
                types.Activity.Field.NumberOfProductLines);

        const isStrikethrough =
            useCallback(
                (line: Entity) =>
                    line.getObjectValueByField(types.ProductLine.Field.IsProcessableInParent),
                [
                    types
                ]);

        return <Card>
            <ExpansionGroup>
                <ExpansionPanel
                    id={props.activity.uuid}
                    summary={
                        <Header
                            large
                            title={
                                <div>
                                    {props.activity.entityType.getName()}: {props.activity.name}
                                </div>
                            }
                            inset
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                                alignment="center"
                            >
                                <ViewGroupItem>
                                    {props.activity.getDataObjectValueByField(
                                        currency
                                            ? types.Activity.Field.AmountInCurrency
                                            : types.Activity.Field.Amount
                                    ).toCurrencyString(currency)}
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    {numberOfProductLines} {types.ProductLine.Type.getName(numberOfProductLines !== 1).toLowerCase()}
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <IconButton
                                        icon="open_in_new"
                                        onClick={openActivity}
                                        tooltip={`${props.activity.entityType.getName()} openen`}
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </Header>
                    }
                    expansion={
                        <>
                            <ProductLines
                                entity={props.activity}
                                selectedProductLines={selectedProductLines}
                                onSelectProductLine={onSelectProductLine}
                                isStrikethrough={isStrikethrough}
                            />
                            <HoverCardBottom
                                onClick={addLinesToParentActivity}
                                disabled={selectedProductLines.size === 0}
                            >
                                {selectedProductLines.size} regel{selectedProductLines.size === 1 ? '' : 's'} toevoegen aan {props.parentActivity.entityType.getName().toLowerCase()}
                            </HoverCardBottom>
                        </>
                    }
                />
            </ExpansionGroup>
        </Card>;
    };

export default observer(ChildActivityProductEditor);
