import * as React from 'react';
import { DataObjectBespokeEditor } from '../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { observer } from 'mobx-react';
import { ComplexEditorStore } from './ComplexEditorStore';
import Button from '../../../../Generic/Button/Button';
import { Dialog } from '../../../../Generic/Dialog/Dialog';

@observer
export class ComplexEditor extends DataObjectBespokeEditor<ComplexEditorStore>
{
    renderComponent(store: DataObjectEditorStore<ComplexEditorStore>)
    {
        return <div>
            {
                store.bespokeStore.hasValue &&
                    <div>
                        <Button store={store.bespokeStore.btnEditStore}/>
                        <Button store={store.bespokeStore.btnClearStore} />
                    </div>
            }
            {
                !store.bespokeStore.hasValue &&
                    <div>
                        <Button store={store.bespokeStore.btnCreateStore} />
                    </div>
            }
            <Dialog store={store.bespokeStore.dialogStore} />
        </div>;
    }
}
