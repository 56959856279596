import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../Model/DataObjectType';
import { Comparator } from '../../Model/Comparator';
import { Aggregate } from '../../Model/Aggregate';
import { MathematicalOperator } from '../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../Model/DataObjectFunction';
import { DataObject } from '../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../@Util/DependencyInjection/index';
import { DateView } from './DateView';
import { DateEditor } from './DateEditor';
import { DateSpecification } from './DateSpecification';
import { DataDescriptor } from '../../Model/DataDescriptor';
import { DateRangeValue } from './DateRange/DateRangeValue';
import { DateIntervalValue } from './DateInterval/DateIntervalValue';
import moment, { Moment } from 'moment';
import { ComparatorDescriptor } from '../../Model/ComparatorDescriptor';
import { DataObjectRepresentation } from '../../Model/DataObjectRepresentation';
import { DataObjectOperatorOverload } from '../../Model/DataObjectOperatorOverload';
import { DataObjectContext } from '../../Model/DataObjectContext';
import { DataObjectComparatorOverload } from '../../Model/DataObjectComparatorOverload';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { DataObjectBespokeEditorStore } from '../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { DateEditorStore } from './DateEditorStore';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { Localizer } from '../../../../../@Service/Localization/Localizer';
import { DataObjectSpecification } from '../../Model/DataObjectSpecification';
import localizeText from '../../../../../@Api/Localization/localizeText';

export type DateValue = Moment | Date | number;

export class DateType implements DataObjectType<DateValue>
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('Localizer') localizer: Localizer;

    id(): string
    {
        return 'Date';
    }

    name()
    {
        return localizeText('DataObject.Type.Date', 'Datum');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [
            {
                comparator: Comparator.In,
                compareTypeId: 'DateRange'
            },
            {
                comparator: Comparator.Equals
            },
            {
                comparator: Comparator.NotEquals
            },
            {
                comparator: Comparator.GreaterThan
            },
            {
                comparator: Comparator.GreaterThanOrEqual
            },
            {
                comparator: Comparator.LessThan
            },
            {
                comparator: Comparator.LessThanOrEqual
            }
        ];
    }

    supportedAggregates(): Aggregate[]
    {
        return [ Aggregate.Average, Aggregate.Max, Aggregate.Min ];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [ MathematicalOperator.Add, MathematicalOperator.Subtract ];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return DateView;
    }

    editor(): DataObjectEditorType
    {
        return DateEditor;
    }

    editorStore(editorStore: DataObjectEditorStore): DataObjectBespokeEditorStore
    {
        return new DateEditorStore(editorStore);
    }

    specification(): DataObjectSpecificationType
    {
        return DateSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return null;
    }

    requiresInitialization(value: any): boolean
    {
        return false;
    }

    getDataFromValue(value: DateValue, specification: DataObjectSpecification): DataDescriptor
    {
        const data = new DataDescriptor();
        data.date1 = this.getDateFromValue(value);

        return data;
    }

    getDateFromValue(value: DateValue)
    {
        if (value === null || value === undefined)
        {
            return undefined;
        }
        else if (value instanceof Date)
        {
            return value;
        }
        else if (value instanceof moment)
        {
            return (value as Moment).toDate();
        }
        else if (typeof value === 'number')
        {
            return new Date(value as any);
        }
        else
        {
            return undefined;
        }
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): any
    {
        if (data.date1)
        {
            return data.date1;
        }
        else if (data.text)
        {
            // Note that `new Date(data.text)` will return midnight at UTC, while `moment(...)` will return midnight at browser timezone!
            return moment(data.text, 'YYYY-MM-DD').toDate();
        }
        else
        {
            return undefined;
        }
    }

    valueId(value: DateValue): string
    {
        const date = this.getDateFromValue(value);

        if (date)
        {
            return date.toISOString();
        }
        else
        {
            return undefined;
        }
    }

    getString(value: DateValue,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        const date = this.getDateFromValue(value);

        if (representation.data.isCompact)
        {
            return this.localizer.formatNumeralDate(date);
        }
        else
        {
            return this.localizer.formatDate(this.getDateFromValue(value));
        }
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        let date1 = lhs?.getValue();
        let date2 = rhs?.getValue();

        switch (comparator)
        {
            case Comparator.Equals:
                return date1 === date2;

            case Comparator.NotEquals:
                return date1 !== date2;

            case Comparator.GreaterThan:
                return date1 > date2;

            case Comparator.GreaterThanOrEqual:
                return date1 >= date2;

            case Comparator.LessThan:
                return date1 < date2;

            case Comparator.LessThanOrEqual:
                return date1 <= date2;
        }

        return false;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [];
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    intervalTypeId(): string
    {
        return 'DateInterval';
    }

    constructInterval(min: DataObject,
                      max: DataObject,
                      steps: number,
                      intervalToSet: DataObject): void
    {
        let minDate = min.getValue();
        let maxDate = max.getValue();

        if (minDate && maxDate)
        {
            let minMomentDate = moment(minDate);
            let maxMomentDate = moment(maxDate);

            if (steps >= maxMomentDate.diff(minMomentDate, 'days'))
            {
                intervalToSet.setValue({ type: 'Daily' });
            }
            else if (steps >= maxMomentDate.diff(minMomentDate, 'weeks'))
            {
                intervalToSet.setValue({ type: 'Weekly' });
            }
            else // if (steps >= maxMomentDate.diff(minMomentDate, 'months'))
            {
                intervalToSet.setValue({ type: 'Monthly' });
            }
            // else
            // {
            //     intervalToSet.setValue({ type: 'Yearly' });
            // }
        }
    }

    rangeTypeId(): string
    {
        return 'DateRange';
    }

    constructRange(from: DataObject,
                   interval: DataObject,
                   rangeToSet: DataObject): void
    {
        let toDate = moment(from.getValue().getTime());
        let intervalValue: DateIntervalValue = interval.getValue();

        if (intervalValue.type === 'Daily')
        {
            toDate = toDate.add(1, 'day');
        }
        else if (intervalValue.type === 'Weekly')
        {
            toDate = toDate.add(7, 'days');
        }
        else if (intervalValue.type === 'FourWeekly')
        {
            toDate = toDate.add(28, 'days');
        }
        else if (intervalValue.type === 'Monthly')
        {
            toDate = toDate.set('month', toDate.get('month') + 1);
        }
        else if (intervalValue.type === 'Quarterly')
        {
            toDate = toDate.set('month', toDate.get('month') + 3);
        }
        else if (intervalValue.type === 'HalfYearly')
        {
            toDate = toDate.set('month', toDate.get('month') + 6);
        }
        else if (intervalValue.type === 'Yearly')
        {
            toDate = toDate.set('year', toDate.get('year') + 1);
        }

        let rangeValue: DateRangeValue =
        {
            type: 'DateRange',
            from: from.getValue(),
            to: toDate.toDate()
        };

        rangeToSet.setValue(rangeValue);
    }

    isValid(dataObject: DataObject): boolean
    {
        if (dataObject.specification.isRequired)
        {
            return dataObject.getValue() !== undefined && dataObject.getValue() !== null;
        }

        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return false;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }
}
