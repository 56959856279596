import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { EntityPath } from '../../Path/@Model/EntityPath';

export class BespokeDatasetCategory extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore, code: string = 'DatasetCategory')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isConfigurationType(): boolean
    {
        return true;
    }

    orderByField(entity?: Entity,
                 pathFromRelatedEntity?: RelatedEntityPath): EntityFieldPath
    {
        return EntityPath.fromEntityType(this.type).field(this.entityTypeStore.sortIndexField);
    }

    // ----------------------- Private logic ------------------------
}
