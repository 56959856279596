import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';

export default function useCharacters(entity: Entity,
                                      allowCharactersOrIcon: boolean)
{
    return useComputed(
        () =>
            allowCharactersOrIcon &&
                entity.entityType.bespoke.getCharactersInAvatar(entity),
        [
            allowCharactersOrIcon,
            entity
        ]);
}