import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { EntityExpansionBuilder } from '../../../../Selection/Builder/EntityExpansionBuilder';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import HoverCardMiddle from '../../../../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { useCommittableEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/useCommittableEntity';
import { constructEntityOfType } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { ProductLinesTable } from '../../../../Viewer/Content/Activity/Product/ProductLinesTable/ProductLinesTable';

export interface TimeRegistrationProductLinesEditorProps
{
    entity: Entity;
    noScroll?: boolean;
    commitContext?: CommitContext;
}

const TimeRegistrationProductLinesEditor: React.FC<TimeRegistrationProductLinesEditorProps> =
    props =>
    {
        const commitContext = useNewCommitContext(props.commitContext);
        const entity = useCommittableEntity(props.entity, commitContext);
        const [ isLoading, setLoading ] = useState(true);
        const types = useTypes();
        const pathToLines =
            useMemo(
                () =>
                    EntityPath.fromEntity(entity)
                        .joinTo(
                            types.TimeRegistration.RelationshipDefinition.ProductLines,
                            false),
                [
                    entity,
                    types
                ]);

        const allLines =
            useComputed(
                () =>
                    pathToLines.traverseEntity(
                        entity,
                        commitContext
                    ),
                [
                    pathToLines,
                    entity,
                    commitContext,
                ]);

        const isDisabled =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.SalesOpportunity.Type) &&
                    props.entity.getRelatedEntitiesByDefinition(
                        false,
                        types.Activity.SalesOpportunity.RelationshipDefinition.Phase,
                        commitContext)
                        .some(
                            state =>
                                state.getObjectValueByField(types.Datastore.Field.Code, commitContext) === 'Won'),
                [
                    props.entity,
                    types,
                    commitContext,
                ]);

        const onAdd =
            useCallback(
                () =>
                {
                    if (!isDisabled)
                    {
                        const newLine =
                            constructEntityOfType(
                                pathToLines.entityType,
                                commitContext
                            );
                        updateRelationship(
                            newLine,
                            true,
                            types.Activity.RelationshipDefinition.ProductLines,
                            entity.getRelatedEntityByDefinition(
                                true,
                                types.Activity.RelationshipDefinition.TimeRegistrations,
                                commitContext
                            ),
                            commitContext
                        );
                        updateRelationship(
                            newLine,
                            true,
                            pathToLines.lastJoinNode.relationshipDefinition,
                            entity,
                            commitContext
                        );
                        setValueByFieldInEntity(
                            newLine,
                            types.Entity.Field.SortIndex,
                            allLines.length,
                            commitContext
                        );
                        setValueByFieldInEntity(
                            newLine,
                            types.ProductLine.Field.Quantity,
                            1,
                            commitContext
                        );
                    }
                },
                [
                    types,
                    entity,
                    pathToLines,
                    allLines,
                    isDisabled,
                    commitContext,
                ]);

        useEffect(
            () =>
            {
                new EntityExpansionBuilder(
                    entity.entityType,
                    [
                        entity
                    ],
                    [
                        EntityPath.fromEntity(entity)
                            .joinTo(
                                types.Activity.RelationshipDefinition.TimeRegistrations,
                                true),
                        pathToLines,
                        pathToLines
                            .joinTo(
                                types.ProductLine.RelationshipDefinition.Product,
                                false),
                        pathToLines
                            .joinTo(
                                types.ProductLine.RelationshipDefinition.VatGroup,
                                false)
                    ]).expand()
                    .then(
                        () =>
                        {
                            setLoading(false);

                            const entities = pathToLines.traverseEntity(entity, commitContext);

                            if (entities.length === 0)
                            {
                                onAdd();
                            }
                        });
            },
            [
                entity,
                pathToLines,
                setLoading,
                onAdd,
                types,
                commitContext,
            ]);

        if (isLoading)
        {
            return <CardInset>
                <Centered
                    horizontal
                >
                    <Loader />
                </Centered>
            </CardInset>;
        }
        else
        {
            return <div>
                <ProductLinesTable
                    entity={entity}
                    lines={allLines}
                    disabled={isDisabled}
                    noScroll={props.noScroll}
                    autoCommit={!props.entity.isNew()}
                    hideTotals={props.entity.isNew()} // totals remain empty when the entity is new
                    commitContext={commitContext}
                />
                {
                    isDisabled &&
                        <CardInset
                            top={false}
                            left={false}
                            right={false}
                        />
                }
                {
                    !isDisabled &&
                        <HoverCardMiddle
                            onClick={onAdd}
                        >
                            <LocalizedText
                                code="TimeRegistrationProductLines.AddProductButton"
                                value="+ Product"
                            />
                        </HoverCardMiddle>
                }
            </div>;
        }
    };

export default observer(TimeRegistrationProductLinesEditor);
