import { ModuleContext, ModuleManager } from '../../../../../../@Util/DependencyInjection/index';

export class PortalEnvironmentContext extends ModuleContext
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    /* eslint-disable-next-line @typescript-eslint/no-useless-constructor */
    constructor(moduleManager: ModuleManager)
    {
        super(moduleManager);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    protected defineModules()
    {

    }

    // ----------------------- Private logic ------------------------
}
