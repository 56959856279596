import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import CampaignPercentageChart from './CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import useCampaignTotalEmailCount from '../Hooks/useCampaignTotalEmailCount';
import useCampaignEmailStateCount from '../Hooks/useCampaignEmailStateCount';
import useTypes from '../../../../../Type/Api/useTypes';

export interface CampaignChartProcessingProps
{
    size: number;
    campaign: Entity;
}

export const CampaignChartProcessing: React.FC<CampaignChartProcessingProps> =
    observer(
        ({
             size,
             campaign,
        }) =>
            {
                const types = useTypes();
                const totalEmailCount = useCampaignTotalEmailCount(campaign);
                const states =
                    useMemo(
                        () =>
                            [
                                types.Activity.Email.State.Sent
                            ],
                        [
                            types
                        ]
                    );
                const queueCount = useCampaignEmailStateCount(campaign,states)

                const allSet =
                    useAllSet(
                        [
                            totalEmailCount,
                            queueCount
                        ]
                    );

                const seletion =
                    useMemo(
                        () =>
                        {
                            return `${queueCount} / ${totalEmailCount}`
                        },
                        [
                            queueCount,
                            totalEmailCount
                        ]
                    )

                return <div>
                    {
                        allSet &&
                        <CampaignPercentageChart
                            value={queueCount / totalEmailCount * 100}
                            size={size}
                            label={localizeText('Campaign.Chart.Processing', 'In behandeling')}
                            selection={seletion}
                        />
                    }
                </div>
            }
    );
