import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import getTypes from '../../Type/Api/getTypes';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { DataObject } from '../../../DataObject/Model/DataObject';

export async function getStateByNameAndCountry(
    name: string,
    country: Entity
): Promise<Entity>
{
    const types = getTypes();
    const selectionBuilder =
        EntitySelectionBuilder.builder(
            types.Datastore.State.Type,
            (builder, rootPath) =>
                builder
                    .where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath
                                    .joinTo(
                                        types.Datastore.Country.RelationshipDefinition.States,
                                        true
                                    ),
                                country
                            )
                    )
                    .where(
                        cb =>
                            cb.eq(
                                rootPath.field(types.Datastore.Field.LocalizedName),
                                null,
                                DataObject.constructFromTypeIdAndValue(
                                    'Text',
                                    name
                                )
                            )
                    )
        );
    const results = await selectionBuilder.select(0, 1);
    selectionBuilder.dispose();

    return results
        .map(
             result =>
                result.entity
        )
        .find(() => true);
}
