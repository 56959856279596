import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Calendar.module.scss';
import localeNL from '@fullcalendar/core/locales/nl';
import localeFR from '@fullcalendar/core/locales/fr';
import localeDE from '@fullcalendar/core/locales/de';
import localeEN from '@fullcalendar/core/locales/en-gb';
import localeES from '@fullcalendar/core/locales/es';
import localeIT from '@fullcalendar/core/locales/it';
import localeSV from '@fullcalendar/core/locales/sv';
import localeFI from '@fullcalendar/core/locales/fi';
import localeNB from '@fullcalendar/core/locales/nb';
import localeDA from '@fullcalendar/core/locales/da';
import { Color } from 'react-color';
import { classNames } from '../../../Util/Class/classNames';
import { observer } from 'mobx-react-lite';
import Loader from '../Loader/Loader';
import AsyncComponent from '../../../../@Util/AsyncComponent/AsyncComponent';
import localizeText from '../../../../@Api/Localization/localizeText';
import { useIsMounted } from '../../../../@Util/Async/useIsMounted';

export type CalendarViewType = 'day' | 'week' | 'month';

export type EventFetcher = (query: EventQuery) => Promise<any>;

export interface CalendarTimeSlot
{
    start: Date,
    end: Date,
    allDay: boolean
}

export interface CalendarEvent
{
    id?: string;
    title: string;
    allDay?: boolean;
    start: Date | string;
    end?: Date | string;
    backgroundColor?: Color;
    borderColor?: Color;
    editable?: boolean;
    occurrenceOriginalStartDate?: Date;
    showPopup?: boolean;
}

export interface CalendarChangeEvent
{
    old: CalendarEvent,
    new: CalendarEvent
}

export interface CalendarNavigationEvent
{
    start: Date,
    end: Date,
    period: string,
}

export interface CalendarProps
{
    type?: CalendarViewType;
    onChangeType?: (type: CalendarViewType) => void;
    events?: CalendarEvent[];
    fetchEvents?: EventFetcher;
    onNavigate?: (event: CalendarNavigationEvent) => void;
    onSelectEvent?: (event: CalendarEvent) => void;
    onSelectTimeslot?: (timeslot: CalendarTimeSlot) => void;
    onChangeEvent?: (changeEvent: CalendarChangeEvent) => Promise<boolean>;
    locale?: string;
    enableTypeSwitcher?: boolean;
    hideHeader?: boolean;
    hideNavigation?: boolean;
    hideDateLabel?: boolean;
    hideViewSwitcher?: boolean;
    hideTodayButton?: boolean;
    hideWeekends?: boolean;
    height?: 'parent' | 'noscroll' | number;
    contentHeight?: 'noscroll' | number;
    allDaySlot?: boolean;
}

export interface EventQuery
{
    start: Date,
    end: Date,
    startStr: string,
    endStr: string,
    timeZone: string;
}

const generateStateSignature = (data: any) =>
{
    return data ? JSON.stringify(data) : '';
};

const internalCalendarLoader =
    () =>
        import('./CalendarWithPopupEvents');

const Calendar: React.FC<CalendarProps> =
    props =>
    {
        const [ ref, setRef ] = useState<any>();
        const isMounted = useIsMounted();

        const { onNavigate, onChangeEvent, onSelectEvent, onSelectTimeslot, fetchEvents } = props;

        const [ stateSignature, setStateSignature ] = useState<string>('');
        const [ changePropagated, setChangePropagated ] = useState<boolean>(false);
        const [ lastEventQuerySignature, setLastEventQuerySignature ] = useState<string>('');
        const [ lastNavigationSignature, setNavigationSignature ] = useState<string>('');
        const [ isLoading, setIsLoading ] = useState<boolean>(false);

        const [ viewType, setViewType ] = useState<CalendarViewType>(props.type);

        const eventApiToEvent = useCallback(
            (e: any) =>
            {
                const event: CalendarEvent = {
                    id: e.id,
                    title: e.title,
                    start: e.start,
                    end: e.end,
                    allDay: e.allDay,
                    backgroundColor: e.backgroundColor,
                    borderColor: e.borderColor,
                    editable: e.editable,
                    occurrenceOriginalStartDate: e.extendedProps?.occurrenceOriginalStartDate,
                    showPopup: e.extendedProps?.showPopup
                };

                return event;
            },
            []);

        const handleChangeEvent = useCallback(
            (e: any) =>
            {
                if (onChangeEvent)
                {
                    setIsLoading(true);

                    onChangeEvent(
                        {
                            old: eventApiToEvent(e.prevEvent ? e.prevEvent : e.oldEvent),
                            new: eventApiToEvent(e.event)
                        })
                        .then(
                            shouldStopLoading =>
                            {
                                if (shouldStopLoading)
                                {
                                    setIsLoading(false);
                                }
                            }
                        )
                        .catch(
                            e =>
                            {
                                setIsLoading(false);

                                throw e;
                            }
                        );
                }
            },
            [
                onChangeEvent,
                setIsLoading,
                eventApiToEvent,
                fetchEvents
            ]);

        const handleSelectEvent = useCallback(
            (e: any) =>
            {
                if (onSelectEvent)
                {
                    onSelectEvent(eventApiToEvent(e.event));
                }
            },
            [
                onSelectEvent,
                eventApiToEvent
            ]);

        const handleSelect = useCallback(
            (info: any) =>
            {
                const slot: CalendarTimeSlot = {
                    start: info.start,
                    end: info.end,
                    allDay: info.allDay
                };

                if (onSelectTimeslot)
                {
                    onSelectTimeslot(slot);
                }
            },
            [
                onSelectTimeslot
            ]);

        const type = useMemo(
            () =>
            {
                switch (viewType)
                {
                    case 'day': return 'timeGridDay';
                    case 'week': return 'timeGridWeek';
                    case 'month': return 'dayGridMonth';
                }
            },
            [
                viewType
            ]);

        useEffect(
            () =>
            {
                // When changing the view type, call the calendar component
                // to change the view. This is necessary because changing the defaultView
                // does not reload the calendar.
                if (ref?.calendar)
                {
                    if (ref.calendar.view.type !== type)
                    {
                        ref.calendar.changeView(type);
                    }
                }
            },
            [
                type,
                ref
            ]);

        useEffect(
            () =>
            {
                setViewType(props.type);
            },
            [
                props.type
            ]);

        useEffect(
            () =>
            {
                const signature = generateStateSignature(props.events);

                if (signature !== stateSignature)
                {
                    setStateSignature(signature);
                    setChangePropagated(false);
                }
            },
            [
                props.events,
                stateSignature,
                setStateSignature,
                setChangePropagated
            ]);

        const locale = useMemo(
            () =>
            {
                switch (props.locale)
                {
                    case 'nl': return localeNL;
                    case 'fr': return localeFR;
                    case 'de': return localeDE;
                    case 'en': return localeEN;
                    case 'es': return localeES;
                    case 'it': return localeIT;
                    case 'sv': return localeSV;
                    case 'fi': return localeFI;
                    case 'nb': return localeNB;
                    case 'da': return localeDA;
                    default: return localeEN;
                }
            },
            [
                props.locale
            ]);

        const handleNavigationEvent =
            useCallback(
                (event) =>
                {
                    if (onNavigate)
                    {
                        const navEvent = {
                            start: event.view.currentStart,
                            end: event.view.currentEnd,
                            period: event.view.title
                        };

                        const signature = generateStateSignature(navEvent);

                        if (signature !== lastNavigationSignature)
                        {
                            setNavigationSignature(signature);

                            if (onNavigate)
                            {
                                onNavigate(navEvent);
                            }
                        }
                    }
                },
                [
                    onNavigate,
                    setNavigationSignature,
                    lastNavigationSignature
                ]);

        // Define dummy event sources:
        // One to provide the (reactive) events from the props
        // The other to call the provided event fetcher with a date boundary
        const eventSources =
            useMemo(
                () =>
                    [
                        {
                            events: (query, resolve) =>
                            {
                                const querySignature = generateStateSignature(query);
                                const queryChanged = querySignature !== lastEventQuerySignature;

                                if (fetchEvents && (!changePropagated || queryChanged))
                                {
                                    setChangePropagated(true);
                                    setLastEventQuerySignature(querySignature);

                                    setIsLoading(true);

                                    fetchEvents(query)
                                        .finally(
                                            () =>
                                            {
                                                resolve([]);

                                                // Release the editing possibilities with a little
                                                // delay to avoid JS errors of dragging while redraws
                                                // are taking place.
                                                setTimeout(
                                                    () =>
                                                    {
                                                        if (isMounted())
                                                        {
                                                            setIsLoading(false);
                                                        }
                                                    },
                                                    500);
                                            });
                                }
                                else
                                {
                                    resolve([]);
                                }
                            }
                        },
                        {
                            events: props.events ? props.events : []
                        }
                    ],
                [
                    props.events,
                    fetchEvents,
                    changePropagated,
                    setChangePropagated,
                    lastEventQuerySignature,
                    setLastEventQuerySignature,
                    isMounted
                ]);

        const headerLeft =
            useMemo(
                ()=>
                {
                    let items = '';

                    if (!props.hideNavigation)
                    {
                        items += 'prev,next';
                    }

                    if (!props.hideDateLabel)
                    {
                        items += (items === '' ? '' : ' ') + 'title';
                    }

                    return items;
                },
                [
                    props.hideNavigation,
                    props.hideDateLabel
                ]);

        const headerRight =
            useMemo(
                ()=>
                {
                    let items = '';

                    if (!props.hideTodayButton)
                    {
                        items += 'today';
                    }

                    if (!props.hideViewSwitcher)
                    {
                        items += (items === '' ? '' : ' ') + 'customDay,customWeek,customMonth'; // 'timeGridDay,timeGridWeek,dayGridMonth';
                    }

                    return items;
                },
                [
                    props.hideTodayButton,
                    props.hideViewSwitcher
                ]);

        const isEditable =
            useMemo(
                () =>
                    !isLoading && !!props.onChangeEvent,
                [
                    props.onChangeEvent,
                    isLoading
                ]);

        const onChangeType =
            useCallback(
                (viewType: CalendarViewType) =>
                {
                    setViewType(viewType);
                    if (props.onChangeType)
                    {
                        props.onChangeType(viewType);
                    }
                },
                [
                    setViewType,
                    props.onChangeType,
                ]);

        return <div
            className={classNames(
                styles.root,
                props.hideHeader && styles.hideHeader,
                isLoading && styles.loading,
                viewType === 'month' && styles.viewMonth,
                viewType === 'week' && styles.viewWeek,
                viewType === 'day' && styles.viewDay)}
        >
            <div
                className={styles.loadingOverlay}
            >
                <Loader />
            </div>

            <AsyncComponent
                _loader={internalCalendarLoader}
                _loadKey="default"
                _onRef={setRef}
                _passOnRefDown
                defaultView={type}
                height={props.height === 'noscroll' ? 'auto' : props.height}
                contentHeight={props.contentHeight === 'noscroll' ? 'auto' : props.contentHeight}
                aspectRatio={props.height === 'noscroll' ? 2 : undefined}
                locale={locale}
                header={{
                    left: headerLeft,
                    right: headerRight
                }}
                eventSources={eventSources as any[]}
                selectable={!!props.onSelectTimeslot}
                editable={isEditable}
                eventStartEditable={isEditable}
                eventResizableFromStart={isEditable}
                eventDurationEditable={isEditable}
                eventDrop={handleChangeEvent}
                customButtons={{
                    customDay: {
                        text: localizeText('Generic.Day', 'Dag'),
                        click: () => onChangeType('day')
                    },
                    customWeek: {
                        text: localizeText('Generic.Week', 'Week'),
                        click: () => onChangeType('week')
                    },
                    customMonth: {
                        text: localizeText('Generic.Month', 'Maand'),
                        click: () => onChangeType('month')
                    }
                }}
                eventResize={handleChangeEvent}
                datesRender={handleNavigationEvent}
                select={handleSelect}
                eventClick={handleSelectEvent}
                businessHours={{
                    daysOfWeek: [ 1, 2, 3, 4, 5 ],
                    startTime: '8:00',
                    endTime: '18:00',
                }}
                timeGridEventMinHeight={16}
                slotEventOverlap={false}
                allDaySlot={props.allDaySlot}
                scrollTime="7:00"
                nowIndicator
                handleWindowResize
                weekNumbers
                weekNumbersWithinDays
                weekends={!props.hideWeekends}
            />
        </div>;
    };

Calendar.defaultProps =
    {
        height: 'parent',
        locale: 'en',
        type: 'month',
        allDaySlot: true
    };

export default observer(Calendar);
