import { reference, type } from '../../../@Util/Serialization/Serialization';
import { LanguageEntryTranslation } from './LanguageEntryTranslation';

@type('Language')
export class Language
{
    // ------------------- Persistent Properties --------------------

    id: number;
    languageCode: string;
    countryCode: string;
    cultureCode: string;
    name: string;
    flagUrl: string;
    isEnabled: boolean;
    @reference(undefined, 'LanguageEntryTranslation') translations: LanguageEntryTranslation[];

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
