import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import LocalizerContext from '../../../../@Service/Localization/LocalizerContext';
import moment from 'moment';
import styles from './DateView.module.scss';
import Tooltip from '../Tooltip/Tooltip';
import useRelativeTime from './api/useTimer';

export interface DateViewProps
{
    date: Date | number;
    displayTime?: boolean;
    displayDate?: boolean;
}

const DateView: React.FC<DateViewProps> =
    props =>
    {
        const localizer = useContext(LocalizerContext);
        const date = moment(props.date);
        const dateTime = `${date.format('dddd')}, ${localizer.formatDate(date)} ${localizer.formatTime(date)}`;
        const time = `${localizer.formatTime(date)}`;

        const relativeTime = useRelativeTime(moment(props.date));
        const displayTime = props.displayTime ? localizer.formatTime(date) : dateTime;

        return <Tooltip
            title={relativeTime}
        >
            <div
                className={styles.date}
            >
                {props.children} {props.displayDate ? displayTime : time}
            </div>
        </Tooltip>;
    };

export default observer(DateView);
