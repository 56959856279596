import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export class BespokeMileageRegistration extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'MileageRegistration')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    extendRelationshipSelection(entity: Entity,
                                relationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean,
                                builder: EntitySelectionBuilder,
                                commitContext?: CommitContext)
    {
        super.extendRelationshipSelection(
            entity,
            relationshipDefinition,
            isParent,
            builder,
            commitContext
        );

        const types = this.entityTypeStore.bespoke.types;

        if (relationshipDefinition === types.Relationship.RelationshipDefinition.MileageRegistrations
            && isParent)
        {
            builder.where(
                cb =>
                    cb.isNotOfType(
                        builder.rootPath,
                        types.Relationship.Person.Contact.Type,
                        false,
                        true));
        }
        else if (relationshipDefinition === types.Activity.RelationshipDefinition.MileageRegistrations
            && isParent)
        {
            builder
                .if(
                    () =>
                        entity.hasRelationshipsByDefinition(
                            true,
                            types.Relationship.RelationshipDefinition.MileageRegistrations,
                            commitContext
                        ),
                    sb =>
                        sb.where(
                            cb =>
                                cb.relatedToEntity(
                                    builder.rootPath
                                        .joinTo(
                                            types.Relationship.RelationshipDefinition.Activities,
                                            true),
                                    entity.getRelatedEntityByDefinition(
                                        true,
                                        types.Relationship.RelationshipDefinition.MileageRegistrations,
                                        commitContext)
                                )
                        )
                );
        }
        else if (relationshipDefinition === types.Milestone.RelationshipDefinition.MileageRegistrations
            && isParent)
        {
            builder
                .where(
                    cb =>
                        cb.relatedToEntity(
                            builder.rootPath
                                .joinTo(
                                    types.Activity.Project.RelationshipDefinition.Milestones,
                                    true),
                            entity.getRelatedEntityByDefinition(
                                true,
                                types.Activity.RelationshipDefinition.MileageRegistrations,
                                commitContext
                            )
                        )
                );
        }
    }

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        const types = this.entityTypeStore.bespoke.types;

        if (relationshipDefinition === types.Activity.RelationshipDefinition.MileageRegistrations
            && isParent
            && relatedEntity)
        {
            updateRelationship(
                entity,
                true,
                types.Relationship.RelationshipDefinition.MileageRegistrations,
                relatedEntity.getRelatedEntityByDefinition(
                    true,
                    types.Relationship.RelationshipDefinition.Activities,
                    commitContext
                ),
                commitContext
            );
        }
    }

    getEntityToOpen(entity: Entity): Entity
    {
        return entity.getRelatedEntityByDefinition(
            true,
            this.types.Activity.RelationshipDefinition.MileageRegistrations);
    }

    orderByField(entity?: Entity, pathFromRelatedEntity?: RelatedEntityPath): EntityFieldPath
    {
        return EntityPath.root(this.type)
            .field(this.types.MileageRegistration.Field.Date);
    }

    isAssignableToTeam(): boolean
    {
        return true;
    }

    inheritTeamFrom(): EntityPath
    {
        return EntityPath.root(this.type)
            .joinTo(
                this.types.Activity.RelationshipDefinition.MileageRegistrations,
                true
            );
    }

    // ----------------------- Private logic ------------------------
}
