import { observable } from 'mobx';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import FieldMapping from '../FieldMapping';
import { DataObject } from '../../../../../DataObject/Model/DataObject';

export default class ValueFieldMapping extends FieldMapping
{
    // ------------------------- Properties -------------------------

    @observable.ref value: DataObject;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                targetFieldPath: EntityFieldPath,
                value: DataObject)
    {
        super(id, targetFieldPath);

        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        const targetFieldPath = EntityFieldPath.construct(descriptor.targetFieldPath);
        const value =
            descriptor.value
                ?
                    targetFieldPath.isRelationship
                        ?
                            descriptor.value
                        :
                            DataObject.constructFromDescriptor(descriptor.value)
                :
                    undefined;

        return new ValueFieldMapping(
            descriptor.id,
            targetFieldPath,
            value);
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Value',
            value: this.value?.descriptor()
        };
    }

    // ----------------------- Private logic ------------------------
}
