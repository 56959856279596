import React, { useCallback } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import Fields from '../../../Fields/Fields';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { EntityExpansionBuilder } from '../../../Selection/Builder/EntityExpansionBuilder';
import { openEntity } from '../../../@Util/openEntity';
import { createTransactionalModel } from '../../../../../../@Util/TransactionalModelV2/index';
import { EntityField } from '../../../../../../@Api/Model/Implementation/EntityField';
import constructEntity from '../../../../../../@Api/Entity/constructEntity';
import useTypes from '../../../Type/Api/useTypes';
import EntityRecurrencePatternEditor from '../../Shared/RecurrencePattern/EntityRecurrencePatternEditor';
import { useNewCommitContext } from '../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import PrimaryButton from '../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface CalendarItemProps
{
    entity: Entity;
}

const CalendarItem: React.FC<CalendarItemProps> =
    ({
        entity,
     }) =>
    {
        const types = useTypes();
        const convertToAppointment =
            useCallback(
                () =>
                {
                    const parentActivityPaths = [
                        EntityPath.fromEntity(entity)
                            .joinTo(
                                types.Activity.Task.RelationshipDefinition.CalendarItem,
                                true),
                        EntityPath.fromEntity(entity)
                            .joinTo(
                                types.Activity.Appointment.RelationshipDefinition.CalendarItem,
                                true)
                    ];

                    return new EntityExpansionBuilder(
                        entity.entityType,
                        [
                            entity
                        ],
                        parentActivityPaths)
                        .expand()
                        .then(
                            () =>
                            {
                                const parentActivity =
                                    parentActivityPaths
                                        .map(
                                            path =>
                                                path.traverseEntity(entity))
                                        .reduce((a, b) => a.concat(b), [])
                                        .find(() => true);

                                if (parentActivity)
                                {
                                    const relatedEntity =
                                        parentActivity.getRelatedEntityByDefinition(
                                            true,
                                            types.Activity.RelationshipDefinition.LinkedActivities)
                                        ||
                                        parentActivity.getRelatedEntityByDefinition(
                                            true,
                                            types.Relationship.Person.Contact.RelationshipDefinition.Activities)
                                        ||
                                        parentActivity.getRelatedEntityByDefinition(
                                            true,
                                            types.Relationship.RelationshipDefinition.Activities);

                                    if (relatedEntity)
                                    {
                                        return openEntity(relatedEntity)
                                            .then(
                                                () =>
                                                    Promise.resolve(false));
                                    }
                                    else
                                    {
                                        return Promise.resolve(true);
                                    }
                                }
                                else
                                {
                                    const appointment =
                                        createTransactionalModel(
                                            new Entity(types.Activity.Appointment.Type)
                                                .initialize());

                                    appointment.updateRelationship(
                                        false,
                                        types.Activity.Appointment.RelationshipDefinition.CalendarItem,
                                        createTransactionalModel(entity));

                                    appointment.updateRelationship(
                                        false,
                                        types.Activity.Appointment.RelationshipDefinition.Owner,
                                        createTransactionalModel(
                                            entity.getRelatedEntityByDefinition(
                                                true,
                                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.CalendarItems)));

                                    const map = new Map<EntityField, EntityField>();
                                    map.set(types.CalendarItem.Field.Subject, types.Activity.Field.Subject);
                                    map.set(types.CalendarItem.Field.StartDate, types.Activity.Appointment.Field.StartDate);
                                    map.set(types.CalendarItem.Field.EndDate, types.Activity.Appointment.Field.EndDate);
                                    map.set(types.CalendarItem.Field.Content, types.Activity.Appointment.Field.Description);
                                    map.set(types.CalendarItem.Field.Location, types.Activity.Appointment.Field.Location);

                                    map.forEach(
                                        (targetField, sourceField) =>
                                            appointment.setValueByField(
                                                targetField,
                                                entity.getObjectValueByField(sourceField)));

                                    constructEntity(
                                        appointment.entityType,
                                        undefined,
                                        appointment
                                    );

                                    return Promise.resolve(false);
                                }
                            });
                },
                [
                    types,
                    entity,
                ]
            );
        const commitContext = useNewCommitContext();

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset
                                    bottom={false}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <TypeAndName
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                                <Fields
                                    entity={entity}
                                    commitContext={commitContext}
                                />
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <EntityRecurrencePatternEditor
                                    entity={entity}
                                    commitContext={commitContext}
                                    autoCommit
                                    inset
                                    startDateField={types.CalendarItem.Field.StartDate}
                                    isRecurringField={types.CalendarItem.Field.IsRecurring}
                                    recurrencePatternField={types.CalendarItem.Field.RecurrencePattern}
                                />
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Card
                                inset
                            >
                                <PrimaryButton
                                    label={
                                        <LocalizedText
                                            code="ConvertToAppointment"
                                            value="Converteren naar afspraak"
                                        />
                                    }
                                    onClick={convertToAppointment}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(CalendarItem);
