import React, { useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import noteCardStyles from '../Card/Card.module.scss';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import LocalizationContext from '../../../../../../../@Service/Localization/LocalizationContext';
import { getRichtextEditorDefaultButtons, RichTextEditorStore } from '../../../../../../Generic/RichText/Editor/RichTextEditorStore';
import { ToolbarButtonStore } from '../../../../../../Generic/RichText/Toolbar/Types/Button/ToolbarButtonStore';
import { ButtonStore } from '../../../../../../Generic/Button/ButtonStore';
import { RichTextEditor } from '../../../../../../Generic/RichText/Editor/RichTextEditor';
import useTypes from '../../../../Type/Api/useTypes';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import useTransactionalEntity from '../../../../../../../@Api/Entity/Bespoke/useTransactionalEntity';
import deleteEntity from '../../../../../../../@Api/Entity/deleteEntity';

export interface EditorProps
{
    entity: Entity;
    white?: boolean;
    hideCard?: boolean;
    onClose: () => void;
}

const Editor: React.FC<EditorProps> =
    props =>
    {
        const localization = useContext(LocalizationContext);
        const types = useTypes();
        const transactionalEntity = useTransactionalEntity(props.entity);

        /* eslint-disable */
        const saveCallback =
            useCallback(
                () =>
                {
                    if (richTextEditorStore.contentLength === 0)
                    {
                        deleteEntity(transactionalEntity)
                            .then(
                                () =>
                                    props.onClose());
                    }
                    else
                    {
                        transactionalEntity
                            .checkAndDoCommit()
                            .then(
                                () =>
                                    props.onClose());
                    }
                },
                [
                    props,
                    transactionalEntity,
                    props.onClose,
                ]);
        /* eslint-enable */

        const cancelCallback =
            useCallback(
                () =>
                {

                    transactionalEntity.cancelCommit();
                    props.onClose();
                },
                [
                    props,
                    transactionalEntity,
                ]);

        const updateCallback =
            useCallback(
                value =>
                    transactionalEntity.setValueByField(types.Note.Field.Content, value),
                [
                    transactionalEntity,
                    types.Note.Field.Content
                ]);

        const [ richTextEditorStore ] = useState(
            () =>
                new RichTextEditorStore({
                    white: props.white,
                    placeholder: localization.translate('EntityNoteEditor.PlaceholderText'), // Add a note or attachment
                    content: () => transactionalEntity.getDataObjectValueByField(types.Note.Field.Content).toString(),
                    showEditorInCard: true,
                    controls:
                    [
                        [
                            new ToolbarButtonStore(
                                'btnSave',
                                new ButtonStore({
                                    label: localization.translate('Generic.Save'), // Save
                                    onClick: saveCallback,
                                    color: 'primary',
                                    isRaised: () => false,
                                    style:
                                    {
                                        height: 27,
                                        padding: '0px 8px',
                                    }
                                })),

                            new ToolbarButtonStore(
                                'btnCancel',
                                new ButtonStore({
                                    label: localization.translate('Generic.Cancel'), // Cancel
                                    onClick: cancelCallback,
                                    color: 'default',
                                    isRaised: () => false,
                                    style:
                                    {
                                        height: 27,
                                        padding: '0px 8px',
                                    }
                                })),

                            ...getRichtextEditorDefaultButtons()

                        ].filter(control => control !== undefined)
                    ],
                    initialHeight: 20,
                    autoHideToolbarWhenEmpty: false,
                    showToolbarOnFocus: false,
                    onChangeCallback: updateCallback,
                    classes:
                        {
                            editor:
                                classNames(
                                    !props.hideCard && noteCardStyles.card,
                                    props.white && noteCardStyles.white)
                        },
                    onCtrlEnter: saveCallback
                }));


        return <RichTextEditor store={richTextEditorStore} />;
    };

export default observer(Editor);
