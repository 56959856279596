import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LabelButton from '../../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import { green } from '@material-ui/core/colors';
import styles from './TrialStatus.module.scss';
import RouterContext from '../../../../@Service/Router/RouterContext';
import { useComputed } from 'mobx-react-lite';
import useTypes from '../../Entity/Type/Api/useTypes';
import moment from 'moment';
import useCurrentLicensePeriod from '../Api/useCurrentLicensePeriod';
import useIsMobile from '../../../../@Util/Responsiveness/useIsMobile';
import CurrentUserContext from '../../User/CurrentUserContext';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';

export interface TrialStatusProps
{
    onStart?: () => void;
}

const TrialStatus: React.FC<TrialStatusProps> =
    props =>
    {
        const [ , periodEnd ] = useCurrentLicensePeriod();
        const routerStore = useContext(RouterContext);
        const types = useTypes();

        const start =
            useCallback(
                () =>
                {
                    routerStore.route('/account');

                    if (props.onStart)
                    {
                        props.onStart();
                    }
                },
                [
                    routerStore,
                    props.onStart
                ]);

        const currentUserStore = useContext(CurrentUserContext);

        const isTrial =
            useComputed(
                () =>
                    !currentUserStore.contractEntity?.hasValueForField(types.LicenseContract.Field.ActivationDate),
            [
                currentUserStore,
                types
            ]);

        const numberOfDaysLeftInPeriod =
            useComputed(
                () =>
                {
                    return Math.round(
                        moment(periodEnd)
                            .diff(moment(), 'days', true));
                },
                [
                    periodEnd
                ]);

        const isMobile = useIsMobile();

        if (currentUserStore.contractEntity
            && isTrial
            && !currentUserStore.currentOrganization.parentOrganization)
        {
            return <ViewGroup
                orientation={isMobile ? 'vertical' : 'horizontal'}
                spacing={isMobile ? 5 : 15}
                alignment="center"
            >
                <ViewGroupItem>
                    {
                        numberOfDaysLeftInPeriod <= 0
                            ?
                                <LocalizedText
                                    code="Trial.IsExpired"
                                    value="Je proefperiode is verlopen."
                                />
                            :
                                numberOfDaysLeftInPeriod <= 1
                                    ?
                                        <LocalizedText
                                            code="Trial.ExpiresInOneDay"
                                            value="Je proefperiode verloopt binnen 1 dag."
                                        />
                                    :
                                        <LocalizedText
                                            code="Trial.ExpiresInNumberOfDays"
                                            value="Nog ${numberOfDays} dagen proefperiode."
                                            numberOfDays={numberOfDaysLeftInPeriod}
                                        />
                    }
                </ViewGroupItem>
                <ViewGroupItem>
                    <LabelButton
                        label={
                            <LocalizedText
                                code="Trial.BecomeACustomer"
                                value="Klant worden"
                            />
                        }
                        onClick={start}
                        color={green[400]}
                        size="xs"
                        filled
                        classes={{
                            root: styles.startButton
                        }}
                    />
                </ViewGroupItem>
            </ViewGroup>;
        }
        else
        {
            return null;
        }
    };

export default observer(TrialStatus);
