import React from 'react';
import { RecurrencePatternEditorProps } from '../../RecurrencePatternEditor';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { WeeklyRecurrencePattern } from '../../../Model/RecurrencePattern';
import { RecurrenceIntervalEditor } from '../../Util/Interval/RecurrenceIntervalEditor';
import { DaysOfWeekEditor } from '../../Util/DaysOfWeek/DaysOfWeekEditor';

export const WeeklyRecurrencePatternEditor: React.FC<RecurrencePatternEditorProps<WeeklyRecurrencePattern>> =
    ({
        value,
        onChange,
     }) =>
    {
        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem>
                        <LocalizedText
                            code="Generic.Every"
                            value="Elke"
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <RecurrenceIntervalEditor
                            value={value?.interval}
                            onChange={
                                interval =>
                                    onChange({
                                        ...value,
                                        interval,
                                    })
                            }
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <LocalizedText
                            code="Generic.Weeks"
                            value="Weken"
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
            <ViewGroupItem>
                <DaysOfWeekEditor
                    value={value?.daysOfWeek}
                    onChange={
                        daysOfWeek =>
                            onChange({
                                ...value,
                                daysOfWeek,
                            })
                    }
                />
            </ViewGroupItem>
        </ViewGroup>;
    };
