import { observer } from 'mobx-react';
import { EntityEvent } from '../../../../../../../@Api/Model/Implementation/EntityEvent';
import React, { useContext, useMemo } from 'react';
import { useComputed } from 'mobx-react-lite';
import LocalizerContext from '../../../../../../../@Service/Localization/LocalizerContext';
import { TableCell, TableRow } from '@material-ui/core';

interface AutomationMutationLogRowProps
{
    event: EntityEvent;
    onClick: (event: EntityEvent) => void;
}

export const AutomationMutationLogRow: React.FC<AutomationMutationLogRowProps> = observer(
    ({
        event,
        onClick
     }) =>
    {
        const localizer = useContext(LocalizerContext);
        const userName =
            useComputed(
                () =>
                    event.user
                        ? event.user.entity.getName()
                        : "system",
                [
                    event
                ]
            );

        const eventDate =
            useMemo(
                () => new Date(event.date),
                [
                    event
                ]
            );

        return <TableRow
            onClick={() => onClick(event)}
            hover
            style={{cursor: 'pointer'}}
        >
            <TableCell>
                {localizer.formatDate(eventDate) + ' [' + localizer.formatTime(eventDate) + ']'}
            </TableCell>
            <TableCell>
                {userName}
            </TableCell>
        </TableRow>
    }
);