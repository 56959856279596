import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../../Editor/LayoutEditor';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import FormInputLayout from '../../../../../../../@Api/Layout/Type/Form/Input/FormInputLayout';

export interface FormInputParameterViewerProps extends LayoutEditorProps<FormInputLayout>
{

}

const FormInputParameterViewer: React.FC<FormInputParameterViewerProps> =
    props =>
    {
        const parameter =
            useMemo(
                () =>
                    props.parameterDictionary.getParameterById(
                        props.layout.parameterId
                    ),
                [
                    props.parameterDictionary,
                    props.layout.parameterId,
                ]
            );

        return <Input
            labelPosition="left"
            label={
                <LocalizedText
                    code="Generic.Parameter"
                    value="Parameter"
                />
            }
        >
            {parameter.getName()}
        </Input>;
    };

export default observer(FormInputParameterViewer);
