import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../../Editor/LayoutEditor';
import FunctionContext from '../../../../../../../@Api/Automation/Function/FunctionContext';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import FormInputLayout from '../../../../../../../@Api/Layout/Type/Form/Input/FormInputLayout';
import LayoutEventTriggersEditor from '../../../../EventTriggers/LayoutEventTriggersEditor';

export interface FormInputPlaceholderEditorProps extends LayoutEditorProps<FormInputLayout>
{

}

const FormInputEventTriggersEditor: React.FC<FormInputPlaceholderEditorProps> =
    props =>
    {
        const { layout } = props;

        const context =
            useComputed(
                () =>
                    new FunctionContext(
                        props.parameterDictionary,
                        props.parameterAssignment,
                        props.commitContext
                    ),
                [
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        return <Input
            labelPosition="top"
            label={
                <LocalizedText
                    code="Generic.Triggers"
                    value="Triggers"
                />
            }
        >
            <LayoutEventTriggersEditor
                eventTriggers={layout.eventTriggers}
                context={context}
            />
        </Input>;
    };

export default observer(FormInputEventTriggersEditor);
