import React from 'react';
import { observer } from 'mobx-react';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import PlayerList from '../../../../../Domain/Multiplayer/PlayerList/PlayerList';
import styles from './Dock.module.scss';
import OnboardingManager from '../../../../../Domain/OnboardingManager/OnboardingManager';

export interface DockProps
{

}

const Dock: React.FC<DockProps> =
    props =>
    {
        return <div
            className={styles.root}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={15}
                alignment="end"
            >
                <ViewGroupItem>
                    <OnboardingManager />
                </ViewGroupItem>
                <ViewGroupItem>
                    <PlayerList />
                </ViewGroupItem>
            </ViewGroup>
        </div>;
    };

export default observer(Dock);
