import { Entity } from '../../../Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';
import useTypes from '../../../../@Component/Domain/Entity/Type/Api/useTypes';
import { useEffect } from 'react';
import { EntityExpansionBuilder } from '../../../../@Component/Domain/Entity/Selection/Builder/EntityExpansionBuilder';
import { EntityPath } from '../../../../@Component/Domain/Entity/Path/@Model/EntityPath';

export default function useFirstParentRelationship(entity: Entity,
                                                   doFetchIfMissing: boolean = false)
{
    const types = useTypes();

    const firstParentRelationship =
        useComputed(
            () =>
                entity &&
                (entity
                    .getRelatedEntityByDefinition(
                        true,
                        types.Relationship.Person.RelationshipDefinition.Person)
                    ||
                entity
                    .getRelatedEntityByDefinition(
                        true,
                        types.Relationship.Organization.RelationshipDefinition.Organization)),
            [
                entity,
                types
            ]);

    useEffect(
        () =>
        {
            if (!firstParentRelationship && doFetchIfMissing && entity)
            {
                new EntityExpansionBuilder(
                    entity.entityType,
                    [
                        entity
                    ],
                    [
                        EntityPath.fromEntity(entity)
                            .joinTo(
                                entity.entityType.isA(types.Relation.Person.Type)
                                    ?
                                        types.Relationship.Person.RelationshipDefinition.Person
                                    :
                                        types.Relationship.Organization.RelationshipDefinition.Organization,
                                true)
                    ]).expand();
            }
        },
        [
            doFetchIfMissing,
            entity,
            firstParentRelationship,
            types.Relation.Person.Type,
            types.Relationship.Person.RelationshipDefinition.Person,
            types.Relationship.Organization.RelationshipDefinition.Organization
        ]);

    return firstParentRelationship;
}
