import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { RelatedEntityPath } from '../Path/@Model/RelatedEntityPath';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { RouterStore } from '../../../../@Service/Router/RouterStore';
import { EntityViewerPageInitializerStore } from '../Viewer/Page/Initializer/EntityViewerPageInitializerStore';
import { SelectionCursor } from '../Viewer/SelectionCursor/SelectionCursor';
import { runInAction } from 'mobx';

export function openEntity(entity: Entity,
                           pathFromRelatedEntity?: RelatedEntityPath,
                           fromEntity?: Entity,
                           selectionCursor?: SelectionCursor): Promise<any>
{
    if (entity.entityType.bespoke.isOpenable(
        entity,
        pathFromRelatedEntity))
    {
        return entity.entityType.bespoke.onOpen(
            entity,
            pathFromRelatedEntity)
            .then(
                doRoute =>
                {
                    if (doRoute)
                    {
                        const routerStore = loadModuleDirectly(RouterStore);
                        const entityToOpen = entity.entityType.bespoke.getEntityToOpen(entity);

                        return routerStore.route(`/entity/${entityToOpen.id}`)
                            .then(
                                pageStore =>
                                {
                                    const viewerPageStore = pageStore as EntityViewerPageInitializerStore;

                                    runInAction(
                                        () =>
                                        {
                                            if (viewerPageStore.entityViewerPageStore)
                                            {
                                                viewerPageStore.entityViewerPageStore.selectionCursor = selectionCursor;
                                            }
                                        });

                                    return Promise.resolve(viewerPageStore);
                                });
                    }
                    else
                    {
                        return Promise.resolve(undefined);
                    }
                });
    }
    else
    {
        return Promise.resolve();
    }
}
