import React, { useContext, useEffect, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { DataObjectEditor } from '../../../../../../DataObject/Editor/Value/Editor/DataObjectEditor';
import { DataObjectEditorStore } from '../../../../../../DataObject/Editor/Value/Editor/DataObjectEditorStore';
import { DataObject } from '../../../../../../DataObject/Model/DataObject';
import TypeMapping from '../../../../Model/TypeMapping';
import { DataObjectSpecification } from '../../../../../../DataObject/Model/DataObjectSpecification';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import EntityTypeContext from '../../../../../Type/EntityTypeContext';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';

export interface MappingEntityTypeEditorProps
{
    typeMapping: TypeMapping;
    value: DataObject;
    entity?: Entity;
}

const MappingEntityTypeEditor: React.FC<MappingEntityTypeEditorProps> =
    props =>
    {
        const entityTypeStore = useContext(EntityTypeContext);
        const editorStore =
            useMemo(
                () =>
                    new DataObjectEditorStore({
                        dataObject:
                            new DataObject(
                                new DataObjectSpecification(
                                    props.value.specification.type,
                                    {
                                        rootEntityTypeId: props.typeMapping.entityType.id,
                                        isInstantiable: true
                                    }),
                                props.value.data)
                    }),
                [
                    props.value,
                    props.typeMapping
                ]);

        const selectedEntityType =
            useComputed<EntityType>(
                () =>
                    editorStore.dataObject.value,
                [
                    editorStore
                ]);

        useEffect(
            () =>
            {
                if (props.entity)
                {
                    props.entity.setEntityType(selectedEntityType, entityTypeStore);
                }

                props.value.setValue(selectedEntityType);
            },
            [
                selectedEntityType
            ]);

        return <DataObjectEditor
            store={editorStore}
        />;
    };

export default observer(MappingEntityTypeEditor);
