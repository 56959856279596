import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Automation.module.scss';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import useTransactionalEntity from '../../../../../../../@Api/Entity/Bespoke/useTransactionalEntity';
import { openEntity } from '../../../../../Entity/@Util/openEntity';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import AutomationActivationSwitch from '../AutomationActivationToggle/AutomationActivationSwitch';

export interface AutomationProps
{
    automation: Entity;
    activation?: Entity;
    allowManagement: boolean;
}

const Automation: React.FC<AutomationProps> =
    props =>
    {
        const entity = useTransactionalEntity(props.automation);
        const click =
            useCallback(
                () =>
                    openEntity(entity),
                [
                    entity
                ]);

        return <div
            className={classNames(styles.root, props.allowManagement && styles.allowManagement)}
            onClick={
                props.allowManagement
                    ?
                        click
                    :
                        undefined}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={15}
                alignment="center"
            >
                <ViewGroupItem
                    ratio={1}
                >
                    {props.automation.name}
                </ViewGroupItem>
                {
                    props.allowManagement &&
                        <ViewGroupItem>
                            <AutomationActivationSwitch
                                automation={props.automation}
                                activation={props.activation}
                            />
                        </ViewGroupItem>
                }
            </ViewGroup>
        </div>;
    };

export default observer(Automation);
