import { EntityType } from '../Model/Implementation/EntityType';
import { SettingSource } from '../../@Component/Domain/Setting/SettingStore';
import getSetting from '../../@Component/Domain/Setting/Api/getSetting';

export default function getMetadataSettingValue<T>(entityType: EntityType,
                                                  code: string): T | undefined
{
    const setting = getSetting<any>(SettingSource.Organization, code);

    if (setting === undefined)
    {
        return undefined;
    }
    else
    {
        return setting[entityType.id];
    }
}
