import { loadModuleDirectly } from '../../../../../../../../@Util/DependencyInjection/index';
import { LocalizationStore } from '../../../../../../../../@Service/Localization/LocalizationStore';

export function isMultiCurrency(
    includeInactive: boolean = false
): boolean
{
    const localizationStore = loadModuleDirectly(LocalizationStore);

    return localizationStore.localizer.currencies
        .filter(
            currency =>
                !includeInactive
                    ? currency.isActive
                    : true
        )
        ?.length > 1;
}