import { observable } from 'mobx';

export type OutputType = 'Default' | 'Email';

export default class RenderOptions
{
    // ------------------------- Properties -------------------------

    @observable outputType: OutputType;

    // ------------------------ Constructor -------------------------

    constructor(outputType: OutputType)
    {
        this.outputType = outputType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor()
    {
        return {
            outputType: this.outputType
        };
    }

    static fromDescriptor(descriptor: any)
    {
        return new RenderOptions(descriptor.outputType);
    }

    // ----------------------- Private logic ------------------------
}
