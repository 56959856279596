import React from 'react';
import LabelButton, { LabelButtonProps } from '../Label/LabelButton';
import { dangerColor } from '../../../../../../@Resource/Theme/Theme';

export interface DangerTextButtonProps extends LabelButtonProps
{

}

const DangerTextButton: React.FC<DangerTextButtonProps> =
    props =>
    {
        return <LabelButton
            {...props}
        />;
    };

DangerTextButton.defaultProps = {
    color: dangerColor,
    hoverColor: dangerColor
};

export default DangerTextButton;
