import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { SidebarPanelStore } from './SidebarPanelStore';
import { CardContent, Grid, Typography } from '@material-ui/core';
import Button from '../Button/Button';
import { View } from '../ViewStack/View';
import { buttonInset, normalGrey, viewInset } from '../../../@Resource/Theme/Theme';
import { TabBar } from '../Tab/TabBar';

@observer
export class SidebarPanel extends BaseComponent<SidebarPanelStore>
{
    renderComponent(store: SidebarPanelStore)
    {
        const title = store.title
            ?
                <Typography variant="h6" style={{flex: '1 1 auto'}}>
                    {store.title} {store.subTitle && <Typography variant="caption">{store.subTitle}</Typography>}
                </Typography>
            :
                <div style={{flex: '1 1 auto'}} />;



        const content = <>
            {
                title
            }
            {
                store.headerView &&
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: viewInset
                        }}
                    >
                        <View
                            viewComponent={store.headerView}
                        />
                    </div>
            }
            {
                <div>
                    {
                        store.headerButtons && store.headerButtons.length > 0 &&
                            store.headerButtons.map(
                                (buttonStore, index) =>
                                    <div
                                        key={index}
                                        style={{
                                            display: 'inline-block',
                                            marginLeft: buttonInset
                                        }}
                                    >
                                        <Button store={buttonStore} />
                                    </div>)
                    }
                </div>
            }
            {
                store.closeButtonStore &&
                    <div
                        style={{
                            display: 'inline-block',
                            marginLeft: buttonInset
                        }}
                    >
                        <Button store={store.closeButtonStore} />
                    </div>
            }
        </>;


        return <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                flex: 1,
            }}
            onMouseEnter={store.startHovering}
            onMouseLeave={store.stopHovering}
        >
            {
                store.showHeader &&
                    <div
                        style={{
                            flex: '0 0 auto',
                            borderBottom: store.showInCard ? `1px solid ${normalGrey}` : undefined
                        }}
                    >
                        {
                            store.showInCard
                                ?
                                    <CardContent
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingBottom: 6,
                                            paddingTop: 6,
                                            height: 60,
                                            paddingLeft: 13,
                                            background: 'white'
                                        }}
                                    >
                                        {content}
                                    </CardContent>
                                :
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingBottom: 6,
                                            paddingTop: 6,
                                            height: 60,
                                            paddingLeft: 16,
                                            paddingRight: 16
                                        }}

                                    >
                                        {content}
                                    </div>
                        }
                        {
                            store.tabBarStore.hasTabs &&
                                <TabBar store={store.tabBarStore}/>
                        }
                    </div>
            }
            <div
                style={{
                    flex: '1 1 auto',
                    overflowY: 'auto', // store.isHovering && store.scrollable ? 'auto' : 'hidden',
                    overflowX: 'hidden',
                    paddingTop:
                        store.hasHeaderInset
                            ?
                                10
                            :
                                undefined,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flex: '1 1 auto',
                        flexDirection: 'column',
                        padding: store.bodyPadding,
                        // marginRight: store.isHovering && store.scrollable ? -5 : undefined
                    }}
                >
                    <View viewComponent={store.viewComponent} />
                </div>
            </div>
            {
                store.buttons && store.buttons.length > 0 &&
                    <CardContent
                        style={{
                            flex: '0 0 auto',
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12} style={{textAlign: 'right'}}>
                                {
                                    store.buttons.map(
                                        (buttonStore, index) =>
                                            <Button
                                                key={index}
                                                store={buttonStore}
                                                style={{
                                                    marginRight: 10
                                                }}
                                            />)
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
            }
        </div>;
    }
}
