import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';
import Read from './Read/Read';
import Item from '../../Item';

export interface EventProps
{
    entity: Entity;
}

const Event: React.FC<EventProps> =
    props =>
    {
        const types = useTypes();

        if (props.entity.entityType.isA(types.Event.Read.Type))
        {
            return <Read
                entity={props.entity}
            />;
        }
        else
        {
            return <Item
                entity={props.entity}
                showType
                showMenuButton
            />;
        }
    };

export default observer(Event);
