import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Icon from '../../../../../../../../@Future/Component/Generic/Icon/Icon';
import { makeStyles } from '@material-ui/styles';
import localize from '../../../../../../../../@Api/Localization/localize';
import { loadModuleDirectly } from '../../../../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../../../Type/EntityTypeStore';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';

const useStyles =
    makeStyles({
        wrapper:
            {
                padding: 5,
                paddingLeft: 0,
                fontSize: 13,
                whiteSpace: 'normal',
            }
    });

export interface TrackItemTooltipAddressProps
{
    relation: Entity
}

export const TrackItemTooltipAddress: React.FC<TrackItemTooltipAddressProps> =
    observer(
        ({
             relation
        }) =>
        {
            const entityTypeStore = loadModuleDirectly(EntityTypeStore);
            const types = entityTypeStore.bespoke.types;
            const classes = useStyles();

            const address =
                useMemo<Entity>(
                    () =>
                    {
                        if (relation?.entityType.isA(types.Relation.Organization.Type))
                        {
                            return relation.getRelatedEntityByDefinition(
                                false,
                                types.Relation.Organization.RelationshipDefinition.VisitingAddress);
                        }
                        else if (relation?.entityType.isA(types.Relation.Person.Type))
                        {
                            return relation.getRelatedEntityByDefinition(
                                false,
                                types.Relation.Person.RelationshipDefinition.Address);
                        }

                        return undefined;
                    },
                    [
                        relation,
                        types
                    ]
                );

            const addressLine1 =
                useMemo<string>(
                    () =>
                    {
                        if (address)
                        {
                            const street = address.getObjectValueByField(types.Address.Field.Street);
                            const houseNr = address.getObjectValueByField(types.Address.Field.HouseNr);
                            const houseNrSuffix = address.getObjectValueByField(types.Address.Field.HouseNrSuffix);

                            return [street, houseNr, houseNrSuffix]
                                .filter(e => e !== undefined)
                                .join(" ");
                        }
                    },
                    [
                        address,
                        types
                    ]
                );

            const addressLine2 =
                useMemo<string>(
                    () =>
                    {
                        if (address)
                        {
                            const postalCode = address.getObjectValueByField(types.Address.Field.PostalCode);
                            const city = address.getObjectValueByField(types.Address.Field.City);

                            return [postalCode, city]
                                .filter(e => e !== undefined)
                                .join(" ");
                        }
                    },
                    [
                        address,
                        types
                    ]
                );

            const addressLine3 =
                useMemo<string>(
                    () =>
                    {
                        if (address)
                        {
                            const country =
                                address.getRelatedEntityByDefinition(
                                    false,
                                    types.Address.RelationshipDefinition.Country);

                            if (country)
                            {
                                return localize(
                                    country.getObjectValueByField(types.Datastore.Field.LocalizedName)
                                );
                            }
                        }
                    },
                    [
                        address,
                        types
                    ]
                );

            if (address !== undefined)
            {
                return <ViewGroup
                    orientation="horizontal"
                    spacing={8}
                    className={classes.wrapper}
                >
                    <ViewGroupItem>
                        <Icon
                            icon="room"
                            size={22}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="vertical"
                            spacing={4}
                        >
                            <ViewGroupItem>
                                {addressLine1}
                            </ViewGroupItem>
                            <ViewGroupItem>
                                {addressLine2}
                            </ViewGroupItem>
                            <ViewGroupItem>
                                {addressLine3}
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                </ViewGroup>
            }
            else
            {
                return null;
            }
        }
    );