import React, { useMemo, useState } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import useTypes from '../../../../Type/Api/useTypes';
import { Card } from '@material-ui/core';
import getSystemDefaultView from '../../../../View/Api/getSystemDefaultView';
import getViewParameters from '../../../../View/Api/getViewParameters';
import ComparisonPredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../../../View/Model/ViewParams';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { Comparator } from '../../../../../DataObject/Model/Comparator';
import useFetchedRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity';
import EntityValue from '../../../../../../../@Api/Automation/Value/EntityValue';
import List, { ListSelection } from '../../../../View/List/List';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import { CommitBuilder } from '../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { mapBy } from '../../../../../../../@Util/MapUtils/mapBy';
import CompositePredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../../DataObject/Model/LogicalOperator';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';

export interface CourseLessonAttendanceRegistrationProps
{
    lesson: Entity;
    commitContext?: CommitContext;
}

const CourseLessonAttendanceRegistration: React.FC<CourseLessonAttendanceRegistrationProps> =
    ({
         lesson,
         commitContext,
     }) =>
    {
        const types = useTypes();
        const [ course ] =
            useFetchedRelatedEntity(
                lesson,
                types.Activity.Course.RelationshipDefinition.Lessons,
                true,
                commitContext
            );
        const rootType =
            useMemo(
                () =>
                    types.Activity.CourseAttendance.Type,
                [types]
            );
        const viewParameters =
            useMemo(
                () =>
                    getViewParameters(rootType),
                [
                    rootType,
                ]
            );
        const isRegistrationPerLesson =
            useEntityValue<boolean>(
                course,
                types.Activity.Course.Field.IsRegistrationPerLesson,
                undefined,
                commitContext
            );
        const view =
            useMemo(
                () =>
                    course !== undefined &&
                    getSystemDefaultView(
                        rootType,
                        viewParameters,
                        new CompositePredicate(
                            LogicalOperator.And,
                            [
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        viewParameters.getParameterById(ViewParams.Entity),
                                        EntityPath.fromEntityType(rootType)
                                            .joinTo(
                                                types.Activity.Course.RelationshipDefinition.Attendances,
                                                true
                                            )
                                            .field()
                                    ),
                                    Comparator.Equals,
                                    new EntityValue(course)
                                ),
                                ...isRegistrationPerLesson
                                    ? [
                                        new ComparisonPredicate(
                                            new ValueFromEntityComputation(
                                                viewParameters.getParameterById(ViewParams.Entity),
                                                EntityPath.fromEntityType(rootType)
                                                    .joinTo(
                                                        types.Activity.CourseAttendance.RelationshipDefinition.CourseLessonRegistrations,
                                                        false
                                                    )
                                                    .joinTo(
                                                        types.CourseLesson.RelationshipDefinition.Registrations,
                                                        true
                                                    )
                                                    .field()
                                            ),
                                            Comparator.Equals,
                                            new EntityValue(lesson)
                                        )
                                    ]
                                    : []
                            ]
                        )
                    ),
                [
                    rootType,
                    viewParameters,
                    types,
                    course,
                    isRegistrationPerLesson,
                    lesson,
                ]
            );
        const [listSelection, setListSelection] =
            useState<ListSelection>(
                () => ({
                    base: 'none',
                    mutations: [],
                    size: 0
                })
            );

        return <Card>
            {
                view &&
                <List
                    view={view}
                    selectable
                    selection={listSelection}
                    onSelectionChange={setListSelection}
                    readonly
                />
            }
            <CardInset>
                <PrimaryButton
                    label={
                        <LocalizedText
                            code="CourseLesson.RegisterAttendance"
                            value="Aanwezigheid registreren"
                        />
                    }
                    onClick={
                        async () =>
                        {
                            const lessonAttendanceResults =
                                await EntitySelectionBuilder.builder(
                                    types.Activity.CourseLessonAttendance.Type,
                                    (builder, rootPath) =>
                                        builder
                                            .join(
                                                rootPath.joinTo(
                                                    types.Activity.CourseLessonAttendance.RelationshipDefinition.CourseAttendance,
                                                    false
                                                )
                                            )
                                            .where(
                                                cb =>
                                                    cb.relatedToEntity(
                                                        rootPath.joinTo(
                                                            types.CourseLesson.RelationshipDefinition.Attendances,
                                                            true
                                                        ),
                                                        lesson
                                                    )
                                            )
                                ).select()
                            const lessonAttendances =
                                lessonAttendanceResults.map(
                                    result =>
                                        result.entity
                                );
                            const lessonAttendanceByCourseAttendanceId =
                                mapBy(
                                    lessonAttendances,
                                    attendance =>
                                        attendance.getRelatedEntityByDefinition(
                                            false,
                                            types.Activity.CourseLessonAttendance.RelationshipDefinition.CourseAttendance
                                        )?.id
                                );
                            const courseAttendanceResults =
                                await new EntitySelectionBuilder(rootType)
                                    .where(
                                        cb =>
                                            cb.filter(
                                                view.filter,
                                                {
                                                    parameter: viewParameters.getParameterById(ViewParams.Entity),
                                                }
                                            )
                                    )
                                    .select();
                            const courseAttendances =
                                courseAttendanceResults.map(
                                    result =>
                                        result.entity
                                );
                            const exclusionCourseAttendanceIds = new Set(listSelection.mutations);

                            return new CommitBuilder()
                                .ifValid(
                                    () =>
                                        true,
                                    cb =>
                                        courseAttendances.forEach(
                                            courseAttendance =>
                                            {
                                                const isPresent =
                                                    listSelection.base === 'none'
                                                        ? exclusionCourseAttendanceIds.has(courseAttendance.uuid)
                                                        : !exclusionCourseAttendanceIds.has(courseAttendance.uuid);

                                                if (lessonAttendanceByCourseAttendanceId.has(courseAttendance.id))
                                                {
                                                    return cb.setObjectValueInEntity(
                                                        lessonAttendanceByCourseAttendanceId.get(courseAttendance.id)!,
                                                        types.Activity.CourseLessonAttendance.Field.IsPresent,
                                                        isPresent
                                                    );
                                                }
                                                else
                                                {
                                                    return cb.createEntity(
                                                        types.Activity.CourseLessonAttendance.Type,
                                                        eb =>
                                                            eb
                                                                .relateTo(
                                                                    true,
                                                                    types.CourseLesson.RelationshipDefinition.Attendances,
                                                                    lesson
                                                                )
                                                                .relateTo(
                                                                    false,
                                                                    types.Activity.CourseLessonAttendance.RelationshipDefinition.CourseAttendance,
                                                                    courseAttendance
                                                                )
                                                                .relateTo(
                                                                    true,
                                                                    types.Relationship.RelationshipDefinition.Activities,
                                                                    courseAttendance.getRelatedEntityByDefinition(
                                                                        true,
                                                                        types.Relationship.RelationshipDefinition.Activities
                                                                    )
                                                                )
                                                                .relateTo(
                                                                    true,
                                                                    types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                                                    courseAttendance.getRelatedEntityByDefinition(
                                                                        true,
                                                                        types.Relationship.Person.Contact.RelationshipDefinition.Activities
                                                                    )
                                                                )
                                                                .setObjectValue(
                                                                    types.Activity.CourseLessonAttendance.Field.IsPresent,
                                                                    isPresent
                                                                )
                                                    );
                                                }
                                            }
                                        )
                                )
                                .commit();
                        }
                    }
                />
            </CardInset>
        </Card>;
    };

export default observer(CourseLessonAttendanceRegistration);
