import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Item from '../../../Item/Item';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import styles from './DividedList.module.scss';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import OnOpenContext from '../../../Item/Context/OnOpenContext';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';

export interface DividedListProps
{
    entities: Entity[];
    highlightedEntity?: Entity;
    openable?: boolean;
    editable?: boolean;
    compact?: boolean;
    inset?: boolean;
    hideFavoriteButton?: boolean;
    showType?: boolean;
    showMenuButton?: boolean;
    onOpen?: (entity: Entity) => void;
    renderItem?: (entity: Entity) => React.ReactNode;
}

const DividedList: React.FC<DividedListProps> =
    props =>
    {
        const dividerGlue = useDividerGlue();

        return <OnOpenContext.Provider
            value={props.onOpen}
        >
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={dividerGlue}
            >
                {
                    props.entities.map(
                        (entity, idx) =>
                        {
                            return <ViewGroupItem
                                key={entity.uuid}
                                className={styles.itemContainer}
                            >
                                {
                                    props.renderItem
                                        ?
                                            props.renderItem(entity)
                                        :
                                            <Item
                                                entity={entity}
                                                editable={props.editable}
                                                horizontalInset={props.inset}
                                                verticalInset
                                                compact={props.compact}
                                                showType={props.showType}
                                                showMenuButton={props.showMenuButton}
                                                hideFavoriteButton={props.hideFavoriteButton}
                                                highlighted={equalsEntity(props.highlightedEntity, entity)}
                                                classes={{
                                                    root:
                                                        classNames(
                                                            styles.item,
                                                            idx === 0 && styles.first,
                                                            idx === props.entities.length - 1 && styles.last)
                                                }}
                                            />
                                }
                            </ViewGroupItem>;
                        })
                }
            </ViewGroup>
        </OnOpenContext.Provider>;
    };

export default observer(DividedList);
