import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { BaseDialogStore } from './BaseDialogStore';
import Button from '../Button/Button';
import { View } from '../ViewStack/View';
import { Dialog as MuiDialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, Paper, Slide, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Loader } from '../Loader/Loader';
import { Sidebar } from '../Sidebar/Sidebar';
import useIsMobile from '../../../@Util/Responsiveness/useIsMobile';

@observer
export class Dialog extends BaseComponent<BaseDialogStore>
{
    renderLoader(store: BaseDialogStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: BaseDialogStore)
    {
        return <DialogComponent
            {...this.props}
            store={store}
        />;
    }
}

interface DialogComponentProps
{
    store: BaseDialogStore;
}

const DialogComponent: React.FC<DialogComponentProps> =
    observer(
        ({
            store,
            children,
            ...otherProps
         }) =>
        {
            const isSmallScreen = useIsMobile();
            const content =
                <>
                    {
                        store.viewComponent &&
                        <View viewComponent={store.viewComponent} />
                    }
                    {
                        children
                    }
                </>;
            const isFullScreen = store.isFullScreenOnSmallScreen && isSmallScreen;

            return <MuiDialog
                open={store.isOpen}
                fullWidth={store.isFullWidth}
                fullScreen={isFullScreen}
                maxWidth={store.maxWidth}
                disableBackdropClick={store.isModal || store.isLoading}
                disableEscapeKeyDown={store.isModal || store.isLoading}
                scroll="body"
                {...otherProps as any}
                style={{
                    right: store.sidebarStore.hasViews ? 470 : 0
                }}
                PaperProps={{
                    style: {
                        minHeight:
                            isFullScreen
                                ? '100%'
                                : undefined,
                        height:
                            isFullScreen
                                ? 'auto'
                                : undefined,
                    },
                }}
            >
                {
                    store.title &&
                    <DialogTitle style={{ display: 'flex', alignItems: 'center' }} disableTypography>
                        {
                            store.icon &&
                            <Icon style={{ marginRight: 15, color: grey[800] }}>
                                {store.icon}
                            </Icon>
                        }
                        <Typography variant="h6">
                            {store.title}
                        </Typography>
                    </DialogTitle>
                }
                {
                    (store.closeButtonStore
                        || store.alertMessage
                        || store.hasContentInset) &&
                    <DialogContent>
                        {
                            store.closeButtonStore &&
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 20,
                                    right: 10
                                }}
                            >
                                <Button store={store.closeButtonStore} />
                            </div>
                        }
                        {
                            store.alertMessage &&
                            <DialogContentText>
                                    <span
                                        style={{
                                            whiteSpace: 'pre-wrap'
                                        }}
                                    >
                                        {store.alertMessage}
                                    </span>
                            </DialogContentText>
                        }
                        {
                            store.hasContentInset &&
                            content
                        }
                    </DialogContent>
                }
                {
                    !store.hasContentInset &&
                    content
                }
                {
                    store.buttons && store.buttons.length > 0 &&
                    <DialogActions>
                        {
                            store.isLoading
                                ?
                                <Loader
                                    inline
                                    center
                                />
                                :
                                store.buttons
                                    .map(
                                        (buttonStore, index) =>
                                            <div
                                                key={index}
                                                style={{
                                                    marginLeft: 10
                                                }}
                                            >
                                                <Button
                                                    store={buttonStore}
                                                />
                                            </div>)
                        }

                    </DialogActions>
                }
                {
                    store.sidebarStore.hasViews &&
                    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                        <Paper
                            elevation={10}
                            square={true}
                            style={{
                                position: 'fixed',
                                backgroundColor: 'white',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                width: 470
                            }}
                        >
                            <Sidebar store={store.sidebarStore} />
                        </Paper>
                    </Slide>
                }
            </MuiDialog>;
        }
    );