import { useContext } from 'react';
import FilterContext from '../FilterContext/FilterContext';
import { useComputed } from 'mobx-react-lite';
import { default as EntityTypeStoreContext } from '../../../Type/EntityTypeContext';
import EntityTypeContext from '../EntityTypeContext/EntityTypeContext';
import getFilteredEntityType from './getFilteredEntityType';

export default function useFilteredEntityType()
{
    const entityType = useContext(EntityTypeContext);
    const entityTypeStore = useContext(EntityTypeStoreContext);
    const filter = useContext(FilterContext);

    return useComputed(
        () =>
            (filter && getFilteredEntityType(filter))
                ||
            entityType,
        [
            filter,
            entityTypeStore
        ]);
}
