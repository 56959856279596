import React, { useContext } from 'react';
import styles from './TabLabel.module.scss';
import ViewGroupItem from '../../../ViewGroup/ViewGroupItem';
import ViewGroup from '../../../ViewGroup/ViewGroup';
import TabSelectedContext from '../Context/TabSelectedContext';
import { classNames } from '../../../../../Util/Class/classNames';
import { observer } from 'mobx-react';

export interface TabLabelProps
{
    label: React.ReactNode;
    secondary: React.ReactNode;
}

export const TabLabel: React.FC<TabLabelProps> = observer(
    ({
        label,
         secondary
    }) =>
    {
        const isSelected = useContext(TabSelectedContext);

        return <ViewGroup
            orientation="horizontal"
            spacing={8}
            alignment="center"
        >
            <ViewGroupItem
                ratio={1}
            >
                {label}
            </ViewGroupItem>
            {
                secondary !== undefined &&
                    <ViewGroupItem>
                        <span
                            className={classNames(styles.secondary, isSelected && styles.selected)}
                        >
                           {secondary}
                        </span>
                    </ViewGroupItem>
            }
        </ViewGroup>
    }
);
