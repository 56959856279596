import TransactionalContext from './TransactionalContext';
import { TransactionalPredicate } from './TransactionalConfiguration';

export type Newable<T> = new (...args: any[]) => T;

export const ConfigurationRepository = new Map<Newable<any>, TransactionalBuilder<any>>();

export function registerBuilder<T>(type: Newable<T>): TransactionalBuilder<T>
{
    if (ConfigurationRepository.has(type))
    {
        return ConfigurationRepository.get(type) as TransactionalBuilder<T>;
    }
    else
    {
        const builder = new TransactionalBuilder(type);

        ConfigurationRepository.set(
            type,
            builder);

        return builder;
    }
}

export function getBuilder<T>(type: Newable<T>): TransactionalBuilder<T>
{
    return ConfigurationRepository.get(type) as TransactionalBuilder<T>;
}

export enum BuildMode { IncludeAll, ExcludeAll }

export default class TransactionalBuilder<T>
{
    // ------------------------- Properties -------------------------

    type: Newable<T>;
    idProvider: (value: T) => string;
    mode: BuildMode;
    _props = new Set<keyof T>();
    predicates: Array<TransactionalPredicate<T>> = [];
    deepProps = new Set<keyof T>();
    deepPredicates: Array<TransactionalPredicate<T>> = [];

    // ------------------------ Constructor -------------------------

    constructor(type: Newable<T>)
    {
        this.type = type;
        this.mode = BuildMode.ExcludeAll;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(provider: (value: T) => string): this
    {
        this.idProvider = provider;

        return this;
    }

    includeAll(): this
    {
        this.mode = BuildMode.IncludeAll;

        return this;
    }

    excludeAll(): this
    {
        this.mode = BuildMode.ExcludeAll;

        return this;
    }

    prop(prop: keyof T): this
    {
        this._props.add(prop);

        return this;
    }

    props(...props: Array<(keyof T)>): this
    {
        props.forEach(
            prop =>
                this._props.add(prop));

        return this;
    }

    predicate(predicate: TransactionalPredicate<T>): this
    {
        this.predicates.push(predicate);

        return this;
    }

    deep(prop: keyof T): this
    {
        this.deepProps.add(prop);

        return this;
    }

    deepPredicate(predicate: TransactionalPredicate<T>): this
    {
        this.deepPredicates.push(predicate);

        return this;
    }

    getId(value: T): string | undefined
    {
        if (this.idProvider)
        {
            return this.idProvider(value);
        }
        else
        {
            return undefined;
        }
    }

    isIncluded(context: TransactionalContext<T>): boolean
    {
        const key = context.key as keyof T;
        const isMatch =
            this._props.has(key)
                && this.predicates.every(predicate => predicate(context));

        switch (this.mode)
        {
            case BuildMode.IncludeAll:
                return !isMatch;

            case BuildMode.ExcludeAll:
                return isMatch;
        }
    }

    isDeep(context: TransactionalContext<T>): boolean
    {
        const key = context.key as keyof T;

        return this.deepProps.has(key)
            && this.deepPredicates.every(predicate => predicate(context));
    }

    // ----------------------- Private logic ------------------------
}
