import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { EntityByDate } from '../../../../../../../../../@Api/Model/Implementation/EntityByDate';
import Item from './Item/Item';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CreationItem from './Item/CreationItem';

export interface ListProps
{
    entity: Entity;
    entitiesByLatestActivity: EntityByDate[];
    showCreationItem?: boolean;
    notesOpenByDefault?: boolean;
}

const List: React.FC<ListProps> =
    props =>
    {
        return <ViewGroup
            orientation="vertical"
            spacing={20}
        >
            {
                props.entitiesByLatestActivity.map(
                    (entityByDate, idx) =>
                        <ViewGroupItem
                            key={entityByDate.entity.uuid}
                        >
                            <Item
                                entityByDate={entityByDate}
                                rootEntity={props.entity}
                                notesOpenByDefault={props.notesOpenByDefault}
                                first={idx === 0}
                                // last={idx === props.entitiesByLatestActivity.length - 1 && (props.open ? props.closedCount === 0 : true)}
                                last={false}
                            />
                        </ViewGroupItem>)
            }
            {
                props.showCreationItem &&
                    <ViewGroupItem>
                        <CreationItem
                            entity={props.entity}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(List);
