import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import { findAssistantComputation } from './findAssistantComputation';
import TextComputation from '../../../../../@Api/Automation/Function/Computation/TextComputation';
import { AssistantTemplatedTextComputation } from '../../Model/Automation/Computation/AssistantTemplatedTextComputation';
import ComputationInText from '../../../../../@Api/Automation/Function/Computation/ComputationInText';
import uuid from '../../../../../@Util/Id/uuid';

export function findAssistantTemplatedTextComputation(
    computation: AssistantTemplatedTextComputation,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Computation<any, any>
{
    const computationsInText: ComputationInText[] = [];
    let rewrittenTemplate = computation.template;
    let nextOccurrence = rewrittenTemplate.indexOf('%$');
    let idx = 0;

    while (nextOccurrence >= 0)
    {
        const placeholderComputation =
            findAssistantComputation(
                computation.placeholders[idx],
                parameters,
                rootParameter
            );
        const computationInText =
            new ComputationInText(
                uuid(),
                placeholderComputation
            );
        computationsInText.push(computationInText);
        rewrittenTemplate = rewrittenTemplate.replace('%$', `<computation data-id="${computationInText.id}">${computationInText.computation.getName()}</computation>`);
        nextOccurrence = rewrittenTemplate.indexOf('%$');
        idx++;
    }

    return new TextComputation(
        rewrittenTemplate,
        false,
        computationsInText
    );
}
