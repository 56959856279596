import { BaseStore, getOrCompute, getOrComputeProperty, PropType } from '../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { MenuGroup, MenuItemStore } from './MenuItemStore';
import { ViewComponent } from '../ViewStack/Model/ViewComponent';
import { TextStore } from '../Text/TextStore';
import { mediumGrey, textColor, textSecondaryColor } from '../../../@Resource/Theme/Theme';
import { ListStore } from '../List/V2/ListStore';
import { ExpansionPanelListItemStore } from '../List/V2/ExpansionPanel/ExpansionPanelListItemStore';
import Text from '../Text/Text';
import List from '../List/V2/List';
import { ViewGroupStore } from '../ViewGroup/ViewGroupStore';
import { ViewGroupItem } from '../ViewGroup/ViewGroupItem';
import ListFragment from '../List/V2/Fragment/ListFragment';
import { ListFragmentStore } from '../List/V2/Fragment/ListFragmentStore';
import { createStringComparator } from '../List/V2/Comparator/StringComparator';
import { ButtonStore } from '../Button/ButtonStore';

type OnSelect<D> = (store: MenuItemStore<D>) => void;

export interface MenuProps<D>
{
    isVisible?: ((store: MenuStore) => boolean) | boolean;
    items?: ((store: MenuStore) => Array<MenuItemStore<D> | ButtonStore>) | Array<MenuItemStore<D> | ButtonStore>;
    groups?: PropType<MenuStore<D>, MenuProps<D>, MenuGroup[]>;
    itemProvider?: ((store: MenuStore) => Promise<Array<MenuItemStore<D> | ButtonStore>>) | Promise<Array<MenuItemStore<D> | ButtonStore>>;
    onSelect?: OnSelect<D>;
    maxHeight?: number;
}

const defaultProps: MenuProps<any> =
{
    isVisible: () => true
};

export class MenuStore<D = any> extends BaseStore<MenuProps<D>>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable providedItems: MenuItemStore[];
    @observable expandedItemStore: MenuItemStore;

    // ------------------------ Constructor -------------------------

    constructor(props: MenuProps<D>)
    {
        super(
            props,
            defaultProps);
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        if (this.props.itemProvider)
        {
            return getOrComputeProperty(this, 'itemProvider')
                .then(this.setProvidedItems);
        }
        else
        {
            return Promise.resolve();
        }
    }

    // -------------------------- Computed --------------------------

    @computed
    get isGrouped(): boolean
    {
        return this.groups && this.groups.length > 0;
    }

    @computed
    get isVisible(): boolean
    {
        return getOrComputeProperty(this, 'isVisible');
    }

    @computed
    get maxHeight(): number
    {
        return getOrCompute(this, this.props.maxHeight);
    }

    @computed
    get expansionView(): ViewComponent
    {
        if (this.expandedItemStore)
        {
            return getOrComputeProperty(this.expandedItemStore, 'expansionView');
        }
        else
        {
            return null;
        }
    }

    @computed
    get isExpandedInChild(): boolean
    {
        return this.expansionView != null
            && this.expansionView.store instanceof MenuStore
            && this.expansionView.store.expansionView != null;
    }

    @computed
    get groups(): MenuGroup[]
    {
        return getOrCompute(this, this.props.groups);
    }
    // --------------------------- Stores ---------------------------

    @computed
    get itemStores(): Array<MenuItemStore | ButtonStore>
    {
        return getOrComputeProperty(this, 'items') || this.providedItems || [];
    }

    @computed
    get listStore(): ListStore<MenuItemStore<D> | ButtonStore, Array<MenuItemStore<D> | ButtonStore>, ExpansionPanelListItemStore<MenuItemStore<D> | ButtonStore>, MenuItemStore<D> | ButtonStore, MenuGroup, {}>
    {
        return new ListStore<MenuItemStore<D> | ButtonStore, Array<MenuItemStore<D> | ButtonStore>, ExpansionPanelListItemStore<MenuItemStore<D> | ButtonStore>, MenuItemStore<D> | ButtonStore, MenuGroup, {}>({
            id:
                menuItem =>
                    menuItem.id,
            load:
                () =>
                    Promise.resolve(
                        this.itemStores.filter(item => item.isVisible)),
            group:
                store =>
                {
                    if (this.groups)
                    {
                        return {
                            id:
                                menuGroup =>
                                    menuGroup.id,
                            group:
                                    menuItemStore =>
                                        menuItemStore instanceof MenuItemStore
                                            ?
                                                this.groups.find(group => group.id ===  menuItemStore.groupId)
                                            :
                                                undefined,
                            template:
                                groupStore =>
                                    new ViewComponent(
                                        List,
                                        new ViewGroupStore({
                                            items:
                                                [
                                                    new ViewGroupItem({
                                                        id: 'Details.tsx',
                                                        spacing: 0,
                                                        isHidden:
                                                            () =>
                                                                !groupStore.value || !groupStore.value.name,
                                                        view:
                                                            new ViewComponent(
                                                                List as any,
                                                                new ViewGroupStore({
                                                                    direction: 'horizontal',
                                                                    items:
                                                                        [
                                                                            new ViewGroupItem({
                                                                                id: 'group_name',
                                                                                growRatio: 1,
                                                                                view:
                                                                                    new ViewComponent(
                                                                                        Text as any,
                                                                                        new TextStore({
                                                                                            label: groupStore.value ? groupStore.value.name : '',
                                                                                            weight: 'bold',
                                                                                            variant: 'body2',
                                                                                            color: textColor
                                                                                        }))
                                                                            })
                                                                        ],
                                                                    style:
                                                                        () =>
                                                                            ({
                                                                                padding: '12px 16px',
                                                                                alignItems: 'center'
                                                                            })
                                                                }))
                                                    }),
                                                    new ViewGroupItem({
                                                        id: 'List',
                                                        spacing: 0,
                                                        view:
                                                            new ViewComponent(
                                                                ListFragment,
                                                                new ListFragmentStore<any>(store, groupStore.itemStores))
                                                    })
                                                ]
                                        })),

                            comparator:
                                createStringComparator(
                                    group =>
                                        group
                                            ?
                                                group.name || ''
                                            :
                                                '')

                        };
                    }
                    else
                    {
                        return undefined;
                    }
                },
            construct:
                menuItem =>
                    new ExpansionPanelListItemStore<MenuItemStore<D> | ButtonStore>({
                        isHidden:
                            () => !menuItem.isVisible,
                        expansionPanel:
                            itemStore =>
                                ({
                                    isExpandable: false,
                                    hasBorderLeftRight: false,
                                    hasBorderTop:
                                        this.isGrouped
                                            ?
                                                false
                                            :
                                                true,
                                    hasBorderBottom:
                                        this.isGrouped
                                            ?
                                                false
                                            :
                                                true,
                                    summaryIcon:
                                        () =>
                                            menuItem.iconStore,
                                    summaryAvatar:
                                        () =>
                                            menuItem.avatar,
                                    summaryCheckbox:
                                        () =>
                                            menuItem instanceof MenuItemStore
                                                ?
                                                    menuItem.checkbox
                                                :
                                                    undefined,
                                    summaryPrimaryText:
                                        () =>
                                            menuItem instanceof MenuItemStore
                                                ?
                                                    new TextStore({
                                                        label:
                                                            () => menuItem.name,
                                                        color:
                                                            () =>
                                                                menuItem.isDisabled ? textSecondaryColor : menuItem.color,
                                                        style:
                                                            {
                                                                whiteSpace: 'nowrap'
                                                            }
                                                    })
                                                :
                                                    menuItem.label instanceof TextStore
                                                        ?
                                                            menuItem.label
                                                        :
                                                            menuItem.label
                                                                ?
                                                                    new TextStore({
                                                                        label:
                                                                            () => menuItem.label as string,
                                                                        style:
                                                                            {
                                                                                whiteSpace: 'nowrap'
                                                                            }
                                                                    })
                                                                :
                                                                    new TextStore({
                                                                        label:
                                                                            () => menuItem.tooltip as string,
                                                                        style:
                                                                            {
                                                                                whiteSpace: 'nowrap'
                                                                            }
                                                                    }),
                                    summarySecondaryText:
                                        () =>
                                            menuItem instanceof MenuItemStore
                                                ?
                                                    new TextStore({
                                                        label: menuItem.description})
                                                :
                                                    undefined,
                                    summaryButtons:
                                        menuItem instanceof MenuItemStore
                                            ?
                                                menuItem.actions
                                            :
                                                undefined,
                                    hoverBackgroundColor: mediumGrey,
                                    onClick:
                                        () =>
                                        {
                                            if (!itemStore.data.isDisabled)
                                            {
                                                if (menuItem instanceof MenuItemStore)
                                                {
                                                    if (menuItem.isExpandable)
                                                    {
                                                        if (this.expandedItemStore)
                                                        {
                                                            this.collapse();
                                                        }
                                                        else
                                                        {
                                                            this.expand(menuItem);
                                                        }
                                                    }
                                                    else
                                                    {
                                                        this.selectItem(menuItem);
                                                    }

                                                    menuItem.handleClick();
                                                }
                                                else
                                                {
                                                    menuItem.click(null);
                                                }

                                                return Promise.resolve();
                                            }
                                        },
                                    isExpanded:
                                        () =>
                                            menuItem instanceof ButtonStore
                                                ?
                                                    menuItem.popperView
                                                        ?
                                                            true
                                                        :
                                                            false
                                                :
                                                    undefined,
                                    detailView:
                                        () =>
                                            menuItem instanceof ButtonStore
                                                ?
                                                    menuItem.popperView
                                                        ?
                                                            menuItem.popperView
                                                        :
                                                            undefined
                                                :
                                                    undefined
                                })

                    })
        });
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    setProvidedItems(items: MenuItemStore[])
    {
        this.providedItems = items;
    }

    @action.bound
    selectItem(item: MenuItemStore<D>)
    {
        if (this.props.onSelect)
        {
            this.props.onSelect(item);
        }
    }

    @action.bound
    expand(itemStore: MenuItemStore)
    {
        this.expandedItemStore = itemStore;
    }

    @action.bound
    collapse()
    {
        this.expandedItemStore = null;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
