import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Icon from '../../../../../../../@Future/Component/Generic/Icon/Icon';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../Type/Api/useTypes';
import styles from './Item.module.scss';
import { FileValue } from '../../../../../DataObject/Type/File/FileValue';
import { observer, useComputed } from 'mobx-react-lite';
import PlainList from '../../../../List/V2/Plain/PlainList';
import NameEditor from './NameEditor/NameEditor';
import DetailsLayout from '../../../../Viewer/Shared/Details/DetailsLayout/DetailsLayout';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { updateRelationship } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import NoteTextEditor from '../../../Note/Editor/TextEditor/NoteTextEditor';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { constructEntityOfType } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';

export interface ItemClasses
{
    root?: string;
}

export interface ItemProps
{
    attachment: Entity;
    classes?: ItemClasses;
    list?: boolean;
    commitContext?: CommitContext;
}

const Item: React.FC<ItemProps> =
    props =>
    {
        const types = useTypes();

        const commitContext = useNewCommitContext(props.commitContext);
        const [ preview, setPreview ] = useState<string>();
        const [ notes ] = useState<Entity[]>();

        const fileValue =
            useMemo(
                () =>
                {
                    if (props.attachment)
                    {
                        const value =
                            props.attachment.getDataObjectValueByField(
                                types.Attachment.Field.File,
                                undefined,
                                commitContext
                            );

                        if (value && value.getValue() instanceof FileValue)
                        {
                            return value.getValue();
                        }
                        else
                        {
                            return undefined;
                        }
                    }
                },
                [
                    props.attachment,
                    types.Attachment.Field.File,
                    commitContext,
                ]);

        const filesize =
            useComputed(
                () =>
                {
                    if (fileValue && fileValue instanceof FileValue)
                    {
                        return fileValue.humanReadableFilesize;
                    }
                    else
                    {
                        return '-';
                    }
                },
                [
                    fileValue
                ]);

        useEffect(
            () =>
            {
                if (fileValue && fileValue instanceof FileValue)
                {
                    if (fileValue.file.type.indexOf("image") === 0)
                    {
                        const reader = new FileReader();
                        reader.onload = event =>
                        {
                            setPreview((event.target as any).result);
                        };

                        reader.readAsDataURL(fileValue.file);
                    }
                }

            },
            [
                fileValue
            ]);

        const [ note, setNote ] = useState<string | undefined>(undefined);

        useEffect(
            () =>
            {
                let noteEntity =
                    props.attachment.getRelatedEntityByDefinition(
                        false,
                        types.Entity.RelationshipDefinition.Notes,
                        commitContext
                    );

                if (note)
                {
                    if (!noteEntity)
                    {
                        noteEntity =
                            constructEntityOfType(
                                types.Note.Type,
                                commitContext
                            );
                        updateRelationship(
                            noteEntity,
                            true,
                            types.Entity.RelationshipDefinition.Notes,
                            props.attachment,
                            commitContext
                        );
                    }

                    setValueByFieldInEntity(
                        noteEntity,
                        types.Note.Field.Content,
                        note,
                        commitContext
                    );
                }
                else
                {
                    if (noteEntity)
                    {
                        updateRelationship(
                            noteEntity,
                            true,
                            types.Entity.RelationshipDefinition.Notes,
                            undefined,
                            commitContext
                        );
                        commitContext.deleteEntity(noteEntity);

                        console.log(commitContext);
                    }
                }
            },
            [
                props.attachment,
                types,
                commitContext,
                note,
            ]
        );

        const onCloseDetailsEditMode = useCallback(() => {}, []);

        return <div
            className={
                classNames(
                    styles.root,
                    props.list && styles.listItem
                )}
        >
            <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={0}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            {
                                fileValue &&
                                <NameEditor
                                    fileValue={fileValue}
                                />
                            }
                        </ViewGroupItem>
                        <ViewGroupItem>
                                    <span
                                        className={styles.filesize}
                                    >
                                        {filesize}
                                    </span>
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem
                    className={preview && styles.preview}
                >
                    {
                        preview &&
                            <img
                                src={preview}
                                alt="preview"
                            />
                    }
                    {
                        !preview &&
                            <Icon
                                className={styles.icon}
                                size={100}
                                icon="attach_file"
                            />
                    }
                </ViewGroupItem>
                <ViewGroupItem
                    ratio={1}
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={15}
                    >
                        <ViewGroupItem>
                            <DetailsLayout
                                entity={props.attachment}
                                showNoneIfNoLayout
                                isInEditMode={false}
                                onCloseEditMode={onCloseDetailsEditMode}
                                commitContext={commitContext}
                            />
                        </ViewGroupItem>
                        {
                            props.attachment &&
                                <ViewGroupItem>
                                    <NoteTextEditor
                                        value={note}
                                        onChange={setNote}
                                    />
                                </ViewGroupItem>
                        }
                        {
                            notes &&
                                <ViewGroupItem>
                                    <PlainList
                                        entities={notes}
                                    />
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                </ViewGroupItem>
            </ViewGroup>
        </div>;
    };

export default observer(Item);
