import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { default as InternalMenuButton } from '../../../Item/MenuButton/MenuButton';
import RouterContext from '../../../../../../@Service/Router/RouterContext';

export interface MenuButtonProps
{
    entity: Entity;
    small?: boolean;
}

const MenuButton: React.FC<MenuButtonProps> =
    props =>
    {
        const routerStore = useContext(RouterContext);
        const goBack =
            useCallback(
                () =>
                    routerStore.goBack(),
                [
                    routerStore
                ]);

        return <InternalMenuButton
            entity={props.entity}
            onDelete={goBack}
            small={props.small}

        >
            {props.children}
        </InternalMenuButton>;
    };

export default observer(MenuButton);
