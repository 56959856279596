import React from 'react';
import { LogQueryResult } from '../Model/LogQueryResult';
import { LogEventRow } from './Row/LogEventRow';

export interface LogEventPageProps
{
    page: LogQueryResult;
}

export const LogEventPage: React.FC<LogEventPageProps> =
    ({
         page,
     }) =>
    {
        return <>
            {
                page.events.map(
                    event =>
                        <LogEventRow
                            key={event.id}
                            event={event}
                        />
                )
            }
        </>;
    };
