import * as React from 'react';
import { observer } from 'mobx-react';
import { EntityBucketSummaryStore } from './EntityBucketSummaryStore';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { mediumGrey } from '../../../../@Resource/Theme/Theme';
import Input from '../Input/Input';

@observer
export class EntityBucketSummary extends BaseComponent<EntityBucketSummaryStore>
{
    renderComponent(store: EntityBucketSummaryStore)
    {
        if (store.showEntitySummaryFields)
        {
            return <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    borderTop: store.displayInList ? `${mediumGrey} 1px solid` : undefined,
                    marginRight: store.displayInList ? 5 : undefined,
                    marginLeft: store.displayInList ? 58 : undefined
                }}
            >
                {
                    store.favoriteEntityValues.map(
                        value =>
                            <div
                                key={value.id}
                                style={{
                                    width: '50%',
                                    minWidth: 40,
                                    paddingRight: 10
                                }}
                            >
                                <Input
                                    entity={store.transactionalEntity}
                                    field={value}
                                    labelPosition="top"
                                />
                            </div>)
                }
            </div>;
        }
        else
        {
            return null;
        }
    }
}
