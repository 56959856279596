import React from 'react';
import { User } from '../../../../../../../../../@Api/Model/Implementation/User';
import Dialog from '../../../../../../../../../@Future/Component/Generic/Dialog/Dialog';
import DialogTitle from '../../../../../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Icon from '../../../../../../../../../@Future/Component/Generic/Icon/Icon';
import { dangerColor } from '../../../../../../../../../@Resource/Theme/Theme';
import LocalizedText from '../../../../../../../../Domain/Localization/LocalizedText/LocalizedText';
import Divider from '../../../../../../../../../@Future/Component/Generic/Divider/Divider';
import DialogContent from '../../../../../../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import CardInset from '../../../../../../../../../@Future/Component/Generic/Card/CardInset';

export interface NoLicenseErrorDialogProps
{
    user: User;
    onClose: () => void;
}

export const NoLicenseErrorDialog: React.FC<NoLicenseErrorDialogProps> =
    ({
         user,
         onClose,
     }) =>
    {
        return <Dialog
            width={'xs'}
            onClose={onClose}
            hideCloseButton={false}
            open
        >
            <DialogTitle>
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                >
                    <ViewGroupItem>
                        <Icon
                            icon="remove_circle"
                            color={dangerColor}
                            size={'32px'}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <LocalizedText
                            code="EnvironmentUser.LicenseExpired"
                            value="Je gebruikerslicentie is verlopen"
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <CardInset
                    left={false}
                >
                    <LocalizedText
                        code="EnvironmentUser.AccessBlocked"
                        value="De toegang tot ${environment} omgeving is geblokkeerd"
                        environment={user.organization.name}
                    />
                </CardInset>
            </DialogContent>
        </Dialog>;
    }

