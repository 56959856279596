import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { runInAction } from 'mobx';
import DrawingFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/DrawingFormInputLayout';
import NumberEditor from '../../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';
import FormInputPlaceholderEditor from '../Shared/FormInputPlaceholderEditor/FormInputPlaceholderEditor';
import FormInputIsDisabledEditor from '../Shared/FormInputIsDisabledEditor/FormInputIsDisabledEditor';
import FormInputParameterViewer from '../Shared/FormInputParameterViewer/FormInputParameterViewer';

export interface DrawingFormInputControlsProps extends LayoutEditorProps<DrawingFormInputLayout>
{

}

const DrawingFormInputLayoutControls: React.FC<DrawingFormInputControlsProps> =
    props =>
    {
        const setHeight =
            useCallback(
                (height: number = 0) =>
                    runInAction(
                        () =>
                            props.layout.height = Math.max(height, 50)),
                [
                    props.layout
                ])

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <FormInputParameterViewer
                    {...props}
                />
                <FormInputPlaceholderEditor
                    {...props}
                />
                <FormInputIsDisabledEditor
                    {...props}
                />
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.Height"
                            value="Hoogte"
                        />
                    }
                >
                    <NumberEditor
                        value={props.layout.height}
                        onChange={setHeight}
                        min={50}
                    />
                </Input>
            </InputGroup>
        </CardInset>;
    };

export default observer(DrawingFormInputLayoutControls);
