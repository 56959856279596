import { BespokeEntityType } from '../../../BespokeEntityType';
import { EntityTypeStore } from '../../../EntityTypeStore';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationshipDefinition } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import copyProductLines from '../../../../../../../@Api/Entity/Bespoke/Activity/copyProductLines';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';

export class BespokeActivitySalesOrder extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Activity.SalesOrder')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        // In case of linking a new project to an activity, then:
        // - copy the subject
        if (entity.isNew()
            && isParent
            && relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities
            && relatedEntity)
        {
            // Copy product lines in case of a sales opportunity (non-billed ones)
            // E.g. in case of a project, then we manually decide which lines are copied
            if ((relatedEntity.entityType.isA(this.types.Activity.SalesOpportunity.Type)
                || relatedEntity.entityType.isA(this.types.Activity.WorkOrder.Type))
                && entity.getRelatedEntitiesByDefinition(false, this.types.Activity.RelationshipDefinition.ProductLines, commitContext).length === 0)
            {
                await copyProductLines(
                    relatedEntity,
                    entity,
                    line =>
                        !line.getObjectValueByField(this.types.ProductLine.Field.IsBilled),
                    commitContext
                );
            }

            // Copy fields
            [
                this.types.Activity.Field.Subject,
                this.types.Activity.Field.DiscountPercentage,
                this.types.Activity.Field.IsVatIncluded,
                this.types.Activity.Field.Currency,
            ].forEach(
                field =>
                    setValueByFieldInEntity(
                        entity,
                        field,
                        relatedEntity.getObjectValueByField(field),
                        commitContext
                    )
            );
        }
    }

    hideFieldPath(entity: Entity,
                  fieldPath: EntityFieldPath,
                  fromFieldPath?: EntityFieldPath,
                  isInConstructor?: boolean,
                  commitContext?: CommitContext): boolean
    {
        return (super.hideFieldPath(entity, fieldPath, fromFieldPath, isInConstructor, commitContext)
            && fieldPath.field !== this.entityTypeStore.bespoke.types.Activity.Field.Number);
    }

    // ----------------------- Private logic ------------------------
}
