import React, { useCallback } from 'react';
import NumberEditor from '../../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';

interface RecurrenceIntervalEditorProps
{
    value: number;
    onChange: (value: number) => void;
    disabled?: boolean;
}

export const RecurrenceIntervalEditor: React.FC<RecurrenceIntervalEditorProps> =
    ({
        value,
        onChange,
        disabled,
     }) =>
    {
        const onBlur = useCallback(
            (v?: number) =>
            {
                if (v === undefined || v < 1)
                {
                    onChange(1);
                }
            },
            [
                onChange
            ]
        );

        return <NumberEditor
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            min={1}
            decimalScale={0}
            disabled={disabled}
        />;
    };
