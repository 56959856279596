import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Item from '../../../Item/Item';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';

export interface CardedListProps
{
    entities: Entity[];
}

const CardedList: React.FC<CardedListProps> =
    props =>
    {
        return <ViewGroup
            orientation="vertical"
            spacing={7}
        >
            {
                props.entities.map(
                    entity =>
                        <ViewGroupItem
                            key={entity.uuid}
                        >
                            <Card>
                                <Item
                                    entity={entity}
                                    horizontalInset
                                    verticalInset
                                    hideFavoriteButton
                                />
                            </Card>
                        </ViewGroupItem>)
            }
        </ViewGroup>;
    };

export default observer(CardedList);
