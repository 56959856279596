import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { CronEditorProps } from '../CronEditor';
import InputGroup from '../../InputGroup/InputGroup';
import Input from '../../Input/Input';
import DailyCronEditor from './DailyCronEditor';
import Checkbox from '../../Checkbox/Checkbox';
import ViewGroup from '../../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../../ViewGroup/ViewGroupItem';
import { getCronDays } from '../../../../../../@Util/Date/getCronDays';

export interface WeeklyCronEditorProps extends CronEditorProps
{

}

const WeeklyCronEditor: React.FC<WeeklyCronEditorProps> =
    props =>
    {
        const { onChange} = props;
        const selectedCronDays =
            useMemo(
                () =>
                    new Set(props.value.split(' ')[5].split(',')),
                [
                    props.value
                ]);

        const onChangeCallback =
            useCallback(
                (cronDay: string, isChecked: boolean) =>
                {
                    const newSelectedCronDays =
                        Array.from(selectedCronDays);

                    if (isChecked)
                    {
                        newSelectedCronDays.push(cronDay);
                    }
                    else
                    {
                        if (newSelectedCronDays.length > 1)
                        {
                            newSelectedCronDays.splice(
                                newSelectedCronDays.indexOf(cronDay),
                                1);
                        }
                    }

                    const split = props.value.split(' ');
                    split.splice(
                        5,
                        1,
                        newSelectedCronDays.join(','));

                    onChange(split.join(' '));
                },
                [
                    onChange,
                    props.value,
                    selectedCronDays
                ]);

        const cronDays =
            useMemo(
                () =>
                    {
                        return getCronDays();
                    },
                [

                ]
            );

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <InputGroup>
                    {
                        cronDays.map(
                            cronDay =>
                                <Input
                                    key={cronDay.id}
                                    label={cronDay.name}
                                    labelPosition="left"
                                >
                                    <Checkbox
                                        checked={selectedCronDays.has(cronDay.id)}
                                        onToggle={isChecked => onChangeCallback(cronDay.id, isChecked)}
                                    />
                                </Input>)
                    }
                </InputGroup>
            </ViewGroupItem>
            <ViewGroupItem>
                <DailyCronEditor
                    {...props}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(WeeklyCronEditor);
