import React, { useCallback, useEffect, useState } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import styles from './ProductsInvoiceEditor.module.scss';
import useTypes from '../../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import Centered from '../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../@Future/Component/Generic/Loader/Loader';
import ProductLines from '../ProductLines/ProductLines';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import Tab from '../../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import TabBar from '../../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import useAggregateResult from '../../../../../Selection/Hooks/useAggregateResult';
import { Aggregate } from '../../../../../../DataObject/Model/Aggregate';
import { DataObject } from '../../../../../../DataObject/Model/DataObject';
import { HighGranularityCompactRepresentation } from '../../../../../../DataObject/Util/Representation/HighGranularityCompactRepresentation';
import useIsMobile from '../../../../../../../../@Util/Responsiveness/useIsMobile';
import CloseButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/CloseButton/CloseButton';
import { TabLabel } from '../../../../../../../../@Future/Component/Generic/TabBar/Tab/TabLabel/TabLabel';
import useSetting from '../../../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../../../Setting/SettingStore';
import { Setting } from '../../../../../../../../@Api/Settings/Setting';
import ChildActivitiesProductEditor from '../ChildActivitiesProductEditor/ChildActivitiesProductEditor';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import useDividerGlue from '../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import { default as EntityInput } from '../../../../../Input/Input';
import ProductLinesProcessingOptions from './ProductLinesProcessingOptions';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { DataObjectRepresentation } from '../../../../../../DataObject/Model/DataObjectRepresentation';
import CurrencyEditor from '../../../../../Currency/CurrencyEditor';

export interface ProductsInvoiceEditorProps
{
    relationship: Entity;
    entity: Entity;
    path: EntityPath;
    onClose?: () => void;
    milestone?: Entity;
}

const ProductsInvoiceEditor: React.FC<ProductsInvoiceEditorProps> =
    props =>
    {
        const types = useTypes();

        const [ isLoading ] = useState(false);
        const [ tab, setTab ] = useState(0);
        const [ selectedInvoiceLines, setSelectedInvoiceLines ] = useState<Set<Entity>>(() => new Set());

        const currency =
            useEntityValue<string>(
                props.entity,
                types.Activity.Field.Currency);

        const [ isSalesOrderEnabled ] =
            useSetting<boolean>(
                SettingSource.Organization,
                Setting.Invoicing.IsSalesOrderEnabled);

        useEffect(
            () =>
            {
                setSelectedInvoiceLines(new Set());
            },
            [
                setSelectedInvoiceLines,
                props.milestone,
                tab
            ]);

        const onSelectInvoiceLines =
            useCallback(
                (lines: Entity[], selected: boolean) =>
                {
                    if (selected)
                    {
                        const newLines = new Set(
                            [
                                ...Array.from(selectedInvoiceLines),
                                ...lines
                            ]
                        );
                        setSelectedInvoiceLines(newLines);
                    }
                    else
                    {
                        const newLines = new Set(
                            Array.from(selectedInvoiceLines)
                                .filter(line => !lines.includes(line))
                        );
                        setSelectedInvoiceLines(newLines);
                    }
                },
                [
                    selectedInvoiceLines,
                    setSelectedInvoiceLines
                ]);

        const onSelectInvoiceLine =
            useCallback(
                (invoiceLine: Entity, selected: boolean) =>
                {
                    onSelectInvoiceLines([invoiceLine], selected);
                },
                [
                    onSelectInvoiceLines
                ]);

        const totalToInvoiceResult =
            useAggregateResult(
                types.ProductLine.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.RelationshipDefinition.ProductLines,
                                        true),
                                    props.entity))
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.ProductLine.Field.IsBilled),
                                    undefined,
                                    false))
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.ProductLines,
                                                true),
                                            props.milestone)))
                        .aggregateOn(
                            rootPath.field(
                                currency
                                    ? types.ProductLine.Field.TotalInCurrency
                                    : types.ProductLine.Field.Total
                            ),
                            undefined,
                            Aggregate.Sum),
                [
                    types,
                    props.entity,
                    props.milestone,
                    currency,
                ]);

        const totalToInvoice =
            useComputed(
                () =>
                {
                    if (totalToInvoiceResult && !totalToInvoiceResult.aggregates[0].isEmpty)
                    {
                        return totalToInvoiceResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Currency', 0);
                    }
                },
                [
                    totalToInvoiceResult
                ]);

        const totalInvoicedResult =
            useAggregateResult(
                types.ProductLine.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.RelationshipDefinition.ProductLines,
                                        true),
                                    props.entity))
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.ProductLine.Field.IsBilled),
                                    undefined,
                                    true))
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.ProductLines,
                                                true),
                                            props.milestone)))
                        .aggregateOn(
                            rootPath.field(
                                currency
                                    ? types.ProductLine.Field.TotalInCurrency
                                    : types.ProductLine.Field.Total
                            ),
                            undefined,
                            Aggregate.Sum),
                [
                    types,
                    props.entity,
                    props.milestone,
                    currency,
                ]);

        const totalInvoiced =
            useComputed(
                () =>
                {
                    if (totalInvoicedResult && !totalInvoicedResult.aggregates[0].isEmpty)
                    {
                        return totalInvoicedResult.aggregates[0];
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue('Currency', 0);
                    }
                },
                [
                    totalInvoicedResult
                ]);

        const isCompact = useIsMobile();

        const numberOfProductLines =
            useEntityValue(
                props.entity,
                types.Activity.Field.NumberOfProductLines,
                0
            );

        const header =
            <ViewGroup
                orientation="horizontal"
                spacing={15}
                alignment="center"
            >
                <ViewGroupItem
                    ratio={1}
                />
                <ViewGroupItem>
                    <CurrencyEditor
                        entity={props.entity}
                        field={types.Activity.Field.Currency}
                        priceListRelationshipDefinition={types.Activity.RelationshipDefinition.PriceList}
                        disabled={numberOfProductLines > 0}
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <EntityInput
                        entity={props.entity}
                        field={types.Activity.Field.IsVatIncluded}
                        labelPosition="right"
                    />
                </ViewGroupItem>
                {
                    props.onClose &&
                        <ViewGroupItem>
                            <CloseButton
                                onClick={props.onClose}
                            />
                        </ViewGroupItem>
                }
            </ViewGroup>;

        const [ isLoadingProductLines, setLoadingProductLines ] = useState(false);
        const dividerGlue = useDividerGlue();

        if (isLoading)
        {
            return <Card
                inset
            >
                <Centered
                    horizontal
                >
                    <Loader />
                </Centered>
            </Card>
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <Card>
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                            glue={dividerGlue}
                        >
                            <ViewGroupItem>
                                <div
                                    className={styles.header}
                                >
                                    {
                                        isCompact &&
                                            header
                                    }
                                    <TabBar
                                        value={tab}
                                    >
                                        <Tab
                                            value={0}
                                            onClick={setTab}
                                        >
                                            <TabLabel
                                                label={
                                                    isSalesOrderEnabled
                                                    ?
                                                        <LocalizedText
                                                            code="InvoiceEditor.ToBeProcessed"
                                                            value="Te verwerken"
                                                        />
                                                    :
                                                        <LocalizedText
                                                            code="InvoiceEditor.ToBeInvoiced"
                                                            value="Te factureren"
                                                        />
                                                }
                                                secondary={totalToInvoice.toString(
                                                    new DataObjectRepresentation({
                                                        ...HighGranularityCompactRepresentation,
                                                        currency: currency
                                                    })
                                                )}
                                            />
                                        </Tab>
                                        <Tab
                                            value={1}
                                            onClick={setTab}
                                        >
                                            <TabLabel
                                                label={
                                                    isSalesOrderEnabled
                                                    ?
                                                        <LocalizedText
                                                            code="InvoiceEditor.ProcessedInSalesOrders"
                                                            value="Verwerkt in verkooporders"
                                                        />
                                                    :
                                                        <LocalizedText
                                                            code="InvoiceEditor.ProcessedInInvoices"
                                                            value="Verwerkt in facturen"
                                                        />
                                                }
                                                secondary={totalInvoiced.toString(
                                                    new DataObjectRepresentation({
                                                        ...HighGranularityCompactRepresentation,
                                                        currency: currency
                                                    })
                                                )}
                                            />
                                        </Tab>
                                    </TabBar>
                                    {
                                        !isCompact &&
                                            <div
                                                className={styles.menu}
                                            >
                                                {header}
                                            </div>
                                    }
                                </div>
                                {
                                    isLoadingProductLines
                                        ?
                                            <CardInset>
                                                <Centered
                                                    horizontal
                                                >
                                                    <Loader />
                                                </Centered>
                                            </CardInset>
                                        :
                                            <ProductLines
                                                entity={props.entity}
                                                selectedProductLines={selectedInvoiceLines}
                                                onSelectProductLine={onSelectInvoiceLine}
                                                onSelectAllProductLines={onSelectInvoiceLines}
                                                milestone={props.milestone}
                                                showMilestone={props.milestone === undefined}
                                                billed={tab === 1}
                                            />
                                }
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ProductLinesProcessingOptions
                                    {...props}
                                    invoiceLines={selectedInvoiceLines}
                                    onChangeInvoiceLines={setSelectedInvoiceLines}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </Card>
                </ViewGroupItem>
                {
                    tab >= 0 &&
                        <ViewGroupItem>
                            <ChildActivitiesProductEditor
                                entity={props.entity}
                                path={props.path}
                                onLoad={setLoadingProductLines}
                            />
                        </ViewGroupItem>
                }
            </ViewGroup>;
        }
    };

export default observer(ProductsInvoiceEditor);
