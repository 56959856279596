import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { TableCell, TableRow } from '@material-ui/core';
import { PortalUser } from './PortalUserList';
import { format } from 'date-fns';


export interface PortalUserListRowProps
{
    portalUser: PortalUser;
}

export const PortalUserListRow: React.FC<PortalUserListRowProps> =
    observer(
        ({
             portalUser,
         }) =>
        {
            const formatDate =
                useCallback(
                    (date) =>
                        date
                            ? format(new Date(date), 'dd-MM-yyyy HH:mm:ss')
                            : '',
                    []
                );

            const activationDate =
                useMemo(
                    () =>
                        formatDate(portalUser?.activationDate),
                    [
                        portalUser,
                    ]
                );

            const lastActivityDate =
                useMemo(
                    () =>
                        formatDate(portalUser?.lastActivityDate),
                    [
                        portalUser,
                    ]
                );

            return <TableRow>
                <TableCell>
                    {portalUser.emailAddress}
                </TableCell>
                <TableCell>
                    {portalUser?.hasTwoFactorAuthentication}
                </TableCell>
                <TableCell>
                    {portalUser.contact}
                </TableCell>
                <TableCell>
                    {portalUser.relationship}
                </TableCell>
                <TableCell>
                    {activationDate}
                </TableCell>
                <TableCell>
                    {lastActivityDate}
                </TableCell>
            </TableRow>;
        }
    );
