import ResourceSelection from '../Model/ResourceSelection';
import { EntityPath } from '../../Path/@Model/EntityPath';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../../DataObject/Model/Comparator';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import getTypes from '../../Type/Api/getTypes';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ResourcePlanner from '../Model/ResourcePlanner';
import { getTimeRegistrationsForMilestone } from './getTimeRegistrationsForMilestone';

export function getMilestonesForProjectAndUser(
    timeRegistrationParameter: Parameter<EntityValueType>,
    projectParameter: Parameter<EntityValueType>,
    employeeParameter: EntityValue
) {

    const types = getTypes();

    const milestoneParameter =
        new Parameter(
            'MilestoneResource',
            new EntityValueType(types.Milestone.Type),
            true,
            types.Milestone.Type.getName()
        );

    return new ResourcePlanner(
        milestoneParameter,
        new ParameterDictionary([]),

        // ResourceFilter: Milestones related to the current Project
        new ComparisonPredicate(
            new ValueFromEntityComputation(
                milestoneParameter,
                EntityPath.fromEntityType(types.Milestone.Type)
                    .joinTo(
                        types.Activity.Project.RelationshipDefinition.Milestones,
                        true)
                    .field()
            ),
            Comparator.Equals,
            projectParameter
        ),

        // ResourceSelection: Timeregistration entities related to CurrentUser to show aggregated on milestone rows
        [
            types.TimeRegistration.Type &&
                new ResourceSelection(
                    timeRegistrationParameter,
                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                        .joinTo(
                            types.Milestone.RelationshipDefinition.TimeRegistrations,
                            true
                        ),
                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                        .field(types.TimeRegistration.Field.StartDate),
                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                        .field(types.TimeRegistration.Field.EndDate),
                    new CompositePredicate(
                        LogicalOperator.And,
                        [
                            // Timeregistrations related to project
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    timeRegistrationParameter,
                                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                                        .joinTo(
                                            types.Activity.RelationshipDefinition.TimeRegistrations,
                                            true
                                        )
                                        .field()
                                ),
                                Comparator.Equals,
                                projectParameter
                            ),
                            // Timeregistrations related to milestone
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    timeRegistrationParameter,
                                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                                        .joinTo(
                                            types.Milestone.RelationshipDefinition.TimeRegistrations,
                                            true
                                        )
                                        .field()
                                ),
                                Comparator.Equals,
                                milestoneParameter
                            ),
                            // Timeregistrations related to employee
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    timeRegistrationParameter,
                                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                                        .joinTo(
                                            types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                            true
                                        )
                                        .field()
                                ),
                                Comparator.Equals,
                                employeeParameter
                            )
                        ]
                    ),
                    undefined,
                    undefined,
                    undefined,
                    true,
                    [
                        EntityPath.fromEntityType(types.TimeRegistration.Type)
                            .joinTo(
                                types.TimeRegistration.RelationshipDefinition.Activity,
                                false
                            )
                            .field(),
                        EntityPath.fromEntityType(types.TimeRegistration.Type)
                            .joinTo(
                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                true
                            )
                            .field()
                    ]
                )
        ],

        // ChildSelection
        [
            getTimeRegistrationsForMilestone(
                timeRegistrationParameter,
                projectParameter,
                milestoneParameter,
                employeeParameter
            ),
        ],
        true
    )
}