import React, { Fragment } from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import BlockOptionsPanel from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Settings/BlockOptionsPanel';
import ColorOptionsPanel from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Settings/ColorOptionsPanel';
import OrganizationLogo from '../../../Organization/OrganizationLogo';
import DimensionsOptionsPanel from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Settings/DimensionsOptionsPanel';
import GutenbergBlockType from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Gutenberg/GutenbergBlockType';
import { OrganizationLogoBlockId } from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/DocumentEditor';
import Icon from '../../../../../../@Future/Component/Generic/Icon/Icon';

const OrganizationLogoBlock: GutenbergBlockType =
    {
        name: OrganizationLogoBlockId,
        configuration:
            {
                title: {
                    code: 'GutenbergBlock.Logo',
                    value: 'Logo'
                },
                icon: <Icon icon="business" />,
                category: 'common',

                attributes:
                {
                    content:
                        {
                            type: 'string',
                            source: 'html',
                            selector: 'img',
                            query: undefined,
                        },
                },

                supports:
                {
                    align: true
                },

                edit: props =>
                {
                    const {
                        backgroundColor,
                        borderColor,
                        fontColor,
                        borderWidth,
                        borderRadius,
                        padding,
                        margin,
                        width,
                        widthUnit,
                        height,
                        heightUnit
                    } = props.attributes as any;

                    const containerStyles = {
                        padding: margin
                    };

                    const innerContainerStyles = {
                        backgroundColor: backgroundColor,
                        borderWidth: borderWidth ? borderWidth : undefined,
                        borderColor: borderWidth && borderColor ? borderColor : undefined,
                        borderStyle: borderWidth && borderColor ? 'solid' : undefined,
                        borderRadius: borderRadius,
                        color: fontColor,
                        padding: padding
                    };

                    return (
                        <Fragment>
                            <InspectorControls>
                                <DimensionsOptionsPanel props={props} open />
                                <BlockOptionsPanel props={props} />
                                <ColorOptionsPanel props={props} />
                            </InspectorControls>
                            <div
                                style={containerStyles}
                            >
                                <div
                                    style={innerContainerStyles}
                                >
                                    <OrganizationLogo
                                        width={width > 0 ? width : undefined}
                                        widthUnit={widthUnit}
                                        height={height > 0 ? height : undefined}
                                        heightUnit={heightUnit}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    );
                },

                save: props =>
                {
                    return <div></div>;
                }
            },

        view: block =>
        {
            return <div />;
        }
    };

export default OrganizationLogoBlock;
