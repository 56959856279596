import * as React from 'react';
import { ComputationType } from '../ComputationType';
import { ConditionalComputationEditorStore } from './ConditionalComputationEditorStore';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { ConditionalComputationEditor } from './ConditionalComputationEditor';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { ConditionalComputationSpecification } from './ConditionalComputationSpecification';
import { ComputationContext } from '../../ComputationContext';
import { ComputationTypeStore } from '../../ComputationTypeStore';
import { ComputationEditorStore } from '../../ComputationEditorStore';
import { DataObjectSpecification } from '../../../DataObject/Model/DataObjectSpecification';
import { ConditionalComputationConditionStore } from './Condition/ConditionalComputationConditionStore';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { PredicateEditorStore } from '../../../Predicate/PredicateEditorStore';
import { PredicateTypeStore } from '../../../Predicate/PredicateTypeStore';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';
import { EntityContext } from '../../../Entity/@Model/EntityContext';
import { ConditionalComputation } from './ConditionalComputation';
import { ConditionalComputationCondition } from './Condition/ConditionalComputationCondition';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';

export class ConditionalComputationType extends ComputationType<ConditionalComputationEditorStore, ConditionalComputationSpecification, ConditionalComputation>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('PredicateTypeStore') predicateTypeStore: PredicateTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return 'Conditional';
    }

    name(): string
    {
        return this.localizationStore.translate('Computation.ConditionalCalculation'); // Conditional calculation;
    }

    allow(context: ComputationContext): boolean
    {
        return true;
    }

    isTerminal(): boolean
    {
        return false;
    }

    isResource(): boolean
    {
        return false;
    }

    fromSpecification(specification: ConditionalComputationSpecification): ConditionalComputation
    {
        return new ConditionalComputation(
            specification.conditions.map(
                condition =>
                    new ConditionalComputationCondition(
                        this.predicateTypeStore.fromSpecification(condition.predicate),
                        this.computationTypeStore.fromSpecification(condition.computation))),
            specification.otherwise
                ?
                    this.computationTypeStore.fromSpecification(specification.otherwise)
                :
                    undefined);
    }

    toSpecification(computation: ConditionalComputation): ConditionalComputationSpecification
    {
        return {
            type: this.id(),
            conditions:
                computation.conditions.map(
                    operation =>
                        ({
                            predicate:
                                this.predicateTypeStore.toSpecification(operation.predicate),
                            computation:
                                this.computationTypeStore.toSpecification(operation.computation)
                        })),
            otherwise:
                computation.otherwise
                    ?
                        this.computationTypeStore.toSpecification(computation.otherwise)
                    :
                        undefined
        };
    }

    compute(context: ComputationContext,
            computation: ConditionalComputation): DataObject
    {
        let result: DataObject = null;
        let isFound = false;

        computation.conditions.forEach(
            condition =>
            {
                if (!isFound)
                {
                    if (condition.predicate.evaluate(context))
                    {
                        isFound = true;

                        result = condition.computation.compute(context);
                    }
                }
            });

        if (!isFound)
        {
            result = computation.otherwise.compute(context);
        }

        return result;
    }

    description(context: ComputationContext,
                specification: ConditionalComputationSpecification): string
    {
        return this.name();
    }

    editorStore(context: ComputationContext,
                specification: ConditionalComputationSpecification): ConditionalComputationEditorStore
    {
        return new ConditionalComputationEditorStore(
            this as any,
            context,
            specification,
            (specification.conditions || []).map(
                condition => (
                    new ConditionalComputationConditionStore(
                        PredicateEditorStore.construct(
                            context,
                            condition.predicate,
                            this.predicateTypeStore),
                        ComputationEditorStore.construct(
                            context,
                            condition.computation,
                            this.computationTypeStore),
                        null))),
            ComputationEditorStore.construct(
                context,
                specification.otherwise,
                this.computationTypeStore));
    }

    editorView(): React.ComponentClass<BaseComponentProps<ConditionalComputationEditorStore>>
    {
        return ConditionalComputationEditor;
    }

    editorSpecification(store: ConditionalComputationEditorStore): ConditionalComputationSpecification
    {
        return {
            type: this.id(),
            conditions: store.conditionStores.map(
                conditionStore => ({
                    predicate:
                        conditionStore.predicateStore
                            .type
                            .editorSpecification(conditionStore.predicateStore.editorStore),
                    computation:
                        conditionStore.computationStore
                            .type
                            .editorSpecification(conditionStore.computationStore.editorStore)
                })),
            otherwise:
                store.otherwiseStore
                    .type
                    .editorSpecification(store.otherwiseStore.editorStore)
        };
    }

    editorResultSpecification(store: ConditionalComputationEditorStore): DataObjectSpecification
    {
        if (store.conditionStores.length > 0)
        {
            return store.conditionStores[store.conditionStores.length - 1]
                .computationStore
                .type
                .editorResultSpecification(store.conditionStores[store.conditionStores.length - 1].computationStore);
        }
        else
        {
            return null;
        }
    }

    entityFieldPaths(specification: ConditionalComputationSpecification,
                     context: EntityContext,
                     parameter?: string): EntityFieldPath[]
    {
        let paths: EntityFieldPath[] = [];

        if (specification.conditions)
        {
            specification.conditions.forEach(
                operation =>
                {
                    let predicateType = this.predicateTypeStore.getTypeById(operation.predicate.type);

                    if (predicateType)
                    {
                        paths.push(
                            ...predicateType.entityFieldPaths(
                                operation.predicate,
                                context,
                                parameter));
                    }

                    let computationType = this.computationTypeStore.getTypeById(operation.computation.type);

                    if (computationType)
                    {
                        paths.push(
                            ...computationType.entityFieldPaths(
                                operation.computation,
                                context,
                                parameter));
                    }
                });
        }

        return paths;
    }

    // ----------------------- Private logic ------------------------
}
