import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../../Model/DataObjectType';
import { PercentageView } from './PercentageView';
import { PercentageEditor } from './PercentageEditor';
import { PercentageSpecification } from './PercentageSpecification';
import { NumberType } from '../NumberType';
import { DataObjectRepresentation } from '../../../Model/DataObjectRepresentation';
import numbro from 'numbro';
import { DataObjectOperatorOverload } from '../../../Model/DataObjectOperatorOverload';
import { MathematicalOperator } from '../../../Model/MathematicalOperator';
import { DataObject } from '../../../Model/DataObject';
import { DataObjectContext } from '../../../Model/DataObjectContext';
import { DataObjectOverloadType } from '../../../Model/DataObjectOverloadType';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export class PercentageType extends NumberType
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Percentage';
    }

    name()
    {
        return localizeText('DataObject.Type.Percentage', 'Percentage');
    }

    view(): DataObjectViewerType
    {
        return PercentageView;
    }

    editor(): DataObjectEditorType
    {
        return PercentageEditor;
    }

    specification(): DataObjectSpecificationType
    {
        return PercentageSpecification;
    }

    getString(value: any, representation: DataObjectRepresentation, context: DataObjectContext, dataObject: DataObject): string
    {
        let format: any;

        if (representation.data.isCompact)
        {
            format = {
                mantissa: 0,
            };
        }

        return value != null ? `${numbro(value).format(format)}%` : '';
    }

    intervalTypeId(): string
    {
        return 'Percentage';
    }

    rangeTypeId(): string
    {
        return 'PercentageRange';
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [
            generateOverload(this, MathematicalOperator.Add),
            generateOverload(this, MathematicalOperator.Subtract),
            generateOverload(this, MathematicalOperator.Multiply),
            generateOverload(this, MathematicalOperator.Divide)
        ];
    }
}

function generateOverload(dataObjectType: DataObjectType,
                          operator: MathematicalOperator)
{
    return new DataObjectOperatorOverload(
        dataObjectType,
        operator,
        DataObjectOverloadType.Symmetric,
        relatedType => relatedType instanceof NumberType,
        (type, relatedType, isThisLhs) =>
        {
            if (isDividedPercentage(operator, isThisLhs))
            {
                // I.e. (25% + 75%) / 2 = 50%
                return dataObjectType;
            }
            else
            {
                // I.e. 50% x 2 = 1 and 2 x 50% = 1
                if (relatedType instanceof PercentageType)
                {
                    return dataObjectType;
                }
                else
                {
                    return relatedType;
                }
            }
        },
        (value, relatedValue, isThisLhs, outputType) =>
        {
            if (relatedValue.specification.type instanceof PercentageType
                || isDividedPercentage(operator, isThisLhs))
            {
                // I.e. 50% x 50% = 25%
                return outputType.compute(
                    isThisLhs ? value : relatedValue,
                    isThisLhs ? relatedValue : value,
                    operator);
            }
            else
            {
                const thisValue =
                    DataObject.constructFromTypeAndValue(
                        relatedValue.specification.type,
                        (value.value || 0) / 100);

                return outputType.compute(
                    isThisLhs ? thisValue : relatedValue,
                    isThisLhs ? relatedValue : thisValue,
                    operator);
            }
        });
}

/**
 *
 * @param operator
 * @param isLhsPercentage
 * @return true if percentage divided by anything
 */
function isDividedPercentage(operator: MathematicalOperator,
                             isLhsPercentage: boolean)
{
    return operator === MathematicalOperator.Divide
        && isLhsPercentage;
}
