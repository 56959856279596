// Polyfill found on: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
if (!Element.prototype.matches)
{
    Element.prototype.matches = (Element.prototype as any).msMatchesSelector || (Element.prototype as any).webkitMatchesSelector;
}

if (!Element.prototype.closest)
{
    Element.prototype.closest = function(s)
    {
        var el = this;

        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

export default function closest(element, s)
{
    return element.closest(s);
}
