import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { useComputed } from 'mobx-react-lite';
import useTypes from '../../../../Type/Api/useTypes';

export default function useHasDividedList(path: EntityPath)
{
    const types = useTypes();

    return useComputed(
        () =>
        {
            if (path.entityType.isA(types.Note.Type))
            {
                return false;
            }
            else
            {
                return true;
            }
        },
        [
            path,
            types
        ]);
}
