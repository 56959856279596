import { observable } from 'mobx';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Action from './Action';
import Validation from '../../Validation/Validation';
import localizeText from '../../../Localization/localizeText';
import Value from '../../Value/Value';
import ActionSignature from '../../ActionTemplate/Model/ActionSignature';
import DynamicParameterAssignment from '../Dynamic/DynamicParameterAssignment';
import getActionSignatureById from '../../ActionTemplate/Api/getActionSignatureById';

export default class InvocationAction extends Action<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref actionSignature: ActionSignature;
    @observable.ref parameterAssignment: DynamicParameterAssignment;

    // ------------------------ Constructor -------------------------

    constructor(actionSignature: ActionSignature,
                parameterAssignment: DynamicParameterAssignment)
    {
        super();

        this.actionSignature = actionSignature;
        this.parameterAssignment = parameterAssignment;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return this.actionSignature.resultType;
    }

    getName()
    {
        return localizeText(
            'Action.Invocation',
            'Actie aanroepen'
        );
    }

    validate(): Validation[]
    {
        if (!this.actionSignature)
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        'Action.Invocation.MissingAction',
                        'Geen actie geselecteerd'
                    )
                )
            ];
        }

        return this.parameterAssignment.validate();
    }

    isAsync(): boolean
    {
        return true;
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Invocation';
        descriptor.actionId = this.actionSignature.id;
        descriptor.parameterAssignment = this.parameterAssignment.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.parameterAssignment.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const actionId = descriptor.actionId;
        const actionSignature = await getActionSignatureById(actionId, dependencyContext);

        return new InvocationAction(
            actionSignature,
            await DynamicParameterAssignment.fromDescriptor(
                descriptor.parameterAssignment,
                actionSignature.parameters,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
