import React from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { default as MuiDrawer } from '@material-ui/core/Drawer';
import useDraggingEntity from './Api/useDraggingEntity';
import useBucketResults from './Api/useBucketResults';
import styles from './Drawer.module.scss';
import useBucketDropCallback from './Api/useBucketDropCallback';
import useTypes from '../../../Type/Api/useTypes';
import { colorToCss } from '../../../../DataObject/Type/Color/ColorType';
import Icon from '../../../../../../@Future/Component/Generic/Icon/Icon';
import Segment from '../../../Dataset/Model/Segment';


export interface DrawerProps
{
    entityType: EntityType;
    segment: Segment;
}

const Drawer: React.FC<DrawerProps> =
    props =>
    {
        const draggingEntity = useDraggingEntity();
        const results = useBucketResults(props.segment, draggingEntity);

        const onDrop =
            useBucketDropCallback(
                props.segment,
                draggingEntity);

        const types = useTypes();

        if (!results || results.length === 0)
        {
            return null;
        }
        else
        {
            return <MuiDrawer
                variant="permanent"
                anchor="bottom"
            >
                <div
                    className={styles.root}
                >
                    {
                        results
                            .map(
                                result =>
                                    <div
                                        key={result.uuid}
                                        className={styles.bucket}
                                        onMouseUp={() => onDrop(result)}
                                        onTouchEnd={() => onDrop(result)}
                                        style={{
                                            backgroundColor: colorToCss(result.getObjectValueByField(types.Datastore.Phase.Field.Color))
                                        }}
                                    >
                                        <div
                                            className={styles.overlay}
                                        >
                                            <Icon
                                                size={18}
                                                icon={result.getObjectValueByField(types.Datastore.Phase.Field.Icon)}
                                                className={styles.icon}
                                            />
                                            {result.name}
                                        </div>
                                    </div>)
                    }
                </div>
            </MuiDrawer>;
        }
    };

export default observer(Drawer);
