import React, { useMemo } from 'react';
import { RangeControl, SelectControl } from '@wordpress/components';
import ViewGroup from '../../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../../ViewGroup/ViewGroupItem';

type DimensionUnit = 'Percentage' | 'Pixel';

export interface DimensionValue
{
    unit: DimensionUnit;
    value: number;
}

export interface DimensionEditorProps
{
    label?: React.ReactNode;
    value?: DimensionValue;
    onChange: (value?: DimensionValue) => void;
}

const DimensionEditor: React.FC<DimensionEditorProps> =
    ({
        label,
        value,
        onChange,
     }) =>
    {
        const units =
            useMemo(
                () => [
                    {
                        label: 'Px',
                        value: 'Pixel'
                    },
                    {
                        label: '%',
                        value: 'Percentage'
                    },
                    {
                        label: 'Auto',
                        value: 'Auto'
                    }
                ],
                []);

        return <ViewGroup
            orientation="horizontal"
            spacing={10}
            alignment="center"
        >
            {
                value &&
                    <ViewGroupItem>
                        <RangeControl
                            name="width"
                            label={label}
                            value={value.value}
                            onChange={dimensionValue => onChange({...value, value: dimensionValue })}
                            min={0}
                            max={value.unit === 'Percentage' ? 100 : 1000}
                        />
                    </ViewGroupItem>
            }
            <ViewGroupItem>
                <SelectControl
                    label={label ? (value ? <span>&nbsp;</span> : label) : undefined}
                    options={units}
                    value={value?.unit ?? 'Auto'}
                    onChange={
                        unit =>
                            onChange(
                                unit === 'Auto'
                                    ? undefined
                                    : { unit, value: 100 }
                            )
                    }
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default DimensionEditor;
