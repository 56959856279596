import * as React from 'react';
import { observer } from 'mobx-react';
import { Popper, PopperProps } from 'react-popper';

export interface PopperContainerProps extends PopperProps<any>
{
    children: any;
    style?: React.CSSProperties;
    eventsEnabled?: boolean;
}

@observer
export class PopperContainer extends React.Component<PopperContainerProps, {}>
{

    render()
    {
        const children = this.props.children;

        return <Popper
            {...this.props}
        >
            {({ ref, style, placement, arrowProps }) => (
                <div
                    ref={ref}
                    style={{
                        ...style,
                        ...this.props.style}}
                    data-placement={placement}
                >
                    {children}
                </div>
            )}
        </Popper>;
    }
}
