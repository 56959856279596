import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Default from '../../Default';
import { ContentProps } from '../../Content';
import useTypes from '../../../../Type/Api/useTypes';
import Planner from '../../../../Planner/Planner';
import { EntityPath } from '../../../../Path/@Model/EntityPath';

export interface ActivityTaskProps extends ContentProps
{

}

const ActivityTask: React.FC<ActivityTaskProps> =
    props =>
    {
        const types = useTypes();
        const employeePath =
            useMemo(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Activity.Task.RelationshipDefinition.Assignee,
                            false),
                [
                    props.entity,
                    types
                ]);

        return <Default
            {...props}
            entity={props.entity}
            appendix={
                <Planner
                    entity={props.entity}
                    startDateField={types.Activity.Task.Field.StartDate}
                    endDateField={types.Activity.Task.Field.EndDate}
                    employeePath={employeePath}
                    autoCommit={props.autoCommit}
                    commitContext={props.commitContext}
                />
            }
        />;
    };

export default observer(ActivityTask);
