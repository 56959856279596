import { BaseStore } from '../../../../@Framework/Store/BaseStore';
import { DataObjectStore } from '../DataObjectStore';
import { Alignment, DataObject } from '../Model/DataObject';
import { computed, observable } from 'mobx';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { DataObjectRepresentation } from '../Model/DataObjectRepresentation';
import { DataObjectBespokeViewerStore } from './DataObjectBespokeViewerStore';
import { LocalizationStore } from '../../../../@Service/Localization/LocalizationStore';
import { CSSProperties } from 'react';

export class DataObjectViewerStore<T extends DataObjectBespokeViewerStore = DataObjectBespokeViewerStore> extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable dataObject: DataObject;
    @observable dataObjectRepresentation: DataObjectRepresentation;
    @observable alignment: Alignment;
    @observable style: CSSProperties;

    // ------------------------ Constructor -------------------------

    constructor(dataObject: DataObject,
                dataObjectRepresentation?: DataObjectRepresentation,
                alignment: Alignment = Alignment.Left,
                style?: CSSProperties)
    {
        super(
            () =>
                !(dataObject.isInitialized
                    || dataObject.isEmpty
                    || !dataObject.specification.type.requiresInitialization(dataObject.value)));

        this.dataObject = dataObject;
        this.dataObjectRepresentation = dataObjectRepresentation;
        this.alignment = alignment;
        this.style = style;
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        if (this.dataObject.isInitialized
            || this.dataObject.isEmpty
            || !this.dataObject.specification.type.requiresInitialization(this.dataObject.value))
        {
            return Promise.resolve();
        }
        else
        {
            return this.dataObjectStore.initializeDataObjects([ this.dataObject ]);
        }
    }

    // -------------------------- Computed --------------------------

    @computed
    get alignmentStyle()
    {
        return this.alignment === Alignment.Left
            ?
                'left'
            :
                this.alignment === Alignment.Center
                    ?
                        'center'
                    :
                        'right';
    }

    @computed
    get isSingleLineMode()
    {
        return this.dataObjectRepresentation && this.dataObjectRepresentation.data.isCompact;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get bespokeStore(): T
    {
        if (this.dataObject.specification.type.viewStore)
        {
            return this.dataObject.specification.type.viewStore(this) as T;
        }
        else
        {
            return new DataObjectBespokeViewerStore(this) as T;
        }
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
