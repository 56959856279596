export default abstract class ValueType<T>
{
    // ------------------------- Properties -------------------------

    type: T;

    // ------------------------ Constructor -------------------------

    constructor(type: T)
    {
        this.type = type;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract id(): string;

    abstract isA(type: ValueType<any>): boolean;

    abstract getName(): string;

    abstract toDescriptor(): any;

    // ----------------------- Private logic ------------------------
}
