import { observable } from 'mobx';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Action from './Action';
import EntityValueType from '../../Value/Type/EntityValueType';
import Mapping from '../../Mapping/Mapping';
import EntityValue from '../../Value/EntityValue';
import Validation from '../../Validation/Validation';
import EmptyValueType from '../../Value/Type/EmptyValueType';
import localizeText from '../../../Localization/localizeText';

export default class CreateEntityAction extends Action<EntityValueType, EntityValue>
{
    // ------------------------- Properties -------------------------

    @observable.ref mapping: Mapping;

    // ------------------------ Constructor -------------------------

    constructor(mapping: Mapping)
    {
        super();

        this.mapping = mapping;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        if (this.mapping)
        {
            return new EntityValueType(this.mapping.entityType);
        }
        else
        {
            return EmptyValueType.instance;
        }
    }

    getName()
    {
        if (this.mapping)
        {
            return localizeText(
                    'CreateEntityOfType',
                    '${typeName} aanmaken',
                    {
                        typeName: this.mapping.entityType.getName()
                    }
                )
        }
        else
        {
            return localizeText(
                'CreateNewRecord',
                'Nieuw record aanmaken'
            )
        }
    }

    validate(): Validation[]
    {
        if (this.mapping)
        {
            return this.mapping.validate();
        }
        else
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        'NoEntityTypeSelected',
                        'Er is nog geen entiteittype gekozen om aan te maken.'
                    )
                )
            ];
        }
    }

    isAsync(): boolean
    {
        return this.mapping.isAsync();
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'CreateEntity';
        descriptor.mapping = this.mapping.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.mapping.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new CreateEntityAction(
            await Mapping.fromDescriptor(
                descriptor.mapping,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
