import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntitySelectionAggregateResult } from '../../../../../../Selection/Model/EntitySelectionAggregateResult';
import { EntityType } from '../../../../../../../../../@Api/Model/Implementation/EntityType';
import { default as SegmentModel } from '../../../../../Model/Segment';
import styles from './DefaultChart.module.scss';
import useFilterAndSortAggregateResult from '../../Api/useFilterAndSortAggregateResult';
import useResultChartData from '../../Api/useResultChartData';
import { ChartStore } from '../../../../../../../../Generic/Chart/ChartStore';
import { classNames } from '../../../../../../../../../@Future/Util/Class/classNames';
import Chart from '../../../../../../../../Generic/Chart/Chart';

export interface DefaultChartProps
{
    entityType: EntityType;
    segment: SegmentModel;
    nextSegments: SegmentModel[];
    result: EntitySelectionAggregateResult;
    onSelectChild: (child?: EntitySelectionAggregateResult) => void;
}

const DefaultChart: React.FC<DefaultChartProps> =
    props =>
    {
        const sortedAggregateResults =
            useFilterAndSortAggregateResult(
                props.segment,
                props.result);

        const chartData =
            useResultChartData(
                props.segment,
                props.result.groupInterval,
                sortedAggregateResults);

        const chartStore =
            useComputed(
                () =>
                    new ChartStore(
                        {
                            type: props.segment.type.replace('Chart.', '').toLowerCase() as any,
                            data: () => chartData,
                            color: props.entityType.getInheritedColor(),
                            onSelect:
                                idx =>
                                    props.onSelectChild(sortedAggregateResults[idx]),
                            onUnselect:
                                () =>
                                    props.onSelectChild(undefined)
                        }),
                [
                    chartData,
                    props.onSelectChild,
                    sortedAggregateResults,
                    props.segment
                ]);

        return <div
            className={classNames(styles.chart, props.segment.type === 'Funnel' && styles.funnel)}
        >
            <Chart
                store={chartStore}
            />
        </div>;
    };

export default observer(DefaultChart);
