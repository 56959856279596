import { EntityPath } from '../../Path/@Model/EntityPath';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import getTypes from './getTypes';

export function getRelationshipAndContactInitializationPathsInViewer(
    rootPath: EntityPath,
    relationshipDefinition: EntityRelationshipDefinition,
    contactDefinition: EntityRelationshipDefinition
): EntityPath[]
{
    const types = getTypes();

    const pathToParentRelationship =
        rootPath.joinTo(
            relationshipDefinition,
            true);

    const pathToParentOrganization =
        pathToParentRelationship
            .castTo(types.Relationship.Organization.Type)
            .joinTo(types.Relationship.Organization.RelationshipDefinition.Organization, false);

    const pathToParentPerson =
        pathToParentRelationship
            .castTo(types.Relationship.Person.Type)
            .joinTo(types.Relationship.Person.RelationshipDefinition.Person, false);

    const pathToParentContact =
        rootPath
            .joinTo(
                contactDefinition,
                true)
            .joinTo(
                types.Relationship.Person.RelationshipDefinition.Person,
                false);

    return [
        pathToParentRelationship,
        pathToParentOrganization,
        ...pathToParentOrganization.entityType.bespoke.getListDependencies().map(
            dependency =>
                pathToParentOrganization.join(dependency)),
        pathToParentPerson,
        ...pathToParentPerson.entityType.bespoke.getListDependencies().map(
            dependency =>
                pathToParentPerson.join(dependency)),
        pathToParentContact,
        ...pathToParentContact.entityType.bespoke.getListDependencies().map(
            dependency =>
                pathToParentContact.join(dependency)),
    ];
}