import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { FieldMappingEditorProps } from '../FieldMappingEditor';
import FieldInput from '../../../../../../../../../../../Multiplayer/Model/Input/FieldInput';
import PrimitiveValueType from '../../../../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import EntityValueType from '../../../../../../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import RelationshipInput from '../../../../../../../../../../../Multiplayer/Model/Input/RelationshipInput';
import ValueFieldMapping from '../../../../../../../../../../../../../@Api/Automation/Mapping/Field/ValueFieldMapping';
import Value from '../../../../../../../../../../../../../@Api/Automation/Value/Value';
import { runInAction } from 'mobx';
import ComputationEditor from '../../../../../../Computation/ComputationEditor';

export interface ValueFieldMappingEditorProps extends FieldMappingEditorProps<ValueFieldMapping>
{

}

const ValueFieldMappingEditor: React.FC<ValueFieldMappingEditorProps> =
    props =>
    {
        const type =
            useComputed(
                () =>
                {
                    if (props.fieldMapping.field instanceof FieldInput)
                    {
                        return new PrimitiveValueType(props.fieldMapping.field.field.dataObjectSpecification.type);
                    }
                    else if (props.fieldMapping.field instanceof RelationshipInput)
                    {
                        return new EntityValueType(props.fieldMapping.field.relationshipDefinition.getEntityType(props.fieldMapping.field.isParent));
                    }
                },
                [
                    props.fieldMapping
                ]);

        const onChange =
            useCallback(
                (value?: Value<any, any>) =>
                    runInAction(
                        () =>
                        {
                            props.fieldMapping.value = value;
                        }),
                [
                    props.fieldMapping
                ]);

        return <ComputationEditor
            context={props.context}
            type={type}
            value={props.fieldMapping.value}
            onChange={onChange}
        />;
    };

export default observer(ValueFieldMappingEditor);
