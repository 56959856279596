import React from 'react';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../../../../../../@Future/Component/Generic/Card/Card';
import styles from './Item.module.scss';
import useIsMobile from '../../../../../../../../../../@Util/Responsiveness/useIsMobile';
import { classNames } from '../../../../../../../../../../@Future/Util/Class/classNames';
import Icon from './Icon';

export interface ConstructorItemProps
{

}

const ConstructorItem: React.FC<ConstructorItemProps> =
    props =>
    {
        const isCompact = useIsMobile();

        return <div
            className={classNames(styles.root, isCompact && styles.compact, styles.constructorItem)}
        >
            {
                !isCompact &&
                    <Icon
                        icon="add"
                    />
            }
            <div
                className={styles.data}
            >
                <Card>
                    <div
                        className={styles.header}
                    >
                        {props.children}
                    </div>
                </Card>
            </div>
        </div>;
    };

export default observer(ConstructorItem);
