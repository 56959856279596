export default function probeBrowserLanguageCode()
{
    const language = (window.navigator as any).userLanguage || window.navigator.language;

    if (language)
    {
        return language.split('-')[0].toUpperCase();
    }
    else
    {
        return 'EN';
    }
}
