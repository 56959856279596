import Layout from '../../../../../../../../@Api/Layout/Layout';
import getLayoutFromDescriptor from '../../../../../../../../@Api/Layout/Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../../../../@Api/Layout/LayoutDependencyContext';
import { observable } from 'mobx';

export default class CustomWidgetSpecification
{
    // ------------------------- Properties -------------------------

    @observable.ref layout: Layout;
    @observable isFullWidth: boolean;

    // ------------------------ Constructor -------------------------

    constructor(layout: Layout, isFullWidth: boolean)
    {
        this.layout = layout;
        this.isFullWidth = isFullWidth;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(
        descriptor: any,
        layoutDependencyContext: LayoutDependencyContext
    )
    {
        const layout =
            await getLayoutFromDescriptor(
                descriptor.layout,
                layoutDependencyContext
            );
        const isFullWidth = descriptor.isFullWidth;

        return new CustomWidgetSpecification(
            layout,
            isFullWidth
        );
    }

    toDescriptor()
    {
        return {
            layout: this.layout.toDescriptor(),
            isFullWidth: this.isFullWidth,
        };
    }

    // ----------------------- Private logic ------------------------
}
