import { EntitySelectionAggregateResult } from '../../../../../Selection/Model/EntitySelectionAggregateResult';
import { ChartDataPoint } from '../../../../../../../Generic/Chart/Model/ChartDataPoint';
import { ChartDataSegmentation } from '../../../../../../../Generic/Chart/Model/ChartDataSegmentation';
import getResultGroup from '../../../Api/getResultGroup';
import getResultValue from '../../../Api/getResultValue';
import { useComputed } from 'mobx-react-lite';
import { DataObject } from '../../../../../../DataObject/Model/DataObject';
import Segment from '../../../../Model/Segment';

export default function useResultChartData(segment: Segment,
                                           groupInterval: DataObject,
                                           result: EntitySelectionAggregateResult[])
{
    return useComputed(
        () =>
        {
            return result
                .map(
                    (child, idx) =>
                        new ChartDataPoint(
                            getResultGroup(
                                segment,
                                child,
                                idx),
                            groupInterval,
                            getResultValue(child),
                            child.children.length > 0
                                ?
                                    [
                                        new ChartDataSegmentation(
                                            child.children.map(
                                                (childResult, segmentIdx) =>
                                                    new ChartDataPoint(
                                                        getResultGroup(segment, childResult, segmentIdx),
                                                        undefined,
                                                        getResultValue(childResult))))
                                    ]
                                :
                                    []));
        },
        [
            segment,
            result
        ]);
}
