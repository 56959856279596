import { useContext, useMemo } from 'react';
import Trigger from '../../../../../../Action/Api/Trigger';
import RouterContext from '../../../../../../../../@Service/Router/RouterContext';
import { LinkExternalAppointmentPageStore } from './LinkExternalAppointmentPageStore';
import ExternalAppointment from '../ExternalAppointment';

export default function useLinkExternalAppointmentTrigger()
{
    const routerStore = useContext(RouterContext);

    return useMemo<Trigger<ExternalAppointment>>(
        () =>
            ({
                type: 'Activity.Appointment.LinkExternalAppointment',
                onEvent:
                    event =>
                    {
                        const pageStore = new LinkExternalAppointmentPageStore(event.data);

                        routerStore.route(
                            pageStore.routePath,
                            undefined,
                            undefined,
                            pageStore,
                            true);
                    }
            }),
        [
            routerStore
        ]);
}
