import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Email.module.scss';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { EntityExpansionBuilder } from '../../../../Selection/Builder/EntityExpansionBuilder';
import useTypes from '../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import EntityEmailViewer from '../../../../Email/EntityEmailViewer';
import Recipient from './RecipientList/Recipient/Recipient';
import RecipientList from './RecipientList/RecipientList';
import useRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import useRelatedEntities from '../../../../../../../@Api/Entity/Hooks/useRelatedEntities';
import InputGroup from '../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import { MoreProps } from '../More';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import Constructor from '../../../../Constructor/Constructor';
import uuid from '../../../../../../../@Util/Id/uuid';
import moment from 'moment';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { constructEntityOfType } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { openEntity } from '../../../../@Util/openEntity';
import DateView from '../../../../../../../@Future/Component/Generic/DateView/DateView';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import AttachmentList from '../../../../AttachmentList/AttachmentList';

export interface EmailProps extends MoreProps
{

}

const Email: React.FC<EmailProps> =
    props =>
    {
        const types = useTypes();

        useEffect(
            () =>
            {
                const rootPath = EntityPath.fromEntity(props.entity);

                new EntityExpansionBuilder(
                    props.entity.entityType,
                    [
                        props.entity
                    ],
                    [
                        rootPath
                            .joinTo(
                                types.Activity.Email.RelationshipDefinition.From,
                                false)
                            .joinTo(
                                types.Recipient.RelationshipDefinition.Addressee,
                                false),
                        rootPath
                            .joinTo(
                                types.Activity.Email.RelationshipDefinition.To,
                                false)
                            .joinTo(
                                types.Recipient.RelationshipDefinition.Addressee,
                                false),
                        rootPath
                            .joinTo(
                                types.Activity.Email.RelationshipDefinition.CC,
                                false)
                            .joinTo(
                                types.Recipient.RelationshipDefinition.Addressee,
                                false),
                        rootPath
                            .joinTo(
                                types.Activity.Email.RelationshipDefinition.BCC,
                                false)
                            .joinTo(
                                types.Recipient.RelationshipDefinition.Addressee,
                                false),
                        rootPath
                            .joinTo(
                                types.Entity.RelationshipDefinition.Attachments,
                                false)
                    ])
                    .expand()
            },
            [
                props.entity,
                types
            ]);

        const phase =
            useRelatedEntity(
                props.entity,
                types.Activity.Email.RelationshipDefinition.Phase,
                false);

        const isConcept =
            useMemo(
                () =>
                    phase?.getObjectValueByField(types.Datastore.Field.Code) === types.Activity.Email.State.Concept,
                [
                    phase,
                    types
                ]);

        const isReceived =
            useMemo(
                () =>
                    phase?.getObjectValueByField(types.Datastore.Field.Code) === types.Activity.Email.State.Received,
                [
                    phase,
                    types
                ]);

        const sendDate =
            useEntityValue<Date>(
                props.entity,
                types.Activity.Email.Field.SendDate);

        const from =
            useRelatedEntity(
                props.entity,
                types.Activity.Email.RelationshipDefinition.From,
                false);

        const to =
            useRelatedEntities(
                props.entity,
                types.Activity.Email.RelationshipDefinition.To,
                false);

        const cc =
            useRelatedEntities(
                props.entity,
                types.Activity.Email.RelationshipDefinition.CC,
                false);

        const bcc =
            useRelatedEntities(
                props.entity,
                types.Activity.Email.RelationshipDefinition.BCC,
                false);

        const attachments =
            useRelatedEntities(
                props.entity,
                types.Entity.RelationshipDefinition.Attachments,
                false);

        const [ replyEmail, setReplyEmail ] = useState<Entity | undefined>(undefined);
        const clearReplyEmail =
            useCallback(
                () =>
                    setReplyEmail(undefined),
                [
                    setReplyEmail
                ]
            );
        const replyCommitContext = useNewCommitContext();

        const replyToEmail =
            useCallback(
                (
                    isForward: boolean = false,
                    isReplyAll: boolean = false
                ) =>
                    {
                        const replyEmail =
                            constructEntityOfType(
                                types.Activity.Email.Type,
                                replyCommitContext
                            );

                        const parentEmail = props.entity;

                        updateRelationship(
                            replyEmail,
                            true,
                            isForward ? types.Activity.Email.RelationshipDefinition.Forwards : types.Activity.Email.RelationshipDefinition.Replies,
                            parentEmail,
                            replyCommitContext
                        );

                        [
                            types.Activity.RelationshipDefinition.LinkedActivities,
                            types.Relationship.RelationshipDefinition.Activities,
                            types.Relationship.Person.Contact.RelationshipDefinition.Activities
                        ].forEach(
                            relationshipDefinition =>
                                updateRelationship(
                                    replyEmail,
                                    true,
                                    relationshipDefinition,
                                    parentEmail
                                        .getRelatedEntityByDefinition(
                                            true,
                                            relationshipDefinition),
                                    replyCommitContext
                                )
                        );

                        if (!isForward
                            && from
                            && from.hasRelationshipsByDefinition(
                                false,
                                types.Recipient.RelationshipDefinition.Addressee,
                                replyCommitContext)
                        )
                        {
                            const to =
                                constructEntityOfType(
                                    types.Recipient.Email.Type,
                                    replyCommitContext
                                );

                            updateRelationship(
                                to,
                                false,
                                types.Recipient.RelationshipDefinition.Addressee,
                                from.getRelatedEntityByDefinition(
                                    false,
                                    types.Recipient.RelationshipDefinition.Addressee),
                                replyCommitContext
                            );

                            [
                                types.Recipient.Email.Field.Name,
                                types.Recipient.Email.Field.EmailAddress
                            ].forEach(
                                field =>
                                    setValueByFieldInEntity(
                                        to,
                                        field,
                                        from.getObjectValueByField(field),
                                        replyCommitContext
                                    )
                            );

                            updateRelationship(
                                replyEmail,
                                false,
                                types.Activity.Email.RelationshipDefinition.To,
                                to,
                                replyCommitContext
                            );
                        }

                        if (isReplyAll)
                        {
                            for (const toRecipient of to)
                            {
                                const to =
                                    constructEntityOfType(
                                        types.Recipient.Email.Type,
                                        replyCommitContext
                                    );

                                updateRelationship(
                                    to,
                                    false,
                                    types.Recipient.RelationshipDefinition.Addressee,
                                    toRecipient.getRelatedEntityByDefinition(
                                        false,
                                        types.Recipient.RelationshipDefinition.Addressee),
                                    replyCommitContext
                                );

                                [
                                    types.Recipient.Email.Field.Name,
                                    types.Recipient.Email.Field.EmailAddress
                                ].forEach(
                                    field =>
                                        setValueByFieldInEntity(
                                            to,
                                            field,
                                            toRecipient.getObjectValueByField(field),
                                            replyCommitContext
                                        )
                                );

                                updateRelationship(
                                    replyEmail,
                                    false,
                                    types.Activity.Email.RelationshipDefinition.To,
                                    to,
                                    replyCommitContext
                                );
                            }

                            for (const ccRecipient of cc)
                            {
                                const to =
                                    constructEntityOfType(
                                        types.Recipient.Email.Type,
                                        replyCommitContext
                                    );

                                updateRelationship(
                                    to,
                                    false,
                                    types.Recipient.RelationshipDefinition.Addressee,
                                    ccRecipient.getRelatedEntityByDefinition(
                                        false,
                                        types.Recipient.RelationshipDefinition.Addressee),
                                    replyCommitContext
                                );

                                [
                                    types.Recipient.Email.Field.Name,
                                    types.Recipient.Email.Field.EmailAddress
                                ].forEach(
                                    field =>
                                        setValueByFieldInEntity(
                                            to,
                                            field,
                                            ccRecipient.getObjectValueByField(field),
                                            replyCommitContext
                                        )
                                );

                                updateRelationship(
                                    replyEmail,
                                    false,
                                    types.Activity.Email.RelationshipDefinition.To,
                                    to,
                                    replyCommitContext
                                );
                            }
                        }

                        const parentEmailSubject = props.entity.getObjectValueByField(types.Activity.Field.Subject, replyCommitContext)?.trim() || '';
                        const prefix = isForward ? 'FW:' : 'RE:';

                        setValueByFieldInEntity(
                            replyEmail,
                            types.Activity.Field.Subject,
                            parentEmailSubject.toLowerCase().startsWith(prefix.toLowerCase())
                                ?
                                parentEmailSubject
                                :
                                `${prefix} ${parentEmailSubject}`,
                            replyCommitContext
                        );

                        const getRecipientAsString =
                            (recipient: Entity) =>
                            {
                                const addressee =
                                    recipient.getRelatedEntityByDefinition(
                                        false,
                                        types.Recipient.RelationshipDefinition.Addressee,
                                        replyCommitContext
                                    );

                                const addresseeName = recipient.getObjectValueByField(types.Recipient.Email.Field.Name, replyCommitContext) || addressee?.getName(replyCommitContext);
                                const emailAddress = recipient.getObjectValueByField(types.Recipient.Email.Field.EmailAddress, replyCommitContext);

                                if (addresseeName)
                                {
                                    return `${addresseeName} &lt;${emailAddress}&gt;`;
                                }
                                else
                                {
                                    return emailAddress;
                                }
                            };

                        const sendDate = parentEmail.getObjectValueByField(types.Activity.Email.Field.SendDate, replyCommitContext);
                        const sendDateAsString =
                            sendDate
                                ?
                                moment(sendDate).format('LLLL')
                                :
                                undefined;

                        const replyEmailFooter =
                            `<style>
                                p.MsoNormal, li.MsoNormal, div.MsoNormal {
                                    margin: 0;
                                    line-height: normal;
                                    font-size: 11.0pt;
                                    font-family: "Calibri", sans-serif;
                                }
                            </style>
                            <div style='border:none;border-top:solid #E1E1E1 1.0pt;padding:3.0pt 0 0 0'>
                                <p class=MsoNormal>
                                    ${from ? `<b>From:</b> ${getRecipientAsString(from)}<br />` : ''}
                                    ${sendDateAsString ? `<b>Sent:</b> ${moment(parentEmail.getObjectValueByField(types.Activity.Email.Field.SendDate)).format('LLLL')}<br />` : ''}
                                    <b>To:</b> ${to.map(toRecipient => getRecipientAsString(toRecipient)).join(', ')}<br />
                                    ${cc.length > 0 ? `<b>CC:</b> ${cc.map(ccRecipient => getRecipientAsString(ccRecipient)).join(', ')}<br />` : ''}
                                    <b>Subject:</b> ${parentEmailSubject}
                                </p>
                            </div>
                            <br />
                            ${props.entity.getObjectValueByField(types.Activity.Email.Field.HTML, replyCommitContext)}`;

                        setValueByFieldInEntity(
                            replyEmail,
                            types.Activity.Email.Field.Content,
                            {
                                type: 'gutenberg',
                                definition:
                                    [
                                        {
                                            clientId: uuid(),
                                            name: 'pv/expression',
                                            isValid: true,
                                            attributes: {},
                                            innerBlocks: []
                                        },
                                        {
                                            clientId: uuid(),
                                            name: 'core/spacer',
                                            isValid: true,
                                            attributes: {
                                                height: 10
                                            },
                                            innerBlocks: []
                                        },
                                        {
                                            clientId: uuid(),
                                            name: 'pv/html',
                                            isReplyMail: true,
                                            isValid: true,
                                            attributes: {
                                                html: replyEmailFooter
                                            },
                                            innerBlocks: []
                                        }
                                    ]
                            },
                            replyCommitContext
                        );

                        if (isForward)
                        {
                            props.entity.getRelatedEntitiesByDefinition(
                                false,
                                types.Entity.RelationshipDefinition.Attachments
                            ).forEach(
                                attachment =>
                                {
                                    const copiedAttachment =
                                        constructEntityOfType(
                                            types.Attachment.Type,
                                            replyCommitContext
                                        );

                                    setValueByFieldInEntity(
                                        copiedAttachment,
                                        types.Attachment.Field.File,
                                        attachment.getObjectValueByField(types.Attachment.Field.File),
                                        replyCommitContext
                                    );

                                    updateRelationship(
                                        copiedAttachment,
                                        true,
                                        types.Entity.RelationshipDefinition.Attachments,
                                        replyEmail,
                                        replyCommitContext
                                    );
                                }
                            )
                        }

                        setReplyEmail(replyEmail);
                    },
                [
                    props.entity,
                    replyCommitContext,
                    from,
                    to,
                    cc,
                ]
            );

        const forwardEmail =
            useCallback(
                () =>
                    replyToEmail(true),
                [
                    replyToEmail
                ]);

        const replyAllEmail =
            useCallback(
                () =>
                    replyToEmail(
                        false,
                        true
                    ),
                [
                    replyToEmail
                ]
            )

        if (isConcept)
        {
            return <div
                className={classNames(styles.root, props.disableInset && styles.disableInset)}
            >
                <Card
                    inset
                >
                    <Constructor
                        entity={props.entity}
                    />
                </Card>
            </div>;
        }
        else if (replyEmail)
        {
            return <div
                className={classNames(styles.root, props.disableInset && styles.disableInset)}
            >
                <Card
                    inset
                >
                    <Constructor
                        entity={replyEmail}
                        commitContext={replyCommitContext}
                        onSave={openEntity}
                        onClose={clearReplyEmail}
                    />
                </Card>
            </div>;
        }
        else
        {
            return <div
                className={classNames(styles.root, props.disableInset && styles.disableInset)}
            >
                <Card
                    inset
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={15}
                    >
                        <ViewGroupItem>
                            <InputGroup>
                                {
                                    sendDate &&
                                    <Input
                                        label={
                                            isReceived
                                                ? <LocalizedText
                                                    code="Generic.Received"
                                                    value="Ontvangen"
                                                />
                                                : <LocalizedText
                                                    code="Generic.Sent"
                                                    value="Verzonden"
                                                />
                                        }
                                        labelPosition="left"
                                    >
                                        <DateView
                                            date={sendDate}
                                            displayDate={true}
                                        />
                                    </Input>
                                }
                                {
                                    from &&
                                    <Input
                                        label={
                                            types.Activity.Email.RelationshipDefinition.From.getName(false)
                                        }
                                        labelPosition="left"
                                    >
                                        <Recipient
                                            recipient={from}
                                        />
                                    </Input>
                                }
                                {
                                    to.length > 0 &&
                                    <Input
                                        label={
                                            types.Activity.Email.RelationshipDefinition.To.getName(false)
                                        }
                                        labelPosition="left"
                                    >
                                        <RecipientList
                                            recipients={to}
                                        />
                                    </Input>
                                }
                                {
                                    cc.length > 0 &&
                                    <Input
                                        label={
                                            types.Activity.Email.RelationshipDefinition.CC.getName(false)
                                        }
                                        labelPosition="left"
                                    >
                                        <RecipientList
                                            recipients={cc}
                                        />
                                    </Input>
                                }
                                {
                                    bcc.length > 0 &&
                                    <Input
                                        label={
                                            types.Activity.Email.RelationshipDefinition.BCC.getName(false)
                                        }
                                        labelPosition="left"
                                    >
                                        <RecipientList
                                            recipients={bcc}
                                        />
                                    </Input>
                                }
                                <Input
                                    label={
                                        types.Activity.Field.Subject.getName()
                                    }
                                    labelPosition="left"
                                >
                                    {props.entity.name}
                                </Input>
                                {
                                    attachments.length > 0 &&
                                    <Input
                                        label={
                                            types.Entity.RelationshipDefinition.Attachments.getName(false)
                                        }
                                        labelPosition="left"
                                    >
                                        <AttachmentList
                                            attachments={attachments}
                                            readOnly={true}
                                        />
                                    </Input>
                                }
                            </InputGroup>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <EntityEmailViewer
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <RightAlignedButtonGroup>
                                <PrimaryButton
                                    icon="reply"
                                    label={
                                        <LocalizedText
                                            code="Email.Reply"
                                            value="Beantwoorden"
                                        />
                                    }
                                    onClick={replyToEmail}
                                />
                                <PrimaryButton
                                    icon="reply_all"
                                    label={
                                        <LocalizedText
                                            code="Email.ReplyAll"
                                            value="Allen beantwoorden"
                                        />
                                    }
                                    onClick={replyAllEmail}
                                />
                                <PrimaryButton
                                    icon="forward"
                                    label={
                                        <LocalizedText
                                            code="Email.Forward"
                                            value="Doorsturen"
                                        />
                                    }
                                    onClick={forwardEmail}
                                />
                            </RightAlignedButtonGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </Card>
            </div>;
        }
    };

export default observer(Email);
