import React from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import CardHeader from '../../../../../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import ExpansionGroup from '../../../../../../../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import Header from '../../../../../../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import ExpansionPanel from '../../../../../../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import useDividerGlue from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import QueryFilterEditor from '../QueryFilterEditor';
import { QueryEditorProps } from '../QueryEditor';
import AggregateQueryOptionsEditor from './AggregateQueryOptionsEditor';
import AggregateQuery from '../../../../../../../../../../../../@Api/Automation/Query/AggregateQuery';

export interface AggregateQueryEditorProps extends QueryEditorProps<AggregateQuery>
{

}

const AggregateQueryEditor: React.FC<AggregateQueryEditorProps> =
    props =>
    {
        const dividerGlue = useDividerGlue();

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <CardInset>
                    <CardHeader>
                        Selecteer {props.query.entityType.getName(true).toLowerCase()}
                    </CardHeader>
                </CardInset>
            </ViewGroupItem>
            <ViewGroupItem>
                <ExpansionGroup>
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                        glue={dividerGlue}
                    >
                        <ViewGroupItem>
                            <ExpansionPanel
                                id="options"
                                summary={
                                    <Header
                                        title="Opties"
                                        inset
                                    />
                                }
                                expansion={
                                    <CardInset
                                        horizontal
                                        top={false}
                                    >
                                        <AggregateQueryOptionsEditor
                                            {...props}
                                        />
                                    </CardInset>
                                }
                                expanded
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ExpansionPanel
                                id="filter"
                                summary={
                                    <Header
                                        title="Filters"
                                        inset
                                    />
                                }
                                expansion={
                                    <CardInset
                                        horizontal
                                        top={false}
                                    >
                                        <QueryFilterEditor
                                            {...props}
                                        />
                                    </CardInset>
                                }
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </ExpansionGroup>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(AggregateQueryEditor);
