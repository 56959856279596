import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Member from './Member/Member';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import HoverCardBottom from '../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import useSwitch from '../../../../../../@Util/Switch/useSwitch';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useFetchedRelatedEntities from '../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntities';
import MenuButton from '../../../../Entity/Item/MenuButton/MenuButton';
import Selectbox from '../../../../Entity/Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../../../Entity/Selection/Builder/EntitySelectionBuilder';
import { SelectboxProps } from '../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import useTransactionalEntity from '../../../../../../@Api/Entity/Bespoke/useTransactionalEntity';
import Input from '../../../../Entity/Input/Input';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { openEntity } from '../../../../Entity/@Util/openEntity';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

export interface TeamManagerProps
{
    team: Entity;
    showIsAccessLimitedToTeam?: boolean;
}

const TeamManager: React.FC<TeamManagerProps> =
    props =>
    {
        const types = useTypes();
        const team = useTransactionalEntity(props.team);

        const [ members ] =
            useFetchedRelatedEntities(
                props.team,
                types.Team.RelationshipDefinition.Members,
                false);

        const [ isInAddMode, startAddMode, endAddMode ] = useSwitch(false);
        const newMemberSelection =
            useComputed(
                () => [
                    new EntitySelectionBuilder(types.Relationship.Person.Contact.Employee.Type)
                        // .where(
                        //     cb =>
                        //         cb.notRelatedToEntity(
                        //             EntityPath.fromEntityType(types.Relationship.Person.Contact.Employee.Type)
                        //                 .joinTo(
                        //                     types.Team.RelationshipDefinition.Members,
                        //                     true),
                        //             props.team))
                        .build()
                ],
                [
                    types,
                    // props.team
                ]);
        const addMember =
            useCallback(
                (employee: Entity) =>
                    new CommitBuilder()
                        .relateEntityTo(
                            employee,
                            true,
                            types.Team.RelationshipDefinition.Members,
                            props.team
                        )
                        .commit()
                        .then(
                            () =>
                                endAddMode()
                        ),
                [
                    types,
                    props.team,
                    endAddMode,
                ]);
        const addMemberSelectboxProps =
            useMemo(
                () => ({
                    open: true,
                    onClose:
                        () =>
                            endAddMode()
                } as SelectboxProps<any>),
                [
                    endAddMode
                ]);

        const editTeam =
            useCallback(
                () =>
                    openEntity(props.team),
                [
                    props.team
                ]);

        return <Card>
            <CardInset>
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <CardHeader>
                            {props.team.name}
                        </CardHeader>
                    </ViewGroupItem>
                    {/*<ViewGroupItem>*/}
                    {/*    <div*/}
                    {/*        className={styles.secondaryHeader}*/}
                    {/*    >*/}
                    {/*        Teamleider*/}
                    {/*    </div>*/}
                    {/*</ViewGroupItem>*/}
                    <ViewGroupItem>
                        <IconButton
                            icon="edit"
                            tooltip={
                                <LocalizedText
                                    code="Generic.Edit"
                                />
                            }
                            onClick={editTeam}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <MenuButton
                            entity={props.team}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </CardInset>
            <Divider />
            {
                props.showIsAccessLimitedToTeam &&
                    <>
                        <CardInset>
                            <Input
                                entity={team}
                                field={types.Team.Field.IsAccessLimitedToTeam}
                                labelPosition="left"
                                labelFullWidth
                                label={
                                    <LocalizedText
                                        code="Team.AccessIsLimited"
                                        value="Gegevens afschermen"
                                    />
                                }
                            />
                        </CardInset>
                        <Divider />
                        {
                            team.getObjectValueByField(types.Team.Field.IsAccessLimitedToTeam) &&
                            <>
                                <CardInset>
                                    <RadioGroup
                                        onChange={
                                            event =>
                                                new CommitBuilder()
                                                    .setObjectValueInEntity(
                                                        team,
                                                        types.Team.Field.HasAccessToNonTeamRelationships,
                                                        event.target.value === 'true'
                                                    )
                                                    .commit()
                                        }
                                        value={
                                            team.getObjectValueByField(types.Team.Field.HasAccessToNonTeamRelationships)
                                                ? 'true'
                                                : 'false'
                                        }
                                    >
                                        <FormControlLabel
                                            value="false"
                                            control={
                                                <Radio
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <LocalizedText
                                                    code="Team.AccessIsLimitedToEverything"
                                                    value="Relaties, activiteiten en eigen entiteiten zijn afgeschermd voor leden buiten dit team."
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            value="true"
                                            control={
                                                <Radio
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <LocalizedText
                                                    code="Team.AccessIsLimitedToNonRelationships"
                                                    value="Alle relaties zijn zichtbaar voor alle gebruikers in het CRM. Activiteiten en eigen entiteiten zijn afgeschermd voor leden buiten dit team."
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </CardInset>
                                <Divider />
                            </>
                        }
                    </>
            }
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={useDividerGlue()}
            >
                {
                    members && members.map(
                        member =>
                            <ViewGroupItem
                                key={member.uuid}
                            >
                                <Member
                                    member={member}
                                    team={props.team}
                                />
                            </ViewGroupItem>)
                }
            </ViewGroup>
            <Divider />
            {
                isInAddMode &&
                    <CardInset>
                        <Selectbox
                            selections={newMemberSelection}
                            onChange={addMember}
                            autoFocus
                            genericSelectboxProps={addMemberSelectboxProps}
                        />
                    </CardInset>
            }
            {
                !isInAddMode &&
                    <HoverCardBottom
                        onClick={startAddMode}
                    >
                        <LocalizedText
                            code="Configuration.Teams.AddMemberButton"
                            value="+ Teamlid toevoegen"
                        />
                    </HoverCardBottom>
            }
        </Card>;
    };

export default observer(TeamManager);
