import { useContext, useMemo } from 'react';
import DroppedDraggableContext from '../../Context/DroppedDraggableContext';

export default function useIsDropped(id: string)
{
    const droppedItemId = useContext(DroppedDraggableContext);

    return useMemo(
        () =>
            id === droppedItemId,
        [
            id,
            droppedItemId
        ]);
}