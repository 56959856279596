import { observable } from 'mobx';
import { Computation } from '../Computation';
import { ConditionalComputationCondition } from './Condition/ConditionalComputationCondition';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import ConditionalInComputation from '../../../../../@Api/Automation/Function/Computation/ConditionalInComputation';
import { default as NewConditionalComputation } from '../../../../../@Api/Automation/Function/Computation/ConditionalComputation';

export class ConditionalComputation extends Computation
{
    // ------------------------- Properties -------------------------

    @observable conditions: ConditionalComputationCondition[];
    @observable otherwise: Computation;

    // ------------------------ Constructor -------------------------

    constructor(operations: ConditionalComputationCondition[],
                otherwise: Computation)
    {
        super('Conditional');

        this.conditions = observable.array(operations);
        this.otherwise = otherwise;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async initialize(): Promise<any>
    {
        return Promise.all([
            ...this.conditions.map(
                condition =>
                    condition.computation?.initialize()),
            this.otherwise?.initialize()
        ]);
    }

    async toNewComputation(context: AutomationDependencyContext)
    {
        const conditions =
            await Promise.all(
                this.conditions.map(
                    async condition =>
                        new ConditionalInComputation(
                            await condition.predicate.toNewPredicate(context),
                            await condition.computation.toNewComputation(context),
                        )
                )
            );
        const otherwise = await this.otherwise?.toNewComputation(context);

        return new NewConditionalComputation(
            conditions,
            otherwise
        );
    }

    // ----------------------- Private logic ------------------------
}
