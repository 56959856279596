import * as Sentry from '@sentry/browser';

export default function captureException(error: any)
{
    try
    {
        if (!error || error._isCapturedByThisHandler !== undefined)
        {
            return;
        }

        error._isCapturedByThisHandler = true;

        Sentry.captureException(error);
    }
    catch (e)
    {
        console.error('error capturing exception', e);
    }
}
