import React from 'react';
import DangerButton from '../DangerButton/DangerButton';
import { LabelButtonProps } from '../Label/LabelButton';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface DeleteButtonProps extends Partial<LabelButtonProps>
{

}

const DeleteButton: React.FC<DeleteButtonProps> =
    props =>
    {
        return <DangerButton
            label={
                <LocalizedText
                    code="Generic.Delete"
                />
            }
            {...props as LabelButtonProps}
        />;
    };

export default DeleteButton;
