import { useState } from 'react';
import ExpansionPanelContainer from '../ExpansionPanelContainer';
import { Id } from '../../../../Util/Id/Id';

export default function useExpansionGroup(expandedItemId?: Id)
{
    const [ container ] = useState(new ExpansionPanelContainer(expandedItemId));

    // useEffect(
    //     () =>
    //     {
    //         container.setExpandedItemId(expandedItemId);
    //     },
    //     [
    //         container,
    //         expandedItemId
    //     ]);

    return container;
}
