import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { CronEditorProps } from '../CronEditor';
import NumberEditor from '../../NumberEditor/NumberEditor';
import ViewGroup from '../../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../../ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface MinutelyCronEditorProps extends CronEditorProps
{

}

const MinutelyCronEditor: React.FC<MinutelyCronEditorProps> =
    props =>
    {
        const hours =
            useMemo(
                () =>
                    parseInt(props.value.split(' ')[2].split('/')[1]),
                [
                    props.value
                ]);

        const { onChange } = props;
        const onChangeHours =
            useCallback(
                (hours: number) =>
                {
                    if (hours === undefined
                        || hours < 1)
                    {
                        hours = 1;
                    }

                    const split = props.value.split(' ');
                    split.splice(
                        2,
                        1,
                        `0/${hours}`);

                    onChange(split.join(' '));
                },
                [
                    onChange,
                    props.value
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={5}
            alignment="center"
        >
            <ViewGroupItem>
                <LocalizedText
                    code="CronEditor.Each"
                    value="Om de"
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <NumberEditor
                    value={hours}
                    onChange={onChangeHours}
                    min={1}
                    fitContent
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <LocalizedText
                    code="CronEditor.Hour"
                    value="uur"
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(MinutelyCronEditor);
