import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import ActivityTemplateConstructor from './Constructor/ActivityTemplateConstructor';
import ActivityTemplateDocumentEditor from './DocumentEditor/ActivityTemplateContentEditor';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import useSwitch from '../../../../../../../@Util/Switch/useSwitch';
import { EntityField } from '../../../../../../../@Api/Model/Implementation/EntityField';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import { EntityRelationshipDefinition } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import Input from '../../../../Input/Input';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import useTypes from '../../../../Type/Api/useTypes';

export interface ActivityTemplateEditorProps
{
    entity: Entity;
    templateEntity: Entity;
    onPreview: () => void;
    templateType: EntityType;
    templateRelationshipDefinition: EntityRelationshipDefinition;
    templateContentField: EntityField;
    templateFileField: EntityField;
    templateManagerRoute: string;
    commitContext?: CommitContext;
}

const ActivityTemplateEditor: React.FC<ActivityTemplateEditorProps> =
    props =>
    {
        const types = useTypes();
        const commitContext = useNewCommitContext(props.commitContext);
        const [ isInEditMode, startEditMode, stopEditMode ] = useSwitch(false);

        if (props.entity.hasValueForField(props.templateFileField, commitContext))
        {
            return <CardInset>
                <Input
                    entity={props.entity}
                    field={props.templateFileField}
                    labelPosition="top"
                    commitContext={commitContext}
                />
            </CardInset>;
        }
        else if (
            props.templateEntity?.hasValueForField(types.Template.Field.File)
            || !props.entity.hasValueForField(props.templateContentField, commitContext)
            || isInEditMode
        )
        {
            return <CardInset>
                <ActivityTemplateConstructor
                    {...props}
                    commitContext={commitContext}
                    onCancel={isInEditMode ? stopEditMode : undefined}
                />
            </CardInset>;
        }
        else
        {
            return <ActivityTemplateDocumentEditor
                {...props}
                commitContext={commitContext}
                onEdit={startEditMode}
            />;
        }
    };

export default observer(ActivityTemplateEditor);
