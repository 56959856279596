import React from 'react';
import { default as MuiCard } from '@material-ui/core/Card';
import CardInset from './CardInset';
import { classNames } from '../../../Util/Class/classNames';
import styles from './Card.module.scss';
import IsInCardContext from './IsInCardContext';

export interface CardClasses
{
    root?: string;
    inset?: string;
}

export interface CardProps
{
    inset?: boolean;
    square?: boolean;
    shadow?: boolean;
    classes?: CardClasses;
    style?: React.CSSProperties;
}

const Card: React.FC<CardProps> =
    props =>
    {
        return <MuiCard
            square={props.square}
            className={classNames(styles.root, props.shadow && styles.shadow, props.classes && props.classes.root)}
            style={props.style}
        >
            <IsInCardContext.Provider
                value={true}
            >
                {
                    props.inset
                        ?
                            <CardInset
                                classes={{
                                    root: props.classes && props.classes.inset
                                }}
                            >
                                {props.children}
                            </CardInset>
                        :
                            props.children
                }
            </IsInCardContext.Provider>
        </MuiCard>;
    };

export default Card;
