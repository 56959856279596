import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './NumberChart.module.scss';
import CircularPercentageChart from '../../../../../../../../../../../../../../@Future/Component/Generic/Chart/CircularPercentageChart';

export interface NumberChartProps
{
    value: number;
    label?: string;
    size: number;
    color?: string;
}

const NumberChart: React.FC<NumberChartProps> =
    props =>
    {
        return <CircularPercentageChart
            className={styles.numberChart}
            size={props.size}
            value={props.value > 0 ? 100 : 0}
            color={props.color}
            label={() => `${props.value}x`}
            subLabel={() => props.label}
        />;
    };

export default observer(NumberChart);