import { DataComparator } from '../ListStore';

export function createDateComparator<D>(retriever: (data: D) => Date): DataComparator<D>
{
    return (d1, d2) =>
    {
        const date1 = retriever(d1);
        const date2 = retriever(d2);

        if (!date1 && date2)
        {
            return -1;
        }
        else if (date1 && !date2)
        {
            return 1;
        }
        else if (!date1 && !date2)
        {
            return 0;
        }
        else
        {
            if (date1 > date2)
            {
                return 1;
            }
            else if (date1 < date2)
            {
                return -1;
            }
            else
            {
                return 0;
            }
        }
    };
}
