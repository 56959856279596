import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import { EntityContext } from '../../../Entity/@Model/EntityContext';
import { runInAction } from 'mobx';
import resolveInputFromFieldPath from '../../../Multiplayer/Model/Input/Api/resolveInputFromFieldPath';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';
import OptionalRelatedEntityLayout from '../../../../../@Api/Layout/Type/OptionalRelatedEntityLayout';
import RelationshipInput from '../../../Multiplayer/Model/Input/RelationshipInput';
import useOptionalRelatedEntity from './Api/useOptionalRelatedEntity';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import MenuButton from '../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import { primaryColor } from '../../../../../@Resource/Theme/Theme';
import FieldPathSelector from '../../../Entity/Path/FieldPathEditor/FieldPathSelector';
import uuid from '../../../../../@Util/Id/uuid';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import Layout from '../../../../../@Api/Layout/Layout';

export interface OptionalRelatedEntityLayoutEditorProps extends LayoutEditorProps<OptionalRelatedEntityLayout>
{

}

const OptionalRelatedEntityLayoutEditor: React.FC<OptionalRelatedEntityLayoutEditorProps> =
    props =>
    {
        const setLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.layout = layout),
                [
                    props.layout
                ]);

        const context =
            useComputed(
                () =>
                    EntityContext.fromEntityType(props.layout.parameter.type.type),
                [
                    props.layout
                ]);

        const setFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                        {
                            if (fieldPath.isRelationship)
                            {
                                const field = resolveInputFromFieldPath(fieldPath) as RelationshipInput;

                                props.layout.field = field;
                                props.layout.relatedEntityParameter =
                                    OptionalRelatedEntityLayout.getRelatedEntityParameter(
                                        uuid(),
                                        props.layout.parameter,
                                        field);
                            }
                        }),
                [
                    props.layout
                ]);

        const entity =
            useComputed(
                () =>
                    props.parameterAssignment?.getObjectValue(props.layout.parameter),
                [
                    props.parameterAssignment,
                    props.layout.parameter
                ]);

        const relatedEntityParameter =
            useComputed(
                () =>
                    props.layout.relatedEntityParameter,
                [
                    props.layout
                ]);

        const nextParameterDictionary =
            useComputed(
                () =>
                    relatedEntityParameter
                        ?
                            props.parameterDictionary.getNewDictionaryWithParameter(relatedEntityParameter)
                        :
                            props.parameterDictionary,
                [
                    relatedEntityParameter,
                    props.parameterDictionary
                ]);

        const optionalRelatedEntity =
            useOptionalRelatedEntity(
                entity,
                props.layout.field?.relationshipDefinition,
                props.layout.field?.isParent);

        const nextParameterAssignment =
            useComputed(
                () =>
                    props.parameterAssignment && relatedEntityParameter && optionalRelatedEntity
                        ?
                            props.parameterAssignment.getNewAssignmentWithParameter(
                                relatedEntityParameter,
                                new EntityValue(optionalRelatedEntity))
                        :
                            props.parameterAssignment,
                [
                    props.parameterAssignment,
                    relatedEntityParameter,
                    optionalRelatedEntity
                ]);

        if (props.layout.field)
        {
            return <ChildLayoutEditor
                {...props}
                parameterDictionary={nextParameterDictionary}
                parameterAssignment={nextParameterAssignment}
                childLayout={props.layout.layout}
                onChange={setLayout}
            />;
        }
        else
        {
            return <MenuButton
                icon="edit"
                tooltip="Veld selecteren"
                small
                fullWidth
                label={props.layout.field === undefined ? 'Selecteer een gerelateerd record...' : undefined}
                color={primaryColor}
            >
                <FieldPathSelector
                    context={context}
                    onSelect={setFieldPath}
                />
            </MenuButton>;
        }
    };

export default observer(OptionalRelatedEntityLayoutEditor);
