import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import Layout from '../../../../../../@Api/Layout/Layout';
import EditIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/EditIconButton/EditIconButton';
import Dialog from '../../../../../../@Future/Component/Generic/Dialog/Dialog';
import DialogContent from '../../../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import ItemLayoutEditorDialog from './Dialog/ItemLayoutEditorDialog';
import { primaryColor } from '../../../../../../@Resource/Theme/Theme';
import openDialog from '../../../../../../@Service/Navigation/Page/Hooks/openDialog';
import { cloneLayout } from '../../../../Layout/Editor/Api/cloneLayout';
import LayoutDependencyContext from '../../../../../../@Api/Layout/LayoutDependencyContext';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import CurrentUserContext from '../../../../User/CurrentUserContext';

export interface ItemLayoutEditorProps
{
    parameters: ParameterDictionary;
    value?: Layout;
    onChange: (value?: Layout) => void;
}

const ItemLayoutEditor: React.FC<ItemLayoutEditorProps> =
    ({
        parameters,
        value,
        onChange,
     }) =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const open =
            useCallback(
                async () =>
                {
                    const clonedLayout =
                        value
                            ?
                                await cloneLayout(
                                    value,
                                    new LayoutDependencyContext(parameters)
                                )
                            :
                                undefined;

                    openDialog(
                        onClose =>
                            <Dialog
                                onClose={onClose}
                                hideCloseButton
                                open
                            >
                                <DialogContent>
                                    <ItemLayoutEditorDialog
                                        parameters={parameters}
                                        initialItemLayout={clonedLayout}
                                        onClose={onClose}
                                        onSave={onChange}
                                    />
                                </DialogContent>
                            </Dialog>
                    );
                },
                [
                    value,
                    parameters,
                    onChange,
                ]
            );

        if (currentUserStore.isSupport)
        {
            return <ViewGroup
                orientation="horizontal"
                spacing={10}
                alignment="center"
            >
                <ViewGroupItem>
                    <CardHeader>
                        <LocalizedText
                            code="Configuration.View.ItemLayout"
                            value="Layout van item"
                        />
                    </CardHeader>
                </ViewGroupItem>
                <ViewGroupItem>
                    <EditIconButton
                        onClick={open}
                        color={
                            value ? primaryColor : undefined
                        }
                    />
                </ViewGroupItem>
            </ViewGroup>;
        }
        else
        {
            return null;
        }
    };

export default observer(ItemLayoutEditor);
