import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import styles from './Avatar.module.scss';
import { default as EntityAvatar } from '../../../../Avatar/Avatar';
import useHasAvatar from '../../../../Avatar/Api/useHasAvatar';
import HeaderContext from '../../../Context/HeaderContext';

export interface AvatarProps
{
    entity: Entity;
}

const Avatar: React.FC<AvatarProps> =
    props =>
    {
        const hasAvatar = useHasAvatar(props.entity);
        const isHeader = useContext(HeaderContext);

        if (hasAvatar)
        {
            return <div
                className={styles.root}
            >
                <EntityAvatar
                    entity={props.entity}
                    editable
                    size={isHeader ? 40 : 35}
                />
            </div>;
        }
        else
        {
            return null;
        }
    };

export default observer(Avatar);
