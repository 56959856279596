import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';

export interface OrganizationRegistrationForm
{
    organizationName: string;
    emailAddress: string;
    password: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    languageCode: string;
    gclId?: string;
    partnerId?: string;
    applicationPackIds: string[];
    internalEnvironmentId: string;
}

export interface OrganizationRegistrationResult
{
    organizationId: number;
    registrationStatusToken: string;
    singleShotLoginToken: string;
}

export type OrganizationStatus =
    'Initializing' |
    'InitializationFailed' |
    'Active' |
    'Deinitializing' |
    'DeinitializationFailed' |
    'Deinitialized';

export class OrganizationRegistrationController
{
    apiClient: ApiClient;

    constructor(apiClient: ApiClient)
    {
        this.apiClient = apiClient;
    };

    register(form: OrganizationRegistrationForm): Promise<OrganizationRegistrationResult>
    {
        return this.apiClient.request(
            new ApiRequest<any>(
                '/public/organization/register',
                Method.Post,
                JSON.stringify(form),
                undefined,
                true,
                'application/json'))
            .then(
                result =>
                {
                    if (result.ok)
                    {
                        return Promise.resolve(result.data);
                    }
                    else
                    {
                        return Promise.reject(result);
                    }
                });
    }

    getStatus(result: OrganizationRegistrationResult): Promise<any>
    {
        return this.apiClient.request(
            new ApiRequest<any>(
                '/public/organization/status',
                Method.Post,
                JSON.stringify(result),
                undefined,
                true,
                'application/json'))
            .then(
                result =>
                {
                    return Promise.resolve(result.data);
                }
            );
    }
}
