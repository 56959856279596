import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Connection from '../../Model/Connection';
import useResult from '../../../Entity/Selection/Hooks/useResult';
import useTypes from '../../../Entity/Type/Api/useTypes';
import Avatar from '../../../Entity/Avatar/Avatar';
import styles from './Player.module.scss';
import Tooltip from '../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import MultiplayerContext from '../../Context/MultiplayerContext';

export interface PlayerProps
{
    connection: Connection;
}

const Player: React.FC<PlayerProps> =
    props =>
    {
        const multiplayer = useContext(MultiplayerContext);

        const types = useTypes();
        const [ entity ] =
            useResult(
                types.Relationship.Person.Contact.Employee.Type,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.eq(
                                rootPath.field(types.Entity.Field.Id),
                                undefined,
                                props.connection.entityId)),
                [
                    types,
                    props.connection
                ]);

        if (entity)
        {
            return <Tooltip
                title={multiplayer.connection === props.connection ? undefined : `${entity.name} kijkt ook naar deze pagina`}
            >
                <div
                    className={styles.root}
                >
                    <Avatar
                        entity={entity}
                        linkMode="Link"
                    />
                </div>
            </Tooltip>;
        }
        else
        {
            return null;
        }
    };

export default observer(Player);
