import React, { useCallback, useMemo } from 'react';
import { EntityContext } from '../../../../@Model/EntityContext';
import { EntityPath } from '../../../@Model/EntityPath';
import useSwitch from '../../../../../../../@Util/Switch/useSwitch';
import { EntityFieldPath } from '../../../@Model/EntityFieldPath';
import Popper from '../../../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import FieldPathSelector from '../../FieldPathSelector';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';

export interface FieldPathSelectorRenderProps
{
    open: () => void;
}

export interface FieldPathSelectorProps
{
    entityType: EntityType;
    onSelect: (value: EntityFieldPath) => void;
    view: (props: FieldPathSelectorRenderProps) => React.ReactNode;
}

export const SimpleFieldPathSelector: React.FC<FieldPathSelectorProps> =
    ({
         entityType,
         onSelect,
         view,
     }) =>
    {
        const fieldContext =
            useMemo(
                () =>
                    new EntityContext(
                        [],
                        EntityPath.fromEntityType(entityType)),
                [
                    entityType
                ]);
        const [ isFieldSelectorOpen, openFieldSelector, closeFieldSelector ] = useSwitch(false);
        const selectFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                {
                    onSelect(fieldPath);
                    closeFieldSelector();
                },
                [
                    onSelect,
                    closeFieldSelector,
                ]);

        return <Popper
            reference={
                <>
                    {
                        view({
                            open: openFieldSelector,
                        })
                    }
                </>
            }
            popper={
                <Card>
                    <FieldPathSelector
                        context={fieldContext}
                        onSelect={selectFieldPath}
                    />
                </Card>
            }
            open={isFieldSelectorOpen}
            onClose={closeFieldSelector}
        />;
    };
