import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import LocalizerContext from '../../../../../@Service/Localization/LocalizerContext';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TabBar from '../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import LocalizedLayout from '../../../../../@Api/Layout/Type/LocalizedLayout';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import useSwitch from '../../../../../@Util/Switch/useSwitch';
import Popper from '../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import Item from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import useResults from '../../../Entity/Selection/Hooks/useResults';
import useTypes from '../../../Entity/Type/Api/useTypes';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../@Future/Component/Generic/Loader/Loader';
import LayoutConstructor from '../../Editor/Constructor/LayoutConstructor';
import Layout from '../../../../../@Api/Layout/Layout';
import { IObservableArray, runInAction } from 'mobx';
import LocalizedLayoutItem from '../../../../../@Api/Layout/Type/LocalizedLayoutItem';
import uuid from '../../../../../@Util/Id/uuid';
import MenuButton from '../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import Flag from '../../../../../@Future/Component/Generic/Flag/Flag';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import ComputationEditor from '../../../Entity/Viewer/Content/Automation/Editor/Computation/ComputationEditor';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';

export interface LocalizedLayoutEditorProps extends LayoutEditorProps<LocalizedLayout>
{

}

const LocalizedLayoutEditor: React.FC<LocalizedLayoutEditorProps> =
    props =>
    {
        const localizer = useContext(LocalizerContext);
        const types = useTypes();
        const [ selectedLanguageCode, setSelectedLanguageCode ] = useState(localizer.languageCode);
        const selectedItem =
            useComputed(
                () =>
                    props.layout.items.find(item => item.languageCode === selectedLanguageCode),
                [
                    props.layout,
                    selectedLanguageCode
                ]);
        const setSelectedItemLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            selectedItem.layout = layout),
                [
                    selectedItem
                ]);

        const [ isLanguagePickerOpen, openLanguagePicker, closeLanguagePicker ] = useSwitch(false);

        const languages = useResults(
            isLanguagePickerOpen &&
            types.Datastore.Language.Type,
            () => {},
            [
                types
            ]
        );
        const languageCodes =
            useMemo(
                () =>
                    languages?.map(
                        language =>
                            language.getObjectValueByField(types.Datastore.Field.Code)?.toLowerCase())
                        .filter(
                            languageCode => languageCode !== undefined),
                [
                    types,
                    languages
                ]);
        const usedLanguageCodes =
            useComputed(
                () =>
                    new Set(props.layout.items.map(item => item.languageCode)),
                [
                    props.layout
                ]);
        const availableLanguageCodes =
            useMemo(
                () =>
                    languageCodes?.filter(
                        languageCode =>
                            !usedLanguageCodes.has(languageCode)),
                [
                    languageCodes,
                    usedLanguageCodes
                ]);

        const createNewItem =
            useCallback(
                (languageCode: string) =>
                {
                    setSelectedLanguageCode(languageCode);
                    closeLanguagePicker();
                },
                [
                    setSelectedLanguageCode,
                    closeLanguagePicker
                ]);
        const onConstruct =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.items.push(
                                new LocalizedLayoutItem(
                                    uuid(),
                                    selectedLanguageCode,
                                    layout))),
                [
                    props.layout,
                    selectedLanguageCode
                ]);

        const deleteSelectedItem =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (props.layout.items as IObservableArray).remove(selectedItem)),
                [
                    props.layout,
                    selectedItem
                ]);

        const context =
            useComputed(
                () =>
                    new FunctionContext(
                        props.parameterDictionary,
                        props.parameterAssignment,
                        props.commitContext
                    ),
                [
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        const onChangeLanguageComputation =
            useCallback(
                (computation: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.layout.language = computation
                    ),
                [
                    props.layout
                ]
            );

        const languageType =
            useMemo(
                () =>
                    new EntityValueType(types.Datastore.Language.Type),
                [
                    types
                ]
            );

        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            <ViewGroupItem>
                <Input
                    label={
                        <LocalizedText
                            code="Language"
                            value="Taal"
                        />
                    }
                    labelPosition="left"
                >
                    <ComputationEditor
                        context={context}
                        value={props.layout.language}
                        onChange={onChangeLanguageComputation}
                        type={languageType}
                    />
                </Input>
            </ViewGroupItem>
            <ViewGroupItem>
                <TabBar
                    value={selectedItem ? selectedLanguageCode : 'new'}
                    appendix={
                        selectedItem &&
                            <MenuButton>
                                <Menu>
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Generic.Delete"
                                                value="Verwijderen"
                                            />
                                        }
                                        onClick={deleteSelectedItem}
                                    />
                                </Menu>
                            </MenuButton>
                    }
                >
                    {
                        props.layout.items.map(
                            item =>
                                <Tab
                                    key={item.id}
                                    value={item.languageCode}
                                    onClick={setSelectedLanguageCode}
                                >
                                    <Flag
                                        languageCode={item.languageCode}
                                    />
                                </Tab>)
                    }
                    {
                        !selectedItem &&
                            <Tab
                                value="new"
                                onClick={openLanguagePicker}
                            >
                                <Flag
                                    languageCode={selectedLanguageCode}
                                />
                            </Tab>
                    }
                    <Popper
                        reference={
                            <Tab
                                value="constructor"
                                onClick={openLanguagePicker}
                            >
                                +
                            </Tab>
                        }
                        popper={
                            <Card>
                                {
                                    availableLanguageCodes
                                        ?
                                            <Menu>
                                                {
                                                    availableLanguageCodes.map(
                                                        languageCode =>
                                                            <Item
                                                                onClick={createNewItem}
                                                                value={languageCode}
                                                                name={
                                                                    <ViewGroup
                                                                        orientation="horizontal"
                                                                        spacing={15}
                                                                        alignment="center"
                                                                    >
                                                                        <ViewGroupItem>
                                                                            <Flag
                                                                                languageCode={languageCode}
                                                                            />
                                                                        </ViewGroupItem>
                                                                        <ViewGroupItem>
                                                                            {languageCode}
                                                                        </ViewGroupItem>
                                                                    </ViewGroup>
                                                                }
                                                            />)
                                                }
                                            </Menu>
                                        :
                                            <CardInset>
                                                <Centered
                                                    horizontal
                                                >
                                                    <Loader />
                                                </Centered>
                                            </CardInset>
                                }
                            </Card>
                        }
                        open={isLanguagePickerOpen}
                        onClose={closeLanguagePicker}
                    />
                </TabBar>
            </ViewGroupItem>
            {
                selectedItem &&
                    <ViewGroupItem>
                        <ChildLayoutEditor
                            key={selectedLanguageCode}
                            {...props}
                            childLayout={selectedItem.layout}
                            onChange={setSelectedItemLayout}
                        />
                    </ViewGroupItem>
            }
            {
                !selectedItem &&
                    <ViewGroupItem>
                        <LayoutConstructor
                            mode="Button"
                            parameterDictionary={props.parameterDictionary}
                            onConstruct={onConstruct}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(LocalizedLayoutEditor);
