import { ComputationType } from '../../ComputationType';
import { EntityAggregateComputationEditorStore } from './EntityAggregateComputationEditorStore';
import { BaseComponentProps } from '../../../../../../@Framework/Component/BaseComponent';
import { EntityAggregateComputationEditor } from './EntityAggregateComputationEditor';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import { EntityAggregateComputationSpecification } from './EntityAggregateComputationSpecification';
import { ComputationContext } from '../../../ComputationContext';
import { ComputationTypeStore } from '../../../ComputationTypeStore';
import { ComputationEditorStore } from '../../../ComputationEditorStore';
import { DataObjectSpecification } from '../../../../DataObject/Model/DataObjectSpecification';
import { Aggregate } from '../../../../DataObject/Model/Aggregate';
import { EntityPath } from '../../../../Entity/Path/@Model/EntityPath';
import { EntityTypeStore } from '../../../../Entity/Type/EntityTypeStore';
import { EntityContext } from '../../../../Entity/@Model/EntityContext';
import { EntityPathRootNode } from '../../../../Entity/Path/@Model/Node/EntityPathRootNode';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import { DataObjectStore } from '../../../../DataObject/DataObjectStore';
import { DataDescriptor } from '../../../../DataObject/Model/DataDescriptor';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import { EntityAggregateComputation } from './EntityAggregateComputation';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';

export class EntityAggregateComputationType extends ComputationType<EntityAggregateComputationEditorStore, EntityAggregateComputationSpecification, EntityAggregateComputation>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return 'EntityAggregate';
    }

    name(): string
    {
        return this.localizationStore.translate('Computation.AggregateTotalAvarage'); // Aggregate (Count, sum, avarage)
    }

    allow(context: ComputationContext): boolean
    {
        return context.entityContext != null;
    }

    isTerminal(): boolean
    {
        return false;
    }

    isResource(): boolean
    {
        return false;
    }

    fromSpecification(specification: EntityAggregateComputationSpecification): EntityAggregateComputation
    {
        return new EntityAggregateComputation(
            specification.entityContextParameter,
            (Aggregate as any)[specification.aggregate],
            specification.entityPath
                ?
                    EntityPath.construct(
                        specification.entityPath,
                        this.entityTypeStore
                    )
                :
                    undefined,
            specification.computation
                ?
                    this.computationTypeStore.fromSpecification(specification.computation)
                :
                    undefined);
    }

    toSpecification(computation: EntityAggregateComputation): EntityAggregateComputationSpecification
    {
        return {
            type: this.id(),
            entityContextParameter: computation.entityContextParameter,
            aggregate: Aggregate[computation.aggregate],
            entityPath: computation.entityPath.descriptor,
            computation: this.computationTypeStore.toSpecification(computation.computation)
        };
    }

    compute(context: ComputationContext,
            computation: EntityAggregateComputation): DataObject
    {
        let values: DataObject[] = [];

        const traversedContext =
            context.entityContext.traverse(
                computation.entityPath,
                computation.entityContextParameter,
                context.commitContext
            );

        traversedContext
            .getContext(computation.entityContextParameter)
            .split()
            .forEach(
                childContext =>
                {
                    if (computation.computation)
                    {
                        let computationResult =
                            computation.computation.compute(
                                Object.assign(
                                    {},
                                    context,
                                    {
                                        entityContext:
                                            traversedContext.setContext(
                                                computation.entityContextParameter,
                                                childContext)
                                    }));

                        if (computationResult)
                        {
                            values.push(computationResult);
                        }
                        else
                        {
                            values.push(
                                new DataObject(
                                    new DataObjectSpecification(
                                        this.dataObjectStore.getTypeById('Number'),
                                        {}),
                                    new DataDescriptor()));
                        }
                    }
                    else
                    {
                        values.push(
                            new DataObject(
                                new DataObjectSpecification(
                                    this.dataObjectStore.getTypeById('Number'),
                                    {}),
                                new DataDescriptor()));
                    }
                });

        return DataObject.aggregate(
            this.dataObjectStore,
            values,
            computation.aggregate);
    }

    description(context: ComputationContext,
                specification: EntityAggregateComputationSpecification): string
    {
        return this.name();
    }

    editorStore(context: ComputationContext,
                specification: EntityAggregateComputationSpecification): EntityAggregateComputationEditorStore
    {
        let entityPath =
            specification.entityPath
                ?
                    EntityPath.construct(
                        specification.entityPath,
                        this.entityTypeStore)
                :
                    context.entityContext.entityPath;
        let childContext =
            Object.assign({}, context,
                {
                    entityContext:
                        entityPath ?
                            new EntityContext(
                                [],
                                new EntityPath([ new EntityPathRootNode(entityPath.entityType) ]))
                        :
                            context.entityContext
                });

        return new EntityAggregateComputationEditorStore(
            this as any,
            context,
            specification,
            (Aggregate as any)[specification.aggregate || 'Count'],
            entityPath,
            childContext,
            specification.computation
                ?
                    ComputationEditorStore.construct(
                        childContext,
                        specification.computation,
                        this.computationTypeStore)
                :
                    undefined);
    }

    editorView(): React.ComponentClass<BaseComponentProps<EntityAggregateComputationEditorStore>>
    {
        return EntityAggregateComputationEditor;
    }

    editorSpecification(store: EntityAggregateComputationEditorStore): EntityAggregateComputationSpecification
    {
        return {
            type: this.id(),
            aggregate: Aggregate[store.aggregate],
            entityPath: store.entityPath ? store.entityPath.descriptor : undefined,
            computation: store.computationStore
                ?
                    store.computationStore.type.editorSpecification(store.computationStore.editorStore)
                :
                    undefined
        };
    }

    editorResultSpecification(store: EntityAggregateComputationEditorStore): DataObjectSpecification
    {
        if (store.computationStore)
        {
            return store.computationStore.type.editorResultSpecification(store.computationStore);
        }
        else
        {
            return null;
        }
    }

    entityFieldPaths(specification: EntityAggregateComputationSpecification,
                     context: EntityContext,
                     parameter?: string): EntityFieldPath[]
    {
        let paths: EntityFieldPath[] = [];
        let path =
            specification.entityPath
                ?
                    EntityPath.construct(
                        specification.entityPath,
                        this.entityTypeStore
                    )
                :
                    undefined;

        if (path)
        {
            const aggregateContext = context.traverse(path, parameter);
            const aggregatePath = aggregateContext.getContext(parameter).entityPath;

            if (parameter === specification.entityContextParameter)
            {
                paths.push(
                    new EntityFieldPath(
                        aggregatePath));
            }

            if (specification.computation)
            {
                let computationType = this.computationTypeStore.getTypeById(specification.computation.type);

                if (computationType)
                {
                    paths.push(
                        ...computationType.entityFieldPaths(
                            specification.computation,
                            aggregateContext,
                            parameter));
                }
            }

            return paths;
        }
        else
        {
            return [];
        }
    }

    // ----------------------- Private logic ------------------------
}
