import * as React from 'react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { ColorPickerStore } from './ColorPickerStore';
import SketchPicker from 'react-color/lib/components/sketch/Sketch';
import { observer } from 'mobx-react';
import Button from '../Button/Button';

@observer
export class ColorPicker extends BaseComponent<ColorPickerStore>
{
    renderComponent(store: ColorPickerStore)
    {
        return <div
            style={{
                width: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        flex: '1 1 auto',
                        borderRadius: 5,
                        cursor: 'pointer',
                        backgroundColor: '#fff',
                        padding: 6,
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)'
                    }}
                    onClick={store.open}
                >
                    <div
                        style={{
                            width: '100%',
                            height: 13,
                            backgroundColor: store.colorCss ? store.colorCss : '#fff',
                            border: store.colorCss ? undefined : '1px solid #f3f3f3'
                        }}
                    />
                </div>
                {
                    store.isButtonVisible &&
                        <Button
                            store={store.clearButtonStore}
                        />
                }
            </div>
            {
                store.isOpen &&
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: 2000
                        }}
                    >
                        <div
                            style={{
                                position: 'fixed',
                                top: '0px',
                                right: '0px',
                                bottom: '0px',
                                left: '0px',
                            }}
                            onClick={store.close}
                        />
                        <SketchPicker
                            color={store.rgba}
                            onChange={store.change}
                        />
                    </div>
            }
        </div>;
    }
}
