import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '../../../@Future/Component/Generic/Tooltip/Tooltip';
import Icon from '../../../@Future/Component/Generic/Icon/Icon';

export interface GoogleMapsPinProps
{
    // lat and lng properties are used by GoogleMapReact
    lat: number;
    lng: number;
    label?: string;
}

const useStyles =
    makeStyles({
        pin: {
            position: 'absolute',
            color: 'red',
            transform: 'translate(-50%, -100%)',
            cursor: 'pointer'
        }
    });

export const GoogleMapsPin: React.FC<GoogleMapsPinProps> =
    observer(
        (
            props
        ) =>
        {
            const classes = useStyles();

            return <div
                className={classes.pin}
            >
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            {props.label}
                        </div>
                    }
                >
                    <Icon
                        icon="location_on"
                        size={30}
                    />
                </Tooltip>

            </div>
        }
    );
