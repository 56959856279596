import { reference, type } from '../../../@Util/Serialization/Serialization';
import { Entity } from './Entity';
import { OrganizationSetting } from './OrganizationSetting';
import { User } from './User';

@type('Organization')
export class Organization
{
    // ------------------- Persistent Properties --------------------

    id: number;
    uuid: string;
    @reference(undefined, 'Organization') parentOrganization: Organization;
    name: string;
    isEnabled: boolean;
    isCustomer: boolean;
    @reference(undefined, 'Entity') entity: Entity;
    @reference(undefined, 'OrganizationSetting') organizationSettings: OrganizationSetting[];
    @reference(undefined, 'User') users: User[];

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
