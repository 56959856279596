import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import localizeText from '../../Localization/localizeText';
import getTypes from '../../../@Component/Domain/Entity/Type/Api/getTypes';
import getEntityByUuid from '../../Entity/Bespoke/getEntityByUuid';
import LayoutTemplate from '../Template/LayoutTemplate';
import DynamicParameterAssignment from '../../Automation/Function/Dynamic/DynamicParameterAssignment';
import AutomationDependencyContext from '../../Automation/AutomationDependencyContext';
import { Entity } from '../../Model/Implementation/Entity';

export default class TemplateLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable templateId: string;
    @observable.ref template: LayoutTemplate;
    @observable.ref parameterAssignment: DynamicParameterAssignment;

    // ------------------------ Constructor -------------------------

    constructor(templateId: string,
                template: LayoutTemplate,
                parameterAssignment: DynamicParameterAssignment)
    {
        super();

        this.templateId = templateId;
        this.template = template;
        this.parameterAssignment = parameterAssignment;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Generic.Template', 'Sjabloon');
    }

    validate(): Validation[]
    {
        return this.parameterAssignment?.validate() || [];
    }

    getDependencies(): Dependency[]
    {
        return this.parameterAssignment?.getDependencies() || [];
    }

    toDescriptor()
    {
        return {
            type: 'Template',
            templateId: this.templateId,
            parameterAssignment: this.parameterAssignment?.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const templateId = descriptor.templateId;
        const template = await TemplateLayout.getTemplateById(templateId, dependencyContext);
        const parameterAssignment =
            template && descriptor.parameterAssignment
                ?
                    await DynamicParameterAssignment.fromDescriptor(
                        descriptor.parameterAssignment,
                        template.parameters,
                        dependencyContext)
                :
                    undefined;

        return new TemplateLayout(
            descriptor.templateId,
            template,
            parameterAssignment);
    }

    static async getTemplateById(id: string,
                                 dependencyContext: AutomationDependencyContext): Promise<LayoutTemplate>
    {
        const types = getTypes();
        const { value: template } = await getEntityByUuid(types.LayoutTemplate.Type, id);

        if (template)
        {
            return TemplateLayout.getTemplateByEntity(
                template,
                dependencyContext);
        }
        else
        {
            return undefined;
        }
    }

    static async getTemplateByEntity(template: Entity,
                                     dependencyContext: AutomationDependencyContext): Promise<LayoutTemplate>
    {
        const types = getTypes();
        const specification = template.getObjectValueByField(types.LayoutTemplate.Field.Specification);

        if (specification)
        {
            return LayoutTemplate.fromDescriptor(
                specification,
                dependencyContext);
        }
        else
        {
            return undefined;
        }
    }

    // ----------------------- Private logic ------------------------
}
