import { observer } from 'mobx-react';
import * as React from 'react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { RootStore } from './RootStore';
import { DragDropContext } from 'react-beautiful-dnd';
import { DndProvider } from 'react-dnd';
import { ApiState } from './ApiState/ApiState';
import { DragAndDropBar } from '../../Generic/DragAndDrop/Bar/DragAndDropBar';
import { Environment } from './Environment/Environment';
import HTML5Backend from 'react-dnd-html5-backend';
import RootContext from './RootContext';
import EntityTypeContext from '../../Domain/Entity/Type/EntityTypeContext';
import LocalizerContext from '../../../@Service/Localization/LocalizerContext';
import RouterContext from '../../../@Service/Router/RouterContext';
import LocalizationContext from '../../../@Service/Localization/LocalizationContext';
import DataObjectContext from '../../Domain/DataObject/DataObjectContext';
import ApiControllerContext from '../../../@Api/Controller/ApiControllerContext';
import DragAndDropInitialization from '../../Domain/DragAndDrop/Initialization/DragAndDropInitialization';
import EntityCacheContext from '../../Service/Entity/EntityCacheContext';
import { ModalProvider } from 'react-modal-hook/dist';
import { SnackbarProvider } from 'notistack';
import ErrorDialog from '../../Error/ErrorDialog';
import ViewGroup from '../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Logo from '../../Generic/Logo/Logo';
import Centered from '../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../@Future/Component/Generic/Loader/Loader';
import AuthenticationManagerContext from '../../../@Service/Authentication/AuthenticationManagerContext';

const snackbarAnchor = {
    vertical: 'bottom',
    horizontal: 'right'
};

@observer
export class Root extends BaseComponent<RootStore>
{
    renderLoader(store: RootStore)
    {
        return <Centered
            horizontal
            vertical
        >
            <Loader />
        </Centered>;
    }

    renderComponent(store: RootStore): any
    {
        if (store.initializationError)
        {
            return <Centered
                horizontal
                vertical
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={20}
                    alignment="center"
                    justification="center"
                >
                    <ViewGroupItem>
                        <Logo />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <ErrorDialog
                            error={store.initializationError}
                            onClose={store.recoverFromError}
                            title={store.errorDialogTranslations.title}
                            description={store.errorDialogTranslations.description}
                            action={store.errorDialogTranslations.action}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </Centered>;
        }
        else if (store.context.authenticationManager.isLoading)
        {
            return <Centered
                horizontal
                vertical
            >
                <Loader />
            </Centered>;
        }
        else
        {
            return <RootContext.Provider
                value={store}
            >
                <DndProvider
                    backend={HTML5Backend}
                >
                    <DragDropContext
                        onBeforeDragStart={store.context.dragAndDropStore.onBeforeDragStart}
                        onDragStart={store.context.dragAndDropStore.onDragStart}
                        onDragEnd={store.context.dragAndDropStore.onDragEnd}
                    >
                        <DragAndDropInitialization>
                            <AuthenticationManagerContext.Provider
                                value={store.context.authenticationManager}
                            >
                                <EntityTypeContext.Provider
                                    value={store.context.entityTypeStore}
                                >
                                    <EntityCacheContext.Provider
                                        value={store.context.entityCacheService}
                                    >
                                        <LocalizerContext.Provider
                                            value={store.context.localize}
                                        >
                                            <LocalizationContext.Provider
                                                value={store.context.localizationStore}
                                            >
                                                <ApiControllerContext.Provider
                                                    value={store.context.apiControllerStore}
                                                >
                                                    <DataObjectContext.Provider
                                                        value={store.context.dataObjectStore}
                                                    >
                                                        <RouterContext.Provider
                                                            value={store.context.router}
                                                        >
                                                            <SnackbarProvider
                                                                maxSnack={3}
                                                                anchorOrigin={snackbarAnchor as any}
                                                            >
                                                                <ModalProvider>
                                                                    <Environment
                                                                        store={store}
                                                                    />
                                                                    <DragAndDropBar
                                                                        store={store.context.dragAndDropStore}
                                                                    />
                                                                    <ApiState
                                                                        store={store.context.apiStateStore}
                                                                    />
                                                                </ModalProvider>
                                                            </SnackbarProvider>
                                                        </RouterContext.Provider>
                                                    </DataObjectContext.Provider>
                                                </ApiControllerContext.Provider>
                                            </LocalizationContext.Provider>
                                        </LocalizerContext.Provider>
                                    </EntityCacheContext.Provider>
                                </EntityTypeContext.Provider>
                            </AuthenticationManagerContext.Provider>
                        </DragAndDropInitialization>
                    </DragDropContext>
                </DndProvider>
            </RootContext.Provider>;
        }
    }
}
