import { observable } from 'mobx';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import ParameterDictionary from '../../Parameter/ParameterDictionary';
import DynamicParameterAssignment from './DynamicParameterAssignment';
import DynamicFunctionRepository from './Repository/DynamicFunctionRepository';
import { DynamicFunction } from './DynamicFunction';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import DynamicFunctionRepositoryManager from './Repository/DynamicFunctionRepositoryManager';
import { FunctionType } from '../FunctionType';
import localizeText from '../../../Localization/localizeText';

export default class DynamicFunctionInvocation
{
    // ------------------------- Properties -------------------------

    @observable.ref parameterDictionary: ParameterDictionary;
    @observable.ref parameterAssignment: DynamicParameterAssignment;
    @observable.ref repository: DynamicFunctionRepository<DynamicFunction<any, any>>;
    @observable.ref functionType: FunctionType;
    @observable.ref function: DynamicFunction<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(parameterDictionary: ParameterDictionary,
                parameterAssignment: DynamicParameterAssignment,
                repository: DynamicFunctionRepository<DynamicFunction<any, any>>,
                functionType: FunctionType,
                func: DynamicFunction<any, any>)
    {
        this.parameterDictionary = parameterDictionary;
        this.parameterAssignment = parameterAssignment;
        this.repository = repository;
        this.functionType = functionType;
        this.function = func;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getReturnType(): ValueType<any>
    {
        return this.function.getReturnType();
    }

    getName()
    {
        return this.function.getName();
    }

    validate(): Validation[]
    {
        const validations: Validation[] = [];

        this.parameterDictionary.parameterById
            .forEach(
                parameter =>
                {
                    if (this.parameterAssignment.getComputation(parameter) === undefined
                        && parameter.isRequired)
                    {
                        validations.push(
                            new Validation(
                                'Error',
                                localizeText(
                                    'ParameterIsRequired',
                                    `De parameter '${parameter.name}' is verplicht.`
                                )
                            )
                        );
                    }
                });

        this.parameterAssignment.computationByParameter
            .forEach(
                (computation, parameter) =>
                {
                    validations.push(
                        ...computation.validate());
                });

        return validations;
    }

    getDependencies(): Dependency[]
    {
        return [
            ...Array.from(this.parameterAssignment.computationByParameter.values())
                .map(
                    computation =>
                        computation.getDependencies())
                .reduce(
                    (a, b) =>
                        a.concat(b),
                    [])
        ];
    }

    toDescriptor()
    {
        return {
            parameterAssignment: this.parameterAssignment.toDescriptor(),
            repositoryId: this.repository.id,
            functionType: this.functionType,
            functionId: this.function.getId()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const repositoryManager = loadModuleDirectly(DynamicFunctionRepositoryManager);
        const repository = repositoryManager.getRepositoryById(descriptor.repositoryId);
        let func: DynamicFunction<any, any>;

        if (repository)
        {
            func = await repository.getFunctionById(descriptor.functionId);
        }

        const parameterDictionary = new ParameterDictionary(func?.getParameters() || []);

        return new DynamicFunctionInvocation(
            parameterDictionary,
            await DynamicParameterAssignment.fromDescriptor(
                descriptor.parameterAssignment,
                parameterDictionary,
                dependencyContext),
            repository,
            descriptor.functionType,
            func);
    }

    // ----------------------- Private logic ------------------------
}
