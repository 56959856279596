import { useContext, useMemo } from 'react';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import EntityTypeContext from '../../../Type/EntityTypeContext';
import { isAttachedType } from './getIsAttachedType';

export default function useIsAttachedEntityType(entityType: EntityType)
{
    const entityTypeStore = useContext(EntityTypeContext);

    return useMemo(
        () =>
        {
            return isAttachedType(
                entityTypeStore,
                entityType);
        },
        [
            entityType,
            entityTypeStore
        ]);
}

