import { DataObjectType } from './DataObjectType';
import { computed, observable } from 'mobx';
import { DataObjectStore } from '../DataObjectStore';
import { DataObject } from './DataObject';

export class DataObjectSpecification
{
    // ------------------------- Properties -------------------------

    @observable.ref type: DataObjectType;
    @observable.ref data: any;
    @observable isRequired: boolean;

    // ------------------------ Constructor -------------------------

    constructor(type: DataObjectType,
                data: any,
                isRequired: boolean = false)
    {
        this.type = type;
        this.data = data;
        this.isRequired = isRequired;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get isDiscrete(): boolean
    {
        return this.type.getDiscreteValues != null;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getDiscreteValues(dataObjectStore: DataObjectStore): Promise<DataObject[]>
    {
        if (this.isDiscrete)
        {
            return new Promise(
                (resolve, reject) =>
                {
                    this.type.getDiscreteValues(this, dataObjectStore)
                        .then(values =>
                        {
                            let dataObjects = values.map(value => (DataObject.constructFromValue(this, value)));

                            dataObjectStore.initializeDataObjects(dataObjects)
                                .then(() => (resolve(dataObjects)))
                                .catch(reject);
                        })
                        .catch(reject);
                });
        }
        else
        {
            return Promise.resolve([]);
        }
    }

    clone(): DataObjectSpecification
    {
        return new DataObjectSpecification(this.type, this.data);
    }

    // ----------------------- Private logic ------------------------
}
