import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { ButtonStore } from '../../../../../../Generic/Button/ButtonStore';
import { computed } from 'mobx';
import { createEntityListNameComparator, EntityListProps, EntityListStore } from '../../../../List/EntityListStore';
import { EntityViewerStore } from '../../EntityViewerStore';
import { EntityTypeStore } from '../../../../Type/EntityTypeStore';
import { DataObjectStore } from '../../../../../DataObject/DataObjectStore';
import { injectWithQualifier } from '../../../../../../../@Util/DependencyInjection/index';
import { createTransactionalModel, getAdministration } from '../../../../../../../@Util/TransactionalModelV2/index';
import { EntityExpansionBuilder } from '../../../../Selection/Builder/EntityExpansionBuilder';
import { LocalizationStore } from '../../../../../../../@Service/Localization/LocalizationStore';
import { CurrentUserStore } from '../../../../../User/CurrentUserStore';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityCaptionTextStore } from '../../../../Caption/EntityCaptionTextStore';
import { getOrCompute } from '../../../../../../../@Framework/Store/BaseStore';
import { RelatedEntityPath } from '../../../../Path/@Model/RelatedEntityPath';

export interface EntityExpansionListProps extends Partial<EntityListProps>
{
    viewerStore: EntityViewerStore;
    paths: EntityPath[];
}

export class EntityExpansionListStore<P extends EntityExpansionListProps = EntityExpansionListProps> extends EntityListStore<EntityListProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;

    // ------------------------- Properties -------------------------

    // @observable paths: EntityPath[];

    // ------------------------ Constructor -------------------------

    constructor(props: P)
    {
        super({
            showOpenInFullscreenIcon: true,
            isEmbedded: true,
            onVisit: entity =>
            {
                if (entity.entityType.bespoke.isOpenable(
                    entity,
                    this.pathFromRelatedEntity))
                {
                    return entity.entityType.bespoke.onOpen(
                        entity,
                        this.pathFromRelatedEntity)
                        .then(
                            () =>
                                this.viewerStore.openEntityViewerRight(entity));
                }
                else
                {
                    return entity.entityType.bespoke.onOpen(
                        entity,
                        this.pathFromRelatedEntity);
                }
            },
            selection:
                () =>
                    this.relatedEntities,
            isReactive: true,
            comparator: createEntityListNameComparator(),
            summaryPrimaryTextCaption:
                (entity, store) =>
                    new EntityCaptionTextStore({
                        entity: entity,
                        pathFromRelatedEntity:
                            new RelatedEntityPath(
                                this.viewerStore.entity,
                                this.paths[0])
                    }),
            summaryButtons:
                (entity, listStore, itemStore) =>
                    [
                        new ButtonStore(
                            {
                                icon: 'link_off',
                                tooltip: this.localizationStore.translate('EntityExpansionList.Tooltip.Detach'), // Detach
                                onClick:
                                    () =>
                                    {
                                        const path = this.getPathByEntity(entity);
                                        const transactional =
                                            createTransactionalModel(
                                                entity,
                                                undefined,
                                                getAdministration(this.viewerStore.entity)?.context);

                                        transactional.getRelationshipsByDefinition(
                                            !path.lastJoinNode.isParent,
                                            path.lastJoinNode.relationshipDefinition)
                                            .forEach(
                                                relationship =>
                                                {
                                                    transactional.deleteRelationship(
                                                        relationship,
                                                        !path.lastJoinNode.isParent);
                                                });

                                        return transactional.checkAndDoCommit()
                                            .then(() =>
                                            {
                                                listStore.deleteItemStore(itemStore);
                                            });
                                    },
                                isVisible:
                                    () =>
                                    {
                                        const path = this.getPathByEntity(entity);

                                        return this.currentUserStore.rightProfile.canUpdate(this.viewerStore.entity)
                                            && !path.lastJoinNode.relationshipDefinition.isMandatory(!path.lastJoinNode.isParent);
                                    }
                            })
                    ],
            ...props
        });
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return new EntityExpansionBuilder(
            this.viewerStore.entity.entityType,
            [ this.viewerStore.entity ],
            [
                this.paths[0]
            ])
            .expand()
            .then(() => super.initialize());
    }

    // -------------------------- Computed --------------------------

    @computed
    get viewerStore(): EntityViewerStore
    {
        return getOrCompute(this, (this.props as EntityExpansionListProps).viewerStore);
    }

    @computed
    get paths(): EntityPath[]
    {
        return getOrCompute(this, (this.props as EntityExpansionListProps).paths);
    }

    @computed
    get relatedEntitiesByPath(): Map<EntityPath, Entity[]>
    {
        const entitiesByPath = new Map<EntityPath, Entity[]>();

        this.paths.forEach(
            path =>
                entitiesByPath.set(
                    path,
                    path.traverseEntity(this.viewerStore.entity)));

        return entitiesByPath;
    }

    @computed
    get pathsByRelatedEntity(): Map<Entity, EntityPath[]>
    {
        const pathsByRelatedEntity = new Map<Entity, EntityPath[]>();

        this.relatedEntitiesByPath.forEach(
            (entities, path) =>
                entities.forEach(
                    entity =>
                    {
                        if (!pathsByRelatedEntity.has(entity))
                        {
                            pathsByRelatedEntity.set(entity, []);
                        }

                        pathsByRelatedEntity.get(entity).push(path);
                    }));

        return pathsByRelatedEntity;
    }

    @computed
    get relatedEntities(): Entity[]
    {
        // Non-duplicate entities
        const relatedEntities = new Set<Entity>();

        this.relatedEntitiesByPath.forEach(
            entities =>
                entities.forEach(
                    entity =>
                        relatedEntities.add(entity)));

        return Array.from(relatedEntities)
            .filter(
                entity =>
                    !entity.isNew()); // Hide new entities because they should not be shown during construction
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getPathByEntity(entity: Entity): EntityPath
    {
        return this.pathsByRelatedEntity.get(entity)[0];
    }

    // ----------------------- Private logic ------------------------
}
