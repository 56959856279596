import { observable } from 'mobx';
import Computation from './Computation';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Validation from '../../Validation/Validation';
import ListQuery from '../../Query/ListQuery';
import getDependenciesWithoutParameters from '../../Api/getDependenciesWithoutParameters';

export default class QueryFileExportField
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref name: Computation<any, any>;
    @observable.ref value: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(
        id: string,
        name: Computation<any, any>,
        value: Computation<any, any>
    )
    {
        this.id = id;
        this.name = name;
        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return [
            ...this.name.validate(),
            ...this.value.validate(),
        ];
    }

    getDependencies(query: ListQuery): Dependency[]
    {
        return [
            ...this.name.getDependencies(),
            ...getDependenciesWithoutParameters(
                this.value.getDependencies(),
                query.parameter
            )
        ];
    }

    toDescriptor()
    {
        return {
            id: this.id,
            name: this.name.toDescriptor(),
            value: this.value.toDescriptor(),
        };
    }

    static async fromDescriptor(
        descriptor: any,
        query: ListQuery,
        dependencyContext: AutomationDependencyContext
    )
    {
        return new QueryFileExportField(
            descriptor.id,
            await getComputationFromDescriptor(
                descriptor.name,
                dependencyContext
            ),
            await getComputationFromDescriptor(
                descriptor.value,
                new AutomationDependencyContext(
                    dependencyContext.parameterDictionary.getNewDictionaryWithParameter(
                        query.parameter
                    )
                )
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
