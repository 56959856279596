import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';


export default function useIsShowOverlineInHeader(entity: Entity)
{
    const types = useTypes();

    return useComputed(
        () =>
            !entity.entityType.isA(types.Relation.Type)
                &&
            !entity.entityType.isA(types.Relationship.Type),
        [
            types
        ]);
}
