import React from 'react';
import GutenbergBlockType from '../../../Gutenberg/GutenbergBlockType';
import { GutenbergCoreBlockSpacerId } from '../../../DocumentEditor';
import StyledBlock from '../../StyledBlock/StyledBlock';

const GutenbergCoreBlockSpacer: GutenbergBlockType =
{
    name: GutenbergCoreBlockSpacerId,

    configuration:
    {
        attributes: {},
        category: null,
        title: {
            code: 'GutenbergBlock.Spacer',
            value: 'Afstandhouder'
        }
    },

    view: block =>
    {
        return <StyledBlock
            block={block}
        >
            <div style={{ height: block.attributes.height }} />
        </StyledBlock>;
    }
};

export default GutenbergCoreBlockSpacer;
