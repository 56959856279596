import { DataObject } from '../../../DataObject/Model/DataObject';
import DataSeries from '../Model/DataSeries';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import getIntervalMultiplier from '../../../DataObject/Type/Date/DateInterval/Api/getIntervalMultiplier';
import { MathematicalOperator } from '../../../DataObject/Model/MathematicalOperator';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../../../DataObject/DataObjectStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import Chart from '../Model/Chart';

interface DataPoint
{
    x: DataObject;
    y: DataPointValue;
}

export interface DataPointValue
{
    value: DataObject;
    segments: Map<Entity | DataObject, DataObject>;
}

export default async function getSeriesData(chart: Chart,
                                            series: DataSeries,
                                            windowStart: Date,
                                            windowEnd: Date,
                                            range?: DataObject,
                                            interval?: DataObject,
                                            filter?: Predicate): Promise<[ DataPointValue, DataPoint[] ]>
{
    function getBaseSelection()
    {
        return new EntitySelectionBuilder(chart.entityType)
            .if(
                () =>
                    filter !== undefined,
                sb =>
                    sb.where(
                        cb =>
                            cb.filter(
                                filter,
                                {
                                    parameter: chart.parameter,
                                }
                            )
                    )
            )
            .if(
                () =>
                    series.filter !== undefined,
                sb =>
                    sb.where(
                        cb =>
                            cb.filter(
                                series.filter,
                                {
                                    parameter: chart.parameter,
                                }
                            )
                    )
            )
            .aggregateOn(
                series.yAggregateValue.fieldPath,
                undefined,
                series.yAggregateValue.aggregate
            );
    }

    const [ startResult, seriesResult ] = await Promise.all([
        series.isCumulative &&
            windowStart &&
            getBaseSelection()
                .where(
                    cb =>
                        cb.lt(
                            series.xFieldPath,
                            undefined,
                            windowStart))
                .if(
                    () => series.intervalFieldPath !== undefined,
                    sb =>
                        sb.groupBy(series.intervalFieldPath))
                .if(
                    () => series.ySegmentFieldPath !== undefined,
                    sb =>
                        sb.groupBy(series.ySegmentFieldPath))
                .selectAggregates(),
        getBaseSelection()
            .if(
                () =>
                    windowStart !== undefined,
                sb =>
                    sb.where(
                        cb =>
                            cb.ge(
                                series.xFieldPath,
                                undefined,
                                windowStart)))
            .if(
                () =>
                    windowEnd !== undefined,
                sb =>
                    sb.where(
                        cb =>
                            cb.lt(
                                series.xFieldPath,
                                undefined,
                                windowEnd)))
            .groupBy(
                series.xFieldPath,
                interval
                    ?
                        {
                            value: range,
                            interval: interval,
                            isFloored: true,
                            isCeiled: true
                        }
                    :
                        undefined)
            .if(
                () => series.intervalFieldPath !== undefined,
                sb =>
                    sb.groupBy(series.intervalFieldPath))
            .if(
                () => series.ySegmentFieldPath !== undefined,
                sb =>
                    sb.groupBy(series.ySegmentFieldPath))
            .selectAggregates()
    ]);

    const startValueBuckets =
        startResult
            ?
                startResult.children
            :
                [];

    const normalizeWithInterval =
        (value: DataObject, valueInterval?: DataObject) =>
        {
            if (interval && valueInterval)
            {
                return DataObject.compute(
                    DataObject.constructFromTypeIdAndValue(
                        'Percentage',
                        getIntervalMultiplier(
                            valueInterval.value.type,
                            interval.value.type) * 100),
                    value,
                    MathematicalOperator.Multiply);
            }
            else
            {
                return value;
            }
        };

    const hasInterval = interval && series.intervalFieldPath;

    const startValue =
        hasInterval && startValueBuckets.length > 0
            ?
                {
                    value:
                        DataObject.aggregate(
                            loadModuleDirectly(DataObjectStore),
                            startValueBuckets
                                .map(
                                    intervalBucket =>
                                        normalizeWithInterval(
                                            intervalBucket.aggregates[0],
                                            intervalBucket.groupValue)),
                            series.yAggregateValue.aggregate),
                    segments:
                        new Map(
                            startValueBuckets
                                .map(
                                    intervalBucket =>
                                        intervalBucket.children.map(
                                            segmentBucket => [
                                                segmentBucket.groupEntity || segmentBucket.groupValue,
                                                normalizeWithInterval(
                                                    segmentBucket.aggregates[0],
                                                    intervalBucket.groupValue)
                                            ]))
                                .reduce((a, b) => a.concat(b), []) as any[])
                } as DataPointValue
            :
                startResult
                    ?
                        {
                            value: startResult.aggregates[0],
                            segments:
                                new Map(
                                    startResult.children
                                        .map(
                                            child => [
                                                child.groupEntity || child.groupValue,
                                                child.aggregates[0]
                                            ]))
                        } as DataPointValue
                    :
                        undefined;

    const points =
        seriesResult
            .children
            .filter(
                bucket =>
                    bucket.groupValue
                    && !bucket.groupValue.isEmpty)
            .map(
                bucket =>
                {
                    if (hasInterval)
                    {
                        const intervalBuckets = bucket.children;

                        let y: DataObject;

                        if (intervalBuckets.length === 0)
                        {
                            y = bucket.aggregates[0];
                        }
                        else
                        {
                            y =
                                DataObject.aggregate(
                                    loadModuleDirectly(DataObjectStore),
                                    intervalBuckets
                                        .map(
                                            intervalBucket =>
                                                normalizeWithInterval(
                                                    intervalBucket.aggregates[0],
                                                    intervalBucket.groupValue)),
                                    series.yAggregateValue.aggregate);
                        }

                        return {
                            x: bucket.groupValue,
                            y: {
                                value: y,
                                segments:
                                    new Map(
                                        intervalBuckets
                                            .map(
                                                intervalBucket =>
                                                    intervalBucket.children.map(
                                                        segmentBucket => [
                                                            segmentBucket.groupEntity || segmentBucket.groupValue,
                                                            normalizeWithInterval(
                                                                segmentBucket.aggregates[0],
                                                                intervalBucket.groupValue)
                                                        ]))
                                            .reduce((a, b) => a.concat(b), []) as any[])
                            }
                        } as DataPoint;
                    }
                    else
                    {
                        return {
                            x: bucket.groupValue,
                            y: {
                                value: bucket.aggregates[0],
                                segments:
                                    new Map(
                                        bucket.children.map(
                                            child => [
                                                child.groupEntity || child.groupValue,
                                                child.aggregates[0]
                                            ]))
                            }
                        } as DataPoint;
                    }
                });

    return [ startValue, points ];
}
