import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { default as ColumnModel } from '../../../Model/Column';
import { default as WidgetModel } from '../../../Model/Widget';
import Draggable from '../../../../../DragAndDrop/Draggable/Draggable';
import { IObservableArray, runInAction } from 'mobx';
import Widget from '../../../Widget/Widget';
import styles from './Item.module.scss';
import ErrorBoundary from '../../../../../../Error/ErrorBoundary';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';

export interface ItemProps
{
    column: ColumnModel;
    widget: WidgetModel;
    idx: number;
    disabled?: boolean;
    onDelete: (widget) => void;
    firstColumn?: boolean;
    lastColumn?: boolean;
}

const Item: React.FC<ItemProps> =
    props =>
    {
        const onDelete =
            useCallback(
                () =>
                {
                    runInAction(() =>
                        {
                            (props.column.widgets as IObservableArray).remove(props.widget)
                            props.onDelete(props.widget)
                        }
                    )
                },
                [
                    props.column,
                    props.widget,
                    props.onDelete,
                ]);

        const renderError =
            useCallback(
                () =>
                    <Card
                        inset
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={5}
                        >
                            <ViewGroupItem>
                                <Centered
                                    horizontal
                                >
                                    Er is iets misgegaan tijdens het tonen van deze widget.
                                </Centered>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <Centered
                                    horizontal
                                >
                                    <PrimaryButton
                                        label={
                                            <LocalizedText
                                                code="Generic.Delete"
                                                value="Verwijderen"
                                            />
                                        }
                                        onClick={onDelete}
                                    />
                                </Centered>
                            </ViewGroupItem>
                        </ViewGroup>
                    </Card>,
                [
                    onDelete
                ]);

        return <ErrorBoundary
            renderError={renderError}
        >
            <Draggable
                id={props.widget.id}
                index={props.idx}
                disabled={props.disabled}
            >
                <div
                    className={
                        classNames(
                            styles.root,
                            props.firstColumn && styles.firstColumn,
                            props.lastColumn && styles.lastColumn
                        )}
                >
                    <Widget
                        widget={props.widget}
                        onDelete={onDelete}
                        disabled={props.disabled}
                    />
                </div>
            </Draggable>
        </ErrorBoundary>;
    };

export default observer(Item);
