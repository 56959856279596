import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { EntityExpansionBuilder } from '../../Selection/Builder/EntityExpansionBuilder';
import { updateRelationship } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import equalsEntity from '../../../../../@Api/Entity/Bespoke/equalsEntity';
import getTypes from './getTypes';

export async function onRelationshipUpdateCheckAndSetContact(
    isParent: boolean,
    relationshipDefinition: EntityRelationshipDefinition,
    contactDefinition: EntityRelationshipDefinition,
    entity: Entity,
    relatedEntity?: Entity,
    commitContext?: CommitContext
)
{
    const types = getTypes();

    // Check and update contact
    if (relatedEntity)
    {
        if (relatedEntity.entityType.isA(types.Relationship.Person.Contact.Type))
        {
            const pathToOrgRelationship =
                EntityPath.fromEntity(relatedEntity)
                    .joinTo(
                        types.Relation.RelationshipDefinition.Relationships,
                        true)
                    .castTo(types.Relation.Organization.Type)
                    .joinTo(
                        types.Relationship.Organization.RelationshipDefinition.Organization,
                        true);

            await new EntityExpansionBuilder(
                relatedEntity.entityType,
                [
                    relatedEntity
                ],
                [
                    pathToOrgRelationship
                ])
                .expand()
                .then(
                    () =>
                    {
                        const firstRelationship =
                            pathToOrgRelationship.traverseEntity(relatedEntity, commitContext)
                                .find(() => true);

                        updateRelationship(
                            entity,
                            isParent,
                            relationshipDefinition,
                            firstRelationship,
                            commitContext
                        );

                        updateRelationship(
                            entity,
                            isParent,
                            contactDefinition,
                            relatedEntity,
                            commitContext
                        );
                    });
        }
        else if (relatedEntity.entityType.isA(types.Relationship.Organization.Type))
        {
            const contact =
                entity.getRelatedEntityByDefinition(
                    isParent,
                    contactDefinition,
                    commitContext);

            if (!contact
                || !equalsEntity(
                    contact.getRelatedEntityByDefinition(true, types.Relation.RelationshipDefinition.Relationships),
                    relatedEntity.getRelatedEntityByDefinition(false, types.Relationship.Organization.RelationshipDefinition.Organization)))
            {
                if (contact)
                {
                    updateRelationship(
                        entity,
                        isParent,
                        contactDefinition,
                        undefined,
                        commitContext
                    );
                }

                const pathToFirstContact =
                    EntityPath.fromEntity(relatedEntity)
                        .joinTo(
                            types.Relationship.Organization.RelationshipDefinition.Organization,
                            false)
                        .joinTo(
                            types.Relation.RelationshipDefinition.Relationships,
                            false)
                        .castTo(types.Relationship.Person.Contact.Type);

                await new EntityExpansionBuilder(
                    relatedEntity.entityType,
                    [
                        relatedEntity
                    ],
                    [
                        pathToFirstContact
                    ])
                    .expand()
                    .then(
                        () =>
                        {
                            // It might be that in the meantime the contact was programmatically set
                            const contact =
                                entity.getRelatedEntityByDefinition(
                                    isParent,
                                    contactDefinition,
                                    commitContext
                                );

                            if (!contact ||
                                !equalsEntity(
                                    contact.getRelatedEntityByDefinition(true, types.Relation.RelationshipDefinition.Relationships),
                                    relatedEntity.getRelatedEntityByDefinition(false, types.Relationship.Organization.RelationshipDefinition.Organization)))
                            {
                                const firstContact =
                                    pathToFirstContact.traverseEntity(relatedEntity)
                                        .find(() => true);

                                if (firstContact)
                                {
                                    updateRelationship(
                                        entity,
                                        isParent,
                                        contactDefinition,
                                        firstContact,
                                        commitContext
                                    );
                                }
                            }
                        });
            }
        }
    }
    else
    {
        updateRelationship(
            entity,
            isParent,
            contactDefinition,
            undefined,
            commitContext
        );
    }
}