import { createContext } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';

export type ExpansionType = 'Notes' | 'Attachments' | 'More';

export interface EntityExpansion
{
    entity: Entity;
    types: Set<ExpansionType>;
}

export default createContext<[ EntityExpansion | undefined, (expansion?: EntityExpansion) => void ]>(undefined);
