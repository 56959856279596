import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ApiBaseEditorStore } from './ApiBaseEditorStore';
import Selectbox from '../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';

export interface ApiBaseSelectorProps
{
    store: ApiBaseEditorStore<any>;
}

const ApiBaseSelector: React.FC<ApiBaseSelectorProps> =
    props =>
    {
        const load =
            useCallback(
                (query: string) =>
                    props.store.type.searchInstances(
                        query,
                        50,
                        props.store.baseStore.dataObject,
                        props.store.dataObjectStore.moduleManager),
                [
                    props.store
                ]);

        const idResolver =
            useCallback(
                (instance: any) =>
                    `${props.store.type.getInstanceId(instance)}`,
                [
                    props.store
                ]);

        const optionFormatter =
            useCallback(
                (instance: any) =>
                    props.store.type.getInstanceName(instance),
                [
                    props.store
                ]);

        return <Selectbox
            load={load}
            value={props.store.baseStore.dataObject.value}
            onChange={props.store.setValue}
            idResolver={idResolver}
            formatOption={optionFormatter}
            disabled={props.store.baseStore.isDisabled}
            disableUnderline={!props.store.baseStore.showUnderline}
        />;
    };

export default observer(ApiBaseSelector);
