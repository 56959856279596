import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import localizeText from '../../Localization/localizeText';

export default class HeaderWithScrollBodyLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref header: Layout;
    @observable.ref body: Layout;

    // ------------------------ Constructor -------------------------

    constructor(header: Layout,
                body: Layout)
    {
        super();

        this.header = header;
        this.body = body;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.HeaderWithScrollBody', 'Header met scrollbare body');
    }

    validate(): Validation[]
    {
        return [
            ...this.header.validate(),
            ...this.body.validate()
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.header.getDependencies(),
            ...this.body.getDependencies()
        ];
    }

    toDescriptor()
    {
        return {
            type: 'HeaderWithScrollBody',
            header: this.header.toDescriptor(),
            body: this.body.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new HeaderWithScrollBodyLayout(
            await getLayoutFromDescriptor(
                descriptor.header,
                dependencyContext),
            await getLayoutFromDescriptor(
                descriptor.body,
                dependencyContext),);
    }

    // ----------------------- Private logic ------------------------
}
