import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import TableDimensionSection from '../../../../../../../@Api/Layout/Type/Table/Model/TableDimensionSection';
import TableCellLocation from '../../../../../../../@Api/Layout/Type/Table/Model/TableCellLocation';
import { TableLayoutEditorProps } from '../TableLayoutEditor';
import TableLayoutCellEditor from './TableLayoutCellEditor';
import { IObservableArray, runInAction } from 'mobx';
import TableCell from '../../../../../../../@Api/Layout/Type/Table/Model/TableCell';
import uuid from '../../../../../../../@Util/Id/uuid';
import PrimitiveValue from '../../../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import CompositeLayout from '../../../../../../../@Api/Layout/Type/CompositeLayout';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import AddIconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/AddIconButton/AddIconButton';
import MenuButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import TableLayoutDimensionSectionEditor from '../DimensionSection/TableLayoutDimensionSectionEditor';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import DeleteButton from '../../../../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { textSecondaryColor } from '../../../../../../../@Resource/Theme/Theme';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import CollectionTableDimensionSection from '../../../../../../../@Api/Layout/Type/Table/Model/CollectionTableDimensionSection';
import ChildTable from '../../../../../../../@Api/Layout/Type/Table/Model/ChildTable';

const useStyles = makeStyles({
    editor: {
        minWidth: '20vw'
    }
});

export interface TableLayoutCellLocationEditorProps extends TableLayoutEditorProps
{
    rowSection: TableDimensionSection;
    rowSectionEditor: boolean;
    columnSection: TableDimensionSection;
    columnSectionEditor: boolean;
    first?: boolean;
    last?: boolean;
    childTable?: ChildTable;
}

const TableLayoutCellLocationEditor: React.FC<TableLayoutCellLocationEditorProps> =
    props =>
    {
        const {
            layout,
            rowSection,
            columnSection,
            rowSectionEditor,
            columnSectionEditor,
            childTable,
        } = props;
        const classes = useStyles();
        const location =
            useMemo(
                () =>
                    new TableCellLocation(
                        rowSection.id,
                        columnSection.id
                    ),
                [
                    rowSection,
                    columnSection
                ]
            );
        const cell =
            useComputed(
                () =>
                    (childTable ?? layout).cellByLocationId.get(location.id),
                [
                    layout,
                    location
                ]
            );
        const createCell =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (childTable ?? layout).cells.push(
                                new TableCell(
                                    uuid(),
                                    location,
                                    new PrimitiveValue(DataObject.constructFromTypeIdAndValue('Number', 1)),
                                    new PrimitiveValue(DataObject.constructFromTypeIdAndValue('Number', 1)),
                                    new CompositeLayout(
                                        'Vertical',
                                        []
                                    )
                                )
                            )
                    ),
                [
                    layout,
                    location
                ]
            );
        const deleteRowSection =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            ((childTable?.sections ?? layout.rowSections) as IObservableArray).remove(rowSection);

                            layout.cells
                                .filter(
                                    cell =>
                                        cell.location.rowId === rowSection.id)
                                .forEach(
                                    cell =>
                                        ((childTable ?? layout).cells as IObservableArray).remove(cell)
                                );
                        }),
                [
                    layout,
                    rowSection
                ]
            );
        const deleteColumnSection =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            (layout.columnSections as IObservableArray).remove(columnSection);

                            layout.cells
                                .filter(
                                    cell =>
                                        cell.location.columnId === columnSection.id)
                                .forEach(
                                    cell =>
                                        (layout.cells as IObservableArray).remove(cell));

                        }),
                [
                    layout,
                    columnSection
                ]
            );

        return <ViewGroup
            orientation="horizontal"
            spacing={5}
            alignment="center"
        >
            <ViewGroupItem
                ratio={1}
            >
                {
                    cell
                        ?
                            <TableLayoutCellEditor
                                {...props}
                                cell={cell}
                            />
                        :
                            <AddIconButton
                                onClick={createCell}
                            />
                }
            </ViewGroupItem>
            {
                (rowSectionEditor || columnSectionEditor) &&
                    <ViewGroupItem>
                        <MenuButton
                            icon="settings"
                        >
                            <CardInset>
                                <div
                                    className={classes.editor}
                                >
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={15}
                                    >
                                        {
                                            rowSectionEditor &&
                                                <ViewGroupItem>
                                                    <ViewGroup
                                                        orientation="vertical"
                                                        spacing={5}
                                                    >
                                                        <ViewGroupItem>
                                                            <strong>Rijspecificatie</strong>
                                                        </ViewGroupItem>
                                                        <ViewGroupItem>
                                                            <TableLayoutDimensionSectionEditor
                                                                {...props}
                                                                section={rowSection}
                                                                columnSectionEditor={false}
                                                            />
                                                        </ViewGroupItem>
                                                        <ViewGroupItem>
                                                            <ViewGroup
                                                                orientation="horizontal"
                                                                spacing={15}
                                                            >
                                                                <ViewGroupItem
                                                                    ratio={1}
                                                                />
                                                                <ViewGroupItem>
                                                                    <DeleteButton
                                                                        onClick={deleteRowSection}
                                                                    />
                                                                </ViewGroupItem>
                                                            </ViewGroup>
                                                        </ViewGroupItem>
                                                    </ViewGroup>
                                                </ViewGroupItem>
                                        }
                                        {
                                            columnSectionEditor &&
                                                <ViewGroupItem>
                                                    <ViewGroup
                                                        orientation="vertical"
                                                        spacing={5}
                                                    >
                                                        <ViewGroupItem>
                                                            <strong>Kolomspecificatie</strong>
                                                        </ViewGroupItem>
                                                        <ViewGroupItem>
                                                            <TableLayoutDimensionSectionEditor
                                                                {...props}
                                                                section={columnSection}
                                                                rowSectionEditor={false}
                                                            />
                                                        </ViewGroupItem>
                                                        <ViewGroupItem>
                                                            <ViewGroup
                                                                orientation="horizontal"
                                                                spacing={15}
                                                            >
                                                                <ViewGroupItem
                                                                    ratio={1}
                                                                />
                                                                <ViewGroupItem>
                                                                    <DeleteButton
                                                                        onClick={deleteColumnSection}
                                                                    />
                                                                </ViewGroupItem>
                                                            </ViewGroup>
                                                        </ViewGroupItem>
                                                    </ViewGroup>
                                                </ViewGroupItem>
                                        }
                                    </ViewGroup>
                                </div>
                            </CardInset>
                        </MenuButton>
                    </ViewGroupItem>
            }
            {
                (rowSectionEditor && rowSection instanceof CollectionTableDimensionSection) &&
                    <AddIconButton
                        icon="playlist_add"
                        color={textSecondaryColor}
                        tooltip={
                            <LocalizedText
                                code="TableLayoutEditor.AddChildTable"
                                value="Subtabel toevoegen"
                            />
                        }
                        onClick={
                            () =>
                                runInAction(
                                    () =>
                                        layout.childTables.push(
                                            new ChildTable(
                                                uuid(),
                                                'Row',
                                                rowSection,
                                                [],
                                                []
                                            )
                                        )
                                )
                        }
                    />
            }
        </ViewGroup>;
    };

export default observer(TableLayoutCellLocationEditor);
