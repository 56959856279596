import * as React from 'react';
import LinkExternalAppointment, { LinkExternalAppointmentProps } from './LinkExternalAppointment';
import { observable } from 'mobx';
import { PageStore } from '../../../../../../../../@Service/Navigation/Page/PageStore';
import md5 from 'md5';
import ExternalAppointment from '../ExternalAppointment';

export class LinkExternalAppointmentPageStore extends PageStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable.ref externalAppointment: ExternalAppointment;

    // ------------------------ Constructor -------------------------

    constructor(externalAppointment: ExternalAppointment)
    {
        super(0);

        this.externalAppointment = externalAppointment;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    get componentClass(): React.ClassType<any, any, any>
    {
        return LinkExternalAppointment;
    }

    get componentProps(): LinkExternalAppointmentProps
    {
        return {
            externalAppointment: this.externalAppointment
        };
    }

    get routePath(): string
    {
        return `/bespoke/activity/appointment/link-external-appointment/${md5(this.externalAppointment.id)}`;
    }

    // ----------------------- Private logic ------------------------
}
