import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import localizeText from '../../Localization/localizeText';

export default class ExpansionGroupLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref layout: Layout;

    // ------------------------ Constructor -------------------------

    constructor(layout: Layout)
    {
        super();

        this.layout = layout;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.ExpansionGroup', 'Uitklapgroep');
    }

    validate(): Validation[]
    {
        return this.layout.validate();
    }

    getDependencies(): Dependency[]
    {
        return this.layout.getDependencies();
    }

    toDescriptor()
    {
        return {
            type: 'ExpansionGroup',
            layout: this.layout.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new ExpansionGroupLayout(
            await getLayoutFromDescriptor(
                descriptor.layout,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
