import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import { runInAction } from 'mobx';
import PortalRouteLayout from '../../../../../@Api/Layout/Type/PortalRouteLayout';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationEditor from '../../../Entity/Viewer/Content/Automation/Editor/Computation/ComputationEditor';

export interface PortalRouteLayoutEditorProps extends LayoutEditorProps<PortalRouteLayout>
{

}

const PortalRouteLayoutEditor: React.FC<PortalRouteLayoutEditorProps> =
    props =>
    {
        const { layout, parameterDictionary, parameterAssignment, commitContext } = props;
        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        parameterDictionary,
                        parameterAssignment || new ParameterAssignment(),
                        commitContext
                    ),
                [
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                ]);
        const setRoute =
            useCallback(
                (route: Computation<any, any>) =>
                    runInAction(
                        () =>
                            layout.route = route),
                [
                    layout
                ]);

        return <Card
            inset
        >
            <ComputationEditor
                context={context}
                value={layout.route}
                onChange={setRoute}
            />
        </Card>;
    };

export default observer(PortalRouteLayoutEditor);
