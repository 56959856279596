import { Entity } from '../../../../../../../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../../../../../../../Type/Api/useTypes';
import useCount from '../../../../../../../../../../../Selection/Hooks/useCount';
import { EntityPath } from '../../../../../../../../../../../Path/@Model/EntityPath';

export default function useSpotlerCampaignResultCount(spotlerCampaignResult: Entity, eventType: string): number
{
    const types = useTypes();

    return useCount(
        types.SpotlerCampaignEvent.Type,
        (builder, rootPath) =>
            builder
                .where(
                    cb =>
                        cb.relatedToEntity(
                            EntityPath.fromEntityType(types.SpotlerCampaignEvent.Type)
                                .joinTo(
                                    types.Activity.SpotlerCampaignResult.RelationshipDefinition.Events,
                                    true
                                ),
                            spotlerCampaignResult))
                .where(
                    cb =>
                        cb.eq(
                            EntityPath.fromEntityType(types.SpotlerCampaignEvent.Type)
                                .joinTo(
                                    types.SpotlerCampaignEvent.RelationshipDefinition.EventType,
                                    false)
                                .field(types.Datastore.Field.Code),
                            undefined,
                            eventType
                        )
                ),
        [
            types,
            spotlerCampaignResult,
        ]
    );
}