import { DataObjectEditorType, DataObjectSpecificationType, DataObjectViewerType } from '../../../../Model/DataObjectType';
import { DataObject } from '../../../../Model/DataObject';
import { CurrencyRangeView } from './CurrencyRangeView';
import { CurrencyRangeEditor } from './CurrencyRangeEditor';
import { CurrencyRangeSpecification } from './CurrencyRangeSpecification';
import { NumberRangeValue } from '../../NumberRange/NumberRangeValue';
import { NumberRangeType } from '../../NumberRange/NumberRangeType';
import { DataObjectRepresentation } from '../../../../Model/DataObjectRepresentation';
import numbro from 'numbro';
import { DataObjectContext } from '../../../../Model/DataObjectContext';
import { LocalizationStore } from '../../../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../../../../@Util/DependencyInjection/index';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export class CurrencyRangeType extends NumberRangeType
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'CurrencyRange';
    }

    name()
    {
        return localizeText('DataObject.Type.CurrencyRange', 'Valutabereik');
    }

    view(): DataObjectViewerType
    {
        return CurrencyRangeView;
    }

    editor(): DataObjectEditorType
    {
        return CurrencyRangeEditor;
    }

    specification(): DataObjectSpecificationType
    {
        return CurrencyRangeSpecification;
    }

    intervalTypeId(): string
    {
        return 'Currency';
    }

    rangeTypeId(): string
    {
        return 'CurrencyRange';
    }

    constructRange(from: DataObject,
                   interval: DataObject,
                   rangeToSet: DataObject): void
    {
        let range: NumberRangeValue =
        {
            from: from.getValue(),
            to: from.getValue() + interval.getValue()
        };

        rangeToSet.setValue(range);
    }

    getString(value: NumberRangeValue,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        return `${numbro(value.from).formatCurrency()} - ${numbro(value.to).formatCurrency()}`;
    }
}
