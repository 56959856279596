import { enumerated, reference, type } from '../../../@Util/Serialization/Serialization';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { observable } from 'mobx';
import { EntityType } from './EntityType';
import { LanguageEntry } from './LanguageEntry';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { Entity } from './Entity';
import { EntityRelationship } from './EntityRelationship';
import { EntityField } from './EntityField';
import { EntityContext } from '../../../@Component/Domain/Entity/@Model/EntityContext';
import { EntityFieldPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import { Computation } from '../../../@Component/Domain/Computation/Type/Computation';
import { registerBuilder } from '../../../@Util/TransactionalModelV2/Shared/TransactionalBuilder';
import { EntityTypeParameter } from './EntityTypeParameter';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import getTypes from '../../../@Component/Domain/Entity/Type/Api/getTypes';
import { DataObject } from '../../../@Component/Domain/DataObject/Model/DataObject';
import { safelyGetOrUndefined } from '../../../@Util/Exception/safelyGetOrUndefined';
import localizeText from '../../Localization/localizeText';

export enum Cardinality { OneToOne, OneToMany, ManyToOne, ManyToMany }

export enum ImportanceLevel { None, Low, Normal, High, Critical }

export enum ReferentialAction { None, Restrict, Cascade, CascadeWhenNotShared }

export enum Type { Definition, Reference, Ownership }

@type('EntityRelationshipDefinition')
export class EntityRelationshipDefinition
{
    // ------------------- Persistent Properties -------------------

    @observable id: number;
    @observable code: string;
    @observable mergeCode: string;
    @reference(undefined, 'EntityType') @observable.ref parentEntityType: EntityType;
    @reference(undefined, 'EntityTypeParameter') @observable.shallow parentEntityTypeParameter: EntityTypeParameter;
    @reference(undefined, 'EntityType') @observable.ref childEntityType: EntityType;
    @reference(undefined, 'EntityTypeParameter') @observable.shallow childEntityTypeParameter: EntityTypeParameter;
    @observable.ref parentComputation: any;
    @observable.ref childComputation: any;
    @reference(undefined, 'EntityField') @observable.ref parentFreeFormField: EntityField;
    @reference(undefined, 'EntityField') @observable.ref childFreeFormField: EntityField;
    @observable @enumerated(Type, 'Type') type: Type;
    @observable @enumerated(Cardinality, 'Cardinality') cardinality: Cardinality;
    @observable isDefaultForParent: boolean;
    @observable isDefaultForChild: boolean;
    @observable isMandatoryForParent: boolean;
    @observable isMandatoryForChild: boolean;
    @observable isVisibleInParent: boolean;
    @observable isVisibleInChild: boolean;
    @observable isVisibleAsTabInParent: boolean;
    @observable isVisibleAsTabInChild: boolean;
    @observable isManagedInParent: boolean;
    @observable isManagedInChild: boolean;
    @observable isInactiveCascadedToParent: boolean;
    @observable isInactiveCascadedToChild: boolean;
    @observable isLazyFromParent: boolean;
    @observable isLazyFromChild: boolean;
    @observable.ref parentPredicate: any;
    @observable.ref childPredicate: any;
    @observable @enumerated(ReferentialAction, 'ReferentialAction') parentDeletionReferentialAction: ReferentialAction;
    @observable @enumerated(ReferentialAction, 'ReferentialAction') childDeletionReferentialAction: ReferentialAction;
    @reference(undefined, 'EntityRelationshipDefinition') @observable.ref dependentOnRelationshipDefinition: EntityRelationshipDefinition;
    @observable.ref configuration: any;
    @reference(undefined, 'EntityRelationshipDefinition') @observable.shallow dependentRelationshipDefinitions: EntityRelationshipDefinition[];
    @observable @enumerated(ImportanceLevel, 'ImportanceLevel') importanceLevel: ImportanceLevel;
    @observable color: string;
    @observable icon: string;
    @observable sortIndex: number;
    @observable.ref defaultParentPath: any;
    @observable.ref defaultChildPath: any;
    @reference(undefined, 'Entity') @observable.ref entity: Entity;

    // ------------------------- Properties -------------------------

    @observable.ref initializedParentComputation: Computation;
    @observable.ref initializedChildComputation: Computation;

    // ------------------------ Constructor -------------------------

    constructor(
        code?: string,
        parentEntityType?: EntityType,
        childEntityType?: EntityType,
        definitionType?: Type,
        cardinality?: Cardinality,
        isMandatoryForParent?: boolean,
        isMandatoryForChild?: boolean,
        isVisibleInParent?: boolean,
        isVisibleInChild?: boolean,
        isVisibleAsTabInParent?: boolean,
        isVisibleAsTabInChild?: boolean,
        isManagedInParent?: boolean,
        isManagedInChild?: boolean,
        parentPredicate?: any,
        childPredicate?: any,
        parentDeletionReferentialAction?: ReferentialAction,
        childDeletionReferentialAction?: ReferentialAction,
        nameLanguageEntry?: LanguageEntry,
        inverseNameLanguageEntry?: LanguageEntry,
        importanceLevel?: ImportanceLevel,
        icon?: string)
    {
        this.code = code;
        this.parentEntityType = parentEntityType;
        this.childEntityType = childEntityType;
        this.type = definitionType;
        this.cardinality = cardinality;
        this.isMandatoryForParent = isMandatoryForParent;
        this.isMandatoryForChild = isMandatoryForChild;
        this.isVisibleInParent = isVisibleInParent;
        this.isVisibleInChild = isVisibleInChild;
        this.isVisibleAsTabInParent = isVisibleAsTabInParent;
        this.isVisibleAsTabInChild = isVisibleAsTabInChild;
        this.isManagedInParent = isManagedInParent;
        this.isManagedInChild = isManagedInChild;
        this.parentPredicate = parentPredicate;
        this.childPredicate = childPredicate;
        this.parentDeletionReferentialAction = parentDeletionReferentialAction;
        this.childDeletionReferentialAction = childDeletionReferentialAction;
        this.importanceLevel = importanceLevel;
        this.icon = icon;
    }

    // ----------------------- Initialization -----------------------

    initialize(entityTypeStore: EntityTypeStore)
    {
        [ true, false ]
            .filter(
                isParent =>
                    this.getEntityType(isParent) != null)
            .forEach(
                isParent =>
                {
                    let type = entityTypeStore.getTypeById(this.getEntityType(isParent).id);

                    this.setEntityType(
                        isParent,
                        type);
                });
    }

    async postInitialize(entityTypeStore: EntityTypeStore)
    {
        if (this.entity)
        {
            this.entity.initialize(
                entityTypeStore,
                undefined,
                undefined,
                undefined,
                false
            );
        }

        // To be executed after the entity type store has been fully initialized
        [ true, false ]
            .filter(
                isParent =>
                    this.getEntityType(isParent) != null)
            .forEach(
                isParent =>
                {
                    const computationDescriptor = this.getComputationDescriptor(isParent);

                    if (computationDescriptor)
                    {
                        this.setComputation(
                            isParent,
                            safelyGetOrUndefined(
                                () =>
                                    entityTypeStore.computationTypeStore.fromSpecification(computationDescriptor)
                            )
                        );
                    }
                }
            );
    }

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    get name(): string
    {
        return this.nameDataObject?.toString();
    }

    set name(value)
    {

    }

    get inverseName(): string
    {
        return this.inverseNameDataObject?.toString();
    }

    set inverseName(value)
    {

    }

    get nameDataObject(): DataObject
    {
        return this.entity?.getDataObjectValueByField(getTypes().EntityRelationshipDefinition.Field.LocalizedChildName);
    }

    get inverseNameDataObject(): DataObject
    {
        return this.entity?.getDataObjectValueByField(getTypes().EntityRelationshipDefinition.Field.LocalizedParentName);
    }

    isPlural(isParent: boolean): boolean
    {
        if (isParent)
        {
            switch (this.cardinality)
            {
                case Cardinality.OneToOne:
                case Cardinality.OneToMany:
                    return false;

                case Cardinality.ManyToOne:
                    return true;

                case Cardinality.ManyToMany:
                    return true;
            }
        }
        else
        {
            switch (this.cardinality)
            {
                case Cardinality.OneToOne:
                    return false;

                case Cardinality.OneToMany:
                    return true;

                case Cardinality.ManyToOne:
                    return false;

                case Cardinality.ManyToMany:
                    return true;
            }
        }
    }

    isSingular(isParent: boolean): boolean
    {
        return !this.isPlural(isParent);
    }

    /**
     * Determines whether this relationship definition is mandatory
     * for parent/child.
     *
     * isParent = true => relationship definition is mandatory from the child perspective
     * isParent = false => relationship definition is mandatory from the parent perspective
     *
     * @param {boolean} isParent
     * @returns {boolean}
     */
    isMandatory(isParent: boolean): boolean
    {
        if (isParent)
        {
            return this.isMandatoryForChild;
        }
        else
        {
            return this.isMandatoryForParent;
        }
    }

    /**
     * Determines whether this relationship definition is managed
     * in parent/child.
     *
     * isParent = true => relationship definition is managed from the child perspective
     * isParent = false => relationship definition is managed from the parent perspective
     *
     * @param {boolean} isParent
     * @returns {boolean}
     */
    isManaged(isParent: boolean): boolean
    {
        if (isParent)
        {
            return this.isManagedInParent;
        }
        else
        {
            return this.isManagedInChild;
        }
    }

    /**
     * Determines whether this relationship definition is visible
     * in parent/child.
     *
     * isParent = true => relationship definition is visible from the child perspective
     * isParent = false => relationship definition is visible from the parent perspective
     *
     * @param {boolean} isParent
     * @returns {boolean}
     */
    isVisibleInDetails(isParent: boolean): boolean
    {
        if (isParent)
        {
            return this.isVisibleInParent;
        }
        else
        {
            return this.isVisibleInChild;
        }
    }

    isVisibleAsTab(isParent: boolean): boolean
    {
        if (isParent)
        {
            return this.isVisibleAsTabInParent;
        }
        else
        {
            return this.isVisibleAsTabInChild;
        }
    }

    isVisibleDuringConstruction(isParent: boolean): boolean
    {
        if (isParent)
        {
            return this.isDefaultForChild;
        }
        else
        {
            return this.isDefaultForParent;
        }
    }

    getName(
        isParent: boolean,
        entityType: EntityType = this.getEntityType(!isParent),
        entityTypeStore: EntityTypeStore = loadModuleDirectly(EntityTypeStore)
    ): string | undefined
    {
        const types = entityTypeStore.bespoke.types;

        if (this === types.Relation.RelationshipDefinition.Relationships)
        {
            if (isParent)
            {
                if (entityType.isA(types.Relationship.Organization.Type))
                {
                    return localizeText('FromOrganization', 'Van organisatie');
                }
                else if (entityType.isA(types.Relationship.Person.Type))
                {
                    return localizeText('Generic.Organization', 'Organisatie');
                }
            }
            else
            {
                if (entityType.isA(types.Relation.Organization.Type))
                {
                    return localizeText('Generic.ContactPerson', 'Contactpersonen');
                }
            }
        }

        return isParent
            ?
                this.inverseName
            :
                this.name;
    }

    getNameDataObject(isParent: boolean)
    {
        return isParent
            ?
                this.inverseNameDataObject
            :
                this.nameDataObject;
    }

    getEntityType(isParent: boolean): EntityType
    {
        return isParent ? this.parentEntityType : this.childEntityType;
    }

    setEntityType(isParent: boolean,
                  entityType: EntityType)
    {
        if (isParent)
        {
            this.parentEntityType = entityType;
        }
        else
        {
            this.childEntityType = entityType;
        }
    }

    isAddableByType(isParent: boolean)
    {
        return this.type === Type.Definition && isParent;
    }

    getAddableEntityTypes(entity: Entity,
                          isParent: boolean,
                          excludedRelationship?: EntityRelationship)
    {
        if (this.isAddableByType(isParent))
        {
            let existentEntityTypes = new Set<EntityType>();

            // Add all entity types except the one that is being edited
            entity.getRelationshipsByDefinition(isParent, this)
                .filter(relationship => relationship !== excludedRelationship)
                .forEach(
                    relationship =>
                    {
                        let entityType = relationship.getEntity(isParent).entityType;
                        existentEntityTypes.add(entityType);

                        // Add any parent types that are between the relationship type and this type
                        if (entityType.parentType !== this.getEntityType(isParent))
                        {
                            existentEntityTypes.add(entityType.parentType);
                        }
                    });

            let existentEntityTypesArray = Array.from(existentEntityTypes.values());

            return this.getEntityType(isParent)
                .getAllInstantiableTypes()
                .filter(
                    entityType =>
                        !existentEntityTypes.has(entityType)
                            && existentEntityTypesArray.every(existentType => !entityType.isA(existentType))
                            && !entityType.isIdentity);
        }
        else
        {
            return this.getEntityType(isParent).getAllInstantiableTypes();
        }
    }

    isAddable(entity: Entity,
              isParent: boolean)
    {
        return this.getAddableEntityTypes(entity, isParent).length > 0
            && (!this.isSingular(isParent) || entity.getRelationshipsByDefinition(isParent, this).length === 0);
    }

    getComputationDescriptor(isParent: boolean)
    {
        if (isParent)
        {
            return this.parentComputation;
        }
        else
        {
            return this.childComputation;
        }
    }

    isComputed(isParent: boolean): boolean
    {
        return this.getComputation(isParent) != null;
    }

    getComputation(isParent: boolean)
    {
        if (isParent)
        {
            return this.initializedParentComputation;
        }
        else
        {
            return this.initializedChildComputation;
        }
    }

    setComputation(isParent: boolean,
                   computation: Computation)
    {
        if (isParent)
        {
            this.initializedParentComputation = computation;
        }
        else
        {
            this.initializedChildComputation = computation;
        }
    }

    setMandatory(isParent: boolean,
                 isMandatory: boolean)
    {
        if (isParent)
        {
            this.isMandatoryForChild = isMandatory;
        }
        else
        {
            this.isMandatoryForParent = isMandatory;
        }
    }

    setVisibleDuringConstruction(
        isParent: boolean,
        isDefault: boolean
    )
    {
        if (isParent)
        {
            this.isDefaultForChild = isDefault;
        }
        else
        {
            this.isDefaultForParent = isDefault;
        }
    }

    setVisibleInDetails(
        isParent: boolean,
        isVisibleInDetails: boolean
    )
    {
        if (isParent)
        {
            this.isVisibleInChild = isVisibleInDetails;
        }
        else
        {
            this.isVisibleInParent = isVisibleInDetails;
        }
    }

    setVisibleAsTab(
        isParent: boolean,
        isVisibleAsTab: boolean
    )
    {
        if (isParent)
        {
            this.isVisibleAsTabInChild = isVisibleAsTab;
        }
        else
        {
            this.isVisibleAsTabInParent = isVisibleAsTab;
        }
    }

    getDependencies(isParent: boolean,
                    path: EntityPath): EntityFieldPath[]
    {
        const paths: EntityFieldPath[] = [];
        const computation = this.getComputation(isParent);

        if (computation)
        {
            const context = new EntityContext([], path);
            paths.push(
                ...computation.type.entityFieldPaths(
                    this.getComputationDescriptor(isParent),
                    context));
        }

        return paths;
    }

    getDeletionReferentialAction(isParent: boolean): ReferentialAction
    {
        return isParent
            ?
                this.parentDeletionReferentialAction
            :
                this.childDeletionReferentialAction;
    }

    toString(): string
    {
        return `EntityRelationshipDefinition(${this.id})`;
    }

    // ----------------------- Private logic ------------------------
}

registerBuilder(EntityRelationshipDefinition)
    .includeAll()
    //.deep('nameLanguageEntry')
    //.deep('inverseNameLanguageEntry')
    .deep('entity');
