import { BaseStore } from '../../../../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';

export interface NavigatorContextProps
{
    id: string;
}

const defaultProps: Partial<NavigatorContextProps> =
{

};


export class NavigatorContextStore extends BaseStore<NavigatorContextProps>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable selectedPath: EntityPath;
    @observable countByPath: Map<EntityPath, EntitySelectionAggregateResult>;

    // ------------------------ Constructor -------------------------

    constructor(props: NavigatorContextProps)
    {
        super(props, defaultProps);

        this.id = props.id;
        this.countByPath = observable.map<EntityPath, EntitySelectionAggregateResult>();
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get isExpanded()
    {
        return this.selectedPath !== undefined;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    setSelectedPath(path?: EntityPath)
    {
        this.selectedPath = path;
    }

    @action.bound
    recordCount(path: EntityPath,
                count: EntitySelectionAggregateResult)
    {
        this.countByPath.set(path, count);
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
