import { BaseStore, getOrCompute, PropType } from '../../../../../../@Framework/Store/BaseStore';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { ModalDialogStore } from '../../../../../Generic/Dialog/Type/ModalDialogStore';
import { ViewComponent } from '../../../../../Generic/ViewStack/Model/ViewComponent';
import { ButtonStore } from '../../../../../Generic/Button/ButtonStore';
import { action, computed, observable } from 'mobx';
import { EntityPath } from '../../@Model/EntityPath';
import { EntityContext } from '../../../@Model/EntityContext';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import EntityPathEditorModal from './Modal/EntityPathEditorModal';
import { EntityPathEditorModalStore } from './Modal/EntityPathEditorModalStore';

export interface EntityPathEditorProps
{
    dialogTitle?: PropType<EntityPathEditorStore, EntityPathEditorProps, string>;
    entityContext?: PropType<EntityPathEditorStore, EntityPathEditorProps, EntityContext>;
    entityPath?: PropType<EntityPathEditorStore, EntityPathEditorProps, EntityPath>;
    shouldBeOfType?: PropType<EntityPathEditorStore, EntityPathEditorProps, EntityType>;
    onChange?: (entityPath: EntityPath) => Promise<any>;
    onDelete?: () => Promise<any>;
}

const defaultProps: Partial<EntityPathEditorProps> =
{

};

export class EntityPathEditorStore extends BaseStore<EntityPathEditorProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable isEditorVisible: boolean = false;
    @observable entityPath: EntityPath;

    // ------------------------ Constructor -------------------------

    constructor(props: EntityPathEditorProps)
    {
        super(props, defaultProps);

        this.entityPath = getOrCompute(this, this.props.entityPath);

    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get dialogTitle(): string
    {
        return getOrCompute(this, this.props.dialogTitle);
    }

    @computed
    get entityContext(): EntityContext
    {
        return getOrCompute(this, this.props.entityContext);
    }

    @computed
    get shouldBeOfType(): EntityType
    {
        return getOrCompute(this, this.props.shouldBeOfType);
    }

    @computed
    get isValid(): boolean
    {
        return this.shouldBeOfType
            ?
                this.entityPath
                    ?
                        this.entityPath.entityType.isA(this.shouldBeOfType)
                            ?
                                true
                            :
                                false
                    :
                        true
            :
                true;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get modalDialogStore(): ModalDialogStore
    {
        return new ModalDialogStore({
            title: this.dialogTitle,
            isOpen: this.isEditorVisible,
            isFullWidth: true,
            maxWidth: 'md',
            viewComponent:
                new ViewComponent(
                    EntityPathEditorModal as any,
                    new EntityPathEditorModalStore({
                        entityContext: this.entityContext,
                        selectedEntityPath: this.entityPath,
                        shouldBeOfType: this.shouldBeOfType,
                        onSelected: path => Promise.resolve(this.setSelectedPath(path))
                    })),
            buttons:
            [
                new ButtonStore(
                {
                    label: this.localizationStore.translate('Generic.Cancel'), // Cancel
                    onClick: this.closeEditor,
                    isDisabled: this.isLoading
                }),
                new ButtonStore(
                {
                    label: this.localizationStore.translate('Generic.Save'), // Save
                    color: 'primary',
                    isRaised: true,
                    onClick: this.saveAndClose,
                    isDisabled: this.isLoading || (!this.isValid && this.entityPath === this.props.entityPath) || (this.entityPath === this.props.entityPath)
                })
            ]
        });
    }

    @computed
    get editButtonStore(): ButtonStore
    {
        return new ButtonStore({
            color: 'primary',
            isRaised: true,
            label:
                !this.entityPath
                    ?
                        this.localizationStore.translate('Generic.Add') // Add
                    :
                        this.localizationStore.translate('Generic.Edit'), // Edit
            onClick: this.showEditor,
            style: { marginRight: 8 }
        });
    }

    @computed
    get deleteButtonStore(): ButtonStore
    {
        return new ButtonStore({
            isRaised: true,
            color: 'red',
            label: this.localizationStore.translate('Generic.Delete'), // Delete
            onClick: this.deleteComputation
        });
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    setSelectedPath(path: EntityPath)
    {
        if (this.shouldBeOfType && path.entityType.isA(this.shouldBeOfType))
        {
            this.entityPath = path;
        }
        else if (!this.shouldBeOfType)
        {
            this.entityPath = path;
        }
    }

    @action.bound
    saveAndClose()
    {
        if (this.props.onChange)
        {
            this.props
                .onChange(this.entityPath)
                .then(result =>
                    this.closeEditor());
        }
        else
        {
            this.closeEditor();
        }

        this.closeEditor();
    }

    @action.bound
    deleteComputation()
    {
        if (this.props.onDelete)
        {
            this.props.onDelete()
                .then(result =>
                    this.closeEditor());
        }
        else
        {
            this.closeEditor();
        }
    }

    @action.bound
    showEditor()
    {
        this.isEditorVisible = true;
    }

    @action.bound
    closeEditor()
    {
        this.isEditorVisible = false;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
