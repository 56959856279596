import { EntityPath } from '../../Path/@Model/EntityPath';
import getTypes from '../../Type/Api/getTypes';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';

export default function getRelatedAddressesPathsFromEntityType(entityType: EntityType): EntityPath[]
{
    const types = getTypes();

    if (entityType.isA(types.Relationship.Organization.Type))
    {
        return [
            EntityPath.fromEntityType(entityType)
                .joinTo(
                    types.Relationship.Organization.RelationshipDefinition.Organization,
                    false
                )
                .joinTo(
                    types.Relation.Organization.RelationshipDefinition.PostalAddress,
                    false
                ),
            EntityPath.fromEntityType(entityType)
                .joinTo(
                    types.Relationship.Organization.RelationshipDefinition.Organization,
                    false
                )
                .joinTo(
                    types.Relation.Organization.RelationshipDefinition.InvoiceAddress,
                    false
                ),
            EntityPath.fromEntityType(entityType)
                .joinTo(
                    types.Relationship.Organization.RelationshipDefinition.Organization,
                    false
                )
                .joinTo(
                    types.Relation.Organization.RelationshipDefinition.VisitingAddress,
                    false
                )
        ]
    }
    else if (entityType.isA(types.Relationship.Person.Type))
    {
        return [
            EntityPath.fromEntityType(entityType)
                .joinTo(
                    types.Relationship.Person.RelationshipDefinition.Person,
                    false
                )
                .joinTo(
                    types.Relation.Person.RelationshipDefinition.Address,
                    false
                )
        ]
    }
    else if (entityType.isA(types.Activity.Appointment.Type))
    {
        return [
            EntityPath.fromEntityType(entityType)
                .joinTo(
                    types.Activity.Appointment.RelationshipDefinition.Address,
                    false
                )
        ];
    }
    else if (entityType.isA(types.Activity.WorkOrder.Type))
    {
        return [
            EntityPath.fromEntityType(entityType)
                .joinTo(
                    types.Activity.WorkOrder.RelationshipDefinition.Address,
                    false
                )
        ];
    }
    else
    {
        return [];
    }
}