import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import FunctionContext from '../FunctionContext';
import Value from '../../Value/Value';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import LocalizedComputationItem from './LocalizedComputationItem';
import EmptyValue from '../../Value/EmptyValue';
import EmptyValueType from '../../Value/Type/EmptyValueType';
import { FallbackLanguageCode, Localizer } from '../../../../@Service/Localization/Localizer';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import getTypes from '../../../../@Component/Domain/Entity/Type/Api/getTypes';
import EntityValue from '../../Value/EntityValue';

export default class LocalizedComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.shallow items: LocalizedComputationItem[];
    @observable.shallow language?: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(
        items: LocalizedComputationItem[],
        language?: Computation<any, any>
    )
    {
        super();

        this.items = items;
        this.language = language;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        if (this.items.length === 0)
        {
            return EmptyValueType.instance;
        }
        else
        {
            return this.items[0].computation.getType();
        }
    }

    isAsync(): boolean
    {
        return this.language?.isAsync() ||
            this.items.some(
                item =>
                    item.isAsync()
            );
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const languageValue =
            this.language
                ? await safelyApplyFunction(
                    this.language,
                    context
                )
                : EmptyValue.instance;

        const localizedComputation = this.getLocalizedComputation(languageValue);

        if (localizedComputation)
        {
            return safelyApplyFunction(
                localizedComputation.computation,
                context);
        }
        else
        {
            return EmptyValue.instance;
        }
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        const languageValue =
            this.language
            ? safelySynchronousApplyFunction(
                    this.language,
                    context
                )
            : undefined;

        const localizedComputation = this.getLocalizedComputation(languageValue);

        if (localizedComputation)
        {
            return safelySynchronousApplyFunction(
                localizedComputation.computation,
                context
            );
        }
        else
        {
            return EmptyValue.instance;
        }
    }

    getLocalizedComputation(preferredLanguage: Value<any, any>)
    {
        const types = getTypes();

        return (
                preferredLanguage &&
                preferredLanguage instanceof EntityValue &&
                preferredLanguage.value.entityType.isA(types.Datastore.Language) &&
                this.getLocalizedComputationByLanguageCode(
                    preferredLanguage.value.getObjectValueByField(
                        types.Datastore.Field.Code
                    )
                )
            )
            ?? this.getLocalizedComputationByCurrentLanguageCode()
            ?? this.getLocalizedComputationByLanguageCode(FallbackLanguageCode)
            ?? this.getAnyLocalizedComputation();
    }

    getLocalizedComputationByCurrentLanguageCodeOrAny()
    {
        return this.getLocalizedComputationByCurrentLanguageCode()
            ?? this.getLocalizedComputationByLanguageCode(FallbackLanguageCode)
            ?? this.getAnyLocalizedComputation();
    }

    getLocalizedComputationByCurrentLanguageCode()
    {
        const languageCode = loadModuleDirectly(Localizer).languageCode;

        return this.getLocalizedComputationByLanguageCode(languageCode);
    }

    getLocalizedComputationByLanguageCode(languageCode: string)
    {
        return this.items.find(item => item.languageCode === languageCode);
    }

    getAnyLocalizedComputation()
    {
        return this.items.find(() => true);
    }

    getName(): string
    {
        return this.getLocalizedComputationByCurrentLanguageCodeOrAny()?.computation?.getName();
    }

    validate(): Validation[]
    {
        return this.items
            .map(
                item =>
                    item.validate()
            )
            .concat(
                this.language
                    ? this.language.validate()
                    : []
            )
            .reduce((a, b) => a.concat(b), []);
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Localized';
        descriptor.items = this.items.map(item => item.toDescriptor());
        descriptor.language = this.language?.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        const dependencies = this.items
            .map(
                item =>
                    item.getDependencies())
            .reduce((a, b) => a.concat(b), []);

        if (this.language)
        {
            return dependencies.concat(this.language.getDependencies());
        }
        else
        {
            return dependencies;
        }
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new LocalizedComputation(
            await Promise.all(
                descriptor.items.map(
                    item =>
                        LocalizedComputationItem.fromDescriptor(
                            item,
                            dependencyContext)
                )
            ),
            descriptor.language
                ? await getComputationFromDescriptor(
                        descriptor.language,
                        dependencyContext
                    )
                : undefined
        );
    }

    // ----------------------- Private logic ------------------------
}
