import LayoutEventTriggers from '../LayoutEventTriggers';
import LayoutEventTrigger from '../LayoutEventTrigger';
import LayoutContext from '../../LayoutContext';

export function getLayoutEventTriggerHtmlEvents(
    triggers: LayoutEventTriggers,
    context: LayoutContext
)
{
    const object = {};

    for (const trigger of triggers.triggers)
    {
        const htmlEventType = getHtmlTriggerType(trigger);

        if (htmlEventType !== undefined)
        {
            object[htmlEventType] =
                () =>
                    trigger.action.apply(context);
        }
    }

    return object;
}

function getHtmlTriggerType(trigger: LayoutEventTrigger)
{
    switch (trigger.eventType)
    {
        case 'Click':
            return 'onClick';

        case 'Change':
            return 'onChange';

        case 'Blur':
            return 'onBlur';

        default:
            return undefined;
    }
}