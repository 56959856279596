import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { default as GenericInput } from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { ContentProps } from '../../Content';
import useTypes from '../../../../Type/Api/useTypes';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { updateRelationship } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { runInAction } from 'mobx';
import { constructEntityOfType } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { FileValue } from '../../../../../DataObject/Type/File/FileValue';
import useRelatedEntities from '../../../../../../../@Api/Entity/Hooks/useRelatedEntities';
import AttachmentList from '../../../../AttachmentList/AttachmentList';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import FileDropZoneWithLibrary from '../../../../../../../@Future/Component/Generic/Input/FileDropZoneWithLibrary/FileDropZoneWithLibrary';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import CancelButton from '../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import SaveButton from '../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import { isEntityDirty } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/isEntityDirty';
import { default as EntityInput } from '../../../../Input/Input';
import { commitEntityWithContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import styles from './ActivityDigitalSigningConstructor.module.scss';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import getConnectorActivationByCode from '../../../../../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import { SignersTable } from './SignersTable/SignersTable';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import isEmailAddressValid from '../../../../../../../@Util/EmailAddress/isEmailAddressValid';
import useIsConnectorActivationActivated from '../../../../../Marketplace/Api/useIsConnectorActivationActivated';
import { updateDigitalSigningSignOrder } from '../../../../Item/Navigator/More/Signing/updateDigitalSigningSignOrder';


export interface ActivityDigitalSigningConstructorProps extends ContentProps
{
    hideSave?: boolean,
    documentMessage?: string,
    startSendingHandler?: (signingActivity: Entity) => Promise<any>,
    allowNoAttachments?: boolean,
    buttons?: ReactNode[],
}

const AllowedAttachmentMimeTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.oasis.opendocument.text",
    "text/plain"
];

export const ActivityDigitalSigningConstructor: React.FC<ActivityDigitalSigningConstructorProps> = observer(
    ({
        entity,
        commitContext,
        hideSave,
        documentMessage,
        startSendingHandler,
        allowNoAttachments,
        buttons,
        onSave,
        onClose,
        touched,
        autoCommit
     }) =>

    {
        const types = useTypes();

        const feedbackStore = loadModuleDirectly(FeedbackStore);

        const [validSignConnectorActivation] =
            useAsyncResult(
                () => getConnectorActivationByCode("ValidSign"),
                []
            );
        const hasActiveValidSignConnector = useIsConnectorActivationActivated(validSignConnectorActivation);

        const phaseEntity =
            entity.getRelatedEntityByDefinition(
                false,
                types.Activity.DigitalSigning.RelationshipDefinition.Phase,
                commitContext
            );

        const signers =
            entity.getRelatedEntitiesByDefinition(
                false,
                types.Activity.DigitalSigning.RelationshipDefinition.Signers,
                commitContext
            );

        // ===== ATTACHMENTS =======
        const addAttachment =
            useCallback(
                (attachment: Entity) => {

                    updateRelationship(
                        entity,
                        false,
                        types.Entity.RelationshipDefinition.Attachments,
                        attachment,
                        commitContext
                    );

                },
                [
                    entity,
                    types,
                    commitContext,
                ]
            );

        const addResource =
            useCallback(
                (file: FileValue) =>
                    runInAction(
                        () => {
                            if (!AllowedAttachmentMimeTypes.includes(file.mime))
                            {
                                feedbackStore.enqueueSnackbar(
                                    localizeText("ActivitySigningAttachmentTypeNotAllowed", "Alleen .pfd, .doc, docx, .rtf, .txt en .odt documenten zijn toegestaan als bijlage"),
                                    {
                                        variant: 'error'
                                    }
                                );
                                return;
                            }

                            const attachment =
                                constructEntityOfType(
                                    types.Attachment.Type,
                                    commitContext
                                );

                            setValueByFieldInEntity(
                                attachment,
                                types.Attachment.Field.File,
                                file,
                                commitContext
                            );

                            addAttachment(attachment);
                        }),
                [
                    types,
                    commitContext,
                    addAttachment,
                    feedbackStore
                ]
            );

        const updateFiles =
            useCallback(
                (files: File[]) =>
                    runInAction(
                        () =>
                        {
                            if (files.some( file => !AllowedAttachmentMimeTypes.includes(file.type)))
                            {
                                feedbackStore.enqueueSnackbar(
                                    localizeText("ActivitySigningAttachmentTypeNotAllowed", "Alleen .pfd, .doc, docx, .rtf, .txt en .odt documenten zijn toegestaan als bijlage"),
                                    {
                                        variant: 'error'
                                    }
                                );
                                return;
                            }

                            files.forEach(
                                file =>
                                {
                                    const attachment =
                                        constructEntityOfType(
                                            types.Attachment.Type,
                                            commitContext
                                        );

                                    setValueByFieldInEntity(
                                        attachment,
                                        types.Attachment.Field.File,
                                        FileValue.fromFile(file),
                                        commitContext
                                    );

                                    addAttachment(attachment);
                                });

                        }),
                [
                    types,
                    commitContext,
                    addAttachment,
                    feedbackStore
                ]);

        const attachments =
            useRelatedEntities(
                entity,
                types.Entity.RelationshipDefinition.Attachments,
                false,
                commitContext
            );

        const entityValid =
            useMemo(
                () =>
                    signers.length > 0
                    && signers.every(
                        signer =>  signer.hasValueForField(types.DigitalSigner.Field.EmailAddress, commitContext)
                            && isEmailAddressValid(signer.getObjectValueByField(types.DigitalSigner.Field.EmailAddress, commitContext))
                            && signer.hasValueForField(types.DigitalSigner.Field.LastName, commitContext)
                            && signer.hasValueForField(types.DigitalSigner.Field.FirstName, commitContext)
                    )
                    && (allowNoAttachments || attachments.length > 0),
                [
                    types,
                    allowNoAttachments,
                    signers,
                    attachments,
                    commitContext
                ]
            );

        const entityDirty =
            useMemo(
                () =>
                    isEntityDirty(entity, commitContext)
                    || (
                        signers.some(signer => isEntityDirty(signer, commitContext))
                    ),
                [
                    entity,
                    signers,
                    commitContext
                ]
            )

        const doSave =
            useCallback(
                () =>
                {
                    if (entityValid)
                    {
                        updateDigitalSigningSignOrder(entity, commitContext)

                        return commitEntityWithContext(
                            entity,
                            commitContext,
                            {
                                isForced: true
                            }
                        ).then(
                            (entity) =>
                            {
                                if (onSave)
                                {
                                    onSave(entity)
                                }
                                if (onClose)
                                {
                                    onClose();
                                }
                            }
                        );
                    }
                    else
                    {
                        feedbackStore.enqueueSnackbar(
                            localizeText("InvalidEntity", "Gegevens zijn ongeldig of niet volledig")
                        )
                    }
                },
                [
                    entity,
                    entityValid,
                    onSave,
                    onClose,
                    commitContext,
                    feedbackStore
                ]);

        const isConcept =
            useMemo(
                () => phaseEntity?.getObjectValueByField(types.Datastore.Field.Code) === 'Draft',
                [
                    phaseEntity,
                    types
                ]
            );

        const canStartSigning =
            useMemo(
                () =>
                    hasActiveValidSignConnector
                    && entityValid
                    && (hideSave ||
                        (!entityDirty
                        && isConcept)
                    ),
                [
                    hasActiveValidSignConnector,
                    entityValid,
                    isConcept,
                    entityDirty,
                    hideSave
                ]
            );

        const [ isSending, setIsSending ] = useState(false)

        const startSigning =
            useCallback(
                () =>
                {

                    setIsSending(true);

                    if (startSendingHandler)
                    {
                        startSendingHandler(entity)
                            .finally(
                                () => setIsSending(false)
                            )
                    }
                },
                [
                    entity,
                    startSendingHandler
                ]
            );

         return <ViewGroup
            orientation="vertical"
            spacing={1}
        >
            <ViewGroupItem>
                <EntityInput
                    entity={entity}
                    field={types.Activity.Field.Subject}
                    labelPosition="left"
                    touched={touched}
                    commitContext={commitContext}
                    disabled={!isConcept}
                    doAutocommit={autoCommit}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <EntityInput
                    entity={entity}
                    field={types.Activity.RelationshipDefinition.Relationship}
                    labelPosition="left"
                    touched={touched}
                    commitContext={commitContext}
                    parent
                    disabled={!isConcept}
                    doAutocommit={autoCommit}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <EntityInput
                    entity={entity}
                    field={types.Activity.DigitalSigning.RelationshipDefinition.Phase}
                    labelPosition="left"
                    touched={touched}
                    commitContext={commitContext}
                    disabled
                />
            </ViewGroupItem>

            <ViewGroupItem>
                <GenericInput
                    label={
                        <div>
                            <LocalizedText
                                code="ActivitySigningDocuments"
                                value="Documenten"
                            />
                            *
                        </div>
                    }
                    labelPosition="left"
                >
                    {
                        documentMessage !== undefined && "(" + documentMessage + ")"
                    }
                    <AttachmentList
                        attachments={attachments}
                        commitContext={commitContext}
                        readOnly={!isConcept}
                    />
                </GenericInput>
                {
                    isConcept &&
                    <FileDropZoneWithLibrary
                        onChangeFiles={updateFiles}
                        onAddFile={addResource}
                        entity={entity}
                        commitContext={commitContext}
                    />
                }
            </ViewGroupItem>

            <ViewGroupItem>
                <EntityInput
                    entity={entity}
                    field={types.Activity.DigitalSigning.Field.IsFixedSigningOrder}
                    labelPosition="left"
                    touched={touched}
                    commitContext={commitContext}
                    doAutocommit={autoCommit}
                    disabled={!isConcept}
                />
            </ViewGroupItem>
            <ViewGroupItem
                className={styles.spacing}
            >
                <SignersTable
                    entity={entity}
                    commitContext={commitContext}
                    readOnly={!isConcept}
                    autoCommit={autoCommit}
                />

            </ViewGroupItem>

            <ViewGroupItem
                alignment="right"

            >
                <RightAlignedButtonGroup>
                    {buttons}
                    {
                        onClose &&
                        <CancelButton
                            onClick={onClose}
                        />
                    }
                    { !hideSave &&
                        <SaveButton
                            onClick={doSave}
                            disabled={
                                !hasActiveValidSignConnector
                                || !entityValid
                                || !entityDirty
                                || isSending
                            }
                        />
                    }
                    <PrimaryButton
                        label={
                            <LocalizedText
                                code="ActivitySignStart"
                                value="Start"
                            />
                        }
                        onClick={startSigning}
                        disabled={!canStartSigning || isSending}
                        loading={isSending}
                    />
                </RightAlignedButtonGroup>
            </ViewGroupItem>

        </ViewGroup>
    }
)