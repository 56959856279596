import { reference, type } from '../../../@Util/Serialization/Serialization';
import { DataObject } from '../../../@Component/Domain/DataObject/Model/DataObject';
import { EntityField } from './EntityField';
import { EntityValue } from './EntityValue';
import { EntityMutation } from './EntityMutation';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { DataDescriptor } from '../../../@Component/Domain/DataObject/Model/DataDescriptor';
import { Entity } from './Entity';

@type('EntityValueMutation')
export class EntityValueMutation extends EntityMutation
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'EntityField') entityField: EntityField;
    @reference(undefined, 'EntityValue') entityValue: EntityValue;
    valueBeforeMutation: any;
    valueAfterMutation: any;

    // ------------------------- Properties -------------------------

    oldValue: DataObject;
    newValue: DataObject;

    // ------------------------ Constructor -------------------------

    constructor(entity: Entity,
                entityField: EntityField,
                entityValue: EntityValue,
                oldValue: DataObject,
                newValue: DataObject)
    {
        super(entity);

        this.entityField = entityField;
        this.entityValue = entityValue;
        this.oldValue = oldValue;
        this.newValue = newValue;
    }

    // ----------------------- Initialization -----------------------

    initialize(entityTypeStore: EntityTypeStore,
               doMergeEditableValues: boolean = true)
    {
        super.initialize(
            entityTypeStore,
            doMergeEditableValues);

        this.entityField =
            entityTypeStore.getFieldByIdOrCode(
                this.entityField.id,
                this.entityField.code);

        [ true, false ].forEach(
            isNew =>
                this.setDataObject(
                    isNew,
                    new DataObject(
                        this.entityField.dataObjectSpecification,
                        DataDescriptor.construct(
                            isNew
                                ?
                                    this.valueAfterMutation
                                :
                                    this.valueBeforeMutation,
                            this.entityField.dataObjectSpecification))));
    }

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getDataObject(isNew: boolean)
    {
        if (isNew)
        {
            return this.newValue;
        }
        else
        {
            return this.oldValue;
        }
    }

    setDataObject(isNew: boolean,
                  dataObject: DataObject)
    {
        if (isNew)
        {
            this.newValue = dataObject;
        }
        else
        {
            this.oldValue = dataObject;
        }
    }

    // ----------------------- Private logic ------------------------
}
