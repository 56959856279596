import { EntityType } from '../../../../Model/Implementation/EntityType';
import { Entity } from '../../../../Model/Implementation/Entity';
import getPhaseRelationshipDefinition from './getPhaseRelationshipDefinition';
import { EntityPath } from '../../../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntitySelectionBuilder } from '../../../../../@Component/Domain/Entity/Selection/Builder/EntitySelectionBuilder';
import getTypes from '../../../../../@Component/Domain/Entity/Type/Api/getTypes';

export default function getDefaultPhase(entityType: EntityType,
                                        pipeline?: Entity,
                                        doAnotherCheckForFirstNonDefault: boolean = true): Promise<Entity | undefined>
{
    const phaseRelationshipDefinition = getPhaseRelationshipDefinition(entityType);

    if (phaseRelationshipDefinition)
    {
        const types = getTypes();
        const phasePath = EntityPath.fromEntityType(phaseRelationshipDefinition.childEntityType);

        return new EntitySelectionBuilder(phaseRelationshipDefinition.childEntityType)
            .if(
                () =>
                    pipeline === undefined,
                sb =>
                    sb.where(
                        cb =>
                            cb.isNotDefined(
                                phasePath
                                    .joinTo(
                                        types.Pipeline.RelationshipDefinition.Phases,
                                        true)
                                    .field(types.Entity.Field.Id))))
            .if(
                () =>
                    pipeline !== undefined,
                sb =>
                    sb.where(
                        cb =>
                            cb.relatedToEntity(
                                phasePath.joinTo(
                                    types.Pipeline.RelationshipDefinition.Phases,
                                    true),
                                pipeline)))
            .if(
                () =>
                    pipeline === undefined,
                sb =>
                    sb.where(
                        cb =>
                            cb.isNotDefined(
                                phasePath
                                    .joinTo(
                                        types.Pipeline.RelationshipDefinition.Phases,
                                        true)
                                    .field(types.Entity.Field.Id))))
            .if(
                () =>
                    doAnotherCheckForFirstNonDefault,
                sb =>
                    sb.where(
                        cb =>
                            cb.eq(
                                phasePath.field(types.Datastore.Field.IsDefault),
                                undefined,
                                true)))
            .select(0, 1)
            .then(
                results =>
                {
                    const defaultPhase = results.length > 0 ? results[0].entity : undefined;

                    if (defaultPhase)
                    {
                        return Promise.resolve(defaultPhase);
                    }
                    else
                    {
                        if (doAnotherCheckForFirstNonDefault)
                        {
                            return getDefaultPhase(entityType, pipeline, false);
                        }
                        else
                        {
                            return Promise.resolve(undefined);
                        }
                    }
                });
    }
    else
    {
        return Promise.resolve(undefined);
    }
}
