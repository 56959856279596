import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import moment from 'moment';
import CurrentUserContext from '../../../User/CurrentUserContext';
import useCreateTimeRegistration from '../Hooks/useCreateTimeRegistration';
import Input from '../../Input/Input';
import useTypes from '../../Type/Api/useTypes';
import { EntityExpansionBuilder } from '../../Selection/Builder/EntityExpansionBuilder';
import { EntityPath } from '../../Path/@Model/EntityPath';
import Dialog from '../../../../../@Future/Component/Generic/Dialog/Dialog';
import DialogContent from '../../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import DialogTitle from '../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import { observer, useComputed } from 'mobx-react-lite';
import CancelButton from '../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import SaveButton from '../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import DeleteButton from '../../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';
import Planner from '../../Planner/Planner';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import RightAlignedButtonGroup from '../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import deleteEntity from '../../../../../@Api/Entity/deleteEntity';
import getCurrentlyOpenedEntity from '../../@Util/getCurrentlyOpenedEntity';
import { useNewCommitContext } from '../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { setValueByFieldInEntity } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import { computed } from 'mobx';

export interface TimeRegistrationProps
{
    timeRegistration?: Entity;
    close: () => void;
    start?: Date;
    end?: Date;
    onSave?: (Entity) => void;
    hideStartDateWhenNew?: boolean;
    hideEndDateWhenNew?: boolean;
}

const TimeRegistration: React.FC<TimeRegistrationProps> =
    props =>
    {
        const types = useTypes();
        const commitContext = useNewCommitContext();
        const currentUser = useContext(CurrentUserContext);
        const createTimeRegistration = useCreateTimeRegistration(commitContext);
        const [ timeRegistration, setTimeRegistration ] = useState<Entity>();
        const [ isTouched, setTouched ] = useState<boolean>();
        const { close, onSave } = props;

        useEffect(
            () =>
            {
                if (props.timeRegistration)
                {
                    const rootPath = EntityPath.fromEntityType(types.TimeRegistration.Type);

                    new EntityExpansionBuilder(
                        types.TimeRegistration.Type,
                        [
                            props.timeRegistration
                        ],
                        [
                            rootPath.joinTo(
                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                true),
                            rootPath.joinTo(
                                types.TimeRegistration.RelationshipDefinition.Activity,
                                false),
                            rootPath.joinTo(
                                types.Relationship.RelationshipDefinition.TimeRegistrations,
                                true),
                            rootPath.joinTo(
                                types.Activity.RelationshipDefinition.TimeRegistrations,
                                true)
                        ])
                        .expand()
                        .then(
                            () =>
                                setTimeRegistration(props.timeRegistration)
                        );
                }
                else
                {
                    const currentlyOpenedEntity = getCurrentlyOpenedEntity();

                    setTimeRegistration(
                        createTimeRegistration(
                            currentUser.employeeEntity,
                            currentlyOpenedEntity?.entityType.isA(types.Relationship.Type)
                                && !currentlyOpenedEntity?.entityType.isA(types.Relationship.Person.Contact.Type)
                                ?
                                    currentlyOpenedEntity
                                :
                                    undefined,
                            undefined,
                            currentlyOpenedEntity?.entityType.isA(types.Activity.Type)
                                ?
                                    currentlyOpenedEntity
                                :
                                    undefined,
                            props.start ? props.start : moment(new Date()).startOf('minute').toDate(),
                            props.end,
                            undefined /*lastTimeRegistration*/));
                }
            },
            [
                currentUser,
                createTimeRegistration,
                props.timeRegistration,
                types,
                setTimeRegistration,
                props.start,
                props.end
            ]);

        const isNew =
            useMemo(
                () =>
                    !props.timeRegistration,
                [
                    props.timeRegistration
                ]);

        const saveTimeRegistration =
            useCallback(
                () =>
                {
                    if (props.hideStartDateWhenNew
                        && timeRegistration.isNew())
                    {
                        setValueByFieldInEntity(
                            timeRegistration,
                            types.TimeRegistration.Field.StartDate,
                            new Date(),
                            commitContext
                        );
                    }

                    setTouched(true);

                    if (timeRegistration.isValid)
                    {
                        return commitEntityWithContext(timeRegistration, commitContext)
                            .then(
                                () =>
                                {
                                    if (onSave)
                                    {
                                        onSave(timeRegistration);
                                    }

                                    close();
                                });
                    }
                },
                [
                    props.hideStartDateWhenNew,
                    types,
                    timeRegistration,
                    close,
                    onSave,
                    setTouched,
                    commitContext,
                ]);

        const deleteTimeRegistration =
            useCallback(
                () =>
                {
                    return deleteEntity(props.timeRegistration)
                        .then(() => close());
                },
                [
                    props.timeRegistration,
                    close
                ]);

        const activity =
            useComputed(
                () =>
                    timeRegistration &&
                        timeRegistration.getRelatedEntityByDefinition(
                            true,
                            types.Activity.RelationshipDefinition.TimeRegistrations,
                            commitContext
                        ),
                [
                    timeRegistration,
                    types,
                    commitContext,
                ]);

        const employeePath =
            useMemo(
                () =>
                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                        .joinTo(
                            types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                            true),
                [
                    types
                ]);

        useEffect(
            () =>
                computed(
                    () =>
                        timeRegistration.getRelatedEntityByDefinition(
                            false,
                            types.TimeRegistration.RelationshipDefinition.Activity,
                            commitContext
                        )
                )
                .observe(
                    change =>
                    {
                        if (change.newValue)
                        {
                            new EntityExpansionBuilder(
                                change.newValue.entityType,
                                [
                                    change.newValue
                                ],
                                [
                                    EntityPath.fromEntity(change.newValue)
                                        .joinTo(
                                            types.TimeRegistrationActivity.RelationshipDefinition.Product,
                                            false
                                        )
                                ]
                            )
                            .expand();
                        }
                    }
                ),
            [
                timeRegistration,
                types,
                commitContext,
            ]
        );

        const isBillableAllowed =
            useComputed(
                () =>
                    {
                        if (timeRegistration)
                        {
                            return timeRegistration.getRelatedEntitiesByDefinition(
                                false,
                                types.TimeRegistration.RelationshipDefinition.Activity,
                                commitContext
                            )
                                .some(
                                    activity =>
                                        activity.hasRelationshipsByDefinition(
                                            false,
                                            types.TimeRegistrationActivity.RelationshipDefinition.Product,
                                            commitContext
                                        )
                                )
                        }
                    },
                    [
                        timeRegistration,
                        types,
                        commitContext,
                    ]
            );

        return <Dialog
            open={true}
            width="sm"
        >
            <DialogTitle>
                {
                    isNew
                    ?
                        <LocalizedText
                            code="TimeRegistration.New"
                            value="Nieuwe urenregistratie"
                        />
                    :
                        <LocalizedText
                            code="TimeRegistration.Edit"
                            value="Urenregistratie bewerken"
                        />
                }
            </DialogTitle>
            <DialogContent>
                {
                    timeRegistration &&
                        <InputGroup>
                            {
                                !props.hideStartDateWhenNew && !props.hideEndDateWhenNew &&
                                    <Planner
                                        entity={timeRegistration}
                                        startDateField={types.TimeRegistration.Field.StartDate}
                                        endDateField={types.TimeRegistration.Field.EndDate}
                                        employeePath={employeePath}
                                        commitContext={commitContext}
                                    />
                            }
                            {
                                !props.hideStartDateWhenNew && props.hideEndDateWhenNew &&
                                    <Input
                                        labelPosition="left"
                                        entity={timeRegistration}
                                        field={types.TimeRegistration.Field.StartDate}
                                        doAutocommit={false}
                                        touched={isTouched}
                                        commitContext={commitContext}
                                    />
                            }
                            {
                                !props.hideEndDateWhenNew && props.hideStartDateWhenNew &&
                                    <Input
                                        labelPosition="left"
                                        entity={timeRegistration}
                                        field={types.TimeRegistration.Field.EndDate}
                                        doAutocommit={false}
                                        touched={isTouched}
                                        commitContext={commitContext}
                                    />
                            }
                            <Input
                                labelPosition="left"
                                entity={timeRegistration}
                                field={types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations}
                                parent
                                doAutocommit={false}
                                touched={isTouched}
                                commitContext={commitContext}
                            />
                            <Input
                                labelPosition="left"
                                entity={timeRegistration}
                                field={types.Relationship.RelationshipDefinition.TimeRegistrations}
                                parent
                                doAutocommit={false}
                                touched={isTouched}
                                commitContext={commitContext}
                            />
                            <Input
                                labelPosition="left"
                                entity={timeRegistration}
                                field={types.Activity.RelationshipDefinition.TimeRegistrations}
                                parent
                                doAutocommit={false}
                                touched={isTouched}
                                commitContext={commitContext}
                            />
                            {
                                activity && activity.entityType.isA(types.Activity.Project.Type) &&
                                    <Input
                                        labelPosition="left"
                                        entity={timeRegistration}
                                        field={types.Milestone.RelationshipDefinition.TimeRegistrations}
                                        parent
                                        doAutocommit={false}
                                        touched={isTouched}
                                        commitContext={commitContext}
                                    />
                            }
                            <Input
                                labelPosition="left"
                                entity={timeRegistration}
                                field={types.TimeRegistration.RelationshipDefinition.Activity}
                                doAutocommit={false}
                                touched={isTouched}
                                commitContext={commitContext}
                            />
                            {
                                isBillableAllowed &&
                                    <Input
                                        labelPosition="left"
                                        entity={timeRegistration}
                                        field={types.TimeRegistration.Field.IsBillable}
                                        doAutocommit={false}
                                        touched={isTouched}
                                        commitContext={commitContext}
                                    />
                            }
                            <Input
                                labelPosition="left"
                                entity={timeRegistration}
                                field={types.TimeRegistration.Field.Description}
                                doAutocommit={false}
                                touched={isTouched}
                                commitContext={commitContext}
                            />
                        </InputGroup>
                }
                <RightAlignedButtonGroup>
                    {
                        !isNew &&
                        <DeleteButton
                            onClick={deleteTimeRegistration}
                        />
                    }
                    <CancelButton
                        onClick={close}
                    />
                    <SaveButton
                        onClick={saveTimeRegistration}
                        label={
                            timeRegistration && timeRegistration.isNew() && props.hideStartDateWhenNew
                                ?
                                    <LocalizedText
                                        code="TimeRegistration.Start"
                                        value="Starten"
                                    />
                                :
                                    <LocalizedText
                                        code="Generic.Save"
                                    />
                        }
                    />
                </RightAlignedButtonGroup>
            </DialogContent>
        </Dialog>;
    };

export default observer(TimeRegistration);
