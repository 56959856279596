import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import { ViewParams } from '../Model/ViewParams';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import getTypes from '../../Type/Api/getTypes';
import localizeText from '../../../../../@Api/Localization/localizeText';
import PrimitiveValueType from '../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../DataObject/Model/DataObject';

export default function getViewParameters(
    entityType: EntityType
)
{
    return new ParameterDictionary([
        new Parameter(
            ViewParams.Entity,
            new EntityValueType(entityType),
            true,
            entityType.getName()),
        new Parameter(
            ViewParams.Me,
            new EntityValueType(getTypes().Relationship.Person.Contact.Employee.Type),
            true,
            localizeText('Generic.Myself', 'Mijzelf')),
        new Parameter(
            ViewParams.CurrentDateTime,
            new PrimitiveValueType(DataObject.getTypeById('DateTime')),
            true,
            localizeText('Generic.CurrentDateTime', 'Huidige datum & tijd'))
    ]);
}
