import React, { useCallback, useMemo, useState } from 'react';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { default as GenericAvatar } from '../../../../@Future/Component/Generic/Avatar/Avatar';
import useAvatarSrc from './Api/useAvatarSrc';
import useAllowCharactersOrIcon from './Api/useAllowCharactersOrIcon';
import useIcon from './Api/useIcon';
import useCharacters from './Api/useCharacters';
import useColor from './Api/useColor';
import { classNames } from '../../../../@Future/Util/Class/classNames';
import useAvatarEntity from './Api/useAvatarEntity';
import useIsEditable from './Api/useIsEditable';
import styles from './Avatar.module.scss';
import EntityPictureEditor from '../Picture/Editor/EntityPictureEditor';
import AvatarWithSubAvatar from '../../../../@Future/Component/Generic/AvatarWithSubAvatar/AvatarWithSubAvatar';
import { colorToCss } from '../../DataObject/Type/Color/ColorType';
import useTypes from '../Type/Api/useTypes';
import getSubAvatarSize from '../../../../@Future/Component/Generic/AvatarWithSubAvatar/Api/getSubAvatarSize';
import getSubAvatarIconSize from '../../../../@Future/Component/Generic/AvatarWithSubAvatar/Api/getSubAvatarIconSize';
import { openEntity } from '../@Util/openEntity';
import { useFetchedApiFile } from '../../../../@Service/ApiClient/Hooks/useFetchedApiFile';

export interface AvatarClasses
{
    root?: string;
}
export interface AvatarProps
{
    entity: Entity;
    size?: number;
    editable?: boolean;
    classes?: AvatarClasses;
    linkMode?: 'Editor' | 'Link';
}

const Avatar: React.FC<AvatarProps> =
    props =>
    {
        const avatarEntity = useAvatarEntity(props.entity);
        const src = useAvatarSrc(avatarEntity);
        const [ initializedSrc ] = useFetchedApiFile(src, true);
        const allowCharactersOrIcon = useAllowCharactersOrIcon(avatarEntity);
        const icon = useIcon(avatarEntity, allowCharactersOrIcon);
        const characters = useCharacters(avatarEntity, allowCharactersOrIcon);
        const color = useColor(avatarEntity);
        const isEditable = useIsEditable(avatarEntity, props.editable);
        const [ isInEditMode, setEditMode ] = useState(false);
        const openEditor =
            useCallback(
                () =>
                    props.linkMode === 'Editor'
                        ?
                            isEditable
                                ?
                                    setEditMode(true)
                                :
                                    undefined
                        :
                            openEntity(props.entity),
                [
                    props.linkMode,
                    props.entity,
                    isEditable,
                    setEditMode
                ]);
        const closeEditor =
            useCallback(
                () =>
                    setEditMode(false),
                [
                    setEditMode
                ]);

        const types = useTypes();
        const subAvatar =
            useMemo(
                () =>
                {
                    if (props.entity.bespoke.isInClosedState)
                    {
                        const state = props.entity.bespoke.phase;

                        return <GenericAvatar
                            icon={state.getObjectValueByField(types.Datastore.Phase.Field.Icon)}
                            iconColor="white"
                            iconSize={getSubAvatarIconSize(35)}
                            color={colorToCss(state.getObjectValueByField(types.Datastore.Phase.Field.Color))}
                            size={getSubAvatarSize(35)}
                        />;
                    }
                    else
                    {
                        const entityToShowTypeOf = props.entity.entityType.bespoke.getEntityToShowTypeAvatarOf(props.entity);

                        if (entityToShowTypeOf)
                        {
                            return <GenericAvatar
                                icon={entityToShowTypeOf.entityType.getInheritedIcon()}
                                iconColor="white"
                                iconSize={getSubAvatarIconSize(35)}
                                color={entityToShowTypeOf.entityType.getInheritedColor()}
                                size={getSubAvatarSize(35)}
                            />;
                        }
                        else
                        {
                            return undefined;
                        }
                    }
                },
                [
                    props.entity,
                    types
                ]);
        const avatar =
            <GenericAvatar
                src={initializedSrc}
                icon={characters && characters.length > 0 ? undefined : icon}
                color={color}
                characters={characters}
                size={props.size}
            />;

        return <>
            <div
                className={
                    classNames(
                        styles.root,
                        isEditable && styles.editable,
                        props.classes && props.classes.root)
                }
                onClick={openEditor}
            >
                {
                    subAvatar
                        ?
                            <AvatarWithSubAvatar
                                avatar={avatar}
                                subAvatar={subAvatar}
                            />
                        :
                            avatar
                }
            </div>
            {
                isInEditMode &&
                    <EntityPictureEditor
                        entity={avatarEntity}
                        onClose={closeEditor}
                        onSave={closeEditor}
                    />
            }
        </>;
    };

Avatar.defaultProps =
    {
        editable: true,
        linkMode: 'Editor'
    };

export default Avatar;
