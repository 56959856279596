import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import Name from '../../../../Item/Name/Name';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Buttons from '../../../../Item/Navigator/Buttons/Buttons';
import Navigator from '../../../../Item/Navigator/Navigator';

import styles from './Activity.module.scss';
import EntityLink from '../../../../Link/EntityLink';
import useIsContextExpanded from '../../../../Item/Navigator/Api/useIsContextExpanded';
import useSetContextExpansion from '../../../../Item/Navigator/Api/useSetContextExpansion';
import getDisplayType from '../../Api/getDisplayType';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import SmallOverlineLabel from '../../../../../../../@Future/Component/Generic/Label/Variant/SmallOverline/SmallOverlineLabel';
import Caption from '../../../../Item/Caption/Caption';

export interface ActivityProps
{
    entity: Entity;
    appendix?: React.ReactNode;
    nested?: boolean;
}

const Activity: React.FC<ActivityProps> =
    props =>
    {
        const isContextExpanded = useIsContextExpanded();
        const setContextExpansion = useSetContextExpansion();
        const closeExpansionCallback =
            useCallback(
                () =>
                    setContextExpansion(undefined),
                [
                    setContextExpansion
                ]);

        const itemHeader =
            <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                <ViewGroupItem
                    ratio={1}
                >
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            <div>
                                <SmallOverlineLabel>
                                    {getDisplayType(props.entity.entityType).getName()}
                                </SmallOverlineLabel>
                            </div>
                            <Name
                                entity={props.entity}
                                editable={false}
                                link={false}
                            />
                        </ViewGroupItem>
                        {
                            props.appendix &&
                                <ViewGroupItem>
                                    {props.appendix}
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Caption
                        entity={props.entity}
                    />
                </ViewGroupItem>
                {
                    !props.nested &&
                        <ViewGroupItem>
                            <Buttons
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                }
            </ViewGroup>;

        if (props.nested)
        {
            return <div
                className={classNames(styles.title, styles.nested)}
            >
                <EntityLink
                    entity={props.entity}
                >
                    {props.entity.name}
                </EntityLink>
            </div>;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                <ViewGroupItem
                    className={styles.title}
                >
                    {
                        isContextExpanded
                            ?
                                <div
                                    onClick={closeExpansionCallback}
                                >
                                    {
                                        itemHeader
                                    }
                                </div>
                            :
                                <EntityLink
                                    entity={props.entity}
                                >
                                    {
                                        itemHeader
                                    }
                                </EntityLink>
                    }
                </ViewGroupItem>
                <ViewGroupItem
                    ratio={1}
                >
                    <Navigator
                        entity={props.entity}
                    />
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

export default observer(Activity);
