import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import InputGroup from '../../../../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { AggregateQueryEditorProps } from './AggregateQueryEditor';
import { EntityContext } from '../../../../../../../../../@Model/EntityContext';
import { EntityPath } from '../../../../../../../../../Path/@Model/EntityPath';
import useSwitch from '../../../../../../../../../../../../@Util/Switch/useSwitch';
import { EntityFieldPath } from '../../../../../../../../../Path/@Model/EntityFieldPath';
import ViewGroup from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Card from '../../../../../../../../../../../../@Future/Component/Generic/Card/Card';
import FieldPathSelector from '../../../../../../../../../Path/FieldPathEditor/FieldPathSelector';
import Popper from '../../../../../../../../../../../../@Future/Component/Generic/Popper/Popper';
import Link from '../../../../../../../../../../../../@Future/Component/Generic/Link/Link';
import Menu from '../../../../../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import { Aggregate } from '../../../../../../../../../../DataObject/Model/Aggregate';
import { getAggregates, getAggregateSymbol } from '../../../../../../../../../../DataObject/Util/SymbolUtils';
import LocalizedText from '../../../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface AggregateQueryOptionsEditorProps extends AggregateQueryEditorProps
{

}

const AggregateQueryOptionsEditor: React.FC<AggregateQueryOptionsEditorProps> =
    props =>
    {
        const [ isAggregateSelectorOpen, openAggregateSelector, closeAggregateSelector ] = useSwitch(false);
        const selectAggregate =
            useCallback(
                (aggregate: Aggregate) =>
                    runInAction(
                        () =>
                        {
                            props.query.aggregate = aggregate;

                            if (aggregate === Aggregate.Count)
                            {
                                props.query.aggregateFieldPath = undefined;
                            }

                            closeAggregateSelector();
                        }),
                [
                    props.query,
                    closeAggregateSelector
                ]);

        const fieldContext =
            useMemo(
                () =>
                    new EntityContext(
                        [],
                        EntityPath.fromEntityType(props.query.entityType)),
                [
                    props.query.entityType
                ]);

        const [ isFieldSelectorOpen, openFieldSelector, closeFieldSelector ] = useSwitch(false);
        const selectFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                        {
                            props.query.aggregateFieldPath = fieldPath;

                            // props.query.fieldMappings
                            //     .push(
                            //         new ValueFieldMapping(
                            //             resolveInputFromFieldPath(fieldPath),
                            //             undefined));

                            closeFieldSelector();
                        }),
                [
                    props.query,
                    closeFieldSelector
                ]);

        return <InputGroup>
            <Input
                label="Aggregatie"
                labelPosition="left"
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={5}
                >
                    <ViewGroupItem>
                        Selecteer
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Popper
                            reference={
                                <Link
                                    onClick={openAggregateSelector}
                                    highlighted
                                >

                                    {getAggregateSymbol(props.query.aggregate)}
                                </Link>
                            }
                            popper={
                                <Card>
                                    <Menu>
                                        {
                                            getAggregates()
                                                .map(
                                                    aggregate =>
                                                        <Item
                                                            active={props.query.aggregate === aggregate}
                                                            name={getAggregateSymbol(aggregate)}
                                                            onClick={() => selectAggregate(aggregate)}
                                                        />)
                                        }
                                    </Menu>
                                </Card>
                            }
                            open={isAggregateSelectorOpen}
                            onClose={closeAggregateSelector}
                        />
                    </ViewGroupItem>
                    {
                        props.query.aggregate !== Aggregate.Count &&
                            <ViewGroupItem>
                                van
                            </ViewGroupItem>
                    }
                    {
                        props.query.aggregate !== Aggregate.Count &&
                            <ViewGroupItem>
                                <Popper
                                    reference={
                                        <Link
                                            onClick={openFieldSelector}
                                            highlighted
                                        >
                                            {
                                                props.query.aggregateFieldPath?.getPathName()?.toLowerCase() ||
                                                    <LocalizedText
                                                        code="Computation.ChooseField"
                                                        value="kies een veld"
                                                        lowerCase
                                                    />
                                            }
                                        </Link>
                                    }
                                    popper={
                                        <Card>
                                            <FieldPathSelector
                                                context={fieldContext}
                                                value={props.query.aggregateFieldPath}
                                                onSelect={selectFieldPath}
                                            />
                                        </Card>
                                    }
                                    open={isFieldSelectorOpen}
                                    onClose={closeFieldSelector}
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </Input>
        </InputGroup>;
    };

export default observer(AggregateQueryOptionsEditor);
