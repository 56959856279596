import React from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import AttachmentList from '../AttachmentList/AttachmentList';
import AttachmentDropZone from '../Bespoke/Attachment/DropZone/AttachmentDropZone';
import { default as GenericInput } from '../../../../@Future/Component/Generic/Input/Input/Input';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import useTypes from '../Type/Api/useTypes';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import useRelatedEntities from '../../../../@Api/Entity/Hooks/useRelatedEntities';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';

export interface EntityAttachmentInputProps
{
    entity: Entity;
    commitContext?: CommitContext;
}

const EntityAttachmentInput: React.FC<EntityAttachmentInputProps> =
    props =>
    {
        const types = useTypes();

        const attachments =
            useRelatedEntities(
                props.entity,
                types.Entity.RelationshipDefinition.Attachments,
                false,
                props.commitContext
            );

        return <GenericInput
            label={
                <LocalizedText
                    code="Attachments"
                    value="Bijlagen"
                />
            }
            labelPosition="top"
        >
            <div
                style={{
                    marginTop: 4
                }}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={10}
                >
                    <ViewGroupItem>
                        <AttachmentList
                            attachments={attachments}
                            commitContext={props.commitContext}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <AttachmentDropZone
                            entity={props.entity}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </div>
        </GenericInput>;
    };

export default observer(EntityAttachmentInput);
