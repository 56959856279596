import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import getDefaultPipelineName from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/getDefaultPipelineName';

export class BespokePipeline extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Pipeline')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getEmptyOption(): string | undefined
    {
        // Default pipeline name is stored in settings using SalesOpportunity Type id
        const salesOpportunityType = this.entityTypeStore.bespoke.types.Activity.SalesOpportunity.Type;
        return getDefaultPipelineName(salesOpportunityType);
    }

    // ----------------------- Private logic ------------------------
}
