import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Role, { RoleType } from '../../../Model/Role';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { RightEditor } from '../../RightEditor/RightEditor';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Input from '../../../../Multiplayer/Model/Input/Input';
import FieldInput from '../../../../Multiplayer/Model/Input/FieldInput';
import RelationshipInput from '../../../../Multiplayer/Model/Input/RelationshipInput';
import styles from '../../RoleEditor.module.scss';
import { DataType } from '../../../../Configuration/TypeManager/TypeEditor/FieldsEditor/Model/DataType';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { EntityMetadataPermission } from '../../../Model/EntityMetadataPermission';
import { RightPredicateButton } from '../../RightPredicateEditor/RightPredicateButton';

export interface ItemEditorProps
{
    rolesByType: Map<RoleType, Role>;
    role: Role;
    input: Input;
    isReadOnly: boolean;
}

export const ItemEditor: React.FC<ItemEditorProps> =
    observer(
        ({
             rolesByType,
             role,
             input,
             isReadOnly,
         }) =>
        {
            const isField =
                useComputed(
                    () =>
                        input instanceof FieldInput,
                    [
                        input
                    ]);

            const field =
                useComputed(
                    () =>
                        isField
                            ? (input as FieldInput).field
                            : undefined,
                    [
                        input,
                        isField
                    ]);

            const relationshipDefinition =
                useComputed(
                    () =>
                        !isField
                            ? (input as RelationshipInput).relationshipDefinition
                            : undefined,
                    [
                        input,
                        isField
                    ]);

            const metadataPermission: EntityMetadataPermission =
                useMemo(
                    () =>
                    {
                        return isField
                            ?
                                {
                                    type: 'EntityField',
                                    field: field,
                                }
                            :
                                {
                                    type: 'RelationshipDefinition',
                                    relationship: relationshipDefinition,
                                    isParent: relationshipDefinition.childEntityType.code === input.entityType.code
                                };
                    },
                    [
                        isField,
                        field,
                        relationshipDefinition,
                    ]
                );

            const dataTypeFormatter =
                useCallback(
                    (type: DataType) =>
                    {
                        if (type instanceof EntityType)
                        {
                            if (type.parentType)
                            {
                                return `${type.parentType.getName(false)}: ${type.getName(false)}`;
                            }
                            else
                            {
                                return type.getName(false);
                            }
                        }
                        else
                        {
                            return type.name();
                        }
                    },
                    []);

            const dataType =
                useComputed(
                    () =>
                        field
                            ? dataTypeFormatter(field.dataObjectSpecification.type)
                            : relationshipDefinition
                                ? dataTypeFormatter(relationshipDefinition.childEntityType)
                                : null,
                    [
                        field,
                        relationshipDefinition
                    ]);

            return <CardInset>
                <ViewGroup
                    orientation="horizontal"
                    spacing={10}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                        className={styles.label}
                    >
                        {input.getName()}
                        <span className={styles.dataType}>
                            {dataType}
                        </span>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <RightPredicateButton
                            rolesByType={rolesByType}
                            role={role}
                            metadataPermission={metadataPermission}
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <RightEditor
                            rolesByType={rolesByType}
                            role={role}
                            metadataPermission={metadataPermission}
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </CardInset>;
        }
    );
