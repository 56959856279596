import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { computed } from 'mobx';
import { EntityPath } from './EntityPath';

export class RelatedEntityPath
{
    // ------------------------- Properties -------------------------

    entity: Entity;
    path: EntityPath;

    // ------------------------ Constructor -------------------------

    constructor(entity: Entity, path: EntityPath)
    {
        this.entity = entity;
        this.path = path;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get id(): string
    {
        return `${this.entity ? this.entity.uuid : ''}:${this.path.id}`;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
