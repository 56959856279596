import { getOrCompute, PropType } from '../../../@Framework/Store/BaseStore';
import { computed } from 'mobx';
import { ListStore } from '../List/V2/ListStore';
import { ListItemStore } from '../List/V2/Item/ListItemStore';
import { ViewGroupItem } from './ViewGroupItem';
import { CSSProperties } from 'react';
import { ViewComponent } from '../ViewStack/Model/ViewComponent';
import ViewGroupList from './ViewGroupList';

export type ViewGroupListDirection = 'horizontal' | 'vertical';
export type ViewGroupListAlignment = 'start' | 'end' | 'center';
export type ViewGroupListJustifyContent = ViewGroupListAlignment | 'space-between' | 'space-around' | 'stretch' | 'space-evenly';

export interface ViewGroupListStoreProps
{
    items: PropType<ViewGroupStore, ViewGroupListStoreProps, ViewGroupItem[]>;
    direction?: PropType<ViewGroupStore, ViewGroupListStoreProps, ViewGroupListDirection>;
    alignment?: PropType<ViewGroupStore, ViewGroupListStoreProps, ViewGroupListAlignment>;
    justifyContent?: PropType<ViewGroupStore, ViewGroupListStoreProps, ViewGroupListJustifyContent>;
    style?: PropType<ViewGroupStore, ViewGroupListStoreProps, CSSProperties>;
}

const defaultProps: Partial<ViewGroupListStoreProps> =
{
    direction: 'vertical'
};

export class ViewGroupStore extends ListStore<ViewGroupItem, {}, ListItemStore<ViewGroupItem>, {}, {}, ViewGroupListStoreProps>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: ViewGroupListStoreProps)
    {
        super({
            ...props,
            id: item => item.id,
            load: () => Promise.resolve(this.items),
            construct:
                    item =>
                        new ListItemStore<ViewGroupItem>({
                            template: item.viewComponent,
                            isHidden:
                                () =>
                                    item.isHidden
                        }),
            template:
                () =>
                    new ViewComponent(
                        ViewGroupList,
                        this)
        },
        defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get items(): ViewGroupItem[]
    {
        return getOrCompute(this, this.props.items);
    }

    @computed
    get direction(): ViewGroupListDirection
    {
        return getOrCompute(this, this.props.direction);
    }

    @computed
    get alignment(): ViewGroupListAlignment
    {
        return getOrCompute(this, this.props.alignment);
    }

    @computed
    get justifyContent(): ViewGroupListJustifyContent
    {
        return getOrCompute(this, this.props.justifyContent);
    }

    @computed
    get style(): CSSProperties
    {
        return getOrCompute(this, this.props.style);
    }

    @computed
    get isHorizontal(): boolean
    {
        return this.direction === 'horizontal';
    }

    @computed
    get isVertical(): boolean
    {
        return this.direction === 'vertical';
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
