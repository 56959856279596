import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { ButtonStore } from './ButtonStore';
import { Badge, CircularProgress, ClickAwayListener, createStyles, Grow, Paper, Theme, Tooltip, withStyles } from '@material-ui/core';
import { PopperManager } from '../Popper/PopperManager';
import { PopperReference } from '../Popper/PopperReference';
import { PopperContainer } from '../Popper/PopperContainer';
import { red } from '@material-ui/core/colors';

import { IconButton } from './Type/IconButton';
import { AvatarButton } from './Type/AvatarButton';
import { LabelButton } from './Type/LabelButton';
import { ToggleButton } from './Type/ToggleButton';
import ChipButton from './Type/ChipButton';
import theme from '../../../@Resource/Theme/Theme';
import { View } from '../ViewStack/View';
import { MuiThemeProvider } from '@material-ui/core/styles';

const styles =
    (theme: Theme) =>
        createStyles({
            root:
            {
                display: 'inline-block',
                position: 'relative'
            },
            buttonProgress:
            {
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
            },
            popperViewPaper:
            {
                borderRadius: 5,
                minWidth: 'max-content',
                // maxHeight: 400,
                // overflowY: 'auto !important' as OverflowYProperty,
                // overflowX: 'hidden',
                // position: 'absolute'
            },
            badge:
            {
                fontSize: 11,
                borderRadius: '40%',
                maxHeight: 17,
                marginTop: 5,
                marginRight: 5
            },
            badgeColorPrimary:
            {
                backgroundColor: red.A700,
                color: 'white'
            },
            badgeDot:
            {
                marginTop: 10
            }
        });

@observer
class Button extends BaseComponent<ButtonStore>
{
    renderLoader(store: ButtonStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: ButtonStore)
    {
        if (store.viewOnly && store.view)
        {
            return <View viewComponent={store.view} />;
        }
        else if (store.isVisible)
        {
            if (store.popperView)
            {
                const popperView =
                    <MuiThemeProvider
                        theme={theme}
                    >
                        <View
                            viewComponent={store.popperView}
                        />
                    </MuiThemeProvider>;

                return <PopperManager>
                    <PopperReference
                        isInline
                    >
                        {this.renderButtonLayout(store)}
                    </PopperReference>
                    <PopperContainer
                        placement={store.popperViewPlacement}
                        eventsEnabled={true}
                        style={{
                            zIndex: 1500,
                            left: store.popperViewOffset
                        }}
                        modifiers={[
                            {
                                name: 'preventOverflow',
                                enabled: true
                            }
                        ]}
                    >
                        <ClickAwayListener
                            onClickAway={store.popperCloseOnClickAway ? store.closePopperView : () => true}
                        >
                            <Grow
                                in={true}
                            >
                                {
                                    store.popperNoPaper
                                    ?
                                        <div
                                            onClick={(e) => e.stopPropagation ? e.stopPropagation() : undefined}
                                        >
                                            {
                                                popperView
                                            }
                                        </div>
                                    :
                                        <Paper
                                            className={`${this.props.classes.popperViewPaper} OwnOverflow`}
                                            elevation={3}
                                            onClick={(e) => e.stopPropagation ? e.stopPropagation() : undefined}
                                        >
                                            {
                                                popperView
                                            }
                                        </Paper>
                                }
                            </Grow>
                        </ClickAwayListener>
                    </PopperContainer>
                </PopperManager>;
            }
            else
            {
                return this.renderButtonLayout(store);
            }
        }
        else
        {
            return null;
        }
    }

    renderButtonLayout(store: ButtonStore)
    {
        return <div
            className={this.props.classes.root}
            style={{
                display: store.fullWidth ? 'flex' : undefined
            }}
        >
            {
                store.hasBadge
                    ?
                        this.renderBadge(store)
                    :
                        this.renderButton(store)
            }
            {
                !store.isLabelButton && store.showLoader && store.iconPosition !== 'top' && // If an icon is above a label there is a custom loader (position and color is different)
                    <CircularProgress
                        size={24}
                        className={this.props.classes.buttonProgress}
                    />
            }
        </div>;
    }

    renderBadge(store: ButtonStore)
    {
        return <Badge
            classes={{
                badge: this.props.classes.badge,
                colorPrimary: store.isDotBadge ? undefined : this.props.classes.badgeColorPrimary,
                dot: this.props.classes.badgeDot
            }}
            badgeContent={store.badgeContent}
            color="primary"
            variant={store.isDotBadge ? 'dot' : 'standard'}
        >
            {
                this.renderButton(store)
            }
        </Badge>;
    }

    renderButton(store: ButtonStore)
    {
        let button;

        if (store.isIconButton)
        {
            button = <IconButton store={store} />;
        }
        else if (store.isAvatarButton)
        {
            button = <AvatarButton store={store} />;
        }
        else if (store.isChipButton)
        {
            button = <ChipButton store={store} />;
        }
        else if (store.isToggleButton)
        {
            button = <ToggleButton store={store} />;
        }
        else
        {
            button = <LabelButton store={store} />;
        }

        if (store.view)
        {
            button = <>
                    {button}
                    <View viewComponent={store.view} />
                </>;
        }

        if (store.tooltip)
        {
            return <Tooltip
                title={store.tooltip}
                enterDelay={500}
            >
                <div>   {/*  DIV is needed because you can not add a tooltip to a disabled button (Material UI throws an error) */}
                    {button}
                </div>
            </Tooltip>;
        }
        else
        {
            return button;
        }
    }
}

export default withStyles(styles)(Button as any);
