import React, { useContext, useMemo, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import Fields from '../../../Fields/Fields';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import TabBar from '../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import useTypes from '../../../Type/Api/useTypes';
import RelatedEntityView from '../../Shared/RelatedEntityView/RelatedEntityView';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import PortalLayoutEditor from './LayoutEditor/PortalLayoutEditor';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import PortalUserSettingsFormEditor from './UserSettings/PortalUserSettingsFormEditor';
import hasPack from '../../../../../../@Api/Pack/hasPack';
import { Pack } from '../../../../../../@Api/Pack/Pack';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { PortalWhiteLabelSettings } from './WhiteLabelSettings/PortalWhiteLabelSettings';
import { PortalUserList } from './PortalUserList/PortalUserList';

export interface PortalProps
{
    entity: Entity;
    commitContext?: CommitContext;
}

type SelectedTab = 'Users' | 'Pages' | 'Layout' | 'UserSettings' | 'WhiteLabelSettings' | 'InvitationEmailLayout' | 'PasswordResetEmailLayout';

const Portal: React.FC<PortalProps> =
    ({
        entity,
        commitContext,
     }) =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);
        const isAllowedToMutatePortal =
            useComputed(
                () =>
                    currentUserStore.rightProfile.isAllowedToMutate(entity),
                [
                    currentUserStore
                ]);
        const hasAccessToWhitePortalLabels = hasPack(Pack.WhiteLabelPortals);

        const pathToUsers =
            useMemo(
                () =>
                    EntityPath.fromEntity(entity)
                        .joinTo(
                            types.Portal.RelationshipDefinition.Users,
                            false
                        ),
                [
                    entity,
                    types,
                ]);

        const pathToPages =
            useMemo(
                () =>
                    isAllowedToMutatePortal
                        ? EntityPath.fromEntity(entity)
                            .joinTo(
                                types.Portal.RelationshipDefinition.Pages,
                                false
                            )
                        : undefined,
                [
                    entity,
                    types,
                    isAllowedToMutatePortal
                ]);

        const [ selectedTab, setSelectedTab ] = useState<SelectedTab>('Users');

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset
                                    bottom={false}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <TypeAndName
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                                <Fields
                                    entity={entity}
                                    commitContext={commitContext}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Card>
                        <TabBar
                            value={selectedTab}
                        >
                            <Tab
                                value="Users"
                                onClick={setSelectedTab}
                            >
                                {pathToUsers.getName(undefined, false, false)}
                            </Tab>
                            {
                                isAllowedToMutatePortal &&
                                <Tab
                                    value="Pages"
                                    onClick={setSelectedTab}
                                >
                                    {pathToPages.getName(undefined, false, false)}
                                </Tab>
                            }
                            {
                                isAllowedToMutatePortal &&
                                    <Tab
                                        value="Layout"
                                        onClick={setSelectedTab}
                                    >
                                        <LocalizedText
                                            code="Generic.Layout"
                                            value="Layout"
                                        />
                                    </Tab>
                            }
                            {
                                isAllowedToMutatePortal &&
                                    <Tab
                                        value="UserSettings"
                                        onClick={setSelectedTab}
                                    >
                                        <LocalizedText
                                            code="Generic.UserSettings"
                                            value="Gebruikersinstellingen"
                                        />
                                    </Tab>
                            }
                            {
                                isAllowedToMutatePortal && hasAccessToWhitePortalLabels &&
                                <Tab
                                    value="WhiteLabelSettings"
                                    onClick={setSelectedTab}
                                >
                                    <LocalizedText
                                        code="Portal.WhiteLabelSettings"
                                        value="White-label instellingen"
                                    />
                                </Tab>
                            }
                        </TabBar>
                    </Card>
                </Grid>
                {
                    selectedTab === 'Users' &&
                    <Grid
                        item
                        xs={12}
                    >
                        <PortalUserList
                            portal={entity}
                        />
                    </Grid>
                }
                {
                    pathToPages && selectedTab === 'Pages' &&
                    <Grid
                        item
                        xs={12}
                    >
                        <RelatedEntityView
                            entity={entity}
                            path={pathToPages}
                        />
                    </Grid>
                }
                {
                    isAllowedToMutatePortal && selectedTab === 'Layout' &&
                        <Grid
                            item
                            xs={12}
                        >
                            <PortalLayoutEditor
                                entity={entity}
                            />
                        </Grid>
                }
                {
                    isAllowedToMutatePortal && selectedTab === 'UserSettings' &&
                        <Grid
                            item
                            xs={12}
                        >
                            <PortalUserSettingsFormEditor
                                entity={entity}
                            />
                        </Grid>
                }
                {
                    hasAccessToWhitePortalLabels && selectedTab === 'WhiteLabelSettings' &&
                    <Grid
                        item
                        xs={12}
                    >
                        <PortalWhiteLabelSettings
                            entity={entity}
                            commitContext={commitContext}
                        />
                    </Grid>
                }
            </Grid>
        </BaseLayout>;
    };

export default observer(Portal);
