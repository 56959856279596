import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import { runInAction } from 'mobx';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import ExpansionLayout from '../../../../../@Api/Layout/Type/ExpansionLayout';
import ComputationEditor from '../../../Entity/Viewer/Content/Automation/Editor/Computation/ComputationEditor';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import PrimitiveValueType from '../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../DataObject/Model/DataObject';

export interface ExpansionLayoutControlsProps extends LayoutEditorProps<ExpansionLayout>
{

}

const ExpansionLayoutControls: React.FC<ExpansionLayoutControlsProps> =
    props =>
    {
        const context =
            useComputed(
                () =>
                    new FunctionContext(
                        props.parameterDictionary,
                        props.parameterAssignment,
                        props.commitContext
                    ),
                [
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        const setOpenByDefault =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.layout.isExpandedByDefault = value),
                [
                    props.layout
                ]);

        const booleanType =
            useComputed(
                () =>
                    new PrimitiveValueType(DataObject.getTypeById('Boolean')),
                []);

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.IsOpen"
                            value="Is geopend"
                        />
                    }
                >
                    <ComputationEditor
                        context={context}
                        type={booleanType}
                        value={props.layout.isExpandedByDefault}
                        onChange={setOpenByDefault}
                    />
                </Input>
            </InputGroup>
        </CardInset>;
    };

export default observer(ExpansionLayoutControls);
