import { EntityNode } from './EntityNode';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityPathRootNode } from '../../../@Component/Domain/Entity/Path/@Model/Node/EntityPathRootNode';
import { Entity } from './Entity';
import { EntityEvent } from './EntityEvent';
import { EntityCreationMutation } from './EntityCreationMutation';
import { EntityDeletionMutation } from './EntityDeletionMutation';

export class RootNode extends EntityNode
{
    // ------------------- Persistent Properties --------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    equals(node: RootNode): boolean
    {
        return super.equals(node);
    }

    matches(entity: Entity): boolean
    {
        return entity.entityType.isA(this.entityType);
    }

    isAffectedBy(event: EntityEvent): boolean
    {
        return (event instanceof EntityCreationMutation || event instanceof EntityDeletionMutation)
            && event.entity.entityType.isA(this.entityType);
    }

    entityPath(): EntityPath
    {
        return new EntityPath(
            [
                new EntityPathRootNode(this.entityType)
            ]);
    }

    descriptor()
    {
        return Object.assign(
            super.descriptor(),
            {
                type: 'Root',
                alias: this.alias,
                entityTypeId: this.entityType.isStaticType() ? undefined : this.entityType.id,
                entityTypeCode: this.entityType.code
            });
    }

    // ----------------------- Private logic ------------------------
}
