import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../DataObject/Model/DataObject';
import Value from '../../../../../@Api/Automation/Value/Value';
import { AssistantTimePeriodValue } from '../../Model/Value/AssistantTimePeriodValue';
import { DatePeriodValue } from '../../../DataObject/Type/Date/DatePeriod/DatePeriodValue';

export function findAssistantTimePeriodValue(
    value: AssistantTimePeriodValue
): Value<any, any>
{
    const datePeriodValue: DatePeriodValue = {
        type: value.periodType,
        period: value.numberOfPeriods,
    };

    return new PrimitiveValue(
        DataObject.constructFromTypeIdAndValue(
            'DatePeriod',
            datePeriodValue
        )
    );
}
