import Layout from '../Layout';
import LayoutDependencyContext from '../LayoutDependencyContext';
import { DeferredInitializer } from '../../Automation/DeferredInitializer/DeferredInitializer';
import getLayoutFromDescriptor from './getLayoutFromDescriptor';
import InvalidLayout from '../Type/InvalidLayout';

export async function getAndInitializeLayoutFromDescriptor(
    descriptor: any,
    dependencyContext: LayoutDependencyContext
): Promise<Layout>
{
    const deferredInitializer = new DeferredInitializer();
    const dependencyContextWithInitializer =
        new LayoutDependencyContext(
            dependencyContext.parameterDictionary,
            deferredInitializer
        );

    const layout =
        await getLayoutFromDescriptor(
            descriptor,
            dependencyContextWithInitializer
        );

    try
    {
        await deferredInitializer.initialize();
    }
    catch (e)
    {
        console.log(e.message);

        return new InvalidLayout(
            (e as Error).message,
            (e as Error).stack,
            descriptor
        );
    }

    return layout;
}
