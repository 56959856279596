import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useHasTeamsMeetingActivated from './api/useHasTeamsMeetingActivated';
import PrimaryButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import useTypes from '../../../../../Type/Api/useTypes';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import performAction from '../../../../../../../../@Api/Entity/performAction';
import InputGroup from '../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';

export interface TeamsMeetingPlannerProps
{
    appointment: Entity;
    commitContext: CommitContext;
}

export interface MicrosoftGraphOnlineMeeting
{
    graphId: string,
    meetingId: string,
    passCode: string,
    joinLink: string,
    inviteMessage: string
}

export const TeamsMeetingPlanner: React.FC<TeamsMeetingPlannerProps> = observer(
    ({
        appointment,
        commitContext,
    }) =>
    {
        const types = useTypes();
        const [ hasOnlineMeetingsActivated, activation ] = useHasTeamsMeetingActivated();
        const [ isEnabled, setIsEnabled ] = useState<boolean>(true);
        const [ isGeneratingMeeting, setIsGeneratingMeeting ] = useState<boolean>(false);

        const createAndInsertOnlineMeeting = useCallback(
            () =>
            {
                setIsGeneratingMeeting(true);
                setIsEnabled(false);

                performAction<MicrosoftGraphOnlineMeeting>(
                    activation,
                    {
                        code: 'MicrosoftGraph.Action.CreateOnlineMeeting',
                        parameters: {
                            startDate: appointment.getObjectValueByField(types.Activity.Appointment.Field.StartDate, commitContext),
                            endDate: appointment.getObjectValueByField(types.Activity.Appointment.Field.EndDate, commitContext),
                            subject: appointment.getObjectValueByField(types.Activity.Field.Subject, commitContext) || ''
                        }
                    })
                    .then(
                        actionResult =>
                        {
                            const currentDescription =
                                appointment.getObjectValueByField<string>(types.Activity.Appointment.Field.Description, commitContext) || '';

                            const newDescription =
                                currentDescription !== undefined
                                    ? currentDescription + '\n\n' + actionResult.result.inviteMessage
                                    : actionResult.result.inviteMessage;

                            setValueByFieldInEntity(
                                appointment,
                                types.Activity.Appointment.Field.Description,
                                newDescription,
                                commitContext
                            );

                            setIsGeneratingMeeting(false);
                        }
                    );
            },
            [
                setIsEnabled,
                setIsGeneratingMeeting,
                appointment,
                activation,
                types,
                commitContext
            ]
        );
        
        if (hasOnlineMeetingsActivated)
        {
            return <InputGroup>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="OnlineMeeting"
                            value="Online meeting"
                        />
                    }
                >
                    <PrimaryButton
                        label={
                            <LocalizedText
                                code="Generic.Add"
                                value="Toevoegen"
                            />
                        }
                        onClick={createAndInsertOnlineMeeting}
                        disabled={!isEnabled}
                        loading={isGeneratingMeeting}
                    />
                </Input>
            </InputGroup>
        }
        else
        {
            return null;
        }
    });