import React, { useCallback, useEffect, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import AttachmentDialog from '../Dialog/AttachmentDialog';

export interface AttachmentPasterProps
{
    entity: Entity;
}

const AttachmentPaster: React.FC<AttachmentPasterProps> =
    props =>
    {
        const [ files, setFiles] = useState<File[]>(undefined);

        const onReset =
            useCallback(
                () =>
                {
                    setFiles(undefined);
                },
                [
                    setFiles
                ]);

        useEffect(
            () =>
            {
                const onPaste =
                    (event: any) =>
                    {
                        const items = (event.clipboardData  || event.originalEvent.clipboardData).items;
                        for (let i = 0; i < items.length; i++)
                        {
                            const blob = items[i].getAsFile();
                            if (blob)
                            {
                                setFiles([ blob ]);
                                break;
                            }
                        }
                    };

                window.addEventListener('paste', onPaste);

                return () =>
                    window.removeEventListener('paste', onPaste);
            },
            [
                props.entity,
                setFiles
            ]);

        return <>
            {
                files &&
                <AttachmentDialog
                    files={files}
                    entity={props.entity}
                    onReset={onReset}
                />
            }
            {props.children}
        </>;
    };

export default observer(AttachmentPaster);
