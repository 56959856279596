import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import { EmployeeAllocationEditor } from '../../../EmployeeAllocationEditor/EmployeeAllocationEditor';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../Type/Api/useTypes';
import ExpansionPanel from '../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import { buttonInset } from '../../../../../../@Resource/Theme/Theme';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import ExpansionGroup from '../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import { openEntity } from '../../../@Util/openEntity';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { getProjectEmployeeAllocationSpecification } from '../../../EmployeeAllocationEditor/Api/getProjectEmployeeAllocationSpecification';

export interface ProjectAllocationItemProps
{
    project: Entity;
    settingStorageKey?: string;
}

const ProjectAllocationItem: React.FC<ProjectAllocationItemProps> =
    ({
        project,
        settingStorageKey,
     }) =>
    {
        const types = useTypes();
        const name =
            useMemo(
                () => [
                    project.getRelatedEntityByDefinition(
                        true,
                        types.Relationship.RelationshipDefinition.Activities
                    )?.name,
                    project.name
                ]
                    .filter(name => name !== undefined)
                    .join(' - '),
                [
                    project,
                    types,
                ]
            );
        const specification =
            useMemo(
                () =>
                    getProjectEmployeeAllocationSpecification(project),
                [
                    project,
                ]
            );

        return <ExpansionGroup>
            <ExpansionPanel
                id={project.uuid}
                summary={
                    <Header
                        title={name}
                        large
                        inset
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={buttonInset}
                            alignment="center"
                        >
                            <ViewGroupItem>
                                <IconButton
                                    icon="open_in_new"
                                    onClick={() => openEntity(project)}
                                    tooltip=""
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </Header>
                }
                expansion={
                    <CardInset
                        top={false}
                    >
                        <EmployeeAllocationEditor
                            specification={specification}
                            settingStorageKey={settingStorageKey}
                        />
                    </CardInset>
                }
            />
        </ExpansionGroup>;
    };

export default observer(ProjectAllocationItem);
