import React from 'react';
import { observer } from 'mobx-react-lite';
import Attachment from './Attachment/AttachmentMore';
import Offer from './Offer/Offer';
import Fields from '../../../Fields/Fields';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';
import Email from './Email/Email';
import { Invoice } from './Invoice/Invoice';
import Document from './Document/Document';
import Task from './Task/Task';
import Appointment from './Appointment/Appointment';
import TimeRegistration from './TimeRegistration/TimeRegistration';
import { useInitializedEntityForViewer } from '../../../Viewer/Api/useInitializedEntityForViewer';
import ActivitySigningMore from './Signing/ActivitySigningMore';

export interface MoreProps
{
    entity: Entity;
    disableInset?: boolean;
}

const More: React.FC<MoreProps> =
    props =>
    {
        const types = useTypes();
        useInitializedEntityForViewer(props.entity);

        if (props.entity.entityType.isA(types.Attachment.Type))
        {
            return <Attachment
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.Task.Type))
        {
            return <Task
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.Appointment.Type))
        {
            return <Appointment
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.Offer.Type))
        {
            return <Offer
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.Invoice.Type))
        {
            return <Invoice
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.Email.Type))
        {
            return <Email
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.Document.Type))
        {
            return <Document
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.DigitalSigning.Type))
        {
            return <ActivitySigningMore
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.TimeRegistration.Type))
        {
            return <TimeRegistration
                {...props}
            />;
        }
        else
        {
            return <Fields
                entity={props.entity}
            />;
        }
    };

export default observer(More);
