import { ButtonStore } from '../../../Button/ButtonStore';

export class OptionGroup<D>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    options: D[];
    actions: ButtonStore[];

    // ------------------------ Constructor -------------------------

    constructor(options?: D[],
                actions?: ButtonStore[])
    {
        this.options = options ? options : [];
        this.actions = actions ? actions : [];
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
