import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { PortalListLayoutEditorProps } from './PortalListLayoutEditor';
import Layout from '../../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';
import OptionalLayoutEditor from '../../../OptionalEditor/OptionalLayoutEditor';

export interface PortalListLayoutEmptyLayoutEditorProps extends PortalListLayoutEditorProps
{

}

const PortalListLayoutEmptyLayoutEditor: React.FC<PortalListLayoutEmptyLayoutEditorProps> =
    props =>
    {
        const { layout } = props;

        const setEmptyLayout =
            useCallback(
                (emptyLayout: Layout | undefined) =>
                    runInAction(
                        () =>
                            layout.emptyLayout = emptyLayout),
                [
                    layout
                ]);

        return <OptionalLayoutEditor
            {...props}
            layout={props.layout.emptyLayout}
            onChange={setEmptyLayout}
        />;
    };

export default observer(PortalListLayoutEmptyLayoutEditor);
