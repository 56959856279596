import { ProductLineEditorInterface } from '../Hooks/useProductLine';
import React from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../Type/Api/useTypes';
import Input from '../../../../../../../Input/Input';
import { Alignment } from '../../../../../../../../DataObject/Model/DataObject';

export interface ProductLineDiscountPercentageProps
{
    productLine: ProductLineEditorInterface;
    autoCommit: boolean;
    showLabel?: boolean;
    alignment?: Alignment;
}

export const ProductLineDiscountPercentage: React.FC<ProductLineDiscountPercentageProps> = observer(
    ({
         productLine,
         autoCommit,
         showLabel,
         alignment,
     }) =>
    {
        const types = useTypes();

        return <Input
            entity={productLine.productLine}
            field={types.ProductLine.Field.DiscountPercentage}
            labelPosition={showLabel ? 'left' : 'none'}
            alignment={alignment}
            disabled={productLine.isProductAndQuantityAndPriceDisabled}
            doAutocommit={autoCommit}
            commitContext={productLine.commitContext}
        />
    }
);