import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { ButtonStore } from '../ButtonStore';
import * as React from 'react';
import { Chip as MuiChip, Icon, Theme, withStyles } from '@material-ui/core';
import Text from '../../Text/Text';

const styles =
    (theme: Theme) =>
        ({
            icon:
            {
                marginLeft: 12
            },
        });

@observer
class ChipButton extends BaseComponent<ButtonStore>
{
    renderLoader(store: ButtonStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: ButtonStore)
    {
        return <MuiChip
            label={
                store.isCustomLabel
                    ?
                        <Text store={store.labelTextStore} />
                    :
                        store.labelTextStore.label}
            icon={
                store.icon
                    ?
                        <Icon
                            style={{
                                color: store.iconColor,
                                fontSize: store.iconSize
                            }}
                        >
                            {store.icon}
                        </Icon>
                    :
                        undefined}
            variant={
                store.isFinallyOutlined
                    ?
                        'outlined'
                    :
                        'default'}
            color={store.muiColor as any}
            onClick={store.props.onClick ? store.click : undefined}
            onMouseEnter={store.startHovering}
            onMouseLeave={store.stopHovering}
            classes={{
                deleteIcon: this.props.classes.icon,
                label: this.props.classes.label
            }}
            style={{
                boxSizing: 'border-box',
                backgroundColor:
                    store.isFinallyOutlined
                        ?
                            undefined
                        :
                            store.nonMuiColor,
                borderColor:
                    store.isFinallyOutlined
                        ?
                            store.nonMuiColor
                        :
                            undefined,
                color:
                    store.isFinallyOutlined
                        ?
                            store.nonMuiColor
                        :
                            undefined,
                height:
                    store.size === 'small'
                        ?
                            20
                        :
                            undefined,
                ...store.style
            }}
        />;
    }
}

export default withStyles(styles)(ChipButton as any);
