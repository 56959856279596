import { BaseStore, getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { EntityRelationshipDefinition } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { action, computed, observable } from 'mobx';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { CurrentUserStore } from '../../User/CurrentUserStore';
import { RouterStore } from '../../../../@Service/Router/RouterStore';
import { EntityFieldPath } from '../Path/@Model/EntityFieldPath';
import { InputStore } from '../../../Generic/Input/InputStore';
import { TransactionalModel } from '../../../../@Util/TransactionalModelV2';
import { LocalizationStore } from '../../../../@Service/Localization/LocalizationStore';
import { SettingStore } from '../../Setting/SettingStore';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';

export interface EntityConstructorProps
{
    entityType: PropType<EntityConstructorStore, EntityConstructorProps, EntityType>;
    entity?: PropType<EntityConstructorStore, EntityConstructorProps, Entity>;
    sourceRelationshipDefinition?: PropType<EntityConstructorStore, EntityConstructorProps, EntityRelationshipDefinition>;
    checkForDuplicates?: PropType<EntityConstructorStore, EntityConstructorProps, boolean>;
    isEmbedded?: PropType<EntityConstructorStore, EntityConstructorProps, boolean>;
    onClose?: PropType<EntityConstructorStore, EntityConstructorProps, () => void>;
    hideCloseButton?: PropType<EntityConstructorStore, EntityConstructorProps, boolean>;
    isFinalType?: PropType<EntityConstructorStore, EntityConstructorProps, boolean>;
    hideWorkflow?: PropType<EntityConstructorStore, EntityConstructorProps, boolean>;
    fieldPathFilter?: (fieldPath: EntityFieldPath) => boolean;
    commitContext?: CommitContext;
}

const defaultProps: Partial<EntityConstructorProps> =
{
    checkForDuplicates: false,
    isEmbedded: false,
    isFinalType: false
};

export class EntityConstructorStore extends BaseStore<EntityConstructorProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;
    @injectWithQualifier('RouterStore') routerStore: RouterStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('SettingStore') settingStore: SettingStore;

    // ------------------------- Properties -------------------------

    @observable entity: TransactionalModel<Entity>;
    @observable doCheckForDuplicates: boolean;
    @observable onRequestClose: () => void;
    @observable hideCloseButton: boolean;
    @observable onSave?: (entity: Entity) => void;
    @observable onOpen?: (entity: Entity) => void;
    @observable onDelete?: (entity: Entity) => void;
    @observable allowOpen?: boolean;
    @observable onEnterPressed: () => void;
    @observable typeInputStore: InputStore;
    @observable isTouched: boolean;
    commitContext?: CommitContext;

    // ------------------------ Constructor -------------------------

    constructor(props: EntityConstructorProps)
    {
        super(props, defaultProps);

        this.doCheckForDuplicates = getOrCompute(this, this.props.checkForDuplicates);
        this.onRequestClose = getOrCompute(this, this.props.onClose);
        this.hideCloseButton = getOrCompute(this, this.props.hideCloseButton);
        this.commitContext = this.props.commitContext;
        // this.onEnterPressed = getOrCompute(this, this.props.onEnterPressed);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get entityType(): EntityType
    {
        return getOrCompute(this, this.props.entityType);
    }

    @computed
    get originalEntity(): Entity
    {
        return getOrCompute(this, this.props.entity);
    }

    // --------------------------- Stores ---------------------------

    @computed
    get isValid(): boolean
    {
        return this.entity != null && this.entity.isValid; // this.entity.isValidImpl(undefined, undefined, true, true);
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    touchAll()
    {
        this.isTouched = true;
    }

    @action.bound
    setCheckForDuplicates(doCheck: boolean)
    {
        this.doCheckForDuplicates = doCheck;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
