import React from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../Type/Api/useTypes';
import useResults from '../../../../../Selection/Hooks/useResults';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ChildActivityProductEditor from './ChildActivityProductEditor/ChildActivityProductEditor';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';

export interface ChildActivitiesProductEditorProps
{
    entity: Entity;
    path: EntityPath;
    onLoad: (isLoading: boolean) => void;
}

const ChildActivitiesProductEditor: React.FC<ChildActivitiesProductEditorProps> =
    props =>
    {
        const types = useTypes();
        const childActivitiesWithProductLines =
            useResults(
                types.Activity.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath
                                        .joinTo(
                                            types.Activity.RelationshipDefinition.LinkedActivities,
                                            true),
                                    props.entity))
                        .where(
                            cb =>
                                cb.gt(
                                    rootPath.field(types.Activity.Field.NumberOfProductLines),
                                    undefined,
                                    0)),
                [
                    props.entity,
                    types
                ]);

        if (childActivitiesWithProductLines)
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                {
                    childActivitiesWithProductLines.map(
                        childActivity =>
                            <ViewGroupItem
                                key={childActivity.uuid}
                            >
                                <ChildActivityProductEditor
                                    parentActivity={props.entity}
                                    activity={childActivity}
                                    path={props.path}
                                    onLoad={props.onLoad}
                                />
                            </ViewGroupItem>)
                }
            </ViewGroup>;
        }
        else
        {
            return null;
        }
    };

export default observer(ChildActivitiesProductEditor);
