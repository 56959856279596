import React from 'react';
import { observer } from 'mobx-react-lite';
import View from '../View/View';
import { default as ViewModel } from '../View/Model/View';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { SelectionOptionsValue } from '../SelectionOptions/Model/SelectionOptionsValue';

export interface TypeViewProps
{
    entityType: EntityType;
    view?: ViewModel;
    selectionOptionsValue?: SelectionOptionsValue;
    inConfiguration?: boolean;
    configurationPageId?: string;
    configurationResourceId?: string;
    viewId?: string;
    allView?: boolean;
    showHiddenEntities?: boolean;
    hideAddButton?: boolean;
}

const TypeView: React.FC<TypeViewProps> =
    props =>
    {
        return <View
            entityType={props.entityType}
            view={props.view}
            selectionOptionsValue={props.selectionOptionsValue}
            inConfiguration={props.inConfiguration}
            configurationPageId={props.configurationPageId}
            configurationResourceId={props.configurationResourceId}
            viewId={props.viewId}
            showHiddenEntities={props.showHiddenEntities}
            settingStorageKey={`TypeView.EntityTypeId:${props.entityType.id}`}
            hideAddButton={props.hideAddButton}
        />;
    };

export default observer(TypeView);
