import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Trigger from '../../../../../@Api/Automation/Trigger/Trigger';
import { AssistantCronTrigger } from '../../Model/Automation/Trigger/AssistantCronTrigger';
import CronTrigger from '../../../../../@Api/Automation/Trigger/CronTrigger';

export function findAssistantCronTrigger(
    trigger: AssistantCronTrigger,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Trigger
{
    return new CronTrigger(
        undefined,
        trigger.cron
    );
}
