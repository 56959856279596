import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import useIsWorkflowPipeline from '../../../Api/useIsWorkflowPipeline';
import Segment from '../../../../Dataset/Model/Segment';
import useWorkflowStates from '../../../../Workflow/Api/useWorkflowStates';

export default function useBucketResults(segment: Segment,
                                         draggingEntity?: Entity)
{
    const isWorkflowPipeline = useIsWorkflowPipeline(segment);

    return useWorkflowStates(
        isWorkflowPipeline && draggingEntity
            ?
                draggingEntity.entityType
            :
                undefined,
        true);
}
