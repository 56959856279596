import Addressee from '../Model/Addressee';
import getOrganizationDomainName from './getOrganizationDomainName';
import capitalize from 'lodash/capitalize';
import getRootDomainWithoutExtension from '../../../../../../../../@Util/Url/getRootDomainWithoutExtension';

export default function getOrganizationNameFromAddressee(addressee: Addressee): string
{
    const domain = getOrganizationDomainName(addressee.email);

    if (domain)
    {
        return capitalize(getRootDomainWithoutExtension(domain));
    }
}
