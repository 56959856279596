import { Entity } from '../../../../../Model/Implementation/Entity';
import { EntityRelationshipDefinition } from '../../../../../Model/Implementation/EntityRelationshipDefinition';
import { CommitContext } from '../../CommitContext';
import { createTransactionalModel } from '../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { EntityRelationship } from '../../../../../Model/Implementation/EntityRelationship';
import { CommitMutationOptions } from '../../Model/CommitMutationOptions';

export function updateRelationship(
    entity: Entity,
    isParent: boolean,
    relationshipDefinition: EntityRelationshipDefinition,
    relatedEntity?: Entity,
    commitContext: CommitContext = entity.getCommitContext(),
    fromEntity?: Entity,
    isSilently?: boolean,
    relationship?: EntityRelationship,
    options?: CommitMutationOptions
)
{
    return entity.updateRelationship(
        isParent,
        relationshipDefinition,
        commitContext ? relatedEntity : createTransactionalModel(relatedEntity),
        isSilently,
        fromEntity,
        commitContext,
        relationship,
        options
    );
}
