import React from 'react';
import { observer } from 'mobx-react-lite';
import { TabSourceEditorProps } from '../TabSourceEditor';
import DynamicTabSource from '../../../../../../../@Api/Layout/Type/TabBar/Model/DynamicTabSource';

export interface DynamicTabSourceEditorProps extends TabSourceEditorProps<DynamicTabSource>
{

}

const DynamicTabSourceEditor: React.FC<DynamicTabSourceEditorProps> =
    props =>
    {
        return <>Unsupported</>;
    };

export default observer(DynamicTabSourceEditor);
