import React, { useCallback, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import Dialog from '../../../../../../../@Future/Component/Generic/Dialog/Dialog';
import DialogTitle from '../../../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import DialogContent from '../../../../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import DialogActions from '../../../../../../../@Future/Component/Generic/Dialog/Actions/DialogActions';
import SaveButton from '../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import { EntityRelationshipDefinition } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import Selectbox from '../../../../Selectbox/Selectbox';
import { CommitBuilder } from '../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';

export interface LinkRelatedEntityDialogProps
{
    entity: Entity;
    relationshipDefinition: EntityRelationshipDefinition;
    isParent: boolean;
    onClose: (success: boolean) => void;
    relatedEntityType?: EntityType;
}

const LinkRelatedEntityDialog: React.FC<LinkRelatedEntityDialogProps> =
    ({
         entity,
         relationshipDefinition,
         isParent,
         onClose,
         relatedEntityType
     }) =>
    {
        const [ relatedEntity, setRelatedEntity ] = useState<Entity | undefined>(undefined);

        const entityType =
            useMemo(
                () =>
                    relatedEntityType
                        ? relatedEntityType
                        : relationshipDefinition.getEntityType(isParent),
                [
                    relatedEntityType,
                    relationshipDefinition,
                    isParent,
                ]
            );

        const pathToEntity =
            useMemo(
                () =>
                    EntityPath.fromEntityType(entityType)
                        .joinTo(
                            relationshipDefinition,
                            !isParent
                        ),
                [
                    entityType,
                    relationshipDefinition,
                    isParent,
                ]
            );

        const selections =
            useComputed(
                () =>
                {
                    return [
                        new EntitySelectionBuilder(entityType)
                            .if(
                                () => relationshipDefinition.isSingular(!isParent),
                                    sb =>
                                        sb.where(
                                            cb =>
                                                cb.isNotDefined(
                                                    pathToEntity.field(),
                                                    undefined
                                                )
                                        )
                            )
                            .where(
                                cb =>
                                    cb.or(
                                        ob =>
                                            ob
                                                .notRelatedToEntity(
                                                    pathToEntity,
                                                    entity
                                                )
                                                .isNotDefined(
                                                    pathToEntity.field(),
                                                    undefined
                                                )
                                    )
                            )
                            .selection
                    ]
                },
                [
                    entityType,
                    pathToEntity,
                    relationshipDefinition,
                    isParent,
                    entity
                ]);

        const onSelect =
            useCallback(
                (value?: Entity) =>
                    setRelatedEntity(value),
                [
                    setRelatedEntity,
                ]
            );

        const onLink =
            useCallback(
                () =>
                    new CommitBuilder()
                        .relateEntityTo(
                            entity,
                            isParent,
                            relationshipDefinition,
                            relatedEntity
                        )
                        .commit()
                        .then(
                            () =>
                                onClose(true)
                        ),
                [
                    relatedEntity,
                    isParent,
                    relationshipDefinition,
                    entity,
                    onClose,
                ]
            );


        return <Dialog
            onClose={() => onClose(false)}
            open={true}
        >
            <DialogTitle>
                <LocalizedText
                    code="Generic.LinkEntityType"
                    value="${entityTypeName} koppelen"
                    entityTypeName={entityType.getName()}
                />
            </DialogTitle>
            <DialogContent>
                <Selectbox
                    selections={selections}
                    onChange={onSelect}
                    value={relatedEntity}
                    autoFocus
                    disableConstruction
                    clearable
                />
            </DialogContent>
            <DialogActions>
                <SaveButton
                    onClick={onLink}
                    disabled={relatedEntity === undefined}
                />
            </DialogActions>
        </Dialog>;
    };

export default observer(LinkRelatedEntityDialog);