import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import LayoutViewer from '../../../../../../Viewer/LayoutViewer';
import { TableCell } from '@material-ui/core';
import { PortalTableColumn } from '../../../../../../../../../@Api/Layout/Type/PortalTable/Model/PortalTableColumn';
import ComputationLayout from '../../../../../../../../../@Api/Layout/Type/ComputationLayout';
import { PortalTableRowProps } from '../PortalTableRow';

export interface PortalTableCellProps extends PortalTableRowProps
{
    column: PortalTableColumn;
}

export const PortalTableCell: React.FC<PortalTableCellProps> =
    observer(
        ({
             layout,
             column,
             ...otherProps
         }) =>
        {
            const cellLayout =
                useMemo(
                    () =>
                        column.layout === undefined
                            ? new ComputationLayout(column.expression)
                            : column.layout,
                    [
                        column.layout,
                    ]
                );

            return <TableCell>
                <LayoutViewer
                    {...otherProps}
                    layout={cellLayout}
                />
            </TableCell>;
        }
    );
