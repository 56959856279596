import { EntityTypeStore } from '../../../EntityTypeStore';
import { Action, BespokeEntityType } from '../../../BespokeEntityType';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { createTransactionalModel } from '../../../../../../../@Util/TransactionalModelV2';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection';
import { CurrentUserStore } from '../../../../../User/CurrentUserStore';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import constructEntity from '../../../../../../../@Api/Entity/constructEntity';
import { EntityActionResult } from '../../../../../../../@Api/Model/Implementation/EntityActionResult';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export class BespokeActivityCampaign extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Activity.Campaign')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    allowWorkflowInteraction(): boolean
    {
        return false;
    }

    onConstruct(entity: Entity,
                commitContext?: CommitContext): void
    {
        super.onConstruct(entity, commitContext);

        if (!entity.hasRelationshipsByDefinition(
            true,
            this.entityTypeStore.bespoke.types.Relationship.Person.Contact.RelationshipDefinition.Activities,
            commitContext))
        {
            updateRelationship(
                entity,
                true,
                this.entityTypeStore.bespoke.types.Relationship.RelationshipDefinition.Activities,
                loadModuleDirectly(CurrentUserStore).employeeEntity,
                commitContext
            );
        }
    }

    hideFieldPath(entity: Entity,
                  fieldPath: EntityFieldPath,
                  fromFieldPath?: EntityFieldPath,
                  isInConstructor?: boolean,
                  commitContext?: CommitContext): boolean
    {
        return super.hideFieldPath(entity, fieldPath, fromFieldPath, isInConstructor, commitContext)
            || (entity.isNew()
                && fieldPath.relationshipDefinition === this.types.Activity.Campaign.RelationshipDefinition.Phase
                && !fieldPath.isParentRelationship)
            || (entity.isNew()
                && fieldPath.relationshipDefinition === this.types.Relationship.RelationshipDefinition.Activities
                && fieldPath.isParentRelationship)
            || (entity.isNew()
                && fieldPath.relationshipDefinition === this.types.Relationship.Person.Contact.RelationshipDefinition.Activities
                && fieldPath.isParentRelationship);
    }

    getActions(entity: Entity): Action[]
    {
        return [
            ...super.getActions(entity),
            {
                id: 'Activity.Campaign.Duplicate',
                code: 'Activity.Campaign.Duplicate',
                name: localizeText('Generic.Duplicate', 'Dupliceren'),
                perform:
                    campaign =>
                    {
                        const newCampaign =
                            createTransactionalModel(
                                new Entity(this.types.Activity.Campaign.Type)
                                    .initialize());

                        [
                            this.types.Activity.Field.Subject,
                            this.types.Activity.Campaign.Field.SenderEmailAddress,
                            this.types.Activity.Campaign.Field.RootType,
                            this.types.Activity.Campaign.Field.UseAccountManagerAsSender,
                            this.types.Activity.Campaign.Field.SelectionFilter,
                            this.types.Activity.Campaign.Field.ManualSelection,
                            this.types.Activity.Campaign.Field.EmailSubject,
                            this.types.Activity.Campaign.Field.Template,
                            this.types.Activity.Campaign.Field.RelationshipPath,
                            this.types.Activity.Campaign.Field.Font
                        ].forEach(
                            field =>
                                newCampaign.setValueByField(
                                    field,
                                    campaign.getObjectValueByField(field)));

                        [
                            this.types.Relationship.RelationshipDefinition.Activities,
                            this.types.Relationship.Person.Contact.RelationshipDefinition.Activities
                        ].forEach(
                            relationshipDefinition =>
                                newCampaign.updateRelationship(
                                    true,
                                    relationshipDefinition,
                                    createTransactionalModel(
                                        newCampaign.getRelatedEntityByDefinition(
                                            true,
                                            relationshipDefinition))));

                        [
                            this.types.Activity.Campaign.RelationshipDefinition.EmailingCategory
                        ].forEach(
                            relationshipDefinition =>
                                newCampaign.updateRelationship(
                                    false,
                                    relationshipDefinition,
                                    createTransactionalModel(
                                        newCampaign.getRelatedEntityByDefinition(
                                            false,
                                            relationshipDefinition))));

                        constructEntity(
                            newCampaign.entityType,
                            undefined,
                            newCampaign);

                        return Promise.resolve(new EntityActionResult());
                    }
            },
            ...loadModuleDirectly(CurrentUserStore).isAdministrator
                ?
                    [
                        {
                            id: 'Activity.Campaign.UpdateEmailStatus',
                            code: 'Activity.Campaign.UpdateEmailStatus',
                            name: `${localizeText('CampaignEmailUpdate', 'Campagne e-mails status bijwerken')}`
                        }
                    ]
                :
                    []
        ];
    }

    // ----------------------- Private logic ------------------------
}
