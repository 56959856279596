import { useEffect, useState } from 'react';
import uuid from '../../../@Util/Id/uuid';

const delayTimeoutById = new Map<string, any>();

export default function useDelayedCallback<T>(
    value: T,
    onChange: (value: T) => void,
    timeout = 800
)
{
    const [ id ] = useState(() => uuid());

    useEffect(
        () =>
        {
            if (delayTimeoutById.has(id))
            {
                clearTimeout(delayTimeoutById.get(id));
            }

            delayTimeoutById.set(
                id,
                setTimeout(
                    () =>
                    {
                        delayTimeoutById.delete(id);
                        onChange(value);
                    },
                    timeout
                )
            );
        },
        [
            id,
            onChange,
            value,
            timeout,
        ]
    );
}
