import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { TotalRow } from '../TotalRow/TotalRow';
import Column from '../../Model/Specification/Column';
import useAggregateResult from '../../../Selection/Hooks/useAggregateResult';
import useTypes from '../../../Type/Api/useTypes';
import FilterContext from '../../../Dataset/Segment/FilterContext/FilterContext';
import View from '../../Model/View';
import { Aggregate } from '../../../../DataObject/Model/Aggregate';

export interface NonGroupedListTotalRowProps
{
    view: View;
    isInEditMode?: boolean;
}

export const NonGroupedListTotalRow: React.FC<NonGroupedListTotalRowProps> =
    observer(
        ({
            view,
            isInEditMode,
         }) =>
        {
            const list = view.specification.list;
            const visibleColumns =
                useComputed(
                    () =>
                        list.columns.filter(
                            column =>
                                column.shouldShowColumn(
                                    view,
                                    isInEditMode
                                )
                        ),
                    [list, view, isInEditMode]
                );
            const visibleAggregatedColumns =
                useComputed(
                    () =>
                        visibleColumns
                            .filter(
                                column =>
                                    column.aggregate !== undefined
                            ),
                    [visibleColumns]
                );
            
            if (visibleAggregatedColumns.length > 0)
            {
                return <InnerListTotalRow 
                    view={view}
                    visibleAggregatedColumns={visibleAggregatedColumns}
                />
            }
            else
            {
                return null;
            }
        }
    );

interface InnerListTotalRowProps extends NonGroupedListTotalRowProps
{
    visibleAggregatedColumns: Column[];
}

const InnerListTotalRow: React.FC<InnerListTotalRowProps> =
    observer(
        ({
            view,
            visibleAggregatedColumns,
         }) =>
        {
            const types = useTypes();
            const list = view.specification.list;
            const filter = useContext(FilterContext);
            const columnFilters =
                useComputed(
                    () =>
                        list.columns
                            .filter(
                                column =>
                                    column.filter !== undefined)
                            .map(
                                column =>
                                    column.filter),
                    [
                        list
                    ]);
            const result =
                useAggregateResult(
                    view.entityType,
                    (builder, rootPath) =>
                    {
                        builder.aggregateOn(
                            rootPath.field(types.Entity.Field.Id),
                            undefined,
                            Aggregate.Count
                        );

                        visibleAggregatedColumns.forEach(
                            column =>
                                builder.aggregateOn(
                                    column.fieldPath,
                                    undefined,
                                    column.aggregate
                                )
                        );

                        if (filter && filter.isValid())
                        {
                            builder.where(
                                cb =>
                                    cb.filter(
                                        filter,
                                        {
                                            parameter: view.parameter,
                                        }
                                    )
                            );
                        }
                        else if (view.filter && view.filter.isValid())
                        {
                            builder
                                .where(
                                    cb =>
                                        cb.filter(
                                            view.filter,
                                            {
                                                parameter: view.parameter,
                                            }
                                        )
                                );
                        }

                        for (const filter of columnFilters)
                        {
                            builder.where(
                                cb =>
                                    cb.filter(
                                        filter,
                                        {
                                            parameter: view.parameter,
                                        }
                                    )
                            );
                        }
                    },
                    [
                        list,
                        view,
                        visibleAggregatedColumns,
                        types,
                        filter,
                        columnFilters,
                    ]
                );

            return <TotalRow
                view={view}
                columns={view.specification.list!.columns}
                result={result}
            />;
        }
    );
