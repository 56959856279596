import { observable, runInAction } from 'mobx';
import Predicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';

const currentCopiedFilter = observable.box();

export function copyFilterToClipboard(
    type: EntityType,
    filter: Predicate
)
{
    runInAction(
        () =>
            currentCopiedFilter.set({
                type: type,
                filter: filter
            })
    );
}

export function getCopiedFilterFromClipboard()
{
    return currentCopiedFilter.get();
}

export function hasCopiedFilterInClipboard()
{
    const filterFromClipboard = getCopiedFilterFromClipboard();

    return filterFromClipboard
        && filterFromClipboard.type
        && filterFromClipboard.filter;
}
