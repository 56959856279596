import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import Switch from '../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import { Field } from '../../Model/Field';
import { runInAction } from 'mobx';
import useSetting from '../../../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../../../Setting/SettingStore';
import { Setting } from '../../../../../../../../@Api/Settings/Setting';
import resolveInputFromFieldPath from '../../../../../../Multiplayer/Model/Input/Api/resolveInputFromFieldPath';

export interface FieldItemProps
{
    entityType: EntityType;
    field: Field;
}

const IsVisibleDuringConstructionEditor: React.FC<FieldItemProps> =
    props =>
    {
        const [ visibleFieldsDuringConstruction, setVisibleFieldsDuringConstructionFields ] = useSetting<any>(SettingSource.Organization, Setting.Metadata.VisibleFieldsDuringConstruction);
        const input =
            useComputed(
                () =>
                    props.field.fieldPath &&
                    resolveInputFromFieldPath(props.field.fieldPath),
                [
                    props.field
                ]);
        const isVisibleDuringConstruction =
            useComputed(
                () =>
                {
                    if (props.field.isEditable)
                    {
                        return props.field.isVisibleDuringConstruction;
                    }
                    else
                    {
                        if (visibleFieldsDuringConstruction && input && visibleFieldsDuringConstruction[input.id()])
                        {
                            return visibleFieldsDuringConstruction[input.id()] as boolean;
                        }
                        else
                        {
                            return props.field.isVisibleDuringConstruction;
                        }
                    }
                },
                [
                    props.field,
                    visibleFieldsDuringConstruction,
                    input,
                ]);
        const onChange =
            useCallback(
                (value: boolean) =>
                    runInAction(
                        () =>
                        {
                            if (props.field.isEditable)
                            {
                                props.field.isVisibleDuringConstruction = value;
                            }
                            else
                            {
                                const newVisibleFieldsDuringConstruction = {
                                    ...visibleFieldsDuringConstruction
                                };

                                if (value)
                                {
                                    newVisibleFieldsDuringConstruction[input.id()] = value;
                                }
                                else
                                {
                                    delete newVisibleFieldsDuringConstruction[input.id()];
                                }

                                return setVisibleFieldsDuringConstructionFields(newVisibleFieldsDuringConstruction);
                            }
                        }
                    ),
                [
                    props.field,
                    visibleFieldsDuringConstruction,
                    setVisibleFieldsDuringConstructionFields,
                ]);

        return <Switch
            checked={isVisibleDuringConstruction}
            onToggle={onChange}
            disabled={!(props.field.isEditable || !props.field.isVisibleDuringConstruction)}
        />;
    };

export default observer(IsVisibleDuringConstructionEditor);
