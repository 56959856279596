import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import { Field } from '../../Model/Field';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Cardinality, EntityRelationshipDefinition, ReferentialAction, Type } from '../../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import uuid from '../../../../../../../../@Util/Id/uuid';
import { LanguageEntry } from '../../../../../../../../@Api/Model/Implementation/LanguageEntry';
import LocalizerContext from '../../../../../../../../@Service/Localization/LocalizerContext';
import { commit, createTransactionalModel, isDirty, rollback } from '../../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { EntityRelationshipDefinitionApi } from '../../../../../../../../@Api/Api/EntityRelationshipDefinitionApi';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import { runInAction } from 'mobx';
import { EntityPath } from '../../../../../../Entity/Path/@Model/EntityPath';
import { EntityFieldPath } from '../../../../../../Entity/Path/@Model/EntityFieldPath';
import SaveButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import CancelButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../../Entity/Type/Api/useTypes';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';

export interface FieldItemProps
{
    entityType: EntityType;
    field: Field;
    onAddField: (fieldPath: EntityFieldPath) => void;
    onCancel: (field: Field) => void;
}

const Options: React.FC<FieldItemProps> =
    props =>
    {
        const types = useTypes();
        const localizerStore = useContext(LocalizerContext);
        const { onAddField } = props;

        const cancel =
            useCallback(
                () =>
                {
                    rollback(props.field);
                    props.onCancel(props.field);
                },
                [
                    props.field,
                    props.onCancel
                ]);

        const save =
            useCallback(
                () =>
                {
                    if (props.field.dataType)
                    {
                        const isNew = props.field.fieldPath === undefined;

                        let relationshipDefinition: EntityRelationshipDefinition;
                        const isParent = props.field.isParent;
                        let entity: Entity;

                        if (props.field.fieldPath)
                        {
                            relationshipDefinition =
                                createTransactionalModel(
                                    props.field.fieldPath.relationshipDefinition);

                            relationshipDefinition.cardinality = props.field.cardinality;
                            relationshipDefinition.setEntityType(isParent, props.field.dataType);
                            relationshipDefinition.setVisibleDuringConstruction(isParent, props.field.isVisibleDuringConstruction);
                            relationshipDefinition.setVisibleInDetails(isParent, props.field.isVisibleInDetails);
                            relationshipDefinition.setVisibleAsTab(isParent, props.field.isVisibleAsTab);

                            entity = relationshipDefinition.entity;
                        }
                        else
                        {
                            const nameLanguageEntry = new LanguageEntry();
                            const inverseNameLanguageEntry = new LanguageEntry();

                            relationshipDefinition =
                                createTransactionalModel(
                                    new EntityRelationshipDefinition(
                                        uuid(),
                                        props.entityType,
                                        props.field.dataType,
                                        props.field.cardinality === Cardinality.OneToMany
                                            ||
                                        props.field.cardinality === Cardinality.OneToOne
                                            ?
                                                Type.Ownership
                                            :
                                                Type.Reference,
                                        props.field.cardinality,
                                        false,
                                        false,
                                        isParent ? false : props.field.isVisibleInDetails,
                                        isParent ? props.field.isVisibleInDetails : false,
                                        isParent ? false : props.field.isVisibleAsTab,
                                        isParent ? props.field.isVisibleAsTab : false,
                                        true,
                                        true,
                                        undefined,
                                        undefined,
                                        ReferentialAction.None,
                                        ReferentialAction.None,
                                        nameLanguageEntry,
                                        inverseNameLanguageEntry));

                            relationshipDefinition.setVisibleDuringConstruction(isParent, props.field.isVisibleDuringConstruction);
                            relationshipDefinition.setVisibleDuringConstruction(!isParent, true);

                            entity =
                                createTransactionalModel(
                                    new Entity(types.EntityRelationshipDefinition.Type)
                                        .initialize());

                            relationshipDefinition.entity = entity;
                        }

                        if (isParent)
                        {
                            entity.setValueByField(
                                types.EntityRelationshipDefinition.Field.LocalizedChildName,
                                props.field.inverseName);

                            entity.setValueByField(
                                types.EntityRelationshipDefinition.Field.LocalizedParentName,
                                props.field.name);
                        }
                        else
                        {
                            entity.setValueByField(
                                types.EntityRelationshipDefinition.Field.LocalizedChildName,
                                props.field.name);

                            entity.setValueByField(
                                types.EntityRelationshipDefinition.Field.LocalizedParentName,
                                props.field.inverseName);
                        }

                        entity.updateRelationship(
                            true,
                            types.Pack.RelationshipDefinition.Entities,
                            props.field.pack);

                        return new EntityRelationshipDefinitionApi()
                            .commit(relationshipDefinition, true)
                            .then(
                                () =>
                                    runInAction(
                                        () =>
                                            props.field.fieldPath =
                                                EntityPath.fromEntityType(props.entityType)
                                                    .joinTo(
                                                        relationshipDefinition,
                                                        isParent
                                                    )
                                                    .field()))
                            .then(
                                () =>
                                {
                                    if (isNew)
                                    {
                                        onAddField(props.field.fieldPath);
                                    }

                                    commit(props.field);
                                });
                    }
                },
                [
                    props.entityType,
                    props.field,
                    localizerStore,
                    onAddField,
                    types
                ]);

        const deleteField =
            useCallback(
                () =>
                {
                    if (window.confirm(
                        localizeText(
                            'ConnectionsEditor.DeleteConnection',
                            'Let op: als u deze koppeling verwijderd, worden alle ingevulde koppelingen over de gehele historie óók verwijderd.')))
                    {
                        if (props.field.fieldPath.isRelationship)
                        {
                            return new EntityRelationshipDefinitionApi()
                                .delete(createTransactionalModel(props.field.fieldPath.relationshipDefinition));
                        }
                    }
                },
                [
                    props.field
                ]);

        if (props.field.isEditable)
        {
            return <ViewGroup
                orientation="horizontal"
                spacing={10}
                alignment="center"
            >
                <ViewGroupItem
                    ratio={1}
                />
                {
                    (isDirty(props.field) || !props.field.fieldPath) &&
                        <ViewGroupItem>
                            <CancelButton
                                onClick={cancel}
                            />
                        </ViewGroupItem>
                }
                {
                    (!props.field.fieldPath || isDirty(props.field)) &&
                        <ViewGroupItem>
                            <SaveButton
                                onClick={save}
                            />
                        </ViewGroupItem>
                }
                {
                    props.field.fieldPath &&
                        <ViewGroupItem>
                            <MenuButton>
                                <Menu>
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Generic.Delete"
                                                value="Verwijderen"
                                            />
                                        }
                                        onClick={deleteField}
                                    >
                                        <LocalizedText
                                            code="Generic.Delete"
                                            value="Verwijderen"
                                        />
                                    </Item>
                                </Menu>
                            </MenuButton>
                        </ViewGroupItem>
                }
            </ViewGroup>;
        }
        else
        {
            return null;
        }
    };

export default observer(Options);
