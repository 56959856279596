import useCount from '../../../../../Selection/Hooks/useCount';
import { useMemo } from 'react';
import useTypes from '../../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';

export default function useCampaignEmailStateCount(campaign: Entity, states?: string[])
{
    const types = useTypes();
    const emailRootPath =
        useMemo(
            () =>
                EntityPath.fromEntityType(types.Activity.Email.Type),
            [
                types
            ]);

    const emailToCampaignPath =
        useMemo(
            () =>
                emailRootPath
                    .joinTo(
                        types.Activity.Campaign.RelationshipDefinition.Email,
                        true),
            [
                emailRootPath,
                types
            ]);

    const emailPhasePath =
        useMemo(
            () =>
                emailRootPath
                    .joinTo(
                        types.Activity.Email.RelationshipDefinition.Phase,
                        false),
            [
                emailRootPath,
                types
            ]);

    return useCount(
        emailToCampaignPath && emailPhasePath ? types.Activity.Email.Type : undefined,
        (builder) =>
            builder
                .where(
                    cb =>
                        cb.relatedToEntity(emailToCampaignPath, campaign))
                .where(
                    cb =>
                        states
                        ?
                            cb.or(
                                ob =>
                                    states.forEach(
                                        state =>
                                            ob.eq(emailPhasePath.field(types.Datastore.Field.Code),
                                                null,
                                                state)))
                        :
                            cb),
        [
            types,
            emailToCampaignPath,
            emailPhasePath,
            campaign
        ]);
}
