import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { runInAction } from 'mobx';
import ConditionalEditor from './Conditional/ConditionalEditor';
import ActionEditor, { ActionEditorProps } from '../../ActionEditor';
import ConditionalAction from '../../../../../../../../../../@Api/Automation/Function/Action/ConditionalAction';
import Action from '../../../../../../../../../../@Api/Automation/Function/Action/Action';
import CardInset from '../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import IconButton from '../../../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import ConditionalInAction from '../../../../../../../../../../@Api/Automation/Function/Action/ConditionalInAction';
import CompositePredicate from '../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../../../../../DataObject/Model/LogicalOperator';
import ComparisonPredicate from '../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import { Comparator } from '../../../../../../../../DataObject/Model/Comparator';
import CompositeAction from '../../../../../../../../../../@Api/Automation/Function/Action/CompositeAction';
import styles from './ConditionalActionEditor.module.scss';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface ConditionalActionEditorProps extends ActionEditorProps<ConditionalAction>
{

}

const ConditionalActionEditor: React.FC<ConditionalActionEditorProps> =
    props =>
    {
        const setOtherwise =
            useCallback(
                (action: Action<any, any>) =>
                    runInAction(
                        () =>
                        {
                            props.action.otherwise = action;
                        }),
                [
                    props.action
                ]);

        const addConditional =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            props.action.conditionals.push(
                                new ConditionalInAction(
                                    new CompositePredicate(
                                        LogicalOperator.And,
                                        [
                                            new ComparisonPredicate(
                                                undefined,
                                                Comparator.Equals,
                                                undefined)
                                        ]),
                                    new CompositeAction([])))),
                [
                    props.action
                ]);

        return <CardInset>
            <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                {
                    props.action.conditionals.map(
                        conditional =>
                            <ViewGroupItem
                                key={conditional.id}
                            >
                                <ConditionalEditor
                                    {...props}
                                    conditional={conditional}
                                />
                            </ViewGroupItem>)
                }
                <ViewGroupItem>
                    <IconButton
                        icon="add"
                        tooltip={
                            <LocalizedText
                                code="AutomationEditor.AddConditionalAction"
                                value="Conditionele actie toevoegen"
                            />
                        }
                        onClick={addConditional}
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                    >
                        <ViewGroupItem
                            className={styles.label}
                        >
                            <LocalizedText
                                code="Generic.Otherwise"
                                value="Anders"
                            />
                        </ViewGroupItem>
                        <ViewGroupItem
                            ratio={1}
                        >
                            <ActionEditor
                                action={props.action.otherwise}
                                onChange={setOtherwise}
                                context={props.context}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
            </ViewGroup>
        </CardInset>;
    };

export default observer(ConditionalActionEditor);
