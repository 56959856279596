import React, { useCallback } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import Input from '../../../Input/Input';
import useTypes from '../../../Type/Api/useTypes';
import useResults from '../../../Selection/Hooks/useResults';
import { setValueByFieldInEntity } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import Details from '../../Shared/Details/Details';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';

export interface TimeRegistrationActivityProps
{
    entity: Entity;
    commitContext?: CommitContext;
}

const TimeRegistrationActivity: React.FC<TimeRegistrationActivityProps> =
    ({
        entity,
        commitContext
     }) =>
    {
        const types = useTypes();

        const defaultTimeRegistrationActivities =
            useResults(
                entity.entityType,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.TimeRegistrationActivity.Field.IsDefault),
                                    undefined,
                                    true
                                )
                        ),
                [
                    entity,
                    types
                ]
            )

        const updateIsDefault =
            useCallback(
                () =>
                    {
                        defaultTimeRegistrationActivities?.forEach(
                            timeRegistrationActitivy =>
                                {
                                    if (entity.getObjectValueByField(types.TimeRegistrationActivity.Field.IsDefault) === true
                                        && entity.id !== timeRegistrationActitivy.id
                                    )
                                    {
                                        setValueByFieldInEntity(
                                            timeRegistrationActitivy,
                                            types.TimeRegistrationActivity.Field.IsDefault,
                                            false,
                                            commitContext
                                        );
                                    }
                                }
                        )
                    },
                    [
                        defaultTimeRegistrationActivities,
                        entity,
                        types,
                        commitContext
                    ]
            );

        const isFieldHidden =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    fieldPath.field === types.TimeRegistrationActivity.Field.IsDefault,
                [types]
            );

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <TypeAndName
                                                entity={entity}
                                            />
                                            <Input
                                                entity={entity}
                                                field={types.TimeRegistrationActivity.Field.IsDefault}
                                                labelPosition="left"
                                                commitContext={commitContext}
                                                onChange={updateIsDefault}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Details
                                entity={entity}
                                hidden={isFieldHidden}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(TimeRegistrationActivity);
