import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationBox from '../../Box/ComputationBox';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../../../../../DataObject/Model/DataObject';
import AiAudioTranscriptionComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/AiAudioTranscriptionComputation';

export interface AiAudioTranscriptionComputationEditorProps extends ComputationEditorProps<ValueType<any>, AiAudioTranscriptionComputation>
{

}

const AiAudioTranscriptionComputationEditor: React.FC<AiAudioTranscriptionComputationEditorProps> =
    props =>
    {
        const setValue =
            useCallback(
                (file?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.audio = file
                    ),
                [
                    props.value
                ]
            );
        const type =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        DataObject.getTypeById('File')
                    ),
                []
            );

        return <Input
            label={
                <LocalizedText
                    code="Computation.AiAudioTranscription.TranscribeFromFile"
                    value="Transcriptie uit bestand"
                />
            }
            labelPosition="left"
        >
            <ComputationBox>
                <ComputationEditor
                    context={props.context}
                    value={props.value.audio}
                    onChange={setValue}
                    type={type}
                />
            </ComputationBox>
        </Input>;
    };

export default observer(AiAudioTranscriptionComputationEditor);
