import Dependency from '../../../../Automation/Parameter/Dependency';
import Validation from '../../../../Automation/Validation/Validation';
import LayoutDependencyContext from '../../../LayoutDependencyContext';
import Computation from '../../../../Automation/Function/Computation/Computation';
import getComputationFromDescriptor from '../../../../Automation/Api/getComputationFromDescriptor';
import TableDimensionSection from './TableDimensionSection';
import { observable } from 'mobx';
import CollectionType from '../../../../Automation/Value/Type/CollectionType';
import Parameter from '../../../../Automation/Parameter/Parameter';
import FunctionContext from '../../../../Automation/Function/FunctionContext';
import TableDimensionInstance from './TableDimensionInstance';
import ParameterDictionary from '../../../../Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../../../Automation/Parameter/ParameterAssignment';
import safelyApplyFunction from '../../../../Automation/Api/safelyApplyFunction';
import Value from '../../../../Automation/Value/Value';
import CollectionValue from '../../../../Automation/Value/CollectionValue';
import TableLayout from '../TableLayout';
import initializeDependencies from '../../../../Automation/Api/initializeDependencies';
import LayoutAction from '../../../Action/LayoutAction';
import getLayoutActionFromDescriptor from '../../../Action/Api/getLayoutActionFromDescriptor';
import Layout from '../../../Layout';
import getLayoutFromDescriptor from '../../../Api/getLayoutFromDescriptor';

export default class CollectionTableDimensionSection extends TableDimensionSection
{
    // ------------------------- Properties -------------------------

    @observable.ref collection: Computation<any, any>;
    @observable.ref parameter: Parameter<any>;
    @observable.ref emptyLayout: Layout;

    // ------------------------ Constructor -------------------------

    constructor(
        id: string,
        size: Computation<any, any>,
        onClick: LayoutAction | undefined,
        collection: Computation<any, any>,
        parameter: Parameter<any>,
        emptyLayout?: Layout
    )
    {
        super(id, size, onClick);

        this.collection = collection;
        this.parameter = parameter;
        this.emptyLayout = emptyLayout;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getParameters(): Parameter<any>[]
    {
        if (this.parameter)
        {
            return [
                this.parameter
            ];
        }
        else
        {
            return [];
        }
    }

    async computeInstanceValues(
        context: FunctionContext,
        layout: TableLayout
    ): Promise<CollectionValue<any>>
    {
        return (await safelyApplyFunction(this.collection, context)) as CollectionValue<any>;
    }

    async computeInstances(
        context: FunctionContext,
        layout: TableLayout,
        collection: Value<any, any>[]
    ): Promise<TableDimensionInstance[]>
    {
        const parameters = new ParameterDictionary(this.getParameters());

        if (collection.length === 0
            && this.emptyLayout !== undefined)
        {
            return [
                this.computeEmptyInstance(parameters)
            ];
        }
        else
        {
            const cellDependencies =
                (layout.cellsBySectionId.get(this.id) || [])
                    .map(
                        cell =>
                            cell.getDependencies()
                    )
                    .reduce(
                        (a, b) =>
                            a.concat(b),
                        []
                    );

            await initializeDependencies(
                this.parameter,
                collection,
                cellDependencies
            );

            return Promise.all(
                collection.map(
                    (element, idx) =>
                        this.computeInstance(
                            context,
                            parameters,
                            element,
                            idx
                        )
                )
            );
        }
    }

    computeEmptyInstance(parameters: ParameterDictionary)
    {
        return new TableDimensionInstance(
            'Empty',
            this,
            1,
            parameters,
            new ParameterAssignment(),
            this.emptyLayout
        );
    }

    async computeInstance(
        context: FunctionContext,
        parameters: ParameterDictionary,
        element: Value<any, any>,
        idx: number
    )
    {
        const id = `${this.id}.${idx}.${element.getId()}`;
        const parameterAssignment =
            new ParameterAssignment()
                .setValue(
                    this.parameter,
                    element
                );
        const size = await this.computeSize(context);

        return new TableDimensionInstance(
            id,
            this,
            size,
            parameters,
            parameterAssignment
        );
    }

    validate(): Validation[]
    {
        return [
            ...super.validate(),
            ...this.collection.validate(),
            ...this.emptyLayout ? this.emptyLayout.validate() : []
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.size.getDependencies(),
            ...(this.onClick?.getDependencies() ?? []),
            ...this.collection.getDependencies(),
            ...(this.emptyLayout?.getDependencies() ?? [])
        ].filter(
            dependency =>
                dependency.parameter !== this.parameter
        );
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Collection',
            collection: this.collection.toDescriptor(),
            emptyLayout: this.emptyLayout?.toDescriptor()
        };
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: LayoutDependencyContext
    )
    {
        const id = descriptor.id;
        const collection =
            await getComputationFromDescriptor(
                descriptor.collection,
                dependencyContext
            );
        const parameter = CollectionTableDimensionSection.getParameterFromCollection(id, collection);
        const parametersWithParameter = dependencyContext.parameterDictionary.getNewDictionaryWithParameter(parameter);
        const dependencyContextWithParameter =
            dependencyContext.withParameterDictionary(
                parametersWithParameter
            );
        const size =
            await getComputationFromDescriptor(
                descriptor.size,
                dependencyContextWithParameter
            );
        const onClick =
            descriptor.onClick
                ?
                    await getLayoutActionFromDescriptor(
                        descriptor.onClick,
                        dependencyContextWithParameter
                    )
                :
                    undefined;
        const emptyLayout =
            descriptor.emptyLayout
                ?
                    await getLayoutFromDescriptor(
                        descriptor.emptyLayout,
                        dependencyContext
                    )
                :
                    undefined;

        return new CollectionTableDimensionSection(
            id,
            size,
            onClick,
            collection,
            parameter,
            emptyLayout
        );
    }

    static getParameterFromCollection(
        id: string,
        collection: Computation<any, any>
    )
    {
        const collectionType = collection.getType();

        if (collectionType instanceof CollectionType)
        {
            return new Parameter(
                id,
                collectionType.type,
                true,
                collectionType.type.getName()
            );
        }
        else
        {
            throw new Error('Expected collection type');
        }
    }

    // ----------------------- Private logic ------------------------
}
