import { ViewStackStore } from '../ViewStack/ViewStackStore';
import { action, computed, observable } from 'mobx';
import { ViewComponent } from '../ViewStack/Model/ViewComponent';

export class DrawerStore extends ViewStackStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable rightSideViewComponent: ViewComponent;
    @observable rightSideViewIsVisible: boolean = true;
    @observable isLeft: boolean = true;

    // ------------------------ Constructor -------------------------

    constructor(isLeft: boolean)
    {
        super();

        this.isLeft = isLeft;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get width(): number | string
    {
        return this.currentView && this.currentView.props && this.currentView.props.width
            ?
                this.currentView.props.width
            :
                750;
    }

    @computed
    get hasRightSideView()
    {
        return !!this.rightSideViewComponent;
    }

    @computed
    get rightSideViewWidth(): number | string
    {
        return this.rightSideViewComponent && this.rightSideViewComponent.props && this.rightSideViewComponent.props.width
            ?
                this.rightSideViewComponent.props.width
            :
                300;
    }

    @computed
    get background(): string
    {
        return this.currentView && this.currentView.props && this.currentView.props.backgroundUrl
            ?
                this.currentView.props.backgroundUrl
            :
                undefined;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    clear()
    {
        this.rightSideViewComponent = undefined;
        this.rightSideViewIsVisible = true;

        super.clear();
    }

    @action.bound
    setRightSideViewComponent(view: ViewComponent)
    {
        this.rightSideViewComponent = view;
    }

    @action.bound
    hideRightSideView()
    {
        this.rightSideViewIsVisible = false;
    }

    @action.bound
    showRightSideView()
    {
        this.rightSideViewIsVisible = false;
    }

    @action.bound
    toggleRightSideView()
    {
        this.rightSideViewIsVisible = !this.rightSideViewIsVisible;
    }

    @action.bound
    setRightSideViewVisibility(isVisible: boolean)
    {
        this.rightSideViewIsVisible = isVisible;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
