import { DailyRecurrencePattern } from '../../../Model/RecurrencePattern';
import { Frequency, Options } from 'rrule';

export function getDailyRecurrencePatternAsRRule(
    pattern: DailyRecurrencePattern
): Partial<Options>
{
    return {
        freq: Frequency.DAILY,
        interval: pattern.interval ?? 1,
    };
}
