import Widget from '../Model/Widget';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { groupBy } from '../../../../../@Util/MapUtils/groupBy';
import getEntitiesByUuids from '../../../../../@Api/Entity/Bespoke/getEntitiesByUuids';
import { Disposer } from '../../../../../@Util/Disposable/Disposer';
import { EntitySelectionBuilder } from "../../Selection/Builder/EntitySelectionBuilder";
import { EntityPath } from "../../Path/@Model/EntityPath";

export default async function collectAndInitializeByEntityIds<T extends Widget>(
    widgets: T[],
    collectFunction: (widget: T) => string | undefined,
    setFunction: (widget: T, entity: Entity) => Promise<any>,
    entityType: EntityType,
    callback?: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void
): Promise<Disposer>
{
    const widgetsByEntityId =
        groupBy(
            widgets
                .filter(
                    widget =>
                        collectFunction(widget) !== undefined),
            widget =>
                collectFunction(widget));

    const { value: entities, dispose } =
        await getEntitiesByUuids(
            entityType,
            Array.from(widgetsByEntityId.keys()),
            callback
        );

    await Promise.all(
        entities
            .map(
                entity =>
                    widgetsByEntityId.get(entity.uuid)
                        .map(
                            widget => [
                                widget,
                                entity
                            ] as [ T, Entity ]
                        )
            )
            .reduce((a, b) => a.concat(b), [])
            .map(
                async ([ widget, entity ]) =>
                {
                    return await setFunction(widget, entity);
                }
            )
    );

    return dispose;
}
