import Role, { Permission, Privilege, RoleType } from '../../../Model/Role';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { getEntityTypeInheritedPermission } from './getEntityTypeInheritedPermission';
import { getEntityTypeSpecifiedPermission } from './getEntityTypeSpecifiedPermission';

export function getEntityTypePermission(
    rolesByType: Map<RoleType, Role>,
    role: Role,
    privilege: Privilege,
    entityType: EntityType
): Permission
{
    const permission =
        getEntityTypeSpecifiedPermission(
            rolesByType,
            role,
            privilege,
            entityType
        );

    if (permission === 'Inherited')
    {
        return getEntityTypeInheritedPermission(
            rolesByType,
            role,
            privilege,
            entityType
        );
    }
    else
    {
        return permission;
    }
}