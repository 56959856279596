import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../Type/Api/useTypes';
import DocumentEditor, {
    DocumentDefinition,
    EntityImageBlockId,
    GutenbergBlockExpressionId,
    GutenbergBlockHtmlId,
    GutenbergBlockImageId,
    GutenbergBlockPageBreakId,
    GutenbergBlockTemplateId,
    GutenbergBlockVideoId,
    LayoutBlockId,
    MileageRegistrationsBlockId,
    OrganizationLogoBlockId,
    ProductLinesBlockId,
    TimeRegistrationsBlockId,
} from '../../../../../../../../@Future/Component/Generic/Input/DocumentEditor/DocumentEditor';
import { EntityContext } from '../../../../../@Model/EntityContext';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import ExpressionEditorContext from '../../../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Expression/ExpressionEditorContext';
import styles from './ActivityTemplateContentEditor.module.scss';
import EditIconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/EditIconButton/EditIconButton';
import { ActivityTemplateEditorProps } from '../ActivityTemplateEditor';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { setValueByFieldInEntity } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import InteractiveLayoutContext from '../../../../../../Layout/InteractiveLayoutContext';

export interface ActivityTemplateContentEditorProps extends ActivityTemplateEditorProps
{
    onEdit: () => void;
}

const ActivityTemplateContentEditor: React.FC<ActivityTemplateContentEditorProps> =
    props =>
    {
        const types = useTypes();

        const definition =
            useEntityValue(
                props.entity,
                props.templateContentField,
                undefined,
                props.commitContext
            );

        const onChange =
            useCallback(
                (definition: DocumentDefinition) =>
                {
                    setValueByFieldInEntity(
                        props.entity,
                        props.templateContentField,
                        definition,
                        props.commitContext
                    );

                    return commitEntityWithContext(props.entity, props.commitContext);
                },
                [
                    props.entity,
                    props.templateContentField,
                    props.commitContext,
                ]);

        const expressionContext =
            useMemo(
                () =>
                    ({
                        entityContext:
                            new EntityContext(
                                [],
                                EntityPath.fromEntity(props.entity))
                                .setContext('Environment', new EntityContext([], EntityPath.fromEntityType(types.Relation.Organization.Environment.Type)))
                                .setContext('Employee', new EntityContext([], EntityPath.fromEntityType(types.Relationship.Person.Contact.Employee.Type)))
                    }),
                [
                    props.entity,
                    types
                ]);

        const blockTypes =
            useMemo(
                () => [
                    GutenbergBlockExpressionId,
                    GutenbergBlockImageId,
                    GutenbergBlockVideoId,
                    GutenbergBlockTemplateId,
                    ProductLinesBlockId,
                    TimeRegistrationsBlockId,
                    MileageRegistrationsBlockId,
                    OrganizationLogoBlockId,
                    EntityImageBlockId,
                    GutenbergBlockPageBreakId,
                    GutenbergBlockHtmlId,
                    LayoutBlockId,
                ],
                []);

        return <div
            className={styles.root}
        >
            <ExpressionEditorContext.Provider
                value={expressionContext}
            >
                <InteractiveLayoutContext.Provider
                    value={false}
                >
                    <DocumentEditor
                        type="gutenberg"
                        onChange={onChange}
                        definition={definition}
                        blockTypes={blockTypes}
                        buttons={
                            <EditIconButton
                                onClick={props.onEdit}
                            />
                        }
                    />
                </InteractiveLayoutContext.Provider>
            </ExpressionEditorContext.Provider>
        </div>;
    };

export default observer(ActivityTemplateContentEditor);
