import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import LocalizedComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/LocalizedComputation';
import LocalizerContext from '../../../../../../../../../../@Service/Localization/LocalizerContext';
import useTypes from '../../../../../../../Type/Api/useTypes';
import useSwitch from '../../../../../../../../../../@Util/Switch/useSwitch';
import useResults from '../../../../../../../Selection/Hooks/useResults';
import { IObservableArray, runInAction } from 'mobx';
import uuid from '../../../../../../../../../../@Util/Id/uuid';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TabBar from '../../../../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import MenuButton from '../../../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import Tab from '../../../../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import Flag from '../../../../../../../../../../@Future/Component/Generic/Flag/Flag';
import Popper from '../../../../../../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import Centered from '../../../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../../../@Future/Component/Generic/Loader/Loader';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import LocalizedComputationItem from '../../../../../../../../../../@Api/Automation/Function/Computation/LocalizedComputationItem';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import EntityValueType from '../../../../../../../../../../@Api/Automation/Value/Type/EntityValueType';

export interface LocalizedComputationEditorProps extends ComputationEditorProps<ValueType<any>, LocalizedComputation>
{

}

const LocalizedComputationEditor: React.FC<LocalizedComputationEditorProps> =
    props =>
    {
        const localizer = useContext(LocalizerContext);
        const types = useTypes();
        const [ selectedLanguageCode, setSelectedLanguageCode ] = useState(localizer.languageCode);
        const selectedItem =
            useComputed(
                () =>
                    props.value.items.find(item => item.languageCode === selectedLanguageCode),
                [
                    props.value,
                    selectedLanguageCode
                ]);

        const [ isLanguagePickerOpen, openLanguagePicker, closeLanguagePicker ] = useSwitch(false);
        const languages = useResults(isLanguagePickerOpen && types.Datastore.Language.Type, () => {}, []);
        const languageCodes =
            useMemo(
                () =>
                    languages?.map(
                        language =>
                            language.getObjectValueByField(types.Datastore.Field.Code)?.toLowerCase())
                        .filter(
                            languageCode => languageCode !== undefined),
                [
                    types,
                    languages
                ]);
        const usedLanguageCodes =
            useComputed(
                () =>
                    new Set(props.value.items.map(item => item.languageCode)),
                [
                    props.value
                ]);
        const availableLanguageCodes =
            useMemo(
                () =>
                    languageCodes?.filter(
                        languageCode =>
                            !usedLanguageCodes.has(languageCode)),
                [
                    languageCodes,
                    usedLanguageCodes
                ]);

        const createNewItem =
            useCallback(
                (languageCode: string) =>
                {
                    setSelectedLanguageCode(languageCode);
                    closeLanguagePicker();
                },
                [
                    setSelectedLanguageCode,
                    closeLanguagePicker
                ]);

        const deleteSelectedItem =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (props.value.items as IObservableArray).remove(selectedItem)),
                [
                    props.value,
                    selectedItem
                ]);

        const onConstruct =
            useCallback(
                (computation: Computation<any, any>) =>
                    computation
                        ?
                            runInAction(
                                () =>
                                    props.value.items.push(
                                        new LocalizedComputationItem(
                                            uuid(),
                                            selectedLanguageCode,
                                            computation)))
                        :
                            deleteSelectedItem(),
                [
                    props.value,
                    selectedLanguageCode,
                    deleteSelectedItem
                ]);

        const onChangeLanguageComputation =
            useCallback(
                (computation: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.language = computation
                    ),
                [
                    props.value
                ]
            );

        const languageType =
            useMemo(
                () =>
                    new EntityValueType(types.Datastore.Language.Type),
                [
                    types
                ]
            );

        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            <ViewGroupItem>
                <Input
                    label={
                        <LocalizedText
                            code="Language"
                            value="Taal"
                        />
                    }
                    labelPosition="left"
                >
                    <ComputationEditor
                        context={props.context}
                        value={props.value.language}
                        onChange={onChangeLanguageComputation}
                        type={languageType}
                    />
                </Input>
            </ViewGroupItem>
            <ViewGroupItem>
                <TabBar
                    value={selectedItem ? selectedLanguageCode : 'new'}
                    appendix={
                        selectedItem &&
                        <MenuButton>
                            <Menu>
                                <Item
                                    name={
                                        <LocalizedText
                                            code="Generic.Delete"
                                            value="Verwijderen"
                                        />
                                    }
                                    onClick={deleteSelectedItem}
                                />
                            </Menu>
                        </MenuButton>
                    }
                >
                    {
                        props.value.items.map(
                            item =>
                                <Tab
                                    key={item.id}
                                    value={item.languageCode}
                                    onClick={setSelectedLanguageCode}
                                >
                                    <Flag
                                        languageCode={item.languageCode}
                                    />
                                </Tab>)
                    }
                    {
                        !selectedItem &&
                            <Tab
                                value="new"
                                onClick={openLanguagePicker}
                            >
                                <Flag
                                    languageCode={selectedLanguageCode}
                                />
                            </Tab>
                    }
                    <Popper
                        reference={
                            <Tab
                                value="constructor"
                                onClick={openLanguagePicker}
                            >
                                +
                            </Tab>
                        }
                        popper={
                            <Card>
                                {
                                    availableLanguageCodes
                                        ?
                                        <Menu>
                                            {
                                                availableLanguageCodes.map(
                                                    languageCode =>
                                                        <Item
                                                            onClick={createNewItem}
                                                            value={languageCode}
                                                            name={
                                                                <ViewGroup
                                                                    orientation="horizontal"
                                                                    spacing={15}
                                                                    alignment="center"
                                                                >
                                                                    <ViewGroupItem>
                                                                        <Flag
                                                                            languageCode={languageCode}
                                                                        />
                                                                    </ViewGroupItem>
                                                                    <ViewGroupItem>
                                                                        {languageCode}
                                                                    </ViewGroupItem>
                                                                </ViewGroup>
                                                            }
                                                        />)
                                            }
                                        </Menu>
                                        :
                                        <CardInset>
                                            <Centered
                                                horizontal
                                            >
                                                <Loader />
                                            </Centered>
                                        </CardInset>
                                }
                            </Card>
                        }
                        open={isLanguagePickerOpen}
                        onClose={closeLanguagePicker}
                    />
                </TabBar>
            </ViewGroupItem>
            <ViewGroupItem>
                <ComputationEditor
                    key={selectedLanguageCode}
                    {...props}
                    value={selectedItem?.computation}
                    onChange={onConstruct}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(LocalizedComputationEditor);
