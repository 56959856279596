import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import LocalizerContext from '../../../../../@Service/Localization/LocalizerContext';
import LocalizedLayout from '../../../../../@Api/Layout/Type/LocalizedLayout';
import { FallbackLanguageCode } from '../../../../../@Service/Localization/Localizer';

export interface LocalizedLayoutViewerProps extends LayoutViewerProps<LocalizedLayout>
{

}

const LocalizedLayoutViewer: React.FC<LocalizedLayoutViewerProps> =
    props =>
    {
        const localizer = useContext(LocalizerContext);
        const selectedItem =
            useComputed(
                () =>
                    props.layout.items.find(item => item.languageCode === localizer.languageCode)
                        ?? props.layout.items.find(item => item.languageCode === FallbackLanguageCode)
                        ?? props.layout.items.find(() => true),
                [
                    props.layout,
                    localizer.languageCode
                ]);

        if (selectedItem)
        {
            return <LayoutViewer
                {...props}
                layout={selectedItem.layout}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(LocalizedLayoutViewer);
