import React, { useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import PortalRouteLayout from '../../../../../@Api/Layout/Type/PortalRouteLayout';
import PortalPage from '../../../../App/Root/Environment/Portal/Page/PortalPage';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import safelySynchronousApplyFunction from '../../../../../@Api/Automation/Api/safelySynchronousApplyFunction';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { TextType } from '../../../DataObject/Type/Text/TextType';

export interface PortalRouteLayoutViewerProps extends LayoutViewerProps<PortalRouteLayout>
{

}

const PortalRouteLayoutViewer: React.FC<PortalRouteLayoutViewerProps> =
    props =>
    {
        const { layout, parameterDictionary, parameterAssignment, commitContext } = props;
        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        parameterDictionary,
                        parameterAssignment,
                        commitContext
                    ),
                [
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                ]);
        const route =
            useComputed(
                () =>
                {
                    const value =
                        safelySynchronousApplyFunction(
                            layout.route,
                            context);

                    if (value instanceof PrimitiveValue
                        && value.value.type instanceof TextType)
                    {
                        return value.value.value;
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    layout,
                    context
                ]);

        if (route)
        {
            return <PortalPage
                route={route}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(PortalRouteLayoutViewer);
