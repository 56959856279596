import ParameterDictionary from './Parameter/ParameterDictionary';
import { DeferredInitializer } from './DeferredInitializer/DeferredInitializer';
import { ComputationSubstitution } from './Function/Computation/Util/ComputationSubstitution';

export default class AutomationDependencyContext
{
    // ------------------------- Properties -------------------------

    parameterDictionary: ParameterDictionary;
    deferredInitializer?: DeferredInitializer;
    substitution?: ComputationSubstitution;

    // ------------------------ Constructor -------------------------

    constructor(
        parameterDictionary: ParameterDictionary,
        deferredValueInitializer?: DeferredInitializer,
        parameterSubstition?: ComputationSubstitution
    )
    {
        this.parameterDictionary = parameterDictionary;
        this.deferredInitializer = deferredValueInitializer;
        this.substitution = parameterSubstition;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static GetEmptyContext(): AutomationDependencyContext
    {
        return new AutomationDependencyContext(
            new ParameterDictionary([]));
    }

    // ----------------------- Private logic ------------------------
}
