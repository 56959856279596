import Validation from '../Automation/Validation/Validation';
import Dependency from '../Automation/Parameter/Dependency';

export default abstract class Layout
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract getName(): string;

    abstract validate(): Validation[];

    abstract getDependencies(): Dependency[];

    abstract toDescriptor();

    // ----------------------- Private logic ------------------------
}
