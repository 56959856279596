import { observable } from 'mobx';
import { PredicateType } from './PredicateType';
import { ComputationContext } from '../../Computation/ComputationContext';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import { PredicateTypeStore } from '../PredicateTypeStore';
import NewPredicate from '../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import AutomationDependencyContext from '../../../../@Api/Automation/AutomationDependencyContext';

export abstract class OldPredicate
{
    // ------------------------- Properties -------------------------

    @observable.ref type: PredicateType;

    // ------------------------ Constructor -------------------------

    constructor(typeId: string)
    {
        this.type = loadModuleDirectly(PredicateTypeStore).getTypeById(typeId);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    evaluate(context: ComputationContext): boolean
    {
        return this.type.evaluate(context, this);
    }

    abstract getLeafs(): OldPredicate[];

    abstract getTrueStatements(): OldPredicate[];

    abstract toNewPredicate(context: AutomationDependencyContext): Promise<NewPredicate>;

    abstract initialize();

    // ----------------------- Private logic ------------------------
}
