import React from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import { SearchItemSearcherProps } from '../SearchItemSearcher';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import styles from './SearchItemBucket.module.scss';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Avatar from '../../../../../../@Future/Component/Generic/Avatar/Avatar';
import DividedList from '../../../../Entity/List/V2/Divided/DividedList';

export interface SearchItemBucketProps extends SearchItemSearcherProps
{
    entityType: EntityType;
    results: Entity[];
}

const SearchItemBucket: React.FC<SearchItemBucketProps> =
    props =>
    {
        return <Card
            classes={{
                root: styles.card
            }}
        >
            <CardInset>
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                    className={styles.header}
                >
                    <ViewGroupItem>
                        <Avatar
                            icon={props.entityType.getInheritedIcon()}
                            color={props.entityType.getInheritedColor()}
                            size={20}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem
                        ratio={1}
                    >
                        {props.entityType.getName(true)}
                    </ViewGroupItem>
                    <ViewGroupItem>
                        {props.results.length}
                    </ViewGroupItem>
                </ViewGroup>
            </CardInset>
            <Card
                classes={{
                    root: styles.listCard
                }}
            >
                <DividedList
                    entities={props.results}
                    inset
                    compact
                    hideFavoriteButton
                    onOpen={props.onOpen}
                />
            </Card>
        </Card>;
    };

export default observer(SearchItemBucket);
