import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { EntityField } from '../../../../../@Api/Model/Implementation/EntityField';
import { DataObjectRepresentationProps } from '../../../DataObject/Model/DataObjectRepresentation';
import getSetting from '../../../Setting/Api/getSetting';
import { InvoiceRegime } from '../../../Configuration/Page/Invoicing/model/InvoiceRegime';
import { SettingSource } from '../../../Setting/SettingStore';
import { Setting } from '../../../../../@Api/Settings/Setting';

export class BespokeProductLine extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    currencyFields: EntityField[];

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'ProductLine')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    extendRelationshipSelection(entity: Entity,
                                relationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean,
                                builder: EntitySelectionBuilder,
                                commitContext?: CommitContext)
    {
        super.extendRelationshipSelection(
          entity,
          relationshipDefinition,
          isParent,
          builder,
          commitContext
        );

        const types = this.entityTypeStore.bespoke.types;

        if (relationshipDefinition === types.ProductLine.RelationshipDefinition.Product
          && !isParent)
        {
            // This ensures that the caption of the product group is shown
            builder.join(
              builder.rootPath
                .joinTo(
                  this.types.ProductGroup.RelationshipDefinition.Products,
                  true));

            // Do not select any inactive products
            builder.where(
              cb =>
                cb.neq(
                  builder.rootPath.field(this.types.Product.Field.IsInactive),
                  undefined,
                  true));
        }
        else if (relationshipDefinition === types.Milestone.RelationshipDefinition.ProductLines
          && isParent)
        {
            builder
              .where(
                cb =>
                  cb.relatedToEntity(
                    builder.rootPath
                      .joinTo(
                        types.Activity.Project.RelationshipDefinition.Milestones,
                        true),
                    entity.getRelatedEntityByDefinition(
                      true,
                      types.Activity.RelationshipDefinition.ProductLines,
                      commitContext)
                  )
              );
        }
    }

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    ): Promise<void>
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        if (relationshipDefinition === this.types.ProductLine.RelationshipDefinition.VatGroup)
        {
            entity.setValueByField(
                this.types.ProductLine.Field.VatPercentage,
                relatedEntity?.getObjectValueByField(this.types.Datastore.VatGroup.Field.Percentage),
                true,
                false,
                commitContext
            );
        }
    }

    getListDependencies(): EntityPath[]
    {
        const rootPath = EntityPath.fromEntityType(this.type);

        return [
            ...super.getListDependencies(),
            rootPath
              .joinTo(
                this.types.Activity.RelationshipDefinition.ProductLines,
                true)
              .joinTo(
                this.types.Relationship.RelationshipDefinition.Activities,
                true)
        ];
    }

    getEntityToOpen(entity: Entity): Entity
    {
        const activity =
          entity.getRelatedEntityByDefinition(
            true,
            this.types.Activity.RelationshipDefinition.ProductLines);

        return activity || entity;
    }

    onValueSet(entity: Entity,
               field: EntityField,
               value: any,
               commitContext?: CommitContext)
    {
        super.onValueSet(entity, field, value, commitContext);

        if (field === this.types.ProductLine.Field.PriceInCurrency)
        {
            this.onSetValueFromCurrency(
              entity,
              this.types.ProductLine.Field.Currency,
              this.types.ProductLine.Field.PriceInCurrency,
              value,
              this.types.ProductLine.Field.Price,
              commitContext
            );
        }
        else if (field === this.types.ProductLine.Field.TotalInCurrency)
        {
            this.onSetValueFromCurrency(
              entity,
              this.types.ProductLine.Field.Currency,
              this.types.ProductLine.Field.TotalInCurrency,
              value,
              this.types.ProductLine.Field.Total,
              commitContext
            );
        }
    }

    getRepresentation(
      entity: Entity,
      field: EntityField
    ): DataObjectRepresentationProps
    {
        const representation =
          super.getRepresentation(
            entity,
            field
          );

        if (this.getCurrencyFields().includes(field))
        {
            return {
                ...representation,
                currency: entity.getObjectValueByField(this.types.ProductLine.Field.Currency),
            };
        }
        else
        {
            return representation;
        }
    }

    getCurrencyFields(): EntityField[]
    {
        if (!this.currencyFields)
        {
            this.currencyFields = [
                this.types.ProductLine.Field.PriceInCurrency,
                this.types.ProductLine.Field.TotalExcludingVatInCurrency,
                this.types.ProductLine.Field.TotalIncludingVatInCurrency,
                this.types.ProductLine.Field.TotalInCurrency,
                this.types.ProductLine.Field.VatAmountIncludingDiscountInCurrency,
                this.types.ProductLine.Field.VatAmountInCurrency,
            ];
        }
        return this.currencyFields;
    }

    isDeletable(entity: Entity)
    {
        if (!super.isDeletable(entity))
        {
            return false;
        }

        const activity =
            entity.getRelatedEntityByDefinition(
                true,
                this.types.Activity.RelationshipDefinition.ProductLines
            );

        if (!activity.entityType.isA(this.types.Activity.Invoice.Type))
        {
            return true;
        }

        const regime =
            getSetting<InvoiceRegime>(
                SettingSource.Organization,
                Setting.InvoiceRegime
            );

        if (regime !== 'Strict')
        {
            return true;
        }

        const finalized =
            activity.getObjectValueByField(this.types.Activity.Invoice.Field.IsFinalized);

        return !finalized;

    }

    // ----------------------- Private logic ------------------------
}
