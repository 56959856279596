import { BaseStore, getOrCompute, PropType } from '../../../../../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { EntityPathPickerStore } from '../../../Field/EntityPathPickerStore';
import { EntityPath } from '../../../@Model/EntityPath';
import { EntityContext } from '../../../../@Model/EntityContext';
import { TextStore } from '../../../../../../Generic/Text/TextStore';
import { textSecondaryColor } from '../../../../../../../@Resource/Theme/Theme';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import { LocalizationStore } from '../../../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../../Type/EntityTypeStore';

export interface EntityPathEditorModalProps
{
    entityContext?: PropType<EntityPathEditorModalStore, EntityPathEditorModalProps, EntityContext>;
    selectedEntityPath?: PropType<EntityPathEditorModalStore, EntityPathEditorModalProps, EntityPath>;
    shouldBeOfType?: PropType<EntityPathEditorModalStore, EntityPathEditorModalProps, EntityType>;
    onSelected?: (entityPath: EntityPath) => Promise<any>;
}

const defaultProps: Partial<EntityPathEditorModalProps> =
{

};

export class EntityPathEditorModalStore extends BaseStore<EntityPathEditorModalProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;

    // ------------------------- Properties -------------------------

    @observable entityPath: EntityPath;

    // ------------------------ Constructor -------------------------

    constructor(props: EntityPathEditorModalProps)
    {
        super(props, defaultProps);

        this.entityPath = getOrCompute(this, this.props.selectedEntityPath);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get entityContext(): EntityContext
    {
        return getOrCompute(this, this.props.entityContext);
    }

    @computed
    get shouldBeOfType(): EntityType
    {
        return getOrCompute(this, this.props.shouldBeOfType);
    }

    @computed
    get isValid(): boolean
    {
        return this.shouldBeOfType
            ?
                this.entityPath
                    ?
                        this.entityPath.entityType.isA(this.shouldBeOfType)
                            ?
                                true
                            :
                                false
                    :
                        true
            :
                true;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get entityPathPickerStore(): EntityPathPickerStore
    {
        return new EntityPathPickerStore(
            this.entityContext,
            (path: EntityPath, parameter?: string) => this.selectPath(path));
    }

    @computed
    get labelStore(): TextStore
    {
        return new TextStore({
            color: textSecondaryColor,
            variant: 'body2',
            label: 'Geselecteerde waarde: ',
            isInline: true
        });
    }

    @computed
    get valueStore(): TextStore
    {
        return new TextStore({
            color: textSecondaryColor,
            variant: 'body2',
            label: this.entityPath ? this.entityPath.getName(this.entityTypeStore) : 'Geen',
            isInline: true,
            weight: 'bold'
        });
    }

    @computed
    get validationText(): TextStore
    {
        return new TextStore({
            color: 'red',
            variant: 'body2',
            label:
                this.localizationStore.translate(
                    'EntityRelationshipEditor.InvalidDefaultValueMessage',
                    this.shouldBeOfType.nameSingular)
        });
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    selectPath(path: EntityPath)
    {
        this.entityPath = path;

        if (this.props.onSelected)
        {
            this.props.onSelected(path);
        }

    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
