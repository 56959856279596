import { loadModuleDirectly } from '../../@Util/DependencyInjection/Injection/DependencyInjection';
import { CurrentUserStore } from '../../@Component/Domain/User/CurrentUserStore';
import hasPack from './hasPack';
import { Pack } from './Pack';

export default function hasAccessToWorkflowAutomations()
{
    if (isEarlyBirdForWorkflowAutomations())
    {
        return true;
    }
    else
    {
        return hasPack(Pack.WorkflowAutomations);
    }
}

function isEarlyBirdForWorkflowAutomations()
{
    const currentUserStore = loadModuleDirectly(CurrentUserStore);

    return currentUserStore.isEarlyBird || currentUserStore.isSupport;
}
