import FunctionContext from '../Automation/Function/FunctionContext';
import ParameterAssignment from '../Automation/Parameter/ParameterAssignment';
import { observable } from 'mobx';
import FormLayoutContext from './FormLayoutContext';
import ParameterDictionary from '../Automation/Parameter/ParameterDictionary';
import { CommitContext } from '../Entity/Commit/Context/CommitContext';

export default class LayoutContext extends FunctionContext
{
    // ------------------------- Properties -------------------------

    @observable isInSafeMode: boolean;
    @observable.shallow formLayoutContexts: FormLayoutContext[];
    @observable.shallow contextByFormId: Map<string, FormLayoutContext>;

    // ------------------------ Constructor -------------------------

    constructor(
        parameterDictionary: ParameterDictionary,
        parameterAssignment: ParameterAssignment,
        commitContext: CommitContext | undefined,
        isInSafeMode: boolean,
        formLayoutContexts: FormLayoutContext[]
    )
    {
        super(
            parameterDictionary,
            parameterAssignment,
            commitContext
        );

        this.isInSafeMode = isInSafeMode;
        this.formLayoutContexts = formLayoutContexts;
        this.contextByFormId =
            new Map(
                formLayoutContexts.map(
                    context => [
                        context.layout.id,
                        context
                    ]));
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
