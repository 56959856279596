import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { observable } from 'mobx';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { EntityTypeStore } from './EntityTypeStore';
import { EntityFieldPath } from '../Path/@Model/EntityFieldPath';
import { EntityRelationshipDefinition, Type } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { RelatedEntityPath } from '../Path/@Model/RelatedEntityPath';
import { DataObjectType, EntityField } from '../../../../@Api/Model/Implementation/EntityField';
import { EntityPath } from '../Path/@Model/EntityPath';
import { SideEffectProps, SideEffectStore } from '../SideEffect/SideEffectStore';
import { EntityRelationshipDefinitionTuple } from '../@Model/EntityRelationshipDefinitionTuple';
import { EntitySelectionBuilder } from '../Selection/Builder/EntitySelectionBuilder';
import { EntityValue } from '../../../../@Api/Model/Implementation/EntityValue';
import { TextStore } from '../../../Generic/Text/TextStore';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import { CurrentUserStore } from '../../User/CurrentUserStore';
import { Newable } from '../../../../@Util/Serialization/Serialization';
import Automation from '../../../../@Api/Automation/Automation';
import getPipelineRelationshipDefinition from '../../../../@Api/Entity/Bespoke/Datastore/Phase/getPipelineRelationshipDefinition';
import getPhaseRelationshipDefinition from '../../../../@Api/Entity/Bespoke/Datastore/Phase/getPhaseRelationshipDefinition';
import getMetadataSettingFlag from '../../../../@Api/Metadata/getMetadataSettingFlag';
import { Setting } from '../../../../@Api/Settings/Setting';
import getDefaultPipelineName from '../../../../@Api/Entity/Bespoke/Datastore/Phase/getDefaultPipelineName';
import { EntityActionResult } from '../../../../@Api/Model/Implementation/EntityActionResult';
import { DataObjectRepresentationProps } from '../../DataObject/Model/DataObjectRepresentation';
import getDefaultPhase from '../../../../@Api/Entity/Bespoke/Datastore/Phase/getDefaultPhase';
import { doAfterEntityCommit } from '../../../../@Api/Entity/Commit/commitEntity';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { commitEntityWithContext } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import { deleteRelationship } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/deleteRelationship';
import { getModel } from '../../../../@Util/TransactionalModelV2/index';
import { setValueByFieldInEntity } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { Localizer } from '../../../../@Service/Localization/Localizer';
import { getInheritedNameFieldWithFallback } from '../../../../@Api/Metadata/Field/getInheritedNameFieldWithFallback';
import { AuditTrailEntity } from '../Timeline/Model/AuditTrailEntity';
import { LocalizationStore } from '../../../../@Service/Localization/LocalizationStore';
import { Selection } from '../../../../@Api/Selection/Model/Selection';
import getEntityTypeColorForPlanner from '../@Util/getEntityTypeColorForPlanner';

export interface SummaryField
{
    id: string;
    icon: string;
    title: string;
    tooltip: React.ReactNode;
    link: string;
    value: EntityValue;
    entity?: Entity;
}

export interface Action<T = any>
{
    id: string;
    code: string;
    name?: string;
    parameters?: any;
    files?: Map<string, File>;
    resultType?: Newable<T>;
    perform?: (entity?: Entity, parameters?: any) => Promise<EntityActionResult>;
    commitId?: string;
}

export interface Automator
{
    code: string;
    name: string;
    description: string;
    automate: () => Automation;
    isUser?: boolean;
}

export class BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable.ref entityTypeStore: EntityTypeStore;
    @observable.ref type: EntityType;

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore, code: string)
    {
        this.entityTypeStore = entityTypeStore;
        this.type = entityTypeStore.getTypeByCode(code);
        entityTypeStore.registerBespoke(this.type, this);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    onConstruct(entity: Entity,
                commitContext?: CommitContext): void
    {
        const currentUserStore = loadModuleDirectly(CurrentUserStore);

        // Set pack to environment pack (if not already set)
        if (!entity.hasRelationshipsByDefinition(
            true,
            this.entityTypeStore.bespoke.types.Pack.RelationshipDefinition.Entities,
            commitContext))
        {
            updateRelationship(
                entity,
                true,
                this.entityTypeStore.bespoke.types.Pack.RelationshipDefinition.Entities,
                currentUserStore.rightProfile.getPackForNewType(entity.entityType),
                commitContext
            );
        }

        // Set team to first team (if in any team)
        if (this.entityTypeStore.currentUserStore.currentTeam
            && this.isAssignableToTeam()
            && (
                this.inheritTeamFrom() === undefined
                || this.inheritTeamFrom()
                    .joinTo(
                        this.types.Team.RelationshipDefinition.Entities,
                        true
                    )
                    .traverseEntity(entity, commitContext)
                    .length === 0
            )
            && !entity.hasRelationshipsByDefinition(
                true,
                this.entityTypeStore.bespoke.types.Team.RelationshipDefinition.Entities,
                commitContext))
        {
            updateRelationship(
                entity,
                true,
                this.entityTypeStore.bespoke.types.Team.RelationshipDefinition.Entities,
                currentUserStore.currentTeam,
                commitContext
            );
        }
    }

    onConstructFromRelationship(entity: Entity,
                                fromEntity: Entity,
                                fromRelationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean,
                                commitContext?: CommitContext)
    {
        const pipelineRelationshipDefinition = getPipelineRelationshipDefinition(fromEntity.entityType);
        const phaseRelationshipDefinition = getPhaseRelationshipDefinition(fromEntity.entityType);

        if (fromRelationshipDefinition === pipelineRelationshipDefinition
            && !isParent)
        {
            updateRelationship(
                entity,
                true,
                this.types.EntityType.RelationshipDefinition.Pipelines,
                getModel(fromEntity.entityType.entity), // ensure fresh transactional model
                commitContext
            );
        }
        else if (pipelineRelationshipDefinition
            && fromRelationshipDefinition === phaseRelationshipDefinition
            && !isParent)
        {
            const pipeline =
                fromEntity.getRelatedEntityByDefinition(
                    false,
                    pipelineRelationshipDefinition,
                    commitContext
                );

            updateRelationship(
                entity,
                true,
                this.types.Pipeline.RelationshipDefinition.Phases,
                getModel(pipeline), // ensure fresh transactional model
                commitContext
            );
        }
    }

    isConfigurationType(): boolean
    {
        return false;
    }

    isOpenable(entity: Entity, pathFromRelatedEntity?: RelatedEntityPath): boolean
    {
        return this.getEntityToOpen(entity) !== undefined;
    }

    isOpenableFromSelectbox(): boolean
    {
        return false;
    }

    isLinkable(entity: Entity): boolean
    {
        return true;
    }

    isFormerLink(entity: Entity): boolean
    {
        return false;
    }

    hasAvatarInCard(entity: Entity): boolean
    {
        return true;
    }

    getRelationshipDefinitionsToDisplayInCards(entity: Entity): EntityRelationshipDefinitionTuple[]
    {
        return [];
    }

    getAvatarFromEntity(entity: Entity): Entity
    {
        return entity;
    }

    /**
     * Returns a promise with a boolean specifying whether
     * the entity should be routed to.
     *
     * @param entity
     */
    onOpen(entity: Entity, pathFromRelatedEntity?: RelatedEntityPath): Promise<boolean>
    {
        return Promise.resolve(true);
    }

    getSideEffects(props: SideEffectProps): SideEffectStore[]
    {
        return [];
    }

    getEntityToOpen(entity: Entity): Entity
    {
        return entity;
    }

    getEntityToList(entity: Entity, isRelated: boolean): Entity
    {
        return entity;
    }

    listAsTimelineByDefault(): boolean
    {
        return false;
    }

    getName(isPlural: boolean, entity?: Entity): string
    {
        return undefined;
    }

    isOwnable(entity: Entity): boolean
    {
        return entity.bespoke.isOwnable;
    }

    isAssignable(entity: Entity): boolean
    {
        return entity.bespoke.isAssignable;
    }

    isFollowUpAllDay(entity: Entity): boolean
    {
        return entity.bespoke.isFollowUpAllDay;
    }

    isInWorkflow(entity: Entity): boolean
    {
        return entity.bespoke.isInWorkflow;
    }

    isInClosedState(entity: Entity): boolean
    {
        return entity.bespoke.isInClosedState;
    }

    showAssigneeOrOwnerInPrimaryAvatar(entity: Entity, pathFromRelatedEntity?: RelatedEntityPath): boolean
    {
        return false;
    }

    isOwnerInListCaption(entity: Entity, owner: Entity, relationshipDefinition: EntityRelationshipDefinition, pathFromRelatedEntity?: RelatedEntityPath)
    {
        return relationshipDefinition.type === Type.Ownership;
    }

    allowAddingNoteOrAttachments(entity: Entity): boolean
    {
        return false;
    }

    hideFieldPath(entity: Entity,
                  fieldPath: EntityFieldPath,
                  fromFieldPath?: EntityFieldPath,
                  isInConstructor?: boolean,
                  commitContext?: CommitContext): boolean
    {
        if (fieldPath.field === this.types.Entity.Field.Type)
        {
            return isInConstructor
                && entity.entityType.isInstantiableByInheritance()
                && entity.entityType.isSwitchableByInheritance();
        }
        else if (fieldPath.relationshipDefinition === this.types.Team.RelationshipDefinition.Entities
            && fieldPath.isParentRelationship)
        {
            const currentUserStore = loadModuleDirectly(CurrentUserStore);

            if (this.isAssignableToTeam())
            {
                if (currentUserStore.currentTeam === undefined)
                {
                    return currentUserStore.allLimitedAccessTeams.length === 0;
                }
                else
                {
                    return true;
                }
            }
            else
            {
                return true;
            }
        }
        else
        {
            return false;
        }
    }

    isDisabled(entity: Entity,
               fieldPath: EntityFieldPath): boolean
    {
        return false;
    }

    includeFieldPathDuringConstruction(entity: Entity, fieldPath: EntityFieldPath): boolean
    {
        return false;
    }

    orderByField(entity?: Entity,
                 pathFromRelatedEntity?: RelatedEntityPath): EntityFieldPath
    {
        if (pathFromRelatedEntity && this.isSortable(pathFromRelatedEntity))
        {
            return EntityPath.fromEntityType(this.type).field(this.types.Entity.Field.SortIndex);
        }
        else if (entity && entity.bespoke.timelineDateField)
        {
            return EntityPath.fromEntityType(this.type).field(entity.bespoke.timelineDateField);
        }
        else
        {
            return EntityPath.fromEntityType(this.type)
                .field(
                    getInheritedNameFieldWithFallback(
                        this.type
                    )
                );
        }
    }

    isAttentionButtonVisible(entity: Entity): boolean
    {
        return false;
    }

    getAttentionButtonCaption(entity: Entity): string
    {
        return undefined;
    }

    addEntityPathForAttentionButtonClick(entity: Entity): EntityPath
    {
        return undefined;
    }

    isSortable(pathFromRelatedEntity: RelatedEntityPath)
    {
        return false;
    }

    isHiddenInSelectbox(entity: Entity)
    {
        return false;
    }

    isDeletable(entity: Entity)
    {
        return true;
    }

    isTypeDeletable()
    {
        return false;
    }

    extendRelationshipSelection(entity: Entity,
                                relationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean,
                                builder: EntitySelectionBuilder,
                                commitContext?: CommitContext)
    {
        // In case of editing a pack, only show your own owned packs (not the packs you can read)
        if (relationshipDefinition === this.types.Pack.RelationshipDefinition.Entities
            && isParent)
        {
            builder.where(
                cb =>
                    cb.or(
                        ob =>
                            loadModuleDirectly(CurrentUserStore).rightProfile.ownedPacks
                                .forEach(
                                    ownedPack =>
                                        ob.relatedToEntity(
                                            builder.rootPath,
                                            ownedPack))));
        }

        // Limit the selectable teams
        if (relationshipDefinition === this.types.Team.RelationshipDefinition.Entities
            && isParent)
        {
            const currentUserStore = loadModuleDirectly(CurrentUserStore);
            const selectableTeams =
                currentUserStore.limitedAccessTeams.length === 0
                    ?
                        currentUserStore.allLimitedAccessTeams
                    :
                        currentUserStore.limitedAccessTeams;

            builder.where(
                cb =>
                    cb.or(
                        ob =>
                            selectableTeams.forEach(
                                team =>
                                    ob.relatedToEntity(
                                        EntityPath.root(this.types.Team.Type),
                                        team))));
        }

        // Limit the selectable pipelines
        const pipelineRelationshipDefinition = getPipelineRelationshipDefinition(entity.entityType);

        if (relationshipDefinition === pipelineRelationshipDefinition
            && !isParent)
        {
            builder.where(
                cb =>
                    cb.eq(
                        builder.rootPath.field(this.types.Pipeline.Field.IsActive),
                        undefined,
                        true));
        }

        // Limit the selectable phases
        const phaseRelationshipDefinition = getPhaseRelationshipDefinition(entity.entityType);

        if (relationshipDefinition === phaseRelationshipDefinition
            && !isParent)
        {
            if (pipelineRelationshipDefinition)
            {
                const pipeline =
                    entity.getRelatedEntityByDefinition(
                        false,
                        pipelineRelationshipDefinition,
                        commitContext
                    );

                if (pipeline)
                {
                    builder.where(
                        cb =>
                            cb.relatedToEntity(
                                builder.rootPath
                                    .joinTo(
                                        this.types.Pipeline.RelationshipDefinition.Phases,
                                        true),
                                pipeline));
                }
                else
                {
                    builder.where(
                        cb =>
                            cb.isNotDefined(
                                builder.rootPath
                                    .joinTo(
                                        this.types.Pipeline.RelationshipDefinition.Phases,
                                        true)
                                    .field(this.types.Entity.Field.Id)));
                }
            }
        }
    }

    hideCaptionInSelectboxValue(entity: Entity,
                                relationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean): boolean
    {
        return relationshipDefinition.getEntityType(isParent).isA(this.types.Relationship.Person.Contact.Employee.Type)
            || relationshipDefinition.getEntityType(isParent).isA(this.types.Datastore.Phase.Type);
    }

    getLabelInInterface(entity: Entity,
                        fieldPath: EntityFieldPath)
    {
        return fieldPath.getName(this.entityTypeStore);
    }

    isPluralInInterface(entity: Entity,
                        relationshipDefinition: EntityRelationshipDefinition,
                        isParent: boolean)
    {
        return relationshipDefinition.isPlural(isParent);
    }

    getEmptyOptionLabel(entity: Entity,
                        relationshipDefinition: EntityRelationshipDefinition,
                        isParent: boolean): string | undefined
    {
        if (relationshipDefinition.getEntityType(isParent).isA(this.types.Pipeline.Type)
            && getMetadataSettingFlag(entity.entityType, Setting.Metadata.HasDefaultPipeline))
        {
            return getDefaultPipelineName(entity.entityType);
        }
        else
        {
            return undefined;
        }
    }

    onValueSet(entity: Entity,
               field: EntityField,
               value: any,
               commitContext?: CommitContext)
    {
        // If the type changes, then call onConstruct and cleanup any illegal relationships
        if (field === this.entityTypeStore.typeField
            && value instanceof EntityType)
        {
            BespokeEntityType.cleanupIllegalRelationships(entity, value, commitContext);
            entity.entityType = value;
            value.bespoke.onConstruct(entity, commitContext);
        }
    }

    static cleanupIllegalRelationships(
        entity: Entity,
        entityType: EntityType,
        commitContext?: CommitContext
    )
    {
        // Only cleanup illegal relationships for new entities
        if (entity.isNew())
        {
            [ true, false ]
                .forEach(
                    isParent =>
                        entity.getRelationships(isParent, commitContext)
                            .filter(
                                relationship =>
                                    !entityType.isA(relationship.definition.getEntityType(!isParent))
                            )
                            .forEach(
                                relationship =>
                                    deleteRelationship(
                                        relationship,
                                        commitContext
                                    )
                            )
                );
        }
    }

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        // When the pipeline changes, then also re-initialize the default phase for that specific pipeline
        const pipelineRelationshipDefinition = getPipelineRelationshipDefinition(entity.entityType);

        if (relationshipDefinition === pipelineRelationshipDefinition
            && !isParent)
        {
            const phaseRelationshipDefinition = getPhaseRelationshipDefinition(entity.entityType);

            if (phaseRelationshipDefinition)
            {
                await getDefaultPhase(entity.entityType, relatedEntity)
                    .then(
                        phase =>
                            doAfterEntityCommit(
                                entity,
                                () =>
                                {
                                    updateRelationship(
                                        entity,
                                        false,
                                        phaseRelationshipDefinition,
                                        phase,
                                        commitContext
                                    );

                                    if (!entity.isNew())
                                    {
                                        return commitEntityWithContext(
                                            entity,
                                            commitContext
                                        );
                                    }
                                }));
            }
        }
    }

    isAssignableToTeam(): boolean
    {
        return false;
    }

    inheritTeamFrom(): EntityPath
    {
        return undefined;
    }

    allowWorkflowInteraction(): boolean
    {
        return true;
    }

    getSummaryFields(entity: Entity): SummaryField[]
    {
        return [];
    }

    getHeaderFieldPaths(entity: Entity): EntityFieldPath[]
    {
        return [];
    }

    getEntityToOpenInSidebar(entity: Entity): Entity
    {
        return undefined;
    }

    getEntityToShowTypeAvatarOf(entity: Entity): Entity
    {
        return undefined;
    }

    showListItemsAsEntityCard(pathFromRelatedEntity?: RelatedEntityPath)
    {
        return false;
    }

    showAvatarInEntityCard(entity: Entity,
                           pathFromRelatedEntity?: RelatedEntityPath)
    {
        return true;
    }

    getEntityCardTitle(entity: Entity,
                       pathFromRelatedEntity?: RelatedEntityPath): TextStore
    {
        return undefined;
    }

    showDividerInCard(entity: Entity,
                      pathFromRelatedEntity?: RelatedEntityPath)
    {
        return false;
    }

    showTypeSwitcherInCard(entity: Entity,
                           pathFromRelatedEntity?: RelatedEntityPath)
    {
        return false;
    }

    getInitializationPathsInViewer(rootPath: EntityPath): EntityPath[]
    {
        // Initialize entity with singleton relationships, expand the original as
        // this entity might be used from outside in views. We want to continue to
        // manipulate that instance.
        const paths =
            [ true, false ]
                .map(
                    isParent =>
                        rootPath.entityType.getInheritedRelationshipDefinitions(isParent)
                            .filter(
                                relationshipDefinition =>
                                    (!isParent && relationshipDefinition.type === Type.Definition)
                                    || relationshipDefinition.isSingular(isParent)
                                    || relationshipDefinition.isVisibleInDetails(!isParent))
                            .map(
                                relationshipDefinition =>
                                    rootPath.joinTo(
                                        relationshipDefinition,
                                        isParent)))
                .reduce((a, b) => a.concat(b));

        // Add initialization paths for defining relationships
        paths.push(
            ...rootPath.entityType
                .getInheritedRelationshipDefinitions(false)
                .filter(
                    relationshipDefinition =>
                        relationshipDefinition.type === Type.Definition)
                .map(
                    relationshipDefinition =>
                        rootPath.joinTo(
                            relationshipDefinition,
                            false))
                .filter(
                    relationshipPath =>
                        // Avoid infinite loop
                        relationshipPath.length > rootPath.length)
                .map(
                    relationshipPath =>
                        relationshipPath.entityType
                            .bespoke
                            .getInitializationPathsInViewer(relationshipPath))
                .reduce((a, b) => a.concat(b), []));

        return paths;
    }

    getInitializationPathsInTimeline(entity: Entity,
                                     rootPath: EntityPath): EntityPath[]
    {
        return [
            EntityPath.fromEntity(entity)
                .joinTo(
                    this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Notes,
                    false),
            EntityPath.fromEntity(entity)
                .joinTo(
                    this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Attachments,
                    false)
        ];
    }

    getAddPathsInTimeline(entity: Entity,
                          rootPath: EntityPath): RelatedEntityPath[]
    {
        if (this.allowAddingNoteOrAttachments(entity))
        {
            return [
                new RelatedEntityPath(
                    entity,
                    rootPath
                        .joinTo(
                            this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Notes,
                            false)),
                new RelatedEntityPath(
                    entity,
                    rootPath
                        .joinTo(
                            this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Attachments,
                            false)),
            ];
        }
        else
        {
            return [];
        }
    }

    getListDependencies(): EntityPath[]
    {
        return [];
    }

    getColor(entity: Entity)
    {
        return entity.entityType.getInheritedColor();
    }

    allowIconOrCharactersInAvatar(): boolean
    {
        return false;
    }

    allowChildTypeCreation(): boolean
    {
        return false;
    }

    getCharactersInAvatar(entity: Entity): string
    {
        if (entity.name && entity.name.length > 0)
        {
            return entity.name[0];
        }
        else
        {
            return undefined;
        }
    }

    getUserFriendlyEntityType()
    {
        return this.type;
    }

    getSearchFieldPaths(rootPath: EntityPath): EntityFieldPath[]
    {
        return [
            rootPath.field(this.entityTypeStore.bespoke.types.Entity.Field.Name),
            rootPath.field(this.entityTypeStore.bespoke.types.Entity.Field.Description)
        ];
    }

    setName(entity: Entity,
            name: string,
            commitContext?: CommitContext)
    {
        const nameField = entity.entityType.getInheritedNameField();

        const textFieldToSet =
            nameField
                ||
            entity.entityType
                .getInheritedFields()
                .filter(field => field.type === DataObjectType.Text && field.isDefining)
                .find(() => true);

        if (textFieldToSet)
        {
            if (textFieldToSet.type === DataObjectType.LocalizedText)
            {
                setValueByFieldInEntity(
                    entity,
                    textFieldToSet,
                    {
                        [loadModuleDirectly(Localizer).languageCode]: name,
                    },
                    commitContext
                );
            }
            else
            {
                setValueByFieldInEntity(
                    entity,
                    textFieldToSet,
                    name,
                    commitContext
                );
            }
        }
    }

    showSaveNotification(entity: Entity)
    {
        return true;
    }

    showDeleteNotification(entity: Entity)
    {
        return true;
    }

    getActions(entity: Entity): Action[]
    {
        if (entity.getRelatedEntityByDefinition(true, this.types.Pack.RelationshipDefinition.Entities)?.entityType.isA(this.types.Pack.Data.Type))
        {
            return [
                {
                    id: 'Entity.PushToAllEnvironments',
                    code: 'Entity.PushToAllEnvironments',
                    name: 'Deze waarde pushen naar alle bestaande omgevingen'
                }
            ];
        }
        else
        {
            return [];
        }
    }

    getEventTitle(entity: Entity): string
    {
        return entity.name || entity.entityType.getName();
    }

    hasResourceAvatar(entity: Entity): boolean
    {
        return false;
    }

    getResourceTitle(entity: Entity): string
    {
        return this.getEventTitle(entity);
    }

    getAutomators(entity: Entity): Automator[]
    {
        return [];
    }

    getRepresentation(
        entity: Entity,
        field: EntityField
    ): DataObjectRepresentationProps
    {
        return {};
    }

    getAuditTrailEntities(entity: Entity, types): AuditTrailEntity[]
    {
        return [
            new AuditTrailEntity(entity, entity.entityType.nameSingular)
        ];
    }

    getTimelineDateField(isOpen: boolean): EntityFieldPath
    {
        return EntityPath
            .fromEntityType(this.type)
            .field(this.types.Entity.Field.CreationDate);
    }

    getEmptyOption(): string | undefined
    {
        return undefined;
    }

    getCurrencyFields(): EntityField[]
    {
        return undefined;
    }

    getEntityColorForCalendar(entity: Entity): string
    {
        return getEntityTypeColorForPlanner(entity.entityType);
    }

    getAttachmentSelections(entity: Entity, commitContext?: CommitContext) : Selection[]
    {
        // Return Selections[] to retrieve attachments related to provided entity
        // i.e. used in FileDropZoneWithLibrary to allow selecting attachments
        // Override attachmentEntitySelectionBuilder() per BespokeEntityType to
        // provide EntityType specific implementation
        return [
            entity.entityType.bespoke.attachmentEntitySelectionBuilder(
                new EntitySelectionBuilder(this.types.Attachment.Type),
                entity,
                commitContext
            )
            .build()
        ]
    }

    attachmentEntitySelectionBuilder(
        sb: EntitySelectionBuilder,
        entity: Entity,
        commitContext?: CommitContext
    ) : EntitySelectionBuilder
    {
        // By default include attachments directly related to entity, if not new
        return sb
            .if(
                () => !entity.isNew(),
                cb =>
                    cb.where(
                        ws =>
                            ws.relatedToEntity(
                                EntityPath
                                    .fromEntityType(this.types.Attachment.Type)
                                    .joinTo(
                                        this.types.Entity.RelationshipDefinition.Attachments,
                                        true
                                    ),
                                entity
                            )
                    )
            );
    }

    // ----------------------- Private logic ------------------------

    get types()
    {
        return this.entityTypeStore.bespoke.types;
    }

    onSetValueFromCurrency(
        entity: Entity,
        currencyField: EntityField,
        fieldInCurrency: EntityField,
        valueInCurrency: any,
        field: EntityField,
        commitContext?: CommitContext
    )
    {
        if (valueInCurrency)
        {
            const currency = entity.getObjectValueByField(currencyField, commitContext);
            if (currency)
            {
                const currenciesByCode = loadModuleDirectly(LocalizationStore).localizer.currenciesByCode;
                if (currenciesByCode.has(currency)
                    && currenciesByCode.get(currency).rate > 0)
                {
                    entity.setValueByField(
                        field,
                        valueInCurrency / currenciesByCode.get(currency).rate,
                        undefined,
                        undefined,
                        commitContext
                    )
                }
            }
        }
        else
        {
            entity.setValueByField(
                field,
                undefined,
                undefined,
                undefined,
                commitContext
            )
        }
    }

}
