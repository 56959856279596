import Value from '../Value/Value';
import DeferredValue from '../Value/DeferredValue';
import ValueType from '../Value/Type/ValueType';
import { mapBy } from '../../../@Util/MapUtils/mapBy';

export class DeferredInitializer
{
    // ------------------------- Properties -------------------------

    valuesByType: Map<string, DeferredValue<any>[]>;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        this.valuesByType = new Map();
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    defer(
        descriptor: any,
        valueType: ValueType<any>,
        idFromDescriptor: (descriptor: any) => string,
        idFromValue: (value: Value<any, any>) => string,
        initialize: (descriptors: any[]) => Promise<Value<any, any>[]>
    ): DeferredValue<any>
    {
        const deferredValue =
            new DeferredValue(
                descriptor,
                valueType,
                idFromDescriptor,
                idFromValue,
                initialize
            );

        this.valuesByType.set(
            descriptor.type,
            [
                ...(this.valuesByType.get(descriptor.type) ?? []),
                deferredValue,
            ]
        );

        return deferredValue;
    }

    async initialize()
    {
        await Promise.all(
            Array.from(this.valuesByType.keys())
                .map(
                    type =>
                        this.initializeType(type)
                )
        );

        this.valuesByType.clear();
    }

    private async initializeType(type: string)
    {
        const values = this.valuesByType.get(type)!;
        const someValue = values[0]!;
        const initializedValues =
            await someValue.initialize(
                values.map(
                    value =>
                        value.descriptor
                )
            );
        const initializedValueById =
            mapBy(
                initializedValues,
                initializedValue =>
                    someValue.idFromValue(initializedValue)
            );

        for (const value of values)
        {
            const id = someValue.idFromDescriptor(value.descriptor);
            const initializedValue = initializedValueById.get(id);

            if (initializedValue)
            {
                value.initializedValue = initializedValue;
            }
            else
            {
                throw new Error(`Value of type: ${type} with id: ${id} is not found`);
            }
        }
    }

    // ----------------------- Private logic ------------------------
}
