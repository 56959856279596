import { SelectionNode } from '../../Model/Implementation/SelectionNode';
import { EntityPathNode } from '../../../@Component/Domain/Entity/Path/@Model/EntityPathNode';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityNode } from '../../Model/Implementation/EntityNode';
import { RootNode } from '../../Model/Implementation/RootNode';
import { JoinNode, JoinType } from '../../Model/Implementation/JoinNode';
import { CastNode } from '../../Model/Implementation/CastNode';
import { EntityPathRootNode } from '../../../@Component/Domain/Entity/Path/@Model/Node/EntityPathRootNode';
import { EntityPathJoinNode } from '../../../@Component/Domain/Entity/Path/@Model/Node/EntityPathJoinNode';
import { EntityPathCastNode } from '../../../@Component/Domain/Entity/Path/@Model/Node/EntityPathCastNode';
import { EntityPathStaticNode } from '../../../@Component/Domain/Entity/Path/@Model/Node/EntityPathStaticNode';

export function getSelectionNodeFromPathNode(
    node: EntityPathNode,
    alias: string,
    baseEntityPath: EntityPath,
    entityNodeByPath: Map<string, EntityNode>
): SelectionNode
{
    if (node instanceof EntityPathRootNode)
    {
        return new RootNode(node.entityType, alias);
    }
    else if (node instanceof EntityPathCastNode)
    {
        return new CastNode(
            node.entityType,
            alias,
            entityNodeByPath.get(baseEntityPath.id)
        );
    }
    else if (node instanceof EntityPathJoinNode)
    {
        return new JoinNode(
            alias,
            node.relationshipDefinition.getEntityType(node.isParent),
            entityNodeByPath.get(baseEntityPath.id),
            !node.isParent,
            node.relationshipDefinition,
            JoinType.LeftJoin
        );
    }
    else if (node instanceof EntityPathStaticNode)
    {
        return new JoinNode(
            alias,
            node.entityType,
            entityNodeByPath.get(baseEntityPath.id),
            true,
            null,
            JoinType.LeftJoin
        );
    }
    else
    {
        throw new Error('Unimplemented node type');
    }
}