import { computed, observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../Value/PrimitiveValue';
import ComputationInText from './ComputationInText';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import FunctionContext from '../FunctionContext';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import { getTextFromHtml } from '../../../../@Util/Html/HtmlUtils';
import Value from '../../Value/Value';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import { RichTextType } from '../../../../@Component/Domain/DataObject/Type/RichText/RichTextType';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import { mapBy } from '../../../../@Util/MapUtils/mapBy';
import { mapMap } from '../../../../@Util/MapUtils/mapMap';
import EmptyValue from '../../Value/EmptyValue';

export default class TextComputation extends Computation<PrimitiveValueType, PrimitiveValue>
{
    // ------------------------- Properties -------------------------

    @observable text: string;
    @observable isRichText: boolean;
    @observable.shallow computations: ComputationInText[];

    // ------------------------ Constructor -------------------------

    constructor(text: string,
                isRichText: boolean,
                computations: ComputationInText[])
    {
        super();

        this.text = text;
        this.isRichText = isRichText;
        this.computations = computations;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get computationById()
    {
        return new Map(
            this.computations.map(
                computation => [
                    computation.id,
                    computation
                ]));
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById(this.isRichText ? 'RichText' : 'Text'));
    }

    isAsync(): boolean
    {
        return this.computations.some(
            computation =>
                computation.isAsync()
        );
    }

    async apply(context: FunctionContext): Promise<PrimitiveValue>
    {
        const values =
            await Promise.all(
                this.computations.map(
                    async computation => ({
                        computation,
                        value:
                            await safelyApplyFunction(
                                computation.computation,
                                context
                            ),
                    })
                )
            );
        const valueById =
            mapMap(
                mapBy(
                    values,
                    value =>
                        value.computation.id
                ),
                value =>
                    value.value
            );

        return this.computeValue(valueById);
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        const values =
            this.computations.map(
                computation => ({
                    computation,
                    value:
                        safelySynchronousApplyFunction(
                            computation.computation,
                            context
                        ),
                })
            );
        const valueById =
            mapMap(
                mapBy(
                    values,
                    value =>
                        value.computation.id
                ),
                value =>
                    value.value
            );

        return this.computeValue(valueById);
    }

    private computeValue(
        valueById: Map<string, Value<any, any>>
    ): PrimitiveValue
    {
        const text = this.text === undefined ? '' : this.text;
        const result = this.computeText(text, valueById);

        return new PrimitiveValue(
            DataObject.constructFromTypeIdAndValue(
                this.isRichText
                    ? 'RichText'
                    : 'Text',
                result
            )
        );
    }

    private computeText(
        text: string,
        valueById: Map<string, Value<any, any>>
    ): string
    {
        const textWithSubstitutedComputations =
            this.replaceComputationsInText(
                text,
                valueById
            );

        if (this.isRichText)
        {
            return textWithSubstitutedComputations;
        }
        else
        {
            return getTextFromHtml(textWithSubstitutedComputations);
        }
    }

    private replaceComputationsInText(
        text: string,
        valueById: Map<string, Value<any, any>>
    ): string
    {
        return this.replaceComputationsInTextWithReplacer(
            text,
            computation =>
                valueById.get(computation.id) ?? EmptyValue.instance
        );
    }

    private replaceComputationsInTextWithReplacer(
        text: string,
        replacer: (computation: ComputationInText) => Value<any, any>
    ): string
    {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        let references: Element[];

        do
        {
            references = Array.from(doc.getElementsByTagName('computation'));

            if (references.length > 0)
            {
                const reference = references[0];
                const computationId = reference.getAttribute('data-id');
                const computation = this.computationById.get(computationId);
                const newReference = this.replaceElementWithSpan(reference);

                if (computation)
                {
                    const replacementValue = replacer(computation);

                    newReference.innerHTML = replacementValue.getName() ?? '';
                    this.correctToAvoidNestedPTagsIfNecessary(
                        newReference,
                        replacementValue
                    );
                }
                else
                {
                    newReference.innerHTML = '';
                }
            }
        }
        while (references.length > 0);

        return doc.body.innerHTML;
    }

    private replaceElementWithSpan(element: Element)
    {
        const parent = element.parentNode;

        if (parent)
        {
            const span = document.createElement('span');
            span.innerHTML = element.innerHTML;
            parent.replaceChild(span, element);

            return span;
        }
        else
        {
            return element;
        }
    }

    private correctToAvoidNestedPTagsIfNecessary(
        replacementElement: Element,
        replacementValue: Value<any, any>
    )
    {
        if (this.shouldCorrectTAvoidNestedPTags(replacementElement, replacementValue))
        {
            this.replaceParentParagraphTagWithSpan(replacementElement);
        }
    }

    private shouldCorrectTAvoidNestedPTags(
        replacementElement: Element,
        replacementValue: Value<any, any>
    )
    {
        return this.isRichText
            && this.isParentElementAParagraph(replacementElement)
            && this.isValueRichText(replacementValue);
    }

    private isValueRichText(value: Value<any, any>)
    {
        const type = value.getType();

        return type instanceof PrimitiveValueType
            && type.type instanceof RichTextType;
    }

    private isParentElementAParagraph(element: Element)
    {
        return element.parentElement?.tagName.toLowerCase() === 'p';
    }

    private replaceParentParagraphTagWithSpan(element: Element)
    {
        this.replaceElementWithSpan(element.parentElement);
    }

    getName(): string
    {
        const text = this.text === undefined ? '' : this.text;

        return getTextFromHtml(
            this.replaceComputationsInTextWithReplacer(
                text,
                computation =>
                    new PrimitiveValue(
                        DataObject.constructFromTypeIdAndValue(
                            'Text',
                            computation.computation.getName() ?? ''
                        )
                    )
            )
        );
    }

    validate(): Validation[]
    {
        return this.computations
            .map(
                computation =>
                    computation.validate())
            .reduce((a, b) => a.concat(b), []);
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Text';
        descriptor.text = this.text;
        descriptor.isRichText = this.isRichText;
        descriptor.computations =
            this.computations
                // Ensure that computation is contained in text (sometimes this is not the case)
                .filter(
                    computation =>
                        this.text?.indexOf(computation.id) >= 0)
                .map(
                    computation =>
                        computation.toDescriptor());
    }

    getDependencies(): Dependency[]
    {
        return this.computations
            .map(
                computation =>
                    computation.getDependencies())
            .reduce((a, b) => a.concat(b), []);
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new TextComputation(
            descriptor.text,
            descriptor.isRichText,
            await Promise.all(
                descriptor.computations.map(
                    computation =>
                        ComputationInText.fromDescriptor(
                            computation,
                            dependencyContext))));
    }

    // ----------------------- Private logic ------------------------
}
