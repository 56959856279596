import { type } from '../../@Util/Serialization/Serialization';

export enum SymbolPosition { Prefix = "Prefix", Suffix = "Suffix"}

export const EuroCurrency: Currency = {
    code: 'EUR',
    name: 'Euro',
    symbol: '€',
    symbolPosition: SymbolPosition.Prefix,
    precision: 2,
    isBase: true,
    rate: 1,
    isActive: true
};

@type('Currency')
export class Currency
{
    code: string;
    name: string;
    symbol: string;
    symbolPosition: SymbolPosition;
    precision: number;
    isBase: boolean;
    rate: number;
    isActive: boolean;

    static format(
        value: number,
        languageTag: string,
        currency: Currency
    ): string
    {
        return value === undefined
            ? ''
            : new Intl.NumberFormat(
                languageTag,
                {
                    style: 'currency',
                    currency: currency.code
                }
            ).format(value);
    }
}