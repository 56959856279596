import { useComputed } from 'mobx-react-lite';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import ParameterAssignment from '../../../../../../@Api/Automation/Parameter/ParameterAssignment';

export function useFormInputParameterValue(parameter: Parameter<any>,
                                           parameterAssignment: ParameterAssignment)
{
    return useComputed<DataObject>(
        () =>
            parameterAssignment.getObjectValue(parameter)
            || DataObject.constructFromTypeIdAndValue(
            parameter.type.type.id(),
            undefined),
        [
            parameterAssignment,
            parameter
        ]);
}
