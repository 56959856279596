import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import React, { useMemo } from 'react';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import CampaignPercentageChart from '../../Campaign/Charts/CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import useApsisCampaignSendCount from '../Hooks/useApsisCampaignSendCount';
import useApsisCampaignViewedCount from '../Hooks/useApsisCampaignViewedCount';
import { observer } from 'mobx-react';

export interface ApsisCampaignChartViewedProps
{
    size: number;
    apsisCampaign: Entity;
    caption?: boolean;
}

export const ApsisCampaignChartViewed: React.FC<ApsisCampaignChartViewedProps> =
    observer(
        (
            {
                apsisCampaign,
                size
            }
        ) =>
        {
            const totalResultCount =
                useApsisCampaignSendCount(
                    apsisCampaign
                );

            const viewedCount =
                useApsisCampaignViewedCount(
                    apsisCampaign
                );

            const allSet =
                useAllSet(
                    [
                        totalResultCount,
                        viewedCount
                    ]
                );

            const formattedSelection =
                useMemo(
                    () =>
                    {
                        return `${viewedCount} / ${totalResultCount}`;
                    },
                    [
                        viewedCount,
                        totalResultCount
                    ]
                );

            return <div>
                {
                    allSet &&
                    <CampaignPercentageChart
                        value={viewedCount / totalResultCount * 100}
                        size={size}
                        label={localizeText('ApsisCampaign.Chart.Opened', 'Geopend')}
                        selection={formattedSelection}
                    />
                }
            </div>;
        }
    );