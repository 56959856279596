import Layout from '../../Layout';
import Dependency from '../../../Automation/Parameter/Dependency';
import Validation from '../../../Automation/Validation/Validation';
import { computed, observable } from 'mobx';
import LayoutDependencyContext from '../../LayoutDependencyContext';
import localizeText from '../../../Localization/localizeText';
import getTableDimensionSectionByDescriptor from './Api/getTableDimensionSectionByDescriptor';
import TableDimensionSection from './Model/TableDimensionSection';
import TableCell from './Model/TableCell';
import getDependenciesWithoutParameters from '../../../Automation/Api/getDependenciesWithoutParameters';
import ChildTable from './Model/ChildTable';

export default class TableLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.shallow rowSections: TableDimensionSection[];
    @observable.shallow columnSections: TableDimensionSection[];
    @observable.shallow cells: TableCell[];
    @observable.shallow childTables: ChildTable[];

    // ------------------------ Constructor -------------------------

    constructor(
        rowSections: TableDimensionSection[],
        columnSections: TableDimensionSection[],
        cells: TableCell[],
        childTables: ChildTable[]
    )
    {
        super();

        this.rowSections = rowSections;
        this.columnSections = columnSections;
        this.cells = cells;
        this.childTables = childTables;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    @computed
    get cellByLocationId(): Map<string, TableCell>
    {
        return new Map(
            this.cells.map(
                cell => [
                    cell.location.id,
                    cell
                ]));
    }

    @computed
    get cellsBySectionId(): Map<string, TableCell[]>
    {
        const cellsBySectionId = new Map<string, TableCell[]>();
        const setOrAdd =
            (key: string, cell: TableCell) =>
            {
                if (cellsBySectionId.has(key))
                {
                    cellsBySectionId.get(key).push(cell);
                }
                else
                {
                    cellsBySectionId.set(key, [ cell ]);
                }
            };

        for (const cell of this.cells)
        {
            setOrAdd(cell.location.rowId, cell);
            setOrAdd(cell.location.columnId, cell);
        }

        return cellsBySectionId;
    }

    getName(): string
    {
        return localizeText('Generic.Table', 'Tabel');
    }

    validate(): Validation[]
    {
        return [
            ...this.rowSections
                .map(section => section.validate())
                .reduce((a, b) => a.concat(b), []),
            ...this.columnSections
                .map(section => section.validate())
                .reduce((a, b) => a.concat(b), []),
            ...this.cells
                .map(cell => cell.validate())
                .reduce((a, b) => a.concat(b), [])
        ];
    }

    getDependencies(): Dependency[]
    {
        const rowAndColumnSectionParameters = [
            ...this.rowSections,
            ...this.columnSections
        ].map(
            section =>
                section.getParameters())
            .reduce((a, b) => a.concat(b), []);

        return [
            ...this.rowSections
                .map(section => section.getDependencies())
                .reduce((a, b) => a.concat(b), []),
            ...this.columnSections
                .map(section => section.getDependencies())
                .reduce((a, b) => a.concat(b), []),
            ...getDependenciesWithoutParameters(
                this.cells
                    .map(cell => cell.getDependencies())
                    .reduce((a, b) => a.concat(b), []),
                ...rowAndColumnSectionParameters)
        ];
    }

    toDescriptor()
    {
        return {
            type: 'Table',
            rowSections: this.rowSections.map(section => section.toDescriptor()),
            columnSections: this.columnSections.map(section => section.toDescriptor()),
            cells: this.cells.map(cell => cell.toDescriptor()),
            childTables: this.childTables.map(childTable => childTable.toDescriptor())
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const rowSections =
            await Promise.all<TableDimensionSection>(
                descriptor.rowSections.map(
                    section =>
                        getTableDimensionSectionByDescriptor(
                            section,
                            dependencyContext
                        )
                )
            );
        const columnSections =
            await Promise.all<TableDimensionSection>(
                descriptor.columnSections.map(
                    section =>
                        getTableDimensionSectionByDescriptor(
                            section,
                            dependencyContext
                        )
                )
            );
        const dimensionSectionById =
            new Map<string, TableDimensionSection>([
                ...rowSections,
                ...columnSections
            ].map(section => [ section.id, section ]));
        const cells =
            await Promise.all<TableCell>(
                descriptor.cells.map(
                    cell =>
                        TableCell.fromDescriptor(
                            cell,
                            dependencyContext,
                            dimensionSectionById
                        )
                )
            );
        const childTables =
            await Promise.all<ChildTable>(
                (descriptor.childTables ?? []).map(
                    childTable =>
                        ChildTable.fromDescriptor(
                            childTable,
                            dependencyContext,
                            dimensionSectionById
                        )
                )
            );

        return new TableLayout(
            rowSections,
            columnSections,
            cells,
            childTables
        );
    }

    // ----------------------- Private logic ------------------------
}
