import { type } from '../../../@Util/Serialization/Serialization';
import { EntityTypeMutation } from './EntityTypeMutation';

@type('EntityTypeUpdateMutation')
export class EntityTypeUpdateMutation extends EntityTypeMutation
{
    // ------------------- Persistent Properties --------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
