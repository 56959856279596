import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import EffectLayout from '../../../../../@Api/Layout/Type/EffectLayout';
import { Icon, makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

export interface EffectLayoutEditorProps extends LayoutEditorProps<EffectLayout>
{

}

const useStyles =
    makeStyles({
        root: {
            width: '100%',
        },
    }
);

const EffectLayoutEditor: React.FC<EffectLayoutEditorProps> =
    props =>
    {
        const classes = useStyles();

        return <Chip
            icon={
                <Icon>
                    cached
                </Icon>
            }
            label="Effect"
            className={classes.root}
            color="primary"
        />;
    };

export default observer(EffectLayoutEditor);
