import React from 'react';
import { BlockEditProps } from '@wordpress/blocks';
import { PanelBody, PanelRow, TextControl } from '@wordpress/components';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import LocalizedText from '../../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';


export interface VideoOptionsPanelProps
{
    props: BlockEditProps<{}>;
    open?: boolean;
}

const VideoOptionsPanel: React.FC<VideoOptionsPanelProps> =
    props =>
    {
        const {
            videoUrl
        } = props.props.attributes as any;

        return <PanelBody
            title={localizeText('Gutenberg.VideoOptions.Title', 'Video')}
            initialOpen={!!props.open}
        >
            <PanelRow>
                <TextControl
                    name="videoUrl"
                    label={
                        <LocalizedText
                            code="Gutenberg.VideoOptions.VideoURL"
                            value="YouTube URL"
                        />
                    }
                    value={videoUrl}
                    onChange={videoUrl => props.props.setAttributes({ videoUrl: videoUrl }) }
                />
            </PanelRow>
        </PanelBody>;
    };

export default VideoOptionsPanel;
