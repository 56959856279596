import React, { useContext, useMemo, useState } from 'react';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import getLayoutFromDescriptor from '../../../../../../../@Api/Layout/Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../../../@Api/Layout/LayoutDependencyContext';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import CompositeLayout from '../../../../../../../@Api/Layout/Type/CompositeLayout';
import LayoutEditor from '../../../../../Layout/Editor/LayoutEditor';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import ExpressionEditorContext from '../../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Expression/ExpressionEditorContext';
import useSwitch from '../../../../../../../@Util/Switch/useSwitch';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import { makeStyles } from '@material-ui/styles';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import CancelButton from '../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import uuid from '../../../../../../../@Util/Id/uuid';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { primaryColor } from '../../../../../../../@Resource/Theme/Theme';
import { getParametersFromEntityContext } from '../Api/getParametersFromEntityContext';
import { getParameterAssignmentFromEntityContext } from '../Api/getParameterAssignmentFromEntityContext';
import ParameterAssignment from '../../../../../../../@Api/Automation/Parameter/ParameterAssignment';

export interface LayoutBlockEditorProps
{
    value: any;
    onChange: (value: any) => void;
}

const useStyles =
    makeStyles({
        root: {

        },
        viewMode: {
            position: 'relative',
            cursor: 'pointer',
            '&:hover $viewModeBackdrop': {
                display: 'flex',
            },
        },
        viewModeBackdrop: {
            display: 'none',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backdropFilter: 'blur(2px)',
            zIndex: 1,
            pointerEvents: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500,
            color: primaryColor,
            borderRadius: 5,
            border: `1px solid ${primaryColor}`,
        },
        editMode: {

        }
    });

const LayoutBlockEditor: React.FC<LayoutBlockEditorProps> =
    ({
        value,
        onChange,
     }) =>
    {
        const classes = useStyles();
        const expressionContext = useContext(ExpressionEditorContext);
        const entityContext = expressionContext?.entityContext;
        const parameters =
            useMemo(
                () =>
                    entityContext
                        ? getParametersFromEntityContext(entityContext)
                        : new ParameterDictionary([]),
                [
                    entityContext
                ]
            );
        const parameterAssignment =
            useMemo(
                () =>
                    entityContext
                        ? getParameterAssignmentFromEntityContext(entityContext, parameters)
                        : new ParameterAssignment(),
                [
                    entityContext,
                    parameters,
                ]
            );
        const [ loadKey, setLoadKey ] = useState(() => uuid());
        const [ layout, isLoading ] =
            useAsyncResult(
                async () =>
                {
                    if (value)
                    {
                        return await getLayoutFromDescriptor(
                            value,
                            new LayoutDependencyContext(
                                parameters
                            )
                        );
                    }
                    else
                    {
                        return new CompositeLayout(
                            'Vertical',
                            []
                        );
                    }
                },
                [
                    value,
                    parameters,
                    loadKey,
                ]
            );
        const [ isInEditMode, startEditing, stopEditing ] = useSwitch(value === undefined);

        if (isLoading)
        {
            return <Centered
                horizontal
            >
                <Loader />
            </Centered>
        }
        else
        {
            return <div
                className={
                    classNames(
                        classes.root,
                        isInEditMode ? classes.editMode : classes.viewMode
                    )
                }
                onClick={startEditing}
            >
                {
                    !isInEditMode &&
                        <div
                            className={classes.viewModeBackdrop}
                        >
                            <div>
                                <LocalizedText
                                    code="Generic.Edit"
                                    value="Wijzigen"
                                />
                            </div>
                        </div>
                }
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <div
                            style={{
                                pointerEvents: isInEditMode ? undefined : 'none',
                            }}
                        >
                            <LayoutEditor
                                layout={layout}
                                parameterDictionary={parameters}
                                parameterAssignment={parameterAssignment}
                            />
                        </div>
                    </ViewGroupItem>
                    {
                        isInEditMode &&
                            <ViewGroupItem>
                                <CardInset>
                                    <RightAlignedButtonGroup>
                                        <CancelButton
                                            onClick={
                                                () =>
                                                {
                                                    setLoadKey(uuid());
                                                    stopEditing();
                                                }
                                            }
                                        />
                                        <SaveButton
                                            onClick={
                                                () =>
                                                {
                                                    onChange(layout.toDescriptor());
                                                    stopEditing();
                                                }
                                            }
                                        />
                                    </RightAlignedButtonGroup>
                                </CardInset>
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </div>;
        }
    };

export default LayoutBlockEditor;
