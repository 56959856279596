import React from 'react';
import useAsyncResult from '../../@Util/Async/useAsyncResult';
import { setupSentry } from './Setup/setupSentry';
import { setupMobx } from './Setup/setupMobx';
import { setupGclid } from './Setup/setupGclid';
import { setupRootStore } from './Setup/setupRootStore';
import { setupOfficeAddIn } from './Setup/setupOfficeAddIn';
import { Root } from './Root/Root';
import { ThemeProvider } from './ThemeProvider/ThemeProvider';
import ErrorBoundary from '../Error/ErrorBoundary';

export interface AppProps
{

}

export const App: React.FC<AppProps> =
    () =>
    {
        const [ rootStore, isLoading ] =
            useAsyncResult(
                async () =>
                {
                    setupSentry();
                    setupMobx();
                    setupGclid();

                    await setupOfficeAddIn();

                    return setupRootStore();
                },
                []
            );

        if (isLoading)
        {
            return null;
        }
        else
        {
            return <ThemeProvider>
                <ErrorBoundary
                    centered
                >
                    <Root
                        store={rootStore}
                    />
                </ErrorBoundary>
            </ThemeProvider>;
        }
    };
