import React from 'react';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import StaticSelectbox from '../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import { RecurrencePatternEndEditorProps } from '../RecurrencePatternEndEditor';

export const RecurrencePatternEndTypeEditor: React.FC<RecurrencePatternEndEditorProps> =
    ({
        value,
        onChange,
     }) =>
    {
        return <Input
            label={
                <LocalizedText
                    code="Generic.End"
                    value="Einde"
                />
            }
            labelPosition="left"
        >
            <StaticSelectbox
                value={value.type}
                onChange={
                    newValue =>
                    {
                        if (newValue === 'No')
                        {
                            return onChange({
                                type: 'No',
                            });
                        }
                        else if (newValue === 'Date')
                        {
                            return onChange({
                                type: 'Date',
                                date: new Date().toISOString(),
                            });
                        }
                        else if (newValue === 'Numbered')
                        {
                            return onChange({
                                type: 'Numbered',
                                numberOfOccurrences: 1,
                            });
                        }
                    }
                }
                options={[
                    {
                        id: 'No',
                        value: 'No',
                        label: localizeText('Generic.None', 'Geen'),
                    },
                    {
                        id: 'Date',
                        value: 'Date',
                        label: localizeText('Generic.Date', 'Datum'),
                    },
                    {
                        id: 'Numbered',
                        value: 'Numbered',
                        label: localizeText('NumberOfTimes', 'Aantal keer'),
                    },
                ]}
            />
        </Input>;
    };
