import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import { runInAction } from 'mobx';
import LinkComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/LinkComputation';
import InputGroup from '../../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { loadModuleDirectly } from '../../../../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../../../../../DataObject/DataObjectStore';

export interface LinkComputationEditorProps extends ComputationEditorProps<PrimitiveValueType, LinkComputation>
{

}

const LinkComputationEditor: React.FC<LinkComputationEditorProps> =
    props =>
    {
        const setText =
            useCallback(
                (value: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.text = value),
                [
                    props.value
                ]);

        const setUrl =
            useCallback(
                (value: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.url = value),
                [
                    props.value
                ]);

        const textType =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        loadModuleDirectly(DataObjectStore)
                            .getTypeById('Text')),
                []);

        const urlType =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        loadModuleDirectly(DataObjectStore)
                            .getTypeById('Url')),
                []);

        return <InputGroup>
            <Input
                labelPosition="left"
                label="Linktekst"
            >
                <ComputationEditor
                    {...props}
                    type={textType}
                    value={props.value.text}
                    onChange={setText}
                />
            </Input>
            <Input
                labelPosition="left"
                label="URL"
            >
                <ComputationEditor
                    {...props}
                    type={urlType}
                    value={props.value.url}
                    onChange={setUrl}
                />
            </Input>
        </InputGroup>;
    };

export default observer(LinkComputationEditor);
