import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import { default as ResourcePlanner } from '../Model/ResourcePlanner';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { EntityPath } from '../../Path/@Model/EntityPath';
import getTypes from '../../Type/Api/getTypes';
import { Comparator } from '../../../DataObject/Model/Comparator';
import EmptyValue from '../../../../../@Api/Automation/Value/EmptyValue';
import ResourceSelection from '../Model/ResourceSelection';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';

export function getTimeRegistrationsForMilestone(
    timeRegistrationParameter: Parameter<EntityValueType>,
    projectParameter: Parameter<EntityValueType>,
    milestoneParameter: Parameter<EntityValueType>,
    employee: EntityValue
)
{
    const types= getTypes();

    return new ResourcePlanner(
        timeRegistrationParameter,
        new ParameterDictionary([]),
        ({
            startDate,
            endDate,
        }) =>
            new CompositePredicate(
                LogicalOperator.And,
                [
                    // TimeRegistration related to project
                    new ComparisonPredicate(
                        new ValueFromEntityComputation(
                            timeRegistrationParameter,
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.TimeRegistrations, true
                                )
                                .field()
                        ),
                        Comparator.Equals,
                        projectParameter
                    ),
                    // TimeRegistration related to milestone
                    new ComparisonPredicate(
                        new ValueFromEntityComputation(
                            timeRegistrationParameter,
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .joinTo(
                                    types.Milestone.RelationshipDefinition.TimeRegistrations, true
                                )
                                .field()
                        ),
                        Comparator.Equals,
                        milestoneParameter
                    ),
                    // TimeRegistration related to current user
                    new ComparisonPredicate(
                        new ValueFromEntityComputation(
                            timeRegistrationParameter,
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                    true
                                )
                                .field()
                        ),
                        Comparator.Equals,
                        employee
                    ),
                    // Time registration overlaps with time window
                    new ComparisonPredicate(
                        new ValueFromEntityComputation(
                            timeRegistrationParameter,
                            timeRegistrationParameter.type.type.path().field(types.TimeRegistration.Field.StartDate)
                        ),
                        Comparator.LessThan,
                        endDate
                    ),
                    new CompositePredicate(
                        LogicalOperator.Or,
                        [
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    timeRegistrationParameter,
                                    timeRegistrationParameter.type.type.path().field(types.TimeRegistration.Field.EndDate)
                                ),
                                Comparator.IsNotDefined,
                                EmptyValue.instance
                            ),
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    timeRegistrationParameter,
                                    timeRegistrationParameter.type.type.path().field(types.TimeRegistration.Field.EndDate)
                                ),
                                Comparator.GreaterThanOrEqual,
                                startDate
                            ),
                        ]
                    ),
                ]
            ),

        // Child ResourceSelection: TimeRegistrations related to CurrentUser and Milestone to show on TimeRegistration row
        [
            types.TimeRegistration.Type &&
            new ResourceSelection(
                timeRegistrationParameter,
                EntityPath.fromEntityType(types.TimeRegistration.Type),
                EntityPath.fromEntityType(types.TimeRegistration.Type)
                    .field(types.TimeRegistration.Field.StartDate),
                EntityPath.fromEntityType(types.TimeRegistration.Type)
                    .field(types.TimeRegistration.Field.EndDate),
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                [
                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                        .joinTo(types.Milestone.RelationshipDefinition.TimeRegistrations, false)
                        .field(),
                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                        .joinTo(types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations, true)
                        .field(),
                ]
            ),
        ].filter(selection => selection),
    );
}
