import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Parchment from 'parchment';
import { ExpressionEditorStoreById } from '../../../Domain/Expression/Editor/ExpressionEditorStore';
import { ComputationWidget } from './ComputationWidget';

const ReactQuill = require('react-quill');
const {Quill} = ReactQuill;

const EmbedBlock = Quill.import('blots/embed') as typeof Parchment.default.Embed;

export class ComputationBlot extends EmbedBlock
{
    static blotName = 'Computation';
    static tagName = 'computation';

    constructor(domNode: Node, value?: any)
    {
        super(domNode);

        let htmlElement = domNode as HTMLElement;

        const editorStore = ExpressionEditorStoreById.get(value);

        if (htmlElement.firstElementChild)
        {
            if (editorStore)
            {
                ReactDOM.render(
                    React.createElement(
                        ComputationWidget,
                        {
                            store: editorStore.getComputationWidgetStoreById(value)
                        } as any),
                    htmlElement.firstElementChild);
            }
        }
    }

    static create(value: any)
    {
        let node = super.create(value) as any; // HTMLElement;
        node.setAttribute('data-id', value);
        node.setAttribute('contenteditable', 'false');

        return node;
    }

    static value(domNode: any)
    {
        return domNode.dataset.id;
    }

    static formats(domNode: any)
    {
        return true;
    }
}
