import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldValueEditorProps } from '../FieldValueEditor';
import Selectbox from '../../../../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import performAction from '../../../../../../../../../@Api/Entity/performAction';
import FormFieldOption from '../../../../../Model/FormFieldOption';

export interface OptionValueEditorProps extends FieldValueEditorProps
{

}

const OptionValueEditor: React.FC<OptionValueEditorProps> =
    props =>
    {
        const load =
            useCallback(
                (query: string) =>
                    performAction<FormFieldOption[]>(
                        undefined,
                        {
                            code: 'Form.GetFieldOptions',
                            parameters: {
                                formId: props.form.id,
                                fieldId: props.field.id,
                                searchQuery: query
                            }
                        })
                        .then(
                            result =>
                                Promise.resolve(
                                    result.result
                                )
                        ),
                [
                    props.form,
                    props.field
                ]);

        const idResolver =
            useCallback(
                (option: FormFieldOption) =>
                    option.id,
                []);

        const formatOption =
            useCallback(
                (option: FormFieldOption) =>
                    option.name,
                []);

        return <Selectbox
            load={load}
            idResolver={idResolver}
            formatOption={formatOption}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            multi={props.field.isPlural}
            disabled={props.disabled}
            clearable
        />;
    };

export default observer(OptionValueEditor);
