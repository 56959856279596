import Dependency from '../../../../Automation/Parameter/Dependency';
import Validation from '../../../../Automation/Validation/Validation';
import { computed, observable } from 'mobx';
import LayoutDependencyContext from '../../../LayoutDependencyContext';
import getTableDimensionSectionByDescriptor from '../Api/getTableDimensionSectionByDescriptor';
import TableDimensionSection from './TableDimensionSection';
import TableCell from './TableCell';
import getDependenciesWithoutParameters from '../../../../Automation/Api/getDependenciesWithoutParameters';
import { TableDimension } from './TableDimension';
import { concatMaps } from '../../../../../@Util/MapUtils/concatMaps';
import ParameterDictionary from '../../../../Automation/Parameter/ParameterDictionary';

export default class ChildTable
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable dimension: TableDimension;
    @observable parentSection: TableDimensionSection;
    @observable.shallow sections: TableDimensionSection[];
    @observable.shallow cells: TableCell[];

    // ------------------------ Constructor -------------------------

    constructor(
        id: string,
        dimension: TableDimension,
        parentSection: TableDimensionSection,
        sections: TableDimensionSection[],
        cells: TableCell[]
    )
    {
        this.id = id;
        this.dimension = dimension;
        this.parentSection = parentSection;
        this.sections = sections;
        this.cells = cells;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    @computed
    get cellByLocationId(): Map<string, TableCell>
    {
        return new Map(
            this.cells.map(
                cell => [
                    cell.location.id,
                    cell
                ]));
    }

    @computed
    get cellsBySectionId(): Map<string, TableCell[]>
    {
        const cellsBySectionId = new Map<string, TableCell[]>();
        const setOrAdd =
            (key: string, cell: TableCell) =>
            {
                if (cellsBySectionId.has(key))
                {
                    cellsBySectionId.get(key).push(cell);
                }
                else
                {
                    cellsBySectionId.set(key, [ cell ]);
                }
            };

        for (const cell of this.cells)
        {
            setOrAdd(cell.location.rowId, cell);
            setOrAdd(cell.location.columnId, cell);
        }

        return cellsBySectionId;
    }

    validate(): Validation[]
    {
        return [
            ...this.sections
                .map(section => section.validate())
                .reduce((a, b) => a.concat(b), []),
            ...this.cells
                .map(cell => cell.validate())
                .reduce((a, b) => a.concat(b), [])
        ];
    }

    getDependencies(): Dependency[]
    {
        const rowAndColumnSectionParameters =
            this.sections.map(
                section =>
                    section.getParameters()
            )
            .reduce((a, b) => a.concat(b), []);

        return [
            ...this.sections
                .map(section => section.getDependencies())
                .reduce((a, b) => a.concat(b), []),
            ...getDependenciesWithoutParameters(
                this.cells
                    .map(cell => cell.getDependencies())
                    .reduce((a, b) => a.concat(b), []),
                ...rowAndColumnSectionParameters
            )
        ];
    }

    toDescriptor()
    {
        return {
            id: this.id,
            dimension: this.dimension,
            parentSectionId: this.parentSection.id,
            sections: this.sections.map(section => section.toDescriptor()),
            cells: this.cells.map(cell => cell.toDescriptor())
        };
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: LayoutDependencyContext,
        parentDimensionSectionById: Map<string, TableDimensionSection>
    )
    {
        const id = descriptor.id;
        const dimension = descriptor.dimension;
        const parentSectionId = descriptor.parentSectionId;
        const parentSection = parentDimensionSectionById.get(parentSectionId);
        const childDependencyContext =
            dependencyContext.withParameterDictionary(
                ParameterDictionary.union(
                    dependencyContext.parameterDictionary,
                    new ParameterDictionary(
                        parentSection.getParameters()
                    )
                )
            );
        const sections =
            await Promise.all<TableDimensionSection>(
                descriptor.sections.map(
                    section =>
                        getTableDimensionSectionByDescriptor(
                            section,
                            childDependencyContext
                        )
                )
            );
        const dimensionSectionById =
            new Map<string, TableDimensionSection>(
                sections.map(
                    section => [
                        section.id,
                        section,
                    ]
                )
            );
        const mergedDimensionSectionById =
            concatMaps(
                parentDimensionSectionById,
                dimensionSectionById
            );
        const cells =
            await Promise.all<TableCell>(
                descriptor.cells.map(
                    cell =>
                        TableCell.fromDescriptor(
                            cell,
                            childDependencyContext,
                            mergedDimensionSectionById
                        )
                )
            );

        return new ChildTable(
            id,
            dimension,
            parentSection,
            sections,
            cells
        );
    }

    // ----------------------- Private logic ------------------------
}
