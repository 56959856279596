import { reference, type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';
import { SettingDefinition } from './SettingDefinition';

@type('Setting')
export class Setting
{
    // ------------------- Persistent Properties --------------------

    @observable id: number;
    @reference(undefined, 'SettingDefinition') @observable.ref settingDefinition: SettingDefinition;
    @observable parent: number;
    @observable.ref value: any;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
