import React, { useMemo } from 'react';

export interface FlagProps
{
    countryCode?: string;
    languageCode?: string;
}

const Flag: React.FC<FlagProps> =
    props =>
    {
        const countryCode =
            useMemo(
                () =>
                {
                    if (props.countryCode)
                    {
                        return props.countryCode;
                    }
                    else
                    {
                        switch (props.languageCode)
                        {
                            case 'da':
                                return 'dk';

                            case 'en':
                                return 'gb';

                            case 'sv':
                                return 'se';

                            default:
                                return props.languageCode;
                        }
                    }
                },
                [
                    props.countryCode,
                    props.languageCode,
                ]);

        return <img
            src={`https://flagcdn.com/16x12/${countryCode}.png`}
            srcSet={`https://flagcdn.com/32x24/${countryCode}.png 2x, https://flagcdn.com/48x36/${countryCode}.png 3x`}
            width="16"
            height="12"
            alt={props.languageCode}
            title={props.languageCode}
        />;
    };

export default Flag;
