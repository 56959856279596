import * as React from 'react';
import { Typography } from '@material-ui/core';
import { DataObjectBespokeViewer } from '../../../../Viewer/DataObjectBespokeViewer';
import { DataObjectViewerStore } from '../../../../Viewer/DataObjectViewerStore';
import { observer } from 'mobx-react';

@observer
export class CurrencyRangeView extends DataObjectBespokeViewer
{
    renderComponent(store: DataObjectViewerStore)
    {
        return <Typography variant="subtitle1">
            {store.dataObject.toString(store.dataObjectRepresentation)}
        </Typography>;
    }
}
