import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../../../../../../@Api/Model/Implementation/Entity';
import FieldView from '../../../../../../../../../../Field/View/FieldView';
import useTypes from '../../../../../../../../../../Type/Api/useTypes';
import ViewGroupItem from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import LocalizedText from '../../../../../../../../../../../Localization/LocalizedText/LocalizedText';
import InputIcon from '../../../../../../../../../../../../../@Future/Component/Generic/Input/Input/Icon/InputIcon';
import Tooltip from '../../../../../../../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';

export interface ContactProps
{
    entity: Entity;
}

const Contact: React.FC<ContactProps> =
    props =>
    {
        const types = useTypes();

        return <ViewGroup
            orientation="horizontal"
            spacing={5}
            alignment="center"
        >
            <ViewGroupItem>
                <Tooltip
                    title={
                        <LocalizedText
                            code="Generic.Contact"
                            value="Contactpersoon"
                        />
                    }
                >
                    <InputIcon
                        icon={types.Activity.RelationshipDefinition.Contact.parentEntityType.getInheritedIcon() ?? 'person'}
                    />
                </Tooltip>
            </ViewGroupItem>
            <ViewGroupItem>
                <FieldView
                    entity={props.entity}
                    field={types.Activity.RelationshipDefinition.Contact}
                    parent
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(Contact);
