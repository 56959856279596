import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from './EntityPath';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationship } from '../../../../../@Api/Model/Implementation/EntityRelationship';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';

export abstract class EntityPathNode
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract id(): string;

    abstract code(): string;

    abstract getName(entityTypeStore: EntityTypeStore): string;

    abstract getEntityType(baseEntityType: EntityType): EntityType;

    abstract joinNode(baseEntityPath: EntityPath): EntityPath;

    abstract inverseNode(baseEntityType: EntityType): EntityPathNode;

    abstract isVirtual(): boolean;

    abstract traverseEntity(entity: Entity,
                            commitContext?: CommitContext,
                            onRelationship?: (relationship: EntityRelationship, isParent: boolean) => void): Entity[];

    abstract constructEntity(baseEntity: Entity,
                             forceCreation: boolean,
                             addRelationshipToBase: boolean,
                             entityType: EntityType,
                             commitContext?: CommitContext): Entity;

    abstract descriptor(): any;

    equals(otherNode: EntityPathNode)
    {
        return this.id === otherNode.id;
    }

    // ----------------------- Private logic ------------------------
}
