import React from 'react';
import { observer } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import LabelLayout from '../../../../../@Api/Layout/Type/LabelLayout';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';

export interface LabelLayoutViewerProps extends LayoutViewerProps<LabelLayout>
{
    label?: React.ReactNode;
    view?: React.ReactNode;
}

const LabelLayoutViewer: React.FC<LabelLayoutViewerProps> =
    props =>
    {
        return <Input
            label={
                props.label ||
                    <LayoutViewer
                        {...props}
                        layout={props.layout.label}
                    />
            }
            labelPosition={props.layout.labelPosition.toLowerCase() as any}
        >
            {
                props.view ||
                    <LayoutViewer
                        {...props}
                        layout={props.layout.view}
                    />
            }
        </Input>;
    };

export default observer(LabelLayoutViewer);
