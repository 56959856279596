import { observable } from 'mobx';
import { Computation } from '../../../Computation/Type/Computation';
import { Comparator } from '../../../DataObject/Model/Comparator';
import { OldPredicate } from '../OldPredicate';
import NewComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';

export class OldComparisonPredicate extends OldPredicate
{
    // ------------------------- Properties -------------------------

    @observable lhs: Computation;
    @observable comparator: Comparator;
    @observable rhs: Computation;

    // ------------------------ Constructor -------------------------

    constructor(lhs: Computation,
                comparator: Comparator,
                rhs?: Computation)
    {
        super('Comparison');

        this.lhs = lhs;
        this.comparator = comparator;
        this.rhs = rhs;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getTrueStatements(): OldPredicate[]
    {
        return [ this ];
    }

    getLeafs(): OldPredicate[]
    {
        return [ this ];
    }

    async toNewPredicate(context: AutomationDependencyContext)
    {
        return new NewComparisonPredicate(
            await this.lhs?.toNewComputation(context),
            this.comparator,
            await this.rhs?.toNewComputation(context)
        );
    }

    async initialize(): Promise<any>
    {
        await Promise.all([
            this.lhs?.initialize(),
            this.rhs?.initialize()
        ]);
    }

    // ----------------------- Private logic ------------------------
}
