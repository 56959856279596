import React from 'react';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntityField } from '../../../../../@Api/Model/Implementation/EntityField';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { TextType } from '../../../DataObject/Type/Text/TextType';
import { DataObjectRepresentation } from '../../../DataObject/Model/DataObjectRepresentation';
import EntityLink from '../../Link/EntityLink';
import RichText from '../../../../Generic/RichText/RichText';
import { RichTextType } from '../../../DataObject/Type/RichText/RichTextType';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { deduplicateArray } from '../../../../../@Util/Array/deduplicateArray';
import { UrlType } from '../../../DataObject/Type/Text/Url/UrlType';
import Link from '../../../../../@Future/Component/Generic/Link/Link';
import resolveUrlFromUserInput from '../../../../../@Util/Url/resolveUrlFromUserInput';
import openWindow from '../../../../../@Util/Window/openWindow';

export interface FieldViewProps
{
    entity: Entity;
    field: EntityFieldPath | EntityField | EntityRelationshipDefinition;
    parent?: boolean;
    compact?: boolean;
    richText?: boolean;
    commitContext?: CommitContext;
}

const FieldView: React.FC<FieldViewProps> =
    props =>
    {
        const fieldPath =
            useComputed(
                () =>
                {
                    if (props.field instanceof EntityFieldPath)
                    {
                        return props.field;
                    }
                    else if (props.field instanceof EntityField)
                    {
                        return EntityPath.fromEntity(props.entity).field(props.field);
                    }
                    else if (props.field instanceof EntityRelationshipDefinition)
                    {
                        return EntityPath.fromEntity(props.entity)
                            .joinTo(props.field, props.parent)
                            .field();
                    }
                },
                [
                    props.entity,
                    props.field,
                    props.parent
                ]);

        const label =
            useComputed(
                () =>
                {
                    if (fieldPath.isField)
                    {
                        const value = fieldPath.getDataObjectValue(props.entity, props.commitContext);

                        if (value)
                        {
                            if ((value.specification.type instanceof TextType
                                && value.specification.data.isRichText)
                                || value.specification.type instanceof RichTextType
                                || props.richText)
                            {
                                return <RichText
                                    value={value.value}
                                />;
                            }
                            else if (value.specification.type instanceof UrlType)
                            {
                                return <Link
                                    onClick={
                                        () =>
                                            openWindow(
                                                resolveUrlFromUserInput(value.value),
                                                '_blank'
                                            )
                                    }
                                >
                                    {value.value}
                                </Link>
                            }
                            else
                            {
                                const baseEntity =
                                    fieldPath.path
                                        .traverseEntity(props.entity, props.commitContext)
                                        .find(() => true);

                                return value.toString(
                                    new DataObjectRepresentation({
                                        ...baseEntity?.entityType.bespoke.getRepresentation(
                                            baseEntity,
                                            fieldPath.field
                                        ),
                                        isCompact: props.compact
                                    }));
                            }
                        }
                    }
                    else if (fieldPath.isRelationship)
                    {
                        const relatedEntities =
                            deduplicateArray(
                                fieldPath.path.traverseEntity(props.entity, props.commitContext),
                                entity => entity.uuid
                            );

                        if (relatedEntities.length === 0)
                        {
                            if (fieldPath.relationshipDefinition.isSingular(fieldPath.isParentRelationship))
                            {
                                return fieldPath.path.entityType.bespoke.getEmptyOption();
                            }
                            else
                            {
                                return null;
                            }
                        }
                        else
                        {
                            return <>
                                {
                                    relatedEntities
                                        .map(
                                            (entity, idx) =>
                                                <span
                                                    key={entity.uuid}
                                                >
                                                {idx > 0 ? ', ' : ''} <EntityLink entity={entity} stopPropagation />
                                            </span>)
                                }
                            </>;
                        }
                    }
                },
                [
                    props.entity,
                    props.commitContext,
                    fieldPath,
                    props.compact,
                    props.richText,
                ]);

        if (label)
        {
            return <span>
                {label}
            </span>;
        }
        else
        {
            return null;
        }
    };

export default observer(FieldView);
