import { useMemo } from 'react';
import useTypes from '../../../../../Type/Api/useTypes';
import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import getOrganizationDomainName from './getOrganizationDomainName';
import useResults from '../../../../../Selection/Hooks/useResults';
import Addressee from '../Model/Addressee';

export default function useRelationshipByAddressee(addressee?: Addressee,
                                                   doSearchByDomain: boolean = true): [ Entity, boolean ]
{
    const types = useTypes();

    const domain =
        useMemo(
            () =>
                addressee &&
                    getOrganizationDomainName(addressee.email),
            [
                addressee
            ]);

    const relationshipsByEmailAddress =
        useResults(
            addressee && types.Relationship.Type,
            (builder, rootPath) =>
                builder
                    .where(
                        cb =>
                            cb.or(
                                ob =>
                                    ob
                                        .eq(
                                            rootPath
                                                .castTo(types.Relationship.Person.Contact.Type)
                                                .field(types.Relationship.Person.Contact.Field.EmailAddress),
                                            undefined,
                                            addressee.email)
                                        .eq(
                                            rootPath
                                                .castTo(types.Relationship.Person.Type)
                                                .joinTo(
                                                    types.Relationship.Person.RelationshipDefinition.Person,
                                                    false)
                                                .field(types.Relation.Person.Field.EmailAddress),
                                            undefined,
                                            addressee.email)
                                        .eq(
                                            rootPath
                                                .castTo(types.Relationship.Organization.Type)
                                                .joinTo(
                                                    types.Relationship.Organization.RelationshipDefinition.Organization,
                                                    false)
                                                .field(types.Relation.Organization.Field.EmailAddress),
                                            undefined,
                                            addressee.email))),
            [
                types,
                addressee
            ]);

    const relationshipsByDomain =
        useResults(
            doSearchByDomain && domain && types.Relationship.Type,
            (builder, rootPath) =>
                builder.where(
                    cb =>
                        cb.or(
                            ob =>
                                ob
                                    .contains(
                                        rootPath
                                            .castTo(types.Relationship.Organization.Type)
                                            .joinTo(
                                                types.Relationship.Organization.RelationshipDefinition.Organization,
                                                false)
                                            .field(types.Relation.Organization.Field.EmailAddress),
                                        undefined,
                                        `@${domain}` // contact@tribecrm.nl
                                    )
                                    .contains(
                                        rootPath
                                            .castTo(types.Relationship.Organization.Type)
                                            .joinTo(
                                                types.Relationship.Organization.RelationshipDefinition.Organization,
                                                false)
                                            .field(types.Relation.Organization.Field.Website),
                                        undefined,
                                        `.${domain}` // http://www.tribecrm.nl
                                    )
                                    .contains(
                                        rootPath
                                            .castTo(types.Relationship.Organization.Type)
                                            .joinTo(
                                                types.Relationship.Organization.RelationshipDefinition.Organization,
                                                false)
                                            .field(types.Relation.Organization.Field.Website),
                                        undefined,
                                        `/${domain}` // http://tribecrm.nl
                                    )
                        )
                ),
            [
                types,
                domain
            ]
        );

    return useComputed(
        () =>
        {
            if ((addressee ? relationshipsByEmailAddress : [])
                && (doSearchByDomain && domain ? relationshipsByDomain : []))
            {
                return [
                    [
                        ...(relationshipsByEmailAddress || []),
                        ...(relationshipsByDomain || [])
                    ].find(() => true),
                    false
                ];
            }
            else
            {
                return [ undefined, true ];
            }
        },
        [
            addressee,
            doSearchByDomain,
            domain,
            relationshipsByEmailAddress,
            relationshipsByDomain
        ]);
}
