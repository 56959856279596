import React, { useCallback, useMemo, useState } from 'react';
import { successColor } from '../../../../@Resource/Theme/Theme';
import { Box, Grow, makeStyles } from '@material-ui/core';
import SaveIconButton from '../Button/Variant/SaveIconButton/SaveIconButton';
import { FocusField } from '../FocusField/FocusField';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});

export interface CommittableTouchFieldProps
{
    focused?: boolean;
    showCommitButtonOnTouch?: boolean;
    onCommit: () => void;
}

export const CommittableTouchField: React.FC<CommittableTouchFieldProps> =
    ({
        focused: isFocused,
        showCommitButtonOnTouch,
        onCommit,
        children,
     }) =>
    {
        const classes = useStyles();
        const [ isFocusedByTouch, setFocusedByTouch ] = useState(false);
        const isFocusedWithCommitButton =
            useMemo(
                () =>
                    isFocused
                    && showCommitButtonOnTouch
                    && isFocusedByTouch,
                [isFocused, isFocusedByTouch, showCommitButtonOnTouch]
            );
        const onPointerUp =
            useCallback(
                (event: React.PointerEvent<HTMLElement>) =>
                    setFocusedByTouch(
                        event.pointerType === 'touch'
                    ),
                []
            );

        return <FocusField
            focused={isFocusedWithCommitButton}
        >
            <Box
                className={classes.root}
                onPointerUp={onPointerUp}
            >
                <Box
                    flex={1}
                >
                    {children}
                </Box>
                <Grow
                    in={isFocusedWithCommitButton}
                    mountOnEnter
                    unmountOnExit
                >
                    <Box
                        paddingLeft={1}
                    >
                        <SaveIconButton
                            onClick={onCommit}
                            color={successColor}
                            filled
                            square
                        />
                    </Box>
                </Grow>
            </Box>
        </FocusField>;
    };
