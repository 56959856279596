import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { LanguageEntry } from '../../Model/Implementation/LanguageEntry';
import { ImportanceLevel } from '../../Model/Implementation/EntityRelationshipDefinition';
import { DataObjectType } from '../../Model/Implementation/SettingDefinition';
import { EntityFieldMutation } from '../../Model/Implementation/EntityFieldMutation';

export class EntityFieldController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    deleteEntityField(id: number): Promise<EntityFieldMutation>
    {
            return new Promise<EntityFieldMutation>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/entity/field/delete', Method.Post, { id: id }))
                    .then(result =>
                    {
                        resolve(fromJson(result, EntityFieldMutation));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    saveEntityField(id: number, code: string, name: LanguageEntry, description: LanguageEntry, dataObjectType: DataObjectType, importanceLevel: ImportanceLevel, dataObjectSpecification: any, handlers: any, isRequired: boolean, isDefining: boolean, isDefault: boolean, isCompact: boolean, isReadonly: boolean, isHiddenFromTimeline: boolean, computation: any, defaultValueComputation: any, numberGeneratorId: number, entityTypeId: number, entity: any): Promise<EntityFieldMutation>
    {
            return new Promise<EntityFieldMutation>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/entity/field/save', Method.Post, { id: id, code: code, name: name, description: description, data_object_type: dataObjectType, importance_level: importanceLevel, data_object_specification: dataObjectSpecification, handlers: handlers, is_required: isRequired, is_defining: isDefining, is_default: isDefault, is_compact: isCompact, is_readonly: isReadonly, is_hidden_from_timeline: isHiddenFromTimeline, computation: computation, default_value_computation: defaultValueComputation, number_generator_id: numberGeneratorId, entity_type_id: entityTypeId, entity: entity }))
                    .then(result =>
                    {
                        resolve(fromJson(result, EntityFieldMutation));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
}
