import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { TableLayoutDimensionSectionEditorProps } from './TableLayoutDimensionSectionEditor';
import CollectionTableDimensionSection from '../../../../../../../@Api/Layout/Type/Table/Model/CollectionTableDimensionSection';
import InputGroup from '../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import FunctionContext from '../../../../../../../@Api/Automation/Function/FunctionContext';
import ParameterAssignment from '../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import Computation from '../../../../../../../@Api/Automation/Function/Computation/Computation';
import { runInAction } from 'mobx';
import ComputationPopperEditor from '../../../../../Entity/Viewer/Content/Automation/Editor/Computation/Dialog/ComputationDialogEditor';
import Layout from '../../../../../../../@Api/Layout/Layout';
import OptionalLayoutEditor from '../../../../OptionalEditor/OptionalLayoutEditor';

export interface TableLayoutCollectionDimensionSectionEditorProps extends TableLayoutDimensionSectionEditorProps
{
    section: CollectionTableDimensionSection;
}

const TableLayoutCollectionDimensionSectionEditor: React.FC<TableLayoutCollectionDimensionSectionEditorProps> =
    props =>
    {
        const { section, parameterDictionary, parameterAssignment, commitContext, rowSectionEditor } = props;
        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        parameterDictionary,
                        parameterAssignment || new ParameterAssignment(),
                        commitContext
                    ),
                [
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                ]);
        const setCollection =
            useCallback(
                (collection: Computation<any, any>) =>
                    runInAction(
                        () =>
                        {
                            if (section.collection !== collection)
                            {
                                section.collection = collection;
                                section.parameter = CollectionTableDimensionSection.getParameterFromCollection(section.id, collection);
                            }
                        }),
                [
                    section
                ]);
        const setEmptyLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            section.emptyLayout = layout),
                [
                    section
                ]);

        return <InputGroup>
            <Input
                label={
                    <LocalizedText
                        code="Generic.Collection"
                        value="Collectie"
                    />
                }
            >
                <ComputationPopperEditor
                    context={context}
                    value={section.collection}
                    onChange={setCollection}
                />
            </Input>
            {
                rowSectionEditor &&
                    <Input
                        label={
                            <LocalizedText
                                code="Layout.Table.EmptyLayout"
                                value="Layout bij géén records"
                            />
                        }
                    >
                        <OptionalLayoutEditor
                            parameterDictionary={parameterDictionary}
                            parameterAssignment={parameterAssignment}
                            layout={section.emptyLayout}
                            onChange={setEmptyLayout}
                        />
                    </Input>
            }
        </InputGroup>;
    };

export default observer(TableLayoutCollectionDimensionSectionEditor);
