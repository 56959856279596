import { observer } from 'mobx-react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import Popper from '../../../../../../../../@Future/Component/Generic/Popper/Popper';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { CampaignChartSelection } from '../Charts/CampaignChartSelection';
import CampaignChartDelivered from '../Charts/CampaignChartDelivered';
import { CampaignChartProcessing } from '../Charts/CampaignChartProcessing';
import { CampaignChartUndelivered } from '../Charts/CampaignChartUndelivered';
import CampaignChartOpened from '../Charts/CampaignChartOpened';
import CampaignChartClicked from '../Charts/CampaignChartClicked';
import { CampaignChartUnsubscribed } from '../Charts/CampaignChartUnsubscribed';
import { CampaignChartSpam } from '../Charts/CampaignChartSpam';
import CampaignChartUndeliverable from '../Charts/CampaignChartUndeliverable';
import React, { useState } from 'react';
import { CampaignChartBounced } from '../Charts/CampaignChartBounced';
import { CampaignChartBlocked } from '../Charts/CampaignChartBlocked';
import useIsMobile from '../../../../../../../../@Util/Responsiveness/useIsMobile';

export interface CampaignResultChartsProps
{
    campaign: Entity;
}

export const CampaignResultCharts: React.FC<CampaignResultChartsProps> =
    observer(
        ({
            campaign
        }) =>
        {
            const [ isHovered, setIsHovered] = useState(false);
            const isMobile = useIsMobile();

            const handleMouseEnter =
                () =>
                {
                    setIsHovered(true);
                };

            const handleMouseLeave =
                () =>
                {
                    setIsHovered(false);
                };

            return <ViewGroupItem>
                    <ViewGroup
                        orientation={isMobile ? 'vertical' : 'horizontal'}
                        spacing={isMobile ? 30 : 70}
                        justification="center"
                        alignment="center"
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={10}
                            alignment="center"
                        >
                            <ViewGroupItem
                                alignment="center"
                            >
                                <CampaignChartSelection
                                    campaign={campaign}
                                    size={130}
                                />
                            </ViewGroupItem>
                            <ViewGroup
                                orientation="vertical"
                                spacing={10}
                            >
                                <ViewGroupItem
                                    alignment="center"
                                >
                                    <CampaignChartDelivered
                                        campaign={campaign}
                                        size={90}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem
                                    alignment="center"
                                >
                                    <CampaignChartProcessing
                                        campaign={campaign}
                                        size={90}
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                            <ViewGroupItem
                                alignment="center"
                            >
                                <CampaignChartUndelivered
                                    campaign={campaign}
                                    size={90}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={10}
                            alignment="center"
                        >
                            <ViewGroupItem
                                alignment="center"
                            >
                                <CampaignChartOpened
                                    campaign={campaign}
                                    size={130}
                                />
                            </ViewGroupItem>
                            <ViewGroup
                                orientation="vertical"
                                spacing={10}
                            >
                                <ViewGroupItem
                                    alignment="center"
                                >
                                    <CampaignChartClicked
                                        campaign={campaign}
                                        size={90}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem
                                    alignment="center"
                                >
                                    <CampaignChartUnsubscribed
                                        campaign={campaign}
                                        size={90}
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroup>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={10}
                            alignment="center"
                        >
                            <ViewGroupItem
                                alignment="center"
                            >
                                <CampaignChartUndelivered
                                    campaign={campaign}
                                    size={130}
                                />
                            </ViewGroupItem>
                            <ViewGroup
                                orientation="vertical"
                                spacing={10}
                            >
                                <ViewGroupItem
                                    alignment="center"
                                >
                                    <CampaignChartSpam
                                        campaign={campaign}
                                        size={90}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem
                                    alignment="center"
                                >
                                    <Popper
                                        reference={
                                            <div
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                <ViewGroupItem>
                                                    <CampaignChartUndeliverable
                                                        campaign={campaign}
                                                        size={90}
                                                    />
                                                </ViewGroupItem>
                                            </div>
                                        }
                                        popper={
                                            <Card>
                                                <ViewGroup
                                                    orientation="vertical"
                                                    alignment="center"
                                                    spacing={10}
                                                >
                                                    <ViewGroupItem>
                                                        <CampaignChartBlocked
                                                            campaign={campaign}
                                                            size={90}
                                                        />
                                                    </ViewGroupItem>
                                                    <ViewGroupItem>
                                                        <CampaignChartBounced
                                                            campaign={campaign}
                                                            size={90}
                                                        />
                                                    </ViewGroupItem>
                                                </ViewGroup>
                                            </Card>
                                        }
                                        open={isHovered}
                                        placement="right"
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroup>
                    </ViewGroup>
            </ViewGroupItem>
        }
    );
