import { Selection } from '../Model/Selection';
import { SelectionHint } from '../../Model/Implementation/SelectionHint';

export function getSelectionDescriptor(selection: Selection)
{
    return {
        nodes:
            selection.nodes.map(
                node =>
                    node.descriptor()
            ),
        hints:
            selection.hints.map(
                hint =>
                    SelectionHint[hint]
            )
    };
}