import React from 'react';
import { observer } from 'mobx-react-lite';
import useDroppedItemId from '../Api/useDroppedItemId';
import DroppedDraggableContext from '../Context/DroppedDraggableContext';

export interface DraggableProps
{

}

const DragAndDropInitialization: React.FC<DraggableProps> =
    props =>
    {
        const droppedItemId = useDroppedItemId();

        return <DroppedDraggableContext.Provider
            value={droppedItemId}
        >
            {props.children}
        </DroppedDraggableContext.Provider>;
    };

export default observer(DragAndDropInitialization);
