import { RelatedEntityPath } from './RelatedEntityPath';

export class AddEntityPath
{
    // ------------------------- Properties -------------------------

    fullPath: RelatedEntityPath;
    relativePath: RelatedEntityPath;

    // ------------------------ Constructor -------------------------

    constructor(fullPath: RelatedEntityPath,
                relativePath: RelatedEntityPath = fullPath)
    {
        this.relativePath = relativePath;
        this.fullPath = fullPath;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
