import React from 'react';
import { observer } from 'mobx-react';
import Card from '../../@Future/Component/Generic/Card/Card';
import CardInset from '../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardHeader from '../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import MenuButton from '../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import RightAlignedButtonGroup from '../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import PrimaryButton from '../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import Centered from '../../@Future/Component/Generic/Centered/Centered';
import useToggle from '../../@Util/Toggle/useToggle';
import Input from '../../@Future/Component/Generic/Input/Input/Input';
import Switch from '../../@Future/Component/Generic/Input/Switch/Switch';
import localizeText from '../../@Api/Localization/localizeText';
import LocalizedText from '../Domain/Localization/LocalizedText/LocalizedText';

export interface ErrorProps
{
    error: any;
    errorInfo?: any;
    onClose: () => void;
    centered?: boolean;
    title?: string;
    description?: string;
    action?: string;
}

const ErrorDialog: React.FC<ErrorProps> =
    props =>
    {
        const [ showDetails, toggleDetails ] = useToggle(false);

        const defaultError =
            {
                title: localizeText('ErrorDialog.DefaultTitle', 'Oops! Er is iets fout gegaan.'),
                description: localizeText('ErrorDialog.DefaultDescription', 'Wij zijn hiervan op de hoogte gesteld en gaan aan de slag om het probleem op te lossen.'),
                action: localizeText('ErrorDialog.DefaultAction', 'Sluiten')
            }

        return <Centered
            horizontal={props.centered}
            vertical={props.centered}
        >
            <Card>
                <CardInset>
                    <ViewGroup
                        orientation="vertical"
                        spacing={15}
                    >
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                                alignment="center"
                            >
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <CardHeader>
                                        {
                                            props.title
                                                ? props.title
                                                : defaultError.title
                                        }
                                    </CardHeader>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <MenuButton>
                                        <CardInset>
                                            <Input
                                                label={
                                                    <LocalizedText
                                                        code="ShowDetails"
                                                        value="Toon details"
                                                    />
                                                }
                                                labelPosition="left"
                                            >
                                                <Switch
                                                    checked={showDetails}
                                                    onToggle={toggleDetails}
                                                />
                                            </Input>
                                        </CardInset>
                                    </MenuButton>
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            {
                                props.description
                                    ? props.description
                                    : defaultError.description
                            }
                        </ViewGroupItem>
                        {
                            showDetails &&
                                <ViewGroupItem>
                                    <code>
                                        {props.error.toString()}
                                        <br />
                                        {props.errorInfo?.componentStack}
                                    </code>
                                </ViewGroupItem>
                        }
                        <ViewGroupItem>
                            <RightAlignedButtonGroup>
                                <PrimaryButton
                                    label={props.action ? props.action : defaultError.action}
                                    onClick={props.onClose}
                                />
                            </RightAlignedButtonGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </CardInset>
            </Card>
        </Centered>;
    };

export default observer(ErrorDialog);
