import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { primaryColor, textSecondaryColor } from '../../../../../../../../../@Resource/Theme/Theme';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import MenuButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import CardInset from '../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { PortalTableColumn } from '../../../../../../../../../@Api/Layout/Type/PortalTable/Model/PortalTableColumn';
import useSwitch from '../../../../../../../../../@Util/Switch/useSwitch';
import SearchBar from '../../../../../../../../../@Future/Component/Generic/SearchBar/SearchBar';

export interface PortalTableColumnFilterButtonProps
{
    column: PortalTableColumn;
    onFilter: (column: PortalTableColumn, filterQuery: string | undefined) => void;
}

export const PortalTableColumnFilterButton: React.FC<PortalTableColumnFilterButtonProps> =
    observer(
        ({
            column,
            onFilter,
         }) =>
        {
            const [ filterQuery, setFilterQuery ] = useState('');
            const [ isOpen, open, close ] = useSwitch(false);

            return <MenuButton
                icon="filter_list"
                tooltip={
                    <LocalizedText
                        code="Generic.Filter"
                        value="Filter"
                    />
                }
                color={filterQuery.length > 0 ? primaryColor : textSecondaryColor}
                open={isOpen}
                onOpen={open}
                onClose={close}
                badge={filterQuery.length > 0 ? 1 : undefined}
                badgeVariant="dot"
            >
                <CardInset>
                    <SearchBar
                        onSearch={
                            filterQuery =>
                            {
                                setFilterQuery(filterQuery);
                                onFilter(column, filterQuery);
                            }
                        }
                        autoFocus
                        defaultValue={filterQuery}
                    />
                </CardInset>
            </MenuButton>;
        }
    );