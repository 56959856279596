import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import localizeText from '../../Localization/localizeText';

export default class InsetLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref layout: Layout;
    @observable top: number;
    @observable right: number;
    @observable bottom: number;
    @observable left: number;

    // ------------------------ Constructor -------------------------

    constructor(layout: Layout,
                top: number,
                right: number,
                bottom: number,
                left: number)
    {
        super();

        this.layout = layout;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
        this.left = left;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.Inset', 'Padding');
    }

    validate(): Validation[]
    {
        return this.layout.validate();
    }

    getDependencies(): Dependency[]
    {
        return this.layout.getDependencies();
    }

    toDescriptor()
    {
        return {
            type: 'Inset',
            layout: this.layout.toDescriptor(),
            top: this.top,
            right: this.right,
            bottom: this.bottom,
            left: this.left
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new InsetLayout(
            await getLayoutFromDescriptor(
                descriptor.layout,
                dependencyContext),
            descriptor.top,
            descriptor.right,
            descriptor.bottom,
            descriptor.left);
    }

    // ----------------------- Private logic ------------------------
}
