import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import PortalImageLayout from '../../../../../@Api/Layout/Type/PortalImageLayout';
import FileDropZone from '../../../../../@Future/Component/Generic/Input/FileDropZone/FileDropZone';
import PortalContext from '../../../../../@Api/Portal/Context/PortalContext';
import useTypes from '../../../Entity/Type/Api/useTypes';
import { runInAction } from 'mobx';
import PortalImageLayoutViewer from './PortalImageLayoutViewer';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import { FileValue } from '../../../DataObject/Type/File/FileValue';
import { CommitBuilder } from '../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export interface PortalImageLayoutEditorProps extends LayoutEditorProps<PortalImageLayout>
{

}

const PortalImageLayoutEditor: React.FC<PortalImageLayoutEditorProps> =
    props =>
    {
        const types = useTypes();
        const { portal } = useContext(PortalContext);
        const onDrop =
            useCallback(
                (files: File[]) =>
                    new CommitBuilder()
                        .createEntity(
                            types.PortalResource.Type,
                            builder =>
                                builder
                                    .relateTo(
                                        true,
                                        types.Portal.RelationshipDefinition.Resources,
                                        portal
                                    )
                                    .setObjectValue(
                                        types.PortalResource.Field.File,
                                        FileValue.fromFile(files[0])
                                    ),
                            'resource'
                        )
                        .commit()
                        .then(
                            result =>
                                result.withEntity(
                                    'resource',
                                    resource =>
                                        runInAction(
                                            () =>
                                                props.layout.resourceId = resource.uuid
                                        )
                                )
                        ),
                [
                    types,
                    portal
                ]);

        const parameterAssignment =
            useMemo(
                () =>
                    props.parameterAssignment || new ParameterAssignment(),

                [
                    props.parameterAssignment
                ]);

        if (props.layout.resourceId)
        {
            return <PortalImageLayoutViewer
                {...props}
                parameterAssignment={parameterAssignment}
            />;
        }
        else
        {
            return <FileDropZone
                onDrop={onDrop}
            />;
        }
    };

export default observer(PortalImageLayoutEditor);
