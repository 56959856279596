import React, { useCallback, useMemo } from 'react';
import { default as MuiDrawer, DrawerProps as MuiDrawerProps } from '@material-ui/core/Drawer';
import styles from './Drawer.module.scss';
import ViewGroup from '../ViewGroup/ViewGroup';
import useDividerGlue from '../ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../ViewGroup/ViewGroupItem';
import SectionLabel from '../Label/Variant/SectionLabel/SectionLabel';
import CloseButton from '../Button/Variant/CloseButton/CloseButton';
import CardInset from '../Card/CardInset';
import useWindowSize from '../../../../@Util/Size/useWindowSize';
import ErrorBoundary from '../../../../@Component/Error/ErrorBoundary';

export interface DrawerProps extends Omit<MuiDrawerProps, 'title'>
{
    title?: React.ReactNode;
    width?: number;
}

const Drawer: React.FC<DrawerProps> =
    props =>
    {
        const { onClose } = props;
        const dividerGlue = useDividerGlue();
        const close =
            useCallback(
                () =>
                    onClose(
                        undefined,
                        'backdropClick'
                    ),
                [
                    onClose
                ]);
        const { title, ...drawerProps } = props;
        const { width } = useWindowSize();
        const style =
            useMemo(
                () => ({
                    width: Math.min(props.width, 0.9 * width)
                }),
                [
                    props.width,
                    width
                ]);

        return <MuiDrawer
            {...drawerProps}
        >
            <div
                className={styles.root}
                style={style}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    glue={dividerGlue}
                >
                    {
                        title &&
                            <ViewGroupItem>
                                <CardInset>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                        alignment="center"
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            {
                                                title &&
                                                    <SectionLabel>
                                                        {props.title}
                                                    </SectionLabel>
                                            }
                                        </ViewGroupItem>
                                        {
                                            props.onClose &&
                                                <ViewGroupItem>
                                                    <CloseButton
                                                        onClick={close}
                                                    />
                                                </ViewGroupItem>
                                        }
                                    </ViewGroup>
                                </CardInset>
                            </ViewGroupItem>
                    }
                    <ViewGroupItem>
                        <ErrorBoundary>
                            {props.children}
                        </ErrorBoundary>
                    </ViewGroupItem>
                </ViewGroup>
            </div>
        </MuiDrawer>;
    };

Drawer.defaultProps = {
    width: 450
};

export default Drawer;