import React from 'react';
import { PanelBody, PanelRow, RangeControl } from '@wordpress/components';
import { BlockEditProps } from '@wordpress/blocks';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import LocalizedText from '../../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface BlockOptionsPanelProps
{
    props: BlockEditProps<{}>;
    open?: boolean;
}

const BlockOptionsPanel: React.FC<BlockOptionsPanelProps> =
    props =>
    {
        const {
            borderWidth,
            borderRadius,
            padding,
            margin
        } = props.props.attributes as any;

        return <PanelBody
            title={localizeText('Gutenberg.BlockOptions.Title', 'Blok')}
            initialOpen={!!props.open}
        >
            <PanelRow>
                <RangeControl
                    name="padding"
                    label={
                        <LocalizedText
                            code="Gutenberg.BlockOptions.Padding"
                            value="Vulling"
                        />
                    }
                    value={ padding ? padding : 0 }
                    onChange={ padding => props.props.setAttributes({ padding: padding }) }
                    min={ 0 }
                    max={ 200 }
                />
            </PanelRow>
            <PanelRow>
                <RangeControl
                    name="margin"
                    label={
                        <LocalizedText
                            code="Gutenberg.BlockOptions.Margin"
                            value="Marge"
                        />
                    }
                    value={ margin ? margin: 0 }
                    onChange={ margin => props.props.setAttributes({ margin: margin }) }
                    min={ 0 }
                    max={ 200 }
                />
            </PanelRow>
            <PanelRow>
                <RangeControl
                    name="border"
                    label={
                        <LocalizedText
                            code="Gutenberg.BlockOptions.BorderWidth"
                            value="Rand breedte"
                        />
                    }
                    value={ borderWidth ? borderWidth : 0 }
                    onChange={ borderWidth => props.props.setAttributes({ borderWidth: borderWidth }) }
                    min={ 0 }
                    max={ 100 }
                />
            </PanelRow>
            <PanelRow>
                <RangeControl
                    name="borderRadius"
                    label={
                        <LocalizedText
                            code="Gutenberg.BlockOptions.BorderRadius"
                            value="Rand afronding"
                        />
                    }
                    value={ borderRadius ? borderRadius : 0 }
                    onChange={ borderRadius => props.props.setAttributes({ borderRadius: borderRadius }) }
                    min={ 0 }
                    max={ 100 }
                />
            </PanelRow>
        </PanelBody>;
    };

export default BlockOptionsPanel;
