import * as React from 'react';
import { TextView } from '../Text/TextView';
import { DataObjectViewerStore } from '../../Viewer/DataObjectViewerStore';
import { observer } from 'mobx-react';
import { Typography } from '@material-ui/core';

@observer
export class MeView extends TextView
{
    renderComponent(store: DataObjectViewerStore)
    {
        return <Typography
            variant="body2"
        >
            {
                store.localizationStore.translate('Generic.CurrentUser') // Current user
            }
        </Typography>;
    }
}
