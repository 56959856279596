import { EntityType } from '../../../../Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../../@Component/Domain/Entity/Type/EntityTypeStore';

export default function getPhaseRelationshipDefinition(type: EntityType)
{
    const entityTypeStore = loadModuleDirectly(EntityTypeStore);
    const types = entityTypeStore.bespoke.types;

    return type.getInheritedRelationshipDefinitions(false)
        .find(
            relationshipDefinition =>
                relationshipDefinition.childEntityType.isA(types.Datastore.Phase.Type)
                    && relationshipDefinition !== types.Entity.RelationshipDefinition.PhaseReference
                    && relationshipDefinition !== types.Entity.RelationshipDefinition.ClosePhase
                    && relationshipDefinition !== types.Pipeline.RelationshipDefinition.Phases);
}
