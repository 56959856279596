import { when } from 'mobx';

export interface ModuleLoader
{
    load(ref: Function, qualifier?: string): Promise<any>;
    directLoad(ref: Function, qualifier?: string): any;
}

export interface Newable<T>
{
    new (...args: any[]): T;
}

const injectionProperty = '__injectionKeys';
const moduleLoaders: ModuleLoader[] = [];
const openPromises = new Map<Function, any[]>();

/**
 * Registers a module loader.
 *
 * @param {ModuleLoader} loader
 */
export function registerModuleLoader(loader: ModuleLoader)
{
    moduleLoaders.push(loader);

    let promises: Array<Promise<any>> = [];

    openPromises.forEach((resolves, key) =>
    {
        resolves.forEach(resolve =>
        {
            let promise = loadModule(key).then(resolve);

            promises.push(promise);
        });
    });

    Promise.all(promises).then(() => (openPromises.clear()));
}

/**
 * Unregisters a module loader.
 *
 * @param {ModuleLoader} loader
 */
export function unregisterModuleLoader(loader: ModuleLoader)
{
    let idx = moduleLoaders.indexOf(loader);

    if (idx >= 0)
    {
        moduleLoaders.splice(idx, 1);
    }
}

/**
 * Loads a module with a specified ref.
 *
 * @param {Function} ref
 * @returns {Promise<any>}
 */
function loadModule(ref: Function): Promise<any>
{
    if (moduleLoaders.length === 0)
    {
        return new Promise<any>(
            resolve =>
            {
                if (!openPromises.has(ref))
                {
                    openPromises.set(ref, []);
                }

                openPromises.get(ref)!.push(resolve);
            }
        );
    }
    else
    {
        return Promise.all(moduleLoaders.map(loader => loader.load(ref)));
    }
}

export function loadModuleDirectly<T>(ref: Newable<T>,
                                      qualifier?: string): T
{
    if (moduleLoaders.length === 0)
    {
        console.warn("No moduleLoaders registered lo load", ref, qualifier);
        return undefined;
    }

    for (let loader of moduleLoaders)
    {
        const instance =
            loader.directLoad(
                ref,
                qualifier);

        if (instance)
        {
            return instance;
        }
        else
        {
            console.warn('Could not load module directly:', ref, qualifier, loader);
        }
    }

    return undefined;
}

export function injectWithQualifier(qualifier: string): any
{
    return function(target: any, key: string)
    {
        return inject(target, key, qualifier);
    };
}

/**
 * Decorator for module injection.
 *
 * @param target
 * @param {string} key
 * @returns {any}
 */
function inject(target: any,
                key: string,
                qualifier: string): any
{
    return {
        set: (value: any) =>
        {

        },
        get: () =>
        {
            return loadModuleDirectly(undefined, qualifier);
        },
        enumerable: true,
        configurable: true
    };
}

export function getInjections(target: any): string[]
{
    return target[injectionProperty] || [];
}

export function areInjectionsSatisfied(target: any): boolean
{
    return getInjections(target).every(injection => (target[injection] != null)) || true;
}

export function awaitInjections(target: any): Promise<any>
{
    if (areInjectionsSatisfied(target))
    {
        return Promise.resolve(getInjections(target));
    }
    else
    {
        return new Promise<any>(
            resolve =>
            {
                when(
                    () => (areInjectionsSatisfied(target)),
                    () =>
                    {
                        resolve(getInjections(target));
                    });
            });
    }
}
