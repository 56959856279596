import { observable } from 'mobx';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Action from './Action';
import EmptyValueType from '../../Value/Type/EmptyValueType';
import EmptyValue from '../../Value/EmptyValue';
import Computation from '../Computation/Computation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Validation from '../../Validation/Validation';
import localizeText from '../../../Localization/localizeText';

export default class FtpUploadAction extends Action<EmptyValueType, EmptyValue>
{
    // ------------------------- Properties -------------------------

    @observable.ref file: Computation<any, any>;
    @observable.ref host: Computation<any, any>;
    @observable.ref port: Computation<any, any>;
    @observable.ref username: Computation<any, any>;
    @observable.ref password: Computation<any, any>;
    @observable.ref uploadPath: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(
        file: Computation<any, any>,
        host: Computation<any, any>,
        port: Computation<any, any>,
        username: Computation<any, any>,
        password: Computation<any, any>,
        uploadPath: Computation<any, any>
    )
    {
        super();

        this.file = file;
        this.host = host;
        this.port = port;
        this.username = username;
        this.password = password;
        this.uploadPath = uploadPath;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return EmptyValueType.instance;
    }

    getName()
    {
        return localizeText(
            'Action.FtpUpload',
            'Upload bestand in FTP server'
        );
    }

    validate(): Validation[]
    {
        return [
            ...this.file.validate(),
            ...this.host.validate(),
            ...this.port.validate(),
            ...this.username.validate(),
            ...this.password.validate(),
            ...this.uploadPath.validate(),
        ];
    }

    isAsync(): boolean
    {
        return true;
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'FtpUpload';
        descriptor.file = this.file.toDescriptor();
        descriptor.host = this.host.toDescriptor();
        descriptor.port = this.port.toDescriptor();
        descriptor.username = this.username.toDescriptor();
        descriptor.password = this.password.toDescriptor();
        descriptor.uploadPath = this.uploadPath.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.file.getDependencies(),
            ...this.host.getDependencies(),
            ...this.port.getDependencies(),
            ...this.username.getDependencies(),
            ...this.password.getDependencies(),
            ...this.uploadPath.getDependencies(),
        ];
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext
    )
    {
        const [
            file,
            host,
            port,
            username,
            password,
            uploadPath
        ] = await Promise.all([
            getComputationFromDescriptor(
                descriptor.file,
                dependencyContext
            ),
            getComputationFromDescriptor(
                descriptor.host,
                dependencyContext
            ),
            getComputationFromDescriptor(
                descriptor.port,
                dependencyContext
            ),
            getComputationFromDescriptor(
                descriptor.username,
                dependencyContext
            ),
            getComputationFromDescriptor(
                descriptor.password,
                dependencyContext
            ),
            getComputationFromDescriptor(
                descriptor.uploadPath,
                dependencyContext
            ),
        ]);

        return new FtpUploadAction(
            file,
            host,
            port,
            username,
            password,
            uploadPath
        );
    }

    // ----------------------- Private logic ------------------------
}
