import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import LayoutDependencyContext from '../LayoutDependencyContext';
import { observable } from 'mobx';
import Dimension from '../Style/Dimension';
import localizeText from '../../Localization/localizeText';
import ImageLayout from './ImageLayout';

export default class ResourceImageLayout extends ImageLayout
{
    // ------------------------- Properties -------------------------

    @observable resourceId: string;
    @observable resourceToken: string;

    // ------------------------ Constructor -------------------------

    constructor(resourceId: string,
                resourceToken: string,
                width: Dimension | undefined,
                height: Dimension | undefined)
    {
        super(width, height);

        this.resourceId = resourceId;
        this.resourceToken = resourceToken;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.ResourceImage', 'Bibliotheekafbeelding');
    }

    validate(): Validation[]
    {
        return [];
    }

    getDependencies(): Dependency[]
    {
        return [];
    }

    toDescriptor()
    {
        return {
            type: 'ResourceImage',
            resourceId: this.resourceId,
            resourceToken: this.resourceToken,
            width: this.width?.toDescriptor(),
            height: this.height?.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new ResourceImageLayout(
            descriptor.resourceId,
            descriptor.resourceToken,
            descriptor.width
                ?
                    Dimension.fromDescriptor(descriptor.width)
                :
                    undefined,
            descriptor.height
                ?
                    Dimension.fromDescriptor(descriptor.height)
                :
                    undefined);
    }

    // ----------------------- Private logic ------------------------
}
