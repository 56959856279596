import React from 'react';
import { observer } from 'mobx-react';
import { WidgetProps } from '../Widget';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import { default as InternalResourcePlanner } from '../../../ResourcePlanner/ResourcePlanner';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import ProjectPlannerWidget from '../../Model/Widget/ProjectPlanner/ProjectPlannerWidget';
import useProjectTimesheet from '../../../ResourcePlanner/Type/useProjectTimesheet';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface MyProjectHoursTimesheetProps extends WidgetProps<ProjectPlannerWidget>
{

}

const MyProjectHoursTimesheet: React.FC<MyProjectHoursTimesheetProps> =
    props =>
    {
        const resourcePlanner = useProjectTimesheet(true,true);

        return <Card>
            <CardInset
                horizontal={false}
                bottom={false}
            >
                <InternalResourcePlanner
                    id={props.widget.id}
                    resourcePlanner={resourcePlanner}
                    name={
                        <LocalizedText
                            code="ResourcePlanner.MyProjectHoursTimesheet"
                            value="Mijn projecten - Mijn uren"
                        />
                    }
                    appendix={
                        <MenuButton>
                            <Menu>
                                <DeleteItem
                                    onClick={props.onDelete}
                                />
                            </Menu>
                        </MenuButton>
                    }
                    dragHandle
                />
            </CardInset>
        </Card>;
    };

export default observer(MyProjectHoursTimesheet);
