import * as React from 'react';
import { PageStore } from '../../../../../../../../@Service/Navigation/Page/PageStore';
import ComposeExternalEmail, { ComposeExternalEmailProps } from './ComposeExternalEmail';
import { observable } from 'mobx';
import ExternalEmail from '../Model/ExternalEmail';
import md5 from 'md5';

export class ComposeExternalEmailPageStore extends PageStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable.ref externalEmail: Partial<ExternalEmail>;

    // ------------------------ Constructor -------------------------

    constructor(externalEmail: Partial<ExternalEmail>)
    {
        super(0);

        this.externalEmail = externalEmail;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    get componentClass(): React.ClassType<any, any, any>
    {
        return ComposeExternalEmail;
    }

    get componentProps(): ComposeExternalEmailProps
    {
        return {
            // the React key is to ensure that another message gets a completely new composer (and does not reuse the entity of an existing composer)
            key: this.externalEmail.id,
            externalEmail: this.externalEmail
        };
    }

    get routePath(): string
    {
        return `/bespoke/activity/email/compose-external-email/${md5(this.externalEmail.id)}`;
    }

    // ----------------------- Private logic ------------------------
}
