import React, { useState } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import MilestoneSummary from './MilestoneSummary/MilestoneSummary';
import ProjectOverview from './ProjectOverview/ProjectOverview';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface ProjectProps
{
    project: Entity;
    commitContext: CommitContext;
}

const Project: React.FC<ProjectProps> =
    props =>
    {
        const [ milestone, setMilestone ] = useState<Entity | undefined>();

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <MilestoneSummary
                    entity={props.project}
                    onOpen={setMilestone}
                    milestone={milestone}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <ProjectOverview
                    project={props.project}
                    commitContext={props.commitContext}
                    milestone={milestone}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(Project);
