import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { WidgetProps } from '../Widget';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import styles from './Website.module.scss';
import ExpansionGroup from '../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import ExpansionPanel from '../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import { buttonInset } from '../../../../../../@Resource/Theme/Theme';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import useExpanded from '../../../Dataset/Api/useExpanded';
import WebsiteWidget from '../../Model/Widget/Website/WebsiteWidget';
import TextEditor from '../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import SaveButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import { runInAction } from 'mobx';
import { useComputed } from 'mobx-react-lite';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { isValidUrl } from '../../../../../../@Util/Url/isValidUrl';
import openWindow from '../../../../../../@Util/Window/openWindow';
import PrimaryButton from '../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';

export interface WebsiteProps extends WidgetProps<WebsiteWidget>
{

}

const Website: React.FC<WebsiteProps> =
    props =>
    {
        const [ url, setUrl ] = useState<string>();
        const [ isExpanded, toggleExpansion ] = useExpanded(`Widget.${props.widget.id}`);
        const openInFullscreen =
            useCallback(
                () =>
                    openWindow(props.widget.url),
                [
                    props.widget
                ]);

        const save =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            props.widget.url = url),
                [
                    url,
                    props.widget
                ]);

        const isYouTubeLink =
            useComputed(
                () =>
                    props.widget.url && props.widget.url.indexOf('https://www.youtube.com/watch?v=') >= 0,
                [
                    props.widget
                ]);

        const parsedUrl =
            useComputed(
                () =>
                {
                    const url = props.widget.url;

                    if (url)
                    {
                        if (isValidUrl(url))
                        {
                            if (isYouTubeLink)
                            {
                                return url.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/');
                            }
                            else
                            {
                                return url;
                            }
                        }
                        else
                        {
                            return undefined;
                        }
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    isYouTubeLink,
                    props.widget
                ]);

        return <Card
            classes={{
                root: styles.root
            }}
        >
            <ExpansionGroup>
                <ExpansionPanel
                    id={`Widget.${props.widget.id}`}
                    summary={
                        <DragHandle>
                            <Header
                                title={
                                    props.widget.url
                                        ?
                                            props.widget.url
                                        :
                                            <LocalizedText
                                                code="Widget.Website.Setup"
                                                value="Website instellen..."
                                            />
                                }
                                large
                                inset
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={buttonInset}
                                    alignment="center"
                                >
                                    {
                                        props.onAdd &&
                                            <ViewGroupItem>
                                                <IconButton
                                                    icon="add"
                                                    onClick={props.onAdd}
                                                    tooltip="Toevoegen aan dashboard"
                                                />
                                            </ViewGroupItem>
                                    }
                                    {
                                        props.widget.url &&
                                            <ViewGroupItem>
                                                <IconButton
                                                    icon="open_in_new"
                                                    tooltip={
                                                        <LocalizedText
                                                            code="Generic.NavigateTo"
                                                            value="Navigeer naar ${resource}"
                                                            resource={props.widget.url}
                                                        />
                                                    }
                                                    onClick={openInFullscreen}
                                                />
                                            </ViewGroupItem>
                                    }
                                    <ViewGroupItem>
                                        <MenuButton>
                                            <Menu>
                                                <DeleteItem
                                                    onClick={props.onDelete}
                                                />
                                            </Menu>
                                        </MenuButton>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </Header>
                        </DragHandle>
                    }
                    expansion={
                        <CardInset
                            top={false}
                        >
                            {
                                !parsedUrl &&
                                    <Card
                                        inset
                                    >
                                        <ViewGroup
                                            orientation="vertical"
                                            spacing={10}
                                        >
                                            <ViewGroupItem>
                                                <Input
                                                    label={
                                                        <LocalizedText
                                                            code="Widget.Website.Input"
                                                            value="Voer een geldige URL in"
                                                        />
                                                    }
                                                    labelPosition="top"
                                                >
                                                    <TextEditor
                                                        value={url}
                                                        onChange={setUrl}
                                                        placeholder="http://"
                                                    />
                                                </Input>
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <ViewGroup
                                                    orientation="horizontal"
                                                    spacing={0}
                                                >
                                                    <ViewGroupItem
                                                        ratio={1}
                                                    />
                                                    <ViewGroupItem>
                                                        <SaveButton
                                                            onClick={save}
                                                        />
                                                    </ViewGroupItem>
                                                </ViewGroup>
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </Card>
                            }
                            {
                                parsedUrl && isYouTubeLink
                                    ? <>
                                        {
                                            parsedUrl &&
                                            <iframe
                                                width="100%"
                                                src={parsedUrl}
                                                className={classNames(styles.iframe, isYouTubeLink && styles.video)}
                                                title="Website"
                                            />
                                        }
                                    </>
                                    : <PrimaryButton
                                        onClick={openInFullscreen}
                                        fullWidth
                                        label={
                                            <LocalizedText
                                                code="Generic.NavigateTo"
                                                value="Navigeer naar ${resource}"
                                                resource={props.widget.url}
                                            />
                                        }
                                    />
                            }
                        </CardInset>
                    }
                    expanded={isExpanded}
                    onExpand={toggleExpansion}
                    onCollapse={toggleExpansion}
                />
            </ExpansionGroup>
        </Card>;
    };

export default observer(Website);
