export function getHourAndMinuteFromValue(value?: string): [ number | undefined, number | undefined ]
{
    if (value === undefined)
    {
        return [
            undefined,
            undefined
        ];
    }
    else
    {
        const split = value.split(':');

        return [
            parseInt(split[0]),
            parseInt(split[1]),
        ];
    }
}
