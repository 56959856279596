import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import IconButton from '../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import useCount from '../../Entity/Selection/Hooks/useCount';
import useTypes from '../../Entity/Type/Api/useTypes';
import CurrentUserContext from '../../User/CurrentUserContext';
import Drawer from '../Drawer/Drawer';
import { useSnackbar, VariantType } from 'notistack';
import LabelButton from '../../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import useResults from '../../Entity/Selection/Hooks/useResults';
import { openEntity } from '../../Entity/@Util/openEntity';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useSwitch from '../../../../@Util/Switch/useSwitch';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import useMarkAsRead from '../Drawer/Notification/Api/useMarkAsRead';

export interface NotificationButtonProps
{

}

const NotificationButton: React.FC<NotificationButtonProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);

        const unreadNotificationCount =
            useCount(
                types.Notification.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Notifications,
                                        true),
                                    currentUserStore.employeeEntity))
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.Notification.Field.IsRead),
                                    undefined,
                                    false)),
                [
                    types,
                    currentUserStore
                ]);

        const shouldHideNewNotifications = window.innerWidth < 960;
        const newNotifications =
            useResults(
                shouldHideNewNotifications
                    ? undefined
                    : types.Notification.Type,
                (builder, rootPath) =>
                    builder
                        .join(
                            rootPath.joinTo(
                                types.Entity.RelationshipDefinition.Notifications,
                                true
                            )
                        )
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Notifications,
                                        true),
                                    currentUserStore.employeeEntity
                                )
                        )
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.Notification.Field.IsRead),
                                    undefined,
                                    false
                                )
                        )
                        .limit(
                            shouldHideNewNotifications
                                ? 0
                                : 10
                        ),
                [
                    types,
                    currentUserStore,
                    shouldHideNewNotifications,
                ]);

        const snackbar = useSnackbar();
        const [ enqueuedNotificationIds ] =
            useState(
                () =>
                    new Set<string>());

        const markAsRead = useMarkAsRead();
        useEffect(
            () =>
            {
                if (newNotifications)
                {
                    newNotifications
                        .forEach(
                            notification =>
                            {
                                const readNotification =
                                    () =>
                                        markAsRead(notification, true);

                                if (!enqueuedNotificationIds.has(notification.uuid))
                                {
                                    enqueuedNotificationIds.add(notification.uuid);

                                    const variant = notification.getObjectValueByField(types.Notification.Field.Variant);
                                    let notistackVariant: VariantType;

                                    if (variant)
                                    {
                                        switch (variant)
                                        {
                                            case 'Success':
                                                notistackVariant = 'success';
                                                break;

                                            case 'Warning':
                                                notistackVariant = 'warning';
                                                break;

                                            case 'Danger':
                                                notistackVariant = 'error';
                                                break;

                                            case 'Info':
                                                notistackVariant = 'info';
                                                break;
                                        }
                                    }

                                    snackbar.enqueueSnackbar(
                                        notification.getDataObjectValueByField(types.Notification.Field.Name).toString(),
                                        {
                                            action:
                                                key =>
                                                    <ViewGroup
                                                        orientation="horizontal"
                                                        spacing={5}
                                                        alignment="center"
                                                    >
                                                        <ViewGroupItem>
                                                            <LabelButton
                                                                label="Openen"
                                                                onClick={
                                                                    () =>
                                                                    {
                                                                        snackbar.closeSnackbar(key);

                                                                        const entity =
                                                                            notification.getRelatedEntityByDefinition(
                                                                                true,
                                                                                types.Entity.RelationshipDefinition.Notifications);

                                                                        if (entity)
                                                                        {
                                                                            openEntity(entity);
                                                                        }

                                                                        return readNotification();
                                                                    }}
                                                                color="white"
                                                                hoverColor="white"
                                                            />
                                                        </ViewGroupItem>
                                                        <ViewGroupItem>
                                                            <IconButton
                                                                icon="close"
                                                                tooltip={
                                                                    <LocalizedText
                                                                        code="Generic.Close"
                                                                        value="Afsluiten"
                                                                    />
                                                                }
                                                                onClick={
                                                                    () =>
                                                                    {
                                                                        snackbar.closeSnackbar(key);

                                                                        return readNotification();
                                                                    }}
                                                                color="white"
                                                            />
                                                        </ViewGroupItem>
                                                    </ViewGroup>,
                                            persist: true,
                                            variant: notistackVariant
                                        });
                                }
                            });
                }
            },
            [
                markAsRead,
                newNotifications,
                types,
                snackbar,
                enqueuedNotificationIds
            ]);

        const [ isOpen, open, close ] = useSwitch(false);
        const toggle =
            useCallback(
                () =>
                {
                    if (isOpen)
                    {
                        close();
                    }
                    else
                    {
                        open();
                    }
                },
                [
                    isOpen,
                    close,
                    open
                ]);

        return <>
            <IconButton
                icon="notifications"
                onClick={toggle}
                tooltip={
                    <LocalizedText
                        code="Notification.Button.Tooltip"
                        value="Notificaties: ${unreadNotificationCount} ongelezen"
                        unreadNotificationCount={unreadNotificationCount}
                    />
                }
                iconSize={22}
                color="white"
                badge={unreadNotificationCount}
            />
            {
                isOpen &&
                    <Drawer
                        open={isOpen}
                        onClose={close}
                        variant="temporary"
                        anchor="right"
                    />
            }
        </>;
    };

export default observer(NotificationButton);
