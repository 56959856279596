import AutomationDependencyContext from '../AutomationDependencyContext';
import { observable } from 'mobx';
import Predicate from '../Function/Computation/Predicate/Predicate';
import { EntityType } from '../../Model/Implementation/EntityType';
import Query from './Query';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import ValueType from '../Value/Type/ValueType';
import EntityValueType from '../Value/Type/EntityValueType';
import { Aggregate } from '../../../@Component/Domain/DataObject/Model/Aggregate';
import { EntityFieldPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import PrimitiveValueType from '../Value/Type/PrimitiveValueType';
import { DataObjectStore } from '../../../@Component/Domain/DataObject/DataObjectStore';
import Validation from '../Validation/Validation';
import FunctionContext from '../Function/FunctionContext';
import Value from '../Value/Value';
import { EntitySelectionBuilder } from '../../../@Component/Domain/Entity/Selection/Builder/EntitySelectionBuilder';
import PrimitiveValue from '../Value/PrimitiveValue';
import { QuerySearchParameters } from './QuerySearchParameters';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import getTypes from '../../../@Component/Domain/Entity/Type/Api/getTypes';
import Parameter from '../Parameter/Parameter';

export default class AggregateQuery extends Query
{
    // ------------------------- Properties -------------------------

    @observable aggregate: Aggregate;
    @observable.ref aggregateFieldPath: EntityFieldPath;

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType,
                parameter: Parameter<EntityValueType>,
                filter: Predicate,
                aggregate: Aggregate,
                aggregateFieldPath?: EntityFieldPath)
    {
        super(entityType, parameter, filter);

        this.aggregate = aggregate;
        this.aggregateFieldPath = aggregateFieldPath;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        const validations = super.validate();

        if (this.aggregate !== Aggregate.Count
            && !this.aggregateFieldPath)
        {
            validations.push(
                new Validation(
                    'Error',
                    'Geen geldig veld om op te aggregeren geselecteerd.'));
        }

        return validations;
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Aggregate';
        descriptor.aggregate = Aggregate[this.aggregate];
        descriptor.aggregateFieldPath = this.aggregateFieldPath?.descriptor;
    }

    getType(): ValueType<any>
    {
        if (this.aggregate === Aggregate.Count)
        {
            return new PrimitiveValueType(
                loadModuleDirectly(DataObjectStore)
                    .getTypeById('Number'));
        }
        else
        {
            if (this.aggregateFieldPath.isField)
            {
                return new PrimitiveValueType(
                    this.aggregateFieldPath.field.dataObjectSpecification.type);
            }
            else
            {
                return new EntityValueType(
                    this.aggregateFieldPath.path.entityType);
            }
        }
    }

    async execute(context: FunctionContext,
                  searchParameters?: QuerySearchParameters): Promise<Value<any, any>>
    {
        const selectionBuilder =
            EntitySelectionBuilder.builder(
                this.entityType,
                builder =>
                    builder
                        .if(
                            () => this.filter !== undefined,
                            () =>
                                builder.where(
                                    cb =>
                                        cb.filter(
                                            this.filter,
                                            {
                                                context,
                                                parameter: this.parameter,
                                            }
                                        )
                                )
                        )
                        .if(
                            () => searchParameters !== undefined,
                            () =>
                                this.augmentBuilderWithSearchParameters(
                                    builder,
                                    searchParameters))
                        .aggregateOn(
                            this.aggregateFieldPath
                            ??
                            // in case of count aggregate
                            EntityPath.root(this.entityType)
                                .field(getTypes().Entity.Field.Id),
                            undefined,
                            this.aggregate
                        )
            );
        const results = await selectionBuilder.selectAggregates();

        return new PrimitiveValue(results.aggregates[0]);
    }

    static async fromDescriptor(dependencyContext: AutomationDependencyContext,
                                descriptor: any)
    {
        const entityType = loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.entityTypeId);
        const parameter =
            Query.getResultParameter(
                entityType,
                descriptor.parameterId
            );

        return new AggregateQuery(
            entityType,
            parameter,
            undefined,
            (Aggregate as any)[descriptor.aggregate],
            descriptor.aggregateFieldPath
                ?
                    EntityFieldPath.construct(
                        descriptor.aggregateFieldPath)
                :
                    undefined);
    }

    // ----------------------- Private logic ------------------------
}
