import * as React from 'react';
import { DataObjectBespokeEditor } from '../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { observer } from 'mobx-react';
import { DateEditorStore } from './DateEditorStore';
import { default as GenericDateEditor } from '../../../../../@Future/Component/Generic/Input/DateEditor/DateEditor';

@observer
export class DateEditor extends DataObjectBespokeEditor<DateEditorStore>
{
    renderComponent(store: DataObjectEditorStore<DateEditorStore>)
    {
        return <GenericDateEditor
            value={store.dataObject.value}
            onChange={store.changeValue}
            inputProps={store.handlerProperties as any}
            disableUnderline
            fitContent={store.representation?.data?.fitContent}
            placeholder={store.placeholder}
        />;
    }
}
