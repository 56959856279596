import LayoutDependencyContext from '../../LayoutDependencyContext';
import EmptyAction from '../Type/EmptyAction';
import LayoutAction from '../LayoutAction';
import RouteAction from '../Type/RouteAction';
import CompositeAction from '../Type/CompositeAction';
import PortalActionInvocationAction from '../Type/PortalActionInvocationAction';
import SetFormParameterAction from '../Type/SetFormParameterAction';
import PortalDataSourceFileDownloadAction from '../Type/PortalDataSourceFileDownloadAction';
import ActionTemplateInvocationAction from '../Type/ActionTemplateInvocationAction';
import DownloadFileAction from '../Type/DownloadFileAction';
import RefreshAction from "../Type/RefreshAction";

export default async function getLayoutActionFromDescriptor(
    descriptor: any,
    dependencyContext: LayoutDependencyContext
): Promise<LayoutAction>
{
    switch (descriptor.type)
    {
        case 'Empty':
            return EmptyAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'Composite':
            return CompositeAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'Route':
            return RouteAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'Refresh':
            return RefreshAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'SetFormParameter':
            return SetFormParameterAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'DownloadFile':
            return DownloadFileAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'ActionTemplateInvocation':
            return ActionTemplateInvocationAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'PortalActionInvocation':
            return PortalActionInvocationAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'PortalDataSourceFileDownload':
            return PortalDataSourceFileDownloadAction.fromDescriptor(
                descriptor,
                dependencyContext
            );
    }

    throw new Error('Layout action type not found: ' + descriptor.type);
}
