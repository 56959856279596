import React, { useCallback, useContext } from 'react';
import TypeMapping from '../../../Model/TypeMapping';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { IObservableArray, runInAction } from 'mobx';
import { observer, useComputed } from 'mobx-react-lite';
import uuid from '../../../../../../../@Util/Id/uuid';
import Switch from '../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import RelationshipFieldMapping from '../../../Model/FieldMapping/Relationship/RelationshipFieldMapping';
import useTypes from '../../../../Type/Api/useTypes';
import ValueFieldMapping from '../../../Model/FieldMapping/Constant/ValueFieldMapping';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import FileContext from '../../../Context/FileContext';
import FieldMapping from '../../FieldMappings/FieldMapping/FieldMapping';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import getExternalId from '../../../Api/getExternalId';
import { UserDefinedImportKeyService } from '../../../Api/Constants';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface CreateExternalIdProps
{
    typeMapping: TypeMapping;
    entity: Entity;
    commitContext: CommitContext;
}

const CreateExternalId: React.FC<CreateExternalIdProps> =
    props =>
    {
        const types = useTypes();
        const fileValue = useContext(FileContext);

        const externalIdFieldMapping =
            useComputed(
                () =>
                    fileValue &&
                        props.typeMapping.fieldMappings
                            .find(
                                fieldMapping =>
                                    fieldMapping instanceof RelationshipFieldMapping
                                        && fieldMapping.typeMapping.entityType.isA(types.ExternalId.Type)
                                        && fieldMapping.typeMapping.fieldMappings.some(
                                        externalIdFieldMapping =>
                                            externalIdFieldMapping instanceof ValueFieldMapping &&
                                            externalIdFieldMapping.targetFieldPath.field === types.ExternalId.Field.Type &&
                                            externalIdFieldMapping.value.value === getExternalId(fileValue, props.typeMapping))) as RelationshipFieldMapping | undefined,
                [
                    props.typeMapping,
                    fileValue,
                    types
                ]);

        const externalIdFieldPath =
            useComputed(
                () =>
                    EntityPath.fromEntityType(types.ExternalId.Type)
                        .field(types.ExternalId.Field.Id),
                [
                    types
                ]);

        const toggleExternalId =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            if (externalIdFieldMapping)
                            {
                                (props.typeMapping.fieldMappings as IObservableArray).remove(externalIdFieldMapping);
                            }
                            else
                            {
                                const rootPath = EntityPath.fromEntityType(types.ExternalId.Type);

                                props.typeMapping.fieldMappings.push(
                                    new RelationshipFieldMapping(
                                        uuid(),
                                        EntityPath.fromEntityType(props.typeMapping.entityType)
                                            .joinTo(
                                                types.Entity.RelationshipDefinition.ExternalIds,
                                                false)
                                            .field(),
                                        new TypeMapping(
                                            uuid(),
                                            rootPath.entityType,
                                            [
                                                new ValueFieldMapping(
                                                    uuid(),
                                                    rootPath.field(types.ExternalId.Field.Service),
                                                    DataObject.constructFromTypeIdAndValue('Text', UserDefinedImportKeyService)),
                                                new ValueFieldMapping(
                                                    uuid(),
                                                    rootPath.field(types.ExternalId.Field.Type),
                                                    DataObject.constructFromTypeIdAndValue('Text', getExternalId(fileValue, props.typeMapping)))
                                            ],
                                            [])));
                            }
                        }),
                [
                    externalIdFieldMapping,
                    props.typeMapping,
                    types,
                    fileValue
                ]);

        if (fileValue)
        {
            return <CardInset>
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                            alignment="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <LocalizedText
                                    code="Import.TypeMapping.AddNewIdToTypeForLaterImport"
                                    value="Voeg een ID toe aan de nieuwe ${type} voor gebruik in latere imports."
                                    type={props.typeMapping.entityType.getName().toLowerCase()}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <Switch
                                    onToggle={toggleExternalId}
                                    checked={externalIdFieldMapping !== undefined}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    {
                        externalIdFieldMapping &&
                        <ViewGroupItem>
                            <FieldMapping
                                typeMapping={externalIdFieldMapping.typeMapping}
                                targetFieldPath={externalIdFieldPath}
                                entity={props.entity}
                                commitContext={props.commitContext}
                            />
                        </ViewGroupItem>
                    }
                </ViewGroup>
            </CardInset>;
        }
        else
        {
            return null;
        }
    };

export default observer(CreateExternalId);
