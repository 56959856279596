import React, { useCallback, useMemo } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../Type/Api/useTypes';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import getLayoutFromDescriptor from '../../../../../../../../@Api/Layout/Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../../../../@Api/Layout/LayoutDependencyContext';
import Centered from '../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../@Future/Component/Generic/Loader/Loader';
import CompositeLayout from '../../../../../../../../@Api/Layout/Type/CompositeLayout';
import Layout from '../../../../../../../../@Api/Layout/Layout';
import LayoutEditor from '../../../../../../Layout/Editor/LayoutEditor';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import RightAlignedButtonGroup from '../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import ClearButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/ClearButton/ClearButton';
import getPortalParameters from '../../Api/getPortalParameters';
import { CommitBuilder } from '../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import FormLayout from '../../../../../../../../@Api/Layout/Type/Form/FormLayout';
import uuid from '../../../../../../../../@Util/Id/uuid';
import ParameterDictionary from '../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import CompositeLayoutItem from '../../../../../../../../@Api/Layout/Type/CompositeLayoutItem';
import Parameter from '../../../../../../../../@Api/Automation/Parameter/Parameter';
import TextComputation from '../../../../../../../../@Api/Automation/Function/Computation/TextComputation';
import InsetLayout from '../../../../../../../../@Api/Layout/Type/InsetLayout';
import Dimension from '../../../../../../../../@Api/Layout/Style/Dimension';
import Color from '../../../../../../../../@Api/Layout/Style/Color';
import BodyLayout from '../../../../../../../../@Api/Layout/Type/BodyLayout';
import CenteredBodyLayout from '../../../../../../../../@Api/Layout/Type/CenteredBodyLayout';
import CardLayout from '../../../../../../../../@Api/Layout/Type/CardLayout';
import ComputationLayout from '../../../../../../../../@Api/Layout/Type/ComputationLayout';
import CardHeader from '../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { default as EntityInput } from '../../../../../Input/Input';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { EntityField } from '../../../../../../../../@Api/Model/Implementation/EntityField';

export interface PortalEmailLayoutEditorProps
{
    entity: Entity;
    subjectField: EntityField;
    bodyField: EntityField;
    parameters: Parameter<any>[];
    commitContext?: CommitContext;
}

const PortalEmailLayoutEditor: React.FC<PortalEmailLayoutEditorProps> =
    ({
        entity,
        subjectField,
        bodyField,
        parameters,
        commitContext,
     }) =>
    {
        const types = useTypes();

        const emailSubjectDescriptor = useEntityValue(entity, subjectField);
        const emailBodyDescriptor = useEntityValue(entity, bodyField);

        const [ parameterDictionary, isLoadingParameters ] =
            useAsyncResult(
                () =>
                    getPortalParameters(entity),
                [
                    entity
                ]
            );

        const bodyParameterDictionary =
            useMemo(
                () =>
                    new ParameterDictionary(
                        isLoadingParameters
                            ? []
                            : [
                                ...parameterDictionary.parameters,
                                ...parameters,
                            ]
                    ),
                [
                    parameterDictionary,
                    parameters,
                    isLoadingParameters
                ]
            );

        const [ emailSubject, isLoadingEmailSubject ] =
            useAsyncResult<Layout>(
                async () =>
                {
                    if (emailSubjectDescriptor && parameterDictionary)
                    {
                        return getLayoutFromDescriptor(
                            emailSubjectDescriptor,
                            new LayoutDependencyContext(parameterDictionary)
                        );
                    }
                    else
                    {
                        return new FormLayout(
                            uuid(),
                            new ParameterDictionary([]),
                            new CompositeLayout(
                                'Vertical',
                                [
                                    new CompositeLayoutItem(
                                        uuid(),
                                        new ComputationLayout(
                                            new TextComputation('', false, [])
                                        ),
                                        undefined)
                                ]
                            )
                        );
                    }
                },
                [
                    emailSubjectDescriptor,
                    parameterDictionary
                ]
            );

        const [ emailBody, isLoadingEmailBody ] =
            useAsyncResult<Layout>(
                async () =>
                {
                    if (emailBodyDescriptor && bodyParameterDictionary)
                    {
                        return getLayoutFromDescriptor(
                            emailBodyDescriptor,
                            new LayoutDependencyContext(bodyParameterDictionary)
                        );
                    }
                    else
                    {
                        return new FormLayout(
                            uuid(),
                            new ParameterDictionary([]),
                            new CompositeLayout(
                                'Vertical',
                                [
                                    new CompositeLayoutItem(
                                        uuid(),
                                        new BodyLayout(
                                            new InsetLayout(
                                                new CenteredBodyLayout(
                                                    new CardLayout(
                                                        new CompositeLayout(
                                                            'Vertical',
                                                            []
                                                        )
                                                    ),
                                                    new Dimension(
                                                        600,
                                                        'Pixels'
                                                    )
                                                ),
                                                15,
                                                0,
                                                15,
                                                0),
                                            new Color('#f5f5f5')
                                        ),
                                        undefined
                                    )
                                ]
                            )
                        );
                    }
                },
                [
                    emailBodyDescriptor,
                    bodyParameterDictionary
                ]
            );

        const clearEmailSubject =
            useCallback(
                () =>
                    new CommitBuilder()
                        .setObjectValueInEntity(
                            entity,
                            subjectField,
                            undefined
                        )
                        .commit(),
                [
                    entity,
                    subjectField,
                ]
            );
        const clearEmailBody =
            useCallback(
                () =>
                    new CommitBuilder()
                        .setObjectValueInEntity(
                            entity,
                            bodyField,
                            undefined
                        )
                        .commit(),
                [
                    entity,
                    bodyField,
                ]
            );
        const saveEmailSubject =
            useCallback(
                () =>
                    new CommitBuilder()
                        .setObjectValueInEntity(
                            entity,
                            subjectField,
                            emailSubject.toDescriptor()
                        )
                        .commit(),
                [
                    entity,
                    subjectField,
                    emailSubject,
                ]
            );
        const saveEmailBody =
            useCallback(
                () =>
                    new CommitBuilder()
                        .setObjectValueInEntity(
                            entity,
                            bodyField,
                            emailBody.toDescriptor()
                        )
                        .commit(),
                [
                    entity,
                    bodyField,
                    emailBody,
                ]
            );

        if (isLoadingParameters || isLoadingEmailSubject || isLoadingEmailBody)
        {
            return <Centered
                horizontal
                vertical
            >
                <Loader />
            </Centered>;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <Card>
                        <CardInset>
                            <ViewGroup
                                orientation="vertical"
                                spacing={15}
                            >
                                <ViewGroupItem>
                                    <EntityInput
                                        entity={entity}
                                        field={types.Portal.RelationshipDefinition.EmailSender}
                                        commitContext={commitContext}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <Input
                                        label={
                                            <LocalizedText
                                                code="Email.Subject"
                                                value="E-mail onderwerp"
                                            />
                                        }
                                        labelPosition="left"
                                    >
                                        <LayoutEditor
                                            layout={emailSubject}
                                            parameterDictionary={parameterDictionary}
                                        />
                                    </Input>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <RightAlignedButtonGroup>
                                        <ClearButton
                                            onClick={clearEmailSubject}
                                        />
                                        <SaveButton
                                            onClick={saveEmailSubject}
                                        />
                                    </RightAlignedButtonGroup>
                                </ViewGroupItem>
                            </ViewGroup>
                        </CardInset>
                    </Card>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Card>
                        <CardInset>
                            <ViewGroup
                                orientation="vertical"
                                spacing={15}
                            >
                                <ViewGroupItem>
                                    <CardHeader>
                                        <LocalizedText
                                            code="Email.Body"
                                            value="E-mailtekst"
                                        />
                                    </CardHeader>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <LayoutEditor
                                        layout={emailBody}
                                        parameterDictionary={bodyParameterDictionary}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <RightAlignedButtonGroup>
                                        <ClearButton
                                            onClick={clearEmailBody}
                                        />
                                        <SaveButton
                                            onClick={saveEmailBody}
                                        />
                                    </RightAlignedButtonGroup>
                                </ViewGroupItem>
                            </ViewGroup>
                        </CardInset>
                    </Card>
                </ViewGroupItem>
            </ViewGroup>
        }
    };

export default observer(PortalEmailLayoutEditor);
