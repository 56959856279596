import { Entity } from '../../../../../../../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../../../../../../../Type/Api/useTypes';
import useSpotlerCampaignResultCount from '../Hooks/useSpotlerCampaignResultViewCount';
import localizeText from '../../../../../../../../../../../../../../@Api/Localization/localizeText';
import React from 'react';
import NumberChart from './NumberChart';
import { observer } from 'mobx-react-lite';

export interface SpotlerCampaignResultChartViewedProps
{
    size: number;
    campaignResult: Entity;
}

export const SpotlerCampaignResultChartViewed: React.FC<SpotlerCampaignResultChartViewedProps> =
    observer(
        (
            {
                campaignResult,
                size,
            }
        ) =>
        {
            const types = useTypes();
            const viewCount =
                useSpotlerCampaignResultCount(
                    campaignResult,
                    types.SpotlerCampaignEvent.EventType.View
                );

            return <div>
                <NumberChart
                    value={viewCount}
                    size={size}
                    label={localizeText('Campaign.Chart.Opened', 'Geopend')}
                />
            </div>;
        }
    );