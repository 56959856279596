import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../Value/PrimitiveValue';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Value from '../../Value/Value';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import localizeText from '../../../Localization/localizeText';

export default class UrlEncodedTextComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref value: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(value: Computation<any, any>)
    {
        super();

        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('Text')
        );
    }

    getName(): string
    {
        return `${localizeText('UrlEncodedText', 'Url encoded tekst')}...`;
    }

    validate(): Validation[]
    {
        return this.value.validate();
    }

    isAsync(): boolean
    {
        return this.value.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const value = await safelyApplyFunction(this.value, context);

        return this.getUrlEncodedText(value);
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        const value = safelySynchronousApplyFunction(this.value, context);

        return this.getUrlEncodedText(value);
    }

    getUrlEncodedText(value: Value<any, any>): Value<any, any>
    {
        const textValue: string = value.getName() ?? "";
        const encodedText = encodeURIComponent(textValue);

        return new PrimitiveValue(
            DataObject.constructFromTypeIdAndValue(
                'Text',
                encodedText
            )
        );
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'UrlEncodedText';
        descriptor.value = this.value.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.value.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new UrlEncodedTextComputation(
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
