import useExpansion from '../../../../../../@Future/Component/Generic/ExpansionPanel/Hook/useExpansion';
import { useContext, useMemo } from 'react';
import NavigatorContext from '../NavigatorContext/NavigatorContext';
import useUniqueItemId from '../../Api/useUniqueItemId';

export default function useIsContextExpanded()
{
    const context = useContext(NavigatorContext);
    const id = useUniqueItemId();
    const [ isGloballyExpanded ] = useExpansion(id);

    return useMemo(
        () =>
            isGloballyExpanded && context.isExpanded,
        [
            context.isExpanded,
            isGloballyExpanded
        ]);
}
