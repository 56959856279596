import React, { useCallback, useContext, useState } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../../Type/Api/useTypes';
import uuid from '../../../../../../../@Util/Id/uuid';
import { Comparator } from '../../../../../DataObject/Model/Comparator';
import CardHeader from '../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CurrentUserContext from '../../../../../User/CurrentUserContext';
import ButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/ButtonGroup';
import PrimaryTextButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryTextButton/PrimaryTextButton';
import { createTransactionalModel } from '../../../../../../../@Util/TransactionalModelV2/index';
import MutationTrigger from '../../../../../../../@Api/Automation/Trigger/MutationTrigger';
import Automation from '../../../../../../../@Api/Automation/Automation';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import CreationTrigger from '../../../../../../../@Api/Automation/Trigger/CreationTrigger';
import ComparisonPredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import CompositeAction from '../../../../../../../@Api/Automation/Function/Action/CompositeAction';
import CompositeActionInvocation from '../../../../../../../@Api/Automation/Function/Action/CompositeActionInvocation';
import CreateEntityAction from '../../../../../../../@Api/Automation/Function/Action/CreateEntityAction';
import Mapping from '../../../../../../../@Api/Automation/Mapping/Mapping';
import ValueFieldMapping from '../../../../../../../@Api/Automation/Mapping/Field/ValueFieldMapping';
import RelationshipInput from '../../../../../Multiplayer/Model/Input/RelationshipInput';
import ValueFromEntityComputation from '../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import EntityValue from '../../../../../../../@Api/Automation/Value/EntityValue';
import Popper from '../../../../../../../@Future/Component/Generic/Popper/Popper';
import Menu from '../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import isHiddenType from '../../../../../../../@Api/Metadata/EntityType/isHiddenType';
import { openEntity } from '../../../../@Util/openEntity';
import FieldInput from '../../../../../Multiplayer/Model/Input/FieldInput';
import useDividerGlue from '../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import TextComputation from '../../../../../../../@Api/Automation/Function/Computation/TextComputation';
import MappingFieldMapping from '../../../../../../../@Api/Automation/Mapping/Field/MappingFieldMapping';
import DynamicAction from '../../../../../../../@Api/Automation/Function/Action/DynamicAction';
import DynamicFunctionInvocation from '../../../../../../../@Api/Automation/Function/Dynamic/DynamicFunctionInvocation';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/index';
import DynamicFunctionRepositoryManager from '../../../../../../../@Api/Automation/Function/Dynamic/Repository/DynamicFunctionRepositoryManager';
import DynamicParameterAssignment from '../../../../../../../@Api/Automation/Function/Dynamic/DynamicParameterAssignment';
import ServerFunction from '../../../../../../../@Api/Automation/Function/Dynamic/Repository/Server/ServerFunction';
import getDatastoreByCode from '../../../../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import ComputationInText from '../../../../../../../@Api/Automation/Function/Computation/ComputationInText';
import UpdateTrigger from '../../../../../../../@Api/Automation/Trigger/UpdateTrigger';
import CompositePredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../../DataObject/Model/LogicalOperator';
import EmptyValue from '../../../../../../../@Api/Automation/Value/EmptyValue';
import ConditionalComputation from '../../../../../../../@Api/Automation/Function/Computation/ConditionalComputation';
import ConditionalInComputation from '../../../../../../../@Api/Automation/Function/Computation/ConditionalInComputation';
import EntityValueType from '../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { AutomationsTable } from '../../../../../Configuration/Page/Automations/AutomationManager/Table/AutomationsTable';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export interface MailboxAutomationsProps
{
    entity: Entity;
}

const MailboxAutomations: React.FC<MailboxAutomationsProps> =
    ({
        entity,
     }) =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);
        const constructAndActivateAutomation =
            useCallback(
                async (name: string, automation: Automation) =>
                {
                    const automationEntity =
                        createTransactionalModel(
                            new Entity(types.Automation.Type)
                                .initialize());

                    automationEntity.setValueByField(
                        types.Automation.Field.LocalizedName,
                        name);

                    automationEntity.setValueByField(
                        types.Automation.Field.Specification,
                        automation.toDescriptor());

                    automationEntity.updateRelationship(
                        true,
                        types.Mailbox.RelationshipDefinition.Automations,
                        createTransactionalModel(entity));

                    automationEntity.updateRelationship(
                        true,
                        types.EntityType.RelationshipDefinition.Automations,
                        types.Activity.Email.Type.entity
                    );

                    const activationEntity =
                        createTransactionalModel(
                            new Entity(types.AutomationActivation.Type)
                                .initialize());

                    activationEntity.updateRelationship(
                        true,
                        types.Automation.RelationshipDefinition.Activations,
                        automationEntity);

                    activationEntity.updateRelationship(
                        true,
                        types.Relation.Organization.Environment.RelationshipDefinition.AutomationActivations,
                        createTransactionalModel(currentUserStore.environmentEntity));

                    return activationEntity.checkAndDoCommit()
                        .then(
                            () =>
                                openEntity(automationEntity));
                },
                [
                    types,
                    entity,
                    currentUserStore
                ]);
        const constructTrigger =
            useCallback(
                () =>
                {
                    const trigger =
                        new UpdateTrigger(
                            types.Activity.Email.Type,
                            undefined,
                            new RelationshipInput(
                                types.Activity.Email.Type,
                                types.Relationship.RelationshipDefinition.Activities,
                                true));

                    const parameterDictionary = new ParameterDictionary(trigger.getParameters());
                    const entityParameter = parameterDictionary.getParameterById(MutationTrigger.EntityParameterId);

                    trigger.predicate =
                        new CompositePredicate(
                            LogicalOperator.And,
                            [
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        entityParameter,
                                        trigger.entityType.path()
                                            .joinTo(
                                                types.Mailbox.RelationshipDefinition.Emails,
                                                true)
                                            .field()),
                                    Comparator.Equals,
                                    new EntityValue(entity)),
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        entityParameter,
                                        trigger.entityType.path()
                                            .joinTo(
                                                types.Relationship.RelationshipDefinition.Activities,
                                                true)
                                            .field()),
                                    Comparator.IsDefined,
                                    EmptyValue.instance),
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        entityParameter,
                                        trigger.entityType.path()
                                            .joinTo(
                                                types.Activity.RelationshipDefinition.LinkedActivities,
                                                true)
                                            .field()),
                                    Comparator.IsNotDefined,
                                    EmptyValue.instance)
                            ]);

                    return [ trigger, entityParameter ] as [ CreationTrigger, Parameter<any> ];
                },
                [
                    types,
                    entity
                ]);

        const createAutomaticResponseCompositeAction =
            useCallback(
                async (entityParameter: Parameter<EntityValueType>,
                       activityParameter?: Parameter<EntityValueType>) =>
                {
                    const entityType = types.Activity.Email.Type;
                    const sendEmailActionId = uuid();

                    const dynamicFunctionRepository = loadModuleDirectly(DynamicFunctionRepositoryManager);
                    const serverRepository = dynamicFunctionRepository.getRepositoryById('Server');
                    const sendEmailAction: ServerFunction<any, any> = await serverRepository.getFunctionById('SendEmail');

                    const conceptPhase = await getDatastoreByCode(types.Datastore.Phase.ActivityEmail.Type, types.Activity.Email.State.Concept);

                    const activityNumberId = uuid();
                    const subjectFieldId = uuid();
                    const subjectFieldOtherwiseId = uuid();

                    const numberComputation =
                        activityParameter
                            ?
                                new ValueFromEntityComputation(
                                    activityParameter,
                                    activityParameter.type.type.path()
                                        .field(types.Activity.Field.Number))
                            :
                                new ValueFromEntityComputation(
                                    entityParameter,
                                    entityType.path()
                                        .joinTo(
                                            types.Activity.RelationshipDefinition.LinkedActivities,
                                            true)
                                        .field(types.Activity.Field.Number));

                    const createEmailActionInvocation =
                        new CompositeActionInvocation(
                            sendEmailActionId,
                            new CreateEntityAction(
                                new Mapping(
                                    entityType,
                                    Mapping.buildParameter(entityType),
                                    [
                                        new MappingFieldMapping(
                                            new RelationshipInput(
                                                entityType,
                                                types.Activity.Email.RelationshipDefinition.To,
                                                false),
                                            new Mapping(
                                                types.Recipient.Email.Type,
                                                Mapping.buildParameter(types.Recipient.Email.Type),
                                                [
                                                    new ValueFieldMapping(
                                                        new FieldInput(
                                                            types.Recipient.Email.Type,
                                                            types.Recipient.Email.Field.EmailAddress),
                                                        new ValueFromEntityComputation(
                                                            entityParameter,
                                                            entityType.path()
                                                                .joinTo(
                                                                    types.Activity.Email.RelationshipDefinition.From,
                                                                    false)
                                                                .field(types.Recipient.Email.Field.EmailAddress)))
                                                ],
                                                [])),
                                        new ValueFieldMapping(
                                            new RelationshipInput(
                                                entityType,
                                                types.Relationship.RelationshipDefinition.Activities,
                                                true),
                                            new ValueFromEntityComputation(
                                                entityParameter,
                                                entityType.path()
                                                    .joinTo(
                                                        types.Relationship.RelationshipDefinition.Activities,
                                                        true)
                                                    .field())),
                                        new ValueFieldMapping(
                                            new RelationshipInput(
                                                entityType,
                                                types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                                true),
                                            new ValueFromEntityComputation(
                                                entityParameter,
                                                entityType.path()
                                                    .joinTo(
                                                        types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                                        true)
                                                    .field())),
                                        new ValueFieldMapping(
                                            new FieldInput(
                                                entityType,
                                                types.Activity.Field.Subject),
                                            new ConditionalComputation(
                                                [
                                                    new ConditionalInComputation(
                                                        new ComparisonPredicate(
                                                            numberComputation,
                                                            Comparator.IsDefined,
                                                            EmptyValue.instance),
                                                        new TextComputation(
                                                            `[<span><computation data-id="${activityNumberId}" /></span>] Re: <computation data-id="${subjectFieldId}" />`,
                                                            false,
                                                            [
                                                                new ComputationInText(
                                                                    activityNumberId,
                                                                    numberComputation),
                                                                new ComputationInText(
                                                                    subjectFieldId,
                                                                    new ValueFromEntityComputation(
                                                                        entityParameter,
                                                                        entityType.path()
                                                                            .field(types.Activity.Field.Subject)))
                                                            ]))
                                                ],
                                                new TextComputation(
                                                    `Re: <computation data-id="${subjectFieldOtherwiseId}" />`,
                                                    false,
                                                    [
                                                        new ComputationInText(
                                                            subjectFieldOtherwiseId,
                                                            new ValueFromEntityComputation(
                                                                entityParameter,
                                                                entityType.path()
                                                                    .field(types.Activity.Field.Subject)))
                                                    ]))),
                                        new ValueFieldMapping(
                                            new FieldInput(
                                                entityType,
                                                types.Activity.Email.Field.HTML
                                            ),
                                            new TextComputation(
                                                localizeText(
                                                    'MailboxAutomations.AutomaticReplyText',
                                                    'Dit is een automatisch antwoord.'
                                                ),
                                                true,
                                                []
                                            )
                                        ),
                                        new ValueFieldMapping(
                                            new RelationshipInput(
                                                entityType,
                                                types.Activity.Email.RelationshipDefinition.Replies,
                                                true),
                                            entityParameter),
                                        new ValueFieldMapping(
                                            new RelationshipInput(
                                                entityType,
                                                types.Activity.Email.RelationshipDefinition.Phase,
                                                false),
                                            new EntityValue(conceptPhase)),
                                        ...activityParameter
                                            ?
                                                [
                                                    new ValueFieldMapping(
                                                        new RelationshipInput(
                                                            entityType,
                                                            types.Activity.RelationshipDefinition.LinkedActivities,
                                                            true),
                                                        activityParameter)
                                                ]
                                            :
                                                []
                                    ],
                                    [])));

                    const createEmailActionParameter = createEmailActionInvocation.getResultParameter();

                    const sendEmailParameterDictionary = new ParameterDictionary(sendEmailAction.getParameters());
                    const sendEmailParameterAssignment = new DynamicParameterAssignment();
                    sendEmailParameterAssignment.setComputation(
                        sendEmailParameterDictionary.getParameterById('Entity'),
                        createEmailActionParameter);

                    const sendEmailActionInvocation =
                        new CompositeActionInvocation(
                            uuid(),
                            new DynamicAction(
                                new DynamicFunctionInvocation(
                                    sendEmailParameterDictionary,
                                    sendEmailParameterAssignment,
                                    serverRepository,
                                    'Action',
                                    sendEmailAction)));

                    return new CompositeAction(
                        [
                            createEmailActionInvocation,
                            sendEmailActionInvocation
                        ]
                    );
                },
                [
                    types
                ]
            );

        const createActivityAutomation =
            useCallback(
                async (entityType: EntityType, isWithAutomaticReply: boolean) =>
                {
                    const emailType = types.Activity.Email.Type;
                    const [ trigger, entityParameter ] = constructTrigger();

                    const createActivityAction =
                        new CompositeActionInvocation(
                            uuid(),
                            new CreateEntityAction(
                                new Mapping(
                                    entityType,
                                    Mapping.buildParameter(entityType),
                                    [
                                        new ValueFieldMapping(
                                            new RelationshipInput(
                                                entityType,
                                                types.Relationship.RelationshipDefinition.Activities,
                                                true),
                                            new ValueFromEntityComputation(
                                                entityParameter,
                                                emailType.path()
                                                    .joinTo(
                                                        types.Relationship.RelationshipDefinition.Activities,
                                                        true)
                                                    .field())),
                                        new ValueFieldMapping(
                                            new RelationshipInput(
                                                entityType,
                                                types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                                true),
                                            new ValueFromEntityComputation(
                                                entityParameter,
                                                emailType.path()
                                                    .joinTo(
                                                        types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                                        true)
                                                    .field())),
                                        new ValueFieldMapping(
                                            new FieldInput(
                                                entityType,
                                                types.Activity.Field.Subject),
                                            new ValueFromEntityComputation(
                                                entityParameter,
                                                emailType.path()
                                                    .field(types.Activity.Field.Subject))),
                                        new ValueFieldMapping(
                                            new RelationshipInput(
                                                entityType,
                                                types.Activity.RelationshipDefinition.LinkedActivities,
                                                false),
                                            entityParameter)
                                    ],
                                    [])));

                    const compositeAutomaticReplyAction =
                        isWithAutomaticReply
                            ?
                                await createAutomaticResponseCompositeAction(
                                    entityParameter,
                                    createActivityAction.getResultParameter())
                            :
                                undefined;

                    const compositeAction =
                        new CompositeAction([
                            createActivityAction,
                            ...compositeAutomaticReplyAction
                                ?
                                compositeAutomaticReplyAction.invocations
                                :
                                []
                        ]);

                    const automationName =
                        isWithAutomaticReply
                            ?   localizeText(
                                'MailboxAutomations.AutomationNameWithReply',
                                '${entityName} mailbox: ${entityTypeName} aanmaken met automatisch antwoord',
                                {
                                    entityName: entity.name,
                                    entityTypeName: entityType.getName().toLowerCase()
                                }
                            )
                            :   localizeText(
                                'MailboxAutomations.AutomationNameWithoutReply',
                                '${entityName} mailbox: ${entityTypeName} aanmaken zonder automatisch antwoord',
                                {
                                    entityName: entity.name,
                                    entityTypeName: entityType.getName().toLowerCase()
                                }
                            );

                    return constructAndActivateAutomation(
                        automationName,
                        new Automation(
                            new ParameterDictionary([]),
                            trigger,
                            compositeAction));
                },
                [
                    types,
                    entity,
                    createAutomaticResponseCompositeAction,
                    constructAndActivateAutomation,
                    constructTrigger
                ]);

        const createAutomaticResponseAutomation =
            useCallback(
                async () =>
                {
                    const [ trigger, entityParameter ] = constructTrigger();
                    const compositeAction = await createAutomaticResponseCompositeAction(entityParameter);

                    const automationName =
                        localizeText(
                            'MailboxAutomations.AutomationNameAutomaticResponse',
                            '${entityName} mailbox: automatisch antwoord',
                            {
                                entityName: entity.name
                            }
                        );

                    return constructAndActivateAutomation(
                        automationName,
                        new Automation(
                            new ParameterDictionary([]),
                            trigger,
                            compositeAction));
                },
                [
                    entity,
                    createAutomaticResponseCompositeAction,
                    constructAndActivateAutomation,
                    constructTrigger
                ]);

        const createOtherAutomation =
            useCallback(
                async () =>
                {
                    const [ trigger ] = constructTrigger();

                    const automationName =
                        localizeText(
                            'MailboxAutomations.AutomationNameOther',
                            '${entityName} mailbox: overig',
                            {
                                entityName: entity.name
                            }
                        );

                    return constructAndActivateAutomation(
                        automationName,
                        new Automation(
                            new ParameterDictionary([]),
                            trigger,
                            new CompositeAction([])));
                },
                [
                    entity,
                    constructAndActivateAutomation,
                    constructTrigger
                ]);

        const [ isMenuOpen, setMenuOpen ] = useState<boolean | undefined>();

        const dividerGlue = useDividerGlue();

        const automationsFilter =
            useCallback(
                (builder: EntitySelectionBuilder, rootPath: EntityPath) =>
                    builder.where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath.joinTo(
                                    types.Mailbox.RelationshipDefinition.Automations,
                                    true
                                ),
                                entity
                            )
                    ),
                [
                    types,
                    entity,
                ]
            );

        return <Card>
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={dividerGlue}
            >
                <ViewGroupItem>
                    <CardInset>
                        <ViewGroup
                            orientation="vertical"
                            spacing={5}
                        >
                            <ViewGroupItem>
                                <CardHeader>
                                    {types.Automation.Type.getName(true)}
                                </CardHeader>
                            </ViewGroupItem>
                        </ViewGroup>
                    </CardInset>
                </ViewGroupItem>
                <ViewGroupItem>
                    <AutomationsTable
                        filter={automationsFilter}
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <CardInset>
                        <ButtonGroup>
                            {
                                [ true, false ].map(
                                    isWithAutomaticReply =>
                                        <React.Fragment
                                            key={isWithAutomaticReply.toString()}
                                        >
                                            <Popper
                                                reference={
                                                    <PrimaryTextButton
                                                        label={
                                                            <>
                                                            + {isWithAutomaticReply
                                                                ?
                                                                    <LocalizedText
                                                                        code="MailboxAutomations.NewActivityOnIncomingMailWithReply"
                                                                        value="Activiteit bij nieuwe e-mail met automatische reply"
                                                                    />
                                                                :
                                                                    <LocalizedText
                                                                        code="MailboxAutomations.NewActivityOnIncomingMailWithoutReply"
                                                                        value="Activiteit bij nieuwe e-mail zonder automatische reply"
                                                                    />}
                                                            </>
                                                        }
                                                        onClick={() => setMenuOpen(isWithAutomaticReply)}
                                                    />
                                                }
                                                popper={
                                                    <Card>
                                                        <Menu>
                                                            {
                                                                types.Activity.Type.getAllInstantiableTypes()
                                                                    .filter(
                                                                        type =>
                                                                            !isHiddenType(type))
                                                                    .map(
                                                                        activityType =>
                                                                            <Item
                                                                                key={activityType.id}
                                                                                name={activityType.getName()}
                                                                                onClick={
                                                                                    () =>
                                                                                        createActivityAutomation(activityType, isWithAutomaticReply)
                                                                                }
                                                                            />)
                                                            }
                                                        </Menu>
                                                    </Card>
                                                }
                                                open={isMenuOpen === isWithAutomaticReply}
                                                onClose={() => setMenuOpen(undefined)}
                                            />
                                        </React.Fragment>)
                            }
                            <PrimaryTextButton
                                label={
                                    <>
                                        +
                                        <LocalizedText
                                            code="MailboxAutomations.ReplyWithoutNewActivity"
                                            value="Automatische reply (zonder activiteit)"
                                        />
                                    </>
                                }
                                onClick={createAutomaticResponseAutomation}
                            />
                            <PrimaryTextButton
                                label={
                                    <>
                                        +
                                        <LocalizedText
                                            code="Generic.Other"
                                            value="Overig"
                                        />
                                    </>
                                }
                                onClick={createOtherAutomation}
                            />
                        </ButtonGroup>
                    </CardInset>
                </ViewGroupItem>
            </ViewGroup>
        </Card>;
    };

export default observer(MailboxAutomations);
