// https://stackoverflow.com/questions/41604540/get-root-domain-name-without-extension-javascript
export default function getRootDomainWithoutExtension(url: string)
{
    // let rightPeriodIndex;

    for (var i = url.length - 1; i >= 0; i--)
    {
        if(url[i] === '.')
        {
            // rightPeriodIndex = i;
            var noExtension = url.substr(0,i);
            break;
        }
    }

    var result = noExtension.substring(noExtension.lastIndexOf('.') + 1);
    return result;
}
