import FormInputLayout from '../../../../../../../@Api/Layout/Type/Form/Input/FormInputLayout';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import { useComputed } from 'mobx-react-lite';
import safelySynchronousApplyFunction from '../../../../../../../@Api/Automation/Api/safelySynchronousApplyFunction';
import FunctionContext from '../../../../../../../@Api/Automation/Function/FunctionContext';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';

export default function useFormInputLayoutPlaceholder(
    layout: FormInputLayout,
    parameterDictionary: ParameterDictionary,
    parameterAssignment: ParameterAssignment,
    commitContext?: CommitContext
)
{
    return useComputed(
        () =>
        {
            const name =
                safelySynchronousApplyFunction(
                    layout.placeholder,
                    new FunctionContext(
                        parameterDictionary,
                        parameterAssignment,
                        commitContext
                    )
                )
                    .getName();

            if (name && name.length > 0)
            {
                return name;
            }
            else
            {
                return '...';
            }
        },
        [
            layout,
            parameterDictionary,
            parameterAssignment,
            commitContext,
        ])
}
