import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Grid, makeStyles, Table, TableCell, TableRow } from '@material-ui/core';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import useEvents from '../../../../Timeline/Api/useEvents';
import useTypes from '../../../../Type/Api/useTypes';
import { FieldMutationFilter } from '../../../../../../../@Api/Model/Implementation/FieldMutationFilter';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import InfiniteScroll from '../../../../../../../@Future/Component/Generic/InfiniteScroll/InfiniteScroll';
import { EntityEvent } from '../../../../../../../@Api/Model/Implementation/EntityEvent';
import { AutomationMutationLogRow } from './AutomationMutationLogRow';
import openDialog from '../../../../../../../@Service/Navigation/Page/Hooks/openDialog';
import { AutomationDiffViewer } from '../AutomationDiffViewer/AutomationDiffViewer';
import Dialog from '../../../../../../../@Future/Component/Generic/Dialog/Dialog';
import { EntityValueUpdateMutation } from '../../../../../../../@Api/Model/Implementation/EntityValueUpdateMutation';
import TableHead from '@material-ui/core/TableHead';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

;

export interface AutomationMutationLogTabProps
{
    entity: Entity;
}

const useStyles = makeStyles({
    dialogPaper:
        {
            height: '85vh',
            maxHeight: '85vh'
        }
});

export const AutomationMutationLogTab : React.FC<AutomationMutationLogTabProps> = observer(
    ({
         entity
    }) =>
    {
        const types = useTypes();
        const classes = useStyles();

        const eventFilters =
            useMemo(
                () => [
                    new FieldMutationFilter(
                        EntityPath.fromEntity(entity),
                        types.Automation.Field.Specification
                    )
                ],
                [
                    entity,
                    types
                ]);

        const [ events, isLoading, hasMore, loadMore ] =
            useEvents(
                types.Automation.Type,
                eventFilters,
                entity,
                false,
            );

        const onClick =
            useCallback(
                (event: EntityEvent) =>
                {
                    openDialog(
                        close =>
                             <Dialog
                                width={'xl'}
                                onClose={close}
                                className={classes.dialogPaper}
                                open
                             >
                                <AutomationDiffViewer
                                    event={event as EntityValueUpdateMutation}
                                    onClose={close}
                                />
                             </Dialog>
                         )
                },
                [
                    classes
                ]
            )

        return <ViewGroupItem>
            <Grid
                item
                xs={12}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <InfiniteScroll
                            loadMore={loadMore}
                            hasMore={hasMore}
                            isLoading={isLoading}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <LocalizedText
                                                code="DataObject.Type.DateTime"
                                                value="Datum met tijd"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <LocalizedText
                                                code="Generic.User"
                                                value="Gebruiker"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {
                                    events?.map(
                                        event =>
                                            <AutomationMutationLogRow
                                                event={event}
                                                onClick={onClick}
                                            />)
                                }
                            </Table>
                        </InfiniteScroll>
                    </ViewGroupItem>
                    {
                        isLoading &&
                        <ViewGroupItem>
                            <CardInset>
                                <Centered
                                    horizontal
                                >
                                    <Loader />
                                </Centered>
                            </CardInset>
                        </ViewGroupItem>
                    }
                </ViewGroup>
            </Grid>
        </ViewGroupItem>
    }
)