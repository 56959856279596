import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../../Viewer/LayoutViewer';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import EmptyValue from '../../../../../../@Api/Automation/Value/EmptyValue';
import { runInAction } from 'mobx';
import SelectionFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/SelectionFormInputLayout';
import Selectbox, { OptionMetadata, SelectionValue } from '../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import InsetLayout from '../../../../../../@Api/Layout/Type/InsetLayout';
import CollectionType from '../../../../../../@Api/Automation/Value/Type/CollectionType';
import Value from '../../../../../../@Api/Automation/Value/Value';
import CollectionValue from '../../../../../../@Api/Automation/Value/CollectionValue';
import PrimitiveValueType from '../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import queryPortalDataSource from '../../../../../../@Api/Portal/DataSource/Api/queryPortalDataSource';
import DataSourceValue from '../../../../../../@Api/Automation/Value/DataSourceValue';
import PortalDataSourceValue from '../../../../../../@Api/Portal/DataSource/PortalDataSourceValue';
import PortalDataSourceListQuery from '../../../../../../@Api/Portal/DataSource/PortalDataSourceListQuery';
import uuid from '../../../../../../@Util/Id/uuid';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import useFormInputLayoutPlaceholder from '../Shared/Api/useFormInputLayoutPlaceholder';
import { getSearchPredicateByQueryAndParameters } from './Api/getSearchPredicateByQueryAndParameters';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import CompositePredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../DataObject/Model/LogicalOperator';
import { getPartiallyComputedPredicate } from '../../../../../../@Api/Automation/Api/getPartiallyComputedPredicate';

export interface SelectionFormInputLayoutViewerProps extends LayoutViewerProps<SelectionFormInputLayout>
{

}

const SelectionFormInputLayoutViewer: React.FC<SelectionFormInputLayoutViewerProps> =
    props =>
    {
        const parameter =
            useComputed<Parameter<any>>(
                () =>
                    props.layout.parameterId
                    && props.parameterDictionary.getParameterById(props.layout.parameterId),
                [
                    props.layout,
                    props.parameterDictionary
                ]);
        const [ parameterAssignment ] =
            useAsyncResult(
                () =>
                    props.layout.parameterAssignment.apply(
                        new FunctionContext(
                            props.parameterDictionary,
                            props.parameterAssignment,
                            props.commitContext
                        )
                    ),
                [
                    props.layout,
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);
        const load =
            useCallback(
                async (query: string) =>
                {
                    const baseFilter = props.layout.filter;
                    const searchFilter =
                        getSearchPredicateByQueryAndParameters(
                            query,
                            props.layout.optionLayout.getDependencies()
                                .map(
                                    dependency =>
                                        dependency.parameter
                                )
                                .filter(
                                    parameter =>
                                        props.layout.dataSourceSignature.resultParameters.hasParameter(parameter)
                                )
                        );
                    let filter: Predicate | undefined;

                    if (baseFilter !== undefined && searchFilter !== undefined)
                    {
                        filter =
                            new CompositePredicate(
                                LogicalOperator.And,
                                [
                                    baseFilter,
                                    searchFilter,
                                ]
                            );
                    }
                    else
                    {
                        filter = baseFilter ?? searchFilter;
                    }

                    const substitutedFilter =
                        filter === undefined
                            ? undefined
                            : await getPartiallyComputedPredicate(
                                filter,
                                new FunctionContext(
                                    props.parameterDictionary,
                                    props.parameterAssignment
                                ),
                                props.layout.dataSourceSignature.resultParameters
                            );

                    const resultSet =
                        await queryPortalDataSource(
                            new PortalDataSourceListQuery(
                                uuid(),
                                props.layout.dataSourceSignature,
                                parameterAssignment,
                                substitutedFilter,
                                props.layout.orderings,
                                0,
                                100
                            )
                        );

                    return resultSet.results.map(
                        result =>
                            new PortalDataSourceValue(
                                result.id,
                                props.layout.dataSourceSignature.id,
                                parameterAssignment,
                                result.parameterAssignment
                            )
                    );
                },
                [
                    props.layout,
                    parameterAssignment,
                ]);
        const optionLayoutWithInset =
            useComputed(
                () =>
                    new InsetLayout(
                        props.layout.optionLayout,
                        5,
                        5,
                        5,
                        5),
                [
                    props.layout
                ]);
        const optionParameterDictionary =
            useComputed(
                () =>
                    ParameterDictionary.union(
                        props.parameterDictionary,
                        props.layout.dataSourceSignature.resultParameters,
                        new ParameterDictionary([ props.layout.optionParameter ])),
                [
                    props.parameterDictionary,
                    props.layout
                ]);
        const formatter =
            useCallback(
                (option: PortalDataSourceValue, metadata: OptionMetadata<PortalDataSourceValue>) =>
                    <LayoutViewer
                        parameterDictionary={optionParameterDictionary}
                        parameterAssignment={
                            props.parameterAssignment
                                .getNewAssignment(option.valueParameterAssignment)
                                .getNewAssignmentWithParameter(
                                    props.layout.optionParameter,
                                    new DataSourceValue(option)
                                )
                        }
                        layout={metadata.context === 'value' ? props.layout.optionLayout : optionLayoutWithInset}
                    />,
                [
                    props.layout,
                    optionParameterDictionary,
                    optionLayoutWithInset
                ]);
        const idResolver =
            useCallback(
                (option: PortalDataSourceValue) =>
                    option.id,
                []);
        const isMultiselect =
            useComputed(
                () =>
                    parameter.type instanceof CollectionType,
                [
                    parameter
                ]);
        const onChange =
            useCallback(
                (value: SelectionValue<PortalDataSourceValue>) =>
                    runInAction(
                        () =>
                        {
                            function getDataSourceValueFromResult(result: PortalDataSourceValue)
                            {
                                return new DataSourceValue(result);
                            }

                            let parameterValue: Value<any, any>;

                            if (isMultiselect && value)
                            {
                                const arrayValue = value as PortalDataSourceValue[];

                                if (arrayValue.length === 0)
                                {
                                    parameterValue = EmptyValue.instance;
                                }
                                else
                                {
                                    parameterValue =
                                        new CollectionValue(
                                            arrayValue.map(
                                                element =>
                                                    getDataSourceValueFromResult(element)),
                                            new PrimitiveValueType(
                                                DataObject.getTypeById('Text')));
                                }
                            }
                            else
                            {
                                if (value)
                                {
                                    const valueAsResult = value as PortalDataSourceValue;

                                    parameterValue = getDataSourceValueFromResult(valueAsResult);
                                }
                                else
                                {
                                    parameterValue = EmptyValue.instance;
                                }
                            }

                            props.parameterAssignment
                                .setValue(
                                    parameter,
                                    parameterValue);
                        }),
                [
                    isMultiselect,
                    props.parameterAssignment,
                    parameter
                ]);
        const value =
            useComputed(
                () =>
                {
                    const value = props.parameterAssignment.getValue(parameter);

                    if (value instanceof CollectionValue)
                    {
                        return value.value
                            .map(
                                element =>
                                    element.value);
                    }
                    else
                    {
                        return value?.value;
                    }
                },
                [
                    props.parameterAssignment,
                    parameter
                ]);
        const placeholder =
            useFormInputLayoutPlaceholder(
                props.layout,
                props.parameterDictionary,
                props.parameterAssignment,
                props.commitContext
            );
        const isDisabled =
            useComputed(
                () =>
                    props.layout.isDisabled?.synchronouslyEvaluate(
                        new FunctionContext(
                            props.parameterDictionary,
                            props.parameterAssignment,
                            props.commitContext
                        )
                    ),
                [
                    props.layout,
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        return <Selectbox
            load={load}
            formatOption={formatter}
            idResolver={idResolver}
            onChange={onChange}
            value={value}
            multi={isMultiselect}
            disableUnderline={false}
            clearable
            placeholder={placeholder}
            disabled={isDisabled}
        />;
    };

export default observer(SelectionFormInputLayoutViewer);
