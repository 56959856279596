import { FormEvent } from './FormEvent';

export class FormEventListener<T>
{
    // ------------------ Members -----------------------------

    eventId: string;

    listen: (event: FormEvent<T>) => void;

    // -------------- Constructor -----------------------------

    constructor(eventId: string, listen: (event: FormEvent<T>) => void)
    {
        this.eventId = eventId;
        this.listen = listen;
    }

    /***********************************
     **         Business logic        **
     ***********************************/

    // -------------- Getters & setters ------------------

}
