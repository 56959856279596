import { Entity } from '../Model/Implementation/Entity';
import { loadModuleDirectly } from '../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityActionController } from '../Controller/Directory/EntityActionController';
import { FeedbackStore } from '../../@Component/App/Root/Environment/Organization/Feedback/FeedbackStore';
import { Action } from '../../@Component/Domain/Entity/Type/BespokeEntityType';
import uuid from '../../@Util/Id/uuid';
import { EntityCacheService } from '../../@Component/Service/Entity/EntityCacheService';
import { EntityActionResult } from '../Model/Implementation/EntityActionResult';
import { fromJson, fromJsonAsArray } from '../../@Util/Serialization/Serialization';
import { EntityEvent } from '../Model/Implementation/EntityEvent';
import isUserError from '../Error/isUserError';
import getErrorMessage from '../Error/getErrorMessage';
import localizeText from '../Localization/localizeText';
import { handleCommitResult } from './Commit/commitEntity';

export default function performAction<T = any>(entity: Entity,
                                               action: Omit<Action<T>, 'id'>): Promise<EntityActionResult<T>>
{
    return executeAction(entity, action)
        .then(
            result =>
                maybeShowSnackbar(
                    entity,
                    action,
                    result))
        .catch(
            error =>
                handleError(error));
}

function executeAction<T>(entity: Entity,
                          action: Omit<Action<T>, 'id'>): Promise<EntityActionResult<T>>
{
    if (action.perform)
    {
        return performActionLocally(entity, action);
    }
    else
    {
        return performActionInApi(entity, action);
    }
}

function performActionLocally<T>(entity: Entity,
                                 action: Omit<Action<T>, 'id'>): Promise<EntityActionResult<T>>
{
    return action.perform(entity, action.parameters || {});
}

function performActionInApi<T>(entity: Entity,
                               action: Omit<Action<T>, 'id'>): Promise<EntityActionResult<T>>
{
    const commitId = action.commitId || uuid();
    loadModuleDirectly(EntityCacheService).registerCommit(commitId);

    return loadModuleDirectly(EntityActionController)
        .perform(
            action.code,
            action.parameters || {},
            entity?.entityType.id,
            entity?.id,
            commitId,
            action.files)
        .then(
            result =>
                handleApiResult(
                    entity,
                    action,
                    result,
                    commitId));
}

function handleApiResult<T>(entity: Entity,
                            action: Omit<Action<T>, 'id'>,
                            _result: EntityActionResult,
                            commitId: string)
{
    const result = new EntityActionResult();
    result.events = fromJsonAsArray(_result.events, EntityEvent);

    if (action.resultType)
    {
        result.result =
            fromJson(
                _result.result,
                action.resultType);
    }
    else
    {
        result.result = _result.result;
    }

    if (entity || result.events.length > 0)
    {
        return handleCommitResult(
            entity,
            {
                descriptor: {},
                id: commitId,
                events: [],
                files: new Map(),
            },
            {
                isDeferred: false,
                isForced: false,
                isDebounced: false,
                isBulkMode: false,
                isDebugMode: false,
                isAutoCommit: false,
            },
            result.events)
            .then(
                () =>
                    Promise.resolve(result));
    }
    else
    {
        return Promise.resolve(result);
    }
}

function maybeShowSnackbar<T>(entity: Entity,
                              action: Omit<Action<T>, 'id'>,
                              result: EntityActionResult)
{
    if (action.name)
    {
        loadModuleDirectly(FeedbackStore)
            .enqueueSnackbar(
                localizeText(
                    'Generic.ActionPerformedSuccessfully',
                    '\'${actionName}\' is succesvol uitgevoerd',
                    {
                        actionName: action.name
                    }),
                {
                    variant: 'success',
                    autoHideDuration: 6000
                });
    }

    return Promise.resolve(result);
}

function handleError(error: Error)
{
    if (!isUserError(error))
    {
        loadModuleDirectly(FeedbackStore)
            .enqueueSnackbar(
                getErrorMessage(error),
                {
                    variant: 'error',
                    autoHideDuration: 6000
                });
    }

    return Promise.reject(error);
}
