import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../Model/DataObjectType';
import { Comparator } from '../../Model/Comparator';
import { Aggregate } from '../../Model/Aggregate';
import { MathematicalOperator } from '../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../Model/DataObjectFunction';
import { DataObject } from '../../Model/DataObject';
import { ApiBaseView } from './ApiBaseView';
import { ApiBaseEditor } from './ApiBaseEditor';
import { ApiBaseSpecification } from './ApiBaseSpecification';
import { ModuleManager } from '../../../../../@Util/DependencyInjection/index';
import Dictionary from 'typescript-collections/dist/lib/Dictionary';
import { DataDescriptor } from '../../Model/DataDescriptor';
import { ComparatorDescriptor } from '../../Model/ComparatorDescriptor';
import { DataObjectRepresentation } from '../../Model/DataObjectRepresentation';
import { DataObjectSpecification } from '../../Model/DataObjectSpecification';
import { DataObjectStore } from '../../DataObjectStore';
import { DataObjectOperatorOverload } from '../../Model/DataObjectOperatorOverload';
import { DataObjectContext } from '../../Model/DataObjectContext';
import { DataObjectComparatorOverload } from '../../Model/DataObjectComparatorOverload';
import { DataObjectOverloadType } from '../../Model/DataObjectOverloadType';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { DataObjectBespokeEditorStore } from '../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { ApiBaseEditorStore } from './ApiBaseEditorStore';
import isEqual from '../../../../../@Util/IsEqual/isEqual';
import isNumber from 'lodash/isNumber';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

export abstract class ApiBaseType<T> implements DataObjectType<T>
{
    abstract id(): string;

    abstract name(): string;

    supportedComparators(): ComparatorDescriptor[]
    {
        return [
            {
                comparator: Comparator.Equals
            },
            {
                comparator: Comparator.NotEquals
            },
            {
                comparator: Comparator.In
            }
        ];
    }

    supportedAggregates(): Aggregate[]
    {
        return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return ApiBaseView;
    }

    editor(): DataObjectEditorType
    {
        return ApiBaseEditor;
    }

    editorStore(editorStore: DataObjectEditorStore): DataObjectBespokeEditorStore
    {
        return new ApiBaseEditorStore(editorStore);
    }

    specification(): DataObjectSpecificationType
    {
        return ApiBaseSpecification;
    }

    initialize(dataObjects: DataObject[],
               moduleManager: ModuleManager): Promise<boolean>
    {
        const idsToFetch: number[] = [];
        const dataObjectsById: Dictionary<number, DataObject> = new Dictionary<number, DataObject>();

        dataObjects.map(
            dataObject =>
                this.getInstanceIds(dataObject)
                    .forEach(
                        id =>
                        {
                            idsToFetch.push(id);

                            dataObjectsById.setValue(id, dataObject);
                        }));

        return (idsToFetch.length === 0
            ?
                Promise.resolve([])
            :
                this.getInstances(idsToFetch, dataObjectsById, moduleManager))
            .then(
                results =>
                {
                    let instanceById = new Map<number, T>();

                    // Slice because concat with observable array as parameter does not work well
                    results
                        .forEach(
                            instance =>
                            {
                                instanceById.set(
                                    this.getInstanceId(instance),
                                    instance);
                            });

                    dataObjects.forEach(
                        dataObject =>
                        {
                            let instances: T[] = [];

                            this.getInstanceIds(dataObject).forEach(
                                id =>
                                {
                                    let instance = instanceById.get(id);

                                    if (instance != null)
                                    {
                                        instances.push(instance);
                                    }
                                    else
                                    {
                                        // console.warn('not found instance', this.constructor.name, instance, id);
                                    }
                                });

                            if (instances.length > 0)
                            {
                                if (instances.length === 1)
                                {
                                    dataObject.setValue(instances[0]);
                                }
                                else
                                {
                                    dataObject.setValue(instances);
                                }
                            }
                            else
                            {
                                dataObject.setValue(undefined);
                            }
                        }
                    );

                    return Promise.resolve(true);
                });
    }

    requiresInitialization(value: any): boolean
    {
        if (isObject(value))
        {
            if ((value as any)._proxy)
            {
                return true;
            }
            else
            {
                return false;
            }
        }

        return true;
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return false;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [
            new DataObjectComparatorOverload(
                this,
                Comparator.Equals,
                DataObjectOverloadType.Symmetric,
                type =>
                    type instanceof ApiBaseType,
                (value, relatedValue, isThisLhs) =>
                {
                    return value != null
                        && relatedValue != null
                        && isEqual(this.getInstanceIds(value), this.getInstanceIds(relatedValue));
                })
        ];
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    getDataFromValue(value: any, specification: DataObjectSpecification): DataDescriptor
    {
        let data = new DataDescriptor();

        if (isNumber(value))
        {
            data.number1 = value;
        }
        else if (isArray(value))
        {
            data.complex = (value as T[]).map(
                instance => (this.getInstanceId(instance)));
        }
        else
        {
            if (value._proxy)
            {
                if (value.ids.length === 1)
                {
                    data.number1 = value.ids[0];
                }
                else if (value.ids.length > 1)
                {
                    data.complex = value.ids;
                }
            }
            else
            {
                data.number1 = this.getInstanceId(value);
            }
        }

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): any
    {
        let ids: number[] = [];

        if (data.number1)
        {
            ids.push(data.number1);
        }

        if (data.complex)
        {
            ids.push(...data.complex);
        }

        return { _proxy: true, ids: ids };
    }

    valueId(value: T): string
    {
        return `${this.getInstanceId(value)}`;
    }

    getString(value: T,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        return this.getInstanceName(value);
    }

    getInstanceIds(dataObject: DataObject): number[]
    {
        let value = dataObject.value;

        if (value)
        {
            if (isNumber(value))
            {
                return [ value ];
            }
            else if (value._proxy && value.ids)
            {
                return value.ids;
            }
            else if (isArray(value))
            {
                return (value as any[]).map(instance => (this.getInstanceId(instance)));
            }
            else if (isObject(value))
            {
                return [ this.getInstanceId(value as any) ];
            }
            else
            {
                return [];
            }
        }
        else
        {
            return [];
        }
    }

    getDiscreteValues(specification: DataObjectSpecification,
                      dataObjectStore: DataObjectStore): Promise<any[]>
    {
        return this.getAllInstances(specification, dataObjectStore.moduleManager);
    }

    abstract getInstanceId(instance: T): number;

    abstract getInstanceName(instance: T): string;

    abstract getInstanceDescription(instance: T): string;

    abstract getInstanceAvatarUrl(instance: T, moduleManager: ModuleManager): string;

    abstract getInstances(ids: number[],
                          dataObjectById: Dictionary<number, DataObject>,
                          moduleManager: ModuleManager): Promise<T[]>;

    abstract searchInstances(query: string,
                             limit: number,
                             dataObject: DataObject,
                             moduleManager: ModuleManager): Promise<T[]>;

    abstract getAllInstances(specification: DataObjectSpecification,
                             moduleManager: ModuleManager): Promise<T[]>;

    isValid(dataObject: DataObject): boolean
    {
        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return false;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }

}
