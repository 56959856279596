import { Entity } from '../../Model/Implementation/Entity';
import validateEntity from '../Validation/validateEntity';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { FeedbackStore } from '../../../@Component/App/Root/Environment/Organization/Feedback/FeedbackStore';
import commitEntity, { CommitOptions } from './commitEntity';
import { EntityValidationResult } from '../Validation/EntityValidationResult';

export async function validateAndCommitEntityOrShowError(entity: Entity,
                                                         partialOptions?: Partial<CommitOptions>): Promise<[ boolean, EntityValidationResult ]>
{
    const validationResult =
        validateEntity(
            entity,
            {
                commitContext: partialOptions?.context,
            }
        );

    if (validationResult.isValid)
    {
        await commitEntity(entity, partialOptions);

        return [ true, validationResult ];
    }
    else
    {
        for (const message of validationResult.messages)
        {
            loadModuleDirectly(FeedbackStore)
                .enqueueSnackbar(
                    message.message,
                    {
                        variant: 'error'
                    });
        }

        return [ false, validationResult ];
    }
}
