import React, { useCallback, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styles from './DatePicker.module.scss';
import useWindowSize from '../../../../Util/DOM/useWindowSize';
import AsyncComponent from '../../../../../@Util/AsyncComponent/AsyncComponent';
import ViewGroup from '../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import { getCalendarYearSelection } from '../../../../../@Util/Date/getCalendarYearSelection';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { getMonthSelection } from '../../../../../@Util/Date/getCalendarMonthSelection';
import Popper from '../../Popper/Popper';
import Card from '../../Card/Card';
import LabelButton from '../../Button/Variant/Label/LabelButton';

export interface DatePickerProps
{
    value?: Date;
    onChange: (date?: Date) => void;
    isOutsideRange?: (day: any) => boolean;
    isDayBlocked?: (day: any) => boolean;
}

const useStyles =
    makeStyles(
        {
            yearLabel:
                {
                    fontSize: '18px',
                    fontWeight: 'bold',
                },
            monthLabel:
                {
                    fontSize: '18px',
                    fontWeight: 'bold',
                },
            calendarLabel:
                {
                    marginLeft: '10px'
                },
            calendarItem:
                {
                    margin: '5px',
                    padding: '10px',
                    cursor: 'pointer',

                    '&:hover':
                        {
                            backgroundColor: '#0000000a',
                        }
                },
            itemRow:
                {
                    display: 'flex',
                    justifyContent: 'center',
                },
            calendarPopper:
                {
                    maxHeight: '245px',
                }
        }
    );

const internalDayPickerLoader =
    () =>
        import('./InternalDayPicker');

const DatePicker: React.FC<DatePickerProps> =
    props =>
    {
        const classes = useStyles();
        const [ selectedMonth, setSelectedMonth] = useState(undefined);
        const [ isYearOpen, setIsYearOpen] = useState({});
        const popperRef = useRef<HTMLDivElement>(null);

        const date =
            useMemo(
                () =>
                    moment(props.value),
                [
                    props.value
                ]);

        const { onChange } = props;

        const onDateChange =
            useCallback(
                (date: moment.Moment) =>
                {
                    onChange(date.toDate())
                },
                [
                    onChange
                ]);
        const onFocusChange =
            useCallback(
                () => {},
                []);

        const today =
            useMemo(
                () =>
                    moment(new Date()),
                []);

        const isDayHighlighted =
            useCallback(
                (day: moment.Moment) =>
                    today.isSame(day, 'day'),
                [
                    today
                ]);

        const monthSelection =
            useMemo(
                () =>
                {
                    return getMonthSelection()
                },
                []
            );

        const yearSelection =
            useMemo(
                () =>
                {
                   return getCalendarYearSelection(
                       moment().year(),
                       100,
                       25
                   );
                },
                []
            );

        const formatYearValue =
            (year: number) =>
                <div
                    className={classes.yearLabel}
                >
                    {year}
                </div>;

        const formatMonthValue =
            (month: number) =>
                    <div
                        className={classes.monthLabel}
                    >
                        {moment().month(month).format('MMMM')}
                    </div>


        const formatShortMonthValue =
            (month: number) =>
                moment().month(month).format('MMM').replace(".", "")

        const formatSelectableYearValue =
            (year: number) =>
                moment().year(year).format('YYYY')

        const generateMonthRows =
            useCallback(
                (onMonthSelect: any) => {
                const rows = [];
                for (let i = 0; i < monthSelection.length; i += 3)
                {
                    const rowMonths = monthSelection.slice(i, i + 3);
                    const row =
                        <div
                            key={i}
                            className={classes.itemRow}
                        >
                            {
                                rowMonths.map(
                                    (month) =>
                                        <div
                                            key={month.value}
                                            className={classes.calendarItem}
                                            onClick={() =>
                                                {
                                                    onMonthSelect(month.value);
                                                    setSelectedMonth(null);
                                                }
                                            }
                                        >
                                            {formatShortMonthValue(month.value)}
                                        </div>
                                )
                            }
                        </div>
                    rows.push(row);
                }
                return rows;
            },
                [
                    monthSelection,
                ]
        );

        const generateYearRows =
            useCallback(
                (onYearSelect: any) => {
                    const rows = [];
                    for (let i = 0; i < yearSelection.length; i += 3)
                    {
                        const rowYears = yearSelection.slice(i, i + 3);
                        const row =
                            <div
                                key={i}
                                className={classes.itemRow}
                            >
                                {
                                    rowYears.map(
                                        (year) =>
                                            <div
                                                key={year.value}
                                                className={classes.calendarItem}
                                                onClick={() =>
                                                    {
                                                        onYearSelect(year.value);
                                                        setIsYearOpen({});
                                                    }
                                                }
                                            >
                                                {formatSelectableYearValue(year.value)}
                                            </div>
                                    )
                                }
                            </div>
                        rows.push(row);
                    }
                    return rows;
                },
                [
                    yearSelection
                ]
            );

        const setHeight =
            useCallback(
                (year: any) =>
                {
                    //Timeout to ensure the popper is loaded first
                    setTimeout(
                        () =>
                        {
                            const currentYear = year as Moment
                            const popperDiv = popperRef.current;

                            if (popperDiv) {
                                const totalHeight = popperDiv.scrollHeight;
                                const firstYearInSelection = yearSelection[0].value
                                const lastYearInSelection  = yearSelection[yearSelection.length - 1].value;

                                const line = Math.floor((currentYear.year() - firstYearInSelection) / 3);
                                const positionInLine = (currentYear.year() - firstYearInSelection) % 3;
                                const lineHeight = totalHeight / Math.ceil((lastYearInSelection - firstYearInSelection + 1) / 3);

                                popperDiv.scrollTop = lineHeight * line + (lineHeight / 3) * positionInLine;
                            }
                        },
                        0
                    )
                },
                [
                    yearSelection,
                ]
            )

        const handleYearClick =
            useCallback(
                (month: number) =>
                    {
                        setIsYearOpen(
                            prevState =>
                                (
                                    {
                                        ...prevState,
                                        [month]: !prevState[month]
                                    }
                                )
                        );

                        setHeight(month)
                    },
                [
                    setIsYearOpen,
                    setHeight
                ]
            );

        const renderMonthElement =
            ({ month, onYearSelect, onMonthSelect }) =>
                <ViewGroup
                    orientation="horizontal"
                    spacing={0}
                    justification="center"
                    className={classes.calendarLabel}
                >
                    <ViewGroupItem>
                        <Popper
                            reference={
                                <LabelButton
                                    label={formatMonthValue(month.month())}
                                    onClick={() => setSelectedMonth(month)}
                                />
                            }
                            popper={
                                <Card>
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={5}
                                        className={classes.calendarPopper}
                                    >
                                        {
                                            generateMonthRows(
                                                (selectedMonth: any) =>
                                                {
                                                    onMonthSelect(month, selectedMonth);
                                                }
                                            )
                                        }
                                    </ViewGroup>
                                </Card>
                            }
                            open={selectedMonth === month}
                            onClose={() => setSelectedMonth(undefined)}
                        />
                    </ViewGroupItem>

                    <ViewGroupItem>
                        <Popper
                            reference={
                                <LabelButton
                                    label={formatYearValue(month.year())}
                                    onClick={() => handleYearClick(month)}
                                />
                            }
                            popper={
                                <Card>
                                    <div
                                        ref={popperRef}
                                        style={{
                                            maxHeight: '250px',
                                            overflowY: 'auto',
                                        }}
                                    >
                                        <ViewGroup
                                            orientation="vertical"
                                            spacing={5}
                                            className={classes.calendarPopper}
                                        >
                                            {
                                                generateYearRows(
                                                    (selectedYear: number) =>
                                                        {
                                                            onYearSelect(month, selectedYear);
                                                        }
                                                )
                                            }
                                        </ViewGroup>
                                    </div>
                                </Card>
                            }
                            open={isYearOpen[month]}
                            onClose={() => setIsYearOpen({})}
                        />
                    </ViewGroupItem>
                </ViewGroup>

        const windowSize = useWindowSize();

        return <div
            className={styles.wrapper}
        >
            <div
                className={styles.positioner}
            >
                <AsyncComponent
                    _loader={internalDayPickerLoader}
                    _loadKey="default"
                    hideKeyboardShortcutsPanel
                    noBorder
                    date={date}
                    onDateChange={onDateChange}
                    focused
                    onFocusChange={onFocusChange}
                    numberOfMonths={windowSize.width > 630 ? 2 : 1}
                    isDayHighlighted={isDayHighlighted}
                    renderMonthElement={renderMonthElement}
                />
            </div>
        </div>;
    };

export default observer(DatePicker);
