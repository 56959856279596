import useCount from '../../../Entity/Selection/Hooks/useCount';
import useTypes from '../../../Entity/Type/Api/useTypes';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';

export function useNumberOfActiveUserLicenses(contract: Entity)
{
    const types = useTypes();

    return useCount(
            types.License.User.Type,
            (builder, rootPath) =>
                builder
                    .where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath.joinTo(
                                    types.LicenseContract.RelationshipDefinition.Licenses,
                                    true
                                ),
                                contract
                            )
                    )
                    .where(
                        cb =>
                            cb.or(
                                ob =>
                                    ob
                                        .isNotDefined(rootPath.field(types.License.Field.EndDate))
                                        .gt(
                                            rootPath.field(types.License.Field.EndDate),
                                            undefined,
                                            new Date()
                                        )
                            )
                    ),
            [
                types,
                contract
            ]
        );
}
