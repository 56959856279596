import { observable } from 'mobx';
import { DataObject } from '../../../../../DataObject/Model/DataObject';

export default class ValueToValueMapping
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable sourceValue: any;
    @observable sourceValueName: string;
    @observable targetValue: DataObject;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                sourceValue: string,
                sourceValueName: string,
                targetValue: DataObject)
    {
        this.id = id;
        this.sourceValue = sourceValue;
        this.sourceValueName = sourceValueName;
        this.targetValue = targetValue;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new ValueToValueMapping(
            descriptor.id,
            descriptor.sourceValue,
            descriptor.sourceValueName,
            descriptor.targetValue
                ?
                    DataObject.constructFromDescriptor(descriptor.targetValue)
                :
                    undefined);
    }

    toDescriptor()
    {
        return {
            id: this.id,
            sourceValue: this.sourceValue,
            sourceValueName: this.sourceValueName,
            targetValue: this.targetValue?.descriptor()
        };
    }

    // ----------------------- Private logic ------------------------
}
