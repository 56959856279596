import * as React from 'react';
import { RelatedEntityViewerStore } from './RelatedEntityViewerStore';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../../../../@Framework/Component/BaseComponent';
import List from '../../../../../../Generic/List/V2/List';

@observer
export class RelatedEntityViewer extends BaseComponent<RelatedEntityViewerStore>
{
    renderComponent(store: RelatedEntityViewerStore)
    {
        return <div>
            <div
                style={{
                    marginBottom: 5
                }}
            >
                <List
                    store={store.headerGroupStore}
                />
            </div>
            <List
                store={store.widgetListStore}
            />
        </div>;
    }
}
