import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import useEntityValue from '../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import useTypes from '../../../../../../../Entity/Type/Api/useTypes';
import useAsyncResult from '../../../../../../../../../@Util/Async/useAsyncResult';
import getPredicateFromDescriptor from '../../../../../../../../../@Api/Automation/Api/getPredicateFromDescriptor';
import FunctionContext from '../../../../../../../../../@Api/Automation/Function/FunctionContext';
import Centered from '../../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../../@Future/Component/Generic/Loader/Loader';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import PredicateEditor from '../../../../../../../Entity/Viewer/Content/Automation/Editor/Predicate/PredicateEditor';
import CompositePredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../../../../DataObject/Model/LogicalOperator';
import RightAlignedButtonGroup from '../../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import ComparisonPredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../../../../../../DataObject/Model/Comparator';
import CancelButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import DeleteButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';
import { FieldGroupPredicateButtonProps } from '../FieldGroupPredicateButton';
import getFieldGroupPredicateParameterDictionary from '../../../../../../../../../@Api/Metadata/FieldGroup/Predicate/getFieldGroupPredicateParameterDictionary';
import { EntityParameterId } from '../../../../../../../../../@Api/Metadata/FieldGroup/Predicate/FieldGroupPredicateConstants';
import { EntityField } from '../../../../../../../../../@Api/Model/Implementation/EntityField';

export interface FieldGroupPredicateEditorProps extends FieldGroupPredicateButtonProps
{
    entityField: EntityField;
    onClose: () => void;
}

const FieldGroupPredicateEditor: React.FC<FieldGroupPredicateEditorProps> =
    props =>
    {
        const { fieldGroup, onClose } = props;
        const types = useTypes();
        const predicateDescriptor =
            useEntityValue(
                fieldGroup,
                props.entityField);

        const predicateContext =
            useMemo(
                () =>
                    new FunctionContext(
                        getFieldGroupPredicateParameterDictionary(
                            fieldGroup)),
                [
                    fieldGroup
                ]);

        const [ predicate, isLoading ] =
            useAsyncResult(
                async () =>
                {
                    if (predicateDescriptor)
                    {
                        return getPredicateFromDescriptor(
                            predicateDescriptor,
                            predicateContext);
                    }
                    else
                    {
                        return new CompositePredicate(
                            LogicalOperator.And,
                            []);
                    }
                },
                [
                    predicateDescriptor,
                    predicateContext
                ]);

        const dummyOnChange =
            useCallback(
                () => {},
                []);

        const clear =
            useCallback(
                () =>
                {
                    fieldGroup.setValueByField(
                        props.entityField,
                        undefined);

                    return fieldGroup.checkAndDoCommit()
                        .then(onClose);
                },
                [
                    fieldGroup,
                    types,
                    onClose
                ]);

        const save =
            useCallback(
                () =>
                {
                    fieldGroup.setValueByField(
                        props.entityField,
                        predicate?.toDescriptor());

                    if (fieldGroup.isNew())
                    {
                        return onClose();
                    }
                    else
                    {
                        return fieldGroup.checkAndDoCommit()
                            .then(onClose);
                    }
                },
                [
                    fieldGroup,
                    types,
                    predicate,
                    onClose
                ]);

        const comparisonConstructor =
            useCallback(
                () =>
                    new ComparisonPredicate(
                        new ValueFromEntityComputation(
                            predicateContext.parameterDictionary.getParameterById(EntityParameterId),
                            undefined),
                        Comparator.Equals,
                        undefined),
                [
                    predicateContext
                ]);

        if (isLoading)
        {
            return <Centered
                horizontal
            >
                <Loader />
            </Centered>;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <PredicateEditor
                        value={predicate}
                        onChange={dummyOnChange}
                        context={predicateContext}
                        comparisonConstructor={comparisonConstructor}
                        comparisonLhsTypeFixed
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <RightAlignedButtonGroup>
                        <CancelButton
                            onClick={props.onClose}
                        />
                        <DeleteButton
                            onClick={clear}
                        />
                        <SaveButton
                            onClick={save}
                        />
                    </RightAlignedButtonGroup>
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

export default observer(FieldGroupPredicateEditor);
