import { DataObjectBespokeEditorStore } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { computed } from 'mobx';
import { PredicatePickerStore } from '../../../../Predicate/Picker/PredicatePickerStore';

export class PredicateEditorStore extends DataObjectBespokeEditorStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    @computed
    get predicatePickerStore(): PredicatePickerStore
    {
        return new PredicatePickerStore({
            context: this.baseStore.dataObject.context,
            predicate: this.baseStore.dataObject.value,
            dialogTitle: 'Predicaat',
            onChange:
                predicate =>
                    Promise.resolve(this.baseStore.changeValue(predicate)),
            onDelete:
                () =>
                    Promise.resolve(this.baseStore.changeValue(undefined))
        });
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
