import React, { useContext, useMemo, useRef } from 'react';
import useTypes from '../../../../../Domain/Entity/Type/Api/useTypes';
import CurrentUserContext from '../../../../../Domain/User/CurrentUserContext';
import RouterContext from '../../../../../../@Service/Router/RouterContext';
import { observer } from 'mobx-react';
import NavigationAppendixContext from './NavigationAppendixContext';
import { OrganizationEnvironmentStore } from '../OrganizationEnvironmentStore';
import { Body } from '../Body/Body';
import styles from './Navigation.module.scss';
import { useComputed } from 'mobx-react-lite';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import Item from './Item/Item';
import { createNumberComparator } from '../../../../../Generic/List/V2/Comparator/NumberComparator';
import ScrollRefContext from '../../../../../../@Service/Navigation/Page/Scroll/ScrollRefContext';
import useResults from '../../../../../Domain/Entity/Selection/Hooks/useResults';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import DashboardSelector from '../../../../../Domain/Entity/Dashboard/DashboardSelector/DashboardSelector';
import useFavoriteDashboardIds from '../../../../../Domain/Entity/Dashboard/Api/useFavoriteDashboardIds';
import useOnToggleFavoriteDashboard from '../../../../../Domain/Entity/Dashboard/Api/useOnToggleFavoriteDashboard';
import { Hidden } from '@material-ui/core';
import LocalizedText from '../../../../../Domain/Localization/LocalizedText/LocalizedText';
import { useFetchedApiFile } from '../../../../../../@Service/ApiClient/Hooks/useFetchedApiFile';

export interface NavigationProps
{
    appStore: OrganizationEnvironmentStore;
}

const Navigation: React.FC<NavigationProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);
        const routerStore = useContext(RouterContext);
        const appendix = useRef();

        const [ favoriteDashboardIds ] = useFavoriteDashboardIds();
        const onToggleFavoriteDashboard = useOnToggleFavoriteDashboard();

        const dashboards =
            useResults(
                favoriteDashboardIds && favoriteDashboardIds.length > 0 ? types.Dashboard.Type : undefined,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.or(
                                ob =>
                                    favoriteDashboardIds.forEach(
                                        dashboardId =>
                                            ob.eq(
                                                rootPath.field(types.Entity.Field.Uuid),
                                                undefined,
                                                dashboardId)))),
                [
                    types,
                    favoriteDashboardIds
                ]);

        const dashboardUrls =
            useMemo(
                () =>
                    new Set(
                        dashboards
                            ?
                                dashboards.map(
                                    dashboard =>
                                        `/dashboard/${dashboard.id}`)
                            :
                                undefined),
                [
                    dashboards
                ]);

        const wallpaper =
            useComputed(
                () =>
                    routerStore.currentPageStore
                    && routerStore.currentPageStore.isInitialized
                    && routerStore.currentPageStore.wallpaper,
                [
                    routerStore
                ]);
        const [ initializedWallpaper ] = useFetchedApiFile(wallpaper);

        const style =
            useComputed(
                () => ({
                    backgroundImage:
                        initializedWallpaper
                            ?
                                `url(${initializedWallpaper})`
                            :
                                undefined,
                    backgroundSize: 'cover'
                }),
                [
                    initializedWallpaper
                ]);

        const scrollRef = useRef();

        const name =
            useMemo(
                () =>
                {
                    if (routerStore?.currentPageStore?.name?.length > 50)
                    {
                        return routerStore.currentPageStore.name.slice(0, 50) + '...';
                    }
                    else
                    {
                        return routerStore?.currentPageStore?.name;
                    }
                },
                [
                    routerStore?.currentPageStore?.name
                ]
            )

        return <ScrollRefContext.Provider
            value={scrollRef}
        >
            <div
                className={classNames(styles.root, wallpaper && styles.wallpaper)}
                style={style}
                ref={scrollRef}
            >
                <Hidden
                    smDown
                >
                    <div
                        className={styles.header}
                    >
                        <div
                            className={styles.items}
                        >
                            <ViewGroup
                                orientation="horizontal"
                                alignment="center"
                                spacing={0}
                                wrap
                            >
                                <ViewGroupItem>
                                    <DashboardSelector />
                                </ViewGroupItem>
                                {
                                    dashboards &&
                                    dashboards
                                        .slice()
                                        .sort(createNumberComparator(d => d.sortIndex))
                                        .map(
                                            (dashboard, idx) =>
                                                <ViewGroupItem
                                                    key={dashboard.uuid}
                                                >
                                                    <Item
                                                        icon={dashboard.getObjectValueByField(types.Dashboard.Field.Icon) || 'dashboard'}
                                                        name={dashboard.name}
                                                        route={`/dashboard/${dashboard.id}`}
                                                        onClose={() => onToggleFavoriteDashboard(dashboard)}
                                                        exact
                                                    />
                                                </ViewGroupItem>)
                                }
                                {
                                    routerStore.path !== '/'
                                    && !dashboardUrls.has(routerStore.path)
                                    && !routerStore.path.startsWith('/configuration')
                                    && routerStore.currentPageStore
                                    &&
                                        <ViewGroupItem>
                                            <Item
                                                icon={routerStore.currentPageStore.icon}
                                                route={routerStore.path}
                                                name={name}
                                                onClose={() => routerStore.goBack()}
                                                exact
                                            />
                                        </ViewGroupItem>
                                }
                                {
                                    currentUserStore.isAllowedInConfiguration &&
                                        <ViewGroupItem>
                                            <Item
                                                icon="settings"
                                                name={
                                                    <LocalizedText
                                                        code="Generic.Configuration"
                                                        value="Configuratie"
                                                    />
                                                }
                                                route="/configuration"
                                            />
                                        </ViewGroupItem>
                                }
                            </ViewGroup>
                        </div>
                        <div
                            className={styles.appendix}
                            ref={appendix}
                        />
                    </div>
                </Hidden>
                <div
                    className={styles.body}
                >
                    <NavigationAppendixContext.Provider
                        value={appendix.current}
                    >
                        <Body
                            store={props.appStore.bodyStore}
                        />
                    </NavigationAppendixContext.Provider>
                </div>
            </div>
        </ScrollRefContext.Provider>;
    };

export default observer(Navigation);
