import React from 'react';
import { ViewGroupItemProps } from './ViewGroupItem';
import { PromiseOrAny } from '../Button/Button';
import { classNames } from '../../../Util/Class/classNames';
import styles from './ViewGroup.module.scss';

export type Orientation = 'vertical' | 'horizontal';

export type Alignment = 'start' | 'center' | 'end';

export interface ViewGroupProps
{
    orientation: Orientation;
    alignment?: Alignment;
    justification?: Alignment;
    spacing: number;
    wrap?: boolean;
    className?: string;
    glue?: (beforeIdx: number) => React.ReactNode;
    onClick?: () => PromiseOrAny<any>;
    noMinWidth?: boolean;
}

const ViewGroup: React.FC<ViewGroupProps> =
    props =>
    {
        const innerSpacing = props.spacing / 2;
        const childrenAsArray: any[] =
            React.Children.toArray(props.children)
                .filter(
                    child =>
                        child);

        const childrenAsArrayWithGlue =
            props.glue
                ?
                    childrenAsArray.map(
                        (child, idx) =>
                        {
                            if (idx > 0)
                            {
                                const glue = props.glue!(idx);

                                if (glue)
                                {
                                    return [
                                        <div
                                            key={`${child.key === null ? idx : child.key}$$glue`}
                                            className={styles.glue}
                                        >
                                            {glue}
                                        </div>,
                                        child
                                    ];
                                }
                            }

                            return [ child ];
                        })
                        .reduce((a, b) => a.concat(b), [])
                :
                    childrenAsArray;

        const numberOfChildren = childrenAsArrayWithGlue.length;

        return <div
            className={
                classNames(
                    styles.root,
                    props.className && props.className)}
            onClick={props.onClick}
            style={{
                display: 'flex',
                alignItems:
                    props.alignment === 'start'
                        ?
                            'flex-start'
                        :
                            props.alignment === 'center'
                                ?
                                    'center'
                                :
                                    props.alignment === 'end'
                                        ?
                                            'flex-end'
                                        :
                                            undefined,
                justifyContent:
                    props.justification === 'start'
                        ?
                            'flex-start'
                        :
                            props.justification === 'center'
                                ?
                                    'center'
                                :
                                    props.justification === 'end'
                                        ?
                                            'flex-end'
                                        :
                                            undefined,
                flexDirection:
                    props.orientation === 'vertical'
                        ?
                            'column'
                        :
                            'row',
                flexWrap:
                    props.wrap
                        ?
                            'wrap'
                        :
                            'nowrap'
            }}
        >
            {
                childrenAsArrayWithGlue.map(
                    (child: React.ReactElement<ViewGroupItemProps>, idx) =>
                    {
                        return <div
                            key={child.key as any}
                            className={
                                classNames(
                                    child.props.className !== styles.glue && styles.item,
                                    child.props.ratio > 0 && styles.hasRatio,
                                    child.props.className)}
                            style={{
                                flex: child.props.ratio,
                                marginTop:
                                    idx === 0 || props.orientation === 'horizontal'
                                        ?
                                            0
                                        :
                                           innerSpacing,
                                marginBottom:
                                    idx === numberOfChildren - 1 || props.orientation === 'horizontal'
                                        ?
                                            0
                                        :
                                            innerSpacing,
                                marginLeft:
                                    idx === 0 || props.orientation === 'vertical'
                                        ?
                                            0
                                        :
                                            props.wrap ?  0 : innerSpacing,
                                marginRight:
                                    idx === numberOfChildren - 1 || props.orientation === 'vertical'
                                        ?
                                            0
                                        :
                                            props.wrap ?  props.spacing : innerSpacing,
                                textAlign:
                                    child.props.alignment === 'center' || child.props.alignment === 'right'
                                        ?
                                            child.props.alignment
                                        :
                                            undefined,
                                // https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout
                                minWidth: props.orientation === 'horizontal' && !props.noMinWidth ? 0 : undefined,
                                // minHeight: props.orientation === 'vertical' ? 0 : undefined
                            }}
                        >
                            {child}
                        </div>;
                    })
            }
        </div>;
    };

export default ViewGroup;
