import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import { MathematicalOperator } from '../../../../@Component/Domain/DataObject/Model/MathematicalOperator';
import Dependency from '../../Parameter/Dependency';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import EmptyValue from '../../Value/EmptyValue';
import PrimitiveValue from '../../Value/PrimitiveValue';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
export default class MathematicalComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref lhs: Computation<any, any>;
    @observable operator: MathematicalOperator;
    @observable.ref rhs: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(lhs: Computation<any, any>,
                operator: MathematicalOperator,
                rhs: Computation<any, any>)
    {
        super();

        this.lhs = lhs;
        this.operator = operator;
        this.rhs = rhs;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        const lhsType = this.lhs.getType();
        const rhsType = this.rhs.getType();

        if (lhsType instanceof PrimitiveValueType
            && rhsType instanceof PrimitiveValueType)
        {
            return new PrimitiveValueType(
                DataObject.getComputationOutputType(
                    lhsType.type,
                    rhsType.type,
                    this.operator));
        }
        else
        {
            return lhsType;
        }
    }

    isAsync(): boolean
    {
        return this.lhs.isAsync()
            || this.rhs.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const lhsValue = await safelyApplyFunction(this.lhs, context);
        const rhsValue = await safelyApplyFunction(this.rhs, context);

        return this.compare(lhsValue, rhsValue);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        return this.compare(
            safelySynchronousApplyFunction(this.lhs, context),
            safelySynchronousApplyFunction(this.rhs, context));
    }

    private compare(lhsValue: Value<any, any>,
                    rhsValue: Value<any, any>)
    {
        if (lhsValue instanceof EmptyValue
            && rhsValue instanceof EmptyValue)
        {
            return EmptyValue.instance;
        }
        else if ((lhsValue instanceof PrimitiveValue
            || lhsValue instanceof EmptyValue)
            && (rhsValue instanceof PrimitiveValue
                || rhsValue instanceof EmptyValue))
        {
            return new PrimitiveValue(
                DataObject.compute(
                    lhsValue.value as DataObject,
                    rhsValue.value as DataObject,
                    this.operator));
        }
        else
        {
            return EmptyValue.instance;
        }
    }

    getName(): string
    {
        switch (this.operator)
        {
            case MathematicalOperator.Add:
                return '... + ...';

            case MathematicalOperator.Divide:
                return '... : ...';

            case MathematicalOperator.Multiply:
                return '... x ...';

            case MathematicalOperator.Subtract:
                return '... - ...';
        }

        return '';
    }

    validate(): Validation[]
    {
        if (!this.lhs)
        {
            return [
                new Validation(
                    'Error',
                    'Geen linkerkant gespecificeerd in berekening')
            ];
        }

        if (!this.rhs)
        {
            return [
                new Validation(
                    'Error',
                    'Geen rechterkant gespecificeerd in berekening')
            ];
        }

        return [
            ...this.lhs.validate(),
            ...this.rhs.validate()
        ];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Mathematical';
        descriptor.lhs = this.lhs.toDescriptor();
        descriptor.operator = MathematicalOperator[this.operator];
        descriptor.rhs = this.rhs.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.lhs.getDependencies(),
            ...this.rhs.getDependencies()
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new MathematicalComputation(
            await getComputationFromDescriptor(descriptor.lhs, dependencyContext),
            (MathematicalOperator as any)[descriptor.operator],
            await getComputationFromDescriptor(descriptor.rhs, dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
