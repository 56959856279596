import { useCallback, useEffect, useState } from 'react';
import uuid from 'uuid';
import { observable, runInAction } from 'mobx';
import { useComputed } from 'mobx-react-lite';

export type StorageType = 'local' | 'session';

const localStorageKey = observable.box(uuid());

export default function useLocalSetting<T>(
    code?: string,
    initialState?: T,
    storageType: StorageType = 'local'
): [ T, (value?: T) => void ]
{
    const [ value, _setValue ] =
        useState<T>(
            () =>
                getValueFromStorage(
                    code,
                    initialState,
                    storageType
                )
        );
    const setValue =
        useCallback(
            (value?: T) =>
            {
                setValueInStorage(
                    code,
                    value,
                    storageType
                );
                _setValue(value);
            },
            [
                code,
                storageType,
                _setValue,
            ]);

    // Whenever the local storage is updated, then reload the value from local storage
    const localStorageState =
        useComputed(
            () =>
                localStorageKey.get(),
            [
                localStorageKey
            ]);

    useEffect(
        () =>
        {
            _setValue(
                getValueFromStorage(
                    code,
                    initialState,
                    storageType
                )
            );
        },
        [
            localStorageState,
            _setValue,
            code,
            storageType,
            initialState,
        ]);

    return [ value, setValue ];
}

export function getValueFromStorage<T>(
    code?: string,
    initialState?: T,
    storageType: StorageType = 'local'
)
{
    if (code)
    {
        try
        {
            const storedValue =
                getStorage(storageType)
                    .getItem(code);

            if (storedValue == null)
            {
                return initialState;
            }
            else
            {
                return JSON.parse(storedValue);
            }
        }
        catch
        {
            return initialState;
        }
    }
    else
    {
        return initialState;
    }
}

export function setValueInStorage<T>(
    code?: string,
    value?: T,
    storageType: StorageType = 'local'
)
{
    if (code)
    {
        try
        {
            if (value === undefined)
            {
                getStorage(storageType).removeItem(code);
            }
            else
            {
                getStorage(storageType)
                    .setItem(
                        code,
                        JSON.stringify(value)
                    );
            }

            runInAction(
                () =>
                    localStorageKey.set(uuid()));
        }
        catch (e)
        {
            console.error(
                'Error while setting local setting with key:',
                code,
                'and value:',
                value,
                'in storage:',
                storageType,
                e
            );
        }
    }
}

export function removeValuesFromStorage(
    codeStartsWith: string,
    storageType: StorageType = 'local'
)
{
    if (codeStartsWith)
    {
        Object.keys(getStorage(storageType))
            .filter(
                key =>
                    key.startsWith(codeStartsWith)
            )
            .forEach(
                key =>
                    getStorage(storageType).removeItem(key)
            );
    }
}

function getStorage(storageType: StorageType)
{
    switch (storageType)
    {
        case 'local':
            return window.localStorage;

        case 'session':
            return window.sessionStorage;

        default:
            throw new Error(`Unknown storage type: ${storageType}`);
    }
}