import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Automation from '../../../../../../../@Api/Automation/Automation';
import { default as TriggerModel } from '../../../../../../../@Api/Automation/Trigger/Trigger';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TriggerEditor from './Trigger/TriggerEditor';
import CompositeAction from '../../../../../../../@Api/Automation/Function/Action/CompositeAction';
import ActionEditor from './Action/ActionEditor';
import FunctionContext from '../../../../../../../@Api/Automation/Function/FunctionContext';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ErrorBoundary from '../../../../../../Error/ErrorBoundary';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import AutomationEntityContext from './Context/AutomationEntityContext';
import useTypes from '../../../../Type/Api/useTypes';
import { AutomationDeactivatedTag } from './DeactivationTag/AutomationDeactivatedTag';
import { makeStyles } from '@material-ui/styles';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';

export interface AutomationEditorProps
{
    entityType?: EntityType;
    automation?: Automation;
    onCreate: (automation: Automation) => void;
    parameterDictionary: ParameterDictionary;
    entity?: Entity;
    readOnly?: boolean;
}

const useStyles =
    makeStyles({
        disabled: {
            pointerEvents: 'none',
            opacity: '70%'
        }
    });

const AutomationEditor: React.FC<AutomationEditorProps> =
    props =>
    {
        const types = useTypes();
        const classes = useStyles();

        const parameterDictionary =
            useComputed(
                () =>
                {
                    if (props.automation)
                    {
                        return props.automation.parameterDictionary;
                    }
                    else
                    {
                        return new ParameterDictionary([]);
                    }
                },
                [
                    props.automation
                ]);

        const onCreateTrigger =
            useCallback(
                (trigger: TriggerModel) =>
                    props.onCreate(
                        new Automation(
                            parameterDictionary,
                            trigger,
                            new CompositeAction([]))),
                [
                    parameterDictionary,
                    props
                ]);

        const functionContext =
            useComputed(
                () =>
                {
                    if (props.automation?.trigger)
                    {
                        return new FunctionContext(
                            new ParameterDictionary(
                                [
                                    ...Array.from(props.parameterDictionary.parameterById.values()),
                                    ...props.automation.trigger.getParameters()
                                ]));
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.parameterDictionary,
                    props.automation
                ]);

        const automationActivation =
            useComputed(
                () =>
                    props.entity
                        .getRelatedEntityByDefinition(
                            false,
                            types.Automation.RelationshipDefinition.Activations
                        ),
                [
                    props.entity,
                    types
                ]
            )

        return  <div
            className={classNames(props.readOnly && classes.disabled)}
        >
            <AutomationEntityContext.Provider
                value={props.entity}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={15}
                >
                    <ViewGroupItem>
                        <ErrorBoundary>
                            <Card>
                                <TriggerEditor
                                    entityType={props.entityType}
                                    onCreate={onCreateTrigger}
                                    trigger={props.automation?.trigger}
                                    context={functionContext}
                                />
                            </Card>
                        </ErrorBoundary>
                    </ViewGroupItem>
                    {
                        !props.readOnly &&
                        <ViewGroupItem>
                            <AutomationDeactivatedTag
                                activation={automationActivation}
                            />
                        </ViewGroupItem>
                    }
                    {
                        props.automation?.action &&
                            <ViewGroupItem>
                                <ErrorBoundary>
                                    <ActionEditor
                                        context={functionContext}
                                        action={props.automation.action}
                                    />
                                </ErrorBoundary>
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </AutomationEntityContext.Provider>
        </div>;
    };

export default observer(AutomationEditor);
