import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import useCampaignEmailStateCount from './useCampaignEmailStateCount';
import { useMemo } from 'react';

export default function useCampaignOpenedCount(campaign: Entity)
{
    const types = useTypes();

    const states =
        useMemo(
            () =>
                [
                    types.Activity.Email.State.Unsubscribed,
                    types.Activity.Email.State.Clicked,
                    types.Activity.Email.State.Opened
                ],
            [
                types
            ]
        );

    return useCampaignEmailStateCount(
        campaign,
        states);
}
