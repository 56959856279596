import { DataType } from './DataType';
import { EntityFieldPath } from '../../../../../Entity/Path/@Model/EntityFieldPath';
import { observable } from 'mobx';
import { registerBuilder } from '../../../../../../../@Util/TransactionalModelV2/Shared/TransactionalBuilder';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { LocalizedTextValue } from '../../../../../../../@Api/Localization/LocalizedTextValue';
import { createTransactionalModel } from '../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';

export class Field
{
    @observable id: string;
    @observable idx: number;
    @observable name: LocalizedTextValue;
    @observable.ref dataType: DataType;
    @observable isPlural: boolean;
    @observable isRequired: boolean;
    @observable isVisibleDuringConstruction: boolean;
    @observable isName: boolean;
    @observable isEditable: boolean;
    @observable.ref pack: Entity;
    @observable.ref fieldPath?: EntityFieldPath;

    constructor(id: string,
                idx: number,
                name: LocalizedTextValue,
                dataType: DataType,
                isPlural: boolean,
                isRequired: boolean,
                isVisibleDuringConstruction: boolean,
                isName: boolean,
                isEditable: boolean,
                pack: Entity,
                fieldPath?: EntityFieldPath)
    {
        this.id = id;
        this.idx = idx;
        this.name = name;
        this.dataType = dataType;
        this.isPlural = isPlural;
        this.isRequired = isRequired;
        this.isVisibleDuringConstruction = isVisibleDuringConstruction;
        this.isName = isName;
        this.isEditable = isEditable;
        this.pack = pack;
        this.fieldPath = fieldPath;
    }

    get entity()
    {
        if (this.fieldPath)
        {
            if (this.fieldPath.field)
            {
                return createTransactionalModel(this.fieldPath.field.entity);
            }
            else
            {
                return this.fieldPath.relationshipDefinition.entity;
            }
        }
        else
        {
            return undefined;
        }
    }
}

registerBuilder(Field).includeAll();
