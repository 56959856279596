import { EntityType } from '../../Model/Implementation/EntityType';
import { Entity } from '../../Model/Implementation/Entity';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { EntitySelectionBuilder } from '../../../@Component/Domain/Entity/Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { DisposableValue } from '../../../@Util/Disposable/DisposableValue';
import { EntityCacheService } from '../../../@Component/Service/Entity/EntityCacheService';
import { default as generateUuid } from '../../../@Util/Id/uuid';

export default async function getEntityByUuid(entityType: EntityType,
                                              uuid: string): Promise<DisposableValue<Entity | undefined>>
{
    const entityTypeStore = loadModuleDirectly(EntityTypeStore);
    const entityCacheService = loadModuleDirectly(EntityCacheService);
    const cachedEntity = entityCacheService.getEntity(uuid);

    if (cachedEntity)
    {
        const cacheReferrer = `getEntityByUuid.${generateUuid()}`;
        entityCacheService.cacheReferrerService.addReferrerToCacheInformationForEntity(cachedEntity, cacheReferrer);

        return {
            value: cachedEntity,
            dispose:
                () =>
                    entityCacheService.disposeReferrer(cacheReferrer),
        };
    }
    else
    {
        const selectionBuilder =
            new EntitySelectionBuilder(entityType)
                .where(
                    cb =>
                        cb.eq(
                            EntityPath.fromEntityType(entityType)
                                .field(entityTypeStore.bespoke.types.Entity.Field.Uuid),
                            undefined,
                            uuid));

        const results = await selectionBuilder.select(0, 1);

        return {
            value: results.length > 0 ? results[0].entity : undefined,
            dispose: () => selectionBuilder.dispose()
        };
    }
}
