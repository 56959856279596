import React from 'react';
import { DialogContent as MuiDialogContent } from '@material-ui/core';

import styles from './DialogContent.module.scss';

export interface DialogContentProps
{

}
const DialogContent: React.FC<DialogContentProps> =
    props =>
    {
        return <MuiDialogContent>
            <div
                className={styles.root}
            >
                {props.children}
            </div>
        </MuiDialogContent>;
    };

export default DialogContent;
