import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../../Model/DataObjectType';
import { Comparator } from '../../../Model/Comparator';
import { Aggregate } from '../../../Model/Aggregate';
import { MathematicalOperator } from '../../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../../Model/DataObjectFunction';
import { DataObject } from '../../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../../@Util/DependencyInjection/index';
import { PredicateView } from './PredicateView';
import { PredicateEditor } from './PredicateEditor';
import { PredicateSpecification } from './PredicateSpecification';
import { DataDescriptor } from '../../../Model/DataDescriptor';
import { ComparatorDescriptor } from '../../../Model/ComparatorDescriptor';
import { DataObjectRepresentation } from '../../../Model/DataObjectRepresentation';
import { DataObjectOperatorOverload } from '../../../Model/DataObjectOperatorOverload';
import { DataObjectContext } from '../../../Model/DataObjectContext';
import { EntityTypeStore } from '../../../../Entity/Type/EntityTypeStore';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { DataObjectBespokeEditorStore } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { PredicateEditorStore } from './PredicateEditorStore';
import { DataObjectSpecification } from '../../../Model/DataObjectSpecification';
import { OldPredicate } from '../../../../Predicate/Type/OldPredicate';
import { PredicateTypeStore } from '../../../../Predicate/PredicateTypeStore';
import { DataObjectComparatorOverload } from '../../../Model/DataObjectComparatorOverload';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { toJS } from 'mobx';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export class PredicateType implements DataObjectType<OldPredicate>
{
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('PredicateTypeStore') predicateTypeStore: PredicateTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Predicate';
    }

    name()
    {
        return localizeText('DataObject.Type.Predicate', 'Predicaat');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [
            {
                comparator: Comparator.Equals
            },
            {
                comparator: Comparator.NotEquals
            }
        ];
    }

    supportedAggregates(): Aggregate[]
    {
        return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return PredicateView;
    }

    editor(): DataObjectEditorType
    {
        return PredicateEditor;
    }

    editorStore(editorStore: DataObjectEditorStore): DataObjectBespokeEditorStore
    {
        return new PredicateEditorStore(editorStore);
    }

    hasLargeEditor(specification: DataObjectSpecification): boolean
    {
        return false;
    }

    specification(): DataObjectSpecificationType
    {
        return PredicateSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return null;
    }

    requiresInitialization(value: OldPredicate): boolean
    {
        return false;
    }

    getDataFromValue(value: OldPredicate, specification: DataObjectSpecification): DataDescriptor
    {
        let data = new DataDescriptor();
        try
        {
            data.complex = this.predicateTypeStore.toSpecification(value);
        }
        catch (e)
        {
            console.error('Error while resolving predicate specification', value, e);
        }

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): OldPredicate
    {
        if (data.complex)
        {
            return this.predicateTypeStore.fromSpecification(data.complex);
        }
        else
        {
            return null;
        }
    }

    valueId(value: OldPredicate): string
    {
        return JSON.stringify(toJS(this.predicateTypeStore.toSpecification(value)));
    }

    getString(value: OldPredicate,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        return 'Predicaat';
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return false;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [];
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    isValid(dataObject: DataObject): boolean
    {
        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return false;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }

}
