import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { action, observable } from 'mobx';

export class JsonEditorStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable defaultValue: object;
    @observable value: object;

    // ------------------------ Constructor -------------------------

    constructor(value: object)
    {
        super();

        this.defaultValue = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    handleChange(value: object)
    {
        this.value = value;

        return true;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
