import React from 'react';
import { default as MuiCircularProgress } from '@material-ui/core/CircularProgress';

export interface LoaderProps
{
    size?: number;
}

const Loader: React.FC<LoaderProps> =
    props =>
    {
        return <MuiCircularProgress
            size={props.size}
        />;
    };

export default Loader;
