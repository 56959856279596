import { EntitySelectionBuilder } from '../../../../@Component/Domain/Entity/Selection/Builder/EntitySelectionBuilder';
import { Entity } from '../../../Model/Implementation/Entity';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { EntityPath } from '../../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityType } from '../../../Model/Implementation/EntityType';

export default async function getDatastoreByCode(datastoreType: EntityType,
                                                 code: string): Promise<Entity>
{
    const entityTypeStore = loadModuleDirectly(EntityTypeStore);

    const results = await new EntitySelectionBuilder(datastoreType)
        .where(
            cb =>
                cb.eq(
                    EntityPath.fromEntityType(datastoreType)
                        .field(entityTypeStore.bespoke.types.Datastore.Field.Code),
                    undefined,
                    code))
        .select(0, 1);

    return results
        .map(
            result =>
                result.entity)
        .find(() => true);
}
