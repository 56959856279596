import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { ValueEditorProps } from '../../ValueEditor';
import { EntitySelectionBuilder } from '../../../../../../../Selection/Builder/EntitySelectionBuilder';
import Selectbox from '../../../../../../../Selectbox/Selectbox';
import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import DataSourceValueType from '../../../../../../../../../../@Api/Automation/Value/Type/DataSourceValueType';
import DataSourceValue from '../../../../../../../../../../@Api/Automation/Value/DataSourceValue';
import PortalDataSourceValue from '../../../../../../../../../../@Api/Portal/DataSource/PortalDataSourceValue';
import ParameterAssignment from '../../../../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import useEntityByUuid from '../../../../../../../../../../@Api/Entity/Bespoke/useEntityByUuid';

export interface DataSourceEditorProps extends ValueEditorProps<DataSourceValueType, DataSourceValue>
{

}

const DataSourceEditor: React.FC<DataSourceEditorProps> =
    props =>
    {
        const { type, value, onChange, filter, autoFocus } = props;

        const [ entity ] = useEntityByUuid(type.dataSource.entityType, value?.value.id);
        const selections =
            useComputed(
                () =>
                    type.dataSource
                        ?
                            [
                                new EntitySelectionBuilder(type.dataSource.entityType)
                                    .if(
                                        () => type.dataSource.filter !== undefined,
                                        sb =>
                                            sb.where(
                                                cb =>
                                                    cb.filter(
                                                        type.dataSource.filter,
                                                        {
                                                            parameter: type.dataSource.resultParameter,
                                                        }
                                                    )
                                            )
                                    )
                                    .build()
                            ]
                        :
                            [],
                [
                    type
                ]);

        const selectEntity =
            useCallback(
                (entity: Entity) =>
                    onChange(
                        entity
                            ?
                                new DataSourceValue(
                                    new PortalDataSourceValue(
                                        entity.uuid,
                                        type.dataSource.id,
                                        new ParameterAssignment(),
                                        new ParameterAssignment()))
                            :
                                undefined),
                [
                    onChange,
                    type
                ]);

        return <Selectbox
            selections={selections}
            value={entity}
            onChange={selectEntity}
            clearable
            emptyOption={filter ? `Alle ${type.getName().toLowerCase()}s` : undefined}
            hideCaptionInValue={filter}
            autoFocus={autoFocus}
        />;
    };

export default observer(DataSourceEditor);
