import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import Inset from '../Inset/Inset';
import useTypes from '../../../Type/Api/useTypes';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTextEntityValue from '../../../../../../@Api/Entity/Hooks/useTextEntityValue';
import Click from '../Click/Click';
import useRelatedEntity from '../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import Caption from '../../Caption/Caption';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';

export interface ProductLineProps
{
    entity: Entity;
}

const ProductLine: React.FC<ProductLineProps> =
    props =>
    {
        const types = useTypes();
        const description =
            useTextEntityValue(
                props.entity,
                types.ProductLine.Field.Description);

        const quantity =
            useTextEntityValue(
                props.entity,
                types.ProductLine.Field.Quantity);

        const currency =
            useEntityValue<string>(
                props.entity,
                types.ProductLine.Field.Currency);

        const activity =
            useRelatedEntity(
                props.entity,
                types.Activity.RelationshipDefinition.ProductLines,
                true);

        return <Click
            open
            allowExpansionHighlight
        >
            <Inset>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={5}
                            alignment="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                {quantity}x {description}
                            </ViewGroupItem>
                            <ViewGroupItem>
                                {
                                    props.entity.getDataObjectValueByField(
                                        currency
                                            ? types.ProductLine.Field.TotalInCurrency
                                            : types.ProductLine.Field.Total
                                    ).toCurrencyString(currency)
                                }
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    {
                        activity &&
                            <ViewGroupItem>
                                <Caption
                                    entity={activity}
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </Inset>
        </Click>;
    };

export default observer(ProductLine);
