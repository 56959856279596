import LayoutDependencyContext from '../../../LayoutDependencyContext';
import StaticTableDimensionSection from '../Model/StaticTableDimensionSection';
import CollectionTableDimensionSection from '../Model/CollectionTableDimensionSection';

export default async function getTableDimensionSectionByDescriptor(descriptor: any,
                                                                   dependencyContext: LayoutDependencyContext)
{
    switch (descriptor.type)
    {
        case 'Static':
            return StaticTableDimensionSection.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Collection':
            return CollectionTableDimensionSection.fromDescriptor(
                descriptor,
                dependencyContext);
    }

    throw new Error(`Unknown type: ${descriptor.type}`);
}
