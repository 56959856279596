import ParameterDictionary from '../../Automation/Parameter/ParameterDictionary';
import { observable } from 'mobx';
import AutomationDependencyContext from '../../Automation/AutomationDependencyContext';

export default class PortalDataSourceSignature
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable name: string;
    @observable.ref parameters: ParameterDictionary;
    @observable.ref resultParameters: ParameterDictionary;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                name: string,
                parameters: ParameterDictionary,
                resultParameters: ParameterDictionary)
    {
        this.id = id;
        this.name = name;
        this.parameters = parameters;
        this.resultParameters = resultParameters;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext = AutomationDependencyContext.GetEmptyContext())
    {
        const parameters =
            await ParameterDictionary.fromDescriptor(
                descriptor.parameters,
                dependencyContext);

        const resultParameters =
            await ParameterDictionary.fromDescriptor(
                descriptor.resultParameters,
                dependencyContext);

        return new PortalDataSourceSignature(
            descriptor.id,
            descriptor.name,
            parameters,
            resultParameters);
    }

    // ----------------------- Private logic ------------------------
}
