import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Layout from '../../../../../@Api/Layout/Layout';
import useSwitch from '../../../../../@Util/Switch/useSwitch';
import Popper from '../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import { ActionType } from '../../../Entity/Viewer/Content/Automation/Editor/Action/Constructor/ActionConstructor';
import CompositeLayout from '../../../../../@Api/Layout/Type/CompositeLayout';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import EntityInputLayout from '../../../../../@Api/Layout/Type/EntityInputLayout';
import InsetLayout from '../../../../../@Api/Layout/Type/InsetLayout';
import styles from './LayoutConstructor.module.scss';
import Icon from '../../../../../@Future/Component/Generic/Icon/Icon';
import { classNames } from '../../../../../@Future/Util/Class/classNames';
import ExpansionLayout from '../../../../../@Api/Layout/Type/ExpansionLayout';
import ComputationLayout from '../../../../../@Api/Layout/Type/ComputationLayout';
import PrimaryTextButton from '../../../../../@Future/Component/Generic/Button/Variant/PrimaryTextButton/PrimaryTextButton';
import ConditionalLayout from '../../../../../@Api/Layout/Type/ConditionalLayout';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import { Comparator } from '../../../DataObject/Model/Comparator';
import OptionalRelatedEntityLayout from '../../../../../@Api/Layout/Type/OptionalRelatedEntityLayout';
import { getCopiedLayoutFromClipboard, hasCopiedLayoutInClipboard } from '../Clipboard/LayoutClipboard';
import isLayoutCompatibleWithParameterDictionary from '../Api/isLayoutCompatibleWithParameterDictionary';
import getLayoutFromDescriptor from '../../../../../@Api/Layout/Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../@Api/Layout/LayoutDependencyContext';
import CompositeLayoutDepth from '../../Type/Composite/Context/CompositeLayoutDepth';
import TextComputation from '../../../../../@Api/Automation/Function/Computation/TextComputation';
import CardLayout from '../../../../../@Api/Layout/Type/CardLayout';
import PortalContext from '../../../../../@Api/Portal/Context/PortalContext';
import PortalListLayout from '../../../../../@Api/Layout/Type/PortalListLayout';
import ClickLayout from '../../../../../@Api/Layout/Type/ClickLayout';
import CompositeAction from '../../../../../@Api/Layout/Action/Type/CompositeAction';
import DynamicParameterAssignment from '../../../../../@Api/Automation/Function/Dynamic/DynamicParameterAssignment';
import FormLayout from '../../../../../@Api/Layout/Type/Form/FormLayout';
import uuid from '../../../../../@Util/Id/uuid';
import FormContext from '../../Type/Form/FormContext';
import PrimitiveFormInputLayout from '../../../../../@Api/Layout/Type/Form/Input/PrimitiveFormInputLayout';
import LabelButtonLayout from '../../../../../@Api/Layout/Type/LabelButtonLayout';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import SelectionFormInputLayout from '../../../../../@Api/Layout/Type/Form/Input/SelectionFormInputLayout';
import localizeText from '../../../../../@Api/Localization/localizeText';
import LabelLayout from '../../../../../@Api/Layout/Type/LabelLayout';
import PortalImageLayout from '../../../../../@Api/Layout/Type/PortalImageLayout';
import AppBarLayout from '../../../../../@Api/Layout/Type/AppBarLayout';
import PortalUserButtonLayout from '../../../../../@Api/Layout/Type/PortalUserButtonLayout';
import CenteredBodyLayout from '../../../../../@Api/Layout/Type/CenteredBodyLayout';
import PortalRouterLayout from '../../../../../@Api/Layout/Type/PortalRouterLayout';
import CompositeLayoutItem from '../../../../../@Api/Layout/Type/CompositeLayoutItem';
import Dimension from '../../../../../@Api/Layout/Style/Dimension';
import { primaryColor } from '../../../../../@Resource/Theme/Theme';
import Color from '../../../../../@Api/Layout/Style/Color';
import HeaderWithScrollBodyLayout from '../../../../../@Api/Layout/Type/HeaderWithScrollBodyLayout';
import PortalSwitcherTabBarLayout from '../../../../../@Api/Layout/Type/PortalSwitcherTabBarLayout';
import LocalizedLayout from '../../../../../@Api/Layout/Type/LocalizedLayout';
import ResourceImageLayout from '../../../../../@Api/Layout/Type/ResourceImageLayout';
import TemplateLayout from '../../../../../@Api/Layout/Type/TemplateLayout';
import BodyLayout from '../../../../../@Api/Layout/Type/BodyLayout';
import ExpansionGroupLayout from '../../../../../@Api/Layout/Type/ExpansionGroupLayout';
import DrawingFormInputLayout from '../../../../../@Api/Layout/Type/Form/Input/DrawingFormInputLayout';
import { runInAction } from 'mobx';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import PrimitiveValueType from '../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../DataObject/Model/DataObject';
import ComputedImageLayout from '../../../../../@Api/Layout/Type/ComputedImageLayout';
import EmptyValue from '../../../../../@Api/Automation/Value/EmptyValue';
import CollectionType from '../../../../../@Api/Automation/Value/Type/CollectionType';
import FilesFormInputLayout from '../../../../../@Api/Layout/Type/Form/Input/FilesFormInputLayout';
import TabBarLayout from '../../../../../@Api/Layout/Type/TabBar/TabBarLayout';
import StaticTabSource from '../../../../../@Api/Layout/Type/TabBar/Model/StaticTabSource';
import TableLayout from '../../../../../@Api/Layout/Type/Table/TableLayout';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import StaticTableDimensionSection from '../../../../../@Api/Layout/Type/Table/Model/StaticTableDimensionSection';
import EntitySelectionFormInputLayout from '../../../../../@Api/Layout/Type/Form/Input/EntitySelectionFormInputLayout';
import PortalRouteLayout from '../../../../../@Api/Layout/Type/PortalRouteLayout';
import CollectionLayout from '../../../../../@Api/Layout/Type/CollectionLayout';
import CollectionValue from '../../../../../@Api/Automation/Value/CollectionValue';
import EmptyValueType from '../../../../../@Api/Automation/Value/Type/EmptyValueType';
import EffectLayout from '../../../../../@Api/Layout/Type/EffectLayout';
import AddressLookupLayout from '../../../../../@Api/Layout/Type/AddressLookupLayout';
import PortalTableLayout from '../../../../../@Api/Layout/Type/PortalTable/PortalTableLayout';
import LayoutEventTriggers from '../../../../../@Api/Layout/Event/LayoutEventTriggers';
import LocalizedLayoutItem from '../../../../../@Api/Layout/Type/LocalizedLayoutItem';
import { useLocalizer } from '../../../../../@Service/Localization/Api/useLocalizer';
import InteractiveLayoutContext from '../../InteractiveLayoutContext';

export type LayoutConstructorMode = 'Button' | 'EditButton' | 'Horizontal' | 'Vertical' | 'VerticalButton';

export interface LayoutConstructorProps
{
    parameterDictionary: ParameterDictionary;
    onConstruct: (layout: Layout) => void;
    mode: LayoutConstructorMode;
    onHover?: (isHovering: boolean) => void;
    layout?: Layout;
}

const LayoutConstructor: React.FC<LayoutConstructorProps> =
    props =>
    {
        const [ isOpen, open, close ] = useSwitch(false);
        const localizer = useLocalizer();
        const startHovering =
            useCallback(
                () =>
                    props.onHover &&
                        props.onHover(true),
                [
                    props.onHover
                ]);
        const stopHovering =
            useCallback(
                () =>
                    props.onHover &&
                        props.onHover(false),
                [
                    props.onHover
                ]);

        const onConstruct =
            useCallback(
                (layout: Layout) =>
                {
                    props.onConstruct(layout);
                    close();
                },
                [
                    props.onConstruct,
                    close
                ]);

        const portalContext = useContext(PortalContext);
        const form = useContext(FormContext);
        const interactiveLayoutContext = useContext(InteractiveLayoutContext)

        const types =
            useComputed<ActionType[]>(
                () => [
                    ...props.parameterDictionary.parameters
                        .filter(
                            parameter =>
                                parameter.type instanceof EntityValueType)
                        .map(
                            parameter => ({
                                id: `Parameter.${parameter.id}.Input`,
                                name: localizeText('LayoutConstructor.ParameterInput', 'Inputveld van ${name}', { name: parameter.name.toLowerCase() }),
                                construct:
                                    () =>
                                        onConstruct(
                                            new EntityInputLayout(
                                                parameter,
                                                undefined,
                                                'Left'))
                            })),
                    {
                        id: 'CompositeLayoutVertical',
                        name: localizeText('LayoutConstructor.CompositeLayoutVertical', 'Verticale layout'),
                        construct:
                            () =>
                                onConstruct(
                                    new CompositeLayout(
                                        'Vertical',
                                        [],
                                        0))
                    },
                    {
                        id: 'CompositeLayoutHorizontal',
                        name: localizeText('LayoutConstructor.CompositeLayoutHorizontal', 'Horizontale layout'),
                        construct:
                            () =>
                                onConstruct(
                                    new CompositeLayout(
                                        'Horizontal',
                                        [],
                                        0))
                    },
                    {
                        id: 'CollectionLayoutVertical',
                        name: localizeText('Layout.Collection.Vertical', 'Dynamische verticale layout'),
                        construct:
                            () =>
                                onConstruct(
                                    new CollectionLayout(
                                        'Vertical',
                                        new CollectionValue([], EmptyValueType.instance),
                                        CollectionLayout.getParameterFromCollection(
                                            uuid(),
                                            new CollectionValue([], EmptyValueType.instance)),
                                        new CompositeLayout(
                                            'Vertical',
                                            [],
                                            0),
                                        EmptyValue.instance,
                                        0,
                                        false,
                                        false))
                    },
                    {
                        id: 'CollectionLayoutHorizontal',
                        name: localizeText('Layout.Collection.Horizontal', 'Dynamische horizontale layout'),
                        construct:
                            () =>
                                onConstruct(
                                    new CollectionLayout(
                                        'Horizontal',
                                        new CollectionValue([], EmptyValueType.instance),
                                        CollectionLayout.getParameterFromCollection(
                                            uuid(),
                                            new CollectionValue([], EmptyValueType.instance)),
                                        new CompositeLayout(
                                            'Vertical',
                                            [],
                                            0),
                                        EmptyValue.instance,
                                        0,
                                        false,
                                        false))
                    },
                    {
                        id: 'LocalizedLayout',
                        name: localizeText('LayoutConstructor.LocalizedLayout', 'Gelokaliseerde layout'),
                        construct:
                            () =>
                                onConstruct(
                                    new LocalizedLayout(
                                        props.layout
                                            ? [
                                                new LocalizedLayoutItem(
                                                    uuid(),
                                                    localizer.languageCode,
                                                    props.layout
                                                )
                                            ]
                                            : []
                                    )
                                )
                    },
                    {
                        id: 'CardLayout',
                        name: localizeText('LayoutConstructor.CardLayout', 'Kaart'),
                        construct:
                            () =>
                                onConstruct(
                                    new CardLayout(
                                        new CompositeLayout(
                                            'Vertical',
                                            [],
                                            0)))
                    },
                    {
                        id: 'InsetLayout',
                        name: localizeText('LayoutConstructor.InsetLayout', 'Layout met padding'),
                        construct:
                            () =>
                                onConstruct(
                                    new InsetLayout(
                                        new CompositeLayout(
                                            'Vertical',
                                            [],
                                            0),
                                        15,
                                        15,
                                        15,
                                        15))
                    },
                    {
                        id: 'ConditionalLayout',
                        name: localizeText('LayoutConstructor.ConditionalLayout', 'Conditionele layout'),
                        construct:
                            () =>
                                onConstruct(
                                    new ConditionalLayout(
                                        new CompositePredicate(
                                            LogicalOperator.And,
                                            [
                                                new ComparisonPredicate(
                                                    undefined,
                                                    Comparator.Equals,
                                                    undefined)
                                            ]),
                                        new CompositeLayout(
                                            'Vertical',
                                            [],
                                            0)))
                    },
                    {
                        id: 'RichText',
                        name: localizeText('LayoutConstructor.RichText', 'Tekst met opmaak'),
                        construct:
                            () =>
                                onConstruct(
                                    new ComputationLayout(
                                        new TextComputation(
                                            undefined,
                                            true,
                                            [])))
                    },
                    {
                        id: 'Table',
                        name: localizeText('Generic.Table', 'Tabel'),
                        construct:
                            () =>
                                onConstruct(
                                    new TableLayout(
                                        [
                                            new StaticTableDimensionSection(
                                                uuid(),
                                                new PrimitiveValue(DataObject.constructFromTypeIdAndValue('Number', 1)),
                                                undefined
                                            ),
                                            new StaticTableDimensionSection(
                                                uuid(),
                                                new PrimitiveValue(DataObject.constructFromTypeIdAndValue('Number', 1)),
                                                undefined
                                            )
                                        ],
                                        [
                                            new StaticTableDimensionSection(
                                                uuid(),
                                                new PrimitiveValue(DataObject.constructFromTypeIdAndValue('Number', 1)),
                                                undefined
                                            ),
                                            new StaticTableDimensionSection(
                                                uuid(),
                                                new PrimitiveValue(DataObject.constructFromTypeIdAndValue('Number', 1)),
                                                undefined
                                            )
                                        ],
                                        [],
                                        []
                                    )
                                )
                    },
                    ...props.parameterDictionary.parameters
                        .filter(
                            parameter =>
                                parameter.type instanceof EntityValueType)
                        .map(
                            parameter => ({
                                id: `Parameter.${parameter.id}.OptionalRelatedEntity`,
                                name: localizeText('LayoutConstructor.ParameterOptionalRelatedEntity', 'Nieuw of bestaand gerelateerd record van ${name}', { name: parameter.name.toLowerCase() }),
                                construct:
                                    () =>
                                        onConstruct(
                                            new OptionalRelatedEntityLayout(
                                                parameter,
                                                undefined,
                                                undefined,
                                                new CompositeLayout(
                                                    'Vertical',
                                                    [],
                                                    0)))
                            })),
                    {
                        id: 'ComputedImage',
                        name: localizeText('Layout.ComputedImage', 'Afbeelding (dynamisch)'),
                        construct:
                            () =>
                                onConstruct(
                                    new ComputedImageLayout(
                                        EmptyValue.instance,
                                        undefined,
                                        undefined))
                    },
                    ...portalContext
                        ?
                            []
                        :
                            [
                                {
                                    id: 'Body',
                                    name: localizeText('Layout.Body', 'Body'),
                                    construct:
                                        () =>
                                            onConstruct(
                                                new BodyLayout(
                                                    new InsetLayout(
                                                        new CenteredBodyLayout(
                                                            new CardLayout(
                                                                new CompositeLayout(
                                                                    'Vertical',
                                                                    [])),
                                                            new Dimension(
                                                                600,
                                                                'Pixels')),
                                                        15,
                                                        0,
                                                        15,
                                                        0),
                                                    new Color('#f5f5f5')))
                                },
                                {
                                    id: 'ResourceImage',
                                    name: localizeText('Generic.Image', 'Afbeelding'),
                                    construct:
                                        () =>
                                            onConstruct(
                                                new ResourceImageLayout(
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined))
                                },
                                {
                                    id: 'Template',
                                    name: localizeText('Generic.Template', 'Sjabloon'),
                                    construct:
                                        () =>
                                            onConstruct(
                                                new TemplateLayout(
                                                    undefined,
                                                    undefined,
                                                    undefined))
                                }
                            ],
                    ...portalContext
                        ?
                            [
                                {
                                    id: 'PortalList',
                                    name: localizeText('LayoutConstructor.PortalList', 'Data lijst'),
                                    construct:
                                        () =>
                                            onConstruct(
                                                new PortalListLayout(
                                                    undefined,
                                                    new DynamicParameterAssignment(),
                                                    [],
                                                    undefined,
                                                    new CompositeLayout(
                                                        'Vertical',
                                                        []),
                                                    undefined,
                                                    true))
                                },
                                {
                                    id: 'PortalList',
                                    name: localizeText('LayoutConstructor.PortalTable', 'Data tabel'),
                                    construct:
                                        () =>
                                            onConstruct(
                                                new PortalTableLayout(
                                                    undefined,
                                                    new DynamicParameterAssignment(),
                                                    undefined,
                                                    [],
                                                    [],
                                                    new LayoutEventTriggers([])
                                                )
                                            )
                                },
                                {
                                    id: 'PortalImage',
                                    name: localizeText('Generic.Image', 'Afbeelding'),
                                    construct:
                                        () =>
                                            onConstruct(
                                                new PortalImageLayout(
                                                    undefined,
                                                    undefined,
                                                    undefined))
                                },
                                {
                                    id: 'HeaderWithScrollBody',
                                    name: 'Header met scroll body',
                                    construct:
                                        () =>
                                            onConstruct(
                                                new HeaderWithScrollBodyLayout(
                                                    new CompositeLayout(
                                                        'Vertical',
                                                        []),
                                                    new CompositeLayout(
                                                        'Vertical',
                                                        [])))
                                },
                                {
                                    id: 'AppBar',
                                    name: 'App header',
                                    construct:
                                        () =>
                                            onConstruct(
                                                new AppBarLayout(
                                                    new InsetLayout(
                                                        new CompositeLayout(
                                                            'Horizontal',
                                                            [
                                                                new CompositeLayoutItem(
                                                                    uuid(),
                                                                    new PortalImageLayout(
                                                                        undefined,
                                                                        undefined,
                                                                        new Dimension(35, 'Pixels')),
                                                                    undefined),
                                                                new CompositeLayoutItem(
                                                                    uuid(),
                                                                    new CompositeLayout(
                                                                        'Horizontal',
                                                                        []),
                                                                    1),
                                                                new CompositeLayoutItem(
                                                                    uuid(),
                                                                    new PortalUserButtonLayout(),
                                                                    undefined)
                                                            ],
                                                            15),
                                                        0,
                                                        15,
                                                        0,
                                                        15),
                                                    new Color(primaryColor)))
                                },
                                {
                                    id: 'PortalSwitcherTabBar',
                                    name: 'Portaal switcher tab bar',
                                    construct:
                                        () =>
                                            onConstruct(
                                                new PortalSwitcherTabBarLayout())
                                },
                                {
                                    id: 'CenteredContent',
                                    name: 'Gecentreerde content',
                                    construct:
                                        () =>
                                            onConstruct(
                                                new CenteredBodyLayout(
                                                    new CompositeLayout(
                                                        'Vertical',
                                                        [])))
                                },
                                {
                                    id: 'Router',
                                    name: 'Pagina router',
                                    construct:
                                        () =>
                                            onConstruct(
                                                new PortalRouterLayout())
                                },
                                {
                                    id: 'PortalButton',
                                    name: 'Portaalknop',
                                    construct:
                                        () =>
                                            onConstruct(
                                                new PortalUserButtonLayout())
                                },
                                {
                                    id: 'PortalPage',
                                    name: 'Portaal pagina',
                                    construct:
                                        () =>
                                            onConstruct(
                                                new PortalRouteLayout(
                                                    new TextComputation(
                                                        '/',
                                                        false,
                                                        [])))
                                },
                            ]
                        :
                            [],
                    ...interactiveLayoutContext
                        ?
                        [
                            {
                                id: 'ExpansionGroupLayout',
                                name: localizeText('Layout.ExpansionGroup', 'Uitklapgroep'),
                                construct:
                                    () =>
                                        onConstruct(
                                            new ExpansionGroupLayout(
                                                new CompositeLayout(
                                                    'Vertical',
                                                    [],
                                                    0)))
                            },
                            {
                                id: 'ExpansionLayout',
                                name: localizeText('Layout.Expansion', 'Uitklappaneel'),
                                construct:
                                    () =>
                                        onConstruct(
                                            new ExpansionLayout(
                                                new ComputationLayout(
                                                    new TextComputation(
                                                        localizeText('LayoutConstructor.Title', 'Titel...'),
                                                        false,
                                                        [])),
                                                new CompositeLayout(
                                                    'Vertical',
                                                    [],
                                                    0),
                                                true))
                            },
                            {
                                id: 'Click',
                                name: localizeText('LayoutConstructor.Click', 'Klikregio'),
                                construct:
                                    () =>
                                        onConstruct(
                                            new ClickLayout(
                                                new CompositeLayout(
                                                    'Vertical',
                                                    []),
                                                new CompositeAction([])))
                            },
                            {
                                id: 'LabelButton',
                                name: localizeText('Generic.Button', 'Knop'),
                                construct:
                                    () =>
                                        onConstruct(
                                            new LabelButtonLayout(
                                                new ComputationLayout(
                                                    new TextComputation(
                                                        'Label...',
                                                        false,
                                                        [])),
                                                new CompositeAction([]),
                                                'Contained',
                                                'Primary'))
                            },
                            {
                                id: 'Form',
                                name: localizeText('LayoutConstructor.Form', 'Formulier'),
                                construct:
                                    () =>
                                        onConstruct(
                                            new FormLayout(
                                                uuid(),
                                                new ParameterDictionary([]),
                                                new CompositeLayout(
                                                    'Vertical',
                                                    [])))
                            },
                            {
                                id: 'TabBar',
                                name: localizeText('Layout.TabBar', 'Tabbalk'),
                                construct:
                                    () =>
                                        runInAction(
                                            () =>
                                            {
                                                const valueParameter =
                                                    new Parameter(
                                                        uuid(),
                                                        new PrimitiveValueType(DataObject.getTypeById('Text')),
                                                        false,
                                                        localizeText('Layout.TabBar.ValueOfTabBar', 'Waarde van tabbalk'));

                                                form.parameters.addParameter(valueParameter);

                                                return onConstruct(
                                                    new TabBarLayout(
                                                        valueParameter,
                                                        [
                                                            new StaticTabSource(
                                                                uuid(),
                                                                [])
                                                        ]));
                                            })
                            },
                        ]
                    :
                        [],
                    {
                        id: 'Label',
                        name: localizeText('Generic.Label', 'Label'),
                        construct:
                            () =>
                                onConstruct(
                                    new LabelLayout(
                                        'Left',
                                        new ComputationLayout(
                                            new TextComputation(
                                                'Label...',
                                                false,
                                                [])),
                                        new CompositeLayout(
                                            'Vertical',
                                            [])))
                    },
                    ...form
                        ?
                            [
                                {
                                    id: 'PrimitiveFormInput',
                                    name: localizeText('LayoutConstructor.PrimitiveFormInput', 'Veld in formulier (tekst, bedrag, datum, ...)'),
                                    construct:
                                        () =>
                                            onConstruct(
                                                new PrimitiveFormInputLayout(
                                                    form.id,
                                                    undefined,
                                                    undefined,
                                                    undefined))
                                },
                                {
                                    id: 'SelectionFormInput',
                                    name: localizeText('Layout.SelectionFormInput', 'Veld in formulier (selectbox)'),
                                    construct:
                                        () =>
                                            onConstruct(
                                                portalContext === undefined
                                                    ?
                                                        new EntitySelectionFormInputLayout(
                                                            form.id,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            new CompositeLayout(
                                                                'Vertical',
                                                                []),
                                                            [])
                                                    :
                                                        new SelectionFormInputLayout(
                                                            form.id,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            new DynamicParameterAssignment(),
                                                            undefined,
                                                            [],
                                                            undefined,
                                                            new CompositeLayout(
                                                                'Vertical',
                                                                [])))
                                },
                                {
                                    id: 'DrawingFormInput',
                                    name: localizeText('Layout.DrawingFormInput', 'Veld in formulier (handtekening)'),
                                    construct:
                                        () =>
                                            runInAction(
                                                () =>
                                                {
                                                    const parameter =
                                                        new Parameter(
                                                            uuid(),
                                                            new PrimitiveValueType(DataObject.getTypeById('File')),
                                                            false,
                                                            localizeText('Generic.Signature', 'Handtekening'));

                                                    form.parameters.addParameter(parameter);

                                                    return onConstruct(
                                                        new DrawingFormInputLayout(
                                                            form.id,
                                                            parameter.id,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            200));
                                                })
                                },
                                {
                                    id: 'FilesFormInput',
                                    name: localizeText('Layout.FilesFormInput', 'Veld in formulier (meerdere bestanden)'),
                                    construct:
                                        () =>
                                            runInAction(
                                                () =>
                                                {
                                                    const parameter =
                                                        new Parameter(
                                                            uuid(),
                                                            new CollectionType(
                                                                new PrimitiveValueType(
                                                                    DataObject.getTypeById('File'))),
                                                            false,
                                                            localizeText('Generic.Files', 'Bestanden'));

                                                    form.parameters.addParameter(parameter);

                                                    return onConstruct(
                                                        new FilesFormInputLayout(
                                                            form.id,
                                                            parameter.id,
                                                            undefined,
                                                            undefined));
                                                })
                                },
                                ...portalContext
                                    ?
                                        []
                                    :
                                        [
                                            {
                                                id: 'AddressLookup',
                                                name: localizeText('Layout.AddressLookup', 'Adres opzoeker'),
                                                construct:
                                                    () =>
                                                        onConstruct(
                                                            new AddressLookupLayout()
                                                        )
                                            },
                                        ],
                                {
                                    id: 'Effect',
                                    name: localizeText('Layout.Effect', 'Effect'),
                                    construct:
                                        () =>
                                            onConstruct(
                                                new EffectLayout(
                                                    new CompositeAction([]),
                                                    []
                                                )
                                            )
                                },
                            ]
                        :
                            [],
                    ...hasCopiedLayoutInClipboard() && isLayoutCompatibleWithParameterDictionary(getCopiedLayoutFromClipboard(), props.parameterDictionary)
                        ?
                            [
                                {
                                    id: 'PasteFromClipboard',
                                    name: localizeText('Generic.PasteFromClipboard', 'Plakken vanuit klembord'),
                                    construct:
                                        async () =>
                                        {
                                            const layoutDescriptor = getCopiedLayoutFromClipboard().toDescriptor();
                                            const copiedLayout =
                                                await getLayoutFromDescriptor(
                                                    layoutDescriptor,
                                                    new LayoutDependencyContext(props.parameterDictionary));

                                            return onConstruct(copiedLayout);
                                        }
                                }
                            ]
                        :
                            []
                ],
                [
                    onConstruct,
                    portalContext,
                    form,
                    localizer,
                    props.parameterDictionary,
                    props.layout,
                ]);

        const depth = useContext(CompositeLayoutDepth);
        const style =
            useMemo(
                () => ({
                    height: props.mode === 'Vertical' ? 20 : undefined,
                    width: props.mode === 'Horizontal' ? 16 : undefined
                }),
                [
                    props.mode,
                    depth
                ]);

        return <>
            {
                props.mode === 'VerticalButton'
                    ?
                        <PrimaryTextButton
                            classes={{
                                root: styles.verticalButton
                            }}
                            onClick={open}
                            fullWidth
                            label="+"
                        />
                    :
                        props.mode === 'Button'
                            ?
                                <PrimaryTextButton
                                    onClick={open}
                                    fullWidth
                                    label={
                                        <LocalizedText
                                            code="LayoutConstructor.AddFirstElement"
                                            value="+ Voeg een eerste element toe"
                                        />
                                    }
                                />
                            :
                                props.mode === 'EditButton'
                                    ?
                                        <PrimaryTextButton
                                            onClick={open}
                                            label={
                                                <LocalizedText
                                                    code="LayoutConstructor.ChangeLayout"
                                                    value="Layout wijzigen"
                                                />
                                            }
                                        />
                                    :
                                        <div
                                            className={
                                                classNames(
                                                    styles.root,
                                                    isOpen && styles.open,
                                                    props.mode === 'Horizontal' && styles.horizontal,
                                                    props.mode === 'Vertical' && styles.vertical)}
                                            onClick={open}
                                            onMouseOver={startHovering}
                                            onMouseLeave={stopHovering}
                                            style={style}
                                        >
                                            <div
                                                className={styles.line}
                                            />
                                            <div
                                                className={styles.icon}
                                            >
                                                <Icon
                                                    icon="add"
                                                    size={11}
                                                />
                                            </div>
                                        </div>
            }
            <Popper
                reference={
                    <div
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                    />
                }
                popper={
                    <Card>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={0}
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <Menu>
                                    {
                                        types.map(
                                            type =>
                                                <Item
                                                    key={type.id}
                                                    name={type.name}
                                                    onClick={type.construct}
                                                />)
                                    }
                                </Menu>
                            </ViewGroupItem>
                        </ViewGroup>
                    </Card>
                }
                open={isOpen}
                onClose={close}
            />
        </>;
    };

export default observer(LayoutConstructor);
