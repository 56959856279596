import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import EntityConstructorPathSelector from '../../../../../../../Button/EntityConstructorButton/EntityConstructorPathSelector';
import CardInset from '../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import CardHeader from '../../../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import { QueryFileExportComputationEditorProps } from './QueryFileExportComputationEditor';
import { AddEntityPath } from '../../../../../../../Path/@Model/AddEntityPath';
import { runInAction } from 'mobx';
import ListQuery from '../../../../../../../../../../@Api/Automation/Query/ListQuery';
import Dialog from '../../../../../../../../../../@Future/Component/Generic/Dialog/Dialog';
import QueryEditor from '../Query/Query/QueryEditor';
import Query from '../../../../../../../../../../@Api/Automation/Query/Query';
import uuid from '../../../../../../../../../../@Util/Id/uuid';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface QueryComputationDialogProps extends QueryFileExportComputationEditorProps
{
    onClose: () => void;
}

const QueryFileExportComputationEditor: React.FC<QueryComputationDialogProps> =
    props =>
    {
        const onCreateQuery =
            useCallback(
                (path: AddEntityPath) =>
                    runInAction(
                        () =>
                            props.value.query =
                                new ListQuery(
                                    path.fullPath.path.entityType,
                                    Query.getResultParameter(
                                        path.fullPath.path.entityType,
                                        uuid()
                                    ),
                                    undefined,
                                    [],
                                    [],
                                    undefined,
                                    undefined
                                )
                    ),
                [
                    props.value,
                ]
            );

        if (props.value.query)
        {
            return <Dialog
                open
                width="lg"
                onClose={props.onClose}
            >
                <QueryEditor
                    query={props.value.query}
                    context={props.context}
                />
            </Dialog>;
        }
        else
        {
            return <Dialog
                open
                width="md"
                onClose={props.onClose}
            >
                <CardInset>
                    <CardHeader>
                        <LocalizedText
                            code="Query.SelectType"
                            value="Welk type wil je selecteren?"
                        />
                    </CardHeader>
                </CardInset>
                <EntityConstructorPathSelector
                    onSelect={onCreateQuery}
                    ignoreHidden
                />
            </Dialog>;
        }
    };

export default observer(QueryFileExportComputationEditor);
