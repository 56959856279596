import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PortalDataSourceSignature from '../../../Portal/DataSource/PortalDataSourceSignature';
import DynamicParameterAssignment from '../Dynamic/DynamicParameterAssignment';
import getPortalDataSourceSignatureById from '../../../Portal/DataSource/Api/getPortalDataSourceSignatureById';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import uuid from 'uuid';
import localizeText from '../../../Localization/localizeText';
import Predicate from './Predicate/Predicate';
import getPredicateFromDescriptor from '../../Api/getPredicateFromDescriptor';
import ParameterDictionary from '../../Parameter/ParameterDictionary';
import { getPartiallyComputedPredicate } from '../../Api/getPartiallyComputedPredicate';
import { Aggregate } from '../../../../@Component/Domain/DataObject/Model/Aggregate';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import PortalDataSourceAggregateQuery from '../../../Portal/DataSource/PortalDataSourceAggregateQuery';
import { aggregateQueryPortalDataSource } from '../../../Portal/DataSource/Api/aggregateQueryPortalDataSource';

export default class DataSourceAggregateQueryComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref dataSourceSignature: PortalDataSourceSignature;
    @observable.ref parameterAssignment: DynamicParameterAssignment;
    @observable.ref filter?: Predicate;
    @observable aggregate: Aggregate;
    @observable.ref expression: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(
        dataSourceSignature: PortalDataSourceSignature,
        parameterAssignment: DynamicParameterAssignment,
        filter: Predicate | undefined,
        aggregate: Aggregate,
        expression: Computation<any, any>
    )
    {
        super();

        this.dataSourceSignature = dataSourceSignature;
        this.parameterAssignment = parameterAssignment;
        this.filter = filter;
        this.aggregate = aggregate;
        this.expression = expression;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        if (this.aggregate === Aggregate.Count)
        {
            return new PrimitiveValueType(
                DataObject.getTypeById('Number')
            );
        }
        else
        {
            return this.expression.getType();
        }
    }

    isAsync(): boolean
    {
        return true;
    }

    async apply(context: FunctionContext)
    {
        const parameterAssignment = await this.parameterAssignment.apply(context);
        const substitutedFilter =
            this.filter
                ? await this.getSubstitutedFilter(this.filter, context)
                : undefined;

        return await aggregateQueryPortalDataSource(
            new PortalDataSourceAggregateQuery(
                uuid(),
                this.dataSourceSignature,
                parameterAssignment,
                substitutedFilter,
                this.aggregate,
                this.expression
            )
        );
    }

    async getSubstitutedFilter(
        filter: Predicate,
        context: FunctionContext
    )
    {
        return getPartiallyComputedPredicate(
            filter,
            context,
            this.dataSourceSignature.resultParameters
        );
    }

    getName(): string
    {
        return localizeText('Computation.DataSourceAggregateQuery', 'Databron aggregatie query...');
    }

    validate(): Validation[]
    {
        if (!this.dataSourceSignature)
        {
            return [
                new Validation('Error', 'Geen databron geselecteerd')
            ];
        }

        return [
            ...this.parameterAssignment.validate(),
            ...(this.filter ? this.filter.validate() : []),
            ...this.expression.validate(),
        ];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'DataSourceAggregateQuery';
        descriptor.dataSourceId = this.dataSourceSignature.id;
        descriptor.parameterAssignment = this.parameterAssignment.toDescriptor();
        descriptor.filter = this.filter?.toDescriptor();
        descriptor.aggregate = Aggregate[this.aggregate];
        descriptor.expression = this.expression.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.parameterAssignment.getDependencies(),
            ...(this.filter ? this.filter.getDependencies() : []),
            ...this.expression.getDependencies()
                .filter(
                    dependency =>
                        !this.dataSourceSignature.resultParameters.hasParameter(dependency.parameter)
                ),
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const dataSourceSignature = await getPortalDataSourceSignatureById(descriptor.dataSourceId);
        const parameterAssignment =
            await DynamicParameterAssignment.fromDescriptor(
                descriptor.parameterAssignment,
                dataSourceSignature.parameters,
                dependencyContext
            );
        const resultParametersDependencyContext =
            new AutomationDependencyContext(
                ParameterDictionary.union(
                    dataSourceSignature.resultParameters,
                    dependencyContext.parameterDictionary
                )
            );
        const filter =
            descriptor.filter
                ?
                    await getPredicateFromDescriptor(
                        descriptor.filter,
                        resultParametersDependencyContext
                    )
                :
                    undefined;
        const aggregate = Aggregate[descriptor.aggregate as string];
        const expression =
            await getComputationFromDescriptor(
                descriptor.expression,
                resultParametersDependencyContext
            );

        return new DataSourceAggregateQueryComputation(
            dataSourceSignature,
            parameterAssignment,
            filter,
            aggregate,
            expression
        );
    }

    // ----------------------- Private logic ------------------------
}
