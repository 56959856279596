import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { PayInvoiceDescriptor } from '../../Model/Implementation/PayInvoiceDescriptor';

export class PayInvoicePublicController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
        this.apiClient = apiClient;
    }
    requestPayLinkDescriptor(organizationId: string, invoiceId: string, token: string) : Promise<PayInvoiceDescriptor>
    {
        return new Promise<PayInvoiceDescriptor>(
            (resolve, reject) =>
            {
                this.apiClient
                    .request(
                        new ApiRequest<any>(
                            '/public/entity/bespoke/invoice/pay',
                            Method.Post,
                            {
                                organization_id: organizationId,
                                invoice_id: invoiceId,
                                token: token
                            }
                        )
                    )
                    .then(
                        result =>
                            resolve(
                                fromJson(
                                    result,
                                    PayInvoiceDescriptor
                                )
                            )
                    )
                    .catch(
                        reason =>
                            reject(reason)
                    );
            }
        );
    }
}