import React from 'react';
import { OrganizationEnvironmentStore } from './OrganizationEnvironmentStore';
import { DatabaseStatusChecker } from './DatabaseStatusChecker/DatabaseStatusChecker';
import { InitializedOrganizationEnvironment } from './InitializedOrganizationEnvironment';

export interface OrganizationEnvironmentProps
{
    store: OrganizationEnvironmentStore;
}

export const OrganizationEnvironment: React.FC<OrganizationEnvironmentProps> =
    ({
         store,
     }) =>
    {
        return <DatabaseStatusChecker>
            <InitializedOrganizationEnvironment
                store={store}
            />
        </DatabaseStatusChecker>;
    };
