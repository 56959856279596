import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../Entity/Type/EntityTypeStore';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { replaceAll } from '../../../../@Util/String/replaceAll';

export function findAssistantEntityTypeOrThrow(code: string): EntityType
{
    const entityType = findAssistantEntityType(code);

    if (!entityType)
    {
        throw new Error(`Could not find entity type: ${code}`);
    }

    return entityType;
}

export function findAssistantEntityType(code: string): EntityType | undefined
{
    const entityTypeStore = loadModuleDirectly(EntityTypeStore);

    return entityTypeStore.getTypeByCode(code)
        ?? entityTypeStore.types
        .find(
            type =>
                replaceAll(
                    type.code,
                    '\\.',
                    ''
                ) === code
        );
}
