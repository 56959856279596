import { type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';
import { EntityType } from './EntityType';

@type('StaticEntityType')
export class StaticEntityType extends EntityType
{
    // ------------------- Persistent Properties --------------------

    @observable sqlJoinPaths: any[];
    @observable objectPath: string;
    @observable valuePath: string;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
