import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import React from 'react';
import { observer } from 'mobx-react';
import { openEntity } from '../../../../../@Util/openEntity';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import useTypes from '../../../../../Type/Api/useTypes';
import FieldView from '../../../../../Field/View/FieldView';
import useRelatedEntity from '../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';

export interface AttachmentVersionsTableRowProps
{
    previousVersion: Entity;
}

export const AttachmentVersionsRowTable: React.FC<AttachmentVersionsTableRowProps> =
    observer(
        ({
             previousVersion
         }) =>
        {
            const types= useTypes();

            const creator =
                useRelatedEntity(
                    previousVersion,
                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.CreatedEntities,
                    true
                );

            return <TableRow
                key={previousVersion.id}
                hover
                onClick={() => {openEntity(previousVersion).then()}}
                style = {{
                    cursor: 'pointer'
                }}
            >
                <TableCell>
                    {previousVersion.getObjectValueByField(types.Attachment.Field.Version)}
                </TableCell>
                <TableCell>
                    <FieldView
                        entity={previousVersion}
                        field={types.Entity.Field.CreationDate}
                    />
                </TableCell>
                <TableCell>
                    {
                        creator
                            ? <FieldView
                                entity={previousVersion}
                                field={types.Relationship.Person.Contact.Employee.RelationshipDefinition.CreatedEntities}
                                parent
                              />
                            : <LocalizedText
                                code="Generic.Automation"
                                value="Automation"
                                lowerCase
                              />
                    }
                </TableCell>
            </TableRow>
        }

    )