import React from 'react';
import PrimaryButton from '../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import useDialog from '../../../@Service/Navigation/Page/Hooks/useDialog';
import DefinitionBuilder from './Definition/DefinitionBuilder';
import { EntityField } from '../../../@Api/Model/Implementation/EntityField';
import { EntityType } from '../../../@Api/Model/Implementation/EntityType';
import LocalizedText from '../Localization/LocalizedText/LocalizedText';

export interface NumberSequenceConfigurationButtonProps
{
    entityType: EntityType;
    entityField: EntityField;
}

const NumberSequenceConfigurationButton: React.FC<NumberSequenceConfigurationButtonProps> =
    props =>
    {
        const [ showDialog ] = useDialog(
            close =>
                <DefinitionBuilder
                    entityType={props.entityType}
                    entityField={props.entityField}
                    close={close}
                />,
            [
                props
            ]);

        return <PrimaryButton
            label={
                <LocalizedText
                    code="Configuration.AutoNumbering"
                    value="Autonummering"
                />
            }
            onClick={showDialog}
        />;
    };

export default NumberSequenceConfigurationButton;
