import { reference } from '../../../@Util/Serialization/Serialization';
import { EntityNode } from './EntityNode';
import { EntityField } from './EntityField';
import { observable } from 'mobx';
import { ComparisonConstraintNode } from './ComparisonConstraintNode';
import { Entity } from './Entity';
import { EntityEvent } from './EntityEvent';
import { EntityValueMutation } from './EntityValueMutation';
import isEqual from '../../../@Util/IsEqual/isEqual';
import { Comparator } from '../../../@Component/Domain/DataObject/Model/Comparator';

export class EntityComparisonConstraintNode extends ComparisonConstraintNode
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'EntityNode') @observable.ref passiveEntityNode: EntityNode;
    @reference(undefined, 'EntityField') @observable.ref passiveEntityField: EntityField;
    @observable.ref passiveEntityFieldRepresentation: any;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(activeEntityNode: EntityNode,
                activeEntityField: EntityField,
                activeEntityFieldRepresentation: any,
                comparator: Comparator,
                passiveEntityNode: EntityNode,
                passiveEntityField: EntityField,
                passiveEntityFieldRepresentation: any)
    {
        super(activeEntityNode, activeEntityField, activeEntityFieldRepresentation, comparator);

        this.passiveEntityNode = passiveEntityNode;
        this.passiveEntityField = passiveEntityField;
        this.passiveEntityFieldRepresentation = passiveEntityFieldRepresentation;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getDependencies(): EntityNode[]
    {
        return [
            ...super.getDependencies(),
            this.passiveEntityNode,
        ];
    }

    hashCode(): string
    {
        return `${super.hashCode()},${this.passiveEntityNode && this.passiveEntityNode.alias},${this.passiveEntityField && this.passiveEntityField.id}`;
    }

    equals(node: EntityComparisonConstraintNode): boolean
    {
        return super.equals(node)
            && (this.passiveEntityNode && this.passiveEntityNode.alias) === (node.passiveEntityNode && node.passiveEntityNode.alias)
            && this.passiveEntityField === node.passiveEntityField
            && isEqual(this.passiveEntityFieldRepresentation, node.passiveEntityFieldRepresentation);
    }

    matches(entity: Entity): boolean
    {
        const entities = this.activeEntityNode.entityPath().traverseEntity(entity, null);

        return this.passiveEntityNode.entityPath().traverseEntity(entity, null)
            .every(
                comparisonEntities =>
                    entities.includes(comparisonEntities));
    }

    isAffectedBy(event: EntityEvent): boolean
    {
        return super.isAffectedBy(event)
            || (event instanceof EntityValueMutation && event.entityField === this.passiveEntityField);
    }

    descriptor()
    {
        return Object.assign(
            super.descriptor(),
            {
                type: 'EntityComparison',
                passiveEntityNodeAlias: this.passiveEntityNode.alias,
                passiveEntityFieldId: this.passiveEntityField.isStaticField() ? undefined : this.passiveEntityField.id,
                passiveEntityFieldCode: this.passiveEntityField.isStaticField() ? this.passiveEntityField.code : undefined
            });
    }

    // ----------------------- Private logic ------------------------

}
