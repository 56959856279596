import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import ConditionalInComputation from './ConditionalInComputation';
import EmptyValueType from '../../Value/Type/EmptyValueType';
import Validation from '../../Validation/Validation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';

export default class ConditionalComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.shallow conditionals: ConditionalInComputation[];
    @observable.ref otherwise: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(conditionals: ConditionalInComputation[],
                otherwise: Computation<any, any>)
    {
        super();

        this.conditionals = conditionals;
        this.otherwise = otherwise;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isAsync(): boolean
    {
        return this.conditionals.some(
            conditional =>
                conditional.isAsync()
        ) || (this.otherwise?.isAsync() ?? false);
    }

    async apply(context: FunctionContext)
    {
        for (const conditional of this.conditionals)
        {
            if (await conditional.predicate.evaluate(context))
            {
                return safelyApplyFunction(conditional.value, context);
            }
        }

        return safelyApplyFunction(this.otherwise, context);
    }

    synchronousApply(context: FunctionContext)
    {
        for (const conditional of this.conditionals)
        {
            if (conditional.predicate.synchronouslyEvaluate(context))
            {
                return safelySynchronousApplyFunction(conditional.value, context);
            }
        }

        return safelySynchronousApplyFunction(this.otherwise, context);
    }

    getName(): string
    {
        return 'Als ..., dan ... of anders ...';
    }

    getType(): ValueType<any>
    {
        if (this.conditionals.length === 0)
        {
            return EmptyValueType.instance;
        }
        else
        {
            if (this.conditionals[0].value)
            {
                return this.conditionals[0].value.getType();
            }
            else
            {
                return EmptyValueType.instance;
            }
        }
    }

    validate(): Validation[]
    {
        return [
            ...this.conditionals
                .map(
                    conditional =>
                        conditional.validate())
                .reduce((a, b) => a.concat(b), []),
            ...(this.otherwise ? this.otherwise.validate() : [])
        ];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Conditional';
        descriptor.conditionals =
            this.conditionals.map(
                conditional =>
                    conditional.toDescriptor());
        descriptor.otherwise = this.otherwise?.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.conditionals
                .map(
                    conditional =>
                        conditional.getDependencies())
                .reduce((a, b) => a.concat(b), []),
            ...this.otherwise
                ?
                    this.otherwise.getDependencies()
                :
                    []
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new ConditionalComputation(
            await Promise.all(
                descriptor.conditionals.map(
                    conditional =>
                        ConditionalInComputation.fromDescriptor(
                            conditional,
                            dependencyContext))),
            descriptor.otherwise
                ?
                    await getComputationFromDescriptor(
                        descriptor.otherwise,
                        dependencyContext)
                :
                    undefined);
    }

    // ----------------------- Private logic ------------------------
}
