import List from './Specification/List';
import Column from './Column/Column';
import { observable } from 'mobx';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';

export default class Specification
{
    // ------------------------- Properties -------------------------

    @observable.ref list?: List;
    @observable.ref column?: Column;

    // ------------------------ Constructor -------------------------

    constructor(list?: List,
                column?: Column)
    {
        this.list = list;
        this.column = column;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isValid(): boolean
    {
        return (this.list ? this.list.isValid() : true)
            && (this.column ? this.column.isValid() : true);
    }

    static async fromDescriptor(
        descriptor: any,
        parameters: ParameterDictionary
    )
    {
        return new Specification(
            descriptor.list ? await List.fromDescriptor(descriptor.list, parameters) : undefined,
            descriptor.column ? await Column.fromDescriptor(descriptor.column, parameters) : undefined);
    }

    toDescriptor(includeColumnFilters: boolean)
    {
        return {
            list: this.list ? this.list.toDescriptor(includeColumnFilters) : undefined,
            column: this.column ?  this.column.toDescriptor() : undefined
        };
    }

    // ----------------------- Private logic ------------------------
}
