import { type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';

@type('ApiStatus')
export class ApiStatus
{
    // ------------------- Persistent Properties --------------------

    @observable version: string;
    @observable buildNumber: string;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
