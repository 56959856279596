import { observable } from 'mobx';
import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { ComponentClass } from 'react';

export class ToolbarElementStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable id: number | string;
    @observable layout: ComponentClass<any>;
    @observable quillEditor: any;
    @observable collapsible: boolean;

    // ------------------------ Constructor -------------------------

    constructor(id: number | string,
                layout: ComponentClass<any>,
                collapsible: boolean = false)
    {
        super();

        this.id = id;
        this.layout = layout;
        this.collapsible = collapsible;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
