import useSetting from '../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../Setting/SettingStore';
import { useMemo } from 'react';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import getConnectorActivationByCode from '../../../../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';
import useIsConnectorActivationActivated from '../../../../Marketplace/Api/useIsConnectorActivationActivated';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import { SMTPServerConfiguration } from '../../../../Marketplace/Connector/Type/SMTPServer/model/SMTPServerConfiguration';
import useTypes from '../../../../Entity/Type/Api/useTypes';

export default function useAllowedDomainsForSMTP()
{
    const types = useTypes();

    const [ smtpServerConnectorActivation] =
        useAsyncResult(
            () => getConnectorActivationByCode('SMTPServer'),
            []
        );
    const smtpServerConnectorActive = useIsConnectorActivationActivated(smtpServerConnectorActivation);
    const smtpConfiguration =
        useEntityValue<SMTPServerConfiguration>(
            smtpServerConnectorActivation,
            types.ConnectorActivation.Field.Configuration
        );

    return useMemo(
        () =>
        {
            const domains = [];
            if (smtpServerConnectorActive)
            {
                domains.push(
                    ...smtpConfiguration.domains
                        .filter(domain => domain.isValidated)
                        .map(domain => domain.domain)
                );
            }
            return domains;
        },
        [
            smtpServerConnectorActive,
            smtpConfiguration
        ]
    );
}
