import React, { useCallback } from 'react';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../Type/Api/useTypes';
import MenuButton from '../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import { primaryColor } from '../../../../../@Resource/Theme/Theme';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { observer } from 'mobx-react-lite';
import EntityTreeBrowser from '../../TreeBrowser/EntityTreeBrowser';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import styles from './EmailTemplateSelectorButton.module.scss';
import EntityTreeBrowserView from '../../TreeBrowser/View/EntityTreeBrowserView';
import { EntityExpansionBuilder } from '../../Selection/Builder/EntityExpansionBuilder';
import { EntityPath } from '../../Path/@Model/EntityPath';

export interface EmailTemplateSelectorButtonProps
{
    templateType?: EntityType;
    onSelect: (template: Entity) => Promise<any>;
}

const EmailTemplateSelectorButton: React.FC<EmailTemplateSelectorButtonProps> =
    props =>
    {
        const { onSelect } = props;
        const types = useTypes();
        const templateType = props.templateType || types.Template.Email.Type;

        const clickHandler =
            useCallback(
                async (template: Entity) =>
                {
                    await new EntityExpansionBuilder(
                        template.entityType,
                        [
                            template
                        ],
                        [
                            EntityPath.fromEntity(template)
                                .joinTo(
                                    types.Template.Email.RelationshipDefinition.DefaultEmailSender,
                                    false)
                        ]).expand();

                    return onSelect(template);
                },
                [
                    onSelect
                ]);

        const isInactive =
            useCallback(
                (entity: Entity) =>
                    entity.getObjectValueByField(types.Template.Field.IsInactive),
                [
                    types
                ]
            );

        return <MenuButton
            label={types.Template.Type.getName(true)}
            color={primaryColor}
        >
            <div
                className={styles.browserContainer}
            >
                {
                    templateType === types.Template.Email.General.Type
                        ?
                            <CardInset>
                                <EntityTreeBrowser
                                    categoryType={types.TemplateCategory.Email.Type}
                                    childCategoriesRelationshipDefinition={types.TemplateCategory.RelationshipDefinition.ChildCategories}
                                    recordsRelationshipDefinition={types.TemplateCategory.RelationshipDefinition.Templates}
                                    categoryNameField={types.TemplateCategory.Field.Name}
                                    recordType={templateType}
                                    recordNameField={types.Template.Field.LocalizedName}
                                    onOpen={clickHandler}
                                    isInactive={isInactive}
                                />
                            </CardInset>
                        :
                            <EntityTreeBrowserView
                                categoryType={types.TemplateCategory.Email.Type}
                                childCategoriesRelationshipDefinition={types.TemplateCategory.RelationshipDefinition.ChildCategories}
                                recordsRelationshipDefinition={types.TemplateCategory.RelationshipDefinition.Templates}
                                categoryNameField={types.TemplateCategory.Field.Name}
                                recordType={templateType}
                                recordNameField={types.Template.Field.LocalizedName}
                                onOpen={clickHandler}
                                isInactive={isInactive}
                            />
                }
            </div>
        </MenuButton>;
    };

export default observer(EmailTemplateSelectorButton);
