import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import useSpotlerCampaignSendCount from '../Hooks/useSpotlerCampaignSendCount';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import CampaignPercentageChart from '../../Campaign/Charts/CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';

export interface SpotlerCampaignChartSelectionProps
{
    size: number;
    spotlerCampaign: Entity;
    selectedStep?: Entity
    caption?: boolean;
}

export const SpotlerCampaignChartSelection: React.FC<SpotlerCampaignChartSelectionProps> =
    observer(
        ({
            size,
            spotlerCampaign,
            selectedStep
         }) =>
        {
            const totalSendCount =
                useSpotlerCampaignSendCount(
                    spotlerCampaign,
                    selectedStep
                );

            const allSet =
                useAllSet([
                    totalSendCount,
                ]);

            const formattedSelection =
                useMemo(
                    () =>
                    {
                        return `${totalSendCount}/${totalSendCount}`;
                    },
                    [
                        totalSendCount
                    ]
                )

            return <div>
                {
                    allSet &&
                    <CampaignPercentageChart
                        value={totalSendCount / totalSendCount * 100}
                        size={size}
                        label={localizeText('Campaign.Chart.Sent', 'Verzonden') }
                        selection={formattedSelection}
                    />
                }
            </div>;
        }
    );
