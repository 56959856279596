import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import './Viewer.module.scss';
import styles from './Viewer.module.scss';
import EntityLink from '../../../../Link/EntityLink';
import DateView from '../../../../../../../@Future/Component/Generic/DateView/DateView';
import useTypes from '../../../../Type/Api/useTypes';
import RichText from '../../../../../../Generic/RichText/RichText';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface ViewerProps
{
    entity: Entity;
    creator: Entity;
    white?: boolean;
    hideCreationDate?: boolean;
    dateAppendix?: React.ReactNode;
    displayTime?: boolean;
    displayDate?: boolean;
    hideCreator?: boolean;
    hideCard?: boolean;
}

const Viewer: React.FC<ViewerProps> =
    props =>
    {
        const types = useTypes();
        const value =
            useComputed(
                () =>
                    props.entity.getDataObjectValueByField(types.Note.Field.Content).toString(),
                [
                    types
                ]);

        const creationDate =
            useEntityValue<Date>(
                props.entity,
                types.Entity.Field.CreationDate);

        return <div
            className={styles.root}
        >
            <div
                className={styles.header}
            >
                {
                    !props.hideCreator &&
                        <div
                            className={styles.creator}
                        >
                            {
                                props.creator
                                    ?
                                        <EntityLink
                                            entity={props.creator}
                                        />
                                    :
                                        <LocalizedText
                                            code="ExternalUser"
                                            value="Externe gebruiker"
                                        />
                            }
                        </div>
                }
                {
                    creationDate && !props.hideCreationDate &&
                        <div
                            className={styles.noteDate}
                        >
                            <DateView
                                date={creationDate}
                                displayTime={props.displayTime}
                                displayDate={props.displayDate}
                            >
                                {props.dateAppendix}
                            </DateView>
                        </div>
                }
            </div>
            <div
                className={styles.content}
            >
                <span
                    className={'note'}
                >
                    <RichText
                        value={value}
                    />
                </span>
            </div>
        </div>;
    };

export default observer(Viewer);
