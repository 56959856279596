import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Dimension, { DimensionUnit } from '../../../../../@Api/Layout/Style/Dimension';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import NumberEditor from '../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';
import StaticSelectbox, { StaticOption } from '../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import localizeText from '../../../../../@Api/Localization/localizeText';

export interface DimensionEditorProps
{
    value?: Dimension;
    onChange: (dimension?: Dimension) => void;
}

const DimensionEditor: React.FC<DimensionEditorProps> =
    props =>
    {
        const onChangeValue =
            useCallback(
                (number?: number) =>
                    props.onChange(
                        number === undefined
                            ?
                                undefined
                            :
                            new Dimension(
                                number,
                                props.value?.unit || 'Pixels')),
                [
                    props.onChange,
                    props.value
                ]);

        const onChangeUnit =
            useCallback(
                (unit: DimensionUnit) =>
                    props.onChange(
                        new Dimension(
                            props.value?.value ?? 100,
                            unit)),
                [
                    props.onChange,
                    props.value
                ]);

        const unitOptions =
            useMemo<StaticOption<DimensionUnit>[]>(
                () => [
                    {
                        id: 'Pixels',
                        label: localizeText('Generic.Pixels', 'Pixels'),
                        value: 'Pixels'
                    },
                    {
                        id: 'Percentage',
                        label: localizeText('Generic.Percentage', 'Percentage'),
                        value: 'Percentage'
                    }
                ],
                []);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem
                ratio={1}
            >
                <NumberEditor
                    value={props.value?.value}
                    onChange={onChangeValue}
                    min={0}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <StaticSelectbox
                    options={unitOptions}
                    value={props.value?.unit}
                    onChange={onChangeUnit}
                    disableUnderline={false}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(DimensionEditor);
