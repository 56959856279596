import React from 'react';
import '@wordpress/editor';
import '@wordpress/format-library';
import '@wordpress/components/build-style/style.css';
import '@wordpress/block-editor/build-style/style.css';
import '@wordpress/block-library/build-style/style.css';
import '@wordpress/block-library/build-style/editor.css';
import '@wordpress/block-library/build-style/theme.css';
import '@wordpress/format-library/build-style/style.css';
import { observer } from 'mobx-react-lite';

export interface StyledBlockProps
{
    block: any;
}

const StyledBlock: React.FC<StyledBlockProps> =
    props =>
    {
        const {
            backgroundColor,
            borderColor,
            fontColor,
            borderWidth,
            borderRadius,
            padding,
            margin
        } = props.block.attributes as any;

        const containerStyles = {
            padding: margin
        };

        const innerContainerStyles = {
            backgroundColor: backgroundColor,
            borderWidth: borderWidth ? borderWidth : undefined,
            borderColor: borderWidth && borderColor ? borderColor : undefined,
            borderStyle: borderWidth && borderColor ? 'solid' : undefined,
            borderRadius: borderRadius,
            color: fontColor,
            padding: padding
        };

        return <div
            style={containerStyles}
        >
            <div
                style={innerContainerStyles}
            >
                {props.children}
            </div>
        </div>;
    };

export default observer(StyledBlock);
