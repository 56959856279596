import ParameterDictionary from '../../Automation/Parameter/ParameterDictionary';
import Parameter from '../../Automation/Parameter/Parameter';
import EntityValueType from '../../Automation/Value/Type/EntityValueType';
import getTypes from '../../../@Component/Domain/Entity/Type/Api/getTypes';
import localizeText from '../../Localization/localizeText';
import { CustomWidgetParams } from './CustomWidgetParams';

export default function getCustomWidgetParameters()
{
    return new ParameterDictionary([
        new Parameter(
            CustomWidgetParams.User,
            new EntityValueType(getTypes().Relationship.Person.Contact.Employee.Type),
            true,
            localizeText('Generic.User', 'Gebruiker')
        ),
    ]);
}
