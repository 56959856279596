import React, { useEffect, useMemo } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import Fields from '../../../Fields/Fields';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import { default as RoleManager } from '../../../../Configuration/Page/Roles/Manager/RoleManager';
import { RoleEditor } from '../../../../Role/Editor/RoleEditor';
import { default as RoleModel, RoleType } from '../../../../Role/Model/Role';
import useTypes from '../../../Type/Api/useTypes';
import { computed } from 'mobx';
import useDebouncedCallback from '../../../../../../@Util/Debounce/useDebouncedCallback';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import { RoleLegend } from '../../../../Role/Editor/Legend/RoleLegend';
import { DetailedRole } from '../../../../Configuration/Page/Employees/Employee/RightsViewer/RightsViewer';
import getRoleDetails from './Api/getRoleDetails';

export interface RoleProps
{
    entity: Entity;
}

export const Role: React.FC<RoleProps> =
    observer(
        ({
             entity,
         }) =>
        {
            const types = useTypes();

            const [ roles, isLoading ] =
                useAsyncResult<DetailedRole[]>(
                    () =>
                        getRoleDetails(entity, 'Role.GetRoleDetails'),
                    [
                        entity,
                    ]
                );

            const rolesByType =
                useMemo(
                    () =>
                        new Map<RoleType, RoleModel>(
                            roles
                                ?.filter(r => r.isBase)
                                ?.map(
                                    baseRole =>
                                        [ baseRole.name as RoleType, baseRole.role]
                                )
                        ),
                    [
                        roles
                    ]
                );

            const role =
                useMemo(
                    () =>
                        roles
                            ?.find(r => !r.isBase)
                            ?.role,
                    [
                        roles
                    ]
                );

            const saveRole =
                useDebouncedCallback(
                    (descriptor: any) =>
                        new CommitBuilder()
                            .setObjectValueInEntity(
                                entity,
                                types.Role.Field.Specification,
                                descriptor
                            )
                            .commit(),
                    [
                        entity,
                        types
                    ],
                    500
                );

            useEffect(
                () =>
                {
                    if (!isLoading && role)
                    {
                        return computed(
                            () =>
                                role.toDescriptor()
                        )
                            .observe(
                                change =>
                                {
                                    saveRole(change.newValue);
                                }
                            );
                    }
                },
                [
                    isLoading,
                    role,
                    saveRole
                ]
            );

            return <BaseLayout>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Card>
                                    <CardInset
                                        bottom={false}
                                    >
                                        <ViewGroup
                                            orientation="horizontal"
                                            spacing={15}
                                        >
                                            <ViewGroupItem
                                                ratio={1}
                                            >
                                                <TypeAndName
                                                    entity={entity}
                                                />
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <MenuButton
                                                    entity={entity}
                                                />
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </CardInset>
                                    <Fields
                                        entity={entity}
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <RoleManager
                            role={entity}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <RoleLegend />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        {
                            isLoading &&
                            <Centered
                                horizontal
                            >
                                <Loader />
                            </Centered>
                        }
                        {
                            !isLoading &&
                            <RoleEditor
                                role={role}
                                rolesByType={rolesByType}
                            />
                        }
                    </Grid>
                </Grid>
            </BaseLayout>;
        }
    );