import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import useCampaignTotalEmailCount from '../Hooks/useCampaignTotalEmailCount';
import CampaignPercentageChart from './CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import useCampaignUndeliveredCount from '../Hooks/useCampaignUndeliveredCount';

export interface CampaignChartUndeliveredProps
{
    campaign: Entity;
    size: number;
}

export const CampaignChartUndelivered: React.FC<CampaignChartUndeliveredProps> =
    observer(
        ({
            campaign,
            size,
        }) =>
            {
                const totalEmailCount = useCampaignTotalEmailCount(campaign);
                const undeliveredCount = useCampaignUndeliveredCount(campaign);


                const allSet =
                    useAllSet([
                        totalEmailCount,
                        undeliveredCount
                    ]);

                const selection =
                    useMemo(
                        () =>
                        {
                            return `${undeliveredCount} / ${totalEmailCount}`;
                        },
                        [
                            undeliveredCount,
                            totalEmailCount
                        ]
                    )

                return <div>
                    {
                        allSet &&
                        <CampaignPercentageChart
                            value={undeliveredCount / totalEmailCount * 100}
                            size={size}
                            label={localizeText('Campaign.Chart.Undelivered', 'Onbezorgd')}
                            color={'#8f0012'}
                            selection={selection}
                        />
                    }
                </div>;
            }
    );
