import { useMemo } from 'react';
import { useTwoFactorAuthenticationConfiguration } from './useTwoFactorAuthenticationConfiguration';
import { User } from '../../../../@Api/Model/Implementation/User';


export function useHasToEnableTwoFactorAuthenticationForPortalUser(portalUser: User | undefined): [ boolean, boolean ]
{
    const [ twoFactorAuthenticationConfiguration, isLoading] =
        useTwoFactorAuthenticationConfiguration(
            portalUser
        );

    return useMemo(
        () =>
            [
                twoFactorAuthenticationConfiguration?.isRequired
                && !twoFactorAuthenticationConfiguration?.isEnabled,
                isLoading
            ],
        [
            twoFactorAuthenticationConfiguration,
            isLoading
        ]
    );

}