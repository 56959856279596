import isHiddenType from '../../../../@Api/Metadata/EntityType/isHiddenType';
import Role from '../Model/Role';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';

export function isRoleEntityTypeVisible(role: Role, entityType: EntityType)
{
    return role.rightByType.has(entityType)
        || !isHiddenType(entityType);
}

export function hasRoleEntityTypeVisibleChildren(role: Role, entityType: EntityType): boolean
{
   return entityType
            .getAllTypes(false)
            .findIndex(
                child =>
                    isRoleEntityTypeVisible(role, child)
            ) !== -1;
}

