import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { observable } from 'mobx';
import { injectWithQualifier, ModuleManager } from '../../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../Organization/Feedback/FeedbackStore';
import { PortalEnvironmentContext } from './Context/PortalEnvironmentContext';

export class PortalEnvironmentStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('FeedbackStore') feedbackStore: FeedbackStore;

    // ------------------------- Properties -------------------------

    @observable context: PortalEnvironmentContext;

    // ------------------------ Constructor -------------------------

    constructor(moduleManager: ModuleManager)
    {
        super();

        this.context = new PortalEnvironmentContext(moduleManager);
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return this.context.initialize();
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
