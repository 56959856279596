import React, {useMemo} from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import DetailsLayout from '../../../../Viewer/Shared/Details/DetailsLayout/DetailsLayout';
import Planner from "../../../../Planner/Planner";
import {EntityPath} from "../../../../Path/@Model/EntityPath";
import useTypes from "../../../../Type/Api/useTypes";
import ViewGroupItem from "../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem";
import EntityRecurrencePatternEditor from "../../../../Viewer/Shared/RecurrencePattern/EntityRecurrencePatternEditor";
import Input from "../../../../../../../@Future/Component/Generic/Input/Input/Input";
import {
    AppointmentAttendeesEditor
} from "../../../../Bespoke/Activity/Appointment/AppointmentAttendeesEditor/AppointmentAttendeesEditor";
import ViewGroup from "../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup";
import { TeamsMeetingPlanner } from '../../../../Constructor/Type/Activity/Appointment/Teams/TeamsMeetingPlanner';

export interface AppointmentProps
{
    entity: Entity;
}

const Appointment: React.FC<AppointmentProps> =
    props =>
    {
        const commitContext = useNewCommitContext();
        const types = useTypes();
        const employeePath =
            useMemo(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Activity.Appointment.RelationshipDefinition.Owner,
                            false
                        ),
                [
                    props.entity,
                    types
                ]
            );

        return <CardInset
            horizontal={false}
            bottom={false}
        >
            <DetailsLayout
                entity={props.entity}
                isInEditMode={false}
                onCloseEditMode={() => {}}
                commitContext={commitContext}
                hidden={
                    fieldPath =>
                        fieldPath.field === types.Activity.Appointment.Field.StartDate
                        || fieldPath.field === types.Activity.Appointment.Field.EndDate
                        || fieldPath.field === types.Activity.Appointment.Field.IsRecurring
                        || fieldPath.field === types.Activity.Appointment.Field.RecurrencePattern
                }
                appendix={
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                    >
                        <ViewGroupItem>
                            <Planner
                                entity={props.entity}
                                startDateField={types.Activity.Appointment.Field.StartDate}
                                endDateField={types.Activity.Appointment.Field.EndDate}
                                employeePath={employeePath}
                                autoCommit
                                commitContext={commitContext}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <TeamsMeetingPlanner
                                appointment={props.entity}
                                commitContext={commitContext}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <EntityRecurrencePatternEditor
                                {...props}
                                entity={props.entity}
                                startDateField={types.Activity.Appointment.Field.StartDate}
                                isRecurringField={types.Activity.Appointment.Field.IsRecurring}
                                recurrencePatternField={types.Activity.Appointment.Field.RecurrencePattern}
                                commitContext={commitContext}
                                autoCommit
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Input
                                label={types.Activity.Appointment.RelationshipDefinition.Attendees.getName(false)}
                                labelPosition="left"
                            >
                                <AppointmentAttendeesEditor
                                    appointment={props.entity}
                                    commitContext={commitContext}
                                    autoCommit
                                />
                            </Input>
                        </ViewGroupItem>
                    </ViewGroup>
                }
            />
        </CardInset>;
    };

export default observer(Appointment);
