import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityAvatarEditorStore } from '../../Avatar/Editor/EntityAvatarEditorStore';
import DialogTitle from '../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import DialogContent from '../../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import SaveButton from '../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import Dialog from '../../../../../@Future/Component/Generic/Dialog/Dialog';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { EntityAvatarEditor } from '../../Avatar/Editor/EntityAvatarEditor';
import { ImageFinder } from '../../../../Generic/ImageFinder/ImageFinder';
import { ImageFinderStore } from '../../../../Generic/ImageFinder/ImageFinderStore';
import RightAlignedButtonGroup from '../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import CancelButton from '../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import useIsMobile from '../../../../../@Util/Responsiveness/useIsMobile';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import { useNewCommitContext } from '../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import commitEntity from '../../../../../@Api/Entity/Commit/commitEntity';

export interface EntityPictureEditorProps
{
    entity: Entity;
    onSave?: () => void;
    onClose?: () => void;
}

const EntityPictureEditor: React.FC<EntityPictureEditorProps> =
    props =>
    {
        const commitContext = useNewCommitContext();
        const avatarStore =
            useMemo(
                () =>
                    new EntityAvatarEditorStore(
                        commitContext,
                        props.entity,
                        200,
                        () => {},
                        props.onClose
                    ),
                [
                    props.entity,
                    props.onSave,
                    props.onClose
                ]);
        const isMobile = useIsMobile();
        const imageFinderStore =
            useMemo(
                () =>
                    new ImageFinderStore(
                        props.entity.name,
                        image =>
                            avatarStore.setSelectedFile(image, true),
                        isMobile ? 2 : 3,
                        isMobile ? 100 : 150),
                [
                    props.entity,
                    avatarStore,
                    isMobile
                ]);
        const save =
            useCallback(
                async () =>
                {
                    avatarStore.saveAvatar();

                    await commitEntity(
                        props.entity,
                        {
                            context: commitContext,
                        }
                    );

                    return props.onClose();
                },
                [
                    commitContext,
                    props.entity,
                    props.onClose
                ]);

        return <Dialog
            onClose={props.onClose}
            open
        >
            <DialogTitle>
                <LocalizedText
                    code="EntityPictureEditorDialog.Title"
                    value="Afbeelding aanpassen"
                />
            </DialogTitle>
            <DialogContent>
                <ViewGroup
                    orientation="vertical"
                    spacing={15}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation={isMobile ? 'vertical' : 'horizontal'}
                            spacing={15}
                        >
                            <ViewGroupItem>
                                <EntityAvatarEditor
                                    store={avatarStore}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem
                                ratio={isMobile ? undefined : 1}
                            >
                                <ImageFinder
                                    store={imageFinderStore}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <RightAlignedButtonGroup>
                            <CancelButton
                                onClick={props.onClose}
                            />
                            <SaveButton
                                onClick={save}
                                disabled={!avatarStore.canSave}
                            />
                        </RightAlignedButtonGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </DialogContent>
        </Dialog>;
    };

export default observer(EntityPictureEditor);
