import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import { Field } from '../../Model/Field';
import { default as EntityInput } from '../../../../../../Entity/Input/Input';
import useTypes from '../../../../../../Entity/Type/Api/useTypes';
import useTransactionalEntity from '../../../../../../../../@Api/Entity/Bespoke/useTransactionalEntity';
import { SelectboxProps } from '../../../../../../Entity/Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../../../../../Entity/Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../../../../Entity/Path/@Model/EntityPath';

export interface FieldGroupEditorProps
{
    entityType: EntityType;
    field: Field;
}

const FieldGroupEditor: React.FC<FieldGroupEditorProps> =
    props =>
    {
        const types = useTypes();
        const transactionalEntity = useTransactionalEntity(props.field.entity);
        const fieldGroupRelationshipDefinition =
            useMemo(
                () =>
                    props.field.fieldPath?.field
                        ?
                            types.EntityFieldGroup.RelationshipDefinition.Fields
                        :
                            types.EntityFieldGroup.RelationshipDefinition.Relationships,
                [
                    props.field,
                    types
                ]);

        const relationshipSelectboxProps =
            useMemo<Partial<SelectboxProps>>(
                () => ({
                    disableConstruction: true,
                    selections:
                        props.field.fieldPath?.path.entityType
                            ?
                                [
                                    new EntitySelectionBuilder(types.EntityFieldGroup.Type)
                                        .where(
                                            cb =>
                                                cb.or(
                                                    ob =>
                                                        props.field.fieldPath.basePath.entityType.getInheritedTypes()
                                                            .forEach(
                                                                inheritedType =>
                                                                    ob.relatedToEntity(
                                                                        EntityPath.root(types.EntityFieldGroup.Type)
                                                                            .joinTo(
                                                                                types.EntityType.RelationshipDefinition.FieldGroups,
                                                                                true),
                                                                        inheritedType.entity))))
                                        .build()
                                ]
                            :
                                []
                }),
                [
                    types,
                    props.field
                ]);

        if (transactionalEntity)
        {
            return <EntityInput
                labelPosition="none"
                entity={transactionalEntity}
                field={fieldGroupRelationshipDefinition}
                parent
                disabled={!props.field.isEditable}
                relationshipSelectboxProps={relationshipSelectboxProps}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(FieldGroupEditor);
