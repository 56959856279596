import React, { useCallback, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import StaticSelectbox from '../../../../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { MathematicalOperator } from '../../../../../../../../DataObject/Model/MathematicalOperator';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import MathematicalComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/MathematicalComputation';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { loadModuleDirectly } from '../../../../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../../../../../DataObject/DataObjectStore';
import { DataObjectOverloadType } from '../../../../../../../../DataObject/Model/DataObjectOverloadType';
import MenuButton from '../../../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import useSwitch from '../../../../../../../../../../@Util/Switch/useSwitch';
import Menu from '../../../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import ComputationBox from '../../Box/ComputationBox';

export interface MathematicalComputationEditorProps extends ComputationEditorProps<ValueType<any>, MathematicalComputation>
{

}

const MathematicalComputationEditor: React.FC<MathematicalComputationEditorProps> =
    props =>
    {
        const operatorOptions =
            useMemo(
                () => [
                    {
                        id: 'Add',
                        value: MathematicalOperator.Add,
                        label: '+'
                    },
                    {
                        id: 'Subtract',
                        value: MathematicalOperator.Subtract,
                        label: '-'
                    },
                    {
                        id: 'Multiply',
                        value: MathematicalOperator.Multiply,
                        label: 'x'
                    },
                    {
                        id: 'Divide',
                        value: MathematicalOperator.Divide,
                        label: '/'
                    }
                ],
                []);

        const setOperator =
            useCallback(
                (operator: MathematicalOperator) =>
                    runInAction(
                        () =>
                            props.value.operator = operator),
                [
                    props.value
                ]);

        const setLhs =
            useCallback(
                (lhs: Computation<any, any>) =>
                    runInAction(
                        () =>
                        {
                            props.value.lhs = lhs;
                        }),
                [
                    props.value
                ]);

        const lhsType =
            useComputed(
                () =>
                {
                    if (props.value.lhs
                        && props.value.lhs.isValid())
                    {
                        return props.value.lhs.getType();
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.value
                ]);

        const availableRhsTypes =
            useComputed<ValueType<any>[]>(
                () =>
                    [
                        ...lhsType ? [ lhsType ] : [],
                        ...lhsType instanceof PrimitiveValueType
                            ?
                                loadModuleDirectly(DataObjectStore)
                                    .types
                                    .filter(
                                        type =>
                                            type.operatorOverloads()
                                                .some(
                                                    overload =>
                                                        overload.operator === props.value.operator
                                                        && (overload.type === DataObjectOverloadType.Symmetric || overload.type === DataObjectOverloadType.Lhs)
                                                        && overload.isCompatible(lhsType.type)))
                                    .map(
                                        type =>
                                            new PrimitiveValueType(type))
                            :
                                []
                    ],
                [
                    lhsType
                ]);

        const [ isRhsTypeMenuOpen, openRhsTypeMenu, closeRhsTypeMenu ] = useSwitch(false);
        const [ rhsType, _setRhsType ] =
            useState(
                () =>
                    props.value.rhs?.getType()
                    || (availableRhsTypes.length > 0 ? availableRhsTypes[0] : undefined));

        const setRhsType =
            useCallback(
                (rhsType: ValueType<any>) =>
                    runInAction(
                        () =>
                        {
                            props.value.rhs = undefined;
                            _setRhsType(rhsType);
                            closeRhsTypeMenu();
                        }),
                [
                    props.value,
                    _setRhsType,
                    closeRhsTypeMenu
                ]);

        const setRhs =
            useCallback(
                (rhs: Computation<any, any>) =>
                    runInAction(
                        () =>
                        {
                            props.value.rhs = rhs
                        }),
                [
                    props.value
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
            wrap
        >
            <ViewGroupItem>
                <ComputationBox>
                    <ComputationEditor
                        value={props.value.lhs}
                        onChange={setLhs}
                        context={props.context}
                    />
                </ComputationBox>
            </ViewGroupItem>
            <ViewGroupItem>
                <StaticSelectbox
                    options={operatorOptions}
                    value={props.value.operator}
                    onChange={setOperator}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <ComputationBox>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={0}
                        alignment="center"
                    >
                        <ViewGroupItem>
                            <ComputationEditor
                                type={rhsType}
                                value={props.value.rhs}
                                onChange={setRhs}
                                context={props.context}
                            />
                        </ViewGroupItem>
                        {
                            availableRhsTypes.length > 1 &&
                                <ViewGroupItem>
                                    <MenuButton
                                        open={isRhsTypeMenuOpen}
                                        onClick={openRhsTypeMenu}
                                        onClose={closeRhsTypeMenu}
                                    >
                                        <Menu>
                                            {
                                                availableRhsTypes.map(
                                                    availableRhsType =>
                                                        <Item
                                                            key={availableRhsType.id()}
                                                            name={availableRhsType.getName()}
                                                            onClick={setRhsType}
                                                            value={availableRhsType}
                                                            active={rhsType?.id() === availableRhsType.id()}
                                                        />
                                                )
                                            }
                                        </Menu>
                                    </MenuButton>
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                </ComputationBox>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(MathematicalComputationEditor);
