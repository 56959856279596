
export interface OpenWindowOptions {
    url?: string;
    target?: string;
    features?: string
}

// Open a browser window after completion of async task
// Task returned promise should be the full url of the page to open, or undefined in which case the options.url will be opened (if provided)
export function asyncOpenWindow(
    task: () => Promise<string>,
    options?: OpenWindowOptions
)
{
    // Create window reference outside async method to satisfy Safari
    // https://stackoverflow.com/a/39387533
    const windowReference =
        options
            ? window.open(undefined, options.target ?? '_blank', options.features)
            : window.open();
    task()
        .then(
            result =>
            {
                if (result)
                {
                    windowReference.location.assign(result);
                }
                else if (options && options.url)
                {
                    windowReference.location.assign(options.url)
                }
                else
                {
                    windowReference.close();
                }
            }
    );
}
