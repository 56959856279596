import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import Dependency from '../../Parameter/Dependency';
import CollectionType from '../../Value/Type/CollectionType';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import EmptyValue from '../../Value/EmptyValue';
import DataSourceValueType from '../../Value/Type/DataSourceValueType';
import EmptyValueType from '../../Value/Type/EmptyValueType';
import EntityValueType from '../../Value/Type/EntityValueType';

export default class EntityFromDataSourceValueComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref value: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(value: Computation<any, any>)
    {
        super();

        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        if (this.isCollection())
        {
            return new CollectionType(
                this.getSingularType());
        }
        else
        {
            return this.getSingularType();
        }
    }

    isAsync(): boolean
    {
        return true;
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        return EmptyValue.instance;

        // const values = await this.getValues(context);
        // return this.getValueFromValues(values);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        return EmptyValue.instance;
    }

    // private getValueFromValues(values: Value<any, any>[])
    // {
    //     if (this.isCollection())
    //     {
    //         return new CollectionValue(
    //             values,
    //             this.getType());
    //     }
    //     else
    //     {
    //         return values.find(() => true) || EmptyValue.instance;
    //     }
    // }
    //
    // private async getValues(context: FunctionContext): Promise<Value<any, any>[]>
    // {
    //     const value = await safelyApplyFunction(this.entity, context);
    //
    //     return this.getValuesFromValue(value);
    // }
    //
    // private synchronouslyGetValues(context: FunctionContext): Value<any, any>[]
    // {
    //     const value = safelySynchronousApplyFunction(this.entity, context);
    //
    //     return this.getValuesFromValue(value);
    // }
    //
    // private getValuesFromValue(value: Value<any, any>): Value<any, any>[]
    // {
    //     if (value instanceof EntityValue)
    //     {
    //         const entity = value.value;
    //         const relatedEntities = this.fieldPath.path.traverseEntity(entity);
    //
    //         if (this.fieldPath.isField)
    //         {
    //             return relatedEntities
    //                 .map(
    //                     relatedEntity =>
    //                         relatedEntity.getDataObjectValueByField(this.fieldPath.field))
    //                 .filter(
    //                     primitive =>
    //                         primitive != null && !primitive.isEmpty)
    //                 .map(
    //                     primitive =>
    //                         new PrimitiveValue(primitive));
    //         }
    //         else
    //         {
    //             return relatedEntities
    //                 .map(
    //                     relatedEntity =>
    //                         new EntityValue(relatedEntity));
    //         }
    //     }
    //     else
    //     {
    //         return [];
    //     }
    // }

    getName(): string
    {
        return this.value.getName();
    }

    validate(): Validation[]
    {
        return this.value.validate();
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'EntityFromDataSourceValue';
        descriptor.value = this.value.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.value.getDependencies();
    }

    private isCollection()
    {
        return this.value.getType() instanceof CollectionType;
    }

    private getSingularType(): ValueType<any>
    {
        const valueType = this.probeDataSourceValueType();

        if (valueType)
        {
            if (valueType.dataSource)
            {
                return new EntityValueType(valueType.dataSource.entityType);
            }
            else
            {
                return EmptyValueType.instance;
            }
        }
        else
        {
            return EmptyValueType.instance;
        }
    }

    private probeDataSourceValueType(): DataSourceValueType
    {
        const valueType = this.value.getType();

        if (valueType instanceof CollectionType)
        {
            if (valueType.type instanceof DataSourceValueType)
            {
                return valueType.type;
            }
        }
        else if (valueType instanceof DataSourceValueType)
        {
            return valueType;
        }

        return undefined;
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new EntityFromDataSourceValueComputation(
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
