import React from 'react';
import { observer } from 'mobx-react';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import useTypes from '../../../../../Type/Api/useTypes';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { Alert } from '@material-ui/lab';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Typography } from '@material-ui/core';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import { default as EntityInput } from '../../../../../../Entity/Input/Input';

export interface AutomationDeactivatedTagProps
{
    activation?: Entity;
}

export const AutomationDeactivatedTag: React.FC<AutomationDeactivatedTagProps> = observer(
    ({
        activation
     }) =>
    {
        const types = useTypes();

        const isDisabled =
            useEntityValue(
                activation,
                types.AutomationActivation.Field.IsDeactivated
            );

        const disabledReason =
            useEntityValue(
                activation,
                types.AutomationActivation.Field.DeactivationReason
            );

        if (isDisabled)
        {
            return <Alert
                severity={
                    disabledReason
                        ? "error"
                        : "info"
                }
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={10}
                >
                    <ViewGroupItem>
                        <Typography
                            variant="body1"
                        >
                            <LocalizedText
                                code="Automation.DeactivationMessage"
                                value="Deze automation is gedeactiveerd."
                            />
                        </Typography>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <EntityInput
                            entity={activation}
                            field={types.AutomationActivation.Field.DeactivationDate}
                            disabled
                        />
                    </ViewGroupItem>
                    {
                        disabledReason &&
                        <ViewGroupItem>
                            <EntityInput
                                entity={activation}
                                field={types.AutomationActivation.Field.DeactivationReason}
                                disabled
                            />
                        </ViewGroupItem>
                    }
                </ViewGroup>
            </Alert>
        }
        else
        {
            return null;
        }
    }
)
