import { observable } from 'mobx';
import { reference, type } from '../../../../../@Util/Serialization/Serialization';

@type('TypeMappingSummary')
export default class TypeMappingSummary
{
    // ------------------------- Properties -------------------------

    @reference(undefined, 'TypeMappingSummary', true) @observable.shallow summaryByTypeId: Map<string, TypeMappingSummary>;
    @observable newCount: number;
    @observable existentCount: number;
    @observable errorCount: number;
    @observable.shallow newIndices: number[];
    @observable.shallow existentIndices: number[];
    @observable.shallow errorIndices: number[];
    @observable.shallow errorSourceInstanceIds: string[];

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
