import { EntityContext } from '../../../../@Model/EntityContext';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { RootEntityContextParameterId } from './EntityContextParameterConstants';
import ParameterAssignment from '../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import EntityValue from '../../../../../../../@Api/Automation/Value/EntityValue';

export function getParameterAssignmentFromEntityContext(
    context: EntityContext,
    parameters: ParameterDictionary
): ParameterAssignment
{
    const parameterAssignment = new ParameterAssignment();

    if (context.entity)
    {
        parameterAssignment.setValue(
            parameters.getParameterById(RootEntityContextParameterId),
            new EntityValue(context.entity)
        );
    }

    context.contextByParameter
        .forEach(
            (subContext, parameterId) =>
            {
                if (subContext.entity)
                {
                    parameterAssignment.setValue(
                        parameters.getParameterById(parameterId),
                        new EntityValue(subContext.entity)
                    );
                }
            }
        );

    return parameterAssignment;
}
