import React from 'react';
import styles from './Link.module.scss';
import Tooltip from '../Tooltip/Tooltip';
import { classNames } from '../../../Util/Class/classNames';

export interface LinkClasses
{
    root?: string;
}

export interface LinkProps
{
    onClick: (event: any) => void;
    highlighted?: boolean;
    disabled?: boolean;
    strikethrough?: boolean;
    tooltip?: React.ReactNode;
    classes?: LinkClasses;
}

const Link: React.FC<LinkProps> =
    props =>
    {
        return <Tooltip
            title={props.tooltip}
        >
            <span
                className={
                    classNames(
                        styles.link,
                        props.highlighted && styles.highlighted,
                        props.disabled && styles.disabled,
                        props.strikethrough && styles.strikethrough,
                        props.classes && props.classes.root)
                }
                onClick={props.onClick}
            >
                {props.children}
            </span>
        </Tooltip>;
    };

export default Link;
