import matchAll from '../../../../@Util/String/matchAll';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { fetchApiFile } from '../../../../@Service/ApiClient/Hooks/fetchApiFile';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { ApiClient } from '../../../../@Service/ApiClient/ApiClient';
import getTypes from '../Type/Api/getTypes';
import { blobToBase64 } from '../../../../@Util/File/blobToBase64String';
import { replaceAll } from '../../../../@Util/String/replaceAll';

export async function getHtmlBodyWithEmbeddedFiles(
    apiClient: ApiClient,
    email: Entity,
    attachmentByFileName: Map<string, Entity>
): Promise<string>
{
    const types = getTypes();
    const entityTypeStore = loadModuleDirectly(EntityTypeStore);

    let htmlBody = email.getObjectValueByField<string>(types.Activity.Email.Field.HTML);

    if (htmlBody)
    {
        // find occurrences of src="cid:xxx" ...other elements>
        // group[1] "cid:xxx" is text to replace with attachment content in base64
        // group[2] "xxx" filename specified with cid tag, used to find attachment entity
        // group[3] other elements after "cid" in <img> tag
        //
        // Example: <img width="356" height="221" id="Afbeelding_x0020_1" src="cid:image001.png@01DA4EAB.D46E6630" style="width:3.7083in; height:2.302in">
        // Note that "xxx" can have a suffix with "@...." which should be part of group[1] but not be part of group[2]

        const cidRegExp = /src="(cid:([^"@]+)[^"]*)"([^>]*>)/gm;
        const cidResults = Array.from(matchAll(htmlBody, cidRegExp));

        // Map<fileName, {stringToReplace, fileUrl}>
        const replacementByFilename = new Map<string, any>();

        cidResults.forEach(
            cidMatch =>
            {
                const attachment = attachmentByFileName.get(cidMatch[2]);
                if (attachment)
                {
                    const fileUrl =
                        entityTypeStore.getFileUrl(
                            attachment.getValueByField(types.Attachment.Field.File)
                        );

                    replacementByFilename.set(
                        cidMatch[2],
                        {
                            stringToReplace: cidMatch[1],
                            fileUrl: fileUrl
                        }
                    );
                }
                else
                {
                    // filename from cid not found in attachments, try to find alt="filename" in other elements of <img> tag (cidMatch[3])
                    // group[1] = filename
                    const altRegEx = /alt="([^"]+)"/gm;
                    const altResult = Array.from(matchAll(cidMatch[3], altRegEx));

                    if (altResult.length > 0)
                    {
                        const fileName = altResult[0][1];
                        const att = attachmentByFileName.get(fileName);
                        if (att)
                        {
                            const altFileUrl =
                                entityTypeStore.getFileUrl(
                                    att.getValueByField(types.Attachment.Field.File)
                                )

                            if (altFileUrl)
                            {
                                replacementByFilename.set(
                                    fileName,
                                    {
                                        stringToReplace: cidMatch[1],
                                        fileUrl: altFileUrl
                                    }
                                );
                            }
                        }
                    }
                }
            });

        const files =
            await getFiles(
                apiClient,
                Array.from(replacementByFilename.values())
                    .map(
                        replacement => replacement.fileUrl
                    )
            );

        const promises = [];
        replacementByFilename
            .forEach(
                (replacement, fileName) =>
                {
                    const file =
                        files
                            .find(
                                file =>
                                    file.fileName === replaceAll(fileName, ' ', '_')
                            );

                    if (file)
                    {
                        promises.push(
                            blobToBase64(file.blob)
                                .then(
                                    (result) =>
                                    {
                                         htmlBody =
                                            replaceAll(
                                                htmlBody,
                                                replacement.stringToReplace,
                                                result as string
                                            )
                                    }
                                )
                        )
                    }
                }
            );

        await Promise.all(promises);

        return Promise.resolve(htmlBody);
    }
    else
    {
        return Promise.resolve('');
    }
}

async function getFiles(
    apiClient: ApiClient,
    fileUrls: string[]
)
{
    return await Promise.all(
        fileUrls.map(
            (fileUrl) =>
                fetchApiFile(
                    apiClient,
                    fileUrl
                )
        ));
}
