import * as React from 'react';
import { Theme, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { EntitySystemFieldsStore } from './EntitySystemFieldsStore';
import List from '../../../Generic/List/V2/List';
import Fields from '../Fields/Fields';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardHeader from '../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import Input from '../Input/Input';
import Menu from '../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../@Future/Component/Generic/Menu/Item/Item';
import performAction from '../../../../@Api/Entity/performAction';
import Card from '../../../../@Future/Component/Generic/Card/Card';
import EntityCacheInformation from './CacheInformation/EntityCacheInformation';
import InputGroup from '../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';

const styles =
    (theme: Theme) =>
        ({

        });

@observer
class EntitySystemFields extends BaseComponent<EntitySystemFieldsStore>
{
    renderLoader(store: EntitySystemFieldsStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: EntitySystemFieldsStore)
    {
        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <ViewGroupItem>
                <CardInset
                    vertical={false}
                >
                    <CardHeader>
                        Systeemvelden
                    </CardHeader>
                </CardInset>
            </ViewGroupItem>
            <ViewGroupItem>
                <CardInset
                    vertical={false}
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                    >
                        {
                            store.paths.map(
                                path =>
                                    <ViewGroupItem
                                        key={path.id}
                                    >
                                        <Input
                                            entity={store.entity}
                                            field={path}
                                            labelPosition="left"
                                            commitContext={store.commitContext}
                                        />
                                    </ViewGroupItem>)
                        }
                    </ViewGroup>
                </CardInset>
            </ViewGroupItem>
            <ViewGroupItem>
                <CardInset
                    vertical={false}
                >
                    <CardHeader>
                        Alle velden
                    </CardHeader>
                </CardInset>
            </ViewGroupItem>
            <ViewGroupItem>
                <Fields
                    entity={store.entity}
                    ignoreHidden
                    commitContext={store.commitContext}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <CardInset
                    vertical={false}
                >
                    <CardHeader>
                        Overig
                    </CardHeader>
                </CardInset>
            </ViewGroupItem>
            {/*<ViewGroupItem>*/}
            {/*    <ExpansionGroup>*/}
            {/*        <ViewGroup*/}
            {/*            orientation="vertical"*/}
            {/*            spacing={0}*/}
            {/*            glue={() => <Divider />}*/}
            {/*        >*/}
            {/*            {*/}
            {/*                store.relatedPaths.map(*/}
            {/*                    relatedPath =>*/}
            {/*                        <ViewGroupItem*/}
            {/*                            key={relatedPath.id}*/}
            {/*                        >*/}
            {/*                            <ExpansionPanel*/}
            {/*                                id={relatedPath.id}*/}
            {/*                                summary={*/}
            {/*                                    <Header*/}
            {/*                                        title={relatedPath.getName(undefined, false)}*/}
            {/*                                        inset*/}
            {/*                                    />*/}
            {/*                                }*/}
            {/*                                expansion={*/}
            {/*                                    <CardInset>*/}
            {/*                                        <RelatedList*/}
            {/*                                            entity={store.entity}*/}
            {/*                                            path={relatedPath}*/}
            {/*                                        />*/}
            {/*                                    </CardInset>*/}
            {/*                                }*/}
            {/*                            />*/}
            {/*                        </ViewGroupItem>)*/}
            {/*            }*/}
            {/*        </ViewGroup>*/}
            {/*    </ExpansionGroup>*/}
            {/*</ViewGroupItem>*/}
            <ViewGroupItem>
                <CardInset>
                    <InputGroup>
                        {
                            store.genericRelatedListStorePaths.map(
                                path =>
                                    <Input
                                        key={path.id}
                                        entity={store.entity}
                                        field={path.lastJoinNode.relationshipDefinition}
                                        parent={path.lastJoinNode.isParent}
                                        labelPosition="top"
                                        commitContext={store.commitContext}
                                    />
                            )
                        }
                    </InputGroup>
                </CardInset>
            </ViewGroupItem>
            <ViewGroupItem>
                <List store={store.fields2ListStore} />
            </ViewGroupItem>
            <ViewGroupItem>
                <CardInset
                    vertical={false}
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                    >
                        <ViewGroupItem>
                            <CardHeader>
                                Cache
                            </CardHeader>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <EntityCacheInformation
                                entity={store.entity}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </CardInset>
            </ViewGroupItem>
            {
                store.actions.map(
                    action =>
                        <ViewGroupItem
                            key={action.id}
                        >
                            <Card
                                square
                            >
                                <Menu>
                                    <Item
                                        name={action.name}
                                        onClick={
                                            () =>
                                                performAction(
                                                    store.entity,
                                                    action)
                                        }
                                    />
                                </Menu>
                            </Card>
                        </ViewGroupItem>)
            }
        </ViewGroup>;
    }
}

export default withStyles(styles)(EntitySystemFields as any);
