import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { classNames } from '../../../../../@Future/Util/Class/classNames';
import styles from './RightSwitch.module.scss';
import { Permission } from '../../Model/Role';

export interface RightSwitchProps
{
    permission: Permission;
    inheritedPermission: Permission;
    onChange: (permission: Permission) => void;
    canRightBeInherited: boolean;
    isDisabled: boolean;
    isInherited?: boolean;
    isReadOnly: boolean;
}

export const RightSwitch: React.FC<RightSwitchProps> =
    observer(
        ({
             permission,
             inheritedPermission,
             onChange,
             canRightBeInherited,
             isDisabled,
             isInherited,
             isReadOnly,
         }) =>
        {
            const togglePermission =
                useCallback(
                    () =>
                    {
                        if (!isDisabled && !isReadOnly && permission !== 'ConditionallyGranted')
                        {
                            if (permission === 'Granted')
                            {
                                onChange('Denied');
                            }
                            else if (permission === 'Denied' && canRightBeInherited)
                            {
                                if (isInherited)
                                {
                                    onChange('Granted');
                                }
                                else
                                {
                                    onChange('Inherited');
                                }
                            }
                            else
                            {
                                onChange('Granted');
                            }
                        }
                    },
                    [
                        permission,
                        canRightBeInherited,
                        isDisabled,
                        isReadOnly,
                        onChange
                    ]);

            return <div
                className={
                    classNames(
                        styles.root,
                        (!isDisabled
                            && (permission === 'Granted'
                                || (permission === 'Inherited' && inheritedPermission === 'Granted'))
                        ) && styles.checkedTrue,
                        (!isDisabled
                            && permission === 'ConditionallyGranted'
                        ) && styles.checkedConditionallyTrue,
                        (!isDisabled
                            && (permission === 'Denied'
                                || (permission === 'Inherited' && inheritedPermission === 'Denied'))
                        ) && styles.checkedFalse,
                        isDisabled && styles.disabled,
                        (isReadOnly || permission === 'ConditionallyGranted') && styles.isReadOnly)}
                onClick={togglePermission}
            >
                {
                    !isDisabled && (permission === 'Inherited' || isInherited) &&
                    <div
                        className={classNames(styles.fill)}
                    />
                }
            </div>;
        }
    );
