import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { default as WidgetModel } from '../../Model/Widget';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useResults from '../../../Selection/Hooks/useResults';
import useTypes from '../../../Type/Api/useTypes';
import uuid from '../../../../../../@Util/Id/uuid';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ExpansionGroup from '../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import WidgetAdder from '../Widget/WidgetAdder';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import CustomWidget from '../../Model/Widget/Custom/CustomWidget';
import Custom from '../../Widget/Custom/Custom';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import CustomWidgetSpecification from '../../Model/Widget/Custom/Model/CustomWidgetSpecification';
import LayoutDependencyContext from '../../../../../../@Api/Layout/LayoutDependencyContext';
import getCustomWidgetParameters from '../../../../../../@Api/Dashboard/CustomWidget/getCustomWidgetParameters';

export interface TypeBrowserProps
{
    onAdd: (widget: WidgetModel) => void;
}

const CustomWidgetBrowser: React.FC<TypeBrowserProps> =
    ({
        onAdd,
     }) =>
    {
        const types = useTypes();

        const entities =
            useResults(
                types.CustomWidget.Type,
                (builder, rootPath) =>
                    builder
                        .orderBy(
                            rootPath.field(types.CustomWidget.Field.Name),
                            true
                        ),
                [
                    types,
                ]);
        const dependencyContext =
            useMemo(
                () =>
                    new LayoutDependencyContext(
                        getCustomWidgetParameters()
                    ),
                []
            );
        const [ initializedWidgets ] =
            useAsyncResult(
                () =>
                    Promise.all(
                        (entities ?? [])
                            .filter(
                                entity =>
                                    entity.hasValueForField(types.CustomWidget.Field.Specification)
                            )
                            .map(
                                async entity => ({
                                    entity,
                                    specification:
                                        await CustomWidgetSpecification.fromDescriptor(
                                            entity.getObjectValueByField(types.CustomWidget.Field.Specification),
                                            dependencyContext
                                        )
                                })
                            )
                    ),
                [
                    entities,
                    types,
                    dependencyContext,
                ]
            );

        const onDelete = useCallback(() => {}, []);
        const dividerGlue = useDividerGlue();

        return <ExpansionGroup>
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={dividerGlue}
            >
                {
                    initializedWidgets?.map(
                        ({ entity, specification }) =>
                            <ViewGroupItem
                                key={entity.uuid}
                            >
                                <WidgetAdder
                                    name={entity.name}
                                    onAdd={
                                        () =>
                                            onAdd(
                                                new CustomWidget(
                                                    uuid(),
                                                    entity.uuid,
                                                    dependencyContext.parameterDictionary,
                                                    specification
                                                )
                                            )
                                    }
                                >
                                    <Custom
                                        widget={
                                            new CustomWidget(
                                                uuid(),
                                                entity.uuid,
                                                dependencyContext.parameterDictionary,
                                                specification
                                            )
                                        }
                                        onAdd={() =>
                                            onAdd(
                                                new CustomWidget(
                                                    uuid(),
                                                    entity.uuid,
                                                    dependencyContext.parameterDictionary,
                                                    specification
                                                )
                                            )}
                                        onDelete={onDelete}
                                        whiteCard
                                    />
                                </WidgetAdder>
                            </ViewGroupItem>)
                }
                {
                    initializedWidgets && initializedWidgets.length === 0 &&
                        <ViewGroupItem>
                            <CardInset>
                                <Centered
                                    horizontal
                                >
                                    <LocalizedText
                                        code="Dashboard.NoCustomWidgets"
                                        value="Er zijn géén eigen widgets beschikbaar."
                                    />
                                </Centered>
                            </CardInset>
                        </ViewGroupItem>
                }
            </ViewGroup>
        </ExpansionGroup>;
    };

export default observer(CustomWidgetBrowser);
