import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { loadModuleDirectly } from '../../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../../Type/EntityTypeStore';

export default function doOpenAfterCreation(entity: Entity)
{
    const types = loadModuleDirectly(EntityTypeStore).bespoke.types;

    return entity.entityType.isA(types.Activity.Offer.Type)
    || entity.entityType.isA(types.Activity.Invoice.Type)
    || entity.entityType.isA(types.Activity.Document.Type);
}
