import { ToolbarElementStore } from '../ToolbarElementStore';
import { observable } from 'mobx';
import { ToolbarButton } from './ToolbarButton';
import { ButtonStore } from '../../../../Button/ButtonStore';

export class ToolbarButtonStore extends ToolbarElementStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable buttonStore: ButtonStore;

    // ------------------------ Constructor -------------------------

    constructor(id: string | number,
                buttonStore: ButtonStore,
                collapsible: boolean = false)
    {
        super(id, ToolbarButton);

        this.buttonStore = buttonStore;
        this.collapsible = collapsible
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
