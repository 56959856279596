import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../DataObject/Model/DataObject';
import Value from '../../../../../@Api/Automation/Value/Value';
import { AssistantDateTimeValue } from '../../Model/Value/AssistantDateTimeValue';

export function findAssistantDateTimeValue(
    value: AssistantDateTimeValue
): Value<any, any>
{
    return new PrimitiveValue(
        DataObject.constructFromTypeIdAndValue(
            'DateTime',
            new Date(value.value)
        )
    );
}
