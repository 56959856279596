import React, { useCallback, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import { runInAction } from 'mobx';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import ConditionalLayout from '../../../../../@Api/Layout/Type/ConditionalLayout';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import PredicateEditor from '../../../Entity/Viewer/Content/Automation/Editor/Predicate/PredicateEditor';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import TabBar from '../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import Layout from '../../../../../@Api/Layout/Layout';

export interface ConditionalLayoutEditorProps extends LayoutEditorProps<ConditionalLayout>
{

}

const ConditionalLayoutEditor: React.FC<ConditionalLayoutEditorProps> =
    props =>
    {
        const setPredicate =
            useCallback(
                (predicate: Predicate) =>
                    runInAction(
                        () =>
                            props.layout.predicate = predicate),
                [
                    props.layout
                ]);

        const context =
            useComputed(
                () =>
                    new FunctionContext(
                        props.parameterDictionary,
                        props.parameterAssignment,
                        props.commitContext
                    ),
                [
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        const setLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.layout = layout),
                [
                    props.layout
                ]);

        const [ tab, setTab ] = useState(props.layout.predicate ? 0 : 1);
        const dividerGlue = useDividerGlue();

        return <ViewGroup
            orientation="vertical"
            spacing={0}
            glue={dividerGlue}
        >
            <ViewGroupItem>
                <TabBar
                    value={tab}
                >
                    <Tab
                        value={0}
                        onClick={setTab}
                    >
                        <LocalizedText
                            code="Generic.Layout"
                            value="Layout"
                        />
                    </Tab>
                    <Tab
                        value={1}
                        onClick={setTab}
                    >
                        <LocalizedText
                            code="Generic.Manage"
                            value="Beheren"
                        />
                    </Tab>
                </TabBar>
            </ViewGroupItem>
            {
                tab === 0 &&
                    <ViewGroupItem>
                        <ChildLayoutEditor
                            {...props}
                            childLayout={props.layout.layout}
                            onChange={setLayout}
                        />
                    </ViewGroupItem>
            }
            {
                tab === 1 &&
                    <ViewGroupItem>
                        <CardInset>
                            <PredicateEditor
                                onChange={setPredicate}
                                context={context}
                                value={props.layout.predicate}
                            />
                        </CardInset>
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(ConditionalLayoutEditor);
