import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { useComputed } from 'mobx-react-lite';
import useTypes from '../../../../../Type/Api/useTypes';
import { EntityExpansionBuilder } from '../../../../../Selection/Builder/EntityExpansionBuilder';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import useAsyncResult, { AsyncResult } from '../../../../../../../../@Util/Async/useAsyncResult';

export default function usePreviousVersions(
    attachment: Entity,
    commitContext?: CommitContext
): AsyncResult<Entity[]>
{
    const types= useTypes();

    const getPreviousVersions =
        async (entity): Promise<Entity[]> =>
        {
            if (!entity)
            {
                return [];
            }
            else
            {
                await new EntityExpansionBuilder(
                    entity.entityType,
                    [ entity ],
                    [
                        EntityPath.fromEntity(entity)
                            .joinTo(
                                types.Attachment.RelationshipDefinition.AttachmentsBasedOnThis,
                                true
                            )
                    ])
                    .expand()

                const base = entity.getRelatedEntityByDefinition(
                    true,
                    types.Attachment.RelationshipDefinition.AttachmentsBasedOnThis,
                    commitContext
                );

                if (base) {
                    return getPreviousVersions(base)
                        .then(
                            previous =>
                            {
                                return [
                                    base, ...previous
                                ];
                            }
                        )
                }
                else
                {
                    return []
                }
            }
        }

    return useAsyncResult(
        () =>
            getPreviousVersions(attachment),
        [
            attachment,
            commitContext,
        ]
    );
}
