import { AssistantComparator } from '../Model/AssistantComparator';
import { Comparator } from '../../DataObject/Model/Comparator';

export function findAssistantComparator(comparator: AssistantComparator): Comparator | undefined
{
    const convertedComparator = Comparator[comparator];

    if (convertedComparator === undefined)
    {
        throw new Error(`Could not resolve comparator: ${comparator}`);
    }
    else
    {
        return convertedComparator;
    }
}
