import React from 'react';
import { BlockEditProps } from '@wordpress/blocks';
import { PanelBody, PanelRow, TextControl } from '@wordpress/components';
import localizeText from '../../../../../../../@Api/Localization/localizeText';


export interface ButtonOptionsPanelProps
{
    props: BlockEditProps<{}>;
    open?: boolean;
}

const ButtonOptionsPanel: React.FC<ButtonOptionsPanelProps> =
    props =>
    {
        const {
            label,
            fontSize,
        } = props.props.attributes as any;

        return <PanelBody
            title={
                localizeText(
                    'Gutenberg.ButtonOptions.ButtonOptions',
                    'Knop instellingen'
                )
            }
            initialOpen={!!props.open}
        >
            <PanelRow>
                <TextControl
                    name="label"
                    label={
                        localizeText(
                            'Gutenberg.ButtonOptions.Label',
                            'Label'
                        )
                    }
                    value={label}
                    onChange={label => props.props.setAttributes({ label: label }) }
                />
            </PanelRow>
            <PanelRow>
                <TextControl
                    name="fontSize"
                    label={
                        localizeText(
                            'Gutenberg.ButtonOptions.FontSize',
                            'Tekstgrootte'
                        )
                    }
                    value={fontSize}
                    onChange={fontSize => props.props.setAttributes({ fontSize: fontSize }) }
                />
            </PanelRow>
        </PanelBody>;
    };

export default ButtonOptionsPanel;
