import { observable } from 'mobx';
import { Computation } from '../../Computation';
import { OldPredicate } from '../../../../Predicate/Type/OldPredicate';

export class ConditionalComputationCondition
{
    // ------------------------- Properties -------------------------

    @observable predicate: OldPredicate;
    @observable computation: Computation;

    // ------------------------ Constructor -------------------------

    constructor(predicate: OldPredicate,
                computation: Computation)
    {
        this.predicate = predicate;
        this.computation = computation;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
