import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityTypeStore } from '../../../EntityTypeStore';
import { BespokeEntityType } from '../../../BespokeEntityType';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { CurrentUserStore } from '../../../../../User/CurrentUserStore';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export class BespokeActivityEvent extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Activity.Event')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    onConstruct(entity: Entity,
                commitContext?: CommitContext): void
    {
        super.onConstruct(entity, commitContext);

        if (!entity.hasRelationshipsByDefinition(
            false,
            this.entityTypeStore.bespoke.types.Activity.Event.RelationshipDefinition.Owner,
            commitContext))
        {
            updateRelationship(
                entity,
                false,
                this.entityTypeStore.bespoke.types.Activity.Event.RelationshipDefinition.Owner,
                loadModuleDirectly(CurrentUserStore).employeeEntity,
                commitContext
            );
        }
    }

    // ----------------------- Private logic ------------------------
}
