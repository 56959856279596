import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../../../Path/@Model/EntityPath';
import ProjectActivityList from '../ActivityList/ProjectActivityList';
import TimeSheet from '../../../TimeRegistration/TimeSheet/TimeSheet';
import ExternalCosts from '../ExternalCosts/ExternalCosts';
import ProductsInvoiceEditor from '../../../Product/ProductsInvoiceEditor/ProductsInvoiceEditor';
import InvoiceTable from '../../../Product/InvoiceTable/InvoiceTable';
import useCount from '../../../../../../Selection/Hooks/useCount';
import useAggregateResult from '../../../../../../Selection/Hooks/useAggregateResult';
import { Aggregate } from '../../../../../../../DataObject/Model/Aggregate';
import { HighGranularityCompactRepresentation } from '../../../../../../../DataObject/Util/Representation/HighGranularityCompactRepresentation';
import EmployeeAllocation from '../EmployeeAllocation/EmployeeAllocation';
import { LowGranularityCompactRepresentation } from '../../../../../../../DataObject/Util/Representation/LowGranularityCompactRepresentation';
import useSetting from '../../../../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../../../../Setting/SettingStore';
import { Setting } from '../../../../../../../../../@Api/Settings/Setting';
import RelatedEntityView from '../../../../../Shared/RelatedEntityView/RelatedEntityView';
import RightAlignedButtonGroup from '../../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import DeleteButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';
import promptEntityDeletion from '../../../../../../../../../@Api/Entity/promptEntityDeletion';
import getCustomPluralRelatedPaths from '../../../Api/getCustomPluralRelatedPaths';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import PageTabBar, { TabFamily } from '../../../../../Shared/Page/TabBar/PageTabBar';
import CardInset from '../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import useRelatedEntity from '../../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import Details from '../../../../../Shared/Details/Details';
import { CommitContext } from '../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import MileageSheet from '../../../MileageRegistration/MileageSheet/MileageSheet';
import EmployeePlanner from '../EmployeePlanner/EmployeePlanner';
import { useRoutingState } from '../../../../../../../../../@Service/Router/Model/Api/useRoutingState';
import { useUserLocalSettingCode } from '../../../../../../../Setting/Api/useUserLocalSettingCode';
import useLocalSetting from '../../../../../../../Setting/Api/useLocalSetting';
import useIsFeatureAllowed from '../../../../../../../Role/Api/useIsFeatureAllowed';
import {
    HideExternalCostInProjectFeature,
    HideMileageRegistrationInProjectFeature,
    HideEmployeePlannerInProjectFeature,
    HideTimeRegistrationInProjectFeature,
    HideToInvoiceInProjectFeature,
    HideInvoiceInProjectFeature,
    HideEmployeeAllocationInProjectFeature,
} from '../../../../../../../Role/Model/Features';
import useEntityValue from '../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { DataObjectRepresentation } from '../../../../../../../DataObject/Model/DataObjectRepresentation';
import TimeRegistrationDuration from '../../../TimeRegistration/TimeRegistrationDuration/TimeRegistrationDuration';
import MileageRegistrationDistance from '../../../MileageRegistration/MileageRegistrationDistance/MileageRegistrationDistance';
import RelatedEntityCount from '../../../../../Shared/RelatedEntityCount/RelatedEntityCount';
import useOpenedEntity from '../../../../../Context/OpenedEntity/useOpenedEntity';
import equalsEntity from '../../../../../../../../../@Api/Entity/Bespoke/equalsEntity';

export interface NavigationProps
{
    project: Entity;
    commitContext: CommitContext;
    milestone?: Entity;
}

const Navigation: React.FC<NavigationProps> =
    props =>
    {
        const types = useTypes();
        const pathToProducts =
            useComputed(
                () =>
                    EntityPath.fromEntity(props.project)
                        .joinTo(
                            types.Activity.RelationshipDefinition.ProductLines,
                            false),
                [
                    props.project,
                    types
                ]);
        const relationship =
            useRelatedEntity(
                props.project,
                types.Relationship.RelationshipDefinition.Activities,
                true);
        const currency =
            useEntityValue<string>(
                props.project,
                types.Activity.Field.Currency);
        const [ activityCount, setActivityCount ] = useState<number>();

        const totalExternalCostsResult =
            useAggregateResult(
                types.ExternalCost.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.Project.RelationshipDefinition.ExternalCosts,
                                        true
                                    ),
                                    props.project
                                )
                        )
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.ExternalCosts,
                                                true
                                            ),
                                            props.milestone
                                        )
                                )
                        )
                        .aggregateOn(
                            rootPath.field(types.ExternalCost.Field.Amount),
                            undefined,
                            Aggregate.Sum),
                [
                    types,
                    props.project,
                    props.milestone
                ]);
        const totalExternalCostsLabel =
            useComputed(
                () =>
                {
                    if (totalExternalCostsResult && totalExternalCostsResult.aggregates[0].value > 0)
                    {
                        return totalExternalCostsResult.aggregates[0].toString(LowGranularityCompactRepresentation);
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    totalExternalCostsResult
                ]);
        const totalToInvoiceResult =
            useAggregateResult(
                types.ProductLine.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.RelationshipDefinition.ProductLines,
                                        true),
                                    props.project))
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.ProductLine.Field.IsBilled),
                                    undefined,
                                    false))
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.ProductLines,
                                                true),
                                            props.milestone)))
                        .aggregateOn(
                            rootPath.field(
                                currency
                                    ? types.ProductLine.Field.TotalExcludingVatInCurrency
                                    : types.ProductLine.Field.TotalExcludingVat),
                            undefined,
                            Aggregate.Sum)
                        .aggregateOn(
                            rootPath.field(types.Entity.Field.Id),
                            undefined,
                            Aggregate.Count),
                [
                    types,
                    props.project,
                    props.milestone,
                    currency,
                ]);
        const totalToInvoiceLabel =
            useComputed(
                () =>
                {
                    if (totalToInvoiceResult && totalToInvoiceResult.aggregates[0].value > 0)
                    {
                        return totalToInvoiceResult.aggregates[0].toString(
                            new DataObjectRepresentation({
                                ...HighGranularityCompactRepresentation,
                                currency: currency
                            })
                        );
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    totalToInvoiceResult,
                    currency,
                ]);
        const numberOfInvoices =
            useCount(
                types.Activity.Invoice.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.RelationshipDefinition.LinkedActivities,
                                        true),
                                    props.project))
                        .if(
                            () =>
                                props.milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Milestone.RelationshipDefinition.Activities,
                                                true),
                                            props.milestone))),
                [
                    types,
                    props.project,
                    props.milestone
                ]);
        const [ isSalesOrderEnabled ] =
            useSetting<boolean>(
                SettingSource.Organization,
                Setting.Invoicing.IsSalesOrderEnabled);
        const customPluralRelatedPaths =
            useComputed(
                () =>
                    getCustomPluralRelatedPaths(props.project.entityType),
                [
                    props.project
                ]);
        const deleteMilestone =
            useCallback(
                () =>
                    promptEntityDeletion(props.milestone),
                [
                    props.milestone
                ]);
        const [ tab, setTab ] =
            useRoutingState(
                `Entity.${props.project.id}.TabId`,
                'Static:Activities'
            );
        const lastSelectedTabIdSettingCode =
            useUserLocalSettingCode(
                'ProjectOverview.PageTabBar.LastSelectedTabId'
            );
        const [ lastSelectedTabId, setLastSelectedTabId ] =
            useLocalSetting<string>(
                lastSelectedTabIdSettingCode,
                tab
            );

        const openedEntity = useOpenedEntity();

        useEffect(
            () =>
                {
                    if (!equalsEntity(props.project, openedEntity) && openedEntity !== undefined)
                    {
                        setLastSelectedTabId('Static:Activities');
                    }
                },
            [
                props.project,
                openedEntity,
                setLastSelectedTabId,
            ]
        );

        const onChange =
            useCallback(
                (tabId: string) =>
                {
                    setLastSelectedTabId(tabId);
                    setTab(tabId);
                },
                [
                    setLastSelectedTabId,
                    setTab
                ]
            );
        const hideTimeRegistrationInProject = useIsFeatureAllowed(HideTimeRegistrationInProjectFeature);
        const hideMileageRegistrationInProject = useIsFeatureAllowed(HideMileageRegistrationInProjectFeature);
        const hideExternalCostInProject = useIsFeatureAllowed(HideExternalCostInProjectFeature);
        const hideToInvoiceInProject = useIsFeatureAllowed(HideToInvoiceInProjectFeature);
        const hideInvoiceInProject = useIsFeatureAllowed(HideInvoiceInProjectFeature);
        const hideEmployeePlannerInProject = useIsFeatureAllowed(HideEmployeePlannerInProjectFeature);
        const hideEmployeeAllocationInProject = useIsFeatureAllowed(HideEmployeeAllocationInProjectFeature);

        const tabFamilies =
            useMemo<TabFamily[]>(
                () => [
                    {
                        id: 'Static',
                        tabs: [
                            {
                                id: 'Details',
                                name:
                                    <LocalizedText
                                        code="Generic.Details"
                                        value="Details"
                                    />,
                                content:
                                    () =>
                                        <Details
                                            key={(props.milestone || props.project).uuid}
                                            entity={props.milestone || props.project}
                                            appendix={
                                                props.milestone &&
                                                <CardInset>
                                                    <RightAlignedButtonGroup>
                                                        <DeleteButton
                                                            onClick={deleteMilestone}
                                                        />
                                                    </RightAlignedButtonGroup>
                                                </CardInset>
                                            }
                                        />
                            },
                            {
                                id: 'Activities',
                                name:
                                    <LocalizedText
                                        code="Generic.Activities"
                                        value="Activiteiten"
                                    />,
                                nameAppendix:
                                    activityCount > 0 && activityCount,
                                content:
                                    tabProps =>
                                        <ProjectActivityList
                                            entity={props.project}
                                            milestone={props.milestone}
                                            onCount={setActivityCount}
                                            {...tabProps}
                                        />
                            }
                        ]
                    },
                    {
                        id: 'PluralRelatedPaths',
                        tabs:
                            customPluralRelatedPaths.map(
                                path =>
                                    ({
                                        id: path.id,
                                        name: path.getName(undefined, false),
                                        nameAppendix:
                                            <RelatedEntityCount
                                                entity={props.project}
                                                path={path}
                                            />,
                                        content:
                                            () =>
                                                <RelatedEntityView
                                                    entity={props.project}
                                                    path={path}
                                                />
                                    }))
                    },
                    {
                        id: 'Static2',
                        tabs: [
                            {
                                id: 'Hours',
                                name:
                                    <LocalizedText
                                        code="Generic.Hours"
                                        value="Uren"
                                    />,
                                nameAppendix:
                                    <TimeRegistrationDuration
                                        entity={props.project}
                                        milestone={props.milestone}
                                    />,
                                isVisible: () => !hideTimeRegistrationInProject && types.TimeRegistration.Type !== undefined,
                                content:
                                    () =>
                                        <TimeSheet
                                            relationship={relationship}
                                            activity={props.project}
                                            milestone={props.milestone}
                                        />
                            },
                            {
                                id: 'Mileage',
                                name:
                                    <LocalizedText
                                        code="Generic.Mileage"
                                        value="Kilometers"
                                    />,
                                nameAppendix:
                                    <MileageRegistrationDistance
                                        entity={props.project}
                                        milestone={props.milestone}
                                    />,
                                isVisible: () => !hideMileageRegistrationInProject && types.MileageRegistration.Type !== undefined,
                                content:
                                    () =>
                                        <MileageSheet
                                            relationship={relationship}
                                            activity={props.project}
                                            milestone={props.milestone}
                                        />
                            },
                            {
                                id: 'ExternalCosts',
                                name:
                                    <LocalizedText
                                        code="ExternalCosts"
                                        value="Externe kosten"
                                    />,
                                nameAppendix: totalExternalCostsLabel,
                                isVisible: () => !hideExternalCostInProject,
                                content:
                                    () =>
                                        <ExternalCosts
                                            entity={props.project}
                                            milestone={props.milestone}
                                        />
                            },
                            {
                                id: 'ToInvoice',
                                name:
                                    isSalesOrderEnabled
                                        ?
                                            <LocalizedText
                                                code="ToBeProcessed"
                                                value="Te verwerken"
                                            />
                                        :
                                            <LocalizedText
                                                code="ToBeInvoiced"
                                                value="Te factureren"
                                            />,
                                nameAppendix: totalToInvoiceLabel,
                                isVisible: () => !hideToInvoiceInProject,
                                content:
                                    () =>
                                        <ProductsInvoiceEditor
                                            relationship={relationship}
                                            entity={props.project}
                                            path={pathToProducts}
                                            milestone={props.milestone}
                                        />
                            },
                            {
                                id: 'Invoices',
                                name:
                                    <LocalizedText
                                        code="Generic.Invoices"
                                        value="Facturen"
                                    />,
                                nameAppendix: numberOfInvoices > 0 ? numberOfInvoices : undefined,
                                isVisible: () => !hideInvoiceInProject && types.Activity.Invoice.Type !== undefined,
                                content:
                                    () =>
                                        <InvoiceTable
                                            entity={props.project}
                                            milestone={props.milestone}
                                        />
                            },
                            {
                                id: 'EmployeePlanner',
                                name:
                                    <LocalizedText
                                        code="Generic.Planner"
                                        value="Planner"
                                    />,
                                isVisible: () => !hideEmployeePlannerInProject,
                                content:
                                    () =>
                                        <EmployeePlanner
                                            project={props.project}
                                            milestone={props.milestone}
                                        />
                            },
                            {
                                id: 'EmployeeAllocation',
                                name:
                                    <LocalizedText
                                        code="Generic.Allocation"
                                        value="Bezetting"
                                    />,
                                isVisible: () => !hideEmployeeAllocationInProject,
                                content:
                                    () =>
                                        <EmployeeAllocation
                                            project={props.project}
                                            milestone={props.milestone}
                                        />,
                            }
                        ]
                    }
                ],
                [
                    props.project,
                    props.milestone,
                    relationship,
                    types,
                    deleteMilestone,
                    customPluralRelatedPaths,
                    totalExternalCostsLabel,
                    isSalesOrderEnabled,
                    totalToInvoiceLabel,
                    pathToProducts,
                    numberOfInvoices
                ]);

        return <PageTabBar
            entity={props.project}
            tabFamilies={tabFamilies}
            tabId={lastSelectedTabId}
            onChangeTab={onChange}
            commitContext={props.commitContext}
        />;
    };

export default observer(Navigation);
