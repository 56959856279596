import { ViewComponent } from '../../../ViewStack/Model/ViewComponent';
import { ListItemStore, ListItemStoreProps } from '../Item/ListItemStore';
import List from '../List';
import Text from '../../../Text/Text';
import { TextStore } from '../../../Text/TextStore';
import { backgroundColor, textSecondaryColor } from '../../../../../@Resource/Theme/Theme';
import ListFragment from '../Fragment/ListFragment';
import { ListFragmentStore } from '../Fragment/ListFragmentStore';
import { ViewGroupStore } from '../../../ViewGroup/ViewGroupStore';
import { ViewGroupItem } from '../../../ViewGroup/ViewGroupItem';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { Localizer } from '../../../../../@Service/Localization/Localizer';
import { computed, observable } from 'mobx';
import { ListStore } from '../ListStore';
import { DragAndDropStore } from '../../../DragAndDrop/DragAndDropStore';
import { ButtonStore } from '../../../Button/ButtonStore';
import Button from '../../../Button/Button';
import { ListGroupStore } from '../ListGroupStore';

export class TimelineGroupLayout extends ViewComponent<ViewGroupStore>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('Localizer') localizer: Localizer;
    @injectWithQualifier('DragAndDropStore') dragAndDropStore: DragAndDropStore;

    // ------------------------- Properties -------------------------

    @observable groupStore: ListGroupStore<unknown, ListItemStore<unknown, ListItemStoreProps<unknown>>, Date>;
    @observable groupIdx: number;
    @observable groupStores: Array<ListGroupStore<unknown, ListItemStore<unknown, ListItemStoreProps<unknown>>, Date>>;
    @observable groupByYear: boolean;
    @observable groupByDate: boolean;

    // ------------------------ Constructor -------------------------

    constructor(listStore: ListStore<unknown, unknown, ListItemStore<unknown, ListItemStoreProps<unknown>>, unknown, unknown, unknown>,
                groupStore: ListGroupStore<unknown, ListItemStore<unknown, ListItemStoreProps<unknown>>, Date>,
                groupIdx: number,
                groupStores: Array<ListGroupStore<unknown, ListItemStore<unknown, ListItemStoreProps<unknown>>, Date>>,
                groupByYear: boolean,
                groupByDate: boolean,
                buttonStore?: ButtonStore)
    {
        super(
            List,
            new ViewGroupStore({
                items:
                [
                    new ViewGroupItem({
                        id: 'Details.tsx',
                        spacing: 0,
                        isHidden:
                            () =>
                                !this.showYear && !this.showDate,
                        view:
                            new ViewComponent(
                                List,
                                new ViewGroupStore({
                                    direction: 'horizontal',
                                    items:
                                    [
                                        new ViewGroupItem({
                                            id: 'Text',
                                            spacing: 0,
                                            growRatio: 1,
                                            style:
                                            {
                                                padding: `5px 0px 5px 5px`,
                                                background: backgroundColor
                                            },
                                            view:
                                                new ViewComponent(
                                                    List,
                                                    new ViewGroupStore({
                                                        direction: 'vertical',
                                                        items:
                                                        [
                                                            new ViewGroupItem({
                                                                id: 'Year',
                                                                spacing: 0,
                                                                isHidden:
                                                                    () =>
                                                                        !this.showYear,
                                                                view:
                                                                    new ViewComponent(
                                                                        Text,
                                                                        new TextStore(
                                                                            {
                                                                                label: () => this.localizer.formatYear(groupStore.value),
                                                                                variant: 'timelineDate',
                                                                                isDense: true,
                                                                                color: textSecondaryColor,
                                                                                isVisible:
                                                                                    () =>
                                                                                        !this.dragAndDropStore.isDragging
                                                                            }))
                                                            }),
                                                            new ViewGroupItem({
                                                                id: 'Date',
                                                                spacing: 0,
                                                                isHidden:
                                                                    () => !this.showDate,
                                                                view:
                                                                    new ViewComponent(
                                                                        Text,
                                                                        new TextStore(
                                                                            {
                                                                                label: () => this.localizer.formatDate(groupStore.value, false, true),
                                                                                variant: 'timelineDate',
                                                                                isDense: true,
                                                                                color: textSecondaryColor,
                                                                                isVisible:
                                                                                    () =>
                                                                                        !this.dragAndDropStore.isDragging
                                                                            }))
                                                            }),
                                                        ]
                                                    }))
                                        }),
                                        ...buttonStore
                                            ?
                                                [
                                                    new ViewGroupItem({
                                                        id: 'Button',
                                                        spacing: 0,
                                                        view:
                                                            new ViewComponent(
                                                                Button,
                                                                buttonStore)
                                                    })
                                                ]
                                            :
                                                []
                                    ]
                                })),
                    }),
                    new ViewGroupItem({
                        id: 'List',
                        spacing: 0,
                        view:
                            new ViewComponent(
                                ListFragment,
                                new ListFragmentStore(
                                    listStore,
                                    groupStore.itemStores))
                    })
                ]
            }));

        this.groupStore = groupStore;
        this.groupIdx = groupIdx;
        this.groupStores = groupStores;
        this.groupByYear = groupByYear;
        this.groupByDate = groupByDate;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get showYear(): boolean
    {
        // Show if previous date is in different year
        return this.groupStore.value !== undefined
            && this.groupByYear
            && (this.groupIdx === 0 ||
                    (this.groupIdx > 0
                        && this.groupStores[this.groupIdx - 1].value !== undefined
                        && this.groupStore.value !== undefined
                        && this.groupStores[this.groupIdx - 1].value.getFullYear() !== this.groupStore.value.getFullYear()));
    }

    @computed
    get showDate(): boolean
    {
        return this.groupStore.value !== undefined
            && this.groupByDate;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
