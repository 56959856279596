import { DependencyList, useEffect, useMemo } from 'react';
import useAsyncResult, { AsyncResult } from './useAsyncResult';
import { Disposer } from '../Disposable/Disposer';

export interface DisposableResult<T>
{
    value: T;
    disposer: Disposer;
}

export function useDisposableAsyncResult<T>(
    promiser: () => Promise<DisposableResult<T>>,
    deps: DependencyList,
    doLoad: boolean = true
): AsyncResult<T>
{
    const [ disposableResult, isLoading ] =
        useAsyncResult(
            promiser,
            deps,
            doLoad
        );

    useEffect(
        () =>
        {
            if (disposableResult !== undefined)
            {
                return () =>
                    disposableResult.disposer();
            }
        },
        [disposableResult]
    );

    return useMemo(
        () => [
            disposableResult?.value,
            isLoading,
            () =>
            {
                throw new Error('Unsupported');
            }
        ],
        [
            disposableResult,
            isLoading
        ]
    );
}