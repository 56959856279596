import React, { useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Table, TableCell, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import useTypes from '../../../Type/Api/useTypes';
import Head from '../Head/Head';
import FilterContext from '../../../Dataset/Segment/FilterContext/FilterContext';
import { isColumnPlural } from '../../Api/isColumnPlural';
import { Aggregate } from '../../../../DataObject/Model/Aggregate';
import { BaseListSelectionType, ListProps } from '../List';
import { Grouping } from '../../Model/Grouping';
import useAggregateResult from '../../../Selection/Hooks/useAggregateResult';
import { GroupedListGroup } from './GroupedListGroup';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { ListGroupsEditor } from './ListGroupsEditor';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import { TotalRow } from '../TotalRow/TotalRow';
import { useTableClasses } from '../Api/useTableClasses';
import { ViewStateOpenedGroup } from '../../State/ViewState';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import { getNumberOfVisibleColumnsInView } from '../../Api/getNumberOfVisibleColumnsInView';

export interface GroupedListProps extends ListProps
{
    root?: boolean;
    idx: number;
    grouping: Grouping;
    remainingGroups: Grouping[];
    openedGroup?: ViewStateOpenedGroup;
}

export const GroupedList: React.FC<GroupedListProps> =
    observer(
        props =>
        {
            const tableClasses = useTableClasses();
            const [ selectionMutations, setSelectionMutations ] =
                useState<string[]>(
                    props.selection
                        ? props.selection.mutations
                        : []
                );
            const [ baseSelectionType, setBaseSelectionType ] =
                useState<BaseListSelectionType>(
                    props.selection
                        ? props.selection.base
                        : props.defaultSelectionType
                            ? props.defaultSelectionType
                            : 'none'
                );
            const types = useTypes();
            const list = props.view.specification.list;
            const filter = useContext(FilterContext);
            const columnFilters =
                useComputed(
                    () =>
                        list.columns
                            .filter(
                                column =>
                                    column.filter !== undefined)
                            .map(
                                column =>
                                    column.filter),
                    [
                        list
                    ]);
            const visibleColumns =
                useComputed(
                    () =>
                        list.columns.filter(
                            column =>
                                column.shouldShowColumn(
                                    props.view,
                                    props.isInEditMode
                                )
                        ),
                    [list, props.view, props.isInEditMode]
                );
            const visibleAggregatedColumns =
                useComputed(
                    () =>
                        visibleColumns
                            .filter(
                                column =>
                                    column.aggregate !== undefined
                            ),
                    [visibleColumns]
                );
            const result =
                useAggregateResult(
                    props.view.entityType,
                    (builder) =>
                    {
                        builder.groupBy(props.grouping.column.fieldPath);

                        const groupingAggregateFieldPath =
                            props.grouping.column.fieldPath
                                .path
                                .field(
                                    props.grouping.column.fieldPath.field
                                    ?? types.Entity.Field.Id
                                );
                        builder.aggregateOn(
                            groupingAggregateFieldPath,
                            undefined,
                            Aggregate.Count
                        );

                        visibleAggregatedColumns.forEach(
                            column =>
                                builder.aggregateOn(
                                    column.fieldPath,
                                    undefined,
                                    column.aggregate
                                )
                        );

                        visibleColumns.forEach(
                            column =>
                            {
                                // Exclude plural child relations, as they interfere with paging mechanism and might
                                // cause incomplete results.
                                // Data is added later before displaying data
                                if (!isColumnPlural(column))
                                {
                                    builder.join(column.fieldPath.path);
                                }

                                if (column.cellLayout !== undefined)
                                {
                                    column.cellLayout.getDependencies()
                                        .filter(
                                            dependency =>
                                                dependency.parameter === props.view.parameter
                                                && dependency.fieldPath !== undefined
                                        )
                                        .forEach(
                                            dependencyFieldPath =>
                                                builder.join(dependencyFieldPath.fieldPath.path)
                                        );
                                }
                            }
                        );

                        list.ordering.forEach(
                            ordering =>
                                builder.orderBy(
                                    ordering.column.fieldPath,
                                    ordering.isAscending
                                )
                        );

                        if (filter && filter.isValid())
                        {
                            builder.where(
                                cb =>
                                    cb.filter(
                                        filter,
                                        {
                                            parameter: props.view.parameter,
                                        }
                                    )
                            );
                        }
                        else if (props.view.filter && props.view.filter.isValid())
                        {
                            builder
                                .where(
                                    cb =>
                                        cb.filter(
                                            props.view.filter,
                                            {
                                                parameter: props.view.parameter,
                                            }
                                        )
                                );
                        }

                        for (const filter of columnFilters)
                        {
                            builder.where(
                                cb =>
                                    cb.filter(
                                        filter,
                                        {
                                            parameter: props.view.parameter,
                                        }
                                    )
                            );
                        }
                    },
                    [
                        list,
                        props.view,
                        props.grouping,
                        visibleColumns,
                        visibleAggregatedColumns,
                        types,
                        filter,
                        columnFilters,
                    ]
                );
            const head =
                useMemo(
                    () =>
                        <Head
                            entityType={props.view.entityType}
                            view={props.view}
                            list={list}
                            isInEditMode={props.isInEditMode}
                            sortable={props.sortable}
                            selectable={props.selectable}
                            selected={baseSelectionType === 'all'}
                            selectionIsIndeterminated={selectionMutations.length > 0}
                            onSelect={
                                value =>
                                {
                                    setBaseSelectionType(value ? 'all' : 'none');
                                    setSelectionMutations([]);
                                }
                            }
                        >
                            <title/>
                        </Head>,
                    [
                        baseSelectionType,
                        setBaseSelectionType,
                        selectionMutations,
                        props.sortable,
                        props.view,
                        props.isInEditMode,
                        props.selectable,
                        list
                    ]);
            const body =
                <>
                    {
                        (result?.children ?? [])
                            .map(
                                result =>
                                    <GroupedListGroup
                                        {...props}
                                        visibleAggregatedColumns={visibleAggregatedColumns}
                                        result={result}
                                    />
                            )
                    }
                </>;
            const numberOfVisibleColumns =
                useComputed(
                    () =>
                        getNumberOfVisibleColumnsInView(
                            props.view,
                            props.sortable ?? false,
                            props.selectable ?? false,
                            props.isInEditMode ?? false
                        ),
                    [
                        props.view,
                        props.sortable,
                        props.selectable,
                        props.isInEditMode,
                    ]
                );
            const isLoading = result === undefined;

            const bodyWithAppendix =
                <TableBody>
                    {
                        visibleAggregatedColumns.length > 0 &&
                        <TotalRow
                            view={props.view}
                            columns={list.columns}
                            result={result}
                        />
                    }
                    {body}
                    {
                        isLoading &&
                        <TableRow>
                            <TableCell
                                colSpan={numberOfVisibleColumns}
                            >
                                <Centered
                                    horizontal
                                >
                                    <Loader />
                                </Centered>
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>;

            if (props.root)
            {
                return <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <CardInset>
                            <ListGroupsEditor
                                view={props.view}
                            />
                        </CardInset>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Divider />
                        <Table
                            className={tableClasses.root}
                        >
                            {head}
                            {bodyWithAppendix}
                        </Table>
                    </ViewGroupItem>
                </ViewGroup>;
            }
            else
            {
                return body;
            }
        }
    );
