import CollaborationClient from '../CollaborationClient';
import { Delta, Selection, State } from './State';
import AwaitingConfirmState from './AwaitingConfirmState';

export default class AwaitingWithBufferState implements State
{
    outstanding: Delta;
    buffer: Delta;

    constructor(outstanding: Delta,
                buffer: Delta)
    {
        this.outstanding = outstanding;
        this.buffer = buffer;
    }

    applyFromClient(client: CollaborationClient,
                    delta: Delta): State
    {
        const newBuffer = this.buffer.compose(delta);

        return new AwaitingWithBufferState(this.outstanding, newBuffer);
    }

    applyFromServer(client: CollaborationClient,
                    delta: Delta): State
    {
        const newOutstanding = delta.transform(this.outstanding, false);
        const newBuffer = delta.transform(this.buffer, false);
        const toApply = newOutstanding.transform(delta, false);

        client.applyDelta(toApply);

        return new AwaitingWithBufferState(newOutstanding, newBuffer);
    }

    serverAck(client: CollaborationClient): State
    {
        client.sendDelta(client.version, this.buffer);

        return new AwaitingConfirmState(this.buffer);
    }

    transformSelection(selection: Selection): Selection
    {
        return selection.transform(this.outstanding).transform(this.buffer);
    }

    resend(client: CollaborationClient)
    {
        client.sendDelta(client.version, this.outstanding);
    }
}
