import React, { useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../../../../../../../Type/Api/useTypes';
import Input from '../../../../../../../../../../Input/Input';
import Base from '../../Base/Base';
import { ContentProps } from '../../Content';
import useEntityValue from '../../../../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import useAggregateResult from '../../../../../../../../../../Selection/Hooks/useAggregateResult';
import { Aggregate } from '../../../../../../../../../../../DataObject/Model/Aggregate';
import { default as GenericInput } from "../../../../../../../../../../../../../@Future/Component/Generic/Input/Input/Input"
import { DataObjectRepresentation } from '../../../../../../../../../../../DataObject/Model/DataObjectRepresentation';
import { LowGranularityCompactRepresentation } from '../../../../../../../../../../../DataObject/Util/Representation/LowGranularityCompactRepresentation';

export interface SubscriptionProps extends ContentProps
{

}

const Subscription: React.FC<SubscriptionProps> =
    props =>
    {
        const types = useTypes();

        const currency =
            useEntityValue<string>(
                props.entity,
                types.Activity.Field.Currency);

        const now =
            useMemo(
                () =>
                    new Date(),
                []);

        const subscriptionRevenue =
            useAggregateResult(
                types.ProductLine.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.and(
                                    ab =>
                                        ab.relatedToEntity(
                                            rootPath
                                                .joinTo(
                                                    types.Activity.RelationshipDefinition.ProductLines,
                                                    true
                                                ),
                                            props.entity
                                        )
                                        .or(
                                            ob =>
                                                ob.isNotDefined(
                                                    rootPath.field(types.ProductLine.Field.EndDate)
                                                )
                                                .gt(
                                                    rootPath.field(types.ProductLine.Field.EndDate),
                                                    undefined,
                                                    now
                                                )
                                        )
                                )
                        )
                        .aggregateOn(
                            rootPath.field(
                                currency
                                    ? types.ProductLine.Field.TotalInCurrency
                                    : types.ProductLine.Field.Total
                            ),
                            undefined,
                            Aggregate.Sum),
                [
                    types,
                    props.entity,
                    currency
                ]
            );

        const subscriptionRevenueLabel =
            useComputed(
                () =>
                {
                    if (subscriptionRevenue)
                    {
                        return subscriptionRevenue.aggregates[0].toString(
                            new DataObjectRepresentation(
                                {
                                    ...LowGranularityCompactRepresentation,
                                    currency: currency,
                                }
                            )
                        );
                    }
                },
                [
                    subscriptionRevenue,
                    currency,
                ]
            );

        const fields = [
            <Input
                key={0}
                entity={props.entity}
                field={types.Activity.Subscription.RelationshipDefinition.Phase}
                labelPosition={props.labelPosition}
                inline={props.inlineLabel}
                commitContext={props.commitContext}
            />,
            <GenericInput
                key={1}
                label=""
                labelPosition={props.labelPosition}
                inline={props.inlineLabel}
            >
                 {subscriptionRevenueLabel}
            </GenericInput>,
            <Input
                key={2}
                entity={props.entity}
                field={types.Activity.Subscription.Field.Interval}
                labelPosition={props.labelPosition}
                inline={props.inlineLabel}
                commitContext={props.commitContext}
            />
        ];

        return <Base
            {...props}
            open
        >
            {fields}
        </Base>;
    };

export default observer(Subscription);
