import ParameterAssignment from '../Automation/Parameter/ParameterAssignment';
import { observable } from 'mobx';
import FormLayout from './Type/Form/FormLayout';

export default class FormLayoutContext
{
    // ------------------------- Properties -------------------------

    @observable.ref layout: FormLayout;
    @observable.ref parameterAssignment: ParameterAssignment;

    // ------------------------ Constructor -------------------------

    constructor(layout: FormLayout,
                parameterAssignment: ParameterAssignment)
    {
        this.layout = layout;
        this.parameterAssignment = parameterAssignment;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
