/* tslint:disable:no-invalid-this */
import { _getAdministration, action, computed, isAction, isComputedProp } from 'mobx';
import { getAdministration, TransactionalModel } from './TransactionalModel';

export const TransactionalPrototypeMap = new Map<any, any>();

export function resolveTransactionalModel<T>(model: T): TransactionalModel<T> | undefined
{
    const prototype =
        resolveTransactionalPrototype(
            model,
            model);

    if (prototype)
    {
        return Object.create(prototype);
    }
    else
    {
        return undefined;
    }
}

export function resolveTransactionalPrototype(origin: any,
                                              originPrototype: any): any
{
    if (originPrototype === undefined || originPrototype.constructor.name === 'Object')
    {
        return null;
    }

    if (TransactionalPrototypeMap.has(originPrototype))
    {
        return TransactionalPrototypeMap.get(originPrototype);
    }
    else
    {
        const transactionalPrototype =
            Object.create(
                resolveTransactionalPrototype(
                    origin,
                    Object.getPrototypeOf(originPrototype)));

        buildTransactionalPrototype(
            origin,
            originPrototype,
            transactionalPrototype);

        TransactionalPrototypeMap.set(
            origin === originPrototype
                ? Object.getPrototypeOf(origin)
                : originPrototype,
            transactionalPrototype);

        return transactionalPrototype;
    }
}

export function buildTransactionalPrototype(origin: any,
                                            originPrototype: any,
                                            transactionalPrototype: any)
{
    Object.getOwnPropertyNames(originPrototype)
        .forEach(
            name =>
            {
                buildTransactionalProperty(
                    origin,
                    originPrototype,
                    transactionalPrototype,
                    name);
            });
}

export function buildTransactionalProperty(origin: any,
                                           originPrototype: any,
                                           transactionalPrototype: any,
                                           name: string)
{
    const originPropertyDescriptor =
        Object.getOwnPropertyDescriptor(
            originPrototype,
            name);

    // console.log('initializing base', name);

    if (isComputedProp(origin, name))
    {
        Object.defineProperty(
            transactionalPrototype,
            name,
            {
                configurable:
                    originPropertyDescriptor === undefined
                        ?
                        true
                        :
                        originPropertyDescriptor.configurable,
                enumerable:
                    originPropertyDescriptor === undefined
                        ?
                        true
                        :
                        originPropertyDescriptor.enumerable,
                get: generateComputedGetterForProperty(name)
            });
    }
    else
    {
        // NOTE: only use this value to see whether it is an action,
        // but use getAdministration(this).model[name] for getting the
        // current value
        const value = origin[name];

        if (isAction(value))
        {
            Object.defineProperty(
                transactionalPrototype,
                name,
                {
                    configurable:
                        originPropertyDescriptor === undefined
                            ?
                            true
                            :
                            originPropertyDescriptor.configurable,
                    enumerable:
                        originPropertyDescriptor === undefined
                            ?
                            true
                            :
                            originPropertyDescriptor.enumerable,
                    value:
                        action(
                            name,
                            generateBoundGetValueForPropertyFunction(name))
                });
        }
        else if (typeof value === 'function')
        {
            const {
                get,
                set,
                ...originPropertyDescriptorWithoutGetterAndSetter
            } = originPropertyDescriptor ?? {};

            Object.defineProperty(
                transactionalPrototype,
                name,
                {
                    ...originPropertyDescriptorWithoutGetterAndSetter,
                    value: generateBoundGetValueForPropertyFunction(name)
                });
        } else
        {
            // console.log('   initializing other', name, value);

            Object.defineProperty(
                transactionalPrototype,
                name,
                {
                    configurable:
                        originPropertyDescriptor === undefined
                            ?
                            true
                            :
                            originPropertyDescriptor.configurable,
                    enumerable:
                        originPropertyDescriptor === undefined
                            ?
                            true
                            :
                            originPropertyDescriptor.enumerable,
                    get: generateGetterForProperty(name),
                    set: generateSetterForProperty(name)
                });
        }
    }
}

function generateBoundGetValueForPropertyFunction(name: string)
{
    return function()
    {
        return getAdministration(this).model[name].bind(this)(...Array.from(arguments));
    };
}

function generateComputedGetterForProperty(name: string)
{
    return function()
    {
        const administration = getAdministration(this);

        if (!administration.computedValues.has(name))
        {
            administration.computedValues.set(
                name,
                computed(_getAdministration(administration.model, name).derivation.bind(this)));
        }

        return administration.computedValues.get(name)!.get();
    };
}

function generateGetterForProperty(name: string)
{
    return function()
    {
        // console.log('getting property', name, 'from', this);

        const administration = getAdministration(this);

        if (administration)
        {
            return administration.getFromTransactional(name);
        }
        else
        {
            return undefined;
        }
    };
}

function generateSetterForProperty(name: string)
{
    return function (value: any)
    {
        getAdministration(this)
            .setInTransactional(
                name,
                value);
    };
}
