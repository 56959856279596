import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import RoundedNumberComputation, { RoundingMethod } from '../../../../../../../../../../@Api/Automation/Function/Computation/RoundedNumberComputation';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import NumberEditor from '../../../../../../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';
import StaticSelectbox, { StaticOption } from '../../../../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import localizeText from '../../../../../../../../../../@Api/Localization/localizeText';
import EmptyValue from '../../../../../../../../../../@Api/Automation/Value/EmptyValue';

export interface RoundedNumberComputationEditorProps extends ComputationEditorProps<PrimitiveValueType, RoundedNumberComputation>
{

}

const RoundedNumberComputationEditor: React.FC<RoundedNumberComputationEditorProps> =
    props =>
    {
        const setScale =
            useCallback(
                (scale?: number) =>
                    runInAction(
                        () =>
                            props.value.scale = scale ?? 0
                    ),
                [
                    props.value
                ]);
        const setMethod =
            useCallback(
                (roundingMethod?: RoundingMethod) =>
                    runInAction(
                        () =>
                            props.value.method = roundingMethod ?? 'HalfUp'
                    ),
                [
                    props.value
                ]);
        const setValue =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.value = value ?? EmptyValue.instance
                    ),
                [
                    props.value
                ]
            );
        const methods =
            useMemo<StaticOption<RoundingMethod>[]>(
                () =>
                    [
                        {
                            id: 'Down',
                            label: localizeText('RoundingMethod.RoundDown', 'Naar beneden'),
                            value: 'Down',
                        },
                        {
                            id: 'Up',
                            label: localizeText('RoundingMethod.RoundUp', 'Naar boven'),
                            value: 'Up',
                        },
                        {
                            id: 'HalfUp',
                            label: localizeText('RoundingMethod.RoundHalfUp', 'Half naar boven'),
                            value: 'HalfUp',
                        },
                    ],
                []
            );

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                <LocalizedText
                    code="RoundedValue"
                    value="Afgeronde waarde"
                />:
            </ViewGroupItem>
            <ViewGroupItem>
                <ComputationEditor
                    context={props.context}
                    value={props.value.value}
                    onChange={setValue}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <StaticSelectbox
                    options={methods}
                    onChange={setMethod}
                    value={props.value.method}
                    disableUnderline={false}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <LocalizedText
                    code="NumberOfDecimals"
                    value="Aantal decimalen"
                />:
            </ViewGroupItem>
            <ViewGroupItem>
                <NumberEditor
                    value={props.value.scale}
                    onChange={setScale}
                    min={0}
                    decimalScale={0}
                    fixedDecimalScale
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(RoundedNumberComputationEditor);
