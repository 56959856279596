import React, { useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import FormParameter from '../../../../../../@Api/Layout/Type/Form/FormParameter/FormParameter';
import LayoutContext from '../../../../../../@Api/Layout/LayoutContext';
import StaticSelectbox, { StaticOption } from '../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';

export interface FormParameterEditorProps
{
    context: LayoutContext;
    value?: FormParameter<any>;
    onChange: (value?: FormParameter<any>) => void;
}

const FormParameterEditor: React.FC<FormParameterEditorProps> =
    ({
         context,
         value,
         onChange,
     }) =>
    {
        const formParameters =
            useComputed(
                () =>
                    context.formLayoutContexts
                        .map(
                            formContext =>
                                formContext.layout.parameters.parameters.map(
                                    parameter =>
                                        new FormParameter(
                                            formContext.layout.id,
                                            parameter
                                        )
                                )
                        )
                        .reduce(
                            (a, b) =>
                                a.concat(b),
                            []
                        ),
                [
                    context
                ]
            );
        const options =
            useMemo<StaticOption<FormParameter<any>>[]>(
                () =>
                    formParameters.map(
                        parameter => ({
                            id: parameter.getId(),
                            label: parameter.parameter.getName(),
                            value: parameter,
                        })
                    ),
                [
                    formParameters
                ]
            );

        return <StaticSelectbox
            options={options}
            value={value}
            onChange={onChange}
        />;
    };

export default observer(FormParameterEditor);
