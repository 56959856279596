import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import SourceFieldMapping from '../SourceField/SourceFieldMapping';
import ValueToEntityMapping from './ValueToEntityMapping';
import { observable } from 'mobx';

export default class ValueToEntitySourceFieldMapping extends SourceFieldMapping
{
    // ------------------------- Properties -------------------------

    @observable valueMappings: ValueToEntityMapping[];

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                targetFieldPath: EntityFieldPath,
                sourceFieldId: string,
                valueMappings: ValueToEntityMapping[])
    {
        super(id, targetFieldPath, sourceFieldId);

        this.valueMappings = valueMappings;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new ValueToEntitySourceFieldMapping(
            descriptor.id,
            EntityFieldPath.construct(descriptor.targetFieldPath),
            descriptor.sourceFieldId,
            descriptor.valueMappings
                .map(
                    valueMapping =>
                        ValueToEntityMapping.fromDescriptor(
                            valueMapping)));
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'ValueToEntitySourceField',
            valueMappings:
                this.valueMappings.map(
                    valueMapping =>
                        valueMapping.toDescriptor())
        };
    }

    // ----------------------- Private logic ------------------------
}
