import React, { useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import { ComputationLayoutViewerProps } from './ComputationLayoutViewer';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';
import safelyApplyFunction from '../../../../../@Api/Automation/Api/safelyApplyFunction';
import ValueViewer from '../../../Entity/Viewer/Content/Automation/Editor/Value/ValueViewer';
import safelySynchronousApplyFunction from '../../../../../@Api/Automation/Api/safelySynchronousApplyFunction';
import { useDelayedValue } from '../../../../../@Future/Util/Hook/useDelayedValue';

export interface AsyncComputationLayoutViewerProps extends ComputationLayoutViewerProps
{

}

export const AsyncComputationLayoutViewer: React.FC<AsyncComputationLayoutViewerProps> =
    observer(
        props =>
        {
            const context =
                useMemo(
                    () =>
                        new FunctionContext(
                            props.parameterDictionary,
                            props.parameterAssignment,
                            props.commitContext
                        ),
                    [
                        props.parameterDictionary,
                        props.parameterAssignment,
                        props.commitContext,
                    ]
                );
            const dependencies =
                useMemo(
                    () =>
                        Array.from(
                            new Set(
                                props.layout
                                    .getDependencies()
                                    .map(
                                        dependency =>
                                            dependency.parameter
                                    )
                            )
                        ),
                    [
                        props.layout,
                    ]
                );
            const dependencyValues =
                useComputed(
                    () =>
                        dependencies.map(
                            dependency =>
                                safelySynchronousApplyFunction(
                                    dependency,
                                    context
                                )
                        ),
                    [
                        dependencies,
                        context,
                    ]
                );
            const delayedDependencyValues = useDelayedValue(dependencyValues);
            const delayedDependencyValuesSignature =
                useMemo(
                    () =>
                        delayedDependencyValues.slice()
                            .map(
                                value =>
                                    value.getId()
                            )
                            .join(';'),
                    [
                        delayedDependencyValues
                    ]
                );
            const [ value ] =
                useAsyncResult(
                    () =>
                        safelyApplyFunction(
                            props.layout.computation,
                            context
                        ),
                    [
                        props.layout,
                        context,
                        delayedDependencyValuesSignature,
                    ]
                );

            if (value)
            {
                return <ValueViewer
                    value={value}
                />;
            }
            else
            {
                return null;
            }
        }
    );