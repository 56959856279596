import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { observable } from 'mobx';
import ResourceSelection from './ResourceSelection';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import { ResourceIntervalType } from './ResourceInterval';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import getPredicateFromDescriptor from '../../../../../@Api/Automation/Api/getPredicateFromDescriptor';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import PrimitiveValueType from '../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../DataObject/Model/DataObject';

interface ResourcePlannerFilterParams
{
    startDate: Parameter<PrimitiveValueType>;
    endDate: Parameter<PrimitiveValueType>;
}

export default class ResourcePlanner
{
    // ------------------------- Properties -------------------------

    @observable.ref resourceParameter: Parameter<EntityValueType>;
    @observable.ref startDateParameter: Parameter<PrimitiveValueType>;
    @observable.ref endDateParameter: Parameter<PrimitiveValueType>;
    @observable.ref parameterDictionary: ParameterDictionary;
    @observable.ref resourceFilter?: Predicate;
    @observable.shallow resourceSelections: ResourceSelection[];
    @observable.ref childSelections: ResourcePlanner[];
    @observable isTimesheet: boolean;
    @observable minInterval: ResourceIntervalType;
    @observable maxInterval: ResourceIntervalType;

    // ------------------------ Constructor -------------------------

    constructor(
        resourceParameter: Parameter<EntityValueType>,
        parameterDictionary: ParameterDictionary,
        resourceFilter: Predicate | ((params: ResourcePlannerFilterParams) => Predicate) | undefined,
        resourceSelections: ResourceSelection[],
        childSelections: ResourcePlanner[] = [],
        isTimesheet: boolean = false,
        minInterval: ResourceIntervalType = 'day',
        maxInterval: ResourceIntervalType = 'month'
    )
    {
        this.resourceParameter = resourceParameter;
        this.parameterDictionary = parameterDictionary;
        this.startDateParameter =
            new Parameter<PrimitiveValueType>(
                'StartDate',
                new PrimitiveValueType(
                    DataObject.getTypeById('DateTime')
                ),
                true,
                undefined
            );
        this.endDateParameter =
            new Parameter<PrimitiveValueType>(
                'EndDate',
                new PrimitiveValueType(
                    DataObject.getTypeById('DateTime')
                ),
                true,
                undefined
            );
        this.resourceFilter =
            typeof resourceFilter === 'function'
                ? resourceFilter({
                    startDate: this.startDateParameter,
                    endDate: this.endDateParameter,
                })
                : resourceFilter as Predicate | undefined;
        this.resourceSelections = resourceSelections;
        this.childSelections = childSelections;
        this.isTimesheet = isTimesheet;
        this.minInterval = minInterval;
        this.maxInterval = maxInterval;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static getResourceParameter(entityType: EntityType): Parameter<EntityValueType>
    {
        return new Parameter(
            'Resource',
            new EntityValueType(entityType),
            true,
            entityType.getName());
    }

    get id()
    {
        return this.resourceParameter.id;
    }

    get resourceType()
    {
        return this.resourceParameter.type.type;
    }

    get allParameterDictionary()
    {
        return ParameterDictionary.union(
            new ParameterDictionary([ this.resourceParameter ]),
            this.parameterDictionary);
    }

    static async fromDescriptor(descriptor: any)
    {
        const resourceParameter =
            ResourcePlanner.getResourceParameter(
                loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.resourceTypeId));

        const specificationParameterDictionary =
            await ParameterDictionary.fromDescriptor(
                descriptor.parameters,
                AutomationDependencyContext.GetEmptyContext());

        const parameterDictionary = new ParameterDictionary([ resourceParameter, ...specificationParameterDictionary.parameters ]);
        const dependencyContext = new AutomationDependencyContext(parameterDictionary);

        const resourceFilter =
            descriptor.resourceFilter
                ?
                    await getPredicateFromDescriptor(
                        descriptor.resourceFilter,
                        dependencyContext)
                :
                    undefined;

        const resourceSelections: ResourceSelection[] =
            await Promise.all(
                descriptor.resourceSelections.map(
                    (selection: any) =>
                        ResourceSelection.fromDescriptor(
                            selection,
                            dependencyContext)));

        return new ResourcePlanner(
            resourceParameter,
            specificationParameterDictionary,
            resourceFilter,
            resourceSelections);
    }

    toDescriptor()
    {
        return {
            resourceTypeId: this.resourceType.id,
            parameters: this.parameterDictionary.toDescriptor(),
            resourceFilter: this.resourceFilter?.toDescriptor(),
            resourceSelections:
                this.resourceSelections.map(
                    selection =>
                        selection.toDescriptor())
        };
    }

    // ----------------------- Private logic ------------------------
}
