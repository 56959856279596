import { EntityTypeStore } from '../../../Type/EntityTypeStore';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';

export function isAttachedType(entityTypeStore: EntityTypeStore, entityType: EntityType)
{
    return [
        entityTypeStore.bespoke.types.Activity.Appointment.Type,
        entityTypeStore.bespoke.types.Activity.Task.Type,
        entityTypeStore.bespoke.types.Activity.Offer.Type,
        entityTypeStore.bespoke.types.Activity.Invoice.Type,
        entityTypeStore.bespoke.types.Activity.Email.Type,
        entityTypeStore.bespoke.types.Activity.Document.Type,
        entityTypeStore.bespoke.types.Activity.DigitalSigning.Type,
        entityTypeStore.bespoke.types.Activity.CourseLessonAttendance.Type,
        entityTypeStore.bespoke.types.Note.Type,
        entityTypeStore.bespoke.types.Attachment.Type,
        entityTypeStore.bespoke.types.ProductLine.Type,
    ].some(
        type =>
            entityType && entityType.isA(type));
}
