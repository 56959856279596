import { BaseStore } from '../../../../../../@Framework/Store/BaseStore';
import { action, observable } from 'mobx';
import { ComputationEditorStore } from '../../../ComputationEditorStore';
import { PredicateEditorStore } from '../../../../Predicate/PredicateEditorStore';

export class ConditionalComputationConditionStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable predicateStore: PredicateEditorStore;
    @observable computationStore: ComputationEditorStore;
    @observable isOperatorMenuOpen: boolean;
    @observable onDelete: (store: ConditionalComputationConditionStore) => void;

    // ------------------------ Constructor -------------------------

    constructor(predicateStore: PredicateEditorStore,
                computationStore: ComputationEditorStore,
                onDelete: (store: ConditionalComputationConditionStore) => void)
    {
        super();

        this.predicateStore = predicateStore;
        this.computationStore = computationStore;
        this.onDelete = onDelete;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    delete()
    {
        this.onDelete(this);
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
