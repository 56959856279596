import { Entity } from '../../../../../Model/Implementation/Entity';
import { CommitContext } from '../../CommitContext';
import { createTransactionalModel } from '../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';

export function createTransactionalModelIfCommitContextIsAbsent(
    entity: Entity,
    commitContext?: CommitContext
): Entity
{
    if (commitContext)
    {
        return entity;
    }
    else
    {
        return createTransactionalModel(entity);
    }
}
