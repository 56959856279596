import React from 'react';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import useTypes from '../Type/Api/useTypes';
import RichText from '../../../Generic/RichText/RichText';
import { FileValue } from '../../DataObject/Type/File/FileValue';
import useApiClient from '../../../../@Service/ApiClient/Hooks/useApiClient';
import useAsyncResult from '../../../../@Util/Async/useAsyncResult';
import { getHtmlBodyWithEmbeddedFiles } from './getHtmlBodyWithEmbeddedFiles';
import { useComputed } from 'mobx-react-lite';
import { observer } from 'mobx-react';

export interface EntityEmailViewerProps
{
    entity: Entity;
}

const EntityEmailViewer: React.FC<EntityEmailViewerProps> =
    props =>
    {
        const types = useTypes();

        const attachmentByFileName =
            useComputed(
                () =>
                    new Map(
                        props.entity
                            .getRelatedEntitiesByDefinition(
                                false,
                                types.Entity.RelationshipDefinition.Attachments
                            )
                            .map(
                                attachment => [
                                    attachment.getObjectValueByField<FileValue>(types.Attachment.Field.File)?.name,
                                    attachment
                                ]
                            )
                    ),
                [
                    props.entity,
                    types
                ]
            );

        const apiClient = useApiClient();

        const [ htmlBody] =
            useAsyncResult(
                () =>
                    getHtmlBodyWithEmbeddedFiles(
                        apiClient,
                        props.entity,
                        attachmentByFileName
                    ),
                [
                    apiClient,
                    props.entity,
                    attachmentByFileName
                ]
            );

        if (htmlBody)
        {
            return <RichText
                value={htmlBody}
                standalone
                allowScrolling
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(EntityEmailViewer);
