import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { useCallback } from 'react';
import useTypes from '../../../Type/Api/useTypes';
import usePhaseRelationshipDefinition from '../../../../../../@Api/Entity/Bespoke/Datastore/Phase/usePhaseRelationshipDefinition';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export default function usePhaseSelectionCallback(
    entity: Entity,
    states: Entity[]
)
{
    const types = useTypes();
    const phaseRelationshipDefinition = usePhaseRelationshipDefinition(entity && entity.entityType);

    return useCallback(
        (idx: number) =>
        {
            if (idx !== undefined && phaseRelationshipDefinition)
            {
                const phase = states[idx];

                return new CommitBuilder()
                    .relateEntityTo(
                        entity,
                        false,
                        phaseRelationshipDefinition,
                        phase
                    )
                    .commit();
            }
        },
        [
            entity,
            states,
            types,
            phaseRelationshipDefinition
        ]);
}
