import { observer } from 'mobx-react';
import * as React from 'react';
import { EntityViewerPageInitializerStore } from './EntityViewerPageInitializerStore';
import { Typography } from '@material-ui/core';
import { EntityViewerPage } from '../EntityViewerPage';
import { Page } from '../../../../../../@Service/Navigation/Page/Page';

@observer
export class EntityViewerPageInitializer extends Page<EntityViewerPageInitializerStore>
{
    renderBody(store: EntityViewerPageInitializerStore): JSX.Element
    {
        if (store.viewerStore)
        {
            return <EntityViewerPage store={store.viewerStore} />;
        }
        else
        {
            return <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                style={{
                    marginTop: 100
                }}
            >
                {
                    store.localizationStore.translate('EntityViewerPageInitializer.PageNotFound') // Page not found
                }
            </Typography>;
        }
    }
}
