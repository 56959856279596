import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../Entity/Type/Api/useTypes';
import { observer } from 'mobx-react-lite';
import DefinitionComponent from './DefinitionComponent';
import PrimaryButton from '../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import { v4 as uuid } from 'uuid';
import generateNumberSequence from '../Hooks/generateNumberSequence';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { default as InputLabel } from '../../../../@Future/Component/Generic/Input/Input/Input';
import EntityTypeContext from '../../Entity/Type/EntityTypeContext';
import SaveButton from '../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import { EntityField } from '../../../../@Api/Model/Implementation/EntityField';
import Dialog from '../../../../@Future/Component/Generic/Dialog/Dialog';
import DialogContent from '../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import DialogActions from '../../../../@Future/Component/Generic/Dialog/Actions/DialogActions';
import DialogTitle from '../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import { toJS } from 'mobx';
import Input from '../../Entity/Input/Input';
import { createTransactionalModel } from '../../../../@Util/TransactionalModelV2';
import useResult from '../../Entity/Selection/Hooks/useResult';
import useEntityValue from '../../../../@Api/Entity/Hooks/useEntityValue';
import CardHeader from '../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import InputGroup from '../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import RightAlignedButtonGroup from '../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import DeleteIconButton from '../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import sanitizeNumberSequenceDefinition from './sanitizeNumberSequenceDefinition';
import { useCommittableEntity } from '../../../../@Api/Entity/Commit/Context/Api/useCommittableEntity';
import { useNewCommitContext } from '../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { DefinitionBuilderRelationshipType } from './DefinitionBuilderRelationshipType';

export type NumberSequenceDefinitionComponentType = 'Number' | 'Text' | 'DateDD' | 'DateYY' | 'DateYYYY' | 'DateMM' | 'DateWW' | 'FieldPath';

export type NumberSequenceDefinitionComponent =
{
    uuid: string;
    type?: NumberSequenceDefinitionComponentType,
    data?: any
};

export type NumberSequenceDefinition =
{
    components: NumberSequenceDefinitionComponent[]
};

export interface DefinitionBuilderProps
{
    entityType: EntityType;
    entityField: EntityField;
    close: () => void;
}

const DefinitionBuilder: React.FC<DefinitionBuilderProps> =
    props =>
    {
        const entityTypeStore = useContext(EntityTypeContext);
        const types = useTypes();
        const [ components, setComponents ] = useState<NumberSequenceDefinitionComponent[]>([]);
        const [ definition, setDefinition ] = useState<NumberSequenceDefinition>({ components: [] });
        const { close } = props;

        const [ numberSequenceDefinition ] =
            useResult(
                types.NumberSequenceDefinition.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.NumberSequenceDefinition.Field.EntityType),
                                    undefined,
                                    props.entityType))
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.NumberSequenceDefinition.Field.EntityField),
                                    undefined,
                                    props.entityField)),
                [
                    types,
                    props.entityType,
                    props.entityField
                ]);

        useEffect(
            () =>
            {
                if (numberSequenceDefinition)
                {
                    const specification =
                        numberSequenceDefinition.getObjectValueByField(
                            types.NumberSequenceDefinition.Field.Specification);

                    if (specification && specification.components)
                    {
                        setComponents(specification.components);
                    }
                }
            },
            [
                numberSequenceDefinition,
                types,
                setComponents
            ]);

        const entity =
            useMemo(
                () =>
                    numberSequenceDefinition
                    ?
                        createTransactionalModel(
                            numberSequenceDefinition)
                    :
                        createTransactionalModel(
                            new Entity(
                                types.NumberSequenceDefinition.Type)
                                .initialize(entityTypeStore)),
                [
                    types,
                    entityTypeStore,
                    numberSequenceDefinition
                ]);

        const minNumberLength = useEntityValue(
            entity,
            types.NumberSequenceDefinition.Field.NumberLength,
            1);

        const sequenceHasNumber =
            useMemo(
                () =>
                    !!components.find(
                        c =>
                            c.type === 'Number'),
                [
                    components
                ]);

        useEffect(
            () =>
                setDefinition(
                    {
                        components: components
                    }),
            [
                components,
                setDefinition
            ]);

        const addComponent =
            useCallback(
                () =>
                    setComponents(
                        [
                            ...components,
                            {
                                uuid: uuid()
                            }
                        ]),
                [
                    setComponents,
                    components
                ]);

        const save =
            useCallback(
                () =>
                {
                    const sanitizedDefinition = sanitizeNumberSequenceDefinition(definition);

                    entity.setValueByField(types.NumberSequenceDefinition.Field.EntityType, props.entityType);
                    entity.setValueByField(types.NumberSequenceDefinition.Field.EntityField, props.entityField);
                    entity.setValueByField(types.NumberSequenceDefinition.Field.Specification, toJS(sanitizedDefinition));

                    entity.checkAndDoCommit()
                        .then(
                            () =>
                                close());
                },
                [
                    entity,
                    props.entityType,
                    props.entityField,
                    definition,
                    close,
                    types
                ]);

        const componentChanged =
            useCallback(
                (component) =>
                {
                    setComponents(
                        components.map(
                            c =>
                                component.uuid === c.uuid
                                ?
                                    {
                                        uuid: c.uuid,
                                        type: component.type,
                                        data: component.data
                                    }
                                :
                                    c
                        ))
                },
                [
                    components,
                    setComponents
                ]);

        const generatedNumberSample =
            useMemo(
                () =>
                    definition &&
                        generateNumberSequence(
                            definition,
                            1,
                            minNumberLength),
                [
                    definition,
                    minNumberLength
                ]);

        const deleteComponent =
            useCallback(
                (uuid) =>
                {
                    setComponents(
                        components.filter(
                            c => c.uuid !== uuid));
                },
                [
                   components,
                   setComponents
                ]);

        const [ currentNumberSequence, ] =
            useResult(
                numberSequenceDefinition ? types.NumberSequence.Type : undefined,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath
                                        .joinTo(
                                            types.NumberSequenceDefinition.RelationshipDefinition.NumberSequences,
                                            true),
                                    numberSequenceDefinition))
                        .orderBy(
                            rootPath.field(types.Entity.Field.Id),
                            false)
                        .limit(1),
                [
                    numberSequenceDefinition
                ]);
        const commitContext = useNewCommitContext();
        const transactionalCurrentNumberSequence = useCommittableEntity(currentNumberSequence, commitContext);

        return <Dialog
            width="md"
            open={true}
            onClose={close}
        >
            <DialogTitle>
                <LocalizedText
                    code="Configuration.AutoNumbering"
                    value="Autonummering"
                />
            </DialogTitle>
            <DialogContent>
                <ViewGroup
                    orientation="vertical"
                    spacing={15}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={10}
                        >
                            <ViewGroupItem
                                ratio={4}
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={10}
                                >
                                    <ViewGroupItem>
                                        <InputLabel
                                            label={
                                                <LocalizedText
                                                    code="Generic.Type"
                                                    value="Type"
                                                />
                                            }
                                            labelPosition="left"
                                        >
                                            <div>
                                                {props.entityType.nameSingular}
                                            </div>
                                        </InputLabel>
                                    </ViewGroupItem>
                                    {
                                        props.entityField === types.Relation.Field.DebtorNumber &&
                                        <DefinitionBuilderRelationshipType
                                            entityType={props.entityType}
                                        />
                                    }
                                    <ViewGroupItem>
                                        <InputLabel
                                            label={
                                                <LocalizedText
                                                    code="Generic.Field"
                                                    value="Veld"
                                                />
                                            }
                                            labelPosition="left"
                                        >
                                            <div>
                                                {props.entityField.name}
                                            </div>
                                        </InputLabel>
                                    </ViewGroupItem>
                                    {
                                        generatedNumberSample &&
                                            <ViewGroupItem>
                                                <InputLabel
                                                    label={
                                                        <LocalizedText
                                                            code="Generic.Example"
                                                            value="Voorbeeld"
                                                        />
                                                    }
                                                    labelPosition="left"
                                                >
                                                    <div>
                                                        <b><i>{generatedNumberSample}</i></b>
                                                    </div>
                                                </InputLabel>
                                            </ViewGroupItem>
                                    }
                                </ViewGroup>
                            </ViewGroupItem>
                            <ViewGroupItem
                                ratio={5}
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={10}
                                >
                                    <ViewGroupItem>
                                        <Input
                                            entity={entity}
                                            field={types.NumberSequenceDefinition.Field.NumberLength}
                                            labelPosition="left"
                                            doAutocommit={false}
                                            underline="always"
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <Input
                                            entity={entity}
                                            field={types.NumberSequenceDefinition.Field.StartNumber}
                                            labelPosition="left"
                                            doAutocommit={false}
                                            underline="always"
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <Input
                                            entity={entity}
                                            field={types.NumberSequenceDefinition.Field.SubsequentStartNumber}
                                            labelPosition="left"
                                            doAutocommit={false}
                                            underline="always"
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={10}
                        >
                            <ViewGroupItem
                                ratio={2}
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={10}
                                >
                                    <ViewGroupItem>
                                        <CardHeader>
                                            <LocalizedText
                                                code="Configuration.AutoNumbering.Structure"
                                                value="Nummer opbouw"
                                            />
                                        </CardHeader>
                                    </ViewGroupItem>
                                    {
                                        definition.components.map(
                                            (component, idx) =>
                                                <ViewGroupItem
                                                    key={component.uuid}
                                                >
                                                    <ViewGroup
                                                        orientation="horizontal"
                                                        alignment="center"
                                                        spacing={10}
                                                    >
                                                        <ViewGroupItem>
                                                            {idx + 1})
                                                        </ViewGroupItem>
                                                        <ViewGroupItem
                                                            ratio={1}
                                                        >
                                                            <DefinitionComponent
                                                                component={component}
                                                                sequenceHasNumber={sequenceHasNumber}
                                                                onChange={componentChanged}
                                                                entityType={props.entityType}
                                                                entityField={props.entityField}
                                                            />
                                                        </ViewGroupItem>
                                                        <ViewGroupItem>
                                                            <DeleteIconButton
                                                                onClick={() => deleteComponent(component.uuid)}
                                                            />
                                                        </ViewGroupItem>
                                                    </ViewGroup>
                                                </ViewGroupItem>)
                                    }
                                    <ViewGroupItem>
                                        <RightAlignedButtonGroup>
                                            <PrimaryButton
                                                label={
                                                    <LocalizedText
                                                        code="Configuration.AutoNumbering.AddComponent"
                                                        value="Onderdeel toevoegen"
                                                    />
                                                }
                                                onClick={addComponent}
                                            />
                                        </RightAlignedButtonGroup>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    {
                        transactionalCurrentNumberSequence &&
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={5}
                                >
                                    <ViewGroupItem>
                                        <CardHeader>
                                            <LocalizedText
                                                code="Configuration.AutoNumbering.CurrentNumber"
                                                value="Huidig nummer"
                                            />
                                        </CardHeader>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <InputGroup>
                                            <Input
                                                commitContext={commitContext}
                                                entity={transactionalCurrentNumberSequence}
                                                field={types.NumberSequence.Field.Pattern}
                                                labelPosition="left"
                                                doAutocommit
                                                disabled
                                            />
                                            <Input
                                                commitContext={commitContext}
                                                entity={transactionalCurrentNumberSequence}
                                                field={types.NumberSequence.Field.LastNumber}
                                                labelPosition="left"
                                                doAutocommit
                                                underline="always"
                                            />
                                        </InputGroup>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </DialogContent>
            <DialogActions>
                <SaveButton
                    onClick={save}
                />
            </DialogActions>
        </Dialog>;
    };

export default observer(DefinitionBuilder);
