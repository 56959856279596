import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import styles from '../EnvironmentUserList.module.scss';
import Item from '../../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import { User } from '../../../../../../../../../@Api/Model/Implementation/User';
import performAction from '../../../../../../../../../@Api/Entity/performAction';
import showErrorMessage from '../../../../../../../../../@Api/Error/showErrorMessage';
import AuthenticationManagerContext from '../../../../../../../../../@Service/Authentication/AuthenticationManagerContext';
import { classNames } from '../../../../../../../../../@Future/Util/Class/classNames';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { textSecondaryColor } from '../../../../../../../../../@Resource/Theme/Theme';
import { Box } from '@material-ui/core';

export interface EnvironmentUserProps
{
    user: User;
    isSelected: boolean;
    onSelect: (user: User) => void;
    onSwitched?: (user: User) => void;
    onNoLicenseError: (user: User) => void;
}

const EnvironmentUser: React.FC<EnvironmentUserProps> =
    ({
         user,
         isSelected,
         onSelect,
         onSwitched,
         onNoLicenseError,
     }) =>
    {
        const authenticationManagerContext = useContext(AuthenticationManagerContext);

        const switchUser =
            useCallback(
                () =>
                {
                    authenticationManagerContext
                        .switchToUser(user)
                        .then(
                            () =>
                            {
                                if (onSwitched)
                                {
                                    onSwitched(user);
                                }
                            }
                        )
                        .catch(
                            error =>
                                showErrorMessage(error)
                        );
                },
                [
                    authenticationManagerContext,
                    user,
                    onSwitched,
                ]);

        const clickCallback =
            useCallback(
                () =>
                {
                    onSelect(user);

                    if (!isSelected)
                    {
                        if (user.portal || user.account?.isDeveloper || user.account?.isConsultant)
                        {
                            switchUser();
                        }
                        else
                        {
                            performAction(
                                undefined, {
                                    code: 'User.IsLicensed',
                                    parameters: {
                                        userId: user.id
                                    }
                                })
                                .then(result =>
                                {
                                    if (result.result)
                                    {
                                        switchUser();
                                    }
                                    else
                                    {
                                        onNoLicenseError(user);
                                    }
                                });
                        }
                    }
                },
                [
                    user,
                    onSelect,
                    switchUser,
                    onNoLicenseError,
                ]);

        const isSubAdministration =
            useMemo(
                () =>
                    user.organization.parentOrganization !== undefined,
                [
                    user
                ]
            );

        return <Item
            key={user.id}
            name={
                <ViewGroup
                    orientation="horizontal"
                    alignment="center"
                    spacing={5}
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        {user.organization.name}{user.portal ? `: ${user.portal._name}` : ''} {user.description ? `(${user.description})` : ''}
                    </ViewGroupItem>
                    {
                        user.account?.isDeveloper &&
                        <ViewGroupItem>
                            <Box
                                color={textSecondaryColor}
                                fontSize={12}
                            >
                                {user.organization.id}
                            </Box>
                        </ViewGroupItem>
                    }
                </ViewGroup>
            }
            onClick={clickCallback}
            className={isSelected ? classNames(styles.selectedItem, isSubAdministration && styles.isSubAdministration)
                : isSubAdministration && styles.isSubAdministration}
        />;
    };

export default observer(EnvironmentUser);
