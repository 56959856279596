import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import ComputedImageLayout from '../../../../../@Api/Layout/Type/ComputedImageLayout';
import safelySynchronousApplyFunction from '../../../../../@Api/Automation/Api/safelySynchronousApplyFunction';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import { FileValue } from '../../../DataObject/Type/File/FileValue';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { useFetchedApiFile } from '../../../../../@Service/ApiClient/Hooks/useFetchedApiFile';

export interface ComputedImageLayoutViewerProps extends LayoutViewerProps<ComputedImageLayout>
{

}

const ComputedImageLayoutViewer: React.FC<ComputedImageLayoutViewerProps> =
    props =>
    {
        const value =
            useComputed(
                () =>
                    props.layout.image?.isValid() &&
                    safelySynchronousApplyFunction(
                        props.layout.image,
                        new FunctionContext(
                            props.parameterDictionary,
                            props.parameterAssignment,
                            props.commitContext
                        )
                    ),
                [
                    props.layout,
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        const [ uri ] =
            useAsyncResult(
                async () =>
                {
                    if (value instanceof PrimitiveValue && value.value.value instanceof FileValue)
                    {
                        const dataUri = await value.value.value.getDataUri();

                        if (dataUri)
                        {
                            return dataUri;
                        }
                        else if (value.value.value.url
                            && value.value.context.getFileUrl)
                        {
                            return value.value.context.getFileUrl(value.value.value.url);
                        }
                        else
                        {
                            return undefined;
                        }
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    value
                ]);
        const [ initializedUri ] = useFetchedApiFile(uri);
        const style =
            useComputed(
                () =>
                    ({
                        width: props.layout.width?.toCss(),
                        height: props.layout.height?.toCss()
                    }),
                [
                    props.layout
                ]);

        if (initializedUri)
        {
            return <img
                src={initializedUri}
                style={style}
                alt=""
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(ComputedImageLayoutViewer);
