import { EntityPath } from '../../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityType } from '../../../Model/Implementation/EntityType';
import getTypes from '../../../../@Component/Domain/Entity/Type/Api/getTypes';

export function getEmailAddressesDependencyPathsByType(
    entityType: EntityType
)
{
    const types = getTypes();
    const rootPath = EntityPath.root(entityType);

    if (entityType.isCompatibleWith(types.Relationship.Type))
    {
        const relationshipPath = rootPath.castTo(types.Relationship.Type);
        const organizationPath =
            relationshipPath.castTo(types.Relationship.Organization.Type)
                .joinTo(
                    types.Relationship.Organization.RelationshipDefinition.Organization,
                    false
                );
        const personPath =
            relationshipPath.castTo(types.Relationship.Person.Type)
                .joinTo(
                    types.Relationship.Person.RelationshipDefinition.Person,
                    false
                );

        return [
            rootPath,
            ...entityType.isCompatibleWith(types.Relationship.Organization.Type)
                ? getEmailAddressesDependencyPathsByType(organizationPath.entityType)
                    .map(
                        dependencyPath =>
                            organizationPath.join(dependencyPath)
                    )
                : [],
            ...entityType.isCompatibleWith(types.Relationship.Person.Type)
                ? getEmailAddressesDependencyPathsByType(personPath.entityType)
                    .map(
                        dependencyPath =>
                            personPath.join(dependencyPath)
                    )
                : [],
        ];
    }
    else
    {
        return [
            rootPath
        ];
    }
}