import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../Type/Api/useTypes';
import Click from '../Click/Click';
import Inset from '../Inset/Inset';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import Header from '../../../Viewer/Content/Activity/RelatedActivityList/List/List/Item/Header/Header';

export interface LeexiCallProps
{
    entity: Entity;
}

export const LeexiCall: React.FC<LeexiCallProps> =
    observer(
        props =>
        {
            const types = useTypes();

            const title =
                useMemo(
                    () =>
                        props.entity.getObjectValueByField(types.LeexiCall.Field.Title),
                    [
                        props.entity,
                        types
                    ]
                );

            return <Click
                open
            >
                <Inset>
                    <Header
                        name={props.entity.entityType.getName()}
                        entity={props.entity}
                        includeAuthor
                    />
                    <div>{title}</div>
                </Inset>
            </Click>;
        }
    );