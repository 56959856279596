import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { DataObject } from '../Model/DataObject';
import { DataObjectViewerStore } from './DataObjectViewerStore';
import { DataObjectViewer } from './DataObjectViewer';

export interface BaseViewerProps
{
    dataObject: DataObject;
}

const Viewer: React.FC<BaseViewerProps> =
    props =>
    {
        const viewerStore =
            useMemo(
                () =>
                    new DataObjectViewerStore(props.dataObject),
                [
                    props.dataObject
                ]);

        return <DataObjectViewer
            store={viewerStore}
        />;
    };

export default observer(Viewer);
