import { datetime } from 'rrule';

export function toRRuleDate(date: Date) : Date
{
    // Convert localdate to utc date
    return datetime(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
    );
}