import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { useExpansion } from '../../Selection/Api/useExpansion';

export function useInitializedEntityForViewer(entity?: Entity)
{
    return useExpansion(
        entity?.entityType,
        rootPath =>
            entity ? entity.entityType.bespoke.getInitializationPathsInViewer(rootPath) : [],
        () => entity ? [ entity ] : [],
        [
            entity
        ]);
}
