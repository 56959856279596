import React from 'react';
import { observer } from 'mobx-react-lite';
import useRecentlyVisitedEntities from './Api/useRecentlyVisitedEntities';
import Centered from '../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../@Future/Component/Generic/Loader/Loader';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import OverlineLabel from '../../../../@Future/Component/Generic/Label/Variant/Overline/OverlineLabel';
import Card from '../../../../@Future/Component/Generic/Card/Card';
import DividedList from '../List/V2/Divided/DividedList';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';

export interface RecentlyVisitedListProps
{
    onOpen?: (entity: Entity) => void;
}

const RecentlyVisitedList: React.FC<RecentlyVisitedListProps> =
    props =>
    {
        const [ isLoading, entities ] = useRecentlyVisitedEntities();

        if (isLoading)
        {
            return <Centered
                horizontal
            >
                <Loader />
            </Centered>;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                <ViewGroupItem>
                    <OverlineLabel>
                        <LocalizedText
                            code="RecentlyVisited"
                            value="Recent bezocht"
                        />
                    </OverlineLabel>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Card>
                        <DividedList
                            entities={entities}
                            inset
                            compact
                            hideFavoriteButton
                            showType
                            onOpen={props.onOpen}
                        />
                    </Card>
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

export default observer(RecentlyVisitedList);
