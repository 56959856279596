import Connection from './Connection';
import Room from './Room';

export default class Multiplayer
{
    // ------------------------- Properties -------------------------

    room: Room;
    connection: Connection;
    webSocket: WebSocket;

    // ------------------------ Constructor -------------------------

    constructor(
        room: Room,
        connection: Connection,
        webSocket: WebSocket
    )
    {
        this.room = room;
        this.connection = connection;
        this.webSocket = webSocket;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    trySendMessage(data: string | ArrayBufferLike | Blob | ArrayBufferView)
    {
        if (this.isSocketOpen())
        {
            this.webSocket.send(data);
        }
    }

    // ----------------------- Private logic ------------------------

    isSocketOpen()
    {
        return this.webSocket?.readyState === WebSocket.OPEN;
    }
}