import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { observer, useComputed } from 'mobx-react-lite';
import styles from './View.module.scss';
import { LogicalOperator } from '../../DataObject/Model/LogicalOperator';
import FilterContext from '../Dataset/Segment/FilterContext/FilterContext';
import Header, { Filter } from './Header/Header';
import useTypes from '../Type/Api/useTypes';
import { Comparator } from '../../DataObject/Model/Comparator';
import { DataObject } from '../../DataObject/Model/DataObject';
import { EntityPath } from '../Path/@Model/EntityPath';
import List from './List/List';
import Columns from './Columns/Columns';
import { default as ViewModel } from './Model/View';
import uuid from 'uuid/v4';
import TypeEditor from '../../Configuration/TypeManager/TypeEditor/TypeEditor';
import Card from '../../../../@Future/Component/Generic/Card/Card';
import getOwnFieldPaths from '../../Configuration/TypeManager/TypeEditor/FieldsEditor/Api/getOwnFieldPaths';
import { EntityFieldPath } from '../Path/@Model/EntityFieldPath';
import { default as ListColumn } from './Model/Specification/Column';
import { runInAction } from 'mobx';
import Segment from '../Dataset/Model/Segment';
import CurrentUserContext from '../../User/CurrentUserContext';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import Centered from '../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../@Future/Component/Generic/Loader/Loader';
import useToggle from '../../../../@Util/Toggle/useToggle';
import usePhaseRelationshipDefinition from '../../../../@Api/Entity/Bespoke/Datastore/Phase/usePhaseRelationshipDefinition';
import usePipelineSetting from '../Workflow/Api/usePipelineSetting';
import PipelineContext from '../Dataset/Context/PipelineContext';
import ScrollButton from './ScrollButton/ScrollButton';
import getViewParameters from './Api/getViewParameters';
import Predicate from '../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import ComparisonPredicate from '../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from './Model/ViewParams';
import EntityValue from '../../../../@Api/Automation/Value/EntityValue';
import CompositePredicate from '../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import PrimitiveValue from '../../../../@Api/Automation/Value/PrimitiveValue';
import useAsyncResult from '../../../../@Util/Async/useAsyncResult';
import getEntityByUuid from '../../../../@Api/Entity/Bespoke/getEntityByUuid';
import { getSearchTermsFromQuery } from '../../../../@Util/Search/getSearchTermsFromQuery';
import { isFieldPathSearchable } from './Api/isFieldPathSearchable';
import { getPotentiallySearchableFieldPath } from './Api/getPotentiallySearchableFieldPath';
import { isFieldPathSearchableForKeyword } from './Api/isFieldPathSearchableForKeyword';
import { useUserLocalSettingCode } from '../../Setting/Api/useUserLocalSettingCode';
import useLocalSetting, { getValueFromStorage, setValueInStorage } from '../../Setting/Api/useLocalSetting';
import { SelectionOptionsValue } from '../SelectionOptions/Model/SelectionOptionsValue';
import { useViewRoutingState } from './Api/useViewRoutingState';
import { ViewStateKey } from './State/ViewStateKey';
import MapView from './MapView/MapView';
import { default as DashboardModel } from '../Dashboard/Model/Dashboard';
import Dashboard from '../Dashboard/Dashboard';
import { ViewType } from './Model/ViewType';
import { getDefaultViewTypeForDashboard } from './Api/getViewTypeForDashboard';
import getConnectorActivationByCode from '../../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';
import { ViewStateContext } from './State/ViewStateContext';
import { PersistViewStateContext } from './State/PersistViewStateContext';
import { useDefaultView } from './Api/useDefaultView';

export interface ViewProps
{
    entityType: EntityType;
    viewId?: string;
    view?: ViewModel;
    filter?: Filter;
    applyFilterOnAllViews?: boolean;
    inConfiguration?: boolean;
    configurationPageId?: string;
    configurationResourceId?: string;
    onOpen?: (entity: Entity) => void;
    settingStorageKey?: string;
    showHiddenEntities?: boolean;
    headerAppendix?: React.ReactNode;
    selectionOptionsValue?: SelectionOptionsValue;
    hideAddButton?: boolean;
    dashboard?: DashboardModel;
    defaultViewType?: ViewType;
    onViewTypeSelected?: (viewType: ViewType) => void;
}

const View: React.FC<ViewProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);
        const [ isExternalFilterEnabled, toggleExternalFilter ] = useToggle(true);
        const viewStateKey =
            useMemo<ViewStateKey>(
                () => ({
                    entityType: props.entityType,
                    viewId: props.viewId,
                    view: props.view,
                    filter: props.filter,
                }),
                [
                    props.entityType,
                    props.viewId,
                    props.view,
                    props.filter,
                ]
            );
        const [ isInEditMode, setInEditMode ] =
            useState(
                () =>
                    props.inConfiguration
                    || getOwnFieldPaths(EntityPath.fromEntityType(props.entityType)).length === 0
            );
        const isViewStatePersisted = !isInEditMode;

        const lastSelectedViewIdSettingCode =
            useUserLocalSettingCode(
                isInEditMode || props.settingStorageKey === undefined
                    ? undefined
                    : `${props.settingStorageKey}.LastSelectedViewId`
            );
        const lastSelectedViewTypeSettingCode =
            useUserLocalSettingCode(
                isInEditMode || props.settingStorageKey === undefined
                    ? undefined
                    : `${props.settingStorageKey}.LastSelectedViewType`
            );

        const [ isSelectionOptionsValueFilterEnabled, toggleSelectionOptionsFilter ] = useToggle(props.selectionOptionsValue !== undefined);
        const [ predicateBySegment, setPredicateBySegment ] = useState(new Map<Segment, Predicate>());

        const viewParameters =
            useMemo(
                () =>
                {
                    if (props.view !== undefined)
                    {
                        return props.view.parameters;
                    }
                    else if (props.selectionOptionsValue !== undefined)
                    {
                        return props.selectionOptionsValue.view.parameters;
                    }
                    else
                    {
                        return getViewParameters(props.entityType);
                    }
                },
                [
                    props.view,
                    props.selectionOptionsValue,
                    props.entityType,
                ]
            );

        const setPredicate =
            useCallback(
                (segment: Segment, predicate?: Predicate) =>
                {
                    if (predicateBySegment.get(segment) !== predicate)
                    {
                        const newMap = new Map(predicateBySegment);

                        if (predicate)
                        {
                            newMap.set(segment, predicate);
                        }
                        else
                        {
                            newMap.delete(segment);
                        }

                        setPredicateBySegment(newMap);
                    }
                },
                [
                    predicateBySegment,
                    setPredicateBySegment
                ]);

        const allFilter =
            useMemo<Filter>(
                () =>
                    (
                        {
                            name: `${props.entityType.getName(true)}`,
                            predicate:
                                props.applyFilterOnAllViews
                                    ?
                                    props.filter?.predicate
                                    :
                                    undefined
                        }
                    ),
                [
                    props.entityType,
                    props.applyFilterOnAllViews,
                    props.filter?.predicate
                ]
            );

        const [ globalFilter, setGlobalFilter ] = useState(allFilter);

        useEffect(
            () =>
            {
                setGlobalFilter(allFilter)
            },
            [
                allFilter
            ]
        );

        const [ view, _setView ] = useState<ViewModel | undefined>(props.view);
        const [ defaultView, isLoadingDefaultView ] = useDefaultView(props.entityType, viewParameters);
        const [ viewState, setViewState ] =
            useViewRoutingState(
                isViewStatePersisted
                    ? viewStateKey
                    : undefined
            );
        const [ search, setSearch ] = useState<string>(viewState?.searchQuery);

        const onChangeView =
            useCallback(
                (view?: ViewModel) =>
                {
                    _setView(view);
                    setViewState({
                        searchQuery: search,
                    });
                    setViewType(view.type);

                    if (lastSelectedViewIdSettingCode !== undefined)
                    {
                        setValueInStorage(
                            lastSelectedViewIdSettingCode,
                            view?.entity?.uuid
                        );
                    }
                },
                [lastSelectedViewIdSettingCode, search, setViewState]
            );
        const setViewAndDisableExternalFilter =
            useCallback(
                (view?: ViewModel) =>
                {
                    onChangeView(view);
                    toggleExternalFilter(false);
                },
                [
                    onChangeView,
                    toggleExternalFilter
                ]);

        const getViewEntity =
            useCallback(
                async (viewEntity?: Entity) =>
                {
                    try
                    {
                        return await ViewModel.fromDescriptor(
                            viewEntity.getObjectValueByField(types.View.Field.Specification),
                            viewEntity
                        );
                    }
                    catch (e)
                    {
                        console.error(e);
                        return defaultView;
                    }
                },
                [
                    types,
                    defaultView
                ]);

        const getView =
            useCallback(
                async () =>
                {
                    if (viewState?.descriptor)
                    {
                        try
                        {
                            return await ViewModel.fromDescriptor(viewState.descriptor);
                        }
                        catch (e)
                        {
                            console.error('Failed to load view model from state with descriptor',
                                viewState.descriptor,
                                e
                            );
                        }
                    }

                    if (props.selectionOptionsValue?.view)
                    {
                        runInAction(
                            () =>
                            {
                                defaultView.type = props.selectionOptionsValue.view.type;
                                defaultView.specification = props.selectionOptionsValue.view.specification;
                            }
                        );
                        return defaultView;

                    }
                    else if (props.view)
                    {
                        return props.view;
                    }
                    else if (props.viewId)
                    {
                        const {
                            value: viewEntity
                        } = await getEntityByUuid(
                            types.View.Type,
                            props.viewId
                        );

                        const view = await getViewEntity(viewEntity);
                        setViewType(view.type);
                        return view;
                    }
                    else if (lastSelectedViewIdSettingCode !== undefined)
                    {
                        const viewIdFromLocalStorage = getValueFromStorage(lastSelectedViewIdSettingCode);

                        if (viewIdFromLocalStorage)
                        {
                            const { value: viewEntity } =
                                await getEntityByUuid(
                                    types.View.Type,
                                    viewIdFromLocalStorage
                                );

                            return await getViewEntity(viewEntity);
                        }
                        else
                        {
                            return defaultView;
                        }
                    }
                    else
                    {
                        return defaultView;
                    }
                },
                [
                    viewState?.descriptor,
                    types,
                    getViewEntity,
                    defaultView,
                    lastSelectedViewIdSettingCode,
                    props.view,
                    props.viewId,
                    props.selectionOptionsValue
                ]
            );

        const defaultViewType =
            props.view?.type
            ?? getDefaultViewTypeForDashboard(
                currentUserStore.currentUser,
                props.dashboard?.entity
            );

        const [ viewType, setViewType ] =
            useLocalSetting<ViewType | undefined>(
                lastSelectedViewTypeSettingCode,
                isInEditMode
                    ? (view?.type ?? 'List')
                    : defaultViewType
            );

        const [ , isLoadingView ] =
            useAsyncResult(
                async () =>
                {
                    if (!isLoadingDefaultView)
                    {
                        const _view = await getView();
                        _setView(_view);
                        if (props.dashboard === undefined)
                        {
                            setViewType(_view.type);
                        }
                    }
                },
                [
                    _setView,
                    getView,
                    props.entityType,
                    props.dashboard,
                    setViewType,
                    isLoadingDefaultView
                ]);

        const showPackSelector =
            useMemo(
                () =>
                     !props.entityType.isA(types.Relation.Type)
                     && !props.entityType.isA(types.Relationship.Type)
                     && !props.entityType.isA(types.Activity.Type)
                     && !props.entityType.isA(types.CustomEntity.Type),
                [
                    types,
                    props.entityType
                ]
            );

        const [ pack, setPack ] =
            useState<Entity>(
                currentUserStore.ownedPackEntities.length > 1 && showPackSelector
                    ? currentUserStore.environmentPackEntity
                    : undefined
            );

        const filter =
            useComputed(
                () =>
                {
                    const rootPath = EntityPath.fromEntityType(props.entityType);
                    const predicates = new Set<Predicate>();

                    if (isExternalFilterEnabled
                        && props.filter
                        && props.filter.predicate
                        && props.filter.predicate.isValid())
                    {
                        predicates.add(props.filter.predicate);
                    }

                    if (view
                        && view.filter
                        && view.filter.isValid())
                    {
                        predicates.add(view.filter);
                    }

                    if (!isInEditMode
                        && isSelectionOptionsValueFilterEnabled
                        && props.selectionOptionsValue
                        && props.selectionOptionsValue.filter
                        && props.selectionOptionsValue.filter.isValid())
                    {
                        predicates.add(props.selectionOptionsValue.filter);
                    }

                    if (globalFilter.predicate
                        && globalFilter.predicate.isValid())
                    {
                        predicates.add(globalFilter.predicate)
                    }

                    if (pack)
                    {
                        predicates.add(
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    viewParameters.getParameterById(ViewParams.Entity),
                                    rootPath
                                        .joinTo(
                                            types.Pack.RelationshipDefinition.Entities,
                                            true)
                                        .field()),
                                Comparator.Equals,
                                new EntityValue(pack)));
                    }

                    predicateBySegment
                        .forEach(
                            predicate =>
                            {
                                if (predicate.isValid())
                                {
                                    predicates.add(predicate);
                                }
                            });

                    if (view && search)
                    {
                        const keywords = getSearchTermsFromQuery(search);

                        if (keywords && keywords.length > 0)
                        {
                            const searchFieldPaths =
                                (view.type === 'List' && view.specification.list
                                    ?
                                        view.specification.list.columns
                                            .filter(
                                                column =>
                                                    column.isSearchable
                                            )
                                            .map(
                                                column =>
                                                    column.fieldPath
                                            )
                                    :
                                        view.entityType.bespoke.getSearchFieldPaths(rootPath)
                                )
                                    .map(
                                        searchPath =>
                                            getPotentiallySearchableFieldPath(searchPath)
                                    )
                                    .filter(
                                        searchPath =>
                                            isFieldPathSearchable(searchPath)
                                    );

                            if (searchFieldPaths.length > 0)
                            {
                                for (const keyword of keywords)
                                {
                                    const keywordPredicates: Predicate[] = [];

                                    for (const searchFieldPath of searchFieldPaths)
                                    {
                                        if (isFieldPathSearchableForKeyword(searchFieldPath, keyword))
                                        {
                                            keywordPredicates.push(
                                                new ComparisonPredicate(
                                                    new ValueFromEntityComputation(
                                                        viewParameters.getParameterById(ViewParams.Entity),
                                                        searchFieldPath),
                                                    Comparator.Contains,
                                                    new PrimitiveValue(
                                                        DataObject.constructFromTypeIdAndValue(
                                                            'Text',
                                                            keyword))));
                                        }
                                    }

                                    if (keywordPredicates.length > 0)
                                    {
                                        predicates.add(
                                            new CompositePredicate(
                                                LogicalOperator.Or,
                                                keywordPredicates));
                                    }
                                }
                            }
                        }
                    }

                    if (predicates.size > 0)
                    {
                        return new CompositePredicate(
                            LogicalOperator.And,
                            Array.from(predicates)
                        );
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    viewParameters,
                    predicateBySegment,
                    search,
                    types,
                    view,
                    pack,
                    isExternalFilterEnabled,
                    isSelectionOptionsValueFilterEnabled,
                    props.selectionOptionsValue,
                    isInEditMode,
                    globalFilter
                ]);

        const onAddField =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                        {
                            if (view.specification.list)
                            {
                                view.specification.list.columns.push(
                                    new ListColumn(
                                        uuid(),
                                        fieldPath,
                                        false
                                    )
                                );
                            }
                        }
                    ),
                [
                    view
                ]);

        const [ isViewVisibleDuringEditMode, setViewVisibleDuringEditMode ] = useState(true);

        const phaseRelationshipDefinition = usePhaseRelationshipDefinition(props.entityType);
        const [ pipeline, setPipeline, isLoadingPipeline ] =
            usePipelineSetting(
                `View.${view?.entity?.uuid || `${props.entityType.id}`}.Pipeline`,
                props.entityType,
                phaseRelationshipDefinition !== undefined);
        const showPipelineSelector =
            useComputed(
                () =>
                    (!isInEditMode || props.configurationPageId === 'views')
                    && view?.type === 'Column'
                    && view?.specification?.column?.groupFieldPath?.relationshipDefinition === phaseRelationshipDefinition
                    && !view?.specification?.column?.groupFieldPath?.isParentRelationship,
                [
                    isInEditMode,
                    props.configurationPageId,
                    view
                ]);

        const isLoading =
            useMemo(
                () =>
                    isLoadingView || isLoadingDefaultView || (!view && !props.inConfiguration),
                [
                    isLoadingView,
                    isLoadingDefaultView,
                    view,
                    props.inConfiguration
                ]);

        const onChangeViewType =
            useCallback(
                (viewType: ViewType) =>
                {
                    setViewType(
                        viewType === defaultViewType
                            ? undefined
                            : viewType
                    );

                    // when a dashboard is selected
                    // the viewId stored in the local storage is removed
                    if (viewType === 'Dashboard' && lastSelectedViewIdSettingCode)
                    {
                        setValueInStorage(
                            lastSelectedViewIdSettingCode,
                            undefined
                        );
                    }

                    if (props.onViewTypeSelected)
                    {
                        props.onViewTypeSelected(viewType);
                    }
                },
                [
                    setViewType,
                    setValueInStorage,
                    lastSelectedViewTypeSettingCode,
                    defaultViewType
                ]);

        const [isGoogleMapsConnectorActive, isGoogleMapsConnectorActiveLoading] =
            useAsyncResult(
                () =>
                    getConnectorActivationByCode('GoogleMaps')
                        .then(
                            activation =>
                                activation
                                    ? activation.getObjectValueByField(types.ConnectorActivation.Field.IsActivated)
                                    : false
                        ),
                [
                    types
                ]
            );
        const showMap =
            useMemo(
                () =>
                    !isGoogleMapsConnectorActiveLoading
                    && isGoogleMapsConnectorActive
                    && (props.entityType.isA(types.Activity.Type)
                        || props.entityType.isA(types.Relationship.Organization.Type)
                        || props.entityType.isA(types.Relationship.Person.Type)),
                [
                    isGoogleMapsConnectorActiveLoading,
                    isGoogleMapsConnectorActive,
                    props.entityType,
                    types
                ]);

        if (isLoading || !view)
        {
            return <Centered
                horizontal
            >
                <Loader />
            </Centered>;
        }
        else
        {
            return <ViewStateContext.Provider
                value={viewState}
            >
                <PersistViewStateContext.Provider
                    value={setViewState}
                >
                    <FilterContext.Provider
                        value={filter}
                    >
                        <PipelineContext.Provider
                            value={pipeline}
                        >
                            <div
                                className={styles.root}
                            >
                                <div
                                    className={styles.header}
                                >
                                    <Header
                                        entityType={props.entityType}
                                        viewType={viewType}
                                        onChangeViewType={onChangeViewType}
                                        view={view}
                                        onChangeView={setViewAndDisableExternalFilter}
                                        onFilter={setPredicate}
                                        predicateByCategory={predicateBySegment}
                                        additionalFilter={view.filter}
                                        search={search}
                                        onSearch={setSearch}
                                        globalFilter={globalFilter}
                                        onGlobalFilter={setGlobalFilter}
                                        isInEditMode={isInEditMode}
                                        onChangeEditMode={setInEditMode}
                                        pack={pack}
                                        onChangePack={setPack}
                                        showPackSelector={showPackSelector}
                                        externalFilter={props.filter}
                                        isExternalFilterEnabled={isExternalFilterEnabled}
                                        toggleExternalFilter={toggleExternalFilter}
                                        pipeline={pipeline}
                                        onChangePipeline={setPipeline}
                                        showPipelineSelector={showPipelineSelector}
                                        headerAppendix={props.headerAppendix}
                                        selectionOptionsValue={props.selectionOptionsValue}
                                        onToggleSelectionOptionsFilter={toggleSelectionOptionsFilter}
                                        hideAddButton={props.hideAddButton}
                                        showMap={showMap}
                                        showDashboard={props.dashboard !== undefined}
                                    />
                                </div>
                                {
                                    isInEditMode &&
                                    <FilterContext.Provider
                                        value={undefined}
                                    >
                                        <div
                                            className={styles.editor}
                                        >
                                            <Card>
                                                <TypeEditor
                                                    entityType={props.entityType}
                                                    view={view}
                                                    onChangeView={onChangeView}
                                                    allView={defaultView}
                                                    onAddField={onAddField}
                                                    onViewVisibleDuringEditMode={setViewVisibleDuringEditMode}
                                                    configurationPageId={props.configurationPageId || 'views'}
                                                    configurationResourceId={props.configurationResourceId}
                                                />
                                            </Card>
                                        </div>
                                    </FilterContext.Provider>
                                }
                                {
                                    (!isInEditMode || isViewVisibleDuringEditMode)
                                && view
                                    &&
                                    <div
                                        className={styles.data}
                                    >
                                        {
                                            viewType === 'List'
                                            && view.specification.list
                                            && view.specification.list.columns.length > 0
                                            &&  <div
                                                className={styles.cardAndScrollButtons}
                                            >
                                                <ScrollButton
                                                    position="Left"
                                                />
                                                <ScrollButton
                                                    position="Right"
                                                />
                                                <Card
                                                    classes={{
                                                        root: styles.card
                                                    }}
                                                >
                                                    <List
                                                        view={view}
                                                        showHiddenEntities={props.showHiddenEntities}
                                                        isInEditMode={isInEditMode}
                                                        onClick={props.onOpen}
                                                        searchQuery={search}
                                                        saveViewState={isViewStatePersisted}
                                                        readonly={props.hideAddButton}
                                                    />
                                                </Card>
                                            </div>
                                        }
                                        {
                                            viewType === 'Column'
                                            && view.specification.column
                                            && view.specification.column.groupFieldPath
                                            && !isLoadingPipeline
                                            && <Columns
                                                view={view}
                                                isInEditMode={isInEditMode}
                                                onOpen={props.onOpen}
                                            />
                                        }
                                        {
                                            viewType === 'Map' &&
                                            <MapView
                                                view={view}
                                                onClickMarker={props.onOpen}
                                                onOpen={props.onOpen}
                                                searchQuery={search}
                                                saveViewState={isViewStatePersisted}
                                            />
                                        }
                                        {
                                            viewType === 'Dashboard'
                                            && props.dashboard
                                            &&
                                            <Dashboard
                                                dashboard={props.dashboard}
                                            />
                                        }
                                    </div>
                                }
                            </div>
                        </PipelineContext.Provider>
                    </FilterContext.Provider>
                </PersistViewStateContext.Provider>
            </ViewStateContext.Provider>;
        }
    };

export default observer(View);
