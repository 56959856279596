import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import CardLayout from '../../../../../@Api/Layout/Type/CardLayout';
import Card from '../../../../../@Future/Component/Generic/Card/Card';

export interface CardLayoutViewerProps extends LayoutViewerProps<CardLayout>
{

}

const CardLayoutViewer: React.FC<CardLayoutViewerProps> =
    props =>
    {
        const style =
            useComputed(
                () => ({
                    backgroundColor: props.layout.backgroundColor?.toCss()
                }),
                [
                    props.layout
                ]);

        return <Card
            style={style}
        >
            <LayoutViewer
                {...props}
                layout={props.layout.layout}
            />
        </Card>;
    };

export default observer(CardLayoutViewer);
