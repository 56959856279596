import Value from './Value';
import EmptyValueType from './Type/EmptyValueType';

export default class EmptyValue extends Value<void, EmptyValueType>
{
    // ------------------------- Properties -------------------------

    static instance = new EmptyValue();

    // ------------------------ Constructor -------------------------

    constructor()
    {
        super(undefined);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getId(): string
    {
        return 'Empty';
    }

    getType(): EmptyValueType
    {
        return EmptyValueType.instance;
    }

    getName(): string
    {
        return '';
    }

    equals(otherValue: Value<any, any>)
    {
        return otherValue instanceof EmptyValue;
    }

    clone(): EmptyValue
    {
        return EmptyValue.instance;
    }

    augmentDescriptor(descriptor)
    {
        descriptor.type = 'Empty';
    }

    static async fromDescriptor(descriptor: any)
    {
        return EmptyValue.instance;
    }

    // ----------------------- Private logic ------------------------
}
