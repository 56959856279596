import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Selectbox from '../../../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import AggregateValue from '../../../../../../Entity/View/Model/Column/AggregateValue';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { Aggregate } from '../../../../../../DataObject/Model/Aggregate';
import { getAggregateSymbol } from '../../../../../../DataObject/Util/SymbolUtils';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import FieldPathSelector from '../../../../../../Entity/Path/FieldPathEditor/FieldPathSelector';
import { EntityFieldPath } from '../../../../../../Entity/Path/@Model/EntityFieldPath';
import { EntityContext } from '../../../../../../Entity/@Model/EntityContext';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';

export interface DatasetSelectorProps
{
    entityType: EntityType;
    aggregateValue?: AggregateValue;
    onChange: (aggregateValue?: AggregateValue) => void;
}

const AggregateValueEditor: React.FC<DatasetSelectorProps> =
    props =>
    {
        const loader =
            useCallback(
                () =>
                    Promise.resolve(Object.values(Aggregate)),
                []);

        const formatter =
            useCallback(
                (aggregate: Aggregate) =>
                    getAggregateSymbol(aggregate),
                []);

        const idResolver =
            useCallback(
                (aggregate: Aggregate) =>
                    Aggregate[aggregate],
                []);

        const onChangeAggregate =
            useCallback(
                (aggregate: Aggregate) =>
                {
                    if (aggregate === Aggregate.Count)
                    {
                        props.onChange();
                    }
                    else
                    {
                        props.onChange(
                            new AggregateValue(
                                props.aggregateValue ? props.aggregateValue.fieldPath : undefined,
                                aggregate))
                    }
                },
                [
                    props.onChange,
                    props.aggregateValue
                ]);

        const onChangeFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                {
                    props.onChange(
                        new AggregateValue(
                            fieldPath,
                            props.aggregateValue.aggregate));
                },
                [
                    props.onChange,
                    props.aggregateValue
                ]);

        const context =
            useComputed(
                () =>
                    EntityContext.fromEntityType(props.entityType),
                [
                    props.entityType
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            <ViewGroupItem>
                <Input
                    label={
                        <LocalizedText
                            code="Generic.Value"
                            value="Waarde"
                        />
                    }
                    labelPosition="left"
                >
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                        alignment="center"
                    >
                        <ViewGroupItem>
                            <Selectbox
                                load={loader}
                                onChange={onChangeAggregate}
                                formatOption={formatter}
                                idResolver={idResolver}
                                value={props.aggregateValue ? props.aggregateValue.aggregate : Aggregate.Count}
                            />
                        </ViewGroupItem>
                        {
                            props.aggregateValue && props.aggregateValue.aggregate !== Aggregate.Count &&
                                <ViewGroupItem>
                                    van {props.aggregateValue && props.aggregateValue.fieldPath ? props.aggregateValue.fieldPath.getName().toLowerCase() : '...'}
                                </ViewGroupItem>
                        }
                        {
                            props.aggregateValue && props.aggregateValue.aggregate !== Aggregate.Count &&
                                <ViewGroupItem>
                                    <MenuButton
                                        icon="edit"
                                        tooltip={
                                            <LocalizedText
                                                code="Generic.Edit"
                                                value="Wijzigen"
                                            />
                                        }
                                        small
                                    >
                                        <FieldPathSelector
                                            context={context}
                                            onSelect={onChangeFieldPath}
                                        />
                                    </MenuButton>
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                </Input>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(AggregateValueEditor);
