import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import IconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import Popper from '../../../../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import useSwitch from '../../../../../../../../@Util/Switch/useSwitch';
import { primaryColor, textSecondaryColor } from '../../../../../../../../@Resource/Theme/Theme';
import RelationshipDefinitionFilterEditor from './RelationshipDefinitionFilterEditor/RelationshipDefinitionFilterEditor';
import getRelationshipDefinitionFilterDescriptor from '../../../../../../../../@Api/Metadata/RelationshipDefinition/Filter/getRelationshipDefinitionFilterDescriptor';
import { EntityFieldPath } from '../../../../../../Entity/Path/@Model/EntityFieldPath';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';

export interface FilterEditorProps
{
    entityType: EntityType;
    fieldPath?: EntityFieldPath;
}

const FilterEditor: React.FC<FilterEditorProps> =
    props =>
    {
        const { entityType, fieldPath } = props;

        const [ isOpen, open, close ] = useSwitch(false);
        const hasFilter =
            useComputed(
                () =>
                    fieldPath?.relationshipDefinition !== undefined &&
                        getRelationshipDefinitionFilterDescriptor(
                            entityType,
                            fieldPath.relationshipDefinition,
                            fieldPath.isParentRelationship) !== undefined,
                [
                    entityType,
                    fieldPath
                ]);

        if (fieldPath?.relationshipDefinition)
        {
            return <Popper
                reference={
                    <IconButton
                        icon="edit"
                        onClick={open}
                        tooltip={
                            <LocalizedText
                                code="Generic.Edit"
                            />
                        }
                        color={hasFilter ? primaryColor : textSecondaryColor}
                    />
                }
                popper={
                    <Card
                        inset
                    >
                        <RelationshipDefinitionFilterEditor
                            entityType={props.entityType}
                            relationshipDefinition={fieldPath.relationshipDefinition}
                            parent={fieldPath.isParentRelationship}
                            onClose={close}
                        />
                    </Card>
                }
                open={isOpen}
                onClose={close}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(FilterEditor);
