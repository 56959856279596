import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/styles';
import Icon from '../Icon/Icon';

export interface NavigationButtonsProps
{
    onBackClicked: () => void,
    onForwardClicked: () => void
}

const useStyles = makeStyles({
    buttonGroup:
        {
            display: 'flex',
            alignItems: 'center',

        },
    navButton:
        {
            borderRadius: 4,
            cursor: 'pointer',
            color: '#717171 !important',
            border: 'none !important',
            boxShadow: 'none !important',
            padding: '3px 8px',
            fontSize: 12,
            zIndex: 'unset',
            whiteSpace: 'nowrap',
            backgroundColor: '#f3f3f3 !important',
            '&:hover':
                {
                    backgroundColor: '#d3d3d3 !important'
                },
            '&:first-child':
                {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0
                },
            '&:last-child':
                {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                }
        }
});

export const NavigationButtons: React.FC<NavigationButtonsProps> =
    observer(
        ({
             onBackClicked,
             onForwardClicked
        }) =>
        {
            const classes = useStyles();

            return <div
                className={classes.buttonGroup}
            >
                <div
                    className={classes.navButton}
                    onClick={onBackClicked}
                >
                    <Icon
                        icon="chevron_left"
                        size="1.5em"
                    />
                </div>
                <div
                    className={classes.navButton}
                    onClick={onForwardClicked}
                >
                    <Icon
                        icon="chevron_right"
                        size="1.5em"
                    />
                </div>
            </div>
        }
    );