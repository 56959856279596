import { reference } from '../../../@Util/Serialization/Serialization';
import { EntityType } from './EntityType';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { observable } from 'mobx';
import { SelectionNode } from './SelectionNode';
import { Entity } from './Entity';
import EntityValueType from '../../Automation/Value/Type/EntityValueType';
import Parameter from '../../Automation/Parameter/Parameter';

export class EntityNode extends SelectionNode
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'EntityType') @observable.ref entityType: EntityType;
    @observable alias: string;
    @observable.ref parameter: Parameter<EntityValueType>;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(
        entityType: EntityType,
        alias: string
    )
    {
        super();

        this.entityType = entityType;
        this.alias = alias;
        this.parameter =
            new Parameter<EntityValueType>(
                alias,
                new EntityValueType(entityType),
                false,
                alias
            );
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    hashCode(): string
    {
        return `${super.hashCode()},${this.alias}`;
    }

    equals(node: EntityNode): boolean
    {
        return super.equals(node) &&
            this.entityType === node.entityType &&
            this.alias === node.alias;
    }

    matches(entity: Entity): boolean
    {
        return true;
    }

    entityPath(): EntityPath
    {
        throw new Error('Unimplemented');
    }

    descriptor(): any
    {
        return {
            type: 'Entity',
            alias: this.alias,
            entityTypeId: this.entityType.isStaticType() ? undefined : this.entityType.id,
            entityTypeCode: this.entityType.isStaticType() ? this.entityType.code : undefined
        };
    }

    // ----------------------- Private logic ------------------------
}
