import React, { useContext, useMemo } from 'react';
import RichtextEditorContext from '../RichtextEditorContext/RichtextEditorContext';


export interface CollapsibleToolbarProps
{
}

const CollapsibleToolbar: React.FC<CollapsibleToolbarProps> =
    props =>
    {
        const context = useContext(RichtextEditorContext);

        const visible =
            useMemo(
                () => context.showCollapsibleToolbar,
                [
                    context.showCollapsibleToolbar
                ]);


        return <div
            style={{
                display: visible ? 'flex' : 'none',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: 5,
            }}
        >
            {props.children}
        </div>

    };

CollapsibleToolbar.defaultProps =
{

};

export default CollapsibleToolbar;
