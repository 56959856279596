import { observable } from 'mobx';
import Input from './Input';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../Entity/Type/EntityTypeStore';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';
import { EntityPath } from '../../../Entity/Path/@Model/EntityPath';

export default class RelationshipInput extends Input
{
    // ------------------------- Properties -------------------------

    @observable.ref relationshipDefinition: EntityRelationshipDefinition;
    @observable isParent: boolean;

    // ------------------------ Constructor -------------------------


    constructor(entityType: EntityType,
                relationshipDefinition: EntityRelationshipDefinition,
                isParent: boolean)
    {
        super(entityType);

        this.relationshipDefinition = relationshipDefinition;
        this.isParent = isParent;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        const entityType = loadModuleDirectly(EntityTypeStore).getTypeByIdOrCode(descriptor.entityTypeId, descriptor.entityTypeCode);
        const relationshipDefinition = loadModuleDirectly(EntityTypeStore).getRelationshipDefinitionById(descriptor.relationshipDefinitionId);

        if (entityType && relationshipDefinition)
        {
            return new RelationshipInput(
                entityType,
                relationshipDefinition,
                descriptor.isParent);
        }
        else
        {
            return undefined;
        }
    }

    id(): string
    {
        return `${super.id()}:${this.isParent ? 'parent' : 'child'}:${this.relationshipDefinition.id}`;
    }

    getName()
    {
        return this.relationshipDefinition.getName(this.isParent);
    }

    fromType(entityType: EntityType): Input
    {
        return new RelationshipInput(
            entityType,
            this.relationshipDefinition,
            this.isParent
        );
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Relationship',
            relationshipDefinitionId: this.relationshipDefinition.id,
            isParent: this.isParent
        };
    }

    isRequired(): boolean
    {
        return this.relationshipDefinition.isMandatory(this.isParent)
            || this.isRequiredBySetting();
    }

    isComputed(): boolean
    {
        return this.relationshipDefinition.isComputed(this.isParent);
    }

    getValue(entity: Entity): DataObject
    {
        return DataObject.constructFromTypeIdAndValue(
            'Entity',
            entity.getRelatedEntityByDefinition(
                this.isParent,
                this.relationshipDefinition));
    }

    getFieldCode(): string
    {
        const split = this.relationshipDefinition.code.split(':');
        let fieldCode;

        if (split.length === 2)
        {
            if (this.isParent)
            {
                fieldCode = split[0];
            }
            else
            {
                fieldCode = split[1];
            }
        }
        else
        {
            fieldCode = this.relationshipDefinition.code;
        }

        const dotSplit = fieldCode.split('.');

        return dotSplit[dotSplit.length - 1];
    }

    toFieldPath(): EntityFieldPath
    {
        return EntityPath.root(this.entityType)
            .joinTo(
                this.relationshipDefinition,
                this.isParent)
            .field();
    }

    // ----------------------- Private logic ------------------------
}
