import React from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import { TableCell } from '@material-ui/core';
import Column from '../../../Model/Specification/Column';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import styles from './Cell.module.scss';
import FieldView from '../../../../Field/View/FieldView';
import ItemLayoutViewer from '../../../ItemLayout/Viewer/ItemLayoutViewer';
import View from '../../../Model/View';

export interface CellProps
{
    view: View;
    column: Column;
    entity: Entity;
    first?: boolean;
}

const Cell: React.FC<CellProps> =
    props =>
    {
        return <TableCell
            className={classNames(styles.root, props.first && styles.first, props.column.isHidden && styles.hidden)}
        >
            {
                props.column.cellLayout === undefined
                    ? <FieldView
                        entity={props.entity}
                        field={props.column.fieldPath}
                    />
                    : <ItemLayoutViewer
                        parameters={props.view.parameters}
                        entity={props.entity}
                        layout={props.column.cellLayout}
                    />
            }
        </TableCell>;
    };

export default observer(Cell);
