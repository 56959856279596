import { EntityPathNode } from '../EntityPathNode';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { EntityTypeStore } from '../../../Type/EntityTypeStore';
import { EntityPath } from '../EntityPath';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationship } from '../../../../../../@Api/Model/Implementation/EntityRelationship';
import { getModel } from '../../../../../../@Util/TransactionalModelV2';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier, loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/index';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';

export class EntityPathCastNode extends EntityPathNode
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    entityType: EntityType;

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType)
    {
        super();

        this.entityType = entityType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static construct(descriptor: any,
                     entityTypeStore: EntityTypeStore): EntityPathNode
    {
        const entityType = entityTypeStore.getTypeById(descriptor.childEntityTypeId);

        if (entityType)
        {
            return new EntityPathCastNode(entityType);
        }
        else
        {
            throw new Error(`Cast entity type with ID: ${descriptor.childEntityTypeId} not found`);
        }
    }

    id(): string
    {
        return `childEntityType.${this.entityType.id}`;
    }

    code(): string
    {
        return `as:${this.entityType.code}`;
    }

    getName(entityTypeStore: EntityTypeStore): string
    {
        return localizeText('EntityPathCastNode.IfTypeName', 'Indien ${type}', { type: this.entityType.getName().toLowerCase() });
    }

    getEntityType(baseEntityType: EntityType): EntityType
    {
        return this.entityType;
    }

    joinNode(baseEntityPath: EntityPath): EntityPath
    {
        // We no longer allow static nodes
        if (this.entityType.isStaticType())
        {
            return baseEntityPath;
        }
        else
        {
            if (baseEntityPath.entityType.isA(this.entityType))
            {
                return baseEntityPath;
            }
            else
            {
                return baseEntityPath.addNode(this);
            }
        }
    }

    inverseNode(baseEntityType: EntityType): EntityPathNode
    {
        if (this.entityType.isA(baseEntityType))
        {
            return null;
        }
        else
        {
            return new EntityPathCastNode(this.entityType);
        }
    }

    isVirtual()
    {
        return true;
    }

    traverseEntity(entity: Entity,
                   commitContext?: CommitContext,
                   onRelationship?: (relationship: EntityRelationship, isParent: boolean) => void): Entity[]
    {
        if (entity.entityType.isA(this.entityType))
        {
            return [ entity ];
        }
        else
        {
            return [];
        }
    }

    constructEntity(baseEntity: Entity,
                    forceCreation: boolean,
                    addRelationshipToBase: boolean,
                    entityType: EntityType,
                    commitContext?: CommitContext): Entity
    {
        getModel(baseEntity).setEntityType(
            this.entityType,
            loadModuleDirectly(EntityTypeStore),
            commitContext
        );

        return baseEntity;
    }

    descriptor(): any
    {
        return {
            childEntityTypeId: this.entityType.id
        };
    }

    // ----------------------- Private logic ------------------------
}
