import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { EntitySelectionAggregateResult } from '../../../../../Selection/Model/EntitySelectionAggregateResult';
import ExpansionPanel from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Segment from '../../../Segment';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import Header from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import ExpansionHeader from '../../../../../../../../@Future/Component/Generic/Label/Variant/ExpansionHeader/ExpansionHeader';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useResultFilter from '../../../Api/useResultFilter';
import FilterContext from '../../../FilterContext/FilterContext';
import useEntityTypeForFilter from '../../../Api/useEntityTypeForFilter';
import useTranslation from '../../../../../../../../@Service/Localization/Api/useTranslation';
import CategoryContext from '../../../SegmentContext/SegmentContext';
import styles from './Item.module.scss';
import { buttonInset } from '../../../../../../../../@Resource/Theme/Theme';
import { EntitySelectionResult } from '../../../../../Selection/Model/EntitySelectionResult';
import useResultName from '../../../Api/useResultName';
import useResultCount from '../../../Api/useResultCount';
import FavoriteCallbackContext from '../../../../Favorite/FavoriteCallbackContext';
import Button from '../../../../Favorite/Button/Button';
import EntityConstructorButton from '../../../../../Button/EntityConstructorButton/EntityConstructorButton';
import { default as SegmentModel } from '../../../../Model/Segment';
import useNextSegments from '../../../Api/useNextSegments';
import DatasetContext from '../../../../Context/DatasetContext';

export interface ItemProps
{
    entityType: EntityType;
    segment: SegmentModel;
    nextSegments: SegmentModel[];
    child: EntitySelectionAggregateResult;
    idx: number;
    selected?: boolean;
    onSelect: (result?: EntitySelectionResult) => void;
}

const Item: React.FC<ItemProps> =
    props =>
    {
        const { child, idx, onSelect, selected } = props;
        const dataset = useContext(DatasetContext);
        const [ nextSegment, nextNextSegments ] = useNextSegments(props.nextSegments);

        const childFilter =
            useResultFilter(
                dataset,
                props.segment,
                child);
        const childType =
            useEntityTypeForFilter(
                props.entityType,
                childFilter);

        const noValue = useTranslation('Generic.Uncategorized');

        const expand =
            useCallback(
                () =>
                    onSelect(child),
                [
                    onSelect,
                    child
                ]);
        const collapse =
            useCallback(
                () =>
                    onSelect(),
                [
                    onSelect
                ]);

        const name = useResultName(props.segment, child, idx);
        const count = useResultCount(child);
        const favoriteCallback = useContext(FavoriteCallbackContext);

        return <ExpansionPanel
            id={child.id}
            summary={
                <Header
                    title={name || noValue}
                    inset
                    classes={{
                        root: styles.header
                    }}
                >
                    <ExpansionHeader>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={buttonInset}
                            alignment="center"
                        >
                            {
                                favoriteCallback
                                    ?
                                        <ViewGroupItem
                                            className={styles.headerButtons}
                                        >
                                            <Button
                                                segment={props.segment}
                                                filter={childFilter}
                                            />
                                        </ViewGroupItem>
                                    :
                                        <ViewGroupItem
                                            className={styles.headerButtons}
                                        >
                                            <EntityConstructorButton
                                                entityType={childType}
                                            />
                                        </ViewGroupItem>
                            }
                            <ViewGroupItem>
                                {count}
                            </ViewGroupItem>
                        </ViewGroup>
                    </ExpansionHeader>
                </Header>}
            expansion={
                selected &&
                    <FilterContext.Provider
                        value={childFilter}
                    >
                        <CategoryContext.Provider
                            value={props.segment}
                        >
                            <Segment
                                entityType={childType}
                                segment={nextSegment}
                                nextSegments={nextNextSegments}
                                result={child}
                            />
                        </CategoryContext.Provider>
                    </FilterContext.Provider>
            }
            onExpand={expand}
            onCollapse={collapse}
            expanded={selected}
        />;
    };

export default observer(Item);
