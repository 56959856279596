export function mapMap<K, V, U>(map: Map<K, V>,
                                mapper: (value: V) => U): Map<K, U>
{
    const newMap = new Map<K, U>();

    map.forEach(
        (value, key) =>
            newMap.set(
                key,
                mapper(value)));

    return newMap;
}
