import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import FunctionContext from '../FunctionContext';
import Value from '../../Value/Value';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import LocalizedComputationItem from './LocalizedComputationItem';
import EmptyValue from '../../Value/EmptyValue';
import EmptyValueType from '../../Value/Type/EmptyValueType';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import PrimitiveValue from '../../Value/PrimitiveValue';

interface Translation
{
    languageCode: string;
    translation: Value<any, any>;
}

export default class LocalizedTextComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.shallow items: LocalizedComputationItem[];

    // ------------------------ Constructor -------------------------

    constructor(
        items: LocalizedComputationItem[]
    )
    {
        super();

        this.items = items;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        if (this.items.length === 0)
        {
            return EmptyValueType.instance;
        }
        else
        {
            return new PrimitiveValueType(
                DataObject.getTypeById('LocalizedText')
            );
        }
    }

    isAsync(): boolean
    {
        return this.items.some(
            item =>
                item.isAsync()
        );
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const translations: Translation[] =
            await Promise.all(
                this.items.map(
                    async item => ({
                        languageCode: item.languageCode,
                        translation:
                            await safelyApplyFunction(
                                item.computation,
                                context
                            )
                    })
                )
            );

        return this.getTranslationValueFromTranslations(translations);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        const translations: Translation[] =
            this.items.map(
                item => ({
                    languageCode: item.languageCode,
                    translation:
                        safelySynchronousApplyFunction(
                            item.computation,
                            context
                        )
                })
            );

        return this.getTranslationValueFromTranslations(translations);
    }

    getTranslationValueFromTranslations(translations: Translation[])
    {
        const translationsWithString =
            translations
                .map(
                    translation => ({
                        ...translation,
                        translation: translation.translation.getName(),
                    })
                )
                .filter(
                    translation =>
                        translation.translation !== undefined
                        && translation.translation.length > 0
                );

        if (translationsWithString.length === 0)
        {
            return EmptyValue.instance;
        }
        else
        {
            const translationDescriptor =
                translationsWithString.reduce(
                    (a, b) => ({
                        ...a,
                        [b.languageCode]: b.translation,
                    }),
                    {}
                );

            return new PrimitiveValue(
                DataObject.constructFromTypeIdAndValue(
                    'LocalizedText',
                    translationDescriptor
                )
            );
        }
    }

    getName(): string
    {
        return DataObject.getTypeById('LocalizedText').name();
    }

    validate(): Validation[]
    {
        return this.items
            .map(
                item =>
                    item.validate()
            )
            .reduce((a, b) => a.concat(b), []);
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'LocalizedText';
        descriptor.items = this.items.map(item => item.toDescriptor());
    }

    getDependencies(): Dependency[]
    {
        return this.items
            .map(
                item =>
                    item.getDependencies())
            .reduce((a, b) => a.concat(b), []);
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext
    )
    {
        return new LocalizedTextComputation(
            await Promise.all(
                descriptor.items.map(
                    item =>
                        LocalizedComputationItem.fromDescriptor(
                            item,
                            dependencyContext
                        )
                )
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
