import CollaborationClient from '../CollaborationClient';
import { Delta, Selection, State } from './State';
import AwaitingConfirmState from './AwaitingConfirmState';

class SynchronizedState implements State
{
    applyFromClient(client: CollaborationClient,
                    delta: Delta): State
    {
        client.sendDelta(client.version, delta);

        return new AwaitingConfirmState(delta);
    }

    applyFromServer(client: CollaborationClient,
                    delta: Delta): State
    {
        client.applyDelta(delta);

        return this;
    }

    serverAck(client: CollaborationClient): State
    {
        throw new Error('error');
    }

    transformSelection(selection: Selection): Selection
    {
        return selection;
    }

    resend(client: CollaborationClient)
    {

    }
}

const StateSynchronized = new SynchronizedState();

export default StateSynchronized;
