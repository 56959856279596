import { CommitContext } from './CommitContext';
import { Entity } from '../../../Model/Implementation/Entity';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import { EntityRelationship } from '../../../Model/Implementation/EntityRelationship';
import { EntityType } from '../../../Model/Implementation/EntityType';
import { CommitMutationOptions } from './Model/CommitMutationOptions';
import { EntityField } from '../../../Model/Implementation/EntityField';
import { EntityRelationshipDefinition } from '../../../Model/Implementation/EntityRelationshipDefinition';
import { CommitOptions } from '../commitEntity';
import { CommitResult } from './Model/CommitResult';
import { CommitRequest } from './Model/CommitRequest';
import { FileReporter } from '../../../../@Component/Domain/DataObject/Model/DataDescriptor';
import { CommitLock } from './Model/CommitLock';
import { CommitContextOptions } from './Model/CommitContextOptions';

export class CommitContextWithMutationOptions implements CommitContext
{
    // ------------------------- Properties -------------------------

    context: CommitContext;
    mutationOptions?: CommitMutationOptions;

    // ------------------------ Constructor -------------------------

    constructor(
        context: CommitContext,
        mutationOptions?: CommitMutationOptions
    )
    {
        this.context = context;
        this.mutationOptions = mutationOptions;
    }

    // ----------------------- Initialization -----------------------

    // ------------------------ Public logic ------------------------

    public getOptions(): CommitContextOptions
    {
        return this.context.getOptions();
    }

    public getFirstUpdatedEntity(): Entity | undefined
    {
        return this.context.getFirstUpdatedEntity();
    }

    public clear(): void
    {
        this.context.clear();
    }

    public commit(
        options?: Partial<CommitOptions>
    ): Promise<CommitResult>
    {
        return this.context.commit({
            ...options,
            lock: options?.lock ?? this.mutationOptions?.lock,
        });
    }

    public createEntity(
        entityType: EntityType,
        options?: CommitMutationOptions
    ): Entity
    {
        return this.context.createEntity(
            entityType,
            this.getMutationOptions(options)
        );
    }

    public createRelationship(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity: Entity,
        options?: CommitMutationOptions
    ): EntityRelationship
    {
        return this.context.createRelationship(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            this.getMutationOptions(options)
        );
    }

    public deleteEntity(
        entity: Entity,
        options?: CommitMutationOptions
    ): Entity
    {
        return this.context.deleteEntity(
            entity,
            this.getMutationOptions(options)
        );
    }

    public deleteRelationship(
        relationship: EntityRelationship,
        options?: CommitMutationOptions
    ): EntityRelationship
    {
        return this.context.deleteRelationship(
            relationship,
            this.getMutationOptions(options)
        );
    }

    public dispose(): void
    {
        this.context.dispose();
    }

    public getCommitRequest(
        options: Partial<CommitOptions>,
        fileReporter: FileReporter,
        id?: string
    ): CommitRequest
    {
        return this.context.getCommitRequest(
            options,
            fileReporter,
            id
        );
    }

    public getCreatedOrUpdatedRelationshipsByEntity(
        entity: Entity,
        isParent: boolean
    ): EntityRelationship[]
    {
        return this.context.getCreatedOrUpdatedRelationshipsByEntity(
            entity,
            isParent
        );
    }

    public getDeletedRelationshipsByEntity(
        entity: Entity,
        isParent: boolean
    ): EntityRelationship[]
    {
        return this.context.getDeletedRelationshipsByEntity(
            entity,
            isParent
        );
    }

    public getEntity(uuid: string): Entity | undefined
    {
        return this.context.getEntity(uuid);
    }

    public getNewRelationships(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean
    ): EntityRelationship[]
    {
        return this.context.getNewRelationships(
            entity,
            relationshipDefinition,
            isParent
        );
    }

    public getRelationship(uuid: string): EntityRelationship | undefined
    {
        return this.context.getRelationship(uuid);
    }

    public getValue(
        entity: Entity,
        field: EntityField
    ): DataObject | undefined
    {
        return this.context.getValue(
            entity,
            field
        );
    }

    public hasRelationship(relationship: EntityRelationship): boolean
    {
        return this.context.hasRelationship(relationship);
    }

    public hasValue(
        entity: Entity,
        field: EntityField
    ): boolean
    {
        return this.context.hasValue(
            entity,
            field
        );
    }

    public isDirty(): boolean
    {
        return this.context.isDirty();
    }

    public isEntityDirty(entity: Entity): boolean
    {
        return this.context.isEntityDirty(entity);
    }

    public isEntityGraphDirty(
        entity: Entity,
        seenEntityIds?: Set<string>
    ): boolean
    {
        return this.context.isEntityGraphDirty(
            entity,
            seenEntityIds
        );
    }

    public isRelationshipDeleted(relationship: EntityRelationship): boolean
    {
        return this.context.isRelationshipDeleted(relationship);
    }

    public isRelationshipDirty(relationship: EntityRelationship): boolean
    {
        return this.context.isRelationshipDirty(relationship);
    }

    public isLocked(): boolean
    {
        return this.context.isLocked();
    }

    public awaitLock(): Promise<void>
    {
        return this.context.awaitLock();
    }

    public lock(runnable: (lock: CommitLock) => Promise<any>): Promise<any>
    {
        return this.context.lock(runnable);
    }

    public setValue(
        entity: Entity,
        field: EntityField,
        value: DataObject,
        options?: CommitMutationOptions
    ): DataObject
    {
        return this.context.setValue(
            entity,
            field,
            value,
            this.getMutationOptions(options)
        );
    }

    public updateRelationship(
        relationship: EntityRelationship,
        isParent: boolean,
        relatedEntity: Entity,
        options?: CommitMutationOptions
    ): EntityRelationship
    {
        return this.context.updateRelationship(
            relationship,
            isParent,
            relatedEntity,
            this.getMutationOptions(options)
        );
    }

    // ----------------------- Private logic ------------------------

    private getMutationOptions(options?: CommitMutationOptions)
    {
        return {
            ...this.mutationOptions,
            ...options,
        };
    }
}
