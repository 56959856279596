import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';

export class OrganizationController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    register(organizationName: string,
             emailAddress: string,
             password: string,
             firstName: string,
             lastName: string,
             phoneNumber: string,
             languageCode: string,
             gclId: string): Promise<any>
    {
            return new Promise<any>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        '/public/register',
                        Method.Post,
                        {
                            organization_name: organizationName,
                            email_address: emailAddress,
                            password: password,
                            first_name: firstName,
                            last_name: lastName,
                            phone_number: phoneNumber,
                            language_code: languageCode,
                            gclid: gclId
                        }))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    validateRegistration(token: string,
                         emailAddress: string,
                         languageCode: string
                         ): Promise<boolean>
    {
        return new Promise<boolean>(
            (resolve, reject) =>
            {
                this.apiClient
                    .request(
                        new ApiRequest<any>('/public/register/validate',
                            Method.Post,
                            {
                                token: token,
                                email_address: emailAddress //,
                                //language_code: languageCode
                            }))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

    changeOrganizationName(id: number,
                           name: string): Promise<boolean>
    {
        return new Promise<boolean>(
            (resolve, reject) =>
                this.apiClient
                    .request(
                        new ApiRequest<boolean>(`/organization/${id}/update`,
                            Method.Post,
                            {
                                name: name
                            }
                        )
                    )
                    .then(
                        result =>
                            resolve(result)
                    )
                    .catch(
                        reason =>
                            reject(reason)
                    )
        );
    }
}
