import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { ListQueryEditorProps } from '../ListQueryEditor';
import { EntityFieldPath } from '../../../../../../../../../../Path/@Model/EntityFieldPath';
import ViewGroup from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Divider from '../../../../../../../../../../../../../@Future/Component/Generic/Divider/Divider';
import HoverCardBottom from '../../../../../../../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import ListQueryJoinPathEditor from './ListQueryJoinPathEditor';
import { SimpleFieldPathSelector } from '../../../../../../../../../../Path/FieldPathEditor/Simple/Selector/SimpleFieldPathSelector';
import LocalizedText from '../../../../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface ListQueryJoinPathsEditorProps extends ListQueryEditorProps
{

}

const ListQueryJoinPathsEditor: React.FC<ListQueryJoinPathsEditorProps> =
    props =>
    {
        const { query } = props;
        const selectFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                            query.joinPaths.push(fieldPath.path)
                    ),
                [
                    query,
                ]
            );

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <ol>
                    {
                        query.joinPaths.map(
                            joinPath =>
                                <li
                                    key={joinPath.id}
                                >
                                    <ListQueryJoinPathEditor
                                        {...props}
                                        joinPath={joinPath}
                                    />
                                </li>
                        )
                    }
                </ol>
            </ViewGroupItem>
            <ViewGroupItem>
                <Divider />
                <SimpleFieldPathSelector
                    entityType={query.entityType}
                    onSelect={selectFieldPath}
                    view={
                        ({ open }) =>
                            <HoverCardBottom
                                onClick={open}
                            >
                                <LocalizedText
                                    code="Query.AddPath"
                                    value="Pad toevoegen"
                                />
                            </HoverCardBottom>
                    }
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(ListQueryJoinPathsEditor);
