import FieldInput from '../FieldInput';
import RelationshipInput from '../RelationshipInput';

export default function resolveInputFromDescriptor(descriptor: any)
{
    switch (descriptor.type)
    {
        case 'Field':
            return FieldInput.fromDescriptor(descriptor);

        case 'Relationship':
            return RelationshipInput.fromDescriptor(descriptor);
    }
}
