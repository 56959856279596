import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { default as TypeMappingModel } from '../../../Model/TypeMapping';
import SourceFieldMapping from '../../../Model/FieldMapping/SourceField/SourceFieldMapping';
import { default as GenericInput, LabelPosition } from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Selectbox from '../../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import FileSpecificationContext from '../../../Context/FileSpecificationContext';
import { computed, IObservableArray, runInAction } from 'mobx';
import ValueFieldMapping from '../../../Model/FieldMapping/Constant/ValueFieldMapping';
import uuid from '../../../../../../../@Util/Id/uuid';
import Input from '../../../../Input/Input';
import ImportResultContext from '../../../Context/ImportResultContext';
import ViewerEntityContext from '../../../../Viewer/Context/ViewerEntity/ViewerEntityContext';
import resolveNewSourceFieldMapping from '../../../Model/FieldMapping/Api/resolveNewSourceFieldMapping';
import ValueToEntitySourceFieldMapping from '../../../Model/FieldMapping/ValueToEntity/ValueToEntitySourceFieldMapping';
import ValueToEntityMapping from './ValueToEntityMapping/ValueToEntityMapping';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import OverrideInputContext from '../../../../Input/OverrideInputContext';
import EntityFieldMapping from '../../../Model/FieldMapping/Constant/EntityFieldMapping';
import ValueToValueSourceFieldMapping from '../../../Model/FieldMapping/ValueToValue/ValueToValueSourceFieldMapping';
import ValueToValueMapping from './ValueToValueMapping/ValueToValueMapping';
import search from '../../../../../../../@Util/String/search';
import getFieldSpecificSourceFieldMappingTypes from '../../../Api/getFieldSpecificSourceFieldMappingTypes';
import StaticSelectbox from '../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import SourceField from '../../../Model/SourceField';
import useTypes from '../../../../Type/Api/useTypes';
import MappingEntityTypeEditor from './MappingEntityTypeEditor/MappingEntityTypeEditor';
import ValueContainer from './ValueContainer/ValueContainer';
import RelationshipFieldMapping from '../../../Model/FieldMapping/Relationship/RelationshipFieldMapping';
import resolveInputFromFieldPath from '../../../../../Multiplayer/Model/Input/Api/resolveInputFromFieldPath';
import IconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import resolveValueToValueSourceFieldMapping from '../../../Model/FieldMapping/Api/resolveValueToValueSourceFieldMapping';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import styles from './FieldMapping.module.scss';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import ComputationFieldMapping from '../../../Model/FieldMapping/Computation/ComputationFieldMapping';
import EmptyValue from '../../../../../../../@Api/Automation/Value/EmptyValue';
import { ComputationFieldMappingEditor } from './Computation/ComputationFieldMappingEditor';

export interface FieldMappingProps
{
    typeMapping: TypeMappingModel;
    targetFieldPath: EntityFieldPath;
    entity: Entity;
    commitContext: CommitContext;
    labelPosition?: LabelPosition;
}

const FieldMapping: React.FC<FieldMappingProps> =
    props =>
    {
        const types = useTypes();

        const fieldMapping =
            useComputed(
                () =>
                    props.typeMapping
                        .fieldMappingByTargetFieldPathId
                        .get(props.targetFieldPath.id),
                [
                    props.targetFieldPath
                ]);

        const fileSpecification = useContext(FileSpecificationContext);
        const noneOption =
            useMemo(
                () => ({
                    type: 'None',
                    id: 'None',
                    label: '...'
                }),
                []);
        const fieldSpecificOptions =
            useMemo(
                () =>
                    getFieldSpecificSourceFieldMappingTypes(props.targetFieldPath),
                [
                    props.targetFieldPath
                ]);
        const valueOption =
            useMemo(
                () => ({
                    type: 'EntityOrValue',
                    id: 'EntityOrValue',
                    label: localizeText('Import.FieldMapping.DefaultValue', 'Standaardwaarde')
                }),
                []);
        const computationOption =
            useMemo(
                () => ({
                    type: 'Computation',
                    id: 'Computation',
                    label: localizeText('Import.FieldMapping.Computation', 'Formule')
                }),
                []);
        const sourceFieldOptions =
            useMemo(
                () =>
                    (fileSpecification ? fileSpecification.fields : [])
                        .map(
                            sourceField =>
                                ({
                                    type: 'SourceField',
                                    id: `SourceField.${sourceField.id}`,
                                    label: localizeText(
                                        'Import.FieldMapping.ColumnLabel',
                                        'Kolom: ${name}',
                                        {
                                            name: sourceField.name
                                        }),
                                    field: sourceField
                                })),
                [
                    fileSpecification
                ]);
        const selectedOption =
            useComputed(
                () =>
                {
                    if (fieldMapping)
                    {
                        if (fieldMapping instanceof ComputationFieldMapping)
                        {
                            return computationOption;
                        }
                        else if (fieldMapping instanceof SourceFieldMapping)
                        {
                            return fieldSpecificOptions.find(
                                option =>
                                    option.constructor === fieldMapping.constructor)
                                || sourceFieldOptions.find(
                                    option =>
                                        option.field.id === fieldMapping.sourceFieldId)
                                || noneOption;
                        }
                        else
                        {
                            return valueOption;
                        }
                    }
                    else
                    {
                        return noneOption;
                    }
                },
                [
                    fieldMapping,
                    computationOption,
                    valueOption,
                    sourceFieldOptions,
                    fieldSpecificOptions,
                    noneOption
                ]);

        const typeLoader =
            useCallback(
                query =>
                    Promise.resolve([
                        noneOption,
                        valueOption,
                        computationOption,
                        ...fieldSpecificOptions,
                        ...sourceFieldOptions
                    ].filter(
                        option =>
                            search(query, option.label))),
                [
                    noneOption,
                    valueOption,
                    computationOption,
                    fieldSpecificOptions,
                    sourceFieldOptions
                ]);

        const typeFormatter =
            useCallback(
                (option: any) =>
                    option.label,
                []);

        const idResolver =
            useCallback(
                (option: any) =>
                    option.id,
                []);

        const importEntity = useContext(ViewerEntityContext);

        const onChangeType =
            useCallback(
                (option: any) =>
                    runInAction(
                        () =>
                        {
                            const fieldMappings = props.typeMapping.fieldMappings as IObservableArray;

                            if (fieldMapping)
                            {
                                fieldMappings.remove(fieldMapping);
                            }

                            switch (option.type)
                            {
                                case 'None':
                                    break;

                                case 'EntityOrValue':
                                    if (props.targetFieldPath.isRelationship)
                                    {
                                        fieldMappings.push(
                                            new EntityFieldMapping(
                                                fieldMapping ? fieldMapping.id : uuid(),
                                                props.targetFieldPath,
                                                props.entity
                                                    .getRelatedEntitiesByDefinition(
                                                        props.targetFieldPath.isParentRelationship,
                                                        props.targetFieldPath.relationshipDefinition,
                                                        props.commitContext
                                                    )
                                                    .map(
                                                        relatedEntity =>
                                                            relatedEntity.uuid)
                                                    .find(id => id !== undefined)));
                                    }
                                    else
                                    {
                                        fieldMappings.push(
                                            new ValueFieldMapping(
                                                fieldMapping ? fieldMapping.id : uuid(),
                                                props.targetFieldPath,
                                                props.entity
                                                    .getDataObjectValueByField(
                                                        props.targetFieldPath.field,
                                                        undefined,
                                                        props.commitContext
                                                    )
                                            )
                                        );
                                    }

                                    break;

                                case 'Computation':
                                    fieldMappings.push(
                                        new ComputationFieldMapping(
                                            fieldMapping ? fieldMapping.id : uuid(),
                                            props.targetFieldPath,
                                            EmptyValue.instance
                                        )
                                    );

                                    break;

                                case 'SourceField':
                                    resolveNewSourceFieldMapping(
                                        importEntity,
                                        props.targetFieldPath,
                                        option.field,
                                        fieldMapping ? fieldMapping.id : uuid())
                                        .then(
                                            sourceFieldMapping =>
                                                runInAction(
                                                    () =>
                                                        fieldMappings.push(sourceFieldMapping)
                                                )
                                        );

                                    break;
                            }

                            if (option.type.startsWith('SourceField.'))
                            {
                                fieldMappings.push(
                                    new option.constructor(
                                        uuid(),
                                        props.targetFieldPath,
                                        undefined));
                            }
                        }),
                [
                    props.typeMapping,
                    fieldMapping,
                    props.targetFieldPath,
                    props.entity,
                    importEntity
                ]);

        useEffect(
            () =>
            {
                if (fieldMapping instanceof EntityFieldMapping)
                {
                    if (fieldMapping.targetFieldPath.isRelationship)
                    {
                        return computed(
                            () =>
                                fieldMapping.targetFieldPath.path.traverseEntity(
                                    props.entity,
                                    props.commitContext
                                )
                        )
                            .observe(
                                change =>
                                {
                                    runInAction(
                                        () =>
                                        {
                                            fieldMapping.entityId = change.newValue.map(e => e.uuid).find(() => true);
                                        });
                                });
                    }
                }
                else if (fieldMapping instanceof ValueFieldMapping)
                {
                    if (fieldMapping.targetFieldPath.isField)
                    {
                        return computed(
                            () =>
                            {
                                const value =
                                    fieldMapping.targetFieldPath.getDataObjectValue(
                                        props.entity,
                                        props.commitContext
                                    );

                                if (value)
                                {
                                    return value.value;
                                }
                                else
                                {
                                    return undefined;
                                }
                            })
                            .observe(
                                change =>
                                {
                                    runInAction(
                                        () =>
                                        {
                                            if (change.newValue)
                                            {
                                                fieldMapping.value =
                                                    DataObject.constructFromValue(
                                                        fieldMapping.targetFieldPath.field.dataObjectSpecification,
                                                        change.newValue);
                                            }
                                            else
                                            {
                                                fieldMapping.value = undefined;
                                            }
                                        });
                                });
                    }
                }
            },
            [
                props.entity,
                props.commitContext,
                fieldMapping,
            ]);

        const importResult = useContext(ImportResultContext);

        const importResultEntity =
            useComputed(
                () =>
                    importResult && importResult.targetInstanceByMappingId.get(props.typeMapping.id),
                [
                    importResult,
                    props.typeMapping
                ]);

        const importValue =
            useComputed(
                () =>
                {
                    if (importResultEntity)
                    {
                        if (props.targetFieldPath.isField)
                        {
                            const value =
                                props.targetFieldPath.getDataObjectValue(
                                    importResultEntity,
                                    props.commitContext
                                );

                            if (value)
                            {
                                return value.toString();
                            }
                            else
                            {
                                return undefined;
                            }
                        }
                        else
                        {
                            return props.targetFieldPath.path.traverseEntity(
                                importResultEntity,
                                props.commitContext
                            )
                                .map(
                                    relatedEntity =>
                                        relatedEntity.getName(props.commitContext)
                                )
                                .join(', ');
                        }
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    importResultEntity,
                    props.targetFieldPath,
                    props.commitContext,
                ]);

        const sourceFieldSelectboxOptions =
            useMemo(
                () =>
                    fileSpecification
                        ?
                            fileSpecification.fields
                                .map(
                                    sourceField => ({
                                        id: sourceField.id,
                                        label: localizeText(
                                            'Import.FieldMapping.ColumnLabel',
                                            'Kolom: ${name}',
                                            {
                                                name: sourceField.name
                                            }),
                                        value: sourceField
                                    }))
                        :
                            [],
                [
                    fileSpecification
                ]);

        const sourceField =
            useComputed(
                () =>
                    fieldMapping
                    && fieldMapping instanceof SourceFieldMapping
                    && fieldMapping.sourceFieldId
                    && fileSpecification
                    && fileSpecification.fieldById.get(fieldMapping.sourceFieldId),
                [
                    fieldMapping,
                    fileSpecification
                ]);

        const setSourceField =
            useCallback(
                (sourceField?: SourceField) =>
                    runInAction(
                        () =>
                            (fieldMapping as SourceFieldMapping).sourceFieldId = sourceField?.id),
                [
                    fieldMapping
                ]);

        const targetField =
            useComputed(
                () =>
                    resolveInputFromFieldPath(props.targetFieldPath),
                [
                    props.targetFieldPath
                ]);

        const manuallySpecifyValueMappings =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            const fieldMappings = props.typeMapping.fieldMappings as IObservableArray;

                            if (fieldMapping)
                            {
                                fieldMappings.remove(fieldMapping);
                            }

                            resolveValueToValueSourceFieldMapping(
                                importEntity,
                                props.targetFieldPath,
                                fileSpecification.fieldById.get(
                                    (fieldMapping as SourceFieldMapping).sourceFieldId),
                                fieldMapping ? fieldMapping.id : uuid())
                                .then(
                                    sourceFieldMapping =>
                                        runInAction(
                                            () =>
                                                fieldMappings.push(sourceFieldMapping)));
                        }),
                [
                    props.typeMapping,
                    props.targetFieldPath,
                    fieldMapping
                ]);

        if (fieldMapping instanceof RelationshipFieldMapping)
        {
            return null;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                <ViewGroupItem>
                    <GenericInput
                        label={`${props.targetFieldPath.getName()} ${targetField.isRequired() ? '*' : ''}`}
                        labelPosition={props.labelPosition || 'left'}
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={5}
                            alignment="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <Selectbox
                                    key={fileSpecification ? fileSpecification.fields.length : 'undefined'}
                                    load={typeLoader}
                                    onChange={onChangeType}
                                    formatOption={typeFormatter}
                                    idResolver={idResolver}
                                    value={selectedOption}
                                    disableUnderline
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <div
                                    className={
                                        classNames(
                                            styles.manuallySpecifyValueMappings,
                                            fieldMapping
                                            && fieldMapping.constructor === SourceFieldMapping
                                            && props.targetFieldPath.isField
                                            && styles.show)
                                    }
                                >
                                    <IconButton
                                        icon="call_split"
                                        tooltip={
                                            <LocalizedText
                                                code="Import.FieldMapping.ManualValueInput"
                                                value="Handmatig waardes invoeren"
                                            />
                                        }
                                        onClick={manuallySpecifyValueMappings}
                                    />
                                </div>
                            </ViewGroupItem>
                            {
                                (fieldMapping instanceof ValueFieldMapping || fieldMapping instanceof EntityFieldMapping) &&
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        <OverrideInputContext.Provider
                                            value={undefined}
                                        >
                                            <ValueContainer>
                                                {
                                                    fieldMapping.targetFieldPath.field === types.Entity.Field.Type
                                                        ?
                                                            <MappingEntityTypeEditor
                                                                typeMapping={props.typeMapping}
                                                                value={
                                                                    props.entity.getDataObjectValueByField(
                                                                        types.Entity.Field.Type,
                                                                        undefined,
                                                                        props.commitContext
                                                                    )
                                                                }
                                                                entity={props.entity}
                                                            />
                                                        :
                                                            <Input
                                                                entity={props.entity}
                                                                field={fieldMapping.targetFieldPath}
                                                                labelPosition="none"
                                                                doAutocommit={false}
                                                                commitContext={props.commitContext}
                                                            />
                                                }
                                            </ValueContainer>
                                        </OverrideInputContext.Provider>
                                    </ViewGroupItem>
                            }
                            {
                                fieldMapping instanceof ComputationFieldMapping &&
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <ComputationFieldMappingEditor
                                        fieldMapping={fieldMapping}
                                    />
                                </ViewGroupItem>
                            }
                            {
                                !(fieldMapping instanceof ValueFieldMapping || fieldMapping instanceof EntityFieldMapping) &&
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        {
                                            !(fieldMapping instanceof ValueFieldMapping || fieldMapping instanceof EntityFieldMapping) && importValue &&
                                                <ValueContainer>
                                                    {importValue}
                                                </ValueContainer>
                                        }
                                    </ViewGroupItem>
                            }
                        </ViewGroup>
                        {/*{*/}
                        {/*    !(fieldMapping instanceof ValueFieldMapping || fieldMapping instanceof EntityFieldMapping) && importValue &&*/}
                        {/*        <ValueContainer>*/}
                        {/*            {importValue}*/}
                        {/*        </ValueContainer>*/}
                        {/*}*/}
                    </GenericInput>
                </ViewGroupItem>
                {
                    fieldMapping instanceof SourceFieldMapping
                    && selectedOption.type.startsWith('SourceField.')
                    &&
                    <ViewGroupItem>
                        <GenericInput
                            label={
                                <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <LocalizedText
                                        code="Import.FieldMapping.FromColumn"
                                        value="- Uit kolom"
                                    />
                                </span>
                            }
                            labelPosition="left"
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={5}
                                alignment="center"
                            >
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <StaticSelectbox
                                        options={sourceFieldSelectboxOptions}
                                        onChange={setSourceField}
                                        value={sourceField}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem
                                    ratio={1}
                                />
                            </ViewGroup>
                        </GenericInput>
                    </ViewGroupItem>
                }
                {
                    fieldMapping instanceof ValueToEntitySourceFieldMapping &&
                        fieldMapping.valueMappings.map(
                            valueMapping =>
                                <ViewGroupItem
                                    key={valueMapping.id}
                                >
                                    <ValueToEntityMapping
                                        fieldMapping={fieldMapping}
                                        valueMapping={valueMapping}
                                    />
                                </ViewGroupItem>)
                }
                {
                    fieldMapping instanceof ValueToValueSourceFieldMapping &&
                        fieldMapping.valueMappings.map(
                            valueMapping =>
                                <ViewGroupItem
                                    key={valueMapping.id}
                                >
                                    <ValueToValueMapping
                                        typeMapping={props.typeMapping}
                                        fieldMapping={fieldMapping}
                                        valueMapping={valueMapping}
                                    />
                                </ViewGroupItem>)
                }
            </ViewGroup>;
        }
    };

export default observer(FieldMapping);
