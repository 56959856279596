import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import IconButton from '../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import updateEntityFromCoC from './updateEntityFromCoC';
import { commitEntityWithContext } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import useDialog from '../../../../@Service/Navigation/Page/Hooks/useDialog';
import Search from './CocSearch/CocSearch';
import useTypes from '../Type/Api/useTypes';
import hasPack from '../../../../@Api/Pack/hasPack';
import useExternalIdsFromEntity from '../../../../@Api/Entity/Bespoke/ExternalId/useExternalIdsFromEntity';
import { useExpansion } from '../Selection/Api/useExpansion';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';

export interface LinkCoCButtonProps
{
    relation: Entity;
    relationship?: Entity;
    commitContext?: CommitContext
}

export const LinkCoCButton: React.FC<LinkCoCButtonProps> = observer(
    ({
        relation,
        relationship,
        commitContext
    }) =>
    {
        const types = useTypes();

        const [ foundExternalIds, isLoading ] =
            useExternalIdsFromEntity(
                relation,
                'CocService',
                'Organization'
            );

        const [ loadingAddresses ] =
            useExpansion(
                types.Relation.Organization.Type,
                rootPath =>
                    [
                        types.Relation.Organization.RelationshipDefinition.VisitingAddress,
                        types.Relation.Organization.RelationshipDefinition.PostalAddress,
                    ].flatMap(
                        addressType =>
                            [
                                types.Address.RelationshipDefinition.Country,
                                types.Address.RelationshipDefinition.State
                            ].map(
                                countryOrState =>
                                rootPath
                                    .joinTo(
                                        addressType,
                                        false)
                                    .joinTo(
                                        countryOrState,
                                        false
                                    )
                            )
                    ),
                () => [ relation ],
                [
                    types,
                    relation
                ]
            );

        const onSelect = useCallback(
            async (result: any) =>
            {
                // Clear fields to force change
                relation.setValueByField(
                    types.Relation.Organization.Field.ChamberOfCommerceNumber,
                    undefined,
                    undefined,
                    undefined,
                    commitContext
                );
                relation.setValueByField(
                    types.Relation.Organization.Field.EstablishmentNumber,
                    undefined,
                    undefined,
                    undefined,
                    commitContext
                );

                await commitEntityWithContext(relation, commitContext)
                    .then(() =>
                    {
                        if (!loadingAddresses)
                        {
                            updateEntityFromCoC(result, relation, relationship, commitContext)
                                .then(() =>
                                {
                                    commitEntityWithContext(relation, commitContext)
                                })
                        }
                    })
            },
            [
                relation,
                commitContext,
                loadingAddresses
            ]
        );

        const [openSearch] =
            useDialog(close =>
                <Search
                    entity={relation}
                    onClose={close}
                    onSelect={onSelect}
                />,
                [
                    relation, onSelect
                ]
        );

        if (hasPack('AdvancedCoC') &&
            relation.entityType.isA(types.Relation.Organization.Type) &&
            !isLoading &&
            foundExternalIds && foundExternalIds.length === 0)
        {
            return <IconButton
                onClick={openSearch}
                icon="link"
                tooltip={
                    <LocalizedText
                        code="LinkCoCButton.LinkToCoC"
                        value="Koppelen aan KVK data"
                    />
                }
            />
        }
        else
        {
            return null;
        }
    })