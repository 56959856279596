import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import Relationship from '../Relationship/Relationship';
import useFirstParentRelationship from '../../../../../../@Api/Entity/Bespoke/Relation/useFirstParentRelationship';

export interface RelationProps
{
    entity: Entity;
}

const Relation: React.FC<RelationProps> =
    props =>
    {
        const firstParentRelationship = useFirstParentRelationship(props.entity);

        if (firstParentRelationship)
        {
            return <Relationship
                entity={firstParentRelationship}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(Relation);
