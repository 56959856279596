import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { ValueEditorProps } from '../../ValueEditor';
import EntityValueType from '../../../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import EntityValue from '../../../../../../../../../../@Api/Automation/Value/EntityValue';
import { EntitySelectionBuilder } from '../../../../../../../Selection/Builder/EntitySelectionBuilder';
import Selectbox from '../../../../../../../Selectbox/Selectbox';
import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import localizeText from '../../../../../../../../../../@Api/Localization/localizeText';

export interface EntityEditorProps extends ValueEditorProps<EntityValueType, EntityValue>
{

}

const EntityEditor: React.FC<EntityEditorProps> =
    props =>
    {
        const selections =
            useComputed(
                () => [
                    new EntitySelectionBuilder(props.type.type).build()
                ],
                [
                    props.type
                ]);

        const onChange =
            useCallback(
                (entity: Entity) =>
                    props.onChange(
                        entity
                            ?
                                new EntityValue(entity)
                            :
                                undefined),
                [
                    props.onChange
                ]);

        return <Selectbox
            selections={selections}
            value={props.value?.value}
            onChange={onChange}
            clearable
            emptyOption={
                props.filter
                    ?
                        localizeText(
                            'View.AllOfType',
                            'Alle ${typeName}',
                            {
                                typeName: props.type.type.getName(true).toLowerCase()
                            }
                        )
                    :
                        props.type.type.bespoke.getEmptyOption()
            }
            hideCaptionInValue={props.filter}
            autoFocus={props.autoFocus}
        />;
    };

export default observer(EntityEditor);
