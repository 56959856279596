import { Entity } from '../../Model/Implementation/Entity';
import { consoleLog } from '../../../@Future/Util/Logging/consoleLog';
import { EntityField } from '../../Model/Implementation/EntityField';
import { isPropertyDirty, isTransactionalModel } from '../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { EntityRelationshipDefinition } from '../../Model/Implementation/EntityRelationshipDefinition';
import isFieldInEntityValid from './isFieldInEntityValid';
import { EntityValidationOptions, getEntityValidationOptionsWithDefaults } from './EntityValidationOptions';
import { EntityValidationResult } from './EntityValidationResult';
import localizeText from '../../Localization/localizeText';
import FieldInput from '../../../@Component/Domain/Multiplayer/Model/Input/FieldInput';
import validateRelationshipDefinitionInEntity from './validateRelationshipDefinitionInEntity';
import { EntityRelationship } from '../../Model/Implementation/EntityRelationship';

export default function validateEntity(entity: Entity,
                                       partialOptions?: Partial<EntityValidationOptions>): EntityValidationResult
{
    const options = getEntityValidationOptionsWithDefaults(entity, partialOptions);

    return validateEntityWithOptions(entity, options);
}

export function validateEntityWithOptions(entity: Entity,
                                          options: EntityValidationOptions): EntityValidationResult
{
    const { isValidByEntityUuid, objectPath, isDebug, path } = options;

    if (isValidByEntityUuid.has(entity.uuid))
    {
        return {
            isValid: isValidByEntityUuid.get(entity.uuid),
            messages: []
        };
    }

    isValidByEntityUuid.set(entity.uuid, true);

    if (isDebug)
    {
        consoleLog('Validating entity', path.code, entity.name, entity, objectPath);
    }

    if (shouldValidateEntity(entity, options))
    {
        return checkEntity(entity, options);
    }
    else
    {
        return {
            isValid: true,
            messages: []
        };
    }
}

function shouldValidateEntity(
    entity: Entity,
    options: EntityValidationOptions
)
{
    if (options.commitContext)
    {
        return options.commitContext.isEntityDirty(entity);
    }
    else
    {
        return entity.isManaged;
    }
}

function checkEntity(entity: Entity,
                     options: EntityValidationOptions): EntityValidationResult
{
    return checkInstantiability(entity, options)
        || checkFields(entity, options)
        || checkRelationshipDefinitions(entity, options)
        || { isValid: true, messages: [] };
}

function checkInstantiability(entity: Entity,
                              options: EntityValidationOptions): EntityValidationResult | undefined
{
    if (!entity.entityType.isInstantiableByInheritance())
    {
        if (options.isDebug)
        {
            consoleLog('non-instantiable entity type', options.path.code, entity, options.objectPath, entity.entityType.code);
        }

        return {
            isValid: false,
            messages: [
                {
                    type: 'NotInstantiable',
                    entity: entity,
                    message: localizeText('EntityValidation.NotInstantiable', `${entity.entityType.getName()} is niet instantieerbaar`)
                }
            ]
        };
    }
}

function checkFields(entity: Entity,
                     options: EntityValidationOptions): EntityValidationResult | undefined
{
    const invalidFields = getInvalidFieldsInEntity(entity, options);

    if (invalidFields.length > 0)
    {
        // console.warn(`[AutoCommit] Invalid values: ${entity.invalidValues.map(v => v.field.code).join(', ')}`);

        if (options.isDebug)
        {
            consoleLog(
                `invalid values ${invalidFields.map(f => f.code).join()}`,
                options.path.code,
                entity,
                options.objectPath,
                invalidFields);
        }

        return {
            isValid: false,
            messages:
                invalidFields.map(
                    invalidField => ({
                        type: 'MissingField',
                        entity: entity,
                        field:
                            new FieldInput(
                                entity.entityType,
                                invalidField),
                        message:
                            localizeText(
                                'EntityValidation.MissingField',
                                '${fieldName} is verplicht',
                                {
                                    fieldName: invalidField.getName()
                                })
                    }))
        };
    }
}

function checkRelationshipDefinitions(entity: Entity,
                                      options: EntityValidationOptions): EntityValidationResult | undefined
{
    return checkRelationshipDefinitionsForSide(entity, true, options)
        || checkRelationshipDefinitionsForSide(entity, false, options);
}

function checkRelationshipDefinitionsForSide(entity: Entity,
                                             isParent: boolean,
                                             options: EntityValidationOptions): EntityValidationResult | undefined
{
    for (const relationshipDefinition of getRelationshipDefinitionsToCheckValidityFor(entity, isParent, options))
    {
        const result =
            checkRelationshipDefinitionForSide(
                entity,
                relationshipDefinition,
                isParent,
                options);

        if (result)
        {
            return result;
        }
    }
}

function checkRelationshipDefinitionForSide(entity: Entity,
                                            relationshipDefinition: EntityRelationshipDefinition,
                                            isParent: boolean,
                                            options: EntityValidationOptions): EntityValidationResult | undefined
{
    const result =
        validateRelationshipDefinitionInEntity(
            entity,
            relationshipDefinition,
            isParent,
            {
                ...options,
                path:
                    options.path.joinTo(
                        relationshipDefinition,
                        isParent
                    )
            });

    if (result.isValid)
    {
        return undefined;
    }
    else
    {
        return result;
    }
}

function getRelationshipDefinitionsToCheckValidityFor(entity: Entity,
                                                      isParent: boolean,
                                                      options: EntityValidationOptions)
{
    return entity.entityType
        .getInheritedRelationshipDefinitions(isParent)
        .filter(
            relationshipDefinition =>
                doCheckRelationshipDefinitionForValidity(
                    entity,
                    relationshipDefinition,
                    isParent,
                    options
                )
        );
}

function getInvalidFieldsInEntity(entity: Entity,
                                  options: EntityValidationOptions)
{
    return entity
        .getEditableFields()
        .filter(
            field =>
                doCheckFieldForValidity(entity, field, options)
                && !isFieldInEntityValid(entity, field, options.commitContext));
}

function doCheckFieldForValidity(entity: Entity,
                                 field: EntityField,
                                 options: EntityValidationOptions): boolean
{
    if (entity.isNew())
    {
        return true;
    }
    else
    {
        return isFieldMutated(entity, field, options);
    }
}

function isFieldMutated(entity: Entity,
                        field: EntityField,
                        options: EntityValidationOptions): boolean
{
    if (options.commitContext)
    {
        return options.commitContext.hasValue(entity, field);
    }
    else
    {
        const value = entity.getDataObjectValueByField(field);

        return isTransactionalModel(entity)
            && isTransactionalModel(value)
            && isPropertyDirty(
                value,
                'value');
    }
}

function doCheckRelationshipDefinitionForValidity(entity: Entity,
                                                  relationshipDefinition: EntityRelationshipDefinition,
                                                  isParent: boolean,
                                                  options: EntityValidationOptions): boolean
{
    if (entity.isNew())
    {
        return true;
    }
    else
    {
        return isRelationshipDefinitionMutated(
            entity,
            relationshipDefinition,
            isParent,
            options
        );
    }
}

function isRelationshipDefinitionMutated(entity: Entity,
                                         relationshipDefinition: EntityRelationshipDefinition,
                                         isParent: boolean,
                                         options: EntityValidationOptions): boolean
{
    return getDeletedRelationshipsByDefinition(entity, relationshipDefinition, isParent, options).length > 0
        || getCurrentRelationshipsByDefinition(entity, relationshipDefinition, isParent, options)
            .some(
                relationship =>
                {
                    if (options.commitContext)
                    {
                        return options.commitContext.hasRelationship(relationship);
                    }
                    else
                    {
                        return isTransactionalModel(relationship)
                            && isPropertyDirty(
                                relationship,
                                isParent ? 'parentEntity' : 'childEntity');
                    }
                }
            );
}

function getDeletedRelationshipsByDefinition(
    entity: Entity,
    relationshipDefinition: EntityRelationshipDefinition,
    isParent: boolean,
    options: EntityValidationOptions
): EntityRelationship[]
{
    if (options.commitContext)
    {
        return options.commitContext.getDeletedRelationshipsByEntity(
            entity,
            isParent
        ).filter(
            relationship =>
                relationship.definition === relationshipDefinition
        );
    }
    else
    {
        return entity.getDeletedRelationshipsByDefinition(
            isParent,
            relationshipDefinition
        );
    }
}

function getCurrentRelationshipsByDefinition(
    entity: Entity,
    relationshipDefinition: EntityRelationshipDefinition,
    isParent: boolean,
    options: EntityValidationOptions
): EntityRelationship[]
{
    return entity.getRelationshipsByDefinition(
        isParent,
        relationshipDefinition,
        options.commitContext
    );
}
