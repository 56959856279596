import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../../@Api/Model/Implementation/EntityType';
import FunctionContext from '../../../../../../../../../@Api/Automation/Function/FunctionContext';
import CompositeTriggerElement from '../../../../../../../../../@Api/Automation/Trigger/CompositeTriggerElement';
import TriggerEditor from '../TriggerEditor';
import ParameterDictionary from '../../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';

export interface CompositeTriggerElementProps
{
    entityType: EntityType;
    trigger: CompositeTriggerElement;
    context: FunctionContext;
    idx: number;
    onDelete: (trigger: CompositeTriggerElement) => void;
}

const CompositeTriggerElementEditor: React.FC<CompositeTriggerElementProps> =
    props =>
    {
        const onDelete =
            useCallback(
                () =>
                    props.onDelete(props.trigger),
                [
                    props.onDelete
                ]);
        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        ParameterDictionary.union(
                            props.context.parameterDictionary,
                            new ParameterDictionary(
                                props.trigger.trigger.getParameters()
                                    .filter(
                                        parameter =>
                                            !props.context.parameterDictionary.hasParameterId(parameter.id)
                                    )
                            )
                        ),
                        props.context.parameterAssignment
                    ),
                [
                    props.context,
                    props.trigger,
                ]
            );

        return <TriggerEditor
            entityType={props.entityType}
            trigger={props.trigger.trigger}
            context={context}
            idx={props.idx}
            onDelete={onDelete}
        />;
    };

export default observer(CompositeTriggerElementEditor);
