import React from 'react';
import { observer } from 'mobx-react-lite';
import useActionListener from './Api/useActionListener';
import TriggerRegistrationContext from './Api/TriggerRegistrationContext';
import TriggerUnregistrationContext from './Api/TriggerUnregistrationContext';

export interface ActionProviderProps
{

}

const ActionProvider: React.FC<ActionProviderProps> =
    props =>
    {
        const [ registerTrigger, unregisterTrigger ] = useActionListener();

        return <TriggerRegistrationContext.Provider
            value={registerTrigger}
        >
            <TriggerUnregistrationContext.Provider
                value={unregisterTrigger}
            >
                {props.children}
            </TriggerUnregistrationContext.Provider>
        </TriggerRegistrationContext.Provider>;
    };

export default observer(ActionProvider);
