import Widget from '../../Widget';
import { observable } from 'mobx';
import { EmptyDisposer } from '../../../../../../../@Util/Disposable/EmptyDisposer';

export default class WebsiteWidget extends Widget
{
    // ------------------------- Properties -------------------------

    @observable url: string;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                url?: string)
    {
        super(id);

        this.url = url;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async doInitialize(widgets: Widget[])
    {
        return EmptyDisposer;
    }

    static fromDescriptor(descriptor: any)
    {
        return new WebsiteWidget(
            descriptor.id,
            descriptor.url);
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Website',
            url: this.url
        }
    }

    // ----------------------- Private logic ------------------------
}
