import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import CardHeader from '../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import ParameterDictionary from '../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import HoverCardBottom from '../../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import Parameter from '../../../../../../../../@Api/Automation/Parameter/Parameter';
import uuid from '../../../../../../../../@Util/Id/uuid';
import PrimitiveValueType from '../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { loadModuleDirectly } from '../../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../../../DataObject/DataObjectStore';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ParameterEditor from './Parameter/ParameterEditor';
import { runInAction } from 'mobx';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import FunctionContext from '../../../../../../../../@Api/Automation/Function/FunctionContext';

export interface ParametersEditorProps
{
    parameterDictionary: ParameterDictionary;
    allowDefaultValue?: boolean;
    context?: FunctionContext;
}

const ParametersEditor: React.FC<ParametersEditorProps> =
    props =>
    {
        const createParameter =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            props.parameterDictionary.addParameter(
                                new Parameter(
                                    uuid(),
                                    new PrimitiveValueType(
                                        loadModuleDirectly(DataObjectStore).getTypeById('Boolean')),
                                    false,
                                    `${localizeText('Generic.Parameter', 'Parameter')} #${props.parameterDictionary.parameters.length + 1}`))),
                [
                    props.parameterDictionary
                ]);

        const deleteParameter =
            useCallback(
                (parameter: Parameter<any>) =>
                    runInAction(
                        () =>
                            props.parameterDictionary.deleteParameter(parameter)),
                [
                    props.parameterDictionary
                ]);

        const dividerGlue = useDividerGlue();

        return <Card>
            <CardInset
                bottom={false}
            >
                <CardHeader>
                    Parameters
                </CardHeader>
            </CardInset>
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={dividerGlue}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="vertical"
                        spacing={15}
                        glue={dividerGlue}
                    >
                        {
                            props.parameterDictionary
                                .parameters
                                .map(
                                    parameter =>
                                        <ViewGroupItem
                                            key={parameter.id}
                                        >
                                            <ParameterEditor
                                                parameter={parameter}
                                                onDelete={deleteParameter}
                                                allowDefaultValue={props.allowDefaultValue}
                                                context={props.context}
                                            />
                                        </ViewGroupItem>)
                        }
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <HoverCardBottom
                        onClick={createParameter}
                    >
                        + <LocalizedText code="Generic.Parameter" value="Parameter" />
                    </HoverCardBottom>
                </ViewGroupItem>
            </ViewGroup>
        </Card>;
    };

export default observer(ParametersEditor);
