import React, { useCallback, useState } from 'react';
import Dialog from '../../../../@Future/Component/Generic/Dialog/Dialog';
import DialogTitle from '../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import styles from './EntityEmailDialog.module.scss';
import { ActivityEmail } from '../Constructor/Type/Activity/Email/ActivityEmail';
import CancelButton from '../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import DialogContent from '../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import { ExpressionContext } from '../../Expression/ExpressionContext';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import Computation from '../../../../@Api/Automation/Function/Computation/Computation';
import FunctionContext from '../../../../@Api/Automation/Function/FunctionContext';

export interface EntityEmailDialogProps
{
    close: () => void;
    title?: string;
    email: Entity;
    commitContext: CommitContext;
    sendHandler?: (email: Entity, subjectTemplate?: Computation<any, any>) => void;
    onSent?: (email: Entity) => void;
    template?: Entity;
    templateType?: EntityType;
    subjectTemplate?: Computation<any, any>;
    templateContext: FunctionContext;
    expressionContext?: ExpressionContext;
    disableDraftSave?: boolean;
    emailDialogMessage?: string;
}

const EntityEmailDialog: React.FC<EntityEmailDialogProps> =
    props =>
    {
        const { close, sendHandler, onSent } = props;
        const [ subjectTemplate, setSubjectTemplate ] = useState<Computation<any, any>>(props.subjectTemplate);
        const internalSendHandler =
            useCallback(
                (email: Entity) =>
                    sendHandler(
                        email,
                        subjectTemplate
                    ),
                [
                    sendHandler,
                    subjectTemplate,
                ]
            );

        return <Dialog
            onClose={close}
            open={true}
            width="md"
            className={styles.root}
        >
            <DialogTitle>
                {
                    props.title
                        ?
                            props.title
                        :
                            <LocalizedText
                                code="Generic.SendEmail"
                                value="E-mail versturen"
                            />
                }
            </DialogTitle>

            <DialogContent>
                <ActivityEmail
                    entity={props.email}
                    sendHandler={internalSendHandler}
                    onSent={onSent}
                    autoCommit={false}
                    buttons={[
                        <CancelButton
                            onClick={close}
                        />
                    ]}
                    template={props.template}
                    templateType={props.templateType}
                    subjectTemplate={subjectTemplate}
                    templateContext={props.templateContext}
                    expressionContext={props.expressionContext}
                    onChangeSubjectTemplate={setSubjectTemplate}
                    commitContext={props.commitContext}
                    disableDraftSave={props.disableDraftSave}
                    message={props.emailDialogMessage}
                />
            </DialogContent>
        </Dialog>;
    };

export default observer(EntityEmailDialog);
