import { Setting } from '../../Settings/Setting';
import { Entity } from '../../Model/Implementation/Entity';
import { EntityType } from '../../Model/Implementation/EntityType';
import getMetadataSettingValue from '../getMetadataSettingValue';

export function isFieldGroupExpandedByDefault(
    entityType: EntityType,
    fieldGroup: Entity,
    includeInherited: boolean
): boolean
{
    const isExpandedByDefault =
        isFieldGroupExpandedByDefaultInSetting(
            entityType,
            fieldGroup
        );

    if (isExpandedByDefault)
    {
        return true;
    }
    else if (includeInherited)
    {
        if (entityType.hasParent())
        {
            return isFieldGroupExpandedByDefault(
                entityType.parentType,
                fieldGroup,
                includeInherited
            );
        }
        else
        {
            return false;
        }
    }
    else
    {
        return false;
    }
}

function isFieldGroupExpandedByDefaultInSetting(
    entityType: EntityType,
    fieldGroup: Entity
)
{
    const expandedFieldGroupIds: string[] =
        getMetadataSettingValue(
            entityType,
            Setting.Metadata.ExpandedFieldGroups)
        ?? [];

    return expandedFieldGroupIds
        .some(
            id =>
                id === fieldGroup.uuid
        );
}