import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { CronEditorProps } from '../CronEditor';
import NumberEditor from '../../NumberEditor/NumberEditor';
import ViewGroup from '../../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../../ViewGroup/ViewGroupItem';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export interface MinutelyCronEditorProps extends CronEditorProps
{

}

const MinutelyCronEditor: React.FC<MinutelyCronEditorProps> =
    props =>
    {
        const minutes =
            useMemo(
                () =>
                    parseInt(props.value.split(' ')[1].split('/')[1]),
                [
                    props.value
                ]);

        const { onChange } = props;
        const onChangeMinutes =
            useCallback(
                (minutes: number) =>
                {
                    if (minutes === undefined
                        || minutes < 1)
                    {
                        minutes = 1;
                    }

                    const split = props.value.split(' ');
                    split.splice(
                        1,
                        1,
                        `0/${minutes}`);

                    onChange(split.join(' '));
                },
                [
                    onChange,
                    props.value
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={5}
            alignment="center"
        >
            <ViewGroupItem>
                Om de
            </ViewGroupItem>
            <ViewGroupItem>
                <NumberEditor
                    value={minutes}
                    onChange={onChangeMinutes}
                    min={15}
                    fitContent
                />
            </ViewGroupItem>
            <ViewGroupItem>
                {
                    minutes === 1
                        ?
                            localizeText('Generic.Minute', 'minuut')
                        :
                            localizeText('Generic.Minutes', 'minuten')
                }
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(MinutelyCronEditor);
