import {useEffect, useRef} from "react";

export function useAutoFocusRef(autoFocus: boolean)
{
    const inputRef = useRef();

    useEffect(
        () =>
        {
            if (autoFocus && inputRef && inputRef.current)
            {
                (inputRef.current as any).focus();
            }
        },
        [
            inputRef,
            autoFocus
        ]
    );

    return inputRef;
}