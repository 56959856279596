import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import LabelLayoutViewer from './LabelLayoutViewer';
import LabelLayout from '../../../../../@Api/Layout/Type/LabelLayout';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import Layout from '../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';

export interface LabelLayoutEditorProps extends LayoutEditorProps<LabelLayout>
{

}

const LabelLayoutEditor: React.FC<LabelLayoutEditorProps> =
    props =>
    {
        const setLabel =
            useCallback(
                (label: Layout) =>
                    runInAction(
                        () =>
                            props.layout.label = label),
                [
                    props.layout
                ]);
        const setView =
            useCallback(
                (view: Layout) =>
                    runInAction(
                        () =>
                            props.layout.view = view),
                [
                    props.layout
                ]);

        const parameterAssignment =
            useMemo(
                () =>
                    props.parameterAssignment || new ParameterAssignment(),

                [
                    props.parameterAssignment
                ]);

        return <LabelLayoutViewer
            {...props}
            parameterAssignment={parameterAssignment}
            label={
                <ChildLayoutEditor
                    {...props}
                    childLayout={props.layout.label}
                    onChange={setLabel}
                />
            }
            view={
                <ChildLayoutEditor
                    {...props}
                    childLayout={props.layout.view}
                    onChange={setView}
                />
            }
        />
    };

export default observer(LabelLayoutEditor);
