import React from 'react';
import { observer } from 'mobx-react-lite';
import { default as FilterOptionModel } from '../../Model/FilterOption';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import PrimitiveFilterOption from './PrimitiveFilterOption';
import EntityFilterOption from './EntityFilterOption';

export interface FilterOptionProps
{
    filterOptionId: string;
    filterOption: FilterOptionModel;
}

const FilterOption: React.FC<FilterOptionProps> =
    props =>
    {
        return <Input
            labelPosition={props.filterOption.isLabelHidden ? 'none' : 'left'}
            label={props.filterOption.fieldPath.getName()}
            inline
        >
            {
                props.filterOption.fieldPath.field
                    ?
                        <PrimitiveFilterOption
                            {...props}
                        />
                    :
                        <EntityFilterOption
                            {...props}
                        />
            }
        </Input>;
    };

export default observer(FilterOption);
