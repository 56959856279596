import * as React from 'react';
import { observer } from 'mobx-react';
import { JsonEditorStore } from './JsonEditorStore';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { toJS } from 'mobx';
import { default as InternalJsonEditor } from '../../../@Future/Component/Generic/Input/JsonEditor/JsonEditor';

@observer
export class JsonEditor extends BaseComponent<JsonEditorStore>
{
    renderComponent(store: JsonEditorStore)
    {
        return <InternalJsonEditor
            json={toJS(store.defaultValue)}
            onChange={store.handleChange}
        />;
    }
}
