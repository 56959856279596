import { DataObjectBespokeEditorStore } from '../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { action, computed, observable, reaction } from 'mobx';
import { TextStore } from '../../../../Generic/Text/TextStore';
import { textSecondaryColor } from '../../../../../@Resource/Theme/Theme';

export class NumberEditorStore extends DataObjectBespokeEditorStore
{
    // ------------------------ Dependencies ------------------------

    @observable inputRef: any;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    entersUI(isMounted: boolean)
    {
        super.entersUI(isMounted);

        this.registerDisposable(
            reaction(
                () => this.baseStore.isFocused,
                () =>
                {
                    if (this.baseStore.isFocused
                        && this.inputRef
                        && typeof this.inputRef.focus === 'function')
                    {
                        this.inputRef.focus();
                    }
                }));
    }

    // -------------------------- Computed --------------------------

    @computed
    get suffix(): string
    {
        return this.baseStore.dataObject.specification.data.suffix;
    }

    @computed
    get suffixTextStore(): TextStore
    {
        if (this.suffix)
        {
            return new TextStore({
                label: this.suffix.toLowerCase(),
                variant: 'body2',
                color: textSecondaryColor
            });
        }
        else
        {
            return undefined;
        }
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    setInputRef(ref: any)
    {
        this.inputRef = ref;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
