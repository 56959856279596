import { useCallback, useMemo, useState } from 'react';

interface Checklist<D>
{
    items: D[];
    setItems: (items: D[]) => void;
    selectedItems: D[];
    setSelectedIndexes: (selectedIndexes: number[]) => void;
    toggleItem: (item: D) => void;
    toggleAllItems: () => void;
    checkedItem: (item: D) => boolean;
    checkedAllItems: () => boolean;
    selectionIsIndeterminate: () => boolean;
}

export default function useChecklist<D>(initialItems: D[] = [], initialSelected: boolean = true): Checklist<D>

{
    const [ items, setItems ] = useState<D[]>(initialItems);
    const [ selectedIndexes, setSelectedIndexes ] = useState<number[]>(initialSelected ? initialItems.map(initialValue => initialItems.indexOf(initialValue)) : [] );

/*    const selectedItems =
        useCallback(
            () => {
                return selectedIndexes.map(selectedIndex => items[selectedIndex]);
            },
            [
                items,
                selectedIndexes
            ]);*/

    const selectedItems = useMemo(
        () => selectedIndexes.map(selectedIndex => items[selectedIndex]),
        [
            items,
            selectedIndexes
        ]);

    const toggleItem =
        useCallback(
            (item: D) => {
                const index = items.indexOf(item);

                if (selectedIndexes.indexOf(index) >= 0) {
                    setSelectedIndexes(
                        selectedIndexes.filter(v => v !== index)
                    );
                }
                else {
                    setSelectedIndexes([
                        ...selectedIndexes,
                        index
                    ]);
                }
            },
            [
                items,
                selectedIndexes,
                setSelectedIndexes,
            ]);

    const toggleAllItems =
        useCallback(
            () =>
            {
                if (selectedIndexes.length !== items.length ) {
                    setSelectedIndexes(
                        items.map(item => items.indexOf(item))
                    );
                }
                else {
                    setSelectedIndexes([]);
                }
            },
            [
                items,
                selectedIndexes,
                setSelectedIndexes
            ]);

    const checkedItem =
        useCallback(
            (item: D) =>
            {
                const index = items.indexOf(item);
                return selectedIndexes.indexOf(index) >= 0;
            },
            [
                items,
                selectedIndexes
            ]);

    const checkedAllItems =
        useCallback(
            () =>
            {
                return selectedIndexes.length === items.length;
            },
            [
                items,
                selectedIndexes
            ]);

    const selectionIsIndeterminate =
        useCallback(
            () =>
            {
                return selectedIndexes.length < items.length && selectedIndexes.length > 0;
            },
            [
                items,
                selectedIndexes
            ]);

    return {
        items,
        setItems,
        selectedItems,
        setSelectedIndexes,
        toggleItem,
        toggleAllItems,
        checkedItem,
        checkedAllItems,
        selectionIsIndeterminate
    };
}

