import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, Hidden } from '@material-ui/core';
import styles from './BaseLayout.module.scss';
import IsCompactContext from '../../Context/IsCompactContext';
import useIsMobile from '../../../../../../@Util/Responsiveness/useIsMobile';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import IsBaseLayoutFullScreenOnXl from './IsBaseLayoutFullScreenOnXl';

export interface BaseLayoutProps
{

}

const BaseLayout: React.FC<BaseLayoutProps> =
    props =>
    {
        const isCompact = useContext(IsCompactContext);
        const isMobile = useIsMobile();
        const isBaseLayoutFullScreenOnXl = useContext(IsBaseLayoutFullScreenOnXl);

        if (isCompact)
        {
            return <>{props.children}</>;
        }
        else
        {
            return <div
                className={classNames(styles.root, isMobile && styles.mobile)}
            >
                <Grid
                    container
                    spacing={0}
                >
                    <Hidden
                        lgDown
                    >
                        <Grid
                            item
                            xl={1}
                        />
                    </Hidden>
                    <Grid
                        item
                        xl={isBaseLayoutFullScreenOnXl ? 11 : 10}
                        lg={12}
                        md={12}
                        xs={12}
                    >
                        {props.children}
                    </Grid>
                </Grid>
            </div>;
        }
    };

export default observer(BaseLayout);
