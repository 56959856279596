import localizeText from '../../../../../@Api/Localization/localizeText';

export type ResourceIntervalType = 'day' | 'week' | '2week' | 'month' | 'quarter' | 'year';

export interface ResourceInterval {
    id: string;
    interval: ResourceIntervalType;
    value: number;
    label: string;
}

export function getResourceIntervals() : ResourceInterval[]
{
    return [
        {
            id: 'day',
            interval: 'day',
            value: 1,
            label: localizeText('Generic.Day', 'Dag')
        },
        {
            id: 'week',
            interval: 'week',
            value: 1,
            label: localizeText('Generic.Week', 'Week')
        },
        {
            id: '2week',
            interval: 'week',
            value: 2,
            label: localizeText('Generic.2Week', '2 Weken')
        },
        {
            id: 'month',
            interval: 'month',
            value: 1,
            label: localizeText('Generic.Month', 'Maand')
        },
        {
            id: 'quarter',
            interval: 'quarter',
            value: 1,
            label: localizeText('Generic.Quarter', 'Kwartaal')
        },
        {
            id: 'year',
            interval: 'year',
            value: 1,
            label: localizeText('Generic.Year', 'Jaar')
        }
    ]
}
