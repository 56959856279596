import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../../Viewer/LayoutViewer';
import CollectionLayout from '../../../../../../@Api/Layout/Type/CollectionLayout';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import safelyApplyFunction from '../../../../../../@Api/Automation/Api/safelyApplyFunction';
import { CollectionLayoutViewerItems } from './Items/CollectionLayoutViewerItems';

export interface CollectionLayoutViewerProps extends LayoutViewerProps<CollectionLayout>
{

}

const CollectionLayoutViewer: React.FC<CollectionLayoutViewerProps> =
    props =>
    {
        const { parameterDictionary, parameterAssignment, commitContext, layout } = props;
        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        parameterDictionary,
                        parameterAssignment,
                        commitContext
                    ),
                [
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                ]
            );
        const [ collectionValue, isLoadingCollectionValue ] =
            useAsyncResult(
                async () =>
                    await safelyApplyFunction(
                        layout.collection,
                        context
                    ),
                [
                    layout,
                    context
                ]
            );

        return <CollectionLayoutViewerItems
            {...props}
            collectionValue={collectionValue}
            loading={isLoadingCollectionValue}
        />;
    };

export default observer(CollectionLayoutViewer);
