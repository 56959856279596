import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import useTypes from '../../../../Type/Api/useTypes';
import { default as DatasetModel } from '../../../../Dataset/Model/Dataset';
import Segment from '../../../../Dataset/Model/Segment';
import uuid from '../../../../../../../@Util/Id/uuid';
import { Comparator } from '../../../../../DataObject/Model/Comparator';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import Dataset from '../../../../Dataset/Dataset';
import { LogicalOperator } from '../../../../../DataObject/Model/LogicalOperator';
import useCount from '../../../../Selection/Hooks/useCount';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import getViewParameters from '../../../../View/Api/getViewParameters';
import CompositePredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import ComparisonPredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../../../View/Model/ViewParams';
import PrimitiveValue from '../../../../../../../@Api/Automation/Value/PrimitiveValue';
import EntityValue from '../../../../../../../@Api/Automation/Value/EntityValue';

export interface InvolvedListProps
{
    entity: Entity;
    former?: boolean;
}

const ChildRelationships: React.FC<InvolvedListProps> =
    props =>
    {
        const types = useTypes();

        const id =
            useMemo(
                () =>
                    uuid(),
                []);

        const dataset =
            useMemo(
                () =>
                {
                    const datasetParameters = getViewParameters(types.Relationship.Type);

                    return new DatasetModel(
                        types.Relationship.Type,
                        datasetParameters.getParameterById(ViewParams.Entity),
                        datasetParameters,
                        [
                            new Segment(
                                uuid(),
                                'Tile',
                                EntityPath.fromEntityType(types.Relationship.Type)
                                    .field(types.Entity.Field.Type))
                        ],
                        new CompositePredicate(
                            LogicalOperator.And,
                            [
                                ...props.former
                                    ?
                                        []
                                    :
                                        types.Relationship.Person.Contact.Type.getAllTypes(false, true)
                                            .map(
                                                contactType =>
                                                    new ComparisonPredicate(
                                                        new ValueFromEntityComputation(
                                                            datasetParameters.getParameterById(ViewParams.Entity),
                                                            EntityPath.fromEntityType(types.Relationship.Type)
                                                                .field(types.Entity.Field.Type)),
                                                        Comparator.NotEquals,
                                                        new PrimitiveValue(
                                                            DataObject.constructFromTypeIdAndValue(
                                                                'EntityType',
                                                                contactType)))),
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        datasetParameters.getParameterById(ViewParams.Entity),
                                        EntityPath.fromEntityType(types.Relationship.Type)
                                            .joinTo(
                                                types.Relation.RelationshipDefinition.Relationships,
                                                true)
                                            .field()),
                                    Comparator.Equals,
                                    new EntityValue(props.entity)),
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        datasetParameters.getParameterById(ViewParams.Entity),
                                        EntityPath.fromEntityType(types.Relationship.Type)
                                            .field(types.Relationship.Field.IsFormer)),
                                    Comparator.Equals,
                                    new PrimitiveValue(
                                        DataObject.constructFromTypeIdAndValue(
                                            'Boolean',
                                            props.former)))
                            ]));
                },
                [
                    types,
                    props.entity,
                    props.former
                ]);

        const count =
            useCount(
                props.former && dataset.entityType,
                builder =>
                    builder.where(
                        cb =>
                            cb.filter(
                                dataset.filter,
                                {
                                    parameter: dataset.parameter,
                                }
                            )
                    ),
                [
                    dataset
                ]);

        if (!props.former || count > 0)
        {
            return <Dataset
                id={id}
                name={
                    props.former
                        ?
                            localizeText(
                                'Relationships.FormerOf',
                                'Voormalige relaties van ${name}',
                                {
                                    name: props.entity.name
                                })
                        :
                            localizeText(
                                'Relationships.Of',
                                'Relaties van ${name}',
                                {
                                    name: props.entity.name
                                })
                }
                dataset={dataset}
                disableExpansionMemory
                whiteCard
                showMenuButtonInItems
            />;
        }
        else
        {
            return null;
        }
    };

ChildRelationships.defaultProps = {
    former: false
};

export default observer(ChildRelationships);
