import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { IObservableArray, runInAction } from 'mobx';
import ViewGroup from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import DeleteIconButton from '../../../../../../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import ListQueryOrdering from '../../../../../../../../../../../../../@Api/Automation/Query/ListQueryOrdering';
import { ListQueryOrderingsEditorProps } from './ListQueryOrderingsEditor';
import LocalizedText from '../../../../../../../../../../../Localization/LocalizedText/LocalizedText';
import Switch from '../../../../../../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import Input from '../../../../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';

export interface ListQueryOrderingEditorProps extends ListQueryOrderingsEditorProps
{
    ordering: ListQueryOrdering;
}

const ListQueryOrderingsEditor: React.FC<ListQueryOrderingEditorProps> =
    props =>
    {
        const { query, ordering } = props;

        const toggleDirection =
            useCallback(
                (isDescending: boolean) =>
                    runInAction(
                        () =>
                            ordering.direction = isDescending ? 'Descending' : 'Ascending'),
                [
                    query,
                    ordering
                ]);
        const deleteOrdering =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (query.orderings as IObservableArray).remove(ordering)),
                [
                    query,
                    ordering
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem
                ratio={1}
            >
                {ordering.expression.getName()}
            </ViewGroupItem>
            <ViewGroupItem>
                <Input
                    labelPosition="right"
                    label={
                        <LocalizedText
                            code="View.Column.SortDescending"
                            value="Sorteer aflopend"
                        />
                    }
                >
                    <Switch
                        checked={ordering.direction === 'Descending'}
                        onToggle={toggleDirection}
                    />
                </Input>
            </ViewGroupItem>
            <ViewGroupItem>
                <DeleteIconButton
                    onClick={deleteOrdering}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(ListQueryOrderingsEditor);
