import { DailyRecurrencePattern, MonthlyRecurrencePattern, RecurrencePattern, RelativeMonthlyRecurrencePattern, RelativeYearlyRecurrencePattern, WeeklyRecurrencePattern, YearlyRecurrencePattern } from '../../Model/RecurrencePattern';
import { getDailyRecurrencePatternAsRRule } from './Type/getDailyRecurrencePatternAsRRule';
import { Options, RRule, RRuleSet } from 'rrule';
import { getEndAsRRule } from './getEndAsRRule';
import { getWeeklyRecurrencePatternAsRRule } from './Type/getWeeklyRecurrencePatternAsRRule';
import { getMonthlyRecurrencePatternAsRRule } from './Type/getMonthlyRecurrencePatternAsRRule';
import { getYearlyRecurrencePatternAsRRule } from './Type/getYearlyRecurrencePatternAsRRule';
import { getRelativeMonthlyRecurrencePatternAsRRule } from './Type/getRelativeMonthlyRecurrencePatternAsRRule';
import { getRelativeYearlyRecurrencePatternAsRRule } from './Type/getRelativeYearlyRecurrencePatternAsRRule';
import { toRRuleDate } from './toRRuleDate';

export function getRecurrencePatternAsRRule<T extends RecurrencePattern>(
    pattern: T,
    includeExceptions: boolean = true
): RRuleSet
{
    const startDate = 
        toRRuleDate(
            new Date(
                Date.parse(
                    pattern.startDate
                )
            )
        );
    const rruleSet = new RRuleSet();

    const rrule =
        new RRule({
            dtstart: startDate,
            ...getEndAsRRule(pattern.end),
            ...getRecurrencePatternOptionsAsRRule(pattern),
        });

    rruleSet.rrule(rrule);

    if (includeExceptions)
    {
        const modifiedStartDates =
            new Set(
                pattern.modifiedOccurrences.map(
                    modifiedOccurrence =>
                        modifiedOccurrence.startDate
                )
            );

        for (const deletedOccurrence of pattern.deletedOccurrences)
        {
            if (!modifiedStartDates.has(deletedOccurrence.originalStartDate))
            {
                rruleSet.exdate(
                    toRRuleDate(
                        new Date(
                            Date.parse(
                                deletedOccurrence.originalStartDate
                            )
                        )
                    )
                );
            }
        }

        for (const modifiedOccurrence of pattern.modifiedOccurrences)
        {
            if (!modifiedStartDates.has(modifiedOccurrence.originalStartDate))
            {
                rruleSet.exdate(
                    toRRuleDate(
                        new Date(
                            Date.parse(
                                modifiedOccurrence.originalStartDate
                            )
                        )
                    )
                );
            }

            rruleSet.rdate(
                toRRuleDate(
                    new Date(
                        Date.parse(
                            modifiedOccurrence.startDate
                        )
                    )
                )
            );
        }
    }

    return rruleSet;
}

export function getRecurrencePatternOptionsAsRRule<T extends RecurrencePattern>(
    pattern: T
): Partial<Options>
{
    switch (pattern.type)
    {
        case 'Daily':
            return getDailyRecurrencePatternAsRRule(pattern as DailyRecurrencePattern);

        case 'Weekly':
            return getWeeklyRecurrencePatternAsRRule(pattern as WeeklyRecurrencePattern);

        case 'Monthly':
            return getMonthlyRecurrencePatternAsRRule(pattern as MonthlyRecurrencePattern);

        case 'RelativeMonthly':
            return getRelativeMonthlyRecurrencePatternAsRRule(pattern as RelativeMonthlyRecurrencePattern);

        case 'Yearly':
            return getYearlyRecurrencePatternAsRRule(pattern as YearlyRecurrencePattern);

        case 'RelativeYearly':
            return getRelativeYearlyRecurrencePatternAsRRule(pattern as RelativeYearlyRecurrencePattern);

        default:
            throw new Error(`Unsupported recurrence pattern type: ${pattern.type}`);
    }
}
