import { ContentProps } from "../../Content";
import React, { useContext, useMemo } from "react";
import useTypes from "../../../../../../../../../../Type/Api/useTypes";
import useFetchedRelatedEntity from "../../../../../../../../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity";
import useEntityValue from "../../../../../../../../../../../../../@Api/Entity/Hooks/useEntityValue";
import { format } from "date-fns";
import Base from "../../Base/Base";
import ViewGroup from "../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup";
import ViewGroupItem from "../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem";
import EntityLink from "../../../../../../../../../../Link/EntityLink";
import LocalizerContext from "../../../../../../../../../../../../../@Service/Localization/LocalizerContext";
import { Table, TableCell, TableRow } from '@material-ui/core';
import LocalizedText from '../../../../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface ApsisFormResultProps extends ContentProps
{

}

export interface DataField
{
    name: string,
    value: string
}

export const ApsisFormResult: React.FC<ApsisFormResultProps> =
    props  =>
    {
        const types = useTypes();
        const [ apsisForm ] = useFetchedRelatedEntity(
            props.entity,
            types.Activity.ApsisForm.RelationshipDefinition.Results,
            true
        );
        const submitDate =
            useEntityValue<Date>(
                props.entity,
                types.Activity.ApsisFormResult.Field.SubmitDate
            );

        const localizer = useContext(LocalizerContext);
        const formattedDate =
            useMemo(
                () =>
                    submitDate !== undefined
                        ? localizer.formatDateTime(new Date(submitDate))
                        : undefined,
                [
                    submitDate,
                    localizer
                ]
            );

        const formData =
            useEntityValue<string>(
                props.entity,
                types.Activity.ApsisFormResult.Field.FormData
            );



        const data = useMemo<DataField[]>(
            () =>
            {
                const result = [];
                const json = JSON.parse(JSON.stringify(formData))

                for(const key in json)
                {
                    result.push(
                        {
                            name: key,
                            value: json[key]
                        }
                    );
                }

                return result;
            },
                [
                    formData
                ]
        );


        return <Base
            {...props}
            expand
            noInputGroup
        >
            <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <Table
                        style={{width: 'auto', margin: 30, borderSpacing: 30, border: '1px solid rgba(0, 0, 0, 0.08)'}}
                        cellSpacing={10}
                    >
                        {
                            apsisForm &&
                            <TableRow>
                                <TableCell
                                    style={{fontWeight: 600, fontSize: 14, textTransform: 'capitalize', padding: 10}}
                                    align="left"
                                >
                                    <LocalizedText
                                        code="ApsisForm.Form"
                                        value="Apsis formulier"
                                    />:
                                </TableCell>
                                <TableCell
                                    style={{
                                        padding: 10
                                    }}
                                >
                                    <EntityLink entity={apsisForm} />
                                </TableCell>
                            </TableRow>
                        }
                        <TableRow>
                            <TableCell
                                style={{fontWeight: 600, fontSize: 14, textTransform: 'capitalize', padding: 10}}
                                align="right"
                            >
                                <LocalizedText
                                    code="ApsisForm.FillDate"
                                    value="Ingevult op"
                                />:
                            </TableCell>
                            <TableCell
                                style={{
                                    padding: 10
                                }}
                            >
                                {
                                    formattedDate
                                }
                            </TableCell>
                        </TableRow>
                        {
                            data?.map(
                                (f, idx) =>
                                    <TableRow
                                        key={`field_${idx}`}
                                    >
                                        <TableCell
                                            style={{fontWeight: 600, fontSize: 14, textTransform: 'capitalize', padding: 10}}
                                            align="right"
                                        >
                                            {f.name}:
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                padding: 10
                                            }}
                                        >
                                            {f.value}
                                        </TableCell>
                                    </TableRow>
                            )
                        }
                    </Table>
                </ViewGroupItem>
            </ViewGroup>
        </Base>
    }