import { loadModuleDirectly } from '../../@Util/DependencyInjection/Injection/DependencyInjection';
import { FeedbackStore } from '../../@Component/App/Root/Environment/Organization/Feedback/FeedbackStore';
import getErrorMessage from './getErrorMessage';
import isUserError from './isUserError';
import { runInAction } from 'mobx';

export default function showErrorMessage(error: Error)
{
    if (!isErrorMessageAlreadyShown(error))
    {
        markErrorMessageAsShown(error);
        showErrorMessageSnackbar(error);
    }

    if (!isUserError(error))
    {
        throw error;
    }
}

const isHandledKey = '__isErrorMessageShown';

function isErrorMessageAlreadyShown(error: Error)
{
    return error !== undefined && error[isHandledKey] === true;
}

function markErrorMessageAsShown(error: Error)
{
    if (error)
    {
        runInAction(
            () =>
            {
                error[isHandledKey] = true;
            });
    }
}

function showErrorMessageSnackbar(error: Error)
{
    console.log('showing error', (error as any).message, (error as any).detail, (error as any).ok, isUserError(error), getErrorMessage(error));

    loadModuleDirectly(FeedbackStore)
        .enqueueSnackbar(getErrorMessage(error), {
            variant: 'error',
            autoHideDuration: 5000,
        });
}
