import Value from '../Value/Value';
import { useEffect, useState } from 'react';
import { computed } from 'mobx';
import ReactiveInterface from '../Value/ReactiveInterface';

export default function useReactiveValue(
    value?: Value<any, any>,
    reactiveInterface?: ReactiveInterface
)
{
    const [ currentValue, setCurrentValue ] = useState(value);

    useEffect(
        () =>
        {
            setCurrentValue(value);

            if (reactiveInterface)
            {
                return computed(() => reactiveInterface.getState())
                    .observe(
                        () =>
                        {
                            const newValue = reactiveInterface.getValue();
                            setCurrentValue(newValue);
                        }
                    );
            }
        },
        [
            value,
            reactiveInterface,
            setCurrentValue,
        ]);

    return currentValue;
}
