import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import TableDimensionSection from '../../../../../../../@Api/Layout/Type/Table/Model/TableDimensionSection';
import { IObservableArray, runInAction } from 'mobx';
import { TableLayoutDimensionSectionEditorProps } from './TableLayoutDimensionSectionEditor';
import IconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import ButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/ButtonGroup';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';

export interface TableLayoutDimensionSectionEditorShiftProps extends TableLayoutDimensionSectionEditorProps
{

}

const TableLayoutDimensionSectionShiftEditor: React.FC<TableLayoutDimensionSectionEditorShiftProps> =
    props =>
    {
        const { layout, section, rowSectionEditor, columnSectionEditor, first, last } = props;
        const onShift =
            useCallback(
                (isRow: boolean, isBackwards: boolean) =>
                {
                    const sections = (isRow ? layout.rowSections : layout.columnSections) as IObservableArray<TableDimensionSection>;
                    const x = sections.indexOf(section);
                    const y = isBackwards ? x - 1 : x + 1;
                    const ySection = sections[y];

                    runInAction(
                        () =>
                        {
                            sections[y] = section;
                            sections[x] = ySection;
                        });
                },
                [
                    rowSectionEditor,
                    layout,
                    section
                ])
        const shiftRowUp =
            useCallback(
                () =>
                    onShift(true, true),
                [
                    onShift
                ]);
        const shiftRowDown =
            useCallback(
                () =>
                    onShift(true, false),
                [
                    onShift
                ]);
        const shiftColumnLeft =
            useCallback(
                () =>
                    onShift(false, true),
                [
                    onShift
                ]);
        const shiftColumnRight =
            useCallback(
                () =>
                    onShift(false, false),
                [
                    onShift
                ]);

        return <Input
            labelPosition="top"
            label={
                <LocalizedText
                    code="Generic.Move"
                    value="Verplaatsen"
                />
            }
        >
            <ButtonGroup>
                {
                    rowSectionEditor &&
                        <IconButton
                            icon="keyboard_arrow_up"
                            onClick={shiftRowUp}
                            tooltip={
                                <LocalizedText
                                    code="Generic.Up"
                                    value="Boven"
                                />
                            }
                            disabled={first}
                            small
                        />
                }
                {
                    rowSectionEditor &&
                        <IconButton
                            icon="keyboard_arrow_down"
                            onClick={shiftRowDown}
                            tooltip={
                                <LocalizedText
                                    code="Generic.Down"
                                    value="Beneden"
                                />
                            }
                            disabled={last}
                            small
                        />
                }
                {
                    columnSectionEditor &&
                        <IconButton
                            icon="keyboard_arrow_left"
                            onClick={shiftColumnLeft}
                            tooltip={
                                <LocalizedText
                                    code="Generic.Left"
                                    value="Links"
                                />
                            }
                            disabled={first}
                            small
                        />
                }
                {
                    columnSectionEditor &&
                        <IconButton
                            icon="keyboard_arrow_right"
                            onClick={shiftColumnRight}
                            tooltip={
                                <LocalizedText
                                    code="Generic.Right"
                                    value="Rechts"
                                />
                            }
                            disabled={last}
                            small
                        />
                }
            </ButtonGroup>
        </Input>;
    };

export default observer(TableLayoutDimensionSectionShiftEditor);
