import { EntityConstraintBuilderFilterContext } from './Model/EntityConstraintBuilderFilterContext';
import { ConstraintNode } from '../../../../../../@Api/Model/Implementation/ConstraintNode';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { getRhsValue } from './Util/getRhsValue';
import { getLhsNodeAndFieldAndAugmentContext } from './Util/getLhsNodeAndFieldAndAugmentContext';
import { Comparator } from '../../../../DataObject/Model/Comparator';
import { ValueComparisonConstraintNode } from '../../../../../../@Api/Model/Implementation/ValueComparisonConstraintNode';
import Value from '../../../../../../@Api/Automation/Value/Value';
import ValueFromEntityComputation from '../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../@Api/Automation/Value/Type/EntityValueType';
import { isResultParameter } from './Util/isResultParameter';
import { getEntityNodeAndFieldByParameterAndFieldPath } from './Util/getEntityNodeAndFieldByParameterAndFieldPath';
import { EntityComparisonConstraintNode } from '../../../../../../@Api/Model/Implementation/EntityComparisonConstraintNode';
import PrimitiveValue from '../../../../../../@Api/Automation/Value/PrimitiveValue';
import EntityValue from '../../../../../../@Api/Automation/Value/EntityValue';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import CollectionValue from '../../../../../../@Api/Automation/Value/CollectionValue';
import { CompositeConstraintNode, LogicalOperator } from '../../../../../../@Api/Model/Implementation/CompositeConstraintNode';
import { DataObjectType, EntityField } from '../../../../../../@Api/Model/Implementation/EntityField';
import { EntityNode } from '../../../../../../@Api/Model/Implementation/EntityNode';

function getEntityValueComparator(
    lhsNode: EntityNode,
    lhsField: EntityField,
    comparator: Comparator
)
{
    if (comparator === Comparator.Contains)
    {
        return Comparator.Equals;
    }
    else if (comparator === Comparator.NotContains)
    {
        if (lhsNode.entityPath().isPlural
            && lhsField?.type !== DataObjectType.Text)
        {
            // server side this is transformed into an aggregate query
            return Comparator.NotContains;
        }
        else
        {
            return Comparator.NotEquals
        }
    }
    else
    {
        return comparator;
    }
}

export function buildDefaultComparisonConstraintNode(
    filter: ComparisonPredicate,
    context: EntityConstraintBuilderFilterContext
): ConstraintNode
{
    const lhs = filter.lhs;
    const rhs = filter.rhs;
    const { node: lhsNode, field: lhsField } = getLhsNodeAndFieldAndAugmentContext(filter, context) ?? {};

    if (lhsNode && lhsField)
    {
        if (filter.comparator === Comparator.IsDefined
            || filter.comparator === Comparator.IsNotDefined)
        {
            return new ValueComparisonConstraintNode(
                lhsNode,
                lhsField,
                {},
                filter.comparator,
                undefined
            );
        }
        else
        {
            let rhsValue: Value<any, any>;

            if (rhs instanceof ValueFromEntityComputation
                && rhs.entity instanceof Parameter
                && rhs.entity.type instanceof EntityValueType
                && isResultParameter(rhs.entity, context)
                && (rhs.entity === lhs
                    || (lhs instanceof ValueFromEntityComputation && lhs.entity === rhs.entity)))
            {
                if (rhs.fieldPath)
                {
                    const { node: rhsNode, field: rhsField } =
                        getEntityNodeAndFieldByParameterAndFieldPath(
                            rhs.entity,
                            rhs.fieldPath,
                            context
                        );

                    return new EntityComparisonConstraintNode(
                        lhsNode,
                        lhsField,
                        {},
                        filter.comparator,
                        rhsNode,
                        rhsField,
                        {}
                    );
                }
                else
                {
                    return undefined;
                }
            }
            else if (rhs instanceof Parameter
                && rhs.type instanceof EntityValueType
                && isResultParameter(rhs, context))
            {
                const { node: rhsNode, field: rhsField } =
                    getEntityNodeAndFieldByParameterAndFieldPath(
                        rhs,
                        EntityPath.fromEntityType(rhs.type.type).field(),
                        context
                    );

                return new EntityComparisonConstraintNode(
                    lhsNode,
                    lhsField,
                    undefined,
                    filter.comparator,
                    rhsNode,
                    rhsField,
                    undefined
                );
            }
            else if (rhs)
            {
                rhsValue = getRhsValue(rhs, context);
            }

            if (rhsValue instanceof PrimitiveValue)
            {
                return new ValueComparisonConstraintNode(
                    lhsNode,
                    lhsField,
                    {},
                    filter.comparator,
                    rhsValue.value
                );
            }
            else if (rhsValue instanceof EntityValue)
            {
                return new ValueComparisonConstraintNode(
                    lhsNode,
                    lhsField,
                    {},
                    getEntityValueComparator(
                        lhsNode,
                        lhsField,
                        filter.comparator
                    ),
                    DataObject.constructFromTypeIdAndValue(
                        'Number',
                        rhsValue.value.id
                    )
                );
            }
            else if (rhsValue instanceof CollectionValue
                && filter.comparator === Comparator.In)
            {
                if (rhsValue.elementType instanceof EntityValueType)
                {
                    return new CompositeConstraintNode(
                        LogicalOperator.Or,
                        rhsValue.value.map(
                            (element: EntityValue) =>
                                new ValueComparisonConstraintNode(
                                    lhsNode,
                                    lhsField,
                                    undefined,
                                    Comparator.Equals,
                                    DataObject.constructFromTypeIdAndValue(
                                        'Number',
                                        element.value.id
                                    )
                                )
                        )
                    );
                }
                else if (rhsValue.elementType instanceof PrimitiveValue)
                {
                    return new CompositeConstraintNode(
                        LogicalOperator.Or,
                        rhsValue.value.map(
                            (element: PrimitiveValue) =>
                                new ValueComparisonConstraintNode(
                                    lhsNode,
                                    lhsField,
                                    undefined,
                                    Comparator.Equals,
                                    element.value
                                )
                        )
                    );
                }
            }
        }
    }
}
