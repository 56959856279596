import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import useLocalEntitySetting from '../../../Setting/Api/useLocalEntitySetting';
import useTypes from '../../Type/Api/useTypes';
import { useEffect, useState } from 'react';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../Path/@Model/EntityPath';

export default function usePipelineSetting(id: string,
                                           entityType: EntityType,
                                           doFetchInitialPipeline: boolean): [ Entity | undefined, (entity?: Entity) => void, boolean ]
{
    doFetchInitialPipeline = false;

    const types = useTypes();
    const [ pipeline, setPipeline, isLoading ] = useLocalEntitySetting(id, types.Pipeline.Type);

    const [ isLoadingInitial, setLoadingInitial ] = useState(doFetchInitialPipeline);

    useEffect(
        () =>
        {
            if (doFetchInitialPipeline)
            {
                if (pipeline)
                {
                    setLoadingInitial(false);
                }
                else
                {
                    new EntitySelectionBuilder(types.Pipeline.Type)
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    EntityPath.fromEntityType(types.Pipeline.Type)
                                        .joinTo(
                                            types.EntityType.RelationshipDefinition.Pipelines,
                                            true),
                                    entityType.entity))
                        .select(0, 1)
                        .then(
                            results =>
                            {
                                if (results.length > 0)
                                {
                                    setPipeline(results[0].entity);
                                }

                                setLoadingInitial(false);
                            });
                }
            }
        },
        [
            doFetchInitialPipeline,
            pipeline,
            setLoadingInitial,
            types,
            setPipeline
        ]);

    return [ pipeline, setPipeline, isLoading || isLoadingInitial ];
}
