import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TextEditor from '../TextEditor/TextEditor';
import moment from 'moment';
import Popper from '../../Popper/Popper';
import Card from '../../Card/Card';
import DatePicker, { DatePickerProps } from '../DatePicker/DatePicker';
import useSwitch from '../../../../../@Util/Switch/useSwitch';
import { InputProps as StandardInputProps } from '@material-ui/core';

export interface DateEditorProps extends DatePickerProps
{
    inputProps?: StandardInputProps['inputProps'];
    disableUnderline?: boolean;
    fitContent?: boolean;
    placeholder?: string;
}

const dateFormat = 'DD-MM-YYYY';

const DateEditor: React.FC<DateEditorProps> =
    props =>
    {
        const [ text, setText ] = useState<string | undefined>();
        const { onChange } = props;

        useEffect(
            () =>
            {
                if (props.value)
                {
                    setText(moment(props.value).format(dateFormat));
                }
                else
                {
                    setText(undefined);
                }
            },
            [
                props.value,
                setText
            ]);

        const [ isOpen, open, close ] = useSwitch(false);

        const inputProps =
            useMemo(
                () => ({
                    onFocus:
                        (event: any) =>
                        {
                            open();

                            if (props.inputProps?.onFocus)
                            {
                                props.inputProps?.onFocus(event);
                            }
                        },
                    onBlur:
                        (event: any) =>
                        {
                            if (props.inputProps?.onBlur)
                            {
                                props.inputProps?.onBlur(event);
                            }
                        },
                    onClick:
                        () =>
                        {
                            open();
                        },
                    onChange:
                        (event: any) =>
                        {
                            const text = event.target.value;

                            if (!text || text.length === 0)
                            {
                                onChange(undefined);
                            }
                            else if (text && text.length === 10)
                            {
                                const date = moment(text, dateFormat);

                                if (date.isValid())
                                {
                                    if (props.value === undefined || moment(props.value).format(dateFormat) !== text)
                                    {
                                        onChange(date.toDate());
                                        close();
                                    }
                                }
                                else if (props.value)
                                {
                                    setText(moment(props.value).format(dateFormat))
                                }
                            }
                        },
                    ...inputProps
                }),
                [
                    props.value,
                    setText,
                    open,
                    close,
                    props.inputProps,
                    onChange
                ]);

        const onChangeCallback =
            useCallback(
                (value?: Date) =>
                {
                    close();
                    onChange(value);
                },
                [
                    close,
                    onChange
                ]);

        return <Popper
            open={isOpen}
            onClose={isOpen ? close : undefined}
            fullWidth
            reference={
                <TextEditor
                    value={text}
                    onChange={setText}
                    inputProps={inputProps}
                    disableUnderline={props.disableUnderline}
                    fitContent={props.fitContent}
                    placeholder={props.placeholder}
                />
            }
            popper={
                <Card
                    inset
                >
                    <DatePicker
                        {...props}
                        onChange={onChangeCallback}
                    />
                </Card>
            }
        />;
    };

export default observer(DateEditor);
