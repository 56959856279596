import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import EntityTypeContext from '../../../Type/EntityTypeContext';
import useToggle from '../../../../../../@Util/Toggle/useToggle';
import { isAttachedType } from '../../../Item/Navigator/Api/getIsAttachedType';
import useTypes from '../../../Type/Api/useTypes';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Switch from '../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import Popper from '../../../../../../@Future/Component/Generic/Popper/Popper';
import Link from '../../../../../../@Future/Component/Generic/Link/Link';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../@Future/Component/Generic/Menu/Item/Item';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import { createTransactionalModel } from '../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import Constructor from '../../../Constructor/Constructor';

export interface ConvertToActivityProps
{
    subject: string;
    relationship: Entity;
    contact?: Entity;
    validate?: boolean;
    onParentActivity: (entity: Entity) => void;
    allowToggle?: boolean;
    allowSendWarning?: boolean;
}

const ConvertToActivity: React.FC<ConvertToActivityProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);

        const [ conversionType, setConversionType ] = useState(types.Activity.SalesOpportunity.Type);
        const [ isConversionEnabled, toggleConversion ] = useToggle(!props.allowToggle);
        const [ activity, setActivity ] = useState<Entity>();

        useEffect(
            () =>
            {
                if (isConversionEnabled)
                {
                    const activity =
                        createTransactionalModel(
                            new Entity(conversionType)
                                .initialize(entityTypeStore));

                    activity.updateRelationship(
                        true,
                        types.Relationship.RelationshipDefinition.Activities,
                        createTransactionalModel(props.relationship));

                    if (props.contact)
                    {
                        activity.updateRelationship(
                            true,
                            types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                            createTransactionalModel(props.contact));
                    }

                    activity.setValueByField(
                        types.Activity.Field.Subject,
                        props.subject);

                    setActivity(activity);
                }
                else
                {
                    setActivity(undefined);
                }
            },
            [
                entityTypeStore,
                types.Activity.Field.Subject,
                types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                types.Relationship.RelationshipDefinition.Activities,
                isConversionEnabled,
                conversionType,
                props.relationship,
                props.contact,
                props.subject,
                setActivity
            ]);

        const { onParentActivity } = props;

        useEffect(
            () =>
            {
                onParentActivity(activity);
            },
            [
                activity,
                onParentActivity
            ]);

        const [ isConversionTypeMenuOpened, toggleConversionTypeMenu ] = useToggle(false);
        const closeConversionTypeMenu =
            useCallback(
                () =>
                    toggleConversionTypeMenu(false),
                [
                    toggleConversionTypeMenu
                ]);

        const nonAttachedTypes =
            useMemo(
                () =>
                    types.Activity.Type.getAllInstantiableTypes()
                        .filter(
                            type =>
                                !isAttachedType(entityTypeStore, type)
                                && type !== conversionType),
                [
                    entityTypeStore,
                    types,
                    conversionType
                ]);

        const showActivityInvalidWarning =
            useComputed(
                () =>
                    props.allowSendWarning &&
                        activity !== undefined &&
                        !activity.isValid,
                [
                    props.allowSendWarning,
                    activity
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <ViewGroupItem>
                <ViewGroup
                    orientation="horizontal"
                    spacing={10}
                    alignment="center"
                >
                    {
                        props.allowToggle &&
                            <ViewGroupItem>
                                <Switch
                                    onToggle={toggleConversion}
                                    checked={isConversionEnabled}
                                />
                            </ViewGroupItem>
                    }
                    <ViewGroupItem>
                        Converteren naar&nbsp;
                        <Popper
                            reference={
                                <Link
                                    onClick={toggleConversionTypeMenu}
                                    highlighted
                                >
                                    {conversionType.getName().toLowerCase()}
                                </Link>}
                            popper={
                                <Card>
                                    <Menu>
                                        {
                                            nonAttachedTypes.map(
                                                type =>
                                                    <Item
                                                        key={type.id}
                                                        name={type.getName()}
                                                        onClick={
                                                            () => {
                                                                setConversionType(type);
                                                                toggleConversionTypeMenu(false);
                                                            }
                                                        }
                                                    />)
                                        }
                                    </Menu>
                                </Card>}
                            open={isConversionTypeMenuOpened}
                            onClose={closeConversionTypeMenu}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
            {
                activity &&
                    <ViewGroupItem>
                        <Constructor
                            entity={activity}
                            disableActions
                            touched={props.validate}
                        />
                    </ViewGroupItem>
            }
            {
                showActivityInvalidWarning &&
                    <ViewGroupItem>
                        <strong>Let op!</strong> De e-mail wordt bij het versturen niet geconverteerd naar een {activity.entityType.getName().toLowerCase()} zolang verplichte velden missen.
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(ConvertToActivity);
