import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import View from '../../../../../Entity/View/Model/View';
import useTypes from '../../../../../Entity/Type/Api/useTypes';
import { default as ListModel } from '../../../../../Entity/View/Model/Specification/List';
import Column from '../../../../../Entity/View/Model/Specification/Column';
import uuid from '../../../../../../../@Util/Id/uuid';
import { EntityPath } from '../../../../../Entity/Path/@Model/EntityPath';
import Specification from '../../../../../Entity/View/Model/Specification';
import List from '../../../../../Entity/View/List/List';
import usePhaseRelationshipDefinition from '../../../../../../../@Api/Entity/Bespoke/Datastore/Phase/usePhaseRelationshipDefinition';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import styles from './PipelineEditor.module.scss';
import { Cardinality, EntityRelationshipDefinition, ReferentialAction, Type } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { createTransactionalModel } from '../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { EntityRelationshipDefinitionApi } from '../../../../../../../@Api/Api/EntityRelationshipDefinitionApi';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import { EntityTypeApi } from '../../../../../../../@Api/Api/EntityTypeApi';
import LocalizerContext from '../../../../../../../@Service/Localization/LocalizerContext';
import Pipeline from '../../../../../Entity/Workflow/Pipeline/Pipeline';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import Divider from '../../../../../../../@Future/Component/Generic/Divider/Divider';
import Ordering from '../../../../../Entity/View/Model/Ordering';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { Comparator } from '../../../../../DataObject/Model/Comparator';
import FilterContext from '../../../../../Entity/Dataset/Segment/FilterContext/FilterContext';
import Fields from '../../../../../Entity/Fields/Fields';
import InputGroup from '../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import useMetadataSettingFlag from '../../../../../../../@Api/Metadata/useMetadataSettingFlag';
import { Setting } from '../../../../../../../@Api/Settings/Setting';
import Switch from '../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import useMetadataSettingValue from '../../../../../../../@Api/Metadata/useMetadataSettingValue';
import TextEditor from '../../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import useDebouncedCallback from '../../../../../../../@Util/Debounce/useDebouncedCallback';
import getExtendedLocalizedValue from '../../../../../../../@Api/Localization/getExtendedLocalizedValue';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import getViewParameters from '../../../../../Entity/View/Api/getViewParameters';
import ComparisonPredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../../../../Entity/View/Model/ViewParams';
import EntityValue from '../../../../../../../@Api/Automation/Value/EntityValue';
import EmptyValue from '../../../../../../../@Api/Automation/Value/EmptyValue';
import { SharePackEditor } from '../../../../Page/Packs/MySharedPackManager/SharePackEditor/SharePackEditor';
import { usePackSelector } from '../../../../Page/Packs/MySharedPackManager/Api/usePackSelector';

export interface PipelineEditorProps
{
    entityType: EntityType;
    pipeline?: Entity;
    numberOfPipelines: number;
}

const PipelineEditor: React.FC<PipelineEditorProps> =
    props =>
    {
        const types = useTypes();
        const localizerStore = useContext(LocalizerContext);

        const phaseRelationshipDefinition = usePhaseRelationshipDefinition(props.entityType);
        const filterParameters =
            useMemo(
                () =>
                    phaseRelationshipDefinition &&
                        getViewParameters(phaseRelationshipDefinition.childEntityType),
                [
                    phaseRelationshipDefinition
                ]);
        const filter =
            useMemo(
                () =>
                {
                    if (phaseRelationshipDefinition)
                    {
                        if (props.pipeline)
                        {
                            return new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    filterParameters.getParameterById(ViewParams.Entity),
                                    EntityPath.fromEntityType(phaseRelationshipDefinition.childEntityType)
                                        .joinTo(
                                            types.Pipeline.RelationshipDefinition.Phases,
                                            true)
                                        .field(types.Entity.Field.Id)),
                                Comparator.Equals,
                                new EntityValue(props.pipeline));
                        }
                        else
                        {
                            return new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    filterParameters.getParameterById(ViewParams.Entity),
                                    EntityPath.fromEntityType(phaseRelationshipDefinition.childEntityType)
                                        .joinTo(
                                            types.Pipeline.RelationshipDefinition.Phases,
                                            true)
                                        .field(types.Entity.Field.Id)),
                                Comparator.IsNotDefined,
                                EmptyValue.instance);
                        }
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.pipeline,
                    types,
                    phaseRelationshipDefinition,
                    filterParameters
                ]);

        const view =
            useComputed(
                () =>
                {
                    if (phaseRelationshipDefinition)
                    {
                        return new View(
                            'List',
                            'Fasen',
                            phaseRelationshipDefinition.childEntityType,
                            filterParameters,
                            filter,
                            new Specification(
                                new ListModel([
                                        new Column(
                                            uuid(),
                                            EntityPath.fromEntityType(phaseRelationshipDefinition.childEntityType)
                                                .field(types.Datastore.Field.LocalizedName)),
                                        new Column(
                                            uuid(),
                                            EntityPath.fromEntityType(phaseRelationshipDefinition.childEntityType)
                                                .field(types.Datastore.Field.IsDefault)),
                                        new Column(
                                            uuid(),
                                            EntityPath.fromEntityType(phaseRelationshipDefinition.childEntityType)
                                                .field(types.Datastore.Phase.Field.IsTerminal)),
                                    ],
                                    [
                                        new Ordering(
                                            new Column(
                                                uuid(),
                                                EntityPath.fromEntityType(phaseRelationshipDefinition.childEntityType)
                                                    .field(types.Entity.Field.SortIndex)),
                                            true)
                                    ])));
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    phaseRelationshipDefinition,
                    filterParameters,
                    filter,
                    types
                ]);

        const configurePhaseRelationshipDefinition =
            useCallback(
                async () =>
                {
                    const entityType = props.entityType;

                    const phaseType =
                        createTransactionalModel(
                            new EntityType());

                    phaseType.code = uuid();
                    phaseType.parentType = types.Datastore.Phase.Type;
                    phaseType.isInstantiable = true;

                    const entity =
                        createTransactionalModel(
                            new Entity(types.EntityType.Type)
                                .initialize());
                    phaseType.entity = entity;

                    entity.setValueByField(
                        types.EntityType.Field.LocalizedSingularName,
                        getExtendedLocalizedValue(
                            entityType.getNameDataObject(false).value,
                            value =>
                                `${value} ` + localizeText('Generic.Phase', 'Fase')
                        ));

                    entity.setValueByField(
                        types.EntityType.Field.LocalizedPluralName,
                        getExtendedLocalizedValue(
                            entityType.getNameDataObject(true).value,
                            value =>
                                `${value} ` + localizeText('Generic.Phases', 'Fasen')
                        ));

                    await new EntityTypeApi()
                        .commit(phaseType, true);

                    //const nameLanguageEntry = new LanguageEntry();
                    //const inverseNameLanguageEntry = new LanguageEntry();

                    const phaseRelationshipDefinition =
                        createTransactionalModel(
                            new EntityRelationshipDefinition(
                                uuid(),
                                entityType,
                                phaseType,
                                Type.Reference,
                                Cardinality.ManyToOne,
                                true,
                                false,
                                true,
                                false,
                                false,
                                false,
                                true,
                                false,
                                undefined,
                                undefined,
                                ReferentialAction.None,
                                ReferentialAction.Restrict,
                                undefined, // nameLanguageEntry,
                                undefined)); // inverseNameLanguageEntry));

                    [ true, false ]
                        .forEach(
                            isParent =>
                                phaseRelationshipDefinition.setVisibleDuringConstruction(
                                    isParent,
                                    true));

                    const phaseRelationshipDefinitionEntity =
                        createTransactionalModel(
                            new Entity(types.EntityRelationshipDefinition.Type)
                                .initialize());
                    phaseRelationshipDefinition.entity = phaseRelationshipDefinitionEntity;

                    phaseRelationshipDefinitionEntity.setValueByField(
                        types.EntityRelationshipDefinition.Field.LocalizedChildName,
                        localizeText('Generic.Phase', 'Fase')
                    );

                    phaseRelationshipDefinitionEntity.setValueByField(
                        types.EntityRelationshipDefinition.Field.LocalizedParentName,
                        {
                            ...entityType.getNameDataObject(true).value
                        }
                    );

                    await new EntityRelationshipDefinitionApi()
                        .commit(phaseRelationshipDefinition, true);
                },
                [
                    types,
                    props.entityType,
                    localizerStore
                ]);

        const [ hasDefaultPipeline, setDefaultPipeline ] = useMetadataSettingFlag(props.entityType, Setting.Metadata.HasDefaultPipeline);
        const [ defaultPipelineNameSettingValue, _setDefaultPipelineNameSettingValue ] = useMetadataSettingValue<string>(props.entityType, Setting.Metadata.DefaultPipelineName);
        const [ defaultPipelineNameValue, _setDefaultPipelineNameValue ] = useState(() => defaultPipelineNameSettingValue);

        const setDefaultPipelineName =
            useDebouncedCallback(
                _setDefaultPipelineNameSettingValue,
                [
                    _setDefaultPipelineNameSettingValue
                ],
                300);

        const setDefaultPipelineNameValue =
            useCallback(
                (value?: string) =>
                {
                    _setDefaultPipelineNameValue(value);
                    setDefaultPipelineName(value);
                },
                [
                    _setDefaultPipelineNameValue,
                    setDefaultPipelineName
                ]);

        const [ isPackSelectorVisible, ownedShareAndEnvironmentPacks ] = usePackSelector(props.pipeline);

        if (view)
        {
            return <FilterContext.Provider
                value={filter}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    {
                        !props.pipeline && props.numberOfPipelines > 0 &&
                            <ViewGroupItem>
                                <CardInset
                                    bottom={false}
                                >
                                    <InputGroup>
                                        <Input
                                            label={types.Pipeline.Field.Name.getName()}
                                            labelPosition="left"
                                        >
                                            <TextEditor
                                                value={defaultPipelineNameValue}
                                                onChange={setDefaultPipelineNameValue}
                                                placeholder={localizeText(
                                                    'Generic.Default',
                                                    'Standaard'
                                                )}
                                            />
                                        </Input>
                                        <Input
                                            label={types.Pipeline.Field.IsActive.getName()}
                                            labelPosition="left"
                                        >
                                            <Switch
                                                checked={hasDefaultPipeline}
                                                onToggle={setDefaultPipeline}
                                            />
                                        </Input>
                                    </InputGroup>
                                </CardInset>
                            </ViewGroupItem>
                    }
                    {
                        props.pipeline &&
                            <ViewGroupItem>
                                <CardInset
                                    horizontal={false}
                                    bottom={false}
                                >
                                    <Fields
                                        entity={props.pipeline}
                                    />
                                </CardInset>
                            </ViewGroupItem>
                    }
                    {
                        isPackSelectorVisible &&
                        <ViewGroupItem>
                            <CardInset>
                                <SharePackEditor
                                    entity={props.pipeline}
                                    ownedShareAndEnvironmentPacks={ownedShareAndEnvironmentPacks}
                                />
                            </CardInset>
                        </ViewGroupItem>
                    }
                    <ViewGroupItem>
                        <CardInset>
                            <Card
                                inset
                            >
                                <Pipeline
                                    entityType={props.entityType}
                                    showWhenNoPhases
                                    pipeline={props.pipeline}
                                />
                            </Card>
                        </CardInset>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Divider />
                        <List
                            view={view}
                            sortable
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </FilterContext.Provider>;
        }
        else
        {
            return <div
                className={styles.configure}
            >
                <Centered
                    horizontal
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                        alignment="center"
                    >
                        <ViewGroupItem>
                            <LocalizedText
                                code="PipelineEditor.SetupPhases"
                                value="Om met fases te werken moet er voor ${typeName} een faseveld ingesteld zijn."
                                typeName={props.entityType.getName().toLowerCase()}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <PrimaryButton
                                label={
                                    <LocalizedText
                                        code="Generic.SetupNow"
                                        value="Nu instellen"
                                    />
                                }
                                onClick={configurePhaseRelationshipDefinition}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </Centered>
            </div>;
        }
    };

export default observer(PipelineEditor);
