import { computed, observable } from 'mobx';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { type } from '../../../../../@Util/Serialization/Serialization';

@type('SourceInstance')
export default class SourceInstance
{
    // ------------------------- Properties -------------------------

    @observable valueByFieldId: any;

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get dataObjectByFieldId(): Map<string, DataObject>
    {
        return new Map(
            Object.keys(this.valueByFieldId)
                .map(
                    fieldId => [
                        fieldId,
                        DataObject.constructFromDescriptor(this.valueByFieldId[fieldId])
                    ]));
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
