import { findAssistantBooleanValue } from './findAssistantBooleanValue';
import { AssistantValue } from '../../Model/Value/AssistantValue';
import { findAssistantTextValue } from './findAssistantTextValue';
import { findAssistantNumberValue } from './findAssistantNumberValue';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { findAssistantDateTimeValue } from './findAssistantDateTimeValue';
import { findAssistantEntityTypeValue } from './findAssistantEntityTypeValue';
import { findAssistantTimePeriodValue } from './findAssistantTimePeriodValue';

export function findAssistantValue(
    value: AssistantValue,
    parameters: ParameterDictionary
)
{
    switch (value.type)
    {
        case 'Boolean':
            return findAssistantBooleanValue(value);

        case 'Text':
            return findAssistantTextValue(value);

        case 'Number':
            return findAssistantNumberValue(value);

        case 'DateTime':
            return findAssistantDateTimeValue(value);

        case 'TimePeriod':
            return findAssistantTimePeriodValue(value);

        case 'EntityType':
            return findAssistantEntityTypeValue(value);

        // case 'QueryAlias':
        //     return findAssistantQueryAliasValue(
        //         value,
        //         parameters
        //     );
        //
        // case 'AggregateQuery':
        //     return findAssistantAggregateQueryValue(
        //         value,
        //         parameters
        //     );

        default:
            throw new Error(`Could not resolve value: ${JSON.stringify(value)}`);
    }
}
