import * as React from 'react';
import { observer } from 'mobx-react';
import { Page } from '../../../../../@Service/Navigation/Page/Page';
import { EntityViewerPageStore } from './EntityViewerPageStore';
import { EntityViewer as OldEntityViewer } from '../Deprecated/EntityViewer';
import ViewerWithSelectionCursor from '../SelectionCursor/ViewerWithSelectionCursor/ViewerWithSelectionCursor';

@observer
export class EntityViewerPage extends Page<EntityViewerPageStore>
{
    renderBody(store: EntityViewerPageStore): JSX.Element
    {
        if (store.showNewViewer)
        {
            return <ViewerWithSelectionCursor
                entity={store.entity}
                cursor={store.selectionCursor}
                command={store.entityViewerStore.command}
            />;
        }
        else
        {
            return <OldEntityViewer
                store={store.entityViewerStore}
            />;
        }
    }
}
