import React, { useContext } from 'react';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import Role from '../../Model/Role';
import CardHeader from '../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import { ExportingFeature, MergingFeature, TranslatingFeature } from '../../Model/Features';
import styles from '../RoleEditor.module.scss';
import CurrentUserContext from '../../../User/CurrentUserContext';
import { FeatureEditor } from './Feature/FeatureEditor';

export interface FeaturesEditorProps
{
    role: Role;
    isReadOnly: boolean;
}

export const FeaturesEditor: React.FC<FeaturesEditorProps> =
        ({
            role,
            isReadOnly
         }) =>
        {
            const dividerGlue = useDividerGlue();
            const currentUserStore = useContext(CurrentUserContext);

            return <Card>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    glue={dividerGlue}
                >
                    <ViewGroupItem>
                        <CardInset
                            classes={{
                                root: styles.cardHeader
                            }}
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={0}
                                alignment="center"
                            >
                                <CardHeader>
                                    <LocalizedText
                                        code="Generic.General"
                                        value="Algemeen"
                                    />
                                </CardHeader>
                            </ViewGroup>
                        </CardInset>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <FeatureEditor
                            role={role}
                            feature={ExportingFeature}
                            label={
                                <LocalizedText
                                    code="Generic.Export"
                                    value="Exporteren"
                                />
                            }
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <FeatureEditor
                            role={role}
                            feature={MergingFeature}
                            label={
                                <LocalizedText
                                    code="Generic.Merge"
                                    value="Samenvoegen"
                                />
                            }
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                    {
                        currentUserStore.isDeveloper &&
                        <ViewGroupItem>
                            <FeatureEditor
                                role={role}
                                feature={TranslatingFeature}
                                label={
                                    <LocalizedText
                                        code="Generic.Translate"
                                        value="Vertalen"
                                    />
                                }
                                isReadOnly={isReadOnly}
                            />
                        </ViewGroupItem>
                    }
                </ViewGroup>
            </Card>;
        };
