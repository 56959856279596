import { Action, BespokeEntityType } from '../../../BespokeEntityType';
import { EntityTypeStore } from '../../../EntityTypeStore';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationshipDefinition } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import copyProductLines from '../../../../../../../@Api/Entity/Bespoke/Activity/copyProductLines';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import { InvoiceRegime } from '../../../../../Configuration/Page/Invoicing/model/InvoiceRegime';
import { SettingSource } from '../../../../../Setting/SettingStore';
import { Setting } from '../../../../../../../@Api/Settings/Setting';
import getSetting from '../../../../../Setting/Api/getSetting';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import { getRelationshipAndContactInitializationPathsInViewer } from '../../../Api/getRelationshipAndContactInitializationPathsInViewer';
import { extendContactRelationshipSelection } from '../../../Api/extendContactRelationshipSelection';
import { onRelationshipUpdateCheckAndSetContact } from '../../../Api/onRelationshipUpdateCheckAndSetContact';

export class BespokeActivityInvoice extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Activity.Invoice')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getInitializationPathsInViewer(rootPath: EntityPath): EntityPath[]
    {
        // If fetch the SendToAlternativeContact organization and contact
        const paths = super.getInitializationPathsInViewer(rootPath);

        const relationshipAndContactPaths =
            getRelationshipAndContactInitializationPathsInViewer(
                rootPath,
                this.types.Activity.Invoice.RelationshipDefinition.SendToAlternativeRelationship,
                this.types.Activity.Invoice.RelationshipDefinition.SendToAlternativeContact
            );

        paths.push(
            ...relationshipAndContactPaths
        );

        return paths;
    }

    extendRelationshipSelection(entity: Entity,
                                relationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean,
                                builder: EntitySelectionBuilder,
                                commitContext?: CommitContext)
    {
        super.extendRelationshipSelection(
            entity,
            relationshipDefinition,
            isParent,
            builder,
            commitContext
        );

        const types = this.types;

        if (relationshipDefinition === types.Activity.Invoice.RelationshipDefinition.SendToAlternativeContact
            && isParent)
        {
            extendContactRelationshipSelection(
                types.Activity.Invoice.RelationshipDefinition.SendToAlternativeRelationship,
                true,
                entity,
                builder,
                commitContext
            );
        }
    }

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        // In case of linking a new project to an activity, then:
        // - copy the subject
        if (entity.isNew()
            && isParent
            && relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities
            && relatedEntity)
        {
            // Copy product lines in case of a sales opportunity (non-billed ones)
            // E.g. in case of a project, then we manually decide which lines are copied
            if ((relatedEntity.entityType.isA(this.types.Activity.SalesOpportunity.Type)
                || relatedEntity.entityType.isA(this.types.Activity.WorkOrder.Type))
                && entity.getRelatedEntitiesByDefinition(false, this.types.Activity.RelationshipDefinition.ProductLines, commitContext).length === 0)
            {
                await copyProductLines(
                    relatedEntity,
                    entity,
                    line =>
                        !line.getObjectValueByField(this.types.ProductLine.Field.IsBilled),
                    commitContext
                );
            }

            // Copy fields
            [
                this.types.Activity.Field.Subject,
                this.types.Activity.Field.DiscountPercentage,
                this.types.Activity.Field.IsVatIncluded,
                this.types.Activity.Field.Currency,
            ].forEach(
                field =>
                    setValueByFieldInEntity(
                        entity,
                        field,
                        relatedEntity.getObjectValueByField(field),
                        commitContext
                    )
            );
        }
        // In case of an update of the SendToAlternativeRelationship field, then check if it is a contact and set the SendToAlternativeContact field
        else if (relationshipDefinition === this.types.Activity.Invoice.RelationshipDefinition.SendToAlternativeRelationship
            && isParent)
        {
            await onRelationshipUpdateCheckAndSetContact(
                true,
                this.types.Activity.Invoice.RelationshipDefinition.SendToAlternativeRelationship,
                this.types.Activity.Invoice.RelationshipDefinition.SendToAlternativeContact,
                entity,
                relatedEntity,
                commitContext
            );
        }
    }

    isDisabled(
        entity: Entity,
        fieldPath: EntityFieldPath
    ): boolean
    {
        if (super.isDisabled(entity, fieldPath))
        {
            return true;
        }

        const regime =
            getSetting<InvoiceRegime>(
                SettingSource.Organization,
                Setting.InvoiceRegime
            );

        if (regime !== 'Strict')
        {
            return false;
        }

        const finalized =
            entity.getObjectValueByField(this.types.Activity.Invoice.Field.IsFinalized);

        if (!finalized)
        {
            return false;
        }

        const nonDisabledRelationshipsWhenFinalized = [
            this.types.Activity.Invoice.RelationshipDefinition.Phase
        ];

        return (
            fieldPath.isField ||
            (fieldPath.relationshipDefinition && !nonDisabledRelationshipsWhenFinalized.includes(fieldPath.relationshipDefinition))
        );
    }


    hideFieldPath(entity: Entity,
                  fieldPath: EntityFieldPath,
                  fromFieldPath?: EntityFieldPath,
                  isInConstructor?: boolean,
                  commitContext?: CommitContext): boolean
    {
        return (super.hideFieldPath(entity, fieldPath, fromFieldPath, isInConstructor, commitContext)
            && fieldPath.field !== this.entityTypeStore.bespoke.types.Activity.Field.Number);
    }

    getActions(entity: Entity): Action[]
    {
        const phase = entity.getRelatedEntityByDefinition(false, this.types.Activity.Invoice.RelationshipDefinition.Phase);

        if (phase
            && entity.getObjectValueByField(this.types.Activity.Field.Amount) >= 0
            && (phase.getObjectValueByField(this.types.Datastore.Field.Code) === 'Sent'
                || phase.getObjectValueByField(this.types.Datastore.Field.Code) === 'Paid'
                || phase.getObjectValueByField(this.types.Datastore.Field.Code) === 'Reminder1'
                || phase.getObjectValueByField(this.types.Datastore.Field.Code) === 'Reminder2'))
        {
            return [
                ...super.getActions(entity),
                {
                    id: 'Activity.Invoice.Credit',
                    code: 'Activity.Invoice.Credit',
                    name: localizeText('Invoice.Credit', 'Factuur crediteren')
                }
            ];
        }
        else
        {
            return super.getActions(entity);
        }
    }

    isDeletable(entity: Entity)
    {
        if (!super.isDeletable(entity))
        {
            return false;
        }

        const regime =
            getSetting<InvoiceRegime>(
                SettingSource.Organization,
                Setting.InvoiceRegime
            );

        if (regime !== 'Strict')
        {
            return true;
        }

        const finalized =
            entity.getObjectValueByField(this.types.Activity.Invoice.Field.IsFinalized);

        return !finalized;

    }


    // ----------------------- Private logic ------------------------
}
