import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import ExpansionGroup from '../../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import CampaignResults from './Results/CampaignResults';
import useCampaignPhaseCode from './Hooks/useCampaignPhaseCode';
import CampaignSelection from './Selection/CampaignSelection';
import CampaignTemplate from './Template/CampaignTemplate';
import CampaignStart from './Start/CampaignStart';
import CampaignOptOut from './OptOut/CampaignOptOut';
import CampaignSender from './Sender/CampaignSender';
import CampaignTest from './Test/CampaignTest';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { useCommittableEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/useCommittableEntity';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface CampaignProps
{
    entity: Entity;
    commitContext?: CommitContext;
}

const Campaign: React.FC<CampaignProps> =
    props =>
    {
        const commitContext = useNewCommitContext(props.commitContext);
        const entity = useCommittableEntity(props.entity, commitContext);
        const phaseCode = useCampaignPhaseCode(entity);

        const isConcept =
            useMemo(
                () =>
                    phaseCode === 'Concept',
                [
                    phaseCode
                ]);

        if (!phaseCode)
        {
            return null;
        }
        else
        {
            return <ExpansionGroup>
                <ViewGroup
                    orientation="vertical"
                    spacing={10}
                >
                    {
                        !isConcept &&
                        <ViewGroupItem>
                            <CampaignResults
                                entity={entity}
                            />
                        </ViewGroupItem>
                    }
                    <ViewGroupItem>
                        <Card>
                            <CampaignSender
                                entity={entity}
                                commitContext={commitContext}
                            />
                        </Card>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Card>
                            <CampaignOptOut
                                entity={entity}
                                commitContext={commitContext}
                            />
                        </Card>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Card>
                            <CampaignSelection
                                entity={entity}
                                commitContext={commitContext}
                            />
                        </Card>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Card>
                            <CampaignTemplate
                                entity={entity}
                                commitContext={commitContext}
                            />
                        </Card>
                    </ViewGroupItem>
                    {
                        isConcept &&
                        <ViewGroup
                            orientation="vertical"
                            spacing={10}
                        >
                            <ViewGroupItem>
                                <Card>
                                    <CampaignTest
                                        entity={entity}
                                    />
                                </Card>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <Card>
                                    <CampaignStart
                                        entity={entity}
                                        commitContext={commitContext}
                                    />
                                </Card>
                            </ViewGroupItem>
                        </ViewGroup>
                    }
                </ViewGroup>
            </ExpansionGroup>;
        }
    };

export default observer(Campaign);
