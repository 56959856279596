import isEqual from 'lodash/isEqual';
import uuid from '../../../../../../@Util/Id/uuid';
import { ExternalIdProperty } from '../../../../../../Constants';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/index';
import { MicrosoftExchangeConnectorController } from '../../../../../../@Api/Controller/Directory/MicrosoftExchangeConnectorController';
import Attachment from '../../../../../Domain/Entity/Bespoke/Activity/Email/ExternalEmail/Model/Attachment';
import { serialPromise } from '../../../../../../@Util/Promise/PromiseUtils';
import { getOffice } from './OfficeAddin';

type OutlookItem = any;

const postMessage =
    (message: any) =>
    {
        window.postMessage(message, '*');
    };

let item: OutlookItem;
let isInReadMode: boolean;

let itemId: string;
let currentSubject: any;
let currentBody: any;
let currentToRecipients: any[];
let itemIntervals: any[] = [];

export async function onEmailMessageChange()
{
    itemId = undefined;
    item = getOffice().context.mailbox.item;

    itemIntervals.splice(0, itemIntervals.length)
        .forEach(
            itemInterval =>
                clearInterval(itemInterval));

    if (item)
    {
        // https://stackoverflow.com/questions/52293787/in-an-outlook-addin-how-to-check-whether-we-are-in-compose-mode-or-read-mode
        isInReadMode = item.displayReplyForm !== undefined;

        if (isInReadMode)
        {
            return readItem(item);
        }
        else
        {
            itemId = uuid();

            if (item.itemType === 'appointment')
            {
                return readItem(item);
            }
            else if (item.itemType === 'message')
            {
                const promise =
                    refreshComposeItem(
                        true,
                        true,
                        true);

                // Refresh recipients every 2 seconds
                itemIntervals.push(
                    setInterval(
                        () =>
                        {
                            refreshComposeItem(
                                true,
                                false,
                                false);
                        },
                        2000));

                // Refresh complete item every 15 seconds
                itemIntervals.push(
                    setInterval(
                        () =>
                        {
                            refreshComposeItem(
                                true,
                                true,
                                true);
                        },
                        15000));

                return promise;
            }
        }
    }

    return Promise.resolve();
}

function readItem(item: OutlookItem)
{

    return Promise.all([
        getEntityIdFromItem(item),
        getItemSubject(item),
        getItemLocation(item),
        getItemStart(item),
        getItemEnd(item),
        getItemBody(item),
        getItemOrganizer(item),
        getRequiredAttendees(item),
        getOptionalAttendees(item)
    ])
        .then(
            ([ entityId, subject, location, start, end, body, organizer, requiredAttendees, optionalAttendees ]) =>
            {
                const myEmailAddress = getOffice().context.mailbox?.userProfile?.emailAddress;
                const attachmentsWithoutContent =
                    item.attachments
                        .map(
                            attachment =>
                                ({ name: attachment.name || attachment.id }));

                if (item.itemType === 'appointment')
                {
                    postMessage({
                        type: 'Activity.Appointment.LinkExternalAppointment',
                        data: {
                            id: item.internetMessageId || item.itemId || itemId,
                            entityId: entityId,
                            service: 'Microsoft.Office.Outlook',
                            subject: subject,
                            startDate: start,
                            endDate: end,
                            location: location,
                            isOrganizedByMe: !isInReadMode,
                            organizer:
                                organizer
                                ?
                                    {
                                        name: organizer.displayName,
                                        email: organizer.emailAddress
                                    }
                                :
                                    undefined,
                            participants: [
                                ...requiredAttendees.map(r => ({ name: r.displayName, email: r.emailAddress, type: 'Required', isMe: r.emailAddress === myEmailAddress })),
                                ...optionalAttendees.map(r => ({ name: r.displayName, email: r.emailAddress, type: 'Optional', isMe: r.emailAddress === myEmailAddress }))
                            ],
                            body: body,
                            syncDate: new Date(),
                            attachments: attachmentsWithoutContent,
                            areAttachmentsFetchedExternally: true
                        }
                    });
                }
                else if (item.itemType === 'message')
                {
                    postMessage({
                        type: 'Activity.Email.LinkExternalEmail',
                        data: {
                            id: item.internetMessageId || item.itemId,
                            entityId: entityId,
                            service: 'Microsoft.Office.Outlook',
                            threadId: item.conversationId,
                            sender: {
                                name: item.sender.displayName,
                                email: item.sender.emailAddress,
                                isMe: item.sender.emailAddress === myEmailAddress
                            },
                            recipients: [
                                ...item.to.map(r => ({ name: r.displayName, email: r.emailAddress, type: 'To', isMe: r.emailAddress === myEmailAddress })),
                                ...item.cc.map(r => ({ name: r.displayName, email: r.emailAddress, type: 'CC', isMe: r.emailAddress === myEmailAddress }))
                            ],
                            subject: item.subject,
                            body: body,
                            syncDate: new Date(),
                            date: item.dateTimeCreated,
                            attachments: attachmentsWithoutContent,
                            areAttachmentsFetchedExternally: true
                        }
                    });
                }
            });
}

function getItemSubject(item: OutlookItem)
{
    return new Promise<string>(
        resolve =>
            item?.subject?.getAsync
                ?
                    item.subject.getAsync(result => resolve(result.value))
                :
                    resolve(item?.subject));
}

function getItemLocation(item: OutlookItem)
{
    return new Promise<string>(
        resolve =>
            item?.location?.getAsync
                ?
                    item.location.getAsync(result => resolve(result.value))
                :
                    resolve(item?.location));
}

function getItemStart(item: OutlookItem)
{
    return new Promise<string>(
        resolve =>
            item?.start?.getAsync
                ?
                    item.start.getAsync(result => resolve(result.value))
                :
                    resolve(item?.start));
}

function getItemEnd(item: OutlookItem)
{
    return new Promise<string>(
        resolve =>
            item?.end?.getAsync
                ?
                    item.end.getAsync(result => resolve(result.value))
                :
                    resolve(item?.end));
}

function getItemBody(item: OutlookItem)
{
    return new Promise<string>(
        resolve =>
            item?.body.getAsync(
                item.itemType === 'appointment' ? getOffice().CoercionType.Text : getOffice().CoercionType.Html,
                newBody =>
                    resolve(newBody.value)));
}

function getItemOrganizer(item: OutlookItem)
{
    return new Promise<any>(
        resolve =>
            item?.organizer
                ?
                    item.organizer.getAsync
                        ?
                            item.organizer.getAsync(
                                organizer =>
                                    resolve(organizer))
                        :
                            resolve(item.organizer)
                :
                    resolve(undefined));
}

function getRequiredAttendees(item: OutlookItem)
{
    return new Promise<any>(
        resolve =>
            item?.requiredAttendees
                ?
                    item.requiredAttendees.getAsync
                        ?
                            item.requiredAttendees.getAsync(
                                result =>
                                    resolve(result.value))
                        :
                            resolve(item.requiredAttendees)
                :
                    resolve([]));
}

function getOptionalAttendees(item: OutlookItem)
{
    return new Promise<any>(
        resolve =>
            item?.optionalAttendees
                ?
                    item.optionalAttendees.getAsync
                        ?
                            item.optionalAttendees.getAsync(
                                result =>
                                    resolve(
                                        result.value))
                        :
                            resolve(item.optionalAttendees)
                :
                    resolve([]));
}

export function getCurrentItemAttachments(): Promise<Attachment[]>
{
    if (item)
    {
        return getAttachments(item);
    }
    else
    {
        return Promise.resolve([]);
    }
}

function getAttachments(item: OutlookItem): Promise<Attachment[]>
{
    if (item
        && item.attachments
        && item.attachments.length > 0
        && getOffice().context.mailbox.ewsUrl)
    {
        console.log('fetching', item.attachments.length, 'attachments for item', item, 'attachments:', item.attachments);

        return getCallbackToken()
            .then(
                token =>
                {
                    console.log('fetched token from office add-in');

                    // To avoid too many concurrent connections to mailbox error
                    return serialPromise(
                        (item.attachments as any[]).map(
                            attachment =>
                                () =>
                                    loadModuleDirectly(MicrosoftExchangeConnectorController)
                                        .downloadAttachmentFromExchangeServer(
                                            getOffice().context.mailbox.ewsUrl,
                                            token,
                                            item.itemId,
                                            attachment.id
                                        )
                                        .then(
                                            blob =>
                                                Promise.resolve({
                                                    name: attachment.name || attachment.id,
                                                    blob: blob
                                                } as Attachment)
                                        )
                                        .catch(
                                            e =>
                                            {
                                                console.error('Error fetching attachment', e);

                                                return Promise.resolve(undefined);
                                            }
                                        )
                        )
                    );
                })
            .then(
                (attachments: (Attachment | undefined)[]) =>
                    Promise.resolve(
                        attachments.filter(
                            attachment =>
                                attachment !== undefined
                        )
                    )
            )
            .catch(
                error =>
                {
                    console.error('Error fetching callback token', error);

                    return Promise.resolve([]);
                }
            );
    }
    else
    {
        return Promise.resolve([]);
    }
}

function getCallbackToken()
{
    return new Promise<string>(
        (resolve, reject) =>
        {
            if (getOffice().context.mailbox.getCallbackTokenAsync)
            {
                getOffice().context.mailbox.getCallbackTokenAsync(
                    result =>
                    {
                        if (result.status === 'succeeded')
                        {
                            resolve(result.value);
                        }
                        else
                        {
                            console.error('error getting callback token', result);

                            reject();
                        }
                    });
            }
            else
            {
                console.error('error getting callback token, method not existent');

                reject();
            }
        });
}

function getItemToRecipients(item: OutlookItem)
{
    return new Promise<any>(
        resolve =>
            item?.to.getAsync(
                result =>
                    resolve(
                        result
                            .value
                            .map(
                                r =>
                                    ({
                                        name: r.displayName,
                                        email: r.emailAddress,
                                        type: 'To'
                                    })))));
}

export function getEntityIdFromItem(item: OutlookItem): Promise<string | undefined>
{
    return new Promise(
        resolve =>
        {
            if (item !== undefined)
            {
                item.loadCustomPropertiesAsync(
                    result =>
                    {
                        if (result.status !== getOffice().AsyncResultStatus.Failed)
                        {
                            const customProps = result.value;

                            resolve(customProps.get(ExternalIdProperty));
                        }
                        else
                        {
                            resolve();
                        }
                    });
            }
            else
            {
                resolve();
            }
        });
}

export function storeEntityIdInItem(id: string)
{
    return new Promise(
        resolve =>
        {
            if (item !== undefined)
            {
                item.loadCustomPropertiesAsync(
                    result =>
                    {
                        if (result.status !== getOffice().AsyncResultStatus.Failed)
                        {
                            const customProps = result.value;
                            customProps.set(ExternalIdProperty, id);
                            customProps.saveAsync(
                                () =>
                                    resolve());
                        }
                        else
                        {
                            resolve();
                        }
                    });
            }
            else
            {
                resolve();
            }
        });
}

export function canRefreshComposeItem()
{
    return item !== undefined && !isInReadMode;
}

export function refreshComposeItem(refreshToRecipients: boolean,
                                   refreshSubject: boolean,
                                   refreshBody: boolean,
                                   doForce: boolean = false)
{
    if (item)
    {
        if (item.itemType === 'appointment')
        {
            return readItem(item);
        }
        else if (item.itemType === 'message')
        {
            return Promise.all([
                refreshToRecipients ? getItemToRecipients(item) : Promise.resolve(currentToRecipients),
                refreshSubject ? getItemSubject(item) : Promise.resolve(currentSubject),
                refreshBody ? getItemBody(item) : Promise.resolve(currentBody)
            ]).then(
                ([ toRecipients, subject, body ])  =>
                {
                    if (doForce
                        || !isEqual(currentToRecipients, toRecipients)
                        || !isEqual(currentBody, body)
                        || !isEqual(currentSubject, subject))
                    {
                        postMessage({
                            type: 'Activity.Email.ComposeExternalEmail',
                            data:
                                {
                                    id: itemId,
                                    service: 'Microsoft.Office.Outlook',
                                    recipients: toRecipients,
                                    sender:
                                        {
                                            email: getOffice().context.mailbox.userProfile.emailAddress
                                        },
                                    body: body,
                                    subject: subject,
                                    syncDate: new Date()
                                }
                        });
                    }

                    currentToRecipients = toRecipients;
                    currentBody = body;
                    currentSubject = subject;
                });
        }
    }

    return Promise.resolve();
}
