import React, { useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Layout from '../../../../../../@Api/Layout/Layout';
import { LayoutEditorProps } from '../../LayoutEditor';
import styles from './LayoutControlsRegion.module.scss';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import Icon from '../../../../../../@Future/Component/Generic/Icon/Icon';
import useSwitch from '../../../../../../@Util/Switch/useSwitch';
import Draggable from '../../../../DragAndDrop/Draggable/Draggable';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import LayoutConstructor, { LayoutConstructorMode } from '../../Constructor/LayoutConstructor';
import { HighlightedLayoutContext } from './HighlightedLayoutContext';
import Dialog from '../../../../../../@Future/Component/Generic/Dialog/Dialog';
import { TransitionProps } from '@material-ui/core/transitions';

export interface LayoutControlsProps<L extends Layout> extends LayoutEditorProps<L>
{
    editor: JSX.Element;
    controls: JSX.Element;
    onConstruct?: (layout: Layout) => void;
    constructorMode?: LayoutConstructorMode;
    draggableId?: string;
    draggableIdx?: number;
    draggableType?: string;
    horizontal?: boolean;
    last?: boolean;
}

const LayoutControlsRegion: React.FC<LayoutControlsProps<Layout>> =
    props =>
    {
        const [ isControlsOpen, openControls, closeControls ] = useSwitch(false);
        const [ isHoveringOverConstructor, setHoveringOverConstructor ] = useState(false);
        const [ isHoveringOverDragHandle, startHoveringOverDragHandle, stopHoveringOverDragHandle ] = useSwitch(false);
        const [ isHoveringOverControls, startHoveringOverControls, stopHoveringOverControls ] = useSwitch(false);
        const highlightedLayoutContainer = useContext(HighlightedLayoutContext);
        const isHighlighted =
            useComputed(
                () =>
                    highlightedLayoutContainer.get() === props.layout,
                [
                    highlightedLayoutContainer,
                    props.layout
                ]);

        const isBordered = isHoveringOverConstructor || isHoveringOverDragHandle || isHoveringOverControls || isControlsOpen || isHighlighted;

        const depth = 0; // useContext(CompositeLayoutDepth);

        const dragHandleStyle =
            useMemo(
                () => ({
                    width: 16, // + 3 * depth,
                    zIndex: 1000 - depth
                }),
                [
                    depth
                ]);

        const controlsStyle =
            useMemo(
                () => ({
                    width: 16, // + 3 * depth,
                    zIndex: 1000 - depth
                }),
                [
                    depth
                ]);

        const transitionProps =
            useMemo<TransitionProps>(
                () => ({
                    mountOnEnter: true,
                    unmountOnExit: true,
                }),
                []
            );

        return <div
            className={
                classNames(
                    styles.root,
                    isBordered && styles.bordered)
            }
        >
            <Draggable
                id={props.draggableId}
                index={props.draggableIdx}
                type={props.draggableType}
                disabled={props.draggableId === undefined}
            >
                <div
                    className={
                        classNames(
                            styles.root,
                            props.horizontal && styles.horizontal,
                            props.last && styles.last)}
                >
                    {
                        isBordered &&
                            <div
                                className={styles.label}
                            >
                                {props.layout.getName()}
                            </div>
                    }
                    {
                        props.draggableId !== undefined &&
                            <DragHandle
                                className={styles.dragHandle}
                                onMouseOver={startHoveringOverDragHandle}
                                onMouseLeave={stopHoveringOverDragHandle}
                                style={dragHandleStyle}
                            >
                                <div
                                    className={styles.line}
                                />
                                <div
                                    className={styles.icon}
                                >
                                    <Icon
                                        icon="drag_indicator"
                                        size={11}
                                    />
                                </div>
                            </DragHandle>
                    }
                    {props.editor}
                    <Dialog
                        open={isControlsOpen}
                        onClose={closeControls}
                        TransitionProps={transitionProps}
                        fullWidth={false}
                        width="xl"
                    >
                        {props.controls}
                    </Dialog>
                    <div
                        className={classNames(styles.controls, isControlsOpen && styles.open)}
                        onClick={openControls}
                        onMouseOver={startHoveringOverControls}
                        onMouseLeave={stopHoveringOverControls}
                        style={controlsStyle}
                    >
                        <div
                            className={styles.line}
                        />
                        <div
                            className={styles.icon}
                        >
                            <Icon
                                icon="edit"
                                size={11}
                            />
                        </div>
                    </div>
                </div>
            </Draggable>
            {
                props.onConstruct &&
                    <LayoutConstructor
                        parameterDictionary={props.parameterDictionary}
                        onConstruct={props.onConstruct}
                        mode={props.constructorMode}
                        onHover={setHoveringOverConstructor}
                    />
            }
        </div>;
    };

export default observer(LayoutControlsRegion);
