import React, { useCallback, useMemo } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../Input/Input';
import useTypes from '../../../Type/Api/useTypes';
import Default from '../Default';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import { ContentProps } from '../Content';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import ExpansionGroup from '../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import useSetting from '../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../Setting/SettingStore';
import CocFetcher from '../../../Coc/CocFetcher';
import useRelatedEntities from '../../../../../../@Api/Entity/Hooks/useRelatedEntities';
import Header from '../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import ExpansionPanel from '../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import { updateRelationship } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export interface RelationshipProps extends ContentProps
{
    showRelation?: boolean;
}

const Relationship: React.FC<RelationshipProps> =
    observer(
        props =>
        {
            const types = useTypes();
            const toRelationDefinition =
                useComputed(
                    () =>
                        props.entity.entityType.isA(types.Relationship.Person.Type)
                            ?
                                types.Relationship.Person.RelationshipDefinition.Person
                            :
                                types.Relationship.Organization.RelationshipDefinition.Organization,
                    [
                        props.entity,
                        types
                    ]);
            const relation =
                useComputed(
                    () =>
                        props.entity.getRelatedEntityByDefinition(
                            false,
                            toRelationDefinition,
                            props.commitContext
                        ),
                    [
                        props.entity,
                        toRelationDefinition,
                        props.commitContext,
                    ]);

            const onConstruct =
                useCallback(
                    (entity: Entity) =>
                        updateRelationship(
                            props.entity,
                            false,
                            toRelationDefinition,
                            entity,
                            props.commitContext
                        ),
                    [
                        props.entity,
                        toRelationDefinition,
                        props.commitContext,
                    ]);

            const relationshipFieldPathFilter =
                useCallback(
                    (fieldPath: EntityFieldPath) =>
                        !(fieldPath.relationshipDefinition === toRelationDefinition && !fieldPath.isParentRelationship)
                        && !(fieldPath.relationshipDefinition === types.Relation.RelationshipDefinition.Relationships && fieldPath.isParentRelationship),
                    [
                        toRelationDefinition,
                        types
                    ]);

            const relationFieldPathFilter =
                useCallback(
                    (fieldPath: EntityFieldPath) =>
                        !(fieldPath.relationshipDefinition === toRelationDefinition && fieldPath.isParentRelationship),
                    [
                        toRelationDefinition
                    ]);

            const noParentRelationshipFieldPathFilter =
                useCallback(
                    (fieldPath: EntityFieldPath) =>
                        !(fieldPath.relationshipDefinition === types.Relation.RelationshipDefinition.Relationships && fieldPath.isParentRelationship),
                    [
                        types
                    ]);

            const showRelation =
                useComputed(
                    () =>
                        props.showRelation === undefined
                            ?
                                props.filter(
                                    EntityPath.fromEntity(props.entity)
                                        .joinTo(
                                            toRelationDefinition,
                                            false)
                                        .field())
                            :
                                props.showRelation,
                    [
                        props.showRelation,
                        props.filter
                    ]);

            const relationshipSelectboxProps =
                useMemo(
                    () => ({
                        disableRelationshipCreationOnConstruction: true
                    }),
                    []);

            const toParentRelationFieldPath =
                useMemo(
                    () =>
                        EntityPath.fromEntity(props.entity)
                            .joinTo(
                                types.Relation.RelationshipDefinition.Relationships,
                                true)
                            .field(),
                    [
                        props.entity,
                        types
                    ]);

            const dividerGlue = useDividerGlue();
            const [ hasMultipleRelationshipManagement ] = useSetting<boolean>(SettingSource.Organization, 'CRM.MultipleRelationshipManagement');

            const childRelationships =
                useRelatedEntities(
                    relation,
                    types.Relation.RelationshipDefinition.Relationships,
                    false,
                    props.commitContext
                );

            const hasRelationFields = showRelation && relation && relation.isNew();
            const expandedByDefault =
                useCallback(
                    (code: string) =>
                        code === 'Relation.Communication'
                        || code === 'Relationship.Person.Communication',
                    []
                );

            return <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                {
                    relation && relation.isNew() && relation.entityType.isA(types.Relation.Organization.Type) &&
                        <ViewGroupItem
                            alignment="right"
                        >
                            <CocFetcher
                                entity={relation}
                                relationship={props.entity}
                                commitContext={props.commitContext}
                            />
                        </ViewGroupItem>
                }
                <ViewGroupItem>
                    <InputGroup>
                        {
                            showRelation && (!relation || !relation.isNew()) &&
                                <Input
                                    entity={props.entity}
                                    field={toRelationDefinition}
                                    labelPosition="left"
                                    doAutocommit={props.autoCommit}
                                    onConstruct={onConstruct}
                                    useParentCommitContextForConstruction
                                    touched={props.touched}
                                    relationshipSelectboxProps={relationshipSelectboxProps}
                                    autoFocus={props.autoFocus}
                                    underline={props.underline}
                                    commitContext={props.commitContext}
                                />
                        }
                        {
                            hasRelationFields &&
                                <Default
                                    {...props}
                                    entity={relation}
                                    filter={relationFieldPathFilter}
                                    expandedByDefault={expandedByDefault}
                                    appendix={
                                        <Default
                                            {...props}
                                            entity={props.entity}
                                            filter={relationshipFieldPathFilter}
                                            expandedByDefault={expandedByDefault}
                                            autoFocus={false}
                                        />
                                    }
                                />
                        }
                        {
                            (hasMultipleRelationshipManagement || props.entity.entityType.isA(types.Relationship.Person.Contact.Type)) &&
                                props.filter(toParentRelationFieldPath) &&
                                    <Input
                                        entity={props.entity}
                                        field={types.Relation.RelationshipDefinition.Relationships}
                                        parent
                                        labelPosition="left"
                                        doAutocommit={props.autoCommit}
                                        touched={props.touched}
                                        underline={props.underline}
                                        commitContext={props.commitContext}
                                    />
                        }
                        {
                            !hasRelationFields &&
                            <Default
                                {...props}
                                entity={props.entity}
                                filter={relationshipFieldPathFilter}
                                expandedByDefault={expandedByDefault}
                                autoFocus={false}
                            />
                        }
                    </InputGroup>
                </ViewGroupItem>
                {
                    showRelation
                    && relation
                    && relation.isNew()
                    && !props.entity.entityType.isA(types.Relationship.Person.Contact.Type)
                    &&
                        <ViewGroupItem>
                            <ExpansionGroup>
                                <Divider />
                                {
                                    relation.entityType.isA(types.Relation.Organization.Type) &&
                                        <ViewGroup
                                            orientation="vertical"
                                            spacing={0}
                                            glue={dividerGlue}
                                        >
                                            {
                                                childRelationships &&
                                                    childRelationships.map(
                                                        (childRelationship, idx) =>
                                                            <ExpansionPanel
                                                                key={childRelationship.uuid}
                                                                id={childRelationship.uuid}
                                                                summary={
                                                                    <Header
                                                                        title={`${childRelationship.entityType.getName()} ${idx + 1}`}
                                                                        // title={
                                                                        //     `${childRelationship.entityType.getName()}:
                                                                        //    ${childRelationship.getRelatedEntityByDefinition(false, types.Relationship.Person.RelationshipDefinition.Person).getObjectValueByField(types.Relation.Person.Field.FirstName)}
                                                                        //    ${childRelationship.getRelatedEntityByDefinition(false, types.Relationship.Person.RelationshipDefinition.Person).getObjectValueByField(types.Relation.Person.Field.LastName)}`}
                                                                    />
                                                                }
                                                                expansion={
                                                                    <Relationship
                                                                        {...props}
                                                                        entity={childRelationship}
                                                                        filter={noParentRelationshipFieldPathFilter}
                                                                    />
                                                                }
                                                            />)
                                            }
                                        </ViewGroup>
                                }
                            </ExpansionGroup>
                        </ViewGroupItem>
                }
            </ViewGroup>;
        });

export default Relationship;
