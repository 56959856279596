import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import View from '../../../../../Entity/View/Model/View';
import Column from '../../../../../Entity/View/Model/Column/Column';
import Layout from '../../../../../../../@Api/Layout/Layout';
import ItemLayoutEditor from '../../../../../Entity/View/ItemLayout/Editor/ItemLayoutEditor';

export interface ViewColumnOrderingsEditor
{
    view: View;
    column: Column;
}

const ViewColumnItemLayoutEditor: React.FC<ViewColumnOrderingsEditor> =
    ({
        view,
        column,
     }) =>
    {
        const setItemLayout =
            useCallback(
                (itemLayout?: Layout) =>
                    runInAction(
                        () =>
                            column.itemLayout = itemLayout
                    ),
                [
                    column
                ]
            );

        return <ItemLayoutEditor
            parameters={view.parameters}
            value={column.itemLayout}
            onChange={setItemLayout}
        />;
    };

export default observer(ViewColumnItemLayoutEditor);
