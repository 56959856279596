import React, { useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityFieldGroup } from '../../../Management/Application/EntityTypesEditor/EntityFieldGroup';
import ExpansionPanel from '../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import Expansion from './Expansion/Expansion';
import FieldInsetContext from '../Context/FieldInsetContext';
import styles from './Group.module.scss';
import { FieldsProps } from '../Fields';
import ExpansionGroup from '../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';
import useTypes from '../../Type/Api/useTypes';
import getPredicateFromDescriptor from '../../../../../@Api/Automation/Api/getPredicateFromDescriptor';
import getFieldGroupPredicateParameterDictionary from '../../../../../@Api/Metadata/FieldGroup/Predicate/getFieldGroupPredicateParameterDictionary';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import { EntityParameterId } from '../../../../../@Api/Metadata/FieldGroup/Predicate/FieldGroupPredicateConstants';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import useEntityValue from '../../../../../@Api/Entity/Hooks/useEntityValue';
import { isFieldGroupExpandedByDefault } from '../../../../../@Api/Metadata/FieldGroup/isFieldGroupExpandedByDefault';
import { useFieldGroupExpandedPredicate } from '../../../../../@Api/Metadata/FieldGroup/useFieldGroupExpandedPredicate';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';

export interface GroupProps extends FieldsProps
{
    group: EntityFieldGroup;
    fieldPaths: EntityFieldPath[];
}

const Group: React.FC<GroupProps> =
    props =>
    {
        const { group, entity, commitContext, expanded, expandedByDefault } = props;

        const types = useTypes();
        const hasInset = useContext(FieldInsetContext);

        const parameterDictionary =
            useComputed(
                () =>
                    group.entity &&
                    getFieldGroupPredicateParameterDictionary(group.entity),
                [
                    group
                ]);

        const expandedPredicateValue = useFieldGroupExpandedPredicate(group.entity);

        const [ expandedPredicate, isLoadingPredicate ] =
            useAsyncResult(
                () =>
                {
                    if (expandedPredicateValue && parameterDictionary)
                    {
                        return getPredicateFromDescriptor(
                            expandedPredicateValue,
                            new AutomationDependencyContext(
                                parameterDictionary));
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    expandedPredicateValue,
                    parameterDictionary
                ]);

        const expandedPredicateContext =
            useMemo(
                () =>
                    parameterDictionary &&
                    new FunctionContext(
                        parameterDictionary,
                        new ParameterAssignment()
                            .setValue(
                                parameterDictionary.getParameterById(EntityParameterId),
                                new EntityValue(entity))),
                [
                    parameterDictionary,
                    entity
                ]);

        const code =
            useEntityValue<string>(
                group.entity,
                types.EntityFieldGroup.Field.Code
            );

        const isExpandedByDefault =
            useComputed(
                () =>
                    isLoadingPredicate
                        ? expandedPredicateValue === undefined
                        : expandedPredicate
                            ? expandedPredicate.synchronouslyEvaluate(expandedPredicateContext)
                            : group.entity
                                ? isFieldGroupExpandedByDefault(
                                    entity.entityType,
                                    group.entity,
                                    true
                                ) || (code !== undefined && expandedByDefault !== undefined && expandedByDefault(code))
                                : false,
                [
                    group,
                    entity,
                    isLoadingPredicate,
                    expandedPredicateValue,
                    expandedPredicate,
                    expandedPredicateContext,
                    code,
                    expandedByDefault,
                ]);

        const predicateValue =
            useEntityValue(
                group.entity,
                types.EntityFieldGroup.Field.Predicate);

        const [ predicate, isLoading ] =
            useAsyncResult(
                () =>
                {
                    if (predicateValue && parameterDictionary)
                    {
                        return getPredicateFromDescriptor(
                            predicateValue,
                            new AutomationDependencyContext(
                                parameterDictionary));
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    predicateValue,
                    parameterDictionary
                ]);

        const predicateContext =
            useMemo(
                () =>
                    parameterDictionary &&
                        new FunctionContext(
                            parameterDictionary,
                            new ParameterAssignment()
                                .setValue(
                                    parameterDictionary.getParameterById(EntityParameterId),
                                    new EntityValue(entity)
                                ),
                            commitContext
                        ),
                [
                    parameterDictionary,
                    entity,
                    commitContext,
                ]);

        const isVisible =
            useComputed(
                () =>
                    isLoading
                        ?
                            predicateValue === undefined
                        :
                            predicate
                                ?
                                    predicate.synchronouslyEvaluate(predicateContext)
                                :
                                    true,
                [
                    isLoading,
                    predicateValue,
                    predicate,
                    predicateContext
                ]);

        if (props.ignoreHidden || isVisible)
        {
            return <ExpansionGroup>
                <ExpansionPanel
                    id={group.id || group.customId}
                    summary={
                        <Header
                            title={group.name}
                            inset={hasInset}
                        />
                    }
                    expansion={
                        <div
                            className={styles.expansion}
                        >
                            <Expansion
                                {...props}
                                group={group}
                            />
                        </div>
                    }
                    expanded={isExpandedByDefault || expanded}
                />
            </ExpansionGroup>;
        }
        else
        {
            return null;
        }
    };

export default observer(Group);
