import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../Type/Api/useTypes';
import useResults from '../../../../Selection/Hooks/useResults';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import CardHeader from '../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import DividedList from '../../../../List/V2/Divided/DividedList';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface InvolvedListProps
{
    entity: Entity;
}

const InvolvedList: React.FC<InvolvedListProps> =
    props =>
    {
        const types = useTypes();
        const isContact =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Relationship.Person.Contact.Type),
                [
                    props.entity,
                    types
                ]);
        const involved =
            useResults(
                types.Involved.Type,
                (builder, rootPath) =>
                    builder
                        .join(
                            rootPath
                                .joinTo(
                                    types.Activity.RelationshipDefinition.Involved,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Involved.RelationshipDefinition.Role,
                                    false))
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        isContact
                                            ? types.Involved.RelationshipDefinition.Contact
                                            : types.Involved.RelationshipDefinition.Relationship,
                                        false),
                                    props.entity)),
                [
                    types,
                    props.entity
                ]);

        if (involved && involved.length > 0)
        {
            return <Card>
                <CardInset
                    bottom={false}
                >
                    <CardHeader>
                        <LocalizedText
                            code="Relationships.InvolvedIn"
                            value="Betrokken in"
                        />
                    </CardHeader>
                </CardInset>
                <DividedList
                    entities={involved}
                    inset
                />
            </Card>;
        }
        else
        {
            return null;
        }
    };

export default observer(InvolvedList);
