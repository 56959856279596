import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import SourceFieldMapping from './SourceFieldMapping';

export default class DateSourceFieldMapping extends SourceFieldMapping
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new DateSourceFieldMapping(
            descriptor.id,
            EntityFieldPath.construct(descriptor.targetFieldPath),
            descriptor.sourceFieldId);
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'SourceField.Date',
            sourceFieldId: this.sourceFieldId
        };
    }

    // ----------------------- Private logic ------------------------
}
