import { observable } from 'mobx';
import Predicate from '../Function/Computation/Predicate/Predicate';
import { EntityType } from '../../Model/Implementation/EntityType';
import Validation from '../Validation/Validation';
import ValueType from '../Value/Type/ValueType';
import Parameter from '../Parameter/Parameter';
import EntityValueType from '../Value/Type/EntityValueType';
import Dependency from '../Parameter/Dependency';
import FunctionContext from '../Function/FunctionContext';
import Value from '../Value/Value';
import { QuerySearchParameters } from './QuerySearchParameters';
import { EntitySelectionBuilder } from '../../../@Component/Domain/Entity/Selection/Builder/EntitySelectionBuilder';
import { getSearchTermsFromQuery } from '../../../@Util/Search/getSearchTermsFromQuery';
import ComparisonPredicate from '../Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../../@Component/Domain/DataObject/Model/Comparator';
import PrimitiveValue from '../Value/PrimitiveValue';
import { DataObject } from '../../../@Component/Domain/DataObject/Model/DataObject';
import CompositePredicate from '../Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../@Component/Domain/DataObject/Model/LogicalOperator';
import { isFieldPathSearchableForKeyword } from '../../../@Component/Domain/Entity/View/Api/isFieldPathSearchableForKeyword';
import { getPotentiallySearchableFieldPath } from '../../../@Component/Domain/Entity/View/Api/getPotentiallySearchableFieldPath';

export default abstract class Query
{
    // ------------------------- Properties -------------------------

    static QueryResultParameterId = 'QueryResult';

    @observable.ref parameter: Parameter<EntityValueType>;
    @observable.ref entityType: EntityType;
    @observable.ref filter: Predicate;

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType,
                parameter: Parameter<EntityValueType>,
                filter: Predicate)
    {
        this.entityType = entityType;
        this.parameter = parameter;
        this.filter = filter;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return [];
    }

    getDependencies(): Dependency[]
    {
        if (this.filter)
        {
            return this.filter.getDependencies()
                .filter(
                    dependency =>
                        dependency.parameter !== this.parameter);
        }
        else
        {
            return [];
        }
    }

    toDescriptor()
    {
        const descriptor = {
            entityTypeId: this.entityType.id,
            parameterId: this.parameter.id,
            filter: this.filter?.toDescriptor()
        };

        this.augmentDescriptor(descriptor);

        return descriptor;
    }

    abstract getType(): ValueType<any>;

    abstract execute(context: FunctionContext,
                     searchParameters?: QuerySearchParameters): Promise<Value<any, any>>;

    abstract augmentDescriptor(descriptor: any);

    static getResultParameter(
        entityType: EntityType,
        id?: string
    )
    {
        return new Parameter(
            id ?? Query.QueryResultParameterId,
            new EntityValueType(entityType),
            true,
            entityType.getName());
    }

    augmentBuilderWithSearchParameters(builder: EntitySelectionBuilder,
                                       searchParameters: QuerySearchParameters)
    {
        if (searchParameters.fieldPaths.length > 0)
        {
            for (const keyword of getSearchTermsFromQuery(searchParameters.query))
            {
                const keywordPredicates: Predicate[] = [];

                for (const searchFieldPath of searchParameters.fieldPaths)
                {
                    const potentialSearchFieldPath = getPotentiallySearchableFieldPath(searchFieldPath);

                    if (isFieldPathSearchableForKeyword(potentialSearchFieldPath, keyword))
                    {
                        keywordPredicates.push(
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    this.parameter,
                                    potentialSearchFieldPath),
                                Comparator.Contains,
                                new PrimitiveValue(
                                    DataObject.constructFromTypeIdAndValue(
                                        'Text',
                                        keyword
                                    )
                                )
                            )
                        );
                    }
                }

                if (keywordPredicates.length > 0)
                {
                    builder.where(
                        cb =>
                            cb.filter(
                                new CompositePredicate(
                                    LogicalOperator.Or,
                                    keywordPredicates
                                ),
                                {
                                    parameter: this.parameter,
                                }
                            )
                    );
                }
            }
        }
    }

    // ----------------------- Private logic ------------------------
}
