import React, { useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer/polyfilled';
import * as PopperJS from '@popperjs/core';
import styles from './PopperResizer.module.scss';

export interface PopperResizerProps
{
    update: () => Promise<null | Partial<PopperJS.State>>;
}

const PopperResizer: React.FC<PopperResizerProps> =
    props =>
    {
        const { width, height, ref } = useResizeObserver<HTMLDivElement>();
        const [ previousWidth, setPreviousWidth ] = useState(width);
        const [ previousHeight, setPreviousHeight ] = useState(height);
        const { update } = props;

        useEffect(
            () =>
                setPreviousWidth(width),
            [
                setPreviousWidth,
                width
            ]);

        useEffect(
            () =>
                setPreviousHeight(height),
            [
                setPreviousHeight,
                height
            ]);

        useEffect(
            () =>
            {
                if (width !== previousWidth || height !== previousHeight)
                {
                    update();
                }
            },
            [
                width,
                previousWidth,
                height,
                previousHeight,
                update
            ]);

        return <div
            ref={ref}
            className={styles.root}
        />;
    };

export default PopperResizer;
