import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { ContentProps } from '../Content/Content';
import ViewGroupItem from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LabelButton from '../../../../../../../../../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import { primaryColor, textSecondaryColor } from '../../../../../../../../../../../@Resource/Theme/Theme';
import MenuButton from '../../../../../../../../Item/MenuButton/MenuButton';
import ViewGroup from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useTypes from '../../../../../../../../Type/Api/useTypes';
import EntityExpansionContext from '../../../../Context/EntityExpansionContext';
import CloseButton from '../../../../../../../../../../../@Future/Component/Generic/Button/Variant/CloseButton/CloseButton';
import LocalizedText from '../../../../../../../../../Localization/LocalizedText/LocalizedText';
import CardInset from '../../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import Input from '../../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import Checkbox from '../../../../../../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import CurrentUserContext from '../../../../../../../../../User/CurrentUserContext';

export interface OptionsProps extends ContentProps
{

}

const Options: React.FC<OptionsProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();
        const [ , setExpansion ] = useContext(EntityExpansionContext);
        const close =
            useCallback(
                () =>
                    setExpansion(undefined),
                [
                    setExpansion
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={5}
            alignment="center"
        >
            {
                props.optionsPrefix &&
                    <ViewGroupItem>
                        {props.optionsPrefix}
                    </ViewGroupItem>
            }
            {
                props.hasAttachments &&
                    <ViewGroupItem>
                        <LabelButton
                            icon="attach_file"
                            iconOutlined
                            label={(props.entity.getObjectValueByField(types.Entity.Field.NumberOfAttachments) || '0').toString()}
                            onClick={props.toggleAttachments}
                            color={props.showAttachments ? primaryColor : textSecondaryColor}
                            hoverColor={primaryColor}
                            size="xs"
                            tooltip={
                                !props.showAttachments
                                    ?
                                        <LocalizedText
                                            code="RelatedActivities.ShowAttachments"
                                            value="Toon bijlagen"
                                        />
                                    :
                                        <LocalizedText
                                            code="RelatedActivities.HideAttachments"
                                            value="Verberg bijlagen"
                                        />
                            }
                        />
                    </ViewGroupItem>
            }
            {
                props.hasNotes &&
                    <ViewGroupItem>
                        <LabelButton
                            icon="mode_comment"
                            iconOutlined
                            label={(props.entity.getObjectValueByField(types.Entity.Field.NumberOfNotes) || '0').toString()}
                            onClick={props.toggleNotes}
                            color={props.showNotes ? primaryColor : textSecondaryColor}
                            hoverColor={primaryColor}
                            size="xs"
                            tooltip={
                                !props.showNotes
                                    ?
                                        <LocalizedText
                                            code="RelatedActivities.ShowNotes"
                                            value="Toon notities"
                                        />
                                    :
                                        <LocalizedText
                                            code="RelatedActivities.HideNotes"
                                            value="Verberg notities"
                                        />
                            }
                        />
                    </ViewGroupItem>
            }
            <ViewGroupItem>
                <MenuButton
                    entity={props.entity}
                >
                    {
                        props.onChangeLayoutEditMode && currentUserStore.isSupport &&
                            <>
                                <CardInset
                                    vertical={false}
                                >
                                    <Input
                                        labelPosition="right"
                                        label={
                                            <LocalizedText
                                                code="Metadata.ConfigurationMode"
                                                value="Configuratiemodus"
                                            />
                                        }
                                    >
                                        <Checkbox
                                            checked={props.isLayoutInEditMode}
                                            onToggle={props.onChangeLayoutEditMode}
                                        />
                                    </Input>
                                </CardInset>
                            </>
                    }
                </MenuButton>
            </ViewGroupItem>
            {
                props.closeable &&
                    <ViewGroupItem>
                        <CloseButton
                            onClick={close}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(Options);