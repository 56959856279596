import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import InsetLayout from '../../../../../@Api/Layout/Type/InsetLayout';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import NumberEditor from '../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';
import { runInAction } from 'mobx';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';

export interface InsetLayoutControlsProps extends LayoutEditorProps<InsetLayout>
{

}

const InsetLayoutControls: React.FC<InsetLayoutControlsProps> =
    props =>
    {
        const setTop =
            useCallback(
                (value: number) =>
                    runInAction(
                        () =>
                            props.layout.top = value),
                [
                    props.layout
                ]);

        const setRight =
            useCallback(
                (value: number) =>
                    runInAction(
                        () =>
                            props.layout.right = value),
                [
                    props.layout
                ]);

        const setBottom =
            useCallback(
                (value: number) =>
                    runInAction(
                        () =>
                            props.layout.bottom = value),
                [
                    props.layout
                ]);

        const setLeft =
            useCallback(
                (value: number) =>
                    runInAction(
                        () =>
                            props.layout.left = value),
                [
                    props.layout
                ]);

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.Top"
                            value="Boven"
                        />
                    }
                >
                    <NumberEditor
                        value={props.layout.top}
                        onChange={setTop}
                        min={0}
                    />
                </Input>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.Right"
                            value="Rechts"
                        />
                    }
                >
                    <NumberEditor
                        value={props.layout.right}
                        onChange={setRight}
                        min={0}
                    />
                </Input>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.Bottom"
                            value="Beneden"
                        />
                    }
                >
                    <NumberEditor
                        value={props.layout.bottom}
                        onChange={setBottom}
                        min={0}
                    />
                </Input>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.Left"
                            value="Links"
                        />
                    }
                >
                    <NumberEditor
                        value={props.layout.left}
                        onChange={setLeft}
                        min={0}
                    />
                </Input>
            </InputGroup>
        </CardInset>;
    };

export default observer(InsetLayoutControls);
