import * as React from 'react';
import { SearchStore } from './SearchStore';
import { observer } from 'mobx-react';
import { Page } from '../../../@Service/Navigation/Page/Page';
import Searcher from './Searcher/Searcher';

@observer
export class Search extends Page<SearchStore>
{
    renderBody(store: SearchStore)
    {
        if (store.searchBarStore.hasQuery)
        {
            return <Searcher
                onOpen={store.onOpen}
                onClose={store.closeSearch}
                query={store.searchBarStore.delayedQuery}
            />;
        }
        else
        {
            return null;
        }
    }
}
