import { action, computed, observable } from 'mobx';
import { BaseStore } from '../../../../@Framework/Store/BaseStore';
import { ExpressionEditorStore } from '../../../Domain/Expression/Editor/ExpressionEditorStore';
import { ComputationSpecification } from '../../../Domain/Computation/Type/ComputationSpecification';
import { ComputationTypeStore } from '../../../Domain/Computation/ComputationTypeStore';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { ComputationType } from '../../../Domain/Computation/Type/ComputationType';
import { ComputationContext } from '../../../Domain/Computation/ComputationContext';
import { safelyGetOrUndefined } from '../../../../@Util/Exception/safelyGetOrUndefined';

export class ComputationWidgetStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;

    // ------------------------- Properties -------------------------

    @observable isMouseOver: boolean = false;
    @observable computation: ComputationSpecification;
    @observable expressionEditor: ExpressionEditorStore;
    @observable reference: any;

    // ------------------------ Constructor -------------------------

    constructor(computation: ComputationSpecification, expressionEditor: ExpressionEditorStore)
    {
        super();

        this.computation = computation;
        this.expressionEditor = expressionEditor;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get showValue(): boolean
    {
        return this.expressionEditor.showValue;
    }

    @computed
    get context(): ComputationContext
    {
        return this.expressionEditor.context;
    }

    @computed
    get computationType(): ComputationType
    {
        if (!this.computation.type)
        {
            this.computation = { type: 'Composite' };
        }

        return this.computationTypeStore.getTypeById(this.computation.type);
    }

    @computed
    get description(): string
    {
        return safelyGetOrUndefined(
            () =>
                this.computationType
                    .description(
                        this.context,
                        this.computation)
        );
    }

    @computed
    get value(): string
    {
        const result =
            safelyGetOrUndefined(
                () =>
                    this.computationTypeStore.computeFromSpecification(
                        this.context,
                        this.computation
                    )
            );
        const resultValue = result ? result.value : undefined;

        if (resultValue && resultValue.length > 0)
        {
            return resultValue;
        }
        else
        {
            return '《geen waarde》';
        }
    }

    @computed
    get showTooltip(): boolean
    {
        if (this.reference)
        {
            return this.isMouseOver;
        }
        else
        {
            return false;
        }
    }
    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    setMouseOver(isMouseOver: boolean)
    {
        this.isMouseOver = isMouseOver;
        if (this.isMouseOver)
        {
            this.expressionEditor.setHoverOverWidget(this);
        }
        else
        {
            this.expressionEditor.setHoverOverWidget(undefined);
        }
    }

    @action.bound
    onWidgetClicked()
    {
        if (this.expressionEditor)
        {
            this.expressionEditor.openComputationEditor(this);
        }
    }

    @action.bound
    setReference(node: any)
    {
        this.reference = node;
    }


    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
