import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import DragHandle from '../../../../../../../DragAndDrop/DragHandle/DragHandle';
import Tab from '../../../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import Tooltip from '../../../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import { TabLabel } from '../../../../../../../../../@Future/Component/Generic/TabBar/Tab/TabLabel/TabLabel';
import useEntityValue from '../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import useTypes from '../../../../../../Type/Api/useTypes';
import moment from 'moment';
import LocalizerContext from '../../../../../../../../../@Service/Localization/LocalizerContext';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { classNames } from '../../../../../../../../../@Future/Util/Class/classNames';
import styles from './Milestone.module.scss';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import { Draggable } from 'react-beautiful-dnd';

export interface MilestoneSummaryProps
{
    project: Entity;
    milestone?: Entity;
    idx?: number;
    onOpen: (milestone: Entity, tab: HTMLDivElement) => void;
    active?: boolean;
}

export const Milestone: React.FC<MilestoneSummaryProps> = observer(
    (
        {
            milestone,
            idx,
            onOpen
        }
    ) =>
    {
        const types = useTypes();
        const localizer = useContext(LocalizerContext);

        const tabRef = useRef<HTMLDivElement>();
        const open =
            useCallback(
                () =>
                {
                    onOpen(milestone, tabRef.current);
                },
                [
                    onOpen,
                    milestone,
                    tabRef
                ]);

        const isFinished =
            useEntityValue<boolean>(
                milestone,
                types.Milestone.Field.IsFinished);

        const endDate =
            useEntityValue<Date>(
                milestone,
                types.Milestone.Field.EndDate);

        const isOverdue =
            useMemo(
                () =>
                {
                    if (!isFinished && endDate)
                    {
                        return moment(endDate).startOf('day').isBefore(moment().startOf('day'));
                    }
                    else
                    {
                        return false;
                    }
                },
                [
                    isFinished,
                    endDate
                ]);

        const numberOfDays =
            useMemo(
                () =>
                {
                    if (endDate)
                    {
                        return moment(endDate).startOf('day').diff(moment(), 'day');
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    endDate
                ]);

        const numberOfWeeks =
            useMemo(
                () =>
                {
                    if (numberOfDays === undefined)
                    {
                        return undefined;
                    }
                    else
                    {
                        return Math.round(numberOfDays / 7);
                    }
                },
                [
                    numberOfDays
                ]);

        return <Draggable
            draggableId={milestone.uuid}
            index={idx}
            disableInteractiveElementBlocking
        >
            {(provided) =>
                <Tab
                    value={milestone.uuid}
                    onClick={open}
                    tabProps={{
                        innerRef: provided.innerRef,
                        ...provided.draggableProps,
                        ...provided.dragHandleProps,
                        style: {
                            ...provided.draggableProps.style,
                            textTransform: 'initial',
                        },
                    }}
                >
                    <DragHandle>
                        <Tooltip
                            title={
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={5}
                                >
                                    <ViewGroupItem>
                                        {
                                            endDate === undefined
                                                ?
                                                <LocalizedText
                                                    code="Projects.MilestoneNotPlanned"
                                                    value="Milestone is niet ingepland."
                                                />
                                                :
                                                isOverdue
                                                    ?
                                                    numberOfWeeks > 0
                                                        ?
                                                        <LocalizedText
                                                            code="Projects.MilestoneOverdueWeeks"
                                                            value="Opleverdatum is ${numberOfWeeks} weken geleden op ${endDate} verstreken."
                                                            numberOfWeeks={Math.abs(numberOfWeeks)}
                                                            endDate={localizer.formatDate(endDate)}
                                                        />
                                                        :
                                                        <LocalizedText
                                                            code="Projects.MilestoneOverdueDays"
                                                            value="Opleverdatum is ${numberOfDays} dagen geleden op ${endDate} verstreken."
                                                            numberOfDays={Math.abs(numberOfDays)}
                                                            endDate={localizer.formatDate(endDate)}
                                                        />
                                                    :
                                                    numberOfWeeks > 0
                                                        ?
                                                        <LocalizedText
                                                            code="Projects.MilestoneDeliveryDateWeeks"
                                                            value="Opleverdatum is over ${numberOfWeeks} weken op ${endDate}."
                                                            numberOfWeeks={Math.abs(numberOfWeeks)}
                                                            endDate={localizer.formatDate(endDate)}
                                                        />
                                                        :
                                                        <LocalizedText
                                                            code="Projects.MilestoneDeliveryDateDays"
                                                            value="Opleverdatum is over ${numberOfDays} dagen op ${endDate}."
                                                            numberOfDays={Math.abs(numberOfDays)}
                                                            endDate={localizer.formatDate(endDate)}
                                                        />
                                        }
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <LocalizedText
                                            code="Projects.MilestoneCanBeMoved"
                                            value="Het is mogelijk om deze milestone te verslepen."
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            }
                        >
                            <TabLabel
                                label={milestone.name}
                                secondary={

                                    <span
                                        className={classNames(isOverdue && styles.overdue, isFinished && styles.finished)}
                                    >
                                        {isFinished ? '✓' : undefined}
                                        {/*(numberOfWeeks > 0 ? `${numberOfWeeks}w` : `${numberOfDays}d`)}*/}
                                        </span>
                                }
                            />
                        </Tooltip>
                    </DragHandle>
                </Tab>
            }
        </Draggable>;
    }
)
