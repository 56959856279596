import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import { AssistantListQueryComputation } from '../../Model/Automation/Computation/AssistantListQueryComputation';
import QueryComputation from '../../../../../@Api/Automation/Function/Computation/QueryComputation';
import ListQuery from '../../../../../@Api/Automation/Query/ListQuery';
import { findAssistantEntityTypeOrThrow } from '../findAssistantEntityTypeOrThrow';
import { findAssistantPredicate } from '../Predicate/findAssistantPredicate';
import Query from '../../../../../@Api/Automation/Query/Query';
import uuid from '../../../../../@Util/Id/uuid';

export function findAssistantListQueryComputation(
    computation: AssistantListQueryComputation,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Computation<any, any>
{
    const entityType = findAssistantEntityTypeOrThrow(computation.entityType);
    const parameter =
        Query.getResultParameter(
            entityType,
            uuid()
        );
    const filter =
        computation.filter
            ? findAssistantPredicate(
                computation.filter,
                parameters.getNewDictionaryWithParameter(parameter),
                parameter
            )
            : undefined;

    return new QueryComputation(
        new ListQuery(
            entityType,
            parameter,
            filter,
            [],
            [],
            undefined,
            undefined
        )
    );
}
