import React from 'react';
import { observer } from 'mobx-react-lite';
import Draggable from '../../../../../../DragAndDrop/Draggable/Draggable';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import styles from './Item.module.scss';
import InnerItem from './InnerItem/InnerItem';
import View from '../../../../Model/View';

export interface ItemProps
{
    idx: number;
    view: View;
    entity: Entity;
    disableDragging?: boolean;
    onOpen?: (entity: Entity) => void;
}

const Item: React.FC<ItemProps> =
    props =>
    {
        return <div
            className={styles.root}
        >
            <Draggable
                id={props.entity.uuid}
                index={props.idx}
                disabled={props.disableDragging}
                handleOnContainer
            >
                <InnerItem
                    view={props.view}
                    entity={props.entity}
                    onOpen={props.onOpen}
                />
            </Draggable>
        </div>;
    };

export default observer(Item);
