import React, { useContext, useMemo } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import Name from '../../../../Item/Name/Name';
import HeaderContext from '../../../../Item/Context/HeaderContext';
import styles from './Header.module.scss';
import EntitySummary from '../../../../Summary/EntitySummary';
import TypeName from '../../../Shared/TypeName/TypeName';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../../Input/Input';
import useTypes from '../../../../Type/Api/useTypes';
import useAggregateResult from '../../../../Selection/Hooks/useAggregateResult';
import { Aggregate } from '../../../../../DataObject/Model/Aggregate';
import { default as GenericInput } from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { default as EntityAvatar } from '../../../../Avatar/Avatar';
import { openEntity } from '../../../../@Util/openEntity';
import Icon from '../../../../../../../@Future/Component/Generic/Icon/Icon';
import Link from '../../../../../../../@Future/Component/Generic/Link/Link';
import { primaryColor } from '../../../../../../../@Resource/Theme/Theme';
import MenuButton from '../../../Shared/MenuButton/MenuButton';
import useIsMobile from '../../../../../../../@Util/Responsiveness/useIsMobile';
import Pipeline from '../../../../Workflow/Pipeline/Pipeline';
import EntityLink from '../../../../Link/EntityLink';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import useChildRelation from '../../../../../../../@Api/Entity/Bespoke/Relationship/useChildRelation';
import equalsEntity from '../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import CurrentUserContext from '../../../../../User/CurrentUserContext';
import IsCompactContext from '../../../Context/IsCompactContext';
import Automator from '../../Automation/Automator/Automator';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { useExpansion } from '../../../../Selection/Api/useExpansion';
import hasPack from '../../../../../../../@Api/Pack/hasPack';
import { Pack } from '../../../../../../../@Api/Pack/Pack';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { LinkCoCButton } from '../../../../Coc/LinkCoCButton';

export interface RightColumnProps
{
    entity: Entity;
    commitContext?: CommitContext;
}

const Header: React.FC<RightColumnProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);

        const pipeline =
            useAggregateResult(
                types.Activity.SalesOpportunity.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        props.entity.entityType.isA(types.Relationship.Person.Contact.Type)
                                            ?
                                                types.Relationship.Person.Contact.RelationshipDefinition.Activities
                                            :
                                                types.Relationship.RelationshipDefinition.Activities,
                                        true),
                                    props.entity))
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath
                                        .joinTo(
                                            types.Activity.SalesOpportunity.RelationshipDefinition.Phase,
                                            false)
                                        .field(types.Datastore.Phase.Field.IsTerminal),
                                    undefined,
                                    false))
                        .aggregateOn(
                            rootPath.field(types.Activity.Field.Amount),
                            undefined,
                            Aggregate.Sum),
                [
                    types,
                    props.entity
                ]);

        const parentRelation =
            useComputed(
                () =>
                    !props.entity.entityType.isA(types.Relationship.Organization.Identity.Type) &&
                        props.entity.getRelatedEntityByDefinition(
                            true,
                            types.Relation.RelationshipDefinition.Relationships),
                [
                    props.entity,
                    types
                ]);

        const isMobile = useIsMobile();
        const isCompact = useContext(IsCompactContext) || isMobile;
        const isParentRelationFormer =
            useComputed(
                () =>
                {
                    if (parentRelation)
                    {
                        const parentRelationships = [
                            ...parentRelation.getRelatedEntitiesByDefinition(
                                true,
                                types.Relationship.Organization.RelationshipDefinition.Organization),
                            ...parentRelation.getRelatedEntitiesByDefinition(
                                true,
                                types.Relationship.Person.RelationshipDefinition.Person)
                        ];

                        if (parentRelationships.length > 0)
                        {
                            return parentRelationships.every(
                                relationship =>
                                    relationship.getObjectValueByField(types.Relationship.Field.IsFormer));
                        }
                        else
                        {
                            return false;
                        }
                    }
                    else
                    {
                        return false;
                    }
                },
                [
                    parentRelation,
                    types
                ]);

        const parentRelationUi =
            parentRelation &&
                <span
                    className={styles.parentRelation}
                >
                    <Icon
                        icon={parentRelation.entityType.getInheritedIcon()}
                        size={15}
                        color={primaryColor}
                        className={styles.icon}
                    />
                    <Link
                        onClick={() => openEntity(parentRelation)}
                    >
                        {parentRelation.name} {isParentRelationFormer ? `(${types.Relationship.Field.IsFormer.getName()?.toLowerCase()})` : ''}
                    </Link>
                </span>;

        const relation = useChildRelation(props.entity);

        const pathToOtherRelationships =
            useComputed(
                () =>
                    relation &&
                        (relation.entityType.isA(types.Relation.Person.Type)
                            ?
                                EntityPath.fromEntity(relation)
                                    .joinTo(
                                        types.Relationship.Person.RelationshipDefinition.Person,
                                        true)
                            :
                                EntityPath.fromEntity(relation)
                                    .joinTo(
                                        types.Relationship.Organization.RelationshipDefinition.Organization,
                                        true)),
                [
                    types,
                    relation
                ]);

        useExpansion(
            relation?.entityType,
            () =>
                pathToOtherRelationships
                    ? [ pathToOtherRelationships ]
                    : [],
            () => relation ? [ relation ] : [],
            [
                relation,
                pathToOtherRelationships
            ]);

        const otherRelationships =
            useComputed(
                () =>
                    pathToOtherRelationships
                        ?
                            pathToOtherRelationships
                                .traverseEntity(relation)
                                .filter(
                                    relationship =>
                                        !equalsEntity(
                                            relationship,
                                            props.entity))
                        :
                            [],
                [
                    pathToOtherRelationships,
                    relation,
                    props.entity
                ]);


        const isFormerLink =
            useComputed(
                () =>
                    props.entity.entityType.bespoke.isFormerLink(props.entity),
                [
                    props.entity
                ]
            );

        const hasSalesPack =
            useMemo(
                () => hasPack(Pack.Sales)
            ,
            []
        );


        return <HeaderContext.Provider
            value={true}
        >
            <Card
                inset
            >
                <div
                    className={styles.header}
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={5}
                    >
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={isCompact ? 20 : 50}
                            >
                                <ViewGroupItem
                                    ratio={isCompact ? 1 : undefined}
                                >
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={2}
                                    >
                                        <ViewGroupItem>
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={5}
                                                alignment="end"
                                                wrap
                                            >
                                                <ViewGroupItem>
                                                    <TypeName
                                                        entity={props.entity}
                                                        strikethrough={isFormerLink}
                                                    />
                                                </ViewGroupItem>
                                                {
                                                    relation &&
                                                    <ViewGroupItem>
                                                        <LinkCoCButton
                                                            relation={relation}
                                                            relationship={props.entity}
                                                            commitContext={props.commitContext}
                                                        />
                                                    </ViewGroupItem>
                                                }
                                                {
                                                    parentRelation &&
                                                    otherRelationships.length > 0 &&
                                                    !equalsEntity(
                                                        currentUserStore.environmentEntity,
                                                        parentRelation) &&
                                                        <ViewGroupItem>
                                                            <LocalizedText
                                                                code="RelatedEntity.From"
                                                                value="van"
                                                            />
                                                            &nbsp;
                                                            <EntityLink
                                                                entity={parentRelation}
                                                                highlighted
                                                            />
                                                        </ViewGroupItem>
                                                }
                                                {
                                                    otherRelationships.map(
                                                        otherRelationship =>
                                                            <ViewGroupItem
                                                                key={otherRelationship.uuid}
                                                            >
                                                                / <EntityLink entity={otherRelationship} highlighted tooltip={`${otherRelationship.entityType.getName()} kaart openen`}>{otherRelationship.entityType.getName()}</EntityLink>
                                                                {
                                                                    otherRelationship.getRelatedEntityByDefinition(
                                                                        true,
                                                                        types.Relation.RelationshipDefinition.Relationships) &&
                                                                    !equalsEntity(
                                                                        currentUserStore.environmentEntity,
                                                                        otherRelationship.getRelatedEntityByDefinition(
                                                                            true,
                                                                            types.Relation.RelationshipDefinition.Relationships)) &&
                                                                        <span>
                                                                            &nbsp;
                                                                            <LocalizedText
                                                                                code="RelatedEntity.From"
                                                                                value="van"
                                                                            />
                                                                            &nbsp;
                                                                            <EntityLink
                                                                                entity={otherRelationship.getRelatedEntityByDefinition(
                                                                                    true,
                                                                                    types.Relation.RelationshipDefinition.Relationships
                                                                                )}
                                                                                highlighted
                                                                            />
                                                                        </span>

                                                                }
                                                            </ViewGroupItem>)
                                                }
                                            </ViewGroup>
                                        </ViewGroupItem>
                                        <ViewGroupItem
                                        >
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={15}
                                                alignment="center"
                                            >
                                                <ViewGroupItem>
                                                    <EntityAvatar
                                                        entity={props.entity}
                                                        editable
                                                        size={35}
                                                    />
                                                </ViewGroupItem>
                                                <ViewGroupItem
                                                    ratio={1}
                                                >
                                                    <Name
                                                        entity={props.entity}
                                                        editable
                                                        fitContent
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>
                                {
                                    !isCompact &&
                                        <ViewGroupItem
                                            ratio={2}
                                        >
                                            {
                                                parentRelation &&
                                                    <ViewGroupItem>
                                                        {parentRelationUi}
                                                    </ViewGroupItem>
                                            }
                                            <EntitySummary
                                                entity={props.entity}
                                                clickable
                                            />
                                        </ViewGroupItem>
                                }
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={10}
                                        alignment="center"
                                    >
                                        {
                                            !isCompact &&
                                                <ViewGroupItem>
                                                    <Input
                                                        entity={props.entity}
                                                        field={types.Relationship.RelationshipDefinition.AccountManager}
                                                    />
                                                </ViewGroupItem>
                                        }
                                        {
                                            !isCompact && hasSalesPack &&
                                                <ViewGroupItem>
                                                    <GenericInput
                                                        label={
                                                            <LocalizedText
                                                                code="Generic.InPipeline"
                                                                value="In pijplijn"
                                                            />
                                                        }
                                                        labelPosition="top"
                                                    >
                                                        {pipeline ? pipeline.aggregates[0].toString() : '...'}
                                                    </GenericInput>
                                                </ViewGroupItem>
                                        }
                                        <ViewGroupItem>
                                            <Automator
                                                entity={props.entity}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={props.entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                        {
                            isCompact &&
                                <ViewGroupItem>
                                    {parentRelationUi}
                                    <EntitySummary
                                        entity={props.entity}
                                        clickable
                                    />
                                </ViewGroupItem>
                        }
                        <ViewGroupItem>
                            <Pipeline
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </div>
            </Card>
        </HeaderContext.Provider>;
    };

export default observer(Header);
