import Dependency from '../../../../Automation/Parameter/Dependency';
import Validation from '../../../../Automation/Validation/Validation';
import LayoutDependencyContext from '../../../LayoutDependencyContext';
import FormInputLayout from './FormInputLayout';
import localizeText from '../../../../Localization/localizeText';
import getComputationFromDescriptor from '../../../../Automation/Api/getComputationFromDescriptor';
import Computation from '../../../../Automation/Function/Computation/Computation';
import { observable } from 'mobx';
import Predicate from '../../../../Automation/Function/Computation/Predicate/Predicate';
import getPredicateFromDescriptor from '../../../../Automation/Api/getPredicateFromDescriptor';
import LayoutEventTriggers from '../../../Event/LayoutEventTriggers';

export default class DrawingFormInputLayout extends FormInputLayout
{
    // ------------------------- Properties -------------------------

    @observable height: number;

    // ------------------------ Constructor -------------------------

    constructor(formId: string,
                parameterId: string,
                placeholder: Computation<any, any> | undefined,
                isDisabled: Predicate | undefined,
                hasIntrinsicSize: boolean = false,
                eventTriggers: LayoutEventTriggers = undefined,
                height: number)
    {
        super(formId, parameterId, placeholder, isDisabled, hasIntrinsicSize, eventTriggers);

        this.height = height;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.DrawingFormInput', 'Input veld van formulier (tekening)');
    }

    validate(): Validation[]
    {
        return super.validate();
    }

    getDependencies(): Dependency[]
    {
        return super.getDependencies();
    }

    _toDescriptor(descriptor: any)
    {
        Object.assign(
            descriptor,
            {
                type: 'DrawingFormInput',
                height: this.height
            });
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const placeholder =
            descriptor.placeholder
                ?
                    await getComputationFromDescriptor(
                        descriptor.placeholder,
                        dependencyContext)
                :
                    undefined;
        const isDisabled =
            descriptor.isDisabled
                ?
                    await getPredicateFromDescriptor(
                        descriptor.isDisabled,
                        dependencyContext)
                :
                    undefined;
        const eventTriggers =
            await LayoutEventTriggers.fromDescriptor(
                descriptor.eventTriggers || [],
                dependencyContext);

        return new DrawingFormInputLayout(
            descriptor.formId,
            descriptor.parameterId,
            placeholder,
            isDisabled,
            descriptor.hasIntrinsicSize,
            eventTriggers,
            descriptor.height);
    }

    // ----------------------- Private logic ------------------------
}
