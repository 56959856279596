import { Entity } from '../../../../Model/Implementation/Entity';

export class CommitBuilderResult
{
    // ------------------------- Properties -------------------------

    private entityById: Map<string, Entity>;

    // ------------------------ Constructor -------------------------

    constructor(entityById: Map<string, Entity>)
    {
        this.entityById = entityById;
    }

    // ----------------------- Initialization -----------------------

    // ------------------------ Public logic ------------------------

    public getEntity(id: string): Entity | undefined
    {
        const entity = this.entityById.get(id);

        if (entity)
        {
            return entity;
        }
        else
        {
            throw new Error(`Entity with ID: ${id} not found`);
        }
    }

    public withEntity<T>(
        id: string,
        callback: (entity: Entity) => T
    ): T
    {
        return callback(this.getEntity(id));
    }

    // ----------------------- Private logic ------------------------
}
