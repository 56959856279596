import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { TableCell } from '@material-ui/core';
import Column from '../../../Model/Specification/Column';
import { EntitySelectionAggregateResult } from '../../../../Selection/Model/EntitySelectionAggregateResult';
import { DataObjectRepresentation, DataObjectRepresentationProps } from '../../../../../DataObject/Model/DataObjectRepresentation';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';

export interface TotalCellProps
{
    aggregatedVisibleColumns: Column[];
    column: Column;
    result?: EntitySelectionAggregateResult;
    color?: string;
    prefix: React.ReactNode;
}

export const TotalCell: React.FC<TotalCellProps> =
    observer(
        ({
            aggregatedVisibleColumns,
            column,
            result,
            color,
            prefix,
         }) =>
        {
            const aggregate =
                useComputed(
                    () =>
                    {
                        const idx = aggregatedVisibleColumns.indexOf(column);

                        if (result !== undefined && idx >= 0 && idx + 1 <= result.aggregates.length)
                        {
                            return result?.aggregates[idx + 1]?.toString(
                                new DataObjectRepresentation<DataObjectRepresentationProps>({
                                    isCompact: true,
                                })
                            );
                        }
                        else
                        {
                            return undefined;
                        }
                    },
                    [aggregatedVisibleColumns, column, result]
                );

            return <TableCell>
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                >
                    {
                        prefix &&
                        <ViewGroupItem>
                            {prefix}
                        </ViewGroupItem>
                    }
                    {
                        aggregate &&
                        <ViewGroupItem>
                            <span
                                style={{
                                    color,
                                    fontWeight: 500,
                                }}
                            >
                                {aggregate}
                            </span>
                        </ViewGroupItem>
                    }
                </ViewGroup>
            </TableCell>;
        }
    );
