import * as React from 'react';
import { EntityConstructor } from '../EntityConstructor';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { EntityRelationshipConstructorStore } from './EntityRelationshipConstructorStore';
import { observer } from 'mobx-react';
import Input from '../../Input/Input';

@observer
export class EntityRelationshipConstructor extends BaseComponent<EntityRelationshipConstructorStore>
{
    renderComponent(store: EntityRelationshipConstructorStore)
    {
        if (store.relatedEntityConstructorStore)
        {
            return <EntityConstructor
                store={store.relatedEntityConstructorStore}
            />;
        }
        else
        {
            return <Input
                entity={store.entity}
                field={store.relationshipDefinition}
                parent={store.isParent}
                touched={store.isTouched}
            />;
        }
    }
}
