import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { classNames } from '../../../Util/Class/classNames';

const useStyles = makeStyles({
    root: {
        transition: 'all 0.5s',
    },
    focusedWithCommitButton: {
        marginTop: 5,
        marginBottom: 5,
        padding: 10,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 10,
    },
});

export interface FocusFieldProps
{
    focused?: boolean;
}

export const FocusField: React.FC<FocusFieldProps> =
    ({
        focused: isFocused,
        children,
     }) =>
    {
        const classes = useStyles();

        return <Box
            className={
                classNames(
                    classes.root,
                    isFocused && classes.focusedWithCommitButton
                )
            }
        >
            <Box
                flex={1}
            >
                {children}
            </Box>
        </Box>;
    };
