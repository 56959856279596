import { green } from '@material-ui/core/colors';
import getTypes from '../Type/Api/getTypes';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import getMetadataSettingValue from '../../../../@Api/Metadata/getMetadataSettingValue';
import { Setting } from '../../../../@Api/Settings/Setting';

export default function getEntityTypeColorForPlanner(entityType: EntityType) : string
{
    const types = getTypes();
    const setting = getMetadataSettingValue<string>(entityType, Setting.Metadata.PlannerColor);

    if (setting === undefined)
    {
        if (entityType.isA(types.TimeRegistration.Type))
        {
            return green[500];
        }
        else
        {
            return '#3788d8';
        }
    }
    else
    {
        return setting;
    }
}
