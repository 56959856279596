import { NumberSequenceDefinition } from '../Definition/DefinitionBuilder';
import moment from 'moment';

export default function generateNumberSequence(
    definition: NumberSequenceDefinition,
    number: number,
    minNumberLength: number)
{
    return definition.components
        .map(
            c =>
            {
                const now = moment(new Date());

                switch (c.type)
                {
                    case 'Number': return (number + '').padStart(minNumberLength, '0');
                    case 'Text': return c.data;
                    case 'DateDD': return now.format('DD');
                    case 'DateYY': return now.format('YY');
                    case 'DateYYYY': return now.format('YYYY');
                    case 'DateMM': return now.format('MM');
                    case 'DateWW': return now.format('WW');
                    case 'FieldPath': return 'FP';
                    default: return '';
                }
            })
            .join('');
}
