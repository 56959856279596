import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { default as InternalNote } from '../../../../../../../../../Item/Content/Note/Note';
import { ContentProps } from '../Content';
import Base from '../Base/Base';
import useRelatedEntity from '../../../../../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import useTypes from '../../../../../../../../../Type/Api/useTypes';
import MenuButton from '../../../../../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import CardInset from '../../../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import Input from '../../../../../../../../../Input/Input';
import useEntityValue from '../../../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { primaryColor, textSecondaryColor } from '../../../../../../../../../../../../@Resource/Theme/Theme';
import equalsEntity from '../../../../../../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import CurrentUserContext from '../../../../../../../../../../User/CurrentUserContext';

export interface NoteProps extends ContentProps
{

}

const Note: React.FC<NoteProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);

        const parentEntity =
            useRelatedEntity(
                props.entity,
                types.Entity.RelationshipDefinition.Notes,
                true);

        const isParentEntitySupportTicket =
            useComputed(
                () =>
                    parentEntity?.entityType.isA(types.Activity.SupportTicket.Type),
                [
                    parentEntity,
                    types
                ]);

        const isVisibleInSupportPortal =
            useEntityValue(
                props.entity,
                types.Note.Field.IsVisibleInSupportPortal);

        const creator =
            useRelatedEntity(
                props.entity,
                types.Relationship.Person.Contact.Employee.RelationshipDefinition.CreatedEntities,
                true);

        const isEditable =
            useComputed(() =>
                creator !== undefined && equalsEntity(creator, currentUserStore.employeeEntity),
                [
                    creator,
                    currentUserStore
                ]);

        const visibilityButton =
            isParentEntitySupportTicket && types.Note.Field.IsVisibleInSupportPortal &&
            <MenuButton
                icon="visibility"
                color={isVisibleInSupportPortal ? primaryColor : textSecondaryColor}
            >
                <CardInset>
                    <Input
                        disabled={!isEditable}
                        entity={props.entity}
                        labelPosition="left"
                        field={types.Note.Field.IsVisibleInSupportPortal}
                        doAutocommit={!props.entity.isNew()}
                        commitContext={props.commitContext}
                    />
                </CardInset>
            </MenuButton>;

        return <Base
            {...props}
            noInputGroup
            optionsPrefix={
                visibilityButton
            }
        >
            <InternalNote
                entity={props.entity}
                hideAvatar
                hideCreator
                hideCreationDate
            />
        </Base>;
    };

export default observer(Note);
