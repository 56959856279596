import { PhoneNumberEditor } from './PhoneNumberEditor';
import { PhoneNumberView } from './PhoneNumberView';
import { PhoneNumberSpecification } from './PhoneNumberSpecification';
import { TextType } from '../TextType';
import { DataObjectEditorType, DataObjectSpecificationType, DataObjectViewerType } from '../../../Model/DataObjectType';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import Editor from './Editor';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export class PhoneNumberType extends TextType
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'TelephoneNumber';
    }

    name()
    {
        return localizeText('DataObject.Type.PhoneNumber', 'Telefoon nummer');
    }

    view(): DataObjectViewerType
    {
        return PhoneNumberView;
    }

    hooksEditor()
    {
        return Editor;
    }

    editor(): DataObjectEditorType
    {
        return PhoneNumberEditor;
    }

    specification(): DataObjectSpecificationType
    {
        return PhoneNumberSpecification;
    }
}
