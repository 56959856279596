import { IntervalType } from '../DateIntervalValue';

export default function getIntervalMultiplier(fromInterval: IntervalType,
                                              toInterval: IntervalType)

{
    switch (fromInterval)
    {
        case 'Daily':
            switch (toInterval)
            {
                case 'Daily':
                    return 1;

                case 'Weekly':
                    return 7;

                case 'FourWeekly':
                    return 28;

                case 'Monthly':
                    return 365 / 12;

                case 'Quarterly':
                    return 365 / 4;

                case 'HalfYearly':
                    return 365 / 2;

                case 'Yearly':
                    return 365;
            }
            break;

        case 'Weekly':
            return 1 / 7 * getIntervalMultiplier('Daily', toInterval);

        case 'FourWeekly':
            return 4 * getIntervalMultiplier('Weekly', toInterval);

        case 'Monthly':
            return 12 / 365 * getIntervalMultiplier('Daily', toInterval);

        case 'Quarterly':
            return 1 / 3 * getIntervalMultiplier('Monthly', toInterval);

        case 'HalfYearly':
            return 1 / 6 * getIntervalMultiplier('Monthly', toInterval);

        case 'Yearly':
            return 1 / 365 * getIntervalMultiplier('Daily', toInterval);
    }
}
