import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { WidgetProps } from '../Widget';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import PlanButton from '../../../Agenda/PlanButton/PlanButton';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { removeValuesFromStorage } from '../../../../Setting/Api/useLocalSetting';
import { FullscreenAgendaWidgetModel } from '../../Model/Widget/FullscreenAgenda/FullscreenAgendaWidgetModel';
import EntityCalendar from '../../../Calendar/EntityCalendar';
import useWindowSize from '../../../../../../@Future/Util/DOM/useWindowSize';
import Item from '../../../../../../@Future/Component/Generic/Menu/Item/Item';

export interface FullscreenAgendaProps extends WidgetProps<FullscreenAgendaWidgetModel>
{

}

export const FullscreenAgendaWidget: React.FC<FullscreenAgendaProps> = observer(
    ({
        widget,
        onDelete
     }) =>
    {
        const settingStorageKey =
            useMemo(
                () =>
                    `Widget.${widget.id}.FullScreenAgenda`,
                [
                    widget.id
                ]
            );

        const localOnDelete =
            useCallback(
                () =>
                {
                    removeValuesFromStorage(settingStorageKey);
                    if (onDelete)
                    {
                        onDelete();
                    }
                },
                [
                    onDelete,
                    settingStorageKey,
                ]
            );

        const windowSize = useWindowSize();

        return <Card>
            <DragHandle>
                <CardInset>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            <CardHeader>
                                <LocalizedText
                                    code="Generic.Agenda"
                                    value="Agenda"
                                />
                            </CardHeader>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <PlanButton />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <MenuButton>
                                <Menu>
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Generic.Delete"
                                                value="Verwijderen"
                                            />
                                        }
                                        onClick={localOnDelete}
                                    />
                                </Menu>
                            </MenuButton>
                        </ViewGroupItem>
                    </ViewGroup>
                </CardInset>
            </DragHandle>
            <CardInset
                top={false}
            >
                <EntityCalendar
                    viewType="week"
                    height={windowSize.height - 250}
                    settingStorageKey={settingStorageKey}
                />
            </CardInset>
        </Card>;
    }
);
