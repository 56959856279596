import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../Type/Api/useTypes';
import Click from '../Click/Click';
import Inset from '../Inset/Inset';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import Avatar from '../Relationship/Avatar/Avatar';
import Header from '../Relationship/Header/Header';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import Link from '../../Caption/Link/Link';
import { useExpansion } from '../../../Selection/Api/useExpansion';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import Input from '../../../Input/Input';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';

export interface LeexiParticipantProps
{
    entity: Entity;
}

export const LeexiParticipant: React.FC<LeexiParticipantProps> = observer(
    ({
        entity
    }) =>
    {
        const types = useTypes();

        const [isLoading] =
            useExpansion(
                entity.entityType,
                () => [
                    EntityPath.fromEntity(entity)
                        .joinTo(
                            types.Relationship.RelationshipDefinition.LeexiCallParticipants,
                            true),
                    EntityPath.fromEntity(entity)
                        .joinTo(
                            types.Relationship.Person.Contact.RelationshipDefinition.ContactLeexiCallParticipants,
                            true),
                ],
                () => [
                    entity
                ],
                [
                    types,
                    entity,
                ]
            );

        const participantRelationship =
            useComputed(
                () =>
                    entity.getRelatedEntityByDefinition(
                        true,
                        types.Relationship.RelationshipDefinition.LeexiCallParticipants
                    ),
                [
                    entity,
                    types
                ]);

        const participantContact =
            useComputed(
                () =>
                    entity.getRelatedEntityByDefinition(
                        true,
                        types.Relationship.Person.Contact.RelationshipDefinition.ContactLeexiCallParticipants
                    ),
                [
                    entity,
                    types
                ]);

        return !isLoading && <Click
            open
            target={participantContact ?? participantRelationship}
        >
            <Inset>
                <ViewGroup
                    orientation="horizontal"
                    spacing={10}
                >
                    <ViewGroupItem>
                        <Avatar
                            entity={participantContact ?? participantRelationship}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem
                        ratio={1}
                    >
                        <Header
                            entity={participantContact ?? participantRelationship}
                            pathFromRelatedEntity={undefined}
                        />
                        <ViewGroup
                            spacing={0}
                            orientation="vertical"
                        >
                            <ViewGroupItem>
                            {
                                participantContact &&
                                <>
                                    {participantContact.entityType.getName()}
                                    &nbsp;
                                    <LocalizedText
                                        code="Generic.Of"
                                        value="van"
                                    />
                                    &nbsp;
                                    <Link
                                        entity={participantRelationship}
                                    />
                                </>
                            }
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <InputGroup>
                                    <Input
                                        entity={entity}
                                        field={types.LeexiCallParticipant.Field.Email}
                                        labelPosition="left"
                                        disabled
                                    />
                                    <Input
                                        entity={entity}
                                        field={types.LeexiCallParticipant.Field.PhoneNumber}
                                        labelPosition="left"
                                        disabled
                                    />
                                </InputGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </Inset>
        </Click>;
    }
);