import { action, computed, observable } from 'mobx';
import { BaseStore, getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { LocalizationStore } from '../../../../@Service/Localization/LocalizationStore';
import { PredicateTypeStore } from '../PredicateTypeStore';
import { PredicateEditorStore } from '../PredicateEditorStore';
import { ButtonStore } from '../../../Generic/Button/ButtonStore';
import { PredicateContext } from '../PredicateContext';
import { OldPredicate } from '../Type/OldPredicate';
import { ModalDialogStore } from '../../../Generic/Dialog/Type/ModalDialogStore';
import { ViewComponent } from '../../../Generic/ViewStack/Model/ViewComponent';
import { PredicateEditor } from '../PredicateEditor';

export interface PredicatePickerProps
{
    context: PropType<PredicatePickerStore, PredicatePickerProps, PredicateContext>;
    predicate: PropType<PredicatePickerStore, PredicatePickerProps, OldPredicate>;
    dialogTitle: PropType<PredicatePickerStore, PredicatePickerProps, string>;
    onChange?: (predicate: OldPredicate) => Promise<any>;
    onDelete?: () => Promise<any>;
}

const defaultProps: Partial<PredicatePickerProps> =
{

};

export class PredicatePickerStore extends BaseStore<PredicatePickerProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('PredicateTypeStore') predicateTypeStore: PredicateTypeStore;

    // ------------------------- Properties -------------------------

    @observable isEditorVisible: boolean = false;

    // ------------------------ Constructor -------------------------

    constructor(props: PredicatePickerProps)
    {
        super(props, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get predicate(): OldPredicate
    {
        return getOrCompute(this, this.props.predicate);
    }

    @computed
    get initialSpecification(): any
    {
        return this.predicate
            ?
                this.predicateTypeStore.toSpecification(this.predicate)
            :
                {
                    type: 'Composite',
                    logicalOperator: 'And'
                };
    }

    @computed
    get predicateContext(): PredicateContext
    {
        return getOrCompute(this, this.props.context);
    }

    @computed
    get dialogTitle(): string
    {
        return getOrCompute(this, this.props.dialogTitle);
    }

    // --------------------------- Stores ---------------------------

    @computed
    get predicateEditorStore(): PredicateEditorStore
    {
        if (this.predicateContext && this.initialSpecification)
        {
            return new PredicateEditorStore(
                this.predicateTypeStore.getTypeById(this.initialSpecification.type),
                this.predicateContext,
                this.initialSpecification);
        }
        else
        {
            return undefined;
        }
    }

    get modalDialogStore(): ModalDialogStore
    {
        if (this.predicateEditorStore)
        {
            return new ModalDialogStore({
                title: this.dialogTitle,
                isOpen: this.isEditorVisible,
                isFullWidth: true,
                maxWidth: 'md',
                viewComponent:
                    new ViewComponent(
                        PredicateEditor,
                        this.predicateEditorStore),
                buttons:
                [
                    new ButtonStore(
                    {
                        label: this.localizationStore.translate('Generic.Cancel'), // Cancel
                        onClick: this.closeEditor,
                        isDisabled: this.isLoading
                    }),
                    new ButtonStore(
                    {
                        label: this.localizationStore.translate('Generic.Save'), // Save
                        color: 'primary',
                        isRaised: true,
                        onClick: this.saveAndClose,
                        isDisabled: this.isLoading
                    })
                ]
            });
        }
        else
        {
            return undefined;
        }
    }


    @computed
    get editButtonStore(): ButtonStore
    {
        return new ButtonStore({
            color: 'primary',
            isRaised: true,
            label:
                !this.predicate
                    ?
                        this.localizationStore.translate('Generic.Add') // Add
                    :
                        this.localizationStore.translate('Generic.Edit'), // Edit
            onClick: this.showEditor,
            style: { marginRight: 8 }
        });
    }

    @computed
    get deleteButtonStore(): ButtonStore
    {
        return new ButtonStore({
            isRaised: true,
            color: 'red',
            label: this.localizationStore.translate('Generic.Delete'), // Delete
            onClick: this.deletePredicate
        });
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    showEditor()
    {
        this.isEditorVisible = true;
    }

    @action.bound
    closeEditor()
    {
        this.isEditorVisible = false;
    }

    @action.bound
    saveAndClose()
    {
        if (this.props.onChange)
        {
            this.props
                .onChange(this.predicateTypeStore.fromSpecification(
                    this.predicateEditorStore && this.predicateEditorStore.descriptor))
                .then(result =>
                    this.closeEditor());
        }
        else
        {
            this.closeEditor();
        }
    }

    @action.bound
    deletePredicate()
    {
        if (this.props.onDelete)
        {
            this.props.onDelete()
                .then(result =>
                    this.closeEditor());
        }
        else
        {
            this.closeEditor();
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

}
