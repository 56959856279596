import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import LayoutDependencyContext from '../LayoutDependencyContext';
import { observable } from 'mobx';
import localizeText from '../../Localization/localizeText';
import Computation from '../../Automation/Function/Computation/Computation';
import getComputationFromDescriptor from '../../Automation/Api/getComputationFromDescriptor';

export default class PortalRouteLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref route: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(route: Computation<any, any>)
    {
        super();

        this.route = route;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.PortalRoute', 'Portaal pagina');
    }

    validate(): Validation[]
    {
        return this.route.validate();
    }

    getDependencies(): Dependency[]
    {
        return this.route.getDependencies();
    }

    toDescriptor()
    {
        return {
            type: 'PortalRoute',
            route: this.route.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const route =
            await getComputationFromDescriptor(
                descriptor.route,
                dependencyContext);

        return new PortalRouteLayout(route);
    }

    // ----------------------- Private logic ------------------------
}
