import * as React from 'react';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useAsyncResult from '../../../../@Util/Async/useAsyncResult';
import { sanitize } from '../../../../@Util/Html/HtmlUtils';

const innerStyleContent =
    `
    *
    {
        word-break: break-word;
    }
    
    p
    {
        margin-top: 0;
        margin-bottom: 0;
    }
    
    .ql-align-center
    {
        text-align: center;
    }
    
    .ql-align-right
    {
        text-align: right;
    }
    
    .ql-size-small
    {
        font-size: 0.75em;
    }
    
    .ql-size-large
    {
        font-size: 1.5em;
        line-height: 1.1;
    }
    
    .ql-size-huge
    {
        font-size: 2.5em;
        line-height: 1.2;
    }
    
    ol > li {
        list-style-type: none;
    }

    ol li {
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-0;
    }
    ol li:before {
        content: counter(list-0, decimal) '. ';
    }
    ol li.ql-indent-1 {
        counter-increment: list-1;
    }
    ol li.ql-indent-1:before {
        content: counter(list-1, lower-alpha) '. ';
    }
    ol li.ql-indent-1 {
        counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-2 {
        counter-increment: list-2;
    }
    ol li.ql-indent-2:before {
        content: counter(list-2, lower-roman) '. ';
    }
    ol li.ql-indent-2 {
        counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }
    .ql-indent-1 {
        margin-left: 50px
    }
    .ql-indent-2 {
        margin-left: 100px
    }
    .ql-indent-3 {
        margin-left: 150px
    }
    .ql-indent-4 {
        margin-left: 200px
    }
    .ql-indent-5 {
        margin-left: 250px
    }
    .ql-indent-6 {
        margin-left: 300px
    }
    .ql-indent-7 {
        margin-left: 350px
    }
    .ql-indent-8 {
        margin-left: 400px
    }
    .ql-indent-9 {
        margin-left: 450px
    }
    `;

export interface RichTextSpanProps
{
    value: string;
}

const RichTextSpan: React.FC<RichTextSpanProps> =
    props =>
    {
        const [ result ] =
            useAsyncResult(
                () =>
                    sanitize(props.value, true),
                [
                    props.value
                ]);
        const [ ref, setRef ] = useState<HTMLSpanElement | undefined>(undefined);
        useEffect(
            () =>
            {
                if (ref && result)
                {
                    if (ref.attachShadow === undefined)
                    {
                        ref.innerHTML = result;
                    }
                    else
                    {
                        // Use shadow DOM to avoid <style> tags inside the text contents to bleed out from this component
                        const shadow = ref.shadowRoot ?? ref.attachShadow({ mode: 'open' });

                        const innerStyle = document.createElement('style');
                        innerStyle.textContent = innerStyleContent;
                        shadow.appendChild(innerStyle);

                        const innerSpan = document.createElement('span');
                        innerSpan.innerHTML = result;
                        shadow.appendChild(innerSpan);
                    }
                }
            },
            [
                ref,
                result,
            ]
        );

        if (result)
        {
            return <span
                ref={setRef}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(RichTextSpan);

