import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { WidgetProps } from '../Widget';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import EmployeeAllocationWidget from '../../Model/Widget/EmployeeAllocation/EmployeeAllocationWidget';
import { EmployeeAllocationEditor } from '../../../EmployeeAllocationEditor/EmployeeAllocationEditor';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Typography } from '@material-ui/core';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { getDefaultEmployeeAllocationSpecification } from '../../../EmployeeAllocationEditor/Api/getDefaultEmployeeAllocationSpecification';
import { removeValuesFromStorage } from '../../../../Setting/Api/useLocalSetting';

export interface EmployeeAllocationProps extends WidgetProps<EmployeeAllocationWidget>
{

}

const EmployeeAllocation: React.FC<EmployeeAllocationProps> =
    ({
        onDelete,
        widget,
     }) =>
    {
        const specification =
            useMemo(
                () =>
                    getDefaultEmployeeAllocationSpecification(),
                []
            );

        const settingStorageKey =
            useMemo(
                () =>
                    `Widget.${widget.id}.EmployeeAllocation`,
                [
                    widget.id
                ]
            );

        const onDeleteItem =
            useCallback(
                () =>
                {
                    removeValuesFromStorage(settingStorageKey);
                    if (onDelete)
                    {
                        onDelete();
                    }
                },
                [
                    onDelete,
                    settingStorageKey,
                ]
            );

        return <Card>
            <CardInset
                horizontal={false}
                bottom={false}
            >
                <EmployeeAllocationEditor
                    specification={specification}
                    title={
                        <Typography
                            variant="body1"
                        >
                            <LocalizedText
                                code="EmployeeAllocation"
                                value="Medewerker bezetting"
                            />
                        </Typography>
                    }
                    options={
                        <ViewGroup
                            orientation="horizontal"
                            spacing={5}
                            alignment="center"
                        >
                            <ViewGroupItem>
                                <MenuButton>
                                    <Menu>
                                        <DeleteItem
                                            onClick={onDeleteItem}
                                        />
                                    </Menu>
                                </MenuButton>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <DragHandle
                                    horizontal
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    }
                    settingStorageKey={settingStorageKey}
                />
            </CardInset>
        </Card>;
    };

export default observer(EmployeeAllocation);
