import { action, observable } from 'mobx';
import { RelatedEntityConstructorStore } from '../RelatedEntityConstructorStore';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import { EntityController } from '../../../../../../@Api/Controller/Directory/EntityController';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { ViewComponent } from '../../../../../Generic/ViewStack/Model/ViewComponent';
import { ButtonStore } from '../../../../../Generic/Button/ButtonStore';
import { DialogStore } from '../../../../../Generic/Dialog/Type/DialogStore';
import { RelatedEntityConstructor } from '../RelatedEntityConstructor';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { EntityViewerStore } from '../../../Viewer/Deprecated/EntityViewerStore';
import { DrawerStore } from '../../../../../Generic/Drawer/DrawerStore';
import { EntityTypeStore } from '../../../Type/EntityTypeStore';
import { RouterStore } from '../../../../../../@Service/Router/RouterStore';
import { EntityViewerPageStore } from '../../../Viewer/Page/EntityViewerPageStore';

export class RelatedEntityConstructorModalStore extends DialogStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('EntityController') entityController: EntityController;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('LeftDrawerStore') drawerStore: DrawerStore;
    @injectWithQualifier('RouterStore') routerStore: RouterStore;

    // ------------------------- Properties -------------------------

    @observable relatedEntityConstructorStore: RelatedEntityConstructorStore;
    @observable onSave?: (entity: Entity, dialogStore: RelatedEntityConstructorModalStore) => Promise<any>;
    @observable onClose?: (dialogStore: RelatedEntityConstructorModalStore) => void;
    @observable onOpen?: (entity: Entity, dialogStore: RelatedEntityConstructorModalStore) => void;

    // ------------------------ Constructor -------------------------

    constructor(relatedEntityConstructorStore: RelatedEntityConstructorStore,
                onSave: (entity: Entity, dialogStore: RelatedEntityConstructorModalStore) => Promise<any>,
                onClose: (dialogStore: RelatedEntityConstructorModalStore) => void,
                onOpen?: (entity: Entity, dialogStore: RelatedEntityConstructorModalStore) => void)
    {
        super(
        {
            title:
                () =>
                    relatedEntityConstructorStore.rootEntity
                        ?
                            `${relatedEntityConstructorStore.path.entityType.getName()} toevoegen aan ${relatedEntityConstructorStore.rootEntity.entityType.getName().toLowerCase()}`
                        :
                            undefined,
            buttons:
                () =>
                    [
                        new ButtonStore(
                        {
                            onClick: () => this.close(true),
                            label: () => this.localizationStore.translate('Generic.Cancel'), // Cancel
                            isLoading: () => this.isLoading
                        }),
                        new ButtonStore(
                        {
                            onClick: () => this.save(false),
                            label: () => this.localizationStore.translate('Generic.Save'), // Save,
                            color: 'primary',
                            isRaised:
                                () =>
                                    !this.relatedEntityConstructorStore.relatedEntity
                                        ||
                                    !this.relatedEntityConstructorStore.path.entityType.bespoke.isOpenable(
                                        this.relatedEntityConstructorStore.relatedEntity,
                                        this.relatedEntityConstructorStore.relatedFromEntityPath)
                        }),
                        new ButtonStore(
                        {
                            onClick: () => this.save(true),
                            label: () => this.localizationStore.translate('Generic.SaveAndOpen'), // Save and open
                            isRaised: true,
                            color: 'primary',
                            isVisible:
                                () =>
                                    this.relatedEntityConstructorStore.relatedEntity &&
                                        this.relatedEntityConstructorStore.path.entityType.bespoke.isOpenable(
                                            this.relatedEntityConstructorStore.relatedEntity,
                                            this.relatedEntityConstructorStore.relatedFromEntityPath)
                        })
                    ].filter(
                        button =>
                            button !== undefined),
            onClose: () => this.close(true),
            viewComponent:
                new ViewComponent(
                    RelatedEntityConstructor,
                    relatedEntityConstructorStore),
            icon: relatedEntityConstructorStore.entity && relatedEntityConstructorStore.entity.entityType.getInheritedIcon()
        });

        this.onSave = onSave;
        this.onClose = onClose;
        this.onOpen = onOpen;
        this.relatedEntityConstructorStore = relatedEntityConstructorStore;
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    save(doOpen: boolean): Promise<any>
    {
        this.relatedEntityConstructorStore.touchAll();

        if (this.relatedEntityConstructorStore.isValid)
        {
            return this.relatedEntityConstructorStore.save(doOpen)
                .then(entity =>
                {
                    this.close(false);

                    if (doOpen)
                    {
                        const entityToOpen = entity.entityType.bespoke.getEntityToOpen(entity);

                        if (this.onOpen)
                        {
                            this.onOpen(entityToOpen, this);
                        }
                        else
                        {
                            this.routerStore.pushPage(
                                new EntityViewerPageStore(
                                    entityToOpen,
                                    new EntityViewerStore(entityToOpen)));

                            // this.routerStore.route(`/entity/${entityToOpen.id}`);
                            // this.leftDrawerStore.pushView(
                            //     new ViewComponent(
                            //         EntityViewer,
                            //         new EntityViewerStore(
                            //             entity,
                            //             true,
                            //             undefined,
                            //             () => this.leftDrawerStore.popView())));
                        }
                    }

                    if (this.onSave)
                    {
                        return this.onSave(entity, this);
                    }
                });
        }
    }

    @action.bound
    close(doDeleteWhenNew: boolean)
    {
        if (doDeleteWhenNew
            && this.relatedEntityConstructorStore.entity
            && this.relatedEntityConstructorStore.entity.isNew())
        {
            this.relatedEntityConstructorStore.entity.deleteEntity();
        }

        if (this.onClose)
        {
            this.onClose(this);
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
