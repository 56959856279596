import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import MenuButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { amber } from '@material-ui/core/colors';
import useDividerGlue from '../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import AutomatorItem from './AutomatorItem';
import useResults from '../../../../Selection/Hooks/useResults';
import { mapBy } from '../../../../../../../@Util/MapUtils/mapBy';
import useTypes from '../../../../Type/Api/useTypes';
import CurrentUserContext from '../../../../../User/CurrentUserContext';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Automation from '../../../../../Configuration/Page/Automations/AutomationManager/Automation/Automation';
import { groupBy } from '../../../../../../../@Util/MapUtils/groupBy';
import CardHeader from '../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import HoverCardMiddle from '../../../../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import HoverCardBottom from '../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import { createTransactionalModel } from '../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import constructEntity from '../../../../../../../@Api/Entity/constructEntity';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface AutomatorProps
{
    entity: Entity;
}

const Automator: React.FC<AutomatorProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);

        const automators =
            useComputed(
                () =>
                    currentUserStore.isAdministrator
                        ?
                            props.entity.entityType.bespoke.getAutomators(props.entity)
                        :
                            [],
                [
                    currentUserStore,
                    props.entity
                ]);

        const automations =
            useResults(
                types.Automation.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.or(
                                    ob =>
                                        ob
                                            .isNotDefined(
                                                rootPath
                                                    .joinTo(
                                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Automations,
                                                        true)
                                                    .field(types.Entity.Field.Id),
                                                undefined)
                                            .relatedToEntity(
                                                rootPath
                                                    .joinTo(
                                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Automations,
                                                        true),
                                                currentUserStore.employeeEntity)))
                        .where(
                            cb =>
                                cb.or(
                                    ob =>
                                        props.entity.entityType.getInheritedTypes()
                                            .forEach(
                                                type =>
                                                    ob.relatedToEntity(
                                                        rootPath.joinTo(
                                                            types.EntityType.RelationshipDefinition.Automations,
                                                            true),
                                                        type.entity)))),
                [
                    props.entity,
                    types,
                    currentUserStore
                ]);

        const activations =
            useResults(
                automations !== undefined && automations.length > 0 ? types.AutomationActivation.Type : undefined,
                (builder, rootPath) =>
                    builder
                        .join(
                            rootPath
                                .joinTo(
                                    types.Automation.RelationshipDefinition.Activations,
                                    true))
                        .where(
                            cb =>
                                cb.or(
                                    ob =>
                                        automations.map(
                                            automation =>
                                                ob.relatedToEntity(
                                                    rootPath.joinTo(
                                                        types.Automation.RelationshipDefinition.Activations,
                                                        true),
                                                    automation))))
                        .where(
                            cb =>
                                cb.or(
                                    ob =>
                                        ob
                                            .relatedToEntity(
                                                rootPath
                                                    .joinTo(
                                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.AutomationActivations,
                                                        true),
                                                currentUserStore.employeeEntity)
                                            .isNotDefined(
                                                rootPath
                                                    .joinTo(
                                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.AutomationActivations,
                                                        true)
                                                    .field(types.Entity.Field.Id)))),
                [
                    automations,
                    types,
                    currentUserStore
                ]);

        const activationByAutomation =
            useComputed(
                () =>
                    mapBy(
                        activations || [],
                        key =>
                            key.getRelatedEntityByDefinition(
                                true,
                                types.Automation.RelationshipDefinition.Activations)),
                [
                    activations,
                    types
                ]);

        const automationsByIsPersonal =
            useComputed(
                () =>
                    groupBy(
                        automations || [],
                        automation =>
                            automation.hasRelationshipsByDefinition(
                                true,
                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.Automations)),
                [
                    automations,
                    types
                ]);

        const dividerGlue = useDividerGlue();

        const createAutomation =
            useCallback(
                (isPersonal: boolean) =>
                {
                    const entity =
                        createTransactionalModel(
                            new Entity(types.Automation.Type)
                                .initialize());

                    entity.updateRelationship(
                        true,
                        types.EntityType.RelationshipDefinition.Automations,
                        createTransactionalModel(props.entity.entityType.entity));

                    if (isPersonal)
                    {
                        entity.updateRelationship(
                            true,
                            types.Relationship.Person.Contact.Employee.RelationshipDefinition.Automations,
                            createTransactionalModel(currentUserStore.employeeEntity));
                    }

                    constructEntity(
                        entity.entityType,
                        undefined,
                        entity);
                },
                [
                    types,
                    currentUserStore
                ]);

        const createPersonalAutomation =
            useCallback(
                () =>
                    createAutomation(true),
                [
                    createAutomation
                ]);
        const createEnvironmentAutomation =
            useCallback(
                () =>
                    createAutomation(false),
                [
                    createAutomation
                ]);

        return <MenuButton
            icon="emoji_objects"
            color={(activations || []).length > 0 ? amber[500] : undefined}
            tooltip={
                <LocalizedText
                    code="Automator.ManageAutomations"
                    value="Beheer automations"
                />
            }
        >
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={dividerGlue}
            >
                <ViewGroupItem>
                    <CardInset>
                        <CardHeader>
                            <LocalizedText
                                code="Automator.AutomationsName"
                                value="${name} automations"
                                name={props.entity.entityType.getName()}
                            />
                        </CardHeader>
                    </CardInset>
                </ViewGroupItem>
                {
                    (automationsByIsPersonal.get(false) || [])
                        .filter(
                            automation =>
                                currentUserStore.isAdministrator || activationByAutomation.has(automation))
                        .map(
                            automation =>
                                <ViewGroupItem
                                    key={automation.uuid}
                                >
                                    <Automation
                                        automation={automation}
                                        activation={activationByAutomation.get(automation)}
                                        allowManagement={currentUserStore.isAdministrator}
                                    />
                                </ViewGroupItem>)
                }
                {
                    currentUserStore.isAdministrator &&
                        <ViewGroupItem>
                            <HoverCardMiddle
                                onClick={createEnvironmentAutomation}
                                inside
                            >
                                + <LocalizedText code="Generic.Add" value="Toevoegen" />
                            </HoverCardMiddle>
                        </ViewGroupItem>
                }
                <ViewGroupItem>
                    <CardInset>
                        <CardHeader>
                            <LocalizedText
                                code="Automator.MyAutomationsName"
                                value="Mijn ${name} automations"
                                name={props.entity.entityType.getName().toLowerCase()}
                            />
                        </CardHeader>
                    </CardInset>
                </ViewGroupItem>
                {
                    (automationsByIsPersonal.get(true) || [])
                        .map(
                            automation =>
                                <ViewGroupItem
                                    key={automation.uuid}
                                >
                                    <Automation
                                        automation={automation}
                                        activation={activationByAutomation.get(automation)}
                                        allowManagement
                                    />
                                </ViewGroupItem>)
                }
                {
                    currentUserStore.isAdministrator &&
                        <ViewGroupItem>
                            <HoverCardBottom
                                onClick={createPersonalAutomation}
                                inside
                            >
                                + <LocalizedText code="Generic.Add" value="Toevoegen" />
                            </HoverCardBottom>
                        </ViewGroupItem>
                }
                {
                    automators.length > 0 &&
                        <ViewGroupItem>
                            <CardInset>
                                <CardHeader>
                                    <LocalizedText
                                        code="Automator.Suggestions"
                                        value="Suggesties"
                                    />
                                </CardHeader>
                            </CardInset>
                        </ViewGroupItem>
                }
                {
                    automators.map(
                        automator =>
                            <ViewGroupItem>
                                <AutomatorItem
                                    key={automator.code}
                                    entity={props.entity}
                                    automator={automator}
                                />
                            </ViewGroupItem>)
                }
            </ViewGroup>
        </MenuButton>;
    };

export default observer(Automator);
