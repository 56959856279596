import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import usePhaseRelationshipDefinition from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/usePhaseRelationshipDefinition';
import useResults from '../../Selection/Hooks/useResults';
import useTypes from '../../Type/Api/useTypes';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { useComputed } from 'mobx-react-lite';
import { createNumberComparator } from '../../../../Generic/List/V2/Comparator/NumberComparator';

export default function useWorkflowStates(entityType?: EntityType,
                                          isTerminal?: boolean,
                                          pipeline?: Entity): Entity[]
{
    const types = useTypes();
    const phaseRelationshipDefinition = usePhaseRelationshipDefinition(entityType);

    const results =
        useResults(
            phaseRelationshipDefinition ? phaseRelationshipDefinition.childEntityType : undefined,
            (builder, rootPath) =>
                builder
                    .if(
                        () =>
                            isTerminal !== undefined,
                        sb =>
                            sb.where(
                                cb =>
                                    cb.eq(
                                        rootPath.field(types.Datastore.Phase.Field.IsTerminal),
                                        undefined,
                                        isTerminal)))
                    .if(
                        () =>
                            pipeline !== undefined,
                        sb =>
                            sb.where(
                                cb =>
                                    cb.relatedToEntity(
                                        rootPath.joinTo(
                                            types.Pipeline.RelationshipDefinition.Phases,
                                            true),
                                        pipeline)))
                    .if(
                        () =>
                            pipeline === undefined,
                        sb =>
                            sb.where(
                                cb =>
                                    cb.isNotDefined(
                                        rootPath
                                            .joinTo(
                                                types.Pipeline.RelationshipDefinition.Phases,
                                                true)
                                            .field(types.Entity.Field.Id))))
                    .orderBy(
                        rootPath.field(types.Entity.Field.SortIndex),
                        true),
            [
                isTerminal,
                pipeline
            ]);

    return useComputed(
        () =>
            results
                ?
                    results.slice()
                        .sort(
                            createNumberComparator(
                                e =>
                                    e.getObjectValueByField(types.Entity.Field.SortIndex)))
                :
                    [],
        [
            results,
            types
        ]);
}
