import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { observable } from 'mobx';
import { BespokeTemplateEmail } from './BespokeTemplateEmail';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../Path/@Model/EntityPath';

export class BespokeTemplate extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    @observable.ref email: BespokeTemplateEmail;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Template')
    {
        super(entityTypeStore, code);

        this.email = new BespokeTemplateEmail(this.entityTypeStore);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getInitializationPathsInViewer(rootPath: EntityPath): EntityPath[]
    {
        return [
            ...super.getInitializationPathsInViewer(rootPath),
            rootPath
                .joinTo(
                    this.types.Entity.RelationshipDefinition.Attachments,
                    false)
        ];
    }

    isHiddenInSelectbox(entity: Entity): boolean
    {
        return entity.getObjectValueByField(this.types.Template.Field.IsInactive) === true;
    }

    // ----------------------- Private logic ------------------------
}
