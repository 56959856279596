import * as React from 'react';
import { PredicateType } from '../PredicateType';
import { RightPredicateEditorStore } from './RightPredicateEditorStore';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { RightPredicateEditor } from './RightPredicateEditor';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { RightPredicateSpecification } from './RightPredicateSpecification';
import { PredicateContext } from '../../PredicateContext';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';
import { EntityContext } from '../../../Entity/@Model/EntityContext';
import { RightPredicate } from './RightPredicate';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';

export class RightPredicateType extends PredicateType<RightPredicateEditorStore, RightPredicateSpecification, RightPredicate>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return 'Right';
    }

    name(): string
    {
        return this.localizationStore.translate('RightPredicateType.Name'); // Has rights
    }

    allow(context: PredicateContext): boolean
    {
        return true;
    }

    isTerminal(): boolean
    {
        return true;
    }

    fromSpecification(specification: RightPredicateSpecification): RightPredicate
    {
        return new RightPredicate(
            specification.rightType,
            specification.rightId);
    }

    toSpecification(predicate: RightPredicate): RightPredicateSpecification
    {
        return {
            type: this.id(),
            rightType: predicate.rightType,
            rightId: predicate.rightId
        };
    }

    evaluate(context: PredicateContext,
             predicate: RightPredicate): boolean
    {
        return true;
    }

    editorStore(context: PredicateContext,
                specification: RightPredicateSpecification): RightPredicateEditorStore
    {
        return new RightPredicateEditorStore(
            this as any,
            context,
            specification,
            specification.rightType,
            specification.rightId);
    }

    editorView(): React.ComponentClass<BaseComponentProps<RightPredicateEditorStore>>
    {
        return RightPredicateEditor;
    }

    editorSpecification(store: RightPredicateEditorStore): RightPredicateSpecification
    {
        return {
            type: this.id(),
            rightType: store.rightType,
            rightId: store.rightId
        };
    }

    entityFieldPaths(specification: RightPredicateSpecification,
                     context: EntityContext,
                     parameter?: string): EntityFieldPath[]
    {
        return [];
    }

    // ----------------------- Private logic ------------------------
}
