import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutEditor, { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import useEntityByUuid from '../../../../../../@Api/Entity/Bespoke/useEntityByUuid';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { runInAction } from 'mobx';
import DynamicParameterAssignment from '../../../../../../@Api/Automation/Function/Dynamic/DynamicParameterAssignment';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { EntitySelectionBuilder } from '../../../../Entity/Selection/Builder/EntitySelectionBuilder';
import useDialog from '../../../../../../@Service/Navigation/Page/Hooks/useDialog';
import Dialog from '../../../../../../@Future/Component/Generic/Dialog/Dialog';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Selectbox from '../../../../Entity/Selectbox/Selectbox';
import EditIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/EditIconButton/EditIconButton';
import DynamicParameterAssignmentEditor from '../../../../Entity/Viewer/Content/Automation/Editor/DynamicParameterAssignment/DynamicParameterAssignmentEditor';
import getPortalDataSourceSignatureById from '../../../../../../@Api/Portal/DataSource/Api/getPortalDataSourceSignatureById';
import PortalDataSourceSignature from '../../../../../../@Api/Portal/DataSource/PortalDataSourceSignature';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import ParameterAssignment from '../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import uuid from '../../../../../../@Util/Id/uuid';
import PortalContext from '../../../../../../@Api/Portal/Context/PortalContext';
import { createTransactionalModel } from '../../../../../../@Util/TransactionalModelV2/index';
import SelectionFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/SelectionFormInputLayout';
import PortalDataSourceEditor from '../../PortalList/Editor/DataSource/Editor/PortalDataSourceEditor';
import ComputationLayout from '../../../../../../@Api/Layout/Type/ComputationLayout';
import EmptyLayout from '../../../../../../@Api/Layout/Type/EmptyLayout';
import Layout from '../../../../../../@Api/Layout/Layout';
import PortalDataSourceOrderingsEditor from '../../PortalList/Editor/DataSource/Orderings/PortalDataSourceOrderingsEditor';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import ComputationBox from '../../../../Entity/Viewer/Content/Automation/Editor/Computation/Box/ComputationBox';
import PredicateEditor from '../../../../Entity/Viewer/Content/Automation/Editor/Predicate/PredicateEditor';

export interface PortalListLayoutEditorProps extends LayoutEditorProps<SelectionFormInputLayout>
{

}

const PortalListLayoutDataSourceEditor: React.FC<PortalListLayoutEditorProps> =
    props =>
    {
        const { layout, parameterDictionary, parameterAssignment, commitContext } = props;
        const portalContext = useContext(PortalContext);

        const types = useTypes();
        const [ dataSource, isLoading ] = useEntityByUuid(types.PortalDataSource.Type, layout.dataSourceSignature?.id);
        const setDataSource =
            useCallback(
                async (value?: Entity) =>
                {
                    if (value?.hasValueForField(types.PortalDataSource.Field.Specification))
                    {
                        const signature = await getPortalDataSourceSignatureById(value.uuid, false);

                        runInAction(
                            () =>
                            {
                                if (layout.dataSourceSignature?.id !== signature.id)
                                {
                                    props.layout.optionParameter =
                                        SelectionFormInputLayout.getOptionParameter(
                                            uuid(),
                                            signature);

                                    if (signature.resultParameters.parameters.length > 0)
                                    {
                                        layout.optionLayout = new ComputationLayout(signature.resultParameters.parameters[0]);
                                    }
                                    else
                                    {
                                        layout.optionLayout = new EmptyLayout();
                                    }
                                }

                                layout.parameterAssignment = layout.parameterAssignment.getWithNewParameters(signature.parameters);
                                layout.orderings = [];
                                layout.dataSourceSignature = signature;
                            });
                    }
                    else
                    {
                        runInAction(
                            () =>
                            {
                                layout.parameterAssignment = new DynamicParameterAssignment();
                                layout.dataSourceSignature =
                                    value
                                        ?
                                            new PortalDataSourceSignature(
                                                value.uuid,
                                                undefined,
                                                new ParameterDictionary([]),
                                                new ParameterDictionary([]))
                                        :
                                            undefined;
                                layout.orderings = [];
                                layout.optionLayout = new EmptyLayout();
                            });
                    }
                },
                [
                    layout,
                    types
                ]);
        const dataSourceSelection =
            useMemo(
                () => [
                    new EntitySelectionBuilder(types.PortalDataSource.Type)
                        .build()
                ],
                [
                    types
                ]);
        const dataSourceParameters =
            useComputed(
                () =>
                    layout.dataSourceSignature?.parameters || new ParameterDictionary([]),
                [
                    layout
                ]);
        const dataSourceParameterAssignment =
            useComputed(
                () =>
                    layout.parameterAssignment || new DynamicParameterAssignment(),
                [
                    layout
                ]);
        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        parameterDictionary,
                        parameterAssignment || new ParameterAssignment(),
                        commitContext
                    ),
                [
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                ]);

        const [ openEditor ] =
            useDialog(
                close =>
                    <Dialog
                        open
                        onClose={close}
                        width="xl"
                    >
                        <PortalContext.Provider
                            value={portalContext}
                        >
                            <PortalDataSourceEditor
                                entity={dataSource}
                                onCancel={close}
                                onSave={
                                    async () =>
                                    {
                                        await setDataSource(dataSource);
                                        close();
                                    }
                                }
                            />
                        </PortalContext.Provider>
                    </Dialog>,
                [
                    portalContext,
                    dataSource,
                    setDataSource
                ]);

        const onConstruct =
            useCallback(
                (newDataSource: Entity) =>
                {
                    newDataSource.updateRelationship(
                        true,
                        types.Portal.RelationshipDefinition.DataSources,
                        createTransactionalModel(portalContext.portal));
                },
                [
                    types,
                    portalContext
                ]);

        const setOptionLayout =
            useCallback(
                (optionLayout: Layout) =>
                    runInAction(
                        () =>
                            layout.optionLayout = optionLayout),
                [
                    layout
                ]);

        const filterContext =
            useComputed(
                () =>
                    props.layout.dataSourceSignature &&
                    new FunctionContext(
                        ParameterDictionary.union(
                            props.layout.dataSourceSignature.resultParameters,
                            props.parameterDictionary),
                        props.parameterAssignment,
                        props.commitContext
                    ),
                [
                    props.layout,
                    props.parameterDictionary,
                    props.parameterAssignment,
                ]);
        const setFilter =
            useCallback(
                (filter: Predicate) =>
                    runInAction(
                        () =>
                            props.layout.filter = filter),
                [
                    props.layout
                ]);

        return <CardInset
            vertical={false}
            horizontal={false}
        >
            <Input
                label={
                    <LocalizedText
                        code="Generic.DataSource"
                        value="Databron"
                    />
                }
                labelPosition="left"
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <Selectbox
                            value={dataSource}
                            onChange={setDataSource}
                            selections={dataSourceSelection}
                            genericSelectboxProps={{
                                disabled: isLoading
                            }}
                            onConstruct={onConstruct}
                        />
                    </ViewGroupItem>
                    {
                        dataSource &&
                            <ViewGroupItem>
                                <EditIconButton
                                    onClick={openEditor}
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </Input>
            <DynamicParameterAssignmentEditor
                context={context}
                parameterDictionary={dataSourceParameters}
                parameterAssignment={dataSourceParameterAssignment}
            />
            {
                layout.dataSourceSignature?.resultParameters.parameters.length > 0 &&
                <>
                    {
                        filterContext &&
                        <Input
                            label={
                                <LocalizedText
                                    code="Generic.Filter"
                                    value="Filter"
                                />
                            }
                            labelPosition="top"
                        >
                            <ComputationBox>
                                <PredicateEditor
                                    context={filterContext}
                                    value={props.layout.filter}
                                    onChange={setFilter}
                                />
                            </ComputationBox>
                        </Input>
                    }
                    <Input
                        labelPosition="top"
                        label={
                            <LocalizedText
                                code="Generic.Ordering"
                                value="Sortering"
                            />
                        }
                    >
                        <PortalDataSourceOrderingsEditor
                            value={layout.orderings}
                            onChange={
                                orderings =>
                                    runInAction(
                                        () =>
                                            layout.orderings = orderings
                                    )
                            }
                            dataSourceSignature={layout.dataSourceSignature}
                        />
                    </Input>
                </>
            }
            {
                layout.dataSourceSignature &&
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.Layout"
                                value="Layout"
                            />
                        }
                        labelPosition="left"
                    >
                        <LayoutEditor
                            parameterDictionary={layout.dataSourceSignature.resultParameters}
                            layout={layout.optionLayout}
                            onChange={setOptionLayout}
                        />
                    </Input>
            }
        </CardInset>;
    };

export default observer(PortalListLayoutDataSourceEditor);
