import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { EntitySelectionAggregateResult } from '../../../../Selection/Model/EntitySelectionAggregateResult';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import useResultFilter from '../../Api/useResultFilter';
import useEntityTypeForFilter from '../../Api/useEntityTypeForFilter';
import styles from './ChartSelector.module.scss';
import FilterContext from '../../FilterContext/FilterContext';
import Segment from '../../Segment';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import SegmentContext from '../../SegmentContext/SegmentContext';
import useChildSelection from '../Api/useChildSelection';
import { default as SegmentModel } from '../../../Model/Segment';
import useNextSegments from '../../Api/useNextSegments';
import DefaultChart from './Type/Default/DefaultChart';
import NewChart from './Type/New/NewChart';
import DatasetContext from '../../../Context/DatasetContext';

export interface ChartProps
{
    entityType: EntityType;
    segment: SegmentModel;
    nextSegments: SegmentModel[];
    result: EntitySelectionAggregateResult;
}

const ChartSelector: React.FC<ChartProps> =
    props =>
    {
        const dataset = useContext(DatasetContext);
        const [ selectedChild, setSelectedChild ] =
            useChildSelection(
                props.segment,
                props.result);
        const [ nextSegment, nextNextSegments ] = useNextSegments(props.nextSegments);
        const selectedChildFilter =
            useResultFilter(
                dataset,
                props.segment,
                selectedChild);
        const selectedChildType =
            useEntityTypeForFilter(
                props.entityType,
                selectedChildFilter);

        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            {
                props.segment.chart &&
                    <ViewGroupItem>
                        <NewChart
                            {...props}
                        />
                    </ViewGroupItem>
            }
            {
                !props.segment.chart &&
                    <ViewGroupItem>
                        <DefaultChart
                            {...props}
                            onSelectChild={setSelectedChild}
                        />
                    </ViewGroupItem>
            }
            {
                selectedChild &&
                    <ViewGroupItem>
                        <FilterContext.Provider
                            value={selectedChildFilter}
                        >
                            <SegmentContext.Provider
                                value={props.segment}
                            >
                                <div
                                    className={styles.childSegment}
                                >
                                    <Segment
                                        entityType={selectedChildType}
                                        segment={nextSegment}
                                        nextSegments={nextNextSegments}
                                        result={selectedChild}
                                    />
                                </div>
                            </SegmentContext.Provider>
                        </FilterContext.Provider>
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(ChartSelector);
