import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';

export default function getDisplayType(type: EntityType)
{
    // This is to display the name of the entity type of Action instead of Action.Call
    if (type.isSwitchableByInheritance())
    {
        return type.getSwitchableType();
    }
    else
    {
        return type;
    }
}