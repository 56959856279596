import { DependencyList, useCallback, useEffect, useState } from 'react';
import { observable, runInAction } from 'mobx';
import { EntitySelectionResult } from '../../Selection/Model/EntitySelectionResult';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { useIsMounted } from '../../../../../@Util/Async/useIsMounted';

export type Page = EntitySelectionResult[];

export const DefaultPageSize = 50;

export default function usePaginatedSelection(
    type: EntityType,
    callback: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void,
    deps: DependencyList,
    pageSize: number = DefaultPageSize,
    logName?: string
): [
    Page[],
    boolean,
    (nextPage?: number) => Promise<boolean>,
    boolean
]
{
    // eslint-disable-next-line
    const memorizedCallback = useCallback(callback, deps);
    const isMounted = useIsMounted();

    const selectionBuilder =
        useComputed(
            () =>
            {
                const selectionBuilder = new EntitySelectionBuilder(type);

                memorizedCallback(selectionBuilder, selectionBuilder.rootPath);

                return selectionBuilder;
            },
            [
                type,
                memorizedCallback
            ]);

    useEffect(
        () =>
            () =>
                selectionBuilder.dispose(),
        [
            selectionBuilder
        ]);

    const [ pages ] =
        useState(
            () =>
                observable.array<EntitySelectionResult[]>());

    const [ page, setPage ] = useState(0);
    const [ hasMore, setHasMore ] = useState(false);
    const [ isLoading, setLoading ] = useState(false);

    const loadPage =
        useCallback(
            (page: number) =>
            {
                setLoading(true);

                return selectionBuilder
                    .log(logName ? logName : '')
                    .selectExtendedResult(page * pageSize, pageSize)
                    .then(
                        ({ numberOfRecords, records: results }) =>
                        {
                            if (isMounted())
                            {
                                setPage(page);

                                runInAction(
                                    () =>
                                    {
                                        if (page === 0)
                                        {
                                            pages.clear();
                                        }

                                        pages.push(results);
                                    });

                                const hasMore = numberOfRecords >= pageSize;
                                setHasMore(hasMore);
                                setLoading(false);

                                return hasMore;
                            }
                            else
                            {
                                return false;
                            }
                        });
            },
            [
                pages,
                selectionBuilder,
                isMounted,
                pageSize,
                logName
            ]);

    useEffect(
        () =>
        {
            loadPage(0).finally();
        },
        [
            loadPage
        ]);

    const loadMore =
        useCallback(
            () =>
            {
                if (isLoading || !hasMore)
                {
                    return Promise.resolve(false);
                }
                else
                {
                    return loadPage(page + 1);
                }
            },
            [
                hasMore,
                page,
                loadPage,
                isLoading
            ]);

    const nonEmptyPages =
        useComputed(
            () =>
                pages
                    .filter(
                        page =>
                            page.length > 0),
            [
                pages
            ]);

    return [
        nonEmptyPages,
        hasMore,
        loadMore,
        isLoading
    ];
}
