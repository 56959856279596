import { observable } from 'mobx';
import { ChartDataPoint } from './ChartDataPoint';

export class ChartDataSegmentation
{
    // ------------------------- Properties -------------------------

    @observable points: ChartDataPoint[];

    // ------------------------ Constructor -------------------------

    constructor(points: ChartDataPoint[])
    {
        this.points = points;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
