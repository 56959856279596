import { observable } from 'mobx';
import ParameterDictionary from '../../Parameter/ParameterDictionary';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Action from '../../Function/Action/Action';
import getActionFromDescriptor from '../../Api/getActionFromDescriptor';
import ActionSignature from './ActionSignature';
import Dependency from '../../Parameter/Dependency';

export default class ActionTemplate
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref parameters: ParameterDictionary;
    @observable.ref action: Action<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                parameters: ParameterDictionary,
                action: Action<any, any>)
    {
        this.id = id;
        this.parameters = parameters;
        this.action = action;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getDependencies(): Dependency[]
    {
        return [
            ...this.parameters.getDependencies(),
            ...this.action.getDependencies()
                .filter(
                    dependency =>
                        !this.parameters.hasParameter(dependency.parameter))
        ];
    }

    toSignature()
    {
        return new ActionSignature(
            this.id,
            this.parameters,
            this.action.getType());
    }

    toDescriptor()
    {
        return {
            id: this.id,
            parameters: this.parameters.toDescriptor(),
            action: this.action.toDescriptor()
        };
    }

    static async fromDescriptor(id: string,
                                descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const parameters =
            await ParameterDictionary.fromDescriptor(
                descriptor.parameters,
                dependencyContext);

        const action =
            await getActionFromDescriptor(
                descriptor.action,
                new AutomationDependencyContext(
                    ParameterDictionary.union(
                        dependencyContext.parameterDictionary,
                        parameters)));

        return new ActionTemplate(
            id,
            parameters,
            action);
    }

    // ----------------------- Private logic ------------------------
}
