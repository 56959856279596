import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { IObservableArray, runInAction } from 'mobx';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import DeleteIconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import Switch from '../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ColumnOrdering from '../../../../../Entity/View/Model/Column/ColumnOrdering';
import { ViewColumnOrderingsEditorProps } from './ViewColumnOrderingsEditor';

export interface ViewColumnOrderingEditorProps extends ViewColumnOrderingsEditorProps
{
    ordering: ColumnOrdering;
}

const ViewColumnOrderingEditor: React.FC<ViewColumnOrderingEditorProps> =
    props =>
    {
        const { column, ordering } = props;

        const toggleDirection =
            useCallback(
                (isDescending: boolean) =>
                    runInAction(
                        () =>
                            ordering.isAscending = !isDescending),
                [
                    column,
                    ordering
                ]);
        const deleteOrdering =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (column.ordering as IObservableArray).remove(ordering)),
                [
                    column,
                    ordering
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem
                ratio={1}
            >
                {ordering.fieldPath.getName()}
            </ViewGroupItem>
            <ViewGroupItem>
                <Input
                    labelPosition="right"
                    label={
                        <LocalizedText
                            code="View.Column.SortDescending"
                            value="Sorteer aflopend"
                        />
                    }
                >
                    <Switch
                        checked={!ordering.isAscending}
                        onToggle={toggleDirection}
                    />
                </Input>
            </ViewGroupItem>
            <ViewGroupItem>
                <DeleteIconButton
                    onClick={deleteOrdering}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(ViewColumnOrderingEditor);
