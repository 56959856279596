import { observable } from 'mobx';
import { PageStore } from '../../Navigation/Page/PageStore';
import { Route } from './Route';

export class RouteInstantiation
{
    // ------------------------- Properties -------------------------

    @observable path: string;
    @observable.ref route: Route;
    @observable.ref parameters: any;
    @observable.ref store: PageStore;
    @observable browserKey: string;

    // ------------------------ Constructor -------------------------

    constructor(path: string,
                route: Route,
                parameters: any,
                store: PageStore,
                browserKey: string)
    {
        this.path = path;
        this.route = route;
        this.parameters = parameters;
        this.store = store;
        this.browserKey = browserKey;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
