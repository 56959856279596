import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { useContext } from 'react';
import EntityTypeContext from '../../../Type/EntityTypeContext';
import { useComputed } from 'mobx-react-lite';
import getFilteredEntityType from './getFilteredEntityType';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';

export default function useEntityTypeForFilter(baseEntityType: EntityType,
                                               filter?: Predicate)
{
    const entityTypeStore = useContext(EntityTypeContext);

    return useComputed(
        () =>
            (filter && getFilteredEntityType(filter))
                ||
            baseEntityType,
        [
            baseEntityType,
            filter,
            entityTypeStore
        ]);
}
