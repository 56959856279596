import React, { useCallback } from 'react';
import { PanelBody, PanelRow, TextControl } from '@wordpress/components';
import { BlockEditProps } from '@wordpress/blocks';
import ViewGroupItem from '../../../../ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';
import ViewGroup from '../../../../ViewGroup/ViewGroup';
import useIsConnectorActivationActivated from '../../../../../../../@Component/Domain/Marketplace/Api/useIsConnectorActivationActivated';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import getConnectorActivationByCode from '../../../../../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';

export interface GoogleTagManagerSettingsProps
{
    props: BlockEditProps<{}>;
    open?: boolean;
}

const GoogleTagManagerSettings: React.FC<GoogleTagManagerSettingsProps> =
    ({
        props,
        open
    }) =>
    {
        const [ googleTagManagerConnectorActivation] =
            useAsyncResult(
                () =>
                    getConnectorActivationByCode('GoogleTagManager'),
                []
            );

        const isActivated = useIsConnectorActivationActivated(googleTagManagerConnectorActivation);

        const {
            buttonId
        } = props.attributes as any;

        const onChangeButtonId =
            useCallback(
                (id) => {
                    props.setAttributes({
                        buttonId: id
                    })
                },
                [
                    props
                ]
            );

        if (isActivated)
        {
            return <PanelBody
                title={'Google Tag Manager'}
                initialOpen={!!open}
            >
                <PanelRow>
                    <ViewGroup
                        orientation="vertical"
                        spacing={5}
                    >
                        <ViewGroupItem>
                            <TextControl
                                name="linkUrl"
                                label={<LocalizedText
                                    code="Gutenberg.GoogleTagManagerOptions.ButtonId"
                                    value="Button Id"
                                />}
                                value={buttonId}
                                onChange={onChangeButtonId}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </PanelRow>
            </PanelBody>
        }
        else
        {
            return null;
        }
    };

export default GoogleTagManagerSettings;
