import ValueType from '../Value/Type/ValueType';
import Value from '../Value/Value';
import FunctionContext from './FunctionContext';
import Dependency from '../Parameter/Dependency';
import Validation from '../Validation/Validation';

export default abstract class Function<T extends ValueType<any>, V extends Value<any, any>>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract getType(): T;

    abstract getName(): string;

    abstract validate(): Validation[];

    abstract isAsync(): boolean;

    async apply(context: FunctionContext): Promise<V>
    {
        throw new Error('unimplemented');
    }

    synchronousApply(context: FunctionContext): V
    {
        throw new Error('unimplemented');
    }

    abstract getDependencies(): Dependency[];

    abstract toDescriptor(): any;

    isValid()
    {
        return this.validate()
            .every(
                validation =>
                    validation.type !== 'Error');
    }

    // ----------------------- Private logic ------------------------
}
