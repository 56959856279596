import Value from './Value';
import ValueType from './Type/ValueType';
import getValueFromDescriptor from '../Api/getValueFromDescriptor';
import AutomationDependencyContext from '../AutomationDependencyContext';
import getValueTypeFromDescriptor from '../Api/getValueTypeFromDescriptor';
import { FileReporter } from '../../../@Component/Domain/DataObject/Model/DataDescriptor';
import MapType from './Type/MapType';

type InternalMap = Map<string, InternalMapValue>;

interface InternalMapValue
{
    key: Value<any, any>;
    value: Value<any, any>;
}

function convertToInternalMap(value: Map<Value<any, any>, Value<any, any>>): InternalMap
{
    const convertedMap = new Map<string, InternalMapValue>();
    value.forEach(
        (value, key) =>
            convertedMap.set(
                key.getId(),
                {
                    key: key,
                    value: value
                }));

    return convertedMap;
}

export default class MapValue extends Value<InternalMap, MapType>
{
    // ------------------------- Properties -------------------------

    keyType: ValueType<any>;
    valueType: ValueType<any>;

    // ------------------------ Constructor -------------------------

    constructor(value: Map<Value<any, any>, Value<any, any>>,
                keyType: ValueType<any>,
                valueType: ValueType<any>)
    {
        super(convertToInternalMap(value));

        this.keyType = keyType;
        this.valueType = valueType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getId(): string
    {
        return `Map(${this.keyType.id()},${this.valueType.id()},${Array.from(this.value.values()).map((value) => `(${value.key.getId()},${value.value.getId()})`).join(',')})`;
    }

    getType(): MapType
    {
        return new MapType(
            this.keyType,
            this.valueType);
    }

    getName(): string
    {
        return Array.from(this.value.values())
            .map(
                value =>
                    `${value.key.getName()}: ${value.value.getName()}`)
            .join(', ');
    }

    equals(otherValue: Value<any, any>)
    {
        return otherValue instanceof MapValue
            && this.value.size === otherValue.value.size
            && Array.from(this.value.keys()).every(
                key =>
                {
                    const thisMapValue = this.value.get(key).value;
                    const otherMapValue = otherValue.value.get(key)?.value;

                    if (otherMapValue)
                    {
                        return thisMapValue.equals(otherMapValue);
                    }
                    else
                    {
                        return false;
                    }
                });
    }

    clone(): MapValue
    {
        return new MapValue(
            new Map(
                Array.from(this.value.values())
                    .map(
                        value => [
                            value.key.clone(),
                            value.value.clone()
                        ])),
            this.keyType,
            this.valueType);
    }

    augmentDescriptor(descriptor,
                      onFile?: FileReporter)
    {
        descriptor.type = 'Map';
        descriptor.keyType = this.keyType.toDescriptor();
        descriptor.valueType = this.valueType.toDescriptor();
        descriptor.value =
            Array.from(this.value.values())
                .map(
                    value => ({
                        key: value.key.toDescriptor(),
                        value: value.value.toDescriptor()
                    }));
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const [ keyType, valueType, value ] =
            await Promise.all([
                getValueTypeFromDescriptor(
                    descriptor.keyType,
                    dependencyContext),
                getValueTypeFromDescriptor(
                    descriptor.valueType,
                    dependencyContext),
                new Map<Value<any, any>, Value<any, any>>(
                    await Promise.all(
                        descriptor.value.map(
                            async entry =>
                                Promise.all([
                                    await getValueFromDescriptor(
                                        entry.key,
                                        dependencyContext),
                                    await getValueFromDescriptor(
                                        entry.value,
                                        dependencyContext),
                            ]))))
            ]);

        return new MapValue(
            value,
            keyType,
            valueType);
    }

    static getMap(collection: Value<any, any>): InternalMap
    {
        if (collection instanceof MapValue)
        {
            return collection.value;
        }
        else
        {
            return new Map();
        }
    }

    // ----------------------- Private logic ------------------------
}
