/**
 * Format function that can be applied on Strings.
 *
 * Usage: var text = 'This is the {0} text, and this the {1}!'.format('first', 'second'));
 *
 * Formatting processors are also allows, such as {0:noun}. Multiple processors can be combined, e.g.
 * {0:processor1:processor2:processor3}.
 */

/** Map from processor id to corresponding string processing callback, which should be a function accepting the
 *  following parameters:
 *  - string: the original string (i.e. "Add {0:noun}")
 *  - processor: the matched processing string (i.e. "noun")
 *  - number: the number of the match to be formatted (i.e. 0)
 *  - index: the first index of the portion to be formatted (i.e. 5, since indexOf("{0:noun}") in "Add {0:noun}" is 5)
 *  - value: the replacement value of the portion to be formatted (i.e. "Organization")
 *  - processors: array of processors applied to the string
 *
 *  It should return the new replacement value of the portion to be formatted (i.e. "organisation" in English
 *  or "Organization" in German (different capitalization).
 */
export class StringUtils
{

    public static format(input: string, ...args: any[]): string
    {
        try
        {
            return input.replace(/{(\d+(:.*)?)}/g, (match, i) =>
            {
                let s = match.split(':');
                if (s.length > 1)
                {
                    i = i[0];
                    match = s[1].replace('}', '');
                }

                let arg = this.formatPattern(match, args[i]);
                return (typeof arg !== 'undefined' && arg != null) ? arg : null;
            });
        }
        catch (e)
        {
            return null;
        }
    }

    private static formatPattern(match: string, arg: string): string
    {
        switch (match) {
            case 'L':
                arg = arg.toLowerCase();
                break;
            case 'U':
                arg = arg.toUpperCase();
                break;
            default:
                break;
        }
        return arg;
    }

    public static jsonEscape(input: string)
    {
        if (input === undefined)
        {
            return undefined;
        }

        return input
            .replace(/[\\]/g, '\\\\')
            .replace(/[/]/g, '\\/')
            .replace(/[\b]/g, '\\b')
            .replace(/[\f]/g, '\\f')
            .replace(/[\n]/g, '\\n')
            .replace(/[\r]/g, '\\r')
            .replace(/[\t]/g, '\\t')
            .replace(/["]/g, '\\"')
            .replace(/\\'/g, "\\'");
    }
}
