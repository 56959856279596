import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { Action, BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import { CurrentUserStore } from '../../../User/CurrentUserStore';

export class BespokeRelationOrganization extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Relation.Organization')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getInitializationPathsInViewer(rootPath: EntityPath): EntityPath[]
    {
        // If entity is a relation, fetch the parent relationships
        const paths = super.getInitializationPathsInViewer(rootPath);

        paths.push(
            rootPath
                .joinTo(
                    this.types.Relation.Organization.RelationshipDefinition.VisitingAddress,
                    false)
                .joinTo(
                    this.types.Address.RelationshipDefinition.Country,
                    false));

        const relationshipPath =
            rootPath.joinTo(
                this.entityTypeStore.bespoke.types.Relationship.Organization.RelationshipDefinition.Organization,
                true);

        // Avoid infinite loop
        if (relationshipPath.length > rootPath.length)
        {
            paths.push(relationshipPath);
            paths.push(
                ...relationshipPath.entityType.bespoke.getInitializationPathsInViewer(relationshipPath));
        }

        return paths;
    }

    isOwnerInListCaption(entity: Entity,
                         owner: Entity,
                         relationshipDefinition: EntityRelationshipDefinition,
                         pathFromRelatedEntity?: RelatedEntityPath): boolean
    {
        return super.isOwnerInListCaption(entity, owner, relationshipDefinition, pathFromRelatedEntity)
            && relationshipDefinition !== this.entityTypeStore.bespoke.types.Relationship.Organization.RelationshipDefinition.Organization;
    }

    getInitializationPathsInTimeline(entity: Entity, rootPath: EntityPath): EntityPath[]
    {
        return [
            ...super.getInitializationPathsInTimeline(entity, rootPath),
            rootPath
                .joinTo(
                    this.entityTypeStore.bespoke.types.Relationship.Organization.RelationshipDefinition.Organization,
                    true)
                .joinTo(
                    this.entityTypeStore.bespoke.types.Relationship.RelationshipDefinition.Activities,
                    false)
                .joinTo(
                    this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Notes,
                    false)
        ];
    }

    getAddPathsInTimeline(entity: Entity, rootPath: EntityPath): RelatedEntityPath[]
    {
        const relationship =
            entity.getRelatedEntityByDefinition(
                true,
                this.entityTypeStore.bespoke.types.Relationship.Organization.RelationshipDefinition.Organization);

        return [
            ...super.getAddPathsInTimeline(entity, rootPath),
            ...relationship
                ?
                    relationship.entityType.bespoke.getAddPathsInTimeline(
                        relationship,
                        rootPath.joinTo(
                            this.entityTypeStore.bespoke.types.Relationship.Organization.RelationshipDefinition.Organization,
                            true))
                :
                    []
        ];
    }

    getActions(entity: Entity): Action[]
    {
        return [
            ...super.getActions(entity),
            ...loadModuleDirectly(CurrentUserStore).isDeveloper
                ?
                    [
                        {
                            id: 'DebugExternalIds',
                            code: 'Connector.DebugExternalIdsOfOrganization',
                            name: 'Debug external IDs'
                        }
                    ]
                :
                    []
        ];
    }

// ----------------------- Private logic ------------------------
}
