import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { ListStore } from './ListStore';
import { View } from '../../ViewStack/View';
import ListGroup from './ListGroup';
import { Loader } from '../../Loader/Loader';
import Text from '../../Text/Text';
import { Theme, withStyles } from '@material-ui/core';
import Button from '../../Button/Button';
import { Droppable } from 'react-beautiful-dnd';
import ListFragment from './Fragment/ListFragment';

// noinspection TsLint
const styles =
    (theme: Theme) =>
        ({

        });

@observer
class List extends BaseComponent<ListStore<any, any, any, any, any, any>>
{
    renderLoader(store: ListStore<any, any, any, any, any, any>)
    {
        return this.renderComponent(store, true);
    }

    renderComponent(store: ListStore<any, any, any, any, any, any>,
                    withLoader: boolean = false): JSX.Element
    {
        return <>
            {this.renderContent(store)}
            {
                withLoader && !store.template && store.showLoader &&
                    <Loader />
            }
        </>;
    }

    renderContent(store: ListStore<any, any, any, any, any, any>): JSX.Element
    {
        if (store.template)
        {
            return <View
                viewComponent={store.template}
            />;
        }
        else if ((store.visibleItemStores.length === 0 || store.isEmpty) && !store.isLoading && !store.createButton)
        {
            if (store.isEmpty && !store.showNoDataLabel && !store.isDraggable)
            {
                return null;
            }
            else
            {
                return <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 60,
                        padding: 24
                    }}
                >
                    <div
                        style={{
                            flex: '1 1 auto'
                        }}
                    >
                        {
                            store.isDraggable
                                ?
                                    <Droppable
                                        droppableId={store.droppableId()}
                                        type={store.droppableType()}
                                    >
                                        {
                                            (provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={{ minHeight: 40 }}
                                                >
                                                    {this.renderEmptyList(store)}
                                                    {provided.placeholder}
                                                </div>)
                                        }
                                    </Droppable>
                                :
                                    this.renderEmptyList(store)
                        }
                    </div>
                </div>;
            }
        }
        else if (store.groupStores.length > 0)
        {
            return this.renderInternalList(
                store,
                <>
                    {
                        store.createButton &&
                            <Button
                                key={store.createButton.uuid}
                                store={store.createButton}
                            />
                    }
                    {
                        store.groupStores
                            .map(
                                (groupStore, idx) =>
                                    <ListGroup
                                        key={groupStore.id}
                                        idx={idx}
                                        store={store}
                                        groupStore={groupStore}
                                    />)
                    }
                </>);
        }
        else
        {
            return this.renderInternalList(
                store,
                <>
                    {
                        store.createButton &&
                            <Button
                                key={store.createButton.uuid}
                                store={store.createButton}
                            />
                    }
                    <ListFragment store={store.listFragmentStore} />
                </>);
        }
    }

    renderInternalList(store: ListStore<any, any, any, any, any, any>,
                       component: JSX.Element)
    {
        if (store.isDraggable)
        {
            return <Droppable
                droppableId={store.droppableId()}
                type={store.droppableType()}
            >
                {
                    (provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                        >
                            {component}
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>;
        }
        else
        {
            return component;
        }
    }

    renderEmptyList(store: ListStore<any, any, any, any, any, any>)
    {
        if (store.showNoDataLabel)
        {
            return <Text store={store.emptyTextStore} />;
        }
        else
        {
            return null;
        }
    }
}

export default withStyles(styles as any)(List);
