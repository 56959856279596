import { useMemo } from 'react';
import PrimitiveValue from '../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../@Component/Domain/DataObject/Model/DataObject';

export default function useFalseValue()
{
    return useMemo(
        () =>
            new PrimitiveValue(DataObject.constructFromTypeIdAndValue(
                'Boolean',
                false)
            ),
        []
    );
}