import { GlobalEnvironment } from '../../../../../@Global/GlobalEnvironment';

const assetRequire = require.context('../../../../../@Resource/Image/FileIcon/', true, /\.png$/);

export const SupportedPreviewImageMimeTypes = new Set<string>([
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/svg+xml'
]);

export class FileUtils
{
    static getFileAvatar(url: string, mime: string): string
    {
        if (mime)
        {
            switch (mime)
            {
                case 'application/pdf':
                    return getBlobOrLocalFile(assetRequire('./pdf.png').default);
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                case 'application/msword':
                    return getBlobOrLocalFile(assetRequire('./word.png').default);
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                case 'application/vnd.ms-excel':
                    return getBlobOrLocalFile(assetRequire('./excel.png').default);
                case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                case 'application/vnd.ms-powerpoint':
                    return getBlobOrLocalFile(assetRequire('./powerpoint.png').default);
                default:
                    if (SupportedPreviewImageMimeTypes.has(mime.toLowerCase()))
                    {
                        return url;
                    }
                    else
                    {
                        return getBlobOrLocalFile(assetRequire('./default.png').default);
                    }
            }
        }
        else
        {
            return getBlobOrLocalFile(assetRequire('./default.png').default);
        }
    }
}

function getBlobOrLocalFile(
    filePath: string
)
{
    if (filePath.startsWith('blob:')
        || filePath.startsWith('data:'))
    {
        return filePath;
    }
    else
    {
        return `${GlobalEnvironment.APP_ENDPOINT}${filePath}`;
    }
}
