import { BaseStore, getOrCompute, PropType } from '../../../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityConstructorStore } from '../EntityConstructorStore';
import { StoreState } from '../../../../../@Framework/Store/@Model/StoreState';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { ModalDialogStore } from '../../../../Generic/Dialog/Type/ModalDialogStore';
import { ViewComponent } from '../../../../Generic/ViewStack/Model/ViewComponent';
import { EntityConstructor } from '../EntityConstructor';
import { DialogStore } from '../../../../Generic/Dialog/Type/DialogStore';
import { RouterStore } from '../../../../../@Service/Router/RouterStore';
import { EntityViewerPageStore } from '../../Viewer/Page/EntityViewerPageStore';
import { EntityViewerStore } from '../../Viewer/Deprecated/EntityViewerStore';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { FeedbackStore } from '../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import { EntityTypeStore } from '../../Type/EntityTypeStore';

export interface EntityConstructorModalProps
{
    constructorStore: PropType<EntityConstructorModalStore, EntityConstructorModalProps, EntityConstructorStore>;
    isOpen?: PropType<EntityConstructorModalStore, EntityConstructorModalProps, boolean>;
    allowOpen?: PropType<EntityConstructorModalStore, EntityConstructorModalProps, boolean>;
    doPersist?: PropType<EntityConstructorModalStore, EntityConstructorModalProps, boolean>;
    onSave?: (entity: Entity, doStartChecklist: boolean) => void | Promise<any>;
    onOpen?: (entity: Entity, doStartChecklist: boolean) => void;
    onClose?: (store: EntityConstructorModalStore) => void;
    onDelete?: (entity: Entity) => void;
}

const defaultProps: Partial<EntityConstructorModalProps> =
{
    isOpen: true,
    allowOpen: true,
    doPersist: true
};

export class EntityConstructorModalStore extends BaseStore<EntityConstructorModalProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('RouterStore') routerStore: RouterStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('FeedbackStore') feedbackStore: FeedbackStore;

    // ------------------------- Properties -------------------------

    @observable constructorStore: EntityConstructorStore;
    @observable isOpen: boolean = false;
    @observable onSave?: (entity: Entity, doStartChecklist: boolean) => void | Promise<any>;
    @observable onClose?: (store: EntityConstructorModalStore) => void;
    @observable onDelete?: (entity: Entity) => void;
    @observable allowOpen: boolean;
    @observable onOpen?: (entity: Entity, doStartChecklist: boolean) => void;
    @observable doPersist: boolean;
    @observable modalDialogStore: ModalDialogStore;

    // ------------------------ Constructor -------------------------

    constructor(props: EntityConstructorModalProps)
    {
        super(props, defaultProps);

        this.constructorStore = getOrCompute(this, this.props.constructorStore);
        this.constructorStore.onRequestClose = () => this.close(true);

        this.isOpen = getOrCompute(this, this.props.isOpen);
        this.onSave = this.props.onSave;
        this.allowOpen = getOrCompute(this, this.props.allowOpen);
        this.onOpen = this.props.onOpen;
        this.onClose = this.props.onClose;
        this.onDelete = this.props.onDelete;
        this.doPersist = getOrCompute(this, this.props.doPersist);
        this.constructorStore.onSave = this.onSave as any;
        this.constructorStore.onOpen = this.onOpen as any;
        this.constructorStore.onDelete = this.onDelete as any;
        this.constructorStore.allowOpen = this.allowOpen;
        this.constructorStore.hideCloseButton = true;

        this.modalDialogStore =
            new DialogStore({
                title: this.constructorStore.entityType.nameSingular,
                maxWidth: () => 'sm',
                isFullScreenOnSmallScreen: () => true,
                onClose:
                    () =>
                        this.close(true),
                viewComponent:
                    new ViewComponent(
                        EntityConstructor,
                        this.constructorStore),
                isOpen: this.isOpen,
                icon: this.constructorStore.entityType.getInheritedIcon()
            });
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    @computed
    get firstChecklistItemName(): string
    {
        return undefined;

        // TODO MS: this is a temporarily solution: After updating the entityChecklist to the new list it's not possible to fetch that provider
        // if (this.constructorStore.entity && this.constructorStore.entity.entityType)
        // {
        //     const provider = new SalesOfferChecklistDataProvider();
        //
        //     return provider.provide(
        //         this.constructorStore.entity,
        //         undefined,
        //         () => undefined,
        //         () => Promise.resolve(),
        //         () => undefined)
        //         .then(items =>
        //         {
        //
        //             if (provider && provider.hasInitialChecklistItem())
        //             {
        //                 return provider.getInitialChecklistItemName();
        //             }
        //             else
        //             {
        //                 return undefined;
        //             }
        //         });
        // }
        // else
        // {
        //     return undefined;
        // }
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    save(doStartChecklist: boolean = false,
         doOpen: boolean = false): Promise<any>
    {
        this.constructorStore.touchAll();

        if (this.constructorStore.isValid)
        {
            if (this.doPersist)
            {
                this.setState(StoreState.Loading);

                return this.constructorStore.entity.checkAndDoCommit()
                    .then(entity =>
                    {
                        return this.performSaveCallback(entity, doStartChecklist)
                            .then(() =>
                            {
                                this.close(false);

                                const entityToOpen = entity.entityType.bespoke.getEntityToOpen(entity);

                                if (doOpen && this.allowOpen)
                                {
                                    if (this.onOpen)
                                    {
                                        this.onOpen(
                                            entityToOpen,
                                            doStartChecklist);
                                    }
                                    else
                                    {
                                        this.routerStore
                                            .pushPage(
                                                new EntityViewerPageStore(
                                                    entityToOpen,
                                                    new EntityViewerStore(
                                                        entityToOpen)));
                                    }
                                }
                            });
                    })
                    .catch(reason =>
                    {
                        this.setState(StoreState.Error);

                        if (reason.error === 'duplicate-relationship')
                        {
                            this.feedbackStore.enqueueSnackbar(
                                'De verhouding die u probeert te registreren bestaat al.',
                                {
                                    variant: 'error'
                                });
                        }

                        this.close(true);
                    });
            }
            else
            {
                return this.performSaveCallback(
                    this.constructorStore.entity,
                    doStartChecklist)
                    .then(
                        () =>
                        {
                            this.close(false);
                        });
            }
        }
        else
        {
            console.log('cannot save entity, entity is invalid', this.constructorStore);
        }
    }

    @action.bound
    close(doDeleteWhenNew: boolean)
    {
        if (doDeleteWhenNew
            && this.constructorStore.entity
            && this.constructorStore.entity.isNew())
        {
            this.constructorStore.entity.deleteEntity();
        }

        this.isOpen = false;

        if (this.onClose)
        {
            this.onClose(this);
        }

        return Promise.resolve();
    }

    // ------------------------ Public logic ------------------------

    performSaveCallback(entity: Entity,
                        doStartChecklist: boolean): Promise<any>
    {
        if (this.onSave)
        {
            return this.onSave(
                entity,
                doStartChecklist) || Promise.resolve();
        }
        else
        {
            return Promise.resolve();
        }
    }

    // ----------------------- Private logic ------------------------
}
