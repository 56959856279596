import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';

export class BespokeRelationshipPersonContactEmployee extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Relationship.Person.Contact.Employee')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isDisabled(entity: Entity, fieldPath: EntityFieldPath): boolean
    {
        return super.isDisabled(entity, fieldPath)
            || (fieldPath.field === this.types.Relationship.Person.Contact.Employee.Field.IsAdministrator
                && !this.entityTypeStore.currentUserStore.isAdministrator);
    }

    // ----------------------- Private logic ------------------------
}
