import { useComputed } from 'mobx-react-lite';
import { default as ResourcePlanner } from '../Model/ResourcePlanner';
import ResourceSelection from '../Model/ResourceSelection';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { Comparator } from '../../../DataObject/Model/Comparator';
import useTypes from '../../Type/Api/useTypes';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../DataObject/Model/DataObject';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import { useContext } from 'react';
import CurrentUserContext from '../../../User/CurrentUserContext';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import QueryComputation from '../../../../../@Api/Automation/Function/Computation/QueryComputation';
import AggregateQuery from '../../../../../@Api/Automation/Query/AggregateQuery';
import { Aggregate } from '../../../DataObject/Model/Aggregate';
import EmptyValue from '../../../../../@Api/Automation/Value/EmptyValue';
import { getMilestonesForProjectAndUser } from '../Api/getMilestonesForProjectAndUser';
import { getTimeRegistrationsWithoutMilestone } from '../Api/getTimeRegistrationsWithoutMilestone';
/**
 * Select TimeRegistrations per project of current user
 */
export function useMyProjectTimesheetByTimeRegistration()
{
    const types = useTypes();
    const currentUserStore = useContext(CurrentUserContext);

    return useComputed(
        () =>
        {
            const projectParameter =
                new Parameter(
                    'ProjectResource',
                    new EntityValueType(types.Activity.Project.Type),
                    true,
                    types.Activity.Project.Type.getName());

            const timeRegistrationParameter =
                types.TimeRegistration.Type &&
                    new Parameter(
                        'TimeRegistration',
                        new EntityValueType(types.TimeRegistration.Type),
                        true,
                        types.TimeRegistration.Type.getName());

            const currentEmployee =
                new EntityValue(currentUserStore.employeeEntity);

            return new ResourcePlanner(
                projectParameter,
                new ParameterDictionary([]),

                // ResourceFilter: Time registrations related to active project, related to current user, in selected period
                ({ startDate, endDate }) =>
                    new CompositePredicate(
                        LogicalOperator.And,
                        [
                            new ComparisonPredicate(
                                // Open projects
                                new ValueFromEntityComputation(
                                    projectParameter,
                                    EntityPath.fromEntityType(types.Activity.Project.Type)
                                        .joinTo(
                                            types.Activity.Project.RelationshipDefinition.Phase,
                                            false)
                                        .field(types.Datastore.Phase.Field.IsTerminal)
                                ),
                                Comparator.Equals,
                                new PrimitiveValue(
                                    DataObject.constructFromTypeIdAndValue(
                                        'Boolean',
                                        false
                                    )
                                )
                            ),
                            // Project contains Timeregistrations for current user
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    projectParameter,
                                    EntityPath.fromEntityType(types.Activity.Project.Type)
                                        .joinTo(
                                            types.Activity.RelationshipDefinition.TimeRegistrations,
                                            false
                                        )
                                        .joinTo(
                                            types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                            true
                                        )
                                        .field()
                                ),
                                Comparator.Equals,
                                currentEmployee
                            ),
                            // Project contains time registrations for current user in time window
                            new ComparisonPredicate(
                                new QueryComputation(
                                    new AggregateQuery(
                                        timeRegistrationParameter.type.type,
                                        timeRegistrationParameter,
                                        new CompositePredicate(
                                            LogicalOperator.And,
                                            [
                                                // Time registration is attached to project
                                                new ComparisonPredicate(
                                                    new ValueFromEntityComputation(
                                                        timeRegistrationParameter,
                                                        timeRegistrationParameter.type.type.path()
                                                            .joinTo(
                                                                types.Activity.RelationshipDefinition.TimeRegistrations,
                                                                true
                                                            )
                                                            .field()
                                                    ),
                                                    Comparator.Equals,
                                                    projectParameter
                                                ),
                                                // Time registration is attached to current employee
                                                new ComparisonPredicate(
                                                    new ValueFromEntityComputation(
                                                        timeRegistrationParameter,
                                                        timeRegistrationParameter.type.type.path()
                                                            .joinTo(
                                                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                                                true
                                                            )
                                                            .field()
                                                    ),
                                                    Comparator.Equals,
                                                    currentEmployee
                                                ),
                                                // Time registration overlaps with time window
                                                new ComparisonPredicate(
                                                    new ValueFromEntityComputation(
                                                        timeRegistrationParameter,
                                                        timeRegistrationParameter.type.type.path()
                                                            .field(types.TimeRegistration.Field.StartDate)
                                                    ),
                                                    Comparator.LessThan,
                                                    endDate
                                                ),
                                                new CompositePredicate(
                                                    LogicalOperator.Or,
                                                    [
                                                        new ComparisonPredicate(
                                                            new ValueFromEntityComputation(
                                                                timeRegistrationParameter,
                                                                timeRegistrationParameter.type.type.path()
                                                                    .field(types.TimeRegistration.Field.EndDate)
                                                            ),
                                                            Comparator.IsNotDefined,
                                                            EmptyValue.instance
                                                        ),
                                                        new ComparisonPredicate(
                                                            new ValueFromEntityComputation(
                                                                timeRegistrationParameter,
                                                                timeRegistrationParameter.type.type.path()
                                                                    .field(types.TimeRegistration.Field.EndDate)
                                                            ),
                                                            Comparator.GreaterThanOrEqual,
                                                            startDate
                                                        ),
                                                    ]
                                                )
                                            ]
                                        ),
                                        Aggregate.Count
                                    )
                                ),
                                Comparator.GreaterThan,
                                new PrimitiveValue(
                                    DataObject.constructFromTypeIdAndValue(
                                        'Number',
                                        0
                                    )
                                )
                            )
                        ]),

                // ResourceSelection: Timeregistration entities related to CurrentUser to show aggregated on project rows
                [
                    timeRegistrationParameter &&
                        new ResourceSelection(
                            timeRegistrationParameter,
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.TimeRegistrations,
                                    true
                                ),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .field(types.TimeRegistration.Field.StartDate),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .field(types.TimeRegistration.Field.EndDate),
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    timeRegistrationParameter,
                                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                                        .joinTo(
                                            types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                            true
                                        )
                                        .field()
                                ),
                                Comparator.Equals,
                                currentEmployee
                            ),
                            undefined,
                            undefined,
                            undefined,
                            true,
                            [
                                EntityPath.fromEntityType(types.TimeRegistration.Type)
                                    .joinTo(
                                        types.TimeRegistration.RelationshipDefinition.Activity,
                                        false
                                    )
                                    .field(),
                                EntityPath.fromEntityType(types.TimeRegistration.Type)
                                    .joinTo(
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                        true
                                    )
                                    .field()
                            ],
                        )
                ].filter(selection => selection),

                //ChildSelections
                [
                    getTimeRegistrationsWithoutMilestone(timeRegistrationParameter, projectParameter, currentEmployee),
                    getMilestonesForProjectAndUser(timeRegistrationParameter, projectParameter, currentEmployee),
                ],
                true
            );
        },
        [
            types,
            currentUserStore
        ]);
}
