import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './WidgetAdder.module.scss';
import Header from '../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import ExpansionPanel from '../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import uuid from '../../../../../../@Util/Id/uuid';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface WidgetAdderProps
{
    name?: React.ReactNode;
    onAdd?: () => void;
}

const WidgetAdder: React.FC<WidgetAdderProps> =
    props =>
    {
        const id =
            useMemo(
                () =>
                    uuid(),
                []);

        return <ExpansionPanel
            id={id}
            summary={
                <Header
                    title={props.name || '...'}
                    large
                    inset
                    classes={{
                        title: styles.title
                    }}
                />
            }
            expansion={
                <CardInset>
                    <div
                        className={styles.root}
                        onClick={props.onAdd}
                    >
                        {
                            props.onAdd &&
                                <div
                                    className={styles.foreground}
                                >
                                    <div
                                        className={styles.addLabel}
                                    >
                                        + <LocalizedText
                                            code="Dashboard.AddWidgetToDashboard"
                                            value="Widget toevoegen aan dashboard"
                                        />
                                    </div>
                                </div>
                        }
                        <div
                            className={styles.widget}
                        >
                            {props.children}
                        </div>
                    </div>
                </CardInset>
            }
        />;
    };

export default observer(WidgetAdder);
