import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import performAction from '../../../../../../@Api/Entity/performAction';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Layout from '../../../../../../@Api/Layout/Layout';
import ParameterAssignment from '../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import LayoutDependencyContext from '../../../../../../@Api/Layout/LayoutDependencyContext';
import AutomationDependencyContext from '../../../../../../@Api/Automation/AutomationDependencyContext';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import LayoutViewer from '../../../../../Domain/Layout/Viewer/LayoutViewer';
import { Helmet } from 'react-helmet';
import RouterContext from '../../../../../../@Service/Router/RouterContext';
import { useComputed } from 'mobx-react-lite';
import PortalUserContext from '../User/PortalUserContext';
import { usePortalThemeFromContext } from '../../../../../../@Api/Portal/Api/usePortalThemeFromContext';
import { getAndInitializeLayoutFromDescriptor } from '../../../../../../@Api/Layout/Api/getAndInitializeLayoutFromDescriptor';

export interface PortalProps
{
    route?: string;
}

interface PageDescriptor
{
    name: string;
    route: string;
    parameters: any;
    layout: any;
}

interface PageInstantiationDescriptor
{
    page: PageDescriptor;
    parameters: any;
    parameterAssignment: any;
}

interface Page
{
    name: string;
    route: string;
    parameters: ParameterDictionary;
    layout: Layout;
}

interface PageInstantiation
{
    page: Page;
    parameters: ParameterDictionary;
    parameterAssignment: ParameterAssignment;
}

const PortalPage: React.FC<PortalProps> =
    props =>
    {
        const { route } = props;
        const routerStore = useContext(RouterContext);
        const portalUser = useContext(PortalUserContext);
        usePortalThemeFromContext();

        const currentRoute =
            useComputed(
                () =>
                    route || routerStore.router.location.pathname,
                [
                    route,
                    routerStore
                ]);

        const [ currentRouteInstantiation ] =
            useAsyncResult<PageInstantiation>(
                () =>
                    performAction<PageInstantiationDescriptor>(
                        undefined,
                        {
                            code: 'Portal.GetPageInstantiation',
                            parameters: {
                                route: currentRoute
                            }
                        })
                        .then(
                            async page =>
                            {
                                const parameters =
                                    await ParameterDictionary.fromDescriptor(
                                        page.result.parameters,
                                        AutomationDependencyContext.GetEmptyContext()
                                    );
                                const layout =
                                    await getAndInitializeLayoutFromDescriptor(
                                        page.result.page.layout,
                                        new LayoutDependencyContext(
                                            parameters
                                        )
                                    );
                                const parameterAssignment =
                                    await ParameterAssignment.fromDescriptor(
                                        new AutomationDependencyContext(parameters),
                                        page.result.parameterAssignment
                                    );

                                return {
                                    page: {
                                        name: page.result.page.name,
                                        route: page.result.page.route,
                                        parameters: page.result.page.parameters,
                                        layout: layout
                                    },
                                    parameters: parameters,
                                    parameterAssignment: parameterAssignment
                                }
                            }),
                [
                    currentRoute,
                    portalUser.get()
                ]);

        if (!currentRouteInstantiation)
        {
            return <Centered
                horizontal
                vertical
            >
                <Loader />
            </Centered>;
        }
        else
        {
            return <>
                <Helmet>
                    <title>{currentRouteInstantiation.page.name}</title>
                </Helmet>
                <LayoutViewer
                    layout={currentRouteInstantiation.page.layout}
                    parameterDictionary={currentRouteInstantiation.parameters}
                    parameterAssignment={currentRouteInstantiation.parameterAssignment}
                />
            </>;
        }
    };

export default observer(PortalPage);
