import { DataObjectType } from '../../../Model/DataObjectType';
import { Comparator } from '../../../Model/Comparator';
import { DataObjectTextComparatorOverload } from './DataObjectTextComparatorOverload';

export function getTextComparatorOverloads(dataObjectType: DataObjectType)
{
    return [
        Comparator.Equals,
        Comparator.NotEquals,
        Comparator.LessThan,
        Comparator.LessThanOrEqual,
        Comparator.GreaterThan,
        Comparator.GreaterThanOrEqual,
        Comparator.Contains,
        Comparator.NotContains
    ].map(
        comparator =>
            new DataObjectTextComparatorOverload(
                dataObjectType,
                comparator));
}
