import { type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';
import { EntityField } from './EntityField';

@type('StaticEntityField')
export class StaticEntityField extends EntityField
{
    // ------------------- Persistent Properties --------------------

    @observable isModifiable: boolean;
    @observable objectPath: string;
    @observable sqlColumn: string;
    @observable valuePath: string;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
