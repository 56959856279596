import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';

export class BespokeCalendarItem extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'CalendarItem')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    orderByField(entity?: Entity, pathFromRelatedEntity?: RelatedEntityPath): EntityFieldPath
    {
        return EntityPath.root(this.type)
            .field(this.types.CalendarItem.Field.StartDate);
    }

    getEventTitle(entity: Entity): string
    {
        return entity.getObjectValueByField(this.types.CalendarItem.Field.Subject);
    }

    getEntityColorForCalendar(entity: Entity): string
    {
        if (entity.getObjectValueByField(this.types.CalendarItem.Field.IsPrivate))
        {
            return '#aaa';
        }

        return super.getEntityColorForCalendar(entity);
    }

    // ----------------------- Private logic ------------------------
}
