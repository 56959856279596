import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import { PrimaryButtonProps } from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import sendDocument from '../Api/sendDocument';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import useRelatedEntity from '../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';

export interface SendDocumentMenuItemProps extends Partial<PrimaryButtonProps>
{
    entity: Entity;
    actionCode: string;
    emailTemplateType: EntityType;
    label?: React.ReactNode;
    entityByParameter?: Map<string, Entity>;
}

const SendDocumentMenuItem: React.FC<SendDocumentMenuItemProps> =
    props =>
    {
        const types = useTypes();

        const parentOfDocument =
            useRelatedEntity(
                props.entity,
                types.Activity.RelationshipDefinition.LinkedActivities,
                true
            );

        const label =
            useComputed(
                () =>
                    props.label || localizeText('Generic.Send', 'Verzenden'),
                [
                    props.label
                ]);

        const onClick =
            useCallback(
                () =>
                    sendDocument(
                        props.entity,
                        props.emailTemplateType,
                        props.actionCode,
                        undefined,
                        parentOfDocument,
                        props.entityByParameter),
                [
                    props.entity,
                    props.emailTemplateType,
                    props.actionCode,
                    parentOfDocument,
                    props.entityByParameter
                ]);

        return <Menu>
                <Item
                    name={label}
                    onClick={onClick}
                />
            </Menu>

    };

export default observer(SendDocumentMenuItem);
