import { ButtonProps, ButtonStore } from '../../../Generic/Button/ButtonStore';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { action, computed } from 'mobx';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { LocalizationStore } from '../../../../@Service/Localization/LocalizationStore';
import { CurrentUserStore } from '../../User/CurrentUserStore';
import { createTransactionalModel } from '../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import deleteEntity from '../../../../@Api/Entity/deleteEntity';

export interface EntityActivationButtonProps extends ButtonProps
{
    entity: PropType<EntityActivationButtonStore, EntityActivationButtonProps, Entity>;
}

const defaultProps: Partial<EntityActivationButtonProps> =
{

};

export class EntityActivationButtonStore extends ButtonStore<EntityActivationButtonProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: EntityActivationButtonProps)
    {
        super({
            label:
                () =>
                    this.entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Entity.Field.IsActive)
                        ?
                            this.localizationStore.translate('EntityViewer.Label.MarkAs', this.inactiveName) // Mark as {inactiveName}
                        :
                            this.localizationStore.translate('EntityViewer.Label.UnMarkAs', this.inactiveName), // Unmark {inactiveName}
            onClick:
                () =>
                    this.toggleActivity(),
            isVisible:
                () => this.canBeInactivated,
            ...props,
        }, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get entity(): Entity
    {
        return getOrCompute(this, this.props.entity);
    }

    @computed
    get canBeInactivated(): boolean
    {
        return this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Type);
    }

    @computed
    get inactiveName(): string
    {
        if (this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Organization.Type))
        {
            return this.localizationStore.translate('EntityViewer.Label.Liquidated'); // Liquidated
        }
        else if (this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relation.Person.Type))
        {
            return this.localizationStore.translate('EntityViewer.Label.Deceased'); // Deceased
        }
        else
        {
            return null;
        }
    }

    @action.bound
    toggleActivity()
    {
        const transactionalEntity = createTransactionalModel(this.entity);

        transactionalEntity.setValueByField(
            this.entityTypeStore.bespoke.types.Entity.Field.IsActive,
            !transactionalEntity.getObjectValueByField(this.entityTypeStore.bespoke.types.Entity.Field.IsActive));

        return transactionalEntity.checkAndDoCommit();
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    deleteEntity()
    {
        return deleteEntity(this.entity);
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
