import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { computed, observable } from 'mobx';

export class HexagonIconStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable scaleFactor: number = Math.tan(30 * Math.PI / 180);
    @observable size: number;
    @observable color: string;
    @observable icon?: string;
    @observable iconColor: string;
    @observable borderColor: string;
    @observable imageUrl?: string;
    @observable hasBorder: boolean = true;
    @observable hasShadow: boolean;
    @observable shadowColor: string;
    @observable shadowBlur: number;

    // ------------------------ Constructor -------------------------

    constructor(size: number = 35,
                icon?: string,
                imageUrl?: string,
                color: string = '#64C7CC',
                iconColor: string = 'white',
                borderColor: string = color,
                showShadow: boolean = false,
                shadowColor: string = 'rgba(0,0,0,0.6)',
                shadowBlur: number = 20)
    {
        super();

        this.size = size;
        this.color = color;
        this.icon = icon;
        this.imageUrl = imageUrl;
        this.iconColor = iconColor;
        this.borderColor = borderColor;
        this.shadowColor = shadowColor;
        this.shadowBlur = shadowBlur;
        this.hasShadow = showShadow;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get backgroundColor(): string
    {
        return this.imageUrl
            ?
                undefined
            :
                this.color;
    }

    @computed
    get height(): number
    {
        return this.size / Math.sqrt(3);
    }

    @computed
    get capWidth(): number
    {
        return this.size / Math.sqrt(2);
    }

    @computed
    get topStyle(): any
    {
        if (this.hasShadow)
        {
            return {
                position: 'absolute',
                zIndex: 1,
                width: `${this.capWidth}px`,
                height: `${this.capWidth}px`,
                transform: `scaleY(${this.scaleFactor}) rotate(-45deg)`,
                backgroundColor: this.backgroundColor,
                left: `${(this.size - this.capWidth) / 2}px`,
                top: `-${this.capWidth / 2}px`,
                boxShadow: `0 0 ${this.shadowBlur}px ${this.shadowColor}`
            };
        }
        else
        {
            return {
                position: 'absolute',
                width: 0,
                borderLeft: `${this.size / 2}px solid transparent`,
                borderRight: `${this.size / 2}px solid transparent`,
                bottom: '100%',
                borderBottom: `${this.height / 2}px solid ${this.color}`
            };
        }
    }

    @computed
    get middleStyle(): any
    {
        if (this.hasShadow)
        {
            return {
                position: 'absolute',
                width: `${this.size}px`,
                height: `${this.height}px`,
                backgroundColor: this.backgroundColor,
                boxShadow: this.hasShadow ? `0 0 ${this.shadowBlur}px ${this.shadowColor}` : undefined
            };
        }
        else
        {
            return {
                position: 'absolute',
                width: `${this.size}px`,
                height: `${this.height}px`,
                backgroundColor: this.backgroundColor
            };
        }
    }

    @computed
    get bottomStyle(): any
    {
        if (this.hasShadow)
        {
            return {
                position: 'absolute',
                zIndex: 1,
                width: `${this.capWidth}px`,
                height: `${this.capWidth}px`,
                transform: `scaleY(${this.scaleFactor}) rotate(-45deg)`,
                backgroundColor: this.backgroundColor,
                left: `${(this.size - this.capWidth) / 2}px`,
                bottom: `-${Math.ceil(this.capWidth / 2)}px`,
                boxShadow: `0 0 ${this.shadowBlur}px ${this.shadowColor}`
            };
        }
        else
        {
            return {
                position: 'absolute',
                width: 0,
                borderLeft: `${this.size / 2}px solid transparent`,
                borderRight: `${this.size / 2}px solid transparent`,
                // top: '100%',
                borderTop: `${this.height / 2}px solid ${this.color}`,
                margin: `${this.height}px 0`
            };

        }
    }

    @computed
    get coverStyle(): any
    {
        return {
            display: 'block',
            position: 'absolute',
            top: `${this.scaleFactor}px`,
            left: 0,
            width: `${this.size}px`,
            height: `${this.height}px`,
            zIndex: 2,
            backgroundColor: this.backgroundColor
        };
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
