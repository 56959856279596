import React, { useCallback, useEffect, useMemo, useState } from 'react';
import StaticSelectbox from '../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import { NumberSequenceDefinitionComponent, NumberSequenceDefinitionComponentType } from './DefinitionBuilder';
import TextEditor from '../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import FieldPathSelector from '../../Entity/Path/FieldPathEditor/FieldPathSelector';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { EntityFieldPath } from '../../Entity/Path/@Model/EntityFieldPath';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import { EntityContext } from '../../Entity/@Model/EntityContext';
import { EntityField } from '../../../../@Api/Model/Implementation/EntityField';
import { NumberType } from '../../DataObject/Type/Number/NumberType';
import localizeText from '../../../../@Api/Localization/localizeText';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';

export interface DefinitionComponentProps
{
    component: NumberSequenceDefinitionComponent;
    onChange: (component: NumberSequenceDefinitionComponent) => void;
    sequenceHasNumber?: boolean;
    entityType: EntityType;
    entityField: EntityField;
}

const DefinitionComponent: React.FC<DefinitionComponentProps> =
    props =>
    {
        const [ type, setType ] = useState(props.component.type);
        const [ data, setData ] = useState(props.component.data)
        const { onChange } = props;

        useEffect(
            () =>
            {
                if (type !== props.component.type || data !== props.component.data)
                {
                    onChange({
                        uuid: props.component.uuid,
                        type: type,
                        data: data
                    });
                }
            },
            [
                type,
                data,
                onChange,
                props.component
            ]);

        const partTypes =
            useMemo(
                () =>
                    [
                        ...(props.component.type === 'Number' || !props.sequenceHasNumber)
                            ?
                                [
                                    {
                                        id: 'Number',
                                        value: 'Number',
                                        label: localizeText('NumberSequence.PartType.SequenceNumber', 'Volgnummer')
                                    }
                                ]
                            :
                                [],
                        {
                            id: 'Text',
                            value: 'Text',
                            label: localizeText('NumberSequence.PartType.Text', 'Text')
                        },
                        {
                            id: 'DateDD',
                            value: 'DateDD',
                            label: localizeText('NumberSequence.PartType.DateDD', 'Dag [DD]')
                        },
                        {
                            id: 'DateYY',
                            value: 'DateYY',
                            label: localizeText('NumberSequence.PartType.DateYY', 'Jaar [YY]')
                        },
                        {
                            id: 'DateYYYY',
                            value: 'DateYYYY',
                            label: localizeText('NumberSequence.PartType.DateYYYY', 'Jaar [YYYY]')
                        },
                        {
                            id: 'DateMM',
                            value: 'DateMM',
                            label: localizeText('NumberSequence.PartType.DateMM', 'Maand [MM]')
                        },
                        {
                            id: 'DateWW',
                            value: 'DateWW',
                            label: localizeText('NumberSequence.PartType.DateWW', 'Week [WW]')
                        },
                        {
                            id: 'FieldPath',
                            value: 'FieldPath',
                            label: localizeText('NumberSequence.PartType.FieldPath', 'Veld')
                        }
                    ]
                    .filter(e => e)
                    .filter(
                        type =>
                        {
                            if (props.entityField.dataObjectSpecification.type instanceof NumberType)
                            {
                                return type.id === 'Number';
                            }
                            else
                            {
                                return true;
                            }
                        }),
                [
                    props.component,
                    props.sequenceHasNumber,
                    props.entityField
                ]);

        const onTypeSelectionChange =
            useCallback(
                (type: NumberSequenceDefinitionComponentType) =>
                {
                    setType(type);

                    if (type !== 'Text' && type !== 'FieldPath')
                    {
                        setData(undefined);
                    }
                },
                [
                    setType,
                    setData
                ]);

        const onFieldPathSelectionChange =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                {
                    setData(fieldPath.descriptor);
                },
                [
                    setData
                ]);

        const fieldPath =
            useMemo(
                () =>
                    type === 'FieldPath'
                    && data
                    && EntityFieldPath.construct(data),
                [
                    type,
                    data
                ]);

        const context =
            useMemo(
                () =>
                    EntityContext.fromEntityType(props.entityType),
                [
                    props.entityType
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={10}
            alignment="center"
        >
            <ViewGroupItem
                ratio={1}
            >
                <StaticSelectbox
                    options={partTypes}
                    onChange={onTypeSelectionChange}
                    value={type}
                    disableUnderline={false}
                />
            </ViewGroupItem>
            {
                type === 'Text' &&
                    <ViewGroupItem
                        ratio={1}
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={10}
                        >
                            <ViewGroupItem
                            >
                                Text:
                            </ViewGroupItem>
                            <ViewGroupItem
                                ratio={1}
                            >
                                <TextEditor
                                    onChange={setData}
                                    value={data}
                                    placeholder={localizeText('NumberSequence.CustomText', 'Vrije tekst')}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
            }
            {
                type === 'FieldPath' &&
                    <ViewGroupItem
                        ratio={1}
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={10}
                        >
                            <ViewGroupItem>
                                {
                                    fieldPath ? fieldPath.getName() : localizeText('NumberSequence.ChooseField', 'Kies een veld')
                                }
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <MenuButton
                                    key={fieldPath ? fieldPath.id : 'undefined'}
                                    icon="edit"
                                    tooltip={
                                        <LocalizedText
                                            code="Generic.Edit"
                                            value="Bewerken"
                                        />
                                    }
                                    small
                                >
                                    <FieldPathSelector
                                        context={context}
                                        onSelect={onFieldPathSelectionChange}
                                    />
                                </MenuButton>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default DefinitionComponent;
