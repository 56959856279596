import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../Type/Api/useTypes';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import RightAlignedButtonGroup from '../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import NumberEditor from '../../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import SaveButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { add, getWeek, getYear } from 'date-fns';
import { ItemPlannerEmployeePlanTableRowProps } from './EmployeeAllocationTableEditorRow';

export interface EmployeeAllocationTableEditorRowRepeatEditorProps extends ItemPlannerEmployeePlanTableRowProps
{
    item: Entity;
    onClose: () => void;
}

export const EmployeeAllocationTableEditorRowRepeatEditor: React.FC<EmployeeAllocationTableEditorRowRepeatEditorProps> =
    observer(
        ({
             item,
             onClose,
             ...otherProps
         }) =>
        {
            const [ weeks, setWeeks ] = useState(2);
            const types = useTypes();
            const save =
                useCallback(
                    () =>
                        new CommitBuilder()
                            .ifValid(
                                () => true,
                                builder =>
                                {
                                    const startWeekDate = item.getObjectValueByField<Date>(types.EmployeeAllocation.Field.WeekDate);
                                    const sequence =
                                        builder
                                            .createEntity(
                                                types.EmployeeAllocationSequence.Type,
                                                sequenceBuilder =>
                                                    sequenceBuilder
                                                        .setObjectValue(
                                                            types.EmployeeAllocationSequence.Field.StartDate,
                                                            startWeekDate
                                                        )
                                                        .setObjectValue(
                                                            types.EmployeeAllocationSequence.Field.EndDate,
                                                            add(startWeekDate, { weeks })
                                                        ),
                                                'Sequence'
                                            )
                                            .getEntityOrThrow('Sequence');

                                    builder.relateEntityTo(
                                        item,
                                        true,
                                        types.EmployeeAllocationSequence.RelationshipDefinition.Allocations,
                                        sequence
                                    );

                                    for (let i = 1; i < weeks; i++)
                                    {
                                        const nextItemWeekDate = add(startWeekDate, { weeks: i });
                                        builder.createEntity(
                                            types.EmployeeAllocation.Type,
                                            itemBuilder =>
                                                itemBuilder
                                                    .relateTo(
                                                        true,
                                                        types.EmployeeAllocationSequence.RelationshipDefinition.Allocations,
                                                        sequence
                                                    )
                                                    .setObjectValue(
                                                        types.EmployeeAllocation.Field.Year,
                                                        getYear(nextItemWeekDate)
                                                    )
                                                    .setObjectValue(
                                                        types.EmployeeAllocation.Field.Week,
                                                        getWeek(nextItemWeekDate)
                                                    )
                                                    .setObjectValue(
                                                        types.EmployeeAllocation.Field.WeekDate,
                                                        nextItemWeekDate
                                                    )
                                                    .setObjectValue(
                                                        types.EmployeeAllocation.Field.Description,
                                                        item.getObjectValueByField(types.EmployeeAllocation.Field.Description)
                                                    )
                                                    .setObjectValue(
                                                        types.EmployeeAllocation.Field.DurationInHours,
                                                        item.getObjectValueByField(types.EmployeeAllocation.Field.DurationInHours)
                                                    )
                                                    .relateTo(
                                                        true,
                                                        types.Relationship.RelationshipDefinition.EmployeeAllocations,
                                                        item.getRelatedEntityByDefinition(
                                                            true,
                                                            types.Relationship.RelationshipDefinition.EmployeeAllocations
                                                        )
                                                    )
                                                    .relateTo(
                                                        true,
                                                        types.Activity.RelationshipDefinition.EmployeeAllocations,
                                                        item.getRelatedEntityByDefinition(
                                                            true,
                                                            types.Activity.RelationshipDefinition.EmployeeAllocations
                                                        )
                                                    )
                                                    .relateTo(
                                                        true,
                                                        types.Milestone.RelationshipDefinition.EmployeeAllocations,
                                                        item.getRelatedEntityByDefinition(
                                                            true,
                                                            types.Milestone.RelationshipDefinition.EmployeeAllocations
                                                        )
                                                    )
                                                    .relateTo(
                                                        true,
                                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Allocations,
                                                        item.getRelatedEntityByDefinition(
                                                            true,
                                                            types.Relationship.Person.Contact.Employee.RelationshipDefinition.Allocations
                                                        )
                                                    )
                                        )
                                    }
                                }
                            )
                            .commit(),
                    [
                        weeks,
                        types,
                        item
                    ]
                );

            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                    >
                        <ViewGroupItem>
                            <LocalizedText
                                code="Generic.RepeatFor"
                                value="Herhalen voor"
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <NumberEditor
                                value={weeks}
                                onChange={setWeeks}
                                min={2}
                                max={50}
                                autoFocus
                                endAdornment={
                                    <LocalizedText
                                        code="Generic.Weeks"
                                        value="weken"
                                    />
                                }
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <RightAlignedButtonGroup>
                        <SaveButton
                            onClick={save}
                        />
                    </RightAlignedButtonGroup>
                </ViewGroupItem>
            </ViewGroup>;
        }
    );
