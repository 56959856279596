import * as React from 'react';
import { BaseComponent } from '../../../../../../@Framework/Component/BaseComponent';
import { DataObjectEditorStore } from './DataObjectEditorStore';
import { observer } from 'mobx-react';
import { DataObjectLabel } from '../Label/DataObjectLabel';
import { NoUnderlineClassName } from '../../../../../../@Resource/Theme/Theme';

@observer
export class DataObjectEditor extends BaseComponent<DataObjectEditorStore>
{
    renderComponent(store: DataObjectEditorStore): any
    {
        const element =
            React.createElement(
                store.dataObject.specification.type.editor(),
                {
                    store: store
                });

        if (store.hasLabel)
        {
            if (store.isLabelOnSide)
            {
                return this.renderWrapper(
                    store,
                    <>
                        {
                            store.hasTopBorder &&
                            <div
                                style={{
                                    width: '100%',
                                    borderTop: '1px solid #ebebeb',
                                    marginLeft: -10,
                                    marginTop: -5
                                }}
                            />
                        }
                        <div style={this.props.style}>
                            <div
                                style={{
                                    display: 'flex',
                                    minHeight: 45,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '33%'
                                    }}
                                >
                                    <DataObjectLabel store={store} />
                                </div>
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '67%'
                                    }}
                                    className={store.showUnderline ? undefined : NoUnderlineClassName}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{flex: '1 1 auto'}}>
                                            {element}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>);
            }
            else if (store.isLabelOnTop)
            {
                return this.renderWrapper(
                    store,
                    <>
                        {
                            store.hasTopBorder &&
                            <div
                                style={{
                                    width: '100%',
                                    borderTop: '1px solid #ebebeb',
                                    marginLeft: -10,
                                    marginTop: -5
                                }}
                            />
                        }
                        <div style={this.props.style}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        flex: '1 1 auto'
                                    }}
                                    className={store.showUnderline ? undefined : NoUnderlineClassName}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{flex: '1 1 auto'}}>
                                            {element}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>);
            }
            else
            {
                return this.renderWrapper(store, element);
            }
        }
        else
        {
            return element;
        }
    }

    renderWrapper(store: DataObjectEditorStore,
                  element: JSX.Element)
    {
        return <div
            onMouseEnter={store.startHovering}
            onMouseLeave={store.stopHovering}
        >
            {element}
        </div>;
    }
}
