import { observable } from 'mobx';
import Value from '../../Value/Value';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import getValueFromDescriptor from '../../Api/getValueFromDescriptor';
import ActionInvocation from './ActionInvocation';

export default class ActionInvocationResult
{
    // ------------------------- Properties -------------------------

    @observable invocationId: string;
    @observable.ref result: Value<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(invocationId: string,
                result: Value<any, any>)
    {
        this.invocationId = invocationId;
        this.result = result;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(descriptor: any,
                                invocation: ActionInvocation)
    {
        const invocationId = descriptor.invocationId;

        const result =
            await getValueFromDescriptor(
                descriptor.result,
                AutomationDependencyContext.GetEmptyContext());

        return new ActionInvocationResult(
            invocationId,
            result);
    }

    // ----------------------- Private logic ------------------------
}
