import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import MenuButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import uuid from 'uuid/v4';
import List from '../../../Model/Specification/List';
import FieldPathSelector from '../../../../Path/FieldPathEditor/FieldPathSelector';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import { runInAction } from 'mobx';
import Column from '../../../Model/Specification/Column';
import { EntityContext } from '../../../../@Model/EntityContext';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface AddColumnButtonProps
{
    entityType: EntityType;
    list: List;
}

const AddColumnButton: React.FC<AddColumnButtonProps> =
    props =>
    {
        const onSelect =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                        {
                            props.list.columns.push(
                                new Column(
                                    uuid(),
                                    fieldPath));
                        }),
            [
                props.entityType,
                props.list
            ]);

        const context =
            useComputed(
                () =>
                    EntityContext.fromEntityType(props.entityType),
                [
                    props.entityType
                ]);

        return <MenuButton
            icon="add"
            tooltip={
                <LocalizedText
                    code="EntityView.List.AddColumn"
                    value="Kolom toevoegen"
                />
            }
            small
        >
            <FieldPathSelector
                context={context}
                onSelect={onSelect}
            />
        </MenuButton>;
    };

export default observer(AddColumnButton);
