import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';

export class TokenController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    validateToken(resource: string,
                  resourceId: string,
                  token: string): Promise<string>
    {
            return new Promise<string>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/public/token/validate', Method.Get, { resource: resource, resource_id: resourceId, token: token }))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
}
