import { MonthlyRecurrencePattern } from '../../../Model/RecurrencePattern';
import { Frequency, Options } from 'rrule';

export function getMonthlyRecurrencePatternAsRRule(
    pattern: MonthlyRecurrencePattern
): Partial<Options>
{
    return {
        freq: Frequency.MONTHLY,
        interval: pattern.interval ?? 1,
        bymonthday: pattern.dayOfMonth,
    };
}
