import React from 'react';
import { observer } from 'mobx-react';
import { WidgetProps } from '../Widget';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import { default as InternalResourcePlanner } from '../../../ResourcePlanner/ResourcePlanner';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import useMyPlanner from '../../../ResourcePlanner/Type/useMyTimesheet';
import MyTimesheetWidget from '../../Model/Widget/MyTimesheet/MyTimesheetWidget';

export interface MyTimesheetProps extends WidgetProps<MyTimesheetWidget> {
}

const MyTimesheet: React.FC<MyTimesheetProps> =
    props =>
    {
        const resourcePlanner = useMyPlanner();

        return <Card>
            <CardInset
                horizontal={false}
                bottom={false}
            >
                <InternalResourcePlanner
                    id={props.widget.id}
                    resourcePlanner={resourcePlanner}
                    name={
                        <LocalizedText
                            code="ResourcePlanner.MyTimesheet"
                            value="Mijn timesheet"
                        />
                    }
                    appendix={
                        <MenuButton>
                            <Menu>
                                <DeleteItem
                                    onClick={props.onDelete}
                                />
                            </Menu>
                        </MenuButton>
                    }
                    dragHandle
                    timesheet
                />
            </CardInset>
        </Card>;
    };

export default observer(MyTimesheet);
