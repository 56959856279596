import { Entity } from '../../../../Model/Implementation/Entity';
import { useMemo } from 'react';
import { CommitContext } from '../CommitContext';
import { getModel } from '../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { runInAction } from 'mobx';

export function useCommittableEntity(entity: Entity | undefined,
                                     context: CommitContext)
{
    const originalEntity = getModel(entity);

    useMemo(
        () =>
        {
            if (originalEntity)
            {
                const oldContext = originalEntity.commitContext;

                runInAction(
                    () =>
                        originalEntity.commitContext = context
                );

                return () =>
                {
                    runInAction(
                        () =>
                            originalEntity.commitContext = oldContext
                    );
                };
            }
        },
        [
            originalEntity,
            context,
        ]
    );

    return originalEntity;
}
