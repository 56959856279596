import { reference, registerType, type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';
import { LanguagePackage } from './LanguagePackage';
import { EntityMetadata } from './EntityMetadata';
import { User } from './User';
import { SettingPackage } from './SettingPackage';
import { Entity } from './Entity';
import { Currency } from '../../Localization/Currency';

@type('WelcomePackage')
export class WelcomePackage
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'LanguagePackage') @observable.ref languagePackage: LanguagePackage;
    @reference(undefined, 'SettingPackage') @observable.ref settingPackage: SettingPackage;
    @reference(undefined, 'EntityMetadata') @observable.ref metadata: EntityMetadata;
    @reference(undefined, 'User') @observable.ref user: User;
    @reference(undefined, 'Entity') @observable.ref team: Entity;
    @reference(undefined, 'Entity') @observable.ref contract: Entity;
    @reference(undefined, 'Currencies') @observable.ref currencies: Currency[];

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}

registerType(
    LanguagePackage,
    'LanguagePackage');

registerType(
    SettingPackage,
    'SettingPackage');

registerType(
    EntityMetadata,
    'EntityMetadata');
