import React from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Default from '../Default';
import { ContentProps } from '../Content';
import EntityRecurrencePatternEditor from '../../../Viewer/Shared/RecurrencePattern/EntityRecurrencePatternEditor';
import useTypes from '../../../Type/Api/useTypes';

export interface CalendarItemProps extends ContentProps
{

}

const CalendarItem: React.FC<CalendarItemProps> =
    props =>
    {
        const types = useTypes();

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <Default
                    {...props}
                    entity={props.entity}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <EntityRecurrencePatternEditor
                    {...props}
                    entity={props.entity}
                    startDateField={types.CalendarItem.Field.StartDate}
                    isRecurringField={types.CalendarItem.Field.IsRecurring}
                    recurrencePatternField={types.CalendarItem.Field.RecurrencePattern}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(CalendarItem);
