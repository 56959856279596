import React, { useContext } from 'react';
import { classNames } from '../../../Util/Class/classNames';
import styles from './AvatarWithSubAvatar.module.scss';
import IsInCardContext from '../Card/IsInCardContext';

export interface AvatarWithSubAvatarClasses
{
    root?: string;
    avatar?: string;
    subAvatar?: string;
}

export interface AvatarWithSubAvatarProps
{
    avatar: JSX.Element;
    subAvatar: JSX.Element;
    classes?: AvatarWithSubAvatarClasses;
}

const AvatarWithSubAvatar: React.FC<AvatarWithSubAvatarProps> =
    props =>
    {
        const isInCard = useContext(IsInCardContext);

        return <div
            className={classNames(
                styles.root,
                props.classes && props.classes.root
            )}
            style={{
                width: props.avatar.props.size,
                height: props.avatar.props.size,
            }}
        >
            <span
                className={classNames(
                    styles.avatar,
                    props.classes && props.classes.avatar)}
            >
                {props.avatar}
            </span>
            <span
                className={classNames(
                    styles.subAvatar,
                    isInCard && styles.subAvatarBackgroundInCard,
                    props.classes && props.classes.subAvatar)}
            >
                {props.subAvatar}
            </span>
        </div>;
    };

export default AvatarWithSubAvatar;
