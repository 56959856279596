import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import useCampaignUndeliverableCount from '../Hooks/useCampaignUndeliverableCount';
import CampaignPercentageChart from './CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import useCampaignUndeliveredCount from '../Hooks/useCampaignUndeliveredCount';

export interface CampaignChartUndeliverableProps
{
    campaign: Entity;
    size: number;
}

const CampaignChartUndeliverable: React.FC<CampaignChartUndeliverableProps> =
    props =>
    {
        const campaign = props.campaign;

        const undeliveredCount = useCampaignUndeliveredCount(campaign);
        const undeliverableCount = useCampaignUndeliverableCount(campaign);

        const allSet =
            useAllSet([
                undeliveredCount,
                undeliverableCount
            ]);

        const selection =
            useMemo(
                () =>
                {
                    return `${undeliverableCount} / ${undeliveredCount}`;
                },
                [
                    undeliverableCount,
                    undeliveredCount
                ]
            )

        return <div>
            {
                allSet &&
                    <CampaignPercentageChart
                        value={undeliverableCount / undeliveredCount * 100}
                        size={props.size}
                        label={localizeText('Campaign.Chart.Failed', 'Mislukt')}
                        color={'#8f0012'}
                        selection={selection}
                    />
            }
        </div>;
    };

export default observer(CampaignChartUndeliverable);
