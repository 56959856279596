import { observable } from 'mobx';
import { EntityField } from '../../../../../@Api/Model/Implementation/EntityField';
import Input from './Input';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../Entity/Type/EntityTypeStore';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';
import { EntityPath } from '../../../Entity/Path/@Model/EntityPath';

export default class FieldInput extends Input
{
    // ------------------------- Properties -------------------------

    @observable field: EntityField;

    // ------------------------ Constructor -------------------------


    constructor(entityType: EntityType,
                field: EntityField)
    {
        super(entityType);

        this.field = field;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        const entityType = loadModuleDirectly(EntityTypeStore).getTypeByIdOrCode(descriptor.entityTypeId, descriptor.entityTypeCode);
        const field = loadModuleDirectly(EntityTypeStore).getFieldByIdOrCode(descriptor.fieldId, descriptor.fieldCode);

        if (entityType && field)
        {
            return new FieldInput(
                entityType,
                field);
        }
        else
        {
            return undefined;
        }
    }

    id()
    {
        return `${super.id()}:${this.field.isStaticField() ? this.field.code : this.field.id}`;
    }

    getName()
    {
        return this.field.name;
    }

    isRequired(): boolean
    {
        return this.field.isRequired || this.isRequiredBySetting();
    }

    isComputed(): boolean
    {
        return this.field.isComputedField;
    }

    getValue(entity: Entity): DataObject
    {
        return entity.getDataObjectValueByField(this.field);
    }

    getFieldCode(): string
    {
        const split = this.field.code.split('.');

        return split[split.length - 1];
    }

    toFieldPath(): EntityFieldPath
    {
        return EntityPath.root(this.entityType)
            .field(this.field);
    }

    fromType(entityType: EntityType): Input
    {
        return new FieldInput(
            entityType,
            this.field
        );
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Field',
            fieldId: this.field.isStaticField() ? undefined : this.field.id,
            fieldCode: this.field.isStaticField() ? this.field.code : undefined
        };
    }

    // ----------------------- Private logic ------------------------
}
