import React, { useEffect, useState } from 'react';
import { BaseButtonProps } from '../../Button';
import Icon from '../../../Icon/Icon';
import styles from './ExpansionButton.module.scss';
import ViewGroup from '../../../ViewGroup/ViewGroup';
import { classNames } from '../../../../../Util/Class/classNames';
import ViewGroupItem from '../../../ViewGroup/ViewGroupItem';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export type ChevronPosition = 'start' | 'end';

export interface ExpansionButtonClasses
{
    root?: string;
    label?: string;
    chevron?: string;
}

export interface ExpansionButtonProps extends BaseButtonProps
{
    expanded?: boolean;
    label?: React.ReactNode;
    classes?: ExpansionButtonClasses;
    forceDisplayLabel?: boolean;
    chevronPosition?: ChevronPosition;
}

const ExpansionButton: React.FC<ExpansionButtonProps> =
    props =>
    {
        const [ label, setLabel ] = useState<React.ReactNode>();
        const [ expanded, setExpanded ] = useState<boolean>(props.expanded);

        useEffect(
            () =>
            {
                if (!props.forceDisplayLabel && expanded)
                {
                    setLabel(localizeText('Hide', 'Verbergen'));
                }
                else if (props.label)
                {
                    setLabel(props.label);
                }
                else
                {
                    setLabel(localizeText('Generic.Details','details'));
                }
            },
            [ props.label, expanded, props.forceDisplayLabel ]);

        useEffect(
            () =>
            {
                setExpanded(props.expanded);
            },
            [ props.expanded ]);


        const chevronItem =
            <ViewGroupItem
                className={classNames(styles.iconWrapper, props.classes && props.classes.chevron)}
            >
                <Icon
                    className={styles.icon}
                    size={16}
                    icon={expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
                />
            </ViewGroupItem>;

        const labelItem =
            <ViewGroupItem
                className={classNames(styles.label)}
            >
                <div
                    className={props.classes && props.classes.label}
                >
                    {label}
                </div>
            </ViewGroupItem>;


        return <ViewGroup
            orientation="horizontal"
            spacing={5}
            className={classNames(styles.root, props.classes && props.classes.root)}
            alignment="center"
            onClick={props.onClick}
        >
            {props.chevronPosition === 'start' ? chevronItem : labelItem}
            {props.chevronPosition === 'start' ? labelItem : chevronItem}
        </ViewGroup>;
    };

ExpansionButton.defaultProps =
{
    chevronPosition: 'end'
};

export default ExpansionButton;
