import Function from '../Function/Function';
import ParameterDictionary from '../Parameter/ParameterDictionary';

export default function isFunctionCompatibleWithParameterDictionary(
    fn: Function<any, any>,
    parameterDictionary: ParameterDictionary
): boolean
{
    return fn.getDependencies()
        .every(
            element =>
                parameterDictionary.hasParameter(element.parameter)
        );
}
