const SampleColors = [
    {
        name: 'Blue',
        color: 'rgb(74, 144, 226)'
    },
    {
        name: 'Orange',
        color: '#ff7f50'
    },
    {
        name: 'White',
        color: '#ffffff'
    },
    {
        name: 'Black',
        color: '#000000'
    }
];

export default SampleColors;
