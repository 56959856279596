import { action, computed, Lambda, observable, ObservableMap } from 'mobx';
import TransactionalConfiguration from '../Shared/TransactionalConfiguration';
import TransactionalContext from '../Shared/TransactionalContext';
import { commit, createTransactionalModel, getModel, isDirty, isTransactionalModel, rollback, TransactionalModel, whyDirty } from '../Model/TransactionalModel';
import TransactionalCommitAdministration from '../Shared/TransactionalCommitAdministration';
import { isContextDeep } from '../Shared/TransactionUtils';

export type TransactionalElementType<T> = T | TransactionalModel<T>;

export function isTransactionalMap(element: any): boolean
{
    return element instanceof TransactionalMap;
}

export default class TransactionalMap<K, T>
{
    // ------------------------- Properties -------------------------

    @observable.shallow map: ObservableMap<K, T>;
    @observable.shallow transactionalMap: ObservableMap<K, TransactionalElementType<T>>;
    @observable.ref configuration: TransactionalConfiguration;
    @observable.ref context: TransactionalContext<T>;
    @observable.shallow disposers = observable.array<Lambda>();
    @observable isInitialized: boolean;

    // ------------------------ Constructor -------------------------

    constructor(array: ObservableMap<K, T>,
                configuration: TransactionalConfiguration,
                context: TransactionalContext<T>)
    {
        this.map = array;
        this.transactionalMap = observable.map();
        this.configuration = configuration;
        this.context = context;

        this.initialize();
    }

    // ----------------------- Initialization -----------------------

    initialize()
    {
        // Initialize first values
        this.reset();

        // Track changes of model map (when original changes, the transactional map should change as well)
        this.disposers.push(
            this.map.observe(
                change =>
                {
                    if (!this.context.isLocked)
                    {
                        // console.log('changing model map', this.context.formattedKeyPath, this.context.isLocked, this.context.parentContext !== undefined);

                        if (change.type === 'update' || change.type === 'add')
                        {
                            this.transactionalMap.set(
                                change.name,
                                this.resolveElement(
                                    change.newValue,
                                    change.name));
                        } else if (change.type === 'delete')
                        {
                            this.transactionalMap.delete(change.name);
                        }
                    }
                }));

        // Track changes of transactional map
        this.disposers.push(
            this.transactionalMap.intercept(
                change =>
                {
                    // console.log('changing transactional map', this.context.formattedKeyPath, this.context.doTrackChangesFromTransactionalModel, this.context.parentContext !== undefined);

                    if (this.context.doTrackChangesFromTransactionalModel)
                    {
                        if (change.type === 'update' || change.type === 'add')
                        {
                            change.newValue =
                                this.resolveElement(
                                    change.newValue as any,
                                    change.name);
                        } else if (change.type === 'delete')
                        {
                            // do nothing
                        }

                        return change;
                    } else
                    {
                        return change;
                    }
                }));

        this.isInitialized = true;
    }

    deinitialize()
    {
        if (this.isInitialized)
        {
            this.isInitialized = false;

            this.disposers.forEach(
                disposer =>
                    disposer());

            this.disposers.clear();
        }
    }

    // -------------------------- Computed --------------------------

    @computed
    get isLocallyDirty(): boolean
    {
        if (this.map.size !== this.transactionalMap.size)
        {
            return true;
        }

        let isDirty = false;

        this.map.forEach(
            (value, key) =>
            {
                if (value !== getModel(this.transactionalMap.get(key)))
                {
                    isDirty = true;
                }
            });

        if (isDirty)
        {
            return true;
        }

        this.transactionalMap.forEach(
            (value, key) =>
            {
                if (getModel(value) !== this.map.get(key))
                {
                    isDirty = true;
                }
            });

        return isDirty;
    }

    // -------------------------- Actions ---------------------------

    @action
    reset()
    {
        const replacementMap = observable.map();

        this.map.forEach(
            (element, key) =>
                replacementMap.set(
                    key,
                    this.resolveElement(
                        element,
                        key)));

        this.transactionalMap.replace(replacementMap);
    }

    @action
    commit(administration: TransactionalCommitAdministration): Promise<any>
    {
        if (administration.hasSeen(this))
        {
            return Promise.resolve(this.map);
        }

        administration.seen(this);

        const promises: Array<Promise<any>> = [];
        const replacementMap = observable.map();

        this.transactionalMap.forEach(
            (element, key) =>
            {
                replacementMap.set(
                    key,
                    getModel(element));

                if (isTransactionalModel(element))
                {
                    promises.push(
                        commit(
                            (element as TransactionalModel<T>),
                            administration));
                }
            });

        this.map.replace(replacementMap);

        return Promise.all(promises);
    }

    @action
    rollback(administration: TransactionalCommitAdministration)
    {
        if (administration.hasSeen(this))
        {
            return;
        }

        administration.seen(this);

        this.transactionalMap
            .forEach(
                element =>
                {
                    if (isTransactionalModel(element))
                    {
                        rollback(
                            element as TransactionalModel<T>,
                            administration);
                    }
                });

        this.reset();
    }

    // ------------------------ Public logic ------------------------

    resolveElement(element: T,
                   key: K): TransactionalElementType<T>
    {
        if (isContextDeep(this.context))
        {
            return createTransactionalModel(
                element,
                this.configuration,
                this.context.joinTo(
                    `${key}`,
                    element));
        } else
        {
            return element;
        }
    }

    isDirty(checkAdministration: TransactionalCommitAdministration): boolean
    {
        if (this.isLocallyDirty)
        {
            return true;
        }

        let isDeepDirty = false;

        this.transactionalMap.forEach(
            value =>
            {
                if (isTransactionalModel(value))
                {
                    if (isDirty(value, checkAdministration))
                    {
                        isDeepDirty = true;
                    }
                }
            });

        return isDeepDirty;
    }

    whyDirty(checkAdministration: TransactionalCommitAdministration): any
    {
        const descriptor: any =
            {
                isLocallyDirty: this.isLocallyDirty
            };

        this.transactionalMap.forEach(
            (element, key) =>
            {
                if (isTransactionalModel(element))
                {
                    const deepDescriptor = whyDirty(element, checkAdministration);

                    if (deepDescriptor !== undefined)
                    {
                        descriptor[key] = deepDescriptor;
                    }
                } else
                {
                    // TODO: check if locally dirty
                }
            });

        if (Object.keys(descriptor).length === 1 && !this.isLocallyDirty)
        {
            return undefined;
        } else
        {
            return descriptor;
        }
    }

    // ----------------------- Private logic ------------------------
}
