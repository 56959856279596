import Dependency from '../../../../Automation/Parameter/Dependency';
import Validation from '../../../../Automation/Validation/Validation';
import { observable } from 'mobx';
import Computation from '../../../../Automation/Function/Computation/Computation';
import Layout from '../../../Layout';
import LayoutDependencyContext from '../../../LayoutDependencyContext';
import getComputationFromDescriptor from '../../../../Automation/Api/getComputationFromDescriptor';
import getLayoutFromDescriptor from '../../../Api/getLayoutFromDescriptor';
import Parameter from '../../../../Automation/Parameter/Parameter';
import DataSourceValueType from '../../../../Automation/Value/Type/DataSourceValueType';
import getDependenciesWithoutParameters from '../../../../Automation/Api/getDependenciesWithoutParameters';
import PortalDataSourceSignature from '../../../../Portal/DataSource/PortalDataSourceSignature';

export class PortalTableColumn
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref name: Computation<any, any>;
    @observable.ref expression: Computation<any, any>;
    @observable isSearchable: boolean;
    @observable isFilterable: boolean;
    @observable isOrderable: boolean;
    @observable ratio: number;
    @observable.ref layout?: Layout;

    // ------------------------ Constructor -------------------------

    constructor(
        id: string,
        name: Computation<any, any>,
        expression: Computation<any, any>,
        isSearchable: boolean,
        isFilterable: boolean,
        isOrderable: boolean,
        ratio: number,
        layout?: Layout
    )
    {
        this.id = id;
        this.name = name;
        this.expression = expression;
        this.isSearchable = isSearchable;
        this.isFilterable = isFilterable;
        this.isOrderable = isOrderable;
        this.ratio = ratio;
        this.layout = layout;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return [
            ...this.name.validate(),
            ...this.expression.validate(),
            ...this.layout?.validate()
        ];
    }

    getDependencies(
        rowParameter: Parameter<DataSourceValueType>
    ): Dependency[]
    {
        return [
            ...this.name.getDependencies(),
            ...getDependenciesWithoutParameters(
                this.expression.getDependencies(),
                rowParameter
            ),
            ...getDependenciesWithoutParameters(
                this.layout?.getDependencies() ?? [],
                rowParameter
            ),
        ];
    }

    toDescriptor()
    {
        return {
            id: this.id,
            name: this.name.toDescriptor(),
            expression: this.expression.toDescriptor(),
            isSearchable: this.isSearchable,
            isFilterable: this.isFilterable,
            isOrderable: this.isOrderable,
            ratio: this.ratio,
            layout: this.layout?.toDescriptor(),
        };
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: LayoutDependencyContext,
        rowParameter: Parameter<DataSourceValueType>,
        dataSourceSignature: PortalDataSourceSignature
    )
    {
        const name =
            await getComputationFromDescriptor(
                descriptor.name,
                dependencyContext
            );
        const dependencyContextWithResultParameters =
            dependencyContext.withParameterDictionary(
                dataSourceSignature.resultParameters
            );
        const dependencyContextWithRowParameter =
            dependencyContext.withParameterDictionary(
                dependencyContext.parameterDictionary.getNewDictionaryWithParameter(
                    rowParameter
                )
            );
        const expression =
            await getComputationFromDescriptor(
                descriptor.expression,
                dependencyContextWithResultParameters
            );
        const layout =
            descriptor.layout === undefined
                ? undefined
                : await getLayoutFromDescriptor(
                    descriptor.layout,
                    dependencyContextWithRowParameter
                );

        return new PortalTableColumn(
            descriptor.id,
            name,
            expression,
            descriptor.isSearchable,
            descriptor.isFilterable,
            descriptor.isOrderable,
            descriptor.ratio,
            layout
        );
    }

    // ----------------------- Private logic ------------------------
}
