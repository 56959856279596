import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Card from '../../../../@Future/Component/Generic/Card/Card';
import HoverCardBottom from '../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import uuid from '../../../../@Util/Id/uuid';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import EventTriggerEditor from './EventTrigger/LayoutEventTriggerEditor';
import { IObservableArray, runInAction } from 'mobx';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import FunctionContext from '../../../../@Api/Automation/Function/FunctionContext';
import LayoutEventTriggers from '../../../../@Api/Layout/Event/LayoutEventTriggers';
import LayoutEventTrigger from '../../../../@Api/Layout/Event/LayoutEventTrigger';
import CompositeAction from '../../../../@Api/Layout/Action/Type/CompositeAction';

export interface LayoutEventTriggersEditorProps
{
    eventTriggers: LayoutEventTriggers;
    context: FunctionContext;
}

const LayoutEventTriggersEditor: React.FC<LayoutEventTriggersEditorProps> =
    props =>
    {
        const { eventTriggers, context } = props;

        const createTrigger =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            eventTriggers.triggers.push(
                                new LayoutEventTrigger(
                                    uuid(),
                                    'Click',
                                    new CompositeAction([])))),
                [
                    eventTriggers
                ]);

        const deleteTrigger =
            useCallback(
                (trigger: LayoutEventTrigger) =>
                    runInAction(
                        () =>
                            (eventTriggers.triggers as IObservableArray).remove(trigger)),
                [
                    eventTriggers
                ]);

        const dividerGlue = useDividerGlue();

        return <Card>
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={dividerGlue}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="vertical"
                        spacing={15}
                        glue={dividerGlue}
                    >
                        {
                            eventTriggers.triggers
                                .map(
                                    trigger =>
                                        <ViewGroupItem
                                            key={trigger.id}
                                        >
                                            <EventTriggerEditor
                                                trigger={trigger}
                                                onDelete={deleteTrigger}
                                                context={context}
                                            />
                                        </ViewGroupItem>)
                        }
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <HoverCardBottom
                        onClick={createTrigger}
                    >
                        + <LocalizedText code="Generic.Trigger" value="Trigger" />
                    </HoverCardBottom>
                </ViewGroupItem>
            </ViewGroup>
        </Card>;
    };

export default observer(LayoutEventTriggersEditor);
