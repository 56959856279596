import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import { EntityConstraintBuilderFilterContext } from '../Model/EntityConstraintBuilderFilterContext';
import { EntitySelectionBuilder } from '../../EntitySelectionBuilder';
import { EntityConstraintBuilderNodeAndField } from '../Model/EntityConstraintBuilderNodeAndField';
import getTypes from '../../../../Type/Api/getTypes';

export function getEntityNodeAndFieldByParameterAndFieldPath(
    parameter: Parameter<EntityValueType>,
    fieldPath: EntityFieldPath,
    context: EntityConstraintBuilderFilterContext,
    aliasGenerator?: (selectionBuilder: EntitySelectionBuilder) => string
): EntityConstraintBuilderNodeAndField
{
    if (context.parameter.id === parameter.id)
    {
        const selectionBuilder = context.builder.selectionBuilder;
        const path = fieldPath.path.rootedAt(selectionBuilder.entityType);
        const node =
            aliasGenerator
                ? selectionBuilder.joinAndGetByAlias(path, aliasGenerator(selectionBuilder))
                : selectionBuilder.joinAndGet(path);

        return {
            node,
            field: fieldPath.field ?? getTypes().Entity.Field.Id
        };
    }
    else if (context.parent)
    {
        return getEntityNodeAndFieldByParameterAndFieldPath(
            parameter,
            fieldPath,
            context.parent,
            aliasGenerator
        );
    }
    else
    {
        throw new Error(`Parameter with ID: ${parameter.id} not found, found parameter with ID: ${context.parameter.id}`);
    }
}
