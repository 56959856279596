export enum Method
{
    Get,
    Post,
    Put,
    Delete
}

export type ResultType = 'Json' | 'Blob';

// T is used as result type from request()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class ApiRequest<T>
{
    // ------------------ Members -----------------------------

    resource: string;
    method: Method;
    data: any;
    headers: any;
    isRaw: boolean;
    contentType: string;
    isCached: boolean;
    isForeignResource: boolean;
    isMultipart: boolean = false;
    resultType: ResultType;

    // -------------- Constructor -----------------------------

    constructor(resource: string,
                method: Method,
                data: any = {},
                headers: any = {},
                isRaw: boolean = false,
                contentType: string = 'application/x-www-form-urlencoded;charset=UTF-8;',
                isCached: boolean = false,
                isForeignResource: boolean = false,
                isMultipart: boolean = false,
                resultType: ResultType = 'Json')
    {
        this.resource = resource;
        this.method = method;
        this.data = data;
        this.headers = headers;
        this.isRaw = isRaw;
        this.contentType = contentType;
        this.isCached = isCached;
        this.isForeignResource = isForeignResource;
        this.isMultipart = isMultipart;
        this.resultType = resultType;
    }

    /***********************************
     **         Business logic        **
     ***********************************/

    toString()
    {
        return `${Method[this.method].toUpperCase()}:${this.resource}`;
    }

    // -------------- Getters & setters ------------------

}
