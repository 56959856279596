import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { TableLayoutDimensionSectionEditorProps } from './TableLayoutDimensionSectionEditor';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationEditor from '../../../../../Entity/Viewer/Content/Automation/Editor/Computation/ComputationEditor';
import PrimitiveValueType from '../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import FunctionContext from '../../../../../../../@Api/Automation/Function/FunctionContext';

export interface TableLayoutDimensionSectionOnClickEditorProps extends TableLayoutDimensionSectionEditorProps
{

}

const TableLayoutDimensionSectionOnClickEditor: React.FC<TableLayoutDimensionSectionOnClickEditorProps> =
    ({
        section,
        parameterDictionary,
        parameterAssignment,
        commitContext,
     }) =>
    {
        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        ParameterDictionary.union(
                            parameterDictionary,
                            new ParameterDictionary(section.getParameters())),
                        parameterAssignment ?? new ParameterAssignment(),
                        commitContext
                    ),
                [
                    parameterDictionary,
                    section,
                    parameterAssignment,
                    commitContext,
                ]);
        const setSize =
            useCallback(
                (size: Computation<any, any>) =>
                    runInAction(
                        () =>
                            section.size = size),
                [
                    section
                ]);
        const numberType =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        DataObject.getTypeById('Number')),
                []);

        return <Input
            label={
                <LocalizedText
                    code="Generic.Size"
                    value="Grootte"
                />
            }
        >
            <ComputationEditor
                context={context}
                type={numberType}
                value={section.size}
                onChange={setSize}
            />
        </Input>;
    };

export default observer(TableLayoutDimensionSectionOnClickEditor);
