import React, { useContext } from 'react';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import EntityCacheContext from '../../../../Service/Entity/EntityCacheContext';

export interface EntityCacheInformationProps
{
    entity: Entity;
}

const EntityCacheInformation: React.FC<EntityCacheInformationProps> =
    ({
        entity
     }) =>
    {
        const cacheService = useContext(EntityCacheContext);

        return <span>
            <ol>
                {
                    Array.from(cacheService.cacheReferrerService.cacheInformationByEntityUuid.get(entity.uuid)!.referrers)
                        .map(
                            referrer =>
                                <li
                                    key={referrer}
                                >
                                    {referrer}
                                </li>)
                }
            </ol>
        </span>;
    };

export default observer(EntityCacheInformation);
