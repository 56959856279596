import { useComputed } from 'mobx-react-lite';
import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import useResultPredicate from './useResultPredicate';
import { useContext } from 'react';
import FilterContext from '../FilterContext/FilterContext';
import Segment from '../../Model/Segment';
import combinePredicates from './combinePredicates';
import Dataset from '../../Model/Dataset';

export default function useResultFilter(
    dataset: Dataset,
    segment: Segment,
    result?: EntitySelectionAggregateResult
)
{
    const parentFilter = useContext(FilterContext);
    const resultPredicate =
        useResultPredicate(
            dataset.parameter,
            segment,
            result
        );

    return useComputed(
        () =>
        {
            return combinePredicates(
                parentFilter,
                resultPredicate);
        },
        [
            resultPredicate,
            parentFilter
        ]);
}
