import DynamicFunctionRepository from '../DynamicFunctionRepository';
import ServerFunction from './ServerFunction';
import performAction from '../../../../../Entity/performAction';
import { FunctionType } from '../../../FunctionType';
import AutomationDependencyContext from '../../../../AutomationDependencyContext';
import localizeText from '../../../../../Localization/localizeText';

export default class ServerFunctionRepository extends DynamicFunctionRepository<ServerFunction<any, any>>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor()
    {
        super('Server');
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText(
            'ServerActions',
            'Server acties'
        );
    }

    async getFunctionById(id: string): Promise<ServerFunction<any, any>>
    {
        const actionResult = await performAction(undefined, { code: 'ServerFunction.GetById', parameters: { id: id }});

        if (actionResult.result)
        {
            return await ServerFunction.fromDescriptor(actionResult.result, AutomationDependencyContext.GetEmptyContext());
        }
        else
        {
            return undefined;
        }
    }

    async listFunctions(type: FunctionType): Promise<ServerFunction<any, any>[]>
    {
        const actionsResult =
            await performAction(
                undefined,
                {
                    code: 'ServerFunction.List',
                    parameters:
                    {
                        type: type
                    }
                });

        if (actionsResult.result)
        {
            const dependencyContext = AutomationDependencyContext.GetEmptyContext();

            return await Promise.all(
                actionsResult.result
                    .map(
                        result =>
                            ServerFunction.fromDescriptor(result, dependencyContext)));
        }
        else
        {
            return [];
        }
    }

    // ----------------------- Private logic ------------------------
}
