import { createContext } from 'react';
import { ObservableMap } from 'mobx';
import { FormFieldValue } from '../Model/FormFieldValue';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import Form from '../Model/Form';

export interface FormContext
{
    entityType?: EntityType;
    form?: Form;
    valueByFieldId: ObservableMap<string, FormFieldValue>;
    onSubmit: () => Promise<boolean>;
}

export default createContext<FormContext>(undefined);
