import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import useTypes from '../../../../Type/Api/useTypes';
import InputGroup from '../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import { MoreProps } from '../More';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import AttachmentList from '../../../../AttachmentList/AttachmentList';
import styles from './ActivitySigningMore.module.scss';
import localize from '../../../../../../../@Api/Localization/localize';
import { SignersTable } from '../../../../Constructor/Type/Activity/DigitalSigning/SignersTable/SignersTable';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import performAction from '../../../../../../../@Api/Entity/performAction';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import { useExpansion } from '../../../../Selection/Api/useExpansion';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import { createNumberComparator } from '../../../../../../Generic/List/V2/Comparator/NumberComparator';
import { Switch } from '@material-ui/core';

export interface ActivitySigningMoreProps extends MoreProps
{

}

const ActivitySigningMore: React.FC<ActivitySigningMoreProps> =
    props =>
    {
        const types = useTypes();
        const feedbackStore = loadModuleDirectly(FeedbackStore);

        const [ isLoading ] =
            useExpansion(
                props.entity.entityType,
                rootPath => [
                    rootPath
                        .joinTo(
                            types.Activity.DigitalSigning.RelationshipDefinition.Signers,
                            false
                        )
                        .joinTo(
                            types.DigitalSigner.RelationshipDefinition.Relationship,
                            false
                        ),
                    rootPath
                        .joinTo(
                            types.Activity.DigitalSigning.RelationshipDefinition.Signers,
                            false
                        )
                        .joinTo(
                            types.DigitalSigner.RelationshipDefinition.Phase,
                            false
                        ),
                    rootPath
                        .joinTo(
                            types.Activity.DigitalSigning.RelationshipDefinition.Phase,
                            false
                        ),
                    rootPath
                        .joinTo(
                            types.Entity.RelationshipDefinition.Attachments,
                            false
                        ),
                    rootPath
                        .joinTo(
                            types.Activity.DigitalSigning.RelationshipDefinition.DocumentToSign,
                            false
                        ),
                    rootPath
                        .joinTo(
                            types.Entity.RelationshipDefinition.ExternalIds,
                            false
                        )
                    ],
                () => [
                    props.entity
                ],
                [
                    props.entity
                ]
            );

        const status =
            useMemo(
                () =>
                    isLoading
                        ? undefined
                        : props.entity.getRelatedEntityByDefinition(
                            false,
                            types.Activity.DigitalSigning.RelationshipDefinition.Phase
                        ),
                [
                    types,
                    isLoading,
                    props.entity
                ]
            );

        const isInProgress =
            useMemo(
                () =>
                    status?.getObjectValueByField(types.Datastore.Field.Code) === 'InProgress',
                [
                    status,
                    types
                ]);

        const [ isCancelling, setIsCancelling ] = useState(false)

        const cancelSigning =
            useCallback(
                () => {

                    setIsCancelling(true);

                    performAction(props.entity, {
                        code: 'ActivityDigitalSigning.Cancel'
                    })
                        .then(
                            () =>
                            {
                                feedbackStore.enqueueSnackbar(
                                    localizeText("ActivitySigningCancelSucceeded", "Ondertekenactiviteit is geanuleerd"),
                                    { variant: "success" }
                                )
                            }
                        )
                        .catch(
                            () =>
                            {
                                feedbackStore.enqueueSnackbar(
                                    localizeText(
                                        "ActivitySigningCancelFailed",
                                        "Afbreken ondertekenactiviteit is niet gelukt"),
                                    {variant: "error"}
                                )
                            }
                        )
                        .finally(
                            () => setIsCancelling(false)
                        );
                },
                [
                    feedbackStore,
                    props.entity
                ]
            );

        const signers =
            useMemo(
                () =>
                    isLoading
                        ? []
                        : props.entity.getRelatedEntitiesByDefinition(
                            false,
                            types.Activity.DigitalSigning.RelationshipDefinition.Signers
                          )
                        .sort(
                            createNumberComparator(
                                signer =>
                                    signer.getObjectValueByField(
                                        types.DigitalSigner.Field.SignOrderIndex
                                    )
                            )
                        ),
                [
                    types,
                    isLoading,
                    props.entity
                ]
            );

        const validsignPackageId =
            useMemo(
                () =>
                    isLoading
                        ? undefined
                        : props.entity.getRelatedEntitiesByDefinition(
                            false,
                            types.Entity.RelationshipDefinition.ExternalIds
                        ).find(
                            externalId =>
                            {
                                return externalId.getObjectValueByField(types.ExternalId.Field.Service) === "ValidsignService"
                                    && externalId.getObjectValueByField(types.ExternalId.Field.Type) === "PackageId"
                            }
                    ),
                [
                    types,
                    props.entity,
                    isLoading
                ]
            )

        const attachments =
            useMemo(
                () =>
                    isLoading
                        ? []
                        : [
                            props.entity.getRelatedEntityByDefinition(
                                false,
                                types.Activity.DigitalSigning.RelationshipDefinition.DocumentToSign
                            ),
                            ...props.entity.getRelatedEntitiesByDefinition(
                                false,
                                types.Entity.RelationshipDefinition.Attachments
                            )
                          ].filter(doc => doc !== undefined),
                [
                    types,
                    isLoading,
                    props.entity
                ]
            );

        if (isLoading)
        {
            return <Centered
                horizontal
            >
                <Loader />
            </Centered>
        }
        else
        {
            return <div
                className={classNames(styles.root, props.disableInset && styles.disableInset)}
            >
                <Card
                    inset
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={15}
                    >
                        <ViewGroupItem>
                            <InputGroup>
                                <Input
                                    label={
                                        types.Activity.Field.Subject.getName()
                                    }
                                    labelPosition="left"
                                >
                                    {props.entity.name}
                                </Input>
                                <Input
                                    label={
                                        <LocalizedText
                                            code="ValidSign.PackageId"
                                            value="ValidSign packageId"
                                        />
                                    }
                                    labelPosition="left"
                                >
                                    {
                                        validsignPackageId?.getObjectValueByField(
                                            types.ExternalId.Field.Id
                                        ) || ""
                                    }
                                </Input>
                                <Input
                                    label={
                                        types.Activity.DigitalSigning.RelationshipDefinition.Phase.getName(false)
                                    }
                                    labelPosition="left"
                                >
                                    {localize(status?.getObjectValueByField(types.Datastore.Field.LocalizedName))}
                                </Input>
                                <Input
                                    label={
                                        types.Activity.DigitalSigning.Field.IsFixedSigningOrder.getName()
                                    }
                                    labelPosition="left"
                                >
                                    <Switch
                                        checked={props.entity.getObjectValueByField(types.Activity.DigitalSigning.Field.IsFixedSigningOrder)}
                                        disabled
                                    />
                                </Input>
                                {
                                    attachments.length > 0 &&
                                    <Input
                                        label={
                                            types.Entity.RelationshipDefinition.Attachments.getName(false)
                                        }
                                        labelPosition="left"
                                    >
                                        <AttachmentList
                                            attachments={attachments}
                                            readOnly
                                        />
                                    </Input>
                                }
                                {
                                    signers.length > 0 &&
                                        <SignersTable
                                            entity={props.entity}
                                            readOnly
                                        />
                                }
                            </InputGroup>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <RightAlignedButtonGroup>
                                <PrimaryButton
                                    label={
                                        <LocalizedText
                                            code="ActivitySignCancel"
                                            value="Afbreken"
                                        />
                                    }
                                    onClick={cancelSigning}
                                    disabled={!isInProgress || isCancelling}
                                    loading={isCancelling}
                                />
                            </RightAlignedButtonGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </Card>
            </div>;
        }
    };

export default observer(ActivitySigningMore);
