import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import getTypes from '../../../../Type/Api/getTypes';
import FormLayout from '../../../../../../../@Api/Layout/Type/Form/FormLayout';
import LayoutDependencyContext from '../../../../../../../@Api/Layout/LayoutDependencyContext';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';

export default async function getPortalUserSettingsParameters(portal: Entity,
                                                              runtimeParameters: ParameterDictionary)
{
    const types = getTypes();
    const userSettingsFormDescriptor = portal.getObjectValueByField(types.Portal.Field.UserSettingsForm);

    if (userSettingsFormDescriptor)
    {
        const formLayout =
            await FormLayout.fromDescriptor(
                userSettingsFormDescriptor,
                new LayoutDependencyContext(runtimeParameters));

        return formLayout.parameters;
    }
    else
    {
        return new ParameterDictionary([]);
    }
}
