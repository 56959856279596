import React from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import Relationship from '../Relationship/Relationship';
import ViewerEntityContext from '../../Context/ViewerEntity/ViewerEntityContext';
import useFirstParentRelationship from '../../../../../../@Api/Entity/Bespoke/Relation/useFirstParentRelationship';
import { useInitializedEntityForViewer } from '../../Api/useInitializedEntityForViewer';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface RelationProps
{
    entity: Entity;
    commitContext: CommitContext;
}

const Relation: React.FC<RelationProps> =
    props =>
    {
        const relationship = useFirstParentRelationship(props.entity);

        useInitializedEntityForViewer(relationship);

        if (relationship)
        {
            return <ViewerEntityContext.Provider
                value={relationship}
            >
                <Relationship
                    entity={relationship}
                    commitContext={props.commitContext}
                    withRelation={props.entity}
                />
            </ViewerEntityContext.Provider>;
        }
        else
        {
            return null;
        }
    };

export default observer(Relation);
