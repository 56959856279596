import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import CampaignPercentageChart from './CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import useCampaignEmailStateCount from '../Hooks/useCampaignEmailStateCount';
import useTypes from '../../../../../Type/Api/useTypes';
import useCampaignUndeliverableCount from '../Hooks/useCampaignUndeliverableCount';

export interface CampaignChartBlockedProps
{
    campaign: Entity;
    size: number;
}

export const CampaignChartBlocked: React.FC<CampaignChartBlockedProps> =
    observer(
        ({
             campaign,
             size,
         }) =>
        {
            const types = useTypes();

            const undeliverableCount = useCampaignUndeliverableCount(campaign);
            const states =
                useMemo(
                    () =>
                        [
                            types.Activity.Email.State.Blocked
                        ],
                    [
                        types
                    ]
                );
            const blockedCount = useCampaignEmailStateCount(campaign,states);

            const allSet =
                useAllSet([
                    undeliverableCount,
                    blockedCount
                ]);

            const selection =
                useMemo(
                    () =>
                    {
                        return `${blockedCount} / ${undeliverableCount}`
                    },
                    [
                        blockedCount,
                        undeliverableCount
                    ]
                )

            return <div>
                {
                    allSet &&
                    <CampaignPercentageChart
                        value={blockedCount / undeliverableCount * 100}
                        size={size}
                        label={localizeText('Campaign.Chart.Blocked','Geblokkeerd')}
                        color={'#8f0012'}
                        selection={selection}
                    />
                }
            </div>;
        }
    );
