import React from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../../../../Type/Api/useTypes';
import Input from '../../../../../../../../../../Input/Input';
import Base from '../../Base/Base';
import { ContentProps } from '../../Content';

export interface EmailProps extends ContentProps
{

}

const Email: React.FC<EmailProps> =
    props =>
    {
        const types = useTypes();

        const fields =
            [
                <Input
                    key={0}
                    entity={props.entity}
                    field={types.Activity.Email.RelationshipDefinition.Phase}
                    labelPosition={props.labelPosition}
                    inline={props.inlineLabel}
                    disabled
                    commitContext={props.commitContext}
                />,
                props.entity.hasValueForField(types.Activity.Email.Field.SendDate) &&
                    <Input
                        key={1}
                        entity={props.entity}
                        field={types.Activity.Email.Field.SendDate}
                        labelPosition={props.labelPosition}
                        inline={props.inlineLabel}
                        disabled
                        commitContext={props.commitContext}
                    />
            ];

        return <Base
            {...props}
            expand
        >
            {fields}
        </Base>;
    };

export default observer(Email);
