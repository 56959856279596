import * as React from 'react';
import { DataObjectBespokeEditor } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { observer } from 'mobx-react';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { RichTextEditorStore } from './RichTextEditorStore';
import RichtextEditor from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/RichtextEditor';
import EditorButton from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorCommandButton';
import EditorFontSelector from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorFontSelector';
import EditorColorPicker from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorColorPicker';
import CollapsibleToolbar from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/CollapsibleToolbar';
import DefaultToolbarButtons from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/DefaultToolbarButtons';

@observer
export default class RichTextEditor extends DataObjectBespokeEditor<RichTextEditorStore>
{
    renderComponent(store: DataObjectEditorStore<RichTextEditorStore>): any
    {
        return <RichtextEditor
            value={store.dataObject.value}
            placeholder={store.placeholder}
            {...store.handlerProperties as any}
            onChange={store.changeValue}
            autoFocus={store.isFocused}
            showToolbarOnFocus
        >
            <CollapsibleToolbar>
                <DefaultToolbarButtons />

                <EditorButton icon="link" tooltip="Link" command="link"/>
                <EditorFontSelector />
                <EditorColorPicker tooltip="Selecteer kleur" />
            </CollapsibleToolbar>
        </RichtextEditor>;
    }
}
