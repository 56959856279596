import React, { useCallback, useContext, useState } from 'react';
import TypeMapping from '../../../Model/TypeMapping';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { IObservableArray, runInAction } from 'mobx';
import styles from './ExternalIdKey.module.scss';
import { observer, useComputed } from 'mobx-react-lite';
import KeyField from '../../../Model/KeyField';
import uuid from '../../../../../../../@Util/Id/uuid';
import isExternalIdKey from '../../../Api/isExternalIdKey';
import ValueFieldMapping from '../../../Model/FieldMapping/Constant/ValueFieldMapping';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import useTypes from '../../../../Type/Api/useTypes';
import FileContext from '../../../Context/FileContext';
import Switch from '../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import Key from '../../../Model/Key';
import InputGroup from '../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import Selectbox from '../../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import { UserDefinedImportKeyService } from '../../../Api/Constants';
import { Aggregate } from '../../../../../DataObject/Model/Aggregate';
import HoverCardMiddle from '../../../../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import Divider from '../../../../../../../@Future/Component/Generic/Divider/Divider';
import SourceFieldMapping from '../../../Model/FieldMapping/SourceField/SourceFieldMapping';
import SourceFieldSelector from './SourceFieldSelector';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface ExternalIdKeyProps
{
    typeMapping: TypeMapping;
    idx: number;
    entity: Entity;
    commitContext: CommitContext;
    externalIdKey?: Key;
    last?: boolean;
}

const ExternalIdKey: React.FC<ExternalIdKeyProps> =
    props =>
    {
        const types = useTypes();
        const fileValue = useContext(FileContext);

        const key =
            useComputed(
                () =>
                    props.externalIdKey,
                    // props.typeMapping
                    //     .keys
                    //     .find(
                    //         key =>
                    //             isExternalIdKey(key)),
                [
                    props.externalIdKey,
                    // props.typeMapping
                ]);

        const deleteKey =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (props.typeMapping.keys as IObservableArray).remove(key)),
                [
                    props.typeMapping,
                    key
                ]);

        const externalIdServiceFieldMapping =
            useComputed(
                () =>
                    (key?.fields || [])
                        .find(
                            keyField =>
                                keyField.fieldPath.field === types.ExternalId.Field.Service)?.mapping as ValueFieldMapping,
                [
                    key,
                    types
                ]);

        const externalIdTypeFieldMapping =
            useComputed(
                () =>
                    (key?.fields || [])
                        .find(
                            keyField =>
                                keyField.fieldPath.field === types.ExternalId.Field.Type)?.mapping as ValueFieldMapping,
                [
                    key,
                    types
                ]);

        const externalIdIdFieldMapping =
            useComputed(
                () =>
                    (key?.fields || [])
                        .find(
                            keyField =>
                                keyField.fieldPath.field === types.ExternalId.Field.Id)?.mapping as SourceFieldMapping,
                [
                    key,
                    types
                ]);

        const addExternalIdKey =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            const rootPath = EntityPath.fromEntityType(types.ExternalId.Type);

                            const pathToExternalId =
                                EntityPath.fromEntityType(props.typeMapping.entityType)
                                    .joinTo(
                                        types.Entity.RelationshipDefinition.ExternalIds,
                                        false);

                            props.typeMapping.keys.splice(
                                props.typeMapping.keys
                                    .filter(
                                        key =>
                                            isExternalIdKey(key))
                                    .length,
                                0,
                                new Key(
                                    uuid(),
                                    [
                                        new KeyField(
                                            uuid(),
                                            pathToExternalId.field(types.ExternalId.Field.Service),
                                            true,
                                            new ValueFieldMapping(
                                                uuid(),
                                                rootPath.field(types.ExternalId.Field.Service),
                                                DataObject.constructFromTypeIdAndValue('Text', UserDefinedImportKeyService))),
                                        new KeyField(
                                            uuid(),
                                            pathToExternalId.field(types.ExternalId.Field.Type),
                                            true,
                                            new ValueFieldMapping(
                                                uuid(),
                                                rootPath.field(types.ExternalId.Field.Type),
                                                DataObject.constructFromTypeIdAndValue('Text', undefined))),
                                        new KeyField(
                                            uuid(),
                                            pathToExternalId.field(types.ExternalId.Field.Id),
                                            true,
                                            new SourceFieldMapping(
                                                uuid(),
                                                rootPath.field(types.ExternalId.Field.Type),
                                                undefined))
                                    ]));
                        }),
                [
                    props.typeMapping,
                    types,
                    fileValue
                ]);

        const toggleExternalId =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            if (key)
                            {
                                deleteKey();
                            }
                            else
                            {
                                addExternalIdKey();
                            }
                        }),
                [
                    key,
                    deleteKey,
                    addExternalIdKey
                ]);

        const [ externalIdService, setExternalIdService ] =
            useState(
                () =>
                    externalIdServiceFieldMapping?.value.value);

        const loadServices =
            useCallback(
                () =>
                    new EntitySelectionBuilder(types.ExternalId.Type)
                        .aggregateOn(
                            EntityPath.fromEntityType(types.ExternalId.Type)
                                .field(types.Entity.Field.Id),
                            undefined,
                            Aggregate.Count)
                        .groupBy(
                            EntityPath.fromEntityType(types.ExternalId.Type)
                                .field(types.ExternalId.Field.Service))
                        .selectAggregates()
                        .then(
                            result =>
                                Promise.resolve(
                                    result.children
                                        .filter(
                                            result =>
                                                result.groupValue
                                                && !result.groupValue.isEmpty)
                                        .map(
                                            result =>
                                                result.groupValue.value))),
                [
                    types
                ]);

        const loadPreviousImportTypes =
            useCallback(
                () =>
                    new EntitySelectionBuilder(types.ExternalId.Type)
                        .where(
                            cb =>
                                cb.eq(
                                    EntityPath.fromEntityType(types.ExternalId.Type)
                                        .field(types.ExternalId.Field.Service),
                                    undefined,
                                    externalIdService || UserDefinedImportKeyService))
                        .aggregateOn(
                            EntityPath.fromEntityType(types.ExternalId.Type)
                                .field(types.Entity.Field.Id),
                            undefined,
                            Aggregate.Count)
                        .groupBy(
                            EntityPath.fromEntityType(types.ExternalId.Type)
                                .field(types.ExternalId.Field.Type))
                        .selectAggregates()
                        .then(
                            result =>
                                Promise.resolve(
                                    result.children
                                        .filter(
                                            result =>
                                                result.groupValue
                                                && !result.groupValue.isEmpty)
                                        .map(
                                            result =>
                                                result.groupValue.value))),
                [
                    types,
                    externalIdService
                ]);

        const optionFormatter =
            useCallback(
                d => d,
                []);

        if (fileValue)
        {
            return <>
                <CardInset>
                    <ViewGroup
                        orientation="vertical"
                        spacing={5}
                    >
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={5}
                                alignment="center"
                            >
                                {
                                    props.idx === 0 &&
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <LocalizedText
                                                code="Import.TypeMapping.SearchForEntityByID1"
                                                value="${index}. Zoek naar entiteit met een ID uit een eerdere import"
                                                index={props.idx + 1}
                                            />
                                        </ViewGroupItem>
                                }
                                {
                                    props.idx > 0 &&
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <LocalizedText
                                                code="Import.TypeMapping.SearchForEntityByID2"
                                                value="${index}. óf zoek naar entiteit met een ID uit een eerdere import"
                                                index={props.idx + 1}
                                            />
                                        </ViewGroupItem>
                                }
                                <ViewGroupItem>
                                    <Switch
                                        onToggle={toggleExternalId}
                                        checked={key !== undefined}
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                        {
                            key &&
                                <ViewGroupItem>
                                    <div
                                        className={styles.fieldContainer}
                                    >
                                        <InputGroup>
                                            <Input
                                                labelPosition="left"
                                                label={
                                                    <LocalizedText
                                                        code="Import.TypeMapping.KeyCollection"
                                                        value="Sleutelcollectie"
                                                    />
                                                }
                                            >
                                                <Selectbox
                                                    value={externalIdService}
                                                    onChange={
                                                        value => {
                                                            setExternalIdService(value);
                                                            externalIdServiceFieldMapping.value.setValue(value);
                                                        }}
                                                    load={loadServices}
                                                    formatOption={optionFormatter}
                                                    idResolver={optionFormatter}
                                                />
                                            </Input>
                                            <Input
                                                labelPosition="left"
                                                label={
                                                    <LocalizedText
                                                        code="Import.TypeMapping.KeyFromPreviousImport"
                                                        value="Sleutel uit eerdere import"
                                                    />
                                                }
                                            >
                                                <Selectbox
                                                    value={externalIdTypeFieldMapping?.value.value}
                                                    onChange={value => externalIdTypeFieldMapping.value.setValue(value)}
                                                    load={loadPreviousImportTypes}
                                                    formatOption={optionFormatter}
                                                    idResolver={optionFormatter}
                                                />
                                            </Input>
                                            <Input
                                                labelPosition="left"
                                                label={
                                                    <LocalizedText
                                                        code="Import.TypeMapping.SourceFieldID"
                                                        value="Bronveld te gebruiken als ID"
                                                    />
                                                }
                                            >
                                                <SourceFieldSelector
                                                    sourceFieldId={externalIdIdFieldMapping?.sourceFieldId}
                                                    onChange={sourceFieldId => runInAction(() => externalIdIdFieldMapping.sourceFieldId = sourceFieldId)}
                                                />
                                            </Input>
                                        </InputGroup>
                                    </div>
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                </CardInset>
                {
                    props.last && key &&
                        <>
                            <Divider />
                            <HoverCardMiddle
                                onClick={addExternalIdKey}
                            >
                                <LocalizedText
                                    code="Import.TypeMapping.SearchForAnotherIDFromPreviousImport"
                                    value="Zoek op nog een ID van een entiteit uit een eerdere import"
                                />
                            </HoverCardMiddle>
                        </>
                }
            </>;
        }
        else
        {
            return null;
        }
    };

export default observer(ExternalIdKey);
