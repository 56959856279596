import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../Value/PrimitiveValue';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import FunctionContext from '../FunctionContext';
import EntityValue from '../../Value/EntityValue';
import getTypes from '../../../../@Component/Domain/Entity/Type/Api/getTypes';
import getValueFromDescriptor from '../../Api/getValueFromDescriptor';

export default class FileAttachmentComputation extends Computation<PrimitiveValueType, PrimitiveValue>
{
    // ------------------------- Properties -------------------------

    @observable.ref attachment: EntityValue;

    // ------------------------ Constructor -------------------------

    constructor(attachment: EntityValue)
    {
        super();

        this.attachment = attachment;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('File'));
    }

    isAsync(): boolean
    {
        return true;
    }

    async apply(context: FunctionContext): Promise<PrimitiveValue>
    {
        // For now... This will change when we finish the implementation
        return this.synchronousApply(context);
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        return new PrimitiveValue(this.attachment.value.getDataObjectValueByField(getTypes().Attachment.Field.File));
    }

    getName(): string
    {
        return 'Bestand...';
    }

    validate(): Validation[]
    {
        if (!this.attachment)
        {
            return [
                new Validation(
                    'Error',
                    'Géén bestand geselecteerd.')
            ];
        }

        return [];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'FileAttachment';
        descriptor.attachment = this.attachment.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new FileAttachmentComputation(
            await getValueFromDescriptor(
                descriptor.attachment,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
