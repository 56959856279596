import { observable } from 'mobx';
import { Computation } from '../Computation';
import { ExpressionSpecification } from '../../../Expression/Model/ExpressionSpecification';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import ComputationInText from '../../../../../@Api/Automation/Function/Computation/ComputationInText';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import { ComputationTypeStore } from '../../ComputationTypeStore';
import TextComputation from '../../../../../@Api/Automation/Function/Computation/TextComputation';

export class ExpressionComputation extends Computation
{
    // ------------------------- Properties -------------------------

    @observable.ref expression: ExpressionSpecification;

    // ------------------------ Constructor -------------------------

    constructor(expression: ExpressionSpecification)
    {
        super('Expression');

        this.expression = expression;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------


    async initialize(): Promise<any>
    {
        return Promise.resolve();
    }

    async toNewComputation(context: AutomationDependencyContext)
    {
        const computationTypeStore = loadModuleDirectly(ComputationTypeStore);
        const computations =
            await Promise.all(
                this.expression.computations
                    .map(
                        async computation =>
                            new ComputationInText(
                                computation.id,
                                await computationTypeStore
                                    .fromSpecification(computation.computation)
                                    .toNewComputation(context)
                            )
                    )
            );

        return new TextComputation(
            this.expression.expression,
            false,
            computations
        );
    }

    // ----------------------- Private logic ------------------------
}
