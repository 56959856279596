import { ApiRequest, Method } from './Model/ApiRequest';
import { ApiResponse } from './Model/ApiResponse';
import { ApiInterceptor } from './Model/ApiInterceptor';
import assign from 'lodash/assign';
import * as qs from 'qs';
import URI from 'urijs';
import isUserError from '../../@Api/Error/isUserError';
import captureException from '../../@Util/Exception/captureException';
import { ErrorDataKey } from '../../@Api/Error/getErrorData';

export const unauthorizedErrorCode = 'unauthorized';

export type ApiCallback = (request: ApiRequest<any>,
                           response: ApiResponse<any>) => void;

export class ApiClient
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    endpoint: string;
    interceptors: ApiInterceptor[];
    defaultHeaders: { [key: string]: string; };
    defaultParameters: { [key: string]: string; };
    apiUnavailableCallback: ApiCallback;
    noAuthenticationCallback: ApiCallback;
    apiErrorCallback: ApiCallback;
    apiSuccessCallback: ApiCallback;

    // ------------------------ Constructor -------------------------

    constructor(endpoint: string,
                interceptors: ApiInterceptor[],
                defaultHeaders: { [key: string]: string; },
                defaultParameters: { [key: string]: string; },
                apiUnavailableCallback: ApiCallback,
                noAuthenticationCallback: ApiCallback,
                apiErrorCallback: ApiCallback,
                apiSuccessCallback: ApiCallback)
    {
        if (endpoint.startsWith('http'))
        {
            this.endpoint = endpoint;
        }
        else
        {
            this.endpoint = `${window.location.protocol}//${window.location.hostname}/${endpoint}`;
        }

        this.interceptors = interceptors;
        this.defaultHeaders = defaultHeaders;
        this.defaultParameters = defaultParameters;
        this.apiUnavailableCallback = apiUnavailableCallback;
        this.noAuthenticationCallback = noAuthenticationCallback;
        this.apiErrorCallback = apiErrorCallback;
        this.apiSuccessCallback = apiSuccessCallback;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    /**
     * Constructs an url.
     *
     * @param {string} resource
     * @param {{string: any}} data
     * @param {boolean} excludeDefaultParameters
     * @param {boolean} isForeignResource
     */
    url(resource: string,
        data: any = {},
        excludeDefaultParameters: boolean = false,
        isForeignResource: boolean = false): string
    {
        // Handle default parameters
        if (!excludeDefaultParameters)
        {
            data = assign({}, this.defaultParameters, data);
        }

        // Handle interceptors
        for (let interceptor of this.interceptors)
        {
            interceptor.onUrl(resource, data);
        }

        // Sanitize parameters
        this.sanitizeParameters(data);

        // Build query string
        let uri = URI(isForeignResource ? resource : this.endpoint);

        if (!isForeignResource)
        {
            if (!resource.startsWith('/'))
            {
                resource = '/' + resource;
            }

            uri = uri.resource(this.endpoint + resource);
        }

        uri = uri.addQuery(data);

        return uri.toString();
    }

    sanitizeParameters(parameters: any)
    {
        // Stringify complex parameters
        for (let key in parameters)
        {
            if (parameters.hasOwnProperty(key))
            {
                let value = parameters[key];

                if (value && this.isComplex(value))
                {
                    parameters[key] = JSON.stringify(value);
                }
            }
        }
    }

    isComplex(object: any): boolean
    {
        return !(object !== Object(object)) || object instanceof Array;
    }

    request<T>(request: ApiRequest<T>): Promise<T>
    {
        // Do not include default parameters if the resource is foreign (for instance, we want to protect the access token)
        let includeDefaultParameters = !request.isForeignResource;
        let parameters: any = {};

        if (!request.isRaw)
        {
            parameters = assign(
                {},
                includeDefaultParameters ? this.defaultParameters : {},
                request.data);

            this.sanitizeParameters(parameters);

            Object.getOwnPropertyNames(parameters)
                .forEach(
                    key =>
                    {
                        if (parameters[key] == null)
                        {
                            delete parameters[key];
                        }
                    });
        }

        const url =
            this.url(
                request.resource,
                request.method === Method.Get ? parameters : {},
                !includeDefaultParameters,
                request.isForeignResource);

        let headers;

        if (request.isForeignResource)
        {
            headers = assign(request.headers);
        }
        else
        {
            headers = assign(this.defaultHeaders, request.headers);
        }

        if (!request.isMultipart)
        {
            headers['Content-Type'] = request.contentType ? request.contentType : 'application/x-www-form-urlencoded';
        }
        else
        {
            delete headers['Content-Type'];
            headers['enctype'] = 'multipart/form-data';
        }

        const fetchOptions: RequestInit =
            {
                method: Method[request.method].toLowerCase(),
                body: request.isRaw || request.isMultipart ? request.data : (request.method === Method.Get ? undefined : qs.stringify(parameters)),
                headers: headers,
                credentials: request.isForeignResource ? undefined : 'include'
            };

        return this.performFetch(
            request,
            url,
            fetchOptions);
    }

    performFetch<T>(request: ApiRequest<T>,
                    url: string,
                    options: RequestInit): Promise<T>
    {
        return fetch(url, options)
            .catch(
                error =>
                {
                    const apiResponse =
                        new ApiResponse(
                            false,
                            error,
                            error
                        );

                    if (this.apiUnavailableCallback)
                    {
                        this.apiUnavailableCallback(request, apiResponse);
                    }

                    throw error;
                })
            .then(
                response =>
                {
                    return this.getData(request, response)
                        .then(
                            resultData =>
                            {
                                if (response.status === 200)
                                {
                                    let data: T = null;

                                    if (request.resultType !== 'Json'
                                        || request.isRaw
                                        || request.isForeignResource)
                                    {
                                        data = resultData;

                                        return Promise.resolve(data);
                                    }
                                    else
                                    {
                                        if (resultData.ok)
                                        {
                                            data = resultData.data;

                                            return Promise.resolve(data);
                                        }
                                        else
                                        {
                                            const error = new Error(`HTTP response error to ${request.toString()}: ${resultData.detail || resultData.message || resultData.error}`);
                                            error[ErrorDataKey] = resultData;

                                            return Promise.reject(error);
                                        }
                                    }
                                }
                                else
                                {
                                    const apiResponse =
                                        new ApiResponse(
                                            false,
                                            response,
                                            resultData,
                                            response.status
                                        );

                                    if (response.status === 401)
                                    {
                                        if (this.noAuthenticationCallback)
                                        {
                                            this.noAuthenticationCallback(request, apiResponse);
                                        }

                                        apiResponse.error = unauthorizedErrorCode;

                                        throw new Error('API: unauthorized');
                                    }
                                    else if (response.status === 500)
                                    {
                                        if (this.apiErrorCallback)
                                        {
                                            this.apiErrorCallback(request, apiResponse);
                                        }

                                        throw new Error('API: server error');
                                    }
                                    else if (response.status === 400)
                                    {
                                        // This happens in case of invalid token

                                        throw new Error('API: bad request');
                                    }
                                    else
                                    {
                                        if (this.apiUnavailableCallback)
                                        {
                                            this.apiUnavailableCallback(request, apiResponse);
                                        }

                                        throw new Error('API: unreachable');
                                    }

                                    // return Promise.reject(apiResponse);
                                }
                            })
                        .catch(
                            error =>
                            {
                                if (!isUserError(error))
                                {
                                    captureException(error);
                                }

                                return Promise.reject(error);
                            });
                });
    }

    private getData(request: ApiRequest<any>,
                    response: Response): Promise<any>
    {
        switch (request.resultType)
        {
            case 'Blob':
                return response.blob();

            default:
            case 'Json':
                return response.json();
        }
    }

    setDefaultHeader(header: string, value: string)
    {
        if (value == null)
        {
            delete this.defaultHeaders[header];
        }
        else
        {
            this.defaultHeaders[header] = value;
        }
    }

    setDefaultParameter(parameter: string, value: string)
    {
        if (value == null)
        {
            delete this.defaultParameters[parameter];
        }
        else
        {
            this.defaultParameters[parameter] = value;
        }
    }

    // ----------------------- Private logic ------------------------
}
