import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Tooltip from '../../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';

export interface SourceValueLabelProps
{
    name?: string;
    value?: any;
}

const SourceValueLabel: React.FC<SourceValueLabelProps> =
    props =>
    {
        const label =
            useMemo(
                () =>
                {
                    const valueLabel = props.value === undefined ? localizeText('Import.FieldMapping.NoValue', 'Géén waarde') : props.value.toString();

                    if (props.name)
                    {
                        return <Tooltip
                            title={valueLabel}
                        >
                            {props.name}
                        </Tooltip>;
                    }
                    else
                    {
                        return valueLabel
                    }
                },
                [
                    props.value,
                    props.name
                ]);

        return <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {label}</span>;
    };

export default observer(SourceValueLabel);
