import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import CompositeLayout from '../../../../@Api/Layout/Type/CompositeLayout';
import ParameterDictionary from '../../../../@Api/Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../../../@Api/Automation/Parameter/ParameterAssignment';
import EntityValue from '../../../../@Api/Automation/Value/EntityValue';
import Layout from '../../../../@Api/Layout/Layout';
import LayoutDependencyContext from '../../../../@Api/Layout/LayoutDependencyContext';
import uuid from '../../../../@Util/Id/uuid';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import { getEntityLayoutParameter } from './Api/getEntityLayoutParameter';
import { ResettableLayoutEditor } from '../../Layout/ResettableEditor/ResettableLayoutEditor';
import { getAndInitializeLayoutFromDescriptor } from '../../../../@Api/Layout/Api/getAndInitializeLayoutFromDescriptor';
import { useInitializedParameterAssignment } from '../../../../@Api/Automation/Api/useInitializedParameterAssignment';

export interface EntityLayoutProps
{
    entity: Entity;
    isInEditMode: boolean;
    onCloseEditMode: () => void;
    layout?: any;
    onChangeLayout: (layout?: any) => void;
    commitContext?: CommitContext;
}

const EntityLayout: React.FC<EntityLayoutProps> =
    props =>
    {
        const [ reloadKey, setReloadKey ] = useState(() => uuid());
        const layoutDescriptor = props.layout;
        const parameter =
            useMemo(
                () =>
                    getEntityLayoutParameter(props.entity.entityType),
                [
                    props.entity
                ]);
        const parameterDictionary =
            useMemo(
                () =>
                    new ParameterDictionary([
                        parameter
                    ]),
                [
                    parameter
                ]);
        const parameterAssignment =
            useMemo(
                () =>
                    new ParameterAssignment()
                        .setValue(
                            parameterDictionary.getParameterById('Entity'),
                            new EntityValue(props.entity)),
                [
                    parameterDictionary,
                    props.entity
                ]);
        const [ layout, setLayout ] = useState<Layout>();
        const saveLayout =
            useCallback(
                (layout: Layout) =>
                {
                    setLayout(layout);
                    props.onChangeLayout(layout?.toDescriptor());
                },
                [
                    props.onChangeLayout
                ]
            );
        const close =
            useCallback(
                () =>
                {
                    setReloadKey(uuid());

                    props.onCloseEditMode();
                },
                [
                    setReloadKey,
                    props.onCloseEditMode
                ]);

        useEffect(
            () =>
            {
                if (layoutDescriptor)
                {
                    getAndInitializeLayoutFromDescriptor(
                        layoutDescriptor,
                        new LayoutDependencyContext(parameterDictionary)
                    )
                        .then(
                            layout =>
                                setLayout(layout)
                        )
                        .catch(
                            reason =>
                            {
                                console.error(reason);

                                setLayout(
                                    new CompositeLayout(
                                        'Vertical',
                                        [],
                                        0,
                                        false));
                            });
                }
                else
                {
                    setLayout(
                        new CompositeLayout(
                            'Vertical',
                            [],
                            0,
                            false));
                }
            },
            [
                layoutDescriptor,
                parameter,
                props.entity,
                setLayout,
                parameterDictionary,
                reloadKey
            ]);
        const layoutDependencies =
            useMemo(
                () =>
                    layout?.getDependencies() ?? [],
                [layout]
            );
        const [ initializedParameterAssignment, isInitializingParameterAssignment ] =
            useInitializedParameterAssignment(
                parameterAssignment,
                layoutDependencies
            );

        return <ResettableLayoutEditor
            parameterDictionary={parameterDictionary}
            parameterAssignment={initializedParameterAssignment}
            isInEditMode={props.isInEditMode}
            onCloseEditMode={close}
            onChangeLayout={saveLayout}
            layout={
                isInitializingParameterAssignment
                    ? undefined
                    : layout
            }
            commitContext={props.commitContext}
        />;
    };

export default observer(EntityLayout);
