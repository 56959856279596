import React, { useCallback, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import { EntityContext } from '../../../../Entity/@Model/EntityContext';
import styles from './ResourcePlannersEditor.module.scss';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import { IObservableArray, runInAction } from 'mobx';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import FieldPathSelector from '../../../../Entity/Path/FieldPathEditor/FieldPathSelector';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import { Grid } from '@material-ui/core';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import useTransactionalEntity from '../../../../../../@Api/Entity/Bespoke/useTransactionalEntity';
import RightAlignedButtonGroup from '../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import isMutable from '../../../../../../@Api/RightProfile/isMutable';
import { default as EntityMenuButton } from '../../../../Entity/Item/MenuButton/MenuButton';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import { default as EntityInput } from '../../../../Entity/Input/Input';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import ResourcePlannerSelector from './ResourcePlannerSelector/ResourcePlannerSelector';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import { default as ResourcePlannerModel } from '../../../../Entity/ResourcePlanner/Model/ResourcePlanner';
import ResourceSelection from '../../../../Entity/ResourcePlanner/Model/ResourceSelection';
import ResourcePlanner from '../../../../Entity/ResourcePlanner/ResourcePlanner';
import SaveButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import DeleteButton from '../../../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';
import deleteEntity from '../../../../../../@Api/Entity/deleteEntity';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import ResourceSelectionConstructor from './ResourceSelectionConstructor/ResourceSelectionConstructor';
import { EntityPath } from '../../../../Entity/Path/@Model/EntityPath';
import DeleteIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import FilterEditor from './FilterEditor/FilterEditor';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import ErrorBoundary from '../../../../../Error/ErrorBoundary';
import ParametersEditor from '../../../../Entity/Viewer/Content/Automation/Editor/Parameters/ParametersEditor';

export interface ResourcePlannersEditorProps
{
    entityType: EntityType;
}

const ResourcePlannersEditor: React.FC<ResourcePlannersEditorProps> =
    props =>
    {
        const types = useTypes();

        const [ entity, setEntity ] = useState<Entity>();
        const transactionalEntity = useTransactionalEntity(entity);

        const [ resourcePlanner ] =
            useAsyncResult(
                () =>
                {
                    if (entity)
                    {
                        return ResourcePlannerModel.fromDescriptor(
                            entity.getObjectValueByField(types.ResourcePlanner.Field.Specification));
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    entity
                ]);

        const setResourceFilter =
            useCallback(
                (filter: Predicate) =>
                    runInAction(
                        () =>
                            resourcePlanner.resourceFilter = filter),
                [
                    resourcePlanner
                ]);

        const [ constructingResourceSelectionPath, setConstructingResourceSelectionPath ] = useState<EntityPath>();
        const setConstructingResourceSelectionFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    setConstructingResourceSelectionPath(fieldPath.path),
                [
                    setConstructingResourceSelectionPath
                ]);

        const addResourceSelection =
            useCallback(
                (resourceSelection: ResourceSelection) =>
                    runInAction(
                        () =>
                        {
                            resourcePlanner.resourceSelections.push(resourceSelection);
                            setConstructingResourceSelectionPath(undefined);
                        }),
                [
                    resourcePlanner
                ]);

        const deleteResourceSelection =
            useCallback(
                (resourceSelection: ResourceSelection) =>
                    runInAction(
                        () =>
                            (resourcePlanner.resourceSelections as IObservableArray).remove(resourceSelection)),
                [
                    resourcePlanner
                ]);

        const entityContext =
            useComputed(
                () =>
                    resourcePlanner &&
                        EntityContext.fromEntityType(resourcePlanner.resourceParameter.type.type),
                [
                    resourcePlanner
                ]);

        const onDelete =
            useCallback(
                () =>
                    setEntity(undefined),
                [
                    setEntity
                ]);

        const saveResourcePlanner =
            useCallback(
                () =>
                {
                    transactionalEntity.setValueByField(
                        types.ResourcePlanner.Field.Specification,
                        resourcePlanner.toDescriptor());

                    return transactionalEntity.checkAndDoCommit(false, true);
                },
                [
                    types,
                    resourcePlanner
                ]);

        const deleteResourcePlanner =
            useCallback(
                () =>
                    deleteEntity(transactionalEntity)
                        .then(
                            () =>
                                onDelete()),
                [
                    transactionalEntity,
                    onDelete
                ]);

        const dividerGlue = useDividerGlue();

        const isEditable =
            useComputed(
                () =>
                    transactionalEntity ? isMutable(transactionalEntity) : true,
                [
                    transactionalEntity
                ]);

        return <Grid
            container
            spacing={2}
        >
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
            >
                <CardInset>
                    <ViewGroup
                        orientation="vertical"
                        spacing={5}
                    >
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                            >
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <Input
                                        label={types.ResourcePlanner.Type.getName()}
                                        labelPosition="left"
                                    >
                                        <ResourcePlannerSelector
                                            key={entity === undefined ? 'undefined' : entity.uuid}
                                            entityType={props.entityType}
                                            value={entity}
                                            onChange={setEntity}
                                        />
                                    </Input>
                                </ViewGroupItem>
                                {
                                    transactionalEntity &&
                                        <ViewGroupItem>
                                            <EntityMenuButton
                                                entity={transactionalEntity}
                                                onDelete={onDelete}
                                            />
                                        </ViewGroupItem>
                                }
                            </ViewGroup>
                        </ViewGroupItem>
                        {
                            resourcePlanner &&
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={15}
                                    >
                                        <ViewGroupItem>
                                            <InputGroup>
                                                <EntityInput
                                                    labelPosition="left"
                                                    entity={transactionalEntity}
                                                    field={types.ResourcePlanner.Field.Name}
                                                    doAutocommit={false}
                                                />
                                            </InputGroup>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <ViewGroup
                                                orientation="vertical"
                                                spacing={5}
                                            >
                                                <ViewGroupItem>
                                                    <CardHeader>
                                                        <LocalizedText
                                                            code="Generic.Parameters"
                                                            value="Parameters"
                                                        />
                                                    </CardHeader>
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <ParametersEditor
                                                        parameterDictionary={resourcePlanner.parameterDictionary}
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <ViewGroup
                                                orientation="vertical"
                                                spacing={5}
                                            >
                                                <ViewGroupItem>
                                                    <CardHeader>
                                                        <LocalizedText
                                                            code="Generic.Filter"
                                                            value="Filter"
                                                        />
                                                    </CardHeader>
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <FilterEditor
                                                        parameterDictionary={resourcePlanner.allParameterDictionary}
                                                        filterParameter={resourcePlanner.resourceParameter}
                                                        value={resourcePlanner.resourceFilter}
                                                        onChange={setResourceFilter}
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <ViewGroup
                                                orientation="vertical"
                                                spacing={5}
                                            >
                                                <ViewGroupItem>
                                                    <ViewGroup
                                                        orientation="vertical"
                                                        spacing={15}
                                                    >
                                                        <ViewGroupItem>
                                                            <CardHeader>
                                                                <ViewGroup
                                                                    orientation="horizontal"
                                                                    spacing={5}
                                                                    alignment="center"
                                                                >
                                                                    <ViewGroupItem>
                                                                        <LocalizedText
                                                                            code="Configuration.ResourcePlanner.PlannableItems"
                                                                            value="Planbare items"
                                                                        />
                                                                    </ViewGroupItem>
                                                                    <ViewGroupItem>
                                                                        <MenuButton
                                                                            icon="add"
                                                                            tooltip={
                                                                                <LocalizedText
                                                                                    code="Generic.Add"
                                                                                    value="Toevoegen"
                                                                                />
                                                                            }
                                                                            small
                                                                        >
                                                                            <ViewGroup
                                                                                orientation="vertical"
                                                                                spacing={5}
                                                                                glue={dividerGlue}
                                                                            >
                                                                                <ViewGroupItem>
                                                                                    <div
                                                                                        className={styles.addSegmentLabel}
                                                                                    >
                                                                                        <LocalizedText
                                                                                            code="Configuration.ResourcePlanner.PathToPlannableItem"
                                                                                            value="Selecteer het pad naar het planbare item"
                                                                                        />
                                                                                    </div>
                                                                                </ViewGroupItem>
                                                                                <ViewGroupItem>
                                                                                    <FieldPathSelector
                                                                                        key={constructingResourceSelectionPath?.id}
                                                                                        context={entityContext}
                                                                                        onSelect={setConstructingResourceSelectionFieldPath}
                                                                                    />
                                                                                </ViewGroupItem>
                                                                            </ViewGroup>
                                                                        </MenuButton>
                                                                    </ViewGroupItem>
                                                                </ViewGroup>
                                                            </CardHeader>
                                                        </ViewGroupItem>
                                                        {
                                                            constructingResourceSelectionPath &&
                                                                <ViewGroupItem>
                                                                    <Card
                                                                        inset
                                                                    >
                                                                        <ResourceSelectionConstructor
                                                                            resourcePlanner={resourcePlanner}
                                                                            resourcePath={constructingResourceSelectionPath}
                                                                            onConstruct={addResourceSelection}
                                                                        />
                                                                    </Card>
                                                                </ViewGroupItem>
                                                        }
                                                    </ViewGroup>
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <ViewGroup
                                                        orientation="vertical"
                                                        spacing={5}
                                                    >
                                                        {
                                                            resourcePlanner.resourceSelections.map(
                                                                (resourceSelection) =>
                                                                    <ViewGroupItem
                                                                        key={resourceSelection.id}
                                                                    >
                                                                        <ViewGroup
                                                                            orientation="horizontal"
                                                                            spacing={15}
                                                                        >
                                                                            <ViewGroupItem
                                                                                ratio={1}
                                                                            >
                                                                                {resourceSelection.parameter.type.type.getName(true)}
                                                                            </ViewGroupItem>
                                                                            <ViewGroupItem>
                                                                                <DeleteIconButton
                                                                                    onClick={
                                                                                        () =>
                                                                                            deleteResourceSelection(resourceSelection)
                                                                                    }
                                                                                />
                                                                            </ViewGroupItem>
                                                                        </ViewGroup>
                                                                    </ViewGroupItem>)
                                                        }
                                                    </ViewGroup>
                                                </ViewGroupItem>

                                            </ViewGroup>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                </CardInset>
            </Grid>
            {
                resourcePlanner &&
                    <>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                        >
                            <CardInset>
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={5}
                                >
                                    <ViewGroupItem>
                                        <CardHeader>
                                            <LocalizedText
                                                code="Generic.Example"
                                                value="Voorbeeld"
                                            />
                                        </CardHeader>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <Card
                                            inset
                                        >
                                            <ErrorBoundary>
                                                <ResourcePlanner
                                                    key={resourcePlanner.resourceSelections.length}
                                                    id={transactionalEntity.uuid}
                                                    name={transactionalEntity.name}
                                                    resourcePlanner={resourcePlanner}
                                                />
                                            </ErrorBoundary>
                                        </Card>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </CardInset>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <CardInset
                                top={false}
                            >
                                <RightAlignedButtonGroup>
                                    <SaveButton
                                        onClick={saveResourcePlanner}
                                        disabled={!isEditable}
                                    />
                                    {
                                        transactionalEntity &&
                                            <DeleteButton
                                                onClick={deleteResourcePlanner}
                                                disabled={!isEditable}
                                            />
                                    }
                                </RightAlignedButtonGroup>
                            </CardInset>
                        </Grid>
                    </>
            }
        </Grid>;
    };

export default observer(ResourcePlannersEditor);
