import { BaseStore } from '../../../../../../@Framework/Store/BaseStore';
import { action, observable } from 'mobx';
import { OptionsObject, SnackbarKey, SnackbarMessage, WithSnackbarProps } from 'notistack';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import React from 'react';
import { DialogStore } from '../../../../../Generic/Dialog/Type/DialogStore';
import uuid from '../../../../../../@Util/Id/uuid';
import LocalizedText from '../../../../../Domain/Localization/LocalizedText/LocalizedText';

export interface FeedbackProps
{
}

const defaultProps: Partial<FeedbackProps> =
{
};

export interface DialogProps
{
    id?: string;
    store?: DialogStore;
    content?: React.ReactNode;
}

export class FeedbackStore extends BaseStore<FeedbackProps>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable.ref snackbar?: WithSnackbarProps;
    @observable.shallow dialogs = observable.array<DialogProps>([], { deep: false });

    // ------------------------ Constructor -------------------------

    constructor(props?: FeedbackProps)
    {
        super(props, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    setSnackbar(snackbar?: WithSnackbarProps)
    {
        this.snackbar = snackbar;
    }

    @action.bound
    enqueueSnackbar(message: SnackbarMessage, options?: OptionsObject): SnackbarKey
    {
        if (this.snackbar)
        {
            return this.snackbar.enqueueSnackbar(
                message,
                {
                    persist: false,
                    autoHideDuration: 3000,
                    action:
                        key =>
                            <IconButton
                                icon="close"
                                tooltip={
                                    <LocalizedText
                                        code="Generic.Close"
                                        value="Afsluiten"
                                    />
                                }
                                onClick={
                                    () =>
                                    {
                                        this.snackbar?.closeSnackbar(key);
                                    }}
                                color="white"
                            />,
                    preventDuplicate: true,
                    ...options
                });
        }
    }

    @action.bound
    closeSnackbar(key?: SnackbarKey)
    {
        if (this.snackbar)
        {
            this.snackbar.closeSnackbar(key);
        }
    }

    @action.bound
    openDialog(dialog: DialogProps): () => void
    {
        if (!dialog.id)
        {
            dialog.id = uuid();
        }

        this.dialogs.push(dialog);

        return () =>
            this.closeDialog(dialog);
    }

    @action.bound
    closeDialog(dialog: DialogProps)
    {
        this.dialogs.remove(dialog);
    }

    @action.bound
    closeDialogById(id: string)
    {
        this.dialogs
            .filter(
                dialog =>
                    dialog.id === id)
            .forEach(this.closeDialog);
    }

    @action.bound
    clear()
    {
        this.dialogs
            .slice()
            .forEach(
                dialog =>
                    this.closeDialog(dialog));

        this.closeSnackbar();
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
