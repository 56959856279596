import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { WelcomePackage } from '../../Model/Implementation/WelcomePackage';
import { LanguagePackage } from '../../Model/Implementation/LanguagePackage';
import { SettingPackage } from '../../Model/Implementation/SettingPackage';
import { User } from '../../Model/Implementation/User';
import { Entity } from '../../Model/Implementation/Entity';
import { EntityMetadata } from '../../Model/Implementation/EntityMetadata';
import { PackMetadataState } from '../../Model/Implementation/PackMetadataState';
import { PackMetadata } from '../../Model/Implementation/PackMetadata';
import { Currency } from '../../Localization/Currency';

export class WelcomeController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    welcome(metadataState?: string): Promise<WelcomePackage>
    {
        return new Promise<WelcomePackage>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/public/welcome', Method.Get, { metadata_state: metadataState }))
                    .then(result =>
                    {
                        resolve(result);
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    }

    getLanguagePackage(): Promise<LanguagePackage>
    {
        return this.apiClient.request(new ApiRequest<any>('/public/welcome/language-package', Method.Get, {  }))
            .then(result =>
                Promise.resolve(fromJson(result, LanguagePackage)));
    }

    getSettingPackage(): Promise<SettingPackage>
    {
        return this.apiClient.request(new ApiRequest<any>('/public/welcome/setting-package', Method.Get, {  }))
            .then(result =>
                Promise.resolve(fromJson(result, SettingPackage)));
    }

    getPackMetadataStates(): Promise<PackMetadataState[]>
    {
        return this.apiClient.request(new ApiRequest<any>('/public/welcome/metadata/pack/states', Method.Get))
            .then(result =>
                Promise.resolve(fromJson(result, PackMetadataState)));
    }

    getPackMetadata(packId: string,
                    state: string): Promise<any>
    {
        return this.apiClient.request(new ApiRequest<any>(`/public/welcome/metadata/pack/${packId}`, Method.Get, { state: state }))
            .then(result =>
                Promise.resolve(fromJson(result, PackMetadata)));
    }

    getMetadataState(): Promise<string>
    {
        return this.apiClient.request(new ApiRequest<any>('/public/welcome/metadata/state', Method.Get))
            .then(result =>
                Promise.resolve(result));
    }

    getMetadata(metadataState?: string,
                isCached: boolean = false): Promise<EntityMetadata>
    {
        return this.apiClient.request(new ApiRequest<any>('/public/welcome/metadata', Method.Get, { metadata_state: metadataState, is_cached: isCached }))
            .then(result =>
                Promise.resolve(fromJson(result, EntityMetadata)));
    }

    getUser(): Promise<User>
    {
        return this.apiClient.request(new ApiRequest<any>('/public/welcome/user', Method.Get, {  }))
            .then(result =>
                Promise.resolve(fromJson(result, User)));
    }

    getTeam(): Promise<Entity>
    {
        return this.apiClient.request(new ApiRequest<any>('/public/welcome/team', Method.Get, {  }))
            .then(result =>
                Promise.resolve(fromJson(result, Entity)));
    }

    getContract(): Promise<Entity>
    {
        return this.apiClient.request(new ApiRequest<any>('/public/welcome/contract', Method.Get, {  }))
            .then(result =>
                Promise.resolve(fromJson(result, Entity)));
    }

    getCurrencies(): Promise<Currency[]>
    {
        return this.apiClient.request(new ApiRequest<any>('/public/welcome/currencies', Method.Get, {  }))
            .then(result =>
                Promise.resolve(fromJson(result, Currency)));
    }
}
