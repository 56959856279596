import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import JoinWithDelimiterComputation from "../../../../../../../../../../@Api/Automation/Function/Computation/JoinWithDelimiterComputation";
import LocalizedText from "../../../../../../../../Localization/LocalizedText/LocalizedText";
import TextEditor from "../../../../../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor";
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';

export interface JoinWithDelimiterComputationEditorProps extends ComputationEditorProps<ValueType<any>, JoinWithDelimiterComputation>
{

}

const JoinWithDelimiterComputationEditor: React.FC<JoinWithDelimiterComputationEditorProps> =
    props =>
    {
        const setValue =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.value = value),
                [
                    props.value
                ]);

        const setDelimiter =
            useCallback(
                (delimiter?: string) =>
                    runInAction(
                        () =>
                            props.value.delimiter = delimiter
                    ),
                [
                    props.value
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={10}
        >
            <ViewGroupItem>
                <LocalizedText
                    code="Computation.JoinWithDelimiter"
                    value="Samenvoegen met scheidingsteken"
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <ComputationEditor
                    context={props.context}
                    value={props.value.value}
                    onChange={setValue}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <LocalizedText
                    code="Computation.Delimiter"
                    value="Scheidingsteken:"
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <TextEditor
                    value={props.value.delimiter}
                    onChange={setDelimiter}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(JoinWithDelimiterComputationEditor);