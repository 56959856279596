import { observable } from 'mobx';

export default class ValueToEntityMapping
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable sourceValue: any;
    @observable sourceValueName: string;
    @observable targetId: string;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                sourceValue: string,
                sourceValueName: string,
                targetId: string)
    {
        this.id = id;
        this.sourceValue = sourceValue;
        this.sourceValueName = sourceValueName;
        this.targetId = targetId;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new ValueToEntityMapping(
            descriptor.id,
            descriptor.sourceValue,
            descriptor.sourceValueName,
            descriptor.targetId);
    }

    toDescriptor()
    {
        return {
            id: this.id,
            sourceValue: this.sourceValue,
            sourceValueName: this.sourceValueName,
            targetId: this.targetId
        };
    }

    // ----------------------- Private logic ------------------------
}
