import React, { useCallback, useState } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../../Type/Api/useTypes';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import EntitySummary from '../../../../../Summary/EntitySummary';
import styles from './Relationship.module.scss';
import Link from '../../../../../../../../@Future/Component/Generic/Link/Link';
import { openEntity } from '../../../../../@Util/openEntity';
import { RelatedEntityPath } from '../../../../../Path/@Model/RelatedEntityPath';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import Caption from '../../../../../Item/Caption/Caption';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import RelationshipEditorDialog from '../RelationshipEditorDialog/RelationshipEditorDialog';
import CardHeader from '../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import useChildRelation from '../../../../../../../../@Api/Entity/Bespoke/Relationship/useChildRelation';
import HoverCard from '../../../../../../../../@Future/Component/Generic/Card/HoverCard/HoverCard';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import { useExpansion } from '../../../../../Selection/Api/useExpansion';
import GoogleMapWithAddresses from '../../../../../../../Generic/GoogleMaps/GoogleMapWithAddresses';

export interface RelationshipProps
{
    entity: Entity;
}

const Relationship: React.FC<RelationshipProps> =
    props =>
    {
        const types = useTypes();
        const relationship =
            useComputed(
                () =>
                    props.entity.getRelatedEntityByDefinition(
                        true,
                        types.Relationship.RelationshipDefinition.Activities),
                [
                    props.entity,
                    types
                ]);
        const relation = useChildRelation(relationship);

        const openRelationship =
            useCallback(
                () =>
                    openEntity(
                        relationship,
                        new RelatedEntityPath(
                            props.entity,
                            EntityPath.fromEntity(props.entity)
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.Activities,
                                    true))),
                [
                    relationship,
                    props.entity,
                    types
                ]);

        const [ open, setOpen ] = useState(false);

        const editClickCallback =
            useCallback(
                () => setOpen(true),
                [
                    setOpen
                ]);

        const saveCallback =
            useCallback(
                successful =>
                {
                    setOpen(!successful)
                },
                [
                    setOpen
                ]);

        const closeCallback =
            useCallback(
                () => setOpen(false),
                [
                    setOpen
                ]);

        useExpansion(
            relation?.entityType,
            rootPath =>
                relation
                    ?
                        relation.entityType.getInheritedRelationshipDefinitions(false)
                            .filter(
                                relationshipDefinition =>
                                    relationshipDefinition.isSingular(false)
                                    && relationshipDefinition.getEntityType(false).isA(types.Address.Type))
                            .map(
                                addressRsp =>
                                    rootPath
                                        .joinTo(
                                            addressRsp,
                                            false)
                                        .joinTo(
                                            types.Address.RelationshipDefinition.Type,
                                            false))
                    :
                        [],
            () => relation ? [ relation ] : [],
            [
                relation,
                types
            ]);

        return <>
            {
                relationship &&
                    <Card>
                        <CardInset>
                            <div
                                className={styles.header}
                            >
                                <div
                                    className={styles.name}
                                >
                                    <CardHeader>
                                        <Link
                                            onClick={openRelationship}
                                        >
                                            {relationship.name}
                                        </Link>
                                    </CardHeader>
                                    <Caption
                                        entity={relationship}
                                    />
                                </div>
                                <div
                                    className={styles.menu}
                                >
                                    <MenuButton
                                        expansion={
                                            close =>
                                                <Menu>
                                                    <Item
                                                        name={
                                                            <LocalizedText
                                                                code="Relationships.EditRelationship"
                                                                value="Relatie wijzigen"
                                                            />
                                                        }
                                                        onClick={() => { close(); editClickCallback(); }}
                                                    />
                                                </Menu>
                                        }
                                    />
                                </div>
                            </div>
                            <EntitySummary
                                entity={relationship}
                                clickable
                            />
                        </CardInset>
                        <CardInset>
                            <GoogleMapWithAddresses
                                relation={relationship}
                            />
                        </CardInset>
                    </Card>
            }
            {
                !relationship &&
                    <HoverCard
                        onClick={editClickCallback}
                    >
                        <LocalizedText
                            code="Relationships.ConnectRelationshipButton"
                            value="+ Relatie koppelen"
                        />
                    </HoverCard>
            }
            {
                open &&
                    <RelationshipEditorDialog
                        entity={props.entity}
                        saveCallback={saveCallback}
                        closeCallback={closeCallback}
                    />
            }
        </>;
    };

export default observer(Relationship);
