export function mapBy<T, K>(array: T[],
                            key: (element: T) => K): Map<K, T>
{
    const map = new Map<K, T>();

    array.forEach(
        element =>
            map.set(
                key(element),
                element));

    return map;
}
