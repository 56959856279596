import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Header.module.scss';
import useExpansion from '../Hook/useExpansion';
import ExpansionButton from '../../Button/Variant/Expansion/ExpansionButton';
import ViewGroup from '../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import { buttonInset } from '../../../../../@Resource/Theme/Theme';
import { classNames } from '../../../../Util/Class/classNames';
import useExpansionPanelId from '../Hook/useExpansionPanelId';

export interface HeaderClasses
{
    root?: string;
    title?: string;
    titleChevron?: string;
}

export interface HeaderProps
{
    title: React.ReactNode;
    inset?: boolean;
    classes?: HeaderClasses;
    large?: boolean;
    onToggle?: (isExpanded: boolean) => void;
    prefix?: React.ReactNode;
}

const Header: React.FC<HeaderProps> =
    props =>
    {
        const id = useExpansionPanelId();
        const [ isExpanded, setExpansion ] = useExpansion(id);

        const { onToggle } = props;
        const toggleExpansion =
            useCallback(
                () =>
                {
                    setExpansion(!isExpanded);

                    if (onToggle)
                    {
                        onToggle(!isExpanded);
                    }
                },
                [
                    setExpansion,
                    isExpanded,
                    onToggle
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={buttonInset}
            alignment="center"
            className={classNames(styles.root, props.inset && styles.inset, isExpanded && styles.expanded, props.classes && props.classes.root)}
        >
            {
                props.prefix &&
                    <ViewGroupItem>
                        {props.prefix}
                    </ViewGroupItem>
            }
            <ViewGroupItem
                ratio={1}
            >
                <ExpansionButton
                    expanded={isExpanded}
                    onClick={toggleExpansion}
                    label={props.title}
                    forceDisplayLabel={true}
                    classes={{
                        label: classNames(styles.moreLabel, props.classes && props.classes.title, props.large && styles.header),
                        chevron: classNames(styles.moreChevron, props.classes && props.classes.titleChevron)
                    }}
                />
            </ViewGroupItem>
            {
                props.children &&
                    <ViewGroupItem>
                        {props.children}
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(Header);
