import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import Parameter from '../../../../../../../../@Api/Automation/Parameter/Parameter';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationEditor from '../Computation/ComputationEditor';
import Computation from '../../../../../../../../@Api/Automation/Function/Computation/Computation';
import FunctionContext from '../../../../../../../../@Api/Automation/Function/FunctionContext';
import DynamicFunctionInvocation from '../../../../../../../../@Api/Automation/Function/Dynamic/DynamicFunctionInvocation';

export interface DynamicFunctionParameterEditorProps
{
    context: FunctionContext;
    invocation: DynamicFunctionInvocation;
    parameter: Parameter<any>;
    disabled?: boolean;
}

const DynamicFunctionParameterEditor: React.FC<DynamicFunctionParameterEditorProps> =
    props =>
    {
        const computation =
            useComputed(
                () =>
                    props.invocation.parameterAssignment.getComputation(props.parameter),
                [
                    props.invocation,
                    props.parameter
                ]);

        const setComputation =
            useCallback(
                (computation: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.invocation.parameterAssignment.setComputation(
                                props.parameter,
                                computation)),
                [
                    props.invocation,
                    props.parameter
                ]);

        return <Input
            label={props.parameter.name}
            labelPosition="left"
        >
            <ComputationEditor
                context={props.context}
                type={props.parameter.type}
                value={computation}
                onChange={setComputation}
                disabled={props.disabled}
            />
        </Input>;
    };

export default observer(DynamicFunctionParameterEditor);
