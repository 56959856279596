import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { TypeFieldInclusion } from './useVisibleFieldGroups';
import getVisibleFieldPathsForEntity from './getVisibleFieldPathsForEntity';

export default function useVisibleFieldPathsForEntity(
    entity: Entity,
    ignoreHidden: boolean = false,
    forType?: EntityType,
    includeTypeField?: TypeFieldInclusion
)
{
    return useComputed(
        () =>
            getVisibleFieldPathsForEntity(
                entity,
                ignoreHidden,
                forType,
                includeTypeField
            ),
        [
            ignoreHidden,
            entity,
            forType
        ]
    );
}
