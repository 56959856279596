import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import moment from 'moment';
import { dangerColor, successColor } from '../../../../../@Resource/Theme/Theme';

export class BespokeMilestone extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore, code: string = 'ProjectMilestone')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    onConstruct(entity: Entity,
                commitContext?: CommitContext): void
    {
        super.onConstruct(entity, commitContext);

        if (!entity.hasRelationshipsByDefinition(
            false,
            this.entityTypeStore.bespoke.types.Milestone.RelationshipDefinition.Owner,
            commitContext))
        {
            updateRelationship(
                entity,
                false,
                this.entityTypeStore.bespoke.types.Milestone.RelationshipDefinition.Owner,
                loadModuleDirectly(CurrentUserStore).employeeEntity,
                commitContext
            );
        }
    }

    getEntityToOpen(entity: Entity): Entity
    {
        return entity.getRelatedEntityByDefinition(
            true,
            this.types.Activity.Project.RelationshipDefinition.Milestones);
    }

    getEventTitle(entity: Entity): string
    {
        return `Milestone: ${entity.name}`;
    }

    getResourceTitle(entity: Entity): string
    {
        return entity.name;
    }

    getEntityColorForCalendar(entity: Entity): string
    {
        const isOverDue = moment(entity.getObjectValueByField(this.types.Milestone.Field.EndDate)).startOf('day').isBefore(moment().startOf('day'));
        const isFinished = entity.getObjectValueByField(this.types.Milestone.Field.IsFinished);

        if (isOverDue)
        {
            return isFinished ? successColor : dangerColor;
        }

        return super.getEntityColorForCalendar(entity);
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
