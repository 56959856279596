import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';

export function getEntityLayoutParameter(entityType: EntityType)
{
    return new Parameter(
        'Entity',
        new EntityValueType(entityType),
        true,
        entityType.getName()
    );
}
