import { EntityType } from '../Model/Implementation/EntityType';
import { SettingSource } from '../../@Component/Domain/Setting/SettingStore';
import getSetting from '../../@Component/Domain/Setting/Api/getSetting';

export default function getMetadataSettingFlag(entityType: EntityType,
                                               code: string): boolean
{
    const setting = getSetting<number[]>(SettingSource.Organization, code);

    return setting.indexOf(entityType.id) >= 0;
}
