import React from 'react';
import { observer } from 'mobx-react-lite';
import SubmitButtonViewer from '../Viewer/SubmitButtonViewer';

export interface SubmitButtonEditorProps
{
}

const SubmitButtonEditor: React.FC<SubmitButtonEditorProps> =
    props =>
    {
        return <SubmitButtonViewer />;
    };

export default observer(SubmitButtonEditor);
