import { BaseStore, CallbackType, getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { SelectboxItemStore } from './Item/SelectboxItemStore';
import { action, computed, observable, reaction } from 'mobx';
import { StoreState } from '../../../../@Framework/Store/@Model/StoreState';
import { Alignment } from '../../../Domain/DataObject/Model/DataObject';
import { OptionGroup } from './Group/OptionGroup';
import { ButtonStore } from '../../Button/ButtonStore';
import debounce from 'lodash/debounce';
import { TextStore } from '../../Text/TextStore';
import { red } from '@material-ui/core/colors';
import { FormHandlerContext } from '../../Form/FormHandlerContext';
import { FormEvent } from '../../Form/FormEvent';
import { ViewComponent } from '../../ViewStack/Model/ViewComponent';
import localizeText from '../../../../@Api/Localization/localizeText';

export type DataLoader<D> = (query: string) => Promise<Array<OptionGroup<D>>>;
export type ItemConstructor<D> = (
    data: D,
    elements: D[],
    idx: number
) => SelectboxItemStore<D>;
export type ItemCreator<D> = (query: string) => Promise<SelectboxItemStore<D>>;
export type ItemSelector<D> = (items: Array<SelectboxItemStore<D>>) => void;


export interface SelectboxProps<D>
{
    load: DataLoader<D>;
    construct: ItemConstructor<D>;
    create?: ItemCreator<D>;
    onSelect?: ItemSelector<D>;
    onInputValueChange?: (value: string) => void;

    isMultiselect?: PropType<SelectboxStore<D>, SelectboxProps<D>, boolean>;
    isDisabled?: PropType<SelectboxStore<D>, SelectboxProps<D>, boolean>;
    isClearable?: PropType<SelectboxStore<D>, SelectboxProps<D>, boolean>;
    isRequired?: PropType<SelectboxStore<D>, SelectboxProps<D>, boolean>;
    autoFocus?: PropType<SelectboxStore<D>, SelectboxProps<D>, boolean>;
    isTouched?: PropType<SelectboxStore<D>, SelectboxProps<D>, boolean>;
    value?: PropType<SelectboxStore<D>, SelectboxProps<D>, D | D[]>;
    alignment?: PropType<SelectboxStore<D>, SelectboxProps<D>, Alignment>;
    inputValue?: PropType<SelectboxStore<D>, SelectboxProps<D>, string>;
    placeholder?: PropType<SelectboxStore<D>, SelectboxProps<D>, string>;

    onFocus?: CallbackType<SelectboxStore<D>, SelectboxProps<D>, void>;
    onBlur?: CallbackType<SelectboxStore<D>, SelectboxProps<D>, void>;
    onTouch?: CallbackType<SelectboxStore<D>, SelectboxProps<D>, void>;
    onMouseEnter?: CallbackType<SelectboxStore<D>, SelectboxProps<D>, Promise<any>>;
    onMouseLeave?: CallbackType<SelectboxStore<D>, SelectboxProps<D>, Promise<any>>;

    disableUnderline?: PropType<SelectboxStore<D>, SelectboxProps<D>, boolean>;
    showAvatarInSelection?: PropType<SelectboxStore<D>, SelectboxProps<D>, boolean>;
    isOpenByDefault?: PropType<SelectboxStore<D>, SelectboxProps<D>, boolean>;

    handlerContext?: PropType<SelectboxStore<D>, SelectboxProps<D>, FormHandlerContext<D>>;

    componentInput?: (props: any, store: SelectboxStore<D>) => ViewComponent;
    componentValueContainer?: (props: any, store: SelectboxStore<D>) => ViewComponent;
}

export interface ReactSelectOption<D>
{
    value: string;
    label: string;
    itemStore?: SelectboxItemStore<D>;
    buttonStore?: ButtonStore;
}

const defaultSelectboxProps: Partial<SelectboxProps<any>> = {
    isMultiselect: false,
    isClearable: true,
    isTouched: false,
    disableUnderline: true,
    showAvatarInSelection: false,
    handlerContext: new FormHandlerContext()
};

export class SelectboxStore<D, P = {}> extends BaseStore<SelectboxProps<D> & P>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------


    @observable values = observable.array<SelectboxItemStore<D>>();
    @observable options = observable.array<SelectboxItemStore<D>>();
    @observable actions = observable.array<ButtonStore>();
    @observable isOpen: boolean;
    @observable isFocused: boolean;
    @observable isHovering: boolean;
    @observable inputValue: string;
    @observable debouncedLoadOptions: () => void;

    @observable isDataLoading: boolean = false;
    @observable isDataLoaded: boolean = false;
    @observable isTouched: boolean = false;
    @observable invalidTextStore: TextStore;

    // ------------------------ Constructor -------------------------

    constructor(
        props: SelectboxProps<D> & P,
        defaultProps?: Partial<SelectboxProps<D> & P>
    )
    {
        super(props, {
            ...defaultSelectboxProps,
            ...(defaultProps as any)
        } as any);

        this.debouncedLoadOptions = debounce(this.loadOptions, 300);
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        if (this.initialValue)
        {
            this.setValue(
                this.initialValue instanceof Array
                    ? (this.initialValue as D[]).map(
                        data =>
                            this.props.construct(data, undefined, undefined))
                    : [
                        this.props.construct(
                            this.initialValue as D,
                            undefined,
                            undefined
                        )
                    ],
                true
            );
        }

        this.isTouched = getOrCompute(this, this.props.isTouched);

        this.invalidTextStore = new TextStore({
            color: red[500],
            label: localizeText('MandatoryField', 'Dit is een verplicht veld'),
            variant: 'caption'
        });

        if (this.isOpenByDefault)
        {
            this.loadOptions();
        }

        return Promise.resolve();
    }

    entersUI(isMounted: boolean)
    {
        super.entersUI(isMounted);

        this.registerDisposable(
            reaction(
                () => this.providedInputValue,
                () =>
                {
                    if (this.providedInputValue !== undefined)
                    {
                        this.setInputValue(this.providedInputValue);
                    }
                },
                {
                    fireImmediately: true
                }
            )
        );
    }

    // -------------------------- Computed --------------------------

    @computed
    get isMultiselect(): boolean
    {
        return getOrCompute(this, this.props.isMultiselect);
    }

    @computed
    get isDisabled(): boolean
    {
        return getOrCompute(this, this.props.isDisabled);
    }

    @computed
    get isClearable(): boolean
    {
        return getOrCompute(this, this.props.isClearable);
    }

    @computed
    get isRequired(): boolean
    {
        return getOrCompute(this, this.props.isRequired);
    }

    @computed
    get autoFocus(): boolean
    {
        return getOrCompute(this, this.props.autoFocus);
    }

    @computed
    get alignment(): Alignment
    {
        return getOrCompute(this, this.props.alignment);
    }

    @computed
    get initialValue(): D | D[]
    {
        return getOrCompute(this, this.props.value);
    }

    @computed
    get disableUnderline(): boolean
    {
        return getOrCompute(this, this.props.disableUnderline);
    }

    @computed
    get showAvatarInSelection(): boolean
    {
        return getOrCompute(this, this.props.showAvatarInSelection);
    }

    @computed
    get providedInputValue(): string
    {
        return getOrCompute(this, this.props.inputValue);
    }

    @computed
    get isOpenByDefault(): boolean
    {
        return getOrCompute(this, this.props.isOpenByDefault);
    }

    @computed
    get handlerContext(): FormHandlerContext<D[]>
    {
        return getOrCompute(this, this.props.handlerContext);
    }

    @computed
    get reactSelectOptions(): Array<ReactSelectOption<D>>
    {
        const options = this.options.map(option =>
            this.getReactSelectOption(option)
        );

        // Add buttons
        this.actions.forEach(action =>
            options.push(this.getReactSelectActionOption(action))
        );

        return options;
    }

    @computed
    get reactSelectValue(): Array<ReactSelectOption<D>>
    {
        return this.values.map(option => this.getReactSelectOption(option));
    }

    @computed
    get placeholder(): string
    {
        return getOrCompute(this, this.props.placeholder);
    }

    @computed
    get isValid(): boolean
    {
        return (
            !this.isRequired ||
            (this.isRequired && this.reactSelectValue.length > 0)
        );
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    setOptions(groups: Array<OptionGroup<D>>)
    {
        this.options.replace(
            groups
                .map(group =>
                    group.options.map((option, idxInGroup) =>
                        this.props.construct(option, group.options, idxInGroup)
                    )
                )
                .reduce((a, b) => a.concat(b), [])
        );
    }

    @action.bound
    setActions(buttons: ButtonStore[])
    {
        this.actions.replace(buttons);
    }

    @action.bound
    onFocus(event: any)
    {
        if (this.isOpenByDefault)
        {
            return;
        }

        this.isFocused = true;

        if (this.props.onFocus)
        {
            this.props.onFocus(this);
        }

        if (!this.isDataLoaded)
        {
            this.loadOptions();
        }

        this.handlerContext.perform(
            new FormEvent(FormEvent.Focus, undefined, event)
        );
    }

    @action.bound
    onBlur(event: any)
    {
        if (this.isOpenByDefault)
        {
            return;
        }

        this.isFocused = false;
        this.touch();

        if (this.props.onBlur)
        {
            this.props.onBlur(this);
        }

        this.handlerContext.perform(
            new FormEvent(FormEvent.Blur, undefined, event)
        );
    }

    @action.bound
    onKeyDown(event: any)
    {
        this.handlerContext.perform(
            new FormEvent(FormEvent.KeyDown, undefined, event)
        );
    }

    @action.bound
    onMouseEnter(event: any)
    {
        this.isHovering = true;

        if (this.props.onMouseEnter)
        {
            this.props.onMouseEnter(this);
        }
    }

    @action.bound
    onMouseLeave(event: any)
    {
        this.isHovering = false;

        if (this.props.onMouseLeave)
        {
            this.props.onMouseLeave(this);
        }
    }

    @action.bound
    touch()
    {
        this.isTouched = true;

        if (this.props.onTouch)
        {
            this.props.onTouch(this);
        }
    }

    @action.bound
    open()
    {
        this.isOpen = true;
    }

    @action.bound
    close()
    {
        this.isOpen = false;
        this.touch();
    }

    @action.bound
    setInputValue(inputValue: string)
    {
        this.inputValue = inputValue;

        this.debouncedLoadOptions();

        if (this.props.onInputValueChange)
        {
            this.props.onInputValueChange(this.inputValue);
        }

        // this.loadOptions();
    }

    @action.bound
    setValue(itemStores: Array<SelectboxItemStore<D>>, isSilent?: boolean)
    {
        if (!this.isClearable && itemStores.length === 0)
        {
            return;
        }

        // Create new ItemStores with value representation
        const selectedItems = itemStores.map(
            itemStore =>
                new SelectboxItemStore(
                    {
                        data: itemStore.data,
                        textStore: itemStore.textStore,
                        avatarStore: itemStore.avatarStore,
                        isValueRepresentation: true,
                    }
                ));

        this.values.replace(selectedItems);
        this.inputValue = undefined;

        // Run callback on SelectboxStore
        if (!isSilent && this.props.onSelect)
        {
            this.props.onSelect(this.values);
        }

        this.handlerContext.perform(
            new FormEvent(
                FormEvent.Change,
                itemStores.map(itemStore => itemStore.data),
                {}
            )
        );
    }

    @action.bound
    setDataLoading(isLoading: boolean)
    {
        this.isDataLoading = isLoading;
    }

    @action.bound
    setDataLoaded(isLoaded: boolean)
    {
        this.isDataLoaded = isLoaded;
    }

    @action.bound
    filterOption(option: any): boolean
    {
        return true;
    }

    // ------------------------ Public logic ------------------------

    @action.bound
    loadOptions()
    {
        if (this.isDataLoading)
        {
            return;
        }

        this.setDataLoading(true);
        this.setDataLoaded(false);
        this.setState(StoreState.Loading);

        return this.props
            .load(
                this.inputValue === undefined || this.inputValue === ''
                    ? undefined
                    : this.inputValue
            )
            .then(data =>
            {
                this.setOptions(data);

                this.setActions(
                    data
                        .map(group => group.actions)
                        .reduce((a, b) => a.concat(b), [])
                );

                this.setDataLoading(false);
                this.setDataLoaded(true);
                this.setState(StoreState.Loaded);
            })
            .catch(() => this.setDataLoading(false));
    }

    // ----------------------- Private logic ------------------------

    public isSelected(data: any): boolean
    {
        return !!this.values.find(
            value =>
            {
                if (data && value.data && (value.data as any).id !== undefined)
                {
                    return (value.data as any).id === data.id;
                }
                else if (data && value.data && (value.data as any).uuid !== undefined)
                {
                    return (value.data as any).uuid === data.uuid;
                }
                else
                {
                    return value.data === data;
                }
            });
    }

    private getReactSelectOption(option: SelectboxItemStore<D>): ReactSelectOption<D>
    {
        return {
            value: option.uuid,
            label: (option.data as any).name,
            itemStore: option
        };
    }

    private getReactSelectActionOption(
        action: ButtonStore
    ): ReactSelectOption<D>
    {
        return {
            value: action.uuid,
            label: action.labelTextStore.label,
            buttonStore: action
        };
    }
}
