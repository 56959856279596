import React, { useCallback, useEffect, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { PredicateEditorProps } from '../../PredicateEditor';
import ComparisonPredicate from '../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import { Comparator } from '../../../../../../../../DataObject/Model/Comparator';
import StaticSelectbox from '../../../../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import { computed, runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationEditor from '../../../Computation/ComputationEditor';
import styles from './ComparisonPredicateEditor.module.scss';
import CollectionType from '../../../../../../../../../../@Api/Automation/Value/Type/CollectionType';
import { classNames } from '../../../../../../../../../../@Future/Util/Class/classNames';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { LocalizedTextType } from '../../../../../../../../DataObject/Type/LocalizedText/LocalizedTextType';
import { loadModuleDirectly } from '../../../../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../../../../../DataObject/DataObjectStore';
import localizeText from '../../../../../../../../../../@Api/Localization/localizeText';

export interface ComparisonPredicateEditorProps extends PredicateEditorProps<ComparisonPredicate>
{

}

const ComparisonPredicateEditor: React.FC<ComparisonPredicateEditorProps> =
    props =>
    {
        const comparatorOptions =
            useMemo(
                () => [
                    {
                        id: 'Equals',
                        value: Comparator.Equals,
                        label: localizeText('Comparator.Equals', 'is gelijk aan')
                    },
                    {
                        id: 'NotEquals',
                        value: Comparator.NotEquals,
                        label: localizeText('Comparator.NotEquals', 'is niet gelijk aan')
                    },
                    {
                        id: 'IsDefined',
                        value: Comparator.IsDefined,
                        label: localizeText('Comparator.IsDefined', 'is gedefinieerd')
                    },
                    {
                        id: 'IsNotDefined',
                        value: Comparator.IsNotDefined,
                        label: localizeText('Comparator.IsNotDefined', 'is niet gedefinieerd')
                    },
                    {
                        id: 'LessThan',
                        value: Comparator.LessThan,
                        label: localizeText('Comparator.LessThan', 'kleiner dan')
                    },
                    {
                        id: 'LessThanOrEqual',
                        value: Comparator.LessThanOrEqual,
                        label: localizeText('Comparator.LessThanOrEqual', 'kleiner of gelijk aan')
                    },
                    {
                        id: 'GreaterThan',
                        value: Comparator.GreaterThan,
                        label: localizeText('Comparator.GreaterThan', 'groter dan')
                    },
                    {
                        id: 'GreaterThanOrEqual',
                        value: Comparator.GreaterThanOrEqual,
                        label: localizeText('Comparator.GreaterThanOrEqual', 'groter of gelijk aan')
                    },
                    {
                        id: 'Contains',
                        value: Comparator.Contains,
                        label: localizeText('Comparator.Contains', 'bevat')
                    },
                    {
                        id: 'NotContains',
                        value: Comparator.NotContains,
                        label: localizeText('Comparator.NotContains', 'bevat niet')
                    },
                    {
                        id: 'In',
                        value: Comparator.In,
                        label: localizeText('Comparator.In', 'in')
                    },
                    {
                        id: 'StartsWith',
                        value: Comparator.StartsWith,
                        label: localizeText('Comparator.StartsWith', 'start met')
                    },
                    {
                        id: 'EndsWith',
                        value: Comparator.EndsWith,
                        label: localizeText('Comparator.EndsWith', 'eindigt met')
                    }
                ],
                []);

        const setComparator =
            useCallback(
                (comparator: Comparator) =>
                    runInAction(
                        () =>
                            props.value.comparator = comparator),
                [
                    props.value
                ]);

        const setLhs =
            useCallback(
                (lhs: Computation<any, any>) =>
                    runInAction(
                        () =>
                        {
                            props.value.lhs = lhs;
                        }),
                [
                    props.value
                ]);

        const lhsType =
            useComputed(
                () =>
                {
                    if (props.value.lhs
                        && props.value.lhs.isValid())
                    {
                        return props.value.lhs.getType();
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.value
                ]);

        const rhsType =
            useComputed(
                () =>
                {
                    if (lhsType instanceof CollectionType)
                    {
                        return lhsType.type;
                    }
                    else if (lhsType instanceof PrimitiveValueType
                        && lhsType.type instanceof LocalizedTextType)
                    {
                        return new PrimitiveValueType(
                            loadModuleDirectly(DataObjectStore)
                                .getTypeById('Text'));
                    }
                    else
                    {
                        return lhsType;
                    }
                },
                [
                    lhsType,
                    props.value
                ]);

        const setRhs =
            useCallback(
                (rhs: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.rhs = rhs),
                [
                    props.value
                ]);

        useEffect(
            () =>
                computed(
                    () =>
                        props.value.lhs && props.value.lhs.isValid() && props.value.lhs instanceof CollectionType)
                    .observe(
                        change =>
                            runInAction(
                                () =>
                                    props.value.comparator =
                                        change.newValue ? Comparator.Contains : Comparator.Equals)),
            [
                props.value
            ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                <div
                    className={classNames(styles.side, props.comparisonLhsTypeFixed && styles.fixedType)}
                >
                    <ComputationEditor
                        value={props.value.lhs}
                        onChange={setLhs}
                        context={props.context}
                        fixedType={props.comparisonLhsTypeFixed}
                        constructorItemFilter={props.constructorItemFilter}
                        disallowRichText={props.disallowRichText}
                        disallowTextComputation={props.disallowTextComputation}
                        autoFocus={props.autoFocus}
                        disabled={props.disabled}
                    />
                </div>
            </ViewGroupItem>
            <ViewGroupItem>
                <StaticSelectbox
                    options={comparatorOptions}
                    value={props.value.comparator}
                    onChange={setComparator}
                    disabled={props.disabled}
                />
            </ViewGroupItem>
            {
                props.value.comparator !== Comparator.IsDefined &&
                    props.value.comparator !== Comparator.IsNotDefined &&
                        <ViewGroupItem>
                            <div
                                className={styles.side}
                            >
                                <ComputationEditor
                                    type={rhsType}
                                    value={props.value.rhs}
                                    onChange={setRhs}
                                    context={props.context}
                                    constructorItemFilter={props.constructorItemFilter}
                                    disallowRichText={props.disallowRichText}
                                    disallowTextComputation={props.disallowTextComputation}
                                    autoFocus={props.autoFocus}
                                    disabled={props.disabled}
                                />
                            </div>
                        </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(ComparisonPredicateEditor);
