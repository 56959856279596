import FormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/FormInputLayout';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { useComputed } from 'mobx-react-lite';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import PrimitiveValueType from '../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';

export function useFormInputParameter(layout: FormInputLayout,
                                      parameters: ParameterDictionary)
{
    return useComputed<Parameter<PrimitiveValueType>>(
        () =>
            layout.parameterId
            && parameters.getParameterById(layout.parameterId),
        [
            layout,
            parameters
        ]);
}
