import Value from '../Value/Value';
import { DependencyList, useCallback, useEffect, useMemo, useState } from 'react';
import { computed } from 'mobx';

export default function useReactiveValues<T>(
    collectionSupplier: () => T[],
    collectionDependencies: DependencyList,
    valueFetcher: (value: T) => Value<any, any>,
    reactiveValueConstructor: (baseValue: T, value: Value<any, any>) => T
)
{
    const memoizedValues = useMemo(collectionSupplier, collectionDependencies);
    const [ currentValues, setCurrentValues ] = useState(memoizedValues);
    const memoizedValueFetcher = useCallback(valueFetcher, []);
    const memoizedReactiveValueConstructor = useCallback(reactiveValueConstructor, []);

    useEffect(
        () =>
        {
            const reactiveInterfaces =
                memoizedValues
                    .map(
                        element =>
                            memoizedValueFetcher(element)
                    )
                    .map(
                        value =>
                            value.reactiveInterface
                    );

            setCurrentValues(memoizedValues);

            return computed(
                () =>
                    reactiveInterfaces
                        .map(
                            reactiveInterface =>
                                reactiveInterface?.getState() || '')
                        .join(';')
            )
                .observe(
                    () =>
                    {
                        const newValues =
                            reactiveInterfaces.map(
                                (reactiveInterface, idx) =>
                                {
                                    const value = reactiveInterface?.getValue();

                                    if (value === undefined)
                                    {
                                        return memoizedValues[idx];
                                    }
                                    else
                                    {
                                        return memoizedReactiveValueConstructor(
                                            memoizedValues[idx],
                                            value
                                        );
                                    }
                                }
                            );
                        setCurrentValues(newValues);
                    });
        },
        [
            memoizedValues,
            setCurrentValues,
            memoizedValueFetcher,
            memoizedReactiveValueConstructor,
        ]
    );

    return currentValues;
}