export function deduplicateArray<T>(
    array: T[],
    keyMapper: (value: T) => any = value => value
)
{
    const deduplicatedArray: T[] = [];
    const keys = new Set();

    for (const element of array)
    {
        const key = keyMapper(element);

        if (!keys.has(key))
        {
            deduplicatedArray.push(element);
            keys.add(key);
        }
    }

    return deduplicatedArray;
}
