import React from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import { PrivilegeEditor } from './PrivilegeEditor/PrivilegeEditor';
import Role, { AllPrivileges, RoleType } from '../../Model/Role';
import { EntityMetadataPermission } from '../../Model/EntityMetadataPermission';

export interface RightEditorProps
{
    rolesByType: Map<RoleType, Role>;
    role: Role;
    metadataPermission: EntityMetadataPermission;
    isReadOnly: boolean;
}

export const RightEditor: React.FC<RightEditorProps> =
    observer(
        ({
             rolesByType,
             role,
             metadataPermission,
             isReadOnly,
        }) =>
        {
            return <ViewGroup
                orientation="horizontal"
                spacing={16}
            >
                {
                    AllPrivileges.map(
                        privilege =>
                            <PrivilegeEditor
                                key={privilege}
                                rolesByType={rolesByType}
                                role={role}
                                metadataPermission={metadataPermission}
                                privilege={privilege}
                                isReadOnly={isReadOnly}
                            />
                    )
                }
            </ViewGroup>;
        }
    );
