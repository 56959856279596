import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './SourceField.module.scss';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import { default as SourceFieldModel } from '../Model/SourceField';
import ImportResult from '../Model/ImportResult';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Icon from '../../../../../@Future/Component/Generic/Icon/Icon';
import { successColor } from '../../../../../@Resource/Theme/Theme';

export interface SourceFieldProps
{
    sourceField: SourceFieldModel;
    usedSourceFields: Set<SourceFieldModel>;
    result?: ImportResult;
}

const SourceField: React.FC<SourceFieldProps> =
    props =>
    {
        const isUsed =
            useMemo(
                () =>
                    props.usedSourceFields.has(props.sourceField),
                [
                    props.usedSourceFields,
                    props.sourceField
                ]);

        return <CardInset>
            <ViewGroup
                orientation="horizontal"
                alignment="center"
                spacing={15}
            >
                <ViewGroupItem
                    ratio={1}
                >
                    <div
                        className={isUsed ? styles.used : undefined}
                    >
                        <div>
                            {props.sourceField.name}
                        </div>
                        {
                            props.result &&
                            props.result.sourceInstance &&
                            props.result.sourceInstance.dataObjectByFieldId &&
                            props.result.sourceInstance.dataObjectByFieldId.has(props.sourceField.id) &&
                            <div
                                className={styles.preview}
                            >
                                {props.result.sourceInstance.dataObjectByFieldId.get(props.sourceField.id).toString()}
                            </div>
                        }
                    </div>
                </ViewGroupItem>
                {
                    isUsed &&
                        <ViewGroupItem>
                            <Icon
                                icon="check"
                                color={successColor}
                            />
                        </ViewGroupItem>
                }
            </ViewGroup>
        </CardInset>;
    };

export default observer(SourceField);
