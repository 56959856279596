import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Layout from '../../../../../../@Api/Layout/Layout';
import { LayoutEditorProps } from '../../LayoutEditor';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LayoutControlsTypeContent from './TypeContent/LayoutControlsTypeContent';
import RightAlignedButtonGroup from '../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import PrimaryTextButton from '../../../../../../@Future/Component/Generic/Button/Variant/PrimaryTextButton/PrimaryTextButton';
import LayoutConstructor from '../../Constructor/LayoutConstructor';
import DeleteButton from '../../../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';
import { copyLayoutToClipboard } from '../../Clipboard/LayoutClipboard';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { FeedbackStore } from '../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface LayoutControlsContentProps<L extends Layout> extends LayoutEditorProps<L>
{
    onDelete?: () => void;
}

const LayoutControlsContent: React.FC<LayoutControlsContentProps<Layout>> =
    props =>
    {
        const copyToClipboard =
            useCallback(
                () =>
                {
                    copyLayoutToClipboard(props.layout);

                    loadModuleDirectly(FeedbackStore)
                        .enqueueSnackbar(
                            localizeText('Generic.CopiedToClipboard', 'Gekopieerd naar klembord'),
                            {
                                variant: 'success'
                            });
                },
                [
                    props.layout
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <LayoutControlsTypeContent
                    {...props}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <ViewGroupItem>
                    <CardInset
                        top={false}
                    >
                        <RightAlignedButtonGroup>
                            <PrimaryTextButton
                                label={
                                    <LocalizedText
                                        code="Generic.CopyToClipboard"
                                        value="Kopieren naar klembord"
                                    />
                                }
                                onClick={copyToClipboard}
                            />
                            {
                                props.onChange &&
                                <LayoutConstructor
                                    parameterDictionary={props.parameterDictionary}
                                    onConstruct={props.onChange}
                                    mode="EditButton"
                                    layout={props.layout}
                                />
                            }
                            {
                                props.onDelete &&
                                    <DeleteButton
                                        onClick={props.onDelete}
                                    />
                            }
                        </RightAlignedButtonGroup>
                    </CardInset>
                </ViewGroupItem>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(LayoutControlsContent);
