import React, { useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import Dialog, { DialogProps } from '../../../../@Future/Component/Generic/Dialog/Dialog';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import CardHeader from '../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import PrimaryButton from '../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import RouterContext from '../../../../@Service/Router/RouterContext';
import performAction from '../../../../@Api/Entity/performAction';
import { CurrentUserStore } from '../../User/CurrentUserStore';
import EmailAddressesEditor from '../../../../@Future/Component/Generic/Input/EmailAddressesEditor/EmailAddressesEditor';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import SuccessButton from '../../../../@Future/Component/Generic/Button/Variant/SuccessButton/SuccessButton';
import getErrorData from '../../../../@Api/Error/getErrorData';
import localizeText from '../../../../@Api/Localization/localizeText';
import sendAnalyticsLogging, { EventTypes } from '../../../../@Util/Analytics/sendAnalyticsLogging';

export interface InviteEmployeesProps extends DialogProps
{

}

const InviteEmployees: React.FC<InviteEmployeesProps> =
    props =>
    {
        const [ emailAddresses, setEmailAddresses ] = useState<string[]>([]);

        const { onClose } = props;
        const [ isInsufficientLicensesError, setInsufficientLicensesError ] = useState<boolean>(false);

        const save =
            useCallback(
                () =>
                    performAction(
                        loadModuleDirectly(CurrentUserStore).environmentEntity,
                        {
                            code: 'Environment.InviteUsers',
                            parameters: {
                                emailAddresses: emailAddresses
                            },
                            name: localizeText('Environment.InviteColleagues', 'Gebruikers uitnodigen')
                        })
                        .then(
                            () =>
                            {
                                sendAnalyticsLogging(EventTypes.InviteUser);

                                setEmailAddresses([]);
                                onClose();

                                alert(localizeText('InviteColleaguesDialog.Info', 'De door jouw ingevulde collega\'s zijn uitgenodigd. Ze krijgen via de mail een uitnodigingslink toegestuurd.'));
                            }
                        )
                        .catch(
                            error =>
                            {
                                if (getErrorData(error).error === 'insufficient-licenses-available')
                                {
                                    setInsufficientLicensesError(true);
                                }
                                else
                                {
                                    return Promise.reject(error);
                                }
                            }
                        ),
                [
                    emailAddresses,
                    setEmailAddresses,
                    onClose,
                    setInsufficientLicensesError
                ]);

        const routerStore = useContext(RouterContext);
        const openAccountPage =
            useCallback(
                () =>
                {
                    onClose();

                    return routerStore.route('/account');
                },
                [
                    routerStore,
                    onClose
                ]);

        return <Dialog
            {...props}
            width="sm"
        >
            <CardInset>
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    <ViewGroupItem>
                        <CardHeader>
                            <LocalizedText
                                code="InviteColleaguesDialog.Title"
                                value="Collega's uitnodigen"
                            />
                        </CardHeader>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <LocalizedText
                            code="InviteColleaguesDialog.Help"
                            value="Nodig je collega's uit door hieronder de e-mail adressen in te voeren."
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                      <EmailAddressesEditor
                            value={emailAddresses}
                            onChange={setEmailAddresses}
                            autoFocus
                        />
                      </ViewGroupItem>
                    {
                        isInsufficientLicensesError &&
                            <ViewGroupItem>
                                <LocalizedText
                                    code="InviteColleaguesDialog.InsufficientLicensesAvailable"
                                    value="Er zijn niet genoeg licenties beschikbaar om ${numberOfColleagues} collega(s) uit te nodigen."
                                    numberOfColleagues={emailAddresses.length}
                                />
                            </ViewGroupItem>
                    }
                    {
                        isInsufficientLicensesError &&
                            <ViewGroupItem>
                                <SuccessButton
                                    label={
                                        <LocalizedText
                                            code="InviteColleaguesDialog.OpenLicenseManager"
                                            value="Licentiebeheer openen"
                                        />
                                    }
                                    onClick={openAccountPage}
                                />
                            </ViewGroupItem>
                    }
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={0}
                        >
                            <ViewGroupItem
                                ratio={1}
                            />
                            <ViewGroupItem>
                                <PrimaryButton
                                    label={
                                        <LocalizedText
                                            code="Generic.Invite"
                                            value="Uitnodigen"
                                        />
                                    }
                                    onClick={save}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </CardInset>
        </Dialog>;
    };

export default observer(InviteEmployees);
