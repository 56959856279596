import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldValueEditorProps } from '../FieldValueEditor';
import Editor from '../../../../../../../DataObject/Editor/Editor';
import { DataObject } from '../../../../../../../DataObject/Model/DataObject';
import { computed } from 'mobx';

export interface PrimitiveValueEditorProps extends FieldValueEditorProps
{

}

const PrimitiveValueEditor: React.FC<PrimitiveValueEditorProps> =
    props =>
    {
        const dataObject =
            useMemo<DataObject>(
                () =>
                {
                    if (props.value)
                    {
                        return props.value as DataObject;
                    }
                    else
                    {
                        return DataObject.constructFromTypeIdAndValue(
                            props.field.type,
                            undefined);
                    }
                },
                [
                    props.field,
                    props.value
                ]);

        useEffect(
            () =>
                computed(
                    () =>
                        dataObject.isEmpty)
                    .observe(
                        change =>
                        {
                            if (change.newValue)
                            {
                                props.onChange(undefined);
                            }
                            else
                            {
                                props.onChange(dataObject);
                            }
                        }),
            [
                dataObject,
                props.onChange
            ]);

        return <Editor
            dataObject={dataObject}
            placeholder={props.placeholder}
            disabled={props.disabled}
        />;
    };

export default observer(PrimitiveValueEditor);
