import { observable } from 'mobx';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import getPredicateFromDescriptor from '../../Api/getPredicateFromDescriptor';
import Action from './Action';
import Predicate from '../Computation/Predicate/Predicate';
import getActionFromDescriptor from '../../Api/getActionFromDescriptor';
import Validation from '../../Validation/Validation';
import uuid from '../../../../@Util/Id/uuid';

export default class ConditionalInAction
{
    // ------------------------- Properties -------------------------

    @observable.ref id: string;
    @observable.ref predicate: Predicate;
    @observable.ref action: Action<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(predicate: Predicate,
                action: Action<any, any>)
    {
        this.id = uuid();
        this.predicate = predicate;
        this.action = action;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        const validations: Validation[] = [];

        if (!this.predicate)
        {
            validations.push(
                new Validation(
                    'Error',
                    'Geen geldige conditie gespecificeerd.'));
        }

        if (!this.action)
        {
            validations.push(
                new Validation(
                    'Error',
                    'Geen geldige actie gespecificeerd.'));
        }

        return validations;
    }

    isAsync(): boolean
    {
        return this.predicate.isAsync()
            || this.action.isAsync();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.predicate.getDependencies(),
            ...this.action.getDependencies()
        ];
    }

    toDescriptor()
    {
        return {
            predicate: this.predicate.toDescriptor(),
            action: this.action.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new ConditionalInAction(
            await getPredicateFromDescriptor(
                descriptor.predicate,
                dependencyContext),
            await getActionFromDescriptor(
                descriptor.action,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
