import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import RichtextEditor from '../../../../../../../@Future/Component/Generic/Input/RichtextEditor/RichtextEditor';
import LocalizationContext from '../../../../../../../@Service/Localization/LocalizationContext';
import CollapsibleToolbar from '../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/CollapsibleToolbar';
import EditorButton from '../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorCommandButton';
import EditorFontSelector from '../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorFontSelector';
import EditorColorPicker from '../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorColorPicker';
import styles from './NoteTextEditor.module.scss';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import DefaultToolbarButtons from '../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/DefaultToolbarButtons';

export interface NoteTextEditorProps
{
    value?: string;
    onChange: (value?: string) => void;
    white?: boolean;
    autoFocus?: boolean;
    onKeyDown?: (event: any) => void;
}

const NoteTextEditor: React.FC<NoteTextEditorProps> =
    props =>
    {
        const localizationStore = useContext(LocalizationContext);
        const note = props.value;
        const setNote = props.onChange;
        const placeholderText =
            useMemo(
                () =>
                    localizationStore
                        .translate(
                            'EntityNoteEditor.PlaceholderText'),
                [
                    localizationStore
                ]);
        const onKeyDownCallback = props.onKeyDown;

        // Added defaultValue because without it, the note adds an empty lines when adding sorted or unsorter lists
        return <RichtextEditor
            placeholder={placeholderText}
            defaultValue={note}
            value={note}
            onChange={setNote}
            onKeyDown={onKeyDownCallback}
            autoFocus={props.autoFocus}
            collapsibleToolbar
            hideToolbarWhenEmpty
            allowTextStyling
            classes={{
                editor:
                    classNames(
                        styles.editor,
                        props.white && styles.white),
                toolbar: styles.toolbar
            }}
        >
            {props.children}
            <CollapsibleToolbar>
                <DefaultToolbarButtons />
                <EditorButton icon="link" tooltip="Link" command="link"/>
                <EditorFontSelector />
                <EditorColorPicker tooltip="Selecteer kleur" />
            </CollapsibleToolbar>
        </RichtextEditor>;
    };

NoteTextEditor.defaultProps =
{
    white: false,
    autoFocus: false,
};

export default observer(NoteTextEditor);