import { findAssistantEntityTypeOrThrow } from './findAssistantEntityTypeOrThrow';
import { ViewParams } from '../../Entity/View/Model/ViewParams';
import { AssistantAutomation } from '../Model/Automation/AssistantAutomation';
import Automation from '../../../../@Api/Automation/Automation';
import Parameter from '../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../@Api/Automation/Value/Type/EntityValueType';
import ParameterDictionary from '../../../../@Api/Automation/Parameter/ParameterDictionary';
import { findAssistantTrigger } from './Trigger/findAssistantTrigger';
import { findAssistantAction } from './Action/findAssistantAction';
import { findAssistantPredicate } from './Predicate/findAssistantPredicate';
import { runInAction } from 'mobx';
import CompositeAction from '../../../../@Api/Automation/Function/Action/CompositeAction';
import CompositeActionInvocation from '../../../../@Api/Automation/Function/Action/CompositeActionInvocation';
import uuid from '../../../../@Util/Id/uuid';

export function findAssistantAutomation(
    specification: AssistantAutomation
): Automation
{
    const entityType = findAssistantEntityTypeOrThrow(specification.entityType);
    const rootParameter =
        new Parameter<EntityValueType>(
            ViewParams.Entity,
            new EntityValueType(entityType),
            true,
            entityType.getName()
        );
    const parameters = new ParameterDictionary([ rootParameter ]);
    const trigger =
        findAssistantTrigger(
            specification.trigger,
            parameters,
            rootParameter
        );
    const triggerParameters =
        new ParameterDictionary(
            trigger.getParameters()
        );
    const predicate =
        specification.predicate
            ? findAssistantPredicate(
                specification.predicate,
                triggerParameters,
                rootParameter
            )
            : undefined;

    if (predicate)
    {
        runInAction(
            () =>
                trigger.predicate = predicate
        );
    }

    let action =
        findAssistantAction(
            specification.action,
            triggerParameters,
            rootParameter
        );

    if (!(action instanceof CompositeAction))
    {
        action =
            new CompositeAction(
                [
                    new CompositeActionInvocation(
                        uuid(),
                        action
                    )
                ]
            );
    }

    return new Automation(
        triggerParameters,
        trigger,
        action
    );
}
