import AutomationDependencyContext from '../../AutomationDependencyContext';
import CreationTrigger from '../CreationTrigger';
import DeletionTrigger from '../DeletionTrigger';
import UpdateTrigger from '../UpdateTrigger';
import CompositeTrigger from '../CompositeTrigger';

export default async function getMutationTriggerFromDescriptor(descriptor: any,
                                                               dependencyContext: AutomationDependencyContext)
{
    switch (descriptor.type)
    {
        case 'Creation':
            return CreationTrigger.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Update':
            return UpdateTrigger.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Deletion':
            return DeletionTrigger.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Composite':
            return CompositeTrigger.fromDescriptor(
                    descriptor,
                    dependencyContext);
    }

    throw new Error();
}
