import React from 'react';
import { CheckboxControl, PanelBody, PanelRow } from '@wordpress/components';
import { BlockEditProps } from '@wordpress/blocks';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';

export interface FieldOptionsPanelProps
{
    props: BlockEditProps<{}>;
    open?: boolean;
}

const FieldOptionsPanel: React.FC<FieldOptionsPanelProps> =
    props =>
    {
        const {
            isDisabled,
            isHidden,
            fieldId,
        } = props.props.attributes as any;

        return <PanelBody
            title={
                <LocalizedText
                    code="Generic.Field"
                    value="Veld"
                />
            }
            initialOpen={!!props.open}
        >
            <PanelRow>
                <CheckboxControl
                    name="Disabled"
                    label={
                        <LocalizedText
                            code="Generic.Disabled"
                            value="Gedeactiveerd"
                        />
                    }
                    checked={isDisabled}
                    onChange={isDisabled => props.props.setAttributes({ isDisabled, })}
                />
            </PanelRow>
            <PanelRow>
                <CheckboxControl
                    name="Hidden"
                    label={
                        <LocalizedText
                            code="Generic.Hidden"
                            value="Verborgen"
                        />
                    }
                    checked={isHidden}
                    onChange={isHidden => props.props.setAttributes({ isHidden, })}
                />
            </PanelRow>
            <PanelRow>
                <Input
                    label={
                        <LocalizedText
                            code="Generic.UrlParameter"
                            value="URL parameter"
                        />
                    }
                >
                    {fieldId}
                </Input>
            </PanelRow>
        </PanelBody>;
    };

export default FieldOptionsPanel;
