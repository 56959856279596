import React from 'react';
import { GutenbergCoreBlockGroupId } from '../../../DocumentEditor';
import ColorOptionsPanel from '../../Settings/ColorOptionsPanel';
import GutenbergBlockType from '../../../Gutenberg/GutenbergBlockType';
import StyledBlock from '../../StyledBlock/StyledBlock';
import GutenbergBlockViewer from '../../../Gutenberg/GutenbergBlockViewer';
import { useSelect } from '@wordpress/data';
import { __experimentalUseInnerBlocksProps as useInnerBlocksProps, InnerBlocks, InspectorControls, store as blockEditorStore, useBlockProps, useSetting } from '@wordpress/block-editor';

const GutenbergCoreBlockGroup: GutenbergBlockType =
    {
        name: GutenbergCoreBlockGroupId,

        // https://github.com/WordPress/gutenberg/blob/trunk/packages/block-library/src/group

        configuration: {
            title: {
                code: 'GutenbergBlock.Group',
                value: 'Groep'
            },
            category: "design",
            //icon: <Icon icon="group" />,
            description: "Combine blocks into a group.",
            keywords: [
                "container",
                "wrapper",
                "row",
                "section"
            ],
            textdomain: "default",
            attributes: {
                tagName: {
                    type: "string",
                    default: "div"
                },
                templateLock: {
                    type: [
                        "string",
                        "boolean"
                    ],
                    enum: [
                        "all",
                        "insert",
                        false
                    ]
                }
            },
            supports: {
                align: [
                    "wide",
                    "full"
                ],
                anchor: true,
                html: false,
                color: {
                    gradients: false,
                    link: true
                },
                spacing: {
                    padding: true,
                    blockGap: true,
                    __experimentalDefaultControls: {
                        padding: true,
                        blockGap: true
                    }
                },
                __experimentalBorder: {
                    color: true,
                    radius: true,
                    style: true,
                    width: true,
                    __experimentalDefaultControls: {
                        color: true,
                        radius: true,
                        style: true,
                        width: true
                    }
                },
                __experimentalLayout: true
            },

            edit: props =>
            {
                return <GutenbergCoreBlockGroupEditor
                    {...props}
                />;
            },

            save: (props) =>
            {
                const { tagName: Tag } = props.attributes;
                return (
                    <Tag { ...useBlockProps.save() }>
                        <InnerBlocks.Content />
                    </Tag>
                );
            }
        },

        view: (block, props) =>
        {
            return <StyledBlock
                    block={block}
                >
                    <GutenbergBlockViewer
                        {...props}
                        blocks={block.innerBlocks}
                    />
                </StyledBlock>;
        }
    };

const GutenbergCoreBlockGroupEditor =
    (props: any) =>
    {
        const { clientId } = props;
        const {
            tagName: TagName = 'div',
            templateLock,
            layout = {},
            backgroundColor,
            fontColor,
            borderColor
        } = props.attributes;

        const {
            hasInnerBlocks,
            themeSupportsLayout
        } = useSelect((select) =>
        {
            const {
                getBlock,
                getSettings
            } = select(blockEditorStore);
            const block = getBlock(clientId);
            return {
                hasInnerBlocks: !!(block && block.innerBlocks.length),
                themeSupportsLayout: getSettings()?.supportsLayout,
            };
        }, [
            clientId
        ]);

        const defaultLayout = useSetting('layout') || {};

        const usedLayout = !!layout && layout.inherit ? defaultLayout : layout;
        const {type = 'default'} = usedLayout;
        const layoutSupportEnabled = themeSupportsLayout || type !== 'default';

        const blockProps = useBlockProps({
            style: {
                backgroundColor: backgroundColor,
                color: fontColor,
                borderColor: borderColor
            }
        });

        const innerBlocksProps = useInnerBlocksProps(
            layoutSupportEnabled ? blockProps : {className: 'wp-block-group__inner-container'},
            {
                templateLock,
                renderAppender: hasInnerBlocks ? undefined : InnerBlocks.ButtonBlockAppender,
                __experimentalLayout: layoutSupportEnabled ? usedLayout : undefined,
            });

        return (<>
                <InspectorControls>
                    <ColorOptionsPanel
                        props={props}
                        disableBorderColor
                        disableFontColor
                    />
                </InspectorControls>
                {
                    layoutSupportEnabled &&
                    <TagName
                        {...innerBlocksProps}
                    />
                }
                {
                    !layoutSupportEnabled &&
                    <TagName {...blockProps}>
                        <div {...innerBlocksProps} />
                    </TagName>
                }
                </>);
    }

export default GutenbergCoreBlockGroup;
