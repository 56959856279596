import React, { useCallback } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import styles from './SelectionCursorHandle.module.scss';
import { SelectionCursor } from '../SelectionCursor';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';

export interface SelectionCursorHandleProps
{
    entity: Entity;
    cursor: SelectionCursor;
    open?: boolean;
    onToggle: (isOpen: boolean) => void;
}

const SelectionCursorHandle: React.FC<SelectionCursorHandleProps> =
    props =>
    {
        const toggle =
            useCallback(
                () =>
                    props.onToggle(!props.open),
                [
                    props.onToggle,
                    props.open
                ]);

        return <div
            className={classNames(styles.root, props.open && styles.open)}
        >
            <div
                className={styles.container}
            >
                {
                    !props.open &&
                        <div
                            className={styles.handleContainer}
                            onClick={toggle}
                        >
                            <div
                                className={styles.handle}
                            >
                                {props.cursor.name}
                            </div>
                        </div>
                }
            </div>
        </div>;
    };

export default observer(SelectionCursorHandle);
