import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import styles from './Note.module.scss';
import Avatar from '../../../Avatar/Avatar';
import NoteEditor from '../../../Bespoke/Note/Editor/NoteEditor';
import { ContentProps } from '../Content';
import useRelatedEntity from '../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import useTypes from '../../../Type/Api/useTypes';
import Input from '../../../Input/Input';
import DetailsLayout from '../../../Viewer/Shared/Details/DetailsLayout/DetailsLayout';

export interface NoteProps extends ContentProps
{
    white?: boolean;
}

const Note: React.FC<NoteProps> =
    props =>
    {
        const currentUser = useContext(CurrentUserContext).employeeEntity;
        const types = useTypes();
        const parentEntity =
            useRelatedEntity(
                props.entity,
                types.Entity.RelationshipDefinition.Notes,
                true,
                props.commitContext
            );
        const isParentEntitySupportTicket =
            useComputed(
                () =>
                    parentEntity?.entityType.isA(types.Activity.SupportTicket.Type),
                [
                    parentEntity,
                    types
                ]);

        const onCloseDetailsEditMode = useCallback(() => {}, []);

        return <ViewGroup
            orientation="horizontal"
            spacing={7}
        >
            <ViewGroupItem
                className={styles.avatar}
            >
                <Avatar
                    entity={currentUser}
                    size={25}
                />
            </ViewGroupItem>
            <ViewGroupItem
                ratio={1}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={15}
                >
                    <ViewGroupItem>
                        <NoteEditor
                            white={props.white}
                            entity={props.entity}
                            onSave={props.onSave}
                            onClose={props.onClose}
                            autoFocus={props.autoFocus}
                            commitContext={props.commitContext}
                        >
                            <ViewGroup
                                orientation="vertical"
                                spacing={0}
                            >
                                {
                                    isParentEntitySupportTicket &&
                                        <ViewGroupItem>
                                            <Input
                                                entity={props.entity}
                                                labelPosition="left"
                                                field={types.Note.Field.IsVisibleInSupportPortal}
                                                doAutocommit={false}
                                                commitContext={props.commitContext}
                                            />
                                        </ViewGroupItem>
                                }
                                <ViewGroupItem>
                                    <DetailsLayout
                                        entity={props.entity}
                                        showNoneIfNoLayout
                                        isInEditMode={false}
                                        onCloseEditMode={onCloseDetailsEditMode}
                                        commitContext={props.commitContext}
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </NoteEditor>
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
        </ViewGroup>;
    };


export default observer(Note);
