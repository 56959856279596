import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { observable } from 'mobx';
import Series from './Series';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import getSeriesFromDescriptor from '../Api/getSeriesFromDescriptor';
import { DataObject } from '../../../DataObject/Model/DataObject';
import FilterOption from '../../Dataset/Model/FilterOption';
import getViewParameters from '../../View/Api/getViewParameters';
import getPredicateFromDescriptor from '../../../../../@Api/Automation/Api/getPredicateFromDescriptor';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import getNewViewPredicateFromOldPredicate from '../../View/Api/getNewViewPredicateFromOldPredicate';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { PredicateTypeStore } from '../../../Predicate/PredicateTypeStore';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import { ViewParams } from '../../View/Model/ViewParams';

export default class Chart
{
    // ------------------------- Properties -------------------------

    @observable.ref entityType: EntityType;
    @observable.ref parameters: ParameterDictionary;
    @observable.shallow series: Series[];
    @observable.ref filter?: Predicate;
    @observable.ref range?: DataObject;
    @observable.ref interval?: DataObject;
    @observable.shallow filterOptions: FilterOption[];

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType,
                parameters: ParameterDictionary,
                series: Series[],
                filter?: Predicate,
                range?: DataObject,
                interval?: DataObject,
                filterOptions: FilterOption[] = [])
    {
        this.entityType = entityType;
        this.parameters = parameters;
        this.series = series;
        this.filter = filter;
        this.range = range;
        this.interval = interval;
        this.filterOptions = filterOptions;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    get parameter(): Parameter<EntityValueType>
    {
        return this.parameters.getParameterById(ViewParams.Entity);
    }

    static async fromDescriptor(descriptor: any)
    {
        const entityType = loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.entityTypeId);
        const parameters = getViewParameters(entityType);
        let filter: Predicate;

        if (descriptor.filter)
        {
            if (descriptor.isFilterConverted)
            {
                filter =
                    await getPredicateFromDescriptor(
                        descriptor.filter,
                        new AutomationDependencyContext(parameters));
            }
            else
            {
                const oldFilter = loadModuleDirectly(PredicateTypeStore).fromSpecification(descriptor.filter);

                if (oldFilter)
                {
                    filter =
                        await getNewViewPredicateFromOldPredicate(
                            parameters,
                            oldFilter);
                }
            }
        }

        return new Chart(
            entityType,
            parameters,
            descriptor.series.map(
                series =>
                    getSeriesFromDescriptor(series)),
            filter,
            descriptor.range
                ?
                    DataObject.constructFromDescriptor(descriptor.range)
                :
                    undefined,
            descriptor.interval
                ?
                    DataObject.constructFromDescriptor(descriptor.interval)
                :
                    undefined,
            descriptor.filterOptions.map(
                filterOption =>
                    FilterOption.fromDescriptor(filterOption)));
    }

    toDescriptor()
    {
        return {
            entityTypeId: this.entityType.id,
            series:
                this.series.map(
                    series =>
                        series.toDescriptor()),
            filter: this.filter?.toDescriptor(),
            isFilterConverted: true,
            range:
                this.range
                    ?
                        this.range.descriptor()
                    :
                        undefined,
            interval:
                this.interval
                    ?
                        this.interval.descriptor()
                    :
                        undefined,
            filterOptions:
                this.filterOptions.map(
                    filterOption =>
                        filterOption.toDescriptor())
        };
    }

    // ----------------------- Private logic ------------------------
}
