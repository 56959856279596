import moment from 'moment';

interface MonthSelection {
    id: string,
    value: number,
    label: string
}

export function getMonthSelection()
{
    const monthSelection: MonthSelection[] = []

    for (let i = 0; i < 12; i++)
    {
        monthSelection.push(
            {
                id: `${i}`,
                value: moment().month(i).month(),
                label: `${moment().month(i).format('MMMM')}`
            }
        )
    }

    return monthSelection
}
