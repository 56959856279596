import { DependencyList, useCallback, useEffect, useMemo, useState } from 'react';
import { observable, runInAction } from 'mobx';
import { useComputed } from 'mobx-react-lite';

export default function useMemoizedMap<K, V>(
    mapBuilder: () => Map<K, V> | undefined,
    areValuesEqual: (a: V, b: V) => boolean = (a, b) => (a === b),
    deps: DependencyList
): Map<K, V> | undefined
{
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const map = useMemo(mapBuilder, deps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedAreValuesEqual = useCallback(areValuesEqual, deps);
    const [ memoryMap ] = useState(() => observable.box(map));

    useEffect(
        () =>
        {
            if (map)
            {
                const newMap = new Map<K, V>(map);

                (memoryMap.get() || new Map<K, V>())
                    .forEach(
                        (valueA, key) =>
                        {
                            const valueB = map.get(key);

                            if (valueB !== undefined)
                            {
                                if (memoizedAreValuesEqual(valueA, valueB))
                                {
                                    newMap.set(key, valueA);
                                }
                            }
                        });

                runInAction(
                    () =>
                        memoryMap.set(newMap));
            }
            else
            {
                runInAction(
                    () =>
                        memoryMap.set(undefined));
            }
        },
        [
            map,
            memoryMap,
            memoizedAreValuesEqual
        ]);

    return useComputed(
        () => memoryMap.get(),
        [
            memoryMap
        ]);
}
