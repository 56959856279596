import { useComputed } from 'mobx-react-lite';
import { useCallback } from 'react';
import { SettingSource } from '../../../Setting/SettingStore';
import useSetting from '../../../Setting/Api/useSetting';
import useSetExpanded from './useSetExpanded';

export const expandedSettingCode = 'EntityDataBrowser.IsExpanded';

export default function useExpanded(id: string,
                                    disableExpansionMemory?: boolean,
                                    expanded?: boolean): [ boolean, () => void ]
{
    const [ isExpandedSetting, ] = useSetting(SettingSource.User, expandedSettingCode);
    const isExpanded =
        useComputed(
            () =>
            {
                if (expanded === undefined)
                {
                    return !disableExpansionMemory
                        && (!isExpandedSetting || !isExpandedSetting[id]);
                }
                else
                {
                    return expanded;
                }
            },
            [
                disableExpansionMemory,
                isExpandedSetting,
                id,
                expanded
            ]);
    const setExpanded = useSetExpanded();

    const toggleExpansion =
        useCallback(
            () =>
            {
                if (!disableExpansionMemory)
                {
                    setExpanded([id], !isExpanded);
                }
            },
            [
                disableExpansionMemory,
                setExpanded,
                id,
                isExpanded
            ]);

    return [
        isExpanded,
        toggleExpansion
    ];
}
