import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import FormInputPlaceholderEditor from '../Shared/FormInputPlaceholderEditor/FormInputPlaceholderEditor';
import FormInputIsDisabledEditor from '../Shared/FormInputIsDisabledEditor/FormInputIsDisabledEditor';
import EntitySelectionFormInputLayoutQueryEditor from './EntitySelectionFormInputLayoutQueryEditor';
import EntitySelectionFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/EntitySelectionFormInputLayout';
import EntitySelectionFormInputLayoutOptionLayoutEditor from './EntitySelectionFormInputLayoutOptionLayoutEditor';
import EntitySelectionFormInputLayoutSearchFieldPathsEditor from './EntitySelectionFormInputLayoutSearchFieldPathsEditor';
import FormInputParameterViewer from '../Shared/FormInputParameterViewer/FormInputParameterViewer';

export interface EntitySelectionFormInputControlsProps extends LayoutEditorProps<EntitySelectionFormInputLayout>
{

}

const EntitySelectionFormInputLayoutControls: React.FC<EntitySelectionFormInputControlsProps> =
    props =>
    {
        const { layout } = props;

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <FormInputParameterViewer
                    {...props}
                />
                <EntitySelectionFormInputLayoutQueryEditor
                    {...props}
                />
                {
                    layout.query &&
                        <EntitySelectionFormInputLayoutOptionLayoutEditor
                            {...props}
                        />
                }
                {
                    layout.query &&
                        <EntitySelectionFormInputLayoutSearchFieldPathsEditor
                            {...props}
                        />
                }
                <FormInputPlaceholderEditor
                    {...props}
                />
                <FormInputIsDisabledEditor
                    {...props}
                />
            </InputGroup>
        </CardInset>;
    };

export default observer(EntitySelectionFormInputLayoutControls);
