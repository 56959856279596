import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import Inset from '../Inset/Inset';
import useTypes from '../../../Type/Api/useTypes';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTextEntityValue from '../../../../../../@Api/Entity/Hooks/useTextEntityValue';
import Click from '../Click/Click';
import useRelatedEntity from '../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import Caption from '../../Caption/Caption';

export interface EmailClickProps
{
    entity: Entity;
}

const EmailClick: React.FC<EmailClickProps> =
    props =>
    {
        const types = useTypes();

        const linkUrl =
            useTextEntityValue(
                props.entity,
                types.EmailClick.Field.LinkUrl);

        const creationDate =
            useTextEntityValue(
                props.entity,
                types.Entity.Field.CreationDate);

        const activity =
            useRelatedEntity(
                props.entity,
                types.Activity.Email.RelationshipDefinition.Clicks,
                true);

        return <Click
            open
            allowExpansionHighlight
        >
            <Inset>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={5}
                            alignment="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                {linkUrl}
                            </ViewGroupItem>
                            <ViewGroupItem>
                                {creationDate}
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    {
                        activity &&
                            <ViewGroupItem>
                                <Caption
                                    entity={activity}
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </Inset>
        </Click>;
    };

export default observer(EmailClick);
