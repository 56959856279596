import Layout from '../../../../../@Api/Layout/Layout';
import getLayoutFromDescriptor from '../../../../../@Api/Layout/Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../@Api/Layout/LayoutDependencyContext';

export async function cloneLayout(
    layout: Layout,
    dependencyContext: LayoutDependencyContext
): Promise<Layout>
{
    return getLayoutFromDescriptor(
        layout.toDescriptor(),
        dependencyContext
    );
}
