import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import styles from './TypeItem.module.scss';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Switch from '../../../../../@Future/Component/Generic/Input/Switch/Switch';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { textSecondaryColor } from '../../../../../@Resource/Theme/Theme';
import useSetting from '../../../Setting/Api/useSetting';
import { SettingSource } from '../../../Setting/SettingStore';
import { openEntityTypeConfiguration } from '../../../Entity/@Util/openEntityTypeConfiguration';
import { Setting } from '../../../../../@Api/Settings/Setting';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import { openEntityTypeWithHiddenEntities } from '../../../Entity/@Util/openEntityTypeWithHiddenEntities';
import { createStringComparator } from '../../../../Generic/List/V2/Comparator/StringComparator';
import useDividerGlue from '../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import { Box } from '@material-ui/core';
import useToggle from '../../../../../@Util/Toggle/useToggle';

export interface TypeItemProps
{
    type: EntityType;
    depth: number;
    filter: (childType: EntityType) => boolean;
    allowConfig?: boolean;
}

const TypeItem: React.FC<TypeItemProps> =
    props =>
    {
        const openType =
            useCallback(
                () =>
                    openEntityTypeWithHiddenEntities(props.type),
                [
                    props.type
                ]);

        const openConfiguration =
            useCallback(
                () =>
                    openEntityTypeConfiguration(props.type),
                [
                    props.type
                ]);

        const [ hiddenTypes, setHiddenTypes ] = useSetting(SettingSource.Organization, Setting.Metadata.HiddenTypes);
        const isHidden =
            useComputed(
                () =>
                    hiddenTypes[props.type.id],
                [
                    hiddenTypes,
                    props.type
                ]);

        const setHidden =
            useCallback(
                (isVisible, event) =>
                {
                    const hiddenTypesCopy = Object.assign({}, hiddenTypes);

                    if (isVisible)
                    {
                        delete hiddenTypesCopy[props.type.id];
                    }
                    else
                    {
                        hiddenTypesCopy[props.type.id] = true;
                    }

                    setHiddenTypes(hiddenTypesCopy);

                    event.stopPropagation();
                },
                [
                    hiddenTypes,
                    props.type,
                    setHiddenTypes
                ]);
        const childTypes =
            useComputed(
                () =>
                    props.type.childTypes
                        .filter(
                            type =>
                                props.filter(type)
                        )
                        .sort(
                            createStringComparator(
                                a =>
                                    a.getName()
                            )
                        ),
                [
                    props.type,
                    props.filter,
                ]);
        const dividerGlue = useDividerGlue();
        const [ isOpen, toggleOpen ] = useToggle(true);

        return <ViewGroup
            orientation="vertical"
            spacing={0}
            glue={dividerGlue}
        >
            <ViewGroupItem>
                <div
                    className={styles.root}
                    onClick={props.allowConfig ? openConfiguration : openType}
                >
                    <ViewGroup
                        orientation="horizontal"
                        spacing={10}
                        alignment="center"
                    >
                        {
                            props.depth > 0 &&
                            <ViewGroupItem>
                                <Box
                                    paddingLeft={props.depth * (2 + (childTypes.length === 0 ? 2 : 0))}
                                />
                            </ViewGroupItem>
                        }
                        {
                            childTypes.length > 0 &&
                            <ViewGroupItem>
                                <IconButton
                                    onClick={toggleOpen}
                                    icon={
                                        isOpen
                                            ? 'keyboard_arrow_down'
                                            : 'keyboard_arrow_right'
                                    }
                                    tooltip={null}
                                />
                            </ViewGroupItem>
                        }
                        <ViewGroupItem
                            ratio={1}
                        >
                            {props.type.getName()}
                        </ViewGroupItem>
                        {
                            props.allowConfig &&
                                <ViewGroupItem>
                                    <IconButton
                                        icon="remove_red_eye"
                                        tooltip={
                                            <LocalizedText
                                                code="Configuration.ShowEntitiesOfType"
                                                value="${typeName} weergeven"
                                                typeName={props.type.getName(true)}
                                            />
                                        }
                                        onClick={openType}
                                        color={textSecondaryColor}
                                    />
                                </ViewGroupItem>
                        }
                        <ViewGroupItem>
                            <Switch
                                checked={!isHidden}
                                onToggle={setHidden}
                                large
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </div>
            </ViewGroupItem>
            {
                isOpen && childTypes.length > 0 &&
                <ViewGroupItem>
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                        glue={dividerGlue}
                    >
                        {
                            childTypes.map(
                                childType =>
                                    <ViewGroupItem
                                        key={childType.id}
                                    >
                                        <TypeItem
                                            type={childType}
                                            depth={props.depth + 1}
                                            filter={props.filter}
                                            allowConfig={props.allowConfig}
                                        />
                                    </ViewGroupItem>)
                        }
                    </ViewGroup>
                </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(TypeItem);
