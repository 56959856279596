import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import FunctionContext from '../FunctionContext';
import Value from '../../Value/Value';
import EmptyValue from '../../Value/EmptyValue';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import localizeText from '../../../Localization/localizeText';
import MergedPdfComputationPdf from './MergedPdfComputationPdf';

export default class MergedPdfComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.shallow pdfs: MergedPdfComputationPdf[];
    @observable.ref fileName: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(pdfs: MergedPdfComputationPdf[],
                fileName: Computation<any, any>)
    {
        super();

        this.pdfs = pdfs;
        this.fileName = fileName;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('File'));
    }

    getName(): string
    {
        return localizeText('Computation.MergedPdf', 'Samengevoegde PDF');
    }

    isAsync(): boolean
    {
        return this.pdfs.some(
            pdf =>
                pdf.isAsync()
        );
    }

    async apply(context: FunctionContext)
    {
        return this.synchronousApply(context);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        return EmptyValue.instance;
    }

    validate(): Validation[]
    {
        return [
            ...this.pdfs
                .map(
                    pdf =>
                        pdf.validate())
                .reduce((a, b) => a.concat(b), []),
            ...this.fileName.validate()
        ];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'MergedPdf';
        descriptor.pdfs = this.pdfs.map(pdf => pdf.toDescriptor());
        descriptor.fileName = this.fileName.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.pdfs.map(
                pdf =>
                    pdf.getDependencies())
                .reduce((a, b) => a.concat(b), []),
            ...this.fileName.getDependencies()
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new MergedPdfComputation(
            await Promise.all(
                descriptor.pdfs.map(
                    pdf =>
                        MergedPdfComputationPdf.fromDescriptor(
                            pdf,
                            dependencyContext))),
            await getComputationFromDescriptor(
                descriptor.fileName,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
