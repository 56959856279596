import { Entity } from '../../../Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';
import useTypes from '../../../../@Component/Domain/Entity/Type/Api/useTypes';

export default function useParentRelation(entity: Entity)
{
    const types = useTypes();

    return useComputed(
        () =>
            entity &&
                entity.getRelatedEntityByDefinition(
                    true,
                    types.Relation.RelationshipDefinition.Relationships),
        [
            entity
        ]);
}
