import { getAdministration, getModel, isLocallyDirty, isTransactionalModel, TransactionalModel as NewTransactionalModel } from '../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { EntityRelationship } from '../../Model/Implementation/EntityRelationship';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityEvent } from '../../Model/Implementation/EntityEvent';
import { consoleLog } from '../../../@Future/Util/Logging/consoleLog';
import { EntityCreationMutation } from '../../Model/Implementation/EntityCreationMutation';
import { EntityValueUpdateMutation } from '../../Model/Implementation/EntityValueUpdateMutation';
import { Entity } from '../../Model/Implementation/Entity';
import getRelationshipDescriptor from './getRelationshipDescriptor';
import isEntityValid from '../Validation/isEntityValid';
import { CommitContext } from '../Commit/Context/CommitContext';
import { EntityValue } from '../../Model/Implementation/EntityValue';

export default function getEntityDescriptor(entity: NewTransactionalModel<Entity>,
                                            sourceRelationship?: EntityRelationship,
                                            seenObjects: Set<string> = new Set<string>(),
                                            backReferences: Set<string> = new Set<string>(),
                                            isEntityValidByUuid: Map<string, boolean> = new Map<string, boolean>(),
                                            numberOfMutationsByObject: Map<Entity | EntityRelationship, number> = new Map<Entity | EntityRelationship, number>(),
                                            path: EntityPath = entity && EntityPath.root(entity.entityType),
                                            onFile?: (fileId: string, file: File) => void,
                                            isDebug: boolean = false,
                                            events: EntityEvent[] = [],
                                            commitContext?: CommitContext)
{
    const entityId = entity && `Entity.${entity.uuid}`;

    const isValid =
        entity == null
            ?
                false
            :
                isEntityValid(
                    entity,
                    {
                        isValidByEntityUuid: isEntityValidByUuid,
                        isDeep: false,
                        commitContext,
                    });

    if (isDebug)
    {
        consoleLog('checking',
            path && path.code,
            entity,
            'undef:',
            entity == null,
            'seen: ',
            seenObjects.has(entityId),
            'newAndInvalid:',
            entity.isNew() && !isValid,
            'deleted:',
            entity.isDeleted,
            '=',
            entity == null || seenObjects.has(entityId) || (entity.isNew() && !isValid) || entity.isDeleted,
        );
    }

    if (entity == null || seenObjects.has(entityId) || (entity.isNew() && !isValid) || entity.isDeleted)
    {
        // !entity.isManaged
        return undefined;
    }
    else
    {
        seenObjects.add(entityId);
        isEntityValidByUuid.set(entity.uuid, isValid);

        const originalModel = getModel(entity);
        const values: any[] = [];

        if (entity.isNew())
        {
            events.push(
                new EntityCreationMutation(
                    originalModel));
        }

        if (commitContext)
        {
            entity.getEditableFields()
                .filter(
                    field =>
                        commitContext.hasValue(
                            entity,
                            field
                        )
                )
                .forEach(
                    field =>
                    {
                        const value = entity.getValueByField(field, false, false);
                        const newValue =
                            new EntityValue(
                                value.id,
                                entity,
                                field,
                                commitContext.getValue(entity, field)!
                            );

                        events.push(
                            new EntityValueUpdateMutation(
                                originalModel,
                                newValue.field,
                                newValue,
                                originalModel.getDataObjectValueByField(newValue.field).clone(),
                                newValue.dataObject.clone()));

                        values.push(newValue.descriptor(onFile));
                    }
                );
        }
        else
        {
            entity.getEditableFields()
                // Do not consider empty values which were already empty
                .filter(
                    field =>
                        !(!originalModel.hasValueForField(field) && !entity.hasValueForField(field)))
                // Ensure that empty values which were non-existent in the original model are not seen as change
                // .filter(field => !(isTransactionalModel(entity) && !originalModel.hasValueForField(field)))
                .map(
                    field =>
                        entity.getValueByField(field))
                .filter(
                    value =>
                        // E.g. in case of fields modified before entity is made transactional (such as the sort index),
                        // or a new value is added after the entity was made transactional
                        // We exclude static fields in the case the new values are here, because static field values are
                        // always seen as 'new'
                        ((entity.isNew() || (value.isNew && !value.field.isStaticField())) && !value.dataObject.isEmpty) || // In any other case
                        (!isTransactionalModel(value.dataObject) || (isLocallyDirty(value.dataObject) && // Only when value is dirty, it might be that in some cases the specification is dirty (see setSpecification in EntityValueEditorStore), but we do not want to save that
                            (getAdministration(value.dataObject)
                                .dirtyValues
                                .has('value') || getAdministration(value.dataObject)
                                .deletedValues
                                .has('value')))))
                .forEach(
                    value =>
                    {
                        events.push(
                            new EntityValueUpdateMutation(
                                originalModel,
                                value.field,
                                value,
                                originalModel.getDataObjectValueByField(value.field).clone(),
                                value.dataObject.clone()));

                        values.push(value.descriptor(onFile));
                    });
        }

        if (isDebug)
        {
            consoleLog('posting values for', entity.entityType.code, entity, getModel(entity), values, entity.editableValues
            // Ensure that empty values which were non-existent in the original model are not seen as change
                .filter(value => !(isTransactionalModel(entity) && !originalModel.hasValueForField(value.field) && value.dataObject.isEmpty))
                .map(value => ({
                    ...value.descriptor(onFile),
                    origin: value,
                    booleanA: (entity.isNew() || (value.isNew && !value.field.isStaticField())) && !value.dataObject.isEmpty,
                    booleanB: !isTransactionalModel(value.dataObject) || isLocallyDirty(value.dataObject),
                })));
        }

        const childBackReferences = new Set<string>(backReferences);
        childBackReferences.add(entityId);

        const childRelationships: any[] =
            (
                commitContext
                    ? commitContext.getCreatedOrUpdatedRelationshipsByEntity(entity, false)
                    : entity.childRelationships
            )
                .filter(relationship => relationship !== sourceRelationship)
                .map(
                    relationship =>
                        getRelationshipDescriptor(
                            relationship as NewTransactionalModel<EntityRelationship>,
                            false,
                            seenObjects,
                            childBackReferences,
                            isEntityValidByUuid,
                            numberOfMutationsByObject,
                            path.joinTo(relationship.definition, false),
                            onFile,
                            isDebug,
                            entity.doCheckTransactionalRelationship(false, relationship),
                            events,
                            commitContext
                        )
                )
                .filter(relationship => relationship != null);
        const deletedChildRelationships =
            (
                commitContext
                    ? commitContext.getDeletedRelationshipsByEntity(entity, false)
                    : entity.deletedChildRelationships
            )
                .filter(relationship => !relationship.isNew())
                .map(
                    deletedRelationship => ({
                        id: deletedRelationship.id,
                        definitionId: deletedRelationship.definition.id,
                    } as any)
                );

        const parentRelationships: any[] =
            (
                commitContext
                    ? commitContext.getCreatedOrUpdatedRelationshipsByEntity(entity, true)
                    : entity.parentRelationships
            )
                .filter(relationship => relationship !== sourceRelationship)
                .map(
                    relationship =>
                        getRelationshipDescriptor(
                            relationship as NewTransactionalModel<EntityRelationship>,
                            true,
                            seenObjects,
                            childBackReferences,
                            isEntityValidByUuid,
                            numberOfMutationsByObject,
                            path.joinTo(relationship.definition, true),
                            onFile,
                            isDebug,
                            entity.doCheckTransactionalRelationship(true, relationship),
                            events,
                            commitContext
                        )
                )
                .filter(relationship => relationship != null);
        const deletedParentRelationships =
            (
                commitContext
                    ? commitContext.getDeletedRelationshipsByEntity(entity, true)
                    : entity.deletedParentRelationships
            )
                .filter(relationship => !relationship.isNew())
                .map(
                    deletedRelationship => ({
                        id: deletedRelationship.id,
                        definitionId: deletedRelationship.definition.id,
                    })
                );

        const numberOfMutations = values.length + deletedParentRelationships.length + deletedChildRelationships.length + (entity.isNew() ? 1 : 0);

        if (isValid && (entity.isNew() || numberOfMutations > 0 || parentRelationships.length > 0 || childRelationships.length > 0))
        {
            numberOfMutationsByObject.set(entity, numberOfMutations);

            return {
                id: entity.id,
                uuid: entity.uuid,
                entityTypeId: entity.entityType.id,
                entityTypeCode: entity.entityType.code,
                parentRelationships: deletedParentRelationships.concat(...parentRelationships),
                childRelationships: deletedChildRelationships.concat(...childRelationships),
                values: values,
            };
        }
        else
        {
            return undefined;
        }
    }
}
