import { DataObjectBespokeEditor } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { renderTextField } from '../TextEditor';
import { observer } from 'mobx-react';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { TextEditorStore } from '../TextEditorStore';

@observer
export class PhoneNumberEditor extends DataObjectBespokeEditor<TextEditorStore>
{
    renderComponent(store: DataObjectEditorStore<TextEditorStore>): any
    {
        return renderTextField(store, 'tel');
    }
}
