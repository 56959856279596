import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import CurrentUserContext from '../User/CurrentUserContext';
import useTypes from '../Entity/Type/Api/useTypes';
import OnboardingManagerContent from './Content/Content';
import RouterContext from '../../../@Service/Router/RouterContext';
import useIsMobile from '../../../@Util/Responsiveness/useIsMobile';

export interface OnboardingManagerProps
{

}

const OnboardingManager: React.FC<OnboardingManagerProps> =
    props =>
    {
        const routerStore = useContext(RouterContext);
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();
        const isMobile = useIsMobile();

        const isOnboarding =
            useComputed(
                () =>
                    (routerStore.path.startsWith('/dashboard') || routerStore.path === '/')
                    && currentUserStore.environmentEntity.getObjectValueByField(types.Relation.Organization.Environment.Field.IsOnboarding)
                    && !isMobile,
                [
                    routerStore,
                    currentUserStore,
                    types,
                    isMobile
                ]);

        if (isOnboarding)
        {
            return <OnboardingManagerContent />;
        }
        else
        {
            return null;
        }
    };

export default observer(OnboardingManager);
