import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutActionEditorProps } from '../../LayoutActionEditor';
import { runInAction } from 'mobx';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import ActionTemplateInvocationAction from '../../../../../../@Api/Layout/Action/Type/ActionTemplateInvocationAction';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import useEntityByUuid from '../../../../../../@Api/Entity/Bespoke/useEntityByUuid';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import Selectbox from '../../../../Entity/Selectbox/Selectbox';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import DynamicParameterAssignment from '../../../../../../@Api/Automation/Function/Dynamic/DynamicParameterAssignment';
import getActionSignatureById from '../../../../../../@Api/Automation/ActionTemplate/Api/getActionSignatureById';
import { EntitySelectionBuilder } from '../../../../Entity/Selection/Builder/EntitySelectionBuilder';
import DynamicParameterAssignmentEditor from '../../../../Entity/Viewer/Content/Automation/Editor/DynamicParameterAssignment/DynamicParameterAssignmentEditor';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import EditIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/EditIconButton/EditIconButton';
import useDialog from '../../../../../../@Service/Navigation/Page/Hooks/useDialog';
import ActionTemplateEditor from './ActionTemplateEditor/ActionTemplateEditor';
import ActionSignature from '../../../../../../@Api/Automation/ActionTemplate/Model/ActionSignature';
import EmptyValueType from '../../../../../../@Api/Automation/Value/Type/EmptyValueType';
import Dialog from '../../../../../../@Future/Component/Generic/Dialog/Dialog';

export interface ActionTemplateInvocationActionEditorProps extends LayoutActionEditorProps<ActionTemplateInvocationAction>
{

}

const ActionTemplateInvocationActionEditor: React.FC<ActionTemplateInvocationActionEditorProps> =
    props =>
    {
        const { action, context } = props;

        const types = useTypes();
        const [ actionTemplate, isLoading ] = useEntityByUuid(types.ActionTemplate.Type, action.actionSignature?.id);
        const setActionTemplate =
            useCallback(
                async (value?: Entity) =>
                {
                    if (value?.hasValueForField(types.ActionTemplate.Field.Specification))
                    {
                        const signature = await getActionSignatureById(value.uuid, context);

                        runInAction(
                            () =>
                            {
                                action.parameterAssignment = action.parameterAssignment.getWithNewParameters(signature.parameters);
                                action.actionSignature = signature;
                            });
                    }
                    else
                    {
                        runInAction(
                            () =>
                            {
                                action.parameterAssignment = new DynamicParameterAssignment();
                                action.actionSignature =
                                    value
                                        ?
                                            new ActionSignature(
                                                value.uuid,
                                                new ParameterDictionary([]),
                                                EmptyValueType.instance)
                                        :
                                            undefined;
                            });
                    }
                },
                [
                    action,
                    types,
                    context
                ]);
        const actionTemplateSelection =
            useMemo(
                () => [
                    new EntitySelectionBuilder(types.ActionTemplate.Type)
                        .build()
                ],
                [
                    types
                ]);
        const parameters =
            useComputed(
                () =>
                    action.actionSignature?.parameters || new ParameterDictionary([]),
                [
                    action
                ]);

        const [ openEditor ] =
            useDialog(
                close =>
                    <Dialog
                        open
                        onClose={close}
                        width="xl"
                    >
                        <ActionTemplateEditor
                            entity={actionTemplate}
                            onCancel={close}
                            onSave={
                                async () =>
                                {
                                    const signature = await getActionSignatureById(actionTemplate.uuid, context);

                                    runInAction(
                                        () =>
                                            action.actionSignature = signature);

                                    close();
                                }
                            }
                        />
                    </Dialog>,
                [
                    actionTemplate,
                    context,
                    action
                ]);

        return <CardInset
            top={false}
        >
            <Input
                label={
                    <LocalizedText
                        code="Generic.Action"
                        value="Actie"
                    />
                }
                labelPosition="left"
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <Selectbox
                            value={actionTemplate}
                            onChange={setActionTemplate}
                            selections={actionTemplateSelection}
                            genericSelectboxProps={{
                                disabled: isLoading
                            }}
                        />
                    </ViewGroupItem>
                    {
                        actionTemplate &&
                            <ViewGroupItem>
                                <EditIconButton
                                    onClick={openEditor}
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </Input>
            <DynamicParameterAssignmentEditor
                context={context}
                parameterDictionary={parameters}
                parameterAssignment={action.parameterAssignment}
            />
        </CardInset>;
    };

export default observer(ActionTemplateInvocationActionEditor);
