import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../Model/Implementation/Entity';
import { useContext } from 'react';
import LocalizationContext from '../../../@Service/Localization/LocalizationContext';
import useTypes from '../../../@Component/Domain/Entity/Type/Api/useTypes';

export default function useInactiveName(entity: Entity)
{
    const types = useTypes();
    const localizationStore = useContext(LocalizationContext);

    return useComputed(
        () =>
        {
            if (entity.entityType.isA(types.Relation.Organization.Type))
            {
                return localizationStore.translate('EntityViewer.Label.Liquidated'); // Liquidated
            }
            else if (entity.entityType.isA(types.Relation.Person.Type))
            {
                return localizationStore.translate('EntityViewer.Label.Deceased'); // Deceased
            }
            else
            {
                return undefined;
            }
        },
        [
            entity,
            localizationStore,
            types
        ]);
}
