import { action, computed, observable } from 'mobx';
import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { ExternalPicture } from '../../../../../@Api/Model/Implementation/ExternalPicture';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { ApiClient } from '../../../../../@Service/ApiClient/ApiClient';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { ExternalPictureController } from '../../../../../@Api/Controller/Directory/ExternalPictureController';
import { EntityField } from '../../../../../@Api/Model/Implementation/EntityField';
import { FileValue, FileValueType } from '../../../DataObject/Type/File/FileValue';
import { DataObjectStore } from '../../../DataObject/DataObjectStore';
import { EntityValue } from '../../../../../@Api/Model/Implementation/EntityValue';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { debounce } from '../../../../../@Util/Promise/PromiseUtils';
import convertDataUrlToFile from '../../../../../@Util/File/convertDataUrlToFile';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { EntityAvatarEditorImage } from './Model/EntityAvatarEditorImage';

export class EntityAvatarEditorStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ApiClient') apiClient: ApiClient;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('EntityPictureController') entityPictureController: ExternalPictureController;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable.ref commitContext: CommitContext;
    @observable.ref entity: Entity;
    @observable size: number;
    @observable dropZoneReference: any;
    @observable editorReference: any;
    @observable zoomLevel: number = 1.0;
    @observable selectedFile: FileValue | ExternalPicture;
    @observable canSave: boolean = false;
    @observable saveCallback: () => void;
    @observable deleteCallback: () => void;
    @observable doAutoSave: boolean;
    @observable isChange: boolean;

    // ------------------------ Constructor -------------------------

    constructor(
        commitContext: CommitContext,
        entity: Entity,
        size: number,
        saveCallback: () => void,
        deleteCallback: () => void,
        doAutoSave: boolean = false
    )
    {
        super();

        this.commitContext = commitContext;
        this.entity = entity;
        this.size = size;
        this.saveCallback = saveCallback;
        this.deleteCallback = deleteCallback;
        this.doAutoSave = doAutoSave;
    }

    // ----------------------- Initialization -----------------------

    initialize?(): Promise<any>
    {
        this.entity.getValueByField(this.avatarField, true);

        this.setSelectedFile(
            this.entityValue.dataObject.value,
            false);

        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    @computed
    get avatarField(): EntityField
    {
        return this.entity.entityType.getInheritedAvatarField();
    }

    @computed
    get entityValue(): EntityValue
    {
        return this.entity.getValueByField(this.avatarField);
    }

    @computed
    get imageUrl(): string
    {
        if (this.selectedFile)
        {
            return this.selectedFile.url;
        }

        return undefined;
    }

    @computed
    get image(): EntityAvatarEditorImage | undefined
    {
        if (this.selectedFile)
        {
            if (this.selectedFile instanceof ExternalPicture)
            {
                // Return proxy url of selected image
                return {
                    type: 'ProxyUrl',
                    url:
                        ExternalPicture.getProxyUrl(
                            this.apiClient,
                            this.selectedFile.url,
                            this.selectedFile.hmac
                        ),
                };
            }
            else if (this.selectedFile instanceof FileValue)
            {
                if (this.selectedFile.file)
                {
                    // Return uploaded file
                    return {
                        type: 'File',
                        file: this.selectedFile.file,
                    };
                }
                else
                {
                    // Return dataobject value
                    return {
                        type: 'ApiUrl',
                        url: this.entityValue.dataObject.context.getFileUrl(this.entityValue.dataObject.value.url)
                    };
                }
            }
            else
            {
                return undefined;
            }
        }
        else
        {
            // If can save is true, meaning that the file has been changed, then return no file
            if (this.canSave)
            {
                // return 1x1 transparent pixel (editor can not be cleared, so set an empty image)
                return {
                    type: 'DataUrl',
                    url: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
                };
            }
            else
            {
                let entityPictureUrl =
                    this.entityValue.dataObject.context.getFileUrl(
                        this.entityValue.dataObject.value && this.entityValue.dataObject.value.url
                    );

                // Return entity picture url OR a 1x1 transparent pixel (editor can not be cleared, so set an empty image)
                if (!entityPictureUrl)
                {
                    return {
                        type: 'DataUrl',
                        url: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
                    };
                }
                else
                {
                    return {
                        type: 'ApiUrl',
                        url: entityPictureUrl,
                    };
                }
            }
        }
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    setDropZoneReference(dropZone: any)
    {
        this.dropZoneReference = dropZone;
    }

    @action.bound
    onFileUpload(accepted: File[], rejected: File[])
    {
        this.setSelectedFile(
            new FileValue(
                FileValueType.File,
                accepted[0].name,
                undefined,
                undefined,
                undefined,
                accepted[0].type,
                accepted[0],
                undefined),
            true);
    }

    @action.bound
    onCanvasImageUpdate(isDebounced: boolean = true)
    {
        if (this.selectedFile && this.doAutoSave) // && isChange)
        {
            if (isDebounced)
            {
                if (!(this as any)['canvasImageUpdateDebounced'])
                {
                    (this as any)['canvasImageUpdateDebounced'] =
                        debounce(
                            () =>
                                this.onCanvasImageUpdate(false),
                            1000);
                }

                return (this as any)['canvasImageUpdateDebounced']();
            }

            this.setCanSave(true);
            this.saveAvatar();
        }
    }

    @action.bound
    setEditorReference(editor: any)
    {
        this.editorReference = editor;
    }

    @action.bound
    setSelectedFile(file: ExternalPicture | FileValue,
                    isChange: boolean)
    {
        this.zoomLevel = 1.0;
        this.selectedFile = file;
        this.isChange = isChange;
        this.setCanSave(true);
    }

    @action.bound
    zoomAvatar(event: any)
    {
        let newZoomLevel = this.zoomLevel - (event.deltaY / 1000);

        if (newZoomLevel >= 1)
        {
            this.zoomLevel = newZoomLevel;
        }
    }
    @action.bound
    setZoomLevel(level: number)
    {
        this.zoomLevel = level;
    }

    @action.bound
    saveAvatar()
    {
        if (this.canSave)
        {
            this.setCanSave(false);

            if (this.selectedFile)
            {
                let dataUrl: string;
                let filename: string;

                if (this.selectedFile instanceof ExternalPicture)
                {
                    dataUrl = this.editorReference.getImageScaledToCanvas().toDataURL();
                    filename = `EntityPicture.png`;
                }
                else
                {
                    dataUrl = this.editorReference.getImageScaledToCanvas().toDataURL(this.selectedFile.type);
                    filename = this.selectedFile.name;
                }

                const file = convertDataUrlToFile(dataUrl, filename);

                // Create file preview
                (file as any).preview = window.URL.createObjectURL(file);

                const newValue =
                    new FileValue(
                        FileValueType.File,
                        filename,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        file,
                        file.size
                    );

                setValueByFieldInEntity(
                    this.entity,
                    this.avatarField,
                    newValue,
                    this.commitContext
                );
            }
            else
            {
                setValueByFieldInEntity(
                    this.entity,
                    this.avatarField,
                    undefined,
                    this.commitContext
                );
            }
        }

        if (this.saveCallback)
        {
            this.saveCallback();
        }
    }

    @action.bound
    deleteAvatar()
    {
        this.setSelectedFile(undefined, false);
    }

    @action.bound
    openUploadDialog()
    {
        if (this.dropZoneReference)
        {
            this.dropZoneReference.open();
        }
    }

    @action.bound
    setCanSave(canSave: boolean)
    {
        this.canSave = canSave;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
