import { EntityPath } from './EntityPath';
import { EntityField } from '../../../../../@Api/Model/Implementation/EntityField';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntityFieldGroup } from '../../../Management/Application/EntityTypesEditor/EntityFieldGroup';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { DataObjectStore } from '../../../DataObject/DataObjectStore';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityRelationship } from '../../../../../@Api/Model/Implementation/EntityRelationship';
import cached from '../../../../../@Util/Cached/cached';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { CommitMutationOptions } from '../../../../../@Api/Entity/Commit/Context/Model/CommitMutationOptions';

export class EntityFieldPath
{
    // ------------------------- Properties -------------------------

    path: EntityPath;
    field: EntityField;

    // ------------------------ Constructor -------------------------

    constructor(path: EntityPath,
                field?: EntityField)
    {
        this.path = path;
        this.field = field;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    /**
     * Gets the unique code of this entity path for a user friendly description.
     *
     * @returns unique code of this entity path
     */
    @cached
    get code(): string
    {
        return this.path.code + (this.field == null ? '' : `->${this.field.code}`);
    }

    @cached
    get isField(): boolean
    {
        return this.field !== undefined
            && this.field !== null;
    }

    @cached
    get isRelationship(): boolean
    {
        return !this.isField && this.relationshipDefinition !== undefined;
    }

    @cached
    get isComputed(): boolean
    {
        if (this.isField)
        {
            return this.field.isComputedField
        }
        else if (this.isRelationship)
        {
            return this.relationshipDefinition.isComputed(this.isParentRelationship);
        }
        else
        {
            return false;
        }
    }

    @cached
    get isParentRelationship(): boolean
    {
        return this.isRelationship
            && this.path.lastJoinNode.isParent;
    }

    @cached
    get relationshipDefinition(): EntityRelationshipDefinition
    {
        if (this.path && this.path.lastJoinNode)
        {
            return this.path.lastJoinNode.relationshipDefinition;
        }
        else
        {
            return undefined;
        }
    }

    @cached
    get basePath(): EntityPath
    {
        if (this.path && this.path.lastJoinNode)
        {
            return this.path.getPathUntilNode(this.path.lastJoinNode);
        }
        else
        {
            return this.path;
        }
    }

    @cached
    get id(): string
    {
        return `${this.path ? this.path.id : ''}${this.field ? `:${this.field ? (this.field.isStaticField() ? this.field.code : this.field.id) : ''}` : ''}`;
    }

    @cached
    get sortIndex(): number
    {
        if (this.field)
        {
            if (this.field.entity)
            {
                return this.field.entity.sortIndex;
            }
            else
            {
                return this.field.sortIndex;
            }
        }
        else if (this.relationshipDefinition)
        {
            if (this.relationshipDefinition.entity)
            {
                return this.relationshipDefinition.entity.sortIndex;
            }
            else
            {
                return this.relationshipDefinition.sortIndex;
            }
        }
        else
        {
            return undefined;
        }
    }

    @cached
    get descriptor()
    {
        return {
            nodes: this.path.descriptor,
            fieldId: this.field ? (this.field.isStaticField() ? undefined : this.field.id) : undefined,
            fieldCode: this.field ? (this.field.isStaticField() ? this.field.code : undefined) : undefined
        };
    }

    @cached
    get entityType()
    {
        if (this.isRelationship)
        {
            return this.path
                .getPathUntilNode(this.path.lastJoinNode)
                .entityType;
        }
        else
        {
            return this.path.entityType;
        }
    }

    @cached
    get typeThatDefinesField()
    {
        if (this.isField)
        {
            return this.field.entityType;
        }
        else
        {
            return this.relationshipDefinition.getEntityType(!this.isParentRelationship);
        }
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static construct(descriptor: any,
                     entityTypeStore: EntityTypeStore = loadModuleDirectly(EntityTypeStore))
    {
        const path =
            EntityPath.construct(
                descriptor.nodes,
                entityTypeStore
            );

        if (path)
        {
            if (descriptor.fieldId !== undefined || descriptor.fieldCode !== undefined)
            {
                const field =
                    entityTypeStore.getFieldByIdOrCode(
                        descriptor.fieldId,
                        descriptor.fieldCode
                    );

                if (field)
                {
                    return path.field(field);
                }
                else
                {
                    throw new Error(`Field with ID/code: ${descriptor.fieldId ?? descriptor.fieldCode} not found`);
                }
            }
            else
            {
                return path.field();
            }
        }
        else
        {
            return undefined;
        }
    }

    /**
     * Gets the name of the entity field path.
     */
    getAdministrativeName( entityTypeStore: EntityTypeStore, includeRootNode: boolean = true): string
    {
        let names: string[] = [];

        if (this.path)
        {
            let pathName = this.path.getName(entityTypeStore, includeRootNode);

            if (pathName !== '...')
            {
                names.push(
                    pathName);
            }
        }

        if (this.field)
        {
            names.push(this.field.name);
        }

        return names.join(' › ');
    }

    getNewDataObject(dataObjectStore: DataObjectStore,
                     value?: any): DataObject
    {
        if (this.field)
        {
            return DataObject.constructFromValue(
                this.field.dataObjectSpecification,
                value);
        }
        else
        {
            return DataObject.constructFromTypeIdAndValue(
                'Entity',
                value,
                dataObjectStore);
        }
    }

    getDataObject(entity: Entity,
                  commitContext?: CommitContext): DataObject
    {
        const relatedEntities = this.path.traverseEntity(entity, commitContext);
        const relatedEntity = relatedEntities.find(() => true);

        if (this.field)
        {
            if (relatedEntity)
            {
                return relatedEntity.getDataObjectValueByField(
                    this.field,
                    undefined,
                    commitContext
                );
            }
            else
            {
                return DataObject.constructFromValue(
                    this.field.dataObjectSpecification,
                    undefined
                );
            }
        }
        else
        {
            return DataObject.constructFromTypeIdAndValue(
                'Entity',
                relatedEntity
            );
        }
    }

    getObjectValue(entity: Entity,
                   commitContext?: CommitContext): any
    {
        const relatedEntities = this.path.traverseEntity(entity, commitContext);

        if (this.field)
        {
            if (relatedEntities.length > 0)
            {
                return relatedEntities[0].getObjectValueByField(
                    this.field,
                    commitContext
                );
            }
            else
            {
                return undefined;
            }
        }
        else
        {
            return relatedEntities;
        }
    }

    getDataObjectValue(
        entity: Entity,
        commitContext?: CommitContext
    ): DataObject | undefined
    {
        if (this.path && this.field)
        {
            const relatedEntities = this.path.traverseEntity(entity, commitContext);

            if (relatedEntities.length > 0)
            {
                return relatedEntities[0].getDataObjectValueByField(
                    this.field,
                    undefined,
                    commitContext
                );
            }
            else
            {
                return undefined;
            }
        }
        else
        {
            return undefined;
        }
    }

    getRelationships(entity: Entity): EntityRelationship[]
    {
        if (this.path && this.relationshipDefinition)
        {
            return this.path.getPathUntilNode(this.path.lastJoinNode)
                .traverseEntity(entity)
                .map(
                    relatedEntity =>
                        relatedEntity.getRelationshipsByDefinition(
                            this.isParentRelationship,
                            this.relationshipDefinition))
                .reduce((a, b) => a.concat(b), []);
        }
        else
        {
            return [];
        }
    }

    getName(entityTypeStore: EntityTypeStore = loadModuleDirectly(EntityTypeStore)): string
    {
        if (this.field)
        {
            return this.field.getName(this.path.entityType, entityTypeStore);
        }
        else
        {
            if (this.path.lastJoinNode)
            {
                return this.path.lastJoinNode
                    .relationshipDefinition
                    .getName(
                        this.path.lastJoinNode.isParent,
                        this.path.getPathUntilNode(this.path.lastJoinNode).entityType,
                        entityTypeStore);
            }
            else
            {
                return null;
            }
        }
    }

    getPathName(includeRootNode: boolean = false)
    {
        const pathName = this.path.getName(undefined, includeRootNode, true);
        const name = this.isField ? this.getName() : '';

        if (pathName.length > 0 && pathName !== '...')
        {
            if (name && name !== '')
            {
                return `${pathName} › ${name}`;
            }
            else
            {
                return pathName;
            }
        }
        else
        {
            return name;
        }
    }

    isEditable(_: Entity)
    {
        if (this.isField)
        {
            return !this.field.isReadonly && !this.field.isComputedField;
        }
        else
        {
            return !this.relationshipDefinition.isComputed(this.isParentRelationship);
        }
    }

    isInFieldGroup(entityFieldGroup: EntityFieldGroup, entityTypeStore: EntityTypeStore): boolean
    {
        if (this.isField)
        {
            if (!this.field.isStaticField())
            {
                const relatedFieldGroups = this.field.entity
                    .getRelatedEntitiesByDefinition(
                        true,
                        entityTypeStore.bespoke.types.EntityFieldGroup.RelationshipDefinition.Fields);

                if (entityFieldGroup.entity)
                {
                    return relatedFieldGroups
                        .find(groupEntity =>
                            groupEntity.id === entityFieldGroup.entity.id) !== undefined;
                }
                else
                {
                    return relatedFieldGroups.length === 0;
                }
            }
            else
            {
                if (this.field === entityTypeStore.bespoke.types.Entity.Field.Type)
                {
                    // The type field should be in the uncategorized field group
                    return !entityFieldGroup.entity;
                }
                else if (entityFieldGroup.entity)
                {
                    return entityFieldGroup.entity.getObjectValueByField(entityTypeStore.bespoke.types.EntityFieldGroup.Field.IsSystem);
                }
                else
                {
                    return false;
                }
            }
        }
        else
        {
            const relatedFieldGroups =
                this.relationshipDefinition.entity
                    .getRelatedEntitiesByDefinition(
                        true,
                        entityTypeStore.bespoke.types.EntityFieldGroup.RelationshipDefinition.Relationships);

            if (entityFieldGroup.entity)
            {
                return relatedFieldGroups
                    .find(groupEntity =>
                        groupEntity.id === entityFieldGroup.entity.id) !== undefined;
            }
            else
            {
                return relatedFieldGroups.length === 0;
            }
        }
    }

    isDefinedOnType(type: EntityType)
    {
        return this.typeThatDefinesField.isA(type);
    }

    getFieldGroup(entityTypeStore: EntityTypeStore): Entity
    {
        if (this.field)
        {
            if (this.field.isStaticField())
            {
                return undefined;
            }
            else
            {
                return this.field.entity.getRelatedEntityByDefinition(
                    true,
                    entityTypeStore.bespoke.types.EntityFieldGroup.RelationshipDefinition.Fields
                );
            }

        }
        else if (this.relationshipDefinition)
        {
            return this.relationshipDefinition.entity.getRelatedEntityByDefinition(
                true,
                entityTypeStore.bespoke.types.EntityFieldGroup.RelationshipDefinition.Fields);
        }
        else
        {
            return undefined;
        }
    }

    rootedAt(type: EntityType): EntityFieldPath
    {
        return this.path.rootedAt(type)
            .field(this.field);
    }

    setValue(
        entity: Entity,
        value: Entity | any,
        fromEntity?: Entity,
        commitContext?: CommitContext,
        options?: CommitMutationOptions
    )
    {
        if (this.isField)
        {
            for (const valueEntity of this.path.traverseEntity(entity))
            {
                valueEntity.setValueByField(
                    this.field,
                    value,
                    undefined,
                    undefined,
                    commitContext,
                    options
                );
            }
        }
        else if (this.isRelationship)
        {
            for (const valueEntity of this.path.getPathUntilNode(this.path.lastJoinNode).traverseEntity(entity))
            {
                updateRelationship(
                    valueEntity,
                    this.isParentRelationship,
                    this.relationshipDefinition,
                    value,
                    commitContext,
                    fromEntity,
                    undefined,
                    undefined,
                    options
                );
            }
        }
    }

    equals(path: EntityFieldPath): boolean
    {
        return this.id === path.id;
    }

    // ----------------------- Private logic ------------------------
}
