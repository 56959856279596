import React, { useEffect } from 'react';
import useSwitch from '../../../../../@Util/Switch/useSwitch';
import TextEditor from '../TextEditor/TextEditor';
import ViewGroup from '../../ViewGroup/ViewGroup';
import RightAlignedButtonGroup from '../../Button/ButtonGroup/RightAlignedButtonGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import RichText from '../../../../../@Component/Generic/RichText/RichText';
import styles from './HtmlEditor.module.scss';
import { observer } from 'mobx-react-lite';
import PrimaryTextButton from '../../Button/Variant/PrimaryTextButton/PrimaryTextButton';
import LocalizedText from '../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface HtmlEditorProps
{
    value?: string;
    onChange: (value?: string) => void;
}

const HtmlEditor: React.FC<HtmlEditorProps> =
    props =>
    {
        const [ isInEditMode, openEditMode, closeEditMode ] = useSwitch(false);

        useEffect(
            () =>
            {
                if (!props.value)
                {
                    openEditMode();
                }
            },
            [
                props.value,
                openEditMode
            ]);

        if (isInEditMode)
        {
            return <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                <ViewGroupItem>
                    <TextEditor
                        value={props.value}
                        onChange={props.onChange}
                        multiline
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <RightAlignedButtonGroup>
                        <PrimaryTextButton
                            label={
                                <LocalizedText
                                    code="HtmlEditor.BackToView"
                                    value="Terug naar weergave"
                                />
                            }
                            onClick={closeEditMode}
                        />
                    </RightAlignedButtonGroup>
                </ViewGroupItem>
            </ViewGroup>;
        }
        else
        {
            return <div
                className={styles.viewerRoot}
                onClick={openEditMode}
            >
                <div
                    className={styles.editOverlay}
                >
                    <div
                        className={styles.editLabel}
                    >
                        <LocalizedText
                            code="Generic.Edit"
                            value="Wijzigen"
                        />
                    </div>
                </div>
                <RichText
                    value={props.value}
                    standalone
                />
            </div>;
        }
    };

export default observer(HtmlEditor);
