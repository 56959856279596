import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import { Field } from '../../Model/Field';
import Selectbox from '../../../../../../Entity/Selectbox/Selectbox';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';

export interface PackEditorProps
{
    entityType: EntityType;
    field: Field;
}

const PackEditor: React.FC<PackEditorProps> =
    props =>
    {
        const { field } = props;
        const currentUserStore = useContext(CurrentUserContext);

        const setPack =
            useCallback(
                (pack: Entity) =>
                    field.pack = pack,
                [
                    field
                ]);

        const selectboxProps =
            useComputed(
                () => ({
                    disabled: !field.isEditable
                }),
                [
                    field
                ]);

        return <Selectbox
            selections={currentUserStore.rightProfile.ownedPacks}
            value={field.pack}
            onChange={setPack}
            disableConstruction
            genericSelectboxProps={selectboxProps}
        />;
    };

export default observer(PackEditor);
