import { action, computed, observable } from 'mobx';
import { ExpressionContext } from '../ExpressionContext';
import { ComputationEditorStore } from '../../Computation/ComputationEditorStore';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { ComputationTypeStore } from '../../Computation/ComputationTypeStore';
import * as cheerio from 'cheerio';
import { ExpressionSpecification } from '../Model/ExpressionSpecification';
import { NamedComputation } from '../Model/NamedComputation';
import { RichTextEditorStore } from '../../../Generic/RichText/Editor/RichTextEditorStore';
import { ToolbarElementStore } from '../../../Generic/RichText/Toolbar/Types/ToolbarElementStore';
import { ComputationBlot } from '../../../Generic/RichText/Widget/ComputationBlot';
import { ComputationWidgetStore } from '../../../Generic/RichText/Widget/ComputationWidgetStore';
import { EntityTypeStore } from '../../Entity/Type/EntityTypeStore';
import { EntityFieldPath } from '../../Entity/Path/@Model/EntityFieldPath';
import { CompositeComputationSpecification } from '../../Computation/Type/Composite/CompositeComputationSpecification';
import { EntityFieldComputationSpecification } from '../../Computation/Type/Entity/Field/EntityFieldComputationSpecification';
import { ToolbarButtonStore } from '../../../Generic/RichText/Toolbar/Types/Button/ToolbarButtonStore';
import { ButtonStore } from '../../../Generic/Button/ButtonStore';
import { v4 as uuid } from 'uuid';
import { LocalizationStore } from '../../../../@Service/Localization/LocalizationStore';
import { ReactViewComponent } from '../../../Generic/ViewStack/Model/ReactViewComponent';
import FieldPathSelector from '../../Entity/Path/FieldPathEditor/FieldPathSelector';
import { ContextVariable } from '../../Entity/@Model/ContextVariable';
import localizeText from '../../../../@Api/Localization/localizeText';

const ReactQuill = require('react-quill');
const { Quill } = ReactQuill;

interface EntityFieldPathWithParameter
{
    parameterId: string;
    path: EntityFieldPath;
}

export const ExpressionEditorStoreById = new Map<string, ExpressionEditorStore>();

export class ExpressionEditorStore extends RichTextEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable context: ExpressionContext;
    @observable expression: ExpressionSpecification;
    @observable computationWidgetStoreById = observable.map<string, ComputationWidgetStore>();
    @observable computationEditorStore: ComputationEditorStore;
    @observable currentWidgetStore: ComputationWidgetStore;
    @observable hoverOverWidget: ComputationWidgetStore;
    @observable onChange: (expression: ExpressionSpecification) => void;
    @observable showValue: boolean = false;
    @observable hideFieldButton: boolean = false;
    @observable isSingleLine: boolean = false;

    // ------------------------ Constructor -------------------------

    constructor(context: ExpressionContext,
                expression: ExpressionSpecification = { expression: '', computations: [] },
                placeholder: string,
                controls: Array<Array<ToolbarElementStore | ToolbarElementStore[]>>,
                initialHeight: number = 250,
                hideToolbarWhenEmpty: boolean = false,
                showToolbarOnFocus: boolean = false,
                onChange?: (expression: ExpressionSpecification) => void,
                onFocus?: (store: RichTextEditorStore) => void,
                onBlur?: (store: RichTextEditorStore) => void,
                allowTextStyling: boolean = true,
                hideFieldButton: boolean = false,
                isSingleLine: boolean = false)
    {
        super({
            placeholder: placeholder,
            content: () => expression.expression,
            controls: [...controls],
            initialHeight: initialHeight,
            autoHideToolbarWhenEmpty: hideToolbarWhenEmpty,
            showToolbarOnFocus: showToolbarOnFocus,
            onChangeCallback: richText => this.richTextChanged(richText),
            onFocus: onFocus,
            onBlur: onBlur,
            allowTextStyling: allowTextStyling
        });

        this.context = context;

        this.expression = expression;

        this.onChange = onChange;

        this.hideFieldButton = hideFieldButton;

        // Initialize widget stores
        if (expression.computations)
        {
            expression.computations.forEach(
                computation =>
                    this.computationWidgetStoreById.set(
                        computation.id,
                        new ComputationWidgetStore(computation.computation, this)));
        }

        this.isSingleLine = isSingleLine;
    }

    // ----------------------- Initialization -----------------------

    entersUI(isMounted: boolean)
    {
        super.entersUI(isMounted);

        this.computationWidgetStoreById.forEach((value, id) => ExpressionEditorStoreById.set(id, this));
        this.registerBlot();
    }

    exitsUI(isUnmounted: boolean)
    {
        super.exitsUI(isUnmounted);

        this.computationWidgetStoreById.forEach((value, id) => ExpressionEditorStoreById.delete(id));
    }

    registerBlot()
    {
        Quill.register(ComputationBlot);
    }

    // -------------------------- Computed --------------------------

    @computed
    get expressionSpecification(): ExpressionSpecification
    {
        return this.expression;
    }

    @computed
    get contextHasEntities(): boolean
    {
        return this.context.entityContext && this.context.entityContext.entity !== null;
    }

    @computed
    get insertEntityFieldPathToolbarButtonStore(): ToolbarButtonStore
    {
        return new ToolbarButtonStore(
            'InsertEntityFieldPath',
            new ButtonStore(
                {
                    size: 'small',
                    label: this.localizationStore.translate('ExpressionEditor.Label.AddField'), // Add field
                    onClick:
                        store =>
                            store.openPopperView(
                                new ReactViewComponent(
                                    FieldPathSelector,
                                    {
                                        context: this.context.entityContext,
                                        onSelect:
                                            (fieldPath, parameter) =>
                                                {
                                                    store.closePopperView();

                                                    this.insertFieldComputation(
                                                        {
                                                            path: fieldPath,
                                                            parameterId: parameter
                                                        });
                                                },
                                        onSelectVariable:
                                            variable =>
                                                {
                                                    store.closePopperView();

                                                    if (variable.isLink)
                                                    {
                                                        this.insertLink(variable);
                                                    }
                                                    else
                                                    {
                                                        this.insertVariable(variable);
                                                    }
                                                }
                                    }))
                    }));
    }

    @computed
    get insertComputationToolbarButtonStore(): ToolbarButtonStore
    {
        return new ToolbarButtonStore(
            'InsertComputation',
            new ButtonStore(
                {
                    size: 'small',
                    label: localizeText('ExpressionEditor.AddVariableButton', 'Variabele waarde toevoegen'),
                    onClick:
                        () =>
                            this.insertComputation()
                }));
    }

    // --------------------------- Stores ---------------------------

    getComputationWidgetStoreById(id: string): ComputationWidgetStore
    {
        return this.computationWidgetStoreById.get(id);
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    setExpression(expression: ExpressionSpecification)
    {
        this.expression = expression;
        this.setRichTextContent(expression && expression.expression);
    }

    @action.bound
    insertComputation()
    {
        let computationId = uuid();
        let computationSpecification: CompositeComputationSpecification = { type: 'Composite', operations: [] };

        this.computationWidgetStoreById.set(computationId,
            new ComputationWidgetStore(computationSpecification, this));

        ExpressionEditorStoreById.set(computationId, this);

        if (this.quillEditor)
        {
            let selection = this.quillEditor.getSelection();

            this.quillEditor.deleteText(selection.index, selection.length);
            this.quillEditor.insertEmbed(selection.index, 'Computation', computationId, Quill.sources.USER);
            this.quillEditor.setSelection(selection.index + 1, 0);
        }
        else
        {
            console.log('no quill editor');
        }

        // this.openComputationEditor(
        //     this.computationWidgetStoreById.get(computationId));
    }

    @action.bound
    insertFieldComputation(value: EntityFieldPathWithParameter)
    {
        let computationId = uuid();
        let computationSpecification: EntityFieldComputationSpecification =
            {
                type: 'EntityField',
                entityContextParameter: value.parameterId,
                entityFieldPath: value.path.descriptor
            };

        this.computationWidgetStoreById.set(computationId,
            new ComputationWidgetStore(computationSpecification, this));

        ExpressionEditorStoreById.set(computationId, this);

        if (this.quillEditor)
        {
            /*let selection = this.quillEditor.getSelection();

            if (selection === null)
            {
                this.quillEditor.focus();
                selection = this.quillEditor.getSelection();
            }

            if (selection !== null)
            {
                console.log('selection', selection, computationId);

                this.quillEditor.deleteText(selection.index, selection.length);
                this.quillEditor.insertEmbed(selection.index, 'Computation', computationId, Quill.sources.USER);
                this.quillEditor.setSelection(selection.index + 1, 0);
            }*/

            this.quillEditor.deleteText(this.cursorPosition, this.selectionLength);
            this.quillEditor.insertEmbed(this.cursorPosition, 'Computation', computationId, Quill.sources.USER);
            this.quillEditor.setSelection(this.cursorPosition + 1, 0);
        }
    }

    @action.bound
    insertVariable(variable: ContextVariable)
    {
        if (this.quillEditor)
        {
            const value = '{{' + variable.variable + '}}';

            this.quillEditor.deleteText(this.cursorPosition, this.selectionLength);
            this.quillEditor.insertText(this.cursorPosition, value, Quill.sources.USER);
            this.quillEditor.setSelection(this.cursorPosition + value.length, 0);
        }
    }

    @action.bound
    insertLink(link: ContextVariable)
    {
        if (this.quillEditor)
        {
            const delta = {
                ops:
                [
                    { retain: this.cursorPosition },
                    {
                        insert: link.name,
                        attributes:
                            {
                                link: '{{' + link.variable + '}}'
                            }
                    }
                ]
            };

            this.quillEditor.deleteText(this.cursorPosition, this.selectionLength);
            // @ts-ignore
            this.quillEditor.updateContents(delta, Quill.sources.USER);
            this.quillEditor.setSelection(this.cursorPosition + link.name.length, 0);
        }
    }

    @action.bound
    openComputationEditor(widget: ComputationWidgetStore)
    {
        let computationEditorStore = ComputationEditorStore.construct(
            this.context,
            widget.computation,
            this.computationTypeStore);

        this.currentWidgetStore = widget;
        this.computationEditorStore = computationEditorStore;
    }

    @action.bound
    closeComputationEditor()
    {
        if (this.currentWidgetStore)
        {
            this.currentWidgetStore.computation = this.computationEditorStore.type.editorSpecification(this.computationEditorStore.editorStore);

            this.currentWidgetStore = undefined;
            this.computationEditorStore = undefined;
        }

        const expression =
            this.content
                ? this.getExpressionSpecification(this.content)
                : undefined;

        this.expression = expression;

        if (this.onChange)
        {
            this.onChange(expression);
        }

        // if (this.onChange)
        // {
        //     this.onChange(this.expressionSpecification);
        // }
    }

    @action.bound
    toggleShowValue()
    {
        this.showValue = !this.showValue;
    }

    @action.bound
    setHoverOverWidget(widgetStore: ComputationWidgetStore)
    {
        this.hoverOverWidget = widgetStore;
    }

    @action.bound
    richTextChanged(richText: string)
    {
        const expression =
            richText
                ? this.getExpressionSpecification(richText)
                : undefined;

        this.expression = expression;

        if (this.onChange)
        {
            this.onChange(expression);
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    private getExpressionSpecification(rootHtml: string)
    {
        const existentComputationIds: string[] = [];
        const html =
            cheerio.load(
                rootHtml,
                { decodeEntities: false });

        const computationNodes = html('computation');

        computationNodes
            .each((idx, element) =>
            {
                existentComputationIds.push(
                    element.attribs['data-id']);
            })
            .html('');

        let cleanedExpression =
            computationNodes.length > 0
                ?
                    html('body').html()
                :
                    rootHtml;

        const namedComputations: NamedComputation[] = [];

        this.computationWidgetStoreById.forEach(
            (store, id) =>
            {
                if (existentComputationIds.indexOf(id) > -1)
                {
                    namedComputations.push(
                    {
                        id: id,
                        computation: store.computation
                    });
                }
            });

        return {
            expression: cleanedExpression,
            computations: namedComputations
        };
    }
}
