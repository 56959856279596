import { observable } from 'mobx';

export type SeriesType = 'Pie' | 'Bar' | 'Line';

export default abstract class Series
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable type: SeriesType;
    @observable name: string;
    @observable isCumulative: boolean;
    @observable isHidden: boolean;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                type: SeriesType,
                name: string,
                isCumulative: boolean,
                isHidden: boolean)
    {
        this.id = id;
        this.type = type;
        this.name = name;
        this.isCumulative = isCumulative;
        this.isHidden = isHidden;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor()
    {
        return {
            id: this.id,
            type: this.type,
            name: this.name,
            isCumulative: this.isCumulative,
            isHidden: this.isHidden
        };
    }

    // ----------------------- Private logic ------------------------
}
