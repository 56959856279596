import React, { Fragment } from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import GutenbergBlockType from '../../Gutenberg/GutenbergBlockType';
import { GutenbergBlockPageBreakId } from '../../DocumentEditor';
import StyledBlock from '../StyledBlock/StyledBlock';
import Icon from '../../../../Icon/Icon';

const GutenbergBlockPageBreak: GutenbergBlockType =
{
    name: GutenbergBlockPageBreakId,
    configuration:
        {
            title: {
                code: 'GutenbergBlock.PageBreak',
                value: 'Pagina-einde'
            },
            icon: <Icon icon="post_add" />,
            category: 'common',

            attributes:
            {
                content:
                {
                    type: 'string',
                    source: 'html',
                    selector: 'p',
                    query: undefined,
                },
            },

            edit: props =>
            {
                return (
                    <Fragment>
                        <InspectorControls>

                        </InspectorControls>
                        <StyledBlock
                            block={props}
                        >
                            <hr />
                        </StyledBlock>
                    </Fragment>
                );
            },

            save: props =>
            {
                return <p>
                    <hr />
                </p>;
            }
        },

    view: block =>
    {
        return <StyledBlock
            block={block}
        >
            <hr />
        </StyledBlock>;
    }
};

export default GutenbergBlockPageBreak;
