import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { getModel } from '../../../../../@Util/TransactionalModelV2/index';
import { observable } from 'mobx';
import { BespokeRelationshipPersonContactEmployee } from './BespokeRelationshipPersonContactEmployee';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export class BespokeRelationshipPersonContact extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable employee: BespokeRelationshipPersonContactEmployee;

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Relationship.Person.Contact')
    {
        super(entityTypeStore, code);

        this.employee = new BespokeRelationshipPersonContactEmployee(this.entityTypeStore);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    onConstructFromRelationship(entity: Entity,
                                fromEntity: Entity,
                                fromRelationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean,
                                commitContext?: CommitContext)
    {
        super.onConstructFromRelationship(entity, fromEntity, fromRelationshipDefinition, isParent, commitContext);

        if (fromRelationshipDefinition === this.types.Relationship.Person.Contact.RelationshipDefinition.Activities
            && isParent)
        {
            const relationship =
                fromEntity.getRelatedEntityByDefinition(
                    true,
                    this.types.Relationship.RelationshipDefinition.Activities,
                    commitContext
                );

            if (relationship)
            {
                const organization =
                    relationship.getRelatedEntityByDefinition(
                        false,
                        this.types.Relationship.Organization.RelationshipDefinition.Organization,
                        commitContext
                    );

                if (organization)
                {
                    updateRelationship(
                        entity,
                        true,
                        this.types.Relation.RelationshipDefinition.Relationships,
                        getModel(organization),
                        commitContext
                    );
                }
            }
        }
    }

    extendRelationshipSelection(entity: Entity,
                                relationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean,
                                builder: EntitySelectionBuilder,
                                commitContext?: CommitContext)
    {
        super.extendRelationshipSelection(
            entity,
            relationshipDefinition,
            isParent,
            builder,
            commitContext
        );

        const types = this.entityTypeStore.bespoke.types;

        if (relationshipDefinition === types.Relation.RelationshipDefinition.Relationships
            && isParent)
        {
            builder.where(
                cb =>
                    cb.isOfType(
                        builder.rootPath,
                        types.Relation.Organization.Type,
                        false,
                        true));
        }
    }

    getName(isPlural: boolean, entity?: Entity): string
    {
        if (!isPlural &&
            entity &&
            entity.entityType.isA(this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Type) &&
            (entity.hasValueForField(this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Field.FunctionOnBusinessCard)
            || entity.hasRelationshipsByDefinition(false, this.types.Relationship.Person.Contact.RelationshipDefinition.Function)))
        {
            return entity.getObjectValueByField(this.types.Relationship.Person.Contact.Field.FunctionOnBusinessCard)
                || entity.getRelatedEntityByDefinition(false, this.types.Relationship.Person.Contact.RelationshipDefinition.Function)?.name;
        }
        else
        {
            if (isPlural)
            {
                return super.getName(isPlural, entity);
            }
            else
            {
                if (entity
                    && (this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Type.isA(entity.entityType)
                        || this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Standard.Type.isA(entity.entityType)))
                {
                    // Abbreviation, useful in the captions displayed in selectboxes
                    return 'Contact';
                }
                else
                {
                    return super.getName(isPlural, entity);
                }
            }
        }
    }

    showListItemsAsEntityCard(pathFromRelatedEntity?: RelatedEntityPath): boolean
    {
        if (pathFromRelatedEntity)
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    getAddPathsInTimeline(entity: Entity, rootPath: EntityPath): RelatedEntityPath[]
    {
        const pathToActivities =
            rootPath.joinTo(
                this.entityTypeStore.bespoke.types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                false);

        return [
            new RelatedEntityPath(
                entity,
                pathToActivities.castTo(this.entityTypeStore.bespoke.types.Activity.Task.Type)),
            new RelatedEntityPath(
                entity,
                pathToActivities.castTo(this.entityTypeStore.bespoke.types.Activity.Email.Type)),
            new RelatedEntityPath(
                entity,
                pathToActivities.castTo(this.entityTypeStore.bespoke.types.Activity.Project.Type)),
            new RelatedEntityPath(
                entity,
                pathToActivities.castTo(this.entityTypeStore.bespoke.types.Activity.DigitalSigning.Type))
        ];
    }

    getListDependencies(): EntityPath[]
    {
        return [
            ...super.getListDependencies(),
            EntityPath.fromEntityType(this.type)
                .joinTo(
                    this.types.Relationship.Person.Contact.RelationshipDefinition.Function,
                    false)
        ];
    }

    hideFieldPath(entity: Entity,
                  fieldPath: EntityFieldPath,
                  fromFieldPath?: EntityFieldPath,
                  isInConstructor?: boolean,
                  commitContext?: CommitContext): boolean
    {
        if (this.types.Relationship.RelationshipDefinition.ChildAccounts
            && fieldPath.relationshipDefinition === this.types.Relationship.RelationshipDefinition.ChildAccounts
            && fieldPath.isParentRelationship)
        {
            return true;
        }
        else if (this.types.Relationship.Field.IsMasterAccount
            && fieldPath.field === this.types.Relationship.Field.IsMasterAccount)
        {
            return true;
        }
        else
        {
            return super.hideFieldPath(entity, fieldPath, fromFieldPath, isInConstructor, commitContext);
        }
    }

    allowChildTypeCreation(): boolean
    {
        return true;
    }

    // ----------------------- Private logic ------------------------
}
