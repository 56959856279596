import { DataComparator } from '../ListStore';

export function createNumberComparator<D>(
    retriever: (data: D) => number,
    nullsLast: boolean = false
): DataComparator<D>
{
    return (d1, d2) =>
    {
        let n1 = retriever(d1);

        if (n1 == null)
        {
            n1 =
                nullsLast
                    ? Number.MAX_VALUE
                    : Number.MIN_VALUE;
        }

        let n2 = retriever(d2);

        if (n2 == null)
        {
            n2 =
                nullsLast
                    ? Number.MAX_VALUE
                    : Number.MIN_VALUE;
        }

        return n1 > n2
            ?
                1
            :
                n1 === n2
                    ?
                        0
                    :
                        -1;
    };
}
