import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import SourceFieldMapping from '../SourceField/SourceFieldMapping';
import ValueToValueMapping from './ValueToValueMapping';
import { observable } from 'mobx';

export default class ValueToValueSourceFieldMapping extends SourceFieldMapping
{
    // ------------------------- Properties -------------------------

    @observable valueMappings: ValueToValueMapping[];

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                targetFieldPath: EntityFieldPath,
                sourceFieldId: string,
                valueMappings: ValueToValueMapping[])
    {
        super(id, targetFieldPath, sourceFieldId);

        this.valueMappings = valueMappings;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new ValueToValueSourceFieldMapping(
            descriptor.id,
            EntityFieldPath.construct(descriptor.targetFieldPath),
            descriptor.sourceFieldId,
            descriptor.valueMappings
                .map(
                    valueMapping =>
                        ValueToValueMapping.fromDescriptor(
                            valueMapping)));
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'ValueToValueSourceField',
            valueMappings:
                this.valueMappings.map(
                    valueMapping =>
                        valueMapping.toDescriptor())
        };
    }

    // ----------------------- Private logic ------------------------
}
