import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import getTypes from '../../Type/Api/getTypes';

export default function getEntityTypeViewsBuilder(
    entityType: EntityType,
    currentUserStore: CurrentUserStore,
    builder: EntitySelectionBuilder,
    rootPath: EntityPath
)
{
    const types = getTypes();

    return builder
        .where(
            cb =>
                cb.relatedToEntity(
                    rootPath
                        .joinTo(
                            types.EntityType.RelationshipDefinition.Views,
                            true
                        ),
                    entityType.entity
                )
        )
        .where(
            cb =>
                cb.or(
                    ob =>
                        ob
                            // the view is not private
                            .neq(
                                rootPath.field(types.View.Field.IsPrivate),
                                undefined,
                                true
                            )
                            // current user is owner of the view
                            .relatedToEntity(
                                rootPath
                                    .joinTo(
                                        types.View.RelationshipDefinition.Owners,
                                        false),
                                currentUserStore.employeeEntity
                            )
                            // the view is shared with current user
                            .relatedToEntity(
                                rootPath
                                    .joinTo(
                                        types.View.RelationshipDefinition.SharedWithEmployees,
                                        false),
                                currentUserStore.employeeEntity
                            )
                            // the view is shared with current user's team
                            .relatedToEntity(
                                rootPath
                                    .joinTo(
                                        types.View.RelationshipDefinition.SharedWithTeams,
                                        false)
                                    .joinTo(
                                        types.Team.RelationshipDefinition.Members,
                                        false),
                                currentUserStore.employeeEntity
                            )
                )
        )
        .orderBy(
            rootPath.field(types.View.Field.LocalizedName),
            true
        )
        .orderBy(
            // Default view first
            rootPath.field(types.View.Field.IsDefault),
            false
        );
}