export enum VerticalAlignment { Top, Center, Bottom }

export function getVerticalAlignmentCss(alignment: VerticalAlignment)
{
    switch (alignment)
    {
        case VerticalAlignment.Top:
            return 'flex-start';

        case VerticalAlignment.Center:
            return 'center';

        case VerticalAlignment.Bottom:
            return 'flex-end';
    }

    return undefined;
}
