import React from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../Type/Api/useTypes';
import Activity from './Activity/Activity';
import Note from './Note/Note';
import Attachment from './Attachment/Attachment';
import Relationship from './Relationship/Relationship';
import Relation from './Relation/Relation';
import Default from './Default/Default';
import ProductLine from './ProductLine/ProductLine';
import EventContent from './Event/Event';
import Involved from './Involved/Involved';
import Campaign from './Campaign/Campaign';
import TimeRegistration from './TimeRegistration/TimeRegistration';
import { ItemProps } from '../Item';
import ProjectMember from './ProjectMember/ProjectMember';
import EventParticipation from './EventParticipation/EventParticipation';
import EmailClick from './EmailClick/EmailClick';
import { ApsisCampaign } from './ApsisCampaign/ApsisCampaign';
import WorkOrderMember from './WorkOrderMember/WorkOrderMember';
import { SpotlerCampaign } from './SpotlerCampaign/SpotlerCampaign';
import { LeexiCompletion } from './Leexi/LeexiCompletion';
import { LeexiParticipant } from './Leexi/LeexiParticipant';
import { LeexiCall } from './Leexi/LeexiCall';

export interface ContentProps extends ItemProps
{

}

const Content: React.FC<ContentProps> =
    props =>
    {
        const types = useTypes();

        if (props.entity.entityType.isA(types.Note.Type))
        {
            return <Note
                entity={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.Attachment.Type))
        {
            return <Attachment
                entity={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.Relation.Type))
        {
            return <Relation
                entity={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.Relationship.Type))
        {
            return <Relationship
                entity={props.entity}
                showMenuButton={props.showMenuButton}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.Campaign.Type))
        {
            return <Campaign
                entity={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.ApsisCampaign.Type))
        {
            return <ApsisCampaign
                apsisCampaign={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.SpotlerCampaign.Type))
        {
            return <SpotlerCampaign
                spotlerCampaign={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.Type))
        {
            return <Activity
                entity={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.Involved.Type))
        {
            return <Involved
                entity={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.EventParticipation.Type))
        {
            return <EventParticipation
                entity={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.ProjectMember.Type))
        {
            return <ProjectMember
                entity={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.WorkOrderMember.Type))
        {
            return <WorkOrderMember
                entity={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.ProductLine.Type))
        {
            return <ProductLine
                entity={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.EmailClick.Type))
        {
            return <EmailClick
                entity={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.TimeRegistration.Type))
        {
            return <TimeRegistration
                entity={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.Event.Type))
        {
            return <EventContent
                entity={props.entity}
            />;
        }
        else if (props.entity.entityType.isA(types.LeexiCallCompletion.Type))
        {
            return <LeexiCompletion
                entity={props.entity}
            />
        }
        else if (props.entity.entityType.isA(types.LeexiCallParticipant.Type))
        {
            return <LeexiParticipant
                entity={props.entity}
            />
        }
        else if (props.entity.entityType.isA(types.LeexiCall.Type))
        {
            return <LeexiCall
                entity={props.entity}
            />
        }
        else
        {
            return <Default
                entity={props.entity}
            />;
        }
    };

export default observer(Content);
