import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import FormContext from '../../../Context/FormContext';
import Input, { LabelPosition } from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import FieldValueEditor from './Value/FieldValueEditor';
import { FormFieldValue } from '../../../Model/FormFieldValue';
import { runInAction } from 'mobx';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface FieldViewerProps
{
    fieldId: string;
    field: any;
    labelPosition: LabelPosition | 'placeholder';
    disabled?: boolean;
}

const FieldViewer: React.FC<FieldViewerProps> =
    props =>
    {
        const context = useContext(FormContext);

        const formField =
            useMemo(
                () =>
                    context.form?.fields
                        .find(
                            field =>
                                field.id === props.fieldId),
                [
                    context,
                    props.fieldId
                ]);

        const setValue =
            useCallback(
                (value?: FormFieldValue) =>
                    runInAction(
                        () =>
                        {
                            if (value)
                            {
                                context.valueByFieldId
                                    .set(formField.id, value)
                            }
                            else
                            {
                                context.valueByFieldId.delete(formField.id)
                            }
                        }
                    ),
                [
                    context,
                    formField
                ]);

        const label =
            useMemo(
                () =>
                    formField &&
                        `${formField.name} ${formField.isRequired ? '(*)' : ''}`,
                [
                    formField
                ]);

        if (formField)
        {
            return <Input
                label={label}
                labelPosition={props.labelPosition === 'placeholder' ? 'none' : props.labelPosition || 'left'}
            >
                <FieldValueEditor
                    form={context.form}
                    field={formField}
                    value={context.valueByFieldId.get(formField.id)}
                    onChange={setValue}
                    placeholder={props.labelPosition === 'placeholder' ? label : '...'}
                    disabled={props.disabled}
                />
            </Input>;
        }
        else
        {
            return <div>
                <LocalizedText
                    code="EntityForm.FieldNotFound"
                    value="Veld niet gevonden."
                />
            </div>;
        }
    };

export default observer(FieldViewer);
