import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { PageStore } from './PageStore';
import { Paper, Slide } from '@material-ui/core';
import { Sidebar } from '../../../@Component/Generic/Sidebar/Sidebar';
import { grey } from '@material-ui/core/colors';
import { normalGrey } from '../../../@Resource/Theme/Theme';
import { Dialog } from '../../../@Component/Generic/Dialog/Dialog';
import Drawer from '../../../@Component/Generic/Drawer/Drawer';
import styles from './Page.module.scss';
import { classNames } from '../../../@Future/Util/Class/classNames';

const pagePadding = 20;

export abstract class Page<T extends PageStore> extends BaseComponent<T>
{
    private ref: any;
    private element: any;

    constructor(props: any)
    {
        super(props);

        this.ref = React.createRef();
    }

    componentDidMount()
    {
        super.componentDidMount();

        this.element = ReactDOM.findDOMNode(this.ref.current) as Element;
    }

    renderComponent(store: T)
    {
        const renderStore = store;

        const sidebarWidth =
            store.leftSidebarPanelStore
                ?
                    store.leftSidebarPanelStore.width
                :
                    store.rightSidebarPanelStore
                        ?
                            store.rightSidebarPanelStore.width
                        :
                            0;

        const body = store.childPageStore ? null : this.renderBody(renderStore);

        if (store.childPageStore && store.renderChildPageAsSelf)
        {
            return React.createElement(
                store.childPageStore.componentClass,
                {
                    store: store.childPageStore,
                    ...store.componentProps
                } as any);
        }

        return <div
            ref={this.ref}
            className={styles.root}
        >
            <Drawer
                store={store.drawerStore}
            />
            {
                store.dialogStores.map(
                    dialogStore =>
                        <Dialog
                            key={dialogStore.uuid}
                            store={dialogStore}
                        />)
            }
            {
                store.dialogComponents.map(
                    (dialog, idx) =>
                        <div
                            key={idx}
                        >
                            {dialog}
                        </div>
                )
            }
            <div
                className={styles.body}
            >
                {
                    renderStore.hasLeftSidebar &&
                        <div
                            className={styles.sidebar}
                        >
                            <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                                <Paper
                                    elevation={0}
                                    square={true}
                                    style={{
                                        backgroundColor:
                                            store.leftSidebarPanelStore
                                                ?
                                                    store.leftSidebarPanelStore.backgroundColor
                                                :
                                                    'white',
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        width: sidebarWidth,
                                        height: '100%',
                                        marginRight: renderStore.hasPagePadding ? pagePadding : 0,
                                        border: '1px solid',
                                        borderColor: normalGrey,
                                        borderTop: 0
                                    }}
                                >
                                    <Sidebar store={renderStore.leftSidebarStore} />
                                </Paper>
                            </Slide>
                        </div>
                }
                {
                    renderStore.hasSecondaryLeftSidebar &&
                        <div
                            className={styles.sidebar}
                        >
                            <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                                <Paper
                                    elevation={2}
                                    square={true}
                                    style={{
                                        backgroundColor: 'white',
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        width: sidebarWidth,
                                        height: '100%',
                                        marginRight: pagePadding
                                    }}
                                >
                                    <Sidebar store={renderStore.secondaryLeftSidebarStore} />
                                </Paper>
                            </Slide>
                        </div>
                }
                <div
                    className={classNames(styles.content, renderStore.hasPagePadding && styles.padding)}
                >
                    {body}
                </div>
                {
                    renderStore.hasRightSidebar &&
                        <div
                            className={styles.sidebar}
                        >
                            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                                <Paper
                                    elevation={2}
                                    square={true}
                                    style={{
                                        backgroundColor:
                                            store.rightSidebarPanelStore
                                                ?
                                                    store.rightSidebarPanelStore.backgroundColor
                                                :
                                                    'white',
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        width: sidebarWidth,
                                        height: '100%',
                                        borderLeft: !renderStore.hasPagePadding ? `${grey[100]} 1px solid` : undefined
                                    }}
                                >
                                    <Sidebar store={renderStore.rightSidebarStore} />
                                </Paper>
                            </Slide>
                        </div>
                }
            </div>
        </div>;
    }

    abstract renderBody(store: T): JSX.Element;
}
