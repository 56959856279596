import React, { useCallback, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutEditor, { LayoutEditorProps } from '../../Editor/LayoutEditor';
import TemplateLayout from '../../../../../@Api/Layout/Type/TemplateLayout';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TabBar from '../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import DynamicParameterAssignmentEditor from '../../../Entity/Viewer/Content/Automation/Editor/DynamicParameterAssignment/DynamicParameterAssignmentEditor';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import Layout from '../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';
import ParametersEditor from '../../../Entity/Viewer/Content/Automation/Editor/Parameters/ParametersEditor';
import RightAlignedButtonGroup from '../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import useTypes from '../../../Entity/Type/Api/useTypes';
import useEntityByUuid from '../../../../../@Api/Entity/Bespoke/useEntityByUuid';
import useTransactionalEntity from '../../../../../@Api/Entity/Bespoke/useTransactionalEntity';
import Input from '../../../Entity/Input/Input';
import TemplateSelector from './TemplateSelector';

export interface TemplateLayoutEditorProps extends LayoutEditorProps<TemplateLayout>
{

}

const TemplateLayoutEditor: React.FC<TemplateLayoutEditorProps> =
    props =>
    {
        const [ tab, setTab ] = useState(props.layout.templateId ? 0 : 1);

        const context =
            useComputed(
                () =>
                    new FunctionContext(
                        props.parameterDictionary,
                        props.parameterAssignment,
                        props.commitContext
                    ),
                [
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        const setTemplateLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.template.layout = layout),
                [
                    props.layout
                ]);

        const types = useTypes();
        const [ templateEntity ] = useEntityByUuid(types.LayoutTemplate.Type, props.layout.templateId);
        const transactionalTemplateEntity = useTransactionalEntity(templateEntity);

        const saveTemplateLayout =
            useCallback(
                () =>
                {
                    transactionalTemplateEntity.setValueByField(
                        types.LayoutTemplate.Field.Specification,
                        props.layout.template.toDescriptor());

                    return transactionalTemplateEntity.checkAndDoCommit()
                        .then(
                            () =>
                                setTab(0));
                },
                [
                    transactionalTemplateEntity,
                    types,
                    props.layout,
                    setTab
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <TabBar
                    value={tab}
                >
                    {
                        props.layout.template &&
                            <Tab
                                value={0}
                                onClick={setTab}
                            >
                                <LocalizedText
                                    code="Generic.Content"
                                    value="Inhoud"
                                />
                            </Tab>
                    }
                    <Tab
                        value={1}
                        onClick={setTab}
                    >
                        <LocalizedText
                            code="Generic.Template"
                            value="Sjabloon"
                        />
                    </Tab>
                </TabBar>
            </ViewGroupItem>
            {
                tab === 0 && props.layout.template &&
                    <ViewGroupItem>
                        <DynamicParameterAssignmentEditor
                            context={context}
                            parameterDictionary={props.layout.template.parameters}
                            parameterAssignment={props.layout.parameterAssignment}
                        />
                    </ViewGroupItem>
            }
            {
                tab === 1 &&
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="vertical"
                            spacing={15}
                        >
                            <ViewGroupItem>
                                <TemplateSelector
                                    {...props}
                                />
                            </ViewGroupItem>
                            {
                                props.layout.template && transactionalTemplateEntity &&
                                    <ViewGroupItem>
                                        <ViewGroup
                                            orientation="vertical"
                                            spacing={15}
                                        >
                                            <ViewGroupItem>
                                                <Input
                                                    entity={transactionalTemplateEntity}
                                                    field={types.LayoutTemplate.Field.Name}
                                                    doAutocommit={false}
                                                    labelPosition="left"
                                                />
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <ParametersEditor
                                                    parameterDictionary={props.layout.template.parameters}
                                                />
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <LayoutEditor
                                                    parameterDictionary={props.layout.template.parameters}
                                                    onChange={setTemplateLayout}
                                                    layout={props.layout.template.layout}
                                                />
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <RightAlignedButtonGroup>
                                                    <SaveButton
                                                        onClick={saveTemplateLayout}
                                                    />
                                                </RightAlignedButtonGroup>
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </ViewGroupItem>
                            }
                        </ViewGroup>
                    </ViewGroupItem>
            }
        </ViewGroup>
    };

export default observer(TemplateLayoutEditor);
