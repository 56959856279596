import { EntityConstraintBuilderFilterContext } from './Model/EntityConstraintBuilderFilterContext';
import { ConstraintNode } from '../../../../../../@Api/Model/Implementation/ConstraintNode';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { BooleanConstraintNode } from '../../../../../../@Api/Model/Implementation/BooleanConstraintNode';
import { isResultParameter } from './Util/isResultParameter';

export function isBooleanConstraintNode(
    filter: Predicate,
    context: EntityConstraintBuilderFilterContext
)
{
    return filter.getDependencies().every(
        dependency =>
            !isResultParameter(dependency.parameter, context)
    );
}

export function buildBooleanConstraintNode(
    filter: Predicate,
    context: EntityConstraintBuilderFilterContext
): ConstraintNode
{
    try
    {
        const isTrue = filter.synchronouslyEvaluate(context.context);

        return new BooleanConstraintNode(isTrue);
    }
    catch (e)
    {
        console.warn(e);

        return new BooleanConstraintNode(false);
    }
}
