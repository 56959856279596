import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { CronEditorProps } from '../CronEditor';
import DailyCronEditor from './DailyCronEditor';
import ViewGroup from '../../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../../ViewGroup/ViewGroupItem';
import NumberEditor from '../../NumberEditor/NumberEditor';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface MonthlyCronEditorProps extends CronEditorProps
{

}

const MonthlyCronEditor: React.FC<MonthlyCronEditorProps> =
    props =>
    {
        const { onChange } = props;
        const day =
            useMemo(
                () =>
                    parseInt(props.value.split(' ')[3]),
                [
                    props.value
                ]);

        const onChangeDay =
            useCallback(
                (day: number) =>
                {
                    const split = props.value.split(' ');
                    split.splice(
                        3,
                        1,
                        day.toString());

                    onChange(split.join(' '));
                },
                [
                    onChange,
                    props.value
                ]);

        const months =
            useMemo(
                () =>
                    parseInt(props.value.split(' ')[4].split('/')[1]),
                [
                    props.value
                ]);

        const onChangeMonths =
            useCallback(
                (months: number) =>
                {
                    const split = props.value.split(' ');
                    split.splice(
                        4,
                        1,
                        `1/${months}`);

                    onChange(split.join(' '));
                },
                [
                    onChange,
                    props.value
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <ViewGroupItem>
                <ViewGroup
                    orientation="horizontal"
                    spacing={5}
                    alignment="center"
                >
                    <ViewGroupItem>
                        <LocalizedText
                            code="CronEditor.Day"
                            value="Dag"
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <NumberEditor
                            value={day}
                            onChange={onChangeDay}
                            min={1}
                            max={31}
                            fitContent
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <LocalizedText
                            code="CronEditor.OfEach"
                            value="van elke"
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <NumberEditor
                            value={months}
                            onChange={onChangeMonths}
                            min={1}
                            max={12}
                            fitContent
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        {
                            months === 1
                                ?
                                    localizeText('month', 'maand')
                                :
                                    localizeText('months', 'maanden')
                        }
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
            <ViewGroupItem>
                <DailyCronEditor
                    {...props}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(MonthlyCronEditor);
