import { useContext, useMemo } from 'react';
import FilterContext from '../FilterContext/FilterContext';
import { LogicalOperator } from '../../../../DataObject/Model/LogicalOperator';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import CompositePredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';

export default function useCombinedPredicate(...predicates: Predicate[])
{
    const filter = useContext(FilterContext);

    return useMemo(
        () =>
            new CompositePredicate(
                LogicalOperator.And,
                [
                    ...filter ? [ filter ] : [],
                    ...predicates.filter(pred => pred !== undefined)
                ]),
        [
            filter,
            ...predicates
        ]);
}
