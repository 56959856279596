import React, { Fragment } from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import BlockOptionsPanel from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Settings/BlockOptionsPanel';
import ColorOptionsPanel from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Settings/ColorOptionsPanel';
import GutenbergBlockType from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Gutenberg/GutenbergBlockType';
import { FormFieldBlockId } from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/DocumentEditor';
import FieldEditor from './Editor/FieldEditor';
import FieldViewer from './Viewer/FieldViewer';
import resolveInputFromDescriptor from '../../../../Multiplayer/Model/Input/Api/resolveInputFromDescriptor';
import uuid from '../../../../../../@Util/Id/uuid';
import FieldOptionsPanel from './Editor/Settings/FieldOptionsPanel';
import StyledBlock from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/StyledBlock/StyledBlock';
import Icon from '../../../../../../@Future/Component/Generic/Icon/Icon';
import LabelOptionsPanel from './Editor/Settings/LabelOptionsPanel';

const FormFieldBlock: GutenbergBlockType =
{
    name: FormFieldBlockId,
    configuration:
        {
            title: {
                code: 'GutenbergBlock.Field',
                value: 'Veld'
            },
            icon: <Icon icon="short_text" />,
            category: 'common',

            attributes:
            {
                content:
                {
                    type: 'string',
                    source: 'html',
                    selector: 'p',
                    query: undefined,
                },
            },

            edit: props =>
            {
                const {
                    field
                } = props.attributes as any;

                return (
                    <Fragment>
                        <InspectorControls>
                            <BlockOptionsPanel props={props} />
                            <ColorOptionsPanel props={props} />
                            <LabelOptionsPanel props={props} />
                            <FieldOptionsPanel props={props} />
                        </InspectorControls>
                        <StyledBlock
                            block={props}
                        >
                            <FieldEditor
                                onSelect={
                                    field =>
                                        props.setAttributes({
                                            fieldId: (props.attributes as any).fieldId || uuid(),
                                            field: field.toDescriptor()
                                        })}
                                field={field ? resolveInputFromDescriptor(field) : undefined}
                            />
                        </StyledBlock>
                    </Fragment>
                );
            },

            save: (props) =>
            {
                return <p>
                    {(props.attributes as any).content}
                </p>;
            }
        },

    view: block =>
    {
        if (block.attributes.isHidden)
        {
            return null;
        }
        else
        {
            return <StyledBlock
                block={block}
            >
                <FieldViewer
                    fieldId={block.attributes.fieldId}
                    field={block.attributes.field}
                    labelPosition={block.attributes.labelPosition}
                    disabled={block.attributes.isDisabled}
                />
            </StyledBlock>;
        }
    }
};

export default FormFieldBlock;
