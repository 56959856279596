import { reference, type } from '../../../@Util/Serialization/Serialization';
import { LanguageEntry } from './LanguageEntry';
import { Language } from './Language';
import { registerBuilder } from '../../../@Util/TransactionalModelV2/Shared/TransactionalBuilder';
import { observable } from 'mobx';

@type('LanguageEntryTranslation')
export class LanguageEntryTranslation
{
    // ------------------- Persistent Properties --------------------

    @observable id: number;
    @reference(undefined, 'LanguageEntry') @observable.ref languageEntry: LanguageEntry;
    @reference(undefined, 'Language') @observable.ref language: Language;
    @observable value: string;
    @observable isApproved: boolean;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(id?: number,
                languageEntry?: LanguageEntry,
                language?: Language,
                value?: string,
                isApproved?: boolean)
    {
        this.id = id;
        this.languageEntry = languageEntry;
        this.language = language;
        this.value = value;
        this.isApproved = isApproved;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    descriptor(): any
    {
        return {
            id: this.id,
            languageId: this.language.id,
            value: this.value
        };
    }

    // ----------------------- Private logic ------------------------
}

registerBuilder(LanguageEntryTranslation)
    .includeAll()
    .deep('languageEntry');
