import React from 'react';
import useResizeObserver from 'use-resize-observer/polyfilled';

export interface HideableBoxProps
{
    hidden?: boolean;
}

export const HideableFixedBox: React.FC<HideableBoxProps> =
    ({
        hidden,
        children,
     }) =>
    {
        const { ref, height } = useResizeObserver<HTMLDivElement>();

        return <div
            ref={ref}
            style={{
                height: hidden ? height : undefined,
            }}
        >
            {hidden ? undefined : children}
        </div>;
    };
