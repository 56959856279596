import { ComputationEditorStore } from '../../ComputationEditorStore';
import { action, observable } from 'mobx';
import { ComputationContext } from '../../ComputationContext';
import { ComputationType } from '../ComputationType';
import { ComputationSpecification } from '../ComputationSpecification';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../../../DataObject/DataObjectStore';
import { ExpressionSpecification } from '../../../Expression/Model/ExpressionSpecification';
import { ExpressionEditorStore } from '../../../Expression/Editor/ExpressionEditorStore';

export class ExpressionComputationEditorStore extends ComputationEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;

    // ------------------------- Properties -------------------------

    @observable expressionEditorStore: ExpressionEditorStore;
    @observable expression: ExpressionSpecification;



    // ------------------------ Constructor -------------------------

    constructor(type: ComputationType,
                context: ComputationContext,
                specification: ComputationSpecification,
                expression: ExpressionSpecification)
    {
        super(type, context, specification);

        this.expression = expression;
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        this.expressionEditorStore = new ExpressionEditorStore(
            this.context,
            this.expression,
            '',
            [[]],
            undefined,
            undefined,
            undefined,
            this.setExpression);

        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    setExpression(expression: ExpressionSpecification)
    {
        this.expression = expression;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
