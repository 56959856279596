import Role, { Privilege, RoleType } from '../../../Model/Role';
import { EntityRelationshipDefinition } from '../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { getEntityTypePermission } from '../EntityType/getEntityTypePermission';
import { getRelationshipDefinitionSpecifiedPermission } from './getRelationshipDefinitionSpecifiedPermission';

export function isRelationshipDefinitionPermissionDisabled(
    rolesByType: Map<RoleType, Role>,
    role: Role,
    privilege: Privilege,
    relationshipDefinition: EntityRelationshipDefinition
): boolean
{
    if (privilege === 'canExport' && !role.isExportingEnabled())
    {
        return true;
    }

    const administratorBaseRole = rolesByType.get('AdministratorBaseRole');

    if (administratorBaseRole.rightByType.get(relationshipDefinition.parentEntityType)?.getPermission('canRead') === 'Denied'
        || administratorBaseRole.rightByType.get(relationshipDefinition.childEntityType)?.getPermission('canRead') === 'Denied'
        || administratorBaseRole.rightByRelationshipType.get(relationshipDefinition)?.getPermission('canRead') === 'Denied'
        || administratorBaseRole.rightByRelationshipType.get(relationshipDefinition)?.getPermission(privilege) === 'Denied')
    {
        return true;
    }

    if (getEntityTypePermission(rolesByType, role, 'canRead', relationshipDefinition.parentEntityType) === 'Denied'
        || getEntityTypePermission(rolesByType, role, 'canRead', relationshipDefinition.childEntityType) === 'Denied')
    {
        return true;
    }

    switch (privilege)
    {
        case 'canCreate':
        case 'canUpdate':
        case 'canDelete':
            return ((administratorBaseRole.rightByType.get(relationshipDefinition.parentEntityType)?.getPermission('canUpdate') === 'Denied'
                     || getEntityTypePermission(rolesByType, role, 'canUpdate', relationshipDefinition.parentEntityType) === 'Denied')
                && (administratorBaseRole.rightByType.get(relationshipDefinition.childEntityType)?.getPermission('canUpdate') === 'Denied'
                    || getEntityTypePermission(rolesByType, role, 'canUpdate', relationshipDefinition.childEntityType) === 'Denied'))
                || getRelationshipDefinitionSpecifiedPermission(rolesByType, role, 'canRead', relationshipDefinition) === 'Denied';
        case 'canExport':
            return administratorBaseRole.rightByType.get(relationshipDefinition.parentEntityType)?.getPermission('canExport') === 'Denied'
                || getEntityTypePermission(rolesByType, role, 'canExport', relationshipDefinition.parentEntityType) === 'Denied'
                || administratorBaseRole.rightByType.get(relationshipDefinition.childEntityType)?.getPermission('canExport') === 'Denied'
                || getEntityTypePermission(rolesByType, role, 'canExport', relationshipDefinition.childEntityType) === 'Denied'
                || getRelationshipDefinitionSpecifiedPermission(rolesByType, role, 'canRead', relationshipDefinition) === 'Denied';
        default:
            return false;
    }
}