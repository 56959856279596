export function isValidUrl(value: string): boolean
{
    let url: URL | undefined;

    try
    {
        url = new URL(value);
    }
    catch (_)
    {
        return false;
    }

    return url.protocol === 'http:'
        || url.protocol === 'https:';
}
