import { useComputed } from 'mobx-react-lite';
import { default as ResourcePlannerModel } from '../Model/ResourcePlanner';
import ResourceSelection from '../Model/ResourceSelection';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import { Comparator } from '../../../DataObject/Model/Comparator';
import useTypes from '../../Type/Api/useTypes';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import useTrueValue from '../../../../../@Future/Util/Hook/useTrueValue';
import useFalseValue from '../../../../../@Future/Util/Hook/useFalseValue';
import { useContext } from 'react';
import CurrentUserContext from '../../../User/CurrentUserContext';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';

export default function useMyPlanner()
{
    const types = useTypes();
    const currentUserStore = useContext(CurrentUserContext);

    const trueValue = useTrueValue();
    const falseValue = useFalseValue();

    return useComputed(
        () =>
        {
            const employeeParameter =
                new Parameter(
                    'Employee',
                    new EntityValueType(types.Relationship.Person.Contact.Employee.Type),
                    true,
                    types.Relationship.Person.Contact.Employee.Type.getName());

            const currentUserEmployee =
                new EntityValue(currentUserStore.employeeEntity);

            const timeRegistrationParameter =
                types.TimeRegistration.Type &&
                    new Parameter(
                        'TimeRegistration',
                        new EntityValueType(types.TimeRegistration.Type),
                        true,
                        types.TimeRegistration.Type.getName());

            return new ResourcePlannerModel(
                employeeParameter,
                new ParameterDictionary([]),
                new CompositePredicate(
                    LogicalOperator.And,
                    [
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .field()),
                            Comparator.Equals,
                            currentUserEmployee),

                    ]),
                [
                    timeRegistrationParameter &&
                        new ResourceSelection(
                            timeRegistrationParameter,
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                    true),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .field(types.TimeRegistration.Field.StartDate),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .field(types.TimeRegistration.Field.EndDate),

                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            [
                                EntityPath.fromEntityType(types.TimeRegistration.Type)
                                    .joinTo(
                                        types.Relationship.RelationshipDefinition.TimeRegistrations,
                                        true)
                                    .field(),
                                EntityPath.fromEntityType(types.TimeRegistration.Type)
                                    .joinTo(
                                        types.Activity.RelationshipDefinition.TimeRegistrations,
                                        true)
                                    .field(),
                                EntityPath.fromEntityType(types.TimeRegistration.Type)
                                    .joinTo(
                                        types.TimeRegistration.RelationshipDefinition.Activity,
                                        false)
                                    .field(),
                                EntityPath.fromEntityType(types.TimeRegistration.Type)
                                    .joinTo(
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                        true)
                                    .field()
                            ])
                ].filter(selection => selection));
        },
        [
            types,
            trueValue,
            falseValue
        ]);
}
