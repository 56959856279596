import { BaseStore, CallbackType, getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { DragAndDropStore } from '../../DragAndDrop/DragAndDropStore';
import { Droppable } from '../../DragAndDrop/Model/DragAndDropListener';
import { DragAndDropBucketStore } from '../../DragAndDrop/Bar/Bucket/DragAndDropBucketStore';
import { ViewComponent } from '../../ViewStack/Model/ViewComponent';
import { Id, ListItemStore } from './Item/ListItemStore';
import { action, computed, isObservableArray, isObservableMap, observable, observe, runInAction } from 'mobx';
import { ListQuery } from './ListQuery';
import { TextStore } from '../../Text/TextStore';
import { ButtonStore } from '../../Button/ButtonStore';
import { ListDragProvider } from './Drag/ListDragProvider';
import { LocalizationStore } from '../../../../@Service/Localization/LocalizationStore';
import { ListGroupStore } from './ListGroupStore';
import { ListFragmentStore } from './Fragment/ListFragmentStore';

export type IdResolver<D> = (data: D) => Id;
export type DataLoader<D, Q> =  (query: ListQuery<Q>) => Promise<D[]>;
export type DataTransformer<D> = (data: D[]) => Promise<D[]>;
export type DataCounter<Q> =  (query: Q) => Promise<number>;
export type ItemStoreConstructor<D, I> = (data: D) => I;
export type DataComparator<D> = (lhs: D, rhs: D) => number;

export interface ListStoreProps<D, Q, I extends ListItemStore<D>, K, G>
{
    id: IdResolver<D>;
    load: DataLoader<D, Q>;
    transform?: DataTransformer<D>;
    construct: ItemStoreConstructor<D, I>;
    template?: PropType<ListStore<D, Q, I, K, G>, ListStoreProps<D, Q, I, K, G>, ViewComponent<ListStore<D, Q, I, K, G, unknown>>>;
    query?: CallbackType<ListStore<D, Q, I, K, G>, ListStoreProps<D, Q, I, K, G>, Q>;
    count?: DataCounter<Q>;
    comparator?: DataComparator<I>;
    group?: PropType<ListStore<D, Q, I, K, G>, ListStoreProps<D, Q, I, K, G>, GroupProps<D, I, G>>;
    drag?: ListDragProvider<D, K>
    showLoader?: PropType<ListStore<D, Q, I, K, G>, ListStoreProps<D, Q, I, K, G>, boolean>;
    isReactive?: PropType<ListStore<D, Q, I, K, G>, ListStoreProps<D, Q, I, K, G>, boolean>;
    createButton?: PropType<ListStore<D, Q, I, K, G>, ListStoreProps<D, Q, I, K, G>, ButtonStore>;
    showNoDataLabel?: PropType<ListStore<D, Q, I, K, G>, ListStoreProps<D, Q, I, K, G>, boolean>;
    noDataLabel?: PropType<ListStore<D, Q, I, K, G>, ListStoreProps<D, Q, I, K, G>, string>;
}

export type GroupIdResolver<G> = (group: G) => Id;
export type GroupResolver<D, G> = (data: D) => G;

export interface GroupProps<D, I extends ListItemStore<D>, G>
{
    id: GroupIdResolver<G>;
    group: GroupResolver<D, G>;
    template: (groupStore: ListGroupStore<D, I, G>, groupIdx: number, groupStores: Array<ListGroupStore<D, I, G>>) => ViewComponent;
    comparator?: DataComparator<G>
}

export const UngroupedKey = '';
export const ListIndentationDepth = 48;

export const listDefaultProps: Partial<ListStoreProps<any, any, any, any, any>> =
{
    transform: (d: any[]) => Promise.resolve(d),
    showLoader: true,
    isReactive: false,
    showNoDataLabel: true
};

/**
 * <D> item metadata
 * <Q> query metadata (what do we need to select?)
 * <I> item store (wrapper around the item that contains specific functionality for each item in the list)
 * <K> droppable metadata (what can be dropped into this list?)
 * <G> group metadata (the grouped value usually has a type)
 * <P> property type that can be extended from
 */
export class ListStore<D, Q, I extends ListItemStore<D>, K = D, G = {}, P = ListStoreProps<D, Q, I, K, G>> extends BaseStore<ListStoreProps<D, Q, I, K, G> & P> implements Droppable<D, K>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('DragAndDropStore') dragAndDropStore: DragAndDropStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable itemStores = observable.array<I>();

    // ------------------------ Constructor -------------------------

    constructor(props: ListStoreProps<D, Q, I, K, G> & P,
                defaultProps?: Partial<ListStoreProps<D, Q, I, K, G> & P>)
    {
        super(props, { ...listDefaultProps, ...defaultProps as any });
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return this.loadData();
    }

    entersUI(isMounted: boolean)
    {
        super.entersUI(isMounted);

        if (this.props.drag)
        {
            this.dragAndDropStore.listen(this);
        }

        if (this.isReactive)
        {
            this.registerDisposable(
                observe(
                    this,
                    'query',
                    change =>
                    {
                        if (this.isInitialized)
                        {
                            const query = change.newValue;

                            this.initializeQueryObserver(query);
                            return this.loadData(
                                query,
                                false
                            );
                        }
                    }
                )
            );

            this.initializeQueryObserver(this.query);
        }

        this.registerDisposable(
            observe(
                this,
                'sortedItemStores',
                change =>
                {
                    runInAction(
                        () =>
                        {
                            change.newValue.forEach(
                                (itemStore, idx) =>
                                {
                                    itemStore.listIdx = idx;
                                });
                        });
                }
            )
        );

        this.registerDisposable(
            observe(
                this,
                'groupStores',
                change =>
                {
                    runInAction(
                        () =>
                        {
                            const groupedItemStores = new Set();

                            change.newValue.forEach(
                                (groupStore, groupIdx) =>
                                {
                                    groupStore.itemStores.forEach(
                                        itemStore =>
                                        {
                                            groupedItemStores.add(itemStore);

                                            itemStore.listGroupId = groupStore.id;
                                            itemStore.listGroupIdx = groupIdx;
                                        });
                                });

                            this.sortedItemStores
                                .forEach(
                                    itemStore =>
                                    {
                                        if (!groupedItemStores.has(itemStore))
                                        {
                                            itemStore.listGroupId = undefined;
                                            itemStore.listGroupIdx = undefined;
                                        }
                                    });
                        });
                }));
    }

    exitsUI(isMounted: boolean)
    {
        if (this.props.drag)
        {
            this.dragAndDropStore.unlisten(this);
        }
    }

    initializeQueryObserver(query: Q)
    {
        if (isObservableArray(query) || isObservableMap(query))
        {
            this.registerDisposable(
                observe(
                    query,
                    () =>
                        this.loadData(
                            query,
                            false
                        )
                )
            );
        }
    }

    // -------------------------- Computed --------------------------

    @computed
    get query(): Q
    {
        return getOrCompute(this, this.props.query);
    }

    @computed
    get isReactive(): boolean
    {
        return getOrCompute(this, this.props.isReactive);
    }

    @computed
    get template(): ViewComponent<I>
    {
        return getOrCompute(this, this.props.template);
    }

    @computed
    get group(): GroupProps<D, I, G>
    {
        return getOrCompute(this, this.props.group);
    }

    @computed
    get showLoader(): boolean
    {
        return getOrCompute(this, this.props.showLoader);
    }

    @computed
    get visibleItemStores(): I[]
    {
        return this.sortedItemStores
            .filter(
                itemStore =>
                    !itemStore.isHidden);
    }

    @computed
    get predecessorMap(): Map<I, I>
    {
        return this.getPredecessorMap(this.sortedItemStores);
    }

    @computed
    get predecessorMapByGroupId(): Map<Id, Map<I, I>>
    {
        const map = new Map<Id, Map<I, I>>();

        this.groupStores.forEach(
            groupStore =>
                map.set(
                    groupStore.id,
                    this.getPredecessorMap(groupStore.itemStores)));

        return map;
    }

    @computed
    get successorMap(): Map<I, I>
    {
        return this.getSuccessorMap(this.sortedItemStores);
    }

    @computed
    get successorMapByGroupId(): Map<Id, Map<I, I>>
    {
        const map = new Map<Id, Map<I, I>>();

        this.groupStores.forEach(
            groupStore =>
                map.set(
                    groupStore.id,
                    this.getSuccessorMap(groupStore.itemStores)));

        return map;
    }

    @computed
    get isEmpty(): boolean
    {
        return this.groupStores.length === 0
            && this.visibleItemStores.length === 0;
    }

    @computed
    get sortedGroupIds(): Id[]
    {
        return this.groupStores
            .map(groupStore =>
                groupStore.id);
    }

    @computed
    get groupColumnWidth(): any
    {
        if (this.sortedGroupIds.length > 2)
        {
            return 4;
        }
        else if (this.sortedGroupIds.length > 0)
        {
            return 12 / this.sortedGroupIds.length;
        }
        else
        {
            return 0;
        }
    }

    @computed
    get ids(): Set<Id>
    {
        const ids = new Set<Id>();

        this.itemStores.forEach(
            itemStore =>
                ids.add(itemStore.id));

        return ids;
    }

    @computed
    get isDraggable(): boolean
    {
        return this.props.drag !== undefined;
    }

    @computed
    get showNoDataLabel(): boolean
    {
        return getOrCompute(this, this.props.showNoDataLabel);
    }

    @computed
    get noDataLabel(): string
    {
        const noData = getOrCompute(this, this.props.noDataLabel);

        return noData
            ?
                noData
            :
                this.localizationStore.translate('Generic.NoData');  // There is no data available.
    }

    @computed
    get groupStores(): Array<ListGroupStore<D, I, G>>
    {
        if (this.group)
        {
            const groupValueById = new Map<Id, G>();
            const storeById = new Map<Id, ListGroupStore<D, I, G>>();

            this.sortedItemStores
                .forEach(
                    itemStore =>
                    {
                        let groupValue =
                            this.group.group(itemStore.data);

                        if (groupValue === undefined)
                        {
                            // undefined should be cast to null such that it is still included in the groupById
                            groupValue = null;
                        }

                        const groupId =
                            groupValue == null
                                ?
                                    UngroupedKey
                                :
                                    this.group.id(groupValue).toString();

                        groupValueById.set(
                            groupId,
                            groupValue);

                        if (!storeById.has(groupId))
                        {
                            storeById.set(
                                groupId,
                                new ListGroupStore(
                                    groupId,
                                    groupValue == null
                                        ?
                                            undefined
                                        :
                                            groupValue,
                                    []));
                        }

                        storeById.get(groupId)
                            .itemStores
                            .push(itemStore);
                    });

            const groups = Array.from(storeById.values());

            if (this.group.comparator)
            {
                return groups.sort(
                    (g1, g2) =>
                        this.group.comparator(g1.value, g2.value));
            }
            else
            {
                return groups;
            }
        }
        else
        {
            return [];
        }
    }

    // --------------------------- Stores ---------------------------

    @computed
    get emptyTextStore(): TextStore
    {
        return new TextStore(
        {
            label: () => this.noDataLabel
        });
    }

    @computed
    get createButton(): ButtonStore
    {
        return getOrCompute(this, this.props.createButton);
    }

    @computed
    get listFragmentStore(): ListFragmentStore<D>
    {
        return new ListFragmentStore<D>(this, this.sortedItemStores);
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    appendData(data: D[]): I[]
    {
        const itemStores =
            data.map(item =>
                this.construct(item));

        this.appendItemStores(itemStores);

        return itemStores;
    }

    @action.bound
    addData(data: D,
            atIdx: number)
    {
        this.addItemStore(
            this.construct(data),
            atIdx);
    }

    @action.bound
    deleteData(data: D)
    {
        const itemStore = this.itemStores.find(item => item.data === data);

        if (itemStore)
        {
            this.deleteItemStore(itemStore);
        }
    }

    @action.bound
    replaceItemStores(itemStores: I[])
    {
        this.itemStores = observable.array<I>();
        this.itemStores.push(...itemStores);

        this.initializeItemStores();
    }

    @action.bound
    appendItemStores(itemStores: I[])
    {
        itemStores =
            itemStores.filter(
                itemStore =>
                    !this.ids.has(itemStore.id));

        this.itemStores.push(...itemStores);

        this.initializeItemStores();
    }

    @action.bound
    addItemStore(itemStore: I,
                 atIdx: number)
    {
        this.itemStores.splice(
            atIdx,
            0,
            itemStore);

        this.initializeItemStores();
    }

    @action.bound
    deleteItemStore(itemStore: I)
    {
        this.itemStores.remove(itemStore);

        this.initializeItemStores();
    }

    @action.bound
    initializeItemStores()
    {
        if (this.props.comparator)
        {
            this.itemStores.replace(this.sortedItemStores);
        }

        this.itemStores.forEach(
            (itemStore, idx) =>
            {
                itemStore.listIdx = idx;
                itemStore.listStore = this;
            });
    }

    @computed
    get sortedItemStores(): I[]
    {
        if (this.props.comparator)
        {
            return this.itemStores
                .slice()
                .sort(this.props.comparator);
        }
        else
        {
            return this.itemStores;
        }
    }

    // ------------------------ Public logic ------------------------

    loadData(query: Q = this.query,
             doAppend: boolean = true): Promise<any>
    {
        return this.props.load(new ListQuery<Q>(query))
            .then(
                dataItems =>
                    this.processDataItems(dataItems, doAppend));
    }

    transformDataItems(dataItems: D[])
    {
        if (this.props.transform)
        {
            return this.props.transform(dataItems);
        }
        else
        {
            return Promise.resolve(dataItems);
        }
    }

    processDataItems(dataItems: D[],
                     doAppend: boolean)
    {
        return this.transformDataItems(dataItems)
            .then(
                transformedDataItems =>
                {
                    if (transformedDataItems !== undefined)
                    {
                        const itemStores =
                            transformedDataItems.map(
                                data =>
                                    this.construct(data));

                        if (doAppend)
                        {
                            this.appendItemStores(itemStores);
                        }
                        else
                        {
                            this.replaceItemStores(itemStores);
                        }
                    }

                    return Promise.resolve(transformedDataItems);
                });
    }

    construct(data: D): I
    {
        const itemStore = this.props.construct(data);
        itemStore.id = this.props.id(data);
        itemStore.data = data;

        return itemStore;
    }

    droppableId(): string
    {
        return this.uuid;
    }

    droppableType(): string
    {
        return this.props.drag.type;
    }

    droppableItemAdded(item: K,
                       index: number,
                       source: Droppable<K>,
                       rollback: () => void): Promise<any>
    {
        if (!this.props.drag.isForeign || !this.props.drag.isForeign(item))
        {
            this.addData(
                item as any,
                index);
        }

        if (this.props.drag)
        {
            return this.props.drag.add(
                item,
                index,
                rollback,
                this);
        }
        else
        {
            return Promise.resolve();
        }
    }

    droppableItemMoved(item: D,
                       sourceIndex: number,
                       destinationIndex: number,
                       droppable: Droppable<D>,
                       rollback: () => void): void
    {
        this.itemStores.splice(
            destinationIndex,
            0,
            this.itemStores.splice(sourceIndex, 1)[0]);

        // this.removeDataItem(item, sourceIndex);
        //
        // By removing the item from the list, the destination index might change
        // this happens when the item is removed BEFORE its destination position
        let destinationOffset = 0;

        if (sourceIndex < destinationIndex)
        {
            destinationOffset = -1;
        }
        //
        // this.addDataItem(item, destinationIndex + destinationOffset);

        if (this.props.drag.move)
        {
            this.props.drag.move(
                item,
                sourceIndex,
                destinationIndex,
                destinationOffset,
                rollback,
                this);
        }
    }

    droppableItemRemoved(item: D,
                         index: number,
                         destination: Droppable<D>,
                         onAdd: Promise<any>,
                         rollback: () => void)
    {
        this.deleteData(item);

        if (this.props.drag.remove)
        {
            this.props.drag.remove(
                item,
                index,
                onAdd,
                rollback,
                this);
        }
    }

    droppableItem(id: string): any
    {
        // expected id format = this.store.uuid + '.' + itemId
        let itemId = id.substring(id.indexOf('.') + 1);

        if (itemId)
        {
            let item = this.itemStores
                .find(itemStore =>
                    itemStore.id.toString() === itemId);

            if (item)
            {
                return item.data;
            }
            else
            {
                return null;
            }
        }
        else
        {
            return null;
        }
    }

    droppableBuckets(): Array<DragAndDropBucketStore<D>>
    {
        if (this.props.drag.buckets)
        {
            return this.props.drag.buckets(this);
        }
        else
        {
            return [];
        }
    }

    getPredecessor(itemStore: I,
                   groupId?: Id): I
    {
        if (groupId === undefined)
        {
            return this.predecessorMap.get(itemStore);
        }
        else
        {
            if (this.predecessorMapByGroupId.has(groupId))
            {
                return this.predecessorMapByGroupId.get(groupId).get(itemStore);
            }
            else
            {
                return undefined;
            }
        }
    }

    getSuccessor(itemStore: I,
                 groupId?: Id): I
    {
        if (groupId === undefined)
        {
            return this.successorMap.get(itemStore);
        }
        else
        {
            if (this.successorMapByGroupId.has(groupId))
            {
                return this.successorMapByGroupId.get(groupId).get(itemStore);
            }
            else
            {
                return undefined;
            }
        }
    }

    // ----------------------- Private logic ------------------------

    private getPredecessorMap(itemStores: I[]): Map<I, I>
    {
        const visibleItemStores =
            itemStores.filter(
                itemStore =>
                    !itemStore.isHidden);

        const map = new Map<I, I>();

        for (let idx = 1; idx < visibleItemStores.length; idx++)
        {
            const predecessor = visibleItemStores[idx - 1];
            const item = visibleItemStores[idx];

            map.set(item, predecessor);
        }

        return map;
    }

    private getSuccessorMap(itemStores: I[]): Map<I, I>
    {
        const visibleItemStores =
            itemStores.filter(
                itemStore =>
                    !itemStore.isHidden);

        const map = new Map<I, I>();

        for (let idx = 0; idx < visibleItemStores.length - 1; idx++)
        {
            const item = visibleItemStores[idx];
            const successor = visibleItemStores[idx + 1];

            map.set(item, successor);
        }

        return map;
    }
}
