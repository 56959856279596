import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import PortalImageLayout from '../../../../../@Api/Layout/Type/PortalImageLayout';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { ApiClient } from '../../../../../@Service/ApiClient/ApiClient';
import { useFetchedApiFile } from '../../../../../@Service/ApiClient/Hooks/useFetchedApiFile';

export interface PortalImageLayoutViewerProps extends LayoutViewerProps<PortalImageLayout>
{

}

const PortalImageLayoutViewer: React.FC<PortalImageLayoutViewerProps> =
    props =>
    {
        const url =
            useComputed(
                () =>
                    props.layout.resourceId
                        ?
                            loadModuleDirectly(ApiClient).url(`/portal/resource/${props.layout.resourceId}`)
                        :
                            undefined,
                [
                    props.layout
                ]);
        const [ initializedUrl ] = useFetchedApiFile(url);
        const style =
            useComputed(
                () =>
                    ({
                        width: props.layout.width?.toCss(),
                        height: props.layout.height?.toCss()
                    }),
                [
                    props.layout
                ]);

        if (initializedUrl)
        {
            return <img
                src={initializedUrl}
                style={style}
                alt=""
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(PortalImageLayoutViewer);
