import React, { useCallback, useEffect, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Addressee from '../../Model/Addressee';
import Centered from '../../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../../@Future/Component/Generic/Loader/Loader';
import Item from '../../../../../../Item/Item';
import Inset from '../../../../../../Item/Content/Inset/Inset';
import Region from '../../../../../../Item/Content/Click/Region/Region';
import Tooltip from '../../../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import CardInset from '../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import useRelationshipByAddressee from '../../Api/useRelationshipByAddressee';
import Selectbox from '../../../../../../Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../../../../../Selection/Builder/EntitySelectionBuilder';
import useTypes from '../../../../../../Type/Api/useTypes';
import useSwitch from '../../../../../../../../../@Util/Switch/useSwitch';
import Input from '../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import useRelationshipAddresseeAugmenter from '../../Api/useRelationshipAddresseeAugmenter';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { constructEntityOfType } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { setValueByFieldInEntity } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';

export interface LinkAddresseeProps
{
    email: Entity;
    addressee: Addressee;
    linkedRecipient?: Entity;
    onLink: (addressee: Addressee, entity: Entity) => void;
    commitContext: CommitContext;
}

const LinkAddressee: React.FC<LinkAddresseeProps> =
    props =>
    {
        const types = useTypes();
        const [ isLinking, startLinking, stopLinking ] = useSwitch(false);
        const selectEntity =
            useCallback(
                (relationship: Entity) =>
                {
                    let recipient = props.linkedRecipient;

                    if (!recipient)
                    {
                        recipient =
                            constructEntityOfType(
                                types.Recipient.Email.Type,
                                props.commitContext
                            );

                        switch (props.addressee.type)
                        {
                            case 'To':
                                updateRelationship(
                                    recipient,
                                    true,
                                    types.Activity.Email.RelationshipDefinition.To,
                                    props.email,
                                    props.commitContext
                                );
                                break;

                            case 'CC':
                                updateRelationship(
                                    recipient,
                                    true,
                                    types.Activity.Email.RelationshipDefinition.CC,
                                    props.email,
                                    props.commitContext
                                );
                                break;

                            case 'BCC':
                                updateRelationship(
                                    recipient,
                                    true,
                                    types.Activity.Email.RelationshipDefinition.BCC,
                                    props.email,
                                    props.commitContext
                                );
                                break;
                        }
                    }

                    updateRelationship(
                        recipient,
                        false,
                        types.Recipient.RelationshipDefinition.Addressee,
                        relationship,
                        props.commitContext
                    );
                    setValueByFieldInEntity(
                        recipient,
                        types.Recipient.Email.Field.EmailAddress,
                        props.addressee.email,
                        props.commitContext
                    );

                    if (!props.linkedRecipient)
                    {
                        props.onLink(props.addressee, recipient);
                    }

                    stopLinking();
                },
                [
                    props.linkedRecipient,
                    props.commitContext,
                    props.onLink,
                    props.addressee,
                    props.email,
                    stopLinking,
                    types
                ]
            );
        const [ relationship, isLoading ] = useRelationshipByAddressee(props.addressee, false);

        useEffect(
            () =>
            {
                if (!isLoading && relationship)
                {
                    selectEntity(relationship);
                }
            },
            [
                relationship,
                isLoading,
                selectEntity
            ]);

        const selections =
            useMemo(
                () => [
                    new EntitySelectionBuilder(types.Relationship.Type).selection
                ],
                [
                    types
                ]);
        const onConstruct =
            useRelationshipAddresseeAugmenter(
                props.addressee,
                props.commitContext
            );
        const addresseeName =
            props.addressee.name
                ? `${props.addressee.name} (${props.addressee.email})`
                : props.addressee.email;
        const linkedRelationship =
            useComputed(
                () =>
                    props.linkedRecipient?.getRelatedEntityByDefinition(
                        false,
                        types.Recipient.RelationshipDefinition.Addressee,
                        props.commitContext
                    ),
                [
                    props.linkedRecipient,
                    props.commitContext,
                    types,
                ]
            );

        if (linkedRelationship)
        {
            return <Item
                horizontalInset
                verticalInset
                entity={linkedRelationship}
            />;
        }
        else if (isLoading)
        {
            return <Centered
                horizontal
            >
                <Loader />
            </Centered>;
        }
        else if (isLinking)
        {
            return <CardInset>
                <Input
                    labelPosition="top"
                    label={
                        <LocalizedText
                            code="ExternalEmailLinkAddressee"
                            value="${name} nu koppelen"
                            name={addresseeName}
                        />
                    }
                >
                    <Selectbox
                        selections={selections}
                        onChange={selectEntity}
                        onConstruct={onConstruct}
                        autoFocus
                    />
                </Input>
            </CardInset>;
        }
        else
        {
            return <Tooltip
                title={
                    <LocalizedText
                        code="ExternalEmailLinkAddressee"
                        value="${name} nu koppelen"
                        name={props.addressee.name || props.addressee.email}
                    />
                }
                block
            >
                <Region
                    clickable
                    onClick={startLinking}
                >
                    <Inset
                        horizontal
                        vertical
                    >
                        <LocalizedText
                            code="ExternalEmailAddresseeNotLinkedYet"
                            value="<strong>${addresseeName}</strong> is nog niet gekoppeld"
                            addresseeName={addresseeName}
                            containsHTML
                        />
                    </Inset>
                </Region>
            </Tooltip>;
        }
    };

export default observer(LinkAddressee);
