import { useMemo } from 'react';
import { Entity } from '../../Model/Implementation/Entity';
import getFreshTransactionalEntity from './getFreshTransactionalEntity';

export default function useFreshTransactionalEntity(entity: Entity)
{
    return useMemo(
        () =>
            getFreshTransactionalEntity(entity),
        [
            entity
        ]);
}
