import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Member.module.scss';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Entity/Type/Api/useTypes';
import { openEntity } from '../../../../../Entity/@Util/openEntity';
import IconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { CommitBuilder } from '../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export interface MemberProps
{
    member: Entity;
    team: Entity;
}

const Member: React.FC<MemberProps> =
    props =>
    {
        const types = useTypes();

        const click =
            useCallback(
                () =>
                    openEntity(props.member),
                [
                    props.member
                ]);

        const unmember =
            useCallback(
                () =>
                    new CommitBuilder()
                        .relateEntityTo(
                            props.member,
                            true,
                            types.Team.RelationshipDefinition.Members,
                            undefined,
                            props.team
                        )
                        .commit(),
                [
                    props.member,
                    props.team,
                    types,
                ]);

        return <div
            className={styles.root}
            onClick={click}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={15}
                alignment="center"
            >
                <ViewGroupItem
                    ratio={1}
                >
                    {props.member.name}
                </ViewGroupItem>
                <ViewGroupItem>
                    <IconButton
                        icon="link_off"
                        onClick={unmember}
                        tooltip={
                            <LocalizedText
                                code="Configuration.Teams.RemoveTeamMember"
                                value="Lid uit team zetten"
                            />
                        }
                    />
                </ViewGroupItem>
            </ViewGroup>
        </div>;
    };

export default observer(Member);
