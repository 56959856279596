import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import ExpansionGroup from '../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import ExpansionGroupLayout from '../../../../../@Api/Layout/Type/ExpansionGroupLayout';
import Layout from '../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';

export interface ExpansionGroupLayoutEditorProps extends LayoutEditorProps<ExpansionGroupLayout>
{

}

const ExpansionGroupLayoutEditor: React.FC<ExpansionGroupLayoutEditorProps> =
    props =>
    {
        const setLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.layout = layout),
                [
                    props.layout
                ]);

        return <ExpansionGroup>
            <ChildLayoutEditor
                {...props}
                childLayout={props.layout.layout}
                onChange={setLayout}
            />
        </ExpansionGroup>;
    };

export default observer(ExpansionGroupLayoutEditor);
