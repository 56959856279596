import React, { useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';
import LocalizerContext from '../../../../../../@Service/Localization/LocalizerContext';
import { isAllDay } from '../../../Calendar/Util/isAllDay';
import useIsAttachedEntityType from '../../Navigator/Api/useIsAttachedEntityType';
import Click from '../Click/Click';
import Inset from '../Inset/Inset';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import styles from './Activity.module.scss';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Caption from '../../Caption/Caption';
import Link from '../../Caption/Link/Link';
import CloseStateButton from '../../../Button/CloseStateButton/CloseStateButton';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { textSecondaryColor } from '../../../../../../@Resource/Theme/Theme';

export interface ActivityProps
{
    entity: Entity;
}

const useStyles = makeStyles({
    aggregate:
        {
            textAlign: 'right',
            fontWeight: 'bolder'
        },
    aggregateInCurrency:
        {
            fontSize: 'smaller',
            textAlign: 'right',
            //fontWeight: 'bolder',
            color: textSecondaryColor,
        },
});

const Activity: React.FC<ActivityProps> =
    props =>
    {
        const types = useTypes();
        const classes = useStyles();
        const localizer = useContext(LocalizerContext);

        const relationship =
            useComputed(
                () =>
                    props.entity.getRelatedEntityByDefinition(
                        true,
                        types.Relationship.RelationshipDefinition.Activities),
                [
                    props.entity,
                    types
                ]);

        const aggregateInCurrency =
            useComputed(
                () =>
                {
                    const currency = props.entity.getObjectValueByField(types.Activity.Field.Currency);
                    if (!currency)
                    {
                        return undefined;
                    }
                    else if (props.entity.entityType.isA(types.Activity.SalesOpportunity.Type)
                        || props.entity.entityType.isA(types.Activity.Offer.Type)
                        || props.entity.entityType.isA(types.Activity.Invoice.Type))
                    {
                        return props.entity
                            .getDataObjectValueByField(types.Activity.Field.AmountInCurrency)
                            .toCurrencyString(currency);
                    }
                    else if (currency && props.entity.entityType.isA(types.Activity.Subscription.Type))
                    {
                        return props.entity
                            .getDataObjectValueByField(types.Activity.Field.TotalRecurringSalesExcludingVatInCurrency)
                            .toCurrencyString(currency);
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.entity,
                    types
                ]);

        const aggregate =
            useComputed(
                () =>
                {
                    if (props.entity.entityType.isA(types.Activity.SalesOpportunity.Type)
                        || props.entity.entityType.isA(types.Activity.Offer.Type)
                        || props.entity.entityType.isA(types.Activity.Invoice.Type))
                    {
                        return props.entity.getDataObjectValueByField(types.Activity.Field.Amount).toString();
                    }
                    else if (props.entity.entityType.isA(types.Activity.Subscription.Type))
                    {
                        return props.entity.getDataObjectValueByField(types.Activity.Field.TotalRecurringSalesExcludingVat).toString();
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.entity,
                    types
                ]);

        const startDate =
            useComputed(
                () =>
                    props.entity.getObjectValueByField(types.Activity.Task.Field.StartDate),
                [
                    props.entity
                ]);

        const endDate =
            useComputed(
                () =>
                    props.entity.getObjectValueByField(types.Activity.Task.Field.EndDate),
                [
                    props.entity
                ]);

        const allDay =
            useMemo(
                () =>
                    isAllDay(startDate, endDate),
                [
                    startDate,
                    endDate
                ]);

        const isOverdue =
            useMemo(
                () =>
                    allDay ? moment().startOf('day').isAfter(startDate) : moment().isAfter(startDate),
                [
                    allDay,
                    startDate
                ]);

        const isAttachedType = useIsAttachedEntityType(props.entity.entityType);
        const parentActivity =
            useComputed(
                () =>
                    props.entity.getRelatedEntityByDefinition(
                        true,
                        types.Activity.RelationshipDefinition.LinkedActivities),
                [
                    props.entity,
                    types
                ]);

        return <Click
            open
            allowExpansionHighlight
        >
            <Inset>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    className={classNames(styles.root)}
                >
                    <ViewGroupItem>
                        <div>
                            <ViewGroup
                                orientation="vertical"
                                spacing={0}
                            >
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={10}
                                        alignment="center"
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <div
                                                className={styles.name}
                                            >
                                                {(relationship || props.entity).name}
                                            </div>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <span
                                                className={classNames(styles.date, isOverdue && styles.overdue)}
                                            >
                                                {
                                                    allDay
                                                        ?
                                                            localizer.formatDate(startDate)
                                                        :
                                                            localizer.formatDateTime(startDate)
                                                }
                                            </span>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={10}
                                        alignment="center"
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            {
                                                relationship &&
                                                    <div
                                                        className={styles.description}
                                                    >
                                                        {props.entity.name}
                                                    </div>
                                            }
                                            {
                                                isAttachedType && parentActivity &&
                                                    <Caption
                                                        entity={props.entity}
                                                        classes={{
                                                            root: styles.caption
                                                        }}
                                                    >
                                                        {parentActivity.entityType.getName()} <Link entity={parentActivity} />
                                                    </Caption>
                                            }
                                        </ViewGroupItem>
                                        {
                                            props.entity.entityType.isA(types.Activity.Task.Type) &&
                                                <ViewGroupItem>
                                                    <CloseStateButton
                                                        entity={props.entity}
                                                    />
                                                </ViewGroupItem>
                                        }
                                    </ViewGroup>
                                </ViewGroupItem>
                            </ViewGroup>
                            <ViewGroup
                                orientation="vertical"
                                spacing={0}
                            >
                                {
                                    aggregate &&
                                    <ViewGroupItem
                                        className={classes.aggregate}
                                    >
                                        {aggregate}
                                    </ViewGroupItem>
                                }
                                {
                                    aggregateInCurrency &&
                                    <ViewGroupItem
                                        className={classes.aggregateInCurrency}
                                    >
                                        {aggregateInCurrency}
                                    </ViewGroupItem>
                                }
                            </ViewGroup>
                        </div>
                    </ViewGroupItem>
                </ViewGroup>
            </Inset>
        </Click>;
    };

export default observer(Activity);
