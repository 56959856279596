import { action, computed } from 'mobx';
import { ToolbarElementCommandStore } from '../ToolbarElementCommandStore';
import { ToolbarColorPicker } from './ToolbarColorPicker';
import { ColorPickerStore, RGBA } from '../../../../ColorPicker/ColorPickerStore';
import { ColorUtils } from '../../../../../../@Util/Color/ColorUtils';

export class ToolbarColorPickerStore extends ToolbarElementCommandStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(id: string | number,
                command: string)
    {
        super(id, ToolbarColorPicker, command, true);

        this.collapsible = true;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    @computed
    get colorPickerStore(): ColorPickerStore
    {
        return new ColorPickerStore('#ff0000', false, (hex, rgba) => this.passColor(hex));
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    setColor(color: RGBA)
    {
        this.colorPickerStore.change(color);
    }

    @action.bound
    passColor(color: string)
    {
        if (color)
        {
            this.setFormat(this.command, color);
        }
    }

    passEventInternal(event: any)
    {
        super.passEventInternal(event);

        // Change state
        if (event[this.command])
        {
            // If there are multiple colors selected take the first one
            if (Array.isArray(event[this.command]))
            {
                this.setColor(
                    ColorUtils.hexToRgba(event[this.command][0]));
            }
            else
            {
                this.setColor(
                    ColorUtils.hexToRgba(event[this.command]));
            }
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
