import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';

export default function useCampaignPhaseCode(campaign: Entity)
{
    const types = useTypes();

    const phase =
        useComputed(
            () =>
                campaign
                    .getRelatedEntityByDefinition(
                        false,
                        types.Activity.Campaign.RelationshipDefinition.Phase),
            [
                campaign,
                types
            ]);

    return useComputed(
            () =>
                phase?.getDataObjectValueByField(types.Datastore.Field.Code).value,
            [
                phase,
                types
            ]);
}
