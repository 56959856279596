import React, { useMemo } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { LogEvent } from '../../Model/LogEvent';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/styles';
import { LogLevelViewer } from '../../LevelViewer/LogLevelViewer';

const useStyles =
    makeStyles({
        message: {
            whiteSpace: 'pre-line',
        },
    });

export interface LogEventRowProps
{
    event: LogEvent;
}

export const LogEventRow: React.FC<LogEventRowProps> =
    ({
        event,
     }) =>
    {
        const classes = useStyles();
        const date =
            useMemo(
                () =>
                    format(
                        new Date(event.date),
                        'dd-MM-yyyy HH:mm:ss'
                    ),
                [
                    event,
                ]
            );

        return <TableRow>
            <TableCell
                valign="top"
            >
                {date}
            </TableCell>
            <TableCell
                valign="top"
            >
                <LogLevelViewer
                    level={event.level}
                />
            </TableCell>
            <TableCell
                valign="top"
                className={classes.message}
            >
                {event.message}
            </TableCell>
        </TableRow>;
    };
