import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Layout from '../../../../../@Api/Layout/Layout';
import LayoutEditor, { LayoutEditorProps } from '../LayoutEditor';
import ParentLayoutsContext from './ParentLayoutsContext';

export interface ChildLayoutEditorProps<L extends Layout> extends LayoutEditorProps<L>
{
    childLayout: Layout;
}

const ChildLayoutEditor: React.FC<ChildLayoutEditorProps<any>> =
    props =>
    {
        const parentLayouts = useContext(ParentLayoutsContext);
        const newParentLayouts =
            useMemo(
                () => [
                    props.layout,
                    ...parentLayouts
                ],
                [
                    parentLayouts,
                    props.layout
                ]);

        return <ParentLayoutsContext.Provider
            value={newParentLayouts}
        >
            <LayoutEditor
                {...props}
                layout={props.childLayout}
            />
        </ParentLayoutsContext.Provider>;
    };

export default observer(ChildLayoutEditor);
