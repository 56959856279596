import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Role, { RoleType } from '../../../Model/Role';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { RightEditor } from '../../RightEditor/RightEditor';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Link from '../../../../../../@Future/Component/Generic/Link/Link';
import styles from '../../RoleEditor.module.scss';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import { RoleEntityTypeTreeProps } from '../RoleEntityTypeTree';
import { EntityTypePermission } from '../../../Model/EntityMetadataPermission';
import { RightPredicateButton } from '../../RightPredicateEditor/RightPredicateButton';

export interface ItemEditorProps extends RoleEntityTypeTreeProps
{
    rolesByType: Map<RoleType, Role>;
    label?: React.ReactNode;
    indent?: number;
    isReadOnly: boolean;
}

export const ItemEditor: React.FC<ItemEditorProps> =
    observer(
        ({
             rolesByType,
             role,
             entityType,
             onOpen,
             label,
             isReadOnly,
         }) =>
        {
            const onOpenEntityType =
                useCallback(
                    () =>
                        onOpen(entityType),
                    [
                        onOpen,
                        entityType
                    ]);

            const metadataPermission: EntityTypePermission =
                useMemo(
                    () =>
                    {
                        return {
                            type: 'EntityType',
                            entityType: entityType,
                        };
                    },
                    [
                        entityType
                    ]
                );

            return <CardInset
                right={false}
                left={false}
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={0}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <Link
                            onClick={onOpenEntityType}
                        >
                            {label || entityType.getName()}
                        </Link>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <RightPredicateButton
                            rolesByType={rolesByType}
                            role={role}
                            metadataPermission={metadataPermission}
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem
                        className={classNames(styles.root, styles.treeItem)}
                    >
                        <RightEditor
                            rolesByType={rolesByType}
                            role={role}
                            metadataPermission={metadataPermission}
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </CardInset>;
        }
    );
