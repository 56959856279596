export const ErrorDialogTranslations: any = {
    nl: {
        title: 'Tribe kan niet worden gestart',
        description: 'Mogelijk is de server onbereikbaar. Controleer uw internetverbinding of probeer het later opnieuw.',
        action: 'Opnieuw proberen',
    },
    en: {
        title: 'Tribe cannot be started',
        description: 'The server may be unreachable. Please check your internet connection or try again later.',
        action: 'Try again',
    },
    de: {
        title: 'Tribe kann nicht gestartet werden',
        description: 'Der Server ist möglicherweise nicht erreichbar. Bitte überprüfen Sie Ihre Internetverbindung oder versuchen Sie es später erneut.',
        action: 'Versuchen Sie es erneut',
    },
    es: {
        title: 'No se puede iniciar Tribe',
        description: 'No se puede acceder al servidor. Por favor comprueba tu conexión a Internet o intenta de nuevo más tarde.',
        action: 'Intenta de nuevo',
    },
    fr: {
        title: 'Tribe ne peut pas être démarré',
        description: 'Le serveur est peut-être inaccessible. Veuillez vérifier votre connexion Internet ou réessayer plus tard.',
        action: 'Réessayer',
    },
    sv: {
        title: 'Tribe kan inte startas',
        description: 'Servern kanske inte går att nå. Kontrollera din internetanslutning eller försök igen senare.',
        action: 'Försök igen',
    },
    no: {
        title: 'Tribe kan ikke startes',
        description: 'Serveren kan være utilgjengelig. Sjekk Internett-tilkoblingen din eller prøv igjen senere.',
        action: 'Prøv igjen',
    }
}