import { BaseStore, getOrComputeProperty } from '../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';

export interface BadgeProps
{
    icon?: ((store: BadgeStore) => string) | string;
    label?: ((store: BadgeStore) => string) | string;
    isVisible?: ((store: BadgeStore) => boolean) | boolean;
    provider?: () => Promise<string>;
}

const defaultProps: BadgeProps =
{
    icon: () => undefined,
    label: () => undefined,
    isVisible: () => true
};

export class BadgeStore extends BaseStore<BadgeProps>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable providedLabel: string;

    // ------------------------ Constructor -------------------------

    constructor(props: BadgeProps)
    {
        super(props, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        if (this.props.provider)
        {
            return this.props.provider()
                .then(this.setProvidedLabel);
        }
        else
        {
            return Promise.resolve();
        }
    }

    // -------------------------- Computed --------------------------

    @computed
    get icon(): string
    {
        return getOrComputeProperty(this, 'icon');
    }

    @computed
    get label(): string
    {
        return getOrComputeProperty(this, 'label'); // || this.providedLabel;
    }

    @computed
    get isVisible(): boolean
    {
        return getOrComputeProperty(this, 'isVisible');
    }

    @computed
    get finalLabel(): string
    {
        return this.label || this.providedLabel;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    setProvidedLabel(label: string)
    {
        // this.props.label = label;
        this.providedLabel = label;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
