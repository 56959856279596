import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { loadModuleDirectly } from '../../../../../../../../@Util/DependencyInjection/index';
import { PredicateTypeStore } from '../../../../../../Predicate/PredicateTypeStore';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../../../Type/Api/useTypes';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { default as ViewModel } from '../../../../../View/Model/View';
import Specification from '../../../../../View/Model/Specification';
import { default as ListModel } from '../../../../../View/Model/Specification/List';
import Column from '../../../../../View/Model/Specification/Column';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import StaticSelectbox from '../../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import { default as LabelInput } from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import List from '../../../../../View/List/List';
import ExpansionPanel from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { v4 as uuid } from 'uuid';
import CardHeader from '../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import { LogicalOperator } from '../../../../../../DataObject/Model/LogicalOperator';
import { CampaignProps } from '../Campaign';
import useCampaignRootType from '../Api/useCampaignRootType';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import ScrollRefContext from '../../../../../../../../@Service/Navigation/Page/Scroll/ScrollRefContext';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import getViewParameters from '../../../../../View/Api/getViewParameters';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { SimpleFieldPathEditor } from '../../../../../Path/FieldPathEditor/Simple/Editor/SimpleFieldPathEditor';
import { EntityFieldPath } from '../../../../../Path/@Model/EntityFieldPath';
import { red } from '@material-ui/core/colors';
import useRelatedEntity from '../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import equalsEntity from '../../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import useDebouncedCallback from '../../../../../../../../@Util/Debounce/useDebouncedCallback';
import AutomationDependencyContext from '../../../../../../../../@Api/Automation/AutomationDependencyContext';
import ParameterDictionary from '../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import getPredicateFromDescriptor from '../../../../../../../../@Api/Automation/Api/getPredicateFromDescriptor';
import CompositePredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import Predicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import FilterEditor from '../../../../../../Configuration/TypeManager/TypeEditor/ResourcePlannersEditor/FilterEditor/FilterEditor';
import { ViewParams } from '../../../../../View/Model/ViewParams';
import { useDelayedValue } from '../../../../../../../../@Future/Util/Hook/useDelayedValue';
import { makeStyles } from '@material-ui/styles';
import isHiddenType from '../../../../../../../../@Api/Metadata/EntityType/isHiddenType';
import IconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import { FeedbackStore } from '../../../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import { copyFilterToClipboard, getCopiedFilterFromClipboard, hasCopiedFilterInClipboard } from '../../../../../../Configuration/TypeManager/TypeEditor/ResourcePlannersEditor/FilterEditor/Clipboard/FilterClipboard';

const useStyles =
    makeStyles({
        list: {
            maxHeight: '80vh',
            overflowY: 'auto',
            overflowX: 'hidden'
        }
    });

export interface CampaignSelectionProps extends CampaignProps
{
    commitContext: CommitContext;
}

const CampaignSelection: React.FC<CampaignSelectionProps> =
    ({
        entity,
        commitContext,
     }) =>
    {
        const types = useTypes();
        const classes = useStyles();
        const [ reloadList, setReloadList ] = useState(() => uuid());

        const rootTypeSelectionOptions =
            useMemo(
                () => [
                    {
                        id: types.Relationship.Organization.Type.id.toString(),
                        label: types.Relation.Organization.Type.getName(true),
                        value: types.Relationship.Organization.Type
                    },
                    ...types.Relationship.Organization.Type.getAllTypes(false, true)
                        .filter(type => type !== types.Relationship.Organization.Type)
                        .map(
                            type => ({
                                id: type.id.toString(),
                                label: `${types.Relation.Organization.Type.getName(true)}: ${type.getName(true).toLowerCase()}`,
                                value: type
                            })),
                    {
                        id: types.Relationship.Person.Type.id.toString(),
                        label: types.Relation.Person.Type.getName(true),
                        value: types.Relationship.Person.Type
                    },
                    ...types.Relationship.Person.Type.getAllTypes(false, true)
                        .filter(type => type !== types.Relationship.Person.Type)
                        .map(
                            type => ({
                                id: type.id.toString(),
                                label: `${types.Relation.Person.Type.getName(true)}: ${type.getName(true).toLowerCase()}`,
                                value: type
                            })),
                    ...types.Activity.Type.getAllTypes(false, true)
                        .map(
                            type => ({
                                id: type.id.toString(),
                                label: `${types.Activity.Type.getName(true)}: ${type.getName(true).toLowerCase()}`,
                                value: type
                            })),
                    ...types.CustomEntity.Type.getAllTypes(false, true)
                        .map(
                            type => ({
                                id: type.id.toString(),
                                label: `${types.CustomEntity.Type.getName(true)}: ${type.getName(true).toLowerCase()}`,
                                value: type
                            })),
                    ...[
                        types.Involved.Type,
                        types.EventParticipation.Type,
                    ].filter(
                        type =>
                            type !== undefined
                    ).map(
                        type => ({
                            id: type.id.toString(),
                            label: `${type.getName(true)}`,
                            value: type
                        })
                    )
                ]
                .filter(
                  type =>
                    !isHiddenType(
                      type.value
                    )
                ),
                [
                    types
                ]);
        const rootType = useCampaignRootType(entity);
        const isRootTypeARelationship =
            useMemo(
                () =>
                    rootType?.isA(types.Relationship.Type),
                [
                    rootType
                ]
            );
        const relationshipFieldPath =
            useComputed(
                () =>
                {
                    const relationshipPathDescriptor =
                        entity.getObjectValueByField(
                            types.Activity.Campaign.Field.RelationshipPath,
                            commitContext
                        );

                    if (relationshipPathDescriptor)
                    {
                        return EntityPath.construct(relationshipPathDescriptor).field();
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    entity,
                    types,
                    commitContext,
                ]
            );
        const setRelationshipFieldPath =
            useCallback(
                (relationshipPath: EntityFieldPath) =>
                {
                    setValueByFieldInEntity(
                        entity,
                        types.Activity.Campaign.Field.RelationshipPath,
                        relationshipPath.path.descriptor,
                        commitContext
                    );

                    return commitEntityWithContext(
                        entity,
                        commitContext
                    );
                },
                [
                    entity,
                    types,
                    commitContext,
                ]
            );
        const filterSpecification =
            useEntityValue(
                entity,
                types.Activity.Campaign.Field.SelectionFilter,
                undefined,
                commitContext
            );
        const viewParameters =
            useMemo(
                () =>
                    getViewParameters(rootType),
                [
                    rootType
                ]
            );
        const filterParameter =
            useMemo(
                () =>
                    viewParameters.getParameterById(ViewParams.Entity),
                [
                    viewParameters
                ]
            );
        const filterDependencyContext =
            useMemo(
                () =>
                    new AutomationDependencyContext(
                        new ParameterDictionary([
                            filterParameter,
                        ])
                    ),
                [filterParameter]
            );
        const [
            filter,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            isLoadingFilter,
            setFilter,
        ] =
            useAsyncResult<Predicate>(
                async () =>
                {
                    if (filterSpecification === undefined)
                    {
                        return new CompositePredicate(
                            LogicalOperator.And,
                            []
                        );
                    }
                    else
                    {
                        if (filterSpecification._isNew)
                        {
                            return getPredicateFromDescriptor(
                                filterSpecification,
                                filterDependencyContext
                            );
                        }
                        else
                        {
                            return loadModuleDirectly(PredicateTypeStore)
                                .fromSpecification(filterSpecification)
                                .toNewPredicate(filterDependencyContext);
                        }
                    }
                },
                [
                    filterSpecification,
                    filterDependencyContext,
                ]
            );
        const emptyFilter =
            useMemo(
                () =>
                    new CompositePredicate(
                        LogicalOperator.And,
                        []
                    ),
                []);
        const setFilterOrNew =
            useCallback(
                (newFilter?: Predicate) =>
                    setFilter(newFilter ?? emptyFilter),
                [
                    setFilter,
                    emptyFilter,
                ]
            );
        const filterDescriptor =
            useComputed(
                () =>
                {
                    if (filter?.isValid())
                    {
                        return filter?.toDescriptor();
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    filter,
                ]
            );
        const delayedFilterDescriptor = useDelayedValue(filterDescriptor);

        useEffect(
            () =>
            {
                if (delayedFilterDescriptor)
                {
                    const currentSelectionFilter =
                        entity.getObjectValueByField(
                            types.Activity.Campaign.Field.SelectionFilter,
                            commitContext
                        );
                    const newFilterDescriptor = {
                        _isNew: true,
                        ...delayedFilterDescriptor,
                    };

                    if (!currentSelectionFilter
                        || JSON.stringify(newFilterDescriptor) !== JSON.stringify(currentSelectionFilter))
                    {
                        setValueByFieldInEntity(
                            entity,
                            types.Activity.Campaign.Field.SelectionFilter,
                            newFilterDescriptor,
                            commitContext
                        );

                        commitEntityWithContext(
                            entity,
                            commitContext
                        );
                    }
                }
            },
            [
                delayedFilterDescriptor,
                entity,
                types,
                commitContext,
            ]
        );

        const changeRootType =
            useCallback(
                (type: EntityType) =>
                {
                    setValueByFieldInEntity(
                        entity,
                        types.Activity.Campaign.Field.RootType,
                        type,
                        commitContext
                    );
                    setValueByFieldInEntity(
                        entity,
                        types.Activity.Campaign.Field.RelationshipPath,
                        EntityPath.fromEntityType(type).descriptor,
                        commitContext
                    );
                    setValueByFieldInEntity(
                        entity,
                        types.Activity.Campaign.Field.ManualSelection,
                        undefined,
                        commitContext
                    );
                    setValueByFieldInEntity(
                        entity,
                        types.Activity.Campaign.Field.SelectionFilter,
                        undefined,
                        commitContext
                    );
                    setFilter(
                        new CompositePredicate(
                            LogicalOperator.And,
                            []
                        )
                    );
                    setReloadList(uuid());

                    return commitEntityWithContext(entity, commitContext);
                },
                [
                    types,
                    entity,
                    setReloadList,
                    setFilter,
                    commitContext,
                ]
            );

        useEffect(
            () =>
            {
                if (!entity.hasValueForField(types.Activity.Campaign.Field.RootType, commitContext))
                {
                    changeRootType(types.Relationship.Organization.Type).finally();
                }
            },
            [
                entity,
                types,
                changeRootType,
                commitContext,
            ]
        );

        const listSelection =
            useEntityValue(
                entity,
                types.Activity.Campaign.Field.ManualSelection,
                undefined,
                commitContext
            );
        const setListSelection =
            useDebouncedCallback(
                selection =>
                {
                    setValueByFieldInEntity(
                        entity,
                        types.Activity.Campaign.Field.ManualSelection,
                        selection,
                        commitContext
                    );

                    return commitEntityWithContext(
                        entity,
                        commitContext
                    );
                },
                [
                    types,
                    entity,
                    commitContext,
                ],
                3000
            );
        const emailAddressFieldPath =
            useMemo(
                () =>
                {
                    const rootPath = relationshipFieldPath?.path ?? EntityPath.fromEntityType(rootType);

                    if (rootPath.entityType.isA(types.Relationship.Organization.Type))
                    {
                        return rootPath
                            .joinTo(
                                types.Relationship.Organization.RelationshipDefinition.Organization,
                                false)
                            .field(types.Relation.Organization.Field.EmailAddress);
                    }
                    else if (rootPath.entityType.isA(types.Relationship.Person.Contact.Type))
                    {
                        return rootPath
                            .field(types.Relationship.Person.Contact.Field.EmailAddress);
                    }
                    else if (rootPath.entityType.isA(types.Relationship.Person.Type))
                    {
                        return rootPath
                            .joinTo(
                                types.Relationship.Person.RelationshipDefinition.Person,
                                false)
                            .field(types.Relation.Person.Field.EmailAddress);
                    }
                },
                [
                    types,
                    rootType,
                    relationshipFieldPath,
                ]
            );
        const optOutRelationshipFieldPath =
            useMemo(
                () =>
                {
                    const rootPath = relationshipFieldPath?.path ?? EntityPath.fromEntityType(rootType);

                    return rootPath
                        .joinTo(
                            types.Relationship.RelationshipDefinition.OptOutEmailingCategories,
                            false
                        )
                        .field();
                },
                [
                    types,
                    rootType,
                    relationshipFieldPath,
                ]
            );
        const view =
            useComputed(
                () =>
                    new ViewModel(
                        'List',
                        undefined,
                        rootType,
                        viewParameters,
                        filter,
                        new Specification(
                            new ListModel(
                                [
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(rootType).field(types.Entity.Field.Name)
                                    ),

                                    ...(relationshipFieldPath && relationshipFieldPath?.path.entityType !== rootType)
                                        ?
                                            [
                                                new Column(
                                                    uuid(),
                                                    relationshipFieldPath.path.field()
                                                )
                                            ]
                                        :
                                            [],

                                    ...relationshipFieldPath?.path.entityType.isA(types.Relationship.Person.Contact.Type)
                                        ?
                                            [
                                                new Column(
                                                    uuid(),
                                                    relationshipFieldPath.path.joinTo(
                                                        types.Relation.RelationshipDefinition.Relationships,
                                                        true
                                                    ).field()
                                                )
                                            ]
                                        :
                                            [],

                                    ...emailAddressFieldPath
                                        ?
                                            [
                                                new Column(
                                                    uuid(),
                                                    emailAddressFieldPath
                                                )
                                            ]
                                        :
                                            [],

                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(rootType)
                                            .field(types.Relationship.Field.OptOut)
                                    ),

                                    ...optOutRelationshipFieldPath
                                        ?
                                            [
                                                new Column(
                                                    uuid(),
                                                    optOutRelationshipFieldPath
                                                )
                                            ]
                                        :
                                            [],

                                ],
                                []
                            )
                        )
                    ),
                [
                    types,
                    rootType,
                    relationshipFieldPath,
                    viewParameters,
                    filter,
                    emailAddressFieldPath,
                    optOutRelationshipFieldPath
                ]
            );
        const campaignCategory =
            useRelatedEntity(
                entity,
                types.Activity.Campaign.RelationshipDefinition.EmailingCategory,
                false
            );

        useEffect(
            () => {
                setReloadList(uuid());
            },
            [
                campaignCategory
            ]
        );

        const disableSelection =
            useCallback(
                (relationEntity: Entity) =>
                {
                    // Disable selection for entities which opted out
                    const mailingOptOut = relationEntity.getObjectValueByField(
                        EntityPath.fromEntityType(rootType).field(types.Relationship.Field.OptOut).field,
                        commitContext
                    );
                    const relationOptOutCategories = relationEntity.getRelatedEntitiesByDefinition(
                        false,
                        types.Relationship.RelationshipDefinition.OptOutEmailingCategories,
                        commitContext
                    );
                    const optOut =
                        campaignCategory &&
                        relationOptOutCategories &&
                        relationOptOutCategories.some(optOutCat => equalsEntity(optOutCat, campaignCategory));

                    return !!mailingOptOut || optOut;
                },
                [
                    types,
                    rootType,
                    commitContext,
                    campaignCategory
                ]
            );
        const selectionSize =
            useMemo(
                () =>
                    listSelection && listSelection.size,
                [listSelection]
            );
        const scrollRef = useRef();

        const canCopyFilter =
            useComputed(
                () =>
                    filter &&
                    filter.isValid() &&
                        JSON.stringify(filter.toDescriptor()) !== JSON.stringify(emptyFilter.toDescriptor()),
                [
                    filter,
                    emptyFilter,
                ]
            );

        const canPasteFilter =
            useComputed(
                () =>
                {
                    if (hasCopiedFilterInClipboard())
                    {
                        const filterFromClipboard = getCopiedFilterFromClipboard();

                        return rootType.isA(filterFromClipboard.type)
                            && filterFromClipboard.filter.getDependencies()
                                .every(
                                    element =>
                                        filterDependencyContext.parameterDictionary.hasParameter(element.parameter)
                                );
                    }
                    else
                    {
                        return false;
                    }
                },
                [
                    getCopiedFilterFromClipboard,
                    rootType,
                    filterDependencyContext.parameterDictionary,
                ]
            );

        const copyToClipboard =
            useCallback(
                () =>
                {
                    copyFilterToClipboard(
                        rootType,
                        filter
                    );

                    loadModuleDirectly(FeedbackStore)
                        .enqueueSnackbar(
                            localizeText('Generic.CopiedToClipboard', 'Gekopieerd naar klembord'),
                            {
                                variant: 'success'
                            });
                },
                [
                    rootType,
                    filter,
                ]);

        const pasteFromClipboard =
            useCallback(
                async () =>
                {
                    const filterFromClipboard = getCopiedFilterFromClipboard();
                    const filterDescriptor = filterFromClipboard.filter.toDescriptor();

                    const copiedFilter =
                        await getPredicateFromDescriptor(
                            filterDescriptor,
                            filterDependencyContext
                        );

                    setFilterOrNew(copiedFilter);
                    copyFilterToClipboard(undefined, undefined);
                },
                [
                    filterDependencyContext,
                    setFilterOrNew,
                ]);

        return <ExpansionPanel
            id="Selection"
            summary={
                <Header
                    large
                    inset
                    title={
                        selectionSize
                            ?
                                <LocalizedText
                                    code="Campaign.SelectionWithSize"
                                    value="Selectie (${selectionSize})"
                                    selectionSize={selectionSize}
                                />
                            :
                                <LocalizedText
                                    code="Campaign.Selection"
                                    value="Selectie"
                                />
                    }
                />
            }
            expansion={
                <ViewGroup
                    orientation="vertical"
                    spacing={20}
                >
                    <ViewGroupItem>
                        <CardInset
                            vertical={false}
                        >
                            <ViewGroup
                                orientation="vertical"
                                spacing={10}
                            >
                                <ViewGroupItem>
                                    <LabelInput
                                        label={
                                            <LocalizedText
                                                code="Generic.Type"
                                                value="Type"
                                            />
                                        }
                                        labelPosition="left"
                                    >
                                        <StaticSelectbox
                                            options={rootTypeSelectionOptions}
                                            value={rootType}
                                            onChange={changeRootType}
                                            disableUnderline={false}
                                        />
                                    </LabelInput>
                                </ViewGroupItem>
                                {
                                    relationshipFieldPath
                                    && !relationshipFieldPath.path.entityType.isA(types.Relationship.Type) &&
                                        <ViewGroupItem>
                                            <div
                                                style={{
                                                    color: red[500],
                                                    fontWeight: 500,
                                                }}
                                            >
                                                <LocalizedText
                                                    code="Campaign.InvalidRelationshipPath"
                                                    value="Geen geldig pad opgegeven naar een relatie"
                                                />
                                            </div>
                                        </ViewGroupItem>
                                }
                                {
                                    rootType && !isRootTypeARelationship &&
                                        <ViewGroupItem>
                                            <LabelInput
                                                label={types.Activity.Campaign.Field.RelationshipPath.getName()}
                                                labelPosition="left"
                                            >
                                                <SimpleFieldPathEditor
                                                    entityType={rootType}
                                                    value={relationshipFieldPath}
                                                    onChange={setRelationshipFieldPath}
                                                />
                                            </LabelInput>
                                        </ViewGroupItem>
                                }
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={5}
                                    >
                                        <ViewGroupItem>
                                            <CardHeader>
                                                <ViewGroup
                                                    orientation="horizontal"
                                                    spacing={5}
                                                >
                                                    <ViewGroupItem
                                                        ratio={1}
                                                    >
                                                        <LocalizedText
                                                            code="Generic.Filter"
                                                            value="Filter"
                                                        />
                                                    </ViewGroupItem>
                                                    {
                                                        canPasteFilter &&
                                                        <ViewGroupItem>
                                                            <IconButton
                                                                tooltip={localizeText('Generic.PasteFromClipboard', 'Plakken vanuit klembord')}
                                                                iconSize={14}
                                                                icon="content_paste"
                                                                onClick={pasteFromClipboard}
                                                            />
                                                        </ViewGroupItem>
                                                    }
                                                    {
                                                        canCopyFilter &&
                                                        <ViewGroupItem>
                                                            <IconButton
                                                                tooltip={localizeText('Generic.CopyToClipboard', 'Kopieer naar klembord')}
                                                                icon="content_copy"
                                                                onClick={copyToClipboard}
                                                            />
                                                        </ViewGroupItem>
                                                    }
                                                </ViewGroup>
                                            </CardHeader>

                                            <ViewGroupItem
                                                alignment="right"
                                            >

                                            </ViewGroupItem>

                                        </ViewGroupItem>
                                        {
                                            filter &&
                                            <ViewGroupItem>
                                                <FilterEditor
                                                    filterParameter={filterParameter}
                                                    parameterDictionary={filterDependencyContext.parameterDictionary}
                                                    value={filter}
                                                    onChange={setFilterOrNew}
                                                />
                                            </ViewGroupItem>
                                        }
                                    </ViewGroup>
                                </ViewGroupItem>
                            </ViewGroup>
                        </CardInset>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <div
                            ref={scrollRef}
                            className={classes.list}
                        >
                            <ScrollRefContext.Provider
                                value={scrollRef}
                            >
                                <List
                                    key={reloadList}
                                    view={view}
                                    onSelectionChange={setListSelection}
                                    selection={listSelection}
                                    defaultSelectionType="all"
                                    selectable
                                    disableSelection={disableSelection}
                                />
                            </ScrollRefContext.Provider>
                        </div>
                    </ViewGroupItem>
                </ViewGroup>
            }
        />;
    };

export default observer(CampaignSelection);
