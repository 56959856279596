import React, { Fragment } from 'react';
import LayoutBlockEditor from './Editor/LayoutBlockEditor';
import GutenbergBlockType from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Gutenberg/GutenbergBlockType';
import { LayoutBlockId } from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/DocumentEditor';
import Icon from '../../../../../../@Future/Component/Generic/Icon/Icon';
import LayoutBlockViewer from './Viewer/LayoutBlockViewer';

const LayoutBlock: GutenbergBlockType =
    {
        name: LayoutBlockId,
        configuration:
            {
                title: {
                    code: 'GutenbergBlock.Layout',
                    value: 'Layout'
                },
                icon: <Icon icon="dashboard" />,
                category: 'common',
                attributes: {},
                edit: props =>
                {
                    return <Fragment>
                        <LayoutBlockEditor
                            value={props.attributes.layout}
                            onChange={value => props.setAttributes({ layout: value })}
                        />
                    </Fragment>;
                },
                save: props =>
                {
                    return <p>
                        {(props.attributes as any).content}
                    </p>;
                },
            },
        view:
            (block, props) =>
            {
                return <LayoutBlockViewer
                    value={block.attributes.layout}
                    safeMode={props.safeMode}
                />;
            },
    };

export default LayoutBlock;
