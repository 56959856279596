import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Parameter from '../../../../../../../../../@Api/Automation/Parameter/Parameter';
import Input from '../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { runInAction } from 'mobx';
import ParameterAssignment from '../../../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import Value from '../../../../../../../../../@Api/Automation/Value/Value';
import ValueEditor from '../../Value/ValueEditor';
import FunctionContext from '../../../../../../../../../@Api/Automation/Function/FunctionContext';
import ParameterDictionary from '../../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';

export interface ParameterValueEditorProps
{
    parameterAssignment: ParameterAssignment;
    parameter: Parameter<any>;
    filter?: boolean;
}

const ParameterValueEditor: React.FC<ParameterValueEditorProps> =
    props =>
    {
        const value =
            useComputed(
                () =>
                    props.parameterAssignment.getValue(props.parameter),
                [
                    props.parameterAssignment,
                    props.parameter
                ]);

        const setValue =
            useCallback(
                (value?: Value<any, any>) =>
                    runInAction(
                        () =>
                            props.parameterAssignment.setValue(props.parameter, value)),
                [
                    props.parameterAssignment,
                    props.parameter
                ]);

        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        new ParameterDictionary([]),
                        props.parameterAssignment),
                []);

        return <Input
            labelPosition="left"
            label={props.parameter.name}
            inline
        >
            <ValueEditor
                context={context}
                type={props.parameter.type}
                value={value}
                onChange={setValue}
                filter={props.filter}
            />
        </Input>;
    };

export default observer(ParameterValueEditor);
