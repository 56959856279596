import TrackItem from '../../Model/TrackItem';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/styles';
import useTypes from '../../../../../Type/Api/useTypes';

const useStyles =
    makeStyles({
        subject:
            {
                paddingTop: 15,
                paddingBottom: 15,
                paddingRight:8,
                paddingLeft: 8,
                fontSize: 14,
                fontWeight: 'bold',
                whiteSpace: 'normal'
            }
    });

export interface TrackItemTooltipSubjectProps
{
    trackItem: TrackItem;
}

export const TrackItemTooltipSubject: React.FC<TrackItemTooltipSubjectProps> =
    observer(
        ({
             trackItem
        }) =>
        {
            const classes = useStyles();
            const types = useTypes();

            const subject =
                useMemo(
                    () =>
                        trackItem.item.entity.getObjectValueByField(types.Activity.Field.Subject),
                    [
                        trackItem,
                        types
                    ]
                );

            const eventColor =
                useMemo<string>(
                    () =>
                        trackItem.item.selection.color ?? trackItem.item.entity.entityType.bespoke.getEntityColorForCalendar(trackItem.item.entity) ?? '#5DACE2',
                    [
                        trackItem
                    ]
                );

            return <div
                className={classes.subject}
                style={{backgroundColor: eventColor}}
            >
                {subject}
            </div>
        }
    );