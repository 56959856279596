import React, { useState } from 'react';
import styles from './Pipeline.module.scss';
import Phase from './Phase/Phase';

export interface PipelineProps
{
    disabled?: boolean;
    onPhaseHover?: (index: number) => void;
    onPhaseSelected?: (index: number) => void;
    finalPhaseComponent?: JSX.Element;
}

const Pipeline: React.FC<PipelineProps> =
    props =>
    {
        const [ hoveringPhaseIndex, setHoveringPhaseIndex ] = useState<number>(undefined);

        const children: any[] =
            React.Children.toArray(props.children)
                .filter(
                    child => child);

        return <div
            className={styles.root}
        >
            {
                children.map(
                    (child: React.ReactElement, idx: number) =>
                        <Phase
                            {...child.props}
                            key={idx}
                            first={idx === 0}
                            last={idx === children.length - 1}
                            visited={hoveringPhaseIndex !== undefined ? (idx <= hoveringPhaseIndex) : child.props.visited}
                            current={hoveringPhaseIndex !== undefined ? (idx === hoveringPhaseIndex) : child.props.current}
                            onClick={!props.disabled && props.onPhaseSelected ? () => props.onPhaseSelected(idx) : undefined}
                            onMouseEnter={
                                () =>
                                {
                                    if (!props.disabled)
                                    {
                                        setHoveringPhaseIndex(idx);

                                        if (props.onPhaseHover)
                                        {
                                            props.onPhaseHover(idx);
                                        }
                                    }
                                }
                            }
                            onMouseLeave={
                                () =>
                                {
                                    if (!props.disabled)
                                    {
                                        setHoveringPhaseIndex(undefined);

                                        if (props.onPhaseHover)
                                        {
                                            props.onPhaseHover(undefined);
                                        }
                                    }
                                }
                            }
                        />)
            }
        </div>;
    };

export default Pipeline;
