import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';

export default function useIsShowEntitySummary(entity: Entity)
{
    return useComputed(
        () =>
            entity.entityType.bespoke.getSummaryFields(entity)
                .filter(
                    summaryField =>
                        summaryField.title !== undefined)
                .length > 0,
        [
            entity
        ]);
}
