import { EntityType } from '../Model/Implementation/EntityType';
import { SettingSource } from '../../@Component/Domain/Setting/SettingStore';
import getSetting from '../../@Component/Domain/Setting/Api/getSetting';
import setSetting from '../../@Component/Domain/Setting/Api/setSetting';

export default async function setMetadataSettingValue<T>(entityType: EntityType,
                                                         code: string,
                                                         value: T): Promise<void>
{
    const settingValue = getSetting<any>(SettingSource.Organization, code);
    const newSettingValue = {
        ...settingValue
    };
    newSettingValue[entityType.id] = value;

    await setSetting(
        SettingSource.Organization,
        code,
        newSettingValue
    );
}
