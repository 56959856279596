import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import { AssistantValueFromEntityComputation } from '../../Model/Automation/Computation/AssistantValueFromEntityComputation';
import { findAssistantFieldPath } from '../findAssistantFieldPath';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { findAssistantVariableComputation } from './findAssistantVariableComputation';
import ValueType from '../../../../../@Api/Automation/Value/Type/ValueType';

export function findAssistantValueFromEntityComputation(
    computation: AssistantValueFromEntityComputation,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): ValueFromEntityComputation
{
    const entity =
        findAssistantVariableComputation(
            computation.entity,
            parameters,
            rootParameter
        );
    const entityType = entity.getType() as ValueType<any>;

    if (entityType instanceof EntityValueType)
    {
        const fieldPath =
            findAssistantFieldPath(
                entityType.type.path(),
                computation.field
            );

        return new ValueFromEntityComputation(
            entity,
            fieldPath
        );
    }
    else
    {
        throw new Error(`Expected entity value type, but got: ${entity.getType().id()} in ${JSON.stringify(computation)}`);
    }
}
