import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { observable } from 'mobx';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { EntityPath } from '../../Path/@Model/EntityPath';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import getPredicateFromDescriptor from '../../../../../@Api/Automation/Api/getPredicateFromDescriptor';
import ResourceSelectionRecurrence from './ResourceSelectionRecurrence';

export default class ResourceSelection
{
    // ------------------------- Properties -------------------------

    @observable.ref parameter: Parameter<EntityValueType>;
    @observable.ref resourcePath: EntityPath;
    @observable.ref startDateFieldPath: EntityFieldPath;
    @observable.ref endDateFieldPath: EntityFieldPath;
    @observable.ref filter?: Predicate;
    @observable.ref highlightFilter?: Predicate;
    @observable isPlannable: boolean;
    @observable color?: string;
    @observable showOnExpansion: boolean;
    @observable.ref timesheetConstructorFieldPaths: EntityFieldPath[];
    @observable.ref recurrence?: ResourceSelectionRecurrence;
    @observable description?: string;

    // ------------------------ Constructor -------------------------

    constructor(
        parameter: Parameter<EntityValueType>,
        resourcePath: EntityPath,
        startDateFieldPath: EntityFieldPath,
        endDateFieldPath: EntityFieldPath,
        filter?: Predicate,
        highlightFilter?: Predicate,
        isPlannable: boolean = true,
        color?: string,
        showOnExpansion: boolean = false,
        timesheetConstructorFieldPaths: EntityFieldPath[] = [],
        recurrence?: ResourceSelectionRecurrence,
        description?: string
    )
    {
        this.parameter = parameter;
        this.resourcePath = resourcePath;
        this.startDateFieldPath = startDateFieldPath;
        this.endDateFieldPath = endDateFieldPath;
        this.filter = filter;
        this.highlightFilter = highlightFilter;
        this.isPlannable = isPlannable;
        this.color = color;
        this.showOnExpansion = showOnExpansion;
        this.timesheetConstructorFieldPaths = timesheetConstructorFieldPaths;
        this.recurrence = recurrence;
        this.description = description;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static getResourceSelectionParameter(id: string,
                                         entityType: EntityType)
    {
        return new Parameter(
            id,
            new EntityValueType(entityType),
            true,
            entityType.getName());
    }

    get id(): string
    {
        return this.parameter.id;
    }

    get entityType(): EntityType
    {
        return this.parameter.type.type;
    }

    get getDescription(): string
    {
        return this.description;
    }

    static async fromDescriptor(descriptor: any,
                                parentDependencyContext: AutomationDependencyContext): Promise<ResourceSelection>
    {
        const parameter =
            ResourceSelection.getResourceSelectionParameter(
                descriptor.id,
                loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.entityTypeId));

        const parameterDictionary =
            parentDependencyContext
                .parameterDictionary
                .getNewDictionaryWithParameter(parameter);

        const dependencyContext = new AutomationDependencyContext(parameterDictionary);

        return new ResourceSelection(
            parameter,
            EntityPath.construct(descriptor.resourcePath),
            EntityFieldPath.construct(descriptor.startDateFieldPath),
            EntityFieldPath.construct(descriptor.endDateFieldPath),
            descriptor.filter
                ?
                    await getPredicateFromDescriptor(descriptor.filter, dependencyContext)
                :
                    undefined,
            descriptor.highlightFilter
                ?
                    await getPredicateFromDescriptor(descriptor.highlightFilter, dependencyContext)
                :
                    undefined,
            descriptor.isPlannable,
            descriptor.color,
            descriptor.showOnExpansion,
            [],
            descriptor.recurrence
                ? ResourceSelectionRecurrence.fromDescriptor(descriptor.recurrence)
                : undefined
        );
    }

    toDescriptor()
    {
        return {
            id: this.parameter.id,
            entityTypeId: this.entityType.id,
            resourcePath: this.resourcePath.descriptor,
            startDateFieldPath: this.startDateFieldPath.descriptor,
            endDateFieldPath: this.endDateFieldPath.descriptor,
            filter: this.filter?.toDescriptor(),
            highlightFilter: this.highlightFilter?.toDescriptor(),
            isPlannable: this.isPlannable,
            color: this.color,
            showOnExpansion: this.showOnExpansion,
            recurrence: this.recurrence?.toDescriptor(),
        };
    }

    // ----------------------- Private logic ------------------------
}
