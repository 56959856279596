import React from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import Switch from '../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import { Field } from '../../Model/Field';
import { runInAction } from 'mobx';

export interface FieldItemProps
{
    entityType: EntityType;
    field: Field;
}

const IsVisibleAsTabEditor: React.FC<FieldItemProps> =
    props =>
    {
        if (props.field.isPlural)
        {
            return <Switch
                checked={props.field.isVisibleAsTab}
                onToggle={
                    () =>
                        runInAction(
                            () =>
                                props.field.isVisibleAsTab = !props.field.isVisibleAsTab
                        )
                }
                disabled={!props.field.isEditable}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(IsVisibleAsTabEditor);
