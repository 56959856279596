import * as React from 'react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { EntityConstructorStore } from './EntityConstructorStore';
import { observer } from 'mobx-react';
import Constructor from './Constructor';

@observer
export class EntityConstructor extends BaseComponent<EntityConstructorStore>
{
    renderComponent(store: EntityConstructorStore)
    {
        return <Constructor
            entityType={store.entityType}
            entity={store.originalEntity}
            onClose={store.onRequestClose}
            onSave={store.onSave}
            onOpen={store.onOpen}
            onDelete={store.onDelete}
            openable={store.allowOpen}
            filter={store.props.fieldPathFilter}
            autoFocus
            hideCloseButton={store.hideCloseButton}
            commitContext={store.commitContext}
        />;
    }
}
