import { EventRelationshipUpdate } from './EventRelationshipUpdate';
import { EntityRelationshipCreationMutation } from '../../../../../@Api/Model/Implementation/EntityRelationshipCreationMutation';
import { BadgeStore } from '../../../../Generic/Badge/BadgeStore';
import { EntityEventController } from '../../../../../@Api/Controller/Directory/EntityEventController';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { EntityReadMutationFilter } from '../../../../../@Api/Model/Implementation/EntityReadMutationFilter';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';

export class EventRelationshipCreate extends EventRelationshipUpdate
{
    @injectWithQualifier('EntityEventController') entityEventController: EntityEventController;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Entity.Relationship.Create';
    }

    caption(event: EntityRelationshipCreationMutation): string
    {
        if (event.entityRelationshipDefinition.isPlural(event.isParentRelationship))
        {
            return this.localizationStore.translate('Event.RelationshipCreate.Caption'); // added
        }
        else
        {
            return super.caption(event);
        }
    }

    badges(event: EntityRelationshipCreationMutation): BadgeStore[]
    {
        return event.toRelatedEntity && event.toRelatedEntity.entityType.isA(this.entityTypeStore.bespoke.types.Activity.Email.Type)
                ?
                    [
                        new BadgeStore(
                        {
                            icon: 'remove_red_eye',
                            provider:
                                () =>
                                    this.entityEventController.countEvents(
                                        event.toRelatedEntity.entityType.id,
                                        event.toRelatedEntity.id, // event.entity.id,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [
                                            new EntityReadMutationFilter(
                                                EntityPath.fromEntity(event.toRelatedEntity),
                                                true)
                                                .descriptor()
                                        ],
                                        undefined,
                                        undefined)
                                        .then(count =>
                                            Promise.resolve(`${count}`))
                        })
                    ]
                :
                    [];
    }
}
