import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { isFieldPathSearchable } from './isFieldPathSearchable';
import { isPrimitiveTypeSearchableForKeyword } from '../../../../../@Api/Automation/Api/isPrimitiveTypeSearchableForKeyword';

export function isFieldPathSearchableForKeyword(
    fieldPath: EntityFieldPath,
    keyword: string
): boolean
{
    if (isFieldPathSearchable(fieldPath))
    {
        return isPrimitiveTypeSearchableForKeyword(
            fieldPath.field.dataObjectSpecification.type,
            keyword
        );
    }
    else
    {
        return false;
    }
}
