import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { AssistantCompositePredicate } from '../../Model/Predicate/AssistantCompositePredicate';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { findAssistantPredicate } from './findAssistantPredicate';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';

export function findAssistantCompositePredicate(
    predicate: AssistantCompositePredicate,
    parameters: ParameterDictionary,
    resultParameter: Parameter<EntityValueType>
): Predicate
{
    const convertedOperator = LogicalOperator[predicate.operator];

    if (convertedOperator === undefined)
    {
        throw new Error(`Could not resolve operator: ${predicate.operator}`);
    }

    const convertedChildPredicates: Predicate[] = [];

    for (const childPredicate of predicate.childPredicates)
    {
        const convertedChildPredicate =
            findAssistantPredicate(
                childPredicate,
                parameters,
                resultParameter
            );

        convertedChildPredicates.push(convertedChildPredicate);
    }

    return new CompositePredicate(
        convertedOperator,
        convertedChildPredicates
    );
}
