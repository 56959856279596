import React from 'react';
import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../Type/Api/useTypes';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Input from '../../../../../../../Input/Input';
import MenuButton from '../../../../../../../Item/MenuButton/MenuButton';

export interface ExternalCostProps
{
    entity: Entity;
    milestone?: Entity;
}

const ExternalCost: React.FC<ExternalCostProps> =
    props =>
    {
        const types = useTypes();

        return <TableRow>
            <TableCell>
                <Input
                    entity={props.entity}
                    field={types.ExternalCost.Field.Description}
                    labelPosition="none"
                    autoFocus={props.entity.isNew()}
                />
            </TableCell>
            <TableCell>
                <Input
                    entity={props.entity}
                    field={types.ExternalCost.Field.Amount}
                    labelPosition="none"
                />
            </TableCell>
            <TableCell>
                <Input
                    entity={props.entity}
                    field={types.ExternalCost.RelationshipDefinition.VatGroup}
                    labelPosition="none"
                />
            </TableCell>
            {
                !props.milestone &&
                    <TableCell>
                        <Input
                            entity={props.entity}
                            field={types.Milestone.RelationshipDefinition.ExternalCosts}
                            parent
                            labelPosition="none"
                        />
                    </TableCell>
            }
            <TableCell>
                <MenuButton
                    entity={props.entity}
                />
            </TableCell>
        </TableRow>;
    };

export default observer(ExternalCost);
