import { Entity } from '../../../Model/Implementation/Entity';
import { useMemo } from 'react';
import useTypes from '../../../../@Component/Domain/Entity/Type/Api/useTypes';
import useResults from '../../../../@Component/Domain/Entity/Selection/Hooks/useResults';

export default function useExternalIdsFromEntity(
    entity: Entity,
    service: string,
    type: string
): [ Entity[] | undefined, boolean ]
{
    const types = useTypes();

    const externalIds =
        useResults(
            types.ExternalId.Type,
            (builder, rootPath) =>
                builder
                    .where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath
                                    .joinTo(
                                        types.Entity.RelationshipDefinition.ExternalIds,
                                        true),
                                entity))
                    .where(
                        cb =>
                            cb.eq(
                                rootPath.field(types.ExternalId.Field.Service),
                                undefined,
                                service
                            )
                    )
                    .where(
                        cb =>
                            cb.eq(
                                rootPath.field(types.ExternalId.Field.Type),
                                undefined,
                                type)),
            [
                entity,
                service,
                type,
            ]
        )
        // useFetchedRelatedEntities(
        //     entity,
        //     types.Entity.RelationshipDefinition.ExternalIds,
        //     false,
        //     commitContext);

    const response = useMemo<Entity[] | undefined>(
        () =>
            externalIds === undefined
                ? undefined
                : externalIds,
        [
            externalIds,
            types
        ]
    );

    return [
        response,
        externalIds === undefined
    ];
}