import { BaseStore, getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { computed, observable } from 'mobx';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { SettingSource, SettingStore } from '../../Setting/SettingStore';
import { EntityFieldPath } from '../Path/@Model/EntityFieldPath';
import { createTransactionalModel, TransactionalModel } from '../../../../@Util/TransactionalModelV2';

interface FavoriteSetting
{
    type: string;
    id?: number;
    code?: string;
}

export interface EntityBucketSummaryProps
{
    entity: PropType<EntityBucketSummaryStore, EntityBucketSummaryProps, Entity>;
    alwaysShowSummaryFields?: PropType<EntityBucketSummaryStore, EntityBucketSummaryProps, boolean>;
    displayInList?: PropType<EntityBucketSummaryStore, EntityBucketSummaryProps, boolean>;
}

const defaultProps: Partial<EntityBucketSummaryProps> =
{
    alwaysShowSummaryFields: false,
    displayInList: false
};

export class EntityBucketSummaryStore extends BaseStore<EntityBucketSummaryProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('SettingStore') settingStore: SettingStore;

    // ------------------------- Properties -------------------------

    @observable.ref transactionalEntity: TransactionalModel<Entity>;

    // ------------------------ Constructor -------------------------

    constructor(props: EntityBucketSummaryProps)
    {
        super(props, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        this.transactionalEntity = createTransactionalModel(this.entity);

        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    @computed
    get entity(): Entity
    {
        return getOrCompute(this, this.props.entity);
    }

    @computed
    get displayInList(): boolean
    {
        return getOrCompute(this, this.props.displayInList);
    }

    @computed
    get alwaysShowSummaryFields(): boolean
    {
        return getOrCompute(this, this.props.alwaysShowSummaryFields);
    }

    @computed
    get favoriteEntityFields(): FavoriteSetting[]
    {
        return (this.settingStore.getValue(SettingSource.User, 'EntityType.Field.Config') || {})[this.entity.entityType.id] || undefined;
    }

    @computed
    get favoriteEntityValues(): EntityFieldPath[]
    {
        if (this.entity)
        {
            const allFields =
                this.entityTypeStore
                    .getVisibleEntityFieldPaths(
                        this.entity.entityType);
            let fieldPaths: EntityFieldPath[] = [];

            if (this.favoriteEntityFields)
            {
                fieldPaths =
                    this.favoriteEntityFields
                        .map(field =>
                            field.type === 'EntityField'
                                ?
                                    allFields.find(path => path.field && ((path.field.isStaticField() && path.field.code === field.code) || (!path.field.isStaticField() && path.field.id === field.id)))
                                :
                                    allFields.find(path => path.relationshipDefinition && path.relationshipDefinition.id === field.id));
            }

            return fieldPaths.filter(path => path != null);
        }
        else
        {
            return [];
        }
    }

    @computed
    get showEntitySummaryFields(): boolean
    {
        if (this.alwaysShowSummaryFields)
        {
            return true;
        }
        else if (this.favoriteEntityFields && this.favoriteEntityFields.length > 0)
        {
            return this.settingStore.getValue(
                SettingSource.User,
                'entity.summaryfields.isvisible').boolean1;
        }
        else
        {
            return false;
        }
    }

    @computed
    get isEmpty(): boolean
    {
        return this.favoriteEntityValues.length === 0;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
