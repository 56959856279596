import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import { CommitContext } from '../../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { useComputed } from 'mobx-react-lite';
import { DataObject } from '../../../../../../../../DataObject/Model/DataObject';
import { MathematicalOperator } from '../../../../../../../../DataObject/Model/MathematicalOperator';
import { useMemo } from 'react';
import useTypes from '../../../../../../../Type/Api/useTypes';

export interface TotalsEditorInterface
{
    showDiscountSection: boolean;
    additionalColspan: number;
    subtotalAmount: DataObject;
    discountAmount: DataObject;
    totalExcludingVatAmount: DataObject;
    totalIncludingVatAmount: DataObject;
    amountByVatGroup: Map<string, DataObject>;
}

export default function useTotals(
    lines: Entity[],
    currency: string,
    entity: Entity,
    commitContext: CommitContext,
    discountHidden: boolean,
    onSelect?: (entity: Entity, selected: boolean) => void
) : TotalsEditorInterface
{
    const types = useTypes();

    const subtotalAmount =
        useComputed(
            () =>
            {
                let subtotal = DataObject.constructFromTypeIdAndValue('Currency', 0);

                lines
                    .forEach(
                        line =>
                        {
                            subtotal =
                                DataObject.compute(
                                    subtotal,
                                    line.getDataObjectValueByField(
                                        currency
                                            ? types.ProductLine.Field.TotalInCurrency
                                            : types.ProductLine.Field.Total
                                    ),
                                    MathematicalOperator.Add);
                        });

                return subtotal;
            },
            [
                lines,
                types
            ]);

    const subtotalExcludingVatAmount =
        useComputed(
            () =>
            {
                let subtotal = DataObject.constructFromTypeIdAndValue('Currency', 0);

                lines
                    .forEach(
                        line =>
                        {
                            subtotal =
                                DataObject.compute(
                                    subtotal,
                                    line.getDataObjectValueByField(
                                        currency
                                            ? types.ProductLine.Field.TotalExcludingVatInCurrency
                                            : types.ProductLine.Field.TotalExcludingVat
                                    ),
                                    MathematicalOperator.Add);
                        });

                return subtotal;
            },
            [
                lines,
                types
            ]);

    const discountAmount =
        useComputed(
            () =>
                DataObject.compute(
                    subtotalExcludingVatAmount,
                    entity.getDataObjectValueByField(types.Activity.Field.DiscountPercentage),
                    MathematicalOperator.Multiply),
            [
                subtotalExcludingVatAmount,
                entity,
                types
            ]);

    const amountByVatGroup =
        useComputed(
            () =>
            {
                const discountFactor =
                    DataObject.compute(
                        DataObject.constructFromTypeIdAndValue('Percentage', 100),
                        entity.getDataObjectValueByField(types.Activity.Field.DiscountPercentage),
                        MathematicalOperator.Subtract);

                const amountByVatPercentage = new Map<string, DataObject>();

                lines.forEach(
                    line =>
                    {
                        const vatPercentage = line.getDataObjectValueByField(types.ProductLine.Field.VatPercentage);

                        const vatPercentageKey = vatPercentage.toString();

                        if (!amountByVatPercentage.has(vatPercentageKey))
                        {
                            amountByVatPercentage.set(
                                vatPercentageKey,
                                DataObject.constructFromTypeIdAndValue('Currency', 0));
                        }

                        amountByVatPercentage.set(
                            vatPercentageKey,
                            DataObject.compute(
                                amountByVatPercentage.get(vatPercentageKey),
                                DataObject.compute(
                                    vatPercentage,
                                    DataObject.compute(
                                        discountFactor,
                                        line.getDataObjectValueByField(
                                            currency
                                                ? types.ProductLine.Field.TotalExcludingVatInCurrency
                                                : types.ProductLine.Field.TotalExcludingVat
                                        ),
                                        MathematicalOperator.Multiply),
                                    MathematicalOperator.Multiply),
                                MathematicalOperator.Add));
                    });

                Array.from(amountByVatPercentage.keys())
                    .filter(
                        percentage =>
                            amountByVatPercentage.get(percentage).value === 0)
                    .forEach(
                        percentage =>
                            amountByVatPercentage.delete(percentage));

                return amountByVatPercentage;
            },
            [
                lines,
                types
            ]);

    const totalExcludingVatAmount =
        useComputed(
            () =>
                DataObject.compute(
                    subtotalExcludingVatAmount,
                    discountAmount,
                    MathematicalOperator.Subtract),
            [
                subtotalExcludingVatAmount,
                discountAmount
            ]);

    const totalIncludingVatAmount =
        useComputed(
            () =>
            {
                let total = totalExcludingVatAmount;

                amountByVatGroup.forEach(
                    vatAmount =>
                        total =
                            DataObject.compute(
                                total,
                                vatAmount,
                                MathematicalOperator.Add));

                return total;
            },
            [
                totalExcludingVatAmount,
                amountByVatGroup
            ]);

    const additionalColspan =
        useMemo(
            () =>
                onSelect ? 2 : 1,
            [
                onSelect
            ]);

    const showDiscountSection =
        useComputed(
            () =>
                entity.getObjectValueByField(types.Activity.Field.DiscountPercentage, commitContext) > 0
                || !discountHidden,
            [
                entity,
                discountHidden,
                commitContext,
            ]);

    return useMemo(
        () =>
            ({
                showDiscountSection: showDiscountSection,
                additionalColspan: additionalColspan,
                subtotalAmount: subtotalAmount,
                discountAmount: discountAmount,
                totalExcludingVatAmount: totalExcludingVatAmount,
                totalIncludingVatAmount: totalIncludingVatAmount,
                amountByVatGroup: amountByVatGroup
            }),
        [
            showDiscountSection,
            additionalColspan,
            subtotalAmount,
            discountAmount,
            totalExcludingVatAmount,
            totalIncludingVatAmount,
            amountByVatGroup
        ]
    );
}