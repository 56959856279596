import React, { useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useParentRelation from '../../../../../../@Api/Entity/Bespoke/Relationship/useParentRelation';
import useInactiveName from '../../../../../../@Api/Entity/Bespoke/useInactiveName';
import styles from '../../Content/Relationship/Caption/Caption.module.scss';
import Link from '../Link/Link';
import ViewerEntityContext from '../../../Viewer/Context/ViewerEntity/ViewerEntityContext';
import useTypes from '../../../Type/Api/useTypes';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';
import useFilteredEntityType from '../../../Dataset/Segment/Api/useFilteredEntityType';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import useFirstParentRelationship from '../../../../../../@Api/Entity/Bespoke/Relation/useFirstParentRelationship';
import { CaptionClasses } from '../Caption';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface RelationProps
{
    entity: Entity;
    classes?: CaptionClasses;
}

// Function name / entity type name
// (0) [DISABLED FOR NOW] if parent entity is current environment, then nothing otherwise 'of {parent entity}'

// Rules:
// (1) if {entity type} is of {filtered type}, then omit entity type name
// (2) if {viewer entity} is of type Activity, and this relationship is equal to the contact of that activity, then omit 'of {parent entity}'

const Relationship: React.FC<RelationProps> =
    props =>
    {
        const types = useTypes();
        const parentRelation = useParentRelation(props.entity);
        const parentRelationship = useFirstParentRelationship(parentRelation);
        const inactiveName = useInactiveName(props.entity);
        const viewerEntity = useContext(ViewerEntityContext);
        const filteredType = useFilteredEntityType();
        const currentUserStore = useContext(CurrentUserContext);

        const inactiveNameComponent =
            useMemo(
                () =>
                {
                    if (inactiveName)
                    {
                        return <span>
                            {inactiveName}
                        </span>;
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    inactiveName
                ]);

        const isParentRelationSameOnCard =
            useComputed(
                () =>
                    equalsEntity(parentRelation, viewerEntity)
                        ||
                    equalsEntity(parentRelationship, viewerEntity),
                [
                    parentRelation,
                    viewerEntity,
                    parentRelationship
                ]);

        const showOwner =
            useMemo(
                () =>
                {
                    if (equalsEntity(parentRelation, currentUserStore.environmentEntity))
                    {
                        return false;
                    }
                    if (parentRelation && viewerEntity && viewerEntity.entityType.isA(types.Activity.Type))
                    {
                        const viewerParentRelationship =
                            viewerEntity.getRelatedEntityByDefinition(
                                true,
                                types.Relationship.RelationshipDefinition.Activities);

                        const viewerParentRelation =
                            viewerParentRelationship?.getRelatedEntityByDefinition(
                                false,
                                types.Relationship.Organization.RelationshipDefinition.Organization);

                        if (viewerParentRelation && equalsEntity(viewerParentRelation, parentRelation))
                        {
                            return false;
                        }
                    }
                    else if (isParentRelationSameOnCard)
                    {
                        return false;
                    }
                    // TODO [DD]: is the statement below necessary anymore with the statement above?
                    else if (parentRelation && viewerEntity && viewerEntity.entityType.isA(types.Relation.Type))
                    {
                        return !equalsEntity(parentRelation, viewerEntity);
                    }

                    return true;
                },
                [
                    currentUserStore,
                    parentRelation,
                    viewerEntity,
                    types,
                    isParentRelationSameOnCard
                ]);

        const isFilteredOnType =
            useComputed(
                () =>
                    filteredType && props.entity.entityType.isA(filteredType),
                [
                    filteredType,
                    props.entity
                ]);

        const typeName =
            useComputed(
                () =>
                    props.entity.entityType.getName(false, props.entity),
                [
                    props.entity
                ]);

        const isFormer = useEntityValue<boolean>(props.entity, types.Relationship.Field.IsFormer);

        if (showOwner && parentRelation)
        {
            return <span
                className={
                    classNames(isFormer && styles.strikethrough)
                }
            >
                {
                    !isFilteredOnType &&
                        <span>
                            {typeName}&nbsp;
                        </span>
                }
                <LocalizedText
                    code="Generic.Of"
                    value="van"
                />
                &nbsp;
                <span
                    className={styles.parentEntityName}
                >
                    <Link entity={parentRelation} classes={props.classes && props.classes.link} />
                </span>
               {inactiveNameComponent}
            </span>;
        }
        else
        {
            return <span
                className={
                    classNames(isFormer && styles.strikethrough)
                }
            >
                {
                    !isFilteredOnType &&
                        <span>
                            {typeName}
                        </span>
                }
                {inactiveNameComponent}
            </span>;
        }
    };

export default observer(Relationship);
