import { observable } from 'mobx';
import { User } from '../../../../@Api/Model/Implementation/User';

export class EntityInputMutation
{
    // ------------------------- Properties -------------------------

    @observable mutator: User;
    @observable date: Date;

    // ------------------------ Constructor -------------------------

    constructor(mutator: User, date: Date)
    {
        this.mutator = mutator;
        this.date = date;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
