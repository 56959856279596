import { Entity } from '../Model/Implementation/Entity';
import deleteEntity from './deleteEntity';
import localizeText from '../Localization/localizeText';

export default function promptEntityDeletion(entity: Entity): Promise<boolean>
{
    if (entity.entityType.bespoke.isDeletable(entity))
    {
        if (window.confirm(
            localizeText(
                'ConfirmBox.EntityDelete',
                'Weet je zeker dat je wilt verwijderen?')))
        {
            return deleteEntity(entity)
                .then(
                    () =>
                        Promise.resolve(true));
        }
        else
        {
            return Promise.resolve(false);
        }
    }
    else
    {
        window.alert('Verwijderen is niet mogelijk, dit element is nodig voor de correcte werking van de module.');

        return Promise.resolve(false);
    }
}
