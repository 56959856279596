import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import DocumentEditor, { DocumentDefinition, FormFieldBlockId, FormSubmitButtonBlockId, GutenbergBlockTextId, LayoutBlockId } from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/DocumentEditor';
import useDebouncedCallback from '../../../../../../@Util/Debounce/useDebouncedCallback';
import useTransactionalEntity from '../../../../../../@Api/Entity/Bespoke/useTransactionalEntity';
import useTypes from '../../../Type/Api/useTypes';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import FormEntityContext, { FormContext } from '../../Context/FormContext';
import { createTransactionalModel } from '../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import uuid from '../../../../../../@Util/Id/uuid';
import { observable } from 'mobx';
import FormPage from '../../Page/FormPage';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface FormInterfaceEditorProps
{
    entityType: EntityType;
    metadataKey: string;
}

const FormInterfaceEditor: React.FC<FormInterfaceEditorProps> =
    props =>
    {
        const types = useTypes();

        const entityTypeEntity = useTransactionalEntity(props.entityType.entity);

        const [ descriptor, _setDescriptor ] = useState<DocumentDefinition>();
        const [ isLoading, setLoading ] = useState(true);
        const [ revision, setRevision ] = useState<string>();

        useEffect(
            () =>
            {
                const metadata =
                    entityTypeEntity
                        .getObjectValueByField(types.EntityType.Field.Metadata);
                const form = metadata ? metadata[props.metadataKey] : undefined;

                _setDescriptor(form);
                setLoading(false);
            },
            [
                entityTypeEntity,
                types,
                _setDescriptor,
                setLoading,
                props.metadataKey
            ]);

        const saveDescriptor =
            useDebouncedCallback(
                descriptor =>
                {
                    const metadata = {
                        ...entityTypeEntity.getObjectValueByField(types.EntityType.Field.Metadata),
                    };

                    metadata[props.metadataKey] = descriptor;

                    entityTypeEntity.setValueByField(
                        types.EntityType.Field.Metadata,
                        metadata);

                    return entityTypeEntity.checkAndDoCommit()
                        .then(
                            () =>
                                setRevision(uuid()));
                },
                [
                    entityTypeEntity,
                    props.metadataKey,
                    setRevision
                ],
                500);
        const setDescriptor =
            useCallback(
                descriptor =>
                {
                    _setDescriptor(descriptor);
                    saveDescriptor(descriptor);
                },
                [
                    _setDescriptor,
                    saveDescriptor
                ]);

        const formEntity =
            useMemo(
                () =>
                    createTransactionalModel(
                        new Entity(props.entityType)
                            .initialize()),
                [
                    props.entityType
                ]);

        const [ valueByFieldId ] =
            useState(
                () =>
                    observable.map());

        const formContext =
            useComputed<FormContext>(
                () => ({
                    entityType: props.entityType,
                    valueByFieldId: valueByFieldId,
                    onSubmit: () => {return Promise.resolve(false)}
                }),
                [
                    props.entityType,
                    formEntity
                ]);

        const blockTypes =
            useMemo(
                () => [
                    GutenbergBlockTextId,
                    LayoutBlockId,
                    ...props.metadataKey === 'formLayout'
                        ?
                            [
                                FormFieldBlockId,
                                FormSubmitButtonBlockId
                            ]
                        :
                            []
                ],
                [
                    props.metadataKey
                ]
            );

        return <FormEntityContext.Provider
            value={formContext}
        >
            <CardInset>
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                >
                    <ViewGroupItem
                        ratio={3}
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                {
                                    !isLoading &&
                                        <DocumentEditor
                                            type="gutenberg"
                                            onChange={setDescriptor}
                                            definition={descriptor}
                                            blockTypes={blockTypes}
                                        />
                                }
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    <ViewGroupItem
                        ratio={1}
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                <CardHeader>
                                    <LocalizedText
                                        code="Generic.Preview"
                                        value="Voorbeeld"
                                    />
                                </CardHeader>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <FormPage
                                    key={revision}
                                    formId={props.entityType.entity.uuid}
                                    submitted={props.metadataKey === 'submittedFormLayout'}
                                    safeMode
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </CardInset>
        </FormEntityContext.Provider>;
    };

export default observer(FormInterfaceEditor);
