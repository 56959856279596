import React from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import { Grid } from '@material-ui/core';
import Relationship from './Relationship/Relationship';
import Contact from './Contact/Contact';
import useTypes from '../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { useExpansion } from '../../../../Selection/Api/useExpansion';

export interface RightColumnProps
{
    entity: Entity;
}

const Relationships: React.FC<RightColumnProps> =
    props =>
    {
        const types = useTypes();
        const isContactAllowed =
            useComputed(
                () =>
                    props.entity.getRelatedEntitiesByDefinition(
                        true,
                        types.Relationship.RelationshipDefinition.Activities)
                        .some(
                            relationship =>
                                relationship.entityType.isA(types.Relationship.Organization.Type)),
                [
                    props.entity,
                    types
                ]);

        useExpansion(
            props.entity.entityType,
            () => [
                ...types.Relationship.Type.bespoke.getListDependencies()
                    .map(
                        path =>
                            EntityPath.fromEntity(props.entity)
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.Activities,
                                    true)
                                .join(path)),
                ...types.Relationship.Person.Contact.Type.bespoke.getListDependencies()
                    .map(
                        path =>
                            EntityPath.fromEntity(props.entity)
                                .joinTo(
                                    types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                    true)
                                .join(path))
            ],
            () => [
                props.entity
            ],
            [
                types,
                props.entity
            ]);

        return <Grid
            container
            spacing={2}
        >
            <Grid
                item
                xs={12}
            >
                <Relationship
                    entity={props.entity}
                />
            </Grid>
            {
                isContactAllowed &&
                    <Grid
                        item
                        xs={12}
                    >
                        <Contact
                            entity={props.entity}
                        />
                    </Grid>
            }
        </Grid>;
    };

export default observer(Relationships);
