import React, { useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import styles from './LinkExternalAppointment.module.scss';
import Centered from '../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../@Future/Component/Generic/Loader/Loader';
import useEntityByExternalId from '../../../../../../../../@Api/Entity/Bespoke/ExternalId/useEntityByExternalId';
import EntityViewer from '../../../../../Viewer/EntityViewer';
import CreateAppointment from './CreateAppointment/CreateAppointment';
import ExternalAppointment from '../ExternalAppointment';
import useResult from '../../../../../Selection/Hooks/useResult';
import useTypes from '../../../../../Type/Api/useTypes';

export interface LinkExternalAppointmentProps
{
    externalAppointment: ExternalAppointment;
}

export const appointmentType = 'Appointment';

const LinkExternalAppointment: React.FC<LinkExternalAppointmentProps> =
    props =>
    {
        const types = useTypes();

        // Find linked e-mail:
        // - by item id
        const [ calendarItemByEntityIdInItem, isLoadingByEntityId ] =
            useResult(
                props.externalAppointment.entityId && types.CalendarItem.Type,
                (builder, rootPath) =>
                    builder
                        .join(
                            rootPath.joinTo(
                                types.Activity.Appointment.RelationshipDefinition.CalendarItem,
                                true))
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.Entity.Field.Uuid),
                                    undefined,
                                    props.externalAppointment.entityId
                                )),
                [
                    types,
                    props.externalAppointment
                ]);

        // - by external id
        const [ linkedAppointmentByExternalId, isLoadingByExternalId ] =
            useEntityByExternalId(
                props.externalAppointment.service,
                appointmentType,
                props.externalAppointment.id);

        const linkedAppointment =
            useComputed(
                () =>
                    calendarItemByEntityIdInItem?.getRelatedEntityByDefinition(
                        true,
                        types.Activity.Appointment.RelationshipDefinition.CalendarItem)
                    || linkedAppointmentByExternalId,
                [
                    calendarItemByEntityIdInItem,
                    linkedAppointmentByExternalId,
                    types
                ]);

        const isLoading =
            useMemo(
                () =>
                    isLoadingByEntityId || isLoadingByExternalId,
                [
                    isLoadingByEntityId,
                    isLoadingByExternalId
                ]);

        if (isLoading)
        {
            return <div
                className={styles.root}
            >
                <Centered
                    horizontal
                >
                    <Loader />
                </Centered>
            </div>;
        }
        else
        {
            if (linkedAppointment)
            {
                return <div
                    className={styles.root}
                >
                    <EntityViewer
                        entity={linkedAppointment}
                    />
                </div>;
            }
            else
            {
                return <div
                    className={styles.root}
                >
                    <CreateAppointment
                        externalAppointment={props.externalAppointment}
                    />
                </div>;
            }
        }
    };

export default observer(LinkExternalAppointment);
