import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import useDividerGlue from '../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TrackItem from '../../Model/TrackItem';
import { TrackItemTooltipSubject } from './TrackItemTooltipSubject';
import { TrackItemTooltipTimespan } from './TrackItemTooltipTimespan';
import { TrackItemTooltipAddress } from './TrackItemTooltipAddress';
import { TrackItemTooltipRelation } from './TrackItemTooltipRelation';
import { useExpansion } from '../../../../../Selection/Api/useExpansion';
import useTypes from '../../../../../Type/Api/useTypes';
import useRelatedEntity from '../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';


export interface TrackItemTooltipProps
{
    trackItem: TrackItem;
}

export const TrackItemTooltip: React.FC<TrackItemTooltipProps> =
    observer(
        ({
             trackItem
        }) =>
        {
            const glue = useDividerGlue();
            const types = useTypes();

            const activity =
                useMemo(
                    () =>
                        trackItem.item.entity,
                    [
                        trackItem
                    ]
            );

            useExpansion(
                types.Activity.Type,
                rootPath =>
                    [
                        rootPath
                            .joinTo(
                                types.Activity.RelationshipDefinition.Contact,
                                false),
                        rootPath
                            .joinTo(
                                types.Activity.RelationshipDefinition.Relationship,
                                true)
                            .castTo(types.Relationship.Organization.Type)
                            .joinTo(
                                types.Relationship.Organization.RelationshipDefinition.Organization,
                                false)
                            .joinTo(
                                types.Relation.Organization.RelationshipDefinition.VisitingAddress,
                                false)
                            .joinTo(
                                types.Address.RelationshipDefinition.Country,
                                false),
                        rootPath
                            .joinTo(
                                types.Activity.RelationshipDefinition.Relationship,
                                true)
                            .castTo(types.Relationship.Person.Type)
                            .joinTo(
                                types.Relationship.Person.RelationshipDefinition.Person,
                                false)
                            .joinTo(
                                types.Relation.Person.RelationshipDefinition.Address,
                                false)
                            .joinTo(
                                types.Address.RelationshipDefinition.Country,
                                false),
                    ],
                () => trackItem.item.entity ? [ trackItem.item.entity ] : [],
                [
                    types,
                    trackItem
                ]
            );

            const relationship = useRelatedEntity(activity, types.Activity.RelationshipDefinition.Relationship, true);
            const organization = useRelatedEntity(relationship, types.Relationship.Organization.RelationshipDefinition.Organization, false);
            const person = useRelatedEntity(relationship, types.Relationship.Person.RelationshipDefinition.Person, false);

            return <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={glue}
            >
                <ViewGroupItem>
                    <TrackItemTooltipSubject
                        trackItem={trackItem}
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <TrackItemTooltipTimespan
                        trackItem={trackItem}
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <TrackItemTooltipAddress
                        relation={organization || person}
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <TrackItemTooltipRelation
                        activity={activity}
                        relation={organization || person}
                    />
                </ViewGroupItem>
            </ViewGroup>
        }
    );