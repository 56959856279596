import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import styles from './TimePicker.module.scss';
import { classNames } from '../../../../Util/Class/classNames';
import { InputProps as StandardInputProps } from '@material-ui/core';
import TextEditor from '../TextEditor/TextEditor';
import useSwitch from '../../../../../@Util/Switch/useSwitch';
import Popper from '../../Popper/Popper';
import Card from '../../Card/Card';
import ViewGroup from '../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import Menu from '../../Menu/Menu';
import Item from '../../Menu/Item/Item';

export interface TimePickerClasses
{
    root?: string;
}

export interface TimePickerProps
{
    hour?: number,
    minute?: number,
    onChange?: (hour: number, minute: number) => void;
    classes?: TimePickerClasses;
    disableUnderline?: boolean;
    inputProps?: StandardInputProps['inputProps'];
    disabled?: boolean;
}

const timeFormat = 'HH:mm';

function getTimeComponentAsString(number: number)
{
    if (number < 10)
    {
        return `0${number}`;
    }
    else
    {
        return number.toString();
    }
}

const TimePicker: React.FC<TimePickerProps> =
    props =>
    {
        const [ text, setText ] = useState<string | undefined>();

        const resetText =
            useCallback(
                () =>
                {
                    if (props.hour !== undefined
                        && !props.minute !== undefined)
                    {
                        setText(moment().set('hour', props.hour).set('minute', props.minute).format(timeFormat));
                    }
                    else
                    {
                        setText(undefined);
                    }
                },
                [
                    props.hour,
                    props.minute,
                    setText
                ]);

        useEffect(
            () =>
            {
                resetText();
            },
            [
                resetText
            ]);

        const [ isOpen, open, close ] = useSwitch(false);

        const inputProps =
            useMemo(
                () => ({
                    onFocus:
                        (event: any) =>
                        {
                            open();

                            if (props.inputProps?.onFocus)
                            {
                                props.inputProps?.onFocus(event);
                            }
                        },
                    onBlur:
                        (event: any) =>
                        {
                            if (props.inputProps?.onBlur)
                            {
                                props.inputProps?.onBlur(event);
                            }

                            resetText();
                        },
                    onClick:
                        (event) =>
                        {
                            open();
                            event.stopPropagation();
                        },
                    onChange:
                        (event: any) =>
                        {
                            const text = event.target.value;

                            if (text.length === 0)
                            {
                                props.onChange(undefined, undefined);
                            }
                            else if (text.length === 5)
                            {
                                const date = moment(text, timeFormat);

                                if (date.isValid())
                                {
                                    props.onChange(date.get('hour'), date.get('minute'));
                                }
                                else if (props.hour !== undefined && props.minute !== undefined)
                                {
                                    resetText();
                                }
                            }
                        },
                    ...inputProps
                }),
                [
                    text,
                    open,
                    close,
                    props,
                    props.inputProps,
                    props.onChange,
                    props.hour,
                    props.minute,
                    resetText
                ]);

        const setHour =
            useCallback(
                (hour: number) =>
                {
                    props.onChange(hour, props.minute);
                },
                [
                    props.onChange,
                    props.minute
                ]);

        const setMinute =
            useCallback(
                (minute: number) =>
                {
                    props.onChange(props.hour, minute);

                    close();
                },
                [
                    props.onChange,
                    props.hour,
                    close
                ]);

        const cardClasses =
            useMemo(
                () => ({
                    root: styles.card
                }),
                []);

        return <Popper
            open={!props.disabled && isOpen}
            onClose={isOpen ? close : undefined}
            fullWidth
            reference={
                <div
                    className={styles.field}
                >
                    <TextEditor
                        value={text}
                        onChange={setText}
                        inputProps={inputProps}
                        disableUnderline={props.disableUnderline}
                        disabled={props.disabled}
                    />
                </div>
            }
            popper={
                <div
                    className={styles.popper}
                >
                    <Card
                        classes={cardClasses}
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={0}
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <Menu
                                    className={classNames(styles.menu, styles.hourMenu)}
                                    scrollToItemIdx={props.hour === undefined ? 8 : props.hour}
                                >
                                    {
                                        new Array(24)
                                            .fill(0)
                                            .map(
                                                (v, idx) =>
                                                    <Item
                                                        key={idx}
                                                        name={getTimeComponentAsString(idx)}
                                                        active={idx === props.hour}
                                                        onClick={() => setHour(idx)}
                                                    />)
                                    }
                                </Menu>
                            </ViewGroupItem>
                            <ViewGroupItem
                                ratio={1}
                            >
                                <Menu
                                    className={styles.menu}
                                    scrollToItemIdx={props.minute === undefined ? undefined : props.minute / 5}
                                >
                                    {
                                        new Array(12)
                                            .fill(0)
                                            .map(
                                                (v, idx) =>
                                                    <Item
                                                        key={idx}
                                                        name={getTimeComponentAsString(idx * 5)}
                                                        active={idx * 5 === props.minute}
                                                        onClick={() => setMinute(idx * 5)}
                                                    />)
                                    }
                                </Menu>
                            </ViewGroupItem>
                        </ViewGroup>
                    </Card>
                </div>
            }
        />;
    };

export default TimePicker;
