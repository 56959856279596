import { Entity } from '../../../../../@Api/Model/Implementation/Entity';

export class AuditTrailEntity
{
    entity: Entity;
    title: string;

    constructor(entity: Entity, title: string)
    {
        this.entity = entity;
        this.title = title;
    }
}