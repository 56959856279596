import { reference } from '../../../@Util/Serialization/Serialization';
import { EntityNode } from './EntityNode';
import { ConstraintNode } from './ConstraintNode';
import { observable } from 'mobx';
import { EntityEvent } from './EntityEvent';
import { EntityValueMutation } from './EntityValueMutation';
import { EntityType } from './EntityType';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';

export class InstanceOfConstraintNode extends ConstraintNode
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'EntityNode') @observable.ref entityNode: EntityNode;
    @observable.ref entityType: EntityType;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityNode: EntityNode,
                entityType: EntityType)
    {
        super();

        this.entityNode = entityNode;
        this.entityType = entityType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getDependencies(): EntityNode[]
    {
        return [
            ...super.getDependencies(),
            this.entityNode,
        ];
    }

    hashCode(): string
    {
        return `${super.hashCode()},${this.entityNode && this.entityNode.alias},${this.entityType.id}`;
    }

    equals(node: InstanceOfConstraintNode): boolean
    {
        return super.equals(node)
            && (this.entityNode && this.entityNode.alias) === (node.entityNode && node.entityNode.alias)
            && this.entityType === node.entityType;
    }

    isAffectedBy(event: EntityEvent): boolean
    {
        return event instanceof EntityValueMutation
            && event.entityField === loadModuleDirectly(EntityTypeStore).bespoke.types.Entity.Field.Type;
    }

    descriptor()
    {
        return Object.assign(super.descriptor(), {
            type: 'InstanceOf',
            entityNodeAlias: this.entityNode.alias,
            entityTypeId: this.entityType.id
        });
    }

    // ----------------------- Private logic ------------------------
}
