import React, { useCallback, useState } from 'react';
import styles from './Item.module.scss';
import ViewGroup from '../../ViewGroup/ViewGroup';
import { primaryColor, textSecondaryColor } from '../../../../../@Resource/Theme/Theme';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import Icon from '../../Icon/Icon';
import { ButtonBase } from '@material-ui/core';
import { classNames } from '../../../../Util/Class/classNames';
import { useButtonClickHandler } from '../../Button/Button';
import Loader from '../../Loader/Loader';

export interface ItemClasses
{
    root?: string;
    label?: string;
}

export interface ItemProps<T = any>
{
    icon?: string;
    iconColor?: string;
    iconOutlined?: boolean;
    name?: React.ReactNode;
    onClick: (value?: T) => void;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
    className?: string;
    classes?: ItemClasses;
    active?: boolean;
    indent?: number;
    disabled?: boolean;
    value?: T
}

const Item: React.FC<ItemProps> =
    props =>
    {
        const [ isLoading, setLoading ] = useState(false);
        const {onClick, value} = props;

        const doClick =
            useCallback(
                () =>
                    onClick(value),
                [
                    onClick,
                    value
                ]);

        const click =
            useButtonClickHandler(
                doClick,
                setLoading);

        return <div
            className={
                classNames(
                    styles.root,
                    props.active && styles.active,
                    props.classes && props.classes.root,
                    isLoading && styles.loading)}
        >
            <ButtonBase
                onClick={click}
                className={styles.button}
                focusRipple
                disabled={isLoading || props.disabled}
            >
                <ViewGroup
                    spacing={10}
                    orientation="horizontal"
                    alignment="center"
                    className={styles.viewGroup}
                >
                    {
                        props.prefix &&
                            <ViewGroupItem>
                                {props.prefix}
                            </ViewGroupItem>
                    }
                    {
                        props.icon &&
                            <ViewGroupItem>
                                <Icon
                                    icon={props.icon}
                                    color={props.active ? primaryColor : props.iconColor}
                                    outlined={props.iconOutlined}
                                    size={20}
                                />
                            </ViewGroupItem>
                    }
                    {
                        props.name &&
                            <ViewGroupItem
                                className={classNames(
                                    styles.label,
                                    props.className,
                                    props.classes && props.classes.label,
                                    props.disabled && styles.disabled
                                )}
                                ratio={1}
                            >
                                <span
                                    style={{
                                        paddingLeft: props.indent
                                    }}
                                >
                                    {props.name}
                                </span>
                            </ViewGroupItem>
                    }
                    {
                        props.suffix &&
                            <ViewGroupItem>
                                <div
                                    className={styles.suffix}
                                >
                                    {props.suffix}
                                </div>
                            </ViewGroupItem>
                    }
                    {
                        isLoading &&
                            <ViewGroupItem>
                                <Loader
                                    size={17}
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </ButtonBase>
        </div>;
    };

Item.defaultProps = {
    iconColor: textSecondaryColor
};

export default Item;
