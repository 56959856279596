import React, { useMemo } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../../Type/Api/useTypes';
import useResults from '../../../../../Selection/Hooks/useResults';
import useCount from '../../../../../Selection/Hooks/useCount';
import TimeRegistrationButton from '../../TimeRegistration/TimeRegistrationButton/TimeRegistrationButton';
import PrimaryButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import performAction from '../../../../../../../../@Api/Entity/performAction';
import SendDocumentButton from '../../../../../Item/Navigator/More/ProductLinesEditor/Button/SendDocumentButton';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import { primaryColor } from '../../../../../../../../@Resource/Theme/Theme';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import sendDocument from '../../../../../Item/Navigator/More/ProductLinesEditor/Api/sendDocument';
import constructRelatedEntity from '../../../../../Dataset/Segment/Api/constructRelatedEntity';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import ButtonGroup from '../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/ButtonGroup';
import useActiveTimeRegistration from '../../../../../Timetracker/Hooks/useActiveTimeRegistration';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useSetting from '../../../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../../../Setting/SettingStore';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import { asyncOpenWindow } from '../../../../../../../../@Util/Window/asyncOpenWindow';

const copy = require('copy-to-clipboard');

export interface WorkOrderActionsProps
{
    entity: Entity;
}

const WorkOrderActions: React.FC<WorkOrderActionsProps> =
    props =>
    {
        const types = useTypes();
        const isWorkOrderFinalized =
            useComputed(
                () =>
                    props.entity.hasValueForField(types.Activity.WorkOrder.Field.SignedDocument)
                    || props.entity.hasValueForField(types.Activity.WorkOrder.Field.FinalizedDocument),
                [
                    props.entity,
                    types
                ]);
        const canWorkOrderBeExecuted =
            useComputed(
                () =>
                {
                    const phase =
                        props.entity.getRelatedEntityByDefinition(
                            false,
                            types.Activity.WorkOrder.RelationshipDefinition.Phase);

                    if (phase)
                    {
                        const phaseCode = phase.getObjectValueByField(types.Datastore.Field.Code);

                        return phaseCode === 'Concept'
                            || phaseCode === 'Planned'
                            || phaseCode === 'InProgress';
                    }
                    else
                    {
                        return false;
                    }
                },
                [
                    props.entity,
                    types
                ]);
        const isWorkOrderSigned =
            useComputed(
                () =>
                {
                    const phase =
                        props.entity.getRelatedEntityByDefinition(
                            false,
                            types.Activity.WorkOrder.RelationshipDefinition.Phase);

                    if (phase)
                    {
                        const phaseCode = phase.getObjectValueByField(types.Datastore.Field.Code);

                        return phaseCode === 'Signed';
                    }
                    else
                    {
                        return false;
                    }
                },
                [
                    props.entity,
                    types
                ]);
        const timeRegistrationActivityTypes =
            useResults(
                types.TimeRegistrationActivity.Type,
                () => {},
                [
                    types
                ]);

        const [ isSalesOrderEnabled ] = useSetting(SettingSource.Organization, 'Invoicing.IsSalesOrderEnabled');

        const numberOfInvoices =
            useCount(
                types.Activity.Invoice.Type,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath.joinTo(
                                    types.Activity.RelationshipDefinition.LinkedActivities,
                                    true),
                                props.entity)),
                [
                    types,
                    props.entity
                ]);
        const activeTimeRegistration = useActiveTimeRegistration();
        const entityByParameter =
            useMemo(
                () =>
                    new Map([
                        [ 'WorkOrder', props.entity ]
                    ]),
                [
                    props.entity
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
        >
            <ViewGroupItem
                ratio={1}
            >
                <ButtonGroup>
                    {
                        canWorkOrderBeExecuted
                        && timeRegistrationActivityTypes
                        && timeRegistrationActivityTypes.map(
                            timeRegistrationType =>
                                <TimeRegistrationButton
                                    key={timeRegistrationType.uuid}
                                    entity={props.entity}
                                    activity={timeRegistrationType}
                                />)
                    }
                </ButtonGroup>
            </ViewGroupItem>
            <ViewGroupItem>
                <ButtonGroup>
                    {
                        !isWorkOrderFinalized
                        && !activeTimeRegistration
                        &&
                            <PrimaryButton
                                icon="check"
                                label={
                                    <LocalizedText
                                        code="WorkOrder.Finalize"
                                        value="Gereed melden"
                                    />
                                }
                                onClick={
                                    () =>
                                        performAction(
                                            props.entity,
                                            {
                                                code: 'Activity.WorkOrder.Finalize',
                                                name: localizeText('WorkOrder.Finalize', 'Gereed melden')
                                            })
                                }
                                fullWidth
                                size="sm"
                            />
                    }
                    {
                        isWorkOrderFinalized &&
                            <SendDocumentButton
                                entity={props.entity}
                                fullWidth
                                actionCode="Activity.WorkOrder.Send"
                                emailTemplateType={types.Template.Email.WorkOrder.Type}
                                label={
                                    <LocalizedText
                                        code="WorkOrder.Send"
                                        value="Werkbon versturen ter info"
                                    />
                                }
                                size="sm"
                                entityByParameter={entityByParameter}
                            />
                    }
                    {
                        isWorkOrderFinalized
                        && !isWorkOrderSigned
                        &&
                            <MenuButton
                                color={primaryColor}
                                icon="edit"
                                label={
                                    <LocalizedText
                                        code="Generic.DigitallySign"
                                        value="Digitaal ondertekenen"
                                    />
                                }
                                filled
                                fullWidth
                                size="sm"
                            >
                                <Menu>
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Generic.Direct"
                                                value="Direct"
                                            />
                                        }
                                        onClick={
                                            () =>
                                                asyncOpenWindow(
                                                    () =>
                                                        performAction(
                                                            props.entity,
                                                            {
                                                                code: 'Activity.WorkOrder.GenerateSignLink',
                                                            })
                                                            .then(
                                                                result =>
                                                                    result.result
                                                            )
                                                )
                                        }
                                    />
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Generic.ViaEmail"
                                                value="Via e-mail"
                                            />
                                        }
                                        onClick={
                                            () =>
                                                sendDocument(
                                                    props.entity,
                                                    types.Template.Email.WorkOrderSign.Type,
                                                    'Activity.WorkOrder.Send',
                                                    {
                                                        doSign: true
                                                    },
                                                    undefined,
                                                    entityByParameter)
                                        }
                                    />
                                    <Item
                                        name={
                                            <LocalizedText
                                                code="Generic.ViaLink"
                                                value="Via link"
                                            />
                                        }
                                        onClick={
                                            () =>
                                                performAction(
                                                    props.entity,
                                                    {
                                                        code: 'Activity.WorkOrder.GenerateSignLink',
                                                        name: localizeText('Generic.CopyToClipboard', 'Kopieren naar klembord')
                                                    })
                                                    .then(
                                                        result =>
                                                            copy(result.result))
                                        }
                                    />
                                </Menu>
                            </MenuButton>
                    }
                    {
                        isWorkOrderFinalized
                        && numberOfInvoices === 0
                        &&
                            <PrimaryButton
                                icon="receipt"
                                label={
                                    isSalesOrderEnabled
                                    ?
                                        <LocalizedText
                                            code="WorkOrder.CreateSalesOrder"
                                            value="Verkooporder aanmaken"
                                        />
                                    :
                                        <LocalizedText
                                            code="WorkOrder.CreateInvoice"
                                            value="Factureren"
                                        />
                                }
                                onClick={
                                    () =>
                                        constructRelatedEntity(
                                            props.entity,
                                            EntityPath.fromEntity(props.entity)
                                                .joinTo(
                                                    types.Activity.RelationshipDefinition.LinkedActivities,
                                                    false)
                                                .castTo(isSalesOrderEnabled ? types.Activity.SalesOrder.Type : types.Activity.Invoice.Type))
                                }
                                fullWidth
                                size="sm"
                            />
                    }
                </ButtonGroup>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(WorkOrderActions);
