import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../Value/PrimitiveValue';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Value from '../../Value/Value';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import EmptyValue from '../../Value/EmptyValue';
import CollectionValue from '../../Value/CollectionValue';
import localizeText from '../../../Localization/localizeText';

export default class JoinWithDelimiterComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref delimiter: string;
    @observable.ref value: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(
        delimiter: string,
        value: Computation<any, any>
    )
    {
        super();

        this.delimiter = delimiter;
        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('Text'));
    }

    getName(): string
    {
        return localizeText(
            'JoinWithDelimiter',
            'Samenvoegen met scheidingsteken'
        );
    }

    validate(): Validation[]
    {
        if (!this.delimiter)
        {
            return [
                new Validation(
                    'Error',
                    localizeText('Computation.NoDelimiter', 'Het scheidingsteken is niet ingevuld.'))
            ];
        }
        else
        {
            return this.value.validate();
        }
    }

    isAsync(): boolean
    {
        return this.value.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const value = await safelyApplyFunction(this.value, context);

        return this.getStringOfValue(value);
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        const value = safelySynchronousApplyFunction(this.value, context);

        return this.getStringOfValue(value);
    }

    getStringOfValue(value: Value<any, any>): Value<any, any>
    {
        if (value instanceof CollectionValue)
        {
            const stringOfCollection = value.value.join(this.delimiter);

            return new PrimitiveValue(
                DataObject.constructFromTypeIdAndValue(
                    'Text',
                    stringOfCollection));
        }
        else
        {
            return EmptyValue.instance;
        }
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'JoinWithDelimiter';
        descriptor.delimiter = this.delimiter;
        descriptor.value = this.value.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.value.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new JoinWithDelimiterComputation(
            descriptor.delimiter,
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}