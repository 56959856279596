import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntityField } from '../../../../../@Api/Model/Implementation/EntityField';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';

export default function getHiddenFields(entityType: EntityType)
{
    const entityTypeStore = loadModuleDirectly(EntityTypeStore);
    const types = entityTypeStore.bespoke.types;

    const entityField = types.Entity.Field;
    const entityRelationship = types.Entity.RelationshipDefinition;

    return new Set<EntityField | EntityRelationshipDefinition>([
        entityRelationship.WorkflowState,
        entityRelationship.CloseWorkflowState,
        entityField.IsRoot,
        entityField.IsActive,
        entityField.Deadline,
        entityField.FollowupStartDate,
        entityField.FollowupEndDate,
        entityField.IsExclusion,
        entityField.CloseDate,
        types.Entity.Field.CreationDate,
        types.Pack.RelationshipDefinition.Entities,
        types.Entity.RelationshipDefinition.PhaseReference
    ]);
}
