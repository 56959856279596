import SourceField from '../../SourceField';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import ValueToEntitySourceFieldMapping from '../ValueToEntity/ValueToEntitySourceFieldMapping';
import uuid from '../../../../../../../@Util/Id/uuid';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import ValueToEntityMapping from '../ValueToEntity/ValueToEntityMapping';
import SourceFieldMapping from '../SourceField/SourceFieldMapping';
import { TextType } from '../../../../../DataObject/Type/Text/TextType';
import { NumberType } from '../../../../../DataObject/Type/Number/NumberType';
import { DateType } from '../../../../../DataObject/Type/Date/DateType';
import { DateTimeType } from '../../../../../DataObject/Type/Date/DateTime/DateTimeType';
import DateSourceFieldMapping from '../SourceField/DateSourceFieldMapping';
import getSourceValues from '../../../Api/getSourceValues';
import resolveValueToValueSourceFieldMapping from './resolveValueToValueSourceFieldMapping';
import { RichTextType } from '../../../../../DataObject/Type/RichText/RichTextType';
import { LocalizedTextType } from '../../../../../DataObject/Type/LocalizedText/LocalizedTextType';

export default async function resolveNewSourceFieldMapping(imprt: Entity,
                                                           targetFieldPath: EntityFieldPath,
                                                           sourceField: SourceField,
                                                           newSourceFieldMappingId: string)
{
    if (targetFieldPath.isRelationship)
    {
        const sourceValues = await getSourceValues(imprt, sourceField);

        return new ValueToEntitySourceFieldMapping(
            newSourceFieldMappingId,
            targetFieldPath,
            sourceField.id,
            sourceValues.map(
                sourceValue =>
                    new ValueToEntityMapping(
                        uuid(),
                        sourceValue.value,
                        sourceValue.name,
                        undefined)));
    }
    else if (targetFieldPath.isField
        && (targetFieldPath.field.dataObjectSpecification.type instanceof DateType
            || targetFieldPath.field.dataObjectSpecification.type instanceof DateTimeType))
    {
        return new DateSourceFieldMapping(
            newSourceFieldMappingId,
            targetFieldPath,
            sourceField.id);
    }
    else if (targetFieldPath.isField
        && !(targetFieldPath.field.dataObjectSpecification.type instanceof TextType
            || targetFieldPath.field.dataObjectSpecification.type instanceof RichTextType
            || targetFieldPath.field.dataObjectSpecification.type instanceof NumberType
            || targetFieldPath.field.dataObjectSpecification.type instanceof LocalizedTextType))
    {
        return resolveValueToValueSourceFieldMapping(
            imprt,
            targetFieldPath,
            sourceField,
            newSourceFieldMappingId);
    }
    else
    {
        return new SourceFieldMapping(
            newSourceFieldMappingId,
            targetFieldPath,
            sourceField.id);
    }
}
