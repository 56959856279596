import { observable } from 'mobx';
import { BaseStore } from '../../../../@Framework/Store/BaseStore';

export class ViewComponent<T extends BaseStore = any, P = any>
{
    // ------------------------- Properties -------------------------

    @observable.ref component: any;
    @observable.ref store: T;
    @observable name: string;
    @observable.ref props: P;

    // ------------------------ Constructor -------------------------

    constructor(component: any,
                store: T,
                name?: string,
                props?: P)
    {
        this.component = component;
        this.store = store;
        this.name = name;
        this.props = props;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
