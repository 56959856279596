import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ActionEditorProps } from '../../ActionEditor';
import { runInAction } from 'mobx';
import CardInset from '../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationEditor from '../../../Computation/ComputationEditor';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import DeleteEntityAction from '../../../../../../../../../../@Api/Automation/Function/Action/DeleteEntityAction';
import InputGroup from '../../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface DeleteEntityActionEditorProps extends ActionEditorProps<DeleteEntityAction>
{

}

const DeleteEntityActionEditor: React.FC<DeleteEntityActionEditorProps> =
    props =>
    {
        const setValue =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.action.value = value),
                [
                    props.action
                ]);

        return <CardInset
            top={false}
        >
            <InputGroup>
                <Input
                    label={
                        <LocalizedText
                            code="AutomationEditor.SelectRecordForDeletion"
                            value="Selecteer het record dat je wilt verwijderen"
                        />
                    }
                    labelPosition="top"
                >
                    <ComputationEditor
                        context={props.context}
                        value={props.action.value}
                        onChange={setValue}
                    />
                </Input>
            </InputGroup>
        </CardInset>;
    };

export default observer(DeleteEntityActionEditor);
