import { BaseStore, getOrCompute, PropType } from '../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { Color } from '@material-ui/core';
import * as MaterialUIColors from '@material-ui/core/colors';
import { HexagonIconStore } from '../HexagonIcon/HexagonIconStore';
import { ButtonStore } from '../Button/ButtonStore';

const AvatarsColor: Color[] =
[
    MaterialUIColors.amber,
    MaterialUIColors.blue,
    MaterialUIColors.blueGrey,
    MaterialUIColors.brown,
    MaterialUIColors.cyan,
    MaterialUIColors.deepOrange,
    MaterialUIColors.deepPurple,
    MaterialUIColors.green,
    MaterialUIColors.grey,
    MaterialUIColors.indigo,
    MaterialUIColors.lightBlue,
    MaterialUIColors.lightGreen,
    MaterialUIColors.lime,
    MaterialUIColors.orange,
    MaterialUIColors.pink,
    MaterialUIColors.purple,
    MaterialUIColors.red,
    MaterialUIColors.teal,
    MaterialUIColors.yellow
];

export enum AvatarType
{
    Square,
    Round,
    Hexagon
}

export enum SubAvatarPosition
{
    Top,
    Bottom
}

export interface AvatarProps
{
    type?: PropType<AvatarStore, AvatarProps, AvatarType>;
    src?: PropType<AvatarStore, AvatarProps, string>;
    characters?: PropType<AvatarStore, AvatarProps, string>;
    size?: PropType<AvatarStore, AvatarProps, number>;
    color?: PropType<AvatarStore, AvatarProps, string>;
    unfilledBackgroundColor?: PropType<AvatarStore, AvatarProps, string>;
    icon?: PropType<AvatarStore, AvatarProps, string>;
    overrideIconFontSize?: PropType<AvatarStore, AvatarProps, number>;
    isFilled?: PropType<AvatarStore, AvatarProps, boolean>;
    isVisible?: PropType<AvatarStore, AvatarProps, boolean>;
    subAvatar?: PropType<AvatarStore, AvatarProps, AvatarStore>;
    subAvatarPosition?: PropType<AvatarStore, AvatarProps, SubAvatarPosition>;
    borderSize?: PropType<AvatarStore, AvatarProps, number>;
    borderColor?: PropType<AvatarStore, AvatarProps, string>;
    popperButtons?: PropType<AvatarStore, AvatarProps, ButtonStore[]>;
    spacingTop?: PropType<AvatarStore, AvatarProps, number>;
    spacingBottom?: PropType<AvatarStore, AvatarProps, number>;
}

export const AvatarDefaultProps: Partial<AvatarProps> =
{
    color: 'white',
    unfilledBackgroundColor: 'rgba(0,0,0,0)',
    isFilled: true,
    isVisible:
        store =>
            (store.characters && store.characters.length > 0)
                ||
            store.failSafeSrc !== undefined
                ||
            store.icon !== undefined,
    size: 34,
    type: AvatarType.Round,
    subAvatarPosition: SubAvatarPosition.Bottom,
    borderSize: 1
};

export class AvatarStore<P extends AvatarProps = AvatarProps> extends BaseStore<P>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable isHovering: boolean = false;
    @observable isHoveringOverPopper: boolean = false;
    @observable failedToLoadSrc: string;

    // ------------------------ Constructor -------------------------

    constructor(props: P, defaultProps?: Partial<P>)
    {
        super(
            props,
            {
                ...AvatarDefaultProps,
                ...defaultProps,
            });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get popperButtons(): ButtonStore[]
    {
        return getOrCompute(this, this.props.popperButtons);
    }

    @computed
    get type(): AvatarType
    {
        return getOrCompute(this, this.props.type);
    }

    @computed
    get src(): string
    {
        return getOrCompute(this, this.props.src);
    }

    @computed
    get characters(): string
    {
        return getOrCompute(this, this.props.characters);
    }

    @computed
    get color(): string
    {
        let color = getOrCompute(this, this.props.color);

        if (!color)
        {
            if (this.character && this.character.length > 0)
            {
                let index = this.character.charCodeAt(0);

                if (index >= AvatarsColor.length)
                {
                    index = (index % AvatarsColor.length);
                }

                return AvatarsColor[index].A700;
            }
            else
            {
                return MaterialUIColors.grey[300];
            }
        }
        else
        {
            return color;
        }
    }

    @computed
    get unfilledBackgroundColor(): string
    {
        return getOrCompute(this, this.props.unfilledBackgroundColor);
    }

    @computed
    get size(): number
    {
        return getOrCompute(this, this.props.size);
    }

    @computed
    get borderSize(): number
    {
        return getOrCompute(this, this.props.borderSize);
    }

    @computed
    get borderColor(): string
    {
        return getOrCompute(this, this.props.borderColor, this.color);
    }

    @computed
    get isFilled(): boolean
    {
        return getOrCompute(this, this.props.isFilled);
    }

    @computed
    get isVisible(): boolean
    {
        return getOrCompute(this, this.props.isVisible);
    }

    @computed
    get subAvatar(): AvatarStore
    {
        return getOrCompute(this, this.props.subAvatar);
    }

    @computed
    get subAvatarPosition(): SubAvatarPosition
    {
        return getOrCompute(this, this.props.subAvatarPosition);
    }

    @computed
    get spacingTop(): number
    {
        return getOrCompute(this, this.props.spacingTop);
    }

    @computed
    get spacingBotttom(): number
    {
        return getOrCompute(this, this.props.spacingBottom);
    }

    @computed
    get iconFontSize(): string
    {
        return this.overrideIconFontSize ? `${this.overrideIconFontSize}px` : `${0.65 * this.size}px`;
    }

    @computed
    public get style(): any
    {
        return {
            width: this.size,
            height: this.size,
            backgroundColor:
                this.failSafeSrc === undefined
                    ?
                        (this.isFilled
                            ?
                                this.color
                            :
                                this.unfilledBackgroundColor)
                    :
                        this.unfilledBackgroundColor,
            color:
                this.isFilled
                    ?
                        'white'
                    :
                        this.color,
            fontSize: this.iconFontSize,
            border: `${this.borderSize}px solid ${this.borderColor}`,
            borderRadius:
                this.type === AvatarType.Square
                    ?
                        5
                    :
                        this.type === AvatarType.Round
                            ?
                                '50%'
                            :
                                undefined
        };
    }

    @computed
    public get subStyle(): any
    {
        const size = this.size == null ? 17 : this.size / 2;

        return {
            width: size,
            height: size,
            backgroundColor:
                this.failSafeSrc === undefined
                    ?
                        (this.isFilled
                            ?
                                this.color
                            :
                                this.unfilledBackgroundColor)
                    :
                        this.unfilledBackgroundColor,
            color:
                this.isFilled
                    ?
                        'white'
                    :
                        this.color,
            fontSize: this.iconFontSize,
            border: !this.isFilled && `2px solid ${this.color}`
        };
    }

    @computed
    get character(): string
    {
        // Only get character if there is no image provided
        if (this.failSafeSrc === undefined && this.characters)
        {
            let match = /[a-zA-Z0-9]/.exec(this.characters);

            if (match)
            {
                return this.characters[match.index].toUpperCase();
            }
            else
            {
                return ' ';
            }
        }
        else
        {
            return null;
        }
    }
    
    @computed
    get icon(): string
    {
        return getOrCompute(this, this.props.icon);
    }

    @computed
    get overrideIconFontSize(): number
    {
        return getOrCompute(this, this.props.overrideIconFontSize);
    }

    @computed
    get isEmpty(): boolean
    {
        return !((this.failSafeSrc && this.failSafeSrc.length > 0) || (this.characters && this.characters.length > 0));
    }

    @computed
    get isLoadFailure(): boolean
    {
        return this.src === this.failedToLoadSrc;
    }

    @computed
    get failSafeSrc(): string
    {
        if (this.isLoadFailure)
        {
            return undefined;
        }
        else
        {
            return this.src;
        }
    }

    // --------------------------- Stores ---------------------------

    @computed
    get hexagonStore(): HexagonIconStore
    {
        if (this.type === AvatarType.Hexagon)
        {
            return new HexagonIconStore(
                typeof this.size === 'number' ? this.size : 40,
                this.icon,
                this.failSafeSrc,
                this.isFilled ? this.color : this.unfilledBackgroundColor,
                this.isFilled ? 'white' : this.color,
                this.borderColor);
        }
        else
        {
            return null;
        }
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    startHovering()
    {
        this.isHovering = true;
    }

    @action.bound
    stopHovering()
    {
        this.isHovering = false;
    }

    @action.bound
    startHoveringOverPopper()
    {
        this.isHoveringOverPopper = true;
    }

    @action.bound
    stopHoveringOverPopper()
    {
        this.isHoveringOverPopper = false;
    }

    @action.bound
    onFailToLoad()
    {
        this.failedToLoadSrc = this.src;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
