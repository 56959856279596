import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Icon, TableCell, TableRow, withStyles } from '@material-ui/core';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import { getTriggerInfo } from '../../Api/getTriggerInfo';
import useTypes from '../../../../../../Entity/Type/Api/useTypes';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { attentionColor, lightTextColor, recordHoverBackgroundColor, textSecondaryColor } from '../../../../../../../../@Resource/Theme/Theme';
import AutomationActivationSwitch from '../../AutomationActivationToggle/AutomationActivationSwitch';
import { openEntity } from '../../../../../../Entity/@Util/openEntity';
import uuid from 'uuid/v4';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import Tooltip from '../../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';

const StyledTableRow = withStyles(
    () =>
        ({
            root: {
                "&:hover": {
                    backgroundColor: recordHoverBackgroundColor,
                    cursor: "pointer",
                }
            }
        })
)(TableRow);

const useStyles = makeStyles({
    trigger: {
        fontSize: 'small',
        color: lightTextColor
    },
});

export interface AutomationsTableRowProps
{
    automation: Entity;
    showEntityType?: boolean;
}

export const AutomationsTableRow: React.FC<AutomationsTableRowProps> =
    observer(
        ({
             automation,
             showEntityType,
         }) =>
        {
            const types = useTypes();
            const classes = useStyles();
            const [ refreshActivation, setRefreshActivation ] = useState(undefined);
            const [ triggerInfo ] =
                useAsyncResult(
                    () =>
                        getTriggerInfo(automation),
                    [
                        automation
                    ]
                );
           const parentEntityName =
                useMemo(
                   () =>
                       showEntityType
                           ?
                               [
                                   types.EntityType.RelationshipDefinition.Automations,
                                   types.Mailbox.RelationshipDefinition.Automations,
                               ]
                               .map(
                                   relationship =>
                                       automation.getRelatedEntityByDefinition(true, relationship)
                               )
                               .find(
                                   parent =>
                                       parent !== undefined
                               )
                               ?.getName()
                           : undefined,
                   [
                       types,
                       automation,
                       showEntityType
                   ]
               );
           const activation =
               useMemo(
                   () =>
                       {
                           return automation.getRelatedEntityByDefinition(
                               false,
                               types.Automation.RelationshipDefinition.Activations
                           )
                       },
                   // eslint-disable-next-line react-hooks/exhaustive-deps
                   [
                       types,
                       automation,
                       refreshActivation // force refresh on change
                   ]
               );

           const click =
                useCallback(
                    () =>
                        openEntity(automation),
                    [
                        automation
                    ]
                );

            const isDeactivatedValue =
                useEntityValue(
                    activation,
                    types.AutomationActivation.Field.IsDeactivated
                );

           const deactivationReason =
               useEntityValue(
                   activation,
                   types.AutomationActivation.Field.DeactivationReason
               )

           return <StyledTableRow>
               <TableCell
                   onClick={click}
                   align="center"
               >
                   <Icon
                       fontSize="small"
                       style={{
                           color: textSecondaryColor,
                       }}
                   >
                       {triggerInfo?.icon ?? 'question_mark'}
                   </Icon>
               </TableCell>
               {
                   showEntityType &&
                   <TableCell
                       onClick={click}
                   >
                       {parentEntityName}
                   </TableCell>
               }
               <TableCell
                   onClick={click}
               >
                   <ViewGroup
                       orientation="vertical"
                       spacing={0}
                   >
                       <ViewGroupItem>
                           {automation.getName()}
                       </ViewGroupItem>
                       <ViewGroupItem
                           className={classes.trigger}
                       >
                           {triggerInfo?.name}
                       </ViewGroupItem>
                       <ViewGroupItem
                           className={classes.trigger}
                       >
                           {automation.getObjectValueByField(types.Automation.Field.Description)}
                       </ViewGroupItem>
                   </ViewGroup>
               </TableCell>
               <TableCell>
                   {
                       activation && isDeactivatedValue &&
                       <Tooltip
                           title={
                               <LocalizedText
                                   code="Automation.IsDisabledWithReason"
                                   value="Deze automation is gedeactiveerd: ${reason}"
                                   reason={deactivationReason}
                               />
                           }
                       >
                           <Icon
                               style={{
                                   color: attentionColor,
                               }}
                           >
                               {'error_outline'}
                           </Icon>
                       </Tooltip>
                   }
               </TableCell>
               <TableCell>
                   <AutomationActivationSwitch
                       automation={automation}
                       activation={activation}
                       onActivationChanged={() => setRefreshActivation(uuid())}
                   />
               </TableCell>
           </StyledTableRow>
        }
    );
