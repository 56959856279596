import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../Path/@Model/EntityPath';
import TypeMapping, { default as TypeMappingModel } from '../Model/TypeMapping';
import uuid from '../../../../../@Util/Id/uuid';
import RelationshipFieldMapping from '../Model/FieldMapping/Relationship/RelationshipFieldMapping';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import ValueFieldMapping from '../Model/FieldMapping/Constant/ValueFieldMapping';
import { DataObject } from '../../../DataObject/Model/DataObject';
import EntityFieldMapping from '../Model/FieldMapping/Constant/EntityFieldMapping';
import { CurrentUserStore } from '../../../User/CurrentUserStore';

export default function buildMappingForType(entityType: EntityType): TypeMapping
{
    const types = loadModuleDirectly(EntityTypeStore).bespoke.types;

    const rootPath = EntityPath.fromEntityType(entityType);
    const typeMapping =
        new TypeMappingModel(
            uuid(),
            entityType);

    if (!entityType.isInstantiableByInheritance())
    {
        typeMapping.fieldMappings
            .push(
                new ValueFieldMapping(
                    uuid(),
                    rootPath.field(types.Entity.Field.Type),
                    DataObject.constructFromTypeIdAndValue(
                        'EntityType',
                        entityType.getAllInstantiableTypes().find(() => true))));
    }

    if (entityType.isA(types.Relationship.Type))
    {
        typeMapping.fieldMappings
            .push(
                new EntityFieldMapping(
                    uuid(),
                    rootPath
                        .joinTo(
                            types.Relationship.RelationshipDefinition.AccountManager,
                            false)
                        .field(),
                    loadModuleDirectly(CurrentUserStore).employeeEntity.uuid));

        if (entityType.isA(types.Relationship.Organization.Type))
        {
            typeMapping.fieldMappings
                .push(
                    new RelationshipFieldMapping(
                        uuid(),
                        rootPath
                            .joinTo(
                                types.Relationship.Organization.RelationshipDefinition.Organization,
                                false)
                            .field(),
                        buildMappingForType(types.Relation.Organization.Type)));
        }
        else if (entityType.isA(types.Relationship.Person.Type))
        {
            typeMapping.fieldMappings
                .push(
                    new RelationshipFieldMapping(
                        uuid(),
                        rootPath
                            .joinTo(
                                types.Relationship.Person.RelationshipDefinition.Person,
                                false)
                            .field(),
                        buildMappingForType(types.Relation.Person.Type)));
        }
    }
    else if (entityType.isA(types.Relation.Type))
    {
        if (entityType.isA(types.Relation.Organization.Type))
        {
            [
                types.Relation.Organization.RelationshipDefinition.VisitingAddress,
                types.Relation.Organization.RelationshipDefinition.PostalAddress,
                types.Relation.Organization.RelationshipDefinition.InvoiceAddress
            ].forEach(
                addressRelationshipDefinition =>
                    typeMapping.fieldMappings.push(
                        new RelationshipFieldMapping(
                            uuid(),
                            EntityPath.fromEntityType(types.Relation.Organization.Type)
                                .joinTo(
                                    addressRelationshipDefinition,
                                    false)
                                .field(),
                            new TypeMappingModel(
                                uuid(),
                                types.Address.Type))));
        }
        else if (entityType.isA(types.Relation.Person.Type))
        {
            [
                types.Relation.Person.RelationshipDefinition.Address
            ].forEach(
                addressRelationshipDefinition =>
                    typeMapping.fieldMappings.push(
                        new RelationshipFieldMapping(
                            uuid(),
                            EntityPath.fromEntityType(types.Relation.Person.Type)
                                .joinTo(
                                    addressRelationshipDefinition,
                                    false)
                                .field(),
                            new TypeMappingModel(
                                uuid(),
                                types.Address.Type))));
        }
    }

    return typeMapping;
}
