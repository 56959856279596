import Role, { Permission, Privilege, RoleType } from '../../Model/Role';
import { setEntityTypePermission } from './EntityType/setEntityTypePermission';
import { setFieldPermission } from './Field/setFieldPermission';
import { setRelationshipDefinitionPermission } from './RelationshipDefinition/setRelationshipDefinitionPermission';
import { EntityMetadataPermission } from '../../Model/EntityMetadataPermission';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';

export function setPermission(
    rolesByType: Map<RoleType, Role>,
    role: Role,
    privilege: Privilege,
    permission: Permission,
    metadataPermission: EntityMetadataPermission,
    condition?: Predicate,
    parameterDictionary?: ParameterDictionary
)
{
    switch (metadataPermission.type)
    {
        case 'EntityType':
            setEntityTypePermission(
                role,
                privilege,
                permission,
                metadataPermission.entityType,
                condition,
                parameterDictionary
            );
            break;
        case 'EntityField':
            setFieldPermission(
                rolesByType,
                role,
                privilege,
                permission,
                metadataPermission.field,
                condition,
                parameterDictionary
            );
            break;
        case 'RelationshipDefinition':
            setRelationshipDefinitionPermission(
                rolesByType,
                role,
                privilege,
                permission,
                metadataPermission.relationship,
                condition,
                parameterDictionary
            );
            break;
        default:
            throw new Error('Unsupported entity metadata permission type');
    }
}