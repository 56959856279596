import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import styles from './TranslationEditor.module.scss';
import { classNames } from '../../../../../Util/Class/classNames';
import useTypes from '../../../../../../@Component/Domain/Entity/Type/Api/useTypes';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../../ViewGroup/ViewGroupItem';
import EntityTypeContext from '../../../../../../@Component/Domain/Entity/Type/EntityTypeContext';
import TextEditor from '../../TextEditor/TextEditor';
import { InputProps as StandardInputProps } from '@material-ui/core';
import IconButton from '../../../Button/Variant/Icon/IconButton';
import { primaryColor, textSecondaryColor } from '../../../../../../@Resource/Theme/Theme';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';
import { useFetchedApiFile } from '../../../../../../@Service/ApiClient/Hooks/useFetchedApiFile';

export interface TranslationEditorProps
{
    language: Entity;
    languageCode: string;
    value?: string;
    onChange: (languageCode: string, value?: string) => void;
    onFlagClick?: () => void;
    placeholder?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    inputProps?: StandardInputProps['inputProps'];
    disableUnderline?: boolean;
    outlined?: boolean;
    multiline?: boolean;
    error?: boolean;
    fitContent?: boolean;
    inputStyle?: any;
    onExpand?: (isExpanded: boolean) => boolean;
    expanded?: boolean;
}

const TranslationEditor: React.FC<TranslationEditorProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);

        const { onChange, languageCode } = props;
        const onChangeCallback =
            useCallback(
                (value?: string) =>
                    onChange(languageCode, value),
                [
                    onChange,
                    languageCode
                ]);
        const flagValue =
            useComputed(
                () =>
                    props.language.getValueByField(types.Datastore.Language.Field.Flag),
                [
                    props.language,
                    types
                ]);
        const flagUrl =
            useComputed(
                () =>
                {
                    if (flagValue && !flagValue.isEmpty)
                    {
                        return entityTypeStore.getFileUrl(flagValue);
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    flagValue,
                    entityTypeStore
                ]);
        const [ initializedFlagUrl ] = useFetchedApiFile(flagUrl);
        const { onExpand, expanded } = props;
        const toggleExpansion =
            useCallback(
                () =>
                    onExpand(!expanded),
                [
                    onExpand,
                    expanded
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={10}
            alignment="center"
        >
            <ViewGroupItem>
                {
                    initializedFlagUrl
                        ?
                            <img
                                alt={props.languageCode}
                                src={initializedFlagUrl}
                                className={classNames(styles.flag, props.onFlagClick && styles.clickable)}
                                onClick={props.onFlagClick}
                            />
                        :
                            props.languageCode
                }
            </ViewGroupItem>
            <ViewGroupItem
                ratio={1}
            >
                <TextEditor
                    value={props.value}
                    onChange={onChangeCallback}
                    placeholder={props.placeholder}
                    autoFocus={props.autoFocus}
                    disabled={props.disabled}
                    inputProps={props.inputProps}
                    disableUnderline={props.disableUnderline}
                    outlined={props.outlined}
                    multiline={props.multiline}
                    error={props.error}
                    fitContent={props.fitContent}
                    inputStyle={props.inputStyle}
                />
            </ViewGroupItem>
            {
                props.onExpand &&
                    <ViewGroupItem>
                        <IconButton
                            onClick={toggleExpansion}
                            icon="public"
                            tooltip={
                                props.expanded
                                    ?
                                        <LocalizedText
                                            code="TranslationEditor.HideAllLanguages"
                                            value="Alle talen verbergen"
                                        />
                                    :
                                        <LocalizedText
                                            code="TranslationEditor.ShowAllLanguages"
                                            value="Alle talen tonen"
                                        />
                            }
                            color={props.expanded ? primaryColor : textSecondaryColor}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(TranslationEditor);
