import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../Path/@Model/EntityPath';

export class BespokeEmailClick extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'EmailClick')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getListDependencies(): EntityPath[]
    {
        const rootPath = EntityPath.fromEntityType(this.type);

        return [
            ...super.getListDependencies(),
            rootPath
                .joinTo(
                    this.types.Activity.Email.RelationshipDefinition.Clicks,
                    true)
                .joinTo(
                    this.types.Relationship.RelationshipDefinition.Activities,
                    true)
        ];
    }

    getEntityToOpen(entity: Entity): Entity
    {
        const activity =
            entity.getRelatedEntityByDefinition(
                true,
                this.types.Activity.Email.RelationshipDefinition.Clicks);

        return activity || entity;
    }

    // ----------------------- Private logic ------------------------
}
