import performAction from '../../../Entity/performAction';
import PortalDataSourceSignature from '../PortalDataSourceSignature';
import { createAsyncCache } from '../../../../@Util/Cache/AsyncCache';

const cache = createAsyncCache<string, PortalDataSourceSignature>(fetchById);

export default async function getPortalDataSourceSignatureById(dataSourceId: string,
                                                               fetchFromCacheIfAvailable: boolean = true): Promise<PortalDataSourceSignature>
{
    if (fetchFromCacheIfAvailable)
    {
        return cache.getOrLoad(dataSourceId);
    }
    else
    {
        return fetchById(dataSourceId);
    }
}

async function fetchById(dataSourceId: string): Promise<PortalDataSourceSignature>
{
    const result =
        await performAction<any[]>(
            undefined,
            {
                code: 'Portal.GetPortalDataSourceSignature',
                parameters: {
                    dataSourceId: dataSourceId
                }
            });
    const signature = await PortalDataSourceSignature.fromDescriptor(result.result);
    cache.insert(dataSourceId, signature);

    return signature;
}
