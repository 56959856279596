import Role, { Permission, Privilege, RoleType } from '../../../Model/Role';
import { EntityRelationshipDefinition } from '../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';

function getPermission(
    role: Role,
    privilege: Privilege,
    relationshipDefinition: EntityRelationshipDefinition
): Permission
{
    return role.rightByRelationshipType.get(relationshipDefinition)?.getPermission(privilege)
        ?? 'Inherited';
}

export function getRelationshipDefinitionSpecifiedPermission(
    rolesByType: Map<RoleType, Role>,
    role: Role,
    privilege: Privilege,
    relationshipDefinition: EntityRelationshipDefinition
): Permission
{
    const permission =
        getPermission(
            role,
            privilege,
            relationshipDefinition
        );

    if (permission === 'Inherited')
    {
        const defaultPermission =
            getPermission(
                rolesByType.get('UserDefaultRole'),
                privilege,
                relationshipDefinition
            );
        if (defaultPermission === 'Inherited')
        {
            return getPermission(
                rolesByType.get('UserBaseRole'),
                privilege,
                relationshipDefinition
            );
        }
        else
        {
            return defaultPermission;
        }
    }
    else
    {
        return permission;
    }
}