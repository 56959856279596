import { EntityConstraintBuilderFilterContext } from './Model/EntityConstraintBuilderFilterContext';
import { ConstraintNode } from '../../../../../../@Api/Model/Implementation/ConstraintNode';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import QueryComputation from '../../../../../../@Api/Automation/Function/Computation/QueryComputation';
import AggregateQuery from '../../../../../../@Api/Automation/Query/AggregateQuery';
import { buildAggregateSelectionComparisonConstraintNode } from './buildAggregateSelectionComparisonConstraintNode';
import { buildDefaultComparisonConstraintNode } from './buildDefaultComparisonConstraintNode';

export function buildComparisonConstraintNode(
    filter: ComparisonPredicate,
    context: EntityConstraintBuilderFilterContext
): ConstraintNode
{
    const lhs = filter.lhs;
    const rhs = filter.rhs;

    if (lhs instanceof QueryComputation
        && lhs.query instanceof AggregateQuery
        && lhs.isValid()
        && rhs)
    {
        return buildAggregateSelectionComparisonConstraintNode(
            filter,
            context
        );
    }
    else
    {
        return buildDefaultComparisonConstraintNode(
            filter,
            context
        );
    }
}
