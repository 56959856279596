import Predicate from '../Automation/Function/Computation/Predicate/Predicate';
import { EntityFieldPath } from '../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import ComparisonPredicate from '../Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../Automation/Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../@Component/Domain/DataObject/Model/Comparator';
import ParameterAssignment from '../Automation/Parameter/ParameterAssignment';
import Parameter from '../Automation/Parameter/Parameter';
import ParameterDictionary from '../Automation/Parameter/ParameterDictionary';
import FunctionContext from '../Automation/Function/FunctionContext';

export default function isFilteredOnFieldPath(
    fieldPath: EntityFieldPath,
    filter: Predicate,
    parameterDictionary: ParameterDictionary,
    parameterAssignment: ParameterAssignment
)
{
    return filter.getTrueStatements(
        new FunctionContext(
            parameterDictionary,
            parameterAssignment
        )
    )
        .some(
            predicate =>
                predicate instanceof ComparisonPredicate
                    && predicate.lhs instanceof ValueFromEntityComputation
                    && fieldPath.equals(predicate.lhs.fieldPath)
                    && predicate.comparator === Comparator.Equals
                    && predicate.rhs instanceof Parameter
                    && parameterAssignment.getObjectValue(predicate.rhs) !== undefined);
}
