import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../DataObject/DataObjectStore';

export default function getResultValue(result: EntitySelectionAggregateResult)
{
    const dataObjectStore = loadModuleDirectly(DataObjectStore);

    if (result.aggregates.length > 0)
    {
        return result.aggregates[result.aggregates.length - 1];
    }
    else
    {
        return DataObject.constructFromTypeIdAndValue(
            'Number',
            0,
            dataObjectStore);
    }
}
