import Addressee from '../Model/Addressee';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import constructEntity from '../../../../../../../../@Api/Entity/constructEntity';
import { useCallback } from 'react';
import augmentRelationshipWithAddressee from './augmentRelationshipWithAddressee';
import useTypes from '../../../../../Type/Api/useTypes';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export default function useRelationshipAddresseeAugmenter(
    addressee: Addressee,
    parentCommitContext: CommitContext,
    activity?: Entity
)
{
    const types =  useTypes();

    return useCallback(
        (relationship: Entity, commitContext?: CommitContext) =>
        {
            augmentRelationshipWithAddressee(
                relationship,
                addressee,
                commitContext
            );

            constructEntity(
                relationship.entityType,
                undefined,
                relationship,
                activity
                    ?
                    () =>
                        updateRelationship(
                            activity,
                            true,
                            types.Activity.RelationshipDefinition.Relationship,
                            relationship,
                            parentCommitContext
                        )
                    :
                    undefined,
                false,
                undefined,
                undefined,
                undefined,
                commitContext
            );
        },
        [
            activity,
            types,
            parentCommitContext,
        ]
    );
}
