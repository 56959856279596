import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { DataObject } from '../Model/DataObject';
import { InputProps as StandardInputProps } from '@material-ui/core/Input';
import { DataObjectEditorStore } from './Value/Editor/DataObjectEditorStore';
import { DataObjectEditor } from './Value/Editor/DataObjectEditor';
import { DataObjectRepresentation, DataObjectRepresentationProps } from '../Model/DataObjectRepresentation';
import { InputEventTrigger } from '../../InputEvent/InputEventTrigger';
import { FormHandlerContext } from '../../../Generic/Form/FormHandlerContext';
import { FormEventListener } from '../../../Generic/Form/FormEventListener';

export interface BaseEditorProps
{
    dataObject: DataObject;
    inputProps?: StandardInputProps['inputProps'];
    placeholder?: string;
    disabled?: boolean;
    onChange?: (value: DataObject) => void;
    underline?: boolean;
    representation?: DataObjectRepresentationProps;
    autoFocus?: boolean;
    eventTriggers?: InputEventTrigger[];
}

const Editor: React.FC<BaseEditorProps> =
    props =>
    {
        const editorStore =
            useMemo(
                () =>
                    !props.dataObject.specification.type.hooksEditor &&
                        new DataObjectEditorStore({
                            dataObject: props.dataObject,
                            placeholder: props.placeholder,
                            isDisabled: props.disabled,
                            onChange: props.onChange,
                            hasUnderline: props.underline,
                            representation: props.representation && new DataObjectRepresentation(props.representation),
                            isFocused: props.autoFocus,
                            handlerContext:
                                props.eventTriggers &&
                                    new FormHandlerContext(
                                        props.eventTriggers.map(
                                            eventTrigger =>
                                                new FormEventListener(
                                                    eventTrigger.eventType,
                                                    () =>
                                                        eventTrigger.trigger(props.dataObject))))
                        }),
                [
                    props.dataObject,
                    props.placeholder,
                    props.disabled,
                    props.onChange,
                    props.underline,
                    props.representation,
                    props.autoFocus,
                    props.eventTriggers
                ]);

        if (props.dataObject.specification.type.hooksEditor)
        {
            return React.createElement(
                props.dataObject.specification.type.hooksEditor(),
                props
            );
        }
        else
        {
            return <DataObjectEditor
                store={editorStore}
            />;
        }
    };

export default observer(Editor);
