import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import { findAssistantComputation } from './findAssistantComputation';
import { AssistantGenAiAudioTranscriptionComputation } from '../../Model/Automation/Computation/AssistantGenAiAudioTranscriptionComputation';
import AiAudioTranscriptionComputation from '../../../../../@Api/Automation/Function/Computation/AiAudioTranscriptionComputation';

export function findAssistantGenAiAudioTranscriptionComputation(
    computation: AssistantGenAiAudioTranscriptionComputation,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Computation<any, any>
{
    const audio =
        findAssistantComputation(
            computation.audio,
            parameters,
            rootParameter
        );

    return new AiAudioTranscriptionComputation(audio);
}
