import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { default as LabelInput } from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ExpansionPanel from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { CampaignProps } from '../Campaign';
import PrimaryButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import performAction from '../../../../../../../../@Api/Entity/performAction';
import useCampaignRootType from '../Api/useCampaignRootType';
import { EntitySelectionBuilder } from '../../../../../Selection/Builder/EntitySelectionBuilder';
import Selectbox from '../../../../../Selectbox/Selectbox';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import ButtonGroup from '../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/ButtonGroup';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import RichText from '../../../../../../../Generic/RichText/RichText';
import { Card } from '@material-ui/core';

export interface CampaignTestProps extends CampaignProps
{

}

const CampaignTest: React.FC<CampaignTestProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const rootType = useCampaignRootType(props.entity);
        const { entity } = props;

        const testSelections =
            useMemo(
                () => [
                    new EntitySelectionBuilder(
                        rootType
                    )
                    .build()
                ],
                [
                    rootType
                ]
            );

        const [ testSubject, setTestSubject ] = useState<Entity>();

        const sendTestEmail =
            useCallback(
                () =>
                    performAction(
                        entity,
                        {
                            code: 'Activity.Campaign.SendTestEmail',
                            name: localizeText('Activity.Campaign.TestAction', 'Campagne testen'),
                            parameters: {
                                subjectId: testSubject.uuid
                            }
                        }),
                [
                    entity,
                    testSubject,
                ]);

        const [ showPreview, setShowPreview ] = useState<boolean>();
        const toggle =
            useCallback(
                () =>
                    setShowPreview(!showPreview),
                [
                    setShowPreview,
                    showPreview
                ]);

        const [previewEmail] =
            useAsyncResult(
                async () =>
                {
                    if (showPreview)
                    {
                        return await performAction<string>(entity, {
                            code: 'Activity.Campaign.PreviewTestEmail',
                            name: localizeText('Activity.Campaign.PreviewAction', 'Campagne voorbeeld tonen'),
                            parameters: {
                                subjectId: testSubject.uuid
                            }
                        }).then(
                            result =>
                            {
                                return result.result
                            }
                        )
                    }
                    else {
                        return ''
                    }
                },
                [
                    showPreview,
                    entity,
                    testSubject,
                ]);

        const debugSelectionInCampaign =
            useCallback(
                () =>
                    performAction(
                        entity,
                        {
                            code: 'Activity.Campaign.DebugSelectionInCampaign',
                            name : 'Campagne debuggen'
                        }),
                [
                    entity
                ]);

        const testSelectionLabel =
            useMemo(
                () =>
                    rootType.getName(),
                [
                    rootType
                ]
            );

        const formatSelection =
            (testSubject: Entity) =>
                {
                   if (testSubject.getName())
                   {
                       return testSubject.getName();
                   }
                   else
                   {
                       return testSubject.entityType.getName();
                   }
                }

        return <ExpansionPanel
            id="Test"
            summary={
                <Header
                    large
                    inset
                    title={
                        <LocalizedText
                            code="Generic.Testing"
                            value="Testen"
                        />
                    }
                />
            }
            expansion={
                <CardInset>
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        <ViewGroupItem>
                            <LabelInput
                                label={testSelectionLabel}
                                labelPosition="left"
                            >
                                <Selectbox
                                    selections={testSelections}
                                    onChange={setTestSubject as any}
                                    value={testSubject}
                                    formatOptionValue={formatSelection}
                                />
                            </LabelInput>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ButtonGroup>
                                <PrimaryButton
                                    label={
                                        <LocalizedText
                                            code="Generic.Testing"
                                            value="Testen"
                                        />
                                    }
                                    onClick={sendTestEmail}
                                    disabled={testSubject === undefined}
                                />
                                <PrimaryButton
                                    label={
                                        <LocalizedText
                                            code="Generic.Example"
                                            value="Voorbeeld"
                                        />
                                    }
                                    onClick={toggle}
                                    disabled={testSubject === undefined}
                                />
                                {
                                    currentUserStore.isDeveloper &&
                                        <PrimaryButton
                                            label={
                                                <LocalizedText
                                                    code="Generic.Debug"
                                                    value="Debuggen"
                                                />
                                            }
                                            onClick={debugSelectionInCampaign}
                                        />
                                }
                            </ButtonGroup>
                        </ViewGroupItem>
                        {
                            showPreview ?
                                <ViewGroupItem>
                                    <Card>
                                        <RichText
                                            value={previewEmail}
                                            standalone
                                        >
                                        </RichText>
                                    </Card>
                                </ViewGroupItem>
                            : null
                        }
                    </ViewGroup>
                </CardInset>
            }
        />;
    };

export default observer(CampaignTest);
