import { BaseStore, getOrCompute, PropType } from '../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';

export interface Tab
{
    label: string;
    onSelect?: (tab: Tab) => void;
    disabled?: boolean;
    selected?: boolean;
    id?: string;
    context?: any;
    isVisible?: () => boolean;
}

export interface TabBarProps
{
    tabs?: PropType<TabBarStore, TabBarProps, Tab[]>;
    showBorder?: PropType<TabBarStore, TabBarProps, boolean>;
    fullWidth?: boolean;
    onSelect?: (tab: Tab) => void;
}

const defaultProps: TabBarProps =
{
    fullWidth: true,
    showBorder: true
};

export class TabBarStore extends BaseStore<TabBarProps>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable tabIndex: number = undefined;

    // ------------------------ Constructor -------------------------

    constructor(props: TabBarProps)
    {
        super(props, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get tabs(): Tab[]
    {
        return getOrCompute(this, this.props.tabs);
    }

    @computed
    get isFullWidth(): boolean
    {
        return getOrCompute(this, this.props.fullWidth);
    }

    @computed
    get showBorder(): boolean
    {
        return getOrCompute(this, this.props.showBorder);
    }

    @computed
    get selectedTabIndex(): number
    {
        if (this.tabIndex !== undefined)
        {
            return this.tabIndex;
        }

        if (this.tabs)
        {
            for (let i = 0; i < this.tabs.length; ++i)
            {
                let tab = this.tabs[i];

                if (tab.selected)
                {
                    return i;
                }
            }
        }

        return 0;
    }

    @computed
    get hasTabs(): boolean
    {
        return this.tabs && this.tabs.length > 0;
    }

    @computed
    get selectedTab(): Tab
    {
        return this.tabs[this.selectedTabIndex];
        // return this.tabIndex !== undefined
        //     ?
        //         this.tabs[this.tabIndex]
        //     :
        //         undefined;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    tabSelected(event: any, tab: number)
    {
        this.tabIndex = tab;

        if (this.tabs[this.tabIndex].onSelect)
        {
            this.tabs[this.tabIndex].onSelect(this.tabs[this.tabIndex]);
        }

        if (this.props.onSelect)
        {
            this.props.onSelect(this.tabs[this.tabIndex]);
        }
    }

    // ------------------------ Public logic ------------------------

    public isSelected(id: string)
    {
        return this.selectedTab && this.selectedTab.id === id;
    }

    // ----------------------- Private logic ------------------------
}
