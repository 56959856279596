import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { makeStyles } from '@material-ui/core';
import { EntityValueUpdateMutation } from '../../../../../../../@Api/Model/Implementation/EntityValueUpdateMutation';
import AutomationDependencyContext from '../../../../../../../@Api/Automation/AutomationDependencyContext';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { default as AutomationModel } from '../../../../../../../@Api/Automation/Automation';
import AutomationEditor from '../Editor/AutomationEditor';
import { useParameterDictionary } from '../Api/useParameterDictionary';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import { DataDescriptor } from '../../../../../DataObject/Model/DataDescriptor';
import useTypes from '../../../../Type/Api/useTypes';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import { AutomationDiffViewerHeader } from './AutomationDiffViewerHeader';


interface AutomationDiffViewerProps
{
    event: EntityValueUpdateMutation;
    onClose: () => void;
}

const useStyles = makeStyles({
    group:
        {
            maxHeight: '85vh'
        },
    editors:
        {
            overflowY: 'scroll !important' as any,
            maxHeight: '100%'
        }
})

export const AutomationDiffViewer: React.FC<AutomationDiffViewerProps> = observer(
    ({
        event,
        onClose
     }) =>
    {
        const classes = useStyles();
        const types = useTypes();
        const [ loadingModels,  setLoadingModels ] = useState<boolean>(false);
        const [ oldModel,  setOldModel ] = useState<AutomationModel>(undefined);
        const [ newModel,  setNewModel ] = useState<AutomationModel>(undefined);

        const parameterDictionary = useParameterDictionary(event.entity);

        useEffect(
            () =>
            {
                setLoadingModels(true);
                const dataObjectSpecification = types.Automation.Field.Specification.dataObjectSpecification;

                const valueBefore =
                    event.valueBeforeMutation
                        ? dataObjectSpecification.type
                            .getUninitializedValueFromData(
                                DataDescriptor.construct(
                                    event.valueBeforeMutation,
                                    dataObjectSpecification
                                ),
                                dataObjectSpecification
                            )
                        : undefined;

                const valueAfter =
                    event.valueAfterMutation
                        ? dataObjectSpecification.type
                            .getUninitializedValueFromData(
                                DataDescriptor.construct(
                                    event.valueAfterMutation,
                                    dataObjectSpecification
                                ),
                                dataObjectSpecification
                            )
                        : undefined;

                Promise.all(
                    [
                        valueBefore
                            ? AutomationModel.fromDescriptor(
                                valueBefore,
                                    new AutomationDependencyContext(
                                        new ParameterDictionary(
                                            Array.from(parameterDictionary.parameters)
                                        )
                                    )
                                )
                                .then(setOldModel)
                            : Promise.resolve(),

                        valueAfter
                            ? AutomationModel.fromDescriptor(
                                    valueAfter,
                                    new AutomationDependencyContext(
                                        new ParameterDictionary(
                                            Array.from(parameterDictionary.parameters)
                                        )
                                    )
                                )
                                .then(setNewModel)
                            : Promise.resolve()
                    ]
                )
                .finally(
                    () =>
                        setLoadingModels(false)
                );
            },
            [
                types,
                event,
                parameterDictionary
            ]
        );

        return <CardInset>
            <ViewGroup
                orientation="vertical"
                spacing={15}
                className={classes.group}
            >
                <ViewGroupItem>
                    <AutomationDiffViewerHeader
                        event={event}
                    />
                </ViewGroupItem>
                {
                    loadingModels &&
                    <ViewGroupItem>
                        <Centered
                            horizontal
                        >
                            <Loader />
                        </Centered>
                    </ViewGroupItem>
                }
                {
                    !loadingModels &&
                    <ViewGroupItem
                        className={classes.editors}
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                {
                                    oldModel &&
                                    <AutomationEditor
                                        entityType={event.entity.entityType}
                                        automation={oldModel}
                                        parameterDictionary={parameterDictionary}
                                        entity={event.entity}
                                        readOnly
                                        onCreate={() => {}}
                                    />
                                }
                            </ViewGroupItem>
                            <ViewGroupItem
                                ratio={1}
                            >
                                <AutomationEditor
                                    entityType={event.entity.entityType}
                                    automation={newModel}
                                    parameterDictionary={parameterDictionary}
                                    entity={event.entity}
                                    readOnly
                                    onCreate={() => {}}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                }
                <ViewGroupItem>
                    <CardInset>
                        <RightAlignedButtonGroup>
                            <PrimaryButton
                                label={
                                    <LocalizedText
                                        code="Generic.Close"
                                        value="Sluiten"
                                    />
                                }
                                onClick={onClose}
                            />
                        </RightAlignedButtonGroup>
                    </CardInset>
                </ViewGroupItem>
            </ViewGroup>
        </CardInset>
    }
);