import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityPath } from '../Path/@Model/EntityPath';
import useEventFilters from './Api/useEventFilters';
import TimelineData from './TimelineData';
import useTypes from '../Type/Api/useTypes';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TabBar from '../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import StaticSelectbox from '../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import localizeText from '../../../../@Api/Localization/localizeText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { primaryColor } from '../../../../@Resource/Theme/Theme';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import { addMonths, endOfDay, startOfDay } from 'date-fns';
import DateEditor from '../../../../@Future/Component/Generic/Input/DateEditor/DateEditor';


const useStyles = makeStyles({
    eventsExtraFilter: {
        color: primaryColor,
        fontWeight: 400
    },
    dateFilter: {
        width: 85,
    }
});

export interface TimelineProps
{
    entity: Entity;
}

const Timeline: React.FC<TimelineProps> =
    ({
        entity
     }) =>
    {
        const classes = useStyles();
        const types = useTypes();
        const auditTrailEntities =
            useMemo(
                () =>
                    entity.entityType.bespoke.getAuditTrailEntities(entity, types),
                [
                    entity,
                    types,
                ]);
        const [ tab, setTab ] = useState(0);
        const auditTrailEntity = auditTrailEntities[tab];
        const rootPath =
            useMemo(
                () =>
                   EntityPath.fromEntityType(auditTrailEntity.entity.entityType),
                [
                    auditTrailEntity
                ]
            );
        const entityEventFilters = useEventFilters(rootPath);
        const entityEventsExtraFilterOptions =
            useMemo(
                () => [
                    {
                        id: 'All',
                        label: localizeText('AuditTrail.All', ' - '),
                        value: 'All'
                    },
                    {
                        id: 'Users',
                        label: localizeText('AuditTrail.FilterUsers', 'users'),
                        value: 'Users'
                    },
                    {
                        id: 'Automations',
                        label: localizeText('AuditTrail.Automations', 'automatic processes'),
                        value: 'Automations'
                    }
                ],
                []
            );
        const [ selectedEntityEventExtraFilter, setSelectedEntityEventExtraFilter ] = useState<string>('All');

        const today =
            useMemo(
                () =>
                    new Date(),
                []
            );
        const [ startDate, setStartDate ] = useState(addMonths(today, -6));
        const [ endDate, setEndDate ] = useState(endOfDay(today));
        const onStartDate =
            useCallback(
                (date?: Date) =>
                {
                    if (date && date <= endDate)
                    {
                        setStartDate(startOfDay(date));
                    }
                    else if (date && date >= endDate && date <= today)
                    {
                        setStartDate(startOfDay(date));
                        setEndDate(endOfDay(date));
                    }
                    else
                    {
                        setStartDate(startOfDay(today));
                        setEndDate(endOfDay(today));
                    }
                },
                [
                    today,
                    startDate,
                    setStartDate,
                    setEndDate
                ]
            );
        const onEndDate =
            useCallback(
                (date?: Date) =>
                {
                    if (date && date >= today)
                    {
                        setEndDate(endOfDay(today));
                    }
                    else if (date && date >= startDate)
                    {
                        setEndDate(endOfDay(date));
                    }
                    else if (date)
                    {
                        setStartDate(startOfDay(date));
                        setEndDate(endOfDay(date));
                    }
                    else
                    {
                        setStartDate(startOfDay(today));
                        setEndDate(endOfDay(today));
                    }
                },
                [
                    today,
                    startDate,
                    setStartDate,
                    setEndDate
                ]
            );

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    alignment="end"
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                        >
                            <ViewGroupItem>
                                <LocalizedText
                                    code="AuditTrail.Source"
                                    value="Source"
                                />
                            </ViewGroupItem>
                            <ViewGroupItem
                                className={classes.eventsExtraFilter}
                            >
                                <StaticSelectbox
                                    options={entityEventsExtraFilterOptions}
                                    value={selectedEntityEventExtraFilter}
                                    onChange={setSelectedEntityEventExtraFilter}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={15}
                                    alignment="end"
                                >
                                    <ViewGroupItem>
                                        <LocalizedText
                                            code="Generic.Period"
                                            value="Periode"
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem
                                        className={classes.dateFilter}
                                    >
                                        <DateEditor
                                            value={startDate}
                                            onChange={onStartDate}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem
                                        alignment="right"
                                        className={classes.dateFilter}
                                    >
                                        <DateEditor
                                           value={endDate}
                                           onChange={onEndDate}
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
            {
                auditTrailEntities.length > 1 &&
                <ViewGroupItem>
                    <CardInset>
                        <TabBar
                            value={tab}
                            bordered={false}
                        >
                            {
                                auditTrailEntities
                                    .map(
                                        (auditTrailEntity, idx) =>
                                            <Tab
                                                value={idx}
                                                onClick={setTab}
                                            >
                                                {auditTrailEntity.title}
                                            </Tab>
                                    )
                            }
                        </TabBar>
                    </CardInset>
                </ViewGroupItem>
            }
            <ViewGroupItem>
                <TimelineData
                    entityType={auditTrailEntity.entity.entityType}
                    entity={auditTrailEntity.entity}
                    filters={entityEventFilters}
                    startDate={startDate}
                    endDate={endDate}
                    onlyIncludeEventsWithoutUser={
                        selectedEntityEventExtraFilter === 'All'
                            ? undefined
                            : selectedEntityEventExtraFilter === 'Automations'
                    }
                    showConstructor
                />
            </ViewGroupItem>
        </ViewGroup>
    };

export default observer(Timeline);
