import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { WidgetProps } from '../Widget';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import { default as InternalResourcePlanner, TimesheetTotalFormatter } from '../../../ResourcePlanner/ResourcePlanner';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import EmployeeTimesheetWidget from '../../Model/Widget/EmployeeTimesheet/EmployeeTimesheetWidget';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';
import useEmployeeBillability from '../../../ResourcePlanner/Type/useEmployeeBillability';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import getWorkdaysInRange from '../../../../../../@Util/Date/getWorkdaysInRange';

export interface EmployeeBillabilityProps extends WidgetProps<EmployeeTimesheetWidget>
{

}

const EmployeeBillability: React.FC<EmployeeBillabilityProps> =
    props =>
    {
        const types = useTypes();
        const resourcePlanner = useEmployeeBillability();

        const timesheetTotalFormatter =
            useCallback<TimesheetTotalFormatter>(
                (timeInMillis, period, employees, intervalType, employee) =>
                {
                    function getWorkableMillisPerWeek(employee: Entity)
                    {
                        const workableHoursPerWeek = employee.getObjectValueByField(types.Relationship.Person.Contact.Employee.Field.WorkableHoursPerWeek);

                        if (workableHoursPerWeek === undefined)
                        {
                            return 0;
                        }
                        else
                        {
                            return workableHoursPerWeek * 3600 * 1000;
                        }
                    }

                    if (timeInMillis === 0 || employees.length === 0)
                    {
                        return undefined;
                    }
                    else
                    {
                        let millisInPeriod = period.to.diff(period.from, 'milliseconds');

                        let totalCapacityPerWeek = 0;
                        if (employee)
                        {
                            totalCapacityPerWeek = getWorkableMillisPerWeek(employee);
                        }
                        else
                        {
                            employees.forEach(
                                employee =>
                                {
                                    totalCapacityPerWeek += getWorkableMillisPerWeek(employee)
                                });
                        }

                        let millisInInterval = 0;
                        let totalCapacityPerInterval = 0;
                        switch (intervalType)
                        {
                            case "month":
                                millisInInterval = 1000 * 3600 * 24;

                                // Change period size, use only working days
                                if (millisInPeriod > millisInInterval)
                                {
                                    let workingDays = getWorkdaysInRange(period.from, period.to);
                                    let totalDays = period.to.diff(period.from, 'days');

                                    millisInPeriod *= (workingDays / totalDays);
                                }

                                // For now we assume that a week has 5 working days
                                totalCapacityPerInterval = millisInPeriod / millisInInterval * (totalCapacityPerWeek / 5);
                                break;
                            case "quarter":
                            default:
                                millisInInterval = 1000 * 3600 * 24 * 7;
                                totalCapacityPerInterval = millisInPeriod / millisInInterval * totalCapacityPerWeek;
                                break;
                        }

                        if (totalCapacityPerInterval === 0)
                        {
                            return undefined;
                        }
                        else
                        {
                            return `${Math.round(timeInMillis / totalCapacityPerInterval * 1000) / 10}%`;
                        }
                    }
                },
                [
                    types
                ]);

        return <Card>
            <CardInset
                horizontal={false}
                bottom={false}
            >
                <InternalResourcePlanner
                    id={props.widget.id}
                    resourcePlanner={resourcePlanner}
                    name={
                        <LocalizedText
                            code="ResourcePlanner.EmployeeBillability"
                            value="Medewerker declarabiliteit"
                        />
                    }
                    appendix={
                        <MenuButton>
                            <Menu>
                                <DeleteItem
                                    onClick={props.onDelete}
                                />
                            </Menu>
                        </MenuButton>
                    }
                    dragHandle
                    timesheet
                    timesheetTotalFormatter={timesheetTotalFormatter}
                />
            </CardInset>
        </Card>;
    };

export default observer(EmployeeBillability);
