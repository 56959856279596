import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import TabBarLayout from '../../../../../../@Api/Layout/Type/TabBar/TabBarLayout';
import StaticTabSource from '../../../../../../@Api/Layout/Type/TabBar/Model/StaticTabSource';
import TabSource from '../../../../../../@Api/Layout/Type/TabBar/Model/TabSource';
import DynamicTabSource from '../../../../../../@Api/Layout/Type/TabBar/Model/DynamicTabSource';
import StaticTabSourceEditor, { StaticTabSourceEditorProps } from './Static/StaticTabSourceEditor';
import DynamicTabSourceEditor, { DynamicTabSourceEditorProps } from './Dynamic/DynamicTabSourceEditor';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';

export interface TabSourceEditorProps<T extends TabSource> extends LayoutEditorProps<TabBarLayout>
{
    source: T;
    context: FunctionContext;
}

const TabSourceEditor: React.FC<TabSourceEditorProps<TabSource>> =
    props =>
    {
        const { source } = props;

        if (source instanceof StaticTabSource)
        {
            return <StaticTabSourceEditor
                {...props as StaticTabSourceEditorProps}
            />;
        }
        else if (source instanceof DynamicTabSource)
        {
            return <DynamicTabSourceEditor
                {...props as DynamicTabSourceEditorProps}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(TabSourceEditor);
