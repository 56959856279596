import AutomationDependencyContext from '../../Automation/AutomationDependencyContext';
import PortalDataSourceQueryResult from './PortalDataSourceQueryResult';

export default class PortalDataSourceQueryResultSet
{
    // ------------------------- Properties -------------------------

    results: PortalDataSourceQueryResult[];

    // ------------------------ Constructor -------------------------

    constructor(results: PortalDataSourceQueryResult[])
    {
        this.results = results;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new PortalDataSourceQueryResultSet(
            await Promise.all(
                descriptor.results.map(
                    result =>
                        PortalDataSourceQueryResult.fromDescriptor(
                            result,
                            dependencyContext))));
    }

    // ----------------------- Private logic ------------------------
}
