import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import Predicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import ComparisonPredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../../../../DataObject/Model/Comparator';
import { ComputationConstructorItem } from '../../../../../Entity/Viewer/Content/Automation/Editor/Computation/ComputationConstructor';
import FunctionContext from '../../../../../../../@Api/Automation/Function/FunctionContext';
import PredicateEditor from '../../../../../Entity/Viewer/Content/Automation/Editor/Predicate/PredicateEditor';
import EntityValueType from '../../../../../../../@Api/Automation/Value/Type/EntityValueType';

export interface FilterEditorProps
{
    filterParameter: Parameter<EntityValueType>;
    parameterDictionary: ParameterDictionary;
    value: Predicate;
    onChange: (value?: Predicate) => void;
    lhsTypeNotFixed?: boolean;
    disabled?: boolean
}

const FilterEditor: React.FC<FilterEditorProps> =
    props =>
    {
        const context =
            useMemo(
                () =>
                    new FunctionContext(props.parameterDictionary),
                [
                    props.parameterDictionary
                ]);

        const comparisonConstructor =
            useCallback(
                () =>
                    new ComparisonPredicate(
                        new ValueFromEntityComputation(
                            props.filterParameter,
                            undefined),
                        Comparator.Equals,
                        undefined),
                [
                    props.filterParameter
                ]);

        const constructorItemFilter =
            useCallback(
                (item: ComputationConstructorItem) =>
                    true,
                []);

        return <PredicateEditor
            comparisonConstructor={comparisonConstructor}
            comparisonLhsTypeFixed={!props.lhsTypeNotFixed}
            onChange={props.onChange}
            context={context}
            value={props.value}
            constructorItemFilter={constructorItemFilter}
            disallowRichText
            disabled={props.disabled}
        />;
    };

export default observer(FilterEditor);
