import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { Entity } from '../../Model/Implementation/Entity';
import { EntityEvent } from '../../Model/Implementation/EntityEvent';

export class EntityController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    deleteEntity(id: number): Promise<EntityEvent[]>
    {
            return new Promise<EntityEvent[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/entity/delete', Method.Post, { id: id }))
                    .then(result =>
                    {
                        resolve((fromJson(result, EntityEvent as any) as EntityEvent[]));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    downloadFile(entityId: number, fieldId: number): Promise<any>
    {
            return new Promise<any>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/entity/' + entityId + '/file/' + fieldId + '', Method.Get, {  }))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };

    getEntitiesByIds(ids: any): Promise<Entity[]>
    {
            return new Promise<Entity[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/entities', Method.Get, { ids: ids }))
                    .then(result =>
                    {
                        resolve((fromJson(result, Entity as any) as Entity[]));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    getEntityById(id: number, doRead: boolean): Promise<Entity>
    {
            return new Promise<Entity>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/entity', Method.Get, { id: id, do_read: doRead }))
                    .then(result =>
                    {
                        resolve(fromJson(result, Entity));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    saveEntity(entity: any): Promise<EntityEvent[]>
    {
            return new Promise<EntityEvent[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/entity/save', Method.Post, { entity: entity }))
                    .then(result =>
                    {
                        resolve((fromJson(result, EntityEvent) as any) as EntityEvent[]);
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
}
