import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { runInAction } from 'mobx';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import ComputationEditor from '../../../../../Entity/Viewer/Content/Automation/Editor/Computation/ComputationEditor';
import Computation from '../../../../../../../@Api/Automation/Function/Computation/Computation';
import EffectLayoutDependency from '../../../../../../../@Api/Layout/Type/EffectLayoutDependency';
import FunctionContext from '../../../../../../../@Api/Automation/Function/FunctionContext';

export interface EffectLayoutDependencyEditorProps
{
    value: EffectLayoutDependency;
    onDelete: (dependency: EffectLayoutDependency) => void;
    context: FunctionContext;
}

const EffectLayoutDependencyEditor: React.FC<EffectLayoutDependencyEditorProps> =
    ({
        value,
        onDelete,
        context,
     }) =>
    {
        const setValue =
            useCallback(
                (internalValue: Computation<any, any>) =>
                    runInAction(
                        () =>
                            value.value = internalValue
                    ),
                [
                    value
                ]);
        const doDelete =
            useCallback(
                () =>
                    onDelete(value),
                [
                    onDelete,
                    value
                ]);

        return <CardInset
            vertical={false}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={15}
            >
                <ViewGroupItem
                    ratio={1}
                >
                    <InputGroup>
                        <Input
                            labelPosition="left"
                            label={
                                <LocalizedText
                                    code="Generic.Value"
                                    value="Waarde"
                                />
                            }
                        >
                            <ComputationEditor
                                context={context}
                                value={value.value}
                                onChange={setValue}
                            />
                        </Input>
                    </InputGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <MenuButton>
                        <Menu>
                            <Item
                                name={
                                    <LocalizedText
                                        code="Generic.Delete"
                                    />
                                }
                                onClick={doDelete}
                            />
                        </Menu>
                    </MenuButton>
                </ViewGroupItem>
            </ViewGroup>
        </CardInset>;
    };

export default observer(EffectLayoutDependencyEditor);
