import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import Group from './Group/Group';
import useVisibleFieldGroups, { TypeFieldInclusion } from './Api/useVisibleFieldGroups';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useUncategorizedFieldGroup from './Api/useUncategorizedFieldGroup';
import Expansion from './Group/Expansion/Expansion';
import useChildRelation from '../../../../@Api/Entity/Bespoke/Relationship/useChildRelation';
import ExpansionGroup from '../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import useTypes from '../Type/Api/useTypes';
import Centered from '../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../@Future/Component/Generic/Loader/Loader';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import Header from '../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import ExpansionPanel from '../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import styles from './Fields.module.scss';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import { useExpansion } from '../Selection/Api/useExpansion';
import { useNewCommitContext } from '../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import FieldParentPathContext from './Context/FieldParentPathContext';
import { useChildRelationPath } from '../../../../@Api/Entity/Bespoke/Relationship/useChildRelationPath';
import { EntityFieldPath } from '../Path/@Model/EntityFieldPath';
import useVisibleFieldPathsForEntity from './Api/useVisibleFieldPathsForEntity';

export interface FieldsProps
{
    entity: Entity;
    noVirtualGroups?: boolean;
    ignoreHidden?: boolean;
    forType?: EntityType;
    includeTypeField?: TypeFieldInclusion;
    expanded?: boolean;
    commitContext?: CommitContext;
    useProvidedCommitContext?: boolean;
    readOnly?: boolean;
    fieldPaths?: EntityFieldPath[];
    doAutoCommit?: boolean;
    touched?: boolean;
    autoFocus?: (fieldPath: EntityFieldPath) => boolean;
    required?: (fieldPath: EntityFieldPath) => boolean;
    disabled?: (fieldPath: EntityFieldPath) => boolean;
    hidden?: (fieldPath: EntityFieldPath) => boolean;
    expandedByDefault?: (code: string) => boolean;
    appendix?: React.ReactNode;
    hideBespokeGroups?: boolean;
    hideGroups?: boolean;
    hideNoFields?: boolean;
}

const Fields: React.FC<FieldsProps> =
    props =>
    {
        const types = useTypes();
        const defaultFieldPaths = useVisibleFieldPathsForEntity(props.entity, props.ignoreHidden, props.forType, props.includeTypeField);
        const fieldPaths = props.fieldPaths ?? defaultFieldPaths;
        const [ groups, isLoadingGroups ] = useVisibleFieldGroups(props.entity, fieldPaths, props.noVirtualGroups, props.forType);
        const uncategorizedGroup = useUncategorizedFieldGroup(props.entity, fieldPaths, props.forType);
        const dividerGlue = useDividerGlue();
        const newCommitContext =
            useNewCommitContext(
                props.useProvidedCommitContext
                    ? undefined
                    : props.commitContext
            );
        const commitContext =
            props.useProvidedCommitContext
                ? props.commitContext
                : newCommitContext;
        const entity = props.entity;
        const childRelationPath = useChildRelationPath(props.entity.entityType);
        const _childRelation = useChildRelation(props.entity);
        const childRelation =
            props.hideBespokeGroups
                ? undefined
                : _childRelation;

        const [ isLoading ] =
            useExpansion(
                props.entity.entityType.isA(types.Relation.Type)
                    ? props.entity.entityType
                    : undefined,
                rootPath => [
                    ...props.entity.entityType.isA(types.Relation.Organization.Type)
                        ?
                        [
                            types.Relation.Organization.RelationshipDefinition.VisitingAddress,
                            types.Relation.Organization.RelationshipDefinition.PostalAddress,
                            types.Relation.Organization.RelationshipDefinition.InvoiceAddress,
                        ].map(
                            addressType =>
                                rootPath
                                    .joinTo(
                                        addressType,
                                        false)
                                    .joinTo(
                                        types.Address.RelationshipDefinition.Country,
                                        false))
                        :
                        [],
                    ...props.entity.entityType.isA(types.Relation.Person.Type)
                        ?
                        [
                            types.Relation.Person.RelationshipDefinition.Address
                        ].map(
                            addressType =>
                                rootPath
                                    .joinTo(
                                        addressType,
                                        false)
                                    .joinTo(
                                        types.Address.RelationshipDefinition.Country,
                                        false))
                        :
                        []
                ],
                () => [ props.entity ],
                [
                    props.entity,
                    types
                ]);

        if (isLoading || isLoadingGroups)
        {
            return <CardInset>
                <Centered
                    horizontal
                >
                    <Loader />
                </Centered>
            </CardInset>;
        }
        else
        {
            return <ExpansionGroup>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    glue={dividerGlue}
                >
                    {
                        !uncategorizedGroup
                        && groups.length === 0
                        && !childRelation
                        && !props.hideNoFields
                        &&
                            <ViewGroupItem>
                                <CardInset>
                                    <Centered
                                        horizontal
                                    >
                                        <i>
                                            <LocalizedText
                                                code="Fields.NoFields"
                                                value="Er zijn géén velden."
                                            />
                                        </i>
                                    </Centered>
                                </CardInset>
                            </ViewGroupItem>
                    }
                    {
                        (uncategorizedGroup || props.appendix) &&
                            <ViewGroupItem>
                                {
                                    uncategorizedGroup
                                        ? <Expansion
                                            {...props}
                                            fieldPaths={fieldPaths}
                                            entity={entity}
                                            group={uncategorizedGroup}
                                            commitContext={commitContext}
                                            includeFieldPathsFromUnknownGroups
                                        />
                                        : <CardInset
                                            top={false}
                                            horizontal={false}
                                        >
                                            {props.appendix}
                                        </CardInset>
                                }
                            </ViewGroupItem>
                    }
                    {
                        (groups.length > 0 || (!props.forType && childRelation)) &&
                        !props.hideGroups &&
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="vertical"
                                spacing={0}
                                glue={dividerGlue}
                            >
                                {
                                    groups.map(
                                        group =>
                                            <ViewGroupItem
                                                key={group.id || group.customId}
                                            >
                                                <Group
                                                    {...props}
                                                    fieldPaths={fieldPaths}
                                                    entity={entity}
                                                    group={group}
                                                    commitContext={commitContext}
                                                    appendix={undefined}
                                                />
                                            </ViewGroupItem>)
                                }
                                {
                                    !props.forType && childRelation &&
                                    <ViewGroupItem>
                                        <ExpansionGroup
                                            disabled={props.expanded === undefined}
                                        >
                                            <ExpansionPanel
                                                id="ChildRelation"
                                                summary={
                                                    <Header
                                                        title={childRelation.entityType.isA(types.Relation.Organization.Type)
                                                            ?
                                                            <LocalizedText
                                                                code="OrganizationDetails"
                                                                value="Organisatiegegevens"
                                                            />
                                                            :
                                                            <LocalizedText
                                                                code="PersonalDetails"
                                                                value="Persoonsgegevens"
                                                            />
                                                        }
                                                        inset
                                                    />
                                                }
                                                expansion={
                                                    <div
                                                        className={styles.childRelationExpansion}
                                                    >
                                                        <FieldParentPathContext.Provider
                                                            value={childRelationPath}
                                                        >
                                                            {/*<FieldInsetContext.Provider*/}
                                                            {/*    value={false}*/}
                                                            {/*>*/}
                                                            <ObservableFields
                                                                {...props}
                                                                includeTypeField="auto"
                                                                entity={childRelation}
                                                                commitContext={commitContext}
                                                                noVirtualGroups
                                                                appendix={undefined}
                                                            />
                                                            {/*</FieldInsetContext.Provider>*/}
                                                        </FieldParentPathContext.Provider>
                                                    </div>
                                                }
                                                expanded={props.expanded}
                                            />
                                        </ExpansionGroup>
                                    </ViewGroupItem>
                                }
                            </ViewGroup>
                        </ViewGroupItem>
                    }
                </ViewGroup>
            </ExpansionGroup>;
        }
    };

const ObservableFields = observer(Fields);

export default ObservableFields;
