import { getValueFromStorage } from '../../../Setting/Api/useLocalSetting';
import { SelectionOptionsValue } from '../Model/SelectionOptionsValue';
import View from '../../View/Model/View';
import getPredicateFromDescriptor from '../../../../../@Api/Automation/Api/getPredicateFromDescriptor';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';

export async function getSelectionOptionsValueFromStorage(
    key: string
): Promise<SelectionOptionsValue>
{
    const selectionOptionsValueDescriptor =
        getValueFromStorage(
            key,
            undefined,
            'session'
        );
    const view = await View.fromDescriptor(selectionOptionsValueDescriptor.view);
    const filter =
        selectionOptionsValueDescriptor.filter
            ? await getPredicateFromDescriptor(
                selectionOptionsValueDescriptor.filter,
                new AutomationDependencyContext(view.parameters)
            )
            : undefined;

    return {
        view: view,
        filter: filter
    };
}