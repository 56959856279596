import React from 'react';
import { observer } from 'mobx-react-lite';
import FunctionContext from '../../../../../../../../../../../@Api/Automation/Function/FunctionContext';
import Query from '../../../../../../../../../../../@Api/Automation/Query/Query';
import ListQuery from '../../../../../../../../../../../@Api/Automation/Query/ListQuery';
import ListQueryEditor from './List/ListQueryEditor';
import AggregateQuery from '../../../../../../../../../../../@Api/Automation/Query/AggregateQuery';
import AggregateQueryEditor from './Aggregate/AggregateQueryEditor';

export interface QueryEditorProps<T extends Query = Query>
{
    query: T;
    context: FunctionContext;
}

const QueryEditor: React.FC<QueryEditorProps> =
    props =>
    {
        if (props.query instanceof ListQuery)
        {
            return <ListQueryEditor
                {...props as QueryEditorProps<ListQuery>}
            />;
        }
        else if (props.query instanceof AggregateQuery)
        {
            return <AggregateQueryEditor
                {...props as QueryEditorProps<AggregateQuery>}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(QueryEditor);
