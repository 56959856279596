/**
 * @name StoreState
 * @description The state of a store
 * @copyright PerfectView
 *
 * @since 7-9-2017
 * @author Gertjan Centen
 * @version Initial version
 */

export enum StoreState
{
    Undefined,
    Loading,
    Loaded,
    Error
}
