import { useComputed } from 'mobx-react-lite';
import usePastValue from './usePastValue';
import useTodayValue from './useTodayValue';
import useTomorrowValue from './useTomorrowValue';
import useLaterValue from './useLaterValue';

export default function useDateValues()
{
    const pastValue = usePastValue();
    const todayValue = useTodayValue();
    const tomorrowValue = useTomorrowValue();
    const laterValue = useLaterValue();

    return useComputed(
        () =>
            [
                pastValue,
                todayValue,
                tomorrowValue,
                laterValue
            ],
        [
            pastValue,
            todayValue,
            tomorrowValue,
            laterValue
        ]);
}
