import { PredicateEditorStore } from '../../PredicateEditorStore';
import { action, observable } from 'mobx';
import { PredicateContext } from '../../PredicateContext';
import { PredicateType } from '../PredicateType';
import { PredicateSpecification } from '../PredicateSpecification';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { PredicateTypeStore } from '../../PredicateTypeStore';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';

export class NotPredicateEditorStore extends PredicateEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('PredicateTypeStore') predicateTypeStore: PredicateTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable predicateStore: PredicateEditorStore;
    @observable isAddMenuOpen: boolean;

    // ------------------------ Constructor -------------------------

    constructor(type: PredicateType,
                context: PredicateContext,
                specification: PredicateSpecification,
                predicateStore: PredicateEditorStore)
    {
        super(type, context, specification);

        this.predicateStore = predicateStore;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    deletePredicate(predicateStore: PredicateEditorStore)
    {
        this.predicateStore = null;
    }

    @action.bound
    openAddMenu()
    {
        this.isAddMenuOpen = true;
    }

    @action.bound
    closeAddMenu()
    {
        this.isAddMenuOpen = false;
    }

    @action.bound
    addPredicate(type: PredicateType)
    {
        this.predicateStore =
            PredicateEditorStore.construct(
                this.context,
                { type: type.id() },
                this.predicateTypeStore);
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
