import Text from '../../../Generic/Text/Text';
import { TextProps, TextStore } from '../../../Generic/Text/TextStore';
import { ExpansionPanelStore } from '../ExpansionPanelStore';
import { ViewComponent } from '../../ViewStack/Model/ViewComponent';
import List from '../../List/V2/List';
import { ViewGroupStore } from '../../ViewGroup/ViewGroupStore';
import { ViewGroupItem } from '../../ViewGroup/ViewGroupItem';
import Icon from '../../Icon/Icon';
import { IconStore } from '../../Icon/IconStore';
import { primaryColor } from '../../../../@Resource/Theme/Theme';

export interface ExpansionTitleProps extends TextProps
{
    expansionPanelStore: ExpansionPanelStore;
}

const defaultProps: Partial<ExpansionTitleProps> =
{

};

export class ExpansionTextStore extends TextStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: ExpansionTitleProps)
    {
        super({
            viewComponent:
                new ViewComponent(
                    List,
                    new ViewGroupStore({
                        direction: 'horizontal',
                        alignment: 'center',
                        items:
                        [
                            new ViewGroupItem({
                                id: 'label',
                                spacing: 3,
                                view:
                                    new ViewComponent(
                                        Text,
                                        new TextStore({
                                            variant: 'expansionPanelHeader',
                                            ...props,
                                            color:
                                                () =>
                                                    props.expansionPanelStore.isHoveringOverSummary
                                                        ?
                                                            'primary'
                                                        :
                                                            undefined
                                        }))
                            }),
                            new ViewGroupItem({
                                id: 'chevron',
                                spacing: 3,
                                isHidden:
                                    () =>
                                        !props.expansionPanelStore.isHoveringOverSummary
                                            &&
                                        !props.expansionPanelStore.isExpanded,
                                view:
                                    new ViewComponent(
                                        Icon,
                                        new IconStore({
                                            icon:
                                                () =>
                                                    props.expansionPanelStore.isExpanded
                                                        ?
                                                            'keyboard_arrow_up'
                                                        :
                                                            'keyboard_arrow_down',
                                            isInline: true,
                                            color:
                                                () =>
                                                    props.expansionPanelStore.isHoveringOverSummary
                                                        ?
                                                            primaryColor
                                                        :
                                                            undefined,
                                            size: 16,
                                            weight: 100,
                                            style:
                                            {
                                                paddingTop: 3
                                            }
                                        })
                                    )
                            })
                        ]
                    }))
        },
        defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
