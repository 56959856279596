import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { default as TabBarTab } from '../../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import { StaticTabSourceEditorProps } from '../StaticTabSourceEditor';
import Tab from '../../../../../../../../@Api/Layout/Type/TabBar/Model/Tab';
import Popper from '../../../../../../../../@Future/Component/Generic/Popper/Popper';
import useSwitch from '../../../../../../../../@Util/Switch/useSwitch';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import InputGroup from '../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import LayoutViewer from '../../../../../Viewer/LayoutViewer';
import ComputationEditor from '../../../../../../Entity/Viewer/Content/Automation/Editor/Computation/ComputationEditor';
import { IObservableArray, runInAction } from 'mobx';
import Value from '../../../../../../../../@Api/Automation/Value/Value';
import LayoutEditor from '../../../../../Editor/LayoutEditor';
import Layout from '../../../../../../../../@Api/Layout/Layout';
import FunctionContext from '../../../../../../../../@Api/Automation/Function/FunctionContext';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import RightAlignedButtonGroup from '../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import DeleteButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';

export interface StaticTabEditorProps extends StaticTabSourceEditorProps
{
    tab: Tab;
}

const StaticTabEditor: React.FC<StaticTabEditorProps> =
    props =>
    {
        const { layout, source, tab, context } = props;
        const [ isOpen, open, close ] = useSwitch(tab.layout === undefined);
        const setTabValue =
            useCallback(
                (value: Value<any, any>) =>
                    runInAction(
                        () =>
                            tab.value = value),
                [
                    tab
                ]);
        const setTabLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            tab.layout = layout),
                [
                    tab
                ]);
        const layoutContext =
            useMemo(
                () =>
                    new FunctionContext(
                        context.parameterDictionary.getNewDictionaryWithParameter(tab.parameter),
                        context.parameterAssignment,
                        context.commitContext
                    ),
                [
                    context,
                    tab
                ]);
        const deleteTab =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (source.tabs as IObservableArray).remove(tab)),
                [
                    source,
                    tab
                ]);

        return <Popper
            reference={
                <TabBarTab
                    value={1}
                    onClick={open}
                    selected={isOpen}
                >
                    <LayoutViewer
                        parameterDictionary={layoutContext.parameterDictionary}
                        parameterAssignment={layoutContext.parameterAssignment}
                        layout={tab.layout}
                    />
                </TabBarTab>
            }
            popper={
                <Card
                    inset
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={15}
                    >
                        <ViewGroupItem>
                            <InputGroup>
                                <Input
                                    labelPosition="left"
                                    label={
                                        <LocalizedText
                                            code="Generic.Value"
                                            value="Waarde"
                                        />
                                    }
                                >
                                    <ComputationEditor
                                        value={tab.value}
                                        onChange={setTabValue}
                                        type={layout.parameter.type}
                                        context={context}
                                    />
                                </Input>
                                <Input
                                    labelPosition="left"
                                    label={
                                        <LocalizedText
                                            code="Generic.Layout"
                                            value="Layout"
                                        />
                                    }
                                >
                                    <LayoutEditor
                                        parameterDictionary={layoutContext.parameterDictionary}
                                        parameterAssignment={layoutContext.parameterAssignment}
                                        layout={tab.layout}
                                        onChange={setTabLayout}
                                    />
                                </Input>
                            </InputGroup>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <RightAlignedButtonGroup>
                                <DeleteButton
                                    onClick={deleteTab}
                                />
                            </RightAlignedButtonGroup>
                        </ViewGroupItem>
                    </ViewGroup>

                </Card>
            }
            open={isOpen}
            onClose={close}
        />;
    };

export default observer(StaticTabEditor);
