import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { IObservableArray, runInAction } from 'mobx';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import DeleteIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import { EntitySelectionFormInputLayoutSearchFieldPathsEditorProps } from './EntitySelectionFormInputLayoutSearchFieldPathsEditor';

export interface EntitySelectionFormInputLayoutSearchFieldPathEditorProps extends EntitySelectionFormInputLayoutSearchFieldPathsEditorProps
{
    fieldPath: EntityFieldPath;
}

const EntitySelectionFormInputLayoutSearchFieldPathEditor: React.FC<EntitySelectionFormInputLayoutSearchFieldPathEditorProps> =
    props =>
    {
        const { layout, fieldPath } = props;

        const deleteFieldPath =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (layout.searchFieldPaths as IObservableArray).remove(fieldPath)),
                [
                    layout,
                    fieldPath
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem
                ratio={1}
            >
                {fieldPath.getName()}
            </ViewGroupItem>
            <ViewGroupItem>
                <DeleteIconButton
                    onClick={deleteFieldPath}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(EntitySelectionFormInputLayoutSearchFieldPathEditor);
