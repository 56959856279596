import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Popper from '../../../../../../../@Future/Component/Generic/Popper/Popper';
import Avatar from '../../../../../../Domain/Entity/Avatar/Avatar';
import Menu from '../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import RouterContext from '../../../../../../../@Service/Router/RouterContext';
import CurrentUserContext from '../../../../../../Domain/User/CurrentUserContext';
import styles from './EnvironmentButton.module.scss';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ApiControllerContext from '../../../../../../../@Api/Controller/ApiControllerContext';
import { User } from '../../../../../../../@Api/Model/Implementation/User';
import Icon from '../../../../../../../@Future/Component/Generic/Icon/Icon';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import useIsMobile from '../../../../../../../@Util/Responsiveness/useIsMobile';
import useDividerGlue from '../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import LocalizedText from '../../../../../../Domain/Localization/LocalizedText/LocalizedText';
import EnvironmentUserList from './EnvironmentUserList/EnvironmentUserList';
import { default as GenericAvatar } from '../../../../../../../@Future/Component/Generic/Avatar/Avatar';
import { openEntity } from '../../../../../../Domain/Entity/@Util/openEntity';
import openDialog from '../../../../../../../@Service/Navigation/Page/Hooks/openDialog';
import { NoLicenseErrorDialog } from './EnvironmentUserList/EnvironmentUser/NoLicenseErrorDialog';
import Link from '../../../../../../../@Future/Component/Generic/Link/Link';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Divider from '../../../../../../../@Future/Component/Generic/Divider/Divider';

export interface EnvironmentButtonClasses
{
    root?: string;
}

export interface EnvironmentButtonProps
{
    classes?: EnvironmentButtonClasses
}

export const defaultPageSize = 20;

const EnvironmentButton: React.FC<EnvironmentButtonProps> =
    () =>
    {
        const routerContext = useContext(RouterContext);
        const currentUserContext = useContext(CurrentUserContext);
        const apiControllerContext = useContext(ApiControllerContext);

        const [ isOpen, setOpen ] = useState(false);
        const [ users, setUsers ] = useState<Array<User>>([currentUserContext.currentUser]);

        const [ lastPage, setLastPage ] = useState<number>(0);
        const [ hasMore, setHasMore ] = useState<boolean>(true);

        const loadUsers =
            useCallback( (
                    page: number = 0,
                    pageSize: number
                ) =>
                {
                    apiControllerContext.accountUserController
                        .getUsersInAccount(page,defaultPageSize)
                        .then(
                            result =>
                            {
                                const nextLoadedUsers = result
                                    .filter(
                                        resultUser => !users
                                            .some(
                                                user => user.id === resultUser.id
                                            )
                                    );

                                setUsers(users.concat(nextLoadedUsers));
                                setLastPage(page);
                                setHasMore(result.length === pageSize);
                            }
                        )
                },
                [
                    apiControllerContext.accountUserController,
                    users,
                    setUsers,
                ]
        )

        useEffect(
            () =>
            {
                loadUsers(0, defaultPageSize)
            },
            [
            ]
        )

        const openOrganization =
            useCallback(
                () =>
                {
                    setOpen(false);
                    openEntity(currentUserContext.environmentEntity);
                },
                [
                    currentUserContext,
                    setOpen
                ]);

        const openContract =
            useCallback(
                () =>
                {
                    setOpen(false);
                    routerContext.route('/account');
                },
                [
                    routerContext,
                    setOpen
                ]);

        const openConfigurationPageCallback =
            useCallback(
                () =>
                {
                    setOpen(false);
                    routerContext.route('/management/configuration');
                },
            [
                routerContext,
                setOpen
            ]);

        const userSelectCallback =
            useCallback(
                (_: User) =>
                {
                    setOpen(false);
                },
                []
            );

        const noLicenseErrorCallback =
            useCallback(
                (user: User) =>
                {
                    openDialog(
                        close =>
                            <NoLicenseErrorDialog
                                user={user}
                                onClose={close}
                            />
                    );
                },
                []
            );

        const isMobile = useIsMobile();
        const dividerGlue = useDividerGlue();

        return <Popper
           reference={
               <div
                   onClick={() => setOpen(!isOpen)}
                   className={classNames(
                       styles.wrapper,
                       isOpen && styles.open
                   )}
               >
                   {
                       currentUserContext?.environmentEntity
                           ?
                               <Avatar
                                   entity={currentUserContext?.environmentEntity}
                                   editable={false}
                                   size={30}
                                   classes={{
                                       root: styles.avatarRoot
                                   }}
                               />
                           :
                               <GenericAvatar
                                   icon="person"
                                   size={30}
                                   className={styles.avatarRoot}
                               />
                   }
                   <div className={styles.overlay} />
                   <Icon
                       color="white"
                       icon="keyboard_arrow_down"
                       className={styles.overlayIcon}
                   />
               </div>
           }
           popper={
               <Card>
                   <ViewGroup
                       orientation={isMobile ? 'vertical' : 'horizontal'}
                       spacing={isMobile ? 0 : 16}
                       glue={isMobile ? dividerGlue : undefined}
                   >
                       {
                           (currentUserContext?.isAllowedInConfiguration || currentUserContext?.isSupport) &&
                               <ViewGroupItem
                                   ratio={isMobile ? undefined : 1}
                               >
                                   <Menu>
                                       {
                                           currentUserContext.isAllowedInConfiguration &&
                                               <Item
                                                   name={
                                                       <LocalizedText
                                                           code="Header.AccountSettings"
                                                           value="Accountinstellingen"
                                                       />
                                                   }
                                                   onClick={openContract}
                                                />
                                       }
                                       {
                                           currentUserContext.isDeveloper &&
                                               <Item
                                                   name={
                                                       <LocalizedText
                                                           code="Settings.Configuration"
                                                           value="Configuratie"
                                                       />
                                                   }
                                                   onClick={openConfigurationPageCallback}
                                               />
                                       }
                                       <Item
                                           key="organization"
                                           name={
                                               <LocalizedText
                                                   code="Settings.OwnOrganizationEntity"
                                                   value="Organisatie gegevens"
                                               />
                                           }
                                           onClick={openOrganization}
                                       />
                                   </Menu>
                               </ViewGroupItem>
                       }
                       <ViewGroupItem
                           ratio={isMobile ? undefined : 1}
                       >
                           <EnvironmentUserList
                               users={users}
                               currentUser={currentUserContext?.currentUser}
                               onSelect={userSelectCallback}
                               onNoLicenseError={noLicenseErrorCallback}
                           />
                           {
                               hasMore &&
                               <>
                                   <Divider />
                                   <Menu>
                                       <Item
                                           name={
                                               <Centered
                                                   horizontal
                                               >
                                                   <Link
                                                       onClick={() => {}}
                                                       highlighted
                                                   >
                                                       <LocalizedText
                                                           code="Generic.LoadMore"
                                                           value="Meer laden"
                                                       />...
                                                   </Link>
                                               </Centered>
                                           }
                                           onClick={() => loadUsers(lastPage +1, defaultPageSize)}
                                       />
                                   </Menu>
                               </>
                           }
                       </ViewGroupItem>
                   </ViewGroup>
               </Card>
           }
           open={isOpen}
           onClose={() => setOpen(false)}
        />;
    };

export default observer(EnvironmentButton);
