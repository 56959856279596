import React from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { RightSwitch } from '../../RightSwitch/RightSwitch';
import Tooltip from '../../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import { Permission } from '../../../Model/Role';

export interface RoleLegendItemProps
{
    resourceCode: string;
    resourceValue: string;
    tooltipResourceCode: string;
    tooltipResourceValue: string;
    permission?: Permission;
    inheritedPermission?: Permission;
    disabled?: boolean;
}

export const RoleLegendItem: React.FC<RoleLegendItemProps> =
    observer(
        ({
             resourceCode,
             resourceValue,
             tooltipResourceCode,
             tooltipResourceValue,
             permission,
             inheritedPermission,
             disabled,
         }) =>
        {
            return <ViewGroupItem>
                    <Tooltip
                        title={
                            <LocalizedText
                                code={tooltipResourceCode}
                                value={tooltipResourceValue}
                            />
                        }
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={8}
                            alignment="center"
                        >
                            <ViewGroupItem>
                                <RightSwitch
                                    permission={permission ? permission : 'Inherited'}
                                    inheritedPermission={inheritedPermission ? inheritedPermission : 'Inherited'}
                                    isDisabled={disabled}
                                    onChange={undefined}
                                    canRightBeInherited={false}
                                    isReadOnly={true}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <LocalizedText
                                    code={resourceCode}
                                    value={resourceValue}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </Tooltip>
                </ViewGroupItem>;
        }
    );
