import { DataObject } from '../../../DataObject/Model/DataObject';
import { Comparator } from '../../../DataObject/Model/Comparator';

export function compareDataObjects(
    d1: DataObject,
    d2: DataObject,
    isAscending: boolean
)
{
    if (DataObject.compare(d1, d2, Comparator.LessThan))
    {
        if (isAscending)
        {
            return -1;
        }
        else
        {
            return 1;
        }
    }
    else if (DataObject.compare(d1, d2, Comparator.Equals))
    {
        return 0;
    }
    else
    {
        if (isAscending)
        {
            return 1;
        }
        else
        {
            return -1;
        }
    }
}
