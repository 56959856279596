import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Tooltip from '../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import { useDrag } from 'react-dnd';
import { openEntity } from '../../../../@Util/openEntity';
import TrackItemLayout from './Layout/TrackItemLayout';
import { default as TrackItemModel } from '../Model/TrackItem';
import { TrackProps } from '../Track';
import { TrackItemTooltip } from './Tooltip/TrackItemTooltip';
import { makeStyles } from '@material-ui/styles';
import { isAllDay } from '../../../../Calendar/Util/isAllDay';

const useStyles =
    makeStyles({
        tooltip:
            {
                background: '#fff',
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 400,
                border: '#ddd 1px solid',
                padding: 8,
                color: 'rgba(0, 0, 0, 0.67)',
                maxWidth: 500
            }
    });

export interface TrackItemProps extends TrackProps
{
    item: TrackItemModel;
    row: number;
    preview?: boolean;
}

const TrackItem: React.FC<TrackItemProps> =
    props =>
    {
        const classes = useStyles();
        const [ fromIdx, toIdx ] = [ props.item.range.from.cell.idx, props.item.range.to.cell.idx ];

        const [ { isDragging }, drag, preview ] =
            useDrag({
                item: {
                    type: 'TrackItem',
                    item: props.item
                },
                collect:
                    monitor => ({
                        isDragging: !!monitor.isDragging()
                    })
            });

        const style =
            useMemo(
                () => ({
                    gridColumn: `${fromIdx + 1} / ${toIdx + 2}`,
                    gridRowStart: props.row + 1,
                    opacity: isDragging ? 0.5 : 1,
                    padding: '0.5px',
                    height: 23,
                    marginTop: 4.5
                }),
                [
                    fromIdx,
                    toIdx,
                    isDragging,
                    props.row
                ]);

        const onOpen =
            useCallback(
                event =>
                {
                    event.stopPropagation();

                    openEntity(props.item.item.entity);
                },
                [
                    props.item
                ]);

        const onSelectEvent =
            useCallback(
                event =>
                {
                    event.stopPropagation();
                },
                []);

        const allDay =
            useMemo(
                () =>
                {
                    return isAllDay(
                        props.item.item.period.from.toDate(),
                        props.item.item.period.to.toDate()
                    )
                },
               [
                   props.item.item.period.from,
                   props.item.item.period.to
               ]
            );

        if (fromIdx === undefined || toIdx === undefined
            || (fromIdx === toIdx && allDay))
        {
            return null;
        }
        else
        {
            return <>
                <div
                    ref={preview}
                />
                <div
                    ref={drag}
                    style={style}
                    onClick={onOpen}
                    onMouseDown={onSelectEvent}
                    onMouseUp={onSelectEvent}
                >
                    <Tooltip
                        customTooltipStyle={classes.tooltip}
                        placement="right"
                        interactive
                        title={
                            <TrackItemTooltip
                                trackItem={props.item}
                            />
                        }
                        block
                        fullHeight
                    >
                        <TrackItemLayout
                            {...props}
                        />
                    </Tooltip>
                </div>
            </>;
        }
    };

export default observer(TrackItem);
