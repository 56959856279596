import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { ExpansionPanelStore } from './ExpansionPanelStore';
import { createStyles, Divider, Theme, withStyles } from '@material-ui/core';
import Text from '../Text/Text';
import Button from '../Button/Button';
import Avatar from '../Avatar/Avatar';
import Badge from '../Badge/Badge';
import Checkbox from '../Checkbox/Checkbox';
import { ListIndentationDepth } from '../List/V2/ListStore';
import { buttonInset, smallViewInset, viewInset } from '../../../@Resource/Theme/Theme';
import Icon from '../Icon/Icon';
import { getVerticalAlignmentCss } from '../../../@Resource/Theme/Alignment';
import { View } from '../ViewStack/View';

// noinspection TsLint
const styles =
    (theme: Theme) =>
        createStyles({
            summaryRoot:
            {
                position: 'relative'
            },
            summaryRootWithPadding:
            {
                padding: '0 15px'
            },
            summaryRootWithSmallRightPadding:
            {
                padding: '0 10px 0 15px'
            },
            summaryContent:
            {
                paddingTop: 4,
                paddingBottom: 4
            },
            summaryContentExpanded:
            {

            }
        });

@observer
class ExpansionPanelSummary extends BaseComponent<ExpansionPanelStore>
{
    renderComponent(store: ExpansionPanelStore)
    {
        return <div
            className={`
                ${this.props.classes.summaryRoot}
                ${store.isCard && store.hasSummaryHorizontalPadding
                    ?
                        store.hasSummarySmallRightPadding
                            ?
                                this.props.classes.summaryRootWithSmallRightPadding
                            :
                                this.props.classes.summaryRootWithPadding
                    :
                        this.props.classes.summaryRoot}
                ${this.props.classes.summaryContent}
                ${store.isExpanded ? this.props.classes.summaryContentExpanded : ''}`}
            style={{
                position: 'relative',
                cursor:
                    store.showPointerOverSummary
                        ?
                            'pointer'
                        :
                            'default',
                borderBottom:
                    store.hasSummaryBorderBottom && store.isExpanded
                        ?
                            `1px solid ${store.borderColor}`
                        :
                            undefined,
                backgroundColor: store.finalBackgroundColor,
                borderTopLeftRadius: store.topBorderRadius - 2,
                borderTopRightRadius: store.topBorderRadius - 2,
                borderBottomLeftRadius: !store.isExpanded ? store.bottomBorderRadius - 2 : 0,
                borderBottomRightRadius: !store.isExpanded ? store.bottomBorderRadius - 2 : 0
            }}
            onClick={
                store.isClickable
                    ?
                        store.click
                    :
                        undefined}
            onMouseEnter={store.startHoveringOverSummary}
            onMouseOver={store.startHoveringOverSummary}
            onMouseLeave={store.stopHoveringOverSummary}
        >
            {
                store.ribbonColor && (store.showRibbonOnlyOnHover ? store.isHovering : true) &&
                    <div
                        style={{
                            width: 4,
                            background: store.ribbonColor,
                            borderRadius: '2px 0px 0px 2px',
                            position: 'absolute',
                            top: 2,
                            bottom: 2,
                            left: 2,
                        }}
                    />
            }

            <div
                style={{
                    display: 'flex',
                    alignItems: getVerticalAlignmentCss(store.verticalAlignment),
                    flex: '1 1 auto',
                    paddingRight: 0,
                    paddingLeft:
                        store.listIndentation
                            ?
                                store.listIndentation * ListIndentationDepth
                            :
                                undefined
                }}
            >

                {
                    store.summaryPrefix &&
                        <div
                            style={{
                                marginRight: viewInset
                            }}
                        >
                            <View
                                viewComponent={store.summaryPrefix}
                            />
                        </div>
                }
                <div
                    onMouseEnter={store.startHoveringOverAvatar}
                    onMouseOver={store.startHoveringOverAvatar}
                    onMouseLeave={store.stopHoveringOverAvatar}
                    style={{
                        position: 'relative',
                        width:
                            store.showSummaryAvatarStore
                                ?
                                    store.summaryAvatarStore.size
                                :
                                    store.summaryCheckboxStore
                                        ?
                                            store.summaryCheckboxStore.size
                                        :
                                            undefined,
                        minWidth:
                            store.showSummaryAvatarStore
                                ?
                                    store.summaryAvatarStore.size
                                :
                                    store.summaryCheckboxStore
                                        ?
                                            store.summaryCheckboxStore.size
                                        :
                                            undefined,
                        height:
                            store.showSummaryAvatarStore
                                ?
                                    store.summaryAvatarStore.size
                                :
                                    store.summaryCheckboxStore
                                        ?
                                            store.summaryCheckboxStore.size
                                        :
                                            undefined,
                        marginRight:
                            store.summaryCheckboxStore || store.showSummaryAvatarStore
                                ?
                                    viewInset
                                :
                                    undefined,
                        alignSelf:
                            store.showTimeline
                                ?
                                    'flex-start'
                                :
                                    undefined,
                        paddingTop:
                            store.showTimeline
                                ?
                                    10
                                :
                                    undefined
                    }}
                >
                    {
                        store.summaryIcon &&
                            <div
                                style={{
                                    marginRight: smallViewInset
                                }}
                            >
                                <Icon
                                    store={store.summaryIcon}
                                />
                            </div>
                    }
                    {
                        store.showSummaryAvatarStore &&
                            <div
                                style={{
                                    position: 'absolute',
                                    display: store.showCheckbox ? 'none' : 'block',
                                }}
                            >
                                <Avatar store={store.summaryAvatarStore} />
                            </div>
                    }
                    {
                        store.summaryCheckboxStore &&
                            <div
                                style={{
                                    position: 'absolute',
                                    display: store.showCheckbox ? 'block' : 'none'
                                }}
                            >
                                <Checkbox store={store.summaryCheckboxStore}/>
                            </div>
                    }
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1 1 auto',
                        paddingRight: 0,
                        maxWidth: '100%'
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            flex: '1 1 auto',
                            alignItems: getVerticalAlignmentCss(store.verticalAlignment)
                        }}
                    >
                        {
                            store.summaryPrimaryTextStore &&
                                <div
                                    style={{
                                        flex: '1 1 auto',
                                        paddingTop: 6,
                                        paddingBottom: 6,
                                        paddingRight: viewInset
                                    }}
                                >
                                    <Text
                                        store={store.summaryPrimaryTextStore}
                                    />
                                </div>
                        }
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                bottom: 0,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {
                                store.visibleSummaryButtonStores.length > 0 &&
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: store.finalBackgroundColor,
                                            borderRadius: '50%',
                                            height: '100%',
                                            paddingLeft: buttonInset
                                        }}
                                    >
                                        {
                                            store.visibleSummaryButtonStores.map(
                                                (buttonStore, idx) =>
                                                    <div
                                                        key={buttonStore.uuid}
                                                        style={{
                                                            marginLeft:
                                                                idx > 0
                                                                    ?
                                                                        buttonInset
                                                                    :
                                                                        undefined
                                                        }}
                                                    >
                                                        <Button
                                                            store={buttonStore}
                                                        />
                                                    </div>)
                                        }
                                    </div>
                            }
                            {
                                store.summaryBadgeStores && store.summaryBadgeStores.length > 0 &&
                                    <div
                                        style={{
                                            marginLeft: buttonInset
                                        }}
                                    >
                                        {
                                            store.summaryBadgeStores.map(
                                                badgeStore =>
                                                    <Badge
                                                        key={badgeStore.uuid}
                                                        store={badgeStore}
                                                    />)
                                        }
                                    </div>
                            }
                            {
                                store.summarySecondaryTextStore &&
                                (!store.isHoveringOverSummary || (store.isHoveringOverSummary && !store.summarySecondaryAvatar)) &&
                                    <div
                                        style={{
                                            marginLeft: buttonInset,
                                            backgroundColor: store.finalBackgroundColor,
                                        }}
                                    >
                                        <Text
                                            store={store.summarySecondaryTextStore}
                                        />
                                    </div>
                            }
                            {
                                store.summarySecondaryAvatar &&
                                store.isHoveringOverSummary &&
                                    <div
                                        style={{
                                            marginLeft: buttonInset
                                        }}
                                    >
                                        <Avatar store={store.summarySecondaryAvatar} />
                                    </div>
                            }
                            {
                                 store.showSummaryIcons &&
                                    <div
                                        style={{
                                            marginLeft: buttonInset
                                        }}
                                    >
                                        {
                                            store.summaryIcons.map(
                                                (icon, index) =>
                                                    <Icon key={index}>{icon}</Icon>)
                                        }
                                    </div>
                            }
                            {
                                store.showChevron &&
                                    <div
                                        style={{
                                            marginLeft: buttonInset
                                        }}
                                    >
                                        <Button
                                            store={store.chevronButtonStore}
                                        />
                                    </div>
                            }
                            <div
                                style={{
                                    marginLeft: buttonInset
                                }}
                            >
                                <Button store={store.menuButtonStore} />
                            </div>
                        </div>
                    </div>

                </div>
                {
                    store.summarySuffix &&
                        <div
                            style={{
                                marginLeft: buttonInset
                            }}
                        >
                            <View
                                viewComponent={store.summarySuffix}
                            />
                        </div>
                }
            </div>
            {
                store.summaryView &&
                    React.createElement(
                        store.summaryView.component,
                        {
                            store: store.summaryView.store
                        })
            }
            {
                store.showTimeline &&
                    <Divider
                        light
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: store.isFirstInTimeline ? 0 : 50,
                            right: 0
                        }}
                    />
            }
            {
                store.showTimeline && store.isLastInTimeline &&
                    <Divider
                        light
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0
                        }}
                    />
            }
        </div>;
    }
}

export default withStyles(styles)(ExpansionPanelSummary as any);
