import * as React from 'react';
import { BaseComponent } from '../../../../../../@Framework/Component/BaseComponent';
import { ConditionalComputationConditionStore } from './ConditionalComputationConditionStore';
import { observer } from 'mobx-react';
import { grey } from '@material-ui/core/colors';
import { Icon, IconButton, Paper } from '@material-ui/core';
import { ComputationEditor } from '../../../ComputationEditor';
import { PredicateEditor } from '../../../../Predicate/PredicateEditor';

@observer
export class ConditionalComputationConditionEditor extends BaseComponent<ConditionalComputationConditionStore>
{
    renderComponent(store: ConditionalComputationConditionStore)
    {
        return <div
            style={{
                position: 'relative',
                margin: 10
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    backgroundColor: grey[500],
                    width: 35,
                    height: 1,
                    left: -35,
                    top: 25
                }}
            />
            <Paper
                style={{
                    display: 'inline-flex',
                    flex: '1 1 auto',
                    background: store.computationStore.type.isTerminal() ? 'white' : 'none',
                    borderRadius: store.computationStore.type.isTerminal() ? 10 : undefined,
                    paddingLeft: store.computationStore.type.isTerminal() ? 15 : undefined
                }}
                elevation={store.computationStore.type.isTerminal() ? undefined : 0}
            >
                <div style={{ flex: '1 1 auto', display: 'inline-flex', alignItems: 'center' }}>
                    <PredicateEditor store={store.predicateStore} />
                </div>
                <div style={{ flex: '1 1 auto', display: 'inline-flex', alignItems: 'center' }}>
                    <ComputationEditor store={store.computationStore} />
                </div>
                <div>
                    <IconButton onClick={store.delete}>
                        <Icon>remove_circle</Icon>
                    </IconButton>
                </div>
            </Paper>
        </div>;
    }
}
