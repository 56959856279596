import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import TabBarLayout from '../../../../../@Api/Layout/Type/TabBar/TabBarLayout';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import TabBar from '../../../../../@Future/Component/Generic/TabBar/TabBar';
import EmptyValue from '../../../../../@Api/Automation/Value/EmptyValue';
import safelySynchronousApplyFunction from '../../../../../@Api/Automation/Api/safelySynchronousApplyFunction';
import Tab from '../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import { runInAction } from 'mobx';

export interface TabBarLayoutViewerProps extends LayoutViewerProps<TabBarLayout>
{

}

const TabBarLayoutViewer: React.FC<TabBarLayoutViewerProps> =
    props =>
    {
        const { layout, parameterDictionary, parameterAssignment, commitContext } = props;
        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        parameterDictionary,
                        parameterAssignment,
                        commitContext
                    ),
                [
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                ]);
        const value =
            useComputed(
                () =>
                    context.parameterAssignment.getValue(layout.parameter) || EmptyValue.instance,
                [
                    context,
                    layout
                ]);
        const valueId =
            useMemo(
                () =>
                    value instanceof EmptyValue ? undefined : value.getId(),
                [
                    value
                ]);

        const tabs =
            useComputed(
                () =>
                    layout.sources
                        .map(
                            source =>
                                source.getTabs().slice())
                        .reduce((a, b) => a.concat(b), []),
                [
                    layout
                ]);
        const tabValues =
            useComputed(
                () =>
                    tabs.map(
                        tab =>
                            safelySynchronousApplyFunction(
                                tab.value,
                                context)),
                [
                    tabs,
                    context
                ]);
        const parameterAssignments =
            useMemo(
                () =>
                    tabs.map(
                        (tab, idx) =>
                            context.parameterAssignment.getNewAssignmentWithParameter(tab.parameter, tabValues[idx])),
                [
                    tabs,
                    context,
                    tabValues
                ]);
        const click =
            useCallback(
                (idx: number) =>
                    runInAction(
                        () =>
                            parameterAssignment.setValue(
                                layout.parameter,
                                tabValues[idx])),
                [
                    parameterAssignment,
                    layout,
                    tabValues
                ]);

        return <TabBar
            value={valueId}
        >
            {
                tabs.map(
                    (tab, idx) =>
                        <Tab
                            key={tab.getId()}
                            value={tabValues[idx].getId()}
                            onClick={() => click(idx)}
                        >
                            <LayoutViewer
                                parameterDictionary={context.parameterDictionary}
                                parameterAssignment={parameterAssignments[idx]}
                                layout={tab.layout}
                            />
                        </Tab>)
            }
        </TabBar>;
    };

export default observer(TabBarLayoutViewer);
