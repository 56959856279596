export const Setting = {
    Metadata: {
        HiddenTypes: 'Metadata.HiddenTypes',
        HiddenFields: 'Metadata.HiddenFields',
        RequiredFields: 'Metadata.RequiredFields',
        VisibleFieldsDuringConstruction: 'Metadata.VisibleFieldsDuringConstruction',
        ExpandedFieldGroups: 'Metadata.ExpandedFieldGroups',
        ExpandedFieldGroupPredicate: 'Metadata.ExpandedFieldGroupPredicate',
        HasProducts: 'Metadata.HasProducts',
        HasInvolved: 'Metadata.HasInvolved',
        HasDefaultPipeline: 'Metadata.HasDefaultPipeline',
        DefaultPipelineName: 'Metadata.DefaultPipelineName',
        DetailsLayout: 'Metadata.DetailsLayout',
        ActivityList: 'Metadata.ActivityList',
        ActivityListItemLayout: 'Metadata.ActivityListItemLayout',
        ProductLine: {
            SpecificationLayout: 'Metadata.ProductLine.SpecificationLayout'
        },
        TimeRegistration: {
            SpecificationLayout: 'Metadata.TimeRegistration.SpecificationLayout'
        },
        ParentRelationshipDefinitionFilter: 'Metadata.ParentRelationshipDefinitionFilter',
        ChildRelationshipDefinitionFilter: 'Metadata.ChildRelationshipDefinitionFilter',
        PlannerColor: 'Metadata.PlannerColor',
    },
    Invoicing: {
        BccEmailAddress: 'Invoicing.BccEmailAddress',
        IsSalesOrderEnabled: 'Invoicing.IsSalesOrderEnabled',
        PaymentTermInDays: 'Invoicing.PaymentTermInDays',
        CombineTimeRegistrationsWhenProcessing: 'Invoicing.CombineTimeRegistrationsWhenProcessing'
    },
    Sales: {
        Offer: {
            DefaultExpirationInDays: 'Sales.Offer.DefaultExpirationInDays',
            SigningBackground: 'Sales.Offer.SigningBackground'
        },
        IsPriceListInRelationshipEnabled: 'Sales.IsPriceListInRelationshipEnabled',
    },
    WorkOrder: {
        HidePrices: 'WorkOrder.HidePrices'
    },
    Project: {
        ShowFinancialWidgets: 'Project.ShowFinancialWidgets'
    },
    Address: {
        UseNEN5825Format: 'Address.UseNEN5825Format'
    },
    Datastore: {
        HiddenIds: 'Datastore.HiddenIds'
    },
    EmailingPreferences: {
        Layout: 'EmailingPreferences.Layout'
    },
    Emailing: {
        IsLegacySearchBehaviourEnabled: 'Emailing.IsLegacySearchBehaviourEnabled'
    },
    Local: {
        Translation: {
            IsInPlaceEditorEnabled: 'Translation.IsInPlaceEditorEnabled'
        },
        Assistant: {
            IsEnabled: 'Assistant.IsEnabled',
        }
    },
    Currency: 'Currency',
    TwoFactorAuthentication: 'TwoFactorAuthentication',
    PasswordPolicy: 'PasswordPolicy',
    IsOpenRelatedActivityListAscendingSort: 'IsOpenRelatedActivityListAscendingSort',
    ShowOnlyTeamCalendar: 'ShowOnlyTeamCalendar',
    TimeZone: 'TimeZone',
    ActivityRecurringTotalsInterval: 'ActivityRecurringTotalsInterval',
    DebtorNumberGeneratorEntityTypeIds: 'DebtorNumberGeneratorEntityTypeIds',
    FavoriteDashboard: 'FavoriteDashboard',
    InvoiceRegime: 'Sales.Invoice.InvoiceRegime',
    Export: 'Export.Configuration',
    RedirectToV2: 'RedirectToV2',
};
