import { type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';
import { Entity } from './Entity';

@type('EntityByDate')
export class EntityByDate
{
    // ------------------- Persistent Properties --------------------

    @observable.ref entity: Entity;
    @observable date: number;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entity: Entity, date: number)
    {
        this.entity = entity;
        this.date = date;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
