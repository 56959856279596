import { TextProps, TextStore } from '../../../Generic/Text/TextStore';
import { getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { computed } from 'mobx';
import { ViewComponent } from '../../../Generic/ViewStack/Model/ViewComponent';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { EntityCaptionLinkStore } from './EntityCaptionLinkStore';
import Button from '../../../Generic/Button/Button';
import { ButtonStore } from '../../../Generic/Button/ButtonStore';
import { RelatedEntityPath } from '../Path/@Model/RelatedEntityPath';
import RichText from '../../../Generic/RichText/RichText';
import { ReactViewComponent } from '../../../Generic/ViewStack/Model/ReactViewComponent';
import localizedText from '../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../@Api/Localization/localizeText';

export interface EntityCaptionTextProps extends TextProps
{
    entity: PropType<EntityCaptionTextStore, EntityCaptionTextProps, Entity>;
    onVisit?: (entity: Entity) => Promise<any>;
    listType?: PropType<EntityCaptionTextStore, EntityCaptionTextProps, EntityType>;
    showOwnersByDefault?: PropType<EntityCaptionTextStore, EntityCaptionTextProps, boolean>;
    showType?: PropType<EntityCaptionTextStore, EntityCaptionTextProps, boolean>;
    pathFromRelatedEntity?: PropType<EntityCaptionTextStore, EntityCaptionTextProps, RelatedEntityPath>;
}

const defaultProps: Partial<EntityCaptionTextProps> =
{
    variant: 'underline',
    color: 'secondary',
    showOwnersByDefault: true,
    showType: false
};

export class EntityCaptionTextStore extends TextStore<EntityCaptionTextProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: EntityCaptionTextProps)
    {
        super({
            isVisible: () => this.owners.length > 0 || this.entity.entityType === this.entityTypeStore.bespoke.types.Relationship.Organization.Identity.Type,
            ...props
        },
        defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get entity(): Entity
    {
        return getOrCompute(this, this.props.entity);
    }

    @computed
    get listType(): EntityType
    {
        return getOrCompute(this, this.props.listType);
    }

    @computed
    get showType(): boolean
    {
        return getOrCompute(this, this.props.showType);
    }

    @computed
    get showOwnersByDefault(): boolean
    {
        return getOrCompute(this, this.props.showOwnersByDefault);
    }

    @computed
    get pathFromRelatedEntity(): RelatedEntityPath
    {
        return getOrCompute(this, this.props.pathFromRelatedEntity);
    }

    @computed
    get isActivity(): boolean
    {
        return this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Activity.Type);
    }

    @computed
    get isPersonRelationship(): boolean
    {
        return this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Type);
    }

    @computed
    get isEmployee(): boolean
    {
        return this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Employee.Type);
    }

    @computed
    get isOrganizationRelationship(): boolean
    {
        return this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relationship.Organization.Type);
    }

    @computed
    get owners(): Entity[]
    {
        return this.entity.entityType === this.entityTypeStore.bespoke.types.Relationship.Organization.Identity.Type
            ?
                []
            :
                this.entity.getRelationships(true)
                    .filter(
                        relationship =>
                            relationship.definition !== this.entityTypeStore.bespoke.types.Pack.RelationshipDefinition.Entities
                            && (this.pathFromRelatedEntity === undefined || relationship.getEntity(true).id !== this.pathFromRelatedEntity.entity.id)
                            && this.entity.entityType.bespoke.isOwnerInListCaption(
                                this.entity,
                                relationship.getEntity(true),
                                relationship.definition,
                                this.pathFromRelatedEntity))
                    .map(
                        relationship =>
                            relationship.parentEntity);
    }

    @computed
    get relationship(): Entity
    {
        return this.entity.getRelatedEntityByDefinition(
            true,
            this.entityTypeStore.bespoke.types.Activity.RelationshipDefinition.Relationship);
    }

    @computed
    get contact(): Entity
    {
        return this.entity.getRelatedEntityByDefinition(
            true,
            this.entityTypeStore.bespoke.types.Activity.RelationshipDefinition.Contact);
    }

    @computed
    get label(): string
    {
        if (this.showType)
        {
            return '%0 %1';
        }
        else
        {
            return this.getLabel();
        }
    }

    @computed
    get showChips(): boolean
    {
        return this.props.onVisit !== undefined;
    }

    @computed
    get separator(): string
    {
        return '';
    }

    // --------------------------- Stores ---------------------------

    @computed
    get childTextStores(): TextStore[]
    {
        if (this.showType)
        {
            return [
                new TextStore({
                    viewComponent:
                        new ViewComponent(
                            Button as any,
                            new ButtonStore({
                                renderAsChip: true,
                                icon:
                                    () =>
                                        this.entity.entityType.getInheritedIcon(),
                                outlined: false,
                                size: 'small',
                                color:
                                    () =>
                                        this.entity.entityType.getInheritedColor(),
                                label:
                                    () =>
                                    {
                                        const name = this.entity.entityType.getName();

                                        if (name)
                                        {
                                            return name.toLowerCase();
                                        }
                                        else
                                        {
                                            return undefined;
                                        }
                                    }})),
                    isInline: true
                }),
                new TextStore({
                    label:
                        () =>
                            this.getLabel(),
                    childTextStores:
                        () =>
                            this.getChildTextStores(),
                    isInline: true
                })
            ];
        }
        else
        {
            return this.getChildTextStores();
        }
    }

    @computed
    get view(): ViewComponent
    {
        if (this.isActivity)
        {
            return undefined;
        }
        else if (this.isPersonRelationship)
        {
            return undefined;
        }
        else if (this.isOrganizationRelationship)
        {
            return undefined;
        }
        else if (this.owners.length > 0)
        {
            return undefined;
        }
        else if (this.entity.description)
        {
            const descriptionFieldStore = this.entity.entityType.getInheritedDescriptionField();

            return this.entity.description &&
                descriptionFieldStore &&
                descriptionFieldStore.dataObjectSpecification.data.isRichText &&
                new ReactViewComponent(
                    RichText,
                    {
                        value: this.entity.description
                    });
        }
        else
        {
            return undefined;
        }
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getLabel(): string
    {
       if (this.isActivity)
       {
           const linkedActivities =
               this.owners.filter(
                   entity =>
                       entity.entityType.isA(this.entityTypeStore.bespoke.types.Activity.Type));

           // Do not show other owners in the card itself
           const otherOwners =
               this.owners.filter(
                   entity =>
                       !entity.entityType.isA(this.entityTypeStore.bespoke.types.Activity.Type));

           let owners = [...otherOwners, ...linkedActivities ];

           if (owners.length > 0)
           {
               return `${
                   owners
                       .map(
                           (entity, idx) =>
                               `${entity.entityType.isA(this.entityTypeStore.bespoke.types.Activity.Type)
                                   ? localizeText('Generic.For', 'voor')
                                   : localizeText('Generic.By', 'bij')
                                } %${idx}`).join(`${this.separator} `)}`;
           }
       }
       else if (this.isPersonRelationship)
       {
           // In the case that the list that this caption resides in only contains employees,
           // then do not return a caption (because we already know it is an employee)
           if (this.listType
               && this.listType.isA(this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Employee.Type)
               && this.isEmployee)
           {
               return undefined;
           }

           if (this.owners.length > 0)
           {
               if (this.owners[0].entityType !== this.entityTypeStore.bespoke.types.Relation.Organization.Environment.Type)
               {
                   return `${this.entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Field.FunctionOnBusinessCard) || this.entity.entityType.nameSingular} van %0`;
               }
               else
               {
                   return `${this.entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Field.FunctionOnBusinessCard) || this.entity.entityType.nameSingular}`;
               }
           }
       }
       else if (this.isOrganizationRelationship)
       {
           if (this.owners.length > 0)
           {
               if (this.owners[0].entityType !== this.entityTypeStore.bespoke.types.Relation.Organization.Environment.Type)
               {
                   return localizeText(
                       'TypenameOfIndex',
                       '${typeName} van %0',
                       {
                           typeName: this.entity.entityType.nameSingular
                       }
                   )
               }
               else
               {
                   return `${this.entity.entityType.nameSingular}`;
               }
           }
           else
           {
               return `${this.entity.entityType.nameSingular}`;
           }
       }
       else if (this.owners.length > 0 && this.showOwnersByDefault)
       {
           return localizedText('Generic.of', 'van') + ` ${this.owners.map((owner, idx) => `%${idx}`).join(`${this.separator} `)}`;
       }
       else if (this.entity.description)
       {
           return this.entity.description;
       }
    }

    getChildTextStores(): TextStore[]
    {
        if (this.isActivity)
        {
            if (this.owners.length > 0)
            {
                // Enforce ordering of relationship first, and then the linked activities
                const linkedActivities =
                    this.owners.filter(
                        entity =>
                            entity.entityType.isA(this.entityTypeStore.bespoke.types.Activity.Type));

                // Do not show other owners in the card itself
                const otherOwners =
                    this.owners.filter(
                        entity =>
                            !entity.entityType.isA(this.entityTypeStore.bespoke.types.Activity.Type));

                return [...otherOwners, ...linkedActivities ]
                    .map(
                        owner =>
                        {
                            const ownerTextStore =
                                new EntityCaptionLinkStore({
                                    entity: owner,
                                    onVisit: this.props.onVisit,
                                    variant: this.variant,
                                    color: this.color,
                                    isInline: true
                                });

                            if (owner === this.relationship && this.pathFromRelatedEntity && this.pathFromRelatedEntity.entity === this.entity)
                            {
                                // Only show contact in card
                                if (this.contact)
                                {
                                    const contactTextStore =
                                        new EntityCaptionLinkStore({
                                            entity: this.contact,
                                            onVisit: this.props.onVisit,
                                            variant: this.variant,
                                            color: this.color,
                                            isInline: true
                                        });

                                    return new TextStore({
                                        label:
                                            () =>
                                                localizeText(
                                                    'EntityCaptionValueOf',
                                                    '%0${separator} ${value} van %1',
                                                    {
                                                        separator: this.separator,
                                                        value: this.contact.getObjectValueByField(this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Field.FunctionOnBusinessCard) || ''
                                                    }
                                                ),
                                        variant: this.variant,
                                        color: this.color,
                                        isInline: true,
                                        childTextStores:
                                            [
                                                contactTextStore,
                                                ownerTextStore
                                            ]
                                    });
                                }
                                else
                                {
                                    return ownerTextStore;
                                }
                            }
                            else if (owner === this.contact)
                            {
                                return new TextStore({
                                    label:
                                        () =>
                                            `%0 ${this.contact.getObjectValueByField(this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Field.FunctionOnBusinessCard) || ''}`,
                                    variant: this.variant,
                                    color: this.color,
                                    isInline: true,
                                    childTextStores:
                                        [
                                            ownerTextStore
                                        ]
                                });
                            }
                            else
                            {
                                return ownerTextStore;
                            }
                        });
            }
        }
        else if (this.isPersonRelationship)
        {
            if (this.owners.length > 0)
            {
                if (this.owners[0].entityType !== this.entityTypeStore.bespoke.types.Relation.Organization.Environment.Type)
                {
                    return [
                        new EntityCaptionLinkStore({
                            entity: this.owners[0],
                            onVisit: this.props.onVisit,
                            variant: this.variant,
                            color: this.color,
                            isInline: true
                        })
                    ];
                }
            }
        }
        else if (this.isOrganizationRelationship)
        {
            if (this.owners.length > 0)
            {
                if (this.owners[0].entityType !== this.entityTypeStore.bespoke.types.Relation.Organization.Environment.Type)
                {
                    return [
                        new EntityCaptionLinkStore({
                            entity: this.owners[0],
                            onVisit: this.props.onVisit,
                            variant: this.variant,
                            color: this.color,
                            isInline: true
                        })
                    ];
                }
            }
        }
        else if (this.owners.length > 0)
        {
            return this.owners.map(
                owner =>
                    new EntityCaptionLinkStore({
                        entity: owner,
                        onVisit: this.props.onVisit,
                        variant: this.variant,
                        color: this.color,
                        isInline: true
                    }));
        }
        else if (this.entity.description)
        {
            return [];
        }

        return [];
    }

    // ----------------------- Private logic ------------------------
}
