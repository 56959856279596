import { AssistantChart } from '../Model/Chart/AssistantChart';
import Chart from '../../Entity/Chart/Model/Chart';
import { findAssistantEntityTypeOrThrow } from './findAssistantEntityTypeOrThrow';
import DataSeries from '../../Entity/Chart/Model/DataSeries';
import uuid from '../../../../@Util/Id/uuid';
import { findAssistantFieldPath } from './findAssistantFieldPath';
import { EntityPath } from '../../Entity/Path/@Model/EntityPath';
import { EntityFieldPath } from '../../Entity/Path/@Model/EntityFieldPath';
import AggregateValue from '../../Entity/View/Model/Column/AggregateValue';
import { findAssistantAggregate } from './findAssistantAggregate';
import Predicate from '../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { findAssistantPredicate } from './Predicate/findAssistantPredicate';
import getViewParameters from '../../Entity/View/Api/getViewParameters';
import { DateType } from '../../DataObject/Type/Date/DateType';
import { DataObject } from '../../DataObject/Model/DataObject';
import { DateIntervalValue } from '../../DataObject/Type/Date/DateInterval/DateIntervalValue';
import Dataset from '../../Entity/Dataset/Model/Dataset';
import Segment from '../../Entity/Dataset/Model/Segment';
import FilterOption from '../../Entity/Dataset/Model/FilterOption';
import { Aggregate } from '../../DataObject/Model/Aggregate';
import getTypes from '../../Entity/Type/Api/getTypes';
import { ViewParams } from '../../Entity/View/Model/ViewParams';
import { findAssistantAggregateFieldPath } from './findAssistantAggregateFieldPath';
import { findAssistantSegmentFieldPath } from './findAssistantSegmentFieldPath';

export function findAssistantDatasetByChart(
    assistantChart: AssistantChart
): Dataset
{
    const types = getTypes();
    const entityType = findAssistantEntityTypeOrThrow(assistantChart.entityType);
    const rootPath = EntityPath.fromEntityType(entityType);
    const xFieldPath =
        findAssistantSegmentFieldPath(
            rootPath,
            assistantChart.xField
        );
    let yFieldPath =
        findAssistantAggregateFieldPath(
            rootPath,
            assistantChart.yField
        );
    let ySegmentFieldPath: EntityFieldPath | undefined;

    if (assistantChart.ySegmentField)
    {
        ySegmentFieldPath =
            findAssistantSegmentFieldPath(
                rootPath,
                assistantChart.ySegmentField
            );
    }

    const yAggregate = findAssistantAggregate(assistantChart.yAggregate);

    if (yAggregate === Aggregate.Count
        && !yFieldPath.isField)
    {
        yFieldPath = yFieldPath.path.field(types.Entity.Field.Id);
    }

    const parameters = getViewParameters(entityType);
    const resultParameter = parameters.getParameterById(ViewParams.Entity);
    let filter: Predicate | undefined;

    if (assistantChart.filter)
    {
        filter =
            findAssistantPredicate(
                assistantChart.filter,
                parameters,
                resultParameter
            );
    }

    const isXDateType = xFieldPath.field?.dataObjectSpecification.type instanceof DateType;
    const yAggregateValue =
        new AggregateValue(
            yFieldPath,
            yAggregate
        );
    const chart =
        new Chart(
            entityType,
            parameters,
            [
                new DataSeries(
                    uuid(),
                    isXDateType
                        ? 'Bar'
                        : 'Pie',
                    yFieldPath.getName(),
                    false,
                    false,
                    xFieldPath,
                    yAggregateValue,
                    ySegmentFieldPath
                )
            ],
            filter,
            undefined,
            isXDateType
                ? DataObject.constructFromTypeIdAndValue(
                    'DateInterval',
                    {
                        type: 'Monthly',
                        frequency: 1,
                    } as DateIntervalValue
                )
                : undefined
        );

    return new Dataset(
        chart.entityType,
        resultParameter,
        chart.parameters,
        [
            isXDateType
                ? new Segment(
                    uuid(),
                    'Chart',
                    xFieldPath,
                    undefined,
                    [
                        new FilterOption(
                            uuid(),
                            xFieldPath,
                            true,
                            true
                        )
                    ],
                    chart
                )
                : new Segment(
                    uuid(),
                    'Bar',
                    xFieldPath,
                    [
                        yAggregateValue
                    ],
                    undefined,
                    undefined,
                    ySegmentFieldPath
                ),
            ...ySegmentFieldPath
                ? [
                    new Segment(
                        uuid(),
                        'Tile',
                        ySegmentFieldPath,
                        [
                            yAggregateValue
                        ]
                    )
                ]
                : []
        ],
        chart.filter
    );
}
