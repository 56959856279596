import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../../Editor/LayoutEditor';
import FunctionContext from '../../../../../../../@Api/Automation/Function/FunctionContext';
import { runInAction } from 'mobx';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import FormInputLayout from '../../../../../../../@Api/Layout/Type/Form/Input/FormInputLayout';
import PredicateEditor from '../../../../../Entity/Viewer/Content/Automation/Editor/Predicate/PredicateEditor';
import Predicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';

export interface FormInputIsDisabledEditorProps extends LayoutEditorProps<FormInputLayout>
{

}

const FormInputIsDisabledEditor: React.FC<FormInputIsDisabledEditorProps> =
    props =>
    {
        const context =
            useComputed(
                () =>
                    new FunctionContext(
                        props.parameterDictionary,
                        props.parameterAssignment,
                        props.commitContext
                    ),
                [
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        const setDisabled =
            useCallback(
                (value: Predicate) =>
                    runInAction(
                        () =>
                            props.layout.isDisabled = value),
                [
                    props.layout
                ]);

        return <Input
            labelPosition="left"
            label={
                <LocalizedText
                    code="Generic.Disabled"
                    value="Gedeactiveerd"
                />
            }
        >
            <PredicateEditor
                value={props.layout.isDisabled}
                onChange={setDisabled}
                context={context}
            />
        </Input>;
    };

export default observer(FormInputIsDisabledEditor);
