import { observable } from 'mobx';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import AggregateValue from '../../View/Model/Column/AggregateValue';
import Series, { SeriesType } from './Series';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';

export default class DataSeries extends Series
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable type: SeriesType;
    @observable.ref xFieldPath: EntityFieldPath;
    @observable.ref yAggregateValue: AggregateValue;
    @observable.ref ySegmentFieldPath?: EntityFieldPath;
    @observable.ref intervalFieldPath?: EntityFieldPath;
    @observable.ref filter?: Predicate;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                type: SeriesType,
                name: string,
                isCumulative: boolean,
                isHidden: boolean,
                xFieldPath: EntityFieldPath,
                yAggregateValue: AggregateValue,
                ySegmentFieldPath?: EntityFieldPath,
                intervalFieldPath?: EntityFieldPath,
                filter?: Predicate)
    {
        super(id, type, name, isCumulative, isHidden);

        this.xFieldPath = xFieldPath;
        this.yAggregateValue = yAggregateValue;
        this.ySegmentFieldPath = ySegmentFieldPath;
        this.intervalFieldPath = intervalFieldPath;
        this.filter = filter;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new DataSeries(
            descriptor.id,
            descriptor.type,
            descriptor.name,
            descriptor.isCumulative,
            descriptor.isHidden,
            EntityFieldPath.construct(descriptor.xFieldPath),
            AggregateValue.fromDescriptor(descriptor.yAggregateValue),
            EntityFieldPath.construct(descriptor.ySegmentFieldPath),
            descriptor.intervalFieldPath
                ?
                    EntityFieldPath.construct(descriptor.intervalFieldPath)
                :
                    undefined);
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            xFieldPath: this.xFieldPath.descriptor,
            yAggregateValue: this.yAggregateValue.toDescriptor(),
            ySegmentFieldPath:
                this.ySegmentFieldPath
                    ?
                        this.ySegmentFieldPath.descriptor
                    :
                        undefined,
            intervalFieldPath:
                this.intervalFieldPath
                    ?
                        this.intervalFieldPath.descriptor
                    :
                        undefined
        };
    }

    // ----------------------- Private logic ------------------------
}
