export default function reloadWindow(doForce: boolean = false)
{
    if (window.location)
    {
        setTimeout(
            () =>
                (window.location as any).reload(doForce),
            0);
    }
}
