import Validation from '../../../Automation/Validation/Validation';
import Dependency from '../../../Automation/Parameter/Dependency';
import Value from '../../../Automation/Value/Value';
import LayoutAction from '../LayoutAction';
import LayoutDependencyContext from '../../LayoutDependencyContext';
import { observable } from 'mobx';
import ValueType from '../../../Automation/Value/Type/ValueType';
import localizeText from '../../../Localization/localizeText';
import uuid from '../../../../@Util/Id/uuid';
import PortalDataSourceSignature from '../../../Portal/DataSource/PortalDataSourceSignature';
import Computation from '../../../Automation/Function/Computation/Computation';
import Parameter from '../../../Automation/Parameter/Parameter';
import getPortalDataSourceSignatureById from '../../../Portal/DataSource/Api/getPortalDataSourceSignatureById';
import getComputationFromDescriptor from '../../../Automation/Api/getComputationFromDescriptor';
import EmptyValue from '../../../Automation/Value/EmptyValue';
import safelyApplyFunction from '../../../Automation/Api/safelyApplyFunction';
import DataSourceValue from '../../../Automation/Value/DataSourceValue';
import downloadPortalDataSourceFile from '../../../Portal/DataSource/Api/downloadPortalDataSourceFile';
import PortalDataSourceFileQuery from '../../../Portal/DataSource/PortalDataSourceFileQuery';
import EmptyValueType from '../../../Automation/Value/Type/EmptyValueType';
import LayoutContext from '../../LayoutContext';

export default class PortalDataSourceFileDownloadAction extends LayoutAction
{
    // ------------------------- Properties -------------------------

    @observable.ref dataSourceSignature: PortalDataSourceSignature;
    @observable.ref record: Computation<any, any>;
    @observable.ref fileField: Parameter<any>;

    // ------------------------ Constructor -------------------------

    constructor(dataSourceSignature: PortalDataSourceSignature,
                record: Computation<any, any>,
                fileField: Parameter<any>)
    {
        super();

        this.dataSourceSignature = dataSourceSignature;
        this.record = record;
        this.fileField = fileField;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async apply(context: LayoutContext): Promise<Value<any, any>>
    {
        const record = await safelyApplyFunction(this.record, context);

        if (record instanceof DataSourceValue)
        {
            downloadPortalDataSourceFile(
                new PortalDataSourceFileQuery(
                    uuid(),
                    this.dataSourceSignature,
                    record.value.dataSourceParameterAssignment,
                    record.value.id,
                    this.fileField
                )
            );
        }

        return EmptyValue.instance;
    }

    getReturnType(): ValueType<any>
    {
        return EmptyValueType.instance;
    }

    getName(): string
    {
        return localizeText(
            'LayoutAction.PortalDataSourceFileDownload.Download',
            '${field} downloaden',
            {
                field: this.fileField.name
            });
    }

    getDependencies(): Dependency[]
    {
        return this.record.getDependencies();
    }

    validate(): Validation[]
    {
        return this.record.validate();
    }

    toDescriptor()
    {
        return {
            type: 'PortalDataSourceFileDownload',
            dataSourceId: this.dataSourceSignature.id,
            record: this.record.toDescriptor(),
            fileFieldId: this.fileField.id
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const dataSourceId = descriptor.dataSourceId;
        const dataSourceSignature = await getPortalDataSourceSignatureById(dataSourceId);
        const record =
            await getComputationFromDescriptor(
                descriptor.record,
                dependencyContext);
        const fileField =
            dataSourceSignature.resultParameters
                .getParameterById(descriptor.fileFieldId);

        return new PortalDataSourceFileDownloadAction(
            dataSourceSignature,
            record,
            fileField);
    }

    // ----------------------- Private logic ------------------------
}
