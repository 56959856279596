import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import styles from './Item.module.scss';
import Description from './Description/Description';
import DateView from '../../../../../@Future/Component/Generic/DateView/DateView';
import Mutator from './Description/Mutator/Mutator';
import { classNames } from '../../../../../@Future/Util/Class/classNames';
import localizeText from '../../../../../@Api/Localization/localizeText';

export interface ItemProps
{
    event: EntityEvent;
    showMutator?: boolean;
    forceEntityDescription?: boolean;
    large?: boolean;
}

const Item: React.FC<ItemProps> =
    props =>
    {
        const descriptionClassNames =
            useMemo(
                () =>
                    classNames(styles.description, props.large && styles.large),
                [
                    props.large
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={2}
        >
            <ViewGroupItem
                className={descriptionClassNames}
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={5}
                    alignment="end"
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        {
                            props.showMutator &&
                                <>
                                    <Mutator event={props.event} /> {localizeText('TimeLine.AuditTrail.Has', 'heeft')}&nbsp;
                                </>
                        }
                        <Description
                            event={props.event}
                            forceEntityDescription={props.forceEntityDescription}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <DateView
                            date={props.event.date}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(Item);
