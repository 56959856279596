export function concatMaps<K, V>(
    mapA: Map<K, V>,
    mapB: Map<K, V>
)
{
    const map = new Map(mapA);
    mapB.forEach(
        (value, key) =>
            map.set(
                key,
                value
            )
    );

    return map;
}