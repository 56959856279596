import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import { findAssistantComputation } from './findAssistantComputation';
import { AssistantGetFileNameComputation } from '../../Model/Automation/Computation/AssistantGetFileNameComputation';
import NameFromFileComputation from '../../../../../@Api/Automation/Function/Computation/NameFromFileComputation';

export function findAssistantGetFileNameComputation(
    computation: AssistantGetFileNameComputation,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Computation<any, any>
{
    const file =
        findAssistantComputation(
            computation.file,
            parameters,
            rootParameter
        );

    return new NameFromFileComputation(file);
}
