import useTypes from '../../../../@Component/Domain/Entity/Type/Api/useTypes';
import { useMemo } from 'react';
import { EntityPath } from '../../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityType } from '../../../Model/Implementation/EntityType';

export function useChildRelationPath(entityType: EntityType): EntityPath | undefined
{
    const types = useTypes();

    return useMemo(
        () =>
        {
            if (entityType.isA(types.Relationship.Organization.Type))
            {
                return EntityPath.root(entityType)
                    .joinTo(
                        types.Relationship.Organization.RelationshipDefinition.Organization,
                        false
                    );
            }
            else if (entityType.isA(types.Relationship.Person.Type))
            {
                return EntityPath.root(entityType)
                    .joinTo(
                        types.Relationship.Person.RelationshipDefinition.Person,
                        false
                    );
            }
            else
            {
                return undefined;
            }
        },
        [
            entityType,
            types,
        ]
    );
}
