import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ExternalEmail from '../Model/ExternalEmail';
import styles from './ComposeExternalEmail.module.scss';
import SectionLabel from '../../../../../../../../@Future/Component/Generic/Label/Variant/SectionLabel/SectionLabel';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import LinkAddressee from './Addressee/LinkAddressee';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Centered from '../../../../../../../../@Future/Component/Generic/Centered/Centered';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { computed, observable, runInAction } from 'mobx';
import Addressee from '../Model/Addressee';
import useTypes from '../../../../../Type/Api/useTypes';
import getDatastoreByCode from '../../../../../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import Input from '../../../../../Input/Input';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';
import IconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import LocalizerContext from '../../../../../../../../@Service/Localization/LocalizerContext';
import FieldView from '../../../../../Field/View/FieldView';
import useRelationshipByAddressee from '../Api/useRelationshipByAddressee';
import DangerButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/DangerButton/DangerButton';
import SuccessButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/SuccessButton/SuccessButton';
import useRelationshipAddresseeAugmenter from '../Api/useRelationshipAddresseeAugmenter';
import useRelationshipSelectorProps from '../Api/useRelationshipSelectorProps';
import InputGroup from '../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import deleteEntity from '../../../../../../../../@Api/Entity/deleteEntity';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import { useNewCommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { constructEntityOfType } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { setValueByFieldInEntity } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { updateRelationship } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import commitEntity from '../../../../../../../../@Api/Entity/Commit/commitEntity';
import { canRefreshComposeItem, refreshComposeItem, storeEntityIdInItem } from '../../../../../../../App/Root/Environment/Public/AddIn/OutlookAddin';

export interface ComposeExternalEmailProps
{
    key: string;
    externalEmail: Partial<ExternalEmail>;
}

const ComposeExternalEmail: React.FC<ComposeExternalEmailProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();
        const commitContext = useNewCommitContext();
        const [ recipientEntityByEmailAddress ] =
            useState(
                () =>
                    observable.map<string, Entity>(
                        [],
                        { deep: false }));
        const [ email, setEmail ] = useState<Entity | undefined>(undefined);
        const startTracking =
            useCallback(
                async () =>
                {
                    runInAction(
                        () =>
                            recipientEntityByEmailAddress.clear());

                    const email =
                        constructEntityOfType(
                            types.Activity.Email.Type,
                            commitContext
                        );
                    setValueByFieldInEntity(
                        email,
                        types.Activity.Email.Field.SendDate,
                        new Date(),
                        commitContext
                    );

                    await getDatastoreByCode(types.Datastore.Phase.ActivityEmail.Type, 'Sent')
                        .then(
                            sentPhase =>
                                updateRelationship(
                                    email,
                                    false,
                                    types.Activity.Email.RelationshipDefinition.Phase,
                                    sentPhase,
                                    commitContext
                                )
                        );

                    const sender =
                        constructEntityOfType(
                            types.Recipient.Email.Type,
                            commitContext
                        );
                    updateRelationship(
                        email,
                        false,
                        types.Activity.Email.RelationshipDefinition.From,
                        sender,
                        commitContext
                    );
                    updateRelationship(
                        sender,
                        false,
                        types.Recipient.RelationshipDefinition.Addressee,
                        currentUserStore.employeeEntity,
                        commitContext
                    );
                    setValueByFieldInEntity(
                        sender,
                        types.Recipient.Email.Field.EmailAddress,
                        props.externalEmail.sender?.email
                        ||
                        currentUserStore.employeeEntity.getObjectValueByField(types.Relationship.Person.Contact.Field.EmailAddress),
                        commitContext
                    );
                    setEmail(email);

                    return refreshComposeItem(
                        true,
                        true,
                        true,
                        true
                    );
                },
                [
                    recipientEntityByEmailAddress,
                    types,
                    setEmail,
                    commitContext,
                    currentUserStore,
                ]
            );
        const stopTracking =
            useCallback(
                () =>
                    deleteEntity(email, commitContext)
                        .then(
                            () =>
                                setEmail(undefined)
                        ),
                [
                    email,
                    commitContext,
                    setEmail
                ]
            );
        const isTracking =
            useMemo(
                () =>
                    email !== undefined,
                [
                    email
                ]
            );
        const addresseeToLookupRelationship =
            useMemo(
                () =>
                    props.externalEmail.recipients?.find(() => true),
                [
                    props.externalEmail
                ]
            );
        const [ relationship, isLoadingRelationship ] = useRelationshipByAddressee(addresseeToLookupRelationship);

        useEffect(
            () =>
            {
                if (!isLoadingRelationship
                    && email)
                {
                    updateRelationship(
                        email,
                        true,
                        types.Relationship.RelationshipDefinition.Activities,
                        relationship,
                        commitContext
                    );

                    commitEntity(
                        email,
                        {
                            context: commitContext,
                            isForced: true,
                        }
                    );
                }
            },
            [
                isLoadingRelationship,
                email,
                relationship,
                types,
                commitContext,
            ]
        );
        const onLink =
            useCallback(
                (addressee: Addressee, recipientEntity: Entity) =>
                    recipientEntityByEmailAddress.set(
                        addressee.email,
                        recipientEntity
                    ),
                [
                    recipientEntityByEmailAddress
                ]
            );

        useEffect(
            () =>
            {
                if (email)
                {
                    setValueByFieldInEntity(
                        email,
                        types.Activity.Field.Subject,
                        props.externalEmail.subject,
                        commitContext
                    );
                    setValueByFieldInEntity(
                        email,
                        types.Activity.Email.Field.HTML,
                        props.externalEmail.body,
                        commitContext
                    );

                    commitEntity(
                        email,
                        {
                            context: commitContext,
                            isForced: true,
                            isDeferred: true,
                        }
                    );
                }
            },
            [
                email,
                types,
                props.externalEmail,
                commitContext,
            ]);

        useEffect(
            () =>
            {
                if (email)
                {
                    const interval =
                        setInterval(
                            () =>
                            {
                                commitEntity(
                                    email,
                                    {
                                        context: commitContext,
                                        isForced: true,
                                    }
                                );
                            },
                            5000);

                    return () =>
                        clearInterval(interval);
                }
            },
            [
                email,
                commitContext,
            ]);
        const glue = useDividerGlue();
        const recipients =
            useMemo(
                () =>
                    props.externalEmail.recipients ?? [],
                [
                    props.externalEmail
                ]
            );
        const isContactVisible =
            useComputed(
                () =>
                    email &&
                        !email.entityType.bespoke.hideFieldPath(
                            email,
                            EntityPath.fromEntity(email)
                                .joinTo(
                                    types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                    true)
                                .field()
                        ),
                [
                    email,
                    types,
                ]
            );
        const onConstruct =
            useRelationshipAddresseeAugmenter(
                addresseeToLookupRelationship,
                commitContext,
                email
            );
        const relationshipSelectorProps =
            useRelationshipSelectorProps(
                relationship,
                isLoadingRelationship,
                addresseeToLookupRelationship
            );

        useEffect(
            () =>
                computed(() => email?.isNew())
                    .observe(
                        change =>
                        {
                            if (change.newValue)
                            {
                                storeEntityIdInItem(email.uuid)
                                    .then(
                                        () =>
                                            refreshComposeItem(true, true, true, true));
                            }
                        }
                    ),
            [
                email
            ]
        );
        const localizer = useContext(LocalizerContext);

        return <div
            className={styles.root}
        >
            <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={10}
                        alignment="center"
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                           <SectionLabel>
                                <LocalizedText
                                    code="ExternalEmailRegister"
                                    value="E-mail vastleggen"
                                />
                            </SectionLabel>
                        </ViewGroupItem>
                        {
                            isTracking &&
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={0}
                                    >
                                        <ViewGroupItem>
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={5}
                                                alignment="center"
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                />
                                                {
                                                    canRefreshComposeItem() &&
                                                        <ViewGroupItem
                                                            className={styles.syncLabel}
                                                        >
                                                            <LocalizedText
                                                                code="ExternalEmailLastSync"
                                                                value="Laatste sync. om ${time}"
                                                                time={localizer.formatTime(props.externalEmail.syncDate, true)}
                                                            />
                                                        </ViewGroupItem>
                                                }
                                                {
                                                    canRefreshComposeItem() &&
                                                        <ViewGroupItem>
                                                            <IconButton
                                                                icon="refresh"
                                                                onClick={() => refreshComposeItem(true, true, true, true)}
                                                                tooltip={
                                                                    <LocalizedText
                                                                        code="ExternalEmailSyncContents"
                                                                        value="Inhoud synchronizeren met e-mail"
                                                                    />
                                                                }
                                                            />
                                                        </ViewGroupItem>
                                                }
                                            </ViewGroup>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                </ViewGroupItem>
                {
                    isTracking &&
                        <ViewGroupItem>
                            <Card
                                inset
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={0}
                                >
                                    <ViewGroupItem>
                                        <InputGroup>
                                            <Input
                                                entity={email}
                                                field={types.Relationship.RelationshipDefinition.Activities}
                                                parent
                                                labelPosition="left"
                                                doAutocommit
                                                onConstruct={onConstruct}
                                                relationshipSelectboxProps={relationshipSelectorProps}
                                                commitContext={commitContext}
                                            />
                                            {
                                                isContactVisible &&
                                                    <Input
                                                        entity={email}
                                                        field={types.Relationship.Person.Contact.RelationshipDefinition.Activities}
                                                        parent
                                                        labelPosition="left"
                                                        doAutocommit
                                                        onConstruct={onConstruct}
                                                        commitContext={commitContext}
                                                    />
                                            }
                                            <Input
                                                entity={email}
                                                field={types.Activity.RelationshipDefinition.LinkedActivities}
                                                parent
                                                labelPosition="left"
                                                doAutocommit
                                                commitContext={commitContext}
                                            />
                                            <Input
                                                entity={email}
                                                field={types.Activity.Field.Subject}
                                                labelPosition="left"
                                                doAutocommit
                                                commitContext={commitContext}
                                            />
                                        </InputGroup>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </Card>
                        </ViewGroupItem>
                }
                {
                    isTracking &&
                        <ViewGroupItem>
                            <SectionLabel>
                                <LocalizedText
                                    code="ExternalEmailRecipients"
                                    value="Ontvangers"
                                />
                            </SectionLabel>
                        </ViewGroupItem>
                }
                {
                    isTracking &&
                        <ViewGroupItem>
                            {
                                recipients.length === 0 &&
                                    <Card
                                        inset
                                    >
                                        <Centered
                                            horizontal
                                        >
                                            <LocalizedText
                                                code="ExternalEmailNoRecipients"
                                                value="Nog géén ontvangers geregistreerd"
                                            />
                                        </Centered>
                                    </Card>
                            }
                            {
                                recipients.length > 0 &&
                                    <Card>
                                        <ViewGroup
                                            orientation="vertical"
                                            spacing={0}
                                            glue={glue}
                                        >
                                            {
                                                recipients.map(
                                                    recipient =>
                                                        <ViewGroupItem
                                                            key={recipient.email}
                                                        >
                                                            <LinkAddressee
                                                                email={email}
                                                                addressee={recipient}
                                                                linkedRecipient={recipientEntityByEmailAddress.get(recipient.email)}
                                                                onLink={onLink}
                                                                commitContext={commitContext}
                                                            />
                                                        </ViewGroupItem>)
                                            }
                                        </ViewGroup>
                                    </Card>
                            }
                        </ViewGroupItem>
                }
                {
                    isTracking &&
                        <ViewGroupItem>
                            <SectionLabel>
                                <LocalizedText
                                    code="ExternalEmailContents"
                                    value="Inhoud"
                                />
                            </SectionLabel>
                        </ViewGroupItem>
                }
                {
                    isTracking &&
                        <ViewGroupItem>
                            <Card
                                inset
                            >
                                <FieldView
                                    entity={email}
                                    field={types.Activity.Email.Field.HTML}
                                    richText
                                />
                            </Card>
                        </ViewGroupItem>
                }
                {
                    isTracking &&
                        <ViewGroupItem>
                            <DangerButton
                                label={
                                    <LocalizedText
                                        code="ExternalEmailDelete"
                                        value="E-mail verwijderen"
                                    />
                                }
                                onClick={stopTracking}
                                fullWidth
                            />
                        </ViewGroupItem>
                }
                {
                    !isTracking &&
                        <ViewGroupItem>
                            <SuccessButton
                                label={
                                    <LocalizedText
                                        code="ExternalEmailStoreInTribe"
                                        value="E-mail vastleggen in Tribe"
                                    />
                                }
                                onClick={startTracking}
                                fullWidth
                            />
                        </ViewGroupItem>
                }
            </ViewGroup>
        </div>;
    };

export default observer(ComposeExternalEmail);
