import * as React from 'react';
import { Grid, Theme, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../../../../@Framework/Component/BaseComponent';
import { EntityPathEditorModalStore } from './EntityPathEditorModalStore';
import Menu from '../../../../../../Generic/Menu/Menu';
import Text from '../../../../../../Generic/Text/Text';

const styles =
    (theme: Theme) =>
        ({

        });

@observer
class EntityPathEditorModal extends BaseComponent<EntityPathEditorModalStore>
{
    renderLoader(store: EntityPathEditorModalStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: EntityPathEditorModalStore)
    {
        return <Grid container>
            <Grid item xs={12}>
                <Text store={store.labelStore} />
                {
                    store.entityPath
                        ?
                            store.entityPath.getName(store.entityTypeStore)
                        :
                            'Geen'
                }
            </Grid>
            <Grid  item xs={12}>
                {
                    !store.isValid &&
                        <Text store={store.validationText}/>
                }
            </Grid>
            <Grid item xs={12}>
                <Menu store={store.entityPathPickerStore} />
            </Grid>
        </Grid>;
    }
}

export default withStyles(styles)(EntityPathEditorModal as any);
