import { ComputationEditorStore } from '../../../ComputationEditorStore';
import { action, computed, observable } from 'mobx';
import { ComputationContext } from '../../../ComputationContext';
import { ComputationType } from '../../ComputationType';
import { ComputationSpecification } from '../../ComputationSpecification';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../../../../DataObject/DataObjectStore';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import { EntityFieldPathPickerStore } from '../../../../Entity/Path/Field/EntityFieldPathPickerStore';
import { EntityTypeStore } from '../../../../Entity/Type/EntityTypeStore';

export class EntityFieldComputationEditorStore extends ComputationEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;

    // ------------------------- Properties -------------------------

    @observable entityContextParameter: string;
    @observable entityFieldPath: EntityFieldPath;
    @observable isPathEditorOpen: boolean;

    // ------------------------ Constructor -------------------------

    constructor(type: ComputationType,
                context: ComputationContext,
                specification: ComputationSpecification,
                entityContextParameter: string,
                entityFieldPath: EntityFieldPath)
    {
        super(type, context, specification);

        this.entityContextParameter = entityContextParameter;
        this.entityFieldPath = entityFieldPath;
        this.isPathEditorOpen = entityFieldPath == null;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get entityFieldPathPickerStore(): EntityFieldPathPickerStore
    {
        return new EntityFieldPathPickerStore(
            this.context.entityContext,
            this.setPath);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    openPathEditor()
    {
        this.isPathEditorOpen = true;
    }

    @action.bound
    closePathEditor()
    {
        this.isPathEditorOpen = false;
    }

    @action.bound
    setPath(path: EntityFieldPath,
            parameter?: string)
    {
        this.entityContextParameter = parameter;
        this.entityFieldPath = path;
        this.closePathEditor();
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
