import Value from './Value';
import ValueType from './Type/ValueType';
import { FileReporter } from '../../../@Component/Domain/DataObject/Model/DataDescriptor';
import FunctionContext from '../Function/FunctionContext';

export default class DeferredValue<T> extends Value<Value<T, ValueType<any>>[], ValueType<T>>
{
    // ------------------------- Properties -------------------------

    descriptor: any;
    valueType: ValueType<any>;
    idFromDescriptor: (descriptor: any) => string;
    idFromValue: (value: Value<any, any>) => string;
    initialize: (descriptors: any[]) => Promise<Value<any, any>[]>;
    initializedValue?: Value<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(
        descriptor: any,
        valueType: ValueType<any>,
        idFromDescriptor: (descriptor: any) => string,
        idFromValue: (value: Value<any, any>) => string,
        initialize: (descriptors: any[]) => Promise<Value<any, any>[]>,
        initializedValue?: Value<any, any>
    )
    {
        super(undefined);

        this.descriptor = descriptor;
        this.valueType = valueType;
        this.idFromDescriptor = idFromDescriptor;
        this.idFromValue = idFromValue;
        this.initialize = initialize;
        this.initializedValue = initializedValue;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getId(): string
    {
        if (this.initializedValue)
        {
            return this.initializedValue.getId();
        }
        else
        {
            return `DeferredValue(${this.valueType.id()}:${JSON.stringify(this.descriptor)})`;
        }
    }

    getType(): ValueType<T>
    {
        if (this.initializedValue)
        {
            return this.initializedValue.getType();
        }
        else
        {
            return this.valueType;
        }
    }

    async apply(context: FunctionContext)
    {
        return this.initializedValue ?? this;
    }

    synchronousApply(context: FunctionContext)
    {
        return this.initializedValue ?? this;
    }

    getName(): string
    {
        if (this.initializedValue)
        {
            return this.initializedValue.getName();
        }
        else
        {
            return this.getId();
        }
    }

    equals(otherValue: Value<any, any>)
    {
        if (this.initializedValue)
        {
            return this.initializedValue.equals(otherValue);
        }
        else
        {
            return otherValue instanceof DeferredValue
                && this.getId() === otherValue.getId();
        }
    }

    clone(): DeferredValue<T>
    {
        return new DeferredValue(
            this.descriptor,
            this.valueType,
            this.idFromDescriptor,
            this.idFromValue,
            this.initialize,
            this.initializedValue?.clone()
        );
    }

    augmentDescriptor(
        descriptor: any,
        onFile?: FileReporter
    )
    {
        if (this.initializedValue)
        {
            return this.initializedValue.augmentDescriptor(
                descriptor,
                onFile
            );
        }
        else
        {
            Object.assign(
                descriptor,
                this.descriptor
            );
        }
    }

    // ----------------------- Private logic ------------------------
}
