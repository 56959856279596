import { action, decorate, observable } from 'mobx';
import { Id } from '../../../Util/Id/Id';

export default class ExpansionPanelContainer
{
    // ------------------------- Properties -------------------------

    expandedItemId?: Id;

    // ------------------------ Constructor -------------------------

    constructor(expandedItemId?: Id)
    {
        this.expandedItemId = expandedItemId;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    setExpandedItemId(id?: Id)
    {
        this.expandedItemId = id;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}

decorate(
    ExpansionPanelContainer,
    {
        expandedItemId: observable,
        setExpandedItemId: action
    });
