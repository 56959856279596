import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { User } from '../../../../../@Api/Model/Implementation/User';
import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';
import { useComputed } from 'mobx-react-lite';
import moment from 'moment';
import { EntityRelationshipMutation } from '../../../../../@Api/Model/Implementation/EntityRelationshipMutation';
import equalsEntity from '../../../../../@Api/Entity/Bespoke/equalsEntity';
import equalsUser from '../../../../../@Api/User/equalsUser';
import { createNumberComparator } from '../../../../Generic/List/V2/Comparator/NumberComparator';

interface Group
{
    id: string;
    day: string;
    date: Date;
    user: User;
    entity: Entity;
    events: EntityEvent[];
}

const eventComparator =
    createNumberComparator<EntityEvent>(
        event =>
            event.id);

export default function useGroupedEvents(rootEntity: Entity,
                                         events: EntityEvent[])
{
    return useComputed(
        () =>
        {
            const sortedAndFilteredEvents =
                events
                    // Filter out any events that relate to deleted entities
                    .filter(
                        event =>
                            !(event.entity.isDeleted
                                || (event instanceof EntityRelationshipMutation
                                    && event.toRelatedEntity
                                    && event.toRelatedEntity.isDeleted)))
                    // Sort events on ascending order
                    .sort(eventComparator);

            const groups: Group[] = [];

            // Create groups by consecutive day, user and entity
            let currentGroup: Group;
            let currentDay: string;
            let currentUser: User;
            let currentEntity: Entity;
            let currentFirstEvent: EntityEvent;

            for (const event of sortedAndFilteredEvents)
            {
                let eventDay = moment(event.date).format('YYYYMMDD');
                const eventDate = new Date(event.date);
                let eventEntity;

                // When a top-level event is a relationship creation event to a related entity, then the
                // relationship event belongs in the same group as any mutations on that related entity
                if (equalsEntity(event.entity, rootEntity)
                    && event instanceof EntityRelationshipMutation
                    && event.entityRelationshipDefinition.isPlural(event.isParentRelationship)
                    && event.toRelatedEntity)
                {
                    eventEntity = event.toRelatedEntity;
                }
                else
                {
                    eventEntity = event.entity;
                }

                if (currentGroup
                    && eventDay === currentDay
                    // Users should match or user should be undefined
                    && (equalsUser(event.user, currentUser) || (!event.user && !currentUser))
                    && equalsEntity(eventEntity, currentEntity))
                {
                    currentGroup.events.push(event);
                }
                else
                {
                    currentDay = eventDay;
                    currentFirstEvent = event;
                    currentUser = event.user;
                    currentEntity = eventEntity;

                    currentGroup =
                        {
                            id: `${currentUser && currentUser.id}.${currentEntity && currentEntity.uuid}.${currentDay}.${currentFirstEvent.id}`,
                            date: eventDate,
                            day: currentDay,
                            events: [ event ],
                            entity: currentEntity,
                            user: currentUser
                        };

                    groups.push(currentGroup);
                }
            }

            return groups;
        },
        [
            events
        ]);
}