
export function getTriggerIcon(
    triggerType: string
): string
{
    switch (triggerType)
    {
        case 'Creation':
            return 'playlist_add';
        case 'Update':
            return 'edit';
        case 'Deletion':
            return 'delete_forever';
        case 'Composite':
            return 'settings_input_component';
        case 'Cron':
            return 'access_time';
        default:
            return '';
    }
}