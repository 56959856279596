import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { EntityExpansionBuilder } from '../../../../../Selection/Builder/EntityExpansionBuilder';
import useTypes from '../../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import styles from './MilestoneSummary.module.scss';
import { Droppable } from 'react-beautiful-dnd';
import uuid from '../../../../../../../../@Util/Id/uuid';
import useOnDragEnd from '../../../../../../DragAndDrop/Api/useOnDragEnd';
import { observable, runInAction } from 'mobx';
import { createNumberComparator } from '../../../../../../../Generic/List/V2/Comparator/NumberComparator';
import MilestoneList from './MilestoneList/MilestoneList';
import Tab from '../../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import useSwitch from '../../../../../../../../@Util/Switch/useSwitch';
import Constructor from '../../../../../Constructor/Constructor';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import { CommitBuilder } from '../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { useNewCommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { Tabs } from '@material-ui/core';
import TabBarContainer from '../../../../../../../../@Future/Component/Generic/TabBar/TabBarContainer';

export interface MilestoneSummaryProps
{
    entity: Entity;
    onOpen: (milestone?: Entity) => void;
    milestone?: Entity;
}

const MilestoneSummary: React.FC<MilestoneSummaryProps> =
    props =>
    {
        const [ isLoading, setLoading ] = useState(false);
        const types = useTypes();

        const pathToMilestones =
            useMemo(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Activity.Project.RelationshipDefinition.Milestones,
                            false),
                [
                    props.entity,
                    types
                ]);

        useEffect(
            () =>
            {
                setLoading(true);

                new EntityExpansionBuilder(
                    props.entity.entityType,
                    [
                        props.entity
                    ],
                    [
                        pathToMilestones
                    ])
                    .expand()
                    .then(
                        () =>
                            setLoading(false));
            },
            [
                props.entity,
                pathToMilestones,
                types
            ]);

        const [ sortIndexByMilestoneId ] = useState(() => observable.map<string, number>());

        const milestones =
            useComputed(
                () =>
                    pathToMilestones
                        .traverseEntity(props.entity)
                        .sort(
                            createNumberComparator(
                                d =>
                                    sortIndexByMilestoneId.has(d.uuid)
                                        ?
                                            sortIndexByMilestoneId.get(d.uuid)
                                        :
                                            d.getObjectValueByField(types.Entity.Field.SortIndex))),
                [
                    sortIndexByMilestoneId,
                    props.entity,
                    types
                ]);

        const droppableId = useMemo(() => uuid(), []);

        useOnDragEnd(
            useCallback(
                result =>
                {
                    if (result.destination?.droppableId === droppableId)
                    {
                        const results = milestones.slice();
                        const milestone =
                            results.find(
                                entity =>
                                    entity.uuid === result.draggableId);

                        if (milestone)
                        {
                            const newResults =
                                results.filter(
                                    checkResult => checkResult !== milestone);

                            newResults.splice(
                                result.destination.index,
                                0,
                                milestone);

                            runInAction(
                                () =>
                                    newResults.forEach(
                                        (entity, idx) =>
                                        {
                                            sortIndexByMilestoneId.set(entity.uuid, idx);
                                        }));

                            return new CommitBuilder()
                                .ifValid(
                                    () => true,
                                    cb =>
                                        newResults.forEach(
                                            (entity, idx) =>
                                                cb.setObjectValueInEntity(
                                                    entity,
                                                    types.Entity.Field.SortIndex,
                                                    idx
                                                )
                                        )
                                )
                                .commit()
                                .then(
                                    () =>
                                        runInAction(
                                            () =>
                                                newResults.forEach(
                                                    entity =>
                                                        sortIndexByMilestoneId.delete(entity.uuid)
                                                )
                                        )
                                );
                        }
                    }
                },
                [
                    droppableId,
                    milestones,
                    sortIndexByMilestoneId,
                    types,
                ]));

        const [ isCreationOpen, openCreation, closeCreation ] = useSwitch(false);
        const creationCommitContext = useNewCommitContext();
        const [ milestoneToCreate, setMilestoneToCreate ] = useState<Entity>();

        const tabContainerRef = useRef<HTMLDivElement>();
        const [ tabIndicatorProps, setTabIndicatorProps ] = useState<any>();

        const selectOverview =
            useCallback(
                () =>
                {
                    props.onOpen(undefined);
                    closeCreation();
                },
                [
                    props.onOpen,
                    closeCreation
                ]);

        const onOpen =
            useCallback(
                (milestone: Entity, tabElement: HTMLDivElement) =>
                {
                    props.onOpen(milestone);

                    if (tabContainerRef.current && tabElement)
                    {
                        const tabContainerRect = tabContainerRef.current.getBoundingClientRect();
                        const tabRect = tabElement.getBoundingClientRect();

                        setTabIndicatorProps({
                            style: {
                                left: tabRect.x - tabContainerRect.x - 15,
                                width: tabRect.width
                            }
                        });
                    }

                    closeCreation();
                },
                [
                    props.onOpen,
                    tabContainerRef,
                    setTabIndicatorProps,
                    closeCreation
                ]);

        useEffect(
            () =>
            {
                creationCommitContext.clear();

                if (isCreationOpen)
                {
                    const milestone =
                        new CommitBuilder(creationCommitContext)
                            .createEntity(
                                types.Milestone.Type,
                                builder =>
                                    builder
                                        .relateTo(
                                            true,
                                            types.Activity.Project.RelationshipDefinition.Milestones,
                                            props.entity
                                        )
                                        .setObjectValue(
                                            types.Entity.Field.SortIndex,
                                            milestones.length
                                        ),
                                'milestone'
                            )
                            .getEntityOrThrow('milestone');
                    setMilestoneToCreate(milestone);
                }
                else
                {
                    setMilestoneToCreate(undefined);
                }
            },
            [
                props.entity,
                types,
                isCreationOpen,
                creationCommitContext,
                setMilestoneToCreate,
            ]);

        const isCurrentMilestoneDeleted =
            useComputed(
                () =>
                    props.milestone?.isDeleted,
                [
                    props.milestone
                ]);

        useEffect(
            () =>
            {
                if (isCurrentMilestoneDeleted)
                {
                    props.onOpen(undefined);
                }
            },
            [
                isCurrentMilestoneDeleted,
                props.onOpen
            ]);

        return <Card>
            <div
                className={styles.root}
                ref={tabContainerRef}
            >
                <TabBarContainer
                    value={isCreationOpen ? 'constructor' : props.milestone?.uuid || 'overview'}
                    bordered={isCreationOpen}
                    tabIndicatorProps={!isCreationOpen && props.milestone ? tabIndicatorProps : undefined}
                    renderTabs={
                        tabsProps =>
                            <Droppable
                                droppableId={droppableId}
                                type="milestone"
                                direction="horizontal"
                            >
                                {
                                    (provided) =>
                                        <Tabs
                                            {...tabsProps}
                                            innerRef={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            <Tab
                                                value="overview"
                                                onClick={selectOverview}
                                            >
                                                <LocalizedText
                                                    code="Projects.ProjectOverview"
                                                    value="Projectoverzicht"
                                                />
                                            </Tab>
                                            {
                                                !isLoading && milestones.length > 0 &&
                                                <MilestoneList
                                                    entity={props.entity}
                                                    onOpen={onOpen}
                                                    milestone={props.milestone}
                                                    sortIndexByMilestoneId={sortIndexByMilestoneId}
                                                />
                                            }
                                            {provided.placeholder}
                                            <Tab
                                                value="constructor"
                                                onClick={openCreation}
                                            >
                                                +&nbsp;&nbsp;
                                                <LocalizedText
                                                    code="Projects.Milestone"
                                                    value="Mijlpaal"
                                                />
                                            </Tab>
                                        </Tabs>
                                }
                            </Droppable>
                    }
                />
            </div>
            {
                isCreationOpen && milestoneToCreate &&
                    <CardInset>
                        <Constructor
                            entity={milestoneToCreate}
                            onOpen={props.onOpen}
                            onClose={closeCreation}
                            autoFocus
                            commitContext={creationCommitContext}
                        />
                    </CardInset>
            }
         </Card>;
    };

export default observer(MilestoneSummary);
