import { Setting } from '../../Settings/Setting';
import { Entity } from '../../Model/Implementation/Entity';
import getSetting from '../../../@Component/Domain/Setting/Api/getSetting';
import { SettingSource } from '../../../@Component/Domain/Setting/SettingStore';
import { useComputed } from 'mobx-react-lite';

export function useFieldGroupExpandedPredicate(fieldGroup?: Entity)
{
    return useComputed(
        () =>
        {
            if (fieldGroup)
            {
                return getFieldGroupExpandedSettingValue(
                    fieldGroup,
                    Setting.Metadata.ExpandedFieldGroupPredicate
                );
            }
            else
            {
                return undefined;
            }
        },
        [fieldGroup]
    );
}

function getFieldGroupExpandedSettingValue(
    fieldGroup: Entity,
    code: string
)
{
    const setting = getSetting<any>(SettingSource.Organization, code);

    if (setting === undefined)
    {
        return undefined;
    }
    else
    {
        return setting[fieldGroup.uuid];
    }
}