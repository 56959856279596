import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { FileValue } from '../../../../DataObject/Type/File/FileValue';
import useTypes from '../../../Type/Api/useTypes';
import Inset from '../Inset/Inset';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Buttons from '../../Navigator/Buttons/Buttons';
import styles from './Attachment.module.scss';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import MenuButton from '../../MenuButton/MenuButton';
import TimelineContext from '../../../Timeline/TimelineContext/TimelineContext';
import HorizontalInsetContext from '../../Context/HorizontalInsetContext';
import Click from '../Click/Click';
import Navigator from '../../Navigator/Navigator';

export interface AttachmentProps
{
    entity: Entity;
}

const Attachment: React.FC<AttachmentProps> =
    props =>
    {
        const types = useTypes();
        const isInTimeline = useContext(TimelineContext);
        const hasInset = useContext(HorizontalInsetContext);

        const filename =
            useComputed(
                () =>
                {
                   const value = props.entity.getDataObjectValueByField(types.Attachment.Field.File);

                   if (value && value.getValue() instanceof FileValue)
                   {
                       return (value.getValue() as FileValue).name;
                   }
                   else if (props.entity.name)
                   {
                       return props.entity.name;
                   }
                   else
                   {
                       return '-';
                   }
                },
                [
                    props.entity,
                    types
                ]);

        const filesize =
            useComputed(
                () =>
                {
                    const value = props.entity.getDataObjectValueByField(types.Attachment.Field.File);

                    if (value && value.getValue() instanceof FileValue)
                    {
                        return (value.getValue() as FileValue).humanReadableFilesize;
                    }
                    else
                    {
                        return '-';
                    }
                },
                [
                    props.entity,
                    types
                ]);

        return <>
            <Click
                expand
                allowExpansionHighlight
            >
                <Inset>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={0}
                        className={styles.root}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            <span>
                                {filename}
                            </span>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Buttons
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem
                            className={styles.fileSizeWrapper}
                        >
                            <span
                                className={styles.fileSize}
                            >
                                {filesize}
                            </span>
                        </ViewGroupItem>
                        {
                            !isInTimeline &&
                                <ViewGroupItem
                                    className={classNames(styles.menuButtonContainer, hasInset && styles.hasInset)}
                                >
                                    <MenuButton
                                        entity={props.entity}
                                        icon="more_vert"
                                        small
                                    />
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                </Inset>
            </Click>
            <Navigator
                entity={props.entity}
            />
        </>;
    };

export default observer(Attachment);
