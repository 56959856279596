import { observable } from 'mobx';
import ParameterDictionary from '../../Parameter/ParameterDictionary';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import ValueType from '../../Value/Type/ValueType';
import getValueTypeFromDescriptor from '../../Api/getValueTypeFromDescriptor';

export default class ActionSignature
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref parameters: ParameterDictionary;
    @observable.ref resultType: ValueType<any>;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                parameters: ParameterDictionary,
                resultType: ValueType<any>)
    {
        this.id = id;
        this.parameters = parameters;
        this.resultType = resultType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const parameters =
            await ParameterDictionary.fromDescriptor(
                descriptor.parameters,
                dependencyContext);

        const resultType =
            await getValueTypeFromDescriptor(
                descriptor.resultType,
                dependencyContext);

        return new ActionSignature(
            descriptor.id,
            parameters,
            resultType);
    }

    // ----------------------- Private logic ------------------------
}
