import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Parameter from '../../Parameter/Parameter';
import CollectionType from '../../Value/Type/CollectionType';
import Value from '../../Value/Value';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import CollectionValue from '../../Value/CollectionValue';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import getDependenciesWithoutParameters from '../../Api/getDependenciesWithoutParameters';
import { mapBy } from '../../../../@Util/MapUtils/mapBy';
import MapValue from '../../Value/MapValue';
import MapType from '../../Value/Type/MapType';

export default class MapByComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref collection: Computation<any, any>;
    @observable.ref elementParameter: Parameter<any>;
    @observable.ref key: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(collection: Computation<any, any>,
                elementParameter: Parameter<any>,
                key: Computation<any, any>)
    {
        super();

        this.collection = collection;
        this.elementParameter = elementParameter;
        this.key = key;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new MapType(
            this.key.getType(),
            (this.collection.getType() as CollectionType<any>).type);
    }

    isAsync(): boolean
    {
        return this.collection.isAsync()
            || this.key.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const collection = await safelyApplyFunction(this.collection, context);

        if (collection instanceof CollectionValue)
        {
            const elementsInCollection = CollectionValue.getCollection(collection);
            const keyParameters =
                context.parameterDictionary.getNewDictionaryWithParameter(
                    this.elementParameter);

            return new MapValue(
                new Map<any, any>(
                    await Promise.all(
                        elementsInCollection.map(
                            async element => [
                                await safelyApplyFunction(
                                    this.key,
                                    new FunctionContext(
                                        keyParameters,
                                        context.parameterAssignment.getNewAssignmentWithParameter(
                                            this.elementParameter,
                                            element
                                        ),
                                        context.commitContext
                                    )
                                ),
                                element
                            ] as [Value<any, any>, Value<any, any>]))),
                this.key.getType(),
                collection.elementType);
        }
        else
        {
            throw new Error(`Expected collection but got ${collection.getName()}`);
        }
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        const collection = safelySynchronousApplyFunction(this.collection, context);

        if (collection instanceof CollectionValue)
        {
            const elementsInCollection = CollectionValue.getCollection(collection);
            const keyParameters =
                context.parameterDictionary.getNewDictionaryWithParameter(
                    this.elementParameter);

            return new MapValue(
                mapBy(
                    elementsInCollection,
                    element =>
                        safelySynchronousApplyFunction(
                            this.key,
                            new FunctionContext(
                                keyParameters,
                                context.parameterAssignment.getNewAssignmentWithParameter(
                                    this.elementParameter,
                                    element
                                ),
                                context.commitContext
                            )
                        )
                ),
                this.key.getType(),
                collection.elementType);
        }
        else
        {
            throw new Error(`Expected collection but got ${collection.getName()}`);
        }
    }

    getName(): string
    {
        return 'MapBy';
    }

    validate(): Validation[]
    {
        if (!this.collection)
        {
            return [
                new Validation(
                    'Error',
                    'Missing collection')
            ];
        }
        else if (!this.key)
        {
            return [
                new Validation(
                    'Error',
                    'Missing key')
            ];
        }
        else
        {
            return [
                ...this.collection.validate(),
                ...this.key.validate()
            ];
        }
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'MapBy';
        descriptor.collection = this.collection.toDescriptor();
        descriptor.elementParameterId = this.elementParameter.id;
        descriptor.key = this.key.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.collection.getDependencies(),
            ...getDependenciesWithoutParameters(
                this.key?.getDependencies() || [],
                this.elementParameter)
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const collection = await getComputationFromDescriptor(descriptor.collection, dependencyContext);
        const collectionType = collection.getType();

        if (collectionType instanceof CollectionType)
        {
            const elementParameter =
                MapByComputation.getElementParameter(
                    collection,
                    descriptor.elementParameterId);
            const key =
                await getComputationFromDescriptor(
                    descriptor.key,
                    new AutomationDependencyContext(
                        dependencyContext.parameterDictionary.getNewDictionaryWithParameter(elementParameter)));

            return new MapByComputation(
                collection,
                elementParameter,
                key);
        }
        else
        {
            throw new Error(`Expected collection but got ${collectionType.getName()}`);
        }
    }

    static getElementParameter(collection: Computation<any, any>,
                               id: string)
    {
        return new Parameter(
            id,
            (collection.getType() as CollectionType<any>).type,
            true,
            `Element uit collectie`);
    }

    // ----------------------- Private logic ------------------------
}
