import React, { useCallback, useContext } from 'react';
import TypeMapping from '../../../Model/TypeMapping';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { IObservableArray, runInAction } from 'mobx';
import { observer, useComputed } from 'mobx-react-lite';
import uuid from '../../../../../../../@Util/Id/uuid';
import Switch from '../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import RelationshipFieldMapping from '../../../Model/FieldMapping/Relationship/RelationshipFieldMapping';
import useTypes from '../../../../Type/Api/useTypes';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import EntityFieldMapping from '../../../Model/FieldMapping/Constant/EntityFieldMapping';
import MappingContext from '../../../Context/MappingContext';
import buildMappingForType from '../../../Api/buildMappingForType';
import TypeReferenceFieldMapping from '../../../Model/FieldMapping/Relationship/TypeReferenceFieldMapping';
import CurrentUserContext from '../../../../../User/CurrentUserContext';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface RelateToOwnEnvironmentProps
{
    typeMapping: TypeMapping;
    entity: Entity;
    commitContext: CommitContext;
}

const RelateToOwnEnvironment: React.FC<RelateToOwnEnvironmentProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);
        const mapping = useContext(MappingContext);

        const parentRelationFieldMapping =
            useComputed(
                () =>
                    props.typeMapping.fieldMappings
                        .find(
                            fieldMapping =>
                                fieldMapping.targetFieldPath.relationshipDefinition === types.Relation.RelationshipDefinition.Relationships
                                    && fieldMapping.targetFieldPath.isParentRelationship),
                [
                    props.typeMapping
                ]);

        const isRelatedToOwnEnvironment =
            useComputed(
                () =>
                    parentRelationFieldMapping instanceof EntityFieldMapping,
                [
                    parentRelationFieldMapping
                ]);

        const toggleRelatedToOwnEnvironment =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            (props.typeMapping.fieldMappings as IObservableArray).remove(parentRelationFieldMapping);

                            if (isRelatedToOwnEnvironment)
                            {
                                // Create an organization type mapping
                                const relationshipMapping = buildMappingForType(types.Relationship.Organization.Type);
                                const relationFieldMapping =
                                    relationshipMapping
                                        .fieldMappings
                                        .find(
                                            fieldMapping =>
                                                fieldMapping.targetFieldPath.relationshipDefinition === types.Relationship.Organization.RelationshipDefinition.Organization
                                                    && !fieldMapping.targetFieldPath.isParentRelationship
                                                    && fieldMapping instanceof RelationshipFieldMapping) as RelationshipFieldMapping;

                                mapping.typeMappings.push(relationshipMapping);
                                props.typeMapping.fieldMappings.push(
                                    new TypeReferenceFieldMapping(
                                        uuid(),
                                        parentRelationFieldMapping.targetFieldPath,
                                        relationFieldMapping.typeMapping.id));
                            }
                            else
                            {
                                // Create a reference to the own organization (delete the organization type mapping)
                                const oldTypeMappingReferenceId = (parentRelationFieldMapping as TypeReferenceFieldMapping).typeMappingId;
                                const relationMapping =
                                    mapping.typeMappings
                                        .filter(
                                            relationshipTypeMapping =>
                                                relationshipTypeMapping.entityType.isA(types.Relationship.Organization.Type)
                                                    && relationshipTypeMapping.fieldMappings
                                                    .some(
                                                        fieldMapping =>
                                                            fieldMapping.targetFieldPath.relationshipDefinition === types.Relationship.Organization.RelationshipDefinition.Organization
                                                                && !fieldMapping.targetFieldPath.isParentRelationship
                                                                && fieldMapping instanceof RelationshipFieldMapping
                                                                && fieldMapping.typeMapping.id === oldTypeMappingReferenceId))
                                        .find(() => true);

                                (mapping.typeMappings as IObservableArray).remove(relationMapping);

                                props.typeMapping.fieldMappings.push(
                                    new EntityFieldMapping(
                                        uuid(),
                                        parentRelationFieldMapping.targetFieldPath,
                                        currentUserStore.environmentEntity.uuid));
                            }
                        }),
                [
                    props.typeMapping,
                    isRelatedToOwnEnvironment,
                    parentRelationFieldMapping,
                    mapping,
                    types,
                    currentUserStore
                ]);

        if (props.typeMapping.entityType.isA(types.Relationship.Type))
        {
            return <CardInset>
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                            alignment="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <LocalizedText
                                    code="Import.LinkToOwnOrganization"
                                    value="${type} koppelen aan de eigen organisatie"
                                    type={props.typeMapping.entityType.getName()}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <Switch
                                    onToggle={toggleRelatedToOwnEnvironment}
                                    checked={isRelatedToOwnEnvironment}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </CardInset>;
        }
        else
        {
            return null;
        }
    };

export default observer(RelateToOwnEnvironment);
