import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { ButtonStore } from '../Button/ButtonStore';

export interface RGBA
{
    r: number,
    g: number,
    b: number,
    a?: number
}

export class ColorPickerStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // @observable hex: string;
    @observable rgba: RGBA;

    @observable isOpen: boolean;
    @observable onChange: (hex: string, rgba: RGBA) => void;
    @observable callChangeOnClose: boolean;
    @observable saveCallback: () => void;

    // ------------------------ Constructor -------------------------

    constructor(color?: string | RGBA,
                callChangeOnClose?: boolean,
                onChange?: (hex: string, rgba: RGBA) => void,
                onSave?: () => void)
    {
        super();

        if (typeof(color) === 'string')
        {
            this.rgba = ColorPickerStore.hexToRGBA(color as string);
        }
        else if (typeof(color) === 'object' && (color as RGBA).r && (color as RGBA).g && (color as RGBA).b)
        {
            this.rgba = color as RGBA;
        }

        this.onChange = onChange;
        this.callChangeOnClose = callChangeOnClose;
        this.saveCallback = onSave;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get colorCss()
    {
        return this.rgba ? `rgb(${this.rgba.r}, ${this.rgba.g}, ${this.rgba.b}, ${this.rgba.a})` : undefined;
    }

    @computed
    get isButtonVisible(): boolean
    {
        // TODO [DD]: enabling the button will ensure that the onClick is pressed whenever the color picker is clicked away from
        return false; // !this.isOpen && this.rgba !== undefined;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get clearButtonStore(): ButtonStore
    {
        return new ButtonStore({
            icon: 'delete',
            onClick:
                () =>
                    this.change(undefined)
        });
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    change(color: any)
    {
        if (color)
        {
            if (typeof(color) === 'object' && color.rgb !== undefined)
            {
                this.rgba = {
                    r: color.rgb.r,
                    g: color.rgb.g,
                    b: color.rgb.b,
                    a: color.rgb.a};
            }
            else
            {
                this.rgba = color;
            }

            if (this.onChange && !this.callChangeOnClose)
            {
                this.onChange(color.hex, this.rgba);
            }
        }
        else
        {
            this.rgba = undefined;

            if (this.onChange)
            {
                this.onChange(undefined, undefined);
            }
        }
    }

    @action.bound
    open()
    {
        this.isOpen = true;
    }

    @action.bound
    close()
    {
        this.isOpen = false;

        if (this.onChange)
        {
            this.onChange(ColorPickerStore.rgbaToHex(this.rgba), this.rgba);
        }

        if (this.saveCallback)
        {
            this.saveCallback();
        }
    }

    // ------------------------ Public logic ------------------------

    public static rgbaToHex(rgba: RGBA): string
    {
        if (rgba)
        {
            let r = rgba.r.toString(16);
            r = r.length === 1 ? `0${r}` : r;

            let g = rgba.g.toString(16);
            g = g.length === 1 ? `0${g}` : g;

            let b = rgba.b.toString(16);
            b = b.length === 1 ? `0${b}` : b;

            let a = rgba.a === undefined ? '' : (rgba.a * 255).toString(16).substring(0, 2);

            return `#${r}${g}${b}${a}`.toUpperCase();
        }
        else
        {
            return '#f00';
        }
    }

    public static hexToRGBA(hex: string): RGBA
    {
        // Expand shorthand "03F" to full "0033FFFF"
        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d])$/i;

        hex = hex.replace(shorthandRegex, (m, r, g, b, a) => {
            return r + r + g + g + b + b + a + a;
        });

        // Try RGBA
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);

        return result ? {
            r: parseInt(result[1] ? result[1] : '255', 16),
            g: parseInt(result[2] ? result[2] : '255', 16),
            b: parseInt(result[3] ? result[3] : '255', 16),
            a: parseInt(result[4] ? result[4] : '255', 16)
        } : null;
    }

    // ----------------------- Private logic ------------------------
}
