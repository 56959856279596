import { useCallback, useContext, useMemo } from 'react';
import IdContext from '../../../Context/IdContext';
import SelectedValueContext from '../Context/SelectedValueContext';
import Segment from '../../../Model/Segment';
import { useComputed } from 'mobx-react-lite';
import { runInAction } from 'mobx';

export default function useChildSelectionId(segment: Segment): [ string, (id?: string) => void]
{
    const memorizedSelectedCategoryValue = useContext(SelectedValueContext);
    const datasetId = useContext(IdContext);
    const categoryId =
        useMemo(
            () =>
                `${datasetId}:${segment.groupFieldPath?.id}`,
            [
                datasetId,
                segment
            ]);

    const selectedId =
        useComputed(
            () =>
                memorizedSelectedCategoryValue.get(categoryId),
            [
                memorizedSelectedCategoryValue,
                categoryId
            ]);

    const setSelectedId =
        useCallback(
            (id?: string) =>
                runInAction(
                    () =>
                    {
                        if (id === undefined)
                        {
                            memorizedSelectedCategoryValue.delete(categoryId);
                        }
                        else
                        {
                            memorizedSelectedCategoryValue.set(categoryId, id);
                        }
                    }),
            [
                memorizedSelectedCategoryValue,
                categoryId
            ]);

    return [ selectedId, setSelectedId ];
}
