import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import styles from './Notification.module.scss';
import ReadStateButton from './Buttons/ReadStateButton';
import OptionsButton from './Buttons/OptionsButton';
import useIsRead from './Api/useIsRead';
import useTypes from '../../../Entity/Type/Api/useTypes';
import { classNames } from '../../../../../@Future/Util/Class/classNames';
import LocalizerContext from '../../../../../@Service/Localization/LocalizerContext';
import useEntityValue from '../../../../../@Api/Entity/Hooks/useEntityValue';

export interface NotificationProps
{
    entity: Entity;
    onOpen: (entity: Entity) => void;
}

const Notification: React.FC<NotificationProps> =
    props =>
    {
        const isRead = useIsRead(props.entity);
        const types = useTypes();
        const name =
            useComputed(
                () =>
                    props.entity.getDataObjectValueByField(types.Notification.Field.Name).toString(),
                [
                    props.entity,
                    types
                ]);

        const rootClass =
            useMemo(
                () =>
                    classNames(
                        styles.root,
                        isRead ? styles.read : styles.unread),
                [
                    isRead
                ]);

        const localizer = useContext(LocalizerContext);

        const date =
            useEntityValue<Date>(
                props.entity,
                types.Notification.Field.Date);

        const open =
            useCallback(
                () =>
                    props.onOpen(props.entity),
                [
                    props.onOpen,
                    props.entity
                ]);

        return <div
            className={rootClass}
            onClick={open}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={5}
                alignment="center"
            >
                <ViewGroupItem
                    ratio={1}
                >
                    <div
                        className={styles.header}
                    >
                        <span
                            className={styles.notificationContent}
                        >
                            {name}
                        </span>
                    </div>
                    <div
                        className={styles.date}
                    >
                        {localizer.formatDateTimeFromNow(date)}
                    </div>
                </ViewGroupItem>
                <ViewGroupItem
                    className={styles.buttonGroup}
                >
                    <ReadStateButton
                        notification={props.entity}
                    />
                </ViewGroupItem>
                <ViewGroupItem
                    className={styles.buttonGroup}
                >
                    <OptionsButton
                        notification={props.entity}
                    />
                </ViewGroupItem>
            </ViewGroup>
        </div>;
    };

export default observer(Notification);
