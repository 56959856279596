import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { computed, runInAction } from 'mobx';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntitySelectionController } from '../../../../../@Api/Controller/Directory/EntitySelectionController';
import { getModel } from '../../../../../@Util/TransactionalModelV2';
import { EntityCacheService } from '../../../../Service/Entity/EntityCacheService';
import markPathsAsFetchedInEntities from '../Api/markPathsAsFetchedInEntities';
import { EntityCacheReferrer } from '../../../../Service/Entity/EntityCacheReferrer';
import uuid from '../../../../../@Util/Id/uuid';
import { EntityPathJoinNode } from '../../Path/@Model/Node/EntityPathJoinNode';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';

export class EntityExpansionBuilder
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('EntitySelectionController') entitySelectionController: EntitySelectionController;
    @injectWithQualifier('EntityCacheService') entityCacheService: EntityCacheService;

    // ------------------------- Properties -------------------------

    entityType: EntityType;
    entities: Entity[];
    paths: EntityPath[];
    pathIds: Set<string>;
    commitContext?: CommitContext;
    cacheReferrer: EntityCacheReferrer;
    childExpansionBuilders: EntityExpansionBuilder[];

    // ------------------------ Constructor -------------------------

    constructor(
        entityType: EntityType,
        entities: Entity[],
        paths: EntityPath[] = [],
        commitContext?: CommitContext
    )
    {
        this.entityType = entityType;
        this.entities = entities;
        this.paths = [];
        this.pathIds = new Set<string>();

        paths.forEach(
            path =>
                this.join(path));

        this.commitContext = commitContext;
        this.cacheReferrer = `ExpansionQuery.${uuid()}`;
        this.childExpansionBuilders = [];
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get rootPath(): EntityPath
    {
        return EntityPath.root(this.entityType);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    join(path: EntityPath): EntityExpansionBuilder
    {
        if (!path.rootEntityType.isA(this.rootPath.rootEntityType))
        {
            path =
                this.rootPath
                    .castTo(path.rootEntityType)
                    .join(path);
        }

        if (!this.pathIds.has(path.id))
        {
            this.paths.push(path);
            this.pathIds.add(path.id);
        }

        return this;
    }

    // ------------------------ Public logic ------------------------

    async expand(): Promise<Entity[]>
    {
        await this.performExpansionForNewEntities();

        return this.performExpansionForExistingEntities();
    }

    dispose()
    {
        this.entityCacheService.disposeReferrer(this.cacheReferrer);

        for (const childExpansionBuilder of this.childExpansionBuilders)
        {
            childExpansionBuilder.dispose();
        }
    }

    // ----------------------- Private logic ------------------------

    private async performExpansionForNewEntities()
    {
        const newEntities =
            this.entities
                .filter(
                    entity =>
                        entity.isNew()
                );

        if (newEntities.length > 0)
        {
            await Promise.all(
                this.paths.map(
                    path =>
                        this.performExpansionForPathAndNewEntities(
                            newEntities,
                            path
                        )
                )
            );
        }
    }

    private async performExpansionForPathAndNewEntities(
        entities: Entity[],
        path: EntityPath
    )
    {
        for (let i = 2; i < path.length; i++)
        {
            const nextPath = path.pathAt(i);
            const nextEntities =
                entities.flatMap(
                    entity =>
                        nextPath.traverseEntity(
                            entity,
                            this.commitContext
                        )
                );
            const nextNonNewEntities =
                nextEntities
                    .filter(
                        entity =>
                            !entity.isNew()
                    );

            const nextExpansionBuilder =
                new EntityExpansionBuilder(
                    nextPath.entityType,
                    nextNonNewEntities,
                    [ path.pathFrom(i - 1) ],
                    this.commitContext
                );
            this.childExpansionBuilders.push(nextExpansionBuilder);
            await nextExpansionBuilder.expand();
        }
    }

    private async performExpansionForExistingEntities()
    {
        const nonTransactionalEntities =
            this.entities
                // Operate on non-transactional entities
                .map(
                    entity =>
                        getModel(entity)
                );
        const nonNewEntities =
            nonTransactionalEntities
                .filter(
                    entity =>
                        !entity.isNew()
                );
        const nonNewAndNonInitializedEntities =
            nonNewEntities
                .filter(
                    entity =>
                        this.paths.some(
                            path =>
                                !this.isPathFetchedForEntity(entity, path)
                        )
                );

        if (this.paths.length === 0 || nonNewAndNonInitializedEntities.length === 0)
        {
            markPathsAsFetchedInEntities(
                this.paths,
                nonNewEntities,
                this.cacheReferrer
            );

            return Promise.resolve(this.entities);
        }
        else
        {
            // console.log('expanding',
            //     this.cacheReferrer,
            //     nonNewAndNonInitializedEntities.map(e => e.id),
            //     this.paths.map(p => p.code),
            //     this.entities,
            //     this.entities.map(entity => getModel(entity)),
            //     'not in cache:',
            //     this.entities.filter(entity => !this.entityCacheService.hasEntity(entity.uuid)));

            return this.entitySelectionController.selectByPaths(
                nonNewAndNonInitializedEntities.map(entity => entity.id),
                this.entityType.id,
                this.paths.map(path => path.descriptor))
                .then(
                    expandedEntities =>
                        runInAction(
                            () =>
                            {
                                expandedEntities.forEach(
                                    expandedEntity =>
                                    {
                                        expandedEntity.initialize(
                                            this.entityTypeStore,
                                            undefined,
                                            undefined,
                                            undefined,
                                            false
                                        );
                                    }
                                );

                                const mergedEntities =
                                    this.entityCacheService.mergeEntityNetworkForEntities(
                                        expandedEntities,
                                        true,
                                        this.cacheReferrer
                                    );

                                markPathsAsFetchedInEntities(
                                    this.paths,
                                    mergedEntities,
                                    this.cacheReferrer
                                );

                                // console.log('expanded',
                                //     this.cacheReferrer,
                                //     nonNewAndNonInitializedEntities.map(e => e.id),
                                //     this.paths.map(p => p.code),
                                //     this.entities,
                                //     this.entities.map(entity => getModel(entity)),
                                //     mergedEntities);

                                return Promise.resolve(mergedEntities);
                            }
                        )
                );
        }
    }

    private isPathFetchedForEntity(entity: Entity,
                                   path: EntityPath): boolean
    {
        let currentEntities = [ entity ];

        for (const node of path.nodes)
        {
            if (node instanceof EntityPathJoinNode
                && !currentEntities.every(
                    entity =>
                        this.entityCacheService.relationshipsCacheReferrerService.isInitialized(
                            entity,
                            node.relationshipDefinition,
                            node.isParent
                        )
                )
            )
            {
                // console.log(entity.entityType.code, entity.name, path.code, 'not fetched',
                //     currentEntities.map(
                //         entity =>
                //             this.entityCacheService.relationshipsCacheReferrerService.isInitialized(
                //                 entity,
                //                 node.relationshipDefinition,
                //                 node.isParent)));

                return false;
            }

            currentEntities =
                currentEntities
                    .map(
                        currentEntity =>
                            node.traverseEntity(currentEntity)
                    )
                    .reduce((a, b) => a.concat(b), []);
        }

        return true;
    }
}
