import { EntityType } from '../Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../@Util/DependencyInjection/index';
import { EntityConstructorModalStore } from '../../@Component/Domain/Entity/Constructor/Modal/EntityConstructorModalStore';
import { EntityConstructorStore } from '../../@Component/Domain/Entity/Constructor/EntityConstructorStore';
import constructEntityFromPredicate from './constructEntityFromPredicate';
import { Entity } from '../Model/Implementation/Entity';
import { EntityFieldPath } from '../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import Predicate from '../Automation/Function/Computation/Predicate/Predicate';
import { FeedbackStore } from '../../@Component/App/Root/Environment/Organization/Feedback/FeedbackStore';
import { CommitContext } from './Commit/Context/CommitContext';

export default function constructEntity(
    entityType: EntityType,
    withPredicate?: Predicate,
    withEntity?: Entity,
    onSave?: (entity: Entity) => void,
    allowOpen: boolean = true,
    onOpen?: (entity: Entity) => void,
    fieldPathFilter?: (fieldPath: EntityFieldPath) => boolean,
    onDelete?: (entity: Entity) => void,
    commitContext?: CommitContext
)
{
    const entity =
        withEntity
            ||
        constructEntityFromPredicate(
            entityType,
            withPredicate,
            commitContext
        );

    const feedbackStore = loadModuleDirectly(FeedbackStore);
    const closeDialog =
        feedbackStore.openDialog({
            store:
                new EntityConstructorModalStore({
                    constructorStore:
                        new EntityConstructorStore({
                            entityType: entityType,
                            entity: entity,
                            fieldPathFilter: fieldPathFilter,
                            commitContext,
                        }),
                    allowOpen: allowOpen,
                    onSave:
                        entity =>
                            onSave && onSave(entity),
                    onClose:
                        () =>
                            closeDialog(),
                    onOpen: onOpen,
                    onDelete: onDelete
                }).modalDialogStore
        });
}
