import React, { useCallback, useMemo } from "react";
import { observer, useComputed } from "mobx-react-lite";
import { LayoutEditorProps } from "../../../../Editor/LayoutEditor";
import FunctionContext from "../../../../../../../@Api/Automation/Function/FunctionContext";
import Computation from "../../../../../../../@Api/Automation/Function/Computation/Computation";
import { runInAction } from "mobx";
import Input from "../../../../../../../@Future/Component/Generic/Input/Input/Input";
import LocalizedText from "../../../../../Localization/LocalizedText/LocalizedText";
import ComputationEditor from "../../../../../Entity/Viewer/Content/Automation/Editor/Computation/ComputationEditor";
import PrimitiveValueType from "../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType";
import { DataObject } from "../../../../../DataObject/Model/DataObject";
import FormInputLayout from "../../../../../../../@Api/Layout/Type/Form/Input/FormInputLayout";

export interface FormInputPlaceholderEditorProps extends LayoutEditorProps<FormInputLayout>
{

}

const FormInputPlaceholderEditor: React.FC<FormInputPlaceholderEditorProps> =
    props =>
    {
        const context =
            useComputed(
                () =>
                    new FunctionContext(
                        props.parameterDictionary,
                        props.parameterAssignment,
                        props.commitContext
                    ),
                [
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        const setPlaceholder =
            useCallback(
                (value: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.layout.placeholder = value
                    ),
                [
                    props.layout
                ]);

        const placeholderType =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        DataObject.getTypeById('Text')),
                []);

        return <Input
            labelPosition="left"
            label={
                <LocalizedText
                    code="Generic.Placeholder"
                    value="Placeholder"
                />
            }
        >
            <ComputationEditor
                context={context}
                value={props.layout.placeholder}
                onChange={setPlaceholder}
                type={placeholderType}
            />
        </Input>;
    };

export default observer(FormInputPlaceholderEditor);
