import { observable } from 'mobx';
import ParameterAssignment from '../../Automation/Parameter/ParameterAssignment';
import PortalDataSourceSignature from './PortalDataSourceSignature';
import PortalDataSourceQuery from './PortalDataSourceQuery';
import Predicate from '../../Automation/Function/Computation/Predicate/Predicate';
import { Aggregate } from '../../../@Component/Domain/DataObject/Model/Aggregate';
import Computation from '../../Automation/Function/Computation/Computation';

export default class PortalDataSourceAggregateQuery extends PortalDataSourceQuery
{
    // ------------------------- Properties -------------------------

    @observable aggregate: Aggregate;
    @observable.ref expression: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(
        id: string,
        dataSourceSignature: PortalDataSourceSignature,
        parameterAssignment: ParameterAssignment,
        filter: Predicate | undefined,
        aggregate: Aggregate,
        expression: Computation<any, any>
    )
    {
        super(id, dataSourceSignature, parameterAssignment, filter);

        this.aggregate = aggregate;
        this.expression = expression;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Aggregate',
            aggregate: Aggregate[this.aggregate],
            expression: this.expression.toDescriptor(),
        };
    }

    // ----------------------- Private logic ------------------------
}
