import { DataObjectBespokeEditorStore } from '../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { action, computed } from 'mobx';
import { ColorPickerStore } from '../../../../Generic/ColorPicker/ColorPickerStore';
import { ColorRGBA } from './ColorType';
import { FormEvent } from '../../../../Generic/Form/FormEvent';
import { DataObject } from '../../Model/DataObject';

export class ColorEditorStore extends DataObjectBespokeEditorStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get hasValue(): boolean
    {
        return this.value !== undefined;
    }

    @computed
    get value(): any
    {
        return this.baseStore.dataObject.value;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get colorPickerStore(): ColorPickerStore
    {
        let rgba = this.value as ColorRGBA;

        return new ColorPickerStore(
            rgba ? {
                r: rgba.r,
                g: rgba.g,
                b: rgba.b,
                a: rgba.a
            } : undefined,
            true,
            (hex, rgba) =>
            {
                if (rgba)
                {
                    this.updateColor(rgba);
                }
                else
                {
                    this.updateColor(undefined);
                }
            },
            this.saveColor);
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    updateColor(rgba: ColorRGBA)
    {
        this.baseStore.dataObject.setValue(rgba);
    }

    @action.bound
    saveColor()
    {
        this.baseStore.handlerContext.perform(
            new FormEvent<DataObject>(
                FormEvent.Change,
                this.baseStore.dataObject));
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
