import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { Privilege } from '../../Model/Role';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { runInAction } from 'mobx';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import FilterEditor from '../../../Configuration/TypeManager/TypeEditor/ResourcePlannersEditor/FilterEditor/FilterEditor';
import localizeText from '../../../../../@Api/Localization/localizeText';
import { makeStyles } from '@material-ui/core';
import { primaryColor, textSecondaryColor } from '../../../../../@Resource/Theme/Theme';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';


const useStyles = makeStyles({
    dialogTitle: {
        color: primaryColor
    },
    privilege: {
        padding: 10,
        borderBlock: 'thin'
    },
    privilegeHeader: {
        padding: 5,
        backgroundColor: 'whitesmoke',
        fontSize: 14,
        fontWeight: 'bold',
        color: textSecondaryColor
    },
    privilegePredicate: {
        padding: 10,
    },
});

export interface RightPrivilegePredicateEditorProps
{
    privilege: Privilege;
    filterParameter: Parameter<EntityValueType>;
    parameterDictionary: ParameterDictionary;
    filter: Predicate;
    onChange: (privilege: Privilege, filter?: Predicate) => void;
    onCopy: (privilege: Privilege) => void;
    onPaste: (privilege: Privilege) => void;
    canPaste: boolean;
    isReadOnly: boolean;
}

export const RightPrivilegePredicateEditor: React.FC<RightPrivilegePredicateEditorProps> = observer(
    ({
         privilege,
         filterParameter,
         parameterDictionary,
         filter,
         onChange,
         onCopy,
         onPaste,
         canPaste,
         isReadOnly,
     }) =>
    {
        const classes = useStyles();

        const setFilter =
            useCallback(
                (filter?: Predicate) =>
                    runInAction(
                        () =>
                        {
                            onChange(privilege, filter)
                        }
                    ),
                [
                    privilege,
                    onChange
                ]);

        const getName =
            useCallback(
                (privilege: Privilege) =>
                {
                    switch (privilege)
                    {
                        case 'canRead':
                            return localizeText('Generic.Read', 'Lezen');
                        case 'canCreate':
                            return localizeText('Generic.Create', 'Creëeren');
                        case 'canUpdate':
                            return localizeText('Generic.Edit', 'Wijzigen');
                        case 'canDelete':
                            return localizeText('Generic.Delete', 'Verwijderen');
                        case 'canExport':
                            return localizeText('Generic.Export', 'Exporteren');
                    }
                },
                []
            );

        return <ViewGroup
                orientation="vertical"
                spacing={0}
                className={classes.privilege}
            >
                <ViewGroupItem
                    className={classes.privilegeHeader}
                >
                    <ViewGroup
                        orientation="horizontal"
                        spacing={1}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            {getName(privilege)}
                        </ViewGroupItem>
                        {
                            canPaste &&
                            <ViewGroupItem>
                                <IconButton
                                    tooltip={localizeText('Generic.PasteFromClipboard', 'Plakken vanuit klembord')}
                                    iconSize={14}
                                    icon="content_paste"
                                    onClick={() => onPaste(privilege)}
                                />
                            </ViewGroupItem>
                        }
                        {
                            filter &&
                            <ViewGroupItem>
                                <IconButton
                                    tooltip={localizeText('Generic.CopyToClipboard', 'Kopieer naar klembord')}
                                    iconSize={14}
                                    icon="content_copy"
                                    onClick={() => onCopy(privilege)}
                                />
                            </ViewGroupItem>
                        }
                        {
                            filter &&
                            <ViewGroupItem>
                                <IconButton
                                    tooltip={localizeText('Generic.Delete', 'Verwijderen')}
                                    iconSize={14}
                                    icon="delete"
                                    onClick={() => setFilter(undefined)}
                                />
                            </ViewGroupItem>
                        }
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem
                    className={classes.privilegePredicate}
                >
                    <FilterEditor
                        filterParameter={filterParameter}
                        parameterDictionary={parameterDictionary}
                        value={filter}
                        onChange={setFilter}
                        disabled={isReadOnly}
                    />
                </ViewGroupItem>
            </ViewGroup>;
    }
);