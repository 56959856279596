import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import useSpotlerCampaignBouncedCount from '../Hooks/useSpotlerCampaignBouncedCount';
import useSpotlerCampaignSendCount from '../Hooks/useSpotlerCampaignSendCount';
import CampaignPercentageChart from '../../Campaign/Charts/CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

export interface SpotlerCampaignChartUndeliverableProps
{
    spotlerCampaign: Entity;
    selectedStep: Entity;
    size: number;
}

export const SpotlerCampaignChartUndeliverable: React.FC<SpotlerCampaignChartUndeliverableProps> =
    observer(
        ({
            spotlerCampaign,
            selectedStep,
            size
        }) =>
    {
        const totalResultCount =
            useSpotlerCampaignSendCount(
                spotlerCampaign,
                selectedStep
            );

        const undeliverableCount =
            useSpotlerCampaignBouncedCount(
                spotlerCampaign,
                selectedStep
            );

        const allSet =
            useAllSet([
                totalResultCount,
                undeliverableCount
            ]);

        const formattedSelection =
            useMemo(
                () =>
                {
                    return `${undeliverableCount} / ${totalResultCount}`
                },
                [
                    undeliverableCount,
                    totalResultCount
                ]
            );

        return <div>
            {
                allSet &&
                <CampaignPercentageChart
                    value={undeliverableCount / totalResultCount * 100}
                    size={size}
                    label={localizeText('Campaign.Chart.Failed', 'Mislukt')}
                    color={'#8f0012'}
                    selection={formattedSelection}
                />
            }
        </div>;
    }
);
