import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import HoverCardMiddle from '../../../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import constructRelatedEntity from '../../../../Entity/Dataset/Segment/Api/constructRelatedEntity';
import { EntityPath } from '../../../../Entity/Path/@Model/EntityPath';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import hasAccessToWorkflowAutomations from '../../../../../../@Api/Pack/hasAccessToWorkflowAutomations';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import useSwitch from '../../../../../../@Util/Switch/useSwitch';
import isNonEssentialEdition from '../../../../License/Api/isNonEssentialEdition';
import CreatorEditionRequired from '../../../../License/CreatorEditionRequired/CreatorEditionRequired';
import { EntitySelectionBuilder } from '../../../../Entity/Selection/Builder/EntitySelectionBuilder';
import { AutomationsTable } from '../../../Page/Automations/AutomationManager/Table/AutomationsTable';

export interface AutomationsEditorProps
{
    entityType: EntityType;
}

const AutomationsEditor: React.FC<AutomationsEditorProps> =
    props =>
    {
        const types = useTypes();

        const [ isCreating, startCreating, stopCreating ] = useSwitch(false);

        const create =
            useCallback(
                () =>
                {
                    if (hasAccessToWorkflowAutomations())
                    {
                        startCreating();

                        if (!isNonEssentialEdition())
                        {
                            return;
                        }

                        return constructRelatedEntity(
                            props.entityType.entity,
                            EntityPath.fromEntity(props.entityType.entity)
                                .joinTo(
                                    types.EntityType.RelationshipDefinition.Automations,
                                    false),
                            undefined,
                            undefined,
                            undefined,
                            fieldPath =>
                                !(fieldPath.relationshipDefinition === types.EntityType.RelationshipDefinition.Automations
                                    && fieldPath.isParentRelationship))
                    }
                    else
                    {
                        return alert(
                            localizeText(
                                'WorkflowAutomations.HasNoAccessToWorkflowAutomations',
                                'Je hebt geen toegang tot de module workflow automations. Deze kan worden aangemaakt in het accountbeheer.'));
                    }
                },
                [
                    startCreating,
                    types,
                    props.entityType
                ]);

        const automationsFilter =
            useCallback(
                (builder: EntitySelectionBuilder, rootPath: EntityPath) =>
                    builder.where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath
                                    .joinTo(
                                        types.EntityType.RelationshipDefinition.Automations,
                                        true),
                                props.entityType.entity
                            )
                    ),
                [
                    types,
                    props.entityType
                ]
            );

        return <>
            <AutomationsTable
                filter={automationsFilter}
            />
            <CreatorEditionRequired
                onClose={stopCreating}
                disabled={!isCreating}
            >
                <HoverCardMiddle
                    onClick={create}
                >
                    + {types.Automation.Type.getName()}
                </HoverCardMiddle>
            </CreatorEditionRequired>
        </>;
    };

export default observer(AutomationsEditor);
