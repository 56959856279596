import { DataComparator, DataTransformer, ListStore, ListStoreProps } from '../../../Generic/List/V2/ListStore';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { ViewComponent } from '../../../Generic/ViewStack/Model/ViewComponent';
import { ExpansionPanelListItemStore } from '../../../Generic/List/V2/ExpansionPanel/ExpansionPanelListItemStore';
import { TextStore } from '../../../Generic/Text/TextStore';
import { recordBackgroundColor, recordHoverBackgroundColor } from '../../../../@Resource/Theme/Theme';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { EntitySelectionController } from '../../../../@Api/Controller/Directory/EntitySelectionController';
import { EntitySelectionBuilder } from '../Selection/Builder/EntitySelectionBuilder';
import { action, computed, IObservableArray, observable } from 'mobx';
import { getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import moment from 'moment';
import { ButtonStore } from '../../../Generic/Button/ButtonStore';
import { EntityBucketSummary } from '../BucketSummary/EntityBucketSummary';
import { EntityBucketSummaryStore } from '../BucketSummary/EntityBucketSummaryStore';
import { AvatarStore } from '../../../Generic/Avatar/AvatarStore';
import { ExpansionPanelProps, ExpansionPanelStore } from '../../../Generic/ExpansionPanel/ExpansionPanelStore';
import { RouterStore } from '../../../../@Service/Router/RouterStore';
import { LocalizationStore } from '../../../../@Service/Localization/LocalizationStore';
import { ListDragProvider } from '../../../Generic/List/V2/Drag/ListDragProvider';
import { createStringComparator } from '../../../Generic/List/V2/Comparator/StringComparator';
import { EntityCaptionTextStore } from '../Caption/EntityCaptionTextStore';
import { ListQuery } from '../../../Generic/List/V2/ListQuery';
import { EntitySelectionResult } from '../Selection/Model/EntitySelectionResult';
import { DataObjectStore } from '../../DataObject/DataObjectStore';
import { RelatedEntityPath } from '../Path/@Model/RelatedEntityPath';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { createDateComparator } from '../../../Generic/List/V2/Comparator/DateComparator';
import { TimelineGroupLayout } from '../../../Generic/List/V2/Timeline/TimelineGroupLayout';
import { EntityFieldPath } from '../Path/@Model/EntityFieldPath';
import { openEntity } from '../@Util/openEntity';
import { compareEntities } from './Comparator/EntityComparator';
import { hasYearsOutsideCurrentYear } from '../../../Generic/List/V2/Timeline/TimelineGroupLayoutUtils';
import { ListItemStore } from '../../../Generic/List/V2/Item/ListItemStore';
// import { EntityCardStore } from '../Card/EntityCardStore';
import { ReactViewComponent } from '../../../Generic/ViewStack/Model/ReactViewComponent';
import equalsEntity from '../../../../@Api/Entity/Bespoke/equalsEntity';
import Item from '../Item/Item';
import { getRootNodeFromSelection } from '../../../../@Api/Selection/Api/getRootNodeFromSelection';

export type SelectionType = EntitySelectionBuilder | (() => Promise<Entity[]>) | Entity[];


export interface EntityListProps extends Partial<ListStoreProps<Entity, SelectionType, ExpansionPanelListItemStore<Entity>, Entity, Date>>
{
    selection: PropType<EntityListStore, EntityListProps, SelectionType>;
    entityType?: PropType<EntityListStore, EntityListProps, EntityType>;
    transform?: DataTransformer<Entity>;
    showTimeline?: PropType<EntityListStore, EntityListProps, boolean>;
    timelineDateFieldPath?: (entity: Entity) => EntityFieldPath;
    isEmbedded?: PropType<EntityListStore, EntityListProps, boolean>;
    isCard?: PropType<EntityListStore, EntityListProps, boolean>;
    isReactive?: PropType<EntityListStore, EntityListProps, boolean>;
    alwaysForceReload?: PropType<EntityListStore, EntityListProps, boolean>;
    doShowEntityType?: PropType<EntityListStore, EntityListProps, boolean>;
    onVisit?: (entity: Entity) => Promise<any>;
    isVisible?: (entity: Entity) => boolean;
    comparator?: DataComparator<ExpansionPanelListItemStore<Entity>>;
    allowEditingFavoriteFields?: () => boolean;
    summaryAvatar?: (entity: Entity, store: EntityListStore, itemStore: ExpansionPanelListItemStore<Entity>) => AvatarStore;
    summaryPrimaryText?: (entity: Entity, store: EntityListStore, itemStore: ExpansionPanelListItemStore<Entity>) => TextStore;
    summaryPrimaryTextCaption?: (entity: Entity, store: EntityListStore, itemStore: ExpansionPanelListItemStore<Entity>) => TextStore,
    summaryButtons?: (entity: Entity, store: EntityListStore, itemStore: ExpansionPanelListItemStore<Entity>) => ButtonStore[];
    showOpenInFullscreenIcon?: PropType<EntityListStore, EntityListProps, boolean>;
    onOpenFullscreen?: (entity: Entity) => Promise<any>;
    showUserButton?: PropType<EntityListStore, EntityListProps, boolean>;
    showDeleteButton?: PropType<EntityListStore, EntityListProps, boolean>;
    isExpandedByDefault?: (entity: Entity) => boolean;
    backgroundColor?: (entity: Entity, store: EntityListStore, itemStore: ExpansionPanelListItemStore<Entity>) => string;
    hoverBackgroundColor?: (entity: Entity, store: EntityListStore, itemStore: ExpansionPanelListItemStore<Entity>) => string;
    expansionPanelOverrides?: (entity: Entity, expansionPanelListItemStore: ExpansionPanelListItemStore<Entity>) => ExpansionPanelProps;
    showTopDividerOnFirstItem?: PropType<EntityListStore, EntityListProps, boolean>;
    onLoad?: (entity: Entity) => void;
    createButton?: PropType<EntityListStore, EntityListProps, ButtonStore>;
    listIndentation?: PropType<EntityListStore, EntityListProps, number>;
    drag?: ListDragProvider<Entity, Entity>;
    noDataLabel?: PropType<EntityListStore, EntityListProps, string>;
    showAssigneeIcon?: PropType<EntityListStore, EntityListProps, boolean>;
    pathFromRelatedEntity?: PropType<EntityListStore, EntityListProps, RelatedEntityPath>;
}

const defaultProps: Partial<EntityListProps> =
{
    isEmbedded: false,
    isCard: true,
    alwaysForceReload: false,
    doShowEntityType: false,
    showOpenInFullscreenIcon: false,
    showUserButton: false,
    showDeleteButton: false,
    showTopDividerOnFirstItem: true,
    showAssigneeIcon: true,
    allowEditingFavoriteFields: () => false,
    summaryPrimaryTextCaption:
        (entity, store) =>
        {
            return new EntityCaptionTextStore({
                showType:
                    () =>
                        store.doShowEntityType && !entity.entityType.isA(store.entityTypeStore.bespoke.types.Relationship.Type),
                entity: entity,
                isDense: true,
                pathFromRelatedEntity: store.pathFromRelatedEntity
            });
        },
    summaryPrimaryText:
        (entity, store, itemStore) =>
            new TextStore(
                {
                    label: '%0 %1 %2',
                    isDense: true,
                    childTextStores:
                    () =>
                    {
                        const caption =
                            store.props.summaryPrimaryTextCaption(
                                entity,
                                store,
                                itemStore);

                        return [
                            new TextStore(
                            {
                                label: '-',
                                variant: 'body2',
                                color: 'default',
                                isDense: true,
                            }),
                            caption
                        ];
                    }
                }),
    summaryButtons: () => []

};

export function createEntityListNameComparator(): (lhs: ExpansionPanelListItemStore<Entity>, rhs: ExpansionPanelListItemStore<Entity>) => number
{
    return createStringComparator(
        data =>
            data.data.name);
}

export class EntityListStore<P extends EntityListProps = EntityListProps> extends ListStore<Entity, SelectionType, ExpansionPanelListItemStore<Entity>, Entity, Date, P>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('EntitySelectionController') entitySelectionController: EntitySelectionController;
    @injectWithQualifier('RouterStore') routerStore: RouterStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable results = observable.array<EntitySelectionResult>();

    // ------------------------ Constructor -------------------------

    constructor(props: P, givenDefaultProps?: P)
    {
        super({
            ...props,
            id: entity => (entity.id || entity.uuid),
            query: () => getOrCompute(this, props.selection),
            isReactive: () => getOrCompute(this, props.isReactive),
            noDataLabel: () => getOrCompute(this, props.noDataLabel),
            load:
                query =>
                {
                    if (query.selection instanceof EntitySelectionBuilder)
                    {
                        return Promise.resolve(
                            this.results.map(
                                result =>
                                    result.entity));
                    }
                    else if (typeof query.selection === 'function')
                    {
                        return query.selection() as Promise<Entity[]>;
                    }
                    else
                    {
                        return Promise.resolve(query.selection || []);
                    }
                },
            transform:
                entities =>
                {
                    const transformedEntities =
                        entities
                            .map(
                                entity =>
                                    entity.entityType.bespoke.getEntityToList(
                                        entity,
                                        this.pathFromRelatedEntity !== undefined))
                            .filter(
                                entity =>
                                    entity !== undefined);

                    if (props.transform)
                    {
                        return props.transform(transformedEntities);
                    }
                    else
                    {
                        return Promise.resolve(transformedEntities);
                    }
                },
            count:
                selection =>
                {
                    if (selection instanceof EntitySelectionBuilder)
                    {
                        return selection.count()
                            .then(
                                count =>
                                    Promise.resolve(count.value));
                    }
                    else
                    {
                        return Promise.resolve((selection as Entity[]).length);
                    }
                },
            construct:
                entity =>
                    entity.entityType.bespoke.showListItemsAsEntityCard(this.pathFromRelatedEntity)
                        ?
                            new ListItemStore<Entity>({
                                template:
                                    store =>
                                        new ReactViewComponent(
                                            Item,
                                            {
                                                entity: entity,
                                                pathFromRelatedEntity: this.pathFromRelatedEntity
                                            })
                            })
                        :
                            new ExpansionPanelListItemStore<Entity>(
                            {
                                expansionPanel:
                                    itemStore =>
                                    {
                                        const followUpDateSelector =
                                            (store: ExpansionPanelStore, isRight: boolean) =>
                                            {
                                                return undefined;
                                            };

                                        return {
                                            showTopDivider: () => !!this.props.showTopDividerOnFirstItem || !itemStore.isFirst,
                                            isFirstInTimeline: () => (itemStore.isFirst || itemStore.isFirstInGroup),
                                            isLastInTimeline: () => (itemStore.isLast || itemStore.isLastInGroup),
                                            selectorColor: entity.entityType.getInheritedColor(),
                                            isExpanded:
                                                this.props.isExpandedByDefault
                                                    ?
                                                        () => this.props.isExpandedByDefault(entity)
                                                    :
                                                        undefined,
                                            hoverBackgroundColor:
                                                () =>
                                                    this.props.hoverBackgroundColor
                                                        ?
                                                            this.props.hoverBackgroundColor(entity, this, itemStore)
                                                        :
                                                            recordHoverBackgroundColor,
                                            backgroundColor:
                                                store =>
                                                    this.props.backgroundColor
                                                        ?
                                                            this.props.backgroundColor(entity, this, itemStore)
                                                        :
                                                            this.showTimeline
                                                                ?
                                                                    store.showHoverBackgroundColor
                                                                        ?
                                                                            recordHoverBackgroundColor
                                                                        :
                                                                            recordBackgroundColor
                                                                :
                                                                    store.showHoverBackgroundColor
                                                                        ?
                                                                            recordHoverBackgroundColor
                                                                        :
                                                                            recordBackgroundColor,
                                            summaryPrefix:
                                                store =>
                                                    this.showTimeline
                                                        ?
                                                            followUpDateSelector(store, false)
                                                        :
                                                            undefined,
                                            summarySuffix:
                                                store =>
                                                    this.showTimeline
                                                        ?
                                                            undefined
                                                        :
                                                            followUpDateSelector(store, true),
                                            summaryAvatar:
                                                () =>
                                                    this.props.summaryAvatar
                                                        ? this.props.summaryAvatar(entity, this, itemStore)
                                                        : undefined,
                                            summaryPrimaryText:
                                                this.props.summaryPrimaryText(
                                                    entity,
                                                    this,
                                                    itemStore),
                                            wrapExpansionInCardContent: true,
                                            summaryView:
                                                new ViewComponent(
                                                    EntityBucketSummary,
                                                    new EntityBucketSummaryStore({
                                                        entity: entity,
                                                        displayInList: true
                                                    })),
                                            isClickable:
                                                () =>
                                                    entity.entityType.bespoke.isOpenable(
                                                        entity,
                                                        this.pathFromRelatedEntity),
                                            onClick:
                                                store =>
                                                 this.openEntity(entity),
                                            isExpandable:
                                                true,
                                            showSummaryIconsWhenExpanded: true,
                                            summaryButtons:
                                                expansionPanelStore =>
                                                {
                                                    const buttons: ButtonStore[] = [];

                                                    buttons.push(
                                                        ...this.props.summaryButtons(
                                                            entity,
                                                            this,
                                                            itemStore));

                                                    const orderedButtons = [
                                                        ...buttons,
                                                    ].filter(item => item);

                                                    return orderedButtons;
                                                },
                                            hasChevron: true,
                                            isSelectable: true,
                                            hasBottomInset:
                                                () =>
                                                    itemStore.isLastInGroup && !this.showTimeline,
                                            isEmbedded:
                                                () => this.isEmbedded,
                                            isCard:
                                                () => this.isCard,
                                            bottomView: undefined,
                                            listIndentation: () => this.listIndentation,
                                            isRounded: true,
                                            hasOutsideMargin: true,
                                            ...this.props.expansionPanelOverrides
                                                ?
                                                    this.props.expansionPanelOverrides(entity, itemStore)
                                                :
                                                    undefined
                                        }},
                                isHidden:
                                    () =>
                                        entity.isDeleted
                                            ||
                                        (this.props.isVisible
                                            ?
                                                !this.props.isVisible(entity)
                                            :
                                                false)
                            }),
            drag: props.drag,
            group:
                {
                    id:
                        date =>
                            this.showTimeline
                                ?
                                    moment(date).startOf('day').toISOString()
                                :
                                    moment(date).format('YYYY'),
                    group:
                        entity =>
                            this.getTimelineDateValue(entity),
                    template:
                        (groupStore, groupIdx, groupStores) =>
                            new TimelineGroupLayout(
                                this,
                                groupStore,
                                groupIdx,
                                groupStores,
                                this.hasYearsOutsideCurrentYear,
                                this.showTimeline),
                    comparator:
                        createDateComparator(data => data)
                },
            createButton: props.createButton,
            comparator:
                props.comparator
                    ?
                        props.comparator
                    :
                        (s1: ExpansionPanelListItemStore<Entity>, s2: ExpansionPanelListItemStore<Entity>) =>
                        {
                            return compareEntities(
                                s1.data,
                                s2.data,
                                this.pathFromRelatedEntity);
                        }

        },
        { ...defaultProps as any, ...givenDefaultProps as any});
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return this.fetchSelectionResults(
            new ListQuery<SelectionType>(this.query))
            .then(this.setResults)
            .then(() => super.initialize());
    }

    fetchSelectionResults(query: ListQuery<SelectionType>): Promise<EntitySelectionResult[]>
    {
        if (query.selection instanceof EntitySelectionBuilder)
        {
            return query.selection.select(query.offset, query.limit, this.alwaysForceReload)
                .then(results =>
                {
                    if (this.props.onLoad)
                    {
                        results.forEach(
                            result =>
                                this.props.onLoad(result.entity));
                    }

                    return Promise.resolve(results);
                });
        }
        else if (typeof query.selection === 'function')
        {
            return Promise.resolve([]);
        }
        else
        {
            return Promise.resolve([]);
        }
    }

    // -------------------------- Computed --------------------------

    @computed
    get showTimeline(): boolean
    {
        const showTimeline =
            getOrCompute(
                this,
                this.props.showTimeline);

        if (showTimeline === undefined)
        {
            return this.entityType.bespoke.listAsTimelineByDefault();
        }
        else
        {
            return showTimeline;
        }
    }

    @computed
    get isEmbedded(): boolean
    {
        return getOrCompute(this, this.props.isEmbedded);
    }

    @computed
    get isCard(): boolean
    {
        return getOrCompute(this, this.props.isCard);
    }

    @computed
    get alwaysForceReload(): boolean
    {
        return getOrCompute(this, this.props.alwaysForceReload);
    }

    @computed
    get doShowEntityType(): boolean
    {
        return getOrCompute(this, this.props.doShowEntityType);
    }



    @computed
    get showOpenInFullscreenIcon(): boolean
    {
        return getOrCompute(this, this.props.showOpenInFullscreenIcon);
    }

    @computed
    get showDeleteButton(): boolean
    {
        return getOrCompute(this, this.props.showDeleteButton);
    }

    @computed
    get allowEditingFavoriteFields(): () => boolean
    {
        return getOrCompute(this, this.props.allowEditingFavoriteFields);
    }

    @computed
    get listIndentation(): number
    {
        return getOrCompute(this, this.props.listIndentation);
    }

    @computed
    get pathFromRelatedEntity(): RelatedEntityPath
    {
        return getOrCompute(this, this.props.pathFromRelatedEntity);
    }

    @computed
    get entityType(): EntityType
    {
        return getOrCompute(
            this,
            this.props.entityType,
            this.query instanceof EntitySelectionBuilder
                ?
                    getRootNodeFromSelection(this.query.selection).entityType
                :
                    this.entityTypeStore.entity.type);
    }

    @computed
    get hasYearsOutsideCurrentYear(): boolean
    {
        return hasYearsOutsideCurrentYear(this.groupStores);
    }

    @computed
    get showAssigneeIcon(): boolean
    {
        return getOrCompute(this, this.props.showAssigneeIcon);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    setResults(results: EntitySelectionResult[])
    {
        this.results = results as IObservableArray<EntitySelectionResult>;

        // TODO [DD]: does this create a memory leak?
        this.results.observe(
            change =>
            {
                this.processDataItems(
                    this.results.map(
                        result =>
                            result.entity),
                    false);
            });
    }

    @action.bound
    markEntity(entity: Entity)
    {
        return this.initializeStore()
            .then(
                () =>
                {
                    this.itemStores
                        .forEach(
                            itemStore =>
                            {
                                if (itemStore.expansionPanelStore)
                                {
                                    if (equalsEntity(itemStore.data, entity))
                                    {
                                        itemStore
                                            .expansionPanelStore
                                            .setMarked(true);
                                    }
                                    else
                                    {
                                        itemStore
                                            .expansionPanelStore
                                            .setMarked(false);
                                    }
                                }
                                // else if (itemStore.template.store instanceof EntityCardStore)
                                // {
                                //     itemStore.template.store.setMarked(true);
                                // }
                            });
                });
    }

    // ------------------------ Public logic ------------------------

    openEntity(entity: Entity): Promise<any>
    {
        if (this.props.onVisit)
        {
            return this.props.onVisit(entity);
        }
        else
        {
            return openEntity(
                entity,
                this.pathFromRelatedEntity);
        }
    }

    // ----------------------- Private logic ------------------------

    private getTimelineDateValue(entity: Entity): Date
    {
        const fieldPath =
            this.props.timelineDateFieldPath
                && this.props.timelineDateFieldPath(entity);

        if (fieldPath)
        {
            return fieldPath.getDataObject(entity).value;
        }
        else
        {
            return undefined;
        }
    }
}
