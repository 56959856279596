import useAsyncResult from '../../../@Util/Async/useAsyncResult';
import useApiClient from '../../../@Service/ApiClient/Hooks/useApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { PortalTheme } from '../Theme/PortalTheme';
import { usePortalThemeFavicon } from './usePortalThemeFavicon';
import { usePortalThemeTitle } from './usePortalThemeTitle';

export function usePortalTheme(
    organizationId: string | undefined,
    portalId: string | undefined
): [PortalTheme | undefined, boolean]
{
    const apiClient = useApiClient();

    const [ portalTheme, isLoading ] =
        useAsyncResult(
            () =>
            {
                if (organizationId && portalId)
                {
                    return apiClient.request(
                        new ApiRequest<PortalTheme>(
                            `/public/portal/theme/${organizationId}/${portalId}`,
                            Method.Get
                        )
                    );
                }
                else
                {
                    return undefined;
                }
            },
            [
                organizationId,
                portalId,
            ]
        );

    usePortalThemeFavicon(portalTheme);
    usePortalThemeTitle(portalTheme);

    if (organizationId && portalId)
    {
        return [
            portalTheme,
            isLoading
        ];
    }
    else
    {
        return [
            undefined,
            false
        ];
    }
}