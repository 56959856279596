import React from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import CardHeader from '../../../../../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import ExpansionGroup from '../../../../../../../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import Header from '../../../../../../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import ExpansionPanel from '../../../../../../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import useDividerGlue from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import QueryFilterEditor from '../QueryFilterEditor';
import ListQuery from '../../../../../../../../../../../../@Api/Automation/Query/ListQuery';
import { QueryEditorProps } from '../QueryEditor';
import ListQueryOptionsEditor from './Options/ListQueryOptionsEditor';
import ListQueryOrderingsEditor from './Orderings/ListQueryOrderingsEditor';
import ListQueryJoinPathsEditor from './JoinPaths/ListQueryJoinPathsEditor';
import LocalizedText from '../../../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface ListQueryEditorProps extends QueryEditorProps<ListQuery>
{

}

const ListQueryEditor: React.FC<ListQueryEditorProps> =
    props =>
    {
        const dividerGlue = useDividerGlue();

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <CardInset>
                    <CardHeader>
                        <LocalizedText
                            code="Generic.Select"
                            value="Selecteer"
                        />
                        &nbsp; {props.query.entityType.getName(true).toLowerCase()}
                    </CardHeader>
                </CardInset>
            </ViewGroupItem>
            <ViewGroupItem>
                <ExpansionGroup>
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                        glue={dividerGlue}
                    >
                        <ViewGroupItem>
                            <ExpansionPanel
                                id="options"
                                summary={
                                    <Header
                                        title={
                                            <LocalizedText
                                                code="Generic.Options"
                                                value="Opties"
                                            />
                                        }
                                        inset
                                    />
                                }
                                expansion={
                                    <CardInset
                                        horizontal
                                        top={false}
                                    >
                                        <ListQueryOptionsEditor
                                            {...props}
                                        />
                                    </CardInset>
                                }
                                expanded
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ExpansionPanel
                                id="filter"
                                summary={
                                    <Header
                                        title={
                                            <LocalizedText
                                                code="Generic.Filters"
                                                value="Filters"
                                            />
                                        }
                                        inset
                                    />
                                }
                                expansion={
                                    <CardInset
                                        horizontal
                                        top={false}
                                    >
                                        <QueryFilterEditor
                                            {...props}
                                        />
                                    </CardInset>
                                }
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ExpansionPanel
                                id="ordering"
                                summary={
                                    <Header
                                        title={
                                            <LocalizedText
                                                code="Generic.Sorting"
                                                value="Sortering"
                                            />
                                        }
                                        inset
                                    />
                                }
                                expansion={
                                    <CardInset
                                        horizontal
                                        top={false}
                                    >
                                        <ListQueryOrderingsEditor
                                            {...props}
                                        />
                                    </CardInset>
                                }
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ExpansionPanel
                                id="joinPaths"
                                summary={
                                    <Header
                                        title={
                                            <LocalizedText
                                                code="Generic.RelatedData"
                                                value="Gerelateerde data ophalen"
                                            />
                                        }
                                        inset
                                    />
                                }
                                expansion={
                                    <CardInset
                                        horizontal
                                        top={false}
                                    >
                                        <ListQueryJoinPathsEditor
                                            {...props}
                                        />
                                    </CardInset>
                                }
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </ExpansionGroup>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(ListQueryEditor);
