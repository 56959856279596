import * as React from 'react';
import { Draggable, DraggableProps, DraggableProvidedDraggableProps, DraggableStateSnapshot, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import { AppBodyPortal } from '../Portal/AppBodyPortal';

export interface DraggableElementHandlerProps
{
    onMouseUp: (provided: DraggableProvidedDraggableProps, event: any) => void;
    onMouseDown: (provided: DraggableProvidedDraggableProps, event: any) => void;
    onDragStart: (provided: DraggableProvidedDraggableProps, event: any) => void;
}

export interface DraggableElementProps extends DraggableProps
{
    dragHandleProps?: DraggableElementHandlerProps;
    dragStyleHandler?: (style: DraggingStyle | NotDraggingStyle, isDragging: boolean) => DraggingStyle | NotDraggingStyle;
    isTableRow?: boolean;
    elevateOnDrag?: boolean
    draggableBackgroundColor?: string;
}
export class DraggableElement extends React.Component<DraggableElementProps>
{
    draggablePortal(isDragging: boolean, element: JSX.Element)
    {
        const elementStyle = `background-color: ${this.props.draggableBackgroundColor ? this.props.draggableBackgroundColor : 'transparent'};
                              box-shadow: ${this.props.elevateOnDrag ? 'rgba(0,0,0,0.2) 0 2px 8px' : 'none'};`;

        if (isDragging)
        {
            if (this.props.isTableRow)
            {
                return <AppBodyPortal>
                    <style>
                        {`.draggableElementStyle_dragging tr { ${elementStyle} }`}
                    </style>
                    <table
                        className={'draggableElementStyle_dragging'}
                    >
                        <tbody>
                            {element}
                        </tbody>
                    </table>
                </AppBodyPortal>;
            }
            else
            {
                return <AppBodyPortal>
                    <style>
                        {`.draggableElementStyle_dragging { ${elementStyle} }`}
                    </style>
                    <div
                        className={'draggableElementStyle_dragging'}
                    >
                        {element}
                    </div>
                </AppBodyPortal>;
            }
        }
        else
        {
            return element;
        }
    }

    private parseStyleHandler(provided: DraggableProvidedDraggableProps,
                              draggingStyleHandler: (style: DraggingStyle | NotDraggingStyle, isDragging: boolean) => DraggingStyle | NotDraggingStyle,
                              snapshot: DraggableStateSnapshot)
    {
        if (draggingStyleHandler)
        {
            provided.style = draggingStyleHandler(provided.style, snapshot.isDragging);
        }

        return provided;
    }

    render()
    {
        const item = this.props;

        return <Draggable
            draggableId={this.props.draggableId}
            index={this.props.index}
            isDragDisabled={this.props.isDragDisabled}
            disableInteractiveElementBlocking={this.props.disableInteractiveElementBlocking}
            // type={this.props.type}
        >
            {(provided, snapshot, rubric) => {

                this.parseStyleHandler(provided.draggableProps, this.props.dragStyleHandler, snapshot);

                return (
                    <>
                        {
                            this.draggablePortal(snapshot.isDragging,
                                item.children(provided, snapshot, rubric)
                            )
                        }
                    </>
                );
            }}
        </Draggable>;
    }
}
