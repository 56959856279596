import Value from './Value';
import { Entity } from '../../Model/Implementation/Entity';
import EntityValueType from './Type/EntityValueType';
import getEntityByUuid from '../../Entity/Bespoke/getEntityByUuid';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { ConstantComputation } from '../../../@Component/Domain/Computation/Type/Constant/ConstantComputation';
import { DataObject } from '../../../@Component/Domain/DataObject/Model/DataObject';
import equalsEntity from '../../Entity/Bespoke/equalsEntity';
import AutomationDependencyContext from '../AutomationDependencyContext';
import getEntitiesByUuids from '../../Entity/Bespoke/getEntitiesByUuids';
import getTypes from '../../../@Component/Domain/Entity/Type/Api/getTypes';
import { deduplicateArray } from '../../../@Util/Array/deduplicateArray';

export default class EntityValue extends Value<Entity, EntityValueType>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getId(): string
    {
        return `Entity(${this.value.uuid})`;
    }

    getType(): EntityValueType
    {
        return new EntityValueType(this.value.entityType);
    }

    getName(): string
    {
        return this.value.name || '';
    }

    equals(otherValue: Value<any, any>)
    {
        return otherValue instanceof EntityValue
            && equalsEntity(this.value, otherValue.value);
    }

    clone(): EntityValue
    {
        return new EntityValue(this.value);
    }

    augmentDescriptor(descriptor)
    {
        descriptor.type = 'Entity';
        descriptor.entityTypeId = this.value.entityType.id;
        descriptor.value = this.value.uuid;
    }

    toOldComputation(): any
    {
        return new ConstantComputation(
            DataObject.constructFromTypeIdAndValue(
                'Entity',
                this.value));
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext
    )
    {
        const entityTypeStore = loadModuleDirectly(EntityTypeStore);
        const entityType = entityTypeStore.getTypeById(descriptor.entityTypeId);

        if (dependencyContext.deferredInitializer)
        {
            return dependencyContext.deferredInitializer.defer(
                descriptor,
                new EntityValueType(entityType),
                descriptor =>
                    descriptor.value,
                value =>
                    value.value.uuid,
                async descriptors =>
                {
                    const entityIds: string[] =
                        deduplicateArray(
                            descriptors.map(
                                descriptor =>
                                    descriptor.value as string
                            )
                        ).sort();
                    const entities =
                        await getEntitiesByUuids(
                            getTypes().Entity.Type,
                            entityIds
                        );

                    return entities.value.map(
                        entity =>
                            new EntityValue(entity)
                    );
                }
            );
        }
        else
        {
            const { value: entity } = await getEntityByUuid(entityType, descriptor.value);

            if (!entity)
            {
                throw new Error(`entity with id: ${descriptor.value} not found`);
            }

            return new EntityValue(entity);
        }
    }

    // ----------------------- Private logic ------------------------
}
