import { observable } from 'mobx';
import { EntitySelectionAggregateResult } from '../../Domain/Entity/Selection/Model/EntitySelectionAggregateResult';
import { DataObject } from '../../Domain/DataObject/Model/DataObject';
import { EntitySelectionAggregate } from '../../Domain/Entity/Selection/Model/EntitySelectionAggregate';

export class EntityQueryMatchResult
{
    // ------------------------- Properties -------------------------

    @observable valueByAggregate: Map<EntitySelectionAggregate, DataObject>;
    @observable resultByMatchedAggregate: Map<EntitySelectionAggregateResult, EntityQueryMatchResult>;

    // ------------------------ Constructor -------------------------

    constructor(valueByAggregate: Map<EntitySelectionAggregate, DataObject>,
                resultByMatchedAggregate: Map<EntitySelectionAggregateResult, EntityQueryMatchResult>)
    {
        this.valueByAggregate = valueByAggregate;
        this.resultByMatchedAggregate = resultByMatchedAggregate;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
