import React, { useMemo } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import Fields from '../../../Fields/Fields';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import CourseTemplateLessonCronPatternEditor from './LessonCronPatternEditor/CourseTemplateLessonCronPatternEditor';
import RelatedEntityView from '../../Shared/RelatedEntityView/RelatedEntityView';
import getCustomPluralRelatedPaths from '../Activity/Api/getCustomPluralRelatedPaths';
import { useRoutingState } from '../../../../../../@Service/Router/Model/Api/useRoutingState';
import PageTabBar, { TabFamily } from '../../Shared/Page/TabBar/PageTabBar';
import RelatedEntityCount from '../../Shared/RelatedEntityCount/RelatedEntityCount';

export interface CourseTemplateProps
{
    entity: Entity;
    commitContext: CommitContext;
}

const CourseTemplate: React.FC<CourseTemplateProps> =
    ({
         entity,
         commitContext,
     }) =>
    {
        const pluralRelatedPaths =
            useComputed(
                () =>
                    getCustomPluralRelatedPaths(entity.entityType),
                [
                    entity,
                ]
            );
        const firstPluralRelatedPath = pluralRelatedPaths.find(() => true);
        const [ tab, setTab ] =
            useRoutingState(
                `Entity.${entity.id}.TabId`,
                firstPluralRelatedPath === undefined
                    ? undefined
                    : `PluralRelatedPaths:${firstPluralRelatedPath.id}`
            );
        const tabFamilies =
            useMemo<TabFamily[]>(
                () => [
                    {
                        id: 'PluralRelatedPaths',
                        tabs:
                            pluralRelatedPaths.map(
                                path =>
                                    ({
                                        id: path.id,
                                        name: path.getName(undefined, false),
                                        nameAppendix:
                                            <RelatedEntityCount
                                                entity={entity}
                                                path={path}
                                            />,
                                        content:
                                            () =>
                                                <RelatedEntityView
                                                    entity={entity}
                                                    path={path}
                                                />,
                                    })
                            )
                    }
                ],
                [
                    entity,
                    pluralRelatedPaths,
                ]
            );

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset
                                    bottom={false}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <TypeAndName
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                                <Fields
                                    entity={entity}
                                    commitContext={commitContext}
                                />
                                <CardInset>
                                    <CourseTemplateLessonCronPatternEditor
                                        entity={entity}
                                        commitContext={commitContext}
                                    />
                                </CardInset>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <PageTabBar
                        entity={entity}
                        tabFamilies={tabFamilies}
                        tabId={tab}
                        onChangeTab={setTab}
                        commitContext={commitContext}
                    />
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(CourseTemplate);
