import { observable } from 'mobx';
import ParameterAssignment from '../../Automation/Parameter/ParameterAssignment';
import PortalDataSourceSignature from './PortalDataSourceSignature';
import PortalDataSourceQuery from './PortalDataSourceQuery';
import Parameter from '../../Automation/Parameter/Parameter';

export default class PortalDataSourceFileQuery extends PortalDataSourceQuery
{
    // ------------------------- Properties -------------------------

    @observable recordId: string;
    @observable.ref fileField: Parameter<any>;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                dataSourceSignature: PortalDataSourceSignature,
                parameterAssignment: ParameterAssignment,
                recordId: string,
                fileField: Parameter<any>)
    {
        super(id, dataSourceSignature, parameterAssignment, undefined);

        this.recordId = recordId;
        this.fileField = fileField;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'File',
            recordId: this.recordId,
            fileFieldId: this.fileField.id
        };
    }

    // ----------------------- Private logic ------------------------
}
