import * as React from 'react';
import { DataObjectBespokeEditor } from '../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { observer } from 'mobx-react';
import { NumberEditorStore } from './NumberEditorStore';
import { default as GenericNumberEditor } from '../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';

@observer
export class NumberEditor extends DataObjectBespokeEditor<NumberEditorStore>
{
    renderComponent(store: DataObjectEditorStore<NumberEditorStore>): any
    {
        return <GenericNumberEditor
            value={store.dataObject.value}
            onChange={store.changeValue}
            inputProps={store.handlers as any}
            disableUnderline={!store.hasUnderline}
            alignment={store.alignmentAsString}
            placeholder={store.placeholder}
            autoFocus={store.isFocused}
            disabled={store.isDisabled}
            disableThousandSeparator={store.representation?.data?.disableThousandSeparator}
            error={store.showError}
            fitContent={store.representation?.data?.fitContent}
        />;
    }
}
