import { reference, type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';
import { Account } from './Account';
import { User } from './User';

@type('AccountSession')
export class AccountSession
{
    // ------------------- Persistent Properties --------------------

    @observable id: number;
    @reference(undefined, 'Account') @observable account: Account;
    @reference(undefined, 'User') @observable user: User;
    @observable startDate: number;
    @observable lastActivityDate: number;
    @observable endDate: number;
    @observable accessToken: string;
    @observable refreshToken: string;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
