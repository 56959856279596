import { BaseType } from '../../../../@Framework/Store/@Model/BaseType';
import { ComponentClass } from 'react';
import { PredicateEditorStore } from '../PredicateEditorStore';
import { PredicateSpecification } from './PredicateSpecification';
import { PredicateContext } from '../PredicateContext';
import { EntityFieldPath } from '../../Entity/Path/@Model/EntityFieldPath';
import { EntityContext } from '../../Entity/@Model/EntityContext';
import { OldPredicate } from './OldPredicate';

export abstract class PredicateType<T extends PredicateEditorStore = PredicateEditorStore, U extends PredicateSpecification = PredicateSpecification, M extends OldPredicate = OldPredicate> implements BaseType
{
    abstract id(): string;

    abstract name(): string;

    abstract allow(context: PredicateContext): boolean;

    abstract isTerminal(): boolean;

    abstract fromSpecification(specification: U): M;

    abstract toSpecification(predicate: M): U;

    abstract evaluate(context: PredicateContext,
                      predicate: M): boolean;

    abstract editorStore(context: PredicateContext,
                         specification: U): T;

    abstract editorView(): ComponentClass<any>;

    abstract editorSpecification(store: T): U;

    abstract entityFieldPaths(specification: U,
                              context: EntityContext,
                              parameter?: string): EntityFieldPath[];
}
