import Computation from '../Function/Computation/Computation';
import AutomationDependencyContext from '../AutomationDependencyContext';
import getComputationFromDescriptor from './getComputationFromDescriptor';
import { ComputationSubstitution } from '../Function/Computation/Util/ComputationSubstitution';

export async function getSubstitutedComputation(
    computation: Computation<any, any>,
    dependencyContext: AutomationDependencyContext,
    substition: ComputationSubstitution
): Promise<Computation<any, any>>
{
    const descriptor = computation.toDescriptor();
    const substitionDependencyContext =
        new AutomationDependencyContext(
            dependencyContext.parameterDictionary,
            undefined,
            substition
        );

    return getComputationFromDescriptor(
        descriptor,
        substitionDependencyContext
    );
}