import { computed, observable } from 'mobx';

export class ListQuery<Q>
{
    // ------------------------- Properties -------------------------

    @observable selection: Q;
    @observable searchQuery: string;
    @observable offset: number;
    @observable limit: number;

    // ------------------------ Constructor -------------------------

    constructor(selection: Q,
                searchQuery?: string,
                offset: number = 0,
                limit: number = 50)
    {
        this.selection = selection;
        this.searchQuery = searchQuery;
        this.offset = offset;
        this.limit = limit;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get hasSearchQuery(): boolean
    {
        return this.searchQuery && this.searchQuery.trim().length > 0;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
