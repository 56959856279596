import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Parameter from '../../../../../../../../../@Api/Automation/Parameter/Parameter';
import Input from '../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { runInAction } from 'mobx';
import { DynamicParameterAssignmentEditorProps } from '../DynamicParameterAssignmentEditor';
import ComputationEditor from '../../Computation/ComputationEditor';
import Computation from '../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationBox from '../../Computation/Box/ComputationBox';

export interface ParameterValueEditorProps extends DynamicParameterAssignmentEditorProps
{
    parameter: Parameter<any>;
}

const ParameterValueEditor: React.FC<ParameterValueEditorProps> =
    props =>
    {
        const value =
            useComputed(
                () =>
                    props.parameterAssignment.getComputation(props.parameter),
                [
                    props.parameterAssignment,
                    props.parameter
                ]);

        const setValue =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.parameterAssignment.setComputation(props.parameter, value)),
                [
                    props.parameterAssignment,
                    props.parameter
                ]);

        return <Input
            labelPosition="top"
            label={props.parameter.name}
        >
            <ComputationBox>
                <ComputationEditor
                    context={props.context}
                    type={props.parameter.type}
                    value={value}
                    onChange={setValue}
                />
            </ComputationBox>
        </Input>;
    };

export default observer(ParameterValueEditor);
