import React, { MouseEventHandler, useCallback, useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LocalizerContext from '../../../../../../../../../../../../../../@Service/Localization/LocalizerContext';
import useIsDueToday from './Api/useIsDueToday';
import useIsOverdue from './Api/useIsOverdue';
import { orange } from '@material-ui/core/colors';
import { EntityField } from '../../../../../../../../../../../../../../@Api/Model/Implementation/EntityField';
import { Editor } from '../../../../../../../../../../../Planner/Editor/Editor';
import Popper from '../../../../../../../../../../../../../../@Future/Component/Generic/Popper/Popper';
import moment from 'moment';
import styles from './DateTime.module.scss';
import Input from '../../../../../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { ContentProps } from '../../../Content';
import { isAllDay } from '../../../../../../../../../../../Calendar/Util/isAllDay';
import { EntityPath } from '../../../../../../../../../../../Path/@Model/EntityPath';
import { Entity } from '../../../../../../../../../../../../../../@Api/Model/Implementation/Entity';
import LocalizedText from '../../../../../../../../../../../../Localization/LocalizedText/LocalizedText';
import { CommitBuilder } from '../../../../../../../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export interface DateTimeProps extends ContentProps
{
    startDateField: EntityField;
    endDateField: EntityField;
    employeePath?: EntityPath;
}

const DateTime: React.FC<DateTimeProps> =
    props =>
    {
        const [ isOpen, setOpen ] = useState(false);
        const localizer = useContext(LocalizerContext);

        const openPopper =
            useCallback<MouseEventHandler>(
                e =>
                {
                    if (e.stopPropagation)
                    {
                        e.stopPropagation();
                    }

                    setOpen(true)
                },
                [
                    setOpen
                ]);

        const closePopper =
            useCallback(
                () =>
                    setOpen(false),
                [
                    setOpen
                ]);

        const startMomentDate =
            useComputed(
                () =>
                {
                    if (props.entity.hasValueForField(props.startDateField, props.commitContext))
                    {
                        return moment(props.entity.getObjectValueByField(props.startDateField, props.commitContext));
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.entity,
                    props.startDateField,
                    props.commitContext,
                ]);

        const endMomentDate =
            useComputed(
                () =>
                {
                    if (props.entity.hasValueForField(props.endDateField, props.commitContext))
                    {
                        return moment(props.entity.getObjectValueByField(props.endDateField, props.commitContext));
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.entity,
                    props.endDateField,
                    props.commitContext,
                ]);

        const isDueToday = useIsDueToday(props.entity, props.startDateField, props.commitContext);
        const isOverdue = useIsOverdue(props.entity, props.endDateField, props.commitContext);

        const color =
            useMemo(
                () =>
                {
                    if (isDueToday)
                    {
                        return orange[200];
                    }
                    else if (isOverdue)
                    {
                        return 'rgb(208, 2, 27)';
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    isDueToday,
                    isOverdue
                ]);

        const onClose =
            useCallback(
                (dateField?: EntityField,
                 date?: Date,
                 endDateField?: EntityField,
                 endDate?: Date,
                 employeePath?: EntityPath,
                 employee?: Entity) =>
                {
                    setOpen(false);

                    if (dateField !== undefined && date !== undefined)
                    {
                        return new CommitBuilder(props.commitContext)
                            .setObjectValueInEntity(
                                props.entity,
                                dateField,
                                date
                            )
                            .ifValid(
                                () =>
                                    endDateField !== undefined && endDate !== undefined,
                                builder =>
                                    builder.setObjectValueInEntity(
                                        props.entity,
                                        endDateField,
                                        endDate
                                    )
                            )
                            .ifValid(
                                () =>
                                    employeePath !== undefined && employee !== undefined,
                                builder =>
                                    builder.setObjectValueInEntityByFieldPath(
                                        props.entity,
                                        employeePath.field(),
                                        employee
                                    )
                            )
                            .commit();
                    }
                },
                [
                    setOpen,
                    props.commitContext,
                    props.entity,
                ]);

        const _isAllDay =
            useComputed(
                () =>
                    startMomentDate && endMomentDate &&
                        isAllDay(
                            startMomentDate.toDate(),
                            endMomentDate.toDate()),
                [
                    startMomentDate,
                    endMomentDate
                ]);

        const numberOfDays =
            useComputed(
                () =>
                    startMomentDate && endMomentDate && endMomentDate.diff(startMomentDate, 'days'),
                [
                    startMomentDate,
                    endMomentDate
                ]);

        return <Popper
            reference={
                <div
                    className={styles.root}
                >
                    <Input
                        label={
                            <LocalizedText
                                code="DateTime.On"
                                value="Op"
                            />
                        }
                        labelPosition={props.labelPosition}
                        inline={props.inlineLabel}
                    >
                        <span
                            onClick={openPopper}
                            style={{
                                color: color
                            }}
                            className={styles.label}
                        >
                            {localizer.formatDate(startMomentDate, true)}{_isAllDay ? (numberOfDays > 1 ? `-${localizer.formatDate(endMomentDate, true)}` : <>, <LocalizedText code="Generic.AllDay" value="hele dag" /></>) : ` ${localizer.formatTime(startMomentDate)}-${localizer.formatTime(endMomentDate)}`}
                        </span>
                    </Input>
                </div>
            }
            popper={
                <Editor
                    entity={props.entity}
                    onClose={onClose}
                    startDateField={props.startDateField}
                    endDateField={props.endDateField}
                    employeePath={props.employeePath}
                />
            }
            open={isOpen}
            onClose={closePopper}
            fullWidth
        />;
    };

export default observer(DateTime);
