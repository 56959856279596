import { EntityType } from '../../Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { SettingSource, SettingStore } from '../../../@Component/Domain/Setting/SettingStore';
import { EntityFieldPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import { safelyGetOrUndefined } from '../../../@Util/Exception/safelyGetOrUndefined';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';

export type FieldOrdering = { [key: string]: number };

export default function getFieldOrderingInType(
    entityType: EntityType,
    baseType?: EntityType
): FieldOrdering
{
    if (entityType.hasParent())
    {
        return {
            ...getFieldOrderingInType(
                entityType.parentType,
                baseType ?? entityType
            ),
            ...getFieldOrderingForSpecificType(
                entityType,
                baseType
            ),
        };
    }
    else
    {
        return getFieldOrderingForSpecificType(
            entityType,
            baseType
        );
    }
}

function getFieldOrderingForSpecificType(
    entityType: EntityType,
    childType?: EntityType
): FieldOrdering
{
    const fields =
        (loadModuleDirectly(SettingStore)
            .getValue(
                SettingSource.Organization,
                'Metadata.FieldOrdering'
            )[entityType.id] || [])
            .map(
                descriptor =>
                    safelyGetOrUndefined(
                        () =>
                            EntityFieldPath.construct(descriptor)
                    )
            )
            .filter(
                fieldPath =>
                    fieldPath !== undefined
            );

    const ordering: FieldOrdering = {};

    fields
        .filter(
            field =>
                field !== undefined
        )
        .forEach(
            (field, idx) =>
                ordering[
                    getFinalFieldId(
                        field.id,
                        entityType,
                        childType
                    )
                ] = idx
        );

    return ordering;
}

function getFinalFieldId(
    fieldId: string,
    entityType: EntityType,
    childType?: EntityType
): string
{
    if (childType === undefined)
    {
        return fieldId;
    }
    else
    {
        return fieldId.replace(
            EntityPath.root(entityType).id,
            EntityPath.root(childType).id,
        );
    }
}