import Role, { BasePermission, Permission, Privilege, RoleType } from '../../../Model/Role';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { getEntityTypeSpecifiedPermission } from './getEntityTypeSpecifiedPermission';

export function getEntityTypeInheritedPermission(
    rolesByType: Map<RoleType, Role>,
    role: Role,
    privilege: Privilege,
    entityType: EntityType
): Permission
{
    if (entityType.parentType)
    {
        const parentTypePermission =
            getEntityTypeSpecifiedPermission(
                rolesByType,
                role,
                privilege,
                entityType.parentType
            );

        if (parentTypePermission === 'Inherited')
        {
            return getEntityTypeInheritedPermission(
                rolesByType,
                role,
                privilege,
                entityType.parentType
            );
        }
        else
        {
            return parentTypePermission;
        }
    }

    return BasePermission;
}