import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import Switch from '../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import { Field } from '../../Model/Field';
import { runInAction } from 'mobx';
import resolveInputFromFieldPath from '../../../../../../Multiplayer/Model/Input/Api/resolveInputFromFieldPath';
import setNameFieldOverrideInType from '../../../../../../../../@Api/Metadata/Field/setNameFieldOverrideInType';

export interface IsNameEditorProps
{
    entityType: EntityType;
    field: Field;
    onSave: (field: Field) => void;
}

const IsNameEditor: React.FC<IsNameEditorProps> =
    props =>
    {
        const input =
            useComputed(
                () =>
                    props.field.fieldPath &&
                        resolveInputFromFieldPath(props.field.fieldPath),
                [
                    props.field
                ]);

        const onChange =
            useCallback(
                (value: boolean) =>
                    runInAction(
                        () =>
                        {
                            props.field.isName = value;

                            if (input && !props.field.isEditable)
                            {
                                return setNameFieldOverrideInType(
                                    props.entityType,
                                    value ? input : undefined)
                                    .then(
                                        () =>
                                            props.onSave(props.field));
                            }
                        }),
                [
                    props.field,
                    props.entityType,
                    props.onSave
                ]);

        return <Switch
            checked={props.field.isName}
            onToggle={onChange}
        />;
    };

export default observer(IsNameEditor);
