import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import CurrentUserContext from '../../User/CurrentUserContext';
import Centered from '../../../../@Future/Component/Generic/Centered/Centered';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CloseButton from '../../../../@Future/Component/Generic/Button/Variant/CloseButton/CloseButton';
import ButtonGroup from '../../../../@Future/Component/Generic/Button/ButtonGroup/ButtonGroup';
import PrimaryTextButton from '../../../../@Future/Component/Generic/Button/Variant/PrimaryTextButton/PrimaryTextButton';
import RouterContext from '../../../../@Service/Router/RouterContext';
import styles from './CreatorEditionRequired.module.scss';

export interface CreatorEditionRequiredProps
{
    onClose: () => void;
    disabled?: boolean;
}

const CreatorEditionRequired: React.FC<CreatorEditionRequiredProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const routerStore = useContext(RouterContext);

        const gotoUpgradePage =
            useCallback(
                () =>
                    routerStore.route('/account'),
                [
                    routerStore
                ]);

        if (currentUserStore.isNonEssentialEdition || props.disabled)
        {
            return <>{props.children}</>;
        }
        else
        {
            return <div
                className={styles.root}
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <Centered
                            horizontal
                        >
                            <LocalizedText
                                code="Edition.Creator.Required"
                                value="Deze functionaliteit vereist de Tribe Creator editie."
                            />
                        </Centered>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <ButtonGroup>
                            <PrimaryTextButton
                                label={
                                    <LocalizedText
                                        code="Generic.ChooseEdition"
                                        value="Kies editie"
                                    />
                                }
                                onClick={gotoUpgradePage}
                                color="white"
                                hoverColor="white"
                            />
                            <CloseButton
                                onClick={props.onClose}
                                color="white"
                            />
                        </ButtonGroup>
                    </ViewGroupItem>
                    <ViewGroupItem>

                    </ViewGroupItem>
                </ViewGroup>
            </div>;
        }
    };

export default observer(CreatorEditionRequired);
