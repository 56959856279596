import { useMemo } from 'react';

export default function useAllSet(values: any[]): boolean
{
    return useMemo(
        () =>
            values
            && values.filter(
                v =>
                    v === undefined).length === 0,
        [
            values
        ]);
}
