import React from 'react';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import StaticSelectbox from '../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';

interface DayOfWeekIndexEditorProps
{
    value: number;
    onChange: (value: number) => void;
    disabled?: boolean;
}

export const DayOfWeekIndexEditor: React.FC<DayOfWeekIndexEditorProps> =
    ({
        value,
        onChange,
        disabled,
     }) =>
    {
        return <StaticSelectbox
            options={[
                {
                    id: 'First',
                    value: 1,
                    label:  localizeText('Generic.First', 'Eerste'),
                },
                {
                    id: 'Second',
                    value: 2,
                    label:  localizeText('Generic.Second', 'Tweede'),
                },
                {
                    id: 'Third',
                    value: 3,
                    label:  localizeText('Generic.Third', 'Derde'),
                },
                {
                    id: 'Fourth',
                    value: 4,
                    label:  localizeText('Generic.Fourth', 'Vierde'),
                },
                {
                    id: 'Last',
                    value: -1,
                    label:  localizeText('Generic.Last', 'Laatste'),
                },
            ]}
            onChange={onChange}
            value={value}
            disabled={disabled}
        />;
    };
