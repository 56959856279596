import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import View from '../Model/View';
import getSystemDefaultView from './getSystemDefaultView';
import getViewParameters from './getViewParameters';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import useTypes from '../../Type/Api/useTypes';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';

export function useDefaultView(
    entityType: EntityType,
    viewParameters?: ParameterDictionary
)
{
    // Returns the default view to use for a specific entitytype
    //    - View for this entitytype with is isDefault == true in this environment pack
    //    - View for this entitytype with is isDefault == true in any other pack in this environment
    //    - systemDefaultView (hardcoded view)

    const types = useTypes();
    const currentUserStore = loadModuleDirectly(CurrentUserStore);
    const currentUser = currentUserStore.currentUser;

    return useAsyncResult<View>(
        async () =>
        {
            // Get all views for entityType with isDefault == true
            return EntitySelectionBuilder.builder(
                types.View.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath
                                        .joinTo(
                                            types.EntityType.RelationshipDefinition.Views,
                                            true
                                        ),
                                    entityType.entity
                                )
                        )
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.View.Field.IsDefault),
                                    undefined,
                                    true
                                )
                        )
            )
                .select()
                .then(
                    async results =>
                    {
                        if (results.length === 0)
                        {
                            // No defaults view found, return hardcoded SystemDefault view
                            return getSystemDefaultView(
                                entityType,
                                viewParameters || getViewParameters(entityType)
                            );
                        }
                        else
                        {
                            // One or more views found with isDefault == true, prefer from EnvironmentPack
                            // if not found than take the first view from result
                            const preferredViewFromResults =
                                results.find(
                                    view => {
                                        const pack =
                                            view.entity.getRelatedEntityByDefinition(
                                                true,
                                                types.Pack.RelationshipDefinition.Entities
                                            );

                                        return pack.entityType.isA(types.Pack.Environment.Type);
                                    }
                                ) ??
                                results[0];

                            try
                            {
                                return await View.fromDescriptor(
                                        preferredViewFromResults.entity.getObjectValueByField(types.View.Field.Specification),
                                        preferredViewFromResults.entity
                                    )
                            }
                            catch (e)
                            {
                                console.error(`Error deserializing view with ID: ${preferredViewFromResults?.entity.id}`, e);
                                return getSystemDefaultView(
                                    entityType,
                                    viewParameters || getViewParameters(entityType)
                                );
                            }
                        }
                    }
                )
                .catch(
                    error => {
                        console.error(`Error retrieving default views for entity type ${entityType.code}: ${error}`);
                        return getSystemDefaultView(
                            entityType,
                            viewParameters || getViewParameters(entityType)
                        )
                    }
                )
        },
        [
            types,
            entityType,
            viewParameters,
            currentUser
        ]
    );
}