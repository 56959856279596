import { observable } from 'mobx';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Dependency from '../../Parameter/Dependency';
import Validation from '../../Validation/Validation';
import Parameter from '../../Parameter/Parameter';
import EntityValueType from '../../Value/Type/EntityValueType';
import Predicate from '../../Function/Computation/Predicate/Predicate';
import getPredicateFromDescriptor from '../../Api/getPredicateFromDescriptor';

export default class Key
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref parameter: Parameter<EntityValueType>;
    @observable.ref predicate: Predicate;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                parameter: Parameter<EntityValueType>,
                predicate: Predicate)
    {
        this.id = id;
        this.parameter = parameter;
        this.predicate = predicate;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return this.predicate.validate();
    }

    isAsync(): boolean
    {
        return this.predicate.isAsync();
    }

    getDependencies(): Dependency[]
    {
        return this.predicate.getDependencies()
            .filter(
                dependency =>
                    dependency.parameter !== this.parameter);
    }

    toDescriptor()
    {
        return {
            id: this.id,
            predicate: this.predicate.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext,
                                parameter: Parameter<EntityValueType>)
    {
        const parameterDictionary =
            dependencyContext.parameterDictionary
                .getNewDictionaryWithParameter(parameter);

        return new Key(
            descriptor.id,
            parameter,
            await getPredicateFromDescriptor(
                descriptor.predicate,
                new AutomationDependencyContext(parameterDictionary)));
    }

    // ----------------------- Private logic ------------------------
}
