import { useContext, useMemo } from 'react';
import NavigatorContext from '../NavigatorContext/NavigatorContext';
import useIsContextExpanded from './useIsContextExpanded';

export default function useContextExpansionPath()
{
    const context = useContext(NavigatorContext);
    const isExpanded = useIsContextExpanded();

    return useMemo(
        () =>
        {
            if (isExpanded)
            {
                return context.selectedPath;
            }
            else
            {
                return undefined;
            }
        },
        [
            isExpanded,
            context.selectedPath
        ]);
}