import React from 'react';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import CardHeader from '../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import styles from '../Legend/RoleLegend.module.scss';
import { RoleLegendItem, RoleLegendItemProps } from './Item/RoleLegendItem';

export interface RoleLegendProps
{
}

export const RoleLegend: React.FC<RoleLegendProps> =
    observer(
        () =>
        {
            const dividerGlue = useDividerGlue();

            const roleLegendItems: RoleLegendItemProps[] = [
                {
                    resourceCode: 'RoleLegend.InheritedGranted',
                    resourceValue: 'Verleend',
                    tooltipResourceCode: 'RoleLegend.InheritedGrantedTooltip',
                    tooltipResourceValue: 'het recht is beschikbaar voor de gebruiker. Het recht is toegekend op basis van overerving uit de moederentiteit',
                    inheritedPermission: 'Granted',
                },
                {
                    resourceCode: 'RoleLegend.Granted',
                    resourceValue: 'Verleend (expliciet ingesteld)',
                    tooltipResourceCode: 'RoleLegend.GrantedTooltip',
                    tooltipResourceValue: 'het recht is beschikbaar voor de gebruiker. Het recht is toegekend op de specifieke entiteit of veld in deze rol',
                    permission: 'Granted',
                },
                {
                    resourceCode: 'RoleLegend.InheritedDenied',
                    resourceValue: 'Geweigerd',
                    tooltipResourceCode: 'RoleLegend.InheritedDeniedTooltip',
                    tooltipResourceValue: 'het recht is niet beschikbaar voor de gebruiker. Het recht is ontnomen op basis van overerving uit de moederentiteit',
                    inheritedPermission: 'Denied',
                },
                {
                    resourceCode: 'RoleLegend.Denied',
                    resourceValue: 'Geweigerd (expliciet ingesteld)',
                    tooltipResourceCode: 'RoleLegend.DeniedTooltip',
                    tooltipResourceValue: 'het recht is niet beschikbaar voor de gebruiker. Het recht is ontnomen op de specifieke entiteit of veld in deze rol',
                    permission: 'Denied',
                },
                {
                    resourceCode: 'RoleLegend.Disabled',
                    resourceValue: 'Niet beschikbaar',
                    tooltipResourceCode: 'RoleLegend.DisabledTooltip',
                    tooltipResourceValue: 'het recht is niet instelbaar. In de bovenliggende entiteit is het recht geweigerd. Het recht is niet instelbaar in de dochterentiteit',
                    disabled: true,
                }
            ];

            return <Card>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    glue={dividerGlue}
                >
                    <ViewGroupItem>
                        <CardInset>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={0}
                            >
                                <ViewGroupItem
                                >
                                    <CardHeader>
                                        <LocalizedText
                                            code="RoleLegend.Title"
                                            value="Legenda"
                                        />
                                    </CardHeader>
                                </ViewGroupItem>
                            </ViewGroup>
                        </CardInset>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <CardInset>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={0}
                            >
                                <ViewGroupItem
                                    className={styles.legendParentWrapper}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={42}
                                        className={styles.legendParent}
                                    >
                                        {
                                            roleLegendItems
                                                .map(
                                                    item =>
                                                        <RoleLegendItem
                                                            key={item.resourceCode}
                                                            resourceCode={item.resourceCode}
                                                            resourceValue={item.resourceValue}
                                                            tooltipResourceCode={item.tooltipResourceCode}
                                                            tooltipResourceValue={item.tooltipResourceValue}
                                                            permission={item.permission}
                                                            inheritedPermission={item.inheritedPermission}
                                                            disabled={item.disabled}
                                                        />
                                            )
                                        }
                                    </ViewGroup>
                                </ViewGroupItem>
                            </ViewGroup>
                        </CardInset>
                    </ViewGroupItem>
                </ViewGroup>
            </Card>;
        }
    );

