import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { DataObjectViewerStore } from '../../../DataObject/Viewer/DataObjectViewerStore';
import { DataObjectEditorStore } from '../../../DataObject/Editor/Value/Editor/DataObjectEditorStore';
import { StoreState } from '../../../../../@Framework/Store/@Model/StoreState';
import { TextEditorStore } from '../../../DataObject/Type/Text/TextEditorStore';
import { ExpressionEditorStore } from '../../../Expression/Editor/ExpressionEditorStore';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';
import { ToolbarButtonStore } from '../../../../Generic/RichText/Toolbar/Types/Button/ToolbarButtonStore';
import { createTransactionalModel, isDirty, rollback, TransactionalModel } from '../../../../../@Util/TransactionalModelV2';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import deleteEntity from '../../../../../@Api/Entity/deleteEntity';

export class EntityCreateStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable isInEditMode: boolean;
    @observable isDeleted: boolean = false;
    @observable entity: TransactionalModel<Entity>;

    // ------------------------ Constructor -------------------------

    constructor(entity: Entity)
    {
        super();

        this.entity = createTransactionalModel(entity);
        this.entity.setManaged(true);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    @computed
    get viewerStore(): DataObjectViewerStore
    {
        const descriptionField =
            this.entity.entityType.getInheritedDescriptionField();

        if (descriptionField)
        {
            return new DataObjectViewerStore(this.entity.getDataObjectValueByField(descriptionField));
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get editorStore(): DataObjectEditorStore
    {
        const descriptionField =
            this.entity.entityType.getInheritedDescriptionField();

        if (descriptionField)
        {
            let editorStore = new DataObjectEditorStore({
                dataObject: this.entity.getDataObjectValueByField(descriptionField),
                isDisabled: this.isLoading
            });

            let textEditorStore = editorStore.bespokeStore as TextEditorStore;

            this.addControlButtons(textEditorStore.expressionEditorStore);

            return editorStore;
        }
        else
        {
            return undefined;
        }
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    addControlButtons(expressionEditor: ExpressionEditorStore)
    {
        expressionEditor.controls[0].unshift(
            [
                new ToolbarButtonStore(
                    'btnSave',
                    new ButtonStore({
                        label: this.localizationStore.translate('Generic.Save'), // Save
                        onClick: this.save,
                        isDisabled: () => (this.entity.descriptor === undefined || this.isLoading),
                        isRaised: true,
                        color: 'primary',
                        style:
                        {
                            height: 27,
                            padding: '0px 16px',
                            marginRight: 3
                        }
                    })),
                new ToolbarButtonStore(
                    'btnCancel',
                    new ButtonStore({
                        label: this.localizationStore.translate('Generic.Cancel'), // Cancel
                        onClick: this.cancel,
                        isDisabled: () => this.isLoading,
                        style:
                        {
                            height: 27,
                            padding: '0px 16px',
                            marginLeft: 3,
                            marginRight: 3
                        }
                    }))
            ]
        );
    }

    @action.bound
    setInReadMode()
    {
        this.isInEditMode = false;
    }

    @action.bound
    setInEditMode()
    {
        this.isInEditMode = true;
    }

    @action.bound
    setDeleted()
    {
        this.isDeleted = true;
    }

    @action.bound
    save(): Promise<any>
    {
        this.setState(StoreState.Loading);

        return this.entity.checkAndDoCommit()
            .then(() =>
            {
                this.setState(StoreState.Loaded);
                this.setInReadMode();
            });
    }

    @action.bound
    delete(): Promise<any>
    {
        this.setState(StoreState.Loading);

        return deleteEntity(this.entity)
            .then(() =>
            {
                this.setDeleted();
                this.setState(StoreState.Loaded);
            });
    }

    @action.bound
    cancel()
    {
        if (isDirty(this.entity))
        {
            rollback(this.entity);
        }

        this.setInReadMode();
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
