import {useComputed} from "mobx-react-lite";
import {useContext, useMemo} from "react";
import useTypes from "../../../../Type/Api/useTypes";
import AuthenticationManagerContext from "../../../../../../../@Service/Authentication/AuthenticationManagerContext";
import useWorkflowState from "../../../../Workflow/Api/useWorkflowState";
import { Entity } from "../../../../../../../@Api/Model/Implementation/Entity";

export interface FileDownloadOption
{
    url: string,
    code: string,
    value: string
}
export interface FileDownloadOptionsInterface
{
    isFinal: boolean,
    isConcept: boolean,
    previewDocumentUrl: string,
    finalizedDocumentUrl: string,
    downloadOptions: FileDownloadOption[]
}

export default function useFileDownloadOptions(
    entity: Entity,
    templateEntityType: string,
    documentTemplate?: Entity
): FileDownloadOptionsInterface {


    const types = useTypes();
    const state = useWorkflowState(entity);
    const authenticationManager = useContext(AuthenticationManagerContext);
    const apiClient = authenticationManager.apiClient;
    const baseDocumentUrl = `/entity/bespoke/${templateEntityType}/${entity.id}`
    const docxUrl =
        useMemo(
            () =>
                entity &&
                apiClient &&
                documentTemplate &&
                documentTemplate.hasValueForField(types.Template.Field.File)
                    ?
                    apiClient.url(`${baseDocumentUrl}/docx`)
                    :
                    undefined,
            [
                entity,
                types,
                apiClient,
                documentTemplate
            ]);
    const documentTemplateDocxUrl =
        useMemo(
            () =>
                entity &&
                apiClient &&
                documentTemplate &&
                documentTemplate.hasValueForField(types.Template.Field.File)
                    ?
                    apiClient.url(`/entity/bespoke/activity/${entity.id}/docx-template`)
                    :
                    undefined,
            [
                entity,
                types,
                apiClient,
                documentTemplate
            ]);
    const finalizedDocumentAttachment =
        useComputed(
            () =>
                entity.getRelatedEntityByDefinition(
                    false,
                    types.Activity.Document.RelationshipDefinition.DocumentAttachment),
            [
                entity,
                types
            ]);
    const finalizedDocumentUrl =
        useMemo(
            () =>
            {
                if (finalizedDocumentAttachment)
                {
                    const file = finalizedDocumentAttachment.getDataObjectValueByField(types.Attachment.Field.File);
                    return file.context.getFileUrl(file.value.url);
                }
                else
                {
                    return undefined;
                }
            },
            [
                finalizedDocumentAttachment,
                types
            ]);
    const previewDocumentUrl =
        useMemo(
            () =>
            {
                if (entity && apiClient)
                {
                    return apiClient.url(`${baseDocumentUrl}/pdf`);
                }
                else
                {
                    return undefined;
                }
            },
            [
                entity,
                apiClient
            ])
    const isConcept =
        useComputed(
            () =>
                state &&
                state.getObjectValueByField(types.Datastore.Field.Code) === 'Concept',
            [
                state
            ]);
    const isFinal =
        useComputed(
            () =>
                finalizedDocumentUrl !== undefined && !isConcept,
            [
                isConcept,
                finalizedDocumentUrl
            ])
    const downloadOptions =
        useMemo(
            () =>
            {
                const options: FileDownloadOption[] = [];
                if (docxUrl)
                {
                    options.push(
                        {
                            url: docxUrl,
                            code: "PDFViewer.DownloadPartialDocX",
                            value: "Download Word bestand"
                        }
                    )
                }
                if (documentTemplateDocxUrl)
                {
                    options.push(
                        {
                            url: documentTemplateDocxUrl,
                            code: "PDFViewer.WordTemplate",
                            value: "Download Word sjabloon"
                        }
                    )
                }
                return options;
            },
            [
                docxUrl,
                documentTemplateDocxUrl
            ]
        );


    return useMemo(
        () =>
            ({
                isFinal,
                isConcept,
                previewDocumentUrl,
                finalizedDocumentUrl,
                downloadOptions,
            }),
        [
            isFinal,
            isConcept,
            previewDocumentUrl,
            finalizedDocumentUrl,
            downloadOptions,
        ]
    )
}