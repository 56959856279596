import isInHttps from '../../@Util/Url/isInHttps';

export class Cookie
{
    // ------------------ Members -----------------------------

    name: string;

    value: string;

    expirationDate: Date;

    // -------------- Constructor -----------------------------

    constructor(name: string,
                value: string,
                expirationDate: Date = null)
    {
        this.name = name;
        this.value = value;
        this.expirationDate = expirationDate;
    }

    /***********************************
     **         Business logic        **
     ***********************************/

    static get(name: string,
               defaultValue: string = null): Promise<Cookie>
    {
        return new Promise<Cookie>(resolve =>
        {
            let cookieName = name + '=';
            let cookies = document.cookie.split(';');
            let value = defaultValue;

            for (let cookieDescriptor of cookies)
            {
                let finalCookieDescriptor = cookieDescriptor.trim();

                if (finalCookieDescriptor.indexOf(cookieName) >= 0)
                {
                    value = finalCookieDescriptor.substr(cookieName.length, finalCookieDescriptor.length);

                    break;
                }
            }

            if (value == null)
            {
                resolve(null);
            }
            else
            {
                resolve(new Cookie(name, value, null));
            }
        });
    }

    static set(name: string,
               value: string,
               expiresInMilliseconds: number): Promise<Cookie>
    {
        if (value === undefined)
        {
            return Cookie.delete(name)
                .then(
                    () =>
                        Promise.resolve(undefined));
        }

        return new Promise<Cookie>(resolve =>
        {
            // It can be the case that we develop on http://localhost (the cookie will not be readable on HTTP is Secure is enabled)
            let cookieDescriptor = `${name}=${value};path=/;${isInHttps() ? `SameSite=None;Secure;` : ''}`;
            let expirationDate = null;

            if (expiresInMilliseconds != null)
            {
                expirationDate = new Date();
                expirationDate.setTime(expirationDate.getTime() + expiresInMilliseconds);

                let expires = 'expires=' + expirationDate.toUTCString() + ';';
                cookieDescriptor += expires;
            }

            document.cookie = cookieDescriptor;

            resolve(new Cookie(name, value, expirationDate));
        });
    }

    static delete(name: string): Promise<boolean>
    {
        return new Promise<boolean>(resolve =>
        {
            Cookie.get(name, null)
                .then(cookie =>
                {
                    if (cookie == null)
                    {
                        resolve(false);
                    }
                    else
                    {
                        document.cookie = cookie.name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';

                        resolve(true);
                    }
                });
        });
    }

    static has(name: string): Promise<boolean>
    {
        return new Promise<boolean>(resolve =>
        {
            Cookie.get(name, null).then(cookie =>
            {
                resolve(cookie != null);
            });
        });
    }

    /***********************************
     **      Getters and setters      **
     ***********************************/
}
