import { EntityRelationshipDefinition } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinition';

export class EntityRelationshipDefinitionTuple
{
    /***********************************
     **          Members              **
     ***********************************/

    relationshipDefinition: EntityRelationshipDefinition;

    isParent: boolean;

    // -------------- Constructor -----------------------------

    constructor(relationshipDefinition: EntityRelationshipDefinition, isParent: boolean)
    {
        this.relationshipDefinition = relationshipDefinition;
        this.isParent = isParent;
    }

    /***********************************
     **         Business logic        **
     ***********************************/

   /***********************************
     **      Getters and setters      **
     ***********************************/
}
