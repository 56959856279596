import { BespokeEntityType } from '../../BespokeEntityType';
import { EntityTypeStore } from '../../EntityTypeStore';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';

export class BespokeDatastorePhaseActivitySubscription extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore, code: string = 'Datastore.Phase.ActivitySubscription')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isDeletable(entity: Entity): boolean
    {
        return !entity.hasValueForField(this.types.Datastore.Field.Code);
    }

    // ----------------------- Private logic ------------------------
}
