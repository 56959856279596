import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { observable } from 'mobx';
import { BespokeRelationshipPersonContact } from './BespokeRelationshipPersonContact';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { BespokeRelationshipPersonCommercial } from './BespokeRelationshipPersonCommercial';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { AuditTrailEntity } from '../../Timeline/Model/AuditTrailEntity';

export class BespokeRelationshipPerson extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable contact: BespokeRelationshipPersonContact;
    @observable commercial: BespokeRelationshipPersonCommercial;

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Relationship.Person')
    {
        super(entityTypeStore, code);

        this.contact = new BespokeRelationshipPersonContact(this.entityTypeStore);
        this.commercial = new BespokeRelationshipPersonCommercial(this.entityTypeStore);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getCharactersInAvatar(entity: Entity): string
    {
        const person = entity.getRelatedEntityByDefinition(false, this.entityTypeStore.bespoke.types.Relationship.Person.RelationshipDefinition.Person);

        if (person)
        {
            return person.entityType.bespoke.getCharactersInAvatar(entity);
        }
        else
        {
            let characters = '';

            const firstName = entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Relation.Person.Field.FirstName);

            if (firstName && firstName.length > 0)
            {
                characters += firstName[0];
            }

            const lastName = entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Relation.Person.Field.LastName);

            if (lastName && lastName.length > 0)
            {
                characters += lastName[0];
            }

            return characters;
        }
    }

    getUserFriendlyEntityType()
    {
        return this.entityTypeStore.bespoke.types.Relation.Person.Type;
    }

    getListDependencies(): EntityPath[]
    {
        return [
            ...super.getListDependencies(),
            EntityPath.fromEntityType(this.type)
                .joinTo(
                    this.types.Relationship.Person.RelationshipDefinition.Person,
                    false)
                .joinTo(
                    this.types.Relation.Person.RelationshipDefinition.Address,
                    false)
                .joinTo(
                    this.types.Address.RelationshipDefinition.Country,
                    false)
        ];
    }

    getAuditTrailEntities(entity: Entity, types): AuditTrailEntity[]
    {
        const childPerson =
            entity.getRelatedEntityByDefinition(
                false,
                types.Relationship.Person.RelationshipDefinition.Person
            );

        const childPersonAddress =
            childPerson?.getRelatedEntityByDefinition(
                false,
                types.Relation.Person.RelationshipDefinition.Address
            );

        return [
            new AuditTrailEntity(entity, entity.entityType.nameSingular),
            ...childPerson
                ? [ new AuditTrailEntity(childPerson, childPerson.entityType.nameSingular) ]
                : [],
            ...childPersonAddress
                ? [ new AuditTrailEntity(childPersonAddress, types.Relation.Person.RelationshipDefinition.Address.name) ]
                : []
        ];
    }

    // ----------------------- Private logic ------------------------
}
