import { padZeroForTimeComponent } from './padZeroForTimeComponent';

export function getTimeAsString(
    hour: number | undefined,
    minute: number | undefined
): string | undefined
{
    if (hour === undefined)
    {
        return undefined;
    }
    else
    {
        return `${padZeroForTimeComponent(hour)}:${padZeroForTimeComponent(minute ?? 0)}:00`;
    }
}
