import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { CronEditorProps } from '../CronEditor';
import TimePicker from '../../TimePicker/TimePicker';
import ViewGroup from '../../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../../ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface DailyCronEditorProps extends CronEditorProps
{

}

const DailyCronEditor: React.FC<DailyCronEditorProps> =
    props =>
    {
        const hour =
            useMemo(
                () =>
                    parseInt(props.value.split(' ')[2]),
                [
                    props.value
                ]);

        const minute =
            useMemo(
                () =>
                    parseInt(props.value.split(' ')[1]),
                [
                    props.value
                ]);

        const { onChange } = props;
        const onChangeTime =
            useCallback(
                (hour: number, minute: number) =>
                {
                    const split = props.value.split(' ');
                    split.splice(
                        1,
                        2,
                        minute.toString(),
                        hour.toString());

                    onChange(split.join(' '));
                },
                [
                    onChange,
                    props.value
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={5}
            alignment="center"
        >
            <ViewGroupItem>
                <LocalizedText
                    code="CronEditor.At"
                    value="Om"
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <TimePicker
                    hour={hour}
                    minute={minute}
                    onChange={onChangeTime}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <LocalizedText
                    code="CronEditor.Hour"
                    value="uur"
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(DailyCronEditor);
