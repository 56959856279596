import { Cookie } from '../../Cookie/Cookie';

export async function getIdToken(): Promise<string | undefined>
{
    // If access token is in cookie, then get it from there
    const cookie = await Cookie.get('id_token');

    if (cookie)
    {
        return cookie.value;
    }

    // Otherwise fetch it either from session storage
    const idToken = sessionStorage.getItem('idToken');

    if (idToken)
    {
        return idToken;
    }
    else
    {
        return undefined;
    }
}
