import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import LabelButton from '../../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import useTypes from '../Type/Api/useTypes';
import { primaryColor, textSecondaryColor } from '../../../../@Resource/Theme/Theme';
import useCount from '../Selection/Hooks/useCount';
import localizeText from '../../../../@Api/Localization/localizeText';

export interface OfferProps
{
    entity: Entity;
}

const ReadCount: React.FC<OfferProps> =
    props =>
    {
        const types = useTypes();
        const count =
            useCount(
                types.Event.Read.Type,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath.joinTo(
                                    types.Entity.RelationshipDefinition.Events,
                                    true),
                                props.entity)),
                [
                    types,
                    props.entity
                ]);

        return <LabelButton
            icon="remove_red_eye"
            label={count === undefined ? '' : count}
            onClick={() => {}}
            size="xs"
            tooltip={
                count === undefined
                    ?
                        undefined
                    :
                        localizeText(
                            'Generic.NumberOfViews',
                            '${numberOfViews} keer bekeken',
                            {
                                numberOfViews: count.toString()
                            })
            }
            color={textSecondaryColor}
            hoverColor={primaryColor}
        />;
    };

export default observer(ReadCount);
