import { Entity } from '../../Model/Implementation/Entity';
import { commit, isTransactionalModel, rollback } from '../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import isEntityValid from '../Validation/isEntityValid';
import debounce from 'lodash-es/debounce';
import { computed, observable, runInAction } from 'mobx';
import { Mutex } from '../../../@Util/Mutex/Mutex';
import { EntityEvent } from '../../Model/Implementation/EntityEvent';
import getEntityDescriptor from '../Descriptor/getEntityDescriptor';
import uuid from '../../../@Util/Id/uuid';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityCacheService } from '../../../@Component/Service/Entity/EntityCacheService';
import localizeText from '../../Localization/localizeText';
import { FeedbackStore } from '../../../@Component/App/Root/Environment/Organization/Feedback/FeedbackStore';
import { EntityMatchResult } from '../../../@Component/Service/Entity/EntityMatchResult';
import { EntityActionEvent } from '../../Model/Implementation/EntityActionEvent';
import { EntityRelationship } from '../../Model/Implementation/EntityRelationship';
import { EntityRelationshipMutation } from '../../Model/Implementation/EntityRelationshipMutation';
import { SideEffectProps } from '../../../@Component/Domain/Entity/SideEffect/SideEffectStore';
import { SideEffectDialogStore } from '../../../@Component/Domain/Entity/SideEffect/Dialog/SideEffectDialogStore';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { EntityController } from '../../Controller/Directory/EntityController';
import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import showErrorMessage from '../../Error/showErrorMessage';
import equalsEntity from '../Bespoke/equalsEntity';
import { CommitContext } from './Context/CommitContext';
import { CommitLock } from './Context/Model/CommitLock';

export interface CommitOptions {
    isDebounced: boolean;
    isForced: boolean;
    isDeferred: boolean;
    isBulkMode: boolean;
    isDebugMode: boolean;
    isAutoCommit: boolean;
    context?: CommitContext;
    lock?: CommitLock;
}

type Commit = {
    id: string;
    descriptor: any;
    events: EntityEvent[];
    files: Map<string, File>;
};

const defaultOptions: CommitOptions = {
    isDebounced: false,
    isForced: false,
    isDeferred: false,
    isBulkMode: false,
    isDebugMode: false,
    isAutoCommit: false,
};

export default async function commitEntity(entity: Entity,
                                           partialOptions?: Partial<CommitOptions>): Promise<Entity>
{
    const options = getOptions(partialOptions);

    return commitEntityWithOptions(entity, options);
}

async function commitEntityWithOptions(entity: Entity,
                                       options: CommitOptions): Promise<Entity>
{
    if (shouldCommit(entity, options))
    {
        return debounceOrDeferOrPerformCommit(
            entity,
            options,
            false
        );
    }
    else
    {
        return entity;
    }
}

function isCommitValid(commit: Commit)
{
    return commit.descriptor !== undefined;
}

function registerCommit(commit: Commit)
{
    loadModuleDirectly(EntityCacheService).registerCommit(commit.id);
}

export function getCommitFromEntityAndRegisterIfValid(entity: Entity): Commit
{
    const commit = getCommitFromEntity(entity);

    if (isCommitValid(commit))
    {
        registerCommit(commit);
    }

    return commit;
}

async function debounceOrDeferOrPerformCommit(
    entity: Entity,
    options: CommitOptions,
    doCheckIfShouldCommitAgain: boolean
): Promise<Entity>
{
    if (options.isDeferred)
    {
        return deferCommit(entity, options);
    }
    else if (options.isDebounced)
    {
        return debounceCommit(entity, options);
    }
    else
    {
        if (doCheckIfShouldCommitAgain)
        {
            if (shouldCommit(entity, options))
            {
                return synchronizeCommit(entity, options);
            }
            else
            {
                return entity;
            }
        }
        else
        {
            return synchronizeCommit(entity, options);
        }
    }
}

export function getCommitFromEntity(
    entity: Entity | undefined,
    commitContext?: CommitContext
): Commit
{
    const commitId = uuid();

    if (entity)
    {
        const fileMap = new Map<string, File>();
        const fileReporter =
            (fileId, file) =>
            {
                fileMap.set(fileId, file);
            };

        if (commitContext)
        {
            return {
                id: commitId,
                descriptor: {
                    entityId: entity.uuid,
                    commitRequest:
                        commitContext.getCommitRequest(
                            {},
                            fileReporter,
                            commitId,
                        )
                },
                events: [],
                files: fileMap
            };
        }
        else
        {
            const events: EntityEvent[] = [];

            const descriptor: any =
                getEntityDescriptor(
                    entity,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    fileReporter,
                    false,
                    events,
                    commitContext
                );

            if (descriptor)
            {
                descriptor.commitId = commitId;
            }

            return {
                id: commitId,
                descriptor: descriptor,
                events: events,
                files: fileMap,
            };
        }
    }
    else
    {
        return {
            id: commitId,
            descriptor: undefined,
            files: new Map(),
            events: [],
        };
    }
}

async function deferCommit(entity: Entity,
                           options: CommitOptions): Promise<Entity>
{
    return new Promise<Entity>(
        (resolve, reject) =>
        {
            setTimeout(
                () =>
                {
                    debounceOrDeferOrPerformCommit(
                        entity,
                        {
                            ...options,
                            isDeferred: false
                        },
                        true
                    )
                        .then(
                            entity =>
                                resolve(entity)
                        )
                        .catch(
                            reason =>
                                reject(reason)
                        );
                },
                0
            );
        });
}

async function debounceCommit(entity: Entity,
                              options: CommitOptions): Promise<Entity>
{
    return runInAction(
        () =>
        {
            if (!entity.checkAndDoCommitDebounced)
            {
                entity.checkAndDoCommitDebounced =
                    debounce(
                        () =>
                            debounceOrDeferOrPerformCommit(
                                entity,
                                {
                                    ...options,
                                    isDebounced: false
                                },
                                true
                            ).then(
                                () =>
                                    runInAction(
                                        () =>
                                            entity.checkAndDoCommitDebounced = undefined
                                    )
                            ),
                        3000
                    );
            }

            return entity.checkAndDoCommitDebounced();
        });
}

async function synchronizeCommit(entity: Entity,
                                 options: CommitOptions): Promise<Entity>
{
    return synchronizeCommitFunction(
        entity,
        () =>
            performCommit(entity, options));
}

const mutex = new Mutex();
const isCommittingValue = observable.box<Entity>(undefined);

export function isCommittingEntity(entity: Entity)
{
    return equalsEntity(isCommittingValue.get(), entity);
}

function startCommmitting(entity: Entity)
{
    runInAction(
        () =>
            isCommittingValue.set(entity));
}

function stopCommitting(entity: Entity)
{
    runInAction(
        () =>
            isCommittingValue.set(undefined));
}

export function doAfterEntityCommit(entity: Entity,
                                    callback: () => void)
{
    if (!isCommittingEntity(entity))
    {
        callback();
    }

    const isCommittingEntityComputation = computed(() => isCommittingEntity(entity));
    let isDisposed = false;

    const dispose =
        isCommittingEntityComputation
            .observe(
                change =>
                {
                    if (!change.newValue)
                    {
                        isDisposed = true;

                        // In case this is called after firing immediately,
                        // the dispose function should be disposed of after
                        // firing immediately
                        if (dispose !== undefined)
                        {
                            dispose();
                        }

                        callback();
                    }
                },
                true);

    if (isDisposed)
    {
        dispose();
    }
}

export async function synchronizeCommitFunction<T>(entity: Entity,
                                                   fn: () => Promise<T>)
{
    // For now, clients may only perform 1 commit simultanously
    return mutex.dispatch(
        async () =>
        {
            startCommmitting(entity);
            const result = await fn();
            stopCommitting(entity);

            return result;
        });

    // return runInAction(
    //     () =>
    //     {
    //         if (!entity.commitMutex)
    //         {
    //             entity.commitMutex = new Mutex();
    //         }
    //
    //         return entity.commitMutex.dispatch(
    //             () =>
    //                 fn());
    //     });
}

async function performCommit(entity: Entity,
                             options: CommitOptions): Promise<Entity>
{
    const context = getContext(entity, options);

    if (context)
    {
        return context.commit({
            isBulkMode: options.isBulkMode
        })
            .then(
                () =>
                    Promise.resolve(entity)
            )
            .catch(
                error =>
                    handleCommitError(
                        entity,
                        error
                    )
            );
    }
    else
    {
        try
        {
            const commit = getCommitFromEntity(entity);

            if (options.isBulkMode)
            {
                commit.descriptor.isBulkMode = true;
            }

            if (isCommitValid(commit))
            {
                registerCommit(commit);

                if (await checkSideEffectsAndRollbackIfNecessary(entity, commit, options))
                {
                    const events = await saveInApi(entity, commit, options);

                    await handleCommitResult(
                        entity,
                        commit,
                        options,
                        events);
                }
            }

            return entity;
        }
        catch (error)
        {
            return handleCommitError(entity, error);
        }
    }
}

async function checkSideEffectsAndRollbackIfNecessary(entity: Entity,
                                                      commit: Commit,
                                                      options: CommitOptions): Promise<boolean>
{
    const doContinue = await checkSideEffects(entity, commit.events);

    if (!doContinue)
    {
        rollback(entity);
    }

    return doContinue;
}

async function checkSideEffects(entity: Entity,
                                localEvents: EntityEvent[]): Promise<boolean>
{
    const props: SideEffectProps = {
        entity: entity,
        events: localEvents,
    };

    const sideEffects = entity.entityType.bespoke.type.bespoke.getSideEffects(props);

    return Promise.all(
        sideEffects
            .map(
                sideEffect =>
                    sideEffect.initializeStore()))
            .then(() =>
            {
                const activatedEffects = sideEffects.filter(effect => effect.isActivated());

                if (activatedEffects.length > 0)
                {
                    return new Promise<boolean>(
                        resolve =>
                        {
                            const feedbackStore = loadModuleDirectly(FeedbackStore);
                            const closeDialog =
                                feedbackStore.openDialog({
                                    store:
                                        new SideEffectDialogStore(
                                            entity,
                                            activatedEffects,
                                            doResolve =>
                                            {
                                                closeDialog();
                                                resolve(doResolve);
                                            })
                                });
                        });
                }
                else
                {
                    return Promise.resolve(true);
                }
            });
}

async function saveInApi(entity: Entity,
                         commit: Commit,
                         options: CommitOptions): Promise<EntityEvent[]>
{
    const { descriptor, files } = commit;

    if (files.size === 0)
    {
        return loadModuleDirectly(EntityController).saveEntity(descriptor);
    }
    else
    {
        const data = new FormData();

        data.append('entity', JSON.stringify(descriptor));

        files.forEach((file, fileId) =>
        {
            // data.append(fileId, file);
            data.append(fileId, file, 'file');
        });

        return loadModuleDirectly(ApiClient)
            .request(
                new ApiRequest<any>(
                    '/entity/save',
                    Method.Post,
                    data,
                    {
                        enctype: 'multipart/form-data',
                    },
                    true,
                    undefined,
                    undefined,
                    undefined,
                    true))
            .then(result =>
            {
                if (result.ok)
                {
                    const events = (fromJson(result.data, Entity) as any) as EntityEvent[];

                    return Promise.resolve(events);
                }
                else
                {
                    return Promise.reject(result.error);
                }
            });
    }
}

export async function handleCommitResult(entity: Entity | undefined,
                                         commit: Commit,
                                         options: CommitOptions,
                                         events: EntityEvent[])
{
    const preCommitResult = await preCommitModel(events);

    if (entity)
    {
        await commitModel(entity);

        maybeShowSaveNotification(entity, events);
    }

    await postCommitModel(
        commit,
        events,
        preCommitResult);
}

async function preCommitModel(events: EntityEvent[]): Promise<EntityMatchResult[]>
{
    return loadModuleDirectly(EntityCacheService).prematchEvents(events);
}

async function commitModel(entity: Entity)
{
    if (isTransactionalModel(entity))
    {
        await commit(entity);
    }
}

export async function postCommitModel(commit: Commit,
                                      events: EntityEvent[],
                                      preCommitResult: EntityMatchResult[])
{
    // Persist changes to cached entities
    const newOrDeletedEntitiesByUuid = new Map<string, Entity>();
    const newOrDeletedRelationshipsByUuid = new Map<string, EntityRelationship>();

    (commit.events || [])
        .forEach(
            event =>
            {
                if (event.entity.isNew())
                {
                    newOrDeletedEntitiesByUuid.set(
                        event.entity.uuid,
                        event.entity);
                }

                if (event instanceof EntityRelationshipMutation
                    && event.entityRelationship
                    && event.entityRelationship.isNew())
                {
                    newOrDeletedRelationshipsByUuid.set(
                        event.entityRelationship.uuid,
                        event.entityRelationship);
                }
            });

    return loadModuleDirectly(EntityCacheService)
        .postMatchEvents(
            events,
            preCommitResult,
            newOrDeletedEntitiesByUuid,
            newOrDeletedRelationshipsByUuid);
}

export function maybeShowSaveNotification(entity: Entity,
                                   events: EntityEvent[])
{
    if (shouldShowSaveNotification(entity, events))
    {
        showSaveNotification(entity, events);
    }
}

function shouldShowSaveNotification(entity: Entity,
                                    events: EntityEvent[])
{
    return entity.entityType.bespoke.showSaveNotification(entity)
        && events.length > 0
        // Only show snackbar if the first action is not an action event (because this shows its own snackbar)
        && !(events[0] instanceof EntityActionEvent);
}

function showSaveNotification(entity: Entity,
                              events: EntityEvent[])
{
    loadModuleDirectly(FeedbackStore)
        .enqueueSnackbar(
            localizeText(
                'Generic.ResourceSaved',
                '${resource} opgeslagen',
                {
                    resource: entity.entityType.getName()
                }),
            {
                variant: 'success',
                autoHideDuration: 1500
            });
}

export function handleCommitError(entity: Entity, error: any)
{
    rollbackCommitIfNecessary(entity);

    if (error === 'mutation-not-allowed')
    {
        loadModuleDirectly(FeedbackStore)
            .enqueueSnackbar(
                localizeText(
                    'Generic.InsufficientRights',
                    'Je hebt onvoldoende rechten om deze actie uit te voeren.'),
                {
                    variant: 'error'
                });
    }
    else
    {
        showErrorMessage(error);

        return Promise.reject(error);
    }
}

function rollbackCommitIfNecessary(entity: Entity)
{
    if (shouldRollbackCommit(entity))
    {
        rollbackCommit(entity);
    }
}

function shouldRollbackCommit(entity: Entity)
{
    return isTransactionalModel(entity) && !entity.isNew();
}

function rollbackCommit(entity: Entity)
{
    return rollback(entity);
}

function shouldCommit(entity: Entity,
                      options: CommitOptions)
{
    const context = getContext(entity, options);

    if (context)
    {
        return context.isDirty()
            && (!options.isAutoCommit || context.getOptions().allowAutoCommit)
            && isEntityValid(entity, { commitContext: options.context });
    }
    else
    {
        return isTransactionalModel(entity) // Temporarily disabled the isFocused check on request by GC: && (!this.isFocused || doForceCommit)
            && (entity.allowAutoCommit() || options.isForced) // default: true
            && isEntityValid(entity, { commitContext: options.context });
    }
}

function getContext(entity: Entity,
                    options: CommitOptions)
{
    return options.context ?? entity.getCommitContext();
}

function getOptions(partialOptions?: Partial<CommitOptions>)
{
    if (partialOptions === undefined)
    {
        return defaultOptions;
    }
    else
    {
        return {
            ...defaultOptions,
            ...partialOptions
        };
    }
}
