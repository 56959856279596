import React from 'react';
import { observer } from 'mobx-react-lite';
import List from '../List/List';
import PathSelector from '../PathSelector/PathSelector';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../../../../../Path/@Model/EntityPath';
import Parameter from '../../../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import Predicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';

export interface PinnedTimelineProps
{
    entity: Entity;
    paths: EntityPath[];
    onTotalCount: (totalCount: number) => void;
    filter?: (parameter: Parameter<EntityValueType>, path: EntityPath) => Predicate | undefined;
    compact?: boolean;
}

const Pinned: React.FC<PinnedTimelineProps> =
    props =>
    {
        return <PathSelector
            entity={props.entity}
            paths={props.paths}
            component={
                (paths, entitiesByDate, isAll, notesOpenByDefault) =>
                    <List
                        entity={props.entity}
                        entitiesByLatestActivity={entitiesByDate}
                        notesOpenByDefault={notesOpenByDefault}
                    />}
            segment="pinned"
            label={
                <LocalizedText
                    code="RelatedActivities.Pinned"
                    value="Favorieten"
                />
            }
            onTotalCount={props.onTotalCount}
            filter={props.filter}
            compact={props.compact}
        />;
    };

export default observer(Pinned);
