import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../Type/Api/useTypes';
import ExpansionPanel from '../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import Header from '../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import Selectbox from '../../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import performAction from '../../../../../../../@Api/Entity/performAction';
import getConnectorActivationByCode from '../../../../../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';
import makeStyles from '@material-ui/core/styles/makeStyles';
import StaticSelectbox from '../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import { CommitBuilder } from '../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import getDatastoreByCode from '../../../../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';

export interface SpotlerCampaignStartProps
{
    spotlerCampaign: Entity;
}

interface SpotlerCampaignTrigger
{
    encryptedId: string;
    name: string;
}

interface SpotlerCampaignProps
{
    active: boolean;
    encryptedId: string;
    isLinkedToTribe: boolean;
    name: string
    triggers: SpotlerCampaignTrigger[];
}

export interface SpotlerConfiguration
{
    spotlerCampaignId: string;
    spotlerCampaignTriggerId: string;
}

const useStyles = makeStyles(
    {
        campaignOption:
            {
                borderBottom: '#eee 1px solid',
                padding: 8
            },
        campaignOptionError:
            {
                color: '#d00'
            },
        campaignOptionOk:
            {
                color: '#080'
            },
        error:
            {
                color: '#f44336'
            }
    });

export const SpotlerCampaignStart: React.FC<SpotlerCampaignStartProps> =
    ({
         spotlerCampaign
    }) =>
    {


        const classes = useStyles();
        const types = useTypes();
        const [ selectedSpotlerCampaign, setSelectedSpotlerCampaign ] = useState<SpotlerCampaignProps>(undefined);
        const [ selectedSpotlerCampaignTrigger, setSelectedSpotlerCampaignTrigger ] = useState<SpotlerCampaignTrigger>(undefined);
        const [ savedSelectedCampaignId, setSavedSelectedCampaignId ] = useState<string>(undefined)
        const [ phasePlanned, setPhasePlanned ] = useState(undefined);

        useAsyncResult(
            () =>
                getDatastoreByCode(
                    types.Datastore.Phase.ActivitySpotlerCampaign.Type,
                    types.Activity.SpotlerCampaign.State.Planned
                ).then(setPhasePlanned),
            [
                types,
                setPhasePlanned,
            ]
        );

        const [ spotlerConnectorActivation ] =
            useAsyncResult(
                () => getConnectorActivationByCode('Spotler'),
                []
            );

        const spotlerConfiguration =
            useEntityValue<SpotlerConfiguration>(
                spotlerCampaign,
                types.Activity.SpotlerCampaign.Field.Configuration
            );

        const [campaigns, campaignsLoading] =
            useAsyncResult(
                () =>
                    spotlerConnectorActivation &&
                    performAction<SpotlerCampaignProps[]>(
                        spotlerConnectorActivation,
                        {
                            code: 'ConnectorActivation.Spotler.GetCampaigns'
                        }
                    ),
                [
                    spotlerConnectorActivation
                ]
            );

        const spotlerCampaigns =
            useMemo(
                () =>
                {
                    if (campaigns?.result)
                    {
                        return campaigns.result;
                    }
                },
                [
                    campaigns
                ]
            );

        const load =
            useCallback(
                () =>
                    Promise.resolve(campaigns.result),
                [
                    campaigns
                ]
            );

        const formatCampaignOption =
            (campaign: SpotlerCampaignProps) =>
                <ViewGroup
                    orientation="horizontal"
                    spacing={0}
                    className={classes.campaignOption}
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={4}
                        >
                            <ViewGroupItem>
                                {campaign.name}
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        {
                            campaign.isLinkedToTribe &&
                            <div>
                                {
                                    campaign.encryptedId === savedSelectedCampaignId
                                        ?
                                            <small
                                                className={classes.campaignOptionOk}
                                            >
                                                Is reeds gelinkt aan deze marketinglijst
                                            </small>
                                        :
                                            <small
                                                className={classes.campaignOptionError}
                                            >
                                                Is reeds gelinkt aan een andere marketinglijst
                                            </small>
                                }
                            </div>
                        }
                        {
                            !campaign.isLinkedToTribe &&
                            !campaign.active &&
                            <small
                                className={classes.campaignOptionError}
                            >
                                <LocalizedText
                                    code="Spotler.CampaignIsNotYetActivated"
                                    value="Campagne is nog niet geactiveerd"
                                />
                            </small>
                        }
                    </ViewGroupItem>
                </ViewGroup>;

        const campaignTriggerOptions =
            useMemo(
                () =>
                    selectedSpotlerCampaign?.triggers?.map(
                        trigger =>
                            ({
                                id: trigger.encryptedId,
                                label: trigger.name,
                                value: trigger
                            })
                    ),
                [
                    selectedSpotlerCampaign
                ]
            );


        const saveSpotlerCampaignConfiguration = useCallback(
            (campaignId: string, campaignTriggerId: string) =>
            {
                const newConfiguration: SpotlerConfiguration =
                    {
                        ...spotlerConfiguration,
                        spotlerCampaignId: campaignId,
                        spotlerCampaignTriggerId: campaignTriggerId
                    };

                return new CommitBuilder()
                    .setObjectValueInEntity(
                        spotlerCampaign,
                        types.Activity.SpotlerCampaign.Field.Configuration,
                        newConfiguration
                    )
                    .commit();
            },
            [
                spotlerConfiguration,
                spotlerCampaign,
                types
            ]
        );


        const saveAndStartSpotlerCampaign =
            useCallback(
                () =>
                {
                    const newConfiguration: SpotlerConfiguration =
                        {
                            ...spotlerConfiguration,
                            spotlerCampaignId: selectedSpotlerCampaign.encryptedId,
                            spotlerCampaignTriggerId: selectedSpotlerCampaignTrigger.encryptedId,
                        };

                    return new CommitBuilder()
                        .setObjectValueInEntity(
                            spotlerCampaign,
                            types.Activity.SpotlerCampaign.Field.Configuration,
                            newConfiguration
                        )
                        .relateEntityTo(
                            spotlerCampaign,
                            false,
                            types.Activity.SpotlerCampaign.RelationshipDefinition.Phase,
                            phasePlanned
                        )
                        .commit();
                },
                [
                    spotlerCampaign,
                    spotlerConfiguration,
                    phasePlanned,
                    selectedSpotlerCampaign,
                    selectedSpotlerCampaignTrigger,
                    types
                ]
            );

        const isCampaignOptionDisabled = useCallback(
            (campaign: SpotlerCampaignProps) =>
            {
                return (campaign.encryptedId !== savedSelectedCampaignId) && (campaign.isLinkedToTribe || !campaign.active);
            },
            [
                savedSelectedCampaignId
            ]
        );

        useEffect(
            () =>
            {
                if (spotlerConfiguration && spotlerConfiguration?.spotlerCampaignId && spotlerCampaigns)
                {
                    const spotlerCampaign =
                        spotlerCampaigns.find(d => d.encryptedId === spotlerConfiguration.spotlerCampaignId);

                    const spotlerCampaignTrigger =
                        spotlerCampaign !== undefined
                            ? spotlerCampaign.triggers?.find(t => t.encryptedId === spotlerConfiguration.spotlerCampaignTriggerId)
                            : undefined;

                    setSavedSelectedCampaignId(spotlerConfiguration?.spotlerCampaignId);
                    setSelectedSpotlerCampaign(spotlerCampaign);
                    setSelectedSpotlerCampaignTrigger(spotlerCampaignTrigger)
                }
            },
            [
                spotlerCampaigns
            ]
        );

        const updateSelectedCampaign = useCallback(
            (campaign: SpotlerCampaignProps) =>
            {
                setSelectedSpotlerCampaign(campaign);
                setSelectedSpotlerCampaignTrigger(undefined);
                return saveSpotlerCampaignConfiguration(campaign?.encryptedId, undefined);
            },
            [
                setSelectedSpotlerCampaign,
                setSelectedSpotlerCampaignTrigger,
                saveSpotlerCampaignConfiguration
            ]
        );

        const updateSelectedCampaignTrigger = useCallback(
            (campaignTrigger: SpotlerCampaignTrigger) =>
            {
                setSelectedSpotlerCampaignTrigger(campaignTrigger);
                return saveSpotlerCampaignConfiguration(selectedSpotlerCampaign?.encryptedId, campaignTrigger?.encryptedId);
            },
            [
                setSelectedSpotlerCampaignTrigger,
                saveSpotlerCampaignConfiguration,
                selectedSpotlerCampaign
            ]
        );

        return <ExpansionPanel
            id="Start"
            summary={
                <Header
                    large
                    inset
                    title={
                        <LocalizedText
                            code="ExtermalCampaign.Send"
                            value="Verzenden"
                        />
                    }
                />
            }
            expansion={
                <CardInset>
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        {spotlerCampaigns && !campaignsLoading && <ViewGroupItem>
                            <Input
                                labelPosition="left"
                                label="Campagne"
                            >
                                <Selectbox
                                    load={load}
                                    idResolver={campaign => campaign.encryptedId}
                                    onChange={updateSelectedCampaign}
                                    value={selectedSpotlerCampaign}
                                    formatOption={formatCampaignOption}
                                    formatValue={campaign => campaign.name}
                                    disableUnderline={false}
                                    isOptionDisabled={isCampaignOptionDisabled}
                                />
                            </Input>
                        </ViewGroupItem>}
                        {
                            selectedSpotlerCampaign &&
                            <ViewGroupItem>
                                <Input
                                    labelPosition="left"
                                    label="Campagne trigger"
                                >
                                    {
                                        campaignTriggerOptions &&
                                        <StaticSelectbox
                                            options={campaignTriggerOptions}
                                            onChange={updateSelectedCampaignTrigger}
                                            value={selectedSpotlerCampaignTrigger}
                                            clearable
                                            disableUnderline={false}
                                        />
                                    }
                                    {
                                        !campaignTriggerOptions &&
                                        <span
                                            className={classes.error}
                                        >
                                            Geselecteerde campagne bevat geen campagne triggers.
                                        </span>
                                    }
                                </Input>
                            </ViewGroupItem>
                        }
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={16}
                                justification="end"
                            >
                                <ViewGroupItem>
                                    <PrimaryButton
                                        label={
                                            <LocalizedText
                                                code="Spotler.StartSpotlerCampaign"
                                                value="Start campagne"
                                            />
                                        }
                                        onClick={saveAndStartSpotlerCampaign}
                                        disabled={!selectedSpotlerCampaignTrigger}
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </CardInset>
            }
        />
    };