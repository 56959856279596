import { ComputationType } from '../ComputationType';
import { ConstantComputationEditorStore } from './ConstantComputationEditorStore';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { ConstantComputationEditor } from './ConstantComputationEditor';
import { ConstantComputationSpecification } from './ConstantComputationSpecification';
import { ComputationContext } from '../../ComputationContext';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../../../DataObject/DataObjectStore';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { DataObjectSpecification } from '../../../DataObject/Model/DataObjectSpecification';
import { DataDescriptor } from '../../../DataObject/Model/DataDescriptor';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';
import { EntityContext } from '../../../Entity/@Model/EntityContext';
import { ConstantComputation } from './ConstantComputation';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';

export class ConstantComputationType extends ComputationType<ConstantComputationEditorStore, ConstantComputationSpecification, ConstantComputation>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return 'Constant';
    }

    name(): string
    {
        return this.localizationStore.translate('Computation.Constant'); // Constant;
    }

    allow(context: ComputationContext): boolean
    {
        return true;
    }

    isTerminal(): boolean
    {
        return true;
    }

    isResource(): boolean
    {
        return false;
    }

    fromSpecification(specification: ConstantComputationSpecification): ConstantComputation
    {
        const constantType = this.dataObjectStore.getTypeById(specification.constantType);
        const dataObjectSpecification = new DataObjectSpecification(constantType, {});
        const constant =
            new DataObject(
                dataObjectSpecification,
                DataDescriptor.construct(specification.constant, dataObjectSpecification));

        return new ConstantComputation(constant);
    }

    toSpecification(computation: ConstantComputation): ConstantComputationSpecification
    {
        return {
            type: this.id(),
            constantType:
                computation.constant.specification.type.id(),
            constant:
                computation.constant.data.descriptor()
        };
    }

    compute(context: ComputationContext,
            computation: ConstantComputation): DataObject
    {
        return computation.constant;
    }

    description(context: ComputationContext,
                specification: ConstantComputationSpecification): string
    {
        let dataObject = DataObject.constructFromDescriptor(
            {
                type: specification.constantType,
                data: specification.constant
            },
            this.dataObjectStore,
            context);

        return `${dataObject.toString()}`;
    }

    editorStore(context: ComputationContext,
                specification: ConstantComputationSpecification): ConstantComputationEditorStore
    {
        return new ConstantComputationEditorStore(
            this as any,
            context,
            specification,
            context.resultSpecification ?
                    new DataObject(
                        context.resultSpecification,
                        DataDescriptor.construct(specification.constant, context.resultSpecification),
                        context)
                :
                    (specification.constantType && specification.constant ?
                        DataObject.constructFromDescriptor(
                            {
                                type: specification.constantType,
                                data: specification.constant
                            },
                            this.dataObjectStore,
                            context) : undefined));
    }

    editorView(): React.ComponentClass<BaseComponentProps<ConstantComputationEditorStore>>
    {
        return ConstantComputationEditor;
    }

    editorSpecification(store: ConstantComputationEditorStore): ConstantComputationSpecification
    {
        return {
            type: this.id(),
            constantType: store.constantEditorStore ? store.constantEditorStore.dataObject.specification.type.id() : undefined,
            constant: store.constantEditorStore ? store.constantEditorStore.dataObject.data.descriptor() : undefined
        };
    }

    editorResultSpecification(store: ConstantComputationEditorStore): DataObjectSpecification
    {
        if (store.constantEditorStore)
        {
            return store.constantEditorStore.dataObject.specification;
        }
        else
        {
            return null;
        }
    }

    entityFieldPaths(specification: ConstantComputationSpecification,
                     context: EntityContext,
                     parameter?: string): EntityFieldPath[]
    {
        return [];
    }

    // ----------------------- Private logic ------------------------
}
