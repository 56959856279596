import Value from './Value';
import { DataObject } from '../../../@Component/Domain/DataObject/Model/DataObject';
import PrimitiveValueType from './Type/PrimitiveValueType';
import { ConstantComputation } from '../../../@Component/Domain/Computation/Type/Constant/ConstantComputation';
import { Comparator } from '../../../@Component/Domain/DataObject/Model/Comparator';
import { FileReporter } from '../../../@Component/Domain/DataObject/Model/DataDescriptor';
import { NumberType } from '../../../@Component/Domain/DataObject/Type/Number/NumberType';
import { TextType } from '../../../@Component/Domain/DataObject/Type/Text/TextType';

export default class PrimitiveValue extends Value<DataObject, PrimitiveValueType>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getId(): string
    {
        return `Primitive(${this.value.valueId})`;
    }

    getType(): PrimitiveValueType
    {
        return new PrimitiveValueType(this.value.type);
    }

    getName(): string
    {
        return this.value.toString() || '';
    }

    equals(otherValue: Value<any, any>)
    {
        return otherValue instanceof PrimitiveValue
            && DataObject.compare(
                this.value,
                otherValue.value,
                Comparator.Equals);
    }

    clone(): PrimitiveValue
    {
        return new PrimitiveValue(this.value.clone());
    }

    augmentDescriptor(descriptor: any,
                      onFile?: FileReporter)
    {
        descriptor.type = 'Primitive';
        descriptor.value = this.value.descriptor(onFile);
    }

    toOldComputation(): any
    {
        return new ConstantComputation(this.value.clone());
    }

    static async fromDescriptor(descriptor: any)
    {
        return new PrimitiveValue(
            DataObject.constructFromDescriptor(
                descriptor.value));
    }

    static getAsNumber(value: Value<any, any>): number | undefined
    {
        if (value instanceof PrimitiveValue
            && value.value.type instanceof NumberType)
        {
            return value.value.value;
        }
        else
        {
            return undefined;
        }
    }

    static getAsText(value: Value<any, any>): string | undefined
    {
        if (value instanceof PrimitiveValue
            && value.value.type instanceof TextType)
        {
            return value.value.value;
        }
        else
        {
            return undefined;
        }
    }

    // ----------------------- Private logic ------------------------
}
