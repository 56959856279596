import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ComputationEditorProps } from '../../ComputationEditor';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import HtmlFromLayoutComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/HtmlFromLayoutComputation';
import { runInAction } from 'mobx';
import LayoutEditor from '../../../../../../../../Layout/Editor/LayoutEditor';
import Layout from '../../../../../../../../../../@Api/Layout/Layout';

export interface HtmlFromLayoutComputationEditorProps extends ComputationEditorProps<ValueType<any>, HtmlFromLayoutComputation>
{

}

const HtmlFromLayoutComputationEditor: React.FC<HtmlFromLayoutComputationEditorProps> =
    props =>
    {
        const setLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.value.layout = layout),
                [
                    props.value
                ]);

        return <LayoutEditor
            parameterDictionary={props.context.parameterDictionary}
            parameterAssignment={props.context.parameterAssignment}
            layout={props.value.layout}
            onChange={setLayout}
        />;
    };

export default observer(HtmlFromLayoutComputationEditor);
