import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';

export default function getCustomPluralRelatedPaths(entityType: EntityType)
{
    return [ true, false ]
        .map(
            isParent =>
                entityType.getAllRelationshipDefinitions(isParent)
                    .filter(
                        relationshipDefinition =>
                            relationshipDefinition.isPlural(isParent)
                            && relationshipDefinition.isVisibleAsTab(!isParent)
                    )
                    .map(
                        relationshipDefinition =>
                            EntityPath.fromEntityType(entityType)
                                .joinTo(
                                    relationshipDefinition,
                                    isParent
                                )
                    )
        )
        .reduce((a, b) => a.concat(b), []);
}
