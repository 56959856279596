import { DependencyList, ReactElement, useCallback, useMemo, useState } from 'react';
import uuid from '../../../../@Util/Id/uuid';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { FeedbackStore } from '../../../../@Component/App/Root/Environment/Organization/Feedback/FeedbackStore';

export default function useDialog(dialogConstructor: (closeDialog: () => void) => ReactElement, deps?: DependencyList)
{
    const [ id ] = useState(uuid());

    if (!deps)
    {
        deps = [];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedDialogConstructor = useCallback(dialogConstructor, deps);
    const feedbackStore =
        useMemo(
            () =>
                loadModuleDirectly(FeedbackStore),
            []
        );
    const closeDialog =
        useCallback(
            () =>
                feedbackStore.closeDialogById(id),
            [
                feedbackStore,
                id,
            ]
        );
    const openDialog =
        useCallback(
            () =>
            {
                const dialog = memoizedDialogConstructor(closeDialog);
                feedbackStore.openDialog({
                    id: id,
                    content: dialog
                });
            },
            [
                memoizedDialogConstructor,
                feedbackStore,
                id,
                closeDialog,
            ]
        );

    return useMemo(
        () =>
        [
            openDialog,
            closeDialog
        ],
        [
            openDialog,
            closeDialog,
        ]
    );
}
