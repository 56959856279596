import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import LayoutAction from '../Action/LayoutAction';
import getLayoutActionFromDescriptor from '../Action/Api/getLayoutActionFromDescriptor';
import localizeText from '../../Localization/localizeText';
import Predicate from '../../Automation/Function/Computation/Predicate/Predicate';
import getPredicateFromDescriptor from '../../Automation/Api/getPredicateFromDescriptor';

export type LabelButtonVariant = 'Contained' | 'Text';

export type LabelButtonColor = 'Primary' | 'Secondary' | 'Success' | 'Danger' | 'Warning';

export default class LabelButtonLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref label: Layout;
    @observable.ref action: LayoutAction;
    @observable variant: LabelButtonVariant;
    @observable color: LabelButtonColor;
    @observable.ref isDisabled: Predicate;

    // ------------------------ Constructor -------------------------

    constructor(label: Layout,
                action: LayoutAction,
                variant: LabelButtonVariant,
                color: LabelButtonColor,
                isDisabled?: Predicate)
    {
        super();

        this.label = label;
        this.action = action;
        this.variant = variant;
        this.color = color;
        this.isDisabled = isDisabled;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.LabelButton', 'Knop met label');
    }

    validate(): Validation[]
    {
        return [
            ...this.label.validate(),
            ...this.action.validate(),
            ...(this.isDisabled?.validate() || [])
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.label.getDependencies(),
            ...this.action.getDependencies(),
            ...(this.isDisabled?.getDependencies() || [])
        ];
    }

    toDescriptor()
    {
        return {
            type: 'LabelButton',
            label: this.label.toDescriptor(),
            action: this.action.toDescriptor(),
            variant: this.variant,
            color: this.color,
            isDisabled: this.isDisabled?.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new LabelButtonLayout(
            await getLayoutFromDescriptor(
                descriptor.label,
                dependencyContext),
            await getLayoutActionFromDescriptor(
                descriptor.action,
                dependencyContext),
            descriptor.variant,
            descriptor.color,
            descriptor.isDisabled
                ?
                    await getPredicateFromDescriptor(
                        descriptor.isDisabled,
                        dependencyContext)
                :
                    undefined);
    }

    // ----------------------- Private logic ------------------------
}
