import useTypes from '../../../../Type/Api/useTypes';
import React, { useCallback, useMemo, useState } from 'react';
import useSpotlerCampaignSendCount from './Hooks/useSpotlerCampaignSendCount';
import useSpotlerCampaignDeliveredCount from './Hooks/useSpotlerCampaignDeliveredCount';
import useSpotlerCampaignBouncedCount from './Hooks/useSpotlerCampaignBouncedCount';
import useSpotlerCampaignViewedCount from './Hooks/useSpotlerCampaignViewedCount';
import useSpotlerCampaignClickedCount from './Hooks/useSpotlerCampaignClickedCount';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { ViewParams } from '../../../../View/Model/ViewParams';
import ValueFromEntityComputation from '../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import ComparisonPredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import EntityValue from '../../../../../../../@Api/Automation/Value/EntityValue';
import { Comparator } from '../../../../../DataObject/Model/Comparator';
import PrimitiveValue from '../../../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import Specification from '../../../../View/Model/Specification';
import uuid from 'uuid';
import { default as ListModel } from '../../../../View/Model/Specification/List';
import getViewParameters from '../../../../View/Api/getViewParameters';
import CompositePredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../../DataObject/Model/LogicalOperator';
import Column from '../../../../View/Model/Specification/Column';
import { default as ViewModel } from '../../../../View/Model/View';
import useFetchedRelatedEntities from '../../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntities';
import { openEntity } from '../../../../@Util/openEntity';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import StaticSelectbox from '../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import TabBar from '../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import List from '../../../../View/List/List';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { SpotlerCampaignResultCharts } from './Charts/SpotlerCampaignResultCharts';
import useIsMobile from '../../../../../../../@Util/Responsiveness/useIsMobile';
import ExpansionPanel from '../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import { observer } from 'mobx-react';

export interface SpotlerCampaignResultsProps
{
    spotlerCampaign: Entity;
}

export const SpotlerCampaignResults: React.FC<SpotlerCampaignResultsProps> =
    observer(
        (
            {
                spotlerCampaign
            }
        ) =>
        {
            const types = useTypes();
            const isMobile = useIsMobile();
            const [ selectedStepOption, setSelectedStepOption] = useState('all');
            const [ tab, setTab ] = useState(1);
            const selectedStep =
                useMemo(
                    () =>
                        typeof selectedStepOption !== 'string'
                            ? selectedStepOption
                            : undefined,
                    [
                        selectedStepOption
                    ]
                );
            const sendCount = useSpotlerCampaignSendCount(spotlerCampaign, selectedStep);
            const deliveredCount = useSpotlerCampaignDeliveredCount(spotlerCampaign, selectedStep);
            const viewCount = useSpotlerCampaignViewedCount(spotlerCampaign, selectedStep);
            const clickedCount = useSpotlerCampaignClickedCount(spotlerCampaign, selectedStep);
            const bouncedCount = useSpotlerCampaignBouncedCount(spotlerCampaign, selectedStep);
            const filterEventCode =
                useMemo(
                    () =>
                    {
                        switch(tab)
                        {
                            case 3:
                                return 'View';
                            case 4:
                                return 'Click';
                            case 5:
                                return 'Bounce';
                        }
                    },
                    [
                        tab
                    ]
                );
            const rootType =
                useEntityValue(
                    spotlerCampaign,
                    types.Activity.SpotlerCampaign.Field.RootType,
                    types.Relationship.Organization.Type
                );
            const relationshipPathDescriptor =
                useEntityValue<any>(
                    spotlerCampaign,
                    types.Activity.SpotlerCampaign.Field.RelationshipPath
                );
            const relationshipFieldPath =
                useMemo(
                    () =>
                    {
                        if (relationshipPathDescriptor)
                        {
                            return EntityPath.construct(relationshipPathDescriptor).field();
                        }
                        else
                        {
                            return undefined;
                        }
                    },
                    [
                        relationshipPathDescriptor,
                        types,
                    ]
                );
            const emailAddressFieldPath =
                useMemo(
                    () =>
                    {
                        const rootPath = relationshipFieldPath?.path ?? EntityPath.fromEntityType(rootType);

                        if (rootPath.entityType.isA(types.Relationship.Organization.Type))
                        {
                            return EntityPath
                                .fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.Relationship,
                                    true
                                )
                                .joinTo(
                                    types.Relationship.Organization.RelationshipDefinition.Organization,
                                    false)
                                .field(types.Relation.Organization.Field.EmailAddress);
                        }
                        else if (rootPath.entityType.isA(types.Relationship.Person.Contact.Type))
                        {
                            return EntityPath
                                .fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.Relationship,
                                    true
                                )
                                .field(types.Relationship.Person.Contact.Field.EmailAddress);
                        }
                        else if (rootPath.entityType.isA(types.Relationship.Person.Type))
                        {
                            return EntityPath
                                .fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.Relationship,
                                    true
                                )
                                .joinTo(
                                    types.Relationship.Person.RelationshipDefinition.Person,
                                    false)
                                .field(types.Relation.Person.Field.EmailAddress);
                        }
                    },
                    [
                        types,
                        rootType
                    ]);
            const eventToEmailAddressFieldPath =
                useMemo(
                    () =>
                    {
                        const rootPath = relationshipFieldPath?.path ?? EntityPath.fromEntityType(rootType);

                        if (rootPath.entityType.isA(types.Relationship.Organization.Type))
                        {
                            return EntityPath
                                .fromEntityType(types.SpotlerCampaignEvent.Type)
                                .joinTo(
                                    types.Activity.SpotlerCampaignResult.RelationshipDefinition.Events,
                                    true
                                )
                                .joinTo(
                                    types.Activity.RelationshipDefinition.Relationship,
                                    true
                                )
                                .joinTo(
                                    types.Relationship.Organization.RelationshipDefinition.Organization,
                                    false)
                                .field(types.Relation.Organization.Field.EmailAddress);
                        }
                        else if (rootPath.entityType.isA(types.Relationship.Person.Contact.Type))
                        {
                            return EntityPath
                                .fromEntityType(types.SpotlerCampaignEvent.Type)
                                .joinTo(
                                    types.Activity.SpotlerCampaignResult.RelationshipDefinition.Events,
                                    true
                                )
                                .joinTo(
                                    types.Activity.RelationshipDefinition.Relationship,
                                    true
                                )
                                .field(types.Relationship.Person.Contact.Field.EmailAddress);
                        }
                        else if (rootPath.entityType.isA(types.Relationship.Person.Type))
                        {
                            return EntityPath
                                .fromEntityType(types.SpotlerCampaignEvent.Type)
                                .joinTo(
                                    types.Activity.SpotlerCampaignResult.RelationshipDefinition.Events,
                                    true
                                )
                                .joinTo(
                                    types.Activity.RelationshipDefinition.Relationship,
                                    true
                                )
                                .joinTo(
                                    types.Relationship.Person.RelationshipDefinition.Person,
                                    false)
                                .field(types.Relation.Person.Field.EmailAddress);
                        }
                    },
                    [
                        types,
                        rootType
                    ]);

            const viewSend =
                useMemo(
                    () =>
                    {
                        const viewParameters =
                            getViewParameters(
                                types.Activity.SpotlerCampaignResult.Type
                            );

                        return new ViewModel(
                            'List',
                            undefined,
                            types.Activity.SpotlerCampaignResult.Type,
                            viewParameters,
                            new CompositePredicate(
                                LogicalOperator.And,
                                [
                                    new ComparisonPredicate(
                                        new ValueFromEntityComputation(
                                            viewParameters.getParameterById(ViewParams.Entity),
                                            EntityPath.fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                                .joinTo(
                                                    types.Activity.SpotlerCampaign.RelationshipDefinition.Results,
                                                    true
                                                )
                                                .field()
                                        ),
                                        Comparator.Equals,
                                        new EntityValue(spotlerCampaign)
                                    ),
                                    selectedStep !== undefined
                                        ?
                                        new ComparisonPredicate(
                                            new ValueFromEntityComputation(
                                                viewParameters.getParameterById(ViewParams.Entity),
                                                EntityPath.fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                                    .joinTo(
                                                        types.SpotlerCampaignStep.RelationshipDefinition.Results,
                                                        true
                                                    )
                                                    .field()
                                            ),
                                            Comparator.Equals,
                                            new EntityValue(selectedStep)
                                        )
                                        :
                                        undefined
                                ]
                                    .filter(e => e !== undefined)
                            ),
                            new Specification(
                                new ListModel(
                                    [
                                        new Column(
                                            uuid(),
                                            EntityPath
                                                .fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                                .field(types.Activity.SpotlerCampaignResult.Field.SendDate)
                                        ),
                                        new Column(
                                            uuid(),
                                            EntityPath
                                                .fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                                .joinTo(
                                                    types.SpotlerCampaignStep.RelationshipDefinition.Results,
                                                    true
                                                ).field(types.SpotlerCampaignStep.Field.Name)
                                        ),
                                        new Column(
                                            uuid(),
                                            EntityPath
                                                .fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                                .joinTo(types.Activity.RelationshipDefinition.Relationship, true)
                                                .field()
                                        ),
                                        ...emailAddressFieldPath
                                            ?
                                            [
                                                new Column(
                                                    uuid(),
                                                    emailAddressFieldPath
                                                )
                                            ]
                                            :
                                            []
                                    ],
                                    []
                                )
                            )
                        );
                    },
                    [
                        types,
                        spotlerCampaign,
                        selectedStep,
                        emailAddressFieldPath
                    ]
                );

            const viewDelivered =
                useMemo(
                    () =>
                    {
                        const viewParameters =
                            getViewParameters(
                                types.Activity.SpotlerCampaignResult.Type
                            );

                        return new ViewModel(
                            'List',
                            undefined,
                            types.Activity.SpotlerCampaignResult.Type,
                            viewParameters,
                            new CompositePredicate(
                                LogicalOperator.And,
                                [
                                    new ComparisonPredicate(
                                        new ValueFromEntityComputation(
                                            viewParameters.getParameterById(ViewParams.Entity),
                                            EntityPath.fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                                .joinTo(
                                                    types.Activity.SpotlerCampaign.RelationshipDefinition.Results,
                                                    true
                                                )
                                                .field()
                                        ),
                                        Comparator.Equals,
                                        new EntityValue(spotlerCampaign)
                                    ),
                                    new ComparisonPredicate(
                                        new ValueFromEntityComputation(
                                            viewParameters.getParameterById(ViewParams.Entity),
                                            EntityPath
                                                .fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                                .field(types.Activity.SpotlerCampaignResult.Field.IsBounced)
                                        ),
                                        Comparator.NotEquals,
                                        new PrimitiveValue(
                                            DataObject.constructFromTypeIdAndValue(
                                                'Boolean',
                                                true
                                            )
                                        )
                                    ),
                                    selectedStep !== undefined
                                        ?
                                        new ComparisonPredicate(
                                            new ValueFromEntityComputation(
                                                viewParameters.getParameterById(ViewParams.Entity),
                                                EntityPath.fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                                    .joinTo(
                                                        types.SpotlerCampaignStep.RelationshipDefinition.Results,
                                                        true
                                                    )
                                                    .field()
                                            ),
                                            Comparator.Equals,
                                            new EntityValue(selectedStep)
                                        )
                                        :
                                        undefined
                                ]
                                    .filter(e => e !== undefined)
                            ),
                            new Specification(
                                new ListModel(
                                    [
                                        new Column(
                                            uuid(),
                                            EntityPath
                                                .fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                                .field(types.Activity.SpotlerCampaignResult.Field.SendDate)
                                        ),
                                        new Column(
                                            uuid(),
                                            EntityPath
                                                .fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                                .joinTo(
                                                    types.SpotlerCampaignStep.RelationshipDefinition.Results,
                                                    true
                                                ).field(types.SpotlerCampaignStep.Field.Name)
                                        ),
                                        new Column(
                                            uuid(),
                                            EntityPath
                                                .fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                                .joinTo(types.Activity.RelationshipDefinition.Relationship, true)
                                                .field()
                                        ),
                                        ...emailAddressFieldPath
                                            ?
                                            [
                                                new Column(
                                                    uuid(),
                                                    emailAddressFieldPath
                                                )
                                            ]
                                            :
                                            []
                                    ],
                                    []
                                )
                            )
                        );
                    },
                    [
                        types,
                        spotlerCampaign,
                        selectedStep
                    ]
                );

            const viewEvents =
                useMemo(
                    () =>
                    {
                        const viewParameters =
                            getViewParameters(
                                types.SpotlerCampaignEvent.Type
                            );

                        return new ViewModel(
                            'List',
                            undefined,
                            types.SpotlerCampaignEvent.Type,
                            viewParameters,
                            new CompositePredicate(
                                LogicalOperator.And,
                                [
                                    new ComparisonPredicate(
                                        new ValueFromEntityComputation(
                                            viewParameters.getParameterById(ViewParams.Entity),
                                            EntityPath.fromEntityType(types.SpotlerCampaignEvent.Type)
                                                .joinTo(
                                                    types.Activity.SpotlerCampaignResult.RelationshipDefinition.Events,
                                                    true
                                                )
                                                .joinTo(
                                                    types.Activity.SpotlerCampaign.RelationshipDefinition.Results,
                                                    true
                                                )
                                                .field()
                                        ),
                                        Comparator.Equals,
                                        new EntityValue(spotlerCampaign)
                                    ),
                                    new ComparisonPredicate(
                                        new ValueFromEntityComputation(
                                            viewParameters.getParameterById(ViewParams.Entity),
                                            EntityPath
                                                .fromEntityType(types.SpotlerCampaignEvent.Type)
                                                .joinTo(
                                                    types.SpotlerCampaignEvent.RelationshipDefinition.EventType,
                                                    false
                                                ).field(types.Datastore.Field.Code)
                                        ),
                                        Comparator.Equals,
                                        new PrimitiveValue(
                                            DataObject.constructFromTypeIdAndValue(
                                                'Text',
                                                filterEventCode
                                            )
                                        )
                                    ),
                                    selectedStep !== undefined
                                        ?
                                        new ComparisonPredicate(
                                            new ValueFromEntityComputation(
                                                viewParameters.getParameterById(ViewParams.Entity),
                                                EntityPath.fromEntityType(types.SpotlerCampaignEvent.Type)
                                                    .joinTo(
                                                        types.Activity.SpotlerCampaignResult.RelationshipDefinition.Events,
                                                        true
                                                    )
                                                    .joinTo(
                                                        types.SpotlerCampaignStep.RelationshipDefinition.Results,
                                                        true
                                                    )
                                                    .field()
                                            ),
                                            Comparator.Equals,
                                            new EntityValue(selectedStep)
                                        )
                                        :
                                        undefined
                                ].filter(c => c !== undefined)
                            ),
                            new Specification(
                                new ListModel(
                                    [
                                        new Column(
                                            uuid(),
                                            EntityPath
                                                .fromEntityType(types.SpotlerCampaignEvent.Type)
                                                .field(types.SpotlerCampaignEvent.Field.Date)
                                        ),
                                        new Column(
                                            uuid(),
                                            EntityPath
                                                .fromEntityType(types.SpotlerCampaignEvent.Type)
                                                .joinTo(
                                                    types.Activity.SpotlerCampaignResult.RelationshipDefinition.Events,
                                                    true
                                                )
                                                .joinTo(
                                                    types.SpotlerCampaignStep.RelationshipDefinition.Results,
                                                    true
                                                )
                                                .field(types.SpotlerCampaignStep.Field.Name)
                                        ),
                                        new Column(
                                            uuid(),
                                            EntityPath
                                                .fromEntityType(types.SpotlerCampaignEvent.Type)
                                                .joinTo(
                                                    types.Activity.SpotlerCampaignResult.RelationshipDefinition.Events,
                                                    true
                                                )
                                                .joinTo(
                                                    types.Activity.RelationshipDefinition.Relationship,
                                                    true
                                                )
                                                .field()
                                        ),
                                        ...eventToEmailAddressFieldPath
                                            ?
                                            [
                                                new Column(
                                                    uuid(),
                                                    eventToEmailAddressFieldPath
                                                )
                                            ]
                                            :
                                            []
                                    ],
                                    []
                                )
                            )
                        );
                    },
                    [
                        types,
                        filterEventCode,
                        spotlerCampaign,
                        selectedStep,
                        eventToEmailAddressFieldPath
                    ]
                );

            const currentView =
                useMemo(
                    () =>
                    {
                        switch (tab)
                        {
                            case 1:
                                return viewSend;
                            case 2:
                                return viewDelivered;
                            default:
                                return viewEvents;

                        }
                    },
                    [
                        tab,
                        viewSend,
                        viewEvents
                    ]
                );

            const [ campaignSteps ] =
                useFetchedRelatedEntities(
                    spotlerCampaign,
                    types.Activity.SpotlerCampaign.RelationshipDefinition.Steps,
                    false
                );

            const stepOptions =
                useMemo(
                    () =>
                        campaignSteps && campaignSteps.length > 1
                            ?
                            [
                                {
                                    id: 'all',
                                    label: 'Complete campagne',
                                    value: 'all'
                                },
                                ...campaignSteps.map(
                                    step => (
                                        {
                                            id: step.uuid,
                                            label: step.getObjectValueByField(types.SpotlerCampaignStep.Field.Name),
                                            value: step
                                        }
                                    )
                                )
                            ]
                            :
                            undefined
                    ,
                    [
                        campaignSteps
                    ]
                );

            const onListItemClicked = useCallback(
                entity =>
                {
                    if (entity.entityType.isA(types.Activity.SpotlerCampaignResult.Type))
                    {
                        return openEntity(entity);
                    }
                    else if (entity.entityType.isA(types.SpotlerCampaignEvent.Type))
                    {
                        return openEntity(
                            entity.getRelatedEntityByDefinition(
                                true,
                                types.Activity.SpotlerCampaignResult.RelationshipDefinition.Events
                            )
                        );
                    }
                    return undefined;
                },
                [
                    types
                ]
            );

            return <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                        <ViewGroup
                            orientation="vertical"
                            spacing={15}
                        >
                            <ViewGroupItem>
                                {
                                    stepOptions &&
                                    <Input
                                        labelPosition="left"
                                        label=""
                                        inline
                                        labelFullWidth
                                    >
                                        <StaticSelectbox
                                            options={stepOptions}
                                            onChange={setSelectedStepOption}
                                            value={selectedStepOption}
                                        />
                                    </Input>
                                }
                            </ViewGroupItem>
                            <ViewGroupItem>
                                {
                                    isMobile
                                        ?
                                        <Card>
                                            <ExpansionPanel
                                                id="spotlerResultCharts"
                                                summary={
                                                    <Header
                                                        large
                                                        inset
                                                        title={
                                                            <LocalizedText
                                                                code="Campaign.Overview"
                                                                value="Overzicht"
                                                            />
                                                        }
                                                    />
                                                }
                                                expansion={
                                                <CardInset>
                                                    <SpotlerCampaignResultCharts
                                                        spotlerCampaign={spotlerCampaign}
                                                        selectedStep={selectedStep}
                                                    />
                                                </CardInset>
                                                }
                                            />
                                        </Card>
                                        :
                                        <Card
                                            inset
                                        >
                                            <SpotlerCampaignResultCharts
                                                spotlerCampaign={spotlerCampaign}
                                                selectedStep={selectedStep}
                                            />
                                        </Card>
                                }
                            </ViewGroupItem>
                        </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Card>
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                <TabBar
                                    value={tab}
                                >
                                    <Tab
                                        value={1}
                                        onClick={setTab}
                                    >
                                        <LocalizedText
                                            code="Campaign.SendCount"
                                            value="Verzonden (${sendCount})"
                                            sendCount={sendCount}
                                        />
                                    </Tab>
                                    <Tab
                                        value={2}
                                        onClick={setTab}
                                    >
                                        <LocalizedText
                                            code="Campaign.DeliveredCount"
                                            value="Bezorgd (${deliveredCount})"
                                            deliveredCount={deliveredCount}
                                        />
                                    </Tab>
                                    <Tab
                                        value={3}
                                        onClick={setTab}
                                    >
                                        <LocalizedText
                                            code="Campaign.OpenedCount"
                                            value="Geopend (${openedCount})"
                                            openedCount={viewCount}
                                        />
                                    </Tab>
                                    <Tab
                                        value={4}
                                        onClick={setTab}
                                    >
                                        <LocalizedText
                                            code="Campaign.ClickedCount"
                                            value="Aangeklikt (${clickedCount})"
                                            clickedCount={clickedCount}
                                        />
                                    </Tab>
                                    <Tab
                                        value={5}
                                        onClick={setTab}
                                    >
                                        <LocalizedText
                                            code="Campaign.BouncedCount"
                                            value="Mislukt (${bouncedCount})"
                                            bouncedCount={bouncedCount}
                                        />
                                    </Tab>
                                </TabBar>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <List
                                    view={currentView}
                                    onClick={onListItemClicked}
                                    readonly
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </Card>
                </ViewGroupItem>
            </ViewGroup>
        }
    );