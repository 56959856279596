import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import CardHeader from '../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import InputGroup from '../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import Constructor from '../../../../../Entity/Constructor/Constructor';
import useTypes from '../../../../../Entity/Type/Api/useTypes';
import MenuButton from '../../../../../Entity/Item/MenuButton/MenuButton';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import TextEditor from '../../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import performAction from '../../../../../../../@Api/Entity/performAction';
import Link from '../../../../../../../@Future/Component/Generic/Link/Link';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import WebhookTriggerEditor from './TriggerEditor/WebhookTriggerEditor';
import HoverCardBottom from '../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import { openEntity } from '../../../../../Entity/@Util/openEntity';
import Divider from '../../../../../../../@Future/Component/Generic/Divider/Divider';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { Alert } from '@material-ui/lab';
import { default as EntityInput } from '../../../../../Entity/Input/Input';
import { CommitBuilder } from '../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import DangerButton from '../../../../../../../@Future/Component/Generic/Button/Variant/DangerButton/DangerButton';
import { Typography } from '@material-ui/core';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface WebhookProps
{
    webhook: Entity;
    commitContext: CommitContext;
    onCreate?: (webhook: Entity) => void;
    showManageButton?: boolean;
}

const WebhookEditor: React.FC<WebhookProps> =
    ({
        webhook,
        commitContext,
        onCreate,
        showManageButton,
     }) =>
    {
        const types = useTypes();
        const [ secret, setSecret ] = useState<string>();
        const showSecret =
            useCallback(
                () =>
                    performAction<string>(
                        webhook,
                        {
                            code: 'Webhook.GetSecret',
                        })
                        .then(
                            result =>
                                setSecret(result.result)),
                [
                    webhook,
                    setSecret
                ]);
        const isDisabled =
            useEntityValue(
                webhook,
                types.Webhook.Field.IsDeactivated,
                undefined,
                commitContext
            );

        return <Card>
            <CardInset>
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                            alignment="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <CardHeader>
                                    {
                                        webhook.getObjectValueByField(types.Webhook.Field.Url, commitContext) ||
                                            <LocalizedText
                                                code="Configuration.API.NewWebhook"
                                                value="Nieuwe webhook"
                                            />
                                    }
                                </CardHeader>
                            </ViewGroupItem>
                            {
                                !webhook.isNew() &&
                                    <ViewGroupItem>
                                        <MenuButton
                                            entity={webhook}
                                        />
                                    </ViewGroupItem>
                            }
                        </ViewGroup>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <InputGroup>
                            <Constructor
                                entity={webhook}
                                autoCommit={!webhook.isNew()}
                                disableActions={!webhook.isNew()}
                                openable={false}
                                onSave={onCreate}
                                commitContext={commitContext}
                            />
                            {
                                !webhook.isNew() &&
                                    <Input
                                        label="Secret"
                                        labelPosition="left"
                                    >
                                        {
                                            secret
                                                ?
                                                    <TextEditor
                                                        value={secret}
                                                        onChange={() => {}}
                                                        disabled
                                                    />
                                                :
                                                    <Link
                                                        onClick={showSecret}
                                                        highlighted
                                                    >
                                                        <LocalizedText
                                                            code="Configuration.API.Show"
                                                            value="Tonen"
                                                        />
                                                    </Link>
                                        }
                                    </Input>
                            }
                        </InputGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </CardInset>
            <Divider />
            <WebhookTriggerEditor
                webhook={webhook}
            />
            <Divider />
            {
                showManageButton &&
                    <HoverCardBottom
                        onClick={() => openEntity(webhook)}
                    >
                        <LocalizedText
                            code="Generic.Manage"
                            value="Beheren"
                        />
                    </HoverCardBottom>
            }
            {
                isDisabled &&
                <Alert
                    severity="error"
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        <ViewGroupItem>
                            <Typography
                                variant="body1"
                            >
                                <LocalizedText
                                    code="Webhook.DeactivationMessage"
                                    value="Deze webhook is gedeactiveerd."
                                />
                            </Typography>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <EntityInput
                                entity={webhook}
                                field={types.Webhook.Field.DeactivationDate}
                                disabled
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <EntityInput
                                entity={webhook}
                                field={types.Webhook.Field.DeactivationReason}
                                disabled
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <DangerButton
                                label={
                                    <LocalizedText
                                        code="Generic.Reactivate"
                                        value="Heractiveren"
                                    />
                                }
                                onClick={
                                    () =>
                                        new CommitBuilder()
                                            .setObjectValueInEntity(
                                                webhook,
                                                types.Webhook.Field.IsDeactivated,
                                                false
                                            )
                                            .setObjectValueInEntity(
                                                webhook,
                                                types.Webhook.Field.DeactivationDate,
                                                undefined
                                            )
                                            .setObjectValueInEntity(
                                                webhook,
                                                types.Webhook.Field.DeactivationReason,
                                                undefined
                                            )
                                            .commit()
                                }
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </Alert>
            }
        </Card>;
    };

export default observer(WebhookEditor);
