import Parameter from '../Parameter/Parameter';
import Value from '../Value/Value';
import Dependency from '../Parameter/Dependency';
import EntityValueType from '../Value/Type/EntityValueType';
import EntityValue from '../Value/EntityValue';
import { Entity } from '../../Model/Implementation/Entity';
import { EntityExpansionBuilder } from '../../../@Component/Domain/Entity/Selection/Builder/EntityExpansionBuilder';
import { Disposer } from '../../../@Util/Disposable/Disposer';
import { CommitContext } from '../../Entity/Commit/Context/CommitContext';

export default async function initializeDependencies(
    parameter: Parameter<any>,
    values: Value<any, any>[],
    dependencies: Dependency[],
    commitContext?: CommitContext
): Promise<Disposer>
{
    if (parameter.type instanceof EntityValueType)
    {
        const entityType = parameter.type.type;
        const initializationPaths =
            dependencies
                .filter(
                    dependency =>
                        dependency.parameter === parameter
                            && dependency.fieldPath !== undefined
                )
                .map(
                    dependency =>
                        dependency.fieldPath.path
                );
        const entitiesToInitialize =
            values
                .filter(
                    value =>
                        value instanceof EntityValue
                            && value.value.entityType.isA(entityType)
                )
                .map(
                    value =>
                        value.value as Entity
                );

        if (initializationPaths.length > 0 && entitiesToInitialize.length > 0)
        {
            const expansionBuilder =
                new EntityExpansionBuilder(
                    entityType,
                    entitiesToInitialize,
                    initializationPaths,
                    commitContext
                );

            await expansionBuilder.expand();

            return () =>
                expansionBuilder.dispose();
        }
        else
        {
            return () => {};
        }
    }
    else
    {
        return () => {};
    }
}
