import React from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import Item from '../../../Item/Item';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import OverlineLabel from '../../../../../../@Future/Component/Generic/Label/Variant/Overline/OverlineLabel';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import ButtonGroup from './ButtonGroup/ButtonGroup';

import styles from './Header.module.scss';
import useIsFavoritable from '../../../Item/Navigator/Api/useIsFavoritable';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';

export interface HeaderProps
{
    entity: Entity;
    showTitle?: boolean;
    isInSidebar?: boolean;
    onClose?: () => void;
}

const Header: React.FC<HeaderProps> =
    props =>
    {
        const hasFavoriteButton = useIsFavoritable(props.entity);

        return <>
            <Card
                classes={{
                    root: styles.card
                }}
            >
                <CardInset
                    top
                    bottom={props.showTitle === true}
                    horizontal
                >
                    {
                        props.showTitle &&
                            <OverlineLabel>
                                {props.entity.entityType.getName(false, props.entity)}
                            </OverlineLabel>
                    }

                    <ButtonGroup
                        entity={props.entity}
                        isInSidebar={props.isInSidebar}
                        onClose={props.onClose}
                        showMenuButton={true}
                        class={
                            classNames(
                                styles.buttonGroup,
                                hasFavoriteButton ? styles.favoriteButtonSpacing : undefined
                            )
                        }
                    />

                </CardInset>
                <Item
                    classes={{
                        navigator:
                            {
                                root: styles.navigator,
                                toolbar: styles.navigatorToolbar,
                                expansion: styles.navigatorExpansion,
                                more: styles.navigatorMore
                            }
                    }}
                    entity={props.entity}
                    editable
                    horizontalInset
                    header
                    showMore
                />
            </Card>
        </>;
    };

export default observer(Header);
