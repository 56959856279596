import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Key from '../../../../../../../../../../../../@Api/Automation/Mapping/Key/Key';
import PredicateEditor from '../../../../../Predicate/PredicateEditor';
import Predicate from '../../../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { IObservableArray, runInAction } from 'mobx';
import DeleteIconButton from '../../../../../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import { KeysEditorProps } from './KeysEditor';
import CardInset from '../../../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import ComparisonPredicate from '../../../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../../../../../../../../../DataObject/Model/Comparator';
import LocalizedText from '../../../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface KeyEditorProps extends KeysEditorProps
{
    keyModel: Key;
    idx: number;
}

const KeyEditor: React.FC<KeyEditorProps> =
    props =>
    {
        const onChange =
            useCallback(
                (predicate?: Predicate) =>
                    runInAction(
                        () =>
                            props.keyModel.predicate = predicate),
                [
                    props.keyModel
                ]);

        const comparisonConstructor =
            useCallback(
                () =>
                    new ComparisonPredicate(
                        new ValueFromEntityComputation(props.mapping.parameter, undefined),
                        Comparator.Equals,
                        undefined),
                [
                    props.mapping
                ]);

        const deleteKey =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (props.mapping.keys as IObservableArray).remove(props.keyModel)),
                [
                    props.mapping,
                    props.keyModel
                ]);

        return <CardInset
            horizontal
        >
            <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                <ViewGroupItem>
                    <strong>
                        <LocalizedText
                            code="DetectDuplicates"
                            value="Duplicatendetectie"
                        /> {props.idx + 1}
                    </strong>
                </ViewGroupItem>
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            <PredicateEditor
                                context={props.context}
                                value={props.keyModel.predicate}
                                onChange={onChange}
                                comparisonConstructor={comparisonConstructor}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <DeleteIconButton
                                onClick={deleteKey}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
            </ViewGroup>
        </CardInset>;
    };

export default observer(KeyEditor);
