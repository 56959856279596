import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { observable } from 'mobx';

export class EntitySelectionResult
{
    // ------------------------- Properties -------------------------

    @observable.ref entity?: Entity;
    @observable.ref groupValue?: DataObject;
    @observable.ref groupInterval?: DataObject;
    @observable.shallow children?: EntitySelectionResult[];

    // ------------------------ Constructor -------------------------

    constructor(entity?: Entity,
                groupValue?: DataObject,
                groupInterval?: DataObject,
                children?: EntitySelectionResult[])
    {
        this.entity = entity;
        this.groupValue = groupValue;
        this.groupInterval = groupInterval;
        this.children = children;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}

// export interface EntitySelectionResult
// {
//     entity?: Entity;
//     groupValue?: DataObject;
//     groupInterval?: DataObject;
//     children?: EntitySelectionResult[];
// }
