import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import localizeText from '../../Localization/localizeText';
import Color from '../Style/Color';

export default class CardLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref layout: Layout;
    @observable.ref backgroundColor: Color;

    // ------------------------ Constructor -------------------------

    constructor(layout: Layout,
                backgroundColor?: Color)
    {
        super();

        this.layout = layout;
        this.backgroundColor = backgroundColor;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.Card', 'Kaart');
    }

    validate(): Validation[]
    {
        return this.layout.validate();
    }

    getDependencies(): Dependency[]
    {
        return this.layout.getDependencies();
    }

    toDescriptor()
    {
        return {
            type: 'Card',
            layout: this.layout.toDescriptor(),
            backgroundColor: this.backgroundColor?.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new CardLayout(
            await getLayoutFromDescriptor(
                descriptor.layout,
                dependencyContext),
            descriptor.backgroundColor
                ?
                    Color.fromDescriptor(descriptor.backgroundColor)
                :
                    undefined);
    }

    // ----------------------- Private logic ------------------------
}
