import Layout from '../../Layout';
import Dependency from '../../../Automation/Parameter/Dependency';
import Validation from '../../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../../LayoutDependencyContext';
import localizeText from '../../../Localization/localizeText';
import Parameter from '../../../Automation/Parameter/Parameter';
import TabSource from './Model/TabSource';
import getTabSourceByDescriptor from './Api/getTabSourceByDescriptor';

export default class TabBarLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref parameter: Parameter<any>;
    @observable.shallow sources: TabSource[];

    // ------------------------ Constructor -------------------------

    constructor(valueParameter: Parameter<any>,
                sources: TabSource[])
    {
        super();

        this.parameter = valueParameter;
        this.sources = sources;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.TabBar', 'Tabbalk');
    }

    validate(): Validation[]
    {
        return this.sources
            .map(
                source =>
                    source.validate())
            .reduce((a, b) => a.concat(b), []);
    }

    getDependencies(): Dependency[]
    {
        return [
            new Dependency(this.parameter, undefined),
            ...this.sources
                .map(
                    source =>
                        source.getDependencies())
                .reduce((a, b) => a.concat(b), [])
        ];
    }

    toDescriptor()
    {
        return {
            type: 'TabBar',
            parameterId: this.parameter.id,
            sources: this.sources.map(source => source.toDescriptor())
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const parameter =
            dependencyContext.parameterDictionary
                .getParameterById(descriptor.parameterId)

        return new TabBarLayout(
            parameter,
            await Promise.all(
                descriptor.sources
                    .map(
                        source =>
                            getTabSourceByDescriptor(
                                source,
                                dependencyContext,
                                parameter.type))));
    }

    // ----------------------- Private logic ------------------------
}
