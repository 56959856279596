import { computed, observable } from 'mobx';
import Connection from './Connection';
import { mapBy } from '../../../../@Util/MapUtils/mapBy';

export default class Room
{
    // ------------------------- Properties -------------------------

    @observable connections: Connection[];

    // ------------------------ Constructor -------------------------

    constructor(connections: Connection[])
    {
        this.connections = connections;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get connectionById(): Map<string, Connection>
    {
        return mapBy(
            this.connections,
            conn => conn.id);
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new Room(
            descriptor.connections
                .map(
                    connection =>
                        Connection.fromDescriptor(connection)));
    }

    toDescriptor()
    {
        return {
            connections:
                this.connections.map(
                    connection =>
                        connection.toDescriptor())
        };
    }

    // ----------------------- Private logic ------------------------
}
