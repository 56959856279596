import { YearlyRecurrencePattern } from '../../../Model/RecurrencePattern';
import { Frequency, Options } from 'rrule';
import { getRRuleMonth } from '../Util/getRRuleMonth';

export function getYearlyRecurrencePatternAsRRule(
    pattern: YearlyRecurrencePattern
): Partial<Options>
{
    return {
        freq: Frequency.YEARLY,
        interval: pattern.interval ?? 1,
        bymonth: getRRuleMonth(pattern.month),
        bymonthday: pattern.dayOfMonth,
    };
}
