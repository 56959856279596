import { SettingStore } from '../../../Domain/Setting/SettingStore';
import { DataObjectStore } from '../../../Domain/DataObject/DataObjectStore';
import { ApiControllerStore } from '../../../../@Api/Controller/ApiControllerStore';
import { BooleanType as DataObjectBooleanType } from '../../../Domain/DataObject/Type/Boolean/BooleanType';
import { CurrencyType as DataObjectCurrencyType } from '../../../Domain/DataObject/Type/Number/Currency/CurrencyType';
import { CurrencyRangeType as DataObjectCurrencyRangeType } from '../../../Domain/DataObject/Type/Number/Currency/CurrencyRange/CurrencyRangeType';
import { DateType as DataObjectDateType } from '../../../Domain/DataObject/Type/Date/DateType';
import { DateIntervalType as DataObjectDateIntervalType } from '../../../Domain/DataObject/Type/Date/DateInterval/DateIntervalType';
import { DatePeriodType as DataObjectDatePeriodType } from '../../../Domain/DataObject/Type/Date/DatePeriod/DatePeriodType';
import { DateRangeType as DataObjectDateRangeType } from '../../../Domain/DataObject/Type/Date/DateRange/DateRangeType';
import { DateTimeType as DataObjectDateTimeType } from '../../../Domain/DataObject/Type/Date/DateTime/DateTimeType';
import { TimeType as DataObjectTimeType } from '../../../Domain/DataObject/Type/Time/TimeType';
import { EmailType as DataObjectEmailType } from '../../../Domain/DataObject/Type/Text/Email/EmailType';
import { NumberType as DataObjectNumberType } from '../../../Domain/DataObject/Type/Number/NumberType';
import { NumberRangeType as DataObjectNumberRangeType } from '../../../Domain/DataObject/Type/Number/NumberRange/NumberRangeType';
import { PercentageType as DataObjectPercentageType } from '../../../Domain/DataObject/Type/Number/Percentage/PercentageType';
import { PercentageRangeType as DataObjectPercentageRangeType } from '../../../Domain/DataObject/Type/Number/Percentage/PercentageRange/PercentageRangeType';
import { PhoneNumberType as DataObjectPhoneNumberType } from '../../../Domain/DataObject/Type/Text/PhoneNumber/PhoneNumberType';
import { UrlType as DataObjectUrlType } from '../../../Domain/DataObject/Type/Text/Url/UrlType';
import { TextType as DataObjectTextType } from '../../../Domain/DataObject/Type/Text/TextType';
import { RichTextType as DataObjectRichTextType } from '../../../Domain/DataObject/Type/RichText/RichTextType';
import { LocalizedTextType as DataObjectLocalizedTextType } from '../../../Domain/DataObject/Type/LocalizedText/LocalizedTextType';
import { FileType as DataObjectFileType } from '../../../Domain/DataObject/Type/File/FileType';
import { ColorType as DataObjectColorType } from '../../../Domain/DataObject/Type/Color/ColorType';
import { ComplexType as DataObjectComplexType } from '../../../Domain/DataObject/Type/Complex/ComplexType';
import { EntityPathType as DataObjectEntityPathType } from '../../../Domain/DataObject/Type/Complex/EntityPath/EntityPathType';
import { EntityFieldPathType as DataObjectEntityFieldPathType } from '../../../Domain/DataObject/Type/Complex/EntityFieldPath/EntityFieldPathType';
import { ComputationType as DataObjectComputationType } from '../../../Domain/DataObject/Type/Complex/Computation/ComputationType';
import { PredicateType as DataObjectPredicateType } from '../../../Domain/DataObject/Type/Complex/Predicate/PredicateType';
import { EntityFieldType as DataObjectEntityFieldType } from '../../../Domain/DataObject/Type/Api/EntityField/EntityFieldType';
import { EntityTypeType as DataObjectEntityTypeType } from '../../../Domain/DataObject/Type/Api/EntityType/EntityTypeType';
import { EntityType as DataObjectEntityType } from '../../../Domain/DataObject/Type/Api/Entity/EntityType';
import { UserType as DataObjectUserType } from '../../../Domain/DataObject/Type/Api/User/UserType';
import { MeType as DataObjectMeType } from '../../../Domain/DataObject/Type/Me/MeType';
import { Localizer } from '../../../../@Service/Localization/Localizer';
import { ApiClient } from '../../../../@Service/ApiClient/ApiClient';
import { AuthenticationManager } from '../../../../@Service/Authentication/AuthenticationManager';
import { RouterStore } from '../../../../@Service/Router/RouterStore';
import { GlobalEnvironment } from '../../../../@Global/GlobalEnvironment';
import { DragAndDropStore } from '../../../Generic/DragAndDrop/DragAndDropStore';
import { ApiStateStore } from '../ApiState/ApiStateStore';
import { observable } from 'mobx';
import { EntityTypeStore } from '../../../Domain/Entity/Type/EntityTypeStore';
import { PredicateTypeStore } from '../../../Domain/Predicate/PredicateTypeStore';
import { ComputationTypeStore } from '../../../Domain/Computation/ComputationTypeStore';
import { loadModuleDirectly, Module, ModuleContext, ModuleManager } from '../../../../@Util/DependencyInjection/index';
import { ExpressionComputationType } from '../../../Domain/Computation/Type/Expression/ExpressionComputationType';
import { CompositeComputationType } from '../../../Domain/Computation/Type/Composite/CompositeComputationType';
import { ConditionalComputationType } from '../../../Domain/Computation/Type/Conditional/ConditionalComputationType';
import { EntityFieldComputationType } from '../../../Domain/Computation/Type/Entity/Field/EntityFieldComputationType';
import { ConstantComputationType } from '../../../Domain/Computation/Type/Constant/ConstantComputationType';
import { EntityAggregateComputationType } from '../../../Domain/Computation/Type/Entity/Aggregate/EntityAggregateComputationType';
import { RightPredicateType } from '../../../Domain/Predicate/Type/Right/RightPredicateType';
import { ComparisonPredicateType } from '../../../Domain/Predicate/Type/Comparison/ComparisonPredicateType';
import { NotPredicateType } from '../../../Domain/Predicate/Type/Not/NotPredicateType';
import { CompositePredicateType } from '../../../Domain/Predicate/Type/Composite/CompositePredicateType';
import { Route } from '../../../../@Service/Router/Model/Route';
import { LocalizationStore } from '../../../../@Service/Localization/LocalizationStore';
import { EntityCacheService } from '../../../Service/Entity/EntityCacheService';
import { v4 as uuid } from 'uuid';
import { FeedbackStore } from '../Environment/Organization/Feedback/FeedbackStore';
import { WelcomeStore } from '../../../../@Service/Welcome/WelcomeStore';
import { ComponentPageStore } from '../../../../@Service/Navigation/Page/Component/ComponentPageStore';
import View from '../../../Domain/Entity/View/Model/View';
import { catchImport } from '../../../../@Util/Import/catchImport';
import { isInOutlookAddIn, isInWordAddIn } from '../Environment/Public/AddIn/OfficeAddin';
import getBrowserLanguageCode from '../Environment/Public/Registration/Api/getBrowserLanguageCode';
import getViewParameters from '../../../Domain/Entity/View/Api/getViewParameters';
import { ClientLanguageHeader } from '../../../../@Service/Localization/LocalizationConstants';
import { EntityQueryCacheService } from '../../../Service/Entity/EntityQueryCacheService';
import getSystemDefaultView from '../../../Domain/Entity/View/Api/getSystemDefaultView';
import { getQueryParameterFromUrl } from '../../../../@Util/Url/getQueryParameter';
import { getSelectionOptionsValueFromStorage } from '../../../Domain/Entity/SelectionOptions/Api/getSelectionOptionsValueFromStorage';
import { CurrentUserStore } from '../../../Domain/User/CurrentUserStore';

export class RootContext extends ModuleContext
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // Keep setting store here: it is not used (it is actually defined in AppContext), but removing the import from
    // the top of this file will result in a 'Object prototype may only be an Object or null: undefined' error
    @observable.ref settingStore: SettingStore;
    @observable.ref localizationStore: LocalizationStore;
    @observable.ref router: RouterStore;
    @observable.ref apiClient: ApiClient;
    @observable.ref apiStateStore: ApiStateStore;
    @observable.ref apiControllerStore: ApiControllerStore;
    @observable.ref welcomeStore: WelcomeStore;
    @observable.ref authenticationManager: AuthenticationManager;
    @observable.ref localize: Localizer;
    @observable.ref dataObjectStore: DataObjectStore;
    // @observable.ref widgetTypeStore: WidgetTypeStore;
    @observable.ref dragAndDropStore: DragAndDropStore;
    @observable.ref predicateTypeStore: PredicateTypeStore;
    @observable.ref computationTypeStore: ComputationTypeStore;
    @observable.ref entityTypeStore: EntityTypeStore;
    @observable.ref entityCacheService: EntityCacheService;
    @observable.ref entityQueryCacheService: EntityQueryCacheService;
    @observable.ref feedbackStore: FeedbackStore;

    // ------------------------ Constructor -------------------------

    /* eslint-disable-next-line @typescript-eslint/no-useless-constructor */
    constructor(moduleManager: ModuleManager)
    {
        super(moduleManager);
    }

    // ----------------------- Initialization -----------------------

    defineModules()
    {
        // Setup router
        this.router =
            new RouterStore(
                [],
                [],
                routeType =>
                {
                    switch (routeType)
                    {
                        case 'Public':
                            return true;

                        case 'App':
                            return this.authenticationManager.isAuthenticated;

                        case 'Portal':
                            return this.authenticationManager.isAuthenticated;

                        default:
                            return false;
                    }
                },
                [
                    new Route(
                        '/',
                        true,
                        'App',
                        () =>
                            import('../../../Domain/Workspace/WorkspaceStore')
                                .then(
                                    index =>
                                        Promise.resolve(new index.WorkspaceStore()))
                                .catch(catchImport)),

                    // Public
                    new Route(
                        '/microsoft-outlook',
                        true,
                        'Public',
                        () =>
                            import('../Environment/Public/AddIn/Outlook/MicrosoftOutlook')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Microsoft Outlook',
                                                index.default)))
                                .catch(catchImport)),
                    new Route(
                        '/microsoft-addin/authenticate',
                        true,
                        'Public',
                        () =>
                            import('../Environment/Public/AddIn/Authenticate/Authenticate')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Authenticatie',
                                                index.default)))
                                .catch(catchImport)),
                    new Route(
                        '/microsoft-word',
                        true,
                        'Public',
                        () =>
                            import('../Environment/Public/AddIn/Word/MicrosoftWord')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Microsoft Word',
                                                index.default)))
                                .catch(catchImport)),
                    new Route(
                        '/microsoft-word/taskpane',
                        true,
                        'App',
                        () =>
                            import('../Environment/Public/AddIn/Word/WordAddInTaskPane')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Microsoft Word Task pane',
                                                index.WordAddInTaskPane
                                            )
                                        )
                                )
                                .catch(catchImport)
                    ),
                    new Route(
                        '/logout',
                        true,
                        'Public',
                        parameters =>
                            import('../Environment/Public/Logout/Logout')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Uitloggen',
                                                index.default,
                                                {
                                                    doRedirectToLoginAfterLogout: parameters.doRedirectToLoginAfterLogout,
                                                }
                                            )
                                        )
                                )
                                .catch(catchImport)
                    ),
                    new Route(
                        '/login',
                        true,
                        'Public',
                        parameters =>
                            import('../Environment/Public/Login/LoginStore')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new index.LoginStore(
                                                parameters.state
                                            )
                                        )
                                )
                                .catch(catchImport)),
                    new Route(
                        '/auth/callback',
                        false,
                        'Public',
                        parameters =>
                            import('../Environment/Public/Authentication/AuthCallbackStore')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new index.AuthCallbackStore(parameters)))
                                .catch(catchImport)),
                    new Route(
                        '/register',
                        true,
                        'Public',
                        () =>
                            import('../Environment/Public/Registration/EnvironmentRegistration/EnvironmentRegistration')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Registratie',
                                                index.default as any
                                            )
                                        )
                                )
                                .catch(catchImport)),
                    new Route(
                        '/register/validate',
                        true,
                        'Public',
                        props =>
                            import('../Environment/Public/Registration/Validate/Validate')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Validatie',
                                                index.default as any,
                                                props)))
                                .catch(catchImport)),
                    new Route(
                        '/register/account',
                        true,
                        'Public',
                        props =>
                            import('../Environment/Public/Registration/AccountRegistration/AccountRegistration')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Registratie',
                                                index.default as any,
                                                props)))
                                .catch(catchImport)),
                    new Route(
                        '/register/accept-invitation',
                        true,
                        'Public',
                        props =>
                            import('../Environment/Public/Registration/AcceptInvitation/AcceptInvitation')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Uitnodiging',
                                                index.default as any,
                                                props)))
                                .catch(catchImport)),
                    new Route(
                        '/reset-password',
                        true,
                        'Public',
                        props =>
                            import('../Environment/Public/ResetPassword/Request/Request')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Nieuw wachtwoord aanvragen',
                                                index.default as any,
                                                props)))
                                .catch(catchImport)),
                    new Route(
                        '/reset-password/reset',
                        true,
                        'Public',
                        props =>
                            import('../Environment/Public/ResetPassword/Reset/Reset')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Wachtwoord resetten',
                                                index.default as any,
                                                props)))
                                .catch(catchImport)),
                    new Route(
                        '/resend-validation-link',
                        true,
                        'Public',
                        props =>
                            import('../Environment/Public/ResendValidationLink/ResendValidationLink')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Validatielink opnieuw versturen',
                                                index.default as any,
                                                props)))
                                .catch(catchImport)),
                    new Route(
                        '/sign-offer',
                        true,
                        'Public',
                        props =>
                            import('../Environment/Public/SignOffer/SignOffer')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Offerte',
                                                index.default as any,
                                                props)))
                                .catch(catchImport)),
                    new Route(
                        '/pay-invoice/:organizationId/:invoiceId',
                        true,
                        'Public',
                        props =>
                            import('../Environment/Public/PayInvoice/Pay/PayInvoice')
                                .then(
                                    index =>
                                        Promise
                                            .resolve(
                                                new ComponentPageStore(
                                                    'Betaal Factuur',
                                                    index.PayInvoice as any,
                                                    props)))
                                .catch(catchImport)),
                    new Route(
                        '/sign-workorder',
                        true,
                        'Public',
                        props =>
                            import('../Environment/Public/SignWorkOrder/SignWorkOrder')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Werkbon',
                                                index.default as any,
                                                props)))
                                .catch(catchImport)),
                    new Route(
                        '/validate-domain',
                        true,
                        'Public',
                        props =>
                            import('../Environment/Public/ValidateDomain/ValidateDomain')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Domeinvalidatie',
                                                index.default as any,
                                                props)))
                                .catch(catchImport)),
                    new Route(
                        '/validate-smtpserver-domain',
                        true,
                        'App',
                        props =>
                            import('../../../Domain/Marketplace/Connector/Type/SMTPServer/Views/validation/ValidateSMTPDomain')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Valideer SMTP domein',
                                                index.ValidateSMTPDomain,
                                                props
                                            )
                                        )
                                )
                                .catch(catchImport)),
                    new Route(
                        '/confirm-smtpserver-testmail',
                        true,
                        'App',
                        props =>
                            import('../../../Domain/Marketplace/Connector/Type/SMTPServer/Views/validation/ConfirmSMTPServerTestMail')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Bevestig ontvangst SMTP test e-mail',
                                                index.ConfirmSMTPServerTestMail,
                                                props
                                            )
                                        )
                                )
                                .catch(catchImport)),
                    new Route(
                        '/form/:formId(/:renderMode)',
                        true,
                        'Public',
                        props =>
                            import('../../../Domain/Entity/Form/Page/Wrapper/FormPageWrapper')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Formulier',
                                                index.default as any,
                                                {
                                                    ...props,
                                                    embedded: props.renderMode === 'embedded'
                                                },
                                                undefined,
                                                false)))
                                .catch(catchImport)),
                    new Route(
                        '/email-preferences(/:emailId)',
                        true,
                        'Public',
                        props =>
                            import('../Environment/Public/EmailingPreferences/EmailingPreferencesView')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'E-mail voorkeuren',
                                                index.default as any,
                                                props)))
                                .catch(catchImport)),
                    new Route(
                        '/oauth2-request-refresh-token',
                        true,
                        'Public',
                        () =>
                            import('../../../Domain/Configuration/Page/Api/Applications/RequestRefreshTokenPage/RequestRefreshTokenPage')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Refresh token',
                                                index.default)))
                                .catch(catchImport)),

                    // App
                    new Route(
                        '/dashboard/:id',
                        true,
                        'App',
                        parameters =>
                            import('../../../Domain/Workspace/WorkspaceStore')
                                .then(
                                    index =>
                                        Promise.resolve(new index.WorkspaceStore(parameters.id)))
                                .catch(catchImport)),
                    new Route(
                        '/type/:id/import',
                        true,
                        'App',
                        parameters =>
                            import('../../../Domain/Entity/TypeImport/TypeImport')
                                .then(
                                    index =>
                                    {
                                        const entityType = this.entityTypeStore.getTypeById(parseInt(parameters.id));

                                        if (entityType)
                                        {
                                            return Promise.resolve(
                                                new ComponentPageStore(
                                                    entityType.getName(true),
                                                    index.default,
                                                    {
                                                        entityType: entityType
                                                    },
                                                    entityType.inheritedIcon,
                                                    false));
                                        }
                                        else
                                        {
                                            return Promise.resolve(undefined);
                                        }
                                    })
                                .catch(catchImport)),
                    new Route(
                        '/type/:id(/:specification)(/:pageId)(/:resourceId)',
                        true,
                        'App',
                        parameters =>
                            import('../../../Domain/Entity/TypeView/TypeView')
                                .then(
                                    async index =>
                                    {
                                        const entityType = this.entityTypeStore.getTypeById(parseInt(parameters.id));

                                        if (entityType)
                                        {
                                            const openPage =
                                                (props: any) =>
                                                    Promise.resolve(
                                                        new ComponentPageStore(
                                                            entityType.getName(true),
                                                            index.default,
                                                            props,
                                                            entityType.inheritedIcon));

                                            try
                                            {
                                                if (!parameters.specification)
                                                {
                                                    return openPage({
                                                        entityType: entityType
                                                    });
                                                }
                                                else if (parameters.specification === 'showHidden')
                                                {
                                                    return openPage({
                                                        entityType: entityType,
                                                        showHiddenEntities: true,
                                                    });
                                                }
                                                else if (parameters.specification === 'configuration')
                                                {
                                                    return openPage({
                                                        entityType: entityType,
                                                        inConfiguration: true,
                                                        configurationPageId: parameters.pageId,
                                                        configurationResourceId: parameters.resourceId
                                                    });
                                                }
                                                else if (parameters.specification === 'view')
                                                {
                                                    if (parameters.pageId === 'all')
                                                    {
                                                        return openPage({
                                                            entityType: entityType
                                                        });
                                                    }
                                                    else
                                                    {
                                                        return openPage({
                                                            entityType: entityType,
                                                            viewId: parameters.pageId,
                                                        });
                                                    }
                                                }
                                                else
                                                {
                                                    const specification = JSON.parse(atob(parameters.specification));

                                                    if (specification.selectionOptionsValueKey)
                                                    {
                                                        const selectionOptionsValue =
                                                            await getSelectionOptionsValueFromStorage(
                                                                specification.selectionOptionsValueKey
                                                            );

                                                        return openPage({
                                                            entityType: entityType,
                                                            selectionOptionsValue: selectionOptionsValue
                                                        });
                                                    }
                                                    else
                                                    {
                                                        const resolveView =
                                                            async () =>
                                                            {
                                                                if (specification.view)
                                                                {
                                                                    return View.fromDescriptor(specification.view);
                                                                }
                                                                else if (specification.filter?.predicate)
                                                                {
                                                                    return getSystemDefaultView(
                                                                        entityType,
                                                                        getViewParameters(entityType),
                                                                        specification.filter.predicate,
                                                                        specification.filter.name);
                                                                }
                                                                else
                                                                {
                                                                    return undefined;
                                                                }
                                                            };
                                                        const view = await resolveView();

                                                        return openPage({
                                                            entityType: entityType,
                                                            view: view
                                                        });
                                                    }
                                                }
                                            }
                                            catch (e)
                                            {
                                                console.error('error while deserializing view', e);

                                                return openPage({
                                                    entityType: entityType
                                                });
                                            }
                                        }
                                        else
                                        {
                                            return Promise.resolve(undefined);
                                        }
                                    })
                                .catch(catchImport)),
                    new Route(
                        '/time-and-billing',
                        true,
                        'App',
                        () =>
                            import('../../../Domain/TimeAndBilling/TimeAndBilling')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'Uren & facturen',
                                                index.default,
                                                {},
                                                'timeline')))
                                .catch(catchImport)),
                    new Route(
                        '/configuration/packs/link-pack',
                        true,
                        'App',
                        parameters =>
                            import('../../../Domain/Configuration/Page/Packs/LinkPackPage/LinkPackPage')
                                .then(
                                    index =>
                                    {
                                        return Promise.resolve(
                                            new ComponentPageStore(
                                                `Pack '${parameters.packName}' linken`,
                                                index.default,
                                                parameters,
                                                'settings'));
                                    })
                                .catch(catchImport)),
                    new Route(
                        '/configuration(/:pageId)',
                        true,
                        'App',
                        parameters =>
                            import('../../../Domain/Configuration/Configuration')
                                .then(
                                    index =>
                                    {
                                        return Promise.resolve(
                                            new ComponentPageStore(
                                                'Configuratie',
                                                index.default,
                                                parameters,
                                                'settings',
                                                false));
                                    })
                                .catch(catchImport)),
                    new Route(
                        '/translation-center',
                        true,
                        'App',
                        () =>
                            import('../../../Domain/Configuration/Page/TranslationCenter/TranslationCenter')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'TranslationCenter',
                                                index.default
                                            )
                                        )
                                )
                                .catch(catchImport)),
                    new Route(
                        '/marketplace(/:category(/:connectorId))',
                        true,
                        'App',
                        parameters =>
                            import('../../../Domain/Marketplace/Marketplace')
                                .then(
                                    index =>
                                    {
                                        return Promise.resolve(
                                            new ComponentPageStore(
                                                'Marketplace',
                                                index.Marketplace,
                                                {
                                                    category: parameters.category,
                                                    connectorId: parameters.connectorId
                                                },
                                                'storefront'
                                            )
                                        );
                                    }
                                )
                                .catch(catchImport)),
                    new Route(
                        '/auth/callback',
                        false,
                        'App',
                        parameters =>
                        {
                            return import('../Environment/Public/Authentication/AuthCallbackStore')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new index.AuthCallbackStore(parameters)))
                                .catch(catchImport)
                        }),
                    new Route(
                        '/account',
                        false,
                        'App',
                        () =>
                            {
                                if (this.isAdmin())
                                {
                                    return import('../../../Domain/License/Contract/ContractPageStore')
                                        .then(
                                            index => Promise.resolve(
                                                new index.ContractPageStore()
                                            )
                                        )
                                        .catch(catchImport)
                                }
                                else
                                {
                                    return Promise.resolve(undefined);
                                }
                            }
                    ),
                    new Route(
                        '/user-settings',
                        false,
                        'App',
                        () =>
                            import('../../../Domain/User/UserSettings/PersonalSettingsPageStore')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new index.PersonalSettingsPageStore()
                                        )
                                )
                                .catch(catchImport)
                    ),
                    new Route(
                        '/change-username/accept',
                        true,
                        'Public',
                        props =>
                            import('../Environment/Public/ChangeUsername/ChangeUsername')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new ComponentPageStore(
                                                'ChangeUsername',
                                                index.default as any,
                                                props)))
                                .catch(catchImport)),
                    new Route(
                       '/agenda(/:settingStorageKey)',
                        false,
                        'App',
                        parameters =>
                            import('../../../Domain/Entity/Agenda/Page/AgendaPageStore')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new index.AgendaPageStore(
                                                parameters.settingStorageKey
                                                    ? atob(parameters.settingStorageKey)
                                                    : undefined
                                            )))
                                .catch(catchImport)),
                    new Route(
                        '/timesheet',
                        false,
                        'App',
                        () =>
                            import('../../../Domain/Entity/Timetracker/TimeSheet/Page/TimesheetPageStore')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new index.TimesheetPageStore()))
                                .catch(catchImport)),
                    new Route(
                        '/entity/:entityId(/:command)',
                        false,
                        'App',
                        parameters =>
                            import('../../../Domain/Entity/Viewer/Page/Initializer/EntityViewerPageInitializerStore')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new index.EntityViewerPageInitializerStore(
                                                parameters.entityId,
                                                undefined,
                                                undefined,
                                                undefined,
                                                // entity =>
                                                // {
                                                //     this.routerStore.goBack();
                                                // },
                                                parameters.command)))
                                .catch(catchImport)),
                    new Route(
                        '/merge/:compareIds',
                        false,
                        'App',
                        parameters =>
                            import('../../../Domain/Merge/Merge')
                                .then(
                                    index =>
                                    {
                                        return Promise.resolve(
                                            new ComponentPageStore(
                                                'Merge',
                                                index.default,
                                                {
                                                    compareIds: parameters.compareIds
                                                },
                                                'call_merge'
                                            )
                                        );
                                    })
                                .catch(catchImport)),
                    new Route(
                        '/management/account',
                        false,
                        'App',
                        () =>
                            {
                                if (this.isSupport())
                                {
                                    return import('../../../Domain/Management/Account/AccountManagementStore')
                                        .then(
                                            index =>
                                                Promise.resolve(
                                                    new index.AccountManagementStore()))
                                        .catch(catchImport)
                                }
                                else
                                {
                                    return Promise.resolve(undefined);
                                }
                            }
                    ),
                    new Route(
                        '/management/application',
                        false,
                        'App',
                        () =>
                            {
                                if (this.isSupport())
                                {
                                    return import('../../../Domain/Management/Application/ApplicationManagementStore')
                                        .then(
                                            index =>
                                                Promise.resolve(
                                                    new index.ApplicationManagementStore()))
                                        .catch(catchImport)
                                }
                                else
                                {
                                    return Promise.resolve(undefined);
                                }
                            }
                    ),
                    new Route(
                        '/management/configuration(/type/:entityTypeId)',
                        false,
                        'App',
                        parameters =>
                            {
                                if (this.isSupport())
                                {
                                    return import('../../../Domain/Management/Configuration/ConfigurationManagementPageStore')
                                        .then(
                                            index =>
                                                Promise.resolve(
                                                    new index.ConfigurationManagementPageStore(
                                                        this.entityTypeStore.getTypeById(
                                                            parseInt(parameters.entityTypeId, 10)))))
                                        .catch(catchImport)
                                }
                                else
                                {
                                    return Promise.resolve(undefined);
                                }
                            }
                    ),
                    new Route(
                        '/management/development',
                        false,
                        'App',
                        () =>
                            {
                                if (this.isSupport())
                                {
                                    return import('../../../Domain/Management/Development/DevelopmentManagementStore')
                                        .then(
                                            index =>
                                                Promise.resolve(
                                                    new index.DevelopmentManagementStore()))
                                        .catch(catchImport)
                                }
                                else
                                {
                                    return Promise.resolve(undefined);
                                }
                            }
                    ),
                    new Route(
                        '/bespoke/activity/email/link-external-email/:hash',
                        false,
                        'App',
                        () =>
                            import('../../../Domain/Entity/Bespoke/Activity/Email/ExternalEmail/LinkExternalEmail/LinkExternalEmailPageStore')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new index.LinkExternalEmailPageStore({} as any)))
                                .catch(catchImport)),
                    new Route(
                        '/bespoke/activity/email/compose-external-email/:hash',
                        false,
                        'App',
                        () =>
                            import('../../../Domain/Entity/Bespoke/Activity/Email/ExternalEmail/ComposeExternalEmail/ComposeExternalEmailPageStore')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new index.ComposeExternalEmailPageStore({} as any)))
                                .catch(catchImport)),
                    new Route(
                        '/bespoke/activity/appointment/link-external-appointment/:hash',
                        false,
                        'App',
                        () =>
                            import('../../../Domain/Entity/Bespoke/Activity/Appointment/ExternalAppointment/LinkExternalAppointment/LinkExternalAppointmentPageStore')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new index.LinkExternalAppointmentPageStore({} as any)))
                                .catch(catchImport)),

                    // Portals:
                    new Route(
                        '/*any',
                        false,
                        'Portal',
                        parameters =>
                            import('../Environment/Public/Login/LoginStore')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new index.LoginStore(
                                                parameters.state
                                            )
                                        )
                                )
                                .catch(catchImport)),

                    // Public (after portal / route):
                    new Route(
                        '/',
                        true,
                        'Public',
                        parameters =>
                            import('../Environment/Public/Login/LoginStore')
                                .then(
                                    index =>
                                        Promise.resolve(
                                            new index.LoginStore(
                                                parameters.state
                                            )
                                        )
                                )
                                .catch(catchImport)),
                ],
                '/');

        this.moduleManager.define(
            new Module(this.router, [ AuthenticationManager ], 'RouterStore'));

        // Feedback
        this.feedbackStore = new FeedbackStore();
        this.moduleManager.define(
            new Module(this.feedbackStore, [], 'FeedbackStore'));

        // Setup api state store
        this.apiStateStore = new ApiStateStore();
        this.moduleManager.define(
            new Module(this.apiStateStore, [], 'ApiStateStore'));

        // Setup api client
        const connectionId = uuid();
        const defaultHeaders: any = {};
        defaultHeaders['Connection-Id'] = connectionId;
        defaultHeaders[ClientLanguageHeader] = getBrowserLanguageCode()?.toLowerCase();

        this.apiClient =
            new ApiClient(
                GlobalEnvironment.API_ENDPOINT,
                [],
                defaultHeaders,
                {},
                (request, response) => this.apiStateStore.reportDown(response),
                () =>
                {
                    if (this.authenticationManager.isInitialized)
                    {
                        // If the app runs in an office add-in, reopen the microsoft start page (so you are reauthenticated)
                        // Otherwise, the app opens a new browser window
                        if (isInOutlookAddIn())
                        {
                            document.location.href = '/microsoft-outlook';

                            return Promise.resolve();
                        }
                        else if (isInWordAddIn())
                        {
                            document.location.href = '/microsoft-word';

                            return Promise.resolve();
                        }
                        else
                        {
                            const uri = new URL(document.location.href);
                            const currentAuthenticationAttempt = parseInt(getQueryParameterFromUrl('authenticationAttempt') ?? '0');

                            if (currentAuthenticationAttempt < 2)
                            {
                                uri.searchParams.set('authenticationAttempt', `${currentAuthenticationAttempt + 1}`);
                                document.location.href = uri.toString();
                            }
                        }
                    }
                    else
                    {
                        return Promise.resolve();
                    }
                },
                (request, response) => this.apiStateStore.reportError(request, response),
                () => this.apiStateStore.reportLive());

        this.moduleManager.define(
            new Module(this.apiClient, [], 'ApiClient'));

        // Setup api controller store
        this.apiControllerStore = new ApiControllerStore(this.apiClient, this.moduleManager);
        this.apiControllerStore.initialize();
        this.moduleManager.define(
            new Module(this.apiControllerStore, [ ApiClient ], 'ApiControllerStore'));

        // Setup authentication manager
        this.authenticationManager =
            new AuthenticationManager(
                this.apiClient,
                this.apiControllerStore.accountUserController
            );
        this.moduleManager.define(
            new Module(this.authenticationManager, [ ApiClient ], 'AuthenticationManager'),
            instance =>
                instance.initializeStore());
        this.authenticationManager.onContextSwitch(
            () =>
                this.router.reset());

        // Setup welcome store
        this.welcomeStore = new WelcomeStore();
        this.moduleManager.define(
            new Module(this.welcomeStore, [ AuthenticationManager ], 'WelcomeStore'),
            instance => instance.initializeStore());
        this.authenticationManager.onContextSwitch(
            () =>
                this.welcomeStore.reinitializeStore());

        // Setup localization
        this.localize = new Localizer();
        this.moduleManager.define(
            new Module(this.localize, [ WelcomeStore ], 'Localizer'),
            instance =>
                instance.initializeStore());
        this.welcomeStore.onUpdate(
            () =>
                this.localize.reinitializeStore());

        // Setup data object factory
        this.dataObjectStore = new DataObjectStore(
        [
            new DataObjectTextType(),
            new DataObjectRichTextType(),
            new DataObjectLocalizedTextType(),
            new DataObjectEmailType(),
            new DataObjectPhoneNumberType(),
            new DataObjectUrlType(),
            new DataObjectNumberType(),
            new DataObjectNumberRangeType(),
            new DataObjectPercentageType(),
            new DataObjectPercentageRangeType(),
            new DataObjectCurrencyType(),
            new DataObjectCurrencyRangeType(),
            new DataObjectBooleanType(),
            new DataObjectDateType(),
            new DataObjectDateTimeType(),
            new DataObjectDateRangeType(),
            new DataObjectDateIntervalType(),
            new DataObjectDatePeriodType(),
            new DataObjectTimeType(),
            new DataObjectFileType(),
            new DataObjectEntityFieldType(),
            new DataObjectEntityType(),
            new DataObjectUserType(),
            new DataObjectMeType(),
            new DataObjectColorType(),
            new DataObjectComplexType(),
            new DataObjectEntityPathType(),
            new DataObjectEntityFieldPathType(),
            new DataObjectComputationType(),
            new DataObjectPredicateType()
        ], this.moduleManager);
        this.moduleManager.define(
            new Module(this.dataObjectStore, [], 'DataObjectStore'));

        // Setup drag and drop context
        this.dragAndDropStore = new DragAndDropStore();
        this.moduleManager.define(
            new Module(this.dragAndDropStore, [], 'DragAndDropStore'));

        // Setup predicate type store
        this.predicateTypeStore =
            new PredicateTypeStore(
                [
                    new CompositePredicateType(),
                    new ComparisonPredicateType(),
                    new NotPredicateType(),
                    new RightPredicateType()
                ]);
        this.moduleManager.define(
            new Module(this.predicateTypeStore, [], 'PredicateTypeStore'));

        // Setup computation type store
        this.computationTypeStore =
            new ComputationTypeStore(
                [
                    new CompositeComputationType(),
                    new ConstantComputationType(),
                    new ConditionalComputationType(),
                    new ExpressionComputationType(),
                    new EntityFieldComputationType(),
                    new EntityAggregateComputationType()
                ]);
        this.moduleManager.define(
            new Module(this.computationTypeStore, [], 'ComputationTypeStore', ComputationTypeStore));

        // Setup query cache service
        this.entityQueryCacheService = new EntityQueryCacheService();
        this.moduleManager.define(
            new Module(this.entityQueryCacheService, [ AuthenticationManager ], 'EntityQueryCacheService'),
            instance =>
                instance.initializeStore());
        this.welcomeStore.onUpdate(
            () =>
                this.entityQueryCacheService.clear());

        // Setup cache service
        this.entityCacheService = new EntityCacheService(connectionId);
        this.moduleManager.define(
            new Module(this.entityCacheService, [ AuthenticationManager, EntityQueryCacheService ], 'EntityCacheService'),
                instance =>
                    instance.initializeStore());
        this.welcomeStore.onUpdate(
            () =>
                this.entityCacheService.clear());

        // Setup entity type store
        this.entityTypeStore = new EntityTypeStore();
        this.dataObjectStore.registerType(
            new DataObjectEntityTypeType(this.entityTypeStore));
        this.moduleManager.define(
            new Module(this.entityTypeStore, [ WelcomeStore, ComputationTypeStore ], 'EntityTypeStore'),
            instance =>
                instance.initialize());
        this.welcomeStore.onUpdate(
            () =>
                this.entityTypeStore.initialize());

        // Setup localization store
        this.localizationStore = new LocalizationStore();
        this.moduleManager.define(
            new Module(this.localizationStore, [ AuthenticationManager, EntityTypeStore ], 'LocalizationStore'),
            instance =>
                instance.initializeStore());
        this.welcomeStore.onUpdate(
            () =>
                this.localizationStore.reinitializeStore());
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    isAdmin()
    {
        return loadModuleDirectly(CurrentUserStore).isAllowedInConfiguration;
    }

    isSupport()
    {
        return loadModuleDirectly(CurrentUserStore).isSupport
    }
}
