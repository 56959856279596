import { EntityPath } from '../Path/@Model/EntityPath';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { observable } from 'mobx';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { ContextVariable } from './ContextVariable';
import ParameterDictionary from '../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../@Api/Automation/Value/Type/EntityValueType';
import PrimitiveValueType from '../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../DataObject/Model/DataObject';
import CollectionType from '../../../../@Api/Automation/Value/Type/CollectionType';
import CollectionValue from '../../../../@Api/Automation/Value/CollectionValue';
import EntityValue from '../../../../@Api/Automation/Value/EntityValue';
import ParameterAssignment from '../../../../@Api/Automation/Parameter/ParameterAssignment';
import FunctionContext from '../../../../@Api/Automation/Function/FunctionContext';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';

export class EntityContext
{
    // ------------------------- Properties -------------------------

    alias: string;
    entities: Entity[];
    entityPath: EntityPath;
    contextByParameter: Map<string, EntityContext>;
    variables: ContextVariable[];

    // ------------------------ Constructor -------------------------

    constructor(entities: Entity[],
                entityPath: EntityPath,
                contextByParameter: Map<string, EntityContext> = new Map(),
                variables: ContextVariable[] = [],
                alias?: string)
    {
        this.entities = entities;
        this.entityPath = entityPath;
        this.contextByParameter = contextByParameter;
        this.variables = variables;
        this.alias = alias;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get entity(): Entity
    {
        if (this.entities.length > 0)
        {
            return this.entities[0];
        }
        else
        {
            return null;
        }
    }

    // -------------------------- Actions ---------------------------

    traverse(
        path: EntityPath,
        parameter?: string,
        commitContext?: CommitContext
    ): EntityContext
    {
        const contextToTraverse = this.getContext(parameter);
        const resultingEntities: Entity[] = [];
        const joinedPath = contextToTraverse.entityPath.join(path);

        contextToTraverse.entities
            .forEach(
                entity =>
                    resultingEntities.push(
                        ...path.traverseEntity(
                            entity,
                            commitContext
                        )
                    )
            );

        const traversedContext =
            new EntityContext(
                resultingEntities,
                joinedPath,
                contextToTraverse.contextByParameter
            );

        if (parameter)
        {
            const traversedContextByParameter = observable.map(this.contextByParameter);

            traversedContextByParameter.set(parameter, traversedContext);

            return new EntityContext(
                this.entities,
                this.entityPath,
                traversedContextByParameter
            );
        }
        else
        {
            return traversedContext;
        }
    }

    // ------------------------ Public logic ------------------------

    static fromEntityType(entityType: EntityType)
    {
        return new EntityContext([], EntityPath.fromEntityType(entityType));
    }

    static fromEntity(entity: Entity)
    {
        return new EntityContext(
            [
                entity
            ],
            EntityPath.root(entity.entityType),
            new Map());
    }

    getContext(parameter?: string): EntityContext
    {
        if (parameter)
        {
            if (this.contextByParameter.has(parameter))
            {
                return this.contextByParameter.get(parameter);
            }
            else
            {
                throw new Error(`missing context for parameter: ${parameter}`);
            }
        }
        else
        {
            return this;
        }
    }

    setContext(parameter: string, context: EntityContext): EntityContext
    {
        if (parameter)
        {
            const contextByParameter = new Map(this.contextByParameter);

            contextByParameter.set(parameter, context);

            return new EntityContext(
                this.entities,
                this.entityPath,
                contextByParameter,
                this.variables
            );
        }
        else
            {
            return context;
        }
    }

    setVariable(variable: ContextVariable)
    {
        if (variable)
        {
            this.variables.push(variable);
        }

        return new EntityContext(
            this.entities,
            this.entityPath,
            this.contextByParameter,
            this.variables
        );
    }

    split(): EntityContext[]
    {
        return this.entities.map(
            entity =>
                new EntityContext(
                    [entity],
                    this.entityPath,
                    this.contextByParameter,
                    this.variables
                )
        );
    }

    toFunctionContext(rootParameterId: string = 'Entity')
    {
        const parameterDictionary = this.toParameterDictionary(rootParameterId);
        const parameterAssignment = this.toParameterAssignment(parameterDictionary, rootParameterId);

        return new FunctionContext(
            parameterDictionary,
            parameterAssignment);
    }

    toParameterDictionary(rootParameterId: string = 'Entity')
    {
        const rootParameter =
            this.entityPath.isPlural
                ?
                    new Parameter(
                        rootParameterId,
                        new CollectionType(new EntityValueType(this.entityPath.entityType)),
                        false,
                        this.entityPath.entityType.getName(true))
                :
                    new Parameter(
                        rootParameterId,
                        new EntityValueType(this.entityPath.entityType),
                        false,
                        this.entityPath.entityType.getName()
                    );

        return new ParameterDictionary(
            [
                rootParameter,
                ...Array.from(this.contextByParameter.entries())
                    .map(
                        ([ parameter, context ]) =>
                            new Parameter(
                                parameter,
                                new EntityValueType(context.entityPath.entityType),
                                false,
                                context.entityPath.entityType.getName()
                            )
                    ),
                ...this.variables.map(
                    variable =>
                        new Parameter(
                            variable.variable,
                            new PrimitiveValueType(DataObject.getTypeById('Text')),
                            false,
                            variable.name
                        )
                )
            ]);
    }

    toParameterAssignment(parameterDictionary: ParameterDictionary,
                          rootParameterId: string = 'Entity')
    {
        const parameterAssignment = new ParameterAssignment();
        const rootValue = this.getRootValue();

        if (rootValue)
        {
            parameterAssignment.setValue(
                parameterDictionary.getParameterById(rootParameterId),
                rootValue);
        }

        this.contextByParameter.forEach(
            (context, parameterId) =>
            {
                const value = context.getRootValue();

                if (value)
                {
                    parameterAssignment.setValue(
                        parameterDictionary.getParameterById(parameterId),
                        value);
                }
            });

        return parameterAssignment;
    }

    getRootValue()
    {
        if (this.entityPath.isPlural)
        {
            return new CollectionValue(
                this.entities.map(
                    entity =>
                        new EntityValue(entity)),
                new EntityValueType(this.entityPath.entityType));
        }
        else if (this.entity)
        {
            return new EntityValue(this.entity);
        }
        else
        {
            return undefined;
        }
    }

    // ----------------------- Private logic ------------------------
}
