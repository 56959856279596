import * as React from 'react';
import { EntityConstructor } from '../EntityConstructor';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { RelatedEntityConstructorStore } from './RelatedEntityConstructorStore';
import { observer } from 'mobx-react';
import { EntityRelationshipConstructor } from '../Relationship/EntityRelationshipConstructor';

@observer
export class RelatedEntityConstructor extends BaseComponent<RelatedEntityConstructorStore>
{
    renderLoader(store: RelatedEntityConstructorStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: RelatedEntityConstructorStore)
    {
        if (store.relationshipConstructorStore)
        {
            return <EntityRelationshipConstructor
                store={store.relationshipConstructorStore}
            />;
        }
        else if (store.constructorStore)
        {
            return <EntityConstructor
                store={store.constructorStore}
            />;
        }
        else
        {
            return null;
        }
    }
}
