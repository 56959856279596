import React from 'react';
import { observer } from 'mobx-react-lite';
import useExpansionGroup from '../Hook/useExpansionGroup';
import ExpansionPanelContainerContext from '../ExpansionPanelContainerContext';
import { Id } from '../../../../Util/Id/Id';

export interface ExpansionGroupProps
{
    expandedItemId?: Id;
    disabled?: boolean;
}

const ExpansionGroup: React.FC<ExpansionGroupProps> =
    props =>
    {
        const group = useExpansionGroup(props.expandedItemId);

        if (props.disabled)
        {
            return <>
                {props.children}
            </>;
        }
        else
        {
            return <ExpansionPanelContainerContext.Provider
                value={group}
            >
                {props.children}
            </ExpansionPanelContainerContext.Provider>;
        }
    };

export default observer(ExpansionGroup);
