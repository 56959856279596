import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { RightSwitch } from '../../RightSwitch/RightSwitch';
import Role, { Permission, Privilege, RoleType } from '../../../Model/Role';
import { getInheritedPermission } from '../../../Api/Permission/getInheritedPermission';
import { getSpecifiedPermission } from '../../../Api/Permission/getSpecifiedPermission';
import { isPermissionDisabled } from '../../../Api/Permission/isPermissionDisabled';
import { isInheritedPermission } from '../../../Api/Permission/isInheritedPermission';
import { runInAction } from 'mobx';
import { setPermission } from '../../../Api/Permission/setPermission';
import { EntityMetadataPermission } from '../../../Model/EntityMetadataPermission';

export interface PrivilegeEditorProps
{
    rolesByType: Map<RoleType, Role>;
    role: Role;
    metadataPermission: EntityMetadataPermission;
    privilege: Privilege;
    isReadOnly: boolean;
}

export const PrivilegeEditor: React.FC<PrivilegeEditorProps> =
    observer(
        ({
             rolesByType,
             role,
             metadataPermission,
             privilege,
             isReadOnly,
         }) =>
        {
            const togglePermission =
                useCallback(
                    (permission: Permission) =>
                        runInAction(
                            () =>
                            {
                                setPermission(
                                    rolesByType,
                                    role,
                                    privilege,
                                    permission,
                                    metadataPermission
                                );
                            }
                        ),
                    [
                        setPermission,
                        rolesByType,
                        role,
                        privilege,
                        metadataPermission,
                    ]);

            const permission =
                useComputed(
                    () =>
                        getSpecifiedPermission(
                            rolesByType,
                            role,
                            privilege,
                            metadataPermission
                        ),
                    [
                        rolesByType,
                        role,
                        privilege,
                        metadataPermission,
                    ]);

            const inheritedPermission =
                useComputed(
                    () =>
                        getInheritedPermission(
                            rolesByType,
                            role,
                            privilege,
                            metadataPermission
                        ),
                    [
                        rolesByType,
                        role,
                        privilege,
                        metadataPermission,
                    ]);

            const isInherited =
                useComputed(
                    () =>
                        isInheritedPermission(
                            role,
                            privilege,
                            metadataPermission,
                        ),
                    [
                        role,
                        privilege,
                        metadataPermission,
                    ]);

            const isDisabled =
                useComputed(
                    () =>
                        isPermissionDisabled(
                            rolesByType,
                            role,
                            privilege,
                            metadataPermission
                        ),
                    [
                        rolesByType,
                        role,
                        privilege,
                        metadataPermission,
                    ]);

            return <RightSwitch
                permission={permission}
                inheritedPermission={inheritedPermission}
                onChange={togglePermission}
                canRightBeInherited={true}
                isDisabled={isDisabled}
                isInherited={isInherited}
                isReadOnly={isReadOnly}
            />;
        }
    );
