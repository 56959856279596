import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';

export default function getIllegalInstantiableTypes(entityType: EntityType,
                                                    entity?: Entity)
{
    const types = loadModuleDirectly(EntityTypeStore).bespoke.types;

    const illegalTypes = new Set<EntityType>();
    // illegalTypes.add(types.Relationship.Person.Contact.Standard.Type);

    if (entityType.isA(types.Relationship.Person.Contact.Type))
    {
        if (entity)
        {
            const isRelatedToRoot =
                entity.getRelationshipsByDefinition(
                    true,
                    types.Relation.RelationshipDefinition.Relationships)
                    .some(
                        relationship =>
                            relationship.getEntity(true).getObjectValueByField(
                                types.Entity.Field.IsRoot));

            if (isRelatedToRoot)
            {
                illegalTypes.add(types.Relationship.Person.Contact.Standard.Type);
            }
            else
            {
                types.Relationship.Person.Contact.Employee.Type.getAllTypes(false, true)
                    .forEach(
                        type =>
                            illegalTypes.add(type));
            }
        }
    }
    else if (entityType.isA(types.Relation.Type))
    {
        illegalTypes.add(types.Relation.Organization.Environment.Type);
        illegalTypes.add(types.Relation.Person.Account.Type);
    }
    else if (entityType.isA(types.Relationship.Organization.Type))
    {
        illegalTypes.add(types.Relationship.Organization.Identity.Type);
    }

    return illegalTypes;
}
