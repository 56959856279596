import Value from './Value';
import CollectionType from './Type/CollectionType';
import ValueType from './Type/ValueType';
import getValueFromDescriptor from '../Api/getValueFromDescriptor';
import AutomationDependencyContext from '../AutomationDependencyContext';
import getValueTypeFromDescriptor from '../Api/getValueTypeFromDescriptor';
import { FileReporter } from '../../../@Component/Domain/DataObject/Model/DataDescriptor';

export default class CollectionValue<T> extends Value<Value<T, ValueType<any>>[], CollectionType<T>>
{
    // ------------------------- Properties -------------------------

    elementType: ValueType<any>;

    // ------------------------ Constructor -------------------------

    constructor(value: Value<T, ValueType<any>>[],
                elementType: ValueType<any>)
    {
        super(value);

        this.elementType = elementType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getId(): string
    {
        return `Collection(${this.elementType.id()}:${this.value.map(element => element.getId()).join(',')})`;
    }

    getType(): CollectionType<any>
    {
        return new CollectionType<any>(this.elementType);
    }

    getName(): string
    {
        return this.value
            .map(
                value =>
                    value.getName())
            .filter(
                value =>
                    value !== '')
            .join(', ');
    }

    equals(otherValue: Value<any, any>)
    {
        return otherValue instanceof CollectionValue
            && this.value.length === otherValue.value.length
            && this.value.every(
                (element, idx) =>
                    element.equals(
                        otherValue.value[idx]));
    }

    clone(): CollectionValue<T>
    {
        return new CollectionValue(
            this.value.map(
                value =>
                    value.clone()),
            this.elementType);
    }

    augmentDescriptor(descriptor,
                      onFile?: FileReporter)
    {
        descriptor.type = 'Collection';
        descriptor.elementType = this.elementType.toDescriptor();
        descriptor.value =
            this.value.map(
                value =>
                    value.toDescriptor(onFile));
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new CollectionValue<any>(
            await Promise.all(
                descriptor.value.map(
                    value =>
                        getValueFromDescriptor(
                            value,
                            dependencyContext))),
            await getValueTypeFromDescriptor(
                descriptor.elementType,
                dependencyContext));
    }

    static getCollection(collection: Value<any, any>): Value<any, any>[]
    {
        if (collection instanceof CollectionValue)
        {
            return collection.value;
        }
        else
        {
            return [
                collection
            ];
        }
    }

    // ----------------------- Private logic ------------------------
}
