import { BaseStore, getOrCompute, PropType } from '../../../@Framework/Store/BaseStore';
import { TextProps, TextStore } from '../Text/TextStore';
import { ViewComponent } from '../ViewStack/Model/ViewComponent';
import { textSecondaryColor } from '../../../@Resource/Theme/Theme';
import { action, computed, observable } from 'mobx';
import { AvatarStore } from '../Avatar/AvatarStore';
import { IconStore } from '../Icon/IconStore';

export type LabelPosition = 'left' | 'top' | 'none' | 'right' | 'placeholder';

export interface InputProps
{
    labelPosition?: PropType<InputStore, InputProps, LabelPosition>;
    labelProps?: PropType<InputStore, InputProps, TextProps>;
    isDense?: PropType<InputStore, InputProps, boolean>;
    lineSpacing?: PropType<InputStore, InputProps, number>;
    isLabelHidden?: PropType<InputStore, InputProps, boolean>;
    view?: PropType<InputStore, InputProps, ViewComponent>;
    avatarStore?: PropType<InputStore, InputProps, AvatarStore>;
    iconStore?: PropType<InputStore, InputProps, IconStore>;
    isHighlighted?: PropType<InputStore, InputProps, boolean>;
    margin?: PropType<InputStore, InputProps, string>;
    isHidden?: PropType<InputStore, InputProps, boolean>;
}

export class InputStore<P extends InputProps = InputProps> extends BaseStore<P>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable isHovering: boolean = false;

    // ------------------------ Constructor -------------------------

    constructor(props: P, defaultProps?: P)
    {
        super(props, defaultProps as any);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get labelProps(): TextProps
    {
        return getOrCompute(this, this.props.labelProps);
    }

    @computed
    get view(): ViewComponent
    {
        return getOrCompute(this, this.props.view);
    }

    @computed
    get labelPosition(): LabelPosition
    {
        return getOrCompute(this, this.props.labelPosition);
    }

    @computed
    get lineSpacing(): number
    {
        return getOrCompute(this, this.props.lineSpacing);
    }

    @computed
    get isLabelLeft(): boolean
    {
        return this.labelPosition === 'left';
    }

    @computed
    get isLabelRight(): boolean
    {
        return this.labelPosition === 'right';
    }

    @computed
    get isLabelTop(): boolean
    {
        return this.labelPosition === 'top';
    }

    @computed
    get margin(): string
    {
        return getOrCompute(this, this.props.margin);
    }

    @computed
    get showLabel(): boolean
    {
        return this.labelPosition !== 'none'
            && !this.iconStore
            && !this.isLabelHidden;
    }

    @computed
    get isLabelHidden(): boolean
    {
        return getOrCompute(this, this.props.isLabelHidden);
    }

    @computed
    get isDense(): boolean
    {
        return getOrCompute(this, this.props.isDense);
    }

    @computed
    get isHighlighted(): boolean
    {
        return getOrCompute(this, this.props.isHighlighted);
    }

    @computed
    get helperLabel(): TextStore
    {
        return undefined;
    }

    @computed
    get isHidden(): boolean
    {
        return getOrCompute(this, this.props.isHidden);
    }

    // --------------------------- Stores ---------------------------

    @computed
    get labelStore(): TextStore
    {
        return new TextStore(
            {
                color:
                    () =>
                        this.isHovering
                            ?
                                'primary'
                            :
                                textSecondaryColor,
                variant: 'label',
                isLowercase:
                    () =>
                        this.isLabelTop
                            ?
                                true
                            :
                                false,
                style: { lineHeight: 1 },
                ...this.labelProps
            });
    }

    @computed
    get avatarStore(): AvatarStore
    {
        return getOrCompute(this, this.props.avatarStore);
    }

    @computed
    get iconStore(): IconStore
    {
        return getOrCompute(this, this.props.iconStore);
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    handleMouseEnter(event: any)
    {
        // $(event.currentTarget).one('mouseleave', (event) => this.handleMouseLeave());

        this.isHovering = true;
    }

    @action.bound
    handleMouseLeave()
    {
        this.isHovering = false;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
