import 'reflect-metadata';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isDate from 'lodash/isDate';
import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';
import keys from 'lodash/keys';

type Serialize<T> = (element: T) => any;
type Deserialize<T> = (descriptor: any) => T;
type RefCallback<T> = (element: T) => void;

const typeProperty = '@type';
const idProperty = '@id';
const refProperty = '@ref';
const proxyProperty = '_proxy';
const referenceMetadataKey = Symbol('reference');
const enumeratedMetadataKey = Symbol('enumerated');
const symbolTable = new Map<string, Newable<any>>();
const hibernateProxyMarker = '$HibernateProxy$';

export function registerType<T>(type: Newable<T>,
                                name: string = type.name)
{
    symbolTable.set(name, type);
}

export function type<T>(name?: string,
                        serialize?: Serialize<T>,
                        deserialize?: Deserialize<T>)
{
    return (constructor: Function) =>
    {
        symbolTable.set(name || constructor.name, (constructor as any) as Newable<any>);
    };
}

export function reference(type: Newable<any>, typeId?: any, isCollection?: boolean)
{
    return Reflect.metadata(referenceMetadataKey, { type: type, typeId: typeId, isCollection: isCollection });
}

export function enumerated(type: any, typeId?: string)
{
    return Reflect.metadata(enumeratedMetadataKey, { type: type, typeId: typeId });
}

export type Newable<T> = new (...args: any[]) => T;

export function toJson<T>(target: any, type?: Newable<T>)
{
    let instance: any = {};

    if (isArray(target))
    {
        let elements: any[] = [];

        for (let element of target)
        {
            elements.push(toJson(element, type));
        }

        return elements;
    }
    if (isBoolean(target))
    {
        return target;
    }
    else if (isString(target))
    {
        return target;
    }
    else if (isDate(target))
    {
        return (target as Date).getTime();
    }
    else if (isObject(target))
    {
        instance[typeProperty] = target.constructor.name;

        keys(target)
            .forEach(key =>
            {
                let enumeratedMetadata = Reflect.getMetadata(enumeratedMetadataKey, instance, key);
                let referenceMetadata = Reflect.getMetadata(referenceMetadataKey, instance, key);

                if (enumeratedMetadata)
                {
                    instance[key] = fromEnum(enumeratedMetadata.type, (target as any)[key]);
                }
                else if (referenceMetadata)
                {
                    instance[key] = toJson((target as any)[key], referenceMetadata.type);
                }
                else
                {
                    instance[key] = toJson((target as any)[key]);
                }
            });
    }

    return instance;
}

const _isTypeNameHibernateProxy = new Map<string, boolean>();

function isTypeNameAHibernateProxy(typeName?: string)
{
    if (typeName === undefined)
    {
        return false;
    }

    const cachedResult = _isTypeNameHibernateProxy.get(typeName);

    if (cachedResult === undefined)
    {
        const result = typeName.includes(hibernateProxyMarker);
        _isTypeNameHibernateProxy.set(typeName, result);

        return result;
    }
    else
    {
        return cachedResult;
    }
}

const metadataRepoByType = new Map<string, Map<Symbol, Map<string, any>>>();

function getReflectMetadataRepoByType(typeName: string)
{
    const cachedRepo = metadataRepoByType.get(typeName);

    if (cachedRepo === undefined)
    {
        const repo = new Map<Symbol, Map<string, any>>();
        repo.set(referenceMetadataKey, new Map());
        repo.set(enumeratedMetadataKey, new Map());

        metadataRepoByType.set(
            typeName,
            repo);

        return repo;
    }
    else
    {
        return cachedRepo;
    }
}

function getReflectMetadata(metadataBySymbol: Map<Symbol, Map<string, any>>,
                            symbol: Symbol,
                            typeName: string,
                            key: string,
                            instance: any)
{
    // if (true)
    //     return Reflect.getMetadata(symbol, instance, key);

    const metadataByKey = metadataBySymbol.get(symbol);
    const cachedMetadata = metadataByKey.get(key);

    if (cachedMetadata === false)
    {
        return undefined;
    }
    else if (cachedMetadata)
    {
        return cachedMetadata;
    }
    else
    {
        const metadata = Reflect.getMetadata(symbol, instance, key);

        if (metadata === undefined)
        {
            metadataByKey.set(key, false);
        }
        else
        {
            metadataByKey.set(key, metadata);
        }

        return metadata;
    }
}

export function fromJsonAsArray<T>(source: T[],
                                   type?: Newable<T>,
                                   objectById: Map<string, any> = new Map<string, any>(),
                                   refCallbacksById: Map<string, Array<RefCallback<any>>> = new Map<string, Array<RefCallback<any>>>(),
                                   refCallback?: RefCallback<T>,
                                   isCollection?: boolean,
                                   objectBySource: Map<any, any> = new Map<any, any>())
{
    const elements =
        source.map(
            (element, idx) =>
                fromJson(
                    element,
                    type,
                    objectById,
                    refCallbacksById,
                    refElement =>
                    {
                        if (elements)
                        {
                            elements[idx] = refElement
                        }
                    },
                    undefined,
                    objectBySource));

    return elements;
}

export function fromJsonAsMap<T>(source: any,
                                 type?: Newable<T>,
                                 objectById: Map<string, any> = new Map<string, any>(),
                                 refCallbacksById: Map<string, Array<RefCallback<any>>> = new Map<string, Array<RefCallback<any>>>(),
                                 refCallback?: RefCallback<T>,
                                 isCollection?: boolean,
                                 objectBySource: Map<any, any> = new Map<any, any>())
{
    const elements =
        new Map(
            Object.keys(source)
                .map(
                    key => [
                        key,
                        fromJson(
                            source[key],
                            type,
                            objectById,
                            refCallbacksById,
                            refElement =>
                            {
                                if (elements)
                                {
                                    elements.set(key, refElement)
                                }
                            },
                            undefined,
                            objectBySource)
                    ]));

    return elements as any;
}

export function fromJsonAsObject<T>(source: any,
                                    type?: Newable<T>,
                                    objectById: Map<string, any> = new Map<string, any>(),
                                    refCallbacksById: Map<string, Array<RefCallback<any>>> = new Map<string, Array<RefCallback<any>>>(),
                                    refCallback?: RefCallback<T>,
                                    isCollection?: boolean,
                                    objectBySource: Map<any, any> = new Map<any, any>()): T
{
    if ((source as any)[proxyProperty])
    {
        return (source as any);
    }

    if (objectBySource.has(source))
    {
        return objectBySource.get(source);
    }

    if ((source as any)[refProperty] !== undefined)
    {
        const ref = (source as any)[refProperty];

        if (objectById.has(ref))
        {
            return objectById.get((source as any)[refProperty]);
        }
        else
        {
            if (!refCallbacksById.has(ref))
            {
                refCallbacksById.set(ref, []);
            }

            refCallbacksById.get(ref)
                .push(refCallback);
        }

        return (source as any);
    }

    let instance: any;
    let typeName = (source as any)[typeProperty] as string;

    if (isTypeNameAHibernateProxy(typeName))
    {
        typeName = typeName.split(hibernateProxyMarker)[0];

        // TODO [DD]: a lot of proxies are returned from the API, this should not be the case
    }

    if (typeName && symbolTable.has(typeName))
    {
        const constructor = symbolTable.get(typeName);

        // Object.setPrototypeOf(source, constructor);
        instance = new constructor();
    }

    if (!instance && type)
    {
        // Object.setPrototypeOf(source, type);
        instance = new type();
    }

    if (!instance)
    {
        // if (typeName)
        //     consoleLog('no type found for', typeName, instance);

        instance = {};
    }

    objectBySource.set(source, instance);

    // TODO [DD]: the code seems to work without this extendObservable, I suspect a performance increase
    // Instance should be observable, otherwise references won't get set properly
    // Maybe decorate is a better alternative? See extendObservable vs decorator (decorator manipulates the prototype?)
    // instance = extendObservable(instance, {}, {}, { deep: false }); // observable(instance);

    // const t3 = new Date().getTime();
    if ((source as any)[idProperty] !== undefined)
    {
        const id = (source as any)[idProperty];

        objectById.set(id, instance);

        if (refCallbacksById.has(id))
        {
            const refCallbacks = refCallbacksById.get(id);

            refCallbacks
                .forEach(
                    callback =>
                        callback(instance));

            refCallbacks.splice(0, refCallbacks.length);
        }
    }

    const metadataRepo = getReflectMetadataRepoByType(typeName);

    Object.keys(source)
        .forEach(key =>
        {
            const referenceMetadata =
                getReflectMetadata(
                    metadataRepo,
                    referenceMetadataKey,
                    typeName,
                    key,
                    instance);

            const refCallback: RefCallback<any> =
                refElement =>
                {
                    instance[key] = refElement;
                };

            if (referenceMetadata)
            {
                let referenceType: Newable<any>;

                if (referenceMetadata.type)
                {
                    referenceType = referenceMetadata.type;
                }
                else if (referenceMetadata.typeId)
                {
                    referenceType = symbolTable.get(referenceMetadata.typeId);
                }

                instance[key] =
                    fromJson(
                        (source as any)[key],
                        referenceType,
                        objectById,
                        refCallbacksById,
                        refCallback,
                        referenceMetadata.isCollection,
                        objectBySource);
            }
            else
            {
                const enumeratedMetadata =
                    getReflectMetadata(
                        metadataRepo,
                        enumeratedMetadataKey,
                        typeName,
                        key,
                        instance);

                if (enumeratedMetadata)
                {
                    instance[key] = toEnum(enumeratedMetadata.type, (source as any)[key]);
                }
                else
                {
                    instance[key] =
                        fromJson(
                            (source as any)[key],
                            undefined,
                            objectById,
                            refCallbacksById,
                            refCallback,
                            undefined,
                            objectBySource);
                }
            }
        });

    return instance;
}

export function fromJson<T>(source: any,
                            type?: Newable<T>,
                            objectById: Map<string, any> = new Map<string, any>(),
                            refCallbacksById: Map<string, Array<RefCallback<any>>> = new Map<string, Array<RefCallback<any>>>(),
                            refCallback?: RefCallback<T>,
                            isCollection?: boolean,
                            objectBySource: Map<any, any> = new Map<any, any>()): any
{
    if (isArray(source))
    {
        return fromJsonAsArray(
            source,
            type,
            objectById,
            refCallbacksById,
            refCallback,
            isCollection,
            objectBySource) as any;
    }
    else if (isObject(source) && isCollection)
    {
        return fromJsonAsMap(
            source,
            type,
            objectById,
            refCallbacksById,
            refCallback,
            isCollection,
            objectBySource);
    }
    else if (isObject(source))
    {
        return fromJsonAsObject(
            source,
            type,
            objectById,
            refCallbacksById,
            refCallback,
            isCollection,
            objectBySource);
    }
    else
    {
        return source;
    }
}

export function fromEnum(type: any, key: number): string
{
    if (key)
    {
        return type[key];
    }
    else
    {
        return undefined;
    }
}

export function toEnum(type: any, value: string): any
{
    if (value)
    {
        let _keys = Object.keys(type);

        if (_keys.length === 0 || _keys.length % 2 > 0)
        {
            return undefined;
        }
        else
        {
            for (let i = 0; i < _keys.length / 2; i++)
            {
                if (type[i] === value)
                {
                    return i;
                }
            }

            return undefined;
        }
    }
    else
    {
        return undefined;
    }
}

// https://stackoverflow.com/questions/42006725/is-there-any-way-to-check-if-an-object-is-a-type-of-enum-in-typescript
export function isEnum(instance: any): boolean
{
    let keys = Object.keys(instance);
    let values = [];

    for (let key of keys)
    {
        let value = instance[key];

        if (typeof value === 'number')
        {
            value = value.toString();
        }

        values.push(value);
    }

    for (let key of keys)
    {
        if (values.indexOf(key) < 0)
        {
            return false;
        }
    }

    return true;
}
