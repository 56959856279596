import { Entity } from '../../../Model/Implementation/Entity';
import { useMemo } from 'react';
import useTypes from '../../../../@Component/Domain/Entity/Type/Api/useTypes';
import useResult from '../../../../@Component/Domain/Entity/Selection/Hooks/useResult';

export default function useEntityByExternalId(service: string,
                                              type: string,
                                              id?: string): [ Entity, boolean ]
{
    const types = useTypes();
    const [ externalId, isLoading ] =
        useResult(
            id && types.ExternalId.Type,
            (builder, rootPath) =>
                builder
                    .join(
                        rootPath.joinTo(
                            types.Entity.RelationshipDefinition.ExternalIds,
                            true))
                    .where(
                        cb =>
                            cb.eq(
                                rootPath.field(types.ExternalId.Field.Service),
                                undefined,
                                service))
                    .where(
                        cb =>
                            cb.eq(
                                rootPath.field(types.ExternalId.Field.Type),
                                undefined,
                                type))
                    .where(
                        cb =>
                            cb.eq(
                                rootPath.field(types.ExternalId.Field.Id),
                                undefined,
                                id)),
            [
                types,
                service,
                type,
                id
            ]);

    return useMemo(
        () => [
            externalId?.getRelatedEntityByDefinition(
                true,
                types.Entity.RelationshipDefinition.ExternalIds),
            isLoading
        ],
        [
            externalId,
            types,
            isLoading
        ]);
}
