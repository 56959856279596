import React, { useCallback, useMemo } from 'react';
import useApiClient from '../../../../../../../../@Service/ApiClient/Hooks/useApiClient';
import { ApiRequest, Method } from '../../../../../../../../@Service/ApiClient/Model/ApiRequest';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import { WebhookEvent } from '../Model/WebhookEvent';
import moment from 'moment';
import PrimaryTextButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryTextButton/PrimaryTextButton';

export interface WebhookEventRowProps
{
    event: WebhookEvent;
    onSave: (event: WebhookEvent) => void;
}

export const WebhookEventRow: React.FC<WebhookEventRowProps> =
    ({
        event,
        onSave,
     }) =>
    {
        const creationDate =
            useMemo(
                () =>
                    moment(event.creationDate).format('LL LTS'),
                [
                    event
                ]);
        const lastAttemptDate =
            useMemo(
                () =>
                    moment(event.lastAttemptDate).format('LL LTS'),
                [
                    event
                ]);
        const nextAttemptDate =
            useMemo(
                () =>
                    moment(event.nextAttemptDate).format('LL LT'),
                [
                    event
                ]);
        const expirationDate =
            useMemo(
                () =>
                    event.deletionDate &&
                        moment(event.deletionDate).format('LL LTS'),
                [
                    event
                ]);
        const apiClient = useApiClient();
        const retry =
            useCallback(
                async () =>
                {
                    const savedEvent =
                        await apiClient
                            .request<WebhookEvent>(
                                new ApiRequest<any>(
                                    `/v1/webhooks/${event.webhookId}/events/${event.id}`,
                                    Method.Post)
                            );

                    onSave(savedEvent);
                },
                [
                    apiClient,
                    event,
                    onSave
                ]);

        return <TableRow>
            <TableCell>
                {creationDate}
            </TableCell>
            <TableCell>
                {event.url}
            </TableCell>
            <TableCell>
                {event.state}
            </TableCell>
            <TableCell>
                {event.numberOfAttempts}
            </TableCell>
            <TableCell>
                {lastAttemptDate}
            </TableCell>
            <TableCell>
                {event.state === 'Attempting' && nextAttemptDate}
            </TableCell>
            <TableCell>
                {expirationDate}
            </TableCell>
            <TableCell
                align="right"
            >
                <PrimaryTextButton
                    label={
                        <LocalizedText
                            code="Generic.Retry"
                            value="Opnieuw proberen"
                        />
                    }
                    onClick={retry}
                />
            </TableCell>
        </TableRow>;
    };
