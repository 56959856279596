import { BaseType } from '../../../../@Framework/Store/@Model/BaseType';
import { ComponentClass } from 'react';
import { ComputationEditorStore } from '../ComputationEditorStore';
import { ComputationSpecification } from './ComputationSpecification';
import { ComputationContext } from '../ComputationContext';
import { DataObjectSpecification } from '../../DataObject/Model/DataObjectSpecification';
import { DataObject } from '../../DataObject/Model/DataObject';
import { EntityFieldPath } from '../../Entity/Path/@Model/EntityFieldPath';
import { EntityContext } from '../../Entity/@Model/EntityContext';
import { Computation } from './Computation';

export abstract class ComputationType<T extends ComputationEditorStore = ComputationEditorStore, U extends ComputationSpecification = ComputationSpecification, M extends Computation = Computation> implements BaseType
{
    abstract id(): string;

    abstract name(): string;

    abstract allow(context: ComputationContext): boolean;

    abstract isTerminal(): boolean;

    abstract isResource(): boolean;

    abstract fromSpecification(specification: U): M;

    abstract toSpecification(computation: M): U;

    abstract compute(context: ComputationContext,
                     computation: M): DataObject;

    abstract description(context: ComputationContext,
                         specification: U): string;

    abstract editorStore(context: ComputationContext,
                         specification: U): T;

    abstract editorView(): ComponentClass<any>;

    abstract editorSpecification(store: T): U;

    abstract editorResultSpecification(store: T): DataObjectSpecification;

    abstract entityFieldPaths(specification: U,
                              context: EntityContext,
                              parameter?: string): EntityFieldPath[];
}
