import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import CurrentUserContext from '../../User/CurrentUserContext';
import Centered from '../../../../@Future/Component/Generic/Centered/Centered';
import styles from './AdministratorRightsRequired.module.scss';

export interface AdministratorRightsRequiredProps
{
    disabled?: boolean;
}

const AdministratorRightsRequired: React.FC<AdministratorRightsRequiredProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);

        if (currentUserStore.isAdministrator || props.disabled)
        {
            return <>{props.children}</>;
        }
        else
        {
            return <div
                className={styles.root}
            >
                <Centered
                    horizontal
                >
                    <LocalizedText
                        code="Generic.AdministratorRightsRequired"
                        value="Deze functionaliteit is alleen beschikbaar voor beheerders."
                    />
                </Centered>
            </div>;
        }
    };

export default observer(AdministratorRightsRequired);
