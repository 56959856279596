import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityRelationshipDefinition } from '../../../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import useAsyncResult from '../../../../../../../../../@Util/Async/useAsyncResult';
import FunctionContext from '../../../../../../../../../@Api/Automation/Function/FunctionContext';
import Centered from '../../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../../@Future/Component/Generic/Loader/Loader';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import PredicateEditor from '../../../../../../../Entity/Viewer/Content/Automation/Editor/Predicate/PredicateEditor';
import RightAlignedButtonGroup from '../../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import ComparisonPredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../../../../../../DataObject/Model/Comparator';
import CancelButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import DeleteButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';
import { RelatedEntityParameterId } from '../../../../../../../../../@Api/Metadata/RelationshipDefinition/Filter/RelationshipDefinitionFilterConstants';
import setRelationshipDefinitionFilter from '../../../../../../../../../@Api/Metadata/RelationshipDefinition/Filter/setRelationshipDefinitionFilter';
import getRelationshipDefinitionFilter from '../../../../../../../../../@Api/Metadata/RelationshipDefinition/Filter/getRelationshipDefinitionFilter';
import getRelationshipDefinitionFilterParameterDictionary from '../../../../../../../../../@Api/Metadata/RelationshipDefinition/Filter/getRelationshipDefinitionFilterParameterDictionary';
import CompositePredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../../../../DataObject/Model/LogicalOperator';
import { EntityType } from '../../../../../../../../../@Api/Model/Implementation/EntityType';

export interface RelationshipDefinitionFilterEditorProps
{
    entityType: EntityType;
    relationshipDefinition: EntityRelationshipDefinition;
    parent: boolean;
    onClose: () => void;
}

const RelationshipDefinitionFilterEditor: React.FC<RelationshipDefinitionFilterEditorProps> =
    props =>
    {
        const { entityType, relationshipDefinition, parent, onClose } = props;

        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        getRelationshipDefinitionFilterParameterDictionary(
                            relationshipDefinition,
                            parent)),
                [
                    relationshipDefinition,
                    parent
                ]);
        const [ filterWithContext, isLoading ] =
            useAsyncResult(
                async () =>
                    getRelationshipDefinitionFilter(
                        entityType,
                        relationshipDefinition,
                        parent),
                [
                    relationshipDefinition,
                    parent
                ]);
        const filter =
            useMemo(
                () =>
                    filterWithContext?.filter
                        ? filterWithContext?.filter
                        : new CompositePredicate(LogicalOperator.And, []),
                [
                    filterWithContext
                ]);

        const dummyOnChange =
            useCallback(
                () => {},
                []);

        const clear =
            useCallback(
                () =>
                    setRelationshipDefinitionFilter(
                        entityType,
                        relationshipDefinition,
                        parent,
                        undefined)
                        .then(onClose),
                [
                    entityType,
                    relationshipDefinition,
                    parent,
                    undefined,
                    context,
                    onClose
                ]);

        const save =
            useCallback(
                () =>
                    setRelationshipDefinitionFilter(
                        entityType,
                        relationshipDefinition,
                        parent,
                        filter)
                        .then(onClose),
                [
                    entityType,
                    relationshipDefinition,
                    parent,
                    filter,
                    context,
                    onClose
                ]);

        const comparisonConstructor =
            useCallback(
                () =>
                    new ComparisonPredicate(
                        new ValueFromEntityComputation(
                            context.parameterDictionary.getParameterById(RelatedEntityParameterId),
                            undefined),
                        Comparator.Equals,
                        undefined),
                [
                    context
                ]);

        if (isLoading)
        {
            return <Centered
                horizontal
            >
                <Loader />
            </Centered>;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <PredicateEditor
                        value={filter}
                        onChange={dummyOnChange}
                        context={context}
                        comparisonConstructor={comparisonConstructor}
                        comparisonLhsTypeFixed
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <RightAlignedButtonGroup>
                        <CancelButton
                            onClick={props.onClose}
                        />
                        <DeleteButton
                            onClick={clear}
                        />
                        <SaveButton
                            onClick={save}
                        />
                    </RightAlignedButtonGroup>
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

export default observer(RelationshipDefinitionFilterEditor);
