import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

interface LoaderProperties
{
    inline?: boolean;
    center?: boolean;
    padding?: boolean;
}

interface LoaderState
{

}

export class Loader extends React.Component<LoaderProperties, LoaderState>
{
    render()
    {
        let loader = <CircularProgress />;

        const inline = this.props.inline == null ? true : this.props.inline;
        const center = this.props.center == null ? true : this.props.center;
        const padding = this.props.padding == null ? true : this.props.padding;

        if (inline)
        {
            if (center)
            {
                return <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: padding ? 15 : 0
                    }}
                >
                    {loader}
                </div>;
            }
            else
            {
                return loader;
            }
        }
        else
        {
            return <Grid container justify="center" alignItems="center" style={{ position: 'absolute', height: '100%' }}>
                <Grid item>
                    {loader}
                </Grid>
            </Grid>;
        }
    }
}
