import { BaseStore, CallbackType, getOrCompute, PropType } from '../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { PropTypes } from '@material-ui/core';
import { StoreState } from '../../../@Framework/Store/@Model/StoreState';
import { ViewComponent } from '../ViewStack/Model/ViewComponent';
import { CSSProperties } from 'react';
import { DrawerStore } from '../Drawer/DrawerStore';
import { injectWithQualifier } from '../../../@Util/DependencyInjection/index';
import { AvatarStore } from '../Avatar/AvatarStore';
import { TextStore } from '../Text/TextStore';
import { IconStore } from '../Icon/IconStore';
import { IconProps } from '../../../@Future/Component/Generic/Icon/Icon';

type ButtonColor = PropTypes.Color | string;

export type ButtonSize = 'extrasmall' | 'small' | 'medium';
export type IconPosition = 'left' | 'right' | 'top';

export interface ButtonProps
{
    id?: PropType<ButtonStore, ButtonProps, string>;
    size?: PropType<ButtonStore, ButtonProps, ButtonSize>;
    label?: PropType<ButtonStore, ButtonProps, string | TextStore>;
    description?: PropType<ButtonStore, ButtonProps, TextStore>;
    tooltip?: PropType<ButtonStore, ButtonProps, string>;
    onClick?: CallbackType<ButtonStore, ButtonProps, Promise<any> | any>;
    isRaised?: PropType<ButtonStore, ButtonProps, boolean>;
    isToggled?: PropType<ButtonStore, ButtonProps, boolean>;
    color?: PropType<ButtonStore, ButtonProps, ButtonColor>,
    highlightColor?: PropType<ButtonStore, ButtonProps, ButtonColor>,
    backgroundColor?: PropType<ButtonStore, ButtonProps, string>,
    backgroundColorOutlineHover?: PropType<ButtonStore, ButtonProps, string>,
    avatar?: PropType<ButtonStore, ButtonProps, AvatarStore>;
    icon?: PropType<ButtonStore, ButtonProps, string>;
    iconSize?: PropType<ButtonStore, ButtonProps, number>;
    iconColor?: PropType<ButtonStore, ButtonProps, string>;
    iconButtonSize?: PropType<ButtonStore, ButtonProps, number>;
    iconPosition?: PropType<ButtonStore, ButtonProps, IconPosition>;
    iconProps?: PropType<ButtonStore, ButtonProps, IconProps>;
    isIconSeparate?: PropType<ButtonStore, ButtonProps, boolean>;
    roundedIcon?: PropType<ButtonStore, ButtonProps, boolean>;
    isDisabled?: PropType<ButtonStore, ButtonProps, boolean>;
    isLoading?: PropType<ButtonStore, ButtonProps, boolean>;
    isVisible?: PropType<ButtonStore, ButtonProps, boolean>;
    isHidden?: PropType<ButtonStore, ButtonProps, boolean>;
    renderAsChip?: PropType<ButtonStore, ButtonProps, boolean>;
    renderAsToggle?: PropType<ButtonStore, ButtonProps, boolean>;
    isHighlighted?: PropType<ButtonStore, ButtonProps, boolean>;
    disableTab?: PropType<ButtonStore, ButtonProps, boolean>;
    stopPropagation?: PropType<ButtonStore, ButtonProps, boolean>;
    fullWidth?: PropType<ButtonStore, ButtonProps, boolean>;
    style?: PropType<ButtonStore, ButtonProps, CSSProperties>;
    badgeContent?: PropType<ButtonStore, ButtonProps, string>;
    isDotBadge?: PropType<ButtonStore, ButtonProps, boolean>;
    outlined?: PropType<ButtonStore, ButtonProps, boolean>;
    view?: PropType<ButtonStore, ButtonProps, ViewComponent>;
    viewOnly?: PropType<ButtonStore, ButtonProps, boolean>;
    disableRipple?: PropType<ButtonStore, ButtonProps, boolean>;
}

const defaultProps: Partial<ButtonProps> =
{
    size: 'medium',
    isLoading: false,
    isDisabled: false,
    roundedIcon: false,
    color:
        store =>
            store.isIconButton && store.popperView != null
                ?
                    'primary'
                :
                    'default',
    highlightColor: undefined,
    isRaised: false,
    isVisible: true,
    isHidden: false,
    disableTab: false,
    stopPropagation: true,
    isHighlighted: false,
    disableRipple: false,
    iconSize:
        store =>
            store.size === 'extrasmall'
                ?
                    7
                :
                    store.size === 'small'
                        ?
                            13
                        :
                            store.size === 'medium'
                                ?
                                    16
                                :
                                    18,
    iconColor:
        store =>
            store.isFinallyOutlined
                ?
                    store.color
                :
                    store.isFilled
                        ?
                            'white'
                        :
                            undefined,
    iconButtonSize:
        store =>
            store.iconSize * (30 / 18),
    fullWidth: false,
    iconPosition: 'right',
    outlined: false,
    isDotBadge: false
};

// Importing placement from popper.js gave a compile warning, saying that it does not exist
export type Placement =
    'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';

export class ButtonStore<P extends ButtonProps = ButtonProps> extends BaseStore<P>
{
    static Colors = new Set<string>(['inherit', 'primary', 'secondary', 'default']);

    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('LeftDrawerStore') leftDrawerStore: DrawerStore;
    @injectWithQualifier('RightDrawerStore') rightDrawerStore: DrawerStore;

    // ------------------------- Properties -------------------------

    @observable popperView: ViewComponent;
    @observable popperViewPlacement: Placement;
    @observable popperViewOffset: number;
    @observable popperCloseOnClickAway: boolean;
    @observable popperNoPaper: boolean;
    @observable isMouseOver: boolean = false;
    @observable isToggled: boolean;

    // ------------------------ Constructor -------------------------

    constructor(props: P, givenDefaultProps?: any)
    {
        super(props,  { ...defaultProps, ...givenDefaultProps});

        this.isToggled = getOrCompute(this, this.props.isToggled);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get roundedIcon(): boolean
    {
        return getOrCompute(this, this.props.roundedIcon);
    }

    @computed
    get isIconButton(): boolean
    {
        return (this.icon || this.iconProps) && !this.isChipButton && !this.isToggleButton && (!this.labelTextStore || (this.labelTextStore && this.isIconSeparate));
    }

    @computed
    get isAvatarButton(): boolean
    {
        return this.avatar && !this.labelTextStore && !this.isChipButton && !this.isToggleButton;
    }

    @computed
    get isChipButton(): boolean
    {
        return this.renderAsChip && !this.isToggleButton;
    }

    @computed
    get isToggleButton(): boolean
    {
        return this.renderAsToggle;
    }

    @computed
    get isLabelButton(): boolean
    {
        if (this.isIconButton)
        {
            return false;
        }
        else if (this.isAvatarButton)
        {
            return false;
        }
        else if (this.isChipButton)
        {
            return false;
        }
        else if (this.isToggleButton)
        {
            return false;
        }
        else
        {
            return true;
        }
    }

    @computed
    get renderAsChip(): boolean
    {
        return getOrCompute(this, this.props.renderAsChip);
    }

    @computed
    get renderAsToggle(): boolean
    {
        return getOrCompute(this, this.props.renderAsToggle);
    }

    @computed
    get iconProps(): IconProps
    {
        return getOrCompute(this, this.props.iconProps);
    }

    @computed
    get hasBadge(): boolean
    {
        return this.badgeContent && this.badgeContent.length > 0;
    }

    @computed
    get badgeContent(): string
    {
        return getOrCompute(this, this.props.badgeContent);
    }

    @computed
    get backgroundColorOutlineHover(): string
    {
        return getOrCompute(this, this.props.backgroundColorOutlineHover);
    }

    @computed
    get isDotBadge(): boolean
    {
        return getOrCompute(this, this.props.isDotBadge);
    }

    @computed
    get view(): ViewComponent
    {
        return getOrCompute(this, this.props.view);
    }

    @computed
    get viewOnly(): boolean
    {
        return getOrCompute(this, this.props.viewOnly);
    }

    @computed
    get id(): string
    {
        return getOrCompute(this, this.props.id);
    }

    @computed
    get size(): ButtonSize
    {
        return getOrCompute(this, this.props.size);
    }

    @computed
    get label(): string | TextStore
    {
        return getOrCompute(this, this.props.label);
    }

    @computed
    get description(): TextStore
    {
        return getOrCompute(this, this.props.description);
    }

    @computed
    get tooltip(): string
    {
        if (this.isDisabled)
        {
            return undefined;
        }
        else
        {
            return getOrCompute(this, this.props.tooltip);
        }
    }

    @computed
    get isRaised(): boolean
    {
        return getOrCompute(this, this.props.isRaised);
    }

    @computed
    get outlined(): boolean
    {
        return getOrCompute(this, this.props.outlined);
    }

    @computed
    get color(): ButtonColor
    {
        return getOrCompute(this, this.props.color);
    }

    @computed
    get backgroundColor(): string
    {
        return getOrCompute(this, this.props.backgroundColor);
    }

    @computed
    get highlightColor(): ButtonColor
    {
        return getOrCompute(this, this.props.highlightColor);
    }

    @computed
    get icon(): string
    {
        return getOrCompute(this, this.props.icon);
    }

    @computed
    get avatar(): AvatarStore
    {
        return getOrCompute(this, this.props.avatar);
    }

    @computed
    get iconPosition(): IconPosition
    {
        return getOrCompute(this, this.props.iconPosition);
    }

    @computed
    get isIconSeparate(): boolean
    {
        return getOrCompute(this, this.props.isIconSeparate);
    }

    @computed
    get isDisabled(): boolean
    {
        return this.isLoading || getOrCompute(this, this.props.isDisabled);
    }

    @computed
    get disableRipple(): boolean
    {
        return getOrCompute(this, this.props.disableRipple);
    }

    @computed
    get showLoader(): boolean
    {
        return this.isLoading || getOrCompute(this, this.props.isLoading);
    }

    @computed
    get style(): CSSProperties
    {
        return getOrCompute(this, this.props.style);
    }

    @computed
    get isVisible(): boolean
    {
        return this.showLoader || getOrCompute(this, this.props.isVisible);
    }

    @computed
    get isHidden(): boolean
    {
        return this.showLoader || getOrCompute(this, this.props.isHidden);
    }

    @computed
    get isHighlighted(): boolean
    {
        return this.showLoader || getOrCompute(this, this.props.isHighlighted);
    }

    @computed
    get isDisableTab(): boolean
    {
        return getOrCompute(this, this.props.disableTab);
    }

    @computed
    get stopPropagation(): boolean
    {
        return getOrCompute(this, this.props.stopPropagation);
    }

    @computed
    get iconSize(): number
    {
        return getOrCompute(this, this.props.iconSize);
    }

    @computed
    get iconColor(): string
    {
        return getOrCompute(this, this.props.iconColor);
    }

    @computed
    get iconButtonSize(): number
    {
        return getOrCompute(this, this.props.iconButtonSize);
    }

    @computed
    get fullWidth(): boolean
    {
        return getOrCompute(this, this.props.fullWidth);
    }

    @computed
    get buttonColor(): ButtonColor
    {
        if (this.isHighlighted && !this.isIconSeparate)
        {
            return this.highlightColor;
        }
        else
        {
            return this.color;
        }
    }

    @computed
    get muiColor(): PropTypes.Color
    {
        return ButtonStore.Colors.has(this.buttonColor)
            ?
                this.buttonColor as PropTypes.Color
            :
                undefined;
    }

    @computed
    get nonMuiColor(): string
    {
        return ButtonStore.Colors.has(this.buttonColor)
            ?
                undefined
            :
                this.buttonColor;
    }

    @computed
    get allowHiding(): boolean
    {
        return !this.popperView;
    }

    @computed
    get isCustomLabel(): boolean
    {
        return this.label instanceof TextStore;
    }

    @computed
    get isClickable(): boolean
    {
        return this.props.onClick !== undefined;
    }

    @computed
    get isFinallyOutlined(): boolean
    {
        if (this.isMouseOver && this.isClickable)
        {
            return !this.outlined;
        }
        else
        {
            return this.outlined;
        }
    }

    @computed
    get isFilled(): boolean
    {
        return !this.isFinallyOutlined && this.renderAsChip;
    }

    @computed
    get isPopperOpen(): boolean
    {
        return this.popperView !== undefined;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get iconStore(): IconStore
    {
        if (this.icon)
        {
            return new IconStore({
                icon: this.icon,
                color: () => this.iconColor, //  (this.iconColor || this.color),  MS: removed, outlined buttons change color automaticly (css, material ui)
                size: () => this.iconSize
            });
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get labelTextStore(): TextStore
    {
        if (this.label instanceof TextStore)
        {
            return this.label;
        }
        else if (this.label)
        {
            if (this.isIconSeparate)
            {
                return new TextStore({
                    label: this.label,
                    isInline: true,
                    size: '.75rem',
                    lineHeight: '1.2',
                    style: {
                        letterSpacing: '0.025em',
                    },
                    alignment:
                        () =>
                            this.iconPosition === 'top'
                                ?
                                    'center'
                                :
                                    'left',
                    color: this.nonMuiColor,
                    innerStyle:
                        () =>
                            ({
                                display: 'block'
                            })
                });
            }
            else
            {
                return new TextStore({
                    label: this.label,
                    isInline: true,
                    innerStyle:
                        () =>
                        ({
                            display: 'inline'
                        })
                });
            }
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get popperViewOpen(): boolean
    {
        return !!this.popperView;
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    click(event: any): Promise<any>
    {
        if (event != null && event.stopPropagation != null && this.stopPropagation)
        {
            event.stopPropagation();
        }

        if (this.props.onClick)
        {
            const result = this.props.onClick(this);

            if (result instanceof Promise)
            {
                this.setState(StoreState.Loading);

                return result.then(() => this.setState(StoreState.Loaded));
            }
        }

        return Promise.resolve();
    }

    @action.bound
    openPopperView(view: ViewComponent,
                   placement: Placement = 'bottom-start',
                   popperViewOffset: number = 0,
                   autoClose: boolean = true,
                   noPaper: boolean = false)
    {
        this.popperView = view;
        this.popperViewPlacement = placement;
        this.popperViewOffset = popperViewOffset;
        this.popperCloseOnClickAway = autoClose;
        this.popperNoPaper = noPaper;
    }

    @action.bound
    openDrawer(view: ViewComponent, isLeft: boolean = true)
    {
        if (isLeft)
        {
            this.leftDrawerStore.pushView(view);
        }
        else
        {
            this.rightDrawerStore.pushView(view);
        }
    }

    @action.bound
    closePopperView()
    {
        this.popperView = undefined;
    }

    @action.bound
    closeDrawer()
    {
        this.leftDrawerStore.clear();
        this.rightDrawerStore.clear();
    }

    @action.bound
    startHovering()
    {
        this.isMouseOver = true;
    }

    @action.bound
    stopHovering()
    {
        this.isMouseOver = false;
    }

    @action.bound
    handleToggle(event: any, values: any[])
    {
        this.isToggled = values.length > 0;

        this.click(event);
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
