import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import CenteredBodyLayout from '../../../../../@Api/Layout/Type/CenteredBodyLayout';
import CenteredBodyView from './View/CenteredBodyView';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import Layout from '../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';

export interface CenteredBodyLayoutEditorProps extends LayoutEditorProps<CenteredBodyLayout>
{

}

const CenteredBodyLayoutEditor: React.FC<CenteredBodyLayoutEditorProps> =
    props =>
    {
        const setLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.layout = layout),
                [
                    props.layout
                ]);

        return <CenteredBodyView
            layout={props.layout}
        >
            <ChildLayoutEditor
                {...props}
                childLayout={props.layout.layout}
                onChange={setLayout}
            />
        </CenteredBodyView>;
    };

export default observer(CenteredBodyLayoutEditor);
