import { EntitySelectionAggregateResult } from '../../../../../Selection/Model/EntitySelectionAggregateResult';
import { useComputed } from 'mobx-react-lite';
import { createResultComparator } from '../../../../Api/createResultComparator';
import Segment from '../../../../Model/Segment';

function isHidden(result: EntitySelectionAggregateResult)
{
    return result.groupEntity && result.groupEntity.isDeleted;
}

export default function useFilterAndSortAggregateResult(segment: Segment,
                                                        result: EntitySelectionAggregateResult)
{
    return useComputed(
        () =>
        {
            if (result)
            {
                let children =
                    result.children
                        .filter(
                            child =>
                                !isHidden(child));

                const resultComparator =
                    createResultComparator<EntitySelectionAggregateResult>(
                        data => data,
                        segment);

                if (resultComparator)
                {
                    children =
                        children.sort(resultComparator);
                }

                return children;
            }
            else
            {
                return [];
            }
        },
        [
            segment,
            result
        ]);
}
