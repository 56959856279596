import React, { useMemo } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import Fields from '../../../Fields/Fields';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import useTypes from '../../../Type/Api/useTypes';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import { EntityExpansionBuilder } from '../../../Selection/Builder/EntityExpansionBuilder';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import CurrencyEditor from '../../../Currency/CurrencyEditor';

export interface PriceProps
{
    entity: Entity;
}

const Price: React.FC<PriceProps> =
    ({
        entity,
     }) =>
    {
        const types = useTypes();

        const [, loadingPriceList] =
            useAsyncResult(
                async () =>
                    await new EntityExpansionBuilder(
                        entity.entityType,
                        [
                            entity
                        ],
                        [
                            EntityPath.fromEntity(entity)
                                .joinTo(
                                    types.PriceList.RelationshipDefinition.Prices,
                                    true
                                )
                        ])
                        .expand(),
                [
                    entity,
                ]
            );

        const priceList =
            useMemo(
                () =>
                    entity
                        .getRelatedEntityByDefinition(
                            true,
                            types.PriceList.RelationshipDefinition.Prices
                        )?.getObjectValueByField(types.PriceList.Field.Name),
                [
                    loadingPriceList,
                    entity,
                ]);

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset
                                    bottom={false}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <TypeAndName
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                                <Fields
                                    entity={entity}
                                />
                                <CardInset>
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={15}
                                    >
                                        <ViewGroupItem>
                                            <Input
                                                label={types.PriceList.Type.getName()}
                                                labelPosition="left"
                                            >
                                                {priceList}
                                            </Input>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <CurrencyEditor
                                                entity={entity}
                                                field={types.Price.Field.Currency}
                                                disabled
                                                withLabel
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(Price);
