/**
 * Asynchronously load an external JavaScript
 * file and resolve upon completion.
 *
 * @param {string} src - the URL for the external source code.
 * @return {promise} a promise that resolves when the script loads.
 */
export default function loadScript(src)
{
    return new Promise(resolve => {
        const script = document.createElement('script');
        script.src = src;
        script.type = 'text/javascript';
        script.async = true;
        script.onload = resolve;
        document.body.appendChild(script);
    });
}
