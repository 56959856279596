import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import CampaignPercentageChart from './CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import useCampaignOpenedCount from '../Hooks/useCampaignOpenedCount';
import useCampaignEmailStateCount from '../Hooks/useCampaignEmailStateCount';
import useTypes from '../../../../../Type/Api/useTypes';

export interface CampaignChartUnsubscribedProps
{
    campaign: Entity;
    size: number;
}

export const CampaignChartUnsubscribed: React.FC<CampaignChartUnsubscribedProps> =
    observer(
        ({
             campaign,
             size,
         }) =>
        {
            const types = useTypes();

            const openedCount = useCampaignOpenedCount(campaign);
            const states =
                useMemo(
                    () =>
                        [
                            types.Activity.Email.State.Unsubscribed
                        ],
                    [
                        types
                    ]
                );
            const unSubscribedCount = useCampaignEmailStateCount(campaign,states);


            const allSet =
                useAllSet([
                    openedCount,
                    unSubscribedCount
                ]);

            const selection =
                useMemo(
                    () =>
                    {
                        return `${unSubscribedCount} / ${openedCount}`;
                    },
                    [
                        unSubscribedCount,
                        openedCount
                    ]
                )

            return <div>
                {
                    allSet &&
                    <CampaignPercentageChart
                        value={unSubscribedCount / openedCount * 100}
                        size={size}
                        label={localizeText('Campaign.Chart.Unsubscribed', 'Uitgeschreven')}
                        selection={selection}
                    />
                }
            </div>;
        }
    );
