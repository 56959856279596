import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import LabelButtonLayout from '../../../../../@Api/Layout/Type/LabelButtonLayout';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import Popper from '../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import useSwitch from '../../../../../@Util/Switch/useSwitch';
import LayoutControlsContent from '../../Editor/Controls/Content/LayoutControlsContent';
import LabelButtonLayoutViewer from './LabelButtonLayoutViewer';
import styles from './LabelButtonLayoutEditor.module.scss';

export interface LabelButtonLayoutEditorProps extends LayoutEditorProps<LabelButtonLayout>
{

}

const LabelButtonLayoutEditor: React.FC<LabelButtonLayoutEditorProps> =
    props =>
    {
        const parameterAssignment =
            useMemo(
                () =>
                    props.parameterAssignment || new ParameterAssignment(),

                [
                    props.parameterAssignment
                ]);

        const [ isOpen, open, close ] = useSwitch(false);

        return <Popper
            reference={
                <LabelButtonLayoutViewer
                    {...props}
                    parameterAssignment={parameterAssignment}
                    onClick={open}
                    doNotDisable
                />
            }
            popper={
                <div
                    className={styles.controls}
                >
                    <Card
                        inset
                    >
                        <LayoutControlsContent
                            {...props}
                        />
                    </Card>
                </div>
            }
            open={isOpen}
            onClose={close}
        />;
    };

export default observer(LabelButtonLayoutEditor);
