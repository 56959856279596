import { Entity } from '../Model/Implementation/Entity';
import { loadModuleDirectly } from '../../@Util/DependencyInjection/Injection/DependencyInjection';
import { CurrentUserStore } from '../../@Component/Domain/User/CurrentUserStore';
import { EntityTypeStore } from '../../@Component/Domain/Entity/Type/EntityTypeStore';
import equalsEntity from '../Entity/Bespoke/equalsEntity';

export default function isMutable(entity?: Entity)
{
    if (!entity)
    {
        return false;
    }

    const currentUserStore = loadModuleDirectly(CurrentUserStore);
    const types = loadModuleDirectly(EntityTypeStore).bespoke.types;

    const pack =
        entity.getRelatedEntityByDefinition(
            true,
            types.Pack.RelationshipDefinition.Entities);

    if (pack)
    {
        return currentUserStore.ownedPackEntities
            .some(
                ownedPack =>
                    equalsEntity(
                        ownedPack,
                        pack));
    }
    else
    {
        return false;
    }
}
