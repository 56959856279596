import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { OrganizationEnvironmentStore } from '../OrganizationEnvironmentStore';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';
import Drawer from '../../../../../Generic/Drawer/Drawer';
import ModuleManager from '../../../../../Domain/ModuleManager/ModuleManager';
import Dock from '../Dock/Dock';
import Feedback from '../Feedback/Feedback';
import BlockChecker from '../BlockChecker/BlockChecker';
import TwoFactorAuthenticationSetup from '../../../../../Domain/Security/TwoFactorAuthenticationSetup';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import { useHasToEnableTwoFactorAuthentication } from '../../../../../Domain/Security/Api/useHasToEnableTwoFactorAuthentication';
import CurrentUserContext from '../../../../../Domain/User/CurrentUserContext';
import { useHeaderNotifications } from '../HeaderNotifications/Api/useHeaderNotifications';
import { HeaderNotifications } from '../HeaderNotifications/HeaderNotifications';
import { IntercomManager } from '../../../../../Domain/IntercomManager/IntercomManager';
import { AssistantButton } from '../../../../../Domain/Assistant/AssistantButton';

export interface ContentProps
{
    store: OrganizationEnvironmentStore;
}

const Content: React.FC<ContentProps> =
    props =>
    {
        const [ hasToEnableTwoFactorAuthentication, isLoading ] = useHasToEnableTwoFactorAuthentication();
        const currentUserStore = useContext(CurrentUserContext);

        const headerNotifications = useHeaderNotifications();
        return <BlockChecker>
            <HeaderNotifications
                headerNotifications={headerNotifications}
            />
            {
                isLoading &&
                <Centered
                    horizontal
                >
                    <Loader />
                </Centered>
            }
            {
                !isLoading && hasToEnableTwoFactorAuthentication &&
                <TwoFactorAuthenticationSetup
                    user={currentUserStore.currentUser}
                />
            }
            {
                !isLoading && !hasToEnableTwoFactorAuthentication &&
                <>
                    <Header store={props.store.headerStore} />
                    <Navigation appStore={props.store} />
                    <Drawer store={props.store.leftDrawerStore} />
                    <Drawer store={props.store.rightDrawerStore} />
                    <Dock />
                    <ModuleManager />
                    <Feedback store={props.store.feedbackStore} />
                    <IntercomManager />
                    <AssistantButton />
                    <div id={'app-body-portal-container'} />
                    <div id="app-body-portal" style={{position: 'fixed', zIndex: 1000000}} />
                    <div id="app-popper" />
                </>
            }
       </BlockChecker>;
    };

export default observer(Content);
