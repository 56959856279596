import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Input from '../../../../../../../../../../Multiplayer/Model/Input/Input';
import Mapping from '../../../../../../../../../../../../@Api/Automation/Mapping/Mapping';
import FieldMapping from '../../../../../../../../../../../../@Api/Automation/Mapping/Field/FieldMapping';
import FunctionContext from '../../../../../../../../../../../../@Api/Automation/Function/FunctionContext';
import FieldEditor from './FieldEditor';

export interface FieldsEditorProps
{
    context: FunctionContext;
    mapping: Mapping;
    field: Input;
    onConstruct?: (fieldMapping: FieldMapping<Input>) => void;
}

const FieldsEditor: React.FC<FieldsEditorProps> =
    props =>
    {
        const fieldMappings =
            useComputed(
                () =>
                    props.mapping.fieldMappingsByFieldId.get(props.field.id()),
                [
                    props.mapping
                ]);

        return <>
            {
                fieldMappings.map(
                    fieldMapping =>
                        <FieldEditor
                            key={fieldMapping.id}
                            {...props}
                            fieldMapping={fieldMapping}
                        />)
            }
        </>;
    };

export default observer(FieldsEditor);
