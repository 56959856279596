import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import { FeaturesEditorProps } from '../FeaturesEditor';
import Feature from '../../../Model/Feature';
import { RightSwitch } from '../../RightSwitch/RightSwitch';
import styles from '../../RoleEditor.module.scss';
import { runInAction } from 'mobx';
import { ExportingFeature } from '../../../Model/Features';
import { Permission } from '../../../Model/Role';

export interface ItemEditorProps extends FeaturesEditorProps
{
    feature: Feature;
    label: React.ReactNode;
    inverted?: boolean;
}

export const FeatureEditor: React.FC<ItemEditorProps> =
    observer(
        ({
             role,
             feature,
             label,
             inverted,
             isReadOnly,
         }) =>
        {
            const isPermissionGranted =
                useComputed(
                    () =>
                        inverted
                            ?
                                !role.isPermissionGrantedForFeature(feature)
                            :
                                role.isPermissionGrantedForFeature(feature),
                    [
                        inverted,
                        role,
                        feature
                    ]);

            const resetExportAspects =
                useCallback(
                    () =>
                    {
                        role.rightByType.forEach(rightByType =>
                        {
                            rightByType.canExport = undefined;
                        })

                        role.rightByField.forEach(rightByField =>
                        {
                            rightByField.canExport = undefined;
                        })

                        role.rightByRelationshipType.forEach(rightByRelationshipType =>
                        {
                            rightByRelationshipType.canExport = undefined;
                        })
                    },
                    [
                        role
                    ]);

            const onFeatureChange =
                useCallback(
                    (permission: Permission) =>
                        runInAction(
                            () =>
                            {
                                const isPermissionGranted =  inverted
                                    ? permission === 'Denied'
                                    : permission === 'Granted';

                                const featureExists =
                                    role.features
                                        .map(feature => feature.id)
                                        .includes(feature.id);

                                if (isPermissionGranted && !featureExists)
                                {
                                    role.features.push(feature);
                                }
                                else if (!isPermissionGranted && featureExists)
                                {
                                    role.features = [...role.features].filter(f => f.id !== feature.id);
                                }

                                if (feature.id === ExportingFeature.id)
                                {
                                    resetExportAspects();
                                }
                            }
                        ),
                    [
                        inverted,
                        role,
                        feature
                    ]);

            return <CardInset
                classes={{
                    root: styles.cardHeader
                }}
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={0}
                    className={styles.label}
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        {label}
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <RightSwitch
                            permission={isPermissionGranted ? 'Granted' : 'Denied'}
                            inheritedPermission={'Inherited'}
                            onChange={isReadOnly ? undefined : onFeatureChange}
                            canRightBeInherited={false}
                            isDisabled={false}
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </CardInset>;
        }
    );
