import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../../../../Type/Api/useTypes';
import CloseStateButton from '../../../../../../../../../../Button/CloseStateButton/CloseStateButton';
import DateTime from './DateTime/DateTime';
import Input from '../../../../../../../../../../Input/Input';
import Base from '../../Base/Base';
import { ContentProps } from '../../Content';
import { EntityPath } from '../../../../../../../../../../Path/@Model/EntityPath';

export interface TaskProps extends ContentProps
{
    showAssignee?: boolean;
    rowClassName?: string;
    cellClassName?: string;
}

const Task: React.FC<TaskProps> =
    props =>
    {
        const types = useTypes();
        const employeePath =
            useMemo(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Activity.Task.RelationshipDefinition.Assignee,
                            false),
                [
                    props.entity,
                    types
                ]);

        const fields = [
            <Input
                key={0}
                entity={props.entity}
                field={types.Activity.Task.RelationshipDefinition.Assignee}
                labelPosition={props.labelPosition}
                inline={props.inlineLabel}
                commitContext={props.commitContext}
            />,
            <Input
                key={1}
                entity={props.entity}
                field={types.Activity.Task.RelationshipDefinition.Type}
                labelPosition={props.labelPosition}
                inline={props.inlineLabel}
                commitContext={props.commitContext}
            />,
            <DateTime
                key={2}
                {...props}
                startDateField={types.Activity.Task.Field.StartDate}
                endDateField={types.Activity.Task.Field.EndDate}
                employeePath={employeePath}
            />
        ];

        return <Base
            {...props}
            suffix={
                <CloseStateButton
                    entity={props.entity}
                />
            }
        >
            {fields}
        </Base>;
    };

export default observer(Task);
