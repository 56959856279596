import { Cookie } from '../../Cookie/Cookie';

export default async function getAccessToken(): Promise<string | undefined>
{
    // If access token is in cookie, then get it from there
    const cookie = await Cookie.get('access_token');

    if (cookie)
    {
        return cookie.value;
    }

    // Otherwise fetch it either from local or session storage
    const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
    const accessTokenExpirationDate = localStorage.getItem('accessTokenExpirationDate');

    if (accessToken)
    {
        if (!accessTokenExpirationDate || new Date() < new Date(accessTokenExpirationDate))
        {
            return accessToken;
        }
        else
        {
            return undefined;
        }
    }
    else
    {
        return undefined;
    }
}
