import { EntityTypeStore } from '../../@Component/Domain/Entity/Type/EntityTypeStore';
import { injectWithQualifier, loadModuleDirectly } from '../../@Util/DependencyInjection/index';
import { action, computed, observable, runInAction } from 'mobx';
import { BaseStore } from '../../@Framework/Store/BaseStore';
import { AuthenticationManager } from '../Authentication/AuthenticationManager';
import { WelcomeStore } from '../Welcome/WelcomeStore';
import { Localizer } from './Localizer';
import { ApiClient } from '../ApiClient/ApiClient';
import { WelcomeController } from '../../@Api/Controller/Directory/WelcomeController';
import { ClientLanguageHeader } from './LocalizationConstants';
import getBrowserLanguageCode from '../../@Component/App/Root/Environment/Public/Registration/Api/getBrowserLanguageCode';
import { ApiResponse } from '../ApiClient/Model/ApiResponseInterface';
import { LocalizationController } from '../../@Api/Controller/Directory/LocalizationController';
import { LocalizationRequest } from '../../@Api/Localization/Model/LocalizationRequest';
import { LocalizationResponse } from '../../@Api/Localization/Model/LocalizationResponse';
import { deduplicateArray } from '../../@Util/Array/deduplicateArray';

export class LocalizationStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('AuthenticationManager') authenticationManager: AuthenticationManager;
    @injectWithQualifier('WelcomeStore') welcomeStore: WelcomeStore;
    @injectWithQualifier('Localizer') localizer: Localizer;
    @injectWithQualifier('ApiClient') apiClient: ApiClient;
    @injectWithQualifier('WelcomeController') welcomeController: WelcomeController;

    // ------------------------- Properties -------------------------

    @observable defaultLanguage: string;
    @observable hasInitializedEntriesForLanguage: string;

    translationByCode = new Map<string, string>();
    translationsRequests: LocalizationRequest[];

    // ------------------------ Constructor -------------------------

    constructor()
    {
        super();

        this.defaultLanguage = getBrowserLanguageCode();
        this.translationsRequests = [];
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get languageCode(): string
    {
        return this.localizer.languageCode;
    }

    updateTranslationByCode()
    {
        this.translationByCode.clear();

        const translations = this.welcomeStore.welcomePackage.languagePackage.translations;

        Object.keys(translations)
            .forEach(
                key =>
                    this.translationByCode.set(
                        key,
                        translations[key]));
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    initialize(): Promise<any>
    {
        const interval =
            setInterval(
                () =>
                {
                    const requests = this.translationsRequests;
                    this.translationsRequests = [];

                    this.bulkTranslate(requests);
                },
                30000
            );

        this.registerDisposable(
            () =>
                clearInterval(interval)
        );

        this.updateTranslationByCode();

        return Promise.resolve();
    }

    public changeLanguage(languageCode: string) : Promise<any>
    {
        this.defaultLanguage = languageCode;
        this.apiClient.setDefaultHeader(ClientLanguageHeader, languageCode);

        if (this.welcomeStore.welcomePackage.languagePackage.languageCode === languageCode)
        {
            return Promise.resolve();
        }

        return this.welcomeController
            .getLanguagePackage()
            .then(
                languagePackage =>
                    runInAction(
                        () =>
                        {
                            this.welcomeStore.welcomePackage.languagePackage = languagePackage;
                            this.updateTranslationByCode();
                        }));
    }

    public translate(code: string,
                     ...args: string[])
    {
        let translation = this.translationByCode.get(code);

        if (translation)
        {
            args.forEach(
                (arg, idx) =>
                {
                    // Replacement of %0
                    translation =
                        translation
                            .replace(
                                new RegExp(`%${idx}`, 'g'),
                                arg);

                    // Replacement of % 0 (space is added by Google Translate)
                    translation =
                        translation
                            .replace(
                                new RegExp(`% ${idx}`, 'g'),
                                arg);
                });

            return translation;
        }

        return `{${code}}`;
    }

    @action
    public requestTranslation(
        code: string,
        sourceTranslation: string,
        sourceLanguage: string
    )
    {
        if (this.translationByCode.has(code))
        {
            return;
        }

        // Keep locally on fallback, refresh later on will update to official translation
        const languagePackage = this.welcomeStore.welcomePackage?.languagePackage;

        // Welcome package may be undefined if API is unreachable
        if (languagePackage)
        {
            languagePackage.setTranslation(
                code,
                sourceTranslation
            );

            this.updateTranslationByCode();
        }

        if (this.authenticationManager.isAuthenticated)
        {
            this.translationsRequests.push({
                code,
                sourceTranslation,
                sourceLanguage,
            });
        }
    }

    private bulkTranslate(requests: LocalizationRequest[])
    {
        if (!this.authenticationManager.isAuthenticated)
        {
            return;
        }

        const deduplicatedRequests = this.deduplicateRequests(requests);

        if (deduplicatedRequests.length === 0)
        {
            return;
        }

        // Request translation from API
        const module = loadModuleDirectly(LocalizationController);

        if (!module)
        {
            // Returning undefined might be because RootStore hasn't been initialized yet
            return;
        }

        const languagePackage = this.welcomeStore.welcomePackage.languagePackage;

        module.bulkTranslate(deduplicatedRequests)
            .then(
                (result: ApiResponse<LocalizationResponse[]>) =>
                {
                    if (result.ok)
                    {
                        result.data.forEach(
                            response =>
                                languagePackage.setTranslation(
                                    response.code,
                                    response.translation
                                )
                        );

                        this.updateTranslationByCode();
                    }
                }
            );
    }

    private deduplicateRequests(requests: LocalizationRequest[])
    {
        return deduplicateArray(
            requests,
            request =>
                `${request.code}.${request.sourceLanguage}.${request.sourceTranslation}`
        );
    }

    // ----------------------- Private logic ------------------------
}