import React, { useState } from 'react';
import { Selection } from '../../../../../@Api/Selection/Model/Selection';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import TabBar from '../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import { SelectboxAdvancedSelectionSearcher } from './SelectionSearcher/SelectboxAdvancedSelectionSearcher';
import { getRootNodeFromSelection } from '../../../../../@Api/Selection/Api/getRootNodeFromSelection';

export interface SelectboxAdvancedSearcherProps
{
    selections: Selection[] | Entity[];
    onSelect: (entity: Entity) => void;
}

export const SelectboxAdvancedSearcher: React.FC<SelectboxAdvancedSearcherProps> =
    ({
         selections,
         onSelect,
     }) =>
    {
        const [ selectedTab, setSelectedTab ] = useState(0);
        const actualSelections: Selection[] =
            (selections as any[])
                .filter(
                    selection =>
                        selection instanceof Selection
                );

        if (actualSelections.length > 0)
        {
            return <>
                {
                    actualSelections.length > 1 &&
                    <TabBar
                        value={selectedTab}
                        orientation="horizontal"
                    >
                        {
                            actualSelections.map(
                                (selection, idx) =>
                                    <Tab
                                        key={idx}
                                        value={idx}
                                        onClick={setSelectedTab}
                                    >
                                        {getRootNodeFromSelection(selection).entityType}
                                    </Tab>
                            )
                        }
                    </TabBar>
                }
                <SelectboxAdvancedSelectionSearcher
                    selection={actualSelections[selectedTab]}
                    onSelect={onSelect}
                />
            </>;
        }
        else
        {
            return null;
        }
    };
