import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { ButtonStore } from '../ButtonStore';
import * as React from 'react';
import Text from '../../Text/Text';
import { default as InternalLabelButton } from '../../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import { primaryColor } from '../../../../@Resource/Theme/Theme';

@observer
export class LabelButton extends BaseComponent<ButtonStore>
{
    renderLoader(store: ButtonStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: ButtonStore)
    {
        return <InternalLabelButton
            label={store.labelTextStore &&
                <div
                    style={{
                        display: 'inline-block'
                    }}
                >
                    {
                        store.isCustomLabel
                            ?
                            <Text store={store.labelTextStore} />
                            :
                            store.labelTextStore.label // No Text component because it overrides all button text formatting.
                    }
                </div>}
            onClick={store.click as any}
            color={store.nonMuiColor || primaryColor}
            hoverColor={primaryColor}
            filled={store.isRaised}
            disabled={store.isDisabled}
        />;
    }
}
