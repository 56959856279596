import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import CurrentUserContext from '../../../User/CurrentUserContext';
import { observer, useComputed } from 'mobx-react-lite';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';

import styles from './FavoriteButton.module.scss';
import useTypes from '../../Type/Api/useTypes';
import EntityTypeContext from '../../Type/EntityTypeContext';
import deleteEntity from '../../../../../@Api/Entity/deleteEntity';
import { createTransactionalModel } from '../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import localizeText from '../../../../../@Api/Localization/localizeText';


export interface FavoriteButtonButtonProps
{
    entity: Entity;
    attention?: boolean;
    size?: 'md' | 'lg';
}

const FavoriteButton: React.FC<FavoriteButtonButtonProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeContext = useContext(EntityTypeContext);
        const currentUserContext = useContext(CurrentUserContext);

        useEffect(
            () =>
            {
                if (!props.entity.isNew())
                {
                    const path = types.Favorite.Type.path();

                    new EntitySelectionBuilder(path.entityType)
                        .where(
                            cb => cb.relatedToEntity(
                                path.joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.Favorites,
                                    true),
                                currentUserContext.employeeEntity))
                        .where(
                            cb => cb.relatedToEntity(
                                path.joinTo(
                                    types.Favorite.RelationshipDefinition.Entity,
                                    false),
                                props.entity))
                        .select();
                }
            }
        );

        const favorite =
            useComputed(
                () =>
                    props.entity.getRelatedEntitiesByDefinition(
                        true,
                        types.Favorite.RelationshipDefinition.Entity)
                        .filter(
                            favorite =>
                                favorite
                                    .getRelatedEntityByDefinition(
                                        true,
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Favorites) === currentUserContext.employeeEntity)
                        .find(() => true));


        const color =
            useMemo(
                () =>
                    favorite
                        ?
                            '#fce18d'
                        :
                            '#e6e6e6',
                [
                    favorite
                ]);

        const tooltip =
            useMemo(
                () =>
                    favorite
                        ? localizeText('RemoveFavorite', 'Favoriet opheffen')
                        : localizeText('CreateFavorite', 'Favoriet maken'),
                [
                    favorite
                ]);

        const onClick =
            useCallback(
                () =>
                {
                    if (favorite !== undefined)
                    {
                        return deleteEntity(favorite);
                    }
                    else
                    {
                        const favorite =
                            createTransactionalModel(
                                new Entity(types.Favorite.Type)
                                    .initialize(entityTypeContext));

                        favorite.updateRelationship(
                            true,
                            types.Relationship.Person.Contact.Employee.RelationshipDefinition.Favorites,
                            currentUserContext.employeeEntity);

                        favorite.updateRelationship(
                            false,
                            types.Favorite.RelationshipDefinition.Entity,
                            props.entity);

                        return favorite.checkAndDoCommit();
                    }
                },
                [
                    currentUserContext.employeeEntity,
                    types,
                    entityTypeContext,
                    favorite,
                    props.entity
                ]);


        const size =
            useMemo(
                () =>
                    props.size === 'md'
                        ?
                            props.attention ? 21 : 18
                        :
                            36,
                [
                    props.size,
                    props.attention
                ]);

        const iconSize =
            useMemo(
                () =>
                    props.size === 'md'
                        ?
                            props.attention ? 15 : 12
                        :
                            30,
                [
                    props.size,
                    props.attention
                ]);

        return <IconButton
            classes={{
                root: props.attention ? styles.buttonRoot : undefined,
                icon: styles.iconButton}}
            icon="bookmark"
            tooltip={tooltip}
            onClick={onClick}
            color={color}
            filled={false}
            outlined={false}
            size={size}
            iconSize={iconSize}
            disableShadow
        />;
    };

FavoriteButton.defaultProps =
{
    size: 'md'
};


export default observer(FavoriteButton);
