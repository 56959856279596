import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import getIllegalInstantiableTypes from './getIllegalInstantiableTypes';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { CurrentUserStore } from '../../../User/CurrentUserStore';

export default function getInstantiableTypes(entityType: EntityType,
                                             entity?: Entity)
{
    const currentUserStore = loadModuleDirectly(CurrentUserStore);
    const illegalInstantiableTypes = getIllegalInstantiableTypes(entityType, entity);

    return currentUserStore.rightProfile.getCreatableSubtypes(entityType)
        .filter(
            type =>
                !illegalInstantiableTypes.has(type));
}
