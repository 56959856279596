import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';
import useTypes from '../../Type/Api/useTypes';

export default function useWorkflowStateIsTerminal(workflowState?: Entity): boolean
{
    const types = useTypes();

    return useComputed(
        () =>
            workflowState
                ?
                    workflowState.getObjectValueByField(types.Datastore.Phase.Field.IsTerminal)
                :
                    undefined,
        [
            workflowState
        ]);
}
