import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';
import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { ColorUtils } from '../../../../../@Util/Color/ColorUtils';
import { AvatarType } from '../../../../Generic/Avatar/AvatarStore';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { BadgeStore } from '../../../../Generic/Badge/BadgeStore';

export const dimmedColor =
    (entityType: EntityType) =>
        ColorUtils.hexToRGB(entityType.getInheritedColor(), 0.6);

export abstract class EntityEventType<T = BaseStore>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract id(): string;

    abstract parentId(): string;

    abstract isEnabledByDefault(): boolean;

    abstract color(event: EntityEvent): string;

    abstract icon(event: EntityEvent): string;

    abstract isFilled(event: EntityEvent): boolean;

    avatarType(event: EntityEvent)
    {
        return AvatarType.Round;
    }

    abstract name(event: EntityEvent): string;

    abstract description(event: EntityEvent): string;

    store?(event: EntityEvent): T;

    abstract content(event: EntityEvent, store?: T): JSX.Element;

    abstract caption(event: EntityEvent, store?: T): string;

    abstract actions(event: EntityEvent, store?: T, onDelete?: (event: EntityEvent) => void): ButtonStore[];

    abstract badges(event: EntityEvent): BadgeStore[];

    abstract allowExpansion(event: EntityEvent): boolean;

    abstract expandInSidebar(event: EntityEvent): boolean;

    abstract expansionComponent(event: EntityEvent): React.ComponentClass<BaseComponentProps<BaseStore>>;

    abstract expansionStore(event: EntityEvent): BaseStore;

    abstract typeNameSingular(event: EntityEvent): string;

    abstract typeNamePlural(): string;

    getOpenableEntity?(event: EntityEvent): Entity;

    // ----------------------- Private logic ------------------------
}
