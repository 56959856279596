import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import DrawingFormInputLayoutViewer from './FilesFormInputLayoutViewer';
import ParameterAssignment from '../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import FilesFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/FilesFormInputLayout';

export interface FilesFormInputLayoutEditorProps extends LayoutEditorProps<FilesFormInputLayout>
{

}

const FilesFormInputLayoutEditor: React.FC<FilesFormInputLayoutEditorProps> =
    props =>
    {
        const parameterAssignment =
            useMemo(
                () =>
                    props.parameterAssignment || new ParameterAssignment(),

                [
                    props.parameterAssignment
                ]);

        return <DrawingFormInputLayoutViewer
            parameterAssignment={parameterAssignment}
            {...props}
        />;
    };

export default observer(FilesFormInputLayoutEditor);
