import { DynamicFunction } from '../../DynamicFunction';
import ValueType from '../../../../Value/Type/ValueType';
import Value from '../../../../Value/Value';
import Parameter from '../../../../Parameter/Parameter';
import { observable } from 'mobx';
import getValueTypeFromDescriptor from '../../../../Api/getValueTypeFromDescriptor';
import AutomationDependencyContext from '../../../../AutomationDependencyContext';

export default class ServerFunction<T extends ValueType<any>, V extends Value<any, T>> implements DynamicFunction<T, V>
{
    // ------------------------- Properties -------------------------

    @observable.ref id: string;
    @observable.ref name: string;
    @observable.ref parameters: Parameter<any>[];
    @observable.ref returnType: T;

    // ------------------------ Constructor -------------------------


    constructor(id: string,
                name: string,
                parameters: Parameter<any>[],
                returnType: T)
    {
        this.id = id;
        this.name = name;
        this.parameters = parameters;
        this.returnType = returnType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getId(): string
    {
        return this.id;
    }

    getName(): string
    {
        return this.name;
    }

    getParameters(): Parameter<any>[]
    {
        return this.parameters;
    }

    getReturnType(): T
    {
        return this.returnType;
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new ServerFunction(
            descriptor.id,
            descriptor.name,
            await Promise.all(
                descriptor.parameters.map(
                    parameter =>
                        Parameter.fromFullDescriptor(
                            parameter,
                            AutomationDependencyContext.GetEmptyContext()))),
            await getValueTypeFromDescriptor(
                descriptor.returnType,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
