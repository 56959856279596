import React, { useCallback, useContext, useMemo, useState } from 'react';
import Card from '../../../@Future/Component/Generic/Card/Card';
import { generateAssistantAnswerByPrompt } from './Api/generateAssistantAnswerByPrompt';
import { AssistantPrompt } from './Model/AssistantPrompt';
import useAsyncResult from '../../../@Util/Async/useAsyncResult';
import { observer } from 'mobx-react';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/index';
import { RouterStore } from '../../../@Service/Router/RouterStore';
import { EntityViewerPageStore } from '../Entity/Viewer/Page/EntityViewerPageStore';
import { EntityViewerPageInitializerStore } from '../Entity/Viewer/Page/Initializer/EntityViewerPageInitializerStore';
import { Entity } from '../../../@Api/Model/Implementation/Entity';
import { FollowUpQuestionWithAnswer } from './Model/FollowUpQuestionWithAnswer';
import ViewGroupItem from '../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Box, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import ViewGroup from '../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import AssistantLogo from './Resources/smart_assistant_avatar_green.png';
import LoadingDots from './Resources/loading-dots.gif';
import { AssistantPromptViewer } from './AssistantPromptViewer';
import { AssistantPromptAnswerViewer } from './AssistantPromptAnswerViewer';
import { AssistantPromptHistoryContext } from './Context/AssistantPromptHistoryContext';

export interface AssistantPromptAnswerProps
{
    prompt: AssistantPrompt;
    remainingPrompts: AssistantPrompt[];
    onClose: () => void;
    followUpQuestionAnswers: FollowUpQuestionWithAnswer[];
    hideAvatar?: boolean;
}

export const AssistantPromptAnswer: React.FC<AssistantPromptAnswerProps> =
    observer(
        ({
            prompt,
            remainingPrompts,
            onClose,
            followUpQuestionAnswers,
            hideAvatar,
         }) =>
        {
            const parentPromptHistory = useContext(AssistantPromptHistoryContext);
            const [retryKey, setRetryKey] = useState(0);
            const retry =
                useCallback(
                    () =>
                        setRetryKey(
                            key =>
                                key + 1
                        ),
                    []
                );
            const [
                answer,
                isLoading,
            ] =
                useAsyncResult(
                    async () =>
                    {
                        const loadAnswer =
                            async (
                                error?: string
                            ) =>
                            {
                                const routerStore = loadModuleDirectly(RouterStore);
                                let watchingEntity: Entity | undefined = undefined;

                                if (typeof routerStore.currentPageStore === 'object')
                                {
                                    if (routerStore.currentPageStore instanceof EntityViewerPageStore)
                                    {
                                        watchingEntity = routerStore.currentPageStore.entity;
                                    }
                                    else if (routerStore.currentPageStore instanceof EntityViewerPageInitializerStore)
                                    {
                                        watchingEntity = routerStore.currentPageStore.entity;
                                    }
                                }

                                const context =
                                    watchingEntity
                                        ? `This ${watchingEntity.entityType.getName()} is defined as a record with id: ${watchingEntity.id} and type: ${watchingEntity.entityType.code}.
                                The 'id' field of this record always points to the 'id' field of a ${watchingEntity.entityType.code}.
                                It can be used in the filter to filter on an id of an entity.`
                                        : undefined;
                                const contextWithError =
                                    context === undefined && error === undefined
                                        ? undefined
                                        : [context, error].filter(c => c).join('\n');
                                const answer =
                                    await generateAssistantAnswerByPrompt(
                                        prompt.prompt,
                                        parentPromptHistory,
                                        contextWithError
                                    );

                                console.log(contextWithError, parentPromptHistory, answer);

                                return answer;
                            };

                        try
                        {
                            return await loadAnswer();
                        }
                        catch (error)
                        {
                            console.warn('Error while fetching answer, trying again...', error);

                            return loadAnswer(
                                `A previous prompted returned the following error "${error}"`
                            );
                        }
                    },
                    [prompt.id, prompt.prompt, onClose, parentPromptHistory, retryKey]
                );
            const newPromptHistory =
                useMemo(
                    () => [
                        ...parentPromptHistory,
                        ...answer
                            ? [
                                {
                                    prompt,
                                    answer,
                                }
                            ]
                            : []
                    ],
                    [parentPromptHistory, prompt, answer]
                );

            return <AssistantPromptHistoryContext.Provider
                value={newPromptHistory}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={16}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={12}
                        >
                            {
                                !hideAvatar &&
                                <ViewGroupItem>
                                    <img
                                        src={AssistantLogo}
                                        width={50}
                                    />
                                </ViewGroupItem>
                            }
                            <ViewGroupItem
                                ratio={1}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        marginBottom: 25,
                                    }}
                                >
                                    {
                                        isLoading
                                            ? <Card
                                                inset
                                                style={{
                                                    width: 'fit-content',
                                                }}
                                            >
                                                <img
                                                    src={LoadingDots}
                                                    width={25}
                                                />
                                            </Card>
                                            : <AssistantPromptAnswerViewer
                                                answer={answer}
                                                prompt={prompt}
                                                remainingPrompts={remainingPrompts}
                                                onClose={onClose}
                                                followUpQuestionAnswers={followUpQuestionAnswers}
                                                hideAvatar={hideAvatar}
                                                onRetry={retry}
                                            />
                                    }
                                    <Box
                                        style={{
                                            position: 'absolute',
                                            right: 0,
                                            bottom: -20,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            {format(prompt.date, 'HH:mm')}
                                        </Typography>
                                    </Box>
                                </div>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    {
                        remainingPrompts.length > 0 &&
                        <ViewGroupItem>
                            <AssistantPromptViewer
                                prompt={remainingPrompts[0]}
                                remainingPrompts={remainingPrompts.slice(1)}
                                onClose={onClose}
                            />
                        </ViewGroupItem>
                    }
                </ViewGroup>
            </AssistantPromptHistoryContext.Provider>;
        }
    );
