import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationBox from '../../Box/ComputationBox';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import AggregateOfCollectionComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/AggregateOfCollectionComputation';
import { Aggregate } from '../../../../../../../../DataObject/Model/Aggregate';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import { AggregateEditor } from '../../../../../../../../AggregateEditor/AggregateEditor';

export interface AggregateOfCollectionComputationEditorProps extends ComputationEditorProps<ValueType<any>, AggregateOfCollectionComputation>
{

}

const AggregateOfCollectionComputationEditor: React.FC<AggregateOfCollectionComputationEditorProps> =
    ({
        value,
        context,

     }) =>
    {
        const setCollection =
            useCallback(
                (collection?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            value.collection = collection
                    ),
                [
                    value,
                ]
            );
        const setAggregate =
            useCallback(
                (aggregate: Aggregate) =>
                    runInAction(
                        () =>
                            value.aggregate = aggregate
                    ),
                [
                    value,
                ]
            );

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                <AggregateEditor
                    value={value.aggregate}
                    onChange={setAggregate}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <LocalizedText
                    code="Of"
                    value="van"
                />:
            </ViewGroupItem>
            <ViewGroupItem>
                <ComputationBox>
                    <ComputationEditor
                        context={context}
                        value={value.collection}
                        onChange={setCollection}
                    />
                </ComputationBox>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(AggregateOfCollectionComputationEditor);
