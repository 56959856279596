import { useContext, useMemo } from 'react';
import LocalizerContext from '../../../../../../../../@Service/Localization/LocalizerContext';
import { Currency } from '../../../../../../../../@Api/Localization/Currency';

export function useCurrencies(
    includeInactive: boolean = false
): Currency[]
{
    const localizer = useContext(LocalizerContext);

    return useMemo(
        () =>
            localizer.currencies
                .filter(
                    currency =>
                        !includeInactive
                            ? currency.isActive
                            : true
                ),
        [
            localizer.currencies,
        ]
    );
}