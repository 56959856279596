import { observable } from 'mobx';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import FieldMapping from './FieldMapping/FieldMapping';
import resolveFieldMapping from './FieldMapping/Api/resolveFieldMapping';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';

export default class KeyField
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref fieldPath: EntityFieldPath;
    @observable isRequired: boolean;
    @observable.ref mapping?: FieldMapping;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                fieldPath: EntityFieldPath,
                isRequired: boolean,
                mapping?: FieldMapping)
    {
        this.id = id;
        this.fieldPath = fieldPath;
        this.isRequired = isRequired;
        this.mapping = mapping;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext,
    )
    {
        return new KeyField(
            descriptor.id,
            EntityFieldPath.construct(descriptor.fieldPath),
            descriptor.isRequired,
            descriptor.mapping
                ?
                    await resolveFieldMapping(
                        descriptor.mapping,
                        dependencyContext
                    )
                :
                    undefined);
    }

    toDescriptor()
    {
        return {
            id: this.id,
            fieldPath: this.fieldPath.descriptor,
            isRequired: this.isRequired,
            mapping: this.mapping?.toDescriptor()
        };
    }

    // ----------------------- Private logic ------------------------
}
