import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { ComputationEditorProps } from '../../ComputationEditor';
import TextComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/TextComputation';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { runInAction } from 'mobx';
import { RichTextType } from '../../../../../../../../DataObject/Type/RichText/RichTextType';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import Switch from '../../../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import { CardContent } from '@material-ui/core';

export interface TextComputationEditorOptionsProps extends ComputationEditorProps<PrimitiveValueType, TextComputation>
{

}

const TextComputationEditorOptions: React.FC<TextComputationEditorOptionsProps> =
    props =>
    {
        const isRichText =
            useComputed(
                () =>
                    props.value
                        ?
                            props.value.isRichText
                        :
                            (props.type instanceof PrimitiveValueType && props.type.type instanceof RichTextType),
                [
                    props.value,
                    props.type
                ]);
        const setRichText =
            useCallback(
                (isRichText: boolean) =>
                    runInAction(
                        () =>
                        {
                            if (props.value)
                            {
                                props.value.isRichText = isRichText;
                            }
                        }
                    ),
                [props.value]
            );

        return <CardContent>
            <Input
                label={
                    <LocalizedText
                        code="Generic.Formatting"
                        value="Opmaak"
                    />
                }
            >
                <Switch
                    checked={isRichText}
                    onToggle={setRichText}
                />
            </Input>
        </CardContent>;
    };

export default observer(TextComputationEditorOptions);
