import Parameter from './Parameter';
import ValueType from '../Value/Type/ValueType';
import Value from '../Value/Value';
import AutomationDependencyContext from '../AutomationDependencyContext';
import { observable } from 'mobx';
import ParameterDictionary from './ParameterDictionary';
import getValueFromDescriptor from '../Api/getValueFromDescriptor';
import { FileReporter } from '../../../@Component/Domain/DataObject/Model/DataDescriptor';
import EmptyValue from '../Value/EmptyValue';

export default class ParameterAssignment
{
    // ------------------------- Properties -------------------------

    @observable.shallow valueByParameter: Map<Parameter<any>, Value<any, ValueType<any>>>;
    @observable.ref parentAssignment: ParameterAssignment;

    // ------------------------ Constructor -------------------------

    constructor(valueByParameter: Map<Parameter<any>, Value<any, ValueType<any>>> = new Map(),
                parentAssignment?: ParameterAssignment)
    {
        this.valueByParameter = valueByParameter;
        this.parentAssignment = parentAssignment;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    hasValue(parameter: Parameter<any>)
    {
        const value = this.getValue(parameter);

        return value !== undefined && !(value instanceof EmptyValue);
    }

    getValue(parameter: Parameter<any>)
    {
        return this.valueByParameter.get(parameter)
            ?? this.parentAssignment?.getValue(parameter);
    }

    getObjectValue(parameter: Parameter<any>)
    {
        return this.getObjectValueOr(parameter, undefined);
    }

    getObjectValueOr<T>(parameter: Parameter<any>,
                        defaultValue: T | undefined): T | undefined
    {
        const value = this.getValue(parameter);

        if (value)
        {
            return value.value;
        }
        else
        {
            return defaultValue;
        }
    }

    setValue(parameter: Parameter<any>,
             value?: Value<any, ValueType<any>>): this
    {
        if (value)
        {
            this.valueByParameter.set(
                parameter,
                value);
        }
        else
        {
            this.valueByParameter.delete(parameter);
        }

        return this;
    }

    toParameterDictionary()
    {
        return new ParameterDictionary(
            Array.from(this.valueByParameter.keys()));
    }

    getNewAssignmentWithParameter(parameter: Parameter<any>,
                                  value: Value<any, any>)
    {
        return new ParameterAssignment(
            new Map(),
            this)
            .setValue(
                parameter,
                value);
    }

    getNewAssignment(parameterAssignment: ParameterAssignment)
    {
        return new ParameterAssignment(
            new Map(parameterAssignment.valueByParameter),
            this
        );
    }

    equals(other: ParameterAssignment)
    {
        return Array.from(this.valueByParameter.entries())
            .every(
                ([ key, valueA ]) =>
                {
                    const valueB = other.getValue(key);

                    if (valueB === undefined)
                    {
                        return false;
                    }
                    else
                    {
                        return valueA.equals(valueB);
                    }
                })
            && Array.from(other.valueByParameter.keys())
                .every(
                    key =>
                        this.valueByParameter.has(key))
            && (this.parentAssignment === undefined || this.parentAssignment.equals(other.parentAssignment ?? new ParameterAssignment()));
    }

    cloneDeep()
    {
        return new ParameterAssignment(
            new Map(
                Array.from(this.valueByParameter.entries())
                    .map(
                        ([ parameter, value ]) => [
                            parameter,
                            value.clone()
                        ])));
    }

    toDescriptor(onFile?: FileReporter)
    {
        return Array.from(this.valueByParameter.entries())
            .map(
                ([ key, value ]) => ({
                    parameterId: key.id,
                    value: value.toDescriptor(onFile)
                }));
    }

    toString()
    {
        return Array.from(this.valueByParameter.entries())
            .map(
                ([ key, value ]) =>
                    `${key.getName()}: ${value.getName()}`
            )
            .join(', ');
    }

    static async fromDescriptor(dependencyContext: AutomationDependencyContext,
                                descriptor: any)
    {
        return new ParameterAssignment(
            new Map(
                await Promise.all(
                    descriptor
                        .filter(
                            element =>
                                dependencyContext
                                    .parameterDictionary
                                    .hasParameterId(element.parameterId))
                        .map(
                            async element => {
                                const parameter = dependencyContext.parameterDictionary.getParameterById(element.parameterId);
                                const value = await getValueFromDescriptor(element.value, dependencyContext);

                                return [
                                    parameter,
                                    value
                                ];
                            }))));
    }

    // ----------------------- Private logic ------------------------
}
