import React, { ChangeEvent, KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TextField } from '@material-ui/core';
import useStartAdornment from '../Api/useStartAdornment';
import useEndAdornment from '../Api/useEndAdornment';
import { InputProps as StandardInputProps } from '@material-ui/core/Input';
import styles from '../TextEditor/TextEditor.module.scss';
import { classNames } from '../../../../Util/Class/classNames';
import { NumericFormat } from 'react-number-format';
import InputContentFit from '../InputContentFit/InputContentFit';
import { OnValueChange } from 'react-number-format/types/types';

export type Alignment = 'left' | 'center' | 'right'

export interface NumberEditorProps
{
    value?: number;
    onChange: (value?: number) => void;
    placeholder?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    min?: number;
    max?: number;
    decimalScale?: number;
    fixedDecimalScale?: boolean;
    prefix?: string;
    suffix?: string;
    inputProps?: StandardInputProps['inputProps'];
    disableUnderline?: boolean;
    disableThousandSeparator?: boolean;
    alignment?: Alignment;
    error?: boolean;
    fitContent?: boolean;
    onBlur?: (value?: number) => void;
}

const NumberEditor: React.FC<NumberEditorProps> =
    props =>
    {
        const { onChange } = props;
        const onCheckedChange =
            useCallback(
                (value?: number) =>
                {
                    if (value === undefined)
                    {
                        onChange(value);

                        return true;
                    }
                    else
                    {
                        const isMinSatisfied = props.min === undefined || value >= props.min;
                        const isMaxSatisfied = props.max === undefined || value <= props.max;

                        if (isMinSatisfied && isMaxSatisfied)
                        {
                            onChange(value);

                            return true;
                        }
                    }

                    return false;
                },
                [
                    onChange,
                    props.min,
                    props.max
                ]);

        const startAdornment = useStartAdornment(props.startAdornment);
        const endAdornment = useEndAdornment(props.endAdornment);

        const inputPropsWithoutUsedEvents =
            useMemo(
                () =>
                {
                    const { onChange, onKeyDown, ...other } = props.inputProps;

                    return {
                        ...other,
                        style: {
                            textAlign: props.alignment
                        }
                    };
                },
                [
                    props.inputProps,
                    props.alignment
                ]);

        const [ inputRef, setInputRef ] = useState<HTMLInputElement | undefined>();
        const { prefix, suffix, decimalScale, fixedDecimalScale } = props;

        const customFormatter =
            useCallback(
                (formatterProps: any) =>
                {
                    const { inputRef, onChange, ...other } = formatterProps;
                    const onValueChange: OnValueChange =
                        (values, sourceInfo) =>
                        {
                            // Do not trigger onChange events from prop changes
                            if (sourceInfo.source === 'event')
                            {
                                onChange({
                                    target: {
                                        value: values.value === '' ? undefined : values.value
                                    }
                                });
                            }
                        };

                    return <NumericFormat
                        {...other}
                        getInputRef={setInputRef}
                        onValueChange={onValueChange}
                        decimalSeparator=","
                        decimalScale={decimalScale}
                        fixedDecimalScale={fixedDecimalScale}
                        prefix={prefix}
                        suffix={suffix}
                        valueIsNumericString
                        thousandSeparator={props.disableThousandSeparator ? false : '.'}
                    />;
                },
                [
                    setInputRef,
                    decimalScale,
                    fixedDecimalScale,
                    props.disableThousandSeparator,
                    prefix,
                    suffix
                ]);

        const inputProps =
            useMemo(
                () =>
                    ({
                        inputComponent: customFormatter,
                        startAdornment: startAdornment,
                        endAdornment: endAdornment,
                        classes: {
                            underline: props.disableUnderline && styles.disableUnderline
                        }
                    }),
                [
                    customFormatter,
                    startAdornment,
                    endAdornment,
                    props.disableUnderline
                ]);

        const onInputChange =
            useCallback(
                (event: ChangeEvent<HTMLInputElement>) =>
                {
                    const value = event.target.value;
                    let isChanged = false;

                    if (value === '' || value === undefined)
                    {
                        isChanged = onCheckedChange();
                    }
                    else
                    {
                        isChanged = onCheckedChange(parseFloat(value));
                    }

                    if (isChanged
                        && props.inputProps?.onChange)
                    {
                        props.inputProps.onChange(event);
                    }
                },
                [
                    onCheckedChange,
                    props.inputProps
                ]);

        const onBlur =
            useCallback(
                (event) =>
                {
                    if (props.onBlur)
                    {
                        props.onBlur(
                            isNaN(parseFloat(event.target.value))
                                ? undefined
                                : parseFloat(event.target.value)
                        );
                    }
                },
                [
                    props.onBlur,
                ]
            );

        const onKeyDown =
            useCallback(
                (event: KeyboardEvent<HTMLInputElement>) =>
                {
                    let value = props.value;

                    if (value === undefined)
                    {
                        onCheckedChange(undefined)
                    }
                    else if (event.key === 'ArrowUp')
                    {
                        onCheckedChange(value + 1);
                    }
                    else if (event.key === 'ArrowDown')
                    {
                        onCheckedChange(value - 1);
                    }

                    if (props.inputProps.onKeyDown)
                    {
                        props.inputProps.onKeyDown(event);
                    }
                },
                [
                    props.value,
                    onCheckedChange,
                    props.inputProps
                ]);

        const onFocus =
            useCallback(
                () =>
                {
                    if (inputRef)
                    {
                        // MS: For reason setTimeout is used see:
                        // https://stackoverflow.com/questions/60129605/is-javascripts-setselectionrange-incompatible-with-react-hooks
                        // We could also try to use the useEffect example, but for now just add a delay of 10ms

                        // In case of clicking on the prefix or suffix, then timeout ensures that still the numbers get selected
                        setTimeout(
                            () =>
                                inputRef.setSelectionRange((prefix || '').length, inputRef.value.length - (suffix || '').length),
                            10
                        );
                    }
                },
                [
                    inputRef,
                    prefix,
                    suffix
                ]);

        const classes =
            useMemo(
                () =>
                    ({
                        root: classNames(styles.root)
                    }),
                []);

        return <InputContentFit
            fitContent={props.fitContent}
            value={`${props.prefix ?? ''}${props.value ?? ''}${props.suffix ?? ''}`}
        >
            <div>
                <TextField
                    value={props.value ?? ''}
                    inputProps={inputPropsWithoutUsedEvents}
                    InputProps={inputProps}
                    classes={classes}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    autoFocus={props.autoFocus}
                    onChange={onInputChange}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    fullWidth
                    error={props.error}
                    onBlur={onBlur}
                />
            </div>
        </InputContentFit>;
    };

NumberEditor.defaultProps = {
    inputProps: {}
};

export default observer(NumberEditor);
