import { BaseStore, CallbackType, getOrCompute, PropType } from '../../../@Framework/Store/BaseStore';
import { action, computed } from 'mobx';
import { CSSProperties } from 'react';

export interface IconProps
{
    icon: PropType<IconStore, IconProps, string>;
    color?: PropType<IconStore, IconProps, string>;
    size?: PropType<IconStore, IconProps, string | number>;
    weight?: PropType<IconStore, IconProps, number>;
    isInline?: PropType<IconStore, IconProps, boolean>;
    onClick?: CallbackType<IconStore, IconProps, any>;
    style?: PropType<IconStore, IconProps, CSSProperties>;
}

const defaultProps: Partial<IconProps> =
{
    size: 16,
    isInline: false
};

export class IconStore extends BaseStore<IconProps>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: IconProps)
    {
        super(props, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get icon(): string
    {
        return getOrCompute(this, this.props.icon);
    }

    @computed
    get color(): string
    {
        return getOrCompute(this, this.props.color);
    }

    @computed
    get size(): string | number
    {
        return getOrCompute(this, this.props.size);
    }

    @computed
    get weight(): number
    {
        return getOrCompute(this, this.props.weight);
    }

    @computed
    get isInline(): string
    {
        return getOrCompute(this, this.props.isInline);
    }

    @computed
    get style(): CSSProperties
    {
        return getOrCompute(this, this.props.style);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    click()
    {
        if (this.props.onClick)
        {
            this.props.onClick(this);
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
