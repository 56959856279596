import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import Selectbox from '../../../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import search from '../../../../../../../../@Util/String/search';
import { Field } from '../../Model/Field';
import { runInAction } from 'mobx';
import useTypes from '../../../../../../Entity/Type/Api/useTypes';

export interface FieldItemProps
{
    entityType: EntityType;
    field: Field;
}

const TypeSelector: React.FC<FieldItemProps> =
    props =>
    {
        const types = useTypes();
        const mainTypeSet =
            useMemo(
                () => new Set([
                    types.Relationship.Organization.Type,
                    types.Relationship.Person.Type,
                    types.Activity.Type,
                    types.CustomEntity.Type,
                    types.Product.Type,
                    types.ProductLine.Type,
                    types.TimeRegistrationActivity.Type,
                    types.TimeRegistration.Type,
                    types.Address.Type,
                ]
                    .filter(
                        type =>
                            type !== undefined
                    )
                ),
                [
                    types
                ]);
        const loader =
            useCallback(
                (query: string) =>
                    Promise.resolve<EntityType[]>(
                        Array.from(mainTypeSet)
                            .map(
                                type =>
                                    type.getAllTypes(false, true))
                            .reduce((a, b) => a.concat(b), [ types.Relationship.Type ])
                            .filter(
                                type =>
                                    search(query, type.getName()))),
                [
                    types
                ]);

        const formatter =
            useCallback(
                (type: EntityType) =>
                {
                    const parentTypeInMainSet =
                        type.getInheritedTypes()
                            .find(
                                checkType =>
                                    mainTypeSet.has(checkType));

                    return `${parentTypeInMainSet && parentTypeInMainSet !== type ? `${parentTypeInMainSet.getName(false)}: ${type.getName(false).toLowerCase()}` : type.getName(false)}`;
                },
                [
                    mainTypeSet
                ]);

        const onChange =
            useCallback(
                (dataType: EntityType) =>
                    runInAction(
                        () =>
                        {
                            props.field.dataType = dataType;

                            if (!props.field.name)
                            {
                                props.field.name = dataType.getNameDataObject(false).value;
                            }

                            if (!props.field.inverseName)
                            {
                                props.field.inverseName = props.entityType.getNameDataObject(false).value;
                            }
                        }),
                [
                    props.field,
                    props.entityType
                ]);

        if (props.field.isEditable && props.field.isNew)
        {
            return <Selectbox
                load={loader}
                onChange={onChange}
                formatOption={formatter}
                idResolver={formatter}
                value={props.field.dataType}
                autoFocus={props.field.fieldPath === undefined}
                disabled={!props.field.isEditable}
            />;
        }
        else
        {
            return <div>
                {formatter(props.field.dataType)}
            </div>;
        }
    };

export default observer(TypeSelector);
