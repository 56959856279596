import React, { useState } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import performAction from '../../../../../../../../@Api/Entity/performAction';
import PrimaryButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import Checkbox from '../../../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';

export interface CourseLessonCronPatternEditorProps
{
    entity: Entity;
}

const CourseLessonGenerator: React.FC<CourseLessonCronPatternEditorProps> =
    ({
         entity,
     }) =>
    {
        const [areHolidaysIgnored, setAreHolidaysIgnored] = useState(false);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <ViewGroupItem>
                <Input
                    label={
                        <LocalizedText
                            code="Activity.Course.GenerateLessons.IgnoreHolidays"
                            value="Geen rekening houden met vakantie"
                        />
                    }
                    labelPosition="right"
                >
                    <Checkbox
                        checked={areHolidaysIgnored}
                        onToggle={
                            isChecked =>
                                setAreHolidaysIgnored(isChecked)
                        }
                    />
                </Input>
            </ViewGroupItem>
            <ViewGroupItem>
                <PrimaryButton
                    label={
                        <LocalizedText
                            code="Activity.Course.GenerateLessons"
                            value="Lessen aanmaken volgens planning"
                        />
                    }
                    onClick={
                        () =>
                            performAction(
                                entity,
                                {
                                    code: 'Activity.Course.GenerateLessons',
                                    parameters: {
                                        areHolidaysIgnored,
                                    }
                                }
                            )
                    }
                    fullWidth
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(CourseLessonGenerator);
