import React, { useCallback, useContext, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../@Future/Component/Generic/Menu/Item/Item';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import styles from './Details.module.scss';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import RouterContext from '../../../../../../@Service/Router/RouterContext';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import Checkbox from '../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import DetailsLayout from './DetailsLayout/DetailsLayout';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';

export interface DetailsProps
{
    entity: Entity;
    commitContext?: CommitContext;
    defaultInEditMode?: boolean;
    appendix?: React.ReactNode;
    hidden?: (fieldPath: EntityFieldPath) => boolean;
    readOnly?: boolean;
}

const Details: React.FC<DetailsProps> =
    props =>
    {
        const routerStore = useContext(RouterContext);
        const currentUserStore = useContext(CurrentUserContext);

        const addNewField =
            useCallback(
                () =>
                    routerStore.route(`/type/${props.entity.entityType.id}/configuration`),
                [
                    routerStore,
                    props.entity
                ]);

        const [ isInEditMode, setInEditMode ] = useState(props.defaultInEditMode || false);

        const menuButton =
            <MenuButton>
                <Menu>
                    <Item
                        name={
                            <LocalizedText
                                code="Metadata.AddField"
                                value="Nieuw veld toevoegen"
                            />
                        }
                        onClick={addNewField}
                    />
                </Menu>
                {
                    currentUserStore.isSupport &&
                        <>
                            <Divider />
                            <CardInset
                                vertical={false}
                            >
                                <Input
                                    labelPosition="right"
                                    label={
                                        <LocalizedText
                                            code="Metadata.ConfigurationMode"
                                            value="Configuratiemodus"
                                        />
                                    }
                                >
                                    <Checkbox
                                        checked={isInEditMode}
                                        onToggle={setInEditMode}
                                    />
                                </Input>
                            </CardInset>
                        </>
                }
            </MenuButton>;

        const header =
            <div
                className={styles.header}
            >
                <CardHeader>
                    <LocalizedText
                        code="Generic.Details"
                        value="Details"
                    />
                </CardHeader>
                <div
                    className={styles.menu}
                >
                    {menuButton}
                </div>
            </div>;

        const closeEditMode =
            useCallback(
                () =>
                    setInEditMode(false),
                [
                    setInEditMode
                ]);

        const fields =
            <DetailsLayout
                entity={props.entity}
                isInEditMode={isInEditMode}
                onCloseEditMode={closeEditMode}
                commitContext={props.commitContext}
                hidden={props.hidden}
                readOnly={props.readOnly}
            />;

        return <Card>
            <CardInset
                bottom={false}
            >
                {header}
            </CardInset>
            {fields}
            {props.appendix}
        </Card>;
    };

export default observer(Details);
