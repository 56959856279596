import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityFieldPath } from '../@Model/EntityFieldPath';
import { EntityContext } from '../../@Model/EntityContext';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import FieldPathBrowser from './FieldBrowser/FieldPathBrowser';
import styles from './FieldPathSelector.module.scss';
import { ContextVariable } from '../../@Model/ContextVariable';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';

export interface FieldPathEditorProps
{
    context: EntityContext;
    onSelect: (fieldPath: EntityFieldPath, parameter?: string) => void;
    value?: EntityFieldPath;
    onSelectVariable?: (variable: ContextVariable) => void;
    parameterId?: string;
    filter?: (fieldPath: EntityFieldPath) => boolean;
}

const FieldPathSelector: React.FC<FieldPathEditorProps> =
({
    context,
    onSelect,
    value,
    onSelectVariable,
    filter,
}) =>
    {
        const parameters = useMemo(
            () =>
            [
                undefined,
                ...Array.from(context.contextByParameter.keys())
            ],
            [
                context
            ]
        );

        const [ selectedParameter, setSelectedParameter ] = useState<string | undefined>();
        const [ showVariables, setShowVariables ] = useState(false);
        const [ showLinks, setShowLinks ] = useState(false);

        const selectParameter =
            useCallback(
                (parameter: string | undefined) =>
                {
                    setSelectedParameter(parameter);
                    setShowVariables(false);
                    setShowLinks(false);
                },
                [
                    setSelectedParameter,
                    setShowVariables,
                    setShowLinks
                ]);

        const variablesClicked = useCallback(
            () =>
            {
                setSelectedParameter(undefined);
                setShowVariables(true);
                setShowLinks(false);
            },
            [
                setSelectedParameter,
                setShowVariables,
                setShowLinks
            ]
        );

        const linksClicked = useCallback(
            () =>
            {
                setSelectedParameter(undefined);
                setShowVariables(false);
                setShowLinks(true);
            },
            [
                setSelectedParameter,
                setShowVariables,
                setShowLinks
            ]
        );

        const onSelectCallback =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                {
                    onSelect(fieldPath, selectedParameter);
                },
                [
                    selectedParameter,
                    onSelect
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={0}
            className={styles.root}
        >
            {
                parameters.length > 1 &&
                    <ViewGroupItem
                        className={styles.menu}
                    >
                        <Menu>
                            {
                                parameters.map(
                                    parameter =>
                                    {
                                        const currentContext = context.getContext(parameter);
                                        const name = currentContext.alias
                                            ? currentContext.alias
                                            : currentContext.entityPath.entityType.getName();

                                        return <Item
                                            key={parameter || 'undefined'}
                                            name={name}
                                            onClick={() => selectParameter(parameter)}
                                            active={parameter === selectedParameter}
                                        />
                                    })
                            }
                            {
                                context.variables && context.variables.filter(v => !v.isLink).length > 0 &&
                                <Item
                                    key="variables"
                                    name={
                                        <LocalizedText
                                            code="FieldPathEditor.Variables"
                                            value="Variabelen"
                                        />
                                    }
                                    onClick={variablesClicked}
                                    active={showVariables}
                                />
                            }
                            {
                                context.variables && context.variables.filter(v => v.isLink).length > 0 &&
                                <Item
                                    key="links"
                                    name={
                                        <LocalizedText
                                            code="FieldPathEditor.Links"
                                            value="Links"
                                        />
                                    }
                                    onClick={linksClicked}
                                    active={showLinks}
                                />
                            }
                        </Menu>
                    </ViewGroupItem>
            }
            <ViewGroupItem
                ratio={1}
                className={styles.browserContainer}
            >
                {
                    showVariables
                        ?
                            <Menu>
                                {
                                    context.variables
                                        .filter(v => !v.isLink)
                                        .map(
                                            (variable, idx) =>
                                                <Item
                                                    key={idx}
                                                    name={variable.name}
                                                    onClick={() => onSelectVariable(variable)}
                                                    classes={{
                                                        label: styles.fieldItemLabel
                                                    }}
                                                />
                                        )
                                }
                            </Menu>
                        : showLinks
                            ?
                                <Menu>
                                    {
                                        context.variables
                                            .filter(v => v.isLink)
                                            .map(
                                                (variable, idx) =>
                                                    <Item
                                                        key={idx}
                                                        name={variable.name}
                                                        onClick={() => onSelectVariable(variable)}
                                                        classes={{
                                                            label: styles.fieldItemLabel
                                                        }}
                                                    />
                                            )
                                    }
                                </Menu>
                            :
                                <FieldPathBrowser
                                    path={value?.path || context.getContext(selectedParameter).entityPath}
                                    onSelect={onSelectCallback}
                                    filter={filter}
                                />
                }
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(FieldPathSelector);
