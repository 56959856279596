import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TabBar from '../../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import { default as ViewModel } from '../../../../../View/Model/View';
import Specification from '../../../../../View/Model/Specification';
import { default as ListModel } from '../../../../../View/Model/Specification/List';
import Column from '../../../../../View/Model/Specification/Column';
import { v4 as uuid } from 'uuid';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import List from '../../../../../View/List/List';
import { Comparator } from '../../../../../../DataObject/Model/Comparator';
import { DataObject } from '../../../../../../DataObject/Model/DataObject';
import { LogicalOperator } from '../../../../../../DataObject/Model/LogicalOperator';
import useCampaignEmailStateCount from '../Hooks/useCampaignEmailStateCount';
import useCampaignPhaseCode from '../Hooks/useCampaignPhaseCode';
import moment from 'moment';
import PrimaryButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import getDatastoreByCode from '../../../../../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import LocalizerContext from '../../../../../../../../@Service/Localization/LocalizerContext';
import { default as DatasetModel } from '../../../../../Dataset/Model/Dataset';
import Segment from '../../../../../Dataset/Model/Segment';
import Dataset from '../../../../../Dataset/Dataset';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import getViewParameters from '../../../../../View/Api/getViewParameters';
import CompositePredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import ComparisonPredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../../../../View/Model/ViewParams';
import EntityValue from '../../../../../../../../@Api/Automation/Value/EntityValue';
import PrimitiveValue from '../../../../../../../../@Api/Automation/Value/PrimitiveValue';
import { CommitBuilder } from '../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import Header from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import ExpansionPanel from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { CampaignResultCharts } from './CampaignResultCharts';
import useIsMobile from '../../../../../../../../@Util/Responsiveness/useIsMobile';

export interface CampaignResultsProps
{
    entity: Entity;
}

const CampaignResults: React.FC<CampaignResultsProps> =
    props =>
    {
        const campaign = props.entity;
        const types = useTypes();
        const [ tab, setTab ] = useState(1);
        const localizer = useContext(LocalizerContext);
        const isMobile = useIsMobile();

        const statesByTab =
            useMemo(
                () =>
                {
                    return {
                        queue: [
                            types.Activity.Email.State.Sent
                        ],
                        delivered:[
                            types.Activity.Email.State.Delivered,
                            types.Activity.Email.State.Opened,
                            types.Activity.Email.State.Clicked,
                            types.Activity.Email.State.Unsubscribed
                        ],
                        opened:[
                            types.Activity.Email.State.Opened,
                            types.Activity.Email.State.Clicked,
                            types.Activity.Email.State.Unsubscribed
                        ],
                        clicked:[
                            types.Activity.Email.State.Clicked
                        ],
                        bounced:[
                            types.Activity.Email.State.Bounced,
                            types.Activity.Email.State.Blocked,
                        ],
                        spam:[
                            types.Activity.Email.State.Spam
                        ],
                        unsubscribed:[
                            types.Activity.Email.State.Unsubscribed
                        ],
                        sent: [
                            types.Activity.Email.State.Sent,
                            types.Activity.Email.State.Delivered,
                            types.Activity.Email.State.Opened,
                            types.Activity.Email.State.Clicked,
                            types.Activity.Email.State.Unsubscribed,
                            types.Activity.Email.State.Spam,
                            types.Activity.Email.State.Bounced,
                            types.Activity.Email.State.Blocked,
                        ],
                        undelivered: [
                            types.Activity.Email.State.Bounced,
                            types.Activity.Email.State.Blocked,
                            types.Activity.Email.State.Spam
                        ]
                    };
                },
                [
                    types
                ]);

        const phaseCodes =
            useMemo(
                () =>
                {
                    switch (tab)
                    {
                        case 0: return statesByTab.sent
                        case 1: return statesByTab.delivered;
                        case 2: return statesByTab.queue;
                        case 3: return statesByTab.undelivered;
                        case 4: return statesByTab.opened;
                        case 5: return statesByTab.clicked;
                        case 6: return statesByTab.unsubscribed;
                        case 7: return statesByTab.spam;
                        case 8: return statesByTab.bounced;
                    }
                },
                [
                    tab,
                    statesByTab
                ]);

        const queueCount = useCampaignEmailStateCount(campaign, statesByTab.queue);
        const deliveredCount = useCampaignEmailStateCount(campaign, statesByTab.delivered);
        const openedCount = useCampaignEmailStateCount(campaign, statesByTab.opened);
        const clickedCount = useCampaignEmailStateCount(campaign, statesByTab.clicked);
        const bouncedCount = useCampaignEmailStateCount(campaign, statesByTab.bounced);
        const spamCount = useCampaignEmailStateCount(campaign, statesByTab.spam);
        const unsubscribedCount = useCampaignEmailStateCount(campaign, statesByTab.unsubscribed);
        const sentCount = useCampaignEmailStateCount(campaign, statesByTab.sent);
        const undeliveredCount = useCampaignEmailStateCount(campaign, statesByTab.undelivered);

        const view =
            useComputed(
                () =>
                {
                    const viewParameters = getViewParameters(types.Activity.Email.Type);
                    return new ViewModel(
                        'List',
                        undefined,
                        types.Activity.Email.Type,
                        viewParameters,
                        new CompositePredicate(
                            LogicalOperator.And,
                            [
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        viewParameters.getParameterById(ViewParams.Entity),
                                        EntityPath.fromEntityType(types.Activity.Email.Type)
                                            .joinTo(
                                                types.Activity.Campaign.RelationshipDefinition.Email,
                                                true)
                                            .field()),
                                    Comparator.Equals,
                                    new EntityValue(campaign)),
                                new CompositePredicate(
                                    LogicalOperator.Or,
                                    phaseCodes.map(
                                        phaseCode =>
                                            new ComparisonPredicate(
                                                new ValueFromEntityComputation(
                                                    viewParameters.getParameterById(ViewParams.Entity),
                                                    EntityPath.fromEntityType(types.Activity.Email.Type)
                                                        .joinTo(
                                                            types.Activity.Email.RelationshipDefinition.Phase,
                                                            false)
                                                        .field(types.Datastore.Field.Code)),
                                                Comparator.Equals,
                                                new PrimitiveValue(
                                                    DataObject.constructFromTypeIdAndValue(
                                                        'Text',
                                                        phaseCode)))))
                            ]),
                        new Specification(
                            new ListModel(
                                [
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(types.Activity.Email.Type)
                                            .joinTo(
                                                types.Activity.Email.RelationshipDefinition.To,
                                                false)
                                            .joinTo(
                                                types.Recipient.RelationshipDefinition.Addressee,
                                                false)
                                            .field()),
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(types.Activity.Email.Type)
                                            .joinTo(
                                                types.Activity.Email.RelationshipDefinition.To,
                                                false)
                                            .field(types.Recipient.Email.Field.EmailAddress)),
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(types.Activity.Email.Type)
                                            .field(types.Activity.Field.Subject)),
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(types.Activity.Email.Type)
                                            .field(types.Activity.Email.Field.SendDate)),
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(types.Activity.Email.Type)
                                            .field(types.Activity.Email.Field.IsHardBounced),
                                        tab !== 4)
                                ],
                                []
                            )
                        )
                    )
                },
                [
                    types,
                    campaign,
                    phaseCodes,
                    tab
                ]);

        const dateScheduled =
            useComputed(
                () =>
                    campaign
                        .getDataObjectValueByField(types.Activity.Campaign.Field.DateScheduled)
                        .value,
                [
                    campaign,
                    types
                ]);

        const phaseCode = useCampaignPhaseCode(campaign);

        const [ currentTime, setCurrentTime ] = useState(new Date());
        const [ timeRemaining, setTimeRemaining ] = useState<string>();

        useEffect(
            () =>
            {
                const interval =
                    setInterval(
                        () =>
                        {
                            setCurrentTime(new Date());
                            setTimeRemaining(localizer.formatDateTimeFromNow(dateScheduled));
                        },
                        1000);

                return () => clearInterval(interval);
            },
            [
                setCurrentTime,
                setTimeRemaining,
                dateScheduled
            ]);

        const isPlanned =
            useMemo(
                () =>
                    phaseCode === 'Planned'
                    && (dateScheduled && dateScheduled > currentTime),
                [
                    phaseCode,
                    currentTime,
                    dateScheduled
                ]);

        const isPlannedWithSafetyMargin =
            useMemo(
                () =>
                    phaseCode === 'Planned'
                    && (dateScheduled && dateScheduled > moment(currentTime).add(1, 'minutes').toDate()),
                [
                    phaseCode,
                    currentTime,
                    dateScheduled
                ]);

        const editCampaign =
            useCallback(
                () =>
                    getDatastoreByCode(types.Datastore.Phase.ActivityCampaign.Type, 'Concept')
                        .then(
                            phase =>
                                new CommitBuilder()
                                    .relateEntityTo(
                                        props.entity,
                                        false,
                                        types.Activity.Campaign.RelationshipDefinition.Phase,
                                        phase
                                    )
                                    .setObjectValueInEntity(
                                        props.entity,
                                        types.Activity.Campaign.Field.DateScheduled,
                                        undefined
                                    )
                                    .commit()
                        ),
                [
                    props.entity,
                    types
                ]);

        const emailClickDataset =
            useMemo(
                () =>
                {
                    const datasetParameters = getViewParameters(types.EmailClick.Type);

                    return new DatasetModel(
                        types.EmailClick.Type,
                        datasetParameters.getParameterById(ViewParams.Entity),
                        datasetParameters,
                        [
                            new Segment(
                                uuid(),
                                'List',
                                EntityPath.fromEntityType(types.EmailClick.Type)
                                    .field(types.EmailClick.Field.LinkUrl))
                        ],
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                datasetParameters.getParameterById(ViewParams.Entity),
                                EntityPath.fromEntityType(types.EmailClick.Type)
                                    .joinTo(
                                        types.Activity.Email.RelationshipDefinition.Clicks,
                                        true)
                                    .joinTo(
                                        types.Activity.Campaign.RelationshipDefinition.Email,
                                        true)
                                    .field()),
                            Comparator.Equals,
                            new EntityValue(props.entity)));
                },
                [
                    types,
                    props.entity
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <ViewGroupItem>
                {
                    isMobile
                        ?
                            <Card>
                                <ExpansionPanel
                                    id="resultCharts"
                                    summary={
                                        <Header
                                            large
                                            inset
                                            title={
                                                <LocalizedText
                                                    code="Campaign.Overview"
                                                    value="Overzicht"
                                                />
                                            }
                                        />
                                    }
                                    expansion={
                                        <CardInset>
                                            <CampaignResultCharts
                                                campaign={campaign}
                                            />
                                        </CardInset>
                                    }
                                />
                            </Card>
                        :
                            <Card
                                inset
                            >
                                <CampaignResultCharts
                                    campaign={campaign}
                                />
                            </Card>
                }
            </ViewGroupItem>
            {
                isPlanned &&
                <ViewGroupItem>
                    <Card
                        inset
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={10}
                            justification="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                                alignment="center"
                            >
                                <LocalizedText
                                    code="Campaign.ScheduledFor"
                                    value="Deze campagne is ingepland op"
                                />
                                &nbsp;<b>{localizer.formatDateTime(dateScheduled)}</b>.
                                {
                                    timeRemaining &&
                                    <>
                                        &nbsp;
                                        <LocalizedText
                                            code="Campaign.ThatIs"
                                            value="Dat is"
                                        />
                                        &nbsp;<b>{timeRemaining}</b>.
                                    </>
                                }
                            </ViewGroupItem>
                            {
                                isPlannedWithSafetyMargin &&
                                <ViewGroupItem
                                    ratio={1}
                                    alignment="center"
                                >
                                    <PrimaryButton
                                        label={
                                            <LocalizedText
                                                code="Campaign.DeactivateAndModify"
                                                value="Campagne deactiveren en aanpassen"
                                            />
                                        }
                                        onClick={editCampaign}
                                    />
                                </ViewGroupItem>
                            }
                        </ViewGroup>
                    </Card>
                </ViewGroupItem>
            }
            <ViewGroupItem>
                <Card>
                    <ExpansionPanel
                        id="Results"
                        summary={
                            <Header
                                large
                                inset
                                title={
                                    <LocalizedText
                                        code="Campaign.Results"
                                        value="Resultaten"
                                    />
                                }
                            />
                        }
                        expanded={true}
                        expansion={
                            <CardInset
                                top={false}
                                left={false}
                                right={false}
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={10}
                                >
                                    <ViewGroupItem>
                                        <Card style={{border: 0}}>
                                            <ViewGroup
                                                orientation="vertical"
                                                spacing={0}
                                            >
                                                <ViewGroupItem>
                                                    <TabBar
                                                        value={queueCount === 0 && tab === 0 ? 1 : tab}
                                                    >
                                                        <Tab
                                                            value={0}
                                                            onClick={setTab}
                                                        >
                                                            <LocalizedText
                                                                code="Campaign.SentCount"
                                                                value="Verzonden (${sentCount})"
                                                                sentCount={sentCount}
                                                            />
                                                        </Tab>
                                                            <Tab
                                                                value={1}
                                                                onClick={setTab}
                                                            >
                                                                <LocalizedText
                                                                    code="Campaign.DeliveredCount"
                                                                    value="Bezorgd (${deliveredCount})"
                                                                    deliveredCount={deliveredCount}
                                                                />
                                                            </Tab>
                                                        {
                                                            queueCount > 0 &&
                                                            <Tab
                                                                value={2}
                                                                onClick={setTab}
                                                            >
                                                                <LocalizedText
                                                                    code="Campaign.InProcess"
                                                                    value="In behandeling (${queueCount})"
                                                                    queueCount={queueCount}
                                                                />
                                                            </Tab>
                                                        }
                                                        <Tab
                                                            value={3}
                                                            onClick={setTab}
                                                        >
                                                            <LocalizedText
                                                                code="Campaign.UndeliveredCount"
                                                                value="Onbezorgd (${undeliveredCount})"
                                                                undeliveredCount={undeliveredCount}
                                                            />
                                                        </Tab>
                                                        <Tab
                                                            value={4}
                                                            onClick={setTab}
                                                        >
                                                            <LocalizedText
                                                                code="Campaign.OpenedCount"
                                                                value="Geopend (${openedCount})"
                                                                openedCount={openedCount}
                                                            />
                                                        </Tab>
                                                        <Tab
                                                            value={5}
                                                            onClick={setTab}
                                                        >
                                                            <LocalizedText
                                                                code="Campaign.ClickedCount"
                                                                value="Aangeklikt (${clickedCount})"
                                                                clickedCount={clickedCount}
                                                            />
                                                        </Tab>
                                                        <Tab
                                                            value={6}
                                                            onClick={setTab}
                                                        >
                                                            <LocalizedText
                                                                code="Campaign.UnsubscribedCount"
                                                                value="Uitgeschreven (${unsubscribedCount})"
                                                                unsubscribedCount={unsubscribedCount}
                                                            />
                                                        </Tab>
                                                        <Tab
                                                            value={7}
                                                            onClick={setTab}
                                                        >
                                                            <LocalizedText
                                                                code="Campaign.SpamCount"
                                                                value="Spam (${spamCount})"
                                                                spamCount={spamCount}
                                                            />
                                                        </Tab>
                                                        <Tab
                                                            value={8}
                                                            onClick={setTab}
                                                        >
                                                            <LocalizedText
                                                                code="Campaign.Failed"
                                                                value="Mislukt (${bouncedCount})"
                                                                bouncedCount={bouncedCount}
                                                            />
                                                        </Tab>
                                                    </TabBar>
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <List
                                                        view={view}
                                                        readonly
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </Card>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </CardInset>
                        }
                    />
                </Card>
            </ViewGroupItem>
            <ViewGroupItem>
                <Dataset
                    id="click"
                    name={localizeText('Campaign.ClickBehavior', 'Klikgedrag')}
                    disableExpansionMemory
                    dataset={emailClickDataset}
                    whiteCard
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(CampaignResults);
