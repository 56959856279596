import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import { EntityConstraintBuilderFilterContext } from '../Model/EntityConstraintBuilderFilterContext';

export function isResultParameter(
    parameter: Parameter<EntityValueType>,
    context: EntityConstraintBuilderFilterContext
): boolean
{
    return context.parameter.id === parameter.id
        || (context.parent ? isResultParameter(parameter, context.parent) : false);
}
