import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../../../@Framework/Component/BaseComponent';
import * as React from 'react';
import { ToolbarColorPickerStore } from './ToolbarColorPickerStore';
import { ColorPicker } from '../../../../ColorPicker/ColorPicker';

@observer
export class ToolbarColorPicker extends BaseComponent<ToolbarColorPickerStore>
{
    renderComponent(store: ToolbarColorPickerStore)
    {
        return <div
            style={{
                width: '25px',
                marginLeft: 5,
                marginRight: 5,
                display: 'inline-block',
                verticalAlign: 'bottom',
                height: 27
            }}
        >
            <ColorPicker store={store.colorPickerStore}  />
        </div>;
    }
}
