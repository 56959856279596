import { createContext } from 'react';
import { Entity } from '../../Model/Implementation/Entity';
import ParameterDictionary from '../../Automation/Parameter/ParameterDictionary';

export interface PortalContextValue
{
    portal: Entity;
    parameters: ParameterDictionary;
}

export default createContext<PortalContextValue>(undefined);
