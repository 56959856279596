import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { Setting } from '../../Model/Implementation/Setting';

export class SettingController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };

    saveOrganizationSetting(
        id: number,
        code: string,
        value: any,
        file?: File,
        logChanges: boolean = false
    ): Promise<Setting>
    {
        return new Promise<Setting>(
            (resolve, reject) =>
            {
                const formData = new FormData();

                if (id)
                {
                    formData.append('id', id.toString());
                }

                formData.append('code', code);

                if (value != null)
                {
                    formData.append('value', JSON.stringify(value));
                }

                if (file)
                {
                    formData.append('file', file);
                }

                if (logChanges)
                {
                    formData.append('logChanges', logChanges.toString());
                }

                this.apiClient.request(
                    new ApiRequest<any>(
                        '/setting/organization/save',
                        Method.Post,
                        formData,
                        {
                            enctype: 'multipart/form-data',
                        },
                        false,
                        undefined,
                        undefined,
                        undefined,
                        true
                    )
                )
                    .then(result =>
                    {
                        resolve(fromJson(result, Setting));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

    saveUserSetting(id: number, code: string, value: any): Promise<Setting>
    {
        return new Promise<Setting>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/setting/user/save', Method.Post, { id: id, code: code, value: value }))
                    .then(result =>
                    {
                        resolve(fromJson(result, Setting));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
}