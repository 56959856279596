import useSpotlerCampaignResultCount from '../Hooks/useSpotlerCampaignResultViewCount';
import useTypes from '../../../../../../../../../../../Type/Api/useTypes';
import { Entity } from '../../../../../../../../../../../../../../@Api/Model/Implementation/Entity';
import localizeText from '../../../../../../../../../../../../../../@Api/Localization/localizeText';
import NumberChart from './NumberChart';
import React from 'react';
import { observer } from 'mobx-react-lite';

export interface SpotlerCampaignResultChartBouncedProps
{
    size: number;
    campaignResult: Entity;
}

export const SpotlerCampaignResultChartBounced: React.FC<SpotlerCampaignResultChartBouncedProps> =
    observer(
        (
            {
                campaignResult,
                size
            }

        ) =>
        {
            const types = useTypes();
            const bounceCount =
                useSpotlerCampaignResultCount(
                    campaignResult,
                    types.SpotlerCampaignEvent.EventType.Bounce
                );

            return <div>
                <NumberChart
                    value={bounceCount}
                    size={size}
                    label={localizeText('Campaign.Chart.Undeliverable', 'Onbezorgbaar')}
                />
            </div>;
        }
    );