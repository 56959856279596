import { DataObjectBespokeEditorStore } from '../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { action, computed, observable, runInAction } from 'mobx';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';
import { DialogStore } from '../../../../Generic/Dialog/Type/DialogStore';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import JsonEditor from '../../../../../@Future/Component/Generic/Input/JsonEditor/JsonEditor';
import { ReactViewComponent } from '../../../../Generic/ViewStack/Model/ReactViewComponent';
import { consoleLog } from '../../../../../@Future/Util/Logging/consoleLog';

export class ComplexEditorStore extends DataObjectBespokeEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get hasValue(): boolean
    {
        return this.value !== undefined;
    }

    @computed
    get value(): any
    {
        return this.baseStore.dataObject.value;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get btnEditStore(): ButtonStore
    {
        return new ButtonStore(
            {
                onClick: this.showEditor,
                label: () => this.localizationStore.translate('Generic.Edit') // Edit
            });
    }

    @computed
    get btnClearStore(): ButtonStore
    {
        return new ButtonStore(
            {
                onClick: this.clearValue,
                label: this.localizationStore.translate('Generic.Delete'), // Delete,
                color: 'red'
            });
    }

    @computed
    get btnCreateStore(): ButtonStore
    {
        return new ButtonStore(
            {
                onClick: this.showEditor,
                label: this.localizationStore.translate('Generic.Create'), // Create,
                isRaised: true
            });
    }

    @computed
    get dialogStore(): DialogStore
    {
        const jsonValue = observable.box(this.baseStore.dataObject.value);

        return new DialogStore({
            title: this.localizationStore.translate('Editor'), // Editor
            isOpen: false,
            buttons: [
                new ButtonStore(
                    {
                        onClick: this.closeEditor,
                        label: this.localizationStore.translate('Generic.Cancel') // Cancel
                    }),
                new ButtonStore(
                    {
                        onClick: () => this.baseStore.changeValue(jsonValue.get()),
                        label: this.localizationStore.translate('Generic.Save') // Save
                    }),
            ],
            onClose: () => undefined,
            viewComponent:
                () =>
                    new ReactViewComponent(
                        JsonEditor,
                        {
                            json: jsonValue.get(),
                            onChange: value => consoleLog(value) || runInAction(() => jsonValue.set(value))
                        }),
            isFullWidth: false,
            maxWidth: 'xl'
        });
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    showEditor()
    {
        this.dialogStore.show();
    }

    @action.bound
    closeEditor()
    {
        this.dialogStore.hide();
    }

    @action.bound
    clearValue()
    {
        this.baseStore.dataObject.setValue(undefined);
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
