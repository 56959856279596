import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import { runInAction } from 'mobx';
import LayoutEditor from '../../../../../../../../Layout/Editor/LayoutEditor';
import Layout from '../../../../../../../../../../@Api/Layout/Layout';
import PdfComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/PdfComputation';
import InputGroup from '../../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TabBar from '../../../../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import NumberEditor from '../../../../../../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';

export interface PdfComputationEditorProps extends ComputationEditorProps<ValueType<any>, PdfComputation>
{

}

const PdfComputationEditor: React.FC<PdfComputationEditorProps> =
    props =>
    {
        const setHeader =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.value.header = layout),
                [
                    props.value
                ]);

        const setBody =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.value.body = layout),
                [
                    props.value
                ]);

        const setFooter =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.value.footer = layout),
                [
                    props.value
                ]);

        const setMarginTop =
            useCallback(
                (value: number) =>
                    runInAction(
                        () =>
                            props.value.marginTop = value),
                [
                    props.value
                ]);

        const setMarginRight =
            useCallback(
                (value: number) =>
                    runInAction(
                        () =>
                            props.value.marginRight = value),
                [
                    props.value
                ]);

        const setMarginBottom =
            useCallback(
                (value: number) =>
                    runInAction(
                        () =>
                            props.value.marginBottom = value),
                [
                    props.value
                ]);

        const setMarginLeft =
            useCallback(
                (value: number) =>
                    runInAction(
                        () =>
                            props.value.marginLeft = value),
                [
                    props.value
                ]);

        const setFileName =
            useCallback(
                (fileName: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.fileName = fileName),
                [
                    props.value
                ]);

        const [ tab, setTab ] = useState<'Header' | 'Body' | 'Footer' | 'Settings'>('Body');

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <TabBar
                    value={tab}
                >
                    <Tab
                        onClick={setTab}
                        value="Body"
                    >
                        <LocalizedText
                            code="Generic.Body"
                            value="Inhoud"
                        />
                    </Tab>
                    <Tab
                        onClick={setTab}
                        value="Header"
                    >
                        <LocalizedText
                            code="Generic.Header"
                            value="Header"
                        />
                    </Tab>
                    <Tab
                        onClick={setTab}
                        value="Footer"
                    >
                        <LocalizedText
                            code="Generic.Footer"
                            value="Footer"
                        />
                    </Tab>
                    <Tab
                        onClick={setTab}
                        value="Settings"
                    >
                        <LocalizedText
                            code="Generic.Settings"
                            value="Instellingen"
                        />
                    </Tab>
                </TabBar>
            </ViewGroupItem>
            {
                tab === 'Body' &&
                    <ViewGroupItem>
                        <LayoutEditor
                            parameterDictionary={props.context.parameterDictionary}
                            parameterAssignment={props.context.parameterAssignment}
                            layout={props.value.body}
                            onChange={setBody}
                        />
                    </ViewGroupItem>
            }
            {
                tab === 'Header' &&
                    <ViewGroupItem>
                        <LayoutEditor
                            parameterDictionary={props.context.parameterDictionary}
                            parameterAssignment={props.context.parameterAssignment}
                            layout={props.value.header}
                            onChange={setHeader}
                        />
                    </ViewGroupItem>
            }
            {
                tab === 'Footer' &&
                    <ViewGroupItem>
                        <LayoutEditor
                            parameterDictionary={props.context.parameterDictionary}
                            parameterAssignment={props.context.parameterAssignment}
                            layout={props.value.footer}
                            onChange={setFooter}
                        />
                    </ViewGroupItem>
            }
            {
                tab === 'Settings' &&
                    <ViewGroupItem>
                        <InputGroup>
                            <Input
                                labelPosition="left"
                                label={
                                    <>
                                        <LocalizedText code="Generic.Margin" value="Marge" /> (<LocalizedText code="Generic.Top" value="Boven" />)
                                    </>
                                }
                            >
                                <NumberEditor
                                    value={props.value.marginTop}
                                    onChange={setMarginTop}
                                    min={0}
                                />
                            </Input>
                            <Input
                                labelPosition="left"
                                label={
                                    <>
                                        <LocalizedText code="Generic.Margin" value="Marge" /> (<LocalizedText code="Generic.Right" value="Rechts" />)
                                    </>
                                }
                            >
                                <NumberEditor
                                    value={props.value.marginRight}
                                    onChange={setMarginRight}
                                    min={0}
                                />
                            </Input>
                            <Input
                                labelPosition="left"
                                label={
                                    <>
                                        <LocalizedText code="Generic.Margin" value="Marge" /> (<LocalizedText code="Generic.Bottom" value="Beneden" />)
                                    </>
                                }
                            >
                                <NumberEditor
                                    value={props.value.marginBottom}
                                    onChange={setMarginBottom}
                                    min={0}
                                />
                            </Input>
                            <Input
                                labelPosition="left"
                                label={
                                    <>
                                        <LocalizedText code="Generic.Margin" value="Marge" /> (<LocalizedText code="Generic.Left" value="Links" />)
                                    </>
                                }
                            >
                                <NumberEditor
                                    value={props.value.marginLeft}
                                    onChange={setMarginLeft}
                                    min={0}
                                />
                            </Input>
                            <Input
                                labelPosition="left"
                                label={
                                    <LocalizedText
                                        code="Generic.FileName"
                                        value="Bestandsnaam"
                                    />
                                }
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={5}
                                    alignment="center"
                                >
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        <ComputationEditor
                                            context={props.context}
                                            value={props.value.fileName}
                                            onChange={setFileName}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        .pdf
                                    </ViewGroupItem>
                                </ViewGroup>
                            </Input>
                        </InputGroup>
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(PdfComputationEditor);
