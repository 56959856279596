import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../Type/Api/useTypes';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import DocumentTemplateField from './DocumentTemplateField';
import TabBar from '../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import Input from '../../../Input/Input';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import FileViewer from '../../../../../Generic/FileViewer/FileViewer';
import AuthenticationManagerContext from '../../../../../../@Service/Authentication/AuthenticationManagerContext';
import { default as GenericInput } from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import Selectbox from '../../../Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../../Selection/Builder/EntitySelectionBuilder';
import FontSelectbox from '../../../../../Generic/Font/FontSelectbox';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import EmailSubjectTemplateField from './EmailSubjectTemplateField';
import EntityAttachmentInput from '../../../Attachment/EntityAttachmentInput';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import { useNewCommitContext } from '../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { useCommittableEntity } from '../../../../../../@Api/Entity/Commit/Context/Api/useCommittableEntity';
import { usePackSelector } from '../../../../Configuration/Page/Packs/MySharedPackManager/Api/usePackSelector';
import { SharePackEditor } from '../../../../Configuration/Page/Packs/MySharedPackManager/SharePackEditor/SharePackEditor';
import useResults from '../../../Selection/Hooks/useResults';
import { getTemplateCategoryType } from '../../../../Configuration/TemplateBrowser/getTemplateCategoryType';
import { updateRelationship } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import useFetchedRelatedEntity from '../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity';
import EntityTypeContext from '../../../Type/EntityTypeContext';

export interface TemplateProps
{
    entity: Entity;
    commitContext?: CommitContext;
}

const Template: React.FC<TemplateProps> =
    props =>
    {
        const types = useTypes();
        const commitContext = useNewCommitContext(props.commitContext);
        const entity = useCommittableEntity(props.entity, commitContext);
        const authenticationManager = useContext(AuthenticationManagerContext);
        const apiClient = authenticationManager.apiClient;
        const entityTypeStore = useContext(EntityTypeContext);

        const hasFile =
            useComputed(
                () =>
                    entity.hasValueForField(
                        types.Template.Field.File,
                        commitContext
                    ),
                [
                    entity,
                    types,
                    commitContext,
                ]);

        const [ tab, setTab ] = useState(hasFile ? 3 : 0);
        const [ previewEntity, setPreviewEntity ] = useState<Entity | undefined>();

        const previewEntityType =
            useMemo(
                () =>
                {
                    if (entity.entityType.isA(types.Template.Document.Offer.Type))
                    {
                        return types.Activity.Offer.Type;
                    }
                    else if (entity.entityType.isA(types.Template.Document.Invoice.Type))
                    {
                        return types.Activity.Invoice.Type;
                    }
                    else if (entity.entityType.isA(types.Template.Document.DocumentActivity.Type))
                    {
                        return types.Activity.Document.Type;
                    }
                    else if (entity.entityType.isA(types.Template.Document.WorkOrder.Type))
                    {
                        return types.Activity.WorkOrder.Type;
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    types,
                    entity
                ]);

        const selections =
            useMemo(
                () =>
                    previewEntityType
                        ?
                            [
                                new EntitySelectionBuilder(previewEntityType)
                                    .selection
                            ]
                        :
                            [],
                [
                    previewEntityType
                ]);

        useEffect(
            () =>
            {
                if (previewEntityType)
                {
                    new EntitySelectionBuilder(previewEntityType)
                        .limit(1)
                        .select()
                        .then(
                            results =>
                            {
                                if (results.length > 0)
                                {
                                    setPreviewEntity(results[0].entity);
                                }
                            });
                }
            },
            [
                previewEntityType,
                setPreviewEntity
            ]);

        const previewDocumentUrl =
            useMemo(
                () =>
                {
                    if (previewEntityType && previewEntity && apiClient)
                    {
                        let url;

                        if (previewEntityType.isA(types.Activity.Offer.Type))
                        {
                            url = apiClient.url(`/entity/bespoke/offer/${previewEntity.id}/pdf?template_id=${entity.id}`);
                        }
                        else if (previewEntityType.isA(types.Activity.Invoice.Type))
                        {
                            url = apiClient.url(`/entity/bespoke/invoice/${previewEntity.id}/pdf?template_id=${entity.id}`);
                        }
                        else if (previewEntityType.isA(types.Activity.WorkOrder.Type))
                        {
                            url = apiClient.url(`/entity/bespoke/workorder/${previewEntity.id}/pdf?template_id=${entity.id}`);
                        }
                        else if (previewEntityType.isA(types.Activity.Document.Type))
                        {
                            url = apiClient.url(`/entity/bespoke/document/${previewEntity.id}/pdf?template_id=${entity.id}`);
                        }

                        return url;
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    previewEntityType,
                    previewEntity,
                    entity,
                    apiClient,
                    types
                ]);

        const setFont =
            useCallback(
                (font: string) =>
                {
                    setValueByFieldInEntity(
                        entity,
                        types.Template.Field.Font,
                        font,
                        commitContext
                    );

                    return commitEntityWithContext(
                        entity,
                        commitContext,
                        {
                            isForced: true
                        }
                    );
                },
                [
                    types,
                    entity,
                    commitContext
                ]);

        const font =
            useEntityValue<string>(
                entity,
                types.Template.Field.Font,
                undefined,
                commitContext
            );

        const [ isPackSelectorVisible, ownedShareAndEnvironmentPacks ] = usePackSelector(entity);

        const defaultTemplates =
            useResults(
                entity.entityType,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(types.Template.Field.IsDefault),
                                    undefined,
                                    true
                                )
                        ),
                [
                    entity,
                    types
                ]
            )

        const updateIsDefault =
            useCallback(
                () =>
                {
                    defaultTemplates?.forEach(
                        template =>
                            {
                                if (entity.getObjectValueByField(types.Template.Field.IsDefault) === true  && entity.id !== template.id)
                                {
                                    setValueByFieldInEntity(
                                        template,
                                        types.Template.Field.IsDefault,
                                        false,
                                        commitContext
                                    );
                                }
                            }
                    )
                },
                [
                    defaultTemplates,
                    entity,
                    types,
                    commitContext
                ]
            )

        const [ blockTemplateType, isLoadingBlockTemplateType ] =
            useFetchedRelatedEntity(
                entity.entityType.isA(types.Template.Block.Type)
                    ?
                    entity
                    :
                    undefined,
                types.Template.Block.RelationshipDefinition.TemplateType,
                false,
                commitContext
            );

        const categorySelections =
            useMemo(
                () =>
                {
                    if (entity.entityType.isA(types.Template.Block.Type))
                    {
                        if (blockTemplateType && !isLoadingBlockTemplateType)
                        {
                            const type = entityTypeStore.getTypeByEntityId(blockTemplateType.id)

                            return [
                                new EntitySelectionBuilder(getTemplateCategoryType(type))
                                    .build()
                            ]
                        }
                    }
                    else
                    {
                        return [
                            new EntitySelectionBuilder(getTemplateCategoryType(entity.entityType))
                                .build()
                        ]
                    }
                },
                [
                    entity,
                    types,
                    blockTemplateType,
                    isLoadingBlockTemplateType,
                    entityTypeStore
                ]
            );

        const setCategory =
            useCallback(
                (category) =>
                {
                    updateRelationship(
                        entity,
                        true,
                        types.TemplateCategory.RelationshipDefinition.Templates,
                        category,
                        commitContext);

                    commitEntityWithContext(
                        entity,
                        commitContext
                    ).then()

                },
                [
                    entity,
                    types,
                    commitContext
                ]
            )

        const category =
            useComputed(
                () =>
                {
                    return entity.getRelatedEntityByDefinition(
                        true,
                        types.TemplateCategory.RelationshipDefinition.Templates,
                        commitContext
                    );

                },
                [
                    entity,
                    commitContext,
                ]);

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                    md={4}
                    lg={3}
                    xl={3}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card
                                inset
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={15}
                                >
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        <TypeAndName
                                            entity={entity}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <MenuButton
                                            entity={entity}
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                                <InputGroup>
                                    <Input
                                        entity={entity}
                                        field={types.Template.Field.IsDefault}
                                        labelPosition="left"
                                        commitContext={commitContext}
                                        onChange={updateIsDefault}
                                    />
                                    <Input
                                        entity={entity}
                                        field={types.Template.Field.IsInactive}
                                        labelPosition="left"
                                        commitContext={commitContext}
                                    />
                                    {
                                        entity.entityType.isA(types.Template.Email.Type) &&
                                        <Input
                                            entity={entity}
                                            field={types.Template.Email.RelationshipDefinition.DefaultEmailSender}
                                            labelPosition="left"
                                            commitContext={commitContext}
                                        />
                                    }
                                    {
                                        entity.entityType.isA(types.Template.Document.Type) &&
                                            <>
                                                <Input
                                                    entity={entity}
                                                    field={types.Template.Document.Field.MarginTop}
                                                    labelPosition="left"
                                                    commitContext={commitContext}
                                                />
                                                <Input
                                                    entity={entity}
                                                    field={types.Template.Document.Field.MarginBottom}
                                                    labelPosition="left"
                                                    commitContext={commitContext}
                                                />
                                                <Input
                                                    entity={entity}
                                                    field={types.Template.Document.Field.MarginLeft}
                                                    labelPosition="left"
                                                    commitContext={commitContext}
                                                />
                                                <Input
                                                    entity={entity}
                                                    field={types.Template.Document.Field.MarginRight}
                                                    labelPosition="left"
                                                    commitContext={commitContext}
                                                />
                                            </>
                                    }
                                    {
                                        <GenericInput
                                            label={
                                                <LocalizedText
                                                    code="Generic.Category"
                                                    value="Categorie"
                                                />
                                            }
                                            labelPosition="left"
                                        >
                                            <Selectbox
                                                value={category}
                                                onChange={setCategory}
                                                selections={categorySelections}
                                                clearable
                                                commitContext={commitContext}
                                            />
                                        </GenericInput>
                                    }
                                    <GenericInput
                                        label={
                                            <LocalizedText
                                                code="Generic.Font"
                                                value="Lettertype"
                                            />
                                        }
                                        labelPosition="left"
                                    >
                                        <FontSelectbox
                                            onChange={setFont}
                                            value={font}
                                        />
                                    </GenericInput>
                                    <Input
                                        entity={entity}
                                        field={types.Template.RelationshipDefinition.Language}
                                        labelPosition="left"
                                        commitContext={commitContext}
                                    />
                                    <Input
                                        entity={entity}
                                        field={types.Template.Field.Code}
                                        labelPosition="left"
                                        commitContext={commitContext}
                                    />
                                    {
                                        isPackSelectorVisible &&
                                        <SharePackEditor
                                            entity={entity}
                                            ownedShareAndEnvironmentPacks={ownedShareAndEnvironmentPacks}
                                        />
                                    }
                                    {
                                        entity.entityType.isA(types.Template.Document.Offer.Type) &&
                                        <Input
                                            entity={entity}
                                            field={types.Template.Document.Offer.RelationshipDefinition.DefaultEmailTemplate}
                                            labelPosition="left"
                                            commitContext={commitContext}
                                        />
                                    }
                                    {
                                        entity.entityType.isA(types.Template.Document.Invoice.Type) &&
                                        <Input
                                            entity={entity}
                                            field={types.Template.Document.Invoice.RelationshipDefinition.DefaultEmailTemplate}
                                            labelPosition="left"
                                            commitContext={commitContext}
                                        />
                                    }
                                    {
                                        entity.entityType.isA(types.Template.Document.WorkOrder.Type) &&
                                        <Input
                                            entity={entity}
                                            field={types.Template.Document.WorkOrder.RelationshipDefinition.DefaultEmailTemplate}
                                            labelPosition="left"
                                            commitContext={commitContext}
                                        />
                                    }
                                </InputGroup>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={8}
                    lg={9}
                    xl={9}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        {
                            props.entity.entityType.isA(types.Template.Document.Type) &&
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <TabBar
                                        value={tab}
                                    >
                                        <Tab
                                            value={0}
                                            onClick={setTab}
                                            disabled={hasFile}
                                        >
                                            <LocalizedText
                                                code="Template.Content"
                                                value="Inhoud"
                                            />
                                        </Tab>
                                        {
                                            props.entity.entityType.isA(types.Template.Document.Type) &&
                                                <Tab
                                                    value={1}
                                                    onClick={setTab}
                                                    disabled={hasFile}
                                                >
                                                    <LocalizedText
                                                        code="Template.Header"
                                                        value="Header"
                                                    />
                                                </Tab>
                                        }
                                        {
                                            props.entity.entityType.isA(types.Template.Document.Type) &&
                                                <Tab
                                                    value={2}
                                                    onClick={setTab}
                                                    disabled={hasFile}
                                                >
                                                    <LocalizedText
                                                        code="Template.Footer"
                                                        value="Footer"
                                                    />
                                                </Tab>
                                        }
                                        {
                                            props.entity.entityType.isA(types.Template.Document.Type) &&
                                                <Tab
                                                    value={3}
                                                    onClick={setTab}
                                                >
                                                    Microsoft Word
                                                </Tab>
                                        }
                                        {
                                            previewEntityType &&
                                                <Tab
                                                    value={4}
                                                    onClick={setTab}
                                                >
                                                    <LocalizedText
                                                        code="Template.Preview"
                                                        value="Voorbeeld"
                                                    />
                                                </Tab>
                                        }
                                    </TabBar>
                                </Grid>
                        }
                        {
                            tab === 0 && props.entity.entityType.isA(types.Template.Email.Type) &&
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <EmailSubjectTemplateField
                                            entity={props.entity}
                                            field={types.Template.Email.Field.SubjectTemplate}
                                            commitContext={commitContext}
                                        />
                                    </Grid>
                                </>
                        }
                        <Grid
                            item
                            xs={12}
                        >
                            {
                                tab === 0 &&
                                    <DocumentTemplateField
                                        entity={entity}
                                        field={types.Template.Field.Content}
                                        commitContext={commitContext}
                                    />
                            }
                            {
                                tab === 1 &&
                                    <DocumentTemplateField
                                        entity={entity}
                                        field={types.Template.Document.Field.Header}
                                        commitContext={commitContext}
                                    />
                            }
                            {
                                tab === 2 &&
                                    <DocumentTemplateField
                                        entity={entity}
                                        field={types.Template.Document.Field.Footer}
                                        commitContext={commitContext}
                                    />
                            }
                            {
                                tab === 3 &&
                                    <Card
                                        inset
                                    >
                                        <Input
                                            entity={entity}
                                            field={types.Template.Field.File}
                                            labelPosition="top"
                                            commitContext={commitContext}
                                        />
                                    </Card>
                            }
                            {
                                tab === 4 && previewEntityType &&
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={10}
                                    >
                                        <ViewGroupItem>
                                            <GenericInput
                                                label={
                                                    <LocalizedText
                                                        code="Template.ChoosePreview"
                                                        value="Kies een ${entityTypeName}"
                                                        entityTypeName={previewEntityType.nameSingular.toLowerCase()}
                                                    />
                                                }
                                                labelPosition="left"
                                            >
                                                <Selectbox
                                                    selections={selections}
                                                    value={previewEntity}
                                                    onChange={setPreviewEntity as any}
                                                />
                                            </GenericInput>
                                        </ViewGroupItem>
                                        {
                                            previewDocumentUrl &&
                                                <ViewGroupItem>
                                                    <FileViewer
                                                        url={previewDocumentUrl}
                                                        type="pdf"
                                                    />
                                                </ViewGroupItem>
                                        }
                                    </ViewGroup>
                            }
                            {
                                tab === 0 && props.entity.entityType.isA(types.Template.Email.Type) &&
                                    <>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                marginTop: 20
                                            }}
                                        >
                                            <Card
                                                inset
                                            >
                                                <EntityAttachmentInput
                                                    entity={entity}
                                                    commitContext={commitContext}
                                                />
                                            </Card>
                                        </Grid>
                                    </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(Template);
