import React, { useCallback, useEffect, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import styles from './Document.module.scss';
import useTypes from '../../../../Type/Api/useTypes';
import useTransactionalEntity from '../../../../../../../@Api/Entity/Bespoke/useTransactionalEntity';
import FileViewer from '../../../../../../Generic/FileViewer/FileViewer';
import Tab from '../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import TabBar from '../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { EntityExpansionBuilder } from '../../../../Selection/Builder/EntityExpansionBuilder';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DocumentEntityController } from '../../../../../../../@Api/Controller/Directory/DocumentEntityController';
import Fields from '../../../../Fields/Fields';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { handleCommitResult } from '../../../../../../../@Api/Entity/Commit/commitEntity';
import uuid from '../../../../../../../@Util/Id/uuid';
import ActivityTemplateEditor from '../Template/ActivityTemplateEditor';
import useFileDownloadOptions from "../Hooks/useFileDownloadOptions";

export interface DocumentProps
{
    entity: Entity;
}

const Document: React.FC<DocumentProps> =
    props =>
    {
        const types = useTypes();
        const entity = useTransactionalEntity(props.entity);

        useEffect(
            () =>
            {
                new EntityExpansionBuilder(
                    entity.entityType,
                    [
                        entity
                    ],
                    [
                        EntityPath.fromEntity(entity)
                            .joinTo(
                                types.Activity.Document.RelationshipDefinition.DocumentAttachment,
                                false),
                        EntityPath.fromEntity(entity)
                            .joinTo(
                                types.Activity.Document.RelationshipDefinition.Template,
                                false),
                    ])
                    .expand();
            },
            [
                entity,
                types
            ]);

        const documentTemplate =
            useComputed(
                () =>
                    entity.getRelatedEntityByDefinition(
                        false,
                        types.Activity.Document.RelationshipDefinition.Template),
                [
                    entity,
                    types
                ]);

        const {
            isFinal,
            isConcept,
            previewDocumentUrl,
            finalizedDocumentUrl,
            downloadOptions,
        } =
            useFileDownloadOptions(entity, `document`, documentTemplate);

        const [ tab, setTab ] = useState(0);

        const onFinalize =
            useCallback(
                () =>
                {
                    return loadModuleDirectly(DocumentEntityController)
                        .finalizeDocument(entity.id)
                        .then(
                            result =>
                                handleCommitResult(
                                    entity,
                                    {
                                        descriptor: {},
                                        id: uuid(),
                                        events: [],
                                        files: new Map(),
                                    },
                                    {
                                        isDeferred: false,
                                        isForced: false,
                                        isDebounced: false,
                                        isBulkMode: false,
                                        isDebugMode: false,
                                        isAutoCommit: false,
                                    },
                                    result));
                },
                [
                    entity
                ]);

        const gotoPreview =
            useCallback(
                () =>
                    setTab(0),
                [
                    setTab
                ]);

        return <div
            className={styles.root}
        >
            {
                !isFinal &&
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        <ViewGroupItem>
                            <TabBar
                                value={tab}
                                appendix={
                                    isConcept &&
                                        <PrimaryButton
                                            label={
                                                <LocalizedText
                                                    code="Generic.Finalize"
                                                    value="Definitief maken"
                                                />
                                            }
                                            onClick={onFinalize}
                                            fullWidth
                                        />
                                }
                            >
                                <Tab
                                    value={-1}
                                    onClick={setTab}
                                >
                                    <LocalizedText
                                        code="Generic.Details"
                                        value="Details"
                                    />
                                </Tab>
                                {
                                    isConcept &&
                                        <Tab
                                            value={0}
                                            onClick={setTab}
                                        >
                                            <LocalizedText
                                                code="Generic.Example"
                                                value="Voorbeeld"
                                            />
                                        </Tab>
                                }
                                {
                                    isConcept &&
                                        <Tab
                                            value={1}
                                            onClick={setTab}
                                        >
                                            <LocalizedText
                                                code="Generic.Edit"
                                                value="Bewerken"
                                            />
                                        </Tab>
                                }
                            </TabBar>
                        </ViewGroupItem>
                        {
                            tab === -1 &&
                                <ViewGroupItem>
                                    <Fields
                                        entity={props.entity}
                                    />
                                </ViewGroupItem>
                        }
                        {
                            tab === 0 && previewDocumentUrl &&
                                <ViewGroupItem>
                                    <FileViewer
                                        url={previewDocumentUrl}
                                        extraDownloadOptions={downloadOptions}
                                        type="pdf"
                                    />
                                </ViewGroupItem>
                        }
                        {
                            tab === 1 &&
                                <ViewGroupItem>
                                    <ActivityTemplateEditor
                                        entity={entity}
                                        templateEntity={documentTemplate}
                                        onPreview={gotoPreview}
                                        templateType={types.Template.Document.DocumentActivity.Type}
                                        templateRelationshipDefinition={types.Activity.Document.RelationshipDefinition.Template}
                                        templateContentField={types.Activity.Document.Field.Content}
                                        templateFileField={types.Activity.Document.Field.TemplateFile}
                                        templateManagerRoute="/configuration/sales"
                                    />
                                </ViewGroupItem>
                        }
                    </ViewGroup>
            }
            {
                isFinal &&
                    <div>
                        {
                            finalizedDocumentUrl &&
                                <FileViewer
                                    url={finalizedDocumentUrl}
                                    type="pdf"
                                />
                        }
                    </div>
            }
        </div>;
    };

export default observer(Document);
