import React, { useMemo } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import Fields from '../../../Fields/Fields';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import RelatedEntityView from '../../Shared/RelatedEntityView/RelatedEntityView';
import getCustomPluralRelatedPaths from '../Activity/Api/getCustomPluralRelatedPaths';
import { useRoutingState } from '../../../../../../@Service/Router/Model/Api/useRoutingState';
import PageTabBar, { TabFamily } from '../../Shared/Page/TabBar/PageTabBar';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import CourseLessonAttendanceEditor from './AttendanceRegistration/CourseLessonAttendanceRegistration';
import RelatedEntityCount from '../../Shared/RelatedEntityCount/RelatedEntityCount';
import CourseLessonParticipantRegistration from "./Registration/CourseLessonRegistration";
import useFetchedRelatedEntity from "../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity";
import useEntityValue from "../../../../../../@Api/Entity/Hooks/useEntityValue";
import useTypes from "../../../Type/Api/useTypes";

export interface CourseTemplateProps
{
    entity: Entity;
    commitContext: CommitContext;
}

const CourseLesson: React.FC<CourseTemplateProps> =
    ({
         entity,
         commitContext,
     }) =>
    {
        const types = useTypes();
        const pluralRelatedPaths =
            useComputed(
                () =>
                    getCustomPluralRelatedPaths(entity.entityType),
                [
                    entity,
                ]
            );
        const [ course ] =
            useFetchedRelatedEntity(
                entity,
                types.Activity.Course.RelationshipDefinition.Lessons,
                true,
                commitContext
            );
        const isRegistrationPerLesson =
            useEntityValue<boolean>(
                course,
                types.Activity.Course.Field.IsRegistrationPerLesson,
                undefined,
                commitContext
            );
        const [ tab, setTab ] =
            useRoutingState(
                `Entity.${entity.id}.TabId`,
                isRegistrationPerLesson
                    ? 'General:ParticipantRegistration'
                    : 'General:AttendanceRegistration'
            );
        const tabFamilies =
            useMemo<TabFamily[]>(
                () => [
                    {
                        id: 'General',
                        tabs: [
                            {
                                id: 'ParticipantRegistration',
                                name:
                                    <LocalizedText
                                        code="Course.PartipantRegistration"
                                        value="Deelname registratie"
                                    />,
                                content:
                                    () =>
                                        <CourseLessonParticipantRegistration
                                            lesson={entity}
                                            commitContext={commitContext}
                                        />,
                                isVisible:
                                    () =>
                                        isRegistrationPerLesson,
                            },
                            {
                                id: 'AttendanceRegistration',
                                name:
                                    <LocalizedText
                                        code="Course.AttendanceRegistration"
                                        value="Aanwezigheidsregistratie"
                                    />,
                                content:
                                    () =>
                                        <CourseLessonAttendanceEditor
                                            lesson={entity}
                                            commitContext={commitContext}
                                        />
                            }
                        ]
                    },
                    {
                        id: 'PluralRelatedPaths',
                        tabs:
                            pluralRelatedPaths.map(
                                path =>
                                    ({
                                        id: path.id,
                                        name: path.getName(undefined, false),
                                        nameAppendix:
                                            <RelatedEntityCount
                                                entity={entity}
                                                path={path}
                                            />,
                                        content:
                                            () =>
                                                <RelatedEntityView
                                                    entity={entity}
                                                    path={path}
                                                />,
                                    })
                            )
                    }
                ],
                [
                    entity,
                    pluralRelatedPaths,
                    isRegistrationPerLesson,
                    commitContext,
                ]
            );

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset
                                    bottom={false}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <TypeAndName
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                                <Fields
                                    entity={entity}
                                    commitContext={commitContext}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <PageTabBar
                        entity={entity}
                        tabFamilies={tabFamilies}
                        tabId={tab}
                        onChangeTab={setTab}
                        commitContext={commitContext}
                    />
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(CourseLesson);
