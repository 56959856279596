import React from 'react';
import { observer } from 'mobx-react-lite';
import { FieldMappingEditorProps } from '../FieldMappingEditor';
import MappingEditor from '../../MappingEditor';
import MappingFieldMapping from '../../../../../../../../../../../../../@Api/Automation/Mapping/Field/MappingFieldMapping';
import Card from '../../../../../../../../../../../../../@Future/Component/Generic/Card/Card';

export interface MappingFieldMappingEditorProps extends FieldMappingEditorProps<MappingFieldMapping>
{

}

const MappingFieldMappingEditor: React.FC<MappingFieldMappingEditorProps> =
    props =>
    {
        return <Card>
            <MappingEditor
                mapping={props.fieldMapping.mapping}
                context={props.context}
                parentEntityType={props.fieldMapping.field.relationshipDefinition.getEntityType(props.fieldMapping.field.isParent)}
                parentFieldMapping={props.fieldMapping}
            />
        </Card>;
    };

export default observer(MappingFieldMappingEditor);
