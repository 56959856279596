import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import { Field } from '../../Model/Field';
import styles from './CodeEditor.module.scss';
import resolveInputFromFieldPath from '../../../../../../Multiplayer/Model/Input/Api/resolveInputFromFieldPath';

export interface CodeEditorProps
{
    entityType: EntityType;
    field: Field;
}

const CodeEditor: React.FC<CodeEditorProps> =
    props =>
    {
        const code =
            useComputed(
                () =>
                {
                    if (props.field.fieldPath)
                    {
                        return resolveInputFromFieldPath(props.field.fieldPath).getFieldCode();
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.field
                ]);

        return <div
            className={styles.root}
        >
            {code}
        </div>;
    };

export default observer(CodeEditor);
