import { EntityField } from '../../Model/Implementation/EntityField';
import FieldInput from '../../../@Component/Domain/Multiplayer/Model/Input/FieldInput';
import { EntityType } from '../../Model/Implementation/EntityType';
import isRequiredField from './isRequiredField';

export function isPrimitiveFieldRequired(
    type: EntityType,
    field: EntityField
): boolean
{
    const input = new FieldInput(type, field);

    return isRequiredField(input);
}
