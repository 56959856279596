import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../../../Viewer/LayoutViewer';
import PortalListLayout from '../../../../../../../@Api/Layout/Type/PortalListLayout';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import queryPortalDataSource from '../../../../../../../@Api/Portal/DataSource/Api/queryPortalDataSource';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import PortalDataSourceListQuery from '../../../../../../../@Api/Portal/DataSource/PortalDataSourceListQuery';
import OpenPortalQueryContext from '../../../../../../../@Api/Portal/Context/OpenPortalQueryContext';
import { runInAction } from 'mobx';
import PortalDataSourceQueryResultSet from '../../../../../../../@Api/Portal/DataSource/PortalDataSourceQueryResultSet';
import DataSourceValue from '../../../../../../../@Api/Automation/Value/DataSourceValue';
import PortalDataSourceValue from '../../../../../../../@Api/Portal/DataSource/PortalDataSourceValue';
import useMemoizedMap from '../../../../../../../@Util/MapUtils/useMemoizedMap';
import uuid from '../../../../../../../@Util/Id/uuid';
import HoverCard from '../../../../../../../@Future/Component/Generic/Card/HoverCard/HoverCard';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface PortalListLayoutViewerForQueryProps extends LayoutViewerProps<PortalListLayout>
{
    itemParameterDictionary: ParameterDictionary;
    query: PortalDataSourceListQuery;
    hideEmptyLayout?: boolean;
}

export const PortalListLayoutViewerForQuery: React.FC<PortalListLayoutViewerForQueryProps> =
    observer(
        props =>
        {
            const itemParameterDictionary = props.itemParameterDictionary;
            const parameterAssignment = props.parameterAssignment;
            const query = props.query;
            const [ _resultSet ] =
                useAsyncResult(
                    () =>
                        query &&
                            queryPortalDataSource(query),
                    [
                        query,
                        query?.state
                    ]
                );
            const [ resultSet, setResultSet ] = useState<PortalDataSourceQueryResultSet>(_resultSet);

            useEffect(
                () =>
                    _resultSet &&
                        setResultSet(_resultSet),
                [
                    _resultSet,
                    setResultSet
                ]
            );

            // Track open queries
            const openQueries = useContext(OpenPortalQueryContext);

            useEffect(
                () =>
                {
                    if (query)
                    {
                        runInAction(
                            () =>
                                openQueries.push(query)
                        );

                        return () =>
                            runInAction(
                                () =>
                                {
                                    openQueries.remove(query);
                                });
                    }
                },
                [
                    openQueries,
                    query
                ]
            );

            const dividerGlue = useDividerGlue();
            const memoizedParameterAssignment =
                useMemoizedMap(
                    () =>
                        resultSet &&
                        new Map(
                            resultSet.results.map(
                                result => [
                                    result.id,
                                    parameterAssignment
                                        .getNewAssignment(result.parameterAssignment)
                                        .getNewAssignmentWithParameter(
                                            props.layout.itemParameter,
                                            new DataSourceValue(
                                                new PortalDataSourceValue(
                                                    result.id,
                                                    props.layout.dataSourceSignature.id,
                                                    query.parameterAssignment,
                                                    result.parameterAssignment
                                                )
                                            )
                                        )
                                ]
                            )
                        ),
                    (a, b) => a.equals(b),
                    [
                        resultSet,
                        parameterAssignment,
                        query,
                        props.layout
                    ]
                );
            const hasMore =
                useComputed(
                    () =>
                        resultSet !== undefined
                        && resultSet.results.length >= query.limit,
                    [
                        resultSet,
                    ]
                );
            const [ moreQuery, setMoreQuery ] = useState<PortalDataSourceListQuery | undefined>(undefined);
            const loadMore =
                useCallback(
                    () =>
                        setMoreQuery(
                            new PortalDataSourceListQuery(
                                uuid(),
                                query.dataSourceSignature,
                                query.parameterAssignment,
                                query.filter,
                                query.orderings,
                                query.offset + query.limit,
                                query.limit
                            )
                        ),
                    [
                        query,
                    ]
                );

            if (memoizedParameterAssignment === undefined)
            {
                return <CardInset>
                    <Centered
                        horizontal
                    >
                        <Loader />
                    </Centered>
                </CardInset>;
            }
            else
            {
                if (resultSet.results.length === 0)
                {
                    if (props.layout.emptyLayout && !props.hideEmptyLayout)
                    {
                        return <LayoutViewer
                            {...props}
                            layout={props.layout.emptyLayout}
                        />;
                    }
                    else
                    {
                        return null;
                    }
                }
                else
                {
                    return <ViewGroup
                        orientation="vertical"
                        spacing={0}
                        glue={props.layout.hasDivider ? dividerGlue : undefined}
                    >
                        {
                            resultSet.results
                                .filter(
                                    result =>
                                        memoizedParameterAssignment.has(result.id)
                                )
                                .map(
                                    result =>
                                        <ViewGroupItem
                                            key={result.id}
                                        >
                                            <LayoutViewer
                                                {...props}
                                                parameterDictionary={itemParameterDictionary}
                                                parameterAssignment={memoizedParameterAssignment.get(result.id)}
                                                layout={props.layout.itemLayout}
                                            />
                                        </ViewGroupItem>
                                )
                        }
                        {
                            hasMore &&
                            moreQuery === undefined &&
                            <ViewGroupItem>
                                <HoverCard
                                    onClick={loadMore}
                                >
                                    <LocalizedText
                                        code="Generic.LoadMore"
                                        value="Meer laden"
                                    />...
                                </HoverCard>
                            </ViewGroupItem>
                        }
                        {
                            moreQuery !== undefined &&
                            <ViewGroupItem>
                                <PortalListLayoutViewerForQuery
                                    {...props}
                                    query={moreQuery}
                                    hideEmptyLayout
                                />
                            </ViewGroupItem>
                        }
                    </ViewGroup>;
                }
            }
        }
    );
