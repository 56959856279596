import { findAssistantFieldPath } from '../findAssistantFieldPath';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Trigger from '../../../../../@Api/Automation/Trigger/Trigger';
import { AssistantOnUpdateTrigger } from '../../Model/Automation/Trigger/AssistantOnUpdateTrigger';
import UpdateTrigger from '../../../../../@Api/Automation/Trigger/UpdateTrigger';
import FieldInput from '../../../Multiplayer/Model/Input/FieldInput';
import RelationshipInput from '../../../Multiplayer/Model/Input/RelationshipInput';

export function findAssistantOnUpdateTrigger(
    trigger: AssistantOnUpdateTrigger,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Trigger
{
    const entityType = rootParameter.type.type;
    const fieldPath =
        findAssistantFieldPath(
            entityType.path(),
            trigger.field
        );

    return new UpdateTrigger(
        entityType,
        undefined,
        fieldPath.field
            ? new FieldInput(
                entityType,
                fieldPath.field
            )
            : new RelationshipInput(
                entityType,
                fieldPath.relationshipDefinition,
                fieldPath.isParentRelationship
            )
    );
}
