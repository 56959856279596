import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardHeader from '../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import Checkbox from '../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import HoverCard from '../../../../../@Future/Component/Generic/Card/HoverCard/HoverCard';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { Currency } from '../../../../../@Api/Localization/Currency';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import LocalizerContext from '../../../../../@Service/Localization/LocalizerContext';
import { ApplicationPack } from '../../Model/ApplicationPack';
import { getApplicationPackPrice } from '../../Api/getApplicationPackPrice';

export interface ItemProps
{
    pack: ApplicationPack;
    onToggle: (pack: ApplicationPack) => void;
    checked?: boolean;
    disabled?: boolean;
    showPrice?: boolean;
    contractCurrency: Currency;
}

const Item: React.FC<ItemProps> =
    props =>
    {
        const localizer = useContext(LocalizerContext);

        const onToggle =
            useCallback(
                () =>
                    props.onToggle(props.pack),
                [
                    props.onToggle,
                    props.pack
                ]);

        const monthlyPrice =
            useComputed(
                () =>
                    DataObject.constructFromTypeIdAndValue(
                        'Currency',
                        props.pack.price > 0
                            ? getApplicationPackPrice(props.pack, 'Monthly')
                            : props.pack.price
                    ),
                [
                    props.pack
                ]);

        return <HoverCard
            onClick={onToggle}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={10}
                alignment="center"
            >
                <ViewGroupItem>
                    <Checkbox
                        checked={props.checked}
                        disabled={props.disabled}
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            <CardHeader>
                                {props.pack.name}
                            </CardHeader>
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem
                    ratio={1}
                />
                {
                    props.showPrice && monthlyPrice.value > 0 &&
                    <ViewGroupItem>
                        <LocalizedText
                            code="Generic.Monthly"
                            value="Maandelijks"
                        />: {Currency.format(monthlyPrice.value, localizer.locale, props.contractCurrency)}
                    </ViewGroupItem>
                }
            </ViewGroup>
        </HoverCard>;
    };

export default observer(Item);
