import * as React from 'react';
import { observer } from 'mobx-react';
import { DataObjectViewerStore } from './DataObjectViewerStore';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';

@observer
export class DataObjectViewer extends BaseComponent<DataObjectViewerStore>
{
    renderComponent(store: DataObjectViewerStore)
    {
        return React.createElement(
            store.dataObject.specification.type.view(),
            {
                store: store
            });
    }
}
