import { observable } from 'mobx';
import { DataObjectType } from './DataObjectType';
import { MathematicalOperator } from './MathematicalOperator';
import { DataObject } from './DataObject';
import { DataObjectOverloadType } from './DataObjectOverloadType';

export class DataObjectOperatorOverload
{
    // ------------------------- Properties -------------------------

    @observable dataObjectType: DataObjectType;
    @observable operator: MathematicalOperator;
    @observable type: DataObjectOverloadType;
    @observable isCompatible: (type: DataObjectType) => boolean;
    @observable outputType: (type: DataObjectType, relatedType: DataObjectType, isThisLhs: boolean) => DataObjectType;
    @observable compute: (value: DataObject, relatedValue: DataObject, isThisLhs: boolean, outputType: DataObjectType) => DataObject;

    // ------------------------ Constructor -------------------------

    constructor(dataObjectType: DataObjectType,
                operator: MathematicalOperator,
                type: DataObjectOverloadType,
                isCompatible: (type: DataObjectType) => boolean,
                outputType: (type: DataObjectType, relatedType: DataObjectType, isThisLhs: boolean) => DataObjectType,
                compute: (value: DataObject, relatedValue: DataObject, isThisLhs: boolean, outputType: DataObjectType) => DataObject)
    {
        this.dataObjectType = dataObjectType;
        this.operator = operator;
        this.type = type;
        this.isCompatible = isCompatible;
        this.outputType = outputType;
        this.compute = compute;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
