import { useEntityTypesWithMutablePack } from './useEntityTypesWithMutablePack';
import { useOwnedShareAndEnvironmentPacks } from './useOwnedShareAndEnvironmentPacks';
import { isEntityPackMutable } from './isEntityPackMutable';
import { useMemo } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';

export function usePackSelector(
    entity: Entity
): [ boolean, Entity[] ]
{
    const entityTypesWithMutablePack = useEntityTypesWithMutablePack();
    const ownedShareAndEnvironmentPacks = useOwnedShareAndEnvironmentPacks();

    const isPackSelectorVisible =
        useMemo(
            () =>
                entity &&
                isEntityPackMutable(
                    entity,
                    ownedShareAndEnvironmentPacks,
                    entityTypesWithMutablePack
                ),
            [
                entity,
                ownedShareAndEnvironmentPacks,
                entityTypesWithMutablePack
            ]);

    return [
        isPackSelectorVisible,
        isPackSelectorVisible
            ? ownedShareAndEnvironmentPacks
            : []
    ]
}