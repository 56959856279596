import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import { observable } from 'mobx';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../Type/EntityTypeStore';
import CompositePredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import Layout from '../../../../../../@Api/Layout/Layout';
import getLayoutFromDescriptor from '../../../../../../@Api/Layout/Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../../@Api/Layout/LayoutDependencyContext';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import AutomationDependencyContext from '../../../../../../@Api/Automation/AutomationDependencyContext';
import { Aggregate } from '../../../../DataObject/Model/Aggregate';
import View from '../View';

export default class Column
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref fieldPath: EntityFieldPath;
    @observable isHidden: boolean;
    @observable isSearchable: boolean;
    @observable.ref filter?: CompositePredicate;
    @observable.ref cellLayout?: Layout;
    @observable aggregate?: Aggregate;

    // ------------------------ Constructor -------------------------

    constructor(
        id: string,
        fieldPath: EntityFieldPath,
        isHidden: boolean = false,
        isSearchable: boolean = true,
        filter?: CompositePredicate,
        cellLayout?: Layout,
        aggregate?: Aggregate
    )
    {
        this.id = id;
        this.fieldPath = fieldPath;
        this.isHidden = isHidden;
        this.isSearchable = isSearchable;
        this.filter = filter;
        this.cellLayout = cellLayout;
        this.aggregate = aggregate;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get isValid()
    {
        return this.fieldPath !== undefined;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(descriptor: any, parameters: ParameterDictionary)
    {
        return new Column(
            descriptor.id,
            EntityFieldPath.construct(
                descriptor.fieldPath,
                loadModuleDirectly(EntityTypeStore)
            ),
            descriptor.isHidden,
            descriptor.isSearchable ?? true,
            descriptor.filter
                ? await CompositePredicate.fromDescriptor(
                    descriptor.filter,
                    new AutomationDependencyContext(parameters)
                )
                : undefined,
            descriptor.cellLayout
                ? await getLayoutFromDescriptor(
                    descriptor.cellLayout,
                    new LayoutDependencyContext(parameters)
                )
                :
                undefined,
            descriptor.aggregate
                ? Aggregate[descriptor.aggregate as string]
                : undefined
        );
    }

    shouldShowColumn(
        view: View,
        isInEditMode: boolean
    )
    {
        if (isInEditMode)
        {
            return true;
        }
        else if (view.specification.list!.groupings.some(grouping => grouping.column === this))
        {
            return false;
        }
        else
        {
            return !this.isHidden;
        }
    }

    toDescriptor(includeColumnFilters: boolean)
    {
        return {
            id: this.id,
            fieldPath: this.fieldPath.descriptor,
            isHidden: this.isHidden,
            isSearchable: this.isSearchable,
            cellLayout: this.cellLayout?.toDescriptor(),
            filter:
                includeColumnFilters === true
                    ? this.filter?.toDescriptor()
                    : undefined,
            aggregate: Aggregate[this.aggregate],
        };
    }

    // ----------------------- Private logic ------------------------
}
