import { computed, observable } from 'mobx';
import { ListStore } from '../ListStore';
import { ViewComponent } from '../../../ViewStack/Model/ViewComponent';
import { BaseStore, getOrCompute, PropType } from '../../../../../@Framework/Store/BaseStore';

export type Id = string | number;

export interface ListItemStoreProps<D>
{
    template?: PropType<ListItemStore<D>, ListItemStoreProps<D>, ViewComponent<BaseStore>>;
    isHidden?: PropType<ListItemStore<D>, ListItemStoreProps<D>, boolean>;
}

export class ListItemStore<D, P extends ListItemStoreProps<D> = ListItemStoreProps<D>> extends BaseStore<P>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable id: Id;
    @observable data: D;
    @observable listIdx: number;
    @observable listStore: ListStore<D, unknown, ListItemStore<D>, unknown, unknown, unknown>;
    @observable listGroupId: Id;
    @observable listGroupIdx: number;

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get template(): ViewComponent<BaseStore>
    {
        return getOrCompute(this, this.props.template);
    }

    @computed
    get isHidden(): boolean
    {
        return getOrCompute(this, this.props.isHidden);
    }

    @computed
    get isFirst(): boolean
    {
        return this.listStore.getPredecessor(this) === undefined;
    }

    @computed
    get isFirstInGroup(): boolean
    {
        if (this.listGroupId != null)
        {
            return this.listStore.getPredecessor(this, this.listGroupId) === undefined;
        }
        else
        {
            return false;
        }
    }

    @computed
    get isLast(): boolean
    {
        return this.listStore.getSuccessor(this) === undefined;
    }

    @computed
    get isLastInGroup(): boolean
    {
        if (this.listGroupId != null)
        {
            return this.listStore.getSuccessor(this, this.listGroupId) === undefined;
        }
        else
        {
            return false;
        }
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
