import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import EntityCalendar from '../Calendar/EntityCalendar';
import styles from './Agenda.module.scss';
import useWindowSize from '../../../../@Future/Util/DOM/useWindowSize';

export interface AgendaProps
{
    settingStorageKey: string;
    fullScreen?: boolean;
}

const Agenda: React.FC<AgendaProps> =
    props =>
    {
        const windowSize = useWindowSize();

        const calendarHeight =
            useMemo(
                () =>
                    props.fullScreen ? Math.max(windowSize.height - 200, 388) : 388,
                [
                    windowSize,
                    props.fullScreen
                ]);

        return <div
            className={styles.root}
        >
            <div
                className={styles.calendarWrapper}
            >
                <EntityCalendar
                    viewType="day"
                    height={calendarHeight}
                    settingStorageKey={props.settingStorageKey}
                />
            </div>
        </div>;
    };

export default observer(Agenda);
