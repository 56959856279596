import React from 'react';
import { RecurrencePatternEditorProps } from '../../RecurrencePatternEditor';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { DailyRecurrencePattern } from '../../../Model/RecurrencePattern';
import { RecurrenceIntervalEditor } from '../../Util/Interval/RecurrenceIntervalEditor';

export const DailyRecurrencePatternEditor: React.FC<RecurrencePatternEditorProps<DailyRecurrencePattern>> =
    ({
        value,
        onChange,
     }) =>
    {
        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem>
                        <LocalizedText
                            code="Generic.Every"
                            value="Elke"
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <RecurrenceIntervalEditor
                            value={value?.interval}
                            onChange={
                                interval =>
                                    onChange({
                                        ...value,
                                        interval,
                                    })
                            }
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <LocalizedText
                            code="Generic.Day(s)"
                            value="Dag(en)"
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
        </ViewGroup>;
    };
