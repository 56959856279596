import { useContext } from 'react';
import useTypes from '../../Type/Api/useTypes';
import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import CurrentUserContext from '../../../User/CurrentUserContext';
import { createNumberComparator } from '../../../../Generic/List/V2/Comparator/NumberComparator';
import { createInverseComparator } from '../../../../Generic/List/V2/Comparator/InverseComparator';
import useResults from '../../Selection/Hooks/useResults';

const inverseIdComparator =
    createInverseComparator(
        createNumberComparator<Entity>(
            entity =>
                entity.id));

export default function useRecentlyVisitedEntities(): [ boolean, Entity[] ]
{
    const types = useTypes();
    const currentUserStore = useContext(CurrentUserContext);
    const readEvents =
        useResults(
            types.Event.Read.Type,
            (builder, rootPath) =>
                builder
                    .where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath
                                    .joinTo(
                                        types.Event.RelationshipDefinition.User,
                                        false),
                                currentUserStore.employeeEntity))
                    .orderBy(
                        rootPath.field(types.Entity.Field.Id),
                        false)
                    .limit(10),
            [
                types,
                currentUserStore
            ]);

    const entities =
        useComputed(
            () =>
            {
                if (readEvents)
                {
                    // Only return unique recently visited entities
                    const uniqueEntities: Entity[] = [];
                    const foundEntities = new Set<Entity>();

                    const sortedReadEvents =
                        readEvents
                            .slice()
                            .sort(inverseIdComparator);

                    sortedReadEvents.forEach(
                        event =>
                        {
                            const visitedEntity =
                                event.getRelatedEntityByDefinition(
                                    true,
                                    types.Entity.RelationshipDefinition.Events);

                            if (visitedEntity && !foundEntities.has(visitedEntity))
                            {
                                foundEntities.add(visitedEntity);
                                uniqueEntities.push(visitedEntity);
                            }
                        });

                    return uniqueEntities.slice(0, 10);
                }
                else
                {
                    return [];
                }
            },
            [
                readEvents
            ]);

    return [ readEvents === undefined, entities ];
}
