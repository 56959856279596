import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import FilteredCollectionComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/FilteredCollectionComputation';
import uuid from '../../../../../../../../../../@Util/Id/uuid';
import FunctionContext from '../../../../../../../../../../@Api/Automation/Function/FunctionContext';
import MapByComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/MapByComputation';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationBox from '../../Box/ComputationBox';

export interface MapByComputationEditorProps extends ComputationEditorProps<ValueType<any>, MapByComputation>
{

}

const MapByComputationEditor: React.FC<MapByComputationEditorProps> =
    props =>
    {
        const { value, context } = props;

        const setCollection =
            useCallback(
                (collection?: Computation<any, any>) =>
                    runInAction(
                        () =>
                        {
                            value.collection = collection;
                            value.elementParameter =
                                FilteredCollectionComputation.getElementParameter(
                                    value.collection,
                                    value.elementParameter?.id || uuid());
                        }),
                [
                    value
                ]);
        const keyContext =
            useComputed(
                () =>
                    new FunctionContext(
                        context.parameterDictionary.getNewDictionaryWithParameter(value.elementParameter),
                        context.parameterAssignment,
                        context.commitContext
                    ),
                [
                    context,
                    value
                ]);
        const setKey =
            useCallback(
                (key?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            value.key = key),
                [
                    value
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <Input
                label="Map"
                labelPosition="left"
            >
                <ComputationBox>
                    <ComputationEditor
                        context={context}
                        value={value.collection}
                        onChange={setCollection}
                    />
                </ComputationBox>
            </Input>
            <Input
                label="By"
                labelPosition="left"
            >
                <ComputationBox>
                    <ComputationEditor
                        context={keyContext}
                        value={value.key}
                        onChange={setKey}
                    />
                </ComputationBox>
            </Input>
        </ViewGroup>;
    };

export default observer(MapByComputationEditor);
