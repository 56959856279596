import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import { findAssistantComputation } from './findAssistantComputation';
import uuid from '../../../../../@Util/Id/uuid';
import { AssistantGenAiPromptComputation } from '../../Model/Automation/Computation/AssistantGenAiPromptComputation';
import { AiPromptComputationPart } from '../../../../../@Api/Automation/Function/Computation/AiPromptComputationPart';
import { findAssistantValueType } from '../findAssistantValueType';
import AiPromptComputation from '../../../../../@Api/Automation/Function/Computation/AiPromptComputation';

export function findAssistantGenAiPromptComputation(
    computation: AssistantGenAiPromptComputation,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Computation<any, any>
{
    const prompt =
        findAssistantComputation(
            computation.prompt,
            parameters,
            rootParameter
        );
    const files =
        computation.files.map(
            file =>
                new AiPromptComputationPart(
                    uuid(),
                    findAssistantComputation(
                        file,
                        parameters,
                        rootParameter
                    )
                )
        );
    const resultType = findAssistantValueType('Text');

    return new AiPromptComputation(
        [
            new AiPromptComputationPart(
                uuid(),
                prompt
            ),
            ...files,
        ],
        resultType
    );
}
