import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';
import useNonEmptyFieldGroups from './useNonEmptyFieldGroups';
import { useContext, useMemo } from 'react';
import EntityTypeContext from '../../Type/EntityTypeContext';
import { EntityFieldGroup } from '../../../Management/Application/EntityTypesEditor/EntityFieldGroup';
import CurrentUserContext from '../../../User/CurrentUserContext';
import equalsEntity from '../../../../../@Api/Entity/Bespoke/equalsEntity';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import useTypes from '../../Type/Api/useTypes';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';
import initializeFieldGroupsForType from './initializeFieldGroupsForType';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';

export type TypeFieldInclusion = 'include' | 'exclude' | 'auto';

export default function useVisibleFieldGroups(
    entity: Entity,
    fieldPaths: EntityFieldPath[],
    noVirtualGroups: boolean = false,
    forType?: EntityType
): [ EntityFieldGroup[], boolean ]
{
    const types = useTypes();

    const [ , isLoading ] =
        useAsyncResult(
            () =>
            {
                const entityType = forType || entity.entityType;

                return initializeFieldGroupsForType(entityType);
            },
            [
                entity,
                forType,
                types
            ]);

    const nonEmptyFieldGroups =
        useNonEmptyFieldGroups(
            entity,
            fieldPaths,
            forType
        );

    const entityTypeStore = useContext(EntityTypeContext);
    const currentUserStore = useContext(CurrentUserContext);

    const nameFieldGroup =
        useComputed(
            () =>
                entity.entityType
                    .getInheritedFieldGroups(entityTypeStore)
                    .find(group => group.id === types.EntityFieldGroup.Entity.NameGroup),
            [
                entity,
                types
            ]);

    const privateDataGroup =
        useMemo(
            () =>
                new EntityFieldGroup(
                    undefined,
                    undefined,
                    () => 'Prive gegevens',
                    'privatedata')
            ,
            []);

    const employeeRelationship =
        useComputed(
            () =>
                entity.getRelatedEntitiesByDefinition(
                    true,
                    types.Relationship.Person.RelationshipDefinition.Person)
                    .find(
                        entity =>
                            entity.entityType.isA(types.Relationship.Person.Contact.Employee.Type)),
            [
                entity,
                types
            ]);

    const settingsDataGroup =
        useComputed(
            () =>
                equalsEntity(employeeRelationship, currentUserStore.employeeEntity)
                    ?
                        entity.entityType
                            .getInheritedFieldGroups(entityTypeStore)
                            .find(
                                group =>
                                    group.id === types.EntityFieldGroup.Entity.SettingGroup)
                    :
                        undefined,
            [
                types,
                entity.entityType,
                entityTypeStore,
                employeeRelationship,
                currentUserStore
            ]);

    return [
        useComputed(
            () =>
            {
                if (entity.entityType.isA(types.Relation.Person.Type) && !noVirtualGroups)
                {
                    return [
                        nameFieldGroup,
                        privateDataGroup,
                        settingsDataGroup
                    ].filter(
                        item =>
                            item !== undefined);
                }
                else
                {
                    return nonEmptyFieldGroups
                        // Don't include virtual groups
                        .filter(
                            group =>
                                group.entity !== undefined);
                }
            },
            [
                entity,
                types,
                noVirtualGroups,
                nameFieldGroup,
                privateDataGroup,
                settingsDataGroup,
                nonEmptyFieldGroups
            ]),
        isLoading
    ] as [ EntityFieldGroup[], boolean ];
}
