import AutomationDependencyContext from '../AutomationDependencyContext';
import Parameter from '../Parameter/Parameter';
import { observable } from 'mobx';
import Predicate from '../Function/Computation/Predicate/Predicate';
import Dependency from '../Parameter/Dependency';
import Validation from '../Validation/Validation';
import { LogicalOperator } from '../../../@Component/Domain/DataObject/Model/LogicalOperator';
import CompositeTriggerElement from './CompositeTriggerElement';
import MutationTrigger from './MutationTrigger';
import { EntityType } from '../../Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import localizeText from '../../Localization/localizeText';

export default class CompositeTrigger extends MutationTrigger
{
    // ------------------------- Properties -------------------------

    @observable operator: LogicalOperator;
    @observable.shallow triggers: CompositeTriggerElement[];

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType,
                predicate: Predicate,
                operator: LogicalOperator,
                triggers: CompositeTriggerElement[])
    {
        super(entityType, predicate);

        this.operator = operator;
        this.triggers = triggers;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getParameters(): Parameter<any>[]
    {
        return [
            this.getEntityParameter(),
            this.getUserParameter(),
            this.getDateParameter()
        ];
    }

    // getParameters(): Parameter<any>[]
    // {
    //     return [
    //         this.getDateParameter(),
    //         ...this.triggers
    //             .map(
    //                 (trigger, idx) =>
    //                 {
    //                     const parameters = trigger.trigger.getParameters();
    //
    //                     for (const parameter of parameters)
    //                     {
    //                         parameter.id = `${trigger.id}.${parameter.id}`;
    //                         parameter.name = `[Trigger ${idx + 1}] ${parameter.name}`;
    //                     }
    //
    //                     return parameters;
    //                 })
    //             .reduce((a, b) => a.concat(b), [])
    //     ];
    // }

    validate(): Validation[]
    {
        if (this.triggers.length === 0)
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        'CompositeTriggerValidationError',
                        'Je moet minimaal één trigger configureren.'
                    )
                )
            ];
        }

        return [
            ...super.validate(),
            ...this.triggers
                .map(
                    trigger =>
                        trigger.trigger.validate())
                .reduce((a, b) => a.concat(b), [])
        ];
    }

    getDependencies(): Dependency[]
    {
        return this.triggers
            .map(
                trigger =>
                    trigger.trigger.getDependencies())
            .reduce((a, b) => a.concat(b), []);
    }

    augmentDescriptor(descriptor: any)
    {
        super.augmentDescriptor(descriptor);

        descriptor.type = 'Composite';
        descriptor.operator = LogicalOperator[this.operator];
        descriptor.triggers =
            this.triggers.map(
                trigger =>
                    trigger.toDescriptor());
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new CompositeTrigger(
            loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.entityTypeId),
            undefined,
            (LogicalOperator as any)[descriptor.operator],
            await Promise.all(
                descriptor.triggers
                    .map(
                        trigger =>
                            CompositeTriggerElement.fromDescriptor(
                                trigger,
                                dependencyContext))));
    }

    getName(): string
    {
        switch (this.operator)
        {
            case LogicalOperator.And:
                return localizeText(
                    'CompositeTriggerAndName',
                    'Indien alle volgende triggers afgaan: ${triggers}',
                    {
                        triggers: this.triggers.map(trigger => trigger.trigger.getName()).join(', ')
                    });

            case LogicalOperator.Or:
                return localizeText(
                    'CompositeTriggerOrName',
                    'Indien één van de volgende triggers afgaan: ${triggers}',
                    {
                        triggers: this.triggers.map(trigger => trigger.trigger.getName()).join(', ')
                    });
        }
    }

    // ----------------------- Private logic ------------------------
}