import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import EntityTypeContext from '../EntityTypeContext';
import { StaticEntityType } from '../../../../../@Api/Model/Implementation/StaticEntityType';
import StaticSelectbox, { StaticSelectboxProps } from '../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { createStringComparator } from '../../../../Generic/List/V2/Comparator/StringComparator';

export interface TypeSelectorProps extends Omit<StaticSelectboxProps<EntityType>, 'options'>
{
    onChange: (value?: EntityType) => void;
}

const TypeSelector: React.FC<TypeSelectorProps> =
    props =>
    {
        const entityTypeStore = useContext(EntityTypeContext);
        const options =
            useMemo(
                () =>
                    entityTypeStore.types
                        .filter(
                            type =>
                                !(type instanceof StaticEntityType))
                        .map(
                            type => ({
                                id: type.id.toString(),
                                label: `${type.getName()} (${type.code})`,
                                value: type
                            }))
                        .sort(createStringComparator(e => e.label)),
                [
                    entityTypeStore
                ]);

        return <StaticSelectbox
            options={options}
            {...props}
        />;
    };

export default observer(TypeSelector);
