import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import { findAssistantComputation } from './findAssistantComputation';
import { AssistantMathComputation } from '../../Model/Automation/Computation/AssistantMathComputation';
import { MathematicalOperator } from '../../../DataObject/Model/MathematicalOperator';
import MathematicalComputation from '../../../../../@Api/Automation/Function/Computation/MathematicalComputation';

export function findAssistantMathComputation(
    computation: AssistantMathComputation,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Computation<any, any>
{
    const lhs =
        findAssistantComputation(
            computation.lhs,
            parameters,
            rootParameter
        );
    const operator = (MathematicalOperator as any)[computation.operator];
    const rhs =
        findAssistantComputation(
            computation.rhs,
            parameters,
            rootParameter
        );

    return new MathematicalComputation(
        lhs,
        operator,
        rhs
    );
}
