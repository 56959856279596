import Role, { Privilege } from '../../../Model/Role';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';

export function resetFieldPermissions(
    role: Role,
    privileges: Privilege[],
    entityType: EntityType
)
{
    entityType.fields
        .filter(
            field =>
                role.rightByField.has(field)
        )
        .forEach(
            field =>
            {
                const fieldRight = role.rightByField.get(field);

                privileges.forEach(
                    privilege =>
                        fieldRight
                            .setPermission(
                                privilege,
                                'Inherited'
                            )
                );

                role.rightByField.set(field, fieldRight);
            }
    );
}