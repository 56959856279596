import React, { useCallback, useContext } from 'react';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import RouterContext from '../../../../../@Service/Router/RouterContext';

export interface AgendaButtonProps
{
    settingStorageKey?: string;
}

const AgendaButton: React.FC<AgendaButtonProps> =
    ({
         settingStorageKey
     }) =>
    {
        const routerStore = useContext(RouterContext);

        const clickHandler =
            useCallback(
                () =>
                    routerStore.route(
                        settingStorageKey
                            ? `/agenda/${btoa(settingStorageKey)}`
                            : '/agenda'
                    ),
                [
                    routerStore,
                    settingStorageKey
                ]
            );

        return <IconButton
            icon="today"
            tooltip="Agenda"
            onClick={clickHandler}
        />;
    };

export default AgendaButton;
