import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useSpotlerCampaignSendCount from '../Hooks/useSpotlerCampaignSendCount';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import useSpotlerCampaignViewedCount from '../Hooks/useSpotlerCampaignViewedCount';
import CampaignPercentageChart from '../../Campaign/Charts/CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

export interface SpotlerCampaignChartViewedProps
{
    size: number;
    spotlerCampaign: Entity;
    selectedStep?: Entity;
}

export const SpotlerCampaignChartViewed: React.FC<SpotlerCampaignChartViewedProps> =
    observer(
        (
            {
                spotlerCampaign,
                selectedStep,
                size,
            }
        ) =>
        {
            const totalResultCount =
                useSpotlerCampaignSendCount(spotlerCampaign, selectedStep);

            const viewedCount =
                useSpotlerCampaignViewedCount(spotlerCampaign, selectedStep);

            const allSet =
                useAllSet([
                    totalResultCount,
                    viewedCount
                ]);

            const formattedSelection =
                useMemo(
                    () =>
                    {
                        return `${viewedCount} / ${totalResultCount}`
                    },
                    [
                        viewedCount,
                        totalResultCount
                    ]
                );

            return <div>
                {
                    allSet &&
                    <CampaignPercentageChart
                        value={viewedCount / totalResultCount * 100}
                        size={size}
                        label={localizeText('Campaign.Chart.Opened', 'Geopend')}
                        selection={formattedSelection}
                    />
                }
            </div>;
        }
    );