import PortalActionInvocationResult from '../PortalActionInvocationResult';
import PortalActionInvocation from '../PortalActionInvocation';
import performAction from '../../../Entity/performAction';
import { runInAction } from 'mobx';
import uuid from '../../../../@Util/Id/uuid';

export default async function invokePortalAction(invocation: PortalActionInvocation): Promise<PortalActionInvocationResult>
{
    const files = new Map<string, File>();

    const result =
        await performAction(
            undefined,
            {
                code: 'Portal.InvokePortalAction',
                parameters: {
                    invocation:
                        invocation.toDescriptor(
                            (fileId, file) =>
                                files.set(fileId, file))
                },
                files: files
            });

    const invocationResult =
        await PortalActionInvocationResult.fromDescriptor(
            result.result,
            invocation);

    runInAction(
        () =>
            invocationResult.dirtyQueries
                .forEach(
                    dirtyQuery =>
                        dirtyQuery.state = uuid()));

    return invocationResult;
}
