import { observable } from 'mobx';
import { Computation } from '../../Computation';
import { MathematicalOperator } from '../../../../DataObject/Model/MathematicalOperator';

export class CompositeComputationOperation
{
    // ------------------------- Properties -------------------------

    @observable operator: MathematicalOperator;
    @observable computation: Computation;

    // ------------------------ Constructor -------------------------

    constructor(operator: MathematicalOperator,
                computation: Computation)
    {
        this.operator = operator;
        this.computation = computation;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
