import { EntityType } from '../../Model/Implementation/EntityType';
import { Entity } from '../../Model/Implementation/Entity';
import useResult from '../../../@Component/Domain/Entity/Selection/Hooks/useResult';
import useTypes from '../../../@Component/Domain/Entity/Type/Api/useTypes';

export default function useEntityByUuid(entityType?: EntityType,
                                        uuid?: string): [ Entity, boolean ]
{
    const types = useTypes();

    return useResult(
        uuid ? entityType : undefined,
        (builder, rootPath) =>
            builder
                .where(
                    cb =>
                        cb.eq(
                            rootPath.field(types.Entity.Field.Uuid),
                            undefined,
                            uuid)),
        [
            types,
            uuid
        ]);
}
