import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { EntityPath } from '../../Path/@Model/EntityPath';
import capitalize from 'lodash/capitalize';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { isPrimitiveFieldRequired } from '../../../../../@Api/Metadata/Input/isPrimitiveFieldRequired';

export class BespokeRelationPerson extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Relation.Person')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getInitializationPathsInViewer(rootPath: EntityPath): EntityPath[]
    {
        // If entity is a relation, fetch the parent relationships
        const paths = super.getInitializationPathsInViewer(rootPath);

        paths.push(
            rootPath
                .joinTo(
                    this.types.Relation.Person.RelationshipDefinition.Address,
                    false
                )
                .joinTo(
                    this.types.Address.RelationshipDefinition.Country,
                    false
                )
        );


        const relationshipPath =
            rootPath.joinTo(
                this.types.Relationship.Person.RelationshipDefinition.Person,
                true);

        // Avoid infinite loop
        if (relationshipPath.length > rootPath.length)
        {
            paths.push(relationshipPath);
            paths.push(
                ...relationshipPath.entityType.bespoke.getInitializationPathsInViewer(relationshipPath));
        }

        return paths;
    }

    isOwnerInListCaption(entity: Entity,
                         owner: Entity,
                         relationshipDefinition: EntityRelationshipDefinition,
                         pathFromRelatedEntity?: RelatedEntityPath): boolean
    {
        return super.isOwnerInListCaption(entity, owner, relationshipDefinition, pathFromRelatedEntity)
            && relationshipDefinition !== this.entityTypeStore.bespoke.types.Relationship.Person.RelationshipDefinition.Person;
    }

    getInitializationPathsInTimeline(entity: Entity, rootPath: EntityPath): EntityPath[]
    {
        return [
            ...super.getInitializationPathsInTimeline(entity, rootPath),
            rootPath
                .joinTo(
                    this.entityTypeStore.bespoke.types.Relationship.Person.RelationshipDefinition.Person,
                    true)
                .joinTo(
                    this.entityTypeStore.bespoke.types.Relationship.RelationshipDefinition.Activities,
                    false)
                .joinTo(
                    this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Notes,
                    false),
            rootPath
                .joinTo(
                    this.entityTypeStore.bespoke.types.Relationship.Person.RelationshipDefinition.Person,
                    true)
                .castTo(this.entityTypeStore.bespoke.types.Relationship.Person.Contact.Type)
                .joinTo(
                    this.entityTypeStore.bespoke.types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                    false)
                .joinTo(
                    this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Notes,
                    false)
        ];
    }

    getAddPathsInTimeline(entity: Entity, rootPath: EntityPath): RelatedEntityPath[]
    {
        const relationship =
            entity.getRelatedEntityByDefinition(
                true,
                this.entityTypeStore.bespoke.types.Relationship.Person.RelationshipDefinition.Person);

        return [
            ...super.getAddPathsInTimeline(entity, rootPath),
            ...relationship
                ?
                    relationship.entityType.bespoke.getAddPathsInTimeline(
                        relationship,
                        rootPath.joinTo(
                            this.entityTypeStore.bespoke.types.Relationship.Person.RelationshipDefinition.Person,
                            true))
                :
                    []
        ];
    }

    getCharactersInAvatar(entity: Entity): string
    {
        let characters = '';

        const firstName = entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Relation.Person.Field.FirstName);

        if (firstName && firstName.length > 0)
        {
            characters += firstName[0];
        }

        const lastName = entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Relation.Person.Field.LastName);

        if (lastName && lastName.length > 0)
        {
            characters += lastName[0];
        }

        return characters;
    }

    hideFieldPath(entity: Entity,
                  fieldPath: EntityFieldPath,
                  fromFieldPath?: EntityFieldPath,
                  isInConstructor?: boolean,
                  commitContext?: CommitContext): boolean
    {
        // Hide private email address, phone number and mobile phone number during construction in
        // case of adding a contact
        if (entity.isNew() && isInConstructor)
        {
            const contact = entity.getRelatedEntityByDefinition(
                true,
                this.types.Relationship.Person.RelationshipDefinition.Person,
                commitContext
            );

            if (contact && contact.entityType.isA(this.types.Relationship.Person.Contact.Type))
            {
                if ((fieldPath.field === this.types.Relation.Person.Field.EmailAddress && !isPrimitiveFieldRequired(entity.entityType, this.types.Relation.Person.Field.EmailAddress))
                    || (fieldPath.field === this.types.Relation.Person.Field.PhoneNumber && !isPrimitiveFieldRequired(entity.entityType, this.types.Relation.Person.Field.PhoneNumber))
                    || (fieldPath.field === this.types.Relation.Person.Field.MobilePhoneNumber && !isPrimitiveFieldRequired(entity.entityType, this.types.Relation.Person.Field.MobilePhoneNumber)))
                {
                    return true;
                }
            }
        }

        return super.hideFieldPath(entity, fieldPath, fromFieldPath, isInConstructor, commitContext)
            // Hide settings fields if the person is not an employee
            || (fieldPath.field === this.types.Relation.Person.Field.WorkspaceBackground
                && !this.isEmployee(entity))
            || (fieldPath.relationshipDefinition === this.types.Relation.Person.RelationshipDefinition.Language
                && !fieldPath.isParentRelationship
                && !this.isEmployee(entity));
    }

    isEmployee(entity: Entity)
    {
        return entity.getRelatedEntitiesByDefinition(
            true,
            this.types.Relationship.Person.RelationshipDefinition.Person)
            .some(
                rsp =>
                    rsp.entityType.isA(this.types.Relationship.Person.Contact.Employee.Type));
    }

    setName(entity: Entity,
            name: string,
            commitContext?: CommitContext)
    {
        const commaSplit = (name || '').split(',');

        if (commaSplit.length > 1)
        {
            setValueByFieldInEntity(
                entity,
                this.types.Relation.Person.Field.FirstName,
                capitalize(commaSplit[1].trim()),
                commitContext
            );

            const spaceSplitLastName = commaSplit[0].split(' ');

            if (spaceSplitLastName.length > 1)
            {
                setValueByFieldInEntity(
                    entity,
                    this.types.Relation.Person.Field.LastName,
                    capitalize(spaceSplitLastName[spaceSplitLastName.length - 1]),
                    commitContext
                );

                if (spaceSplitLastName.length > 2)
                {
                    setValueByFieldInEntity(
                        entity,
                        this.types.Relation.Person.Field.MiddleName,
                        spaceSplitLastName.slice(1, spaceSplitLastName.length - 1).join(' '),
                        commitContext
                    );
                }
            }
            else
            {
                setValueByFieldInEntity(
                    entity,
                    this.types.Relation.Person.Field.LastName,
                    capitalize(commaSplit[0].trim()),
                    commitContext
                );
            }
        }
        else
        {
            const spaceSplit = (name || '').split(' ');

            if (spaceSplit.length === 1)
            {
                setValueByFieldInEntity(
                    entity,
                    this.types.Relation.Person.Field.LastName,
                    capitalize(spaceSplit[0]),
                    commitContext
                );
            }
            else
            {
                if (spaceSplit[0])
                {
                    setValueByFieldInEntity(
                        entity,
                        this.types.Relation.Person.Field.FirstName,
                        capitalize(spaceSplit[0]),
                        commitContext
                    );
                }

                if (spaceSplit.length > 1)
                {
                    setValueByFieldInEntity(
                        entity,
                        this.types.Relation.Person.Field.LastName,
                        capitalize(spaceSplit[spaceSplit.length - 1]),
                        commitContext
                    );

                    if (spaceSplit.length > 2)
                    {
                        setValueByFieldInEntity(
                            entity,
                            this.types.Relation.Person.Field.MiddleName,
                            spaceSplit.slice(1, spaceSplit.length - 1).join(' '),
                            commitContext
                        );
                    }
                }
            }
        }
    }

    getListDependencies(): EntityPath[]
    {
        return [
            ...super.getListDependencies(),
            EntityPath.fromEntityType(this.type)
                .joinTo(
                    this.types.Relationship.Person.RelationshipDefinition.Person,
                    true
                ),
            EntityPath.fromEntityType(this.type)
                .joinTo(
                    this.types.Relationship.Person.RelationshipDefinition.Person,
                    true
                )
                .joinTo(
                    this.types.Relation.RelationshipDefinition.Relationships,
                    true
                )
        ];
    }

    // ----------------------- Private logic ------------------------
}
