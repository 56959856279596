import Layout from '../../../Layout';
import Dependency from '../../../../Automation/Parameter/Dependency';
import Validation from '../../../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../../../LayoutDependencyContext';
import getLayoutFromDescriptor from '../../../Api/getLayoutFromDescriptor';
import Parameter from '../../../../Automation/Parameter/Parameter';
import Computation from '../../../../Automation/Function/Computation/Computation';
import getComputationFromDescriptor from '../../../../Automation/Api/getComputationFromDescriptor';
import ValueType from '../../../../Automation/Value/Type/ValueType';
import localizeText from '../../../../Localization/localizeText';

export default class Tab
{
    // ------------------------- Properties -------------------------

    @observable.ref parameter: Parameter<any>;
    @observable.ref value: Computation<any, any>;
    @observable.ref layout: Layout;

    // ------------------------ Constructor -------------------------

    constructor(parameter: Parameter<any>,
                value: Computation<any, any>,
                layout: Layout)
    {
        this.parameter = parameter;
        this.value = value;
        this.layout = layout;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getId()
    {
        return this.parameter.id;
    }

    validate(): Validation[]
    {
        return [
            ...this.value.validate(),
            ...this.layout.validate()
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.value.getDependencies(),
            ...this.layout.getDependencies()
                .filter(
                    dependency =>
                        dependency.parameter !== this.parameter)
        ];
    }

    toDescriptor()
    {
        return {
            id: this.getId(),
            value: this.value.toDescriptor(),
            layout: this.layout.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext,
                                valueType: ValueType<any>)
    {
        const parameter =
            new Parameter(
                descriptor.id,
                valueType,
                true,
                localizeText('Layout.TabBar.ValueOfTab', 'Waarde van tab'));

        const value =
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext);

        const layoutDependencyContext =
            dependencyContext.withParameterDictionary(
                dependencyContext.parameterDictionary
                    .getNewDictionaryWithParameter(parameter)
            );

        const layout =
            await getLayoutFromDescriptor(
                descriptor.layout,
                layoutDependencyContext);

        return new Tab(
            parameter,
            value,
            layout);
    }

    // ----------------------- Private logic ------------------------
}
