import { reference, registerType, type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';
import { Setting } from './Setting';

@type('SettingPackage')
export class SettingPackage
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'Setting') @observable.shallow applicationSettings: Setting[];
    @reference(undefined, 'Setting') @observable.shallow organizationSettings: Setting[];
    @reference(undefined, 'Setting') @observable.shallow userSettings: Setting[];

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}

registerType(SettingPackage, 'SettingPackage');
