import { observable } from 'mobx';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Action from './Action';
import EmptyValueType from '../../Value/Type/EmptyValueType';
import EmptyValue from '../../Value/EmptyValue';
import Validation from '../../Validation/Validation';
import Computation from '../Computation/Computation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import localizeText from '../../../Localization/localizeText';

export default class DeleteEntityAction extends Action<EmptyValueType, EmptyValue>
{
    // ------------------------- Properties -------------------------

    @observable.ref value: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(value: Computation<any, any>)
    {
        super();

        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return EmptyValueType.instance;
    }

    getName()
    {
        if(this.value)
        {
            return localizeText(
                'Generic.DeleteEntityActionName',
                '${name} verwijderen',
                {
                name: this.value?.getName()
            });
        }
        else {
            return localizeText(
                'Action.DeleteEntity',
                'Record verwijderen'
            )
        }
    }

    validate(): Validation[]
    {
        if (this.value)
        {
            return this.value.validate();
        }
        else
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        'NoEntityTypeToDeleteSelected',
                        'Je hebt nog geen record gekozen om te verwijderen.'
                    )
                )
            ];
        }
    }

    isAsync(): boolean
    {
        return this.value?.isAsync() ?? false;
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'DeleteEntity';
        descriptor.value = this.value?.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        if (this.value)
        {
            return this.value.getDependencies();
        }
        else
        {
            return [];
        }
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new DeleteEntityAction(
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
