import React, { useCallback, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import CompositeLayout from '../../../../../../../@Api/Layout/Type/CompositeLayout';
import LayoutViewer, { LayoutViewerProps } from '../../../../Viewer/LayoutViewer';
import CollectionLayout from '../../../../../../../@Api/Layout/Type/CollectionLayout';
import FunctionContext from '../../../../../../../@Api/Automation/Function/FunctionContext';
import CollectionValue from '../../../../../../../@Api/Automation/Value/CollectionValue';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import safelyApplyFunction from '../../../../../../../@Api/Automation/Api/safelyApplyFunction';
import CompositeLayoutItem from '../../../../../../../@Api/Layout/Type/CompositeLayoutItem';
import PrimitiveValue from '../../../../../../../@Api/Automation/Value/PrimitiveValue';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import useReactiveValue from '../../../../../../../@Api/Automation/Api/useReactiveValue';
import areParameterAssignmentsEqual from '../../../../../../../@Api/Automation/Api/areParameterAssignmentsEqual';
import { useMemoizedArray } from '../../../../../../../@Util/Array/useMemoizedArray';
import PagedCollectionValue from '../../../../../../../@Api/Automation/Value/PagedCollectionValue';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import Value from '../../../../../../../@Api/Automation/Value/Value';
import HoverCard from '../../../../../../../@Future/Component/Generic/Card/HoverCard/HoverCard';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';

export interface CollectionLayoutViewerProps extends LayoutViewerProps<CollectionLayout>
{
    collectionValue?: Value<any, any>;
    loading?: boolean;
}

export const CollectionLayoutViewerItems: React.FC<CollectionLayoutViewerProps> =
    observer(
        props =>
        {
            const { parameterDictionary, parameterAssignment, commitContext, layout } = props;
            const context =
                useMemo(
                    () =>
                        new FunctionContext(
                            parameterDictionary,
                            parameterAssignment,
                            commitContext
                        ),
                    [
                        parameterDictionary,
                        parameterAssignment,
                        commitContext,
                    ]
                );
            const collectionValue = props.collectionValue;
            const isLoadingCollectionValue = props.loading;
            const reactiveValue =
                useReactiveValue(
                    collectionValue,
                    collectionValue?.reactiveInterface
                );
            const [ items ] =
                useAsyncResult(
                    () =>
                        Promise.all(
                            (reactiveValue ? CollectionValue.getCollection(reactiveValue) : [])
                                .map(
                                    async (value, idx) =>
                                        new CompositeLayoutItem(
                                            `${idx}.${value.getId()}`,
                                            layout.itemLayout,
                                            PrimitiveValue.getAsNumber(
                                                await safelyApplyFunction(
                                                    layout.itemRatio,
                                                    new FunctionContext(
                                                        context.parameterDictionary.getNewDictionaryWithParameter(layout.elementParameter),
                                                        context.parameterAssignment.getNewAssignmentWithParameter(
                                                            layout.elementParameter,
                                                            value
                                                        ),
                                                        context.commitContext
                                                    )
                                                )
                                            ) ?? 0,
                                            context.parameterAssignment.getNewAssignmentWithParameter(
                                                layout.elementParameter,
                                                value
                                            )
                                        )
                                )
                        ),
                    [
                        layout,
                        context,
                        reactiveValue
                    ]
                );
            const memoizedItems =
                useMemoizedArray(
                    items,
                    (a, b) =>
                        a.id === b.id
                            && a.ratio === b.ratio
                            && areParameterAssignmentsEqual(a.parameterAssignment, b.parameterAssignment),
                    []
                );
            const hasMore =
                useComputed(
                    () =>
                        reactiveValue instanceof PagedCollectionValue
                            && reactiveValue.hasMore,
                    [
                        reactiveValue,
                    ]
                );
            const [ moreCollectionValue, setMoreCollectionValue ] = useState<Value<any, any> | undefined>(undefined)
            const loadMore =
                useCallback(
                    () =>
                        (reactiveValue as PagedCollectionValue<any>)
                            .loadMore()
                            .then(setMoreCollectionValue),
                    [
                        reactiveValue,
                    ]
                );
            const compositeLayout =
                useComputed(
                    () =>
                        new CompositeLayout(
                            layout.orientation,
                            memoizedItems || [],
                            layout.spacing,
                            layout.hasDivider,
                            layout.isWrapped
                        ),
                    [
                        layout,
                        memoizedItems,
                        hasMore,
                    ]
                );

            // Only show loader when collection value is undefined, otherwise items get re-rendered
            if (isLoadingCollectionValue && collectionValue === undefined)
            {
                return <Centered
                    horizontal
                >
                    <Loader />
                </Centered>;
            }
            else
            {
                return <ViewGroup
                    orientation={
                        props.layout.orientation === 'Vertical'
                            ? 'vertical'
                            : 'horizontal'
                    }
                    spacing={props.layout.spacing}
                >
                    <ViewGroupItem>
                        <LayoutViewer
                            {...props}
                            layout={compositeLayout}
                        />
                    </ViewGroupItem>
                    {
                        moreCollectionValue !== undefined &&
                        <ViewGroupItem>
                            <CollectionLayoutViewerItems
                                {...props}
                                collectionValue={moreCollectionValue}
                            />
                        </ViewGroupItem>
                    }
                    {
                        hasMore &&
                        moreCollectionValue === undefined &&
                        <ViewGroupItem>
                            <HoverCard
                                onClick={loadMore}
                            >
                                <LocalizedText
                                    code="Generic.LoadMore"
                                    value="Meer laden"
                                />...
                            </HoverCard>
                        </ViewGroupItem>
                    }
                </ViewGroup>;
            }
        }
    );
