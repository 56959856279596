import { DateIntervalValue } from "../../../../Domain/DataObject/Type/Date/DateInterval/DateIntervalValue";
import { DataComparator } from "../ListStore";

const intervals = ['Daily', 'Weekly', 'FourWeekly', 'Monthly', 'Quarterly', 'HalfYearly', 'Yearly'];

export function createDateIntervalComparator<D>(retriever: (data: D) => DateIntervalValue): DataComparator<D>
{
    // Order DateIntervalValue with undefined last
    return (d1, d2) =>
    {
        const lhs = retriever(d1);
        const rhs = retriever(d2);

        const dateIntervalType1: number = intervalToNumber(lhs?.type);
        const dateIntervalType2: number = intervalToNumber(rhs?.type);

        if (dateIntervalType1 === undefined)
        {
            return 1;
        }

        if (dateIntervalType2 === undefined)
        {
            return -1;
        }

        if (dateIntervalType1 !== dateIntervalType2)
        {
            return dateIntervalType1 - dateIntervalType2;
        }
        else
        {
            return lhs?.frequency - rhs?.frequency
        }
    }
}

function intervalToNumber(type: string)
{
    return intervals.indexOf(type);
}

