import React from 'react';
import Icon, { IconProps } from '../../../Icon/Icon';

export interface InputIconProps extends IconProps
{

}

const InputIcon: React.FC<InputIconProps> =
    props =>
    {
        return <Icon
            {...props}
        />
    };

InputIcon.defaultProps = {
    color: 'inherit',
    size: 15
};

export default InputIcon;
