import { useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';

export default function useIsDisabled(entityType: EntityType)
{
    return useComputed(
        () =>
            !entityType.bespoke.allowWorkflowInteraction(),
        [
            entityType
        ]);
}
