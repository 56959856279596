import React from 'react';
import { observer } from 'mobx-react';
import { default as SectionModel } from '../Model/Section';
import Column from '../Column/Column';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import styles from './Section.module.scss';
import useIsMobile from '../../../../../@Util/Responsiveness/useIsMobile';

export interface SectionProps
{
    section: SectionModel;
    lastSection?: boolean;
    disabled?: boolean;
}

const Section: React.FC<SectionProps> =
    props =>
    {
        const isMobile = useIsMobile();

        return <ViewGroup
            orientation={isMobile ? 'vertical' : 'horizontal'}
            spacing={0}
            className={styles.root}
        >
            {
                props.section.columns.map(
                    (column, idx) =>
                        <ViewGroupItem
                            key={column.id}
                            ratio={isMobile ? undefined : 1}
                        >
                            <Column
                                column={column}
                                firstColumn={idx === 0 || isMobile}
                                lastColumn={idx === props.section.columns.length - 1 || isMobile}
                                lastSection={props.lastSection}
                                disabled={props.disabled}
                            />
                        </ViewGroupItem>)
            }
        </ViewGroup>;
    };

export default observer(Section);
