import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../../../../Type/Api/useTypes';
import Input from '../../../../../../../../../../Input/Input';
import Base from '../../Base/Base';
import { ContentProps } from '../../Content';
import DateTime from '../Task/DateTime/DateTime';
import CloseStateButton from '../../../../../../../../../../Button/CloseStateButton/CloseStateButton';
import { EntityPath } from '../../../../../../../../../../Path/@Model/EntityPath';
import useRelatedEntity from '../../../../../../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import useFetchedRelatedEntity from '../../../../../../../../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity';
import localize from '../../../../../../../../../../../../../@Api/Localization/localize';

export interface AppointmentProps extends ContentProps
{

}

const Appointment: React.FC<AppointmentProps> =
    props =>
    {
        const types = useTypes();
        const employeePath =
            useMemo(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Activity.Appointment.RelationshipDefinition.Owner,
                            false),
                [
                    props.entity,
                    types
                ]);


        const address =
            useRelatedEntity(
                props.entity,
                types.Activity.Appointment.RelationshipDefinition.Address,
                false
            );

        const addressCountry =
            useMemo(
                () =>
                    address &&
                    address
                        .getRelatedEntityByDefinition(
                            false,
                            types.Address.RelationshipDefinition.Country
                        ),
                [
                    address,
                    types
                ]
            );

        const addressLine = useMemo(
            () =>
                address
                    ?
                        `${address.hasValueForField(types.Address.Field.Street) ? address.getObjectValueByField(types.Address.Field.Street) : ''} ` +
                        `${address.hasValueForField(types.Address.Field.HouseNr) ? address.getObjectValueByField(types.Address.Field.HouseNr) : ''}` +
                        `${address.hasValueForField(types.Address.Field.HouseNrSuffix) ? address.getObjectValueByField(types.Address.Field.HouseNrSuffix) : ''}` +
                        ` ${address.hasValueForField(types.Address.Field.PostalCode) ? address.getObjectValueByField(types.Address.Field.PostalCode) : ''} ` +
                        `${address.hasValueForField(types.Address.Field.City) ? address.getObjectValueByField(types.Address.Field.City) : ''} ` +
                        `${localize(addressCountry?.getObjectValueByField(types.Datastore.Field.LocalizedName)) ?? ''}`
                    : '',
            [
                types,
                address,
                addressCountry
            ]
        );

        const fields = useMemo(
            () =>
                [
                    <DateTime
                        key={0}
                        {...props}
                        startDateField={types.Activity.Appointment.Field.StartDate}
                        endDateField={types.Activity.Appointment.Field.EndDate}
                        employeePath={employeePath}
                        commitContext={props.commitContext}
                    />,
                    <Input
                        key={1}
                        entity={props.entity}
                        field={types.Activity.Appointment.RelationshipDefinition.Owner}
                        labelPosition={props.labelPosition}
                        inline={props.inlineLabel}
                        commitContext={props.commitContext}
                    />,
                    props.entity.hasValueForField(types.Activity.Appointment.Field.Location) &&
                    <Input
                        key={2}
                        entity={props.entity}
                        field={types.Activity.Appointment.Field.Location}
                        labelPosition={props.labelPosition}
                        inline={props.inlineLabel}
                        commitContext={props.commitContext}
                    />,
                    <span>{ addressLine }</span>
                ],
            [
                props,
                types,
                addressLine,
                employeePath
            ]);

        return <Base
            {...props}
            suffix={
                <CloseStateButton
                    entity={props.entity}
                />
            }
        >
            {fields}
        </Base>;
    };

export default observer(Appointment);