import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { setValueByFieldInEntity } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { getValueFromStorage, setValueInStorage } from '../../../Setting/Api/useLocalSetting';
import { EntityField } from '../../../../../@Api/Model/Implementation/EntityField';

export class BespokeTimeRegistration extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    static isBillableLocalSettingCode: string = 'TimeRegistration.IsBillable';

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'TimeRegistration')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    onConstruct(entity: Entity,
                commitContext?: CommitContext): void
    {
        super.onConstruct(entity, commitContext);

        const isBillable =
            getValueFromStorage<boolean>(
                BespokeTimeRegistration.isBillableLocalSettingCode,
                false
            );

        setValueByFieldInEntity(
            entity,
            this.types.TimeRegistration.Field.IsBillable,
            isBillable,
            commitContext
        );
    }

    onValueSet(entity: Entity,
               field: EntityField,
               value: any,
               commitContext?: CommitContext)
    {
        super.onValueSet(entity, field, value, commitContext);

        if (field === this.types.TimeRegistration.Field.IsBillable)
        {
            setValueInStorage<boolean>(
                BespokeTimeRegistration.isBillableLocalSettingCode,
                value
            );
        }
    }

    extendRelationshipSelection(entity: Entity,
                                relationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean,
                                builder: EntitySelectionBuilder,
                                commitContext?: CommitContext)
    {
        super.extendRelationshipSelection(
            entity,
            relationshipDefinition,
            isParent,
            builder,
            commitContext
        );

        const types = this.entityTypeStore.bespoke.types;

        if (relationshipDefinition === types.Relationship.RelationshipDefinition.TimeRegistrations
            && isParent)
        {
            builder.where(
                cb =>
                    cb.isNotOfType(
                        builder.rootPath,
                        types.Relationship.Person.Contact.Type,
                        false,
                        true));
        }
        else if (relationshipDefinition === types.Activity.RelationshipDefinition.TimeRegistrations
            && isParent)
        {
            builder
                .if(
                    () =>
                        entity.hasRelationshipsByDefinition(
                            true,
                            types.Relationship.RelationshipDefinition.TimeRegistrations,
                            commitContext
                        ),
                    sb =>
                        sb.where(
                            cb =>
                                cb.relatedToEntity(
                                    builder.rootPath
                                        .joinTo(
                                            types.Relationship.RelationshipDefinition.Activities,
                                            true),
                                    entity.getRelatedEntityByDefinition(
                                        true,
                                        types.Relationship.RelationshipDefinition.TimeRegistrations,
                                        commitContext)
                                )
                        )
                );
        }
        else if (relationshipDefinition === types.TimeRegistration.RelationshipDefinition.SubActivity
            && !isParent)
        {
            builder.where(
                cb =>
                    cb.relatedToEntity(
                        builder.rootPath
                            .joinTo(
                                types.Activity.RelationshipDefinition.LinkedActivities,
                                true),
                        entity.getRelatedEntityByDefinition(
                            true,
                            types.Activity.RelationshipDefinition.TimeRegistrations,
                            commitContext
                        )
                    )
            );
        }
        else if (relationshipDefinition === types.Milestone.RelationshipDefinition.TimeRegistrations
            && isParent)
        {
            builder
                .where(
                    cb =>
                        cb.relatedToEntity(
                            builder.rootPath
                                .joinTo(
                                    types.Activity.Project.RelationshipDefinition.Milestones,
                                    true),
                            entity.getRelatedEntityByDefinition(
                                true,
                                types.Activity.RelationshipDefinition.TimeRegistrations,
                                commitContext
                            )
                        )
                );
        }
    }

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        const types = this.entityTypeStore.bespoke.types;

        if (relationshipDefinition === types.Activity.RelationshipDefinition.TimeRegistrations
            && isParent
            && relatedEntity)
        {
            updateRelationship(
                entity,
                true,
                types.Relationship.RelationshipDefinition.TimeRegistrations,
                relatedEntity.getRelatedEntityByDefinition(
                    true,
                    types.Relationship.RelationshipDefinition.Activities,
                    commitContext
                ),
                commitContext
            );
        }

        if (relationshipDefinition === types.TimeRegistration.RelationshipDefinition.Activity
            && relatedEntity)
        {
            const isBillable =
                getValueFromStorage<boolean>(
                    BespokeTimeRegistration.isBillableLocalSettingCode,
                    false
                );

            if (isBillable
                && !relatedEntity.hasRelationshipsByDefinition(
                    false,
                    types.TimeRegistrationActivity.RelationshipDefinition.Product,
                    commitContext
                )
            )
            {
                setValueByFieldInEntity(
                    entity,
                    types.TimeRegistration.Field.IsBillable,
                    false
                );
            }
        }
    }

    getEntityToOpen(entity: Entity): Entity
    {
        return entity.getRelatedEntityByDefinition(
            true,
            this.types.Activity.RelationshipDefinition.TimeRegistrations);
    }

    getListDependencies(): EntityPath[]
    {
        return [
            ...super.getListDependencies(),
            EntityPath.root(this.type)
                .joinTo(
                    this.types.Relationship.RelationshipDefinition.TimeRegistrations,
                    true),
            EntityPath.root(this.type)
                .joinTo(
                    this.types.Activity.RelationshipDefinition.TimeRegistrations,
                    true),
            EntityPath.root(this.type)
                .joinTo(
                    this.types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                    true),
            EntityPath.root(this.type)
                .joinTo(
                    this.types.TimeRegistration.RelationshipDefinition.Activity,
                    false
                ),
            EntityPath.root(this.type)
                .joinTo(
                    this.types.TimeRegistration.RelationshipDefinition.Activity,
                    false
                )
                .joinTo(
                    this.types.TimeRegistrationActivity.RelationshipDefinition.Product,
                    false
                )
        ];
    }

    orderByField(entity?: Entity, pathFromRelatedEntity?: RelatedEntityPath): EntityFieldPath
    {
        return EntityPath.root(this.type)
            .field(this.types.TimeRegistration.Field.StartDate);
    }

    getEventTitle(entity: Entity): string
    {
        const activity =
            entity.getRelatedEntityByDefinition(
                false,
                this.types.TimeRegistration.RelationshipDefinition.Activity);

        const relationship =
            entity.getRelatedEntityByDefinition(
                true,
                this.types.Relationship.RelationshipDefinition.TimeRegistrations);

        if (relationship)
        {
            return `${activity?.name} - ${relationship?.name}`
        }
        else
        {
            return activity?.name || super.getEventTitle(entity);
        }
    }

    isAssignableToTeam(): boolean
    {
        return true;
    }

    inheritTeamFrom(): EntityPath
    {
        return EntityPath.root(this.type)
            .joinTo(
                this.types.Activity.RelationshipDefinition.TimeRegistrations,
                true
            );
    }

    // ----------------------- Private logic ------------------------
}
