import { MeView } from './MeView';
import { MeEditor } from './MeEditor';
import { MeSpecification } from './MeSpecification';
import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../Model/DataObjectType';
import { ComparatorDescriptor } from '../../Model/ComparatorDescriptor';
import { Aggregate } from '../../Model/Aggregate';
import { MathematicalOperator } from '../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../Model/DataObjectFunction';
import { DataObject } from '../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../@Util/DependencyInjection/index';
import { DataDescriptor } from '../../Model/DataDescriptor';
import { Comparator } from '../../Model/Comparator';
import { DataObjectRepresentation } from '../../Model/DataObjectRepresentation';
import { DataObjectOperatorOverload } from '../../Model/DataObjectOperatorOverload';
import { DataObjectContext } from '../../Model/DataObjectContext';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import { DataObjectComparatorOverload } from '../../Model/DataObjectComparatorOverload';
import { DataObjectOverloadType } from '../../Model/DataObjectOverloadType';
import { UserType } from '../Api/User/UserType';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { DataObjectSpecification } from '../../Model/DataObjectSpecification';
import localizeText from '../../../../../@Api/Localization/localizeText';

export class MeType implements DataObjectType<{}>
{
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Me';
    }

    name()
    {
        return localizeText('DataObject.Type.CurrentUser', 'Huidige gebruiker');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [];
    }

    supportedAggregates(): Aggregate[]
    {
        return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return MeView;
    }

    editor(): DataObjectEditorType
    {
        return MeEditor;
    }

    specification(): DataObjectSpecificationType
    {
        return MeSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return null;
    }

    requiresInitialization(value: any): boolean
    {
        return false;
    }

    getDataFromValue(value: any, specification: DataObjectSpecification): DataDescriptor
    {
        return new DataDescriptor();
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): any
    {
        return this.currentUserStore.currentUser.entity;
    }

    valueId(value: {}): string
    {
        return 'me';
    }

    getString(value: any, representation: DataObjectRepresentation, context: DataObjectContext, dataObject: DataObject): string
    {
        return undefined;
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return false;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [
            new DataObjectComparatorOverload(
                this,
                Comparator.Equals,
                DataObjectOverloadType.Symmetric,
                type =>
                    type instanceof UserType,
                (value, relatedValue, isThisLhs) =>
                {
                    return value?.value != null
                        && relatedValue?.value != null
                        && value.value.id === relatedValue.value.id;
                })
        ];
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    isValid(dataObject: DataObject): boolean
    {
        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return false;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }
}
