import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../Value/PrimitiveValue';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import Value from '../../Value/Value';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import EmptyValue from '../../Value/EmptyValue';
import { setHours, setMinutes } from 'date-fns';
import localizeText from '../../../Localization/localizeText';

export default class DateWithTimeComputation extends Computation<PrimitiveValueType, PrimitiveValue>
{
    // ------------------------- Properties -------------------------

    @observable.ref date: Computation<any, any>;
    @observable.ref time: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(
        date: Computation<any, any>,
        time: Computation<any, any>
    )
    {
        super();

        this.date = date;
        this.time = time;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('DateTime'));
    }

    getName(): string
    {
        return localizeText('DataObject.Type.DateTime', 'Datum met tijd');
    }

    isAsync(): boolean
    {
        return this.date.isAsync()
            || this.time.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const [ date, time ] =
            await Promise.all([
                safelyApplyFunction(this.date, context),
                safelyApplyFunction(this.time, context),
            ]);

        return this.getDateWithTimeFromDateAndTime(date, time);
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        const date = safelySynchronousApplyFunction(this.date, context);
        const time = safelySynchronousApplyFunction(this.time, context);

        return this.getDateWithTimeFromDateAndTime(date, time);
    }

    getDateWithTimeFromDateAndTime(
        dateValue: Value<any, any>,
        timeValue: Value<any, any>
    ): Value<any, any>
    {
        const date = this.getDateFromDateValue(dateValue);
        const time = this.getTimeFromTimeValue(timeValue);

        if (date === undefined || time === undefined)
        {
            return EmptyValue.instance;
        }
        else
        {
            const timeSplit = time.split(':');
            const dateWithTime =
                setMinutes(
                    setHours(
                        date,
                        parseInt(timeSplit[0])
                    ),
                    parseInt(timeSplit[1])
                );

            return new PrimitiveValue(
                DataObject.constructFromTypeIdAndValue(
                    'DateTime',
                    dateWithTime
                )
            );
        }
    }

    getDateFromDateValue(date: Value<any, any>): Date | undefined
    {
        if (date instanceof PrimitiveValue
            && (date.value.type.id() === 'Date' || date.value.type.id() === 'DateTime'))
        {
            return date.value.value;
        }
        else
        {
            return undefined;
        }
    }

    getTimeFromTimeValue(time: Value<any, any>): string | undefined
    {
        if (time instanceof PrimitiveValue
            && time.value.type.id() === 'Time')
        {
            return time.value.value;
        }
        else
        {
            return undefined;
        }
    }

    validate(): Validation[]
    {
        if (!this.date)
        {
            return [
                new Validation(
                    'Error',
                    'Geen datum ingesteld')
            ];
        }

        if (!this.time)
        {
            return [
                new Validation(
                    'Error',
                    'Geen tijd ingesteld')
            ];
        }

        return [
            ...this.date.validate(),
            ...this.time.validate(),
        ];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'DateWithTime';
        descriptor.date = this.date.toDescriptor();
        descriptor.time = this.time.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.date.getDependencies(),
            ...this.time.getDependencies(),
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new DateWithTimeComputation(
            await getComputationFromDescriptor(
                descriptor.date,
                dependencyContext
            ),
            await getComputationFromDescriptor(
                descriptor.time,
                dependencyContext
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
