import { reference, type } from '../../../@Util/Serialization/Serialization';
import { Entity } from './Entity';

@type('PackMetadata')
export class PackMetadata
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'Entity') pack: Entity;
    @reference(undefined, 'TypeRepository') typeRepository: TypeRepository;
    @reference(undefined, 'FieldRepository') fieldRepository: FieldRepository;
    @reference(undefined, 'RelationshipDefinitionRepository') relationshipDefinitionRepository: RelationshipDefinitionRepository;
    state: string;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}

@type('TypeRepository')
export class TypeRepository
{
    @reference(undefined, 'EntityType', true) metadataList: any[];
    entityByMetadataId: any;
}

@type('FieldRepository')
export class FieldRepository
{
    @reference(undefined, 'EntityField', true) metadataList: any[];
    entityByMetadataId: any;
}

@type('RelationshipDefinitionRepository')
export class RelationshipDefinitionRepository
{
    @reference(undefined, 'EntityRelationshipDefinition', true) metadataList: any[];
    entityByMetadataId: any;
}
