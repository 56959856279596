import makeStyles from '@material-ui/core/styles/makeStyles';

export const useTableClasses =
    makeStyles({
        root: {
            '& td': {
                transition: 'all 500ms',
                WebkitTransition: 'all 500ms',
                MozTransition: 'all 500ms',
                OTransition: 'all 500ms',
            },
        },
    });