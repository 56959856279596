import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { TableRow } from '@material-ui/core';
import Cell from './Cell/Cell';
import styles from './Head.module.scss';
import TableHead from '@material-ui/core/TableHead';
import AddColumnButton from './AddColumnButton/AddColumnButton';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import List from '../../Model/Specification/List';
import Column from '../../Model/Specification/Column';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import { runInAction } from 'mobx';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import View from '../../Model/View';

export interface HeadProps
{
    entityType: EntityType;
    view: View;
    list: List;
    isInEditMode?: boolean;
    sortable?: boolean;
    selectable?: boolean;
    onSelect?: (value: boolean) => void;
    selectionIsIndeterminated?: boolean;
    selected?: boolean;
}

const Head: React.FC<HeadProps> =
    props =>
    {
        const { onSelect } = props;

        const onDelete =
            useCallback(
                (column: Column) =>
                    runInAction(
                        () =>
                            props.list.columns =
                                props.list.columns
                                    .filter(
                                        checkColumn =>
                                            checkColumn !== column)),
                [
                    props.list
                ]);
        const onShift =
            useCallback(
                (column: Column, isLeft: boolean) =>
                {
                    const columns = props.list.columns.slice();
                    const x = columns.indexOf(column);
                    const y = isLeft ? x - 1 : x + 1;
                    const yColumn = columns[y];
                    columns[y] = column;
                    columns[x] = yColumn;

                    runInAction(
                        () =>
                            props.list.columns = columns);
                },
                [
                    props.list
                ]);

        return <TableHead>
            <TableRow
                className={classNames(styles.root, props.isInEditMode && styles.editMode)}
            >
                {
                    props.selectable && onSelect &&
                        <TableCell
                            {...{
                                width: '1%'
                            }}
                        >
                            <Checkbox
                                indeterminate={props.selectionIsIndeterminated}
                                onToggle={onSelect}
                                checked={props.selected}
                            />
                        </TableCell>
                }
                {
                    props.sortable &&
                        <TableCell
                            {...{
                                width: '1%'
                            }}
                        >

                        </TableCell>
                }
                {
                    props.list.columns
                        .filter(
                            column =>
                                column.shouldShowColumn(
                                    props.view,
                                    props.isInEditMode
                                )
                        )
                        .map(
                            (column, idx) =>
                                <Cell
                                    key={column.id}
                                    view={props.view}
                                    list={props.list}
                                    column={column}
                                    onDelete={onDelete}
                                    onShift={onShift}
                                    isInEditMode={props.isInEditMode}
                                    first={idx === 0}
                                    last={idx === props.list.columns.length - 1}
                                    appendix={
                                        (idx === props.list.columns.length - 1) &&
                                            <div
                                                className={styles.addColumnButton}
                                            >
                                                <AddColumnButton
                                                    entityType={props.entityType}
                                                    list={props.list}
                                                />
                                            </div>
                                    }
                                />)
                }
            </TableRow>
        </TableHead>;
    };

export default observer(Head);
