import Column from './Specification/Column';
import { observable } from 'mobx';

export default class Ordering
{
    // ------------------------- Properties -------------------------

    @observable.ref column: Column;
    @observable.ref isAscending: boolean;

    // ------------------------ Constructor -------------------------

    constructor(column: Column,
                isAscending: boolean)
    {
        this.column = column;
        this.isAscending = isAscending;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get isValid()
    {
        return this.column !== undefined
            && this.column.isValid !== undefined;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any,
                          columnById: Map<string, Column>)
    {
        return new Ordering(
            columnById.get(descriptor.columnId),
            descriptor.isAscending);
    }

    toDescriptor()
    {
        return {
            columnId: this.column.id,
            isAscending: this.isAscending
        };
    }

    // ----------------------- Private logic ------------------------
}
