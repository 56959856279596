import { BaseStore } from '../../../../@Framework/Store/BaseStore';
import { observable } from 'mobx';
import { DataObjectViewerStore } from './DataObjectViewerStore';

export class DataObjectBespokeViewerStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable baseStore: DataObjectViewerStore;

    // ------------------------ Constructor -------------------------

    constructor(baseStore: DataObjectViewerStore)
    {
        super();

        this.baseStore = baseStore;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
