import { DataObject } from '../../../DataObject/Model/DataObject';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { action, computed, observable } from 'mobx';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import EmptyValue from '../../../../../@Api/Automation/Value/EmptyValue';
import Value from '../../../../../@Api/Automation/Value/Value';

export class EntitySelectionAggregateResult
{
    // ------------------------- Properties -------------------------

    @observable.ref groupEntity?: Entity;
    @observable.ref groupValue?: DataObject;
    @observable.ref groupInterval?: DataObject;
    @observable.shallow aggregates: DataObject[];
    @observable.ref groupFieldPath: EntityFieldPath;
    @observable.shallow children: EntitySelectionAggregateResult[];

    // ------------------------ Constructor -------------------------

    constructor(groupEntity: Entity,
                groupValue: DataObject,
                groupInterval: DataObject,
                aggregates: DataObject[],
                groupFieldPath: EntityFieldPath,
                children: EntitySelectionAggregateResult[])
    {
        this.groupEntity = groupEntity;
        this.groupValue = groupValue;
        this.groupInterval = groupInterval;
        this.aggregates = aggregates;
        this.groupFieldPath = groupFieldPath;
        this.children = children;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get id(): string
    {
        if (this.groupEntity)
        {
            return this.groupEntity.uuid;
        }
        else if (this.groupValue)
        {
            return this.groupValue.valueId;
        }
        else
        {
            return 'undefined';
        }
    }

    @computed
    get isEmpty(): boolean
    {
        return this.groupEntity == null && !(this.groupValue != null && !this.groupValue.isEmpty);
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    merge(result: EntitySelectionAggregateResult)
    {
        if (result.groupValue)
        {
            if (this.groupValue)
            {
                this.groupValue.setValue(result.groupValue.value);
            }
            else
            {
                this.groupValue = result.groupValue;
            }
        }
        else
        {
            // this.groupValue = undefined;
        }

        if (result.groupEntity)
        {
            this.groupEntity = result.groupEntity;
        }
        else
        {
            // this.groupEntity = undefined;
        }

        this.groupInterval = result.groupInterval;
        this.groupFieldPath = result.groupFieldPath;

        for (let i = 0; i < result.aggregates.length; i++)
        {
            if (i < this.aggregates.length)
            {
                this.aggregates[i].setValue(result.aggregates[i].value);
            }
            else
            {
                this.aggregates[i] = result.aggregates[i];
            }
        }

        const childByValueId = new Map<string, EntitySelectionAggregateResult>();

        this.children.forEach(
            child =>
                childByValueId.set(
                    child.id,
                    child));

        const foundChildIds = new Set<string>();

        result.children
            .slice()
            .forEach(
                child =>
                {
                    const childId = child.id;

                    foundChildIds.add(childId);

                    if (childByValueId.has(childId))
                    {
                        childByValueId.get(childId).merge(child);
                    }
                    else
                    {
                        this.children.push(child);
                    }
                });

        this.children
            .filter(
                child =>
                    !foundChildIds.has(child.id))
            .forEach(
                child =>
                    this.children.splice(
                        this.children.indexOf(child),
                        1));
    }

    @action.bound
    traverse(callback: (result: EntitySelectionAggregateResult) => void)
    {
        callback(this);

        this.children.forEach(
            child =>
                child.traverse(callback));
    }

    // ------------------------ Public logic ------------------------

    public getGroupValue(): Value<any, any>
    {
        if (this.groupEntity)
        {
            return new EntityValue(this.groupEntity);
        }
        else if (this.groupValue && !this.groupValue.isEmpty)
        {
            return new PrimitiveValue(this.groupValue);
        }
        else
        {
            return EmptyValue.instance;
        }
    }

    public toString()
    {
        if (this.groupEntity)
        {
            return this.groupEntity.name;
        }
        else if (this.groupValue)
        {
            return this.groupValue.toString();
        }
        else
        {
            return undefined;
        }
    }

    // ----------------------- Private logic ------------------------
}
