import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import DrawingFormInputLayoutViewer from './DrawingFormInputLayoutViewer';
import ParameterAssignment from '../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import DrawingFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/DrawingFormInputLayout';

export interface DrawingFormInputLayoutEditorProps extends LayoutEditorProps<DrawingFormInputLayout>
{

}

const DrawingFormInputLayoutEditor: React.FC<DrawingFormInputLayoutEditorProps> =
    props =>
    {
        const parameterAssignment =
            useMemo(
                () =>
                    props.parameterAssignment || new ParameterAssignment(),

                [
                    props.parameterAssignment
                ]);

        return <DrawingFormInputLayoutViewer
            parameterAssignment={parameterAssignment}
            {...props}
        />;
    };

export default observer(DrawingFormInputLayoutEditor);
