import { BaseStore } from '../../../../@Framework/Store/BaseStore';
import { computed, observable } from 'mobx';
import { LightboxStore } from '../../Lightbox/LightboxStore';

export class FileViewerLightboxStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable fileUrl: string;
    @observable fileName: string;
    @observable mimeType: string;
    @observable closeCallback: () => void;

    // ------------------------ Constructor -------------------------

    constructor(fileUrl: string, fileName: string, mimeType: string, closeCallback: () => void)
    {
        super();

        this.fileUrl = fileUrl;
        this.fileName = fileName;
        this.mimeType = mimeType;
        this.closeCallback = closeCallback;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    @computed
    get lightboxStore(): LightboxStore
    {
        return new LightboxStore(
            'attach_file',
            this.fileName,
            true,
            this.closeCallback);
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
