import * as React from 'react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { RootStore } from '../RootStore';
import { PublicEnvironment } from './Public/PublicEnvironment';
import { observer } from 'mobx-react';
import Centered from '../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../@Future/Component/Generic/Loader/Loader';
import PortalEnvironment from './Portal/PortalEnvironment';
import { OrganizationEnvironment } from './Organization/OrganizationEnvironment';

@observer
export class Environment extends BaseComponent<RootStore>
{
    protected renderLoader(store: RootStore): any
    {
        return <Centered
            horizontal
            vertical
        >
            <Loader />
        </Centered>;
    }

    renderComponent(store: RootStore)
    {
        // Only render app if the user is authenticated and no public page should be routed to
        if (store.isInPortalEnvironment)
        {
            return <PortalEnvironment
                store={store.portalEnvironmentStore}
            />;
        }
        if (store.isInOrganizationEnvironment)
        {
            if (store.context.authenticationManager.isLoading
                || store.context.welcomeStore.isLoading)
            {
                return this.renderLoader(store);
            }
            else
            {
                return <OrganizationEnvironment
                    store={store.organizationEnvironmentStore}
                />;
            }
        }
        else
        {
            return <PublicEnvironment
                store={store.publicEnvironmentStore}
            />;
        }
    }
}
