import React, { useCallback, useEffect, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../../Viewer/LayoutViewer';
import DrawingFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/DrawingFormInputLayout';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import EmptyValue from '../../../../../../@Api/Automation/Value/EmptyValue';
import PrimitiveValue from '../../../../../../@Api/Automation/Value/PrimitiveValue';
import convertDataUrlToFile from '../../../../../../@Util/File/convertDataUrlToFile';
import SignatureCanvas from 'react-signature-canvas';
import styles from './DrawingFormInputLayoutViewer.module.scss';
import useResizeObserver from 'use-resize-observer/polyfilled';
import ClearButton from '../../../../../../@Future/Component/Generic/Button/Variant/ClearButton/ClearButton';
import { runInAction } from 'mobx';
import { FileValue } from '../../../../DataObject/Type/File/FileValue';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import { useFormInputParameter } from '../Api/useFormInputParameter';
import { useFormInputParameterValue } from '../Api/useFormInputParameterValue';

export interface DrawingFormInputLayoutViewerProps extends LayoutViewerProps<DrawingFormInputLayout>
{

}

const DrawingFormInputLayoutViewer: React.FC<DrawingFormInputLayoutViewerProps> =
    props =>
    {
        const parameter =
            useFormInputParameter(
                props.layout,
                props.parameterDictionary);

        const value =
            useFormInputParameterValue(
                parameter,
                props.parameterAssignment);

        const [ signature, setSignature ] = useState<any>();

        useEffect(
            () =>
            {
                if ((!value || value.isEmpty) && signature)
                {
                    signature.clear();
                }
            },
            [
                value,
                signature
            ]);

        const onChange =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            props.parameterAssignment.setValue(
                                parameter,
                                signature.isEmpty()
                                    ?
                                        EmptyValue.instance
                                    :
                                        new PrimitiveValue(
                                            DataObject.constructFromTypeIdAndValue(
                                                'File',
                                                FileValue.fromFile(
                                                    convertDataUrlToFile(
                                                        signature.toDataURL(),
                                                        'signature.png')))))),
                [
                    props.parameterAssignment,
                    parameter,
                    signature
                ]);

        const clear =
            useCallback(
                () =>
                {
                    signature.clear();
                    onChange();
                },
                [
                    signature,
                    onChange
                ]);

        useEffect(
            () =>
            {
                if (signature)
                {
                    signature.onEnd = onChange;
                }
            },
            [
                signature
            ]);

        const { ref, width } = useResizeObserver<HTMLDivElement>();

        const style =
            useComputed(
                () => ({
                    height: props.layout.height,
                    width: width
                }),
                [
                    props.layout,
                    width
                ]);

        const isDisabled =
            useComputed(
                () =>
                    props.layout.isDisabled?.synchronouslyEvaluate(
                        new FunctionContext(
                            props.parameterDictionary,
                            props.parameterAssignment,
                            props.commitContext
                        )
                    ),
                [
                    props.layout,
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        return <div
            ref={ref}
            className={styles.root}
        >
            <SignatureCanvas
                ref={setSignature}
                canvasProps={style}
                onEnd={isDisabled ? undefined : onChange}
            />
            <div
                className={styles.clearButton}
            >
                <ClearButton
                    onClick={clear}
                />
            </div>
        </div>;
    };

export default observer(DrawingFormInputLayoutViewer);
