import React, { Fragment } from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import BlockOptionsPanel from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Settings/BlockOptionsPanel';
import ColorOptionsPanel from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Settings/ColorOptionsPanel';
import GutenbergBlockType from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Gutenberg/GutenbergBlockType';
import { FormSubmitButtonBlockId } from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/DocumentEditor';
import SubmitButtonEditor from './Editor/SubmitButtonEditor';
import SubmitButtonViewer from './Viewer/SubmitButtonViewer';
import StyledBlock from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/StyledBlock/StyledBlock';
import Icon from '../../../../../../@Future/Component/Generic/Icon/Icon';
import GoogleTagManagerSettings from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Settings/GoogleTagManagerSettings';

const FormSubmitButtonBlock: GutenbergBlockType =
{
    name: FormSubmitButtonBlockId,
    configuration:
        {
            title: {
                code: 'GutenbergBlock.SendButton',
                value: 'Verzendknop'
            },
            icon: <Icon icon="done" />,
            category: 'common',

            attributes:
            {
                content:
                {
                    type: 'string',
                    source: 'html',
                    selector: 'p',
                    query: undefined,
                }
            },

            edit: props =>
            {
                return (
                    <Fragment>
                        <InspectorControls>
                            <BlockOptionsPanel props={props} />
                            <ColorOptionsPanel props={props} />
                            <GoogleTagManagerSettings props={props} />
                        </InspectorControls>
                        <StyledBlock
                            block={props}
                        >
                            <SubmitButtonEditor />
                        </StyledBlock>
                    </Fragment>
                );
            },

            save: (props) =>
            {
                return <p>
                    {(props.attributes as any).content}
                </p>;
            }
        },

    view: block =>
    {
        return <StyledBlock
            block={block}
        >
            <SubmitButtonViewer
                buttonId={block.attributes.buttonId}
            />
        </StyledBlock>;
    }
};

export default FormSubmitButtonBlock;
