import React from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ActivityTemplateSelector from './Selector/ActivityTemplateSelector';
import ActivityTemplateUploader from './Uploader/ActivityTemplateUploader';
import RightAlignedButtonGroup from '../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import CancelButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import { ActivityTemplateEditorProps } from '../ActivityTemplateEditor';

export interface ActivityTemplateConstructorProps extends ActivityTemplateEditorProps
{
    onCancel: () => void;
}

const ActivityTemplateConstructor: React.FC<ActivityTemplateConstructorProps> =
    props =>
    {
        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <ViewGroupItem>
                <ActivityTemplateSelector
                    {...props}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <ActivityTemplateUploader
                    {...props}
                />
            </ViewGroupItem>
            {
                props.onCancel &&
                    <ViewGroupItem>
                        <RightAlignedButtonGroup>
                            <CancelButton
                                onClick={props.onCancel}
                            />
                        </RightAlignedButtonGroup>
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(ActivityTemplateConstructor);
