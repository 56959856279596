import * as React from 'react';
import { Theme, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { ListItem } from '../List/V2/Item/ListItem';
import { ViewGroupStore } from './ViewGroupStore';

// noinspection TsLint
const styles =
    (theme: Theme) =>
        ({

        });

@observer
class ViewGroupList extends BaseComponent<ViewGroupStore>
{
    renderLoader(store: ViewGroupStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: ViewGroupStore): JSX.Element
    {
        let flexAlignment;
        let flexJustifyContent;

        switch (store.alignment)
        {
            case 'start': flexAlignment = 'flex-start'; break;
            case 'end': flexAlignment = 'flex-end'; break;
            default: flexAlignment = undefined;
        }

        switch (store.justifyContent)
        {
            case 'start': flexJustifyContent = 'flex-start'; break;
            case 'end': flexJustifyContent = 'flex-end'; break;
            default: flexJustifyContent = undefined;
        }

        return <div
            style={{
                display: 'flex',
                alignItems: flexAlignment,
                justifyContent: flexJustifyContent,
                flexDirection: store.isVertical ? 'column' : undefined,
                ...store.style
            }}
        >
            {
                store.visibleItemStores.map(
                    (itemStore, index) =>
                        <div
                            key={itemStore.id}
                            style={{
                                flexGrow:
                                    itemStore.data.growRatio
                                        ?
                                            itemStore.data.growRatio
                                        :
                                            undefined,
                                marginLeft:
                                    store.isHorizontal
                                        ?
                                            index !== 0 && itemStore.data.spacing > 0
                                                ?
                                                    itemStore.data.spacing / 2
                                                :
                                                    undefined
                                        :
                                            undefined,
                                marginRight:
                                    store.isHorizontal
                                        ?
                                            index !== (store.visibleItemStores.length - 1) && itemStore.data.spacing > 0
                                                ?
                                                    itemStore.data.spacing / 2
                                                :
                                                    undefined
                                        :
                                            undefined,
                                marginTop:
                                    store.isVertical
                                        ?
                                            index !== 0 && itemStore.data.spacing > 0
                                                ?
                                                    itemStore.data.spacing / 2
                                                :
                                                    undefined
                                        :
                                            undefined,
                                marginBottom:
                                    store.isVertical
                                        ?
                                            index !== (store.visibleItemStores.length - 1) && itemStore.data.spacing > 0
                                                ?
                                                    itemStore.data.spacing / 2
                                                :
                                                    undefined
                                        :
                                            undefined,
                                ...itemStore.data.style
                            }}
                        >
                            <ListItem
                                store={itemStore}
                            />
                        </div>)
            }
        </div>;
    }
}

export default withStyles(styles)(ViewGroupList);
