import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import { runInAction } from 'mobx';
import DimensionEditor from '../../Style/DimensionEditor/DimensionEditor';
import Dimension from '../../../../../@Api/Layout/Style/Dimension';
import ComputedImageLayout from '../../../../../@Api/Layout/Type/ComputedImageLayout';
import ComputationEditor from '../../../Entity/Viewer/Content/Automation/Editor/Computation/ComputationEditor';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';

export interface ComputedImageLayoutControlsProps extends LayoutEditorProps<ComputedImageLayout>
{

}

const ComputedImageLayoutControls: React.FC<ComputedImageLayoutControlsProps> =
    props =>
    {
        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        props.parameterDictionary,
                        props.parameterAssignment,
                        props.commitContext
                    ),
                [
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        const setImage =
            useCallback(
                (image: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.layout.image = image),
                [
                    props.layout
                ]);

        const setWidth =
            useCallback(
                (width: Dimension) =>
                    runInAction(
                        () =>
                            props.layout.width = width),
                [
                    props.layout
                ]);

        const setHeight =
            useCallback(
                (height: Dimension) =>
                    runInAction(
                        () =>
                            props.layout.height = height),
                [
                    props.layout
                ]);

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.Image"
                            value="Afbeelding"
                        />
                    }
                >
                    <ComputationEditor
                        value={props.layout.image}
                        onChange={setImage}
                        context={context}
                    />
                </Input>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.Width"
                            value="Breedte"
                        />
                    }
                >
                    <DimensionEditor
                        value={props.layout.width}
                        onChange={setWidth}
                    />
                </Input>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.Height"
                            value="Hoogte"
                        />
                    }
                >
                    <DimensionEditor
                        value={props.layout.height}
                        onChange={setHeight}
                    />
                </Input>
            </InputGroup>
        </CardInset>;
    };

export default observer(ComputedImageLayoutControls);
