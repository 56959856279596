import React from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { Table, TableBody, TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { AttachmentVersionsRowTable } from './AttachmentVersionsTableRow';
import { createCreationDateComparator } from '../../../../../List/Comparator/EntityComparator';
import Icon from '../../../../../../../../@Future/Component/Generic/Icon/Icon';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import usePreviousVersions from './usePreviousVersions';

export interface AttachmentVersionsTableProps
{
    attachment: Entity;
    commitContext?: CommitContext;
}

export const AttachmentVersionsTable: React.FC<AttachmentVersionsTableProps> =
    observer(
        ({
             attachment,
             commitContext
         }) =>
        {
            const [ previousVersions, isLoading ] = usePreviousVersions(
                attachment,
                commitContext
            )

            if (isLoading || !previousVersions || previousVersions.length === 0)
            {
                return null;
            }

            return <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            width="20%"
                        >
                            <LocalizedText
                                code="Attachment.PreviousVersions"
                                value="Vorige versies"
                            />
                        </TableCell>
                        <TableCell
                            width="20%"
                        >
                            <LocalizedText
                                code="Attachment.Created"
                                value="Aangemaakt"
                            />
                            <Icon icon="arrow_upward"></Icon>
                        </TableCell>
                        <TableCell
                            width="20%"
                        >
                            <LocalizedText
                                code="Attachment.CreatedBy"
                                value="Door"
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        previousVersions
                            .sort(createCreationDateComparator(e => e, 'Descending'))
                            .map(
                                previousVersion =>
                                    <AttachmentVersionsRowTable
                                        previousVersion={previousVersion}
                                    />
                            )
                    }
                </TableBody>
            </Table>
        }
    );