import * as React from 'react';
import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import styles from './RichTextIframe.module.scss';
import { classNames } from '../../../../@Future/Util/Class/classNames';
import { RichTextIframeProps } from './RichTextIframe';
import { GlobalEnvironment } from '../../../../@Global/GlobalEnvironment';

const CspCompliantRichTextIframe: React.FC<RichTextIframeProps> =
    ({
        value,
        allowScrolling,
     }) =>
    {
        const ref = useRef<any>();

        useEffect(
            () =>
            {
                const listener =
                    event =>
                    {
                        if (ref.current)
                        {
                            if (event.data.type === 'HeightUpdate')
                            {
                                ref.current.style.height = `${event.data.height}px`;
                            }
                            else if (event.data.type === 'ReadyForContent')
                            {
                                try
                                {
                                    ref.current.contentWindow.postMessage(
                                        {
                                            type: 'UpdateContent',
                                            content: value,
                                        },
                                        '*'
                                    );
                                }
                                catch (e)
                                {
                                    console.error(e);
                                }
                            }
                        }
                        else
                        {
                            console.error('Received message from iframe but ref is not yet set! Event:', event);
                        }
                    };

                window.addEventListener(
                    'message',
                    listener
                );

                // Whenever the value changes, then immediately update the content
                if (ref.current)
                {
                    ref.current.contentWindow.postMessage(
                        {
                            type: 'UpdateContent',
                            content: value,
                        },
                        '*'
                    );
                }

                return () =>
                    window.removeEventListener(
                        'message',
                        listener
                    );
            },
            [
                ref,
                value,
            ]
        );

        return <div
            style={
                allowScrolling
                    ? {
                        maxHeight: '70vh',
                        overflowY: 'auto',
                    } : {}
            }
        >
            <iframe
                title="CspCompliantRichTextIframe"
                src={GlobalEnvironment.HTML_VIEWER_ENDPOINT}
                className={classNames(styles.root)}
                ref={ref}
                frameBorder={0}
                onError={
                    error =>
                    {
                        console.error(error);
                    }
                }
            />
        </div>;
    };

export default observer(CspCompliantRichTextIframe);

