import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';
import equalsEntity from '../../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import useChildRelation from '../../../../../../../../@Api/Entity/Bespoke/Relationship/useChildRelation';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import useTypes from '../../../../../Type/Api/useTypes';
import RelatedList from '../../../../Shared/RelatedList/RelatedList';
import { EntityFieldPath } from '../../../../../Path/@Model/EntityFieldPath';
import { Comparator } from '../../../../../../DataObject/Model/Comparator';
import { DataObject } from '../../../../../../DataObject/Model/DataObject';
import getViewParameters from '../../../../../View/Api/getViewParameters';
import { ViewParams } from '../../../../../View/Model/ViewParams';
import ComparisonPredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import PrimitiveValue from '../../../../../../../../@Api/Automation/Value/PrimitiveValue';

export interface ContactListProps
{
    entity: Entity;
}

const ContactList: React.FC<ContactListProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();
        const relation = useChildRelation(props.entity);
        const path =
            useComputed(
                () =>
                    equalsEntity(relation, currentUserStore.environmentEntity)
                        ?
                            relation &&
                                EntityPath.fromEntity(relation)
                                    .joinTo(
                                        types.Relation.RelationshipDefinition.Relationships,
                                        false)
                                    .castTo(types.Relationship.Person.Contact.Employee.Type)
                        :
                            relation &&
                                EntityPath.fromEntity(relation)
                                    .joinTo(
                                        types.Relation.RelationshipDefinition.Relationships,
                                        false)
                                    .castTo(types.Relationship.Person.Contact.Type),
                [
                    relation,
                    currentUserStore,
                    types
                ]);

        const fieldPathFilter =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    !(fieldPath.relationshipDefinition === types.Relation.RelationshipDefinition.Relationships
                        && fieldPath.isParentRelationship),
                [
                    types
                ]);
        const viewParameters =
            useMemo(
                () =>
                    path &&
                        getViewParameters(path.entityType),
                [
                    path,
                ]
            );
        const viewParameter =
            useMemo(
                () =>
                    viewParameters?.getParameterById(ViewParams.Entity),
                [
                    viewParameters
                ]
            );
        const filter =
            useMemo(
                () =>
                    path &&
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                viewParameter,
                                EntityPath.fromEntityType(path.entityType)
                                    .field(types.Relationship.Field.IsFormer)
                            ),
                            Comparator.Equals,
                            new PrimitiveValue(
                                DataObject.constructFromTypeIdAndValue(
                                    'Boolean',
                                    false
                                )
                            )
                        ),
                [
                    viewParameter,
                    path,
                    types,
                ]);

        if (relation)
        {
            return <RelatedList
                entity={relation}
                path={path}
                fieldPathFilter={fieldPathFilter}
                filterParameter={viewParameter}
                filter={filter}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(ContactList);
