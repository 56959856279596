import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import styles from './EnvironmentUserList.module.scss';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { User } from '../../../../../../../../@Api/Model/Implementation/User';
import OverlineLabel from '../../../../../../../../@Future/Component/Generic/Label/Variant/Overline/OverlineLabel';
import LocalizedText from '../../../../../../../Domain/Localization/LocalizedText/LocalizedText';
import useSwitch from '../../../../../../../../@Util/Switch/useSwitch';
import IconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import TextEditor from '../../../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import { useDelayedValue } from '../../../../../../../../@Future/Util/Hook/useDelayedValue';
import EnvironmentUser from './EnvironmentUser/EnvironmentUser';
import ApiControllerContext from '../../../../../../../../@Api/Controller/ApiControllerContext';

export interface EnvironmentUserListProps
{
    users?: User[];
    currentUser?: User;
    onSelect: (user: User) => void;
    onSwitched?: (user: User) => void;
    onNoLicenseError: (user: User) => void;
}

const EnvironmentUserList: React.FC<EnvironmentUserListProps> =
    props =>
    {
        const apiControllerContext = useContext(ApiControllerContext);

        const { users, onSelect, onSwitched, onNoLicenseError } = props;

        const [ isSearchEnabled, enableSearch, disableSearch ] = useSwitch(false);
        const [ searchQuery, setSearchQuery ] = useState<string | undefined>(undefined);
        const [ searchUserList, setSearchUserList ] = useState<Array<User>>([]);

        const delayedSearchQuery = useDelayedValue(searchQuery);
        const filteredUsers =
            useMemo(
                () =>
                    isSearchEnabled && delayedSearchQuery
                        ?
                            searchUserList
                        :
                            (users ?? []),
                [
                    isSearchEnabled,
                    users,
                    delayedSearchQuery,
                    searchUserList
                ]
            );

        const sortedFilteredUsers =
            useMemo(
                () =>
                    props.currentUser === undefined
                        ? filteredUsers
                        : filteredUsers.sort(
                            (a, b) =>
                                a.id === props.currentUser.id
                                    ? -1
                                    : b.id === props.currentUser.id
                                        ? 1
                                        : 0
                        ),
                [
                    props.currentUser,
                    filteredUsers,
                ]
            );

        const searchedUserList =
            useCallback(
                () =>
                    isSearchEnabled && delayedSearchQuery
                        ?
                             apiControllerContext.accountUserController
                                .getUsersInAccount(
                                    undefined,
                                    undefined,
                                    delayedSearchQuery
                                )
                                .then(
                                    result =>
                                        {
                                            setSearchUserList(result)
                                        }
                                )
                        :
                            (users ?? []),
                [
                    setSearchUserList,
                    isSearchEnabled,
                    users,
                    delayedSearchQuery
                ]
            );

        useEffect(
            () =>
            {
                searchedUserList()
            },
            [
                delayedSearchQuery,
                setSearchQuery,
                searchQuery
            ]
        )

        return <ViewGroup
           orientation="vertical"
           spacing={16}
       >
           <ViewGroupItem
               className={styles.menuHeaderWrapper}
           >
               {
                   isSearchEnabled
                       ?
                           <div
                               className={styles.menuHeader}
                           >
                               <ViewGroup
                                   orientation="horizontal"
                                   spacing={15}
                                   alignment="center"
                               >
                                   <ViewGroupItem
                                       ratio={1}
                                   >
                                       <TextEditor
                                           value={searchQuery}
                                           onChange={setSearchQuery}
                                           autoFocus
                                           placeholder={`${localizeText('Generic.Search', 'Zoeken')}...`}
                                       />
                                   </ViewGroupItem>
                                   <ViewGroupItem>
                                       <IconButton
                                           icon="close"
                                           onClick={disableSearch}
                                           tooltip={`${localizeText('Generic.Cancel', 'Annuleren')}`}
                                       />
                                   </ViewGroupItem>
                               </ViewGroup>
                           </div>
                       :
                           <ViewGroup
                               orientation="horizontal"
                               spacing={15}
                               alignment="center"
                           >
                               <ViewGroupItem
                                   ratio={1}
                               >
                                   <OverlineLabel
                                       className={styles.menuHeader}
                                   >
                                       <LocalizedText
                                           code="Environment"
                                           value="Omgeving"
                                       />
                                   </OverlineLabel>
                               </ViewGroupItem>
                               <ViewGroupItem>
                                   <IconButton
                                       icon="search"
                                       onClick={enableSearch}
                                       tooltip={`${localizeText('Generic.Search', 'Zoeken')}...`}
                                   />
                               </ViewGroupItem>
                           </ViewGroup>
               }

           </ViewGroupItem>
           <ViewGroupItem
               ratio={1}
           >
               <Menu
                   disableMaxHeight
               >
                   {
                       sortedFilteredUsers &&
                       sortedFilteredUsers.map(
                               user =>
                                   <EnvironmentUser
                                       key={user.id}
                                       user={user}
                                       isSelected={props.currentUser?.id === user.id}
                                       onSelect={onSelect}
                                       onSwitched={onSwitched}
                                       onNoLicenseError={onNoLicenseError}
                                   />
                            )
                   }
                   {props.children}
               </Menu>
           </ViewGroupItem>
       </ViewGroup>;
    };

export default observer(EnvironmentUserList);
