import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { DatasetProps } from '../Dataset';
import AutoReloadContext from '../Context/AutoReloadContext';
import ShowMenuButtonInItemsContext from '../Context/ShowMenuButtonInItemsContext';
import FavoriteCallbackContext from '../Favorite/FavoriteCallbackContext';
import EntityTypeContext from '../Segment/EntityTypeContext/EntityTypeContext';
import FilterContext from '../Segment/FilterContext/FilterContext';
import ExpansionGroup from '../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import ExpansionPanel from '../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import DragHandle from '../../../DragAndDrop/DragHandle/DragHandle';
import Header from '../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import styles from '../Dataset.module.scss';
import ExpansionHeader from '../../../../../@Future/Component/Generic/Label/Variant/ExpansionHeader/ExpansionHeader';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import { buttonInset } from '../../../../../@Resource/Theme/Theme';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Button from '../Favorite/Button/Button';
import EntityConstructorButton from '../../Button/EntityConstructorButton/EntityConstructorButton';
import AgendaButton from '../../Agenda/Button/AgendaButton';
import MenuButton from '../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Empty from '../Segment/Empty/Empty';
import Segment from '../Segment/Segment';
import IdContext from '../Context/IdContext';
import useExpanded from '../Api/useExpanded';
import useToggle from '../../../../../@Util/Toggle/useToggle';
import useCount from '../Api/useCount';
import DatasetContext from '../Context/DatasetContext';
import NameContext from '../Context/NameContext';
import CloseIconButton from '../../../../../@Future/Component/Generic/Button/Variant/CloseIconButton/CloseIconButton';
import HighlightedEntityContext from '../Context/HighlightedEntityContext';
import OnOpenEntityContext from '../Context/OnOpenEntityContext';
import SelectionOptions from '../../SelectionOptions/SelectionOptions';
import DeleteItem from '../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import Item from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import { openDatasetConfiguration } from '../../@Util/openDatasetConfiguration';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import CurrentUserContext from '../../../User/CurrentUserContext';

export interface ContentProps extends DatasetProps
{

}

const Content: React.FC<ContentProps> =
    props =>
    {
        const { entityType, segments, filter } = props.dataset;
        const currentUserStore = useContext(CurrentUserContext);

        const segment =
            useComputed(
                () =>
                {
                    if (segments.length > 0)
                    {
                        return segments[0];
                    }
                },
                [
                    segments
                ]);

        const nextSegments =
            useComputed(
                () =>
                {
                    return segments.slice(1);
                },
                [
                    segments
                ]);

        const [ isExpanded, toggleExpansion ] =
            useExpanded(
                props.id,
                props.disableExpansionMemory,
                props.expanded);

        const [ doAutoReload, setDoAutoReload ] = useToggle(props.autoReload);

        useEffect(
            () =>
            {
                if (props.autoReload !== undefined)
                {
                    setDoAutoReload(props.autoReload);
                }
            },
            [
                props.autoReload,
                setDoAutoReload
            ]);

        const isCalendar =
            useMemo(
                () =>
                    false,
                [

                ]);

        const filterOptions =
            useMemo(
                () => ({
                    parameter: props.dataset.parameter,
                }),
                [
                    props.dataset,
                ]
            );
        const count =
            useCount(
                entityType,
                filter,
                filterOptions,
                doAutoReload
            );

        const countAsString =
            useComputed(
                () =>
                    count && count.toString(),
                [
                    count
                ]);

        const manageDataset =
            useCallback(
                () =>
                    openDatasetConfiguration(
                        props.dataset.entityType,
                        props.entity),
                [
                    props.dataset,
                    props.entity
                ]);

        return <IdContext.Provider
            value={props.id}
        >
            <NameContext.Provider
                value={props.name}
            >
                <DatasetContext.Provider
                    value={props.dataset}
                >
                    <AutoReloadContext.Provider
                        value={doAutoReload}
                    >
                        <ShowMenuButtonInItemsContext.Provider
                            value={props.showMenuButtonInItems}
                        >
                            <FavoriteCallbackContext.Provider
                                value={props.onFavorite}
                            >
                                <EntityTypeContext.Provider
                                    value={entityType}
                                >
                                    <FilterContext.Provider
                                        value={filter}
                                    >
                                        <HighlightedEntityContext.Provider
                                            value={props.highlightedEntity}
                                        >
                                            <OnOpenEntityContext.Provider
                                                value={props.onOpenEntity}
                                            >
                                                <ExpansionGroup>
                                                    <ExpansionPanel
                                                        id={props.id}
                                                        summary={
                                                            <DragHandle>
                                                                <Header
                                                                    title={props.name}
                                                                    large
                                                                    classes={{
                                                                        root: styles.header,
                                                                        title: styles.title
                                                                    }}
                                                                >
                                                                    {
                                                                        count !== undefined &&
                                                                        <ExpansionHeader>
                                                                            <ViewGroup
                                                                                orientation="horizontal"
                                                                                spacing={buttonInset}
                                                                                alignment="center"
                                                                            >
                                                                                <ViewGroupItem
                                                                                    className={styles.headerButtons}
                                                                                >
                                                                                    <ViewGroup
                                                                                        orientation="horizontal"
                                                                                        spacing={buttonInset}
                                                                                        alignment="center"
                                                                                    >
                                                                                        {
                                                                                            props.onFavorite &&
                                                                                                <ViewGroupItem>
                                                                                                    <Button />
                                                                                                </ViewGroupItem>
                                                                                        }
                                                                                        <ViewGroupItem>
                                                                                            <SelectionOptions
                                                                                                entityType={props.dataset.entityType}
                                                                                                parameters={props.dataset.parameters}
                                                                                                segment={props.dataset.segments.length > 0 ? props.dataset.segments[0] : undefined}
                                                                                                name={props.name}
                                                                                                filter={props.dataset.filter}
                                                                                                list={props.dataset.list}
                                                                                                itemLayout={props.dataset.itemLayout}
                                                                                            />
                                                                                        </ViewGroupItem>
                                                                                        {
                                                                                            props.headerAppendix &&
                                                                                                <ViewGroupItem>
                                                                                                    {props.headerAppendix}
                                                                                                </ViewGroupItem>
                                                                                        }
                                                                                    </ViewGroup>
                                                                                </ViewGroupItem>
                                                                                {
                                                                                    !isCalendar &&
                                                                                    !props.hideConstructorButton &&
                                                                                    count &&
                                                                                    count.value > 0 &&
                                                                                    <ViewGroupItem>
                                                                                        <EntityConstructorButton
                                                                                            entityType={entityType}
                                                                                            isFinalType
                                                                                            filter={filter}
                                                                                        />
                                                                                    </ViewGroupItem>
                                                                                }
                                                                                {
                                                                                    !isCalendar &&
                                                                                    <ViewGroupItem>
                                                                                        {countAsString}
                                                                                    </ViewGroupItem>
                                                                                }
                                                                                {
                                                                                    isCalendar &&
                                                                                    <ViewGroupItem>
                                                                                        <AgendaButton />
                                                                                    </ViewGroupItem>
                                                                                }
                                                                                {
                                                                                    props.onDelete &&
                                                                                        <ViewGroupItem>
                                                                                            <div
                                                                                                onClick={e => e.stopPropagation()}
                                                                                            >
                                                                                                <MenuButton>
                                                                                                    <Menu>
                                                                                                        {
                                                                                                            props.entity && currentUserStore.isAllowedInConfiguration &&
                                                                                                                <Item
                                                                                                                    onClick={manageDataset}
                                                                                                                    name={
                                                                                                                        <LocalizedText
                                                                                                                            code="Generic.Manage"
                                                                                                                            value="Beheren"
                                                                                                                        />
                                                                                                                    }
                                                                                                                />
                                                                                                        }
                                                                                                        <DeleteItem
                                                                                                            onClick={props.onDelete}
                                                                                                        />
                                                                                                    </Menu>
                                                                                                    {/*<div*/}
                                                                                                    {/*    className={styles.autoReloadSwitch}*/}
                                                                                                    {/*>*/}
                                                                                                    {/*    <Input*/}
                                                                                                    {/*        label="Automatisch herladen om wijzigingen van andere gebruikers of systemen te volgen"*/}
                                                                                                    {/*        labelPosition="right"*/}
                                                                                                    {/*    >*/}
                                                                                                    {/*        <Switch*/}
                                                                                                    {/*            onToggle={setDoAutoReload}*/}
                                                                                                    {/*            checked={doAutoReload}*/}
                                                                                                    {/*        />*/}
                                                                                                    {/*    </Input>*/}
                                                                                                    {/*</div>*/}
                                                                                                </MenuButton>
                                                                                            </div>
                                                                                        </ViewGroupItem>
                                                                                }
                                                                                {
                                                                                    props.onClose &&
                                                                                        <ViewGroupItem>
                                                                                            <CloseIconButton
                                                                                                onClick={props.onClose}
                                                                                            />
                                                                                        </ViewGroupItem>
                                                                                }
                                                                            </ViewGroup>
                                                                        </ExpansionHeader>
                                                                    }
                                                                </Header>
                                                            </DragHandle>
                                                        }
                                                        expansion={
                                                            count && count.value === 0
                                                                ?
                                                                    <Empty
                                                                        dataset={props.entity}
                                                                        entityType={entityType}
                                                                    />
                                                                :
                                                                    <Segment
                                                                        entityType={entityType}
                                                                        segment={segment}
                                                                        nextSegments={nextSegments}
                                                                        name={props.name}
                                                                        root
                                                                    />
                                                        }
                                                        expanded={isExpanded}
                                                        onExpand={toggleExpansion}
                                                        onCollapse={toggleExpansion}
                                                    />
                                                </ExpansionGroup>
                                            </OnOpenEntityContext.Provider>
                                        </HighlightedEntityContext.Provider>
                                    </FilterContext.Provider>
                                </EntityTypeContext.Provider>
                            </FavoriteCallbackContext.Provider>
                        </ShowMenuButtonInItemsContext.Provider>
                    </AutoReloadContext.Provider>
                </DatasetContext.Provider>
            </NameContext.Provider>
        </IdContext.Provider>;
    };

export default observer(Content);
