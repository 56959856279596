import React, { useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import CampaignChartDelivered from '../../../Viewer/Content/Activity/Campaign/Charts/CampaignChartDelivered';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CampaignChartOpened from '../../../Viewer/Content/Activity/Campaign/Charts/CampaignChartOpened';
import styles from './Campaign.module.scss';
import useCampaignTotalEmailCount from '../../../Viewer/Content/Activity/Campaign/Hooks/useCampaignTotalEmailCount';
import useTypes from '../../../Type/Api/useTypes';
import LocalizerContext from '../../../../../../@Service/Localization/LocalizerContext';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import useCampaignPhaseCode from '../../../Viewer/Content/Activity/Campaign/Hooks/useCampaignPhaseCode';
import { EntityExpansionBuilder } from '../../../Selection/Builder/EntityExpansionBuilder';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import Click from '../Click/Click';
import Inset from '../Inset/Inset';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface CampaignProps
{
    entity: Entity;
}

const Campaign: React.FC<CampaignProps> =
    props =>
    {
        const types = useTypes();
        const localizer = useContext(LocalizerContext);
        const campaign = props.entity;

        useEffect(
            () =>
            {
                new EntityExpansionBuilder(
                    campaign.entityType,
                    [
                        campaign
                    ],
                    [
                        EntityPath.fromEntity(campaign).joinTo(
                            types.Activity.Campaign.RelationshipDefinition.Phase,
                            false)
                    ])
                    .expand()
            },
            [
                campaign,
                types
            ]);

        const numberOfEmails = useCampaignTotalEmailCount(campaign);

        const phaseCode = useCampaignPhaseCode(campaign);
        const dateScheduled = useEntityValue<Date>(campaign, types.Activity.Campaign.Field.DateScheduled);
        const dateDispatched = useEntityValue<Date>(campaign, types.Activity.Campaign.Field.DateDispatched);

        const phaseDescription =
            useMemo(
                () =>
                {
                    switch (phaseCode)
                    {
                        case types.Activity.Campaign.Phase.Concept: return <LocalizedText code='CampaignPhaseConcept' value='Concept'/>;
                        case types.Activity.Campaign.Phase.Planned: return <LocalizedText code='CampaignPhasePlanned' value='Ingepland op'/>;
                        case types.Activity.Campaign.Phase.InProgress: return <LocalizedText code='CampaignPhaseInProgress' value='Bezig met verzenden...'/>;
                        case types.Activity.Campaign.Phase.Completed: return <LocalizedText code='CampaignPhaseCompleted' value='Verzonden'/>;
                    }
                },
                [
                    types,
                    phaseCode
                ]);

        return <Click
            open
        >
            <Inset>
                <ViewGroup
                    className={styles.root}
                    orientation="horizontal"
                    spacing={10}
                    alignment="center"
                >
                    <ViewGroupItem>
                        <CampaignChartDelivered campaign={campaign} size={80} />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <CampaignChartOpened campaign={campaign} size={80} />
                    </ViewGroupItem>
                    <ViewGroupItem
                        ratio={1}
                    >
                        <ViewGroup orientation="vertical" spacing={5}>
                            <ViewGroupItem>
                                <span className={styles.labelName}>
                                    {campaign.name}
                                </span>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <span className={styles.labelEmailCount}>
                                    {numberOfEmails} e-mails
                                </span>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="vertical"
                            spacing={5}
                        >
                            <ViewGroupItem
                                alignment="right"
                                className={styles.labelPhase}
                            >
                                {phaseDescription}
                            </ViewGroupItem>
                            {
                                (phaseCode !== types.Activity.Campaign.Phase.InProgress && (dateDispatched || dateScheduled)) &&
                                    <ViewGroupItem
                                        alignment="right"
                                        className={styles.labelDate}
                                    >
                                        {localizer.formatDateTime(dateDispatched ? dateDispatched : dateScheduled)}
                                    </ViewGroupItem>
                            }
                        </ViewGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </Inset>
        </Click>;
    };

export default observer(Campaign);
