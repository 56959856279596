import React from 'react';
import Item, { ItemProps } from '../Item';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface DeleteItemProps<T = any> extends Omit<ItemProps<T>, 'name'>
{

}

const DeleteItem: React.FC<DeleteItemProps> =
    props =>
    {
        return <Item
            {...props}
            name={
                <LocalizedText
                    code="Generic.Delete"
                    value="Verwijderen"
                />
            }
        />
    };

export default DeleteItem;
