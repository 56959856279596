import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import { ApiClient } from '../../../../@Service/ApiClient/ApiClient';
import getTypes from '../../Entity/Type/Api/getTypes';
import { AssistantAnswer } from '../Model/Answer/AssistantAnswer';
import { replaceAll } from '../../../../@Util/String/replaceAll';
import { AssistantPromptWithAnswer } from '../Model/AssistantPromptWithAnswer';

export async function generateAssistantAnswerByPrompt(
    question: string,
    history: AssistantPromptWithAnswer[],
    context?: string
): Promise<AssistantAnswer>
{
    const apiClient = loadModuleDirectly(ApiClient);
    const types = getTypes();
    const prompt = `
        FieldPath(X) = path to a specific field rooted at X denoted in dot notation. The first field should exist on X. The second field should exist on the type of the first field. Etc.
    
        A predicate has data model:
        type Predicate<X> = Composite<X> | Comparison<X>;
        type Composite<X> {
            type: 'Composite';
            operator: 'And' | 'Or';
            childPredicates: Predicate<X>[];
        }
        type Comparison<X> {
            type: 'Comparison';
            field: FieldPath<X>;
            comparator: 'IsDefined' | 'IsNotDefined' | 'Equals' | 'NotEquals' | 'GreaterThan' | 'GreaterThanOrEqual' | 'LessThan' | 'LessThanOrEqual' | 'Contains' | 'StartsWith' | 'EndsWith';
            value?: Computation<X>;
        }
        
        A value has data model:
        type Value = Boolean | Number | Text | DateTime | TimePeriod | EntityType;
        type Boolean {
            type: 'Boolean';
            value: Boolean;
        }
        type Number {
            type: 'Boolean';
            value: Number;
        }
        type Text {
            type: 'Text';
            value: String;
        }
        type DateTime {
            type: 'DateTime';
            value: String; // ISO 8601
        }
        type TimePeriod {
            type: 'TimePeriod';
            periodType: 'Minutes' | 'Hours' | 'Days' | 'Weeks' | 'Months' | 'Quarters' | 'Years';
            numberOfPeriods: Number;
        }
        type EntityType {
            type: 'EntityType';
            value: String; // entity type in data model
        }
        
        A chart has data model:
        type Chart<X> {
            entityType: X;
            xField: FieldPath<X>;
            yAggregate: 'Sum' | 'Average' | 'Count';
            yField: FieldPath<X>;
            ySegmentField?: FieldPath<X>;
            filter?: Predicate<X>;
        }
        
        A table has data model:
        type Table<X> {
            entityType: X;
            columns: TableColumn<X>[];
            filter?: Predicate<X>;
            orderByField?: FieldPath<X>;
        }
        type TableColumn {
            field: FieldPath<X>;
        }
        
        An automation has data model:
        type Automation<X> {
            title: String;
            entityType: X;
            trigger: Trigger<X>;
            predicate?: Predicate<X>;
            action: Action<X>;
        }
        
        type Trigger<X> = OnCreate<X> | OnUpdate<X> | OnDelete<X> | Cron<X>;
        type OnCreate<X> {
            type: 'OnCreate';
        }
        type OnUpdate<X> {
            type: 'OnUpdate';
            field: FieldPath<X>;
        }
        type OnDelete<X> {
            type: 'OnDelete';
        }
        type Cron<X>: {
            type: 'Cron';
            cron: String; // Automation is triggered every time at the CRON pattern of 6 fields: seconds, minutes, hours, days, months, years.
        }
        
        type Action<X> = ForEach<X> | IfThen<X> | CreateEntity<X> | UpdateEntity<X>;
        type ForEach<X> {
            type: 'ForEach';
            collection<X>: Computation<X>;
            elementVariableId: String;
            action: Action<X>; // Action now has access to a variable denoted by elementVariableId
        }
        type IfThen<X> {
            type: 'IfThen';
            if: Predicate<X>;
            then: Action<X>;
            else?: Action<X>;        
        }
        type CreateEntity<X, Y> {
            type: 'CreateEntity';
            entityType: Y;
            fieldMappings: FieldMapping<X, Y>[];
        }
        type FieldMapping<X, Y> {
            field: FieldPath<Y>;
            value: Computation<X>;        
        }
        type UpdateEntity<X> {
            type: 'UpdateEntity';
            entityVariable: String;
            field: FieldPath<X>;
            value: Computation<X>;
        }
        
        The value type of the value in FieldMapping and UpdateEntity should match the type of the field. So do not reference id fields if not strictly necessary, use a ReferenceToVariable instead.
        
        type Computation<X> = Value | ReferenceToVariable | ValueFromEntity<X> | Math<X> | ListQuery<X> | AggregateQuery<X> | TemplatedText<X> | GenAiPrompt<X> | GenAiAudioTranscription<X> | GetFileName<X>;
        type ReferenceToVariable {
            type: 'ReferenceToVariable';
            variable: String;
        }
        type ValueFromEntity<X> {
            type: 'ValueFromEntity';
            entity: ReferenceToVariable;
            field: FieldPath<X>;
        }
        type Math<X> {
            type: 'Math';
            lhs: Computation<X>;
            operator: 'Add' | 'Subtract' | 'Multiply' | 'Divide';
            rhs: Computation<X>;
        }
        type ListQuery<X> {
            type: 'ListQuery';
            entityType: String;
            filter?: Predicate<X>;
        }
        type AggregateQuery<X> {
            type: 'AggregateQuery';
            entityType: String;
            aggregate: 'Count' | 'Sum' | 'Average' | 'Min' | 'Max';
            field: FieldPath<X>;
            filter?: Predicate<X>;
        }
        type TemplatedText<X> {
            type: 'TemplatedText';
            template: String; // example of a template 'Dear %$, the deadline is in %$. ...',
            placeholders: Computation<X>[];
        }
        type GenAiPrompt<X> {
            type: 'GenAiPrompt';
            prompt: TemplatedText<X>;
            files: Computation<X>[];
        }
        type GenAiAudioTranscription<X> {
            type: 'GenAiAudioTranscription';
            audio: Computation<X>;
        }
        type GetFileName<X> {
            type: 'GetFileName';
            file: Computation<X>;        
        }
        
        The available variables for chart and table filters are:
        - "CurrentDateTime": references the current date time
        - "Me": references the employee of type ${replaceAll(types.Relationship.Person.Contact.Employee.Type.code, '\\.', '')} (representing me/myself/I)
        
        The available variables for automation are:
        - "Entity": references the entity that is being mutated
        - "Date": references the current date time
        - "Initiator": references the employee of type ${replaceAll(types.Relationship.Person.Contact.Employee.Type.code, '\\.', '')} initiating the mutation (representing me/myself/I) (not available in case of a CRON trigger)
        
        In case of the OnUpdate trigger, there will be two additional variables available:
        - "FromValue": the value of the field before it was updated
        - "ToValue": the value of the field after it was updated
        
        An answer has data model:
        type Answer = AggregateAnswer | ChartAnswer | TableAnswer | AutomationAnswer | FollowUpQuestion;
        type AggregateAnswer<X> {
            type: 'AggregateAnswer';
            entityType: X;
            aggregate: 'Count' | 'Sum' | 'Average' | 'Min' | 'Max';
            field: FieldPath<X>;
            filter?: Predicate<X>;
        }
        type ChartAnswer {
            type: 'ChartAnswer';
            chart: Chart;
        }
        type TableAnswer {
            type: 'TableAnswer';
            table: Table;
        }
        type AutomationAnswer {
            type: 'AutomationAnswer';
            automation: Automation;        
        }
        type FollowUpQuestion {
            type: 'FollowUpQuestion';
            question: String;
        }
        
        ${context ?? ''}
        The current date and time is ${new Date().toISOString()}.
        'This year' starts at 1st of january, 0:00.
        Any question that is product related, use the ProductLine for this data.
        If the user queries a date field that cannot be found, fallback to the CreationDate field.
        Use the provided data model and its restrictions to provide the answers.
        You are an AI assistant for a CRM called Lucie. You will always respond an Answer in JSON (without json\`\`\` prefix and suffix) strictly conforming to the typing of Answer.
        If it is unclear what kind of Answer should be provided, please provide a FollowUpQuestion. You must always response with an Answer, never an empty string.
    `;

//     type FindEntityOfTypeByKeyword {
//     type: 'FindEntityOfTypeByKeyword';
//     entityType: String;
//     keyword: String;
// }
//
//     Use FindEntityOfTypeByKeyword if we want to find an entity of a specific entity type by a keyword. E.g. a datastore, phase, employee might have a different name than the user asks in its question.

    // Fields are denoted using dot notation and must be valid in terms of the data model (fields MUST exist).

    // So only return VALID chart/table with a valid entity type and valid fields.
    // Only add filters that unambigiously achieve the goal of the description.
    // Do not add IsDefined filter if it is already implied by the data model (a '?' behind the field name implies optional fields).
    // Do not add InstanceOf filter if it is for the sole reason of using it in the xField/yField/segmentByField.
    // For xField, yField and segmentByField, please refer to the object instead of id, uuid or name.
    const response =
        await fetch(
            apiClient.url('/assistant/prompt'),
            {
                headers: {
                    ...apiClient.defaultHeaders,
                    'Content-Type': 'application/json',
                },
                method: 'post',
                body: JSON.stringify({
                    systemPrompt: prompt,
                    userPrompt: question,
                    isMetadataIncluded: true,
                    history:
                        history.map(
                            item => ({
                                prompt: item.prompt.prompt,
                                answer: JSON.stringify(item.answer),
                            })
                        ),
                })
            }
        );
    const responseAsJson: { content: string } = await response.json();

    return JSON.parse(responseAsJson.content) as AssistantAnswer;

}
