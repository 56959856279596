import React, { useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { SearcherProps, SearchItem, SearchResultLimit } from '../Searcher';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../@Future/Component/Generic/Loader/Loader';
import { groupBy } from '../../../../../@Util/MapUtils/groupBy';
import MasonryLayout from '../../../../../@Future/Component/Generic/MasonryLayout/MasonryLayout';
import SearchItemBucket from './SearchItemBucker/SearchItemBucket';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import usePaginatedSelection from '../../../Entity/View/Api/usePaginatedSelection';
import Link from '../../../../../@Future/Component/Generic/Link/Link';

export interface SearchItemSearcherProps extends SearcherProps
{
    item: SearchItem
}

const SearchItemSearcher: React.FC<SearchItemSearcherProps> =
    props =>
    {
        const { item, query } = props;

        const [ pages, hasMore, loadMore, isLoading ] =
            usePaginatedSelection(
                query ? item.entityType : undefined,
                (builder, rootPath) =>
                    item.builder(builder, rootPath, query),
                [
                    item,
                    query
                ],
                SearchResultLimit);
        const results =
            useComputed(
                () =>
                    pages.map(
                        page =>
                            page.map(result => result.entity))
                        .reduce((a, b) => a.concat(b), []),
                [
                    pages
                ]);

        const resultsByType =
            useMemo(
                () =>
                    groupBy(
                        results || [],
                            entity => entity.entityType),
                [
                    results
                ]);

        if (!query || (results?.length === 0 && isLoading))
        {
            return <Centered
                horizontal
            >
                <Loader />
            </Centered>;
        }
        else if (results?.length === 0)
        {
            return <Card
                inset
            >
                <Centered
                    horizontal
                >
                    <LocalizedText code="SearchResults.NoResultsFoundForQuery" value="Géén zoekresultaten gevonden voor zoekopdracht:" />&nbsp;<strong>{props.query}</strong>
                </Centered>
            </Card>;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                {
                    results.length >= SearchResultLimit &&
                        <ViewGroupItem>
                            <Card
                                inset
                            >
                                <Centered
                                    horizontal
                                >
                                    <LocalizedText
                                        code="SearchResults.MaxResultsFound"
                                        value="Het maximum aantal zoekresultaten is bereikt. Verfijn je zoekopdracht om specifiekere resultaten te krijgen."
                                    />&nbsp;
                                    {
                                        hasMore &&
                                            <Link
                                                onClick={loadMore}
                                                highlighted
                                                disabled={isLoading}
                                            >
                                                <LocalizedText
                                                    code="Generic.LoadMore"
                                                    value="Meer laden"
                                                />...
                                            </Link>
                                    }
                                </Centered>
                            </Card>
                        </ViewGroupItem>
                }
                {
                    isLoading &&
                        <ViewGroupItem>
                            <Centered
                                horizontal
                            >
                                <Loader />
                            </Centered>
                        </ViewGroupItem>
                }
                <ViewGroupItem>
                    <MasonryLayout>
                        {
                            Array.from(resultsByType.keys())
                                .map(
                                    entityType =>
                                        <SearchItemBucket
                                            key={entityType.id}
                                            {...props}
                                            entityType={entityType}
                                            results={resultsByType.get(entityType)}
                                        />)
                        }
                    </MasonryLayout>
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

export default observer(SearchItemSearcher);
