import React, { useContext, useMemo } from 'react';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import getLayoutFromDescriptor from '../../../../../../../@Api/Layout/Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../../../@Api/Layout/LayoutDependencyContext';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import CompositeLayout from '../../../../../../../@Api/Layout/Type/CompositeLayout';
import ExpressionEditorContext from '../../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Expression/ExpressionEditorContext';
import { getParametersFromEntityContext } from '../Api/getParametersFromEntityContext';
import { getParameterAssignmentFromEntityContext } from '../Api/getParameterAssignmentFromEntityContext';
import ParameterAssignment from '../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import LayoutViewer from '../../../../../Layout/Viewer/LayoutViewer';

export interface LayoutBlockViewerProps
{
    value: any;
    safeMode?: boolean;
}

const LayoutBlockViewer: React.FC<LayoutBlockViewerProps> =
    ({
        value,
        safeMode,
     }) =>
    {
        const expressionContext = useContext(ExpressionEditorContext);
        const entityContext = expressionContext?.entityContext;
        const parameters =
            useMemo(
                () =>
                    entityContext
                        ? getParametersFromEntityContext(entityContext)
                        : new ParameterDictionary([]),
                [
                    entityContext
                ]
            );
        const parameterAssignment =
            useMemo(
                () =>
                    entityContext
                        ? getParameterAssignmentFromEntityContext(entityContext, parameters)
                        : new ParameterAssignment(),
                [
                    entityContext,
                    parameters,
                ]
            );
        const [ layout, isLoading ] =
            useAsyncResult(
                async () =>
                {
                    if (value)
                    {
                        return await getLayoutFromDescriptor(
                            value,
                            new LayoutDependencyContext(
                                parameters
                            )
                        );
                    }
                    else
                    {
                        return new CompositeLayout(
                            'Vertical',
                            []
                        );
                    }
                },
                [
                    value,
                    parameters,
                ]
            );

        if (isLoading)
        {
            return null;
        }
        else
        {
            return <LayoutViewer
                layout={layout}
                parameterDictionary={parameters}
                parameterAssignment={parameterAssignment}
                safeMode={safeMode}
            />;
        }
    };

export default LayoutBlockViewer;
