import { EmployeeAllocationSpecification } from '../Model/EmployeeAllocationSpecification';
import getTypes from '../../Type/Api/getTypes';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import uuid from '../../../../../@Util/Id/uuid';
import { EntityPath } from '../../Path/@Model/EntityPath';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../../DataObject/Model/Comparator';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';

export function getMyProjectAllocationSpecification(): EmployeeAllocationSpecification
{
    const types = getTypes();
    const resourceType = types.Activity.Project.Type;
    const employeeAllocationType = types.EmployeeAllocation.Type;
    const resourceToEmployeeAllocationPath =
        EntityPath.fromEntityType(resourceType)
            .joinTo(
                types.Activity.RelationshipDefinition.EmployeeAllocations,
                false
            );
    const resourceParameter =
        new Parameter<EntityValueType>(
            uuid(),
            new EntityValueType(resourceType),
            true,
            resourceType.getName()
        );
    const resourceFilterParameters = new ParameterDictionary([]);
    const currentEmployee = loadModuleDirectly(CurrentUserStore).employeeEntity;
    const resourceFilter =
        new CompositePredicate(
            LogicalOperator.Or,
            [
                new ComparisonPredicate(
                    new ValueFromEntityComputation(
                        resourceParameter,
                        EntityPath.fromEntityType(resourceParameter.type.type)
                            .joinTo(
                                types.Activity.Project.RelationshipDefinition.Owner,
                                false
                            )
                            .field()
                    ),
                    Comparator.Equals,
                    new EntityValue(currentEmployee)
                ),
                new ComparisonPredicate(
                    new ValueFromEntityComputation(
                        resourceParameter,
                        EntityPath.fromEntityType(resourceParameter.type.type)
                            .joinTo(
                                types.Activity.Project.RelationshipDefinition.Members,
                                false
                            )
                            .joinTo(
                                types.ProjectMember.RelationshipDefinition.Employee,
                                false
                            )
                            .field()
                    ),
                    Comparator.Equals,
                    new EntityValue(currentEmployee)
                ),
            ]
        );
    const employeeAllocationParameter =
        new Parameter<EntityValueType>(
            uuid(),
            new EntityValueType(employeeAllocationType),
            true,
            employeeAllocationType.getName()
        );
    const employeeAllocationFilter =
        new ComparisonPredicate(
            new ValueFromEntityComputation(
                employeeAllocationParameter,
                EntityPath.fromEntityType(employeeAllocationParameter.type.type)
                    .joinTo(
                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Allocations,
                        true
                    )
                    .field()
            ),
            Comparator.Equals,
            new EntityValue(currentEmployee)
        );

    return {
        resourceParameter,
        resourceToEmployeeAllocationPath,
        resourceFilterParameters,
        resourceFilter,
        employeeAllocationParameter,
        employeeAllocationFilter,
    };
}