import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { RelatedEntityPath } from '../../../../Path/@Model/RelatedEntityPath';
import OverlineLabel from '../../../../../../../@Future/Component/Generic/Label/Variant/Overline/OverlineLabel';

import useIsShowOverlineInHeader from './Api/useIsShowOverlineInHeader';
import Name from '../../../Name/Name';

export interface HeaderProps
{
    entity: Entity;
    pathFromRelatedEntity: RelatedEntityPath;
}

const Header: React.FC<HeaderProps> =
    props =>
    {
        const [ entityTypeName, setEntityTypeName ] = useState<string>();

        useEffect(
            () =>
            {
                // Set entity type name
                setEntityTypeName(props.entity.entityType.getName());
            },
            [props.entity]);

        return <div>
            {
                useIsShowOverlineInHeader(props.entity) &&
                    <OverlineLabel>
                        {entityTypeName}
                    </OverlineLabel>
            }
            <Name
                entity={props.entity}
                link
            />
        </div>;
    };

export default observer(Header);
