import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { runInAction } from 'mobx';
import ComputationEditor from '../../../ComputationEditor';
import styles from './ConditionalEditor.module.scss';
import ConditionalInComputation from '../../../../../../../../../../../@Api/Automation/Function/Computation/ConditionalInComputation';
import PredicateEditor from '../../../../Predicate/PredicateEditor';
import Predicate from '../../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import Value from '../../../../../../../../../../../@Api/Automation/Value/Value';
import { ConditionalComputationEditorProps } from '../ConditionalComputationEditor';
import LocalizedText from '../../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface ConditionalEditorProps extends ConditionalComputationEditorProps
{
    conditional: ConditionalInComputation;
}

const ConditionalEditor: React.FC<ConditionalEditorProps> =
    props =>
    {
        const setPredicate =
            useCallback(
                (predicate: Predicate) =>
                    runInAction(
                        () =>
                        {
                            props.conditional.predicate = predicate;
                        }),
                [
                    props.conditional
                ]);

        const setValue =
            useCallback(
                (value: Value<any, any>) =>
                    runInAction(
                        () =>
                        {
                            props.conditional.value = value;
                        }),
                [
                    props.conditional
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                <LocalizedText
                    code="Generic.If"
                    value="Als"
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <div
                    className={styles.side}
                >
                    <PredicateEditor
                        value={props.conditional.predicate}
                        onChange={setPredicate}
                        context={props.context}
                    />
                </div>
            </ViewGroupItem>
            <ViewGroupItem>
                <LocalizedText
                    code="Generic.Than"
                    value="Dan"
                    lowerCase
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <div
                    className={styles.side}
                >
                    <ComputationEditor
                        type={props.type}
                        value={props.conditional.value}
                        onChange={setValue}
                        context={props.context}
                    />
                </div>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(ConditionalEditor);
