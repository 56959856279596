import React from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../Type/Api/useTypes';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import styles from '../ProductsInvoiceEditor/ProductsInvoiceEditor.module.scss';
import CardHeader from '../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import Invoice from './Invoice/Invoice';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import useResults from '../../../../../Selection/Hooks/useResults';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';

export interface InvoiceTableProps
{
    entity: Entity;
    milestone?: Entity;
}

const InvoiceTable: React.FC<InvoiceTableProps> =
    props =>
    {
        const { entity, milestone } = props;
        const types = useTypes();
        const invoices =
            useResults(
                types.Activity.Invoice.Type,
                (builder, rootPath) =>
                    builder
                        .join(
                            rootPath.joinTo(
                                types.Activity.Invoice.RelationshipDefinition.Phase,
                                false
                            )
                        )
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath
                                        .joinTo(
                                            types.Activity.RelationshipDefinition.LinkedActivities,
                                            true),
                                    entity))
                        .if(
                            () =>
                                milestone !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath
                                                .joinTo(
                                                    types.Milestone.RelationshipDefinition.Activities,
                                                    true),
                                            milestone))),
                [
                    entity,
                    milestone,
                    types
                ]);

        return <Card>
            <CardInset>
                <div
                    className={styles.header}
                >
                    <CardHeader>
                        <LocalizedText
                            code="Generic.Invoices"
                            value="Facturen"
                        />
                    </CardHeader>
                </div>
            </CardInset>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            {...{ width: '10%' }}
                        >
                            <LocalizedText
                                code="Generic.Number"
                                value="Nummer"
                            />
                        </TableCell>
                        <TableCell
                            {...{ width: '20%' }}
                        >
                            <LocalizedText
                                code="Generic.Total"
                                value="Totaal"
                            />
                        </TableCell>
                        <TableCell
                            {...{ width: '20%' }}
                        >
                            <LocalizedText
                                code="Generic.PaymentTermDays"
                                value="Betaaltermijn in dagen"
                            />
                        </TableCell>
                        <TableCell
                            {...{ width: '55%' }}
                        >
                            <LocalizedText
                                code="Generic.Phase"
                                value="Fase"
                            />
                        </TableCell>
                        <TableCell
                            {...{ width: '5%' }}
                        >

                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        invoices &&
                        invoices
                            .map(
                                invoice =>
                                    <Invoice
                                        key={invoice.uuid}
                                        entity={invoice}
                                    />)
                    }
                </TableBody>
            </Table>
        </Card>;
    };

export default observer(InvoiceTable);
