import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react';
import Role, { AllPrivileges, RoleType } from '../../Model/Role';
import Right from '../../Model/Right';
import { EntityMetadataPermission } from '../../Model/EntityMetadataPermission';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useToggle from '../../../../../@Util/Toggle/useToggle';
import { RightPredicateEditor } from './RightPredicateEditor';
import { useComputed } from 'mobx-react-lite';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import CurrentUserContext from '../../../User/CurrentUserContext';

export interface RightPredicateButtonProps
{
    rolesByType: Map<RoleType, Role>;
    role: Role;
    metadataPermission: EntityMetadataPermission;
    isReadOnly: boolean;
}

export const RightPredicateButton: React.FC<RightPredicateButtonProps> =
    observer(
    ({
         rolesByType,
         role,
         metadataPermission,
         isReadOnly,
     }) =>
    {
        const currentUser = useContext(CurrentUserContext);
        const [ isFilterOpen, toggleFilter ] = useToggle(false);

        const right =
            useComputed(
                () =>
                {
                    switch (metadataPermission.type)
                    {
                        case 'EntityType':
                            return role.rightByType.get(
                                metadataPermission.entityType
                            );
                        case 'EntityField':
                            return role.rightByField.get(
                                metadataPermission.field
                            );
                        case 'RelationshipDefinition':
                            return role.rightByRelationshipType.get(
                                metadataPermission.relationship
                            );
                        default:
                            return undefined;
                    }
                },
                [
                    role,
                    metadataPermission,
                ]
            );

        const hasConditionallyGrantedRight =
            useComputed(
                () =>
                    AllPrivileges.some(
                        privilege =>
                            right && right[Right.getConditionSerializationKey(privilege)]
                    ),
                [
                    right,
                    metadataPermission
                ]);

        const isVisible =
            useComputed(
                () =>
                    metadataPermission.type === 'EntityType'
                    || metadataPermission.type === 'EntityField'
                    || metadataPermission.type === 'RelationshipDefinition',
                [
                    metadataPermission,
                ]);

        const typeName =
            useMemo(
                () =>
                {
                    switch (metadataPermission.type)
                    {
                        case 'EntityType':
                            return metadataPermission.entityType.getName();
                        case 'EntityField':
                            return metadataPermission.field.getName();
                        case 'RelationshipDefinition':
                            return metadataPermission.relationship.name;
                        default:
                            return undefined;
                    }
                },
                [
                    metadataPermission,
                ]
            );

        return <ViewGroup
            orientation="horizontal"
            spacing={0}
        >
            {
                isVisible &&
                <ViewGroupItem>
                    <IconButton
                        tooltip={
                            <LocalizedText
                                code="RightPredicateEditor"
                                value="Conditionele verleende rechten op ${typeName}"
                                typeName={typeName?.toLowerCase()}
                            />
                        }
                        icon="flash_on"
                        color={hasConditionallyGrantedRight ? "#BAB86C" : "whitesmoke"}
                        onClick={toggleFilter}
                    />
                </ViewGroupItem>
            }
            {
                isVisible && isFilterOpen &&
                <ViewGroupItem>
                    <RightPredicateEditor
                        rolesByType={rolesByType}
                        role={role}
                        metadataPermission={metadataPermission}
                        isReadOnly={isReadOnly || !currentUser.isDeveloper}
                        onClose={toggleFilter}
                    />
                </ViewGroupItem>
            }
        </ViewGroup>;
    }
);