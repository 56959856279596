import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../Model/DataObjectType';
import { Comparator } from '../../Model/Comparator';
import { Aggregate } from '../../Model/Aggregate';
import { MathematicalOperator } from '../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../Model/DataObjectFunction';
import { DataObject } from '../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../@Util/DependencyInjection/index';
import { ColorView } from './ColorView';
import { ColorEditor } from './ColorEditor';
import { ColorSpecification } from './ColorSpecification';
import { DataDescriptor } from '../../Model/DataDescriptor';
import { ComparatorDescriptor } from '../../Model/ComparatorDescriptor';
import { DataObjectRepresentation } from '../../Model/DataObjectRepresentation';
import { DataObjectOperatorOverload } from '../../Model/DataObjectOperatorOverload';
import { DataObjectContext } from '../../Model/DataObjectContext';
import { DataObjectBespokeEditorStore } from '../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { ColorEditorStore } from './ColorEditorStore';
import { DataObjectComparatorOverload } from '../../Model/DataObjectComparatorOverload';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { Color } from './Color';
import { toJS } from 'mobx';
import { DataObjectSpecification } from '../../Model/DataObjectSpecification';
import localizeText from '../../../../../@Api/Localization/localizeText';

export interface ColorRGBA
{
    r?: number;
    g?: number;
    b?: number;
    a?: number;
}

export function colorToCss(color: ColorRGBA)
{
    if (color)
    {
        return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
    }
    else
    {
        return undefined;
    }
}

export class ColorType implements DataObjectType<Color>
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Color';
    }

    name()
    {
        return localizeText('DataObject.Type.Color', 'Kleur');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [
            {
                comparator: Comparator.Equals
            },
            {
                comparator: Comparator.NotEquals
            }
        ];
    }

    supportedAggregates(): Aggregate[]
    {
        return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return ColorView;
    }

    editor(): DataObjectEditorType
    {
        return ColorEditor;
    }

    specification(): DataObjectSpecificationType
    {
        return ColorSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return null;
    }

    requiresInitialization(value: any): boolean
    {
        return false;
    }

    editorStore(baseStore: DataObjectEditorStore): DataObjectBespokeEditorStore
    {
        return new ColorEditorStore(baseStore);
    }

    getDataFromValue(value: ColorRGBA | string, specification: DataObjectSpecification): DataDescriptor
    {
        let color: ColorRGBA;

        if (value instanceof String)
        {
            color = JSON.parse(value as string) as ColorRGBA;
        }
        else
        {
            color = value as ColorRGBA;
        }

        let data = new DataDescriptor();

        data.text1 = color.r ? color.r.toString() : undefined;
        data.text2 = color.g ? color.g.toString() : undefined;
        data.text3 = color.b ? color.b.toString() : undefined;
        data.text4 = color.a ? color.a.toString() : undefined;

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): ColorRGBA
    {
        if (data.text1 === undefined || data.text2 === undefined || data.text3 === undefined)
        {
            return undefined;
        }
        else
        {
            return {
                r: Number(data.text1),
                g: Number(data.text2),
                b: Number(data.text3),
                a: data.text4 ? Number(data.text4) : undefined
            };
        }
    }

    valueId(value: Color): string
    {
        return JSON.stringify(toJS(value));
    }

    getString(value: any, representation: DataObjectRepresentation, context: DataObjectContext, dataObject: DataObject): string
    {
        return JSON.stringify(value);
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return false;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [];
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    isValid(dataObject: DataObject): boolean
    {
        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return false;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }

}
