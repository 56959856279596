import { observable } from 'mobx';
import EntityValueType from '../../Automation/Value/Type/EntityValueType';
import Parameter from '../../Automation/Parameter/Parameter';
import ParameterDictionary from '../../Automation/Parameter/ParameterDictionary';
import { EntityType } from '../../Model/Implementation/EntityType';
import Predicate from '../../Automation/Function/Computation/Predicate/Predicate';
import PortalDataSourceField from './PortalDataSourceField';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import getPredicateFromDescriptor from '../../Automation/Api/getPredicateFromDescriptor';
import AutomationDependencyContext from '../../Automation/AutomationDependencyContext';
import PortalDataSourceSignature from './PortalDataSourceSignature';
import Dependency from '../../Automation/Parameter/Dependency';

export default class PortalDataSource
{
    // ------------------------- Properties -------------------------

    static ResultParameterId = 'Result';

    @observable id: string;
    @observable name: string;
    @observable.ref resultParameter: Parameter<EntityValueType>;
    @observable.ref parameters: ParameterDictionary;
    @observable.ref entityType: EntityType;
    @observable.ref filter: Predicate;
    @observable.shallow fields: PortalDataSourceField[];

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                name: string,
                resultParameter: Parameter<EntityValueType>,
                parameters: ParameterDictionary,
                entityType: EntityType,
                filter: Predicate,
                fields: PortalDataSourceField[])
    {
        this.id = id;
        this.name = name;
        this.resultParameter = resultParameter;
        this.parameters = parameters;
        this.entityType = entityType;
        this.filter = filter;
        this.fields = fields;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getDependencies(): Dependency[]
    {
        return [
            ...this.parameters.getDependencies(),
            ...(this.filter ? this.filter.getDependencies() : [])
                .filter(
                    dependency =>
                        !this.parameters.hasParameter(dependency.parameter)
                        && dependency.parameter !== this.resultParameter),
        ];
    }

    getResultParameters(): ParameterDictionary
    {
        const resultParameters =
            this.fields.map(
                field =>
                    field.parameter);

        return new ParameterDictionary(resultParameters);
    }

    toSignature(): PortalDataSourceSignature
    {
        return new PortalDataSourceSignature(
            this.id,
            this.name,
            this.parameters,
            this.getResultParameters());
    }

    toDescriptor()
    {
        return {
            parameters: this.parameters.toDescriptor(),
            entityTypeId: this.entityType.id,
            filter: this.filter?.toDescriptor(),
            fields: this.fields.map(field => field.toDescriptor())
        };
    }

    static async fromDescriptor(id: string,
                                name: string,
                                descriptor: any,
                                dependencyContext: AutomationDependencyContext = AutomationDependencyContext.GetEmptyContext())
    {
        const parameters =
            await ParameterDictionary.fromDescriptor(
                descriptor.parameters,
                dependencyContext);

        const entityType = loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.entityTypeId);
        const resultParameter = PortalDataSource.getResultParameter(entityType);

        const filterParameters =
            new ParameterDictionary([
                ...dependencyContext.parameterDictionary.parameters,
                ...parameters.parameters,
                resultParameter
            ]);
        const filter =
            descriptor.filter
                ?
                    await getPredicateFromDescriptor(
                        descriptor.filter,
                        new AutomationDependencyContext(filterParameters))
                :
                    undefined;

        const fields: PortalDataSourceField[] =
            await Promise.all(
                descriptor.fields.map(
                    field =>
                        PortalDataSourceField.fromDescriptor(field)));

        return new PortalDataSource(
            id,
            name,
            resultParameter,
            parameters,
            entityType,
            filter,
            fields);
    }

    static getResultParameter(entityType: EntityType)
    {
        return new Parameter(
            PortalDataSource.ResultParameterId,
            new EntityValueType(entityType),
            true,
            entityType.getName());
    }

    // ----------------------- Private logic ------------------------
}
