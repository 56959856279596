import { FormEvent } from './FormEvent';
import { FormEventListener } from './FormEventListener';
import { action, observable } from 'mobx';

export class FormHandlerContext<T>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable.shallow listeners: Array<FormEventListener<T>>;
    @observable.shallow listenersByEventId: Map<string, Array<FormEventListener<T>>>;

    // ------------------------ Constructor -------------------------

    constructor(listeners: Array<FormEventListener<T>> = [])
    {
        this.listeners = [];
        this.listenersByEventId = new Map<string, Array<FormEventListener<T>>>();

        for (let listener of listeners)
        {
            this.listen(listener);
        }
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    listen(listener: FormEventListener<T>)
    {
        this.listeners.push(listener);

        if (!this.listenersByEventId.has(listener.eventId))
        {
            this.listenersByEventId.set(listener.eventId, []);
        }

        this.listenersByEventId.get(listener.eventId).push(listener);
    }

    @action.bound
    unlisten(listener: FormEventListener<T>)
    {
        let idx = this.listeners.indexOf(listener);

        if (idx >= 0)
        {
            this.listeners.splice(idx, 1);

            if (this.listenersByEventId.has(listener.eventId))
            {
                this.listenersByEventId.get(listener.eventId)
                    .splice(
                        this.listenersByEventId.get(listener.eventId).indexOf(listener),
                        1);
            }
        }
    }

    @action.bound
    perform(event: FormEvent<T>)
    {
        this.performForEventId(event.id, event);
        this.performForEventId(null, event);
    }

    @action.bound
    performForEventId(eventId: string, event: FormEvent<T>)
    {
        if (this.listenersByEventId.has(eventId))
        {
            for (let listener of this.listenersByEventId.get(eventId))
            {
                listener.listen(event);
            }
        }
    }

    @action.bound
    merge(context: FormHandlerContext<T>)
    {
        for (let listener of context.listeners)
        {
            this.listen(listener);
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
