import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { ChartStore } from '../ChartStore';
import { Theme, withStyles } from '@material-ui/core';
import { Line, LineChart as ReLineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartLabel } from './ChartLabel';
import { DataObject } from '../../../Domain/DataObject/Model/DataObject';
import { DataObjectRepresentation } from '../../../Domain/DataObject/Model/DataObjectRepresentation';

const styles =
    (theme: Theme) =>
        ({

        });

@observer
class LineChart extends BaseComponent<ChartStore>
{
    renderComponent(store: ChartStore): JSX.Element
    {
        const chartData =
            store.data
                .filter(
                    point =>
                        !point.isEmptyGroup)
                .map(point =>
                {
                    if (point.segmentations.length > 0)
                    {
                        return point.segmentations.map(
                            segmentation =>
                            {
                                const descriptor: any =
                                {
                                    name: point.group.value
                                };

                                segmentation.points
                                    .filter(
                                        segment =>
                                            !segment.isEmptyGroup)
                                    .forEach(
                                        segment =>
                                        {
                                            (descriptor as any)[segment.name] = segment.value.value;
                                        });

                                return descriptor;
                            });
                    }
                    else
                    {
                        return [
                            {
                                name: point.name,
                                value: point.value.value
                            }
                        ];
                    }
                })
                .reduce((a, b) => a.concat(b), []);

        return <ResponsiveContainer
            width="100%"
        >
            <ReLineChart
                data={chartData}
                margin={{
                    left: 25,
                    right: 25,
                    bottom: 30
                }}
            >
                <XAxis
                    dataKey="name"
                    height={60}
                    tick={<ChartLabel />}
                    interval={0}
                />
                <YAxis
                    tickFormatter={
                        e =>
                        {
                            if (store.data.length === 0)
                            {
                                return e;
                            }
                            else
                            {
                                return DataObject.constructFromTypeAndValue(
                                    store.data[0].value.specification.type,
                                    e)
                                    .toString(
                                        new DataObjectRepresentation({ isCompact: true }))
                                    .replace(/ /g, '');
                            }
                        }
                    }
                />
                <Tooltip />
                {
                    store.segmentGroups.size > 0
                        ?
                            Array.from(store.segmentGroups)
                                .map(
                                    (segmentGroup, idx) =>
                                        <Line
                                            key={segmentGroup.toString()}
                                            dataKey={segmentGroup.toString()}
                                            type="monotone"
                                            activeDot={{
                                                onClick:
                                                    (dot: any) =>
                                                        store.toggleIndexSelection(dot.index)
                                            }}
                                            // stackId="a"
                                            onClick={((data: any, idx: number) => store.toggleIndexSelection(idx)) as any}
                                            // fill={ColorUtils.color(idx)[500]}
                                        >
                                            {/*{*/}
                                                {/*Array.from(store.data.keys())*/}
                                                    {/*.map((entry, idx2) =>*/}
                                                        {/*<Cell*/}
                                                            {/*key={idx2}*/}
                                                            {/*cursor="pointer"*/}
                                                            {/*fill={idx2 === store.selectedIndex ? ColorUtils.color(idx)[100] : ColorUtils.color(idx)[500]}*/}
                                                        {/*/>)*/}
                                            {/*}*/}
                                        </Line>)
                        :
                            <Line
                                dataKey="value"
                                type="monotone"
                                strokeWidth={2}
                                activeDot={{
                                    onClick:
                                        (dot: any) =>
                                            store.toggleIndexSelection(dot.index)
                                }}
                                // onClick={((data: any, idx: number) => store.toggleIndexSelection(idx)) as any}
                            >
                                {/*{*/}
                                    {/*Array.from(store.data.keys())*/}
                                        {/*.map((entry, idx) =>*/}
                                            {/*<Cell*/}
                                                {/*key={idx}*/}
                                                {/*cursor="pointer"*/}
                                                {/*fill={idx === store.selectedIndex ? store.selectedColor : store.color}*/}
                                            {/*/>)*/}
                                {/*}*/}
                            </Line>
                }
            </ReLineChart>
        </ResponsiveContainer>;
    }
}

export default withStyles(styles)(LineChart as any);
