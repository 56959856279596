import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { default as WidgetModel } from '../Model/Widget';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import DragHandle from '../../../DragAndDrop/DragHandle/DragHandle';
import DatasetWidget from '../Model/Widget/Dataset/DatasetWidget';
import Dataset from './Dataset/Dataset';
import AgendaWidget from '../Model/Widget/Agenda/AgendaWidget';
import Agenda from './Agenda/Agenda';
import CalendarWidget from '../Model/Widget/Calendar/CalendarWidget';
import Calendar from './Calendar/Calendar';
import Note from './Note/Note';
import NoteWidget from '../Model/Widget/Note/NoteWidget';
import Entity from './Entity/Entity';
import EntityWidget from '../Model/Widget/Entity/EntityWidget';
import WebsiteWidget from '../Model/Widget/Website/WebsiteWidget';
import Website from './Website/Website';
import FileWidget from '../Model/Widget/File/FileWidget';
import File from './File/File';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../@Future/Component/Generic/Loader/Loader';
import ResourcePlannerWidget from '../Model/Widget/ResourcePlanner/ResourcePlannerWidget';
import ResourcePlanner from './ResourcePlanner/ResourcePlanner';
import EmployeePlannerWidget from '../Model/Widget/EmployeePlanner/EmployeePlannerWidget';
import EmployeePlanner from './EmployeePlanner/EmployeePlanner';
import ProjectPlanner from './ProjectPlanner/ProjectPlanner';
import ProjectPlannerWidget from '../Model/Widget/ProjectPlanner/ProjectPlannerWidget';
import ProjectTimesheetWidget from '../Model/Widget/ProjectTimesheet/ProjectTimesheetWidget';
import ProjectTimesheet from './ProjectTimesheet/ProjectTimesheet';
import MyProjectsTimesheetWidget from '../Model/Widget/MyProjectsTimesheet/MyProjectsTimesheetWidget';
import MyProjectTimesheet from './MyProjectsTimesheet/MyProjectsTimesheet';
import EmployeeTimesheetWidget from '../Model/Widget/EmployeeTimesheet/EmployeeTimesheetWidget';
import EmployeeTimesheet from './EmployeeTimesheet/EmployeeTimesheet';
import EmployeeBillabilityWidget from '../Model/Widget/EmployeeBillability/EmployeeBillabilityWidget';
import EmployeeBillability from './EmployeeBillability/EmployeeBillability';
import ViewsWidget from '../Model/Widget/Views/ViewsWidget';
import Views from './Views/Views';
import CustomWidget from '../Model/Widget/Custom/CustomWidget';
import Custom from './Custom/Custom';
import EmployeeItemPlanner from './EmployeeAllocation/EmployeeAllocation';
import EmployeeAllocationWidget from '../Model/Widget/EmployeeAllocation/EmployeeAllocationWidget';
import ProjectAllocationWidget from '../Model/Widget/ProjectAllocation/ProjectAllocationWidget';
import ProjectAllocation from './ProjectAllocation/ProjectAllocation';
import MyTimesheetWidget from '../Model/Widget/MyTimesheet/MyTimesheetWidget';
import MyTimesheet from './MyTimesheet/MyTimesheet';
import MyProjectHoursTimesheetWidget from '../Model/Widget/MyProjectHoursTimesheet/MyProjectHoursTimesheetWidget';
import MyProjectHoursTimesheet from './MyProjectHoursTimesheet/MyProjectHoursTimesheet';
import MyProjectAllocation from './MyProjectAllocation/MyProjectAllocation';
import MyProjectAllocationWidget from '../Model/Widget/MyProjectAllocation/MyProjectAllocationWidget';
import { MyProjectTimesheetByTimeRegistration } from './MyProjectTimesheetByTimeRegistration/MyProjectTimesheetByTimeRegistration';
import { MyProjectTimesheetByTimeRegistrationWidget } from '../Model/Widget/MyProjectTimesheetByTimeRegistration/MyProjectTimesheetByTimeRegistrationWidget';
import { FullscreenAgendaWidgetModel } from '../Model/Widget/FullscreenAgenda/FullscreenAgendaWidgetModel';
import { FullscreenAgendaWidget } from './FullScreenAgenda/FullscreenAgendaWidget';

export interface WidgetProps<T extends WidgetModel = WidgetModel>
{
    widget: T;
    onAdd?: () => void;
    onDelete: () => void;
    disabled?: boolean;
}

const Widget: React.FC<WidgetProps> =
    props =>
    {
        const [ isLoading, setLoading ] = useState(!props.widget.isInitialized);

        useEffect(
            () =>
            {
                if (!props.widget.isInitialized)
                {
                    props.widget.initialize([ props.widget ])
                        .then(
                            () =>
                                setLoading(false));
                }
            },
            [
                props.widget,
                setLoading
            ]);

        if (isLoading)
        {
            return <Card
                inset
            >
                <DragHandle>
                    <Centered
                        horizontal
                    >
                        <Loader />
                    </Centered>
                </DragHandle>
            </Card>;
        }
        else if (props.widget instanceof DatasetWidget)
        {
            return <Dataset
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof AgendaWidget)
        {
            return <Agenda
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof FullscreenAgendaWidgetModel)
        {
            return <FullscreenAgendaWidget
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof CalendarWidget)
        {
            return <Calendar
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof ResourcePlannerWidget)
        {
            return <ResourcePlanner
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof EmployeePlannerWidget)
        {
            return <EmployeePlanner
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof EmployeeTimesheetWidget)
        {
            return <EmployeeTimesheet
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof MyTimesheetWidget)
        {
            return <MyTimesheet
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof EmployeeBillabilityWidget)
        {
            return <EmployeeBillability
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof EmployeeAllocationWidget)
        {
            return <EmployeeItemPlanner
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof ProjectPlannerWidget)
        {
            return <ProjectPlanner
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof ProjectAllocationWidget)
        {
            return <ProjectAllocation
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof MyProjectAllocationWidget)
        {
            return <MyProjectAllocation
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof MyProjectsTimesheetWidget)
        {
            return <MyProjectTimesheet
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof MyProjectHoursTimesheetWidget)
        {
            return <MyProjectHoursTimesheet
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof MyProjectTimesheetByTimeRegistrationWidget)
        {
            return <MyProjectTimesheetByTimeRegistration
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof ProjectTimesheetWidget)
        {
            return <ProjectTimesheet
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof NoteWidget)
        {
            return <Note
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof WebsiteWidget)
        {
            return <Website
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof FileWidget)
        {
            return <File
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof EntityWidget)
        {
            return <Entity
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof ViewsWidget)
        {
            return <Views
                {...props as WidgetProps<any>}
            />;
        }
        else if (props.widget instanceof CustomWidget)
        {
            return <Custom
                {...props as WidgetProps<any>}
            />;
        }
        else
        {
            return <Card
                inset
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem>
                        <DragHandle />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        Inhoud {props.widget.id}
                    </ViewGroupItem>
                </ViewGroup>
            </Card>;
        }
    };

export default observer(Widget);
