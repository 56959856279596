import { ApiClient } from '../ApiClient';

export interface FetchApiFileResult
{
    url: string;
    fileName?: string;
    blob: Blob;
    dispose: () => void;
}

export async function fetchApiFile(
    apiClient: ApiClient,
    url: string,
    isCached?: boolean
): Promise<FetchApiFileResult>
{
    let urlToFetch =
        url.startsWith('http:') || url.startsWith('https:') || url.startsWith('data:') || url.startsWith('blob:')
            ? url
            : apiClient.url(url);

    if (isCached)
    {
        urlToFetch = urlToFetch + '&isCached=true';
    }

    const response =
        await fetch(
            urlToFetch,
            {
                headers: apiClient.defaultHeaders,
            }
        );
    const fileName = getFileNameFromResponse(response);
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);

    return {
        url: objectUrl,
        fileName,
        blob,
        dispose:
            () =>
                URL.revokeObjectURL(objectUrl),
    };
}

// https://stackoverflow.com/a/71195901
function getFileNameFromResponse(response: Response): string | undefined
{
    const contentDisposition = response.headers.get('Content-Disposition');

    if (contentDisposition)
    {
        const fileName = contentDisposition.split(/;(.+)/)[1].split(/=(.+)/)[1];

        if (fileName.toLowerCase().startsWith(`utf-8''`))
        {
            return decodeURIComponent(fileName.replace(/utf-8''/i, ''));
        }
        else
        {
            return fileName.replace(/['"]/g, '');
        }
    }
    else
    {
        return undefined;
    }
}