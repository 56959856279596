import { EntityExpansionBuilder } from '../../@Component/Domain/Entity/Selection/Builder/EntityExpansionBuilder';
import { Entity } from '../Model/Implementation/Entity';
import { EntityPath } from '../../@Component/Domain/Entity/Path/@Model/EntityPath';

export default function initializeEntityForViewer(entity: Entity): Promise<Entity>
{

    if (entity.isNew())
    {
        return Promise.resolve(entity);
    }
    else
    {
        // Initialize entity with singleton relationships, expand the original as
        // this entity might be used from outside in views. We want to continue to
        // manipulate that instance.
        const rootPath = EntityPath.root(entity.entityType);

        return new EntityExpansionBuilder(
            entity.entityType,
            [ entity ],
            entity.entityType.bespoke.getInitializationPathsInViewer(rootPath))
            .expand()
            .then(
                () =>
                    Promise.resolve(entity));
    }
}
