import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Trigger from '../../../../../@Api/Automation/Trigger/Trigger';
import { AssistantOnCreateTrigger } from '../../Model/Automation/Trigger/AssistantOnCreateTrigger';
import CreationTrigger from '../../../../../@Api/Automation/Trigger/CreationTrigger';

export function findAssistantOnCreateTrigger(
    trigger: AssistantOnCreateTrigger,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Trigger
{
    return new CreationTrigger(
        rootParameter.type.type,
        undefined
    );
}
