import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../../Model/DataObjectType';
import { Comparator } from '../../../Model/Comparator';
import { Aggregate } from '../../../Model/Aggregate';
import { MathematicalOperator } from '../../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../../Model/DataObjectFunction';
import { DataObject } from '../../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../../@Util/DependencyInjection/index';
import { EntityFieldPathView } from './EntityFieldPathView';
import { EntityFieldPathEditor } from './EntityFieldPathEditor';
import { EntityFieldPathSpecification } from './EntityFieldPathSpecification';
import { DataDescriptor } from '../../../Model/DataDescriptor';
import { ComparatorDescriptor } from '../../../Model/ComparatorDescriptor';
import { DataObjectRepresentation } from '../../../Model/DataObjectRepresentation';
import { DataObjectOperatorOverload } from '../../../Model/DataObjectOperatorOverload';
import { DataObjectContext } from '../../../Model/DataObjectContext';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import { EntityTypeStore } from '../../../../Entity/Type/EntityTypeStore';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { DataObjectBespokeEditorStore } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { EntityFieldPathEditorStore } from './EntityFieldPathEditorStore';
import { DataObjectComparatorOverload } from '../../../Model/DataObjectComparatorOverload';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { DataObjectSpecification } from '../../../Model/DataObjectSpecification';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export class EntityFieldPathType implements DataObjectType<EntityFieldPath>
{
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'EntityFieldPath';
    }

    name()
    {
        return localizeText('DataObject.Type.EntityFieldPath', 'Pad naar veld');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [
            {
                comparator: Comparator.Equals
            },
            {
                comparator: Comparator.NotEquals
            }
        ];
    }

    supportedAggregates(): Aggregate[]
    {
        return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return EntityFieldPathView;
    }

    editor(): DataObjectEditorType
    {
        return EntityFieldPathEditor;
    }

    editorStore(editorStore: DataObjectEditorStore): DataObjectBespokeEditorStore
    {
        return new EntityFieldPathEditorStore(editorStore);
    }

    specification(): DataObjectSpecificationType
    {
        return EntityFieldPathSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return null;
    }

    requiresInitialization(value: EntityFieldPath): boolean
    {
        return false;
    }

    getDataFromValue(value: EntityFieldPath, specification: DataObjectSpecification): DataDescriptor
    {
        let data = new DataDescriptor();
        data.complex = value.descriptor;

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): EntityFieldPath
    {
        if (data.complex)
        {
            return EntityFieldPath.construct(
                data.complex,
                this.entityTypeStore);
        }
        else
        {
            return null;
        }
    }

    valueId(value: EntityFieldPath): string
    {
        return value.id;
    }

    getString(value: EntityFieldPath,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        return value.getAdministrativeName(this.entityTypeStore, false);
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return false;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [];
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    isValid(dataObject: DataObject): boolean
    {
        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return false;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }

}
