import { action, computed, IObservableArray, Lambda, observable, runInAction } from 'mobx';
import TransactionalConfiguration from '../Shared/TransactionalConfiguration';
import TransactionalContext from '../Shared/TransactionalContext';
import { commit, createTransactionalModel, destroyTransactionalModel, getModel, isDirty, isTransactionalModel, rollback, TransactionalModel, whyDirty } from '../Model/TransactionalModel';
import TransactionalCommitAdministration from '../Shared/TransactionalCommitAdministration';
import { isContextDeep } from '../Shared/TransactionUtils';
import { getBuilder } from '../Shared/TransactionalBuilder';

export type TransactionalElementType<T> = T | TransactionalModel<T>;

export function isTransactionalArray(element: any): boolean
{
    return element instanceof TransactionalArray;
}

export default class TransactionalArray<T>
{
    // ------------------------- Properties -------------------------

    @observable.shallow array: IObservableArray<T>;
    @observable.shallow transactionalArray: IObservableArray<TransactionalElementType<T>>;
    @observable.ref configuration: TransactionalConfiguration;
    @observable.ref context: TransactionalContext<T>;
    @observable.shallow disposers = observable.array<Lambda>();
    @observable isInitialized: boolean;

    // ------------------------ Constructor -------------------------

    constructor(array: IObservableArray<T>,
                configuration: TransactionalConfiguration,
                context: TransactionalContext<T>)
    {
        this.array = array;
        this.transactionalArray = observable.array();
        this.configuration = configuration;
        this.context = context;

        this.initialize();
    }

    // ----------------------- Initialization -----------------------

    initialize()
    {
        // Initialize first values
        this.reset();

        // Track changes of model array (when original changes, the transactional array should change as well)
        this.disposers.push(
            this.array.observe(
                change =>
                {
                    if (!this.context.isLocked)
                    {
                        // console.log('changing model array', change, this.context.formattedKeyPath, this.context.isLocked, this.context.parentContext !== undefined);

                        if (change.type === 'update')
                        {
                            this.transactionalArray[change.index] =
                                this.resolveElement(change.newValue, change.index);
                        }
                        else if (change.type === 'splice')
                        {
                            const added =
                                change.added
                                // Determine if the added elements should be added
                                // E.g. it could be the case that the original array receives
                                // a value that was already added on the same location in the
                                // transactional array
                                    .filter(
                                        (element, idx) =>
                                        {
                                            if (change.removedCount > 0)
                                            {
                                                // TODO [DD]: otherwise the product line editor is ruined
                                                return true;
                                            }

                                            // If changed element resides in the array
                                            const elementId = this.getIdOfElement(element);
                                            const arrayIdx = change.index + idx;

                                            if (arrayIdx < this.transactionalArray.length)
                                            {
                                                const elementInArray = getModel(this.transactionalArray[arrayIdx]);
                                                const elementInArrayId = this.getIdOfElement(elementInArray);

                                                if (element === elementInArray
                                                    || (
                                                        elementId != null
                                                        && elementInArrayId != null
                                                        && elementId === elementInArrayId
                                                    )
                                                )
                                                {
                                                    this.transactionalArray[arrayIdx] =
                                                        this.resolveElement(
                                                            element,
                                                            idx);

                                                    return false;
                                                }
                                                else
                                                {
                                                    return true;
                                                }
                                            }
                                            else
                                            {
                                                return true;
                                            }
                                        })
                                    .map(
                                        (element, idx) =>
                                            this.resolveElement(
                                                element,
                                                change.index + idx));

                            // console.log('added', change, added, this.transactionalArray);

                            runInAction(
                                () =>
                                    this.transactionalArray.splice(
                                        change.index,
                                        change.removedCount,
                                        ...added)
                            );
                        }
                    }
                }));

        // Track changes of transactional array
        this.disposers.push(
            this.transactionalArray.intercept(
                change =>
                {
                    // console.log('changing transactional array', this.context.formattedKeyPath, this.context.doTrackChangesFromTransactionalModel, this.context.parentContext !== undefined);

                    if (this.context.doTrackChangesFromTransactionalModel)
                    {
                        if (change.type === 'update')
                        {
                            change.newValue =
                                this.resolveElement(
                                    change.newValue as any,
                                    change.index);
                        }
                        else if (change.type === 'splice')
                        {
                            change.added =
                                change.added
                                    .map(
                                        (element, idx) =>
                                            this.resolveElement(
                                                element as any,
                                                change.index + idx));
                        }

                        return change;
                    }
                    else
                    {
                        return change;
                    }
                }));

        this.isInitialized = true;
    }

    deinitialize()
    {
        if (this.isInitialized)
        {
            this.isInitialized = false;

            this.disposers.forEach(
                disposer =>
                    disposer());

            this.disposers.clear();
        }
    }

    getIdOfElement(element: any): string | undefined
    {
        if (element == null)
        {
            return undefined;
        }
        else
        {
            const builder = getBuilder(getModel(element).constructor);

            return builder?.getId(element) ?? element?.uuid;
        }
    }

    // -------------------------- Computed --------------------------

    @computed
    get isLocallyDirty(): boolean
    {
        if (this.array.length !== this.transactionalArray.length)
        {
            return true;
        }

        let idx = 0;

        for (let element of this.array)
        {
            if (element !== getModel(this.transactionalArray[idx]))
            {
                return true;
            }

            idx += 1;
        }

        return false;
    }

    // -------------------------- Actions ---------------------------

    @action
    reset()
    {
        this.transactionalArray.replace(
            this.array.map(
                (element, idx) =>
                    this.resolveElement(
                        element,
                        idx)));
    }

    @action
    commit(administration: TransactionalCommitAdministration): Promise<any>
    {
        if (administration.hasSeen(this))
        {
            return Promise.resolve();
        }

        administration.seen(this);

        const promise =
            Promise.all<any>(
                this.transactionalArray.map(
                    element =>
                    {
                        if (isTransactionalModel(element))
                        {
                            return commit(
                                (element as TransactionalModel<T>),
                                administration);
                        } else
                        {
                            return Promise.resolve();
                        }
                    }));

        this.array.replace(
            this.transactionalArray.map(
                element =>
                    getModel(element)));

        return promise;
    }

    @action
    rollback(administration: TransactionalCommitAdministration)
    {
        if (administration.hasSeen(this))
        {
            return;
        }

        administration.seen(this);

        this.transactionalArray
            .forEach(
                element =>
                {
                    if (isTransactionalModel(element))
                    {
                        rollback(
                            element as TransactionalModel<T>,
                            administration);
                    }
                });

        this.reset();
    }

    // ------------------------ Public logic ------------------------

    resolveElement(element: T,
                   idx: number): TransactionalElementType<T>
    {
        if (isContextDeep(this.context))
        {
            const transactionalElement =
                createTransactionalModel(
                    element,
                    this.configuration,
                    this.context.joinTo(
                        `${idx}`,
                        element));

            this.disposers.push(
                () =>
                    destroyTransactionalModel(transactionalElement));

            return transactionalElement;
        }
        else
        {
            return element;
        }
    }

    isDirty(checkAdministration: TransactionalCommitAdministration): boolean
    {
        if (this.isLocallyDirty)
        {
            return true;
        }

        return this.transactionalArray.some(
            element =>
            {
                if (isTransactionalModel(element))
                {
                    return isDirty(element, checkAdministration);
                } else
                {
                    return false;
                }
            });
    }

    whyDirty(checkAdministration: TransactionalCommitAdministration): any
    {
        const descriptor: any =
            {
                isLocallyDirty: this.isLocallyDirty
            };

        this.transactionalArray.forEach(
            (element, idx) =>
            {
                if (isTransactionalModel(element))
                {
                    const deepDescriptor = whyDirty(element, checkAdministration);

                    if (deepDescriptor !== undefined)
                    {
                        descriptor[idx] = deepDescriptor;
                    }
                } else
                {
                    // TODO: check if this is locally dirty
                }
            });

        if (Object.keys(descriptor).length === 1 && !this.isLocallyDirty)
        {
            return undefined;
        } else
        {
            return descriptor;
        }
    }

    // ----------------------- Private logic ------------------------
}
