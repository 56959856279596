import { observable } from 'mobx';
import Computation from './Computation';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Validation from '../../Validation/Validation';

export default class MergedPdfComputationPdf
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref value: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                value: Computation<any, any>)
    {
        this.id = id;
        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return this.value.validate();
    }

    isAsync(): boolean
    {
        return this.value.isAsync();
    }

    getDependencies(): Dependency[]
    {
        return this.value.getDependencies();
    }

    toDescriptor()
    {
        return {
            id: this.id,
            value: this.value.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new MergedPdfComputationPdf(
            descriptor.id,
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
