import { EventRelationshipUpdate } from './EventRelationshipUpdate';
import { EntityRelationshipCreationMutation } from '../../../../../@Api/Model/Implementation/EntityRelationshipCreationMutation';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';

export class EventRelationshipDelete extends EventRelationshipUpdate
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Entity.Relationship.Delete';
    }

    isEnabledByDefault(): boolean
    {
        return false;
    }

    caption(event: EntityRelationshipCreationMutation): string
    {
        if (event.entityRelationshipDefinition.isPlural(event.isParentRelationship))
        {
            return this.localizationStore.translate('Event.RelationshipDelete.Caption'); // deleted
        }
        else
        {
            return super.caption(event);
        }
    }
}
