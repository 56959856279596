import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { BaseEditorProps } from '../../../Editor/Editor';
import LocalizedTextEditor from '../../../../../../@Future/Component/Generic/Input/LocalizedTextEditor/LocalizedTextEditor';

export interface TextEditorProps extends BaseEditorProps
{

}

const Editor: React.FC<TextEditorProps> =
    props =>
    {
        const onChange =
            useCallback(
                (value?: any) =>
                    props.dataObject.setValue(value),
                [
                    props.dataObject
                ]);

        return <LocalizedTextEditor
            value={props.dataObject.value}
            placeholder={props.placeholder}
            inputProps={props.inputProps}
            onChange={onChange}
            disableUnderline={!props.underline}
            fitContent={props.representation?.fitContent}
            autoFocus={props.autoFocus}
            disabled={props.disabled}
        />;
    };

export default observer(Editor);
