import React, { useCallback, useEffect, useState } from 'react';
import TextEditor from '../TextEditor/TextEditor';
import { JsonEditorProps } from './JsonEditor';
import ViewGroup from '../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import RightAlignedButtonGroup from '../../Button/ButtonGroup/RightAlignedButtonGroup';
import SaveIconButton from '../../Button/Variant/SaveIconButton/SaveIconButton';

export interface JsonCodeEditorProps extends JsonEditorProps
{

}

function safelyStringifyJson(json: any)
{
    if (json)
    {
        return JSON.stringify(json);
    }
    else
    {
        return undefined;
    }
}

const JsonCodeEditor: React.FC<JsonCodeEditorProps> =
    props =>
    {
        const { onChange, json } = props;

        const [ value, setValue ] =
            useState(
                () =>
                    safelyStringifyJson(json));

        useEffect(
            () =>
            {
                setValue(safelyStringifyJson(json));
            },
            [
                json,
                setValue
            ]);

        const commit =
            useCallback(
                () =>
                {
                    if (value === undefined)
                    {
                        onChange(undefined);
                    }
                    else
                    {
                        try
                        {
                            onChange(JSON.parse(value));
                        }
                        catch (e)
                        {
                            console.error('invalid json', e);

                            window.alert(e.message);
                        }
                    }

                },
                [
                    value,
                    onChange
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <ViewGroupItem>
                <TextEditor
                    value={value}
                    onChange={setValue}
                    multiline
                    disabled={props.disabled}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <RightAlignedButtonGroup>
                    <SaveIconButton
                        onClick={commit}
                    />
                </RightAlignedButtonGroup>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default JsonCodeEditor;
