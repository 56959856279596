import { observable, runInAction } from 'mobx';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Action from './Action';
import Parameter from '../../Parameter/Parameter';
import getActionFromDescriptor from '../../Api/getActionFromDescriptor';
import Validation from '../../Validation/Validation';
import localizeText from '../../../Localization/localizeText';

export default class CompositeActionInvocation
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref action: Action<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                action: Action<any, any>)
    {
        this.id = id;
        this.action = action;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getResultParameter(): Parameter<any>
    {
        if (this.action.isValid())
        {
            return new Parameter(
                this.id,
                this.action.getType(),
                false,
                localizeText(
                    'ResultOfAction',
                    'Resultaat van actie ${name}',
                    {
                        name: this.action.getName()
                    }
                )
            );
        }
        else
        {
            return undefined;
        }
    }

    getDependencies(): Dependency[]
    {
        return this.action.getDependencies();
    }

    validate(): Validation[]
    {
        return this.action.validate();
    }

    isAsync(): boolean
    {
        return this.action.isAsync();
    }

    toDescriptor()
    {
        return {
            id: this.id,
            action: this.action.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const invocation =
            new CompositeActionInvocation(
                descriptor.id,
                await getActionFromDescriptor(
                    descriptor.action,
                    dependencyContext));

        const resultParameter = invocation.getResultParameter();

        if (resultParameter)
        {
            runInAction(
                () =>
                    dependencyContext.parameterDictionary
                        .addParameter(resultParameter));
        }

        return invocation;
    }

    // ----------------------- Private logic ------------------------
}
