import React, { useCallback, useMemo, useState } from 'react';
import { toJS } from 'mobx';
import ViewGroup from '../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import TabBar from '../../TabBar/TabBar';
import Tab from '../../TabBar/Tab/Tab';
import Icon from '../../Icon/Icon';
import JsonRichEditor from './JsonRichEditor';
import JsonCodeEditor from './JsonCodeEditor';

export interface JsonEditorProps
{
    json?: any;
    onChange?: (json: any) => void;
    disabled?: boolean;
}

const JsonEditor: React.FC<JsonEditorProps> =
    props =>
    {
        const { onChange, json } = props;

        const filteredJson =
            useMemo(
                () =>
                    toJS(json),
                [
                    json
                ]);

        const [ tab, setTab ] = useState(0);
        const onCodeChange =
            useCallback(
                (value?: any) =>
                {
                    onChange(value);
                    setTab(0);
                },
                [
                    onChange,
                    setTab
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            <ViewGroupItem>
                <TabBar
                    value={tab}
                >
                    <Tab
                        value={0}
                        onClick={setTab}
                    >
                        <Icon
                            icon="list"
                        />
                    </Tab>
                    <Tab
                        value={1}
                        onClick={setTab}
                    >
                        <Icon
                            icon="code"
                        />
                    </Tab>
                </TabBar>
            </ViewGroupItem>
            {
                tab === 0 &&
                    <ViewGroupItem>
                        <JsonRichEditor
                            {...props}
                            json={filteredJson}
                        />
                    </ViewGroupItem>
            }
            {
                tab === 1 &&
                    <ViewGroupItem>
                        <JsonCodeEditor
                            {...props}
                            json={filteredJson}
                            onChange={onCodeChange}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

JsonEditor.defaultProps = {
    onChange: () => {}
};

export default JsonEditor;
