import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import PortalUserButtonLayout from '../../../../../@Api/Layout/Type/PortalUserButtonLayout';
import PortalEnvironmentButton from '../../../../App/Root/Environment/Portal/Header/PortalEnvironmentButton/PortalEnvironmentButton';
import PortalUserContext from '../../../../App/Root/Environment/Portal/User/PortalUserContext';
import { observable } from 'mobx';
import CurrentUserContext from '../../../User/CurrentUserContext';

export interface PortalButtonLayoutEditorProps extends LayoutEditorProps<PortalUserButtonLayout>
{

}

const PortalUserButtonLayoutEditor: React.FC<PortalButtonLayoutEditorProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const currentUser =
            useMemo(
                () =>
                    observable.box(currentUserStore.currentUser),
                [
                    currentUserStore
                ]);

        return <PortalUserContext.Provider
            value={currentUser}
        >
            <PortalEnvironmentButton />
        </PortalUserContext.Provider>;
    };

export default observer(PortalUserButtonLayoutEditor);
