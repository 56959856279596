import React, { useMemo } from 'react';
import { EntityEvent } from '../../../../../../@Api/Model/Implementation/EntityEvent';
import styles from './Description.module.scss';
import Mutator from '../../Item/Description/Mutator/Mutator';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntityCreationMutation } from '../../../../../../@Api/Model/Implementation/EntityCreationMutation';
import { EntityRelationshipMutation } from '../../../../../../@Api/Model/Implementation/EntityRelationshipMutation';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';
import { observer } from 'mobx-react-lite';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';

export interface DescriptionProps
{
    entity: Entity;
    events: EntityEvent[];
    addEventsByType: Map<EntityType, EntityRelationshipMutation[]>;
    updateEvents: EntityEvent[];
}

const Description: React.FC<DescriptionProps> =
    props =>
    {
        const firstEvent =
            useMemo(
                () =>
                    props.events[0],
                [
                    props.events
                ]);

        const isCreation =
            useMemo(
                () =>
                    props.events
                        .some(
                            event =>
                                event instanceof EntityCreationMutation),
                [
                    props.events
                ]);

        const isAddition =
            useMemo(
                () =>
                    props.events
                        .some(
                            event =>
                                event instanceof EntityRelationshipMutation
                                    && event.entityRelationshipDefinition.isPlural(event.isParentRelationship)
                                    && equalsEntity(props.entity, event.toRelatedEntity)),
                [
                    props.events,
                    props.entity
                ]);

        const addEventTypes =
            useMemo(
                () =>
                    Array.from(props.addEventsByType.keys()),
                [
                    props.addEventsByType
                ]);


        if (isCreation)
        {
            return <div
                className={styles.root}
            >
                <ViewGroup orientation="horizontal" spacing={3}>
                    <ViewGroupItem>
                        <LocalizedText
                            code="Timeline.CreatedBy"
                            value="Aangemaakt door"
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Mutator event={firstEvent} />
                    </ViewGroupItem>
                </ViewGroup>
            </div>;
        }
        else if (isAddition)
        {
            return <div
                className={styles.root}
            >
                <ViewGroup orientation="horizontal" spacing={3}>
                    <ViewGroupItem>
                        <LocalizedText
                            code="Timeline.AddedBy"
                            value="Toegevoegd door"
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Mutator event={firstEvent} />
                    </ViewGroupItem>
                </ViewGroup>
            </div>;
        }
        else if (addEventTypes.length === 1 && props.updateEvents.length === 0)
        {
            const addType = addEventTypes[0];
            const typeName =
                addType &&
                <strong>{(addType.switchableType || addType).getName(props.addEventsByType.get(addType).length !== 1).toLowerCase()}</strong>;

            return <div
                className={styles.root}
            >
                <ViewGroup orientation="horizontal" spacing={3}>
                    <ViewGroupItem>
                        <LocalizedText
                            code="Timeline.TypeAddedBy"
                            value="${type} toegevoegd door"
                            type={typeName}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Mutator event={firstEvent} />
                    </ViewGroupItem>
                </ViewGroup>
            </div>;
        }
        else
        {
            return <div
                className={styles.root}
            >
                <ViewGroup orientation="horizontal" spacing={3}>
                    <ViewGroupItem>
                        <LocalizedText
                            code="Timeline.EditedBy"
                            value="Aangepast door"
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Mutator event={firstEvent} />
                    </ViewGroupItem>
                </ViewGroup>
            </div>;
        }
    };

export default observer(Description);
