import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { BaseEditorProps } from '../../../Editor/Editor';
import RichtextEditor from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/RichtextEditor';
import EditorButton from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorCommandButton';
import EditorFontSelector from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorFontSelector';
import EditorColorPicker from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorColorPicker';
import CollapsibleToolbar from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/CollapsibleToolbar';
import DefaultToolbarButtons from '../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/DefaultToolbarButtons';
import RichText from '../../../../../Generic/RichText/RichText';

export interface TextEditorProps extends BaseEditorProps
{

}

const Editor: React.FC<TextEditorProps> =
    props =>
    {
        const onChange =
            useCallback(
                (value: string) =>
                    props.dataObject.setValue(value),
                [
                    props.dataObject
                ]);

        if (props.disabled)
        {
            return <RichText
                value={props.dataObject.value}
            />;
        }
        else
        {
            return <RichtextEditor
                value={props.dataObject.value}
                placeholder={props.placeholder}
                {...props.inputProps}
                defaultValue={undefined}
                onChange={onChange as any}
                showToolbarOnFocus
                autoFocus={props.autoFocus}
            >
                <CollapsibleToolbar>
                    <DefaultToolbarButtons />
                    <EditorButton icon="link" tooltip="Link" command="link"/>
                    <EditorFontSelector />
                    <EditorColorPicker tooltip="Selecteer kleur" />
                </CollapsibleToolbar>
            </RichtextEditor>;
        }
    };

export default observer(Editor);
