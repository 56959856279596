import Column from "./Specification/Column";
import { observable } from "mobx";

export class Grouping
{
    // ------------------------- Properties -------------------------

    @observable.ref column: Column;

    // ------------------------ Constructor -------------------------

    constructor(
      column: Column
    )
    {
        this.column = column;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get isValid()
    {
        return this.column !== undefined
            && this.column.isValid !== undefined;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(
      descriptor: any,
      columnById: Map<string, Column>
    )
    {
        return new Grouping(
            columnById.get(descriptor.columnId)
        );
    }

    toDescriptor()
    {
        return {
            columnId: this.column.id,
        };
    }

    // ----------------------- Private logic ------------------------
}
