import { useComputed } from 'mobx-react-lite';
import { default as ResourcePlannerModel } from '../Model/ResourcePlanner';
import useTypes from '../../Type/Api/useTypes';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { Comparator } from '../../../DataObject/Model/Comparator';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../DataObject/Model/DataObject';
import ResourceSelection from '../Model/ResourceSelection';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';

export default function useProjectPlanner()
{
    const types = useTypes();

    return useComputed(
        () =>
        {
            const projectParameter =
                new Parameter(
                    'ProjectResource',
                    new EntityValueType(types.Activity.Project.Type),
                    true,
                    types.Activity.Project.Type.getName());

            const milestoneParameter =
                new Parameter(
                    'MilestoneResource',
                    new EntityValueType(types.Milestone.Type),
                    true,
                    types.Milestone.Type.getName());

            const workOrderParameter =
                types.Activity.WorkOrder.Type &&
                    new Parameter(
                        'WorkOrder',
                        new EntityValueType(types.Activity.WorkOrder.Type),
                        true,
                        types.Activity.WorkOrder.Type.getName());

            const appointmentParameter =
                new Parameter(
                    'Appointment',
                    new EntityValueType(types.Activity.Appointment.Type),
                    true,
                    types.Activity.Appointment.Type.getName());

            const taskParameter =
                new Parameter(
                    'Task',
                    new EntityValueType(types.Activity.Task.Type),
                    true,
                    types.Activity.Task.Type.getName());

            const timeRegistrationParameter =
                types.TimeRegistration.Type &&
                    new Parameter(
                        'TimeRegistration',
                        new EntityValueType(types.TimeRegistration.Type),
                        true,
                        types.TimeRegistration.Type.getName());

            return new ResourcePlannerModel(
                projectParameter,
                new ParameterDictionary([]),
                new ComparisonPredicate(
                    new ValueFromEntityComputation(
                        projectParameter,
                        EntityPath.fromEntityType(types.Activity.Project.Type)
                            .joinTo(
                                types.Activity.Project.RelationshipDefinition.Phase,
                                false)
                            .field(types.Datastore.Phase.Field.IsTerminal)),
                    Comparator.Equals,
                    new PrimitiveValue(
                        DataObject.constructFromTypeIdAndValue(
                            'Boolean',
                            false))),
                [
                    new ResourceSelection(
                        milestoneParameter,
                        EntityPath.fromEntityType(types.Milestone.Type)
                            .joinTo(
                                types.Activity.Project.RelationshipDefinition.Milestones,
                                true),
                        EntityPath.fromEntityType(types.Milestone.Type)
                            .field(types.Milestone.Field.StartDate),
                        EntityPath.fromEntityType(types.Milestone.Type)
                            .field(types.Milestone.Field.EndDate),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        true),
                    workOrderParameter &&
                        new ResourceSelection(
                            workOrderParameter,
                            EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.LinkedActivities,
                                    true),
                            EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                .field(types.Activity.WorkOrder.Field.StartDate),
                            EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                .field(types.Activity.WorkOrder.Field.EndDate),
                            undefined,
                            undefined,
                            true,
                        ),
                    new ResourceSelection(
                        appointmentParameter,
                        EntityPath.fromEntityType(types.Activity.Appointment.Type)
                            .joinTo(
                                types.Activity.RelationshipDefinition.LinkedActivities,
                                true),
                        EntityPath.fromEntityType(types.Activity.Appointment.Type)
                            .field(types.Activity.Appointment.Field.StartDate),
                        EntityPath.fromEntityType(types.Activity.Appointment.Type)
                            .field(types.Activity.Appointment.Field.EndDate),
                        undefined,
                        undefined,
                        true,
                    ),
                    new ResourceSelection(
                        taskParameter,
                        EntityPath.fromEntityType(types.Activity.Task.Type)
                            .joinTo(
                                types.Activity.RelationshipDefinition.LinkedActivities,
                                true),
                        EntityPath.fromEntityType(types.Activity.Task.Type)
                            .field(types.Activity.Task.Field.StartDate),
                        EntityPath.fromEntityType(types.Activity.Task.Type)
                            .field(types.Activity.Task.Field.EndDate),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                taskParameter,
                                EntityPath.fromEntityType(types.Activity.Task.Type)
                                    .field(types.Activity.Task.Field.HasCalendarItem)),
                            Comparator.Equals,
                            new PrimitiveValue(
                                DataObject.constructFromTypeIdAndValue(
                                    'Boolean',
                                    true))
                        ),
                        undefined,
                        true,
                    ),
                    timeRegistrationParameter &&
                        new ResourceSelection(
                            timeRegistrationParameter,
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.TimeRegistrations,
                                    true),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .field(types.TimeRegistration.Field.StartDate),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .field(types.TimeRegistration.Field.EndDate),
                            undefined,
                            undefined,
                            undefined,
                        )
                ].filter(selection => selection),
                [
                    new ResourcePlannerModel(
                        milestoneParameter,
                        new ParameterDictionary([]),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                milestoneParameter,
                                EntityPath.fromEntityType(types.Milestone.Type)
                                    .joinTo(
                                        types.Activity.Project.RelationshipDefinition.Milestones,
                                        true)
                                    .field()),
                            Comparator.Equals,
                            projectParameter),
                        [
                            workOrderParameter &&
                                new ResourceSelection(
                                    workOrderParameter,
                                    EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                        .joinTo(
                                            types.Milestone.RelationshipDefinition.Activities,
                                            true),
                                    EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                        .field(types.Activity.WorkOrder.Field.StartDate),
                                    EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                        .field(types.Activity.WorkOrder.Field.EndDate),
                                    new ComparisonPredicate(
                                        new ValueFromEntityComputation(
                                            workOrderParameter,
                                            EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                                .joinTo(
                                                    types.Activity.RelationshipDefinition.LinkedActivities,
                                                    true)
                                                .field()),
                                        Comparator.Equals,
                                        projectParameter
                                    ),
                                    undefined,
                                    true,
                                ),
                            new ResourceSelection(
                                appointmentParameter,
                                EntityPath.fromEntityType(types.Activity.Appointment.Type)
                                    .joinTo(
                                        types.Milestone.RelationshipDefinition.Activities,
                                        true),
                                EntityPath.fromEntityType(types.Activity.Appointment.Type)
                                    .field(types.Activity.Appointment.Field.StartDate),
                                EntityPath.fromEntityType(types.Activity.Appointment.Type)
                                    .field(types.Activity.Appointment.Field.EndDate),
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        appointmentParameter,
                                        EntityPath.fromEntityType(types.Activity.Appointment.Type)
                                            .joinTo(
                                                types.Activity.RelationshipDefinition.LinkedActivities,
                                                true)
                                            .field()),
                                    Comparator.Equals,
                                    projectParameter),
                                undefined,
                                true,
                            ),
                            new ResourceSelection(
                                taskParameter,
                                EntityPath.fromEntityType(types.Activity.Task.Type)
                                    .joinTo(
                                        types.Milestone.RelationshipDefinition.Activities,
                                        true),
                                EntityPath.fromEntityType(types.Activity.Task.Type)
                                    .field(types.Activity.Task.Field.StartDate),
                                EntityPath.fromEntityType(types.Activity.Task.Type)
                                    .field(types.Activity.Task.Field.EndDate),
                                new CompositePredicate(
                                    LogicalOperator.And,
                                    [
                                        new ComparisonPredicate(
                                            new ValueFromEntityComputation(
                                                taskParameter,
                                                EntityPath.fromEntityType(types.Activity.Task.Type)
                                                    .joinTo(
                                                        types.Activity.RelationshipDefinition.LinkedActivities,
                                                        true)
                                                    .field()),
                                            Comparator.Equals,
                                            projectParameter),
                                        new ComparisonPredicate(
                                            new ValueFromEntityComputation(
                                                taskParameter,
                                                EntityPath.fromEntityType(types.Activity.Task.Type)
                                                    .field(types.Activity.Task.Field.HasCalendarItem)),
                                            Comparator.Equals,
                                            new PrimitiveValue(
                                                DataObject.constructFromTypeIdAndValue(
                                                    'Boolean',
                                                    true)))
                                    ]),
                                undefined,
                                true,
                            ),
                            timeRegistrationParameter &&
                                new ResourceSelection(
                                    timeRegistrationParameter,
                                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                                        .joinTo(
                                            types.Milestone.RelationshipDefinition.TimeRegistrations,
                                            true),
                                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                                        .field(types.TimeRegistration.Field.StartDate),
                                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                                        .field(types.TimeRegistration.Field.EndDate),
                                    new ComparisonPredicate(
                                        new ValueFromEntityComputation(
                                            timeRegistrationParameter,
                                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                                .joinTo(
                                                    types.Activity.RelationshipDefinition.TimeRegistrations,
                                                    true)
                                                .field()),
                                        Comparator.Equals,
                                        projectParameter),
                                    undefined,
                                    undefined,
                                )
                            ].filter(selection => selection)
                    )
                ]
            );
        },
        [
            types
        ]);
}
