import React, { useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import useAttachedEntityPaths from './Api/useAttachedEntityPaths';
import { EntityPath } from '../../Path/@Model/EntityPath';
import List from './List/List';
import { classNames } from '../../../../../@Future/Util/Class/classNames';
import HighlightContext from '../HighlightContext/HighlightedEntityContext';
import styles from './Navigator.module.scss';
import More from './More/More';
import useHasMore from './More/Api/useHasMore';
import Divider from '../../../../../@Future/Component/Generic/Divider/Divider';
import useTypes from '../../Type/Api/useTypes';
import useOpenedEntity from '../../Viewer/Context/OpenedEntity/useOpenedEntity';
import Buttons from './Buttons/Buttons';
import NavigatorContext from './NavigatorContext/NavigatorContext';
import equalsEntity from '../../../../../@Api/Entity/Bespoke/equalsEntity';
import useIsContextExpanded from './Api/useIsContextExpanded';
import useSetContextExpansion from './Api/useSetContextExpansion';
import useContextExpansionPath from './Api/useContextExpansionPath';
import HighlightContainer from './HighlightContainer/HighlightContainer';
import ExpansionGroup from '../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import useTransactionalEntity from '../../../../../@Api/Entity/Bespoke/useTransactionalEntity';

export interface NavigatorClasses
{
    deleteButton?: string;
    root?: string;
    toolbar?: string;
    expansion?: string;
    more?: string;
}

export interface NavigatorProps
{
    entity: Entity;
    classes?: NavigatorClasses;
    showButtons?: boolean;
    showMore?: boolean;
}

const Navigator: React.FC<NavigatorProps> =
    props =>
    {
        let paths = useAttachedEntityPaths(props.entity.entityType);
        const types = useTypes();
        const hasMore = useHasMore(props.entity);
        const context = useContext(NavigatorContext);
        const isContextExpanded = useIsContextExpanded();
        const contextExpansion = useContextExpansionPath();
        const setContextExpansion = useSetContextExpansion();

        const highlightedEntities = useContext(HighlightContext);

        const rootPath =
            useMemo(
                () =>
                    EntityPath.fromEntity(props.entity),
                [
                    props.entity
                ]);

        const openedEntity = useOpenedEntity();

        const transactionalEntity = useTransactionalEntity(isContextExpanded ? props.entity : undefined);

        useEffect(
            () =>
            {
                if (equalsEntity(openedEntity, props.entity))
                {
                    if (openedEntity.entityType.isA(types.Activity.Offer.Type)
                        || openedEntity.entityType.isA(types.Activity.Email.Type))
                    {
                        setContextExpansion(rootPath);
                    }
                    else
                    {
                        setContextExpansion(
                            paths.find(
                                path => path.entityType.id === types.Note.Type.id));
                    }
                }
            },
            [
                props.entity,
                openedEntity,
                setContextExpansion,
                paths,
                types,
                rootPath,
                context
            ]);

        const hasHighlight =
            useMemo(
                () =>
                    !isContextExpanded &&
                    highlightedEntities && highlightedEntities.length > 0,
                [
                    isContextExpanded,
                    highlightedEntities
                ]);

        const isExpanded =
            useMemo(
                () =>
                    (isContextExpanded || hasHighlight),
                [
                    isContextExpanded,
                    hasHighlight
                ]);

        const isMore =
            useMemo(
                () =>
                    contextExpansion === rootPath,
                [
                    contextExpansion,
                    rootPath
                ]);

        if (paths.length === 0 && !hasMore)
        {
            return null;
        }
        else
        {
            return <div
                className={
                    classNames(
                        styles.root,
                        props.classes && props.classes.root,
                        isMore && props.classes && props.classes.more)
                }
            >
                {
                    props.showButtons &&
                        <Buttons
                            entity={props.entity}
                        />
                }
                {
                    isExpanded &&
                        <Divider />
                }
                {
                    hasHighlight &&
                        <HighlightContainer
                            paths={paths}
                        />
                }
                {
                    isContextExpanded &&
                        <div
                            className={props.classes && props.classes.expansion}
                        >
                            {
                                contextExpansion && contextExpansion.id === rootPath.id
                                    ?
                                        <More
                                            entity={transactionalEntity}
                                        />
                                    :
                                        <div
                                            className={styles.list}
                                        >
                                            <HighlightContext.Provider
                                                value={undefined}
                                            >
                                                <ExpansionGroup>
                                                    <List
                                                        overline
                                                        entity={props.entity}
                                                        path={contextExpansion}
                                                        editable
                                                    />
                                                </ExpansionGroup>
                                            </HighlightContext.Provider>
                                        </div>
                            }
                        </div>
                }
            </div>;
        }
    };

export default observer(Navigator);
