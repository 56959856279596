import performAction from '../../../Entity/performAction';
import ActionSignature from '../../../Automation/ActionTemplate/Model/ActionSignature';
import AutomationDependencyContext from '../../../Automation/AutomationDependencyContext';

export default async function getPortalActionSignatureById(actionId: string,
                                                           dependencyContext: AutomationDependencyContext): Promise<ActionSignature>
{
    const result =
        await performAction<any[]>(
            undefined,
            {
                code: 'Portal.GetPortalActionSignature',
                parameters: {
                    actionId: actionId
                }
            });

    return ActionSignature.fromDescriptor(result.result, dependencyContext);
}
