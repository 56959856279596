import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useCount from '../../../Selection/Hooks/useCount';
import { EntityPath } from '../../../Path/@Model/EntityPath';

export interface RelatedEntityCountProps
{
    entity: Entity
    path: EntityPath;
}

const RelatedEntityCount: React.FC<RelatedEntityCountProps> =
    ({
         entity,
         path
    }) =>
        {
            const relatedEntityCount =
                useCount(
                   path.entityType,
                    (builder, rootPath) =>
                        builder.where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath
                                        .joinTo(
                                            path.relationshipDefinition,
                                            true
                                        ),
                                    entity
                                )
                        ),
                    [
                        path,
                        entity
                    ]);

            if(relatedEntityCount > 0)
            {
                return <>
                    {relatedEntityCount}
                </>
            }
            else
            {
                return null
            }
        }

export default observer(RelatedEntityCount);
