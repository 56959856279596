import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import useTypes from '../../../../../Entity/Type/Api/useTypes';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import ActionTemplate from '../../../../../../../@Api/Automation/ActionTemplate/Model/ActionTemplate';
import AutomationDependencyContext from '../../../../../../../@Api/Automation/AutomationDependencyContext';
import CompositeAction from '../../../../../../../@Api/Automation/Function/Action/CompositeAction';
import ParametersEditor from '../../../../../Entity/Viewer/Content/Automation/Editor/Parameters/ParametersEditor';
import ActionEditor from '../../../../../Entity/Viewer/Content/Automation/Editor/Action/ActionEditor';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import FunctionContext from '../../../../../../../@Api/Automation/Function/FunctionContext';
import Action from '../../../../../../../@Api/Automation/Function/Action/Action';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import CancelButton from '../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import SaveButton from '../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import { default as EntityInput } from '../../../../../Entity/Input/Input';
import useTransactionalEntity from '../../../../../../../@Api/Entity/Bespoke/useTransactionalEntity';

export interface ActionTemplateEditorProps
{
    entity: Entity;
    onCancel: () => void;
    onSave: () => void;
}

const ActionTemplateEditor: React.FC<ActionTemplateEditorProps> =
    props =>
    {
        const { entity, onCancel, onSave } = props;
        const types = useTypes();
        const transactionalEntity = useTransactionalEntity(entity);

        const [ template, isLoading ] =
            useAsyncResult(
                async () =>
                {
                    const specification = entity.getObjectValueByField(types.ActionTemplate.Field.Specification);

                    if (specification)
                    {
                        return ActionTemplate.fromDescriptor(
                            entity.uuid,
                            specification,
                            new AutomationDependencyContext(
                                new ParameterDictionary([])));
                    }
                    else
                    {
                        return new ActionTemplate(
                            entity.uuid,
                            new ParameterDictionary([]),
                            new CompositeAction([]));
                    }
                },
            [
                entity,
                types
            ]);

        const context =
            useMemo(
                () =>
                    template &&
                        new FunctionContext(template.parameters),
                [
                    template
                ]);

        const setAction =
            useCallback(
                (action?: Action<any, any>) =>
                    runInAction(
                        () =>
                            template.action = action),
                [
                    template
                ]);

        const save =
            useCallback(
                () =>
                {
                    transactionalEntity.setValueByField(
                        types.ActionTemplate.Field.Specification,
                        template.toDescriptor());

                    return transactionalEntity.checkAndDoCommit()
                        .then(onSave);
                },
                [
                    transactionalEntity,
                    types,
                    template,
                    onSave
                ]);

        if (isLoading)
        {
            return <Centered
                horizontal
                vertical
            >
                <Loader />
            </Centered>;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <CardInset
                        bottom={false}
                    >
                        <EntityInput
                            labelPosition="none"
                            entity={transactionalEntity}
                            field={types.ActionTemplate.Field.Name}
                            doAutocommit={false}
                        />
                    </CardInset>
                </ViewGroupItem>
                <ViewGroupItem>
                    <ParametersEditor
                        parameterDictionary={template.parameters}
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <CardInset>
                        <ViewGroup
                            orientation="vertical"
                            spacing={15}
                        >
                            <ViewGroupItem>
                                <ActionEditor
                                    context={context}
                                    action={template.action}
                                    onChange={setAction}
                                    returnValue
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <RightAlignedButtonGroup>
                                    <CancelButton
                                        onClick={onCancel}
                                    />
                                    <SaveButton
                                        onClick={save}
                                    />
                                </RightAlignedButtonGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </CardInset>
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

export default observer(ActionTemplateEditor);
