import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../../../Type/Api/useTypes';
import { default as LabelInput } from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ExpansionPanel from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { CampaignProps } from '../Campaign';
import Input from '../../../../../Input/Input';
import getDatastoreByCode from '../../../../../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import Checkbox from '../../../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import PrimaryButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import { CommitBuilder } from '../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { setValueByFieldInEntity } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import styles from '../../../../../Email/EntityEmailDialog.module.scss';
import useIsSendingFromDomainAllowed from '../../../../../../Configuration/Page/DomainValidation/Hooks/useIsSendingFromDomainAllowed';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';

export interface CampaignStartProps extends CampaignProps
{
    commitContext: CommitContext;
}

const CampaignStart: React.FC<CampaignStartProps> =
    props =>
    {
        const types = useTypes();
        const entity = props.entity;

        const dateScheduled =
            useEntityValue(
                entity,
                types.Activity.Campaign.Field.DateScheduled,
                undefined,
                props.commitContext
            );

        const toggleScheduled =
            useCallback(
                () =>
                    setValueByFieldInEntity(
                        entity,
                        types.Activity.Campaign.Field.DateScheduled,
                        dateScheduled
                            ? undefined
                            : new Date(),
                        props.commitContext
                    ),
                [
                    entity,
                    types,
                    dateScheduled,
                    props.commitContext,
                ]);

        const currentUserStore = useContext(CurrentUserContext);

        const isTrial =
            useComputed(
                () =>
                    !currentUserStore.contractEntity?.hasValueForField(types.LicenseContract.Field.ActivationDate),
                [
                    currentUserStore,
                    types
                ]);

        const scheduleCampaign =
            useCallback(
                () =>
                {
                    if (!dateScheduled || dateScheduled > new Date())
                    {
                        return getDatastoreByCode(types.Datastore.Phase.ActivityCampaign.Type, 'Planned')
                            .then(
                                scheduledPhase =>
                                    new CommitBuilder()
                                        .relateEntityTo(
                                            entity,
                                            false,
                                            types.Activity.Campaign.RelationshipDefinition.Phase,
                                            scheduledPhase
                                        )
                                        .commit()
                            )
                    }
                    else
                    {
                        alert(localizeText('Campaign.PlannedDateCannotBeInFuture', 'De ingeplande datum kan niet in het verleden liggen.'))
                    }
                },
                [
                    entity,
                    dateScheduled,
                    types
                ]);

        const senderAddress =
            useMemo( () =>
                {
                    return entity?.getDataObjectValueByField(types.Activity.Campaign.Field.SenderEmailAddress, true, props.commitContext);
                },
                [
                    entity
                ]
            );

        const [ sendingAllowed, isLoadingSendingAllowed ] = useIsSendingFromDomainAllowed(senderAddress);

        return <ExpansionPanel
            id="Start"
            summary={
                <Header
                    large
                    inset
                    title={
                        <LocalizedText
                            code="Campaign.ScheduleAndSend"
                            value="Inplannen en verzenden"
                        />
                    }
                />
            }
            expansion={
                <CardInset>
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        <ViewGroupItem>
                            <LabelInput
                                label={
                                    <LocalizedText
                                        code="Campaign.ScheduleAndSendLater"
                                        value="Campagne inplannen en later verzenden"
                                    />
                                }
                                labelPosition="right"
                            >
                                <Checkbox
                                    checked={dateScheduled !== undefined}
                                    onToggle={toggleScheduled}
                                />
                            </LabelInput>
                        </ViewGroupItem>
                        {
                            dateScheduled &&
                                <ViewGroupItem>
                                    <Input
                                        labelPosition="top"
                                        entity={props.entity}
                                        field={types.Activity.Campaign.Field.DateScheduled}
                                        commitContext={props.commitContext}
                                    />
                                </ViewGroupItem>
                        }
                        <ViewGroupItem>
                            {
                                !sendingAllowed && !isLoadingSendingAllowed &&
                                <div
                                    className={styles.domainWarning}
                                >
                                    <LocalizedText
                                        code="Generic.EmailDomainNotValidated"
                                        value="E-maildomein niet gevalideerd"
                                    />
                                </div>
                            }
                            <PrimaryButton
                                label={
                                    dateScheduled
                                    ?
                                        <LocalizedText
                                            code="Campaign.Schedule"
                                            value="Inplannen"
                                        />
                                    :
                                        <LocalizedText
                                            code="Campaign.Send"
                                            value="Verzenden"
                                        />
                                }
                                onClick={scheduleCampaign}
                                disabled={!sendingAllowed}
                            />
                            {
                                isTrial &&
                                <div
                                    className={styles.domainWarning}
                                >
                                    <LocalizedText
                                        code="CampaignMaxEMailWarning"
                                        value="Tijdens de proefperiode worden maximaal 15 e-mails per campagne verstuurd."
                                    />
                                </div>
                            }
                        </ViewGroupItem>
                    </ViewGroup>
                </CardInset>
            }
        />;
    };

export default observer(CampaignStart);
