import * as React from 'react';
import { DataObjectViewerStore } from '../../Viewer/DataObjectViewerStore';
import { observer } from 'mobx-react';
import { DataObjectBespokeViewer } from '../../Viewer/DataObjectBespokeViewer';
import { ComplexViewStore } from './ComplexViewStore';

@observer
export class ComplexView extends DataObjectBespokeViewer<ComplexViewStore>
{
    renderComponent(store: DataObjectViewerStore<ComplexViewStore>)
    {
        return <div>ComplexView</div>;
    }
}
