import { EntitySelectionAggregateResult } from '../../../../Selection/Model/EntitySelectionAggregateResult';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { useCallback } from 'react';
import updateEntityWithResult from '../../../../Dataset/Segment/Api/updateEntityWithResult';
import Segment from '../../../../Dataset/Model/Segment';

export default function useBucketDropCallback(segment: Segment,
                                              draggingEntity?: Entity,)
{
    // const entityTypeStore = useContext(EntityTypeContext);
    //
    // const onEnd =
    //     useCallback(
    //         (result: DropResult) =>
    //         {
    //             const entity = entityTypeStore.entityCacheService.getEntity(result.draggableId);
    //             const targetValue = results.get(result.destination && result.destination.droppableId);
    //
    //             if (entity)
    //             {
    //                 updateEntityWithResult(
    //                     entity,
    //                     category,
    //                     new EntitySelectionAggregateResult(
    //                         targetValue,
    //                         undefined,
    //                         undefined,
    //                         [],
    //                         category.groupFieldPath,
    //                         []));
    //             }
    //         },
    //         [
    //             entityTypeStore,
    //             results,
    //             category
    //         ]);
    //
    // useOnDragEnd(onEnd);

    return useCallback(
        (result: Entity) =>
        {
            if (draggingEntity)
            {
                updateEntityWithResult(
                    draggingEntity,
                    segment,
                    new EntitySelectionAggregateResult(
                        result,
                        undefined,
                        undefined,
                        [],
                        segment.groupFieldPath,
                        []));
            }
        },
        [
            draggingEntity,
            segment
        ]);
}
