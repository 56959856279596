import Dependency from '../Parameter/Dependency';
import Parameter from '../Parameter/Parameter';

export default function getDependenciesWithoutParameters(
    dependencies: Dependency[],
    ...parameters: Parameter<any>[]
): Dependency[]
{
    return dependencies
        .filter(
            dependency =>
                parameters.every(
                    parameter =>
                        parameter !== dependency.parameter
                )
        );
}
