import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { default as GenericInput } from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { default as ValueToValueMappingModel } from '../../../../Model/FieldMapping/ValueToValue/ValueToValueMapping';
import { DataObjectEditor } from '../../../../../../DataObject/Editor/Value/Editor/DataObjectEditor';
import { DataObjectEditorStore } from '../../../../../../DataObject/Editor/Value/Editor/DataObjectEditorStore';
import ValueToValueSourceFieldMapping from '../../../../Model/FieldMapping/ValueToValue/ValueToValueSourceFieldMapping';
import useTypes from '../../../../../Type/Api/useTypes';
import MappingEntityTypeEditor from '../MappingEntityTypeEditor/MappingEntityTypeEditor';
import TypeMapping from '../../../../Model/TypeMapping';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import styles from '../ValueToValueMapping/ValueToValueMapping.module.scss';
import SourceValueLabel from '../SourceValueLabel/SourceValueLabel';

export interface ValueToValueMappingProps
{
    typeMapping: TypeMapping;
    fieldMapping: ValueToValueSourceFieldMapping;
    valueMapping: ValueToValueMappingModel;
}

const ValueToValueMapping: React.FC<ValueToValueMappingProps> =
    props =>
    {
        const types = useTypes();
        const editorStore =
            useMemo(
                () =>
                    new DataObjectEditorStore({
                        dataObject: props.valueMapping.targetValue
                    }),
                [
                    props.valueMapping
                ]);

        return <GenericInput
            label={
                <SourceValueLabel
                    name={props.valueMapping.sourceValueName}
                    value={props.valueMapping.sourceValue}
                />
            }
            labelPosition="left"
        >
            <ViewGroup
                orientation="horizontal"
                spacing={5}
                alignment="center"
            >
                <ViewGroupItem
                    ratio={1}
                >
                    <div
                        className={styles.editor}
                    >
                        {
                            props.fieldMapping.targetFieldPath.field === types.Entity.Field.Type
                                ?
                                    <MappingEntityTypeEditor
                                        typeMapping={props.typeMapping}
                                        value={props.valueMapping.targetValue}
                                    />
                                :
                                    <DataObjectEditor
                                        store={editorStore}
                                    />
                        }
                    </div>
                </ViewGroupItem>
                <ViewGroupItem
                    ratio={1}
                />
            </ViewGroup>
        </GenericInput>;
    };

export default observer(ValueToValueMapping);
