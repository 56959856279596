import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Default from '../../Default';
import { ContentProps } from '../../Content';
import useTypes from '../../../../Type/Api/useTypes';
import Planner from '../../../../Planner/Planner';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import EntityRecurrencePatternEditor from '../../../../Viewer/Shared/RecurrencePattern/EntityRecurrencePatternEditor';
import { AppointmentAttendeesEditor } from '../../../../Bespoke/Activity/Appointment/AppointmentAttendeesEditor/AppointmentAttendeesEditor';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { TeamsMeetingPlanner } from './Teams/TeamsMeetingPlanner';

export interface ActivityAppointmentProps extends ContentProps
{

}

const ActivityAppointment: React.FC<ActivityAppointmentProps> =
    props =>
    {
        const types = useTypes();
        const employeePath =
            useMemo(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Activity.Appointment.RelationshipDefinition.Owner,
                            false),
                [
                    props.entity,
                    types
                ]);

        return <Default
            {...props}
            entity={props.entity}
            appendix={
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <TeamsMeetingPlanner
                            appointment={props.entity}
                            commitContext={props.commitContext}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Planner
                            entity={props.entity}
                            startDateField={types.Activity.Appointment.Field.StartDate}
                            endDateField={types.Activity.Appointment.Field.EndDate}
                            employeePath={employeePath}
                            autoCommit={props.autoCommit}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <EntityRecurrencePatternEditor
                            {...props}
                            entity={props.entity}
                            startDateField={types.Activity.Appointment.Field.StartDate}
                            isRecurringField={types.Activity.Appointment.Field.IsRecurring}
                            recurrencePatternField={types.Activity.Appointment.Field.RecurrencePattern}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Input
                            label={types.Activity.Appointment.RelationshipDefinition.Attendees.getName(false)}
                            labelPosition="left"
                        >
                            <AppointmentAttendeesEditor
                                appointment={props.entity}
                                commitContext={props.commitContext}
                                autoCommit={props.autoCommit}
                            />
                        </Input>
                    </ViewGroupItem>
                </ViewGroup>
            }
        />;
    };

export default observer(ActivityAppointment);
