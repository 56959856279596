import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { EntityPathNode } from './EntityPathNode';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPathRootNode } from './Node/EntityPathRootNode';
import { EntityPathCastNode } from './Node/EntityPathCastNode';
import { EntityPathJoinNode } from './Node/EntityPathJoinNode';
import { EntityPathStaticNode } from './Node/EntityPathStaticNode';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntityRelationship } from '../../../../../@Api/Model/Implementation/EntityRelationship';
import { EntityField } from '../../../../../@Api/Model/Implementation/EntityField';
import { EntityFieldPath } from './EntityFieldPath';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { EntityFieldGroup } from '../../../Management/Application/EntityTypesEditor/EntityFieldGroup';
import cached from '../../../../../@Util/Cached/cached';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';

export class EntityPath
{
    // ------------------------- Properties -------------------------

    nodes: EntityPathNode[];

    // ------------------------ Constructor -------------------------

    constructor(nodes: EntityPathNode[] = [])
    {
        this.nodes = nodes;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    /**
     * Gets the unique id of this entity path.
     *
     * @returns unique id of this entity path
     */
    @cached
    get id(): string
    {
        return this.nodes.map(node => (`${node.id()}`)).join('.');
    }

    /**
     * Gets the unique code of this entity path for a user friendly description.
     *
     * @returns unique code of this entity path
     */
    @cached
    get code(): string
    {
        return this.nodes.map(node => (`${node.code()}`)).join('.');
    }

    /**
     * Gets the root entity type of this entity path.
     *
     * @return root entity type of this entity path
     */
    @cached
    get rootEntityType(): EntityType
    {
        if (this.nodes.length > 0)
        {
            return this.nodes[0].getEntityType(null);
        }
        else
        {
            return null;
        }
    }

    /**
     * Gets entity type of last node in path.
     *
     * @returns {EntityType} entity type of last node in path
     */
    @cached
    get entityType(): EntityType
    {
        return this.getNodeEntityType(this.nodes.length - 1);
    }

    @cached
    get firstJoinNode(): EntityPathJoinNode
    {
        return this.nodes
            .find(node => node instanceof EntityPathJoinNode) as EntityPathJoinNode;
    }

    @cached
    get lastJoinNode(): EntityPathJoinNode
    {
        return this.nodes.slice().reverse()
            .find(node => node instanceof EntityPathJoinNode) as EntityPathJoinNode;
    }

    @cached
    get lastNode(): EntityPathNode
    {
        if (this.nodes.length === 0)
        {
            return null;
        }
        else
        {
            return this.nodes[this.nodes.length - 1];
        }
    }

    @cached
    get descriptor(): any
    {
        return this.nodes.map(
            node => (node.descriptor()));
    }

    isInFieldGroup(entityFieldGroup: EntityFieldGroup, entityTypeStore: EntityTypeStore): boolean
    {
        const relatedFieldGroups = this.relationshipDefinition.entity
            .getRelatedEntitiesByDefinition(
                true,
                entityTypeStore.bespoke.types.EntityFieldGroup.RelationshipDefinition.Relationships);

        if (entityFieldGroup.entity)
        {
            return relatedFieldGroups
                .find(groupEntity =>
                    groupEntity.id === entityFieldGroup.entity.id) !== undefined;
        }
        else
        {
            return relatedFieldGroups.length === 0;
        }
    }

    @cached
    get relationshipDefinition(): EntityRelationshipDefinition
    {
        if (this.lastJoinNode)
        {
            return this.lastJoinNode.relationshipDefinition;
        }
        else
        {
            return undefined;
        }
    }

    @cached
    get isPlural(): boolean
    {
        return this.nodes.some(
            node =>
                node instanceof EntityPathJoinNode
                && node.relationshipDefinition.isPlural(node.isParent));
    }

    get length(): number
    {
        return this.nodes.length;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static root(entityType: EntityType): EntityPath
    {
        return new EntityPath([ new EntityPathRootNode(entityType) ]);
    }

    static fromEntity(entity: Entity): EntityPath
    {
        return new EntityPath(
            [
                new EntityPathRootNode(entity.entityType)
            ]);
    }

    static fromEntityType(entityType: EntityType): EntityPath
    {
        return new EntityPath(
            [
                new EntityPathRootNode(entityType)
            ]);
    }

    static construct(nodes: any[],
                     entityTypeStore: EntityTypeStore = loadModuleDirectly(EntityTypeStore)): EntityPath
    {
        let baseEntityType: EntityType | undefined = undefined;
        const pathNodes: EntityPathNode[] = [];

        for (const node of nodes)
        {
            const pathNode =
                EntityPath.constructNode(
                    node,
                    baseEntityType,
                    entityTypeStore
                );

            pathNodes.push(pathNode);
            baseEntityType = pathNode.getEntityType(baseEntityType);

            if (!baseEntityType)
            {
                throw new Error(`Entity type not found`);
            }
        }

        return new EntityPath(pathNodes);
    }

    static constructNode(node: any,
                         baseEntityType: EntityType | undefined,
                         entityTypeStore: EntityTypeStore): EntityPathNode
    {
        if (node.entityTypeId)
        {
            return EntityPathRootNode.construct(node, entityTypeStore);
        }
        else if (node.childEntityTypeId)
        {
            return EntityPathCastNode.construct(node, entityTypeStore);
        }
        else if (node.parentRelationshipDefinitionId || node.childRelationshipDefinitionId)
        {
            return EntityPathJoinNode.construct(node, baseEntityType!, entityTypeStore);
        }
        else if (node.staticEntityTypeCode)
        {
            return EntityPathStaticNode.construct(node, entityTypeStore);
        }
        else
        {
            throw new Error('Node type not found');
        }
    }

    field(field?: EntityField): EntityFieldPath
    {
        if (field)
        {
            return new EntityFieldPath(
                this.castTo(field.entityType),
                field);
        }
        else
        {
            return new EntityFieldPath(
                this,
                field);
        }
    }

    /**
     * Gets node entity type.
     *
     * @param i index of the node in the entity type
     * @return {EntityType}
     */
    getNodeEntityType(i: number): EntityType
    {
        let node = this.nodes[i];

        if (i < 0)
        {
            return null;
        }
        else if (i === 0)
        {
            return this.rootEntityType;
        }
        else if (i > 0)
        {
            let baseEntityType = this.getNodeEntityType(i - 1);

            if (baseEntityType)
            {
                return node.getEntityType(baseEntityType);
            }
            else
            {
                return null;
            }
        }
        else
        {
            return null;
        }
    }

    /**
     * Gets the name of the entity path.
     */
    getName(entityTypeStore: EntityTypeStore = loadModuleDirectly(EntityTypeStore),
            includeRootNode: boolean = true,
            includePath: boolean = true): string
    {
        let names = [];

        for (let node of this.nodes)
        {
            names.push(node.getName(entityTypeStore));
        }

        if (!includeRootNode)
        {
            names = names.slice(1);

            if (!includePath)
            {
                names = [ names[names.length - 1] ];
            }
        }

        if (names.length === 0)
        {
            return '...';
        }
        else
        {
            return names.join(' › ');
        }
    }

    /**
     * Creates a new entity path from this entity path and an additional node.
     *
     * @param node
     */
    addNode(node: EntityPathNode): EntityPath
    {
        return new EntityPath(this.nodes.concat([ node ]));
    }

    /**
     * Joins a new entity path node from this entity path and an additional node.
     *
     * @param node
     */
    joinNode(node: EntityPathNode): EntityPath
    {
        return this.join(
            new EntityPath(
                [
                    new EntityPathRootNode(this.entityType),
                    node
                ]));
    }

    joinTo(relationshipDefinition: EntityRelationshipDefinition,
           isParent: boolean,
           isRecursive?: boolean)
    {
        return this.joinNode(
            new EntityPathJoinNode(
                isParent,
                relationshipDefinition,
                isRecursive));
    }

    castTo(childEntityType: EntityType)
    {
        return this.joinNode(
            new EntityPathCastNode(
                childEntityType));
    }

    /**
     * Creates a new entity path from this entity path and the specified entity path.
     *
     * @param {EntityPath} path
     */
    join(path: EntityPath): EntityPath
    {
        let joinedPath = new EntityPath(this.nodes);

        for (let node of path.nodes)
        {
            joinedPath = node.joinNode(joinedPath);
        }

        return joinedPath;
    }

    rootedAt(type: EntityType): EntityPath
    {
        if (this.rootEntityType === type)
        {
            return this;
        }
        else
        {
            let path = EntityPath.fromEntityType(type);

            for (const node of this.nodes.slice(1))
            {
                path = path.joinNode(node);
            }

            return path;
        }
    }

    /**
     * Reverses entity path.
     */
    reverse(): EntityPath
    {
        let inverseBaseEntityType = this.entityType;
        let inverseEntityPath = new EntityPath([ new EntityPathRootNode(inverseBaseEntityType) ]);

        for (let node of this.nodes.slice().reverse())
        {
            let inverseNode = node.inverseNode(
                inverseBaseEntityType);

            if (inverseNode)
            {
                inverseEntityPath = inverseEntityPath.addNode(inverseNode);
                inverseBaseEntityType = inverseEntityPath.entityType;
            }
        }

        // if (!inverseBaseEntityType.isA(rootEntityType))
        // {
        //     inverseEntityPath = inverseEntityPath.add(new EntityPathCastNode(rootEntityType));
        // }

        return inverseEntityPath;
    }

    /**
     * Traverses path in entity.
     *
     * @returns entities that belong to the path traversal
     */
    traverseEntity(entity: Entity,
                   commitContext?: CommitContext,
                   onRelationship?: (relationship: EntityRelationship, isParent: boolean) => void,
                   onEntity?: (entity: Entity, path: EntityPath, idx: number) => void): Entity[]
    {
        let traversalEntities = [ entity ];
        let idx = 0;

        if (onEntity)
        {
            onEntity(entity, this.pathAt(1), 0);
        }

        for (let node of this.nodes)
        {
            let newTraversalEntities: Entity[] = [];

            for (let traversalEntity of traversalEntities)
            {
                newTraversalEntities.push(
                    ...node.traverseEntity(
                        traversalEntity,
                        commitContext,
                        onRelationship
                    )
                );
            }

            if (newTraversalEntities.length === 0)
            {
                return [];
            }

            if (onEntity && !(node instanceof EntityPathRootNode))
            {
                newTraversalEntities.forEach(
                    /* eslint-disable-next-line no-loop-func */
                    traversalEntity =>
                        onEntity(
                            traversalEntity,
                            this.pathAt(idx + 2),
                            idx + 1));
            }

            traversalEntities = newTraversalEntities;
            idx += 1;
        }

        return traversalEntities;
    }

    /**
     * Traverses an entity using data from the server.
     *
     * @param {Entity} entity
     * @returns {Promise<Entity[]>}
     */
    traverseEntityAsync(entity: Entity): Promise<Entity[]>
    {
        return new EntitySelectionBuilder(this.entityType)
            .where(
                cb =>
                    cb.relatedToEntity(
                        this.reverse(),
                        entity))
            .select()
            .then(
                results =>
                    Promise.resolve(results.map(result => result.entity)));
    }

    /**
     * Constructs the related entity based on a original entity.
     *
     * @param {Entity} baseEntity
     * @returns {Entity}
     */
    constructEntity(baseEntity: Entity = new Entity(this.rootEntityType),
                    addRelationshipToBase: boolean = true,
                    commitContext?: CommitContext): Entity
    {
        let relatedEntity = baseEntity;

        this.nodes.forEach(
            (node, idx) =>
            {
                let entityType = this.getNodeEntityType(idx);

                for (let i = idx + 1; i < this.nodes.length; i++)
                {
                    if (this.nodes[i].isVirtual())
                    {
                        entityType = this.getNodeEntityType(i);
                    }
                    else
                    {
                        break;
                    }
                }

                relatedEntity =
                    node.constructEntity(
                        relatedEntity,
                        // Force create if it is the last join node
                        // So: we traverse through existing relationships up until the last join node
                        node === this.lastJoinNode,
                        addRelationshipToBase,
                        entityType,
                        commitContext
                    );
            });

        return relatedEntity;
    }

    /**
     * Name of the last entity path node.
     *
     * @returns {string}
     */
    name(entityTypeStore: EntityTypeStore): string
    {
        if (this.nodes && this.nodes.length > 0)
        {
            return this.nodes[this.nodes.length - 1].getName(entityTypeStore);
        }
        else
        {
            return null;
        }
    }

    /**
     * Determines whether this path equals the specified path.
     *
     * @param {EntityPath} path
     * @returns {boolean}
     */
    equals(path: EntityPath): boolean
    {
        return this.id === path.id;
    }

    /**
     * Determines whether this path is a subpath of the specified path.
     *
     * @param path
     */
    in(path: EntityPath): boolean
    {
        return path.id.indexOf(this.id) >= 0;
    }

    /**
     * Determines whether the specified path is a subpath of this path.
     *
     * @param path
     */
    contains(path: EntityPath): boolean
    {
        return this.id.indexOf(path.id) >= 0;
    }

    /**
     * Gets the path represented from index 0 up to and excluding the specified index.
     *
     * @param {number} index
     * @returns {EntityPath}
     */
    pathAt(index: number): EntityPath
    {
        return new EntityPath(this.nodes.slice(0, index));
    }

    /**
     * Gets the path represented from index i up to the end of the path.
     *
     * @param {number} index
     * @returns {EntityPath}
     */
    pathFrom(index: number): EntityPath
    {
        let path = EntityPath.root(this.pathAt(index + 1).entityType);

        this.nodes.slice(index + 1)
            .forEach(
                node =>
                    path =
                        path.joinNode(node));

        return path;
    }

    /**
     * Gets path until node.
     *
     * @param {EntityPathNode} node
     * @returns {EntityPath}
     */
    getPathUntilNode(node: EntityPathNode): EntityPath
    {
        const idx = this.nodes.findIndex(checkNode => checkNode === node);

        if (idx >= 0)
        {
            return new EntityPath(this.nodes.slice(0, idx));
        }
        else
        {
            return undefined;
        }
    }

    // ----------------------- Private logic ------------------------
}
