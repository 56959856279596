import Period from '../../../Model/Period';

export default class Cell
{
    // ------------------------- Properties -------------------------

    idx: number;
    period: Period;
    isActive: boolean;
    isPrimaryHeader: boolean;
    isSecondaryHeader: boolean;

    // ------------------------ Constructor -------------------------

    constructor(idx: number,
                period: Period,
                isActive: boolean,
                isPrimaryHeader: boolean,
                isSecondaryHeader: boolean)
    {
        this.idx = idx;
        this.period = period;
        this.isActive = isActive;
        this.isPrimaryHeader = isPrimaryHeader;
        this.isSecondaryHeader = isSecondaryHeader;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
