import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../Value/PrimitiveValue';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import Value from '../../Value/Value';
import FunctionContext from '../FunctionContext';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import localizeText from '../../../Localization/localizeText';

export default class CurrentDateComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('Date')
        );
    }

    getName(): string
    {
        return localizeText('Generic.CurrentDate', 'Huidige datum');
    }

    validate(): Validation[]
    {
        return [];
    }

    isAsync(): boolean
    {
        return false;
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        return this.synchronousApply(context);
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        return new PrimitiveValue(
            DataObject.constructFromTypeIdAndValue(
                'Date',
                new Date()
            )
        );
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'CurrentDate';
    }

    getDependencies(): Dependency[]
    {
        return [];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new CurrentDateComputation();
    }

    // ----------------------- Private logic ------------------------
}
