import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import getTypes from '../../../../Type/Api/getTypes';
import { EntityField } from '../../../../../../../@Api/Model/Implementation/EntityField';
import getExpressionContextForTemplate from './getExpressionContextForTemplate';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import getConnectorActivationByCode from '../../../../../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';

export default async function getExpressionContextByTemplateType(
    templateType: EntityType,
    templateField: EntityField,
    commitContext?: CommitContext
)
{
    const types = getTypes();
    let rootType: EntityType;

    if (templateType.isA(types.Template.Document.Offer.Type))
    {
        rootType = types.Activity.Offer.Type;
    }
    else if (templateType.isA(types.Template.Document.Invoice.Type))
    {
        rootType = types.Activity.Invoice.Type;
    }
    else if (templateType.isA(types.Template.Document.DocumentActivity.Type))
    {
        rootType = types.Activity.Document.Type;
    }
    else if (templateType.isA(types.Template.Document.WorkOrder.Type))
    {
        rootType = types.Activity.WorkOrder.Type;
    }
    else if (templateType.isA(types.Template.Email.WorkOrder.Type))
    {
        rootType = types.Activity.WorkOrder.Type;
    }
    else if (templateType.isA(types.Template.Email.WorkOrderSign.Type))
    {
        rootType = types.Activity.WorkOrder.Type;
    }
    else if (templateType.isA(types.Template.Email.Type))
    {
        rootType = types.Activity.Email.Type;
    }

    const result = getExpressionContextForTemplate(
        rootType,
        templateType,
        templateField,
        undefined,
        undefined,
        commitContext
    );

    if (rootType === types.Activity.Offer.Type)
    {
        await getConnectorActivationByCode("ValidSign")
            .then(
                validSignConnectorActivation => {
                    const validSignConnectorActive = validSignConnectorActivation &&
                        validSignConnectorActivation.getObjectValueByField(types.ConnectorActivation.Field.IsActivated)

                    if (validSignConnectorActive)
                    {
                        ['1', '2', '3', '4'].forEach(
                            (idx) =>
                            {
                                result.entityContext = result.entityContext.setVariable({
                                    name: localizeText(
                                        'ValidSignClickToSign',
                                        'Klik om te ondertekenen ${nr}',
                                        { nr: idx }
                                    ),
                                    variable: 'Validsign.Signer' + idx + '.ClickToSign'
                                })

                                result.entityContext = result.entityContext.setVariable({
                                    name: localizeText(
                                        'ValidSignSignature',
                                        'Handtekening van ondertekenaar ${nr}',
                                        { nr: idx }
                                    ),
                                    variable: 'Validsign.Signer' + idx + '.Signature'
                                })
                            }
                        );
                    }

                }
            );
    }

    return result;
}
