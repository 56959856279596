import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import uuid from '../../../../../../../@Util/Id/uuid';
import LayoutDependencyContext from '../../../../../../../@Api/Layout/LayoutDependencyContext';
import Layout from '../../../../../../../@Api/Layout/Layout';
import LayoutEditor from '../../../../../Layout/Editor/LayoutEditor';
import ClickLayout from '../../../../../../../@Api/Layout/Type/ClickLayout';
import InsetLayout from '../../../../../../../@Api/Layout/Type/InsetLayout';
import CompositeLayout from '../../../../../../../@Api/Layout/Type/CompositeLayout';
import RouteAction from '../../../../../../../@Api/Layout/Action/Type/RouteAction';
import TextComputation from '../../../../../../../@Api/Automation/Function/Computation/TextComputation';
import ComputationInText from '../../../../../../../@Api/Automation/Function/Computation/ComputationInText';
import ValueFromEntityComputation from '../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../../Model/ViewParams';
import EntityValueType from '../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import useTypes from '../../../../Type/Api/useTypes';
import DeleteButton from '../../../../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import CancelButton from '../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';

export interface ItemLayoutEditorDialogProps
{
    parameters: ParameterDictionary;
    initialItemLayout: Layout;
    onClose: () => void;
    onSave: (layout?: Layout) => void;
}

const ItemLayoutEditorDialog: React.FC<ItemLayoutEditorDialogProps> =
    ({
         parameters,
         initialItemLayout,
         onClose,
         onSave,
     }) =>
    {
        const types = useTypes();

        const [ itemLayout, setItemLayout ] =
            useState<Layout>(
                () =>
                {
                    if (initialItemLayout)
                    {
                        return initialItemLayout;
                    }
                    else
                    {
                        const routeParameterId = uuid();
                        const entityParameter = parameters.getParameterById(ViewParams.Entity) as Parameter<EntityValueType>;

                        return new ClickLayout(
                            new InsetLayout(
                                new CompositeLayout(
                                    'Vertical',
                                    []
                                ),
                                8,
                                15,
                                8,
                                15
                            ),
                            new RouteAction(
                                new TextComputation(
                                    `/entity/<computation data-id="${routeParameterId}" />`,
                                    false,
                                    [
                                        new ComputationInText(
                                            routeParameterId,
                                            new ValueFromEntityComputation(
                                                entityParameter,
                                                EntityPath.fromEntityType(entityParameter.type.type)
                                                    .field(types.Entity.Field.Id)
                                            )
                                        )
                                    ]
                                )
                            )
                        );
                    }
                }
            )
        const itemLayoutContext =
            useMemo(
                () =>
                    new LayoutDependencyContext(
                        parameters
                    ),
                []
            );

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            {
                itemLayout &&
                    <ViewGroupItem>
                        <Card>
                            <LayoutEditor
                                layout={itemLayout}
                                onChange={setItemLayout}
                                parameterDictionary={itemLayoutContext.parameterDictionary}
                            />
                        </Card>
                    </ViewGroupItem>
            }
            <ViewGroupItem>
                <RightAlignedButtonGroup>
                    <CancelButton
                        onClick={onClose}
                    />
                    {
                        itemLayout &&
                        <DeleteButton
                            onClick={
                                () =>
                                {
                                    onSave(undefined);
                                    onClose();
                                }}
                        />
                    }
                    <SaveButton
                        onClick={
                            () =>
                            {
                                onSave(itemLayout);
                                onClose();
                            }}
                    />
                </RightAlignedButtonGroup>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(ItemLayoutEditorDialog);
