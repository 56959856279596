import React from 'react';
import IconButton, { IconButtonProps } from '../Icon/IconButton';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface NoteIconButtonProps extends Partial<IconButtonProps>
{

}

const NoteIconButton: React.FC<NoteIconButtonProps> =
    props =>
    {
        return <IconButton
            icon="comment"
            onClick={props.onClick}
            tooltip={
                <LocalizedText
                    code="Note"
                    value="Notitie"
                />
            }
            {...props}
        />;
    };

export default NoteIconButton;
