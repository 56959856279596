import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';

export default function useIcon(entity: Entity,
                                allowCharactersOrIcon: boolean)
{
    return useComputed(
        () =>
            allowCharactersOrIcon &&
                entity.entityType.getInheritedIcon(),
        [
            allowCharactersOrIcon,
            entity
        ]);
}