import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../Type/Api/useTypes';
import { ItemPlannerEmployeePlanCellProps } from '../../EmployeeRow/PlanCell/EmployeeAllocationEditorEmployeePlanCell';
import usePaginatedSelection from '../../../View/Api/usePaginatedSelection';
import HoverCardBottom from '../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { InitializedEmployeeAllocationTableEditor } from '../InitializedEmployeeAllocationTableEditor';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { Box, Typography } from '@material-ui/core';
import Input from '../../../Input/Input';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import MenuButton from '../../../Item/MenuButton/MenuButton';

export interface EmployeeAllocationSequenceEditorProps extends ItemPlannerEmployeePlanCellProps
{
    sequence: Entity;
    onClose: () => void;
}

export const EmployeeAllocationSequenceEditor: React.FC<EmployeeAllocationSequenceEditorProps> =
    observer(
        props =>
        {
            const {
                sequence,
                specification,
                employeeAllocationParameterAssignment,
                resource,
                startDate,
                onClose,
            } = props;
            const types = useTypes();
            const [ pages, isLoading, loadMore, hasMore ]  =
                usePaginatedSelection(
                    types.EmployeeAllocation.Type,
                    (builder, rootPath) =>
                        builder
                            .where(
                                cb =>
                                    cb.relatedToEntity(
                                        rootPath.joinTo(
                                            types.EmployeeAllocationSequence.RelationshipDefinition.Allocations,
                                            true
                                        ),
                                        sequence
                                    )
                            )
                            .join(
                                rootPath.joinTo(
                                    types.Relationship.RelationshipDefinition.EmployeeAllocations,
                                    true
                                )
                            )
                            .join(
                                rootPath.joinTo(
                                    types.Activity.RelationshipDefinition.EmployeeAllocations,
                                    true
                                )
                            )
                            .orderBy(
                                rootPath.field(types.EmployeeAllocation.Field.WeekDate),
                                true
                            ),
                    [
                        types,
                        resource,
                        startDate,
                        specification,
                        employeeAllocationParameterAssignment,
                    ]
                );
            const items =
                useComputed(
                    () =>
                        pages
                            .map(page => page.slice())
                            .reduce(
                                (a, b) => a.concat(b),
                                []
                            )
                            .map(
                                result =>
                                    result.entity!
                            ),
                    [pages]
                );

            return <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                <ViewGroupItem>
                    <CardInset>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    alignment="start"
                                    justification="start"
                                    spacing={5}
                                >
                                    <ViewGroupItem>
                                        <Typography
                                            variant="h6"
                                        >
                                            {types.EmployeeAllocationSequence.Type.getName()}
                                        </Typography>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <Input
                                            entity={sequence}
                                            field={types.EmployeeAllocationSequence.Field.StartDate}
                                            labelPosition="left"
                                            disabled
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <Input
                                            entity={sequence}
                                            field={types.EmployeeAllocationSequence.Field.EndDate}
                                            labelPosition="left"
                                            disabled
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <MenuButton
                                    entity={sequence}
                                    onDelete={onClose}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                {/* Make room for close button in dialog */}
                                <Box
                                    width={20}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </CardInset>
                </ViewGroupItem>
                <ViewGroupItem>
                    <InitializedEmployeeAllocationTableEditor
                        {...props}
                        items={items}
                        loading={isLoading}
                        includeDate
                        disableConstructor
                        disableSequenceControls
                    />
                    {
                        hasMore &&
                        <HoverCardBottom
                            onClick={loadMore}
                            disabled={isLoading}
                        >
                            <LocalizedText
                                code="Generic.LoadMore"
                                value="Meer laden"
                            />...
                        </HoverCardBottom>
                    }
                </ViewGroupItem>
            </ViewGroup>;
        }
    );
