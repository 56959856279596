import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import Role, { RoleType } from '../../Model/Role';
import { EntityType, sortTypes } from '../../../../../@Api/Model/Implementation/EntityType';
import CardHeader from '../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { ItemEditor } from './Item/ItemEditor';
import { PrivilegeHeader } from '../RightEditor/PrivilegeHeader/PrivilegeHeader';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { useIconToggleClick } from 'useseparatetoggleclick';
import useDividerGlue from '../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import Divider from '../../../../../@Future/Component/Generic/Divider/Divider';
import styles from '../RoleEditor.module.scss';
import { hasRoleEntityTypeVisibleChildren, isRoleEntityTypeVisible } from '../../Api/useRoleEntityType';

export interface RoleEntityTypeTreeProps
{
    rolesByType: Map<RoleType, Role>;
    role: Role;
    entityType: EntityType;
    onOpen: (entityType: EntityType) => void;
    filter?: (entityType: EntityType) => boolean;
    includeAll?: boolean;
    includeNonInstantiableChildTypes?: boolean;
    title?: React.ReactNode;
    hideRoot?: boolean;
    isReadOnly: boolean;
}

export const RoleEntityTypeTree: React.FC<RoleEntityTypeTreeProps> =
    observer(
        ({
             role,
             entityType,
             onOpen,
             filter,
             includeAll,
             includeNonInstantiableChildTypes,
             title,
             hideRoot,
             isReadOnly,
             rolesByType,
         }) =>
        {
            const dividerGlue = useDividerGlue();

            const getEntityTypeChildren =
                useCallback(
                    (entityType: EntityType) =>
                    {
                        const children = entityType
                            ?.childTypes
                            .filter(
                                childType =>
                                    filter ? filter(childType) : true)
                            .filter(
                                childType =>
                                    !includeNonInstantiableChildTypes ? childType.isInstantiable : true);

                        return sortTypes(children);
                    },
                    [
                        filter,
                        includeNonInstantiableChildTypes
                    ]);

            const renderTreeItemControl =
                useCallback(
                    (type, hidden) =>
                        (
                            <ViewGroupItem>
                                <ItemEditor
                                    role={role}
                                    label={
                                        includeAll && entityType.id === type.id &&
                                        <LocalizedText
                                            code="Generic.All"
                                            value="Alles"
                                        />
                                    }
                                    entityType={type}
                                    onOpen={onOpen}
                                    filter={filter}
                                    isReadOnly={isReadOnly || hidden}
                                    rolesByType={rolesByType}
                                />
                                <Divider />
                            </ViewGroupItem>
                        ),
                    [
                        role,
                        entityType,
                        includeAll,
                        onOpen,
                        filter,
                        isReadOnly
                    ]);

            const renderTreeItem =
                (type) =>
                {
                    const isVisible = isRoleEntityTypeVisible(role, type);
                    const hasVisibleChildren = isVisible || hasRoleEntityTypeVisibleChildren(role, type);

                    return (isVisible || hasVisibleChildren) &&
                        <TreeItem
                            key={type.id}
                            nodeId={type.id.toString()}
                            classes={{
                                label: styles.label
                            }}
                            label={renderTreeItemControl(type, !isVisible && hasVisibleChildren)}
                        >
                            {renderTreeItemChildren(type)}
                        </TreeItem>;
                };

            const renderTreeItemChildren =
                (type) =>
                    getEntityTypeChildren(type)
                        .map(
                            (childType) =>
                                renderTreeItem(childType)
                        );

            const treeView =
                useMemo(
                    () =>
                        hideRoot
                            ? renderTreeItemChildren(entityType)
                            : renderTreeItem(entityType),
                    [
                        renderTreeItemControl,
                        entityType,
                        hideRoot
                    ]);


            const getAllNodesIds =
                useCallback(
                    (type: EntityType) =>
                    {
                        let initialExpandedIds: string[] =  hideRoot ? [] : [ entityType.id.toString() ];

                        initialExpandedIds.push(
                            ...getEntityTypeChildren(type)
                                .map(childType => childType.id.toString()));

                        for (let childType of getEntityTypeChildren(type))
                        {
                            initialExpandedIds.push(...getAllNodesIds(childType));
                        }

                        return initialExpandedIds;
                    },
                    [
                        entityType,
                        hideRoot,
                        getEntityTypeChildren
                    ]);

            const initialExpandedNodeIds =
                 useMemo(
                     () =>
                         getAllNodesIds(entityType),
                     [
                         entityType
                     ]);

            // https://github.com/tonyhallett/useSeparateToggleClick
            const {
                expanded,
                onNodeToggle: onIconToggle,
                IconWrapper: IconIconWrapper
            } = useIconToggleClick(  [ ...initialExpandedNodeIds ] );

            return <Card>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    glue={dividerGlue}
                >
                    <ViewGroupItem>
                        <CardInset
                            classes={{
                                root: styles.cardHeader
                            }}
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={0}
                                alignment="end"
                                className={styles.header}
                            >
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <CardHeader>
                                        {title || entityType.getName()}
                                    </CardHeader>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <PrivilegeHeader />
                                </ViewGroupItem>
                            </ViewGroup>
                        </CardInset>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <TreeView
                            expanded={expanded}
                            onNodeToggle={onIconToggle}
                            defaultCollapseIcon={
                                <IconIconWrapper
                                >
                                    <ExpandMoreIcon />
                                </IconIconWrapper>
                            }
                            defaultExpandIcon={
                                <IconIconWrapper>
                                    <ChevronRightIcon />
                                </IconIconWrapper>
                            }
                            className={styles.root}
                        >
                            {treeView}
                        </TreeView>
                    </ViewGroupItem>
                </ViewGroup>
            </Card>;
        }
    );
