import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';

export class UserPublicController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    acceptInvitation(
        token: string,
        emailAddress: string,
        firstName: string,
        lastName: string,
        languageCode: string
    ): Promise<any>
    {
        return new Promise<any>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        '/public/user/accept-invitation',
                        Method.Post,
                        {
                            token: token,
                            email_address: emailAddress,
                            first_name: firstName,
                            last_name: lastName,
                            language_code: languageCode
                        }))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };
}
