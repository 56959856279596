import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationBox from '../../Box/ComputationBox';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import EntityTypeFromEntityTypeEntityComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/EntityTypeFromEntityTypeEntityComputation';
import EntityValueType from '../../../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import useTypes from '../../../../../../../Type/Api/useTypes';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface EntityTypeFromEntityTypeEntityComputationEditorProps extends ComputationEditorProps<ValueType<any>, EntityTypeFromEntityTypeEntityComputation>
{

}

const EntityTypeFromEntityTypeEntityComputationEditor: React.FC<EntityTypeFromEntityTypeEntityComputationEditorProps> =
    props =>
    {
        const setValue =
            useCallback(
                (entity?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.entity = entity
                    ),
                [
                    props.value
                ]
            );
        const types = useTypes();
        const type =
            useMemo(
                () =>
                    new EntityValueType(types.EntityType.Type),
                [types]
            );

        return <Input
            label={
                <LocalizedText
                    code="Computation.EntityTypeFromEntityTypeEntity"
                    value="Entiteitstype uit entiteitstype entiteit"
                />
            }
            labelPosition="left"
        >
            <ComputationBox>
                <ComputationEditor
                    context={props.context}
                    value={props.value.entity}
                    onChange={setValue}
                    type={type}
                />
            </ComputationBox>
        </Input>;
    };

export default observer(EntityTypeFromEntityTypeEntityComputationEditor);
