import { observable } from 'mobx';
import ParameterAssignment from '../../Automation/Parameter/ParameterAssignment';
import PortalDataSourceSignature from './PortalDataSourceSignature';
import Predicate from '../../Automation/Function/Computation/Predicate/Predicate';

export default class PortalDataSourceQuery
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref dataSourceSignature: PortalDataSourceSignature;
    @observable.ref parameterAssignment: ParameterAssignment;
    @observable.ref filter?: Predicate;
    @observable state: string;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                dataSourceSignature: PortalDataSourceSignature,
                parameterAssignment: ParameterAssignment,
                filter: Predicate | undefined)
    {
        this.id = id;
        this.dataSourceSignature = dataSourceSignature;
        this.parameterAssignment = parameterAssignment;
        this.filter = filter;
        this.state = id;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor()
    {
        return {
            id: this.id,
            dataSourceId: this.dataSourceSignature.id,
            parameterAssignment: this.parameterAssignment.toDescriptor(),
            filter: this.filter?.toDescriptor()
        };
    }

    // ----------------------- Private logic ------------------------
}
