import { reference, registerType, type } from '../../../@Util/Serialization/Serialization';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { EntityRelationship } from './EntityRelationship';
import { EntityValue } from './EntityValue';
import { EntityField } from './EntityField';
import { DataObject } from '../../../@Component/Domain/DataObject/Model/DataObject';
import { EntityRelationshipDefinition, ReferentialAction } from './EntityRelationshipDefinition';
import { action, computed, IObservableArray, observable } from 'mobx';
import { StaticEntityField } from './StaticEntityField';
import { User } from './User';
import { EntityEvent } from './EntityEvent';
import { EntityValueMutation } from './EntityValueMutation';
import { createTransactionalModel, getAdministration, getModel, isTransactionalModel } from '../../../@Util/TransactionalModelV2';
import { EntityType } from './EntityType';
import { EntityTypeParameter } from './EntityTypeParameter';
import { EntityContext } from '../../../@Component/Domain/Entity/@Model/EntityContext';
import { DateType } from '../../../@Component/Domain/DataObject/Type/Date/DateType';
import { registerBuilder } from '../../../@Util/TransactionalModelV2/Shared/TransactionalBuilder';
import { EntityRelationshipMutation } from './EntityRelationshipMutation';
import { EntityInputMutation } from '../../../@Component/Domain/Entity/Input/EntityInputMutation';
import { EntityDeletionMutation } from './EntityDeletionMutation';
import { EntityRelationshipCreationMutation } from './EntityRelationshipCreationMutation';
import { EntityRelationshipUpdateMutation } from './EntityRelationshipUpdateMutation';
import { EntityRelationshipDeletionMutation } from './EntityRelationshipDeletionMutation';
import { injectWithQualifier, loadModuleDirectly } from '../../../@Util/DependencyInjection/index';
import { v4 as uuid } from 'uuid';
import { Organization } from './Organization';
import { EntityEventTypeStore } from '../../../@Component/Domain/Entity/Event/EntityEventTypeStore';
import { mapBy } from '../../../@Util/MapUtils/mapBy';
import { BespokeEntity } from '../../../@Component/Domain/Entity/Type/BespokeEntity';
import { groupBy } from '../../../@Util/MapUtils/groupBy';
import getEntityDescriptor from '../../Entity/Descriptor/getEntityDescriptor';
import equalsEntity from '../../Entity/Bespoke/equalsEntity';
import Input from '../../../@Component/Domain/Multiplayer/Model/Input/Input';
import FieldInput from '../../../@Component/Domain/Multiplayer/Model/Input/FieldInput';
import RelationshipInput from '../../../@Component/Domain/Multiplayer/Model/Input/RelationshipInput';
import getNameFieldByType from '../../Metadata/Field/getNameFieldByType';
import { FileValue } from '../../../@Component/Domain/DataObject/Type/File/FileValue';
import { LocalizedTextType } from '../../../@Component/Domain/DataObject/Type/LocalizedText/LocalizedTextType';
import validateEntity from '../../Entity/Validation/validateEntity';
import { EntityValidationOptions } from '../../Entity/Validation/EntityValidationOptions';
import { Mutex } from '../../../@Util/Mutex/Mutex';
import commitEntity from '../../Entity/Commit/commitEntity';
import { EntityCreationMutation } from './EntityCreationMutation';
import { EntityValueCreationMutation } from './EntityValueCreationMutation';
import { EntityValueUpdateMutation } from './EntityValueUpdateMutation';
import { EntityValueDeletionMutation } from './EntityValueDeletionMutation';
import { EntityTypeCreationMutation } from './EntityTypeCreationMutation';
import { EntityTypeUpdateMutation } from './EntityTypeUpdateMutation';
import { EntityTypeDeletionMutation } from './EntityTypeDeletionMutation';
import { EntityFieldCreationMutation } from './EntityFieldCreationMutation';
import { EntityFieldUpdateMutation } from './EntityFieldUpdateMutation';
import { EntityFieldDeletionMutation } from './EntityFieldDeletionMutation';
import { EntityRelationshipDefinitionCreationMutation } from './EntityRelationshipDefinitionCreationMutation';
import { EntityRelationshipDefinitionUpdateMutation } from './EntityRelationshipDefinitionUpdateMutation';
import { EntityRelationshipDefinitionDeletionMutation } from './EntityRelationshipDefinitionDeletionMutation';
import { CommitContext } from '../../Entity/Commit/Context/CommitContext';
import { EntityTypeParameterValue } from './EntityTypeParameterValue';
import { CommitMutationOptions } from '../../Entity/Commit/Context/Model/CommitMutationOptions';

export const mutationDuration = 5000;

@type('Entity')
export class Entity
{
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;

    // ------------------- Persistent Properties --------------------

    @observable id: number;
    @observable uuid: string;
    @observable sortIndex: number;
    @observable _name: string;

    set name(value: string)
    {
        this._name = value;
    }

    @observable compactName: string;
    @observable sortName: string;
    @observable description: string;
    @reference(undefined, 'Organization') @observable.ref organization: Organization;
    @reference(undefined, 'EntityType') @observable.ref entityType: EntityType;
    @reference(undefined, 'EntityRelationship') @observable.shallow parentRelationships: EntityRelationship[];
    @reference(undefined, 'EntityRelationship') @observable.shallow childRelationships: EntityRelationship[];
    @reference(undefined, 'EntityValue') @observable.shallow values: EntityValue[];
    @reference(undefined, 'EntityEvent') @observable.shallow events: EntityEvent[];
    @reference(undefined, 'User') @observable.ref user: User;

    // ------------------------- Properties -------------------------

    @observable isManaged: boolean;
    @observable isInitialized: boolean = false;
    deletedParentRelationships: IObservableArray<EntityRelationship>;
    deletedChildRelationships: IObservableArray<EntityRelationship>;
    @observable doAutoCommit: boolean;
    @observable isDeleted = false;
    lastMutationByField: Map<EntityField, EntityInputMutation>;
    lastMutationTimeoutByField: Map<EntityField, any>;
    lastMutationByRelationshipDefinition: Map<EntityRelationshipDefinition, EntityInputMutation>;
    lastMutationTimeoutByRelationshipDefinition: Map<EntityRelationshipDefinition, any>;
    handledEventIds: Set<number>;
    @observable.ref entityContext: EntityContext;
    @observable commitTimeout: any;
    @observable commitPromise: Promise<any>;
    @observable commitPromiseWrapper: Promise<any>;
    @observable isCommitRequestedDuringCommit = false;
    @observable checkAndDoCommitDebounced: () => Promise<any>;
    @observable.ref commitMutex: Mutex;
    @observable isFocused: boolean = false;
    @observable.ref commitContext: CommitContext;

    // ------------------------ Constructor -------------------------

    constructor(entityType?: EntityType)
    {
        this.entityType = entityType;
    }

    // ----------------------- Initialization -----------------------

    initializeWithoutLocalAutomations()
    {
        return this.initialize(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            false);
    }

    @action
    initialize(entityTypeStore: EntityTypeStore = this.entityTypeStore,
               onEntity?: (entity: Entity) => void,
               onRelationship?: (relationship: EntityRelationship) => void,
               forceInitialization: boolean = false,
               doMergeWithCache: boolean = true,
               doMergeEditableValues: boolean = true,
               doReturnCachedInstance: boolean = false,
               doPerformLocalAutomations: boolean = true): this
    {
        return this._initialize(
            entityTypeStore,
            onEntity,
            onRelationship,
            forceInitialization,
            doMergeWithCache,
            doMergeEditableValues,
            doReturnCachedInstance,
            doPerformLocalAutomations);
    }

    _initialize(entityTypeStore: EntityTypeStore,
               onEntity?: (entity: Entity) => void,
               onRelationship?: (relationship: EntityRelationship) => void,
               forceInitialization: boolean = false,
               doMergeWithCache: boolean = true,
               doMergeEditableValues: boolean = true,
               doReturnCachedInstance: boolean = false,
               doPerformLocalAutomations: boolean = true): this
    {
        if (this.isInitialized && !forceInitialization)
        {
            return this;
        }

        this.isInitialized = true;

        if (!this.uuid)
        {
            this.uuid = uuid();
        }

        if (!this.parentRelationships)
        {
            this.parentRelationships = observable.array();
        }

        if (!this.childRelationships)
        {
            this.childRelationships = observable.array();
        }

        if (!this.values)
        {
            this.values = observable.array();
        }

        if (!this.deletedParentRelationships)
        {
            this.deletedParentRelationships = observable.array();
        }

        if (!this.deletedChildRelationships)
        {
            this.deletedChildRelationships = observable.array();
        }

        // Initialize entity type (entity may be lazy, so do a null-check)
        if (this.entityType)
        {
            this.entityType = entityTypeStore.getTypeById(this.entityType.id);
        }

        // Initialize context
        this.entityContext = EntityContext.fromEntity(this);

        // Initialize values
        let valuesToRemove: EntityValue[];

        if (typeof this.values.forEach !== 'function')
        {
            console.warn('[PI202200828] this.values.forEach is nog a function', this);
        }

        this.values
            .forEach(
                value =>
                {
                    if (!value.initialize(this, entityTypeStore))
                    {
                        if (!valuesToRemove)
                        {
                            valuesToRemove = [];
                        }

                        valuesToRemove.push(value);
                    }
                });

        if (valuesToRemove)
        {
            valuesToRemove.forEach(
                value =>
                    (this.values as IObservableArray).remove(value));
        }

        if (this.events)
        {
            loadModuleDirectly(EntityEventTypeStore)
                .initializeEvents(this.events, doMergeEditableValues);
        }

        if (this.entityType)
        {
            if (this.isNew()
                && doPerformLocalAutomations)
            {
                if (this.entityType.bespoke)
                {
                    this.entityType.bespoke.onConstruct(this);
                }
                else
                {
                    console.warn('no bespoke found on entity type of entity', this);
                }
            }

            if (onEntity)
            {
                onEntity(this);
            }

            // Initialize parent relationships
            this.initializeRelatedEntities(
                this.parentRelationships,
                entityTypeStore,
                onEntity,
                onRelationship,
                doMergeEditableValues,
                doPerformLocalAutomations);

            // Initialize child relationships
            this.initializeRelatedEntities(
                this.childRelationships,
                entityTypeStore,
                onEntity,
                onRelationship,
                doMergeEditableValues,
                doPerformLocalAutomations);

            if (doMergeWithCache)
            {
                entityTypeStore.entityCacheService.mergeEntityNetwork(getModel(this), doMergeEditableValues);

                if (doReturnCachedInstance)
                {
                    return entityTypeStore.entityCacheService.getEntity(this.uuid) as this;
                }
            }
        }

        return this;
    }

    initializeRelatedEntities(relationships: EntityRelationship[],
                              entityTypeStore: EntityTypeStore,
                              onEntity: (entity: Entity) => void,
                              onRelationship: (relationship: EntityRelationship) => void,
                              doMergeEditableValues: boolean,
                              doPerformLocalAutomations: boolean)
    {
        for (const relationship of relationships)
        {
            relationship
                .initialize(
                    entityTypeStore,
                    onEntity,
                    onRelationship,
                    undefined,
                    doMergeEditableValues,
                    doPerformLocalAutomations);
        }
    }

    // -------------------------- Computed --------------------------

    @computed get bespoke()
    {
        return new BespokeEntity(
            this.entityTypeStore,
            this);
    }

    @computed get valueByField(): Map<EntityField, EntityValue>
    {
        return mapBy(this.values, value => value.field);
    }

    @computed get editableValues(): EntityValue[]
    {
        return this.entityType
            .getInheritedFields()
            .concat(...this.values
                .filter(value => value.field.isStaticField())
                .map(value => value.field))
            .map(field => this.getValueByField(field, false))
            .filter(value => value.isEditable(this.entityTypeStore));
    }

    @computed get isValid(): boolean
    {
        return this.isValidImpl().isValid;
    }

    // This method is kept for easy debugging from the browser
    isValidImpl(options?: Partial<EntityValidationOptions>)
    {
        return validateEntity(
            this,
            options
        );
    }

    @computed get parentRelationshipsByDefinition(): Map<EntityRelationshipDefinition, EntityRelationship[]>
    {
        return groupBy(
            this.parentRelationships,
            relationship => relationship.definition);
    }

    @computed get deletedParentRelationshipsByDefinition(): Map<EntityRelationshipDefinition, EntityRelationship[]>
    {
        return groupBy(
            this.deletedParentRelationships,
            relationship => relationship.definition);
    }

    @computed get childRelationshipsByDefinition(): Map<EntityRelationshipDefinition, EntityRelationship[]>
    {
        return groupBy(
            this.childRelationships,
            relationship => relationship.definition);
    }

    @computed get deletedChildRelationshipsByDefinition(): Map<EntityRelationshipDefinition, EntityRelationship[]>
    {
        return groupBy(
            this.deletedChildRelationships,
            relationship => relationship.definition);
    }

    @computed get descriptor(): any
    {
        return getEntityDescriptor(this as any, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    }

    @computed
    get isEmpty(): boolean
    {
        return this.getEditableFields()
            .every(
                field =>
                    !this.hasValueForField(field));
    }

    @computed
    get name()
    {
        return this.getName();
    }

    getName(commitContext?: CommitContext)
    {
        if (this.entityType.inheritedNameField &&
            (commitContext || this.entityType.inheritedNameField?.dataObjectSpecification.type instanceof LocalizedTextType))
        {
            return this.getDataObjectValueByField(
                this.entityType.inheritedNameField,
                undefined,
                commitContext
            )?.toString();
        }
        else
        {
            return this._name;
        }
    }

    // -------------------------- Actions ---------------------------

    @action addRelationship(relationship: EntityRelationship,
                            isParent: boolean,
                            isSilently: boolean = false)
    {
        if (isTransactionalModel(relationship) && !isTransactionalModel(relationship.getEntity(isParent)))
        {
            // console.warn('adding transactional relationship to non-transactional entity, so we disallow this', this, relationship, isParent, isSilently);
            return;
        }

        if (!isSilently)
        {
            relationship
                .getEntity(isParent)
                .addRelationship(relationship, !isParent, true);
        }

        this.getRelationships(isParent)
            .push(relationship);

        if (isParent)
        {
            this.deletedParentRelationships.remove(relationship);
        }
        else
        {
            this.deletedChildRelationships.remove(relationship);
        }

        this.entityType.bespoke.onRelate(
            this,
            relationship.definition,
            isParent,
            relationship.getEntity(isParent));

        // If a new relationship is added, then simply add it to the cache if not yet exists
        // This is because if we create a new entity, add relationships to it, the cache
        // will not contain the relationships. This will result in duplicated relationships instantiated
        // when the saved relationship state is returned from the API.
        // const entityCacheService = this.entityTypeStore.entityCacheService;
        //
        // if (entityCacheService.hasEntity(this.uuid))
        // {
        //     if (!entityCacheService.hasRelationship(relationship.uuid))
        //     {
        //         entityCacheService.insertRelationship(getModel(relationship));
        //     }
        // }
    }

    @action deleteRelationship(relationship: EntityRelationship,
                               isParent: boolean,
                               isDeletion: boolean = false,
                               isSilently: boolean = false,
                               addToDeletedRelationships: boolean = true,
                               isDeletionFromOtherSide: boolean = false)
    {
        this.logDeletionIfNecessary(
            relationship,
            isParent
        );

        relationship.isDeleted = true;

        const relatedEntity = relationship.getEntity(isParent);

        let doCascadeDelete = false;

        if (isDeletion && relatedEntity)
        {
            switch (relationship.definition.getDeletionReferentialAction(!isParent))
            {
                case ReferentialAction.Cascade:
                    doCascadeDelete = true;
                    break;

                case ReferentialAction.CascadeWhenNotShared:
                    if (!relatedEntity.hasRelationshipsByDefinition(!isParent, relationship.definition))
                    {
                        doCascadeDelete = true;
                    }
                    break;

                case ReferentialAction.Restrict:
                    if (!isDeletionFromOtherSide)
                    {
                        throw new Error(`Cannot delete entity of type ${this.entityType.code} by deleting relationship of type ${relationship.definition.code} (is parent: ${isParent})`);
                    }
                    break;

                case ReferentialAction.None:
                    break;
            }
        }

        // It might be that relationship.getEntity(isParent) is null, because of a cycle in the deletion (after this the related entity is being deleted)
        if (!isSilently && relatedEntity)
        {
            relatedEntity.deleteRelationship(relationship, !isParent, doCascadeDelete, true, addToDeletedRelationships, true);
        }

        // Perform propagation
        if (doCascadeDelete)
        {
            relationship.getEntity(isParent)
                .deleteEntity();
        }

        // Deleting all relationships with this UUID
        // TODO [DD]: there are still situations where multiple relationships with the same UUID are contained, this should be checked out
        let relationshipIdx =
            this.getRelationships(isParent)
                .findIndex(
                    checkRelationship =>
                        checkRelationship.uuid === relationship.uuid);

        while (relationshipIdx >= 0)
        {
            this.getRelationships(isParent)
                .splice(relationshipIdx, 1);

            relationshipIdx =
                this.getRelationships(isParent)
                    .findIndex(
                        checkRelationship =>
                            checkRelationship.uuid === relationship.uuid);
        }

        if (!relationship.isNew() && // && !isSilently
            addToDeletedRelationships)
        {
            if (isParent)
            {
                this.deletedParentRelationships.push(relationship);
            }
            else
            {
                this.deletedChildRelationships.push(relationship);
            }
        }

        this.entityType.bespoke.onRelate(
            this,
            relationship.definition,
            isParent,
            undefined);
    }

    logDeletionIfNecessary(
        relationship: EntityRelationship,
        isParent: boolean
    )
    {
        if (this.entityType.code === 'ProductLine'
            && relationship.definition.code === 'Pack:Entities'
            && isParent)
        {
            console.warn(
                '[PI202200838] removing pack from product line',
                this,
                relationship
            );
        }
    }

    @action deleteEntity(isSilently: boolean = false)
    {
        if (!this.isDeleted)
        {
            this.isDeleted = true;

            [true, false].forEach(isParent => this.getRelationships(isParent)
                .slice()
                // Make a copy, because we are iterating over an array that we will delete elements from
                // Otherwise, we will skip some relationships
                .forEach(relationship => this.deleteRelationship(relationship, isParent, !isSilently, isSilently, !isSilently)));
        }
    }

    @action getOrCreateValueByField(field: EntityField,
                                    entityTypeStore: EntityTypeStore,
                                    values: IObservableArray<EntityValue> = this.values as IObservableArray<EntityValue>): EntityValue
    {
        return this.getValueByField(field, true);
    }

    /**
     * Updates a relationship.
     *
     * @param isParent whether we want to update a parent or a child relationship
     * @param relationshipDefinition the definition of the relationship we want to update
     * @param newEntity the new related entity (may be undefined if we want to delete a relationship)
     * @param isSilently whether we add the updated relationship to the related side of the relationship
     * @param fromEntity in case of a one-to-many, the previously related entity (used for pinpointing the relationship to update)
     * @param commitContext
     */
    @action
    updateRelationship(
        isParent: boolean,
        relationshipDefinition: EntityRelationshipDefinition,
        newEntity?: Entity,
        isSilently?: boolean,
        fromEntity?: Entity,
        commitContext: CommitContext = this.getCommitContext(),
        relationship?: EntityRelationship,
        options?: CommitMutationOptions
    ): EntityRelationship
    {
        if (commitContext)
        {
            return this.updateRelationshipInCommitContext(
                isParent,
                relationshipDefinition,
                commitContext,
                newEntity,
                fromEntity,
                relationship,
                options
            );
        }
        else
        {
            return this.updateRelationshipInTransactionalModel(
                isParent,
                relationshipDefinition,
                newEntity,
                isSilently,
                fromEntity
            );
        }
    }

    private updateRelationshipInCommitContext(
        isParent: boolean,
        relationshipDefinition: EntityRelationshipDefinition,
        commitContext: CommitContext,
        newEntity?: Entity,
        fromEntity?: Entity,
        relationshipToUpdate?: EntityRelationship,
        options?: CommitMutationOptions
    ): EntityRelationship
    {
        const relationship =
            relationshipToUpdate ??
            this.findRelationshipToUpdate(
                isParent,
                relationshipDefinition,
                commitContext,
                fromEntity
            );

        if (relationship)
        {
            if (newEntity)
            {
                return commitContext.updateRelationship(
                    relationship,
                    isParent,
                    getModel(newEntity),
                    options
                );
            }
            else
            {
                return commitContext.deleteRelationship(
                    relationship,
                    options
                );
            }
        }
        else
        {
            if (newEntity)
            {
                return commitContext.createRelationship(
                    this,
                    relationshipDefinition,
                    isParent,
                    getModel(newEntity),
                    options
                );
            }
            else
            {
                return undefined;
            }
        }
    }

    private findRelationshipToUpdate(
        isParent: boolean,
        relationshipDefinition: EntityRelationshipDefinition,
        commitContext: CommitContext,
        fromEntity?: Entity
    ): EntityRelationship | undefined
    {
        if (relationshipDefinition.isPlural(isParent))
        {
            if (fromEntity)
            {
                return this.getRelationshipsByDefinition(
                    isParent,
                    relationshipDefinition,
                    commitContext
                ).find(
                    relationship =>
                        equalsEntity(
                            relationship.getEntity(isParent),
                            fromEntity
                        )
                );
            }
            else
            {
                return undefined;
            }
        }
        else
        {
            return this.getRelationshipByDefinition(
                isParent,
                relationshipDefinition,
                commitContext
            );
        }
    }

    private updateRelationshipInTransactionalModel(
        isParent: boolean,
        relationshipDefinition: EntityRelationshipDefinition,
        newEntity?: Entity,
        isSilently?: boolean,
        fromEntity?: Entity
    ): EntityRelationship
    {
        // When a non-transactional entity updates the relationship to a transactional one, then do not add the
        // non-transactional entity to the transactional one
        // This happens in onConstruct
        if (!isSilently
            && newEntity
            && !isTransactionalModel(this)
            && isTransactionalModel(newEntity))
        {
            isSilently = true;
        }

        const relationshipInput =
            new RelationshipInput(
                relationshipDefinition.getEntityType(!isParent),
                relationshipDefinition,
                isParent);

        // If we create a relationship from a transactional entity to a non-transactional entity,
        // then the relationship that is created should not be added to the non-transactional entity,
        // but a transactional version of it.
        // Otherwise a memory leak is created
        if (isTransactionalModel(this)
            && newEntity
            && !isTransactionalModel(newEntity)
            && !isSilently)
        {
            // Ensure that no memory leak is created
            return this.updateRelationship(
                isParent,
                relationshipDefinition,
                createTransactionalModel(newEntity));
        }
        else
        {
            const relationships = this.getRelationshipsByDefinition(isParent, relationshipDefinition);

            let isDeletedRelationship: boolean = false;
            let relationship =
                relationshipDefinition.isPlural(isParent)
                    ?
                        fromEntity
                            ?
                                relationships.find(
                                    relationship =>
                                        equalsEntity(
                                            relationship.getEntity(isParent),
                                            fromEntity))
                            :
                                undefined
                    :
                        relationships.length > 0
                            ?
                                relationships[0]
                            :
                                undefined;

            if (!relationship)
            {
                const deletedRelationships =
                    this.getDeletedRelationshipsByDefinition(
                        isParent,
                        relationshipDefinition);

                relationship =
                    relationshipDefinition.isPlural(isParent)
                        ?
                            fromEntity
                                ?
                                    deletedRelationships.find(
                                        relationship =>
                                            equalsEntity(
                                                relationship.getEntity(isParent),
                                                fromEntity))
                                :
                                    newEntity
                                        ?
                                            deletedRelationships.find(
                                                relationship =>
                                                    equalsEntity(
                                                        relationship.getEntity(isParent),
                                                        newEntity))
                                        :
                                            undefined
                        :
                            deletedRelationships.length > 0
                                ?
                                    deletedRelationships[0]
                                :
                                    undefined;

                if (relationship)
                {
                    isDeletedRelationship = true;
                }
            }

            if (newEntity)
            {
                // Recover deleted relationship
                if (relationship && isDeletedRelationship)
                {
                    this.getDeletedRelationships(isParent).remove(relationship);
                    this.getRelationships(isParent).push(relationship);
                    // relationship.id = undefined;
                    relationship.isDeleted = false;
                }

                // If the new entity is the same as the currently related entity,
                // then do not update it
                if (relationship
                    && equalsEntity(newEntity, relationship.getEntity(isParent)))
                {
                    this.entityType.bespoke.onRelate(
                        this,
                        relationshipDefinition,
                        isParent,
                        newEntity);

                    return relationship;
                }

                if (relationshipDefinition.isPlural(isParent))
                {
                    if (relationship)
                    {
                        relationship.getEntity(isParent)
                            .deleteRelationship(
                                relationship,
                                !isParent,
                                false,
                                true,
                                false,
                                false);

                        relationship.setEntity(isParent, newEntity);

                        this.entityType.bespoke.onRelate(
                            this,
                            relationshipDefinition,
                            isParent,
                            newEntity);
                    }
                    else
                    {
                        relationship =
                            this.createRelationship(
                                this,
                                relationshipDefinition,
                                isParent,
                                newEntity);
                    }

                    this.addRelationship(relationship, isParent, isSilently);
                    this.updateName(relationshipInput);

                    return relationship;
                }
                else
                {
                    if (relationship)
                    {
                        relationship.getEntity(isParent)
                            .deleteRelationship(
                                relationship,
                                !isParent,
                                false,
                                true,
                                false,
                                false);

                        relationship.setEntity(isParent, newEntity);

                        this.entityType.bespoke.onRelate(
                            this,
                            relationshipDefinition,
                            isParent,
                            newEntity);

                        this.updateName(relationshipInput);

                        return relationship;
                    }
                    else
                    {
                        const relationship =
                            this.createRelationship(
                                this,
                                relationshipDefinition,
                                isParent,
                                newEntity);

                        this.addRelationship(relationship, isParent, isSilently);
                        this.updateName(relationshipInput);
                    }
                }
            }
            else
            {
                if (relationship)
                {
                    this.deleteRelationship(relationship, isParent, false, isSilently);
                }
                else
                {
                    if (relationshipDefinition.isSingular(isParent))
                    {
                        relationships.forEach(relationship => this.deleteRelationship(relationship, isParent, false, isSilently));
                    }
                }

                this.updateName(relationshipInput);

                return undefined;
            }
        }
    }

    createRelationship(entity: Entity,
                       relationshipDefinition: EntityRelationshipDefinition,
                       isParent: boolean,
                       relatedEntity: Entity): EntityRelationship
    {
        // const t0 = Date.now();
        let relationship = new EntityRelationship();
        relationship.uuid = uuid();
        relationship.definition = relationshipDefinition;

        // relationship.setEntity(isParent, getModel(relatedEntity));
        // relationship.setEntity(!isParent, getModel(this));

        if (isTransactionalModel(this) && isTransactionalModel(relatedEntity))
        {
            relationship.setEntity(isParent, getModel(relatedEntity));
            relationship.setEntity(!isParent, getModel(this));

            relationship = createTransactionalModel(relationship);
            relationship.setEntity(isParent, relatedEntity);
            relationship.setEntity(!isParent, this);
        }
        else
        {
            relationship.setEntity(isParent, getModel(relatedEntity));
            relationship.setEntity(!isParent, getModel(this));
        }

        this.entityTypeStore.entityCacheService.insertRelationship(getModel(relationship));

        return relationship;
    }

    @action setDeleted(isDeleted: boolean)
    {
        this.isDeleted = true;
    }

    @action handleEvent(event: EntityEvent, doMutate: boolean)
    {
        if (this.handledEventIds.has(event.id))
        {
            return;
        }

        this.handledEventIds.add(event.id);

        if (doMutate)
        {
            if (event instanceof EntityValueMutation)
            {
                this.setValueByField(event.entityField, event.newValue && event.newValue.value);
                this.registerMutationForField(event.entityField, new EntityInputMutation(event.user, new Date(event.date)));
            }
            else if (event instanceof EntityRelationshipMutation)
            {
                // In case of relationship events, we only add or remove the relationship from the parent side,
                // because relationship events may have dual events which have the inverse effect.
                // Thus, we only handle them from one side (logically this is the parent)
                if (!event.isParentRelationship)
                {
                    let relationship: EntityRelationship;

                    if (event instanceof EntityRelationshipCreationMutation)
                    {
                        relationship =
                            new EntityRelationship(
                                event.entityRelationship.id,
                                event.isParentRelationship
                                    ?
                                        event.toRelatedEntity
                                    :
                                        this,
                                event.isParentRelationship
                                    ?
                                        this
                                    :
                                        event.toRelatedEntity,
                                event.entityRelationshipDefinition)
                                .initialize(this.entityTypeStore);

                        this.addRelationship(relationship, event.isParentRelationship);
                    }
                    else if (event instanceof EntityRelationshipUpdateMutation)
                    {
                        relationship = this.updateRelationship(event.isParentRelationship, event.entityRelationshipDefinition, event.toRelatedEntity, undefined, event.fromRelatedEntity);
                    }
                    else if (event instanceof EntityRelationshipDeletionMutation)
                    {
                        relationship = this.updateRelationship(event.isParentRelationship, event.entityRelationshipDefinition, undefined, undefined, event.fromRelatedEntity);
                    }

                    // const relationship =
                    //     this.updateRelationship(
                    //         event.isParentRelationship,
                    //         event.entityRelationshipDefinition,
                    //         event.toRelatedEntity,
                    //         undefined,
                    //         event.fromRelatedEntity);

                    if (relationship && event.entityRelationship)
                    {
                        relationship.id = event.entityRelationship.id;
                        relationship.uuid = event.entityRelationship.uuid;
                    }

                    this.registerMutationForRelationshipDefinition(event.entityRelationshipDefinition, new EntityInputMutation(event.user, new Date(event.date)));
                }
            }
            else if (event instanceof EntityDeletionMutation)
            {
                this.deleteEntity();
            }
        }
    }

    @action registerMutationForField(field: EntityField, mutation: EntityInputMutation)
    {
        if (!this.lastMutationByField)
        {
            this.lastMutationByField = new Map();
            this.lastMutationTimeoutByField = new Map();
        }

        this.lastMutationByField.set(field, mutation);

        if (this.lastMutationTimeoutByField.has(field))
        {
            clearTimeout(this.lastMutationTimeoutByField.get(field));
        }

        this.lastMutationTimeoutByField.set(field, setTimeout(() => this.clearMutationForField(field), mutationDuration));
    }

    @action clearMutationForField(field: EntityField)
    {
        if (this.lastMutationByField)
        {
            this.lastMutationByField.delete(field);
        }
    }

    @action registerMutationForRelationshipDefinition(relationshipDefinition: EntityRelationshipDefinition, mutation: EntityInputMutation)
    {
        if (!this.lastMutationByRelationshipDefinition)
        {
            this.lastMutationByRelationshipDefinition = new Map();
            this.lastMutationTimeoutByRelationshipDefinition = new Map();
        }

        this.lastMutationByRelationshipDefinition.set(relationshipDefinition, mutation);

        if (this.lastMutationTimeoutByRelationshipDefinition.has(relationshipDefinition))
        {
            clearTimeout(this.lastMutationTimeoutByRelationshipDefinition.get(relationshipDefinition));
        }

        this.lastMutationTimeoutByRelationshipDefinition.set(relationshipDefinition, setTimeout(() => this.clearMutationForRelationshipDefinition(relationshipDefinition), mutationDuration));
    }

    @action clearMutationForRelationshipDefinition(relationshipDefinition: EntityRelationshipDefinition)
    {
        if (this.lastMutationByRelationshipDefinition)
        {
            this.lastMutationByRelationshipDefinition.delete(relationshipDefinition);
        }
    }

    discover(entityByUuid: Map<string, Entity>, relationshipByUuid: Map<string, EntityRelationship>, onlyDiscoverModels: boolean = false, stoppingCriterion: (entity: Entity) => boolean = () => false)
    {
        if (onlyDiscoverModels && isTransactionalModel(this))
        {
            getModel(this)
                .discover(entityByUuid, relationshipByUuid, onlyDiscoverModels, stoppingCriterion);

            return;
        }

        if (entityByUuid.has(this.uuid))
        {
            return;
        }

        if (stoppingCriterion(this))
        {
            return;
        }

        entityByUuid.set(this.uuid, this);

        // if (!pathByEntity.has(this))
        // {
        //     pathByEntity.set(
        //         this,
        //         path);
        // }

        [true, false].forEach(isParent => this.getRelationships(isParent)
            .forEach(relationship =>
            {
                if (onlyDiscoverModels && isTransactionalModel(relationship))
                {
                    relationship = getModel(relationship);
                }

                // TODO [DD]: API returning empty relationships
                // Sometimes, the API returns a malformed relationship with no values
                // For now, skip these
                if (relationship.uuid && relationship.parentEntity && relationship.childEntity && relationship.definition)
                {
                    if (!relationshipByUuid.has(relationship.uuid))
                    {
                        relationshipByUuid.set(relationship.uuid, relationship);

                        // if (!isTransactionalModel(this) && (isTransactionalModel(relationship) || isTransactionalModel(relationship.getEntity(isParent))))
                        // {
                        //     console.groupCollapsed('joining to transactional model from model during discover', relationship, getModel(relationship), relationship.getEntity(isParent), getModel(relationship.getEntity(isParent)));
                        //     console.trace('here');
                        //     console.groupEnd();
                        // }

                        relationship
                            .getEntity(isParent)
                            .discover(entityByUuid, relationshipByUuid, onlyDiscoverModels, stoppingCriterion);
                    }
                }
            }));
    }

    @action setName(name: string,
                    commitContext?: CommitContext)
    {
        this.entityType.bespoke.setName(
            this,
            name,
            commitContext
        );
    }

    @action setManaged(isManaged: boolean)
    {
        if (isTransactionalModel(this))
        {
            (getModel(this) as any).isManaged = isManaged;
        }
        else
        {
            this.isManaged = isManaged;
        }
    }

    @action setFocused(isFocused: boolean)
    {
        this.isFocused = isFocused;
    }

    @action setEntityType(entityType: EntityType,
                          entityTypeStore: EntityTypeStore,
                          commitContext?: CommitContext)
    {
        if (this.entityType !== entityType)
        {
            // First set the value, otherwise the commit context does not see a change
            this.setValueByField(
                entityTypeStore.typeField,
                entityType,
                undefined,
                undefined,
                commitContext
            );
            this.entityType = entityType;
        }
    }

    @action setDoAutoCommit(doAutoCommit: boolean)
    {
        this.doAutoCommit = doAutoCommit;
    }

    // ------------------------ Public logic ------------------------

    isNew(): boolean
    {
        return this.id == null || this.id === 0;
    }

    getChannel(): string
    {
        if (this.isNew())
        {
            return null;
        }
        else
        {
            return `room/entity/${this.id}`;
        }
    }

    hasValueForField(field: EntityField,
                     commitContext: CommitContext = this.getCommitContext())
    {
        if (commitContext?.hasValue(this, field))
        {
            return !commitContext.getValue(this, field).isEmpty;
        }
        if (this.valueByField.has(field))
        {
            return !this.valueByField.get(field).isEmpty;
        }
        else if (field instanceof StaticEntityField)
        {
            return this.getValueForStaticField(field) !== undefined;
        }
        else
        {
            return false;
        }
    }

    getObjectValueByField<T = any>(field: EntityField,
                                   commitContext?: CommitContext): T | undefined
    {
        if (field instanceof StaticEntityField)
        {
            return this.getValueForStaticField(
                field,
                commitContext
            );
        }
        else
        {
            return this.getDataObjectValueByField(
                field,
                true,
                commitContext
            )?.value;
        }
    }

    getDataObjectValueByField(field: EntityField,
                              doNotCreateNewValueIfNonExistent: boolean = false,
                              commitContext: CommitContext = this.getCommitContext(),
                              ignoreCommitContext: boolean = false)
    {
        return (ignoreCommitContext ? false : commitContext?.getValue(this, field))
            || this.getValueByField(field, false, doNotCreateNewValueIfNonExistent)?.dataObject;
    }

    getValueByField(field: EntityField | undefined,
                    doAddToValuesIfNonExistent: boolean = false,
                    doNotCreateNewValueIfNonExistent: boolean = false): EntityValue
    {
        if (field)
        {
            const value = this.valueByField.get(field);

            if (value)
            {
                return value;
            }
            else
            {
                if (doNotCreateNewValueIfNonExistent)
                {
                    return undefined;
                }
                else
                {
                    return this.getNewValueByField(field, doAddToValuesIfNonExistent);
                }
            }
        }
        else
        {
            return undefined;
        }
    }

    getNewValueByField(field: EntityField, doAddToValues: boolean = false): EntityValue
    {
        let dataObjectValue: any;

        if (field instanceof StaticEntityField)
        {
            dataObjectValue = this.getValueForStaticField(field);
        }

        if (!field.dataObjectSpecification)
        {
            console.error('field has no data object specification', field);
        }

        let value: EntityValue =
            new EntityValue(
                undefined,
                this,
                field,
                DataObject.constructFromValue(
                    field.dataObjectSpecification,
                    dataObjectValue,
                    {
                        entityContext: this.entityContext,
                        getFileUrl:
                            () =>
                                loadModuleDirectly(EntityTypeStore)
                                    .getFileUrl(value),
                    }));

        // In case of a transactional, return the transactional value
        if (isTransactionalModel(this))
        {
            value = createTransactionalModel(value);
        }

        if (doAddToValues)
        {
            this.values.push(value);
        }

        return value;
    }

    getValueForStaticField(field: StaticEntityField,
                           commitContext?: CommitContext)
    {
        const valueInCommitContext = commitContext?.getValue(this, field);

        if (valueInCommitContext)
        {
            return valueInCommitContext.value;
        }
        else
        {
            return this.getValueForStaticFieldFromModel(field);
        }
    }

    private getValueForStaticFieldFromModel(field: StaticEntityField)
    {
        const path = field.objectPath.split('.');
        let value = this.getObjectByPath(this, path);

        // Dates in static fields are of type number
        if (field.dataObjectSpecification.type instanceof DateType && typeof value === 'number')
        {
            value = new Date(value);
        }

        return value;
    }

    @action setValueForStaticField(field: StaticEntityField,
                                   value?: any)
    {
        const path = field.objectPath.split('.');
        const pathToProperty = path.slice(0, -1);
        const property = path.slice(-1).find(() => true);
        let base = this.getObjectByPath(this, pathToProperty);

        // Dates in static fields are of type number
        if (field.dataObjectSpecification.type instanceof DateType && value instanceof Date)
        {
            value = value.getTime();
        }

        if (base && property)
        {
            base[property] = value;
        }

        return value;
    }

    @action
    setValueByField(
        field: EntityField,
        value: any,
        doPerformLocalAutomations: boolean = true,
        ignoreCommitContext: boolean = false,
        commitContext: CommitContext = this.getCommitContext(),
        options?: CommitMutationOptions
    )
    {
        if (!field)
        {
            return;
        }

        if (commitContext && !ignoreCommitContext)
        {
            commitContext.setValue(
                this,
                field,
                DataObject.constructFromValue(
                    field.dataObjectSpecification,
                    value
                ),
                {
                    ...options,
                    disableLocalAutomations: options?.disableLocalAutomations || !doPerformLocalAutomations,
                }
            );
        }
        else
        {
            const entityValue = this.getValueByField(field, true);

            if (entityValue)
            {
                entityValue.setValue(value);
            }

            // Update the static field (if necessary)
            if (field.isStaticField())
            {
                this.setValueForStaticField(field as StaticEntityField, value);
            }

            this.updateName(
                new FieldInput(
                    field.entityType,
                    field));

            if (doPerformLocalAutomations)
            {
                this.entityType.bespoke.onValueSet(
                    this,
                    field,
                    value,
                    undefined
                );
            }

            return entityValue;
        }
    }

    updateName(input: Input)
    {
        // If an entity is updated via an event, the cached name/description fields should also be updated
        // Value should be of type text (some entities may have a file value associated as name, for instance)
        const nameInput = getNameFieldByType(this.entityType);

        if (nameInput && nameInput.id() === input.id())
        {
            this._name = input.getValue(this).toString();
        }

        if (this.entityType.getInheritedDescriptionField() === (input as FieldInput).field)
        {
            this.description = input.getValue(this).toString();
        }

        if (this.entityType.getInheritedCompactNameField() === (input as FieldInput).field)
        {
            this.compactName = input.getValue(this).toString();
        }

        if (this.entityType.getInheritedSortNameField() === (input as FieldInput).field)
        {
            this.sortName = input.getValue(this).toString();
        }
    }

    getObjectByPath(value: any, path: string[]): any
    {
        if (path.length === 0)
        {
            return value;
        }
        else
        {
            if (value)
            {
                return this.getObjectByPath(value[path[0]], path.slice(1));
            }
            else
            {
                return null;
            }
        }
    }

    getRelationships(isParent: boolean,
                     commitContext?: CommitContext): EntityRelationship[]
    {
        const relationships =
            isParent
                ? (this.parentRelationships ?? [])
                : (this.childRelationships ?? []);

        if (commitContext)
        {
            return [
                ...relationships
                    .map(
                        relationship =>
                            commitContext.getRelationship(relationship.uuid) ?? relationship
                    )
                    .filter(
                        relationship =>
                            !commitContext.isRelationshipDeleted(relationship)
                    ),
                ...commitContext.getCreatedOrUpdatedRelationshipsByEntity(
                    this,
                    isParent
                ).filter(
                    relationship =>
                        relationship.isNew()
                )
            ];
        }
        else
        {
            return relationships;
        }
    }

    getRelationshipsByDefinitionMap(isParent: boolean): Map<EntityRelationshipDefinition, EntityRelationship[]>
    {
        if (isParent)
        {
            return this.parentRelationshipsByDefinition;
        }
        else
        {
            return this.childRelationshipsByDefinition;
        }
    }

    getDeletedRelationshipsByDefinitionMap(isParent: boolean): Map<EntityRelationshipDefinition, EntityRelationship[]>
    {
        if (isParent)
        {
            return this.deletedParentRelationshipsByDefinition;
        }
        else
        {
            return this.deletedChildRelationshipsByDefinition;
        }
    }

    getRelationshipsByDefinition(isParent: boolean,
                                 relationshipDefinition: EntityRelationshipDefinition | undefined,
                                 commitContext: CommitContext = this.getCommitContext()): EntityRelationship[]
    {
        if (relationshipDefinition)
        {
            const relationships = (this.getRelationshipsByDefinitionMap(isParent).get(relationshipDefinition) || []);

            if (commitContext)
            {
                return [
                    ...relationships,
                    ...commitContext.getNewRelationships(this, relationshipDefinition, isParent)
                ]
                    .map(
                        relationship =>
                            commitContext.getRelationship(relationship.uuid) ?? relationship
                    )
                    .filter(
                        relationship =>
                            !commitContext.isRelationshipDeleted(relationship)
                    );
            }
            else
            {
                return relationships;
            }
        }
        else
        {
            return [];
        }
    }

    getRelationshipByDefinition(isParent: boolean,
                                relationshipDefinition: EntityRelationshipDefinition,
                                commitContext?: CommitContext): EntityRelationship
    {
        return this.getRelationshipsByDefinition(isParent, relationshipDefinition, commitContext)
            .find(() => true);
    }

    hasRelationshipsByDefinition(isParent: boolean,
                                 relationshipDefinition: EntityRelationshipDefinition,
                                 commitContext?: CommitContext): boolean
    {
        return this.getRelationshipsByDefinition(isParent, relationshipDefinition, commitContext).length > 0;
    }

    getRelatedEntityByDefinition(isParent: boolean,
                                 relationshipDefinition: EntityRelationshipDefinition,
                                 commitContext?: CommitContext): Entity
    {
        const relationship =
            this.getRelationshipByDefinition(
                isParent,
                relationshipDefinition,
                commitContext
            );

        if (relationship)
        {
            return relationship.getEntity(isParent);
        }
        else
        {
            return undefined;
        }
    }

    getRelatedEntitiesByDefinition(isParent: boolean,
                                   relationshipDefinition: EntityRelationshipDefinition,
                                   commitContext?: CommitContext): Entity[]
    {
        return this.getRelationshipsByDefinition(isParent, relationshipDefinition, commitContext)
            .map(relationship => relationship.getEntity(isParent));
    }

    getDeletedRelationships(isParent: boolean): IObservableArray<EntityRelationship>
    {
        if (isParent)
        {
            return this.deletedParentRelationships;
        }
        else
        {
            return this.deletedChildRelationships;
        }
    }

    getDeletedRelationshipsByDefinition(isParent: boolean, relationshipDefinition: EntityRelationshipDefinition): EntityRelationship[]
    {
        return (this.getDeletedRelationshipsByDefinitionMap(isParent)
                .get(relationshipDefinition) || []);
    }

    getAvatarUrl(): string
    {
        const avatarField = this.entityType.getInheritedAvatarField();

        if (avatarField)
        {
            const fileValue = this.getObjectValueByField<FileValue>(avatarField);

            // If avatar is computed, then compute it on-demand
            if (avatarField.isComputedField
                && !fileValue)
            {
                const dataObject = avatarField.initializedComputation.compute({
                    entityContext: this.entityContext,
                });

                if (dataObject && !dataObject.isEmpty)
                {
                    return dataObject.context.getFileUrl(dataObject.value.url);
                }
                else
                {
                    return undefined;
                }
            }
            else
            {
                // Otherwise, just return the saved avatar
                if (fileValue
                    && (fileValue.url || fileValue.file)
                    && fileValue.isImage)
                {
                    const avatarValue = this.getDataObjectValueByField(avatarField);

                    return avatarValue.context.getFileUrl(fileValue.url);
                }
                else
                {
                    return undefined;
                }
            }
        }
    }

    allowAutoCommit(): boolean
    {
        if (this.doAutoCommit === false)
        {
            return false;
        }
        else if (isTransactionalModel(this))
        {
            const context = getAdministration(this).context;

            return context.valuePath.every(value => !(value instanceof Entity) || (value instanceof Entity && context.getSeenTransactional(value).doAutoCommit !== false));
        }
        else
        {
            return true;
        }
    }

    @action cancelCommit()
    {
        if (this.commitTimeout)
        {
            clearTimeout(this.commitTimeout);
            this.commitTimeout = undefined;
        }

        this.isCommitRequestedDuringCommit = false;
        this.setCommitPromise(undefined);
    }

    @action setCommitPromise(commitPromise: Promise<any>)
    {
        if (!isTransactionalModel(this))
        {
            throw new Error('Cannot set commit promise on non-transactional entity');
        }

        if (commitPromise === undefined)
        {
            this.commitPromise = undefined;
        }
        else
        {
            this.commitPromise = commitPromise
                .then(() => this.setCommitPromise(undefined))
                .catch(() => this.setCommitPromise(undefined));
        }
    }

    @action checkAndDoCommit(isDebounced: boolean = false,
                             doForceCommit: boolean = false,
                             doDefer: boolean = false): Promise<any>
    {
        return commitEntity(
            this,
            {
                isDeferred: doDefer,
                isDebounced: isDebounced,
                isForced: doForceCommit
            });
    }

    doCheckTransactionalRelationship(isParent: boolean, relationship: EntityRelationship)
    {
        if (isTransactionalModel(this))
        {
            return isTransactionalModel(relationship.getEntity(isParent));
        }
        else
        {
            return true;
        }
    }

    getEditableFields(): EntityField[]
    {
        return [
            ...this.entityType.getInheritedFields()
                .filter(
                    field =>
                        !field.computation && !field.isReadonly),
            ...this.entityTypeStore.staticType.getInheritedFields()
                .filter(
                    field =>
                        !field.computation
                        && !field.isReadonly
                        && field !== this.entityTypeStore.bespoke.types.Entity.Field.Type),
            ...this.entityType.isSwitchableByInheritance()
            || !this.entityType.isInstantiableByInheritance()
                ?
                    [ this.entityTypeStore.bespoke.types.Entity.Field.Type ]
                :
                    []
        ];
    }

    getCommitContext(): CommitContext | undefined
    {
        if (isTransactionalModel(this))
        {
            return undefined;
        }
        else
        {
            return this.commitContext;
        }
    }

    toString(): string
    {
        return `Entity(${this.uuid})`;
    }

    // ----------------------- Private logic ------------------------
}

registerBuilder(Entity)
    .id(entity => entity.uuid)
    .includeAll()
    .props('isDeleted', 'doAutoCommit', 'commitMutex', 'commitPromise', 'commitPromiseWrapper', 'commitContext')
    .deep('parentRelationships')
    .deep('childRelationships')
    .deep('deletedParentRelationships')
    .deep('deletedChildRelationships')
    .deep('values')
    .deep('valueByField');

registerType(EntityTypeParameter, 'EntityTypeParameter');
registerType(EntityTypeParameterValue, 'EntityTypeParameterValue');

// Mutations
registerType(EntityCreationMutation, 'EntityCreationMutation');
registerType(EntityDeletionMutation, 'EntityDeletionMutation');

registerType(EntityRelationshipMutation, 'EntityRelationshipMutation');
registerType(EntityRelationshipCreationMutation, 'EntityRelationshipCreationMutation');
registerType(EntityRelationshipUpdateMutation, 'EntityRelationshipUpdateMutation');
registerType(EntityRelationshipDeletionMutation, 'EntityRelationshipDeletionMutation');

registerType(EntityValueMutation, 'EntityValueMutation');
registerType(EntityValueCreationMutation, 'EntityValueCreationMutation');
registerType(EntityValueUpdateMutation, 'EntityValueUpdateMutation');
registerType(EntityValueDeletionMutation, 'EntityValueDeletionMutation');

// Metadata mutations
registerType(EntityTypeCreationMutation, 'EntityTypeCreationMutation');
registerType(EntityTypeUpdateMutation, 'EntityTypeUpdateMutation');
registerType(EntityTypeDeletionMutation, 'EntityTypeDeletionMutation');

registerType(EntityFieldCreationMutation, 'EntityFieldCreationMutation');
registerType(EntityFieldUpdateMutation, 'EntityFieldUpdateMutation');
registerType(EntityFieldDeletionMutation, 'EntityFieldDeletionMutation');

registerType(EntityRelationshipDefinitionCreationMutation, 'EntityRelationshipDefinitionCreationMutation');
registerType(EntityRelationshipDefinitionUpdateMutation, 'EntityRelationshipDefinitionUpdateMutation');
registerType(EntityRelationshipDefinitionDeletionMutation, 'EntityRelationshipDefinitionDeletionMutation');

