import React from 'react';
import { observer } from 'mobx-react-lite';
import ValueType from '../../../../../../../../@Api/Automation/Value/Type/ValueType';
import Value from '../../../../../../../../@Api/Automation/Value/Value';
import EntityValueType from '../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import PrimitiveValueType from '../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import CollectionType from '../../../../../../../../@Api/Automation/Value/Type/CollectionType';
import PrimitiveEditor from './Type/Primitive/PrimitiveEditor';
import EntityEditor from './Type/Entity/EntityEditor';
import { ComputationEditorProps } from '../Computation/ComputationEditor';
import FreeValueEditor from './FreeValueEditor';
import EmptyValue from '../../../../../../../../@Api/Automation/Value/EmptyValue';
import DataSourceValueType from '../../../../../../../../@Api/Automation/Value/Type/DataSourceValueType';
import DataSourceEditor from './Type/DataSource/DataSourceEditor';
import DeferredValue from '../../../../../../../../@Api/Automation/Value/DeferredValue';
import CollectionEditor from './Type/Collection/CollectionEditor';

export interface ValueEditorProps<T extends ValueType<any> = ValueType<any>, V extends Value<any, T> = Value<any, any>> extends ComputationEditorProps<T, V>
{
    filter?: boolean;
    disallowFreeValue?: boolean;
}

const ValueEditor: React.FC<ValueEditorProps> =
    observer(
        props =>
        {
            const { type, disallowFreeValue, value } = props;

            if (value instanceof DeferredValue)
            {
                if (value.initializedValue)
                {
                    return <ValueEditor
                        {...props}
                        value={value.initializedValue}
                    />;
                }
                else
                {
                    return null;
                }
            }
            else if (value instanceof EmptyValue)
            {
                if (disallowFreeValue)
                {
                    return null;
                }
                else
                {
                    return <FreeValueEditor
                        {...props}
                    />;
                }
            }
            else if (type instanceof CollectionType)
            {
                return <CollectionEditor
                    {...props as any}
                />;
            }
            else if (type instanceof EntityValueType)
            {
                return <EntityEditor
                    {...props}
                />;
            }
            else if (type instanceof PrimitiveValueType)
            {
                return <PrimitiveEditor
                    {...props}
                />;
            }
            else if (type instanceof DataSourceValueType)
            {
                return <DataSourceEditor
                    {...props as any}
                />;
            }
            else
            {
                if (disallowFreeValue)
                {
                    return null;
                }
                else
                {
                    return <FreeValueEditor
                        {...props}
                    />;
                }
            }
        }
    );

export default ValueEditor;
