export default function processImageFile(file)
{
    return new Promise<File>(
        (resolve) =>
        {
            /*loadImage(
                file,
                (img: any) =>
                {
                    if (img.type === 'error')
                    {
                        resolve(file);
                    }
                    else
                    {
                        console.log('converted image (2)', img, file);

                        img.toBlob(
                            blob =>
                                resolve(
                                    convertBlobToFile(
                                        blob,
                                        file.name)),
                            'image/jpeg');
                    }
                },
                {
                    orientation: true
                });*/

            // Browsers have implemented native image rotation based on exif data,
            // this means the blueimp function above is no longer necessary (for now)
            resolve(file);

        }).catch(
        () =>
            Promise.resolve(file));
}

export function convertBlobToFile(blob: Blob,
                                  fileName: string)
{
    // File constructor is not supported in IE11/EdgeHTML
    const aBlob = blob as any;

    aBlob.lastModifiedDate = new Date();
    aBlob.name = fileName;

    return aBlob as File;
}
