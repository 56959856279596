import React from 'react';
import { observer } from 'mobx-react-lite';
import PrimitiveValue from '../../../../../../../../../../@Api/Automation/Value/PrimitiveValue';
import { ValueViewerProps } from '../../ValueViewer';
import Viewer from '../../../../../../../../DataObject/Viewer/Viewer';

export interface PrimitiveViewerProps extends ValueViewerProps<PrimitiveValue>
{

}

const PrimitiveViewer: React.FC<PrimitiveViewerProps> =
    props =>
    {
        return <Viewer
            dataObject={props.value.value}
        />;
    };

export default observer(PrimitiveViewer);
