import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography } from '@material-ui/core';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import View from '../../Model/View';
import Chip from '@material-ui/core/Chip';
import { IObservableArray, runInAction } from 'mobx';
import { ColorUtils } from '../../../../../../@Util/Color/ColorUtils';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface ListGroupsEditorProps
{
    view: View;
}

export const ListGroupsEditor: React.FC<ListGroupsEditorProps> =
    observer(
        ({
            view,
         }) =>
        {
            return <ViewGroup
                orientation="horizontal"
                spacing={10}
                alignment="center"
                wrap
            >
                <ViewGroupItem>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                        <LocalizedText
                            code="Configuration.View.GroupedBy"
                            value="Gegroepeerd op"
                        />
                    </Typography>
                </ViewGroupItem>
                {
                    view.specification.list!.groupings.map(
                        (grouping, idx) =>
                            <ViewGroupItem
                                key={grouping.column.id}
                            >
                                <Chip
                                    size="small"
                                    label={grouping.column.fieldPath.getName()}
                                    onDelete={
                                        () =>
                                            runInAction(
                                                () =>
                                                    (view.specification.list!.groupings as IObservableArray).remove(grouping)
                                            )
                                    }
                                    style={{
                                        backgroundColor: ColorUtils.color(idx)[500],
                                        color: 'white',
                                    }}
                                />
                            </ViewGroupItem>
                    )
                }
            </ViewGroup>;
        }
    );
