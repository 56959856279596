import { observable } from 'mobx';
import SourceInstance from './SourceInstance';
import { reference, registerType, type } from '../../../../../@Util/Serialization/Serialization';
import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';

@type('ImportResult')
export default class ImportResult
{
    // ------------------------- Properties -------------------------

    @reference(undefined, 'SourceInstance') @observable.ref sourceInstance: SourceInstance;
    @reference(undefined, 'Entity', true) @observable.shallow targetInstanceByMappingId: Map<string, Entity>;
    @reference(undefined, 'EntityEvent') @observable.shallow targetEvents: EntityEvent[];
    @observable isErroneous: boolean;
    @observable.ref errorMessage?: string;
    @observable.ref errorDetail?: string;

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    initialize()
    {
        Array.from(this.targetInstanceByMappingId.keys())
            .forEach(
                typeMappingId =>
                {
                    const entity = this.targetInstanceByMappingId.get(typeMappingId);
                    entity.initialize(
                        loadModuleDirectly(EntityTypeStore),
                        undefined,
                        undefined,
                        undefined,
                        false,
                        undefined,
                        undefined,
                        false);
                });
    }

    // ----------------------- Private logic ------------------------
}

registerType(SourceInstance, 'SourceInstance');
