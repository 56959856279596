import React from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import TypeManager from '../../TypeManager';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import { Box } from '@material-ui/core';
import { backgroundColor } from '../../../../../../@Resource/Theme/Theme';

export interface ChildTypesEditorProps
{
    entityType: EntityType;
}

export const ChildTypesEditor: React.FC<ChildTypesEditorProps> =
    observer(
        ({
            entityType,
         }) =>
        {
            return <Box
                padding={4}
                style={{
                    backgroundColor: backgroundColor,
                }}
            >
                <Centered
                    horizontal
                >
                    <Box
                        width={800}
                    >
                        <TypeManager
                            entityType={entityType}
                            name={entityType.getName()}
                            addName={entityType.getName().toLowerCase()}
                            disallowConfigOnRoot
                            allowConfig
                        />
                    </Box>
                </Centered>
            </Box>;
        });
