import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import localizeText from '../../../Localization/localizeText';
import MapType from '../../Value/Type/MapType';
import MapValue from '../../Value/MapValue';
import EmptyValue from '../../Value/EmptyValue';

export default class MapWithValueComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref map: Computation<any, any>;
    @observable.ref key: Computation<any, any>;
    @observable.ref value: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(map: Computation<any, any>,
                key: Computation<any, any>,
                value: Computation<any, any>)
    {
        super();

        this.map = map;
        this.key = key;
        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return this.map.getType();
    }

    isAsync(): boolean
    {
        return this.map.isAsync()
            || this.key.isAsync()
            || this.value.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const [ map, key, value ] =
            await Promise.all([
                safelyApplyFunction(this.map, context),
                safelyApplyFunction(this.key, context),
                safelyApplyFunction(this.value, context)
            ]);

        return this.applyOnMap(map, key, value);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        const map = safelySynchronousApplyFunction(this.map, context);
        const key = safelySynchronousApplyFunction(this.key, context);
        const value = safelySynchronousApplyFunction(this.value, context);

        return this.applyOnMap(map, key, value);
    }

    applyOnMap(map: Value<any, any>,
               key: Value<any, any>,
               value: Value<any, any>)
    {
        if (map instanceof MapValue)
        {
            const currentValue = map.value.get(key.getId())?.value ?? EmptyValue.instance;

            if (currentValue.equals(value))
            {
                return map;
            }
            else
            {
                const internalMapWithValue = new Map(map.value);

                if (value instanceof EmptyValue)
                {
                    internalMapWithValue.delete(key.getId());
                }
                else
                {
                    internalMapWithValue.set(
                        key.getId(),
                        {
                            key,
                            value
                        });
                }

                const mapWithValue =
                    new MapValue(
                        new Map(),
                        map.keyType,
                        map.valueType);

                mapWithValue.value = internalMapWithValue;

                return mapWithValue;
            }
        }
        else
        {
            return undefined;
        }
    }

    getName(): string
    {
        return `${this.map?.getName() || '...'} with (${this.key?.getName() || '...'}, ${this.value?.getName() || '...'})`;
    }

    validate(): Validation[]
    {
        if (!(this.map?.getType() instanceof MapType))
        {
            return [
                new Validation(
                    'Error',
                    localizeText('Computation.ValueFromMap.InvalidMap', 'Er is geen geldige maptype geselecteerd.'))
            ];
        }
        else if (!this.key)
        {
            return [
                new Validation(
                    'Error',
                    localizeText('Computation.ValueFromMap.InvalidKey', 'Er is geen geldige sleutelwaarde geselecteerd.'))
            ];
        }
        else
        {
            return [];
        }
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'MapWithValue';
        descriptor.map = this.map.toDescriptor();
        descriptor.key = this.key.toDescriptor();
        descriptor.value = this.value.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.map.getDependencies(),
            ...this.key.getDependencies(),
            ...this.value.getDependencies()
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const [ map, key, value ] =
            await Promise.all([
                getComputationFromDescriptor(
                    descriptor.map,
                    dependencyContext),
                getComputationFromDescriptor(
                    descriptor.key,
                    dependencyContext),
                getComputationFromDescriptor(
                    descriptor.value,
                    dependencyContext)
            ]);

        const mapType = map.getType();

        if (mapType instanceof MapType)
        {
            return new MapWithValueComputation(
                map,
                key,
                value);
        }
        else
        {
            throw new Error(`Expected map type, but got: ${mapType.id()}`)
        }
    }

    // ----------------------- Private logic ------------------------
}
