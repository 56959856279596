import GutenbergBlockType from '../../Gutenberg/GutenbergBlockType';
import { GutenbergBlockButtonId } from '../../DocumentEditor';
import { InspectorControls } from '@wordpress/block-editor';
import Icon from '../../../../Icon/Icon';
import React, { Fragment } from 'react';
import DimensionsOptionsPanel from '../Settings/DimensionsOptionsPanel';
import LinkOptionsPanel from '../Settings/LinkOptionsPanel';
import StyledBlock from '../StyledBlock/StyledBlock';
import ButtonOptionsPanel from '../Settings/ButtonOptionsPanel';
import ButtonColorOptionsPanel from '../Settings/ButtonColorOptionsPanel';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

const defaultButtonStyles = {
    cursor: 'pointer',
    borderRadius: '4px',
    borderStyle: 'none',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const GutenbergBlockButton: GutenbergBlockType =
    {
        name: GutenbergBlockButtonId,
        configuration:
            {
                title: {
                    code: 'GutenbergBlock.Button',
                    value: 'Knop'
                },
                icon: <Icon icon="pages" />,
                category: 'text',

                attributes:
                    {
                        content:
                            {
                                type: 'string',
                                source: 'attribute',
                                selector: 'button',
                                query: undefined,
                            },
                        width:
                            {
                                type: 'string',
                                default: '100'
                            },
                        height:
                            {
                                type: 'string',
                                default: '40'
                            },
                        color:
                            {
                                type: 'string',
                                default: '#ffffff'
                            },
                        background:
                            {
                                type: 'string',
                                default: '#3497DB'
                            },
                        fontSize:
                            {
                                type: 'string',
                                default: '14'
                            },
                        label:
                            {
                                type: 'string',
                                default: localizeText('GutenbergBlockButton.Label', 'Knoptekst')
                            }
                    },

                supports:
                    {
                        align: true
                    },

                edit: props =>
                {
                    const {
                    } = props.attributes as any;

                    return (
                        <Fragment>
                            <InspectorControls>
                                <DimensionsOptionsPanel props={props} open />
                                <ButtonOptionsPanel props={props} />
                                <ButtonColorOptionsPanel props={props} />
                                <LinkOptionsPanel props={props} />
                            </InspectorControls>
                            <StyledBlock
                                block={props}
                            >
                                <div
                                    style={{
                                        textAlign: props.attributes.align ?? 'center'
                                    }}
                                >
                                    <a
                                        style={{
                                            ...defaultButtonStyles,
                                            width: props.attributes.width + 'px',
                                            height: props.attributes.height + 'px',
                                            color: props.attributes.color,
                                            backgroundColor: props.attributes.background,
                                            fontSize: props.attributes.fontSize + 'px'
                                        }}
                                    >
                                        {
                                            props.attributes.label
                                        }
                                    </a>
                                </div>
                            </StyledBlock>
                        </Fragment>
                    );
                },

                save: (props) =>
                {
                    return <p>
                        {
                            (props.attributes as any).content
                        }
                    </p>;
                }
            },

        view: block =>
        {
            return <StyledBlock
                block={block}
            />;
        }
    };

export default GutenbergBlockButton;
