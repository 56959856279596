import React, { useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import Inset from '../../Inset/Inset';
import LocalizerContext from '../../../../../../../@Service/Localization/LocalizerContext';
import useTypes from '../../../../Type/Api/useTypes';
import styles from './Read.module.scss';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface ReadProps
{
    entity: Entity;
}

const Read: React.FC<ReadProps> =
    props =>
    {
        const types = useTypes();
        const localizer = useContext(LocalizerContext);
        const date =
            useMemo(
                () =>
                    localizer.formatDateTime(props.entity.getObjectValueByField(types.Entity.Field.CreationDate)),
                [
                    localizer,
                    props.entity,
                    types
                ]);

        const reader =
            useComputed(
                () =>
                    props.entity.getRelatedEntityByDefinition(
                        false,
                        types.Event.RelationshipDefinition.User),
                [
                    props.entity,
                    types
                ]);

        return <div
            className={styles.root}
        >
            <Inset>
                {date}&nbsp;
                {
                    reader &&
                        <span>
                            <LocalizedText
                                code="Event.By"
                                value="door ${name}"
                                name={reader.name}
                            />
                        </span>
                }
            </Inset>
        </div>;
    };

export default observer(Read);
