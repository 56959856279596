import { fetchApiFile } from './fetchApiFile';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/index';
import { ApiClient } from '../ApiClient';
import { downloadUrl } from '../../../@Util/Url/downloadUrl';

export async function downloadApiFile(
    url: string,
    data?: { [key: string]: string | undefined },
    name?: string
)
{
    const urlWithData = getUrlWithData(url, data);
    const apiClient = loadModuleDirectly(ApiClient);
    const { url: fetchedUrl, fileName, dispose } =
        await fetchApiFile(
            apiClient,
            urlWithData
        );
    downloadUrl(
        fetchedUrl,
        fileName ?? name
    );
    dispose();
}

function getUrlWithData(
    url: string,
    data?: { [key: string]: string | undefined }
)
{
    if (data === undefined)
    {
        return url;
    }
    else
    {
        const params =
            Object.keys(data)
                .filter(
                    key =>
                        data[key] !== undefined
                )
                .map(
                    key =>
                        `${key}=${encodeURIComponent(data[key])}`
                );

        if (params.length > 0)
        {
            return `${url}?${params.join('&')}`;
        }
        else
        {
            return url;
        }
    }
}