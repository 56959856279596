import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { action, computed, observable, reaction } from 'mobx';
import { ExternalPicture } from '../../../@Api/Model/Implementation/ExternalPicture';
import { ExternalPictureController } from '../../../@Api/Controller/Directory/ExternalPictureController';
import { injectWithQualifier } from '../../../@Util/DependencyInjection/index';
import { FileValue } from '../../Domain/DataObject/Type/File/FileValue';
import { LocalizationStore } from '../../../@Service/Localization/LocalizationStore';
import { InputStore } from '../Input/InputStore';
import { ViewComponent } from '../ViewStack/Model/ViewComponent';
import { DataObjectEditor } from '../../Domain/DataObject/Editor/Value/Editor/DataObjectEditor';
import { DataObjectEditorStore } from '../../Domain/DataObject/Editor/Value/Editor/DataObjectEditorStore';
import { DataObjectStore } from '../../Domain/DataObject/DataObjectStore';
import { SearchBarStore } from '../SearchBar/SearchBarStore';
import { ApiClient } from '../../../@Service/ApiClient/ApiClient';

export class ImageFinderStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityPictureController') entityPictureController: ExternalPictureController;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('ApiClient') apiClient: ApiClient;

    // ------------------------- Properties -------------------------

    @observable query: string;
    @observable delayedQuery: string;
    @observable queryDelay: any;
    @observable entityPictureReferences: ExternalPicture[] = [];
    @observable selectedFile: FileValue | ExternalPicture;
    @observable onImageSelected?: (image: FileValue | ExternalPicture) => void;
    @observable columns: number;
    @observable cellHeight: number;

    // ------------------------ Constructor -------------------------

    constructor(
        query: string,
        onImageSelected?: (image: FileValue | ExternalPicture) => void,
        columns: number = 3,
        cellHeight: number = 150)
    {
        super();

        this.setQuery(query);
        this.onImageSelected = onImageSelected;
        this.columns = columns;
        this.cellHeight = cellHeight;

    }

    // ----------------------- Initialization -----------------------

    entersUI(isMounted: boolean)
    {
        super.entersUI(isMounted);

        this.registerDisposable(
            reaction(
                () => this.delayedQuery,
                () =>
                {
                    if (this.delayedQuery)
                    {
                        this.entityPictureController.searchPictures(this.delayedQuery)
                            .then(entityPictureReferences =>
                            {
                                this.setEntityPictureReferences(entityPictureReferences);
                            });
                    }
                    else
                    {
                        this.setEntityPictureReferences([]);
                    }
                },
                {
                    fireImmediately: true
                }));
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    @computed
    get searchbarStore(): SearchBarStore
    {
        return new SearchBarStore(
            {
                isLightColor:
                    () => true,
                onUpdate:
                    query => this.updateQuery(query),
                initialValue: this.query,
                isAutoFocus: true
            });
    }

    @computed
    get searchInputStore(): InputStore
    {
        return new InputStore({
           labelProps:
               {
                   label: this.localizationStore.translate('GenericComponent.ImageFinder.Search') // Search
               },
            view:
                new ViewComponent(
                    DataObjectEditor,
                    new DataObjectEditorStore({
                        dataObject:
                            this.dataObjectStore.resolveFromTypeAndValue(
                                'Text',
                                this.query),
                        onChange: value => this.updateQuery(value.getValue())
                    }))
        });
    }
    // -------------------------- Actions ---------------------------

    @action.bound
    updateQuery(value: string)
    {
        this.query = value;

        if (this.queryDelay)
        {
            clearTimeout(this.queryDelay);
        }

        this.queryDelay =
            setTimeout(
                () =>
                {
                    this.setQuery(this.query);
                },
                500);
    }

    @action.bound
    setQuery(query: string)
    {
        this.query = query;
        this.delayedQuery = query;
    }

    @action.bound
    setSelectedFile(file: ExternalPicture | FileValue)
    {
        this.selectedFile = file;

        if (this.onImageSelected)
        {
            this.onImageSelected(file);
        }
    }

    @action.bound
    setEntityPictureReferences(entityPictureReferences: ExternalPicture[])
    {
        this.entityPictureReferences = entityPictureReferences;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
