import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import React from 'react';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { SpotlerCampaignChartDelivered } from './SpotlerCampaignChartDelivered';
import { SpotlerCampaignChartViewed } from './SpotlerCampaignChartViewed';
import { SpotlerCampaignChartClicked } from './SpotlerCampaignChartClicked';
import { SpotlerCampaignChartUndeliverable } from './SpotlerCampaignChartUndeliverable';
import { SpotlerCampaignChartSelection } from './SpotlerCampaignChartSelection';
import useIsMobile from '../../../../../../../../@Util/Responsiveness/useIsMobile';



export interface SpotlerCampaignResultChartsProps
{
    spotlerCampaign: Entity,
    selectedStep: Entity
}

export const SpotlerCampaignResultCharts: React.FC<SpotlerCampaignResultChartsProps> =
    observer(
        ({
            spotlerCampaign,
            selectedStep
        }) =>
        {
            const isMobile = useIsMobile();

            return <ViewGroupItem>
                    <ViewGroup
                        orientation={isMobile ? 'vertical' : 'horizontal'}
                        spacing={isMobile ? 30 : 30}
                        justification="center"
                        alignment="center"
                    >
                            <ViewGroup
                                orientation="horizontal"
                                alignment="center"
                                spacing={30}
                            >
                                <ViewGroupItem
                                    alignment="center"
                                >
                                    <SpotlerCampaignChartSelection
                                        spotlerCampaign={spotlerCampaign}
                                        selectedStep={selectedStep}
                                        size={130}
                                    />
                                    </ViewGroupItem>
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={30}
                                    >
                                        <ViewGroupItem
                                            alignment="center"
                                        >
                                            <SpotlerCampaignChartDelivered
                                                spotlerCampaign={spotlerCampaign}
                                                selectedStep={selectedStep}
                                                size={130}
                                            />
                                        </ViewGroupItem>
                                </ViewGroup>

                                </ViewGroup>

                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={30}
                                    alignment="center"
                                >
                                    <ViewGroupItem
                                        alignment="center"
                                    >
                                        <SpotlerCampaignChartViewed
                                            spotlerCampaign={spotlerCampaign}
                                            selectedStep={selectedStep}
                                            size={130}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={30}
                                    >
                                        <ViewGroupItem
                                            alignment="center"
                                        >
                                            <SpotlerCampaignChartClicked
                                                spotlerCampaign={spotlerCampaign}
                                                selectedStep={selectedStep}
                                                size={130}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroup>
                                <ViewGroupItem
                                    alignment="center"
                                >
                                    <SpotlerCampaignChartUndeliverable
                                        spotlerCampaign={spotlerCampaign}
                                        selectedStep={selectedStep}
                                        size={130}
                                    />
                                </ViewGroupItem>
                    </ViewGroup>
            </ViewGroupItem>
        }
    );
