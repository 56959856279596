export default function resolveUrlFromUserInput(url: string)
{
    if (!url.startsWith('http://')
        && !url.startsWith('https://')
        && !url.startsWith('tel:'))
    {
        return `http://${url}`;
    }
    else
    {
        return url;
    }
}
