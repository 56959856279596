import * as React from 'react';
import { observer } from 'mobx-react';
import { AvatarStore, AvatarType, SubAvatarPosition } from './AvatarStore';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { Avatar as MuiAvatar, Icon, Paper, Theme } from '@material-ui/core';
import HexagonIcon from '../HexagonIcon/HexagonIcon';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '../Button/Button';
import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: Theme) =>
    createStyles({
        root:
        {
            position: 'relative'
        },
        subAvatarTop:
        {
            position: 'absolute',
            top: '-30%',
            left: '60%',
            width: '50%',
            height: '50%'
        },
        subAvatarBottom:
        {
            position: 'absolute',
            top: '60%',
            left: '60%',
            width: '50%',
            height: '50%'
        }
    });

@observer
class Avatar extends BaseComponent<AvatarStore>
{
    renderComponent(store: AvatarStore)
    {
        if (store.isVisible)
        {
            if (store.popperButtons)
            {
                return this.renderAvatarWithPopper(store);
            }
            else
            {
                return this.renderAvatar(store);
            }
        }
        else
        {
            return null;
        }
    }

    renderAvatarWithPopper(store: AvatarStore)
    {
        return <div
            style={{
                height: store.size,
                width: store.size,
                position: 'relative'
            }}
            onMouseEnter={store.startHoveringOverPopper}
            onMouseLeave={store.stopHoveringOverPopper}
        >
            <div
                style={{
                    height: store.size,
                    width: store.size,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: store.isHoveringOverPopper ? 2 : 1
                }}
            >
                {
                    this.renderAvatar(store)
                }
            </div>
            {
                store.isHoveringOverPopper &&
                    <Paper
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'absolute',
                            top: 0,
                            left: 17,
                            border: 'rgba(0, 0, 0, 0.12) 1px solid',
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            background: 'white',
                            height: store.size - 1,
                            width: store.isHoveringOverPopper ? 39 + (store.popperButtons.length * 40) : 0, // Padding + (buttonCount * (buttonWidth + 4 paddingLeft))
                            opacity: (store.isHoveringOverPopper && store.popperButtons.length > 0) ? 1 : 0,
                            transition: '0.2s',
                            zIndex: store.isHoveringOverPopper ? 1 : 0,
                            paddingLeft: 20
                        }}
                        elevation={3}
                    >
                        {
                            store.popperButtons
                                .map(
                                    (buttonStore, idx) =>
                                        <div
                                            key={idx}
                                            style={{
                                                paddingLeft:
                                                    idx === 0
                                                        ?
                                                            15
                                                        :
                                                            4
                                            }}
                                        >
                                            <Button
                                                store={buttonStore}
                                            />
                                        </div>)
                        }
                    </Paper>
            }
        </div>;
    }

    renderAvatar(store: AvatarStore)
    {
        return <div
            className={this.props.classes.root}
            style={{
                width:
                    store.type === AvatarType.Hexagon
                        ?
                            undefined
                        :
                            store.style.width,
                height:
                    store.type === AvatarType.Hexagon
                        ?
                            undefined
                        :
                            store.style.height,
                marginTop: store.spacingTop ? store.spacingTop : undefined,
                marginBottom: store.spacingBotttom ? store.spacingBotttom : undefined,
            }}
            onMouseEnter={store.startHovering}
            onMouseLeave={store.stopHovering}
        >
            {
                this.drawAvatar(store, false)
            }
            {
                store.subAvatar &&
                    <div
                        className={
                            store.subAvatarPosition === SubAvatarPosition.Top
                                ?
                                    this.props.classes.subAvatarTop
                                :
                                    this.props.classes.subAvatarBottom}
                    >
                        {
                            this.drawAvatar(store.subAvatar, true)
                        }
                    </div>
            }
        </div>;
    }

    drawAvatar(store: AvatarStore, drawAsSubAvatar: boolean = false)
    {
        switch (store.type)
        {
            case AvatarType.Hexagon:
                return <HexagonIcon store={store.hexagonStore} />;

            case AvatarType.Square:
            case AvatarType.Round:

                if (store.icon)
                {
                    return <MuiAvatar
                        src={store.failSafeSrc}
                        style={{
                            marginLeft: 0,
                            ...drawAsSubAvatar ? store.subStyle : store.style
                        }}
                        imgProps={{
                            onError: store.onFailToLoad
                        }}
                    >
                        <Icon
                            style={{
                                fontSize:
                                    store.iconFontSize
                            }}
                        >
                            {store.icon}
                        </Icon>
                    </MuiAvatar>;
                }
                else
                {
                    return <MuiAvatar
                        src={store.failSafeSrc}
                        style={{
                            marginLeft: 0,
                            ...drawAsSubAvatar ? store.subStyle : store.style
                        }}
                        imgProps={{
                            onError: store.onFailToLoad
                        }}
                    >
                        {store.character}
                    </MuiAvatar>;
                }
        }
    }
}

export default withStyles(styles)(Avatar);
