import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { EntityField } from '../../../../../@Api/Model/Implementation/EntityField';
import { DataObjectRepresentationProps } from '../../../DataObject/Model/DataObjectRepresentation';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';

export class BespokePrice extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Price')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        if (relationshipDefinition === this.types.PriceList.RelationshipDefinition.Prices)
        {
            setValueByFieldInEntity(
                entity,
                this.types.Price.Field.Currency,
                relatedEntity.getObjectValueByField(this.types.PriceList.Field.Currency, commitContext),
                commitContext
            );
        }
    }

    getRepresentation(
        entity: Entity,
        field: EntityField
    ): DataObjectRepresentationProps
    {
        const representation =
            super.getRepresentation(
                entity,
                field
            );

        if (field === this.types.Price.Field.Price)
        {
            return {
                ...representation,
                currency: entity.getObjectValueByField(this.types.Price.Field.Currency),
            };
        }
        else
        {
            return representation;
        }
    }

    // ----------------------- Private logic ------------------------
}
