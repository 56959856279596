import React, { useCallback, useContext, useState } from 'react';
import RichtextEditorContext from '../RichtextEditorContext/RichtextEditorContext';
import ColorPicker from '../../ColorPicker/ColorPicker';
import ToolbarButtonWrapper from './ToolbarButtonWrapper';

export interface EditorColorPickerProps
{
    tooltip: React.ReactNode;
}

const EditorColorPicker: React.FC<EditorColorPickerProps> =
    props =>
    {
        const { tooltip } = props;

        const context = useContext(RichtextEditorContext);
        const [ color, setColor ] = useState('#7b7b7b');
        const updateColor =
            useCallback(
                (value: any) =>
                {
                    setColor(value);
                    context.updateFormat("color", value);
                },
                [
                    context
                ]);

        if (context.allowTextStyling)
        {
            return <ToolbarButtonWrapper>
                <ColorPicker
                    color={color}
                    onChange={updateColor}
                    tooltip={tooltip}
                />
            </ToolbarButtonWrapper>;
        }
        else
        {
            return null;
        }
    };

EditorColorPicker.defaultProps =
{

};

export default EditorColorPicker;
