import React, { useContext, useMemo } from 'react';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { default as GenericPipeline } from '../../../../../@Future/Component/Generic/Pipeline/Pipeline';
import Phase from '../../../../../@Future/Component/Generic/Pipeline/Phase/Phase';
import { observer, useComputed } from 'mobx-react-lite';
import { colorToCss } from '../../../DataObject/Type/Color/ColorType';
import useSelectedPipelineStateIndex from './Api/useSelectedPipelineStateIndex';
import usePhaseSelectionCallback from './Api/usePhaseSelectionCallback';
import useVisitedStates from './Api/useVisitedStates';
import useIsDisabled from '../Api/useIsDisabled';
import CloseStateButton from '../../Button/CloseStateButton/CloseStateButton';
import useTypes from '../../Type/Api/useTypes';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import useIsMobile from '../../../../../@Util/Responsiveness/useIsMobile';
import CompactContext from '../../Item/Context/CompactContext';
import useWorkflowState from '../Api/useWorkflowState';
import useWorkflowStateIsTerminal from '../Api/useWorkflowStateIsTerminal';
import useResults from '../../Selection/Hooks/useResults';
import usePhaseRelationshipDefinition from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/usePhaseRelationshipDefinition';
import usePipelineRelationshipDefinition from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/usePipelineRelationshipDefinition';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import useSetting from '../../../Setting/Api/useSetting';
import { SettingSource } from '../../../Setting/SettingStore';
import { Setting } from '../../../../../@Api/Settings/Setting';

export interface WorkflowPipelineProps
{
    entity?: Entity;
    entityType?: EntityType;
    disabled?: boolean;
    showWhenNoPhases?: boolean;
    pipeline?: Entity;
}

const Pipeline: React.FC<WorkflowPipelineProps> =
    props =>
    {
        const types = useTypes();
        const entityType =
            useMemo(
                () =>
                    props.entityType || props.entity.entityType,
                [
                    props.entityType,
                    props.entity
                ]);
        const phaseRelationshipDefinition = usePhaseRelationshipDefinition(entityType);
        const pipelineRelationshipDefinition = usePipelineRelationshipDefinition(entityType);
        const [ hiddenIds ] = useSetting<string[]>(SettingSource.Organization, Setting.Datastore.HiddenIds);

        const pipeline =
            useComputed(
                () =>
                {
                    if (props.pipeline)
                    {
                        return props.pipeline;
                    }
                    else if (props.entity
                        && pipelineRelationshipDefinition)
                    {
                        return props.entity.getRelatedEntityByDefinition(
                            false,
                            pipelineRelationshipDefinition);
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.pipeline,
                    props.entity,
                    pipelineRelationshipDefinition
                ]);

        const phases =
            useResults(
                phaseRelationshipDefinition?.childEntityType,
                (builder, rootPath) =>
                    builder
                        .if(
                            () =>
                                pipeline !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Pipeline.RelationshipDefinition.Phases,
                                                true),
                                            pipeline)))
                        .if(
                            () =>
                                pipeline === undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.isNotDefined(
                                            rootPath
                                                .joinTo(
                                                    types.Pipeline.RelationshipDefinition.Phases,
                                                    true)
                                                .field(types.Entity.Field.Id))))
                        .orderBy(
                            rootPath.field(types.Entity.Field.SortIndex),
                            true),
                [
                    pipeline,
                    types
                ]);

        const nonHiddenPhases =
            useMemo(
                () =>
                    (phases || [])
                        .filter(
                            phase =>
                                hiddenIds?.indexOf(phase.uuid) === -1
                        ),
                [
                    phases
                ]
            );

        const openPhases =
            useComputed(
                () =>
                    (nonHiddenPhases || [])
                        .filter(
                            phase =>
                                !phase.getObjectValueByField(types.Datastore.Phase.Field.IsTerminal)
                        ),
                [
                    nonHiddenPhases
                ]);

        const terminalPhases =
            useComputed(
                () =>
                    (nonHiddenPhases || [])
                        .filter(
                            phase =>
                                phase.getObjectValueByField(types.Datastore.Phase.Field.IsTerminal)
                        ),
                [
                    nonHiddenPhases
                ]);

        const isDisabled = useIsDisabled(props.entityType || props.entity.entityType) || props.disabled;
        const selectedWorkflowStateIndex = useSelectedPipelineStateIndex(props.entity, openPhases);
        const onPhaseSelected = usePhaseSelectionCallback(props.entity, openPhases);
        const visitedStateUuids =
            new Set<string>(
                useVisitedStates(props.entity)
                    .map(
                        entity =>
                            entity.uuid));

        // const terminalPhases = useWorkflowStates(props.entityType || props.entity.entityType, true);
        const isMobile = useIsMobile();
        const isCompact = useContext(CompactContext) || isMobile;
        const currentState = useWorkflowState(props.entity);
        const isCurrentStateTerminal = useWorkflowStateIsTerminal(currentState);

        if (isCompact)
        {
            return null;
        }
        else if (!openPhases)
        {
            return null;
        }
        else if (openPhases.length === 0)
        {
            if (props.showWhenNoPhases)
            {
                return <Centered
                    horizontal
                >
                    <LocalizedText
                        code="Pipelines.NoPhasesAvailable"
                        value="Nog geen fases beschikbaar."
                    />
                </Centered>;
            }
            else
            {
                return null;
            }
        }
        else
        {
            return <GenericPipeline
                disabled={isDisabled}
                onPhaseSelected={onPhaseSelected}
            >
                {
                    openPhases.map(
                        (state, idx) =>
                        {
                            const isTerminal = state.getObjectValueByField(types.Datastore.Phase.Field.IsTerminal);

                            return <Phase
                                key={state.uuid}
                                name={state.name}
                                visited={visitedStateUuids.has(state.uuid)}
                                current={idx === selectedWorkflowStateIndex}
                                color={isTerminal ? colorToCss(state.getObjectValueByField(types.Datastore.Phase.Field.Color)) : undefined}
                                phaseComponent={
                                    !isCurrentStateTerminal &&
                                    idx === openPhases.length - 1 &&
                                    terminalPhases &&
                                    terminalPhases.length > 0 &&
                                        <CloseStateButton
                                            entityType={props.entityType}
                                            entity={props.entity}
                                        />
                                }
                            />;
                        })
                }
                {
                    isCurrentStateTerminal &&
                        <Phase
                            key={currentState.uuid}
                            name={currentState.name}
                            visited={visitedStateUuids.has(currentState.uuid)}
                            current
                            last
                            phaseComponent={
                                terminalPhases &&
                                terminalPhases.length > 0 &&
                                    <CloseStateButton
                                        entityType={props.entityType}
                                        entity={props.entity}
                                    />
                            }
                            // color={isCurrentStateTerminal ? colorToCss(currentState.getObjectValueByField(types.Datastore.Phase.Field.Color)) : undefined}
                        />
                }
            </GenericPipeline>;
        }
    };

export default observer(Pipeline);
