import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import styles from './RelatedActivityList.module.scss';
import Constructor from './Constructor/Constructor';
import Present from './List/Present/Present';
import Past from './List/Past/Past';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import EntityExpansionContext, { EntityExpansion } from './Context/EntityExpansionContext';
import useOpenedEntity from '../../../Context/OpenedEntity/useOpenedEntity';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useIsMobile from '../../../../../../../@Util/Responsiveness/useIsMobile';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import Item from './List/List/Item/Item';
import { EntityByDate } from '../../../../../../../@Api/Model/Implementation/EntityByDate';
import { getModel } from '../../../../../../../@Util/TransactionalModelV2/index';
import hasMore from './Api/hasMore';
import ConstructorItem from './List/List/Item/ConstructorItem';
import FileDrop from '../../../../../../../@Future/Component/Generic/FileDrop/FileDrop';
import ActivityList from './Model/ActivityList';
import Predicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import Pinned from './List/Pinned/Pinned';
import equalsEntity from '../../../../../../../@Api/Entity/Bespoke/equalsEntity';

export interface RelatedActivityListProps
{
    list: ActivityList;
    entity: Entity;
    onConstruct?: (entity: Entity, path: EntityPath) => void;
    filter?: (parameter: Parameter<EntityValueType>, path: EntityPath) => Predicate | undefined;
    onCount?: (count: number) => void;
    showCreationItem?: boolean;
}

const RelatedActivityList: React.FC<RelatedActivityListProps> =
    props =>
    {
        const { list } = props;

        const paths =
            useComputed(
                () =>
                    list.items.map(item => item.path),
                [
                    list
                ]);
        const itemByPathId =
            useComputed(
                () =>
                    new Map(
                        list.items.map(
                            item => [
                                item.path.id,
                                item
                            ])),
                [
                    list
                ]);
        const isConstructable =
            useCallback(
                (path: EntityPath) =>
                    itemByPathId.get(path.id).isConstructable,
                [
                    itemByPathId
                ]);
        const isMore =
            useCallback(
                (path: EntityPath) =>
                    itemByPathId.get(path.id).isConstructorInMoreMenu,
                [
                    itemByPathId
                ]);
        const showConstructor =
            useMemo(
                () =>
                    paths.some(path => isConstructable(path) || isMore(path)),
                [
                    paths
                ]);

        const openedEntity  = useOpenedEntity();
        const expansionState = useState<EntityExpansion | undefined>();
        const expansion = expansionState[0];
        const setExpansion = expansionState[1];
        const [ parent, setParent] = useState<Entity>(props.entity);

        useEffect(
            () => {
                if (!equalsEntity(parent, props.entity))
                {
                    setExpansion(undefined);
                    setParent(undefined);
                }
            },
            [
                setExpansion,
                setParent,
                props.entity
            ]
        );

        useEffect(
            () =>
            {
                if (openedEntity
                    && hasMore(openedEntity))
                {
                    const expand : EntityExpansion = {
                        entity: openedEntity,
                        types: new Set()
                    }
                    expand.types.add('More')
                    setExpansion( expand);
                    setParent(props.entity);
                }
            },
            [
                openedEntity,
                setExpansion
            ]);

        const [ openCount, setOpenCount ] = useState<number | undefined>();
        const [ closedCount, setClosedCount ] = useState<number | undefined>();
        const [ pinnedCount, setPinnedCount ] = useState<number | undefined>();
        const totalCount =
            useMemo(
                () =>
                {
                    if (openCount !== undefined && closedCount !== undefined && pinnedCount !== undefined)
                    {
                        return openCount + closedCount + pinnedCount;
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    openCount,
                    closedCount,
                    pinnedCount
                ]);

        useEffect(
            () =>
            {
                if (totalCount !== undefined && props.onCount)
                {
                    props.onCount(totalCount);
                }
            },
            [
                totalCount,
                props.onCount
            ]);

        const isMobile = useIsMobile();
        const openedItem =
            useMemo(
                () =>
                        expansion &&
                        expansion.types.has('More') &&
                        new EntityByDate(getModel(expansion.entity), 0),
                [
                    expansion
                ]);

        return <EntityExpansionContext.Provider
            value={expansionState}
        >
            <div
                style={{
                    position: 'relative'
                }}
            >
                <FileDrop
                    entity={props.entity}
                    disabled={!!openedItem }
                >
                    <div>
                        <div
                            className={classNames(styles.root, isMobile && styles.compact, openedItem && styles.itemOpen)}
                        >
                            {
                                openedItem &&
                                    <div
                                        className={styles.openedItem}
                                    >
                                        <Item
                                            entityByDate={openedItem}
                                            rootEntity={props.entity}
                                            notesOpenByDefault
                                            first
                                            last
                                            closeable
                                            highlighted
                                            open
                                        />
                                    </div>
                            }
                            <div
                                className={styles.main}
                            >
                                {
                                    !isMobile &&
                                        <div
                                            className={styles.line}
                                        />
                                }
                                <div
                                    className={styles.content}
                                >
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={20}
                                    >
                                        {
                                            showConstructor &&
                                                <ViewGroupItem>
                                                    <ConstructorItem>
                                                        <Constructor
                                                            entity={props.entity}
                                                            paths={paths}
                                                            onConstruct={props.onConstruct}
                                                            isMore={isMore}
                                                            isConstructable={isConstructable}
                                                        />
                                                    </ConstructorItem>
                                                </ViewGroupItem>
                                        }
                                        <ViewGroupItem>
                                            <div
                                                className={styles.data}
                                            >
                                                <ViewGroup
                                                    orientation="vertical"
                                                    spacing={32}
                                                >
                                                    <ViewGroupItem>
                                                        <Pinned
                                                            entity={props.entity}
                                                            paths={paths}
                                                            onTotalCount={setPinnedCount}
                                                            filter={props.filter}
                                                            compact={isMobile}
                                                        />
                                                    </ViewGroupItem>
                                                    <ViewGroupItem>
                                                        <Present
                                                            entity={props.entity}
                                                            paths={paths}
                                                            onTotalCount={setOpenCount}
                                                            filter={props.filter}
                                                            compact={isMobile}
                                                        />
                                                    </ViewGroupItem>
                                                    <ViewGroupItem>
                                                        <Past
                                                            entity={props.entity}
                                                            paths={paths}
                                                            onTotalCount={setClosedCount}
                                                            filter={props.filter}
                                                            compact={isMobile}
                                                            showCreationItem={props.showCreationItem}
                                                        />
                                                    </ViewGroupItem>
                                                </ViewGroup>
                                            </div>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </FileDrop>
            </div>
        </EntityExpansionContext.Provider>;
    };

export default observer(RelatedActivityList);
