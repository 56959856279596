import { Entity } from '../../../../Model/Implementation/Entity';
import { DataObject } from '../../../../../@Component/Domain/DataObject/Model/DataObject';
import { EntityField } from '../../../../Model/Implementation/EntityField';
import { EntityRelationshipDefinition } from '../../../../Model/Implementation/EntityRelationshipDefinition';
import { CommitBuilder } from './CommitBuilder';
import Predicate from '../../../../Automation/Function/Computation/Predicate/Predicate';
import ParameterAssignment from '../../../../Automation/Parameter/ParameterAssignment';
import { updateEntityFromFilter } from '../../../updateEntityFromFilter';
import FunctionContext from '../../../../Automation/Function/FunctionContext';
import ParameterDictionary from '../../../../Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../Automation/Parameter/Parameter';
import EntityValueType from '../../../../Automation/Value/Type/EntityValueType';

export class EntityScopedCommitBuilder
{
    // ------------------------- Properties -------------------------

    builder: CommitBuilder;
    entity: Entity;

    // ------------------------ Constructor -------------------------

    constructor(builder: CommitBuilder,
                entity: Entity)
    {
        this.builder = builder;
        this.entity = entity;
    }

    // ----------------------- Initialization -----------------------

    // ------------------------ Public logic ------------------------

    public setValue(
        field: EntityField,
        value: DataObject
    ): EntityScopedCommitBuilder
    {
        this.builder.setValueInEntity(
            this.entity,
            field,
            value
        );

        return this;
    }

    public setObjectValue(
        field: EntityField,
        value?: any
    ): EntityScopedCommitBuilder
    {
        this.builder.setObjectValueInEntity(
            this.entity,
            field,
            value
        );

        return this;
    }

    public relateTo(
        isParent: boolean,
        relationshipDefinition: EntityRelationshipDefinition,
        relatedEntity?: Entity
    ): EntityScopedCommitBuilder
    {
        this.builder.relateEntityTo(
            this.entity,
            isParent,
            relationshipDefinition,
            relatedEntity
        );

        return this;
    }

    public ifValid(
        predicate: () => boolean,
        builderConsumer: (builder: EntityScopedCommitBuilder) => void
    ): EntityScopedCommitBuilder
    {
        if (predicate())
        {
            builderConsumer(this);
        }

        return this;
    }

    public updateFromPredicate(
        predicate: Predicate,
        parameter: Parameter<EntityValueType>,
        parameterDictionary: ParameterDictionary,
        parameterAssignment: ParameterAssignment
    )
    {
        updateEntityFromFilter(
            this.entity,
            predicate,
            new FunctionContext(
                parameterDictionary,
                parameterAssignment,
                this.builder.context
            ),
            parameter,
            this.builder.context
        );

        return this;
    }

    // ----------------------- Private logic ------------------------
}
