import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityExpansionBuilder } from '../../Selection/Builder/EntityExpansionBuilder';
import { EntityPath } from '../../Path/@Model/EntityPath';
import getTypes from '../../Type/Api/getTypes';

export default function initializeFieldGroupsForType(entityType: EntityType)
{
    const types = getTypes();

    return new EntityExpansionBuilder(
        types.EntityType.Type,
        entityType.getInheritedTypes()
            .map(
                inheritedType =>
                    inheritedType.entity),
        [
            EntityPath.fromEntityType(types.EntityType.Type)
                .joinTo(
                    types.EntityType.RelationshipDefinition.FieldGroups,
                    false)
        ])
        .expand();
}
