export function copyElement(element?: any)
{
    if (element === undefined)
    {
        return undefined;
    }
    else
    {
        return JSON.parse(JSON.stringify(element));
    }
}