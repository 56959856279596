import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import styles from './TypeConstructor.module.scss';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { createTransactionalModel } from '../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import uuid from '../../../../../@Util/Id/uuid';
import LocalizerContext from '../../../../../@Service/Localization/LocalizerContext';
import { EntityTypeApi } from '../../../../../@Api/Api/EntityTypeApi';
import RouterContext from '../../../../../@Service/Router/RouterContext';
import useTypes from '../../../Entity/Type/Api/useTypes';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { default as EntityInput } from '../../../Entity/Input/Input';
import useEntityValue from '../../../../../@Api/Entity/Hooks/useEntityValue';
import CloseIconButton from '../../../../../@Future/Component/Generic/Button/Variant/CloseIconButton/CloseIconButton';
import SaveIconButton from '../../../../../@Future/Component/Generic/Button/Variant/SaveIconButton/SaveIconButton';
import CreatorEditionRequired from '../../../License/CreatorEditionRequired/CreatorEditionRequired';

export interface TypeConstructorProps
{
    parentType: EntityType;
    onClose: () => void;
    addRequiresCreator?: boolean;
}

const TypeConstructor: React.FC<TypeConstructorProps> =
    props =>
    {
        const localizerStore = useContext(LocalizerContext);
        const routerStore = useContext(RouterContext);
        const types = useTypes();

        const entityTypeEntity =
            useMemo(
                () =>
                    createTransactionalModel(
                        new Entity(types.EntityType.Type)
                            .initialize()),
                [
                    types
                ]);

        const { onClose } = props;
        const save =
            useCallback(
                () =>
                {
                    const entityType =
                        createTransactionalModel(
                            new EntityType());

                    entityType.entity = entityTypeEntity;

                    entityType.code = uuid();
                    entityType.parentType = props.parentType;
                    entityType.isInstantiable = true;

                    return new EntityTypeApi()
                        .commit(entityType, true)
                        .then(
                            () =>
                                onClose());
                },
                [
                    props.parentType,
                    onClose,
                    localizerStore,
                    routerStore,
                    types,
                    entityTypeEntity
                ]);

        const singularName = useEntityValue(entityTypeEntity, types.EntityType.Field.LocalizedSingularName);
        const pluralName = useEntityValue(entityTypeEntity, types.EntityType.Field.LocalizedPluralName);

        return <CreatorEditionRequired
            onClose={props.onClose}
            disabled={!props.addRequiresCreator}
        >
            <div
                className={styles.root}
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={10}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <EntityInput
                            entity={entityTypeEntity}
                            field={types.EntityType.Field.LocalizedSingularName}
                            labelPosition="none"
                            placeholder
                            doAutocommit={false}
                            autoFocus
                            required
                        />
                    </ViewGroupItem>
                    <ViewGroupItem
                        ratio={1}
                    >
                        <EntityInput
                            entity={entityTypeEntity}
                            field={types.EntityType.Field.LocalizedPluralName}
                            labelPosition="none"
                            placeholder
                            doAutocommit={false}
                            required
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <CloseIconButton
                            onClick={props.onClose}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <SaveIconButton
                            onClick={save}
                            disabled={!singularName || !pluralName}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </div>
        </CreatorEditionRequired>;
    };

export default observer(TypeConstructor);
