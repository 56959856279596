import { EntityType } from '../../../../Model/Implementation/EntityType';
import { useComputed } from 'mobx-react-lite';
import getPhaseRelationshipDefinition from './getPhaseRelationshipDefinition';

export default function usePhaseRelationshipDefinition(type: EntityType)
{
    return useComputed(
        () =>
            type && getPhaseRelationshipDefinition(type),
        [
            type
        ]);
}
