import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { ListFragmentStore } from './ListFragmentStore';
import { ListItem } from '../Item/ListItem';
import { DraggableElement } from '../../../DragAndDrop/DraggableElement';
import { ListItemStore } from '../Item/ListItemStore';

@observer
export default class ListFragment extends BaseComponent<ListFragmentStore<any>>
{
    renderComponent(store: ListFragmentStore<any>): JSX.Element
    {
        return <>
            {
                this.renderItems(store)
            }
        </>;
    }

    renderItems(store: ListFragmentStore<any>)
    {
        if (store.listStore.isDraggable)
        {
            return store.itemStores.map(
                (itemStore, idx) =>
                    <DraggableElement
                        key={`draggable-${itemStore.id}`}
                        draggableId={itemStore.id.toString()}
                        index={idx}
                    >
                        {(provided, snapshot) => (
                            <div
                                key={`itemcontainer-${itemStore.id}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                {
                                    this.renderItem(itemStore)
                                }
                            </div>)}
                    </DraggableElement>);
        }
        else
        {
            return store.itemStores.map(
                itemStore =>
                    this.renderItem(itemStore));
        }
    }

    renderItem(itemStore: ListItemStore<any, any>)
    {
        return <ListItem
            key={itemStore.id === undefined ? itemStore.uuid : itemStore.id}
            store={itemStore}
        />;
    }
}
