import { ButtonProps, ButtonStore } from '../../../Generic/Button/ButtonStore';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { computed } from 'mobx';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { LocalizationStore } from '../../../../@Service/Localization/LocalizationStore';
import { CurrentUserStore } from '../../User/CurrentUserStore';
import promptEntityDeletion from '../../../../@Api/Entity/promptEntityDeletion';

export interface EntityDeleteButtonProps extends ButtonProps
{
    entity: PropType<EntityDeleteButtonStore, EntityDeleteButtonProps, Entity>;
    onDelete?: () => void;
}

const defaultProps: Partial<EntityDeleteButtonProps> =
{

};

export class EntityDeleteButtonStore extends ButtonStore<EntityDeleteButtonProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: EntityDeleteButtonProps)
    {
        super({
            label:
                () => `${this.localizationStore.translate('Generic.Delete')}`, // Delete
            onClick:
                () =>
                    promptEntityDeletion(this.entity)
                        .then(
                            isDeleted =>
                            {
                                if (isDeleted)
                                {
                                    if (this.props.onDelete)
                                    {
                                        this.props.onDelete();
                                    }
                                }
                            }),
            isVisible:
                () => this.currentUserStore.rightProfile.canDelete(this.entity),
            ...props,
        }, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get entity(): Entity
    {
        return getOrCompute(this, this.props.entity);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
