import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../Path/@Model/EntityPath';
import getPhaseRelationshipDefinition from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/getPhaseRelationshipDefinition';
import { default as ViewModel } from '../Model/View';
import Specification from '../Model/Specification';
import { default as ListModel } from '../Model/Specification/List';
import { default as ListColumn } from '../Model/Specification/Column';
import { default as ColumnModel } from '../Model/Column/Column';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import Ordering from '../Model/Ordering';
import localizeText from '../../../../../@Api/Localization/localizeText';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import Layout from '../../../../../@Api/Layout/Layout';
import { isPrimitiveFieldRequired } from '../../../../../@Api/Metadata/Input/isPrimitiveFieldRequired';
import { isRelationshipDefinitionRequired } from '../../../../../@Api/Metadata/Input/isRelationshipDefinitionRequired';
import { isMultiCurrency } from '../../../Configuration/Page/Settings/OrganizationSettings/CurrencySettings/Api/isMultiCurrency';
import getViewParameters from './getViewParameters';

export default function getSystemDefaultView(
    entityType: EntityType,
    parameters?: ParameterDictionary,
    filter?: Predicate,
    name?: string,
    itemLayout?: Layout
): ViewModel
{
    // Returns the hardcoded default view of an entitytype

    const types = loadModuleDirectly(EntityTypeStore).bespoke.types;
    const rootPath = EntityPath.fromEntityType(entityType);
    const _isMultiCurrency = isMultiCurrency();

    const getViewColumns =
        () =>
        {
            if (entityType.isA(types.Activity.Type))
            {
                const phaseRelationshipDefinition = getPhaseRelationshipDefinition(entityType);

                return [
                    rootPath.field(types.Activity.Field.Number),
                    rootPath.joinTo(types.Relationship.RelationshipDefinition.Activities, true).field(),
                    rootPath.joinTo(types.Relationship.RelationshipDefinition.Activities, true).field(types.Entity.Field.Type),
                    rootPath.field(types.Activity.Field.Subject),
                    rootPath.joinTo(types.Relationship.Person.Contact.RelationshipDefinition.Activities, true).field(),
                    ...phaseRelationshipDefinition
                        ?
                            [
                                rootPath.joinTo(phaseRelationshipDefinition, false).field()
                            ]
                        :
                            [],
                    ...entityType.isA(types.Activity.SalesOpportunity.Type)
                        ?
                            _isMultiCurrency
                                ? [
                                    rootPath.field(types.Activity.Field.Amount),
                                    rootPath.field(types.Activity.Field.AmountInCurrency),
                                    rootPath.castTo(types.Activity.SalesOpportunity.Type).field(types.Activity.SalesOpportunity.Field.Probability),
                                ]
                                : [
                                    rootPath.field(types.Activity.Field.Amount),
                                    rootPath.castTo(types.Activity.SalesOpportunity.Type).field(types.Activity.SalesOpportunity.Field.Probability),
                                ]
                        :
                            [],
                    ...entityType.isA(types.Activity.Invoice.Type)
                        ?
                            _isMultiCurrency
                                ? [
                                    rootPath.field(types.Activity.Invoice.Field.Date),
                                    rootPath.field(types.Activity.Invoice.Field.ExpirationDate),
                                    rootPath.field(types.Activity.Field.TotalSalesExcludingVat),
                                    rootPath.field(types.Activity.Field.TotalSalesExcludingVatInCurrency),
                                    rootPath.field(types.Activity.Field.TotalSalesIncludingVat),
                                    rootPath.field(types.Activity.Field.TotalSalesIncludingVatInCurrency)
                                ]
                                : [
                                    rootPath.field(types.Activity.Invoice.Field.Date),
                                    rootPath.field(types.Activity.Invoice.Field.ExpirationDate),
                                    rootPath.field(types.Activity.Field.TotalSalesExcludingVat),
                                    rootPath.field(types.Activity.Field.TotalSalesIncludingVat)
                                ]
                        :
                            []
                ];
            }
            else if (entityType.isA(types.Relationship.Type))
            {
                if (entityType.isA(types.Relationship.Organization.Type))
                {
                    return [
                        rootPath
                            .joinTo(
                                types.Relationship.Organization.RelationshipDefinition.Organization,
                                false)
                            .field(types.Relation.Organization.Field.Name)
                    ];
                }
                else if (entityType.isA(types.Relationship.Person.Type))
                {
                    return [
                        rootPath
                            .joinTo(
                                types.Relationship.Person.RelationshipDefinition.Person,
                                false)
                            .field(types.Relation.Person.Field.FirstName),
                        rootPath
                            .joinTo(
                                types.Relationship.Person.RelationshipDefinition.Person,
                                false)
                            .field(types.Relation.Person.Field.LastName)
                    ];
                }
                else
                {
                    return [
                        rootPath.field(types.Entity.Field.Type),
                        rootPath.field(types.Entity.Field.Name),
                    ];
                }
            }
            else
            {
                const fields = [
                    ...rootPath.entityType.getInheritedFields()
                        .filter(
                            field =>
                                field.isDefining
                                || isPrimitiveFieldRequired(rootPath.entityType, field)
                        )
                        .map(
                            field =>
                                rootPath.field(field)
                        ),
                    ...[ true, false ]
                        .map(
                            isParent =>
                                rootPath.entityType
                                    .getInheritedRelationshipDefinitions(isParent)
                                    .filter(
                                        relationshipDefinition =>
                                            isRelationshipDefinitionRequired(
                                                rootPath.entityType,
                                                isParent,
                                                relationshipDefinition
                                            )
                                            &&
                                            relationshipDefinition.isVisibleDuringConstruction(isParent))
                                    .map(
                                        relationshipDefinition =>
                                            rootPath
                                                .joinTo(relationshipDefinition, isParent)
                                                .field()))
                        .reduce((a, b) => a.concat(b), [])
                ];

                if (fields.length === 0)
                {
                    return [
                        rootPath.field(types.Entity.Field.Type),
                        rootPath.field(types.Entity.Field.Name),
                    ];
                }
                else
                {
                    return fields;
                }
            }
        };

    const viewColumns =
        getViewColumns()
            .map(
                (fieldPath, idx) =>
                    new ListColumn(
                        idx.toString(),
                        fieldPath));

    const orderColumns =
        viewColumns
            .filter(
                (column, idx) =>
                    idx === 0
            )
            .map(
                column =>
                    new Ordering(
                        column,
                        column.fieldPath.field !== types.Activity.Field.Number
                    )
            );

    const phaseRelationshipDefinition = getPhaseRelationshipDefinition(entityType);

    return new ViewModel(
        'List',
        name || localizeText('View.AllOfType', 'Alle ${typeName}', { typeName: entityType.getName(true)?.toLowerCase() || '' }),
        entityType,
        parameters || getViewParameters(entityType),
        filter,
        new Specification(
            new ListModel(
                viewColumns,
                orderColumns
            ),
            phaseRelationshipDefinition
                ?
                    new ColumnModel(
                        rootPath
                            .joinTo(phaseRelationshipDefinition, false)
                            .field(),
                        [],
                        undefined,
                        itemLayout
                    )
                :
                    undefined
        )
    );
}
