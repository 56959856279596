import AutomationDependencyContext from '../AutomationDependencyContext';
import { observable } from 'mobx';
import Computation from '../Function/Computation/Computation';
import getComputationFromDescriptor from '../Api/getComputationFromDescriptor';
import uuid from '../../../@Util/Id/uuid';

export type SortDirection = 'Ascending' | 'Descending';

export default class ListQueryOrdering
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref expression: Computation<any, any>;
    @observable direction: SortDirection;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                expression: Computation<any, any>,
                direction: SortDirection)
    {
        this.id = id;
        this.expression = expression;
        this.direction = direction;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate()
    {
        return this.expression.validate();
    }

    getDependencies()
    {
        return this.expression.getDependencies();
    }

    toDescriptor()
    {
        return {
            id: this.id,
            expression: this.expression.toDescriptor(),
            direction: this.direction
        };
    }

    static async fromDescriptor(dependencyContext: AutomationDependencyContext,
                                descriptor: any)
    {
        const expression = await getComputationFromDescriptor(descriptor.expression, dependencyContext);
        const direction = descriptor.direction;

        return new ListQueryOrdering(
            descriptor.id || uuid(),
            expression,
            direction);
    }

    // ----------------------- Private logic ------------------------
}
