import { type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';
import { RespondentDetails } from './RespondentDetails';

@type('OfferSigningDescriptor')
export class OfferSigningDescriptor
{
    // ------------------- Persistent Properties --------------------

    @observable isSigned: boolean;
    @observable isRejected: boolean;
    @observable isChangeRequested: boolean;
    @observable isCancelled: boolean;
    @observable isExpired: boolean;
    @observable.ref respondentDetails?: RespondentDetails;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
