import { DynamicFunction } from '../DynamicFunction';
import { FunctionType } from '../../FunctionType';

export default abstract class DynamicFunctionRepository<T extends DynamicFunction<any, any>>
{
    // ------------------------- Properties -------------------------

    id: string;

    // ------------------------ Constructor -------------------------

    constructor(id: string)
    {
        this.id = id;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract getName(): string;

    abstract getFunctionById(id: string): Promise<T>;

    abstract listFunctions(type: FunctionType): Promise<T[]>;

    // ----------------------- Private logic ------------------------
}
