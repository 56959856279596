import React from 'react';

export interface ViewGroupItemProps
{
    ratio?: number;
    className?: string;
    alignment?: 'left' | 'center' | 'right';
}

const ViewGroupItem: React.FC<ViewGroupItemProps> =
    props =>
    {
        return <>{props.children}</>;
    };

export default ViewGroupItem;
