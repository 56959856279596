import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useCount from '../../../../../Selection/Hooks/useCount';
import useTypes from '../../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';

export default function useApsisCampaignSendCount(apsisCampaign: Entity)
{
    const types = useTypes();

    return useCount(
        types.Activity.ApsisCampaignResult.Type,
        (builder) =>
            builder
                .where(
                    cb =>
                        cb.relatedToEntity(
                            EntityPath
                                .fromEntityType(types.Activity.ApsisCampaignResult.Type)
                                .joinTo(
                                    types.Activity.ApsisCampaign.RelationshipDefinition.Results,
                                    true
                                ),
                            apsisCampaign
                        )
                ),
        [
            types,
            apsisCampaign
        ]
    );
}