import React, { useCallback, useContext, useMemo } from 'react';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import { EntitySelectionAggregateResult } from '../../../../Selection/Model/EntitySelectionAggregateResult';
import { Droppable } from 'react-beautiful-dnd';
import useOnDrop from './Api/useOnDrop';
import styles from './List.module.scss';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import Item from './Item/Item';
import Icon from '../../../../../../../@Future/Component/Generic/Icon/Icon';
import constructEntity from '../../../../../../../@Api/Entity/constructEntity';
import FilterContext from '../../../../Dataset/Segment/FilterContext/FilterContext';
import { primaryColor } from '../../../../../../../@Resource/Theme/Theme';
import Tooltip from '../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import equalsEntity from '../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import DroppingEntityContext from '../../Context/DroppingEntityContext';
import Segment from '../../../../Dataset/Model/Segment';
import usePaginatedSelection from '../../../Api/usePaginatedSelection';
import InfiniteScroll from '../../../../../../../@Future/Component/Generic/InfiniteScroll/InfiniteScroll';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import Predicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import ColumnOrdering from '../../../Model/Column/ColumnOrdering';
import View from '../../../Model/View';
import { getInitializationPathsForParameterFromLayout } from '../../../../../../../@Api/Layout/Api/getInitializationPathsForParameterFromLayout';
import { ViewParams } from '../../../Model/ViewParams';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';

export interface ListProps
{
    view: View;
    entityType: EntityType;
    idx: number;
    segment: Segment;
    result: EntitySelectionAggregateResult;
    addButtonClassName: string;
    resultFilter: Predicate;
    ordering: ColumnOrdering[];
    onOpen?: (entity: Entity) => void;
}

const List: React.FC<ListProps> =
    props =>
    {
        const filter = useContext(FilterContext);

        const [ pages, hasMore, loadMore, isLoading ] =
            usePaginatedSelection(
                props.entityType,
                builder =>
                    builder
                        .join(props.segment.groupFieldPath.path)
                        .if(
                            () => true,
                            sb =>
                                props.entityType.bespoke.getListDependencies()
                                    .forEach(
                                        dependency =>
                                            sb.join(dependency)
                                    )
                        )
                        .where(
                            cb =>
                                cb.filter(
                                    filter,
                                    {
                                        parameter: props.view.parameter,
                                    }
                                )
                        )
                        .if(
                            () => true,
                            sb =>
                                props.ordering.forEach(
                                    ordering =>
                                        sb.orderBy(
                                            ordering.fieldPath,
                                            ordering.isAscending)))
                        .if(
                            () =>
                                props.view.specification.column.itemLayout !== undefined,
                            sb =>
                                getInitializationPathsForParameterFromLayout(
                                    props.view.specification.column.itemLayout,
                                    props.view.parameters.getParameterById(ViewParams.Entity)
                                ).forEach(
                                    path =>
                                        sb.join(path)
                                )
                        ),
                [
                    props.segment,
                    filter,
                    props.ordering,
                    props.view,
                ]);

        const entities =
            useComputed(
                () =>
                    pages.slice()
                        .map(
                            page =>
                                page.slice())
                        .reduce((a, b) => a.concat(b), [])
                        .map(
                            result =>
                                result.entity),
                [
                    pages
                ]);

        const id = useMemo(() => `${props.idx}`, [ props.idx ]);
        const dropzoneId = useMemo(() => `${id}-secondary`, [ id ]);
        const droppingEntity = useContext(DroppingEntityContext);

        useOnDrop(
            entities,
            id,
            props.segment,
            props.result);

        useOnDrop(
            entities,
            dropzoneId,
            props.segment,
            props.result);

        const onAdd =
            useCallback(
                () =>
                    constructEntity(props.entityType, filter),
                [
                    props.entityType,
                    filter
                ]);

        const addCardStyles =
            useMemo(
                () =>
                    ({
                        root: styles.addCard
                    }),
                []);

        const addLabel =
            useComputed(
                () =>
                    localizeText('EntityList.Add', '${type} toevoegen', { type: props.entityType.getName() }),
                [
                    props.entityType
                ]);

        const idx = droppingEntity ? droppingEntity.targetIdx : entities.length;
        const setA = entities.slice(0, idx);
        const setB = entities.slice(idx, entities.length);

        return <div
            className={styles.root}
        >
            <InfiniteScroll
                loadMore={loadMore}
                hasMore={hasMore}
                isLoading={isLoading}
            >
                <Droppable
                    droppableId={id}
                    type="entity"
                >
                    {(provided, snapshot) =>
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={styles.list}
                        >
                            {
                                setA
                                    .filter(
                                        localEntity =>
                                            !equalsEntity(
                                                localEntity,
                                                droppingEntity && droppingEntity.entity))
                                    .map(
                                        (entity, idx) =>
                                            <Item
                                                key={entity.uuid}
                                                view={props.view}
                                                entity={entity}
                                                idx={idx}
                                            />)
                            }
                            {
                                droppingEntity &&
                                    droppingEntity.target.id === props.result.id &&
                                    <Item
                                        key={droppingEntity.entity.uuid}
                                        view={props.view}
                                        entity={droppingEntity.entity}
                                        idx={entities.length}
                                        disableDragging
                                    />
                            }
                            {
                                setB
                                    .filter(
                                        localEntity =>
                                            !equalsEntity(
                                                localEntity,
                                                droppingEntity && droppingEntity.entity))
                                    .map(
                                        (entity, idx) =>
                                            <Item
                                                key={entity.uuid}
                                                view={props.view}
                                                entity={entity}
                                                idx={idx}
                                                onOpen={props.onOpen}
                                            />)
                            }
                            {provided.placeholder}
                        </div>}
                </Droppable>
                <Droppable
                    droppableId={dropzoneId}
                    type="entity"
                >
                    {(provided, snapshot) =>
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={styles.dropzone}
                        >
                            <div
                                className={props.addButtonClassName}
                                onClick={onAdd}
                            >
                                <Tooltip
                                    title={addLabel}
                                    block
                                >
                                    <Card
                                        inset
                                        classes={addCardStyles}
                                    >
                                        <Centered
                                            horizontal
                                        >
                                            <Icon
                                                icon="add"
                                                color={primaryColor}
                                            />
                                        </Centered>
                                    </Card>
                                </Tooltip>
                            </div>
                            {
                                entities.length === 0 &&
                                    <div
                                        className={styles.emptyLabel}
                                    >
                                        <LocalizedText
                                            code="EntityView.List.DragHere"
                                            value="sleep hier"
                                        />
                                    </div>
                            }
                            {provided.placeholder}
                        </div>}
                </Droppable>
                {/* Keep space because droppable may not be beneath the drawer */}
                <div
                    className={styles.spacer}
                />
            </InfiniteScroll>
            {
                pages.length > 1 && isLoading &&
                    <Centered
                        horizontal
                    >
                        <Loader />
                    </Centered>
            }
        </div>;
    };

export default observer(List);
