import { CommitContext } from "../../../../../../../@Api/Entity/Commit/Context/CommitContext";
import { Entity } from "../../../../../../../@Api/Model/Implementation/Entity";
import ExpansionGroup from "../../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup";
import ViewGroup from "../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup";
import ViewGroupItem from "../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem";
import React from "react";
import { observer } from "mobx-react-lite";
import { ApsisCampaignResults } from "./ApsisCampaignResults";

export interface ApsisCampaignProps
{
    apsisCampaign: Entity;
    commitContext?: CommitContext
}
export const ApsisCampaign: React.FC<ApsisCampaignProps> = observer(
    (
        {
            apsisCampaign,
            commitContext
        }
    ) =>
    {
        return <ExpansionGroup>
            <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <ApsisCampaignResults
                        apsisCampaign={apsisCampaign}
                    />
                </ViewGroupItem>

                <ViewGroupItem>
                    {/*<ExternalCampaignSelection*/}
                    {/*    externalCampaign={apsisCampaign}*/}
                    {/*    commitContext={commitContext}*/}
                    {/*/>*/}
                </ViewGroupItem>
            </ViewGroup>
        </ExpansionGroup>
    }
);