import React from 'react';
import LabelButton, { LabelButtonProps } from '../Label/LabelButton';
import { primaryColor } from '../../../../../../@Resource/Theme/Theme';

export interface PrimaryButtonProps extends LabelButtonProps
{

}

const PrimaryButton: React.FC<PrimaryButtonProps> =
    props =>
    {
        return <LabelButton
            {...props}
        />;
    };

PrimaryButton.defaultProps = {
    color: primaryColor,
    filled: true
};

export default PrimaryButton;
