import { BaseStore, getOrCompute, PropType } from '../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { ColorUtils } from '../../../@Util/Color/ColorUtils';
import { ChartDataPoint } from './Model/ChartDataPoint';
import { DataObject } from '../../Domain/DataObject/Model/DataObject';
import { LocalizationStore } from '../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../../Domain/DataObject/DataObjectStore';
import { Aggregate } from '../../Domain/DataObject/Model/Aggregate';

export type ChartType = 'pie' | 'bar' | 'line' | 'funnel' | 'pipeline';

export type ChartData = ChartDataPoint[]; // Map<string, number>;

export interface ChartProps
{
    type: PropType<ChartStore, ChartProps, ChartType>;
    data: PropType<ChartStore, ChartProps, ChartData>;
    color: PropType<ChartStore, ChartProps, string>;
    onSelect?: (idx: number) => void;
    onUnselect?: () => void;
}

const defaultProps: Partial<ChartProps> =
{

};

export class ChartStore extends BaseStore<ChartProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;

    // ------------------------- Properties -------------------------

    @observable selectedIndex: number;

    // ------------------------ Constructor -------------------------

    constructor(props: ChartProps)
    {
        super(props, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get type(): ChartType
    {
        return getOrCompute(this, this.props.type);
    }

    @computed
    get data(): ChartData
    {
        return getOrCompute(this, this.props.data);
    }

    @computed
    get sum(): DataObject
    {
        return DataObject.aggregate(
            this.dataObjectStore,
            this.data.map(p => p.value),
            Aggregate.Sum);
    }

    @computed
    get segmentGroups(): Set<DataObject>
    {
        const segmentGroups = new Set<DataObject>();
        const segmentGroupNames = new Set<string>();

        this.data.forEach(
            point =>
                point.segmentations.forEach(
                    segmentation =>
                        segmentation.points.forEach(
                            segment =>
                            {
                                if (!segmentGroupNames.has(segment.name))
                                {
                                    segmentGroupNames.add(segment.name);
                                    segmentGroups.add(segment.group);
                                }
                            })));

        return segmentGroups;
    }

    @computed
    get color(): string
    {
        return getOrCompute(this, this.props.color);
    }

    @computed
    get selectedColor(): string
    {
        return ColorUtils.hexToRGB(this.color, 0.1);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    toggleIndexSelection(idx: number)
    {
        if (this.selectedIndex === idx)
        {
            this.unselectIndex();
        }
        else
        {
            this.selectIndex(idx);
        }
    }

    @action.bound
    selectIndex(idx: number)
    {
        this.selectedIndex = idx;

        if (this.props.onSelect)
        {
            this.props.onSelect(idx);
        }
    }

    @action.bound
    unselectIndex()
    {
        this.selectedIndex = undefined;

        if (this.props.onUnselect)
        {
            this.props.onUnselect();
        }
    }

    // ------------------------ Public logic ------------------------

    colorByIdx(idx: number): string
    {
        if (idx === this.selectedIndex)
        {
            return ColorUtils.hexToRGB(this.color, 0.1);
        }
        else
        {
            return ColorUtils.hexToRGB(this.color, 1 - (idx / this.data.length) * 0.7);
        }
    }

    // ----------------------- Private logic ------------------------
}
