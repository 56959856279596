import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import FirstElementOfCollectionComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/FirstElementOfCollectionComputation';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationBox from '../../Box/ComputationBox';

export interface FirstElementOfCollectionComputationEditorProps extends ComputationEditorProps<ValueType<any>, FirstElementOfCollectionComputation>
{

}

const FirstElementOfCollectionComputationEditor: React.FC<FirstElementOfCollectionComputationEditorProps> =
    props =>
    {
        const { value, context } = props;

        const setCollection =
            useCallback(
                (collection?: Computation<any, any>) =>
                    runInAction(
                        () =>
                        {
                            value.collection = collection;
                        }),
                [
                    value
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <Input
                label="Eerste element uit collectie"
                labelPosition="top"
            >
                <ComputationBox>
                    <ComputationEditor
                        context={context}
                        value={value.collection}
                        onChange={setCollection}
                    />
                </ComputationBox>
            </Input>
        </ViewGroup>;
    };

export default observer(FirstElementOfCollectionComputationEditor);
