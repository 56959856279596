import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import useTypes from '../../Type/Api/useTypes';
import { ItemPlannerEmployeePlanCellProps } from '../EmployeeRow/PlanCell/EmployeeAllocationEditorEmployeePlanCell';
import { EmployeeAllocationTableEditorRow } from './Row/EmployeeAllocationTableEditorRow';
import Loader from '../../../../../@Future/Component/Generic/Loader/Loader';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import { EmployeeAllocationTableEditorConstructorRow } from './Row/EmployeeAllocationTableEditorConstructorRow';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { CommitBuilder } from '../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import DeleteButton from '../../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';

export interface InitializedEmployeeAllocationTableEditorProps extends ItemPlannerEmployeePlanCellProps
{
    items: Entity[];
    loading?: boolean;
    includeDate?: boolean;
    disableConstructor?: boolean;
    disableSequenceControls?: boolean;
}

export const InitializedEmployeeAllocationTableEditor: React.FC<InitializedEmployeeAllocationTableEditorProps> =
    observer(
        props =>
        {
            const {
                specification,
                items,
                loading,
                includeDate,
                disableConstructor,
            } = props;
            const types = useTypes();
            const [ selectedItems, setSelectedItems ] = useState(() => new Set<Entity>());
            const onSelect =
                useCallback(
                    (item: Entity, isSelected: boolean) =>
                        setSelectedItems(
                            oldSelection =>
                            {
                                const newSelection = new Set(oldSelection);

                                if (isSelected)
                                {
                                    newSelection.add(item);
                                }
                                else
                                {
                                    newSelection.delete(item);
                                }

                                return newSelection;
                            }
                        ),
                    []
                );
            const numberOfColumns = 6 + (includeDate ? 1 : 0) + (specification.employeeAllocationConstructorFields ?? []).length;

            return <>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {
                                includeDate &&
                                <TableCell>
                                    {types.EmployeeAllocation.Field.WeekDate.getName()}
                                </TableCell>
                            }
                            <TableCell>
                                {types.Relationship.RelationshipDefinition.EmployeeAllocations.getName(true)}
                            </TableCell>
                            <TableCell>
                                {types.Activity.RelationshipDefinition.EmployeeAllocations.getName(true)}
                            </TableCell>
                            {
                                (specification.employeeAllocationConstructorFields ?? [])
                                    .map(
                                        constructorField =>
                                            <TableCell
                                                key={constructorField.id}
                                            >
                                                {constructorField.getName()}
                                            </TableCell>
                                    )
                            }
                            <TableCell>
                                {types.Relationship.Person.Contact.Employee.RelationshipDefinition.Allocations.getName(true)}
                            </TableCell>
                            <TableCell>
                                {types.EmployeeAllocation.Field.DurationInHours.getName()}
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            items
                                .map(
                                    item =>
                                        <EmployeeAllocationTableEditorRow
                                            {...props}
                                            key={item.uuid}
                                            item={item}
                                            autoCommit
                                            selected={selectedItems.has(item)}
                                            onSelect={onSelect}
                                        />
                                )
                        }
                        {
                            loading &&
                            <TableRow>
                                <TableCell
                                    colSpan={numberOfColumns}
                                >
                                    <Centered
                                        horizontal
                                    >
                                        <Loader />
                                    </Centered>
                                </TableCell>
                            </TableRow>
                        }
                        {
                            !disableConstructor &&
                            <EmployeeAllocationTableEditorConstructorRow
                                {...props}
                            />
                        }
                        {
                            selectedItems.size > 0 &&
                            <TableRow>
                                <TableCell
                                    colSpan={numberOfColumns}
                                >
                                    <DeleteButton
                                        onClick={
                                            () =>
                                                new CommitBuilder()
                                                    .ifValid(
                                                        () => true,
                                                        builder =>
                                                            selectedItems.forEach(
                                                                selectedItem =>
                                                                    builder.deleteEntity(selectedItem)
                                                            )
                                                    )
                                                    .commit()
                                        }
                                        fullWidth
                                    />
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </>;
        }
    );
