import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { WidgetProps } from '../Widget';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import { default as InternalResourcePlanner } from '../../../ResourcePlanner/ResourcePlanner';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import ProjectPlannerWidget from '../../Model/Widget/ProjectPlanner/ProjectPlannerWidget';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { useMyProjectTimesheetByTimeRegistration } from '../../../ResourcePlanner/Type/useMyProjectTimesheetByTimeRegistration';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';
import { TimesheetResourceName } from './TimesheetResourceName';
import moment from 'moment';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import EntityConstructorButton from '../../../Button/EntityConstructorButton/EntityConstructorButton';

export interface MyProjectTimesheetByTimeRegistrationProps extends WidgetProps<ProjectPlannerWidget>
{
}

export const MyProjectTimesheetByTimeRegistration: React.FC<MyProjectTimesheetByTimeRegistrationProps> = observer(
    ({
         widget,
        onDelete
    }) =>
    {
        const types = useTypes();
        const resourcePlanner = useMyProjectTimesheetByTimeRegistration();

        const formatResourceName =
            useCallback(
                (resource: Entity) =>
                {
                    if (resource.entityType.isA(types.TimeRegistration.Type))
                    {
                        return <TimesheetResourceName
                            resource={resource}
                        />
                    }
                    else
                    {
                        return undefined
                    }
                },
                [
                    types
                ]
            );

        return <Card>
            <CardInset
                horizontal={false}
                bottom={false}
            >
                <InternalResourcePlanner
                    id={widget.id}
                    resourcePlanner={resourcePlanner}
                    formatResourceName={formatResourceName}
                    name={
                        <LocalizedText
                            code="ResourcePlanner.MyProjectTimesheetByTimeRegistration"
                            value="Mijn geboekte uren per project"
                        />
                    }
                    minimumCellDuration={moment.duration(1, 'day')}
                    appendix={
                        <ViewGroup
                            orientation="horizontal"
                            spacing={10}
                        >
                            <ViewGroupItem>
                                <EntityConstructorButton
                                    entityType={types.TimeRegistration.Type}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <MenuButton>
                                    <Menu>
                                        <DeleteItem
                                            onClick={onDelete}
                                        />
                                    </Menu>
                                </MenuButton>
                            </ViewGroupItem>
                        </ViewGroup>
                    }
                    dragHandle
                    disableTimesheetDialog
                    expandResources
                />
            </CardInset>
        </Card>;
    }
)
