import { Entity } from '../../../../../Model/Implementation/Entity';
import { CommitContext } from '../../CommitContext';
import { isDirty, isTransactionalModel } from '../../../../../../@Util/TransactionalModelV2/index';

export function isEntityDirty(
    entity: Entity,
    commitContext?: CommitContext
): boolean
{
    if (commitContext)
    {
        return commitContext.isEntityDirty(entity);
    }
    else if (isTransactionalModel(entity))
    {
        return isDirty(entity);
    }
    else
    {
        return false;
    }
}
