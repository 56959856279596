import { observable } from 'mobx';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Action from './Action';
import EmptyValueType from '../../Value/Type/EmptyValueType';
import EmptyValue from '../../Value/EmptyValue';
import CompositeActionInvocation from './CompositeActionInvocation';
import Validation from '../../Validation/Validation';
import Computation from '../Computation/Computation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';

export default class CompositeAction extends Action<EmptyValueType, EmptyValue>
{
    // ------------------------- Properties -------------------------

    @observable.shallow invocations: CompositeActionInvocation[];
    @observable.ref returnValue?: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(
        invocations: CompositeActionInvocation[],
        returnValue?: Computation<any, any>
    )
    {
        super();

        this.invocations = invocations;
        this.returnValue = returnValue;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        if (this.returnValue === undefined)
        {
            return EmptyValueType.instance;
        }
        else
        {
            return this.returnValue.getType();
        }
    }

    getName()
    {
        return 'Doe ... en ...';
    }

    validate(): Validation[]
    {
        return [
            ...this.invocations
                .flatMap(
                    invocation =>
                        invocation.validate()
                ),
            ...this.returnValue === undefined
                ? []
                : this.returnValue.validate(),
        ];
    }

    isAsync(): boolean
    {
        return this.invocations.some(
            invocation =>
                invocation.isAsync()
        ) || (this.returnValue?.isAsync() ?? false);
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Composite';
        descriptor.invocations =
            this.invocations.map(
                invocation =>
                    invocation.toDescriptor()
            );
        descriptor.returnValue = this.returnValue?.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.invocations
                .flatMap(
                    action =>
                        action.getDependencies()
                ),
            ...this.returnValue === undefined
                ? []
                : this.returnValue.getDependencies()
        ];
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext
    )
    {
        const invocations: CompositeActionInvocation[] = [];

        for (const invocation of descriptor.invocations)
        {
            invocations.push(
                await CompositeActionInvocation.fromDescriptor(
                    invocation,
                    dependencyContext
                )
            );
        }

        const returnValue =
            descriptor.returnValue === undefined
                ? undefined
                : await getComputationFromDescriptor(
                    descriptor.returnValue,
                    dependencyContext
                );

        return new CompositeAction(
            invocations,
            returnValue
        );
    }

    // ----------------------- Private logic ------------------------
}
