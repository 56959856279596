import { EntityType } from '../Model/Implementation/EntityType';
import useSetting from '../../@Component/Domain/Setting/Api/useSetting';
import { SettingSource } from '../../@Component/Domain/Setting/SettingStore';
import { useComputed } from 'mobx-react-lite';
import { useCallback } from 'react';

export default function useMetadataSettingOverride<T>(entityType: EntityType,
                                                      code: string): [ T, (value?: T) => void ]
{
    const [ setting, setSetting ] = useSetting<any>(SettingSource.Organization, code, true);
    const value =
        useComputed(
            () =>
                entityType.getInheritedTypes()
                    .map(
                        inheritedType =>
                            setting[inheritedType.id])
                    .filter(
                        value =>
                            value !== undefined)
                    .find(() => true),
            [
                setting,
                entityType
            ]);
    const setValue =
        useCallback(
            (value: T) =>
            {
                const newValue: any = {
                    ...setting
                };

                if (value)
                {
                    newValue[entityType.id] = value;
                }
                else
                {
                    delete newValue[entityType.id];
                }

                setSetting(newValue);
            },
            [
                setting,
                setSetting,
                entityType
            ]);

    return [ value, setValue ];
}
