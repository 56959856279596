import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultRelationshipEditor } from '../../DefaultRelationshipEditor/DefaultRelationshipEditor';
import { RelationshipEditorProps } from '../../RelationshipEditor';
import { SelectboxProps } from '../../../../Selectbox/Selectbox';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import useMetadataSettingValue from '../../../../../../../@Api/Metadata/useMetadataSettingValue';
import { Setting } from '../../../../../../../@Api/Settings/Setting';
import useTypes from '../../../../Type/Api/useTypes';

export interface EntityToPipelineEditorProps extends RelationshipEditorProps
{

}

const EntityToPipelineEditor: React.FC<EntityToPipelineEditorProps> =
    props =>
    {
        const types = useTypes();
        const [ defaultPipelineNameSettingValue ] =
            useMetadataSettingValue<string>(
                types.Activity.SalesOpportunity.Type,
                Setting.Metadata.DefaultPipelineName
            );
        const [ defaultPipelineNameValue ] =
            useState(
                () =>
                    defaultPipelineNameSettingValue
                    ?? localizeText(
                        'Generic.Default',
                        'Standaard'
                    )
            );
        const selectboxProps =
            useMemo<Partial<SelectboxProps>>(
                () => ({
                    ...props.selectboxProps,
                    emptyOption: defaultPipelineNameValue,
                }),
                [
                    props.selectboxProps,
                    defaultPipelineNameValue
                ]
            );

        return <DefaultRelationshipEditor
            {...props}
            selectboxProps={selectboxProps}
        />;
    };

export default observer(EntityToPipelineEditor);
