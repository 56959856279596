import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { getModel } from '../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { isAttachedType } from '../../Api/getIsAttachedType';
import EntityTypeContext from '../../../../Type/EntityTypeContext';
import { default as InternalConstructor } from '../../../../Constructor/Constructor';
import { constructEntityFromPath } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityFromPath';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';

export interface ConstructorProps
{
    entity: Entity;
    path: EntityPath;
    inset?: boolean;
    onConstruct?: (entity: Entity) => void | Promise<any>;
    onAdd?: (entity: Entity) => void;
    onCancel?: () => void;
    autoFocus?: boolean;
}

const Constructor: React.FC<ConstructorProps> =
    props =>
    {
        const entityTypeStore = useContext(EntityTypeContext);

        const [ entity, setEntity ] = useState<Entity>();
        const { onConstruct } = props;
        const commitContext =
            useNewCommitContext(
                undefined,
                {
                    allowAutoCommit: false,
                },
                []
            );

        useEffect(
            () =>
            {
                commitContext.clear();
                const clear =
                    () =>
                        setEntity(undefined);

                if (props.path)
                {
                    const relatedEntity =
                        props.entity
                            ?
                                constructEntityFromPath(
                                    getModel(props.entity),
                                    props.path,
                                    commitContext
                                )
                            :
                                undefined;

                    const init =
                        () =>
                            setEntity(relatedEntity);

                    if (onConstruct && relatedEntity)
                    {
                        Promise.all([ onConstruct(relatedEntity) ])
                            .then(() => init())
                            .catch(
                                e =>
                                {
                                    clear();

                                    throw e;
                                }
                            );
                    }
                    else
                    {
                        init();
                    }
                }
                else
                {
                    clear();
                }
            },
            [
                props.path,
                props.entity,
                onConstruct,
                setEntity,
                commitContext,
            ]);

        const isOpenable =
            useComputed(
                () =>
                    entity &&
                        !isAttachedType(
                            entityTypeStore,
                            entity.entityType),
                [
                    entityTypeStore,
                    entity
                ]);

        const onSave =
            useCallback(
                (entity: Entity) =>
                {
                    setEntity(undefined);

                    if (props.onAdd)
                    {
                        props.onAdd(entity);
                    }
                },
                [
                    setEntity,
                    props.onAdd
                ]);

        const onClose =
            useCallback(
                () =>
                {
                    setEntity(undefined);

                    if (props.onCancel)
                    {
                        props.onCancel();
                    }
                },
                [
                    setEntity,
                    props.onCancel
                ]);

        const fromFieldPath =
            useMemo(
                () =>
                    props.path.field(),
                [
                    props.path
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            {
                entity &&
                    <ViewGroupItem>
                        <InternalConstructor
                            entity={entity}
                            onSave={onSave}
                            onClose={onClose}
                            openable={isOpenable}
                            autoFocus={props.autoFocus}
                            fromFieldPath={fromFieldPath}
                            commitContext={commitContext}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(Constructor);
