import React, { useCallback } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import useTypes from '../../../../../Type/Api/useTypes';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import CronEditor, { everyWeekOnMondayAt12Cron } from '../../../../../../../../@Future/Component/Generic/Input/CronEditor/CronEditor';
import { setValueByFieldInEntity } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import HoverCard from '../../../../../../../../@Future/Component/Generic/Card/HoverCard/HoverCard';

export interface CourseLessonCronPatternEditorProps
{
    entity: Entity;
    commitContext?: CommitContext;
}

const CourseLessonCronPatternEditor: React.FC<CourseLessonCronPatternEditorProps> =
    ({
         entity,
         commitContext,
     }) =>
    {
        const types = useTypes();
        const lessonCronPattern =
            useEntityValue(
                entity,
                types.Activity.Course.Field.LessonCronPattern,
                undefined,
                commitContext
            );
        const setLessonCronPattern =
            useCallback(
                (value: string) =>
                {
                    setValueByFieldInEntity(
                        entity,
                        types.Activity.Course.Field.LessonCronPattern,
                        value,
                        commitContext
                    );

                    if (commitContext?.getOptions().allowAutoCommit)
                    {
                        return commitContext.commit();
                    }
                },
                [
                    entity,
                    types,
                    commitContext,
                ]
            );

        return <Input
            label={types.Activity.Course.Field.LessonCronPattern.getName()}
            labelPosition="left"
        >
            {
                lessonCronPattern === undefined
                    ? <HoverCard
                        onClick={
                            () =>
                                setLessonCronPattern(everyWeekOnMondayAt12Cron)
                        }
                    >
                        <LocalizedText
                            code="Generic.Configure"
                            value="Instellen"
                        />
                    </HoverCard>
                    : <CronEditor
                        value={lessonCronPattern}
                        onChange={
                            lessonCronPattern =>
                                setLessonCronPattern(lessonCronPattern)
                        }
                    />
            }
        </Input>;
    };

export default observer(CourseLessonCronPatternEditor);
