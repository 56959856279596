import { DependencyList } from 'react';
import { useRoutingInterceptor } from './useRoutingInterceptor';

export function useOnBeforeRoute(
    callback: () => void,
    deps: DependencyList
)
{
    return useRoutingInterceptor(
        async () =>
        {
            callback();

            return true;
        },
        deps
    );
}