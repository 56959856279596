import React, { useEffect, useState } from 'react';
import GutenbergBlockType from '../../../Gutenberg/GutenbergBlockType';
import { AlignmentControl, BlockControls, InspectorControls, useBlockProps } from '@wordpress/block-editor';
import { GutenbergCoreBlockHeadingId } from '../../../DocumentEditor';
import StyledBlock from '../../StyledBlock/StyledBlock';
import BlockOptionsPanel from '../../Settings/BlockOptionsPanel';
import ColorOptionsPanel from '../../Settings/ColorOptionsPanel';
import RichtextEditor from '../../../../RichtextEditor/RichtextEditor';
import { getTextFromHtml } from '../../../../../../../../@Util/Html/HtmlUtils';
import HeadingLevelDropdown from './GutenbergCoreBlockHeadingLevelDropdown';
import Icon from '../../../../../Icon/Icon';
import uuid from '../../../../../../../../@Util/Id/uuid';

const GutenbergCoreBlockHeading: GutenbergBlockType =
{
    name: GutenbergCoreBlockHeadingId,

    configuration:
    {
        attributes: {
            "textAlign": {
                "type": "string"
            },
            "content": {
                "type": "string",
                "source": "html",
                "selector": "h1,h2,h3,h4,h5,h6",
                "default": "",
                "__experimentalRole": "content"
            },
            "level": {
                "type": "number",
                "default": 2
            },
            "placeholder": {
                "type": "string"
            }
        },
        supports: {
            "align": [ "wide", "full" ],
            "anchor": true,
            "className": false,
            "color": {
                "link": true
            },
            "typography": {
                "fontSize": true,
                "lineHeight": true,
                "__experimentalFontWeight": true
            },
            "__experimentalSelector": "h1,h2,h3,h4,h5,h6",
            "__unstablePasteTextInline": true,
            "__experimentalSlashInserter": true
        },
        icon: <Icon icon="title" />,
        category: 'common',
        title: {
            code: 'GutenbergBlock.Heading',
            value: 'Kopregel'
        },
        "description": "Introduce new sections and organize content to help visitors (and search engines) understand the structure of your content.",
        "keywords": [ "title", "subtitle" ],
        "textdomain": "default",
        edit: props =>
        {
            return <GutenbergCoreBlockHeadingEditor
                {...props}
            />;
        },

        save: (props) =>
        {
            return <p>
                {(props.attributes as any).content}
            </p>;
        }
    },

    view: block =>
    {
        const level = block.attributes.level;

        return <StyledBlock
            block={block}
        >
            <div
                style={{
                    color: block.attributes.customTextColor,
                    textAlign: block.attributes.align
                }}
            >
                {
                    React.createElement(
                        `h${level}`,
                        {
                            children:
                                <React.Fragment>
                                    {block.attributes.content}
                                </React.Fragment>
                        }
                    )
                }
            </div>
        </StyledBlock>;
    }
};

function GutenbergCoreBlockHeadingEditor(props: any)
{
    const blockProps = useBlockProps({
        style: props.style,
    });
    const [ content, setContent ] = useState<string>(() => `<h${props.attributes.level}>${props.attributes.content ?? ''}</h${props.attributes.level}`);
    useEffect(
        () =>
            setContent(`<h${props.attributes.level}>${(props.attributes.content ?? '').length > 0 ? props.attributes.content : ' '}</h${props.attributes.level}`),
        [
            props.attributes.level,
            setContent,
        ]
    );
    const [ id ] = useState(() => `editor_${uuid().replace(/-/g, '')}`);

    return <>
        <BlockControls
            group="block"
        >
            <HeadingLevelDropdown
                selectedLevel={props.attributes.level}
                onChange={
                    newLevel =>
                        props.setAttributes({ level: newLevel })
                }
            />
            <AlignmentControl
                value={props.attributes.textAlign}
                onChange={
                    nextAlign =>
                        props.setAttributes({ textAlign: nextAlign })
                }
            />
        </BlockControls>
        <InspectorControls>
            <BlockOptionsPanel props={props} />
            <ColorOptionsPanel props={props} />
        </InspectorControls>
        <div
            {...blockProps}
        >
            <StyledBlock
                block={props}
            >
                <style>
                    {
                        `.${id} .ql-editor {
                            text-align: ${props.attributes.textAlign ?? 'left'};
                        }`
                    }
                </style>
                <div
                    className={id}
                >
                    <RichtextEditor
                        value={content}
                        onChange={
                            (value: string) =>
                            {
                                setContent(value);
                                props.setAttributes({
                                    content: getTextFromHtml(value)
                                });
                            }
                        }
                    />
                </div>
            </StyledBlock>
        </div>
    </>;
}

export default GutenbergCoreBlockHeading;
