import * as React from 'react';
import { useCallback } from 'react';
import Divider from '../../Divider/Divider';

export default function useDividerGlue()
{
    return useCallback(
        () => <Divider />,
        [

        ]);
}
