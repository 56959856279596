import React from 'react';
import HoverCard, { HoverCardProps } from '../HoverCard/HoverCard';
import styles from './HoverCardMiddle.module.scss';
import { classNames } from '../../../../Util/Class/classNames';

const HoverCardMiddle: React.FC<HoverCardProps> =
    props =>
    {
        return <HoverCard
            {...props}
            className={classNames(styles.root, props.disabled && styles.disabled)}
        >
            {props.children}
        </HoverCard>;
    };

export default HoverCardMiddle;
