import * as React from 'react';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { ConstantComputationEditorStore } from './ConstantComputationEditorStore';
import { observer } from 'mobx-react';
import { Card, Grow } from '@material-ui/core';
import { DataObjectEditor } from '../../../DataObject/Editor/Value/Editor/DataObjectEditor';
import { PopperReference } from '../../../../Generic/Popper/PopperReference';
import { PopperContainer } from '../../../../Generic/Popper/PopperContainer';
import { PopperManager } from '../../../../Generic/Popper/PopperManager';
import Menu from '../../../../Generic/Menu/Menu';
import Button from '../../../../Generic/Button/Button';

@observer
export class ConstantComputationEditor extends BaseComponent<ConstantComputationEditorStore>
{
    renderComponent(store: ConstantComputationEditorStore)
    {
        return <div
            style={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <div
                style={{
                    flex: '1 1 auto'
                }}
            >
                {
                    store.constantEditorStore &&
                        <DataObjectEditor
                            store={store.constantEditorStore}
                        />
                }
            </div>
            <div>
                <PopperManager>
                    <PopperReference>
                        <Button store={store.editButtonStore} />
                    </PopperReference>
                    <PopperContainer
                        placement="bottom-start"
                        eventsEnabled={store.isTypeMenuOpen}
                        style={{zIndex: 10}}
                    >
                        {
                            store.isTypeMenuOpen &&
                                <Grow in={true}>
                                    <Card>
                                        <Menu store={store.typeSelectorStore} />
                                    </Card>
                                </Grow>
                        }
                    </PopperContainer>
                </PopperManager>
            </div>
        </div>;
    }
}
