import { BaseStore } from '../../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { ToolbarElementStore } from './Types/ToolbarElementStore';
import { ToolbarElementCommandStore } from './Types/ToolbarElementCommandStore';
// import { ComputationWidgetStore, ComputationWidgetStoreById } from '../Widget/ComputationWidgetStore';
import Quill from 'quill';
import { ButtonStore } from '../../Button/ButtonStore';

export class RichTextToolbarStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable quillEditor: any;
    @observable givenControls: Array<Array<ToolbarElementStore | ToolbarElementStore[]>>;
    @observable isVisible: () => boolean;
    @observable className: string;
    @observable isCollapsed: boolean;
    @observable collapseButtonStore: ButtonStore;

    // ------------------------ Constructor -------------------------

    constructor(quillEditor: any,
                controls: Array<Array<ToolbarElementStore | ToolbarElementStore[]>>,
                isVisible: () => boolean = () => true,
                className?: string)
    {
        super();

        this.givenControls = controls;
        this.quillEditor = quillEditor;
        this.isVisible = isVisible;
        this.className = className;
        this.isCollapsed = true;

        // Set all command callbacks to toolbar
        this.allControls
            .filter(control => control instanceof ToolbarElementCommandStore)
            .forEach(control => {
                (control as ToolbarElementCommandStore).executeCallback = this.executeCallback;
                (control as ToolbarElementCommandStore).setFormat = this.setFormat;
            });

        this.quillEditor.on((Quill as any).events.EDITOR_CHANGE, (type: any, range: any) =>
        {
            if (type === (Quill as any).events.SELECTION_CHANGE)
            {
                this.update(range);
            }
        });

        this.quillEditor.on((Quill as any).events.SCROLL_OPTIMIZE, () =>
        {
            let [range, ] = this.quillEditor.selection.getRange();
            this.update(range);
        });

        this.collapseButtonStore =
            new ButtonStore({
                onClick: () =>
                    this.isCollapsed ? this.expand() : this.collapse(),
                iconButtonSize: 16,
                iconSize: 14,
                icon: 'format_color_text'
            });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get controls(): Array<Array<ToolbarElementStore | ToolbarElementStore[]>>
    {
        if (this.isCollapsed)
        {
            return this.nonCollapsibleControls;
        }
        else
        {
            return this.givenControls;
        }
    }

    @computed
    get hasCollapsibleControls(): boolean
    {
        return !!this.givenControls
            .find(
                row =>
                    row.find(
                        elementOrArray =>
                            elementOrArray instanceof ToolbarElementStore
                                ? elementOrArray.collapsible
                                : !!(elementOrArray as ToolbarElementStore[])
                                    .find(element => element.collapsible)));
    }

    @computed
    get nonCollapsibleControls(): Array<Array<ToolbarElementStore | ToolbarElementStore[]>>
    {
        return this.givenControls
            .map(
                row =>
                    row.map(
                        elementOrArray =>
                        {
                            if (elementOrArray instanceof ToolbarElementStore)
                            {
                                return (elementOrArray as ToolbarElementStore).collapsible ? undefined : elementOrArray;
                            }
                            else
                            {
                                return (elementOrArray as ToolbarElementStore[]).filter(element => !element.collapsible);
                            }
                        })
                        .filter(
                            elementOrArray =>
                            {
                                if (elementOrArray === undefined)
                                {
                                    return false;
                                }
                                else if (elementOrArray instanceof ToolbarElementStore)
                                {
                                    return true;
                                }
                                else
                                {
                                    return (elementOrArray as ToolbarElementStore[]).length > 0;
                                }
                            })
                        ).filter(
                            row =>
                                row.length > 0);
    }

    @computed
    get allControls(): ToolbarElementStore[]
    {
        let allControls: ToolbarElementStore[] = [];

        let findControls = (destination: ToolbarElementStore[], controls: Array<ToolbarElementStore | ToolbarElementStore[]>) =>
        {
            controls.map(control =>
                control instanceof ToolbarElementStore
                    ? destination.push(control)
                    : findControls(destination, control));
        };

        this.givenControls.forEach(
            line =>
                findControls(allControls, line));

        return allControls;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    update(range: any)
    {
        let formats = range == null ? {} : this.quillEditor.getFormat(range);

        this.allControls
            .filter(control => control instanceof ToolbarElementCommandStore)
            .forEach(control =>
        {
            let element = control as ToolbarElementCommandStore;

            if (element.receiveAllEvents)
            {
                element.passEvent(formats);
            }
            else if (element.command)
            {
                let dividerPos = element.command.indexOf('.');

                if (dividerPos > 0) // Don't accept commands that start with a dot
                {
                    let type = element.command.substring(0, dividerPos);
                    let value = element.command.substring(dividerPos + 1);

                    let isActive = (value.length === 0 && !formats[type])
                        ? true
                        : formats[type] && formats[type] === value;

                    element.setActive(isActive);
                }
                // Switches like: bold, italic, underline, strike (values: boolean on/off)
                else
                {
                    element.setActive(formats[element.command] ? formats[element.command] : false);
                }
            }
        });
    }

    @action.bound
    linkHandler()
    {
        const range = this.quillEditor.getSelection(true);
        const text = this.quillEditor.getText(range.index, range.length);
        this.quillEditor.format('link', text, 'user');
    }

    @action.bound
    executeCallback(command: string, isActive: boolean)
    {
        if (command)
        {
            let dividerPos = command.indexOf('.');

            if (dividerPos > 0) // Don't accept commands that start with a dot
            {
                let type = command.substring(0, dividerPos);
                let value = command.substring(dividerPos + 1);
                this.quillEditor.format(type, isActive ? undefined : value, 'user');
            }
            else
            {
                switch (command)
                {
                    case 'link':
                        this.linkHandler();
                        break;
                    default:
                        this.quillEditor.format(command, !isActive, 'user');
                        break;
                }
            }
        }
    }

    @action.bound
    setFormat(command: string, value: any)
    {
        this.quillEditor.format(command, value, 'user');
    }

    @action.bound
    collapse()
    {
        this.isCollapsed = true;
    }

    @action.bound
    expand()
    {
        this.isCollapsed = false;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
