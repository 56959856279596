import React, { useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { User } from '../../../../../@Api/Model/Implementation/User';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import Item from '../Item/Item';
import Description from './Description/Description';
import Avatar from './Avatar/Avatar';
import { EntityRelationshipMutation } from '../../../../../@Api/Model/Implementation/EntityRelationshipMutation';
import { EntityCreationMutation } from '../../../../../@Api/Model/Implementation/EntityCreationMutation';
import equalsEntity from '../../../../../@Api/Entity/Bespoke/equalsEntity';
import ViewerEntityContext from '../../Viewer/Context/ViewerEntity/ViewerEntityContext';
import HighlightedEntityContext from '../../Item/HighlightContext/HighlightedEntityContext';
import { default as EntityItem } from '../Item/Entity/Entity';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import { groupBy } from '../../../../../@Util/MapUtils/groupBy';
import styles from './Group.module.scss';
import useDividerGlue from '../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import Icon from '../../../../../@Future/Component/Generic/Icon/Icon';
import { textSecondaryColor } from '../../../../../@Resource/Theme/Theme';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import useTypes from '../../Type/Api/useTypes';
import isSystemEvent from '../Item/Api/isSystemEvent';
import { classNames } from '../../../../../@Future/Util/Class/classNames';
import { default as GenericAvatar } from '../../../../../@Future/Component/Generic/Avatar/Avatar';
import Divider from '../../../../../@Future/Component/Generic/Divider/Divider';

export interface GroupProps
{
    entity: Entity;
    events: EntityEvent[];
    rootEntity?: Entity;
    user?: User;
}

const iconSize = 15;
const iconColor = textSecondaryColor;

const Group: React.FC<GroupProps> =
    props =>
    {
        const filteredEvents =
            useMemo(
                () =>
                    props.events.filter(
                        event =>
                            !(event instanceof EntityCreationMutation)),
                [
                    props.events
                ]);

        const [ addEvents, updateEvents ] =
            useMemo(
                () =>
                {
                    const addEvents: EntityRelationshipMutation[] = [];
                    const updateEvents: EntityEvent[] = [];

                    for (const event of filteredEvents)
                    {
                        if (event instanceof EntityRelationshipMutation
                            && event.toRelatedEntity
                            && event.entityRelationshipDefinition.isPlural(event.isParentRelationship))
                        {
                            if (!equalsEntity(props.entity, event.toRelatedEntity))
                            {
                                addEvents.push(event);
                            }
                        }
                        else
                        {
                            updateEvents.push(event);
                        }
                    }

                    return [ addEvents, updateEvents ];
                },
                [
                    props.entity,
                    filteredEvents,
                ]);

        const [ fieldUpdateEvents, systemUpdateEvents ] =
            useMemo(
                () =>
                {
                    const fieldUpdateEvents: EntityEvent[] = [];
                    const systemUpdateEvents: EntityEvent[] = [];

                    for (const event of updateEvents)
                    {
                        if (isSystemEvent(event))
                        {
                            systemUpdateEvents.push(event);
                        }
                        else
                        {
                            fieldUpdateEvents.push(event);
                        }
                    }

                    return [ fieldUpdateEvents, systemUpdateEvents ];
                },
                [
                    updateEvents
                ]);

        const addEventsByType =
            useMemo(
                () =>
                    groupBy(
                        addEvents,
                        event =>
                            event.toRelatedEntity.entityType),
                [
                    addEvents
                ]);

        const addEventTypes =
            useMemo(
                () =>
                    Array.from(addEventsByType.keys()),
                [
                    addEventsByType
                ]);

        const addedEntities = useMemo(() => [], []);
        const viewerEntity = useContext(ViewerEntityContext);
        const isEntitySameAsViewerEntity =
            useMemo(
                () =>
                    equalsEntity(
                        props.entity,
                        viewerEntity),
                [
                    props.entity,
                    viewerEntity
                ]);

        const dividerGlue = useDividerGlue();
        const types = useTypes();

        const isNested =
            useMemo(
                () =>
                    !isEntitySameAsViewerEntity,
                [
                    isEntitySameAsViewerEntity
                ]);
        const eventSection =
            useMemo(
                () =>
                    <div
                        className={isNested ? styles.nestedCard : undefined}
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                            glue={dividerGlue}
                        >
                            {
                                addEventTypes.map(
                                    type =>
                                        <ViewGroupItem
                                            key={type.id}
                                            className={styles.cardSection}
                                        >
                                            <div
                                                className={styles.icon}
                                            >
                                                <Icon
                                                    icon={type.inheritedIcon}
                                                    size={iconSize}
                                                    color={iconColor}
                                                />
                                            </div>
                                            <ViewGroup
                                                orientation="vertical"
                                                spacing={0}
                                                glue={dividerGlue}
                                            >
                                                {
                                                    addEventsByType.get(type)
                                                        .map(
                                                            event =>
                                                                <ViewGroupItem
                                                                    key={event.id}
                                                                >
                                                                    <EntityItem
                                                                        entity={event.toRelatedEntity}
                                                                        event={event}
                                                                        rootEntity={props.rootEntity}
                                                                        nested
                                                                    />
                                                                </ViewGroupItem>)
                                                }
                                            </ViewGroup>
                                        </ViewGroupItem>)
                            }
                            {
                                fieldUpdateEvents.length > 0 &&
                                    <ViewGroupItem
                                        className={styles.cardSection}
                                    >
                                        <div
                                            className={styles.icon}
                                        >
                                            <Icon
                                                icon="update"
                                                size={iconSize}
                                                color={iconColor}
                                            />
                                        </div>
                                        <CardInset
                                            vertical
                                            horizontal={false}
                                        >
                                            <ViewGroup
                                                orientation="vertical"
                                                spacing={3}
                                            >
                                                {
                                                    fieldUpdateEvents
                                                        .map(
                                                            event =>
                                                                <ViewGroupItem
                                                                    key={event.id}
                                                                >
                                                                    <Item
                                                                        event={event}
                                                                        showMutator={addEvents.length === 0 && updateEvents.length === 1}
                                                                    />
                                                                </ViewGroupItem>)
                                                }
                                            </ViewGroup>
                                        </CardInset>
                                    </ViewGroupItem>
                            }
                            {
                                systemUpdateEvents.length > 0 &&
                                    <ViewGroupItem
                                        className={styles.cardSection}
                                    >
                                        <div
                                            className={styles.icon}
                                        >
                                            <Icon
                                                icon="computer"
                                                size={iconSize}
                                                color={iconColor}
                                            />
                                        </div>
                                        <CardInset
                                            vertical
                                            horizontal={false}
                                        >
                                            <ViewGroup
                                                orientation="vertical"
                                                spacing={3}
                                            >
                                                {
                                                    systemUpdateEvents
                                                        .map(
                                                            event =>
                                                                <ViewGroupItem
                                                                    key={event.id}
                                                                >
                                                                    <Item
                                                                        event={event}
                                                                    />
                                                                </ViewGroupItem>)
                                                }
                                            </ViewGroup>
                                        </CardInset>
                                    </ViewGroupItem>
                            }
                        </ViewGroup>
                    </div>,
                [
                    addEvents.length,
                    props.rootEntity,
                    isNested,
                    dividerGlue,
                    addEventTypes,
                    addEventsByType,
                    updateEvents,
                    fieldUpdateEvents,
                    systemUpdateEvents
                ]);

        const isNote =
            useMemo(
                () =>
                    props.entity.entityType.isA(types.Note.Type),
                [
                    props.entity,
                    types
                ]);

        const cardClasses =
            useMemo(
                () =>
                    ({
                        root:
                            classNames(
                                styles.card,
                                isNote && styles.note)
                    }),
                [
                    isNote
                ]);

        const showItem =
            useComputed(
                () =>
                    !isEntitySameAsViewerEntity,
                    // props.entity.entityType.isA(types.Note.Type) ||
                    // props.entity.entityType.isA(types.Activity.Email.Type),
                [
                    isEntitySameAsViewerEntity,
                    // props.entity,
                    // types
                ]);

        return <div
            className={styles.root}
        >
            <div
                className={styles.icon}
            >
                <GenericAvatar
                    icon={props.entity.entityType.getInheritedIcon()}
                    color="rgb(219, 219, 219)"
                    size={20}
                    iconSize={11}
                />
            </div>
            <div
                className={styles.avatar}
            >
                <Avatar
                    entity={props.entity}
                    user={props.user}
                    rootEntity={props.rootEntity}
                />
            </div>
            <div
                className={styles.data}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    {
                        showItem &&
                            <ViewGroupItem>
                                <HighlightedEntityContext.Provider
                                    value={addedEntities}
                                >
                                    <div
                                        className={styles.description}
                                    >
                                        <Description
                                            entity={props.entity}
                                            events={props.events}
                                            addEventsByType={addEventsByType}
                                            updateEvents={updateEvents}
                                        />
                                    </div>
                                    <Card
                                        classes={cardClasses}
                                    >
                                        <EntityItem
                                            entity={props.entity}
                                            event={props.events[0]}
                                            rootEntity={props.rootEntity}
                                        />
                                        <Divider />
                                        <div
                                            className={styles.nestedCard}
                                        >
                                            {eventSection}
                                        </div>
                                    </Card>
                                </HighlightedEntityContext.Provider>
                            </ViewGroupItem>
                    }
                    {
                        !showItem &&
                            <ViewGroupItem>
                                <div
                                    className={styles.rootCard}
                                >
                                    {
                                        (updateEvents.length > 1 || props.events[0] instanceof EntityCreationMutation) &&
                                        <div
                                            className={styles.cardSection}
                                        >
                                            <Item
                                                event={props.events[0]}
                                                forceEntityDescription
                                                large
                                            />
                                        </div>
                                    }
                                    <div
                                        className={styles.nestedRootCard}
                                    >
                                        {eventSection}
                                    </div>
                                </div>
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </div>
        </div>;
    };

export default observer(Group);
