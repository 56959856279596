import React from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../../../../Type/Api/useTypes';
import Input from '../../../../../../../../../../Input/Input';
import Base from '../../Base/Base';
import getPhaseRelationshipDefinition from '../../../../../../../../../../../../../@Api/Entity/Bespoke/Datastore/Phase/getPhaseRelationshipDefinition';
import { ContentProps } from '../../Content';

export interface ProjectProps extends ContentProps
{

}

const Project: React.FC<ProjectProps> =
    props =>
    {
        const types = useTypes();

        const fields = [
            <Input
                key={0}
                entity={props.entity}
                field={getPhaseRelationshipDefinition(props.entity.entityType)}
                labelPosition={props.labelPosition}
                inline={props.inlineLabel}
                commitContext={props.commitContext}
            />,
            <Input
                key={1}
                entity={props.entity}
                field={types.Activity.Project.Field.Budget}
                labelPosition={props.labelPosition}
                inline={props.inlineLabel}
                commitContext={props.commitContext}
            />
        ];

        return <Base
            {...props}
            open
        >
            {fields}
        </Base>;
    };

export default observer(Project);
