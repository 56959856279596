import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { PortalListLayoutEditorProps } from './PortalListLayoutEditor';
import Layout from '../../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';
import ChildLayoutEditor from '../../../Editor/Child/ChildLayoutEditor';

export interface PortalListLayoutItemLayoutEditorProps extends PortalListLayoutEditorProps
{

}

const PortalListLayoutItemLayoutEditor: React.FC<PortalListLayoutItemLayoutEditorProps> =
    props =>
    {
        const { layout, parameterDictionary } = props;

        const setItemLayout =
            useCallback(
                (itemLayout: Layout) =>
                    runInAction(
                        () =>
                            layout.itemLayout = itemLayout),
                [
                    layout
                ]);

        const layoutItemParameters =
            useComputed(
                () =>
                    ParameterDictionary.union(
                        parameterDictionary,
                        layout.dataSourceSignature.resultParameters,
                        new ParameterDictionary([ layout.itemParameter ])),
                [
                    parameterDictionary,
                    layout
                ]);

        return <ChildLayoutEditor
            {...props}
            parameterDictionary={layoutItemParameters}
            childLayout={layout.itemLayout}
            onChange={setItemLayout}
        />;
    };

export default observer(PortalListLayoutItemLayoutEditor);
