import { DataObjectType } from '../../../@Component/Domain/DataObject/Model/DataObjectType';
import { NumberType } from '../../../@Component/Domain/DataObject/Type/Number/NumberType';
import { TextType } from '../../../@Component/Domain/DataObject/Type/Text/TextType';
import { RichTextType } from '../../../@Component/Domain/DataObject/Type/RichText/RichTextType';
import { LocalizedTextType } from '../../../@Component/Domain/DataObject/Type/LocalizedText/LocalizedTextType';

export function isPrimitiveTypeSearchable(type: DataObjectType)
{
    return type instanceof NumberType
        || type instanceof TextType
        || type instanceof RichTextType
        || type instanceof LocalizedTextType;
}
