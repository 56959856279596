import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { InputProps as StandardInputProps } from '@material-ui/core/Input';
import useTypes from '../../../../../@Component/Domain/Entity/Type/Api/useTypes';
import useResults from '../../../../../@Component/Domain/Entity/Selection/Hooks/useResults';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import TranslationEditor from './TranslationEditor/TranslationEditor';
import { createNumberComparator } from '../../../../../@Component/Generic/List/V2/Comparator/NumberComparator';
import LocalizationContext from '../../../../../@Service/Localization/LocalizationContext';
import useSwitch from '../../../../../@Util/Switch/useSwitch';
import Popper from '../../Popper/Popper';
import Card from '../../Card/Card';
import { LocalizedTextValue } from '../../../../../@Api/Localization/LocalizedTextValue';

export interface LanguageWithLanguageCode
{
    idx: number;
    entity: Entity;
    code: string;
}

export interface LocalizedTextEditorProps
{
    value?: LocalizedTextValue;
    onChange?: (value?: LocalizedTextValue) => void;
    placeholder?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    inputProps?: StandardInputProps['inputProps'];
    className?: string;
    disableUnderline?: boolean;
    outlined?: boolean;
    multiline?: boolean;
    error?: boolean;
    fitContent?: boolean;
    inputStyle?: any;
}

const LocalizedTextEditor: React.FC<LocalizedTextEditorProps> =
    props =>
    {
        const types = useTypes();
        const localizationStore = useContext(LocalizationContext);
        const { value, onChange} = props;

        const languages = useResults(types.Datastore.Language.Type, () => {}, []);
        const languagesWithCode =
            useMemo<LanguageWithLanguageCode[]>(
                () =>
                    (languages || [])
                        .filter(
                            language =>
                                language.hasValueForField(types.Datastore.Field.Code))
                        .map(
                            language => ({
                                idx: language.getObjectValueByField(types.Datastore.Field.Code).toLowerCase() === localizationStore.languageCode.toLowerCase() ? -1 : 1,
                                entity: language,
                                code: language.getObjectValueByField(types.Datastore.Field.Code).toLowerCase()
                            }))
                        .sort(createNumberComparator(data => data.idx)),
                [
                    languages,
                    types,
                    localizationStore.languageCode
                ]);

        const setTranslationValue =
            useCallback(
                (languageCode: string, translationValue?: string) =>
                {
                    const newValue = {
                        ...value
                    };

                    if (translationValue)
                    {
                        newValue[languageCode] = translationValue;
                    }
                    else
                    {
                        delete newValue[languageCode];
                    }

                    if (Object.keys(newValue).length === 0)
                    {
                        onChange(undefined);
                    }
                    else
                    {
                        onChange(newValue);
                    }
                },
                [
                   value,
                   onChange
                ]);

        const [ isExpanded, expand, collapse ] = useSwitch(false);
        const firstLanguageWithCode =
            useMemo(
                () =>
                    languagesWithCode.find(() => true),
                [
                    languagesWithCode
                ]);
        const remainingLanguagesWithCode =
            useMemo(
                () =>
                    languagesWithCode.slice(1),
                [
                    languagesWithCode
                ]);

        if (languagesWithCode.length === 0)
        {
            return null;
        }
        else
        {
            return <Popper
                reference={
                    <TranslationEditor
                        language={firstLanguageWithCode.entity}
                        languageCode={firstLanguageWithCode.code}
                        value={props.value ? props.value[firstLanguageWithCode.code] : undefined}
                        onChange={setTranslationValue}
                        disabled={props.disabled}
                        inputProps={props.inputProps}
                        disableUnderline={props.disableUnderline}
                        outlined={props.outlined}
                        multiline={props.multiline}
                        error={props.error}
                        fitContent={props.fitContent}
                        inputStyle={props.inputStyle}
                        onExpand={expand as any}
                        expanded={isExpanded}
                        autoFocus={props.autoFocus}
                        placeholder={props.placeholder}
                        onFlagClick={expand}
                    />
                }
                popper={
                    <Card
                        inset
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={5}
                        >
                            {/*<ViewGroupItem*/}
                            {/*    key={firstLanguageWithCode.entity.uuid}*/}
                            {/*>*/}
                            {/*    <TranslationEditor*/}
                            {/*        language={firstLanguageWithCode.entity}*/}
                            {/*        languageCode={firstLanguageWithCode.code}*/}
                            {/*        value={props.value ? props.value[firstLanguageWithCode.code] : undefined}*/}
                            {/*        onChange={setTranslationValue}*/}
                            {/*        disabled={props.disabled}*/}
                            {/*        inputProps={props.inputProps}*/}
                            {/*        disableUnderline={props.disableUnderline}*/}
                            {/*        outlined={props.outlined}*/}
                            {/*        multiline={props.multiline}*/}
                            {/*        error={props.error}*/}
                            {/*        fitContent={props.fitContent}*/}
                            {/*        inputStyle={props.inputStyle}*/}
                            {/*        onExpand={expand as any}*/}
                            {/*        expanded={isExpanded}*/}
                            {/*    />*/}
                            {/*</ViewGroupItem>*/}
                            {
                                remainingLanguagesWithCode.map(
                                    language =>
                                        <ViewGroupItem
                                            key={language.entity.uuid}
                                        >
                                            <TranslationEditor
                                                language={language.entity}
                                                languageCode={language.code}
                                                value={props.value ? props.value[language.code] : undefined}
                                                onChange={setTranslationValue}
                                                disabled={props.disabled}
                                                inputProps={props.inputProps}
                                                disableUnderline={props.disableUnderline}
                                                outlined={props.outlined}
                                                multiline={props.multiline}
                                                error={props.error}
                                                fitContent={props.fitContent}
                                                inputStyle={props.inputStyle}
                                            />
                                        </ViewGroupItem>)
                            }
                        </ViewGroup>
                    </Card>
                }
                open={isExpanded}
                onClose={collapse}
            />;

            // return <ViewGroup
            //     orientation="vertical"
            //     spacing={5}
            // >
            //     <ViewGroupItem
            //         key={firstLanguageWithCode.entity.uuid}
            //     >
            //         <TranslationEditor
            //             language={firstLanguageWithCode.entity}
            //             languageCode={firstLanguageWithCode.code}
            //             value={props.value ? props.value[firstLanguageWithCode.code] : undefined}
            //             onChange={setTranslationValue}
            //             disabled={props.disabled}
            //             inputProps={props.inputProps}
            //             disableUnderline={props.disableUnderline}
            //             outlined={props.outlined}
            //             multiline={props.multiline}
            //             error={props.error}
            //             fitContent={props.fitContent}
            //             inputStyle={props.inputStyle}
            //             onExpand={expand as any}
            //             expanded={isExpanded}
            //         />
            //     </ViewGroupItem>
            //     {
            //         isExpanded && remainingLanguagesWithCode.map(
            //             language =>
            //                 <ViewGroupItem
            //                     key={language.entity.uuid}
            //                 >
            //                     <TranslationEditor
            //                         language={language.entity}
            //                         languageCode={language.code}
            //                         value={props.value ? props.value[language.code] : undefined}
            //                         onChange={setTranslationValue}
            //                         disabled={props.disabled}
            //                         inputProps={props.inputProps}
            //                         disableUnderline={props.disableUnderline}
            //                         outlined={props.outlined}
            //                         multiline={props.multiline}
            //                         error={props.error}
            //                         fitContent={props.fitContent}
            //                         inputStyle={props.inputStyle}
            //                     />
            //                 </ViewGroupItem>)
            //     }
            // </ViewGroup>;
        }
    };

export default observer(LocalizedTextEditor);
