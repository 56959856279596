import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import useTypes from '../../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import EntityTypeContext from '../../../../../Type/EntityTypeContext';
import HoverCardBottom from '../../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import constructEntity from '../../../../../../../../@Api/Entity/constructEntity';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useSetting from '../../../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../../../Setting/SettingStore';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import { Setting } from '../../../../../../../../@Api/Settings/Setting';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import Popper from '../../../../../../../../@Future/Component/Generic/Popper/Popper';
import Selectbox from '../../../../../Selectbox/Selectbox';
import RightAlignedButtonGroup from '../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SelectButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/SelectButton/SelectButton';
import useDividerGlue from '../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import useSwitch from '../../../../../../../../@Util/Switch/useSwitch';
import { EntitySelectionBuilder } from '../../../../../Selection/Builder/EntitySelectionBuilder';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import HoverCardMiddle from '../../../../../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import { CommitContextImpl } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContextImpl';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { constructEntityOfType } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { setValueByFieldInEntity } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import { EntityExpansionBuilder } from '../../../../../Selection/Builder/EntityExpansionBuilder';
import isHiddenType from '../../../../../../../../@Api/Metadata/EntityType/isHiddenType';

export interface ProductLinesProcessingOptionsProps
{
    relationship: Entity;
    entity: Entity;
    path: EntityPath;
    onClose?: () => void;
    milestone?: Entity;
    invoiceLines: Set<Entity>;
    onChangeInvoiceLines: (invoiceLines: Set<Entity>) => void;
}

const ProductLinesProcessingOptions: React.FC<ProductLinesProcessingOptionsProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);

        const invoiceLines = props.invoiceLines;
        const setInvoiceLines = props.onChangeInvoiceLines;

        const [ isSalesOrderEnabled ] =
            useSetting<boolean>(
                SettingSource.Organization,
                Setting.Invoicing.IsSalesOrderEnabled
            );
        const addInvoiceLinesToActivity =
            useCallback(
                async (
                    activity: Entity,
                    commitContext: CommitContext
                ) =>
                {
                    // Initialize invoice lines with InvoiceProductLine relationship
                    // This is necessary in case the product line is invoiced multiple times (e.g. in a sales opportunity)
                    const expansionBuilder =
                        new EntityExpansionBuilder(
                            types.ProductLine.Type,
                            Array.from(invoiceLines),
                            [
                                EntityPath.fromEntityType(types.ProductLine.Type)
                                    .joinTo(
                                        types.ProductLine.RelationshipDefinition.InvoiceProductLine,
                                        false
                                    )
                            ]
                        );
                    await expansionBuilder.expand();

                    // Add invoice lines to activity
                    invoiceLines.forEach(
                        lineToInvoice =>
                        {
                            const invoiceLine =
                                constructEntityOfType(
                                    types.ProductLine.Type,
                                    commitContext
                                );
                            updateRelationship(
                                lineToInvoice,
                                false,
                                types.ProductLine.RelationshipDefinition.InvoiceProductLine,
                                invoiceLine,
                                commitContext
                            );
                            setValueByFieldInEntity(
                                lineToInvoice,
                                types.ProductLine.Field.IsBilled,
                                true,
                                commitContext
                            );
                            [ true, false ]
                                .forEach(
                                    isParent =>
                                        lineToInvoice.getRelationships(isParent)
                                            .filter(
                                                relationship =>
                                                    relationship.definition !== props.path.lastJoinNode.relationshipDefinition
                                                    && relationship.definition !== types.Pack.RelationshipDefinition.Entities
                                                    && relationship.definition !== types.TimeRegistration.RelationshipDefinition.ProductLine
                                                    && relationship.definition !== types.ProductLine.RelationshipDefinition.BilledTimeRegistrations
                                                    && relationship.definition !== types.ProductLine.RelationshipDefinition.BilledMileageRegistrations
                                            )
                                            .forEach(
                                                relationship =>
                                                    updateRelationship(
                                                        invoiceLine,
                                                        isParent,
                                                        relationship.definition,
                                                        relationship.getEntity(isParent),
                                                        commitContext
                                                    )
                                            )
                                );
                            lineToInvoice.values
                                .forEach(
                                    value =>
                                        setValueByFieldInEntity(
                                            invoiceLine,
                                            value.field,
                                            value.dataObject.value,
                                            commitContext
                                        )
                                );
                            setValueByFieldInEntity(
                                invoiceLine,
                                types.Entity.Field.SortIndex,
                                lineToInvoice.sortIndex,
                                commitContext
                            );
                            updateRelationship(
                                invoiceLine,
                                true,
                                types.Activity.RelationshipDefinition.ProductLines,
                                activity,
                                commitContext
                            );
                        });

                    expansionBuilder.dispose();
                },
                [
                    invoiceLines,
                    types,
                ]);
        const createActivityWithLines =
            useCallback(
                async (entityType: EntityType) =>
                {
                    const commitContext = new CommitContextImpl({ allowAutoCommit: false });
                    const activity =
                        constructEntityOfType(
                            entityType,
                            commitContext
                        );

                    if (props.milestone)
                    {
                        updateRelationship(
                            activity,
                            true,
                            types.Milestone.RelationshipDefinition.Activities,
                            props.milestone,
                            commitContext
                        );
                    }

                    updateRelationship(
                        activity,
                        true,
                        types.Relationship.RelationshipDefinition.Activities,
                        props.entity.getRelatedEntityByDefinition(
                            true,
                            types.Relationship.RelationshipDefinition.Activities),
                        commitContext
                    );
                    updateRelationship(
                        activity,
                        true,
                        types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                        props.entity.getRelatedEntityByDefinition(
                            true,
                            types.Relationship.Person.Contact.RelationshipDefinition.Activities),
                        commitContext
                    );

                    // Before related to parent activity, because that copies product lines if there are none (see e.g. BespokeActivityInvoice#onRelate)
                    await addInvoiceLinesToActivity(
                        activity,
                        commitContext
                    );

                    updateRelationship(
                        activity,
                        true,
                        types.Activity.RelationshipDefinition.LinkedActivities,
                        props.entity,
                        commitContext
                    );
                    setValueByFieldInEntity(
                        activity,
                        types.Activity.Field.Subject,
                        props.entity.getObjectValueByField(types.Activity.Field.Subject),
                        commitContext
                    );
                    setValueByFieldInEntity(
                        activity,
                        types.Activity.Field.IsVatIncluded,
                        props.entity.getObjectValueByField(types.Activity.Field.IsVatIncluded),
                        commitContext
                    );

                    constructEntity(
                        activity.entityType,
                        undefined,
                        activity,
                        () =>
                            setInvoiceLines(new Set()),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        commitContext
                    );
                },
                [
                    types,
                    entityTypeStore,
                    invoiceLines,
                    props.milestone,
                    props.entity,
                    props.path,
                    setInvoiceLines,
                    addInvoiceLinesToActivity
                ]);

        const createInvoice =
            useCallback(
                () =>
                    createActivityWithLines(types.Activity.Invoice.Type),
                [
                    createActivityWithLines,
                    types
                ]);

        const createSalesOrder =
            useCallback(
                () =>
                    createActivityWithLines(types.Activity.SalesOrder.Type),
                [
                    createActivityWithLines,
                    types
                ]);

        const createSubscription =
            useCallback(
                () =>
                    createActivityWithLines(types.Activity.Subscription.Type),
                [
                    createActivityWithLines,
                    types
                ]);

        const [ isInvoiceSelectorOpen, openInvoiceSelector, closeInvoiceSelector ] = useSwitch(false);
        const [ isSubscriptionSelectorOpen, openSubscriptionSelector, closeSubscriptionSelector ] = useSwitch(false);
        const [ selectedActivity, setSelectedActivity ] = useState<Entity | undefined>(undefined);
        const invoiceSelections =
            useMemo(
                () =>
                    types.Activity.Invoice.Type
                        ?
                            [
                                EntitySelectionBuilder.build(
                                    isSalesOrderEnabled ? types.Activity.SalesOrder.Type || types.Activity.Invoice.Type : types.Activity.Invoice.Type,
                                    (builder, rootPath) =>
                                        builder
                                            .where(
                                                cb =>
                                                    cb.relatedToEntity(
                                                        rootPath.joinTo(
                                                            types.Relationship.RelationshipDefinition.Activities,
                                                            true),
                                                        props.relationship))
                                            .where(
                                                cb =>
                                                    cb.eq(
                                                        rootPath
                                                            .joinTo(
                                                                types.Activity.Invoice.RelationshipDefinition.Phase,
                                                                false)
                                                            .field(types.Datastore.Field.Code),
                                                        undefined,
                                                        types.Activity.Invoice.Phase.Concept)))
                            ]
                        :
                            [],
                [
                    types,
                    props.relationship,
                    isSalesOrderEnabled
                ]);
        const subscriptionSelections =
            useMemo(
                () =>
                    types.Activity.Subscription.Type
                        ?
                            [
                                EntitySelectionBuilder.build(
                                    types.Activity.Subscription.Type,
                                    (builder, rootPath) =>
                                        builder
                                            .where(
                                                cb =>
                                                    cb.relatedToEntity(
                                                        rootPath.joinTo(
                                                            types.Relationship.RelationshipDefinition.Activities,
                                                            true),
                                                        props.relationship)))
                            ]
                        :
                            [],
                [
                    types,
                    props.relationship
                ]);

        const addLinesToSelectedActivity =
            useCallback(
                async () =>
                {
                    const commitContext = new CommitContextImpl();
                    await addInvoiceLinesToActivity(
                        selectedActivity,
                        commitContext
                    );
                    return commitEntityWithContext(
                        selectedActivity,
                        commitContext
                    );
                },
                [
                    selectedActivity,
                    addInvoiceLinesToActivity,
                ]);

        const dividerGlue = useDividerGlue();

        return <ViewGroup
            orientation="vertical"
            spacing={0}
            glue={dividerGlue}
        >
            {
                !isSalesOrderEnabled && types.Activity.Invoice.Type &&
                    <ViewGroupItem>
                        <HoverCardMiddle
                            onClick={createInvoice}
                            disabled={invoiceLines.size === 0}
                        >
                            {
                                invoiceLines.size === 1
                                    ?
                                        <LocalizedText
                                            code="InvoiceEditor.CreateInvoice.Singular"
                                            value="Factureer 1 regel"
                                        />
                                    :
                                        <LocalizedText
                                            code="InvoiceEditor.CreateInvoice.Plural"
                                            value="Factureer ${numberOfInvoiceLines} regels"
                                            numberOfInvoiceLines={invoiceLines.size}
                                        />
                            }
                        </HoverCardMiddle>
                    </ViewGroupItem>
            }
            {
                isSalesOrderEnabled && types.Activity.SalesOrder.Type &&
                    <ViewGroupItem>
                        <HoverCardMiddle
                            onClick={createSalesOrder}
                            disabled={invoiceLines.size === 0}
                        >
                            {
                                invoiceLines.size === 1
                                    ?
                                        <LocalizedText
                                            code="InvoiceEditor.CreateSalesOrder.Singular"
                                            value="Maak een verkooporder aan voor 1 regel"
                                        />
                                    :
                                        <LocalizedText
                                            code="InvoiceEditor.CreateSalesOrder.Plural"
                                            value="Maak een verkooporder aan voor ${numberOfInvoiceLines} regels"
                                            numberOfInvoiceLines={invoiceLines.size}
                                        />
                            }
                        </HoverCardMiddle>
                    </ViewGroupItem>
            }
            {
                (types.Activity.Invoice.Type || types.Activity.SalesOrder.Type) &&
                    <ViewGroupItem>
                        <Popper
                            reference={
                                <HoverCardBottom
                                    onClick={openInvoiceSelector}
                                    disabled={invoiceLines.size === 0}
                                >
                                    {
                                        isSalesOrderEnabled
                                            ?
                                                <LocalizedText
                                                    code="InvoiceEditor.AddLinesToExistingSalesOrder"
                                                    value="Naar bestaande conceptorder"
                                                />
                                            :
                                                <LocalizedText
                                                    code="InvoiceEditor.AddLinesToExistingInvoice"
                                                    value="Naar bestaande conceptfactuur"
                                                />
                                    }

                                </HoverCardBottom>
                            }
                            popper={
                                <Card
                                    inset
                                >
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={15}
                                    >
                                        <ViewGroupItem>
                                            <Input
                                                label={isSalesOrderEnabled ? types.Activity.SalesOrder.Type?.getName() : types.Activity.Invoice.Type?.getName()}
                                                labelPosition="top"
                                            >
                                                <Selectbox
                                                    selections={invoiceSelections}
                                                    value={selectedActivity}
                                                    onChange={setSelectedActivity as any}
                                                    autoFocus
                                                />
                                            </Input>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <RightAlignedButtonGroup>
                                                <SelectButton
                                                    onClick={addLinesToSelectedActivity}
                                                />
                                            </RightAlignedButtonGroup>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </Card>
                            }
                            open={isInvoiceSelectorOpen}
                            onClose={closeInvoiceSelector}
                        />
                    </ViewGroupItem>
            }
            {
                types.Activity.Subscription.Type && !isHiddenType(types.Activity.Subscription.Type) &&
                    <ViewGroupItem>
                        <HoverCardBottom
                            onClick={createSubscription}
                            disabled={invoiceLines.size === 0}
                        >
                            <LocalizedText
                                code="InvoiceEditor.AddLinesToNewSubscription"
                                value="Naar nieuw abonnement"
                            />
                        </HoverCardBottom>
                    </ViewGroupItem>
            }
            {
                types.Activity.Subscription.Type && !isHiddenType(types.Activity.Subscription.Type) &&
                    <ViewGroupItem>
                        <Popper
                            reference={
                                <HoverCardBottom
                                    onClick={openSubscriptionSelector}
                                    disabled={invoiceLines.size === 0}
                                >
                                    <LocalizedText
                                        code="InvoiceEditor.AddLinesToExistingSubscription"
                                        value="Naar bestaand abonnement"
                                    />
                                </HoverCardBottom>
                            }
                            popper={
                                <Card
                                    inset
                                >
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={15}
                                    >
                                        <ViewGroupItem>
                                            <Input
                                                label={types.Activity.Subscription.Type.getName()}
                                                labelPosition="top"
                                            >
                                                <Selectbox
                                                    selections={subscriptionSelections}
                                                    value={selectedActivity}
                                                    onChange={setSelectedActivity as any}
                                                    autoFocus
                                                />
                                            </Input>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <RightAlignedButtonGroup>
                                                <SelectButton
                                                    onClick={addLinesToSelectedActivity}
                                                />
                                            </RightAlignedButtonGroup>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </Card>
                            }
                            open={isSubscriptionSelectorOpen}
                            onClose={closeSubscriptionSelector}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(ProductLinesProcessingOptions);
