import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import FieldItem from './FieldItem/FieldItem';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { EntityPath } from '../../../../Entity/Path/@Model/EntityPath';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import { Field } from './Model/Field';
import EntityTypeContext from '../../../../Entity/Type/EntityTypeContext';
import uuid from '../../../../../../@Util/Id/uuid';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import { createTransactionalModel, getModel, TransactionalModel } from '../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import getOwnFieldPaths from './Api/getOwnFieldPaths';
import HoverCardBottom from '../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import { Droppable } from 'react-beautiful-dnd';
import { createNumberComparator } from '../../../../../Generic/List/V2/Comparator/NumberComparator';
import useOnDragEnd from '../../../../DragAndDrop/Api/useOnDragEnd';
import { runInAction } from 'mobx';
import useSetting from '../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../Setting/SettingStore';
import getFieldOrderingInType from '../../../../../../@Api/Metadata/Field/getFieldOrderingInType';
import getNameFieldByType from '../../../../../../@Api/Metadata/Field/getNameFieldByType';
import FieldInput from '../../../../Multiplayer/Model/Input/FieldInput';
import RelationshipInput from '../../../../Multiplayer/Model/Input/RelationshipInput';
import resolveInputFromFieldPath from '../../../../Multiplayer/Model/Input/Api/resolveInputFromFieldPath';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import TabBar from '../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import styles from './FieldsEditor.module.scss';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Details from '../../../../Entity/Viewer/Shared/Details/Details';
import FieldGroupsEditor from '../FieldGroupsEditor/FieldGroupsEditor';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import { EntityExpansionBuilder } from '../../../../Entity/Selection/Builder/EntityExpansionBuilder';

export interface FieldsEditorProps
{
    entityType: EntityType;
    onAddField: (fieldPath: EntityFieldPath) => void;
}

const FieldsEditor: React.FC<FieldsEditorProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);
        const currentUserStore = useContext(CurrentUserContext);

        const rootPath =
            useComputed(
                () =>
                    EntityPath.fromEntityType(props.entityType),
                [
                    props.entityType
                ]);

        const fieldPaths =
            useComputed(
                () =>
                    getOwnFieldPaths(rootPath)
                        .filter(
                            fieldPath =>
                                fieldPath.isField
                                    || fieldPath.relationshipDefinition.isVisibleDuringConstruction(fieldPath.isParentRelationship)
                                    || fieldPath.relationshipDefinition.isVisibleInDetails(!fieldPath.isParentRelationship)
                        ),
                [
                    rootPath
                ]);

        const [ fields, setFields ] = useState<TransactionalModel<Field>[]>([]);

        const loadFields =
            useCallback(
                () =>
                    fieldPaths
                        .map(
                            fieldPath =>
                            {
                                const ownedPacks = [
                                    ...currentUserStore.rightProfile.ownedPacks,
                                    currentUserStore.rightProfile.environmentPack
                                ];

                                if (fieldPath.isField)
                                {
                                    const fieldPack =
                                        fieldPath.field.entity.getRelatedEntityByDefinition(
                                            true,
                                            types.Pack.RelationshipDefinition.Entities);

                                    return createTransactionalModel(
                                        new Field(
                                            fieldPath.id,
                                            fieldPath.field && fieldPath.field.entity && fieldPath.field.entity.sortIndex,
                                            fieldPath.field.nameDataObject.value,
                                            fieldPath.field.dataObjectSpecification.type,
                                            false,
                                            fieldPath.field.isRequired,
                                            fieldPath.field.isDefining,
                                            (getNameFieldByType(props.entityType) as FieldInput)?.field === fieldPath.field,
                                            ownedPacks
                                                .some(
                                                    pack =>
                                                        equalsEntity(
                                                            fieldPack,
                                                            pack)),
                                            fieldPack,
                                            fieldPath));
                                }
                                else
                                {
                                    const fieldPack =
                                        fieldPath.relationshipDefinition.entity.getRelatedEntityByDefinition(
                                            true,
                                            types.Pack.RelationshipDefinition.Entities);

                                    return createTransactionalModel(
                                        new Field(
                                            fieldPath.id,
                                            fieldPath.relationshipDefinition && fieldPath.relationshipDefinition.entity && fieldPath.relationshipDefinition.entity.sortIndex,
                                            fieldPath.relationshipDefinition.getNameDataObject(fieldPath.isParentRelationship).value,
                                            fieldPath.path.entityType,
                                            fieldPath.relationshipDefinition.isPlural(fieldPath.isParentRelationship),
                                            fieldPath.relationshipDefinition.isMandatory(fieldPath.isParentRelationship),
                                            fieldPath.relationshipDefinition.isVisibleDuringConstruction(fieldPath.isParentRelationship),
                                            (getNameFieldByType(props.entityType) as RelationshipInput)?.relationshipDefinition === fieldPath.relationshipDefinition,
                                            ownedPacks
                                                .some(
                                                    pack =>
                                                        equalsEntity(
                                                            fieldPack,
                                                            pack)),
                                            fieldPack,
                                            fieldPath));
                                }
                            }),
                [
                    fieldPaths,
                    currentUserStore,
                    entityTypeStore,
                    props.entityType,
                    types
                ]);

        useEffect(
            () =>
                setFields(loadFields()),
            [
                setFields,
                loadFields
            ]);

        const addField =
            useCallback(
                () =>
                {
                    setFields([
                        ...fields,
                        createTransactionalModel(
                            new Field(
                                uuid(),
                                fields.length + 1,
                                undefined,
                                undefined,
                                false,
                                false,
                                true,
                                fields.length === 0,
                                true,
                                currentUserStore.rightProfile.ownedEnvironmentPackOrAnyOwnedPack))
                    ]);
                },
                [
                    setFields,
                    fields,
                    currentUserStore
                ]);

        const onSaveField =
            useCallback(
                () =>
                {
                    const nameInput = getNameFieldByType(props.entityType);

                    runInAction(
                        () =>
                            fields.forEach(
                                field =>
                                {
                                    const isName = field.fieldPath ? resolveInputFromFieldPath(field.fieldPath)?.id() === nameInput?.id() : false;

                                    getModel(field).isName = isName;
                                    field.isName = isName;
                                }));
                },
                [
                    props.entityType,
                    fields
                ]);

        const cancelField =
            useCallback(
                (field: Field) =>
                {
                    if (!field.fieldPath)
                    {
                        setFields(
                            fields.filter(
                                checkField =>
                                    checkField !== field));
                    }
                },
                [
                    fields,
                    setFields
                ]);

        const droppableId = useMemo(() => uuid(), []);

        const [ fieldOrdering, setFieldOrdering ] =
            useSetting(
                SettingSource.Organization,
                'Metadata.FieldOrdering');

        const fieldOrderingInType =
            useComputed(
                () =>
                    getFieldOrderingInType(props.entityType),
                [
                    props.entityType
                ]);

        const sortedFields =
            useComputed(
                () =>
                    fields.slice()
                        .sort(
                            createNumberComparator(
                                d =>
                                    d.fieldPath
                                        ? fieldOrderingInType[d.fieldPath.id] ?? d.fieldPath.sortIndex
                                        : d.idx,
                                true
                            )
                        ),
                [
                    fields,
                    fieldOrderingInType
                ]);

        useOnDragEnd(
            useCallback(
                result =>
                {
                    if (result.destination?.droppableId === droppableId)
                    {
                        runInAction(
                            () =>
                            {
                                const field =
                                    sortedFields.find(
                                        field =>
                                            field.id === result.draggableId);

                                const fields =
                                    sortedFields.slice()
                                        .filter(
                                            checkField =>
                                                checkField !== field);

                                fields.splice(
                                    result.destination.index,
                                    0,
                                    field);

                                const newFieldOrdering = Object.assign({}, fieldOrdering);
                                const newTypeOrdering =
                                    fields
                                        .filter(
                                            field =>
                                                field.fieldPath !== undefined)
                                        .map(
                                            field =>
                                                field.fieldPath.descriptor);
                                newFieldOrdering[props.entityType.id] = newTypeOrdering;
                                setFieldOrdering(newFieldOrdering);
                            });
                    }
                },
                [
                    droppableId,
                    sortedFields,
                    props.entityType,
                    fieldOrdering,
                    setFieldOrdering
                ]));

        useAsyncResult(
            () =>
                Promise.all([
                    new EntityExpansionBuilder(
                        types.EntityField.Type,
                        fields
                            .map(
                                field =>
                                    field.entity)
                            .filter(
                                entity =>
                                    entity?.entityType.isA(types.EntityField.Type)),
                        [
                            EntityPath.root(types.EntityField.Type)
                                .joinTo(
                                    types.EntityFieldGroup.RelationshipDefinition.Fields,
                                    true)
                        ]).expand(),
                    new EntityExpansionBuilder(
                        types.EntityRelationshipDefinition.Type,
                        fields
                            .map(
                                field =>
                                    field.entity)
                            .filter(
                                entity =>
                                    entity?.entityType.isA(types.EntityRelationshipDefinition.Type)),
                        [
                            EntityPath.root(types.EntityField.Type)
                                .joinTo(
                                    types.EntityFieldGroup.RelationshipDefinition.Relationships,
                                    true)
                        ]).expand()
                ]),
            [
                types,
                fields
            ]);

        const showPackSelector =
            useComputed(
                () =>
                    currentUserStore.rightProfile.ownedPacks.length > 1,
                [
                    currentUserStore
                ]);

        const [ tab, setTab ] = useState(0);
        const dummyEntity =
            useMemo(
                () =>
                    createTransactionalModel(
                        new Entity(props.entityType)
                            .initialize()),
                [
                    props.entityType
                ]);

        return <>
            <TabBar
                value={tab}
            >
                <Tab
                    value={0}
                    onClick={setTab}
                >
                    <LocalizedText
                        code="Generic.Manage"
                        value="Beheren"
                    />
                </Tab>
                <Tab
                    value={1}
                    onClick={setTab}
                >
                    <LocalizedText
                        code="Generic.Groups"
                        value="Groepen"
                    />
                </Tab>
                {
                    currentUserStore.isSupport &&
                    <Tab
                        value={2}
                        onClick={setTab}
                    >
                        <LocalizedText
                            code="Generic.Design"
                            value="Ontwerpen"
                        />
                    </Tab>
                }
            </TabBar>
            {
                tab === 0 &&
                    <>
                        {
                            fields.length > 0 &&
                                <Droppable
                                    droppableId={droppableId}
                                    type="field"
                                >
                                    {(provided, snapshot) =>
                                        <Table
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        {...{
                                                            width: '1%'
                                                        }}
                                                    >

                                                    </TableCell>
                                                    <TableCell
                                                        {...{
                                                            width: '15%'
                                                        }}
                                                    >
                                                        <LocalizedText
                                                            code="Configuration.Field.Name"
                                                            value="Veldnaam"
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        {...{
                                                            width: '14%'
                                                        }}
                                                    >
                                                        <LocalizedText
                                                            code="Configuration.Field.DataType"
                                                            value="Datatype"
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        {...{
                                                            width: '10%'
                                                        }}
                                                    >
                                                        <LocalizedText
                                                            code="Configuration.Field.MultipleValues"
                                                            value="Meerdere waarden"
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        {...{
                                                            width: '10%'
                                                        }}
                                                    >
                                                        <LocalizedText
                                                            code="Configuration.Field.Mandatory"
                                                            value="Verplicht"
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        {...{
                                                            width: '10%'
                                                        }}
                                                    >
                                                        <LocalizedText
                                                            code="Configuration.Field.ShowAtEntityTypeCreation"
                                                            value="Tonen bij aanmaken ${entityTypeName}"
                                                            entityTypeName={props.entityType.getName().toLowerCase()}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        {...{
                                                            width: '10%'
                                                        }}
                                                    >
                                                        <LocalizedText
                                                            code="Configuration.Field.IsNameOfEntityType"
                                                            value="Is naam van ${entityTypeName}"
                                                            entityTypeName={props.entityType.getName().toLowerCase()}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        {...{
                                                            width: '10%'
                                                        }}
                                                    >
                                                        <LocalizedText
                                                            code="Configuration.Field.Hidden"
                                                            value="Verborgen"
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        {...{
                                                            width: '10%'
                                                        }}
                                                    >
                                                        <LocalizedText
                                                            code="Configuration.Field.FieldNameInAPI"
                                                            value="Veldnaam in API"
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        {...{
                                                            width: '10%'
                                                        }}
                                                    >
                                                        {types.EntityFieldGroup.Type.getName()}
                                                    </TableCell>
                                                    {
                                                        showPackSelector &&
                                                            <TableCell
                                                                {...{
                                                                    width: '10%'
                                                                }}
                                                            >
                                                                {types.Pack.Type.getName()}
                                                            </TableCell>
                                                    }
                                                    <TableCell>
                                                        <LocalizedText
                                                            code="Configuration.Field.Filter"
                                                            value="Filter"
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        {...{
                                                            width: '10%'
                                                        }}
                                                    >

                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    sortedFields.map(
                                                        (field, idx) =>
                                                            <FieldItem
                                                                key={field.id}
                                                                idx={idx}
                                                                entityType={props.entityType}
                                                                field={field}
                                                                onAddField={props.onAddField}
                                                                onSave={onSaveField}
                                                                onCancel={cancelField}
                                                                showPackSelector={showPackSelector}
                                                            />)
                                                }
                                                {provided.placeholder}
                                            </TableBody>
                                        </Table>
                                    }
                                </Droppable>
                        }
                        {
                            !fields.some(
                                field =>
                                    !field.fieldPath) &&
                                <HoverCardBottom
                                    onClick={addField}
                                >
                                    <LocalizedText
                                        code="Configuration.Field.AddButton"
                                        value="+ Veld toevoegen"
                                    />
                                </HoverCardBottom>
                        }
                    </>
            }
            {
                tab === 1 &&
                    <div>
                        <FieldGroupsEditor
                            entityType={props.entityType}
                        />
                    </div>
            }
            {
                tab === 2 &&
                <div
                    className={styles.designerContainer}
                >
                    <Centered
                        horizontal
                    >
                        <div
                            className={styles.designerCard}
                        >
                            <Details
                                defaultInEditMode
                                entity={dummyEntity}
                            />
                        </div>
                    </Centered>
                </div>
            }
        </>;
    };

export default observer(FieldsEditor);
