import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import ConditionalLayout from '../../../../../@Api/Layout/Type/ConditionalLayout';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';

export interface ConditionalLayoutViewerProps extends LayoutViewerProps<ConditionalLayout>
{

}

const ConditionalLayoutViewer: React.FC<ConditionalLayoutViewerProps> =
    props =>
    {
        const isVisible =
            useComputed(
                () =>
                {
                    try
                    {
                        return props.layout.predicate.synchronouslyEvaluate(
                            new FunctionContext(
                                props.parameterDictionary,
                                props.parameterAssignment,
                                props.commitContext
                            )
                        );
                    }
                    catch (e)
                    {
                        console.error('error while evaluating layout predicate', e);

                        return false;
                    }
                },
                [
                    props.layout,
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        if (isVisible)
        {
            return <LayoutViewer
                {...props}
                layout={props.layout.layout}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(ConditionalLayoutViewer);
