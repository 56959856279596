import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import EntityLink from '../../../../../../Link/EntityLink';
import useTypes from '../../../../../../Type/Api/useTypes';
import useEntityValue from '../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import useRelatedEntity from '../../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import styles from './Recipient.module.scss';

export interface RecipientProps
{
    recipient: Entity;
    idx?: number;
}

const Recipient: React.FC<RecipientProps> =
    props =>
    {
        const types = useTypes();

        const addressee =
            useRelatedEntity(
                props.recipient,
                types.Recipient.RelationshipDefinition.Addressee,
                false);

        const emailAddress =
            useEntityValue(
                props.recipient,
                types.Recipient.Email.Field.EmailAddress);

        return <span
            className={styles.root}
        >
            {props.idx > 0 ? ', ': ''} {addressee && <EntityLink entity={addressee} />} &lt;{emailAddress}&gt;
        </span>;
    };

Recipient.defaultProps = {
    idx: 0
};

export default observer(Recipient);
