import ParameterDictionary from '../../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Computation from '../../../../../../../../../@Api/Automation/Function/Computation/Computation';

export default function isComputationCompatibleWithParameterDictionary(computation: Computation<any, any>,
                                                                       parameterDictionary: ParameterDictionary)
{
    return computation.getDependencies()
        .every(
            element =>
                parameterDictionary.hasParameter(element.parameter));
}
