import { DependencyList, useCallback, useEffect, useMemo, useState } from 'react';
import { observable, runInAction } from 'mobx';
import { EntitySelectionBuilder } from '../Builder/EntitySelectionBuilder';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../Path/@Model/EntityPath';
import getPaginationResult, { Page, PaginationQuery } from '../Api/getPaginationResult';
import { useComputed } from 'mobx-react-lite';
import { useIsMounted } from '../../../../../@Util/Async/useIsMounted';

export const DefaultPageSize = 50;

interface PaginationQueryBuilder
{
    type: EntityType;
    callback: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void;
    pageSize?: number;
    isDisabled?: boolean;
}

export interface PaginationResultController
{
    pages: Page[];
    hasMore: boolean;
    loadMore: () => Promise<any>;
    isLoading: boolean;
}

export default function usePaginationResult(
    queryBuilder: PaginationQueryBuilder,
    deps: DependencyList
): PaginationResultController
{
    const { type, callback, pageSize = DefaultPageSize } = queryBuilder;
    const isMounted = useIsMounted();
    const query =
        useMemo<PaginationQuery>(
            () =>
            {
                const rootPath = EntityPath.fromEntityType(type);
                const builder = EntitySelectionBuilder.fromPath(rootPath);
                callback(builder, rootPath);
                const selection = builder.build();

                return {
                    type: queryBuilder.type,
                    pageIdx: 0,
                    pageSize: pageSize,
                    selection: selection,
                    ordering: builder.ordering.slice(),
                    isDisabled: queryBuilder.isDisabled,
                };
            },
            deps);

    const [ pageIdx, setPageIdx ] = useState(0);
    const [ isLoading, setLoading ] = useState(false);

    const [ pages ] =
        useState(
            () =>
                observable.array<Page>());

    const loadPage =
        useCallback(
            async (pageIdx: number) =>
            {
                if (query.isDisabled)
                {
                    return;
                }

                setLoading(true);
                setPageIdx(pageIdx);

                getPaginationResult({
                    ...query,
                    pageIdx
                }).then(
                    page =>
                        runInAction(
                            () =>
                            {
                                if (isMounted())
                                {
                                    pages.push(page);
                                    setLoading(false);
                                }
                            }));
            },
            [
                query,
                setPageIdx,
                setLoading,
                isMounted
            ]);

    const loadMore =
        useCallback(
            () =>
                loadPage(pageIdx + 1),
            [
                loadPage,
                pageIdx
            ]);

    const hasMore =
        useComputed(
            () =>
            {
                if (pages.length === 0)
                {
                    return true;
                }
                else
                {
                    return pages[pages.length - 1].numberOfRecords >= pageSize;
                }
            },
            [
                pages,
                pageSize
            ]);

    useEffect(
        () =>
        {
            const disposePages =
                () =>
                    pages.forEach(
                        page =>
                            page.dispose());

            runInAction(
                () =>
                {
                    disposePages();
                    pages.clear();
                });

            loadPage(0)
                 .then();

            return disposePages;
        },
        [
            pages,
            loadPage
        ]);

    return {
        pages,
        hasMore,
        isLoading,
        loadMore
    };
}
