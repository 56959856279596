import { reference, type } from '../../../@Util/Serialization/Serialization';
import { EntityRelationshipDefinition } from './EntityRelationshipDefinition';
import { EntityField } from './EntityField';
import { action, computed, IObservableArray, observable, ObservableMap } from 'mobx';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { AvatarType } from '../../../@Component/Generic/Avatar/AvatarStore';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityFieldPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import { registerBuilder } from '../../../@Util/TransactionalModelV2/Shared/TransactionalBuilder';
import { EntityFieldGroup } from '../../../@Component/Domain/Management/Application/EntityTypesEditor/EntityFieldGroup';
import { EntityAction } from './EntityAction';
import { Entity } from './Entity';
import { EntityFieldDependency } from './EntityFieldDependency';
import { createStringComparator } from '../../../@Component/Generic/List/V2/Comparator/StringComparator';
import { createNumberComparator } from '../../../@Component/Generic/List/V2/Comparator/NumberComparator';
import { BespokeEntityType } from '../../../@Component/Domain/Entity/Type/BespokeEntityType';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection';
import isHiddenType from '../../Metadata/EntityType/isHiddenType';
import getTypes from '../../../@Component/Domain/Entity/Type/Api/getTypes';

export function sortTypes(types: EntityType[],
                   entityTypeStore?: EntityTypeStore): EntityType[]
{
    return types
        .slice()
        .sort(createStringComparator(type => entityTypeStore ? type.getName() : type.nameSingular))
        .sort(createNumberComparator(type => type.entity?.sortIndex));
}

const relationshipDefinitionComparator =
    createNumberComparator<EntityRelationshipDefinition>(
        definition =>
            definition.sortIndex);

@type('EntityType')
export class EntityType
{
    // ------------------- Persistent Properties -------------------

    @observable id: number;
    @observable code: string;
    @observable isInstantiable: boolean;
    @observable isSwitchable: boolean;
    @observable isIdentity: boolean;
    @observable isQueueable: boolean;
    @observable isRoot: boolean;
    @observable isEmbedded: boolean;
    @observable isOwnable: boolean;
    @observable isAssignable: boolean;
    @observable isOrganizationIdentifiable: boolean;
    @observable isUserIdentifiable: boolean;
    @observable isUserInternalIdentifiable: boolean;
    @observable isUserExternalIdentifiable: boolean;
    @observable showInModuleSwitcher: boolean;
    @observable hasPicture: boolean;
    @observable hasFollowUp: boolean;
    @observable hasDuplicatePrevention: boolean;
    @reference(undefined, 'EntityType') @observable.ref parentType: EntityType;
    @reference(undefined, 'EntityType') @observable.shallow childTypes: EntityType[];
    @observable oldNameExpression: string;
    @observable oldCompactNameExpression: string;
    @observable oldSortNameExpression: string;
    @observable oldDescriptionExpression: string;
    @reference(undefined, 'EntityField') @observable.ref avatarField: EntityField;
    @reference(undefined, 'EntityField') @observable.ref nameField: EntityField;
    @reference(undefined, 'EntityField') @observable.ref compactNameField: EntityField;
    @reference(undefined, 'EntityField') @observable.ref sortNameField: EntityField;
    @reference(undefined, 'EntityField') @observable.ref descriptionField: EntityField;
    @observable.ref nameExpression: any;
    @observable.ref compactNameExpression: any;
    @observable.ref sortNameExpression: any;
    @observable.ref descriptionExpression: any;
    @reference(undefined, 'EntityRelationshipDefinition') @observable.shallow parentRelationshipDefinitions: EntityRelationshipDefinition[];
    @reference(undefined, 'EntityRelationshipDefinition') @observable.shallow childRelationshipDefinitions: EntityRelationshipDefinition[];
    @reference(undefined, 'EntityField') @observable fields: EntityField[];
    @reference(undefined, 'EntityAction') @observable actions: EntityAction[];
    @observable.ref form: any;
    @observable color: string;
    @observable icon: string;
    @observable sortIndex: number;
    @observable _nameSingular: string; // for static types
    @observable _namePlural: string; // for static types
    @reference(undefined, 'Entity') @observable.ref entity: Entity;
    @reference(undefined, 'EntityFieldDependency') @observable.shallow incomingDependencies: EntityFieldDependency[];
    @reference(undefined, 'Entity') @observable.shallow entities: Entity[];

    // ------------------------- Properties -------------------------

    @observable.shallow fieldById: ObservableMap<number, EntityField>;
    @observable.shallow fieldByCode: ObservableMap<string, EntityField>;
    @observable.ref bespoke: BespokeEntityType;

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    @action
    initialize(entityTypeStore: EntityTypeStore,
               doInitializeEntity: boolean = true)
    {
        if (this.parentType)
        {
            const parentType = entityTypeStore.getTypeById(this.parentType.id);

            if (parentType != null)
            {
                this.parentType = parentType;
            }
        }

        if (!this.fieldById)
        {
            this.fieldById = observable.map();
        }

        if (!this.fieldByCode)
        {
            this.fieldByCode = observable.map();
        }

        if (!this.fields)
        {
            this.fields = observable.array<EntityField>();
        }

        if (!this.parentRelationshipDefinitions)
        {
            this.parentRelationshipDefinitions = observable.array();
        }

        if (!this.childRelationshipDefinitions)
        {
            this.childRelationshipDefinitions = observable.array();
        }

        if (!this.childTypes)
        {
            this.childTypes = observable.array<EntityType>();
        }

        // Sort child types
        this.childTypes = observable.array(sortTypes(this.childTypes, entityTypeStore));

        // Sort fields
        this.fields =
            observable.array(
                this.fields.slice()
                    .sort(
                        (a, b) =>
                            (a.sortIndex < b.sortIndex
                                ?
                                    -1
                                :
                                    (a.sortIndex === b.sortIndex ? 0 : 1))));

        // Sort relationship definitions
        [ true, false ]
            .forEach(
                isParent =>
                    (this.getRelationshipDefinitions(isParent) as IObservableArray)
                        .replace(
                            this.getRelationshipDefinitions(isParent)
                                .slice()
                                .sort(relationshipDefinitionComparator)));

        this.fields.forEach(
            field =>
            {
                field.initialize(this, entityTypeStore, false);

                if (!field.isStaticField())
                {
                    this.fieldById.set(
                        field.id,
                        field);
                }

                this.fieldByCode.set(
                    field.code,
                    field);
            });

        // Initialize name/description fields
        if (this.avatarField)
        {
            this.avatarField = entityTypeStore.getFieldById(this.avatarField.id);
        }

        if (this.nameField)
        {
            this.nameField = entityTypeStore.getFieldById(this.nameField.id);
        }

        if (this.compactNameField)
        {
            this.compactNameField = entityTypeStore.getFieldById(this.compactNameField.id);
        }

        if (this.sortNameField)
        {
            this.sortNameField = entityTypeStore.getFieldById(this.sortNameField.id);
        }

        if (this.descriptionField)
        {
            this.descriptionField = entityTypeStore.getFieldById(this.descriptionField.id);
        }

        if (this.entity && doInitializeEntity)
        {
            this.entity.initialize(entityTypeStore);
            this.entity = entityTypeStore.entityCacheService.getEntityById(this.entity.id);
        }
    }

    @action
    postInitialize(entityTypeStore: EntityTypeStore,
                   doInitializeEntity: boolean = true)
    {
        if (this.entity && doInitializeEntity)
        {
            this.entity.initialize(
                entityTypeStore,
                undefined,
                undefined,
                undefined,
                false);
        }

        this.fields.forEach(
            field =>
                field.postInitialize(
                    entityTypeStore,
                    doInitializeEntity));

        this.bespoke = entityTypeStore.getBespokeByEntityType(this);

        // Reset prototype of bespoke types, because they should extend from each other. However, it results
        // in a runtime extends undefined error if we do this.
        // Now Activity.Task extends BespokeEntityType, but we want it to extend Activity
        if (this.hasParent() && entityTypeStore.getBespokeByEntityType(this.parentType) !== this.bespoke)
        {
            Object.setPrototypeOf(
                Object.getPrototypeOf(this.bespoke),
                Object.getPrototypeOf(entityTypeStore.getBespokeByEntityType(this.parentType)));
        }
    }

    // -------------------------- Computed --------------------------

    @computed
    get depth(): number
    {
        if (this.parentType)
        {
            return this.parentType.depth + 1;
        }
        else
        {
            return 0;
        }
    }

    @computed
    get isHidden(): boolean
    {
        // TODO [DD]: this is a way of hiding entity types that are currently not deletable for demo purposes
        return this.nameSingular.startsWith('#');
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    get nameSingular(): string
    {
        return this.entity?.getDataObjectValueByField(getTypes().EntityType.Field.LocalizedSingularName).toString() || this._nameSingular;
    }

    set nameSingular(value: string)
    {
        this._nameSingular = value;
    }

    get namePlural(): string
    {
        return this.entity?.getDataObjectValueByField(getTypes().EntityType.Field.LocalizedPluralName).toString() || this._namePlural;
    }

    set namePlural(value: string)
    {
        this._namePlural = value;
    }

    path(): EntityPath
    {
        return EntityPath.root(this);
    }

    getChannel(): string
    {
        return `entity/type/${this.id}`;
    }

    hasParent()
    {
        return this.parentType != null;
    }

    isA(entityType?: EntityType): boolean
    {
        if (entityType)
        {
            return (this.id === entityType.id && this.code === entityType.code)
                || (this.hasParent() && this.parentType.isA(entityType));
        }
        else
        {
            return false;
        }
    }

    isCompatibleWith(entityType: EntityType): boolean
    {
        return this.isA(entityType) || entityType.isA(this);
    }

    isEither(...types: EntityType[])
    {
        return types.some(type => this.isA(type));
    }

    @computed
    get switchableType(): EntityType
    {
        if (this.isSwitchable)
        {
            return this;
        }
        else
        {
            if (this.hasParent())
            {
                return this.parentType.switchableType;
            }
            else
            {
                return undefined;
            }
        }
    }

    getSwitchableType(): EntityType
    {
        return this.switchableType;
    }

    isSwitchableByInheritance(): boolean
    {
        return this.switchableType !== undefined;
    }

    @computed
    get inheritedFollowUp(): boolean
    {
        return this.hasFollowUp || (this.hasParent() && this.parentType.inheritedFollowUp);
    }

    hasInheritedFollowUp(): boolean
    {
        return this.inheritedFollowUp;
    }

    hasInheritedDuplicatePrevention(): boolean
    {
        return this.hasDuplicatePrevention || (this.hasParent() && this.parentType.hasInheritedDuplicatePrevention());
    }

    @computed
    get instantiableByInheritance(): boolean
    {
        return this.isInstantiable || (this.hasParent() && this.parentType.instantiableByInheritance);
    }

    isInstantiableByInheritance(): boolean
    {
        return this.instantiableByInheritance;
    }

    isEmbeddedByInheritance(): boolean
    {
        return this.isEmbedded || (this.hasParent() && this.parentType.isEmbeddedByInheritance());
    }

    @computed
    get isOwnableByInheritance(): boolean
    {
        return this.isOwnable || (this.hasParent() && this.parentType.isOwnableByInheritance);
    }

    @computed
    get isAssignableByInheritance(): boolean
    {
        return this.isAssignable || (this.hasParent() && this.parentType.isAssignableByInheritance);
    }

    isOverridableByInheritance(entityTypeStore: EntityTypeStore): boolean
    {
        const isOverridable =
            this.entity.getObjectValueByField(entityTypeStore.bespoke.types.EntityType.Field.IsOverridable) === true;

        return isOverridable || (this.hasParent() && this.parentType.isOverridableByInheritance(entityTypeStore));
    }

    @computed
    get inheritedColor(): string
    {
        return this.color || (this.hasParent() && this.parentType.inheritedColor);
    }

    getInheritedColor(): string
    {
        return this.inheritedColor;
    }

    @computed
    get inheritedIcon(): string
    {
        return this.icon || (this.hasParent() && this.parentType.inheritedIcon);
    }

    getInheritedIcon(): string
    {
        return this.inheritedIcon;
    }

    getAllTypes(includeInherited: boolean = true,
                includeChildren: boolean = true): EntityType[]
    {
        let types: EntityType[] = [ this ];

        if (includeInherited && this.parentType)
        {
            types.push(...this.parentType.getAllTypes(true, false));
        }

        if (includeChildren && this.childTypes)
        {
            for (let childType of this.childTypes)
            {
                types.push(...childType.getAllTypes(false, true));
            }
        }

        return sortTypes(types);
    }

    getAllInstantiableTypes(
        includeInherited: boolean = true,
        includeHidden: boolean = false
    ): EntityType[]
    {
        return sortTypes(
            this.getAllTypes(includeInherited)
                .filter(
                    type =>
                        type.isInstantiable
                            && (includeHidden || !isHiddenType(type))
                )
        );
    }

    getRelationshipDefinitions(isParent: boolean): EntityRelationshipDefinition[]
    {
        if (isParent)
        {
            return this.parentRelationshipDefinitions || [];
        }
        else
        {
            return this.childRelationshipDefinitions || [];
        }
    }

    getInheritedRelationshipDefinitions(isParent: boolean): EntityRelationshipDefinition[]
    {
        let relationshipDefinitions = this.getRelationshipDefinitions(isParent);

        if (this.hasParent())
        {
            return this.parentType.getInheritedRelationshipDefinitions(isParent).concat(relationshipDefinitions.slice());
        }
        else
        {
            return relationshipDefinitions;
        }
    }

    getAllRelationshipDefinitions(isParent: boolean): EntityRelationshipDefinition[]
    {
        let definitions: EntityRelationshipDefinition[] = [];

        for (let entityType of this.getAllTypes())
        {
            for (let definition of entityType.getRelationshipDefinitions(isParent))
            {
                definitions.push(definition);
            }
        }

        return definitions;
    }

    @computed
    get inheritedAvatarField(): EntityField
    {
        if (this.avatarField)
        {
            return this.avatarField;
        }
        else if (this.hasParent())
        {
            return this.parentType.inheritedAvatarField;
        }
        else
        {
            return undefined;
        }
    }

    getInheritedAvatarField(): EntityField
    {
        return this.inheritedAvatarField;
    }

    @computed
    get inheritedNameField(): EntityField
    {
        if (this.nameField)
        {
            return this.nameField;
        }
        else if (this.hasParent())
        {
            return this.parentType.inheritedNameField;
        }
        else
        {
            return undefined;
        }
    }

    getInheritedNameField(): EntityField
    {
        return this.inheritedNameField;
    }

    @computed
    get inheritedCompactNameField(): EntityField
    {
        if (this.compactNameField)
        {
            return this.compactNameField;
        }
        else if (this.hasParent())
        {
            return this.parentType.inheritedCompactNameField;
        }
        else
        {
            return undefined;
        }
    }

    getInheritedCompactNameField(): EntityField
    {
        return this.inheritedCompactNameField;
    }

    @computed
    get inheritedSortNameField(): EntityField
    {
        if (this.sortNameField)
        {
            return this.sortNameField;
        }
        else if (this.hasParent())
        {
            return this.parentType.inheritedSortNameField;
        }
        else
        {
            return undefined;
        }
    }

    getInheritedSortNameField(): EntityField
    {
        return this.inheritedSortNameField;
    }

    @computed
    get inheritedDescriptionField(): EntityField
    {
        if (this.descriptionField)
        {
            return this.descriptionField;
        }
        else if (this.hasParent())
        {
            return this.parentType.inheritedDescriptionField;
        }
        else
        {
            return undefined;
        }
    }

    getInheritedDescriptionField(): EntityField
    {
        return this.inheritedDescriptionField;
    }

    getInheritedTypes(): EntityType[]
    {
        let types: EntityType[] = [];

        if (this.hasParent())
        {
            types.push(...this.parentType.getInheritedTypes());
        }

        types.push(this);

        return types;
    }

    getInheritedFields(): EntityField[]
    {
        let fields: EntityField[] = [];

        for (let field of this.fields)
        {
            fields.push(field);
        }

        if (this.hasParent())
        {
            return [
                ...this.parentType.getInheritedFields(),
                ...fields,
            ];
        }
        else
        {
            return fields;
        }
    }

    getInheritedFieldGroups(entityTypeStore: EntityTypeStore): EntityFieldGroup[]
    {
        let groups: EntityFieldGroup[] = [];

        this.entity
            .getRelationshipsByDefinition(
                false,
                entityTypeStore.bespoke.types.EntityType.RelationshipDefinition.FieldGroups)
            .forEach(
                relationship =>
                    groups.push(
                        new EntityFieldGroup(
                            this,
                            relationship.getEntity(false))));

        if (this.hasParent())
        {
            return groups.concat(
                this.parentType.getInheritedFieldGroups(entityTypeStore));
        }
        else
        {
            return groups;
        }
    }

    getFieldByCode(code: string): EntityField
    {
        let field = this.fieldByCode.get(code);

        if (field)
        {
            return field;
        }
        else
        {
            if (this.hasParent())
            {
                return this.parentType.getFieldByCode(code);
            }
            else
            {
                return null;
            }
        }
    }

    isStaticType(): boolean
    {
        return (this as any).isStatic;
    }

    getInheritedRelationshipDefinitionById(isParent: boolean,
                                           definitionId: number): EntityRelationshipDefinition
    {
        for (let definition of this.getRelationshipDefinitions(isParent))
        {
            if (definition.id === definitionId)
            {
                return definition;
            }
        }

        if (this.hasParent())
        {
            return this.parentType.getInheritedRelationshipDefinitionById(isParent, definitionId);
        }
        else
        {
            return null;
        }
    }

    getName(isPlural: boolean = false,
            entity?: Entity): string
    {
        let resultingName  = this.bespoke.getName(isPlural, entity);

        if (resultingName)
        {
            return resultingName;
        }
        else if (this.entity
            && this.entity.hasValueForField(loadModuleDirectly(EntityTypeStore).bespoke.types.EntityType.Field.IsNameHiddenForUser)
            && this.hasParent())
        {
            resultingName = this.parentType.getName(
                isPlural,
                entity);
        }
        else if (isPlural)
        {
            resultingName = this.namePlural;
        }
        else
        {
            resultingName = this.nameSingular;
        }

        if (!resultingName)
        {
            console.error("EntityType does not have a name:", this.id, this.code);
            resultingName = "";
        }

        return resultingName;
    }

    getNameDataObject(isPlural: boolean = false)
    {
        if (isPlural)
        {
            return this.entity.getDataObjectValueByField(getTypes().EntityType.Field.LocalizedPluralName);
        }
        else
        {
            return this.entity.getDataObjectValueByField(getTypes().EntityType.Field.LocalizedSingularName);
        }
    }

    getAvatarType(entityTypeStore: EntityTypeStore,
                  defaultType: AvatarType = AvatarType.Round): AvatarType
    {
        return defaultType;

        // Temporarily disabled
        // if (this.isA(entityTypeStore.bespoke.types.Activity.Type))
        // {
        //     return AvatarType.Hexagon;
        // }
        // else
        // {
        //     return defaultType;
        // }
    }

    getDependencies(path: EntityPath): EntityFieldPath[]
    {
        const paths: EntityFieldPath[] = [];

        this.getInheritedFields()
            .forEach(
                field =>
                    paths.push(
                        ...field.getDependencies(path)));

        return paths;
    }

    toString(): string
    {
        return `EntityType(${this.isStaticType() ? this.code : this.id})`;
    }

    // ----------------------- Private logic ------------------------
}

registerBuilder(EntityType)
    .includeAll()
    .deep('entity');
