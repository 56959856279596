import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './PrivilegeHeader.module.scss';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface PrivilegeHeaderProps
{

}

export const PrivilegeHeader: React.FC<PrivilegeHeaderProps> =
    observer(
        () =>
        {
            return <div
                className={styles.root}
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={0}
                    alignment="center"
                >
                    <ViewGroupItem>
                        <div
                            className={styles.aspect}
                        >
                            <LocalizedText
                                code="Generic.Read"
                                value="Lezen"
                            />
                        </div>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <div
                            className={styles.aspect}
                        >
                            <LocalizedText
                                code="Generic.Create"
                                value="Creëeren"
                            />
                        </div>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <div
                            className={styles.aspect}
                        >
                            <LocalizedText
                                code="Generic.Edit"
                                value="Wijzigen"
                            />
                        </div>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <div
                            className={styles.aspect}
                        >
                            <LocalizedText
                                code="Generic.Delete"
                                value="Verwijderen"
                            />
                        </div>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <div
                            className={styles.aspect}
                        >
                            <LocalizedText
                                code="Generic.Export"
                                value="Exporteren"
                            />
                        </div>
                    </ViewGroupItem>
                </ViewGroup>
            </div>;
        }
    );
