import React from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../../Type/Api/useTypes';
import { Entity } from '../../../../../../../../../../../@Api/Model/Implementation/Entity';
import styles from './Author.module.scss';
import FieldView from '../../../../../../../../Field/View/FieldView';
import useRelatedEntity from '../../../../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import LocalizedText from '../../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface AuthorProps
{
    entity: Entity;
    prefix?: string;
}

const Author: React.FC<AuthorProps> =
    props =>
    {
        const types = useTypes();
        const creator =
            useRelatedEntity(
                props.entity,
                types.Relationship.Person.Contact.Employee.RelationshipDefinition.CreatedEntities,
                true);

        return <div
            className={styles.root}
        >
            {props.prefix ? props.prefix : ''} <FieldView entity={props.entity} field={types.Entity.Field.CreationDate} /> &#183; <LocalizedText code="Generic.By" value="door" /> {creator ? <FieldView entity={props.entity} field={types.Relationship.Person.Contact.Employee.RelationshipDefinition.CreatedEntities} parent /> : <LocalizedText code="Generic.Automation" value="Automation" lowerCase />}
        </div>;
    };

export default observer(Author);
