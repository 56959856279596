import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import Selectbox from '../../../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import DataObjectContext from '../../../../../../DataObject/DataObjectContext';
import search from '../../../../../../../../@Util/String/search';
import { DataType } from '../../Model/DataType';
import { Field } from '../../Model/Field';
import { runInAction } from 'mobx';
import { DataObjectType } from '../../../../../../../../@Api/Model/Implementation/EntityField';
import useTypes from '../../../../../../Entity/Type/Api/useTypes';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import IconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import RouterContext from '../../../../../../../../@Service/Router/RouterContext';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';

export interface FieldItemProps
{
    entityType: EntityType;
    field: Field;
}

const TypeSelector: React.FC<FieldItemProps> =
    props =>
    {
        const routerStore = useContext(RouterContext);
        const dataObjectStore = useContext(DataObjectContext);
        const types = useTypes();
        const loader =
            useCallback(
                (query: string) =>
                    Promise.resolve<DataType[]>([
                        ...[
                            DataObjectType.Text,
                            DataObjectType.RichText,
                            DataObjectType.LocalizedText,
                            DataObjectType.EmailAddress,
                            DataObjectType.TelephoneNumber,
                            DataObjectType.Url,
                            DataObjectType.Number,
                            DataObjectType.Percentage,
                            DataObjectType.Currency,
                            DataObjectType.Boolean,
                            DataObjectType.Date,
                            DataObjectType.DateTime,
                            DataObjectType.Time,
                            DataObjectType.File,
                            DataObjectType.DateInterval,
                            DataObjectType.DatePeriod,
                        ].map(
                            type =>
                                dataObjectStore.getTypeById(DataObjectType[type]))
                            .filter(
                                type =>
                                    search(query, type.name())),
                        ...types.Datastore.Type.getAllInstantiableTypes()
                            .filter(
                                type =>
                                    search(query, type.getName()))
                    ]),
                [
                    types,
                    dataObjectStore
                ]);

        const formatter =
            useCallback(
                (type: DataType) =>
                {
                    if (type instanceof EntityType)
                    {
                        if (type.parentType)
                        {
                            return `${type.parentType.getName(false)}: ${type.getName(false)}`;
                        }
                        else
                        {
                            return type.getName(false);
                        }
                    }
                    else
                    {
                        return type.name();
                    }
                },
                []);

        const onChange =
            useCallback(
                (dataType: DataType) =>
                    runInAction(
                        () =>
                            props.field.dataType = dataType),
                [
                    props.field
                ]);

        let content;

        if (props.field.isEditable && !props.field.fieldPath)
        {
            content =
                <Selectbox
                    load={loader}
                    onChange={onChange}
                    formatOption={formatter}
                    idResolver={formatter}
                    value={props.field.dataType}
                    disabled={!props.field.isEditable}
                />;
        }
        else
        {
            content =
                <div>
                    {formatter(props.field.dataType)}
                </div>;
        }

        const edit =
            useCallback(
                () =>
                    routerStore.route(`/type/${props.field.dataType.id}`),
                [
                    routerStore,
                    props.field
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={10}
            alignment="center"
        >
            <ViewGroupItem
                ratio={1}
            >
                {content}
            </ViewGroupItem>
            {
                props.field.dataType instanceof EntityType &&
                    <ViewGroupItem>
                        <IconButton
                            icon="edit"
                            onClick={edit}
                            tooltip={
                                <LocalizedText
                                    code="Generic.Manage"
                                    value="Beheren"
                                />
                            }
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>
    };

export default observer(TypeSelector);
