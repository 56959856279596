import React from 'react';
import { observer } from 'mobx-react-lite';
import CircularPercentageChart from '../../../../../../../../@Future/Component/Generic/Chart/CircularPercentageChart';
import styles from './CampaignChart.module.scss';

export interface CampaignPercentageChartProps
{
    value: number;
    label?: string;
    size: number;
    color?: string;
    selection?: string;
}

const CampaignPercentageChart: React.FC<CampaignPercentageChartProps> =
    props =>
    {
        const customLabel =
            <div>
                <div
                    style={{
                        textAlign: 'center',
                        width: `${props.size * 0.75}px`,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        color: props.color,
                        fontSize: `${props.size / 120}em`
                    }}
                >
                    {props.label}
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        color: props.color,
                        lineHeight: '1.1em',
                        fontSize: `${props.size / 120}em`
                    }}
                >
                    {props.selection}
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        lineHeight: '1.1em',
                        color: props.color,
                        fontSize: `${props.size / 120 * 1.7}em`
                    }}
                >
                    {isNaN(props.value) ? 0 : Math.round(props.value)}%
                </div>
            </div>


        return <CircularPercentageChart
            className={styles.percentageChart}
            size={props.size}
            value={props.value}
            color={props.color}
            label={value => `${Math.round(value)}%`}
            subLabel={() => props.label}
            customLabel={() => customLabel}
        />;
    };

export default observer(CampaignPercentageChart);
