import { type } from '../../../@Util/Serialization/Serialization';
import { EntityRelationshipDefinitionMutation } from './EntityRelationshipDefinitionMutation';

@type('EntityRelationshipDefinitionCreationMutation')
export class EntityRelationshipDefinitionCreationMutation extends EntityRelationshipDefinitionMutation
{
    // ------------------- Persistent Properties --------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
