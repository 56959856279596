import * as React from 'react';
import { observer } from 'mobx-react';
import RichTextIframe from './Iframe/RichTextIframe';
import RichTextSpan from './Span/RichTextSpan';

export interface RichTextProps
{
    value: string;
    standalone?: boolean;
    allowScrolling?: boolean;
}

const RichText: React.FC<RichTextProps> =
    props =>
    {
        if (props.standalone)
        {
            return <RichTextIframe
                value={props.value}
                allowScrolling={props.allowScrolling}
            />;
        }
        else
        {
            return <RichTextSpan
                value={props.value}
            />;
        }
    };

export default observer(RichText);

