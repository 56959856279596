import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { EntityPath } from '../../Path/@Model/EntityPath';

export class BespokeProduct extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Product')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getListDependencies(): EntityPath[]
    {
        return [
            ...super.getListDependencies(),
            EntityPath.root(this.type)
                .joinTo(
                    this.types.Product.RelationshipDefinition.VatGroup,
                    false
                )
        ];
    }

    // ----------------------- Private logic ------------------------
}
