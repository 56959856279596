
import { HeaderNotification } from '../HeaderNotifications';
import getTypes from '../../../../../../Domain/Entity/Type/Api/getTypes';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';

export function getContractNotificationFromFinance(contractEntity: Entity) : HeaderNotification[]
{
    const types= getTypes();

    if (!contractEntity)
    {
        return [];
    }

    if (contractEntity.getObjectValueByField(types.LicenseContract.Field.ShowNotification) &&
        contractEntity.hasValueForField(types.LicenseContract.Field.Notification)
    )
    {
        return [
            { message: contractEntity.getObjectValueByField(types.LicenseContract.Field.Notification) }
        ];
    }
    else
    {
        return [];
    }

}
