import * as React from 'react';
import { DataObjectBespokeEditor } from '../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { observer } from 'mobx-react';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { DataObjectLabel } from '../../Editor/Value/Label/DataObjectLabel';
import { TextEditorStore } from './TextEditorStore';
import { ExpressionEditor } from '../../../Expression/Editor/ExpressionEditor';
import { default as GenericTextEditor } from '../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';

export function renderTextField(store: DataObjectEditorStore<TextEditorStore>, type?: any): any
{
    if (store.bespokeStore.expressionEditorStore)
    {
        return <>
            <DataObjectLabel store={store} />
            <ExpressionEditor
                store={store.bespokeStore.expressionEditorStore}
            />
        </>;
    }
    else
    {
        return <GenericTextEditor
            value={store.dataObject.value}
            onChange={store.changeValue}
            disableUnderline={!store.hasUnderline}
            type={store.isPassword ? 'password' : type}
            autoFocus={store.isFocused}
            disabled={store.isDisabled}
            placeholder={store.placeholder}
            error={store.showError}
            inputProps={store.handlerProperties as any}
            fitContent={store.representation?.data?.fitContent}
            inputStyle={store.inputStyle}
        />;
    }
};

@observer
export default class TextEditor2 extends DataObjectBespokeEditor<TextEditorStore>
{
    renderComponent(store: DataObjectEditorStore<TextEditorStore>): any
    {
        return renderTextField(store);
    }
}
