import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { AuditTrailEntity } from '../../Timeline/Model/AuditTrailEntity';
import { observable } from 'mobx';
import { BespokeRelationshipOrganizationCommercial } from './BespokeRelationshipOrganizationCommercial';

export class BespokeRelationshipOrganization extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable commercial: BespokeRelationshipOrganizationCommercial;

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Relationship.Organization')
    {
        super(entityTypeStore, code);

        this.commercial = new BespokeRelationshipOrganizationCommercial(this.entityTypeStore);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getUserFriendlyEntityType()
    {
        return this.entityTypeStore.bespoke.types.Relation.Organization.Type;
    }

    getListDependencies(): EntityPath[]
    {
        return [
            ...super.getListDependencies(),
            ...[
                this.types.Relation.Organization.RelationshipDefinition.VisitingAddress,
                this.types.Relation.Organization.RelationshipDefinition.PostalAddress,
                this.types.Relation.Organization.RelationshipDefinition.InvoiceAddress,
            ].map(
                addressRelationshipDefinition =>
                    EntityPath.fromEntityType(this.type)
                        .joinTo(
                            this.types.Relationship.Organization.RelationshipDefinition.Organization,
                            false)
                        .joinTo(
                            addressRelationshipDefinition,
                            false)
                        .joinTo(
                            this.types.Address.RelationshipDefinition.Country,
                            false)
            ),
        ];
    }

    getAuditTrailEntities(entity: Entity, types): AuditTrailEntity[]
    {
        const childOrganization =
            entity.getRelatedEntityByDefinition(
                false,
                types.Relationship.Organization.RelationshipDefinition.Organization
            );

        const childOrganizationVisitingAddress =
            childOrganization?.getRelatedEntityByDefinition(
                false,
                types.Relation.Organization.RelationshipDefinition.VisitingAddress
            );

        const childOrganizationPostalAddress =
            childOrganization?.getRelatedEntityByDefinition(
                false,
                types.Relation.Organization.RelationshipDefinition.PostalAddress
            );

        const childOrganizationInvoiceAddress =
            childOrganization?.getRelatedEntityByDefinition(
                false,
                types.Relation.Organization.RelationshipDefinition.InvoiceAddress
            );

        return [
            new AuditTrailEntity(entity, entity.entityType.nameSingular),
            ...childOrganization
                ? [ new AuditTrailEntity(childOrganization, childOrganization.entityType.nameSingular) ]
                : [],
            ...childOrganizationVisitingAddress
                ? [ new AuditTrailEntity(childOrganizationVisitingAddress, types.Relation.Organization.RelationshipDefinition.VisitingAddress.name) ]
                : [],
            ...childOrganizationPostalAddress
                ? [ new AuditTrailEntity(childOrganizationPostalAddress, types.Relation.Organization.RelationshipDefinition.PostalAddress.name) ]
                : [],
            ...childOrganizationInvoiceAddress
                ? [ new AuditTrailEntity(childOrganizationInvoiceAddress, types.Relation.Organization.RelationshipDefinition.InvoiceAddress.name) ]
                : []
        ];
    }
    // ----------------------- Private logic ------------------------
}
