import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Logo.module.scss';
import BlueLogoImg from '../../../@Resource/Image/Logo/Blue.svg';
import ColoredLogoImg from '../../../@Resource/Image/Logo/Colored.svg';

export interface LogoProps
{
    variant?: 'colored' | 'blue';
    src?: string;
}

const Logo: React.FC<LogoProps> =
    props =>
    {
        return <img
            alt="Tribe CRM"
            src={props.src || (props.variant === 'colored' ? ColoredLogoImg : BlueLogoImg)}
            className={styles.root}
        />;
    };

Logo.defaultProps = {
    variant: 'colored'
};

export default observer(Logo);
