import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { EntityFieldGroup } from '../../../../../../Management/Application/EntityTypesEditor/EntityFieldGroup';
import { createNumberComparator } from '../../../../../../../Generic/List/V2/Comparator/NumberComparator';
import EntityTypeContext from '../../../../../Type/EntityTypeContext';
import useVisibleFieldPathsForEntity from '../../../../../Fields/Api/useVisibleFieldPathsForEntity';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import styles from './Expansion.module.scss';
import { EntityPath } from '../../../../@Model/EntityPath';
import { EntityFieldPath } from '../../../../@Model/EntityFieldPath';
import FieldPathBrowser from '../../Content/Content';
import ObservableFieldBrowser from '../../Content/Content';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import useIndent from '../../../Hooks/useIndent';
import ExpansionPanel from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';

export interface ExpansionProps
{
    path: EntityPath;
    onTraverse: (path: EntityPath) => void;
    onSelect: (fieldPath: EntityFieldPath) => void;
    entity: Entity;
    group: EntityFieldGroup;
    readOnly?: boolean;
    depth: number;
    filter?: (fieldPath: EntityFieldPath) => boolean;
}

const Expansion: React.FC<ExpansionProps> =
    props =>
    {
        const unfilteredFieldPaths = useVisibleFieldPathsForEntity(props.entity, true);
        const entityTypeStore = useContext(EntityTypeContext);
        const indent = useIndent(props.depth);

        const fieldOrdering =
            useComputed(
                () =>
                    // TODO: DO NOT FORGET --------------------
                    //getFieldOrderingInType(props.entity.entityType),
                    // TODO: DO NOT FORGET --------------------
                    ({}),
                [
                    props.entity
                ]);

        const fieldPaths =
            useComputed(
                () =>
                    unfilteredFieldPaths
                        .filter(
                            fieldPath =>
                                props.filter === undefined
                                || props.filter(
                                    props.path
                                        .join(fieldPath.path)
                                        .field(fieldPath.field)
                                )
                        )
                        .filter(
                            path =>
                                path.isInFieldGroup(props.group, entityTypeStore))
                        .sort(
                            createNumberComparator(
                                path =>
                                    fieldOrdering[path.id] === undefined
                                        ?
                                            path.sortIndex
                                        :
                                            fieldOrdering[path.id]))
                        .map(
                            fieldPath =>
                                props.path.join(fieldPath.path).field(fieldPath.field)),
                [
                    unfilteredFieldPaths,
                    props.filter,
                    props.entity,
                    props.group,
                    entityTypeStore,
                    fieldOrdering,
                    props.path
                ]);

        if (props.group.customId === 'privatedata')
        {
            return <CardInset
                horizontal={false}
                top={false}
                bottom
                classes={{
                    root: styles.expansion
                }}
            >
                <FieldPathBrowser
                    path={props.path}
                    onTraverse={props.onTraverse}
                    onSelect={props.onSelect}
                    noVirtualGroups
                    depth={props.depth + 1}
                    filter={props.filter}
                />
            </CardInset>;
        }
        else
        {
            return <CardInset
                horizontal={false}
                top={false}
                bottom={false}
            >
                <Menu
                    hideGlue
                >
                    {
                        fieldPaths.map(
                            fieldPath =>
                                fieldPath.isRelationship && fieldPath.relationshipDefinition.isPlural(fieldPath.isParentRelationship)
                                ?
                                    <ExpansionPanel
                                        id={fieldPath.id}
                                        summary={
                                            <Header
                                                title={<span style={{ paddingLeft: indent }}>{fieldPath.path.getName(undefined, false, false)}</span>}
                                                inset
                                            />
                                        }
                                        expansion={
                                            <ObservableFieldBrowser
                                                path={fieldPath.path}
                                                onTraverse={props.onTraverse}
                                                onSelect={props.onSelect}
                                                depth={props.depth + 1}
                                                filter={props.filter}
                                            />
                                        }
                                    />
                                :
                                    <Item
                                        key={fieldPath.id}
                                        name={fieldPath.getName()}
                                        onClick={() => props.onSelect(fieldPath)}
                                        classes={{
                                            label: styles.fieldItemLabel
                                        }}
                                        indent={indent}
                                    />)
                    }
                </Menu>
            </CardInset>;
        }
    };

export default observer(Expansion);
