import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { RelatedEntityPath } from '../../../Path/@Model/RelatedEntityPath';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Avatar from './Avatar/Avatar';
import styles from './Relationship.module.scss';
import Header from './Header/Header';
import Pipeline from '../../../Workflow/Pipeline/Pipeline';
import useIsShowEntitySummary from './Api/useIsShowEntitySummary';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import Summary from './Summary/Summary';
import HeaderContext from '../../Context/HeaderContext';
import Inset from '../Inset/Inset';
import PathFromRelatedEntityContext from '../../Context/PathFromRelatedEntityContext';
import Navigator from '../../Navigator/Navigator';
import Caption from '../../Caption/Caption';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import Click from '../Click/Click';
import ViewerEntityContext from '../../../Viewer/Context/ViewerEntity/ViewerEntityContext';
import FieldInsetContext from '../../../Fields/Context/FieldInsetContext';
import MenuButton from '../../MenuButton/MenuButton';

export interface RelationProps
{
    entity: Entity;
    showMenuButton?: boolean;
}

const Relationship: React.FC<RelationProps> =
    props =>
    {
        const isHeader = useContext(HeaderContext);
        const providedPathFromRelatedEntity = useContext(PathFromRelatedEntityContext);
        const defaultPathFromRelatedEntity =
            useMemo(
                () =>
                    new RelatedEntityPath(
                        props.entity,
                        EntityPath.fromEntity(props.entity)),
                [
                    props.entity
                ]);

        const pathFromRelatedEntity = providedPathFromRelatedEntity || defaultPathFromRelatedEntity;
        const viewerEntity = useContext(ViewerEntityContext);

        return <div
            className={
                classNames(
                    styles.root,
                    isHeader && styles.header)
            }
        >
            <Click
                open={viewerEntity === undefined}
            >
                <Inset>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={0}
                    >
                        <ViewGroupItem>
                            <Avatar
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem
                            ratio={1}
                        >
                            <div
                                className={styles.cardHeaderWrapper}
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={15}
                                >
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        <Header
                                            entity={props.entity}
                                            pathFromRelatedEntity={pathFromRelatedEntity}
                                        />
                                        <Caption
                                            entity={props.entity}
                                        />
                                    </ViewGroupItem>
                                    {
                                        props.showMenuButton &&
                                            <ViewGroupItem>
                                                <MenuButton
                                                    entity={props.entity}
                                                />
                                            </ViewGroupItem>
                                    }
                                </ViewGroup>
                                {
                                    isHeader &&
                                        <div
                                            onClick={e => e.stopPropagation()}
                                        >
                                            <Pipeline
                                                entity={props.entity}
                                            />
                                        </div>
                                }
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={0}
                                >
                                    <ViewGroupItem>
                                        <FieldInsetContext.Provider
                                            value={false}
                                        >
                                            <Navigator
                                                entity={props.entity}
                                            />
                                        </FieldInsetContext.Provider>
                                    </ViewGroupItem>
                                    {
                                        useIsShowEntitySummary(props.entity) &&
                                            <ViewGroupItem>
                                                <Divider className={styles.summaryDivider} />
                                            </ViewGroupItem>
                                    }
                                </ViewGroup>
                                <Summary
                                    entity={props.entity}
                                />
                            </div>
                        </ViewGroupItem>
                    </ViewGroup>
                </Inset>
            </Click>
        </div>;
    };

export default observer(Relationship);
