import { BespokeEntityType } from '../../BespokeEntityType';
import { observable } from 'mobx';
import { BespokeDatastorePhase } from './BespokeDatastorePhase';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { RelatedEntityPath } from '../../../Path/@Model/RelatedEntityPath';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/index';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import getSetting from '../../../../Setting/Api/getSetting';
import { SettingSource } from '../../../../Setting/SettingStore';
import { Setting } from '../../../../../../@Api/Settings/Setting';
import { CurrentUserStore } from '../../../../User/CurrentUserStore';
import { EntityTypeStore } from '../../EntityTypeStore';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';

export class BespokeDatastore extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable.ref phase: BespokeDatastorePhase;

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore, code: string = 'Datastore')
    {
        super(entityTypeStore, code);

        this.phase = new BespokeDatastorePhase(this.entityTypeStore);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    orderByField(entity?: Entity,
                 pathFromRelatedEntity?: RelatedEntityPath): EntityFieldPath
    {
        return EntityPath.fromEntityType(this.type).field(this.entityTypeStore.bespoke.types.Datastore.Field.LocalizedName);
    }

    hideFieldPath(entity: Entity,
                  fieldPath: EntityFieldPath,
                  fromFieldPath?: EntityFieldPath,
                  isInConstructor?: boolean,
                  commitContext?: CommitContext): boolean
    {
        const currentUserStore = loadModuleDirectly(CurrentUserStore);
        return super.hideFieldPath(entity, fieldPath, fromFieldPath, isInConstructor, commitContext)
            || (fieldPath.field === this.types.Datastore.Field.Code && !currentUserStore.isSupport)
            || (fieldPath.field === this.types.Datastore.Field.Color);
    }

    isLinkable(entity: Entity): boolean
    {
        return false;
    }

    isHiddenInSelectbox(entity: Entity): boolean
    {
        const hiddenDatastoreIds = getSetting<string[]>(SettingSource.Organization, Setting.Datastore.HiddenIds);

        if (hiddenDatastoreIds)
        {
            return hiddenDatastoreIds.indexOf(entity.uuid) >= 0;
        }
        else
        {
            return false;
        }
    }

    isTypeDeletable()
    {
        return true;
    }

    // ----------------------- Private logic ------------------------
}
