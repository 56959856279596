import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { DataComparator } from '../../../../Generic/List/V2/ListStore';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import { Comparator } from '../../../DataObject/Model/Comparator';
import EmptyValue from '../../../../../@Api/Automation/Value/EmptyValue';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import Value from '../../../../../@Api/Automation/Value/Value';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';

export function createEntityExpressionComparator<D>(
    retriever: (data: D) => Entity | undefined,
    parameters: ParameterDictionary,
    getParameterAssignment: (entity: Entity) => ParameterAssignment,
    expression: Computation<any, any>,
    isAscending: boolean
): DataComparator<D>
{
    return (d1, d2) =>
        compareEntities(
            retriever(d1),
            retriever(d2),
            parameters,
            getParameterAssignment,
            expression,
            isAscending
        );
}

function compareEntities(
    e1: Entity | undefined,
    e2: Entity | undefined,
    parameters: ParameterDictionary,
    getParameterAssignment: (entity: Entity) => ParameterAssignment,
    expression: Computation<any, any>,
    isAscending: boolean
)
{
    const v1 =
        computeValue(
            e1,
            parameters,
            getParameterAssignment,
            expression
        );
    const v2 =
        computeValue(
            e2,
            parameters,
            getParameterAssignment,
            expression
        );

    return compareValues(
        v1,
        v2,
        isAscending
    );
}

function computeValue(
    entity: Entity | undefined,
    parameters: ParameterDictionary,
    getParameterAssignment: (entity: Entity) => ParameterAssignment,
    expression: Computation<any, any>
)
{
    return entity === undefined
        ? EmptyValue.instance
        : expression.synchronousApply(
            new FunctionContext(
                parameters,
                getParameterAssignment(entity)
            )
        );
}

function compareValues(
    v1: Value<any, any>,
    v2: Value<any, any>,
    isAscending: boolean
): number
{
    // if ascending
    //      returns v2 - v1
    //      where EmptyValue is maximum value

    // if descending
    //      returns v1 - v2
    //      where EmptyValue is minimum value

    /*
            Ascending:            Result: 5 - 10 - null
                      |  v2:
                 v1:  |  null      5       10
                ------|-----------------------
                null  |    0      +1       +1
                ------|-----------------------
                   5  |    -1      0       +1
                ------|-----------------------
                  10  |    -1     -1        0
                ------|-----------------------

            Descending:           Result: null - 10 - 5
                      |  v2:
                 v1:  |  null      5       10
                ------|-----------------------
                null  |    0      -1       -1
                ------|-----------------------
                   5  |    +1      0       -1
                ------|-----------------------
                  10  |    +1     +1        0
                ------|-----------------------
     */

    const emptyContext =
        new FunctionContext(
            new ParameterDictionary([]),
            new ParameterAssignment()
        );

    const isV1Null = v1 instanceof EmptyValue;
    const isV2Null = v2 instanceof EmptyValue;

    if (isV1Null && !isV2Null)
    {

        return isAscending
            ? +1 // Null last
            : -1 // Null first
    }
    else if (!isV1Null && isV2Null)
    {
        // Null first
        return isAscending
            ? -1  // Null last
            : +1; // Null first
    }
    else if (safelyCompare(emptyContext, v1, Comparator.Equals, v2))
    {
        return 0;
    }
    else if (safelyCompare(emptyContext, v1, Comparator.LessThan, v2))
    {
        // v1 < v2
        return isAscending
            ? -1
            : 1;
    }
    else
    {
        // v1 >= v2
        return isAscending
            ? 1
            : -1;
    }
}

function safelyCompare(
    context: FunctionContext,
    v1: Value<any, any>,
    comparator: Comparator,
    v2: Value<any, any>
): boolean
{
    try
    {
        return new ComparisonPredicate(v1, comparator, v2)
            .synchronouslyEvaluate(context);
    }
    catch (e)
    {
        console.error('Failed to compare values', v1, comparator, v2, e);

        return false;
    }
}
