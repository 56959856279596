import { useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import getConnectorActivationByCode from '../../../../../../../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';
import useEntityValue from '../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import useTypes from '../../../../../../Type/Api/useTypes';

export default function useHasTeamsMeetingActivated() : [ boolean, Entity | undefined ]
{
    const types = useTypes();
    const [ connectorActivation, setConnectorActivation ] = useState<Entity | undefined>(undefined);
    const configuration = useEntityValue<any>(connectorActivation, types.ConnectorActivation.Field.Configuration);

    useEffect(
        () =>
        {
            getConnectorActivationByCode('MicrosoftGraph', true)
                .then(setConnectorActivation);
        },
        [
            setConnectorActivation
        ]
    );

    return useMemo<[boolean, Entity | undefined]>(
        () =>
            [
                (configuration?.activatedModules || [])
                    .filter(
                        module =>
                            module === 'OnlineMeetings'
                    ).length > 0,
                connectorActivation
            ],
        [
            configuration,
            connectorActivation
        ]
    );
}
