import React, { useContext, useEffect, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import EffectLayout from '../../../../../@Api/Layout/Type/EffectLayout';
import safelySynchronousApplyFunction from '../../../../../@Api/Automation/Api/safelySynchronousApplyFunction';
import { useMemoizedArray } from '../../../../../@Util/Array/useMemoizedArray';
import { runInAction } from 'mobx';
import LayoutContext from '../../../../../@Api/Layout/LayoutContext';
import FormLayoutContexts from '../Form/FormLayoutContexts';

export interface EffectLayoutViewerProps extends LayoutViewerProps<EffectLayout>
{

}

const EffectLayoutViewer: React.FC<EffectLayoutViewerProps> =
    ({
        layout,
        parameterDictionary,
        parameterAssignment,
        commitContext,
        safeMode,
     }) =>
    {
        const formLayoutContexts = useContext(FormLayoutContexts);
        const context =
            useMemo(
                () =>
                    new LayoutContext(
                        parameterDictionary,
                        parameterAssignment,
                        commitContext,
                        safeMode,
                        formLayoutContexts
                    ),
                [
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                    safeMode,
                    formLayoutContexts,
                ]
            );
        const dependencyValues =
            useComputed(
                () =>
                    layout.dependencies.map(
                        dependency =>
                            safelySynchronousApplyFunction(
                                dependency.value,
                                context
                            )
                    ),
                [
                    layout,
                    context,
                ]
            );
        const stableDependencyValues =
            useMemoizedArray(
                dependencyValues,
                (a, b) => a.equals(b),
                []
            );

        useEffect(
            () =>
            {
                runInAction(
                    () =>
                        layout.action.apply(
                            context
                        )
                );
            },
            stableDependencyValues
        );

        return null;
    };

export default observer(EffectLayoutViewer);
