import { Automator, BespokeEntityType } from '../../../BespokeEntityType';
import { EntityTypeStore } from '../../../EntityTypeStore';
import { EntityRelationshipDefinition } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { CurrentUserStore } from '../../../../../User/CurrentUserStore';
import copyProductLines from '../../../../../../../@Api/Entity/Bespoke/Activity/copyProductLines';
import moment from 'moment';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { Aggregate } from '../../../../../DataObject/Model/Aggregate';
import Automation from '../../../../../../../@Api/Automation/Automation';
import CreationTrigger from '../../../../../../../@Api/Automation/Trigger/CreationTrigger';
import CompositeAction from '../../../../../../../@Api/Automation/Function/Action/CompositeAction';
import CompositeActionInvocation from '../../../../../../../@Api/Automation/Function/Action/CompositeActionInvocation';
import uuid from '../../../../../../../@Util/Id/uuid';
import CreateEntityAction from '../../../../../../../@Api/Automation/Function/Action/CreateEntityAction';
import Mapping from '../../../../../../../@Api/Automation/Mapping/Mapping';
import ValueFieldMapping from '../../../../../../../@Api/Automation/Mapping/Field/ValueFieldMapping';
import RelationshipInput from '../../../../../Multiplayer/Model/Input/RelationshipInput';
import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import MutationTrigger from '../../../../../../../@Api/Automation/Trigger/MutationTrigger';
import EntityValueType from '../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import FieldInput from '../../../../../Multiplayer/Model/Input/FieldInput';
import PrimitiveValue from '../../../../../../../@Api/Automation/Value/PrimitiveValue';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { updateRelationship } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export class BespokeActivityProject extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Activity.Project')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    onConstruct(entity: Entity,
                commitContext?: CommitContext): void
    {
        super.onConstruct(entity, commitContext);

        if (!entity.hasRelationshipsByDefinition(
            false,
            this.entityTypeStore.bespoke.types.Activity.Project.RelationshipDefinition.Owner,
            commitContext))
        {
            updateRelationship(
                entity,
                false,
                this.entityTypeStore.bespoke.types.Activity.Project.RelationshipDefinition.Owner,
                loadModuleDirectly(CurrentUserStore).employeeEntity,
                commitContext
            );

            setValueByFieldInEntity(
                entity,
                this.types.Activity.Project.Field.StartDate,
                new Date(),
                commitContext
            );
            setValueByFieldInEntity(
                entity,
                this.types.Activity.Project.Field.EndDate,
                moment(new Date()).add(14, 'days').toDate(),
                commitContext
            );
        }
    }

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        // In case of linking a new project to an activity, then:
        // - set the project budget to the value of the sales opportunity
        // - copy the subject
        if (entity.isNew()
            && isParent
            && relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities
            && relatedEntity)
        {
            // The budget of the project should match the total of the product lines
            setValueByFieldInEntity(
                entity,
                this.types.Activity.Project.Field.Budget,
                relatedEntity.getObjectValueByField(this.types.Activity.Field.Amount),
                commitContext
            );

            // Copy product lines (non-billed ones)
            await copyProductLines(
                relatedEntity,
                entity,
                line =>
                    !line.hasValueForField(this.types.ProductLine.Field.RepeatInterval),
                commitContext
            );

            // Fill in hour budget (the sum of all product lines hours)
            await new EntitySelectionBuilder(this.types.ProductLine.Type)
                .where(
                    cb =>
                        cb.relatedToEntity(
                            EntityPath.fromEntityType(this.types.ProductLine.Type)
                                .joinTo(
                                    this.types.Activity.RelationshipDefinition.ProductLines,
                                    true),
                            relatedEntity))
                .where(
                    cb =>
                        cb.eq(
                            EntityPath.fromEntityType(this.types.ProductLine.Type)
                                .joinTo(
                                    this.types.ProductLine.RelationshipDefinition.Product,
                                    false)
                                .joinTo(
                                    this.types.Product.RelationshipDefinition.Unit,
                                    false)
                                .field(this.types.Datastore.Field.Code),
                            undefined,
                            'Hour'))
                .aggregateOn(
                    EntityPath.fromEntityType(this.types.ProductLine.Type)
                        .field(this.types.ProductLine.Field.Quantity),
                    undefined,
                    Aggregate.Sum)
                .selectAggregates(true)
                .then(
                    result =>
                    {
                        if (!result.aggregates[0].isEmpty)
                        {
                            setValueByFieldInEntity(
                                entity,
                                this.types.Activity.Project.Field.HourBudget,
                                result.aggregates[0].value,
                                commitContext
                            );
                        }
                    });

            // Copy fields
            [
                this.types.Activity.Field.Subject,
                this.types.Activity.Field.DiscountPercentage,
                this.types.Activity.Field.IsVatIncluded,
                this.types.Activity.Field.Currency,
            ].forEach(
                field =>
                    setValueByFieldInEntity(
                        entity,
                        field,
                        relatedEntity.getObjectValueByField(field),
                        commitContext
                    )
            );
        }
    }

    getAutomators(entity: Entity): Automator[]
    {
        const milestones =
            entity.getRelatedEntitiesByDefinition(
                false,
                this.types.Activity.Project.RelationshipDefinition.Milestones);

        return [
            ...milestones.length > 0
                ?
                    [
                        {
                            code: 'ProjectCreationMilestones',
                            name: 'Milestones bij nieuw project',
                            description: 'Laat de milestones uit dit project automatisch aanmaken onder elk volgende project.',
                            automate:
                                () =>
                                    new Automation(
                                        new ParameterDictionary([]),
                                        new CreationTrigger(
                                            this.types.Activity.Project.Type,
                                            undefined),
                                        new CompositeAction(
                                            milestones.map(
                                                milestone =>
                                                    new CompositeActionInvocation(
                                                        uuid(),
                                                        new CreateEntityAction(
                                                            new Mapping(
                                                                milestone.entityType,
                                                                Mapping.buildParameter(milestone.entityType),
                                                                [
                                                                    new ValueFieldMapping(
                                                                        new RelationshipInput(
                                                                            milestone.entityType,
                                                                            this.types.Activity.Project.RelationshipDefinition.Milestones,
                                                                            true),
                                                                        new Parameter(
                                                                            MutationTrigger.EntityParameterId,
                                                                            new EntityValueType(this.types.Activity.Project.Type),
                                                                            true,
                                                                            undefined)),
                                                                    ...[
                                                                        this.types.Milestone.Field.Name,
                                                                        this.types.Milestone.Field.Budget,
                                                                        this.types.Milestone.Field.HourBudget
                                                                    ]
                                                                        .filter(
                                                                            field =>
                                                                                milestone.hasValueForField(field))
                                                                        .map(
                                                                            field =>
                                                                                new ValueFieldMapping(
                                                                                    new FieldInput(
                                                                                        milestone.entityType,
                                                                                        field),
                                                                                    new PrimitiveValue(
                                                                                        milestone.getDataObjectValueByField(field).clone())))
                                                                ],
                                                                []))))))

                        }
                    ]
                :
                    [],
            ...super.getAutomators(entity)
        ]
    }

    // ----------------------- Private logic ------------------------
}
