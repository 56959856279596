import { observable } from 'mobx';
import Computation from './Computation';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Validation from '../../Validation/Validation';

export class AiPromptComputationPart
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref computation: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(
        id: string,
        computation: Computation<any, any>
    )
    {
        this.id = id;
        this.computation = computation;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return this.computation.validate();
    }

    isAsync(): boolean
    {
        return this.computation.isAsync();
    }

    getDependencies(): Dependency[]
    {
        return this.computation.getDependencies();
    }

    toDescriptor()
    {
        return {
            id: this.id,
            computation: this.computation.toDescriptor()
        };
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext
    )
    {
        return new AiPromptComputationPart(
            descriptor.id,
            await getComputationFromDescriptor(
                descriptor.computation,
                dependencyContext
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
