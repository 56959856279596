import React from 'react';
import { observer } from 'mobx-react';
import { Card } from '@material-ui/core';
import styles from '../../Organization/Header/Header.module.scss';
import Hidden from '@material-ui/core/Hidden';
import { viewInset } from '../../../../../../@Resource/Theme/Theme';
import PortalEnvironmentButton from './PortalEnvironmentButton/PortalEnvironmentButton';
import Logo from '../../../../../../@Resource/Image/Logo/White.svg';

export interface PortalHeaderProps
{

}

const PortalHeader: React.FC<PortalHeaderProps> =
    props =>
    {
        return <>
            <Card
                className={styles.headerCardWithShadow}
                square
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        paddingLeft: 10,
                        paddingRight: 15,
                    }}
                >
                    {/* Mobile */}
                    {/*<Hidden*/}
                    {/*    mdUp*/}
                    {/*>*/}
                    {/*</Hidden>*/}
                    {/*/!* Non-mobile *!/*/}
                    {/*<Hidden*/}
                    {/*    smDown*/}
                    {/*>*/}
                        <div>
                            <img
                                alt="Tribe"
                                src={Logo}
                                style={{
                                    cursor: 'pointer',
                                    padding: '0 5px',
                                    marginLeft: viewInset,
                                    // width: 165,
                                    height: 28
                                }}
                            />
                        </div>
                        <Hidden
                            xsDown
                        >
                            <div
                                style={{
                                    width: '20%'
                                }}
                            >
                                {/*<SearchBar*/}
                                {/*    store={store.searchBarStore}*/}
                                {/*/>*/}
                            </div>
                        </Hidden>
                        <div
                            style={{
                                flex: '1 1 auto',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            {/*{*/}
                            {/*    store.currentUserStore.isAllowedInConfiguration &&*/}
                            {/*    <div*/}
                            {/*        style={{*/}
                            {/*            marginRight: 15,*/}
                            {/*            alignSelf: 'center'*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <TrialStatus />*/}
                            {/*    </div>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    store.entityTypeStore.bespoke.types.TimeRegistration.Type &&*/}
                            {/*    <div*/}
                            {/*        style={{*/}
                            {/*            marginRight: 5,*/}
                            {/*            alignSelf: 'center'*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <RecordButton />*/}
                            {/*    </div>*/}
                            {/*}*/}
                            {/*<div*/}
                            {/*    style={{*/}
                            {/*        marginRight: 5,*/}
                            {/*        alignSelf: 'center'*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <EntityConstructorButton*/}
                            {/*        iconColor="white"*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div*/}
                            {/*    style={{*/}
                            {/*        marginRight: 10,*/}
                            {/*        alignSelf: 'center'*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <NotificationButton />*/}
                            {/*</div>*/}
                            {/*<div*/}
                            {/*    style={{*/}
                            {/*        marginRight: 13,*/}
                            {/*        alignSelf: 'center'*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <IconButton*/}
                            {/*        icon="storefront"*/}
                            {/*        onClick={() => openMarketplace()}*/}
                            {/*        tooltip={*/}
                            {/*            <LocalizedText*/}
                            {/*                code="Header.OpenMarketplace"*/}
                            {/*                value="Marketplace openen"*/}
                            {/*            />*/}
                            {/*        }*/}
                            {/*        color="white"*/}
                            {/*        iconSize={19}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*{*/}
                            {/*    store.currentUserStore.limitedAccessTeams.length > 0 &&*/}
                            {/*    <div*/}
                            {/*        style={{*/}
                            {/*            display: 'flex',*/}
                            {/*            marginRight: 15,*/}
                            {/*            zIndex: 100,*/}
                            {/*            alignSelf: 'center'*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <TeamSwitcher />*/}
                            {/*    </div>*/}
                            {/*}*/}
                            {/*<div*/}
                            {/*    style={{*/}
                            {/*        display: 'flex',*/}
                            {/*        marginRight: 5,*/}
                            {/*        zIndex: 100,*/}
                            {/*        alignSelf: 'center'*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <UserButton />*/}
                            {/*</div>*/}
                            <div
                                style={{
                                    display: 'flex',
                                    marginRight: 10,
                                    zIndex: 100,
                                    alignSelf: 'center'
                                }}
                            >
                                <PortalEnvironmentButton />
                            </div>
                        </div>
                    {/*</Hidden>*/}
                </div>
            </Card>
        </>;
    };

export default observer(PortalHeader);
