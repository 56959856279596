import { EntityFieldPath } from '../../../../../../../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import Computation from '../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import getComputationFromDescriptor from '../../../../../../../../../@Api/Automation/Api/getComputationFromDescriptor';
import FunctionContext from '../../../../../../../../../@Api/Automation/Function/FunctionContext';

export interface FormLinkField
{
    fieldPath: EntityFieldPath;
    value: Computation<any, any>;
}

export interface FormLinkProperties
{
    formId: string;
    fields: FormLinkField[];
}

export function fromDescriptor(descriptor: any, functionContext: FunctionContext): FormLinkProperties
{
    if (!descriptor)
    {
        return {
            formId: undefined,
            fields: []
        }
    }
    else
    {
        const values: FormLinkField[] = [];
        if (descriptor.fields)
        {
            for (const formLinkField of descriptor.fields)
            {
                if (formLinkField.value)
                {
                    getComputationFromDescriptor(
                        formLinkField.value,
                        functionContext
                    )
                    .then(
                        result =>
                            values.push({
                                fieldPath: EntityFieldPath.construct(formLinkField.fieldPath),
                                value: result
                            })
                    )
                    .catch(
                        reason =>
                            console.error(reason)
                    )
                }
                else
                {
                    values.push({
                        fieldPath: EntityFieldPath.construct(formLinkField.fieldPath),
                        value: undefined
                    })
                }
            }
        }
        return {
            formId: descriptor.formId,
            fields: values
        }
    }

}