import React from 'react';
import { observer } from 'mobx-react-lite';
import ParameterDictionary from '../../../../@Api/Automation/Parameter/ParameterDictionary';
import Layout from '../../../../@Api/Layout/Layout';
import CompositeLayout from '../../../../@Api/Layout/Type/CompositeLayout';
import CompositeLayoutViewer, { CompositeLayoutViewerProps } from '../Type/Composite/Viewer/CompositeLayoutViewer';
import InsetLayout from '../../../../@Api/Layout/Type/InsetLayout';
import InsetLayoutViewer, { InsetLayoutViewerProps } from '../Type/Inset/InsetLayoutViewer';
import EntityInputLayout from '../../../../@Api/Layout/Type/EntityInputLayout';
import EntityInputLayoutViewer, { EntityInputLayoutViewerProps } from '../Type/EntityInput/EntityInputLayoutViewer';
import ParameterAssignment from '../../../../@Api/Automation/Parameter/ParameterAssignment';
import ExpansionLayout from '../../../../@Api/Layout/Type/ExpansionLayout';
import ComputationLayout from '../../../../@Api/Layout/Type/ComputationLayout';
import ExpansionLayoutViewer, { ExpansionLayoutViewerProps } from '../Type/Expansion/ExpansionLayoutViewer';
import ComputationLayoutViewer, { ComputationLayoutViewerProps } from '../Type/Computation/ComputationLayoutViewer';
import ConditionalLayout from '../../../../@Api/Layout/Type/ConditionalLayout';
import ConditionalLayoutViewer, { ConditionalLayoutViewerProps } from '../Type/Conditional/ConditionalLayoutViewer';
import OptionalRelatedEntityLayout from '../../../../@Api/Layout/Type/OptionalRelatedEntityLayout';
import OptionalRelatedEntityLayoutViewer, { OptionalRelatedEntityLayoutViewerProps } from '../Type/OptionalRelatedEntity/OptionalRelatedEntityLayoutViewer';
import CardLayout from '../../../../@Api/Layout/Type/CardLayout';
import CardLayoutViewer, { CardLayoutViewerProps } from '../Type/Card/CardLayoutViewer';
import PortalListLayout from '../../../../@Api/Layout/Type/PortalListLayout';
import PortalListLayoutViewer, { PortalListLayoutViewerProps } from '../Type/PortalList/Viewer/PortalListLayoutViewer';
import ClickLayout from '../../../../@Api/Layout/Type/ClickLayout';
import ClickLayoutViewer, { ClickLayoutViewerProps } from '../Type/Click/ClickLayoutViewer';
import FormLayout from '../../../../@Api/Layout/Type/Form/FormLayout';
import PrimitiveFormInputLayout from '../../../../@Api/Layout/Type/Form/Input/PrimitiveFormInputLayout';
import FormLayoutViewer, { FormLayoutViewerProps } from '../Type/Form/FormLayoutViewer';
import PrimitiveFormInputLayoutViewer, { PrimitiveFormInputLayoutViewerProps } from '../Type/FormInput/PrimitiveFormInput/PrimitiveFormInputLayoutViewer';
import LabelButtonLayout from '../../../../@Api/Layout/Type/LabelButtonLayout';
import LabelButtonLayoutViewer, { LabelButtonLayoutViewerProps } from '../Type/LabelButton/LabelButtonLayoutViewer';
import SelectionFormInputLayout from '../../../../@Api/Layout/Type/Form/Input/SelectionFormInputLayout';
import SelectionFormInputLayoutViewer, { SelectionFormInputLayoutViewerProps } from '../Type/FormInput/SelectionFormInput/SelectionFormInputLayoutViewer';
import LabelLayout from '../../../../@Api/Layout/Type/LabelLayout';
import LabelLayoutViewer, { LabelLayoutViewerProps } from '../Type/Label/LabelLayoutViewer';
import PortalImageLayout from '../../../../@Api/Layout/Type/PortalImageLayout';
import PortalImageLayoutViewer, { PortalImageLayoutViewerProps } from '../Type/PortalImage/PortalImageLayoutViewer';
import AppBarLayout from '../../../../@Api/Layout/Type/AppBarLayout';
import AppBarLayoutViewer, { AppBarLayoutViewerProps } from '../Type/AppBar/AppBarLayoutViewer';
import CenteredBodyLayout from '../../../../@Api/Layout/Type/CenteredBodyLayout';
import CenteredBodyLayoutViewer, { CenteredBodyLayoutViewerProps } from '../Type/CenteredBody/CenteredBodyLayoutViewer';
import PortalRouterLayout from '../../../../@Api/Layout/Type/PortalRouterLayout';
import PortalRouterLayoutViewer, { PortalRouterLayoutViewerProps } from '../Type/PortalRouter/PortalRouterLayoutViewer';
import PortalUserButtonLayout from '../../../../@Api/Layout/Type/PortalUserButtonLayout';
import PortalUserButtonLayoutViewer, { PortalButtonLayoutViewerProps } from '../Type/PortalUserButton/PortalUserButtonLayoutViewer';
import HeaderWithScrollBodyLayout from '../../../../@Api/Layout/Type/HeaderWithScrollBodyLayout';
import HeaderWithScrollBodyLayoutViewer, { HeaderWithScrollBodyLayoutViewerProps } from '../Type/HeaderWithScrollBody/HeaderWithScrollBodyLayoutViewer';
import PortalSwitcherTabBarLayout from '../../../../@Api/Layout/Type/PortalSwitcherTabBarLayout';
import PortalSwitcherTabBarLayoutViewer, { PortalSwitcherTabBarLayoutViewerProps } from '../Type/PortalSwitcherTabBar/PortalSwitcherTabBarLayoutViewer';
import LocalizedLayout from '../../../../@Api/Layout/Type/LocalizedLayout';
import LocalizedLayoutViewer, { LocalizedLayoutViewerProps } from '../Type/Localized/LocalizedLayoutViewer';
import BodyLayout from '../../../../@Api/Layout/Type/BodyLayout';
import BodyLayoutViewer, { BodyLayoutViewerProps } from '../Type/Body/BodyLayoutViewer';
import ResourceImageLayout from '../../../../@Api/Layout/Type/ResourceImageLayout';
import ResourceImageLayoutViewer, { ResourceImageLayoutViewerProps } from '../Type/ResourceImage/ResourceImageLayoutViewer';
import TemplateLayout from '../../../../@Api/Layout/Type/TemplateLayout';
import TemplateLayoutViewer, { TemplateLayoutViewerProps } from '../Type/Template/TemplateLayoutViewer';
import ExpansionGroupLayout from '../../../../@Api/Layout/Type/ExpansionGroupLayout';
import ExpansionGroupLayoutViewer, { ExpansionGroupLayoutViewerProps } from '../Type/ExpansionGroup/ExpansionGroupLayoutViewer';
import DrawingFormInputLayout from '../../../../@Api/Layout/Type/Form/Input/DrawingFormInputLayout';
import DrawingFormInputLayoutViewer, { DrawingFormInputLayoutViewerProps } from '../Type/FormInput/DrawingFormInput/DrawingFormInputLayoutViewer';
import ComputedImageLayout from '../../../../@Api/Layout/Type/ComputedImageLayout';
import ComputedImageLayoutViewer, { ComputedImageLayoutViewerProps } from '../Type/ComputedImage/ComputedImageLayoutViewer';
import FilesFormInputLayout from '../../../../@Api/Layout/Type/Form/Input/FilesFormInputLayout';
import FilesFormInputLayoutViewer, { FilesFormInputLayoutViewerProps } from '../Type/FormInput/FilesFormInput/FilesFormInputLayoutViewer';
import TabBarLayout from '../../../../@Api/Layout/Type/TabBar/TabBarLayout';
import TabBarLayoutViewer, { TabBarLayoutViewerProps } from '../Type/TabBar/TabBarLayoutViewer';
import TableLayout from '../../../../@Api/Layout/Type/Table/TableLayout';
import TableLayoutViewer, { TableLayoutViewerProps } from '../Type/Table/Viewer/TableLayoutViewer';
import EntitySelectionFormInputLayout from '../../../../@Api/Layout/Type/Form/Input/EntitySelectionFormInputLayout';
import EntitySelectionFormInputLayoutViewer, { EntitySelectionFormInputLayoutViewerProps } from '../Type/FormInput/EntitySelectionFormInput/EntitySelectionFormInputLayoutViewer';
import PortalRouteLayout from '../../../../@Api/Layout/Type/PortalRouteLayout';
import PortalRouteLayoutViewer, { PortalRouteLayoutViewerProps } from '../Type/PortalRoute/PortalRouteLayoutViewer';
import CollectionLayout from '../../../../@Api/Layout/Type/CollectionLayout';
import CollectionLayoutViewer, { CollectionLayoutViewerProps } from '../Type/Collection/Viewer/CollectionLayoutViewer';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import EffectLayout from '../../../../@Api/Layout/Type/EffectLayout';
import EffectLayoutViewer, { EffectLayoutViewerProps } from '../Type/Effect/EffectLayoutViewer';
import AddressLookupLayout from '../../../../@Api/Layout/Type/AddressLookupLayout';
import AddressLookupLayoutViewer, { AddressLookupLayoutViewerProps } from '../Type/AddressLookup/AddressLookupLayoutViewer';
import InvalidLayout from '../../../../@Api/Layout/Type/InvalidLayout';
import InvalidLayoutViewer, { InvalidLayoutViewerProps } from '../Type/Invalid/InvalidLayoutViewer';
import PortalTableLayout from '../../../../@Api/Layout/Type/PortalTable/PortalTableLayout';
import PortalTableLayoutViewer, { PortalTableLayoutViewerProps } from '../Type/PortalTable/Viewer/PortalTableLayoutViewer';

export interface LayoutViewerProps<L extends Layout>
{
    layout: L;
    parameterDictionary: ParameterDictionary;
    parameterAssignment: ParameterAssignment;
    commitContext?: CommitContext;
    safeMode?: boolean;
}

const LayoutViewer: React.FC<LayoutViewerProps<Layout>> =
    props =>
    {
        if (props.layout instanceof CompositeLayout)
        {
            return <CompositeLayoutViewer
                {...props as CompositeLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof CollectionLayout)
        {
            return <CollectionLayoutViewer
                {...props as CollectionLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof LocalizedLayout)
        {
            return <LocalizedLayoutViewer
                {...props as LocalizedLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof InsetLayout)
        {
            return <InsetLayoutViewer
                {...props as InsetLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof CardLayout)
        {
            return <CardLayoutViewer
                {...props as CardLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof ExpansionGroupLayout)
        {
            return <ExpansionGroupLayoutViewer
                {...props as ExpansionGroupLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof ExpansionLayout)
        {
            return <ExpansionLayoutViewer
                {...props as ExpansionLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof ClickLayout)
        {
            return <ClickLayoutViewer
                {...props as ClickLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof LabelButtonLayout)
        {
            return <LabelButtonLayoutViewer
                {...props as LabelButtonLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof LabelLayout)
        {
            return <LabelLayoutViewer
                {...props as LabelLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof ComputationLayout)
        {
            return <ComputationLayoutViewer
                {...props as ComputationLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof ConditionalLayout)
        {
            return <ConditionalLayoutViewer
                {...props as ConditionalLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof EntityInputLayout)
        {
            return <EntityInputLayoutViewer
                {...props as EntityInputLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof OptionalRelatedEntityLayout)
        {
            return <OptionalRelatedEntityLayoutViewer
                {...props as OptionalRelatedEntityLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof TableLayout)
        {
            return <TableLayoutViewer
                {...props as TableLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof PortalListLayout)
        {
            return <PortalListLayoutViewer
                {...props as PortalListLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof PortalTableLayout)
        {
            return <PortalTableLayoutViewer
                {...props as PortalTableLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof ResourceImageLayout)
        {
            return <ResourceImageLayoutViewer
                {...props as ResourceImageLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof PortalImageLayout)
        {
            return <PortalImageLayoutViewer
                {...props as PortalImageLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof ComputedImageLayout)
        {
            return <ComputedImageLayoutViewer
                {...props as ComputedImageLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof FormLayout)
        {
            return <FormLayoutViewer
                {...props as FormLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof PrimitiveFormInputLayout)
        {
            return <PrimitiveFormInputLayoutViewer
                {...props as PrimitiveFormInputLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof EntitySelectionFormInputLayout)
        {
            return <EntitySelectionFormInputLayoutViewer
                {...props as EntitySelectionFormInputLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof SelectionFormInputLayout)
        {
            return <SelectionFormInputLayoutViewer
                {...props as SelectionFormInputLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof DrawingFormInputLayout)
        {
            return <DrawingFormInputLayoutViewer
                {...props as DrawingFormInputLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof FilesFormInputLayout)
        {
            return <FilesFormInputLayoutViewer
                {...props as FilesFormInputLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof AppBarLayout)
        {
            return <AppBarLayoutViewer
                {...props as AppBarLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof HeaderWithScrollBodyLayout)
        {
            return <HeaderWithScrollBodyLayoutViewer
                {...props as HeaderWithScrollBodyLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof BodyLayout)
        {
            return <BodyLayoutViewer
                {...props as BodyLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof CenteredBodyLayout)
        {
            return <CenteredBodyLayoutViewer
                {...props as CenteredBodyLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof TabBarLayout)
        {
            return <TabBarLayoutViewer
                {...props as TabBarLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof PortalRouterLayout)
        {
            return <PortalRouterLayoutViewer
                {...props as PortalRouterLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof PortalSwitcherTabBarLayout)
        {
            return <PortalSwitcherTabBarLayoutViewer
                {...props as PortalSwitcherTabBarLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof PortalUserButtonLayout)
        {
            return <PortalUserButtonLayoutViewer
                {...props as PortalButtonLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof PortalRouteLayout)
        {
            return <PortalRouteLayoutViewer
                {...props as PortalRouteLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof TemplateLayout)
        {
            return <TemplateLayoutViewer
                {...props as TemplateLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof EffectLayout)
        {
            return <EffectLayoutViewer
                {...props as EffectLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof AddressLookupLayout)
        {
            return <AddressLookupLayoutViewer
                {...props as AddressLookupLayoutViewerProps}
            />;
        }
        else if (props.layout instanceof InvalidLayout)
        {
            return <InvalidLayoutViewer
                {...props as InvalidLayoutViewerProps}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(LayoutViewer);
