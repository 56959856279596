import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import QueryComputation from '../../../../../@Api/Automation/Function/Computation/QueryComputation';
import { findAssistantEntityTypeOrThrow } from '../findAssistantEntityTypeOrThrow';
import { findAssistantPredicate } from '../Predicate/findAssistantPredicate';
import Query from '../../../../../@Api/Automation/Query/Query';
import uuid from '../../../../../@Util/Id/uuid';
import { AssistantAggregateQueryComputation } from '../../Model/Automation/Computation/AssistantAggregateQueryComputation';
import AggregateQuery from '../../../../../@Api/Automation/Query/AggregateQuery';
import { findAssistantAggregate } from '../findAssistantAggregate';
import { findAssistantFieldPath } from '../findAssistantFieldPath';

export function findAssistantAggregateQueryComputation(
    computation: AssistantAggregateQueryComputation,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Computation<any, any>
{
    const entityType = findAssistantEntityTypeOrThrow(computation.entityType);
    const parameter =
        Query.getResultParameter(
            entityType,
            uuid()
        );
    const filter =
        computation.filter
            ? findAssistantPredicate(
                computation.filter,
                parameters.getNewDictionaryWithParameter(parameter),
                parameter
            )
            : undefined;
    const aggregate = findAssistantAggregate(computation.aggregate);
    const aggregateFieldPath =
        findAssistantFieldPath(
            entityType.path(),
            computation.field
        );

    return new QueryComputation(
        new AggregateQuery(
            entityType,
            parameter,
            filter,
            aggregate,
            aggregateFieldPath
        )
    );
}
