import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationBox from '../../Box/ComputationBox';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import NumberFromTextComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/NumberFromTextComputation';

export interface NumberFromTextComputationEditorProps extends ComputationEditorProps<ValueType<any>, NumberFromTextComputation>
{

}

const NumberFromTextComputationEditor: React.FC<NumberFromTextComputationEditorProps> =
    props =>
    {
        const setValue =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.value = value),
                [
                    props.value
                ]);

        return <Input
            label="Nummer uit tekst"
            labelPosition="left"
        >
            <ComputationBox>
                <ComputationEditor
                    context={props.context}
                    value={props.value.value}
                    onChange={setValue}
                />
            </ComputationBox>
        </Input>;
    };

export default observer(NumberFromTextComputationEditor);
