export function splitArrayIntoArraysOfUpToN<T>(
    array: T[],
    n: number
): T[][]
{
    const numArrays = Math.ceil(array.length / n);

    if (numArrays < 2)
    {
        return [array];
    }
    else
    {
        let i = 0;
        const arrays: T[][] = [];
        let currentArray: T[] | undefined;

        for (const element of array)
        {
            if (i % n === 0)
            {
                currentArray = [];
                arrays.push(currentArray);
            }

            currentArray.push(element);
            i++;
        }

        return arrays;
    }
}