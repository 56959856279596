import { useComputed } from 'mobx-react-lite';
import { default as ResourcePlannerModel } from '../Model/ResourcePlanner';
import ResourceSelection from '../Model/ResourceSelection';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import { Comparator } from '../../../DataObject/Model/Comparator';
import useTypes from '../../Type/Api/useTypes';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import useFalseValue from '../../../../../@Future/Util/Hook/useFalseValue';
import useTrueValue from '../../../../../@Future/Util/Hook/useTrueValue';
import ResourceSelectionRecurrence from '../Model/ResourceSelectionRecurrence';

export default function useEmployeePlanner()
{
    const types = useTypes();

    const falseValue = useFalseValue();
    const trueValue = useTrueValue();

    return useComputed(
        () =>
        {
            const employeeParameter =
                new Parameter(
                    'Employee',
                    new EntityValueType(types.Relationship.Person.Contact.Employee.Type),
                    true,
                    types.Relationship.Person.Contact.Employee.Type.getName()
                );

            const teamParameter =
                new Parameter(
                    'Team',
                    new EntityValueType(types.Team.Type),
                    false,
                    types.Team.Type.getName());

            const skillParameter =
                new Parameter(
                    'Skill',
                    new EntityValueType(types.Datastore.Skill.Type),
                    false,
                    types.Datastore.Skill.Type.getName()
                );

            const functionParameter =
                new Parameter(
                    'Function',
                    new EntityValueType(types.Datastore.Function.Type),
                    false,
                    types.Datastore.Function.Type.getName()
                );

            const workOrderParameter =
                types.Activity.WorkOrder.Type &&
                    new Parameter(
                        'WorkOrder',
                        new EntityValueType(types.Activity.WorkOrder.Type),
                        true,
                        types.Activity.WorkOrder.Type.getName()
                    );

            const calendarItemParameter =
                new Parameter(
                    'CalendarItem',
                    new EntityValueType(types.CalendarItem.Type),
                    true,
                    types.CalendarItem.Type.getName()
                );

            const attendeeInCalendarItemParameter =
                new Parameter(
                    'AttendeeInCalendarItem',
                    new EntityValueType(types.CalendarItem.Type),
                    true,
                    types.CalendarItem.Type.getName());

            const hideCalendarItemRelationshipDefinitions =
                [
                    types.Activity.Appointment.RelationshipDefinition.CalendarItem,
                    types.Activity.Task.RelationshipDefinition.CalendarItem,
                    types.Activity.WorkOrder.RelationshipDefinition.CalendarItem,
                    types.Activity.Event.RelationshipDefinition.CalendarItem,
                    types.CourseLesson.RelationshipDefinition.CalendarItem,
                ]
                    .filter(
                        relationshipDefinition =>
                            relationshipDefinition
                    );
            const calendarItemRecurrence =
                new ResourceSelectionRecurrence(
                    EntityPath.fromEntityType(types.CalendarItem.Type)
                        .field(types.CalendarItem.Field.RecurrenceStartDate),
                    EntityPath.fromEntityType(types.CalendarItem.Type)
                        .field(types.CalendarItem.Field.RecurrenceEndDate),
                    EntityPath.fromEntityType(types.CalendarItem.Type)
                        .field(types.CalendarItem.Field.RecurrencePattern)
                );

            const appointmentParameter =
                new Parameter(
                    'Appointment',
                    new EntityValueType(types.Activity.Appointment.Type),
                    true,
                    types.Activity.Appointment.Type.getName()
                );

            const attendeeInAppointmentParameter =
                new Parameter(
                    'AttendeeInAppointment',
                    new EntityValueType(types.Activity.Appointment.Type),
                    true,
                    types.Activity.Appointment.Type.getName(),
                    undefined);

            const memberInWorkOrderParameter =
                types.Activity.WorkOrder.Type &&
                new Parameter(
                    'MemberInWorkOrder',
                    new EntityValueType(types.Activity.WorkOrder.Type),
                    true,
                    types.Activity.WorkOrder.Type.getName(),
                    undefined);

            const taskParameter =
                new Parameter(
                    'Task',
                    new EntityValueType(types.Activity.Task.Type),
                    true,
                    types.Activity.Task.Type.getName()
                );

            const timeRegistrationParameter =
                types.TimeRegistration.Type &&
                    new Parameter(
                        'TimeRegistration',
                        new EntityValueType(types.TimeRegistration.Type),
                        true,
                        types.TimeRegistration.Type.getName()
                    );

            const eventParameter =
                types.Activity.Event.Type &&
                new Parameter(
                    'Event',
                    new EntityValueType(types.Activity.Event.Type),
                    true,
                    types.Activity.Event.Type.getName()
                );

            const courseLessonParameter =
                types.CourseLesson.Type &&
                new Parameter(
                  'CourseLesson',
                  new EntityValueType(types.CourseLesson.Type),
                  true,
                  types.CourseLesson.Type.getName()
                );

            return new ResourcePlannerModel(
                employeeParameter,
                new ParameterDictionary([
                    teamParameter,
                    skillParameter,
                    functionParameter,
                ]),
                new CompositePredicate(
                    LogicalOperator.And,
                    [
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .field(types.Relationship.Person.Contact.Employee.Field.IsSupport)),
                            Comparator.Equals,
                            falseValue
                        ),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .field(types.Relationship.Field.IsFormer)),
                            Comparator.Equals,
                            falseValue
                        ),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .joinTo(
                                        types.Team.RelationshipDefinition.Members,
                                        true)
                                    .field()),
                            Comparator.Equals,
                            teamParameter
                        ),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .joinTo(
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Skills,
                                        false)
                                    .field()),
                            Comparator.Equals,
                            skillParameter
                        ),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                employeeParameter,
                                EntityPath.fromEntityType(employeeParameter.type.type)
                                    .joinTo(
                                        types.Relationship.Person.Contact.RelationshipDefinition.Function,
                                        false)
                                    .field()),
                            Comparator.Equals,
                            functionParameter
                        ),
                    ]
                ),
                [
                    workOrderParameter &&
                        new ResourceSelection(
                            workOrderParameter,
                            EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                .joinTo(
                                    types.Activity.WorkOrder.RelationshipDefinition.Employee,
                                    false
                                ),
                            EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                .field(types.Activity.WorkOrder.Field.StartDate),
                            EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                .field(types.Activity.WorkOrder.Field.EndDate),
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    workOrderParameter,
                                    EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                        .field(types.Activity.WorkOrder.Field.HasCalendarItem)),
                                    Comparator.Equals,
                                    trueValue
                            ),
                            undefined,
                            true
                        ),
                    memberInWorkOrderParameter &&
                        new ResourceSelection(
                            memberInWorkOrderParameter,
                            EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                .joinTo(
                                    types.Activity.WorkOrder.RelationshipDefinition.Members,
                                    false
                                )
                                .joinTo(
                                    types.WorkOrderMember.RelationshipDefinition.Employee,
                                    false
                                ),
                            EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                .field(types.Activity.WorkOrder.Field.StartDate),
                            EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                .field(types.Activity.WorkOrder.Field.EndDate),
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    memberInWorkOrderParameter,
                                    EntityPath.fromEntityType(types.Activity.WorkOrder.Type)
                                        .field(types.Activity.WorkOrder.Field.HasCalendarItem)),
                                Comparator.Equals,
                                trueValue
                            ),
                            undefined,
                            false,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            types.WorkOrderMember.Type.getName()
                        ),
                    // CalenderItems where employee is the organizer
                    new ResourceSelection(
                        calendarItemParameter,
                        EntityPath.fromEntityType(types.CalendarItem.Type)
                            .joinTo(
                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.CalendarItems,
                                true
                            ),
                        EntityPath.fromEntityType(types.CalendarItem.Type)
                            .field(types.CalendarItem.Field.StartDate),
                        EntityPath.fromEntityType(types.CalendarItem.Type)
                            .field(types.CalendarItem.Field.EndDate),
                        new CompositePredicate(
                            LogicalOperator.And,
                            hideCalendarItemRelationshipDefinitions
                                .map(
                                    relationshipDefinition =>
                                        new ComparisonPredicate(
                                            new ValueFromEntityComputation(
                                                calendarItemParameter,
                                                EntityPath.fromEntityType(types.CalendarItem.Type)
                                                    .joinTo(
                                                        relationshipDefinition,
                                                        true
                                                    )
                                                    .field()
                                            ),
                                            Comparator.IsNotDefined,
                                            undefined
                                        )
                                )
                        ),
                        undefined,
                        false,
                        undefined,
                        undefined,
                        undefined,
                        calendarItemRecurrence
                    ),
                    // CalenderItems where employee is one of attendees but not the organizer
                    new ResourceSelection(
                        attendeeInCalendarItemParameter,
                        EntityPath.fromEntityType(types.CalendarItem.Type)
                            .joinTo(
                                types.CalendarItem.RelationshipDefinition.Attendees,
                                false
                            ),
                        EntityPath.fromEntityType(types.CalendarItem.Type)
                            .field(types.CalendarItem.Field.StartDate),
                        EntityPath.fromEntityType(types.CalendarItem.Type)
                            .field(types.CalendarItem.Field.EndDate),
                        new CompositePredicate(
                            LogicalOperator.And,
                            [
                                ...hideCalendarItemRelationshipDefinitions
                                    .map(
                                        relationshipDefinition =>
                                            new ComparisonPredicate(
                                                new ValueFromEntityComputation(
                                                    attendeeInCalendarItemParameter,
                                                    EntityPath.fromEntityType(types.CalendarItem.Type)
                                                        .joinTo(
                                                            relationshipDefinition,
                                                            true
                                                        )
                                                        .field()
                                                ),
                                                Comparator.IsNotDefined,
                                                undefined
                                            )
                                    ),
                                // Relationship.Person.Contact.Employee:CalendarItems is not current employee
                                // (these calendaritems are already included with previous ResourceSelection)
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        attendeeInCalendarItemParameter,
                                        EntityPath.fromEntityType(types.CalendarItem.Type)
                                            .joinTo(
                                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.CalendarItems,
                                                true
                                            )
                                            .field()
                                    ),
                                    Comparator.NotEquals,
                                    employeeParameter
                                )
                            ]
                        ),
                        undefined,
                        false,
                        undefined,
                        undefined,
                        undefined,
                        calendarItemRecurrence
                    ),
                    new ResourceSelection(
                        appointmentParameter,
                        EntityPath.fromEntityType(types.Activity.Appointment.Type)
                            .joinTo(
                                types.Activity.Appointment.RelationshipDefinition.Owner,
                                false),
                        EntityPath.fromEntityType(types.Activity.Appointment.Type)
                            .field(types.Activity.Appointment.Field.StartDate),
                        EntityPath.fromEntityType(types.Activity.Appointment.Type)
                            .field(types.Activity.Appointment.Field.EndDate),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        new ResourceSelectionRecurrence(
                            EntityPath.fromEntityType(types.Activity.Appointment.Type)
                                .field(types.Activity.Appointment.Field.RecurrenceStartDate),
                            EntityPath.fromEntityType(types.Activity.Appointment.Type)
                                .field(types.Activity.Appointment.Field.RecurrenceEndDate),
                            EntityPath.fromEntityType(types.Activity.Appointment.Type)
                                .field(types.Activity.Appointment.Field.RecurrencePattern)
                        )
                    ),
                    new ResourceSelection(
                        attendeeInAppointmentParameter,
                        EntityPath.fromEntityType(types.Activity.Appointment.Type)
                            .joinTo(
                                types.Activity.Appointment.RelationshipDefinition.Attendees,
                                false
                            )
                            .joinTo(
                                types.AppointmentAttendee.RelationshipDefinition.Contact,
                                false
                            ),
                        EntityPath.fromEntityType(types.Activity.Appointment.Type)
                            .field(types.Activity.Appointment.Field.StartDate),
                        EntityPath.fromEntityType(types.Activity.Appointment.Type)
                            .field(types.Activity.Appointment.Field.EndDate),
                        undefined,
                        undefined,
                        false,
                        undefined,
                        undefined,
                        undefined,
                        new ResourceSelectionRecurrence(
                            EntityPath.fromEntityType(types.Activity.Appointment.Type)
                                .field(types.Activity.Appointment.Field.RecurrenceStartDate),
                            EntityPath.fromEntityType(types.Activity.Appointment.Type)
                                .field(types.Activity.Appointment.Field.RecurrenceEndDate),
                            EntityPath.fromEntityType(types.Activity.Appointment.Type)
                                .field(types.Activity.Appointment.Field.RecurrencePattern)
                        ),
                        types.AppointmentAttendee.Type.getName()
                    ),
                    new ResourceSelection(
                        taskParameter,
                        EntityPath.fromEntityType(types.Activity.Task.Type)
                            .joinTo(
                                types.Activity.Task.RelationshipDefinition.Assignee,
                                false),
                        EntityPath.fromEntityType(types.Activity.Task.Type)
                            .field(types.Activity.Task.Field.StartDate),
                        EntityPath.fromEntityType(types.Activity.Task.Type)
                            .field(types.Activity.Task.Field.EndDate),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                taskParameter,
                                EntityPath.fromEntityType(types.Activity.Task.Type)
                                    .field(types.Activity.Task.Field.HasCalendarItem)),
                            Comparator.Equals,
                            trueValue
                        ),
                        undefined,
                        true
                    ),
                    timeRegistrationParameter &&
                        new ResourceSelection(
                            timeRegistrationParameter,
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                    true),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .field(types.TimeRegistration.Field.StartDate),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .field(types.TimeRegistration.Field.EndDate),
                            undefined,
                            undefined,
                            undefined
                        ),
                    eventParameter &&
                        new ResourceSelection(
                            eventParameter,
                            EntityPath.fromEntityType(types.Activity.Event.Type)
                                .joinTo(
                                    types.Activity.Event.RelationshipDefinition.Owner,
                                    false
                                ),
                            EntityPath.fromEntityType(types.Activity.Event.Type)
                                .field(types.Activity.Event.Field.StartDate),
                            EntityPath.fromEntityType(types.Activity.Event.Type)
                                .field(types.Activity.Event.Field.EndDate),
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    eventParameter,
                                    EntityPath.fromEntityType(types.Activity.Event.Type)
                                        .field(types.Activity.Event.Field.HasCalendarItem)),
                                Comparator.Equals,
                                trueValue
                            ),
                            undefined,
                            true
                    ),
                    courseLessonParameter &&
                        new ResourceSelection(
                            courseLessonParameter,
                            EntityPath.fromEntityType(types.CourseLesson.Type)
                                .joinTo(
                                    types.CourseLesson.RelationshipDefinition.CalendarItem,
                                    false
                                )
                                .joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.CalendarItems,
                                    true
                                ),
                            EntityPath.fromEntityType(types.CourseLesson.Type)
                                .field(types.CourseLesson.Field.StartDate),
                            EntityPath.fromEntityType(types.CourseLesson.Type)
                                .field(types.CourseLesson.Field.EndDate),
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    courseLessonParameter,
                                    EntityPath.fromEntityType(types.CourseLesson.Type)
                                        .joinTo(
                                            types.Activity.Course.RelationshipDefinition.Lessons,
                                            true
                                        )
                                        .field(types.Activity.Course.Field.DoLessonsHaveCalendarItem)
                                ),
                                Comparator.Equals,
                                trueValue
                            ),
                            undefined,
                            undefined
                        )
                ].filter(selection => selection));
        },
        [
            types,
            falseValue,
            trueValue
        ]);
}
