import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import useCount from '../../../../../Selection/Hooks/useCount';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';

export default function useSpotlerCampaignBouncedCount(spotlerCampaign: Entity, selectedStep: Entity)
{
    const types = useTypes();

    return useCount(
        types.Activity.SpotlerCampaignResult.Type,
        (builder) =>
            builder
                .where(
                    cb =>
                        cb.relatedToEntity(
                            EntityPath.fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                .joinTo(
                                    types.Activity.SpotlerCampaign.RelationshipDefinition.Results,
                                    true
                                ),
                            spotlerCampaign
                        )
                )
                .where(
                    cb =>
                        cb.eq(
                            EntityPath.fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                .field(types.Activity.SpotlerCampaignResult.Field.IsBounced),
                            null,
                            true
                        )
                )
                .if(
                    () => selectedStep !== undefined,
                    cb =>
                        cb.where(
                            cb => cb.relatedToEntity(
                                EntityPath
                                    .fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                    .joinTo(
                                        types.SpotlerCampaignStep.RelationshipDefinition.Results,
                                        true
                                    ),
                                selectedStep
                            )
                        )
                )
        ,
        [
            types,
            spotlerCampaign,
            selectedStep
        ]
    );
}