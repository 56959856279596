import Validation from '../../../Automation/Validation/Validation';
import Dependency from '../../../Automation/Parameter/Dependency';
import Value from '../../../Automation/Value/Value';
import LayoutAction from '../LayoutAction';
import EmptyValue from '../../../Automation/Value/EmptyValue';
import LayoutDependencyContext from '../../LayoutDependencyContext';
import ValueType from '../../../Automation/Value/Type/ValueType';
import EmptyValueType from '../../../Automation/Value/Type/EmptyValueType';
import LayoutContext from '../../LayoutContext';

export default class EmptyAction extends LayoutAction
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async apply(context: LayoutContext): Promise<Value<any, any>>
    {
        return EmptyValue.instance;
    }

    getReturnType(): ValueType<any>
    {
        return EmptyValueType.instance;
    }

    getName(): string
    {
        return undefined;
    }

    getDependencies(): Dependency[]
    {
        return [];
    }

    validate(): Validation[]
    {
        return [];
    }

    toDescriptor()
    {
        return {
            type: 'Empty'
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new EmptyAction();
    }

    // ----------------------- Private logic ------------------------
}
