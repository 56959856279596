import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react';
import React from 'react';
import useTypes from '../../../../../Type/Api/useTypes';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import TextEditor from '../../../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import Checkbox from '../../../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';

export interface EInvoicingStatusProps
{
    entity: Entity;
}

export interface InvoiceSendStatus
{
    id: string;
    sendStatus: string;
    statusMessage?: string;
    failReason?: string;
    isValid: boolean;
    isSent: boolean;
    isDelivered: boolean;
    sentDateTimeUtc?: Date;
    deliveredDateTimeUtc?: Date;
}

export const EInvoicingStatus: React.FC<EInvoicingStatusProps> =
    observer(
        ({
             entity
         }) =>
        {
            const types = useTypes();

            const metaData = useEntityValue<InvoiceSendStatus>(
                entity,
                types.ExternalId.Field.Metadata
            );

            return metaData &&
                <CardInset
                    left={false}
                >
                <ViewGroupItem>
                    <Input
                        label={
                            <LocalizedText
                                code="Connector.EInvoicing.Id"
                                value="Id"
                            />
                        }
                        labelPosition="left"
                    >
                        <TextEditor
                            value={metaData.id}
                            disabled
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        label={
                            <LocalizedText
                                code="Connector.EInvoicing.SendStatus"
                                value="Status"
                            />
                        }
                        labelPosition="left"
                    >
                        <TextEditor
                            value={metaData.sendStatus}
                            disabled
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        label={
                            <LocalizedText
                                code="Connector.EInvoicing.StatusMessage"
                                value="Status bericht"
                            />
                        }
                        labelPosition="left"
                    >
                        <TextEditor
                            value={metaData.statusMessage}
                            disabled
                        />
                    </Input>
                </ViewGroupItem>

                { metaData.failReason && metaData.failReason.length > 0 &&
                    <ViewGroupItem>
                        <Input
                            label={
                                <LocalizedText
                                    code="Connector.EInvoicing.FailReason"
                                    value="Reden van falen"
                                />
                            }
                            labelPosition="left"
                        >
                            <TextEditor
                                value={metaData.statusMessage}
                                disabled
                            />
                        </Input>
                    </ViewGroupItem>
                }

                    <ViewGroupItem>
                        <Input
                            label={
                                <LocalizedText
                                    code="Connector.EInvoicing.CheckBoxes"
                                    value="Statussen"
                                />
                            }
                            labelPosition="left"
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                            >
                                <ViewGroupItem

                                >
                                    <Input
                                        label={
                                            <LocalizedText
                                                code="Connector.EInvoicing.isValid"
                                                value="Gevalideerd"
                                            />
                                        }
                                        labelPosition="right"
                                    >
                                        <Checkbox
                                            checked={metaData.isValid}
                                            disabled
                                        />
                                    </Input>
                                </ViewGroupItem>

                                <ViewGroupItem>
                                    <Input
                                        label={
                                            <LocalizedText
                                                code="Connector.EInvoicing.isSent"
                                                value="Verstuurd"
                                            />
                                        }
                                        labelPosition="right"
                                    >
                                        <Checkbox
                                            checked={metaData.isSent}
                                            disabled
                                        />
                                    </Input>
                                </ViewGroupItem>

                                <ViewGroupItem>
                                    <Input
                                        label={
                                            <LocalizedText
                                                code="Connector.EInvoicing.IsDelivered"
                                                value="Ontvangen"
                                            />
                                        }
                                        labelPosition="right"
                                    >
                                        <Checkbox
                                            checked={metaData.isDelivered}
                                            disabled
                                        />
                                    </Input>
                                </ViewGroupItem>
                            </ViewGroup>
                        </Input>
                    </ViewGroupItem>


                { metaData.sentDateTimeUtc &&
                    <ViewGroupItem>
                        <Input
                            label={
                                <LocalizedText
                                    code="Connector.EInvoicing.SentDate"
                                    value="Verstuurd op"
                                />
                            }
                            labelPosition="left"
                        >
                            <TextEditor
                                value={metaData.sentDateTimeUtc.toString()}
                                disabled
                            />
                        </Input>
                    </ViewGroupItem>
                }

                { metaData.deliveredDateTimeUtc &&
                    <ViewGroupItem>
                        <Input
                            label={
                                <LocalizedText
                                    code="Connector.EInvoicing.DeliveredDate"
                                    value="Ontvangen op"
                                />
                            }
                            labelPosition="left"
                        >
                            <TextEditor
                                value={metaData.deliveredDateTimeUtc.toString()}
                                disabled
                            />
                        </Input>
                    </ViewGroupItem>
                }
            </CardInset>

        }
    );
