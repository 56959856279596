import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../Type/Api/useTypes';
import Dialog from '../../../../../@Future/Component/Generic/Dialog/Dialog';
import DialogTitle from '../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import DialogContent from '../../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import DialogActions from '../../../../../@Future/Component/Generic/Dialog/Actions/DialogActions';
import React, { useCallback, useState } from 'react';
import FileDropZone from '../../../../../@Future/Component/Generic/Input/FileDropZone/FileDropZone';
import { FileValue } from '../../../DataObject/Type/File/FileValue';
import initializeInitialPhase from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/initializeInitialPhase';
import { openEntity } from '../../@Util/openEntity';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import Mapping from '../../TypeImport/Model/Mapping';
import { useNewCommitContext } from '../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import toHumanReadableFilesize from '../../../../../@Util/File/toHumanReadableFilesize';
import useToggle from '../../../../../@Util/Toggle/useToggle';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import PrimaryButton from '../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import FileSpecification from '../../TypeImport/Model/FileSpecification';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';

export interface DuplicateImportDialogProps
{
    importEntity: Entity;
    closeCallback: () => void;
}

const DuplicateImportDialog: React.FC<DuplicateImportDialogProps> =
    ({
        importEntity,
        closeCallback
     }) =>
    {
        const types = useTypes();
        const commitContext = useNewCommitContext();

        const [ file, setFile ] = useState<File>(undefined);
        const [ isDropDownOpen, toggleDropDownOpen ] = useToggle(false);
        const [ isLoading, setIsLoading ] = useState<boolean>(false);
        const [ importEntityType ] =
            useAsyncResult<EntityType>(
                async () =>
                {
                    const fileSpecification =
                        FileSpecification.fromDescriptor(
                            importEntity.getObjectValueByField(types.Import.Field.FileSpecification)
                        );
                    const dependencyContext =
                        new AutomationDependencyContext(
                            fileSpecification.parameters
                        );
                    const mapping =
                        await Mapping.fromDescriptor(
                            importEntity.getObjectValueByField(types.Import.Field.Mapping),
                            dependencyContext,
                            importEntity
                        );

                    return mapping.typeMappings[0].entityType;
                },
                [
                    importEntity,
                    types
                ]
            );

        const onDrop =
            useCallback(
                (files: File[]) =>
                {
                    const file = files.find(() => true);

                    if (file)
                    {
                        const fileName = file.name.toLowerCase();

                        if (fileName.endsWith('.xls')
                            || fileName.endsWith('.xlsx')
                            || fileName.endsWith('.csv'))
                        {
                            setFile(file);
                        }
                        else
                        {
                            loadModuleDirectly(FeedbackStore)
                                .enqueueSnackbar(
                                    <LocalizedText
                                        code="Import.InvalidImportFile"
                                        value="Ongeldig importbestand. Je kan enkel een .xls, .xlsx of een .csv uploaden."
                                    />,
                                    {
                                        variant: 'error'
                                    });
                        }
                    }
                },
                [
                    setFile,
                ]
            );

        const onDuplicateImport =
            useCallback(
                () =>
                {
                    setIsLoading(true);

                    const duplicateImportEntity = commitContext.createEntity(
                        types.Import.Type
                    );

                    duplicateImportEntity.updateRelationship(
                        true,
                        types.EntityType.RelationshipDefinition.Imports,
                        importEntityType.entity
                    );

                    duplicateImportEntity.setValueByField(
                        types.Import.Field.Date,
                        new Date()
                    );

                    duplicateImportEntity.setValueByField(
                        types.Import.Field.File,
                        FileValue.fromFile(file)
                    );

                    duplicateImportEntity.setValueByField(
                        types.Import.Field.Mapping,
                        importEntity.getObjectValueByField(types.Import.Field.Mapping)
                    );

                    duplicateImportEntity.setValueByField(
                        types.Import.Field.FileSpecification,
                        importEntity.getObjectValueByField(types.Import.Field.FileSpecification)
                    );

                    return initializeInitialPhase(duplicateImportEntity)
                        .then(
                            () =>
                            {
                                commitContext
                                    .commit()
                                    .then(() =>
                                    {
                                        openEntity(duplicateImportEntity).finally();
                                    })
                                    .catch(
                                        error =>
                                        {
                                            console.error(error);

                                            loadModuleDirectly(FeedbackStore)
                                                .enqueueSnackbar(
                                                    error.message,
                                                    {
                                                        variant: 'error'
                                                    });
                                        }
                                    )
                                    .finally(
                                        () =>
                                            closeCallback()
                                    )
                            }
                        );
                },
                [
                    commitContext,
                    importEntity,
                    importEntityType,
                    types,
                    file,
                    closeCallback,
                    setIsLoading
                ]);

        return <Dialog
            onClose={closeCallback}
            open={true}
        >
            <DialogTitle>
                {
                    importEntityType &&
                    <LocalizedText
                        code="DuplicateImportDialog.Title"
                        value="Dupliceer ${entityType} import"
                        entityType={importEntityType.getName(true)}
                    />
                }
            </DialogTitle>
            <DialogContent>
                <ViewGroup
                    orientation="vertical"
                    spacing={15}
                >
                    <ViewGroupItem>
                        {types.Import.Field.File.getName()}
                    </ViewGroupItem>
                    {
                        file &&
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                                alignment="center"
                            >
                                <ViewGroupItem>
                                    {file.name}
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    {"(" + toHumanReadableFilesize(file.size, 0) + ")"}
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <IconButton
                                        icon="edit"
                                        tooltip={
                                            <LocalizedText
                                                code="Generic.Edit"
                                            />
                                        }
                                        onClick={toggleDropDownOpen}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <IconButton
                                        icon="delete"
                                        tooltip={
                                            <LocalizedText
                                                code="Generic.Delete"
                                            />
                                        }
                                        onClick={() => setFile(undefined)}
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                    }
                    {
                        (!file || isDropDownOpen) &&
                        <ViewGroupItem>
                            <FileDropZone
                                onDrop={onDrop}
                            />
                        </ViewGroupItem>
                    }
                </ViewGroup>
            </DialogContent>
            <DialogActions>
                <PrimaryButton
                    label={
                        <LocalizedText
                            code="Generic.Duplicate"
                            value="Dupliceren"
                        />
                    }
                    onClick={onDuplicateImport}
                    disabled={!file || isLoading || !importEntityType}
                    loading={isLoading}
                />
            </DialogActions>
        </Dialog>;
    };

export default DuplicateImportDialog;
