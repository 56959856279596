import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import ValueFromDataSourceValueComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/ValueFromDataSourceValueComputation';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import StaticSelectbox from '../../../../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import Parameter from '../../../../../../../../../../@Api/Automation/Parameter/Parameter';
import DataSourceValueType from '../../../../../../../../../../@Api/Automation/Value/Type/DataSourceValueType';
import ComputationBox from '../../Box/ComputationBox';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';

export interface ValueFromDataSourceValueComputationEditorProps extends ComputationEditorProps<ValueType<any>, ValueFromDataSourceValueComputation>
{

}

const ValueFromDataSourceValueComputationEditor: React.FC<ValueFromDataSourceValueComputationEditorProps> =
    props =>
    {
        const { value, context } = props;

        const setValue =
            useCallback(
                (innerValue?: Computation<any, any>) =>
                    runInAction(
                        () =>
                        {
                            value.value = innerValue;
                        }),
                [
                    value
                ]);

        const valueType =
            useComputed(
                () =>
                    value.value.isValid() ? value.value.getType() : undefined,
                [
                    value
                ]);

        const options =
            useMemo(
                () =>
                {
                    if (valueType instanceof DataSourceValueType)
                    {
                        return valueType.dataSourceSignature.resultParameters.parameters
                            .map(
                                parameter => ({
                                    id: parameter.id,
                                    label: parameter.name,
                                    value: parameter
                                }));
                    }
                    else
                    {
                        return [];
                    }
                },
                [

                ])

        const setParameter =
            useCallback(
                (parameter?: Parameter<any>) =>
                    runInAction(
                        () =>
                            value.parameter = parameter),
                [
                    value
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <Input
                label="Waarde"
                labelPosition="left"
            >
                <ComputationBox>
                    <ComputationEditor
                        context={context}
                        value={value.value}
                        onChange={setValue}
                    />
                </ComputationBox>
            </Input>
            <Input
                label="Veld uit waarde"
                labelPosition="left"
            >
                <ComputationBox>
                    <StaticSelectbox
                        value={value.parameter}
                        onChange={setParameter}
                        options={options}
                    />
                </ComputationBox>
            </Input>
        </ViewGroup>;
    };

export default observer(ValueFromDataSourceValueComputationEditor);
