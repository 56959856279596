import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import styles from './Empty.module.scss';
import Icon from '../../../../../../@Future/Component/Generic/Icon/Icon';
import EntityConstructorButton from '../../../Button/EntityConstructorButton/EntityConstructorButton';
import useTypes from '../../../Type/Api/useTypes';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import View from '../../../View/Model/View';
import Specification from '../../../View/Model/Specification';
import Column from '../../../View/Model/Column/Column';
import RouterContext from '../../../../../../@Service/Router/RouterContext';
import FilterContext from '../FilterContext/FilterContext';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import AggregateValue from '../../../View/Model/Column/AggregateValue';
import { Aggregate } from '../../../../DataObject/Model/Aggregate';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import getViewParameters from '../../../View/Api/getViewParameters';
import { saveSelectionOptionsValueInStorageAndGetKey } from '../../../SelectionOptions/Api/saveSelectionOptionsValueInStorageAndGetKey';

export interface EmptyProps
{
    entityType: EntityType;
    result?: EntitySelectionAggregateResult;
    dataset?: Entity;
}

const Empty: React.FC<EmptyProps> =
    props =>
    {
        const routerStore = useContext(RouterContext);
        const types = useTypes();
        const filter = useContext(FilterContext);

        const openAsColumnView =
            useCallback(
                async () =>
                {
                    const view =
                        new View(
                            'Column',
                            props.entityType.getName(true),
                            props.entityType,
                            getViewParameters(props.entityType),
                            filter,
                            new Specification(
                                undefined,
                                new Column(
                                    EntityPath.fromEntityType(props.entityType)
                                        .joinTo(
                                            types.Activity.SalesOpportunity.RelationshipDefinition.Phase,
                                            false)
                                        .field(),
                                    [
                                        new AggregateValue(
                                            EntityPath.fromEntityType(props.entityType)
                                                .field(types.Activity.Field.Amount),
                                            Aggregate.Sum)
                                    ])
                            )
                        );
                    const selectionOptionsValueKey =
                        saveSelectionOptionsValueInStorageAndGetKey({
                            view: view,
                            filter: filter?.normalize()
                        });
                    const selectionOptionsAsUrl =
                        btoa(
                            JSON.stringify({
                                selectionOptionsValueKey: selectionOptionsValueKey
                            })
                        );

                    return routerStore.route(`/type/${props.entityType.id}/${selectionOptionsAsUrl}`);
                },
                [
                    routerStore,
                    props.entityType,
                    filter,
                    types
                ]);

        if (props.entityType.isA(types.Activity.SalesOpportunity.Type))
        {
            return <div
                className={styles.root}
                onClick={openAsColumnView}
            >
                <div
                    className={styles.container}
                >
                    <div
                        className={styles.icon}
                    >
                        <Icon
                            icon={props.entityType.getInheritedIcon()}
                            size={100}
                            color="lightgray"
                        />
                    </div>
                    <div
                        className={styles.label}
                    >
                        <LocalizedText
                            code="Dataset.GoToSalesPipelineAndCreateYourFirstEntity"
                            value="Ga naar de verkooppijplijn en maak je eerste ${typeName} aan."
                            typeName={props.entityType.getName().toLowerCase()}
                        />
                    </div>
                </div>
            </div>;
        }
        else
        {
            return <EntityConstructorButton
                entityType={props.entityType}
                filter={filter}
            >
                <div
                    className={styles.root}
                >
                    <div
                        className={styles.container}
                    >
                        <div
                            className={styles.icon}
                        >
                            <Icon
                                icon={props.entityType.getInheritedIcon()}
                                size={100}
                                color="lightgray"
                            />
                        </div>
                        <div
                            className={styles.label}
                        >
                            <LocalizedText
                                code="Dataset.CreateYourFirstEntity"
                                value="Maak je eerste ${typeName} aan."
                                typeName={
                                    props.entityType.bespoke.type === props.entityType
                                        ? props.entityType.bespoke.getUserFriendlyEntityType().getName().toLowerCase()
                                        : props.entityType.getName().toLowerCase()
                                }
                            />
                        </div>
                    </div>
                </div>
            </EntityConstructorButton>;
        }
    };

export default observer(Empty);
