import { Entity } from "../../../../../../../@Api/Model/Implementation/Entity";
import React, { useCallback } from "react";
import useTypes from "../../../../Type/Api/useTypes";
import { openEntity } from "../../../../@Util/openEntity";
import ViewGroup from "../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup";
import ViewGroupItem from "../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem";
import Card from "../../../../../../../@Future/Component/Generic/Card/Card";
import { ApsisFormList } from "./Views/ApsisFormList";

export interface ApsisFormResultsProps
{
    apsisForm: Entity;
}

export const ApsisFormResults: React.FC<ApsisFormResultsProps> =
    (
        {
            apsisForm
        }
    ) =>
    {
        const types = useTypes();
        const onItemClicked = useCallback(
            entity =>
            {
                if (entity.entityType.isA(types.Activity.ApsisFormResult.Type))
                {
                    const relation =
                        entity.getRelatedEntityByDefinition(
                            true,
                            types.Activity.RelationshipDefinition.Relationship
                        );

                    if (relation !== undefined)
                    {
                        openEntity(relation)
                             .then();
                    }
                }
            },
            [
                types
            ]
        );

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <ViewGroupItem>
                <Card>
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                    >
                        <ViewGroupItem>
                            <ApsisFormList
                                apsisForm={apsisForm}
                                onItemClicked={onItemClicked}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </Card>
            </ViewGroupItem>
        </ViewGroup>;
    };
