import { ButtonProps, ButtonStore } from '../../../Generic/Button/ButtonStore';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { computed } from 'mobx';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { EntityReadCountButtonStore } from './EntityReadCountButtonStore';
import { EntityPath } from '../Path/@Model/EntityPath';
import { DialogStore } from '../../../Generic/Dialog/Type/DialogStore';
import { ReactViewComponent } from '../../../Generic/ViewStack/Model/ReactViewComponent';
import localizeText from '../../../../@Api/Localization/localizeText';
import RelationshipEditorDialog from '../Viewer/Content/Activity/Relationships/RelationshipEditorDialog/RelationshipEditorDialog';
import { FeedbackStore } from '../../../App/Root/Environment/Organization/Feedback/FeedbackStore';

export interface EntityActivityRelationshipsButtonProps extends ButtonProps
{
    entity: PropType<EntityReadCountButtonStore, EntityActivityRelationshipsButtonProps, Entity>;
}

const defaultProps: Partial<EntityActivityRelationshipsButtonProps> =
{
};

export class EntityActivityRelationshipsButtonStore extends ButtonStore<EntityActivityRelationshipsButtonProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('FeedbackStore') feedbackStore: FeedbackStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: EntityActivityRelationshipsButtonProps)
    {
        super({
            label:
                () =>
                    localizeText(
                        'Activity.EditRelationships',
                        'Relatie wijzigen...'),
            isVisible:
                () => this.entity.entityType.isA(
                    this.entityTypeStore.bespoke.types.Activity.Type),
            onClick:
                () =>
                {
                    const closeDialog =
                        this.feedbackStore.openDialog({
                            store:
                                new DialogStore({
                                    viewComponent:
                                        new ReactViewComponent(
                                            RelationshipEditorDialog,
                                            {
                                                entity: this.entity,
                                                saveCallback:
                                                    success =>
                                                    {
                                                        if (success)
                                                        {
                                                            closeDialog()
                                                        }
                                                    },
                                                closeCallback:
                                                    () =>
                                                        closeDialog()
                                            }
                                        )
                                })
                        });
                },
            ...props,
        }, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    @computed
    get linkedActivities(): Entity[]
    {
        const types = this.entityTypeStore.bespoke.types;
        const rootPath = EntityPath.fromEntity(this.entity);

        if (this.entity.entityType.isA(types.Activity.Type))
        {
            return rootPath
                .joinTo(types.Activity.RelationshipDefinition.LinkedActivities, true)
                .traverseEntity(this.entity);
        }
        else
        {
            return [];
        }
    }

    @computed
    get entity(): Entity
    {
        return getOrCompute(this, this.props.entity);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
