import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import CampaignPercentageChart from './CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import useCampaignEmailStateCount from '../Hooks/useCampaignEmailStateCount';
import useTypes from '../../../../../Type/Api/useTypes';
import useCampaignUndeliveredCount from '../Hooks/useCampaignUndeliveredCount';

export interface CampaignChartSpamProps
{
    campaign: Entity;
    size: number;
}

export const CampaignChartSpam: React.FC<CampaignChartSpamProps> =
    observer(
        ({
             campaign,
             size,
         }) =>
        {
            const types = useTypes();

            const undeliveredCount = useCampaignUndeliveredCount(campaign);
            const states =
                useMemo(
                    () =>
                        [
                            types.Activity.Email.State.Spam
                        ],
                    [
                        types
                    ]
                );
            const spamCount = useCampaignEmailStateCount(campaign,states);

            const allSet =
                useAllSet([
                    undeliveredCount,
                    spamCount
                ]);

            const selection =
                useMemo(
                    () =>
                    {
                        return `${spamCount} / ${undeliveredCount}`
                    },
                    [
                        spamCount,
                        undeliveredCount
                    ]
                )

            return <div>
                {
                    allSet &&
                    <CampaignPercentageChart
                        value={spamCount / undeliveredCount * 100}
                        size={size}
                        label={localizeText('Campaign.Chart.Spam','Spam')}
                        color={'#8f0012'}
                        selection={selection}
                    />
                }
            </div>;
        }
    );
