import React from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import HeaderContext from '../../../../Item/Context/HeaderContext';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TypeAndName from '../../../Shared/TypeAndName/TypeAndName';
import MenuButton from '../../../Shared/MenuButton/MenuButton';
import Automator from '../../Automation/Automator/Automator';

export interface HeaderProps
{
    entity: Entity;
}

export const LeexiCallHeader: React.FC<HeaderProps> = observer(
    ({
        entity
    }) =>
    {
        return <HeaderContext.Provider
            value={true}
        >
            <Card
                inset
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={5}
                                >
                                    <ViewGroupItem>
                                        <TypeAndName
                                            entity={entity}
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={10}
                                    alignment="center"
                                >
                                    <ViewGroupItem>
                                        <Automator
                                            entity={entity}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <MenuButton
                                            entity={entity}
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </Card>
        </HeaderContext.Provider>;
    }
);