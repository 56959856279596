import React, { useEffect, useMemo, useRef, useState } from 'react';
import LocalizedText from '../Localization/LocalizedText/LocalizedText';
import uuid from '../../../@Util/Id/uuid';
import Card from '../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Button, IconButton, TextField, Typography } from '@material-ui/core';
import { AssistantPrompt } from './Model/AssistantPrompt';
import { AssistantPromptViewer } from './AssistantPromptViewer';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { observer } from 'mobx-react';
import { Close, Send } from '@material-ui/icons';
import AssistantLogo from './Resources/smart_assistant_green.png';
import Centered from '../../../@Future/Component/Generic/Centered/Centered';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/index';
import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { green } from '@material-ui/core/colors';
import { useIsNotLgScreen } from '../../../@Util/Responsiveness/useIsNotLgScreen';

export interface AssistantProps
{
    open?: boolean;
    onClose: () => void;
}

export const Assistant: React.FC<AssistantProps> =
    observer(
        ({
            open,
            onClose,
         }) =>
        {
            const [promptInputElement, setPromptInputElement] = useState<HTMLInputElement | undefined>(undefined);
            useEffect(
                () =>
                {
                    if (open && promptInputElement)
                    {
                        promptInputElement.focus();
                    }
                },
                [promptInputElement, open]
            );

            const [prompts, setPrompts] = useState<AssistantPrompt[]>([]);
            const [prompt, setPrompt] = useState('');
            const theme =
                useMemo(
                    () =>
                        createMuiTheme({
                            palette: {
                                primary: green,
                            },
                        }),
                    []
                );
            const send =
                () =>
                {
                    if (prompt.length > 0)
                    {
                        setPrompts(
                            prevPrompts => [
                                ...prevPrompts,
                                {
                                    id: uuid(),
                                    date: new Date(),
                                    prompt,
                                }
                            ]
                        );
                        setPrompt('');
                    }
                };
            const {ref} =
                useResizeObserver<HTMLDivElement>({
                    onResize:
                        () =>
                        {
                            if (ref.current)
                            {
                                ref.current.parentElement.scrollTo(
                                    {
                                        top: ref.current.scrollHeight,
                                        behavior: 'smooth',
                                    }
                                );
                            }
                        }
                });
            const [isRecording, setRecording] = useState(false);
            const streamRef = useRef<MediaStream>(null);
            const recorderRef = useRef<any>(null);
            const isSmallScreen = useIsNotLgScreen();

            return <Card
                style={{
                    position:
                        isSmallScreen
                            ? 'fixed'
                            : 'relative',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(10px)',
                    height:
                        isSmallScreen
                            ? '100vh'
                            : undefined,
                    width:
                        open
                            ? isSmallScreen
                                ? '100vw'
                                : 'min(100vw, 800px)'
                            : 0,
                    zIndex: 1000,
                    ...isSmallScreen && {
                        borderRadius: 0,
                    },
                }}
            >
                <div
                    style={{
                        position: 'fixed',
                        top: 16,
                        right: 16,
                    }}
                >
                    <IconButton
                        size="small"
                        onClick={onClose}
                    >
                        <Close />
                    </IconButton>
                </div>
                <div
                    style={{
                        maxHeight:
                            isSmallScreen
                                ? 'calc(100vh - 56px)'
                                : 'calc(100vh - 200px)',
                        overflowY: 'auto',
                        padding: 16,
                    }}
                >
                    <div
                        ref={ref}
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={8}
                        >
                            <ViewGroupItem>
                                <Centered
                                    horizontal
                                >
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={0}
                                        alignment="center"
                                    >
                                        <ViewGroupItem>
                                            <img
                                                src={AssistantLogo}
                                                width={150}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                            >
                                                I'm Lucie. Your smart assistant.
                                            </Typography>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <Typography
                                                variant="body1"
                                                color="textSecondary"
                                                align="center"
                                                style={{
                                                    fontWeight: 500,
                                                }}
                                            >
                                                You can ask my anything about your data. For example:
                                            </Typography>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <Typography
                                                variant="body1"
                                                color="textSecondary"
                                                style={{
                                                    fontWeight: 400,
                                                }}
                                            >
                                                <ul>
                                                    <li>
                                                        show me the total number of tasks over time assigned to me segmented by type
                                                    </li>
                                                    <li>
                                                        show me the total number of products sold this year, segmented by product
                                                    </li>
                                                    <li>
                                                        how many tasks did I complete last year?
                                                    </li>
                                                </ul>
                                            </Typography>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </Centered>
                            </ViewGroupItem>
                            {
                                prompts.length > 0 &&
                                <ViewGroupItem>
                                    <AssistantPromptViewer
                                        prompt={prompts[0]}
                                        onClose={onClose}
                                        remainingPrompts={prompts.slice(1)}
                                    />
                                </ViewGroupItem>
                            }
                        </ViewGroup>
                    </div>
                </div>
                <div
                    style={{
                        ...isSmallScreen && {
                            position: 'fixed',
                            left: 0,
                            bottom: 0,
                            right: 0,
                        },
                        paddingBottom: 16,
                        paddingLeft: 16,
                        paddingRight: 16,
                    }}
                >
                    <ThemeProvider
                        theme={theme}
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={16}
                            alignment="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <TextField
                                    inputRef={setPromptInputElement}
                                    placeholder="Press and hold space to activate 'push-to-talk'"
                                    variant="outlined"
                                    value={prompt}
                                    onChange={
                                        event =>
                                        {
                                            if (event.target.value !== ' ')
                                            {
                                                setPrompt(event.target.value);
                                            }
                                        }
                                    }
                                    onKeyDown={
                                        event =>
                                        {
                                            if ((event.target as HTMLInputElement).value === ''
                                                && event.code === 'Space')
                                            {
                                                setRecording(
                                                    isRecording =>
                                                    {
                                                        if (!isRecording)
                                                        {
                                                            // Start recording
                                                            navigator.getUserMedia({
                                                                    video: false, audio: true
                                                                },
                                                                function(stream)
                                                                {
                                                                    streamRef.current = stream;
                                                                    const recorder =
                                                                        new (window as any).MediaRecorder(
                                                                            stream,
                                                                            {
                                                                                mimeType: 'audio/webm',
                                                                            }
                                                                        );
                                                                    const recordedBlobs: Blob[] = [];
                                                                    recorder.ondataavailable =
                                                                        event =>
                                                                        {
                                                                            const blob = event.data;
                                                                            recordedBlobs.push(blob);
                                                                        }
                                                                    recorder.onstop =
                                                                        async () =>
                                                                        {
                                                                            const apiClient = loadModuleDirectly(ApiClient);
                                                                            const blob =
                                                                                new Blob(
                                                                                    recordedBlobs,
                                                                                    {
                                                                                        type: 'audio/webm'
                                                                                    }
                                                                                );
                                                                            const formData = new FormData();
                                                                            formData.append('speech', blob, 'audio.webm');
                                                                            const headers = {
                                                                                ...apiClient.defaultHeaders,
                                                                            };
                                                                            delete headers['Content-Type'];
                                                                            const response =
                                                                                await fetch(
                                                                                    apiClient.url('/assistant/speechToText'),
                                                                                    {
                                                                                        headers,
                                                                                        method: 'post',
                                                                                        body: formData,
                                                                                    }
                                                                                );
                                                                            const { text: prompt }: { text: string } = await response.json();

                                                                            if (prompt.length > 0)
                                                                            {
                                                                                setPrompts(
                                                                                    prevPrompts => [
                                                                                        ...prevPrompts,
                                                                                        {
                                                                                            id: uuid(),
                                                                                            date: new Date(),
                                                                                            prompt,
                                                                                        }
                                                                                    ]
                                                                                );
                                                                                setPrompt('');
                                                                            }
                                                                        };
                                                                    recorder.start(1000);
                                                                    recorderRef.current = recorder;
                                                                },
                                                                error =>
                                                                {
                                                                    console.error(error);
                                                                }
                                                            );
                                                        }

                                                        return true;
                                                    }
                                                );
                                            }
                                            else if (event.code === 'Enter')
                                            {
                                                send();
                                            }
                                        }
                                    }
                                    onKeyUp={
                                        event =>
                                        {
                                            if ((event.target as HTMLInputElement).value === ''
                                                && event.code === 'Space')
                                            {
                                                setRecording(false);

                                                const recorder = recorderRef.current;

                                                if (recorder)
                                                {
                                                    recorder.stop();
                                                }

                                                const stream = streamRef.current;

                                                if (stream)
                                                {
                                                    stream.getTracks()
                                                        .forEach(
                                                            track =>
                                                            {
                                                                track.stop()
                                                            }
                                                        );
                                                }
                                            }
                                        }
                                    }
                                    size="small"
                                    fullWidth
                                    autoFocus
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <Button
                                    onClick={send}
                                    disabled={isRecording}
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        color: 'white',
                                        textTransform: 'initial',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Send
                                            fontSize="small"
                                        />
                                        <div
                                            style={{
                                                paddingLeft: 8,
                                            }}
                                        >
                                            <LocalizedText
                                                code="Generic.Send"
                                                value="Versturen"
                                            />
                                        </div>
                                    </div>
                                </Button>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ThemeProvider>
                </div>
            </Card>;
        }
    );
