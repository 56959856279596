import React from 'react';
import { observer } from 'mobx-react-lite';
import EntityValue from '../../../../../../../../../../@Api/Automation/Value/EntityValue';
import { ValueViewerProps } from '../../ValueViewer';

export interface EntityViewerProps extends ValueViewerProps<EntityValue>
{

}

const EntityViewer: React.FC<EntityViewerProps> =
    props =>
    {
        return <span>{props.value.value.name}</span>;
    };

export default observer(EntityViewer);
