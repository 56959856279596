import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Member from './Member/Member';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import HoverCardBottom from '../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import useSwitch from '../../../../../../@Util/Switch/useSwitch';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useFetchedRelatedEntities from '../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntities';
import MenuButton from '../../../../Entity/Item/MenuButton/MenuButton';
import Selectbox from '../../../../Entity/Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../../../Entity/Selection/Builder/EntitySelectionBuilder';
import { SelectboxProps } from '../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { openEntity } from '../../../../Entity/@Util/openEntity';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export interface RoleProps
{
    role: Entity;
}

const RoleManager: React.FC<RoleProps> =
    props =>
    {
        const types = useTypes();

        const [ members ] =
            useFetchedRelatedEntities(
                props.role,
                types.Role.RelationshipDefinition.Members,
                false);

        const [ isInAddMode, startAddMode, endAddMode ] = useSwitch(false);
        const newMemberSelection =
            useComputed(
                () => [
                    new EntitySelectionBuilder(types.Relationship.Person.Contact.Employee.Type)
                        // .where(
                        //     cb =>
                        //         cb.notRelatedToEntity(
                        //             EntityPath.fromEntityType(types.Relationship.Person.Contact.Employee.Type)
                        //                 .joinTo(
                        //                     types.Role.RelationshipDefinition.Members,
                        //                     true),
                        //             props.role))
                        .build()
                ],
                [
                    types,
                    props.role
                ]);
        const addMember =
            useCallback(
                (employee: Entity) =>
                    new CommitBuilder()
                        .relateEntityTo(
                            employee,
                            true,
                            types.Role.RelationshipDefinition.Members,
                            props.role
                        )
                        .commit()
                        .then(
                            () =>
                                endAddMode()
                        ),
                [
                    types,
                    props.role,
                    endAddMode,
                ]);
        const addMemberSelectboxProps =
            useMemo(
                () => ({
                    open: true,
                    onClose:
                        () =>
                            endAddMode()
                } as SelectboxProps<any>),
                [
                    endAddMode
                ]);

        const editRole =
            useCallback(
                () =>
                    openEntity(props.role),
                [
                    props.role
                ]);

        return <Card>
            <CardInset>
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <CardHeader>
                            {props.role.name}
                        </CardHeader>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <IconButton
                            icon="edit"
                            tooltip={
                                <LocalizedText
                                    code="Generic.Edit"
                                    value="Wijzigen"
                                />
                            }
                            onClick={editRole}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <MenuButton
                            entity={props.role}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </CardInset>
            <Divider />
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={useDividerGlue()}
            >
                {
                    members && members.map(
                        member =>
                            <ViewGroupItem
                                key={member.uuid}
                            >
                                <Member
                                    member={member}
                                    role={props.role}
                                />
                            </ViewGroupItem>)
                }
            </ViewGroup>
            <Divider />
            {
                isInAddMode &&
                    <CardInset>
                        <Selectbox
                            selections={newMemberSelection}
                            onChange={addMember}
                            autoFocus
                            genericSelectboxProps={addMemberSelectboxProps}
                        />
                    </CardInset>
            }
            {
                !isInAddMode &&
                    <HoverCardBottom
                        onClick={startAddMode}
                    >
                        <LocalizedText
                            code="Configuration.Roles.AddEmployeeButton"
                            value="+ Medewerker toevoegen"
                        />
                    </HoverCardBottom>
            }
        </Card>;
    };

export default observer(RoleManager);
