import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import styles from './Offer.module.scss';
import useWorkflowState from '../../../../Workflow/Api/useWorkflowState';
import useTypes from '../../../../Type/Api/useTypes';
import useWorkflowStateIsTerminal from '../../../../Workflow/Api/useWorkflowStateIsTerminal';
import FileViewer from '../../../../../../Generic/FileViewer/FileViewer';
import AuthenticationManagerContext from '../../../../../../../@Service/Authentication/AuthenticationManagerContext';
import Tab from '../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import TabBar from '../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { EntityExpansionBuilder } from '../../../../Selection/Builder/EntityExpansionBuilder';
import Input from '../../../../Input/Input';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import ButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/ButtonGroup';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import ActivityTemplateEditor from '../Template/ActivityTemplateEditor';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../../Type/EntityTypeStore';
import Fields from '../../../../Fields/Fields';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import ProductLinesEditor from '../../../../Viewer/Content/Activity/Product/ProductEditor/ProductLinesEditor';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { useCommittableEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/useCommittableEntity';
import useRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import { OfferButtonMenu } from './buttonmenu/OfferButtonMenu';
import useFileDownloadOptions from "../Hooks/useFileDownloadOptions";

export interface OfferProps
{
    entity: Entity;
}

const Offer: React.FC<OfferProps> =
    props =>
    {
        const types = useTypes();
        const currentPhase = useWorkflowState(props.entity);
        const state = useWorkflowState(props.entity);
        const isClosed = useWorkflowStateIsTerminal(state);
        const commitContext = useNewCommitContext();
        const entity = useCommittableEntity(props.entity, commitContext);
        const [ isLoading, setLoading ] = useState(false);

        const offerTemplate =
            useRelatedEntity(
                entity,
                types.Activity.Offer.RelationshipDefinition.Template,
                false
            );

        const {
            isFinal,
            isConcept,
            previewDocumentUrl,
            finalizedDocumentUrl,
            downloadOptions,
        } =
            useFileDownloadOptions(entity, `offer`, offerTemplate);

        useEffect(
            () =>
            {
                const rootPath = EntityPath.fromEntityType(entity.entityType);

                setLoading(true);

                new EntityExpansionBuilder(
                    entity.entityType,
                    [
                        entity
                    ],
                    [
                        rootPath.joinTo(types.Activity.Offer.RelationshipDefinition.SignedOfferAttachment, false),
                        rootPath.joinTo(types.Activity.Offer.RelationshipDefinition.FinalizedDocumentAttachment, false),
                        rootPath.joinTo(types.Entity.RelationshipDefinition.Attachments, false),
                        rootPath.joinTo(types.Activity.Offer.RelationshipDefinition.Template, false)
                    ])
                    .expand()
                    .then(
                        () =>
                            setLoading(false));
            },
            [
                entity,
                types,
                setLoading
            ]);

        const [ tab, setTab ] =
            useState(
                () =>
                {
                    if (isFinal)
                    {
                        return 4;
                    }
                    else
                    {
                        return 1;
                    }
                }
            );

        useEffect(
            () =>
            {
                if (isFinal)
                {
                    setTab(4);
                }
            },
            [
                isFinal,
                setTab
            ]);


        const gotoPreview =
            useCallback(
                () =>
                    setTab(3),
                [
                    setTab
                ]);

        if (isLoading)
        {
            return <CardInset>
                <Centered
                    horizontal
                >
                    <Loader />
                </Centered>
            </CardInset>;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                {
                    isClosed &&
                        <ViewGroupItem>
                            <CardInset
                                bottom={false}
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={0}
                                >
                                    <ViewGroupItem>
                                        <div
                                            className={styles.respondentHeader}
                                        >
                                            {currentPhase.name}
                                        </div>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <ViewGroup
                                            orientation="vertical"
                                            spacing={0}
                                        >
                                            {
                                                [
                                                    types.Activity.Offer.Field.RespondentFirstName,
                                                    types.Activity.Offer.Field.RespondentInsertion,
                                                    types.Activity.Offer.Field.RespondentLastName,
                                                    types.Activity.Offer.Field.RespondentEmailAddress,
                                                    types.Activity.Offer.Field.RespondentIpAddress,
                                                    types.Activity.Offer.Field.RespondentNote
                                                ].map(
                                                    field =>
                                                        <ViewGroupItem
                                                            key={field.id}
                                                        >
                                                            <Input
                                                                entity={props.entity}
                                                                field={field}
                                                                labelPosition="left"
                                                                disabled
                                                            />
                                                        </ViewGroupItem>)
                                            }
                                        </ViewGroup>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </CardInset>
                        </ViewGroupItem>
                }
                <ViewGroup
                    orientation="vertical"
                    spacing={10}
                >
                    <ViewGroupItem>
                        <TabBar
                            value={tab}
                            appendix={
                                <ButtonGroup>
                                    {
                                        !isClosed &&
                                            <OfferButtonMenu
                                                entity={props.entity}
                                                commitContext={commitContext}
                                            />
                                        }
                                </ButtonGroup>
                            }
                        >
                            <Tab
                                value={0}
                                onClick={setTab}
                            >
                                <LocalizedText
                                    code="Generic.Details"
                                    value="Details"
                                />
                            </Tab>
                            <Tab
                                value={1}
                                onClick={setTab}
                            >
                                {types.ProductLine.Type.getName(true)}
                            </Tab>
                            {
                                isConcept &&
                                    <Tab
                                        value={2}
                                        onClick={setTab}
                                    >
                                        <LocalizedText
                                            code="Generic.Edit"
                                            value="Bewerken"
                                        />
                                    </Tab>
                            }
                            {
                                isConcept &&
                                    <Tab
                                        value={3}
                                        onClick={setTab}
                                    >
                                        <LocalizedText
                                            code="Generic.Example"
                                            value="Voorbeeld"
                                        />
                                    </Tab>
                            }
                            {
                                isFinal &&
                                <Tab
                                    value={4}
                                    onClick={setTab}
                                >
                                    {types.Activity.Offer.Type.getName()}
                                </Tab>
                            }
                        </TabBar>
                    </ViewGroupItem>
                    {
                        tab === 0 &&
                            <ViewGroupItem>
                                <Fields
                                    entity={props.entity}
                                />
                            </ViewGroupItem>
                    }
                    {
                        tab === 1 &&
                            <ViewGroupItem>
                                <ProductLinesEditor
                                    entity={props.entity}
                                    addNewLineIfEmpty
                                    disabled={!isConcept}
                                />
                            </ViewGroupItem>
                    }
                    {
                        tab === 2 &&
                            <ViewGroupItem>
                                <ActivityTemplateEditor
                                    entity={entity}
                                    templateEntity={offerTemplate}
                                    onPreview={gotoPreview}
                                    templateType={types.Template.Document.Offer.Type}
                                    templateRelationshipDefinition={types.Activity.Offer.RelationshipDefinition.Template}
                                    templateContentField={types.Activity.Offer.Field.Content}
                                    templateFileField={types.Activity.Offer.Field.TemplateFile}
                                    templateManagerRoute="/configuration/sales"
                                />
                            </ViewGroupItem>
                    }
                    {
                        tab === 3 && previewDocumentUrl &&
                            <ViewGroupItem>
                                <FileViewer
                                    url={previewDocumentUrl}
                                    extraDownloadOptions={downloadOptions}
                                    type="pdf"
                                />
                            </ViewGroupItem>
                    }

                    {
                        tab === 4 && isFinal && finalizedDocumentUrl &&
                        <ViewGroupItem>
                            <FileViewer
                                url={finalizedDocumentUrl}
                                type="pdf"
                            />
                        </ViewGroupItem>
                    }
                </ViewGroup>
            </ViewGroup>;
        }
    };

export default observer(Offer);
