import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import performAction from '../../../../../@Api/Entity/performAction';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Loader from '../../../../../@Future/Component/Generic/Loader/Loader';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';
import CardHeader from '../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import Checkbox from '../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import styles from './CocResult.module.scss';
import RightAlignedButtonGroup from '../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import PrimaryButton from '../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import CancelButton from '../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import LocalizerContext from '../../../../../@Service/Localization/LocalizerContext';
import useTypes from '../../Type/Api/useTypes';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import useChecklist from '../../../../../@Util/Collection/useChecklist';
import useDividerGlue from '../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import Chip from '../../../../../@Future/Component/Generic/Chip/Chip';

export interface CocResultProps
{
    record: any;
    countryCode: any;
    onClose: () => void;
    onSelect: (report: any) => void;
}

const CocResult: React.FC < CocResultProps > =
    props =>
    {
        const types = useTypes();

        const [ result, isLoading ] =
            useAsyncResult(
                () =>
                    performAction(
                        undefined,
                        {
                            code: 'Organization.CoC.Report',
                            parameters: {
                                id: props.record.id,
                                countryCode: props.countryCode
                            }
                        }),
                [
                    props.record,
                    props.countryCode
                ]);

        const report =
            useMemo(
                () =>
                    result?.result,
                [
                    result
                ]);

        const {
            setItems: setContacts,
            selectedItems: selectedContacts,
            setSelectedIndexes: setSelectedContactIndexes,
            toggleItem: toggleContact,
            toggleAllItems: toggleAllContacts,
            checkedItem: isContactSelected,
            checkedAllItems: allContactsSelected,
            selectionIsIndeterminate: isIndeterminate
        } = useChecklist<any>();

        const directors = useMemo(
            () =>
                report?.directors?.filter(director => director?.lastName !== undefined) || [],
            [
                report
            ]
        );

        useEffect(
            () =>
            {
                setContacts(directors);
                setSelectedContactIndexes(directors.map(director => directors.indexOf(director)));
            },
            [
                setContacts,
                setSelectedContactIndexes,
                directors
            ]);

        const sbiCode =
            useMemo(
                () =>
                    report?.companySummary?.mainActivity
                        ? `${report?.companySummary?.mainActivity?.code} - ${report?.companySummary?.mainActivity?.description}`
                        : undefined,
                [
                    report
            ]);

        const select =
            useCallback(
                () =>
                {
                    if (directors)
                    {
                        report.directors = selectedContacts;
                    }

                    return Promise.all([ props.onSelect(report) ])
                        .then(
                            () =>
                                props.onClose());
                },
                [
                    report,
                    selectedContacts,
                    props.onSelect,
                    props.onClose
                ]);

        const isInactive =
            useMemo(
                () =>
                    props.record?.status === 'NonActive',
                [
                    props.record
                ]
            );

        const localizer = useContext(LocalizerContext);
        const dividerGlue = useDividerGlue();

        return <ViewGroup
            orientation="vertical"
            spacing={5}
            className={styles.root}
        >
            <ViewGroupItem>
                <CardHeader>
                    {
                        isInactive &&
                            <ViewGroup
                                orientation="horizontal"
                                spacing={16}
                            >
                                <ViewGroupItem>
                                    <span
                                        style={{textDecoration: 'line-through'}}
                                    >
                                        {props.record?.name}
                                    </span>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <Chip
                                        label={
                                            <LocalizedText
                                                code="Generic.Inactive"
                                                value="Inactief"
                                            />
                                        }
                                        textColor="red"
                                        backgroundColor="red"
                                    />
                                </ViewGroupItem>
                            </ViewGroup>

                    }
                    {
                        !isInactive &&
                            <span>
                                {props.record?.name}
                            </span>
                    }
                </CardHeader>
            </ViewGroupItem>
            {
                isLoading &&
                    <ViewGroupItem
                        ratio={1}
                    >
                        <Centered
                            horizontal
                        >
                            <Loader />
                        </Centered>
                    </ViewGroupItem>
            }
            {
                !isLoading && !report &&
                    <ViewGroupItem
                        ratio={1}
                    >
                        <Centered
                            horizontal
                        >
                            <LocalizedText
                                code="AdvancedCoc.NoReportFound"
                                value="Rapport niet gevonden."
                            />
                        </Centered>
                    </ViewGroupItem>
            }
            {
                !isLoading && report &&
                    <ViewGroupItem
                        ratio={1}
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={5}
                        >
                            <ViewGroupItem>
                                <InputGroup>
                                    <Input
                                        label={types.Relation.Organization.RelationshipDefinition.VisitingAddress.getName(false)}
                                        labelPosition="left"
                                    >
                                        {report?.companyIdentification?.basicInformation?.contactAddress?.simpleValue}
                                    </Input>
                                    <Input
                                        label={types.Relation.Organization.RelationshipDefinition.PostalAddress.getName(false)}
                                        labelPosition="left"
                                    >
                                        {report?.contactInformation?.mainAddress?.simpleValue}
                                    </Input>
                                    <Input
                                        label={types.Relation.Organization.Field.ChamberOfCommerceNumber.getName()}
                                        labelPosition="left"
                                    >
                                        {report?.companySummary?.companyRegistrationNumber}
                                    </Input>
                                    <Input
                                        label={types.Relation.Organization.Field.RSIN.getName()}
                                        labelPosition="left"
                                    >
                                        {report?.additionalInformation?.misc?.rsinNumber}
                                    </Input>
                                    <Input
                                        label={types.Relation.Organization.Field.EstablishmentNumber.getName()}
                                        labelPosition="left"
                                    >
                                        {report?.additionalInformation?.misc?.branchNumber}
                                    </Input>
                                    <Input
                                        label="Handelsnamen"
                                        labelPosition="left"
                                        inline
                                    >
                                        {report?.additionalInformation?.tradingStyles?.filter((name, idx) => idx < 5).map(s => s.tradingName).join(', ')}
                                    </Input>
                                    <Input
                                        label="Classificatie"
                                        labelPosition="left"
                                    >
                                        {sbiCode}
                                    </Input>
                                </InputGroup>
                            </ViewGroupItem>
                            {
                                (directors || []).length > 0 &&
                                    <ViewGroupItem>
                                        <ViewGroup
                                            orientation="vertical"
                                            spacing={5}
                                            glue={dividerGlue}
                                        >
                                            <ViewGroupItem>
                                                <CardHeader>
                                                    <ViewGroup
                                                        orientation="horizontal"
                                                        spacing={15}
                                                        alignment="center"
                                                    >
                                                        <ViewGroupItem>
                                                            <Checkbox
                                                                indeterminate={isIndeterminate()}
                                                                checked={allContactsSelected()}
                                                                onToggle={() => toggleAllContacts()}
                                                            />
                                                        </ViewGroupItem>
                                                        <ViewGroupItem>
                                                            {types.Relationship.Person.Contact.Type.getName(true)}
                                                        </ViewGroupItem>
                                                    </ViewGroup>
                                                </CardHeader>
                                            </ViewGroupItem>
                                            {
                                                (directors || [])
                                                    .map(
                                                        director =>
                                                            <ViewGroupItem
                                                                key={director.index}
                                                            >
                                                                <ViewGroup
                                                                    orientation="horizontal"
                                                                    spacing={15}
                                                                >
                                                                    <ViewGroupItem>
                                                                        <Checkbox
                                                                            checked={isContactSelected(director)}
                                                                            onToggle={() => toggleContact(director)}
                                                                        />
                                                                    </ViewGroupItem>
                                                                    <ViewGroupItem
                                                                        ratio={1}
                                                                    >
                                                                        <div>
                                                                            <ViewGroup
                                                                                orientation="vertical"
                                                                                spacing={5}
                                                                            >
                                                                                <ViewGroupItem>
                                                                                    <b>{director.name}</b>
                                                                                </ViewGroupItem>
                                                                                <ViewGroupItem>
                                                                                    <InputGroup>
                                                                                        {
                                                                                            director.firstName &&
                                                                                            <Input
                                                                                                label={
                                                                                                    <LocalizedText
                                                                                                        code="Generic.FirstNames"
                                                                                                        value="Voornamen"
                                                                                                    />
                                                                                                }
                                                                                                labelPosition="left"
                                                                                            >
                                                                                                {director.firstName}
                                                                                            </Input>
                                                                                        }
                                                                                        {
                                                                                            director.middleName &&
                                                                                            <Input
                                                                                                label={
                                                                                                    <LocalizedText
                                                                                                        code="Generic.MiddleName"
                                                                                                        value="Tussenvoegsel"
                                                                                                    />
                                                                                                }
                                                                                                labelPosition="left"
                                                                                            >
                                                                                                {director.middleName}
                                                                                            </Input>
                                                                                        }
                                                                                        <Input
                                                                                            label={types.Relation.Person.Field.LastName.getName()}
                                                                                            labelPosition="left"
                                                                                        >
                                                                                            {director.lastName}
                                                                                        </Input>
                                                                                        {
                                                                                            director.dataOfBirth &&
                                                                                            <Input
                                                                                                label={types.Relation.Person.Field.BirthDate.getName()}
                                                                                                labelPosition="left"
                                                                                            >
                                                                                                {localizer.formatDate(new Date(director.dateOfBirth))}
                                                                                            </Input>
                                                                                        }
                                                                                        {
                                                                                            director.positions && director.positions.length > 0 &&
                                                                                            <Input
                                                                                                label="Positions"
                                                                                                labelPosition="left"
                                                                                            >
                                                                                                {director.positions.map(p => p.positionName).join(', ')}
                                                                                            </Input>
                                                                                        }
                                                                                    </InputGroup>
                                                                                </ViewGroupItem>
                                                                            </ViewGroup>
                                                                        </div>
                                                                    </ViewGroupItem>
                                                                </ViewGroup>
                                                            </ViewGroupItem>)
                                            }
                                        </ViewGroup>
                                    </ViewGroupItem>
                            }
                        </ViewGroup>
                    </ViewGroupItem>
            }
            <ViewGroupItem>
                <CardInset
                    top={false}
                >
                    <RightAlignedButtonGroup>
                        <CancelButton
                            onClick={props.onClose}
                        />
                        {
                            report &&
                                <PrimaryButton
                                    label={
                                        <LocalizedText
                                            code="Generic.Select"
                                            value="Selecteren"
                                        />
                                    }
                                    onClick={select}
                                />
                        }
                    </RightAlignedButtonGroup>
                </CardInset>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(CocResult);
