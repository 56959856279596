import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntitySelectionAggregateResult } from '../../../../Selection/Model/EntitySelectionAggregateResult';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import Segment from '../../Segment';
import styles from './BucketSelector.module.scss';
import FilterContext from '../../FilterContext/FilterContext';
import useResultFilter from '../../Api/useResultFilter';
import useEntityTypeForFilter from '../../Api/useEntityTypeForFilter';
import OverlineLabel from '../../../../../../../@Future/Component/Generic/Label/Variant/Overline/OverlineLabel';
import EntityTypeContext from '../../../../Type/EntityTypeContext';
import CategoryContext from '../../SegmentContext/SegmentContext';
import Item from './Item/Item';
import useChildSelection from '../Api/useChildSelection';
import useFilterAndSortAggregateResult from '../Chart/Api/useFilterAndSortAggregateResult';
import { default as SegmentModel } from '../../../Model/Segment';
import useNextSegments from '../../Api/useNextSegments';
import DatasetContext from '../../../Context/DatasetContext';
import SelectionOptions from '../../../../SelectionOptions/SelectionOptions';
import NameContext from '../../../Context/NameContext';
import useTypes from '../../../../Type/Api/useTypes';

export interface BucketProps
{
    entityType: EntityType;
    segment: SegmentModel;
    nextSegments: SegmentModel[];
    result: EntitySelectionAggregateResult;
}

const BucketSelector: React.FC<BucketProps> =
    props =>
    {
        const dataset = useContext(DatasetContext);
        const [ selectedChild, setSelectedChild ] =
            useChildSelection(
                props.segment,
                props.result
            );
        const [ nextSegment, nextNextSegments ] = useNextSegments(props.nextSegments);
        const selectedChildFilter =
            useResultFilter(
                dataset,
                props.segment,
                selectedChild
            );
        const types = useTypes();
        const isSegmentedOnRootEntityType =
            useComputed(
                () =>
                    !props.segment.groupFieldPath.path.lastJoinNode // no join may be contained, because this should say something about the root
                    && props.segment.groupFieldPath.field === types.Entity.Field.Type,
                [
                    props.segment,
                    types,
                ]
            );
        const selectedRootChildType =
            useEntityTypeForFilter(
                props.entityType,
                selectedChildFilter
            );
        const entityTypeStore = useContext(EntityTypeContext);
        const parentSegment = useContext(CategoryContext);
        const sortedAggregateResults =
            useFilterAndSortAggregateResult(
                props.segment,
                props.result
            );
        const name = useContext(NameContext);
        const filter = useContext(FilterContext);

        return <div>
            <div
                className={styles.selector}
            >
                {
                    parentSegment && parentSegment.type !== 'List' &&
                        <div
                            className={styles.overline}
                        >
                            <OverlineLabel>
                                Per {props.segment.groupFieldPath.getName(entityTypeStore)}
                            </OverlineLabel>
                        </div>
                }
                {
                    parentSegment && parentSegment.type !== 'List' &&
                        <div>
                            <SelectionOptions
                                entityType={props.entityType}
                                parameters={dataset.parameters}
                                name={name}
                                filter={filter}
                                filtered={props.segment !== undefined}
                                list={dataset.list}
                                itemLayout={dataset.itemLayout}
                            />
                        </div>
                }
                <div
                    className={styles.buckets}
                >
                    {
                        sortedAggregateResults.map(
                            (child, idx) =>
                                <Item
                                    key={child.id}
                                    entityType={props.entityType}
                                    segment={props.segment}
                                    nextSegments={props.nextSegments}
                                    child={child}
                                    idx={idx}
                                    numberOfBuckets={props.result.children.length}
                                    selected={selectedChild === child}
                                    onSelect={setSelectedChild}
                                />)
                    }
                </div>
            </div>
            {
                selectedChild &&
                    <FilterContext.Provider
                        value={
                            // Performance optimization:
                            // If the selected child type differs from the parent type, then simply ignore the 'WHERE type === childType'
                            // filter, because the selection is now rooted from the selected child type.
                            isSegmentedOnRootEntityType && (selectedRootChildType !== props.entityType)
                                ? filter
                                : selectedChildFilter
                        }
                    >
                        <CategoryContext.Provider
                            value={props.segment}
                        >
                            <div
                                className={styles.childSegment}
                            >
                                <Segment
                                    entityType={selectedRootChildType}
                                    segment={nextSegment}
                                    nextSegments={nextNextSegments}
                                    result={selectedChild}
                                />
                            </div>
                        </CategoryContext.Provider>
                    </FilterContext.Provider>
            }
        </div>;
    };

export default observer(BucketSelector);
