import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { ActionEditorProps } from '../../ActionEditor';
import { runInAction } from 'mobx';
import CardInset from '../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import EntityValueType from '../../../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationEditor from '../../../Computation/ComputationEditor';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import CreateRelationshipAction from '../../../../../../../../../../@Api/Automation/Function/Action/CreateRelationshipAction';
import useTypes from '../../../../../../../Type/Api/useTypes';
import { SimpleFieldPathSelector } from '../../../../../../../Path/FieldPathEditor/Simple/Selector/SimpleFieldPathSelector';
import { EntityRelationshipDefinition } from '../../../../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import Link from '../../../../../../../../../../@Future/Component/Generic/Link/Link';

export interface CreateRelationshipActionEditorProps extends ActionEditorProps<CreateRelationshipAction>
{

}

const CreateRelationshipActionEditor: React.FC<CreateRelationshipActionEditorProps> =
    props =>
    {
        const types = useTypes();
        const rootEntityType =
            useMemo(
                () =>
                    new EntityValueType(types.Entity.Type),
                [
                    types,
                ]
            );
        const setParentEntity =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.action.parentEntity = value
                    ),
                [
                    props.action,
                ]
            );
        const parentEntityType =
            useComputed(
                () =>
                {
                    if (props.action.parentEntity
                        && props.action.parentEntity.isValid()
                        && props.action.parentEntity.getType() instanceof EntityValueType)
                    {
                        return (props.action.parentEntity.getType() as EntityValueType).type;
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    types,
                ]
            );
        const setRelationshipDefinition =
            useCallback(
                (value: EntityRelationshipDefinition) =>
                    runInAction(
                        () =>
                            props.action.relationshipDefinition = value
                    ),
                [
                    props.action,
                ]
            );
        const childEntityType =
            useComputed(
                () =>
                    props.action.relationshipDefinition &&
                    new EntityValueType(props.action.relationshipDefinition.childEntityType),
                [
                    props.action,
                ]
            );
        const setChildEntity =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.action.childEntity = value),
                [
                    props.action,
                ]
            );

        return <CardInset
            top={false}
        >
            <Input
                label="Moeder record"
                labelPosition="left"
            >
                <ComputationEditor
                    context={props.context}
                    type={rootEntityType}
                    value={props.action.parentEntity}
                    onChange={setParentEntity}
                />
            </Input>
            {
                props.action.parentEntity &&
                parentEntityType &&
                <Input
                    label="Koppeling"
                    labelPosition="left"
                >
                    <SimpleFieldPathSelector
                        entityType={parentEntityType}
                        onSelect={
                            fieldPath =>
                                setRelationshipDefinition(fieldPath.relationshipDefinition)
                        }
                        view={
                            ({ open }) =>
                                <Link
                                    onClick={open}
                                >
                                    {props.action.relationshipDefinition?.getName(false) ?? '...'}
                                </Link>
                        }
                    />
                </Input>
            }
            {
                props.action.parentEntity &&
                props.action.relationshipDefinition &&
                <Input
                    label="Dochter record"
                    labelPosition="left"
                >
                    <ComputationEditor
                        context={props.context}
                        type={childEntityType}
                        value={props.action.childEntity}
                        onChange={setChildEntity}
                    />
                </Input>
            }
        </CardInset>;
    };

export default observer(CreateRelationshipActionEditor);
