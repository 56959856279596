import { Setting } from '../../../@Api/Model/Implementation/Setting';
import { SettingController } from '../../../@Api/Controller/Directory/SettingController';
import { DataObject } from '../DataObject/Model/DataObject';
import { action, observable, toJS } from 'mobx';
import { injectWithQualifier } from '../../../@Util/DependencyInjection/index';
import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { AuthenticationManager } from '../../../@Service/Authentication/AuthenticationManager';
import { WelcomeStore } from '../../../@Service/Welcome/WelcomeStore';
import isEqual from '../../../@Util/IsEqual/isEqual';
import { SettingDefinition } from '../../../@Api/Model/Implementation/SettingDefinition';

export enum SettingSource
{
    Application = 0,
    Organization = 1,
    User = 2
}

export class SettingStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('SettingController') settingController: SettingController;
    @injectWithQualifier('AuthenticationManager') authenticationManager: AuthenticationManager;
    @injectWithQualifier('WelcomeStore') welcomeStore: WelcomeStore;

    // ------------------------- Properties -------------------------

    @observable settingByCodeByType: Map<SettingSource, Map<string, Setting>>;

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        this.settingByCodeByType = observable.map<SettingSource, Map<string, Setting>>();

        [ SettingSource.Application, SettingSource.User, SettingSource.Organization ]
            .forEach(
                source =>
                    this.settingByCodeByType.set(
                        source,
                        this.getSettingByCodeMap(source)));

        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action
    public updateWithValue(source: SettingSource, code: string, value: any, logChanges: boolean = false): Promise<boolean>
    {
        const settingValue = this.getSettingsCollection(source).get(code);

        if (isEqual(settingValue.value, value))
        {
            return Promise.resolve(false);
        }
        else
        {
            settingValue.value = value;

            return this.saveSetting(source, settingValue, null, logChanges)
                .then(
                    () =>
                        Promise.resolve(true));
        }
    }

    @action
    private setSetting(source: SettingSource,
                       settingDefinition: SettingDefinition,
                       updatedSetting?: Setting)
    {
        const setting = this.getSettingsCollection(source).get(settingDefinition.code);

        if (setting)
        {
            setting.id = updatedSetting?.id;
            setting.value = updatedSetting?.value;
        }
        else
        {
            this.getSettingsCollection(source).set(settingDefinition.code, updatedSetting);
        }
    }

    // ------------------------ Public logic ------------------------

    public getValue(source: SettingSource, code: string): any
    {
        if (this.getSettingsCollection(source).has(code))
        {
            return this.getSettingsCollection(source).get(code).value;
        }
        else
        {
            return null;
        }
    }

    // ----------------------- Private logic ------------------------

    private getSettingsCollection(source: SettingSource): Map<string, Setting>
    {
        return this.settingByCodeByType.get(source);
    }

    private saveSetting(source: SettingSource, setting: Setting, dataObject?: DataObject, logChanges: boolean = false)
    {
        switch (source)
        {
            case SettingSource.Application:
            {
                return Promise.reject('unsupported');
            }
            case SettingSource.Organization:
            {
                return this.settingController.saveOrganizationSetting(
                    toJS((setting.id === 0) ? null : setting.id),
                    toJS(setting.settingDefinition.code),
                    toJS(setting.value),
                    setting.value?.file instanceof File
                        ? setting.value?.file
                        : undefined,
                    logChanges
                )
                    .then((updatedSetting) =>
                    {
                        this.setSetting(
                            SettingSource.Organization,
                            setting.settingDefinition,
                            updatedSetting);
                    });
            }
            case SettingSource.User:
            {
                return this.settingController.saveUserSetting(
                    toJS((setting.id === 0) ? null : setting.id),
                    toJS(setting.settingDefinition.code),
                    toJS(setting.value))
                    .then((updatedSetting) =>
                    {
                        this.setSetting(
                            SettingSource.User,
                            setting.settingDefinition,
                            updatedSetting);
                    });
            }
        }

        return Promise.reject('unsupported');
    }

    private getInternalSettingCollection(type: SettingSource)
    {
        switch (type)
        {
            case SettingSource.Application:
                return this.welcomeStore.welcomePackage.settingPackage.applicationSettings;

            case SettingSource.Organization:
                return this.welcomeStore.welcomePackage.settingPackage.organizationSettings;

            case SettingSource.User:
                return this.welcomeStore.welcomePackage.settingPackage.userSettings;
        }

        return undefined;
    }

    private getSettingByCodeMap(type: SettingSource): Map<string, Setting>
    {
        const settingByCode = observable.map<string, Setting>();

        this.getInternalSettingCollection(type)
            .forEach(
                setting =>
                    settingByCode.set(
                        setting.settingDefinition.code,
                        setting));

        return settingByCode;
    }
}