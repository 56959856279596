export const Pack = {
    WorkflowAutomations: 'WorkflowAutomations',
    PostalCode: 'PostalCode',
    AdvancedCoc: 'AdvancedCoc',
    Sales: 'Sales',
    Invoicing: 'Invoicing',
    Marketing: 'Marketing',
    WhiteLabelPortals: 'WhiteLabelPortals',
    Course: 'Course',
    ESignature: 'ESignature',
    Apsis: 'Apsis',
    Spotler: 'Spotler',
    Yuki: 'Yuki',
    Leexi: 'Leexi'
/*
    Marketing: 'Marketing',
    Invoicing: 'Invoicing',
    Projects: 'Projects',
    TimeRegistration: 'TimeRegistration',
    WorkOrders: 'WorkOrders',
    CoC: 'CoC',
    Support: 'Support',
    Events: 'Events',
    EmailMarketingAutomations: 'EmailMarketingAutomations',
*/
};