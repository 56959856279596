import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { ExpansionPanelListItemStore } from './ExpansionPanelListItemStore';
import ExpansionPanel from '../../../ExpansionPanel/ExpansionPanel';

@observer
export class ExpansionPanelListItem extends BaseComponent<ExpansionPanelListItemStore<any>>
{
    renderComponent(store: ExpansionPanelListItemStore<any>): JSX.Element
    {
        return <ExpansionPanel
            store={store.expansionPanelStore}
        />;
    }
}
