import * as React from 'react';
import { DataObjectSpecificationEditorForm } from '../../Editor/Specification/DataObjectSpecificationEditorForm';

export class MeSpecification extends DataObjectSpecificationEditorForm
{
    render()
    {
        return <div />;
    }
}
