import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { runInAction } from 'mobx';
import PredicateEditor, { PredicateEditorProps } from '../../PredicateEditor';
import Predicate from '../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import NotPredicate from '../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/NotPredicate';
import { classNames } from '../../../../../../../../../../@Future/Util/Class/classNames';
import CompositePredicate from '../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import styles from './NotPredicateEditor.module.scss';

export interface NotPredicateEditorProps extends PredicateEditorProps<NotPredicate>
{

}

const NotPredicateEditor: React.FC<NotPredicateEditorProps> =
    props =>
    {
        const setPredicate =
            useCallback(
                (predicate: Predicate) =>
                    runInAction(
                        () =>
                            props.value.predicate = predicate),
                [
                    props.value
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                <LocalizedText
                    code="Generic.Not"
                    value="Niet"
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <div
                    className={classNames(props.value.predicate instanceof CompositePredicate && styles.compositeComponent)}
                >
                    <PredicateEditor
                        {...props}
                        value={props.value.predicate}
                        onChange={setPredicate}
                    />
                </div>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(NotPredicateEditor);
