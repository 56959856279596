import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import safelySynchronousApplyFunction from '../../../../../@Api/Automation/Api/safelySynchronousApplyFunction';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import ValueViewer from '../../../Entity/Viewer/Content/Automation/Editor/Value/ValueViewer';
import { ComputationLayoutViewerProps } from './ComputationLayoutViewer';

export interface SyncComputationLayoutViewerProps extends ComputationLayoutViewerProps
{

}

export const SyncComputationLayoutViewer: React.FC<SyncComputationLayoutViewerProps> =
    observer(
        props =>
        {
            const value =
                useComputed(
                    () =>
                    {
                        return safelySynchronousApplyFunction(
                            props.layout.computation,
                            new FunctionContext(
                                props.parameterDictionary,
                                props.parameterAssignment,
                                props.commitContext
                            )
                        );
                    },
                    [
                        props.layout,
                        props.parameterDictionary,
                        props.parameterAssignment,
                        props.commitContext,
                    ]);

            return <ValueViewer
                value={value}
            />;
        }
    );