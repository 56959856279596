import { observable, runInAction } from 'mobx';
import { Disposer } from '../../../../../@Util/Disposable/Disposer';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { CurrentUserStore } from '../../../User/CurrentUserStore';

export default abstract class Widget
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;

    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable isInitialized: boolean;

    // ------------------------ Constructor -------------------------

    constructor(id: string)
    {
        this.id = id;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async initialize(widgets: Widget[]): Promise<Disposer>
    {
        const disposer = await this.doInitialize(widgets);

        runInAction(
            () =>
                widgets.forEach(
                    widget =>
                        widget.isInitialized = true));

        return disposer;
    }

    abstract doInitialize(widgets: Widget[]): Promise<Disposer>;

    toDescriptor()
    {
        return {
            id: this.id
        };
    }

    isFullWidth(): boolean
    {
        return false;
    }

    // ----------------------- Private logic ------------------------
}
