import { useContext, useMemo } from 'react';
import Trigger from '../../../../../../Action/Api/Trigger';
import RouterContext from '../../../../../../../../@Service/Router/RouterContext';
import { ComposeExternalEmailPageStore } from './ComposeExternalEmailPageStore';
import ExternalEmail from '../Model/ExternalEmail';
import { runInAction } from 'mobx';

export default function useComposeExternalEmailTrigger()
{
    const routerStore = useContext(RouterContext);

    return useMemo<Trigger<Partial<ExternalEmail>>>(
        () =>
            ({
                type: 'Activity.Email.ComposeExternalEmail',
                onEvent:
                    event =>
                    {
                        if (routerStore.currentPageStore instanceof ComposeExternalEmailPageStore)
                        {
                            runInAction(
                                () =>
                                    (routerStore.currentPageStore as ComposeExternalEmailPageStore).externalEmail = event.data);
                        }
                        else
                        {
                            const pageStore = new ComposeExternalEmailPageStore(event.data);

                            routerStore.route(
                                pageStore.routePath,
                                undefined,
                                undefined,
                                pageStore,
                                true
                            );
                        }
                    }
            }),
        [
            routerStore
        ]);
}
