import { FormEvent } from './FormEvent';
import { FormHandlerContext } from './FormHandlerContext';

export class FormHandlerProperties<T>
{
    // ------------------ Members -----------------------------

    onChange: (event: any, value: any) => void;

    onClick: (event: any, value: any) => void;

    onMouseEnter: (event: any, value: any) => void;

    onMouseOver: (event: any, value: any) => void;

    onMouseLeave: (event: any, value: any) => void;

    onKeyUp: (event: any, value: any) => void;

    onKeyDown: (event: any, value: any) => void;

    onKeyPress: (event: any, value: any) => void;

    onFocus: (event: any, value: any) => void;

    onBlur: (event: any, value: any) => void;

    // -------------- Constructor -----------------------------

    constructor(handlerContext: FormHandlerContext<T>,
                state: T)
    {
        this.onChange = this.generateEventFunction(handlerContext, state, FormEvent.Change);
        this.onClick = this.generateEventFunction(handlerContext, state, FormEvent.Click);
        this.onMouseEnter = this.generateEventFunction(handlerContext, state, FormEvent.MouseEnter);
        this.onMouseOver = this.generateEventFunction(handlerContext, state, FormEvent.MouseOver);
        this.onMouseLeave = this.generateEventFunction(handlerContext, state, FormEvent.MouseLeave);
        this.onKeyUp = this.generateEventFunction(handlerContext, state, FormEvent.KeyUp);
        this.onKeyDown = this.generateEventFunction(handlerContext, state, FormEvent.KeyDown);
        this.onKeyPress = this.generateEventFunction(handlerContext, state, FormEvent.KeyPress);
        this.onFocus = this.generateEventFunction(handlerContext, state, FormEvent.Focus);
        this.onBlur = this.generateEventFunction(handlerContext, state, FormEvent.Blur);
    }

    /***********************************
     **         Business logic        **
     ***********************************/

    wrap(eventId: string,
         wrapper: (event: any, value: any) => void): FormHandlerProperties<T>
    {
        let self = this as any;
        let propertyName = 'on' + eventId;
        let oldFunction = self[propertyName];

        self[propertyName] = this.generateWrapperFunction(oldFunction, wrapper);

        return this;
    }

    generateEventFunction(handlerContext: FormHandlerContext<T>,
                          state: T,
                          eventId: string): (event: any, value: any) => void
    {
        return (event, value) =>
        {
            let formEvent = new FormEvent<T>(eventId, state, event);

            // TODO: this nullcheck should be removed, the handlercontext may never be null
            if (handlerContext != null)
            {
                handlerContext.perform(formEvent);
            }
            else
            {
                // console.warn('HandlerContext may not be null.');
            }
        };
    }

    generateWrapperFunction(func: (event: any, value: any) => void,
                            wrapper: (event: any, value: any) => void): (event: any, value: any) => void
    {
        return (event, value) =>
        {
            wrapper(event, value);
            func(event, value);
        };
    }

    static create<T>(handlerContext: FormHandlerContext<T>,
                     state: T): FormHandlerProperties<T>
    {
        return new FormHandlerProperties<T>(handlerContext, state);
    }

    // -------------- Getters & setters ------------------

}
