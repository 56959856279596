import React, { useCallback, useContext } from 'react';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { EntityRelationshipDefinition } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import useResults from '../Selection/Hooks/useResults';
import { createTransactionalModel } from '../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import constructEntity from '../../../../@Api/Entity/constructEntity';
import { observer } from 'mobx-react-lite';
import { EntityField } from '../../../../@Api/Model/Implementation/EntityField';
import EntityCacheContext from '../../../Service/Entity/EntityCacheContext';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';

export interface EntityTreeViewProps
{
    categoryType: EntityType;
    childCategoriesRelationshipDefinition: EntityRelationshipDefinition;
    categoryNameField: EntityField;
    parentCategory?: Entity;
    onSelectCategory: (category: Entity) => void;
    selectedCategory: Entity;
    isInEditMode?: boolean;
}

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
    item: {
        padding: '5px 15px',
        borderRadius: 5,
        fontWeight: 400,
        fontSize: 14
    }
});

const EntityTreeView: React.FC<EntityTreeViewProps> =
    props =>
    {
        const { categoryType, childCategoriesRelationshipDefinition, categoryNameField, parentCategory, onSelectCategory, selectedCategory } = props;
        const entityCacheService = useContext(EntityCacheContext);

        const onSelectNodeId =
            useCallback(
                (change: any, nodeId: string) =>
                    onSelectCategory(entityCacheService.getEntity(nodeId)),
                [
                    onSelectCategory,
                    entityCacheService
                ]);

        const classes = useStyles();
        const childCategories =
            useResults(
                categoryType,
                (builder, rootPath) =>
                    builder
                        .if(
                            () =>
                                parentCategory === undefined,
                            () =>
                                builder.where(
                                    cb =>
                                        cb.isNotDefined(
                                            rootPath.joinTo(
                                                childCategoriesRelationshipDefinition,
                                                true)
                                                .field())))
                        .if(
                            () =>
                                parentCategory !== undefined,
                            () =>
                                builder.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                childCategoriesRelationshipDefinition,
                                                true),
                                            parentCategory)))
                        .orderBy(
                            rootPath.field(categoryNameField),
                            true),
                [
                    parentCategory,
                    childCategoriesRelationshipDefinition
                ]);

        const createNewCategory =
            useCallback(
                () =>
                {
                    const newCategory =
                        createTransactionalModel(
                            new Entity(categoryType)
                                .initialize());

                    if (parentCategory)
                    {
                        newCategory.updateRelationship(
                            true,
                            childCategoriesRelationshipDefinition,
                            createTransactionalModel(parentCategory));
                    }

                    return constructEntity(
                        categoryType,
                        undefined,
                        newCategory,
                        undefined,
                        false);
                },
                [
                    categoryType,
                    parentCategory,
                    childCategoriesRelationshipDefinition
                ]);

        if (childCategories)
        {
            const content =
                <>
                    {
                        !parentCategory &&
                            <TreeItem
                                nodeId="uncategorized"
                                label={
                                    <LocalizedText
                                        code="Generic.Uncategorized"
                                        value="Ongecategoriseerd"
                                    />
                                }
                                classes={{
                                    label: classes.item
                                }}
                            />
                    }
                    {
                        childCategories.map(
                            childCategory =>
                                <TreeItem
                                    key={childCategory.uuid}
                                    nodeId={childCategory.uuid}
                                    label={childCategory.name}
                                    classes={{
                                        label: classes.item
                                    }}
                                >
                                    <EntityTreeView
                                        {...props}
                                        parentCategory={childCategory}
                                    />
                                </TreeItem>)
                    }
                    {
                        props.isInEditMode && selectedCategory === parentCategory &&
                            <TreeItem
                                nodeId="new"
                                label={`+ ${categoryType.getName(false)}`}
                                onClick={createNewCategory}
                                classes={{
                                    label: classes.item
                                }}
                            />
                    }
                </>;

            if (parentCategory)
            {
                return content;
            }
            else
            {
                return <TreeView
                    className={classes.root}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpanded={['root']}
                    defaultExpandIcon={<ChevronRightIcon />}
                    onNodeSelect={onSelectNodeId}
                    selected={selectedCategory?.uuid || 'uncategorized'}
                >
                    {content}
                </TreeView>;
            }
        }
        else
        {
            return null;
        }
    };

export default observer(EntityTreeView);
