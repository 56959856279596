import { RecurrencePattern } from '../Model/RecurrencePattern';
import { Occurrence } from '../Model/Occurrence';
import { getRecurrencePatternAsRRule } from './RRule/getRecurrencePatternAsRRule';
import { addMilliseconds } from 'date-fns';
import { mapBy } from '../../../../@Util/MapUtils/mapBy';

interface Query<T extends RecurrencePattern>
{
    pattern: T;
    from: Date;
    to: Date;
    occurrenceDurationInMillis: number;
}

export function queryOccurrences<T extends RecurrencePattern>(
    query: Query<T>
): Occurrence[]
{
    const rrule = getRecurrencePatternAsRRule(query.pattern);

    let idx = 0;
    const occurrences: Occurrence[] = [];
    const modifiedOccurrenceByDate =
        mapBy(
            query.pattern.modifiedOccurrences,
            occurrence =>
                new Date(occurrence.startDate).toISOString()
        );

    for (const occurrence of rrule.between(query.from, query.to, true))
    {
        // Convert occurence (UTC) to local date by adding timezone offset
        const occurenceInLocalDatetime = new Date(occurrence.getTime() + occurrence.getTimezoneOffset() * 60 * 1000);

        if (occurenceInLocalDatetime >= query.from && occurenceInLocalDatetime < query.to)
        {
            const occurrenceAsString = occurenceInLocalDatetime.toISOString();
            const modifiedOccurrence = modifiedOccurrenceByDate.get(occurrenceAsString);
            const originalStartDate =
                modifiedOccurrence
                    ? new Date(modifiedOccurrence.originalStartDate)
                    : occurenceInLocalDatetime;
            const endDate =
                modifiedOccurrence
                    ? new Date(modifiedOccurrence.endDate)
                    : addMilliseconds(occurenceInLocalDatetime, query.occurrenceDurationInMillis);

            occurrences.push({
                idx,
                originalStartDate,
                startDate: occurenceInLocalDatetime,
                endDate
            });
        }

        idx++;
    }

    return occurrences;
}
