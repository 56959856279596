import { DataObjectType } from '../../../@Component/Domain/DataObject/Model/DataObjectType';
import { NumberType } from '../../../@Component/Domain/DataObject/Type/Number/NumberType';
import { isPrimitiveTypeSearchable } from './isPrimitiveTypeSearchable';

export function isPrimitiveTypeSearchableForKeyword(
    type: DataObjectType,
    keyword: string
)
{
    if (isPrimitiveTypeSearchable(type))
    {
        const isKeywordNumeric = !isNaN(parseFloat(keyword));
        const isSearchFieldNumeric = type instanceof NumberType;

        return (isSearchFieldNumeric && isKeywordNumeric) || !isSearchFieldNumeric;
    }
    else
    {
        return false;
    }
}
