import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityContext } from '../../../../@Model/EntityContext';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import useTypes from '../../../../Type/Api/useTypes';
import FormContext from '../../../Context/FormContext';
import StaticSelectbox from '../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import FieldInput from '../../../../../Multiplayer/Model/Input/FieldInput';
import RelationshipInput from '../../../../../Multiplayer/Model/Input/RelationshipInput';
import Input from '../../../../../Multiplayer/Model/Input/Input';

export interface FieldPickerProps
{
    onSelect: (field: Input) => void;
    field?: Input;
}

const FieldEditor: React.FC<FieldPickerProps> =
    props =>
    {
        const types = useTypes();
        const context = useContext(FormContext);

        const fieldContext =
            useMemo(
                () =>
                    new EntityContext(
                        [],
                        EntityPath.fromEntityType(context.entityType)),
                [
                    context
                ]);

        const entityType =
            useMemo(
                () =>
                    fieldContext.entityPath.entityType,
                [
                    types
                ]);

        const inputOptions =
            useComputed<Input[]>(
                () => [
                    ...entityType.fields.map(
                        field =>
                            new FieldInput(field.entityType, field)),
                    ...entityType.childRelationshipDefinitions
                        .map(
                            relationshipDefinition =>
                                new RelationshipInput(
                                    relationshipDefinition.parentEntityType,
                                    relationshipDefinition,
                                    false))
                ],
                [
                    entityType
                ]);

        const fieldOptions =
            useMemo(
                () =>
                    inputOptions.map(
                        option => ({
                            id: option.id(),
                            label: option.getName(),
                            value: option.id()
                        })),
                [
                    inputOptions
                ]);

        const selectionChange =
            useCallback(
                fieldId =>
                {
                    const field =
                        inputOptions
                            .find(
                                f =>
                                    f.id() === fieldId);

                    if (field)
                    {
                        props.onSelect(field);
                    }
                },
                [
                    entityType,
                    props.onSelect
                ]);

        return <StaticSelectbox
            options={fieldOptions}
            value={props.field?.id()}
            onChange={selectionChange}
            open={props.field === undefined}
        />;
    };

export default observer(FieldEditor);
