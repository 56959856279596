import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationshipDefinition } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import LinkRelatedEntityDialog from './LinkRelatedEntityDialog';
import useSwitch from '../../../../../../../@Util/Switch/useSwitch';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import Icon from '../../../../../../../@Future/Component/Generic/Icon/Icon';
import Tooltip from '../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';

export interface LinkRelatedEntityButtonProps
{
    entity: Entity;
    relationshipDefinition: EntityRelationshipDefinition;
    parent: boolean;
    relatedEntityType?: EntityType;
}

const LinkRelatedEntityButton: React.FC<LinkRelatedEntityButtonProps> =
    ({
         entity,
         relationshipDefinition,
         parent: isParent,
         relatedEntityType
     }) =>
    {
        const [ isLinkOpen, openLink, closeLink ] = useSwitch(false);
        const entityType =
            useMemo(
                () =>
                    isParent
                        ? relationshipDefinition.parentEntityType
                        : relationshipDefinition.childEntityType,
                [
                    isParent,
                    relationshipDefinition,
                ]
            );

        return <Tooltip
            title={
                <LocalizedText
                    code="Generic.LinkEntityType"
                    value="${entityTypeName} koppelen"
                    entityTypeName={entityType.getName()}
                />
            }
        >
            <PrimaryButton
                label={
                    <Icon
                        icon="link"
                        size="20px"
                    />
                }
                onClick={openLink}
                size="sm"
            />
            {
                isLinkOpen &&
                <LinkRelatedEntityDialog
                    entity={entity}
                    relationshipDefinition={relationshipDefinition}
                    isParent={isParent}
                    onClose={closeLink}
                    relatedEntityType={relatedEntityType}
                />
            }
        </Tooltip>;
    };

export default observer(LinkRelatedEntityButton);