import React, { useCallback, useMemo, useRef, useState } from 'react';
import { default as MuiButton } from '@material-ui/core/Button';
import { BaseButtonProps, useButtonClickHandler } from '../../Button';
import { LinearProgress, PropTypes } from '@material-ui/core';
import Icon from '../../../Icon/Icon';
import styles from './LabelButton.module.scss';
import { classNames } from '../../../../../Util/Class/classNames';
import Tooltip from '../../../Tooltip/Tooltip';
import useSafeState from '../../../../../Util/SafeState/useSafeState';

export interface LabelButtonClasses
{
    root?: string;
    icon?: string;
    label?: string;
    loader?: string;
}

export type LabelButtonSize = 'xs' | 'sm' | 'md';

export interface LabelButtonProps extends BaseButtonProps
{
    label: React.ReactNode;
    icon?: string;
    iconOutlined?: boolean;
    color?: PropTypes.Color | string;
    hoverColor?: string;
    outlined?: boolean;
    filled?: boolean;
    classes?: LabelButtonClasses;
    disableShadow?: boolean;
    size?: LabelButtonSize;
    tooltip?: React.ReactNode;
    loading?: boolean;
    id?: string;
}


const LabelButton: React.FC<LabelButtonProps> =
    props =>
    {
        const ref = useRef();
        const [ _isLoading, setLoading ] = useSafeState(ref, false);
        const [ isHovering, setHovering ] = useState(false);

        const isLoading = _isLoading || props.loading;

        const startHovering = useCallback(() => setHovering(true), [ setHovering ]);
        const stopHovering = useCallback(() => setHovering(false), [ setHovering ]);
        const isFilled = props.filled || (props.outlined && isHovering);
        const color =
            props.disabled
                ?
                    'rgba(0, 0, 0, 0.26)'
                :
                    isFilled
                        ?
                            'white'
                        :
                            isHovering
                                ?
                                    props.hoverColor
                                :
                                    props.color;

        const size = props.size || 'md';
        let sizeClass: string;

        switch (size)
        {
            case 'xs':
                sizeClass = styles.xs;
                break;

            case 'sm':
                sizeClass = styles.sm;
                break;

            case 'md':
                sizeClass = styles.md;
                break;
        }

        const muiColor: PropTypes.Color =
            useMemo(
                () =>
                    props.color === 'primary'
                        ?
                            'primary' as PropTypes.Color
                        :
                            undefined,
                [
                    props.color
                ]);

        const classes =
            useMemo(
                () => ({
                    root: classNames(styles.root, sizeClass, props.classes && props.classes.root),
                    label: classNames(styles.label, sizeClass, props.disabled && styles.disabled, props.classes && props.classes.label)
                }),
                [
                    props.classes,
                    props.disabled,
                    sizeClass
                ]);

        return <Tooltip
            title={props.tooltip}
            fullWidth={props.fullWidth}
        >
            <MuiButton
                id={props.id}
                ref={ref}
                onClick={useButtonClickHandler(props.onClick, setLoading)}
                disabled={isLoading || props.disabled}
                onMouseOver={startHovering}
                onMouseLeave={stopHovering}
                fullWidth={props.fullWidth}
                classes={classes}
                color={muiColor}
                style={{
                    color: color,
                    border:
                        props.outlined && props.color
                            ?
                                `1px solid ${props.color}`
                            :
                                undefined,
                    backgroundColor:
                        isFilled
                            ?
                                props.disabled
                                    ?
                                        'rgba(0, 0, 0, 0.12)'
                                    :
                                        props.color
                            :
                                props.disableShadow
                                    ?
                                        'unset'
                                    :
                                        undefined
                }}
            >
                {
                    props.icon &&
                        <Icon
                            className={
                                classNames(
                                    styles.icon,
                                    sizeClass,
                                    props.disabled && styles.disabled,
                                    props.classes && props.classes.icon)}
                            icon={props.icon}
                            outlined={props.iconOutlined}
                            color={color}
                        />
                }
                <div
                    className={classNames(styles.label, sizeClass, props.classes && props.classes.label)}
                >
                    {props.children}
                    {props.label}
                    {
                        isLoading &&
                            <LinearProgress
                                classes={{
                                    root: classNames(styles.loader, props.classes && props.classes.loader)
                                }}
                            />
                    }
                </div>
            </MuiButton>
        </Tooltip>;
    };

export default LabelButton;
