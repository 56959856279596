import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityFieldGroup } from '../../../../../Management/Application/EntityTypesEditor/EntityFieldGroup';
import Expansion from './Expansion/Expansion';
import { EntityPath } from '../../../@Model/EntityPath';
import { EntityFieldPath } from '../../../@Model/EntityFieldPath';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import styles from './Group.module.scss';
import { makeStyles } from '@material-ui/core';

export interface GroupProps
{
    path: EntityPath;
    onTraverse: (path: EntityPath) => void;
    onSelect: (fieldPath: EntityFieldPath) => void;
    entity: Entity;
    group: EntityFieldGroup;
    depth: number;
    filter?: (fieldPath: EntityFieldPath) => boolean;
}

const useStyles = makeStyles({
    outer:
        {
            minWidth: 100,
            height: '100%',
            whiteSpace: 'break-spaces'
        },
    inner:
        {
            display: 'block',
            overflowY: 'scroll',
            height: '100%',
        }
});

const Group: React.FC<GroupProps> =
    props =>
    {
        const classes = useStyles();

        return <ViewGroup
            orientation="vertical"
            spacing={0}
            className={classes.outer}
        >
            <ViewGroupItem>
                <CardInset>
                    <div
                        className={styles.header}
                    >
                        {props.group.name}
                    </div>
                </CardInset>
            </ViewGroupItem>
            <ViewGroupItem
                className={classes.inner}
            >
                <Expansion
                    path={props.path}
                    onTraverse={props.onTraverse}
                    onSelect={props.onSelect}
                    entity={props.entity}
                    group={props.group}
                    depth={props.depth}
                    filter={props.filter}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(Group);
