import * as React from 'react';
import { DataObjectBespokeEditor } from '../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { observer } from 'mobx-react';
import { Typography } from '@material-ui/core';

@observer
export class MeEditor extends DataObjectBespokeEditor
{
    renderComponent(store: DataObjectEditorStore)
    {
        return <Typography
            variant="body2"
        >
            {
                store.localizationStore.translate('Generic.CurrentUser') // Current user
            }
        </Typography>;
    }
}
