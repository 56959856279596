import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';

export default function useHasMore(entity: Entity)
{
    return true;

    // const types = useTypes();
    //
    // // The hasFields method below is very slow
    // // const hasFields = useHasFields(entity);
    //
    // return useComputed(
    //     () =>
    //     {
    //         if (entity.entityType.isA(types.Attachment.Type))
    //         {
    //             return true;
    //         }
    //         else
    //         {
    //             // return hasFields;
    //             return true;
    //         }
    //     },
    //     [
    //         // hasFields,
    //         types,
    //         entity
    //     ]);
}
