import React from 'react';
import { observer } from 'mobx-react-lite';
import InsetLayout from '../../../../../../../@Api/Layout/Type/InsetLayout';
import InsetLayoutControls from '../../../../Type/Inset/InsetLayoutControls';
import CompositeLayout from '../../../../../../../@Api/Layout/Type/CompositeLayout';
import CompositeLayoutControls from '../../../../Type/Composite/Editor/Controls/CompositeLayoutControls';
import Layout from '../../../../../../../@Api/Layout/Layout';
import EntityInputLayoutControls from '../../../../Type/EntityInput/EntityInputLayoutControls';
import EntityInputLayout from '../../../../../../../@Api/Layout/Type/EntityInputLayout';
import { LayoutEditorProps } from '../../../LayoutEditor';
import ClickLayout from '../../../../../../../@Api/Layout/Type/ClickLayout';
import ClickLayoutControls from '../../../../Type/Click/ClickLayoutControls';
import PrimitiveFormInputLayoutControls from '../../../../Type/FormInput/PrimitiveFormInput/PrimitiveFormInputLayoutControls';
import PrimitiveFormInputLayout from '../../../../../../../@Api/Layout/Type/Form/Input/PrimitiveFormInputLayout';
import LabelButtonLayout from '../../../../../../../@Api/Layout/Type/LabelButtonLayout';
import LabelButtonLayoutControls from '../../../../Type/LabelButton/LabelButtonLayoutControls';
import FormLayout from '../../../../../../../@Api/Layout/Type/Form/FormLayout';
import FormLayoutControls from '../../../../Type/Form/FormLayoutControls';
import SelectionFormInputLayout from '../../../../../../../@Api/Layout/Type/Form/Input/SelectionFormInputLayout';
import SelectionFormInputLayoutControls from '../../../../Type/FormInput/SelectionFormInput/SelectionFormInputLayoutControls';
import LabelLayout from '../../../../../../../@Api/Layout/Type/LabelLayout';
import LabelLayoutControls from '../../../../Type/Label/LabelLayoutControls';
import PortalImageLayout from '../../../../../../../@Api/Layout/Type/PortalImageLayout';
import PortalImageLayoutControls from '../../../../Type/PortalImage/PortalImageLayoutControls';
import AppBarLayoutControls from '../../../../Type/AppBar/AppBarLayoutControls';
import AppBarLayout from '../../../../../../../@Api/Layout/Type/AppBarLayout';
import CenteredBodyLayout from '../../../../../../../@Api/Layout/Type/CenteredBodyLayout';
import CenteredBodyLayoutControls from '../../../../Type/CenteredBody/CenteredBodyLayoutControls';
import BodyLayout from '../../../../../../../@Api/Layout/Type/BodyLayout';
import BodyLayoutControls from '../../../../Type/Body/BodyLayoutControls';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import CardLayout from '../../../../../../../@Api/Layout/Type/CardLayout';
import CardLayoutControls from '../../../../Type/Card/CardLayoutControls';
import ResourceImageLayout from '../../../../../../../@Api/Layout/Type/ResourceImageLayout';
import ResourceImageLayoutControls from '../../../../Type/ResourceImage/ResourceImageLayoutControls';
import TemplateLayout from '../../../../../../../@Api/Layout/Type/TemplateLayout';
import TemplateLayoutControls from '../../../../Type/Template/TemplateLayoutControls';
import ExpansionLayout from '../../../../../../../@Api/Layout/Type/ExpansionLayout';
import ExpansionLayoutControls from '../../../../Type/Expansion/ExpansionLayoutControls';
import DrawingFormInputLayout from '../../../../../../../@Api/Layout/Type/Form/Input/DrawingFormInputLayout';
import DrawingFormInputLayoutControls from '../../../../Type/FormInput/DrawingFormInput/DrawingFormInputLayoutControls';
import ComputedImageLayout from '../../../../../../../@Api/Layout/Type/ComputedImageLayout';
import ComputedImageLayoutControls from '../../../../Type/ComputedImage/ComputedImageLayoutControls';
import EntitySelectionFormInputLayoutControls from '../../../../Type/FormInput/EntitySelectionFormInput/EntitySelectionFormInputLayoutControls';
import EntitySelectionFormInputLayout from '../../../../../../../@Api/Layout/Type/Form/Input/EntitySelectionFormInputLayout';
import CollectionLayout from '../../../../../../../@Api/Layout/Type/CollectionLayout';
import CollectionLayoutControls from '../../../../Type/Collection/Editor/Controls/CollectionLayoutControls';
import FilesFormInputLayout from '../../../../../../../@Api/Layout/Type/Form/Input/FilesFormInputLayout';
import FilesFormInputLayoutControls from '../../../../Type/FormInput/FilesFormInput/FilesFormInputLayoutControls';
import EffectLayout from '../../../../../../../@Api/Layout/Type/EffectLayout';
import EffectLayoutControls from '../../../../Type/Effect/EffectLayoutControls';
import AddressLookupLayout from '../../../../../../../@Api/Layout/Type/AddressLookupLayout';
import AddressLookupLayoutControls from '../../../../Type/AddressLookup/AddressLookupLayoutControls';

export interface LayoutControlsTypeContentProps<L extends Layout> extends LayoutEditorProps<L>
{
}

const LayoutControlsTypeContent: React.FC<LayoutControlsTypeContentProps<Layout>> =
    props =>
    {
        const layout = props.layout;

        if (layout instanceof CompositeLayout)
        {
            return <CompositeLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof CollectionLayout)
        {
            return <CollectionLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof InsetLayout)
        {
            return <InsetLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (props.layout instanceof ClickLayout)
        {
            return <ClickLayoutControls
                {...props}
                layout={layout as any}
            />;
        }
        else if (layout instanceof CardLayout)
        {
            return <CardLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof ExpansionLayout)
        {
            return <ExpansionLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (props.layout instanceof LabelButtonLayout)
        {
            return <LabelButtonLayoutControls
                {...props}
                layout={layout as any}
            />;
        }
        else if (props.layout instanceof LabelLayout)
        {
            return <LabelLayoutControls
                {...props}
                layout={layout as any}
            />;
        }
        else if (layout instanceof EntityInputLayout)
        {
            return <EntityInputLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof PrimitiveFormInputLayout)
        {
            return <PrimitiveFormInputLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof EntitySelectionFormInputLayout)
        {
            return <EntitySelectionFormInputLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof SelectionFormInputLayout)
        {
            return <SelectionFormInputLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof DrawingFormInputLayout)
        {
            return <DrawingFormInputLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof FilesFormInputLayout)
        {
            return <FilesFormInputLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof FormLayout)
        {
            return <FormLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof ResourceImageLayout)
        {
            return <ResourceImageLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof PortalImageLayout)
        {
            return <PortalImageLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof ComputedImageLayout)
        {
            return <ComputedImageLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof AppBarLayout)
        {
            return <AppBarLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof BodyLayout)
        {
            return <BodyLayoutControls
                {...props}
                layout={layout}
            />
        }
        else if (layout instanceof CenteredBodyLayout)
        {
            return <CenteredBodyLayoutControls
                {...props}
                layout={layout}
            />
        }
        else if (layout instanceof TemplateLayout)
        {
            return <TemplateLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof EffectLayout)
        {
            return <EffectLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else if (layout instanceof AddressLookupLayout)
        {
            return <AddressLookupLayoutControls
                {...props}
                layout={layout}
            />;
        }
        else
        {
            return <CardInset
                vertical={false}
            >
                <Centered
                    horizontal
                >
                    <LocalizedText
                        code="Layout.NoControlsAvailable"
                        value="Er zijn géén instellingen beschikbaar op dit element."
                    />
                </Centered>
            </CardInset>;
        }
    };

export default observer(LayoutControlsTypeContent);
