import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import AppBarLayout from '../../../../../@Api/Layout/Type/AppBarLayout';
import ColorEditor from '../../../../../@Future/Component/Generic/Input/ColorEditor/ColorEditor';
import { runInAction } from 'mobx';
import Color from '../../../../../@Api/Layout/Style/Color';
import { primaryColor } from '../../../../../@Resource/Theme/Theme';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';

export interface AppBarLayoutControlsProps extends LayoutEditorProps<AppBarLayout>
{

}

const AppBarLayoutControls: React.FC<AppBarLayoutControlsProps> =
    props =>
    {
        const setColor =
            useCallback(
                (value?: string) =>
                    runInAction(
                        () =>
                            props.layout.color = new Color(value || primaryColor)),
                [
                    props.layout
                ]);

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.Color"
                            value="Kleur"
                        />
                    }
                >
                    <ColorEditor
                        value={props.layout.color.value}
                        onChange={setColor}
                    />
                </Input>
            </InputGroup>
        </CardInset>;
    };

export default observer(AppBarLayoutControls);
