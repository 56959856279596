import { observable } from 'mobx';
import { DataObjectType } from './DataObjectType';
import { DataObject } from './DataObject';
import { Comparator } from './Comparator';
import { DataObjectOverloadType } from './DataObjectOverloadType';

export class DataObjectComparatorOverload
{
    // ------------------------- Properties -------------------------

    @observable dataObjectType: DataObjectType;
    @observable comparator: Comparator;
    @observable type: DataObjectOverloadType;
    @observable isCompatible: (type: DataObjectType | undefined) => boolean;
    @observable compare: (value: DataObject | undefined, relatedValue: DataObject | undefined, isThisLhs: boolean) => boolean;

    // ------------------------ Constructor -------------------------

    constructor(dataObjectType: DataObjectType,
                comparator: Comparator,
                type: DataObjectOverloadType,
                isCompatible: (type: DataObjectType | undefined) => boolean,
                compare: (value: DataObject | undefined, relatedValue: DataObject | undefined, isThisLhs: boolean) => boolean)
    {
        this.dataObjectType = dataObjectType;
        this.comparator = comparator;
        this.type = type;
        this.isCompatible = isCompatible;
        this.compare = compare;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
