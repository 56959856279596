import View from '../Model/View';

export function getNumberOfVisibleColumnsInView(
    view: View,
    isSortable: boolean,
    isSelectable: boolean,
    isInEditMode: boolean
)
{
    return (isSortable ? 1 : 0)
        + (isSelectable ? 1 : 0)
        + view.specification.list.columns.filter(
            column =>
                column.shouldShowColumn(
                    view,
                    isInEditMode
                )
        ).length;
}