import { Type } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import getHiddenFields from './getHiddenFields';
import isHiddenField from '../../../../../@Api/Metadata/Input/isHiddenField';
import resolveInputFromFieldPath from '../../../Multiplayer/Model/Input/Api/resolveInputFromFieldPath';
import { TypeFieldInclusion } from './useVisibleFieldGroups';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { CurrentUserStore } from '../../../User/CurrentUserStore';

export default function getVisibleFieldPathsForType(
    entityType: EntityType,
    ignoreHidden?: boolean,
    includeTypeField: TypeFieldInclusion = 'auto'
)
{
    const entityTypeStore = loadModuleDirectly(EntityTypeStore);
    const currentUserStore = loadModuleDirectly(CurrentUserStore);
    const hiddenFields = getHiddenFields(entityType);

    const hideStaticFields = true;
    const hideComputedFields = true;
    const inheritedAvatarField = entityType.getInheritedAvatarField();

    const fieldPaths = entityTypeStore
        .getVisibleEntityFieldPaths(entityType, false, ignoreHidden)
        .filter(
            path =>
                path.field
                    ?
                        ignoreHidden ||
                        (!path.field.entity?.getObjectValueByField(entityTypeStore.bespoke.types.EntityField.Field.IsHiddenFromDetails)
                            && (path.field !== inheritedAvatarField || entityType.isA(entityTypeStore.bespoke.types.Resource.Type))
                            && (!hideStaticFields || (hideStaticFields && !path.field.isStaticField()))
                            && (!hideComputedFields || (hideComputedFields && !path.field.isComputedField)))
                    :
                    path.relationshipDefinition
                        ?
                            path.relationshipDefinition.type !== Type.Definition
                            && (!hideComputedFields || (hideComputedFields && !path.relationshipDefinition.isComputed(path.path.lastJoinNode.isParent)))
                        :
                            false
        );

    const typeFieldPath =
        EntityPath.fromEntityType(entityType)
            .field(entityTypeStore.bespoke.types.Entity.Field.Type);

    if (includeTypeField === 'auto'
        && ((entityType.isSwitchableByInheritance()
            || !entityType.isInstantiableByInheritance())))
    {
        fieldPaths.unshift(typeFieldPath);
    }
    else if (includeTypeField === 'include')
    {
        fieldPaths.unshift(typeFieldPath);
    }

    if (ignoreHidden)
    {
        return fieldPaths;
    }
    else
    {
        return fieldPaths
            .filter(
                path =>
                    path.relationshipDefinition
                        ?
                        !hiddenFields.has(path.relationshipDefinition)
                        :
                        !hiddenFields.has(path.field))
            .filter(
                path =>
                    path.relationshipDefinition
                        ?
                            currentUserStore
                                .rightProfile
                                .role
                                .isPermissionGrantedForRelationshipTypeFromSide(
                                    entityType,
                                    path.relationshipDefinition,
                                    path.isParentRelationship,
                                    'canRead'
                                )
                        :
                            currentUserStore
                                .rightProfile
                                .role
                                .isPermissionGrantedForField(
                                    entityType,
                                    path.field,
                                    'canRead'
                                )
            )
            .filter(
                fieldPath =>
                    !isHiddenField(
                        resolveInputFromFieldPath(
                            fieldPath
                        )
                    )
            );
    }
}
