import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';

export class BespokeEvent extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Event')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getEntityToOpen(entity: Entity): Entity
    {
        return entity.getRelatedEntityByDefinition(
            true,
            this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Events);
    }

    getEntityToList(entity: Entity, isRelated: boolean): Entity
    {
        if (isRelated)
        {
            return entity;
        }
        else
        {
            return entity.getRelatedEntityByDefinition(
                true,
                this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Events);
        }
    }

    showSaveNotification(entity: Entity)
    {
        return false;
    }

    showDeleteNotification(entity: Entity)
    {
        return false;
    }

    // ----------------------- Private logic ------------------------
}
