import React, { useCallback, useContext } from 'react';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import { useComputed } from 'mobx-react-lite';
import useTypes from '../Type/Api/useTypes';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import IconButton from '../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import Segment from '../Dataset/Model/Segment';
import RouterContext from '../../../../@Service/Router/RouterContext';
import View from '../View/Model/View';
import Specification from '../View/Model/Specification';
import Column from '../View/Model/Column/Column';
import CampaignConstructorButton from './CampaignConstructorButton/CampaignConstructorButton';
import CurrentUserContext from '../../User/CurrentUserContext';
import MenuButton from '../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../@Future/Component/Generic/Menu/Item/Item';
import { openEntity } from '../@Util/openEntity';
import getDatastoreByCode from '../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import { observer } from 'mobx-react';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import Predicate from '../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import getSystemDefaultView from '../View/Api/getSystemDefaultView';
import List from '../View/Model/Specification/List';
import Layout from '../../../../@Api/Layout/Layout';
import { saveSelectionOptionsValueInStorageAndGetKey } from './Api/saveSelectionOptionsValueInStorageAndGetKey';
import ParameterDictionary from '../../../../@Api/Automation/Parameter/ParameterDictionary';
import { CommitBuilder } from '../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import getConnectorActivationByCode from '../../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';
import useAsyncResult from '../../../../@Util/Async/useAsyncResult';

export interface SelectionOptionsProps
{
    entityType: EntityType;
    parameters: ParameterDictionary;
    segment?: Segment;
    name: string;
    filter?: Predicate;
    filtered?: boolean;
    list?: List;
    itemLayout?: Layout;
    disableOpenAsList?: boolean;
    disableOpenAsMap?: boolean;
}

const SelectionOptions: React.FC<SelectionOptionsProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);

        const allowCampaignConstructor =
            useComputed(
                () =>
                    types.Activity.Campaign.Type !== undefined,
                [
                    types
                ]);

        const [ googleMapsConnectorActivated, loadingConnectorActivation ] =
            useAsyncResult(
                () =>
                    getConnectorActivationByCode('GoogleMaps'),
                [
                ]
            );


        const routerStore = useContext(RouterContext);
        const openAsListView =
            useCallback(
                async () =>
                {
                    const view =
                        getSystemDefaultView(
                            props.entityType,
                            props.parameters,
                            props.filter?.normalize(),
                            props.name,
                            props.itemLayout
                        );

                    if (props.list)
                    {
                        view.specification.list = props.list;
                    }

                    const selectionOptionsValueKey =
                        saveSelectionOptionsValueInStorageAndGetKey({
                            view: view,
                            filter: props.filter?.normalize()
                        });
                    const selectionOptionsAsUrl =
                        btoa(
                            JSON.stringify({
                                selectionOptionsValueKey,
                            })
                        );

                    return routerStore.route(`/type/${props.entityType.id}/${selectionOptionsAsUrl}`);
                },
                [
                    routerStore,
                    props.entityType,
                    props.parameters,
                    props.filter,
                    props.name,
                    props.list,
                    props.itemLayout,
                ]);

        const openAsColumnView =
            useCallback(
                async () =>
                {
                    const view =
                        new View(
                            'Column',
                            props.name,
                            props.entityType,
                            props.parameters,
                            props.filter?.normalize(),
                            new Specification(
                                props.list,
                                new Column(
                                    props.segment.groupFieldPath,
                                    props.segment.aggregateValues,
                                    [],
                                    props.itemLayout
                                )
                            )
                        );
                    const selectionOptionsValueKey =
                        saveSelectionOptionsValueInStorageAndGetKey({
                            view: view,
                            filter: props.filter?.normalize()
                        });
                    const selectionOptionsAsUrl =
                        btoa(
                            JSON.stringify({
                                selectionOptionsValueKey,
                            })
                        );

                    return routerStore.route(`/type/${props.entityType.id}/${selectionOptionsAsUrl}`);
                },
                [
                    routerStore,
                    props.entityType,
                    props.parameters,
                    props.name,
                    props.filter,
                    props.segment,
                    props.list
                ]);

        const openAsMapView =
            useCallback(
                async () =>
                {
                    const view =
                        getSystemDefaultView(
                            props.entityType,
                            props.parameters,
                            props.filter?.normalize(),
                            props.name,
                            props.itemLayout
                        );

                    view.type = 'Map';
                    if (props.list)
                    {
                        view.specification.list = props.list;
                    }

                    const selectionOptionsValueKey =
                        saveSelectionOptionsValueInStorageAndGetKey({
                            view: view,
                            filter: props.filter?.normalize()
                        });
                    const selectionOptionsAsUrl =
                        btoa(
                            JSON.stringify({
                                selectionOptionsValueKey,
                            })
                        );

                    return routerStore.route(`/type/${props.entityType.id}/${selectionOptionsAsUrl}`);
                },
                [
                    routerStore,
                    props.entityType,
                    props.name,
                    props.filter,
                    props.segment,
                    props.list
                ]);

        const createBulkDeletion =
            useCallback(
                async () =>
                {
                    const conceptPhase =
                        await getDatastoreByCode(
                            types.Datastore.Phase.BulkDeletion.Type,
                            types.BulkDeletion.Phase.Concept
                        );

                    return new CommitBuilder()
                        .createEntity(
                            types.BulkDeletion.Type,
                            builder =>
                                builder
                                    .relateTo(
                                        true,
                                        types.EntityType.RelationshipDefinition.BulkDeletions,
                                        props.entityType.entity
                                    )
                                    .relateTo(
                                        false,
                                        types.BulkDeletion.RelationshipDefinition.Phase,
                                        conceptPhase
                                    )
                                    .ifValid(
                                        () =>
                                            props.filter !== undefined,
                                        () =>
                                            builder.setObjectValue(
                                                types.BulkDeletion.Field.Filter,
                                                props.filter.toDescriptor()
                                            )
                                    ),
                            'BulkDeletion'
                        )
                        .commit()
                        .then(
                            result =>
                                openEntity(
                                    result.getEntity('BulkDeletion')
                                )
                        );
                },
                [
                    types,
                    props.entityType,
                    props.filter
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={5}
        >
            {
                allowCampaignConstructor &&
                    <ViewGroupItem>
                        <CampaignConstructorButton
                            {...props}
                        />
                    </ViewGroupItem>
            }
            {
                !props.disableOpenAsList &&
                    <ViewGroupItem>
                        <IconButton
                            icon="view_list"
                            tooltip={
                                <LocalizedText
                                    code="View.ShowAsListView"
                                    value="Tonen als lijstweergave"
                                />
                            }
                            onClick={openAsListView}
                        />
                    </ViewGroupItem>
            }
            {
                props.segment && props.segment.groupFieldPath &&
                    <ViewGroupItem>
                        <IconButton
                            icon="view_column"
                            tooltip={
                                <LocalizedText
                                    code="View.ShowAsColumnView"
                                    value="Tonen als kolomweergave"
                                />
                            }
                            onClick={openAsColumnView}
                        />
                    </ViewGroupItem>
            }
            {
                !loadingConnectorActivation && googleMapsConnectorActivated && !props.disableOpenAsMap &&
                <ViewGroupItem>
                    <IconButton
                        icon='location_on'
                        tooltip={
                            <LocalizedText
                                code="View.ShowAsMapView"
                                value="Tonen als kaartweergave"
                            />
                        }
                        onClick={openAsMapView}
                    />
                </ViewGroupItem>
            }
            {
                currentUserStore.isConsultant &&
                    <ViewGroupItem>
                        <MenuButton
                            icon="construction"
                        >
                            <Menu>
                                <Item
                                    name={
                                        <LocalizedText
                                            code="Generic.BulkDeletion"
                                            value="Bulkverwijderen"
                                        />
                                    }
                                    onClick={createBulkDeletion}
                                />
                            </Menu>
                        </MenuButton>
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(SelectionOptions);
