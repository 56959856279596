import React from 'react';
import { observer } from 'mobx-react-lite';
import Input from '../../../../../../../Input/Input';
import { ProductLineEditorInterface } from '../Hooks/useProductLine';
import useTypes from '../../../../../../../Type/Api/useTypes';
import { Alignment } from '../../../../../../../../DataObject/Model/DataObject';


export interface ProductLineTotalProps
{
    productLine: ProductLineEditorInterface;
    autoCommit: boolean;
    currency?: string;
    showLabel?: boolean;
    alignment?: Alignment;
}

export const ProductLineTotal: React.FC<ProductLineTotalProps> = observer(
    ({
         productLine,
         autoCommit,
         currency,
         showLabel,
         alignment,
     }) =>
    {
        const types = useTypes();

        return <Input
            entity={productLine.productLine}
            field={
                currency
                    ? types.ProductLine.Field.TotalInCurrency
                    : types.ProductLine.Field.Total
            }
            labelPosition={showLabel ? 'left' : 'none'}
            alignment={alignment}
            disabled={productLine.isProductAndQuantityAndPriceDisabled}
            doAutocommit={autoCommit}
            commitContext={productLine.commitContext}
        />

    }
);