import * as React from 'react';
import { DataObjectViewerStore } from '../../Viewer/DataObjectViewerStore';
import { observer } from 'mobx-react';
import { DataObjectBespokeViewer } from '../../Viewer/DataObjectBespokeViewer';
import { FileViewStore } from './FileViewStore';
import { FileViewComponent } from './FileViewComponent';

@observer
export class FileView extends DataObjectBespokeViewer<FileViewStore>
{
    renderComponent(store: DataObjectViewerStore<FileViewStore>)
    {
        return <FileViewComponent
            store={store}
        />;
    }
}
