import React from 'react';
import { observer } from 'mobx-react-lite';
import ValueType from '../../../../../../../../@Api/Automation/Value/Type/ValueType';
import Parameter from '../../../../../../../../@Api/Automation/Parameter/Parameter';

export interface ParameterViewerProps
{
    value: Parameter<ValueType<any>>;
}

const ParameterViewer: React.FC<ParameterViewerProps> =
    props =>
    {
        return <span>{props.value.name}</span>;
    };

export default observer(ParameterViewer);
