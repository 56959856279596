import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { ButtonStore } from '../../../../../Generic/Button/ButtonStore';
import Button from '../../../../../Generic/Button/Button';
import { ViewComponent } from '../../../../../Generic/ViewStack/Model/ViewComponent';
import { MenuStore } from '../../../../../Generic/Menu/MenuStore';
import { MenuItemStore } from '../../../../../Generic/Menu/MenuItemStore';
import useDeleteNotification from '../Api/useDeleteNotification';
import Menu from '../../../../../Generic/Menu/Menu';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export interface OptionsButtonProps
{
    notification: Entity;
}

const OptionsButton: React.FC<OptionsButtonProps> =
    props =>
    {
        const deleteNotification = useDeleteNotification();

        const buttonStore =
            useComputed(
                () =>
                    new ButtonStore({
                        icon: 'more_vert',
                        onClick:
                            store =>
                                store.openPopperView(
                                    new ViewComponent(
                                        Menu as any,
                                        new MenuStore(
                                            {
                                                items:
                                                    () =>
                                                        [
                                                            new MenuItemStore(
                                                                {
                                                                    name: localizeText('NotificationDelete', 'Notificatie verwijderen'),
                                                                    onClick:
                                                                        () =>
                                                                            deleteNotification(props.notification),
                                                                })
                                                        ]
                                            })
                                    ))
                    }),
                [
                    props.notification,
                    deleteNotification
                ]);

        if (buttonStore)
        {
            return <Button store={buttonStore} />;
        }
        else
        {
            return null;
        }
    };

export default observer(OptionsButton);
