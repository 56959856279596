import React from 'react';
import { observer } from 'mobx-react-lite';
import useVideoPreviewImage from './useVideoPreviewImage';
import { useFetchedApiFile } from '../../../../../../../../@Service/ApiClient/Hooks/useFetchedApiFile';

export interface YoutubeVideoPreviewImageProps
{
    videoUrl?: string;
    videoIdentifier?: string;

    width?: number;
    height?: number;
}

const YoutubeVideoPreviewImage: React.FC<YoutubeVideoPreviewImageProps> =
    props =>
    {
        const src = useVideoPreviewImage(props.videoUrl);
        const [ initializedSrc ] = useFetchedApiFile(src);

        if (initializedSrc)
        {
            return <div
                style={{
                    position: 'relative'
                }}
            >
                <a
                    href={props.videoUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src={initializedSrc}
                        style={{
                            maxWidth: props.width || undefined,
                            maxHeight: props.height || undefined
                        }}
                        alt=""
                    />
                </a>
            </div>;
        }
        else
        {
            return null;
        }
    };

export default observer(YoutubeVideoPreviewImage);
