import Validation from '../../../Automation/Validation/Validation';
import Dependency from '../../../Automation/Parameter/Dependency';
import Value from '../../../Automation/Value/Value';
import LayoutAction from '../LayoutAction';
import EmptyValue from '../../../Automation/Value/EmptyValue';
import LayoutDependencyContext from '../../LayoutDependencyContext';
import { observable } from 'mobx';
import Computation from '../../../Automation/Function/Computation/Computation';
import getComputationFromDescriptor from '../../../Automation/Api/getComputationFromDescriptor';
import ValueType from '../../../Automation/Value/Type/ValueType';
import EmptyValueType from '../../../Automation/Value/Type/EmptyValueType';
import localizeText from '../../../Localization/localizeText';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { RouterStore } from '../../../../@Service/Router/RouterStore';
import safelyApplyFunction from '../../../Automation/Api/safelyApplyFunction';
import LayoutContext from '../../LayoutContext';
import isInPortal from '../../../Portal/Api/isInPortal';

export default class RouteAction extends LayoutAction
{
    // ------------------------- Properties -------------------------

    @observable.ref route: Computation<any, any>;
    @observable doOpenInNewTab: boolean;

    // ------------------------ Constructor -------------------------

    constructor(
        route: Computation<any, any>,
        doOpenInNewTab: boolean = false
    )
    {
        super();

        this.route = route;
        this.doOpenInNewTab = doOpenInNewTab
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async apply(context: LayoutContext): Promise<Value<any, any>>
    {
        const route = await safelyApplyFunction(this.route, context);
        const routeAsString = route.getName();

        await this.performRouteIfPossible(context, routeAsString);

        return EmptyValue.instance;
    }

    private async performRouteIfPossible(
        context: LayoutContext,
        route: string
    )
    {
        if (this.shouldPerformRoute(context, route))
        {
            return this.performRoute(route);
        }
    }

    private shouldPerformRoute(
        context: LayoutContext,
        route: string
    )
    {
        if (context.isInSafeMode)
        {
            return window.confirm(`Navigate to ${route}?`);
        }
        else
        {
            return true;
        }
    }

    private async performRoute(route: string)
    {
        if (route.startsWith('http:')
            || route.startsWith('https:'))
        {
            if (this.doOpenInNewTab)
            {
                window.open(route, '_blank');
            }
            else
            {
                window.location.href = route;
            }
        }
        else
        {
            if (isInPortal())
            {
                loadModuleDirectly(RouterStore).routeVirtually(route);
            }
            else
            {
                await loadModuleDirectly(RouterStore).route(route);
            }
        }
    }

    getReturnType(): ValueType<any>
    {
        return EmptyValueType.instance;
    }

    getName(): string
    {
        return localizeText(
            'LayoutAction.Route.NavigateToRoute',
            'Navigeren naar ${route}',
            {
                route: this.route.getName()
            });
    }

    getDependencies(): Dependency[]
    {
        return this.route.getDependencies();
    }

    validate(): Validation[]
    {
        return this.route.validate();
    }

    toDescriptor()
    {
        return {
            type: 'Route',
            route: this.route.toDescriptor(),
            doOpenInNewTab: this.doOpenInNewTab
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new RouteAction(
            await getComputationFromDescriptor(
                descriptor.route,
                dependencyContext
            ),
            descriptor.doOpenInNewTab ?? false
        );
    }

    // ----------------------- Private logic ------------------------
}
