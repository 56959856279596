import { FileView } from './FileView';
import { FileEditor } from './FileEditor';
import { FileSpecification } from './FileSpecification';
import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../Model/DataObjectType';
import { ComparatorDescriptor } from '../../Model/ComparatorDescriptor';
import { Aggregate } from '../../Model/Aggregate';
import { MathematicalOperator } from '../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../Model/DataObjectFunction';
import { DataObject } from '../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../@Util/DependencyInjection/index';
import { DataDescriptor } from '../../Model/DataDescriptor';
import { Comparator } from '../../Model/Comparator';
import { DataObjectRepresentation } from '../../Model/DataObjectRepresentation';
import { DataObjectOperatorOverload } from '../../Model/DataObjectOperatorOverload';
import { FileValue, FileValueType } from './FileValue';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { DataObjectBespokeEditorStore } from '../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { FileEditorStore } from './FileEditorStore';
import { DataObjectViewerStore } from '../../Viewer/DataObjectViewerStore';
import { DataObjectBespokeViewerStore } from '../../Viewer/DataObjectBespokeViewerStore';
import { FileViewStore } from './FileViewStore';
import { DataObjectContext } from '../../Model/DataObjectContext';
import { DataObjectSpecification } from '../../Model/DataObjectSpecification';
import { DataObjectComparatorOverload } from '../../Model/DataObjectComparatorOverload';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import localizeText from '../../../../../@Api/Localization/localizeText';

export class FileType implements DataObjectType<FileValue>
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'File';
    }

    name()
    {
        return localizeText('DataObject.Type.File', 'Bestand');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [];
    }

    supportedAggregates(): Aggregate[]
    {
        return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return FileView;
    }

    viewStore(baseStore: DataObjectViewerStore): DataObjectBespokeViewerStore
    {
        return new FileViewStore(baseStore);
    }

    editor(): DataObjectEditorType
    {
        return FileEditor;
    }

    editorStore(baseStore: DataObjectEditorStore): DataObjectBespokeEditorStore
    {
        return new FileEditorStore(baseStore);
    }

    hasLargeEditor(specification: DataObjectSpecification): boolean
    {
        return true;
    }

    specification(): DataObjectSpecificationType
    {
        return FileSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return Promise.resolve(true);
    }

    requiresInitialization(value: FileValue): boolean
    {
        return false;
    }

    getDataFromValue(value: FileValue, specification: DataObjectSpecification): DataDescriptor
    {
        let data = new DataDescriptor();
        data.text1 = FileValueType[value.type];
        data.text2 = value.name;
        data.text3 = value.description;
        data.text4 = value.url;
        data.text5 = value.originUrl;
        data.text6 = value.mime;
        data.file1 = value.file;
        data.number1 = value.size;

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): FileValue
    {
        // If type is defined but nothing else, then it is empty (TODO: check why type is defined in the first place)
        if (data.text2 == null
            && data.text3 == null
            && data.text4 == null
            && data.text5 == null
            && data.text6 == null
            && data.file1 == null
            && data.number1 == null)
        {
            return null;
        }
        else
        {
            return new FileValue(
                (data.text1 && (FileValueType as any)[data.text1]) || FileValueType.File,
                data.text2,
                data.text3,
                data.text4,
                data.text5,
                data.text6,
                data.file1,
                data.number1);
        }
    }

    valueId(value: FileValue): string
    {
        return JSON.stringify(value);
    }

    getString(value: FileValue,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        return value.name;
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return false;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [];
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    isValid(dataObject: DataObject): boolean
    {
        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return false;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }
}
