import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import PortalRouterLayout from '../../../../../@Api/Layout/Type/PortalRouterLayout';
import styles from './PortalRouterLayoutEditor.module.scss';
import Icon from '../../../../../@Future/Component/Generic/Icon/Icon';

export interface PortalRouterLayoutEditorProps extends LayoutEditorProps<PortalRouterLayout>
{

}

const PortalRouterLayoutEditor: React.FC<PortalRouterLayoutEditorProps> =
    props =>
    {
        return <div
            className={styles.root}
        >
            <Icon
                icon="dashboard"
            />
        </div>;
    };

export default observer(PortalRouterLayoutEditor);
