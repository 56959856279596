import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutActionEditorProps } from '../../LayoutActionEditor';
import RefreshAction from '../../../../../../@Api/Layout/Action/Type/RefreshAction';

export interface RefreshActionEditorProps extends LayoutActionEditorProps<RefreshAction>
{

}

const RefreshActionEditor: React.FC<RefreshActionEditorProps> =
    props =>
    {
        return null;
    };

export default observer(RefreshActionEditor);
