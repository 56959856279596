import { PredicateEditorStore } from '../../PredicateEditorStore';
import { action, observable } from 'mobx';
import { PredicateContext } from '../../PredicateContext';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import { PredicateType } from '../PredicateType';
import { PredicateSpecification } from '../PredicateSpecification';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { PredicateTypeStore } from '../../PredicateTypeStore';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';

export class CompositePredicateEditorStore extends PredicateEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('PredicateTypeStore') predicateTypeStore: PredicateTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable logicalOperator: LogicalOperator;
    @observable predicateStores = observable.array<PredicateEditorStore>();
    @observable isAddMenuOpen: boolean;

    // ------------------------ Constructor -------------------------

    constructor(type: PredicateType,
                context: PredicateContext,
                specification: PredicateSpecification,
                logicalOperator: LogicalOperator,
                predicateStores: PredicateEditorStore[])
    {
        super(type, context, specification);

        this.logicalOperator = logicalOperator;
        this.predicateStores.push(...predicateStores);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    toggleLogicalOperator()
    {
        if (this.logicalOperator === LogicalOperator.And)
        {
            this.logicalOperator = LogicalOperator.Or;
        }
        else
        {
            this.logicalOperator = LogicalOperator.And;
        }
    }

    @action.bound
    deletePredicate(predicateStore: PredicateEditorStore)
    {
        this.predicateStores.remove(predicateStore);
    }

    @action.bound
    openAddMenu()
    {
        this.isAddMenuOpen = true;
    }

    @action.bound
    closeAddMenu()
    {
        this.isAddMenuOpen = false;
    }

    @action.bound
    addPredicate(type: PredicateType)
    {
        this.predicateStores.push(
            PredicateEditorStore.construct(
                this.context,
                { type: type.id() },
                this.predicateTypeStore));
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
