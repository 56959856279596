import React from 'react';
import getTypes from '../../../../../Type/Api/getTypes';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import getDefaultTemplate from './getDefaultTemplate';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import openDialog from '../../../../../../../../@Service/Navigation/Page/Hooks/openDialog';
import EntityEmailDialog from '../../../../../Email/EntityEmailDialog';
import performAction from '../../../../../../../../@Api/Entity/performAction';
import updateSubjectFromTemplate from '../../../../../Type/Bespoke/Activity/Email/updateSubjectFromTemplate';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import { EntityExpansionBuilder } from '../../../../../Selection/Builder/EntityExpansionBuilder';
import { getCommitFromEntity } from '../../../../../../../../@Api/Entity/Commit/commitEntity';
import getExpressionContextForTemplate from '../../../../../Viewer/Content/Template/Api/getExpressionContextForTemplate';
import { constructEntityOfType } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { setValueByFieldInEntity } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { updateRelationship } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { ExpressionContext } from '../../../../../../Expression/ExpressionContext';
import { CommitContextImpl } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContextImpl';
import FunctionContext from '../../../../../../../../@Api/Automation/Function/FunctionContext';
import Computation from '../../../../../../../../@Api/Automation/Function/Computation/Computation';
import { getEmailSubjectComputationFromDescriptor } from '../../../../../Type/Bespoke/Activity/Email/getEmailSubjectComputationFromDescriptor';
import { DisposableValue } from '../../../../../../../../@Util/Disposable/DisposableValue';

export default async function sendDocument(document: Entity,
                                           emailTemplateType: EntityType,
                                           actionCode: string,
                                           parameters: any = {},
                                           parentOfDocument?: Entity,
                                           entityByParameterId?: Map<string, Entity>,
                                           titlePostfix?: string,
                                           emailDialogMessage?: string
)
{
    const { value: template, dispose: disposeTemplate } =
        await getEmailTemplate(
            document,
            emailTemplateType
        );
    const types = getTypes();

    // Create email
    const commitContext = new CommitContextImpl();
    const email =
        constructEntityOfType(
            types.Activity.Email.Type,
            commitContext
        );

    // Add email to document
    updateRelationship(
        email,
        true,
        types.Activity.RelationshipDefinition.LinkedActivities,
        document,
        commitContext
    );

    const ownerOfDocument = parentOfDocument;

    if (ownerOfDocument)
    {
        // Add document to owner of document as well (e.g. the sales opportunity)
        updateRelationship(
            email,
            true,
            types.Activity.RelationshipDefinition.LinkedActivities,
            ownerOfDocument,
            commitContext
        );
    }

    // Set subject
    if (template)
    {
        await updateSubjectFromTemplate(
            email,
            template,
            entityByParameterId,
            commitContext
        );
    }
    else
    {
        setValueByFieldInEntity(
            email,
            types.Activity.Field.Subject,
            document.getName(commitContext),
            commitContext
        );
    }

    if (!email.hasValueForField(types.Activity.Field.Subject, commitContext))
    {
        setValueByFieldInEntity(
            email,
            types.Activity.Field.Subject,
            '-',
            commitContext
        );
    }

    setValueByFieldInEntity(
        email,
        types.Activity.Email.Field.IsDraft,
        true,
        commitContext
    );

    // Resolve recipient
    const relationship =
        document.getRelatedEntityByDefinition(
            true,
            types.Relationship.RelationshipDefinition.Activities,
            commitContext
        );

    const organization =
        relationship?.getRelatedEntityByDefinition(
            false,
            types.Relationship.Organization.RelationshipDefinition.Organization,
            commitContext
        );

    const contact =
        document.getRelatedEntityByDefinition(
            true,
            types.Relationship.Person.Contact.RelationshipDefinition.Activities,
            commitContext
        );

    let recipientEntity: Entity;
    let ccRecipientEntity: Entity;

    if (document.entityType.isA(types.Activity.Invoice.Type))
    {
        // In case of invoice
        const parentActivity =
            document.getRelatedEntityByDefinition(
                true,
                types.Activity.RelationshipDefinition.LinkedActivities,
                commitContext
            );

        if (parentActivity?.entityType.isA(types.Activity.Subscription.Type)
            && parentActivity.hasValueForField(types.Activity.Subscription.Field.InvoiceEmailAddress, commitContext))
        {
            recipientEntity =
                types.Recipient.Email.Functions.RecipientFromEntity(
                    relationship,
                    parentActivity.getObjectValueByField(types.Activity.Subscription.Field.InvoiceEmailAddress, commitContext),
                    commitContext
                );
        }
        else if (organization?.hasValueForField(types.Relation.Organization.Field.FinancialEmailAddress, commitContext))
        {
            recipientEntity =
                types.Recipient.Email.Functions.RecipientFromEntity(
                    relationship,
                    organization.getObjectValueByField(types.Relation.Organization.Field.FinancialEmailAddress, commitContext),
                    commitContext
                );
        }
        else
        {
            recipientEntity =
                types.Recipient.Email.Functions.RecipientFromEntity(relationship, undefined, commitContext)
                    || types.Recipient.Email.Functions.RecipientFromEntity(contact, undefined, commitContext);
        }

        if(parentActivity?.entityType.isA(types.Activity.Subscription.Type)
           && parentActivity.hasValueForField(types.Activity.Subscription.Field.InvoiceCcEmailAddress, commitContext))
        {
            ccRecipientEntity =
                types.Recipient.Email.Functions.RecipientFromEntity(
                    relationship,
                    parentActivity.getObjectValueByField(
                        types.Activity.Subscription.Field.InvoiceCcEmailAddress,
                        commitContext
                    ),
                    commitContext
                );
        }
    }
    else
    {
        // In case of non-invoice
        recipientEntity =
            types.Recipient.Email.Functions.RecipientFromEntity(contact, undefined, commitContext)
                || types.Recipient.Email.Functions.RecipientFromEntity(relationship, undefined, commitContext);
    }

    if (recipientEntity)
    {
        updateRelationship(
            email,
            false,
            types.Activity.Email.RelationshipDefinition.To,
            recipientEntity,
            commitContext
        );
    }

    if (ccRecipientEntity)
    {
        updateRelationship(
            email,
            false,
            types.Activity.Email.RelationshipDefinition.CC,
            ccRecipientEntity,
            commitContext
        );
    }

    const label = 
        localizeText(
            'Generic.SendResource',
            '${resource} versturen',
            {
                resource: document.entityType.getName()
            }
        );

    const dialogLabel = label + (titlePostfix ? titlePostfix : '');

    let expressionContext: ExpressionContext | undefined;
    let templateContext: FunctionContext | undefined;
    let subjectTemplate: Computation<any, any> | undefined;

    if (template)
    {
        expressionContext =
            getExpressionContextForTemplate(
                email.entityType,
                template.entityType,
                types.Template.Email.Field.SubjectTemplate,
                email,
                entityByParameterId,
                commitContext
            );
        templateContext = expressionContext.entityContext.toFunctionContext();
        const subjectTemplateDescriptor =
            template.getObjectValueByField(
                types.Template.Email.Field.SubjectTemplate,
                commitContext
            );
        subjectTemplate =
            subjectTemplateDescriptor
            ? await getEmailSubjectComputationFromDescriptor(
                subjectTemplateDescriptor,
                templateContext
            )
            : undefined;
    }

    const disposeDialog =
        () =>
        {
            commitContext.dispose();
            disposeTemplate();
        };

    openDialog(
        onClose =>
            <EntityEmailDialog
                email={email}
                commitContext={commitContext}
                close={
                    () =>
                    {
                        onClose();
                        disposeDialog();
                    }
                }
                title={dialogLabel}
                sendHandler={
                    async (email, subject) =>
                    {
                        const commit = getCommitFromEntity(email, commitContext);

                        return performAction(
                            document,
                            {
                                code: actionCode,
                                name: label,
                                parameters: {
                                    email: commit.descriptor,
                                    emailSubject: subject?.toDescriptor(),
                                    ...parameters
                                },
                                files: commit.files,
                                commitId: commit.id
                            })
                            .then(
                                () =>
                                {
                                    onClose();
                                    disposeDialog();
                                }
                            );
                    }
                }
                template={template}
                templateType={emailTemplateType}
                expressionContext={expressionContext}
                subjectTemplate={subjectTemplate}
                templateContext={templateContext}
                disableDraftSave={true}
                emailDialogMessage={emailDialogMessage}
            />
    );
}

async function getEmailTemplate(
    entity: Entity,
    emailTemplateType: EntityType
): Promise<DisposableValue<Entity | undefined>>
{
    const types = getTypes();

    if (entity.entityType.isA(types.Activity.Offer.Type)
        && emailTemplateType.isA(types.Template.Email.Offer.Type)
    )
    {
        return getOfferTemplate(
            entity,
            emailTemplateType
        );
    }
    else if (entity.entityType.isA(types.Activity.WorkOrder.Type)
        && emailTemplateType.isA(types.Template.Email.WorkOrder.Type)
    )
    {
        return getWorkOrderTemplate(
            entity,
            emailTemplateType
        );
    }
    else if (entity.entityType.isA(types.Activity.Invoice.Type)
        && emailTemplateType.isA(types.Template.Email.Invoice.Type)
    )
    {
        return getInvoiceTemplate(
            entity,
            emailTemplateType
        );
    }
    else
    {
        return getDefaultTemplate(emailTemplateType);
    }
}

async function getOfferTemplate(
    entity: Entity,
    emailTemplateType: EntityType
): Promise<DisposableValue<Entity | undefined>>
{
    const types = getTypes();

    const pathToOfferEmailTemplate =
        EntityPath.fromEntity(entity)
            .joinTo(
                types.Activity.Offer.RelationshipDefinition.Template,
                false
            )
            .castTo(types.Template.Document.Offer.Type)
            .joinTo(
                types.Template.Document.Offer.RelationshipDefinition.DefaultEmailTemplate,
                false
            );

    return getDocumentEmailTemplate(
        entity,
        emailTemplateType,
        pathToOfferEmailTemplate,
        true
    );
}

async function getWorkOrderTemplate(
    entity: Entity,
    emailTemplateType: EntityType
): Promise<DisposableValue<Entity | undefined>>
{
    const types = getTypes();

    const pathToWorkOrderEmailTemplate =
        EntityPath.fromEntity(entity)
            .joinTo(
                types.Activity.WorkOrder.RelationshipDefinition.Template,
                false
            )
            .castTo(types.Template.Document.WorkOrder.Type)
            .joinTo(
                types.Template.Document.WorkOrder.RelationshipDefinition.DefaultEmailTemplate,
                false
            );

    return getDocumentEmailTemplate(
        entity,
        emailTemplateType,
        pathToWorkOrderEmailTemplate,
        true
    );
}

async function getInvoiceTemplate(
    entity: Entity,
    emailTemplateType: EntityType
): Promise<DisposableValue<Entity | undefined>>
{
    const types = getTypes();

    const subscriptionInvoiceEmailTemplate =
        types.Activity.Subscription.Type
            ? await getSubscriptionInvoiceEmailTemplate(entity, emailTemplateType)
            : undefined;

    if (subscriptionInvoiceEmailTemplate?.value === undefined)
    {
        const pathToInvoiceEmailTemplate =
            EntityPath.fromEntity(entity)
                .joinTo(
                    types.Activity.Invoice.RelationshipDefinition.Template,
                    false
                )
                .castTo(types.Template.Document.Invoice.Type)
                .joinTo(
                    types.Template.Document.Invoice.RelationshipDefinition.DefaultEmailTemplate,
                    false
                );

        const invoiceEmailTemplate = await getDocumentEmailTemplate(
            entity,
            emailTemplateType,
            pathToInvoiceEmailTemplate,
            true
        );

        const dispose =
            () =>
            {
                subscriptionInvoiceEmailTemplate?.dispose();
                invoiceEmailTemplate?.dispose();
            };

        return {
            value: invoiceEmailTemplate?.value,
            dispose,
        };
    }
    else
    {
        return subscriptionInvoiceEmailTemplate;
    }
}

async function getSubscriptionInvoiceEmailTemplate(
    entity: Entity,
    emailTemplateType: EntityType
): Promise<DisposableValue<Entity | undefined>>
{
    const types = getTypes();

    const pathToSubscriptionEmailTemplate =
        EntityPath.fromEntity(entity)
            .joinTo(
                types.Activity.RelationshipDefinition.LinkedActivities,
                true)
            .castTo(types.Activity.Subscription.Type)
            .joinTo(
                types.Activity.Subscription.RelationshipDefinition.InvoiceEmailTemplate,
                false);

    return getDocumentEmailTemplate(
        entity,
        emailTemplateType,
        pathToSubscriptionEmailTemplate,
        false
    );
}


async function getDocumentEmailTemplate(
    entity: Entity,
    emailTemplateType: EntityType,
    pathToDocumentEmailTemplate: EntityPath,
    useDefault: boolean
): Promise<DisposableValue<Entity | undefined>>
{
    const types = getTypes();

    const expansionBuilder =
        new EntityExpansionBuilder(
            entity.entityType,
            [
                entity
            ],
            [
                pathToDocumentEmailTemplate,
                pathToDocumentEmailTemplate.joinTo(
                    types.Template.Email.RelationshipDefinition.DefaultEmailSender,
                    false
                )
            ]
        );

    await expansionBuilder.expand();
    const documentEmailTemplate = pathToDocumentEmailTemplate.traverseEntity(entity).find(() => true);
    const dispose =
        () =>
            expansionBuilder.dispose();

    if (documentEmailTemplate)
    {
        return {
            value: documentEmailTemplate,
            dispose,
        };
    }
    else if (useDefault)
    {
        const {
            value: defaultTemplate,
            dispose: defaultDispose
        } = await getDefaultTemplate(emailTemplateType);

        return {
            value: defaultTemplate,
            dispose:
                () =>
                {
                    defaultDispose();
                    dispose();
                }
        };
    }
    else
    {
        return {
            value: undefined,
            dispose,
        };
    }
}
