import React, { useEffect, useMemo, useState } from 'react';
import styles from './TabBar.module.scss';
import TabBarContext from './TabBarContext';
import { classNames } from '../../../Util/Class/classNames';
import useValue from './Api/useValue';
import Centered from '../Centered/Centered';
import useIsMobile from '../../../../@Util/Responsiveness/useIsMobile';
import debounce from 'lodash-es/debounce';
import { TabsProps } from '@material-ui/core/Tabs/Tabs';
import { TabBarProps } from './TabBar';

export interface TabBarContainerProps extends TabBarProps
{
    renderTabs: (tabsProps: TabsProps) => React.ReactNode;
}

const TabBarContainer: React.FC<TabBarContainerProps> =
    props =>
    {
        const isMobile = useIsMobile();
        const [ rootDiv, setRootDiv ] = useState<HTMLDivElement | null>(null);
        const [ rootDivWidth, setRootDivWidth ] = useState<number | undefined>(undefined);
        const [ innerDivWidth, setInnerDivWidth ] = useState<number | undefined>(undefined);

        const isCentered =
            useMemo(
                ()  =>
                {
                    // Otherwise the tabs appear out of screen
                    if (innerDivWidth > rootDivWidth)
                    {
                        return false;
                    }
                    else
                    {
                        return props.centered && !isMobile;
                    }
                },
                [
                    innerDivWidth,
                    rootDivWidth,
                    props.centered,
                    isMobile,
                ]);

        const classes =
            useMemo(
                () => ({
                    ...props.classes,
                    root: classNames(styles.tabBar, props.classes && props.classes.tabBar),
                    flexContainer: classNames(styles.flexContainer, isCentered && styles.centered),
                    scrollButtons: styles.scrollButtons
                }),
                [props.classes, isCentered]
            );

        useEffect(
            () =>
            {
                if (rootDiv)
                {
                    const innerItems = rootDiv.getElementsByClassName(classes.flexContainer);

                    if (innerItems.length > 0)
                    {
                        const innerDiv = innerItems.item(0) as HTMLDivElement;
                        const ResizeObserver: any = (window as any).ResizeObserver;

                        if (ResizeObserver)
                        {
                            const updateWidth =
                                debounce(
                                    () =>
                                    {
                                        setRootDivWidth(rootDiv.getBoundingClientRect().width);
                                        setInnerDivWidth(innerDiv.scrollWidth);
                                    },
                                    200
                                );
                            const observer = new ResizeObserver(updateWidth);
                            observer.observe(rootDiv);
                            observer.observe(innerDiv);

                            return () =>
                                observer.disconnect();
                        }
                    }
                }
                else
                {
                    setRootDivWidth(undefined);
                    setInnerDivWidth(undefined);
                }
            },
            [
                rootDiv,
                classes.flexContainer,
                setRootDivWidth,
                setInnerDivWidth,
            ]
        );

        const value = useValue(props.value);

        return <TabBarContext.Provider
            value={value}
        >
            <div
                className={classNames(styles.root, props.classes && props.classes.root, props.bordered && styles.bordered)}
            >
                <Centered
                    horizontal={isCentered}
                >
                    {
                        props.renderTabs({
                            ref: setRootDiv as any,
                            value,
                            orientation: props.orientation,
                            variant: props.scrollable ? 'scrollable' : props.fullWidth ? 'fullWidth' : 'standard',
                            scrollButtons: 'on',
                            textColor: 'primary',
                            indicatorColor: 'primary',
                            classes,
                            TabIndicatorProps: props.tabIndicatorProps,
                            // ScrollButtonComponent={(props) => {
                            //     return (
                            //         <a style={
                            //             { height: "10px", position: "absolute" }}>
                            //         </a>
                            //     )}},
                            ...props.tabsProps,
                        })
                    }
                </Centered>
                {
                    props.appendix &&
                        <div
                            className={classNames(styles.appendix)}
                        >
                            {props.appendix}
                        </div>
                }
            </div>
        </TabBarContext.Provider>;
    };

TabBarContainer.defaultProps = {
    orientation: 'horizontal',
    scrollable: true,
    bordered: true
};

export default TabBarContainer;
