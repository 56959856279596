import React from 'react';
import { CheckboxControl, PanelBody, PanelRow } from '@wordpress/components';
import { BlockEditProps } from '@wordpress/blocks';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface SalesLinesOptionsPanelProps
{
    props: BlockEditProps<{}>;
    open?: boolean;
}

const ProductLinesOptions: React.FC<SalesLinesOptionsPanelProps> =
    props =>
    {
        const {
            splitProductGroups,
            hideSubTotals,
            hideTotals,
            hideUnitPrice,
            hideUnitVat,
            hideTotalInclVat,
            hidePrices,
            hideQuantity,
            showArticleNumbers,
            showUnit
        } = props.props.attributes as any;

        return <PanelBody
            title={localizeText('Gutenberg.ProductLinesOptions.Title', 'Verkoopregels')}
            initialOpen={!!props.open}
        >
            <PanelRow>
                <CheckboxControl
                    name="splitProductGroups"
                    label={
                        <LocalizedText
                            code="Gutenberg.ProductLinesOptions.SplitProductGroups"
                            value="Productgroepen splitsen"
                        />
                    }
                    checked={splitProductGroups}
                    onChange={splitProductGroups => props.props.setAttributes({ splitProductGroups: splitProductGroups })}
                />
            </PanelRow>
            <PanelRow>
                <CheckboxControl
                    name="hideQuantity"
                    label={
                        <LocalizedText
                            code="Gutenberg.ProductLinesOptions.HideQuantity"
                            value="Aantallen verbergen"
                        />
                    }
                    checked={hideQuantity}
                    onChange={hideQuantity => props.props.setAttributes({ hideQuantity: hideQuantity })}
                />
            </PanelRow>
            <PanelRow>
                <CheckboxControl
                    name="hideSubTotals"
                    label={
                        <LocalizedText
                            code="Gutenberg.ProductLinesOptions.HideSubTotals"
                            value="Subtotalen verbergen"
                        />
                    }
                    checked={hideSubTotals}
                    onChange={hideSubTotals => props.props.setAttributes({ hideSubTotals: hideSubTotals })}
                />
            </PanelRow>
            <PanelRow>
                <CheckboxControl
                    name="hideTotals"
                    label={
                        <LocalizedText
                            code="Gutenberg.ProductLinesOptions.HideTotals"
                            value="Totalen verbergen"
                        />
                    }
                    checked={hideTotals}
                    onChange={hideTotals => props.props.setAttributes({ hideTotals: hideTotals })}
                />
            </PanelRow>
            <PanelRow>
                <CheckboxControl
                    name="hideUnitPrice"
                    label={
                        <LocalizedText
                            code="Gutenberg.ProductLinesOptions.HideUnitPrice"
                            value="Stukprijzen verbergen"
                        />
                    }
                    checked={hideUnitPrice }
                    onChange={hideUnitPrice => props.props.setAttributes({ hideUnitPrice: hideUnitPrice })}
                />
            </PanelRow>
            <PanelRow>
                <CheckboxControl
                    name="hideUnitVat"
                    label={
                        <LocalizedText
                            code="Gutenberg.ProductLinesOptions.HideUnitVat"
                            value="BTW per regel verbergen"
                        />
                    }
                    checked={hideUnitVat}
                    onChange={hideUnitVat => props.props.setAttributes({ hideUnitVat: hideUnitVat })}
                />
            </PanelRow>
            <PanelRow>
                <CheckboxControl
                    name="hideTotalInclVat"
                    label={
                        <LocalizedText
                            code="Gutenberg.ProductLinesOptions.HideTotalInclVat"
                            value="Totalen alleen ex. BTW"
                        />
                    }
                    checked={hideTotalInclVat}
                    onChange={hideTotalInclVat => props.props.setAttributes({ hideTotalInclVat: hideTotalInclVat })}
                />
            </PanelRow>
            <PanelRow>
                <CheckboxControl
                    name="hidePrices"
                    label={
                        <LocalizedText
                            code="Gutenberg.ProductLinesOptions.HidePrices"
                            value="Alle prijzen verbergen"
                        />
                    }
                    checked={hidePrices}
                    onChange={hidePrices => props.props.setAttributes({ hidePrices: hidePrices })}
                />
            </PanelRow>
            <PanelRow>
                <CheckboxControl
                    name="showArticleNumbers"
                    label={
                        <LocalizedText
                            code="Gutenberg.ProductLinesOptions.ShowArticleNumbers"
                            value="Artikelnummers weergeven"
                        />
                    }
                    checked={showArticleNumbers }
                    onChange={showArticleNumbers => props.props.setAttributes({ showArticleNumbers: showArticleNumbers })}
                />
            </PanelRow>
            <PanelRow>
                <CheckboxControl
                    name="showUnit"
                    label={
                        <LocalizedText
                            code="Gutenberg.ProductLinesOptions.ShowUnit"
                            value="Eenheid weergeven"
                        />
                    }
                    checked={showUnit }
                    onChange={showUnit => props.props.setAttributes({ showUnit: showUnit })}
                />
            </PanelRow>
        </PanelBody>;
    };

export default ProductLinesOptions;
