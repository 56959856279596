import Action from '../../../../../@Api/Automation/Function/Action/Action';
import CompositeAction from '../../../../../@Api/Automation/Function/Action/CompositeAction';
import CompositeActionInvocation from '../../../../../@Api/Automation/Function/Action/CompositeActionInvocation';
import uuid from '../../../../../@Util/Id/uuid';

export function wrapAssistantActionInCompositeIfNeeded(
    action: Action<any, any>
): CompositeAction
{
    if (action instanceof CompositeAction)
    {
        return action;
    }
    else
    {
        return new CompositeAction(
            [
                new CompositeActionInvocation(
                    uuid(),
                    action
                ),
            ]
        );
    }
}
