import { Attributes, FunctionComponent } from 'react';
import { ViewComponent } from './ViewComponent';

export class ReactViewComponent<P extends {}> extends ViewComponent<any, any>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(type: FunctionComponent<P>,
                props?: Attributes & P | undefined)
    {
        super(type, undefined, undefined, props);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
