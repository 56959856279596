import React from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import useAggregateResult from '../../../../../Selection/Hooks/useAggregateResult';
import { Aggregate } from '../../../../../../DataObject/Model/Aggregate';
import { getDistanceLabel } from '../MileageSheet/Api/getDistanceLabel';
import { observer } from 'mobx-react';

export interface MileageRegistrationDistanceProps
{
    entity: Entity
    milestone?: Entity
    isEmployee?: boolean
}

const MileageRegistrationDistance: React.FC<MileageRegistrationDistanceProps> =
    ({
        entity,
        milestone,
        isEmployee
    }) =>
        {
            const types = useTypes();

            const mileageRegistrationDistance =
                useAggregateResult(
                    types.MileageRegistration.Type,
                    (builder, rootPath) =>
                        builder
                            .if(
                                () =>
                                    isEmployee === undefined,
                                sb =>
                                    sb.where(
                                        cb =>
                                            cb.relatedToEntity(
                                                rootPath
                                                    .joinTo(
                                                        types.Activity.RelationshipDefinition.MileageRegistrations,
                                                        true
                                                    ),
                                                entity
                                            )
                                    )
                            )
                            .if(
                                () =>
                                    milestone !== undefined,
                                sb =>
                                    sb.where(
                                        cb =>
                                            cb.relatedToEntity(
                                                rootPath
                                                    .joinTo(
                                                        types.Milestone.RelationshipDefinition.MileageRegistrations,
                                                        true
                                                    ),
                                                milestone
                                            )
                                    )
                            )
                            .if(
                                () =>
                                    isEmployee !== undefined && isEmployee,
                                sb =>
                                    sb.where(
                                        cb =>
                                            cb.relatedToEntity(
                                                rootPath
                                                    .joinTo(
                                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.MileageRegistrations,
                                                        true
                                                    ),
                                                entity
                                            )
                                    )
                            )
                            .if(
                                () =>
                                    isEmployee !== undefined && !isEmployee,
                                sb =>
                                    sb.where(
                                        cb =>
                                            cb.relatedToEntity(
                                                rootPath
                                                    .joinTo(
                                                        types.Relationship.RelationshipDefinition.MileageRegistrations,

                                                        true
                                                    ),
                                                entity
                                            )
                                    )
                            )
                            .aggregateOn(
                                rootPath.field(types.MileageRegistration.Field.Distance),
                                undefined,
                                Aggregate.Sum
                            ),
                    [
                        types,
                        entity,
                        isEmployee,
                        milestone
                    ]
                );

            if(mileageRegistrationDistance && mileageRegistrationDistance.aggregates[0].value > 0)
            {
                return <>
                    {
                        getDistanceLabel(mileageRegistrationDistance.aggregates[0].value)
                    }
                </>
            }
            else
            {
                return null
            }
        }

export default observer(MileageRegistrationDistance);
