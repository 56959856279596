import React, { useMemo } from 'react';
import useTypes from '../../../../../Type/Api/useTypes';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import getViewParameters from '../../../../../View/Api/getViewParameters';
import CompositePredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../../../DataObject/Model/LogicalOperator';
import ComparisonPredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../../../../View/Model/ViewParams';
import { Comparator } from '../../../../../../DataObject/Model/Comparator';
import EntityValue from '../../../../../../../../@Api/Automation/Value/EntityValue';
import PrimitiveValue from '../../../../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../../../../DataObject/Model/DataObject';
import Specification from '../../../../../View/Model/Specification';
import Column from '../../../../../View/Model/Specification/Column';
import uuid from 'uuid';
import CompositeLayout from '../../../../../../../../@Api/Layout/Type/CompositeLayout';
import CompositeLayoutItem from '../../../../../../../../@Api/Layout/Type/CompositeLayoutItem';
import ConditionalLayout from '../../../../../../../../@Api/Layout/Type/ConditionalLayout';
import InstanceOfPredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/InstanceOfPredicate';
import EntityValueType from '../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import ComputationLayout from '../../../../../../../../@Api/Layout/Type/ComputationLayout';
import List from '../../../../../View/List/List';

import { default as ViewModel } from '../../../../../View/Model/View';
import { default as ListModel } from '../../../../../View/Model/Specification/List';

export interface ApsisDeliveredListProps
{
    apsisCampaign: Entity;
    onItemClicked: (entity: Entity) => void;
}

export const ApsisDeliveredList: React.FC<ApsisDeliveredListProps> =
    (
        {
            apsisCampaign,
            onItemClicked
        }
    ) =>
    {
        const types = useTypes();

        const emailAddressFieldConditionals =
            useMemo(
                () =>
                [
                    {
                        entityType: types.Relationship.Person.Contact.Type,
                        fieldPath:
                            EntityPath
                                .fromEntityType(types.Activity.ApsisCampaignResult.Type)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.Relationship,
                                    true
                                )
                                .field(types.Relationship.Person.Contact.Field.EmailAddress),
                    },
                    {
                        entityType: types.Relationship.Person.Type,
                        fieldPath:
                            EntityPath
                                .fromEntityType(types.Activity.ApsisCampaignResult.Type)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.Relationship,
                                    true
                                )
                                .joinTo(
                                    types.Relationship.Person.RelationshipDefinition.Person,
                                    false)
                                .field(types.Relation.Person.Field.EmailAddress),
                    }
                ],
                [
                    types
                ]
            );
        const phoneNumberConditionals =
            useMemo(
                () =>
                    [
                        {
                            entityType: types.Relationship.Person.Contact.Type,
                            fieldPath:
                                EntityPath
                                    .fromEntityType(types.Activity.ApsisCampaignResult.Type)
                                    .joinTo(
                                        types.Activity.RelationshipDefinition.Relationship,
                                        true
                                    )
                                    .field(types.Relationship.Person.Contact.Field.MobilePhoneNumber),
                        },
                        {
                            entityType: types.Relationship.Person.Type,
                            fieldPath:
                                EntityPath
                                    .fromEntityType(types.Activity.ApsisCampaignResult.Type)
                                    .joinTo(
                                        types.Activity.RelationshipDefinition.Relationship,
                                        true
                                    )
                                    .joinTo(
                                        types.Relationship.Person.RelationshipDefinition.Person,
                                        false)
                                    .field(types.Relation.Person.Field.MobilePhoneNumber),
                        }
                    ],
                [
                    types
                ]
            );

        const viewDelivered =
            useMemo(
                () =>
                {
                    const viewParameters =
                        getViewParameters(
                            types.Activity.ApsisCampaignResult.Type
                        );

                    return new ViewModel(
                        'List',
                        undefined,
                        types.Activity.ApsisCampaignResult.Type,
                        viewParameters,
                        new CompositePredicate(
                            LogicalOperator.And,
                            [
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        viewParameters.getParameterById(ViewParams.Entity),
                                        EntityPath
                                            .fromEntityType(types.Activity.ApsisCampaignResult.Type)
                                            .joinTo(
                                                types.Activity.ApsisCampaign.RelationshipDefinition.Results,
                                                true
                                            )
                                            .field()
                                    ),
                                    Comparator.Equals,
                                    new EntityValue(apsisCampaign)
                                ),
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        viewParameters.getParameterById(ViewParams.Entity),
                                        EntityPath
                                            .fromEntityType(types.Activity.ApsisCampaignResult.Type)
                                            .field(types.Activity.ApsisCampaignResult.Field.IsBounced)
                                    ),
                                    Comparator.NotEquals,
                                    new PrimitiveValue(
                                        DataObject.constructFromTypeIdAndValue(
                                            'Boolean',
                                            true
                                        )
                                    )
                                )
                            ]
                        ),
                        new Specification(
                            new ListModel(
                                [
                                    new Column(
                                        uuid(),
                                        EntityPath
                                            .fromEntityType(types.Activity.ApsisCampaignResult.Type)
                                            .field(types.Activity.ApsisCampaignResult.Field.SendDate)
                                    ),
                                    new Column(
                                        uuid(),
                                        EntityPath
                                            .fromEntityType(types.Activity.ApsisCampaignResult.Type)
                                            .joinTo(types.Activity.RelationshipDefinition.Relationship, true)
                                            .field()
                                    ),
                                    new Column(
                                        uuid(),
                                        EntityPath
                                            .fromEntityType(types.Activity.ApsisCampaignResult.Type)
                                            .joinTo(
                                                types.Activity.RelationshipDefinition.Relationship,
                                                true
                                            )
                                            .field(types.Relationship.Person.Contact.Field.EmailAddress),
                                        false,
                                        true,
                                        undefined,
                                        new CompositeLayout(
                                            'Vertical',
                                            emailAddressFieldConditionals.map(
                                                conditional =>
                                                    new CompositeLayoutItem(
                                                        conditional.entityType.id.toString(),
                                                        new ConditionalLayout(
                                                            new InstanceOfPredicate(
                                                                new ValueFromEntityComputation(
                                                                    viewParameters.getParameterById(ViewParams.Entity),
                                                                    EntityPath.fromEntityType(types.Activity.ApsisCampaignResult.Type)
                                                                        .joinTo(
                                                                            types.Activity.RelationshipDefinition.Relationship,
                                                                            true
                                                                        )
                                                                        .field()
                                                                ),
                                                                new EntityValueType(conditional.entityType)
                                                            ),
                                                            new ComputationLayout(
                                                                new ValueFromEntityComputation(
                                                                    viewParameters.getParameterById(ViewParams.Entity),
                                                                    conditional.fieldPath
                                                                )
                                                            )
                                                        ),
                                                        1
                                                    )
                                            )
                                        )
                                    ),
                                    new Column(
                                        uuid(),
                                        EntityPath
                                            .fromEntityType(types.Activity.ApsisCampaignResult.Type)
                                            .joinTo(
                                                types.Activity.RelationshipDefinition.Relationship,
                                                true
                                            )
                                            .field(types.Relationship.Person.Contact.Field.MobilePhoneNumber),
                                        false,
                                        true,
                                        undefined,
                                        new CompositeLayout(
                                            'Vertical',
                                            phoneNumberConditionals.map(
                                                conditional =>
                                                    new CompositeLayoutItem(
                                                        conditional.entityType.id.toString(),
                                                        new ConditionalLayout(
                                                            new InstanceOfPredicate(
                                                                new ValueFromEntityComputation(
                                                                    viewParameters.getParameterById(ViewParams.Entity),
                                                                    EntityPath.fromEntityType(types.Activity.ApsisCampaignResult.Type)
                                                                        .joinTo(
                                                                            types.Activity.RelationshipDefinition.Relationship,
                                                                            true
                                                                        )
                                                                        .field()
                                                                ),
                                                                new EntityValueType(conditional.entityType)
                                                            ),
                                                            new ComputationLayout(
                                                                new ValueFromEntityComputation(
                                                                    viewParameters.getParameterById(ViewParams.Entity),
                                                                    conditional.fieldPath
                                                                )
                                                            )
                                                        ),
                                                        1
                                                    )
                                            )
                                        )
                                    )
                                ],
                                []
                            )
                        )
                    );
                },
                [
                    types,
                    apsisCampaign,
                    emailAddressFieldConditionals,
                    phoneNumberConditionals
                ]
            );

        return <List
            view={viewDelivered}
            onClick={onItemClicked}
            readonly
        />;
    };