import Role, { Privilege } from '../../../Model/Role';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';

export function resetRelationshipDefinitionPermissions(
    role: Role,
    privileges: Privilege[],
    entityType: EntityType
)
{
    [ true, false ]
        .forEach(
            isParent =>
                entityType.getRelationshipDefinitions(isParent)
                    .filter(
                        relationshipDefinition =>
                            role.rightByRelationshipType.has(relationshipDefinition))
                    .forEach(
                        relationshipDefinition =>
                        {
                            const relationshipDefinitionRight = role.rightByRelationshipType.get(relationshipDefinition);

                            privileges.forEach(
                                privilege =>
                                    relationshipDefinitionRight.setPermission(privilege, 'Inherited')
                            );

                            role.rightByRelationshipType.set(relationshipDefinition, relationshipDefinitionRight);
                        })
        );
}