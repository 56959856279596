import { observable } from 'mobx';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Action from './Action';
import EmptyValueType from '../../Value/Type/EmptyValueType';
import EmptyValue from '../../Value/EmptyValue';
import Computation from '../Computation/Computation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import EntityValueType from '../../Value/Type/EntityValueType';
import Parameter from '../../Parameter/Parameter';
import { EntityFieldPath } from '../../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import Validation from '../../Validation/Validation';
import localizeText from '../../../Localization/localizeText';

export default class UpdateEntityAction extends Action<EmptyValueType, EmptyValue>
{
    // ------------------------- Properties -------------------------

    @observable.ref parameter: Parameter<EntityValueType>;
    @observable.ref fieldPath: EntityFieldPath;
    @observable.ref value: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(parameter: Parameter<EntityValueType>,
                fieldPath: EntityFieldPath,
                value: Computation<any, any>)
    {
        super();

        this.parameter = parameter;
        this.fieldPath = fieldPath;
        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return EmptyValueType.instance;
    }

    getName()
    {
        if (this.parameter)
        {
            return localizeText(
                'UpdateEntityName',
                'Muteren van'
            ) + ` ${this.parameter.name}`;
        }
        else
        {
            return localizeText(
                'Action.UpdateEntity',
                'Record muteren'
            );
        }
    }

    validate(): Validation[]
    {
        const validations: Validation[] = [];

        if (!this.parameter
            || !this.fieldPath)
        {
            validations.push(
                new Validation(
                    'Error',
                    localizeText(
                        'NoEntityFieldSelected',
                        'Je hebt nog geen veld gekozen om te muteren.'
                    )
                )
            );
        }

        if (this.value)
        {
            validations.push(
                ...this.value.validate());
        }
        else
        {
            validations.push(
                new Validation(
                    'Error',
                    localizeText(
                        'NoEntityFieldValueSelected',
                        'Je hebt nog geen waarde gekozen om het veld mee te muteren.'
                    )));
        }

        return validations;
    }

    isAsync(): boolean
    {
        return this.value.isAsync();
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'UpdateEntity';
        descriptor.parameterId = this.parameter.id;
        descriptor.fieldPath = this.fieldPath.descriptor;
        descriptor.value = this.value.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            new Dependency(
                this.parameter,
                this.fieldPath),
            ...this.value.getDependencies()
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new UpdateEntityAction(
            dependencyContext.parameterDictionary.getParameterById(descriptor.parameterId),
            EntityFieldPath.construct(descriptor.fieldPath),
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
