import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import NumberEditor, { NumberEditorProps } from '../NumberEditor';
import { SymbolPosition } from '../../../../../../@Api/Localization/Currency';
import LocalizerContext from '../../../../../../@Service/Localization/LocalizerContext';

export interface CurrencyEditorProps extends NumberEditorProps
{
    currencyCode?: string;
}

const CurrencyEditor: React.FC<CurrencyEditorProps> =
    props =>
    {
        const localizer = useContext(LocalizerContext);

        const currency =
            useMemo(
                () =>
                    props.currencyCode && localizer.currenciesByCode.has(props.currencyCode)
                        ? localizer.currenciesByCode.get(props.currencyCode)
                        : localizer.baseCurrency,
                [
                    props.currencyCode,
                    localizer.baseCurrency
                ]);

        return <NumberEditor
            {...props}
            prefix={currency.symbolPosition  === SymbolPosition.Prefix ? currency.symbol : undefined}
            suffix={currency.symbolPosition === SymbolPosition.Suffix ? currency.symbol : undefined}
            decimalScale={currency.precision}
            fixedDecimalScale
        />;
    };

export default observer(CurrencyEditor);