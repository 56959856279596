import { EntityTypeStore } from '../../../EntityTypeStore';
import { BespokeEntityType } from '../../../BespokeEntityType';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { injectWithQualifier, loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/index';
import { RouterStore } from '../../../../../../../@Service/Router/RouterStore';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { SideEffectProps, SideEffectStore } from '../../../../SideEffect/SideEffectStore';
import { CustomerUpgradeSideEffectStore } from './SideEffect/CustomerUpgradeSideEffectStore';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import { observable } from 'mobx';
import { CurrentUserStore } from '../../../../../User/CurrentUserStore';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { updateRelationship } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import { EntityField } from '../../../../../../../@Api/Model/Implementation/EntityField';
import { DataObjectRepresentationProps } from '../../../../../DataObject/Model/DataObjectRepresentation';

export class BespokeActivitySalesOpportunity extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('RouterStore') routerStore: RouterStore;

    // ------------------------- Properties -------------------------

    @observable entity: Entity;

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore, entity?: Entity)
    {
        super(entityTypeStore, 'Activity.SalesOpportunity');
        this.entity = entity;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    onConstruct(entity: Entity,
                commitContext?: CommitContext): void
    {
        super.onConstruct(entity, commitContext);

        if (!entity.hasValueForField(this.entityTypeStore.bespoke.types.Activity.SalesOpportunity.Field.ExpectedOrderDate, commitContext))
        {
            setValueByFieldInEntity(
                entity,
                this.entityTypeStore.bespoke.types.Activity.SalesOpportunity.Field.ExpectedOrderDate,
                new Date(),
                commitContext
            );
        }

        if (!entity.hasRelationshipsByDefinition(
            false,
            this.entityTypeStore.bespoke.types.Activity.SalesOpportunity.RelationshipDefinition.SalesRepresentative,
            commitContext))
        {
            updateRelationship(
                entity,
                false,
                this.entityTypeStore.bespoke.types.Activity.SalesOpportunity.RelationshipDefinition.SalesRepresentative,
                loadModuleDirectly(CurrentUserStore).employeeEntity,
                commitContext
            );
        }
    }

    getSideEffects(props: SideEffectProps): SideEffectStore[]
    {
        return [
            ...super.getSideEffects(props),
            // new ProjectCreationSideEffectStore(props),
            new CustomerUpgradeSideEffectStore(props)
        ];
    }

    isAttentionButtonVisible(entity: Entity): boolean
    {
        return this.isInClosedState(entity) === false
            && entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Activity.Field.NumberOfPlannedActions) === 0;
    }

    getAttentionButtonCaption(entity: Entity): string
    {
        return localizeText(
            'SalesOpportunityWithoutOpenActions',
            `Verkoopkans heeft geen openstaande acties.\nNieuwe actie aanmaken.`
        )
    }

    addEntityPathForAttentionButtonClick(entity: Entity): EntityPath
    {
        return EntityPath.fromEntity(entity)
            .joinTo(
                this.entityTypeStore.bespoke.types.Activity.RelationshipDefinition.LinkedActivities,
                false)
            .castTo(this.entityTypeStore.bespoke.types.Activity.Task.Type);
    }

    getHeaderFieldPaths(entity: Entity): EntityFieldPath[]
    {
        if (entity.isNew())
        {
            return super.getHeaderFieldPaths(entity);
        }
        else
        {
            const hasCurrency = entity.hasValueForField(this.entityTypeStore.bespoke.types.Activity.Field.Currency);
            return [
                ...super.getHeaderFieldPaths(entity),
                EntityPath.fromEntity(entity)
                    .field(this.entityTypeStore.bespoke.types.Activity.SalesOpportunity.Field.Probability),
                EntityPath.fromEntity(entity)
                    .field(
                        entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Activity.Field.NumberOfProductLines) > 0
                            ? (hasCurrency
                                ? this.entityTypeStore.bespoke.types.Activity.Field.AmountInCurrency
                                : this.entityTypeStore.bespoke.types.Activity.Field.Amount
                            )
                            : this.entityTypeStore.bespoke.types.Activity.SalesOpportunity.Field.EstimatedAmount
                    )
            ];
        }
    }

    getRepresentation(
        entity: Entity,
        field: EntityField
    ): DataObjectRepresentationProps
    {
        const representation =
            super.getRepresentation(
                entity,
                field
            );

        if (this.getCurrencyFields().includes(field))
        {
            return {
                ...representation,
                currency: entity.getObjectValueByField(this.types.Activity.Field.Currency),
            };
        }
        else
        {
            return representation;
        }
    }

    // ----------------------- Private logic ------------------------
}
