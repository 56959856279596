import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntitySelectionAggregateResult } from '../../../../../../Selection/Model/EntitySelectionAggregateResult';
import { EntityType } from '../../../../../../../../../@Api/Model/Implementation/EntityType';
import { default as SegmentModel } from '../../../../../Model/Segment';
import Chart from '../../../../../../Chart/Chart';
import { ChartDataPoint } from '../../../../../../Chart/Api/getChartData';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import FilterContext from '../../../../FilterContext/FilterContext';
import SegmentContext from '../../../../SegmentContext/SegmentContext';
import styles from '../../ChartSelector.module.scss';
import Segment from '../../../../Segment';
import combinePredicates from '../../../../Api/combinePredicates';
import DataSeries from '../../../../../../Chart/Model/DataSeries';
import { Comparator } from '../../../../../../../DataObject/Model/Comparator';
import useEntityTypeForFilter from '../../../../Api/useEntityTypeForFilter';
import useNextSegments from '../../../../Api/useNextSegments';
import { LogicalOperator } from '../../../../../../../DataObject/Model/LogicalOperator';
import ComputedSeries from '../../../../../../Chart/Model/ComputedSeries';
import useChildSelectionId from '../../../Api/useChildSelectionId';
import IdContext from '../../../../../Context/IdContext';
import DatasetContext from '../../../../../Context/DatasetContext';
import CompositePredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import ValueFromEntityComputation from '../../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import PrimitiveValue from '../../../../../../../../../@Api/Automation/Value/PrimitiveValue';
import ComparisonPredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';

export interface NewChartProps
{
    entityType: EntityType;
    segment: SegmentModel;
    nextSegments: SegmentModel[];
    result: EntitySelectionAggregateResult;
}

const NewChart: React.FC<NewChartProps> =
    props =>
    {
        const datasetId = useContext(IdContext);
        const dataset = useContext(DatasetContext);

        const segmentIdx =
            useMemo(
                () =>
                    dataset.segments.indexOf(props.segment),
                [
                    dataset,
                    props.segment
                ]);

        const segmentId =
            useMemo(
                () =>
                    `${datasetId}.${segmentIdx}`,
                [
                    datasetId,
                    segmentIdx
                ]);

        const parentFilter = useContext(FilterContext);
        const [ selectedPoint, _setSelectedPoint ] = useState<ChartDataPoint | undefined>();
        const [ selectedPointId, setSelectedPointId ] = useChildSelectionId(props.segment);

        const setSelectedPoint =
            useCallback(
                (point?: ChartDataPoint) =>
                {
                    _setSelectedPoint(point);

                    setSelectedPointId(point?.x.valueId)
                },
                [
                    _setSelectedPoint,
                    setSelectedPointId
                ]);

        const selectedChildFilter =
            useComputed(
                () =>
                {
                    const series: DataSeries[] = [];

                    if (selectedPoint)
                    {
                        selectedPoint.data
                            .forEach(
                                (value, key) =>
                                {
                                    if (key instanceof DataSeries)
                                    {
                                        series.push(key);
                                    }
                                    else if (key instanceof ComputedSeries)
                                    {
                                        key.components
                                            .filter(
                                                component =>
                                                    component.seriesId)
                                            .forEach(
                                                component =>
                                                    props.segment.chart.series
                                                        .filter(
                                                            series =>
                                                                series.id === component.seriesId
                                                                && series instanceof DataSeries)
                                                        .forEach(
                                                            dataSeries =>
                                                                series.push(dataSeries as DataSeries)));
                                    }
                                });
                    }

                    return combinePredicates(
                        parentFilter,
                        series.length > 0
                            ?
                                new CompositePredicate(
                                    LogicalOperator.Or,
                                    series.map(
                                        series =>
                                            combinePredicates(
                                                series.filter,
                                                new ComparisonPredicate(
                                                    new ValueFromEntityComputation(
                                                        dataset.parameter,
                                                        series.xFieldPath),
                                                    Comparator.In,
                                                    new PrimitiveValue(selectedPoint.x)))))
                            :
                                undefined);
                },
                [
                    parentFilter,
                    selectedPoint,
                    props.segment
                ]);

        const selectedChildType =
            useEntityTypeForFilter(
                props.entityType,
                selectedChildFilter);

        const onPointsLoaded =
            useCallback(
                (points: ChartDataPoint[]) =>
                {
                    if (selectedPointId)
                    {
                        setSelectedPoint(
                            points.find(
                                point =>
                                    point.x.valueId === selectedPointId));
                    }
                },
                [
                    selectedPointId,
                    setSelectedPoint
                ]);

        const [ nextSegment, nextNextSegments ] = useNextSegments(props.nextSegments);

        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            <ViewGroupItem>
                <Chart
                    id={segmentId}
                    chart={props.segment.chart}
                    onSelectPoint={setSelectedPoint}
                    selectedPoint={selectedPoint}
                    onPointsLoaded={onPointsLoaded}
                    filter={parentFilter}
                />
            </ViewGroupItem>
            {
                selectedPoint &&
                    <ViewGroupItem>
                        <FilterContext.Provider
                            value={selectedChildFilter}
                        >
                            <SegmentContext.Provider
                                value={props.segment}
                            >
                                <div
                                    className={styles.childSegment}
                                >
                                    <Segment
                                        entityType={selectedChildType}
                                        segment={nextSegment}
                                        nextSegments={nextNextSegments}
                                        result={props.result}
                                    />
                                </div>
                            </SegmentContext.Provider>
                        </FilterContext.Provider>
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(NewChart);
