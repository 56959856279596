import { enumerated, reference, type } from '../../../@Util/Serialization/Serialization';
import { Language } from './Language';
import { LanguageEntryTranslation } from './LanguageEntryTranslation';
import { observable } from 'mobx';
import { registerBuilder } from '../../../@Util/TransactionalModelV2/Shared/TransactionalBuilder';

export enum LanguageEntryType { UI, Data }

@type('LanguageEntry')
export class LanguageEntry
{
    // ------------------- Persistent Properties --------------------

    @observable id: number;
    @observable code: string;
    @observable @enumerated(LanguageEntryType, 'LanguageEntryType') type: LanguageEntryType;
    @observable comment: string;
    @reference(undefined, 'LanguageEntryTranslation') @observable.shallow translations: LanguageEntryTranslation[];

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isEmpty(): boolean
    {
        return !this.translations || this.translations.find(translation => (translation.value != null)) == null;
    }

    getTranslation(language: Language): LanguageEntryTranslation
    {
        if (this.translations)
        {
            return this.translations.find(translation =>
                (translation.language.id === language.id));
        }
        else
        {
            return undefined;
        }
    }

    updateTranslation(language: Language, value: string): LanguageEntryTranslation
    {
        if (!this.translations)
        {
            this.translations = [];
        }

        if (value && value.length > 0)
        {
            let updated = false;

            // Try to update value
            this.translations.forEach(
                translation =>
                {
                    if (translation.language.id === language.id)
                    {
                        translation.value = value;
                        updated = true;
                    }
                });

            // Add translation, it does not yet exist in translations
            if (!updated)
            {
                let entry: LanguageEntryTranslation = new LanguageEntryTranslation();
                entry.language = language;
                entry.value = value;

                this.translations.push(entry);
            }
        }
        else
        {
            // Remove translation
            for (let entry of this.translations)
            {
                if (entry.language.id === language.id)
                {
                    this.translations.splice(
                        this.translations.indexOf(entry),
                        1);

                    break;
                }
            }
        }

        return this.getTranslation(language);
    }

    descriptor(): any
    {
        return {
            translations: this.translations ? this.translations.map(translation => (translation.descriptor())) : []
        };
    }

    // ----------------------- Private logic ------------------------
}

registerBuilder(LanguageEntry)
    .includeAll()
    .deep('translations');
