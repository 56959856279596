import * as React from 'react';
import { DataObjectBespokeViewer } from '../../../Viewer/DataObjectBespokeViewer';
import { DataObjectViewerStore } from '../../../Viewer/DataObjectViewerStore';
import { observer } from 'mobx-react';
import { TextViewerStore } from './TextViewerStore';
import RichText from '../../../../../Generic/RichText/RichText';

@observer
export class TextView extends DataObjectBespokeViewer<TextViewerStore>
{
    renderComponent(store: DataObjectViewerStore<TextViewerStore>)
    {
        return <RichText
            value={store.dataObject.value}
        />;
    }
}
