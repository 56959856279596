import * as React from 'react';
import { DataObjectBespokeEditor } from '../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { observer } from 'mobx-react';
import { FileEditorStore } from './FileEditorStore';
import FileDropZone from '../../../../../@Future/Component/Generic/Input/FileDropZone/FileDropZone';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { FileValue } from './FileValue';
import DeleteIconButton from '../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import styles from './FileEditor.module.scss';
import Link from '../../../../../@Future/Component/Generic/Link/Link';
import { action } from 'mobx';
import EditIconButton from '../../../../../@Future/Component/Generic/Button/Variant/EditIconButton/EditIconButton';
import { primaryColor, textSecondaryColor } from '../../../../../@Resource/Theme/Theme';
import { downloadApiFile } from '../../../../../@Service/ApiClient/Hooks/downloadApiFile';

@observer
export class FileEditor extends DataObjectBespokeEditor<FileEditorStore>
{
    renderComponent(store: DataObjectEditorStore<FileEditorStore>)
    {
        const fileValue = store.dataObject.value as FileValue;

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            {
                fileValue &&
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                            alignment="center"
                        >
                            <ViewGroupItem>
                                <Link
                                    onClick={this.downloadFile}
                                >
                                    {fileValue.name}
                                </Link>
                            </ViewGroupItem>
                            <ViewGroupItem
                                className={styles.size}
                            >
                                ({fileValue.humanReadableFilesize})
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <EditIconButton
                                    onClick={store.bespokeStore.toggleEditMode}
                                    small
                                    color={store.bespokeStore.isInEditMode ? primaryColor : textSecondaryColor}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <DeleteIconButton
                                    onClick={store.bespokeStore.clear}
                                    small
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
            }
            {
                (!fileValue || store.bespokeStore.isInEditMode) &&
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="vertical"
                            spacing={15}
                        >
                            <ViewGroupItem>
                                <FileDropZone
                                    onDrop={store.bespokeStore.onUploaderSelected}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
            }
        </ViewGroup>;
    }

    @action.bound
    downloadFile()
    {
        const fileUrl = this.store.dataObject.context?.getFileUrl(undefined);

        if (fileUrl)
        {
            return downloadApiFile(fileUrl);
        }
    }
}
