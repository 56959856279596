import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import PortalSwitcherTabBarLayout from '../../../../../@Api/Layout/Type/PortalSwitcherTabBarLayout';
import PortalSwitcherTabBar from '../../../../App/Root/Environment/Portal/Header/PortalTabBar/PortalSwitcherTabBar';

export interface PortalSwitcherTabBarLayoutViewerProps extends LayoutViewerProps<PortalSwitcherTabBarLayout>
{

}

const PortalSwitcherTabBarLayoutViewer: React.FC<PortalSwitcherTabBarLayoutViewerProps> =
    props =>
    {
        return <PortalSwitcherTabBar />;
    };

export default observer(PortalSwitcherTabBarLayoutViewer);
