import { PortalTheme } from '../Theme/PortalTheme';
import { useEffect } from 'react';

export function usePortalThemeFavicon(
    portalTheme?: PortalTheme
)
{
    useEffect(
        () =>
        {
            if (portalTheme?.faviconUrl)
            {
                const disposers: (() => void)[] =
                    Array.from(document.querySelectorAll<HTMLAnchorElement>(`link[rel~='icon']`).values())
                        .map(
                            link =>
                            {
                                const oldHref = link.href;
                                link.href = portalTheme.faviconUrl;

                                return () =>
                                {
                                    link.href = oldHref;
                                }
                            }
                        );

                return () =>
                    disposers.forEach(
                        disposer =>
                            disposer()
                    );
            }
        },
        [
            portalTheme,
        ]
    );
}