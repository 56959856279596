import { PredicateEditorStore } from '../../PredicateEditorStore';
import { observable } from 'mobx';
import { PredicateContext } from '../../PredicateContext';
import { PredicateType } from '../PredicateType';
import { PredicateSpecification } from '../PredicateSpecification';

export class RightPredicateEditorStore extends PredicateEditorStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable rightType: string;
    @observable rightId: number;

    // ------------------------ Constructor -------------------------

    constructor(type: PredicateType,
                context: PredicateContext,
                specification: PredicateSpecification,
                rightType: string,
                rightId: number)
    {
        super(type, context, specification);

        this.rightType = rightType;
        this.rightId = rightId;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
