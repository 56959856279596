import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { EntityValueMutation } from '../../../../../@Api/Model/Implementation/EntityValueMutation';
import { EntityRelationshipMutation } from '../../../../../@Api/Model/Implementation/EntityRelationshipMutation';

export function isEventCoveringFieldPath(
    event: EntityEvent,
    fieldPath: EntityFieldPath
): boolean
{
    if (fieldPath.field)
    {
        return event instanceof EntityValueMutation
            && fieldPath.field === event.entityField;
    }
    else if (fieldPath.relationshipDefinition)
    {
        return event instanceof EntityRelationshipMutation
            && fieldPath.relationshipDefinition === event.entityRelationshipDefinition;
    }
    else
    {
        return false;
    }
}
