import { useCallback, useState } from 'react';

export default function useList<D>(initialValues: D[] = []): [ D[], (value: D) => void, (value: D) => void, (value: D[]) => void ]
{
    const [ values, setValues ] = useState<D[]>(initialValues);

    const add =
        useCallback(
            (value: D) =>
                setValues([
                    ...values,
                    value
                ]),
            [
                setValues,
                values
            ]);

    const remove =
        useCallback(
            (value: D) =>
                setValues(
                    values.filter(v => v !== value)),
            [
                setValues,
                values
            ]);

    return [
        values,
        add,
        remove,
        setValues
    ];
}
