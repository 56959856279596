import { useEffect, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntityEventSelectionBuilder } from '../../../Event/Selection/EntityEventSelectionBuilder';
import { RelationshipMutationFilter } from '../../../../../../@Api/Model/Implementation/RelationshipMutationFilter';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { EntityRelationshipMutation } from '../../../../../../@Api/Model/Implementation/EntityRelationshipMutation';
import useWorkflowState from '../../Api/useWorkflowState';
import useTypes from '../../../Type/Api/useTypes';
import { getModel } from '../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import usePhaseRelationshipDefinition from '../../../../../../@Api/Entity/Bespoke/Datastore/Phase/usePhaseRelationshipDefinition';

export default function useVisitedStates(entity: Entity)
{
    entity = getModel(entity);

    const types = useTypes();
    const phaseRelationshipDefinition = usePhaseRelationshipDefinition(entity && entity.entityType);
    const workflowState = useWorkflowState(entity);
    const [ visitedStates, setVisitedStates ] = useState<Entity[]>([]);

    useEffect(
        () =>
        {
            if (phaseRelationshipDefinition && workflowState)
            {
                const eventSelectionBuilder =
                    EntityEventSelectionBuilder.fromEntity(entity)
                        .filter(
                            new RelationshipMutationFilter(
                                EntityPath.fromEntity(entity),
                                phaseRelationshipDefinition,
                                false));

                eventSelectionBuilder.select()
                    .then(
                        result =>
                            setVisitedStates(
                                Array.from(
                                    new Set<Entity>(
                                        result.events
                                            .map(
                                                event =>
                                                    (event as EntityRelationshipMutation).toRelatedEntity)
                                            .filter(
                                                entity =>
                                                    entity)))));

                return () =>
                    eventSelectionBuilder.dispose();
            }
        },
        [
            entity,
            phaseRelationshipDefinition,
            types,
            // Every time the state changes, recompute the visited states
            workflowState
        ]);

    return visitedStates;
}
