import React, { useMemo } from 'react';
import { BlockEditProps } from '@wordpress/blocks';
import SampleColors from './SampleColors';
import { PanelColorSettings } from '@wordpress/block-editor';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export interface ColorOptionsPanelProps
{
    props: BlockEditProps<{}>;
    open?: boolean;
    disableBorderColor?: boolean;
    disableFontColor?: boolean;
}

const ColorOptionsPanel: React.FC<ColorOptionsPanelProps> =
    ({
        props,
        disableBorderColor,
        disableFontColor,
     }) =>
    {
        const { setAttributes } = props;
        const {
            backgroundColor,
            borderColor,
            fontColor
        } = props.attributes as any;

        const colorSettings =
            useMemo(
                () =>
                    [
                        {
                            colors: SampleColors,
                            value: backgroundColor,
                            label: localizeText('Gutenberg.ColorOptions.Background', 'Achtergrond'), // Background Color
                            onChange: color => setAttributes({ backgroundColor: color }),
                        },
                        ...!disableBorderColor
                            ? [{
                                    colors: SampleColors,
                                    value: borderColor,
                                    label: localizeText('Gutenberg.ColorOptions.Border', 'Rand'), // Border Color
                                    onChange: color => setAttributes({ borderColor: color }),
                                }]
                            : [],
                        ...!disableFontColor
                            ? [{
                                    colors: SampleColors,
                                    value: fontColor,
                                    label: localizeText('Gutenberg.ColorOptions.Font', 'Letters'), // Font Color
                                    onChange: color => setAttributes({ fontColor: color }),
                                }]
                            : [],
                        ],
                [
                    disableBorderColor,
                    disableFontColor,
                    backgroundColor,
                    fontColor,
                    borderColor,
                    setAttributes
                ]
            );


        return <PanelColorSettings
            title={localizeText('Gutenberg.ColorOptions.Title', 'Kleuren')} // Color options
            initialOpen={!!props.open}
            colorSettings={colorSettings}
        />;
    };

export default ColorOptionsPanel;
