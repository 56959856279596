import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import PortalTableLayoutWithDataSourceEditor from './WithDataSource/PortalTableLayoutWithDataSourceEditor';
import { ComputationParameterSuppressionContext } from '../../../../Entity/Viewer/Content/Automation/Editor/Computation/Context/ComputationParameterSuppressionContext';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import PortalTableLayout from '../../../../../../@Api/Layout/Type/PortalTable/PortalTableLayout';

export interface PortalTableLayoutEditorProps extends LayoutEditorProps<PortalTableLayout>
{

}

const PortalTableLayoutEditor: React.FC<PortalTableLayoutEditorProps> =
    props =>
    {
        const { layout } = props;
        const inheritedSuppressedParameters = useContext(ComputationParameterSuppressionContext);
        const suppressedParameters =
            useMemo<ParameterDictionary>(
                () =>
                    ParameterDictionary.union(
                        inheritedSuppressedParameters,
                        layout.dataSourceSignature ? layout.dataSourceSignature.resultParameters : new ParameterDictionary([])
                    ),
                [
                    inheritedSuppressedParameters,
                    layout.dataSourceSignature
                ]
            );

        if (layout.dataSourceSignature && layout.rowParameter)
        {
            return <ComputationParameterSuppressionContext.Provider
                value={suppressedParameters}
            >
                <PortalTableLayoutWithDataSourceEditor
                    {...props}
                />
            </ComputationParameterSuppressionContext.Provider>;
        }
        else
        {
            return <PortalTableLayoutWithDataSourceEditor
                {...props}
            />;
        }
    };

export default observer(PortalTableLayoutEditor);
