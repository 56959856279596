import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroupItem from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../../../../../../../Type/Api/useTypes';
import ViewGroup from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import FieldView from '../../../../../../../../../Field/View/FieldView';
import { ContentProps } from '../Content';
import Base from '../Base/Base';
import AttachmentChip from '../../../../../../../../../AttachmentList/Attachment/AttachmentChip';
import LocalizedText from '../../../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface AttachmentContentProps extends ContentProps
{

}

const AttachmentContent: React.FC<AttachmentContentProps> =
    props =>
    {
        const types = useTypes();

        const hasVersion =
            useComputed(
                () =>
                    props.entity.getObjectValueByField(types.Attachment.Field.Version) !== undefined,
                [
                    props.entity,
                    types
                ]
            )

        const hasDescription =
            useComputed(
                () =>
                    props.entity.getObjectValueByField(types.Attachment.Field.Description) !== undefined,
                [
                    props.entity,
                    types
                ]
            )

        return <Base
            {...props}
            noInputGroup
            showAttachments={false}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={40}
                alignment="center"
            >
                <ViewGroupItem
                    ratio={1}
                >
                    {
                        hasDescription &&
                        <FieldView
                            entity={props.entity}
                            field={types.Attachment.Field.Description}
                        />
                    }
                    {
                        !hasDescription &&
                        <FieldView
                            entity={props.entity}
                            field={types.Attachment.Field.File}
                        />
                    }
                </ViewGroupItem>
                {
                    hasVersion &&
                    <ViewGroupItem
                        ratio={1}
                    >
                        <LocalizedText code="Generic.Version" value="Versie" /> &nbsp;
                        <FieldView
                            entity={props.entity}
                            field={types.Attachment.Field.Version}
                        />
                    </ViewGroupItem>
                }
                <ViewGroupItem>
                    <AttachmentChip
                        attachment={props.entity}
                        readOnly
                    />
                </ViewGroupItem>
            </ViewGroup>
        </Base>;
    };

export default observer(AttachmentContent);
