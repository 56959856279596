import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import IconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import Popper from '../../../../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import useSwitch from '../../../../../../../../@Util/Switch/useSwitch';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import useTypes from '../../../../../../Entity/Type/Api/useTypes';
import { primaryColor, textSecondaryColor } from '../../../../../../../../@Resource/Theme/Theme';
import FieldGroupPredicateEditor from './RelationshipDefinitionFilterEditor/FieldGroupPredicateEditor';
import { FieldGroupItemProps } from '../FieldGroupItem';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';

export interface FieldGroupPredicateButtonProps extends FieldGroupItemProps
{

}

const FieldGroupPredicateButton: React.FC<FieldGroupPredicateButtonProps> =
    props =>
    {
        const { fieldGroup } = props;
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();
        const [ isOpen, open, close ] = useSwitch(false);
        const entityField = types.EntityFieldGroup.Field.Predicate;
        const filter =
            useEntityValue(
                fieldGroup,
                entityField);

        if (fieldGroup)
        {
            return <Popper
                reference={
                    <IconButton
                        icon="edit"
                        onClick={open}
                        tooltip={
                            <LocalizedText
                                code="Generic.Edit"
                            />
                        }
                        color={filter ? primaryColor : textSecondaryColor}
                        disabled={!currentUserStore.rightProfile.isAllowedToMutate(fieldGroup)}
                    />
                }
                popper={
                    <Card
                        inset
                    >
                        <FieldGroupPredicateEditor
                            {...props}
                            entityField={entityField}
                            onClose={close}
                        />
                    </Card>
                }
                open={isOpen}
                onClose={close}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(FieldGroupPredicateButton);
