import ParameterDictionary from '../../../Automation/Parameter/ParameterDictionary';
import Parameter from '../../../Automation/Parameter/Parameter';
import EntityValueType from '../../../Automation/Value/Type/EntityValueType';
import { EntityParameterId } from './FieldGroupPredicateConstants';
import { Entity } from '../../../Model/Implementation/Entity';
import getTypes from '../../../../@Component/Domain/Entity/Type/Api/getTypes';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../@Component/Domain/Entity/Type/EntityTypeStore';

export default function getFieldGroupPredicateParameterDictionary(fieldGroup: Entity)
{
    const types = getTypes();
    const entityTypeEntity =
        fieldGroup.getRelatedEntityByDefinition(
            true,
            types.EntityType.RelationshipDefinition.FieldGroups);
    const entityType =
        loadModuleDirectly(EntityTypeStore)
            .getTypeByEntityId(entityTypeEntity.id);

    return new ParameterDictionary([
        new Parameter(
            EntityParameterId,
            new EntityValueType(entityType),
            true,
            entityType.getName())
    ]);
}
