import { EntityType } from '../../Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import Input from '../../../@Component/Domain/Multiplayer/Model/Input/Input';
import { SettingSource, SettingStore } from '../../../@Component/Domain/Setting/SettingStore';

export default function setNameFieldOverrideInType(type: EntityType,
                                                   input?: Input)
{
    const settingStore = loadModuleDirectly(SettingStore);
    const overrides = Object.assign({}, settingStore.getValue(SettingSource.Organization, 'Metadata.TypeNameOverrides'));

    if (input)
    {
        overrides[type.id] = input.toDescriptor();
    }
    else
    {
        delete overrides[type.id];
    }

    return loadModuleDirectly(SettingStore).updateWithValue(
        SettingSource.Organization,
        'Metadata.TypeNameOverrides',
        overrides);
}
