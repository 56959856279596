import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { EntitySelectionBuilder } from '../Builder/EntitySelectionBuilder';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { DependencyList, useCallback, useEffect, useState } from 'react';
import { useComputed } from 'mobx-react-lite';
import { EntitySelectionResult } from '../Model/EntitySelectionResult';
import { useIsMounted } from '../../../../../@Util/Async/useIsMounted';

export default function useResults(type: EntityType,
                                   callback: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void,
                                   deps: DependencyList): Entity[] | undefined
{
    const memorizedCallback = useCallback(callback, deps);
    const [ results, setResults ] = useState<EntitySelectionResult[] | undefined>();
    const isMounted = useIsMounted();

    useEffect(
        () =>
        {
            if (type)
            {
                const rootPath = EntityPath.fromEntityType(type);
                const builder = EntitySelectionBuilder.fromPath(rootPath);

                memorizedCallback(
                    builder,
                    rootPath);

                builder
                    .select()
                    .then(
                        results =>
                        {
                            if (isMounted())
                            {
                                setResults(results);
                            }
                        });

                return () =>
                    builder.dispose();
            }
            else
            {
                setResults(undefined);
            }
        },
        [
            type,
            memorizedCallback,
            setResults,
            isMounted
        ]);

    return useComputed(
        () =>
        {
            if (results)
            {
                return results.map(result => result.entity);
            }
            else
            {
                return undefined;
            }
        },
        [
            results
        ]);
}
