import Role, { BasePermission, Permission, Privilege, RoleType } from '../../Model/Role';
import { getEntityTypeInheritedPermission } from './EntityType/getEntityTypeInheritedPermission';
import { EntityMetadataPermission } from '../../Model/EntityMetadataPermission';

export function getInheritedPermission(
    rolesByType: Map<RoleType, Role>,
    role: Role,
    privilege: Privilege,
    metadataPermission: EntityMetadataPermission
): Permission
{
    switch (metadataPermission.type)
    {
        case 'EntityType':
            return getEntityTypeInheritedPermission(
                rolesByType,
                role, privilege,
                metadataPermission.entityType
            );
        case 'EntityField':
        case 'RelationshipDefinition':
            return BasePermission;
        default:
            throw new Error('Unsupported entity metadata permission type');
    }
}