import Series from '../Model/Series';
import { ChartDataPoint } from './getChartData';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { MathematicalOperator } from '../../../DataObject/Model/MathematicalOperator';
import { DataPointValue } from './getSeriesData';

export default function makeSeriesCumulative(series: Series,
                                             points: ChartDataPoint[],
                                             startValueBySeries: Map<Series, DataPointValue>)
{
    let previousY : DataPointValue | undefined;

    for (let i = 0; i < points.length; i++)
    {
        const point = points[i];

        let y = point.data.get(series);

        if (y === undefined) {
            if (previousY !== undefined)
            {
                // Fill in the gap with previous value
                y = previousY;
                point.data.set(series, y);
            }
        }
        else
        {
            let newY: DataObject;

            if (i === 0)
            {
                const startValue = startValueBySeries.get(series);

                if (startValue && !startValue.value?.isEmpty)
                {
                    newY = DataObject.compute(y.value, startValue.value, MathematicalOperator.Add);
                }
                else
                {
                    newY = y.value;
                }
            }
            else
            {
                newY = DataObject.compute(y.value, points[i - 1].data.get(series)?.value, MathematicalOperator.Add);
            }

            y.value = newY;
            previousY = y;
        }
    }
}
