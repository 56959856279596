import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { default as TypeMappingModel } from '../Model/TypeMapping';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import RelationshipFieldMapping from '../Model/FieldMapping/Relationship/RelationshipFieldMapping';
import uuid from '../../../../../@Util/Id/uuid';
import { IObservableArray, runInAction } from 'mobx';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import CardHeader from '../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import Divider from '../../../../../@Future/Component/Generic/Divider/Divider';
import { EntityPath } from '../../Path/@Model/EntityPath';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import useTypes from '../../Type/Api/useTypes';
import Keys from './Keys/Keys';
import ImportResultContext from '../Context/ImportResultContext';
import MenuButton from '../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import useToggle from '../../../../../@Util/Toggle/useToggle';
import HoverCard from '../../../../../@Future/Component/Generic/Card/HoverCard/HoverCard';
import styles from './TypeMapping.module.scss';
import Link from '../../../../../@Future/Component/Generic/Link/Link';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import getEntityByTypeMapping from '../Api/getEntityByTypeMapping';
import { reverseMap } from '../../../../../@Util/MapUtils/reverseMap';
import FieldMapping from './FieldMappings/FieldMapping/FieldMapping';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import buildMappingForType from '../Api/buildMappingForType';
import FieldMappings from './FieldMappings/FieldMappings';
import Popper from '../../../../../@Future/Component/Generic/Popper/Popper';
import FieldPathSelector from '../../Path/FieldPathEditor/FieldPathSelector';
import { EntityContext } from '../../@Model/EntityContext';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { createStringComparator } from '../../../../Generic/List/V2/Comparator/StringComparator';
import Mapping from '../Model/Mapping';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface TypeMappingProps
{
    mapping: Mapping;
    typeMapping: TypeMappingModel;
    commitContext: CommitContext;
    parentTypeMapping?: TypeMappingModel;
    parentRelationshipFieldMapping?: RelationshipFieldMapping;
    idx?: number;
    root?: boolean;
}

const TypeMapping: React.FC<TypeMappingProps> =
    props =>
    {
        const types = useTypes();

        const typeMappings =
            useComputed(
                () => [
                    props.typeMapping,
                    ...props.typeMapping.fieldMappings
                        .filter(
                            fieldMapping =>
                                fieldMapping instanceof RelationshipFieldMapping
                                    && !fieldMapping.targetFieldPath.isParentRelationship
                                    && (fieldMapping.targetFieldPath.relationshipDefinition === types.Relationship.Person.RelationshipDefinition.Person
                                        || fieldMapping.targetFieldPath.relationshipDefinition === types.Relationship.Organization.RelationshipDefinition.Organization))
                        .map(
                            fieldMapping =>
                                (fieldMapping as RelationshipFieldMapping).typeMapping)
                ],
                [
                    props.typeMapping
                ]);

        const addablePathsByTypeMapping =
            useComputed(
                () =>
                    new Map(
                        typeMappings.map(
                            typeMapping =>
                            {
                                const rootPath = EntityPath.fromEntityType(typeMapping.entityType);
                                const defaultPaths = [
                                    !typeMapping.entityType.isA(types.Note.Type) &&
                                    !typeMapping.entityType.isA(types.Relation.Type) &&
                                        rootPath.joinTo(
                                            types.Entity.RelationshipDefinition.Notes,
                                            false)
                                ].filter(p => p);

                                if (rootPath.entityType.isA(types.Relation.Type)
                                    && !props.typeMapping.entityType.isA(types.Relationship.Person.Contact.Type))
                                {
                                    return [
                                        typeMapping,
                                        [
                                            ...rootPath.entityType.isA(types.Relation.Organization.Type)
                                                ?
                                                    [ rootPath.joinTo(types.Relation.RelationshipDefinition.Relationships, false).castTo(types.Relationship.Person.Contact.Standard.Type) ]
                                                :
                                                    []
                                        ]
                                    ];
                                }
                                else
                                {
                                    return [
                                        typeMapping,
                                        defaultPaths
                                    ];
                                }
                            })),
                [
                    typeMappings
                ]);

        const addablePathIdsByTypeMapping =
            useMemo(
                () =>
                    new Map(
                        Array.from(addablePathsByTypeMapping.keys())
                            .map(
                                typeMapping => [
                                    typeMapping,
                                    new Set(addablePathsByTypeMapping.get(typeMapping).map(p => p.id))
                                ])),
                [
                    addablePathsByTypeMapping
                ]);

        const addRelationshipMapping =
            useCallback(
                (typeMapping: TypeMappingModel, path: EntityPath) =>
                    runInAction(
                        () =>
                            typeMapping.fieldMappings.push(
                                new RelationshipFieldMapping(
                                    uuid(),
                                    path.field(),
                                    new TypeMappingModel(
                                        uuid(),
                                        path.entityType,
                                        // In case of a person relationship, also add a person relation
                                        path.entityType.isA(types.Relationship.Person.Type)
                                            ?
                                                [
                                                    new RelationshipFieldMapping(
                                                        uuid(),
                                                        EntityPath.fromEntityType(path.entityType)
                                                            .joinTo(
                                                                types.Relationship.Person.RelationshipDefinition.Person,
                                                                false)
                                                            .field(),
                                                        buildMappingForType(types.Relation.Person.Type))
                                                ]
                                            :
                                                // In case of an organization relationship, also add an organization relation
                                                path.entityType.isA(types.Relationship.Organization.Type)
                                                    ?
                                                        [
                                                            new RelationshipFieldMapping(
                                                                uuid(),
                                                                EntityPath.fromEntityType(path.entityType)
                                                                    .joinTo(
                                                                        types.Relationship.Organization.RelationshipDefinition.Organization,
                                                                        false)
                                                                    .field(),
                                                                buildMappingForType(types.Relation.Organization.Type))
                                                        ]
                                                    :
                                                        [])))),
                [
                    types
                ]);

        const importResult = useContext(ImportResultContext);
        const importEntity =
            useComputed(
                () =>
                    importResult && importResult.targetInstanceByMappingId.get(props.typeMapping.id),
                [
                    importResult,
                    props.typeMapping
                ]);

        // const missingFieldPathsByTypeMapping =
        //     useComputed(
        //         () =>
        //             new Map(
        //                 typeMappings.map(
        //                     typeMapping =>
        //                     {
        //                         const missingFieldPaths: EntityFieldPath[] = [];
        //
        //                         return [
        //                             typeMapping,
        //                             [
        //
        //                             ]
        //                         ]
        //                     })),
        //             [
        //
        //             ]);

        const [ isAdvanced, toggleAdvanced ] = useToggle(false);
        const deleteTypeMapping =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            if (props.parentTypeMapping)
                            {
                                (props.parentTypeMapping.fieldMappings as IObservableArray).remove(props.parentRelationshipFieldMapping);
                            }
                            else
                            {
                                (props.mapping.typeMappings as IObservableArray).remove(props.typeMapping);
                            }
                        }),
                [
                    props.mapping,
                    props.parentTypeMapping,
                    props.parentRelationshipFieldMapping
                ]);

        const commitContext = props.commitContext;
        const [ entity, typeMappingByEntity ] =
            useMemo(
                () =>
                {
                    const entityByTypeMapping = new Map<TypeMappingModel, Entity>();

                    return [
                        getEntityByTypeMapping(
                            props.typeMapping,
                            entityByTypeMapping,
                            commitContext
                        ),
                        reverseMap(entityByTypeMapping)
                    ];
                },
                [
                    props.typeMapping,
                    commitContext,
                ]);

        const renderInputOverride =
            useCallback(
                (entity: Entity, fieldPath: EntityFieldPath) =>
                    typeMappingByEntity.has(entity)
                        ?
                            <FieldMapping
                                typeMapping={typeMappingByEntity.get(entity)}
                                targetFieldPath={fieldPath.rootedAt(typeMappingByEntity.get(entity).entityType)}
                                entity={entity}
                                commitContext={commitContext}
                            />
                        :
                            <Input
                                labelPosition="left"
                                label={fieldPath.getName()}
                            >
                                <span>...</span>
                            </Input>,
                [
                    typeMappingByEntity,
                    commitContext,
                ]);

        const [ addingOtherTypeMappingRelationship, setAddingOtherTypeMappingRelationship ] = useState<TypeMappingModel>();
        const addingOtherTypeMappingRelationshipContext =
            useMemo(
                () =>
                    addingOtherTypeMappingRelationship
                    && EntityContext.fromEntityType(addingOtherTypeMappingRelationship.entityType),
                [
                    addingOtherTypeMappingRelationship
                ]);

        const isRootEntityType =
            useComputed(
                () =>
                    props.typeMapping.entityType === types.Entity.Type,
                [
                    props.typeMapping,
                    types
                ]);
        const setEntityType =
            useCallback(
                (entityType: EntityType) =>
                    runInAction(
                        () =>
                            props.typeMapping.entityType = entityType),
                [
                    props.typeMapping
                ]);

        const childTypes =
            useComputed(
                () =>
                    props.typeMapping.entityType.childTypes
                        .slice()
                        .sort(createStringComparator(a => a.nameSingular)),
                [
                    props.typeMapping
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <Card>
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                    >
                        <ViewGroupItem>
                            <CardInset>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={15}
                                    alignment="center"
                                >
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        <CardHeader>
                                            {props.typeMapping.entityType.getName()} {props.idx === undefined || !props.parentRelationshipFieldMapping?.targetFieldPath.path.isPlural ? '' : `${props.idx + 1}`}
                                        </CardHeader>
                                    </ViewGroupItem>
                                    {
                                        importEntity &&
                                            <ViewGroupItem
                                                className={styles.preview}
                                            >
                                                {
                                                    props.root && importResult.isErroneous &&
                                                        <span>
                                                            <LocalizedText
                                                                code="Import.RecordCanNotBeImported"
                                                                value="Dit record kan niet geïmporteerd worden. ${errorMessage}"
                                                                errorMessage={importResult.errorMessage || importResult.errorDetail ? importResult.errorMessage || importResult.errorDetail : ''}
                                                            />
                                                        </span>
                                                }
                                                {
                                                    importEntity.isNew()
                                                        ?
                                                            <span>
                                                                {/*{props.typeMapping.entityType.getName()} bestaat nog niet*/}
                                                            </span>
                                                        :
                                                            <span>
                                                                <LocalizedText
                                                                    code="Import.ExistingFound"
                                                                    value="Bestaande ${type} gevonden:"
                                                                    type={props.typeMapping.entityType.getName().toLowerCase()}
                                                                />
                                                                &nbsp;
                                                                <Link highlighted onClick={() => window.open(`/entity/${importEntity.id}`, 'blank')}>{importEntity.name}</Link>
                                                            </span>
                                                }
                                            </ViewGroupItem>
                                    }
                                    <ViewGroupItem>
                                        <MenuButton>
                                            <Menu>
                                                <Item
                                                    name={
                                                        <LocalizedText
                                                            code="Import.Advanced"
                                                            value="Geavanceerd"
                                                        />
                                                    }
                                                    onClick={toggleAdvanced}
                                                    active={isAdvanced}
                                                />
                                                <Item
                                                    name={
                                                        <LocalizedText
                                                            code="Generic.Delete"
                                                            value="Verwijderen"
                                                        />
                                                    }
                                                    onClick={deleteTypeMapping}
                                                />
                                            </Menu>
                                        </MenuButton>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </CardInset>
                        </ViewGroupItem>
                        {
                            isRootEntityType &&
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={5}
                                    >
                                        <ViewGroupItem>
                                            <CardInset
                                                vertical={false}
                                            >
                                                Kies een type
                                            </CardInset>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <Menu>
                                                {
                                                    childTypes
                                                        .map(
                                                            childType =>
                                                                <Item
                                                                    key={childType.id}
                                                                    name={childType.getName()}
                                                                    onClick={() => setEntityType(childType)}
                                                                />)
                                                }
                                            </Menu>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>
                        }
                        {
                            !isRootEntityType &&
                                <ViewGroupItem>
                                    <FieldMappings
                                        typeMapping={typeMappingByEntity.get(entity)}
                                        entity={entity}
                                        commitContext={commitContext}
                                        renderInputOverride={renderInputOverride}
                                        includeTypeField={!typeMappingByEntity.get(entity).entityType.isInstantiableByInheritance() ? 'include' : 'exclude'}
                                        advanced={isAdvanced}
                                    />
                                </ViewGroupItem>
                        }
                        {
                            !isRootEntityType &&
                            typeMappingByEntity.get(entity).entityType.getAllTypes(false)
                                .filter(
                                    type =>
                                        entity.entityType !== type)
                                .map(
                                    childType =>
                                        <ViewGroupItem
                                            key={childType.id}
                                        >
                                            <FieldMappings
                                                typeMapping={typeMappingByEntity.get(entity)}
                                                entity={entity}
                                                commitContext={commitContext}
                                                renderInputOverride={renderInputOverride}
                                                forType={childType}
                                                advanced={isAdvanced}
                                            />
                                        </ViewGroupItem>)
                        }
                        {
                            isAdvanced && typeMappings
                                .map(
                                    typeMapping =>
                                        <ViewGroupItem
                                            key={typeMapping.id}
                                        >
                                            <Divider />
                                            <Keys
                                                typeMapping={typeMapping}
                                                entity={entity}
                                                commitContext={commitContext}
                                            />
                                        </ViewGroupItem>)
                        }
                    </ViewGroup>
                </Card>
            </ViewGroupItem>
            {
                (Array.from(addablePathsByTypeMapping.keys())
                    .reduce((a, b) => a + addablePathsByTypeMapping.get(b).length, 0) > 0
                    || isAdvanced) &&
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                            >
                                {
                                    Array.from(addablePathsByTypeMapping.keys())
                                        .filter(
                                            typeMapping =>
                                                addablePathsByTypeMapping.get(typeMapping).length > 0
                                                || isAdvanced)
                                        .map(
                                            typeMapping =>
                                                <ViewGroupItem
                                                    key={typeMapping.id}
                                                    ratio={1}
                                                >
                                                    <ViewGroup
                                                        orientation="vertical"
                                                        spacing={15}
                                                    >
                                                        {
                                                            isAdvanced &&
                                                            typeMapping
                                                                .fieldMappings
                                                                .filter(
                                                                    fieldMapping =>
                                                                        fieldMapping instanceof RelationshipFieldMapping
                                                                        && !addablePathIdsByTypeMapping.get(typeMapping).has(fieldMapping.targetFieldPath.path.id))
                                                                .map(
                                                                    (fieldMapping: RelationshipFieldMapping, idx) =>
                                                                        <ViewGroupItem
                                                                            key={fieldMapping.id}
                                                                        >
                                                                            <ObservableTypeMapping
                                                                                mapping={props.mapping}
                                                                                typeMapping={fieldMapping.typeMapping}
                                                                                commitContext={commitContext}
                                                                                parentTypeMapping={typeMapping}
                                                                                parentRelationshipFieldMapping={fieldMapping}
                                                                                idx={idx}
                                                                            />
                                                                        </ViewGroupItem>)
                                                        }
                                                        {
                                                            addablePathsByTypeMapping.get(typeMapping)
                                                                .map(
                                                                    path =>
                                                                        <ViewGroupItem
                                                                            key={path.id}
                                                                            ratio={1}
                                                                        >
                                                                            <ViewGroup
                                                                                orientation="vertical"
                                                                                spacing={15}
                                                                            >
                                                                                {
                                                                                    typeMapping
                                                                                        .fieldMappings
                                                                                        .filter(
                                                                                            fieldMapping =>
                                                                                                fieldMapping instanceof RelationshipFieldMapping
                                                                                                    && fieldMapping.targetFieldPath.path.id === path.id)
                                                                                        .map(
                                                                                            (fieldMapping: RelationshipFieldMapping, idx) =>
                                                                                                <ViewGroupItem
                                                                                                    key={fieldMapping.id}
                                                                                                >
                                                                                                    <ObservableTypeMapping
                                                                                                        mapping={props.mapping}
                                                                                                        typeMapping={fieldMapping.typeMapping}
                                                                                                        commitContext={props.commitContext}
                                                                                                        parentTypeMapping={typeMapping}
                                                                                                        parentRelationshipFieldMapping={fieldMapping}
                                                                                                        idx={idx}
                                                                                                    />
                                                                                                </ViewGroupItem>)
                                                                                }
                                                                                <ViewGroupItem>
                                                                                    <HoverCard
                                                                                        onClick={() => addRelationshipMapping(typeMapping, path)}
                                                                                    >
                                                                                        + {path.entityType.getName()}
                                                                                    </HoverCard>
                                                                                </ViewGroupItem>
                                                                            </ViewGroup>
                                                                        </ViewGroupItem>)
                                                        }
                                                        {
                                                            isAdvanced &&
                                                                <ViewGroupItem>
                                                                    <Popper
                                                                        reference={
                                                                            <HoverCard
                                                                                onClick={() => setAddingOtherTypeMappingRelationship(typeMapping)}
                                                                            >
                                                                                <LocalizedText
                                                                                    code="Import.AddOtherButton"
                                                                                    value="+ Overig"
                                                                                />
                                                                            </HoverCard>
                                                                        }
                                                                        popper={
                                                                            <Card>
                                                                                <FieldPathSelector
                                                                                    context={addingOtherTypeMappingRelationshipContext}
                                                                                    onSelect={fieldPath => addRelationshipMapping(typeMapping, fieldPath.path)}
                                                                                />
                                                                            </Card>
                                                                        }
                                                                        open={typeMapping === addingOtherTypeMappingRelationship}
                                                                        onClose={() => setAddingOtherTypeMappingRelationship(undefined)}
                                                                    />
                                                                </ViewGroupItem>
                                                        }
                                                    </ViewGroup>
                                                </ViewGroupItem>)
                                }
                            </ViewGroup>
                        </ViewGroupItem>
            }
        </ViewGroup>;
    };

const ObservableTypeMapping = observer(TypeMapping);

export default ObservableTypeMapping;
