import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TextEditor from '../../../../../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import RegExComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/RegExComputation';

export interface RegExComputationEditorProps extends ComputationEditorProps<PrimitiveValueType, RegExComputation>
{

}

const RegExComputationEditor: React.FC<RegExComputationEditorProps> =
    props =>
    {
        const setPattern =
            useCallback(
                (pattern?: string) =>
                    runInAction(
                        () =>
                            props.value.pattern = pattern),
                [
                    props.value
                ]);

        const setValue =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.value = value),
                [
                    props.value
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                Waarde:
            </ViewGroupItem>
            <ViewGroupItem>
                <ComputationEditor
                    context={props.context}
                    value={props.value.value}
                    onChange={setValue}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                Patroon:
            </ViewGroupItem>
            <ViewGroupItem>
                <TextEditor
                    value={props.value.pattern}
                    onChange={setPattern}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(RegExComputationEditor);
