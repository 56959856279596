import Feature from './Feature';

export const ExportingFeature = new Feature('Exporting');
export const MergingFeature = new Feature('Merging');
export const TranslatingFeature = new Feature('Translating');
export const HideFinancialWidgetsInProjectFeature = new Feature('HideFinancialWidgetInProjects');
export const HideTimeRegistrationInProjectFeature = new Feature('HideTimeRegistrationInProjects');
export const HideMileageRegistrationInProjectFeature = new Feature('HideMileageRegistrationInProjects');
export const HideExternalCostInProjectFeature = new Feature('HideExternalCostInProjects');
export const HideToInvoiceInProjectFeature = new Feature('HideToInvoiceInProjects');
export const HideInvoiceInProjectFeature = new Feature('HideInvoiceInProjects');
export const HideEmployeePlannerInProjectFeature = new Feature('HideEmployeePlannerInProjects');
export const HideEmployeeAllocationInProjectFeature = new Feature('HideEmployeeAllocationInProjects');
