import Role, { Privilege } from '../../Model/Role';
import { EntityMetadataPermission } from '../../Model/EntityMetadataPermission';

export function isInheritedPermission(
    role: Role,
    privilege: Privilege,
    metadataPermission: EntityMetadataPermission
): boolean
{
     switch (metadataPermission.type)
     {
          case 'EntityType':
               return role.rightByType.get(metadataPermission.entityType) === undefined
                   || role.rightByType.get(metadataPermission.entityType).getPermission(privilege) === 'Inherited';
          case 'EntityField':
               return role.rightByField.get(metadataPermission.field) === undefined
                   || role.rightByField.get(metadataPermission.field)?.getPermission(privilege) === 'Inherited';
          case 'RelationshipDefinition':
               return role.rightByRelationshipType.get(metadataPermission.relationship) === undefined
               || role.rightByRelationshipType.get(metadataPermission.relationship)?.getPermission(privilege) === 'Inherited';
          default:
               throw new Error('Unsupported entity metadata permission type');
     }
}