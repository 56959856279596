import React from 'react';
import Theme from '../../../@Resource/Theme/Theme';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';

export interface ThemeProviderProps
{

}

export const ThemeProvider: React.FC<ThemeProviderProps> =
    ({
        children,
     }) =>
    {
        return <MuiThemeProvider
            theme={Theme}
        >
            <CssBaseline />
            {children}
        </MuiThemeProvider>
    };
