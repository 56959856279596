import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntitySelectionResult } from '../../../Selection/Model/EntitySelectionResult';
import { Table, TableCell, TableRow } from '@material-ui/core';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import TableBody from '@material-ui/core/TableBody';
import useTypes from '../../../Type/Api/useTypes';
import Head from '../Head/Head';
import InfiniteScroll from '../../../../../../@Future/Component/Generic/InfiniteScroll/InfiniteScroll';
import { IObservableArray, runInAction } from 'mobx';
import HoverCardBottom from '../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import FilterContext from '../../../Dataset/Segment/FilterContext/FilterContext';
import constructEntity from '../../../../../../@Api/Entity/constructEntity';
import { Droppable } from 'react-beautiful-dnd';
import uuid from '../../../../../../@Util/Id/uuid';
import useOnDragEnd from '../../../../DragAndDrop/Api/useOnDragEnd';
import { createTransactionalModel } from '../../../../../../@Util/TransactionalModelV2/index';
import useCount from '../../../Selection/Hooks/useCount';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import constructEntityFromPredicate from '../../../../../../@Api/Entity/constructEntityFromPredicate';
import usePaginatedSelection from '../../Api/usePaginatedSelection';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { setValueByFieldInEntity } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { CommitContextImpl } from '../../../../../../@Api/Entity/Commit/Context/CommitContextImpl';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/index';
import { CurrentUserStore } from '../../../../User/CurrentUserStore';
import { openEntity } from '../../../@Util/openEntity';
import { useAndMaintainViewRoutingState } from '../../Api/useAndMaintainViewRoutingState';
import NonGroupedListPage from './NonGroupedListPage';
import { isColumnPlural } from '../../Api/isColumnPlural';
import { BaseListSelectionType, ListProps } from '../List';
import { getNumberOfVisibleColumnsInView } from '../../Api/getNumberOfVisibleColumnsInView';
import { ViewStateOpenedGroup } from '../../State/ViewState';
import { NonGroupedListTotalRow } from './NonGroupedListTotalRow';
import HoverCardMiddle from '../../../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';

export interface NonGroupedListProps extends ListProps
{
    root?: boolean;
    openedGroup?: ViewStateOpenedGroup;
}

const pageSize = 50;

const NonGroupedList: React.FC<NonGroupedListProps> =
    props =>
    {
        const { onSelectionChange, onClick } = props;
        const currentUserStore = loadModuleDirectly(CurrentUserStore);

        const [ selectionMutations, setSelectionMutations ] =
            useState<string[]>(
                props.selection
                    ? props.selection.mutations
                    : []
            );
        const [ baseSelectionType, setBaseSelectionType ] =
            useState<BaseListSelectionType>(
                props.selection
                    ? props.selection.base
                    : props.defaultSelectionType
                        ? props.defaultSelectionType
                        : 'none'
            );
        const types = useTypes();
        const list = props.view.specification.list;
        const filter = useContext(FilterContext);
        const resultCount =
            useCount(
                // Disable counting if selections are disabled for performance
                props.selectable ? props.view.entityType : undefined,
                (builder) =>
                    builder
                        .if(
                            () => props.view.filter !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.filter(
                                            props.view.filter,
                                            {
                                                parameter: props.view.parameter,
                                            }
                                        )
                                )
                        ),
                [
                    props.view
                ]);

        const selection =
            useMemo(
                () =>
                {
                    let size: number;

                    if (resultCount !== undefined)
                    {
                        if (baseSelectionType === 'all')
                        {
                            size = resultCount - selectionMutations.length;
                        }
                        else
                        {
                            size = selectionMutations.length;
                        }
                    }

                    return {
                        base: baseSelectionType,
                        mutations: selectionMutations,
                        size: size
                    };
                },
                [
                    baseSelectionType,
                    selectionMutations,
                    resultCount
                ]);

        useEffect(
            () =>
            {
                if (onSelectionChange)
                {
                    onSelectionChange(selection);
                }
            },
            [
                onSelectionChange,
                selection
            ]);

        const changeSelection =
            useCallback(
                (value: boolean, uuid: string) =>
                {
                    let mutations = selectionMutations.filter(
                        id =>
                            id !== uuid);

                    if (baseSelectionType === 'none' && value)
                    {
                        mutations.push(uuid);

                        if (mutations.length === resultCount)
                        {
                            mutations = [];
                            setBaseSelectionType('all');
                        }
                    }
                    else if (baseSelectionType === 'all' && !value)
                    {
                        mutations.push(uuid);

                        if (mutations.length === resultCount)
                        {
                            mutations = [];
                            setBaseSelectionType('none');
                        }
                    }

                    setSelectionMutations(mutations);
                },
                [
                    selectionMutations,
                    setSelectionMutations,
                    resultCount,
                    setBaseSelectionType,
                    baseSelectionType
                ]);

        const columnFilters =
            useComputed(
                () =>
                    list.columns
                        .filter(
                            column =>
                                column.filter !== undefined)
                        .map(
                            column =>
                                column.filter),
                [
                    list
                ]);

        const [ pages, hasMore, loadMore, isLoading ] =
            usePaginatedSelection(
                props.view.entityType,
                builder =>
                {
                    list.columns.forEach(
                        column =>
                        {
                            // Exclude plural child relations, as they interfere with paging mechanism and might
                            // cause incomplete results.
                            // Data is added later before displaying data
                            if (!isColumnPlural(column))
                            {
                                builder.join(column.fieldPath.path);
                            }

                            if (column.cellLayout !== undefined)
                            {
                                column.cellLayout.getDependencies()
                                    .filter(
                                        dependency =>
                                            dependency.parameter === props.view.parameter
                                            && dependency.fieldPath !== undefined
                                    )
                                    .forEach(
                                        dependencyFieldPath =>
                                            builder.join(dependencyFieldPath.fieldPath.path)
                                    );
                            }
                        }
                    );

                    list.ordering.forEach(
                        ordering =>
                            builder.orderBy(
                                ordering.column.fieldPath,
                                ordering.isAscending
                            )
                    );

                    if (filter && filter.isValid())
                    {
                        builder.where(
                            cb =>
                                cb.filter(
                                    filter,
                                    {
                                        parameter: props.view.parameter,
                                    }
                                )
                        );
                    }
                    else if (props.view.filter && props.view.filter.isValid())
                    {
                        builder
                            .where(
                                cb =>
                                    cb.filter(
                                        props.view.filter,
                                        {
                                            parameter: props.view.parameter,
                                        }
                                    )
                            );
                    }

                    for (const filter of columnFilters)
                    {
                        builder.where(
                            cb =>
                                cb.filter(
                                    filter,
                                    {
                                        parameter: props.view.parameter,
                                    }
                                )
                        );
                    }
                },
                [
                    list,
                    props.view,
                    types,
                    filter,
                    columnFilters,
                ]
            );

        const [ lastOpenedEntityIdFromRoutingState, setLastOpenedEntityIdInRoutingState ] =
            useAndMaintainViewRoutingState(
                props.saveViewState,
                props.view,
                props.searchQuery,
                loadMore,
                pages.length,
                props.openedGroup
            );

        const isCreateEnabled =
            useComputed(
                () =>
                    !props.readonly
                    && currentUserStore.rightProfile.canCreateType(props.view.entityType),
                [
                    props.readonly,
                    props.view.entityType
                ]
            )

        const onAdd =
            useCallback(
                () =>
                {
                    const commitContext = new CommitContextImpl({ allowAutoCommit: false });
                    const entity =
                        constructEntityFromPredicate(
                            props.view.entityType,
                            filter,
                            commitContext
                        );

                    if (props.sortable)
                    {
                        let sortIndex = 0;
                        const lastPage = pages && pages.length > 0 ? pages[pages.length - 1] : undefined;

                        if (lastPage)
                        {
                            const lastResult =
                                lastPage && lastPage.length > 0
                                    ?
                                        lastPage[lastPage.length - 1]
                                    :
                                        undefined;

                            if (lastResult?.entity?.sortIndex !== undefined)
                            {
                                sortIndex = lastResult.entity.sortIndex + 1;
                            }
                        }

                        setValueByFieldInEntity(
                            entity,
                            types.Entity.Field.SortIndex,
                            sortIndex,
                            commitContext
                        );
                    }

                    return constructEntity(
                        entity.entityType,
                        undefined,
                        entity,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        commitContext
                    );
                },
                [
                    types,
                    props.view.entityType,
                    filter,
                    props.sortable,
                    pages
                ]);

        const onRowClick =
            useCallback(
                (entity: Entity) =>
                {
                    setLastOpenedEntityIdInRoutingState(entity.uuid);
                    if (onClick)
                    {
                        onClick(entity);
                    }
                    else
                    {
                        openEntity(entity).finally();
                    }
                },
                [
                    onClick,
                    setLastOpenedEntityIdInRoutingState
                ]
            );

        const head =
            useMemo(
                () =>
                    <Head
                        entityType={props.view.entityType}
                        view={props.view}
                        list={list}
                        isInEditMode={props.isInEditMode}
                        sortable={props.sortable}
                        selectable={props.selectable}
                        selected={baseSelectionType === 'all'}
                        selectionIsIndeterminated={selectionMutations.length > 0}
                        onSelect={
                            value =>
                            {
                                setBaseSelectionType(value ? 'all' : 'none');
                                setSelectionMutations([]);
                            }
                        }
                    >
                        <title/>
                    </Head>,
                [
                    baseSelectionType,
                    setBaseSelectionType,
                    selectionMutations,
                    props.sortable,
                    props.view,
                    props.isInEditMode,
                    props.selectable,
                    list
                ]);

        const body =
            useComputed(
                () =>
                    <>
                        {
                            pages
                                .filter(
                                    page =>
                                        page.length > 0
                                )
                                .map(
                                    (page, pageIdx) =>
                                            <NonGroupedListPage
                                                key={pageIdx}
                                                pageSize={pageSize}
                                                pageIdx={pageIdx}
                                                page={page}
                                                changeSelection={changeSelection}
                                                baseSelectionType={baseSelectionType}
                                                onRowClick={onRowClick}
                                                lastOpenedEntityIdFromRoutingState={lastOpenedEntityIdFromRoutingState}
                                                selectionMutations={selectionMutations}
                                                {...props}
                                            />
                                    )
                        }
                    </>,
                [
                    pages,
                    selectionMutations,
                    changeSelection,
                    baseSelectionType,
                    props.isInEditMode,
                    props.sortable,
                    props.selectable,
                    props.onClick,
                    props.view.entityType
                ]);

        const droppableId = useMemo(() => uuid(), []);

        useOnDragEnd(
            useCallback(
                result =>
                {
                    if (result.destination?.droppableId === droppableId && pages.length > 0)
                    {
                        runInAction(
                            () =>
                            {
                                const results = pages[0] as IObservableArray<EntitySelectionResult>;
                                const dragResult =
                                    results.find(
                                        entity =>
                                            entity.entity.uuid === result.draggableId);

                                if (dragResult)
                                {
                                    results.replace(
                                        results.filter(
                                            checkResult => checkResult !== dragResult));

                                    results.splice(
                                        result.destination.index,
                                        0,
                                        dragResult);

                                    Promise.all(
                                        results.map(
                                            (result, idx) =>
                                            {
                                                const entity = result.entity;
                                                const transactionalEntity = createTransactionalModel(entity);
                                                transactionalEntity.setValueByField(
                                                    types.Entity.Field.SortIndex,
                                                    idx);

                                                return transactionalEntity.checkAndDoCommit();
                                            }));
                                }
                            });
                    }
                },
                [
                    types,
                    droppableId,
                    pages
                ]));

        const numberOfVisibleColumns =
            useComputed(
                () =>
                    getNumberOfVisibleColumnsInView(
                        props.view,
                        props.sortable ?? false,
                        props.selectable ?? false,
                        props.isInEditMode ?? false
                    ),
                [
                    props.view,
                    props.sortable,
                    props.selectable,
                    props.isInEditMode,
                ]
            );
        const bodyWithAppendix =
            <>
                {body}
                {
                    isLoading &&
                    <TableRow>
                        <TableCell
                            colSpan={numberOfVisibleColumns}
                        >
                            <Centered
                                horizontal
                            >
                                <Loader />
                            </Centered>
                        </TableCell>
                    </TableRow>
                }
                {
                    hasMore &&
                    <TableRow>
                        <TableCell
                            colSpan={numberOfVisibleColumns}
                            padding="none"
                        >
                            <HoverCardMiddle
                                onClick={loadMore}
                            >
                                <LocalizedText
                                    code="Generic.LoadMore"
                                    value="Meer laden"
                                />...
                            </HoverCardMiddle>
                        </TableCell>
                    </TableRow>
                }
                {
                    isCreateEnabled &&
                    <TableRow>
                        <TableCell
                            colSpan={numberOfVisibleColumns}
                            padding="none"
                        >
                            <HoverCardBottom
                                onClick={onAdd}
                            >
                                + <LocalizedText code="Generic.Add" value="Toevoegen" />
                            </HoverCardBottom>
                        </TableCell>
                    </TableRow>
                }
            </>;
        const bodyWithAppendixAndWrapper =
            <TableBody>
                {bodyWithAppendix}
            </TableBody>;

        if (props.root)
        {
            const body =
                props.sortable
                    ? <Droppable
                        droppableId={droppableId}
                        type="field"
                    >
                        {
                            (provided) =>
                                <Table
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {head}
                                    {bodyWithAppendixAndWrapper}
                                    {provided.placeholder}
                                </Table>
                        }
                    </Droppable>
                    : <Table>
                        {head}
                        <NonGroupedListTotalRow
                            view={props.view}
                            isInEditMode={props.isInEditMode}
                        />
                        {bodyWithAppendixAndWrapper}
                    </Table>;

            if (props.disableInfiniteScroll)
            {
                return body;
            }
            else
            {
                return <InfiniteScroll
                    loadMore={loadMore}
                    hasMore={hasMore}
                    isLoading={isLoading}
                >
                    {body}
                </InfiniteScroll>;
            }
        }
        else
        {
            return bodyWithAppendix;
        }
    };

export default observer(NonGroupedList);
