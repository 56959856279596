import React from 'react';
import { observer } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import AppBarLayout from '../../../../../@Api/Layout/Type/AppBarLayout';
import AppBarLayoutView from './View/AppBarLayoutView';

export interface AppBarLayoutViewerProps extends LayoutViewerProps<AppBarLayout>
{

}

const AppBarLayoutViewer: React.FC<AppBarLayoutViewerProps> =
    props =>
    {
        return <AppBarLayoutView
            layout={props.layout}
        >
            <LayoutViewer
                {...props}
                layout={props.layout.layout}
            />
        </AppBarLayoutView>;
    };

export default observer(AppBarLayoutViewer);
