import React, { useCallback, useContext, useMemo } from 'react';
import { SummaryField } from '../../Type/BespokeEntityType';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Icon from '../../../../../@Future/Component/Generic/Icon/Icon';
import { primaryColor } from '../../../../../@Resource/Theme/Theme';
import Link from '../../../../../@Future/Component/Generic/Link/Link';
import { EmailType } from '../../../DataObject/Type/Text/Email/EmailType';
import EntityTypeContext from '../../Type/EntityTypeContext';
import styles from './Item.module.scss';
import resolveUrlFromUserInput from '../../../../../@Util/Url/resolveUrlFromUserInput';
import openWindow from '../../../../../@Util/Window/openWindow';

export interface ItemProps
{
    field: SummaryField;
    clickable?: boolean;
}

const Item: React.FC<ItemProps> =
    props =>
    {
        const entityTypeStore = useContext(EntityTypeContext);

        const iconColor =
            useMemo(
                () =>
                    props.field.link
                        ?
                            primaryColor
                        :
                            undefined,
                [
                    props
                ]
            );

        const onClickCallback =
            useCallback(
                () =>
                    props.field.link
                        ?
                            props.field.value && props.field.value.dataObject.specification.type instanceof EmailType
                                ? entityTypeStore.entity.activity.email.openEmailComposerForRecipient(props.field.value)
                                : openWindow(
                                    resolveUrlFromUserInput(props.field.link),
                                    '_blank'
                                 )
                        :
                            undefined,
                [
                    props,
                    entityTypeStore.entity.activity.email
                ]
            );

        const linkClasses =
            useMemo(
                () =>
                    ({
                        root: styles.link
                    }),
                []
            );

        return <ViewGroup
            orientation="horizontal"
            spacing={6}
        >
            <ViewGroupItem>
                <Icon
                    icon={props.field.icon}
                    color={iconColor}
                    size={15}
                />
            </ViewGroupItem>
            <ViewGroupItem
                ratio={1}
            >
                {
                    props.clickable && props.field.link
                        ?
                            <Link
                                onClick={onClickCallback}
                                tooltip={props.field.tooltip}
                                classes={linkClasses}
                            >
                                {props.field.title}
                            </Link>
                        :
                            <div
                                className={styles.link}
                            >
                                {props.field.title}
                            </div>
                }
            </ViewGroupItem>
        </ViewGroup>;
    };

export default Item;
