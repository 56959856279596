import { EntityType } from '../../Model/Implementation/EntityType';
import { Entity } from '../../Model/Implementation/Entity';
import { DisposableValue } from '../../../@Util/Disposable/DisposableValue';
import { internallyGetEntitiesByIds } from './internallyGetEntitiesByIds';
import getTypes from '../../../@Component/Domain/Entity/Type/Api/getTypes';

export default async function getEntitiesByIds(
    entityType: EntityType,
    ids: number[]
): Promise<DisposableValue<Entity[]>>
{
    return internallyGetEntitiesByIds(
        entityType,
        ids,
        getTypes().Entity.Field.Id
    );
}
