import { computed } from 'mobx';
import { ButtonProps, ButtonStore } from '../ButtonStore';
import { MenuItemStore } from '../../Menu/MenuItemStore';
import { getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { ViewComponent } from '../../ViewStack/Model/ViewComponent';
import Menu from '../../Menu/Menu';
import { MenuStore } from '../../Menu/MenuStore';

export interface MoreButtonProps
{
    items: PropType<MoreButtonStore, MoreButtonProps, Array<(MenuItemStore | ButtonStore)>>;
    menuIcon?: PropType<MoreButtonStore, MoreButtonProps, string>;
}

const defaultProps: Partial<MoreButtonProps> =
{
};

export class MoreButtonStore<P extends MoreButtonProps = MoreButtonProps> extends ButtonStore<P & ButtonProps>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: MoreButtonProps)
    {
        super({
            icon:
                () =>
                    this.menuIcon ? this.menuIcon : 'more_horiz',
            isVisible:
                () =>
                    this.hasMenu,
            onClick:
                () =>
                    this.openPopperView(
                        new ViewComponent(
                            Menu,
                            new MenuStore({
                                items: () => this.menuItemStores
                            })
                        )),
            ...props
        } as any, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    @computed
    get menuItemStores(): Array<(MenuItemStore | ButtonStore)>
    {
        return getOrCompute(this, this.props.items);
    }

    @computed
    get menuIcon(): string
    {
        return getOrCompute(this, this.props.menuIcon);
    }

    @computed
    get hasMenu(): boolean
    {
        return this.menuItemStores
            .find(
                itemStore =>
                    itemStore.isVisible) !== undefined;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
