import { useContext, useMemo } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import CurrentUserContext from '../../../../../User/CurrentUserContext';
import useTypes from '../../../../../Entity/Type/Api/useTypes';

export function useOwnedShareAndEnvironmentPacks(): Entity[]
{
    const currentUserStore = useContext(CurrentUserContext);
    const types = useTypes();

    return useMemo(
        () =>
            currentUserStore.isAdministrator
                ? currentUserStore.ownedPackEntities
                    .filter(
                        pack =>
                            pack.entityType.isA(types.Pack.Environment.Type)
                            || pack.entityType.isA(types.Pack.Share.Type))
                : [],
        [
            currentUserStore,
            types
        ]);
}
