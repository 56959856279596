import { action, computed, observable } from 'mobx';
import { DataObjectBespokeEditorStore } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { FormHandlerProperties } from '../../../../../Generic/Form/FormHandlerProperties';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { DataObject } from '../../../Model/DataObject';
import { FormEvent } from '../../../../../Generic/Form/FormEvent';
import moment, { Moment } from 'moment';

export class DateTimeEditorStore extends DataObjectBespokeEditorStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable handlers: FormHandlerProperties<DataObject>;

    // ------------------------ Constructor -------------------------

    constructor(baseStore: DataObjectEditorStore)
    {
        super(baseStore);

        this.handlers = new FormHandlerProperties(this.baseStore.handlerContext, this.baseStore.dataObject);

        this.handlers.wrap(
            FormEvent.Change,
            (event) =>
            {
                let date = event.target.value;

                if (date)
                {
                    this.baseStore.dataObject.setValue(
                        date
                            ?
                                date instanceof moment
                                    ?
                                        (date as Moment).toDate()
                                    :
                                        date
                            :
                                undefined);
                }
                else
                {
                    this.baseStore.dataObject.setValue(undefined);
                }
            });

        // this.datePickerStore = new DatePickerStore({
        //     date: () => this.momentValue,
        //     onDateChange: datePickerStore =>
        //     {
        //         let setDate, dateTime, dateTimeStamp;
        //
        //         if (datePickerStore.date === null || datePickerStore.date === undefined)
        //         {
        //             setDate = null;
        //             dateTime = null;
        //             dateTimeStamp = null;
        //         }
        //         else
        //         {
        //             // Merge the selected time and date
        //             setDate = moment(datePickerStore.date);
        //             dateTime = moment(this.baseStore.dataObject.getValue());
        //             dateTime.set('year', setDate.get('year'));
        //             dateTime.set('dayOfYear', setDate.get('dayOfYear'));
        //
        //             // Ceil to the next 5 minute step if the date is taken from the current datetime
        //             if (!this.baseStore.dataObject.getValue())
        //             {
        //                 const remainder = 5 - (dateTime.minute() % 5);
        //                 dateTime = moment(dateTime).add(remainder, 'minutes');
        //                 dateTime.set('second', 0);
        //             }
        //
        //             if (this.disableTime)
        //             {
        //                 dateTime.set('hour', 0);
        //                 dateTime.set('minute', 0);
        //                 dateTime.set('second', 0);
        //             }
        //
        //             dateTimeStamp = dateTime.valueOf();
        //         }
        //
        //         this.baseStore.changeValue(dateTimeStamp, false);
        //         this.handlers.onChange({ target: { value: dateTime }}, dateTime);
        //         // this.handlers.onBlur({ target: { value: dateTimeStamp }}, dateTimeStamp);
        //     },
        //     // openDirection: this.baseStore.renderDirectionUp ? 'up' : 'down',
        //     isOutsideRange: (day: any) =>
        //         this.baseStore.dataObject.specification.data.allowSelectingPast &&
        //         this.baseStore.dataObject.specification.data.allowSelectingPast === true
        //             ? false
        //             : !isInclusivelyAfterDay(day, moment()),
        //     isDayBlocked: (day: any) =>
        //     {
        //         if (this.baseStore.dataObject.specification.data.minValue)
        //         {
        //             let minValue = this.baseStore.dataObject.specification.data.minValue as Date;
        //
        //             if (day < minValue)
        //             {
        //                 return true;
        //             }
        //         }
        //         return false;
        //     },
        //     showUnderline: () => this.baseStore.showUnderline
        // });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get momentValue(): Moment
    {
        let dataObjectValue = this.baseStore.dataObject.getValue();

        if (dataObjectValue && /^\d+$/.test(dataObjectValue))
        {
            return moment(dataObjectValue);
        }
        else if (!dataObjectValue)
        {
            return null;
        }
        else
        {
            return moment(dataObjectValue);
        }
    }

    @computed
    get disableTime(): boolean
    {
        return this.baseStore.representation &&
            this.baseStore.representation.data &&
            this.baseStore.representation.data.hasTime !== undefined && !this.baseStore.representation.data.hasTime;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    setDate(date: Date)
    {
        this.baseStore.changeValue(date);
    }

    @action.bound
    setTime(hour: number, minute: number)
    {
        const newDate = moment(this.momentValue);
        newDate.set('hour', hour);
        newDate.set('minute', minute);

        this.baseStore.changeValue(newDate.toDate());

        // // Merge time to date
        // if (time)
        // {
        //     let date = moment(store.dataObject.getValue());
        //     let dateTime = moment(time.valueOf());
        //     dateTime.set('dayOfYear', date.get('dayOfYear'));
        //
        //     store.changeValue(dateTime.valueOf(), false);
        //     store.bespokeStore.handlers.onChange({target: {value: dateTime}}, dateTime);
        // }
        // else
        // {
        //     store.changeValue(undefined);
        // }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
