import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { LocalizedTextProps } from '../LocalizedText';
import localizeText from '../../../../../@Api/Localization/localizeText';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import RichText from '../../../../Generic/RichText/RichText';

export interface LocalizedTextTranslationProps extends LocalizedTextProps
{
}

const LocalizedTextTranslation: React.FC<LocalizedTextTranslationProps & any> =
    props =>
    {
        const { code, value, lowerCase, defaultLanguage, ...params } = props;

        const localizationStore = loadModuleDirectly(LocalizationStore);
        const language = localizationStore.languageCode;

        const translation =
            useMemo(
                () =>
                {
                    const translation = localizeText(code, value, params);

                    if (translation !== undefined && lowerCase)
                    {
                        return translation.toLowerCase();
                    }
                    else
                    {
                        return translation;
                    }
                },
                [
                    code,
                    value,
                    lowerCase,
                    params,
                    language // Needed to force rerender on language change
                ]
            );

        if (props.containsHTML)
        {
            return <RichText
                     value={translation}
                />;
        }
        else
        {
            return <>{translation}</>;
        }
    };

export default observer(LocalizedTextTranslation);
