import React, { useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { makeStyles, TableCell } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { itemPlannerWeekLength } from '../../EmployeeAllocationEditorConstants';
import { ItemPlannerEmployeeRowProps } from '../EmployeeAllocationEditorEmployeeRow';
import { primaryColor, recordHoverBackgroundColor, textColor } from '../../../../../../@Resource/Theme/Theme';
import useSwitch from '../../../../../../@Util/Switch/useSwitch';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import { UninitializedEmployeeAllocationTableEditor } from '../../Table/UninitializedEmployeeAllocationTableEditor';
import useTypes from '../../../Type/Api/useTypes';
import { startOfWeek } from 'date-fns';
import getDurationLabel from '../../../../../../@Util/Date/getDurationLabel';
import { blue } from '../../../../../../@Resource/Theme/Blue';
import { ColorUtils } from '../../../../../../@Util/Color/ColorUtils';
import Dialog from '../../../../../../@Future/Component/Generic/Dialog/Dialog';

const useStyles =
    makeStyles({
        root: {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: `${recordHoverBackgroundColor} !important`,
                color: `${textColor} !important`,
                '& $percentage': {
                    color: `rgba(0,0,0,0.4) !important`,
                },
            },
        },
        percentage: {

        },
        open: {
            backgroundColor: `${primaryColor} !important`,
            color: 'white !important',
            '& $percentage': {
                color: `rgba(255,255,255,0.4) !important`,
            },
        },
        popper: {
            cursor: 'initial',
            textAlign: 'initial',
            minWidth: '30vw',
        },
    });

export interface ItemPlannerEmployeePlanCellProps extends Omit<ItemPlannerEmployeeRowProps, 'numberOfWeeksToShow' | 'total'>
{
    startDate: Date;
    norm?: number;
}

export const EmployeeAllocationEditorEmployeePlanCell: React.FC<ItemPlannerEmployeePlanCellProps> =
    observer(
        ({
             specification,
             employeeAllocationParameterAssignment,
             minDate,
             maxDate,
             resource,
             startDate,
             norm,
             items,
            settingStorageKey,
         }) =>
        {
            const classes = useStyles();
            const types = useTypes();
            const total =
                useComputed(
                    () =>
                        (items ?? [])
                            .reduce(
                                (a, b) =>
                                    a + (b.getObjectValueByField<number>(types.EmployeeAllocation.Field.DurationInHours) ?? 0),
                                0
                            ),
                    [
                        items,
                        types,
                    ]
                );
            const [ isOpen, open, close ] = useSwitch(false);
            const isActive =
                useMemo(
                    () =>
                        (minDate === undefined || startDate >= startOfWeek(minDate, { weekStartsOn: 1 }))
                        && (maxDate === undefined || startDate <= startOfWeek(maxDate, { weekStartsOn: 1 })),
                    [
                        minDate,
                        maxDate,
                        startDate,
                    ]
                );
            const percentage = norm > 0 ? Math.round(total / norm * 100) : 0;
            const colorIdx = Math.round(percentage / 100 * 9);
            const color =
                percentage > 100
                    ? ColorUtils.hexToRGB(blue[900], (Math.max(percentage - 100, 70) / 100))
                    : ColorUtils.hexToRGB(blue[500], percentage / 100);

            return <TableCell
                className={classNames(classes.root, isOpen && classes.open)}
                align="center"
                colSpan={itemPlannerWeekLength}
                style={{
                    borderLeft: `1px solid ${grey[100]}`,
                    backgroundColor: isActive ? color : grey[100],
                    color: colorIdx >= 5 ? '#fff' : undefined,
                }}
                onClick={isOpen ? close : open}
            >
                {
                    total > 0 &&
                    <div>
                        {getDurationLabel(total * 1000 * 60 * 60)}&nbsp;
                        {
                            norm > 0 &&
                            <span
                                className={classes.percentage}
                                style={{
                                    color:
                                        colorIdx >= 5
                                            ? `rgba(255,255,255,0.4)`
                                            : `rgba(0,0,0,0.4)`,
                                }}
                            >
                                        / {percentage}%
                                    </span>
                        }
                    </div>
                }
                {
                    isOpen &&
                    <Dialog
                        open
                        onClose={close}
                        width="lg"
                    >
                        <UninitializedEmployeeAllocationTableEditor
                            specification={specification}
                            employeeAllocationParameterAssignment={employeeAllocationParameterAssignment}
                            resource={resource}
                            startDate={startDate}
                            norm={norm}
                            settingStorageKey={settingStorageKey}
                        />
                    </Dialog>
                }
            </TableCell>;
        }
    );
