import performAction from '../../../Entity/performAction';
import ActionSignature from '../../../Automation/ActionTemplate/Model/ActionSignature';
import AutomationDependencyContext from '../../AutomationDependencyContext';

export default async function getActionSignatureById(actionId: string,
                                                     dependencyContext: AutomationDependencyContext): Promise<ActionSignature>
{
    const result =
        await performAction<any[]>(
            undefined,
            {
                code: 'ActionTemplate.GetSignature',
                parameters: {
                    actionId: actionId
                }
            });

    return ActionSignature.fromDescriptor(result.result, dependencyContext);
}
