import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../MenuButton/MenuButton';
import Inset from '../Inset/Inset';
import Avatar from '../Relationship/Avatar/Avatar';
import Header from '../Relationship/Header/Header';
import ViewerEntityContext from '../../../Viewer/Context/ViewerEntity/ViewerEntityContext';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';
import Name from '../../Name/Name';
import OverlineLabel from '../../../../../../@Future/Component/Generic/Label/Variant/Overline/OverlineLabel';
import Fields from '../../../Fields/Fields';
import useToggle from '../../../../../../@Util/Toggle/useToggle';
import useRelatedEntity from '../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import Click from '../Click/Click';
import { useInitializedEntityForViewer } from '../../../Viewer/Api/useInitializedEntityForViewer';
import ToggleFieldsIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/ToggleFieldsIconButton/ToggleFieldsIconButton';

export interface EventParticipationProps
{
    entity: Entity;
}

const EventParticipation: React.FC<EventParticipationProps> =
    props =>
    {
        const types = useTypes();

        const activity =
            useRelatedEntity(
                props.entity,
                types.Activity.Event.RelationshipDefinition.Participations,
                true);

        const relationship =
            useRelatedEntity(
                props.entity,
                types.EventParticipation.RelationshipDefinition.Relationship,
                false);

        const viewerEntity = useContext(ViewerEntityContext);

        const isViewingFromRelationship =
            useComputed(
                () =>
                    equalsEntity(
                        relationship,
                        viewerEntity),
                [
                    relationship,
                    viewerEntity
                ]);

        useInitializedEntityForViewer(props.entity);

        const [ isFieldsOpen, toggleFieldsOpen ] = useToggle(false);

        if (isViewingFromRelationship && activity)
        {
            return <Click
                open
            >
                <Inset>
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                    >
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={0}
                            >
                                <ViewGroupItem>
                                    <Avatar
                                        entity={activity}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <div>
                                        <OverlineLabel>
                                            {activity.entityType.getName()}
                                        </OverlineLabel>
                                    </div>
                                    <Name
                                        entity={activity}
                                        link
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <ToggleFieldsIconButton
                                        toggleFieldsOpen={toggleFieldsOpen}
                                        isFieldsOpen={isFieldsOpen}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <MenuButton
                                        entity={props.entity}
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                        {
                            isFieldsOpen &&
                                <ViewGroupItem>
                                    <Fields
                                        entity={props.entity}
                                    />
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                </Inset>
            </Click>;
        }
        else if (relationship)
        {
            return <Click
                open
            >
                <Inset>
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                    >
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={0}
                            >
                                <ViewGroupItem>
                                    <Avatar
                                        entity={relationship}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <Header
                                        entity={relationship}
                                        pathFromRelatedEntity={undefined}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <ToggleFieldsIconButton
                                        toggleFieldsOpen={toggleFieldsOpen}
                                        isFieldsOpen={isFieldsOpen}
                                    />

                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <MenuButton
                                        entity={props.entity}
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                        {
                            isFieldsOpen &&
                                <ViewGroupItem>
                                    <Fields
                                        entity={props.entity}
                                    />
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                </Inset>
            </Click>;
        }
        else
        {
            return <span>{props.entity.name}</span>;
        }
    };

export default observer(EventParticipation);
