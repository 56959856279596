import React, { useEffect } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import HeaderContext from '../../../../Item/Context/HeaderContext';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../../Type/Api/useTypes';
import TypeAndName from '../../../Shared/TypeAndName/TypeAndName';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { EntityExpansionBuilder } from '../../../../Selection/Builder/EntityExpansionBuilder';
import HeaderLink from '../../../Shared/HeaderLink/HeaderLink';
import MenuButton from '../../../Shared/MenuButton/MenuButton';
import Pipeline from '../../../../Workflow/Pipeline/Pipeline';
import Automator from '../../Automation/Automator/Automator';

export interface HeaderProps
{
    entity: Entity;
}

const Header: React.FC<HeaderProps> =
    props =>
    {
        const types = useTypes();

        const relatedParentPaths =
            useComputed(
                () =>
                    [ true, false ]
                        .map(
                            isParent =>
                                props.entity.entityType.getInheritedRelationshipDefinitions(isParent)
                                    .filter(
                                        relationshipDefinition =>
                                            !relationshipDefinition.isPlural(isParent)
                                            && !types.Entity.Type.isA(relationshipDefinition.getEntityType(!isParent))
                                            && !relationshipDefinition.getEntityType(isParent).isA(types.Datastore.Type))
                                    .map(
                                        relationshipDefinition =>
                                            EntityPath.fromEntity(props.entity)
                                                .joinTo(
                                                    relationshipDefinition,
                                                    isParent)))
                        .reduce((a, b) => a.concat(b), []),
                [
                    types,
                    props.entity
                ]);

        useEffect(
            () =>
            {
                new EntityExpansionBuilder(
                    props.entity.entityType,
                    [
                        props.entity
                    ],
                    relatedParentPaths)
                    .expand();
            },
            [
                props.entity,
                relatedParentPaths
            ]);

        const relatedParentEntities =
            useComputed(
                () =>
                    relatedParentPaths
                        .map(
                            path =>
                                path.traverseEntity(props.entity))
                        .reduce((a, b) => a.concat(b), []),
                [
                    relatedParentPaths,
                    props.entity
                ]);

        return <HeaderContext.Provider
            value={true}
        >
            <Card
                inset
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={5}
                                >
                                    <ViewGroupItem>
                                        <TypeAndName
                                            entity={props.entity}
                                        />
                                    </ViewGroupItem>
                                    {
                                        relatedParentEntities.length > 0 &&
                                            <ViewGroupItem>
                                                <ViewGroup
                                                    orientation="horizontal"
                                                    spacing={5}
                                                >
                                                    {
                                                        relatedParentEntities.map(
                                                            parentEntity =>
                                                                <ViewGroupItem
                                                                    key={parentEntity.uuid}
                                                                >
                                                                    <HeaderLink
                                                                        entity={parentEntity}
                                                                    />
                                                                </ViewGroupItem>)
                                                    }
                                                </ViewGroup>
                                            </ViewGroupItem>
                                    }
                                    </ViewGroup>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={10}
                                    alignment="center"
                                >
                                    <ViewGroupItem>
                                        <Automator
                                            entity={props.entity}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <MenuButton
                                            entity={props.entity}
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Pipeline
                            entity={props.entity}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </Card>
        </HeaderContext.Provider>;
    };

export default observer(Header);
