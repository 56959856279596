import React from 'react';
import IconButton, { IconButtonProps } from '../Icon/IconButton';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface SortIconButtonProps extends Partial<IconButtonProps>
{
    isAscendingSort: boolean;
}

const SortIconButton: React.FC<SortIconButtonProps> =
    props =>
    {
        return <IconButton
            icon={props.isAscendingSort ? 'arrow_downward' : 'arrow_upward'}
            tooltip={
                props.isAscendingSort
                    ?   <LocalizedText
                            code="View.Column.SortAscending"
                            value="Sorteer oplopend"
                        />
                    :   <LocalizedText
                            code="View.Column.SortDescending"
                            value="Sorteer aflopend"
                        />
            }
            onClick={props.onClick}
            {...props}
        />
    };

export default SortIconButton;
