import { EntityConstraintBuilderFilterContext } from './Model/EntityConstraintBuilderFilterContext';
import { ConstraintNode } from '../../../../../../@Api/Model/Implementation/ConstraintNode';
import { CompositeConstraintNode, LogicalOperator } from '../../../../../../@Api/Model/Implementation/CompositeConstraintNode';
import CompositePredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { buildConstraintNode } from './buildConstraintNode';

export function buildCompositeConstraintNode(
    filter: CompositePredicate,
    context: EntityConstraintBuilderFilterContext
): ConstraintNode
{
    const childConstraintNodes: ConstraintNode[] = [];

    filter.predicates.forEach(
        childPredicate =>
        {
            let childConstraintNode =
                buildConstraintNode(
                    childPredicate,
                    {
                        ...context,
                        isInAnd: filter.operator === LogicalOperator.And
                    }
                );

            if (childConstraintNode)
            {
                childConstraintNodes.push(childConstraintNode);
            }
        }
    );

    if (childConstraintNodes.length === 0)
    {
        return undefined;
    }
    else
    {
        return new CompositeConstraintNode(
            filter.operator,
            childConstraintNodes
        );
    }
}
