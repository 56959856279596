import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import LabelButtonLayout from '../../../../../@Api/Layout/Type/LabelButtonLayout';
import { dangerColor, primaryColor, secondaryColor, successColor, warningColor } from '../../../../../@Resource/Theme/Theme';
import LabelButton from '../../../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import FormLayoutContexts from '../Form/FormLayoutContexts';
import LayoutContext from '../../../../../@Api/Layout/LayoutContext';

export interface LabelButtonLayoutViewerProps extends LayoutViewerProps<LabelButtonLayout>
{
    onClick?: () => void;
    doNotDisable?: boolean;
}

const LabelButtonLayoutViewer: React.FC<LabelButtonLayoutViewerProps> =
    props =>
    {
        const formLayoutContexts = useContext(FormLayoutContexts);
        const click =
            useCallback(
                () =>
                    props.layout.action.apply(
                        new LayoutContext(
                            props.parameterDictionary,
                            props.parameterAssignment,
                            props.commitContext,
                            props.safeMode,
                            formLayoutContexts
                        )
                    ),
                [
                    props.layout,
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.safeMode,
                    formLayoutContexts
                ]);

        const isFilled =
            useComputed(
                () =>
                    props.layout.variant === 'Contained',
                [
                    props.layout
                ]);

        const color =
            useComputed(
                () =>
                {
                    switch (props.layout.color)
                    {
                        default:
                        case 'Primary':
                            return primaryColor;

                        case 'Secondary':
                            return secondaryColor;

                        case 'Success':
                            return successColor;

                        case 'Danger':
                            return dangerColor;

                        case 'Warning':
                            return warningColor;
                    }
                },
                [
                    props.layout
                ]);

        const context =
            useComputed(
                () =>
                    new FunctionContext(
                        props.parameterDictionary,
                        props.parameterAssignment,
                        props.commitContext
                    ),
                [
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        const isDisabled =
            useComputed(
                () =>
                    !props.doNotDisable && props.layout.isDisabled?.synchronouslyEvaluate(context),
                [
                    props.doNotDisable,
                    props.layout,
                    context
                ]);

        return <LabelButton
            label={
                props.children ||
                    <LayoutViewer
                        {...props}
                        layout={props.layout.label}
                    />
            }
            onClick={props.onClick || click}
            filled={isFilled}
            color={color}
            hoverColor={color}
            disabled={isDisabled}
        />;
    };

export default observer(LabelButtonLayoutViewer);
