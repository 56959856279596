import { Entity } from "../../../../../../../@Api/Model/Implementation/Entity";
import { CommitContext } from "../../../../../../../@Api/Entity/Commit/Context/CommitContext";
import React from "react";
import { observer } from "mobx-react-lite";
import ExpansionGroup from "../../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup";
import ViewGroup from "../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup";
import ViewGroupItem from "../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem";

import { ApsisFormResults } from "./ApsisFormResults";

export interface ApsisFormProps
{
    apsisForm: Entity;
    commitContext?: CommitContext
}
export const ApsisForm: React.FC<ApsisFormProps> = observer(
    (
        {
            apsisForm,
            commitContext
        }
    ) =>
    {
        return <ExpansionGroup>
            <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <ApsisFormResults
                        apsisForm={apsisForm}
                    />
                </ViewGroupItem>
            </ViewGroup>
        </ExpansionGroup>
    }
);