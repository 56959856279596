import React, { Fragment } from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import BlockOptionsPanel from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Settings/BlockOptionsPanel';
import ColorOptionsPanel from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Settings/ColorOptionsPanel';
import { ContentLoaderProductLines } from '../../../../../Generic/ContentLoader/ContentLoaderProductLines';
import ProductLinesOptions from './Options/ProductLinesOptions';
import GutenbergBlockType from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Gutenberg/GutenbergBlockType';
import { ProductLinesBlockId } from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/DocumentEditor';
import getTypes from '../../../Type/Api/getTypes';
import Icon from '../../../../../../@Future/Component/Generic/Icon/Icon';

const ProductLinesBlock: GutenbergBlockType =
    {
        name: ProductLinesBlockId,
        configuration:
            {
                title: {
                    code: 'GutenbergBlock.Productlines',
                    value: 'Productregels'
                },
                icon: <Icon icon="shopping_basket"/>,
                category: 'common',

                attributes:
                    {
                        content:
                            {
                                type: 'string',
                                source: 'html',
                                selector: 'p',
                                query: undefined,
                            },
                    },

                edit: props =>
                {
                    const {
                        backgroundColor,
                        borderColor,
                        borderWidth,
                        padding,
                        margin
                    } = props.attributes as any;

                    const containerStyles = {
                        padding: margin,
                        backgroundColor: backgroundColor ? backgroundColor : '#f9f9f9'
                    };

                    const innerContainerStyles = {
                        backgroundColor: backgroundColor ? backgroundColor : undefined,
                        borderWidth: borderWidth ? borderWidth : undefined,
                        borderColor: borderWidth && borderColor ? borderColor : undefined,
                        borderStyle: borderWidth && borderColor ? 'solid' : undefined,
                        padding: padding,
                        paddingTop: padding ? undefined : 10
                    };

                    const types = getTypes();

                    return (
                        <Fragment>
                            <InspectorControls>
                                <ProductLinesOptions props={props} open />
                                <BlockOptionsPanel props={props} />
                                <ColorOptionsPanel props={props} />
                            </InspectorControls>
                            <div
                                style={containerStyles}
                            >
                                <div
                                    style={innerContainerStyles}
                                >
                                    <div
                                        style={{
                                            paddingLeft: '30%',
                                            paddingRight: '30%',
                                            textAlign: 'center',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        [ {types.ProductLine.Type.getName(true)} ]
                                        <ContentLoaderProductLines />
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    );
                },

                save: props =>
                {
                    return <p>
                        {(props.attributes as any).content}
                    </p>;
                }
            },

        view: block =>
        {
            return <div />;
        }
    };

export default ProductLinesBlock;
