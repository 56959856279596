import * as React from 'react';
import { observer } from 'mobx-react';
import { primaryColor } from '../../../../@Resource/Theme/Theme';
import ViewGroup from '../ViewGroup/ViewGroup';
import ViewGroupItem from '../ViewGroup/ViewGroupItem';
import Menu from '../Menu/Menu';
import Item from '../Menu/Item/Item';
import MenuButton from '../Button/Variant/Menu/MenuButton';
import { downloadApiFile } from '../../../../@Service/ApiClient/Hooks/downloadApiFile';
import { DownloadOption } from './model/DownloadOption';
import LocalizedText from '../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

interface PdfViewerDownloadMenuProps
{
    options: DownloadOption[]
}

export const PdfViewerDownloadMenu: React.FC<PdfViewerDownloadMenuProps> = observer(
    ({
        options
     }) =>
    {
        if (options.length === 0)
        {
            return null
        }
        else if (options.length === 1)
        {
            return <MenuButton
                color={primaryColor}
                icon="get_app"
                label={<LocalizedText
                    code="Generic.Download"
                    value="Downloaden"
                />}
                onClick={() => downloadApiFile(options[0].url)}
            />
        }
        else
        {
            return <MenuButton
                color={primaryColor}
                icon="get_app"
                label={<LocalizedText
                    code="Generic.Download"
                    value="Downloaden"
                />}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    {
                        options
                            .map(
                                option => <ViewGroupItem>
                                    <Menu>
                                        <Item
                                            name={option.label}
                                            onClick={() => downloadApiFile(option.url)}
                                        />
                                    </Menu>
                                </ViewGroupItem>
                            )
                    }
                </ViewGroup>
            </MenuButton>
        }

    }
)
