import * as React from 'react';
import { observer } from 'mobx-react';
import { Manager } from 'react-popper';

@observer
export class PopperManager extends React.Component
{
    render()
    {
        const children = this.props.children;

        return <Manager>
            {children}
        </Manager>;
    }
}
