import React, { useCallback, useMemo } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../Type/Api/useTypes';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import CardHeader from '../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../../Input/Input';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { SelectboxProps } from '../../../../Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import { withValue } from '../../../../../../../@Util/Functional/withValue';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import { useExpansion } from '../../../../Selection/Api/useExpansion';

export interface RightColumnProps
{
    entity: Entity;
}

const Labels: React.FC<RightColumnProps> =
    ({
        entity
    }) =>
    {
        const types = useTypes();

        const searchFieldPaths =
            useMemo(
                () => [
                    EntityPath.fromEntityType(types.Label.Type)
                        .field(types.Label.Field.Name),
                    EntityPath.fromEntityType(types.Label.Type)
                        .joinTo(
                            types.LabelCategory.RelationshipDefinition.Labels,
                            true)
                        .field(types.LabelCategory.Field.Name)
                ],
                [
                    types
                ]);

        const formatLabel =
            useCallback(
                entity =>
                {
                    const category =
                        entity.getRelatedEntityByDefinition(
                            true,
                            types.LabelCategory.RelationshipDefinition.Labels);

                    return <span>
                        {
                            category?.name
                        }
                    </span>;
                },
                [
                    types
                ]);

        const relationshipSelectboxProps =
            useMemo<Partial<SelectboxProps>>(
                () =>
                    withValue(
                        EntityPath.fromEntityType(types.Label.Type),
                        rootPath =>
                            ({
                                disableConstruction: false,
                                selections: [
                                    new EntitySelectionBuilder(rootPath.entityType)
                                        .join(
                                            rootPath.joinTo(
                                                types.LabelCategory.RelationshipDefinition.Labels,
                                                true))
                                        .where(
                                            cb =>
                                                cb.eq(
                                                    rootPath.field(types.Label.Field.IsInactive),
                                                    undefined,
                                                    false
                                                )
                                        )
                                        .where(
                                            cb =>
                                                cb.eq(
                                                    rootPath
                                                        .joinTo(
                                                            types.LabelCategory.RelationshipDefinition.Labels,
                                                        true)
                                                        .field(types.LabelCategory.Field.IsInactive),
                                                    undefined,
                                                    false
                                                )
                                        )
                                        .build()
                                ],
                                formatCaption: formatLabel,
                                searchFieldPaths: searchFieldPaths
                            })),
                [
                    types
                ]);

        const [ isLoading ] =
            useExpansion(
                entity.entityType,
                rootPath => [
                    rootPath
                        .joinTo(
                            types.Entity.RelationshipDefinition.Labels,
                            false)
                        .joinTo(
                            types.LabelCategory.RelationshipDefinition.Labels,
                            true)
                ],
                () => [ entity ],
                [
                    entity,
                    types
                ]);

        return <Card
            inset
        >
            <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                <ViewGroupItem>
                    <CardHeader>
                        <LocalizedText
                            code="Generic.Labels"
                            value="Labels"
                        />
                    </CardHeader>
                </ViewGroupItem>
                <ViewGroupItem>
                    {
                        isLoading
                            ?
                                <Centered
                                    horizontal
                                >
                                    <Loader />
                                </Centered>
                            :
                                <Input
                                    labelPosition="none"
                                    entity={entity}
                                    field={types.Entity.RelationshipDefinition.Labels}
                                    relationshipSelectboxProps={relationshipSelectboxProps}
                                />
                    }
                </ViewGroupItem>
            </ViewGroup>
        </Card>;
    };

export default observer(Labels);
