import React from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { default as GenericPipeline } from '../../../../../../../../@Future/Component/Generic/Pipeline/Pipeline';
import Phase from '../../../../../../../../@Future/Component/Generic/Pipeline/Phase/Phase';
import { observer, useComputed } from 'mobx-react-lite';
import useVisitedStates from './Api/useVisitedStates';
import useTypes from '../../../../../Type/Api/useTypes';
import { green, grey, red } from '@material-ui/core/colors';
import moment from 'moment';
import { createNumberComparator } from '../../../../../../../Generic/List/V2/Comparator/NumberComparator';
import { primaryColor, textSecondaryColor } from '../../../../../../../../@Resource/Theme/Theme';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import styles from './MilestonePipeline.module.scss';
import Tooltip from '../../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import CloseStateButton from '../../../../../Button/CloseStateButton/CloseStateButton';
import useWorkflowStates from '../../../../../Workflow/Api/useWorkflowStates';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';

export interface WorkflowPipelineProps
{
    entity?: Entity;
}

const MilestonePipeline: React.FC<WorkflowPipelineProps> =
    props =>
    {
        const types = useTypes();
        const milestones =
            useComputed(
                () =>
                    props.entity
                        .getRelatedEntitiesByDefinition(
                            false,
                            types.Activity.Project.RelationshipDefinition.Milestones)
                        .sort(createNumberComparator(d => d.getObjectValueByField(types.Entity.Field.SortIndex))),
                [
                    props.entity,
                    types
                ]);

        const isDisabled = true;
        // const states = useWorkflowStates(props.entityType || props.entity.entityType, false);
        // const selectedWorkflowStateIndex = useSelectedPipelineStateIndex(props.entity, states);
        // const onPhaseSelected = usePhaseSelectionCallback(props.entity, states);
        const visitedStateUuids =
            new Set<string>(
                useVisitedStates(props.entity)
                    .map(
                        entity =>
                            entity.uuid));

        const firstUnfinishedPhaseIdx =
            milestones.findIndex(
                milestone =>
                    !milestone.getObjectValueByField(types.Milestone.Field.IsFinished));

        const closedStates = useWorkflowStates(props.entity.entityType, true);

        if (milestones.length === 0)
        {
            return null;
        }
        else
        {
            return <GenericPipeline
                disabled={isDisabled}
                onPhaseSelected={() => {}}
            >
                {
                    milestones.map(
                        (milestone, idx) =>
                        {
                            const isFinished = milestone.getObjectValueByField(types.Milestone.Field.IsFinished);
                            const endDate = milestone.getDataObjectValueByField(types.Milestone.Field.EndDate);
                            const isCurrent = firstUnfinishedPhaseIdx === idx;
                            const isOverdue = moment(endDate.value).startOf('day').isBefore(moment().startOf('day'));
                            const numberOfDays = moment(endDate.value).startOf('day').diff(moment(), 'day');
                            const numberOfWeeks = Math.round(numberOfDays / 7);

                            return <Phase
                                key={milestone.uuid}
                                name={
                                    <Tooltip
                                        title={
                                            endDate.isEmpty
                                            ?
                                                <LocalizedText
                                                    code="Projects.MilestoneNotPlanned"
                                                    value="Milestone is niet ingepland."
                                                />
                                            :
                                                isOverdue
                                                    ?
                                                        numberOfWeeks > 0
                                                            ?
                                                                <LocalizedText
                                                                    code="Projects.MilestoneOverdueWeeks"
                                                                    value="Opleverdatum is ${numberOfWeeks} weken geleden op ${endDate} verstreken."
                                                                    numberOfWeeks={Math.abs(numberOfWeeks)}
                                                                    endDate={endDate.toString()}
                                                                />
                                                            :
                                                                <LocalizedText
                                                                    code="Projects.MilestoneOverdueDays"
                                                                    value="Opleverdatum is ${numberOfDays} dagen geleden op ${endDate} verstreken."
                                                                    numberOfDays={Math.abs(numberOfDays)}
                                                                    endDate={endDate.toString()}
                                                                />
                                                    :
                                                        numberOfWeeks > 0
                                                            ?
                                                                <LocalizedText
                                                                    code="Projects.MilestoneDeliveryDateWeeks"
                                                                    value="Opleverdatum is over ${numberOfWeeks} weken op ${endDate}."
                                                                    numberOfWeeks={Math.abs(numberOfWeeks)}
                                                                    endDate={endDate.toString()}
                                                                />
                                                            :
                                                                <LocalizedText
                                                                    code="Projects.MilestoneDeliveryDateDays"
                                                                    value="Opleverdatum is over ${numberOfDays} dagen op ${endDate}."
                                                                    numberOfDays={Math.abs(numberOfDays)}
                                                                    endDate={endDate.toString()}
                                                                />
                                        }
                                        block
                                    >
                                        <ViewGroup
                                            orientation="horizontal"
                                            spacing={10}
                                            alignment="center"
                                        >
                                            <ViewGroupItem
                                                ratio={1}
                                            >
                                                {milestone.name}
                                            </ViewGroupItem>
                                            {
                                                !endDate.isEmpty &&
                                                    <ViewGroupItem>
                                                        <div
                                                            className={styles.deadline}
                                                        >
                                                            {numberOfWeeks > 0 ? `${numberOfWeeks}w` : `${numberOfDays}d`}
                                                        </div>
                                                    </ViewGroupItem>
                                            }
                                        </ViewGroup>
                                    </Tooltip>}
                                visited={visitedStateUuids.has(milestone.uuid)}
                                current={idx === 0}
                                color={
                                    isFinished
                                        ?
                                            green[500]
                                        :
                                            isOverdue
                                                ?
                                                    red[500]
                                                :
                                                    isCurrent
                                                        ?
                                                            primaryColor
                                                        :
                                                            grey[100]}
                                fontColor={isFinished || isOverdue || isCurrent ? 'white' : textSecondaryColor}
                                phaseComponent={
                                    idx === milestones.length - 1 &&
                                    closedStates &&
                                    closedStates.length > 0 &&
                                    <CloseStateButton
                                        entityType={props.entity.entityType}
                                        entity={props.entity}
                                    />
                                }
                            />;
                        })
                }
            </GenericPipeline>;
        }
    };

export default observer(MilestonePipeline);
