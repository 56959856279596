import React, { useMemo } from 'react';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../../@Resource/Theme/Theme';
import ViewGroup from '../ViewGroup/ViewGroup';
import ViewGroupItem from '../ViewGroup/ViewGroupItem';

export interface MasonryLayoutProps
{
    spacing?: number;
    laneDifference?: number;
}

const MasonryLayout: React.FC<MasonryLayoutProps> =
    props =>
    {
        const difference = useMemo(
            () =>
                props.laneDifference !== undefined
                    ? props.laneDifference
                    : 0,
            [
                props
            ]
        );

        const children = React.Children.toArray(props.children);
        const is1Lane = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
        const is2Lanes = useMediaQuery(theme.breakpoints.only('md'));
        const is3Lanes = useMediaQuery(theme.breakpoints.only('lg'));
        const is4Lanes = useMediaQuery(theme.breakpoints.only('xl'));
        const numLanes =
            useMemo(
                () =>
                {
                    if (is1Lane)
                    {
                        return 1;
                    }
                    else if (is2Lanes)
                    {
                        return Math.max(1, 2 + difference);
                    }
                    else if (is3Lanes)
                    {
                        return Math.max(1, 3 + difference);
                    }
                    else if (is4Lanes)
                    {
                        return Math.max(1, 4 + difference);
                    }
                    else
                    {
                        return 1;
                    }
                },
                [
                    is1Lane,
                    is2Lanes,
                    is3Lanes,
                    is4Lanes
                ]);

        const lanes =
            useMemo(
                () =>
                {
                    const lanes = new Array(numLanes);

                    for (let idx = 0; idx < numLanes; idx++)
                    {
                        lanes[idx] = [];
                    }

                    let idx = 0;

                    for (const child of children)
                    {
                        lanes[idx].push(child);

                        idx += 1;

                        if (idx === numLanes)
                        {
                            idx = 0;
                        }
                    }

                    return lanes;
                },
                [
                    children,
                    numLanes
                ])

        return <ViewGroup
            orientation="horizontal"
            spacing={props.spacing}
        >
            {
                lanes.map(
                    (lane, laneIdx) =>
                        <ViewGroupItem
                            key={laneIdx}
                            ratio={1}
                        >
                            <ViewGroup
                                orientation="vertical"
                                spacing={props.spacing}
                            >
                                {
                                    lane
                                        .map(
                                            (child, idx) =>
                                                <ViewGroupItem
                                                    key={idx}
                                                >
                                                    {child}
                                                </ViewGroupItem>)
                                }
                            </ViewGroup>
                        </ViewGroupItem>
                )
            }
        </ViewGroup>;
    };

MasonryLayout.defaultProps = {
    spacing: 16
}

export default MasonryLayout;
