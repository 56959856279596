import { ListGroupStore } from '../ListGroupStore';
import { ListItemStore, ListItemStoreProps } from '../Item/ListItemStore';

export function hasYearsOutsideCurrentYear(groupStores: Array<ListGroupStore<unknown, ListItemStore<unknown, ListItemStoreProps<unknown>>, Date>>): boolean
{
    const currentYear = new Date().getFullYear();

    for (const groupStore of groupStores)
    {
        if (groupStore.value !== undefined)
        {
            const year = groupStore.value.getFullYear();

            if (currentYear !== year)
            {
                return true;
            }
        }
    }

    return false;
}
