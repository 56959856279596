import React from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import TypeImport from '../../../TypeImport/TypeImport';

export interface ImportProps
{
    entity: Entity;
}

const Import: React.FC<ImportProps> =
    props =>
    {
        return <TypeImport
            entity={props.entity}
        />;
    };

export default observer(Import);
