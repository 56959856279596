import { DependencyList, useCallback, useEffect, useMemo, useState } from 'react';
import { loadModuleDirectly } from '../DependencyInjection/index';
import { FeedbackStore } from '../../@Component/App/Root/Environment/Organization/Feedback/FeedbackStore';
import getErrorMessage from '../../@Api/Error/getErrorMessage';
import isUserError from '../../@Api/Error/isUserError';

export type AsyncResult<T> = [
    T | undefined,
    boolean,
    (values: T) => void,
];

export default function useAsyncResult<T>(
    promiser: () => Promise<T>,
    deps: DependencyList,
    doLoad: boolean = true
): AsyncResult<T>
{
    const [ result, setResult ] = useState<T | undefined>(undefined);
    const [ isLoading, setLoading ] = useState<boolean>(doLoad);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const promiserCallback = useCallback(promiser, deps);

    useEffect(
        () =>
        {
            let isActive = true;

            if (doLoad)
            {
                setLoading(true);

                const cb = promiserCallback();

                if (cb)
                {
                    cb
                        .then(
                            result =>
                            {
                                if (isActive)
                                {
                                    setResult(result);
                                }
                            })
                        .catch(
                            error =>
                            {
                                if (isUserError(error))
                                {
                                    loadModuleDirectly(FeedbackStore)
                                        .enqueueSnackbar(
                                            getErrorMessage(error),
                                            {
                                                variant: 'error',
                                                autoHideDuration: 5000
                                            });
                                }
                                else
                                {
                                    return Promise.reject(error);
                                }
                            })
                        .finally(
                            () =>
                            {
                                if (isActive)
                                {
                                    setLoading(false);
                                }
                            }
                        );
                }
                else
                {
                    setLoading(false);
                }
            }
            else
            {
                setLoading(false);
                setResult(undefined);
            }

            return () =>
            {
                isActive = false;
            }
        },
        [
            doLoad,
            setLoading,
            promiserCallback,
            setResult
        ]);

    return useMemo(
        () => [
            result,
            isLoading,
            setResult,
        ],
        [
            result,
            isLoading,
            setResult,
        ]
    );
}
