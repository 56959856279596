import { useEffect, useRef } from 'react';

function usePrevious(value) {
    // See https://blog.logrocket.com/accessing-previous-props-state-react-hooks/
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    },[value]);
    return ref.current;
}

export default usePrevious;