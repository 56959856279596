import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import useTypes from '../../../../Type/Api/useTypes';
import RelatedList from '../../../Shared/RelatedList/RelatedList';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface ChildAccountListProps
{
    entity: Entity;
}

const ChildAccountList: React.FC<ChildAccountListProps> =
    props =>
    {
        const types = useTypes();
        const path =
            useComputed(
                () =>
                    types.Relationship.RelationshipDefinition.ChildAccounts &&
                        EntityPath.fromEntity(props.entity)
                            .joinTo(
                                types.Relationship.RelationshipDefinition.ChildAccounts,
                                false),
                [
                    types,
                    props.entity
                ]);

        const fieldPathFilter =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    !(fieldPath.relationshipDefinition === types.Relationship.RelationshipDefinition.ChildAccounts
                        && fieldPath.isParentRelationship),
                [
                    types
                ]);

        const isMasterAccount = useEntityValue(props.entity, types.Relationship.Field.IsMasterAccount);

        if (isMasterAccount && path)
        {
            return <RelatedList
                entity={props.entity}
                path={path}
                fieldPathFilter={fieldPathFilter}
                title={types.Relationship.RelationshipDefinition.ChildAccounts.getName(false)}
                constructorLabel={
                    <LocalizedText
                        code="Generic.ChildAccount"
                        value="Dochteraccount"
                    />
                }
                hideAddButton
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(ChildAccountList);
