import React, { useCallback, useEffect, useMemo } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityField } from '../../../../../../@Api/Model/Implementation/EntityField';
import getExpressionContextByTemplateType from './Api/getExpressionContextByTemplateType';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import getNewComputationFromExpression from '../../../../Expression/Api/getNewComputationFromExpression';
import { isExpressionSpecification } from '../../../../Expression/Api/isExpressionSpecification';
import getComputationFromDescriptor from '../../../../../../@Api/Automation/Api/getComputationFromDescriptor';
import Computation from '../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationEditor from '../Automation/Editor/Computation/ComputationEditor';
import PrimitiveValueType from '../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import { useDelayedValue } from '../../../../../../@Future/Util/Hook/useDelayedValue';
import TextComputation from '../../../../../../@Api/Automation/Function/Computation/TextComputation';

export interface EmailSubjectTemplateFieldProps
{
    entity: Entity;
    field: EntityField;
    commitContext?: CommitContext;
}

const EmailSubjectTemplateField: React.FC<EmailSubjectTemplateFieldProps> =
    props =>
    {
        const [ context ] =
            useAsyncResult(
                () =>
                    getExpressionContextByTemplateType(
                        props.entity.entityType,
                        props.field,
                        props.commitContext
                    ).then(
                        result => result.entityContext.toFunctionContext()
                    ),
                [
                    props.entity,
                    props.field,
                    props.commitContext,
                ]);
        const descriptor =
            useMemo(
                () =>
                    props.entity.getObjectValueByField(
                        props.field,
                        props.commitContext
                    ),
                [
                    props.entity,
                    props.commitContext,
                ]);
        const [ computation ] =
            useAsyncResult(
                async () =>
                {
                    if (descriptor)
                    {
                        if (isExpressionSpecification(descriptor))
                        {
                            return getNewComputationFromExpression(
                                descriptor,
                                context,
                                false
                            );
                        }
                        else
                        {
                            return getComputationFromDescriptor(
                                descriptor,
                                context
                            );
                        }
                    }
                    else
                    {
                        return new TextComputation(
                            '',
                            false,
                            []
                        );
                    }
                },
                [
                    descriptor,
                    context,
                ]
            );
        const setComputation =
            useCallback(
                (computation: Computation<any, any>) =>
                {
                    if (computation.isValid())
                    {
                        setValueByFieldInEntity(
                            props.entity,
                            props.field,
                            computation.toDescriptor(),
                            props.commitContext
                        );

                        return commitEntityWithContext(
                            props.entity,
                            props.commitContext,
                            {
                                isForced: true
                            }
                        );
                    }
                },
                [
                    props.entity,
                    props.field,
                    props.commitContext,
                ]);
        const newDescriptor =
            useComputed(
                () =>
                    computation?.isValid()
                        ?
                            computation?.toDescriptor()
                        :
                            undefined,
                [
                    computation
                ]
            );
        const delayedNewDescriptor =
            useDelayedValue(
                newDescriptor
            );
        useEffect(
            () =>
            {
                if (computation)
                {
                    setComputation(computation);
                }
            },
            [
                computation,
                setComputation,
                delayedNewDescriptor,
            ]
        );
        const textType =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        DataObject.getTypeById('Text')
                    ),
                []
            );

        return <Card
            inset
        >
            <Input
                labelPosition="top"
                label={props.field.name}
            >
                <ComputationEditor
                    value={computation}
                    onChange={setComputation}
                    context={context}
                    type={textType}
                    disallowRichText
                    fixedType
                />
            </Input>
        </Card>;
    };

export default observer(EmailSubjectTemplateField);
