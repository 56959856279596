import { getSearchTermsFromQuery } from '../../../../../../../@Util/Search/getSearchTermsFromQuery';
import PrimitiveValueType from '../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { isPrimitiveTypeSearchable } from '../../../../../../../@Api/Automation/Api/isPrimitiveTypeSearchable';
import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import Predicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { isPrimitiveTypeSearchableForKeyword } from '../../../../../../../@Api/Automation/Api/isPrimitiveTypeSearchableForKeyword';
import ComparisonPredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import { Comparator } from '../../../../../DataObject/Model/Comparator';
import PrimitiveValue from '../../../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import CompositePredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../../DataObject/Model/LogicalOperator';

export function getSearchPredicateByQueryAndParameters(
    query: string,
    parameters: Parameter<any>[]
): Predicate | undefined
{
    if (query && query.length > 0)
    {
        const keywords = getSearchTermsFromQuery(query);
        const searchParameters =
            parameters
                .filter(
                    parameter =>
                        parameter.type instanceof PrimitiveValueType
                        && isPrimitiveTypeSearchable(parameter.type.type)
                )
                .map(
                    parameter =>
                        parameter as Parameter<PrimitiveValueType>
                );

        if (keywords.length === 0 || searchParameters.length === 0)
        {
            return undefined;
        }
        else
        {
            const predicates: Predicate[] = [];

            for (const keyword of keywords)
            {
                const keywordPredicates: Predicate[] = [];

                for (const searchParameter of searchParameters)
                {
                    if (isPrimitiveTypeSearchableForKeyword(searchParameter.type.type, keyword))
                    {
                        keywordPredicates.push(
                            new ComparisonPredicate(
                                searchParameter,
                                Comparator.Contains,
                                new PrimitiveValue(
                                    DataObject.constructFromTypeIdAndValue(
                                        'Text',
                                        keyword
                                    )
                                )
                            )
                        );
                    }
                }

                if (keywordPredicates.length > 0)
                {
                    predicates.push(
                        new CompositePredicate(
                            LogicalOperator.Or,
                            keywordPredicates
                        )
                    );
                }
            }

            if (predicates.length > 0)
            {
                return new CompositePredicate(
                    LogicalOperator.And,
                    predicates
                );
            }
            else
            {
                return undefined;
            }
        }
    }
    else
    {
        return undefined;
    }
}
