import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Popper from '../../Popper/Popper';
import useSwitch from '../../../../../@Util/Switch/useSwitch';
import SketchPicker from 'react-color/lib/components/sketch/Sketch';
import DeleteIconButton from '../../Button/Variant/DeleteIconButton/DeleteIconButton';
import ViewGroup from '../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';

export interface ColorEditorProps
{
    value?: string;
    onChange: (value?: string) => void;
}

const ColorEditor: React.FC<ColorEditorProps> =
    props =>
    {
        const [ isOpen, open, close ] = useSwitch(false);
        const { onChange } = props;

        const onChangeColor =
            useCallback(
                (value?: any) =>
                {
                    onChange(value.hex);
                },
                [
                    onChange
                ]);

        return <Popper
            open={isOpen}
            onClose={isOpen ? close : undefined}
            fullWidth
            reference={
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <div
                            style={{
                                flex: '1 1 auto',
                                borderRadius: 5,
                                cursor: 'pointer',
                                backgroundColor: '#fff',
                                padding: 6,
                                boxShadow: '0 0 0 1px rgba(0,0,0,.1)'
                            }}
                            onClick={open}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: 13,
                                    backgroundColor: props.value || '#fff',
                                    border: props.value ? undefined : '1px solid #f3f3f3'
                                }}
                            />
                        </div>
                    </ViewGroupItem>
                    {
                        props.value &&
                            <ViewGroupItem>
                                <DeleteIconButton
                                    onClick={
                                        () =>
                                            onChangeColor(undefined)
                                    }
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            }
            popper={
                <SketchPicker
                    color={props.value}
                    onChange={onChangeColor}
                />
            }
        />;
    };

export default observer(ColorEditor);
