import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import InsetLayout from '../../../../../@Api/Layout/Type/InsetLayout';
import InsetLayoutView from './View/InsetLayoutView';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import Layout from '../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';

export interface InsetLayoutEditorProps extends LayoutEditorProps<InsetLayout>
{

}

const InsetLayoutEditor: React.FC<InsetLayoutEditorProps> =
    props =>
    {
        const setLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.layout = layout),
                [
                    props.layout
                ]);

        return <InsetLayoutView
            layout={props.layout}
        >
            <ChildLayoutEditor
                {...props}
                childLayout={props.layout.layout}
                onChange={setLayout}
            />
        </InsetLayoutView>;
    };

export default observer(InsetLayoutEditor);
