import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../Value/PrimitiveValue';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import Value from '../../Value/Value';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import EmptyValue from '../../Value/EmptyValue';
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInMonths, differenceInQuarters, differenceInWeeks, differenceInYears } from 'date-fns';
import { DatePeriodType, DatePeriodValue } from '../../../../@Component/Domain/DataObject/Type/Date/DatePeriod/DatePeriodValue';

export default class PeriodBetweenDatesComputation extends Computation<PrimitiveValueType, PrimitiveValue>
{
    // ------------------------- Properties -------------------------

    @observable.ref startDate: Computation<any, any>;
    @observable.ref endDate: Computation<any, any>;
    @observable periodType: DatePeriodType;

    // ------------------------ Constructor -------------------------

    constructor(
        startDate: Computation<any, any>,
        endDate: Computation<any, any>,
        periodType: DatePeriodType,
    )
    {
        super();

        this.startDate = startDate;
        this.endDate = endDate;
        this.periodType = periodType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('DatePeriod')
        );
    }

    getName(): string
    {
        return 'Periode tussen datums...';
    }

    isAsync(): boolean
    {
        return this.startDate.isAsync()
            || this.endDate.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const [ startDate, endDate ] =
            await Promise.all([
                safelyApplyFunction(this.startDate, context),
                safelyApplyFunction(this.endDate, context),
            ]);

        return this.getPeriodBetweenDates(startDate, endDate);
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        const startDate = safelySynchronousApplyFunction(this.startDate, context);
        const endDate = safelySynchronousApplyFunction(this.endDate, context);

        return this.getPeriodBetweenDates(startDate, endDate);
    }

    getPeriodBetweenDates(
        startDateValue: Value<any, any>,
        endDateValue: Value<any, any>
    ): Value<any, any>
    {
        const startDate = this.getDateFromDateValue(startDateValue);
        const endDate = this.getDateFromDateValue(endDateValue);

        if (startDate === undefined || endDate === undefined)
        {
            return EmptyValue.instance;
        }
        else
        {
            const period = this.getDifferenceInPeriod(startDate, endDate);
            const periodValue: DatePeriodValue = {
                type: this.periodType,
                period,
            };

            return new PrimitiveValue(
                DataObject.constructFromTypeIdAndValue(
                    'DatePeriod',
                    periodValue
                )
            );
        }
    }

    getDateFromDateValue(date: Value<any, any>): Date | undefined
    {
        if (date instanceof PrimitiveValue
            && (date.value.type.id() === 'Date' || date.value.type.id() === 'DateTime'))
        {
            return date.value.value;
        }
        else
        {
            return undefined;
        }
    }

    getDifferenceInPeriod(
        startDate: Date,
        endDate: Date
    )
    {
        switch (this.periodType)
        {
            case 'Minutes':
                return differenceInMinutes(endDate, startDate);

            case 'Hours':
                return differenceInHours(endDate, startDate);

            case 'Days':
                return differenceInDays(endDate, startDate);

            case 'Weeks':
                return differenceInWeeks(endDate, startDate);

            case 'Months':
                return differenceInMonths(endDate, startDate);

            case 'Quarters':
                return differenceInQuarters(endDate, startDate);

            case 'Years':
                return differenceInYears(endDate, startDate);

            default:
                throw new Error('Period type not recognized to convert to period');
        }
    }

    validate(): Validation[]
    {
        if (!this.startDate)
        {
            return [
                new Validation(
                    'Error',
                    'Geen startdatum ingesteld')
            ];
        }

        if (!this.endDate)
        {
            return [
                new Validation(
                    'Error',
                    'Geen einddatum ingesteld')
            ];
        }

        return [
            ...this.startDate.validate(),
            ...this.endDate.validate(),
        ];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'PeriodBetweenDates';
        descriptor.startDate = this.startDate.toDescriptor();
        descriptor.endDate = this.endDate.toDescriptor();
        descriptor.periodType = this.periodType;
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.startDate.getDependencies(),
            ...this.endDate.getDependencies(),
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new PeriodBetweenDatesComputation(
            await getComputationFromDescriptor(
                descriptor.startDate,
                dependencyContext
            ),
            await getComputationFromDescriptor(
                descriptor.endDate,
                dependencyContext
            ),
            descriptor.periodType
        );
    }

    // ----------------------- Private logic ------------------------
}
