import React, { useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { default as GenericEntityItem } from '../../../Item/Item';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';
import Activity from './Activity/Activity';
import { NavigatorContextStore } from '../../../Item/Navigator/NavigatorContext/NavigatorContextStore';
import NavigatorContext from '../../../Item/Navigator/NavigatorContext/NavigatorContext';
import { v4 as uuid } from 'uuid';
import HorizontalInsetContext from '../../../Item/Context/HorizontalInsetContext';
import VerticalInsetContext from '../../../Item/Context/VerticalInsetContext';
import Note from './Note/Note';
import styles from './Entity.module.scss';
import { EntityEvent } from '../../../../../../@Api/Model/Implementation/EntityEvent';
import DateView from '../../../../../../@Future/Component/Generic/DateView/DateView';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';

export interface EntityProps
{
    entity: Entity;
    event: EntityEvent;
    rootEntity?: Entity;
    appendix?: React.ReactNode;
    nested?: boolean;
}

const EntityItem: React.FC<EntityProps> =
    props =>
    {
        const types = useTypes();
        const [ id ] = useState(uuid());
        const navigatorContext =
            useMemo(
                () =>
                    new NavigatorContextStore({
                        id: id
                    }),
                [
                    id
                ]);

        const isNote =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Note.Type),
                [
                    props.entity,
                    types
                ]);

        const isActivity =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.Type),
                [
                    props.entity,
                    types
                ]);

        let content;

        if (isNote)
        {
            content =
                <NavigatorContext.Provider
                    value={navigatorContext}
                >
                    <VerticalInsetContext.Provider
                        value={false}
                    >
                        <HorizontalInsetContext.Provider
                            value={false}
                        >
                            <Note
                                rootEntity={props.rootEntity}
                                entity={props.entity}
                                event={props.event}
                                nested={props.nested}
                            />
                        </HorizontalInsetContext.Provider>
                    </VerticalInsetContext.Provider>
                </NavigatorContext.Provider>;
        }
        else if (isActivity)
        {
            content =
                <NavigatorContext.Provider
                    value={navigatorContext}
                >
                    <HorizontalInsetContext.Provider
                        value={!props.nested}
                    >
                        <Activity
                            entity={props.entity}
                            appendix={props.appendix}
                            nested={props.nested}
                        />
                    </HorizontalInsetContext.Provider>
                </NavigatorContext.Provider>;
        }
        else
        {
            content =
                <GenericEntityItem
                    entity={props.entity}
                    verticalInset
                    horizontalInset={!props.nested}
                    hideParentDescription
                    hideFavoriteButton
                />;
        }

        return <div
            className={styles.root}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={10}
            >
                <ViewGroupItem
                    ratio={1}
                >
                    {content}
                </ViewGroupItem>
                {
                    props.nested &&
                        <ViewGroupItem
                            className={styles.date}
                        >
                            <DateView
                                date={props.event.date}
                                displayTime
                            />
                        </ViewGroupItem>
                }
            </ViewGroup>
        </div>;
    };

export default observer(EntityItem);
