import React, { useCallback, useMemo } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../Type/Api/useTypes';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import getLayoutFromDescriptor from '../../../../../../../@Api/Layout/Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../../../@Api/Layout/LayoutDependencyContext';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import CompositeLayout from '../../../../../../../@Api/Layout/Type/CompositeLayout';
import Layout from '../../../../../../../@Api/Layout/Layout';
import LayoutEditor from '../../../../../Layout/Editor/LayoutEditor';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import ClearButton from '../../../../../../../@Future/Component/Generic/Button/Variant/ClearButton/ClearButton';
import PortalContext from '../../../../../../../@Api/Portal/Context/PortalContext';
import HeaderWithScrollBodyLayout from '../../../../../../../@Api/Layout/Type/HeaderWithScrollBodyLayout';
import getPortalParameters from '../Api/getPortalParameters';
import { CommitBuilder } from '../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export interface PortalProps
{
    entity: Entity;
}

const PortalLayoutEditor: React.FC<PortalProps> =
    props =>
    {
        const types = useTypes();
        const layoutDescriptor = useEntityValue(props.entity, types.Portal.Field.Layout);
        const [ parameterDictionary, isLoadingParameters ] =
            useAsyncResult(
                () =>
                    getPortalParameters(props.entity),
                [
                    props.entity
                ]);
        const portalContext =
            useMemo(
                () => ({
                    portal: props.entity,
                    parameters: parameterDictionary
                }),
                [
                    props.entity,
                    parameterDictionary
                ]);
        const [ layout, isLoadingLayout ] =
            useAsyncResult<Layout>(
                async () =>
                {
                    if (layoutDescriptor && parameterDictionary)
                    {
                        return getLayoutFromDescriptor(
                            layoutDescriptor,
                            new LayoutDependencyContext(parameterDictionary));
                    }
                    else
                    {
                        return new HeaderWithScrollBodyLayout(
                            new CompositeLayout(
                                'Vertical',
                                []),
                            new CompositeLayout(
                                'Vertical',
                                []));
                    }
                },
                [
                    layoutDescriptor,
                    parameterDictionary
                ]);
        const clearLayout =
            useCallback(
                () =>
                    new CommitBuilder()
                        .setObjectValueInEntity(
                            props.entity,
                            types.Portal.Field.Layout,
                            undefined
                        )
                        .commit(),
                [
                    props.entity,
                    types,
                ]);
        const saveLayout =
            useCallback(
                () =>
                    new CommitBuilder()
                        .setObjectValueInEntity(
                            props.entity,
                            types.Portal.Field.Layout,
                            layout.toDescriptor()
                        )
                        .commit(),
                [
                    props.entity,
                    types,
                    layout,
                ]);

        if (isLoadingParameters || isLoadingLayout)
        {
            return <Centered
                horizontal
                vertical
            >
                <Loader />
            </Centered>;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <PortalContext.Provider
                        value={portalContext}
                    >
                        <LayoutEditor
                            layout={layout}
                            parameterDictionary={parameterDictionary}
                        />
                    </PortalContext.Provider>
                </ViewGroupItem>
                <ViewGroupItem>
                    <RightAlignedButtonGroup>
                        <ClearButton
                            onClick={clearLayout}
                        />
                        <SaveButton
                            onClick={saveLayout}
                        />
                    </RightAlignedButtonGroup>
                </ViewGroupItem>
            </ViewGroup>
        }
    };

export default observer(PortalLayoutEditor);
