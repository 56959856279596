import useEntityValue from '../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useTypes from '../../../../../../../Type/Api/useTypes';
import { CommitContext } from '../../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import { setValueByFieldInEntity } from '../../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';

export interface ProductLineDescriptionEditorInterface
{
    description?: string;
    extraDescription?: string;
    isExtraDescriptionVisible: boolean;
    setExtraDescriptionVisible: (visible: boolean, allowCommit?: boolean) => Promise<Entity>;
}

export default function useProductLineDescription(
    productLine: Entity,
    commitContext: CommitContext,
    autoCommit?: boolean
) : ProductLineDescriptionEditorInterface
{
    const types = useTypes();

    const description =
        useEntityValue<string | undefined>(
            productLine,
            types.ProductLine.Field.Description,
            undefined,
            commitContext
        );

    const extraDescription =
        useEntityValue<string | undefined>(
            productLine,
            types.ProductLine.Field.ExtraDescription,
            undefined,
            commitContext
        );

    const [ isExtraDescriptionVisible, _setExtraDescriptionVisible ] = useState(false);
    const setShowExtraDescription =
        useCallback(
            (showExtraDescription: boolean, allowCommit = true) =>
            {
                _setExtraDescriptionVisible(showExtraDescription);

                if (!showExtraDescription)
                {
                    setValueByFieldInEntity(
                        productLine,
                        types.ProductLine.Field.ExtraDescription,
                        undefined,
                        commitContext
                    );

                    if (allowCommit && autoCommit !== false)
                    {
                        return commitEntityWithContext(
                            productLine,
                            commitContext
                        );
                    }
                }
            },
            [
                _setExtraDescriptionVisible,
                productLine,
                autoCommit,
                commitContext,
                types
            ]);

    useEffect(
        () =>
        {
            if (extraDescription)
            {
                setShowExtraDescription(true);
            }
        },
        [
            extraDescription,
            setShowExtraDescription
        ]);

    return useMemo(
        () =>
            ({
                description: description,
                extraDescription: extraDescription,
                isExtraDescriptionVisible: isExtraDescriptionVisible,
                setExtraDescriptionVisible: setShowExtraDescription
            }),
        [
            description,
            extraDescription,
            isExtraDescriptionVisible,
            setShowExtraDescription
        ]
    );
}