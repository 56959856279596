import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Input from '../../../../../Entity/Input/Input';
import useTypes from '../../../../../Entity/Type/Api/useTypes';
import useTransactionalEntity from '../../../../../../../@Api/Entity/Bespoke/useTransactionalEntity';
import useRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import EntityTypeContext from '../../../../../Entity/Type/EntityTypeContext';
import FieldGroupPredicateButton from './PredicateEditor/FieldGroupPredicateButton';
import FieldGroupExpansionEditor from './ExpansionEditor/FieldGroupExpansionEditor';
import CancelButton from '../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import SaveButton from '../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';

export interface FieldGroupItemProps
{
    entityType: EntityType;
    fieldGroup: Entity;
    onClose?: () => void;
}

const FieldGroupItem: React.FC<FieldGroupItemProps> =
    props =>
    {
        const { fieldGroup, onClose } = props;
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);
        const transactionalFieldGroup = useTransactionalEntity(fieldGroup);
        const entityTypeEntity =
            useRelatedEntity(
                transactionalFieldGroup,
                types.EntityType.RelationshipDefinition.FieldGroups,
                true);
        const entityTypeOfGroup =
            useMemo(
                () =>
                    entityTypeEntity &&
                        entityTypeStore.getTypeByEntityId(entityTypeEntity.id),
                [
                    entityTypeEntity
                ]);
        const save =
            useCallback(
                () =>
                    transactionalFieldGroup.checkAndDoCommit()
                        .then(
                            () =>
                                onClose()),
                [
                    transactionalFieldGroup,
                    onClose
                ]);

        return <TableRow>
            <TableCell>
                <Input
                    entity={transactionalFieldGroup}
                    field={types.EntityFieldGroup.Field.LocalizedName}
                    labelPosition="none"
                    autoFocus={transactionalFieldGroup.isNew()}
                    doAutocommit={!transactionalFieldGroup.isNew()}
                />
            </TableCell>
            <TableCell>
                {entityTypeOfGroup?.getName()}
            </TableCell>
            <TableCell>
                <FieldGroupExpansionEditor
                    {...props}
                    fieldGroup={transactionalFieldGroup}
                />
            </TableCell>
            <TableCell>
                <FieldGroupPredicateButton
                    {...props}
                    fieldGroup={transactionalFieldGroup}
                />
            </TableCell>
            <TableCell>
                <Input
                    entity={transactionalFieldGroup}
                    field={types.Pack.RelationshipDefinition.Entities}
                    parent
                    labelPosition="none"
                    doAutocommit={!transactionalFieldGroup.isNew()}
                />
            </TableCell>
            <TableCell>
                <ViewGroup
                    orientation="horizontal"
                    spacing={10}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                    />
                    {
                        onClose &&
                        <ViewGroupItem>
                            <CancelButton
                                onClick={onClose}
                            />
                        </ViewGroupItem>
                    }
                    {
                        transactionalFieldGroup.isNew() &&
                        <ViewGroupItem>
                            <SaveButton
                                onClick={save}
                            />
                        </ViewGroupItem>
                    }
                </ViewGroup>
            </TableCell>
        </TableRow>;
    };

export default observer(FieldGroupItem);
