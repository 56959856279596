import React, { useState } from 'react';
import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import TimeSheetTable from '../../../../TimeRegistration/TimeSheet/Table/TimeSheetTable';
import usePaginatedSelection from '../../../../../../../View/Api/usePaginatedSelection';
import useTypes from '../../../../../../../Type/Api/useTypes';

export interface ProductLineBilledTimeRegistrationsProps
{
    productLine: Entity;
}

const ProductLineBilledTimeRegistrations: React.FC<ProductLineBilledTimeRegistrationsProps> =
    props =>
    {
        const { productLine } = props;
        const types = useTypes();

        const [ pages, hasMore ] =
            usePaginatedSelection(
                types.TimeRegistration.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.ProductLine.RelationshipDefinition.BilledTimeRegistrations,
                                        true),
                                    productLine))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.TimeRegistrations,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Activity.RelationshipDefinition.TimeRegistrations,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.TimeRegistration.RelationshipDefinition.Activity,
                                    false))
                        .orderBy(
                            rootPath.field(types.TimeRegistration.Field.StartDate),
                            true),
                [
                    types,
                    productLine
                ]);
        const [ constructingEntity, setConstructingEntity ] = useState<Entity | undefined>();
        const [ selectedLines ] = useState<Entity[]>([]);

        return <TimeSheetTable
            pages={pages}
            hasMore={hasMore}
            constructingEntity={constructingEntity}
            onChangeConstructingEntity={setConstructingEntity}
            selectedLines={selectedLines}
            showActivity
        />;
    };

export default observer(ProductLineBilledTimeRegistrations);
