import { useMemo } from 'react';
import useTypes from '../../../../../Entity/Type/Api/useTypes';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';

export function useEntityTypesWithMutablePack(): EntityType[]
{
    const types = useTypes();

    return useMemo(
        () =>
            [
                types.Datastore.Type,
                types.LabelCategory.Type,
                types.Label.Type,
                types.View.Type,
                types.Dataset.Type, // widget
                types.Automation.Type,
                types.Template.Type,
                types.Dashboard.Type,
                types.Pipeline.Type,
                types.Resource.Type,
            ],
        [
            types
        ]);
}
