import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { loadModuleDirectly } from '../../../../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../../../Type/EntityTypeStore';

export default function hasMore(entity: Entity)
{
    const types = loadModuleDirectly(EntityTypeStore).bespoke.types;

    return entity.entityType.isA(types.Activity.Task.Type)
    || entity.entityType.isA(types.Activity.Appointment.Type)
    || entity.entityType.isA(types.Activity.Offer.Type)
    || entity.entityType.isA(types.Activity.Invoice.Type)
    || entity.entityType.isA(types.Activity.Email.Type)
    || entity.entityType.isA(types.Activity.Document.Type)
    || entity.entityType.isA(types.Activity.DigitalSigning.Type)
    || entity.entityType.isA(types.Attachment.Type)
    || entity.entityType.isA(types.TimeRegistration.Type);
}
