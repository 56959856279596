import React from 'react';
import { observer } from 'mobx-react';
import useLocalSetting from '../../Setting/Api/useLocalSetting';
import { Setting } from '../../../../@Api/Settings/Setting';
import LocalizedTextTranslator from './LocalizedTextTranslator/LocalizedTextTranslator';
import LocalizedTextTranslation from './LocalizedTextTranslation/LocalizedTextTranslation';

export interface LocalizedTextProps
{
    code: string;
    value?: string;
    defaultLanguage?: string;
    lowerCase?: boolean;
    containsHTML?: boolean;
}

const LocalizedText: React.FC<LocalizedTextProps & any> =
    props =>
    {
        const [ isInPlaceEditorEnabled ] =
            useLocalSetting(
                Setting.Local.Translation.IsInPlaceEditorEnabled,
                false);

        if (isInPlaceEditorEnabled)
        {
            return <LocalizedTextTranslator
                {...props}
            />;
        }
        else
        {
            return <LocalizedTextTranslation
                {...props}
            />;
        }
    };

LocalizedText.defaultProps = {
    defaultLanguage: 'nl'
};

export default observer(LocalizedText);
