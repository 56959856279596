import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';

export class EntityEventsPage
{
    events: EntityEvent[];
    hasMore: boolean;

    constructor(events: EntityEvent[], hasMore: boolean)
    {
        this.events = events;
        this.hasMore = hasMore;
    }
}