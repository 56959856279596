import React, { useMemo } from 'react';
import styles from './Input.module.scss';
import { classNames } from '../../../../Util/Class/classNames';
import Tooltip from '../../Tooltip/Tooltip';
import Icon from '../../Icon/Icon';
import { InputLabelContext } from './LabelContext/InputLabelContext';

export interface InputClasses
{
    root?: string;
}

export type LabelPosition = 'top' | 'left' | 'right' | 'none';

export interface InputProps
{
    labelPosition?: LabelPosition;
    label: React.ReactNode;
    help?: React.ReactNode;
    classes?: InputClasses;
    labelFullWidth?: boolean;
    inline?: boolean;
}

const Input: React.FC<InputProps> =
    props =>
    {
        let isLabelTop =
            useMemo(
                () =>
                    props.labelPosition === 'top',
                [
                    props.labelPosition
                ]);

        let isLabelLeft =
            useMemo(
                () =>
                    props.labelPosition === 'left',
                [
                    props.labelPosition
                ]);

        const isLabelRight =
            useMemo(
                () =>
                    props.labelPosition === 'right',
                [
                    props.labelPosition
                ]);

        // const isMobile = useIsMobile();
        //
        // if (isLabelLeft
        //     && !props.inline
        //     && isMobile)
        // {
        //     isLabelLeft = false;
        //     isLabelTop = true;
        // }

        const showLabel =
            useMemo(
                () =>
                    props.label !== undefined,
                [
                    props.label
                ]);

        const help =
            useMemo(
                () =>
                    props.help
                        ?
                            <div
                                className={
                                    classNames(
                                        styles.help,
                                        isLabelLeft && styles.helpLeft,
                                        isLabelRight && styles.helpRight,
                                        isLabelTop && styles.helpTop
                                    )
                                }
                            >
                                <Tooltip
                                    title={props.help}
                                >
                                    <Icon
                                        icon="info"
                                        size={14}
                                    />
                                </Tooltip>
                            </div>
                        :
                            undefined,
                [
                    props.help,
                    isLabelLeft,
                    isLabelRight,
                    isLabelTop
                ]);

        const label =
            useMemo(
                () =>
                    <div
                        className={styles.label}
                    >
                        {props.help && isLabelRight && help}
                        {props.label}
                        {props.help && (isLabelLeft || isLabelTop) && help}
                    </div>,
                [
                    props.label,
                    props.help,
                    isLabelTop,
                    isLabelRight,
                    isLabelLeft,
                    help
                ]);

        const view =
            useMemo(
                () =>
                    <div
                        className={styles.view}
                    >
                        <div
                            className={styles.innerView}
                        >
                            {props.children}
                        </div>
                    </div>,
                [
                    props.children
                ]);

        const wrapInProvider =
            (children: React.ReactNode) =>
                <InputLabelContext.Provider
                    value={props.label}
                >
                    {children}
                </InputLabelContext.Provider>;

        if (props.labelPosition === 'none' || !showLabel)
        {
            return wrapInProvider(
                <>
                    {props.children}
                </>
            );
        }
        else
        {
            if (isLabelLeft)
            {
                return wrapInProvider(
                    <div
                        // style={{
                        //     display: 'flex',
                        //     minHeight: 27,
                        //     maxWidth: 800,
                        //     alignItems: 'center',
                        //     minWidth: 70
                        // }}
                        className={
                            classNames(
                                styles.root,
                                props.classes && props.classes.root,
                                styles.labelLeft,
                                props.labelFullWidth && styles.labelFullWidth,
                                props.inline && styles.inline)
                        }
                    >
                        <div
                            className={classNames(styles.labelContainer)}
                            // style={{
                            //     display: 'flex',
                            //     width: !props.labelFullWidth ? '33%' : undefined,
                            //     minWidth: 115,
                            //     maxWidth: 250,
                            //     paddingRight: 10,
                            //     paddingTop: 3,
                            //     flex: props.labelFullWidth ? '1 1 auto' : undefined
                            // }}
                        >
                            {label}
                        </div>
                        <div
                            className={styles.viewContainer}
                            // style={{
                            //     flex: !props.labelFullWidth ? '1 1 auto' : undefined,
                            //     display: 'flex',
                            //     alignItems: 'center',
                            //     minWidth: 0 // https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout
                            // }}
                        >
                            {view}
                        </div>
                    </div>
                );
            }
            else
            {
                return wrapInProvider(
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: isLabelTop ? 'column' : undefined,
                            minHeight: !props.inline && (isLabelLeft || isLabelRight) ? 27 : undefined,
                            maxWidth: isLabelLeft ? 800 : undefined,
                            alignItems: isLabelLeft || isLabelRight ? 'center' : undefined,
                            minWidth: props.inline ? undefined : 70
                        }}
                        className={classNames(styles.root, props.classes && props.classes.root)}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: isLabelLeft ? undefined : 'center',
                                width: !props.inline && !props.labelFullWidth && isLabelLeft ? '33%' : undefined,
                                minWidth: !props.inline && isLabelLeft ? 115 : undefined,
                                maxWidth: !props.inline && isLabelLeft ? 250 : undefined,
                                // alignSelf: isLabelLeft ? 'center' : undefined,
                                paddingRight: isLabelLeft ? 10 : 0,
                                paddingTop: !props.inline && isLabelLeft ? 3 : 0,
                                flex: props.labelFullWidth ? '1 1 auto' : undefined
                            }}
                        >
                            {
                                isLabelRight
                                    ?
                                        view
                                    :
                                        label
                            }
                        </div>
                        <div
                            style={{
                                flex: !props.labelFullWidth ? '1 1 auto' : undefined,
                                display: 'flex',
                                alignItems: 'center',
                                minWidth: 0 // https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout
                            }}
                        >
                            {
                                isLabelRight
                                    ?
                                        label
                                    :
                                        view
                            }
                        </div>
                    </div>
                );
            }
        }
    };

Input.defaultProps = {
    labelPosition: 'top'
};

export default Input;
