import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import { primaryColor } from '../../../../../../../../@Resource/Theme/Theme';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import getConnectorActivationByCode from '../../../../../../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';
import React, { useMemo } from 'react';
import useTypes from '../../../../../Type/Api/useTypes';
import SendDocumentButton from '../../ProductLinesEditor/Button/SendDocumentButton';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import SendDocumentMenuItem from '../../ProductLinesEditor/Button/SendDocumentMenuItem';
import { EInvoiceSendButtons } from './EInvoiceSendButtons';
import useIsConnectorActivationActivated from '../../../../../../Marketplace/Api/useIsConnectorActivationActivated';

export interface SendInvoiceButtonMenuProps
{
    entity: Entity;
}

export const SendInvoiceButtonMenu: React.FC<SendInvoiceButtonMenuProps> =
    observer(
        ({
             entity
         }) =>
        {
            const types = useTypes();

            const [ eInvoicingConnectorActivation, isLoading] =
                useAsyncResult(
                    () => getConnectorActivationByCode('EInvoicing'),
                    []
                );

            const isActivated = useIsConnectorActivationActivated(eInvoicingConnectorActivation);

            const eInvoicingConnectorActive =
                useMemo(
                    () =>
                        !isLoading && isActivated,
                    [
                        eInvoicingConnectorActivation,
                        isLoading,
                        isActivated
                    ]
                );

            const entityByParameter =
                useMemo(
                    () =>
                        new Map([
                            [ 'Invoice', entity ]
                        ]),
                    [
                        entity
                    ]);

            return <div>
                { eInvoicingConnectorActive &&
                    <MenuButton
                        color={primaryColor}
                        label={
                            <LocalizedText
                                code="Activity.Invoice.SendButton"
                                value="Versturen"
                            />
                        }
                        filled
                        fullWidth
                        size="sm"
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                <SendDocumentMenuItem
                                    entity={entity}
                                    label={
                                        <LocalizedText
                                            code="Activity.Invoice.SendEmail"
                                            value="via E-mail"
                                        />
                                    }
                                    actionCode="Activity.Invoice.Send"
                                    emailTemplateType={types.Template.Email.Invoice.Type}
                                    entityByParameter={entityByParameter}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <EInvoiceSendButtons
                                    entity={entity}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </MenuButton>
                }

                { !eInvoicingConnectorActive &&
                    <SendDocumentButton
                        entity={entity}
                        fullWidth
                        actionCode="Activity.Invoice.Send"
                        emailTemplateType={types.Template.Email.Invoice.Type}
                        entityByParameter={entityByParameter}
                    />
                }

            </div>

        }
    );
