import React, { useEffect, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import ExpansionLayout from '../../../../../@Api/Layout/Type/ExpansionLayout';
import uuid from '../../../../../@Util/Id/uuid';
import ExpansionPanel from '../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import safelySynchronousApplyFunction from '../../../../../@Api/Automation/Api/safelySynchronousApplyFunction';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { BooleanType } from '../../../DataObject/Type/Boolean/BooleanType';
import useExpansion from '../../../../../@Future/Component/Generic/ExpansionPanel/Hook/useExpansion';

export interface ExpansionLayoutViewerProps extends LayoutViewerProps<ExpansionLayout>
{

}

const ExpansionLayoutViewer: React.FC<ExpansionLayoutViewerProps> =
    props =>
    {
        const id = useMemo(() => uuid(), []);
        const [ , setExpansion ] = useExpansion(id);
        const isExpandedByDefault =
            useComputed(
                () =>
                    props.layout.isExpandedByDefault &&
                        safelySynchronousApplyFunction(
                            props.layout.isExpandedByDefault,
                            new FunctionContext(
                                props.parameterDictionary,
                                props.parameterAssignment,
                                props.commitContext
                            )
                        ),
                [
                    props.layout,
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        useEffect(
            () =>
            {
                const doExpand =
                    isExpandedByDefault instanceof PrimitiveValue
                        && isExpandedByDefault.value.specification.type instanceof BooleanType
                        && isExpandedByDefault.value.value;

                if (doExpand)
                {
                    setExpansion(true);
                }
            },
            [
                isExpandedByDefault,
                setExpansion
            ]);

        return <ExpansionPanel
            id={id}
            summary={
                <Header
                    title={
                        <LayoutViewer
                            {...props}
                            layout={props.layout.header}
                        />
                    }
                    inset={props.layout.hasHeaderInset}
                />
            }
            expansion={
                <LayoutViewer
                    {...props}
                    layout={props.layout.expansion}
                />
            }
        />;
    };

export default observer(ExpansionLayoutViewer);
