import { useCallback, useContext } from 'react';
import useOnDragEnd from '../../../../../../DragAndDrop/Api/useOnDragEnd';
import { DropResult } from 'react-beautiful-dnd';
import EntityTypeContext from '../../../../../Type/EntityTypeContext';
import { EntitySelectionAggregateResult } from '../../../../../Selection/Model/EntitySelectionAggregateResult';
import updateEntityWithResult from '../../../../../Dataset/Segment/Api/updateEntityWithResult';
import { runInAction } from 'mobx';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import OnDropEntityContext from '../../../Context/OnDropEntityContext';
import Segment from '../../../../../Dataset/Model/Segment';

export default function useOnDrop(entities: Entity[],
                                  id: string,
                                  segment: Segment,
                                  result: EntitySelectionAggregateResult)
{
    const isDropping = useContext(OnDropEntityContext);

    const entityTypeStore = useContext(EntityTypeContext);
    const onDrop =
        useCallback(
            (dropResult: DropResult) =>
            {
                runInAction(
                    () =>
                    {
                        const entity = entityTypeStore.entityCacheService.getEntity(dropResult.draggableId);

                        if (entity
                            && dropResult.source
                            && dropResult.destination
                            && dropResult.source.droppableId !== dropResult.destination.droppableId)
                        {
                            if (dropResult.destination.droppableId === id)
                            {
                                entities.splice(
                                    dropResult.destination.index,
                                    0,
                                    entity);

                                isDropping({ entity: entity, target: result, targetIdx: dropResult.destination.index });

                                updateEntityWithResult(
                                    entity,
                                    segment,
                                    result)
                                    .then(
                                        () =>
                                            isDropping(undefined));
                            }
                            else if (dropResult.source.droppableId === id)
                            {
                                entities.splice(
                                    dropResult.source.index,
                                    1);
                            }
                        }
                    });
            },
            [
                entities,
                id,
                entityTypeStore,
                segment,
                result,
                isDropping
            ]);

    useOnDragEnd(onDrop);
}
