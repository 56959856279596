import { useContext } from 'react';
import useTypes from '../../Entity/Type/Api/useTypes';
import moment from 'moment';
import CurrentUserContext from '../../User/CurrentUserContext';
import { useComputed } from 'mobx-react-lite';

export default function useCurrentLicensePeriod(): [ Date, Date, boolean ]
{
    const currentUserStore = useContext(CurrentUserContext);
    const types = useTypes();

    return useComputed(
        () =>
        {
            const contract = currentUserStore.contractEntity;

            if (!contract)
            {
                return [ new Date(), new Date(), true ];
            }
            else if (contract.hasValueForField(types.LicenseContract.Field.ActivationDate))
            {
                const now = moment();
                let startDate = moment(contract.getObjectValueByField(types.LicenseContract.Field.ActivationDate));

                if (now.isBefore(startDate))
                {
                    return [ new Date(), startDate.toDate(), true ];
                }
                else
                {
                    let endDate = startDate.clone().add(1, 'year');

                    while (!now.isBetween(startDate, endDate))
                    {
                        startDate = endDate.clone();
                        endDate = endDate.clone().add(1, 'year');
                    }

                    return [ startDate.toDate(), endDate.toDate(), true ];
                }
            }
            else
            {
                return [
                    new Date(),
                    contract.getObjectValueByField(types.LicenseContract.Field.TrialPeriodEndDate) || moment().add(14, 'days').toDate(),
                    false
                ];
            }
        },
        [
            currentUserStore,
            types
        ]);
}
