import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { EntityConstraintBuilderFilterContext } from './Model/EntityConstraintBuilderFilterContext';
import { ConstraintNode } from '../../../../../../@Api/Model/Implementation/ConstraintNode';
import CompositePredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import NotPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/NotPredicate';
import InstanceOfPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/InstanceOfPredicate';
import { buildCompositeConstraintNode } from './buildCompositeConstraintNode';
import { buildComparisonConstraintNode } from './buildComparisonConstraintNode';
import { buildNotConstraintNode } from './buildNotConstraintNode';
import { buildInstanceOfConstraintNode } from './buildInstanceOfConstraintNode';
import { buildBooleanConstraintNode, isBooleanConstraintNode } from './buildBooleanConstraintNode';

export function buildConstraintNode(
    filter: Predicate,
    context: EntityConstraintBuilderFilterContext
): ConstraintNode | undefined
{
    if (isBooleanConstraintNode(filter, context))
    {
        return buildBooleanConstraintNode(
            filter,
            context
        );
    }
    if (filter instanceof CompositePredicate)
    {
        return buildCompositeConstraintNode(
            filter,
            context
        );
    }
    else if (filter instanceof ComparisonPredicate)
    {
        return buildComparisonConstraintNode(
            filter,
            context
        );
    }
    else if (filter instanceof NotPredicate)
    {
        return buildNotConstraintNode(
            filter,
            context
        );
    }
    else if (filter instanceof InstanceOfPredicate)
    {
        return buildInstanceOfConstraintNode(
            filter,
            context
        );
    }
    else
    {
        return undefined;
    }
}
