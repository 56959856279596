import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ActionEditorProps } from '../../ActionEditor';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { default as CreateEntityActionModel } from '../../../../../../../../../../@Api/Automation/Function/Action/CreateEntityAction';
import EntityConstructorPathSelector from '../../../../../../../Button/EntityConstructorButton/EntityConstructorPathSelector';
import { AddEntityPath } from '../../../../../../../Path/@Model/AddEntityPath';
import { runInAction } from 'mobx';
import Mapping from '../../../../../../../../../../@Api/Automation/Mapping/Mapping';
import CardInset from '../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import MappingEditor from './Mapping/MappingEditor';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface CreateEntityActionEditorProps extends ActionEditorProps<CreateEntityActionModel>
{

}

const CreateEntityActionEditor: React.FC<CreateEntityActionEditorProps> =
    props =>
    {
        const onCreateMapping =
            useCallback(
                (addPath: AddEntityPath) =>
                    runInAction(
                        () =>
                            props.action.mapping =
                                new Mapping(
                                    addPath.fullPath.path.entityType,
                                    Mapping.buildParameter(addPath.fullPath.path.entityType),
                                    [],
                                    [])),
                [
                    props.action
                ]);

        if (props.action.mapping)
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <MappingEditor
                        mapping={props.action.mapping}
                        context={props.context}
                    />
                </ViewGroupItem>
            </ViewGroup>;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                <ViewGroupItem>
                    <CardInset
                        vertical={false}
                    >
                        <LocalizedText
                            code="AutomationEditor.WhichRecordTypeToCreate"
                            value="Welk type record wil je aanmaken?"
                        />
                    </CardInset>
                </ViewGroupItem>
                <ViewGroupItem>
                    <EntityConstructorPathSelector
                        onSelect={onCreateMapping}
                        ignoreHidden
                    />
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

export default observer(CreateEntityActionEditor);
