import React from 'react';
import { observer } from 'mobx-react-lite';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import EntityFromDataSourceValueComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/EntityFromDataSourceValueComputation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationBox from '../../Box/ComputationBox';

export interface EntityFromDataSourceValueComputationEditorProps extends ComputationEditorProps<ValueType<any>, EntityFromDataSourceValueComputation>
{

}

const EntityFromDataSourceValueComputationEditor: React.FC<EntityFromDataSourceValueComputationEditorProps> =
    props =>
    {
        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <Input
                label="Als entiteit"
                labelPosition="left"
            >
                <ComputationBox>
                    <ComputationEditor
                        {...props}
                        type={undefined}
                        value={props.value.value}
                    />
                </ComputationBox>
            </Input>
        </ViewGroup>;
    };

export default observer(EntityFromDataSourceValueComputationEditor);
