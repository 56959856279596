import React, { useCallback, useEffect, useMemo } from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import styles from '../TimeSheet.module.scss';
import useTypes from '../../../../../../Type/Api/useTypes';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TimeRegistration from './Page/TimeRegistration/TimeRegistration';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import { Page } from '../../../../../../View/Api/usePaginatedSelection';
import TimeSheetTablePage from './Page/TimeSheetTablePage';
import equalsEntity from '../../../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Checkbox from '../../../../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import { CommitContext } from '../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { useNewCommitContext } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { isTimeRegistrationVisible } from '../Api/isTimeRegistrationVisible';
import SortIconButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/SortIconButton/SortIconButton';

export type StartDateSortDirection = 'Ascending' | 'Descending';

export interface TimeSheetTableProps
{
    milestone?: Entity;
    pages: Page[];
    hasMore: boolean;
    filter?: (timeRegistration: Entity) => boolean;
    showActivity?: boolean;
    canHaveMilestone?: boolean;
    showBillingActivity?: boolean;
    constructingEntity?: Entity;
    onChangeConstructingEntity?: (entity: Entity | undefined) => void;
    selectedLines?: Entity[];
    onChangeSelectedLines?: (lines: Entity[]) => void;
    showsActiveTimeRegistrations?: boolean;
    startDateSortDirection?: StartDateSortDirection;
    onChangeStartDateSortDirection?: (sortDirection: StartDateSortDirection) => void;
    commitContext?: CommitContext;
    allowSelectAll?: boolean;
}

const TimeSheet: React.FC<TimeSheetTableProps> =
    ({
        milestone,
        pages,
        hasMore,
        filter,
        showActivity,
        canHaveMilestone,
        showBillingActivity,
        constructingEntity,
        onChangeConstructingEntity,
        selectedLines,
        onChangeSelectedLines,
        showsActiveTimeRegistrations,
        startDateSortDirection,
        onChangeStartDateSortDirection,
        commitContext: inheritedCommitContext,
        allowSelectAll,
     }) =>
    {
        const commitContext = useNewCommitContext(inheritedCommitContext);
        const types = useTypes();

        const isSelectionEnabled =
            useMemo(
                () =>
                    selectedLines !== undefined && onChangeSelectedLines !== undefined,
                [
                    selectedLines,
                    onChangeSelectedLines
                ]);
        const clearConstructingEntity =
            useCallback(
                () =>
                    onChangeConstructingEntity(undefined),
                [
                    onChangeConstructingEntity
                ]);
        const selectedLinesAsSet =
            useMemo(
                () =>
                    new Set(
                        selectedLines
                            ? selectedLines.map(line => line.uuid)
                            : []
                    ),
                [
                    selectedLines
                ]);
        const onSelect =
            useCallback(
                (line: Entity, isSelected: boolean) =>
                    onChangeSelectedLines(
                        isSelected
                            ?
                                [
                                    ...selectedLines,
                                    line
                                ]
                            :
                                selectedLines.filter(
                                    selectedLine =>
                                        !equalsEntity(selectedLine, line)
                                )
                    ),
                [
                    selectedLines,
                    onChangeSelectedLines
                ]);
        const allVisibleTimeRegistrations =
            useComputed(
                () =>
                    pages
                        .map(
                            page =>
                                page.map(
                                    record =>
                                        record.entity
                                )
                        )
                        .reduce(
                            (a, b) => a.concat(b),
                            []
                        )
                        .filter(
                            timeRegistration =>
                                isTimeRegistrationVisible(
                                    timeRegistration,
                                    filter
                                )
                        ),
                [
                    pages,
                    filter,
                ]
            );
        const isAllSelected =
            useComputed(
                () =>
                    selectedLines.length === allVisibleTimeRegistrations.length,
                [
                    selectedLines,
                    allVisibleTimeRegistrations
                ]);
        const isIndeterminate =
            useMemo(
                () =>
                    selectedLines.length > 0 && !isAllSelected,
                [
                    selectedLines,
                    isAllSelected
                ]);
        const onToggleAll =
            useCallback(
                (isSelected: boolean) =>
                    onChangeSelectedLines(
                        isSelected
                            ? allVisibleTimeRegistrations
                            : []
                    ),
                [
                    onChangeSelectedLines,
                    allVisibleTimeRegistrations,
                    commitContext,
                    filter,
                ]);
        const onChangeSortDirection =
            useCallback(
                () =>
                {
                    onChangeSelectedLines([]);
                    onChangeStartDateSortDirection(startDateSortDirection === 'Ascending' ? 'Descending' : 'Ascending')
                },
                [
                    onChangeSelectedLines,
                    startDateSortDirection,
                    onChangeStartDateSortDirection
                ]
            );

        useEffect(
            () =>
            {
                if (onChangeSelectedLines)
                {
                    onToggleAll(false);
                }
            },
            [
                onChangeSelectedLines,
                onToggleAll,
            ]
        );

        return <Table
            className={styles.table}
        >
            <TableHead>
                <TableRow>
                    {
                        isSelectionEnabled &&
                            <TableCell
                                {...{ width: '5%' }}
                            >
                                {
                                    allVisibleTimeRegistrations.length > 0 &&
                                        <Checkbox
                                            indeterminate={isIndeterminate}
                                            onToggle={onToggleAll}
                                            checked={isAllSelected}
                                            disabled={!allowSelectAll}
                                        />
                                }
                            </TableCell>
                    }
                    {
                        showActivity &&
                            <TableCell
                                {...{ width: '15%' }}
                            >
                                {types.Activity.RelationshipDefinition.TimeRegistrations.getName(true)}
                            </TableCell>
                    }
                    <TableCell
                        {...{ width: '20%' }}
                    >
                        {types.TimeRegistration.RelationshipDefinition.Activity.getName(false)}
                    </TableCell>
                    <TableCell
                        {...{ width: '15%' }}
                    >
                        {types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations.getName(true)}
                    </TableCell>
                    <TableCell
                        {...{ width: '20%' }}
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                {
                                    showsActiveTimeRegistrations
                                        ?
                                            <LocalizedText
                                                code="Generic.StartDate"
                                                value="Startdatum"
                                            />
                                        :
                                            <LocalizedText
                                                code="Generic.Date"
                                                value="Datum"
                                            />
                                }
                            </ViewGroupItem>
                            {
                                onChangeStartDateSortDirection &&
                                    <ViewGroupItem>
                                        <SortIconButton
                                            isAscendingSort={startDateSortDirection === 'Ascending'}
                                            onClick={onChangeSortDirection}
                                        />
                                    </ViewGroupItem>
                            }
                        </ViewGroup>
                    </TableCell>
                    <TableCell
                        {...{ width: '10%' }}
                    >
                        <LocalizedText
                            code="Generic.Duration"
                            value="Duratie"
                        />
                    </TableCell>
                    {
                        canHaveMilestone && !milestone &&
                            <TableCell
                                {...{ width: showsActiveTimeRegistrations ? '20%' : '25%' }}
                            >
                                <LocalizedText
                                    code="Generic.Milestone"
                                    value="Milestone"
                                />
                            </TableCell>
                    }
                    <TableCell
                        {...{ width: '5%' }}
                        align="center"
                    >
                        {types.TimeRegistration.Field.IsBillable.getName()}
                    </TableCell>
                    {
                        showBillingActivity &&
                            <TableCell
                                {...{ width: '15%' }}
                            >
                                {types.Activity.Invoice.Type.getName()}
                            </TableCell>
                    }
                    <TableCell
                        {...{ width: '5%' }}
                    >

                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    pages.map(
                        (page, idx) =>
                            <TimeSheetTablePage
                                key={idx}
                                milestone={milestone}
                                page={page}
                                hasMore={hasMore}
                                filter={filter}
                                onSelect={isSelectionEnabled ? onSelect : undefined}
                                selectedLinesAsSet={selectedLinesAsSet}
                                showActivity={showActivity}
                                showBillingActivity={showBillingActivity}
                                canHaveMilestone={canHaveMilestone}
                                commitContext={commitContext}
                            />)
                }
                {
                    constructingEntity?.isNew() &&
                        <TimeRegistration
                            entity={constructingEntity}
                            onSelect={isSelectionEnabled ? onSelect : undefined}
                            selected={selectedLinesAsSet.has(constructingEntity.uuid)}
                            milestone={milestone}
                            showActivity={showActivity}
                            showBillingActivity={showBillingActivity}
                            canHaveMilestone={canHaveMilestone}
                            onDelete={clearConstructingEntity}
                            commitContext={commitContext}
                        />
                }
            </TableBody>
        </Table>;
    };

export default observer(TimeSheet);
