import { useCallback, useState } from 'react';
import useOnDragStart from './useOnDragStart';
import useOnDragEnd from './useOnDragEnd';
import { DragStart } from 'react-beautiful-dnd';

export default function useIsDragging(id?: string)
{
    const [ isDragging, setDragging ] = useState(false);
    const onStart =
        useCallback(
            (result: DragStart) =>
            {
                if (id === undefined || result.draggableId === id)
                {
                    setDragging(true);
                }
            },
            [
                setDragging,
                id
            ]);

    const onEnd =
        useCallback(
            () =>
                setDragging(false),
            [
                setDragging
            ]);

    useOnDragStart(onStart);
    useOnDragEnd(onEnd);

    return isDragging;
}
