import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';

export default class LocalizedLayoutItem
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable languageCode: string;
    @observable.ref layout: Layout;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                languageCode: string,
                layout: Layout)
    {
        this.id = id;
        this.languageCode = languageCode;
        this.layout = layout;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return this.layout.validate();
    }

    getDependencies(): Dependency[]
    {
        return this.layout.getDependencies();
    }

    toDescriptor()
    {
        return {
            id: this.id,
            languageCode: this.languageCode,
            layout: this.layout.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new LocalizedLayoutItem(
            descriptor.id,
            descriptor.languageCode,
            await getLayoutFromDescriptor(
                descriptor.layout,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
