import React from 'react';
import { GutenbergCoreBlockColumnId, GutenbergCoreBlockColumnsId } from '../../../DocumentEditor';
import GutenbergBlockType from '../../../Gutenberg/GutenbergBlockType';
import classnames from 'classnames';
import { dropRight, get, times } from 'lodash';
import {
    __experimentalBlockVariationPicker, __experimentalUseInnerBlocksProps as useInnerBlocksProps, BlockControls, BlockVerticalAlignmentToolbar, InnerBlocks, InspectorControls, store as blockEditorStore, useBlockProps,
} from '@wordpress/block-editor';
import { useDispatch, useSelect, withDispatch } from '@wordpress/data';
import { createBlock, createBlocksFromInnerBlocksTemplate, store as blocksStore } from '@wordpress/blocks';
import { __ } from '@wordpress/i18n';
import { Notice, PanelBody, RangeControl, ToggleControl } from '@wordpress/components';
import { getMappedColumnWidths, getRedistributedColumnWidths, hasExplicitPercentColumnWidths, toWidthPrecision } from './GutenbergCoreBlockColumnsUtils';
import ColorOptionsPanel from '../../Settings/ColorOptionsPanel';
import { BlockTypeById } from '../../../Gutenberg/GutenbergBlockEditor';
import { removeRenderedLayoutStyles } from './GutenbergCoreBlockUtils';
import Icon from '../../../../../Icon/Icon';

const ALLOWED_BLOCKS = [ GutenbergCoreBlockColumnId ];

const GutenbergCoreBlockColumns: GutenbergBlockType =
{
    name: GutenbergCoreBlockColumnsId,

    // https://github.com/WordPress/gutenberg/tree/trunk/packages/block-library/src/columns

    configuration:
    {
        title: {
            code: 'GutenbergBlock.Columns',
            value: 'Kolommen'
        },
        icon:  <Icon icon="view_column" />,
        category: "design",
        description: "Display content in multiple columns, with blocks added to each column.",
        textdomain: "default",
        attributes: {
            verticalAlignment: {
                type: "string"
            },
            isStackedOnMobile: {
                type: "boolean",
                default: true
            }
        },
        supports: {
            anchor: true,
            align: [ "wide", "full" ],
            html: false,
            color: {
                gradients: false,
                link: true
            },
            spacing: {
                blockGap: true,
                margin: [ "top", "bottom" ],
                padding: true,
                __experimentalDefaultControls: {
                    padding: true
                }
            },
            __experimentalLayout: {
                allowSwitching: false,
                allowInheriting: false,
                allowEditing: false,
                default: {
                    type: "flex",
                    flexWrap: "nowrap"
                }
            }
        },

        edit: props =>
        {
            return <GutenbergCoreBlockColumnsEditor
                {...props}
            />;
        },

        save: (props) =>
        {
            const { isStackedOnMobile, verticalAlignment } = props.attributes;

            const className = classnames( {
                [ `are-vertically-aligned-${ verticalAlignment }` ]: verticalAlignment,
                [ `is-not-stacked-on-mobile` ]: ! isStackedOnMobile,
            } );

            return (
                <div { ...useBlockProps.save( { className } ) }>
                    <InnerBlocks.Content />
                </div>
            );
        }
    },

    view: (block, props) =>
    {
        return <>
            <div
                style={{
                    position: 'relative'
                }}
            >
                {
                    block.innerBlocks.map(
                        innerBlock =>
                            <React.Fragment
                                key={innerBlock.clientId}
                            >
                                {BlockTypeById.get(innerBlock.name).view(innerBlock, props)}
                            </React.Fragment>)
                }
            </div>
            <div
                style={{
                    clear: 'both'
                }}
            />
        </>;
    }
};

const ColumnsEditContainer =
    (props: any) =>
    {
        const {
            attributes,
            setAttributes,
            updateAlignment,
            updateColumns,
            clientId,
        } = props;

        const {
            isStackedOnMobile,
            verticalAlignment,
            backgroundColor,
            fontColor,
            borderColor
        } = attributes;

        const { count } =
            useSelect(
                (select) =>
                {
                    return {
                        count: select(blockEditorStore)
                            .getBlockCount(clientId),
                    };
                },
                [
                    clientId
                ]);

        const classes = classnames(
            {
                [`are-vertically-aligned-${verticalAlignment}`]: verticalAlignment,
                [`is-not-stacked-on-mobile`]: !isStackedOnMobile,
            });

        const blockProps =
            useBlockProps(
                {
                    className: classes,
                    style: {
                        backgroundColor: backgroundColor,
                        color: fontColor,
                        borderColor: borderColor
                    }
                });

        const innerBlocksProps = {
            ...useInnerBlocksProps(
                blockProps,
                {
                    allowedBlocks: ALLOWED_BLOCKS,
                    orientation: 'horizontal',
                    renderAppender:false
                }),
            className: removeRenderedLayoutStyles(blockProps.className)
        };

        return (
            <>
                <BlockControls>
                    <BlockVerticalAlignmentToolbar
                        onChange={updateAlignment}
                        value={verticalAlignment}
                    />
                </BlockControls>
                <InspectorControls>
                    <ColorOptionsPanel
                        props={props}
                        disableBorderColor
                        disableFontColor
                    />
                    <PanelBody>
                        <RangeControl
                            label={__('Columns')}
                            value={count}
                            onChange={(value) => updateColumns(count, value)}
                            min={1}
                            max={Math.max(6, count)}
                        />
                        {
                            count > 6 &&
                            (
                                <Notice status="warning" isDismissible={false}>
                                    {__('This column count exceeds the recommended amount and may cause visual breakage.')}
                                </Notice>
                            )
                        }
                        <ToggleControl
                            label={__('Stack on mobile')}
                            checked={isStackedOnMobile}
                            onChange={() => setAttributes({
                                isStackedOnMobile: !isStackedOnMobile,
                            })}
                        />
                    </PanelBody>
                </InspectorControls>
                <div {...innerBlocksProps} />
            </>
        );
    }

const ColumnsEditContainerWrapper =
    withDispatch(
        ( dispatch, ownProps, registry ) => ({
            updateAlignment(verticalAlignment)
            {
                const {
                    clientId,
                    setAttributes
                } = ownProps;
                const {updateBlockAttributes} = dispatch(blockEditorStore);
                const {getBlockOrder} = registry.select(blockEditorStore);

                setAttributes({verticalAlignment});

                const innerBlockClientIds = getBlockOrder(clientId);

                innerBlockClientIds
                    .forEach((innerBlockClientId) =>
                    {
                        updateBlockAttributes(innerBlockClientId, {
                            verticalAlignment
                        });
                    });
            },

            updateColumns(previousColumns, newColumns)
            {
                const {clientId} = ownProps;
                const {replaceInnerBlocks} = dispatch(blockEditorStore);
                const {getBlocks} = registry.select(blockEditorStore);

                let innerBlocks = getBlocks(clientId);
                const hasExplicitWidths = hasExplicitPercentColumnWidths(innerBlocks);

                const isAddingColumn = newColumns > previousColumns;

                if (isAddingColumn && hasExplicitWidths)
                {
                    const newColumnWidth = toWidthPrecision(100 / newColumns);

                    const widths = getRedistributedColumnWidths(innerBlocks, 100 - newColumnWidth);

                    innerBlocks = [
                        ...getMappedColumnWidths(innerBlocks, widths), ...times(newColumns - previousColumns, () =>
                        {
                            return createBlock(GutenbergCoreBlockColumnId, {
                                width: `${newColumnWidth}%`,
                            });
                        }),
                    ];
                }
                else if (isAddingColumn)
                {
                    innerBlocks = [
                        ...innerBlocks, ...times(newColumns - previousColumns, () =>
                        {
                            return createBlock(GutenbergCoreBlockColumnId);
                        }),
                    ];
                }
                else
                {
                    innerBlocks = dropRight(innerBlocks, previousColumns - newColumns);

                    if (hasExplicitWidths)
                    {
                        const widths = getRedistributedColumnWidths(innerBlocks, 100);

                        innerBlocks = getMappedColumnWidths(innerBlocks, widths);
                    }
                }

                replaceInnerBlocks(clientId, innerBlocks);
            },
        })
    )( ColumnsEditContainer );

const Placeholder =
    ({
         clientId,
         name,
         setAttributes
    }) =>
    {
        const { blockType, defaultVariation, variations } = useSelect(
            (select) =>
            {
                const { getBlockVariations, getBlockType, getDefaultBlockVariation } = select(blocksStore);

                return {
                    blockType: getBlockType(name),
                    defaultVariation: getDefaultBlockVariation(name, 'block'),
                    variations: getBlockVariations(name, 'block'),
                };
            }, [name]
        );

        const {replaceInnerBlocks} = useDispatch(blockEditorStore);
        const blockProps = useBlockProps();

        // eslint-disable-next-line react/jsx-pascal-case
        return (
            <div {...blockProps}>
                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <__experimentalBlockVariationPicker
                    icon={get(blockType, ['icon', 'src'])}
                    label={get(blockType, ['title'])}
                    variations={variations}
                    onSelect={
                        (nextVariation = defaultVariation) =>
                        {
                            if (nextVariation.attributes)
                            {
                                setAttributes(nextVariation.attributes);
                            }
                            if (nextVariation.innerBlocks)
                            {
                                replaceInnerBlocks(
                                    clientId,
                                    createBlocksFromInnerBlocksTemplate(nextVariation.innerBlocks),
                                    true
                                );
                            }
                        }
                    }
                    allowSkip
                />
            </div>
        );
    }

const GutenbergCoreBlockColumnsEditor =
    (props: any) =>
    {
        const {clientId} = props;
        const hasInnerBlocks = useSelect(
            (select) =>
                select(blockEditorStore)
                    .getBlocks(clientId).length > 0,
            [
                clientId
            ]
        );
        const Component = hasInnerBlocks
            ? ColumnsEditContainerWrapper
            : Placeholder;

        return <Component {...props} />;
    }

export default GutenbergCoreBlockColumns;
