import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutActionEditorProps } from '../../LayoutActionEditor';
import ActionConstructor from '../../Constructor/ActionConstructor';
import { runInAction } from 'mobx';
import uuid from '../../../../../../@Util/Id/uuid';
import CompositeActionInvocationEditor from './CompositeActionInvocationEditor';
import CompositeAction from '../../../../../../@Api/Layout/Action/Type/CompositeAction';
import CompositeActionInvocation from '../../../../../../@Api/Layout/Action/Type/CompositeActionInvocation';
import LayoutAction from '../../../../../../@Api/Layout/Action/LayoutAction';

export interface CompositeActionEditorProps extends LayoutActionEditorProps<CompositeAction>
{

}

const CompositeActionEditor: React.FC<CompositeActionEditorProps> =
    props =>
    {
        const onConstruct =
            useCallback(
                (action: LayoutAction, afterIdx: number = 0) =>
                    runInAction(
                        () =>
                            props.action.invocations.splice(
                                afterIdx,
                                0,
                                new CompositeActionInvocation(
                                    CompositeActionInvocation.getResultParameter(
                                        uuid(),
                                        action),
                                    action))),
                [
                    props.action
                ]);

        const onConstructAfter =
            useCallback(
                (action: LayoutAction) =>
                    onConstruct(action, props.action.invocations.length),
                [
                    onConstruct
                ]);

        const [ invocation, ...tail ] = props.action.invocations.slice();

        if (invocation)
        {
            return <CompositeActionInvocationEditor
                context={props.context}
                action={props.action}
                invocation={invocation}
                tail={tail}
                idx={0}
                onConstruct={onConstruct}
            />;
        }
        else
        {
            return <ActionConstructor
                context={props.context}
                onConstruct={onConstructAfter}
                mode={tail.length > 0 ? 'Line' : 'Button'}
            />;
        }
    };

export default observer(CompositeActionEditor);
