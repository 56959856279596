import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import Dataset from '../../../../Entity/Dataset/Model/Dataset';
import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import RightAlignedButtonGroup from '../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import PrimaryButton from '../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import DangerButton from '../../../../../../@Future/Component/Generic/Button/Variant/DangerButton/DangerButton';
import useIsAlteredDataset from '../../../../Entity/Dataset/Api/useIsAlteredDataset';
import isMutable from '../../../../../../@Api/RightProfile/isMutable';

export interface DatasetEditorButtonsProps
{
    datasetEntity: Entity;
    dataset: Dataset;
    onSave: () => {};
    onSaveAsNew: () => {};
    onDelete: () => {};
}

const DatasetEditorButtons: React.FC<DatasetEditorButtonsProps> =
    props => {
        const { datasetEntity, dataset } = props;

        const isAlteredDataset = useIsAlteredDataset(dataset);

        const isEditable =
            useComputed(
                () =>
                    datasetEntity ? isMutable(datasetEntity) : true,
                [
                    datasetEntity
                ]);

         return  datasetEntity &&
            <ViewGroupItem>
                <CardInset
                    top={false}
                >
                    <RightAlignedButtonGroup>
                        {
                            dataset &&
                            <PrimaryButton
                                label={
                                    <LocalizedText
                                        code="Generic.Save"
                                    />
                                }
                                onClick={props.onSave}
                                disabled={!isEditable || isAlteredDataset}
                            />
                        }
                        {
                            dataset && !datasetEntity.isNew() &&
                            <PrimaryButton
                                label={
                                    <LocalizedText
                                        code="Configuration.Dataset.SaveAsNew"
                                        value="Opslaan als nieuwe widget"
                                    />
                                }
                                disabled={isAlteredDataset}
                                onClick={props.onSaveAsNew}
                            />
                        }
                        <DangerButton
                            label={
                                <LocalizedText
                                    code="Generic.Delete"
                                />
                            }
                            onClick={props.onDelete}
                            disabled={!isEditable}
                        />
                    </RightAlignedButtonGroup>
                </CardInset>
            </ViewGroupItem>
    }

export default observer(DatasetEditorButtons);
