import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { Selection } from '../../../../../@Api/Selection/Model/Selection';
import getTypes from '../../Type/Api/getTypes';
import { EntitySelectionResult } from '../Model/EntitySelectionResult';
import { EntitySelectionOrdering } from '../Model/EntitySelectionOrdering';

type Disposer = () => void;

export interface Page
{
    results: EntitySelectionResult[];
    offset: number;
    limit: number;
    numberOfRecords: number;
    dispose: () => void;
}

export interface PaginationQuery
{
    type: EntityType,
    selection: Selection;
    ordering: EntitySelectionOrdering[];
    processResults?: (results: EntitySelectionResult[]) => Promise<Disposer>;
    pageSize?: number;
    pageIdx?: number;
    isDisabled?: boolean;
}

export default async function getPaginationResult(query: PaginationQuery): Promise<Page>
{
    const { type, selection, pageSize, pageIdx } = query;

    // Without an ordering, the pagination will yield double results
    const selectionBuilder = new EntitySelectionBuilder(type, selection.clone());

    if (query.ordering.length === 0)
    {
        selectionBuilder.orderBy(
            selectionBuilder.rootPath
                .field(getTypes().Entity.Field.Id),
            true);
    }
    else
    {
        for (const ordering of query.ordering)
        {
            selectionBuilder.ordering.push(ordering);
        }
    }

    const offset = pageIdx * pageSize;
    const { numberOfRecords, records: results } =
        await selectionBuilder.selectExtendedResult(
            offset,
            pageSize
        );
    const disposers: Disposer[] = [];
    disposers.push(
        () =>
            selectionBuilder.dispose());

    if (query.processResults)
    {
        disposers.push(
            await query.processResults(results));
    }

    const page: Page = {
        results,
        offset,
        limit: pageSize,
        numberOfRecords,
        dispose:
            () =>
                disposers.forEach(
                    disposer =>
                        disposer())
    };

    return page;
}
