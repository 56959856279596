import { observable } from 'mobx';
import { Droppable } from './DragAndDropListener';

export class DragAndDropAction<D>
{
    @observable source: Droppable<D>;
    @observable sourceIndex: number;
    @observable item: D;

    constructor(source: Droppable<D>,
                sourceIndex: number,
                item: D)
    {
        this.source = source;
        this.sourceIndex = sourceIndex;
        this.item = item;
    }
}
