import React from 'react';
import { observer } from 'mobx-react-lite';
import { ComputationEditorProps } from '../../ComputationEditor';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import CurrentDateTimeComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/CurrentDateTimeComputation';

export interface CurrentDateTimeComputationEditorProps extends ComputationEditorProps<ValueType<any>, CurrentDateTimeComputation>
{

}

const CurrentDateTimeComputationEditor: React.FC<CurrentDateTimeComputationEditorProps> =
    props =>
    {
        return <LocalizedText
            code="Generic.CurrentDateTime"
            value="Huidige datum & tijd"
        />;
    };

export default observer(CurrentDateTimeComputationEditor);
