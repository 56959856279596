import { DataComparator } from '../../../../Generic/List/V2/ListStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import { createNumberComparator } from '../../../../Generic/List/V2/Comparator/NumberComparator';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { SortOrder } from '../../../../../@Util/Sort/SortOrder';
import { compareDataObjects } from './compareDataObjects';

const idComparator = createNumberComparator<Entity>(e => e && e.id);

export function compareEntities(e1: Entity,
                                e2: Entity,
                                pathFromRelatedEntity?: RelatedEntityPath,
                                sortOrder: SortOrder = 'Ascending',
                                fieldPath?: EntityFieldPath)
{
    const f1 = e1 && (fieldPath || e1.entityType.bespoke.orderByField(e1, pathFromRelatedEntity));
    const f2 = e2 && (fieldPath || e2.entityType.bespoke.orderByField(e2, pathFromRelatedEntity));
    const isAscending = sortOrder === 'Ascending';

    if (f1 === undefined || f2 === undefined)
    {
        if (isAscending)
        {
            return idComparator(e1, e2);
        }
        else
        {
            return idComparator(e2, e1);
        }
    }
    else
    {
        const d1 = f1.getDataObject(e1);
        const d2 = f2.getDataObject(e2);

        return compareDataObjects(
            d1,
            d2,
            isAscending
        );
    }
}

export function createEntityComparator<D>(retriever: (data: D) => Entity,
                                          pathFromRelatedEntity?: RelatedEntityPath,
                                          sortOrder: SortOrder = 'Descending',
                                          fieldPath?: EntityFieldPath): DataComparator<D>
{
    return (d1, d2) =>
    {
        return compareEntities(
            retriever(d1),
            retriever(d2),
            pathFromRelatedEntity,
            sortOrder,
            fieldPath);
    };
}

export function createCreationDateComparator<D>(retriever: (data: D) => Entity,
                                                sortOrder: SortOrder = 'Descending'): DataComparator<D>
{
    const types = loadModuleDirectly(EntityTypeStore).bespoke.types;

    return createEntityComparator(
        retriever,
        undefined,
        sortOrder,
        EntityPath.fromEntityType(types.Entity.Type).field(types.Entity.Field.CreationDate));
}
