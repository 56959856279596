import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '../../../../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import { makeStyles } from '@material-ui/core';
import { HeaderProps } from './DesktopHeader';

const useStyles = makeStyles({
    checkbox:
        {
            position: 'absolute',
            top: 0,
            left: 0
        }
});

export const MobileHeader: React.FC<HeaderProps> = observer(
    ({
         onSelectProductLine,
         onSelectAllProductLines,
         lines,
         selectedProductLines
     }) =>
    {
        const classes = useStyles();

        const selectAllProductLines =
            useCallback(
                (selected: boolean) =>
                    onSelectAllProductLines(lines, selected),
                [
                    lines,
                    onSelectAllProductLines
                ]);

        const isAllSelected =
            useMemo(
                () =>
                {
                    if (selectedProductLines)
                    {
                        return lines.every(line => selectedProductLines.has(line));
                    }
                    else
                    {
                        return false;
                    }
                },
                [
                    selectedProductLines,
                    lines
                ]);

        return <TableHead>
            <TableRow>
                <TableCell
                    {...{ width: '1%' }}
                >
                    {
                        onSelectProductLine &&
                        <Checkbox
                            onToggle={selectAllProductLines}
                            checked={isAllSelected}
                            className={classes.checkbox}
                        />
                    }
                </TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
    }
);