import React, { useCallback, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardHeader from '../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import StaticSelectbox from '../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import useTypes from '../../../../Type/Api/useTypes';
import { TribeMailboxDetailConfig } from './TribeMailboxDetailConfig';
import { MicrosoftMailboxDetailConfig } from './MicrosoftMailboxDetailConfig';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import { CommitBuilder } from '../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export interface MailboxConfigProps
{
    entity: Entity;
}

export type MailboxType = 'Tribe' | 'Microsoft';

export interface MailboxConfiguration
{
    type: MailboxType;
}

export const MailboxConfig:  React.FC<MailboxConfigProps> = observer(
    ({
        entity
     }) =>
    {
        const types = useTypes();

        const [ credentials , setCredentials ] = useState<any>(undefined);
        const [ configuration , setConfiguration ] = useState<MailboxConfiguration>(
            useEntityValue<MailboxConfiguration | undefined>(
                entity,
                types.Mailbox.Field.Configuration,
                {
                    type: 'Tribe'
                }
            )
        )
        const [ configurationType, setConfigurationType ] =
            useState<MailboxType>(configuration?.type ?? 'Tribe');

        const mailboxTypeOptions =
            useMemo(
                () =>
                    [
                        {
                            id: 'Tribe',
                            label: localizeText('Mailbox.Type.Tribe', 'Tribe mailbox'),
                            value: 'Tribe'
                        },
                        {
                            id: 'Microsoft',
                            label:localizeText('Mailbox.Type.Microsoft', 'Microsoft mailbox'),
                            value: 'Microsoft'
                        }
                    ],
                []
            );

        const saveConfiguration =
            useCallback(
                () =>
                {
                   const commitBuilder =
                       new CommitBuilder()
                            .setObjectValueInEntity(
                                entity,
                                types.Mailbox.Field.Configuration,
                                configuration,
                            );

                   if (credentials)
                   {
                       commitBuilder
                           .setObjectValueInEntity(
                               entity,
                               types.Mailbox.Field.Credentials,
                               credentials,
                           );
                   }

                   commitBuilder
                       .commit()
                        .then();

                },
                [
                    types,
                    entity,
                    configuration,
                    credentials
                ]
            );

        const onChange =
            useCallback(
                (config, cred) =>
                {
                    setConfiguration(config);
                    setConfigurationType(config.type);

                    if (cred)
                    {
                        setCredentials(cred);
                    }
                },
                [
                    setConfiguration,
                    setConfigurationType,
                    setCredentials
                ]
            )

        return <>
            <Grid
                item
                xs={12}
            >
                <Card
                    inset
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={5}
                    >
                        <ViewGroupItem>
                            <CardHeader>
                                <LocalizedText
                                    code="Mailbox.Setup"
                                    value="Mailbox instellen"
                                />
                            </CardHeader>
                        </ViewGroupItem>

                        <ViewGroupItem>
                            <Input
                                label={
                                    <LocalizedText
                                        code="Mailbox.MailboxType"
                                        value="Selecteer Mailbox type"
                                    />
                                }
                                labelPosition="left"
                                inline={true}
                            >
                                <StaticSelectbox
                                    options={mailboxTypeOptions}
                                    value={configurationType}
                                    onChange={
                                        (value) =>
                                            onChange(
                                                { ...configuration, type: value},
                                                {...credentials}
                                            )
                                    }
                                />
                            </Input>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <CardInset>
                                <ViewGroupItem>
                                {
                                    configurationType === 'Tribe' &&
                                        <TribeMailboxDetailConfig
                                            entity={entity}
                                        />
                                }
                                {
                                    configurationType === 'Microsoft' &&
                                        <MicrosoftMailboxDetailConfig
                                            configuration={configuration}
                                            onChange={onChange}
                                        />
                                }
                                </ViewGroupItem>
                            </CardInset>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <RightAlignedButtonGroup>
                                <SaveButton
                                    onClick={saveConfiguration}
                                />
                            </RightAlignedButtonGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </Card>
            </Grid>
        </>
    }
)