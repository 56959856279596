import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PortalPage from './Page/PortalPage';
import PortalHeader from './Header/PortalHeader';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';
import performAction from '../../../../../@Api/Entity/performAction';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import LayoutDependencyContext from '../../../../../@Api/Layout/LayoutDependencyContext';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import Layout from '../../../../../@Api/Layout/Layout';
import LayoutViewer from '../../../../Domain/Layout/Viewer/LayoutViewer';
import BaseLayout from '../../../../Domain/Entity/Viewer/Shared/BaseLayout/BaseLayout';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../@Future/Component/Generic/Loader/Loader';
import HeaderWithScrollBodyView from '../../../../Domain/Layout/Type/HeaderWithScrollBody/View/HeaderWithScrollBodyView';
import PortalSwitcherTabBar from './Header/PortalTabBar/PortalSwitcherTabBar';
import { observable, runInAction } from 'mobx';
import { User } from '../../../../../@Api/Model/Implementation/User';
import ApiControllerContext from '../../../../../@Api/Controller/ApiControllerContext';
import PortalUserContext from './User/PortalUserContext';
import { PortalEnvironmentStore } from './PortalEnvironmentStore';
import Feedback from '../Organization/Feedback/Feedback';
import { getAndInitializeLayoutFromDescriptor } from '../../../../../@Api/Layout/Api/getAndInitializeLayoutFromDescriptor';
import { useHasToEnableTwoFactorAuthenticationForPortalUser } from '../../../../Domain/Security/Api/useHasToEnableTwoFactorAuthenticationForPortalUser';
import TwoFactorAuthenticationSetup from '../../../../Domain/Security/TwoFactorAuthenticationSetup';

export interface PortalProps
{
    store: PortalEnvironmentStore;
}

interface PortalInstantiationDescriptor
{
    portal: PortalDescriptor;
}

interface PortalDescriptor
{
    name: string;
    layout?: any;
}

interface PortalInstantiation
{
    portal: Portal;
    parameters: ParameterDictionary;
    parameterAssignment: ParameterAssignment;
}

interface Portal
{
    name: string;
    layout?: Layout;
}

const PortalEnvironment: React.FC<PortalProps> =
    ({
        store,
     }) =>
    {
        const apiControllerContext = useContext(ApiControllerContext);
        const [ portalUser ] =
            useState(
                () =>
                    observable.box<User>(undefined)
            );

        const [ hasToEnableTwoFactorAuthentication, isLoading ] = useHasToEnableTwoFactorAuthenticationForPortalUser(portalUser?.get());

        useEffect(
            () =>
            {
                apiControllerContext.accountUserController.getUser()
                    .then(
                        user =>
                            runInAction(
                                () =>
                                    portalUser.set(user)
                            )
                    );
            },
            [
                portalUser
            ]
        );

        const [ currentPortalInstantiation ] =
            useAsyncResult<PortalInstantiation>(
                () =>
                    portalUser.get() &&
                        performAction<PortalInstantiationDescriptor>(
                            undefined,
                            {
                                code: 'Portal.GetPortalInstantiation'
                            })
                            .then(
                                async page =>
                                {
                                    const parameters = new ParameterDictionary([]);
                                    const parameterAssignment = new ParameterAssignment();
                                    const layout =
                                        page.result.portal.layout &&
                                            await getAndInitializeLayoutFromDescriptor(
                                                page.result.portal.layout,
                                                new LayoutDependencyContext(parameters)
                                            );

                                    return {
                                        portal: {
                                            name: page.result.portal.name,
                                            layout: layout
                                        },
                                        parameters: parameters,
                                        parameterAssignment: parameterAssignment
                                    }
                                }),
                [
                    portalUser.get()
                ]);

        if (!currentPortalInstantiation || !portalUser.get())
        {
            return <BaseLayout>
                <Centered
                    horizontal
                    vertical
                >
                    <Loader />
                </Centered>
            </BaseLayout>;
        }
        else if (!isLoading && hasToEnableTwoFactorAuthentication && portalUser?.get())
        {
            return  <TwoFactorAuthenticationSetup
                user={portalUser.get()}
            />
        }
        else
        {
            if (currentPortalInstantiation.portal.layout)
            {
                return <PortalUserContext.Provider
                    value={portalUser}
                >
                    <LayoutViewer
                        layout={currentPortalInstantiation.portal.layout}
                        parameterDictionary={currentPortalInstantiation.parameters}
                        parameterAssignment={currentPortalInstantiation.parameterAssignment}
                    />
                    <Feedback store={store.feedbackStore} />
                </PortalUserContext.Provider>;
            }
            else
            {
                return <PortalUserContext.Provider
                    value={portalUser}
                >
                    <HeaderWithScrollBodyView
                        header={
                            <>
                                <PortalHeader />
                                <PortalSwitcherTabBar />
                            </>
                        }
                        body={
                            <PortalPage />
                        }
                    />
                    <Feedback store={store.feedbackStore} />
                </PortalUserContext.Provider>;
            }
        }
    };

export default observer(PortalEnvironment);
