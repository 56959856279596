import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutViewer from '../../../Viewer/LayoutViewer';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableLayoutCellViewer from './TableLayoutCellViewer';
import TableDimensionInstance from '../../../../../../@Api/Layout/Type/Table/Model/TableDimensionInstance';
import LayoutContext from '../../../../../../@Api/Layout/LayoutContext';
import FormLayoutContexts from '../../Form/FormLayoutContexts';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { recordHoverBackgroundColor } from '../../../../../../@Resource/Theme/Theme';
import { TableLayoutViewerProps } from './TableLayoutViewer';
import ChildTable from '../../../../../../@Api/Layout/Type/Table/Model/ChildTable';
import useToggle from '../../../../../../@Util/Toggle/useToggle';
import { TableLayoutBodyViewer } from './TableLayoutBodyViewer';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';

const useStyles = makeStyles({
    clickableRow: {
        '&:hover': {
            backgroundColor: recordHoverBackgroundColor,
            cursor: 'pointer'
        }
    },
    openedRow: {
        backgroundColor: recordHoverBackgroundColor,
        '& td': {
            borderBottomWidth: 10,
        }
    },
    lastInChildTable: {
        '& td': {
            borderBottomWidth: 10,
        }
    }
});

export interface TableLayoutRowViewerProps extends TableLayoutViewerProps
{
    row: TableDimensionInstance;
    columns: TableDimensionInstance[];
    context: FunctionContext;
    percentagePerColumnWidth: number;
    childTablesByRowSectionId: Map<string, ChildTable[]>;
    childTable?: ChildTable;
    last?: boolean;
}

export const TableLayoutRowViewer: React.FC<TableLayoutRowViewerProps> =
    observer(
        props =>
        {
            const {
                safeMode,
                row,
                columns,
                context,
                percentagePerColumnWidth,
                childTablesByRowSectionId,
                childTable,
                last,
            } = props;
            const classes = useStyles();
            const formLayoutContexts = useContext(FormLayoutContexts);
            const childTables =
                useMemo(
                    () =>
                        childTablesByRowSectionId.get(row.section.id) ?? [],
                    [
                        childTablesByRowSectionId,
                        row,
                    ]
                );
            const [ isOpen, toggleOpen ] = useToggle(false);
            const isClickable =
                row.section.onClick !== undefined
                || childTables.length > 0;
            const childContext =
                useComputed(
                    () =>
                        new LayoutContext(
                            ParameterDictionary.union(
                                context.parameterDictionary,
                                row.parameters
                            ),
                            context.parameterAssignment
                                .getNewAssignment(row.parameterAssignment),
                            context.commitContext,
                            safeMode,
                            formLayoutContexts,
                        ),
                    [
                        row,
                        context,
                        safeMode,
                        formLayoutContexts,
                    ]
                );
            const onRowClick =
                useCallback(
                    async (row: TableDimensionInstance) =>
                    {
                        if (row.section.onClick)
                        {
                            await row.section.onClick.apply(childContext);
                        }

                        if (childTables.length > 0)
                        {
                            toggleOpen();
                        }
                    },
                    [
                        childContext,
                        childTables.length,
                        toggleOpen,
                    ]
                );

            return <>
                <TableRow
                    key={row.id}
                    onClick={
                        isClickable
                            ? () => onRowClick(row)
                            : undefined
                    }
                    className={
                        classNames(
                            isClickable && classes.clickableRow,
                            isOpen && classes.openedRow,
                            last && childTable !== undefined && classes.lastInChildTable,
                        )
                    }
                >
                    {
                        row.layout
                            ? <TableCell
                                colSpan={columns.length}
                            >
                                <LayoutViewer
                                    {...props}
                                    parameterDictionary={childContext.parameterDictionary}
                                    parameterAssignment={childContext.parameterAssignment}
                                    layout={row.layout}
                                />
                            </TableCell>
                            : columns.map(
                                column =>
                                    <TableCell
                                        key={column.id}
                                        style={{
                                            width: `${percentagePerColumnWidth * column.size}%`,
                                        }}
                                    >
                                        <TableLayoutCellViewer
                                            {...props}
                                            context={childContext}
                                            row={row}
                                            column={column}
                                        />
                                    </TableCell>
                            )
                    }
                </TableRow>
                {
                    isOpen &&
                    <>
                        {
                            childTables.map(
                                childTable =>
                                    <TableLayoutBodyViewer
                                        key={childTable.id}
                                        {...props}
                                        parameterDictionary={childContext.parameterDictionary}
                                        parameterAssignment={childContext.parameterAssignment}
                                        childTable={childTable}
                                        loader={
                                            <TableRow>
                                                <TableCell
                                                    colSpan={columns.length}
                                                >
                                                    <Centered
                                                        horizontal
                                                    >
                                                        <Loader />
                                                    </Centered>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    />
                            )
                        }
                    </>
                }
            </>;
        }
    );
