import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useComputed } from 'mobx-react-lite';
import { default as ChartModel } from './Model/Chart';
import getChartData, { ChartDataPoint } from './Api/getChartData';
import { Bar, BarChart, Cell, ComposedChart, Legend, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { ColorUtils } from '../../../../@Util/Color/ColorUtils';
import { DataObjectRepresentation } from '../../DataObject/Model/DataObjectRepresentation';
import Editor from '../../DataObject/Editor/Editor';
import { autorun } from 'mobx';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import { DataObject } from '../../DataObject/Model/DataObject';
import Card from '../../../../@Future/Component/Generic/Card/Card';
import CardHeader from '../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import Series from './Model/Series';
import { DataPointValue } from './Api/getSeriesData';
import getDatesFromRange from '../../DataObject/Type/Date/DateRange/Api/getDatesFromRange';
import useLocalPrimitiveSetting from '../../Setting/Api/useLocalPrimitiveSetting';
import combinePredicates from '../Dataset/Segment/Api/combinePredicates';
import Predicate from '../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import useTypes from '../Type/Api/useTypes';
import { compareEntities } from '../List/Comparator/EntityComparator';
import { EntityPath } from '../Path/@Model/EntityPath';
import { observer } from 'mobx-react';
import { compareDataObjects } from '../List/Comparator/compareDataObjects';

export interface ChartProps
{
    id: string;
    chart: ChartModel;
    onSelectPoint?: (point?: ChartDataPoint) => void;
    selectedPoint?: ChartDataPoint;
    onPointsLoaded?: (points: ChartDataPoint[]) => void;
    filter?: Predicate;
}

interface Segment
{
    id: string;
    idx: number;
    series: Series;
    name?: string;
    value: Entity | DataObject;
}

function getValueId(value: Entity | DataObject)
{
    if (value instanceof Entity)
    {
        return value.uuid;
    }
    else if (value instanceof DataObject)
    {
        return value.valueId;
    }
    else
    {
        return 'undefined';
    }
}

function getValueName(value: Entity | DataObject)
{
    if (value instanceof Entity)
    {
        return value.name;
    }
    else if (value instanceof DataObject)
    {
        return value.toString();
    }
    else
    {
        return undefined;
    }
}

const Chart: React.FC<ChartProps> =
    props =>
    {
        const [ points, setPoints ] = useState<ChartDataPoint[]>([]);
        const [ range ] = useLocalPrimitiveSetting(`Chart.Range.${props.id}`, props.chart.range);
        const [ interval ] = useLocalPrimitiveSetting(`Chart.Interval.${props.id}`, props.chart.interval);
        const types = useTypes();

        useEffect(
            () =>
                autorun(
                    () =>
                    {
                        const filter = combinePredicates(props.chart.filter, props.filter);
                        const [ from, to ] = getDatesFromRange(range?.value);

                        getChartData(
                            props.chart,
                            from,
                            to,
                            range,
                            interval,
                            filter)
                            .then(
                                points =>
                                {
                                    setPoints(points);

                                    if (props.onPointsLoaded)
                                    {
                                        props.onPointsLoaded(points);
                                    }
                                });
                    }),
            [
                props.chart,
                props.filter,
                props.onPointsLoaded,
                interval,
                range
            ]);

        const series =
            useComputed(
                () =>
                    props.chart.series
                        .filter(
                            series =>
                                !series.isHidden),
                [
                    props.chart
                ]);

        const data =
            useComputed(
                () =>
                    points
                        .map(
                            point =>
                            {
                                const dataPoint = {
                                    point: point,
                                    name:
                                        point.x.toString(
                                            new DataObjectRepresentation({
                                                isCompact: true,
                                                interval: interval,
                                                range: range
                                            }))
                                };

                                series
                                    .filter(
                                        series =>
                                            point.data.get(series))
                                    .forEach(
                                        series =>
                                        {
                                            const pointValue = point.data.get(series);

                                            if (pointValue.segments.size === 0)
                                            {
                                                dataPoint[series.name] = pointValue.value.value;
                                            }
                                            else
                                            {
                                                pointValue.segments
                                                    .forEach(
                                                        (value, segment) =>
                                                        {
                                                            dataPoint[getValueName(segment)] = value.value;
                                                        });
                                            }
                                        });

                                return dataPoint;
                            }),
                [
                    props.chart,
                    points
                ]);

        const yAxisTickFormatter =
            useCallback(
                (e: any) =>
                {
                    if (points.length === 0)
                    {
                        return e;
                    }
                    else
                    {
                        const someValue = Array.from(points[0].data.values()).find(() => true);

                        if (someValue)
                        {
                            return DataObject.constructFromTypeAndValue(someValue.value.specification.type, e)
                                .toString(new DataObjectRepresentation({ isCompact: true }))
                                .replace(/ /g, '');
                        }
                        else
                        {
                            return e;
                        }
                    }
                },
                [
                    points
                ]);

        const segments =
            useMemo(
                () =>
                {
                    const segments: Segment[] = [];
                    const ids = new Set<string>();
                    let idx = 0;

                    points.forEach(
                        point =>
                            point.data.forEach(
                                (value, series) =>
                                    value.segments.forEach(
                                        (segmentValue, segmentKey) =>
                                        {
                                            const id = getValueId(segmentKey);

                                            if (!ids.has(id))
                                            {
                                                segments.push({
                                                    id: id,
                                                    idx: idx,
                                                    series,
                                                    name: getValueName(segmentKey),
                                                    value: segmentKey
                                                });

                                                ids.add(id);
                                                idx++;
                                            }
                                        })));

                    return segments;
                },
                [
                    points
                ]);

        const segmentByValueId =
            useMemo(
                () =>
                    new Map(
                        segments.map(
                            segment => [
                                segment.id,
                                segment
                            ])),
                [
                    segments
                ]);

        const renderTooltip =
            useCallback(
                (tooltipProps: TooltipProps) =>
                {
                    const { payload } = tooltipProps;

                    if (payload && payload.length > 0)
                    {
                        const point = (payload[0].payload.point as ChartDataPoint);

                        const renderSeries =
                            (series: Series, value: DataPointValue) =>
                                <>
                                    <strong>{series.name}:</strong> {value.value.toString()}
                                    <ul>
                                        {
                                            Array.from(value.segments.entries())
                                                .sort(
                                                    ([a], [b]) =>
                                                        {
                                                            const segmentA = segmentByValueId.get(getValueId(a));
                                                            const segmentB = segmentByValueId.get(getValueId(b));

                                                            if (segmentA.value instanceof Entity && segmentB.value instanceof Entity)
                                                            {
                                                                return compareEntities(
                                                                    segmentA.value as Entity,
                                                                    segmentB.value as Entity,
                                                                    undefined,
                                                                    'Ascending',
                                                                    EntityPath.fromEntityType(types.Datastore.Phase.Type)
                                                                        .field(types.Entity.Field.SortIndex)
                                                                );
                                                            }
                                                            else if (segmentA.value instanceof DataObject && segmentB.value instanceof DataObject)
                                                            {
                                                                return compareDataObjects(
                                                                    segmentA.value,
                                                                    segmentB.value,
                                                                    true
                                                                );
                                                            }
                                                        }
                                                )
                                                .map(
                                                    ([ key, value ]) =>
                                                    {
                                                        const segment = segmentByValueId.get(getValueId(key));

                                                        return <li
                                                            key={segment.id}
                                                        >
                                                            <strong style={{ color: ColorUtils.color(segment.idx)[500] }}>{segment.name}:</strong> {value.toString()}
                                                        </li>;
                                                    })
                                        }
                                    </ul>
                                </>;

                        return <Card
                            inset
                        >
                            <ViewGroup
                                orientation="vertical"
                                spacing={point.data.size === 1 ? 10 : 0}
                            >
                                <ViewGroupItem>
                                    <CardHeader>
                                        {point.x.toString(new DataObjectRepresentation({ interval }))}
                                    </CardHeader>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    {
                                        point.data.size === 1
                                            ?
                                                <>
                                                    {
                                                        renderSeries(
                                                            Array.from(point.data.keys())[0],
                                                            Array.from(point.data.values())[0])
                                                    }
                                                </>
                                            :
                                                <ul>
                                                    {
                                                        Array.from(point.data.entries())
                                                            .filter(
                                                                ([ series ]) =>
                                                                    !series.isHidden)
                                                            .map(
                                                                ([ series, value ]) =>
                                                                    <li
                                                                        key={series.id}
                                                                    >
                                                                        {renderSeries(series, value)}
                                                                    </li>)
                                                    }
                                                </ul>
                                    }
                                </ViewGroupItem>
                            </ViewGroup>
                        </Card>;
                    }
                    else
                    {
                        return null;
                    }
                },
                [
                    types,
                    interval,
                    segmentByValueId
                ]
            );

        const onClick =
            useCallback(
                (data: any) =>
                {
                    if (props.onSelectPoint)
                    {
                        if (!data || props.selectedPoint === points[data.activeTooltipIndex])
                        {
                            props.onSelectPoint(undefined);
                        }
                        else
                        {
                            props.onSelectPoint(points[data.activeTooltipIndex]);
                        }
                    }
                },
                [
                    props.onSelectPoint,
                    props.selectedPoint,
                    points
                ]);

        const containsBars =
            useComputed(
                () =>
                    props.chart.series.some(
                        series =>
                            series.type === 'Bar'
                    ),
                [
                    props.chart
                ]
            );

        const containsLines =
            useComputed(
                () =>
                    props.chart.series.some(
                        series =>
                            series.type === 'Line'
                    ),
                [
                    props.chart
                ]
            );

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            {
                props.chart.interval &&
                    <ViewGroupItem>
                        <CardInset
                            vertical={false}
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                            >
                                <ViewGroupItem
                                    ratio={1}
                                />
                                {
                                    props.chart.range &&
                                        <ViewGroupItem>
                                            <Editor
                                                dataObject={range}
                                            />
                                        </ViewGroupItem>
                                }
                                {
                                    props.chart.interval &&
                                        <ViewGroupItem>
                                            <Editor
                                                dataObject={interval}
                                            />
                                        </ViewGroupItem>
                                }
                            </ViewGroup>
                        </CardInset>
                    </ViewGroupItem>
            }
            <ViewGroupItem>
                <ResponsiveContainer
                    width="100%"
                    height={300}
                >
                    {
                        React.createElement(
                            containsBars && containsLines
                                ? ComposedChart
                                : containsLines
                                    ? LineChart
                                    : BarChart,
                            {
                                data,
                                onClick,
                            },
                            <XAxis
                                dataKey="name"
                                tickCount={points.length}
                            />,
                            <YAxis
                                tickFormatter={yAxisTickFormatter}
                            />,
                            <Tooltip
                                content={renderTooltip}
                            />,
                            <Legend
                                payload={
                                    segments
                                        .slice()
                                        .sort(
                                            (a, b) =>
                                            {
                                                if (a.value instanceof Entity && b.value instanceof Entity)
                                                {
                                                    return compareEntities(
                                                        a.value as Entity,
                                                        b.value as Entity,
                                                        undefined,
                                                        'Ascending',
                                                        EntityPath.fromEntityType(types.Datastore.Phase.Type)
                                                            .field(types.Entity.Field.SortIndex)
                                                    );
                                                }
                                                else if (a.value instanceof DataObject && b.value instanceof DataObject)
                                                {
                                                    return DataObject.compareTo(
                                                        a.value,
                                                        b.value,
                                                        true
                                                    );
                                                }
                                                else
                                                {
                                                    return 0;
                                                }
                                            }
                                        )
                                        .map(
                                            (segment) => ({
                                                id: segment.name,
                                                type: "square",
                                                value:
                                                    segment.value instanceof Entity
                                                        ? segment.value.name ?? ''
                                                        : segment.value instanceof DataObject
                                                            ? segment.value.toString() ?? ''
                                                            : '',
                                                color: ColorUtils.color(segment.idx)[500]
                                            })
                                        )
                                }
                            />,
                            segments.length === 0 &&
                            series.map(
                                (series, seriesIdx) =>
                                {
                                    switch (series.type)
                                    {
                                        case 'Bar':
                                            return <Bar
                                                key={series.id}
                                                dataKey={series.name}
                                                fill={ColorUtils.color(seriesIdx)[500]}
                                            >
                                                {
                                                    points
                                                        .map((entry, idx) =>
                                                            <Cell
                                                                key={idx}
                                                                cursor="pointer"
                                                                fill={props.selectedPoint?.idx === idx ? ColorUtils.color(seriesIdx)[100] : ColorUtils.color(seriesIdx)[500]}
                                                            />)
                                                }
                                            </Bar>;

                                        case 'Line':
                                            return <Line
                                                key={series.id}
                                                dataKey={series.name}
                                                fill={ColorUtils.color(seriesIdx)[500]}
                                                type="monotone"
                                                strokeWidth={2}
                                            >
                                                {
                                                    points
                                                        .map((entry, idx) =>
                                                            <Cell
                                                                key={idx}
                                                                cursor="pointer"
                                                                fill={props.selectedPoint?.idx === idx ? ColorUtils.color(seriesIdx)[100] : ColorUtils.color(seriesIdx)[500]}
                                                            />)
                                                }
                                            </Line>;

                                        default:
                                            return null;
                                    }
                                }
                            ),
                            segments.length > 0 &&
                            segments
                                .map(
                                    (segment, segmentIdx) =>
                                    {
                                        switch (segment.series.type)
                                        {
                                            case 'Bar':
                                                return <Bar
                                                    key={segment.id}
                                                    dataKey={segment.name}
                                                    fill={ColorUtils.color(segmentIdx)[500]}
                                                    stackId="a"
                                                >
                                                    {
                                                        points
                                                            .map((entry, idx) =>
                                                                <Cell
                                                                    key={idx}
                                                                    cursor="pointer"
                                                                    fill={props.selectedPoint?.idx === idx ? ColorUtils.color(segmentIdx)[100] : ColorUtils.color(segmentIdx)[500]}
                                                                />)
                                                    }
                                                </Bar>;

                                            case 'Line':
                                                return <Line
                                                    key={segment.id}
                                                    dataKey={segment.name}
                                                    fill={ColorUtils.color(segmentIdx)[500]}
                                                    type="monotone"
                                                    strokeWidth={2}
                                                />;

                                            default:
                                                return null;
                                        }
                                    }
                                )
                        )
                    }
                </ResponsiveContainer>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(Chart);
