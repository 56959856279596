import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Column from '../../../Model/Specification/Column';
import { Aggregate } from '../../../../../DataObject/Model/Aggregate';
import { NumberType } from '../../../../../DataObject/Type/Number/NumberType';
import { PercentageType } from '../../../../../DataObject/Type/Number/Percentage/PercentageType';
import { DateType } from '../../../../../DataObject/Type/Date/DateType';
import { DateTimeType } from '../../../../../DataObject/Type/Date/DateTime/DateTimeType';
import MenuButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import useSwitch from '../../../../../../../@Util/Switch/useSwitch';
import { runInAction } from 'mobx';
import { primaryColor, textSecondaryColor } from '../../../../../../../@Resource/Theme/Theme';
import { getAggregateSymbol } from '../../../../../DataObject/Util/SymbolUtils';

export interface ColumnAggregateEditorProps
{
    column: Column;
}

export const ColumnAggregateEditor: React.FC<ColumnAggregateEditorProps> =
    observer(
        ({
            column,
         }) =>
        {
            const availableAggregates =
                useComputed<Aggregate[]>(
                    () =>
                    {
                        if (column.fieldPath.isField)
                        {
                            const type = column.fieldPath.field.dataObjectSpecification.type;

                            if (type instanceof NumberType)
                            {
                                const baseAggregates = [
                                    Aggregate.Average,
                                    Aggregate.Min,
                                    Aggregate.Max
                                ];

                                if (type instanceof PercentageType)
                                {
                                    return baseAggregates;
                                }
                                else
                                {
                                    return [
                                        Aggregate.Sum,
                                        ...baseAggregates,
                                    ];
                                }
                            }
                            else if (type instanceof DateType
                                || type instanceof DateTimeType)
                            {
                                return [
                                    Aggregate.Min,
                                    Aggregate.Max
                                ];
                            }
                            else
                            {
                                return [];
                            }
                        }
                        else
                        {
                            return [];
                        }
                    },
                    [column]
                );
            const [ isOpen, open, close ] = useSwitch(false);

            if (availableAggregates.length > 0)
            {
                return <MenuButton
                    open={isOpen}
                    onOpen={open}
                    onClose={close}
                    icon="functions"
                    color={
                        column.aggregate
                            ? primaryColor
                            : textSecondaryColor
                    }
                    tooltip={
                        availableAggregates
                            .map(
                                aggregate =>
                                    getAggregateSymbol(aggregate)
                            )
                            .join(' / ')
                    }
                >
                    <Menu>
                        {
                            availableAggregates.map(
                                aggregate =>
                                    <Item
                                        key={aggregate}
                                        name={getAggregateSymbol(aggregate)}
                                        onClick={
                                            () =>
                                                runInAction(
                                                    () =>
                                                    {
                                                        column.aggregate =
                                                            aggregate === column.aggregate
                                                                ? undefined
                                                                : aggregate;
                                                        close();
                                                    }
                                                )
                                        }
                                        active={aggregate === column.aggregate}
                                    />
                            )
                        }
                    </Menu>
                </MenuButton>;
            }
            else
            {
                return null;
            }
        }
    );