import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { useComputed } from 'mobx-react-lite';
import { useContext } from 'react';
import { Comparator } from '../../../../../DataObject/Model/Comparator';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import DataObjectContext from '../../../../../DataObject/DataObjectContext';
import useTypes from '../../../../Type/Api/useTypes';
import Predicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import ComparisonPredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import PrimitiveValue from '../../../../../../../@Api/Automation/Value/PrimitiveValue';

export default function useFilter(
    parameter: Parameter<EntityValueType>,
    path: EntityPath
): Predicate
{
    const types = useTypes();
    const dataObjectStore = useContext(DataObjectContext);

    return useComputed(
        () =>
        {
            if (path.entityType.isA(types.Activity.Task.Type))
            {
                return new ComparisonPredicate(
                    new ValueFromEntityComputation(
                        parameter,
                        path
                            .joinTo(
                                types.Activity.Task.RelationshipDefinition.Phase,
                                false)
                            .field(types.Datastore.Phase.Field.IsTerminal)
                    ),
                    Comparator.Equals,
                    new PrimitiveValue(
                        DataObject.constructFromTypeIdAndValue(
                            'Boolean',
                            false,
                            dataObjectStore
                        )
                    )
                );
            }
            else
            {
                return undefined;
            }
        },
        [
            types,
            dataObjectStore,
        ]);
}
