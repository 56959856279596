import { Module } from '../index';
import { when } from 'mobx';
import { alg, Graph } from 'graphlib';

export default class ModuleManager
{

    readonly availabilityOnWindowScope: boolean;
    private moduleByRef = new Map<Function, Module<any>>();
    private moduleInitFunctions = new Map<Function, (instance: any) => Promise<any>>();
    private moduleByQualifier = new Map<string, Module<any>>();

    constructor(availabilityOnWindowScope: boolean = false)
    {
        this.availabilityOnWindowScope = availabilityOnWindowScope;
    }

    /**
     * Defines a new module.
     *
     * @param {Module<T>} module
     * @param {(instance: T) => Promise<void>} initialization
     * @returns {Promise<T>}
     */
    public define<T>(module: Module<T>,
                     initialization: (instance: T) => Promise<any> = (instance) => Promise.resolve(instance)): void
    {
        if (module.qualifier)
        {
            this.moduleByQualifier.set(module.qualifier, module);
        }

        this.moduleByRef.set(module.ref, module);
        this.moduleInitFunctions.set(module.ref, initialization);

        if (this.availabilityOnWindowScope)
        {
            (window as any)[module.ref.name] = module.instance;
        }
    }

    /**
     * Gets instance of module by ref.
     *
     * @param {Function} ref
     * @returns {T} instance
     */
    public getInstance<T>(ref: Function,
                          qualifier?: string): T | undefined
    {
        let module =
            (qualifier
                ?
                    this.moduleByQualifier.get(qualifier)
                :
                    this.moduleByRef.get(ref)) as Module<T> | undefined;

        if (module == null)
        {
            return undefined;
        }
        else
        {
            return module.instance;
        }
    }

    /**
     * Gets instance of module by ref, when it is initialized.
     *
     * @param ref
     */
    public awaitInstance<T>(ref: Function,
                            qualifier?: string): Promise<T>
    {
        return new Promise<T>((resolve) =>
            when(
                () =>
                    qualifier
                        ?
                            this.isInitializedByQualifier(qualifier)
                        :
                            this.isInitialized(ref),
                () => resolve(this.getInstance(ref))
            )
        );
    }

    /**
     * Determines whether the module with ref is initialized.
     *
     * @param {Function} ref
     * @returns {boolean}
     */
    private isInitialized(ref: Function): boolean
    {
        const module = this.moduleByRef.get(ref);

        return module !== undefined && module.isSatisfied;
    }

    /**
     * Determines whether the module with qualifier is initialized.
     *
     * @param {Function} ref
     * @returns {boolean}
     */
    private isInitializedByQualifier(qualifier: string): boolean
    {
        const module = this.moduleByQualifier.get(qualifier);

        return module !== undefined && module.isSatisfied;
    }

    /**
     * Creates a promise to initialize all modules held by this module manager.
     *
     * @returns {Promise<void>}
     */
    public async initialize(): Promise<void>
    {
        const dependencyGraph = this.buildDependencyGraph();

        return this.initializeDependencyGraph(dependencyGraph);
    }

    private buildDependencyGraph()
    {
        const graph = new Graph({ directed: true });
        const modules = Array.from(this.moduleByRef.values());

        modules
            .forEach(
                module =>
                    graph.setNode(module.qualifier));

        modules
            .forEach(
                module =>
                    module.dependencyRefs.forEach(
                        dependencyRef =>
                        {
                            const dependency = this.moduleByRef.get(dependencyRef);

                            if (dependency)
                            {
                                graph.setEdge(module.qualifier, dependency.qualifier);
                            }
                            else
                            {
                                console.warn('missing dependency from', module.qualifier, 'to', dependency.qualifier)
                            }
                        }));

        return graph;
    }

    private async initializeDependencyGraph(dependencyGraph: Graph)
    {
        const topSortedQualifiers = alg.topsort(dependencyGraph);

        if (topSortedQualifiers.length > 0)
        {
            const initializedQualifiers = new Map();

            for (const topSortedQualifier of topSortedQualifiers)
            {
                await this.traverseAndInitializeDependencyGraphIfNecessary(
                    dependencyGraph,
                    topSortedQualifier,
                    initializedQualifiers);
            }
        }
    }

    private async traverseAndInitializeDependencyGraphIfNecessary(dependencyGraph: Graph,
                                                                  qualifier: string,
                                                                  qualifiersBeingInitialized: Map<string, Promise<void>>)
    {
        if (qualifiersBeingInitialized.has(qualifier))
        {
            return qualifiersBeingInitialized.get(qualifier);
        }
        else
        {
            const promise =
                this.traverseAndInitializeDependencyGraph(
                    dependencyGraph,
                    qualifier,
                    qualifiersBeingInitialized);

            qualifiersBeingInitialized.set(qualifier, promise);

            return promise;
        }
    }

    private async traverseAndInitializeDependencyGraph(dependencyGraph: Graph,
                                                       qualifier: string,
                                                       qualifiersBeingInitialized: Map<string, Promise<void>>)
    {
        const module = this.moduleByQualifier.get(qualifier);

        if (module)
        {
            const dependencyQualifiers =
                (dependencyGraph.outEdges(qualifier) as any[])
                    .map(
                        edge =>
                            edge.w);

            await Promise.all(
                dependencyQualifiers.map(
                    dependencyQualifier =>
                        this.traverseAndInitializeDependencyGraphIfNecessary(
                            dependencyGraph,
                            dependencyQualifier,
                            qualifiersBeingInitialized)));

            const initializationFn = this.moduleInitFunctions.get(module.ref);

            if (initializationFn)
            {
                await initializationFn(module.instance);
            }

            module.isSatisfied = true;
        }
    }
}
