import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import useTypes from '../../../../../Type/Api/useTypes';

export default function useCampaignRootType(campaign: Entity)
{
    const types = useTypes();

    return useEntityValue(
        campaign,
        types.Activity.Campaign.Field.RootType,
        types.Relationship.Organization.Type);
}
