import React, { Fragment } from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import RichtextEditor from '../../../RichtextEditor/RichtextEditor';
import CollapsibleToolbar from '../../../RichtextEditor/Toolbar/CollapsibleToolbar';
import EditorButton from '../../../RichtextEditor/Toolbar/EditorCommandButton';
import EditorFontSelector from '../../../RichtextEditor/Toolbar/EditorFontSelector';
import EditorColorPicker from '../../../RichtextEditor/Toolbar/EditorColorPicker';
import BlockOptionsPanel from '../Settings/BlockOptionsPanel';
import ColorOptionsPanel from '../Settings/ColorOptionsPanel';
import GutenbergBlockType from '../../Gutenberg/GutenbergBlockType';
import { GutenbergBlockTextId } from '../../DocumentEditor';
import StyledBlock from '../StyledBlock/StyledBlock';
import DefaultToolbarButtons from '../../../RichtextEditor/Toolbar/DefaultToolbarButtons';
import RichText from '../../../../../../../@Component/Generic/RichText/RichText';
import Icon from '../../../../Icon/Icon';

const GutenbergBlockText: GutenbergBlockType =
{
    name: GutenbergBlockTextId,
    configuration:
        {
            title: {
                code: 'GutenbergBlock.Text',
                value: 'Tekst'
            },
            icon: <Icon icon="text_format" />,
            category: 'common',

            attributes:
            {
                content:
                {
                    type: 'string',
                    source: 'html',
                    selector: 'p',
                    query: undefined,
                },
            },

            edit: props =>
            {
                const {
                    content
                } = props.attributes as any;

                return (
                    <Fragment>
                        <InspectorControls>
                            <BlockOptionsPanel props={props} />
                            <ColorOptionsPanel props={props} />
                        </InspectorControls>
                        <StyledBlock
                            block={props}
                        >
                            <RichtextEditor
                                placeholder={'Start writing here'}
                                value={content}
                                onChange={content => props.setAttributes({content: content})}
                                hideToolbarWhenEmpty={false}
                                allowTextStyling
                                showToolbarOnFocus
                            >
                                <CollapsibleToolbar>
                                    <DefaultToolbarButtons />

                                    <EditorButton icon="link" tooltip="Link" command="link"/>
                                    <EditorFontSelector />
                                    <EditorColorPicker tooltip="Selecteer kleur" />
                                </CollapsibleToolbar>
                            </RichtextEditor>
                        </StyledBlock>
                    </Fragment>
                );
            },

            save: (props) =>
            {
                return <p>
                    {(props.attributes as any).content}
                </p>;
            }
        },

    view: block =>
    {
        return <StyledBlock
            block={block}
        >
            <RichText
                value={block.attributes.content}
            />
        </StyledBlock>;
    }
};

export default GutenbergBlockText;
