import { Moment } from 'moment';
import Period from '../Model/Period';

export default function isInPeriod(moment: Moment,
                                   period: Period,
                                   isEndInclusive: boolean = false)
{
    return moment.isSameOrAfter(period.from)
        && (isEndInclusive ? moment.isSameOrBefore(period.to) : moment.isBefore(period.to));
}
