import * as React from 'react';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { OrganizationEnvironmentStore } from './OrganizationEnvironmentStore';
import { observer } from 'mobx-react';
import CurrentUserContext from '../../../../Domain/User/CurrentUserContext';
import LeftDrawerContext from '../../../../Generic/Drawer/LeftDrawerContext';
import RightDrawerContext from '../../../../Generic/Drawer/RightDrawerContext';
import SettingContext from '../../../../Domain/Setting/SettingContext';
import ActionProvider from '../../../../Domain/Action/ActionProvider';
import Content from './Content/Content';
import Multiplayer from '../../../../Domain/Multiplayer/Multiplayer';

@observer
export class InitializedOrganizationEnvironment extends BaseComponent<OrganizationEnvironmentStore>
{
    renderComponent(store: OrganizationEnvironmentStore): any
    {
        return <CurrentUserContext.Provider
            value={store.context.currentUserStore}
        >
            <SettingContext.Provider
                value={store.context.settingStore}
            >
                <LeftDrawerContext.Provider
                    value={store.context.leftDrawerStore}
                >
                    <RightDrawerContext.Provider
                        value={store.context.rightDrawerStore}
                    >
                        <ActionProvider>
                            <Multiplayer>
                                <Content
                                    store={store}
                                />
                            </Multiplayer>
                        </ActionProvider>
                    </RightDrawerContext.Provider>
                </LeftDrawerContext.Provider>
            </SettingContext.Provider>
        </CurrentUserContext.Provider>;
    }
}
