import { observable } from 'mobx';
import AutomationDependencyContext from '../../Automation/AutomationDependencyContext';
import Computation from '../../Automation/Function/Computation/Computation';
import getComputationFromDescriptor from '../../Automation/Api/getComputationFromDescriptor';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';

export type PortalDataSourceOrderingDirection = 'Ascending' | 'Descending';

export default class PortalDataSourceOrdering
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref expression: Computation<any, any>;
    @observable direction: PortalDataSourceOrderingDirection;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                expression: Computation<any, any>,
                direction: PortalDataSourceOrderingDirection)
    {
        this.id = id;
        this.expression = expression;
        this.direction = direction;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return this.expression.validate();
    }

    getDependencies(): Dependency[]
    {
        return this.expression.getDependencies();
    }

    toDescriptor()
    {
        return {
            id: this.id,
            expression: this.expression.toDescriptor(),
            direction: this.direction
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new PortalDataSourceOrdering(
            descriptor.id,
            await getComputationFromDescriptor(
                descriptor.expression,
                dependencyContext),
            descriptor.direction);
    }

    // ----------------------- Private logic ------------------------
}
