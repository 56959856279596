import { EntityCacheReferrer } from './EntityCacheReferrer';

export class EntityCacheInformation
{
    referrers: Set<EntityCacheReferrer>;
    lastAccessDate: Date;

    constructor(referrers: Set<EntityCacheReferrer>)
    {
        this.referrers = referrers;
        this.registerAccess();
    }

    addReferrer(referrer: EntityCacheReferrer)
    {
        this.referrers.add(referrer);
        this.registerAccess();
    }

    removeReferrer(referrer: EntityCacheReferrer)
    {
        return this.referrers.delete(referrer);
    }

    registerAccess()
    {
        this.lastAccessDate = new Date();
    }

    shouldGarbageCollect(
        now: Date,
        minCacheLifetime: number
    )
    {
        return this.referrers.size === 0
            && (now.getTime() - this.lastAccessDate.getTime()) > minCacheLifetime;
    }
}
