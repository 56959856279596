import { observer } from 'mobx-react';
import InputGroup from '../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import TextEditor from '../../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import React from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';

export interface TribeMailboxDetailConfigProps
{
    entity: Entity;
}

export const TribeMailboxDetailConfig: React.FC<TribeMailboxDetailConfigProps> = observer(
    ({
        entity
    }) =>
    {
        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            <ViewGroupItem>
                <LocalizedText
                    code="Mailbox.Setup.ForwardRule"
                    value="Stel bij je e-mailprovider een forward-rule in naar het onderstaande e-mail adres om e-mails automatisch te laten opnemen."
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <InputGroup>
                    <Input
                        labelPosition="left"
                        label={
                            <LocalizedText
                                code="Generic.EmailAddress"
                                value="E-mailadres"
                            />
                        }
                    >
                        <TextEditor
                            value={`${entity.uuid}@mail-in.tribecrm.nl`}
                            disabled
                        />
                    </Input>
                </InputGroup>
            </ViewGroupItem>
        </ViewGroup>
   }
);