import { useCallback } from 'react';
import { EntitySelectionAggregateResult } from '../../../../Selection/Model/EntitySelectionAggregateResult';
import Segment from '../../../Model/Segment';
import useChildSelectionId from './useChildSelectionId';
import { useComputed } from 'mobx-react-lite';

export default function useChildSelection(segment: Segment,
                                          result: EntitySelectionAggregateResult): [ EntitySelectionAggregateResult | undefined, (child?: EntitySelectionAggregateResult) => void]
{
    const [ selectedId, setSelectedId ] = useChildSelectionId(segment);

    const selectedChild =
        useComputed(
            () =>
            {
                if (selectedId)
                {
                    return result?.children.find(
                        child =>
                            child.id === selectedId);
                }
                else
                {
                    return undefined;
                }
            },
            [
                selectedId,
                result
            ]);

    const setSelectedChild =
        useCallback(
            (child?: EntitySelectionAggregateResult) =>
                setSelectedId(child?.id),
            [
                setSelectedId
            ]);

    return [ selectedChild, setSelectedChild ];
}
