import { observable } from 'mobx';
import Input from '../../../../@Component/Domain/Multiplayer/Model/Input/Input';
import Dependency from '../../Parameter/Dependency';
import Validation from '../../Validation/Validation';
import uuid from '../../../../@Util/Id/uuid';

export default abstract class FieldMapping<T extends Input>
{
    // ------------------------- Properties -------------------------

    id: string;
    @observable.ref field: T;

    // ------------------------ Constructor -------------------------

    constructor(field: T)
    {
        this.id = uuid();
        this.field = field;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract validate(): Validation[];

    abstract isAsync(): boolean;

    abstract getDependencies(): Dependency[];

    toDescriptor()
    {
        const descriptor = {
            field: this.field.toDescriptor()
        };

        this.augmentDescriptor(descriptor);

        return descriptor;
    }

    abstract augmentDescriptor(descriptor: any);

    // ----------------------- Private logic ------------------------
}
