import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../Model/DataObjectType';
import { Comparator } from '../../Model/Comparator';
import { NumberView } from './NumberView';
import { NumberEditor } from './NumberEditor';
import { NumberSpecification } from './NumberSpecification';
import { Aggregate } from '../../Model/Aggregate';
import { MathematicalOperator } from '../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../Model/DataObjectFunction';
import { DataObject } from '../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../@Util/DependencyInjection/index';
import { DataDescriptor } from '../../Model/DataDescriptor';
import { NumberRangeValue } from './NumberRange/NumberRangeValue';
import { ComparatorDescriptor } from '../../Model/ComparatorDescriptor';
import { DataObjectRepresentation } from '../../Model/DataObjectRepresentation';
import numbro from 'numbro';
import { DataObjectOperatorOverload } from '../../Model/DataObjectOperatorOverload';
import { DataObjectSpecification } from '../../Model/DataObjectSpecification';
import Decimal from 'decimal.js';
import { DataObjectContext } from '../../Model/DataObjectContext';
import { DataObjectComparatorOverload } from '../../Model/DataObjectComparatorOverload';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { DataObjectBespokeEditorStore } from '../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { NumberEditorStore } from './NumberEditorStore';
import round from 'lodash/round';
import localizeText from '../../../../../@Api/Localization/localizeText';

export class NumberType implements DataObjectType<number>
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Number';
    }

    name()
    {
        return localizeText('DataObject.Type.Number', 'Nummer');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [
            {
                comparator: Comparator.Equals
            },
            {
                comparator: Comparator.NotEquals
            },
            {
                comparator: Comparator.GreaterThan
            },
            {
                comparator: Comparator.GreaterThanOrEqual
            },
            {
                comparator: Comparator.LessThan
            },
            {
                comparator: Comparator.LessThanOrEqual
            },
            {
                comparator: Comparator.In,
                compareTypeId: 'NumberRange'
            }
        ];
    }

    supportedAggregates(): Aggregate[]
    {
        return [ Aggregate.Average, Aggregate.Max, Aggregate.Min, Aggregate.Sum ];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [ MathematicalOperator.Add, MathematicalOperator.Subtract, MathematicalOperator.Multiply, MathematicalOperator.Divide ];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return NumberView;
    }

    editor(): DataObjectEditorType
    {
        return NumberEditor;
    }

    editorStore(editorStore: DataObjectEditorStore): DataObjectBespokeEditorStore
    {
        return new NumberEditorStore(editorStore);
    }

    specification(): DataObjectSpecificationType
    {
        return NumberSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return null;
    }

    requiresInitialization(value: number): boolean
    {
        return false;
    }

    getPrecision(specification: DataObjectSpecification)
    {
        if (specification && specification.data && specification.data.precision != null)
        {
            return specification.data.precision;
        }
        else
        {
            return undefined;
        }
    }

    processPrecision(value: number, specification: DataObjectSpecification)
    {
        const precision = this.getPrecision(specification);

        if (value != null && precision != null)
        {
            return round(value, precision);
        }
        else
        {
            return value;
        }
    }

    getDataFromValue(value: number, specification: DataObjectSpecification): DataDescriptor
    {
        let data = new DataDescriptor();
        data.number1 = this.processPrecision(value, specification);

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): number
    {
        return this.processPrecision(data.number1, specification);
    }

    valueId(value: number): string
    {
        return value.toString();
    }

    getString(value: number,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        if (value == null || isNaN(value) || (typeof value === 'string' && (value as string).trim().length === 0))
        {
            return '';
        }
        else
        {
            if (dataObject.specification && dataObject.specification.data && dataObject.specification.data.mantissa)
            {
                return numbro(value).format(dataObject.specification.data);
            }
            else
            {
                return numbro(value).format();
            }
        }
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        let number1 = lhs?.getValue();

        if (number1 === undefined)
        {
            number1 = 0;
        }

        let number2 = rhs?.getValue();

        if (number2 === undefined)
        {
            number2  = 0;
        }

        switch (comparator)
        {
            case Comparator.GreaterThan:
                return number1 > number2;

            case Comparator.GreaterThanOrEqual:
                return number1 >= number2;
        }
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [];
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        const number1 = new Decimal(lhs == null ? 0 : (lhs.getValue() || 0));
        const number2 = new Decimal(rhs == null ? 0 : (rhs.getValue() || 0));
        let decimal: Decimal = null;

        switch (operator)
        {
            case MathematicalOperator.Add:
                decimal = number1.add(number2);
                break;

            case MathematicalOperator.Subtract:
                decimal = number1.sub(number2);
                break;

            case MathematicalOperator.Multiply:
                decimal = number1.mul(number2);
                break;

            case MathematicalOperator.Divide:
                if (!number2.eq(0))
                {
                    decimal = number1.div(number2);
                }

                break;
        }

        const outcome = decimal
            ?
                this.processPrecision(
                    decimal.toNumber(),
                    (lhs || rhs).specification)
            :
                null;

        if (outcome == null || isNaN(outcome))
        {
            return null;
        }
        else
        {
            return DataObject.constructFromValue(
                (lhs && lhs.specification.type instanceof NumberType
                    ?
                        (lhs && lhs.specification) || new DataObjectSpecification(this, {})
                    :
                        (rhs && rhs.specification) || new DataObjectSpecification(this, {})),
                outcome);
        }
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    intervalTypeId(): string
    {
        return 'Number';
    }

    constructInterval(min: DataObject,
                      max: DataObject,
                      steps: number,
                      intervalToSet: DataObject): void
    {
        let minNumber = min.getValue() || 0;
        let maxNumber = max.getValue() || 0;

        intervalToSet.setValue(Math.abs(maxNumber - minNumber) / steps);
    }

    rangeTypeId(): string
    {
        return 'NumberRange';
    }

    constructRange(from: DataObject,
                   interval: DataObject,
                   rangeToSet: DataObject): void
    {
        let rangeValue: NumberRangeValue =
        {
            from: from.getValue(),
            to: from.getValue() + interval.getValue()
        };

        rangeToSet.setValue(rangeValue);
    }

    isValid(dataObject: DataObject): boolean
    {
        if (dataObject.specification.isRequired)
        {
            return dataObject.getValue() !== undefined && dataObject.getValue() !== null && dataObject.getValue() !== '';
        }

        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return true;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }
}
