import React from 'react';
import LabelButton, { LabelButtonProps } from '../Label/LabelButton';
import { dangerColor } from '../../../../../../@Resource/Theme/Theme';

export interface DangerButtonProps extends LabelButtonProps
{

}

const DangerButton: React.FC<DangerButtonProps> =
    props =>
    {
        return <LabelButton
            {...props}
        />;
    };

DangerButton.defaultProps = {
    color: dangerColor,
    filled: true
};

export default DangerButton;
