import { EntityRelationshipDefinition } from '../../../Model/Implementation/EntityRelationshipDefinition';
import Predicate from '../../../Automation/Function/Computation/Predicate/Predicate';
import isMutable from '../../../RightProfile/isMutable';
import getTypes from '../../../../@Component/Domain/Entity/Type/Api/getTypes';
import { Setting } from '../../../Settings/Setting';
import { EntityType } from '../../../Model/Implementation/EntityType';
import getMetadataSettingValue from '../../getMetadataSettingValue';
import setMetadataSettingValue from '../../setMetadataSettingValue';
import { CommitBuilder } from '../../../Entity/Commit/Context/Builder/CommitBuilder';

export default async function setRelationshipDefinitionFilter(entityType: EntityType,
                                                              relationshipDefinition: EntityRelationshipDefinition,
                                                              isParent: boolean,
                                                              filter: Predicate | undefined)
{
    if (isMutable(relationshipDefinition.entity))
    {
        await setFilterInEntity(relationshipDefinition, isParent, filter);
    }
    else
    {
        await setFilterInSetting(entityType, relationshipDefinition, isParent, filter);
    }
}

async function setFilterInEntity(relationshipDefinition: EntityRelationshipDefinition,
                                 isParent: boolean,
                                 filter?: Predicate)
{
    const types = getTypes();

    return new CommitBuilder()
        .setObjectValueInEntity(
            relationshipDefinition.entity,
            isParent
                ? types.EntityRelationshipDefinition.Field.ParentFilter
                : types.EntityRelationshipDefinition.Field.ChildFilter,
            filter?.toDescriptor()
        )
        .commit();
}

async function setFilterInSetting(entityType: EntityType,
                                  relationshipDefinition: EntityRelationshipDefinition,
                                  isParent: boolean,
                                  filter?: Predicate)
{
    const settingCode =
        isParent
            ? Setting.Metadata.ParentRelationshipDefinitionFilter
            : Setting.Metadata.ChildRelationshipDefinitionFilter;
    const settingValue: any = getMetadataSettingValue(entityType, settingCode);
    const updatedSettingValue = {
        ...(settingValue || {})
    };

    if (filter)
    {
        updatedSettingValue[relationshipDefinition.id] = filter?.toDescriptor();
    }
    else
    {
        delete updatedSettingValue[relationshipDefinition.id];
    }

    await setMetadataSettingValue(
        entityType,
        settingCode,
        updatedSettingValue);
}
