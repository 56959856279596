import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import FilterContext from '../../FilterContext/FilterContext';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import DividedList from '../../../../List/V2/Divided/DividedList';
import usePaginatedSelection from '../../../../View/Api/usePaginatedSelection';
import InfiniteScroll from '../../../../../../../@Future/Component/Generic/InfiniteScroll/InfiniteScroll';
import Divider from '../../../../../../../@Future/Component/Generic/Divider/Divider';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { openEntity } from '../../../../@Util/openEntity';
import NameContext from '../../../Context/NameContext';
import DatasetContext from '../../../Context/DatasetContext';
import IdContext from '../../../Context/IdContext';
import HighlightedEntityContext from '../../../Context/HighlightedEntityContext';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import SelectionOptions from '../../../../SelectionOptions/SelectionOptions';
import ShowMenuButtonInItemsContext from '../../../Context/ShowMenuButtonInItemsContext';
import SegmentContext from '../../SegmentContext/SegmentContext';
import ItemLayoutViewer from '../../../../View/ItemLayout/Viewer/ItemLayoutViewer';
import { getInitializationPathsForParameterFromLayout } from '../../../../../../../@Api/Layout/Api/getInitializationPathsForParameterFromLayout';

export interface InternalListProps
{
    entityType: EntityType;
    root?: boolean;
    disableCard?: boolean;
    disableTopDivider?: boolean;
}

const InternalList: React.FC<InternalListProps> =
    props =>
    {
        const name = useContext(NameContext);
        const filter = useContext(FilterContext);
        const segment = useContext(SegmentContext);
        const dataset = useContext(DatasetContext);
        const showMenuButtonsInItems = useContext(ShowMenuButtonInItemsContext);

        const [ pages, hasMore, loadMore, isLoading ] =
            usePaginatedSelection(
                props.entityType,
                sb =>
                    sb
                        .if(
                            () => true,
                            sb =>
                                props.entityType.bespoke.getListDependencies()
                                    .forEach(
                                        path =>
                                            sb.join(path)
                                    )
                        )
                        .if(
                            () =>
                                filter !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.filter(
                                            filter,
                                            {
                                                parameter: dataset.parameter,
                                            }
                                        )
                                )
                        )
                        .if(
                            () =>
                                dataset.list === undefined,
                            sb =>
                                sb.orderBy(
                                    props.entityType.bespoke.orderByField(),
                                    true
                                )
                        )
                        .if(
                            () =>
                                dataset.list !==  undefined,
                            sb =>
                                dataset.list.ordering
                                    .forEach(
                                        ordering =>
                                            sb.orderBy(
                                                ordering.column.fieldPath,
                                                ordering.isAscending
                                            )
                                    )
                        )
                        .if(
                            () =>
                                dataset.itemLayout !== undefined,
                            sb =>
                                getInitializationPathsForParameterFromLayout(
                                    dataset.itemLayout,
                                    dataset.parameter
                                ).forEach(
                                    path =>
                                        sb.join(path)
                                )
                        ),
                [
                    props.entityType,
                    dataset,
                    filter
                ]);

        const entities =
            useComputed(
                () =>
                    pages.slice()
                        .map(
                            page =>
                                page.slice())
                        .reduce((a, b) => a.concat(b), [])
                        .map(e => e.entity),
                [
                    pages
                ]);

        const datasetId = useContext(IdContext);
        const highlightedEntity = useContext(HighlightedEntityContext);

        const doOpenEntity =
            useCallback(
                (entity: Entity) =>
                {
                    return openEntity(
                        entity,
                        undefined,
                        undefined,
                        {
                            name: name,
                            entityType: props.entityType,
                            filter: filter,
                            dataset: dataset,
                            datasetId: datasetId
                        }
                    );
                },
                [
                    name,
                    props.entityType,
                    filter,
                    dataset,
                    datasetId
                ]);

        if (pages)
        {
            const content =
                <InfiniteScroll
                    loadMore={loadMore}
                    hasMore={hasMore}
                    isLoading={isLoading}
                >
                    <DividedList
                        entities={entities}
                        inset
                        compact
                        hideFavoriteButton
                        onOpen={doOpenEntity}
                        highlightedEntity={highlightedEntity}
                        showMenuButton={showMenuButtonsInItems}
                        renderItem={
                            dataset.itemLayout
                                ?
                                    entity =>
                                        <ItemLayoutViewer
                                            parameters={dataset.parameters}
                                            layout={dataset.itemLayout}
                                            entity={entity}
                                        />
                                :
                                    undefined
                        }
                    />
                    {
                        isLoading &&
                            <>
                                {
                                    pages.length > 0
                                    && !props.disableTopDivider
                                    &&
                                        <Divider />
                                }
                                <CardInset>
                                    <Centered
                                        horizontal
                                    >
                                        <Loader />
                                    </Centered>
                                </CardInset>
                            </>
                    }
                </InfiniteScroll>;

            if (props.disableCard)
            {
                return content;
            }
            else
            {
                return <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    {
                        !props.root &&
                            <ViewGroupItem>
                                <SelectionOptions
                                    entityType={props.entityType}
                                    parameters={dataset.parameters}
                                    name={name}
                                    filter={filter}
                                    filtered={segment !== undefined}
                                    list={dataset.list}
                                    itemLayout={dataset.itemLayout}
                                />
                            </ViewGroupItem>
                    }
                    <ViewGroupItem>
                        <Card>
                            {content}
                        </Card>
                    </ViewGroupItem>
                </ViewGroup>;
            }
        }
        else
        {
            return null;
        }
    };

export default observer(InternalList);
