import React, { useCallback, useMemo } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../Type/Api/useTypes';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import LayoutDependencyContext from '../../../../../../../@Api/Layout/LayoutDependencyContext';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import FormLayout from '../../../../../../../@Api/Layout/Type/Form/FormLayout';
import useFetchedRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity';
import ParameterAssignment from '../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import AutomationDependencyContext from '../../../../../../../@Api/Automation/AutomationDependencyContext';
import FormLayoutViewer from '../../../../../Layout/Type/Form/FormLayoutViewer';
import useDelayedCallback from '../../../../../../../@Future/Util/Hook/useDelayedCallback';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import getPortalRuntimeParameterAssignment from '../../Portal/Api/getPortalRuntimeParameterAssignment';
import getPortalRuntimeParameters from '../../Portal/Api/getPortalRuntimeParameters';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';

export interface UserSettingsProps
{
    portalUser: Entity;
    doAutoCommit?: boolean;
    commitContext?: CommitContext;
}

const PortalUserSettingsEditor: React.FC<UserSettingsProps> =
    props =>
    {
        const { portalUser, doAutoCommit } = props;
        const commitContext = useNewCommitContext(props.commitContext);
        const types = useTypes();
        const [ portal, isLoadingPortal ] =
            useFetchedRelatedEntity(
                portalUser,
                types.Portal.RelationshipDefinition.Users,
                true);
        const layoutDescriptor = useEntityValue(portal, types.Portal.Field.UserSettingsForm);
        const portalParameters =
            useMemo(
                () =>
                    getPortalRuntimeParameters(),
                []);
        const portalParameterAssignment =
            useMemo(
                () =>
                    getPortalRuntimeParameterAssignment(portalParameters, portalUser),
                [
                    portalParameters,
                    portalUser
                ]);
        const [ layout, isLoadingLayout ] =
            useAsyncResult<FormLayout>(
                async () =>
                {
                    if (layoutDescriptor)
                    {
                        return FormLayout.fromDescriptor(
                            layoutDescriptor,
                            new LayoutDependencyContext(portalParameters));
                    }
                    else
                    {
                        return null;
                    }
                },
                [
                    layoutDescriptor,
                    portalParameters
                ]);
        const [ parameterAssignment, isLoadingParameterAssignment ] =
            useAsyncResult(
                async () =>
                {
                    if (layout)
                    {
                        const descriptor = portalUser.getObjectValueByField(types.PortalUser.Field.UserSettingsParameterAssignment);

                        if (descriptor)
                        {
                            return portalParameterAssignment.getNewAssignment(
                                await ParameterAssignment.fromDescriptor(
                                    new AutomationDependencyContext(layout.parameters),
                                    descriptor)
                            );
                        }
                        else
                        {
                            return portalParameterAssignment;
                        }
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    portalUser,
                    types,
                    layout,
                    portalParameterAssignment
                ]);
        const saveParameterAssignment =
            useCallback(
                (parameterAssignmentDescriptor?: any) =>
                {
                    if (parameterAssignmentDescriptor)
                    {
                        setValueByFieldInEntity(
                            portalUser,
                            types.PortalUser.Field.UserSettingsParameterAssignment,
                            parameterAssignmentDescriptor,
                            commitContext
                        );

                        if (doAutoCommit)
                        {
                            return commitEntityWithContext(
                                portalUser,
                                commitContext
                            );
                        }
                    }
                },
                [
                    parameterAssignment,
                    portalUser,
                    types,
                    doAutoCommit,
                    commitContext,
                ]);
        const parameterAssignmentDescriptor =
            useComputed(
                () =>
                    parameterAssignment?.toDescriptor(),
                [
                    parameterAssignment
                ]);

        useDelayedCallback(
            parameterAssignmentDescriptor,
            saveParameterAssignment,
            500);

        if (isLoadingPortal || isLoadingLayout || isLoadingParameterAssignment)
        {
            return <Centered
                horizontal
                vertical
            >
                <Loader />
            </Centered>;
        }
        else
        {
            if (layout && portalParameters && parameterAssignment)
            {
                return <FormLayoutViewer
                    layout={layout}
                    parameterDictionary={portalParameters}
                    parameterAssignment={parameterAssignment}
                    useParentParameterAssignment
                    commitContext={commitContext}
                />;
            }
            else
            {
                return <LocalizedText
                    code="Generic.NoSettings"
                    value="Géén instellingen"
                />;
            }
        }
    };

export default observer(PortalUserSettingsEditor);
