import FilterOption from '../../../Model/FilterOption';
import initializeFilterOption from './initializeFilterOption';

export default async function initializeFilterOptions(filterOptions: FilterOption[])
{
    return Promise.all(
        filterOptions.map(
            filterOption =>
                initializeFilterOption(
                    filterOption.id,
                    filterOption
                )
        )
    );
}
