import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import styles from "./EmailAddressesEditor.module.scss";
import isEmailAddressValid from "../../../../../@Util/EmailAddress/isEmailAddressValid";
import ChipInput from "material-ui-chip-input";
import { useAutoFocusRef } from "../../../../../@Util/Ref/useAutoFocusRef";

const newChipKeys = [ 'Enter', ' ', ';', ',' ];
const classes = {
    chipContainer: styles.chipLabel
};

export interface EmailAddressesEditorProps
{
    value: string[];
    onChange: (value: string[]) => void;
    autoFocus?: boolean;
}

const EmailAddressesEditor: React.FC<EmailAddressesEditorProps> =
    props =>
    {
        const { value, onChange, autoFocus } = props;
        const autoFocusRef = useAutoFocusRef(autoFocus);
        const addEmailAddress =
            useCallback(
                (emailAddress: string) =>
                {
                    if (isEmailAddressValid(emailAddress))
                    {
                        onChange([
                            ...value,
                            emailAddress
                        ]);
                    }
                },
                [
                    value,
                    onChange
                ]);
        const deleteEmailAddress =
            useCallback(
                (emailAddress: string) =>
                    onChange(
                        value
                            .filter(
                                check =>
                                    emailAddress !== check)),
                [
                    value,
                    onChange
                ]);

        return <ChipInput
            value={value}
            onAdd={addEmailAddress}
            onDelete={deleteEmailAddress}
            fullWidth
            placeholder="john@doe.com, jane@doe.com"
            newChipKeys={newChipKeys}
            blurBehavior="add"
            classes={classes}
            ref={autoFocusRef}
        />;
    };

export default observer(EmailAddressesEditor);
