import { findAssistantEntityTypeOrThrow } from './findAssistantEntityTypeOrThrow';
import uuid from '../../../../@Util/Id/uuid';
import { findAssistantFieldPath } from './findAssistantFieldPath';
import { EntityPath } from '../../Entity/Path/@Model/EntityPath';
import Predicate from '../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { findAssistantPredicate } from './Predicate/findAssistantPredicate';
import getViewParameters from '../../Entity/View/Api/getViewParameters';
import { ViewParams } from '../../Entity/View/Model/ViewParams';
import { AssistantTable } from '../Model/Table/AssistantTable';
import View from '../../Entity/View/Model/View';
import Specification from '../../Entity/View/Model/Specification';
import List from '../../Entity/View/Model/Specification/List';
import Column from '../../Entity/View/Model/Specification/Column';
import Ordering from '../../Entity/View/Model/Ordering';

export function findAssistantViewByTable(
    assistantTable: AssistantTable
): View
{
    const entityType = findAssistantEntityTypeOrThrow(assistantTable.entityType);
    const rootPath = EntityPath.fromEntityType(entityType);

    const parameters = getViewParameters(entityType);
    const resultParameter = parameters.getParameterById(ViewParams.Entity);
    let filter: Predicate | undefined;

    if (assistantTable.filter)
    {
        filter =
            findAssistantPredicate(
                assistantTable.filter,
                parameters,
                resultParameter
            );
    }

    const columns =
        assistantTable.columns.map(
            column =>
            {
                try
                {
                    return new Column(
                        uuid(),
                        findAssistantFieldPath(
                            rootPath,
                            column.field
                        )
                    );
                }
                catch (e)
                {
                    console.error(e);

                    return undefined;
                }
            }
        ).filter(
            column =>
                column !== undefined
        );
    const orderByFieldPath =
        assistantTable.orderByField
            ? findAssistantFieldPath(
                rootPath,
                assistantTable.orderByField
            )
            : undefined;
    let orderByColumn =
        orderByFieldPath
            ? columns.find(
                column =>
                    column.fieldPath.id === orderByFieldPath.id
            )
            : undefined;

    if (!orderByColumn
        && orderByFieldPath)
    {
        orderByColumn =
            new Column(
                uuid(),
                orderByFieldPath
            );

        columns.push(orderByColumn);
    }

    const isOrderByAscending =
        assistantTable.orderByDirection
            ? assistantTable.orderByDirection === 'Asc'
            : false;

    return new View(
        'List',
        entityType.getName(true),
        entityType,
        parameters,
        filter,
        new Specification(
            new List(
                columns,
                orderByFieldPath
                    ? [
                        new Ordering(
                            orderByColumn,
                            isOrderByAscending
                        )
                    ]
                    : []
            )
        )
    );
}
