import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DragAndDropStore } from '../../../Generic/DragAndDrop/DragAndDropStore';
import { useEffect } from 'react';
import { DragEndListener } from './DragEndListener';

export default function useOnDragEnd(listener: DragEndListener)
{
    useEffect(
        () =>
        {
            const dragAndDropStore = loadModuleDirectly(DragAndDropStore);
            dragAndDropStore.listenToDragEnd(listener);

            return () =>
            {
                dragAndDropStore.unlistenToDragEnd(listener);
            };
        },
        [
            listener
        ]);
}