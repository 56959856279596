import React, { useCallback } from 'react';
import Link from '../../../../../@Future/Component/Generic/Link/Link';

export interface LogEventsViewerLinkProps
{
    uuid: string;
    name: string;
}

export const LogEventsViewerLink: React.FC<LogEventsViewerLinkProps> =
    (
        {
            uuid,
            name
        }
    ) =>
    {
        const onClick = useCallback(
            async (event: any) =>
            {
                event.stopPropagation();
                window.open(`/entity/${uuid}`)
            },
            [
                uuid
            ]
        );

        return <Link
            onClick={onClick}
            highlighted
        >
            {name}
        </Link>;
    };