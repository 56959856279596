import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { IObservableArray, runInAction } from 'mobx';
import styles from './ConditionalEditor.module.scss';
import PredicateEditor from '../../../../Predicate/PredicateEditor';
import Predicate from '../../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { ConditionalActionEditorProps } from '../ConditionalActionEditor';
import ConditionalInAction from '../../../../../../../../../../../@Api/Automation/Function/Action/ConditionalInAction';
import ActionEditor from '../../../ActionEditor';
import Action from '../../../../../../../../../../../@Api/Automation/Function/Action/Action';
import DeleteIconButton from '../../../../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import LocalizedText from '../../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface ConditionalEditorProps extends ConditionalActionEditorProps
{
    conditional: ConditionalInAction;
}

const ConditionalEditor: React.FC<ConditionalEditorProps> =
    props =>
    {
        const setPredicate =
            useCallback(
                (predicate: Predicate) =>
                    runInAction(
                        () =>
                        {
                            props.conditional.predicate = predicate;
                        }),
                [
                    props.conditional
                ]);

        const setAction =
            useCallback(
                (action: Action<any, any>) =>
                    runInAction(
                        () =>
                        {
                            props.conditional.action = action;
                        }),
                [
                    props.conditional
                ]);

        const deleteConditional =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (props.action.conditionals as IObservableArray).remove(props.conditional)),
                [
                    props.action
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                >
                    <ViewGroupItem
                        className={styles.label}
                    >
                        <LocalizedText
                            code="Generic.InCase"
                            value="Indien"
                        />
                    </ViewGroupItem>
                    <ViewGroupItem
                        ratio={1}
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={5}
                        >
                            <ViewGroupItem>
                                <PredicateEditor
                                    value={props.conditional.predicate}
                                    onChange={setPredicate}
                                    context={props.context}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={15}
                                >
                                    <ViewGroupItem
                                        className={styles.label}
                                    >
                                        <LocalizedText
                                            code="Generic.Then"
                                            value="Dan"
                                            lowerCase
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        <ActionEditor
                                            action={props.conditional.action}
                                            onChange={setAction}
                                            context={props.context}
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <DeleteIconButton
                            onClick={deleteConditional}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(ConditionalEditor);
