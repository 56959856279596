import { EntitySelectionAggregateResult } from '../../Selection/Model/EntitySelectionAggregateResult';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import { compareEntities } from '../../List/Comparator/EntityComparator';
import Segment from '../Model/Segment';
import { createStringComparator } from '../../../../Generic/List/V2/Comparator/StringComparator';
import getResultName from '../Segment/Api/getResultName';

export function createResultComparator<D>(retriever: (data: D) => EntitySelectionAggregateResult,
                                          segment: Segment,
                                          pathFromRelatedEntity?: RelatedEntityPath)
{
    if (segment.groupFieldPath.field)
    {
        return createStringComparator(
            (d: D) =>
                getResultName(segment, retriever(d), 0)
        );
    }
    else
    {
        return (d1: D, d2: D) =>
        {
            const r1 = retriever(d1);
            const r2 = retriever(d2);

            return compareEntities(
                r1 && r1.groupEntity,
                r2 && r2.groupEntity,
                pathFromRelatedEntity);
        };
    }
}
