import { ApiBaseType } from '../ApiBaseType';
import { injectWithQualifier, ModuleManager } from '../../../../../../@Util/DependencyInjection/index';
import { DataObject } from '../../../Model/DataObject';
import Dictionary from 'typescript-collections/dist/lib/Dictionary';
import { User } from '../../../../../../@Api/Model/Implementation/User';
import { DataObjectSpecification } from '../../../Model/DataObjectSpecification';
import { EntityTypeStore } from '../../../../Entity/Type/EntityTypeStore';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export class UserType extends ApiBaseType<User>
{
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'User';
    }

    name()
    {
        return localizeText('DataObject.Type.User', 'Gebruiker');
    }

    getInstanceId(instance: User): number
    {
        return instance.id;
    }

    getInstanceName(instance: User): string
    {
        if (instance.entity)
        {
            return instance.entity.name;
        }
        else
        {
            return null;
        }
    }

    getInstanceDescription(instance: User): string
    {
        return null;
        // return instance.entity.description;
    }

    getInstanceAvatarUrl(instance: User, moduleManager: ModuleManager): string
    {
        return null;
    }

    getInstances(ids: number[],
                 dataObjectById: Dictionary<number, DataObject>,
                 moduleManager: ModuleManager): Promise<User[]>
    {
        return Promise.resolve([]);
    }

    searchInstances(query: string, limit: number, dataObject: DataObject, moduleManager: ModuleManager): Promise<User[]>
    {
        return Promise.resolve([]);
    }

    getAllInstances(specification: DataObjectSpecification,
                    moduleManager: ModuleManager): Promise<User[]>
    {
        return Promise.resolve([]);
    }

    initializeUsers(users: User[]): User[]
    {
        users.forEach(
            user =>
                user.initialize(this.entityTypeStore));

        return users;
    }
}
