import { reference, type } from '../../../@Util/Serialization/Serialization';
import { Entity } from './Entity';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { EntityRelationshipDefinition } from './EntityRelationshipDefinition';
import { action, observable } from 'mobx';
import { getModel, isTransactionalModel } from '../../../@Util/TransactionalModelV2';
import { v4 as uuid } from 'uuid';
import { registerBuilder } from '../../../@Util/TransactionalModelV2/Shared/TransactionalBuilder';
import getRelationshipDescriptor from '../../Entity/Descriptor/getRelationshipDescriptor';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { isRelationshipDefinitionRequired } from '../../Metadata/Input/isRelationshipDefinitionRequired';

@type('EntityRelationship')
export class EntityRelationship
{

    // ------------------- Persistent Properties --------------------

    @observable id: number;
    @observable uuid: string;
    @reference(undefined, 'Entity') @observable.ref parentEntity: Entity;
    @reference(undefined, 'Entity') @observable.ref childEntity: Entity;
    @reference(undefined, 'EntityRelationshipDefinition') @observable.ref definition: EntityRelationshipDefinition;

    // ------------------------- Properties -------------------------

    @observable isManaged: boolean;
    @observable isInitialized: boolean;
    @observable isDeleted: boolean;

    // ------------------------ Constructor -------------------------

    constructor(id?: number,
                parentEntity?: Entity,
                childEntity?: Entity,
                definition?: EntityRelationshipDefinition)
    {
        this.id = id;
        this.parentEntity = parentEntity;
        this.childEntity = childEntity;
        this.definition = definition;
        this.isManaged = true;
        this.isInitialized = false;
        this.isDeleted = false;
    }

    // ----------------------- Initialization -----------------------

    initialize(entityTypeStore: EntityTypeStore = loadModuleDirectly(EntityTypeStore),
               onEntity?: (entity: Entity) => void,
               onRelationship?: (relationship: EntityRelationship) => void,
               force: boolean = false,
               doMergeEditableValues: boolean = true,
               doPerformLocalAutomations: boolean = true): this
    {
        if (this.isInitialized && !force)
        {
            return this;
        }

        this.isInitialized = true;

        if (!this.uuid)
        {
            this.uuid = uuid();
        }

        if (this.definition)
        {
            const definition =
                entityTypeStore.getRelationshipDefinitionById(this.definition.id);

            if (!definition)
            {
                console.warn('relationship definition not found', this, this.definition);
            }

            this.definition = definition;
        }
        else
        {
            console.warn('relationship definition not found', this, this.definition);
        }

        this.initializeRelatedEntity(
            this.parentEntity,
            entityTypeStore,
            onEntity,
            onRelationship,
            force,
            doMergeEditableValues,
            doPerformLocalAutomations);

        this.initializeRelatedEntity(
            this.childEntity,
            entityTypeStore,
            onEntity,
            onRelationship,
            force,
            doMergeEditableValues,
            doPerformLocalAutomations);

        if (onRelationship)
        {
            onRelationship(this);
        }

        return this;
    }

    initializeRelatedEntity(relatedEntity: Entity,
                            entityTypeStore: EntityTypeStore,
                            onEntity: (entity: Entity) => void,
                            onRelationship: (relationship: EntityRelationship) => void,
                            force: boolean,
                            doMergeEditableValues: boolean,
                            doPerformLocalAutomations: boolean)
    {
        if (relatedEntity == null)
        {
            // console.warn('related entity is null in relationship id:', this);
        }
        else
        {
            relatedEntity
                ._initialize(
                    entityTypeStore,
                    onEntity,
                    onRelationship,
                    undefined,
                    false,
                    doMergeEditableValues,
                    undefined,
                    doPerformLocalAutomations);
        }
    }

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    @action
    setEntity(isParent: boolean,
              entity: Entity)
    {
        if (this.getEntity(isParent) !== entity)
        {
            if (isParent)
            {
                this.parentEntity = entity;
            }
            else
            {
                this.childEntity = entity;
            }
        }
    }

    @action
    restore()
    {
        if (this.isDeleted)
        {
            this.isDeleted = false;

            [ true, false ]
                .forEach(
                    isParent =>
                    {
                        const relatedEntity = this.getEntity(isParent);

                        if (relatedEntity)
                        {
                            relatedEntity.getDeletedRelationships(!isParent)
                                .remove(this);

                            relatedEntity.getRelationships(!isParent)
                                .push(this);
                        }
                    });
        }
    }

    @action
    setManaged(isManaged: boolean)
    {
        if (isTransactionalModel(this))
        {
            (getModel(this) as any).isManaged = isManaged;
        }
        else
        {
            this.isManaged = isManaged;
        }
    }

    // ------------------------ Public logic ------------------------

    isNew(): boolean
    {
        return this.id == null || this.id === 0;
    }

    getEntity(isParent: boolean): Entity
    {
        return isParent ? this.parentEntity : this.childEntity;
    }

    isDeletable(isParent: boolean): boolean
    {
        if (isRelationshipDefinitionRequired(this.getEntity(!isParent).entityType, isParent, this.definition))
        {
            return this.getEntity(!isParent)
                .getRelationshipsByDefinition(isParent, this.definition)
                .filter(relationship => relationship !== this)
                .length > 0;
        }
        else
        {
            return true;
        }
    }

    descriptor(isParent?: boolean): any
    {
        return getRelationshipDescriptor(
            this as any,
            isParent);
    }

    // ----------------------- Private logic ------------------------
}

registerBuilder(EntityRelationship)
    .id(relationship => relationship.uuid)
    .includeAll()
    .deep('parentEntity')
    .deep('childEntity')
    .deepPredicate(
        context =>
        {
            const model = context.secondToLastValue; // secondToLastValue = relationship, value = entity, path = .childEntity

            if (model instanceof EntityRelationship
                && (context.key === 'parentEntity' || context.key === 'childEntity'))
            {
                const toParent = context.key === 'parentEntity';
                const relatedEntity = model.getEntity(toParent);

                // TODO [DD]: for now we exclude only extensions of layouts -- we can extend this behaviour to all
                // TODO [DD]: all entity types using the isManaged. But we must first set it and test it on all entities
                if (relatedEntity && relatedEntity.isNew())
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }
            else
            {
                return true;
            }
        });
