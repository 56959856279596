import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Value from '../../Value/Value';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import CollectionValue from '../../Value/CollectionValue';
import localizeText from '../../../Localization/localizeText';
import CollectionType from '../../Value/Type/CollectionType';

export default class SingletonCollectionComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref value: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(value: Computation<any, any>)
    {
        super();

        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new CollectionType(
            this.value.getType()
        );
    }

    getName(): string
    {
        return localizeText(
            'SingletonCollection',
            'Collectie uit element'
        );
    }

    validate(): Validation[]
    {
        return this.value.validate();
    }

    isAsync(): boolean
    {
        return this.value.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const value = await safelyApplyFunction(this.value, context);

        return new CollectionValue(
            [value],
            value.getType()
        );
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        const value = safelySynchronousApplyFunction(this.value, context);

        return new CollectionValue(
            [value],
            value.getType()
        );
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'SingletonCollection';
        descriptor.value = this.value.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.value.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new SingletonCollectionComputation(
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
