import React, { useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityFieldGroup } from '../../../../Management/Application/EntityTypesEditor/EntityFieldGroup';
import { createNumberComparator } from '../../../../../Generic/List/V2/Comparator/NumberComparator';
import EntityTypeContext from '../../../Type/EntityTypeContext';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Fields, { FieldsProps } from '../../Fields';
import styles from './Expansion.module.scss';
import FieldInsetContext from '../../Context/FieldInsetContext';
import getFieldOrderingInType from '../../../../../../@Api/Metadata/Field/getFieldOrderingInType';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../Input/Input';
import RelationshipConstructor from '../../RelationshipConstructor/RelationshipConstructor';
import useTypes from '../../../Type/Api/useTypes';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import FieldParentPathContext from '../../Context/FieldParentPathContext';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import { useInheritedAddressRelationshipDefinitions } from '../../Api/useInheritedAddressRelationshipDefinitions';
import getAllFieldGroups from '../../Api/getAllFieldGroups';

export interface ExpansionProps extends FieldsProps
{
    fieldPaths: EntityFieldPath[];
    group: EntityFieldGroup;
    readOnly?: boolean;
    includeFieldPathsFromUnknownGroups?: boolean; // Also show fieldpaths for which the group of fieldpath is not known in this entityType
}

const Expansion: React.FC<ExpansionProps> =
    props =>
    {
        const types = useTypes();
        const hasInset = useContext(FieldInsetContext);
        const entityTypeStore = useContext(EntityTypeContext);
        const fieldOrdering =
            useComputed(
                () =>
                    getFieldOrderingInType(props.forType || props.entity.entityType),
                [
                    props.forType,
                    props.entity
                ]
            );
        const addressRelationshipDefinitions =
            useInheritedAddressRelationshipDefinitions(props.entity)
                .filter(
                    relationshipDefinition =>
                        equalsEntity(
                                relationshipDefinition.entity.getRelatedEntityByDefinition(
                                    true,
                                    types.EntityFieldGroup.RelationshipDefinition.Relationships
                                ),
                                props.group.entity
                        )
                        || (
                            !relationshipDefinition.entity.hasRelationshipsByDefinition(
                                    true,
                                    types.EntityFieldGroup.RelationshipDefinition.Relationships
                            )
                            && !props.group.entity
                        )
                );
        const addressRelationshipDefinitionsAsSet =
            useMemo(
                () =>
                    new Set(addressRelationshipDefinitions),
                [addressRelationshipDefinitions]
            );

        const entityTypeFieldGroups =
            useMemo(
                () =>
                    getAllFieldGroups(props.entity.entityType),
                [
                    props.entity.entityType
                ]
            );

        const fieldPaths =
            useComputed(
                () =>
                    props.fieldPaths
                        .filter(
                            path =>
                                path.isEditable(props.entity) &&
                                (
                                    path.isInFieldGroup(props.group, entityTypeStore)
                                    ||
                                    (
                                        props.includeFieldPathsFromUnknownGroups &&
                                        !entityTypeFieldGroups.some(
                                            group =>
                                                path.isInFieldGroup(group, entityTypeStore)
                                        )
                                    )
                                )
                        )
                        .filter(
                            path =>
                                !path.isRelationship
                                || !addressRelationshipDefinitionsAsSet.has(path.relationshipDefinition)
                        )
                        .filter(
                            path =>
                                props.hidden
                                    ? !props.hidden(path)
                                    : true
                        )
                        .sort(
                            createNumberComparator(
                                fieldPath =>
                                    fieldOrdering[fieldPath.id] ?? fieldPath.sortIndex,
                                true
                            )
                        ),
                [
                    props.fieldPaths,
                    props.entity,
                    props.group,
                    props.hidden,
                    entityTypeStore,
                    fieldOrdering
                ]
            );
        const dividerGlue = useDividerGlue();
        const parentPath = useContext(FieldParentPathContext);
        const rootPath =
            useMemo(
                () =>
                    parentPath
                        ? parentPath
                        : EntityPath.fromEntity(props.entity),
                [
                    parentPath,
                    props.entity,
                ]
            );

        if (props.group.customId === 'privatedata')
        {
            return <CardInset
                horizontal={hasInset}
                top={false}
                bottom={false}
                classes={{
                    root: styles.expansion
                }}
            >
                <Fields
                    {...props}
                    noVirtualGroups
                />
                {props.appendix}
            </CardInset>;
        }
        else
        {
            return <CardInset
                horizontal={hasInset}
                top={false}
                bottom={addressRelationshipDefinitions.length === 0}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <InputGroup>
                            {
                                fieldPaths.map(
                                    fieldPath =>
                                        <Input
                                            key={fieldPath.id}
                                            entity={props.entity}
                                            field={fieldPath}
                                            doAutocommit={props.doAutoCommit ?? props.entity.doAutoCommit}
                                            touched={props.touched}
                                            autoFocus={props.autoFocus ? props.autoFocus(fieldPath) : undefined}
                                            required={props.required ? props.required(fieldPath) : undefined}
                                            disabled={props.disabled ? props.disabled(fieldPath) : props.readOnly}
                                            labelPosition="left"
                                            commitContext={props.commitContext}
                                        />
                                )
                            }
                        </InputGroup>
                    </ViewGroupItem>
                    {
                        props.appendix &&
                        <ViewGroupItem>
                            {props.appendix}
                        </ViewGroupItem>
                    }
                    {
                        addressRelationshipDefinitions.length > 0 &&
                            <ViewGroupItem>
                                <Divider />
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={0}
                                    glue={dividerGlue}
                                >
                                    {
                                        addressRelationshipDefinitions.map(
                                            relationshipDefinition =>
                                                <ViewGroupItem
                                                    key={relationshipDefinition.code}
                                                >
                                                    <FieldParentPathContext.Provider
                                                        value={
                                                            rootPath.joinTo(
                                                                relationshipDefinition,
                                                                false
                                                            )
                                                        }
                                                    >
                                                        <RelationshipConstructor
                                                            entity={props.entity}
                                                            relationshipDefinition={relationshipDefinition}
                                                            isParent={false}
                                                            // inset
                                                            autoCommit={props.entity.doAutoCommit ?? !props.entity.isNew()}
                                                            expanded={props.expanded}
                                                            commitContext={props.commitContext}
                                                            disabled={props.readOnly ? () => true : undefined}
                                                        />
                                                    </FieldParentPathContext.Provider>
                                                </ViewGroupItem>)
                                    }
                                </ViewGroup>
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </CardInset>;
        }
    };

export default observer(Expansion);
