import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import LayoutViewer from '../../../../Layout/Viewer/LayoutViewer';
import { getViewParameterAssignment } from '../../Api/getViewParameterAssignment';
import { useNewCommitContext } from '../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import Layout from '../../../../../../@Api/Layout/Layout';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';

export interface ItemLayoutViewerProps
{
    parameters: ParameterDictionary;
    entity: Entity;
    layout: Layout;
}

const ItemLayoutViewer: React.FC<ItemLayoutViewerProps> =
    ({
        parameters,
        entity,
        layout,
     }) =>
    {
        const commitContext = useNewCommitContext();
        const parameterAssignment =
            useMemo(
                () =>
                    getViewParameterAssignment(
                        parameters,
                        entity
                    ),
                [
                    parameters,
                    entity,
                ]
            );
        const isValid =
            useMemo(
                () =>
                    layout.validate().length === 0,
                [
                    layout
                ]
            );

        if (isValid)
        {
            return <LayoutViewer
                layout={layout}
                parameterDictionary={parameters}
                parameterAssignment={parameterAssignment}
                commitContext={commitContext}
            />;
        }
        else
        {
            return <span>
                Invalid layout
            </span>;
        }
    };

export default observer(ItemLayoutViewer);
