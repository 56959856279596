import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import BodyView from './View/BodyView';
import BodyLayout from '../../../../../@Api/Layout/Type/BodyLayout';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import Layout from '../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';

export interface BodyLayoutEditorProps extends LayoutEditorProps<BodyLayout>
{

}

const BodyLayoutEditor: React.FC<BodyLayoutEditorProps> =
    props =>
    {
        const setLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.layout = layout),
                [
                    props.layout
                ]);

        return <BodyView
            layout={props.layout}
        >
            <ChildLayoutEditor
                {...props}
                childLayout={props.layout.layout}
                onChange={setLayout}
            />
        </BodyView>;
    };

export default observer(BodyLayoutEditor);
