import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { BespokeEntityType } from '../BespokeEntityType';
import { EntityTypeStore } from '../EntityTypeStore';

export class BespokeNote extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Note')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // isOpenable(entity: Entity,
    //            pathFromRelatedEntity?: RelatedEntityPath): boolean
    // {
    //     return false;
    // }

    getEntityToOpen(entity: Entity): Entity
    {
        return entity.getRelatedEntityByDefinition(
            true,
            this.types.Entity.RelationshipDefinition.Notes);
    }

    getEntityToList(entity: Entity, isRelated: boolean): Entity
    {
        if (isRelated)
        {
            return entity;
        }
        else
        {
            return entity.getRelatedEntityByDefinition(
                true,
                this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Notes);
        }
    }

    getColor(entity: Entity): string
    {
        const owner =
            entity.getRelatedEntityByDefinition(
                true,
                this.entityTypeStore.bespoke.types.Entity.RelationshipDefinition.Notes);

        if (owner)
        {
            return owner.entityType.getInheritedColor();
        }
        else
        {
            return entity.entityType.getInheritedColor();
        }
    }

    // ----------------------- Private logic ------------------------
}
