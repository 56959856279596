import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import ScrollRefContext from '../../../../../@Service/Navigation/Page/Scroll/ScrollRefContext';
import View from '../Model/View';
import { useOnBeforeRoute } from '../../../../../@Service/Router/Model/Api/useOnBeforeRoute';
import { ViewStateOpenedGroup } from '../State/ViewState';
import { ViewStateContext } from '../State/ViewStateContext';
import { PersistViewStateContext } from '../State/PersistViewStateContext';

export function useAndMaintainViewRoutingState(
    saveViewState: boolean,
    view: View,
    searchQuery: string,
    loadMore?: (nextPage?: number) => Promise<boolean>,
    numberOfLoadedPages?: number,
    openedGroup?: ViewStateOpenedGroup
): [
    string,
    (entityId: string) => void,
    ViewStateOpenedGroup[],
    (group: ViewStateOpenedGroup) => void,
    (group: ViewStateOpenedGroup) => void
]
{
    const scrollRef = useContext(ScrollRefContext);
    const viewState = useContext(ViewStateContext);
    const setViewState = useContext(PersistViewStateContext);
    const scrollTop = useRef<number>(viewState?.scrollTop);
    const [ isDoneLoading, setDoneLoading ] = useState(false);
    const lastLoadedPageIdx = useRef<number>(openedGroup?.lastLoadedPageIdx ?? viewState?.lastLoadedPageIdx);
    
    useEffect(
        () =>
        {
            if (numberOfLoadedPages !== undefined
                && viewState !== undefined
                && isDoneLoading)
            {
                if (openedGroup)
                {
                    openedGroup.lastLoadedPageIdx = numberOfLoadedPages;
                }
                else
                {
                    viewState.lastLoadedPageIdx = numberOfLoadedPages;
                }
            }
        },
        [isDoneLoading, numberOfLoadedPages, openedGroup, viewState]
    );
    
    const scrollListener =
        useCallback(
            async () =>
            {
                scrollTop.current = scrollRef?.current?.scrollTop;
            },
            [scrollRef]
        );

    useLayoutEffect(
        () =>
        {
            const scrollParent = scrollRef?.current;

            if (saveViewState && scrollParent !== undefined)
            {
                scrollParent.addEventListener('scroll', scrollListener);
            }

            return () =>
            {
                if (saveViewState && scrollParent !== undefined)
                {
                    scrollParent.removeEventListener('scroll', scrollListener);
                }
            };
        },
        [
            scrollRef,
            scrollListener,
            saveViewState
        ]
    );

    // Whenever the last page is loaded, then reset the scroll position to where it was
    useEffect(
        () =>
        {
            const isAllPagesLoaded =
                numberOfLoadedPages !== undefined
                && numberOfLoadedPages === lastLoadedPageIdx.current;
            
            if (saveViewState
                && isAllPagesLoaded
                && viewState.scrollTop
                && scrollRef)
            {
                scrollRef.current.scrollTo(0, viewState.scrollTop);
            }
        },
        [saveViewState, lastLoadedPageIdx, numberOfLoadedPages, scrollRef, viewState]
    );

    // When we route away from the component, save the view state
    useOnBeforeRoute(
        async () =>
        {
            if (saveViewState)
            {
                setViewState({
                    ...viewState,
                    searchQuery,
                    descriptor: view.toDescriptor(true),
                    lastLoadedPageIdx: lastLoadedPageIdx.current,
                    scrollTop: scrollTop.current,
                });
            }

            return true;
        },
        [
            saveViewState,
            setViewState,
            searchQuery,
            viewState,
            view,
            lastLoadedPageIdx,
        ]
    );

    // Load all pages up until and including the last loaded page
    useEffect(
        () =>
        {
            const lastLoadedPageIdxValue =
                openedGroup?.lastLoadedPageIdx
                ?? lastLoadedPageIdx.current;

            if (saveViewState
                && loadMore !== undefined
                && numberOfLoadedPages !== undefined
                && lastLoadedPageIdxValue > 0
                && numberOfLoadedPages < lastLoadedPageIdxValue)
            {
                loadMore().finally();
            }
            else if (lastLoadedPageIdxValue === undefined
                || numberOfLoadedPages === lastLoadedPageIdxValue)
            {
                setDoneLoading(true);
            }
        },
        [saveViewState, lastLoadedPageIdx, numberOfLoadedPages, loadMore, openedGroup?.lastLoadedPageIdx, openedGroup]
    );

    const setLastOpenedEntityIdInRoutingState =
        useCallback(
            (entityId: string) =>
            {
                if (saveViewState
                    && viewState !== undefined)
                {
                    if (openedGroup)
                    {
                        openedGroup.lastOpenedEntityId = entityId;
                    }
                    else
                    {
                        viewState.lastOpenedEntityId = entityId;   
                    }
                }
            },
            [saveViewState, viewState, openedGroup]
        );
    const openGroup =
        useCallback(
            (group: ViewStateOpenedGroup) =>
            {
                if (saveViewState
                    && viewState !== undefined)
                {
                    (openedGroup ?? viewState).openedGroups = [
                        ...(viewState.openedGroups ?? []),
                        group
                    ];
                }
            },
            [openedGroup, saveViewState, viewState]
        );
    const closeGroup =
        useCallback(
            (group: ViewStateOpenedGroup) =>
            {
                if (saveViewState
                    && viewState !== undefined)
                {
                    (openedGroup ?? viewState).openedGroups =
                        (viewState.openedGroups ?? []).filter(
                            groupToCheck =>
                                !(groupToCheck.groupId === group.groupId
                                && groupToCheck.valueId === group.valueId)
                        );
                }
            },
            [openedGroup, saveViewState, viewState]
        );

    return [
        openedGroup?.lastOpenedEntityId ?? viewState?.lastOpenedEntityId,
        setLastOpenedEntityIdInRoutingState,
        openedGroup?.openedGroups ?? viewState?.openedGroups ?? [],
        openGroup,
        closeGroup,
    ];
}