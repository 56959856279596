import { useMemo } from 'react';

export default function useIndent(depth: number)
{
    return useMemo(
        () =>
            depth * 20,
        [
            depth
        ]);
}
