import { reference, type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';
import { EntityEvent } from './EntityEvent';

@type('EntityMetadataMutation')
export class EntityMetadataMutation
{
    // ------------------- Persistent Properties --------------------

    @observable id: string;
    @reference(undefined, 'EntityEvent') @observable.shallow events: EntityEvent[];

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
