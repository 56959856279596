import React, { useMemo } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import useTypes from '../../../../Type/Api/useTypes';
import { Card } from '@material-ui/core';
import getSystemDefaultView from '../../../../View/Api/getSystemDefaultView';
import getViewParameters from '../../../../View/Api/getViewParameters';
import ComparisonPredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../../../View/Model/ViewParams';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { Comparator } from '../../../../../DataObject/Model/Comparator';
import useFetchedRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity';
import EntityValue from '../../../../../../../@Api/Automation/Value/EntityValue';
import List, { ListSelection } from '../../../../View/List/List';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import { CommitBuilder } from '../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { mapBy } from '../../../../../../../@Util/MapUtils/mapBy';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';

export interface CourseLessonParticipantRegistrationProps
{
    lesson: Entity;
    commitContext?: CommitContext;
}

const CourseLessonParticipantRegistration: React.FC<CourseLessonParticipantRegistrationProps> =
    ({
         lesson,
         commitContext,
     }) =>
    {
        const types = useTypes();
        const [ course ] =
            useFetchedRelatedEntity(
                lesson,
                types.Activity.Course.RelationshipDefinition.Lessons,
                true,
                commitContext
            );
        const rootType =
            useMemo(
                () =>
                    types.Activity.CourseAttendance.Type,
                [types]
            );
        const viewParameters =
            useMemo(
                () =>
                    getViewParameters(rootType),
                [
                    rootType,
                ]
            );
        const view =
            useMemo(
                () =>
                    course !== undefined &&
                    getSystemDefaultView(
                        rootType,
                        viewParameters,
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                viewParameters.getParameterById(ViewParams.Entity),
                                EntityPath.fromEntityType(rootType)
                                    .joinTo(
                                        types.Activity.Course.RelationshipDefinition.Attendances,
                                        true
                                    )
                                    .field()
                            ),
                            Comparator.Equals,
                            new EntityValue(course)
                        )
                    ),
                [
                    rootType,
                    viewParameters,
                    types,
                    course,
                ]
            );
        const [ listSelection, isLoading, setListSelection ] =
            useAsyncResult<ListSelection>(
                async () =>
                {
                    const lessonRegistrationResults =
                        await EntitySelectionBuilder.builder(
                            types.Activity.CourseLessonRegistration.Type,
                            (builder, rootPath) =>
                                builder
                                    .join(
                                        rootPath.joinTo(
                                            types.Activity.CourseAttendance.RelationshipDefinition.CourseLessonRegistrations,
                                            true
                                        )
                                    )
                                    .where(
                                        cb =>
                                            cb.relatedToEntity(
                                                rootPath.joinTo(
                                                    types.CourseLesson.RelationshipDefinition.Registrations,
                                                    true
                                                ),
                                                lesson
                                            )
                                    )
                        ).select()
                    const lessonRegistrations =
                        lessonRegistrationResults.map(
                            result =>
                                result.entity
                        );
                    const attendanceUuids =
                        lessonRegistrations
                            .map(
                                registration =>
                                    registration.getRelatedEntityByDefinition(
                                        true,
                                        types.Activity.CourseAttendance.RelationshipDefinition.CourseLessonRegistrations
                                    )
                            )
                            .filter(
                                attendance =>
                                    attendance !== undefined
                            )
                            .map(
                                attendance =>
                                    attendance.uuid
                            );

                    return ({
                        base: 'none',
                        mutations: attendanceUuids,
                        size: attendanceUuids.length,
                    });
                },
                [
                    types,
                ]
            );
        const emptyListSelection =
            useMemo<ListSelection>(
                () => ({
                    base: 'none',
                    mutations: [],
                    size: 0,
                }),
                []
            );

        return <Card>
            {
                view && !isLoading &&
                <List
                    view={view}
                    selectable
                    selection={listSelection ?? emptyListSelection}
                    onSelectionChange={setListSelection}
                    readonly
                />
            }
            <CardInset>
                <PrimaryButton
                    label={
                        <LocalizedText
                            code="CourseLesson.RegisterPartipants"
                            value="Deelnemers registreren"
                        />
                    }
                    onClick={
                        async () =>
                        {
                            const lessonRegistrationResults =
                                await EntitySelectionBuilder.builder(
                                    types.Activity.CourseLessonRegistration.Type,
                                    (builder, rootPath) =>
                                        builder
                                            .join(
                                                rootPath.joinTo(
                                                    types.Activity.CourseAttendance.RelationshipDefinition.CourseLessonRegistrations,
                                                    true
                                                )
                                            )
                                            .where(
                                                cb =>
                                                    cb.relatedToEntity(
                                                        rootPath.joinTo(
                                                            types.CourseLesson.RelationshipDefinition.Registrations,
                                                            true
                                                        ),
                                                        lesson
                                                    )
                                            )
                                ).select()
                            const lessonRegistrations =
                                lessonRegistrationResults.map(
                                    result =>
                                        result.entity
                                );
                            const lessonRegistrationByCourseAttendanceId =
                                mapBy(
                                    lessonRegistrations,
                                    attendance =>
                                        attendance.getRelatedEntityByDefinition(
                                            true,
                                            types.Activity.CourseAttendance.RelationshipDefinition.CourseLessonRegistrations
                                        )?.id
                                );
                            const courseAttendanceResults =
                                await new EntitySelectionBuilder(rootType)
                                    .where(
                                        cb =>
                                            cb.filter(
                                                view.filter,
                                                {
                                                    parameter: viewParameters.getParameterById(ViewParams.Entity),
                                                }
                                            )
                                    )
                                    .select();
                            const courseAttendances =
                                courseAttendanceResults.map(
                                    result =>
                                        result.entity
                                );
                            const exclusionCourseAttendanceIds = new Set(listSelection.mutations);

                            return new CommitBuilder()
                                .ifValid(
                                    () =>
                                        true,
                                    cb =>
                                        courseAttendances.forEach(
                                            courseAttendance =>
                                            {
                                                const isPresent =
                                                    listSelection.base === 'none'
                                                        ? exclusionCourseAttendanceIds.has(courseAttendance.uuid)
                                                        : !exclusionCourseAttendanceIds.has(courseAttendance.uuid);

                                                if (lessonRegistrationByCourseAttendanceId.has(courseAttendance.id)
                                                    && !isPresent)
                                                {
                                                    return cb.deleteEntity(lessonRegistrationByCourseAttendanceId.get(courseAttendance.id));
                                                }
                                                else if (isPresent)
                                                {
                                                    return cb.createEntity(
                                                        types.Activity.CourseLessonRegistration.Type,
                                                        eb =>
                                                            eb
                                                                .relateTo(
                                                                    true,
                                                                    types.CourseLesson.RelationshipDefinition.Registrations,
                                                                    lesson
                                                                )
                                                                .relateTo(
                                                                    true,
                                                                    types.Activity.CourseAttendance.RelationshipDefinition.CourseLessonRegistrations,
                                                                    courseAttendance
                                                                )
                                                                .relateTo(
                                                                    true,
                                                                    types.Relationship.RelationshipDefinition.Activities,
                                                                    courseAttendance.getRelatedEntityByDefinition(
                                                                        true,
                                                                        types.Relationship.RelationshipDefinition.Activities
                                                                    )
                                                                )
                                                                .relateTo(
                                                                    true,
                                                                    types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                                                    courseAttendance.getRelatedEntityByDefinition(
                                                                        true,
                                                                        types.Relationship.Person.Contact.RelationshipDefinition.Activities
                                                                    )
                                                                )
                                                    );
                                                }
                                            }
                                        )
                                )
                                .commit();
                        }
                    }
                />
            </CardInset>
        </Card>;
    };

export default observer(CourseLessonParticipantRegistration);
