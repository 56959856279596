import React, { useMemo } from 'react';
import localizeText from '../../../@Api/Localization/localizeText';
import StaticSelectbox, { StaticOption } from '../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import { Aggregate } from '../DataObject/Model/Aggregate';

export interface AggregateEditorProps
{
    value?: Aggregate;
    onChange: (value?: Aggregate) => void;
}

export const AggregateEditor: React.FC<AggregateEditorProps> =
    ({
        value,
        onChange,
     }) =>
    {
        const aggregates =
            useMemo<StaticOption<Aggregate>[]>(
                () =>
                    [
                        {
                            id: 'Count',
                            label: localizeText('Aggregate.Count', 'Aantal elementen'),
                            value: Aggregate.Count,
                        },
                        {
                            id: 'Sum',
                            label: localizeText('Aggregate.Sum', 'Som'),
                            value: Aggregate.Sum,
                        },
                        {
                            id: 'Min',
                            label: localizeText('Aggregate.Min', 'Minimum'),
                            value: Aggregate.Min,
                        },
                        {
                            id: 'Max',
                            label: localizeText('Aggregate.Max', 'Maximum'),
                            value: Aggregate.Max,
                        },
                    ],
                []
            );

        return <StaticSelectbox
            options={aggregates}
            onChange={onChange}
            value={value}
            disableUnderline={false}
        />;
    };
