import React, { useContext, useEffect } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import useResult from '../../Entity/Selection/Hooks/useResult';
import useTypes from '../../Entity/Type/Api/useTypes';
import Avatar from '../../Entity/Avatar/Avatar';
import Tooltip from '../../../../@Future/Component/Generic/Tooltip/Tooltip';
import MultiplayerContext from '../Context/MultiplayerContext';
import { EntityField } from '../../../../@Api/Model/Implementation/EntityField';
import { EntityRelationshipDefinition } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import FieldInput from '../Model/Input/FieldInput';
import RelationshipInput from '../Model/Input/RelationshipInput';
import useMultiplayerInputFocus from '../Api/useMultiplayerInputFocus';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { classNames } from '../../../../@Future/Util/Class/classNames';
import styles from './InputFocus.module.scss';
import { computed } from 'mobx';
import equalsEntity from '../../../../@Api/Entity/Bespoke/equalsEntity';
import CurrentUserContext from '../../User/CurrentUserContext';

export interface InputFocusProps
{
    entity: Entity;
    field: EntityField | EntityRelationshipDefinition;
    parent?: boolean;
    part?: string;
    focused?: boolean;
    disableHighlight?: boolean;
    value?: any;
    onChange?: (value?: any) => void;
    hasBeenFocused?: boolean;
}

const InputFocus: React.FC<InputFocusProps> =
    props =>
    {
        const input =
            useComputed(
                () =>
                {
                    if (props.field instanceof EntityField)
                    {
                        return new FieldInput(
                            props.field.entityType,
                            props.field);
                    }
                    else
                    {
                        return new RelationshipInput(
                            props.field.getEntityType(!props.parent),
                            props.field,
                            props.parent);
                    }
                },
                [
                    props.field,
                    props.parent
                ]);

        useMultiplayerInputFocus(
            props.entity,
            input,
            props.focused,
            props.part,
            props.value,
            props.hasBeenFocused);

        const multiplayer = useContext(MultiplayerContext);
        const focusedConnection =
            useComputed(
                () =>
                {
                    if (multiplayer && props.entity)
                    {
                        return multiplayer.room
                            .connections
                            .filter(
                                connection =>
                                    multiplayer.connection !== connection
                                        && connection.focusPointer
                                        && connection.focusPointer.entityId === props.entity.id)
                            .find(
                                connection =>
                                {
                                    let field: EntityField | EntityRelationshipDefinition;
                                    let isParent = false;

                                    if (connection.focusPointer.input instanceof FieldInput)
                                    {
                                        field = connection.focusPointer.input.field;
                                    }
                                    else if (connection.focusPointer.input instanceof RelationshipInput)
                                    {
                                        field = connection.focusPointer.input.relationshipDefinition;
                                        isParent = connection.focusPointer.input.isParent;
                                    }

                                    return field === props.field
                                        && isParent === props.parent
                                        && connection.focusPointer.part === props.part;
                                });
                    }
                },
                [
                    multiplayer,
                    props.entity,
                    props.field,
                    props.parent,
                    props.part
                ]);

        useEffect(
            () =>
                computed(
                    () =>
                        focusedConnection
                        && focusedConnection.focusPointer
                            ?
                                focusedConnection.focusPointer.value
                            :
                                undefined)
                    .observe(
                        change =>
                        {
                            if (focusedConnection
                                && focusedConnection.focusPointer
                                && focusedConnection.focusPointer.input.id === input.id
                                && focusedConnection.focusPointer.entityId === props.entity.id
                                && focusedConnection.focusPointer.part === props.part
                                && props.onChange)
                            {
                                if (!props.focused)
                                {
                                    props.onChange(change.newValue);
                                }
                            }
                        }),
            [
                focusedConnection,
                props.focused,
                props.onChange,
                input,
                props.entity,
                props.part
            ]);


        const types = useTypes();
        const [ entity ] =
            useResult(
                focusedConnection ? types.Relationship.Person.Contact.Employee.Type : undefined,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.eq(
                                rootPath.field(types.Entity.Field.Id),
                                undefined,
                                focusedConnection.entityId)),
                [
                    types,
                    focusedConnection
                ]);

        const currentUserStore = useContext(CurrentUserContext);

        // Never show yourself
        const filteredEntity =
            useComputed(
                () =>
                    equalsEntity(currentUserStore.employeeEntity, entity)
                        ?
                            undefined
                        :
                            entity,
                [
                    currentUserStore,
                    entity
                ]);

        return <div
            className={classNames(styles.root, filteredEntity && !props.disableHighlight && styles.focused)}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={5}
                alignment="center"
            >
                <ViewGroupItem
                    ratio={1}
                >
                    {props.children}
                </ViewGroupItem>
                {
                    filteredEntity &&
                        <ViewGroupItem>
                            <Tooltip
                                title={`${filteredEntity.name} bewerkt dit veld`}
                                block
                            >
                                <Avatar
                                    entity={filteredEntity}
                                    linkMode="Link"
                                    size={25}
                                />
                            </Tooltip>
                        </ViewGroupItem>
                }
            </ViewGroup>
        </div>;
    };

InputFocus.defaultProps = {
    parent: false
};

export default observer(InputFocus);
