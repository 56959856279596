import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import SelectionFormInputLayoutViewer from './SelectionFormInputLayoutViewer';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import RightAlignedButtonGroup from '../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import TextEditor from '../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import { runInAction } from 'mobx';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import uuid from '../../../../../../@Util/Id/uuid';
import PrimitiveValueType from '../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import FormContext from '../../Form/FormContext';
import ParameterAssignment from '../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import SelectionFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/SelectionFormInputLayout';
import Switch from '../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import CollectionType from '../../../../../../@Api/Automation/Value/Type/CollectionType';
import DataSourceValueType from '../../../../../../@Api/Automation/Value/Type/DataSourceValueType';
import SelectionFormInputLayoutDataSourceEditor from './SelectionFormInputLayoutDataSourceEditor';

export interface SelectionFormInputLayoutEditorProps extends LayoutEditorProps<SelectionFormInputLayout>
{

}

const SelectionFormInputLayoutEditor: React.FC<SelectionFormInputLayoutEditorProps> =
    props =>
    {
        const form = useContext(FormContext);

        const parameter =
            useComputed<Parameter<PrimitiveValueType>>(
                () =>
                    props.layout.parameterId
                    && props.parameterDictionary.getParameterById(props.layout.parameterId),
                [
                    props.layout,
                    props.parameterDictionary
                ]);

        const [ name, setName ] = useState<string>();
        const [ isCollection, setCollection ] = useState<boolean>(false);

        const save =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            const singularValueType =
                                new DataSourceValueType(
                                    props.layout.dataSourceSignature.id,
                                    undefined,
                                    props.layout.dataSourceSignature);

                            const valueType =
                                isCollection
                                    ?
                                        new CollectionType(singularValueType)
                                    :
                                        singularValueType;

                            const parameter =
                                new Parameter(
                                    uuid(),
                                    valueType,
                                    false,
                                    name);

                            form.parameters.addParameter(parameter);
                            props.layout.parameterId = parameter.id;
                        }),
                [
                    name,
                    isCollection,
                    form,
                    props.layout
                ]);

        const parameterAssignment =
            useMemo(
                () =>
                    props.parameterAssignment || new ParameterAssignment(),

                [
                    props.parameterAssignment
                ]);

        if (parameter && parameterAssignment)
        {
            return <SelectionFormInputLayoutViewer
                parameterAssignment={parameterAssignment}
                {...props}
            />;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <InputGroup>
                        <Input
                            labelPosition="left"
                            label={
                                <LocalizedText
                                    code="Generic.Name"
                                    value="Naam"
                                />
                            }
                        >
                            <TextEditor
                                value={name}
                                onChange={setName}
                            />
                        </Input>
                        <SelectionFormInputLayoutDataSourceEditor
                            {...props}
                        />
                        <Input
                            labelPosition="left"
                            label={
                                <LocalizedText
                                    code="Generic.Plural"
                                    value="Meervoudig"
                                />
                            }
                        >
                            <Switch
                                checked={isCollection}
                                onToggle={setCollection}
                            />
                        </Input>
                    </InputGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <RightAlignedButtonGroup>
                        <SaveButton
                            disabled={name === undefined || props.layout.dataSourceSignature === undefined || props.layout.optionLayout === undefined}
                            onClick={save}
                        />
                    </RightAlignedButtonGroup>
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

export default observer(SelectionFormInputLayoutEditor);
