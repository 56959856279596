function formatLabelWithPaddedZero(number: number)
{
    if (number < 10)
    {
        return `0${number}`;
    }
    else
    {
        return number.toString();
    }
}

export default function getDurationLabel(durationInMillis: number,
                                         includeSeconds: boolean = false)
{
    const durationInSeconds = Math.floor(durationInMillis / 1000);
    const hours = Math.floor(durationInSeconds / 3600);
    const unroundedMinutes = durationInSeconds % 3600 / 60;
    const minutes = includeSeconds ? Math.floor(unroundedMinutes) : Math.round(unroundedMinutes);
    const hoursAndMins = `${hours}:${formatLabelWithPaddedZero(minutes)}`;

    if (includeSeconds)
    {
        const seconds = durationInSeconds % 3600 % 60;

        return `${hoursAndMins}:${formatLabelWithPaddedZero(seconds)}`;
    }
    else
    {
        return hoursAndMins;
    }
}
