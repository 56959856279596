import useTypes from '../../../../Entity/Type/Api/useTypes';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { useCallback } from 'react';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export default function useMarkAsRead()
{
    const types = useTypes();

    return useCallback(
        (notification: Entity, value: boolean) =>
            new CommitBuilder()
                .setObjectValueInEntity(
                    notification,
                    types.Notification.Field.IsRead,
                    value
                )
                .commit(),
        [
            types,
        ]);
}
