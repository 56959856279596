import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { ValueEditorProps } from '../../../ValueEditor';
import EntityValueType from '../../../../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import EntityValue from '../../../../../../../../../../../@Api/Automation/Value/EntityValue';
import { EntitySelectionBuilder } from '../../../../../../../../Selection/Builder/EntitySelectionBuilder';
import Selectbox from '../../../../../../../../Selectbox/Selectbox';
import { Entity } from '../../../../../../../../../../../@Api/Model/Implementation/Entity';
import CollectionType from '../../../../../../../../../../../@Api/Automation/Value/Type/CollectionType';
import CollectionValue from '../../../../../../../../../../../@Api/Automation/Value/CollectionValue';
import { EntityType } from '../../../../../../../../../../../@Api/Model/Implementation/EntityType';

export interface EntityCollectionEditorProps extends ValueEditorProps<CollectionType<EntityValueType>, CollectionValue<any>>
{

}

const EntityCollectionEditor: React.FC<EntityCollectionEditorProps> =
    props =>
    {
        const entityType = props.type.type.type as any as EntityType;
        const selections =
            useComputed(
                () => [
                    new EntitySelectionBuilder(entityType).build()
                ],
                [
                    entityType,
                ]
            );
        const value =
            useMemo(
                () =>
                    (props.value?.value ?? [])
                        .map(
                            value =>
                                value.value
                        ),
                [
                    props.value,
                ]
            );
        const onChange =
            useCallback(
                (entities: Entity[]) =>
                {
                    console.log(
                        entities,
                        new CollectionValue<any>(
                            entities.map(
                                entity =>
                                    new EntityValue(entity)
                            ),
                            props.type.type
                        )
                    );

                    props.onChange(
                        new CollectionValue<any>(
                            entities.map(
                                entity =>
                                    new EntityValue(entity)
                            ),
                            props.type.type
                        )
                    );
                },
                [
                    props.onChange
                ]
            );

        return <Selectbox
            selections={selections}
            value={value}
            onChange={onChange}
            clearable
            hideCaptionInValue={props.filter}
            autoFocus={props.autoFocus}
            multi
        />;
    };

export default observer(EntityCollectionEditor);
