import { EntityRelationshipDefinition } from '../../../Model/Implementation/EntityRelationshipDefinition';
import getRelationshipDefinitionFilterDescriptor from './getRelationshipDefinitionFilterDescriptor';
import { default as getRelationshipDefinitionFilterFromDescriptor, FilterWithContext } from './getRelationshipDefinitionFilterFromDescriptor';
import { EntityType } from '../../../Model/Implementation/EntityType';

export default async function getRelationshipDefinitionFilter(entityType: EntityType,
                                                              relationshipDefinition: EntityRelationshipDefinition,
                                                              isParent: boolean): Promise<FilterWithContext | undefined>
{
    const descriptor = getRelationshipDefinitionFilterDescriptor(entityType, relationshipDefinition, isParent);

    if (descriptor)
    {
        return getRelationshipDefinitionFilterFromDescriptor(relationshipDefinition, isParent, descriptor);
    }
    else
    {
        return undefined;
    }
}
