import { useContext, useEffect } from 'react';
import OpenedEntityContext from './OpenedEntityContext';

export default function useOpenedEntity()
{
    const [ entity, setEntity ] = useContext(OpenedEntityContext);

    useEffect(
        () =>
        {
            return () =>
            {
                setEntity(undefined);
            };
        },
        [
            setEntity
        ]);

    return entity;
}