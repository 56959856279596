import React from 'react';
import IconButton, { IconButtonProps } from '../Icon/IconButton';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface RefreshIconButtonProps extends Partial<IconButtonProps>
{

}

const RefreshIconButton: React.FC<RefreshIconButtonProps> =
    props =>
    {
        return <IconButton
            icon="refresh"
            onClick={props.onClick}
            tooltip={
                <LocalizedText
                    code="Generic.Refresh"
                    value="Herladen"
                />
            }
            {...props}
        />;
    };

export default RefreshIconButton;
