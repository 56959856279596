import React from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import Button from '../../../../../../Generic/Button/Button';
import { buttonInset } from '../../../../../../../@Resource/Theme/Theme';
import { ButtonStore } from '../../../../../../Generic/Button/ButtonStore';
import { openEntity } from '../../../../@Util/openEntity';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../MenuButton/MenuButton';

export interface ButtonGroupProps
{
    entity: Entity;
    isInSidebar?: boolean;
    onClose?: () => void;
    appendix?: JSX.Element;
    showMenuButton?: boolean;
    class?: string;
}

const ButtonGroup: React.FC<ButtonGroupProps> =
    props =>
    {
        const sideBarBackButtonStore =
            useComputed(
                () =>
                    props.isInSidebar
                        ?
                            new ButtonStore({
                                icon: 'arrow_back',
                                onClick:
                                    () => openEntity(props.entity)
                            })
                        :
                            undefined,
                [
                    props.isInSidebar,
                    props.entity
                ]);

        const closeButtonStore =
            useComputed(
                () =>
                    props.onClose
                        ?
                            new ButtonStore(
                                {
                                    icon: 'close',
                                    onClick: () => props.onClose()
                                })
                        :
                            undefined,
                [
                    props.onClose
                ]);

        return <ViewGroup
            orientation="horizontal"
            alignment="center"
            spacing={buttonInset}
            className={props.class ? props.class : undefined}
        >
            {
                props.appendix &&
                    <ViewGroupItem>
                        {props.appendix}
                    </ViewGroupItem>
            }
            {
                sideBarBackButtonStore &&
                    <ViewGroupItem>
                        <Button
                            store={sideBarBackButtonStore}
                        />
                    </ViewGroupItem>
            }
            {
                props.showMenuButton &&
                    <ViewGroupItem>
                        <MenuButton
                            entity={props.entity}
                        />
                    </ViewGroupItem>
            }
            {
                closeButtonStore &&
                    <ViewGroupItem>
                        <Button
                            store={closeButtonStore}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(ButtonGroup);
