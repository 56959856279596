import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import Input from '../../../../../Entity/Input/Input';
import { EntityPath } from '../../../../../Entity/Path/@Model/EntityPath';
import useTypes from '../../../../../Entity/Type/Api/useTypes';
import { SelectboxProps } from '../../../../../Entity/Selectbox/Selectbox';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface SharePackEditorProps
{
    entity: Entity;
    ownedShareAndEnvironmentPacks: Entity[];
    commitContext?: CommitContext;
    doAutocommit?: boolean;
}

export const SharePackEditor: React.FC<SharePackEditorProps> =
    observer(
        ({
             entity,
             ownedShareAndEnvironmentPacks,
             commitContext,
             doAutocommit,
         }) =>
        {
            const types = useTypes();

            const relationshipSelectboxProps =
                useMemo<Partial<SelectboxProps>>(
                    () => ({
                        disableConstruction: true,
                        selections: ownedShareAndEnvironmentPacks
                    }),
                    [
                        ownedShareAndEnvironmentPacks
                    ]);

            const field =
                useMemo(
                    () =>
                        EntityPath.fromEntity(entity)
                            .joinTo(
                                types.Pack.RelationshipDefinition.Entities,
                                true)
                            .field(undefined),
                    [
                        entity,
                        types
                    ]
                )

            return <Input
                entity={entity}
                field={field}
                labelPosition="left"
                relationshipSelectboxProps={relationshipSelectboxProps}
                doAutocommit={doAutocommit}
                commitContext={commitContext}
            />;
        }
    );
