import React from 'react';
import IconButton, { IconButtonProps } from '../Icon/IconButton';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface CloseButtonProps extends Omit<IconButtonProps, 'icon' | 'tooltip'>
{

}

const CloseButton: React.FC<CloseButtonProps> =
    props =>
    {
        return <IconButton
            icon="close"
            tooltip={
                <LocalizedText
                    code="Generic.Close"
                />
            }
            {...props}
        />;
    };

export default CloseButton;
