import React, { useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../Type/Api/useTypes';
import { UninitializedEmployeeAllocationTableEditorProps } from '../UninitializedEmployeeAllocationTableEditor';
import { useNewCommitContext } from '../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { getWeek, getYear } from 'date-fns';
import { EmployeeAllocationTableEditorRow } from './EmployeeAllocationTableEditorRow';
import SaveIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveIconButton/SaveIconButton';
import isEntityValid from '../../../../../../@Api/Entity/Validation/isEntityValid';
import uuid from '../../../../../../@Util/Id/uuid';

export interface ItemPlannerEmployeePlanTableConstructorRowProps extends UninitializedEmployeeAllocationTableEditorProps
{

}

export const EmployeeAllocationTableEditorConstructorRow: React.FC<ItemPlannerEmployeePlanTableConstructorRowProps> =
    observer(
        ({
             specification,
             employeeAllocationParameterAssignment,
             resource,
             startDate,
             norm,
            settingStorageKey,
         }) =>
        {
            const types = useTypes();
            const commitContext = useNewCommitContext();
            const [ itemId, setItemId ] = useState(() => uuid());
            const item =
                useMemo(
                    () =>
                        new CommitBuilder(commitContext)
                            .createEntity(
                                types.EmployeeAllocation.Type,
                                builder =>
                                    builder
                                        .relateTo(
                                            !specification.resourceToEmployeeAllocationPath.lastJoinNode.isParent,
                                            specification.resourceToEmployeeAllocationPath.lastJoinNode.relationshipDefinition,
                                            resource
                                        )
                                        .setObjectValue(
                                            types.EmployeeAllocation.Field.Year,
                                            getYear(startDate)
                                        )
                                        .setObjectValue(
                                            types.EmployeeAllocation.Field.Week,
                                            getWeek(startDate)
                                        )
                                        .setObjectValue(
                                            types.EmployeeAllocation.Field.WeekDate,
                                            startDate
                                        )
                                        .ifValid(
                                            () =>
                                                specification.employeeAllocationFilter !== undefined,
                                            builder =>
                                                builder.updateFromPredicate(
                                                    specification.employeeAllocationFilter,
                                                    specification.employeeAllocationParameter,
                                                    specification.employeeAllocationFilterParameters,
                                                    employeeAllocationParameterAssignment
                                                )
                                        ),
                                itemId
                            )
                            .getEntity(itemId),
                    [
                        commitContext,
                        types,
                        startDate,
                        itemId,
                        resource,
                        specification,
                        employeeAllocationParameterAssignment,
                    ]
                );
            const isValid =
                useComputed(
                    () =>
                        isEntityValid(
                            item,
                            {
                                commitContext,
                            }
                        ),
                    [
                        item,
                        commitContext,
                    ]
                );

            return <EmployeeAllocationTableEditorRow
                specification={specification}
                employeeAllocationParameterAssignment={employeeAllocationParameterAssignment}
                resource={resource}
                startDate={startDate}
                norm={norm}
                commitContext={commitContext}
                item={item}
                autoCommit={false}
                autoFocus
                options={
                    <SaveIconButton
                        onClick={
                            () =>
                                commitContext.commit()
                                    .then(() => setItemId(uuid()))
                        }
                        disabled={!isValid}
                    />
                }
                settingStorageKey={settingStorageKey}
            />;
        }
    );
