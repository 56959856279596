import Widget from '../../Widget';
import { EmptyDisposer } from '../../../../../../../@Util/Disposable/EmptyDisposer';

export default class EmployeeTimesheetWidget extends Widget
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async doInitialize(widgets: EmployeeTimesheetWidget[])
    {
        return EmptyDisposer;
    }

    static fromDescriptor(descriptor: any)
    {
        return new EmployeeTimesheetWidget(descriptor.id);
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'EmployeeTimesheet'
        }
    }

    isFullWidth(): boolean
    {
        return true;
    }

    // ----------------------- Private logic ------------------------
}
