import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useEvents from './Api/useEvents';
import Centered from '../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../@Future/Component/Generic/Loader/Loader';
import List from './List/List';
import OpenedEntityContext from '../Viewer/Context/OpenedEntity/OpenedEntityContext';
import InfiniteScroll from '../../../../@Future/Component/Generic/InfiniteScroll/InfiniteScroll';
import TimelineContext from './TimelineContext/TimelineContext';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { EntityEventFilter } from '../../../../@Api/Model/Implementation/EntityEventFilter';

export interface TimelineDataProps
{
    entityType: EntityType;
    entity: Entity;
    filters: EntityEventFilter[];
    startDate: Date;
    endDate: Date;
    onlyIncludeEventsWithoutUser?: boolean | undefined;
    showConstructor?: boolean;
}

const TimelineData: React.FC<TimelineDataProps> =
    ({
         entityType,
         entity,
         filters,
         onlyIncludeEventsWithoutUser,
         startDate,
         endDate,
         showConstructor
     }) =>
    {
        const [ events, isLoading, hasMore, loadMore ] =
            useEvents(
                entityType,
                filters,
                entity,
                onlyIncludeEventsWithoutUser,
                startDate,
                endDate
            );
        const newOpenedEntity = useState<Entity>(undefined);

        return <ViewGroup
            orientation="horizontal"
            spacing={0}
            alignment="start"
        >
            <ViewGroupItem
                ratio={1}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={10}
                >
                    {
                        events &&
                            <ViewGroupItem>
                                <OpenedEntityContext.Provider
                                    value={newOpenedEntity}
                                >
                                    <TimelineContext.Provider
                                        value={true}
                                    >
                                        <InfiniteScroll
                                            loadMore={loadMore}
                                            hasMore={hasMore}
                                            isLoading={isLoading}
                                        >
                                            <List
                                                entity={entity}
                                                events={events}
                                                showConstructor={showConstructor}
                                            />
                                        </InfiniteScroll>
                                    </TimelineContext.Provider>
                                </OpenedEntityContext.Provider>
                            </ViewGroupItem>
                    }
                    {
                        isLoading &&
                            <ViewGroupItem>
                                <Centered
                                    horizontal
                                >
                                    <Loader />
                                </Centered>
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(TimelineData);
