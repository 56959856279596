import { useComputed } from 'mobx-react-lite';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import moment from 'moment';
import { useContext } from 'react';
import DataObjectContext from '../../../../../DataObject/DataObjectContext';

export default function useTodayValue()
{
    const dataObjectStore = useContext(DataObjectContext);

    return useComputed(
        () =>
            DataObject.constructFromTypeIdAndValue(
                'DateRange',
                {
                    type: 'DateRange',
                    from: moment().startOf('day').toDate(),
                    to: moment().startOf('day').add(1, 'day').toDate()
                },
                dataObjectStore
            ),
        [
            dataObjectStore
        ]
    );
}
