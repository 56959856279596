import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../Type/Api/useTypes';

export function useInheritedAddressRelationshipDefinitions(
    entity: Entity
)
{
    const types= useTypes();
    return useComputed(
        () =>
            entity.entityType.getInheritedRelationshipDefinitions(false)
                .filter(
                    relationshipDefinition =>
                        relationshipDefinition.isSingular(false)
                        && relationshipDefinition.getEntityType(false).isA(types.Address.Type)
                ),
        [
            entity,
            types
        ]
    );
}