import { EntityPathNode } from '../EntityPathNode';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { EntityTypeStore } from '../../../Type/EntityTypeStore';
import { EntityPathCastNode } from './EntityPathCastNode';
import { EntityPath } from '../EntityPath';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationship } from '../../../../../../@Api/Model/Implementation/EntityRelationship';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';

export class EntityPathRootNode extends EntityPathNode
{
    // ------------------------- Properties -------------------------

    entityType: EntityType;

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType)
    {
        super();

        this.entityType = entityType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static construct(descriptor: any,
                     entityTypeStore: EntityTypeStore): EntityPathRootNode
    {
        const entityType = entityTypeStore.getTypeById(descriptor.entityTypeId);

        if (entityType)
        {
            return new EntityPathRootNode(entityType);
        }
        else
        {
            throw new Error(`Entity type with ID: ${descriptor.entityTypeId} not found`);
        }
    }

    id(): string
    {
        return `entityType.${this.entityType.id}`;
    }

    code(): string
    {
        return `${this.entityType.code}`;
    }

    getName(entityTypeStore: EntityTypeStore): string
    {
        return this.entityType.nameSingular;
    }

    getEntityType(baseEntityType: EntityType): EntityType
    {
        return this.entityType;
    }

    joinNode(baseEntityPath: EntityPath): EntityPath
    {
        const baseEntityType = baseEntityPath.entityType;

        if (baseEntityType.isA(this.entityType))
        {
            return baseEntityPath;
        }
        else
        {
            return baseEntityPath.addNode(
                new EntityPathCastNode(this.entityType));
        }
    }

    inverseNode(baseEntityType: EntityType): EntityPathNode
    {
        if (this.entityType.isA(baseEntityType))
        {
            return null;
        }
        else
        {
            return new EntityPathCastNode(this.entityType);
        }
    }

    isVirtual()
    {
        return false;
    }

    traverseEntity(entity: Entity,
                   commitContext?: CommitContext,
                   onRelationship?: (relationship: EntityRelationship, isParent: boolean) => void): Entity[]
    {
        if (entity.entityType.isA(this.entityType))
        {
            return [ entity ];
        }
        else
        {
            return [];
        }
    }

    constructEntity(baseEntity: Entity,
                    forceCreation: boolean,
                    addRelationshipToBase: boolean,
                    entityType: EntityType,
                    commitContext?: CommitContext): Entity
    {
        if (!baseEntity.entityType)
        {
            baseEntity.setEntityType(
                this.entityType,
                loadModuleDirectly(EntityTypeStore),
                commitContext
            );
        }

        return baseEntity;
    }

    descriptor(): any
    {
        return {
            entityTypeId: this.entityType.id
        };
    }

    // ----------------------- Private logic ------------------------
}
