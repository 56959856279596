import TransactionalApi from '../../@Util/TransactionalModelV2/Api/TransactionalApi';
import { injectWithQualifier } from '../../@Util/DependencyInjection/index';
import { getModel, TransactionalModel } from '../../@Util/TransactionalModelV2';
import { Cardinality, EntityRelationshipDefinition, ImportanceLevel, ReferentialAction, Type } from '../Model/Implementation/EntityRelationshipDefinition';
import { action } from 'mobx';
import { EntityRelationshipDefinitionController } from '../Controller/Directory/EntityRelationshipDefinitionController';
import { EntityTypeStore } from '../../@Component/Domain/Entity/Type/EntityTypeStore';
import { EntityRelationshipDefinitionMutation } from '../Model/Implementation/EntityRelationshipDefinitionMutation';
import { EntityRelationshipDefinitionDeletionMutation } from '../Model/Implementation/EntityRelationshipDefinitionDeletionMutation';
import { EntityRelationshipDefinitionUpdateMutation } from '../Model/Implementation/EntityRelationshipDefinitionUpdateMutation';
import { EntityCacheService } from '../../@Component/Service/Entity/EntityCacheService';
import { getCommitFromEntityAndRegisterIfValid } from '../Entity/Commit/commitEntity';

export class EntityRelationshipDefinitionApi extends TransactionalApi<EntityRelationshipDefinition, void, EntityRelationshipDefinitionMutation, EntityRelationshipDefinitionDeletionMutation>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityRelationshipDefinitionController') entityRelationshipDefinitionController: EntityRelationshipDefinitionController;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('EntityCacheService') entityCacheService: EntityCacheService;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    protected initialize(model: EntityRelationshipDefinition): Promise<any>
    {
        return Promise.resolve();
    }

    @action
    protected merge(transactional: TransactionalModel<EntityRelationshipDefinition>,
                    source: EntityRelationshipDefinition): Promise<any>
    {
        return Promise.resolve();
    }

    protected saveInApi(transactional: TransactionalModel<EntityRelationshipDefinition>): Promise<EntityRelationshipDefinitionUpdateMutation>
    {
        const commit =
            transactional.entity
                ?
                    getCommitFromEntityAndRegisterIfValid(transactional.entity)
                :
                    undefined;

        return this.entityRelationshipDefinitionController.saveEntityRelationshipDefinition(
            transactional.id ? transactional.id : undefined,
            transactional.code,
            transactional.parentEntityType.id,
            transactional.childEntityType.id,
            transactional.parentFreeFormField ? transactional.parentFreeFormField.id : undefined,
            transactional.childFreeFormField ? transactional.childFreeFormField.id : undefined,
            Type[transactional.type],
            Cardinality[transactional.cardinality],
            transactional.isDefaultForParent ? transactional.isDefaultForParent : false,
            transactional.isDefaultForChild ? transactional.isDefaultForChild : false,
            transactional.isMandatoryForParent ? transactional.isMandatoryForParent : false,
            transactional.isMandatoryForChild ? transactional.isMandatoryForChild : false,
            transactional.isVisibleInParent ? transactional.isVisibleInParent : false,
            transactional.isVisibleInChild ? transactional.isVisibleInChild : false,
            transactional.isVisibleAsTabInParent,
            transactional.isVisibleAsTabInChild,
            transactional.isManagedInParent ? transactional.isManagedInParent : false,
            transactional.isManagedInChild ? transactional.isManagedInChild : false,
            transactional.isInactiveCascadedToParent ? transactional.isInactiveCascadedToParent : false,
            transactional.isInactiveCascadedToChild ? transactional.isInactiveCascadedToChild : false,
            transactional.isLazyFromParent,
            transactional.isLazyFromChild,
            transactional.parentPredicate,
            transactional.childPredicate,
            ReferentialAction[transactional.parentDeletionReferentialAction],
            ReferentialAction[transactional.childDeletionReferentialAction],
            undefined, // transactional.nameLanguageEntry?.descriptor() || {},
            undefined, // transactional.inverseNameLanguageEntry?.descriptor() || {},
            transactional.color,
            transactional.icon,
            ImportanceLevel[transactional.importanceLevel],
            transactional.defaultParentPath,
            transactional.defaultChildPath,
            transactional.parentComputation,
            transactional.childComputation,
            commit?.descriptor);
    }

    @action.bound
    protected postSave(transactional: TransactionalModel<EntityRelationshipDefinition>,
                       resultFromApi: EntityRelationshipDefinitionMutation, result: void): Promise<any>
    {
        return this.entityTypeStore.applyMetadataMutation(
            resultFromApi,
            getModel(transactional));
    }

    protected deleteInApi(transactional: TransactionalModel<EntityRelationshipDefinition>): Promise<any>
    {
        return this.entityRelationshipDefinitionController.deleteEntityRelationshipDefinition(transactional.id);
    }

    @action.bound
    protected postDelete(transactional: TransactionalModel<EntityRelationshipDefinition>,
                         result: EntityRelationshipDefinitionDeletionMutation): Promise<any>
    {
        return this.entityTypeStore.applyMetadataMutation(
            result,
            getModel(transactional));
    }
}
