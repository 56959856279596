import React, { useContext, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import PortalListLayout from '../../../../../../@Api/Layout/Type/PortalListLayout';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import PortalListLayoutDataSourceEditor from './PortalListLayoutDataSourceEditor';
import TabBar from '../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import PortalListLayoutItemLayoutEditor from './PortalListLayoutItemLayoutEditor';
import PortalListLayoutEmptyLayoutEditor from './PortalListLayoutEmptyLayoutEditor';
import { ComputationParameterSuppressionContext } from '../../../../Entity/Viewer/Content/Automation/Editor/Computation/Context/ComputationParameterSuppressionContext';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';

export interface PortalListLayoutEditorProps extends LayoutEditorProps<PortalListLayout>
{

}

const PortalListLayoutEditor: React.FC<PortalListLayoutEditorProps> =
    props =>
    {
        const { layout } = props;
        const [ tab, setTab ] = useState(0);
        const inheritedSuppressedParameters = useContext(ComputationParameterSuppressionContext);
        const suppressedParameters =
            useMemo<ParameterDictionary>(
                () =>
                    ParameterDictionary.union(
                        inheritedSuppressedParameters,
                        layout.dataSourceSignature ? layout.dataSourceSignature.resultParameters : new ParameterDictionary([])),
                [
                    inheritedSuppressedParameters,
                    layout.dataSourceSignature
                ]);

        if (layout.dataSourceSignature && layout.itemParameter)
        {
            return <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                <ViewGroupItem>
                    <TabBar
                        value={tab}
                    >
                        <Tab
                            value={0}
                            onClick={setTab}
                        >
                            <LocalizedText
                                code="Generic.Layout"
                                value="Layout"
                            />
                        </Tab>
                        <Tab
                            value={1}
                            onClick={setTab}
                        >
                            <LocalizedText
                                code="Generic.Manage"
                                value="Beheren"
                            />
                        </Tab>
                        <Tab
                            value={2}
                            onClick={setTab}
                        >
                            <LocalizedText
                                code="Layout.PortalList.EmptyLayout"
                                value="Layout bij géén records"
                            />
                        </Tab>
                    </TabBar>
                </ViewGroupItem>
                <ComputationParameterSuppressionContext.Provider
                    value={suppressedParameters}
                >
                    {
                        tab === 0 &&
                            <ViewGroupItem>
                                <PortalListLayoutItemLayoutEditor
                                    {...props}
                                />
                            </ViewGroupItem>
                    }
                    {
                        tab === 1 &&
                            <ViewGroupItem>
                                <PortalListLayoutDataSourceEditor
                                    {...props}
                                />
                            </ViewGroupItem>
                    }
                    {
                        tab === 2 &&
                            <ViewGroupItem>
                                <PortalListLayoutEmptyLayoutEditor
                                    {...props}
                                />
                            </ViewGroupItem>
                    }
                </ComputationParameterSuppressionContext.Provider>
            </ViewGroup>;
        }
        else
        {
            return <PortalListLayoutDataSourceEditor
                {...props}
            />;
        }
    };

export default observer(PortalListLayoutEditor);
