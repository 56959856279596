import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import getTypes from '../../../../Type/Api/getTypes';
import { createNumberComparator } from '../../../../../../Generic/List/V2/Comparator/NumberComparator';

export function updateDigitalSigningSignOrder(signingActivity: Entity, commitContext: CommitContext)
{
    const types = getTypes();
    signingActivity.getRelatedEntitiesByDefinition(
        false,
        types.Activity.DigitalSigning.RelationshipDefinition.Signers,
        commitContext
    )
    .sort(
        createNumberComparator(
            signer => signer.getObjectValueByField(
                types.DigitalSigner.Field.SignOrderIndex
            )
        )
    )
    .forEach(
        (signer, idx) =>
            signer.setValueByField(
                types.DigitalSigner.Field.SignOrderIndex,
                idx + 1,        // SignerIds start at 1
                false,
                false,
                commitContext
            )
    )
}