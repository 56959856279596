import React, { useState } from 'react';
import { ClickAwayListener, Input } from '@material-ui/core';
import IconButton from '../Button/Variant/Icon/IconButton';
import Loader from '../Loader/Loader';
import styles from './SearchBar.module.scss';
import useDelayedCallback from '../../../Util/Hook/useDelayedCallback';
import localizeText from '../../../../@Api/Localization/localizeText';
import LocalizedText from '../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface SearchBarProps
{
    onSearch: (query: string) => void;
    placeHolder?: string;
    autoFocus?: boolean;
    defaultValue?: string;
}

const SearchBar: React.FC<SearchBarProps> =
    props =>
    {
        const [ isLoading ] = useState(false);
        const [ query, setQuery ] = useState(props.defaultValue ?? '');

        useDelayedCallback(
            query,
            props.onSearch);

        return <ClickAwayListener
            onClickAway={() => {}}
        >
            <div
                className={styles.root}
            >
                <div
                    className={styles.bar}
                >
                    <div
                        className={styles.inputContainer}
                    >
                        <Input
                            placeholder={
                                props.placeHolder
                                    ? props.placeHolder
                                    : `${localizeText('Generic.Search', 'Zoeken')}...`
                            }
                            className={styles.input}
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                            fullWidth
                            disableUnderline
                            inputProps={{
                                className: styles.innerInput
                            }}
                            autoFocus={props.autoFocus}
                        />
                    </div>
                    <div
                        className={styles.buttonContainer}
                    >
                        {
                            isLoading
                                ?
                                    <Loader />
                                :
                                    query.length > 0 &&
                                    <IconButton
                                        onClick={
                                            () =>
                                            {
                                                setQuery('');
                                                props.onSearch('');
                                            }
                                        }
                                        icon="close"
                                        tooltip={
                                            <LocalizedText
                                                code='Generic.Cancel'
                                                value='Annuleren'
                                            />
                                        }
                                    />
                        }
                    </div>
                </div>
            </div>
        </ClickAwayListener>;
    };

export default SearchBar;
