import React from 'react';
import { observer } from 'mobx-react-lite';
import Layout from '../../../../@Api/Layout/Layout';
import LayoutEditor, { LayoutEditorProps } from '../Editor/LayoutEditor';
import LayoutConstructor from '../Editor/Constructor/LayoutConstructor';

export interface OptionalLayoutEditorProps<L extends Layout> extends LayoutEditorProps<L>
{
    onChange: (layout: Layout | undefined) => void;
}

const OptionalLayoutEditor: React.FC<OptionalLayoutEditorProps<Layout>> =
    props =>
    {
        const { onChange, ...editorProps } = props;

        if (props.layout)
        {
            return <LayoutEditor
                {...editorProps}
                onChange={onChange}
            />;
        }
        else
        {
            return <LayoutConstructor
                parameterDictionary={editorProps.parameterDictionary}
                mode="Button"
                onConstruct={onChange}
            />;
        }
    };

export default observer(OptionalLayoutEditor);
