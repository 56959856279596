import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationBox from '../../Box/ComputationBox';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import CapitalizedTextComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/CapitalizedTextComputation';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface CapitalizedTextComputationEditorProps extends ComputationEditorProps<ValueType<any>, CapitalizedTextComputation>
{

}

const CapitalizedTextComputationEditor: React.FC<CapitalizedTextComputationEditorProps> =
    props =>
    {
        const setValue =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.value = value),
                [
                    props.value
                ]);

        return <Input
            label={
                <LocalizedText
                    code="CapitalizedText"
                    value="Tekst met hoofdletter"
                />
            }
            labelPosition="left"
        >
            <ComputationBox>
                <ComputationEditor
                    context={props.context}
                    value={props.value.value}
                    onChange={setValue}
                />
            </ComputationBox>
        </Input>;
    };

export default observer(CapitalizedTextComputationEditor);
