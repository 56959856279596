import React, { useMemo } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../Type/Api/useTypes';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import CardHeader from '../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useResults from '../../../../Selection/Hooks/useResults';
import Portal from './Portal/Portal';
import useDividerGlue from '../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import { groupBy } from '../../../../../../../@Util/MapUtils/groupBy';

export interface PortalProps
{
    entity: Entity;
}

const Portals: React.FC<PortalProps> =
    props =>
    {
        const types = useTypes();
        const portals =
            useResults(
                types.Portal.Type,
                () => {},
                []);
        const isContact =
            useMemo(
                () =>
                    props.entity.entityType.isA(types.Relationship.Person.Contact.Type),
                [
                    props.entity,
                    types
                ]);
        const portalUsers =
            useResults(
                types.PortalUser.Type,
                (builder, rootPath) =>
                    builder
                        .join(
                            rootPath.joinTo(
                                types.Portal.RelationshipDefinition.Users,
                                true))
                        .join(
                            rootPath.joinTo(
                                types.PortalUser.RelationshipDefinition.Relationship,
                                false))
                        .join(
                            rootPath.joinTo(
                                types.PortalUser.RelationshipDefinition.Contact,
                                false))
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        isContact
                                            ?
                                                types.PortalUser.RelationshipDefinition.Contact
                                            :
                                                types.PortalUser.RelationshipDefinition.Relationship,
                                        false),
                                    props.entity)),
                [
                    isContact,
                    types,
                    props.entity
                ]);
        const usersByPortalId =
            useComputed(
                () =>
                    groupBy(
                        portalUsers || [],
                        user =>
                            user.getRelatedEntityByDefinition(
                                true,
                                types.Portal.RelationshipDefinition.Users)?.uuid),
                [
                    portalUsers,
                    types
                ]);

        const dividerGlue = useDividerGlue();

        const isPerson = props.entity.entityType.isA(types.Relationship.Person.Type)

        if (portals?.length > 0 && portalUsers && isPerson)
        {
            return <Card>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    glue={dividerGlue}
                >
                    <ViewGroupItem>
                        <CardInset>
                            <CardHeader>
                                {types.Portal.Type.getName(true)}
                            </CardHeader>
                        </CardInset>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                            glue={dividerGlue}
                        >
                            {
                                portals.map(
                                    portal =>
                                        <ViewGroupItem
                                            key={portal.uuid}
                                        >
                                            <Portal
                                                portal={portal}
                                                relationship={props.entity}
                                                contact={isContact}
                                                users={usersByPortalId.get(portal.uuid) || []}
                                            />
                                        </ViewGroupItem>)
                            }
                        </ViewGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </Card>;
        }
        else
        {
            return null;
        }
    };

export default observer(Portals);
