import React from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import Statistics from './Statistics/Statistics';
import Navigation from './Navigation/Navigation';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Setting } from '../../../../../../../../@Api/Settings/Setting';
import useSetting from '../../../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../../../Setting/SettingStore';
import useIsFeatureAllowed from '../../../../../../Role/Api/useIsFeatureAllowed';
import { HideFinancialWidgetsInProjectFeature } from '../../../../../../Role/Model/Features';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { useInitializedEntityForViewer } from '../../../../Api/useInitializedEntityForViewer';

export interface ProjectOverviewProps
{
    project: Entity;
    commitContext: CommitContext;
    milestone?: Entity;
    onClose?: () => void;
}

const ProjectOverview: React.FC<ProjectOverviewProps> =
    props =>
    {
        const milestone = props.milestone;
        const [ showFinancialWidgetsInProject ] = useSetting<boolean>(SettingSource.Organization, Setting.Project.ShowFinancialWidgets);
        const hideFinancialWidgetsInProjects = useIsFeatureAllowed(HideFinancialWidgetsInProjectFeature);
        useInitializedEntityForViewer(milestone);

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            {
                showFinancialWidgetsInProject &&
                !hideFinancialWidgetsInProjects &&
                    <ViewGroupItem>
                        <Statistics
                            project={props.project}
                            milestone={milestone}
                        />
                    </ViewGroupItem>
            }
            <ViewGroupItem>
                <Navigation
                    project={props.project}
                    commitContext={props.commitContext}
                    milestone={milestone}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(ProjectOverview);
