import { DependencyList } from 'react';
import { useMemoizedArray } from '../Array/useMemoizedArray';

export function useMemoized<T>(
    value: T,
    areValuesEqual: (a: T, b: T) => boolean = (a, b) => a === b,
    deps: DependencyList
): T
{
    const [
        memoizedElement
    ] =
        useMemoizedArray(
            [
                value
            ],
            areValuesEqual,
            deps
        );

    return memoizedElement;
}