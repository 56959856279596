import * as React from 'react';
import { Typography } from '@material-ui/core';
import { DataObjectBespokeViewer } from '../../Viewer/DataObjectBespokeViewer';
import { DataObjectViewerStore } from '../../Viewer/DataObjectViewerStore';
import { observer } from 'mobx-react';

@observer
export class NumberView extends DataObjectBespokeViewer
{
    renderComponent(store: DataObjectViewerStore)
    {
        return <Typography
            variant="body2"
            style={{
                textAlign: store.alignmentStyle,
                ...store.style
            }}
        >
            {store.dataObject.toString(store.dataObjectRepresentation)}
        </Typography>;
    }
}
