import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { DefaultRelationshipEditor } from '../../DefaultRelationshipEditor/DefaultRelationshipEditor';
import { RelationshipEditorProps } from '../../RelationshipEditor';
import { SelectboxProps } from '../../../../Selectbox/Selectbox';
import useTypes from '../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import useDialog from '../../../../../../../@Service/Navigation/Page/Hooks/useDialog';
import ModifyProductDescriptionDialog from './DescriptionDialog/ModifyProductDescriptionDialog';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import IconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import localize from '../../../../../../../@Api/Localization/localize';

export interface ProductLineToProductEditorProps extends RelationshipEditorProps
{

}

const ProductLineToProductEditor: React.FC<ProductLineToProductEditorProps> =
    props =>
    {
        const types = useTypes();

        const isDescriptionEditable =
            useComputed(
                () =>
                {
                    const product =
                        props.entity.getRelatedEntityByDefinition(
                            false,
                            types.ProductLine.RelationshipDefinition.Product,
                            props.commitContext
                        );

                    if (product)
                    {
                        return product.getObjectValueByField(
                            types.Product.Field.IsDescriptionEditable,
                            props.commitContext
                        );
                    }
                    else
                    {
                        return !props.entity.isNew();
                    }
                },
                [
                    props.entity,
                    types,
                    props.commitContext,
                ]);
        const productLineDescription =
            useComputed(
                () =>
                {
                    const productLineLocalizedDescription =
                        localize(
                            props.entity.getObjectValueByField(
                                types.ProductLine.Field.LocalizedDescription,
                                props.commitContext
                            )
                        );

                    return productLineLocalizedDescription?.length > 0
                        ? productLineLocalizedDescription
                        : props.entity.getObjectValueByField<string>(
                            types.ProductLine.Field.Description,
                            props.commitContext
                        );
                },
                [
                    props.entity,
                    props.commitContext,
                    types
                ]);
        const [ editDescription ] =
            useDialog(
                close =>
                    <ModifyProductDescriptionDialog
                        entity={props.entity}
                        commitContext={props.commitContext}
                        onClose={close}
                        open
                        hideCloseButton={false}
                    />,
                [
                    props.entity,
                    props.commitContext
                ]
            );

        const formatSelectedValue =
            useCallback(
                (product: Entity) =>
                {
                    const isEditable =
                        product.getObjectValueByField(
                            types.Product.Field.IsDescriptionEditable,
                            props.commitContext
                        );

                    if (isEditable && productLineDescription?.length > 0)
                    {
                        return productLineDescription;
                    }
                    else
                    {
                        const productLocalizedName =
                            localize(
                                product.getObjectValueByField(
                                    types.Product.Field.LocalizedName,
                                    props.commitContext
                                )
                            );

                        return productLocalizedName?.length > 0
                            ? productLocalizedName
                            : product.getObjectValueByField<string>(
                                types.Product.Field.Name,
                                props.commitContext
                            );
                    }
                },
                [
                    types,
                    props.commitContext,
                    productLineDescription,
                ]
            );
        const formatOptionValue =
            useCallback(
                (product: Entity) =>
                {
                    const productLocalizedName =
                        localize(
                            product.getObjectValueByField(
                                types.Product.Field.LocalizedName,
                                props.commitContext
                            )
                        );

                    return productLocalizedName?.length > 0
                        ? productLocalizedName
                        : product.getObjectValueByField<string>(
                            types.Product.Field.Name,
                            props.commitContext
                        );
                },
                [
                    types,
                    props.commitContext,
                ]
            );
        const searchFieldPaths =
            useMemo(
                () => [
                    EntityPath.fromEntityType(types.Product.Type)
                        .field(types.Product.Field.Code),
                    EntityPath.fromEntityType(types.Product.Type)
                        .field(types.Product.Field.LocalizedName)
                ],
                [
                    types
                ]);
        const selectboxProps =
            useMemo<Partial<SelectboxProps>>(
                () => ({
                    ...props.selectboxProps,
                    searchFieldPaths,
                    formatSelectedValue,
                    formatOptionValue,
                    emptyOption: productLineDescription,
                }),
                [
                    searchFieldPaths,
                    formatSelectedValue,
                    formatOptionValue,
                    productLineDescription,
                    props.selectboxProps,
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={6}
            alignment="center"
        >
            {
                isDescriptionEditable &&
                <ViewGroupItem>
                    <IconButton
                        icon="edit"
                        tooltip={
                            <LocalizedText
                                code="Generic.EditDescription"
                                value="Omschrijving wijzigen"
                            />
                        }
                        onClick={editDescription}
                        small
                    />
                </ViewGroupItem>
            }
            <ViewGroupItem
                ratio={1}
            >
                <DefaultRelationshipEditor
                    {...props}
                    selectboxProps={selectboxProps}
                    required
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(ProductLineToProductEditor);
