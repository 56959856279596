import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import getVisibleFieldPathsForType from './getVisibleFieldPathsForType';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { TypeFieldInclusion } from './useVisibleFieldGroups';

export default function getVisibleFieldPathsForEntity(
    entity: Entity,
    ignoreHidden: boolean = false,
    forType?: EntityType,
    includeTypeField?: TypeFieldInclusion
)
{
    const visiblePathsForType =
        getVisibleFieldPathsForType(
            forType || entity.entityType,
            ignoreHidden,
            includeTypeField
        );

    return visiblePathsForType
        .filter(
            fieldPath =>
                !forType || fieldPath.isDefinedOnType(forType))
        .map(
            fieldPath =>
                forType ? fieldPath.rootedAt(entity.entityType) : fieldPath)
        .filter(
            fieldPath =>
                ignoreHidden || !entity.entityType.bespoke.hideFieldPath(entity, fieldPath)
        );
}
