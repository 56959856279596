// https://usehooks.com/useWindowSize/
import { useEffect, useState } from 'react';

const isClient = typeof window === 'object';

function getSize()
{
    return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined
    };
}

// Hook
export default function useWindowSize()
{
    const [ windowSize, setWindowSize ] = useState(getSize);

    useEffect(
        () =>
        {
            if (!isClient)
            {
                return;
            }

            function handleResize()
            {
                setWindowSize(getSize());
            }

            window.addEventListener('resize', handleResize);

            return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
};
