import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import LabelButton from '../../../../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import { attentionColor, primaryColor, textSecondaryColor, veryLightTextColor } from '../../../../../../@Resource/Theme/Theme';
import useCount from './Api/useCount';
import IconToggleButton from '../../../../../../@Future/Component/Generic/IconToggleButton/IconToggleButton';
import useIsAttachedEntityType from '../Api/useIsAttachedEntityType';
import useTypes from '../../../Type/Api/useTypes';
import useWorkflowState from '../../../Workflow/Api/useWorkflowState';
import useWorkflowStateIsTerminal from '../../../Workflow/Api/useWorkflowStateIsTerminal';
import styles from './Button.module.scss';

export interface ButtonProps
{
    entity: Entity;
    path: EntityPath;
    onClick: () => void;
    editable?: boolean;
    disabled?: boolean;
    active?: boolean;
    attention?: boolean;
    onCount: (path: EntityPath, count: number) => void;
    header?: boolean;
    hideButtonWithZeroCount?: boolean;
}

const Button: React.FC<ButtonProps> =
    props =>
    {
        const types = useTypes();
        const count = useCount(props.entity, props.path);
        const isAttachedType = useIsAttachedEntityType(props.path.entityType);

        const workflowState = useWorkflowState(props.entity);
        const isClosed = useWorkflowStateIsTerminal(workflowState);

        const buttonColor =
            useMemo(
                () =>
                    props.active
                        ?
                            primaryColor
                        :
                            props.attention
                                ?
                                    attentionColor
                                :
                                    count > 0
                                        ?
                                            textSecondaryColor

                                        :
                                            veryLightTextColor,
                [
                    props.active,
                    props.attention,
                    count
                ]);

        const hideButton =
            useMemo(
                () =>
                    !props.header &&
                    ((isClosed && count === 0) ||
                        (count === 0 &&
                            (
                                !props.editable ||
                                props.path.entityType === types.Activity.Offer.Type ||
                                props.path.entityType === types.Activity.Email.Type
                            ))),
                [
                    props.header,
                    isClosed,
                    count,
                    props.editable,
                    props.path.entityType,
                    types
                ]);

        const label =
            useMemo(
                () =>
                {
                    if (count > 0)
                    {
                        return count.toString();
                    }
                    else if (props.path.entityType.isA(types.Event.Read.Type))
                    {
                        return '0';
                    }
                    else
                    {
                        return '+';
                    }
                },
                [
                    count,
                    props.path,
                    types
                ]);

        if (hideButton || (props.hideButtonWithZeroCount && !count))
        {
            return null;
        }
        else
        {
            if (props.header)
            {
                return <IconToggleButton
                    onClick={props.onClick}
                    icon={props.path.entityType.getInheritedIcon()}
                    iconOutlined={isAttachedType}
                    count={count === 0 ? undefined : count}
                    disabled={props.disabled || count === 0}
                    tooltip={props.path.entityType.getName(true)}
                    active={props.active}
                    underline={props.path.entityType.getName(true)}
                />;
            }
            else
            {
                return <LabelButton
                    icon={props.path.entityType.getInheritedIcon()}
                    iconOutlined
                    label={label}
                    onClick={props.onClick}
                    disableShadow
                    color={buttonColor}
                    hoverColor={primaryColor}
                    size={props.attention ? 'sm' : 'xs'}
                    disabled={props.disabled}
                    tooltip={props.path.entityType.getName(true)}
                    classes={{ root: styles.counterButton }}
                />;
            }
        }
    };

export default observer(Button);
