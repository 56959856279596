import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Layout from '../../../../../../@Api/Layout/Layout';
import { LayoutEditorProps } from '../../LayoutEditor';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import styles from '../../../Type/Composite/Editor/Item/Controls/CompositeLayoutItemControls.module.scss';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import LayoutControlsContent from '../Content/LayoutControlsContent';
import ParentLayoutsContext from '../../Child/ParentLayoutsContext';
import ExpansionGroup from '../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ParentLayoutControls from './Parent/ParentLayoutControls';

export interface LayoutControlsCardProps<L extends Layout> extends LayoutEditorProps<L>
{
    onDelete?: () => void;
}

const LayoutControlsCard: React.FC<LayoutControlsCardProps<Layout>> =
    props =>
    {
        const parentLayouts = useContext(ParentLayoutsContext);
        const dividerGlue = useDividerGlue();
        const cardClasses =
            useMemo(
                () => ({
                    root: styles.root
                }),
                []);

        return <Card
            classes={cardClasses}
        >
            <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <CardInset
                        bottom={false}
                    >
                        <CardHeader>
                            <LocalizedText
                                code="Generic.Settings"
                                value="Instellingen"
                            />
                        </CardHeader>
                    </CardInset>
                </ViewGroupItem>
                <ViewGroupItem>
                    <LayoutControlsContent
                        {...props}
                    />
                </ViewGroupItem>
                {
                    props.children &&
                        <ViewGroupItem>
                            {props.children}
                        </ViewGroupItem>
                }
                {
                    parentLayouts.length > 0 &&
                        <ViewGroupItem>
                            <CardInset
                                bottom={false}
                            >
                                <CardHeader>
                                    <LocalizedText
                                        code="Layout.SettingsOfAncestorElements"
                                        value="Instellingen van bovenliggende elementen"
                                    />
                                </CardHeader>
                            </CardInset>
                            <ExpansionGroup>
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={0}
                                    glue={dividerGlue}
                                >
                                    {
                                        parentLayouts.map(
                                            (parentLayout, idx) =>
                                                <ViewGroupItem
                                                    key={idx}
                                                >
                                                    <ParentLayoutControls
                                                        {...props}
                                                        layout={parentLayout}
                                                    />
                                                </ViewGroupItem>)
                                    }
                                </ViewGroup>
                            </ExpansionGroup>
                        </ViewGroupItem>
                }
            </ViewGroup>
        </Card>;
    };

export default observer(LayoutControlsCard);
