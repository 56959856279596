import { ContentProps } from '../../Content';
import React, { useCallback, useMemo } from 'react';
import Base from '../../Base/Base';
import ViewGroup from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../../../../../../../../Type/Api/useTypes';
import useFetchedRelatedEntity from '../../../../../../../../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity';
import useEntityValue from '../../../../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import EntityLink from '../../../../../../../../../../Link/EntityLink';
import { SpotlerCampaignResultChartBounced } from './Charts/SpotlerCampaignResultChartBounced';
import { SpotlerCampaignResultChartViewed } from './Charts/SpotlerCampaignResultChartViewed';
import { SpotlerCampaignResultChartClicked } from './Charts/SpotlerCampaignResultChartClicked';
import { format } from 'date-fns';

export interface SpotlerCampaignResultProps extends ContentProps
{

}

export const SpotlerCampaignResult: React.FC<SpotlerCampaignResultProps> =
    props  =>
    {
        const types = useTypes();

        const [ campaignStep] =
            useFetchedRelatedEntity(
                props.entity,
                types.SpotlerCampaignStep.RelationshipDefinition.Results,
                true
            );

        const stepName =
            useMemo(
                () =>
                    campaignStep && campaignStep.getObjectValueByField(types.SpotlerCampaignStep.Field.Name),
                [
                    campaignStep,
                    types
                ]
            );

        const [ campaign ] = useFetchedRelatedEntity(
            props.entity,
            types.Activity.SpotlerCampaign.RelationshipDefinition.Results,
            true
        );

        const sendDate =
            useEntityValue<Date>(
                props.entity,
                types.Activity.SpotlerCampaignResult.Field.SendDate
            );

        const glue =
            useCallback(
                () =>
                    `·`,
                [
                ]);


        const formattedDate =
            useMemo(
                () =>
                    sendDate !== undefined
                        ? format(new Date(sendDate), 'dd-MM-yyyy HH:mm:ss')
                        : undefined,
                [
                    sendDate
                ]
            );

        return <Base
            {...props}
            expand
            noInputGroup
        >
            <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={10}
                        glue={glue}
                    >
                        <ViewGroupItem>
                            {
                                campaign &&
                                <EntityLink
                                    stopPropagation
                                    entity={campaign}
                                />
                            }
                        </ViewGroupItem>
                        {
                            stepName &&
                            <ViewGroupItem>
                                { stepName }
                            </ViewGroupItem>
                        }
                        {
                            <ViewGroupItem>
                                {
                                    formattedDate
                                }
                            </ViewGroupItem>
                        }
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                    >
                        <ViewGroupItem>
                            <SpotlerCampaignResultChartViewed
                                campaignResult={props.entity}
                                size={100}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <SpotlerCampaignResultChartClicked
                                campaignResult={props.entity}
                                size={100}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <SpotlerCampaignResultChartBounced
                                campaignResult={props.entity}
                                size={100}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
            </ViewGroup>
        </Base>
    }