import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import styles from './Caption.module.scss';
import useTypes from '../../Type/Api/useTypes';
import Activity from './Activity/Activity';
import Relationship from './Relationship/Relationship';
import Relation from './Relation/Relation';
import Default from './Default/Default';
import { classNames } from '../../../../../@Future/Util/Class/classNames';
import { LinkClasses } from './Link/Link';

export interface CaptionClasses
{
    root?: string;
    link?: LinkClasses;
}

export interface CaptionProps
{
    entity: Entity;
    classes?: CaptionClasses;
}

const Caption: React.FC<CaptionProps> =
    props =>
    {
        const types = useTypes();
        let component;

        if (props.children)
        {
            component = props.children;
        }
        else if (props.entity.entityType.isA(types.Relation.Type))
        {
            component =
                <Relation
                    entity={props.entity}
                />;
        }
        else if (props.entity.entityType.isA(types.Relationship.Type))
        {
            component =
                <Relationship
                    entity={props.entity}
                    classes={props.classes}
                />;
        }
        else if (props.entity.entityType.isA(types.Activity.Type))
        {
            component =
                <Activity
                    entity={props.entity}
                    classes={props.classes}
                />;
        }
        else
        {
            component =
                <Default
                    entity={props.entity}
                    classes={props.classes}
                />
        }

        return <div
            className={classNames(styles.root, props.classes && props.classes.root)}
        >
            {component}
        </div>;
    };

export default observer(Caption);
