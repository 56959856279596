import React, { useContext, useEffect, useMemo, useState } from 'react';
import RichtextEditorContext from '../RichtextEditorContext/RichtextEditorContext';
import Quill from 'quill';
import IconButton from '../../../Button/Variant/Icon/IconButton';
import ToolbarButtonWrapper from './ToolbarButtonWrapper';
import { primaryColor, textSecondaryColor } from '../../../../../../@Resource/Theme/Theme';

export type EditorCommand = 'bold' | 'italic' | 'underline' | 'strike' | 'list.ordered' | 'list.bullet' | 'align.' | 'align.center' | 'align.right' | 'link';

export interface EditorButtonProps
{
    icon: string;
    tooltip: React.ReactNode;
    command: EditorCommand;
}

const EditorButton: React.FC<EditorButtonProps> =
    props =>
    {
        const context = useContext(RichtextEditorContext);
        const [isActive, setActive] = useState(false);

        const commandType =
            useMemo(
                () =>
                {
                    let dividerPos = props.command.indexOf('.');
                    if (dividerPos > 0)
                    {
                        return props.command.substring(0, dividerPos);
                    }
                    else
                    {
                        return props.command;
                    }
                },
                [
                    props.command
                ]);

        const commandValue =
            useMemo(
                () =>
                {
                    let dividerPos = props.command.indexOf('.');
                    if (dividerPos > 0)
                    {
                        return props.command.substring(dividerPos + 1);
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.command
                ]);

        useEffect(
            () =>
            {
                if (context.editor)
                {
                    // Hook to editor change to check if button needs 'active' update
                    context.editor.on(
                        (Quill as any).events.EDITOR_CHANGE,
                        (type: any, range: any) =>
                        {
                            if (type === (Quill as any).events.SELECTION_CHANGE)
                            {
                                const formats = range == null ? {} : context.editor.getFormat(range);

                                if (commandValue !== undefined)
                                {
                                    setActive(
                                        commandValue.length === 0 && !formats[commandType]
                                            ?
                                                true
                                            :
                                                formats[commandType] && formats[commandType] === commandValue);
                                }
                                else
                                {
                                    setActive(
                                        formats[commandType]);
                                }
                            }
                        });
                }
            },
            [
                commandType,
                commandValue,
                context.editor
            ]);

        return context.allowTextStyling &&
            <ToolbarButtonWrapper>
                <IconButton
                    icon={props.icon}
                    tooltip={props.tooltip}
                    onClick={() => context.performCommand(props.command, !isActive)}
                    color={isActive ? primaryColor : textSecondaryColor}
                    small
                />
            </ToolbarButtonWrapper>;
    };

EditorButton.defaultProps =
{

};

export default EditorButton;
