import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { useContext, useEffect } from 'react';
import MultiplayerContext from '../Context/MultiplayerContext';

export default function useMultiplayerViewingEntity(entity?: Entity,
                                                    isEnabled: boolean = true)
{
    const multiplayer = useContext(MultiplayerContext);

    useEffect(
        () =>
        {
            if (multiplayer && isEnabled)
            {
                if (entity && !entity.isNew())
                {
                    multiplayer.trySendMessage(
                        JSON.stringify({
                            type: 'Entity.Enter',
                            entityId: entity.id
                        }));

                    return () =>
                        multiplayer.trySendMessage(
                            JSON.stringify({
                                type: 'Entity.Leave',
                                entityId: entity.id
                            }));
                }
            }
        },
        [
            multiplayer,
            entity,
            isEnabled
        ]);
}
