import React, { useEffect, useMemo, useRef } from 'react';
import styles from './Menu.module.scss';
import ViewGroup from '../ViewGroup/ViewGroup';
import ViewGroupItem from '../ViewGroup/ViewGroupItem';
import Divider from '../Divider/Divider';
import { classNames } from '../../../Util/Class/classNames';

export interface MenuProps
{
    hideGlue?: boolean;
    className?: string;
    scrollToItemIdx?: number;
    disableMaxHeight?: boolean;
}

const itemHeight = 36;

const Menu: React.FC<MenuProps> =
    props =>
    {
        const glue =
            useMemo(
                () =>
                    props.hideGlue
                        ?
                            undefined
                        :
                            () => <Divider />,
                [
                    props.hideGlue
                ]);

        const ref = useRef();

        useEffect(
            () =>
            {
                if (ref.current && props.scrollToItemIdx !== undefined)
                {
                    const element = (ref.current as any);

                    const numVisibleElements = element.clientHeight / itemHeight;

                    const scrollToElement = props.scrollToItemIdx - Math.floor((numVisibleElements - 1) / 2);
                    element.scrollTop = itemHeight * scrollToElement;
                }
            },
            [
                ref,
                props.scrollToItemIdx
            ]);

        return <div
            ref={ref}
            className={classNames(styles.root, props.disableMaxHeight && styles.maxHeightDisabled, props.className)}
        >
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={glue}
            >
                {React.Children.map(
                    props.children as any[],
                    child =>
                        child &&
                            <ViewGroupItem
                                key={child.key}
                            >
                                {child}
                            </ViewGroupItem>)}
            </ViewGroup>
        </div>;
    };

Menu.defaultProps =
{
    hideGlue: false,
    disableMaxHeight: true
};

export default Menu;
