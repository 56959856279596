import { observable, runInAction } from 'mobx';
import Action from '../../../../../../../../../@Api/Automation/Function/Action/Action';

const currentCopiedAction = observable.box<Action<any, any>>();

export function copyActionToClipboard(action: Action<any, any>)
{
    runInAction(
        () =>
            currentCopiedAction.set(action));
}

export function getCopiedActionFromClipboard()
{
    return currentCopiedAction.get();
}

export function hasCopiedActionInClipboard()
{
    return getCopiedActionFromClipboard() !== undefined;
}
