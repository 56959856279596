import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TextEditor from '../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import performAction from '../../../../../@Api/Entity/performAction';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import PrimaryButton from '../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import Dialog from '../../../../../@Future/Component/Generic/Dialog/Dialog';
import DialogTitle from '../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import CancelButton from '../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import Loader from '../../../../../@Future/Component/Generic/Loader/Loader';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import styles from './CocSearch.module.scss';
import CocResult from '../CocResult/CocResult';
import RightAlignedButtonGroup from '../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import StaticSelectbox from '../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import useIsMobile from '../../../../../@Util/Responsiveness/useIsMobile';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../@Api/Localization/localizeText';
import LocalizerContext from '../../../../../@Service/Localization/LocalizerContext';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import Chip from '../../../../../@Future/Component/Generic/Chip/Chip';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import Switch from '../../../../../@Future/Component/Generic/Input/Switch/Switch';

export interface SearchProps
{
    entity?: Entity;
    query?: string;
    onSelect?: (result: any) => void;
    onClose?: () => void;
    commitContext?: CommitContext
}

/*const countryOptions = [
    { id: 'AF', value: 'AF', label: 'Afghanistan' },
    { id: 'AL', value: 'AL', label: 'Albanië' },
    { id: 'DZ', value: 'DZ', label: 'Algerije' },
    { id: 'AS', value: 'AS', label: 'Amerikaans Samoa' },
    { id: 'VI', value: 'VI', label: 'Amerikaanse Maagdeneilanden' },
    { id: 'AO', value: 'AO', label: 'Angola' },
    { id: 'AR', value: 'AR', label: 'Argentinië' },
    { id: 'AM', value: 'AM', label: 'Armenië' },
    { id: 'AU', value: 'AU', label: 'Australië' },
    { id: 'AZ', value: 'AZ', label: 'Azerbeidzjan' },
    { id: 'BH', value: 'BH', label: 'Bahrein' },
    { id: 'BD', value: 'BD', label: 'Bangladesh' },
    { id: 'BE', value: 'BE', label: 'België' },
    { id: 'BJ', value: 'BJ', label: 'Benin' },
    { id: 'RE', value: 'RE', label: 'Bijeenkomst' },
    { id: 'BA', value: 'BA', label: 'Bosnië & Herzegovina' },
    { id: 'BW', value: 'BW', label: 'Botswana' },
    { id: 'BR', value: 'BR', label: 'Brazilië' },
    { id: 'BG', value: 'BG', label: 'Bulgarije' },
    { id: 'BF', value: 'BF', label: 'Burkina Faso' },
    { id: 'BI', value: 'BI', label: 'Burundi' },
    { id: 'KH', value: 'KH', label: 'Cambodja' },
    { id: 'CA', value: 'CA', label: 'Canada' },
    { id: 'CF', value: 'CF', label: 'Centraal Afrikaanse Republiek' },
    { id: 'CL', value: 'CL', label: 'Chili' },
    { id: 'CN', value: 'CN', label: 'China' },
    { id: 'CO', value: 'CO', label: 'Colombia' },
    { id: 'CG', value: 'CG', label: 'Congo' },
    { id: 'CD', value: 'CD', label: 'Democratische Republiek Congo' },
    { id: 'DK', value: 'DK', label: 'Denemarken' },
    { id: 'DJ', value: 'DJ', label: 'Djibouti' },
    { id: 'DE', value: 'DE', label: 'Duitsland' },
    { id: 'EC', value: 'EC', label: 'Ecuador' },
    { id: 'EG', value: 'EG', label: 'Egypte' },
    { id: 'GQ', value: 'GQ', label: 'Equatoriaal-Guinea' },
    { id: 'ER', value: 'ER', label: 'Eritrea' },
    { id: 'EE', value: 'EE', label: 'Estland' },
    { id: 'ET', value: 'ET', label: 'Ethiopië' },
    { id: 'FI', value: 'FI', label: 'Finland' },
    { id: 'FR', value: 'FR', label: 'Frankrijk' },
    { id: 'TG', value: 'TG', label: 'Gaan' },
    { id: 'GA', value: 'GA', label: 'Gabon' },
    { id: 'GM', value: 'GM', label: 'Gambia' },
    { id: 'GE', value: 'GE', label: 'Georgië' },
    { id: 'GH', value: 'GH', label: 'Ghana' },
    { id: 'GR', value: 'GR', label: 'Griekenland' },
    { id: 'GU', value: 'GU', label: 'Guam' },
    { id: 'GN', value: 'GN', label: 'Guinea' },
    { id: 'GW', value: 'GW', label: 'Guinee-Bissau' },
    { id: 'HK', value: 'HK', label: 'Hong Kong' },
    { id: 'HU', value: 'HU', label: 'Hongarije' },
    { id: 'IE', value: 'IE', label: 'Ierland' },
    { id: 'IS', value: 'IS', label: 'IJsland' },
    { id: 'IN', value: 'IN', label: 'India' },
    { id: 'ID', value: 'ID', label: 'Indonesië' },
    { id: 'IQ', value: 'IQ', label: 'Irak' },
    { id: 'IR', value: 'IR', label: 'Iran' },
    { id: 'IT', value: 'IT', label: 'Italië' },
    { id: 'CI', value: 'CI', label: 'Ivoorkust' },
    { id: 'JP', value: 'JP', label: 'Japan' },
    { id: 'YE', value: 'YE', label: 'Jemen' },
    { id: 'JO', value: 'JO', label: 'Jordanië' },
    { id: 'CV', value: 'CV', label: 'Kaapverdië' },
    { id: 'CM', value: 'CM', label: 'Kameroen' },
    { id: 'KZ', value: 'KZ', label: 'Kazachstan' },
    { id: 'KE', value: 'KE', label: 'Kenia' },
    { id: 'KG', value: 'KG', label: 'Kirgizië' },
    { id: 'KW', value: 'KW', label: 'Koeweit' },
    { id: 'XK', value: 'XK', label: 'Kosovo' },
    { id: 'HR', value: 'HR', label: 'Kroatië' },
    { id: 'LA', value: 'LA', label: 'Laos' },
    { id: 'LS', value: 'LS', label: 'Lesotho' },
    { id: 'LV', value: 'LV', label: 'Letland' },
    { id: 'LB', value: 'LB', label: 'Libanon' },
    { id: 'LR', value: 'LR', label: 'Liberia' },
    { id: 'LY', value: 'LY', label: 'Libië' },
    { id: 'LI', value: 'LI', label: 'Liechtenstein' },
    { id: 'LT', value: 'LT', label: 'Litouwen' },
    { id: 'LU', value: 'LU', label: 'Luxemburg' },
    { id: 'MK', value: 'MK', label: 'Macedonië' },
    { id: 'MG', value: 'MG', label: 'Madagascar' },
    { id: 'MW', value: 'MW', label: 'Malawi' },
    { id: 'MY', value: 'MY', label: 'Maleisië' },
    { id: 'ML', value: 'ML', label: 'Mali' },
    { id: 'MT', value: 'MT', label: 'Malta' },
    { id: 'MA', value: 'MA', label: 'Marokko' },
    { id: 'MH', value: 'MH', label: 'Marshall eilanden' },
    { id: 'MR', value: 'MR', label: 'Mauritanië' },
    { id: 'MU', value: 'MU', label: 'Mauritius' },
    { id: 'YT', value: 'YT', label: 'Mayotte' },
    { id: 'MX', value: 'MX', label: 'Mexico' },
    { id: 'FM', value: 'FM', label: 'Micronesië' },
    { id: 'MD', value: 'MD', label: 'Moldavië' },
    { id: 'ME', value: 'ME', label: 'Montenegro' },
    { id: 'MZ', value: 'MZ', label: 'Mozambique' },
    { id: 'MM', value: 'MM', label: 'Myanmar' },
    { id: 'NA', value: 'NA', label: 'Namibië' },
    { id: 'NL', value: 'NL', label: 'Nederland' },
    { id: 'NP', value: 'NP', label: 'Nepal' },
    { id: 'NZ', value: 'NZ', label: 'Nieuw-Zeeland' },
    { id: 'NE', value: 'NE', label: 'Niger' },
    { id: 'NG', value: 'NG', label: 'Nigeria' },
    { id: 'MP', value: 'MP', label: 'Noordelijke Marianen' },
    { id: 'NO', value: 'NO', label: 'Noorwegen' },
    { id: 'UG', value: 'UG', label: 'Oeganda' },
    { id: 'UA', value: 'UA', label: 'Oekraïne' },
    { id: 'UZ', value: 'UZ', label: 'Oezbekistan' },
    { id: 'OM', value: 'OM', label: 'Oman' },
    { id: 'AT', value: 'AT', label: 'Oostenrijk' },
    { id: 'PK', value: 'PK', label: 'Pakistan' },
    { id: 'PW', value: 'PW', label: 'Palau' },
    { id: 'PS', value: 'PS', label: 'Palestina' },
    { id: 'PL', value: 'PL', label: 'Polen' },
    { id: 'PT', value: 'PT', label: 'Portugal' },
    { id: 'PR', value: 'PR', label: 'Puerto Rico' },
    { id: 'QA', value: 'QA', label: 'Qatar' },
    { id: 'RO', value: 'RO', label: 'Roemenië' },
    { id: 'RU', value: 'RU', label: 'Rusland' },
    { id: 'RW', value: 'RW', label: 'Rwanda' },
    { id: 'SH', value: 'SH', label: 'Saint Helena, Ascension en Tristan Da Cunha' },
    { id: 'ST', value: 'ST', label: 'Sao Tome en Principe' },
    { id: 'SA', value: 'SA', label: 'Saoedi-Arabië' },
    { id: 'SN', value: 'SN', label: 'Senegal' },
    { id: 'RS', value: 'RS', label: 'Servië' },
    { id: 'SC', value: 'SC', label: 'Seychellen' },
    { id: 'SL', value: 'SL', label: 'Sierra Leone' },
    { id: 'SG', value: 'SG', label: 'Singapore' },
    { id: 'SI', value: 'SI', label: 'Slovenië' },
    { id: 'SK', value: 'SK', label: 'Slowakije' },
    { id: 'SD', value: 'SD', label: 'Soedan' },
    { id: 'SO', value: 'SO', label: 'Somalië' },
    { id: 'ES', value: 'ES', label: 'Spanje' },
    { id: 'LK', value: 'LK', label: 'Sri Lanka' },
    { id: 'SZ', value: 'SZ', label: 'Swaziland' },
    { id: 'SY', value: 'SY', label: 'Syrië' },
    { id: 'TJ', value: 'TJ', label: 'Tadzjikstan' },
    { id: 'TW', value: 'TW', label: 'Taiwan' },
    { id: 'TN', value: 'TN', label: 'Tanzania' },
    { id: 'TZ', value: 'TZ', label: 'Tanzania, Verenigde Republiek' },
    { id: 'TH', value: 'TH', label: 'Thailand' },
    { id: 'TD', value: 'TD', label: 'Tsjaad' },
    { id: 'CZ', value: 'CZ', label: 'Tsjechische Republiek' },
    { id: 'TR', value: 'TR', label: 'Turkije' },
    { id: 'TM', value: 'TM', label: 'Turkmenistan' },
    { id: 'VE', value: 'VE', label: 'Venezuela' },
    { id: 'GB', value: 'GB', label: 'Verenigd Koningkrijk' },
    { id: 'AE', value: 'AE', label: 'Verenigde Arabische Emiraten' },
    { id: 'US', value: 'US', label: 'Verenigde Staten van Amerika' },
    { id: 'VN', value: 'VN', label: 'Vietnam' },
    { id: 'EH', value: 'EH', label: 'Westelijke Sahara' },
    { id: 'BY', value: 'BY', label: 'Wit-Rusland' },
    { id: 'ZM', value: 'ZM', label: 'Zambia' },
    { id: 'ZW', value: 'ZW', label: 'Zimbabwe' },
    { id: 'SS', value: 'SS', label: 'Zuid Soedan' },
    { id: 'KR', value: 'KR', label: 'Zuid-Korea' },
    { id: 'SE', value: 'SE', label: 'Zweden' },
    { id: 'CH', value: 'CH', label: 'Zwitserland' }];*/

const CocSearch: React.FC<SearchProps> =
    props =>
    {
        const countryOptions =
            useMemo(
                () =>
                    [
                        { id: 'BE', value: 'BE', label: localizeText('AdvancedCoc.Country.Belgium', 'België') },
                        { id: 'DK', value: 'DK', label: localizeText('AdvancedCoc.Country.Denmark', 'Denemarken') },
                        { id: 'DE', value: 'DE', label: localizeText('AdvancedCoc.Country.Germany', 'Duitsland') },
                        { id: 'FR', value: 'FR', label: localizeText('AdvancedCoc.Country.France', 'Frankrijk') },
                        { id: 'IE', value: 'IE', label: localizeText('AdvancedCoc.Country.Ireland' ,'Ierland') },
                        { id: 'IT', value: 'IT', label: localizeText('AdvancedCoc.Country.Italy', 'Italië') },
                        { id: 'JP', value: 'JP', label: localizeText('AdvancedCoc.Country.Japan', 'Japan') },
                        { id: 'LU', value: 'LU', label: localizeText('AdvancedCoc.Country.Luxemburg', 'Luxemburg') },
                        { id: 'MX', value: 'MX', label: localizeText('AdvancedCoc.Country.Mexico', 'Mexico') },
                        { id: 'NL', value: 'NL', label: localizeText('AdvancedCoc.Country.Netherlands', 'Nederland') },
                        { id: 'NO', value: 'NO', label: localizeText('AdvancedCoc.Country.Norway', 'Noorwegen') },
                        { id: 'GB', value: 'GB', label: localizeText('AdvancedCoc.Country.UnitedKingdom', 'Verenigd Koningkrijk') },
                        { id: 'US', value: 'US', label: localizeText('AdvancedCoc.Country.UnitedStates', 'Verenigde Staten van Amerika') },
                        { id: 'SE', value: 'SE', label: localizeText('AdvancedCoc.Country.Sweden', 'Zweden') }
                    ],
                []
            );

        const localizer = useContext(LocalizerContext);
        const [ searchQuery, setSearchQuery ] =
            useState<string>(
                () =>
                    props.entity?.getName(props.commitContext)
            );
        const [ searchCityQuery, setSearchCityQuery ] = useState<string>();
        const [ searchResults, setSearchResults ] = useState<any[]>();
        const [ searchTradeNameQuery, setSearchTradeNameQuery ] = useState<string>();
        const [ searchRegNrQuery, setSearchRegNrQuery] = useState<string>();
        const [ searchInactiveQuery, setSearchInactiveQuery] = useState(false);

        const [ isLoading, setLoading ] = useState(false);
        const [ isLoadingReport ] = useState(false);
        const [ selectedRecord, setSelectedRecord ] = useState<any>();
        const [ searchCountryQuery, setSearchCountryQuery ] = useState(localizer.countryCode);

        const query: any =
            useMemo(
                () =>
                {
                    return {
                        countries: searchCountryQuery,
                        name: searchQuery,
                        tradeName: searchTradeNameQuery,
                        regNo: searchRegNrQuery,
                        city: searchCityQuery,
                        pageSize: 100,
                        status: searchInactiveQuery || searchRegNrQuery ? undefined : 'Active',
                    }
                },
                [
                    searchQuery,
                    searchTradeNameQuery,
                    searchRegNrQuery,
                    searchCityQuery,
                    searchCountryQuery,
                    searchInactiveQuery
                ]
            );

        const search =
            useCallback(
                () =>
                {
                    if (query)
                    {
                        setSelectedRecord(null);
                        setLoading(true);

                        performAction(
                            undefined,
                            {
                                code: 'Organization.CoC.Search',
                                parameters: query
                            })
                            .then(
                                results =>
                                {
                                    setLoading(false);
                                    setSearchResults(results?.result);
                                });
                    }
                },
                [
                    query,
                    setLoading,
                    setSearchResults
                ]);

        useEffect(
            () =>
            {

                if (searchQuery && searchQuery.length > 0)
                {
                    search();
                }
            },
            []);

        const isMobile = useIsMobile();
        const selectedRecordViewer =
            selectedRecord &&
                <CocResult
                    record={selectedRecord}
                    countryCode={searchCountryQuery}
                    onClose={props.onClose}
                    onSelect={props.onSelect}
                />;

        const getTradingNames =
            useCallback(
                (result: any) =>
                {
                    if (result !== undefined && result.tradingNames != null)
                    {
                        if (Array.isArray(result.tradingNames))
                        {
                            return result
                                .tradingNames
                                .filter((name, idx) => idx < 4).join(', ');
                        }
                        else
                        {
                            return result
                                .tradingNames;
                        }
                    }
                    return "";
                    },
                []
            );

        return <Dialog
            onClose={props.onClose}
            width="lg"
            open
        >
            <DialogTitle>
                <LocalizedText
                    code="AdvancedCoc.SearchInCoC"
                    value="Zoeken in de Kamer van Koophandel"
                />
            </DialogTitle>
            <ViewGroup
                orientation={isMobile ? 'vertical' : 'horizontal'}
                spacing={15}
            >
                {
                    isMobile && selectedRecord &&
                        <ViewGroupItem>
                            <CardInset>
                                {selectedRecordViewer}
                            </CardInset>
                        </ViewGroupItem>
                }
                <ViewGroupItem
                    ratio={isMobile ? undefined : 1}
                >
                    {
                        isLoadingReport &&
                            <CardInset>
                                <Centered
                                    horizontal
                                >
                                    <Loader />
                                </Centered>
                            </CardInset>
                    }
                    {
                        !isLoadingReport &&
                            <ViewGroup
                                orientation="vertical"
                                spacing={10}
                            >
                                <ViewGroupItem className={styles.header}>
                                    <CardInset>
                                        <ViewGroup
                                            orientation="horizontal"
                                            spacing={15}
                                            alignment="end"
                                            justification="end"
                                            css={{zIndex: 20}}
                                        >
                                            <ViewGroupItem
                                                ratio={1}
                                            >
                                                <div
                                                    className={styles.search}
                                                >
                                                    <TextEditor
                                                        value={searchQuery}
                                                        onChange={setSearchQuery}
                                                        placeholder={`${localizeText('AdvancedCoc.CompanyName', 'Bedrijfsnaam')}`}
                                                    />
                                                </div>
                                            </ViewGroupItem>
                                            <ViewGroupItem
                                                ratio={1}
                                            >
                                                <div
                                                    className={styles.search}
                                                >
                                                    <TextEditor
                                                        value={searchTradeNameQuery}
                                                        onChange={setSearchTradeNameQuery}
                                                        placeholder={`${localizeText('AdvancedCoc.TradeName', 'Handelsnaam')}`}
                                                    />
                                                </div>
                                            </ViewGroupItem>
                                            <ViewGroupItem
                                                ratio={1}
                                            >
                                                <div
                                                    className={styles.search}
                                                >
                                                    <TextEditor
                                                        value={searchRegNrQuery}
                                                        onChange={setSearchRegNrQuery}
                                                        placeholder={`${localizeText('AdvancedCoc.RegistrationNumber', 'Kvk nummer')}`}
                                                    />
                                                </div>
                                            </ViewGroupItem>
                                            <ViewGroupItem
                                                ratio={1}
                                            >
                                                <div
                                                    className={styles.search}
                                                >
                                                    <TextEditor
                                                        value={searchCityQuery}
                                                        onChange={setSearchCityQuery}
                                                        placeholder={`${localizeText('AdvancedCoc.City', 'Plaatsnaam')}`}
                                                    />
                                                </div>
                                            </ViewGroupItem>
                                            <ViewGroupItem
                                                ratio={1}
                                            >
                                                <div
                                                    className={styles.search}
                                                >
                                                    <StaticSelectbox
                                                        options={countryOptions}
                                                        onChange={setSearchCountryQuery}
                                                        value={searchCountryQuery}
                                                    />
                                                </div>
                                            </ViewGroupItem>
                                            <ViewGroupItem
                                                ratio={1}
                                            >
                                                <div
                                                    className={styles.search}
                                                >
                                                    <Input
                                                        label={
                                                            <LocalizedText
                                                                code="AdvancedCoC.ShowInactive"
                                                                value="Toon inactieve relaties"
                                                            />
                                                        }
                                                        labelPosition="right"
                                                    >
                                                        <Switch
                                                            checked={searchInactiveQuery}
                                                            onToggle={setSearchInactiveQuery}
                                                        />
                                                    </Input>
                                                </div>
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <PrimaryButton
                                                    label={
                                                        <LocalizedText
                                                            code="Generic.Search"
                                                            value="Zoeken"
                                                        />
                                                    }
                                                    onClick={search}
                                                />
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </CardInset>
                                </ViewGroupItem>
                                {
                                    isLoading &&
                                        <ViewGroupItem>
                                            <Centered
                                                horizontal
                                            >
                                                <Loader />
                                            </Centered>
                                        </ViewGroupItem>
                                }
                                {
                                    !isLoading && searchResults && searchResults.length > 0 &&
                                        <ViewGroupItem>
                                            <TableContainer
                                                className={styles.searchResultContainer}
                                            >
                                                <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <LocalizedText
                                                                code="AdvancedCoc.CompanyName"
                                                                value="Bedrijfsnaam"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <LocalizedText
                                                                code="AdvancedCoc.TradingName"
                                                                value="Handelsnaam"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <LocalizedText
                                                                code="AdvancedCoc.CoC"
                                                                value="KVK"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <LocalizedText
                                                                code="AdvancedCoc.City"
                                                                value="Plaats"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        searchResults.map(
                                                            result =>
                                                                <TableRow
                                                                    key={result.id}
                                                                    className={styles.searchResult}
                                                                    onClick={() => setSelectedRecord(result)}
                                                                    hover
                                                                    style={{textDecoration: (result?.status === 'NonActive') ? 'line-through' : 'none' }}
                                                                >
                                                                    <TableCell>
                                                                        <ViewGroup
                                                                            orientation="horizontal"
                                                                            spacing={15}

                                                                        >
                                                                            <ViewGroupItem
                                                                                ratio={1}
                                                                            >
                                                                                {result.name}
                                                                            </ViewGroupItem>
                                                                            {
                                                                                result?.status === 'NonActive' &&
                                                                                <ViewGroupItem>
                                                                                    <Chip
                                                                                        label={
                                                                                            <LocalizedText
                                                                                                code="Generic.Inactive"
                                                                                                value="Inactief"
                                                                                            />
                                                                                        }
                                                                                        textColor="red"
                                                                                        backgroundColor="red"
                                                                                    />
                                                                                </ViewGroupItem>
                                                                            }

                                                                        </ViewGroup>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            getTradingNames(result)
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>{result.regNo}</TableCell>
                                                                    <TableCell>{result?.address?.city}</TableCell>
                                                                </TableRow>)
                                                    }
                                                </TableBody>
                                            </Table>
                                            </TableContainer>
                                        </ViewGroupItem>
                                }
                                {
                                    searchResults && searchResults.length === 0 &&
                                        <ViewGroupItem>
                                            <Centered
                                                horizontal
                                            >
                                                <LocalizedText
                                                    code="Generic.NoResultsFound"
                                                    value="Er zijn geen resultaten gevonden."
                                                />
                                            </Centered>
                                        </ViewGroupItem>
                                }
                            </ViewGroup>
                    }
                </ViewGroupItem>
                {
                    !isMobile && selectedRecord &&
                        <ViewGroupItem
                            ratio={1}
                        >
                            {selectedRecordViewer}
                        </ViewGroupItem>
                }
            </ViewGroup>
            {
                !selectedRecord &&
                <CardInset>
                    <RightAlignedButtonGroup>
                        <CancelButton
                            onClick={props.onClose}
                        />
                    </RightAlignedButtonGroup>
                </CardInset>
            }
        </Dialog>;
    };

export default observer(CocSearch);
