import React, { Fragment } from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import BlockOptionsPanel from '../Settings/BlockOptionsPanel';
import ColorOptionsPanel from '../Settings/ColorOptionsPanel';
import DimensionsOptionsPanel from '../Settings/DimensionsOptionsPanel';
import GutenbergBlockType from '../../Gutenberg/GutenbergBlockType';
import { GutenbergBlockVideoId } from '../../DocumentEditor';
import VideoOptionsPanel from '../Settings/VideoOptionsPanel';
import YoutubeVideoPreviewImage from './Video/YoutubeVideoPreviewImage';
import StyledBlock from '../StyledBlock/StyledBlock';
import Icon from '../../../../Icon/Icon';

const GutenbergBlockVideo: GutenbergBlockType =
{
    name: GutenbergBlockVideoId,
    configuration:
        {
            title: {
                code: 'GutenbergBlock.Video',
                value: 'Video'
            },
            icon: <Icon icon="movie_creation" />,
            category: 'common',

            attributes:
            {
                content:
                {
                    type: 'string',
                    source: 'html',
                    selector: 'img',
                    query: undefined,
                },
            },

            supports:
            {
                align: true
            },

            edit: props =>
            {
                const {
                    width,
                    height,
                    videoUrl
                } = props.attributes as any;

                return (
                    <Fragment>
                        <InspectorControls>
                            <VideoOptionsPanel props={props} open />
                            <DimensionsOptionsPanel props={props} />
                            <BlockOptionsPanel props={props} />
                            <ColorOptionsPanel props={props} />
                        </InspectorControls>
                        <StyledBlock
                            block={props}
                        >
                            <div
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center'
                                }}
                            >
                                <YoutubeVideoPreviewImage
                                    videoUrl={videoUrl}
                                    width={width}
                                    height={height}
                                />
                            </div>
                        </StyledBlock>
                    </Fragment>
                );
            },

            save: (props) =>
            {
                return <p>
                    {(props.attributes as any).content}
                </p>;
            }
        },

    view: block =>
    {
        return <StyledBlock
            block={block}
        />;
    }
};

export default GutenbergBlockVideo;
