import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import CardInset from '../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import Parameter from '../../../../../../../../../@Api/Automation/Parameter/Parameter';
import InputGroup from '../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { runInAction } from 'mobx';
import TextEditor from '../../../../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import ValueTypeEditor from '../../Value/ValueTypeEditor';
import ValueType from '../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import Switch from '../../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import useToggle from '../../../../../../../../../@Util/Toggle/useToggle';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import ComputationEditor from '../../Computation/ComputationEditor';
import FunctionContext from '../../../../../../../../../@Api/Automation/Function/FunctionContext';
import Computation from '../../../../../../../../../@Api/Automation/Function/Computation/Computation';

export interface ParameterEditorProps
{
    parameter: Parameter<any>;
    onDelete: (parameter: Parameter<any>) => void;
    allowDefaultValue?: boolean;
    context?: FunctionContext;
}

const ParameterEditor: React.FC<ParameterEditorProps> =
    props =>
    {
        const setName =
            useCallback(
                (value?: string) =>
                    runInAction(
                        () =>
                            props.parameter.name = value),
                [
                    props.parameter
                ]);

        const setType =
            useCallback(
                (value?: ValueType<any>) =>
                    runInAction(
                        () =>
                        {
                            props.parameter.defaultValue = undefined;
                            props.parameter.type = value;
                        }),
                [
                    props.parameter
                ]);

        const setRequired =
            useCallback(
                (isRequired: boolean) =>
                    runInAction(
                        () =>
                            props.parameter.isRequired = isRequired),
                [
                    props.parameter
                ]);

        const setDefaultValue =
            useCallback(
                (value: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.parameter.defaultValue = value),
                [
                    props.parameter
                ]);

        const setId =
            useCallback(
                (id?: string) =>
                    runInAction(
                        () =>
                            props.parameter.id = id),
                [
                    props.parameter
                ]);

        const onDelete =
            useCallback(
                () =>
                    props.onDelete(props.parameter),
                [
                    props.onDelete,
                    props.parameter
                ]);

        const [ isAdvanced, toggleAdvanced ] = useToggle(false);

        return <CardInset
            vertical={false}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={15}
            >
                <ViewGroupItem
                    ratio={1}
                >
                    <InputGroup>
                        <Input
                            labelPosition="left"
                            label={
                                <LocalizedText
                                    code="Generic.Name"
                                    value="Naam"
                                />
                            }
                        >
                            <TextEditor
                                value={props.parameter.name}
                                onChange={setName}
                                disableUnderline
                            />
                        </Input>
                        <Input
                            labelPosition="left"
                            label={
                                <LocalizedText
                                    code="Generic.Type"
                                    value="Type"
                                />
                            }
                        >
                            <ValueTypeEditor
                                value={props.parameter.type}
                                onChange={setType}
                            />
                        </Input>
                        <Input
                            labelPosition="left"
                            label={
                                <LocalizedText
                                    code="Generic.Mandatory"
                                    value="Verplicht"
                                />
                            }
                        >
                            <Switch
                                checked={props.parameter.isRequired}
                                onToggle={setRequired}
                            />
                        </Input>
                        {
                            props.allowDefaultValue && props.context &&
                                <Input
                                    labelPosition="left"
                                    label={
                                        <LocalizedText
                                            code="Generic.DefaultValue"
                                            value="Standaardwaarde"
                                        />
                                    }
                                >
                                    <ComputationEditor
                                        context={props.context}
                                        value={props.parameter.defaultValue}
                                        onChange={setDefaultValue}
                                        type={props.parameter.type}
                                    />
                                </Input>
                        }
                        {
                            isAdvanced &&
                                <Input
                                    labelPosition="left"
                                    label={
                                        <LocalizedText
                                            code="Generic.Code"
                                            value="Code"
                                        />
                                    }
                                >
                                    <TextEditor
                                        value={props.parameter.id}
                                        onChange={setId}
                                        disableUnderline
                                    />
                                </Input>
                        }
                    </InputGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <MenuButton>
                        <Menu>
                            <Item
                                name={
                                    <LocalizedText
                                        code="Generic.Delete"
                                    />
                                }
                                onClick={onDelete}
                            />
                            <Item
                                name={
                                    <LocalizedText
                                        code="Generic.Advanced"
                                        value="Geavanceerd"
                                    />
                                }
                                onClick={toggleAdvanced}
                            />
                        </Menu>
                    </MenuButton>
                </ViewGroupItem>
            </ViewGroup>

        </CardInset>;
    };

export default observer(ParameterEditor);
