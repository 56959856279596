import { ComputationEditorStore } from '../../../ComputationEditorStore';
import { action, computed, observable } from 'mobx';
import { ComputationContext } from '../../../ComputationContext';
import { ComputationType } from '../../ComputationType';
import { ComputationSpecification } from '../../ComputationSpecification';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import { ComputationTypeStore } from '../../../ComputationTypeStore';
import { Aggregate } from '../../../../DataObject/Model/Aggregate';
import { EntityPath } from '../../../../Entity/Path/@Model/EntityPath';
import { EntityContext } from '../../../../Entity/@Model/EntityContext';
import { EntityPathRootNode } from '../../../../Entity/Path/@Model/Node/EntityPathRootNode';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { EntityPathEditorStore } from '../../../../Entity/Path/Editor/V2/EntityPathEditorStore';
import { TextStore } from '../../../../../Generic/Text/TextStore';
import { EntityTypeStore } from '../../../../Entity/Type/EntityTypeStore';
import { EntityPathPickerStore } from '../../../../Entity/Path/Field/EntityPathPickerStore';

export class EntityAggregateComputationEditorStore extends ComputationEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable aggregate: Aggregate;
    @observable entityPath: EntityPath;
    @observable childContext: ComputationContext;
    @observable computationStore: ComputationEditorStore;
    @observable isAddMenuOpen: boolean;
    @observable isAggregateMenuOpen: boolean;
    @observable isEntityPathEditorOpen: boolean;

    // ------------------------ Constructor -------------------------

    constructor(type: ComputationType,
                context: ComputationContext,
                specification: ComputationSpecification,
                aggregate: Aggregate,
                entityPath: EntityPath,
                childContext: ComputationContext,
                computationStore: ComputationEditorStore)
    {
        super(type, context, specification);

        this.aggregate = aggregate;
        this.entityPath = entityPath;
        this.childContext = childContext;
        this.computationStore = computationStore;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    @computed
    get entityPathPickerStore(): EntityPathPickerStore
    {
        return new EntityPathPickerStore(
            this.context.entityContext,
            this.setEntityPath);
    }

    @computed
    get entityPathEditorStore()
    {
        return new EntityPathEditorStore({
            entityContext: this.context.entityContext,
            entityPath: this.entityPath,
            onChange: path => Promise.resolve(this.setEntityPath(path)),
            onDelete: () => Promise.resolve(this.setEntityPath(undefined)),
            dialogTitle: 'Change path'
        });
    }

    @computed
    get aggregateAllTextStore(): TextStore
    {
        return new TextStore({
            label: this.localizationStore.translate('Computation.AggregateAll'), // Aggregate over all
            variant: 'body1'
        });
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    openAggregateMenu()
    {
        this.isAggregateMenuOpen = true;
    }

    @action.bound
    closeAggregateMenu()
    {
        this.isAggregateMenuOpen = false;
    }

    @action.bound
    setAggregate(aggregate: Aggregate)
    {
        this.aggregate = aggregate;
    }

    @action.bound
    openEntityPathEditor()
    {
        this.isEntityPathEditorOpen = true;
    }

    @action.bound
    closeEntityPathEditor()
    {
        this.isEntityPathEditorOpen = false;
    }

    @action.bound
    setEntityPath(entityPath: EntityPath)
    {
        this.entityPath = entityPath;
        this.childContext = Object.assign({}, this.context,
        {
            entityContext:
                new EntityContext(
                    [],
                    new EntityPath([ new EntityPathRootNode(entityPath.entityType) ]))
        });

        if (this.computationStore)
        {
            this.computationStore.context = this.childContext;
        }
        else
        {
            this.setComputation(this.computationTypeStore.getTypeById('Composite'));
        }
    }

    @action.bound
    openAddMenu()
    {
        this.isAddMenuOpen = true;
    }

    @action.bound
    closeAddMenu()
    {
        this.isAddMenuOpen = false;
    }

    @action.bound
    setComputation(type: ComputationType)
    {
        this.computationStore =
            ComputationEditorStore.construct(
                this.childContext,
                { type: type.id() },
                this.computationTypeStore);
    }

    @action.bound
    deleteComputation()
    {
        this.computationStore = null;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
