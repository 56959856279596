import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import { default as EntityInput } from '../../../../Entity/Input/Input';
import { useExpansion } from '../../../../Entity/Selection/Api/useExpansion';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';

export interface ViewOwnersAndShareEditorProps
{
    entity: Entity;
    commitContext: CommitContext;
    disabled: boolean;
}

const ViewOwnersAndShareEditor: React.FC<ViewOwnersAndShareEditorProps> =
    ({
         entity,
         commitContext,
         disabled,
     }) =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);

        const [ isLoading ] =
            useExpansion(
                types.View.Type,
                rootPath =>
                    [
                        types.View.RelationshipDefinition.Owners,
                        types.View.RelationshipDefinition.SharedWithEmployees,
                        types.View.RelationshipDefinition.SharedWithTeams,
                    ]
                        .map(
                            relationshipDefinition =>
                                rootPath
                                    .joinTo(
                                        relationshipDefinition,
                                        false
                                    ),
                        ),
                () =>
                    entity ? [ entity ] : [],
                [
                    entity,
                    types,
                ]
            );

        const isPrivate =
            useEntityValue<boolean>(
                entity,
                types.View.Field.IsPrivate,
                undefined,
                commitContext
            );

        const onChangeIsPrivate =
            useCallback(
                (isPrivate: boolean) =>
                {
                    // clear owners, employees and teams
                    [
                        types.View.RelationshipDefinition.Owners,
                        types.View.RelationshipDefinition.SharedWithEmployees,
                        types.View.RelationshipDefinition.SharedWithTeams,
                    ].map(
                        relationshipDefinition =>
                            entity
                                .getRelationshipsByDefinition(
                                    false,
                                    relationshipDefinition,
                                    commitContext
                                )
                                ?.forEach(
                                    relationship =>
                                        commitContext.deleteRelationship(relationship)
                                )
                    );

                    if (isPrivate)
                    {
                        commitContext.createRelationship(
                            entity,
                            types.View.RelationshipDefinition.Owners,
                            false,
                            currentUserStore.employeeEntity
                        );

                        entity.setValueByField(
                            types.View.Field.IsDefault,
                            false,
                            false,
                            false,
                            commitContext
                        )

                    }
                },
                [
                    types,
                    entity,
                    commitContext,
                    currentUserStore.employeeEntity,
                ]);

        const onChangeDefault =
            useCallback(
                (value) => {
                    if (value)
                    {
                        entity.setValueByField(
                            types.View.Field.IsPrivate,
                            false,
                            false,
                            false,
                            commitContext
                        )
                        onChangeIsPrivate(false);
                    }
                },
                [
                    types,
                    entity,
                    commitContext,
                    onChangeIsPrivate
                ]
            )

        const onChangeOwners =
            useCallback(
                (owners: Entity[]) =>
                {
                    if (!owners.some(o => o.id === currentUserStore.employeeEntity.id))
                    {
                        loadModuleDirectly(FeedbackStore)
                            .enqueueSnackbar(
                                localizeText(
                                    'Configuration.View.OwnerInfo',
                                    'Je kunt jezelf niet verwijderen van de eigenaren.'),
                                {
                                    variant: 'info'
                                });

                        commitContext.createRelationship(
                            entity,
                            types.View.RelationshipDefinition.Owners,
                            false,
                            currentUserStore.employeeEntity
                        );
                    }
                },
                [
                    types,
                    entity,
                    commitContext,
                    currentUserStore.employeeEntity,
                ]);

        if (isLoading)
        {
            return null;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                {
                    currentUserStore.isAdministrator &&
                    <ViewGroupItem>
                        <EntityInput
                            entity={entity}
                            field={types.View.Field.IsDefault}
                            labelPosition="left"
                            commitContext={commitContext}
                            doAutocommit={false}
                            disabled={disabled}
                            onChange={onChangeDefault}
                        />
                    </ViewGroupItem>
                }
                <ViewGroupItem>
                    <EntityInput
                        entity={entity}
                        field={types.View.Field.IsPrivate}
                        labelPosition="left"
                        commitContext={commitContext}
                        doAutocommit={false}
                        disabled={disabled}
                        onChange={onChangeIsPrivate}
                    />
                </ViewGroupItem>
                {
                    isPrivate &&
                    <ViewGroupItem>
                        <EntityInput
                            entity={entity}
                            field={types.View.RelationshipDefinition.Owners}
                            labelPosition="left"
                            commitContext={commitContext}
                            doAutocommit={false}
                            disabled={disabled}
                            onChange={onChangeOwners}
                        />
                    </ViewGroupItem>
                }
                {
                    isPrivate &&
                    <ViewGroupItem>
                        <EntityInput
                            entity={entity}
                            field={types.View.RelationshipDefinition.SharedWithEmployees}
                            labelPosition="left"
                            commitContext={commitContext}
                            doAutocommit={false}
                            disabled={disabled}
                        />
                    </ViewGroupItem>
                }
                {
                    isPrivate &&
                    <ViewGroupItem>
                        <EntityInput
                            entity={entity}
                            field={types.View.RelationshipDefinition.SharedWithTeams}
                            labelPosition="left"
                            commitContext={commitContext}
                            doAutocommit={false}
                            disabled={disabled}
                        />
                    </ViewGroupItem>
                }
            </ViewGroup>;
        }
    };

export default observer(ViewOwnersAndShareEditor);
