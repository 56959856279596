import { DependencyList, useCallback, useContext, useEffect, useMemo } from 'react';
import RouterContext from '../../RouterContext';
import { RoutingInterceptor } from '../RoutingInterceptor';

export function useRoutingInterceptor(
    shouldRoute: (route: string, parameters: any) => Promise<boolean>,
    deps: DependencyList
)
{
    const routerStore = useContext(RouterContext);
    const stableShouldRoute =
        useCallback(
            shouldRoute,
            deps
        );
    const interceptor =
        useMemo<RoutingInterceptor>(
            () => ({
                shouldRoute: stableShouldRoute,
            }),
            [
                stableShouldRoute
            ]
        );

    useEffect(
        () =>
        {
            routerStore.registerInterceptor(interceptor);

            return () =>
                routerStore.unregisterInterceptor(interceptor);
        },
        [
            interceptor,
        ]
    );
}