import { getModel, isDirty, isLocallyDirty, isTransactionalModel, TransactionalModel as NewTransactionalModel } from '../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { Entity } from '../../Model/Implementation/Entity';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityEvent } from '../../Model/Implementation/EntityEvent';
import { consoleLog } from '../../../@Future/Util/Logging/consoleLog';
import TransactionalCommitAdministration from '../../../@Util/TransactionalModelV2/Shared/TransactionalCommitAdministration';
import { EntityRelationshipDeletionMutation } from '../../Model/Implementation/EntityRelationshipDeletionMutation';
import { EntityRelationshipCreationMutation } from '../../Model/Implementation/EntityRelationshipCreationMutation';
import { EntityRelationshipUpdateMutation } from '../../Model/Implementation/EntityRelationshipUpdateMutation';
import { EntityRelationship } from '../../Model/Implementation/EntityRelationship';
import getEntityDescriptor from './getEntityDescriptor';
import isEntityValid from '../Validation/isEntityValid';
import { CommitContext } from '../Commit/Context/CommitContext';

export default function getRelationshipDescriptor(relationship: NewTransactionalModel<EntityRelationship>,
                                                  isParent?: boolean,
                                                  seenObjects: Set<string> = new Set<string>(),
                                                  backReferences: Set<string> = new Set<string>(),
                                                  isEntityValidByUuid: Map<string, boolean> = new Map<string, boolean>(),
                                                  numberOfMutationsByObject: Map<Entity | EntityRelationship, number> = new Map<Entity | EntityRelationship, number>(),
                                                  path?: EntityPath,
                                                  onFile?: (fileId: string, file: File) => void,
                                                  isDebug: boolean = false,
                                                  isDeep: boolean = true,
                                                  events: EntityEvent[] = [],
                                                  commitContext?: CommitContext)
{
    const relationshipId = relationship && `Relationship.${relationship.uuid}`;
    const relatedEntity = isParent != null && relationship.getEntity(isParent);

    // Do not allow back references.
    // e.g. if you have a task A with a linked activity B with linked relationship C and contact D
    // So: (C, B), (D, B), (B, A)
    // and that same task has a link to contact and relationship, so
    // (C, A), (D, A)
    // If we generate the descriptor, links (C, B) and (D, B) will be found first, while (C, A) and (D, A) will be
    // omitted. This is not what we want. We want to only include relationships which are closest to the root of the
    // tree. The way to do this is to exclude back references. Another way will be to sent a graph to the API, but
    // this is not yet supported.
    const isBackReference =
        relatedEntity != null && backReferences.has(`Entity.${relatedEntity.uuid}`);

    if (isDebug)
    {
        consoleLog(
            'checking',
            path && path.code,
            relationship,
            isParent,
            'undef: ', relationship == null,
            'seen: ', seenObjects.has(relationshipId),
            'unmanaged:', !relationship.isManaged,
            'backref:', isBackReference,
            '=',
            relationship == null || seenObjects.has(relationshipId) || !relationship.isManaged || isBackReference);
    }

    if (relationship == null
        || seenObjects.has(relationshipId)
        || !relationship.isManaged
        || isBackReference
        || relationship.parentEntity == null
        || relationship.childEntity == null)
    {
        return undefined;
    }
    else
    {
        seenObjects.add(relationshipId);

        const administration = new TransactionalCommitAdministration();

        if (isParent != null)
        {
            administration.seen(relationship.getEntity(!isParent));
        }

        const isRelatedDirty =
            (
                commitContext
                    ?
                        isParent == null
                        || relatedEntity == null
                        || relatedEntity.isNew()
                        || commitContext.isEntityGraphDirty(relatedEntity, new Set(seenObjects))
                    :
                        isParent == null
                        || relatedEntity == null
                        || relatedEntity.isNew()
                        || (
                            isTransactionalModel(relatedEntity)
                            && isDirty(
                                relatedEntity as NewTransactionalModel<Entity>,
                                administration))
            );

        let parentEntity =
            (isParent == null || isParent === true) && isRelatedDirty && (commitContext !== undefined || isTransactionalModel(relationship.parentEntity)) && isDeep
                ?
                    getEntityDescriptor(
                        relationship.parentEntity as NewTransactionalModel<Entity>,
                        relationship,
                        seenObjects,
                        backReferences,
                        isEntityValidByUuid,
                        numberOfMutationsByObject,
                        path,
                        onFile,
                        isDebug,
                        events,
                        commitContext
                    )
                :
                    undefined;

        let childEntity =
            (isParent == null || isParent === false) && isRelatedDirty && (commitContext !== undefined || isTransactionalModel(relationship.childEntity))
                ?
                    getEntityDescriptor(
                        relationship.childEntity as NewTransactionalModel<Entity>,
                        relationship,
                        seenObjects,
                        backReferences,
                        isEntityValidByUuid,
                        numberOfMutationsByObject,
                        path,
                        onFile,
                        isDebug,
                        events,
                        commitContext
                    )
                :
                    undefined;

        const hasRelatedDescriptor =
            isParent
                ?
                parentEntity !== undefined
                :
                childEntity !== undefined;

        const isDirtyLocally =
            (
                commitContext
                    ?
                        commitContext.isRelationshipDirty(relationship)
                    :
                        isTransactionalModel(relationship) &&
                        isLocallyDirty(relationship)
            );

        if (isParent != null)
        {
            if (isParent && parentEntity == null && relationship.parentEntity.id == null)
            {
                return undefined;
            }
            else if (!isParent && childEntity == null && relationship.childEntity.id == null)
            {
                return undefined;
            }
        }

        const includeParentEntityId = (parentEntity == null && relationship.parentEntity != null) || isTransactionalModel(relationship.parentEntity);
        const includeChildEntityId = (childEntity == null && relationship.childEntity != null) || isTransactionalModel(relationship.childEntity);

        [ true, false ]
            .forEach(
                isParent =>
                {
                    const originalRelatedEntity = getModel(relationship).getEntity(isParent);
                    const relatedEntity = relationship.getEntity(isParent);

                    if (relationship.isDeleted)
                    {
                        if (!relationship.isNew())
                        {
                            events.push(
                                new EntityRelationshipDeletionMutation(
                                    getModel(relationship).getEntity(!isParent),
                                    getModel(relationship),
                                    isParent,
                                    relationship.definition,
                                    getModel(originalRelatedEntity),
                                    undefined,
                                    true));
                        }
                    }
                    else if (relationship.isNew())
                    {
                        if (!relationship.isDeleted)
                        {
                            events.push(
                                new EntityRelationshipCreationMutation(
                                    getModel(relationship).getEntity(!isParent),
                                    getModel(relationship),
                                    isParent,
                                    relationship.definition,
                                    undefined,
                                    getModel(relatedEntity)));
                        }
                    }
                    else if (originalRelatedEntity.uuid !== relatedEntity.uuid)
                    {
                        events.push(
                            new EntityRelationshipUpdateMutation(
                                getModel(relationship).getEntity(!isParent),
                                getModel(relationship),
                                isParent,
                                relationship.definition,
                                getModel(originalRelatedEntity),
                                getModel(relatedEntity)));
                    }
                });

        let descriptor =
            {
                id: relationship.id,
                uuid: relationship.uuid,
                definitionId: relationship.definition.id,
                definitionCode: relationship.definition.code,
                parentEntity: parentEntity,
                parentEntityId:
                    includeParentEntityId
                        ?
                        relationship.parentEntity.id
                        :
                        undefined,
                parentEntityUuid:
                    includeParentEntityId
                        ?
                        relationship.parentEntity.uuid
                        :
                        undefined,
                childEntity: childEntity,
                childEntityId:
                    includeChildEntityId
                        ?
                        relationship.childEntity.id
                        :
                        undefined,
                childEntityUuid:
                    includeChildEntityId
                        ?
                        relationship.childEntity.uuid
                        :
                        undefined,
                isLocallyDirty: isDirtyLocally,
                isRelatedDirty: isRelatedDirty,
                // whyRelatedDirty:
                //     isRelatedDirty
                //         ?
                //             (relatedEntity instanceof TransactionalModelImpl
                //                 && whyDirty(
                //                     relatedEntity as NewTransactionalModel<Entity>,
                //                     administration2))
                //         :
                //             undefined
            };

        const isLocallyChanged = isDirtyLocally || (relationship.isNew() && (descriptor.parentEntity != null || descriptor.childEntity != null || descriptor.parentEntityId != null || descriptor.childEntityId != null));
        const isRelatedValid =
            relatedEntity != null
                && isEntityValid(
                    relatedEntity,
                    {
                        isValidByEntityUuid: isEntityValidByUuid,
                        isDeep: false,
                        commitContext,
                    });

        if (isDebug)
        {
            consoleLog('   - ',
                path && path.code,
                isRelatedValid,
                isLocallyChanged,
                isRelatedDirty,
                hasRelatedDescriptor,
                '---',
                (isLocallyChanged || (isRelatedDirty && hasRelatedDescriptor)),
                (isLocallyChanged || descriptor.parentEntity || descriptor.childEntity || descriptor.parentEntityId || descriptor.childEntityId),
                descriptor);
        }

        if (isRelatedValid
            && (isLocallyChanged || (isRelatedDirty && hasRelatedDescriptor))
            && (isLocallyChanged || descriptor.parentEntity || descriptor.childEntity || descriptor.parentEntityId || descriptor.childEntityId))
        {
            numberOfMutationsByObject.set(relationship, isLocallyChanged ? 1 : 0);

            return descriptor;
        }
        else
        {
            return undefined;
        }
    }
}
