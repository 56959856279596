import SourceField from '../Model/SourceField';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import performAction from '../../../../../@Api/Entity/performAction';
import { DataObject } from '../../../DataObject/Model/DataObject';

export interface SourceValue
{
    name: string;
    value: any;
}

export default async function getSourceValues(imprt: Entity,
                                              sourceField: SourceField): Promise<SourceValue[]>
{
    const sourceValuesResult =
        await performAction<any[]>(
            imprt,
            {
                code: 'Import.GetSourceValues',
                parameters: {
                    sourceFieldId: sourceField.id
                }
            });

    return sourceValuesResult.result
        .map(
            value => ({
                name: value.name ? value.name : undefined,
                value: DataObject.constructFromDescriptor(value).value
            }));
}
