export const ErrorDataKey = 'data';

export default function getErrorData(error: Error)
{
    if (error && error[ErrorDataKey])
    {
        return error[ErrorDataKey];
    }
    else
    {
        return error;
    }
}
