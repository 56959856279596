import React, { useMemo } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import { Setting } from '../../../../../../../@Api/Settings/Setting';
import useMetadataSetting from '../../../../../../../@Api/Metadata/useMetadataSetting';
import Fields from '../../../../Fields/Fields';
import EntityLayout from '../../../../Layout/EntityLayout';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { useCommittableEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/useCommittableEntity';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import {EntityFieldPath} from "../../../../Path/@Model/EntityFieldPath";

export interface DetailsLayoutProps
{
    entity: Entity;
    isInEditMode: boolean;
    onCloseEditMode: () => void;
    setting?: string;
    showNoneIfNoLayout?: boolean;
    commitContext?: CommitContext;
    hidden?: (fieldPath: EntityFieldPath) => boolean;
    appendix?: React.ReactNode;
    readOnly?: boolean;
}

const DetailsLayout: React.FC<DetailsLayoutProps> =
    props =>
    {
        const commitContext = useNewCommitContext(props.commitContext);
        const transactionalEntity = useCommittableEntity(props.entity, commitContext);

        const [ layoutDescriptor, setLayoutDescriptor, , isSetterForBaseSetting ] =
            useMetadataSetting(
                props.entity.entityType,
                props.setting,
                props.isInEditMode);

        const showOldFieldsInterface =
            useMemo(
                () =>
                    layoutDescriptor === undefined,
                [
                    layoutDescriptor,
                ]);

        const shouldShowOldViewer =
            useMemo(
                () =>
                    !props.isInEditMode && !(layoutDescriptor && !showOldFieldsInterface),
                [
                    props.isInEditMode,
                    layoutDescriptor,
                    showOldFieldsInterface
                ]);

        if (shouldShowOldViewer)
        {
            if (props.showNoneIfNoLayout)
            {
                return null;
            }
            else
            {
                return <Fields
                    entity={transactionalEntity}
                    commitContext={commitContext}
                    appendix={props.appendix}
                    hidden={props.hidden}
                    readOnly={props.readOnly}
                />;
            }
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                {
                    props.isInEditMode && isSetterForBaseSetting &&
                        <ViewGroupItem>
                            <CardInset>
                                <LocalizedText
                                    code="DetailsLayout.BaseMutationWarning"
                                    value="Let op: je past hier de basislayout van de ${singularTypeName} aan. Aanpassingen worden gereflecteerd in alle omgevingen die toegang hebben tot ${pluralTypeName}."
                                    singularTypeName={transactionalEntity.entityType.getName().toLowerCase()}
                                    pluralTypeName={transactionalEntity.entityType.getName(true).toLowerCase()}
                                />
                            </CardInset>
                        </ViewGroupItem>
                }
                <ViewGroupItem>
                    <EntityLayout
                        entity={transactionalEntity}
                        layout={layoutDescriptor}
                        onChangeLayout={setLayoutDescriptor}
                        isInEditMode={props.isInEditMode}
                        onCloseEditMode={props.onCloseEditMode}
                        commitContext={commitContext}
                    />
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

DetailsLayout.defaultProps = {
    setting: Setting.Metadata.DetailsLayout
};

export default observer(DetailsLayout);
