import React from 'react';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import styles from './Tooltip.module.scss';
import { classNames } from '../../../Util/Class/classNames';

export type TooltipPlacement =
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';

export interface TooltipProps
{
    title?: React.ReactNode;
    block?: boolean;
    placement?: TooltipPlacement;
    open?: boolean;
    fullWidth?: boolean;
    fullHeight?: boolean;
    customTooltipStyle?: string;
    interactive?: boolean;
}

const defaultEnterDelay = 250;

const Tooltip: React.FC<TooltipProps> =
    props =>
    {
        if (props.title)
        {
            return <MuiTooltip
                title={props.title}
                enterDelay={defaultEnterDelay}
                placement={props.placement}
                interactive={props.interactive}
                className={
                    classNames(
                        styles.root,
                        props.block && styles.block,
                        props.fullWidth && styles.fullWidth,
                        props.fullHeight && styles.fullHeight
                    )
                }
                classes={{tooltip: props.customTooltipStyle}}
                open={props.open}
            >
                <div> {/*  DIV is needed because you can not add a tooltip to a disabled button (Material UI throws an error) */}
                    {props.children}
                </div>
            </MuiTooltip>;
        }
        else
        {
            return <>
                {props.children}
            </>;
        }
    };

export default Tooltip;
