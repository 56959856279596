import { useCallback, useMemo, useState } from 'react';

export default function useToggle(initialValue: boolean): [ boolean, (value?: boolean) => void ]
{
    const [ isToggled, setToggled ] = useState(initialValue);
    const onToggle =
        useCallback(
            (value?: boolean) =>
            {
                if (typeof value !== 'boolean')
                {
                    setToggled(
                        isToggled =>
                            !isToggled
                    );
                }
                else
                {
                    setToggled(value);
                }
            },
            []
        );

    return useMemo(
        () =>
            [
                isToggled,
                onToggle
            ],
        [
            isToggled,
            onToggle
        ]);
}
