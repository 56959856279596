import React, { Key, useCallback, useContext } from 'react';
import { default as InfiniteScrollComponent } from 'react-infinite-scroller';
import ScrollRefContext from '../../../../@Service/Navigation/Page/Scroll/ScrollRefContext';

export interface InfiniteScrollProps
{
    loadMore: () => any;
    hasMore: boolean;
    isLoading?: boolean;
    renderKey?: Key;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> =
    props =>
    {
        const scrollRef = useContext(ScrollRefContext);
        const getScrollParent =
            useCallback(
                () =>
                    scrollRef && scrollRef.current,
                [
                    scrollRef
                ]);

        return <InfiniteScrollComponent
            key={props.renderKey}
            loadMore={props.loadMore}
            hasMore={props.hasMore && !props.isLoading}
            getScrollParent={getScrollParent}
            useWindow={false}
        >
            {props.children}
        </InfiniteScrollComponent>;
    };

export default InfiniteScroll;
