import Widget from '../../Widget';
import { observable } from 'mobx';
import { EmptyDisposer } from '../../../../../../../@Util/Disposable/EmptyDisposer';

export default class NoteWidget extends Widget
{
    // ------------------------- Properties -------------------------

    @observable note: string;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                note: string)
    {
        super(id);

        this.note = note;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async doInitialize(widgets: Widget[])
    {
        return EmptyDisposer;
    }

    static fromDescriptor(descriptor: any)
    {
        return new NoteWidget(
            descriptor.id,
            descriptor.note);
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Note',
            note: this.note
        }
    }

    // ----------------------- Private logic ------------------------
}
