import React, { useCallback, useMemo } from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityPath } from '../../../../../../Path/@Model/EntityPath';
import { createTransactionalModel, getModel } from '../../../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import useTypes from '../../../../../../Type/Api/useTypes';
import { Comparator } from '../../../../../../../DataObject/Model/Comparator';
import isHiddenType from '../../../../../../../../../@Api/Metadata/EntityType/isHiddenType';
import useMetadataSettingFlag from '../../../../../../../../../@Api/Metadata/useMetadataSettingFlag';
import { Setting } from '../../../../../../../../../@Api/Settings/Setting';
import isMutable from '../../../../../../../../../@Api/RightProfile/isMutable';
import ActivityList from '../../../RelatedActivityList/Model/ActivityList';
import Parameter from '../../../../../../../../../@Api/Automation/Parameter/Parameter';
import uuid from '../../../../../../../../../@Util/Id/uuid';
import EntityValueType from '../../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import ActivityListItem from '../../../RelatedActivityList/Model/ActivityListItem';
import ComparisonPredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import EntityValue from '../../../../../../../../../@Api/Automation/Value/EntityValue';
import RelatedActivityListRoot from '../../../RelatedActivityList/RelatedActivityListRoot';

export interface ProjectActivityListProps
{
    entity: Entity;
    milestone?: Entity;
    onCount: (count: number) => void;
    isInEditMode: boolean;
    onChangeEditMode: (isInEditMode: boolean) => void;
}

const ProjectActivityList: React.FC<ProjectActivityListProps> =
    props =>
    {
        const types = useTypes();

        const [ hasProducts ] = useMetadataSettingFlag(props.entity.entityType, Setting.Metadata.HasProducts);

        const relatedActivityPaths =
            useComputed(
                () => [
                    ...types.Activity.Type
                        .getAllInstantiableTypes()
                        .filter(
                            type =>
                            {
                                if (
                                    isHiddenType(type) ||
                                    type.isA(types.Activity.ApsisCampaignResult.Type) ||
                                    type.isA(types.Activity.ApsisCampaign.Type) ||
                                    type.isA(types.Activity.ApsisFormResult.Type) ||
                                    type.isA(types.Activity.ApsisForm.Type) ||
                                    type.isA(types.Activity.Project.Type)
                                )
                                {
                                    return false;
                                }
                                else if (type === types.Activity.Invoice.Type)
                                {
                                    return hasProducts;
                                }
                                else
                                {
                                    return true;
                                }
                            })
                        .map(
                            activityType =>
                                EntityPath.fromEntity(props.entity)
                                    .joinTo(
                                        types.Activity.RelationshipDefinition.LinkedActivities,
                                        false)
                                    .castTo(activityType)),
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Entity.RelationshipDefinition.Attachments,
                            false),
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Entity.RelationshipDefinition.Notes,
                            false)
                ],
                [
                    props.entity,
                    types,
                    hasProducts
                ]);

        const onConstruct =
            useCallback(
                (entity: Entity, path: EntityPath) =>
                {
                    if (entity.entityType.isA(types.Activity.Type))
                    {
                        // In case of a milestone, add it to the milestone
                        if (props.milestone)
                        {
                            entity.updateRelationship(
                                true,
                                types.Milestone.RelationshipDefinition.Activities,
                                createTransactionalModel(
                                    getModel(props.milestone)));
                        }
                    }
                    else if (entity.entityType.isA(types.Note.Type))
                    {
                        // In case of a milestone, add it to the milestone
                        if (props.milestone)
                        {
                            entity.updateRelationship(
                                true,
                                types.Milestone.RelationshipDefinition.Notes,
                                createTransactionalModel(
                                    getModel(props.milestone)));
                        }
                    }
                    else if (entity.entityType.isA(types.Attachment.Type))
                    {
                        // In case of a milestone, add it to the milestone
                        if (props.milestone)
                        {
                            entity.updateRelationship(
                                true,
                                types.Milestone.RelationshipDefinition.Attachments,
                                createTransactionalModel(
                                    getModel(props.milestone)));
                        }
                    }
                },
                [
                    props.milestone,
                    types
                ]);

        const isMore =
            useCallback(
                (path: EntityPath) =>
                {
                    if (isMutable(path.entityType.entity))
                    {
                        return false;
                    }
                    else if (path.entityType.isA(types.Activity.Task.Type)
                        || path.entityType.isA(types.Activity.Appointment.Type)
                        || path.entityType.isA(types.Activity.Email.Type)
                        || path.entityType.isA(types.Attachment.Type)
                        || path.entityType.isA(types.Note.Type))
                    {
                        return false;
                    }
                    else
                    {
                        return !path.entityType.isA(types.Activity.Document.Type);
                    }
                },
                [
                    types
                ]);

        const filter =
            useCallback(
                (parameter: Parameter<EntityValueType>, path: EntityPath) =>
                {
                    if (path.entityType.isA(types.Activity.Type)
                        && props.milestone)
                    {
                        return new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                parameter,
                                EntityPath.fromEntityType(path.entityType)
                                    .joinTo(
                                        types.Milestone.RelationshipDefinition.Activities,
                                        true)
                                    .field()),
                            Comparator.Equals,
                            new EntityValue(props.milestone));
                    }
                    else if (path.entityType.isA(types.Note.Type)
                        && props.milestone)
                    {
                        return new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                parameter,
                                EntityPath.fromEntityType(path.entityType)
                                    .joinTo(
                                        types.Milestone.RelationshipDefinition.Notes,
                                        true)
                                    .field()),
                            Comparator.Equals,
                            new EntityValue(props.milestone));
                    }
                    else if (path.entityType.isA(types.Attachment.Type)
                        && props.milestone)
                    {
                        return new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                parameter,
                                EntityPath.fromEntityType(path.entityType)
                                    .joinTo(
                                        types.Milestone.RelationshipDefinition.Attachments,
                                        true)
                                    .field()),
                            Comparator.Equals,
                            new EntityValue(props.milestone));
                    }
                    else if (path.entityType.isA(types.TimeRegistration.Type)
                        && props.milestone)
                    {
                        return new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                parameter,
                                EntityPath.fromEntityType(path.entityType)
                                    .joinTo(
                                        types.Milestone.RelationshipDefinition.TimeRegistrations,
                                        true)
                                    .field()),
                            Comparator.Equals,
                            new EntityValue(props.milestone));
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    types,
                    props.milestone
                ]);

        const activityList =
            useMemo(
                () =>
                    new ActivityList(
                        new Parameter(
                            uuid(),
                            new EntityValueType(props.entity.entityType),
                            true,
                            props.entity.entityType.getName()),
                        relatedActivityPaths.map(
                            path =>
                            {
                                const parameter =
                                    new Parameter(
                                        uuid(),
                                        new EntityValueType(path.entityType),
                                        true,
                                        path.entityType.getName());

                                return new ActivityListItem(
                                    parameter,
                                    path,
                                    true,
                                    isMore(path));
                            })),
                [
                    props.entity,
                    relatedActivityPaths
                ]);

        return <RelatedActivityListRoot
            list={activityList}
            entity={props.entity}
            onConstruct={onConstruct}
            filter={filter}
            onCount={props.onCount}
            isInEditMode={props.isInEditMode}
            onChangeEditMode={props.onChangeEditMode}
            showCreationItem
        />;
    };

export default observer(ProjectActivityList);
