import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { runInAction } from 'mobx';
import { EntitySelectionBuilder } from '../../../Selection/Builder/EntitySelectionBuilder';
import Selectbox from '../../../Selectbox/Selectbox';
import { FilterOptionProps } from './FilterOption';
import useLocalEntitySetting from '../../../../Setting/Api/useLocalEntitySetting';

export interface EntityFilterOptionProps extends FilterOptionProps
{

}

const EntityFilterOption: React.FC<EntityFilterOptionProps> =
    props =>
    {
        const selections =
            useComputed(
                () => [
                    new EntitySelectionBuilder(props.filterOption.fieldPath.path.entityType)
                        .selection
                ],
                [
                    props.filterOption
                ]);

        const [ , setSetting ] =
            useLocalEntitySetting(
                props.filterOption.getFilterOptionSettingId(props.filterOptionId)
            );

        const setValue =
            useCallback(
                (value: Entity) =>
                    runInAction(
                        () =>
                        {
                            props.filterOption.value = value;
                            setSetting(value);
                        }
                    ),

                [
                    props.filterOptionId,
                    props.filterOption,
                    setSetting
                ]);

        return <Selectbox
            value={props.filterOption.value as Entity}
            onChange={setValue}
            selections={selections}
            clearable
        />;
    };

export default observer(EntityFilterOption);
