import React from 'react';
import { observer } from 'mobx-react-lite';
import { ValueEditorProps } from '../../ValueEditor';
import DataSourceValueType from '../../../../../../../../../../@Api/Automation/Value/Type/DataSourceValueType';
import DataSourceValue from '../../../../../../../../../../@Api/Automation/Value/DataSourceValue';
import InitializedDataSourceEditor from './InitializedDataSourceEditor';

export interface DataSourceEditorProps extends ValueEditorProps<DataSourceValueType, DataSourceValue>
{

}

const DataSourceEditor: React.FC<DataSourceEditorProps> =
    props =>
    {
        const { type } = props;

        if (type.dataSource)
        {
            return <InitializedDataSourceEditor
                {...props}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(DataSourceEditor);
