import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../../../../../../@Future/Component/Generic/Card/Card';
import FunctionContext from '../../../../../../../../../../@Api/Automation/Function/FunctionContext';
import CardInset from '../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import CompositeAction from '../../../../../../../../../../@Api/Automation/Function/Action/CompositeAction';
import { runInAction } from 'mobx';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationEditor from '../../../Computation/ComputationEditor';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';

export interface CompositeActionReturnValueEditorProps
{
    context: FunctionContext;
    action: CompositeAction;
}

const CompositeActionReturnValueEditor: React.FC<CompositeActionReturnValueEditorProps> =
    props =>
    {
        const setReturnValue =
            useCallback(
                (computation: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.action.returnValue = computation
                    ),
                [props.action]
            );

        return <Card>
            <CardInset>
                <Input
                    label={
                        <LocalizedText
                            code="Generic.Result"
                            value="Resultaat"
                        />
                    }
                    labelPosition="left"
                >
                    <ComputationEditor
                        context={props.context}
                        value={props.action.returnValue}
                        onChange={setReturnValue}
                    />
                </Input>
            </CardInset>
        </Card>;
    };

export default observer(CompositeActionReturnValueEditor);
