import TransactionalConfiguration from '../Shared/TransactionalConfiguration';
import TransactionalContext from '../Shared/TransactionalContext';
import TransactionalModelImpl from './TransactionalModelImpl';
import TransactionalModelAdministration, { Disposer, Interceptor, Observer } from './TransactionalModelAdministration';
import TransactionalCommitAdministration from '../Shared/TransactionalCommitAdministration';
import { isObservableObject } from 'mobx';
import { resolveTransactionalModel } from './TransactionalModelPrototype';

export const administrationSymbol = Symbol('transactional administration');

export type TransactionalModel<T> = TransactionalModelImpl<T> & T;

/**
 * Creates a (nestd) transactional model.
 *
 * @param {T} model
 * @param {TransactionalConfiguration} configuration
 * @param {TransactionalContext<T>} context
 * @returns {TransactionalModel<T>}
 */
export function createTransactionalModel<T>(model: T,
                                            configuration: TransactionalConfiguration = new TransactionalConfiguration(),
                                            context: TransactionalContext<T> = new TransactionalContext<T>(model, new Map(), [], [])): TransactionalModel<T>
{
    if (!isObservableObject(model))
    {
        return model;
    }

    if (context.hasSeen(model))
    {
        return context.getSeenTransactional(model);
    }

    if (isTransactionalModel(model))
    {
        return model;
    }

    const transactionalModel = resolveTransactionalModel(model);

    if (transactionalModel)
    {
        context.seen(
            model,
            transactionalModel);

        (transactionalModel as any)[administrationSymbol] =
            new TransactionalModelAdministration<T>(
                model,
                transactionalModel,
                configuration,
                context);

        return transactionalModel as any;
    }
    else
    {
        return model;
    }
}

export function destroyTransactionalModel<T>(model: TransactionalModel<T>)
{
    if (isTransactionalModel(model))
    {
        getAdministration(model).deinitialize();
    }
}

/**
 * Determines whether the element is a transactional model.
 *
 * @param {T} element
 * @returns {boolean}
 */
export function isTransactionalModel<T>(element: T): boolean
{
    if (element === null || element === undefined)
    {
        return false;
    } else
    {
        return getAdministration(element) !== undefined;
    }
}

/**
 * In case of a transactional model, it gets the original model, otherwise it returns the
 * supplied element.
 *
 * @param {TransactionalModel<T> | T} element
 * @returns {T}
 */
export function getModel<T>(element: T | TransactionalModel<T>): T
{
    if (isTransactionalModel(element))
    {
        return getAdministration(element as TransactionalModel<T>)
            .model;
    } else
    {
        return element;
    }
}

/**
 * Gets the administration of the transactional model.
 *
 * @param {TransactionalModel<T>} model
 * @returns {TransactionalModelAdministration<T>}
 */
export function getAdministration<T>(model: TransactionalModel<T>): TransactionalModelAdministration<T>
{
    if (model === undefined)
    {
        return undefined as any;
    } else
    {
        return (model as any)[administrationSymbol];
    }
}

/**
 * Determines whether the model is dirty.
 *
 * @param {TransactionalModel<T>} model
 * @param {TransactionalCommitAdministration} checkAdministration
 * @returns {boolean}
 */
export function isDirty<T>(model: TransactionalModel<T>,
                           checkAdministration: TransactionalCommitAdministration
                               = new TransactionalCommitAdministration()): boolean
{
    return getAdministration(model)
        .isDirty(checkAdministration);
}

/**
 * Determines whether a property on the model is dirty.
 *
 * @param {TransactionalModel<T>} model
 * @param property
 * @returns {boolean}
 */
export function isPropertyDirty<T>(model: TransactionalModel<T>,
                                   property: keyof T): boolean
{
    return getAdministration(model)
        .isPropertyDirty(property as string);
}

/**
 * Resolves a descriptor containing the reason why a model is dirty.
 *
 * @param {TransactionalModel<T>} model
 * @param {TransactionalCommitAdministration} checkAdministration
 * @returns {any}
 */
export function whyDirty<T>(model: TransactionalModel<T>,
                            checkAdministration: TransactionalCommitAdministration
                                = new TransactionalCommitAdministration()): any
{
    return getAdministration(model)
        .whyDirty(checkAdministration);
}

/**
 * Determines whether the model is locally dirty. So this does excludes dirtyness of
 * related entities.
 *
 * @param {TransactionalModel<T>} model
 * @param {TransactionalCommitAdministration} checkAdministration
 * @returns {boolean}
 */
export function isLocallyDirty<T>(model: TransactionalModel<T>,
                                  checkAdministration: TransactionalCommitAdministration
                                      = new TransactionalCommitAdministration()): boolean
{
    return getAdministration(model).isLocallyDirty;
}

/**
 * Ensures that any changes made to the transactional model are observed.
 *
 * @param {TransactionalModel<T>} model
 */
export function trackTransactionalChanges<T>(model: TransactionalModel<T>)
{
    getAdministration(model).context.trackTransactionalChanges();
}

/**
 * Allows the modification of the original model without changes made to the
 * transactional (as a result) are tracked.
 *
 * @param {TransactionalModel<T>} model
 */
export function untrackTransactionalChanges<T>(model: TransactionalModel<T>)
{
    getAdministration(model).context.untrackTransactionalChanges();
}

export function commit<T>(model: TransactionalModel<T>,
                          commitAdministration: TransactionalCommitAdministration
                              = new TransactionalCommitAdministration()): Promise<TransactionalModel<T>>
{
    const administration = getAdministration(model);
    administration.context.startCommit(commitAdministration);

    return administration.commit(commitAdministration)
        .then(() =>
        {
            administration.context.stopCommit();

            return Promise.resolve(model);
        });
}

export function rollback<T>(model: TransactionalModel<T>,
                            rollbackAdministration: TransactionalCommitAdministration
                                = new TransactionalCommitAdministration())
{
    const administration = getAdministration(model);

    administration.context.startRollback(rollbackAdministration);
    administration.rollback(rollbackAdministration);
    administration.context.stopRollback();
}

export function observe<T, D>(model: TransactionalModel<T>,
                                 property: keyof T,
                                 observer: Observer<D>): Disposer
{
    return getAdministration(model)
        .observe(
            property as any,
            observer as any);
}

export function intercept<T, D>(
    model: TransactionalModel<T>,
    property: keyof T,
    interceptor: Interceptor<D>): Disposer
{
    return getAdministration(model)
        .intercept(
            property as any,
            interceptor as any);
}
