import { EntityType } from '../../../../Model/Implementation/EntityType';
import getMetadataSettingValue from '../../../../Metadata/getMetadataSettingValue';
import { Setting } from '../../../../Settings/Setting';
import localizeText from '../../../../Localization/localizeText';

export default function getDefaultPipelineName(entityType: EntityType)
{
    return getMetadataSettingValue<string>(entityType, Setting.Metadata.DefaultPipelineName)
        || localizeText(
            'Generic.Default',
            'Standaard'
        );
}
