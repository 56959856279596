import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import getTypes from '../../Type/Api/getTypes';
import { CommitBuilder } from '../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export function saveReadEventForEntity(entity: Entity,
                                       employee: Entity)
{
    const types = getTypes();

    return new CommitBuilder()
        .createEntity(
            types.Event.Read.Type,
            readEvent =>
                readEvent
                    .relateTo(
                        true,
                        types.Entity.RelationshipDefinition.Events,
                        entity
                    )
                    .relateTo(
                        false,
                        types.Event.RelationshipDefinition.User,
                        employee
                    )
        )
        .commit({
            isBulkMode: true
        });
}
