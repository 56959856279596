import * as React from 'react';
import { DataObjectBespokeEditor } from '../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { FormHandlerProperties } from '../../../../Generic/Form/FormHandlerProperties';
import { FormEvent } from '../../../../Generic/Form/FormEvent';
import { FormControlLabel } from '@material-ui/core';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { observer } from 'mobx-react';
import Switch from '../../../../../@Future/Component/Generic/Input/Switch/Switch';

@observer
export class BooleanEditor extends DataObjectBespokeEditor
{
    renderComponent(store: DataObjectEditorStore)
    {
        let handlers = new FormHandlerProperties(store.handlerContext, store.dataObject);

        handlers.wrap(
            FormEvent.Change,
            (event, value) =>
            {
                store.dataObject.setValue(value);
            });

        if (store.hasLabel)
        {
            return <FormControlLabel
                label={store.label || ''}
                control={
                    <Switch
                        checked={store.dataObject.getValue()}
                        disabled={store.isDisabled}
                        onToggle={() => {}}
                        baseProps={handlers as any}
                    />
                }
            />;
        }
        else
        {
            return <Switch
                checked={store.dataObject.getValue()}
                disabled={store.isDisabled}
                onToggle={() => {}}
                baseProps={handlers as any}
            />;
        }
    }
}
