import Layout from '../Layout';
import Parameter from '../../Automation/Parameter/Parameter';
import EntityValueType from '../../Automation/Value/Type/EntityValueType';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';

export function getInitializationPathsForParameterFromLayout(
    layout: Layout,
    parameter: Parameter<EntityValueType>
): EntityPath[]
{
    return layout.getDependencies()
        .filter(
            dependency =>
                dependency.parameter === parameter
                && dependency.fieldPath !== undefined
        )
        .map(
            dependency =>
                dependency.fieldPath.path
        );
}
