import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import search from '../../../../../../../../@Util/String/search';
import Selectbox from '../../../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import getTypes from '../../../../../../Entity/Type/Api/getTypes';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { createStringComparator } from '../../../../../../../Generic/List/V2/Comparator/StringComparator';
import { EntitySelectionBuilder } from '../../../../../../Entity/Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../../../../Entity/Path/@Model/EntityPath';
import { useAutomationsParentEntities } from '../../Api/useAutomationsParentEntities';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { lightTextColor } from '../../../../../../../../@Resource/Theme/Theme';


const useStyles = makeStyles({
    header: {
        verticalAlign: 'top !important',
    },
    selectBoxItem: {
        fontSize: '14px !important',
        padding: '3px'
    },
    selectBoxItemExtraInfo: {
        fontSize: 'small',
        color: lightTextColor
    },
});

export interface AutomationsTableHeadProps
{
    filter: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void;
    showEntityType?: boolean;
    filteredTypeEntity?: Entity;
    onChangeFilteredTypeEntity: (entity?: Entity) => void;
}

export const AutomationsTableHead: React.FC<AutomationsTableHeadProps> =
    observer(
        ({
             filter,
             showEntityType,
             filteredTypeEntity,
             onChangeFilteredTypeEntity,
         }) =>
        {
            const types = getTypes();
            const classes = useStyles();
            const entities = useAutomationsParentEntities(filter);
            const loadEntities =
                useCallback(
                    (query: string) =>
                        Promise.resolve(
                            entities
                                .sort(createStringComparator(e => e.getName()))
                                .filter(
                                    entity =>
                                        search(query, entity.getName())
                                )
                        ),
                    [
                        entities
                    ]
                );
            const formatEntityOption =
                (entity: Entity) =>
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                        className={classes.selectBoxItem}
                    >
                        <ViewGroupItem>
                            {entity.getName()}
                        </ViewGroupItem>
                        <ViewGroupItem
                            className={classes.selectBoxItemExtraInfo}
                        >
                            {entity.entityType.nameSingular}
                        </ViewGroupItem>
                    </ViewGroup>;
            const formatEntityValue =
                (entity: Entity) =>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={0}
                        className={classes.selectBoxItem}
                    >
                        <ViewGroupItem>
                            {entity.getName()}
                        </ViewGroupItem>
                    </ViewGroup>;

            return <TableHead>
                <TableRow>
                    <TableCell
                        {...{
                            width: '3%'
                        }}
                    >
                        {/*Icon*/}
                    </TableCell>
                    {
                        showEntityType &&
                        <TableCell
                            className={classes.header}
                            {...{
                                width: '7%'
                            }}
                        >
                            <ViewGroup
                                orientation="vertical"
                                spacing={0}
                                >
                                <ViewGroupItem>
                                    {types.Entity.Type.getName()}
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <Input
                                        label={null}
                                        labelPosition="top"
                                        labelFullWidth
                                    >
                                        <Selectbox
                                            load={loadEntities}
                                            idResolver={entity => entity.id}
                                            onChange={onChangeFilteredTypeEntity}
                                            value={filteredTypeEntity}
                                            formatOption={formatEntityOption}
                                            formatValue={formatEntityValue}
                                            disableUnderline
                                            clearable
                                        />
                                    </Input>
                                </ViewGroupItem>
                            </ViewGroup>
                        </TableCell>
                    }
                    <TableCell
                        className={classes.header}
                        {...{
                            width: showEntityType ? '43%' : '50%'
                        }}
                    >
                        <LocalizedText
                            code="Name"
                            value="Naam"
                        />
                    </TableCell>
                    <TableCell
                        {...{
                            width: '2%'
                        }}
                    >
                        {/*Deactivation Icon*/}
                    </TableCell>
                    <TableCell
                        {...{
                            width: '4%'
                        }}
                    >
                        {/*Switch active-inactive*/}
                    </TableCell>
                </TableRow>
            </TableHead>;
        }
    );
