import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import { runInAction } from 'mobx';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import LayoutEventTrigger from '../../../../../@Api/Layout/Event/LayoutEventTrigger';
import LayoutActionEditor from '../../Action/LayoutActionEditor';
import LayoutAction from '../../../../../@Api/Layout/Action/LayoutAction';
import { LayoutEventType } from '../../../../../@Api/Layout/Event/LayoutEventType';
import StaticSelectbox from '../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';

export interface LayoutEventTriggerEditorProps
{
    trigger: LayoutEventTrigger;
    onDelete: (trigger: LayoutEventTrigger) => void;
    context: FunctionContext;
}

const LayoutEventTriggerEditor: React.FC<LayoutEventTriggerEditorProps> =
    props =>
    {
        const { trigger, onDelete, context } = props;

        const setEventType =
            useCallback(
                (value: LayoutEventType) =>
                    runInAction(
                        () =>
                            trigger.eventType = value),
                [
                    trigger
                ]);
        const eventTypeOptions =
            useMemo(
                () =>
                    [ 'Click', 'Blur', 'Change' ].map(
                        eventType => ({
                            id: eventType,
                            value: eventType,
                            label: eventType
                        })),
                []);

        const setAction =
            useCallback(
                (action: LayoutAction) =>
                    runInAction(
                        () =>
                            trigger.action = action),
                [
                    trigger
                ]);

        const deleteTrigger =
            useCallback(
                () =>
                    onDelete(trigger),
                [
                    onDelete,
                    trigger
                ]);

        return <CardInset
            vertical={false}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={15}
            >
                <ViewGroupItem
                    ratio={1}
                >
                    <InputGroup>
                        <Input
                            labelPosition="left"
                            label={
                                <LocalizedText
                                    code="Generic.Type"
                                    value="Type"
                                />
                            }
                        >
                            <StaticSelectbox
                                value={trigger.eventType}
                                onChange={setEventType}
                                options={eventTypeOptions}
                            />
                        </Input>
                        <Input
                            labelPosition="top"
                            label={
                                <LocalizedText
                                    code="Generic.Action"
                                    value="Actie"
                                />
                            }
                        >
                            <LayoutActionEditor
                                action={trigger.action}
                                onChange={setAction}
                                context={context}
                            />
                        </Input>
                    </InputGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <MenuButton>
                        <Menu>
                            <Item
                                name={
                                    <LocalizedText
                                        code="Generic.Delete"
                                    />
                                }
                                onClick={deleteTrigger}
                            />
                        </Menu>
                    </MenuButton>
                </ViewGroupItem>
            </ViewGroup>

        </CardInset>;
    };

export default observer(LayoutEventTriggerEditor);
