import { useComputed } from 'mobx-react-lite';
import { default as ResourcePlannerModel } from '../Model/ResourcePlanner';
import ResourceSelection from '../Model/ResourceSelection';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { Comparator } from '../../../DataObject/Model/Comparator';
import useTypes from '../../Type/Api/useTypes';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../DataObject/Model/DataObject';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import { useContext } from 'react';
import CurrentUserContext from '../../../User/CurrentUserContext';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';

export default function useProjectTimesheet(onlyMyProjects: boolean = false, onlyMyHours: boolean = false)
{
    const types = useTypes();
    const currentUserStore = useContext(CurrentUserContext);

    return useComputed(
        () =>
        {
            const projectParameter =
                new Parameter(
                    'ProjectResource',
                    new EntityValueType(types.Activity.Project.Type),
                    true,
                    types.Activity.Project.Type.getName());

            const milestoneParameter =
                new Parameter(
                    'MilestoneResource',
                    new EntityValueType(types.Milestone.Type),
                    true,
                    types.Milestone.Type.getName());

            const timeRegistrationParameter =
                types.TimeRegistration.Type &&
                    new Parameter(
                        'TimeRegistration',
                        new EntityValueType(types.TimeRegistration.Type),
                        true,
                        types.TimeRegistration.Type.getName());

            const employeeParameter =
                new Parameter(
                    'Employee',
                    new EntityValueType(types.Relationship.Person.Contact.Employee.Type),
                    false,
                    types.Relationship.Person.Contact.Employee.Type.getName());

            const compareEmployeeWith =
                onlyMyProjects
                    ? new EntityValue(currentUserStore.employeeEntity)
                    : employeeParameter;

            return new ResourcePlannerModel(
                projectParameter,
                new ParameterDictionary(
                    onlyMyProjects
                        ? []
                        : [employeeParameter]
                ),
                new CompositePredicate(
                    LogicalOperator.And,
                    [
                        new ComparisonPredicate(
                            // Open projects
                            new ValueFromEntityComputation(
                                projectParameter,
                                EntityPath.fromEntityType(types.Activity.Project.Type)
                                    .joinTo(
                                        types.Activity.Project.RelationshipDefinition.Phase,
                                        false)
                                    .field(types.Datastore.Phase.Field.IsTerminal)),
                            Comparator.Equals,
                            new PrimitiveValue(
                                DataObject.constructFromTypeIdAndValue(
                                    'Boolean',
                                    false))),
                        ...onlyMyProjects
                            ?
                                [
                                    new CompositePredicate(
                                        LogicalOperator.Or,
                                        [
                                            // ProjectOwner is CurrentUser
                                            new ComparisonPredicate(
                                                new ValueFromEntityComputation(
                                                    projectParameter,
                                                    EntityPath.fromEntityType(types.Activity.Project.Type)
                                                        .joinTo(
                                                            types.Activity.Project.RelationshipDefinition.Owner,
                                                            false)
                                                        .field()),
                                                Comparator.Equals,
                                                compareEmployeeWith),
                                            // CurrentUser is a project member
                                            new ComparisonPredicate(
                                                new ValueFromEntityComputation(
                                                    projectParameter,
                                                    EntityPath.fromEntityType(types.Activity.Project.Type)
                                                        .joinTo(
                                                            types.Activity.Project.RelationshipDefinition.Members,
                                                            false)
                                                        .joinTo(
                                                            types.ProjectMember.RelationshipDefinition.Employee,
                                                            false)
                                                        .field()),
                                                Comparator.Equals,
                                                compareEmployeeWith)
                                        ])
                                ]
                            :
                                []
                    ]),
                [
                    timeRegistrationParameter &&
                        new ResourceSelection(
                            timeRegistrationParameter,
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.TimeRegistrations,
                                    true),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .field(types.TimeRegistration.Field.StartDate),
                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                .field(types.TimeRegistration.Field.EndDate),
                            onlyMyProjects && !onlyMyHours
                                ? undefined
                                : new ComparisonPredicate(
                                        new ValueFromEntityComputation(
                                            timeRegistrationParameter,
                                            EntityPath.fromEntityType(types.TimeRegistration.Type)
                                                .joinTo(
                                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                                    true)
                                                .field()),
                                        Comparator.Equals,
                                        onlyMyHours
                                                ? compareEmployeeWith
                                                : employeeParameter),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            [
                                EntityPath.fromEntityType(types.TimeRegistration.Type)
                                    .joinTo(
                                        types.TimeRegistration.RelationshipDefinition.Activity,
                                        false)
                                    .field(),
                                EntityPath.fromEntityType(types.TimeRegistration.Type)
                                    .joinTo(
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                        true)
                                    .field()
                            ],
                        )
                ].filter(selection => selection),
                [
                    new ResourcePlannerModel(
                        milestoneParameter,
                        new ParameterDictionary([]),
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                milestoneParameter,
                                EntityPath.fromEntityType(types.Milestone.Type)
                                    .joinTo(
                                        types.Activity.Project.RelationshipDefinition.Milestones,
                                        true)
                                    .field()),
                            Comparator.Equals,
                            projectParameter),
                        [
                            types.TimeRegistration.Type &&
                                new ResourceSelection(
                                    timeRegistrationParameter,
                                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                                        .joinTo(
                                            types.Milestone.RelationshipDefinition.TimeRegistrations,
                                            true),
                                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                                        .field(types.TimeRegistration.Field.StartDate),
                                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                                        .field(types.TimeRegistration.Field.EndDate),
                                    new CompositePredicate(
                                        LogicalOperator.And,
                                        [
                                            new ComparisonPredicate(
                                                new ValueFromEntityComputation(
                                                    timeRegistrationParameter,
                                                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                                                        .joinTo(
                                                            types.Activity.RelationshipDefinition.TimeRegistrations,
                                                            true)
                                                        .field()
                                                ),
                                                Comparator.Equals,
                                                projectParameter
                                            ),
                                            new ComparisonPredicate(
                                                new ValueFromEntityComputation(
                                                    timeRegistrationParameter,
                                                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                                                        .joinTo(
                                                            types.Milestone.RelationshipDefinition.TimeRegistrations,
                                                            true)
                                                        .field()),
                                                Comparator.Equals,
                                                milestoneParameter
                                            ),
                                            new ComparisonPredicate(
                                                new ValueFromEntityComputation(
                                                    timeRegistrationParameter,
                                                    EntityPath.fromEntityType(types.TimeRegistration.Type)
                                                        .joinTo(
                                                            types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                                            true)
                                                        .field()),
                                                Comparator.Equals,
                                                onlyMyHours
                                                    ? compareEmployeeWith
                                                    : employeeParameter
                                            )
                                        ]),
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    [
                                        EntityPath.fromEntityType(types.TimeRegistration.Type)
                                            .joinTo(
                                                types.TimeRegistration.RelationshipDefinition.Activity,
                                                false)
                                            .field(),
                                        EntityPath.fromEntityType(types.TimeRegistration.Type)
                                            .joinTo(
                                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                                                true)
                                            .field()
                                    ])
                        ].filter(selection => selection)
                    )
                ],
                true);
        },
        [
            types,
            currentUserStore
        ]);
}
