import { SettingSource } from '../SettingStore';
import { useCallback, useContext } from 'react';
import SettingContext from '../SettingContext';
import { useComputed } from 'mobx-react-lite';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import showErrorMessage from '../../../../@Api/Error/showErrorMessage';
import localizeText from '../../../../@Api/Localization/localizeText';
import setSetting from './setSetting';

export default function useSetting<T>(type: SettingSource,
                                      code: string,
                                      showSaveNotification: boolean = false,
                                      logChanges: boolean = false): [ T, (value?: T) => Promise<any> ]
{
    const settingStore = useContext(SettingContext);

    const settingValue =
        useComputed(
            () =>
                settingStore?.getValue(type, code),
            [
                settingStore,
                settingStore.settingByCodeByType,
                type,
                code,
            ]);

    const setValue =
        useCallback(
            (value?: any) =>
                setSetting(type, code, value, logChanges)
                    .then(
                        isChanged =>
                        {
                            if (isChanged && showSaveNotification)
                            {
                                loadModuleDirectly(FeedbackStore).enqueueSnackbar(
                                    localizeText(
                                        'Generic.ResourceSaved',
                                        '${resource} opgeslagen',
                                        {
                                            resource: localizeText('Generic.Setting', 'Instelling')
                                        }),
                                    {
                                        variant: 'success'
                                    });
                            }
                        })
                    .catch(
                        error =>
                        {
                            showErrorMessage(error);
                        }),
            [
                type,
                code,
                showSaveNotification
            ]);

    return [
        settingValue,
        setValue
    ];
}
