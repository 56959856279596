import React, { useEffect } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import { ComputationEditorProps } from '../../ComputationEditor';
import Link from '../../../../../../../../../../@Future/Component/Generic/Link/Link';
import QueryComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/QueryComputation';
import useDialog from '../../../../../../../../../../@Service/Navigation/Page/Hooks/useDialog';
import QueryComputationDialog from './QueryComputationDialog';
import ListQuery from '../../../../../../../../../../@Api/Automation/Query/ListQuery';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface QueryComputationEditorProps extends ComputationEditorProps<ValueType<any>, QueryComputation>
{

}

const QueryComputationEditor: React.FC<QueryComputationEditorProps> =
    props =>
    {
        const [ openDialog ] =
            useDialog(
                close =>
                    <QueryComputationDialog
                        {...props}
                        onClose={close}
                    />,
                [
                    props
                ]);

        const isSingular =
            useComputed(
                () =>
                    props.value.query
                    && props.value.query instanceof ListQuery
                    && props.value.query.limit <= 1,
                [
                    props.value
                ]);

        useEffect(
            () =>
            {
                if (!props.value.query)
                {
                    openDialog();
                }
            },
            [
                props.value.query,
                openDialog
            ]);

        return <span>
            <LocalizedText
                code="Query"
                value="Query"
            />
            :&nbsp;
            <Link
                onClick={openDialog}
                highlighted
            >
                {props.value.query?.entityType.getName(!isSingular) || '...'}
            </Link>
        </span>;
    };

export default observer(QueryComputationEditor);
