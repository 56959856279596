import AutomationDependencyContext from '../AutomationDependencyContext';
import ValueType from '../Value/Type/ValueType';
import Computation from '../Function/Computation/Computation';
import Value from '../Value/Value';
import Dependency from './Dependency';
import Validation from '../Validation/Validation';
import getValueTypeFromDescriptor from '../Api/getValueTypeFromDescriptor';
import { observable } from 'mobx';
import { ConstantComputation } from '../../../@Component/Domain/Computation/Type/Constant/ConstantComputation';
import { DataObject } from '../../../@Component/Domain/DataObject/Model/DataObject';
import FunctionContext from '../Function/FunctionContext';
import getComputationFromDescriptor from '../Api/getComputationFromDescriptor';

export default class Parameter<T extends ValueType<any>> extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref type: T;
    @observable isRequired: boolean;
    @observable name: string;
    @observable.ref defaultValue: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                type: T,
                isRequired: boolean,
                name: string,
                defaultValue?: Computation<any, any>)
    {
        super();

        this.id = id;
        this.type = type;
        this.isRequired = isRequired;
        this.name = name;
        this.defaultValue = defaultValue;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return this.type;
    }

    getName(): string
    {
        return this.name || '';
    }

    validate(): Validation[]
    {
        return [];
    }

    isAsync(): boolean
    {
        return false;
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        return this.synchronousApply(context);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        return context.parameterAssignment.getValue(this);
    }

    getDependencies(): Dependency[]
    {
        return [
            new Dependency(
                this,
                undefined),
            ...this.getDefaultValueDependencies()
        ];
    }

    getDefaultValueDependencies(): Dependency[]
    {
        if (this.defaultValue)
        {
            return this.defaultValue.getDependencies();
        }
        else
        {
            return [];
        }
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Parameter';
        descriptor.parameterId = this.id;
    }

    toOldComputation()
    {
        if (this.id === 'Me')
        {
            return new ConstantComputation(
                DataObject.constructFromTypeIdAndValue(
                    'Me',
                    undefined));
        }
        else
        {
            return undefined;
        }
    }

    cloneWithoutDefaultValue(): Parameter<T>
    {
        return new Parameter(
            this.id,
            this.type,
            this.isRequired,
            this.name);
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return dependencyContext.parameterDictionary
            .getParameterById(descriptor.parameterId);
    }

    toFullDescriptor()
    {
        return {
            id: this.id,
            type: this.type.toDescriptor(),
            isRequired: this.isRequired,
            name: this.name,
            defaultValue: this.defaultValue?.toDescriptor()
        };
    }

    static async fromFullDescriptor(descriptor: any,
                                    dependencyContext: AutomationDependencyContext)
    {
        return new Parameter(
            descriptor.id,
            await getValueTypeFromDescriptor(
                descriptor.type,
                dependencyContext),
            descriptor.isRequired,
            descriptor.name,
            descriptor.defaultValue
                ?
                    await getComputationFromDescriptor(
                        descriptor.defaultValue,
                        dependencyContext)
                :
                    undefined);
    }

    // ----------------------- Private logic ------------------------
}
