import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../../Entity/Type/Api/useTypes';
import useTransactionalEntity from '../../../../../../../../@Api/Entity/Bespoke/useTransactionalEntity';
import EntityTypeContext from '../../../../../../Entity/Type/EntityTypeContext';
import TriggerEditor from '../../../../../../Entity/Viewer/Content/Automation/Editor/Trigger/TriggerEditor';
import FunctionContext from '../../../../../../../../@Api/Automation/Function/FunctionContext';
import ParameterDictionary from '../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import MutationTrigger from '../../../../../../../../@Api/Automation/Trigger/MutationTrigger';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import getTriggerFromDescriptor from '../../../../../../../../@Api/Automation/Trigger/Api/getTriggerFromDescriptor';
import Trigger from '../../../../../../../../@Api/Automation/Trigger/Trigger';
import { useDelayedValue } from '../../../../../../../../@Future/Util/Hook/useDelayedValue';
import AutomationDependencyContext from '../../../../../../../../@Api/Automation/AutomationDependencyContext';

export interface WebhookTriggerEditorProps
{
    webhook: Entity;
}

const WebhookTriggerEditor: React.FC<WebhookTriggerEditorProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);
        const webhook = useTransactionalEntity(props.webhook);

        const entityType =
            useMemo(
                () =>
                {
                    const entityTypeEntity =
                        webhook.getRelatedEntityByDefinition(
                            true,
                            types.EntityType.RelationshipDefinition.Webhooks);

                    if (entityTypeEntity)
                    {
                        return entityTypeStore.getTypeByEntityId(entityTypeEntity.id);
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    webhook
                ]);

        const [ trigger, setTrigger ] = useState<MutationTrigger | undefined>(undefined);
        const dependencyContext =
            useMemo(
                () =>
                    new AutomationDependencyContext(
                        new ParameterDictionary([])
                    ),
                []);
        const [ loadedTrigger ] =
            useAsyncResult(
                async () =>
                {
                    const triggerDescriptor = webhook.getObjectValueByField(types.Webhook.Field.Trigger);

                    if (triggerDescriptor)
                    {
                        return await getTriggerFromDescriptor(
                            triggerDescriptor,
                            dependencyContext) as MutationTrigger;
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    webhook,
                    types,
                    dependencyContext,
                ]);
        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        dependencyContext.parameterDictionary,
                        new ParameterAssignment()
                    ),
                [
                    dependencyContext,
                ]);

        useEffect(
            () =>
                setTrigger(loadedTrigger),
            [
                setTrigger,
                loadedTrigger
            ]);

        const createTrigger =
            useCallback(
                (trigger: Trigger) =>
                {
                    if (trigger instanceof MutationTrigger)
                    {
                        setTrigger(trigger);
                    }
                },
                [
                    setTrigger
                ]);
        const deleteTrigger =
            useCallback(
                () =>
                {
                    setTrigger(undefined);

                    webhook.setValueByField(
                        types.Webhook.Field.Trigger,
                        undefined);

                    if (!webhook.isNew())
                    {
                        return webhook.checkAndDoCommit();
                    }
                },
                [
                    setTrigger
                ]);
        const isTriggerValid =
            useComputed(
                () =>
                    trigger
                        ?
                            trigger.validate().every(message => message.type !== 'Error')
                        :
                            false,
                [
                    trigger
                ]);
        const triggerDescriptor =
            useComputed(
                () =>
                {
                    if (isTriggerValid)
                    {
                        return trigger.toDescriptor();
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    isTriggerValid,
                    trigger
                ]);
        const delayedTriggerDescriptor = useDelayedValue(triggerDescriptor);
        useEffect(
            () =>
            {
                if (delayedTriggerDescriptor)
                {
                    webhook.setValueByField(
                        types.Webhook.Field.Trigger,
                        delayedTriggerDescriptor);

                    if (!webhook.isNew())
                    {
                        webhook.checkAndDoCommit();
                    }
                }
            },
            [
                webhook,
                types,
                delayedTriggerDescriptor
            ]);

        if (!webhook.isNew() && entityType)
        {
            return <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                <ViewGroupItem>
                    <TriggerEditor
                        context={context}
                        entityType={entityType}
                        trigger={trigger}
                        onCreate={createTrigger}
                        onDelete={deleteTrigger}
                    />
                </ViewGroupItem>
            </ViewGroup>;
        }
        else
        {
            return null;
        }
    };

export default observer(WebhookTriggerEditor);
