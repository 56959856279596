import { DataObjectBespokeEditorStore } from '../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { action, computed } from 'mobx';
import { SelectboxStore } from '../../../../Generic/SelectBox/V2/SelectboxStore';
import { ApiBaseType } from './ApiBaseType';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../../DataObjectStore';
import { SelectboxItemStore } from '../../../../Generic/SelectBox/V2/Item/SelectboxItemStore';
import { TextStore } from '../../../../Generic/Text/TextStore';
import { AvatarStore } from '../../../../Generic/Avatar/AvatarStore';
import { OptionGroup } from '../../../../Generic/SelectBox/V2/Group/OptionGroup';
import { DataObject } from '../../Model/DataObject';
import { FormEvent } from '../../../../Generic/Form/FormEvent';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';

export class ApiBaseEditorStore<T> extends DataObjectBespokeEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get type(): ApiBaseType<T>
    {
        return this.baseStore.dataObject.specification.type as ApiBaseType<T>;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get selectboxStore(): SelectboxStore<T>
    {
        return new SelectboxStore<T>(
        {
            isDisabled:
                () => this.baseStore.isDisabled,
            autoFocus:
                () => this.baseStore.isFocused,
            load:
                query =>
                    this.type.searchInstances(
                        query,
                        50,
                        this.baseStore.dataObject,
                        this.dataObjectStore.moduleManager)
                        .then(
                            values =>
                                Promise.resolve(
                                    [
                                        new OptionGroup(values)
                                    ])),
            construct:
                data =>
                    new SelectboxItemStore<T>(
                    {
                        data: data,
                        textStore:
                            new TextStore(
                            {
                                label:
                                    () =>
                                        this.type.getInstanceName(data)
                            }),
                        avatarStore:
                            () =>
                            {
                                const avatarUrl =
                                    this.type.getInstanceAvatarUrl(
                                        data,
                                        this.dataObjectStore.moduleManager);

                                if (avatarUrl)
                                {
                                    return new AvatarStore({
                                        src: avatarUrl
                                    });
                                }
                                else
                                {
                                    return undefined;
                                }
                            }
                    }),
            value: () => this.baseStore.dataObject.value,
            onSelect:
                data =>
                {
                    this.baseStore.dataObject.setValue(
                        data.length > 0
                            ?
                                data[0].data
                            :
                                undefined);

                    this.baseStore.handlerContext.perform(
                        new FormEvent<DataObject>(
                            FormEvent.Change,
                            this.baseStore.dataObject));
                },
            disableUnderline: () => !this.baseStore.hasUnderline,
        });
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    setValue(entityType: EntityType)
    {
        this.baseStore.dataObject.setValue(entityType);

        this.baseStore.handlerContext.perform(
            new FormEvent<DataObject>(
                FormEvent.Change,
                this.baseStore.dataObject));
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
