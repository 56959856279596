import React from 'react';
import { Header, HeaderProps } from '../Header';
import ViewGroup from '../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import TextEditor from '../../Input/TextEditor/TextEditor';
import localizeText from '../../../../../@Api/Localization/localizeText';
import CloseButton from '../../Button/Variant/CloseButton/CloseButton';
import useToggle from '../../../../../@Util/Toggle/useToggle';

export interface StringHeaderFilterProps extends HeaderProps
{
    filter: string;
    onChangeFilter: (filter: string) => void;
}

export const StringHeaderFilter: React.FC<StringHeaderFilterProps> =
    ({
         column,
         fieldPath,
         localizeResourceCode,
         localizeResourceDefaultValue,
         isDefaultSortColumn,
         sort,
         onSort,
         filter,
         onChangeFilter,
     }) =>
    {
        const [ isFilterOpen, toggleFilterOpen ] = useToggle(false);

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <Header
                    column={column}
                    fieldPath={fieldPath}
                    localizeResourceCode={localizeResourceCode}
                    localizeResourceDefaultValue={localizeResourceDefaultValue}
                    isDefaultSortColumn={isDefaultSortColumn}
                    sort={sort}
                    onSort={onSort}
                    hasFilter={filter !== undefined}
                    isFilterOpen={isFilterOpen}
                    onToggleFilter={toggleFilterOpen}
                />
            </ViewGroupItem>
            {
                isFilterOpen &&
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            <TextEditor
                                value={filter}
                                onChange={onChangeFilter}
                                placeholder={
                                    localizeText('Generic.Search', 'Search')
                                }
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <CloseButton
                                onClick={() => onChangeFilter(undefined)}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
            }
        </ViewGroup>
    }