import { EntityType } from '../../../../Model/Implementation/EntityType';
import getPhaseRelationshipDefinition from './getPhaseRelationshipDefinition';

export default function getPossiblePhaseRelationshipDefinitions(type: EntityType)
{
    return Array.from(
        new Set(
            type.getAllInstantiableTypes(undefined, true)
            .map(
                childType =>
                    getPhaseRelationshipDefinition(childType))
            .filter(
                relationshipDefinition =>
                    relationshipDefinition !== undefined)));
}
