import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { IconStore } from './IconStore';
import { Icon as MuiIcon, Theme } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

const styles =
    (theme: Theme) =>
        ({

        });

@observer
class Icon extends BaseComponent<IconStore>
{
    renderLoader(store: IconStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: IconStore)
    {
        if (store.icon)
        {
            return <MuiIcon
                style={{
                    fontSize: store.size,
                    color: store.color,
                    fontWeight: store.weight,
                    cursor:
                        store.props.onClick
                            ?
                                'pointer'
                            :
                                undefined,
                    ...store.isInline
                        ?
                            {}
                        :
                            {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            },
                    ...store.style
                }}
                onClick={store.click}
            >
                {store.icon}
            </MuiIcon>;
        }
        else
        {
            return null;
        }
    }
}

export default withStyles(styles)(Icon as any);
