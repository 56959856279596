import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { ChartStore } from '../ChartStore';
import { Theme, withStyles } from '@material-ui/core';
import { ResponsiveContainer, Tooltip } from 'recharts';
import Funnel from './Funnel';

const Recharts = require('recharts');

const styles =
    (theme: Theme) =>
        ({

        });

@observer
class FunnelChart extends BaseComponent<ChartStore>
{
    renderComponent(store: ChartStore): JSX.Element
    {
        if (true)
            return <Funnel
                store={store}
            />

        const chartData =
            store.data
                .map(
                    point =>
                        ({ name: point.name, value: point.value.value }));

        return <ResponsiveContainer
            width="100%"
        >
            <Recharts.FunnelChart
                data={chartData}
                margin={{
                    left: 25,
                    bottom: 30
                }}

            >
                <Recharts.Funnel
                    dataKey="value"
                    data={chartData}
                    fill={store.color}
                    isAnimationActive
                    onClick={
                        (data: any) =>
                        {
                            const idx =
                                chartData.findIndex(
                                    element =>
                                        element === data.payload.payload);

                            if (idx >= 0)
                            {
                                store.toggleIndexSelection(idx);
                            }
                        }}
                >
                    <Recharts.LabelList
                        position="right"
                        fill="#000"
                        stroke="none"
                        dataKey="name"
                    />
                </Recharts.Funnel>
                <Tooltip />
            </Recharts.FunnelChart>
        </ResponsiveContainer>;
    }
}

export default withStyles(styles)(FunnelChart as any);
