import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { ImageFinderStore } from './ImageFinderStore';
import { GridList, GridListTile, GridListTileBar } from '@material-ui/core';
import { SearchBar } from '../SearchBar/SearchBar';

@observer
export class ImageFinder extends BaseComponent<ImageFinderStore>
{
    renderComponent(store: ImageFinderStore)
    {
        return <div>
            <SearchBar store={store.searchbarStore} />
            {/*<Input store={store.searchInputStore} />*/}
            <div
                style={{
                    maxHeight: 350,
                    overflowY: 'scroll',
                    overflowX: 'hidden'
                }}
            >
                <GridList
                    cellHeight={store.cellHeight}
                    cols={store.columns}
                    spacing={8}
                    style={{
                        maxWidth: '100%',
                    }}
                >
                    {
                        store.entityPictureReferences.map(reference =>
                            (
                                <GridListTile key={reference.url}>
                                    <img
                                        src={reference.getProxyUrl(store.apiClient)}
                                        // src={reference.url}
                                        alt=""
                                        style={{
                                            cursor: 'pointer',
                                            maxWidth: '100%',
                                            objectFit: 'cover',
                                            top: '0%',
                                            left: '50%',
                                            height: '100%',
                                            position: 'relative',
                                            transform: 'translateX(-50%)'
                                        }}
                                        onClick={() => store.setSelectedFile(reference)}
                                    />
                                    <GridListTileBar
                                        title={reference.description}
                                    />
                                </GridListTile>
                            ))
                    }
                </GridList>
            </div>
        </div>;
    }
}
