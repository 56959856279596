import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../../../Viewer/LayoutViewer';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import queryPortalDataSource from '../../../../../../../@Api/Portal/DataSource/Api/queryPortalDataSource';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import PortalDataSourceListQuery from '../../../../../../../@Api/Portal/DataSource/PortalDataSourceListQuery';
import OpenPortalQueryContext from '../../../../../../../@Api/Portal/Context/OpenPortalQueryContext';
import { runInAction } from 'mobx';
import PortalDataSourceQueryResultSet from '../../../../../../../@Api/Portal/DataSource/PortalDataSourceQueryResultSet';
import DataSourceValue from '../../../../../../../@Api/Automation/Value/DataSourceValue';
import PortalDataSourceValue from '../../../../../../../@Api/Portal/DataSource/PortalDataSourceValue';
import useMemoizedMap from '../../../../../../../@Util/MapUtils/useMemoizedMap';
import uuid from '../../../../../../../@Util/Id/uuid';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import PortalTableLayout from '../../../../../../../@Api/Layout/Type/PortalTable/PortalTableLayout';
import { TableCell, TableRow } from '@material-ui/core';
import { PortalTableRow } from './Row/PortalTableRow';
import HoverCardBottom from '../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';

export interface PortalTableLayoutViewerForQueryProps extends LayoutViewerProps<PortalTableLayout>
{
    rowParameterDictionary: ParameterDictionary;
    query: PortalDataSourceListQuery;
    hideEmptyLayout?: boolean;
}

export const PortalTableLayoutViewerForQuery: React.FC<PortalTableLayoutViewerForQueryProps> =
    observer(
        props =>
        {
            const rowParameterDictionary = props.rowParameterDictionary;
            const parameterAssignment = props.parameterAssignment;
            const query = props.query;
            const [ _resultSet ] =
                useAsyncResult(
                    () =>
                        query &&
                            queryPortalDataSource(query),
                    [
                        query,
                        query?.state
                    ]
                );
            const [ resultSet, setResultSet ] = useState<PortalDataSourceQueryResultSet>(_resultSet);

            useEffect(
                () =>
                    _resultSet &&
                        setResultSet(_resultSet),
                [
                    _resultSet,
                    setResultSet
                ]
            );

            // Track open queries
            const openQueries = useContext(OpenPortalQueryContext);

            useEffect(
                () =>
                {
                    if (query)
                    {
                        runInAction(
                            () =>
                                openQueries.push(query)
                        );

                        return () =>
                            runInAction(
                                () =>
                                {
                                    openQueries.remove(query);
                                });
                    }
                },
                [
                    openQueries,
                    query
                ]
            );
            const memoizedParameterAssignment =
                useMemoizedMap(
                    () =>
                        resultSet &&
                        new Map(
                            resultSet.results.map(
                                result => [
                                    result.id,
                                    parameterAssignment
                                        .getNewAssignment(result.parameterAssignment)
                                        .getNewAssignmentWithParameter(
                                            props.layout.rowParameter,
                                            new DataSourceValue(
                                                new PortalDataSourceValue(
                                                    result.id,
                                                    props.layout.dataSourceSignature.id,
                                                    query.parameterAssignment,
                                                    result.parameterAssignment
                                                )
                                            )
                                        )
                                ]
                            )
                        ),
                    (a, b) => a.equals(b),
                    [
                        resultSet,
                        parameterAssignment,
                        query,
                        props.layout
                    ]
                );
            const hasMore =
                useComputed(
                    () =>
                        resultSet !== undefined
                        && resultSet.results.length >= query.limit,
                    [
                        resultSet,
                    ]
                );
            const [ moreQuery, setMoreQuery ] = useState<PortalDataSourceListQuery | undefined>(undefined);
            const loadMore =
                useCallback(
                    () =>
                        setMoreQuery(
                            new PortalDataSourceListQuery(
                                uuid(),
                                query.dataSourceSignature,
                                query.parameterAssignment,
                                query.filter,
                                query.orderings,
                                query.offset + query.limit,
                                query.limit
                            )
                        ),
                    [
                        query,
                    ]
                );

            if (memoizedParameterAssignment === undefined)
            {
                return <CardInset>
                    <Centered
                        horizontal
                    >
                        <Loader />
                    </Centered>
                </CardInset>;
            }
            else
            {
                if (resultSet.results.length === 0)
                {
                    if (props.hideEmptyLayout)
                    {
                        return null;
                    }
                    else
                    {
                        return <TableRow>
                            <TableCell
                                colSpan={props.layout.columns.length}
                                align="center"
                            >
                                <LocalizedText
                                    code="Generic.NoResultsFound"
                                    value="Er zijn geen resultaten gevonden."
                                />
                            </TableCell>
                        </TableRow>;
                    }
                }
                else
                {
                    return <>
                        {
                            resultSet.results
                                .filter(
                                    result =>
                                        memoizedParameterAssignment.has(result.id)
                                )
                                .map(
                                    result =>
                                        <PortalTableRow
                                            key={result.id}
                                            {...props}
                                            parameterDictionary={rowParameterDictionary}
                                            parameterAssignment={memoizedParameterAssignment.get(result.id)}
                                        />
                                )
                        }
                        {
                            hasMore &&
                            moreQuery === undefined &&
                            <TableRow>
                                <TableCell
                                    colSpan={props.layout.columns.length}
                                    padding="none"
                                >
                                    <HoverCardBottom
                                        onClick={loadMore}
                                    >
                                        <LocalizedText
                                            code="Generic.LoadMore"
                                            value="Meer laden"
                                        />...
                                    </HoverCardBottom>
                                </TableCell>
                            </TableRow>
                        }
                        {
                            moreQuery !== undefined &&
                            <PortalTableLayoutViewerForQuery
                                {...props}
                                query={moreQuery}
                                hideEmptyLayout
                            />
                        }
                    </>;
                }
            }
        }
    );
