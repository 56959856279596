import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { default as GenericDrawer, DrawerProps as GenericDrawerProps } from '../../../../../@Future/Component/Generic/Drawer/Drawer';
import Timeline from '../Timeline';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';

export interface DrawerProps extends GenericDrawerProps
{
    entity: Entity;
}

const Drawer: React.FC<DrawerProps> =
    props =>
    {
        const { entity, ...drawerProps } = props;

        return <GenericDrawer
            title="Audit trail"
            width={800}
            {...drawerProps}
        >
            <CardInset>
                <Timeline
                    entity={props.entity}
                />
            </CardInset>
        </GenericDrawer>;
    };

export default observer(Drawer);
