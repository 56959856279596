import React, { useCallback, useContext, useMemo } from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../Type/Api/useTypes';
import performAction from '../../../../../../../../../@Api/Entity/performAction';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import getDatastoreByCode from '../../../../../../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import { createTransactionalModel } from '../../../../../../../../../@Util/TransactionalModelV2/index';
import InputGroup from '../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import { default as EntityInput } from '../../../../../../Input/Input';
import RightAlignedButtonGroup from '../../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import PrimaryButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import useAsyncResult from '../../../../../../../../../@Util/Async/useAsyncResult';
import LocalizerContext from '../../../../../../../../../@Service/Localization/LocalizerContext';
import useEntityValue from '../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import StaticSelectbox, { StaticOption } from '../../../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import localizeText from '../../../../../../../../../@Api/Localization/localizeText';
import Input from '../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { runInAction } from 'mobx';

export interface SubscriptionActivationEditorProps
{
    entity: Entity;
}

export type FirstPeriodInvoicingOptionValue = 'Proportionally' | 'None';

const SubscriptionActivationEditor: React.FC<SubscriptionActivationEditorProps> =
    props =>
    {
        const types = useTypes();
        const localizer = useContext(LocalizerContext);

        const setPhase =
            useCallback(
                async (phaseCode: string) =>
                {
                    const phase =
                        await getDatastoreByCode(
                            types.Datastore.Phase.ActivitySubscription.Type,
                            phaseCode);

                    if (phase)
                    {
                        props.entity.updateRelationship(
                            false,
                            types.Activity.Subscription.RelationshipDefinition.Phase,
                            createTransactionalModel(phase));
                    }

                    return props.entity.checkAndDoCommit();
                },
                [
                    props.entity,
                    types
                ]);

        const activate =
            useCallback(
                () =>
                    setPhase(types.Activity.Subscription.Phase.Activated),
                [
                    setPhase,
                    types
                ]);

        const interval = useEntityValue(props.entity, types.Activity.Subscription.Field.Interval);
        const startDate = useEntityValue<Date>(props.entity, types.Activity.Subscription.Field.StartDate);
        const invoicingStartDate = useEntityValue<Date>(props.entity, types.Activity.Subscription.Field.InvoicingStartDate);
        const startDateOfSecondPeriod = useEntityValue<Date>(props.entity, types.Activity.Subscription.Field.StartDateOfSecondPeriod);

        const [ periods ] =
            useAsyncResult(
                () =>
                    performAction(
                        props.entity,
                        {
                            code: 'Activity.Subscription.GetFirstSubscriptionPeriods',
                            parameters: {
                                numberOfPeriods: 5
                            }
                        })
                        .then(
                            result =>
                                Promise.resolve(result.result as any[])),
                [
                    props.entity,
                    interval,
                    startDate,
                    startDateOfSecondPeriod
                ]);

        const firstPeriodInvoicingOptions =
            useMemo<StaticOption<FirstPeriodInvoicingOptionValue>[]>(
                () => [
                    {
                        id: 'Proportionally',
                        label: localizeText('Subscription.FirstPeriodInvoicingOptions.Proportionally', 'Naar rato'),
                        value: 'Proportionally'
                    },
                    {
                        id: 'None',
                        label: localizeText('Subscription.FirstPeriodInvoicingOptions.None', 'Niet factureren'),
                        value: 'None'
                    }
                ],
                []);

        const firstPeriodInvoicingOptionValue =
            useMemo<FirstPeriodInvoicingOptionValue>(
                () =>
                {
                    if (startDateOfSecondPeriod
                        && invoicingStartDate
                        && startDateOfSecondPeriod.getTime() === invoicingStartDate.getTime())
                    {
                        return 'None';
                    }
                    else
                    {
                        return 'Proportionally';
                    }
                },
                [
                    startDateOfSecondPeriod,
                    invoicingStartDate
                ]);
        const setFirstPeriodInvoicingOptionValue =
            useCallback(
                (value: FirstPeriodInvoicingOptionValue) =>
                    runInAction(
                        () =>
                        {
                            switch (value)
                            {
                                case 'None':
                                    props.entity.setValueByField(
                                        types.Activity.Subscription.Field.InvoicingStartDate,
                                        startDateOfSecondPeriod);

                                    break;

                                case 'Proportionally':
                                    props.entity.setValueByField(
                                        types.Activity.Subscription.Field.InvoicingStartDate,
                                        undefined);

                                    break;
                            }

                            return props.entity.checkAndDoCommit();
                        }),
                [
                    props.entity,
                    startDateOfSecondPeriod
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <InputGroup>
                    <EntityInput
                        entity={props.entity}
                        field={types.Activity.Subscription.Field.StartDate}
                        placeholder={localizeText('Generic.Today', 'Vandaag')}
                    />
                    <EntityInput
                        entity={props.entity}
                        field={types.Activity.Subscription.Field.Interval}
                    />
                    <EntityInput
                        entity={props.entity}
                        field={types.Activity.Subscription.Field.InvoicingStartDate}
                        placeholder={localizeText('Subscription.FromStartDate', 'Vanaf ingangsdatum')}
                    />
                    <EntityInput
                        entity={props.entity}
                        field={types.Activity.Subscription.Field.AdvanceBillingPeriod}
                    />
                </InputGroup>
            </ViewGroupItem>
            <ViewGroupItem>
                <ViewGroup
                    orientation="vertical"
                    spacing={15}
                >
                    <ViewGroupItem>
                        <strong>
                            <LocalizedText
                                code="Subscription.DifferentFirstPeriod"
                                value="Afwijkende eerste facturatieperiode"
                            />
                        </strong>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <LocalizedText
                            code="Subscription.DifferentFirstPeriodDescription"
                            value="Het is mogelijk om de eerste facturatie periode te laten afwijken. Bijvoorbeeld, als het om jaarlijkse facturatie gaat, om halverwege het jaar een facuur te genereren tot de 1 januari van het volgende jaar die naar rato gecalculeerd is. De volgende facturatierondes zullen dan jaarlijks gefactureerd worden."
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <InputGroup>
                            <EntityInput
                                entity={props.entity}
                                field={types.Activity.Subscription.Field.StartDateOfSecondPeriod}
                            />
                            {
                                startDateOfSecondPeriod &&
                                    <Input
                                        labelPosition="left"
                                        label={
                                            <LocalizedText
                                                code="Subscription.FirstPeriodInvoicingOptions"
                                                value="Facturatie eerste periode"
                                            />
                                        }
                                    >
                                        <StaticSelectbox
                                            options={firstPeriodInvoicingOptions}
                                            onChange={setFirstPeriodInvoicingOptionValue}
                                            value={firstPeriodInvoicingOptionValue}
                                        />
                                    </Input>
                            }
                        </InputGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
            {
                periods?.length > 0 &&
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                <strong>
                                    <LocalizedText
                                        code="Subscription.ExamplePeriods"
                                        value="Voorbeeldperiodes"
                                    />
                                </strong>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ol>
                                    {
                                        periods.map(
                                            period =>
                                                <li
                                                    key={period.from}
                                                >
                                                    {localizer.formatDate(new Date(period.from))}-{localizer.formatDate(new Date(period.to))}
                                                </li>)
                                    }
                                </ol>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
            }
            <ViewGroupItem>
                <InputGroup>
                    <EntityInput
                        entity={props.entity}
                        field={types.Activity.Subscription.Field.DoSendAutomatically}
                    />
                </InputGroup>
            </ViewGroupItem>
            <ViewGroupItem>
                <RightAlignedButtonGroup>
                    <PrimaryButton
                        label={
                            <LocalizedText
                                code="Generic.Activate"
                                value="Activeren"
                            />
                        }
                        onClick={activate}
                    />
                </RightAlignedButtonGroup>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(SubscriptionActivationEditor);
