import { LocalizedTextValue } from './LocalizedTextValue';

export default function getExtendedLocalizedValue(value?: LocalizedTextValue,
                                                  augmentor?: (original: string, languageCode: string) => string): LocalizedTextValue
{
    if (value)
    {
        const augmentedValue: LocalizedTextValue = {};

        Object.keys(value)
            .forEach(
                languageCode =>
                    augmentedValue[languageCode] = augmentor(value[languageCode], languageCode));

        return augmentedValue;
    }
    else
    {
        return undefined;
    }
}
