import { ModuleManager, registerModuleLoader } from '../../../@Util/DependencyInjection/index';
import { RootStore } from '../Root/RootStore';

export function setupRootStore()
{
    const moduleManager = new ModuleManager(false);

    registerModuleLoader(
        {
            load: (ref, qualifier) => moduleManager.awaitInstance(ref, qualifier),
            directLoad: (ref, qualifier) => moduleManager.getInstance(ref, qualifier)
        });

    return new RootStore(moduleManager);
}
