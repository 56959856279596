export default function convertBlobToFile(blob: Blob,
                                          fileName: string)
{
    // File constructor is not supported in IE11/EdgeHTML
    const aBlob = blob as any;

    aBlob.lastModifiedDate = new Date();
    aBlob.name = fileName;

    return aBlob as File;
}
