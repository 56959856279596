import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../../../Viewer/LayoutViewer';
import PortalTableLayout from '../../../../../../../@Api/Layout/Type/PortalTable/PortalTableLayout';
import { TableHead, TableRow } from '@material-ui/core';
import { PortalTableHeaderColumn } from './Column/PortalTableHeaderColumn';
import { PortalTableOrdering } from '../../../../../../../@Api/Layout/Type/PortalTable/Model/PortalTableOrdering';
import { PortalTableColumn } from '../../../../../../../@Api/Layout/Type/PortalTable/Model/PortalTableColumn';

export interface PortalTableHeaderProps extends LayoutViewerProps<PortalTableLayout>
{
    ordering?: PortalTableOrdering;
    onOrder: (ordering?: PortalTableOrdering) => void;
    onFilter: (column: PortalTableColumn, filterQuery: string | undefined) => void;
}

export const PortalTableHeader: React.FC<PortalTableHeaderProps> =
    observer(
        props =>
        {
            const totalRatio =
                useComputed(
                    () =>
                        props.layout.columns
                            .map(
                                column =>
                                    column.ratio
                            )
                            .reduce(
                                (a, b) =>
                                    a + b,
                                0
                            ),
                    [
                        props.layout,
                    ]
                );

            return <TableHead>
                <TableRow>
                    {
                        props.layout.columns.map(
                            column =>
                                <PortalTableHeaderColumn
                                    {...props}
                                    column={column}
                                    totalRatio={totalRatio}
                                />
                        )
                    }
                </TableRow>
            </TableHead>;
        }
    );
