import { useCallback } from 'react';
import showErrorMessage from '../../../../@Api/Error/showErrorMessage';
import { useIsMounted } from '../../../../@Util/Async/useIsMounted';

export type PromiseOrAny<T> = Promise<T> | any;

export interface BaseButtonProps
{
    onClick: () => PromiseOrAny<any>;
    fullWidth?: boolean;
    disabled?: boolean;
}

export function useButtonClickHandler(onClick: (event?: any) => PromiseOrAny<any>,
                                      setLoading: (isLoading: boolean) => void,
                                      doPassEventToOnclick = false)
{
    const isMounted = useIsMounted();

    return useCallback(
        event =>
        {
            event.stopPropagation();

            setLoading(true);

            const catchError =
                (error: Error) =>
                {
                    if (isMounted())
                    {
                        setLoading(false);
                    }

                    showErrorMessage(error);
                };

            try
            {
                Promise.resolve(doPassEventToOnclick ? onClick(event) : onClick())
                    .then(
                        () =>
                        {
                            if (isMounted())
                            {
                                setLoading(false);
                            }
                        })
                    .catch(catchError);
            }
            catch (e)
            {
                catchError(e);
            }
        },
        [
            onClick,
            isMounted,
            setLoading,
            doPassEventToOnclick
        ]);
}
