import React from 'react';
import { Box, Collapse, Fab } from '@material-ui/core';
import useToggle from '../../../@Util/Toggle/useToggle';
import { Assistant } from './Assistant';
import { green } from '@material-ui/core/colors';
import SmartAssistantAvatarWhite from './Resources/smart_assistant_avatar_white_2.png';
import useLocalSetting from '../Setting/Api/useLocalSetting';
import { Setting } from '../../../@Api/Settings/Setting';

export interface AssistantButtonProps
{

}

export const AssistantButton: React.FC<AssistantButtonProps> =
    () =>
    {
        const [isEnabled] =
            useLocalSetting(
                Setting.Local.Assistant.IsEnabled,
                false
            );
        const [isOpen, toggleOpen] = useToggle(false);

        if (isEnabled)
        {
            return <Box
                style={{
                    position: 'fixed',
                    right: 16,
                    bottom: 16,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    gap: 16,
                }}
            >
                <Collapse
                    in={isOpen}
                >
                    <Assistant
                        open={isOpen}
                        onClose={toggleOpen}
                    />
                </Collapse>
                <Fab
                    color="primary"
                    onClick={
                        () =>
                            toggleOpen()
                    }
                    style={{
                        backgroundColor: green[500],
                    }}
                >
                    <img
                        src={SmartAssistantAvatarWhite}
                        width={40}
                        style={{
                            marginTop: -3,
                        }}
                    />
                </Fab>
            </Box>;
        }
        else
        {
            return null;
        }
    };
