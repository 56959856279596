import React, { useEffect, useRef } from 'react';
import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { catchImport } from '../Import/catchImport';
import ErrorBoundary from '../../@Component/Error/ErrorBoundary';

export interface AsyncComponentProps
{
    _loader: () => Promise<any>;
    _loadKey?: string;
    _onRef?: (ref: any) => void;
    _passOnRefDown?: boolean;
}

const loadedComponents = observable.map<any, any>([], { deep: false });

const AsyncComponent: React.FC<AsyncComponentProps & any> =
    (props: AsyncComponentProps) =>
    {
        const { _loader, _loadKey, _onRef, _passOnRefDown, ...otherProps } = props;
        const module = loadedComponents.get(_loader);

        useEffect(
            () =>
            {
                if (!module)
                {
                    _loader()
                        .then(
                            module =>
                            {
                                runInAction(
                                    () =>
                                        loadedComponents.set(_loader, module));
                            })
                        .catch(catchImport);
                }
            },
            [
                _loader,
                module
            ]);

        const ref = useRef();

        useEffect(
            () =>
            {
                if (_onRef
                    && !_passOnRefDown)
                {
                    _onRef(ref.current);
                }
            },
            [
                _onRef,
                _passOnRefDown,
                ref
            ]);

        if (module)
        {
            const element =
                React.createElement(
                    _loadKey ? module[_loadKey] : module,
                    {
                        ref: _onRef && !_passOnRefDown ? ref : undefined,
                        _onRef: _onRef && _passOnRefDown ? _onRef : undefined,
                        ...otherProps
                    });

            return <ErrorBoundary>
                {element}
            </ErrorBoundary>;
        }
        else
        {
            return null;
        }
    };

export default observer(AsyncComponent);
