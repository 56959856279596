import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import LayoutEditor, { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import { runInAction } from 'mobx';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import ParameterAssignment from '../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import EntitySelectionFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/EntitySelectionFormInputLayout';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import Layout from '../../../../../../@Api/Layout/Layout';

export interface EntitySelectionFormInputLayoutQueryEditorProps extends LayoutEditorProps<EntitySelectionFormInputLayout>
{

}

const EntitySelectionFormInputLayoutQueryEditor: React.FC<EntitySelectionFormInputLayoutQueryEditorProps> =
    props =>
    {
        const { layout, parameterDictionary, parameterAssignment, commitContext } = props;

        const optionLayoutContext =
            useMemo(
                () =>
                    new FunctionContext(
                        parameterDictionary.getNewDictionaryWithParameter(layout.query.parameter),
                        parameterAssignment || new ParameterAssignment(),
                        commitContext
                    ),
                [
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                ]);
        const setOptionLayout =
            useCallback(
                (optionLayout: Layout) =>
                    runInAction(
                        () =>
                            layout.optionLayout = optionLayout),
                [
                    layout
                ]);

        return <Input
            labelPosition="left"
            label={
                <LocalizedText
                    code="Generic.Layout"
                    value="Layout"
                />
            }
        >
            <LayoutEditor
                layout={layout.optionLayout}
                onChange={setOptionLayout}
                parameterDictionary={optionLayoutContext.parameterDictionary}
                parameterAssignment={optionLayoutContext.parameterAssignment}
            />
        </Input>;
    };

export default observer(EntitySelectionFormInputLayoutQueryEditor);
