import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { ActionEditorProps } from '../../ActionEditor';
import { runInAction } from 'mobx';
import CardInset from '../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationEditor from '../../../Computation/ComputationEditor';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import InputGroup from '../../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import ExceptionAction from '../../../../../../../../../../@Api/Automation/Function/Action/ExceptionAction';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../../../../../DataObject/Model/DataObject';

export interface ExceptionActionEditorProps extends ActionEditorProps<ExceptionAction>
{

}

const ExceptionActionEditor: React.FC<ExceptionActionEditorProps> =
    props =>
    {
        const setMessage =
            useCallback(
                (message?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.action.message = message),
                [
                    props.action
                ]);

        const textType =
            useMemo(
                () =>
                    new PrimitiveValueType(DataObject.getTypeById('Text')),
                []);

        return <CardInset
            top={false}
        >
            <InputGroup>
                <Input
                    label={
                        <LocalizedText
                            code="Generic.Message"
                            value="Bericht"
                        />
                    }
                    labelPosition="top"
                >
                    <ComputationEditor
                        context={props.context}
                        value={props.action.message}
                        onChange={setMessage}
                        type={textType}
                    />
                </Input>
            </InputGroup>
        </CardInset>;
    };

export default observer(ExceptionActionEditor);
