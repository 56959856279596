import { enumerated, type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';
import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { GlobalEnvironment } from '../../../@Global/GlobalEnvironment';

export enum Source { Google, Unknown }

@type('ExternalPicture')
export class ExternalPicture
{
    // ------------------- Persistent Properties --------------------

    @observable @enumerated(Source, 'Source') source: Source;
    @observable url: string;
    @observable hmac: string;
    @observable type?: string;
    @observable description?: string;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getProxyUrl(apiClient: ApiClient): string
    {
        return ExternalPicture.getProxyUrl(
            apiClient,
            this.url,
            this.hmac
        );
    }

    static getProxyUrl(
        apiClient: ApiClient,
        url: string,
        hmac: string
    )
    {
        return `${GlobalEnvironment.FUNCTION_IMAGE_PROXY}?url=${encodeURIComponent(url)}&hmac=${encodeURIComponent(hmac)}`;
    }

    // ----------------------- Private logic ------------------------
}
