import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import LayoutContext from '../../../../../@Api/Layout/LayoutContext';
import FormLayoutContexts from '../Form/FormLayoutContexts';
import AddressLookupLayout from '../../../../../@Api/Layout/Type/AddressLookupLayout';
import { AddressLookupWizard } from '../../../Entity/Constructor/Type/Address/LookupWizard/AddressLookupWizard';
import { AddressDetailsResult } from '../../../Entity/Constructor/Type/Address/LookupWizard/Model/AddressDetailsResult';
import { runInAction } from 'mobx';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../DataObject/Model/DataObject';
import getDatastoreByCode from '../../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import useTypes from '../../../Entity/Type/Api/useTypes';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import safelySynchronousApplyFunction from '../../../../../@Api/Automation/Api/safelySynchronousApplyFunction';

export interface AddressLookupLayoutViewerProps extends LayoutViewerProps<AddressLookupLayout>
{

}

const AddressLookupLayoutViewer: React.FC<AddressLookupLayoutViewerProps> =
    ({
        layout,
        parameterDictionary,
        parameterAssignment,
        commitContext,
         safeMode,
     }) =>
    {
        const types = useTypes();
        const formLayoutContexts = useContext(FormLayoutContexts);
        const context =
            useMemo(
                () =>
                    new LayoutContext(
                        parameterDictionary,
                        parameterAssignment,
                        commitContext,
                        safeMode,
                        formLayoutContexts
                    ),
                [
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                    safeMode,
                    formLayoutContexts,
                ]
            );
        const onLookup =
            useCallback(
                async (address: AddressDetailsResult) =>
                {
                    const country =
                        await getDatastoreByCode(
                            types.Datastore.Country.Type,
                            address.countryCode
                        );

                    return runInAction(
                        () =>
                        {
                            if (layout.streetParameter)
                            {
                                context.parameterAssignment.setValue(
                                    layout.streetParameter.parameter,
                                    new PrimitiveValue(
                                        DataObject.constructFromTypeIdAndValue(
                                            'Text',
                                            address.street
                                        )
                                    )
                                );
                            }

                            if (layout.houseNumberParameter)
                            {
                                context.parameterAssignment.setValue(
                                    layout.houseNumberParameter.parameter,
                                    new PrimitiveValue(
                                        DataObject.constructFromTypeIdAndValue(
                                            'Number',
                                            address.houseNumber
                                        )
                                    )
                                );
                            }

                            if (layout.houseNumberSuffixParameter)
                            {
                                context.parameterAssignment.setValue(
                                    layout.houseNumberSuffixParameter.parameter,
                                    address.houseNumberSuffix
                                        ?
                                        new PrimitiveValue(
                                            DataObject.constructFromTypeIdAndValue(
                                                'Text',
                                                address.houseNumberSuffix
                                            )
                                        )
                                        :
                                        undefined
                                );
                            }

                            if (layout.postalCodeParameter)
                            {
                                context.parameterAssignment.setValue(
                                    layout.postalCodeParameter.parameter,
                                    new PrimitiveValue(
                                        DataObject.constructFromTypeIdAndValue(
                                            'Text',
                                            address.postalCode
                                        )
                                    )
                                );
                            }

                            if (layout.cityParameter)
                            {
                                context.parameterAssignment.setValue(
                                    layout.cityParameter.parameter,
                                    new PrimitiveValue(
                                        DataObject.constructFromTypeIdAndValue(
                                            'Text',
                                            address.city
                                        )
                                    )
                                );
                            }

                            if (layout.countryParameter)
                            {
                                context.parameterAssignment.setValue(
                                    layout.countryParameter.parameter,
                                    country
                                        ?
                                            new EntityValue(country)
                                        :
                                            undefined
                                );
                            }

                            if (layout.onLookup)
                            {
                                return layout.onLookup.apply(context);
                            }
                        }
                    );
                },
                [
                    layout,
                    context,
                ]
            );
        const isAutoFocused =
            useComputed(
                () =>
                {
                    if (layout.isAutoFocused)
                    {
                        return safelySynchronousApplyFunction(
                            layout.isAutoFocused,
                            context
                        ).equals(
                            new PrimitiveValue(
                                DataObject.constructFromTypeIdAndValue(
                                    'Boolean',
                                    true
                                )
                            )
                        );
                    }
                    else
                    {
                        return false;
                    }
                }
            );

        return <AddressLookupWizard
            onLookup={onLookup}
            autoFocus={isAutoFocused}
        />;
    };

export default observer(AddressLookupLayoutViewer);
