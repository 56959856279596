import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import localizeText from '../../Localization/localizeText';
import Computation from '../../Automation/Function/Computation/Computation';
import getComputationFromDescriptor from '../../Automation/Api/getComputationFromDescriptor';

export default class ExpansionLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref header: Layout;
    @observable.ref expansion: Layout;
    @observable.ref hasHeaderInset: boolean;
    @observable.ref isExpandedByDefault?: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(header: Layout,
                expansion: Layout,
                hasHeaderInset: boolean,
                isExpandedByDefault?: Computation<any, any>)
    {
        super();

        this.header = header;
        this.expansion = expansion;
        this.hasHeaderInset = hasHeaderInset;
        this.isExpandedByDefault = isExpandedByDefault;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.Expansion', 'Uitklappaneel');
    }

    validate(): Validation[]
    {
        return [
            ...this.header.validate(),
            ...this.expansion.validate(),
            ...this.isExpandedByDefault?.validate() || []
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.header.getDependencies(),
            ...this.expansion.getDependencies(),
            ...this.isExpandedByDefault?.getDependencies() || []
        ];
    }

    toDescriptor()
    {
        return {
            type: 'Expansion',
            header: this.header.toDescriptor(),
            expansion: this.expansion.toDescriptor(),
            hasHeaderInset: this.hasHeaderInset,
            isExpandedByDefault: this.isExpandedByDefault?.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new ExpansionLayout(
            await getLayoutFromDescriptor(
                descriptor.header,
                dependencyContext),
            await getLayoutFromDescriptor(
                descriptor.expansion,
                dependencyContext),
            descriptor.hasHeaderInset,
            descriptor.isExpandedByDefault
                ?
                    await getComputationFromDescriptor(
                        descriptor.isExpandedByDefault,
                        dependencyContext)
                :
                    undefined);
    }

    // ----------------------- Private logic ------------------------
}
