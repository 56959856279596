import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { ComputationEditorProps } from '../../ComputationEditor';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import FileAttachmentComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/FileAttachmentComputation';
import AutomationEntityContext from '../../../Context/AutomationEntityContext';
import { default as AttachmentViewer } from '../../../../../../../Item/Navigator/More/Attachment/AttachmentMore';
import { FileValue } from '../../../../../../../../DataObject/Type/File/FileValue';
import { FileUploaderStore } from '../../../../../../../../../Generic/FileUploader/FileUploaderStore';
import useTypes from '../../../../../../../Type/Api/useTypes';
import { FileUploader } from '../../../../../../../../../Generic/FileUploader/FileUploader';
import { createTransactionalModel, getModel } from '../../../../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import EntityValue from '../../../../../../../../../../@Api/Automation/Value/EntityValue';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface FileAttachmentComputationEditorProps extends ComputationEditorProps<PrimitiveValueType, FileAttachmentComputation>
{

}

const FileAttachmentComputationEditor: React.FC<FileAttachmentComputationEditorProps> =
    props =>
    {
        const types = useTypes();
        const automationEntity = useContext(AutomationEntityContext);

        const uploadFiles =
            useCallback(
                (files: File[]) =>
                {
                    const file = files.find(() => true);

                    if (file)
                    {
                        const attachment =
                            createTransactionalModel(
                                new Entity(types.Attachment.Type)
                                    .initialize());

                        attachment.updateRelationship(
                            true,
                            types.Entity.RelationshipDefinition.Attachments,
                            createTransactionalModel(automationEntity));

                        attachment.setValueByField(
                            types.Attachment.Field.File,
                            FileValue.fromFile(file));

                        return attachment.checkAndDoCommit()
                            .then(
                                () =>
                                    props.onChange(
                                        new FileAttachmentComputation(
                                            new EntityValue(getModel(attachment)))));
                    }
                },
                [
                    types,
                    automationEntity,
                    props.onChange
                ]);

        const uploaderStore =
            useMemo(
                () =>
                    new FileUploaderStore(uploadFiles),
                [
                    uploadFiles
                ]);

        if (automationEntity)
        {
            if (props.value?.attachment)
            {
                return <AttachmentViewer
                    entity={props.value.attachment.value}
                />;
            }
            else
            {
                return <FileUploader
                    store={uploaderStore}
                />;
            }
        }
        else
        {
            return <span>
                <LocalizedText
                    code="ComputationEditor.FilesCannotBeUploaded"
                    value="Het is niet mogelijk om bestanden in deze automation te uploaden."
                />
            </span>;
        }
    };

export default observer(FileAttachmentComputationEditor);
