import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../../Model/DataObjectType';
import { Comparator } from '../../../Model/Comparator';
import { Aggregate } from '../../../Model/Aggregate';
import { MathematicalOperator } from '../../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../../Model/DataObjectFunction';
import { DataObject } from '../../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../../@Util/DependencyInjection/index';
import { DateIntervalView } from './DateIntervalView';
import { DateIntervalEditor } from './DateIntervalEditor';
import { DateIntervalSpecification } from './DateIntervalSpecification';
import { DateIntervalValue, IntervalType } from './DateIntervalValue';
import { DataDescriptor } from '../../../Model/DataDescriptor';
import { ComparatorDescriptor } from '../../../Model/ComparatorDescriptor';
import { DataObjectRepresentation } from '../../../Model/DataObjectRepresentation';
import { DataObjectOperatorOverload } from '../../../Model/DataObjectOperatorOverload';
import { DataObjectContext } from '../../../Model/DataObjectContext';
import { DataObjectComparatorOverload } from '../../../Model/DataObjectComparatorOverload';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { toJS } from 'mobx';
import { DataObjectSpecification } from '../../../Model/DataObjectSpecification';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export class DateIntervalType implements DataObjectType<DateIntervalValue>
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'DateInterval';
    }

    name()
    {
        return localizeText('DataObject.Type.DateInterval', 'Tijdsinterval');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [
            {
                comparator: Comparator.Equals
            },
            {
                comparator: Comparator.NotEquals
            }
        ];
    }

    supportedAggregates(): Aggregate[]
    {
        return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return DateIntervalView;
    }

    editor(): DataObjectEditorType
    {
        return DateIntervalEditor;
    }

    specification(): DataObjectSpecificationType
    {
        return DateIntervalSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return null;
    }

    requiresInitialization(value: DateIntervalValue): boolean
    {
        return false;
    }

    getDataFromValue(value: DateIntervalValue, specification: DataObjectSpecification): DataDescriptor
    {
        let data = new DataDescriptor();
        data.complex = value;

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): any
    {
        return data.complex;
    }

    valueId(value: DateIntervalValue): string
    {
        return JSON.stringify(toJS(value));
    }

    getString(value: DateIntervalValue,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        switch (value.type)
        {
            case 'Daily':
                return localizeText('Generic.Daily', 'Dagelijks');

            case 'Weekly':
                return localizeText('Generic.Weekly', 'Wekelijks');

            case 'FourWeekly':
                return localizeText('Generic.FourWeekly', 'Vierwekelijks');

            case 'Monthly':
                if (value.frequency === 2)
                {
                    return localizeText('Generic.TwoMonthly', 'Tweemaandelijks');
                }
                else if (value.frequency === 4)
                {
                    return localizeText('Generic.FourMonthly', 'Viermaandelijks');
                }
                else
                {
                    return localizeText('Generic.Monthly', 'Maandelijks');
                }

            case 'Quarterly':
                return localizeText('Generic.Quarterly', 'Per kwartaal');

            case 'HalfYearly':
                return localizeText('Generic.HalfYearly', 'Halfjaarlijks');

            case 'Yearly':
                if (value.frequency === 2)
                {
                    return localizeText('Generic.TwoYearly', 'Tweejaarlijks');
                }
                else
                {
                    return localizeText('Generic.Yearly', 'Jaarlijks');
                }
        }
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return false;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [];
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    constructEnclosingInterval(interval: DataObject, enclosingInterval: DataObject): void
    {
        let intervalValue: DateIntervalValue = interval.getValue();

        if (intervalValue)
        {
            let enclosingType: IntervalType;

            switch (intervalValue.type)
            {
                case 'Daily':
                case 'Weekly':
                case 'FourWeekly':
                    enclosingType = 'Monthly';
                    break;

                case 'Monthly':
                case 'Quarterly':
                case 'HalfYearly':
                    enclosingType = 'Yearly';
                    break;
            }

            if (enclosingType)
            {
                let enclosingIntervalValue: DateIntervalValue =
                {
                    type: enclosingType,
                    frequency: 1
                };

                enclosingInterval.setValue(enclosingIntervalValue);
            }
        }
    }

    constructEnclosedInterval(interval: DataObject, enclosedInterval: DataObject): void
    {
        let intervalValue: DateIntervalValue = interval.getValue();

        if (intervalValue)
        {
            let enclosedType: IntervalType;

            switch (intervalValue.type)
            {
                case 'Monthly':
                case 'Weekly':
                case 'FourWeekly':
                    enclosedType = 'Daily';
                    break;

                case 'Quarterly':
                case 'HalfYearly':
                case 'Yearly':
                    enclosedType = 'Monthly';
                    break;
            }

            if (enclosedType)
            {
                let enclosingIntervalValue: DateIntervalValue =
                {
                    type: enclosedType,
                    frequency: 1
                };

                enclosedInterval.setValue(enclosingIntervalValue);
            }
        }
    }

    isValid(dataObject: DataObject): boolean
    {
        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return false;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }
}
