import { useCallback, useContext } from 'react';
import OpenedEntityContext from './OpenedEntityContext';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';

export default function useOpenEntityCallback()
{
    const [ , setEntity ] = useContext(OpenedEntityContext);

    return useCallback(
        (entity: Entity) =>
            setEntity(entity),
        [
            setEntity
        ]);
}