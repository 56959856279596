import { EntityType } from '../../Model/Implementation/EntityType';
import isRequiredField from './isRequiredField';
import { EntityRelationshipDefinition } from '../../Model/Implementation/EntityRelationshipDefinition';
import RelationshipInput from '../../../@Component/Domain/Multiplayer/Model/Input/RelationshipInput';

export function isRelationshipDefinitionRequired(
    type: EntityType,
    isParent: boolean,
    relationshipDefinition: EntityRelationshipDefinition
): boolean
{
    const input = new RelationshipInput(type, relationshipDefinition, isParent);

    return isRequiredField(input);
}
