import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { OldPredicate } from '../Type/OldPredicate';
import FunctionContext from '../../../../@Api/Automation/Function/FunctionContext';
import ParameterDictionary from '../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../@Api/Automation/Value/Type/EntityValueType';
import { EntityContext } from '../../Entity/@Model/EntityContext';
import PredicateEditor from '../../Entity/Viewer/Content/Automation/Editor/Predicate/PredicateEditor';
import ComparisonPredicate from '../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../DataObject/Model/Comparator';
import CompositePredicate from '../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../DataObject/Model/LogicalOperator';
import useTypes from '../../Entity/Type/Api/useTypes';
import { ComputationConstructorItem } from '../../Entity/Viewer/Content/Automation/Editor/Computation/ComputationConstructor';
import localizeText from '../../../../@Api/Localization/localizeText';
import { default as NewPredicate } from '../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import useAsyncResult from '../../../../@Util/Async/useAsyncResult';

export interface ToNewPredicateEditorProps
{
    context: EntityContext;
    predicate?: OldPredicate;
    onChange: (newPredicate: OldPredicate) => void;
    disableMe?: boolean;
    disabled?: boolean;
}

const ToNewPredicateEditor: React.FC<ToNewPredicateEditorProps> =
    props =>
    {
        const types = useTypes();

        const [ predicate, setPredicate ] =
            useState(
                () =>
                    props.predicate);

        const [ isInitialized, setInitialized ] = useState(false);

        useEffect(
            () =>
            {
                if (!isInitialized)
                {
                    if (predicate)
                    {
                        predicate.initialize()
                            .then(
                                () =>
                                    setInitialized(true));
                    }
                    else
                    {
                        setInitialized(true);
                    }
                }
            },
            [
                isInitialized,
                predicate,
                setInitialized
            ]);

        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        new ParameterDictionary([
                            new Parameter(
                                'Entity',
                                new EntityValueType(
                                    props.context.entityPath.entityType),
                                true,
                                props.context.entityPath.entityType.getName()),
                            new Parameter(
                                'T',
                                new EntityValueType(
                                    props.context.entityPath.entityType),
                                true,
                                props.context.entityPath.entityType.getName()),
                            ...props.disableMe
                                ?
                                    []
                                :
                                    [
                                        new Parameter(
                                            'Me',
                                            new EntityValueType(types.Relationship.Person.Contact.Employee.Type),
                                            true,
                                            localizeText('Predicate.MySelf', 'Mijzelf'))
                                    ]
                        ])),
                [
                    props.context
                ]);

        const [ newPredicate ] =
            useAsyncResult(
                async () =>
                    predicate && isInitialized
                        ?
                            await predicate?.toNewPredicate(context)
                        :
                            new CompositePredicate(
                                LogicalOperator.And,
                                []),
                [
                    predicate,
                    isInitialized,
                    context
                ]);

        const editedOldPredicate =
            useComputed(
                () =>
                    newPredicate?.isValid()
                        ?
                            newPredicate?.toOldPredicate()
                        :
                            undefined,
                [
                    newPredicate
                ]);

        useEffect(
            () =>
            {
                if (isInitialized && editedOldPredicate)
                {
                    props.onChange(editedOldPredicate);
                }
            },
            [
                isInitialized,
                editedOldPredicate,
                props.onChange
            ]);

        const comparisonConstructor =
            useCallback(
                () =>
                    new ComparisonPredicate(
                        new ValueFromEntityComputation(
                            context.parameterDictionary.getParameterById('Entity'),
                            undefined),
                        Comparator.Equals,
                        undefined),
                [
                    context
                ]);

        const onChange =
            useCallback(
                (predicate?: NewPredicate) =>
                {
                    setPredicate(predicate?.toOldPredicate());
                },
                [
                    setPredicate
                ]);

        const constructorItemFilter =
            useCallback(
                (item: ComputationConstructorItem) =>
                    item.id === 'Value'
                    || item.id === 'ValueFromEntityParameter.Entity'
                    || item.id === 'Parameter.Me',
                []);

        return <PredicateEditor
            comparisonConstructor={comparisonConstructor}
            comparisonLhsTypeFixed
            onChange={onChange}
            context={context}
            value={newPredicate}
            constructorItemFilter={constructorItemFilter}
            disallowRichText
        />;
    };

export default observer(ToNewPredicateEditor);
