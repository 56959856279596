import Predicate from '../Automation/Function/Computation/Predicate/Predicate';
import ComparisonPredicate from '../Automation/Function/Computation/Predicate/ComparisonPredicate';
import Parameter from '../Automation/Parameter/Parameter';
import EntityValueType from '../Automation/Value/Type/EntityValueType';
import FunctionContext from '../Automation/Function/FunctionContext';
import EntityValue from '../Automation/Value/EntityValue';
import PrimitiveValue from '../Automation/Value/PrimitiveValue';
import ValueFromEntityComputation from '../Automation/Function/Computation/ValueFromEntityComputation';
import { EntityFieldPath } from '../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import { CommitContext } from './Commit/Context/CommitContext';
import { Entity } from '../Model/Implementation/Entity';

export function updateEntityFromFilter(
    entity: Entity,
    filter: Predicate,
    functionContext: FunctionContext,
    parameter?: Parameter<EntityValueType>,
    commitContext?: CommitContext
)
{
    filter.getTrueStatements(
        functionContext,
        parameter
    )
        .forEach(
            predicate =>
            {
                if (predicate instanceof ComparisonPredicate)
                {
                    let lhsFieldPath: EntityFieldPath;

                    if (predicate.lhs instanceof ValueFromEntityComputation
                        && predicate.lhs.entity instanceof Parameter
                        && predicate.lhs.entity.type instanceof EntityValueType
                        && predicate.lhs.entity.type.type.isA(entity.entityType))
                    {
                        lhsFieldPath = predicate.lhs.fieldPath;
                    }

                    if (lhsFieldPath)
                    {
                        const rhsValue = predicate.rhs.synchronousApply(functionContext);

                        if (rhsValue instanceof PrimitiveValue
                            && lhsFieldPath.field)
                        {
                            lhsFieldPath.setValue(
                                entity,
                                rhsValue.value.value,
                                undefined,
                                commitContext
                            );
                        }
                        else if (rhsValue instanceof EntityValue
                            && !lhsFieldPath.field)
                        {
                            lhsFieldPath.setValue(
                                entity,
                                rhsValue.value,
                                undefined,
                                commitContext
                            )
                        }
                    }
                }
            }
        );
}
