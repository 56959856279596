import React, { useState } from 'react';
import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import usePaginatedSelection from '../../../../../../../View/Api/usePaginatedSelection';
import useTypes from '../../../../../../../Type/Api/useTypes';
import MileageSheetTable from '../../../../MileageRegistration/MileageSheet/Table/MileageSheetTable';

export interface ProductLineBilledMileageRegistrationsProps
{
    productLine: Entity;
}

const ProductLineBilledMileageRegistrations: React.FC<ProductLineBilledMileageRegistrationsProps> =
    props =>
    {
        const { productLine } = props;
        const types = useTypes();

        const [ pages, hasMore ] =
            usePaginatedSelection(
                types.MileageRegistration.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.ProductLine.RelationshipDefinition.BilledMileageRegistrations,
                                        true),
                                    productLine))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.MileageRegistrations,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Activity.RelationshipDefinition.MileageRegistrations,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.MileageRegistrations,
                                    true))
                        .join(
                            rootPath
                                .joinTo(
                                    types.MileageRegistration.RelationshipDefinition.Type,
                                    false))
                        .orderBy(
                            rootPath.field(types.MileageRegistration.Field.Date),
                            true),
                [
                    types,
                    productLine
                ]);
        const [ constructingEntity, setConstructingEntity ] = useState<Entity | undefined>();
        const [ selectedLines ] = useState<Entity[]>([]);

        return <MileageSheetTable
            pages={pages}
            hasMore={hasMore}
            constructingEntity={constructingEntity}
            onChangeConstructingEntity={setConstructingEntity}
            selectedLines={selectedLines}
            showActivity
        />;
    };

export default observer(ProductLineBilledMileageRegistrations);
