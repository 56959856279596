export class FormEvent<T>
{
    // ------------------ Members -----------------------------

    static Change = 'Change';
    static Click = 'Click';
    static MouseEnter = 'MouseEnter';
    static MouseOver = 'MouseOver';
    static MouseLeave = 'MouseLeave';
    static KeyUp = 'KeyUp';
    static KeyDown = 'KeyDown';
    static KeyPress = 'KeyPress';
    static Focus = 'Focus';
    static Blur = 'Blur';

    id: string;

    data: T;

    event: any;

    // -------------- Constructor -----------------------------

    constructor(id: string,
                data: T,
                event?: any)
    {
        this.id = id;
        this.data = data;
        this.event = event;
    }

    /***********************************
     **         Business logic        **
     ***********************************/

    /***********************************
     **      Getters and setters      **
     ***********************************/
}
