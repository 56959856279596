import moment from 'moment';
import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../../../../../../../../@Api/Model/Implementation/Entity';
import { EntityField } from '../../../../../../../../../../../../../../../@Api/Model/Implementation/EntityField';
import { CommitContext } from '../../../../../../../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';

export default function useIsOverdue(entity: Entity,
                                     endDateField: EntityField,
                                     commitContext?: CommitContext)
{
    return useComputed(
        () =>
        {
            const date = entity.getObjectValueByField(endDateField, commitContext);

            if (date)
            {
                return moment().isAfter(date);
            }
            else
            {
                return false;
            }
        },
        [
            entity,
            endDateField,
            commitContext,
        ]);
}
