import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import TabBar from '../../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import styles from './Constructor.module.scss';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import { default as InternalConstructor } from '../../../../../Item/Navigator/List/Constructor/Constructor';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import useOpenEntityCallback from '../../../../Context/OpenedEntity/useOpenEntityCallback';
import useToggle from '../../../../../../../../@Util/Toggle/useToggle';
import isHiddenType from '../../../../../../../../@Api/Metadata/EntityType/isHiddenType';
import doOpenAfterCreation from '../Api/doOpenAfterCreation';
import Popper from '../../../../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import OnFileDropDisableContext from '../../../../../../../../@Future/Component/Generic/FileDrop/OnFileDropDisableContext';

export interface ConstructorProps
{
    entity: Entity;
    paths: EntityPath[];
    onConstruct?: (entity: Entity, path: EntityPath) => void;
    isMore: (path: EntityPath) => boolean;
    isConstructable?: (path: EntityPath) => boolean;
}

const Constructor: React.FC<ConstructorProps> =
    ({
         onConstruct,
         paths,
         isConstructable,
         isMore,
         entity
    }) =>
    {
        const [ path, setPath ] = useState<EntityPath | undefined>();
        const [ isMoreSelected, toggleMoreSelected ] = useToggle(false);
        const currentUserStore = useContext(CurrentUserContext);

        const selectPath =
            useCallback(
                (path: EntityPath) =>
                {
                    setPath(path);
                    toggleMoreSelected(false);
                },
                [
                    setPath,
                    toggleMoreSelected,
                ]
            );

        const closeMore =
            useCallback(
                () =>
                {
                    setPath(undefined);
                    toggleMoreSelected(false);
                },
                [
                    setPath,
                    toggleMoreSelected
                ]);

        const plus =
            <>
                +&nbsp;
            </>;

        const onConstructInternally =
            useCallback(
                (entity: Entity) =>
                {
                    if (onConstruct)
                    {
                        return onConstruct(entity, path);
                    }
                },
                [
                    onConstruct,
                    path
                ]);

        const onClose =
            useCallback(
                () =>
                {
                    setPath(undefined);
                },
                [
                    setPath,
                ]);

        const openEntity = useOpenEntityCallback();
        const onAdd =
            useCallback(
                (entity: Entity) =>
                {
                    if (doOpenAfterCreation(entity))
                    {
                        openEntity(entity);
                    }

                    onClose();
                },
                [
                    openEntity,
                    onClose
                ]);

        const visiblePaths =
            useComputed(
                () =>
                    paths
                        .filter(
                            path =>
                                isConstructable(path))
                        .filter(
                            path =>
                                currentUserStore.rightProfile.canCreate(path.entityType))
                        .filter(
                            path =>
                                !isHiddenType(path.entityType)),
                [
                    paths,
                    isConstructable,
                    currentUserStore
                ]);

        const [ mainPaths, morePaths ] =
            useMemo(
                () => [
                    visiblePaths.filter(
                        path =>
                            !isMore(path)),
                    visiblePaths.filter(
                        path =>
                            isMore(path))
                ],
                [
                    visiblePaths,
                    isMore
                ]);

        const isOpen = !isMoreSelected && path !== undefined;

        const disableFileDrop = useContext(OnFileDropDisableContext);

        useEffect(
            () => {
                if (disableFileDrop)
                {
                    disableFileDrop(isOpen);
                }
            },
            [
                disableFileDrop,
                isOpen
            ]
        )

        return <>
            <TabBar
                value={isMoreSelected && morePaths.length > 0 ? 'more' : path}
                bordered={isOpen}
            >
                {
                    mainPaths.map(
                        path =>
                            <Tab
                                key={path.id}
                                value={path}
                                onClick={selectPath}
                            >
                                <span
                                    className={styles.tabLabel}
                                >
                                    {plus} {path.entityType.getName()}
                                </span>
                            </Tab>)
                }
                {
                    morePaths.length > 0 &&
                        <Popper
                            reference={
                                <Tab
                                    value="more"
                                    onClick={toggleMoreSelected}
                                >
                                    <span
                                        className={styles.tabLabel}
                                    >
                                        {plus} <LocalizedText code="Generic.More" value="Meer" />
                                    </span>
                                </Tab>
                            }
                            popper={
                                <Card>
                                    <Menu>
                                        {
                                            morePaths.map(
                                                morePath =>
                                                    <Item
                                                        key={morePath.id}
                                                        name={morePath.entityType.getName()}
                                                        onClick={() => selectPath(morePath)}
                                                    />)
                                        }
                                    </Menu>
                                </Card>
                            }
                            open={isMoreSelected}
                            onClose={closeMore}
                            renderInPortal={document.body}
                        />
                    }
            </TabBar>
            {
                isOpen &&
                    <CardInset>
                        <InternalConstructor
                            entity={entity}
                            path={path}
                            onConstruct={onConstructInternally}
                            onAdd={onAdd}
                            onCancel={onClose}
                            autoFocus
                        />
                    </CardInset>
            }
        </>;
    };

Constructor.defaultProps = {
    isConstructable: () => true
};

export default observer(Constructor);
