import Parameter from './Parameter';
import { observable } from 'mobx';
import AutomationDependencyContext from '../AutomationDependencyContext';
import Dependency from './Dependency';

export default class ParameterDictionary
{
    // ------------------------- Properties -------------------------

    @observable.shallow parameterById: Map<string, Parameter<any>>;

    // ------------------------ Constructor -------------------------

    constructor(parameters: Parameter<any>[])
    {
        this.parameterById = new Map(
            parameters.map(
                parameter => [
                    parameter.id,
                    parameter
                ]));
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getParameterById(id: string)
    {
        const parameter = this.parameterById.get(id);

        if (!parameter)
        {
            throw new Error(`parameter with id: ${id} not found`);
        }

        return parameter;
    }

    hasParameter(parameter: Parameter<any>)
    {
        return this.hasParameterId(parameter.id);
    }

    hasParameterId(parameterId: string)
    {
        return this.parameterById.has(parameterId);
    }

    addParameter(parameter: Parameter<any>)
    {
        this.parameterById.set(
            parameter.id,
            parameter);
    }

    deleteParameter(parameter: Parameter<any>)
    {
        this.parameterById.delete(parameter.id);
    }

    getNewDictionaryWithParameter(parameter: Parameter<any>)
    {
        return new ParameterDictionary([
            ...this.parameters,
            parameter
        ]);
    }

    getDependencies(): Dependency[]
    {
        return this.parameters
            .map(parameter => parameter.getDefaultValueDependencies())
            .reduce((a, b) => a.concat(b), []);
    }

    toDescriptor()
    {
        return this.parameters
            .map(
                parameter =>
                    parameter.toFullDescriptor());
    }

    static async fromDescriptor(descriptor: any[],
                                dependencyContext: AutomationDependencyContext)
    {
        return new ParameterDictionary(
            await Promise.all(
                descriptor.map(
                    parameter =>
                        Parameter.fromFullDescriptor(
                            parameter,
                            dependencyContext))));
    }

    static union(...parameterDictionaries: ParameterDictionary[]): ParameterDictionary
    {
        return new ParameterDictionary(
            parameterDictionaries
                .map(
                    parameterDictionary =>
                        parameterDictionary.parameters)
                .reduce((a, b) => a.concat(b), []));
    }

    get parameters()
    {
        return Array.from(this.parameterById.values());
    }

    // ----------------------- Private logic ------------------------
}
