import React, { useEffect, useMemo, useState } from 'react';
import Dialog from '../../../../../@Future/Component/Generic/Dialog/Dialog';
import DialogTitle from '../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import { observer, useComputed } from 'mobx-react-lite';
import ResourceSelection from '../Model/ResourceSelection';
import Period from '../Model/Period';
import TabBar from '../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useCombinedCounts from '../../Selection/Hooks/useCombinedCounts';
import useResults from '../../Selection/Hooks/useResults';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../@Future/Component/Generic/Loader/Loader';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import constructEntityFromFilter from '../../../../../@Api/Entity/constructEntityFromFilter';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TimesheetItem from './Item/TimesheetItem';
import { computed } from 'mobx';
import isFilteredOnFieldPath from '../../../../../@Api/Entity/isFilteredOnFieldPath';
import initializeDefaultRelationships from '../../Constructor/Api/initializeDefaultRelationships';
import moment from 'moment';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';

export interface TimeSheetDialogProps
{
    resourceSelections: ResourceSelection[];
    period: Period;
    resource: Entity;
    parameterDictionary: ParameterDictionary;
    parameterAssignment: ParameterAssignment;
    onSave?: (entity: Entity) => void;
    onClose: () => void;
}

const TimesheetDialog: React.FC<TimeSheetDialogProps> =
    props =>
    {
        const plannableResourceSelections =
            useComputed(
                () =>
                    props.resourceSelections.filter(
                        selection =>
                            selection.isPlannable),
                [
                    props.resourceSelections
                ]);

        const [ tab, setTab ] = useState(0);

        const entityTypes =
            useComputed(
                () =>
                    plannableResourceSelections.map(
                        resourceSelection =>
                            resourceSelection.entityType),
                [
                    plannableResourceSelections
                ]);

        const counts =
            useCombinedCounts(
                entityTypes,
                (idx, builder) =>
                    builder
                        .where(
                            cb =>
                                cb.overlapsWith(
                                    plannableResourceSelections[idx].startDateFieldPath,
                                    plannableResourceSelections[idx].endDateFieldPath,
                                    props.period.from.toDate(),
                                    props.period.to.toDate()))
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    plannableResourceSelections[idx].resourcePath,
                                    props.resource))
                        .if(
                            () =>
                                plannableResourceSelections[idx].filter !== undefined,
                            () =>
                                builder
                                    .where(
                                        cb =>
                                            cb.filter(
                                                plannableResourceSelections[idx].filter,
                                                {
                                                    context:
                                                        new FunctionContext(
                                                            props.parameterDictionary,
                                                            props.parameterAssignment
                                                        ),
                                                    parameter: plannableResourceSelections[idx].parameter,
                                                }
                                            )
                                    )
                        ),
                [
                    plannableResourceSelections,
                    props.period,
                    props.resource,
                    props.parameterDictionary,
                    props.parameterAssignment,
                ]);

        const visibleResourceSelections =
            useComputed(
                () =>
                {
                    if (counts)
                    {
                        return plannableResourceSelections
                            .map(
                                (r, idx) =>
                                    [ r, idx, counts[idx] ] as [ ResourceSelection, number, number ]);
                    }
                    else
                    {
                        return [];
                    }
                },
                [
                    counts,
                    plannableResourceSelections
                ]);

        const selectedResourceSelection =
            useMemo(
                () =>
                {
                    return plannableResourceSelections[tab];
                },
                [
                    tab
                ]);

        const items =
            useResults(
                selectedResourceSelection?.entityType,
                (builder) =>
                    builder
                        .if(
                            () =>
                                true,
                            sb =>
                                selectedResourceSelection.timesheetConstructorFieldPaths
                                    .forEach(
                                        constructorFieldPath =>
                                            sb.join(constructorFieldPath.path)))
                        .where(
                            cb =>
                                cb.overlapsWith(
                                    selectedResourceSelection.startDateFieldPath,
                                    selectedResourceSelection.endDateFieldPath,
                                    props.period.from.toDate(),
                                    props.period.to.toDate()))
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    selectedResourceSelection.resourcePath,
                                    props.resource))
                        .if(
                            () =>
                                selectedResourceSelection.filter !== undefined,
                            () =>
                                builder
                                    .where(
                                        cb =>
                                            cb.filter(
                                                selectedResourceSelection.filter,
                                                {
                                                    context:
                                                        new FunctionContext(
                                                            props.parameterDictionary,
                                                            props.parameterAssignment
                                                        ),
                                                    parameter: selectedResourceSelection.parameter,
                                                }
                                            )
                                    )
                        ),
                [
                    selectedResourceSelection,
                    props.period,
                    props.resource,
                    props.parameterDictionary,
                    props.parameterAssignment,
                ]);

        const [ newEntity, setNewEntity ] = useState<Entity>();

        useEffect(
            () =>
                computed(
                    () =>
                        newEntity === undefined || !newEntity.isNew())
                    .observe(
                        change =>
                        {
                            if (change.newValue)
                            {
                                const newEntity =
                                    constructEntityFromFilter(
                                        selectedResourceSelection.entityType,
                                        selectedResourceSelection.filter,
                                        props.parameterAssignment);

                                selectedResourceSelection.startDateFieldPath
                                    .setValue(
                                        newEntity,
                                        props.period.from.toDate());

                                selectedResourceSelection.endDateFieldPath
                                    .setValue(
                                        newEntity,
                                        moment(props.period.from)
                                            .add(3, 'hours')
                                            .toDate());

                                selectedResourceSelection.resourcePath.field()
                                    .setValue(
                                        newEntity,
                                        props.resource);

                                initializeDefaultRelationships(newEntity)
                                    .then(
                                        () =>
                                            setNewEntity(newEntity));
                            }
                        },
                        true),
            [
                newEntity,
                setNewEntity,
                selectedResourceSelection
            ]);

        const constructorFieldPaths =
            useComputed(
                () =>
                    selectedResourceSelection.timesheetConstructorFieldPaths
                        .filter(
                            fieldPath =>
                                !selectedResourceSelection.filter
                                    ||
                                !isFilteredOnFieldPath(
                                    fieldPath,
                                    selectedResourceSelection.filter,
                                    props.parameterDictionary,
                                    props.parameterAssignment
                                )
                        ),
                [
                    selectedResourceSelection,
                    props.parameterDictionary,
                    props.parameterAssignment
                ]
            );

        return <Dialog
            open
            width="lg"
            onClose={props.onClose}
        >
            <DialogTitle>
                <LocalizedText
                    code="TimesheetDialog.Title"
                    value="Uren beheren"
                />
            </DialogTitle>
            <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                {
                    visibleResourceSelections.length > 1 &&
                        <ViewGroupItem>
                            <TabBar
                                value={tab}
                            >
                                {
                                    visibleResourceSelections
                                        .map(
                                            ([ resourceSelection, idx, count ]) =>
                                                <Tab
                                                    value={idx}
                                                    onClick={setTab}
                                                >
                                                    {resourceSelection.entityType.getName()} ({count})
                                                </Tab>)
                                }
                            </TabBar>
                        </ViewGroupItem>
                }
                <ViewGroupItem>
                    {
                        items
                            ?
                                <>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        width: '15%'
                                                    }}
                                                >
                                                    Duratie
                                                </TableCell>
                                                {
                                                    constructorFieldPaths
                                                        .map(
                                                            constructorPath =>
                                                                <TableCell
                                                                    key={constructorPath.id}
                                                                    style={{
                                                                        width: `${75 / constructorFieldPaths.length}%`
                                                                    }}
                                                                >
                                                                    {constructorPath.getName()}
                                                                </TableCell>)
                                                }
                                                <TableCell
                                                    style={{
                                                        width: '10%'
                                                    }}
                                                />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                items.map(
                                                    item =>
                                                        <TimesheetItem
                                                            key={item.uuid}
                                                            entity={item}
                                                            resourceSelection={selectedResourceSelection}
                                                            constructorFieldPaths={constructorFieldPaths}
                                                        />)
                                            }
                                            {
                                                newEntity &&
                                                    <TimesheetItem
                                                        key={newEntity.uuid}
                                                        entity={newEntity}
                                                        resourceSelection={selectedResourceSelection}
                                                        constructorFieldPaths={constructorFieldPaths}
                                                    />
                                            }
                                        </TableBody>
                                    </Table>
                                </>
                            :
                                <Centered
                                    horizontal
                                >
                                    <Loader />
                                </Centered>
                    }
                </ViewGroupItem>
            </ViewGroup>
        </Dialog>;
    };

export default observer(TimesheetDialog);
