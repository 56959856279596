import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../Type/Api/useTypes';
import useResults from '../../../../Selection/Hooks/useResults';
import Tab from '../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import PageTabContent from './TabContent/PageTabContent';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import PageTabManager from './TabManager/PageTabManager';
import TabBar from '../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import { TabLabel } from '../../../../../../../@Future/Component/Generic/TabBar/Tab/TabLabel/TabLabel';
import ErrorBoundary from '../../../../../../Error/ErrorBoundary';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import PageTabTitle from './TabTitle/PageTabTitle';
import Tooltip from '../../../../../../../@Future/Component/Generic/Tooltip/Tooltip';

export interface TabFamily
{
    id: string;
    tabs: TabProps[];
}

interface TabProps
{
    id: string;
    name: React.ReactNode;
    tooltip?: React.ReactNode;
    nameAppendix?: React.ReactNode;
    content: (props: TabContentProps) => React.ReactNode;
    isVisible?: () => boolean;
}

export interface TabContentProps
{
    isInEditMode: boolean;
    onChangeEditMode: (isInEditMode: boolean) => void;
}

export interface PageTabBarProps
{
    entity: Entity;
    tabFamilies: TabFamily[];
    tabId: string;
    onChangeTab: (tabId: string) => void;
    commitContext: CommitContext;
}

interface FamilyAndTab
{
    family: TabFamily;
    tab: TabProps;
}

export function getTabId(family: TabFamily,
                         tab: TabProps)
{
    return `${family.id}:${tab.id}`;
}

function getFamilyAndTabFromId(id: string,
                               families: TabFamily[]): FamilyAndTab
{
    if (!id)
    {
        return undefined;
    }

    const split = id.split(':');

    for (const family of families)
    {
        if (family.id === split[0])
        {
            for (const tab of family.tabs)
            {
                if (tab.id === split[1])
                {
                    return {
                        family: family,
                        tab: tab
                    };
                }
            }
        }
    }

    return undefined;
}

function isTabVisible(tab: TabProps)
{
    return tab.isVisible === undefined || tab.isVisible();
}

const PageTabBar: React.FC<PageTabBarProps> =
    props =>
    {
        const { entity, tabFamilies, tabId, onChangeTab, commitContext } = props;

        const types = useTypes();
        const pages =
            useResults(
                types.Page.Type,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.or(
                                ob =>
                                    entity.entityType.getInheritedTypes()
                                        .forEach(
                                            inheritedType =>
                                                ob.relatedToEntity(
                                                    rootPath
                                                        .joinTo(
                                                            types.EntityType.RelationshipDefinition.Pages,
                                                            true),
                                                    inheritedType.entity)))),
                [
                    types,
                    entity
                ]);

        const [ isInEditMode, setInEditMode ] = useState(false);

        const allTabFamilies =
            useMemo(
                () => ([
                    ...tabFamilies,
                    {
                        id: 'Page',
                        tabs:
                            (pages || []).map(
                                page =>
                                    ({
                                        id: page.uuid,
                                        name:<PageTabTitle
                                                entity={entity}
                                                page={page}
                                                commitContext={commitContext}
                                            />,
                                        content:
                                            () =>
                                                <PageTabContent
                                                    entity={entity}
                                                    page={page}
                                                    isInEditMode={isInEditMode}
                                                    onChangeEditMode={setInEditMode}
                                                    commitContext={commitContext}
                                                />
                                    }))
                    } as TabFamily
                ]),
                [
                    tabFamilies,
                    pages,
                    entity,
                    isInEditMode,
                    setInEditMode,
                    commitContext,
                ]);

        const tab =
            useMemo(
                () =>
                    {
                        const tab = getFamilyAndTabFromId(tabId, allTabFamilies)?.tab;
                        if (tab)
                        {
                           return tab;
                        }
                        else
                        {
                            onChangeTab(undefined);
                            if (allTabFamilies.length > 0 && allTabFamilies[0].tabs.length > 0)
                            {
                                return allTabFamilies[0].tabs[0];
                            }
                            else
                            {
                                return undefined;
                            }
                        }
                    },
                [
                    tabId,
                    allTabFamilies,
                    onChangeTab
                ]);

        const tabContentProps =
            useMemo<TabContentProps>(
                () => ({
                    isInEditMode: isInEditMode,
                    onChangeEditMode: setInEditMode
                }),
                [
                    isInEditMode,
                    setInEditMode
                ]);

        if (pages)
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <Card>
                        <TabBar
                            value={tabId}
                            fullWidth
                            bordered={false}
                            appendix={
                                <PageTabManager
                                    entity={props.entity}
                                    onChangeTab={onChangeTab}
                                    isInEditMode={isInEditMode}
                                    onChangeEditMode={setInEditMode}
                                />
                            }
                        >
                            {
                                allTabFamilies.map(
                                    family =>
                                        family.tabs
                                            .filter(
                                                tab =>
                                                    isTabVisible(tab))
                                            .map(
                                                tab =>
                                                    <Tab
                                                        key={getTabId(family, tab)}
                                                        value={getTabId(family, tab)}
                                                        onClick={onChangeTab}
                                                    >

                                                        <Tooltip
                                                            title={tab.tooltip}
                                                        >
                                                            <TabLabel
                                                                label={tab.name}
                                                                secondary={tab.nameAppendix}
                                                            />
                                                        </Tooltip>
                                                    </Tab>
                                            )
                                )
                            }
                        </TabBar>
                    </Card>
                </ViewGroupItem>
                {
                    tab &&
                        <ViewGroupItem>
                            <ErrorBoundary>
                                {tab.content(tabContentProps)}
                            </ErrorBoundary>
                        </ViewGroupItem>
                }
            </ViewGroup>;
        }
        else
        {
            return null;
        }
    };

export default observer(PageTabBar);
