import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Selectbox from '../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import useTypes from '../../Type/Api/useTypes';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import CurrentUserContext from '../../../User/CurrentUserContext';
import useAggregateResult from '../../Selection/Hooks/useAggregateResult';
import { Aggregate } from '../../../DataObject/Model/Aggregate';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { deduplicateArray } from '../../../../../@Util/Array/deduplicateArray';
import { EntityConstraintBuilderFilterOptions } from '../../Selection/Builder/Predicate/Model/EntityConstraintBuilderFilterOptions';

export interface PackSelectorProps
{
    entityType: EntityType;
    pack?: Entity;
    onChange: (pack: Entity) => void;
    filter?: Predicate;
    filterOptions?: EntityConstraintBuilderFilterOptions;
}

const PackSelector: React.FC<PackSelectorProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);
        const countByPathResult =
            useAggregateResult(
                currentUserStore.ownedPackEntities.length > 1 ? props.entityType : undefined,
                (builder, rootPath) =>
                    builder
                        .if(
                            () => props.filter !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.filter(
                                            props.filter,
                                            props.filterOptions
                                        )
                                )
                        )
                        .groupBy(
                            rootPath
                                .joinTo(
                                    types.Pack.RelationshipDefinition.Entities,
                                    true)
                                .field()
                        )
                        .aggregateOn(
                            rootPath.field(types.Entity.Field.Id),
                            undefined,
                            Aggregate.Count
                        ),
                [
                    props.filter,
                    props.filterOptions,
                    types
                ]
            );
        const countByPath =
            useComputed(
                () =>
                {
                    if (countByPathResult)
                    {
                        return new Map(
                            countByPathResult.children.map(
                                child => [
                                    child.groupEntity?.uuid,
                                    child.aggregates[0]
                                ]
                            )
                        );
                    }
                    else
                    {
                        return new Map();
                    }
                },
                [
                    countByPathResult
                ]
            );
        const loader =
            useCallback(
                async () =>
                    deduplicateArray(
                        [
                            ...countByPathResult?.children.map(
                                child =>
                                    child.groupEntity
                            ).filter(identity => identity !== undefined),
                            ...currentUserStore.ownedPackEntities,
                        ],
                        entity =>
                            entity.uuid
                    ),
                [
                    countByPathResult,
                    currentUserStore,
                ]
            );
        const formatter =
            useCallback(
                (pack: Entity) =>
                    // pack.name,
                    `${pack.name} ${countByPath.has(pack.uuid) ? `(${countByPath.get(pack.uuid).toString()})` : ''}`,
                [
                    countByPath
                ]
            );
        const idResolver =
            useCallback(
                (pack: Entity) =>
                    pack.uuid,
                []
            );

        if (countByPathResult)
        {
            return <Selectbox
                load={loader}
                onChange={props.onChange}
                formatOption={formatter}
                idResolver={idResolver}
                value={props.pack}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(PackSelector);
