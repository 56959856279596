import { EntityContext } from '../../../../@Model/EntityContext';
import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import { ContextVariable } from '../../../../@Model/ContextVariable';
import PrimitiveValueType from '../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { RootEntityContextParameterId } from './EntityContextParameterConstants';

export function getParametersFromEntityContext(context: EntityContext): ParameterDictionary
{
    const parameters: Parameter<any>[] = [];
    parameters.push(
        getParameterFromEntityContext(RootEntityContextParameterId, context)
    );

    context.contextByParameter
        .forEach(
            (subContext, parameterId) =>
                parameters.push(
                    getParameterFromEntityContext(
                        parameterId,
                        subContext
                    )
                )
        );

    context.variables
        .forEach(
            variable =>
                parameters.push(
                    getParameterFromVariable(variable)
                )
        );

    return new ParameterDictionary(parameters);
}

function getParameterFromEntityContext(parameterId: string,
                                       context: EntityContext): Parameter<EntityValueType>
{
    return new Parameter(
        parameterId,
        new EntityValueType(context.entityPath.entityType),
        false,
        context.alias ?? context.entityPath.entityType.getName()
    );
}

function getParameterFromVariable(variable: ContextVariable)
{
    return new Parameter(
        variable.variable,
        new PrimitiveValueType(DataObject.getTypeById('Text')),
        false,
        variable.name
    );
}
