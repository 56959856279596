import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Dialog from '../../../../../../@Future/Component/Generic/Dialog/Dialog';
import DialogTitle from '../../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import DialogActions from '../../../../../../@Future/Component/Generic/Dialog/Actions/DialogActions';
import Item from './Item/Item';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';
import EntityTypeContext from '../../../Type/EntityTypeContext';
import { FileValue, FileValueType } from '../../../../DataObject/Type/File/FileValue';
import styles from './AttachmentDialog.module.scss';
import SaveButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import processImageFile from '../../../../../../@Future/Component/Generic/Input/FileDropZone/ProcessImageFile';
import validateEntity from '../../../../../../@Api/Entity/Validation/validateEntity';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import { EntityValidationError } from '../../../../../../@Api/Entity/Validation/EntityValidationResult';
import { useNewCommitContext } from '../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { constructEntityOfType } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { setValueByFieldInEntity } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface AttachmentDialogClasses
{
    root?: string;
}

export interface AttachmentDialogProps
{
    files: File[];
    entity: Entity;
    classes?: AttachmentDialogClasses;
    onAttachmentAdded?: (attachment: Entity) => void;
    onReset: () => void;
}

const AttachmentDialog: React.FC<AttachmentDialogProps> =
    props =>
    {
        const types = useTypes();

        const commitContext = useNewCommitContext();
        const entityTypeContext = useContext(EntityTypeContext);
        const [ isOpen, setOpen ] = useState(false);
        const [ attachments, setAttachments] = useState<Entity[]>([]);

        const dividerGlue = useDividerGlue();

        useEffect(
            () =>
            {
                Promise.all(
                    props.files
                        .map(
                            file =>
                                processImageFile(file)))
                    .then(
                        files =>
                        {
                            // Create attachments from file
                            setAttachments(
                                files
                                    .map(
                                        file =>
                                        {
                                            // Create attachment entity
                                            const attachmentEntity =
                                                constructEntityOfType(
                                                    types.Attachment.Type,
                                                    commitContext
                                                );

                                            // Add relationship to parent
                                            updateRelationship(
                                                attachmentEntity,
                                                true,
                                                types.Entity.RelationshipDefinition.Attachments,
                                                props.entity,
                                                commitContext
                                            );

                                            // Set attachment file
                                            setValueByFieldInEntity(
                                                attachmentEntity,
                                                types.Attachment.Field.File,
                                                new FileValue(
                                                    FileValueType.File,
                                                    file.name,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    file.type,
                                                    file,
                                                    file.size
                                                ),
                                                commitContext
                                            );

                                            if (props.onAttachmentAdded)
                                            {
                                                props.onAttachmentAdded(attachmentEntity);
                                            }

                                            return attachmentEntity;
                                        }));
                        });

                setOpen(props.files.length > 0);
            },
            [
                props.entity,
                props.files,
                entityTypeContext,
                types,
                commitContext,
            ]);

        const multipleFiles =
            useMemo(
                () => attachments.length > 1,
                [
                    attachments
                ]);

        const closeCallback =
            useCallback(
                () =>
                {
                    props.onReset();
                    setOpen(false);
                },
                [
                    setOpen,
                    props.onReset
                ]);

        const saveCallback =
            useCallback(
                async () =>
                {
                    if (attachments.length > 0)
                    {
                        let isValid = true;
                        let messages: EntityValidationError[] = []

                        attachments.forEach(
                            attachment =>
                            {
                                const validationResult = validateEntity(attachment, { commitContext });
                                messages = messages.concat(validationResult.messages);
                                isValid = validationResult.isValid;
                            });

                        if (isValid)
                        {
                            return commitEntityWithContext(
                                props.entity,
                                commitContext
                            ).then(closeCallback);
                        }
                        else
                        {
                            for (const message of messages)
                            {
                                loadModuleDirectly(FeedbackStore)
                                    .enqueueSnackbar(
                                        message.message,
                                        {
                                            variant: 'error'
                                        });
                            }

                            return Promise.resolve();
                        }
                    }
                    else
                    {
                        closeCallback();
                    }
                },
                [
                    props.entity,
                    commitContext,
                    attachments,
                    closeCallback,
                ]);

        return <Dialog
            onClose={closeCallback}
            open={isOpen}
        >
            <DialogTitle>
                {
                    multipleFiles
                        ? <LocalizedText
                            code="AttachmentDialog.AddFiles"
                            value="Bestanden toevoegen"
                        />
                        : <LocalizedText
                            code="AttachmentDialog.AddFile"
                            value="Bestand toevoegen"
                        />
                }
            </DialogTitle>
            {
                multipleFiles
                    ?
                    <ViewGroup
                        orientation="vertical"
                        spacing={16}
                        className={styles.itemsWrapper}
                        glue={dividerGlue}
                    >
                        {
                            attachments.map(
                                attachment =>
                                    <ViewGroupItem
                                        key={attachment.uuid}
                                    >
                                        <Item
                                            attachment={attachment}
                                            list
                                            commitContext={commitContext}
                                        />
                                    </ViewGroupItem>)
                        }
                    </ViewGroup>
                    :
                    attachments.length > 0 &&
                    <div
                        className={styles.itemsWrapper}
                    >
                        <Item
                            attachment={attachments[0]}
                            commitContext={commitContext}
                        />
                    </div>
            }
            <DialogActions>
                <SaveButton
                    onClick={saveCallback}
                />
            </DialogActions>
        </Dialog>;
    };

export default AttachmentDialog;
