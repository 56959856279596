import { reference, type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';
import { EntityType } from './EntityType';
import { EntityMetadataMutation } from './EntityMetadataMutation';

@type('EntityTypeMutation')
export class EntityTypeMutation extends EntityMetadataMutation
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'EntityTypeMutation') @observable.ref type: EntityType;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
