import ParameterAssignment from '../Parameter/ParameterAssignment';

export default function areParameterAssignmentsEqual(
    a?: ParameterAssignment,
    b?: ParameterAssignment
): boolean
{
    if (a === b)
    {
        return true;
    }
    else if (a !== undefined && b !== undefined)
    {
        return a.equals(b);
    }
    else
    {
        return false;
    }
}
