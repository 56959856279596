import React, { useEffect, useMemo, useState } from 'react';
import styles from './GutenbergBlockEditor.module.scss';
import { BlockEditorProvider, BlockInspector, BlockList, BlockTools, ObserveTyping, SETTINGS_DEFAULTS, WritingFlow } from '@wordpress/block-editor';
import { Popover, SlotFillProvider } from '@wordpress/components';
import { ShortcutProvider } from '@wordpress/keyboard-shortcuts';
import '@wordpress/editor';
import '@wordpress/format-library';
import '@wordpress/components/build-style/style.css';
import '@wordpress/block-editor/build-style/style.css';
import '@wordpress/block-library/build-style/style.css';
import '@wordpress/block-library/build-style/editor.css';
import '@wordpress/block-library/build-style/theme.css';
import '@wordpress/format-library/build-style/style.css';
import ViewGroup from '../../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../../ViewGroup/ViewGroupItem';
import Card from '../../../Card/Card';
import { getBlockTypes, registerBlockType, unregisterBlockType } from '@wordpress/blocks';
import { registerCoreBlocks } from '@wordpress/block-library';
import { CoreBlockType } from './DefaultBlockType';
import { classNames } from '../../../../../Util/Class/classNames';
import { observer } from 'mobx-react-lite';
import GutenbergBlockType from './GutenbergBlockType';
import GutenbergBlockExpression from '../Blocks/Type/GutenbergBlockExpression';
import GutenbergBlockImage from '../Blocks/Type/GutenbergBlockImage';
import OrganizationLogoBlock from '../../../../../../@Component/Domain/Entity/Document/Block/OrganizationLogo/OrganizationLogoBlock';
import {
    BlockTypeId,
    DocumentDefinition,
    DocumentLayoutDefinition,
    EntityImageBlockId,
    FormFieldBlockId,
    FormSubmitButtonBlockId,
    GutenbergBlockExpressionId,
    GutenbergBlockHtmlId,
    GutenbergBlockImageId,
    GutenbergBlockPageBreakId,
    GutenbergBlockTemplateId,
    GutenbergBlockTextId,
    GutenbergBlockVideoId,
    GutenbergCoreBlockColumnId,
    GutenbergCoreBlockColumnsId,
    GutenbergCoreBlockGroupId,
    GutenbergCoreBlockHeadingId,
    GutenbergCoreBlockSpacerId,
    GutenbergCoreBlockTextId,
    GutenbergBlockButtonId,
    LayoutBlockId,
    MileageRegistrationsBlockId,
    OrganizationLogoBlockId,
    ProductLinesBlockId,
    TimeRegistrationsBlockId,
} from '../DocumentEditor';
import GutenbergBlockPageBreak from '../Blocks/Type/GutenbergBlockPageBreak';
import GutenbergBlockText from '../Blocks/Type/GutenbergBlockText';
import ProductLinesBlock from '../../../../../../@Component/Domain/Entity/Document/Block/ProductLines/ProductLinesBlock';
import useIsMobile from '../../../../../../@Util/Responsiveness/useIsMobile';
import ErrorBoundary from '../../../../../../@Component/Error/ErrorBoundary';
import TimeRegistrationsBlock from '../../../../../../@Component/Domain/Entity/Document/Block/TimeRegistrations/TimeRegistrationsBlock';
import FormFieldBlock from '../../../../../../@Component/Domain/Entity/Form/Block/Field/FormFieldBlock';
import GutenbergCoreBlockText from '../Blocks/Type/Core/GutenbergCoreBlockText';
import GutenbergCoreBlockHeading from '../Blocks/Type/Core/GutenbergCoreBlockHeading';
import FormSubmitButtonBlock from '../../../../../../@Component/Domain/Entity/Form/Block/SubmitButton/FormSubmitButtonBlock';
import GutenbergCoreBlockColumn from '../Blocks/Type/Core/GutenbergCoreBlockColumn';
import GutenbergCoreBlockColumns from '../Blocks/Type/Core/GutenbergCoreBlockColumns';
import GutenbergCoreBlockGroup from '../Blocks/Type/Core/GutenbergCoreBlockGroup';
import EntityImageBlock from '../../../../../../@Component/Domain/Entity/Document/Block/EntityImage/EntityImageBlock';
import GutenbergCoreBlockSpacer from '../Blocks/Type/Core/GutenbergCoreBlockSpacer';
import GutenbergBlockHtml from '../Blocks/Type/GutenbergBlockHtml';
import GutenbergBlockVideo from '../Blocks/Type/GutenbergBlockVideo';
import GutenbergBlockTemplate from '../Blocks/Type/Template/GutenbergBlockTemplate';
import DocumentLayoutControls from './Controls/DocumentLayoutControls';
import InserterSidebar from './Inserter/inserter-sidebar';
import './Inserter/style.scss';
import LayoutBlock from '../../../../../../@Component/Domain/Entity/Document/Block/Layout/LayoutBlock';
import MileageRegistrationsBlock from '../../../../../../@Component/Domain/Entity/Document/Block/MileageRegistrations/MileageRegistrationsBlock';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import { useIsNotLgScreen } from '../../../../../../@Util/Responsiveness/useIsNotLgScreen';
import GutenbergBlockButton from '../Blocks/Type/GutenbergBlockButton';

export const BlockTypeById = new Map<BlockTypeId, GutenbergBlockType>();
BlockTypeById.set(GutenbergCoreBlockTextId, GutenbergCoreBlockText);
BlockTypeById.set(GutenbergCoreBlockHeadingId, GutenbergCoreBlockHeading);
BlockTypeById.set(GutenbergCoreBlockColumnId, GutenbergCoreBlockColumn);
BlockTypeById.set(GutenbergCoreBlockColumnsId, GutenbergCoreBlockColumns);
BlockTypeById.set(GutenbergCoreBlockGroupId, GutenbergCoreBlockGroup);
BlockTypeById.set(GutenbergCoreBlockSpacerId, GutenbergCoreBlockSpacer);

BlockTypeById.set(GutenbergBlockExpressionId, GutenbergBlockExpression);
BlockTypeById.set(GutenbergBlockImageId, GutenbergBlockImage);
BlockTypeById.set(GutenbergBlockVideoId, GutenbergBlockVideo);
BlockTypeById.set(GutenbergBlockPageBreakId, GutenbergBlockPageBreak);
BlockTypeById.set(GutenbergBlockTextId, GutenbergBlockText);
BlockTypeById.set(GutenbergBlockHtmlId, GutenbergBlockHtml);
BlockTypeById.set(GutenbergBlockTemplateId, GutenbergBlockTemplate);
BlockTypeById.set(GutenbergBlockButtonId, GutenbergBlockButton);

BlockTypeById.set(OrganizationLogoBlockId, OrganizationLogoBlock);
BlockTypeById.set(EntityImageBlockId, EntityImageBlock);
BlockTypeById.set(ProductLinesBlockId, ProductLinesBlock);
BlockTypeById.set(TimeRegistrationsBlockId, TimeRegistrationsBlock);
BlockTypeById.set(MileageRegistrationsBlockId, MileageRegistrationsBlock);
BlockTypeById.set(LayoutBlockId, LayoutBlock);
BlockTypeById.set(FormFieldBlockId, FormFieldBlock);
BlockTypeById.set(FormSubmitButtonBlockId, FormSubmitButtonBlock);

const globalProhibitedTypes: CoreBlockType[] = [];

export function createGutenbergDocumentDefinition(): DocumentDefinition
{
    return {
        type: 'gutenberg',
        definition: [],
        layout: {
            width: 100,
            widthUnit: 'Percentage'
        }
    };
}

export interface GutenbergBlockEditorProps
{
    blockTypes?: BlockTypeId[];
    blocks?: any[];
    layout?: DocumentLayoutDefinition;
    onChange: (blocks: any[], layout: DocumentLayoutDefinition) => void;
    cardView?: boolean;
    allowedDefaultBlockTypes?: CoreBlockType[];
    prohibitedDefaultBlockTypes?: CoreBlockType[];
    addCoreBlockTypes?: boolean;
    editLayoutMode?: boolean;
    documentLayoutControls?: boolean;
}

const GutenbergBlockEditor: React.FC<GutenbergBlockEditorProps> =
    props =>
    {
        const { onChange } = props;
        const [ blocks, updateBlocks ] = useState<any>(props.blocks ? props.blocks : []);
        const [ insertionPoint, setInsertionPoint ] = useState<any>(undefined);
        const [ layout, updateLayout ] =
            useState<any>(
                props.layout
                    ? props.layout
                    : { width: 100, widthUnit: 'Percentage' });
        const [ isNoBlockSelectedVisible, setNoBlockSelectedVisible ] = useState(true);
        const [ ref, setRef ] = useState<HTMLDivElement | undefined>(undefined);
        const isNotLgScreen = useIsNotLgScreen();

        const blockTypes =
            useMemo(
                () =>
                    props.blockTypes?.map(
                        typeId =>
                            BlockTypeById.get(typeId)),
                [
                    props.blockTypes
                ]);

        useEffect(
            () =>
            {
                // Unregister currently registered block types
                // We have to do this because block types are registered by Gutenberg into the Window scope.
                // Unfortunately this allows Gutenberg to be initialized on a page only once at a time.
                getBlockTypes().forEach(
                    type =>
                        unregisterBlockType(type.name));

                const translateAndRegisterBlock =
                    (type: GutenbergBlockType) =>
                    {
                        let translatedType = type;

                        if (typeof type.configuration.title === 'object'
                            && type.configuration.title.code !== undefined
                            && type.configuration.title.value !== undefined)
                        {
                            translatedType = {
                                ...type,
                                configuration: {
                                    ...type.configuration,
                                    title:
                                        localizeText(
                                            type.configuration.title.code,
                                             type.configuration.title.value
                                        )
                                },
                            };
                        }

                        registerBlockType(
                            translatedType.name,
                             translatedType.configuration
                        );
                    };

                if (props.addCoreBlockTypes)
                {
                    // Register core blocks if they were not already registered
                    registerCoreBlocks();

                    // Unregister prohibited block types
                    getBlockTypes()
                        .forEach(
                            blockType =>
                            {
                                let isUnregistered = false;

                                if (!blockTypes || (blockTypes && !blockTypes.find(customBlockType => customBlockType.name === blockType.name)))
                                {
                                    if (props.allowedDefaultBlockTypes && !props.allowedDefaultBlockTypes.includes(blockType.name as any))
                                    {
                                        unregisterBlockType(blockType.name);
                                        isUnregistered = true;
                                    }

                                    if (!isUnregistered && props.prohibitedDefaultBlockTypes && props.prohibitedDefaultBlockTypes.includes(blockType.name as any))
                                    {
                                        unregisterBlockType(blockType.name);
                                    }
                                    else if (!isUnregistered && globalProhibitedTypes.includes(blockType.name))
                                    {
                                        unregisterBlockType(blockType.name);
                                    }
                                }
                            });
                }

                [
                    GutenbergCoreBlockHeading,
                    GutenbergCoreBlockColumn,
                    GutenbergCoreBlockColumns,
                    GutenbergCoreBlockGroup
                ].forEach(
                    coreBlock =>
                    {
                        unregisterBlockType(coreBlock.name);
                        translateAndRegisterBlock(coreBlock);
                    }
                );

                // Register given block types
                if (blockTypes)
                {
                    blockTypes.map(
                        type =>
                            translateAndRegisterBlock(type)
                    );
                }
            },
            [
                props.addCoreBlockTypes,
                props.allowedDefaultBlockTypes,
                props.prohibitedDefaultBlockTypes,
                blockTypes
            ]);

        useEffect(
            () =>
            {
                onChange(blocks, layout);
            },
            [
                blocks,
                layout,
                props.allowedDefaultBlockTypes,
                props.prohibitedDefaultBlockTypes,
                onChange
            ]);

        useEffect(
            () =>
            {
                if (ref)
                {
                    const isNoBlockSelectedInNodeList =
                        (nodes: NodeList) =>
                            Array.from(nodes).some(
                                node =>
                                {
                                    if (node instanceof Element)
                                    {
                                        return node.className.indexOf('block-editor-block-inspector__no-blocks') >= 0;
                                    }
                                    else
                                    {
                                        return false;
                                    }
                                }
                            );

                    setNoBlockSelectedVisible(isNoBlockSelectedInNodeList(ref.childNodes));

                    const observer =
                        new MutationObserver(
                            (mutationList) =>
                            {
                                const isNoBlockSelectedAdded =
                                    mutationList.some(
                                        mutation =>
                                            isNoBlockSelectedInNodeList(mutation.addedNodes)
                                    );

                                const isNoBlockSelectedRemoved =
                                    mutationList.some(
                                        mutation =>
                                            isNoBlockSelectedInNodeList(mutation.removedNodes)
                                    );

                                if (isNoBlockSelectedAdded)
                                {
                                    setNoBlockSelectedVisible(true);
                                }
                                else if (isNoBlockSelectedRemoved)
                                {
                                    setNoBlockSelectedVisible(false);
                                }
                            }
                        );

                    observer.observe(
                        ref,
                        {
                            childList: true
                        }
                    );

                    return () => observer.disconnect();
                }
            },
            [
                setNoBlockSelectedVisible,
                ref
            ]);

        const layoutWidth =
            useMemo(
                () =>
                    layout.widthUnit === 'Percentage'
                        ?
                            layout.width + '%'
                        :
                            layout.width ?? '100%',
                [
                    layout.width,
                    layout.widthUnit
                ]);

        const playGround =
            <div
                className="editor-styles-wrapper"
            >
                {/*<BlockEditorKeyboardShortcuts />*/}
                <WritingFlow>
                    <ObserveTyping>
                        <div
                            style={{
                                textAlign: 'center',
                                backgroundColor: layout?.backgroundColor
                            }}
                        >
                            <div
                                style={{
                                    width: layoutWidth,
                                    display: 'inline-block',
                                    textAlign: 'left'
                                }}
                            >
                                <BlockList />
                            </div>
                        </div>
                    </ObserveTyping>
                </WritingFlow>
            </div>;

        useEffect(
            () =>
            {
                SETTINGS_DEFAULTS.__experimentalSetIsInserterOpened = setInsertionPoint;
                SETTINGS_DEFAULTS.disableCustomColors = false;
            },
            [
                setInsertionPoint
            ]
        );

        const isMobile = useIsMobile();
        const sidebar =
            <div
                ref={setRef}
            >
                {
                    insertionPoint
                        ?
                            <InserterSidebar
                                insertionPoint={insertionPoint}
                                onClose={() => setInsertionPoint(undefined)}
                            />
                        :
                            <BlockInspector />
                }
            </div>;

        return <div
            className={
                classNames(
                    styles.root,
                    !props.editLayoutMode && styles.hideLayoutControls)
            }
        >
            <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                {
                    props.documentLayoutControls &&
                        <ViewGroupItem>
                            <DocumentLayoutControls
                                layout={layout}
                                onChange={updateLayout}
                            />
                        </ViewGroupItem>
                }
                <ViewGroupItem>
                    <ErrorBoundary>
                        <BlockEditorProvider
                            value={blocks}
                            onInput={updateBlocks}
                            onChange={updateBlocks}
                        >
                            <SlotFillProvider>
                                <ShortcutProvider>
                                    <BlockTools>
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={10}
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                    className={styles.playGround}
                                                >
                                                    {
                                                        props.cardView
                                                            ?
                                                                <Card
                                                                    inset={!isMobile}
                                                                >
                                                                    {playGround}
                                                                </Card>
                                                            :
                                                                playGround
                                                    }
                                                    <Popover.Slot />
                                                </ViewGroupItem>
                                                {
                                                    props.editLayoutMode &&
                                                        <ViewGroupItem>
                                                            <div
                                                                className={
                                                                    classNames(
                                                                        styles.playgroupSidebar,
                                                                        (isNoBlockSelectedVisible && !insertionPoint) && styles.noSidebar,
                                                                        isNotLgScreen && styles.smallScreen
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    props.cardView
                                                                        ?
                                                                            <Card>
                                                                                {sidebar}
                                                                            </Card>
                                                                        :
                                                                            sidebar
                                                                }
                                                            </div>
                                                        </ViewGroupItem>
                                                }
                                            </ViewGroup>
                                    </BlockTools>
                                </ShortcutProvider>
                            </SlotFillProvider>
                        </BlockEditorProvider>
                    </ErrorBoundary>
                </ViewGroupItem>
            </ViewGroup>
        </div>;
    };

GutenbergBlockEditor.defaultProps = {
    cardView: true
};

export default observer(GutenbergBlockEditor);
