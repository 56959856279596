import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import constructEntity from '../../../../../../@Api/Entity/constructEntity';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { constructEntityFromPath } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityFromPath';

export default function constructRelatedEntity(fromEntity: Entity,
                                               path: EntityPath,
                                               onSave?: (entity: Entity) => void,
                                               allowOpen: boolean = true,
                                               onOpen?: (entity: Entity) => void,
                                               fieldPathFilter?: (fieldPath: EntityFieldPath) => boolean,
                                               commitContext?: CommitContext)
{
    const entity =
        constructEntityFromPath(
            fromEntity,
            path,
            commitContext
        );

    return constructEntity(
        path.entityType,
        undefined,
        entity,
        onSave,
        allowOpen,
        onOpen,
        fieldPathFilter,
        undefined,
        commitContext
    );
}
