import * as React from 'react';
import { observer } from 'mobx-react';
import { TabBarStore } from './TabBarStore';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { Tab, Tabs } from '@material-ui/core';

@observer
export class TabBar extends BaseComponent<TabBarStore>
{
    renderComponent(store: TabBarStore)
    {
        return <div>
            <Tabs
                value={store.selectedTabIndex}
                onChange={store.tabSelected}
                indicatorColor="primary"
                variant={store.isFullWidth ? 'fullWidth' : undefined}
                style={{
                    borderBottom: store.showBorder ? '1px solid #ddd' : undefined
                }}
            >
                {
                    store.tabs
                        .filter(
                            tab =>
                                tab.isVisible ? tab.isVisible() : true)
                        .map(
                            (tab, index) =>
                                <Tab
                                    key={index}
                                    label={tab.label}
                                    style={{
                                        textTransform: 'capitalize',
                                        fontSize: 15,
                                        minWidth: 'fit-content',
                                    }}
                                />)
                }
            </Tabs>
        </div>;
    }
}
