import useLocalSetting from './useLocalSetting';
import { useCallback, useEffect, useMemo } from 'react';
import { DataObject } from '../../DataObject/Model/DataObject';
import { computed } from 'mobx';

export default function useLocalPrimitiveSetting(code: string,
                                                 initialState?: DataObject): [ DataObject, (dataObject?: DataObject) => void ]
{
    const initialStateDescriptor =
        useMemo(
            () =>
                initialState?.descriptor(),
            [
                initialState
            ]);
    const [ descriptor, setDescriptor ] = useLocalSetting(code, initialStateDescriptor);

    const setValue =
        useCallback(
            (dataObject?: DataObject) =>
            {
                setDescriptor(dataObject?.descriptor());
            },
            [
                setDescriptor
            ]);
    const value =
        useMemo(
            () =>
            {
                if (descriptor)
                {
                    return DataObject.constructFromDescriptor(descriptor);
                }
                else
                {
                    return undefined;
                }
            },
            [
                descriptor
            ]);

    useEffect(
        () =>
        {
            if (value)
            {
                return computed(
                    () =>
                        value.value)
                    .observe(
                        change =>
                        {
                            setDescriptor(value?.descriptor());
                        });
            }
        },
        [
            value,
            setDescriptor
        ]);

    return [
        value,
        setValue
    ];
}
