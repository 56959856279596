import * as React from 'react';
import { observer } from 'mobx-react';
import { RichTextEditor } from '../../../Generic/RichText/Editor/RichTextEditor';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@material-ui/core';
import { Selectbox as SelectBoxOld } from '../../../Generic/SelectBox/Selectbox';
import { ComputationEditor } from '../../Computation/ComputationEditor';
import { ExpressionEditorStore } from './ExpressionEditorStore';
import CollapsibleToolbar from '../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/CollapsibleToolbar';
import EditorButton from '../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorCommandButton';
import EditorFontSelector from '../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorFontSelector';
import EditorColorPicker from '../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorColorPicker';
import RichtextEditor from '../../../../@Future/Component/Generic/Input/RichtextEditor/RichtextEditor';
import { ToolbarButton } from '../../../Generic/RichText/Toolbar/Types/Button/ToolbarButton';
import DefaultToolbarButtons from '../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/DefaultToolbarButtons';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';

@observer
export class ExpressionEditor extends RichTextEditor
{
    renderComponent(store: ExpressionEditorStore)
    {
        let typeOptions =
            store.computationTypeStore.types
                .filter(type => type.allow(store.context))
                .map(type => (
                {
                    label: type.name(),
                    value: type
                }));

        let selectedTypeOption = typeOptions.find(
            option => (store.computationEditorStore && store.computationEditorStore.type === option.value));

        return <div
            onClick={
                () =>
                    store.registerBlot()
            }
        >
            {
                // Old component, now replaced by new Hooks editor. Leave this here for comparison and test purposes.
                // super.renderComponent(store)
            }
            <RichtextEditor
                editorRef={ref => store.setQuillReference(ref)}
                placeholder={store.placeholder}
                value={store.content}
                onChange={content => store.setRichTextContent(content as string, false)}
                hideToolbarWhenEmpty={store.hideToolbarWhenEmpty}
                allowTextStyling={store.allowTextStyling}
                showToolbarOnFocus={store.showToolbarOnFocus || store.insertEntityFieldPathToolbarButtonStore.buttonStore.popperViewOpen}
                onChangeSelection={store.updateCursorPosition}
                onKeyDown={() => store.updateCursorPosition(true)}
                singleLine={store.isSingleLine}
            >
                <CollapsibleToolbar>
                    {
                        store.allowTextStyling &&
                            <>
                                <DefaultToolbarButtons />

                                <EditorButton
                                    icon="link"
                                    tooltip={
                                        <LocalizedText
                                            code="ComputationEditor.Link"
                                            value="Link"
                                        />
                                    }
                                    command="link"
                                />
                                <EditorFontSelector />
                                <EditorColorPicker
                                    tooltip={
                                        <LocalizedText
                                            code="ComputationEditor.SelectColor"
                                            value="Selecteer kleur"
                                        />
                                    }
                                />
                            </>
                    }
                    {
                        !store.hideFieldButton &&
                            <ToolbarButton store={store.insertEntityFieldPathToolbarButtonStore} />
                    }
                    {
                        !store.hideFieldButton &&
                            <ToolbarButton store={store.insertComputationToolbarButtonStore} />
                    }
                </CollapsibleToolbar>
            </RichtextEditor>
            {
                store.computationEditorStore &&
                    <Dialog
                        open={true}
                        onClose={store.closeComputationEditor}
                        fullWidth
                    >
                        <DialogTitle>
                            <LocalizedText
                                code="ExpressionEditor.DialogTitle"
                                value="Variabele waarde"
                            />
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <SelectBoxOld
                                        label={store.localizationStore.translate('Generic.Type')}
                                        options={typeOptions as any}
                                        value={selectedTypeOption as any}
                                        onChange={option => (store.computationEditorStore.setType((option as any).value))}
                                        clearable={false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper
                                        style={{ background: 'whitesmoke', padding: 15 }}
                                        elevation={0}
                                    >
                                        <ComputationEditor store={store.computationEditorStore} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="primary"
                                onClick={store.closeComputationEditor}
                            >
                                {
                                    store.localizationStore.translate('Generic.Ready') // Ready
                                }
                            </Button>
                        </DialogActions>
                    </Dialog>
            }
        </div>;
    }

}
