import { observable } from 'mobx';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import FieldMapping from '../FieldMapping';
import Computation from '../../../../../../../@Api/Automation/Function/Computation/Computation';
import getComputationFromDescriptor from '../../../../../../../@Api/Automation/Api/getComputationFromDescriptor';
import AutomationDependencyContext from '../../../../../../../@Api/Automation/AutomationDependencyContext';

export default class ComputationFieldMapping extends FieldMapping
{
    // ------------------------- Properties -------------------------

    @observable.ref computation: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(
        id: string,
        targetFieldPath: EntityFieldPath,
        computation: Computation<any, any>
    )
    {
        super(id, targetFieldPath);

        this.computation = computation;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext,
    )
    {
        const targetFieldPath = EntityFieldPath.construct(descriptor.targetFieldPath);

        return new ComputationFieldMapping(
            descriptor.id,
            targetFieldPath,
            await getComputationFromDescriptor(
                descriptor.computation,
                dependencyContext
            )
        );
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Computation',
            computation: this.computation.toDescriptor(),
        };
    }

    // ----------------------- Private logic ------------------------
}
