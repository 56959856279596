import { ContentProps } from '../../Content';
import React, { useCallback, useMemo } from 'react';
import Base from '../../Base/Base';
import ViewGroup from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../../../../../../../../Type/Api/useTypes';
import useFetchedRelatedEntity from '../../../../../../../../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity';
import useEntityValue from '../../../../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import EntityLink from '../../../../../../../../../../Link/EntityLink';

import { format } from 'date-fns';
import { ApsisCampaignResultChartClicked } from './Charts/ApsisCampaignResultChartClicked';
import { ApsisCampaignResultChartBounced } from './Charts/ApsisCampaignResultChartBounced';
import { ApsisCampaignResultChartViewed } from './Charts/ApsisCampaignResultChartViewed';

export interface ApsisCampaignResultProps extends ContentProps
{

}

export const ApsisCampaignResult: React.FC<ApsisCampaignResultProps> =
    props  =>
    {
        const types = useTypes();

        const [ apsisCampaign ] = useFetchedRelatedEntity(
            props.entity,
            types.Activity.ApsisCampaign.RelationshipDefinition.Results,
            true
        );

        const sendDate =
            useEntityValue<Date>(
                props.entity,
                types.Activity.ApsisCampaignResult.Field.SendDate
            );

        const glue =
            useCallback(
                () =>
                    `·`,
                [
                ]);

        const formattedDate =
            useMemo(
                () =>
                    sendDate !== undefined
                        ? format(new Date(sendDate), 'dd-MM-yyyy HH:mm:ss')
                        : undefined,
                [
                    sendDate
                ]
            );

        return <Base
            {...props}
            expand
            noInputGroup
        >
            <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={10}
                        glue={glue}
                    >
                        <ViewGroupItem>
                            {
                                apsisCampaign &&
                                <EntityLink
                                    stopPropagation
                                    entity={apsisCampaign}
                                />
                            }
                        </ViewGroupItem>
                        {
                            <ViewGroupItem>
                                {
                                    formattedDate
                                }
                            </ViewGroupItem>
                        }
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                    >
                        <ViewGroupItem>
                            <ApsisCampaignResultChartViewed
                                size={100}
                                apsisCampaignResult={props.entity}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ApsisCampaignResultChartClicked
                                size={100}
                                apsisCampaignResult={props.entity}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ApsisCampaignResultChartBounced
                                size={100}
                                apsisCampaignResult={props.entity}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
            </ViewGroup>
        </Base>
    }