import { useEffect, useState } from 'react';
import uuid from '../../../@Util/Id/uuid';

const delayTimeoutById = new Map<string, any>();

export function useDelayedValue<T>(
    value: T,
    timeout = 800
): T
{
    const [ delayedValue, setDelayedValue ] = useState(value);
    const [ id ] = useState(() => uuid());

    useEffect(
        () =>
        {
            if (delayTimeoutById.has(id))
            {
                clearTimeout(delayTimeoutById.get(id));
            }

            delayTimeoutById.set(
                id,
                setTimeout(
                    () =>
                    {
                        delayTimeoutById.delete(id);
                        setDelayedValue(value);
                    },
                    timeout
                )
            );
        },
        [
            id,
            setDelayedValue,
            value,
            timeout
        ]
    );

    return delayedValue;
}
