import * as React from 'react';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { DragAndDropBucketStore } from './DragAndDropBucketStore';
import { observer } from 'mobx-react';
import { Button, Icon } from '@material-ui/core';
import { action } from 'mobx';
import { ColorUtils } from '../../../../../@Util/Color/ColorUtils';

@observer
export class DragAndDropBucket extends BaseComponent<DragAndDropBucketStore<any>>
{
    renderComponent(store: DragAndDropBucketStore<any>)
    {
        return <Button
            style={
            {
                display: 'flex',
                flex: '1 1 auto',
                alignItems: 'center',
                justifyContent: 'center',
                height: 60,
                backgroundColor: store.isHovering ? ColorUtils.hexToRGB(store.color, 0.5) : store.color,
                color: 'white',
                margin: '0 1px'
            }}
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
            onMouseUp={this.onMouseUp}
            color="primary"
        >
            {
                store.icon &&
                    <Icon
                        style={{
                            color: 'white',
                            marginRight: 10
                        }}
                    >
                        {store.icon}
                    </Icon>
            }
            {
                store.name
            }
        </Button>;
    }

    @action.bound
    onMouseOver()
    {
        this.store.setHovering(true);
    }

    @action.bound
    onMouseOut()
    {
        this.store.setHovering(false);
    }

    @action.bound
    onMouseUp()
    {
        this.store.drop();
    }
}
