import React from 'react';
import { observer } from 'mobx-react-lite';
import ParameterDictionary from '../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ParameterValueEditor from './Parameter/ParameterValueEditor';
import DynamicParameterAssignment from '../../../../../../../../@Api/Automation/Function/Dynamic/DynamicParameterAssignment';
import FunctionContext from '../../../../../../../../@Api/Automation/Function/FunctionContext';

export interface DynamicParameterAssignmentEditorProps
{
    context: FunctionContext;
    parameterDictionary: ParameterDictionary;
    parameterAssignment: DynamicParameterAssignment;
}

const DynamicParameterAssignmentEditor: React.FC<DynamicParameterAssignmentEditorProps> =
    props =>
    {
        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            {
                props.parameterDictionary
                    .parameters
                    .map(
                        parameter =>
                            <ViewGroupItem
                                key={parameter.id}
                            >
                                <ParameterValueEditor
                                    {...props}
                                    parameter={parameter}
                                />
                            </ViewGroupItem>)
            }
        </ViewGroup>;
    };

export default observer(DynamicParameterAssignmentEditor);
