import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import localizeText from '../../Localization/localizeText';
import LayoutAction from '../Action/LayoutAction';
import getLayoutActionFromDescriptor from '../Action/Api/getLayoutActionFromDescriptor';
import FormParameter from './Form/FormParameter/FormParameter';
import Computation from '../../Automation/Function/Computation/Computation';
import getComputationFromDescriptor from '../../Automation/Api/getComputationFromDescriptor';

export default class AddressLookupLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref streetParameter?: FormParameter<any>;
    @observable.ref houseNumberParameter?: FormParameter<any>;
    @observable.ref houseNumberSuffixParameter?: FormParameter<any>;
    @observable.ref postalCodeParameter?: FormParameter<any>;
    @observable.ref cityParameter?: FormParameter<any>;
    @observable.ref countryParameter?: FormParameter<any>;
    @observable.ref onLookup?: LayoutAction;
    @observable.ref isAutoFocused?: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(
        streetParameter?: FormParameter<any>,
        houseNumberParameter?: FormParameter<any>,
        houseNumberSuffixParameter?: FormParameter<any>,
        postalCodeParameter?: FormParameter<any>,
        cityParameter?: FormParameter<any>,
        countryParameter?: FormParameter<any>,
        onLookup?: LayoutAction,
        isAutoFocused?: Computation<any, any>
    )
    {
        super();

        this.streetParameter = streetParameter;
        this.houseNumberParameter = houseNumberParameter;
        this.houseNumberSuffixParameter = houseNumberSuffixParameter;
        this.postalCodeParameter = postalCodeParameter;
        this.cityParameter = cityParameter;
        this.countryParameter = countryParameter;
        this.onLookup = onLookup;
        this.isAutoFocused = isAutoFocused;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.AddressLookup', 'Adres opzoeker');
    }

    validate(): Validation[]
    {
        return [
            ...this.onLookup?.validate() ?? [],
            ...this.isAutoFocused?.validate() ?? [],
        ];
    }

    getDependencies(): Dependency[]
    {

        return [
            ...[
                this.streetParameter,
                this.houseNumberParameter,
                this.houseNumberSuffixParameter,
                this.postalCodeParameter,
                this.cityParameter,
                this.countryParameter
            ]
                .filter(
                    parameter =>
                        parameter !== undefined
                )
                .map(
                    parameter =>
                        new Dependency(
                            parameter.parameter,
                            undefined
                        )
                )
                .reduce(
                    (a, b) => a.concat(b),
                    []
                ),
            ...this.onLookup
                ? this.onLookup.getDependencies()
                : [],
            ...this.isAutoFocused
                ? this.isAutoFocused.getDependencies()
                : []
        ];
    }

    toDescriptor()
    {
        return {
            type: 'AddressLookup',
            streetParameter: this.streetParameter?.toDescriptor(),
            houseNumberParameter: this.houseNumberParameter?.toDescriptor(),
            houseNumberSuffixParameter: this.houseNumberSuffixParameter?.toDescriptor(),
            postalCodeParameter: this.postalCodeParameter?.toDescriptor(),
            cityParameter: this.cityParameter?.toDescriptor(),
            countryParameter: this.countryParameter?.toDescriptor(),
            onLookup: this.onLookup?.toDescriptor(),
            isAutoFocused: this.isAutoFocused?.toDescriptor(),
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new AddressLookupLayout(
            descriptor.streetParameter
                ?
                    FormParameter.fromDescriptor(
                        descriptor.streetParameter,
                        dependencyContext
                    )
                :
                    undefined,
            descriptor.houseNumberParameter
                ?
                    FormParameter.fromDescriptor(
                        descriptor.houseNumberParameter,
                        dependencyContext
                    )
                :
                    undefined,
            descriptor.houseNumberSuffixParameter
                ?
                    FormParameter.fromDescriptor(
                        descriptor.houseNumberSuffixParameter,
                        dependencyContext
                    )
                :
                    undefined,
            descriptor.postalCodeParameter
                ?
                    FormParameter.fromDescriptor(
                        descriptor.postalCodeParameter,
                        dependencyContext
                    )
                :
                    undefined,
            descriptor.cityParameter
                ?
                    FormParameter.fromDescriptor(
                        descriptor.cityParameter,
                        dependencyContext
                    )
                :
                    undefined,
            descriptor.countryParameter
                ?
                    FormParameter.fromDescriptor(
                        descriptor.countryParameter,
                        dependencyContext
                    )
                :
                    undefined,
            descriptor.onLookup
                ?
                    await getLayoutActionFromDescriptor(
                        descriptor.onLookup,
                        dependencyContext
                    )
                :
                    undefined,
            descriptor.isAutoFocused
                ?
                    await getComputationFromDescriptor(
                        descriptor.isAutoFocused,
                        dependencyContext
                    )
                :
                    undefined
        );
    }

    // ----------------------- Private logic ------------------------
}
