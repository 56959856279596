import { Entity } from './Entity';
import { EntityEvent } from './EntityEvent';
import uuid from '../../../@Util/Id/uuid';

const constructorIdMap = new Map();

function getConstructorId(constructor: any)
{
    const id = constructorIdMap.get(constructor);

    if (id)
    {
        return id;
    }
    else
    {
        const id = uuid();
        constructorIdMap.set(constructor, id);
        return id;
    }
}

export class SelectionNode
{
    // ------------------- Persistent Properties --------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    hashCode()
    {
        return getConstructorId(this.constructor);
    }

    equals(node: SelectionNode): boolean
    {
        return this.constructor === node.constructor;
    }

    matches(entity: Entity): boolean
    {
        return false;
    }

    isAffectedBy(event: EntityEvent): boolean
    {
        return false;
    }

    descriptor(): any
    {
        return {};
    }

    // ----------------------- Private logic ------------------------
}
