import Input from '../../../Multiplayer/Model/Input/Input';
import FieldInput from '../../../Multiplayer/Model/Input/FieldInput';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import RelationshipInput from '../../../Multiplayer/Model/Input/RelationshipInput';
import getSetting from '../../../Setting/Api/getSetting';
import { SettingSource } from '../../../Setting/SettingStore';
import { Setting } from '../../../../../@Api/Settings/Setting';

export function isFieldVisibleDuringConstruction(
    field: Input,
    entity: Entity
): boolean
{
    return (
            isVisibilityDuringConstructionMarkedOnMetadata(field)
            || isVisibilityDuringConstructionMarkedInSetting(field)
            || field.isRequired()
            || entity.entityType.bespoke.includeFieldPathDuringConstruction(entity, field.toFieldPath())
        )
        && !field.isComputed();
}

function isVisibilityDuringConstructionMarkedOnMetadata(field: Input)
{
    if (field instanceof FieldInput)
    {
        return field.field.isDefining;
    }
    else if (field instanceof RelationshipInput)
    {
        return field.relationshipDefinition.isVisibleDuringConstruction(field.isParent);
    }
    else
    {
        return false;
    }
}

function isVisibilityDuringConstructionMarkedInSetting(field: Input)
{
    return getSetting(SettingSource.Organization, Setting.Metadata.VisibleFieldsDuringConstruction)[field.id()];
}
