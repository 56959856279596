import React, { useCallback, useMemo } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { observer, useComputed } from 'mobx-react-lite';
import Selectbox from '../../../../Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import useTypes from '../../../../Type/Api/useTypes';
import { updateRelationship } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import Link from '../../../../../../../@Future/Component/Generic/Link/Link';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import useRelatedEntities from '../../../../../../../@Api/Entity/Hooks/useRelatedEntities';
import { mapBy } from '../../../../../../../@Util/MapUtils/mapBy';
import { constructEntityOfType } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import useFetchedRelatedEntities from '../../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntities';
import { useExpansion } from '../../../../Selection/Api/useExpansion';
import useRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import { EntityEmailAddress } from '../../../../../../../@Api/Entity/Bespoke/EmailAddress/EntityEmailAddress';
import { getEmailAddressesByEntity } from '../../../../../../../@Api/Entity/Bespoke/EmailAddress/getEmailAddressesByEntity';

export interface AppointmentAttendeesEditorProps
{
    appointment: Entity;
    commitContext: CommitContext;
    autoCommit?: boolean;
}

export const AppointmentAttendeesEditor: React.FC<AppointmentAttendeesEditorProps> =
    observer(
        ({
             appointment,
             commitContext,
             autoCommit,
         }) =>
        {
            const types = useTypes();
            useFetchedRelatedEntities(
                appointment,
                types.Activity.Appointment.RelationshipDefinition.Attendees,
                false,
                commitContext
            );
            const selectedOwner =
                useRelatedEntity(
                    appointment,
                    types.Activity.Appointment.RelationshipDefinition.Owner,
                    false
                );
            const relationshipSelections =
                useMemo(
                    () => [
                        new EntitySelectionBuilder(types.Relationship.Type)
                            .join(
                                EntityPath.fromEntityType(types.Relationship.Type)
                                    .castTo(types.Relationship.Person.Type)
                                    .joinTo(
                                        types.Relationship.Person.RelationshipDefinition.Person,
                                        false
                                    )
                            )
                            .join(
                                EntityPath.fromEntityType(types.Relationship.Type)
                                    .castTo(types.Relationship.Organization.Type)
                                    .joinTo(
                                        types.Relationship.Organization.RelationshipDefinition.Organization,
                                        false
                                    )
                            )
                            .if(
                                () => selectedOwner !== undefined,
                                sb =>
                                    sb.where(
                                        cb =>
                                            cb.neq(
                                                EntityPath
                                                    .fromEntityType(types.Relationship.Type)
                                                    .field(types.Entity.Field.Id),
                                                null,
                                                selectedOwner.id
                                            )
                                    )
                            )
                            .selection
                    ],
                    [
                        types,
                        selectedOwner
                    ]
                );
            const createAttendee =
                useCallback(
                    async (relationship: Entity, emailAddress?: string) =>
                    {
                        const emailAddressOrDefault =
                            emailAddress === undefined
                                ? getEmailAddressesByEntity(
                                    relationship,
                                    commitContext
                                )
                                    .map(email => email.emailAddress)
                                    .find(() => true)
                                : emailAddress;

                        if (emailAddressOrDefault !== undefined)
                        {
                            const newAttendee =
                                constructEntityOfType(
                                    types.AppointmentAttendee.Type,
                                    commitContext
                                );
                            setValueByFieldInEntity(
                                newAttendee,
                                types.AppointmentAttendee.Field.EmailAddress,
                                emailAddressOrDefault,
                                commitContext
                            );
                            setValueByFieldInEntity(
                                newAttendee,
                                types.AppointmentAttendee.Field.IsRequired,
                                true,
                                commitContext
                            );

                            let finalRelationship = relationship;
                            let finalContact: Entity | undefined;

                            if (relationship.entityType.isA(types.Relationship.Person.Contact.Type))
                            {
                                const parentRelationships =
                                    await EntitySelectionBuilder.builder(
                                        types.Relationship.Organization.Type,
                                        (builder, rootPath) =>
                                            builder
                                                .where(
                                                    cb =>
                                                        cb.relatedToEntity(
                                                            rootPath
                                                                .joinTo(
                                                                    types.Relationship.Organization.RelationshipDefinition.Organization,
                                                                    false
                                                                )
                                                                .joinTo(
                                                                    types.Relation.RelationshipDefinition.Relationships,
                                                                    false
                                                                ),
                                                            relationship
                                                        )
                                                )
                                                .limit(1)
                                    )
                                        .select();

                                if (parentRelationships.length > 0)
                                {
                                    finalContact = relationship;
                                    finalRelationship = parentRelationships[0].entity!;
                                }
                            }

                            updateRelationship(
                                newAttendee,
                                false,
                                types.AppointmentAttendee.RelationshipDefinition.Relationship,
                                finalRelationship,
                                commitContext
                            );
                            updateRelationship(
                                newAttendee,
                                false,
                                types.AppointmentAttendee.RelationshipDefinition.Contact,
                                finalContact,
                                commitContext
                            );
                            updateRelationship(
                                appointment,
                                false,
                                types.Activity.Appointment.RelationshipDefinition.Attendees,
                                newAttendee,
                                commitContext
                            );

                            if (autoCommit)
                            {
                                return commitContext.commit();
                            }
                        }
                    },
                    [
                        appointment,
                        types,
                        commitContext,
                        autoCommit,
                    ]
                );
            const handleRelationshipChange =
                useCallback(
                    async (selectedEntities: Entity[]) =>
                    {
                        const selectedAttendees =
                            selectedEntities.filter(
                                attendee =>
                                    attendee.entityType.isA(types.AppointmentAttendee.Type)
                            );
                        const newRelationships =
                            selectedEntities.filter(
                                attendee =>
                                    attendee.entityType.isA(types.Relationship.Type)
                            );

                        const attendeesToRemove: Entity[] = [];
                        appointment
                            .getRelatedEntitiesByDefinition(
                                false,
                                types.Activity.Appointment.RelationshipDefinition.Attendees,
                                commitContext
                            )
                            .forEach(
                                attendee =>
                                {
                                    if (!selectedAttendees.includes(attendee))
                                    {
                                        attendeesToRemove.push(attendee);
                                    }
                                }
                            );

                        // Add new addressees
                        for (const relationship of newRelationships)
                        {
                            await createAttendee(relationship);
                        }

                        // Delete all addressees that are no longer selected
                        attendeesToRemove.forEach(
                            attendee =>
                            {
                                updateRelationship(
                                    appointment,
                                    false,
                                    types.Activity.Appointment.RelationshipDefinition.Attendees,
                                    undefined,
                                    commitContext,
                                    attendee
                                );
                                commitContext.deleteEntity(attendee);
                            }
                        );

                        if (autoCommit)
                        {
                            return commitContext.commit();
                        }
                    },
                    [
                        appointment,
                        types,
                        commitContext,
                        autoCommit,
                    ]
                );
            const [ isLoadingAttendees ] =
                useExpansion(
                    types.Activity.Appointment.Type,
                    rootPath => [
                        rootPath
                            .joinTo(
                                types.Activity.Appointment.RelationshipDefinition.Attendees,
                                false
                            )
                            .joinTo(
                                types.AppointmentAttendee.RelationshipDefinition.Relationship,
                                false
                            ),
                        rootPath
                            .joinTo(
                                types.Activity.Appointment.RelationshipDefinition.Attendees,
                                false
                            )
                            .joinTo(
                                types.AppointmentAttendee.RelationshipDefinition.Contact,
                                false
                            ),
                        rootPath
                            .joinTo(
                                types.Activity.Appointment.RelationshipDefinition.Attendees,
                                false
                            )
                            .joinTo(
                                types.AppointmentAttendee.RelationshipDefinition.CalendarItemAttendee,
                                false
                            )
                            .joinTo(
                                types.CalendarItemAttendee.RelationshipDefinition.ResponseStatus,
                                false
                            ),
                    ],
                    () => [
                        appointment,
                    ],
                    [
                        appointment,
                        types,
                    ]
                );
            const attendees =
                useRelatedEntities(
                    appointment,
                    types.Activity.Appointment.RelationshipDefinition.Attendees,
                    false,
                    commitContext
                );
            const attendeeByRelationshipId =
                useComputed(
                    () =>
                        mapBy(
                            attendees,
                            attendee =>
                                (
                                    attendee.getRelatedEntityByDefinition(
                                        false,
                                        types.AppointmentAttendee.RelationshipDefinition.Contact,
                                        commitContext
                                    ) ?? attendee.getRelatedEntityByDefinition(
                                        false,
                                        types.AppointmentAttendee.RelationshipDefinition.Relationship,
                                        commitContext
                                    )
                                )?.id
                        ),
                    [
                        attendees,
                        types,
                        commitContext,
                    ]
                );
            const selectedValueFormatter =
                useCallback(
                    (relationshipOrAttendee: Entity) =>
                    {
                        const relationship =
                            relationshipOrAttendee.entityType.isA(types.AppointmentAttendee.Type)
                                ? relationshipOrAttendee.getRelatedEntityByDefinition(
                                    false,
                                    types.AppointmentAttendee.RelationshipDefinition.Relationship,
                                    commitContext
                                )
                                : relationshipOrAttendee;

                        return relationship?.getName();
                    },
                    [
                        types,
                        commitContext,
                    ]
                );
            const labelFormatter =
                useCallback(
                    (relationshipOrAttendee: Entity) =>
                    {
                        const relationship =
                            relationshipOrAttendee.entityType.isA(types.AppointmentAttendee.Type)
                                ? relationshipOrAttendee.getRelatedEntityByDefinition(
                                    false,
                                    types.AppointmentAttendee.RelationshipDefinition.Relationship,
                                    commitContext
                                )
                                : relationshipOrAttendee;
                        const attendee =
                            relationshipOrAttendee.entityType.isA(types.AppointmentAttendee.Type)
                                ? relationshipOrAttendee
                                : attendeeByRelationshipId.get(relationshipOrAttendee.id);
                        let emailAddresses: EntityEmailAddress[];

                        if (attendee)
                        {
                            emailAddresses =
                                [
                                    {
                                        field: types.AppointmentAttendee.Field.EmailAddress,
                                        emailAddress:
                                            attendee.getObjectValueByField(
                                                types.AppointmentAttendee.Field.EmailAddress,
                                                commitContext
                                            )
                                    }
                                ];
                        }
                        else
                        {
                            emailAddresses =
                                getEmailAddressesByEntity(
                                    relationship,
                                    commitContext
                                );
                        }

                        if (emailAddresses.length > 0)
                        {
                            if (attendee)
                            {
                                const responseStatus =
                                    EntityPath.fromEntity(attendee)
                                        .joinTo(
                                            types.AppointmentAttendee.RelationshipDefinition.CalendarItemAttendee,
                                            false
                                        )
                                        .joinTo(
                                            types.CalendarItemAttendee.RelationshipDefinition.ResponseStatus,
                                            false
                                        )
                                        .traverseEntity(attendee)
                                        .find(() => true);

                                if (responseStatus === undefined)
                                {
                                    return `<${emailAddresses[0].emailAddress}>`;
                                }
                                else
                                {
                                    return `<${emailAddresses[0].emailAddress} - ${responseStatus.name}>`;
                                }
                            }
                            else
                            {
                                return <>
                                    {`<`}
                                    {
                                        emailAddresses.map(
                                            (email, idx) =>
                                                <React.Fragment
                                                    key={`${email.field.id}.${email.emailAddress}`}
                                                >
                                                    {idx > 0 ? ', ' : ''}
                                                    <Link
                                                        highlighted
                                                        onClick={
                                                            async event =>
                                                            {
                                                                event.stopPropagation();

                                                                return createAttendee(
                                                                    relationship,
                                                                    email.emailAddress
                                                                );
                                                            }
                                                        }
                                                        tooltip={email.field.getName()}
                                                    >
                                                        {email.emailAddress}
                                                    </Link>
                                                </React.Fragment>)
                                    }
                                    {`>`}
                                </>;
                            }
                        }
                        else
                        {
                            return localizeText('Email.NoEmailAddressFound', '<géén e-mail adres gevonden>');
                        }
                    },
                    [
                        types,
                        appointment,
                        commitContext,
                        createAttendee,
                        attendeeByRelationshipId,
                    ]
                );
            const searchFieldPaths =
                useMemo(
                    () => [
                        EntityPath.fromEntityType(types.Relationship.Type)
                            .castTo(types.Relationship.Person.Contact.Type)
                            .field(types.Relationship.Person.Contact.Field.EmailAddress),
                        EntityPath.fromEntityType(types.Relationship.Type)
                            .castTo(types.Relationship.Organization.Type)
                            .joinTo(
                                types.Relationship.Organization.RelationshipDefinition.Organization,
                                false)
                            .field(types.Relation.Organization.Field.EmailAddress),
                        EntityPath.fromEntityType(types.Relationship.Type)
                            .castTo(types.Relationship.Person.Type)
                            .joinTo(
                                types.Relationship.Person.RelationshipDefinition.Person,
                                false)
                            .field(types.Relation.Person.Field.EmailAddress)
                    ],
                    [
                        types
                    ]
                );

            if (isLoadingAttendees)
            {
                return null;
            }
            else
            {
                return <Selectbox
                    selections={relationshipSelections}
                    onChange={handleRelationshipChange}
                    value={attendees}
                    multi
                    formatSelectedValue={selectedValueFormatter}
                    formatCaption={labelFormatter}
                    searchFieldPaths={searchFieldPaths}
                    commitContext={commitContext}
                />;
            }
        }
    );
