import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { LocalizedTextProps } from '../LocalizedText';
import LocalizedTextTranslation from '../LocalizedTextTranslation/LocalizedTextTranslation';
import styles from './LocalizedTextTranslator.module.scss';
import Popper from '../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import useSwitch from '../../../../../@Util/Switch/useSwitch';
import AsyncComponent from '../../../../../@Util/AsyncComponent/AsyncComponent';

export interface LocalizedTextTranslatorProps extends LocalizedTextProps
{

}


const localizedTextByCodeEditorLoader =
    () =>
        import('./LocalizedTextByCodeEditor/LocalizedTextByCodeEditor');

const LocalizedTextTranslator: React.FC<LocalizedTextTranslatorProps & any> =
    props =>
    {
        const [ isOpen, open, close ] = useSwitch(false);
        const click =
            useCallback(
                (event: any) =>
                {
                    event.stopPropagation();
                    open();
                },
                [
                    open
                ]);

        return <Popper
            referenceIsSpan
            reference={
                <span
                    className={styles.root}
                    onClick={click}
                >
                    <span
                        className={styles.translation}
                    >
                        <LocalizedTextTranslation
                            {...props}
                        />
                    </span>
                    <span
                        className={styles.code}
                    >
                        {props.code}
                    </span>
                </span>
            }
            popper={
                <Card
                    inset
                >
                    {/* Use an async component here, because this <LocalizedText /> component is used in many generic low-level components.
                        This prevents unresolvable cyclic dependencies.*/}
                    <AsyncComponent
                        _loader={localizedTextByCodeEditorLoader}
                        _loadKey="default"
                        {...props}
                    />
                </Card>
            }
            open={isOpen}
            onClose={close}
        />;
    };

export default observer(LocalizedTextTranslator);
