import { observable } from 'mobx';
import AutomationDependencyContext from '../../Automation/AutomationDependencyContext';
import ParameterAssignment from '../../Automation/Parameter/ParameterAssignment';

export default class PortalDataSourceQueryResult
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref parameterAssignment: ParameterAssignment;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                parameterAssignment: ParameterAssignment)
    {
        this.id = id;
        this.parameterAssignment = parameterAssignment;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new PortalDataSourceQueryResult(
            descriptor.id,
            await ParameterAssignment.fromDescriptor(
                dependencyContext,
                descriptor.parameterAssignment));
    }

    // ----------------------- Private logic ------------------------
}
