import React from 'react';
import { observer } from 'mobx-react-lite';
import { DocumentLayoutDefinition } from '../../DocumentEditor';
import ColorPicker from '../../../ColorPicker/ColorPicker';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import Input from '../../../Input/Input';
import Card from '../../../../Card/Card';
import ExpansionPanel from '../../../../ExpansionPanel/ExpansionPanel';
import LocalizedText from '../../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';
import Header from '../../../../ExpansionPanel/Header/Header';
import CardInset from '../../../../Card/CardInset';
import InputGroup from '../../../InputGroup/InputGroup';
import DimensionEditor from '../../DimensionEditor/DimensionEditor';

export interface DocumentLayoutControlsProps
{
    layout: DocumentLayoutDefinition;
    onChange: (attributes: DocumentLayoutDefinition) => void;
}

const DocumentLayoutControls: React.FC<DocumentLayoutControlsProps> =
    props =>
    {
        return <Card>
            <ExpansionPanel
                id="ChildRelation"
                summary={
                    <Header
                        title={
                            <LocalizedText
                                code="Gutenberg.DocumentLayoutControls.Header"
                                value="Document layout"
                            />
                        }
                        inset
                    />
                }
                expansion={
                    <CardInset>
                        <InputGroup>
                            <Input
                                label={localizeText('Gutenberg.DocumentWidth', 'Document breedte')}
                                labelPosition="left"
                            >
                                <DimensionEditor
                                    value={
                                        props.layout.width !== undefined
                                            ? { unit: props.layout.widthUnit !== undefined
                                                        ? props.layout.widthUnit
                                                        : 'Pixel',
                                                value: props.layout.width }
                                            : undefined
                                    }
                                    onChange={
                                        value =>
                                            props.onChange({
                                                ...props.layout,
                                                widthUnit: value?.unit,
                                                width: value?.value
                                            })
                                    }
                                />
                            </Input>
                            <Input
                                label={localizeText('Generic.BackgroundColor', 'Achtergrondkleur')}
                                labelPosition="left"
                            >
                                <ColorPicker
                                    tooltip={localizeText('Generic.BackgroundColor', 'Achtergrondkleur')}
                                    onChange={color => props.onChange({ ...props.layout, backgroundColor: color })}
                                    color={props.layout?.backgroundColor}
                                />
                            </Input>
                        </InputGroup>
                    </CardInset>
                }
                expanded={false}
            />
        </Card>;
    };

export default observer(DocumentLayoutControls);
