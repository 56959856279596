import React, { useMemo } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import Fields from '../../../Fields/Fields';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import useTypes from '../../../Type/Api/useTypes';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import RelatedEntityView from '../../Shared/RelatedEntityView/RelatedEntityView';
import View from '../../../View/Model/View';
import Specification from '../../../View/Model/Specification';
import List from '../../../View/Model/Specification/List';
import Column from '../../../View/Model/Specification/Column';
import uuid from 'uuid';
import Ordering from '../../../View/Model/Ordering';
import { withValue } from '../../../../../../@Util/Functional/withValue';
import getViewParameters from '../../../View/Api/getViewParameters';
import useCount from '../../../Selection/Hooks/useCount';
import CurrencyEditor from '../../../Currency/CurrencyEditor';

export interface PriceListProps
{
    entity: Entity;
}

const PriceList: React.FC<PriceListProps> =
    ({
        entity,
     }) =>
    {
        const types = useTypes();
        const pathToPrices =
            useMemo(
                () =>
                    EntityPath.fromEntity(entity)
                        .joinTo(
                            types.PriceList.RelationshipDefinition.Prices,
                            false),
                [
                    entity,
                    types
                ]);
        const view =
            useMemo(
                () =>
                    new View(
                        'List',
                        types.Price.Type.getName(),
                        types.Price.Type,
                        getViewParameters(types.Price.Type),
                        undefined,
                        new Specification(
                            withValue(
                                [
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(types.Price.Type)
                                            .joinTo(
                                                types.Price.RelationshipDefinition.Relationship,
                                                false)
                                            .field()),
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(types.Price.Type)
                                            .joinTo(
                                                types.Price.RelationshipDefinition.Product,
                                                false)
                                            .field()),
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(types.Price.Type)
                                            .field(types.Price.Field.StartDate)),
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(types.Price.Type)
                                            .field(types.Price.Field.EndDate)),
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(types.Price.Type)
                                            .field(types.Price.Field.Quantity)),
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(types.Price.Type)
                                            .field(types.Price.Field.BasePrice)),
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(types.Price.Type)
                                            .field(types.Price.Field.Price)),
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(types.Price.Type)
                                            .field(types.Price.Field.DiscountPercentage))
                                ],
                                columns =>
                                    new List(
                                        columns,
                                        [
                                            new Ordering(
                                                columns[0],
                                                true),
                                            new Ordering(
                                                columns[1],
                                                true),
                                            new Ordering(
                                                columns[2],
                                                true),
                                            new Ordering(
                                                columns[4],
                                                true)
                                        ]))
                            )),
                [
                    types
                ]);

        const pricesInPriceListCount =
            useCount(
                types.Price.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath
                                        .joinTo(
                                            types.PriceList.RelationshipDefinition.Prices,
                                            true
                                        ),
                                    entity
                                )
                        ),
                [
                    types,
                    entity,
                ]
            );

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset
                                    bottom={false}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <TypeAndName
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                                <Fields
                                    entity={entity}
                                />
                                <CurrencyEditor
                                    entity={entity}
                                    field={types.PriceList.Field.Currency}
                                    disabled={pricesInPriceListCount > 0}
                                    withLabel
                                    withCardInset
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <RelatedEntityView
                        entity={entity}
                        path={pathToPrices}
                        view={view}
                        hideLinkButton
                    />
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(PriceList);
