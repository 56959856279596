import Function from '../Function';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import { Computation as OldComputation } from '../../../../@Component/Domain/Computation/Type/Computation';
import { FileReporter } from '../../../../@Component/Domain/DataObject/Model/DataDescriptor';

export default abstract class Computation<T extends ValueType<any>, V extends Value<any, any>> extends Function<T, V>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor(onFile?: FileReporter)
    {
        const descriptor = {
            class: 'Computation'
        };

        this.augmentDescriptor(descriptor, onFile);

        return descriptor;
    }

    abstract augmentDescriptor(descriptor: any,
                               onFile?: FileReporter);

    toOldComputation(): OldComputation
    {
        return undefined;
    }

    // ----------------------- Private logic ------------------------
}
