import { RelativeYearlyRecurrencePattern } from '../../../Model/RecurrencePattern';
import { Frequency, Options } from 'rrule';
import { getRRuleMonth } from '../Util/getRRuleMonth';
import { getRRuleWeekDay } from '../Util/getRRuleWeekDay';

export function getRelativeYearlyRecurrencePatternAsRRule(
    pattern: RelativeYearlyRecurrencePattern
): Partial<Options>
{
    return {
        freq: Frequency.YEARLY,
        interval: pattern.interval ?? 1,
        bymonth: getRRuleMonth(pattern.month),
        byweekday:
            pattern.dayOfWeekIndex === 0
                ? getRRuleWeekDay(pattern.dayOfWeek)
                : getRRuleWeekDay(pattern.dayOfWeek)
                    .nth(pattern.dayOfWeekIndex),
    };
}
