import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import PortalDataSourceSignature from '../../../../../../../../@Api/Portal/DataSource/PortalDataSourceSignature';
import Switch from '../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import DeleteIconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import PortalDataSourceOrdering from '../../../../../../../../@Api/Portal/DataSource/PortalDataSourceOrdering';
import uuid from '../../../../../../../../@Util/Id/uuid';
import Parameter from '../../../../../../../../@Api/Automation/Parameter/Parameter';

export interface PortalDataSourceOrderingsEditorProps
{
    value: PortalDataSourceOrdering[];
    onChange: (value: PortalDataSourceOrdering[]) => void;
    dataSourceSignature: PortalDataSourceSignature;
}

const PortalDataSourceOrderingsEditor: React.FC<PortalDataSourceOrderingsEditorProps> =
    props =>
    {
        const { value, onChange, dataSourceSignature } = props;
        const addOrdering =
            useCallback(
                (parameter: Parameter<any>) =>
                    onChange([
                        ...value,
                        new PortalDataSourceOrdering(
                            uuid(),
                            parameter,
                            'Descending')
                    ]),
                [
                    onChange,
                    value
                ]);
        const removeOrdering =
            useCallback(
                (ordering: PortalDataSourceOrdering) =>
                    onChange(
                        value.filter(
                            checkOrdering =>
                                checkOrdering !== ordering)),
                [
                    onChange,
                    value
                ]);

        return <ol>
            {
                value
                    .map(
                        ordering =>
                            <li
                                key={ordering.id}
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    alignment="center"
                                    spacing={15}
                                >
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        {ordering.expression.getName()}
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <Input
                                            labelPosition="right"
                                            label={
                                                <LocalizedText
                                                    code="View.Column.SortDescending"
                                                    value="Sorteer aflopend"
                                                />
                                            }
                                        >
                                            <Switch
                                                checked={ordering.direction === 'Descending'}
                                                onToggle={
                                                    isDescending =>
                                                        runInAction(
                                                            () =>
                                                                ordering.direction = isDescending ? 'Descending' : 'Ascending')
                                                }
                                            />
                                        </Input>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <DeleteIconButton
                                            onClick={
                                                () =>
                                                    removeOrdering(ordering)
                                            }
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </li>)
            }
            <li>
                <MenuButton
                    icon="add"
                    tooltip={
                        <LocalizedText
                            code="Generic.Add"
                            value="Toevoegen"
                        />
                    }
                    small
                >
                    <Menu>
                        {
                            dataSourceSignature.resultParameters.parameters.map(
                                parameter =>
                                    <Item
                                        key={parameter.id}
                                        name={parameter.getName()}
                                        onClick={
                                            () =>
                                                addOrdering(parameter)
                                        }
                                    />)
                        }
                    </Menu>
                </MenuButton>
            </li>
        </ol>;
    };

export default observer(PortalDataSourceOrderingsEditor);
