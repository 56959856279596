import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { default as WidgetModel } from '../../Model/Widget';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../Type/Api/useTypes';
import DatasetWidget from '../../Model/Widget/Dataset/DatasetWidget';
import uuid from '../../../../../../@Util/Id/uuid';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ExpansionPanel from '../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import ExpansionGroup from '../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import Header from '../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import HoverCard from '../../../../../../@Future/Component/Generic/Card/HoverCard/HoverCard';
import EntityWidget from '../../Model/Widget/Entity/EntityWidget';
import Icon from '../../../../../../@Future/Component/Generic/Icon/Icon';
import isHiddenType from '../../../../../../@Api/Metadata/EntityType/isHiddenType';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import WidgetAdder from '../Widget/WidgetAdder';
import ProjectPlannerWidget from '../../Model/Widget/ProjectPlanner/ProjectPlannerWidget';
import ProjectPlanner from '../../Widget/ProjectPlanner/ProjectPlanner';
import ProjectTimesheetWidget from '../../Model/Widget/ProjectTimesheet/ProjectTimesheetWidget';
import ProjectTimesheet from '../../Widget/ProjectTimesheet/ProjectTimesheet';
import MyProjectsTimesheetWidget from '../../Model/Widget/MyProjectsTimesheet/MyProjectsTimesheetWidget';
import MyProjectTimesheet from '../../Widget/MyProjectsTimesheet/MyProjectsTimesheet';
import EmployeeTimesheet from '../../Widget/EmployeeTimesheet/EmployeeTimesheet';
import EmployeeTimesheetWidget from '../../Model/Widget/EmployeeTimesheet/EmployeeTimesheetWidget';
import EmployeeBillabilityWidget from '../../Model/Widget/EmployeeBillability/EmployeeBillabilityWidget';
import EmployeeBillability from '../../Widget/EmployeeBillability/EmployeeBillability';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import ViewsWidget from '../../Model/Widget/Views/ViewsWidget';
import Widget from '../../Widget/Widget';
import Dataset from '../../Widget/Dataset/Dataset';
import ResourcePlannerWidget from '../../Model/Widget/ResourcePlanner/ResourcePlannerWidget';
import ResourcePlanner from '../../Widget/ResourcePlanner/ResourcePlanner';
import { createStringComparator } from '../../../../../Generic/List/V2/Comparator/StringComparator';
import ProjectAllocationWidget from '../../Model/Widget/ProjectAllocation/ProjectAllocationWidget';
import ProjectAllocation from '../../Widget/ProjectAllocation/ProjectAllocation';
import MyTimesheetWidget from '../../Model/Widget/MyTimesheet/MyTimesheetWidget';
import MyTimesheet from '../../Widget/MyTimesheet/MyTimesheet';
import usePaginatedSelection from '../../../View/Api/usePaginatedSelection';
import HoverCardBottom from '../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import Note from '../../Widget/Note/Note';
import NoteWidget from '../../Model/Widget/Note/NoteWidget';
import MyProjectHoursTimesheetWidget from '../../Model/Widget/MyProjectHoursTimesheet/MyProjectHoursTimesheetWidget';
import MyProjectAllocationWidget from '../../Model/Widget/MyProjectAllocation/MyProjectAllocationWidget';
import MyProjectAllocation from '../../Widget/MyProjectAllocation/MyProjectAllocation';
import { MyProjectTimesheetByTimeRegistrationWidget } from '../../Model/Widget/MyProjectTimesheetByTimeRegistration/MyProjectTimesheetByTimeRegistrationWidget';
import { augmentDatasetSelectionBuilderWithEmployeeFilter, augmentDatasetSelectionBuilderWithEntityTypeFilter } from '../../../Dataset/Api/augmentDatasetSelectionBuilder';
import CurrentUserContext from '../../../../User/CurrentUserContext';

export interface TypeBrowserProps
{
    entityType: EntityType;
    onAdd: (widget: WidgetModel) => void;
    onClose: () => void;
}

const TypeBrowser: React.FC<TypeBrowserProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);

        const [
            datasetPages,
            hasMoreDatasets,
            loadMoreDatasets,
            isDatasetsLoading
        ] =
            usePaginatedSelection(
                types.Dataset.Type,
                (builder, ) =>
                {
                    augmentDatasetSelectionBuilderWithEntityTypeFilter(
                        builder,
                        props.entityType,
                        false
                    );

                    augmentDatasetSelectionBuilderWithEmployeeFilter(
                        builder,
                        currentUserStore.employeeEntity,
                        true
                    );
                },
                [
                    types,
                    props.entityType,
                    currentUserStore.employeeEntity,
                ]
            );

        const datasetEntities =
            useComputed(
                () =>
                    datasetPages
                        .map(page => page.map(result => result.entity))
                        .reduce((a, b) => a.concat(b), []),
                [
                    datasetPages
                ]);

        const [
            resourcePlannerPages,
            hasMoreResourcePlanners,
            loadMoreResourcePlanners,
            isLoadingResourcePlanners
        ] =
            usePaginatedSelection(
                types.ResourcePlanner.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.EntityType.RelationshipDefinition.ResourcePlanners,
                                        true),
                                    props.entityType.entity))
                        .orderBy(
                            rootPath.field(types.ResourcePlanner.Field.Name),
                            true),
                [
                    types,
                    props.entityType
                ]);

        const resourcePlannerEntities =
            useComputed(
                () =>
                    resourcePlannerPages
                        .map(page => page.map(result => result.entity))
                        .reduce((a, b) => a.concat(b), []),
                [
                    resourcePlannerPages
                ])

        const onDelete = useCallback(() => {}, []);
        const dividerGlue = useDividerGlue();

        const addEntityWidget =
            useCallback(
                () =>
                    props.onAdd(
                        new EntityWidget(
                            uuid(),
                            props.entityType)),
                [
                    props.onAdd,
                    props.entityType
                ]);

        const addViewsWidget =
            useCallback(
                () =>
                    props.onAdd(
                        new ViewsWidget(
                            uuid(),
                            props.entityType)),
                [
                    props.onAdd,
                    props.entityType
                ]);

        const childTypes =
            useComputed(
                () =>
                    props.entityType
                        .childTypes
                        .filter(
                            type =>
                                !isHiddenType(type))
                        .sort(createStringComparator(type => type.namePlural)),
                [
                    props.entityType
                ]);

        const projectPlannerWidget =
            useMemo(
                () =>
                    new ProjectPlannerWidget(uuid()),
                []);

        const projectAllocationWidget =
            useMemo(
                () =>
                    new ProjectAllocationWidget(uuid()),
                []);

        const myProjectAllocationWidget =
            useMemo(
                () =>
                    new MyProjectAllocationWidget(uuid()),
                []);

        const myProjectsTimesheetWidget =
            useMemo(
                () =>
                    new MyProjectsTimesheetWidget(uuid()),
                []);

        const myProjectHoursTimesheetWidget =
            useMemo(
                () =>
                    new MyProjectHoursTimesheetWidget(uuid()),
                []);


        const myProjectHoursDetailsTimesheetWidget =
            useMemo(
                () =>
                    new MyProjectTimesheetByTimeRegistrationWidget(uuid()),
                []);

        const projectTimesheetWidget =
            useMemo(
                () =>
                    new ProjectTimesheetWidget(uuid()),
                []);

        const employeeTimesheetWidget =
            useMemo(
                () =>
                    new EmployeeTimesheetWidget(uuid()),
                []);

        const myTimesheetWidget =
            useMemo(
                () =>
                    new MyTimesheetWidget(uuid()),
                []);

        const employeeBillabilityWidget =
            useMemo(
                () =>
                    new EmployeeBillabilityWidget(uuid()),
                []);

        const viewsWidget =
            useMemo(
                () =>
                    new ViewsWidget(
                        uuid(),
                        props.entityType),
                [
                    props.entityType
                ]);

        const isNoteType =
            useMemo(
                () =>
                    props.entityType === types.Note.Type,
                [
                    types,
                    props.entityType
                ]);

        const noteWidget = useMemo(() => new NoteWidget(uuid(), ''), []);

        return <ExpansionGroup>
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={dividerGlue}
            >
                {
                    childTypes
                        .map(
                            childType =>
                                <ViewGroupItem
                                    key={childType.id}
                                >
                                    <ExpansionPanel
                                        id={`Type.${childType.id}`}
                                        summary={
                                            <Header
                                                title={childType.getName(true)}
                                                large
                                                inset
                                            />
                                        }
                                        expansion={
                                            <CardInset
                                                vertical={false}
                                            >
                                                <ObservableTypeBrowser
                                                    entityType={childType}
                                                    onAdd={props.onAdd}
                                                    onClose={props.onClose}
                                                />
                                            </CardInset>
                                        }
                                    />
                                </ViewGroupItem>)
                }
                {
                    types.Activity.Project.Type &&
                    props.entityType.isA(types.Activity.Project.Type) &&
                        <ViewGroupItem>
                            <WidgetAdder
                                name={
                                    <LocalizedText
                                        code="ResourcePlanner.ProjectPlanner"
                                        value="Project planner"
                                    />
                                }
                                onAdd={() => props.onAdd(new ProjectPlannerWidget(uuid()))}
                            >
                                <ProjectPlanner
                                    widget={projectPlannerWidget}
                                    onAdd={() => props.onAdd(projectPlannerWidget)}
                                    onDelete={onDelete}
                                />
                            </WidgetAdder>
                        </ViewGroupItem>
                }
                {
                    types.Activity.Project.Type &&
                    props.entityType.isA(types.Activity.Project.Type) &&
                    <ViewGroupItem>
                        <WidgetAdder
                            name={
                                <LocalizedText
                                    code="ProjectAllocation"
                                    value="Project bezetting"
                                />
                            }
                            onAdd={() => props.onAdd(new ProjectAllocationWidget(uuid()))}
                        >
                            <ProjectAllocation
                                widget={projectAllocationWidget}
                                onAdd={() => props.onAdd(projectAllocationWidget)}
                                onDelete={onDelete}
                            />
                        </WidgetAdder>
                    </ViewGroupItem>
                }

                {
                    types.Activity.Project.Type &&
                    props.entityType.isA(types.Activity.Project.Type) &&
                    <ViewGroupItem>
                        <WidgetAdder
                            name={
                                <LocalizedText
                                    code="MyProjectAllocation"
                                    value="Mijn project bezetting"
                                />
                            }
                            onAdd={() => props.onAdd(new MyProjectAllocationWidget(uuid()))}
                        >
                            <MyProjectAllocation
                                widget={myProjectAllocationWidget}
                                onAdd={() => props.onAdd(myProjectAllocationWidget)}
                                onDelete={onDelete}
                            />
                        </WidgetAdder>
                    </ViewGroupItem>
                }
                {
                    types.Activity.Project.Type &&
                    types.TimeRegistration.Type &&
                    props.entityType.isA(types.TimeRegistration.Type) &&
                    <ViewGroupItem>
                        <WidgetAdder
                            name={
                                <LocalizedText
                                    code="ResourcePlanner.MyProjectTimesheet"
                                    value="Mijn projecten - Alle uren"
                                />
                            }
                            onAdd={() => props.onAdd(new MyProjectsTimesheetWidget(uuid()))}
                        >
                            <MyProjectTimesheet
                                widget={myProjectsTimesheetWidget}
                                onAdd={() => props.onAdd(myProjectsTimesheetWidget)}
                                onDelete={onDelete}
                            />
                        </WidgetAdder>
                    </ViewGroupItem>
                }
                {
                    types.Activity.Project.Type &&
                    types.TimeRegistration.Type &&
                    props.entityType.isA(types.TimeRegistration.Type) &&
                    <ViewGroupItem>
                        <WidgetAdder
                            name={
                                <LocalizedText
                                    code="ResourcePlanner.MyProjectHoursTimesheet"
                                    value="Mijn projecten - Mijn uren"
                                />
                            }
                            onAdd={() => props.onAdd(new MyProjectHoursTimesheetWidget(uuid()))}
                        >
                            <MyProjectTimesheet
                                widget={myProjectHoursTimesheetWidget}
                                onAdd={() => props.onAdd(myProjectHoursTimesheetWidget)}
                                onDelete={onDelete}
                            />
                        </WidgetAdder>
                    </ViewGroupItem>
                }
                {
                    types.Activity.Project.Type &&
                    types.TimeRegistration.Type &&
                    props.entityType.isA(types.TimeRegistration.Type) &&
                    <ViewGroupItem>
                        <WidgetAdder
                            name={
                                <LocalizedText
                                    code="ResourcePlanner.MyProjectTimesheetByTimeRegistration"
                                    value="Mijn geboekte uren per project"
                                />
                            }
                            onAdd={() => props.onAdd(new MyProjectTimesheetByTimeRegistrationWidget(uuid()))}
                        >
                            <MyProjectTimesheet
                                widget={myProjectHoursDetailsTimesheetWidget}
                                onAdd={() => props.onAdd(myProjectHoursDetailsTimesheetWidget)}
                                onDelete={onDelete}
                            />
                        </WidgetAdder>
                    </ViewGroupItem>
                }
                {
                    types.Activity.Project.Type &&
                    types.TimeRegistration.Type &&
                    props.entityType.isA(types.TimeRegistration.Type) &&
                        <ViewGroupItem>
                            <WidgetAdder
                                name={
                                    <LocalizedText
                                        code="ResourcePlanner.ProjectTimesheet"
                                        value="Project timesheet"
                                    />
                                }
                                onAdd={() => props.onAdd(new ProjectTimesheetWidget(uuid()))}
                            >
                                <ProjectTimesheet
                                    widget={projectTimesheetWidget}
                                    onAdd={() => props.onAdd(projectTimesheetWidget)}
                                    onDelete={onDelete}
                                />
                            </WidgetAdder>
                        </ViewGroupItem>
                }
                {
                    types.Activity.Project.Type &&
                    types.TimeRegistration.Type &&
                    props.entityType.isA(types.TimeRegistration.Type) &&
                        <ViewGroupItem>
                            <WidgetAdder
                                name={
                                    <LocalizedText
                                        code="ResourcePlanner.EmployeeTimesheet"
                                        value="Medewerker timesheet"
                                    />
                                }
                                onAdd={() => props.onAdd(new EmployeeTimesheetWidget(uuid()))}
                            >
                                <EmployeeTimesheet
                                    widget={employeeTimesheetWidget}
                                    onAdd={() => props.onAdd(employeeTimesheetWidget)}
                                    onDelete={onDelete}
                                />
                            </WidgetAdder>
                        </ViewGroupItem>

                }
                {
                    types.Activity.Project.Type &&
                    types.TimeRegistration.Type &&
                    props.entityType.isA(types.TimeRegistration.Type) &&
                    <ViewGroupItem>
                        <WidgetAdder
                            name={
                                <LocalizedText
                                    code="ResourcePlanner.MyTimesheet"
                                    value="Mijn timesheet"
                                />
                            }
                            onAdd={() => props.onAdd(new MyTimesheetWidget(uuid()))}
                        >
                            <MyTimesheet
                                widget={myTimesheetWidget}
                                onAdd={() => props.onAdd(myTimesheetWidget)}
                                onDelete={onDelete}
                            />
                        </WidgetAdder>
                    </ViewGroupItem>

                }
                {
                    types.Activity.Project.Type &&
                    types.TimeRegistration.Type &&
                    props.entityType.isA(types.TimeRegistration.Type) &&
                        <ViewGroupItem>
                            <WidgetAdder
                                name={
                                    <LocalizedText
                                        code="ResourcePlanner.EmployeeBillability"
                                        value="Medewerker declarabiliteit"
                                    />
                                }
                                onAdd={() => props.onAdd(new EmployeeBillabilityWidget(uuid()))}
                            >
                                <EmployeeBillability
                                    widget={employeeBillabilityWidget}
                                    onAdd={() => props.onAdd(employeeBillabilityWidget)}
                                    onDelete={onDelete}
                                />
                            </WidgetAdder>
                        </ViewGroupItem>
                }
                {
                    datasetEntities?.map(
                        dataset =>
                            <ViewGroupItem
                                key={dataset.uuid}
                            >
                                <WidgetAdder
                                    name={dataset.name}
                                    onAdd={() => props.onAdd(new DatasetWidget(uuid(), dataset.uuid, dataset))}
                                >
                                    <Dataset
                                        widget={
                                            new DatasetWidget(
                                                uuid(),
                                                dataset.uuid,
                                                dataset)
                                        }
                                        onAdd={() =>
                                            props.onAdd(
                                                new DatasetWidget(
                                                    uuid(),
                                                    dataset.uuid,
                                                    dataset)
                                            )}
                                        onDelete={onDelete}
                                        whiteCard
                                    />
                                </WidgetAdder>
                            </ViewGroupItem>)
                }
                {
                    hasMoreDatasets &&
                    <ViewGroupItem>
                        <Divider />
                        <HoverCardBottom
                            onClick={loadMoreDatasets}
                            disabled={isDatasetsLoading}
                        >
                            <LocalizedText
                                code="Generic.LoadMore"
                                value="Meer laden"
                            />...
                        </HoverCardBottom>
                    </ViewGroupItem>
                }
                {
                    resourcePlannerEntities?.map(
                        resourcePlanner =>
                            <ViewGroupItem
                                key={resourcePlanner.uuid}
                            >
                                <WidgetAdder
                                    name={resourcePlanner.name}
                                    onAdd={() => props.onAdd(new ResourcePlannerWidget(uuid(), resourcePlanner.uuid, resourcePlanner))}
                                >
                                    <ResourcePlanner
                                        widget={
                                            new ResourcePlannerWidget(
                                                uuid(),
                                                resourcePlanner.uuid,
                                                resourcePlanner)
                                        }
                                        onAdd={() =>
                                            props.onAdd(
                                                new ResourcePlannerWidget(
                                                    uuid(),
                                                    resourcePlanner.uuid,
                                                    resourcePlanner)
                                            )}
                                        onDelete={onDelete}
                                    />
                                </WidgetAdder>
                            </ViewGroupItem>)
                }
                {
                    hasMoreResourcePlanners &&
                    <ViewGroupItem>
                        <Divider />
                        <HoverCardBottom
                            onClick={loadMoreResourcePlanners}
                            disabled={isLoadingResourcePlanners}
                        >
                            <LocalizedText
                                code="Generic.LoadMore"
                                value="Meer laden"
                            />...
                        </HoverCardBottom>
                    </ViewGroupItem>
                }
                <WidgetAdder
                    onAdd={addViewsWidget}
                    name={
                        <LocalizedText
                            code="Widget.Views.TypeViews"
                            value="${typeName} weergaves"
                            typeName={props.entityType.getName()}
                        />
                    }
                >
                    <Widget
                        onDelete={() => {}}
                        onAdd={() => {}}
                        widget={viewsWidget}
                    />
                </WidgetAdder>
                {
                    props.entityType.isInstantiableByInheritance() &&
                        <ViewGroupItem>
                            <WidgetAdder
                                name={
                                    <LocalizedText
                                        code="Dashboard.PinEntity"
                                        value="${typeName} vastpinnen"
                                        typeName={props.entityType.getName()}
                                    />
                                }
                            >
                                <HoverCard
                                    onClick={addEntityWidget}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={10}
                                        alignment="center"
                                        justification="center"
                                    >
                                        <ViewGroupItem>
                                            <Icon
                                                icon="attach_file"
                                                size={20}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <LocalizedText
                                                code="Dashboard.PinEntityOfType"
                                                value="Pin een ${typeName} op het dashboard"
                                                typeName={props.entityType.getName().toLowerCase()}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </HoverCard>
                            </WidgetAdder>
                        </ViewGroupItem>
                }
                {
                    datasetEntities && datasetEntities.length === 0 && childTypes.length === 0 && !isNoteType &&
                        <ViewGroupItem>
                            <CardInset>
                                <Centered
                                    horizontal
                                >
                                    <LocalizedText
                                        code="Dashboard.NoWidgetsForType"
                                        value="Er zijn geen widgets beschikbaar voor ${typeName}."
                                        typeName={props.entityType.getName().toLowerCase()}
                                    />
                                </Centered>
                            </CardInset>
                        </ViewGroupItem>
                }
                {
                    isNoteType &&
                    <WidgetAdder
                        name={types.Note.Type.getName()}
                        onAdd={() => props.onAdd(noteWidget)}
                    >
                        <Note
                            widget={noteWidget}
                            onAdd={() => props.onAdd(noteWidget)}
                            onDelete={onDelete}
                        />
                    </WidgetAdder>
                }
            </ViewGroup>
        </ExpansionGroup>;
    };

const ObservableTypeBrowser = observer(TypeBrowser);

export default ObservableTypeBrowser;
