import { computed, observable } from 'mobx';
import Column from './Column';

export default class Section
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable columns: Column[];

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                columns: Column[])
    {
        this.id = id;
        this.columns = columns;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new Section(
            descriptor.id,
            descriptor.columns
                .map(
                    column =>
                        Column.fromDescriptor(column)));
    }

    toDescriptor()
    {
        return {
            id: this.id,
            columns:
                this.columns.map(
                    column =>
                        column.toDescriptor())
        };
    }

    @computed
    get isFullWidth() : boolean
    {
        return this.columns.length === 1;
    }
    // ----------------------- Private logic ------------------------
}
