import React, { Fragment } from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import BlockOptionsPanel from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Settings/BlockOptionsPanel';
import ColorOptionsPanel from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Settings/ColorOptionsPanel';
import DimensionsOptionsPanel from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Settings/DimensionsOptionsPanel';
import GutenbergBlockType from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/Gutenberg/GutenbergBlockType';
import { EntityImageBlockId } from '../../../../../../@Future/Component/Generic/Input/DocumentEditor/DocumentEditor';
import EntityImageSelector from './EntityImage';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import Icon from '../../../../../../@Future/Component/Generic/Icon/Icon';

const EntityImageBlock: GutenbergBlockType =
    {
        name: EntityImageBlockId,
        configuration:
            {
                title: {
                    code: 'GutenbergBlock.ImageFromField',
                    value: 'Afbeelding uit veld'
                },
                icon: <Icon icon="image_search" />,
                category: 'common',

                attributes:
                {
                    content:
                        {
                            type: 'string',
                            source: 'html',
                            selector: 'img',
                            query: undefined,
                        },
                },

                supports:
                {
                    align: true
                },

                edit: props =>
                {
                    const {
                        backgroundColor,
                        borderColor,
                        fontColor,
                        borderWidth,
                        borderRadius,
                        padding,
                        margin
                    } = props.attributes as any;

                    const containerStyles = {
                        padding: margin
                    };

                    const innerContainerStyles = {
                        backgroundColor: backgroundColor,
                        borderWidth: borderWidth ? borderWidth : undefined,
                        borderColor: borderWidth && borderColor ? borderColor : undefined,
                        borderStyle: borderWidth && borderColor ? 'solid' : undefined,
                        borderRadius: borderRadius,
                        color: fontColor,
                        padding: padding
                    };

                    const onSelectFieldPath =
                        (path: EntityFieldPath, parameterId?: string) =>
                        {
                            props.setAttributes({ path: path.descriptor, parameterId: parameterId });
                        };

                    const parameterId = (props.attributes as any)?.parameterId;
                    const entityFieldPathDescriptor = (props.attributes as any)?.path;
                    const entityFieldPath =
                        entityFieldPathDescriptor
                            ?
                                EntityFieldPath.construct(entityFieldPathDescriptor)
                            :
                                undefined;

                    return (
                        <Fragment>
                            <InspectorControls>
                                <DimensionsOptionsPanel props={props} open />
                                <BlockOptionsPanel props={props} />
                                <ColorOptionsPanel props={props} />
                            </InspectorControls>
                            <div
                                style={containerStyles}
                            >
                                <div
                                    style={innerContainerStyles}
                                >
                                    <EntityImageSelector
                                        parameterId={parameterId}
                                        fieldPath={entityFieldPath}
                                        onSelect={onSelectFieldPath}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    );
                },

                save: props =>
                {
                    return <div></div>;
                }
            },

        view: block =>
        {
            return <div />;
        }
    };

export default EntityImageBlock;
