import React from 'react';
import { observer } from 'mobx-react-lite';
import { ValueViewerProps } from '../../ValueViewer';
import DataSourceValue from '../../../../../../../../../../@Api/Automation/Value/DataSourceValue';

export interface DataSourceViewerProps extends ValueViewerProps<DataSourceValue>
{

}

const DataSourceViewer: React.FC<DataSourceViewerProps> =
    props =>
    {
        return <span>{props.value.value.id}</span>;
    };

export default observer(DataSourceViewer);
