import { reference, type } from '../../../@Util/Serialization/Serialization';
import { EntityTypeParameter } from './EntityTypeParameter';
import { EntityType } from './EntityType';
import { computed, observable } from 'mobx';

@type('EntityTypeParameterValue')
export class EntityTypeParameterValue
{
    // ------------------- Persistent Properties --------------------

    @observable id: number;
    @reference(undefined, 'EntityTypeParameter') @observable.ref parameter: EntityTypeParameter;
    @reference(undefined, 'EntityType') @observable.ref entityType: EntityType;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(id?: number,
                parameter?: EntityTypeParameter,
                entityType?: EntityType)
    {
        this.id = id;
        this.parameter = parameter;
        this.entityType = entityType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get descriptor()
    {
        return {
            id: this.id,
            parameterId: this.parameter.id,
            entityTypeId: this.entityType.id
        };
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
