import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import ComputationBox from '../../Box/ComputationBox';
import JoinedCollectionComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/JoinedCollectionComputation';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';

export interface JoinedCollectionComputationEditorProps extends ComputationEditorProps<ValueType<any>, JoinedCollectionComputation>
{

}

const JoinedCollectionComputationEditor: React.FC<JoinedCollectionComputationEditorProps> =
    ({
        value,
        context,
     }) =>
    {
        const setLhs =
            useCallback(
                (lhs?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            value.lhs = lhs
                        ),
                [
                    value
                ]
            );
        const setRhs =
            useCallback(
                (rhs?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            value.rhs = rhs
                    ),
                [
                    value
                ]
            );

        return <ViewGroup
            orientation="horizontal"
            spacing={10}
        >
            <ViewGroupItem>
                <ComputationBox>
                    <ComputationEditor
                        context={context}
                        value={value.lhs}
                        onChange={setLhs}
                    />
                </ComputationBox>
            </ViewGroupItem>
            <ViewGroupItem>
                <ComputationBox>
                    <ComputationEditor
                        context={context}
                        value={value.rhs}
                        onChange={setRhs}
                    />
                </ComputationBox>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(JoinedCollectionComputationEditor);
