import { observable } from 'mobx';
import { EntityQueryMatchResult } from './EntityQueryMatchResult';
import { Entity } from '../../../@Api/Model/Implementation/Entity';
import { BaseEntityQuery } from '../../Domain/Entity/Selection/Model/BaseEntityQuery';

export class EntityMatchResult
{
    // ------------------------- Properties -------------------------

    @observable entity: Entity;
    @observable isNew: boolean;
    @observable resultByMatchedQuery: Map<BaseEntityQuery, EntityQueryMatchResult>;

    // ------------------------ Constructor -------------------------

    constructor(
        entity: Entity,
        isNew: boolean,
        resultByMatchedQuery: Map<BaseEntityQuery, EntityQueryMatchResult>
    )
    {
        this.entity = entity;
        this.isNew = isNew;
        this.resultByMatchedQuery = resultByMatchedQuery;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
