import { ExpressionSpecification } from '../Model/ExpressionSpecification';
import ComputationInText from '../../../../@Api/Automation/Function/Computation/ComputationInText';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { ComputationTypeStore } from '../../Computation/ComputationTypeStore';
import AutomationDependencyContext from '../../../../@Api/Automation/AutomationDependencyContext';
import TextComputation from '../../../../@Api/Automation/Function/Computation/TextComputation';

export default async function getNewComputationFromExpression(expression: ExpressionSpecification,
                                                              context: AutomationDependencyContext,
                                                              isRichText: boolean)
{
    const computationTypeStore = loadModuleDirectly(ComputationTypeStore);
    const computationsInText: ComputationInText[] =
        await Promise.all(
            expression.computations.map(
                async computation =>
                    new ComputationInText(
                        computation.id,
                        await computationTypeStore.fromSpecification(computation.computation).toNewComputation(context))))

    return new TextComputation(
        expression.expression,
        isRichText,
        computationsInText);
}
