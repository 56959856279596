import { useCallback, useMemo, useState } from 'react';

export default function useSwitch(initialValue: boolean): [ boolean, () => void, () => void ]
{
    const [ isSwitchedOn, setSwitched ] = useState(initialValue);

    const open =
        useCallback(
            () =>
                setSwitched(true),
            [
                setSwitched
            ]);

    const close =
        useCallback(
            () =>
                setSwitched(false),
            [
                setSwitched
            ]);

    return useMemo(
        () =>
            [
                isSwitchedOn,
                open,
                close
            ],
        [
            isSwitchedOn,
            open,
            close
        ]);
}
