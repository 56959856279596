import { reference, type } from '../../../@Util/Serialization/Serialization';
import { Entity } from './Entity';

@type('Account')
export class Account
{
    // ------------------- Persistent Properties --------------------

    id: number;
    username: string;
    usernamePending: string;
    hashedPassword: string;
    salt: string;
    creationDate: number;
    lockUntilDate: number;
    blockFromDate: number;
    passwordLastChangedDate: number;
    validationIp: string;
    validationDate: number;
    isDeveloper: boolean;
    isConsultant: boolean;
    @reference(undefined, 'Entity') entity: Entity;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
