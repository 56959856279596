import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import { runInAction } from 'mobx';
import LayoutActionEditor from '../../Action/LayoutActionEditor';
import LayoutAction from '../../../../../@Api/Layout/Action/LayoutAction';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import AddressLookupLayout from '../../../../../@Api/Layout/Type/AddressLookupLayout';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import FormParameterEditor from '../Form/FormParameter/FormParameterEditor';
import LayoutContext from '../../../../../@Api/Layout/LayoutContext';
import FormLayoutContexts from '../Form/FormLayoutContexts';
import FormParameter from '../../../../../@Api/Layout/Type/Form/FormParameter/FormParameter';
import useTypes from '../../../Entity/Type/Api/useTypes';
import ComputationEditor from '../../../Entity/Viewer/Content/Automation/Editor/Computation/ComputationEditor';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import PrimitiveValueType from '../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../DataObject/Model/DataObject';

export interface AddressLookupLayoutControlsProps extends LayoutEditorProps<AddressLookupLayout>
{

}

const AddressLookupLayoutControls: React.FC<AddressLookupLayoutControlsProps> =
    ({
        layout,
        parameterDictionary,
        parameterAssignment,
        commitContext,

     }) =>
    {
        const types = useTypes();
        const formContexts = useContext(FormLayoutContexts);
        const context =
            useMemo(
                () =>
                    new LayoutContext(
                        parameterDictionary,
                        parameterAssignment,
                        commitContext,
                        true,
                        formContexts
                    ),
                [
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                    formContexts,
                ]
            );
        const setStreetParameter =
            useCallback(
                (value?: FormParameter<any>) =>
                    runInAction(
                        () =>
                            layout.streetParameter = value
                    ),
                [
                    layout
                ]
            );
        const setHouseNumberParameter =
            useCallback(
                (value?: FormParameter<any>) =>
                    runInAction(
                        () =>
                            layout.houseNumberParameter = value
                    ),
                [
                    layout
                ]
            );
        const setHouseNumberSuffixParameter =
            useCallback(
                (value?: FormParameter<any>) =>
                    runInAction(
                        () =>
                            layout.houseNumberSuffixParameter = value
                    ),
                [
                    layout
                ]
            );
        const setPostalCodeParameter =
            useCallback(
                (value?: FormParameter<any>) =>
                    runInAction(
                        () =>
                            layout.postalCodeParameter = value
                    ),
                [
                    layout
                ]
            );
        const setCityParameter =
            useCallback(
                (value?: FormParameter<any>) =>
                    runInAction(
                        () =>
                            layout.cityParameter = value
                    ),
                [
                    layout
                ]
            );
        const setCountryParameter =
            useCallback(
                (value?: FormParameter<any>) =>
                    runInAction(
                        () =>
                            layout.countryParameter = value
                    ),
                [
                    layout
                ]
            );
        const setOnLookup =
            useCallback(
                (value: LayoutAction) =>
                    runInAction(
                        () =>
                            layout.onLookup = value
                    ),
                [
                    layout
                ]
            );
        const setAutoFocused =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            layout.isAutoFocused = value
                    ),
                [
                    layout
                ]
            );
        const booleanType =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        DataObject.getTypeById('Boolean')
                    ),
                []
            );

        return <CardInset>
            <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <Input
                        labelPosition="top"
                        label={types.Address.Field.Street.getName()}
                    >
                        <FormParameterEditor
                            context={context}
                            value={layout.streetParameter}
                            onChange={setStreetParameter}
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        labelPosition="top"
                        label={types.Address.Field.HouseNr.getName()}
                    >
                        <FormParameterEditor
                            context={context}
                            value={layout.houseNumberParameter}
                            onChange={setHouseNumberParameter}
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        labelPosition="top"
                        label={types.Address.Field.HouseNrSuffix.getName()}
                    >
                        <FormParameterEditor
                            context={context}
                            value={layout.houseNumberSuffixParameter}
                            onChange={setHouseNumberSuffixParameter}
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        labelPosition="top"
                        label={types.Address.Field.PostalCode.getName()}
                    >
                        <FormParameterEditor
                            context={context}
                            value={layout.postalCodeParameter}
                            onChange={setPostalCodeParameter}
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        labelPosition="top"
                        label={types.Address.Field.City.getName()}
                    >
                        <FormParameterEditor
                            context={context}
                            value={layout.cityParameter}
                            onChange={setCityParameter}
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        labelPosition="top"
                        label={types.Address.RelationshipDefinition.Country.getName(false)}
                    >
                        <FormParameterEditor
                            context={context}
                            value={layout.countryParameter}
                            onChange={setCountryParameter}
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        labelPosition="top"
                        label="On lookup"
                    >
                        <LayoutActionEditor
                            context={context}
                            action={layout.onLookup}
                            onChange={setOnLookup}
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        labelPosition="top"
                        label="Autofocused"
                    >
                        <ComputationEditor
                            value={layout.isAutoFocused}
                            onChange={setAutoFocused}
                            context={context}
                            type={booleanType}
                        />
                    </Input>
                </ViewGroupItem>
            </ViewGroup>
        </CardInset>;
    };

export default observer(AddressLookupLayoutControls);
