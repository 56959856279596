import React from 'react';
import NumberEditor from '../../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';

interface DayOfMonthEditorProps
{
    value: number;
    onChange: (value: number) => void;
    disabled?: boolean;
}

export const DayOfMonthEditor: React.FC<DayOfMonthEditorProps> =
    ({
        value,
        onChange,
        disabled,
     }) =>
    {
        return <NumberEditor
            value={value}
            onChange={onChange}
            min={1}
            decimalScale={0}
            disabled={disabled}
        />;
    };
