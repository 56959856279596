import useSetting from '../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../Setting/SettingStore';
import { useMemo } from 'react';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import getConnectorActivationByCode from '../../../../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';
import useIsConnectorActivationActivated from '../../../../Marketplace/Api/useIsConnectorActivationActivated';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import { SMTPServerConfiguration } from '../../../../Marketplace/Connector/Type/SMTPServer/model/SMTPServerConfiguration';
import useTypes from '../../../../Entity/Type/Api/useTypes';

export default function useAllowedDomains()
{
    const [ emailAllowedDomainsConfig ] =
        useSetting<any>(
            SettingSource.Organization,
            'Email.AllowedDomains',
            true
        );

    return useMemo(
        () =>
            emailAllowedDomainsConfig.domains as string[] || [],
        [
            emailAllowedDomainsConfig
        ]
    );
}
