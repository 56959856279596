import { EntityConstraintBuilderFilterContext } from './Model/EntityConstraintBuilderFilterContext';
import { ConstraintNode } from '../../../../../../@Api/Model/Implementation/ConstraintNode';
import NotPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/NotPredicate';
import { NotConstraintNode } from '../../../../../../@Api/Model/Implementation/NotConstraintNode';
import { buildConstraintNode } from './buildConstraintNode';

export function buildNotConstraintNode(
    filter: NotPredicate,
    context: EntityConstraintBuilderFilterContext
): ConstraintNode
{
    const childConstraintNode =
        buildConstraintNode(
            filter.predicate,
            context
        );

    if (childConstraintNode)
    {
        return new NotConstraintNode(childConstraintNode);
    }
    else
    {
        return undefined;
    }
}
