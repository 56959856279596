import React, { useCallback } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LabelButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import useTypes from '../../../../Type/Api/useTypes';
import { green } from '@material-ui/core/colors';
import useCount from '../../../../Selection/Hooks/useCount';
import { getModel } from '../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import constructRelatedEntity from '../../../../Dataset/Segment/Api/constructRelatedEntity';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import useSetting from '../../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../../Setting/SettingStore';
import { CommitContextImpl } from '../../../../../../../@Api/Entity/Commit/Context/CommitContextImpl';

export interface SalesOpportunityProps
{
    entity: Entity;
}

const SalesOpportunity: React.FC<SalesOpportunityProps> =
    props =>
    {
        const types = useTypes();
        const createProject =
            useCallback(
                () =>
                    constructRelatedEntity(
                        getModel(props.entity),
                        EntityPath.fromEntity(props.entity)
                            .joinTo(
                                types.Activity.RelationshipDefinition.LinkedActivities,
                                false)
                            .castTo(types.Activity.Project.Type),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        new CommitContextImpl({
                            allowAutoCommit: false,
                        })
                    ),
                [
                    props.entity,
                    types,
                ]);

        const createWorkOrder =
            useCallback(
                () =>
                    constructRelatedEntity(
                        getModel(props.entity),
                        EntityPath.fromEntity(props.entity)
                            .joinTo(
                                types.Activity.RelationshipDefinition.LinkedActivities,
                                false)
                            .castTo(types.Activity.WorkOrder.Type),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        new CommitContextImpl({
                            allowAutoCommit: false,
                        })
                    ),
                [
                    props.entity,
                    types,
                ]);

        const createInvoice =
            useCallback(
                () =>
                    constructRelatedEntity(
                        getModel(props.entity),
                        EntityPath.fromEntity(props.entity)
                            .joinTo(
                                types.Activity.RelationshipDefinition.LinkedActivities,
                                false)
                            .castTo(types.Activity.Invoice.Type),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        new CommitContextImpl({
                            allowAutoCommit: false,
                        })
                    ),
                [
                    props.entity,
                    types,
                ]);

        const createSalesOrder =
            useCallback(
                () =>
                    constructRelatedEntity(
                        getModel(props.entity),
                        EntityPath.fromEntity(props.entity)
                            .joinTo(
                                types.Activity.RelationshipDefinition.LinkedActivities,
                                false)
                            .castTo(types.Activity.SalesOrder.Type),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        new CommitContextImpl({
                            allowAutoCommit: false,
                        })
                    ),
                [
                    props.entity,
                    types,
                ]);

        const createEvent =
            useCallback(
                () =>
                    constructRelatedEntity(
                        getModel(props.entity),
                        EntityPath.fromEntity(props.entity)
                            .joinTo(
                                types.Activity.RelationshipDefinition.LinkedActivities,
                                false)
                            .castTo(types.Activity.Event.Type),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        new CommitContextImpl({
                            allowAutoCommit: false,
                        })
                    ),
                [
                    props.entity,
                    types,
                ]);

        const [ isSalesOrderEnabled ] = useSetting(SettingSource.Organization, 'Invoicing.IsSalesOrderEnabled');

        const createSubscription =
            useCallback(
                () =>
                    constructRelatedEntity(
                        getModel(props.entity),
                        EntityPath.fromEntity(props.entity)
                            .joinTo(
                                types.Activity.RelationshipDefinition.LinkedActivities,
                                false)
                            .castTo(types.Activity.Subscription.Type),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        new CommitContextImpl({
                            allowAutoCommit: false,
                        })
                    ),
                [
                    props.entity,
                    types,
                ]);

        // const createSubscription = useSubscriptionConstructor(getModel(props.entity));
        //  = useProjectConstructor(getModel(props.entity));
        // const createInvoice = useInvoiceConstructor(getModel(props.entity));

        const numberOfIntervalProductLines =
            useCount(
                types.ProductLine.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Activity.RelationshipDefinition.ProductLines,
                                        true),
                                    props.entity))
                        .where(
                            cb =>
                                cb.isDefined(rootPath.field(types.ProductLine.Field.RepeatInterval))),
                [
                    types,
                    props.entity
                ]);

        const numberOfProductLines =
            useComputed(
                () =>
                    props.entity.getObjectValueByField(types.Activity.Field.NumberOfProductLines),
                [
                    props.entity
                ]);

        const hasSubscriptionButton =
            useComputed(
                () =>
                    types.Activity.Subscription.Type && numberOfIntervalProductLines > 0,
                [
                    types,
                    numberOfIntervalProductLines
                ]);
        const numberOfSubscriptions =
            useCount(
                hasSubscriptionButton ? types.Activity.Subscription.Type : undefined,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath.joinTo(
                                    types.Activity.RelationshipDefinition.LinkedActivities,
                                    true),
                                props.entity)),
                [
                    types,
                    props.entity
                ]);

        const numberOfProjects =
            useCount(
                types.Activity.Project.Type,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath.joinTo(
                                    types.Activity.RelationshipDefinition.LinkedActivities,
                                    true),
                                props.entity)),
                [
                    types,
                    props.entity
                ]);

        const hasInvoiceButton =
            useComputed(
                () =>
                    types.Activity.Invoice.Type && !isSalesOrderEnabled && numberOfProductLines > 0,
                [
                    types,
                    numberOfProductLines
                ]);
        const numberOfInvoices =
            useCount(
                hasInvoiceButton ? types.Activity.Invoice.Type : undefined,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath.joinTo(
                                    types.Activity.RelationshipDefinition.LinkedActivities,
                                    true),
                                props.entity)),
                [
                    types,
                    props.entity
                ]);

        const hasSalesOrderButton =
            useComputed(
                () =>
                    types.Activity.SalesOrder.Type && isSalesOrderEnabled && numberOfProductLines > 0,
                [
                    types,
                    numberOfProductLines
                ]);
        const numberOfSalesOrders =
            useCount(
                hasSalesOrderButton ? types.Activity.SalesOrder.Type : undefined,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath.joinTo(
                                    types.Activity.RelationshipDefinition.LinkedActivities,
                                    true),
                                props.entity)),
                [
                    types,
                    props.entity
                ]);

        const numberOfEvents =
            useCount(
                types.Activity.Event.Type,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath.joinTo(
                                    types.Activity.RelationshipDefinition.LinkedActivities,
                                    true),
                                props.entity)),
                [
                    types,
                    props.entity
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={10}
            alignment="center"
        >
            {
                hasSubscriptionButton &&
                    <ViewGroupItem>
                        <LabelButton
                            label={`+ ${types.Activity.Subscription.Type.getName()}`}
                            onClick={createSubscription}
                            filled
                            size="sm"
                            color={green[400]}
                            disabled={numberOfSubscriptions === undefined || numberOfSubscriptions > 0}
                        />
                    </ViewGroupItem>
            }
            {
                types.Activity.Project.Type &&
                    <ViewGroupItem>
                        <LabelButton
                            label={`+ ${types.Activity.Project.Type.getName()}`}
                            onClick={createProject}
                            filled
                            size="sm"
                            color={green[400]}
                            disabled={numberOfProjects === undefined || numberOfProjects > 0}
                        />
                    </ViewGroupItem>
            }
            {
                types.Activity.WorkOrder.Type &&
                    <ViewGroupItem>
                        <LabelButton
                            label={`+ ${types.Activity.WorkOrder.Type.getName()}`}
                            onClick={createWorkOrder}
                            filled
                            size="sm"
                            color={green[400]}
                        />
                    </ViewGroupItem>
            }
            {
                hasInvoiceButton &&
                    <ViewGroupItem>
                        <LabelButton
                            label={`+ ${types.Activity.Invoice.Type.getName()}`}
                            onClick={createInvoice}
                            filled
                            size="sm"
                            color={green[400]}
                            disabled={numberOfInvoices === undefined || numberOfInvoices > 0}
                        />
                    </ViewGroupItem>
            }
            {
                hasSalesOrderButton &&
                    <ViewGroupItem>
                        <LabelButton
                            label={`+ ${types.Activity.SalesOrder.Type.getName()}`}
                            onClick={createSalesOrder}
                            filled
                            size="sm"
                            color={green[400]}
                            disabled={numberOfSalesOrders === undefined || numberOfSalesOrders > 0}
                        />
                    </ViewGroupItem>
            }
            {
                types.Activity.Event.Type &&
                    <ViewGroupItem>
                        <LabelButton
                            label={`+ ${types.Activity.Event.Type.getName()}`}
                            onClick={createEvent}
                            filled
                            size="sm"
                            color={green[400]}
                            disabled={numberOfEvents === undefined || numberOfEvents > 0}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(SalesOpportunity);
