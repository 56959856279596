import { ContentProps } from '../../Content';
import Base from '../../Base/Base';
import ViewGroup from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import EntityLink from '../../../../../../../../../../Link/EntityLink';
import React from 'react';

export interface ApsisCampaignProps extends ContentProps
{

}

export const ApsisCampaign: React.FC<ApsisCampaignProps> =
    props  =>
    {
        return <Base
            {...props}
            expand
            noInputGroup
        >
            <ViewGroup
                orientation="vertical"
                spacing={8}
            >
                <ViewGroupItem>
                    <EntityLink entity={props.entity} />
                </ViewGroupItem>
            </ViewGroup>
        </Base>
    }