import React from 'react';
import { observer } from 'mobx-react-lite';
import { ValueEditorProps } from '../../ValueEditor';
import CollectionValue from '../../../../../../../../../../@Api/Automation/Value/CollectionValue';
import CollectionType from '../../../../../../../../../../@Api/Automation/Value/Type/CollectionType';
import EntityValueType from '../../../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import EntityCollectionEditor from './Entity/EntityCollectionEditor';
import GenericCollectionEditor from './Generic/GenericCollectionEditor';

export interface CollectionEditorProps extends ValueEditorProps<CollectionType<any>, CollectionValue<any>>
{

}

const CollectionEditor: React.FC<CollectionEditorProps> =
    props =>
    {
        if (props.type.type instanceof EntityValueType)
        {
            return <EntityCollectionEditor
                {...props}
            />;
        }
        else
        {
            return <GenericCollectionEditor
                {...props}
            />;
        }
    };

export default observer(CollectionEditor);
