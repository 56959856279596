import React from 'react';
import { observer } from 'mobx-react-lite';
import InvalidLayout from '../../../../../@Api/Layout/Type/InvalidLayout';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import JsonEditor from '../../../../../@Future/Component/Generic/Input/JsonEditor/JsonEditor';
import { runInAction } from 'mobx';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import Header from '../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import ExpansionPanel from '../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Divider from '../../../../../@Future/Component/Generic/Divider/Divider';

export interface InvalidLayoutViewProps
{
    layout: InvalidLayout;
    editMode?: boolean;
}

const InvalidLayoutView: React.FC<InvalidLayoutViewProps> =
    ({
        layout,
        editMode,
     }) =>
    {
        return <Card>
            <CardInset>
                <div>
                    <strong>
                        Invalid layout
                    </strong>
                </div>
                {
                    editMode &&
                    <div>
                        {layout.message}
                    </div>
                }
            </CardInset>
            {
                editMode && layout.debugInformation &&
                <>
                    <Divider />
                    <ExpansionPanel
                        id="Details"
                        summary={
                            <Header
                                title={
                                    <LocalizedText
                                        code="Generic.Details"
                                        value="Details"
                                    />
                                }
                                inset
                            />
                        }
                        expansion={
                            <CardInset>
                                <code
                                    style={{
                                        whiteSpace: 'pre',
                                    }}
                                >
                                    {layout.debugInformation}
                                </code>
                            </CardInset>
                        }
                    />
                    <Divider />
                    <ExpansionPanel
                        id="Descriptor"
                        summary={
                            <Header
                                title={
                                    <LocalizedText
                                        code="Generic.Descriptor"
                                        value="Descriptor"
                                    />
                                }
                                inset
                            />
                        }
                        expansion={
                            <JsonEditor
                                json={layout.descriptor}
                                onChange={
                                    value =>
                                        runInAction(
                                            () =>
                                                layout.descriptor = value
                                        )
                                }
                            />
                        }
                    />
                </>
            }
        </Card>
    };

export default observer(InvalidLayoutView);
