import performAction from '../../../Entity/performAction';
import ActionInvocation from '../Model/ActionInvocation';
import ActionInvocationResult from '../Model/ActionInvocationResult';

export default async function invokeActionTemplate(invocation: ActionInvocation): Promise<ActionInvocationResult>
{
    const files = new Map<string, File>();

    const result =
        await performAction(
            undefined,
            {
                code: 'ActionTemplate.Invoke',
                parameters: {
                    invocation:
                        invocation.toDescriptor(
                            (fileId, file) =>
                                files.set(fileId, file))
                },
                files: files,
                commitId: invocation.id,
            });

    return ActionInvocationResult.fromDescriptor(
        result.result,
        invocation);
}
