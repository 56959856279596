import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { TranslationEntry } from '../../../@Component/Domain/Configuration/Page/TranslationCenter/TranslationsManager/Model/TranslationEntry';
import { TranslationFieldCount } from '../../../@Component/Domain/Configuration/Page/TranslationCenter/TranslationsManager/Model/TranslationFieldCount';
import { Translation } from '../../../@Component/Domain/Configuration/Page/TranslationCenter/TranslationsManager/Model/Translation';
import { TranslationFilter } from '../../../@Component/Domain/Configuration/Page/TranslationCenter/TranslationsManager/Model/TranslationFilter';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { TranslateLanguageTask } from '../../../@Component/Domain/Configuration/Page/TranslationCenter/TranslationsManager/Model/scheduled_task/TranslateLanguageTask';
import { CommitTranslationsTask } from '../../../@Component/Domain/Configuration/Page/TranslationCenter/TranslationsManager/Model/scheduled_task/CommitTranslationsTask';
import { SyncTranslationsTask } from '../../../@Component/Domain/Configuration/Page/TranslationCenter/TranslationsManager/Model/scheduled_task/SyncTranslationsTask';

export class TranslationController
{
    apiClient: ApiClient;

    constructor(apiClient: ApiClient)
    {
        this.apiClient = apiClient;
    };

    getTranslationPacks()
    {
        return new Promise<string[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        '/translation-entries/packs',
                        Method.Get,
                    ))
                    .then(result =>
                    {
                        resolve(result);
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    }

    getTranslationFieldCounts(
        filter: TranslationFilter
    ): Promise<TranslationFieldCount[]>
    {
        return new Promise<TranslationFieldCount[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        '/translation-entries/group/field',
                        Method.Get,
                        {
                            pack: filter?.packId,
                            language: filter?.language,
                            state: filter?.state,
                        }
                    ))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

    getTranslationEntries(
        page: number,
        pageSize: number,
        filter: TranslationFilter
    ): Promise<TranslationEntry[]>
    {
        return new Promise<TranslationEntry[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        '/translation-entries',
                        Method.Get,
                        {
                            page: page,
                            pageSize: pageSize,
                            field: filter?.entityFieldId,
                            pack: filter?.packId,
                            type: filter?.entityTypeId,
                            language: filter?.language,
                            state: filter?.state,
                            search: filter?.search,
                        }
                    ))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

    getTranslations(
        translationEntryIds: string[],
        languages: string[]
    ): Promise<Translation[]>
    {
        return new Promise<Translation[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        '/translations',
                        Method.Get,
                        {
                            translationEntryIds: JSON.stringify(translationEntryIds),
                            languages: JSON.stringify(languages),
                        }
                    ))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

    saveTranslation(
        id: string | undefined,
        translationEntryId: string,
        language: string,
        translation: string
    ): Promise<Translation>
    {
        return new Promise<Translation>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        id
                            ? `/translations/${id}`
                            : '/translations',
                        Method.Post,
                        JSON.stringify({
                            id: id,
                            translationEntryId: translationEntryId,
                            language: language,
                            translation: translation,
                            }),
                        undefined,
                        true,
                        'application/json'
                    )
                )
                .then(result =>
                {
                    result.ok
                        ? resolve(result.data)
                        : reject(result.error)
                })
                .catch(reason =>
                {
                    reject(reason);
                });
            });
    };

    approveTranslation(
        id: string
    ): Promise<Translation>
    {
        return new Promise<Translation>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        `/translations/${id}/approve`,
                        Method.Post
                    ))
                    .then(result =>
                    {
                        resolve(result);
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

    getTranslateLanguageTasks(
        page: number,
        pageSize: number
    ): Promise<TranslateLanguageTask[]>
    {
        return new Promise<TranslateLanguageTask[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        '/translation-tasks/translate-languages',
                        Method.Get,
                        {
                            page: page,
                            pageSize: pageSize
                        }
                    ))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

    getCommitTranslationsTasks(
        page: number,
        pageSize: number
    ): Promise<CommitTranslationsTask[]>
    {
        return new Promise<CommitTranslationsTask[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        '/translation-tasks/commit-translations',
                        Method.Get,
                        {
                            page: page,
                            pageSize: pageSize
                        }
                    ))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

    getSyncTranslationsTasks(
        page: number,
        pageSize: number
    ): Promise<SyncTranslationsTask[]>
    {
        return new Promise<SyncTranslationsTask[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        '/translation-tasks/sync-translations',
                        Method.Get,
                        {
                            page: page,
                            pageSize: pageSize
                        }
                    ))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

}
