import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import ValueEditor, { ValueEditorProps } from '../../../ValueEditor';
import CollectionType from '../../../../../../../../../../../@Api/Automation/Value/Type/CollectionType';
import CollectionValue from '../../../../../../../../../../../@Api/Automation/Value/CollectionValue';
import ValueType from '../../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import AddIconButton from '../../../../../../../../../../../@Future/Component/Generic/Button/Variant/AddIconButton/AddIconButton';
import ViewGroup from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import DeleteIconButton from '../../../../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import Value from '../../../../../../../../../../../@Api/Automation/Value/Value';
import ComputationBox from '../../../../Computation/Box/ComputationBox';

export interface GenericCollectionEditorProps extends ValueEditorProps<CollectionType<ValueType<any>>, CollectionValue<any>>
{

}

const GenericCollectionEditor: React.FC<GenericCollectionEditorProps> =
    props =>
    {
        const [ newValue, setNewValue ] = useState<Value<any, any> | undefined>(undefined);

        if (props.value)
        {
            return <ol>
                {
                    (props.value?.value ?? []).map(
                        (value, idx) =>
                            <li
                                key={value.getId()}
                            >
                                <ComputationBox>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={10}
                                        alignment="center"
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <ValueEditor
                                                context={props.context}
                                                value={value}
                                                onChange={
                                                    newValue =>
                                                        props.onChange(
                                                            new CollectionValue(
                                                                props.value.value
                                                                    .map(
                                                                        (element, elementIdx) =>
                                                                            idx === elementIdx
                                                                                ? newValue
                                                                                : element
                                                                    ),
                                                                props.value.elementType
                                                            )
                                                        )
                                                }
                                                type={props.value.elementType}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <DeleteIconButton
                                                onClick={
                                                    () =>
                                                        props.onChange(
                                                            new CollectionValue(
                                                                props.value.value
                                                                    .filter(
                                                                        (element, elementIdx) =>
                                                                            elementIdx !== idx
                                                                    ),
                                                                props.value.elementType
                                                            )
                                                        )
                                                }
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ComputationBox>
                            </li>
                    )
                }
                <li>
                    <ComputationBox>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={10}
                            alignment="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <ValueEditor
                                    {...props}
                                    value={newValue}
                                    onChange={setNewValue}
                                    type={props.value.elementType}
                                    autoFocus
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <AddIconButton
                                    onClick={
                                        () =>
                                        {
                                            props.onChange(
                                                new CollectionValue(
                                                    [
                                                        ...props.value.value,
                                                        newValue,
                                                    ],
                                                    props.value.elementType
                                                )
                                            );
                                            setNewValue(undefined);
                                        }
                                    }
                                    disabled={
                                        newValue === undefined
                                            || !newValue.getType().isA(props.value.elementType)
                                            || !newValue.isValid()
                                    }
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ComputationBox>
                </li>
            </ol>;
        }
        else
        {
            return <AddIconButton
                onClick={
                    () =>
                        props.onChange(
                            new CollectionValue(
                                [],
                                props.type.type
                            )
                        )
                }
            />;
        }
    };

export default observer(GenericCollectionEditor);
