export function safelyGetOrUndefined<T>(getter: () => T)
{
    try
    {
        return getter();
    }
    catch (e)
    {
        console.warn('Muted error', e);

        return undefined;
    }
}
