import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import ClickLayout from '../../../../../@Api/Layout/Type/ClickLayout';
import styles from './ClickLayoutViewer.module.scss';
import FormLayoutContexts from '../Form/FormLayoutContexts';
import LayoutContext from '../../../../../@Api/Layout/LayoutContext';

export interface ClickLayoutViewerProps extends LayoutViewerProps<ClickLayout>
{

}

const ClickLayoutViewer: React.FC<ClickLayoutViewerProps> =
    props =>
    {
        const formLayoutContexts = useContext(FormLayoutContexts);
        const click =
            useCallback(
                () =>
                    props.layout.action.apply(
                        new LayoutContext(
                            props.parameterDictionary,
                            props.parameterAssignment,
                            props.commitContext,
                            props.safeMode,
                            formLayoutContexts
                        )
                    ),
                [
                    props.layout,
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                    props.safeMode,
                    formLayoutContexts,
                ]);

        return <div
            className={styles.root}
            onClick={click}
        >
            <LayoutViewer
                {...props}
                layout={props.layout.layout}
            />
        </div>;
    };

export default observer(ClickLayoutViewer);
