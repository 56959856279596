import React, { useCallback, useEffect, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { ContentProps } from '../../Content';
import useTypes from '../../../../Type/Api/useTypes';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import { default as EntityInput } from '../../../../Input/Input';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import Constructor from '../../../Constructor';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import CancelButton from '../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import useFetchedRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import useToggle from '../../../../../../../@Util/Toggle/useToggle';
import { openEntity } from '../../../../@Util/openEntity';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import getErrorMessage from '../../../../../../../@Api/Error/getErrorMessage';

export interface PartnerProps extends ContentProps
{
}

const Partner: React.FC<PartnerProps> =
    ({
         entity,
         commitContext,
         onClose,
         touched,
     }) =>
    {
        const types = useTypes();
        const newCommitContext = useNewCommitContext();
        const [ isSaving, toggleIsSaving ] = useToggle(false);

        const [ newInvoicingAddress, ] = useState<Entity>(
            newCommitContext.createEntity(types.Address.Type)
        );

        const organization =
            useRelatedEntity(
                entity,
                types.Relationship.Organization.RelationshipDefinition.Organization,
                false,
                commitContext
            );

        const [ invoicingAddress, ] =
            useFetchedRelatedEntity(
                organization,
                types.Relation.Organization.RelationshipDefinition.InvoiceAddress,
                false,
                commitContext
            );

        const clearAddress =
            useCallback(
                (address: Entity) =>
                {
                    [
                        types.Address.Field.Street,
                        types.Address.Field.HouseNr,
                        types.Address.Field.HouseNrSuffix,
                        types.Address.Field.PostalCode,
                        types.Address.Field.City,
                    ].map(
                        field =>
                            address.setValueByField(field, undefined)
                    );

                    [
                        types.Address.RelationshipDefinition.State,
                        types.Address.RelationshipDefinition.Country,
                    ].map(
                        relationship =>
                            address.updateRelationship(false, relationship, undefined)
                    );
                },
                [
                    types
                ]
            );

        useEffect(
            () =>
            {
                clearAddress(newInvoicingAddress)
            },
            [
                organization,
                newInvoicingAddress,
                clearAddress
            ]
        )

        const isAddressValid =
            useCallback(
                (address: Entity, context: CommitContext) =>
                    address.hasValueForField(types.Address.Field.Street, context)
                    && address.hasValueForField(types.Address.Field.HouseNr, context)
                    && address.hasValueForField(types.Address.Field.PostalCode, context)
                    && address.hasValueForField(types.Address.Field.City, context)
                    && address.hasRelationshipsByDefinition(
                        false,
                        types.Address.RelationshipDefinition.Country,
                        context),
                [
                    types
                ]
            );

        const isValid =
            useComputed(
                () =>
                    organization
                    && ((invoicingAddress && isAddressValid(invoicingAddress, commitContext))
                        || (!invoicingAddress && isAddressValid(newInvoicingAddress, newCommitContext))),
                [
                    organization,
                    invoicingAddress,
                    commitContext,
                    newInvoicingAddress,
                    newCommitContext,
                ]
            );

        const isAddressFieldRequired =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    fieldPath.field !== types.Address.Field.HouseNrSuffix
                    && !(fieldPath.relationshipDefinition
                        && fieldPath.relationshipDefinition === types.Address.RelationshipDefinition.State),
                [
                    types
                ]
            );

        const commit =
            useCallback(
                () =>
                    commitContext.commit()
                        .then(
                            () =>
                            {
                                onClose();
                                openEntity(entity);
                            }
                        )
                        .catch(
                            error =>
                                loadModuleDirectly(FeedbackStore).enqueueSnackbar(
                                    getErrorMessage(error),
                                    {
                                        variant: 'error',
                                        autoHideDuration: 5000,
                                    })
                        )
                        .finally(
                            () =>
                                toggleIsSaving(false)
                        ),
                [
                    commitContext,
                    onClose,
                    entity,
                    toggleIsSaving,
                ]
            )

        const onCreatePartner =
            useCallback(
                () =>
                {
                    toggleIsSaving(true);

                    if (!invoicingAddress)
                    {
                        newInvoicingAddress.updateRelationship(
                            true,
                            types.Relation.Organization.RelationshipDefinition.InvoiceAddress,
                            organization
                        );

                        newCommitContext.commit()
                            .then(
                                () =>
                                    commit()
                            );
                    }
                    else
                    {
                        commit();
                    }
                },
                [
                    types,
                    organization,
                    toggleIsSaving,
                    invoicingAddress,
                    newInvoicingAddress,
                    newCommitContext,
                    commit
                ]
            );

        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            <ViewGroupItem>
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    <ViewGroupItem>
                        <EntityInput
                            entity={entity}
                            field={types.Relationship.Organization.RelationshipDefinition.Organization}
                            labelPosition="left"
                            touched={touched}
                            commitContext={commitContext}
                            doAutocommit={false}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <EntityInput
                            entity={entity}
                            field={types.Relationship.Organization.Partner.RelationshipDefinition.PartnerRelationshipPriceList}
                            labelPosition="left"
                            touched={touched}
                            commitContext={commitContext}
                            doAutocommit={false}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
            <ViewGroupItem>
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    <ViewGroupItem>
                        {types.Relation.Organization.RelationshipDefinition.InvoiceAddress.getName(false)}
                    </ViewGroupItem>
                    {
                        invoicingAddress &&
                        <ViewGroupItem>
                            <Constructor
                                entity={invoicingAddress}
                                disableActions
                                required={isAddressFieldRequired}
                                commitContext={commitContext}
                                autoCommit={false}
                            />
                        </ViewGroupItem>
                    }
                    {
                        !invoicingAddress &&
                        <ViewGroupItem>
                            <Constructor
                                entity={newInvoicingAddress}
                                disableActions
                                required={isAddressFieldRequired}
                                commitContext={newCommitContext}
                                autoCommit={false}
                            />
                        </ViewGroupItem>
                    }
                </ViewGroup>
            </ViewGroupItem>
            <ViewGroupItem
                alignment="right"
            >
                <RightAlignedButtonGroup>
                    <CancelButton
                        onClick={onClose}
                    />
                    <PrimaryButton
                        label={
                            <LocalizedText
                                code="Generic.Create"
                                value="Creëeren"
                            />
                        }
                        onClick={onCreatePartner}
                        disabled={!isValid || isSaving}
                        loading={isSaving}
                    />
                </RightAlignedButtonGroup>
            </ViewGroupItem>
        </ViewGroup>
    };

export default observer(Partner);