import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { ExpansionPanelStore } from './ExpansionPanelStore';
import { createStyles, Theme, withStyles } from '@material-ui/core';
import { hoverBoxShadow, viewInset } from '../../../@Resource/Theme/Theme';
import ExpansionPanelSummary from './ExpansionPanelSummary';
import ExpansionPanelDetails from './ExpansionPanelDetails';
import ExpansionPanelTimeline from './ExpansionPanelTimeline';
import { View } from '../ViewStack/View';

// Box shadow without having a 'top' border because of the shadow effect
export const expansionPanelBoxShadow = `0px 3px 3px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 3px 1px -2px rgba(0, 0, 0, 0.05) !important`;

export const outsideMargin = 7;

// noinspection TsLint
const styles =
    (theme: Theme) =>
        createStyles({
            rootAvatar:
            {
                // borderBottom: 'purple 1px solid',
                position: 'relative',
                flex: 1,
                '&:before':
                {
                    display: 'none'
                }
            },
            root:
            {
                // borderBottom: 'purple 1px solid',
                position: 'relative',
                flex: 1,
                '&:before':
                {
                    display: 'none'
                }
            },
            rootExpanded:
            {
                '&:before':
                {
                    display: 'none',
                },
                // borderBottom: '0px !important'
            },
            rootPopout:
            {
                margin: '10px 0px !important'
            },
            rootPopoutShadow:
            {
                boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
            },
            viewInset:
            {
                marginRight: viewInset
            }
        });

@observer
class ExpansionPanel extends BaseComponent<ExpansionPanelStore>
{
    renderComponent(store: ExpansionPanelStore)
    {
        if (store.isHidden)
        {
            return null;
        }
        else
        {
            if (store.viewComponent)
            {
                return <View
                    viewComponent={store.viewComponent}
                />;
            }

            return <div
                className={`${store.isExpanded && store.hasPopOut ? this.props.classes.rootPopout : ''}`}
                style={{
                    transition: 'margin 0.2s ease-out'
                }}
            >
                <div
                    className={`
                        ${store.summaryAvatarStore ? this.props.classes.rootAvatar : this.props.classes.root}
                        ${store.isExpanded && store.detailView !== undefined ? this.props.classes.rootExpanded : ''}
                        ${store.isExpanded && store.hasPopOut && store.bottomView === undefined && store.showShadow ? this.props.classes.rootPopoutShadow : ''}`}
                    style={{
                        // margin:
                        //     store.hasOutsideMargin
                        //         ?
                        //             outsideMargin
                        //         :
                        //             0,
                        marginTop:
                            store.hasOutsideTopMargin
                                ?
                                    outsideMargin
                                :
                                    store.hasTopInset
                                        ?
                                            viewInset
                                        :
                                            -1,
                        marginBottom:
                            store.hasOutsideBottomMargin
                                ?
                                    outsideMargin
                                :
                                    store.hasBottomInset
                                        ?
                                            viewInset
                                        :
                                            0,
                        boxShadow:
                            (store.isHovering || (store.isSelected && store.isSelectable) || store.isExpanded)
                                && store.showShadow
                                    ?
                                        hoverBoxShadow
                                    :
                                        (store.isEmbedded
                                            ?
                                                '0px 0px 0px 0px !important'
                                            :
                                                undefined),
                        borderLeft:
                            store.hasBorderLeftRight
                                ?
                                    `1px solid ${store.borderColor}`
                                :
                                    undefined,
                        borderRight:
                            store.hasBorderLeftRight
                                ?
                                    `1px solid ${store.borderColor}`
                                :
                                    undefined,
                        borderTop:
                            !store.hasBorderTop || store.showTimeline
                                ?
                                    'none'
                                :
                                    `1px solid ${store.borderColor}`,
                        borderBottom:
                            store.hasBorderBottom
                                ?
                                    store.showTimeline
                                        ?
                                            'none'
                                        :
                                            `1px solid ${store.borderColor}`
                                :
                                    undefined,
                        ...store.borderRadiusStyle
                    }}
                    onMouseEnter={store.startHovering}
                    onMouseOver={store.startHovering}
                    onMouseLeave={store.stopHovering}
                    onClick={(event: any) => event.stopPropagation()}
                >
                    {
                        store.showTimeline &&
                            <ExpansionPanelTimeline
                                store={store}
                            />
                    }
                    <ExpansionPanelSummary
                        store={store}
                    />
                    <ExpansionPanelDetails store={store} />
                </div>
                {
                    store.bottomView && store.isExpanded &&
                        <View
                            viewComponent={store.bottomView}
                        />
                }
            </div>;
        }
    }
}

export default withStyles(styles)(ExpansionPanel);
