import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { EntitySelectionBuilder } from '../../Entity/Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../Entity/Path/@Model/EntityPath';
import useTypes from '../../Entity/Type/Api/useTypes';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TabBar from '../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import Card from '../../../../@Future/Component/Generic/Card/Card';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import CloseButton from '../../../../@Future/Component/Generic/Button/Variant/CloseButton/CloseButton';
import SearchItemSearcher from './SearchItemSeacher/SearchItemSearcher';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import useSetting from '../../Setting/Api/useSetting';
import { SettingSource } from '../../Setting/SettingStore';
import { Setting } from '../../../../@Api/Settings/Setting';

export interface SearcherProps
{
    onOpen: (entity: Entity) => void;
    onClose: () => void;
    query?: string;
}

export interface SearchItem
{
    id: string;
    name: string;
    entityType: EntityType;
    builder: (builder: EntitySelectionBuilder, rootPath: EntityPath, query: string) => void;
}

export const SearchResultLimit = 50;

const Searcher: React.FC<SearcherProps> =
    props =>
    {
        const types = useTypes();

        const [ isEmailLegacySearchBehaviourEnabled ] =
            useSetting(
                SettingSource.Organization,
                Setting.Emailing.IsLegacySearchBehaviourEnabled)
        const items =
            useMemo<SearchItem[]>(
                () => [
                    {
                        id: 'Relationships',
                        name: types.Relation.Type.getName(true),
                        entityType: types.Relationship.Type,
                        builder:
                            (builder, rootPath, query) =>
                                builder
                                    .where(
                                        cb =>
                                            cb.search(
                                                rootPath.field(types.Entity.Field.Name),
                                                undefined,
                                                query))
                                    .if(
                                        () => true,
                                        sb =>
                                            [
                                                types.Relationship.Person.Type,
                                                types.Relationship.Organization.Type
                                            ].forEach(
                                                castType =>
                                                    castType.bespoke.getListDependencies()
                                                        .forEach(
                                                            dependency =>
                                                                sb.join(
                                                                    rootPath
                                                                        .castTo(castType)
                                                                        .join(dependency)))))
                    },
                    {
                        id: 'Activities',
                        name: types.Activity.Type.getName(true),
                        entityType: types.Activity.Type,
                        builder:
                            (builder, rootPath, query) =>
                                builder
                                    .where(
                                        cb =>
                                            cb.getSearchTerms(query)
                                                .forEach(
                                                    term =>
                                                        cb.or(
                                                            ob =>
                                                                ob
                                                                    .contains(
                                                                        rootPath
                                                                            .field(types.Activity.Field.Subject),
                                                                        undefined,
                                                                        term)
                                                                    // The name field might be a different one for a subtype:
                                                                    .contains(
                                                                        rootPath
                                                                            .field(types.Entity.Field.Name),
                                                                        undefined,
                                                                        term)
                                                                    .contains(
                                                                        rootPath
                                                                            .joinTo(
                                                                                types.Relationship.RelationshipDefinition.Activities,
                                                                                true)
                                                                            .field(types.Entity.Field.Name),
                                                                        undefined,
                                                                        term)
                                                                    .contains(
                                                                        rootPath
                                                                            .joinTo(
                                                                                types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                                                                true)
                                                                            .field(types.Entity.Field.Name),
                                                                        undefined,
                                                                        term))))
                    },
                    {
                        id: 'Emails',
                        name: types.Activity.Email.Type.getName(true),
                        entityType: types.Activity.Email.Type,
                        builder:
                            (builder, rootPath, query) =>
                                builder
                                    .where(
                                        cb =>
                                            cb.getSearchTerms(query)
                                                .forEach(
                                                    term =>
                                                        cb.or(
                                                            ob =>
                                                                ob
                                                                    .contains(
                                                                        rootPath
                                                                            .field(types.Activity.Field.Subject),
                                                                        undefined,
                                                                        term)
                                                                    .contains(
                                                                        rootPath
                                                                            .joinTo(
                                                                                types.Relationship.RelationshipDefinition.Activities,
                                                                                true)
                                                                            .field(types.Entity.Field.Name),
                                                                        undefined,
                                                                        term)
                                                                    .contains(
                                                                        rootPath
                                                                            .joinTo(
                                                                                types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                                                                true)
                                                                            .field(types.Entity.Field.Name),
                                                                        undefined,
                                                                        term)
                                                                    .contains(
                                                                        rootPath
                                                                            .castTo(types.Activity.Email.Type)
                                                                            .field(
                                                                                isEmailLegacySearchBehaviourEnabled
                                                                                    ? types.Activity.Email.Field.HTML
                                                                                    : types.Activity.Email.Field.TextContent),
                                                                        undefined,
                                                                        term))))
                    },
                    ...types.CustomEntity.Type.getAllInstantiableTypes()
                        .map(
                            customType => ({
                                id: `CustomType.${customType.id}`,
                                name: customType.getName(true),
                                entityType: customType,
                                builder:
                                    (builder, rootPath, query) =>
                                        builder
                                            .where(
                                                cb =>
                                                    cb.search(
                                                        rootPath.field(types.Entity.Field.Name),
                                                        undefined,
                                                        query))
                            })),
                    {
                        id: 'Notes',
                        name: types.Note.Type.getName(true),
                        entityType: types.Note.Type,
                        builder:
                            (builder, rootPath, query) =>
                                builder
                                    .join(
                                        rootPath
                                            .joinTo(
                                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.CreatedEntities,
                                                true))
                                    .where(
                                        cb =>
                                            cb.search(
                                                rootPath
                                                    .field(types.Note.Field.Content),
                                                undefined,
                                                query))
                    }
                ],
                [
                    types,
                    isEmailLegacySearchBehaviourEnabled
                ]);

        const [ item, setItem ] = useState<SearchItem>(items[0]);

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            <ViewGroupItem>
                <Card>
                    <TabBar
                        value={item}
                        appendix={
                            <CloseButton
                                onClick={props.onClose}
                            />
                        }
                    >
                        {
                            items.map(
                                item =>
                                    <Tab
                                        key={item.id}
                                        value={item}
                                        onClick={setItem}
                                    >
                                        {item.name}
                                    </Tab>)
                        }
                    </TabBar>
                </Card>
            </ViewGroupItem>
            <ViewGroupItem>
                <SearchItemSearcher
                    {...props}
                    item={item}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(Searcher);
