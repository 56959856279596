import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import Switch from '../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import { Field } from '../../Model/Field';
import useSetting from '../../../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../../../Setting/SettingStore';
import resolveInputFromFieldPath from '../../../../../../Multiplayer/Model/Input/Api/resolveInputFromFieldPath';
import { Setting } from '../../../../../../../../@Api/Settings/Setting';

export interface IsHiddenEditorProps
{
    entityType: EntityType;
    field: Field;
}

const IsHiddenEditor: React.FC<IsHiddenEditorProps> =
    props =>
    {
        const [ hiddenFields, setHiddenFields ] = useSetting(SettingSource.Organization, Setting.Metadata.HiddenFields);
        const input =
            useComputed(
                () =>
                    props.field.fieldPath &&
                        resolveInputFromFieldPath(props.field.fieldPath),
                [
                    props.field
                ]);
        const isHidden =
            useMemo(
                () =>
                    hiddenFields && input ? hiddenFields[input.id()] : undefined,
                [
                    hiddenFields,
                    input
                ]);
        const onChange =
            useCallback(
                (isHidden: boolean) =>
                {
                    const descriptor = Object.assign({}, hiddenFields);
                    descriptor[input.id()] = isHidden;

                    return setHiddenFields(descriptor);
                },
                [
                    hiddenFields,
                    setHiddenFields,
                    input
                ]);

        if (input)
        {
            return <Switch
                checked={isHidden}
                onToggle={onChange}
                disabled={input.isRequired()}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(IsHiddenEditor);
