import React from 'react';
import useTypes from '../../../../../../../../../../../Type/Api/useTypes';
import { Entity } from '../../../../../../../../../../../../../../@Api/Model/Implementation/Entity';
import localizeText from '../../../../../../../../../../../../../../@Api/Localization/localizeText';
import useApsisCampaignResultCount from '../Api/useApsisCampaignResultViewCount';
import { NumberChart } from './NumberChart';
import { observer } from 'mobx-react-lite';

export interface ApsisCampaignResultChartBouncedProps
{
    size: number;
    apsisCampaignResult: Entity;
}

export const ApsisCampaignResultChartBounced: React.FC<ApsisCampaignResultChartBouncedProps> =
    observer(
        (
            {
                apsisCampaignResult,
                size
            }

        ) =>
        {
            const types = useTypes();
            const bounceCount =
                useApsisCampaignResultCount(
                    apsisCampaignResult,
                    types.ApsisCampaignEvent.EventType.Bounce
                );

            return <NumberChart
                    value={bounceCount}
                    size={size}
                    label={localizeText('ApsisCampaign.Chart.Undeliverable', 'Onbezorgbaar')}
                />;
        }
    );