import { UrlEditor } from './UrlEditor';
import { UrlView } from './UrlView';
import { UrlSpecification } from './UrlSpecification';
import { TextType } from '../TextType';
import { DataObjectEditorType, DataObjectSpecificationType, DataObjectViewerType } from '../../../Model/DataObjectType';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import Editor from './Editor';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export class UrlType extends TextType
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Url';
    }

    name()
    {
        return localizeText('DataObject.Type.URL', 'URL');
    }

    view(): DataObjectViewerType
    {
        return UrlView;
    }

    hooksEditor()
    {
        return Editor;
    }

    editor(): DataObjectEditorType
    {
        return UrlEditor;
    }

    specification(): DataObjectSpecificationType
    {
        return UrlSpecification;
    }
}
