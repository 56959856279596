import Segment from '../../Model/Segment';
import { useComputed } from 'mobx-react-lite';

export default function useNextSegments(segments: Segment[]): [ Segment, Segment[] ]
{
    return useComputed(
        () =>
        {
            const [ nextSegment, ...nextSegments ] = segments;

            return [
                nextSegment,
                nextSegments
            ];
        },
        [
            segments
        ]);
}
