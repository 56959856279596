import moment, { Moment } from 'moment';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/sv';
import 'moment/locale/da';
import numbro from 'numbro';
import { computed } from 'mobx';
import { injectWithQualifier } from '../../@Util/DependencyInjection/Injection/DependencyInjection';
import { WelcomeStore } from '../Welcome/WelcomeStore';
import { BaseStore } from '../../@Framework/Store/BaseStore';
import { Currency, EuroCurrency, SymbolPosition } from '../../@Api/Localization/Currency';
import { createStringComparator } from '../../@Component/Generic/List/V2/Comparator/StringComparator';

const noYearLocaleFormat =
    moment
        .localeData()
        .longDateFormat('LL')
        .replace(/[,-/.]*\s*Y+\s*/, '');

export const FallbackLanguageCode = 'en';

export class Localizer extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('WelcomeStore') welcomeStore: WelcomeStore;

    // ------------------------- Properties -------------------------

    dateLocale: any;

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return this.initializeLocale();
    }

    async initializeLocale()
    {
        // Initialize moment
        moment.locale(this.languageCode);

        // Initialize date-fns locale
        this.dateLocale = (await import(`date-fns/locale/${this.dateFnsLocaleCode}/index.js`)).default;

        this.initializeNumbro();
    }

    // -------------------------- Computed --------------------------

    @computed
    get languageCode(): string
    {
        return this.welcomeStore?.welcomePackage?.languagePackage?.languageCode ?? 'nl';
    }

    @computed
    get countryCode(): string
    {
        return this.welcomeStore?.welcomePackage?.languagePackage?.countryCode ?? 'NL';
    }

    @computed
    get locale(): string
    {
        const languageCode =
            !this.languageCode
                ? 'en'
                : this.languageCode.toLowerCase();

        const countryCode =
            !this.countryCode
                ? 'NL'
                : this.countryCode.toUpperCase();

        return languageCode + '-' + countryCode;
    }

    @computed
    get baseCurrency(): Currency
    {
        return this.welcomeStore?.welcomePackage?.currencies.find(currency => currency.isBase) ?? EuroCurrency;
    }

    @computed
    get currencies(): Currency[]
    {
        return this.welcomeStore?.welcomePackage?.currencies ?? [ EuroCurrency ];
    }

    @computed
    get currenciesByCode(): Map<string, Currency>
    {
        return new Map(
            (this.welcomeStore?.welcomePackage?.currencies ?? [ EuroCurrency ])
                .sort(createStringComparator(currency => currency.code))
                .map(
                    currency => [
                        currency.code,
                        currency
                    ]
                )
            );
    }

    @computed
    get dateFnsLocaleCode(): string
    {
        if (this.languageCode === 'en')
        {
            return 'en-GB';
        }
        else if (this.languageCode === 'no')
        {
            // https://github.com/date-fns/date-fns/issues/2963
            return 'nb';
        }
        else
        {
            return this.languageCode;
        }
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    formatYear(date: Date | Moment,
               isCompact: boolean = false): string
    {
        if (isCompact)
        {
            return `'${moment(date).format('YY')}`;
        }
        else
        {
            return moment(date).format('YYYY');
        }
    }

    formatDate(date: Date | Moment,
               isCompact: boolean = false,
               hideYear: boolean = false): string
    {
        if (date)
        {
            date = moment(date);

            if (isCompact)
            {
                // Remove dots from month (e.g. Sep. will be Sep)'
                return `${date.format('D')} ${date.format('MMM').replace(/\./g, '')}${hideYear ? '' : ` '${date.format('YY')}`}`;
            }
            else
            {
                if (hideYear)
                {
                    return moment(date).format(noYearLocaleFormat);
                }
                else
                {
                    return moment(date).format('LL');
                }
            }
        }
        else
        {
            return undefined;
        }
    }

    formatDateTime(date: Date | Moment,
                   hideYear: boolean = false): string
    {
        if (date)
        {
            date = moment(date);

            return `${date.format('D')} ${date.format('MMM').replace(/\./g, '')}${hideYear ? '' : ` '${date.format('YY')}`} ${date.format('LT')}`;

        }
        else
        {
            return undefined;
        }
    }

    formatNumeralDate(date: Date | Moment)
    {
        if (date)
        {
            return moment(date).format('L');
        }
        else
        {
            return undefined;
        }
    }

    formatNumeralDateTime(date: Date | Moment)
    {
        if (date)
        {
            const momentDate = moment(date);

            return `${momentDate.format('L')} ${this.formatTime(momentDate, false)}`;
        }
        else
        {
            return undefined;
        }
    }

    formatTime(date: Date | Moment,
               includeSeconds: boolean = false): string
    {
        if (date)
        {
            return moment(date).format(includeSeconds ? 'LTS' : 'LT');
        }
        else
        {
            return undefined;
        }
    }

    formatDateTimeFromNow(date: Date | Moment): string
    {
        return moment(date).fromNow();
    }

    formatDuration(fromDate: Date | Moment,
                   toDate: Date | Moment): string
    {
        return moment(fromDate).to(moment(toDate));
    }

    formatDateFromNow(date: Date | Moment): string
    {
        const provided = moment(date);
        const today = new Date();

        if (Math.abs(provided.diff(today, 'days')) < 2)
        {
            // Remove time from output
            // TODO: This works only on english/dutch locales, this should be fixed with a more rigid solution
            return provided.calendar(today).replace(/( om | at | )[0-9].*$/g, '');
        }
        else
        {
            return `${provided.format('dddd')} ${this.formatDate(provided)}`;
        }
    }

    initializeNumbro()
    {
        // Initialize numbrojs
        numbro.registerLanguage(
            {
                languageTag: this.locale,
                delimiters: {
                    thousands: '.',
                    decimal: ','
                },
                abbreviations: {
                    thousand: 'k',
                    million: 'mln',
                    billion: 'mrd',
                    trillion: 'bln'
                },
                ordinal: (num) => {
                    let remainder = num % 100;
                    return ((num !== 0 && remainder <= 1) || remainder === 8 || remainder >= 20) ? 'ste' : 'de';
                },
                currency: {
                    symbol: this.baseCurrency.symbol,
                    position: this.baseCurrency.symbolPosition === SymbolPosition.Prefix ? 'prefix' : 'postfix',
                    code: this.baseCurrency.code
                },
                currencyFormat: {
                    output: 'currency',
                    thousandSeparated: true,
                    mantissa: 2,
                    spaceSeparated: true,
                    // average: true
                },
                formats: {
                    fourDigits: {
                        totalLength: 4,
                        spaceSeparated: true,
                        average: true
                    },
                    fullWithTwoDecimals: {
                        output: 'currency',
                        mantissa: 2,
                        spaceSeparated: true,
                        thousandSeparated: true
                    },
                    fullWithTwoDecimalsNoCurrency: {
                        mantissa: 2,
                        thousandSeparated: true
                    },
                    fullWithNoDecimals: {
                        output: 'currency',
                        spaceSeparated: true,
                        thousandSeparated: true,
                        mantissa: 0
                    }}});

        numbro.setLanguage(this.locale);
    }

    // ----------------------- Private logic ------------------------
}
