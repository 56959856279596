import Key from '../Model/Key';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';

export default function isExternalIdKey(key: Key)
{
    const types = loadModuleDirectly(EntityTypeStore).bespoke.types;

    return key.fields.length > 0 // key with no fields is just a new key, and not a external ID key
        && key.fields.every(
            key =>
                key.fieldPath.entityType.isA(types.ExternalId.Type));
}
