import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../Type/Api/useTypes';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { primaryColor, textSecondaryColor } from '../../../../../../@Resource/Theme/Theme';
import { default as MenuItem } from '../../../../../../@Future/Component/Generic/Menu/Item/Item';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';

export interface ItemProps
{
    dashboard: Entity;
    onOpen: (entity: Entity) => void;
    selectedDashboard?: Entity;
    favoriteDashboardIds: Set<string>;
    onToggleFavorite: (entity: Entity) => void;
}

const Item: React.FC<ItemProps> =
    props =>
    {
        const types = useTypes();
        const onClick =
            useCallback(
                () =>
                    props.onOpen(props.dashboard),
                [
                    props.onOpen,
                    props.dashboard
                ]);

        const onToggle =
            useCallback(
                () =>
                    props.onToggleFavorite(props.dashboard),
                [
                    props.onToggleFavorite,
                    props.dashboard
                ]);

        return <MenuItem
            key={props.dashboard.uuid}
            icon={props.dashboard.getObjectValueByField(types.Dashboard.Field.Icon) || 'dashboard'}
            name={props.dashboard.name}
            onClick={onClick}
            active={equalsEntity(props.dashboard, props.selectedDashboard)}
            suffix={
                <IconButton
                    icon={props.favoriteDashboardIds.has(props.dashboard.uuid) ? 'star' : 'star_border'}
                    color={props.favoriteDashboardIds.has(props.dashboard.uuid) ? primaryColor : textSecondaryColor}
                    onClick={onToggle}
                    tooltip={props.favoriteDashboardIds.has(props.dashboard.uuid) ? 'Favoriet verwijderen' : 'Favoritiseren'}
                />
            }
        />;
    };

export default observer(Item);
