import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../../Viewer/LayoutViewer';
import PrimitiveValueType from '../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import PrimitiveValue from '../../../../../../@Api/Automation/Value/PrimitiveValue';
import { runInAction } from 'mobx';
import { FileValue } from '../../../../DataObject/Type/File/FileValue';
import FilesFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/FilesFormInputLayout';
import CollectionValue from '../../../../../../@Api/Automation/Value/CollectionValue';
import FileDropZone from '../../../../../../@Future/Component/Generic/Input/FileDropZone/FileDropZone';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import { FileType } from '../../../../DataObject/Type/File/FileType';
import useFormInputLayoutPlaceholder from '../Shared/Api/useFormInputLayoutPlaceholder';
import { useFormInputParameter } from '../Api/useFormInputParameter';

export interface FilesFormInputLayoutViewerProps extends LayoutViewerProps<FilesFormInputLayout>
{

}

const FilesFormInputLayoutViewer: React.FC<FilesFormInputLayoutViewerProps> =
    props =>
    {
        const { layout, parameterDictionary, parameterAssignment, commitContext } = props;

        const parameter =
            useFormInputParameter(
                layout,
                parameterDictionary);

        const setFiles =
            useCallback(
                (files: File[]) =>
                    runInAction(
                        () =>
                            parameterAssignment.setValue(
                                parameter,
                                new CollectionValue(
                                    files.map(
                                        file =>
                                            new PrimitiveValue(
                                                DataObject.constructFromTypeIdAndValue(
                                                    'File',
                                                    FileValue.fromFile(file)))),
                                    new PrimitiveValueType(DataObject.getTypeById('File'))))),
                [
                    parameterAssignment,
                    parameter
                ]);

        const files =
            useComputed(
                () =>
                {
                    const value = parameterAssignment.getValue(parameter);

                    if (value instanceof CollectionValue)
                    {
                        return value.value
                            .filter(
                                element =>
                                    element instanceof PrimitiveValue
                                        && element.value.type instanceof FileType)
                            .map(
                                element =>
                                    ((element as PrimitiveValue).value.value as FileValue).file)
                            .filter(
                                file =>
                                    file !== undefined);
                    }
                    else
                    {
                        return [];
                    }
                },
                [
                    parameterAssignment,
                    parameter
                ]);

        const placeholder =
            useFormInputLayoutPlaceholder(
                layout,
                parameterDictionary,
                parameterAssignment,
                commitContext
            );

        const isDisabled =
            useComputed(
                () =>
                    layout.isDisabled?.synchronouslyEvaluate(
                        new FunctionContext(
                            parameterDictionary,
                            parameterAssignment,
                            commitContext
                        )
                    ),
                [
                    layout,
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                ]);

        return <FileDropZone
            files={files}
            onDrop={setFiles}
            dropContainerLabel={placeholder}
            disabled={isDisabled}
        />;
    };

export default observer(FilesFormInputLayoutViewer);
