import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import DateWithTimeComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/DateWithTimeComputation';
import ComputationBox from '../../Box/ComputationBox';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import InputGroup from '../../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import { DataObject } from '../../../../../../../../DataObject/Model/DataObject';

export interface DateWithTimeComputationEditorProps extends ComputationEditorProps<PrimitiveValueType, DateWithTimeComputation>
{

}

const DateWithTimeComputationEditor: React.FC<DateWithTimeComputationEditorProps> =
    props =>
    {
        const dateType =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        DataObject.getTypeById('Date')
                    ),
                []
            );
        const timeType =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        DataObject.getTypeById('Time')
                    ),
                []
            );
        const setDate =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.date = value),
                [
                    props.value
                ]
            );
        const setTime =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.time = value),
                [
                    props.value
                ]
            );

        return <InputGroup>
            <Input
                label={
                    <LocalizedText
                        code="Generic.Date"
                        value="Date"
                    />
                }
                labelPosition="left"
            >
                <ComputationBox>
                    <ComputationEditor
                        context={props.context}
                        value={props.value.date}
                        onChange={setDate}
                        type={dateType}
                    />
                </ComputationBox>
            </Input>
            <Input
                label={
                    <LocalizedText
                        code="Generic.Time"
                        value="Tijd"
                    />
                }
                labelPosition="left"
            >
                <ComputationBox>
                    <ComputationEditor
                        context={props.context}
                        value={props.value.time}
                        onChange={setTime}
                        type={timeType}
                    />
                </ComputationBox>
            </Input>
        </InputGroup>;
    };

export default observer(DateWithTimeComputationEditor);
