import * as React from 'react';
import { Props as SelectProps } from 'react-select/base';
import { AsyncProps } from 'react-select/async';
import './_style.scss';
import { grey } from '@material-ui/core/colors';
import { primaryFontFamily, primaryFontSize } from '../../../@Resource/Theme/Theme';

interface SelectboxState
{
    isFocused: boolean;
    isHovering: boolean;

    optionsLoaded: boolean;
    isLoading: boolean;
    loadedOptions: any[];
}

export interface SelectboxProps extends SelectProps
{
    label?: string;
    labelStyle?: React.CSSProperties;
    isLabelOnTop?: boolean;
    showLabel?: boolean;
    isInvalid?: boolean;
    isDisabled?: boolean;
    doNotRenderInPortal?: boolean;
    isClearable?: boolean;
}
export type OptionType = { label: string; value: string };
export type AsyncSelectProps<OptionType> = AsyncProps<OptionType> & SelectProps<OptionType>;

export interface SelectboxAsyncProps extends AsyncSelectProps<OptionType>
{
    label?: string;
    labelStyle?: React.CSSProperties;
    isLabelOnTop?: boolean;
    showLabel?: boolean;
    isInvalid?: boolean;
    isDisabled?: boolean;
    doNotRenderInPortal?: boolean;
    isClearable?: boolean;
}

export abstract class AbstractSelectbox<T extends SelectboxProps | SelectboxAsyncProps = SelectboxProps> extends React.Component<T, SelectboxState>
{
    constructor(props: T)
    {
        super(props);

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    abstract selectboxClass(): React.ComponentClass;

    render()
    {
        let customStyles = {
            singleValue:
                (base: any, state: any) =>
                    ({
                        ...base,
                        color: 'inherit', // inherit color from parent component
                        marginLeft: 0,
                        textAlign: (this.props as any).alignRight ? 'right' : undefined,
                        fontFamily: primaryFontFamily,
                        fontSize: primaryFontSize,
                        position: (this.props as any).selectWidthBehavior === 'full' ? 'absolute' : 'relative',
                        alignSelf: (this.props as any).selectWidthBehavior === 'full' ? undefined : 'center',
                        overflow: (this.props as any).selectWidthBehavior === 'full' ? undefined : 'none',
                        top: '0%',
                        transform: 'translateY(0%)',
                        marginRight: 0
                    }),
            valueContainer:
                (base: any, state: any) =>
                    ({
                        ...base,
                        maxHeight: 'none',
                        cursor: 'text',
                        fontFamily: primaryFontFamily,
                        fontSize: primaryFontSize,
                        alignSelf: (this.props as any).selectWidthBehavior === 'full' ? undefined : 'normal',
                        justifyContent: (this.props as any).alignRight ? 'flex-end' : undefined,
                        padding: `0 ${(this.props as any).alignRight ? 0 : 8}px 3px 0`
                    }),
            control:
                (base: any, state: any) =>
                    ({
                        ...base,
                        border: 0,
                        backgroundColor: 'transparent',
                        borderRadius: 0,
                        boxShadow: 'none',
                        minHeight: 25,
                        maxWidth: '100%'
                    }),
            multiValue:
                (base: any, state: any) =>
                    ({
                        ...base,
                        borderRadius: 25,
                        padding: 0,
                        marginRight: 8
                    }),
            multiValueLabel:
                (base: any, state: any) =>
                    ({
                        ...base,
                        whiteSpace: 'normal',
                        wordBreak: 'break-all'
                    }),
            multiValueRemove:
                (base: any, state: any) =>
                    ({
                        ...base,
                        borderRadius: '50%',
                        width: 25,
                        height: 25,
                        paddingLeft: 0,
                        paddingRight: 0,
                        cursor: 'pointer',
                        justifyContent: 'center',
                        display: (this.props as any).isDisabled ? 'none' : 'flex'
                    }),
            menu:
                (base: any, state: any) =>
                    ({
                        ...base,
                        width: 'auto',
                        minWidth: '100%'
                    }),
            indicatorSeparator:
                (base: any, state: any) =>
                    ({
                        ...base,
                        display: 'none'
                    }),
            clearIndicator:
                (base: any, state: any) =>
                    ({
                        ...base,
                    }),
            option:
                (base: any, state: any) =>
                    ({
                        ...base,
                        padding: 0
                    }),
            dropdownIndicator:
                (base: any, state: any) =>
                    ({
                        ...base,
                        color: grey[600],
                        padding: (this.props as any).alignRight ? 0 : 4,
                        display: (this.props as any).alignRight ? 'none' : undefined
                    }),
            input:
                (base: any, state: any) =>
                    ({
                        ...base,
                        textAlign: (this.props as any).alignRight ? 'right' : undefined,
                        paddingRight: 0,
                        paddingBottom: 0,
                        marginRight: -2,
                        fontSize: primaryFontSize,
                        fontFamily: primaryFontFamily,
                        display: (this.props as any).isDisabled ? 'none' : undefined,
                    })
        };

        let props: any = Object.assign({}, this.props);

        if (!this.props.placeholder)
        {
            props.placeholder = '';
        }

        props.styles = Object.assign(customStyles, props.styles);
        props.menuPlacement = 'auto';
        props.menuPosition = 'fixed';
        props.classNamePrefix = 'react-select';

        if (!props.doNotRenderInPortal)
        {
            props.menuPortalTarget = document.getElementById('app-body-portal');
        }

        let selectBox = React.createElement(
            this.selectboxClass(),
            Object.assign(
                {},
                props,
                {
                    onFocus: this.onFocus,
                    onBlur: this.onBlur,
                    onSelect: this.onSelect,
                    onMouseEnter: this.onMouseEnter,
                    onMouseLeave: this.onMouseLeave
                })
        );

        let className = 'Selectbox';

        if (this.state && this.state.isFocused)
        {
            className += ' is-focused';
        }

        if (this.props.isInvalid === true)
        {
            className += ' invalid';
        }

        if (this.props.isDisabled === true)
        {
            className += ' disabled';
        }

        return <div
            className={className}
            style={{position: 'relative' }}
        >
            <label className="Selectbox-label" hidden={this.props.label == null || this.props.showLabel === false || this.props.label === '' || this.props.isLabelOnTop} style={this.props.labelStyle}>
                {this.props.label}
            </label>
            {selectBox}
        </div>;
    }

    onFocus(event: any)
    {
        if (this.props.onFocus)
        {
            this.props.onFocus(event);
        }

        this.setState({ isFocused: true });
    }

    onBlur(event: any)
    {
        if (this.props.onBlur)
        {
            this.props.onBlur(event);
        }

        this.setState({ isFocused: false });
    }

    onMouseEnter(event: any)
    {
        this.setState({ isHovering: true });

    }

    onMouseLeave(event: any)
    {
        this.setState({ isHovering: false });
    }

    onSelect(event: any)
    {

    }
}
