import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './InputContentFit.module.scss';
import useResizeObserver from 'use-resize-observer/polyfilled';

export interface InputContentFitProps
{
    fitContent?: boolean;
    value?: string;
    inputStyle?: any;
}

const InputContentFit: React.FC<InputContentFitProps> =
    props =>
    {
        const { ref, width } = useResizeObserver<HTMLDivElement>();

        const inputStyle =
            useMemo(
                () => ({
                    width: width === undefined ? undefined : Math.max(width, 20),
                }),
                [
                    width,
                ]);

        if (props.fitContent)
        {
            return <>
                <div
                    style={inputStyle}
                >
                    {props.children}
                </div>
                <div
                    ref={ref}
                    className={styles.dummy}
                    style={props.inputStyle}
                >
                    {props.value}
                </div>
            </>;
        }
        else
        {
            return <>{props.children}</>;
        }
    };

export default observer(InputContentFit);
