import { BespokeEntityType } from '../../../BespokeEntityType';
import { EntityTypeStore } from '../../../EntityTypeStore';
import { EntityRelationshipDefinition } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import copyProductLines from '../../../../../../../@Api/Entity/Bespoke/Activity/copyProductLines';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { Aggregate } from '../../../../../DataObject/Model/Aggregate';
import moment from 'moment';
import getSetting from '../../../../../Setting/Api/getSetting';
import { Setting } from '../../../../../../../@Api/Settings/Setting';
import { SettingSource } from '../../../../../Setting/SettingStore';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';

export class BespokeActivityOffer extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Activity.Offer')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    extendRelationshipSelection(entity: Entity,
                                relationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean,
                                builder: EntitySelectionBuilder,
                                commitContext?: CommitContext)
    {
        super.extendRelationshipSelection(
            entity,
            relationshipDefinition,
            isParent,
            builder,
            commitContext
        );

        if (relationshipDefinition === this.types.Activity.Offer.RelationshipDefinition.Template
            && !isParent)
        {
            builder.where(
                cb =>
                    cb.isOfType(
                        builder.rootPath,
                        this.types.Template.Document.Offer.Type));
        }
    }

    onConstruct(entity: Entity,
                commitContext?: CommitContext): void
    {
        super.onConstruct(entity, commitContext);

        if (!entity.hasValueForField(this.types.Activity.Offer.Field.Version, commitContext))
        {
            setValueByFieldInEntity(
                entity,
                this.types.Activity.Offer.Field.Version,
                1,
                commitContext
            );
        }

        if (!entity.hasValueForField(this.types.Activity.Offer.Field.ExpirationDate, commitContext))
        {
            setValueByFieldInEntity(
                entity,
                this.types.Activity.Offer.Field.ExpirationDate,
                moment()
                    .startOf('day')
                    .add(getSetting(SettingSource.Organization, Setting.Sales.Offer.DefaultExpirationInDays) || 14, 'days')
                    .toDate(),
                commitContext
            );
        }
    }

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        // In case of a new offer, if it is linked to another activity with product lines, then copy all unbilled lines
        if (entity.isNew()
            && isParent
            && relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities
            && relatedEntity)
        {
            // Resolve new version number
            await new EntitySelectionBuilder(this.types.Activity.Offer.Type)
                .where(
                    cb =>
                        cb.relatedToEntity(
                            EntityPath.fromEntityType(this.types.Activity.Offer.Type)
                                .joinTo(
                                    this.types.Activity.RelationshipDefinition.LinkedActivities,
                                    true),
                            relatedEntity))
                .aggregateOn(
                    EntityPath.fromEntityType(this.types.Activity.Offer.Type)
                        .field(this.types.Activity.Offer.Field.Version),
                    undefined,
                    Aggregate.Max)
                .selectAggregates()
                .then(
                    aggregate =>
                    {
                        const version = aggregate.aggregates[0].isEmpty ? 1 : aggregate.aggregates[0].value + 1;

                        setValueByFieldInEntity(
                            entity,
                            this.types.Activity.Offer.Field.Version,
                            version,
                            commitContext
                        );
                    });

            // Copy product lines (non-billed ones)
            const isSalesOpportunity = relatedEntity.entityType.isA(this.types.Activity.SalesOpportunity.Type);

            await copyProductLines(
                relatedEntity,
                entity,
                line =>
                    isSalesOpportunity ? true : !line.getObjectValueByField(this.types.ProductLine.Field.IsBilled),
                commitContext
            );

            // Initialize fields
            [
                this.types.Activity.Field.Subject,
                this.types.Activity.Field.DiscountPercentage,
                this.types.Activity.Field.IsVatIncluded,
                this.types.Activity.Field.Currency,
            ].forEach(
                field =>
                    setValueByFieldInEntity(
                        entity,
                        field,
                        relatedEntity.getObjectValueByField(field),
                        commitContext
                    )
            );
        }
    }

    allowAddingNoteOrAttachments(entity: Entity): boolean
    {
        return false;
    }

    hideFieldPath(entity: Entity,
                  fieldPath: EntityFieldPath,
                  fromFieldPath?: EntityFieldPath,
                  isInConstructor?: boolean,
                  commitContext?: CommitContext): boolean
    {
        return (super.hideFieldPath(entity, fieldPath, fromFieldPath, isInConstructor, commitContext)
            && fieldPath.field !== this.entityTypeStore.bespoke.types.Activity.Field.Number)
            // Hide fields during construction
            || (entity.isNew() && fieldPath.relationshipDefinition === this.entityTypeStore.bespoke.types.Relationship.RelationshipDefinition.Activities && fieldPath.isParentRelationship)
            || (entity.isNew() && fieldPath.relationshipDefinition === this.entityTypeStore.bespoke.types.Relationship.Person.Contact.RelationshipDefinition.Activities && fieldPath.isParentRelationship);
    }

    getColor(entity: Entity): string
    {
        const owner =
            entity.getRelatedEntityByDefinition(
                true,
                this.entityTypeStore.bespoke.types.Activity.RelationshipDefinition.LinkedActivities);

        if (owner)
        {
            return owner.entityType.getInheritedColor();
        }
        else
        {
            return entity.entityType.getInheritedColor();
        }
    }

    allowWorkflowInteraction(): boolean
    {
        return true;
    }

    // ----------------------- Private logic ------------------------
}
