import React, { useCallback, useMemo, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import { TableRow } from '@material-ui/core';
import Cell from './Cell/Cell';
import { openEntity } from '../../../@Util/openEntity';
import styles from './Row.module.scss';
import Column from '../../Model/Specification/Column';
import { Draggable as DraggableDnd } from 'react-beautiful-dnd';
import DragHandlePropsContext from '../../../../DragAndDrop/Context/DragHandlePropsContext';
import DraggableStateContext from '../../../../DragAndDrop/Context/DraggableStateContext';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import { useButtonClickHandler } from '../../../../../../@Future/Component/Generic/Button/Button';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import View from '../../Model/View';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';

export interface RowProps
{
    view: View;
    columns: Column[];
    entity: Entity;
    idx: number;
    isInEditMode?: boolean;
    sortable?: boolean;
    selectable?: boolean;
    selected?: boolean;
    onSelect?: (value: boolean, uuid: string) => void;
    disableSelection?: (entity: Entity) => boolean;
    onClick?: (entity: Entity) => void;
    highlighted?: boolean;
    isInactive?: (entity: Entity) => boolean;
}

const Row: React.FC<RowProps> =
    props =>
    {
        const { onSelect, disableSelection, onClick } = props;
        const [ isLoading, setLoading ] = useState(false);

        const _click =
            useCallback(
                () =>
                    onClick
                        ?
                            onClick(props.entity)
                        :
                            openEntity(props.entity),
                [
                    onClick,
                    props.entity
                ]);

        const click =
            useButtonClickHandler(
                _click,
                setLoading);

        const toggleCheck =
            useCallback(
                value =>
                    onSelect(value, props.entity.uuid),
                [
                    props.entity,
                    onSelect
                ]);

        const disableSelectionValue =
            useMemo(
                () =>
                {
                    return disableSelection && disableSelection(props.entity);
                },
                [
                    disableSelection,
                    props.entity
                ]);

        const cells =
            <>
                {
                    props.selectable &&
                        <TableCell
                            onClick={event => event.stopPropagation()}
                        >
                            <Checkbox
                                checked={disableSelectionValue ? false : props.selected}
                                onToggle={toggleCheck}
                                disabled={disableSelectionValue}
                            />
                        </TableCell>
                }
                {
                    props.sortable &&
                        <TableCell>
                            <DragHandle />
                        </TableCell>
                }
                {
                    props.columns
                        .filter(
                            column =>
                                column.shouldShowColumn(
                                    props.view,
                                    props.isInEditMode
                                )
                        )
                        .map(
                            (column, idx) =>
                                <Cell
                                    key={column.id}
                                    view={props.view}
                                    column={column}
                                    entity={props.entity}
                                    first={idx === 0}
                                />)
                }
            </>;

        if (props.sortable)
        {
            return <DraggableDnd
                draggableId={props.entity.uuid}
                index={props.idx}
            >
                {
                    (provided, snapshot) =>
                        <DragHandlePropsContext.Provider
                            value={provided.dragHandleProps}
                        >
                            <DraggableStateContext.Provider
                                value={snapshot}
                            >
                                <TableRow
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={provided.draggableProps.style}
                                    onClick={click}
                                    className={styles.root}
                                >
                                    {cells}
                                </TableRow>
                            </DraggableStateContext.Provider>
                        </DragHandlePropsContext.Provider>
                }
            </DraggableDnd>;
        }
        else
        {
            return <TableRow
                onClick={click}
                className={classNames(styles.root, isLoading && styles.loading, props.highlighted && styles.highlighted, props.isInactive && props.isInactive(props.entity) && styles.inactive)}
            >
                {cells}
            </TableRow>;
        }
    };

export default observer(Row);
