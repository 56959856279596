import { observable } from 'mobx';
import Series, { SeriesType } from './Series';
import ComputedSeriesComponent from './ComputedSeriesComponent';

export default class ComputedSeries extends Series
{
    // ------------------------- Properties -------------------------

    @observable.shallow components: ComputedSeriesComponent[];

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                type: SeriesType,
                name: string,
                isCumulative: boolean,
                isHidden: boolean,
                components: ComputedSeriesComponent[])
    {
        super(id, type, name, isCumulative, isHidden);

        this.components = components;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new ComputedSeries(
            descriptor.id,
            descriptor.type,
            descriptor.name,
            descriptor.isCumulative,
            descriptor.isHidden,
            descriptor.components.map(
                component =>
                    ComputedSeriesComponent.fromDescriptor(component)));
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            components:
                this.components.map(
                    component =>
                        component.toDescriptor())
        };
    }

    // ----------------------- Private logic ------------------------
}
