import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { SelectionNode } from '../../Model/Implementation/SelectionNode';
import { EntityNode } from '../../Model/Implementation/EntityNode';
import { Selection } from '../Model/Selection';
import { getSelectionNodeFromPathNode } from './getSelectionNodeFromPathNode';

export function getSelectionFromPath(path: EntityPath)
{
    let nodes: SelectionNode[] = [];
    let entityNodeByPath = new Map<string, EntityNode>();

    for (let i = 0; i < path.nodes.length; i++)
    {
        let basePath = new EntityPath(path.nodes.slice(0, i));
        let alias = new EntityPath(path.nodes.slice(0, i + 1)).id;
        let node =
            getSelectionNodeFromPathNode(
                path.nodes[i],
                alias,
                basePath,
                entityNodeByPath
            );

        entityNodeByPath.set(alias, node as EntityNode);
        nodes.push(node);
    }

    return new Selection(nodes);
}