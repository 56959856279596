import { ModuleManager } from '../index';

export default abstract class ModuleContext
{
    moduleManager: ModuleManager;

    protected constructor(moduleManager: ModuleManager)
    {
        this.moduleManager = moduleManager;
    }

    /**
     * Returns an array of ModuleContexts to load as auto configuration.
     *
     * Note: make sure the get the same moduleManager as this.moduleManager.
     */
    protected autoConfigurations?(): ModuleContext[];

    /**
     * Final method to initialize this module context and all auto configurations.
     */
    public initialize(): Promise<void>
    {
        this.defineModules();

        if (this.autoConfigurations)
            this.autoConfigurations().forEach(autoConfig => autoConfig.defineModules());

        return this.moduleManager.initialize();
    }

    protected abstract defineModules(): void;

}
