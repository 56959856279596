// https://support.google.com/google-ads/answer/7012522?hl=en
const expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds

function getParam(p)
{
    const match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);

    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

function getExpiryRecord(value)
{
    const expiryDate = new Date().getTime() + expiryPeriod;

    return {
        value: value,
        expiryDate: expiryDate
    };
}

export function addGclid()
{
    const gclidParam = getParam('gclid');

    if (gclidParam)
    {
        const gclidRecord = getExpiryRecord(gclidParam);
        localStorage.setItem('gclid', JSON.stringify(gclidRecord));
    }
}

export function getGclid()
{
    if (localStorage.getItem('gclid'))
    {
        const gclidRecord = JSON.parse(localStorage.getItem('gclid'));
        const isGclidValid = gclidRecord && new Date().getTime() < gclidRecord.expiryDate;

        if (isGclidValid)
        {
            return gclidRecord.value;
        }
    }

    return undefined;
}
