import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { default as EntityItem } from '../../../../../../Item/Item';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import Card from '../../../../../../../../../@Future/Component/Generic/Card/Card';
import InputFocus from '../../../../../../../Multiplayer/InputFocus/InputFocus';
import DraggableStateContext from '../../../../../../../DragAndDrop/Context/DraggableStateContext';
import usePhaseRelationshipDefinition from '../../../../../../../../../@Api/Entity/Bespoke/Datastore/Phase/usePhaseRelationshipDefinition';
import View from '../../../../../Model/View';
import ItemLayoutViewer from '../../../../../ItemLayout/Viewer/ItemLayoutViewer';

export interface InnerItemProps
{
    view: View;
    entity: Entity;
    onOpen?: (entity: Entity) => void;
}

const InnerItem: React.FC<InnerItemProps> =
    ({
        view,
        entity,
        onOpen,
     }) =>
    {
        const state = useContext(DraggableStateContext);
        const phaseRelationshipDefinition = usePhaseRelationshipDefinition(entity.entityType);
        const item =
            useComputed(
                () =>
                {
                    if (view.specification.column.itemLayout)
                    {
                        return <ItemLayoutViewer
                            parameters={view.parameters}
                            entity={entity}
                            layout={view.specification.column.itemLayout}
                        />;
                    }
                    else
                    {
                        return <EntityItem
                            entity={entity}
                            horizontalInset
                            verticalInset
                            compact
                            hideFavoriteButton
                            onOpen={onOpen}
                        />;
                    }
                },
                [
                    view,
                    entity,
                ]
            );

        return <Card>
            {
                phaseRelationshipDefinition
                    ?
                        <InputFocus
                            entity={entity}
                            field={phaseRelationshipDefinition}
                            focused={state ? state.isDragging : false}
                        >
                            {item}
                        </InputFocus>
                    :
                        item
            }
        </Card>;
    };

export default observer(InnerItem);
