import React from 'react';
import { observer } from 'mobx-react-lite';
import { ComputationEditorProps } from '../Computation/ComputationEditor';
import PrimitiveValueType from '../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import Predicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import PredicateConstructor from './Constructor/PredicateConstructor';
import PredicateEditorContent from './PredicateEditorContent';

export interface PredicateEditorProps<T extends Predicate = Predicate> extends ComputationEditorProps<PrimitiveValueType, T>
{
    comparisonConstructor?: () => Predicate;
    comparisonLhsTypeFixed?: boolean;
    disabled?: boolean;
}

const PredicateEditor: React.FC<PredicateEditorProps> =
    props =>
    {
        const { value, onChange } = props;

        if (!value && onChange && !props.disabled)
        {
            return <PredicateConstructor
                {...props}
                onConstruct={onChange}
            />;
        }
        else if (value)
        {
            return <PredicateEditorContent
                {...props}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(PredicateEditor);
