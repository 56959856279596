import Layout from '../../Layout';
import Dependency from '../../../Automation/Parameter/Dependency';
import Validation from '../../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../../LayoutDependencyContext';
import getLayoutFromDescriptor from '../../Api/getLayoutFromDescriptor';
import ParameterDictionary from '../../../Automation/Parameter/ParameterDictionary';
import localizeText from '../../../Localization/localizeText';

export default class FormLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref parameters: ParameterDictionary;
    @observable.ref layout: Layout;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                parameters: ParameterDictionary,
                layout: Layout)
    {
        super();

        this.id = id;
        this.parameters = parameters;
        this.layout = layout;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.Form', 'Formulier');
    }

    validate(): Validation[]
    {
        return this.layout.validate();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.parameters.getDependencies(),
            ...this.layout.getDependencies()
                .filter(
                    dependency =>
                        !this.parameters.hasParameter(dependency.parameter))
        ];
    }

    toDescriptor()
    {
        return {
            type: 'Form',
            id: this.id,
            parameters: this.parameters.toDescriptor(),
            layout: this.layout.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const formParameters =
            await ParameterDictionary.fromDescriptor(
                descriptor.parameters,
                dependencyContext);

        const formDependencyContext =
            dependencyContext.withParameterDictionary(
                ParameterDictionary.union(
                    dependencyContext.parameterDictionary,
                    formParameters
                )
            );

        const layout =
            await getLayoutFromDescriptor(
                descriptor.layout,
                formDependencyContext);

        return new FormLayout(
            descriptor.id,
            formParameters,
            layout);
    }

    // ----------------------- Private logic ------------------------
}
