import { observable } from 'mobx';
import ValueType from '../../../Value/Type/ValueType';
import Dependency from '../../../Parameter/Dependency';
import AutomationDependencyContext from '../../../AutomationDependencyContext';
import Validation from '../../../Validation/Validation';
import DynamicFunctionInvocation from '../../Dynamic/DynamicFunctionInvocation';
import Predicate from './Predicate';
import FunctionContext from '../../FunctionContext';
import { ComputationSubstitution } from '../Util/ComputationSubstitution';
import Parameter from '../../../Parameter/Parameter';

export default class DynamicPredicate extends Predicate
{
    // ------------------------- Properties -------------------------

    @observable.ref invocation: DynamicFunctionInvocation;

    // ------------------------ Constructor -------------------------

    constructor(invocation: DynamicFunctionInvocation)
    {
        super();

        this.invocation = invocation;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return this.invocation.getReturnType();
    }

    isAsync(): boolean
    {
        return true;
    }

    async evaluate(context: FunctionContext): Promise<boolean>
    {
        return this.synchronouslyEvaluate(context);
    }

    synchronouslyEvaluate(context: FunctionContext): boolean
    {
        throw new Error('unimplemented');
    }

    getName()
    {
        return this.invocation.getName();
    }

    validate(): Validation[]
    {
        return this.invocation.validate();
    }

    getDependencies(): Dependency[]
    {
        return this.invocation.getDependencies();
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Dynamic';
        descriptor.invocation = this.invocation.toDescriptor();
    }

    getTrueStatements(
        context: FunctionContext,
        parameter?: Parameter<any>
    ): Predicate[]
    {
        return [ this ];
    }

    normalize(): Predicate
    {
        return this;
    }

    substitute(substitution: ComputationSubstitution): Predicate
    {
        return this;
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new DynamicPredicate(
            await DynamicFunctionInvocation.fromDescriptor(
                descriptor,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
