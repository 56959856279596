import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ValueType from '../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import Computation from '../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import Value from '../../../../../../../../../@Api/Automation/Value/Value';
import ComputationEditor, { ComputationEditorProps } from '../ComputationEditor';
import RightAlignedButtonGroup from '../../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import CancelButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import Dialog from '../../../../../../../../../@Future/Component/Generic/Dialog/Dialog';
import DialogContent from '../../../../../../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import { DialogActions } from '@material-ui/core';

export interface ComputationDialogContentProps<T extends ValueType<any> = ValueType<any>, C extends Computation<T, Value<any, any>> = Computation<T, Value<any, any>>> extends ComputationEditorProps<T, C>
{
    onClose: () => void;
}

const ComputationDialogContent: React.FC<ComputationDialogContentProps> =
    props =>
    {
        const { value, onChange, onClose } = props;
        const [ innerValue, setInnerValue ] = useState(value);
        const save =
            useCallback(
                () =>
                {
                    onChange(innerValue);
                    onClose();
                },
                [
                    innerValue,
                    onChange,
                    onClose
                ]);

        return <Dialog
            open
            onClose={onClose}
        >
            <DialogContent>
                <ComputationEditor
                    {...props}
                    value={innerValue}
                    onChange={setInnerValue}
                />
            </DialogContent>
            <DialogActions>
                <RightAlignedButtonGroup>
                    <CancelButton
                        onClick={onClose}
                    />
                    <SaveButton
                        onClick={save}
                    />
                </RightAlignedButtonGroup>
            </DialogActions>
        </Dialog>;
    };

export default observer(ComputationDialogContent);
