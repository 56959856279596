import { ProductLineEditorInterface } from '../Hooks/useProductLine';
import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Input from '../../../../../../../Input/Input';
import { Alignment } from '../../../../../../../../DataObject/Model/DataObject';
import useTypes from '../../../../../../../Type/Api/useTypes';
import { makeStyles } from '@material-ui/styles';
import { primaryColor } from '../../../../../../../../../../@Resource/Theme/Theme';
import useEntityValue from '../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { setValueByFieldInEntity } from '../../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { classNames } from '../../../../../../../../../../@Future/Util/Class/classNames';
import useRelatedEntity from '../../../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import { determinePriceForProductLine } from '../Api/determinePriceForProductLine';

export interface ProductLinePriceProps
{
    productLine: ProductLineEditorInterface;
    currency: string;
    showLabel?: boolean;
    alignment?: Alignment;
}

const useStyles =
    makeStyles(
        {
            userEdited:
            {
                color: primaryColor
            }
        }
    );

export const ProductLinePrice: React.FC<ProductLinePriceProps> = observer(
    ({
         productLine,
         currency,
         showLabel,
         alignment,
     }) =>
    {
        const types = useTypes();
        const classes = useStyles();

        const priceField =
            currency
                ? types.ProductLine.Field.PriceInCurrency
                : types.ProductLine.Field.Price;

        const isManuallyChanged =
            useEntityValue(
                productLine.productLine,
                types.ProductLine.Field.IsPriceManuallyChanged,
                false,
                productLine.commitContext
            );

        const activity =
            useRelatedEntity(
                productLine.productLine,
                types.Activity.RelationshipDefinition.ProductLines,
                true,
                productLine.commitContext
            );

        const now =
            useMemo(
                () => new Date(),
                []
            )

        const onBlurPrice =
            useCallback(
                async () =>
                {
                    if (productLine.commitContext.isEntityDirty(productLine.productLine))
                    {
                        const product =
                            productLine.productLine.getRelatedEntityByDefinition(
                                false,
                                types.ProductLine.RelationshipDefinition.Product,
                                productLine.commitContext
                            );

                        const priceValue =
                            productLine.productLine.getObjectValueByField(
                                priceField,
                                productLine.commitContext
                            );

                        // clearing pricevalue will reset to determinedprice, price = 0.00 is a changed price
                        if (priceValue !== undefined && product.getObjectValueByField(types.Product.Field.IsPriceEditable))
                        {
                            const determinedPrice =
                                await determinePriceForProductLine(
                                    productLine.productLine,
                                    product,
                                    activity,
                                    productLine.commitContext,
                                    now
                                );

                            setValueByFieldInEntity(
                                productLine.productLine,
                                types.ProductLine.Field.IsPriceManuallyChanged,
                                priceValue !== determinedPrice,
                                productLine.commitContext
                            );
                        }
                        else
                        {
                            setValueByFieldInEntity(
                                productLine.productLine,
                                types.ProductLine.Field.IsPriceManuallyChanged,
                                false,
                                productLine.commitContext
                            );
                        }

                        await productLine.determinePrice();
                    }
                },
                [
                    productLine,
                    priceField,
                    types,
                    activity,
                    now
                ]
            );

        return <div
            className={
                classNames(
                    isManuallyChanged && classes.userEdited
                )
            }
        >
            <Input
                entity={productLine.productLine}
                field={priceField}
                labelPosition={showLabel ? 'left' : 'none'}
                alignment={alignment}
                disabled={productLine.isPriceReadOnly || productLine.isProductAndQuantityAndPriceDisabled}
                doAutocommit={false}
                commitContext={productLine.commitContext}
                onBlur={onBlurPrice}
            />
        </div>
    })