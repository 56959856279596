import { EntityRelationshipDefinition } from '../../../Model/Implementation/EntityRelationshipDefinition';
import getTypes from '../../../../@Component/Domain/Entity/Type/Api/getTypes';

export default function getRelationshipDefinitionFilterDescriptorFromEntity(relationshipDefinition: EntityRelationshipDefinition,
                                                                            isParent: boolean): any | undefined
{
    const types = getTypes();

    return relationshipDefinition.entity.getObjectValueByField(
        isParent
            ? types.EntityRelationshipDefinition.Field.ParentFilter
            : types.EntityRelationshipDefinition.Field.ChildFilter);
}
