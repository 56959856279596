import React, { useMemo } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import useCampaignDeliveredCount from '../Hooks/useCampaignDeliveredCount';
import useCampaignOpenedCount from '../Hooks/useCampaignOpenedCount';
import { observer } from 'mobx-react';
import CampaignPercentageChart from './CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';

export interface CampaignChartOpenedProps
{
    size: number;
    campaign: Entity;
    caption?: boolean;
}

const CampaignChartOpened: React.FC<CampaignChartOpenedProps> =
    props =>
    {
        const campaign = props.campaign;

        const deliveredCount = useCampaignDeliveredCount(campaign);
        const openedCount = useCampaignOpenedCount(campaign);


        const allSet =
            useAllSet([
                deliveredCount,
                openedCount
            ]);

        const formattedSelection =
            useMemo(
                () =>
                {
                    return `${openedCount} / ${deliveredCount}`
                },
                [
                    openedCount,
                    deliveredCount
                ]
            )

        return <div>
            {
                allSet &&
                    <CampaignPercentageChart
                        value={openedCount / deliveredCount * 100}
                        size={props.size}
                        label={props.caption ? localizeText('Campaign.Chart.Opened', 'Geopend') : undefined}
                        selection={formattedSelection}
                    />
            }
        </div>;
    };

CampaignChartOpened.defaultProps =
{
    caption: true
};

export default observer(CampaignChartOpened);
