import React, { useContext } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import Pipeline from '../../../../Workflow/Pipeline/Pipeline';
import HeaderContext from '../../../../Item/Context/HeaderContext';
import styles from './Header.module.scss';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CloseButtons from '../../../../Workflow/CloseButtons/CloseButtons';
import Input from '../../../../Input/Input';
import useTypes from '../../../../Type/Api/useTypes';
import Icon from '../../../../../../../@Future/Component/Generic/Icon/Icon';
import Link from '../../../../../../../@Future/Component/Generic/Link/Link';
import { openEntity } from '../../../../@Util/openEntity';
import { Hidden } from '@material-ui/core';
import TypeAndName from '../../../Shared/TypeAndName/TypeAndName';
import HeaderLink from '../../../Shared/HeaderLink/HeaderLink';
import MenuButton from '../../../Shared/MenuButton/MenuButton';
import useIsMobile from '../../../../../../../@Util/Responsiveness/useIsMobile';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import IsCompactContext from '../../../Context/IsCompactContext';
import MilestonePipeline from '../Project/MilestonePipeline/MilestonePipeline';
import Automator from '../../Automation/Automator/Automator';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import useRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useRelatedEntity';

export interface RightColumnProps
{
    entity: Entity;
}

const Header: React.FC<RightColumnProps> = (
    {
        entity
    }
) =>
    {
        const types = useTypes();
        const isMobile = useIsMobile();
        const isCompact = useContext(IsCompactContext) || isMobile;
        const relationship =
            useComputed(
                () =>
                    entity.getRelatedEntityByDefinition(
                        true,
                        types.Relationship.RelationshipDefinition.Activities),
                [
                    entity,
                    types
                ]);
        const contact =
            useComputed(
                () =>
                    entity.getRelatedEntityByDefinition(
                        true,
                        types.Relationship.Person.Contact.RelationshipDefinition.Activities),
                [
                    entity,
                    types
                ]);
        const uiNameAndType =
            <TypeAndName
                entity={entity}
            />;
        const uiDeadline =
            !isCompact
            && entity.entityType.isA(types.Activity.SalesOpportunity.Type) &&
                <Input
                    entity={entity}
                    field={types.Activity.SalesOpportunity.Field.ExpectedOrderDate}
                    labelPosition="top"
                />;
        const numberOfProductLines = useEntityValue<number>(entity, types.Activity.Field.NumberOfProductLines);
        const isVatIncluded = useEntityValue<boolean>(entity, types.Activity.Field.IsVatIncluded);
        const currency = useEntityValue(entity, types.Activity.Field.Currency);
        const uiAmount =
            <>
                {
                    !isCompact
                    && entity.entityType.isA(types.Activity.SalesOpportunity.Type) &&
                        <div>
                            {
                                numberOfProductLines > 0
                                    ?
                                        isVatIncluded
                                            ?
                                                <Input
                                                    entity={entity}
                                                    field={
                                                        currency
                                                            ? types.Activity.Field.TotalSalesIncludingVatInCurrency
                                                            : types.Activity.Field.TotalSalesIncludingVat
                                                    }
                                                    label={
                                                        <LocalizedText
                                                            code="Generic.Amount"
                                                            value="Bedrag"
                                                        />
                                                    }
                                                />
                                            :
                                                <Input
                                                    entity={entity}
                                                    field={
                                                        currency
                                                            ? types.Activity.Field.TotalSalesExcludingVatInCurrency
                                                            : types.Activity.Field.TotalSalesExcludingVat
                                                    }
                                                    label={
                                                        <LocalizedText
                                                            code="Generic.Amount"
                                                            value="Bedrag"
                                                        />
                                                    }
                                                />
                                    :
                                        <Input
                                            entity={entity}
                                            field={types.Activity.SalesOpportunity.Field.EstimatedAmount}
                                        />
                            }
                        </div>
                }
            </>;
        const uiCloseButtons =
            <CloseButtons
                entity={entity}
            />;
        const uiAutomator =
            <Automator
                entity={entity}
            />;
        const uiMenuButton =
            <MenuButton
                entity={entity}
            />;
        const uiRelationship =
            relationship &&
                <Link
                    onClick={() => openEntity(relationship)}
                >
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                        alignment="center"
                    >
                        <ViewGroupItem>
                            <Icon
                                icon={relationship.entityType.getInheritedIcon()}
                                size={17}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            {relationship.name}
                        </ViewGroupItem>
                    </ViewGroup>
                </Link>;
        const uiContact =
            <>
                {
                    contact &&
                        <ViewGroupItem>
                            <Link
                                onClick={() => openEntity(contact)}
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={5}
                                    alignment="center"
                                >
                                    <ViewGroupItem>
                                        <Icon
                                            icon={contact.entityType.getInheritedIcon()}
                                            size={17}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        {contact.name}
                                    </ViewGroupItem>
                                </ViewGroup>
                            </Link>
                        </ViewGroupItem>
                }
            </>;
        const uiPipeline =
            !isCompact &&
            !entity.entityType.isA(types.Activity.ApsisCampaign.Type) &&
            (entity.entityType.isA(types.Activity.Project.Type)
                ?
                    <MilestonePipeline
                        entity={entity}
                    />
                :
                    <Pipeline
                        entity={entity}
                    />
            );
        const parentActivities =
            useComputed(
                () =>
                    entity.getRelatedEntitiesByDefinition(
                        true,
                        types.Activity.RelationshipDefinition.LinkedActivities
                    ),
                [
                    entity,
                    types
                ]
            );
        const parentCourseFromAttendance =
            useRelatedEntity(
                entity,
                types.Activity.Course.RelationshipDefinition.Attendances,
                true
            );
        const uiParentActivities =
            <>
                {
                    parentActivities.map(
                        parentActivity =>
                            <ViewGroupItem
                                key={parentActivity.uuid}
                            >
                                <HeaderLink
                                    entity={parentActivity}
                                />
                            </ViewGroupItem>)
                }
                {
                    parentCourseFromAttendance &&
                    <ViewGroupItem>
                        <HeaderLink
                            entity={parentCourseFromAttendance}
                        />
                    </ViewGroupItem>
                }
            </>;

        return <HeaderContext.Provider
            value={true}
        >
            <Card
                inset
            >
                <Hidden smDown>
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                    >
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                            >
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    {uiNameAndType}
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={10}
                                        alignment="center"
                                    >
                                        <ViewGroup
                                            orientation="horizontal"
                                            spacing={20}
                                        >
                                            <ViewGroupItem>
                                                {uiDeadline}
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                {uiAmount}
                                            </ViewGroupItem>
                                        </ViewGroup>
                                        <ViewGroupItem>
                                            {uiCloseButtons}
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            {uiAutomator}
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            {uiMenuButton}
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <div
                                className={styles.subtitle}
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={20}
                                    alignment="center"
                                >
                                    <ViewGroupItem>
                                        {uiRelationship}
                                    </ViewGroupItem>

                                    {uiContact}

                                    {uiParentActivities}
                                </ViewGroup>
                            </div>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            {uiPipeline}
                        </ViewGroupItem>
                    </ViewGroup>
                </Hidden>

                <Hidden mdUp>
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={10}
                            >
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    {uiNameAndType}
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    {uiDeadline}
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    {uiAmount}
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    {uiAutomator}
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    {uiMenuButton}
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <div
                                className={styles.subtitle}
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={20}
                                    alignment="center"
                                    wrap
                                >
                                    <ViewGroupItem>
                                        {uiRelationship}
                                    </ViewGroupItem>

                                    {uiContact}

                                    {uiParentActivities}
                                </ViewGroup>
                            </div>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            {uiCloseButtons}
                        </ViewGroupItem>
                        <ViewGroupItem>
                            {uiPipeline}
                        </ViewGroupItem>
                    </ViewGroup>
                </Hidden>
            </Card>
        </HeaderContext.Provider>;
    };

export default observer(Header);
