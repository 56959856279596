import { observable } from 'mobx';
import ActionSignature from '../../Automation/ActionTemplate/Model/ActionSignature';
import ParameterAssignment from '../../Automation/Parameter/ParameterAssignment';
import PortalDataSourceQuery from '../DataSource/PortalDataSourceQuery';
import { FileReporter } from '../../../@Component/Domain/DataObject/Model/DataDescriptor';
import ActionInvocation from '../../Automation/ActionTemplate/Model/ActionInvocation';

export default class PortalActionInvocation extends ActionInvocation
{
    // ------------------------- Properties -------------------------

    @observable.shallow openQueries: PortalDataSourceQuery[];

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                signature: ActionSignature,
                parameterAssignment: ParameterAssignment,
                openQueries: PortalDataSourceQuery[])
    {
        super(id, signature, parameterAssignment);

        this.openQueries = openQueries;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor(onFile?: FileReporter)
    {
        return {
            ...super.toDescriptor(onFile),
            openQueries: this.openQueries.map(query => query.toDescriptor())
        };
    }

    // ----------------------- Private logic ------------------------
}
