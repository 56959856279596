import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import Dependency from '../../Parameter/Dependency';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import EmptyValue from '../../Value/EmptyValue';
import PrimitiveValue from '../../Value/PrimitiveValue';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import { NumberType } from '../../../../@Component/Domain/DataObject/Type/Number/NumberType';
import round from 'lodash/round';
import { ceil, floor } from 'lodash-es';
import localizeText from '../../../Localization/localizeText';
import EmptyValueType from '../../Value/Type/EmptyValueType';

export type RoundingMethod = 'Up' | 'Down' | 'HalfUp';

export default class RoundedNumberComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref value: Computation<any, any>;
    @observable scale: number;
    @observable method: RoundingMethod;

    // ------------------------ Constructor -------------------------

    constructor(
        scale: number,
        method: RoundingMethod,
        value: Computation<any, any>
    )
    {
        super();

        this.value = value;
        this.scale = scale;
        this.method = method;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        if (this.value !== undefined)
        {
            return this.value.getType();
        }
        else
        {
            return EmptyValueType.instance;
        }
    }

    isAsync(): boolean
    {
        return this.value.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const lhsValue = await safelyApplyFunction(this.value, context);
        return this.round(lhsValue);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        return this.round(safelySynchronousApplyFunction(this.value, context));
    }

    private round(value: Value<any, any>)
    {
        if (value instanceof EmptyValue)
        {
            return EmptyValue.instance;
        }
        else if (value instanceof PrimitiveValue && value.value.type instanceof NumberType)
        {
            const unroundedValue = value.value.getValue() as number;
            let roundedValue;

            switch (this.method)
            {
                case 'Down':
                {
                    roundedValue = floor(unroundedValue, this.scale);
                    break;
                }

                case 'Up':
                {
                    roundedValue = ceil(unroundedValue, this.scale);
                    break;
                }

                case 'HalfUp':
                {
                    roundedValue = round(unroundedValue, this.scale);
                    break;
                }
            }

            return new PrimitiveValue(
                DataObject.constructFromTypeAndValue(
                    value.getType().type,
                    roundedValue
                )
            );
        }
        else
        {
            return value;
        }
    }

    getName(): string
    {
        return `${localizeText(
            'RoundedNumberComputationName',
            'Afronden'
        )}: ${this.value.getName()}`;
    }

    validate(): Validation[]
    {
        return this.value.validate();
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'RoundedNumber';
        descriptor.scale = this.scale;
        descriptor.method = this.method;
        descriptor.value = this.value.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.value.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new RoundedNumberComputation(
            descriptor.scale,
            descriptor.method,
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext
            )
        );
    }
    // ----------------------- Private logic ------------------------
}
