import { BaseStore, CallbackType, getOrCompute, PropType } from '../../../@Framework/Store/BaseStore';
import { ButtonStore } from '../Button/ButtonStore';
import { action, computed, observable } from 'mobx';
import { ViewComponent } from '../ViewStack/Model/ViewComponent';
import { SidebarStore } from '../Sidebar/SidebarStore';

export type MaxWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface WindowProps
{
    title?: PropType<WindowStore, WindowProps, string>;
    subTitle?: PropType<WindowStore, WindowProps, string>;
    buttons?: PropType<WindowStore, WindowProps, ButtonStore[]>;
    onClose?: CallbackType<WindowStore, WindowProps, void>;
    viewComponent?: PropType<WindowStore, WindowProps, ViewComponent<any>>;
    isFullWidth?: PropType<WindowStore, WindowProps, boolean>;
    isModal?: PropType<WindowStore, WindowProps, boolean>;
    isOpen?: PropType<WindowStore, WindowProps, boolean>;
    alertMessage?: PropType<WindowStore, WindowProps, string>;
    hasContentInset?: PropType<WindowStore, WindowProps, boolean>;
    icon?: PropType<WindowStore, WindowProps, string>;
    maxWidth?: PropType<WindowStore, WindowProps, MaxWidth>;
}

const defaultProps: WindowProps =
{
    isOpen: true,
    isFullWidth: true,
    maxWidth: 'sm'
};

export abstract class WindowStore<P extends WindowProps = WindowProps> extends BaseStore<P>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable sidebarStore: SidebarStore;

    // ------------------------ Constructor -------------------------

    constructor(props: P, givenDefaultProps: any)
    {
        super(props, { ...defaultProps, ...givenDefaultProps});
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get title(): string
    {
        return getOrCompute(this, this.props.title);
    }

    @computed
    get subTitle(): string
    {
        return getOrCompute(this, this.props.subTitle);
    }

    @computed
    get buttons(): ButtonStore[]
    {
        return getOrCompute(this, this.props.buttons);
    }

    @computed
    get viewComponent(): ViewComponent<any>
    {
        return getOrCompute(this, this.props.viewComponent);
    }

    @computed
    get isFullWidth(): boolean
    {
        return getOrCompute(this, this.props.isFullWidth);
    }

    @computed
    get isModal(): boolean
    {
        return getOrCompute(this, this.props.isModal);
    }

    @computed
    get isOpen(): boolean
    {
        return getOrCompute(this, this.props.isOpen);
    }

    @computed
    get alertMessage(): string
    {
        return getOrCompute(this, this.props.alertMessage);
    }

    @computed
    get hasContentInset(): boolean
    {
        return getOrCompute(this, this.props.hasContentInset);
    }

    @computed
    get icon(): string
    {
        return getOrCompute(this, this.props.icon);
    }

    @computed
    get maxWidth(): MaxWidth
    {
        return getOrCompute(this, this.props.maxWidth);
    }

    // --------------------------- Stores ---------------------------

    @computed
    get closeButtonStore(): ButtonStore
    {
        if (this.props.onClose)
        {
            return new ButtonStore({
                onClick: this.closeHandler,
                icon: 'clear',
                color: 'default'});
        }
        else
        {
            return null;
        }
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    show()
    {
        this.props.isOpen = true;
    }

    @action.bound
    hide()
    {
        this.props.isOpen = false;
    }

    @action.bound
    closeHandler()
    {
        this.hide();

        if (this.props.onClose)
        {
            this.props.onClose(this);
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
