import React from 'react';
import useIsMobile from './useIsMobile';
import useIsTablet from './useIsTablet';

export interface ResponsiveLayoutProps
{
    mobile: JSX.Element;
    tablet: JSX.Element;
    desktop: JSX.Element;
}

export const ResponsiveLayout: React.FC<ResponsiveLayoutProps> =
    ({
        mobile,
        tablet,
        desktop
    }) =>
    {
        const isMobileSize = useIsMobile();
        const isTabletSize = useIsTablet();

        if (isMobileSize)
        {
            return mobile;
        }
        else if (isTabletSize)
        {
            return tablet;
        }
        else
        {
            return desktop;
        }
    }

