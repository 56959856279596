import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import { useComputed } from 'mobx-react-lite';
import getResultName from './getResultName';
import Segment from '../../Model/Segment';

export default function useResultName(segment: Segment,
                                      result: EntitySelectionAggregateResult,
                                      idx: number)
{
    return useComputed(
        () =>
            getResultName(segment, result, idx),
        [
            segment,
            result,
            idx
        ]);
}
