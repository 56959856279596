const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function isEmailAddressValid(emailAddress: string)
{
    if (emailAddress)
    {
        return re.test(String(emailAddress).toLowerCase());
    }
    else
    {
        return false;
    }
}
