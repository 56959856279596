import React from 'react';
import { observer } from 'mobx-react-lite';
import EditorButton from './EditorCommandButton';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface DefaultToolbarButtonsProps
{
}

const DefaultToolbarButtons: React.FC<DefaultToolbarButtonsProps> =
    () =>
    {
        return <>
            <EditorButton
                icon="format_bold"
                command="bold"
                tooltip={
                    <LocalizedText
                        code="Generic.Bold"
                        value="Vetgedrukt"
                    />
                }
            />
            <EditorButton
                icon="format_italic"
                command="italic"
                tooltip={
                    <LocalizedText
                        code="Generic.Italic"
                        value="Schuingedrukt"
                    />
                }
            />
            <EditorButton
                icon="format_underlined"
                command="underline"
                tooltip={
                    <LocalizedText
                        code="Generic.Underline"
                        value="Onderlijn"
                    />
                }
            />
            <EditorButton
                icon="format_strikethrough"
                command="strike"
                tooltip={
                    <LocalizedText
                        code="Generic.Strikethrough"
                        value="Doorstreept"
                    />
                }
            />

            <EditorButton
                icon="format_list_numbered"
                command="list.ordered"
                tooltip={
                    <LocalizedText
                        code="Generic.OrderedList"
                        value="Gesorteerde lijst"
                    />
                }
            />
            <EditorButton
                icon="format_list_bulleted"
                command="list.bullet"
                tooltip={
                    <LocalizedText
                        code="Generic.BulletList"
                        value="Bullet lijst"
                    />
                }
            />

            <EditorButton
                icon="format_align_left"
                command="align."
                tooltip={
                    <LocalizedText
                        code="Generic.AlignLeft"
                        value="Links uitlijnen"
                    />
                }
            />
            <EditorButton
                icon="format_align_center"
                command="align.center"
                tooltip={
                    <LocalizedText
                        code="Generic.AlignCenter"
                        value="Gecentreerd"
                    />
                }
            />
            <EditorButton
                icon="format_align_right"
                command="align.right"
                tooltip={
                    <LocalizedText
                        code="Generic.AlignRight"
                        value="Rechts uitlijnen"
                    />
                }
            />
        </>;
    };

export default observer(DefaultToolbarButtons);
