import React from 'react';
import { observer } from 'mobx-react-lite';
import NumberEditor, { NumberEditorProps } from '../NumberEditor';

export interface PercentageEditorProps extends NumberEditorProps
{

}

const PercentageEditor: React.FC<PercentageEditorProps> =
    props =>
    {
        return <NumberEditor
            {...props}
            suffix="%"
        />;
    };

PercentageEditor.defaultProps = {

};

export default observer(PercentageEditor);
