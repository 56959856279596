import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { AssistantComparisonPredicate } from '../../Model/Predicate/AssistantComparisonPredicate';
import { findAssistantComparator } from '../findAssistantComparator';
import { findAssistantFieldPath } from '../findAssistantFieldPath';
import { EntityPath } from '../../../Entity/Path/@Model/EntityPath';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import { findAssistantComputation } from '../Computation/findAssistantComputation';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';

export function findAssistantComparisonPredicate(
    predicate: AssistantComparisonPredicate,
    parameters: ParameterDictionary,
    resultParameter: Parameter<EntityValueType>
): Predicate
{
    const rootPath = EntityPath.fromEntityType(resultParameter.type.type);
    let lhs: Computation<any, any>;

    if (parameters.hasParameterId(predicate.field))
    {
        lhs = parameters.getParameterById(predicate.field);
    }
    else
    {
        const lhsFieldPath =
            findAssistantFieldPath(
                rootPath,
                predicate.field
            );
        lhs =
            new ValueFromEntityComputation(
                resultParameter,
                lhsFieldPath
            );
    }

    const comparator = findAssistantComparator(predicate.comparator);
    const rhs =
        predicate.value == null
            ? undefined
            : findAssistantComputation(
                predicate.value,
                parameters,
                resultParameter
            );

    return new ComparisonPredicate(
        lhs,
        comparator,
        rhs
    );
}
