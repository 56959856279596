import { useCallback } from 'react';
import useSetting from '../../../Setting/Api/useSetting';
import { SettingSource } from '../../../Setting/SettingStore';
import { expandedSettingCode } from './useExpanded';
import cloneDeep from 'lodash/cloneDeep';
import { toJS } from 'mobx';

export default function useSetExpanded()
{
    // Setting contains which datasets are collapsed (not expanded, because by default they should all be expanded)
    // E.g. when the user opens his dashboard for the first time
    const [ isExpandedSetting, setExpandedSetting ] = useSetting(SettingSource.User, expandedSettingCode);

    return useCallback(
        (ids: string[], isExpanded: boolean) =>
        {
            const setting = cloneDeep(toJS(isExpandedSetting) || {});

            for (let id of ids)
            {
                if (isExpanded)
                {
                    delete setting[id];
                }
                else
                {
                    setting[id] = true;
                }
            }

            setExpandedSetting(setting);
        },
        [
            isExpandedSetting,
            setExpandedSetting
        ]);
}
