import React, { ReactNode, useState } from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import HoverCardBottom from '../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { AutomationsTableHead } from './Head/AutomationsTableHead';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntitySelectionBuilder } from '../../../../../Entity/Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../../../Entity/Path/@Model/EntityPath';
import useTypes from '../../../../../Entity/Type/Api/useTypes';
import usePaginatedSelection from '../../../../../Entity/View/Api/usePaginatedSelection';
import { observer } from 'mobx-react';
import { useComputed } from 'mobx-react-lite';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import { AutomationsTableRow } from './Row/AutomationsTableRow';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { HeaderFilter } from '../../../../../../../@Future/Component/Generic/Header/HeaderFilter';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { lightTextColor } from '../../../../../../../@Resource/Theme/Theme';
import useToggle from '../../../../../../../@Util/Toggle/useToggle';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';

export interface AutomationsTableProps
{
    filter: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void;
    showEntityType?: boolean;
    header?: ReactNode;
}

const useStyles = makeStyles({
    header: {
        fontSize: 'large',
        fontWeight: 'bold',
        color: lightTextColor
    },
});

export const AutomationsTable: React.FC<AutomationsTableProps> =
    observer(
        ({
            filter,
            showEntityType,
            header
         }) =>
        {
            const types= useTypes();
            const classes = useStyles();
            const [ isExpanded, toggleExpansion ] = useToggle(true);
            const [ filteredTypeEntity, setFilteredTypeEntity ] = useState<Entity | undefined>(undefined);
            const [ pages, hasMore, loadMore, isLoading ] =
                usePaginatedSelection(
                    types.Automation.Type,
                    (selectionBuilder, rootPath) =>
                        selectionBuilder
                            .join(
                                rootPath
                                    .joinTo(
                                        types.Automation.RelationshipDefinition.Activations,
                                        false
                                    )
                            )
                            .if(
                                () => true,
                                () => filter(selectionBuilder, rootPath)
                            )
                            .if(
                                () =>
                                    filteredTypeEntity !== undefined,
                                sb =>
                                    sb.where(
                                        cb =>
                                            cb.relatedToEntity(
                                                rootPath.joinTo(
                                                    types.EntityType.RelationshipDefinition.Automations,
                                                    true
                                                ),
                                                filteredTypeEntity
                                            )
                                    )
                            )
                            .orderBy(
                                rootPath
                                    .joinTo(
                                        types.EntityType.RelationshipDefinition.Automations,
                                        true
                                    )
                                    .field(types.EntityType.Field.LocalizedSingularName),
                                true
                            )
                            .orderBy(
                                rootPath.field(types.Automation.Field.LocalizedName),
                                true
                            ),
                    [
                        types,
                        filter,
                        filteredTypeEntity,
                    ]
                );
            const automations =
                useComputed(
                    () =>
                        pages
                            .map(page => page.slice())
                            .reduce((a, b) => a.concat(b), []),
                    [
                        pages
                    ]
                );

            if (automations.length === 0)
            {
                return null;
            }
            else
            {
                return <Card>
                    <ViewGroup
                        orientation="vertical"
                        spacing={0}
                    >
                        {
                            header &&
                            <ViewGroupItem>
                                <CardInset>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={0}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                            className={classes.header}
                                        >
                                            {header}
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <Input
                                                label={null}
                                                labelPosition="left"
                                            >
                                                <HeaderFilter
                                                    isOpen={isExpanded}
                                                    onChange={() => toggleExpansion(!isExpanded)}
                                                />
                                            </Input>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                            </ViewGroupItem>
                        }
                        {
                            isExpanded &&
                            <ViewGroupItem>
                                <Table>
                                    <AutomationsTableHead
                                        filter={filter}
                                        showEntityType={showEntityType}
                                        filteredTypeEntity={filteredTypeEntity}
                                        onChangeFilteredTypeEntity={setFilteredTypeEntity}
                                    />
                                    {
                                        isLoading &&
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    colSpan={showEntityType ? 3 : 2}
                                                >
                                                    <Centered
                                                        horizontal
                                                    >
                                                        <Loader />
                                                    </Centered>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>}
                                    {!isLoading && <TableBody>
                                        {
                                            automations
                                                .map(
                                                    result =>
                                                        <AutomationsTableRow
                                                            key={result.entity.uuid}
                                                            automation={result.entity}
                                                            showEntityType={showEntityType}
                                                        />
                                                )
                                        }
                                    </TableBody>}
                                </Table>
                                {
                                    hasMore && <HoverCardBottom
                                        onClick={loadMore}
                                        disabled={isLoading}
                                    >
                                        <LocalizedText
                                            code="LoadMore"
                                            value="Meer laden"
                                        />
                                    </HoverCardBottom>}
                            </ViewGroupItem>
                        }
                    </ViewGroup>
                </Card>;
            }
        }
    );
