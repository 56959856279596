import React, { useMemo, useState } from 'react';
import Card from '../Card';
import styles from './HoverCard.module.scss';
import { classNames } from '../../../../Util/Class/classNames';
import { useButtonClickHandler } from '../../Button/Button';
import { LinearProgress } from '@material-ui/core';
import CardInset from '../CardInset';

export interface HoverCardProps
{
    className?: string;
    disabled?: boolean;
    onClick: () => void;
    inside?: boolean;
}

const HoverCard: React.FC<HoverCardProps> =
    props =>
    {
        const [ isLoading, setLoading ] = useState(false);
        const isDisabled = props.disabled || isLoading;
        const classes =
            useMemo(
                () =>
                    ({
                        root: classNames(styles.root, isDisabled && styles.disabled, props.inside && styles.inside, props.className)
                    }),
                [
                    isDisabled,
                    props.inside,
                    props.className
                ]);

        const onDivClick =
            useButtonClickHandler(
                props.onClick,
                setLoading,
                false);

        return <div
            onClick={isDisabled ? undefined : onDivClick}
        >
            <Card
                classes={classes}
            >
                <CardInset>
                    {props.children}
                </CardInset>
                {
                    isLoading &&
                        <div
                            className={styles.loader}
                        >
                            <LinearProgress />
                        </div>
                }
            </Card>
        </div>;
    };

export default HoverCard;
