import { registerType, type } from '../../../@Util/Serialization/Serialization';
import { Language } from './Language';

@type('LanguagePackage')
export class LanguagePackage
{
    // ------------------- Persistent Properties --------------------

    languageCode: string;
    countryCode: string;
    translations: any;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    setTranslation(
        code: string,
        translation: string
    )
    {
        this.translations[code] = translation
    }

    // ----------------------- Private logic ------------------------
}

registerType(
    Language,
    'Language');
