import { getFileNameFromUrl } from './getFileNameFromUrl';

export function downloadUrl(
    url: string,
    name?: string
)
{
    const link = document.createElement('a');
    link.download = name ?? getFileNameFromUrl(url);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
}