import { observable } from 'mobx';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';

export default class ResourceSelectionRecurrence
{
    // ------------------------- Properties -------------------------

    @observable.ref startDateFieldPath: EntityFieldPath;
    @observable.ref endDateFieldPath: EntityFieldPath;
    @observable.ref patternFieldPath: EntityFieldPath;

    // ------------------------ Constructor -------------------------

    constructor(
        startDateFieldPath: EntityFieldPath,
        endDateFieldPath: EntityFieldPath,
        patternFieldPath: EntityFieldPath,
    )
    {
        this.startDateFieldPath = startDateFieldPath;
        this.endDateFieldPath = endDateFieldPath;
        this.patternFieldPath = patternFieldPath;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isRecurring(entity: Entity): boolean
    {
        return this.startDateFieldPath.getObjectValue(entity) !== undefined
            && this.patternFieldPath.getObjectValue(entity) !== undefined;
    }

    static fromDescriptor(
        descriptor: any
    ): ResourceSelectionRecurrence
    {
        return new ResourceSelectionRecurrence(
            EntityFieldPath.construct(descriptor.startDateFieldPath),
            EntityFieldPath.construct(descriptor.endDateFieldPath),
            EntityFieldPath.construct(descriptor.patternFieldPath)
        );
    }

    toDescriptor()
    {
        return {
            startDateFieldPath: this.startDateFieldPath.descriptor,
            endDateFieldPath: this.endDateFieldPath.descriptor,
            patternFieldPath: this.patternFieldPath.descriptor,
        };
    }

    // ----------------------- Private logic ------------------------
}
