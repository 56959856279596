import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { CheckboxProps as MuiCheckboxProps, default as MuiCheckbox } from '@material-ui/core/Checkbox';

export interface CheckboxProps
{
    checked?: boolean;
    disabled?: boolean;
    onToggle?: (isChecked: boolean) => void;
    indeterminate?: boolean;
    baseProps?: Partial<MuiCheckboxProps>;
    className?: string;
}

const Checkbox: React.FC<CheckboxProps> =
    props =>
    {
        const { onToggle } = props;

        const onClick =
           useCallback(
               () =>
                   onToggle(!props.checked),
               [
                   props.checked,
                   onToggle
               ]);

        return <MuiCheckbox
            checked={props.checked}
            disabled={props.disabled}
            color="primary"
            onClick={onClick}
            indeterminate={props.indeterminate}
            className={props.className}
            {...props.baseProps}
        />;
    };

Checkbox.defaultProps = {
    checked: false,
    onToggle: () => {}
};

export default observer(Checkbox);
