import { observable } from 'mobx';
import Computation from './Computation';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Predicate from './Predicate/Predicate';
import getPredicateFromDescriptor from '../../Api/getPredicateFromDescriptor';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Validation from '../../Validation/Validation';
import uuid from '../../../../@Util/Id/uuid';

export default class ConditionalInComputation
{
    // ------------------------- Properties -------------------------

    @observable.ref id: string;
    @observable.ref predicate: Predicate;
    @observable.ref value: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(predicate: Predicate,
                value: Computation<any, any>)
    {
        this.id = uuid();
        this.predicate = predicate;
        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isAsync(): boolean
    {
        return this.predicate.isAsync()
            || this.value.isAsync();
    }

    validate(): Validation[]
    {
        if (!this.predicate)
        {
            return [
                new Validation(
                    'Error',
                    'Er is geen geldige conditie ingevuld.')
            ];
        }

        if (!this.value)
        {
            return [
                new Validation(
                    'Error',
                    'Er is geen geldige waarde ingevuld.')
            ];
        }

        return [
            ...this.predicate.validate(),
            ...(this.value ? this.value.validate() : [])
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.predicate.getDependencies(),
            ...this.value.getDependencies()
        ];
    }

    toDescriptor()
    {
        return {
            predicate: this.predicate.toDescriptor(),
            value: this.value.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new ConditionalInComputation(
            await getPredicateFromDescriptor(
                descriptor.predicate,
                dependencyContext),
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
