import React, { useMemo } from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../../../Path/@Model/EntityPath';
import equalsEntity from '../../../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import { createNumberComparator } from '../../../../../../../../Generic/List/V2/Comparator/NumberComparator';
import { Milestone } from '../Milestone/Milestone';
import { ObservableMap } from 'mobx';

export interface MilestoneListProps
{
    entity: Entity;
    onOpen: (milestone: Entity, tab: HTMLDivElement) => void;
    milestone?: Entity;
    sortIndexByMilestoneId: ObservableMap<string, number>;
}

const MilestoneList: React.FC<MilestoneListProps> =
    props =>
    {
        const types = useTypes();

        const pathToMilestones =
            useMemo(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Activity.Project.RelationshipDefinition.Milestones,
                            false),
                [
                    props.entity,
                    types
                ]);

        const milestones =
            useComputed(
                () =>
                    pathToMilestones
                        .traverseEntity(props.entity)
                        .sort(
                            createNumberComparator(
                                d =>
                                    props.sortIndexByMilestoneId.has(d.uuid)
                                        ?
                                            props.sortIndexByMilestoneId.get(d.uuid)
                                        :
                                            d.getObjectValueByField(types.Entity.Field.SortIndex))),
                [
                    props.entity,
                    props.sortIndexByMilestoneId,
                    types
                ]);

        return <>
            {
                milestones.map(
                    (milestone, idx) =>
                        <Milestone
                            key={milestone.uuid}
                            project={props.entity}
                            milestone={milestone}
                            idx={idx}
                            onOpen={props.onOpen}
                            active={equalsEntity(props.milestone, milestone)}
                        />)
            }
        </>;
    };

export default observer(MilestoneList);
