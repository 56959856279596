import * as React from 'react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { ApiStateStore } from './ApiStateStore';
import { observer } from 'mobx-react';
import { Dialog } from '../../../Generic/Dialog/Dialog';

@observer
export class ApiState extends BaseComponent<ApiStateStore>
{
    renderComponent(store: ApiStateStore)
    {
        if (store.dialogStores.length === 0)
        {
            return null;
        }
        else
        {
            return <Dialog
                store={store.dialogStores[store.dialogStores.length - 1]}
            />;
        }
    }
}
