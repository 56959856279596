import { useComputed } from 'mobx-react-lite';
import useTypes from '../../Type/Api/useTypes';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { EntityPathRootNode } from '../../Path/@Model/Node/EntityPathRootNode';
import { createNumberComparator } from '../../../../Generic/List/V2/Comparator/NumberComparator';
import getPhaseRelationshipDefinition from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/getPhaseRelationshipDefinition';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import getFieldOrderingInType from '../../../../../@Api/Metadata/Field/getFieldOrderingInType';
import isHiddenField from '../../../../../@Api/Metadata/Input/isHiddenField';
import resolveInputFromFieldPath from '../../../Multiplayer/Model/Input/Api/resolveInputFromFieldPath';
import { useContext } from 'react';
import CurrentUserContext from '../../../User/CurrentUserContext';
import { isFieldVisibleDuringConstruction } from './isFieldVisibleDuringConstruction';
import FieldInput from '../../../Multiplayer/Model/Input/FieldInput';
import RelationshipInput from '../../../Multiplayer/Model/Input/RelationshipInput';

export default function useConstructorFieldPaths(entity: Entity,
                                                 fromFieldPath?: EntityFieldPath)
{
    const types = useTypes();
    const currentUserStore = useContext(CurrentUserContext);

    return useComputed(
        () =>
        {
            const fieldOrderingInType = getFieldOrderingInType(entity.entityType);
            const fields =
                entity.entityType
                    .getInheritedFields()
                    .filter(
                        field =>
                            isFieldVisibleDuringConstruction(
                                new FieldInput(
                                    entity.entityType,
                                    field
                                ),
                                entity
                            )
                    );

            let fieldPaths = [
                ...fields
                    .map(
                        entityField =>
                            new EntityFieldPath(
                                new EntityPath(
                                    [
                                        new EntityPathRootNode(
                                            entity.entityType)
                                    ]),
                                entityField)),

                ...[true, false]
                    .map(
                        isParent =>
                            entity.entityType
                                .getInheritedRelationshipDefinitions(isParent)
                                .filter(
                                    relationshipDefinition =>
                                        isFieldVisibleDuringConstruction(
                                            new RelationshipInput(
                                                entity.entityType,
                                                relationshipDefinition,
                                                isParent
                                            ),
                                            entity
                                        )
                                )
                                .map(
                                    relationshipDefinition =>
                                        new EntityFieldPath(
                                            EntityPath.root(entity.entityType)
                                                .joinTo(
                                                    relationshipDefinition,
                                                    isParent))))
                    .reduce(
                        (a, b) =>
                            a.concat(b), [])
            ];

            // Remove path to parent relation from a relationship (if it is filled)
            if (entity.entityType.isA(types.Relationship.Type))
            {
                const pathToParentRelation =
                    fieldPaths.find(
                        path =>
                            path.isRelationship &&
                            path.relationshipDefinition === types.Relation.RelationshipDefinition.Relationships &&
                            path.isParentRelationship);

                if (pathToParentRelation
                    && pathToParentRelation.path.traverseEntity(entity).length > 0)
                {
                    fieldPaths.splice(
                        fieldPaths.indexOf(pathToParentRelation),
                        1);
                }
            }

            fieldPaths.sort(
                createNumberComparator(
                    fieldPath =>
                        fieldOrderingInType[fieldPath.id] === undefined
                            ? fieldPath.isField
                                ?
                                    (fieldPath.field.entity && fieldPath.field.entity.sortIndex)
                                :
                                    (fieldPath.relationshipDefinition.entity && fieldPath.relationshipDefinition.entity.sortIndex)
                            : fieldOrderingInType[fieldPath.id],
                    true
                )
            );

            fieldPaths =
                fieldPaths.filter(
                    fieldPath =>
                        (fieldPath.field
                            ?
                                currentUserStore
                                .rightProfile
                                .role
                                .isPermissionGrantedForField(
                                    entity.entityType,
                                    fieldPath.field,
                                    'canCreate'
                                )
                            :
                                currentUserStore
                                    .rightProfile
                                    .role
                                    .isPermissionGrantedForRelationshipTypeFromSide(
                                        entity.entityType,
                                        fieldPath.relationshipDefinition,
                                        fieldPath.isParentRelationship,
                                        'canCreate'
                                    )
                        )
                        && !entity.entityType.bespoke.hideFieldPath(
                            entity,
                            fieldPath,
                            fromFieldPath,
                            true)
                        && !isHiddenField(
                            resolveInputFromFieldPath(
                                fieldPath)));

            const phaseRelationshipDefinition = getPhaseRelationshipDefinition(entity.entityType);

            if (phaseRelationshipDefinition
                && !entity.entityType.bespoke.allowWorkflowInteraction())
            {
                return fieldPaths.filter(
                    path =>
                        !(path.relationshipDefinition === phaseRelationshipDefinition
                            && !path.isParentRelationship));
            }
            else
            {
                return fieldPaths;
            }
        },
        [
            entity,
            types,
            currentUserStore,
            fromFieldPath
        ]);
}
