import useSpotlerCampaignSendCount from '../Hooks/useSpotlerCampaignSendCount';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useSpotlerCampaignClickedCount from '../Hooks/useSpotlerCampaignClickedCount';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import CampaignPercentageChart from '../../Campaign/Charts/CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

export interface SpotlerCampaignChartClickedProps
{
    size: number;
    spotlerCampaign: Entity;
    selectedStep: Entity;
}

export const SpotlerCampaignChartClicked: React.FC<SpotlerCampaignChartClickedProps> =
    observer(
        (
            {
                spotlerCampaign,
                selectedStep,
                size,
            }
        ) =>
        {
            const totalSendCount =
                useSpotlerCampaignSendCount(
                    spotlerCampaign,
                    selectedStep
                );

            const clickedCount =
                useSpotlerCampaignClickedCount(
                    spotlerCampaign,
                    selectedStep
                );

            const allSet =
                useAllSet([
                    totalSendCount,
                    clickedCount
                ]);

            const formattedSelection =
                useMemo(
                    () =>
                    {
                        return `${clickedCount} / ${totalSendCount}`
                    },
                    [
                        clickedCount,
                        totalSendCount
                    ]
                );

            return <div>
                {
                    allSet &&
                    <CampaignPercentageChart
                        value={clickedCount / totalSendCount * 100}
                        size={size}
                        label={localizeText('Campaign.Chart.Clicked', 'Aangeklikt')}
                        selection={formattedSelection}
                    />
                }
            </div>;
        }
    );