import { EntityFieldPath } from '../../Entity/Path/@Model/EntityFieldPath';
import { EntityPath } from '../../Entity/Path/@Model/EntityPath';
import getTypes from '../../Entity/Type/Api/getTypes';
import { findAssistantFieldPath } from './findAssistantFieldPath';

export function findAssistantSegmentFieldPath(
    rootPath: EntityPath,
    path: string
): EntityFieldPath
{
    const fieldPath =
        findAssistantFieldPath(
            rootPath,
            path
        );
    const types = getTypes();

    if (fieldPath.isField
        && (fieldPath.field === types.Entity.Field.Id || fieldPath.field === types.Entity.Field.Name))
    {
        return fieldPath.path.field();
    }
    else
    {
        return fieldPath;
    }
}
