import React, {useMemo} from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import DetailsLayout from '../../../../Viewer/Shared/Details/DetailsLayout/DetailsLayout';
import Planner from "../../../../Planner/Planner";
import useTypes from "../../../../Type/Api/useTypes";
import {EntityPath} from "../../../../Path/@Model/EntityPath";

export interface TaskProps
{
    entity: Entity;
}

const Task: React.FC<TaskProps> =
    props =>
    {
        const commitContext = useNewCommitContext();
        const types = useTypes();
        const employeePath =
            useMemo(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Activity.Task.RelationshipDefinition.Assignee,
                            false),
                [
                    props.entity,
                    types
                ]
            );

        return <CardInset
            horizontal={false}
            bottom={false}
        >
            <DetailsLayout
                entity={props.entity}
                isInEditMode={false}
                onCloseEditMode={() => {}}
                commitContext={commitContext}
                hidden={
                    fieldPath =>
                        fieldPath.field === types.Activity.Task.Field.StartDate
                        || fieldPath.field === types.Activity.Task.Field.EndDate
                }
                appendix={
                    <Planner
                        entity={props.entity}
                        startDateField={types.Activity.Task.Field.StartDate}
                        endDateField={types.Activity.Task.Field.EndDate}
                        employeePath={employeePath}
                        autoCommit
                        commitContext={commitContext}
                    />
                }
            />
        </CardInset>;
    };

export default observer(Task);
