import { useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityFieldGroup } from '../../../Management/Application/EntityTypesEditor/EntityFieldGroup';
import useTranslation from '../../../../../@Service/Localization/Api/useTranslation';
import EntityTypeContext from '../../Type/EntityTypeContext';
import { useContext } from 'react';

export default function useAllFieldGroups(entityType: EntityType)
{
    const entityTypeStore = useContext(EntityTypeContext);
    const uncategorized = useTranslation('Generic.Uncategorized');

    return useComputed(
        () =>
        {
            return entityType
                .getInheritedFieldGroups(entityTypeStore)
                .concat([
                    new EntityFieldGroup(
                        entityType,
                        undefined,
                        () => uncategorized,
                        'uncategorized'), // Uncategorized
                ]);
        },
        [
            entityType,
            entityTypeStore,
            uncategorized
        ]);
}
