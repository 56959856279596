import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import EditableName from './EditableName/EditableName';
import ViewableName from './ViewableName/ViewableName';
import getNameFieldByType from '../../../../../@Api/Metadata/Field/getNameFieldByType';
import FieldInput from '../../../Multiplayer/Model/Input/FieldInput';

export interface NameProps
{
    entity: Entity;
    editable?: boolean;
    link?: boolean;
    fitContent?: boolean;
}

const Name: React.FC<NameProps> =
    props =>
    {
        const isEditable = props.editable;
        const nameInput =
            useComputed(
                () =>
                    getNameFieldByType(props.entity.entityType),
                [
                    props.entity
                ]);

        if (nameInput)
        {
            if (isEditable
                && nameInput
                && nameInput instanceof FieldInput
                && !nameInput.field.isComputedField)
            {
                return <EditableName
                    entity={props.entity}
                    nameField={nameInput.field}
                    fitContent={props.fitContent}
                />;
            }
            else
            {
                return <ViewableName
                    entity={props.entity}
                    nameInput={nameInput}
                    link={props.link}
                />;
            }
        }
        else
        {
            return null;
        }
    };

export default observer(Name);
