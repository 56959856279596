import { ApiClient } from '../../../../@Service/ApiClient/ApiClient';
import { DataObjectStore } from '../../DataObject/DataObjectStore';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { EntityRelationshipDefinition } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntityField } from '../../../../@Api/Model/Implementation/EntityField';
import { EntitySelectionController } from '../../../../@Api/Controller/Directory/EntitySelectionController';
import { action, computed, IObservableArray, observable, runInAction } from 'mobx';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { EntityFieldPath } from '../Path/@Model/EntityFieldPath';
import { EntityPath } from '../Path/@Model/EntityPath';
import { ComputationTypeStore } from '../../Computation/ComputationTypeStore';
import { PredicateTypeStore } from '../../Predicate/PredicateTypeStore';
import { EntityValue } from '../../../../@Api/Model/Implementation/EntityValue';
import { BespokeEntityTypeStore } from './BespokeEntityTypeStore';
import { ApiControllerStore } from '../../../../@Api/Controller/ApiControllerStore';
import { EntityExpansionBuilder } from '../Selection/Builder/EntityExpansionBuilder';
import { AuthenticationManager } from '../../../../@Service/Authentication/AuthenticationManager';
import { EntityCacheService } from '../../../Service/Entity/EntityCacheService';
import { EntityMetadata } from '../../../../@Api/Model/Implementation/EntityMetadata';
import { BespokeEntity } from './Bespoke/BespokeEntity';
import { BespokeEntityType } from './BespokeEntityType';
import { WelcomeStore } from '../../../../@Service/Welcome/WelcomeStore';
import { BaseStore } from '../../../../@Framework/Store/BaseStore';
import { CurrentUserStore } from '../../User/CurrentUserStore';
import md5 from 'md5';
import { EntityMetadataMutation } from '../../../../@Api/Model/Implementation/EntityMetadataMutation';
import { EntityFieldMutation } from '../../../../@Api/Model/Implementation/EntityFieldMutation';
import { EntityFieldCreationMutation } from '../../../../@Api/Model/Implementation/EntityFieldCreationMutation';
import { EntityRelationshipDefinitionMutation } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinitionMutation';
import { EntityRelationshipDefinitionCreationMutation } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinitionCreationMutation';
import { EntityTypeMutation } from '../../../../@Api/Model/Implementation/EntityTypeMutation';
import { EntityTypeCreationMutation } from '../../../../@Api/Model/Implementation/EntityTypeCreationMutation';
import { EntityRelationshipDefinitionDeletionMutation } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinitionDeletionMutation';
import { EntityTypeDeletionMutation } from '../../../../@Api/Model/Implementation/EntityTypeDeletionMutation';
import { EntityFieldDeletionMutation } from '../../../../@Api/Model/Implementation/EntityFieldDeletionMutation';
import { EntityCacheReferrer } from '../../../Service/Entity/EntityCacheReferrer';

const CacheReferrer: EntityCacheReferrer = 'EntityTypeStore';

export class EntityTypeStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ApiControllerStore') apiControllerStore: ApiControllerStore;
    @injectWithQualifier('EntitySelectionController') entitySelectionController: EntitySelectionController;
    @injectWithQualifier('ApiClient') apiClient: ApiClient;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('PredicateTypeStore') predicateTypeStore: PredicateTypeStore;
    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('EntityCacheService') entityCacheService: EntityCacheService;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;
    @injectWithQualifier('AuthenticationManager') authenticationManager: AuthenticationManager;
    @injectWithQualifier('WelcomeStore') welcomeStore: WelcomeStore;

    // ------------------------- Properties -------------------------

    @observable.shallow types = observable.array<EntityType>();
    @observable.shallow bespokeByType = observable.map<EntityType, BespokeEntityType>();
    @observable.ref staticType: EntityType;
    @observable.ref idField: EntityField;
    @observable.ref uuidField: EntityField;
    @observable.ref typeField: EntityField;
    @observable.ref sortIndexField: EntityField;
    @observable.ref nameField: EntityField;
    @observable.ref descriptionField: EntityField;
    @observable.ref bespoke: BespokeEntityTypeStore;
    @observable.ref entity: BespokeEntity;
    @observable.ref metadata: EntityMetadata;
    @observable.shallow appliedMutationIds = new Set<string>();

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return this.initializeMetadata(this.welcomeStore.welcomePackage.metadata);
    }

    // -------------------------- Computed --------------------------

    @computed
    get typeById(): Map<number, EntityType>
    {
        const map = new Map();

        this.types
            .filter(
                type =>
                    !type.isStaticType())
            .forEach(
                type =>
                    map.set(
                        type.id,
                        type));

        return map;
    }

    @computed
    get typeByCode(): Map<string, EntityType>
    {
        const map = new Map();

        this.types
            .forEach(
                type =>
                    map.set(
                        type.code,
                        type));

        return map;
    }

    @computed
    get typeByEntityId(): Map<number, EntityType>
    {
        const map = new Map();

        this.types
            .filter(
                type =>
                    type.entity)
            .forEach(
                type =>
                    map.set(
                        type.entity.id,
                        type));

        return map;
    }

    @computed
    get fields(): EntityField[]
    {
        return this.metadata.fields;

        // return this.types
        //     .map(
        //         type =>
        //             type.fields.slice())
        //     .reduce((a, b) => a.concat(b), []);
    }

    @computed
    get fieldById(): Map<number, EntityField>
    {
        const map = new Map();

        this.fields
            .filter(
                field =>
                    !field.isStaticField())
            .forEach(
                field =>
                    map.set(
                        field.id,
                        field));

        return map;
    }

    @computed
    get fieldByCode(): Map<string, EntityField>
    {
        const map = new Map();

        this.fields
            .forEach(
                field =>
                    map.set(
                        field.code,
                        field));

        return map;
    }

    @computed
    get fieldByEntityId(): Map<number, EntityField>
    {
        const map = new Map();

        this.fields
            .filter(
                fields =>
                    fields.entity)
            .forEach(
                field =>
                    map.set(
                        field.entity.id,
                        field));

        return map;
    }

    @computed
    get relationshipDefinitions(): EntityRelationshipDefinition[]
    {
        return this.metadata.relationshipDefinitions;
    }

    @computed
    get relationshipDefinitionById(): Map<number, EntityRelationshipDefinition>
    {
        const map = new Map();

        this.relationshipDefinitions
            .forEach(
                relationshipDefinition =>
                    map.set(
                        relationshipDefinition.id,
                        relationshipDefinition));

        return map;
    }

    @computed
    get relationshipDefinitionByCode(): Map<string, EntityRelationshipDefinition>
    {
        const map = new Map();

        this.relationshipDefinitions
            .forEach(
                relationshipDefinition =>
                    map.set(
                        relationshipDefinition.code,
                        relationshipDefinition));

        return map;
    }

    @computed
    get relationshipDefinitionByEntityId(): Map<number, EntityRelationshipDefinition>
    {
        const map = new Map();

        this.relationshipDefinitions
            .filter(
                relationshipDefinition =>
                    relationshipDefinition.entity)
            .forEach(
                relationshipDefinition =>
                    map.set(
                        relationshipDefinition.entity.id,
                        relationshipDefinition));

        return map;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    initializeMetadata(metadata: EntityMetadata): Promise<any>
    {
        this.metadata = metadata;

        const types = metadata.types;
        const typeById = new Map<number, EntityType>();
        let staticType: EntityType;

        for (let type of types)
        {
            if (type.isStaticType())
            {
                staticType = type;
            }
            else
            {
                typeById.set(type.id, type);
                type.entity = metadata.entityByTypeId[type.id.toString()];
            }

            type.fields = observable.array();
            type.parentRelationshipDefinitions = observable.array();
            type.childRelationshipDefinitions = observable.array();
        }

        metadata.fields =
            metadata.fields
                .filter(
                    field =>
                    {
                        if (field.entityType)
                        {
                            if (field.isStaticField())
                            {
                                staticType.fields.push(field);

                                return true;
                            }
                            else
                            {
                                field.entity = metadata.entityByFieldId[field.id];

                                const type = typeById.get(field.entityType.id);

                                if (type)
                                {
                                    type.fields.push(field);

                                    return true;
                                }
                            }
                        }

                        return false;
                    });

        metadata.relationshipDefinitions =
            metadata.relationshipDefinitions
                .filter(
                    relationshipDefinition =>
                    {
                        relationshipDefinition.entity = metadata.entityByRelationshipDefinitionId[relationshipDefinition.id];

                        if (relationshipDefinition.parentEntityType
                            && relationshipDefinition.childEntityType)
                        {
                            const parentType = typeById.get(relationshipDefinition.parentEntityType.id);
                            const childType = typeById.get(relationshipDefinition.childEntityType.id);

                            if (parentType && childType)
                            {
                                parentType.childRelationshipDefinitions.push(relationshipDefinition);
                                childType.parentRelationshipDefinitions.push(relationshipDefinition);

                                return true;
                            }
                        }

                        return false;
                    });

        this.types.replace(types);
        this.staticType = null;

        for (let type of types)
        {
            type.initialize(this, false);
        }

        for (let type of types)
        {
            type.childTypes = [];

            if (type.code === 'static.entity')
            {
                this.staticType = type;
                this.idField = type.getFieldByCode('static.entity.id');
                this.uuidField = type.getFieldByCode('static.entity.uuid');
                this.typeField = type.getFieldByCode('static.entity.type');
                this.sortIndexField = type.getFieldByCode('static.entity.sort_index');
                // this.isRootField = type.getFieldByCode('static.entity.is_root');
                // this.isActiveField = type.getFieldByCode('static.entity.is_active');
                this.nameField = type.getFieldByCode('static.entity.name');
                this.descriptionField = type.getFieldByCode('static.entity.description');
                // this.ownerField = type.getFieldByCode('static.entity.owner');
                // this.assigneeField = type.getFieldByCode('static.entity.assignee');
                // this.followUpStartDateField = type.getFieldByCode('static.entity.follow_up_start_date');
                // this.followUpEndDateField = type.getFieldByCode('static.entity.follow_up_end_date');
                // this.deadlineDateField = type.getFieldByCode('static.entity.deadline_date');
            }
        }

        for (let type of types)
        {
            [true, false]
                .forEach(
                    isParent =>
                    {
                        const relationshipDefinitions = observable.array<EntityRelationshipDefinition>();

                        type.getRelationshipDefinitions(isParent)
                            .forEach(
                                relationshipDefinition =>
                                {
                                    relationshipDefinition.initialize(this);

                                    relationshipDefinitions.push(relationshipDefinition);
                                });

                        if (isParent)
                        {
                            type.parentRelationshipDefinitions = relationshipDefinitions;
                        }
                        else
                        {
                            type.childRelationshipDefinitions = relationshipDefinitions;
                        }
                    });

            if (type.hasParent())
            {
                let parentType = this.typeById.get(type.parentType.id);

                if (parentType == null)
                {
                    console.warn('parent type for type', type.code, 'not found', type);
                    type.parentType = undefined;
                }
                else
                {
                    type.parentType = parentType;
                    parentType.childTypes.push(type);
                }
            }
        }

        this.bespoke = new BespokeEntityTypeStore(this);
        this.entity = new BespokeEntity(this);

        this.postInitialize();

        return Promise.resolve();
    }

    expandTypes(types: EntityType[])
    {
        const typePath = this.bespoke.types.EntityType.Type.path();

        return Promise.all<any>([
            new EntityExpansionBuilder(
                typePath.entityType,
                types
                    .map(
                        type =>
                            type.entity)
                    .filter(
                        type =>
                            type !== undefined),
                [
                    typePath
                        .joinTo(
                            this.bespoke.types.Pack.RelationshipDefinition.Entities,
                            true),
                    typePath
                        .joinTo(
                            this.bespoke.types.EntityType.RelationshipDefinition.FieldGroups,
                            false)
                        .joinTo(
                            this.bespoke.types.Pack.RelationshipDefinition.Entities,
                            true),
                    typePath
                        .joinTo(
                            this.bespoke.types.EntityType.RelationshipDefinition.FieldGroups,
                            false)
                        .joinTo(
                            this.bespoke.types.EntityFieldGroup.RelationshipDefinition.Fields,
                            false)
                        .joinTo(
                            this.bespoke.types.Pack.RelationshipDefinition.Entities,
                            true),
                    typePath
                        .joinTo(
                            this.bespoke.types.EntityType.RelationshipDefinition.FieldGroups,
                            false)
                        .joinTo(
                            this.bespoke.types.EntityFieldGroup.RelationshipDefinition.Relationships,
                            false)
                        .joinTo(
                            this.bespoke.types.Pack.RelationshipDefinition.Entities,
                            true)
                ])
                .expand(),
        ]);
    }

    @action.bound
    postInitialize()
    {
        this.types
            .forEach(type =>
            {
                type.postInitialize(
                    this,
                    true);
            });

        this.fields
            .forEach(
                field =>
                    field.postInitialize(
                        this,
                        true));

        this.relationshipDefinitions
            .forEach(relationshipDefinition =>
            {
                relationshipDefinition.postInitialize(this);
            });

        this.entityCacheService.mergeEntityNetworkForEntities(
            [
                ...this.types
                    .filter(
                        type =>
                            type.entity)
                    .map(
                        type =>
                            type.entity),
                ...this.fields
                    .filter(
                        type =>
                            type.entity)
                    .map(
                        type =>
                            type.entity),
                ...this.relationshipDefinitions
                    .filter(
                        type =>
                            type.entity)
                    .map(
                        type =>
                            type.entity)
            ],
            undefined,
            CacheReferrer);

        this.types.forEach(
            type =>
            {
                if (type.entity)
                {
                    type.entity = this.entityCacheService.getEntityById(type.entity.id);
                }
            });

        this.fields.forEach(
            field =>
            {
                if (field.entity)
                {
                    field.entity = this.entityCacheService.getEntityById(field.entity.id);
                }
            });

        this.relationshipDefinitions.forEach(
            relationshipDefinition =>
            {
                if (relationshipDefinition.entity)
                {
                    relationshipDefinition.entity = this.entityCacheService.getEntityById(relationshipDefinition.entity.id);
                }
            });
    }

    // ------------------------ Public logic ------------------------

    public registerBespoke(type: EntityType, bespokeEntity: BespokeEntityType)
    {
        this.bespokeByType.set(type, bespokeEntity);
    }

    public getBespokeByEntityType(type: EntityType): BespokeEntityType
    {
        if (this.bespokeByType.has(type))
        {
            return this.bespokeByType.get(type);
        }
        else
        {
            if (typeof type.hasParent === 'function' && type.hasParent())
            {
                return this.getBespokeByEntityType(type.parentType);
            }
            else
            {
                return this.entity;
            }
        }
    }

    public getTypeById(id: number): EntityType
    {
        const type = this.typeById.get(id);

        if (!type)
        {
            // console.warn('entity type with id is not found', id);
        }

        return type;
    }

    public getTypeByCode(code: string): EntityType
    {
        return this.typeByCode.get(code);
    }

    public getTypeByEntityId(id: number): EntityType
    {
        return this.typeByEntityId.get(id);
    }

    public getFieldByEntityId(id: number): EntityField
    {
        return this.fieldByEntityId.get(id);
    }

    public getTypeByIdOrCode(id?: number,
                             code?: string): EntityType
    {
        if (id)
        {
            return this.getTypeById(id);
        }
        else if (code)
        {
            return this.getTypeByCode(code);
        }
        else
        {
            return null;
        }
    }

    @action.bound
    registerType(entityType: EntityType)
    {
        this.types.push(entityType);

        if (entityType.hasParent())
        {
            entityType.parentType
                .childTypes
                .push(entityType);
        }

        this.metadata.types.push(entityType);

        return this.expandTypes([ entityType ]);
    }

    @action.bound
    applyMetadataMutation(mutation: EntityMetadataMutation,
                          target?: EntityType | EntityField | EntityRelationshipDefinition)
    {
        if (this.appliedMutationIds.has(mutation.id))
        {
            if (mutation instanceof EntityTypeMutation)
            {
                return Promise.resolve(this.getTypeById(mutation.type.id));
            }
            else if (mutation instanceof EntityFieldMutation)
            {
                return Promise.resolve(this.getFieldById(mutation.field.id));
            }
            else if (mutation instanceof EntityRelationshipDefinitionMutation)
            {
                return Promise.resolve(this.getRelationshipDefinitionById(mutation.relationshipDefinition.id));
            }
            else
            {
                return Promise.resolve();
            }
        }

        this.appliedMutationIds.add(mutation.id);

        if (mutation instanceof EntityTypeMutation)
        {
            let entityType = target as EntityType || this.getTypeById(mutation.type.id);

            if (mutation instanceof EntityTypeDeletionMutation)
            {
                if (entityType)
                {
                    this.deleteType(entityType);
                }

                return this.entityCacheService.applyEvents(mutation.events)
            }
            else
            {
                if (!entityType)
                {
                    entityType = new EntityType();
                }

                const source = mutation.type;
                entityType.id = source.id;
                entityType.code = source.code;
                entityType.isInstantiable = source.isInstantiable;
                entityType.isSwitchable = source.isSwitchable;
                entityType.isIdentity = source.isIdentity;
                entityType.isQueueable = source.isQueueable;
                entityType.isRoot = source.isRoot;
                entityType.isEmbedded = source.isEmbedded;
                entityType.isOwnable = source.isOwnable;
                entityType.isAssignable = source.isAssignable;
                entityType.isOrganizationIdentifiable = source.isOrganizationIdentifiable;
                entityType.isUserIdentifiable = source.isUserIdentifiable;
                entityType.isUserInternalIdentifiable = source.isUserInternalIdentifiable;
                entityType.isUserExternalIdentifiable = source.isUserExternalIdentifiable;
                entityType.showInModuleSwitcher = source.showInModuleSwitcher;
                entityType.hasPicture = source.hasPicture;
                entityType.hasFollowUp = source.hasFollowUp;
                entityType.hasDuplicatePrevention = source.hasDuplicatePrevention;
                entityType.parentType = source.parentType;
                entityType.childTypes = source.childTypes ?? entityType.childTypes;
                entityType.oldNameExpression = source.oldNameExpression;
                entityType.oldCompactNameExpression = source.oldCompactNameExpression;
                entityType.oldSortNameExpression = source.oldSortNameExpression;
                entityType.oldDescriptionExpression = source.oldDescriptionExpression;
                entityType.avatarField = source.avatarField;
                entityType.nameField = source.nameField;
                entityType.compactNameField = source.compactNameField;
                entityType.sortNameField = source.sortNameField;
                entityType.descriptionField = source.descriptionField;
                entityType.nameExpression = source.nameExpression;
                entityType.compactNameExpression = source.compactNameExpression;
                entityType.sortNameExpression = source.sortNameExpression;
                entityType.descriptionExpression = source.descriptionExpression;
                entityType.form = source.form;
                entityType.color = source.color;
                entityType.icon = source.icon;
                entityType.sortIndex = source.sortIndex;

                entityType.initialize(this, false);
                entityType.postInitialize(this, false);

                return this.entityCacheService.applyEvents(mutation.events)
                    .then(
                        () =>
                            runInAction(
                                () =>
                                {
                                    entityType.entity =
                                        this.entityCacheService
                                            .getEntityById(source.entity.id);

                                    if (mutation instanceof EntityTypeCreationMutation)
                                    {
                                        return this.registerType(entityType);
                                    }
                                }))
                    .then(
                        () =>
                            Promise.resolve(entityType));
            }
        }
        else if (mutation instanceof EntityFieldMutation)
        {
            if (!mutation.field)
            {
                console.warn('bad field mutation', mutation);

                return Promise.resolve();
            }

            let field = target as EntityField || this.getFieldById(mutation.field.id);

            if (mutation instanceof EntityFieldDeletionMutation)
            {
                if (field)
                {
                    this.deleteField(field);
                }

                return this.entityCacheService.applyEvents(mutation.events)
            }
            else
            {
                if (!field)
                {
                    field = new EntityField();
                }

                const source = mutation.field;
                field.id = source.id;
                field.entityType = source.entityType;
                field.code = source.code;
                field.mergeCode = source.mergeCode;
                // field.name = source.name;
                // field.description = source.description;
                // field.descriptionLanguageEntry = source.descriptionLanguageEntry;
                field.type = source.type;
                field.specification = source.specification;
                field.handlers = source.handlers;
                field.isRequired = source.isRequired;
                field.isDefining = source.isDefining;
                field.isDefault = source.isDefault;
                field.isCompact = source.isCompact;
                field.isReadonly = source.isReadonly;
                field.isHiddenFromTimeline = source.isHiddenFromTimeline;
                field.computation = source.computation;
                field.defaultValueComputation = source.defaultValueComputation;
                field.numberGenerator = source.numberGenerator;
                field.fieldType = source.fieldType;
                field.importanceLevel = source.importanceLevel;
                field.fieldTypeCode = source.fieldTypeCode;
                field.sortIndex = source.sortIndex;
                field.conditions = source.conditions;

                field.initialize(
                    this.getTypeById(field.entityType.id),
                    this,
                    false);

                field.postInitialize(
                    this,
                    false);

                return this.entityCacheService.applyEvents(mutation.events)
                    .then(
                        () =>
                            runInAction(
                                () =>
                                {
                                    field.entity =
                                        this.entityCacheService
                                            .getEntityById(mutation.field.entity.id);

                                    if (mutation instanceof EntityFieldCreationMutation)
                                    {
                                        this.registerField(field);
                                    }
                                }))
                    .then(
                        () =>
                            Promise.resolve(field));
            }
        }
        else if (mutation instanceof EntityRelationshipDefinitionMutation)
        {
            let relationshipDefinition = target as EntityRelationshipDefinition || this.getRelationshipDefinitionById(mutation.relationshipDefinition.id);

            if (mutation instanceof EntityRelationshipDefinitionDeletionMutation)
            {
                if (relationshipDefinition)
                {
                    this.deleteRelationshipDefinition(relationshipDefinition);
                }

                return this.entityCacheService.applyEvents(mutation.events);
            }
            else
            {
                if (!relationshipDefinition)
                {
                    relationshipDefinition = new EntityRelationshipDefinition();
                }

                const source = mutation.relationshipDefinition;
                relationshipDefinition.id = source.id;
                relationshipDefinition.code =  source.code;
                relationshipDefinition.mergeCode = source.mergeCode;
                relationshipDefinition.parentEntityType = source.parentEntityType;
                relationshipDefinition.parentEntityTypeParameter = source.parentEntityTypeParameter;
                relationshipDefinition.childEntityType = source.childEntityType;
                relationshipDefinition.childEntityTypeParameter = source.childEntityTypeParameter;
                relationshipDefinition.parentComputation = source.parentComputation;
                relationshipDefinition.childComputation = source.childComputation;
                relationshipDefinition.parentFreeFormField = source.parentFreeFormField;
                relationshipDefinition.childFreeFormField = source.childFreeFormField;
                relationshipDefinition.type = source.type;
                relationshipDefinition.cardinality = source.cardinality;
                relationshipDefinition.isDefaultForParent = source.isDefaultForParent;
                relationshipDefinition.isDefaultForChild = source.isDefaultForChild;
                relationshipDefinition.isMandatoryForParent = source.isMandatoryForParent;
                relationshipDefinition.isMandatoryForChild = source.isMandatoryForChild;
                relationshipDefinition.isVisibleInParent = source.isVisibleInParent;
                relationshipDefinition.isVisibleInChild = source.isVisibleInChild;
                relationshipDefinition.isVisibleAsTabInParent = source.isVisibleAsTabInParent;
                relationshipDefinition.isVisibleAsTabInChild = source.isVisibleAsTabInChild;
                relationshipDefinition.isManagedInParent = source.isManagedInParent;
                relationshipDefinition.isManagedInChild = source.isManagedInChild;
                relationshipDefinition.isInactiveCascadedToParent = source.isInactiveCascadedToParent;
                relationshipDefinition.isInactiveCascadedToChild = source.isInactiveCascadedToChild;
                relationshipDefinition.isLazyFromParent = source.isLazyFromParent;
                relationshipDefinition.isLazyFromChild = source.isLazyFromChild;
                relationshipDefinition.parentPredicate = source.parentPredicate;
                relationshipDefinition.childPredicate = source.childPredicate;
                relationshipDefinition.parentDeletionReferentialAction = source.parentDeletionReferentialAction;
                relationshipDefinition.childDeletionReferentialAction = source.childDeletionReferentialAction;
                relationshipDefinition.dependentOnRelationshipDefinition = source.dependentOnRelationshipDefinition;
                relationshipDefinition.configuration = source.configuration;
                relationshipDefinition.dependentRelationshipDefinitions = source.dependentRelationshipDefinitions;
                relationshipDefinition.importanceLevel = source.importanceLevel;
                relationshipDefinition.color = source.color;
                relationshipDefinition.icon = source.icon;
                relationshipDefinition.sortIndex = source.sortIndex;
                relationshipDefinition.defaultParentPath = source.defaultParentPath;
                relationshipDefinition.defaultChildPath = source.defaultChildPath;

                relationshipDefinition.initialize(this);
                relationshipDefinition.postInitialize(this);

                return this.entityCacheService.applyEvents(mutation.events)
                    .then(
                        () =>
                            runInAction(
                                () =>
                                {
                                    relationshipDefinition.entity =
                                        this.entityCacheService
                                            .getEntityById(source.entity.id);

                                    if (mutation instanceof EntityRelationshipDefinitionCreationMutation)
                                    {
                                        this.registerRelationshipDefinition(relationshipDefinition);
                                    }
                                }))
                    .then(
                        () =>
                            Promise.resolve(relationshipDefinition));
            }
        }
        else
        {
            return Promise.resolve();
        }
    }

    @action.bound
    registerField(field: EntityField)
    {
        field.entityType.fields.push(field);

        if (!field.isStaticField())
        {
            field.entityType.fieldById.set(field.id, field);
        }

        field.entityType.fieldByCode.set(field.code, field);

        this.metadata.fields.push(field);
    }

    @action.bound
    registerRelationshipDefinition(relationshipDefinition: EntityRelationshipDefinition)
    {
        [ true, false ]
            .forEach(
                isParent =>
                    relationshipDefinition.getEntityType(isParent)
                        .getRelationshipDefinitions(!isParent)
                        .push(relationshipDefinition));

        this.metadata.relationshipDefinitions.push(relationshipDefinition);
    }

    @action.bound
    deleteType(entityType: EntityType)
    {
        if (entityType.hasParent())
        {
            (entityType.parentType
                .childTypes as IObservableArray)
                .remove(entityType);
        }

        entityType.childTypes
            .forEach(
                childType =>
                    this.deleteType(childType));

        [ true, false ]
            .forEach(
                isParent =>
                    entityType.getRelationshipDefinitions(isParent)
                        .forEach(
                            relationshipDefinition =>
                                this.deleteRelationshipDefinition(relationshipDefinition)));

        this.types.remove(entityType);

        this.metadata.types.splice(
            this.metadata.types.indexOf(entityType),
            1);
    }

    @action.bound
    deleteRelationshipDefinition(relationshipDefinition: EntityRelationshipDefinition)
    {
        [ true, false ]
            .forEach(
                isParent =>
                    (relationshipDefinition.getEntityType(isParent)
                        .getRelationshipDefinitions(!isParent) as IObservableArray)
                        .remove(relationshipDefinition));

        this.metadata.relationshipDefinitions.splice(
            this.metadata.relationshipDefinitions.indexOf(relationshipDefinition),
            1);
    }

    @action.bound
    deleteField(field: EntityField)
    {
        (field.entityType.fields as IObservableArray)
            .remove(field);

        this.metadata.fields.splice(
            this.metadata.fields.indexOf(field),
            1);
    }

    @action.bound
    public addOrUpdateType(entityType: EntityType)
    {
        if (this.typeById.has(entityType.id))
        {
            // TODO: update parents/children

            // TODO: update relationship definitions
        }
        else
        {
            this.types.push(entityType);
            this.typeById.set(entityType.id, entityType);
            this.typeByCode.set(entityType.code, entityType);

            if (entityType.hasParent())
            {
                entityType.parentType = this.getTypeById(entityType.parentType.id);

                if (entityType.parentType)
                {
                    entityType.parentType.childTypes.push(entityType);
                }
            }
        }
    }

    public getRelationshipDefinitionById(id: number): EntityRelationshipDefinition
    {
        const relationshipDefinition = this.relationshipDefinitionById.get(id);

        if (!relationshipDefinition)
        {
            // console.warn('relationship definition with id is not found', id);
        }

        return relationshipDefinition;
    }

    public getRelationshipDefinitionByCode(code: string): EntityRelationshipDefinition
    {
        return this.relationshipDefinitionByCode.get(code);
    }

    public getFieldById(id: number): EntityField
    {
        const field = this.fieldById.get(id);

        if (!field)
        {
            // noinspection TsLint
            // console.warn('field with id is not found', id);
        }

        return field;
    }

    public getFieldByCode(code: string): EntityField
    {
        return this.fieldByCode.get(code);
    }

    public getFieldByIdOrCode(id?: number,
                              code?: string): EntityField
    {
        if (id && id > 0)
        {
            return this.getFieldById(id);
        }
        else if (code)
        {
            return this.getFieldByCode(code);
        }
        else
        {
            return undefined;
        }
    }

    public getEntityTypeById(entityTypeId: number): EntityType
    {
        return this.typeById.get(entityTypeId);
    }

    public getVisibleStaticFields(entityType: EntityType): EntityField[]
    {
        return this.staticType.getInheritedFields();
    }

    public getVisibleEntityFieldPaths(entityType: EntityType,
                                      includePluralRelationships: boolean = false,
                                      ignoreHidden: boolean = false): EntityFieldPath[]
    {
        const rootPath = EntityPath.fromEntityType(entityType);
        const isSwitchableType = entityType.isSwitchableByInheritance();

        return [

            // Add type if entity type is switchable
            ...isSwitchableType
                ?
                    [
                        rootPath
                            .field(this.bespoke.types.Entity.Field.Type)
                    ]
                :
                    [],

            // Add static fields
            ...this.getVisibleStaticFields(entityType)
                .filter(
                    field =>
                        field === this.bespoke.types.Entity.Field.Type
                            ?
                                !isSwitchableType
                            :
                                true)
                .map(staticEntityField =>
                    rootPath.field(staticEntityField)),

            // Add entity fields
            ...entityType.getInheritedFields()
                .filter(field =>

                    ignoreHidden ||
                    (!field.entity?.getObjectValueByField(this.bespoke.types.EntityField.Field.IsHiddenFromDetails))
                )
                .map(field =>
                    rootPath.field(field)),

            // Add relationship definitions
            ...[ true, false ]
                .map(
                    isParent =>
                        entityType.getInheritedRelationshipDefinitions(isParent)
                            .filter(
                                relationshipDefinition =>
                                    includePluralRelationships
                                        ?
                                            true
                                        :
                                            (ignoreHidden || relationshipDefinition.isVisibleInDetails(!isParent))
                                            && (relationshipDefinition.isSingular(isParent) || relationshipDefinition.isVisibleInDetails(!isParent)))
                            .map(relationshipDefinition =>
                                rootPath
                                    .joinTo(
                                        relationshipDefinition,
                                        isParent)
                                    .field()))
                .reduce((a, b) => a.concat(b), []),
        ].filter(item => item !== undefined);
    }

    public getVisibleEntityPaths(entityType: EntityType,
                                 includePluralRelationships: boolean = false): EntityPath[]
    {
        return [

            ...entityType.getInheritedRelationshipDefinitions(true)
                .filter(relationshipDefinition =>
                    includePluralRelationships
                        ?
                            true
                        :
                            relationshipDefinition.isSingular(true))
                .map(relationshipDefinition =>
                    EntityPath.root(entityType)
                        .joinTo(relationshipDefinition, true)),

            ...entityType.getInheritedRelationshipDefinitions(false)
                .filter(relationshipDefinition =>
                    includePluralRelationships
                        ?
                            true
                        :
                            relationshipDefinition.isSingular(false))
                .map(relationshipDefinition =>
                    EntityPath.root(entityType)
                        .joinTo(relationshipDefinition, false)),
        ];
    }

    public getFileUrl(value: EntityValue,
                      skipEmptyCheck: boolean = false): string
    {
        if (value.id == null || (!skipEmptyCheck && value.isEmpty))
        {
            return undefined;
        }
        else
        {
            // Add a q parameter such that the cache is reloaded upon changing the value
            return this.apiClient.url(`/entity/${value.entity.entityType.id}/${value.entity.id}/file/${value.field.id}?q=${value.dataObject.isEmpty || !value.dataObject.value.url ? '' : md5(value.dataObject.value.url)}`);
        }
    }

    // ----------------------- Private logic ------------------------
}
