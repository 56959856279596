import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { openEntity } from '../../../../../../../../../../@Util/openEntity';
import Link from '../../../../../../../../../../../../../@Future/Component/Generic/Link/Link';
import { ContentProps } from '../../Content';
import InputIcon from '../../../../../../../../../../../../../@Future/Component/Generic/Input/Input/Icon/InputIcon';
import { Entity } from '../../../../../../../../../../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';

export interface ParentActivityProps extends ContentProps
{
    parentActivity: Entity;
}

const ParentActivity: React.FC<ParentActivityProps> =
    props =>
    {
        const openParentActivity =
            useCallback(
                () =>
                    openEntity(props.parentActivity),
                [
                    props.parentActivity
                ]);

        if (props.parentActivity)
        {
            return <Link
                onClick={openParentActivity}
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={5}
                    alignment="center"
                >
                    <ViewGroupItem>
                        <InputIcon
                            icon={props.parentActivity.entityType.getInheritedIcon()}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        {props.parentActivity.name}
                    </ViewGroupItem>
                </ViewGroup>
            </Link>;
        }
        else
        {
            return null;
        }
    };

export default observer(ParentActivity);
