import FilterOption from '../../../Model/FilterOption';
import getLocalPrimitiveSetting from '../../../../../Setting/Api/getLocalPrimitiveSetting';
import getLocalEntitySetting from '../../../../../Setting/Api/getLocalEntitySetting';
import { runInAction } from 'mobx';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import { DateRangeType } from '../../../../../DataObject/Type/Date/DateRange/DateRangeType';
import { DateRangeValue } from '../../../../../DataObject/Type/Date/DateRange/DateRangeValue';

export default async function initializeFilterOption(filterOptionId: string,
                                                     filterOption: FilterOption)
{
    const settingId = filterOption.getFilterOptionSettingId(filterOptionId);

    if (filterOption.fieldPath.isField)
    {
        const dataObject = await getLocalPrimitiveSetting(settingId);

        runInAction(
            () =>
                filterOption.value = dataObject);

        if (!dataObject || dataObject.isEmpty)
        {
            const type =
                filterOption.isRange
                    ?
                        filterOption.fieldPath.field.dataObjectSpecification.type.rangeTypeId()
                    :
                        filterOption.fieldPath.field.dataObjectSpecification.type.id();

            runInAction(
                () =>
                {
                    filterOption.value =
                        DataObject.constructFromTypeIdAndValue(
                            type,
                            undefined);

                    if (filterOption.value.specification.type instanceof DateRangeType)
                    {
                        filterOption.value.setValue({
                            type: 'CurrentYear'
                        } as DateRangeValue);
                    }
                });
        }
    }
    else if (filterOption.fieldPath.isRelationship)
    {
        const entity = await getLocalEntitySetting(settingId, filterOption.fieldPath.entityType);

        runInAction(
            () =>
                filterOption.value = entity);
    }
}
