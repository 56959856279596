import { action, computed, observable, runInAction } from 'mobx';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntityViewerStore } from '../../Deprecated/EntityViewerStore';
import { EntityViewerPageStore } from '../EntityViewerPageStore';
import { PageStore } from '../../../../../../@Service/Navigation/Page/PageStore';
import { BaseComponentProps } from '../../../../../../@Framework/Component/BaseComponent';
import { EntityViewerPageInitializer } from './EntityViewerPageInitializer';
import { TransactionalModel } from '../../../../../../@Util/TransactionalModelV2/index';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import getEntityById from '../../../../../../@Api/Entity/Bespoke/getEntityById';
import { EntityTypeStore } from '../../../Type/EntityTypeStore';
import getEntityByUuid from '../../../../../../@Api/Entity/Bespoke/getEntityByUuid';
import { EntityViewerCommand } from '../../EntityViewer';

export class EntityViewerPageInitializerStore extends PageStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable entityId: string;
    @observable entity: TransactionalModel<Entity>;
    @observable entityViewerPageStore: EntityViewerPageStore;
    @observable isCompact: boolean;
    @observable isEmbedded: boolean;
    @observable onClose: (entity: Entity) => void;
    @observable command: EntityViewerCommand;

    // ------------------------ Constructor -------------------------

    constructor(entityId: string,
                isCompact: boolean = false,
                isEmbedded: boolean = false,
                onClose?: (entity: Entity) => void,
                command?: EntityViewerCommand)
    {
        super(0);

        this.entityId = entityId;
        this.isCompact = isCompact;
        this.isEmbedded = isEmbedded;
        this.onClose = onClose;
        this.command = command;
        this.hasPagePadding = false;
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return this.fetchEntity()
            .then(
                ({ value: entity, dispose }) =>
                {
                    this.registerDisposable(dispose);
                    this.registerDisposable(
                        () =>
                            runInAction(
                                () =>
                                {
                                    this.setInitialized(false);
                                    this.entity = null;
                                    this.entityViewerPageStore = null;
                                }));
                    this.setEntity(entity);

                    this.setEntityViewerPageStore(
                        new EntityViewerPageStore(
                            this.entity,
                            new EntityViewerStore(
                                this.entity,
                                this.isCompact,
                                this.onClose,
                                undefined,
                                this.command)));
                })
            .catch(() =>
            {
                this.setEntity(null);
            });
    }

    fetchEntity()
    {
        if (isNaN(this.entityId as any))
        {
            return getEntityByUuid(
                this.entityTypeStore.bespoke.types.Entity.Type,
                this.entityId);
        }
        else
        {
            return getEntityById(
                this.entityTypeStore.bespoke.types.Entity.Type,
                parseInt(this.entityId, 10));
        }
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    @computed
    get viewerStore(): EntityViewerPageStore
    {
        if (this.entityViewerPageStore)
        {
            return this.entityViewerPageStore;
        }
        else
        {
            return undefined;
        }
    }

    // -------------------------- Actions ---------------------------

    @action
    setEntity(entity: TransactionalModel<Entity>)
    {
        this.entity = entity;
    }

    @action
    setEntityViewerPageStore(entityViewerPageStore: EntityViewerPageStore)
    {
        this.entityViewerPageStore = entityViewerPageStore;
    }

    get componentClass(): React.ComponentClass<BaseComponentProps<PageStore>>
    {
        return EntityViewerPageInitializer;
    }

    get name(): string
    {
        if (this.viewerStore)
        {
            return this.viewerStore.name;
        }
        else
        {
            return undefined;
        }
    }

    get icon(): string
    {
        if (this.viewerStore)
        {
            return this.viewerStore.icon;
        }
        else
        {
            return undefined;
        }
    }

    get color(): string
    {
        if (this.viewerStore)
        {
            return this.viewerStore.color;
        }
        else
        {
            return undefined;
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
