import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import ValueType from '../../../../../../../../@Api/Automation/Value/Type/ValueType';
import Value from '../../../../../../../../@Api/Automation/Value/Value';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ValueTypeEditor from './ValueTypeEditor';
import ValueEditor, { ValueEditorProps } from './ValueEditor';

export interface FreeValueEditorProps<T extends ValueType<any> = ValueType<any>, V extends Value<any, T> = Value<any, any>> extends ValueEditorProps<T, V>
{

}

const FreeValueEditor: React.FC<ValueEditorProps> =
    props =>
    {
        const { value, autoFocus } = props;
        const [ typeOverride, setTypeOverride ] = useState<ValueType<any> | undefined>(undefined);
        const finalType = typeOverride || value?.getType();

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                <ValueTypeEditor
                    value={finalType}
                    onChange={setTypeOverride}
                    autoFocus={autoFocus}
                />
            </ViewGroupItem>
            {
                finalType &&
                    <ViewGroupItem>
                        <ValueEditor
                            {...props}
                            type={finalType}
                            value={value && !finalType.isA(value.getType()) ? undefined : value}
                            autoFocus={autoFocus}
                            disallowFreeValue
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(FreeValueEditor);
