import LayoutDependencyContext from '../../../LayoutDependencyContext';
import ValueType from '../../../../Automation/Value/Type/ValueType';
import StaticTabSource from '../Model/StaticTabSource';
import DynamicTabSource from '../Model/DynamicTabSource';

export default async function getTabSourceByDescriptor(descriptor: any,
                                                       dependencyContext: LayoutDependencyContext,
                                                       valueType: ValueType<any>)
{
    switch (descriptor.type)
    {
        case 'Static':
            return StaticTabSource.fromDescriptor(
                descriptor,
                dependencyContext,
                valueType);

        case 'Dynamic':
            return DynamicTabSource.fromDescriptor(
                descriptor,
                dependencyContext,
                valueType);
    }

    throw new Error(`Unknown type: ${descriptor.type}`);
}
