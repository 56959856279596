import { observable } from 'mobx';
import { PortalDataSourceOrderingDirection } from '../../../../Portal/DataSource/PortalDataSourceOrdering';

export class PortalTableOrdering
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable columnId: string;
    @observable direction: PortalDataSourceOrderingDirection;

    // ------------------------ Constructor -------------------------

    constructor(
        id: string,
        columnId: string,
        direction: PortalDataSourceOrderingDirection
    )
    {
        this.id = id;
        this.columnId = columnId;
        this.direction = direction;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor()
    {
        return {
            id: this.id,
            columnId: this.columnId,
            direction: this.direction,
        };
    }

    static async fromDescriptor(
        descriptor: any
    )
    {
        return new PortalTableOrdering(
            descriptor.id,
            descriptor.columnId,
            descriptor.direction
        );
    }

    // ----------------------- Private logic ------------------------
}
