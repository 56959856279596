import { BaseStore, getOrCompute, getOrComputeProperty, PropType } from '../../../@Framework/Store/BaseStore';
import { action, computed } from 'mobx';
import { ViewComponent } from '../ViewStack/Model/ViewComponent';
import { ButtonStore } from '../Button/ButtonStore';
import { AvatarStore } from '../Avatar/AvatarStore';
import { CheckboxStore } from '../Checkbox/CheckboxStore';
import { IconStore } from '../Icon/IconStore';

type IdType = number | string;

export interface MenuGroup
{
    id: IdType,
    name: string
}
export interface MenuItemProps<D>
{
    id?: ((store: MenuItemStore) => IdType) | IdType;
    value?: ((store: MenuItemStore) => D) | D;
    icon?: ((store: MenuItemStore) => IconStore) | IconStore;
    checkbox?: ((store: MenuItemStore) => CheckboxStore) | CheckboxStore;
    avatar?: ((store: MenuItemStore) => AvatarStore) | AvatarStore;
    name?: ((store: MenuItemStore) => string) | string;
    color?: ((store: MenuItemStore) => string) | string;
    description?: ((store: MenuItemStore) => string) | string;
    expansionView?: ((store: MenuItemStore) => ViewComponent);
    actions?: ((store: MenuItemStore) => ButtonStore[]) | ButtonStore[],
    onClick?: (store: MenuItemStore) => void,
    isVisible?: (store: MenuItemStore) => boolean,
    isDisabled?: (store: MenuItemStore) => boolean,
    groupId?: PropType<MenuItemStore<D>, MenuItemProps<D>, IdType>;
}

const defaultProps: MenuItemProps<any> =
{

};

export class MenuItemStore<D = any> extends BaseStore<MenuItemProps<D>>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: MenuItemProps<D>)
    {
        super(props, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get id(): IdType
    {
        return getOrComputeProperty(this, 'id');
    }

    @computed
    get value(): D
    {
        return getOrComputeProperty(this, 'value');
    }

    @computed
    get avatar(): AvatarStore
    {
        return getOrComputeProperty(this, 'avatar');
    }

    @computed
    get checkbox(): CheckboxStore
    {
        return getOrComputeProperty(this, 'checkbox');
    }

    @computed
    get iconStore(): IconStore
    {
        return getOrComputeProperty(this, 'icon');
    }

    @computed
    get name(): string
    {
        return getOrComputeProperty(this, 'name');
    }

    @computed
    get color(): string
    {
        return getOrComputeProperty(this, 'color');
    }

    @computed
    get description(): string
    {
        return getOrComputeProperty(this, 'description');
    }

    @computed
    get isDisabled(): boolean
    {
        return getOrComputeProperty(this, 'isDisabled');
    }

    @computed
    get isExpandable(): boolean
    {
        return this.props.expansionView != null;
    }

    @computed
    get groupId(): number
    {
        return getOrCompute(this, this.props.groupId);
    }

    @computed
    get isVisible(): boolean
    {
        return this.props.isVisible ? this.props.isVisible(this) : true;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get actions(): ButtonStore[]
    {
        return getOrComputeProperty(this, 'actions', []);
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    handleClick()
    {
        if (this.props.onClick)
        {
            this.props.onClick(this);
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
