import React from 'react';
import { RecurrencePatternEditorProps } from '../../RecurrencePatternEditor';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { MonthlyRecurrencePattern, RelativeMonthlyRecurrencePattern } from '../../../Model/RecurrencePattern';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import { Radio } from '@material-ui/core';
import { MonthlyRecurrencePatternEditor } from './Monthly/MonthlyRecurrencePatternEditor';
import { RelativeMonthlyRecurrencePatternEditor } from './RelativeMonthly/RelativeMonthlyRecurrencePatternEditor';

export const MonthlyOrRelativeMonthlyRecurrencePatternEditor: React.FC<RecurrencePatternEditorProps<MonthlyRecurrencePattern | RelativeMonthlyRecurrencePattern>> =
    ({
        value,
        onChange,
     }) =>
    {
        const defaultMonthlyPattern: MonthlyRecurrencePattern = {
            type: 'Monthly',
            startDate: value.startDate,
            end: value.end,
            modifiedOccurrences: value.modifiedOccurrences,
            deletedOccurrences: value.deletedOccurrences,
            interval: 1,
            dayOfMonth: 1,
        };
        const defaultRelativeMonthlyPattern: RelativeMonthlyRecurrencePattern = {
            type: 'RelativeMonthly',
            startDate: value.startDate,
            end: value.end,
            modifiedOccurrences: value.modifiedOccurrences,
            deletedOccurrences: value.deletedOccurrences,
            interval: 1,
            dayOfWeek: 'Monday',
            dayOfWeekIndex: 1,
        };

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <Input
                    label={
                        <MonthlyRecurrencePatternEditor
                            value={value.type === 'Monthly' ? value : defaultMonthlyPattern}
                            onChange={onChange}
                            disabled={value.type !== 'Monthly'}
                        />
                    }
                    labelPosition="right"
                >
                    <Radio
                        checked={value.type === 'Monthly'}
                        onClick={
                            () =>
                                onChange(defaultMonthlyPattern)
                        }
                    />
                </Input>
            </ViewGroupItem>
            <ViewGroupItem>
                <Input
                    label={
                        <RelativeMonthlyRecurrencePatternEditor
                            value={value.type === 'RelativeMonthly' ? value : defaultRelativeMonthlyPattern}
                            onChange={onChange}
                            disabled={value.type !== 'RelativeMonthly'}
                        />
                    }
                    labelPosition="right"
                >
                    <Radio
                        checked={value.type === 'RelativeMonthly'}
                        onClick={
                            () =>
                                onChange(defaultRelativeMonthlyPattern)
                        }
                    />
                </Input>
            </ViewGroupItem>
        </ViewGroup>;
    };
