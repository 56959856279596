import React, { useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ExpressionEditorStore } from '../../../../../../../@Component/Domain/Expression/Editor/ExpressionEditorStore';
import { ExpressionSpecification } from '../../../../../../../@Component/Domain/Expression/Model/ExpressionSpecification';
import { ExpressionEditor as InternalExpressionEditor } from '../../../../../../../@Component/Domain/Expression/Editor/ExpressionEditor';
import { ExpressionContext } from '../../../../../../../@Component/Domain/Expression/ExpressionContext';
import ExpressionEditorContext from './ExpressionEditorContext';
import DocumentContext from '../../DocumentContext';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export interface ExpressionEditorProps
{
    expression?: ExpressionSpecification;
    context?: ExpressionContext,
    onChange?: (expression: ExpressionSpecification) => void;
    singleLine?: boolean;
    noRichText?: boolean;
}

const ExpressionEditor: React.FC<ExpressionEditorProps> =
    props =>
    {
        const [ initialExpression ] = useState(props.expression);
        const [ expression, setExpression ] = useState<ExpressionSpecification>(props.expression);
        const expressionContext = useContext(ExpressionEditorContext) || props.context;

        const documentContext = useContext(DocumentContext);
        const { onChange } = props;
        useEffect(
            () =>
            {
                if (onChange)
                {
                    onChange(expression);
                }
            },
            [
                expression,
                onChange
            ]);

        const expressionEditorStore =
            useMemo(
                () =>
                    new ExpressionEditorStore(
                        expressionContext,
                        initialExpression,
                        localizeText("StartTypingHere", 'Begin hier met typen'), // Name expression
                        [],
                        0,
                        undefined,
                        true,
                        setExpression,
                        undefined,
                        undefined,
                        !props.noRichText,
                        !!documentContext?.hideEntityFieldSelector,
                        props.singleLine),
                [
                    initialExpression,
                    expressionContext,
                    documentContext?.hideEntityFieldSelector, // not just document context, otherwise focus is lost on each keystroke
                    props.noRichText,
                    props.singleLine
                ]);

        return <InternalExpressionEditor
            store={expressionEditorStore}
        />;
    };

export default observer(ExpressionEditor);
