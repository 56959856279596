import React, { useMemo } from 'react';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import StaticSelectbox from '../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import { DayOfWeek } from '../../../Model/Util/DayOfWeek';

interface DayOfWeekEditorProps
{
    value: DayOfWeek;
    onChange: (value: DayOfWeek) => void;
    disabled?: boolean;
}

export const DayOfWeekEditor: React.FC<DayOfWeekEditorProps> =
    ({
        value,
        onChange,
        disabled,
     }) =>
    {
        const daysOfWeek =
            useMemo(
                () => [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday'
                ],
                []
            );

        return <StaticSelectbox
            options={
                daysOfWeek.map(
                    dayOfWeek => ({
                        id: dayOfWeek,
                        value: dayOfWeek,
                        label: localizeText(`Generic.${dayOfWeek}`, dayOfWeek)
                    })
                )
            }
            onChange={onChange}
            value={value}
            disabled={disabled}
        />;
    };
