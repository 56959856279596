import React, { useCallback, useState } from 'react';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { textSecondaryColor } from '../../../../../@Resource/Theme/Theme';
import Popper, { PopperProps } from '../../../../../@Future/Component/Generic/Popper/Popper';
import useCreatableAddPaths from './Api/useCreatableAddPaths';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { AddEntityPath } from '../../Path/@Model/AddEntityPath';
import { RelatedEntityConstructorModalStore } from '../../Constructor/Related/Modal/RelatedEntityConstructorModelStore';
import { RelatedEntityConstructorStore } from '../../Constructor/Related/RelatedEntityConstructorStore';
import { EntityConstructorModalStore } from '../../Constructor/Modal/EntityConstructorModalStore';
import { EntityConstructorStore } from '../../Constructor/EntityConstructorStore';
import styles from './EntityConstructorButton.module.scss';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import constructEntityFromPredicate from '../../../../../@Api/Entity/constructEntityFromPredicate';
import EntityConstructorPathSelector from './EntityConstructorPathSelector';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';

export interface EntityConstructorButtonProps
{
    rootEntity?: Entity;
    entityType?: EntityType;
    pathFromRootEntity?: EntityPath;
    ignoreSourceRelationshipDefinitionDuringAdding?: boolean;
    isFinalType?: boolean;
    onEntityAdded?: (entity: Entity, addPath: AddEntityPath) => Promise<any>;
    prepareFromEntityOnOpen?: () => Promise<Entity>;
    icon?: string;
    tooltip?: string;
    iconColor?: string;
    popperProps?: Partial<PopperProps>;
    filter?: Predicate;
    ignoreHidden?: boolean;
}

const EntityConstructorButton: React.FC<EntityConstructorButtonProps> =
    props =>
    {
        const [ isMenuOpen, setMenuOpen ] = useState(false);
        const { prepareFromEntityOnOpen } = props;

        const closeMenuCallback =
            useCallback(
                () =>
                    setMenuOpen(false),
                [
                    setMenuOpen
                ]);

        const showEntityConstructorModalCallback =
            useCallback(
                (path: AddEntityPath,
                 fromEntity?: Entity) =>
                {
                    closeMenuCallback();

                    const feedbackStore = loadModuleDirectly(FeedbackStore);
                    const entityType = path.relativePath.path.entityType;

                    if (path.relativePath.entity)
                    {
                        const closeDialog =
                            feedbackStore.openDialog({
                                store:
                                    new RelatedEntityConstructorModalStore(
                                        new RelatedEntityConstructorStore(
                                            path.relativePath.entity,
                                            path.relativePath.path,
                                            undefined,
                                            undefined,
                                            props.ignoreSourceRelationshipDefinitionDuringAdding,
                                            props.isFinalType),
                                        entity =>
                                        {
                                            if (props.onEntityAdded)
                                            {
                                                return props.onEntityAdded(entity, path);
                                            }
                                            else
                                            {
                                                return Promise.resolve();
                                            }
                                        },
                                        () =>
                                            closeDialog())
                            });
                    }
                    else
                    {
                        if (!fromEntity
                            && props.filter)
                        {
                            fromEntity =
                                constructEntityFromPredicate(
                                    entityType,
                                    props.filter
                                );
                        }

                        const closeDialog =
                            feedbackStore.openDialog({
                                store:
                                    new EntityConstructorModalStore({
                                        constructorStore:
                                            new EntityConstructorStore({
                                                entityType: entityType,
                                                isFinalType: props.isFinalType,
                                                entity: fromEntity
                                            }),
                                        onSave:
                                            (entity, startChecklist) =>
                                            {
                                                if (props.onEntityAdded)
                                                {
                                                    props.onEntityAdded(entity, path);
                                                }
                                            },
                                        onClose:
                                            () =>
                                                closeDialog()
                                }).modalDialogStore
                            });
                    }
                },
                [
                    closeMenuCallback,
                    props.ignoreSourceRelationshipDefinitionDuringAdding,
                    props.isFinalType,
                    props.onEntityAdded,
                    props.filter
                ]);

        const creatableAddPaths =
            useCreatableAddPaths(
                props.rootEntity,
                props.entityType,
                props.pathFromRootEntity);

        const openMenuCallback =
            useCallback(
                () =>
                {
                    if (prepareFromEntityOnOpen)
                    {
                        prepareFromEntityOnOpen()
                            .then(
                                entity =>
                                {
                                    if (creatableAddPaths.length === 1)
                                    {
                                        showEntityConstructorModalCallback(
                                            creatableAddPaths[0],
                                            entity);
                                    }
                                    else
                                    {
                                        setMenuOpen(true);
                                    }
                                });
                    }
                    else
                    {
                        if (creatableAddPaths.length === 0 && props.entityType)
                        {
                            showEntityConstructorModalCallback(
                                new AddEntityPath(
                                    new RelatedEntityPath(
                                        undefined,
                                        EntityPath.fromEntityType(props.entityType))));
                        }
                        if (creatableAddPaths.length === 1)
                        {
                            showEntityConstructorModalCallback(
                                creatableAddPaths[0]);
                        }
                        else
                        {
                            setMenuOpen(true);
                        }
                    }
                },
                [
                    creatableAddPaths,
                    showEntityConstructorModalCallback,
                    setMenuOpen,
                    prepareFromEntityOnOpen,
                    props.entityType
                ]);

        return <Popper
           reference={
               props.children
                   ?
                       <div
                           onClick={openMenuCallback}
                       >
                           {props.children}
                       </div>
                   :
                       <IconButton
                           icon={props.icon ? props.icon : 'add'}
                           tooltip={
                               props.tooltip
                               ?
                                    props.tooltip
                               :
                                   <LocalizedText
                                       code="Generic.Add"
                                   />
                           }
                           onClick={openMenuCallback}
                           color={props.iconColor || textSecondaryColor}
                       />
           }
           classes={{
               reference: props.children ? styles.referenceWithChildren : undefined
           }}
           popper={
               <Card>
                   <EntityConstructorPathSelector
                       {...props}
                       onSelect={showEntityConstructorModalCallback}
                   />
               </Card>
           }
           open={isMenuOpen}
           onClose={closeMenuCallback}
           {...props.popperProps}
        />;
    };

export default EntityConstructorButton;
