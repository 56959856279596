import { observable } from 'mobx';
import { Computation } from '../Computation';
import { DataObject } from '../../../DataObject/Model/DataObject';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import { EntityType } from '../../../DataObject/Type/Api/Entity/EntityType';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../DataObject/DataObjectStore';
import { MeType } from '../../../DataObject/Type/Me/MeType';
import EmptyValue from '../../../../../@Api/Automation/Value/EmptyValue';
import { ViewParams } from '../../../Entity/View/Model/ViewParams';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';

export class ConstantComputation extends Computation
{
    // ------------------------- Properties -------------------------

    @observable constant: DataObject;

    // ------------------------ Constructor -------------------------

    constructor(constant: DataObject)
    {
        super('Constant');

        this.constant = constant;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async initialize(): Promise<any>
    {
        return loadModuleDirectly(DataObjectStore).initializeDataObjects([ this.constant ]);
    }

    async toNewComputation(context: AutomationDependencyContext)
    {
        if (this.constant.isSemanticEmpty)
        {
            return EmptyValue.instance;
        }
        else if (this.constant.type instanceof EntityType)
        {
            await loadModuleDirectly(DataObjectStore).initializeDataObjects([ this.constant ]);

            if (this.constant.value instanceof Entity)
            {
                return new EntityValue(this.constant.value);
            }
            else
            {
                return EmptyValue.instance;
            }
        }
        else if (this.constant.type instanceof MeType)
        {
            return context.parameterDictionary.getParameterById(ViewParams.Me);
        }
        else
        {
            return new PrimitiveValue(this.constant);
        }
    }

    // ----------------------- Private logic ------------------------
}
