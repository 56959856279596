import { useMemo } from 'react';

export const undefinedValue = '___undefined___';

export default function useValue(value: any)
{
    // Material-UI tab bar component does not work with undefined values
    return useMemo(
        () =>
            value == null
                ?
                    false // undefinedValue
                :
                    value,
        [
            value
        ]);
}
