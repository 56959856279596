import React from 'react';
import { observer } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import HeaderWithScrollBodyView from './View/HeaderWithScrollBodyView';
import HeaderWithScrollBodyLayout from '../../../../../@Api/Layout/Type/HeaderWithScrollBodyLayout';

export interface HeaderWithScrollBodyLayoutViewerProps extends LayoutViewerProps<HeaderWithScrollBodyLayout>
{

}

const HeaderWithScrollBodyLayoutViewer: React.FC<HeaderWithScrollBodyLayoutViewerProps> =
    props =>
    {
        return <HeaderWithScrollBodyView
            header={
                <LayoutViewer
                    {...props}
                    layout={props.layout.header}
                />
            }
            body={
                <LayoutViewer
                    {...props}
                    layout={props.layout.body}
                />
            }
        />;
    };

export default observer(HeaderWithScrollBodyLayoutViewer);
