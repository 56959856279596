import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';
import useTypes from '../../Type/Api/useTypes';

export default function useAvatarEntity(entity: Entity)
{
    const types = useTypes();

    return useComputed(
        () =>
        {
            const getAvatarOfPersonRelationship =
                (entity: Entity) =>
                {
                    const person =
                        entity.getRelatedEntityByDefinition(
                            false,
                            types.Relationship.Person.RelationshipDefinition.Person);

                    return person || entity;
                };

            if (entity.entityType.isA(types.Relationship.Person.Type))
            {
                return getAvatarOfPersonRelationship(entity);
            }
            else if (entity.entityType.isA(types.Relationship.Organization.Type))
            {
                return entity.getRelatedEntityByDefinition(
                    false,
                    types.Relationship.Organization.RelationshipDefinition.Organization) || entity;
            }
            else if (entity.entityType.isA(types.Activity.Type))
            {
                const relationship =
                    entity.getRelatedEntityByDefinition(
                        true,
                        types.Relationship.RelationshipDefinition.Activities);

                if (relationship)
                {
                    if (relationship.entityType.isA(types.Relationship.Person.Type))
                    {
                        return getAvatarOfPersonRelationship(relationship);
                    }
                    else if (relationship.entityType.isA(types.Relationship.Organization.Type))
                    {
                        return relationship.getRelatedEntityByDefinition(
                            false,
                            types.Relationship.Organization.RelationshipDefinition.Organization) || relationship;
                    }
                    else
                    {
                        return relationship;
                    }
                }
                else
                {
                    return entity;
                }
            }
            else
            {
                return entity;
            }
        },
        [
            entity,
            types
        ]);
}
