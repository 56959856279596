import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import RangeCollectionComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/RangeCollectionComputation';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import InputGroup from '../../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import styles from './RangeCollectionComputationEditor.module.scss';

export interface RangeCollectionComputationEditorProps extends ComputationEditorProps<ValueType<any>, RangeCollectionComputation>
{

}

const RangeCollectionComputationEditor: React.FC<RangeCollectionComputationEditorProps> =
    props =>
    {
        const { context, value } = props;

        const setFrom =
            useCallback(
                (from?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            value.from = from),
                [
                    value
                ]);

        const setTo =
            useCallback(
                (to?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            value.to = to),
                [
                    value
                ]);

        const setInterval =
            useCallback(
                (interval?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            value.interval = interval),
                [
                    value
                ]);

        return <div
            className={styles.root}
        >
            <InputGroup>
                <Input
                    label={<LocalizedText code="Generic.From" value="Vanaf"/>}
                    labelPosition="left"
                >
                    <ComputationEditor
                        context={context}
                        value={value.from}
                        onChange={setFrom}
                    />
                </Input>
                <Input
                    label={<LocalizedText code="Generic.To" value="Tot"/>}
                    labelPosition="left"
                >
                    <ComputationEditor
                        context={context}
                        value={value.to}
                        onChange={setTo}
                    />
                </Input>
                <Input
                    label={<LocalizedText code="Generic.Interval" value="Interval"/>}
                    labelPosition="left"
                >
                    <ComputationEditor
                        context={context}
                        value={value.interval}
                        onChange={setInterval}
                    />
                </Input>
            </InputGroup>
        </div>;
    };

export default observer(RangeCollectionComputationEditor);
