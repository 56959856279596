import * as React from 'react';
import { observer } from 'mobx-react';
import { Reference } from 'react-popper';

export interface PopperReferenceProps
{
    isInline?: boolean;
}

@observer
export class PopperReference extends React.Component<PopperReferenceProps>
{
    render()
    {
        const children = this.props.children;
        return <Reference>
            {({ ref }) => (
                <div
                    ref={ref}
                    style={{
                        display: this.props.isInline ? 'inline-block' : 'flex'
                    }}
                >
                    {children}
                </div>
            )}
        </Reference>;
    }
}
