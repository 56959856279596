import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Selectbox from '../../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../../../../Entity/Selection/Builder/EntitySelectionBuilder';
import useTypes from '../../../../../Entity/Type/Api/useTypes';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import ResourcePlanner from '../../../../../Entity/ResourcePlanner/Model/ResourcePlanner';
import { createTransactionalModel } from '../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';

export interface ResourcePlannerSelectorProps
{
    entityType: EntityType;
    value?: Entity;
    onChange: (value: Entity) => void;
}

const ResourcePlannerSelector: React.FC<ResourcePlannerSelectorProps> =
    props =>
    {
        const types = useTypes();
        const viewLoader =
            useCallback(
                () =>
                    new EntitySelectionBuilder(types.ResourcePlanner.Type)
                        .where(
                            cb =>
                                cb.or(
                                    ob =>
                                        props.entityType.getInheritedTypes()
                                            .forEach(
                                                type =>
                                                    ob.relatedToEntity(
                                                        types.ResourcePlanner.Type.path()
                                                            .joinTo(
                                                                types.EntityType.RelationshipDefinition.ResourcePlanners,
                                                                true),
                                                        type.entity))))
                        .select()
                        .then(
                            results =>
                            {
                                const newEntity =
                                    createTransactionalModel(
                                        new Entity(types.ResourcePlanner.Type)
                                            .initialize());

                                const newResourcePlanner =
                                    new ResourcePlanner(
                                        ResourcePlanner.getResourceParameter(props.entityType),
                                        new ParameterDictionary([]),
                                        undefined,
                                        []);

                                newEntity.updateRelationship(
                                    true,
                                    types.EntityType.RelationshipDefinition.ResourcePlanners,
                                    createTransactionalModel(props.entityType.entity));

                                newEntity.setValueByField(
                                    types.ResourcePlanner.Field.Name,
                                    `+ ${types.ResourcePlanner.Type.getName()}`);

                                newEntity.setValueByField(
                                    types.ResourcePlanner.Field.Specification,
                                    newResourcePlanner.toDescriptor());

                                return Promise.all([
                                    newEntity,
                                    ...results.map(result => result.entity)
                                ]);
                            }),
                [
                    types,
                    props.entityType
                ]);

        const formatter =
            useCallback(
                (entity: Entity) =>
                    entity.name,
                []);

        const idResolver =
            useCallback(
                (entity: Entity) =>
                    entity.uuid,
                []);

        return <Selectbox
            load={viewLoader}
            onChange={props.onChange}
            formatOption={formatter}
            idResolver={idResolver}
            value={props.value}
        />;
    };

export default observer(ResourcePlannerSelector);
