import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { EntitySelectionBuilder } from '../Builder/EntitySelectionBuilder';
import { DependencyList } from 'react';
import { useComputed } from 'mobx-react-lite';
import { Aggregate } from '../../../DataObject/Model/Aggregate';
import useCombinedAggregateResults from './useCombinedAggregateResults';
import getTypes from '../../Type/Api/getTypes';

export default function useCombinedCounts(types: EntityType[],
                                          callback: (idx: number, builder: EntitySelectionBuilder, rootPath: EntityPath) => void,
                                          deps: DependencyList): (number | undefined)[] | undefined
{
    const results =
        useCombinedAggregateResults(
            types,
            (idx, builder, rootPath) =>
            {
                callback(idx, builder, rootPath);

                builder.aggregateOn(
                    rootPath.field(getTypes().Entity.Field.Id),
                    undefined,
                    Aggregate.Count);
            },
            deps);

    return useComputed(
        () =>
            results ? results.map(result => result.aggregates[0].value) : undefined,
        [
            results
        ]);
}
