import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Divider from '../../../../../../../@Future/Component/Generic/Divider/Divider';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import useTypes from '../../../../Type/Api/useTypes';
import { EntityRelationshipDefinition } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntityRelationship } from '../../../../../../../@Api/Model/Implementation/EntityRelationship';
import equalsEntity from '../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import { default as EntityInput } from '../../../../Input/Input';
import { observer, useComputed } from 'mobx-react-lite';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import EntityEmailViewer from '../../../../Email/EntityEmailViewer';
import ContentEditor from './ContentEditor/ContentEditor';
import HtmlEditor from './HtmlEditor/HtmlEditor';
import { DocumentDefinition, GutenbergBlockExpressionId } from '../../../../../../../@Future/Component/Generic/Input/DocumentEditor/DocumentEditor';
import styles from '../../../../Email/EntityEmailDialog.module.scss';
import IconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import initializeInitialPhase from '../../../../../../../@Api/Entity/Bespoke/Datastore/Phase/initializeInitialPhase';
import EmailTemplateSelectorButton from '../../../../Email/Template/EmailTemplateSelectorButton';
import CurrentUserContext from '../../../../../User/CurrentUserContext';
import Switch from '../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import Input, { default as GenericInput } from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { StringUtils } from '../../../../../../../@Util/String/StringUtils';
import Selectbox from '../../../../Selectbox/Selectbox';
import { SelectionValue } from '../../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import { ContentProps } from '../../Content';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import CancelButton from '../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import { FileValue } from '../../../../../DataObject/Type/File/FileValue';
import { runInAction } from 'mobx';
import useRelatedEntities from '../../../../../../../@Api/Entity/Hooks/useRelatedEntities';
import useToggle from '../../../../../../../@Util/Toggle/useToggle';
import { primaryColor, textSecondaryColor } from '../../../../../../../@Resource/Theme/Theme';
import AttachmentList from '../../../../AttachmentList/AttachmentList';
import InputGroup from '../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import updateSubjectFromTemplate from '../../../../Type/Bespoke/Activity/Email/updateSubjectFromTemplate';
import StaticSelectbox from '../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import useResults from '../../../../Selection/Hooks/useResults';
import Link from '../../../../../../../@Future/Component/Generic/Link/Link';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import uuid from '../../../../../../../@Util/Id/uuid';
import useRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import { ExpressionContext } from '../../../../../Expression/ExpressionContext';
import { copyElement } from '../../../../../../../@Util/Copy/copyElement';
import { updateRelationship } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { commitEntityWithContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import { constructEntityOfType } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { EntityExpansionBuilder } from '../../../../Selection/Builder/EntityExpansionBuilder';
import { deleteEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/deleteEntity';
import usePrevious from '../../../../../../../@Util/Previous/usePrevious';
import toHumanReadableFilesize from '../../../../../../../@Util/File/toHumanReadableFilesize';
import useIsSendingFromDomainAllowed from '../../../../../Configuration/Page/DomainValidation/Hooks/useIsSendingFromDomainAllowed';
import Computation from '../../../../../../../@Api/Automation/Function/Computation/Computation';
import FunctionContext from '../../../../../../../@Api/Automation/Function/FunctionContext';
import { getEmailSubjectComputationFromDescriptor } from '../../../../Type/Bespoke/Activity/Email/getEmailSubjectComputationFromDescriptor';
import ComputationEditor from '../../../../Viewer/Content/Automation/Editor/Computation/ComputationEditor';
import FileDropZoneWithLibrary from '../../../../../../../@Future/Component/Generic/Input/FileDropZoneWithLibrary/FileDropZoneWithLibrary';
import getDefaultTemplate from '../../../../Item/Navigator/More/ProductLinesEditor/Api/getDefaultTemplate';
import SaveButton from '../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import { isEntityDirty } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/isEntityDirty';
import { Chip } from '@material-ui/core';
import { consoleLog } from '../../../../../../../@Future/Util/Logging/consoleLog';
import { useExpansion } from '../../../../Selection/Api/useExpansion';
import { useDisposableAsyncResult } from '../../../../../../../@Util/Async/useDisposableAsyncResult';
import { sendEmail } from './Api/sendEmail';
import { EntityEmailAddress } from '../../../../../../../@Api/Entity/Bespoke/EmailAddress/EntityEmailAddress';
import { getEmailAddressesByEntity } from '../../../../../../../@Api/Entity/Bespoke/EmailAddress/getEmailAddressesByEntity';
import LabelButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import useDialog from '../../../../../../../@Service/Navigation/Page/Hooks/useDialog';
import { EmailPreview } from './EmailPreview';

export interface ActivityEmailProps extends ContentProps
{
    sendHandler?: (email: Entity) => void;
    onSent?: (email: Entity) => void;
    buttons?: ReactNode[];
    template?: Entity;
    templateType?: EntityType;
    subjectTemplate?: Computation<any, any>;
    templateContext?: FunctionContext;
    expressionContext?: ExpressionContext;
    onChangeSubjectTemplate?: (template: Computation<any, any>) => void;
    disableDraftSave?: boolean;
    disableSend?: boolean;
    message?: string;
    hideToField?: boolean;
}

export const ActivityEmail: React.FC<ActivityEmailProps> = observer(
    props =>
    {
        const { sendHandler, onSent, onChangeSubjectTemplate, message } = props;

        const types = useTypes();
        const entity = props.entity;
        const currentUserStore = useContext(CurrentUserContext);
        const [ editLayoutMode, setEditLayoutMode ] = useState(false);
        const [ lastReloadContentRef, setReloadContentRef ] = useState<any>();
        const MaxAttachmentsSize = 15 * 1024 * 1024;

        useExpansion(
            entity.entityType,
            rootPath =>
                [
                    types.Activity.Email.RelationshipDefinition.From,
                    types.Activity.Email.RelationshipDefinition.To,
                    types.Activity.Email.RelationshipDefinition.CC,
                    types.Activity.Email.RelationshipDefinition.BCC,
                ].map(
                    recipientRelationshipDefinition =>
                        rootPath
                            .joinTo(
                                recipientRelationshipDefinition,
                                false
                            )
                            .joinTo(
                                types.Recipient.RelationshipDefinition.Addressee,
                                false
                            ),
                ),
            () => [
                entity
            ],
            [
                types,
                entity,
            ]
        );
        const fromRecipient =
            useRelatedEntity(
                entity,
                types.Activity.Email.RelationshipDefinition.From,
                false,
                props.commitContext
            );

        const fromAddressee =
            useRelatedEntity(
                fromRecipient,
                types.Recipient.RelationshipDefinition.Addressee,
                false,
                props.commitContext
            );

        const emailSenders =
            useResults(
                types.EmailSender.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.eq(
                                    rootPath.field(
                                        types.EmailSender.Field.IsInactive
                                    ),
                                    undefined,
                                    false
                                )
                        ),
                [
                    types
                ]
            );

        const fromMe =
            useMemo(
                () =>
                    types.Recipient.Email.Functions.RecipientFromEntity(
                        currentUserStore.employeeEntity,
                        undefined,
                        props.commitContext
                    ),
                [
                    types,
                    currentUserStore,
                    props.commitContext,
                ]);

        const fromOptions =
            useMemo(
                () =>
                {
                    const getOptionFromRecipient =
                        (addressee: Entity) => ({
                            id: addressee.uuid,
                            label: `${addressee.getRelatedEntityByDefinition(false, types.Recipient.RelationshipDefinition.Addressee, props.commitContext)?.name} <${addressee.getObjectValueByField(types.Recipient.Email.Field.EmailAddress, props.commitContext)}>`,
                            value: addressee,
                        });

                    const options = [
                        fromMe,
                        ...(emailSenders || [])
                            .map(
                                addressee =>
                                    types.Recipient.Email.Functions.RecipientFromEntity(
                                        addressee,
                                        undefined,
                                        props.commitContext
                                    )
                            ),
                    ]
                        .filter(
                            addressee =>
                                addressee !== undefined
                        )
                        .map(
                            addressee =>
                                getOptionFromRecipient(addressee)
                        );

                    if (fromRecipient !== undefined
                        && options.findIndex(option => option.value === fromRecipient) < 0)
                    {
                        return [
                            ...options,
                            getOptionFromRecipient(fromRecipient)
                        ];
                    }
                    else
                    {
                        return options;
                    }
                },
                [
                    fromMe,
                    emailSenders,
                    types,
                    fromRecipient,
                    props.commitContext,
                ]);

        useEffect(
            () =>
            {
                if (!entity.isNew())
                {
                    return;
                }

                // Set the from to the current employee by default
                if (
                    !entity.hasRelationshipsByDefinition(
                        false,
                        types.Activity.Email.RelationshipDefinition.From,
                        props.commitContext)
                )
                {
                    updateRelationship(
                        entity,
                        false,
                        types.Activity.Email.RelationshipDefinition.From,
                        fromMe,
                        props.commitContext
                    );
                }

                // Set the to to the contact or relationship of the e-mail
                // if the email is not forwarded
                if (!entity.hasRelationshipsByDefinition(
                    false,
                    types.Activity.Email.RelationshipDefinition.To,
                    props.commitContext
                    )
                    && !entity.hasRelationshipsByDefinition(
                        true,
                        types.Activity.Email.RelationshipDefinition.Forwards,
                        props.commitContext
                    )
                )
                {
                    const addressee =
                        entity.getRelatedEntityByDefinition(
                            true,
                            types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                            props.commitContext
                        )
                        ||
                        entity.getRelatedEntityByDefinition(
                            true,
                            types.Relationship.RelationshipDefinition.Activities,
                            props.commitContext
                        );

                    if (addressee)
                    {
                        const recipient =
                            types.Recipient.Email.Functions.RecipientFromEntity(
                                addressee,
                                undefined,
                                props.commitContext
                            );

                        if (recipient)
                        {
                            updateRelationship(
                                entity,
                                false,
                                types.Activity.Email.RelationshipDefinition.To,
                                recipient,
                                props.commitContext
                            );
                        }
                    }
                }
            },
            [
                entity,
                types,
                props.commitContext,
                fromMe,
            ]);

        const [ isExpandRecipientField, setIsExpandRecipientField ] = useState(false);
        const toggleExpandRecipientField =
            useCallback(
                () =>
                    setIsExpandRecipientField(!isExpandRecipientField),
                [
                    setIsExpandRecipientField,
                    isExpandRecipientField
                ]);

        const recipientSelections =
            useComputed(
                () => [
                    new EntitySelectionBuilder(types.Relationship.Type)
                        .join(
                            EntityPath.fromEntityType(types.Relationship.Type)
                                .castTo(types.Relationship.Person.Type)
                                .joinTo(types.Relationship.Person.RelationshipDefinition.Person, false))
                        .join(
                            EntityPath.fromEntityType(types.Relationship.Type)
                                .castTo(types.Relationship.Organization.Type)
                                .joinTo(types.Relationship.Organization.RelationshipDefinition.Organization, false))
                        .selection
                ],
                [
                    types
                ]);

        const recipientSearchFieldPaths =
            useMemo(
                () => [
                    EntityPath.fromEntityType(types.Relationship.Type)
                        .castTo(types.Relationship.Person.Contact.Type)
                        .field(types.Relationship.Person.Contact.Field.EmailAddress),
                    EntityPath.fromEntityType(types.Relationship.Type)
                        .castTo(types.Relationship.Organization.Type)
                        .joinTo(
                            types.Relationship.Organization.RelationshipDefinition.Organization,
                            false)
                        .field(types.Relation.Organization.Field.EmailAddress),
                    EntityPath.fromEntityType(types.Relationship.Type)
                        .castTo(types.Relationship.Person.Type)
                        .joinTo(
                            types.Relationship.Person.RelationshipDefinition.Person,
                            false)
                        .field(types.Relation.Person.Field.EmailAddress)
                ],
                [
                    types
                ]);

        const getRecipients =
            useCallback(
                (relationshipDefinition: EntityRelationshipDefinition) =>
                    entity
                        .getRelatedEntitiesByDefinition(
                            false,
                            relationshipDefinition,
                            props.commitContext
                        )
                        .map(
                            recipientEntity =>
                                recipientEntity.getRelatedEntityByDefinition(
                                    false,
                                    types.Recipient.RelationshipDefinition.Addressee))
                        .filter(
                            addressee =>
                                addressee !== undefined),
                [
                    entity,
                    types,
                    props.commitContext,
                ]);

        const toRecipients =
            useRelatedEntities(
                entity,
                types.Activity.Email.RelationshipDefinition.To,
                false,
                props.commitContext
            );
        const to =
            useComputed(
                () =>
                    getRecipients(types.Activity.Email.RelationshipDefinition.To),
                [
                    entity,
                    types,
                    getRecipients
                ]);
        const ccRecipients =
            useRelatedEntities(
                entity,
                types.Activity.Email.RelationshipDefinition.CC,
                false,
                props.commitContext
            );
        const cc =
            useComputed(
                () =>
                    getRecipients(types.Activity.Email.RelationshipDefinition.CC),
                [
                    entity,
                    types,
                    getRecipients
                ]);

        const bccRecipients =
            useRelatedEntities(
                entity,
                types.Activity.Email.RelationshipDefinition.BCC,
                false,
                props.commitContext
            );
        const bcc =
            useComputed(
                () =>
                    getRecipients(types.Activity.Email.RelationshipDefinition.BCC),
                [
                    entity,
                    types,
                    getRecipients
                ]);

        const content =
            useEntityValue(
                entity,
                types.Activity.Email.Field.Content,
                undefined,
                props.commitContext
            );

        const html =
            useEntityValue(
                entity,
                types.Activity.Email.Field.HTML,
                undefined,
                props.commitContext
            );

        const phase =
            useRelatedEntity(
                entity,
                types.Activity.Email.RelationshipDefinition.Phase,
                false,
                props.commitContext
            );

        const isDraft =
            useComputed<boolean>(
                () =>
                        !phase ||
                        phase.getObjectValueByField(types.Datastore.Field.Code, props.commitContext) === 'Concept',
                [
                    phase,
                    types,
                    props.commitContext,
                    props.disableDraftSave
                ]);

        const isHtmlEditable =
            useMemo(
                () =>
                    isDraft
                    && entity.hasValueForField(types.Activity.Email.Field.HTML, props.commitContext)
                    && !entity.hasValueForField(types.Activity.Email.Field.Content, props.commitContext),
                [
                    isDraft,
                    entity,
                    types,
                    props
                ]
            );

        const senderAddress =
            useMemo( () =>
                {
                    return fromRecipient?.getDataObjectValueByField(types.Recipient.Email.Field.EmailAddress);
                },
                [
                    fromRecipient,
                    types
                ]
            );
        const [ sendingAllowed, isLoadingSendingAllowed ] = useIsSendingFromDomainAllowed(senderAddress);

        const handleRelationshipChange =
            useCallback(
                (relationshipDefinition: EntityRelationshipDefinition,
                 selectedEntities: Entity[]) =>
                {
                    const relationshipsToRemove: EntityRelationship[] = [];
                    const currentAddressees: Entity[] = [];

                    entity
                        .getRelationshipsByDefinition(
                            false,
                            relationshipDefinition,
                            props.commitContext
                        )
                        .forEach(
                            recipientRelationship =>
                                recipientRelationship.childEntity
                                    .getRelationshipsByDefinition(
                                        false,
                                        types.Recipient.RelationshipDefinition.Addressee,
                                        props.commitContext
                                    )
                                    .forEach(
                                        addresseeRelationship =>
                                        {
                                            currentAddressees.push(addresseeRelationship.childEntity);

                                            if (!selectedEntities.includes(addresseeRelationship.childEntity))
                                            {
                                                relationshipsToRemove.push(recipientRelationship);
                                            }
                                        }
                                    )
                        );

                    // Add new addressees
                    selectedEntities.forEach(
                        selectedEntity =>
                        {
                            if (!currentAddressees.includes(selectedEntity))
                            {
                                // Create recipient entity
                                const recipientEntity =
                                    types.Recipient.Email.Functions.RecipientFromEntity(
                                        selectedEntity,
                                        undefined,
                                        props.commitContext
                                    );

                                if (recipientEntity)
                                {
                                    updateRelationship(
                                        entity,
                                        false,
                                        relationshipDefinition,
                                        recipientEntity,
                                        props.commitContext
                                    );
                                }
                            }
                        }
                    );

                    // Delete all recipients that are no longer selected
                    relationshipsToRemove.forEach(
                        relationship =>
                            updateRelationship(
                                entity,
                                false,
                                relationshipDefinition,
                                undefined,
                                props.commitContext,
                                relationship.childEntity
                            )
                    );
                },
                [
                    entity,
                    types,
                    props.commitContext,
                ]);

        const labelFormatter =
            useCallback(
                (selectedRecipients: Entity[],
                 relationshipDefinition: EntityRelationshipDefinition,
                 entityToCaption: Entity) =>
                {
                    const selectedRecipient =
                        selectedRecipients.find(
                            recipient =>
                                equalsEntity(
                                    recipient.getRelatedEntityByDefinition(
                                        false,
                                        types.Recipient.RelationshipDefinition.Addressee,
                                        props.commitContext
                                    ),
                                    entityToCaption
                                )
                        );

                    let emailAddresses: EntityEmailAddress[];

                    if (selectedRecipient)
                    {
                        emailAddresses =
                            [
                                {
                                    field: types.Recipient.Email.Field.EmailAddress,
                                    emailAddress:
                                        selectedRecipient.getObjectValueByField(
                                            types.Recipient.Email.Field.EmailAddress,
                                            props.commitContext
                                        )
                                }
                            ];
                    }
                    else
                    {
                        emailAddresses =
                            getEmailAddressesByEntity(
                                entityToCaption,
                                props.commitContext
                            )
                            .sort(
                                types.Recipient.Email.Functions.SortByPreferedField
                            )
                    }

                    if (emailAddresses.length > 0)
                    {
                        if (selectedRecipient)
                        {
                            return `<${emailAddresses[0].emailAddress}>`;
                        }
                        else
                        {
                            return <>
                                {`<`}
                                {
                                    emailAddresses.map(
                                        (email, idx) =>
                                            <React.Fragment
                                                key={`${email.field.id}.${email.emailAddress}`}
                                            >
                                                {idx > 0 ? ', ' : ''}
                                                <Link
                                                    highlighted
                                                    onClick={
                                                        event =>
                                                        {
                                                            event.stopPropagation();

                                                            const newAddressee =
                                                                types.Recipient.Email.Functions.RecipientFromEntity(
                                                                    entityToCaption,
                                                                    email.emailAddress,
                                                                    props.commitContext
                                                                );

                                                            updateRelationship(
                                                                entity,
                                                                false,
                                                                relationshipDefinition,
                                                                newAddressee,
                                                                props.commitContext
                                                            );
                                                        }
                                                    }
                                                    tooltip={email.field.getName()}
                                                >
                                                    {email.emailAddress}
                                                </Link>
                                            </React.Fragment>)
                                }
                                {`>`}
                            </>;
                        }
                    }
                    else
                    {
                        return localizeText('Email.NoEmailAddressFound', '<géén e-mail adres gevonden>');
                    }
                },
                [
                    types,
                    entity,
                    props.commitContext,
                ]);

        const toLabelFormatter =
            useCallback(
                (entity: Entity) =>
                    labelFormatter(toRecipients, types.Activity.Email.RelationshipDefinition.To, entity),
                [
                    labelFormatter,
                    toRecipients,
                    types
                ]);

        const ccLabelFormatter =
            useCallback(
                (entity: Entity) =>
                    labelFormatter(ccRecipients, types.Activity.Email.RelationshipDefinition.CC, entity),
                [
                    labelFormatter,
                    ccRecipients,
                    types
                ]);

        const bccLabelFormatter =
            useCallback(
                (entity: Entity) =>
                    labelFormatter(bccRecipients, types.Activity.Email.RelationshipDefinition.BCC, entity),
                [
                    labelFormatter,
                    bccRecipients,
                    types
                ]);

        const setEmailBody =
            useCallback(
                (definition: DocumentDefinition) =>
                {
                    setValueByFieldInEntity(
                        entity,
                        types.Activity.Email.Field.Content,
                        definition,
                        props.commitContext
                    );

                    setValueByFieldInEntity(
                        entity,
                        types.Activity.Email.Field.HTML,
                        undefined,
                        props.commitContext
                    );
                },
                [
                    types,
                    entity,
                    props.commitContext,
                ]);

        const setHtml =
            useCallback(
                (newHtml: string) =>
                {
                    setValueByFieldInEntity(
                        entity,
                        types.Activity.Email.Field.HTML,
                        newHtml,
                        props.commitContext
                    );
                },
                [
                    types,
                    entity,
                    props.commitContext,
                ]);

        const { onSave, onClose } = props;
        const onSendCallback =
            useCallback(
                () =>
                {
                    if (onSent)
                    {
                        onSent(entity)
                    }

                    if (onSave)
                    {
                        onSave(entity);
                    }

                    if (onClose)
                    {
                        onClose();
                    }
                },
                [
                    entity,
                    onSent,
                    onSave,
                    onClose
                ]);
        const onSaveCallback =
            useCallback(
                () =>
                {
                    if (onSave)
                    {
                        onSave(entity);
                    }
                },
                [
                    entity,
                    onSave
                ]);

        const saveHtmlToContent =
            useCallback(
                () =>
                {
                    if (isHtmlEditable)
                    {
                        setValueByFieldInEntity(
                            entity,
                            types.Activity.Email.Field.Content,
                            {
                                type: 'gutenberg',
                                definition: [
                                    {
                                        clientId: uuid(),
                                        name: GutenbergBlockExpressionId,
                                        isValid: true,
                                        attributes: {
                                            content: {
                                                expression: entity.getObjectValueByField(types.Activity.Email.Field.HTML, props.commitContext),
                                                computations: []
                                            }
                                        },
                                        innerBlocks: []
                                    }
                                ]
                            },
                            props.commitContext
                        );

                        setValueByFieldInEntity(
                            entity,
                            types.Activity.Email.Field.HTML,
                            undefined,
                            props.commitContext
                        );
                    }
                },
                [
                    isHtmlEditable,
                    entity,
                    types,
                    props.commitContext
                ]
            );


        const doSend =
            useCallback(
                () =>
                {
                    if (!entity.isValid)
                    {
                        consoleLog('cannot send email, entity is invalid', entity);

                        return Promise.resolve();
                    }
                    else
                    {
                        if (sendHandler)
                        {
                            return Promise.resolve()
                                .then(
                                    () =>
                                        sendHandler(entity))
                                .then(
                                    () =>
                                    {
                                        onSendCallback();
                                    });
                        }
                        else
                        {
                            if (isHtmlEditable)
                            {
                                saveHtmlToContent();
                            }

                            return commitEntityWithContext(
                                entity,
                                props.commitContext,
                                {
                                    isForced: true
                                })
                                .then(
                                    emailEntity =>
                                        sendEmail(emailEntity)
                                            .then(
                                                () =>
                                                {
                                                    onSendCallback();
                                                }
                                            )
                                );
                        }
                    }
                },
                [
                    entity,
                    sendHandler,
                    onSendCallback,
                    props.commitContext,
                    saveHtmlToContent,
                    isHtmlEditable
                ]);

        const doSave =
            useCallback(
                () =>
                {
                    if (entity.isValid)
                    {
                        return commitEntityWithContext(
                            entity,
                            props.commitContext,
                            {
                                isForced: true
                            }
                        ).then(
                            () =>
                            {
                                onSaveCallback();
                            }
                        );
                    }
                },
                [
                    entity,
                    props.commitContext,
                    onSaveCallback
                ]);

        const [ showPreview ] =
            useDialog(
                close =>
                    <EmailPreview
                        emailEntity={entity}
                        onClose={close}
                        commitContext={props.commitContext}
                    />,
                [
                    entity,
                    props.commitContext
                ]);

        const parseVariables =
            useCallback(
                (definition: any) =>
                {
                    if (definition)
                    {
                        let strDefinition = JSON.stringify(definition);

                        // Return false if nothing is going to change, to be able to prevent
                        // useless reloads on the document builder.
                        if ((fromAddressee && strDefinition.indexOf('{{Sender.Name}}') >= 0)
                            || (to.length > 0 && strDefinition.indexOf('{{Receiver.Name}}') >= 0))
                        {
                            // Parse variables
                            if (fromAddressee)
                            {
                                strDefinition = strDefinition.replace('{{Sender.Name}}', StringUtils.jsonEscape(fromAddressee.name));
                            }

                            if (to.length > 0)
                            {
                                strDefinition = strDefinition.replace('{{Receiver.Name}}', StringUtils.jsonEscape(to[0].name));
                            }

                            // Copy the definition
                            return JSON.parse(strDefinition);
                        }
                    }

                    return false;
                },
                [
                    fromAddressee,
                    to
                ]);

        const [ selectedTemplate, setSelectedTemplate] = useState<Entity | undefined>(undefined);
        const previousTemplate = usePrevious(selectedTemplate);

        const [ existingMailAttachment, setExistingMailAttachment ] = useState<Entity[] | undefined>(undefined);

        useEffect(
            () =>
                {
                    setExistingMailAttachment(props.entity.getRelatedEntitiesByDefinition(
                        false,
                        types.Entity.RelationshipDefinition.Attachments)
                    )
                },
            [
                types,
                props.entity
            ]
        )

        const selectEmailTemplate =
            useCallback(
                async (template: Entity) =>
                {
                    const newContent =
                        copyElement(
                            template.getObjectValueByField(
                                types.Template.Field.Content,
                                props.commitContext
                            )
                        );

                    const content =
                        entity.getObjectValueByField(
                            types.Activity.Email.Field.Content,
                            props.commitContext
                        );

                    if (content?.definition !== undefined)
                    {
                        const replyMail = content.definition.find(d => d.isReplyMail);
                        if (replyMail !== undefined)
                        {
                            newContent.definition.push({
                                clientId: uuid(),
                                name: 'core/spacer',
                                isValid: true,
                                attributes: {
                                    height: 10
                                },
                                innerBlocks: []
                            });

                            newContent.definition.push(replyMail);
                        }
                    }

                    const defaultSender =
                        template.getRelatedEntityByDefinition(
                            false,
                            types.Template.Email.RelationshipDefinition.DefaultEmailSender,
                            props.commitContext
                        );

                    updateRelationship(
                        entity,
                        true,
                        types.Template.Email.RelationshipDefinition.Emails,
                        template,
                        props.commitContext
                    );

                    if (defaultSender && defaultSender.hasValueForField(types.EmailSender.Field.EmailAddress))
                    {
                        updateRelationship(
                            entity,
                            false,
                            types.Activity.Email.RelationshipDefinition.From,
                            types.Recipient.Email.Functions.RecipientFromEntity(
                                defaultSender,
                                defaultSender.getObjectValueByField(types.EmailSender.Field.EmailAddress),
                                props.commitContext
                            ),
                            props.commitContext
                        );
                    }

                    if (template.hasValueForField(types.Template.Email.Field.SubjectTemplate, props.commitContext)
                        && onChangeSubjectTemplate)
                    {
                        onChangeSubjectTemplate(
                            await getEmailSubjectComputationFromDescriptor(
                                template.getObjectValueByField(
                                    types.Template.Email.Field.SubjectTemplate,
                                    props.commitContext
                                ),
                                props.templateContext
                            )
                        );
                    }
                    else
                    {
                        await updateSubjectFromTemplate(
                            entity,
                            template,
                            undefined,
                            props.commitContext
                        );
                    }

                    setSelectedTemplate(template);

                    // Add attachments
                    const attachmentExpansionBuilder =
                        new EntityExpansionBuilder(
                            template.entityType,
                            [
                                template
                            ],
                            [
                                EntityPath.fromEntity(template)
                                    .joinTo(
                                        types.Entity.RelationshipDefinition.Attachments,
                                        false
                                    )
                            ]
                        );
                    await attachmentExpansionBuilder.expand();

                    // Remove existing attachment when switching templates
                    if (previousTemplate !== undefined)
                    {
                        entity.getRelatedEntitiesByDefinition(
                            false,
                            types.Entity.RelationshipDefinition.Attachments
                        ).forEach(
                            existingAttachment =>
                            {
                                if (!existingMailAttachment.includes(existingAttachment))
                                {
                                    deleteEntity(existingAttachment, props.commitContext);
                                }
                            }
                        );
                    }

                    template.getRelatedEntitiesByDefinition(
                        false,
                        types.Entity.RelationshipDefinition.Attachments,
                    ).forEach(
                        templateAttachment =>
                        {
                            const emailAttachment =
                                constructEntityOfType(
                                    types.Attachment.Type,
                                    props.commitContext
                                );

                            setValueByFieldInEntity(
                                emailAttachment,
                                types.Attachment.Field.File,
                                templateAttachment.getObjectValueByField(types.Attachment.Field.File),
                                props.commitContext
                            );

                            updateRelationship(
                                emailAttachment,
                                true,
                                types.Entity.RelationshipDefinition.Attachments,
                                entity,
                                props.commitContext
                            );
                        });
                    attachmentExpansionBuilder.dispose();

                    setEmailBody(newContent);
                    setReloadContentRef(new Date().getTime());

                    return Promise.resolve();
                },
                [
                    types,
                    setEmailBody,
                    entity,
                    onChangeSubjectTemplate,
                    props.commitContext,
                    previousTemplate,
                    props.templateContext,
                    existingMailAttachment
                ]);

        useDisposableAsyncResult(
            async () =>
            {
                if (entity.isNew()
                    && !entity.hasRelationshipsByDefinition(
                        true,
                        types.Template.Email.RelationshipDefinition.Emails,
                        props.commitContext
                    )
                )
                {
                    if (props.template)
                    {
                        await selectEmailTemplate(props.template);
                    }
                    else
                    {
                        const {
                            value: defaultTemplate,
                            dispose: disposeDefaultTemplate
                        } =
                            await getDefaultTemplate(
                                props.templateType ?? types.Template.Email.General.Type,
                                false
                            );

                        if (defaultTemplate)
                        {
                            await selectEmailTemplate(defaultTemplate);
                        }

                        return {
                            value: undefined,
                            disposer: disposeDefaultTemplate,
                        };
                    }
                }

                return {
                    value: undefined,
                    disposer: () => {},
                };
            },
            [
                entity,
                props.template,
                selectEmailTemplate,
                props.commitContext,
                types,
                props.templateType
            ]
        );

        const toggleEditLayoutMode =
            useCallback(
                () =>
                    setEditLayoutMode(!editLayoutMode),
                [
                    setEditLayoutMode,
                    editLayoutMode
                ]);

        useEffect(
            () =>
            {
                initializeInitialPhase(entity, undefined, props.commitContext)
                    .then(() => {});
            },
            [
                entity,
                 props.commitContext,
            ]);

        const changeFrom =
            useCallback(
                (addressee: Entity) =>
                {
                    updateRelationship(
                        entity,
                        false,
                        types.Activity.Email.RelationshipDefinition.From,
                        addressee,
                        props.commitContext
                    )
                },
                [
                    entity,
                    types,
                    props.commitContext,
                ]);

        const changeToRecipients =
            useCallback(
                (values: SelectionValue<Entity>) =>
                    handleRelationshipChange(types.Activity.Email.RelationshipDefinition.To, values as Entity[]),
                [
                    handleRelationshipChange,
                    types
                ]);

        const changeCcRecipients =
            useCallback(
                (values: SelectionValue<Entity>) =>
                    handleRelationshipChange(types.Activity.Email.RelationshipDefinition.CC, values as Entity[]),
                [
                    handleRelationshipChange,
                    types
                ]);

        const changeBccRecipients =
            useCallback(
                (values: SelectionValue<Entity>) =>
                    handleRelationshipChange(types.Activity.Email.RelationshipDefinition.BCC, values as Entity[]),
                [
                    handleRelationshipChange,
                    types
                ]);

        useEffect(
            () =>
            {
                const result = parseVariables(content);

                if (result !== false)
                {
                    setEmailBody(result);
                    setReloadContentRef(new Date().getTime());
                }
            },
            [
                content,
                setEmailBody,
                parseVariables,
                fromAddressee,
                to
            ]);

        const [ isAttachmentConstructorOpen, toggleAttachmentConstructor ] = useToggle(false);
        const addAttachment =
            useCallback(
                (attachment: Entity) => {

                    updateRelationship(
                        attachment,
                        true,
                        types.Entity.RelationshipDefinition.Attachments,
                        entity,
                        props.commitContext
                    );

                },
            [
                    entity,
                    types,
                    props.commitContext,
                ]
            );

        const addFileAsAttachment =
            useCallback(
                (file: FileValue) =>
                    runInAction(
                        () => {

                    const attachment =
                        constructEntityOfType(
                            types.Attachment.Type,
                            props.commitContext
                        );

                    setValueByFieldInEntity(
                        attachment,
                        types.Attachment.Field.File,
                        file,
                        props.commitContext
                    );

                    addAttachment(attachment);
                }),
                [
                    types,
                    props.commitContext,
                    addAttachment
                ]
            );

        const updateFiles =
            useCallback(
                (files: File[]) =>
                    runInAction(
                        () =>
                        {
                            files.forEach(
                                file =>
                                {
                                    const attachment =
                                        constructEntityOfType(
                                            types.Attachment.Type,
                                            props.commitContext
                                        );

                                    setValueByFieldInEntity(
                                        attachment,
                                        types.Attachment.Field.File,
                                        FileValue.fromFile(file),
                                        props.commitContext
                                    );

                                    addAttachment(attachment);
                                });

                            toggleAttachmentConstructor(false);
                        }),
                [
                    types,
                    toggleAttachmentConstructor,
                    props.commitContext,
                    addAttachment
                ]);

        const attachments =
            useRelatedEntities(
                entity,
                types.Entity.RelationshipDefinition.Attachments,
                false,
                props.commitContext
            );

        const totalSizeAttachments =
            useMemo(
            () => {

                return attachments.reduce(
                    (total, attachment) => {
                        const fileValue =
                            attachment.getObjectValueByField(
                                types.Attachment.Field.File,
                                props.commitContext
                            );
                        return total + (fileValue?.size | 0);
                    }
                    ,0
                );
            },
            [
                attachments,
                props.commitContext,
                types
            ]
            );

        const attachmentsTooBig =
            useMemo(
            () => {
                return totalSizeAttachments > MaxAttachmentsSize;
            },
            [
                totalSizeAttachments,
                MaxAttachmentsSize
            ]
        );

        useEffect(
            () =>
                {
                    if (cc.length > 0 && isExpandRecipientField === false)
                    {
                        toggleExpandRecipientField()
                    }
                },
            [
                cc,
                isExpandRecipientField,
                toggleExpandRecipientField
            ]
        );

        return <div
            className={styles.root}
        >
            <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        <ViewGroupItem>
                            <InputGroup>
                                <GenericInput
                                    label={
                                        <LocalizedText
                                            code="Generic.From"
                                            value="Van"
                                        />
                                    }
                                    labelPosition="left"
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={10}
                                        alignment="center"
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <StaticSelectbox
                                                options={fromOptions}
                                                onChange={changeFrom}
                                                value={fromRecipient}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <IconButton
                                                icon={isExpandRecipientField ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                                onClick={toggleExpandRecipientField}
                                                tooltip="CC, BCC"
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </GenericInput>
                                {
                                    !props.hideToField &&
                                    <GenericInput
                                        label={
                                            <LocalizedText
                                                code="Generic.To"
                                                value="Naar"
                                            />
                                        }
                                        labelPosition="left"
                                    >
                                        <Selectbox
                                            selections={recipientSelections}
                                            onChange={changeToRecipients}
                                            value={to}
                                            multi
                                            formatCaption={toLabelFormatter}
                                            searchFieldPaths={recipientSearchFieldPaths}
                                            commitContext={props.commitContext}
                                        />
                                    </GenericInput>
                                }
                                {
                                    isExpandRecipientField &&
                                        <InputGroup>
                                            <GenericInput
                                                label="CC"
                                                labelPosition="left"
                                            >
                                                <Selectbox
                                                    selections={recipientSelections}
                                                    onChange={changeCcRecipients}
                                                    value={cc}
                                                    multi
                                                    formatCaption={ccLabelFormatter}
                                                    searchFieldPaths={recipientSearchFieldPaths}
                                                    commitContext={props.commitContext}
                                                />
                                            </GenericInput>
                                            <GenericInput
                                                label="BCC"
                                                labelPosition="left"
                                            >
                                                <Selectbox
                                                    selections={recipientSelections}
                                                    onChange={changeBccRecipients}
                                                    value={bcc}
                                                    multi
                                                    formatCaption={bccLabelFormatter}
                                                    searchFieldPaths={recipientSearchFieldPaths}
                                                    commitContext={props.commitContext}
                                                />
                                            </GenericInput>
                                        </InputGroup>
                                }
                            </InputGroup>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Divider />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <InputGroup>
                                {
                                    props.subjectTemplate
                                    && onChangeSubjectTemplate
                                    && props.templateContext
                                        ?
                                            <Input
                                                labelPosition="left"
                                                label={types.Activity.Field.Subject.getName()}
                                            >
                                                <ComputationEditor
                                                    key={selectedTemplate?.uuid}
                                                    value={props.subjectTemplate}
                                                    onChange={onChangeSubjectTemplate}
                                                    context={props.templateContext}
                                                    disallowRichText
                                                />
                                            </Input>
                                        :
                                            <EntityInput
                                                entity={entity}
                                                field={types.Activity.Field.Subject}
                                                labelPosition="left"
                                                doAutocommit={props.autoCommit}
                                                touched={props.touched}
                                                commitContext={props.commitContext}
                                            />
                                }
                                {
                                    attachments.length > 0 &&
                                        <GenericInput
                                            label={
                                                types.Attachment.Type.getName(true)
                                                + " (" + toHumanReadableFilesize(totalSizeAttachments, 0) + ")"
                                            }
                                            labelPosition="left"
                                        >
                                            <AttachmentList
                                                attachments={attachments}
                                                commitContext={props.commitContext}
                                            />
                                        </GenericInput>
                                }
                                {
                                    attachmentsTooBig &&
                                        <div
                                            className={styles.red}
                                        >
                                            <LocalizedText
                                                code="Generic.AttachmentsTooBig"
                                                value="Bijlagen zijn te groot"
                                            />
                                        </div>
                                }
                                {
                                    message &&
                                    <GenericInput
                                        label=""
                                        labelPosition="left"
                                    >
                                        <Chip
                                            size="small"
                                            label={message}
                                        />
                                    </GenericInput>
                                }
                            </InputGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Divider />
                </ViewGroupItem>
                <ViewGroupItem>
                    {
                        isDraft && isHtmlEditable &&
                        <HtmlEditor
                            key={lastReloadContentRef}
                            entity={entity}
                            onChange={setHtml}
                            html={html}
                            editLayoutMode={editLayoutMode}
                            debounceDelay={0}
                            expressionContext={props.expressionContext}
                        />
                    }
                    {
                        isDraft && !isHtmlEditable &&
                        <ContentEditor
                            key={lastReloadContentRef}
                            entity={entity}
                            onChange={setEmailBody}
                            definition={content}
                            editLayoutMode={editLayoutMode}
                            debounceDelay={0}
                            expressionContext={props.expressionContext}
                        />
                    }
                    {
                        !isDraft &&
                        <EntityEmailViewer
                            entity={entity}
                        />
                    }
                </ViewGroupItem>
                <ViewGroupItem>
                    <ViewGroup
                        orientation="vertical"
                        spacing={20}
                    >
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={10}
                                alignment="center"
                                justification="end"
                            >
                                <ViewGroupItem>
                                    <IconButton
                                        icon="attach_file"
                                        tooltip={
                                            <LocalizedText
                                                code="Generic.AddResource"
                                                value="${resource} toevoegen"
                                                resource={types.Attachment.Type.getName(false)}
                                            />
                                        }
                                        onClick={toggleAttachmentConstructor}
                                        color={isAttachmentConstructorOpen ? primaryColor : textSecondaryColor}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem
                                    alignment="left"
                                >
                                    <EmailTemplateSelectorButton
                                        key={lastReloadContentRef}
                                        onSelect={selectEmailTemplate}
                                        templateType={props.templateType || types.Template.Email.General.Type}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem
                                    ratio={1}
                                    alignment="left"
                                >
                                    <Input
                                        label={
                                            <LocalizedText
                                                code="Generic.EditResource"
                                                value="${resource} bewerken"
                                                resource={localizeText('Generic.Layout', 'Layout')}
                                            />
                                        }
                                        labelPosition="right"
                                    >
                                        <Switch
                                            onToggle={toggleEditLayoutMode}
                                            checked={editLayoutMode}
                                        />
                                    </Input>
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                        {
                            isAttachmentConstructorOpen &&
                            <ViewGroupItem>
                                <FileDropZoneWithLibrary
                                    onChangeFiles={updateFiles}
                                    onAddFile={addFileAsAttachment}
                                    entity={props.entity}
                                    commitContext={props.commitContext}
                                />
                            </ViewGroupItem>
                        }
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem
                    alignment="right"
                >
                    {
                        !sendingAllowed && !isLoadingSendingAllowed &&
                        <div
                            className={styles.domainWarning}
                        >
                            <LocalizedText
                                code="Generic.EmailDomainNotValidated"
                                value="E-maildomein niet gevalideerd"
                            />
                        </div>
                    }
                    <RightAlignedButtonGroup>
                        {props.buttons}
                        {
                            isDraft &&
                            <LabelButton
                                label={
                                    <LocalizedText
                                        code="Generic.Example"
                                        value="Voorbeeld"
                                    />
                                }
                                color={primaryColor}
                                onClick={showPreview}>
                            </LabelButton>
                        }
                        {
                            props.onClose &&
                            <CancelButton
                                onClick={props.onClose}
                            />
                        }
                        {
                            isDraft && !props.disableDraftSave &&
                            <SaveButton
                                onClick={doSave}
                                disabled={!isEntityDirty(entity, props.commitContext)}
                            />
                        }
                        {
                            !props.disableSend &&
                            <PrimaryButton
                                label={
                                    <LocalizedText
                                        code="Generic.Send"
                                        value="Verzenden"
                                    />
                                }
                                onClick={doSend}
                                disabled={to.length === 0 || attachmentsTooBig || !sendingAllowed || isLoadingSendingAllowed}
                            />
                        }
                    </RightAlignedButtonGroup>
                </ViewGroupItem>

            </ViewGroup>
        </div>;
    }
);
