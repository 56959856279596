import AutomationDependencyContext from '../AutomationDependencyContext';
import { observable } from 'mobx';
import MutationTrigger from './MutationTrigger';
import getMutationTriggerFromDescriptor from './Api/getMutationTriggerFromDescriptor';
import updateTriggerWithPredicateFromDescriptor from './Api/updateTriggerWithPredicateFromDescriptor';

export default class CompositeTriggerElement
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref trigger: MutationTrigger;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                trigger: MutationTrigger)
    {
        this.id = id;
        this.trigger = trigger;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor()
    {
        return {
            id: this.id,
            trigger: this.trigger.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const trigger =
            await getMutationTriggerFromDescriptor(
                descriptor.trigger,
                dependencyContext)

        await updateTriggerWithPredicateFromDescriptor(
            trigger,
            descriptor.trigger,
            dependencyContext,
            false);

        return new CompositeTriggerElement(
            descriptor.id,
            trigger);
    }

    // ----------------------- Private logic ------------------------
}
