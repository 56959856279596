import { EntityType } from '../Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../@Util/DependencyInjection/index';
import { Comparator } from '../../@Component/Domain/DataObject/Model/Comparator';
import { EntityCacheService } from '../../@Component/Service/Entity/EntityCacheService';
import Predicate from '../Automation/Function/Computation/Predicate/Predicate';
import ComparisonPredicate from '../Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../Automation/Function/Computation/ValueFromEntityComputation';
import PrimitiveValue from '../Automation/Value/PrimitiveValue';
import getTypes from '../../@Component/Domain/Entity/Type/Api/getTypes';
import EntityValue from '../Automation/Value/EntityValue';
import { CommitContext } from './Commit/Context/CommitContext';
import { constructEntityOfType } from './Commit/Context/Api/Compatibility/constructEntityOfType';
import FunctionContext from '../Automation/Function/FunctionContext';
import ParameterDictionary from '../Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../Automation/Parameter/ParameterAssignment';

export default function constructEntityFromPredicate(
    entityType: EntityType,
    predicate: Predicate,
    commitContext?: CommitContext
)
{
    const types = getTypes();
    const entity = constructEntityOfType(entityType, commitContext);

    if (predicate)
    {
        predicate.getTrueStatements(
            new FunctionContext(
                new ParameterDictionary([]),
                new ParameterAssignment(),
                commitContext
            )
        )
            .forEach(
                predicate =>
                {
                    if (predicate instanceof ComparisonPredicate
                        && predicate.lhs instanceof ValueFromEntityComputation
                        && predicate.comparator === Comparator.Equals
                        && entityType.isA(predicate.lhs.fieldPath.path.rootEntityType))
                    {
                        if (predicate.rhs instanceof PrimitiveValue)
                        {
                            if (predicate.lhs.fieldPath.field === types.Entity.Field.Id)
                            {
                                const relatedEntity = loadModuleDirectly(EntityCacheService).getEntityById(predicate.rhs.value?.value);

                                if (relatedEntity)
                                {
                                    predicate.lhs.fieldPath
                                        .path
                                        .field()
                                        .setValue(
                                            entity,
                                            relatedEntity,
                                            undefined,
                                            commitContext
                                        );
                                }
                            }
                            else
                            {
                                predicate.lhs.fieldPath.setValue(
                                    entity,
                                    predicate.rhs.value?.value,
                                    undefined,
                                    commitContext
                                );
                            }
                        }
                        else if (predicate.rhs instanceof EntityValue)
                        {
                            predicate.lhs.fieldPath
                                .path
                                .field()
                                .setValue(
                                    entity,
                                    predicate.rhs.value,
                                    undefined,
                                    commitContext
                                );
                        }
                    }
                });
    }

    return entity;
}
