import { EmployeeAllocationSpecification } from '../Model/EmployeeAllocationSpecification';
import { getDefaultEmployeeAllocationSpecification } from './getDefaultEmployeeAllocationSpecification';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import PrimitiveValueType from '../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../DataObject/Model/DataObject';
import localizeText from '../../../../../@Api/Localization/localizeText';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { Comparator } from '../../../DataObject/Model/Comparator';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import getTypes from '../../Type/Api/getTypes';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';

export function getProjectEmployeeAllocationSpecification(
    project: Entity,
    milestone?: Entity
): EmployeeAllocationSpecification
{
    const baseSpecification = getDefaultEmployeeAllocationSpecification();
    const types = getTypes();
    const showOnlyItemsOfActivityParameter =
        new Parameter(
            'ShowOnlyItemsOfActivity',
            new PrimitiveValueType(
                DataObject.getTypeById('Boolean')
            ),
            false,
            localizeText(
                'ItemPlanner.ShowOnlyItemsOfActivity',
                'Enkel bezetting tonen voor deze activiteit'
            ),
            new PrimitiveValue(
                DataObject.constructFromTypeIdAndValue(
                    'Boolean',
                    true
                )
            )
        );
    const showOnlyProjectMembersParameter =
        new Parameter(
            'ShowOnlyProjectMembers',
            new PrimitiveValueType(
                DataObject.getTypeById('Boolean')
            ),
            false,
            localizeText(
                'ItemPlanner.ShowOnlyProjectMembers',
                'Enkel projectleden tonen'
            ),
            new PrimitiveValue(
                DataObject.constructFromTypeIdAndValue(
                    'Boolean',
                    true
                )
            )
        );
    baseSpecification.resourceFilterParameters!.addParameter(showOnlyProjectMembersParameter);
    const falseValue =
        new PrimitiveValue(
            DataObject.constructFromTypeIdAndValue(
                'Boolean',
                false
            )
        );
    (baseSpecification.resourceFilter! as CompositePredicate)
        .predicates
        .push(
            new CompositePredicate(
                LogicalOperator.Or,
                [
                    new ComparisonPredicate(
                        showOnlyProjectMembersParameter,
                        Comparator.Equals,
                        falseValue
                    ),
                    new ComparisonPredicate(
                        new ValueFromEntityComputation(
                            baseSpecification.resourceParameter,
                            EntityPath.fromEntityType(baseSpecification.resourceParameter.type.type)
                                .joinTo(
                                    types.ProjectMember.RelationshipDefinition.Employee,
                                    true
                                )
                                .joinTo(
                                    types.Activity.Project.RelationshipDefinition.Members,
                                    true
                                )
                                .field()
                        ),
                        Comparator.Equals,
                        new EntityValue(project)
                    )
                ]
            )
        );
    baseSpecification.employeeAllocationFilterParameters =
        new ParameterDictionary([
            showOnlyItemsOfActivityParameter,
        ]);
    baseSpecification.employeeAllocationFilter =
        new CompositePredicate(
            LogicalOperator.Or,
            [
                new ComparisonPredicate(
                    showOnlyItemsOfActivityParameter,
                    Comparator.Equals,
                    falseValue
                ),
                milestone === undefined
                    ? new ComparisonPredicate(
                        new ValueFromEntityComputation(
                            baseSpecification.employeeAllocationParameter,
                            EntityPath.fromEntityType(baseSpecification.employeeAllocationParameter.type.type)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.EmployeeAllocations,
                                    true
                                )
                                .field()
                        ),
                        Comparator.Equals,
                        new EntityValue(project)
                    )
                    : new CompositePredicate(
                        LogicalOperator.And,
                        [
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    baseSpecification.employeeAllocationParameter,
                                    EntityPath.fromEntityType(baseSpecification.employeeAllocationParameter.type.type)
                                        .joinTo(
                                            types.Activity.RelationshipDefinition.EmployeeAllocations,
                                            true
                                        )
                                        .field()
                                ),
                                Comparator.Equals,
                                new EntityValue(project)
                            ),
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    baseSpecification.employeeAllocationParameter,
                                    EntityPath.fromEntityType(baseSpecification.employeeAllocationParameter.type.type)
                                        .joinTo(
                                            types.Milestone.RelationshipDefinition.EmployeeAllocations,
                                            true
                                        )
                                        .field()
                                ),
                                Comparator.Equals,
                                new EntityValue(milestone)
                            )
                        ]
                    )
            ]
        );
    baseSpecification.employeeAllocationConstructorFields = [
        EntityPath.fromEntityType(baseSpecification.employeeAllocationParameter.type.type)
            .joinTo(
                types.Milestone.RelationshipDefinition.EmployeeAllocations,
                true
            )
            .field()
    ];

    return baseSpecification;
}