import React from 'react';
import { RecurrencePattern } from '../Model/RecurrencePattern';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { RecurrencePatternTypeEditor } from './TypeSelector/RecurrencePatternTypeEditor';
import { DailyRecurrencePatternEditor } from './Type/Daily/DailyRecurrencePatternEditor';
import { WeeklyRecurrencePatternEditor } from './Type/Weekly/WeeklyRecurrencePatternEditor';
import { MonthlyOrRelativeMonthlyRecurrencePatternEditor } from './Type/MonthlyOrRelativeMonthly/MonthlyOrRelativeMonthlyRecurrencePatternEditor';
import { YearlyOrRelativeYearlyRecurrencePatternEditor } from './Type/YearlyOrRelativeYearly/YearlyOrRelativeYearlyRecurrencePatternEditor';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import { RecurrencePatternEndEditor } from './End/RecurrencePatternEndEditor';

export interface RecurrencePatternEditorProps<T = RecurrencePattern>
{
    value: T;
    onChange: (value: T) => void;
    inset?: boolean;
    disabled?: boolean;
}

export const RecurrencePatternEditor: React.FC<RecurrencePatternEditorProps> =
    ({
        value,
        onChange,
        inset = false,
     }) =>
    {
        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            <ViewGroupItem>
                <CardInset
                    horizontal={inset}
                    vertical={false}
                >
                    <RecurrencePatternTypeEditor
                        value={value}
                        onChange={onChange}
                    />
                </CardInset>
            </ViewGroupItem>
            <ViewGroupItem>
                <CardInset
                    horizontal={inset}
                    vertical={inset}
                >
                    {
                        value.type === 'Daily' &&
                        <DailyRecurrencePatternEditor
                            value={value}
                            onChange={onChange}
                        />
                    }
                    {
                        value.type === 'Weekly' &&
                        <WeeklyRecurrencePatternEditor
                            value={value}
                            onChange={onChange}
                        />
                    }
                    {
                        (value.type === 'Monthly' || value.type === 'RelativeMonthly') &&
                        <MonthlyOrRelativeMonthlyRecurrencePatternEditor
                            value={value}
                            onChange={onChange}
                        />
                    }
                    {
                        (value.type === 'Yearly' || value.type === 'RelativeYearly') &&
                        <YearlyOrRelativeYearlyRecurrencePatternEditor
                            value={value}
                            onChange={onChange}
                        />
                    }
                </CardInset>
            </ViewGroupItem>
            <ViewGroupItem>
                <RecurrencePatternEndEditor
                    value={value.end}
                    onChange={
                        end =>
                            onChange({
                                ...value,
                                end,
                            })
                    }
                    inset={inset}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };
