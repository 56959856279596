import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import useTypes from '../../../../../Type/Api/useTypes';
import RelatedList from '../../../../Shared/RelatedList/RelatedList';

export interface WorkOrderMemberListProps
{
    entity: Entity;
}

const WorkOrderMemberList: React.FC<WorkOrderMemberListProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();
        const path =
            useComputed(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Activity.WorkOrder.RelationshipDefinition.Members,
                            false
                        ),
                [
                    props.entity,
                    currentUserStore,
                    types,
                ]
            );
        const initializationPaths =
            useComputed(
                () => [
                    EntityPath
                        .fromEntityType(types.WorkOrderMember.Type)
                        .joinTo(
                            types.WorkOrderMember.RelationshipDefinition.Employee,
                            false
                        ),
                    EntityPath
                        .fromEntityType(types.WorkOrderMember.Type)
                        .joinTo(
                            types.WorkOrderMember.RelationshipDefinition.CalendarItemAttendee,
                            false
                        )
                        .joinTo(
                            types.CalendarItemAttendee.RelationshipDefinition.ResponseStatus,
                            false
                        )
                ],
                [
                    types,
                ]
            );

        return <RelatedList
            entity={props.entity}
            path={path}
            initializationPaths={initializationPaths}
        />;
    };

export default observer(WorkOrderMemberList);
