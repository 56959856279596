import React from 'react';
import { GutenbergCoreBlockColumnId, GutenbergCoreBlockColumnsId } from '../../../DocumentEditor';
import GutenbergBlockType from '../../../Gutenberg/GutenbergBlockType';
import { __experimentalUseInnerBlocksProps as useInnerBlocksProps, BlockControls, BlockVerticalAlignmentToolbar, InnerBlocks, InspectorControls, store as blockEditorStore, useBlockProps, useSetting } from '@wordpress/block-editor';
import ColorOptionsPanel from '../../Settings/ColorOptionsPanel';
import classnames from 'classnames';
import { __experimentalUnitControl as UnitControl, __experimentalUseCustomUnits as useCustomUnits, PanelBody } from '@wordpress/components';
import { useDispatch, useSelect } from '@wordpress/data';
import { __, sprintf } from '@wordpress/i18n';
import StyledBlock from '../../StyledBlock/StyledBlock';
import GutenbergBlockViewer from '../../../Gutenberg/GutenbergBlockViewer';

const GutenbergCoreBlockColumn: GutenbergBlockType =
{
    name: GutenbergCoreBlockColumnId,

    // https://github.com/WordPress/gutenberg/tree/trunk/packages/block-library/src/column

    configuration: {
        title: {
            code: 'GutenbergBlock.Column',
            value: 'Kolom'
        },
        category: "text",
        parent: [GutenbergCoreBlockColumnsId],
        description: "A single column within a columns block.",
        textdomain: "default",
        attributes: {
            verticalAlignment: {
                type: "string"
            },
            /*"width": {
                "type": "string"
            },*/
            width: {
                type: "number",
                min: 0,
                max: 100
            },
            allowedBlocks: {
                type: "array"
            },
            templateLock: {
                type: ["string", "boolean"],
                enum: ["all", "insert", false]
            }
        },
        supports: {
            anchor: true,
            reusable: false,
            html: false,
            color: {
                gradients: false,
                link: true
            },
            spacing: {
                padding: true,
                __experimentalDefaultControls: {
                    padding: true
                }
            }
        },

        edit: props =>
        {
            return <GutenbergCoreBlockColumnEditor
                {...props}
            />;
        },

        save: (props) =>
        {
            const { verticalAlignment, width } = props.attributes;

            const wrapperClasses = classnames(
                {
                    [ `is-vertically-aligned-${ verticalAlignment }` ]: verticalAlignment
                });

            let style;

            if ( width ) {
                // Numbers are handled for backward compatibility as they can be still provided with templates.
                let flexBasis = Number.isFinite( width ) ? width + '%' : width;
                // In some cases we need to round the width to a shorter float.
                if ( ! Number.isFinite( width ) && width?.endsWith( '%' ) ) {
                    const multiplier = 1000000000000;
                    // Shrink the number back to a reasonable float.
                    flexBasis =
                        Math.round( Number.parseFloat( width ) * multiplier ) /
                        multiplier +
                        '%';
                }
                style = { flexBasis };
            }

            return (
                <div
                    { ...useBlockProps.save( {
                        className: wrapperClasses,
                        style,
                    } ) }
                >
                    <InnerBlocks.Content />
                </div>
            );
        }
    },

    view: (block, props) =>
    {
        return <div
            style={{
                float: 'left',
                width:
                    block.attributes.width === undefined
                        ?
                            undefined
                        :
                            `${block.attributes.width}%`,
            }}
        >
            <StyledBlock
                block={block}
            >
                <GutenbergBlockViewer
                    {...props}
                    blocks={block.innerBlocks}
                />
            </StyledBlock>
        </div>;
    }
};


const GutenbergCoreBlockColumnEditor =
    (props: any) =>
    {
        const {
            setAttributes,
            clientId
        } = props;

        const {
            verticalAlignment,
            width,
            templateLock = false,
            backgroundColor,
            fontColor,
            borderColor
        } = props.attributes;

        const classes = classnames(
            'block-core-columns',
            {
                [`is-vertically-aligned-${verticalAlignment}`]: verticalAlignment,
            });

        const units = useCustomUnits(
            {
                availableUnits: useSetting('spacing.units') || ['%', 'px', 'em', 'rem', 'vw']
            });

        const { columnsIds, hasChildBlocks, rootClientId } =
            useSelect(
                (select) =>
                {
                    const {
                        getBlockOrder,
                        getBlockRootClientId
                    } = select(blockEditorStore);

                    const rootId = getBlockRootClientId(clientId);

                    return {
                        hasChildBlocks: getBlockOrder(clientId).length > 0,
                        rootClientId: rootId,
                        columnsIds: getBlockOrder(rootId),
                    };
                },
                [
                    clientId
                ]);

        const {updateBlockAttributes} = useDispatch(blockEditorStore);

        const updateAlignment = (value) =>
        {
            setAttributes({verticalAlignment: value});

            updateBlockAttributes(rootClientId, {
                verticalAlignment: null,
            });
        };

        const widthWithUnit = Number.isFinite(width) ? width + '%' : width;

        const blockProps =
            useBlockProps(
                {
                    className: classes,
                    style: {
                        flexBasis: widthWithUnit,
                        backgroundColor: backgroundColor,
                        color: fontColor,
                        borderColor: borderColor
                    }
                });

        const columnsCount = columnsIds.length;
        const currentColumnPosition = columnsIds.indexOf(clientId) + 1;

        const label = sprintf(
            /* translators: 1: Block label (i.e. "Block: Column"), 2: Position of the selected block, 3: Total number of sibling blocks of the same type */
            __('%1$s (%2$d of %3$d)'), blockProps['aria-label'], currentColumnPosition, columnsCount);

        const innerBlocksProps =
            useInnerBlocksProps(
                {
                    ...blockProps,
                    'aria-label': label
                },
                {
                    templateLock,
                    renderAppender: hasChildBlocks ? undefined : InnerBlocks.ButtonBlockAppender,
                });

        return (<>
                <BlockControls>
                    <BlockVerticalAlignmentToolbar
                        onChange={updateAlignment}
                        value={verticalAlignment}
                    />
                </BlockControls>
                <InspectorControls>
                    <ColorOptionsPanel
                        props={props}
                        disableBorderColor
                        disableFontColor
                    />
                    <PanelBody title={__('Column settings')}>
                        <UnitControl
                            label={__('Width')}
                            labelPosition="edge"
                            __unstableInputWidth="80px"
                            value={width || ''}
                            onChange={(nextWidth) =>
                            {
                                nextWidth = 0 > parseFloat(nextWidth) ? '0' : nextWidth;
                                setAttributes({width: nextWidth});
                            }}
                            units={units}
                        />
                    </PanelBody>
                </InspectorControls>
                <div {...innerBlocksProps} />
            </>);
    }

export default GutenbergCoreBlockColumn;
