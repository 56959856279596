import { EntityField } from './EntityField';
import { EntityNode } from './EntityNode';
import { observable } from 'mobx';
import { ComparisonConstraintNode } from './ComparisonConstraintNode';
import { Entity } from './Entity';
import { DataObject } from '../../../@Component/Domain/DataObject/Model/DataObject';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../@Component/Domain/DataObject/DataObjectStore';
import { Comparator } from '../../../@Component/Domain/DataObject/Model/Comparator';

export class ValueComparisonConstraintNode extends ComparisonConstraintNode
{
    // ------------------- Persistent Properties --------------------

    @observable.ref passiveValue: DataObject;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(activeEntityNode: EntityNode,
                activeEntityField: EntityField,
                activeEntityFieldRepresentation: any,
                comparator: Comparator,
                passiveValue: DataObject)
    {
        super(activeEntityNode, activeEntityField, activeEntityFieldRepresentation, comparator);

        if (passiveValue?.value instanceof Entity)
        {
            console.warn('here', this);
        }

        this.passiveValue = passiveValue;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    hashCode(): string
    {
        return `${super.hashCode()},${this.passiveValue && this.passiveValue.valueId}`;
    }

    equals(node: ValueComparisonConstraintNode): boolean
    {
        return super.equals(node)
            && DataObject.compare(
                this.passiveValue,
                node.passiveValue,
                Comparator.Equals);
    }

    matches(entity: Entity): boolean
    {
        const fieldPath = this.activeEntityNode.entityPath().field(this.activeEntityField);

        const values =
            fieldPath.path
                .traverseEntity(entity, null)
                .map(
                    entity =>
                    {
                        if (fieldPath.field)
                        {
                            return entity.getDataObjectValueByField(fieldPath.field, undefined, null);
                        }
                        else
                        {
                            return DataObject.constructFromTypeIdAndValue(
                                'Entity',
                                entity,
                                loadModuleDirectly(DataObjectStore));
                        }
                    });

        if (values.length === 0)
        {
            values.push(undefined);
        }

        return values.some(
            value =>
                DataObject.compare(
                    value,
                    this.passiveValue,
                    this.comparator));

        // log('      compared result with outcome',
        //     fieldPath.code,
        //     value && value.value,
        //     passiveValue && passiveValue.value,
        //     entity,
        //     fieldPath,
        //     value,
        //     passiveValue,
        //     this.comparator,
        //     result);
    }

    descriptor()
    {
        return Object.assign(
            super.descriptor(),
            {
                type: 'ValueComparison',
                passiveType: this.passiveValue && this.passiveValue.specification.type.id(),
                passiveValue: this.passiveValue && this.passiveValue.data.descriptor()
            });
    }

    // ----------------------- Private logic ------------------------
}
