import * as React from 'react';
import { EntityEventType } from '../Model/EntityEventType';
import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../../../DataObject/DataObjectStore';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { EntityCreationMutation } from '../../../../../@Api/Model/Implementation/EntityCreationMutation';
import { DataObjectViewer } from '../../../DataObject/Viewer/DataObjectViewer';
import { Typography } from '@material-ui/core';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';
import { EntityCreateStore } from './EventCreateStore';
import { DataObjectEditor } from '../../../DataObject/Editor/Value/Editor/DataObjectEditor';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import { BadgeStore } from '../../../../Generic/Badge/BadgeStore';
import { registerType } from '../../../../../@Util/Serialization/Serialization';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { ImportanceLevel } from '../../../../../@Api/Model/Implementation/EntityField';

// For some reason, the serializer does not deserialize the entity creation mutation from
// JSON. We manually register this type.
registerType(EntityCreationMutation, 'EntityCreationMutation');

export class EntityCreate extends EntityEventType<EntityCreateStore>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('CurrentUserStore') userStore: CurrentUserStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return 'Entity.Create';
    }

    parentId(): string
    {
        return null;
    }

    isEnabledByDefault(): boolean
    {
        return true;
    }

    color(event: EntityEvent): string
    {
        return event.entity.entityType.getInheritedColor();
    }

    icon(event: EntityEvent): string
    {
        return event.entity.entityType.getInheritedIcon() || 'add';
    }

    isFilled(event: EntityEvent): boolean
    {
        return true;
    }

    importanceLevel(event: EntityEvent): ImportanceLevel
    {
        return ImportanceLevel.Normal;
    }

    name(event: EntityCreationMutation): string
    {
        return `${event.entity.entityType.nameSingular}`;
    }

    description(event: EntityCreationMutation): string
    {
        return this.localizationStore.translate('Event.Create.Description', event.entity.entityType.nameSingular); // {type} created
    }

    store(event: EntityEvent): EntityCreateStore
    {
        return new EntityCreateStore(event.entity);
    }

    content(event: EntityEvent, store: EntityCreateStore): JSX.Element
    {
        if (store.viewerStore && !store.isInEditMode)
        {
            return <DataObjectViewer store={store.viewerStore} />;
        }
        else if (store.editorStore && store.isInEditMode)
        {
            return <DataObjectEditor store={store.editorStore} />;
        }
        else
        {
            return <Typography variant={'body2'}>
                {event.entity.name}
            </Typography>;
        }
    }

    caption(event: EntityEvent): string
    {
        return this.localizationStore.translate('Event.Create.Caption'); // aangemaakt
    }

    actions(event: EntityCreationMutation,
            store: EntityCreateStore,
            onDelete?: (event: EntityCreationMutation) => void): ButtonStore[]
    {
        if (event.entity.entityType.isA(this.entityTypeStore.bespoke.types.Note.Type))
        {
            return [
                new ButtonStore({
                    onClick: store.setInEditMode,
                    icon: 'edit',
                    isVisible: () => !store.isInEditMode && event.user && event.user.id === this.userStore.currentUser.id
                })];
        }
        else if (event.entity.entityType.isA(this.entityTypeStore.bespoke.types.Attachment.Type))
        {
            return [
                new ButtonStore({
                    onClick: () =>
                    {
                        store.delete().then(result =>
                        {
                            if (onDelete)
                            {
                                onDelete(event);
                            }
                        });
                    },
                    icon: 'delete',
                    isVisible: () => !store.isInEditMode && event.user && event.user.id === this.userStore.currentUser.id
                })];
        }

        return [];
    }

    badges(event: EntityEvent): BadgeStore[]
    {
        return [];
    }

    allowExpansion(event: EntityCreationMutation): boolean
    {
        return false;
    }

    expandInSidebar(event: EntityCreationMutation): boolean
    {
        return !event.entity.entityType.isA(this.entityTypeStore.bespoke.types.Note.Type)
            && !event.entity.entityType.isA(this.entityTypeStore.bespoke.types.Attachment.Type);
    }

    expansionComponent(event: EntityCreationMutation): React.ComponentClass<BaseComponentProps<BaseStore>>
    {
        return null;
    }

    expansionStore(event: EntityCreationMutation): BaseStore
    {
        return null;
    }

    typeNameSingular(event: EntityCreationMutation): string
    {
        return this.localizationStore.translate('Event.Create.Singular.Name'); // added
    }

    typeNamePlural(): string
    {
        return this.localizationStore.translate('Event.Create.Plural.Name'); // added
    }

    // ----------------------- Private logic ------------------------
}
