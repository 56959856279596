import * as React from 'react';
import { DataObjectBespokeEditor } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { observer } from 'mobx-react';
import { NumberEditorStore } from '../NumberEditorStore';
import { default as GenericCurrencyEditor } from '../../../../../../@Future/Component/Generic/Input/NumberEditor/CurrencyEditor/CurrencyEditor';

@observer
export class CurrencyEditor extends DataObjectBespokeEditor<NumberEditorStore>
{
    renderComponent(store: DataObjectEditorStore)
    {
        return <GenericCurrencyEditor
            value={store.dataObject.value}
            onChange={store.changeValue}
            inputProps={store.handlers as any}
            disableUnderline={!store.hasUnderline}
            alignment={store.alignmentAsString}
            placeholder={store.placeholder}
            autoFocus={store.isFocused}
            disabled={store.isDisabled}
            error={store.showError}
            fitContent={store.representation?.data?.fitContent}
            currencyCode={store.representation?.data?.currency}
        />;
    }
}
