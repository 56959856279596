import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import InvalidLayout from '../../../../../@Api/Layout/Type/InvalidLayout';
import InvalidLayoutView from './InvalidLayoutView';

export interface InvalidLayoutViewerProps extends LayoutViewerProps<InvalidLayout>
{

}

const InvalidLayoutViewer: React.FC<InvalidLayoutViewerProps> =
    ({
         layout,
     }) =>
    {
        return <InvalidLayoutView
            layout={layout}
        />;
    };

export default observer(InvalidLayoutViewer);
