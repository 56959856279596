import { observable } from 'mobx';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import TypeMapping from '../TypeMapping';

export default abstract class FieldMapping
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable targetFieldPath: EntityFieldPath;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                targetFieldPath: EntityFieldPath)
    {
        this.id = id;
        this.targetFieldPath = targetFieldPath;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor()
    {
        return {
            id: this.id,
            targetFieldPath: this.targetFieldPath.descriptor
        };
    }

    getAllTypeMappings(): TypeMapping[]
    {
        return [];
    }

    getAllFieldMappings(): FieldMapping[]
    {
        return [ this ];
    }

    // ----------------------- Private logic ------------------------
}
