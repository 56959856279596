import { ComputationEditorStore } from '../../ComputationEditorStore';
import { action, observable } from 'mobx';
import { ComputationContext } from '../../ComputationContext';
import { ComputationType } from '../ComputationType';
import { ComputationSpecification } from '../ComputationSpecification';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { ComputationTypeStore } from '../../ComputationTypeStore';
import { ConditionalComputationConditionStore } from './Condition/ConditionalComputationConditionStore';
import { PredicateEditorStore } from '../../../Predicate/PredicateEditorStore';
import { PredicateTypeStore } from '../../../Predicate/PredicateTypeStore';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';

export class ConditionalComputationEditorStore extends ComputationEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('PredicateTypeStore') predicateTypeStore: PredicateTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable conditionStores = observable.array<ConditionalComputationConditionStore>();
    @observable otherwiseStore: ComputationEditorStore;
    @observable isAddMenuOpen: boolean;

    // ------------------------ Constructor -------------------------

    constructor(type: ComputationType,
                context: ComputationContext,
                specification: ComputationSpecification,
                conditionStores: ConditionalComputationConditionStore[],
                otherwiseStore: ComputationEditorStore)
    {
        super(type, context, specification);

        conditionStores.forEach(
            store =>
                (store.onDelete = deletedStore => (this.deleteCondition(deletedStore))));

        this.conditionStores.push(...conditionStores);
        this.otherwiseStore = otherwiseStore;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    openAddMenu()
    {
        this.isAddMenuOpen = true;
    }

    @action.bound
    closeAddMenu()
    {
        this.isAddMenuOpen = false;
    }

    @action.bound
    addCondition(type: ComputationType)
    {
        this.conditionStores.push(
            new ConditionalComputationConditionStore(
                PredicateEditorStore.construct(
                    this.context,
                    { type: 'Composite' },
                    this.predicateTypeStore),
                ComputationEditorStore.construct(
                    this.context,
                    { type: type.id() },
                    this.computationTypeStore),
                store => (this.deleteCondition(store))));
    }

    @action.bound
    deleteCondition(ConditionStore: ConditionalComputationConditionStore)
    {
        this.conditionStores.remove(ConditionStore);
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
