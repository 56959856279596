import AutomationDependencyContext from '../AutomationDependencyContext';
import Query from '../Query/Query';
import ListQuery from '../Query/ListQuery';
import getPredicateFromDescriptor from './getPredicateFromDescriptor';
import AggregateQuery from '../Query/AggregateQuery';

export default async function getQueryFromDescriptor(
    descriptor: any,
    dependencyContext: AutomationDependencyContext
): Promise<Query>
{
    let query: Query;

    switch (descriptor.type)
    {
        case 'List':
            query =
                await ListQuery.fromDescriptor(
                    dependencyContext,
                    descriptor
                );
            break;

        case 'Aggregate':
            query =
                await AggregateQuery.fromDescriptor(
                    dependencyContext,
                    descriptor
                );
            break;
    }

    if (query && descriptor.filter)
    {
        const filterDependencyContext =
            new AutomationDependencyContext(
                dependencyContext.parameterDictionary.getNewDictionaryWithParameter(
                    query.parameter
                )
            );

        query.filter =
            await getPredicateFromDescriptor(
                descriptor.filter,
                filterDependencyContext
            );
    }

    return query;
}
