import { OldPredicate } from '../../../Predicate/Type/OldPredicate';
import { default as NewPredicate } from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';

export default async function getNewViewPredicateFromOldPredicate(parameterDictionary: ParameterDictionary,
                                                                  oldPredicate: OldPredicate): Promise<NewPredicate>
{
    return oldPredicate.toNewPredicate(
        new AutomationDependencyContext(parameterDictionary));
}
