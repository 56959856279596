import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import ParameterAssignment from '../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import EntityValue from '../../../../../../../@Api/Automation/Value/EntityValue';
import { PortalParameters } from './PortalParameters';
import PrimitiveValue from '../../../../../../../@Api/Automation/Value/PrimitiveValue';

export default function getPortalRuntimeParameterAssignment(parameters: ParameterDictionary,
                                                            portalUser: Entity)
{
    return new ParameterAssignment()
        .setValue(
            parameters.getParameterById(PortalParameters.PortalUser),
            new EntityValue(portalUser))
        .setValue(
            parameters.getParameterById(PortalParameters.CurrentDateTime),
            new PrimitiveValue(
                DataObject.constructFromTypeIdAndValue(
                    'DateTime',
                    new Date())));
}
