import { DataObjectSpecification } from './DataObjectSpecification';
import { DataDescriptor, FileReporter } from './DataDescriptor';
import { Aggregate } from './Aggregate';
import { Comparator } from './Comparator';
import { MathematicalOperation, MathematicalOperator } from './MathematicalOperator';
import { action, computed, observable } from 'mobx';
import { DataObjectStore } from '../DataObjectStore';
import { type } from '../../../../@Util/Serialization/Serialization';
import { DataObjectType } from './DataObjectType';
import { DataObjectRepresentation } from './DataObjectRepresentation';
import { DataObjectContext } from './DataObjectContext';
import { DataObjectOverloadType } from './DataObjectOverloadType';
import { registerBuilder } from '../../../../@Util/TransactionalModelV2/Shared/TransactionalBuilder';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import isEqual from '../../../../@Util/IsEqual/isEqual';
import localizeText from '../../../../@Api/Localization/localizeText';

export enum Alignment { Left, Center, Right }

@type('DataObject')
export class DataObject
{
    // ------------------------- Properties -------------------------

    @observable.ref specification: DataObjectSpecification;
    @observable isInitialized: boolean;
    @observable.ref value: any;
    @observable.ref context: DataObjectContext;

    // ------------------------ Constructor -------------------------

    constructor(specification: DataObjectSpecification,
                data: DataDescriptor | any,
                context: DataObjectContext = {})
    {
        this.specification = specification;
        this.isInitialized = false;
        this.context = context;

        if (data instanceof DataDescriptor)
        {
            const value = specification.type.getUninitializedValueFromData(data || new DataDescriptor(), specification);

            if (value != null)
            {
                this.value = value;
            }

            if (value == null)
            {
                this.isInitialized = true;
            }
        }
        else
        {
            this.value = data;
            this.isInitialized = true;
        }
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    /*@computed
    get value(): any
    {
        return this.specification.type.getValue(this);
    }*/

    get type(): DataObjectType
    {
        return this.specification.type;
    }

    @computed
    get isEmpty(): boolean
    {
        return this.getValue() === null || this.getValue() === undefined || this.getValue() === '';
    }

    get isSemanticEmpty(): boolean
    {
        if (this.isEmpty)
        {
            return !this.type.hasSemanticValueWhenEmpty();
        }
        else
        {
            return false;
        }
    }

    @computed
    get data(): DataDescriptor
    {
        if (this.isEmpty)
        {
            return new DataDescriptor();
        }
        else
        {
            return this.specification.type.getDataFromValue(this.value, this.specification) || new DataDescriptor();
        }
    }

    @computed
    get isValid(): boolean
    {
        if (this.specification.isRequired && this.isEmpty)
        {
            return false;
        }
        else
        {
            return this.specification.type.isValid(this);
        }
    }

    @computed
    get invalidCause(): string
    {
        if (this.specification.isRequired && this.isEmpty)
        {
            return localizeText('MandatoryField', 'Dit is een verplicht veld');
        }
        else
        {
            return this.specification.type.invalidCause(this);
        }
    }

    @computed
    get valueId(): string
    {
        if (this.isEmpty)
        {
            return 'undefined';
        }
        else
        {
            return this.specification.type.valueId(this.value);
        }
    }

    // -------------------------- Actions ---------------------------

    @action
    setValue(value: any)
    {
        if (this.specification.type.translateValue)
        {
            this.value = this.specification.type.translateValue(value);
        }
        else
        {
            this.value = value;
        }
    }

    @action
    setInitialized(isInitialized: boolean)
    {
        this.isInitialized = isInitialized;
    }

    // ------------------------ Public logic ------------------------

    public cast(targetType: DataObjectType): DataObject
    {
        return DataObject.constructFromTypeAndValue(targetType, this.getValue());
    }

    public get compute(): MathematicalOperation
    {
        return {
            add: dataObject => DataObject.compute(this, dataObject, MathematicalOperator.Add),
            divide: dataObject => DataObject.compute(this, dataObject, MathematicalOperator.Divide),
            multiply: dataObject => DataObject.compute(this, dataObject, MathematicalOperator.Multiply),
            subtract: dataObject => DataObject.compute(this, dataObject, MathematicalOperator.Subtract),
        };
    }

    static compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return this.innerCompare(lhs, rhs, comparator);
    }

    static innerCompare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        let lhsType = lhs && lhs.specification.type;
        let rhsType = rhs && rhs.specification.type;

        let lhsOverload =
            lhsType?.comparatorOverloads()
                .find(
                    overload =>
                        overload.comparator === comparator
                        && overload.isCompatible(rhsType)
                        && (overload.type === DataObjectOverloadType.Symmetric || overload.type === DataObjectOverloadType.Lhs));

        if (lhsOverload)
        {
            return lhsOverload.compare(
                lhs,
                rhs,
                true);
        }

        let rhsOverload =
            rhsType?.comparatorOverloads()
                .find(
                    overload =>
                        overload.comparator === comparator
                        && overload.isCompatible(lhsType)
                        && (overload.type === DataObjectOverloadType.Symmetric || overload.type === DataObjectOverloadType.Rhs));

        if (rhsOverload)
        {
            return rhsOverload.compare(
                rhs,
                lhs,
                false);
        }

        switch (comparator)
        {
            case Comparator.Equals:
                if (lhs === rhs)
                {
                    return true;
                }
                else if (lhs == null || rhs == null)
                {
                    // Note that == should remain == because it might be null == undefined which should resolve to true
                    /* eslint-disable-next-line eqeqeq */
                    return lhs == rhs;
                }
                else
                {
                    const lhsValue = (lhs || ({} as any)).value;
                    const rhsValue = (rhs || ({} as any)).value;

                    return isEqual(lhsValue, rhsValue);
                }

            case Comparator.NotEquals:
                return !DataObject.compare(lhs, rhs, Comparator.Equals);

            case Comparator.IsDefined:
                return lhs != null && !lhs.isEmpty;

            case Comparator.IsNotDefined:
                return !DataObject.compare(lhs, rhs, Comparator.IsDefined);

            case Comparator.LessThan:
                return !DataObject.compare(lhs, rhs, Comparator.GreaterThanOrEqual);

            case Comparator.LessThanOrEqual:
                return !DataObject.compare(lhs, rhs, Comparator.GreaterThan);

            default:
                if (lhs)
                {
                    return lhs.specification.type.compare(lhs, rhs, comparator);
                }
                else
                {
                    return false;
                }
        }
    }

    static compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return DataObject.innerCompute(lhs, rhs, operator);
    }

    static getOperatorOverload(lhsType: DataObjectType,
                               rhsType: DataObjectType,
                               operator: MathematicalOperator,
                               getLhsOverload: boolean)
    {
        if (getLhsOverload)
        {
            return lhsType.operatorOverloads()
                .find(
                    overload =>
                        overload.operator === operator
                        && overload.isCompatible(rhsType)
                        && (overload.type === DataObjectOverloadType.Symmetric || overload.type === DataObjectOverloadType.Lhs));
        }
        else
        {
            return rhsType.operatorOverloads()
                .find(
                    overload =>
                        overload.operator === operator
                        && overload.isCompatible(lhsType)
                        && (overload.type === DataObjectOverloadType.Symmetric || overload.type === DataObjectOverloadType.Rhs));
        }
    }

    static getComputationOutputType(lhsType: DataObjectType,
                                    rhsType: DataObjectType,
                                    operator: MathematicalOperator)
    {
        if (lhsType != null)
        {
            let lhsOverload =
                DataObject.getOperatorOverload(
                    lhsType,
                    rhsType,
                    operator,
                    true);

            if (lhsOverload)
            {
                return lhsOverload.outputType(
                    lhsType,
                    rhsType,
                    true);
            }
        }

        if (rhsType != null)
        {
            let rhsOverload =
                DataObject.getOperatorOverload(
                    lhsType,
                    rhsType,
                    operator,
                    false);

            if (rhsOverload)
            {
                return rhsOverload.outputType(
                    rhsType,
                    lhsType,
                    false);
            }
        }

        if (lhsType === undefined && rhsType === undefined)
        {
            return undefined;
        }
        else if (lhsType !== undefined)
        {
            return lhsType;
        }
        else
        {
            return rhsType;
        }
    }

    static innerCompute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        let lhsType = lhs && lhs.specification.type;
        let rhsType = rhs && rhs.specification.type;

        if (lhsType != null)
        {
            let lhsOverload =
                DataObject.getOperatorOverload(
                    lhsType,
                    rhsType,
                    operator,
                    true);

            if (lhsOverload)
            {
                return lhsOverload.compute(
                    lhs,
                    rhs,
                    true,
                    lhsOverload.outputType(
                        lhs.type,
                        rhs.type,
                        true));
            }
        }

        if (rhsType != null)
        {
            let rhsOverload =
                DataObject.getOperatorOverload(
                    lhsType,
                    rhsType,
                    operator,
                    false);

            if (rhsOverload)
            {
                return rhsOverload.compute(
                    rhs,
                    lhs,
                    false,
                    rhsOverload.outputType(
                        rhs.type,
                        lhs.type,
                        false));
            }
        }

        if (lhsType == null && rhsType == null)
        {
            return null;
        }
        else if (lhsType != null)
        {
            return lhsType.compute(lhs, rhs, operator);
        }
        else
        {
            return rhsType.compute(lhs, rhs, operator);
        }
    }

    static compareTo(lhs: DataObject, rhs: DataObject, isAscending: boolean): 1 | 0 | -1
    {
        let outcome =
            DataObject.compare(lhs, rhs, Comparator.Equals)
                ?
                    0
                :
                    (DataObject.compare(lhs, rhs, Comparator.GreaterThan) ? 1 : -1);

        if (!isAscending)
        {
            outcome *= -1;
        }

        return outcome as any;
    }

    static sort(dataObjects: DataObject[], isAscending: boolean): DataObject[]
    {
        let sortedDataObjects = dataObjects.slice();

        sortedDataObjects.sort(
            (lhs, rhs) =>
                DataObject.compareTo(
                    lhs,
                    rhs,
                    isAscending));

        return sortedDataObjects;
    }

    static aggregate(dataObjectStore: DataObjectStore,
                     dataObjects: DataObject[],
                     aggregate: Aggregate): DataObject
    {
        switch (aggregate)
        {
            case Aggregate.Count:
                return DataObject.count(dataObjectStore, dataObjects);

            case Aggregate.Sum:
                return DataObject.sum(dataObjectStore, dataObjects);

            case Aggregate.Average:
                return DataObject.average(dataObjectStore, dataObjects);

            case Aggregate.Min:
                return DataObject.min(dataObjectStore, dataObjects);

            case Aggregate.Max:
                return DataObject.max(dataObjectStore, dataObjects);

            default:
                throw new Error(`Unsupported aggregate: ${Aggregate[aggregate]}`);
        }
    }

    static count(dataObjectStore: DataObjectStore,
                 dataObjects: DataObject[]): DataObject
    {
        return DataObject.constructFromValue(
            new DataObjectSpecification(
                dataObjectStore.getTypeById('Number'),
                {}),
            dataObjects.length);
    }

    static sum(dataObjectStore: DataObjectStore,
               dataObjects: DataObject[]): DataObject
    {
        if (dataObjects.length > 0)
        {
            let sum = dataObjects[0];

            for (let i = 1; i < dataObjects.length; i++)
            {
                sum =
                    DataObject.compute(
                        sum,
                        dataObjects[i],
                        MathematicalOperator.Add);
            }

            return sum;
        }
        else
        {
            return null;
        }
    }

    static average(dataObjectStore: DataObjectStore,
                   dataObjects: DataObject[]): DataObject
    {
        const sum = DataObject.sum(dataObjectStore, dataObjects);
        const count = DataObject.count(dataObjectStore, dataObjects);

        if (dataObjects.length > 0 && sum && count)
        {
            return DataObject.compute(
                sum,
                count,
                MathematicalOperator.Divide);
        }
        else
        {
            return null;
        }
    }

    static min(dataObjectStore: DataObjectStore,
               dataObjects: DataObject[]): DataObject
    {
        if (dataObjects.length > 0)
        {
            let min = dataObjects[0];

            dataObjects.forEach(
                value =>
                {
                    if (DataObject.compare(value, min, Comparator.LessThan))
                    {
                        min = value;
                    }
                });

            return min;
        }
        else
        {
            return null;
        }
    }

    static max(dataObjectStore: DataObjectStore,
               dataObjects: DataObject[]): DataObject
    {
        if (dataObjects.length > 0)
        {
            let max = dataObjects[0];

            dataObjects.forEach(
                value =>
                {
                    if (DataObject.compare(value, max, Comparator.GreaterThan))
                    {
                        max = value;
                    }
                });

            return max;
        }
        else
        {
            return null;
        }
    }

    static incrementalAggregate(dataObjectStore: DataObjectStore,
                                aggregate: Aggregate,
                                originalValue: DataObject,
                                originalCount: number,
                                newValue: DataObject): DataObject
    {
        switch (aggregate)
        {
            case Aggregate.Count:
                return DataObject.compute(
                    originalValue,
                    DataObject.constructFromValue(originalValue.specification, 1),
                    MathematicalOperator.Add);

            case Aggregate.Average:
                return DataObject.compute(
                    DataObject.compute(
                        DataObject.compute(
                            originalValue,
                            DataObject.constructFromValue(originalValue.specification, originalCount),
                            MathematicalOperator.Multiply),
                        newValue,
                        MathematicalOperator.Add),
                    DataObject.constructFromValue(originalValue.specification, originalCount + 1),
                    MathematicalOperator.Divide);

            case Aggregate.Sum:
                return DataObject.compute(originalValue, newValue, MathematicalOperator.Add);

            default:
                return DataObject.aggregate(dataObjectStore, [ originalValue, newValue ], aggregate);
        }
    }

    static decrementalAggregate(dataObjectStore: DataObjectStore,
                                aggregate: Aggregate,
                                originalValue: DataObject,
                                originalCount: number,
                                newValue: DataObject): DataObject
    {
        switch (aggregate)
        {
            case Aggregate.Count:
                return DataObject.compute(
                    originalValue,
                    DataObject.constructFromValue(originalValue.specification, 1),
                    MathematicalOperator.Subtract);

            case Aggregate.Average:
                return DataObject.compute(
                    DataObject.compute(
                        DataObject.compute(
                            originalValue,
                            DataObject.constructFromValue(originalValue.specification, originalCount),
                            MathematicalOperator.Multiply),
                        newValue,
                        MathematicalOperator.Subtract),
                    DataObject.constructFromValue(originalValue.specification, originalCount - 1),
                    MathematicalOperator.Divide);

            case Aggregate.Sum:
                return DataObject.compute(originalValue, newValue, MathematicalOperator.Subtract);

            default:
                return null;
        }
    }

    static constructFromDescriptor(descriptor: any,
                                   dataObjectStore: DataObjectStore = loadModuleDirectly(DataObjectStore),
                                   context?: DataObjectContext): DataObject
    {
        const type = dataObjectStore.getTypeById(descriptor.type);

        if (type)
        {
            const specification =
                new DataObjectSpecification(
                    type,
                    descriptor.specification || {});

            return new DataObject(
                specification,
                DataDescriptor.construct(
                    descriptor.data,
                    specification),
                context);
        }
        else
        {
            return null;
        }
    }

    static constructFromValue(specification: DataObjectSpecification,
                              value: any,
                              context?: DataObjectContext): DataObject
    {
        return new DataObject(
            specification,
            value,
            context);
    }

    static constructFromTypeIdAndValue(typeId: string,
                                       value: any,
                                       dataObjectStore: DataObjectStore = loadModuleDirectly(DataObjectStore),
                                       context?: DataObjectContext): DataObject
    {
        return DataObject.constructFromValue(
            new DataObjectSpecification(
                dataObjectStore.getTypeById(typeId),
                {}),
            value,
            context);
    }

    static constructFromTypeAndValue(type: DataObjectType,
                                     value: any,
                                     context?: DataObjectContext): DataObject
    {
        return DataObject.constructFromValue(
            new DataObjectSpecification(
                type,
                {}),
            value,
            context);
    }

    static constructFromTypeIdAndData(typeId: string,
                                      data: DataDescriptor,
                                      dataObjectStore: DataObjectStore,
                                      context?: DataObjectContext): DataObject
    {
        return new DataObject(
            new DataObjectSpecification(
                dataObjectStore.getTypeById(typeId),
                {}),
            data,
            context);
    }

    static getTypeById(typeId: string,
                       dataObjectStore: DataObjectStore = loadModuleDirectly(DataObjectStore)): DataObjectType
    {
        return dataObjectStore.getTypeById(typeId);
    }

    static new(dataObjectType: DataObjectType,
               context?: DataObjectContext): DataObject
    {
        return new DataObject(
            new DataObjectSpecification(dataObjectType, {}),
            new DataDescriptor(),
            context);
    }

    clone(): DataObject
    {
        const clone = new DataObject(this.specification, this.value, this.context);

        if (this.isInitialized && !clone.isInitialized)
        {
            clone.isInitialized = true;
        }

        // clone.isInitialized = this.isInitialized;
        // clone.value = this.value;

        return clone;
    }

    getValue(): any
    {
        return this.value;
    }

    getEnclosingInterval(): DataObject
    {
        if (this.specification.type.constructEnclosingInterval)
        {
            let enclosingInterval = new DataObject(this.specification, new DataDescriptor());
            this.specification.type.constructEnclosingInterval(this, enclosingInterval);

            if (!enclosingInterval.isEmpty)
            {
                return enclosingInterval;
            }
        }

        return null;
    }

    getEnclosedInterval(): DataObject
    {
        if (this.specification.type.constructEnclosedInterval)
        {
            let enclosedInterval = new DataObject(this.specification, new DataDescriptor());
            this.specification.type.constructEnclosedInterval(this, enclosedInterval);

            if (!enclosedInterval.isEmpty)
            {
                return enclosedInterval;
            }
        }

        return null;
    }

    constructRange(from: DataObject,
                   interval: DataObject,
                   dataObjectStore: DataObjectStore): DataObject
    {
        if (this.specification.type.rangeTypeId)
        {
            let rangeType = dataObjectStore.getTypeById(this.specification.type.rangeTypeId());
            let range = DataObject.new(rangeType);

            if (from.isEmpty || interval.isEmpty)
            {
                return null;
            }
            else
            {
                this.specification.type.constructRange(from, interval, range);

                return range;
            }
        }
        else
        {
            return null;
        }
    }

    toCurrencyString(currency?: string)
    {
        return this.toString(
            currency
                ? new DataObjectRepresentation({currency: currency})
                : undefined
        )
    }

    toString(representation?: DataObjectRepresentation,
             context?: DataObjectContext)
    {
        if (this.value == null)
        {
            return '';
        }
        else
        {
            return this.specification.type.getString(
                this.value,
                representation || new DataObjectRepresentation({}),
                context || this.context,
                this) || '';
        }
    }

    descriptor(onFile?: FileReporter)
    {
        return {
            type: this.specification.type.id(),
            specification: this.specification.data,
            data: this.data.descriptor(onFile)
        };
    }

    // ----------------------- Private logic ------------------------
}

registerBuilder(DataObject)
    .includeAll();
