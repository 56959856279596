import React, { useCallback, useMemo } from 'react';
import { ContentProps } from '../../../Content';
import { TableCell, TableRow } from '@material-ui/core';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import Selectbox from '../../../../../Selectbox/Selectbox';
import { useComputed } from 'mobx-react-lite';
import { EntitySelectionBuilder } from '../../../../../Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import { updateRelationship } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { default as EntityInput } from '../../../../../Input/Input';
import DeleteIconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import useFetchedRelatedEntity from '../../../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity';
import { getEmailAddressesByEntity } from '../../../../../../../../@Api/Entity/Bespoke/EmailAddress/getEmailAddressesByEntity';


export interface SignerRowProps extends ContentProps
{
    readOnly?: boolean;
}

export const SignerRow: React.FC<SignerRowProps> =
    ( props ) =>
    {
        const types = useTypes();
        const entity = props.entity;

        const [ recipient ] =
            useFetchedRelatedEntity(
                entity,
                types.DigitalSigner.RelationshipDefinition.Relationship,
                false,
                props.commitContext
            );

        const recipientSelections =
            useComputed(
                () => [
                    new EntitySelectionBuilder(types.Relationship.Type)
                        .join(
                            EntityPath.fromEntityType(types.Relationship.Type)
                                .castTo(types.Relationship.Person.Type)
                                .joinTo(types.Relationship.Person.RelationshipDefinition.Person, false))
                        .join(
                            EntityPath.fromEntityType(types.Relationship.Type)
                                .castTo(types.Relationship.Organization.Type)
                                .joinTo(types.Relationship.Organization.RelationshipDefinition.Organization, false))
                        .selection
                ],
                [
                    types
                ]);

        const recipientSearchFieldPaths =
            useMemo(
                () => [
                    EntityPath.fromEntityType(types.Relationship.Type)
                        .castTo(types.Relationship.Person.Contact.Type)
                        .field(types.Relationship.Person.Contact.Field.EmailAddress),
                    EntityPath.fromEntityType(types.Relationship.Type)
                        .castTo(types.Relationship.Organization.Type)
                        .joinTo(
                            types.Relationship.Organization.RelationshipDefinition.Organization,
                            false)
                        .field(types.Relation.Organization.Field.EmailAddress),
                    EntityPath.fromEntityType(types.Relationship.Type)
                        .castTo(types.Relationship.Person.Type)
                        .joinTo(
                            types.Relationship.Person.RelationshipDefinition.Person,
                            false)
                        .field(types.Relation.Person.Field.EmailAddress)
                ],
                [
                    types
                ]);

        const onChange =
            useCallback(
                (selectedEntity: Entity) =>
                {
                    const splitName = selectedEntity.name.split(' ');
                    splitName.push(' ');

                    entity.setValueByField(
                        types.DigitalSigner.Field.FirstName,
                        splitName[0],
                        false,
                        false,
                        props.commitContext
                    );

                    entity.setValueByField(
                        types.DigitalSigner.Field.LastName,
                        splitName.splice(1).join(' ').trim(),
                        false,
                        false,
                        props.commitContext
                    );

                    const emailAddresses =
                        getEmailAddressesByEntity(
                            selectedEntity,
                            props.commitContext
                        );

                    entity.setValueByField(
                        types.DigitalSigner.Field.EmailAddress,
                        emailAddresses.length > 0 ? emailAddresses[0].emailAddress : undefined,
                        false,
                        false,
                        props.commitContext
                    );

                    updateRelationship(
                        entity,
                        false,
                        types.DigitalSigner.RelationshipDefinition.Relationship,
                        selectedEntity,
                        props.commitContext
                    );

                },
                [
                    entity,
                    props.commitContext,
                    types
                ]
            );

        const onDelete =
            useCallback(
                () => {
                    if (props.onDelete)
                    {
                        props.onDelete(entity)
                    }
                },
                [
                    props.onDelete,
                    entity
                ]
            )
        return <TableRow>
            <TableCell>
                <Selectbox
                    selections={recipientSelections}
                    onChange={onChange}
                    value={recipient}
                    searchFieldPaths={recipientSearchFieldPaths}
                    commitContext={props.commitContext}
                    clearable={false}
                    genericSelectboxProps={{
                        disabled: props.readOnly
                    }}
                />
            </TableCell>
            <TableCell>
                <EntityInput
                    entity={entity}
                    field={types.DigitalSigner.Field.EmailAddress}
                    labelPosition="none"
                    touched={props.touched}
                    commitContext={props.commitContext}
                    compact
                    doAutocommit={props.autoCommit}
                    disabled={props.readOnly}
                />
            </TableCell>
            <TableCell>
                <EntityInput
                    entity={entity}
                    field={types.DigitalSigner.Field.FirstName}
                    labelPosition="none"
                    touched={props.touched}
                    commitContext={props.commitContext}
                    compact
                    doAutocommit={props.autoCommit}
                    disabled={props.readOnly}
                />
            </TableCell>
            <TableCell>
                <EntityInput
                    entity={entity}
                    field={types.DigitalSigner.Field.LastName}
                    labelPosition="none"
                    touched={props.touched}
                    commitContext={props.commitContext}
                    compact
                    doAutocommit={props.autoCommit}
                    disabled={props.readOnly}
                />
            </TableCell>
            <TableCell
                align="center"
            >
                <EntityInput
                    entity={entity}
                    field={types.DigitalSigner.Field.CanChangeSigner}
                    labelPosition="none"
                    touched={props.touched}
                    commitContext={props.commitContext}
                    compact
                    doAutocommit={props.autoCommit}
                    disabled={props.readOnly}
                />
            </TableCell>
            <TableCell>
                <EntityInput
                    entity={entity}
                    field={types.DigitalSigner.RelationshipDefinition.Phase}
                    labelPosition="none"
                    touched={props.touched}
                    commitContext={props.commitContext}
                    disabled
                    compact
                />
            </TableCell>
            <TableCell>
                {
                    !props.readOnly &&
                    <DeleteIconButton
                        onClick={onDelete}
                    />
                }
            </TableCell>
        </TableRow>;
    };