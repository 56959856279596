import React, { useContext } from 'react';
import useTypes from '../../../../../../../../../@Component/Domain/Entity/Type/Api/useTypes';
import { observer } from 'mobx-react-lite';
import useEntityByUuid from '../../../../../../../../../@Api/Entity/Bespoke/useEntityByUuid';
import useEntityValue from '../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import DocumentEditor from '../../../../DocumentEditor';
import DocumentContext from '../../../../DocumentContext';
import styles from './TemplateViewer.module.scss';

export interface TemplateViewerProps
{
    templateId: string;
}

const TemplateViewer: React.FC<TemplateViewerProps> =
    props =>
    {
        const types = useTypes();
        const [ template ] =
            useEntityByUuid(
                types.Template.Block.Type,
                props.templateId);

        const content =
            useEntityValue(
                template,
                types.Template.Field.Content);

        const documentContext = useContext(DocumentContext);

        if (content)
        {
            return <div
                className={styles.root}
            >
                <DocumentEditor
                    type="gutenberg"
                    definition={content as any}
                    blockTypes={documentContext.blockTypes as any}
                    onChange={(() => {}) as any}
                    editLayoutMode={false}
                />
            </div>;
        }
        else
        {
            return null;
        }
    };

export default observer(TemplateViewer);
