import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import LayoutDependencyContext from '../LayoutDependencyContext';
import localizeText from '../../Localization/localizeText';

export default class PortalRouterLayout extends Layout
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.PortalRouter', 'Portaal router');
    }

    validate(): Validation[]
    {
        return [];
    }

    getDependencies(): Dependency[]
    {
        return [];
    }

    toDescriptor()
    {
        return {
            type: 'PortalRouter'
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new PortalRouterLayout();
    }

    // ----------------------- Private logic ------------------------
}
