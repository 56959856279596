import { reference, type } from '../../../@Util/Serialization/Serialization';
import { EntityEventType } from '../../../@Component/Domain/Entity/Event/Model/EntityEventType';
import { Entity } from './Entity';
import { User } from './User';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';

@type('EntityEvent')
export class EntityEvent
{
    // ------------------- Persistent Properties --------------------

    id: number;
    @reference(undefined, 'Entity') entity: Entity;
    entityUuid: string;
    @reference(undefined, 'User') user: User;
    ipAddress: string;
    connectionId: string;
    commitId: string;
    isFirstInCommit: boolean;
    isLastInCommit: boolean;
    date: number;
    isHidden: boolean;
    isPinned: boolean;
    pinnedSortIndex: number;

    // ------------------------- Properties -------------------------

    type: EntityEventType;

    // ------------------------ Constructor -------------------------

    constructor(entity: Entity)
    {
        this.entity = entity;
        this.entityUuid = entity && entity.uuid;
    }

    // ----------------------- Initialization -----------------------

    initialize(entityTypeStore: EntityTypeStore,
               doMergeEditableValues: boolean = true)
    {

    }

    postInitialize(entityById: Map<number, Entity>)
    {
        this.entity = entityById.get(this.entity.id);

        if (this.user && this.user.entity)
        {
            this.user.entity = entityById.get(this.user.entity.id);
        }
    }

    getEntitiesToInitialize(): Entity[]
    {
        return [
            this.entity,
            ...this.user && this.user.entity
                ?
                    [ this.user.entity ]
                :
                    []
        ];
    }

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
