import { BaseStore, getOrCompute, PropType } from '../../../../../../@Framework/Store/BaseStore';
import { DataObjectStore } from '../../../DataObjectStore';
import { Alignment, DataObject } from '../../../Model/DataObject';
import { FormHandlerContext } from '../../../../../Generic/Form/FormHandlerContext';
import { FormEventListener } from '../../../../../Generic/Form/FormEventListener';
import { FormEvent } from '../../../../../Generic/Form/FormEvent';
import { action, computed, observable, untracked } from 'mobx';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import { DataObjectBespokeEditorStore } from '../Bespoke/DataObjectBespokeEditorStore';
import { TextStore } from '../../../../../Generic/Text/TextStore';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { CSSProperties } from 'react';
import { DataObjectRepresentation, DataObjectRepresentationProps } from '../../../Model/DataObjectRepresentation';
import { FormHandlerProperties } from '../../../../../Generic/Form/FormHandlerProperties';

export interface DataObjectEditorProps
{
    dataObject: PropType<DataObjectEditorStore, DataObjectEditorProps, DataObject>;
    isDisabled?: PropType<DataObjectEditorStore, DataObjectEditorProps, boolean>;
    onChange?: (value: DataObject) => void;
    onEnterPressed?: () => void;
    handlerContext?: PropType<DataObjectEditorStore, DataObjectEditorProps, FormHandlerContext<DataObject>>;
    isFocused?: PropType<DataObjectEditorStore, DataObjectEditorProps, boolean>;
    isLabelOnSide?: PropType<DataObjectEditorStore, DataObjectEditorProps, boolean>;
    hasUnderline?: PropType<DataObjectEditorStore, DataObjectEditorProps, boolean>;
    alignment?: PropType<DataObjectEditorStore, DataObjectEditorProps, Alignment>;
    inputStyle?: PropType<DataObjectEditorStore, DataObjectEditorProps, CSSProperties>;
    hasTopBorder?: PropType<DataObjectEditorStore, DataObjectEditorProps, boolean>;
    isLabelOnTop?: PropType<DataObjectEditorStore, DataObjectEditorProps, boolean>;
    placeholder?: PropType<DataObjectEditorStore, DataObjectEditorProps, string>;
    isPassword?: PropType<DataObjectEditorStore, DataObjectEditorProps, boolean>;
    formFieldName?: PropType<DataObjectEditorStore, DataObjectEditorProps, string>;
    representation?: PropType<DataObjectEditorStore, DataObjectEditorProps, DataObjectRepresentation<DataObjectRepresentationProps>>;
    label?: PropType<DataObjectEditorStore, DataObjectEditorProps, string>;
}

const defaultProps: Partial<DataObjectEditorProps> =
{
    isDisabled: false,
    isFocused: false,
    isLabelOnSide: false,
    hasUnderline: false,
    hasTopBorder: false,
    isLabelOnTop: false,
    isPassword: false,
    representation: new DataObjectRepresentation({})
};

export class DataObjectEditorStore<T extends DataObjectBespokeEditorStore = DataObjectBespokeEditorStore> extends BaseStore<DataObjectEditorProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier("LocalizationStore")
    localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable isHovering: boolean;
    @observable renderDirectionUp: boolean;
    @observable isTouched: boolean;

    // ------------------------ Constructor -------------------------

    constructor(props: DataObjectEditorProps)
    {
        super(
            props,
            defaultProps,
            () =>
                !(
                    getOrCompute(undefined, props.dataObject).isInitialized ||
                    getOrCompute(undefined, props.dataObject).isEmpty ||
                    !getOrCompute(undefined, props.dataObject).specification.type.requiresInitialization(
                        // Use untracked here because otherwise every time the value changes,
                        // the editor store is recomputed
                        untracked(
                            () =>
                                getOrCompute(undefined, props.dataObject).value)
                    )
                )
        );

        // The default props are shared between all instances, and we do not want the HandlerContext to be shared
        if (!this.props.handlerContext)
        {
            this.props.handlerContext = new FormHandlerContext<DataObject>();
        }

        if (this.props.onChange)
        {
            this.handlerContext.listen(
                new FormEventListener<DataObject>(
                    FormEvent.Change, () =>
                        this.props.onChange(this.dataObject)));
        }

        this.handlerContext.listen(
            new FormEventListener<DataObject>(
                FormEvent.Focus,
                () =>
                    this.focus()));

        this.handlerContext.listen(
            new FormEventListener<DataObject>(
                FormEvent.Blur,
                () =>
                    this.blur()));

        this.handlerContext.listen(
            new FormEventListener<DataObject>(
                FormEvent.KeyUp,
                event =>
                {
                    if (event.event.keyCode === 13)
                    {
                        this.enterPressed();
                    }
                }));
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        if (this.dataObject.isInitialized ||
            this.dataObject.isEmpty ||
            !this.dataObject.specification.type.requiresInitialization(this.dataObject.value))
        {
            return Promise.resolve();
        }
        else
        {
            return this.dataObjectStore.initializeDataObjects([ this.dataObject ]);
        }
    }

    entersUI(isMounted: boolean)
    {
        super.entersUI(isMounted);

        if (this.bespokeStore)
        {
            this.bespokeStore.entersUI(isMounted);
        }
    }

    // -------------------------- Computed --------------------------

    @computed
    get dataObject(): DataObject
    {
        return getOrCompute(this, this.props.dataObject);
    }

    @computed
    get label(): string
    {
        if (!this.isLabelOnSide)
        {
            return getOrCompute(this, this.props.label);
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get hasLabel()
    {
        return this.label && this.label.length > 0;
    }

    @computed
    get sideLabel(): string
    {
        if (this.isLabelOnSide)
        {
            return getOrCompute(this, this.props.label);
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get isLabelOnSide(): boolean
    {
        return getOrCompute(this, this.props.isLabelOnSide);
    }

    @computed
    get hasUnderline(): boolean
    {
        return getOrCompute(this, this.props.hasUnderline);
    }

    @computed
    get isDisabled(): boolean
    {
        return getOrCompute(this, this.props.isDisabled);
    }

    @computed
    get handlerContext(): FormHandlerContext<DataObject>
    {
        return getOrCompute(this, this.props.handlerContext);
    }

    @computed
    get handlerProperties(): FormHandlerProperties<DataObject>
    {
        return new FormHandlerProperties(this.handlerContext, this.dataObject);
    }

    @computed
    get isFocused(): boolean
    {
        return getOrCompute(this, this.props.isFocused);
    }

    @computed
    get alignment(): Alignment
    {
        return getOrCompute(this, this.props.alignment);
    }

    @computed
    get alignmentAsString()
    {
        switch (this.alignment)
        {
            case Alignment.Center:
                return 'center';

            case Alignment.Right:
                return 'right';

            default:
            case Alignment.Left:
                return 'left';
        }
    }

    @computed
    get inputStyle(): CSSProperties
    {
        return getOrCompute(this, this.props.inputStyle);
    }

    @computed
    get hasTopBorder(): boolean
    {
        return getOrCompute(this, this.props.hasTopBorder);
    }

    @computed
    get isLabelOnTop(): boolean
    {
        return getOrCompute(this, this.props.isLabelOnTop);
    }

    @computed
    get isPassword(): boolean
    {
        return getOrCompute(this, this.props.isPassword);
    }

    @computed
    get formFieldName(): string
    {
        return getOrCompute(this, this.props.formFieldName);
    }

    @computed
    get placeholder(): string
    {
        return getOrCompute(this, this.props.placeholder);
    }

    @computed
    get representation(): DataObjectRepresentation<DataObjectRepresentationProps>
    {
        return getOrCompute(this, this.props.representation);
    }

    @computed
    get bespokeStore(): T
    {
        if (this.dataObject.specification.type.editorStore)
        {
            return this.dataObject.specification.type.editorStore(this) as T;
        }
        else
        {
            return new DataObjectBespokeEditorStore(this) as T;
        }
    }

    @computed
    get isValid(): boolean
    {
        return this.dataObject.isValid;
    }

    @computed
    get isRequired(): boolean
    {
        return this.dataObject.specification.isRequired;
    }

    @computed
    get alignmentStyle()
    {
        return this.alignment === undefined || this.alignment === Alignment.Left
            ?
                "left"
            :
                this.alignment === Alignment.Center
                    ?
                        "center"
                    :
                        "right";
    }

    @computed
    get flexAlignmentStyle()
    {
        return this.alignment === undefined
            ?
                undefined
            :
                this.alignment === Alignment.Left
                    ?
                        "flex-start"
                    :
                        this.alignment === Alignment.Center
                            ?
                                "center"
                            :
                                "flex-end";
    }

    @computed
    get showUnderline(): boolean
    {
        return this.hasUnderline ||
            (!this.isDisabled && (this.isFocused || this.isHovering));
    }

    @computed
    get showError(): boolean
    {
        return this.isTouched && !this.isValid;
    }

    @computed
    get handlers()
    {
        return new FormHandlerProperties(
            this.handlerContext,
            this.dataObject);
    }

    // --------------------------- Stores ---------------------------

    @computed
    get labelStore(): TextStore
    {
        return new TextStore({
            label: this.labelText,
            variant:
                this.isLabelOnTop
                    ?
                        undefined
                    :
                        "body2",
            size:
                this.isLabelOnTop
                    ?
                        13
                    :
                        undefined,
            color:
                () =>
                    this.isFocused || this.isHovering
                        ?
                            "primary"
                        :
                            "secondary"
        });
    }

    @computed
    get labelText(): string
    {
        return this.sideLabel || this.label;
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    setFocused(isFocused: boolean)
    {
        this.props.isFocused = isFocused;
    }

    @action.bound
    changeValue(value: any,
                doFireChangeEvent: boolean = true,
                event?: any)
    {
        this.dataObject.setValue(value);
        this.touch();

        if (doFireChangeEvent)
        {
            this.handlerContext.perform(
                new FormEvent<DataObject>(
                    FormEvent.Change,
                    this.dataObject,
                    event));
        }
    }

    @action.bound
    touch()
    {
        this.isTouched = true;
        return this;
    }

    @action.bound
    focus()
    {
        this.props.isFocused = true;
    }

    @action.bound
    blur()
    {
        this.props.isFocused = false;
        this.touch();
    }

    @action.bound
    enterPressed()
    {
        if (this.props.onEnterPressed)
        {
            this.props.onEnterPressed();
        }
    }

    @action.bound
    startHovering()
    {
        this.isHovering = true;
    }

    @action.bound
    stopHovering()
    {
        this.isHovering = false;
    }

    @action.bound
    setRenderDirectionUp()
    {
        this.renderDirectionUp = true;
    }

    @action.bound
    setRenderDirectionDown()
    {
        this.renderDirectionUp = false;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
