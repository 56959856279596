import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import PrimitiveFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/PrimitiveFormInputLayout';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { runInAction } from 'mobx';
import Switch from '../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import FormInputPlaceholderEditor from '../Shared/FormInputPlaceholderEditor/FormInputPlaceholderEditor';
import FormInputIsDisabledEditor from '../Shared/FormInputIsDisabledEditor/FormInputIsDisabledEditor';
import FormInputEventTriggersEditor from '../Shared/FormInputEventTriggersEditor/FormInputEventTriggersEditor';
import FormInputParameterViewer from '../Shared/FormInputParameterViewer/FormInputParameterViewer';

export interface PrimitiveFormInputControlsProps extends LayoutEditorProps<PrimitiveFormInputLayout>
{

}

const PrimitiveFormInputLayoutControls: React.FC<PrimitiveFormInputControlsProps> =
    props =>
    {
        const setIntrinsicSize =
            useCallback(
                (hasIntrinsicSize: boolean) =>
                    runInAction(
                        () =>
                            props.layout.hasIntrinsicSize = hasIntrinsicSize),
                [
                    props.layout
                ]);

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <FormInputParameterViewer
                    {...props}
                />
                <FormInputPlaceholderEditor
                    {...props}
                />
                <FormInputIsDisabledEditor
                    {...props}
                />
                <FormInputEventTriggersEditor
                    {...props}
                />
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.hasIntrinsicSize"
                            value="Is net zo groot als inhoud"
                        />
                    }
                >
                    <Switch
                        checked={props.layout.hasIntrinsicSize}
                        onToggle={setIntrinsicSize}
                    />
                </Input>
            </InputGroup>
        </CardInset>;
    };

export default observer(PrimitiveFormInputLayoutControls);
