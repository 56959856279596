import Layout from '../Layout';
import { observable } from 'mobx';
import Dimension from '../Style/Dimension';

export default abstract class ImageLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref width: Dimension | undefined;
    @observable.ref height: Dimension | undefined;

    // ------------------------ Constructor -------------------------

    constructor(width: Dimension | undefined,
                height: Dimension | undefined)
    {
        super();

        this.width = width;
        this.height = height;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
