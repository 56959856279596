import * as React from 'react';
import { DataObjectBespokeEditor } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { observer } from 'mobx-react';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { TimeTextEditorStore } from './TimeTextEditorStore';
import TimePicker from '../../../../../../@Future/Component/Generic/Input/TimePicker/TimePicker';
import { getHourAndMinuteFromValue } from './Api/getHourAndMinuteFromValue';
import { getTimeAsString } from './Api/getTimeAsString';

@observer
export default class TimeTextEditor extends DataObjectBespokeEditor<TimeTextEditorStore>
{
    renderComponent(store: DataObjectEditorStore<TimeTextEditorStore>): any
    {
        const [ hour, minute ] = getHourAndMinuteFromValue(store.dataObject.value);

        return <TimePicker
            hour={hour}
            minute={minute}
            onChange={
                (hour, minute) =>
                    store.changeValue(
                        getTimeAsString(hour, minute)
                    )
            }
            inputProps={{
                ...store.handlerProperties as any,
            }}
            disableUnderline={!store.showUnderline}
            disabled={store.isDisabled}
        />;
    }
}
