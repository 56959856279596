import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import CompositeLayout from '../../../../../../@Api/Layout/Type/CompositeLayout';
import { LayoutViewerProps } from '../../../Viewer/LayoutViewer';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CompositeLayoutItemViewer from './Item/CompositeLayoutItemViewer';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import useResizeObserver from 'use-resize-observer/polyfilled';

export interface CompositeLayoutViewerProps extends LayoutViewerProps<CompositeLayout>
{

}

const CompositeLayoutViewer: React.FC<CompositeLayoutViewerProps> =
    props =>
    {
        const dividerGlue = useDividerGlue();

        const { ref, width } = useResizeObserver<HTMLDivElement>();

        const orientation =
            useComputed(
                () =>
                {
                    if (props.layout.orientation === 'Horizontal'
                        && props.layout.items.length > 0
                        && !props.layout.isWrapped
                        && width / props.layout.items.length < 300)
                    {
                        return 'vertical';
                    }
                    else
                    {
                        return props.layout.orientation === 'Vertical' ? 'vertical' : 'horizontal';
                    }
                },
                [
                    props.layout,
                    width
                ]);

        return <div
            ref={ref}
        >
            <ViewGroup
                orientation={orientation}
                spacing={props.layout.spacing}
                glue={props.layout.hasDivider ? dividerGlue : undefined}
                wrap={props.layout.isWrapped}
            >
                {
                    props.layout.items.map(
                        item =>
                            <ViewGroupItem
                                key={item.id}
                                ratio={item.ratio}
                            >
                                <CompositeLayoutItemViewer
                                    {...props}
                                    item={item}
                                />
                            </ViewGroupItem>)
                }
            </ViewGroup>
        </div>;
    };

export default observer(CompositeLayoutViewer);
