import { useEffect, useState } from 'react';

export default function useClock(isActive: boolean = true): Date
{
    const [ now, setNow ] = useState(() => new Date());

    useEffect(
        () =>
        {
            if (isActive)
            {
                const interval =
                    setInterval(
                        () =>
                        {
                            setNow(new Date());
                        },
                        500);

                return () =>
                    clearInterval(interval)
            }
        },
        [
            isActive,
            setNow
        ]);

    return now;
}
