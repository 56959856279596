import { BaseDialogProps, BaseDialogStore } from '../BaseDialogStore';

const defaultProps: BaseDialogProps =
{
    isFullWidth: false,
    isModal: true,
};

export class ModalDialogStore extends BaseDialogStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: BaseDialogProps)
    {
        super(props, defaultProps);
    }

    /*constructor(title: string,
                message: string,
                buttons: ButtonStore[],
                onClose: () => void,
                viewComponent?: ViewComponent<any>,
                open?: boolean,
                icon?: string,
                subTitle?: string)
    {
        super(title, buttons, onClose, viewComponent, false, open, true, subTitle, undefined, icon);
    }*/

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
