import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { OfferSigningDescriptor } from '../../Model/Implementation/OfferSigningDescriptor';

export class OfferEntityPublicController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    signDocument(token: string, id: number, title: string, firstName: string, middleName: string, lastName: string, signature: any, emailAddress: string, note: string): Promise<OfferSigningDescriptor>
    {
            return new Promise<OfferSigningDescriptor>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/public/entity/bespoke/offer/sign', Method.Post, { token: token, id: id, title: title, first_name: firstName, middle_name: middleName, last_name: lastName, signature: signature, email_address: emailAddress, note: note }))
                    .then(result =>
                    {
                        resolve(fromJson(result, OfferSigningDescriptor));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    rejectDocument(token: string, id: number, title: string, firstName: string, middleName: string, lastName: string, emailAddress: string, note: string): Promise<OfferSigningDescriptor>
    {
            return new Promise<OfferSigningDescriptor>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/public/entity/bespoke/offer/reject', Method.Post, { token: token, id: id, title: title, first_name: firstName, middle_name: middleName, last_name: lastName, email_address: emailAddress, note: note }))
                    .then(result =>
                    {
                        resolve(fromJson(result, OfferSigningDescriptor));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    requestChangeDocument(token: string, id: number, title: string, firstName: string, middleName: string, lastName: string, emailAddress: string, note: string): Promise<OfferSigningDescriptor>
    {
            return new Promise<OfferSigningDescriptor>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/public/entity/bespoke/offer/request_change', Method.Post, { token: token, id: id, title: title, first_name: firstName, middle_name: middleName, last_name: lastName, email_address: emailAddress, note: note }))
                    .then(result =>
                    {
                        resolve(fromJson(result, OfferSigningDescriptor));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };

    openDocument(id: number, token: string): Promise<OfferSigningDescriptor>
    {
        return new Promise<OfferSigningDescriptor>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/public/entity/bespoke/offer/open', Method.Get, { id: id, token: token }))
                    .then(result =>
                    {
                        resolve(fromJson(result, OfferSigningDescriptor));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

    /**
     * Returns Promise of contents of OfferSigningBackground image if defined, undefined otherwise
     * Returned value contains contents of image wrapped in 'data:' string which can be used
     * directly in styling with backgroundImage: `url('${contents}')`
     *
     * @param id    EntityId of offer
     * @param token token for viewing OfferSign page
     */
    getBackgroundFile(id: number, token: string): Promise<Blob | undefined>
    {
        return new Promise<Blob | undefined>(
        (resolve, ) =>
            {
                const request = new ApiRequest<Blob>(
                    '/public/entity/bespoke/offer/background',
                    Method.Post,
                    { id: id, token: token },
                );

                request.resultType = 'Blob';

                this.apiClient.request(request)
                    .then(
                        result =>
                        {
                            if (result.size > 0)
                            {
                                resolve(result);
                            }
                            else
                            {
                                resolve(undefined);
                            }
                        }
                    );
            }
        );

    }


}
