import { DialogStore } from '../../../../Generic/Dialog/Type/DialogStore';
import { SideEffectStore } from '../SideEffectStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { ViewComponent } from '../../../../Generic/ViewStack/Model/ViewComponent';
import List from '../../../../Generic/List/V2/List';
import { ListStore } from '../../../../Generic/List/V2/ListStore';
import { v4 as uuid } from 'uuid';
import { ListItemStore } from '../../../../Generic/List/V2/Item/ListItemStore';
import Text from '../../../../Generic/Text/Text';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import localizeText from '../../../../../@Api/Localization/localizeText';

export class SideEffectDialogStore extends DialogStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entity: Entity,
                sideEffects: SideEffectStore[],
                resolve: (doContinue: boolean, dialogStore: DialogStore) => any)
    {
        super({
            title:
                () =>
                    localizeText('ActionStepsToBeExecuted', 'Door deze actie worden de volgende stappen uitgevoerd'),
            viewComponent:
                new ViewComponent(
                    List,
                    new ListStore({
                        id:
                            () => uuid(),
                        load:
                            () => Promise.resolve(sideEffects),
                        construct:
                            item =>
                                new ListItemStore<SideEffectStore>({
                                    template:
                                        new ViewComponent(
                                            Text,
                                            item.description())
                                })
                    })),
            buttons:
            [
                new ButtonStore({
                    label: () => this.localizationStore.translate('Generic.Cancel'),
                    onClick:
                        () =>
                            resolve(false, this),
                }),
                new ButtonStore({
                    label: () => localizeText('Generic.Continue', 'Doorgaan'),
                    isRaised: true,
                    color: 'primary',
                    onClick: () => resolve(true, this)
                })
            ],
            onClose:
                () =>
                    resolve(false, this)
        });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
