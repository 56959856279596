import Validation from '../../../Automation/Validation/Validation';
import Dependency from '../../../Automation/Parameter/Dependency';
import Value from '../../../Automation/Value/Value';
import LayoutAction from '../LayoutAction';
import EmptyValue from '../../../Automation/Value/EmptyValue';
import LayoutDependencyContext from '../../LayoutDependencyContext';
import { observable } from 'mobx';
import Computation from '../../../Automation/Function/Computation/Computation';
import getComputationFromDescriptor from '../../../Automation/Api/getComputationFromDescriptor';
import ValueType from '../../../Automation/Value/Type/ValueType';
import EmptyValueType from '../../../Automation/Value/Type/EmptyValueType';
import localizeText from '../../../Localization/localizeText';
import safelyApplyFunction from '../../../Automation/Api/safelyApplyFunction';
import LayoutContext from '../../LayoutContext';
import PrimitiveValue from '../../../Automation/Value/PrimitiveValue';
import { FileType } from '../../../../@Component/Domain/DataObject/Type/File/FileType';
import { FileValue } from '../../../../@Component/Domain/DataObject/Type/File/FileValue';
import { downloadApiFile } from '../../../../@Service/ApiClient/Hooks/downloadApiFile';

export default class DownloadFileAction extends LayoutAction
{
    // ------------------------- Properties -------------------------

    @observable.ref file: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(route: Computation<any, any>)
    {
        super();

        this.file = route;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async apply(context: LayoutContext): Promise<Value<any, any>>
    {
        const file = await safelyApplyFunction(this.file, context);

        if (file instanceof PrimitiveValue
            && file.value.type instanceof FileType)
        {
            const fileValue: FileValue = file.value.value;
            const fileUrl = file.value.context.getFileUrl(fileValue.url);

            if (fileUrl !== undefined)
            {
                await downloadApiFile(fileUrl);
            }
        }

        return EmptyValue.instance;
    }

    getReturnType(): ValueType<any>
    {
        return EmptyValueType.instance;
    }

    getName(): string
    {
        return localizeText(
            'LayoutAction.DownloadFile',
            'Bestand downloaden'
        );
    }

    getDependencies(): Dependency[]
    {
        return this.file.getDependencies();
    }

    validate(): Validation[]
    {
        return this.file.validate();
    }

    toDescriptor()
    {
        return {
            type: 'DownloadFile',
            file: this.file.toDescriptor(),
        };
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: LayoutDependencyContext
    )
    {
        return new DownloadFileAction(
            await getComputationFromDescriptor(
                descriptor.file,
                dependencyContext
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
