import React from 'react';
import styles from './Phase.module.scss';
import { classNames } from '../../../../Util/Class/classNames';
import Arrow from '../../Arrow/Arrow';
import { primaryColor } from '../../../../../@Resource/Theme/Theme';

export interface PhaseProps
{
    name: React.ReactNode;
    visited?: boolean;
    current?: boolean;
    terminal?: boolean;
    first?: boolean;
    last?: boolean;
    // icon?: string;
    color?: string;
    fontColor?: string;
    className?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onClick?: () => void;
    phaseComponent?: JSX.Element;
}

const Phase: React.FC<PhaseProps> =
    props =>
    {
        const style =
            {
                backgroundColor: props.color,
                color: props.fontColor
            };

        let color = undefined;
        let stroke = undefined;
        let strokeColor = undefined;

        if (props.color)
        {
            color = props.color;
        }
        else if (props.current)
        {
            color = primaryColor;
        }
        else if (props.visited)
        {
            color = 'rgb(147, 206, 149)';
        }
        else if (!props.visited && !props.current)
        {
            // color = 'transparent';
            // stroke = 2;
            // strokeColor = 'rgb(219,219,219)';
            color = 'rgb(245,245,245)';
        }

        return <div
            className={classNames(styles.root, props.onClick && styles.clickable)}
        >
            <div
                className={
                    classNames(
                        styles.inner,
                        props.visited && styles.visited,
                        props.current && styles.current,
                        !props.visited && !props.current && styles.unvisited,
                        // props.first && styles.firstRoot,
                        props.last && styles.lastRoot,
                        props.phaseComponent && styles.hasPhaseComponent,
                        props.color && styles.hasColor,
                        props.className)
                }
                onMouseEnter={props.onMouseEnter ? () => props.onMouseEnter() : undefined}
                onMouseLeave={props.onMouseLeave ? () => props.onMouseLeave() : undefined}
                onClick={props.onClick ? () => props.onClick() : undefined}
            >
                <Arrow
                    color={color}
                    stroke={stroke}
                    strokeColor={strokeColor}
                    start={!props.first ? 'arrow-indent' : 'straight'}
                    // end={props.last && props.phaseComponent ? 'round-indent' : !props.last ? 'arrow' : 'straight'}
                    end={props.last ? 'round-indent' : 'arrow'}
                    renderInBackground={true}
                />
                <div
                    className={styles.name}
                    style={style}
                >
                    {props.name}
                </div>
            </div>
            {
                props.phaseComponent &&
                    <div
                        className={styles.phaseComponentWrapper}
                    >
                        {
                            props.phaseComponent
                        }
                    </div>
            }
        </div>;
    };

export default Phase;
