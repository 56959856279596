import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Selectbox from '../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import useTypes from '../../Type/Api/useTypes';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import localizeText from '../../../../../@Api/Localization/localizeText';
import { augmentDatasetSelectionBuilderWithEmployeeFilter, augmentDatasetSelectionBuilderWithEntityTypeFilter, augmentDatasetSelectionBuilderWithQueryFilter } from "../Api/augmentDatasetSelectionBuilder";
import CurrentUserContext from "../../../User/CurrentUserContext";

export interface DatasetSelectorProps
{
    entityType: EntityType;
    value?: Entity;
    onChange: (value: Entity | undefined) => void;
}

export const DatasetSelector: React.FC<DatasetSelectorProps> = observer(
    ({
        entityType,
        value,
        onChange
     }) =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);

        const loader =
            useCallback(
                (query: string) =>
                    EntitySelectionBuilder.builder(
                        types.Dataset.Type,
                        (builder, ) =>
                        {
                            augmentDatasetSelectionBuilderWithEntityTypeFilter(
                                builder,
                                entityType,
                                true
                            );

                            augmentDatasetSelectionBuilderWithEmployeeFilter(
                                builder,
                                currentUserStore.employeeEntity,
                                true
                            );

                            augmentDatasetSelectionBuilderWithQueryFilter(
                                builder,
                                query
                            );
                        }
                    )
                    .select()
                    .then(
                        async results =>
                        {
                            const newDataset =
                                new Entity(types.Dataset.Type)
                                    .initialize();

                            newDataset.setValueByField(
                                types.Dataset.Field.LocalizedName,
                                `+ ${localizeText('DatasetEditor.NewWidget', 'Nieuwe widget')}`);

                            return Promise.resolve([
                                ...results.map(result => result.entity),
                                newDataset
                            ]);
                        }
                    ),
                [
                    types,
                    entityType,
                    currentUserStore.employeeEntity,
                ]
            );

        const formatter =
            useCallback(
                (dataset: Entity) =>
                    dataset.name,
                []
            );

        const idResolver =
            useCallback(
                (dataset: Entity) =>
                    dataset.uuid,
                []
            );

        return <Selectbox
            load={loader}
            onChange={onChange}
            formatOption={formatter}
            idResolver={idResolver}
            value={value}
        />;
    }
);
