import { EntityType } from '../../../Model/Implementation/EntityType';
import { EntityRelationshipDefinition } from '../../../Model/Implementation/EntityRelationshipDefinition';
import getMetadataSettingValue from '../../getMetadataSettingValue';
import { Setting } from '../../../Settings/Setting';

export default function getRelationshipDefinitionFilterDescriptorFromSetting(entityType: EntityType,
                                                                             relationshipDefinition: EntityRelationshipDefinition,
                                                                             isParent: boolean): any | undefined
{
    const settingValue =
        getMetadataSettingValue(
            entityType,
            isParent
                ? Setting.Metadata.ParentRelationshipDefinitionFilter
                : Setting.Metadata.ChildRelationshipDefinitionFilter);
    if (settingValue)
    {
        return settingValue[relationshipDefinition.id];
    }
    else
    {
        return undefined;
    }
}
