import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { TableLayoutViewerProps } from './TableLayoutViewer';
import TableDimensionInstance from '../../../../../../@Api/Layout/Type/Table/Model/TableDimensionInstance';
import TableCellLocation from '../../../../../../@Api/Layout/Type/Table/Model/TableCellLocation';
import LayoutViewer from '../../../Viewer/LayoutViewer';
import ChildTable from '../../../../../../@Api/Layout/Type/Table/Model/ChildTable';
import LayoutContext from '../../../../../../@Api/Layout/LayoutContext';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';

export interface TableLayoutCellViewerProps extends TableLayoutViewerProps
{
    context: LayoutContext;
    row: TableDimensionInstance;
    column: TableDimensionInstance;
    childTable?: ChildTable;
}

const TableLayoutCellViewer: React.FC<TableLayoutCellViewerProps> =
    props =>
    {
        const {
            context,
            row,
            column,
            layout,
            childTable,
        } = props;
        const location =
            useMemo(
                () =>
                    new TableCellLocation(
                        row.section.id,
                        column.section.id
                    ),
                [
                    row,
                    column
                ]
            );
        const cell =
            useMemo(
                () =>
                    (childTable ?? layout).cellByLocationId.get(location.id),
                [
                    layout,
                    location
                ]
            );
        const childContext =
            useMemo(
                () =>
                    new FunctionContext(
                        ParameterDictionary.union(
                            context.parameterDictionary,
                            column.parameters
                        ),
                        context.parameterAssignment
                            .getNewAssignment(column.parameterAssignment),
                        context.commitContext
                    ),
                [
                    context,
                    column,
                ]
            );

        if (cell)
        {
            return <LayoutViewer
                {...props}
                layout={cell.layout}
                parameterDictionary={childContext.parameterDictionary}
                parameterAssignment={childContext.parameterAssignment}
                commitContext={childContext.commitContext}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(TableLayoutCellViewer);
