import { observable } from 'mobx';

export default class TableCellLocation
{
    // ------------------------- Properties -------------------------

    @observable rowId: string;
    @observable columnId: string;

    // ------------------------ Constructor -------------------------

    constructor(rowId: string, columnId: string)
    {
        this.rowId = rowId;
        this.columnId = columnId;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    get id()
    {
        return `${this.rowId}.${this.columnId}`;
    }

    toDescriptor()
    {
        return {
            rowId: this.rowId,
            columnId: this.columnId
        };
    }

    static fromDescriptor(descriptor: any)
    {
        return new TableCellLocation(
            descriptor.rowId,
            descriptor.columnId);
    }

    // ----------------------- Private logic ------------------------
}
