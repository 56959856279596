import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Checkbox from '../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import ResourceSelection from '../../ResourcePlanner/Model/ResourceSelection';
import { Radio } from '@material-ui/core';
import { CalendarViewType } from '../../../../../@Future/Component/Generic/Calendar/Calendar';
import { makeStyles } from '@material-ui/styles';
import useDividerGlue from '../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import useTypes from '../../Type/Api/useTypes';

export interface EntityCalendarFilterProps
{
    calendarViewType: CalendarViewType;
    onChangeCalendarViewType: (viewType: CalendarViewType) => void;
    hideWeekends: boolean;
    onChangeHideWeekends: (hideWeekends: boolean) => void;

    hideInactiveEmployees: boolean;
    onChangeHideInactiveEmployees: (hideInactiveEmployees: boolean) => void;

    resourceSelections: ResourceSelection[];
    activeSelections: ResourceSelection[];
    onChangeResourceSelections: (selections: ResourceSelection[]) => void;
}


const useStyles =
    makeStyles({
        control: {
            padding: 4,
            color: 'rgb(52, 151, 219) !important',
        },
    });

const EntityCalendarFilter: React.FC<EntityCalendarFilterProps> =
    ({
        resourceSelections,
        activeSelections,
        onChangeResourceSelections,
        hideInactiveEmployees,
        onChangeHideInactiveEmployees,
        calendarViewType,
        onChangeCalendarViewType,
        hideWeekends,
        onChangeHideWeekends,
     }) =>
    {
        const classes = useStyles();
        const dividerGlue = useDividerGlue();
        const types = useTypes();

        const calendarViewTypes: Map<CalendarViewType, React.ReactNode> =
            useMemo(
                () =>
                    new Map<CalendarViewType, React.ReactNode>([
                        [
                            'day',
                            <LocalizedText
                                code="Generic.Day"
                                value="Dag"
                            />
                        ],
                        [
                            'week',
                            <LocalizedText
                                code="Generic.Week"
                                value="Week"
                            />
                        ],
                        [
                            'month',
                            <LocalizedText
                                code="Generic.Month"
                                value="Maand"
                            />
                        ],
                    ]),
                []
            );

        const activeResourceSelections =
            useMemo(
                () =>
                    new Set(activeSelections),
                [
                    activeSelections
                ]);

        const toggleResourceSelection =
            useCallback(
                (selection: ResourceSelection) =>
                {
                    const newSelections = new Set(activeResourceSelections);

                    if (activeResourceSelections.has(selection))
                    {
                        newSelections.delete(selection);
                    }
                    else
                    {
                        newSelections.add(selection);
                    }

                    return onChangeResourceSelections(
                        resourceSelections.filter(
                            selection =>
                                newSelections.has(selection)));
                },
                [
                    activeResourceSelections,
                    resourceSelections,
                    onChangeResourceSelections
                ]);

        return <MenuButton
            icon="filter_list"
            badge={activeResourceSelections.size !== resourceSelections.length ? 1 : undefined}
            badgeVariant="dot"
            tooltip={
                <LocalizedText
                    code="Generic.Filter"
                    value="Filter"
                />
            }
        >
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={dividerGlue}
            >
                <ViewGroupItem>
                    <CardInset>
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                <LocalizedText
                                    code="Configuration.View.Type"
                                    value="Weergavetype"
                                />
                            </ViewGroupItem>
                            {
                                Array.from(calendarViewTypes)
                                    .map(
                                        ([key, value]) =>
                                            <ViewGroupItem
                                                key={key}
                                            >
                                                <ViewGroup
                                                    orientation="horizontal"
                                                    spacing={0}
                                                    alignment="center"
                                                >
                                                    <ViewGroupItem>
                                                        <Radio
                                                            checked={calendarViewType === key}
                                                            onClick={() => onChangeCalendarViewType(key)}
                                                            className={classes.control}
                                                        />
                                                    </ViewGroupItem>
                                                    <ViewGroupItem>
                                                        {value}
                                                    </ViewGroupItem>
                                                </ViewGroup>
                                            </ViewGroupItem>
                                )
                            }
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={0}
                                    alignment="center"
                                >
                                    <ViewGroupItem>
                                        <Checkbox
                                            checked={hideWeekends}
                                            onToggle={onChangeHideWeekends}
                                            className={classes.control}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <LocalizedText
                                            code="EntityCalendarFilter.OnlyWorkingDays"
                                            value="Alleen werkdagen"
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </CardInset>
                </ViewGroupItem>
                <ViewGroupItem>
                    <CardInset>
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                {types.Relationship.Person.Contact.Employee.Type.getName(true)}
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={0}
                                    alignment="center"
                                >
                                    <ViewGroupItem>
                                        <Checkbox
                                            checked={hideInactiveEmployees}
                                            onToggle={onChangeHideInactiveEmployees}
                                            className={classes.control}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <LocalizedText
                                            code="EntityCalendarFilter.HideInactiveEmployees"
                                            value="Inactive verbergen"
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </CardInset>
                </ViewGroupItem>
                <ViewGroupItem>
                    <CardInset>
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                <LocalizedText
                                    code="Generic.Types"
                                    value="Types"
                                />
                            </ViewGroupItem>
                            {
                                resourceSelections.map(
                                    selection =>
                                        <ViewGroupItem
                                            key={selection.id}
                                        >
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={0}
                                                alignment="center"
                                            >
                                                <ViewGroupItem>
                                                    <Checkbox
                                                        checked={activeResourceSelections.has(selection)}
                                                        onToggle={() => toggleResourceSelection(selection)}
                                                        className={classes.control}
                                                    />
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    {selection.entityType.getName(true)} {selection.getDescription ? '(' + selection.getDescription + ')' : ''}
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </ViewGroupItem>
                                )
                            }
                        </ViewGroup>
                    </CardInset>
                </ViewGroupItem>
            </ViewGroup>
        </MenuButton>;
    };

export default observer(EntityCalendarFilter);
