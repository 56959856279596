import { TransactionalModel } from '../Model/TransactionalModel';
import TransactionalCommitAdministration from './TransactionalCommitAdministration';
import cached from '../../Cached/cached';

export default class TransactionalContext<T>
{
    // ------------------------- Properties -------------------------

    rootModel: any;
    seenObjects: Map<any, TransactionalModel<any>>;
    keyPath: string[];
    valuePath: any[];
    parentContext?: TransactionalContext<any>;
    doTrackTransactionalChangeOverride: boolean;
    commitAdministration?: TransactionalCommitAdministration;
    rollbackAdministration?: TransactionalCommitAdministration;

    // ------------------------ Constructor -------------------------

    constructor(rootModel: any,
                seenObjects: Map<any, TransactionalModel<any>> = new Map<any, TransactionalModel<any>>(),
                keyPath: string[] = [],
                valuePath: any[] = [],
                parentContext?: TransactionalContext<any>)
    {
        this.rootModel = rootModel;
        this.seenObjects = seenObjects;
        this.keyPath = keyPath;
        this.valuePath = valuePath;
        this.parentContext = parentContext;
        this.doTrackTransactionalChangeOverride = true;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @cached
    get rootContext(): TransactionalContext<any>
    {
        if (this.parentContext)
        {
            return this.parentContext.rootContext;
        } else
        {
            return this;
        }
    }

    @cached
    get formattedKeyPath(): string
    {
        return this.keyPath.join('.');
    }

    @cached
    get key(): keyof T | undefined
    {
        if (this.keyPath.length > 0)
        {
            return this.keyPath[this.keyPath.length - 1] as keyof T;
        } else
        {
            return undefined;
        }
    }

    @cached
    get secondToLastValue(): T | undefined
    {
        if (this.valuePath.length > 1)
        {
            return this.valuePath[this.valuePath.length - 2];
        } else
        {
            return this.rootModel;
        }
    }

    @cached
    get value(): T | undefined
    {
        if (this.valuePath.length > 0)
        {
            return this.valuePath[this.valuePath.length - 1];
        } else
        {
            return this.rootModel;
        }
    }

    get isCommitting(): boolean
    {
        return this.commitAdministration !== undefined;
    }

    get isRollingBack(): boolean
    {
        return this.rollbackAdministration !== undefined;
    }

    get isLocked(): boolean
    {
        return this.rootContext.isCommitting || this.rootContext.isRollingBack;
    }

    get doTrackChangesFromTransactionalModel(): boolean
    {
        return !this.isLocked && this.rootContext.doTrackTransactionalChangeOverride;
    }

    // -------------------------- Actions ---------------------------

    // @action
    trackTransactionalChanges()
    {
        this.rootContext.doTrackTransactionalChangeOverride = true;
    }

    // @action
    untrackTransactionalChanges()
    {
        this.rootContext.doTrackTransactionalChangeOverride = false;
    }

    // @action
    startCommit(administration: TransactionalCommitAdministration)
    {
        this.commitAdministration = administration;
    }

    // @action
    stopCommit()
    {
        this.commitAdministration = undefined;
    }

    // @action
    startRollback(administration: TransactionalCommitAdministration)
    {
        this.rollbackAdministration = administration;
    }

    // @action
    stopRollback()
    {
        this.rollbackAdministration = undefined;
    }

    // ------------------------ Public logic ------------------------

    joinTo<U>(key: string,
              value: U): TransactionalContext<U>
    {
        return new TransactionalContext(
            this.rootModel,
            this.seenObjects,
            this.keyPath.concat([key]),
            this.valuePath.concat([value]),
            this);
    }

    hasSeen(object: any)
    {
        return this.seenObjects.has(object);
    }

    getSeenTransactional(object: any): TransactionalModel<any>
    {
        return this.seenObjects.get(object);
    }

    // @action
    seen(object: any,
         transactional: TransactionalModel<any>)
    {
        this.seenObjects.set(
            object,
            transactional);
    }

    // ----------------------- Private logic ------------------------
}
