import React, { useCallback, useEffect } from 'react';
import ViewGroup from '../ViewGroup/ViewGroup';
import ViewGroupItem from '../ViewGroup/ViewGroupItem';
import { Id } from '../../../Util/Id/Id';
import { observer } from 'mobx-react-lite';
import useExpansion from './Hook/useExpansion';
import styles from './ExpansionPanel.module.scss';
import { classNames } from '../../../Util/Class/classNames';
import ExpansionPanelIdContext from './ExpansionPanelIdContext';
import ExpansionGroup from './Group/ExpansionGroup';

export interface ExpansionPanelProps
{
    id: Id;
    summary: JSX.Element;
    expansion: JSX.Element;
    expanded?: boolean;
    onExpand?: () => void;
    onCollapse?: () => void;
}

const ExpansionPanel: React.FC<ExpansionPanelProps> =
    props =>
    {
        const [ isExpanded, setExpansion ] = useExpansion(props.id);

        const toggleExpansion =
            useCallback(
                () =>
                {
                    const willBeExpanded = !isExpanded;

                    setExpansion(willBeExpanded);

                    if (willBeExpanded)
                    {
                        if (props.onExpand)
                        {
                            props.onExpand();
                        }
                    }
                    else
                    {
                        if (props.onCollapse)
                        {
                            props.onCollapse();
                        }
                    }
                },
            [
                props,
                setExpansion,
                isExpanded
            ]);

        useEffect(
            () =>
            {
                if (props.expanded)
                {
                    setExpansion(true);
                }
                else
                {
                    setExpansion(false);
                }
            },
            [
                props.expanded,
                setExpansion
            ]);

        return <ExpansionPanelIdContext.Provider
            value={props.id}
        >
            <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                <ViewGroupItem>
                    <div
                        onClick={toggleExpansion}
                        className={classNames(styles.summary, isExpanded && styles.expanded)}
                    >
                        {props.summary}
                    </div>
                </ViewGroupItem>
                {
                    isExpanded &&
                        <ViewGroupItem>
                            <ExpansionGroup>
                                {props.expansion}
                            </ExpansionGroup>
                        </ViewGroupItem>
                }
            </ViewGroup>
        </ExpansionPanelIdContext.Provider>;
    };

export default observer(ExpansionPanel);
