import React from 'react';
import IconButton, { IconButtonProps } from '../Icon/IconButton';
import { primaryColor } from '../../../../../../@Resource/Theme/Theme';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface AddIconButtonProps extends Partial<IconButtonProps>
{

}

const AddIconButton: React.FC<AddIconButtonProps> =
    props =>
    {
        return <IconButton
            icon="add"
            onClick={props.onClick}
            tooltip={
                <LocalizedText
                    code="Generic.Add"
                    value="Toevoegen"
                />
            }
            color={primaryColor}
            {...props}
        />;
    };

export default AddIconButton;
