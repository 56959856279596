import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Input from '../../../../../../../../../../Multiplayer/Model/Input/Input';
import Mapping from '../../../../../../../../../../../../@Api/Automation/Mapping/Mapping';
import { default as GenericInput } from '../../../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import FieldMappingEditor from './FieldMappingEditor';
import ViewGroup from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { IObservableArray, runInAction } from 'mobx';
import RelationshipInput from '../../../../../../../../../../Multiplayer/Model/Input/RelationshipInput';
import ValueFieldMapping from '../../../../../../../../../../../../@Api/Automation/Mapping/Field/ValueFieldMapping';
import MappingFieldMapping from '../../../../../../../../../../../../@Api/Automation/Mapping/Field/MappingFieldMapping';
import FieldMapping from '../../../../../../../../../../../../@Api/Automation/Mapping/Field/FieldMapping';
import IconButton from '../../../../../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { FieldsEditorProps } from './FieldsEditor';
import LocalizedText from '../../../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface FieldEditorProps extends FieldsEditorProps
{
    fieldMapping: FieldMapping<Input>;
}

type FieldMappingType = 'Empty' | 'Value' | 'Computation' | 'Mapping';

const FieldEditor: React.FC<FieldEditorProps> =
    props =>
    {
        const fieldMapping = props.fieldMapping;

        const onChange =
            useCallback(
                (value: FieldMappingType) =>
                    runInAction(
                        () =>
                        {
                            if (fieldMapping)
                            {
                                (props.mapping.fieldMappings as IObservableArray).remove(fieldMapping);
                            }

                            let newFieldMapping;

                            switch (value)
                            {
                                case 'Empty':
                                    break;

                                case 'Value':
                                    newFieldMapping =
                                        new ValueFieldMapping(
                                            props.field,
                                            undefined);

                                    break;

                                case 'Mapping':
                                    if (props.field instanceof RelationshipInput)
                                    {
                                        newFieldMapping =
                                            new MappingFieldMapping(
                                                props.field,
                                                new Mapping(
                                                    props.field.relationshipDefinition.getEntityType(props.field.isParent),
                                                    Mapping.buildParameter(
                                                        props.field.relationshipDefinition.getEntityType(
                                                            props.field.isParent)),
                                                    [],
                                                    []));
                                    }

                                    break;
                            }

                            if (newFieldMapping)
                            {
                                props.mapping.fieldMappings
                                    .push(newFieldMapping);

                                if (props.onConstruct)
                                {
                                    props.onConstruct(newFieldMapping);
                                }
                            }
                        }),
                [
                    fieldMapping,
                    props.mapping,
                    props.field,
                    props.onConstruct
                ]);

        const onDelete =
            useCallback(
                () =>
                    onChange('Empty'),
                [
                    onChange
                ]);

        const createMappingFieldMapping =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            if (props.field instanceof RelationshipInput)
                            {
                                const newFieldMapping =
                                    new MappingFieldMapping(
                                        props.field,
                                        new Mapping(
                                            props.field.relationshipDefinition.getEntityType(props.field.isParent),
                                            Mapping.buildParameter(
                                                props.field.relationshipDefinition.getEntityType(props.field.isParent)),
                                            [],
                                            []));

                                props.mapping.fieldMappings
                                    .splice(
                                        fieldMapping ? props.mapping.fieldMappings.indexOf(fieldMapping) : props.mapping.fieldMappings.length,
                                        fieldMapping ? 1 : 0,
                                        newFieldMapping);

                                if (props.onConstruct)
                                {
                                    props.onConstruct(newFieldMapping);
                                }
                            }
                        }),
                [
                    props.field,
                    props.mapping,
                    props.onConstruct
                ]);

        return <GenericInput
            label={`${props.field.toFieldPath().getName()}${props.field.isRequired() ? '*': ''}`}
            labelPosition={fieldMapping instanceof MappingFieldMapping ? 'top' : 'left'}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={15}
                alignment="center"
            >
                {/*{*/}
                {/*    options.length > 1 &&*/}
                {/*        <ViewGroupItem*/}
                {/*            ratio={fieldMapping ? undefined : 1}*/}
                {/*        >*/}
                {/*            <StaticSelectbox*/}
                {/*                options={options}*/}
                {/*                onChange={onChange}*/}
                {/*                value={selectedOption}*/}
                {/*                autoFocus={fieldMapping === undefined}*/}
                {/*            />*/}
                {/*        </ViewGroupItem>*/}
                {/*}*/}
                {
                    fieldMapping &&
                        <ViewGroupItem
                            ratio={1}
                        >
                            <FieldMappingEditor
                                context={props.context}
                                mapping={props.mapping}
                                fieldMapping={fieldMapping}
                            />
                        </ViewGroupItem>
                }
                {
                    fieldMapping instanceof ValueFieldMapping &&
                    props.field instanceof RelationshipInput &&
                        <ViewGroupItem>
                            <IconButton
                                icon="call_split"
                                tooltip={
                                    <LocalizedText
                                        code="AutomationEditor.HaveAutomationCreateFieldMapping"
                                        value="Laat automation een nieuw record aanmaken"
                                    />
                                }
                                onClick={createMappingFieldMapping}
                            />
                        </ViewGroupItem>
                }
                <ViewGroupItem>
                    <IconButton
                        icon="delete"
                        tooltip={
                            <LocalizedText
                                code="Generic.Delete"
                            />
                        }
                        onClick={onDelete}
                    />
                </ViewGroupItem>
            </ViewGroup>
        </GenericInput>;
    };

export default observer(FieldEditor);
