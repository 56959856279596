import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityFieldPath } from '../../@Model/EntityFieldPath';
import { EntityPath } from '../../@Model/EntityPath';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Content from './Content/Content';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import PrimaryButton from '../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import PathBreadcrumb from '../PathBreadcrumb/PathBreadcrumb';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { makeStyles } from '@material-ui/core';

export interface FieldPathBrowserProps
{
    path: EntityPath;
    onSelect: (fieldPath: EntityFieldPath) => void;
    filter?: (fieldPath: EntityFieldPath) => boolean;
}

const useStyles = makeStyles({
    container:
        {
            height: '80vh',
            maxHeight: '80vh',
        },
    inner:
        {
            display: 'block',
            maxHeight: '100%',
        },
    inner2:
        {
            display: 'block',
            height: 'calc(100% - 53px)'
        },
    topBar:
        {
            marginRight: '44px !important'
        }
});

const FieldPathBrowser: React.FC<FieldPathBrowserProps> =
    props =>
    {
        const classes = useStyles();
        const [ rootPath, setRootPath ] = useState<EntityPath>(props.path);

        useEffect(
            () =>
            {
                setRootPath(props.path);
            },
            [
                setRootPath,
                props.path
            ]);

        return <ViewGroup
            orientation="vertical"
            spacing={0}
            glue={useDividerGlue()}
            className={classes.container}
        >
            {
                rootPath.nodes.length > 1 &&
                    <ViewGroupItem>
                        <CardInset>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                                alignment="center"
                            >
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <PathBreadcrumb
                                        path={rootPath}
                                        onSelect={setRootPath}
                                    />
                                </ViewGroupItem>
                                {
                                    rootPath.lastJoinNode !== undefined &&
                                        <ViewGroupItem
                                            className={classes.topBar}
                                        >
                                            <PrimaryButton
                                                label={
                                                    <LocalizedText
                                                        code="FieldPathEditor.SelectThisField"
                                                        value="Dit veld selecteren"
                                                    />
                                                }
                                                onClick={() => props.onSelect(rootPath.field())}
                                            />
                                        </ViewGroupItem>
                                }
                            </ViewGroup>
                        </CardInset>
                    </ViewGroupItem>
            }
            <ViewGroupItem
                ratio={1}
                className={
                    rootPath.nodes.length > 1
                        ? classes.inner2
                        : classes.inner
                }
            >
                <Content
                    {...props}
                    path={rootPath}
                    onTraverse={setRootPath}
                    noVirtualGroups
                    filter={props.filter}
                />
            </ViewGroupItem>
        </ViewGroup>
    };

const ObservableFieldBrowser = observer(FieldPathBrowser);

export default ObservableFieldBrowser;
