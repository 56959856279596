import React from 'react';
import { observer } from 'mobx-react-lite';
import FieldMapping from '../../../../../../../../../../../../@Api/Automation/Mapping/Field/FieldMapping';
import Input from '../../../../../../../../../../Multiplayer/Model/Input/Input';
import ValueFieldMapping from '../../../../../../../../../../../../@Api/Automation/Mapping/Field/ValueFieldMapping';
import MappingFieldMapping from '../../../../../../../../../../../../@Api/Automation/Mapping/Field/MappingFieldMapping';
import MappingFieldMappingEditor from './Mapping/MappingFieldMappingEditor';
import ValueFieldMappingEditor from './Value/ValueFieldMappingEditor';
import Mapping from '../../../../../../../../../../../../@Api/Automation/Mapping/Mapping';
import FunctionContext from '../../../../../../../../../../../../@Api/Automation/Function/FunctionContext';

export interface FieldMappingEditorProps<T extends FieldMapping<Input> = FieldMapping<Input>>
{
    context: FunctionContext;
    mapping: Mapping;
    fieldMapping: T;
}

const FieldMappingEditor: React.FC<FieldMappingEditorProps> =
    props =>
    {
        if (props.fieldMapping instanceof ValueFieldMapping)
        {
            return <ValueFieldMappingEditor
                {...props as any}
            />;
        }
        else if (props.fieldMapping instanceof MappingFieldMapping)
        {
            return <MappingFieldMappingEditor
                {...props as any}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(FieldMappingEditor);
