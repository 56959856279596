import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './ValueContainer.module.scss';

export interface ValueContainerProps
{

}

const ValueContainer: React.FC<ValueContainerProps> =
    props =>
    {
        return <div
            className={styles.root}
        >
            {props.children}
        </div>;
    };

export default observer(ValueContainer);
