import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import Constructor from '../../../../Constructor/Constructor';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import { useInitializedEntityForViewer } from '../../../../Viewer/Api/useInitializedEntityForViewer';

export interface TaskProps
{
    entity: Entity;
}

const TimeRegistration: React.FC<TaskProps> =
    props =>
    {
        useInitializedEntityForViewer(props.entity);

        return <CardInset>
            <Constructor
                entity={props.entity}
                autoCommit
                disableActions
            />
        </CardInset>;
    };

export default observer(TimeRegistration);
