import React, { useCallback } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import { SettingSource } from '../../../../Setting/SettingStore';
import { Setting } from '../../../../../../@Api/Settings/Setting';
import useSetting from '../../../../Setting/Api/useSetting';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import Switch from '../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { SharePackEditor } from '../../../../Configuration/Page/Packs/MySharedPackManager/SharePackEditor/SharePackEditor';
import { usePackSelector } from '../../../../Configuration/Page/Packs/MySharedPackManager/Api/usePackSelector';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import Details from '../../Shared/Details/Details';

export interface DatastoreProps
{
    entity: Entity;
}

const Datastore: React.FC<DatastoreProps> =
    ({
        entity,
     }) =>
    {
        const [ hiddenIds, setHiddenIds ] = useSetting<string[]>(SettingSource.Organization, Setting.Datastore.HiddenIds, true);
        const isHidden =
            useComputed(
                () =>
                    hiddenIds?.indexOf(entity.uuid) !== -1,
                [
                    hiddenIds
                ]);
        const setHidden =
            useCallback(
                (isHidden: boolean) =>
                {
                    const newHiddenIds = hiddenIds.slice();

                    if (isHidden)
                    {
                        newHiddenIds.push(entity.uuid);
                    }
                    else
                    {
                        newHiddenIds.splice(
                            newHiddenIds.indexOf(entity.uuid),
                            1);
                    }

                    setHiddenIds(newHiddenIds);
                },
                [
                    hiddenIds,
                    setHiddenIds
                ]);

        const [ isPackSelectorVisible, ownedShareAndEnvironmentPacks ] = usePackSelector(entity);

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <TypeAndName
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            >
                                                <CardInset>
                                                    <Input
                                                        labelPosition="right"
                                                        label={
                                                            <LocalizedText
                                                                code="Generic.Hide"
                                                                value="Verbergen"
                                                            />
                                                        }
                                                    >
                                                        <Switch
                                                            onToggle={setHidden}
                                                            checked={isHidden}
                                                        />
                                                    </Input>
                                                </CardInset>
                                            </MenuButton>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                                {
                                    isPackSelectorVisible &&
                                    <React.Fragment>
                                        <Divider />
                                        <CardInset>
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={15}
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                >
                                                    <SharePackEditor
                                                        entity={entity}
                                                        ownedShareAndEnvironmentPacks={ownedShareAndEnvironmentPacks}
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </CardInset>
                                    </React.Fragment>
                                }
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Details
                                entity={entity}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(Datastore);
