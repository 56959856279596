import React from 'react';
import { BlockEditProps } from '@wordpress/blocks';
import { PanelBody, PanelRow } from '@wordpress/components';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import LocalizedText from '../../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';
import DimensionEditor from '../../DimensionEditor/DimensionEditor';

export interface DimensionsOptionsPanelProps
{
    props: BlockEditProps<{}>;
    open?: boolean;
}

const DimensionsOptionsPanel: React.FC<DimensionsOptionsPanelProps> =
    props =>
    {
        const {
            width,
            widthUnit,
            height,
            heightUnit
        } = props.props.attributes as any;

        return <PanelBody
            title={localizeText('Gutenberg.DimensionsOptions.Title', 'Afmetingen')} // Dimensions
            initialOpen={!!props.open}
        >
            <PanelRow>
                <DimensionEditor
                    label={
                        <LocalizedText
                            code="Gutenberg.DimensionsOptions.Width"
                            value="Breedte"
                        />
                    }
                    value={
                        widthUnit !== undefined && width !== undefined
                            ? { unit: widthUnit, value: width }
                            : undefined
                    }
                    onChange={
                        value =>
                            props.props.setAttributes({
                                widthUnit: value?.unit,
                                width: value?.value
                            })
                    }
                />
            </PanelRow>
            <PanelRow>
                <DimensionEditor
                    label={
                        <LocalizedText
                            code="Gutenberg.DimensionsOptions.Height"
                            value="Hoogte"
                        />
                    }
                    value={
                        heightUnit !== undefined && height !== undefined
                            ? { unit: heightUnit, value: height }
                            : undefined
                    }
                    onChange={
                        value =>
                            props.props.setAttributes({
                                heightUnit: value?.unit,
                                height: value?.value
                            })
                    }
                />
            </PanelRow>
        </PanelBody>;
    };

export default DimensionsOptionsPanel;
