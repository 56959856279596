import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import { useComputed } from 'mobx-react-lite';
import getResultCount from './getResultCount';

export default function useResultCount(result: EntitySelectionAggregateResult)
{
    return useComputed(
        () =>
            getResultCount(result),
        [
            result
        ]);
}
