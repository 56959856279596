import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './TypeName.module.scss';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';

export interface TypeAndNameProps
{
    entity: Entity;
    strikethrough?: boolean;
}

const TypeName: React.FC<TypeAndNameProps> =
    props =>
    {
        return <div
            className={
                classNames(
                    styles.root,
                    props.strikethrough && styles.strikethrough
                )
            }
            >
                {props.entity.entityType.getName(false)}
        </div>;
    };

export default observer(TypeName);
