import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { ExpansionPanelStore } from './ExpansionPanelStore';
import { Theme, withStyles } from '@material-ui/core';
import { dividerColor } from '../../../@Resource/Theme/Theme';

// noinspection TsLint
const styles =
    (theme: Theme) =>
        ({

        });

@observer
class ExpansionPanelTimeline extends BaseComponent<ExpansionPanelStore>
{
    renderComponent(store: ExpansionPanelStore): React.ReactNode
    {
        return <div
            style={{
                position: 'absolute',
                width: 1,
                height:
                    (store.isFirstInTimeline || store.isExpanded) && (store.isLastInTimeline || store.isExpanded)   // Only one item, no line to render
                        ?
                            0
                        :
                            (store.isFirstInTimeline || store.isExpanded)                     // First item, remove 35px from start
                                ?
                                    `calc(100% - ${store.summaryAvatarStore.size}px)`
                                :
                                    (store.isLastInTimeline || store.isExpanded)              // Last item, show only first 35px
                                        ?
                                            store.summaryAvatarStore.size
                                        :
                                            '100%',
                background: dividerColor, // store.timelineColor,
                left: 25,
                top:
                    store.isFirstInTimeline || store.isExpanded
                        ?
                            store.summaryAvatarStore.size
                        :
                            0,
                padding: 0,
                margin: 0
            }}
        />;
    }
}

export default withStyles(styles)(ExpansionPanelTimeline as any);
