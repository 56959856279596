import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { BaseEditorProps } from '../../Editor/Editor';
import TextEditor from '../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';

export interface TextEditorProps extends BaseEditorProps
{

}

const Editor: React.FC<TextEditorProps> =
    props =>
    {
        const onChange =
            useCallback(
                value =>
                {
                    props.dataObject.setValue(value);
                    if (props.onChange)
                    {
                        props.onChange(props.dataObject)
                    }
                },
                [
                    props.dataObject
                ]);

        return <TextEditor
            value={props.dataObject.value}
            onChange={onChange}
            inputProps={props.inputProps}
            placeholder={props.placeholder}
            disableUnderline={!props.underline}
            fitContent={props.representation?.fitContent}
            autoFocus={props.autoFocus}
            disabled={props.disabled}
        />
    };

export default observer(Editor);
