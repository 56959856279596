import { ComputationType } from '../../ComputationType';
import { EntityFieldComputationEditorStore } from './EntityFieldComputationEditorStore';
import { BaseComponentProps } from '../../../../../../@Framework/Component/BaseComponent';
import { EntityFieldComputationEditor } from './EntityFieldComputationEditor';
import { EntityFieldComputationSpecification } from './EntityFieldComputationSpecification';
import { ComputationContext } from '../../../ComputationContext';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../../../../DataObject/DataObjectStore';
import { DataObjectSpecification } from '../../../../DataObject/Model/DataObjectSpecification';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import { EntityTypeStore } from '../../../../Entity/Type/EntityTypeStore';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import { ComputationTypeStore } from '../../../ComputationTypeStore';
import { EntityContext } from '../../../../Entity/@Model/EntityContext';
import { EntityFieldComputation } from './EntityFieldComputation';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';

export class EntityFieldComputationType extends ComputationType<EntityFieldComputationEditorStore, EntityFieldComputationSpecification, EntityFieldComputation>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return 'EntityField';
    }

    name(): string
    {
        return this.localizationStore.translate('Computation.FieldValue'); // Field value
    }

    allow(context: ComputationContext): boolean
    {
        return context.entityContext != null;
    }

    isTerminal(): boolean
    {
        return true;
    }

    isResource(): boolean
    {
        return true;
    }

    fromSpecification(specification: EntityFieldComputationSpecification): EntityFieldComputation
    {
        const entityFieldPath = this.getEntityFieldPathfromSpecification(specification);

        if (entityFieldPath)
        {
            return new EntityFieldComputation(
                entityFieldPath,
                specification.entityContextParameter);
        }
        else
        {
            return undefined;
        }
    }

    toSpecification(computation: EntityFieldComputation): EntityFieldComputationSpecification
    {
        return {
            type: this.id(),
            entityContextParameter: computation.entityContextParameter,
            entityFieldPath:
                computation.entityFieldPath
                    ?
                        computation.entityFieldPath.descriptor
                    :
                        undefined
        };
    }

    compute(context: ComputationContext,
            computation: EntityFieldComputation): DataObject
    {
        const useCachedResults = context.useCachedResults == null ? true : context.useCachedResults;
        const fieldPath = computation.entityFieldPath;

        if (fieldPath)
        {
            const traversedContext =
                context.entityContext.traverse(
                    fieldPath.path,
                    computation.entityContextParameter,
                    context.commitContext
                );

            const parameterizedTraversedContext = traversedContext.getContext(computation.entityContextParameter);

            // If the root entity context has no entities defined, show the names of the field paths
            if (context.entityContext.getContext(computation.entityContextParameter).entities.length === 0)
            {
                return DataObject.constructFromTypeIdAndValue(
                    'Text',
                    new EntityFieldPath(
                        parameterizedTraversedContext.entityPath.join(fieldPath.path),
                        fieldPath.field)
                        .getAdministrativeName(
                            this.entityTypeStore,
                            false),
                    this.dataObjectStore);
            }
            else
            {
                for (const childContext of parameterizedTraversedContext.split())
                {
                    if (fieldPath.field)
                    {
                        if (fieldPath.field.computation && !useCachedResults)
                        {
                            const value =
                                this.computationTypeStore
                                    .computeFromSpecification(
                                        {
                                            entityContext: childContext,
                                            commitContext: context.commitContext,
                                        },
                                        fieldPath.field.computation
                                    );

                            return value;
                        }
                        else
                        {
                            return childContext.entity.getDataObjectValueByField(
                                fieldPath.field,
                                undefined,
                                context.commitContext
                            );
                        }
                    }
                    else
                    {
                        return DataObject.constructFromTypeAndValue(
                            this.dataObjectStore.getTypeById('Entity'),
                            childContext.entity);
                    }
                }
            }
        }

        return undefined;
    }

    description(context: ComputationContext,
                specification: EntityFieldComputationSpecification): string
    {
        let fieldPath = this.getEntityFieldPathfromSpecification(specification);

        return fieldPath
            ?
                fieldPath.getAdministrativeName(
                    this.entityTypeStore,
                    false)
            :
                '-';
    }

    editorStore(context: ComputationContext,
                specification: EntityFieldComputationSpecification): EntityFieldComputationEditorStore
    {
        return new EntityFieldComputationEditorStore(
            this as any,
            context,
            specification,
            specification.entityContextParameter,
            this.getEntityFieldPathfromSpecification(specification));
    }

    editorView(): React.ComponentClass<BaseComponentProps<EntityFieldComputationEditorStore>>
    {
        return EntityFieldComputationEditor;
    }

    editorSpecification(store: EntityFieldComputationEditorStore): EntityFieldComputationSpecification
    {
        return {
            type: this.id(),
            entityContextParameter: store.entityContextParameter,
            entityFieldPath: store.entityFieldPath && store.entityFieldPath.descriptor
        };
    }

    editorResultSpecification(store: EntityFieldComputationEditorStore): DataObjectSpecification
    {
        if (store.entityFieldPath)
        {
            if (store.entityFieldPath.field)
            {
                return store.entityFieldPath.field.dataObjectSpecification;
            }
            else
            {
                return new DataObjectSpecification(
                    this.dataObjectStore.getTypeById('Entity'),
                    {
                        entityTypeId: store.entityFieldPath.path.entityType.id
                    });
            }
        }
        else
        {
            return null;
        }
    }

    entityFieldPaths(specification: EntityFieldComputationSpecification,
                     context: EntityContext,
                     parameter?: string): EntityFieldPath[]
    {
        let fieldPath = this.getEntityFieldPathfromSpecification(specification);

        if (fieldPath && specification.entityContextParameter === parameter)
        {
            const joinedPath =
                context.getContext(parameter)
                    .entityPath
                    .join(fieldPath.path);

            const dependencies: EntityFieldPath[] = [];
            dependencies.push(
                new EntityFieldPath(
                    joinedPath,
                    fieldPath.field));

            if (fieldPath.field && fieldPath.field.isComputedField)
            {
                // Do not add parameter here, because it should be treated as the 'main' context
                dependencies.push(
                    ...this.computationTypeStore.getTypeById(fieldPath.field.computation.type)
                        .entityFieldPaths(
                            fieldPath.field.computation,
                            context.traverse(fieldPath.path, parameter).getContext(parameter)));
            }

            return dependencies;
        }
        else
        {
            return [];
        }
    }

    getEntityFieldPathfromSpecification(specification: EntityFieldComputationSpecification): EntityFieldPath
    {
        let fieldPath: EntityFieldPath;

        if (specification.entityFieldPath)
        {
            fieldPath =
                EntityFieldPath.construct(
                    specification.entityFieldPath,
                    this.entityTypeStore);
        }
        // Backward compatibility
        else if (specification.entityPath)
        {
            fieldPath =
                EntityFieldPath.construct(
                    {
                        nodes: specification.entityPath,
                        fieldId: specification.entityFieldId,
                        fieldCode: specification.entityFieldCode
                    },
                    this.entityTypeStore);
        }

        return fieldPath;
    }

    // ----------------------- Private logic ------------------------
}
