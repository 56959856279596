import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { EntityEvent } from '../../Model/Implementation/EntityEvent';

export class EntityBespokeEmailController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    sendEmail(entityId: number): Promise<EntityEvent[]>
    {
            return new Promise<EntityEvent[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/entity/bespoke/email/send', Method.Post, { entity_id: entityId }))
                    .then(result =>
                    {
                        resolve((fromJson(result, EntityEvent) as any) as EntityEvent[]);
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };

    renderEmail(entityId: number): Promise<string>
    {
        return new Promise<string>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        '/entity/bespoke/email/preview',
                        Method.Get,
                        { entity_id: entityId })
                )
                .then(result =>
                {
                    resolve(result as string);
                })
                .catch(reason =>
                {
                    reject(reason);
                });
            });
    };

    renderConceptEmail(subject: string, content: any, templateId: number, relationshipId: string, contactId: string): Promise<string>
    {
        return new Promise<string>(
            (resolve, reject) =>
            {
                this.apiClient.request(
                    new ApiRequest<any>(
                        '/entity/bespoke/email/preview-concept',
                        Method.Post,
                        {
                            subject: subject,
                            content: content,
                            template_id: templateId,
                            relationship_id: relationshipId,
                            contact_id: contactId })
                )
                .then(result =>
                {
                    resolve(result as string);
                })
                .catch(reason =>
                {
                    reject(reason);
                });
            });
    };
}
