import { Setting } from '../../Settings/Setting';
import { Entity } from '../../Model/Implementation/Entity';
import { EntityType } from '../../Model/Implementation/EntityType';
import getMetadataSettingValue from '../getMetadataSettingValue';
import setMetadataSettingValue from '../setMetadataSettingValue';

export async function setFieldGroupExpandedByDefault(entityType: EntityType,
                                                     fieldGroup: Entity,
                                                     isExpanded: boolean)
{
    const expandedFieldGroupIds: string[] =
        getMetadataSettingValue(
            entityType,
            Setting.Metadata.ExpandedFieldGroups)
        ?? [];

    const newExpandedFieldGroupIds =
        isExpanded
            ? [ ...expandedFieldGroupIds, fieldGroup.uuid ]
            : expandedFieldGroupIds.filter(id => id !== fieldGroup.uuid);

    await setMetadataSettingValue(
        entityType,
        Setting.Metadata.ExpandedFieldGroups,
        newExpandedFieldGroupIds);
}
