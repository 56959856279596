import { useContext, useMemo } from 'react';
import CurrentUserContext from '../../User/CurrentUserContext';

export function useUserLocalSettingCode(code?: string): string | undefined
{
    const user = useContext(CurrentUserContext).currentUser;

    if (user === undefined)
    {
        throw new Error(`Cannot use user local setting outside user context`);
    }

    return useMemo(
        () =>
            code === undefined
                ? undefined
                : `UserId:${user.id}:${code}`,
        [
            user.id,
            code,
        ]
    );
}
