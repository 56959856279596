import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import styles from './List.module.scss';
import InternalList from './InternalList/InternalList';
import ScrollRefContext from '../../../../../../@Service/Navigation/Page/Scroll/ScrollRefContext';

export interface ListProps
{
    entityType: EntityType;
    root?: boolean;
}

const List: React.FC<ListProps> =
    props =>
    {
        const scrollRef = useRef();

        return <ScrollRefContext.Provider
            value={scrollRef}
        >
            <div
                className={styles.root}
                ref={scrollRef}
            >
                <InternalList
                    entityType={props.entityType}
                    root={props.root}
                />
            </div>
        </ScrollRefContext.Provider>;
    };

export default observer(List);
