import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import getLocalSetting from './getLocalSetting';
import getEntityByUuid from '../../../../@Api/Entity/Bespoke/getEntityByUuid';

export default async function getLocalEntitySetting(code: string,
                                                    entityType?: EntityType,
                                                    initialState?: Entity): Promise<Entity | undefined>
{
    const entityId = await getLocalSetting(code, initialState?.uuid);

    if (entityId)
    {
        const { value: entity } = await getEntityByUuid(entityType, entityId);

        return entity;
    }
    else
    {
        return initialState;
    }
}
