import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../../../../../../Path/@Model/EntityPath';
import { EntitySelectionBuilder } from '../../../../../../../Selection/Builder/EntitySelectionBuilder';
import getTypes from '../../../../../../../Type/Api/getTypes';
import { EntityExpansionBuilder } from '../../../../../../../Selection/Builder/EntityExpansionBuilder';
import { Comparator } from '../../../../../../../../DataObject/Model/Comparator';
import { Aggregate } from '../../../../../../../../DataObject/Model/Aggregate';
import PrimitiveValue from '../../../../../../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../../../../../../DataObject/Model/DataObject';

export async function queryPricesForProduct(
    activity: Entity,
    product: Entity,
    relationship: Entity,
    quantity: number,
    date: Date
): Promise<Entity[]>
{
    const types = getTypes();
    const currency = activity?.getObjectValueByField(types.Activity.Field.Currency);
    const pathToPriceList =
        EntityPath.fromEntity(activity)
            .joinTo(
                types.Activity.RelationshipDefinition.PriceList,
                false
            );
    const priceListExpansionBuilder =
        new EntityExpansionBuilder(
            activity.entityType,
            [
                activity
            ],
            [
                pathToPriceList
            ]
        );
    await priceListExpansionBuilder.expand();
    const priceList =
        pathToPriceList
            .traverseEntity(activity)
            .find(() => true);
    const rootPath = EntityPath.fromEntityType(types.Price.Type);
    const relationshipRootPath = EntityPath.root(types.Relationship.Type);
    const prices =
        await EntitySelectionBuilder.fromPath(rootPath)
            .if(
                () =>
                    currency !== undefined,
                sb =>
                    sb.where(
                        cb =>
                            cb.eq(
                                rootPath.field(types.Price.Field.Currency),
                                undefined,
                                currency
                            )
                    )
            )
            .if(
                () =>
                    currency === undefined,
                sb =>
                    sb.where(
                        cb =>
                            cb.isNotDefined(
                                rootPath.field(types.Price.Field.Currency)
                            )
                    )
            )
            .if(
                () =>
                    priceList === undefined,
                sb =>
                    // If no price list is found on the activity, then only fetch a price from a price list that is not tied to a relationship
                    sb.where(
                        cb =>
                            cb.aggregateSelectionComparison(
                                EntitySelectionBuilder.fromPath(relationshipRootPath)
                                    .where(
                                        cb =>
                                            cb.propertyComparisonWithNode(
                                                relationshipRootPath
                                                    .joinTo(
                                                        types.Relationship.RelationshipDefinition.PriceList,
                                                        false
                                                    )
                                                    .field(types.Entity.Field.Id),
                                                undefined,
                                                Comparator.Equals,
                                                sb.joinAndGet(
                                                    rootPath
                                                        .joinTo(
                                                            types.PriceList.RelationshipDefinition.Prices,
                                                            true
                                                        )
                                                ),
                                                types.Entity.Field.Id,
                                                undefined
                                            )
                                    )
                                    .build(),
                                Aggregate.Count,
                                rootPath.field(types.Entity.Field.Id),
                                Comparator.Equals,
                                new PrimitiveValue(
                                    DataObject.constructFromTypeIdAndValue(
                                        'Number',
                                        0
                                    )
                                )
                            )
                    )
                    .where(
                        cb =>
                            cb.neq(
                                rootPath.joinTo(
                                    types.PriceList.RelationshipDefinition.Prices,
                                    true
                                )
                                    .field(types.PriceList.Field.IsExcludedFromPriceSelection),
                                undefined,
                                true
                            )
                    )
            )
            .if(
                () =>
                    priceList !== undefined,
                sb =>
                    sb.where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath.joinTo(
                                    types.PriceList.RelationshipDefinition.Prices,
                                    true
                                ),
                                priceList
                            )
                    )
            )
            .where(
                cb =>
                    cb.relatedToEntity(
                        rootPath.joinTo(
                            types.Price.RelationshipDefinition.Product,
                            false),
                        product
                    )
            )
            .where(
                cb =>
                    cb.or(
                        ob =>
                            ob
                                .relatedToEntity(
                                    rootPath.joinTo(
                                        types.Price.RelationshipDefinition.Relationship,
                                        false
                                    ),
                                    relationship
                                )
                                .isNotDefined(
                                    rootPath
                                        .joinTo(
                                            types.Price.RelationshipDefinition.Relationship,
                                            false)
                                        .field(types.Entity.Field.Id)
                                )
                    )
            )
            .where(
                cb =>
                    cb.or(
                        ob =>
                            ob
                                .le(
                                    rootPath.field(types.Price.Field.StartDate),
                                    undefined,
                                    date
                                )
                                .isNotDefined(rootPath.field(types.Price.Field.StartDate))
                    )
            )
            .where(
                cb =>
                    cb.or(
                        ob =>
                            ob
                                .gt(
                                    rootPath.field(types.Price.Field.EndDate),
                                    undefined,
                                    date
                                )
                                .isNotDefined(rootPath.field(types.Price.Field.EndDate))
                    )
            )
            .where(
                cb =>
                    cb.or(
                        ob =>
                            ob
                                .le(
                                    rootPath.field(types.Price.Field.Quantity),
                                    undefined,
                                    quantity
                                )
                                .isNotDefined(rootPath.field(types.Price.Field.Quantity))
                    )
            )
            .orderBy(
                rootPath.field(types.Price.Field.Quantity),
                false
            )
            .select();

    priceListExpansionBuilder.dispose();

    return prices.map(
        price =>
            price.entity
    );
}
