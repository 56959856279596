import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { ApiResponse } from '../../../@Service/ApiClient/Model/ApiResponseInterface';
import { LocalizationRequest } from '../../Localization/Model/LocalizationRequest';
import { LocalizationResponse } from '../../Localization/Model/LocalizationResponse';

export class LocalizationController
{
    apiClient: ApiClient;

    constructor(apiClient: ApiClient)
    {
        this.apiClient = apiClient;
    };

    bulkTranslate(requests: LocalizationRequest[]): Promise<ApiResponse<LocalizationResponse[]>>
    {
        return this.apiClient.request(
            new ApiRequest<ApiResponse<LocalizationResponse[]>>(
                '/localizations/bulk',
                Method.Post,
                JSON.stringify(requests),
                undefined,
                true,
                'application/json'
            )
        );
    };

}
