import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import View from '../../../Entity/View/Model/View';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TabBar from '../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import ViewEditor from './ViewEditor/ViewEditor';
import FieldsEditor from './FieldsEditor/FieldsEditor';
import MenuButton from '../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import isOwnEntity from '../../Api/isOwnEntity';
import { EntityTypeApi } from '../../../../../@Api/Api/EntityTypeApi';
import { createTransactionalModel } from '../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import RouterContext from '../../../../../@Service/Router/RouterContext';
import GeneralEditor from './GeneralEditor/GeneralEditor';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';
import AutomationsEditor from './AutomationsEditor/AutomationsEditor';
import DatasetEditor from './DatasetEditor/DatasetEditor';
import ConnectionsEditor from './ConnectionsEditor/ConnectionsEditor';
import PipelinesEditor from './PipelinesEditor/PipelinesEditor';
import CurrentUserContext from '../../../User/CurrentUserContext';
import FormsEditor from './FormEditor/FormEditor';
import useTypes from '../../../Entity/Type/Api/useTypes';
import usePipelineRelationshipDefinition from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/usePipelineRelationshipDefinition';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import ResourcePlannersEditor from './ResourcePlannersEditor/ResourcePlannersEditor';
import localizeText from '../../../../../@Api/Localization/localizeText';
import { ChildTypesEditor } from './ChildTypesEditor/ChildTypesEditor';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';

export interface TypeEditorProps
{
    entityType: EntityType;
    view: View;
    onChangeView: (view: View) => void;
    allView: View;
    onAddField: (fieldPath: EntityFieldPath) => void;
    onViewVisibleDuringEditMode: (isVisible: boolean) => void;
    configurationPageId?: string;
    configurationResourceId?: string;
}

const TypeEditor: React.FC<TypeEditorProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();
        const pageId = props.configurationPageId;

        useEffect(
            () =>
            {
                props.onViewVisibleDuringEditMode(pageId === 'views');
            },
            [
                pageId,
                props.onViewVisibleDuringEditMode
            ]);

        const isOwnType =
            useComputed(
                () =>
                    isOwnEntity(props.entityType.entity),
                [
                    props.entityType
                ]);

        const isTypeDeletable =
            useComputed(
                () =>
                    props.entityType.bespoke.isTypeDeletable()
                    || currentUserStore.isSupport ,
                [
                    props.entityType,
                    currentUserStore.isSupport
                ]);

        const routerStore = useContext(RouterContext);
        const deleteType =
            useCallback(
                () =>
                {
                    if (window.confirm(
                        localizeText(
                            'EntityTypeDeleteConfirmation',
                            'Weet u zeker dat u ${type} type wilt verwijderen? Door dit te doen worden alle velden die hierbij horen verwijderd. Als dit type in andere omgevingen wordt gebruikt (bijv. door middel van share packs), dan kan er ook nog data bestaan binnen de andere omgevingen. Deze zal dan ook verwijderd worden.',
                            { type: props.entityType.getName() })
                        ))
                    {
                        return new EntityTypeApi()
                            .delete(
                                createTransactionalModel(
                                    props.entityType
                                )
                            )
                            .then(
                                () =>
                                    routerStore.goBack()
                            )
                            .catch(
                                error =>
                                    loadModuleDirectly(FeedbackStore)
                                        .enqueueSnackbar(
                                            error.data.message,
                                            {
                                                variant: 'error',
                                                autoHideDuration: 10000
                                            })
                            );
                    }
                },
                [
                    props.entityType,
                    routerStore
                ]);

        const isPhaseAllowed =
            useMemo(
                () =>
                    props.entityType.isEither(
                        types.Relationship.Type,
                        types.Activity.Type,
                        types.CustomEntity.Type),
                [
                    props.entityType,
                    types
                ]);

        const setPage =
            useCallback(
                (pageId: string) =>
                    routerStore.route(`/type/${props.entityType.id}/configuration/${pageId}`),
                [
                    routerStore,
                    props.entityType
                ]);

        const pipelineRelationshipDefinition = usePipelineRelationshipDefinition(props.entityType);

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <TabBar
                    value={props.configurationPageId}
                    appendix={
                        isOwnType && currentUserStore.isAllowedInConfiguration && isTypeDeletable &&
                            <ViewGroupItem>
                                <MenuButton>
                                    <Menu>
                                        <Item
                                            name={
                                                <LocalizedText
                                                    code="Generic.Delete"
                                                    value="Verwijderen"
                                                />
                                            }
                                            onClick={deleteType}
                                        />
                                    </Menu>
                                </MenuButton>
                            </ViewGroupItem>
                    }
                >
                    {
                        props.entityType.isA(types.Form.Type) && currentUserStore.isAllowedInConfiguration &&
                            <Tab
                                value="form"
                                onClick={setPage}
                            >
                                <LocalizedText
                                    code="Configuration.Design"
                                    value="Ontwerp"
                                />
                            </Tab>
                    }
                    {
                        currentUserStore.isAllowedInConfiguration &&
                            <Tab
                                value="general"
                                onClick={setPage}
                            >
                                <LocalizedText
                                    code="Configuration.General"
                                    value="Algemeen"
                                />
                            </Tab>
                    }
                    {
                        isPhaseAllowed && currentUserStore.isAllowedInConfiguration &&
                            <Tab
                                value="pipelines"
                                onClick={setPage}
                            >
                                {
                                    pipelineRelationshipDefinition
                                    ?
                                        <LocalizedText
                                            code="Configuration.Pipelines"
                                            value="Pijplijnen"
                                        />
                                    :
                                        <LocalizedText
                                            code="Configuration.Phases"
                                            value="Fasen"
                                        />
                                }
                            </Tab>
                    }
                    {
                        currentUserStore.isAllowedInConfiguration &&
                            <Tab
                                value="fields"
                                onClick={setPage}
                            >
                                <LocalizedText
                                    code="Configuration.Fields"
                                    value="Velden"
                                />
                            </Tab>
                    }
                    {
                        currentUserStore.isAllowedInConfiguration &&
                            <Tab
                                value="connections"
                                onClick={setPage}
                            >
                                <LocalizedText
                                    code="Configuration.Connections"
                                    value="Koppelingen"
                                />
                            </Tab>
                    }
                    {
                        currentUserStore.isAllowedInConfiguration &&
                        props.entityType.bespoke.allowChildTypeCreation()
                        &&
                        <Tab
                            value="childTypes"
                            onClick={setPage}
                        >
                            <LocalizedText
                                code="Configuration.Subtypes"
                                value="Subtypen"
                            />
                        </Tab>
                    }
                    {
                        currentUserStore.isAllowedInViewConfiguration &&
                            <Tab
                                value="views"
                                onClick={setPage}
                            >
                                <LocalizedText
                                    code="Configuration.Views"
                                    value="Weergaven"
                                />
                            </Tab>
                    }
                    {
                        currentUserStore.isAllowedInDatasetConfiguration &&
                            <Tab
                                value="datasets"
                                onClick={setPage}
                            >
                                <LocalizedText
                                    code="Configuration.Datasets"
                                    value="Widgets"
                                />
                            </Tab>
                    }
                    {
                        currentUserStore.isAllowedInConfiguration &&
                            <Tab
                                value="automations"
                                onClick={setPage}
                            >
                                <LocalizedText
                                    code="Configuration.Automations"
                                    value="Automations"
                                />
                            </Tab>
                    }
                    {
                        currentUserStore.isSupport && currentUserStore.isAllowedInConfiguration &&
                            <Tab
                                value="resource-planners"
                                onClick={setPage}
                            >
                                {types.ResourcePlanner.Type.getName(true)}
                            </Tab>
                    }
                </TabBar>
            </ViewGroupItem>
            {
                pageId === 'form' && currentUserStore.isAllowedInConfiguration &&
                    <ViewGroupItem>
                        <FormsEditor
                            {...props}
                        />
                    </ViewGroupItem>
            }
            {
                pageId === 'general' && currentUserStore.isAllowedInConfiguration &&
                    <ViewGroupItem>
                        <GeneralEditor
                            {...props}
                        />
                    </ViewGroupItem>
            }
            {
                pageId === 'pipelines' && currentUserStore.isAllowedInConfiguration &&
                    <ViewGroupItem>
                        <PipelinesEditor
                            {...props}
                        />
                    </ViewGroupItem>
            }
            {
                pageId === 'fields' && currentUserStore.isAllowedInConfiguration &&
                    <ViewGroupItem>
                        <FieldsEditor
                            {...props}
                        />
                    </ViewGroupItem>
            }
            {
                pageId === 'connections' && currentUserStore.isAllowedInConfiguration &&
                    <ViewGroupItem>
                        <ConnectionsEditor
                            {...props}
                        />
                    </ViewGroupItem>
            }
            {
                pageId === 'childTypes' && currentUserStore.isAllowedInConfiguration &&
                <ViewGroupItem>
                    <ChildTypesEditor
                        {...props}
                    />
                </ViewGroupItem>
            }
            {
                pageId === 'views' && currentUserStore.isAllowedInViewConfiguration && // also change props.onViewVisibleDuringEditMode(page === 'views') when changing this tab
                    <ViewGroupItem>
                        <ViewEditor
                            {...props}
                        />
                    </ViewGroupItem>
            }
            {
                pageId === 'datasets' && currentUserStore.isAllowedInDatasetConfiguration &&
                    <ViewGroupItem>
                        <DatasetEditor
                            {...props}
                            datasetId={props.configurationResourceId}
                        />
                    </ViewGroupItem>
            }
            {
                pageId === 'automations' && currentUserStore.isAllowedInConfiguration &&
                    <ViewGroupItem>
                        <AutomationsEditor
                            {...props}
                        />
                    </ViewGroupItem>
            }
            {
                pageId === 'resource-planners' && currentUserStore.isAllowedInConfiguration &&
                    <ViewGroupItem>
                        <ResourcePlannersEditor
                            {...props}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

TypeEditor.defaultProps = {
    configurationPageId: 'general'
};

export default observer(TypeEditor);
