import { loadModuleDirectly } from '../../@Util/DependencyInjection';
import { LocalizationStore } from '../../@Service/Localization/LocalizationStore';
import { Setting } from '../Settings/Setting';
import getLocalSetting from '../../@Component/Domain/Setting/Api/getLocalSetting';

export type Parameters = { [key: string]: string; };

const defaultFallbackLanguage = 'nl';

export default function localizeText(
    code: string,
    fallback: string,
    parameters?: Parameters,
    fallbackLanguage: string = defaultFallbackLanguage
): string | undefined
{
    const bareTranslation =
        safelyGetLocalizedTextWithoutSubstitutingParameters(
            code,
            fallback,
            fallbackLanguage
        );

    const translation =
        substituteParameters(
            bareTranslation,
            parameters);

    if (shouldLogTranslation())
    {
        logTranslation(
            code,
            fallback,
            parameters);
    }

    return translation;
}

function safelyGetLocalizedTextWithoutSubstitutingParameters(
    code: string,
    fallback: string,
    fallbackLanguage: string
)
{
    const bareTranslation = localizeTextWithoutSubstitutingParameters(code);

    if (isBareTranslationInvalid(bareTranslation))
    {
        const localizationStore = loadModuleDirectly(LocalizationStore);
        localizationStore?.requestTranslation(code, fallback, fallbackLanguage);

        // Don't wait for translation to update, use fallback for now
        return fallback;
    }
    else
    {
        return bareTranslation;
    }
}


let isInPlaceEditorEnabled: boolean = false;

getLocalSetting(Setting.Local.Translation.IsInPlaceEditorEnabled, false)
    .then(
        result =>
            isInPlaceEditorEnabled = result);

function shouldLogTranslation(): boolean
{
    return isInPlaceEditorEnabled;
}

function logTranslation(code: string,
                        fallback: string,
                        parameters?: Parameters)
{
    console.log(`translating: ${code}, fallback: ${fallback}, parameters:`, parameters);
}

function isBareTranslationInvalid(bareTranslation: any)
{
    return bareTranslation === undefined || typeof bareTranslation !== 'string';
}

function localizeTextWithoutSubstitutingParameters(code: string)
{
    const localizationStore = loadModuleDirectly(LocalizationStore);
    // if (!localizationStore) {
    //     console.log("Failed loading LocalizationStore for code", code);
    // }
    return localizationStore?.translationByCode.get(code);
}

const parameterSubstitutionPattern = /\${([^}]*)}/g;

function substituteParameters(text: string,
                              parameters?: Parameters)
{
    if (text && parameters)
    {
        return text
            .replace(
                parameterSubstitutionPattern,
                (match, $1) =>
                {
                    if (parameters[$1] === undefined || parameters[$1] == null)
                    {
                        return match;
                    }
                    else
                    {
                        return parameters[$1];
                    }
                });
    }
    else
    {
        return text;
    }
}

export function nounToLocalizedLowerCase(noun: string) : string
{
    if (!noun)
    {
        return noun;
    }

    const localizationStore = loadModuleDirectly(LocalizationStore);

    if (localizationStore.languageCode === 'de')
    {
        noun = noun.trimLeft();
        return noun.charAt(0).toUpperCase() + noun.substr(1, noun.length);
    }
    else
    {
        return noun.toLowerCase();
    }
}
