import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import ExpressionEditorContext from '../../../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Expression/ExpressionEditorContext';
import { EntityContext } from '../../../../../@Model/EntityContext';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import { ExpressionContext } from '../../../../../../Expression/ExpressionContext';
import CollapsibleToolbar from '../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/CollapsibleToolbar';
import DefaultToolbarButtons from '../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/DefaultToolbarButtons';
import EditorButton from '../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorCommandButton';
import EditorFontSelector from '../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorFontSelector';
import EditorColorPicker from '../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/Toolbar/EditorColorPicker';
import RichtextEditor from '../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/RichtextEditor';

export interface HtmlEditorProps
{
    entity: Entity;
    html: string;
    onChange: (newHtml: string) => void;
    expressionContext?: ExpressionContext;
    editLayoutMode?: boolean;
    hideEntityFieldSelector?: boolean;
    debounceDelay?: number;
}

const HtmlEditor: React.FC<HtmlEditorProps> =
    props =>
    {
        const types = useTypes();
        const entity = props.entity;
        const { onChange } = props;

        const onChangeCallback =
            useCallback(
                (newHtml: string) =>
                {
                    if (onChange)
                    {
                        onChange(newHtml);
                    }
                    else
                    {
                        entity.setValueByField(
                            types.Activity.Email.Field.HTML,
                            newHtml
                        );
                    }
                },
                [
                    onChange,
                    entity,
                    types
                ]);

        const expressionContext =
            useMemo(
                () =>
                {
                    if (props.expressionContext)
                    {
                        return props.expressionContext;
                    }
                    else
                    {
                        return {
                            entityContext:
                                new EntityContext(
                                    [],
                                    EntityPath.fromEntityType(types.Activity.Email.Type))
                                    .setContext('Addressee', new EntityContext([], EntityPath.fromEntityType(types.Recipient.Type)))
                                    .setContext('Environment', new EntityContext([], EntityPath.fromEntityType(types.Relation.Organization.Environment.Type)))
                                    .setContext('Employee', new EntityContext([], EntityPath.fromEntityType(types.Relationship.Person.Contact.Employee.Type)))
                        }
                    }
                },
                [
                    props.expressionContext,
                    types
                ]);

        return <ExpressionEditorContext.Provider
            value={expressionContext}
        >
            <RichtextEditor
                placeholder={'Start writing here'}
                value={props.html}
                onChange={onChangeCallback}
                hideToolbarWhenEmpty={false}
                allowTextStyling
                extraTextStylingFormats={['image']}
                showToolbarOnFocus
            >
                <CollapsibleToolbar>
                    <DefaultToolbarButtons />

                    <EditorButton icon="link" tooltip="Link" command="link"/>
                    <EditorFontSelector />
                    <EditorColorPicker tooltip="Selecteer kleur" />
                </CollapsibleToolbar>
            </RichtextEditor>
        </ExpressionEditorContext.Provider>;
    };

export default observer(HtmlEditor);
