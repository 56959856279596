import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { observer } from 'mobx-react';
import useTypes from '../../../../Type/Api/useTypes';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import uuid from 'uuid';
import { EntityContext } from '../../../../@Model/EntityContext';
import { OldPredicate } from '../../../../../Predicate/Type/OldPredicate';
import { OldCompositePredicate } from '../../../../../Predicate/Type/Composite/OldCompositePredicate';
import { LogicalOperator } from '../../../../../DataObject/Model/LogicalOperator';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/index';
import { PredicateTypeStore } from '../../../../../Predicate/PredicateTypeStore';
import useDelayedCallback from '../../../../../../../@Future/Util/Hook/useDelayedCallback';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import getViewParameters from '../../../../View/Api/getViewParameters';
import getNewViewPredicateFromOldPredicate from '../../../../View/Api/getNewViewPredicateFromOldPredicate';
import Specification from '../../../../View/Model/Specification';
import { default as ListModel } from '../../../../View/Model/Specification/List';
import { default as ViewModel } from '../../../../View/Model/View';
import { default as LabelInput } from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import Column from '../../../../View/Model/Specification/Column';
import ExpansionPanel from '../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import StaticSelectbox from '../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import { red } from '@material-ui/core/colors';
import { SimpleFieldPathEditor } from '../../../../Path/FieldPathEditor/Simple/Editor/SimpleFieldPathEditor';
import CardHeader from '../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import ToNewPredicateEditor from '../../../../../Predicate/ToNewPredicateEditor/ToNewPredicateEditor';
import ScrollRefContext from '../../../../../../../@Service/Navigation/Page/Scroll/ScrollRefContext';
import List from '../../../../View/List/List';
import { makeStyles } from '@material-ui/styles';


export interface SpotlerCampaignSelectionProps
{
    spotlerCampaign: Entity;
    commitContext: CommitContext;
    isConcept: boolean;
}

const useStyles =
    makeStyles({
        list: {
            maxHeight: '80vh',
            overflowY: 'auto',
            overflowX: 'hidden'
        }
    });

export const SpotlerCampaignSelection: React.FC<SpotlerCampaignSelectionProps> = observer(
    ({
         spotlerCampaign,
         commitContext,
         isConcept
     }) =>
    {
        const types = useTypes();
        const classes = useStyles();
        const [ reloadList, setReloadList ] = useState(() => uuid());

        const rootType =
            useEntityValue(
                spotlerCampaign,
                types.Activity.SpotlerCampaign.Field.RootType,
                types.Relationship.Organization.Type
            );
        const entityContext =
            useMemo(
                () =>
                    EntityContext.fromEntityType(rootType),
                [
                    rootType
                ]);
        const isRootTypeARelationship =
            useMemo(
                () =>
                    rootType?.isA(types.Relationship.Type),
                [
                    rootType,
                    types
                ]
            );
        const rootTypeSelectionOptions =
            useMemo(
                () =>
                    [
                        {
                            id: types.Relationship.Person.Type.id.toString(),
                            label: types.Relation.Person.Type.getName(true),
                            value: types.Relationship.Person.Type
                        },
                        ...types.Relationship.Person.Type.getAllTypes(false, true)
                            .filter(type => type !== types.Relationship.Person.Type)
                            .map(
                                type =>
                                    ({
                                        id: type.id.toString(),
                                        label: `${types.Relation.Person.Type.getName(true)}: ${type.getName(true).toLowerCase()}`,
                                        value: type
                                    })
                            )
                    ],
                [
                    types
                ]
            );
        const [ filter, setFilter ] = useState<OldPredicate>();
        const [ delayedFilter, setDelayedFilter ] = useState<OldPredicate>();
        const [ filterRevision, setFilterRevision ] = useState(() => uuid());

        const emptyFilter =
            useMemo(
                () =>
                    new OldCompositePredicate(LogicalOperator.And, []),
                []);

        const emptyFilterSpecification =
            useMemo(
                () =>
                    loadModuleDirectly(PredicateTypeStore)
                        .toSpecification(emptyFilter),
                [
                    emptyFilter
                ]
            );

        useDelayedCallback(
            filter,
            setDelayedFilter,
            500
        );

        const relationshipPathDescriptor =
            useEntityValue<any>(
                spotlerCampaign,
                types.Activity.SpotlerCampaign.Field.RelationshipPath
            );

        const relationshipFieldPath =
            useMemo(
                () =>
                {
                    if (relationshipPathDescriptor)
                    {
                        return EntityPath.construct(relationshipPathDescriptor).field();
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    relationshipPathDescriptor,
                    types,
                    commitContext
                ]
            );
        const setRelationshipFieldPath =
            useCallback(
                (relationshipPath: EntityFieldPath) =>
                {
                    setValueByFieldInEntity(
                        spotlerCampaign,
                        types.Activity.SpotlerCampaign.Field.RelationshipPath,
                        relationshipPath.path.descriptor,
                        commitContext
                    );

                    return commitEntityWithContext(
                        spotlerCampaign,
                        commitContext
                    );
                },
                [
                    spotlerCampaign,
                    types,
                    commitContext,
                ]
            );

        const changeRootType =
            useCallback(
                (type: EntityType) =>
                {
                    setValueByFieldInEntity(
                        spotlerCampaign,
                        types.Activity.SpotlerCampaign.Field.RootType,
                        type,
                        commitContext
                    );
                    setValueByFieldInEntity(
                        spotlerCampaign,
                        types.Activity.SpotlerCampaign.Field.RelationshipPath,
                        EntityPath.fromEntityType(type).descriptor,
                        commitContext
                    );

                    // Clear selection
                    setValueByFieldInEntity(
                        spotlerCampaign,
                        types.Activity.SpotlerCampaign.Field.ManualSelection,
                        undefined,
                        commitContext
                    );

                    // Clear filter
                    setValueByFieldInEntity(
                        spotlerCampaign,
                        types.Activity.SpotlerCampaign.Field.SelectionFilter,
                        undefined,
                        commitContext
                    );

                    setFilter(undefined);
                    setDelayedFilter(undefined);

                    setReloadList(uuid());
                    setFilterRevision(uuid());

                    return commitEntityWithContext(spotlerCampaign, commitContext);
                },
                [
                    types,
                    spotlerCampaign,
                    setReloadList,
                    setFilterRevision,
                    setFilter,
                    setDelayedFilter,
                    emptyFilterSpecification,
                    commitContext,
                ]);

        const listSelection =
            useEntityValue(
                spotlerCampaign,
                types.Activity.SpotlerCampaign.Field.ManualSelection,
                undefined,
                commitContext
            );

        const selectionSize =
            useMemo(
                () =>
                    listSelection && listSelection.size,
                [
                    listSelection,
                    listSelection?.size
                ]);

        const filterSpecification =
            useMemo(
                () =>
                    delayedFilter
                        ?
                        loadModuleDirectly(PredicateTypeStore).toSpecification(delayedFilter)
                        :
                        undefined,
                [
                    delayedFilter
                ]);
        useEffect(
            () =>
            {
                if (filterSpecification)
                {
                    setValueByFieldInEntity(
                        spotlerCampaign,
                        types.Activity.SpotlerCampaign.Field.SelectionFilter,
                        filterSpecification,
                        commitContext
                    );

                    commitEntityWithContext(
                        spotlerCampaign,
                        commitContext
                    );
                }
            },
            [
                filterSpecification,
                spotlerCampaign,
                commitContext,
                types
            ]);
        useEffect(
            () =>
            {
                if (spotlerCampaign.hasValueForField(types.Activity.SpotlerCampaign.Field.SelectionFilter, commitContext))
                {
                    const filter =
                        loadModuleDirectly(PredicateTypeStore).fromSpecification(
                            spotlerCampaign.getObjectValueByField(
                                types.Activity.SpotlerCampaign.Field.SelectionFilter,
                                commitContext
                            ));

                    setFilter(filter);
                    setDelayedFilter(filter);
                }
                else
                {
                    setFilter(emptyFilter);
                    setDelayedFilter(emptyFilter);
                }

                setFilterRevision(uuid());
            },
            [
                spotlerCampaign,
                types,
                commitContext,
                setFilter,
                setDelayedFilter,
                setFilterRevision
            ]);

        const disableSelection =
            useCallback(
                (relationEntity: Entity) =>
                {
                    // Disable selection for entities which opted out
                    const mailingOptOut = relationEntity.getObjectValueByField(
                        EntityPath.fromEntityType(rootType).field(types.Relationship.Field.OptOut).field,
                        commitContext
                    );

                    return !!mailingOptOut;
                },
                [
                    types,
                    rootType,
                    commitContext,
                    isConcept
                ]);

        const viewParameters =
            useMemo(
                () =>
                    getViewParameters(rootType),
                [
                    rootType
                ]);

        const [ newDelayedFilter ] =
            useAsyncResult(
                () =>
                    delayedFilter
                        ?
                            getNewViewPredicateFromOldPredicate(
                                viewParameters,
                                delayedFilter
                            )
                        :
                            undefined,
                [
                    delayedFilter,
                    viewParameters
                ]
            );

        const emailAddressFieldPath =
            useMemo(
                () =>
                {
                    const rootPath = relationshipFieldPath?.path ?? EntityPath.fromEntityType(rootType);

                    if (rootPath.entityType.isA(types.Relationship.Organization.Type))
                    {
                        return rootPath
                            .joinTo(
                                types.Relationship.Organization.RelationshipDefinition.Organization,
                                false)
                            .field(types.Relation.Organization.Field.EmailAddress);
                    }
                    else if (rootPath.entityType.isA(types.Relationship.Person.Contact.Type))
                    {
                        return rootPath
                            .field(types.Relationship.Person.Contact.Field.EmailAddress);
                    }
                    else if (rootPath.entityType.isA(types.Relationship.Person.Type))
                    {
                        return rootPath
                            .joinTo(
                                types.Relationship.Person.RelationshipDefinition.Person,
                                false)
                            .field(types.Relation.Person.Field.EmailAddress);
                    }
                },
                [
                    types,
                    rootType,
                    relationshipFieldPath
                ]);

        const view =
            useMemo(
                () =>
                    new ViewModel(
                        'List',
                        undefined,
                        rootType,
                        viewParameters,
                        newDelayedFilter,
                        new Specification(
                            new ListModel(
                                [
                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(rootType).field(types.Entity.Field.Name)
                                    ),

                                    ...(relationshipFieldPath && relationshipFieldPath?.path.entityType !== rootType)
                                        ?
                                        [
                                            new Column(
                                                uuid(),
                                                relationshipFieldPath.path.field()
                                            )
                                        ]
                                        :
                                        [],

                                    ...relationshipFieldPath?.path.entityType.isA(types.Relationship.Person.Contact.Type)
                                        ?
                                        [
                                            new Column(
                                                uuid(),
                                                relationshipFieldPath.path.joinTo(
                                                    types.Relation.RelationshipDefinition.Relationships,
                                                    true
                                                ).field()
                                            )
                                        ]
                                        :
                                        [],

                                    ...emailAddressFieldPath
                                        ?
                                        [
                                            new Column(
                                                uuid(),
                                                emailAddressFieldPath
                                            )
                                        ]
                                        :
                                        [],

                                    new Column(
                                        uuid(),
                                        EntityPath.fromEntityType(rootType)
                                            .field(types.Relationship.Field.OptOut)
                                    )
                                ],
                                []
                            )
                        )
                    ),
                [
                    types,
                    rootType,
                    relationshipFieldPath,
                    viewParameters,
                    newDelayedFilter,
                    emailAddressFieldPath
                ]
            );
        const setListSelection =
            useCallback(
                (selection) =>
                {
                    setValueByFieldInEntity(
                        spotlerCampaign,
                        types.Activity.SpotlerCampaign.Field.ManualSelection,
                        selection,
                        commitContext
                    );

                    return commitEntityWithContext(
                        spotlerCampaign,
                        commitContext,
                        {
                            isDebounced: true,
                        }
                    );
                },
                [
                    types,
                    spotlerCampaign,
                    commitContext,
                ]);

        const scrollRef = useRef();
        return <ExpansionPanel
            id="Selection"
            summary={
                <Header
                    large
                    inset
                    title={
                        selectionSize
                            ?
                                <LocalizedText
                                    code="Campaign.SelectionWithSize"
                                    value="Selectie (${selectionSize})"
                                    selectionSize={selectionSize}
                                />
                            :
                                <LocalizedText
                                    code="Campaign.Selection"
                                    value="Selectie"
                                />
                    }
                />
            }
            expansion={
                <ViewGroup
                    orientation="vertical"
                    spacing={20}
                >
                    {
                        isConcept &&
                        <ViewGroupItem>
                            <CardInset
                                vertical={false}
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={0}
                                >
                                    <ViewGroupItem>
                                        <LabelInput
                                            label={<LocalizedText
                                                code="Generic.Type"
                                                value="Type"
                                            />}
                                            labelPosition="left"
                                        >
                                            <StaticSelectbox
                                                options={rootTypeSelectionOptions}
                                                value={rootType}
                                                onChange={changeRootType}
                                                disableUnderline={false}
                                                disabled={!isConcept}
                                            />
                                        </LabelInput>
                                    </ViewGroupItem>
                                    {relationshipFieldPath && !relationshipFieldPath.path.entityType.isA(types.Relationship.Type) && <ViewGroupItem>
                                        <div
                                            style={{
                                                color: red[500],
                                                fontWeight: 500,
                                            }}
                                        >
                                            <LocalizedText
                                                code="Campaign.InvalidRelationshipPath"
                                                value="Geen geldig pad opgegeven naar een relatie"
                                            />
                                        </div>
                                    </ViewGroupItem>}
                                    {rootType && !isRootTypeARelationship && <ViewGroupItem>
                                        <LabelInput
                                            label={types.Activity.SpotlerCampaign.Field.RelationshipPath.getName()}
                                            labelPosition="left"
                                        >
                                            <SimpleFieldPathEditor
                                                entityType={rootType}
                                                value={relationshipFieldPath}
                                                onChange={setRelationshipFieldPath}
                                            />
                                        </LabelInput>
                                    </ViewGroupItem>}
                                    <ViewGroupItem>
                                        <ViewGroup
                                            orientation="vertical"
                                            spacing={5}
                                        >
                                            <ViewGroupItem>
                                                <CardHeader>
                                                    <LocalizedText
                                                        code="Generic.Filter"
                                                        value="Filter"
                                                    />
                                                </CardHeader>
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <ToNewPredicateEditor
                                                    key={filterRevision}
                                                    context={entityContext}
                                                    predicate={filter}
                                                    onChange={setFilter}
                                                    disabled={!isConcept}
                                                    disableMe
                                                />
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </CardInset>
                        </ViewGroupItem>
                    }
                    <ViewGroupItem>
                        <div
                            ref={scrollRef}
                            className={classes.list}
                        >
                            <ScrollRefContext.Provider
                                value={scrollRef}
                            >
                                <List
                                    key={reloadList}
                                    view={view}
                                    onSelectionChange={setListSelection}
                                    selection={listSelection}
                                    defaultSelectionType="all"
                                    selectable
                                    disableSelection={disableSelection}
                                    readonly={!isConcept}
                                />
                            </ScrollRefContext.Provider>
                        </div>
                    </ViewGroupItem>
                </ViewGroup>
            }
        />;
    })