import { useCallback, useEffect, useMemo, useState } from 'react';
import useTriggers from './useTriggers';
import Message from './Message';
import Trigger from './Trigger';
import { useLocalStore } from 'mobx-react-lite';
import { observable, runInAction } from 'mobx';

const preEvents: MessageEvent[] = [];
const preListener =
    (event: MessageEvent) =>
    {
        preEvents.push(event);
    };

window.addEventListener(
    'message',
    preListener,
    false);

export type TriggerCallback = (trigger: Trigger<any>) => void;

export default function useActionListener(): [ TriggerCallback, TriggerCallback, boolean ]
{
    const triggerStore =
        useLocalStore(
            () =>
                ({
                    triggers: observable.array<Trigger<any>>([], { deep: false }),
                    add: (trigger: Trigger<any>) =>
                    {
                        runInAction(
                            () =>
                                triggerStore.triggers.push(trigger));
                    },
                    remove: (trigger: Trigger<any>) =>
                    {
                        runInAction(
                            () =>
                                triggerStore.triggers.remove(trigger));
                    }
                }));

    const triggers = useTriggers(triggerStore.triggers);

    const listener =
        useCallback(
            (event: MessageEvent) =>
            {
                const message: Message = event.data;

                if (typeof event.data === 'object')
                {
                    triggers
                        .filter(
                            trigger =>
                                trigger.type === message.type)
                        .forEach(
                            trigger =>
                                trigger.onEvent(message));
                }
            },
            [
                triggers
            ]);

    const [ isLoading, setLoading ] = useState(true);

    useEffect(
        () =>
        {
            setLoading(true);

            window.removeEventListener(
                'message',
                preListener);

            preEvents.splice(0, preEvents.length)
                .forEach(
                    event =>
                        listener(event));

            setLoading(false);
        },
        [
            listener,
            setLoading
        ]);

    useEffect(
        () =>
        {
            window.addEventListener(
                'message',
                listener,
                false);

            return () =>
                window.removeEventListener('message', listener);
        },
        [
            listener
        ]);

    return useMemo(
        () =>
            [
                triggerStore.add,
                triggerStore.remove,
                isLoading,
            ],
        [
            triggerStore,
            isLoading
        ]);
}
