import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import ViewGroup from '../ViewGroup/ViewGroup';
import ViewGroupItem from '../ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';
import IconButton from '../Button/Variant/Icon/IconButton';
import { primaryColor, textSecondaryColor } from '../../../../@Resource/Theme/Theme';
import SortIconButton from '../Button/Variant/SortIconButton/SortIconButton';
import { SortOrder } from '../../../../@Util/Sort/SortOrder';
import { PromiseOrAny } from '../Button/Button';
import { classNames } from '../../../Util/Class/classNames';
import { EntityFieldPath } from '../../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';

const useStyles =
    makeStyles({
        root: {
            "&:hover": {
                cursor: "pointer",
                "& $filter": {
                    visibility: "visible"
                }
            },
        },
        label: {
            color: 'rgba(0, 0, 0, 0.63)'
        },
        filter: {
            color: textSecondaryColor,
            visibility: 'hidden',
            "&:hover": {
                visibility: "visible"
            }
        },
        active: {
            visibility: "visible",
            color: primaryColor
        }
    });

export interface SortExpression
{
    column?: string | undefined;
    fieldPath?: EntityFieldPath | undefined;
    order: SortOrder | undefined;
}

export interface HeaderProps
{
    column?: string;
    fieldPath?: EntityFieldPath | undefined;

    localizeResourceCode?: string;
    localizeResourceDefaultValue?: string;

    isDefaultSortColumn?: boolean;
    sort?: SortExpression | undefined;
    onSort?: (sortExpression: SortExpression | undefined) => void;

    hasFilter?: boolean;
    isFilterOpen?: boolean;
    onToggleFilter?: () => PromiseOrAny<any>;
}

export const Header: React.FC<HeaderProps> =
    ({
         column,
         fieldPath,
         localizeResourceCode,
         localizeResourceDefaultValue,
         isDefaultSortColumn,
         sort,
         onSort,
         hasFilter,
         isFilterOpen,
         onToggleFilter
     }) =>
    {
        const classes = useStyles();

        const onSortChange =
            useCallback(
                () =>
                    ((column && column === sort?.column)
                        || (fieldPath && fieldPath === sort?.fieldPath))
                        ? onSort({
                            column: column,
                            fieldPath: fieldPath,
                            order:
                                sort?.order === 'Ascending'
                                    ? 'Descending'
                                    : sort?.order === 'Descending' && !isDefaultSortColumn
                                        ? undefined
                                        : 'Ascending'
                        })
                        : onSort({
                            column: column,
                            fieldPath: fieldPath,
                            order: 'Ascending'
                        }),
                [
                    sort,
                    column,
                    fieldPath,
                    onSort,
                    isDefaultSortColumn
                ]);

        return <ViewGroup
                orientation="horizontal"
                spacing={0}
                alignment="center"
                className={classes.root}
                onClick={onSortChange}
            >
                {
                    !localizeResourceCode &&
                    <ViewGroupItem
                        className={classes.label}
                        ratio={1}
                    >
                        <strong>
                            {fieldPath.getName()}
                        </strong>
                    </ViewGroupItem>
                }
                {
                    localizeResourceCode &&
                    <ViewGroupItem
                        className={classes.label}
                        ratio={1}
                    >
                        <strong>
                            <LocalizedText
                                code={localizeResourceCode}
                                value={localizeResourceDefaultValue}
                            />
                        </strong>
                    </ViewGroupItem>
                }
                {
                    onToggleFilter &&
                    <ViewGroupItem
                        className={
                            classNames(
                                classes.filter,
                                (isFilterOpen || hasFilter) && classes.active)
                        }
                    >
                        <IconButton
                            icon="filter_list"
                            iconSize={20}
                            tooltip={
                                <LocalizedText
                                    code="Generic.Filter"
                                    value="Filter"
                                />
                            }
                            onClick={onToggleFilter}
                            color={
                                (isFilterOpen || hasFilter)
                                    ? primaryColor
                                    : textSecondaryColor
                            }
                            badgeColor="error"
                            badgeVariant="dot"
                            badge={hasFilter ? 1 : undefined}
                        />
                    </ViewGroupItem>
                }
                {
                    onSort &&
                    ((column && column === sort?.column)
                        || (fieldPath && fieldPath === sort?.fieldPath)) &&
                    <ViewGroupItem>
                        <SortIconButton
                            isAscendingSort={sort?.order === 'Ascending'}
                            onClick={onSortChange}
                        />
                    </ViewGroupItem>
                }
        </ViewGroup>;
    }