import { enumerated, reference } from '../../../@Util/Serialization/Serialization';
import { EntityField } from './EntityField';
import { EntityNode } from './EntityNode';
import { ConstraintNode } from './ConstraintNode';
import { observable } from 'mobx';
import { EntityEvent } from './EntityEvent';
import { EntityValueMutation } from './EntityValueMutation';
import isEqual from '../../../@Util/IsEqual/isEqual';
import { Comparator } from '../../../@Component/Domain/DataObject/Model/Comparator';

export abstract class ComparisonConstraintNode extends ConstraintNode
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'EntityNode') @observable.ref activeEntityNode: EntityNode;
    @reference(undefined, 'EntityField') @observable.ref activeEntityField: EntityField;
    @observable.ref activeEntityFieldRepresentation: any;
    @observable @enumerated(Comparator, 'Comparator') comparator: Comparator;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(activeEntityNode: EntityNode,
                activeEntityField: EntityField,
                activeEntityFieldRepresentation: any,
                comparator: Comparator)
    {
        super();

        this.activeEntityNode = activeEntityNode;
        this.activeEntityField = activeEntityField;
        this.activeEntityFieldRepresentation = activeEntityFieldRepresentation;
        this.comparator = comparator;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getDependencies(): EntityNode[]
    {
        return [
            ...super.getDependencies(),
            this.activeEntityNode,
        ];
    }

    hashCode(): string
    {
        return `${super.hashCode()},${this.activeEntityNode && this.activeEntityNode.alias},${this.comparator}`;
    }

    equals(node: ComparisonConstraintNode): boolean
    {
        return super.equals(node)
            && (this.activeEntityNode && this.activeEntityNode.alias) === (node.activeEntityNode && node.activeEntityNode.alias)
            && this.activeEntityField === node.activeEntityField
            && this.comparator === node.comparator
            && isEqual(this.activeEntityFieldRepresentation, node.activeEntityFieldRepresentation);
    }

    isAffectedBy(event: EntityEvent): boolean
    {
        return event instanceof EntityValueMutation
            && event.entityField === this.activeEntityField;
    }

    descriptor()
    {
        return Object.assign(
            super.descriptor(),
            {
                type: 'Comparison',
                activeEntityNodeAlias: this.activeEntityNode.alias,
                activeEntityFieldId: this.activeEntityField ? (this.activeEntityField.isStaticField() ? undefined : this.activeEntityField.id) : undefined,
                activeEntityFieldCode: this.activeEntityField ? (this.activeEntityField.isStaticField() ? this.activeEntityField.code : undefined) : undefined,
                comparator: Comparator[this.comparator]
            });
    }

    // ----------------------- Private logic ------------------------
}
