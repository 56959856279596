import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardHeader from '../../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import Centered from '../../../../../../../../../@Future/Component/Generic/Centered/Centered';
import FileDropZone from '../../../../../../../../../@Future/Component/Generic/Input/FileDropZone/FileDropZone';
import { FileValue } from '../../../../../../../DataObject/Type/File/FileValue';
import { ActivityTemplateConstructorProps } from '../ActivityTemplateConstructor';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import { setValueByFieldInEntity } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';

export interface ActivityTemplateUploaderProps extends ActivityTemplateConstructorProps
{

}

const ActivityTemplateUploader: React.FC<ActivityTemplateUploaderProps> =
    props =>
    {
        const clearTemplateFile =
            useCallback(
                (commit: boolean = false) =>
                {
                    setValueByFieldInEntity(
                        props.entity,
                        props.templateFileField,
                        undefined,
                        props.commitContext
                    );

                    if (commit)
                    {
                        return commitEntityWithContext(
                            props.entity,
                            props.commitContext
                        );
                    }
                },
                [
                    props.entity,
                    props.templateFileField,
                    props.commitContext,
                ]);

        const clearDefinition =
            useCallback(
                () =>
                    setValueByFieldInEntity(
                        props.entity,
                        props.templateContentField,
                        undefined,
                        props.commitContext
                    ),
                [
                    props.entity,
                    props.templateContentField,
                    props.commitContext,
                ]);

        const documentUploaded =
            useCallback(
                () =>
                {
                    clearDefinition();

                    return commitEntityWithContext(props.entity, props.commitContext)
                        .then(
                            () =>
                                props.onPreview());
                },
                [
                    clearDefinition,
                    props.entity,
                    props.commitContext,
                    props.onPreview,
                ]);

        const onDrop =
            useCallback(
                (files: File[]) =>
                {
                    clearTemplateFile();

                    if (files.length > 0)
                    {
                        const file = files[0];

                        setValueByFieldInEntity(
                            props.entity,
                            props.templateFileField,
                            FileValue.fromFile(file),
                            props.commitContext
                        );

                        return commitEntityWithContext(props.entity, props.commitContext)
                            .then(
                                () =>
                                    documentUploaded()
                            );
                    }
                },
                [
                    clearTemplateFile,
                    props.entity,
                    props.templateFileField,
                    props.commitContext,
                    documentUploaded,
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <ViewGroupItem>
                <Centered
                    horizontal
                >
                    <CardHeader>
                        <LocalizedText
                            code="OfferTemplateUploader.OrUploadPdf"
                            value="...of upload een PDF bestand"
                        />
                    </CardHeader>
                </Centered>
            </ViewGroupItem>
            {
                props.templateFileField &&
                    <ViewGroupItem>
                        <FileDropZone
                            onDrop={onDrop}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(ActivityTemplateUploader);
