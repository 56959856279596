import { BaseType } from './@Model/BaseType';
import { BaseStore } from './BaseStore';
import { action, observable } from 'mobx';

/**
 * Abstract type factory for managing generic types.
 */
export abstract class BaseTypeStore<T extends BaseType> extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable types = observable.array<T>();
    @observable typeById = observable.map<string, T>();

    // ------------------------ Constructor -------------------------

    constructor(types: T[] = [])
    {
        super();

        for (let type of types)
        {
            this.registerType(type);
        }
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    /**
     * Registers type.
     *
     * @param {T} type
     */
    @action
    registerType(type: T)
    {
        this.types.push(type);
        this.typeById.set(type.id(), type);
    }

    // ------------------------ Public logic ------------------------

    /**
     * Determines whether type is present.
     *
     * @param {string} id
     * @returns {boolean}
     */
    hasType(id: string): boolean
    {
        return this.typeById.has(id);
    }

    /**
     * Gets type by id.
     *
     * @param {string} id
     * @returns {T}
     */
    getTypeById(id: string): T
    {
        return this.typeById.get(id);
    }

    // ----------------------- Private logic ------------------------
}
