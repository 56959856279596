import { EmailView } from './EmailView';
import { EmailEditor } from './EmailEditor';
import { EmailSpecification } from './EmailSpecification';
import { TextType } from '../TextType';
import { DataObjectEditorType, DataObjectSpecificationType, DataObjectViewerType } from '../../../Model/DataObjectType';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import { DataObject } from '../../../Model/DataObject';
import Editor from '../PhoneNumber/Editor';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export class EmailType extends TextType
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'EmailAddress';
    }

    name()
    {
        return localizeText('DataObject.Type.EmailAddress', 'E-mail adres');
    }

    view(): DataObjectViewerType
    {
        return EmailView;
    }

    hooksEditor()
    {
        return Editor;
    }

    editor(): DataObjectEditorType
    {
        return EmailEditor;
    }

    specification(): DataObjectSpecificationType
    {
        return EmailSpecification;
    }

    isValid(dataObject: DataObject): boolean
    {
        if (dataObject.getValue() !== undefined && dataObject.getValue() !== null)
        {
            return this.validateEmailAddress(dataObject.getValue());
        }

        return super.isValid(dataObject);
    }

    invalidCause(dataObject: DataObject): string
    {
        if (dataObject.getValue() && !this.validateEmailAddress(dataObject.getValue()))
        {
            return 'E-mail adres is ongeldig.';
        }

        return super.invalidCause(dataObject);
    }

    private validateEmailAddress(emailAddress: string)
    {
        let exp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return exp.test(emailAddress.toLowerCase());
    }
}
