import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import getTypes from '../../../../Type/Api/getTypes';
import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import PrimitiveValueType from '../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export default function getPortalRuntimeParameters()
{
    const types = getTypes();

    return new ParameterDictionary([
        new Parameter<EntityValueType>(
            'PortalUser',
            new EntityValueType(types.PortalUser.Type),
            true,
            types.PortalUser.Type.getName()),
        new Parameter<PrimitiveValueType>(
            'CurrentDateTime',
            new PrimitiveValueType(DataObject.getTypeById('DateTime')),
            true,
            localizeText('Generic.CurrentDateTime', 'Huidige datum & tijd'))
    ]);
}
