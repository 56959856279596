import { computed, observable } from 'mobx';

export class Color
{
    // ------------------------- Properties -------------------------

    @observable r: number;
    @observable g: number;
    @observable b: number;
    @observable a: number;

    // ------------------------ Constructor -------------------------

    constructor(r: number,
                g: number,
                b: number,
                a: number)
    {
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get css(): string
    {
        return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
    }

    @computed
    get hex(): string
    {
        return `#${('0' + this.r.toString(16)).slice(-2)}${('0' + this.g.toString(16)).slice(-2)}${('0' + this.b.toString(16)).slice(-2)}`;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
