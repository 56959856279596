import { type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';

@type('PayInvoiceDescriptor')
export class PayInvoiceDescriptor
{
    // ------------------- Persistent Properties --------------------

    @observable url: string;
    @observable provider: string;
    @observable isSucces: boolean;
    @observable errorMessage: string;
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
